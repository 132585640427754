import {lazy} from "../../lib/lazy.js";
export const reportRoutes = [
  {
    path: "/report/indent-report",
    auth: "order-statistics-report",
    component: lazy(() => import(/* webpackChunkName: "reportRoutes" */ "../../views/report/order/index.js"))
  },
  {
    path: "/report/master-product-sales-analysis",
    auth: "master-product-sales",
    component: lazy(() => import(/* webpackChunkName: "reportRoutes" */ "../../views/report/master-product-sales-analysis/index.js"))
  },
  {
    path: "/report/product-activity",
    auth: "product-sale-report",
    component: lazy(() => import(/* webpackChunkName: "reportRoutes" */ "../../views/report/product-activity/index.js"))
  },
  {
    path: "/report/product-report",
    auth: "product-sale-report",
    component: lazy(() => import(/* webpackChunkName: "reportRoutes" */ "../../views/report/product-sale/index.js"))
  },
  {
    path: "/report/product-category-sale-report",
    auth: "product-variant-sale-report",
    component: lazy(() => import(/* webpackChunkName: "reportRoutes" */ "../../views/report/product-category-sale/index.js"))
  },
  {
    path: "/report/platform-report",
    auth: "channel-sale-report",
    component: lazy(() => import(/* webpackChunkName: "reportRoutes" */ "../../views/report/platform-sale/index.js"))
  },
  {
    path: "/report/store-report",
    auth: "store-sale-report",
    component: lazy(() => import(/* webpackChunkName: "reportRoutes" */ "../../views/report/store-sale/index.js"))
  },
  {
    path: "/report/profit-report",
    auth: "net-income-report",
    component: lazy(() => import(/* webpackChunkName: "reportRoutes" */ "../../views/report/profit/index.js"))
  },
  {
    path: "/report/unsalable",
    auth: "low-stock-report",
    component: lazy(() => import(/* webpackChunkName: "reportRoutes" */ "../../views/report/unsalable/index.js"))
  },
  {
    path: "/report/stock-value",
    auth: "product-sale-report",
    component: lazy(() => import(/* webpackChunkName: "reportRoutes" */ "../../views/report/stock-value/index.js"))
  }
];
