import {lazy} from "../../lib/lazy.js";
export const settingRoutes = [
  {
    path: "/stock-setting",
    auth: "stocks-settings",
    component: lazy(() => import(/* webpackChunkName: "settingOldRoutes" */ "../../views/setting/StockSettingNew/index.js")),
    meta: {
      openKeys: ["menu-sub-inventory"]
    }
  },
  {
    path: "/stock-setting/monitoring-sync",
    auth: "stocks-settings",
    component: lazy(() => import(/* webpackChunkName: "settingOldRoutes" */ "../../views/setting/StockSettingMonitoringSync/index.js"))
  },
  {
    path: "/shipping-setting",
    component: lazy(() => import(/* webpackChunkName: "settingOldRoutes" */ "../../views/setting/shipping/index.js"))
  },
  {
    path: "/role-management",
    component: lazy(() => import(/* webpackChunkName: "settingOldRoutes" */ "../../views/rule-management/index.js")),
    auth: "list-role"
  },
  {
    path: "/distribution-settings/distributionSettings-history-operation",
    component: lazy(() => import(/* webpackChunkName: "warehouseRoutes" */ "../../views/order-warehouse-settings/WareHouseHistoryOperation.js"))
  }
];
