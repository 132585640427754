import React from "../../../__snowpack__/pkg/react.js";
import {Spin} from "../../../__snowpack__/pkg/antd.js";
import styled from "../../../__snowpack__/pkg/styled-components.js";
export const Loading = () => {
  return /* @__PURE__ */ React.createElement(Wrapper, {
    style: {
      minHeight: "100vh"
    }
  }, /* @__PURE__ */ React.createElement(Spin, null));
};
const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: '100%';
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;
