import {useIntl} from "../../__snowpack__/pkg/react-intl.js";
export const useIntlTyped = (localeMessage) => {
  const {formatMessage: formatMessageRaw, locale, messages} = useIntl();
  Object.assign(messages, localeMessage[locale]);
  function formatMessage(descriptor, ...args) {
    return formatMessageRaw(descriptor, ...args);
  }
  return {
    locale,
    messages,
    formatMessage,
    formatMessageRaw,
    formatMessageMock
  };
};
export const formatMessageMock = (config, ...args) => {
  if (config.id) {
    return `i18n: ${config.id}`;
  }
  return `i18n`;
};
