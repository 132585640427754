import {useState} from "../../__snowpack__/pkg/react.js";
import Cookies from "../../__snowpack__/pkg/js-cookie.js";
import request from "../utils/request/index.js";
export const usePostError = () => {
  let trackId = Cookies.get("trackId");
  const [errorLoading, setErrorLoading] = useState(false);
  const submitError = async (params) => {
    setErrorLoading(true);
    const defaultParams = {
      applicationName: "SYS_ERP",
      requestHost: window.location.origin,
      requestUri: window.location.pathname,
      httpAgent: window.navigator.userAgent,
      clientId: trackId
    };
    const data = request.post("/infra/supports/ticket/submit", {...defaultParams, ...params}).finally(() => {
      setErrorLoading(false);
    });
    return data;
  };
  return {
    submitError,
    errorLoading
  };
};
