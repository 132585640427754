import {lazy} from "../../lib/lazy.js";
export const warehousePrductListRouters = [
  {
    path: "/warehouse-product-list",
    auth: "warehouse",
    component: lazy(() => import(/* webpackChunkName: "warehouseProductListRoutes" */ "../../views/warehouse-product-list/index.js")),
    meta: {}
  },
  {
    path: "/warehouse-product-list/create-master-product",
    component: lazy(() => import(/* webpackChunkName: "warehouseProductListRoutes" */ "../../views/warehouse-product-list/createMasterProduct/index.js"))
  }
];
