import '../../../../common/_commonjsHelpers-64249c85.js';
import { r as react } from '../../../../common/index-ae8bfcfb.js';

var defaultOrigin = window.location.origin;
var useIframeHook = function (id, beforeClearCallback) {
    var _a = react.useState([]), children = _a[0], setChildren = _a[1];
    react.useEffect(function () {
        var el = document.getElementById(id);
        if (el) {
            children.push(el.contentWindow);
        }
        return function () {
            if (beforeClearCallback) {
                beforeClearCallback();
            }
            setChildren(children.filter(function (_) { return _ !== (el === null || el === void 0 ? void 0 : el.contentWindow); }));
        };
    }, []);
    var sendMessageToChildren = function (data, origin) {
        children.forEach(function (child) {
            sendMessage(data, child, origin);
        });
    };
    var useMessage = function (callback, origin) {
        var curOrigin = origin || window.location.origin;
        react.useEffect(function () {
            var msgHandler = function (event) {
                var data = {};
                if (event.origin !== curOrigin) {
                    return;
                }
                if (!children.includes(event.source)) {
                    return;
                }
                try {
                    data = JSON.parse(event.data);
                }
                catch (error) { }
                try {
                    callback(data);
                }
                catch (error) {
                    console.error(error);
                }
            };
            window.addEventListener("message", msgHandler);
            return function () {
                window.removeEventListener("message", msgHandler);
            };
        }, []);
    };
    return { sendMessageToChildren: sendMessageToChildren, useMessage: useMessage };
};
var sendMessage = function (data, child, origin) {
    var currentOrigin = origin || defaultOrigin;
    if (child) {
        child.postMessage(JSON.stringify(data), currentOrigin);
        return;
    }
    window.parent.postMessage(JSON.stringify(data), currentOrigin);
};

export { useIframeHook };
