import {lazy} from "../../lib/lazy.js";
export const productWooCommerceRoutes = [
  {
    path: "/product-woo-commerce",
    component: lazy(() => import(/* webpackChunkName: "productWooCommerceRoutes" */ "../../views/product-woo-commerce/index.js")),
    auth: "list-product"
  },
  {
    path: "/product-woo-commerce/add",
    component: lazy(() => import(/* webpackChunkName: "productWooCommerceRoutes" */ "../../views/product-woo-commerce/detail/index.js")),
    auth: "list-product"
  },
  {
    path: "/product-woo-commerce/edit/:id",
    component: lazy(() => import(/* webpackChunkName: "productWooCommerceRoutes" */ "../../views/product-woo-commerce/detail/index.js")),
    auth: "list-product"
  },
  {
    path: "/product-woo-commerce/mass-edit-category",
    component: lazy(() => import(/* webpackChunkName: "productWooCommerceRoutes" */ "../../views/product-woo-commerce/MassEditCategory/index.js"))
  },
  {
    path: "/product-woo-commerce/mass-edit",
    component: lazy(() => import(/* webpackChunkName: "productWooCommerceRoutes" */ "../../views/product-woo-commerce/MassEdit/index.js")),
    auth: "list-product"
  }
];
