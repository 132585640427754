import {lazy} from "../../lib/lazy.js";
export const productLazadaRoutes = [
  {
    path: "/product-lazada",
    component: lazy(() => import(/* webpackChunkName: "productLazadaRoutes" */ "../../views/product-lazada/list/index.js")),
    auth: "list-product"
  },
  {
    path: "/product-lazada-:action(add)",
    component: lazy(() => import(/* webpackChunkName: "productLazadaRoutes" */ "../../views/product-lazada/edit/index.js")),
    auth: "list-product"
  },
  {
    path: "/product-lazada-:action(edit)/:id",
    component: lazy(() => import(/* webpackChunkName: "productLazadaRoutes" */ "../../views/product-lazada/edit/index.js")),
    auth: "list-product"
  },
  {
    path: "/product-lazada-mass-edit",
    component: lazy(() => import(/* webpackChunkName: "productLazadaRoutes" */ "../../views/product-lazada/massEdit/index.js"))
  },
  {
    path: "/product-lazada-mass-category",
    component: lazy(() => import(/* webpackChunkName: "productLazadaRoutes" */ "../../views/product-lazada/massEditCategory/index.js"))
  }
];
