import React from "../../../../__snowpack__/pkg/react.js";
export const IconClose = React.memo((props) => {
  return /* @__PURE__ */ React.createElement("svg", {
    width: "14px",
    height: "14px",
    viewBox: "0 0 14 14",
    version: "1.1",
    xmlns: "http://www.w3.org/2000/svg",
    ...props
  }, /* @__PURE__ */ React.createElement("title", null, "\u7F16\u7EC4"), /* @__PURE__ */ React.createElement("g", {
    id: "\u5957\u9910/\u5E97\u94FA\u63D0\u9192",
    stroke: "none",
    "stroke-width": "1",
    fill: "none",
    "fill-rule": "evenodd"
  }, /* @__PURE__ */ React.createElement("g", {
    id: "\u5E97\u94FA\u5230\u671F",
    transform: "translate(-694.000000, -93.000000)",
    "fill-rule": "nonzero"
  }, /* @__PURE__ */ React.createElement("g", {
    id: "\u7F16\u7EC4",
    transform: "translate(694.000000, 93.000000)"
  }, /* @__PURE__ */ React.createElement("path", {
    d: "M7,0 C3.13400798,0 0,3.13400798 0,7 C0,10.865992 3.13400798,14 7,14 C10.865992,14 14,10.865992 14,7 C14,3.13400798 10.865992,0 7,0 Z",
    id: "\u8DEF\u5F84",
    fill: "#FF4D4F"
  }), /* @__PURE__ */ React.createElement("path", {
    d: "M10.4013085,4.0438214 C10.5328972,4.17540968 10.5328972,4.38875295 10.4013085,4.52034123 L7.92164346,7 L10.4013085,9.47965878 C10.5328972,9.61124706 10.5328972,9.82459034 10.4013085,9.95617862 L9.95617724,10.4013088 C9.82458862,10.5328971 9.61124481,10.5328971 9.47965619,10.4013088 L7,7.92164999 L4.52034381,10.401291 C4.38875519,10.5328793 4.17541138,10.5328793 4.04382276,10.401291 L3.59869146,9.95616085 C3.46710285,9.82457257 3.46710285,9.6112293 3.59869146,9.47964102 L6.07835654,6.99998224 L3.59869148,4.52034122 C3.46710286,4.38875294 3.46710286,4.17540966 3.59869148,4.04382138 L4.04382278,3.59869121 C4.1754114,3.46710293 4.38875521,3.46710293 4.52034382,3.59869121 L7,6.07834999 L9.47967398,3.59869121 C9.61126259,3.46710293 9.8246064,3.46710293 9.95619502,3.59869121 L10.4013085,4.0438214 Z",
    id: "\u8DEF\u5F84",
    fill: "#FFFFFF"
  })))));
});
