import {lazy} from "../../lib/lazy.js";
export const productShopeeRoutes = [
  {
    path: "/product-shopee",
    component: lazy(() => import(/* webpackChunkName: "productShopeeRoutes" */ "../../views/product-shopee/list/index.js")),
    auth: "list-product"
  },
  {
    path: "/product-shopee-add",
    component: lazy(() => import(/* webpackChunkName: "productShopeeRoutes" */ "../../views/product-shopee/edit/add.js")),
    auth: "list-product"
  },
  {
    path: "/product-shopee-edit/:id",
    component: lazy(() => import(/* webpackChunkName: "productShopeeRoutes" */ "../../views/product-shopee/edit/edit.js")),
    auth: "list-product"
  },
  {
    path: "/product-shopee-mass-edit-category-attribute",
    component: lazy(() => import(/* webpackChunkName: "productBlibliRoutes" */ "../../views/product-shopee/mass-edit-category-attribute/index.js"))
  },
  {
    path: "/product-shopee-mass-edit",
    component: lazy(() => import(/* webpackChunkName: "productShopeeRoutes" */ "../../views/product-shopee/mass-edit/batch-update.js"))
  }
];
