import {useEffect, useState} from "../../__snowpack__/pkg/react.js";
import iframeResizer from "../../__snowpack__/pkg/iframe-resizer.js";
import {useHistory} from "../../__snowpack__/pkg/react-router-dom.js";
import {createModel} from "../../__snowpack__/pkg/hox.js";
const defaultSendMessageOrigin = "*";
const defaultIfOptions = {};
let children = [];
export const useIframe = (id, ifOptions) => {
  useEffect(() => {
    const cusIfOptions = ifOptions || defaultIfOptions;
    const el = document.getElementById(id);
    const [iframe] = iframeResizer.iframeResizer(cusIfOptions, el);
    children.push(el.contentWindow);
    return () => {
      iframe.iFrameResizer.removeListeners();
      children = children.filter((_) => _ !== el.contentWindow);
    };
  }, []);
};
export const useMessage = (callback, origin) => {
  useEffect(() => {
    const msgHandler = (event) => {
      let data = {};
      if (origin && event.origin !== origin) {
        return;
      }
      try {
        data = JSON.parse(event.data);
      } catch (error) {
      }
      try {
        callback(data);
      } catch (error) {
        console.error(error);
      }
    };
    window.addEventListener("message", msgHandler);
    return () => {
      window.removeEventListener("message", msgHandler);
    };
  }, []);
};
export const sendMessage = (data, child, origin) => {
  const currentOrigin = origin || defaultSendMessageOrigin;
  if (child) {
    child.postMessage(JSON.stringify(data), currentOrigin);
    return;
  }
  window.parent.postMessage(JSON.stringify(data), currentOrigin);
};
export const sendMessageToChildren = (data, origin) => {
  children.forEach((child) => {
    sendMessage(data, child, origin);
  });
};
export const useIsInIframe = () => {
  const [isInIframe, setIsInIframe] = useState(void 0);
  useEffect(() => {
    if (window.parent !== window) {
      setIsInIframe(true);
    } else {
      setIsInIframe(false);
    }
  }, []);
  return {isInIframe};
};
export const useUpdateQuery = () => {
  const {isInIframe} = useIsInIframe();
  const [query, setQuery] = useState("");
  const updateQuery = (query2) => {
    if (isInIframe) {
      window.parent.history.replaceState({}, "", `${window.parent.location.pathname}?${query2}`);
    }
    window.history.replaceState({}, "", `${window.location.pathname}?${query2}`);
    setQuery(query2);
  };
  return {updateQuery, query};
};
export const useUpdateQueryModel = createModel(useUpdateQuery);
export const useUpdateRoute = () => {
  const {isInIframe} = useIsInIframe();
  const router = useHistory();
  const updateRoute = (relativePath) => {
    const fullPath = isInIframe ? `/${relativePath}` : `/${relativePath}`;
    if (isInIframe) {
      sendMessage({
        event: "routerPush",
        value: {
          pathname: fullPath
        }
      });
    } else {
      router.push(fullPath);
    }
  };
  const updateRouteForce = (relativePath) => {
    const fullPath = `/${relativePath}`;
    location.href = fullPath;
  };
  const updateRouteTab = (relativePath) => {
    const fullPath = isInIframe ? `/${relativePath}` : `/${relativePath}`;
    if (isInIframe) {
      window.open(fullPath);
    } else {
      window.open(fullPath);
    }
  };
  const updateRouteBack = (fallback) => {
    if (router.length > 1) {
      router.goBack();
    }
    fallback();
  };
  const updateRouteReload = () => {
    if (router.length > 1) {
      window.location.reload();
    }
  };
  return {
    updateRoute,
    updateRouteForce,
    updateRouteTab,
    updateRouteBack,
    updateRouteReload
  };
};
