import {lazy} from "../../lib/lazy.js";
export const stockPushNewRoutes = [
  {
    path: "/stock-push-new",
    auth: "store-stock-push-rules",
    component: lazy(() => import(/* webpackChunkName: "stockPushNewRoutes" */ "../../views/stock-push-new/index.js")),
    meta: {
      openKeys: ["menu-sub-inventory"]
    }
  },
  {
    path: "/stockpush-rule-detail",
    auth: "stock-push-rules",
    component: lazy(() => import(/* webpackChunkName: "stockPushNewRoutes" */ "../../views/stock-push-new/detail/index.js")),
    meta: {
      openKeys: ["menu-sub-inventory"]
    }
  },
  {
    path: "/stock-push-operation-log",
    auth: "store-stock-push-rules",
    component: lazy(() => import(/* webpackChunkName: "stockPushNewRoutes" */ "../../views/stock-push-new/components/OperationLog.js")),
    meta: {
      openKeys: ["menu-sub-inventory"]
    }
  },
  {
    path: "/stock-push-main-store-stock-lock",
    auth: "store-stock-push-rules",
    component: lazy(() => import(/* webpackChunkName: "stockPushNewRoutes" */ "../../views/stock-push-new/components/MainStoreStockLock.js")),
    meta: {
      openKeys: ["menu-sub-inventory"]
    }
  }
];
