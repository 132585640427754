import {lazy} from "../../lib/lazy.js";
export const outboundManagementRoutes = [
  {
    path: "/outbound-management",
    auth: "outbound-management",
    component: lazy(() => import(/* webpackChunkName: "outboundManagementRoutes" */ "../../views/outbound-management/index.js")),
    meta: {
      openKeys: ["menu-sub-warehouse"]
    }
  },
  {
    path: "/outbound-management-add",
    auth: "outbound-management",
    component: lazy(() => import(/* webpackChunkName: "outboundManagementRoutes" */ "../../views/outbound-management/Add.js")),
    meta: {
      openKeys: ["menu-sub-warehouse"],
      selectedKeys: ["outbound-management"]
    }
  },
  {
    path: "/outbound-management-edit/:id/:orderType",
    component: lazy(() => import(/* webpackChunkName: "outboundManagementRoutes" */ "../../views/outbound-management/Edit.js")),
    meta: {
      openKeys: ["menu-sub-warehouse"],
      selectedKeys: ["outbound-management"]
    }
  },
  {
    path: "/outbound-management-detail/:id/:orderType",
    component: lazy(() => import(/* webpackChunkName: "outboundManagementRoutes" */ "../../views/outbound-management/Detail.js")),
    meta: {
      openKeys: ["menu-sub-warehouse"],
      selectedKeys: ["outbound-management"]
    }
  },
  {
    path: "/outbound-management-submit/:id/:orderType",
    component: lazy(() => import(/* webpackChunkName: "outboundManagementRoutes" */ "../../views/outbound-management/Submit.js")),
    meta: {
      openKeys: ["menu-sub-warehouse"],
      selectedKeys: ["outbound-management"]
    }
  }
];
