import {useEffect, useMemo, useState} from "../../__snowpack__/pkg/react.js";
import {createModel} from "../../__snowpack__/pkg/hox.js";
import {isEmpty, filter} from "../../__snowpack__/pkg/lodash.js";
import {axios} from "../lib/axios.js";
import {persistLocalStorageWithLifetime, PERSIST_LIFETIME_KEY} from "../config/order/persist.js";
import {SessionHelper} from "../../__snowpack__/pkg/@genie/lib-iam-utils.js";
export const useCountryInfo = (formatMessage) => {
  const [CountryList, setCountryList] = useState([]);
  const [currencyCodeToSymbol, setCurrencyCodeToSymbol] = useState({});
  let sessionUserInfo = SessionHelper.getSessionUserInfo();
  let newCountry = sessionUserInfo?.country || {};
  const {email, mobile} = sessionUserInfo ? sessionUserInfo : {
    email: null,
    mobile: null
  };
  const [country, setCountry] = useState({
    ...newCountry,
    currencyCode: newCountry.currency
  });
  const generateCodeToSymbol = (list) => {
    let tempObj = {};
    list.forEach((country2) => {
      tempObj[country2.currencyCode] = country2.currencySymbol;
    });
    setCurrencyCodeToSymbol(tempObj);
  };
  const getLocalCountryList = () => {
    const countryList = persistLocalStorageWithLifetime.get(PERSIST_LIFETIME_KEY.GLOBAL_COUNTRY_LIST);
    let isCountryEmpty = true;
    if (countryList?.length) {
      generateCodeToSymbol(countryList);
      isCountryEmpty = false;
    }
    return {
      list: countryList,
      isCountryEmpty
    };
  };
  const getCountryList = async () => {
    const {list, isCountryEmpty} = getLocalCountryList();
    if (isCountryEmpty) {
      const {data: newData} = await axios.get("/infra/common/selector", {
        params: {type: "COUNTRY_DETAIL"}
      });
      let sortData = [
        {
          countryCode: "CN",
          countryName: "China",
          callingCode: "+86",
          languageCode: "zh",
          currencyCode: "CNY",
          currencySymbol: "\xA5",
          timezone: "GMT+08:00",
          country: "CN",
          currency: "CNY",
          locale: "zh_CN"
        },
        {
          countryCode: "ID",
          countryName: "Indonesia",
          callingCode: "+62",
          languageCode: "id",
          currencyCode: "IDR",
          currencySymbol: "Rp",
          timezone: "GMT+07:00",
          country: "ID",
          currency: "IDR",
          locale: "in_ID"
        },
        {
          countryCode: "PH",
          countryName: "Philippines",
          callingCode: "+63",
          languageCode: "en",
          currencyCode: "PHP",
          currencySymbol: "\u20B1",
          timezone: "GMT+08:00",
          country: "PH",
          currency: "PHP",
          locale: "en_PH"
        },
        {
          countryCode: "TH",
          countryName: "Thailand",
          callingCode: "+66",
          languageCode: "th",
          currencyCode: "THB",
          currencySymbol: "\u0E3F",
          timezone: "GMT+07:00",
          country: "TH",
          currency: "THB",
          locale: "en_US"
        },
        {
          countryCode: "VN",
          countryName: "Vietnam",
          callingCode: "+84",
          languageCode: "vi",
          currencyCode: "VND",
          currencySymbol: "\u20AB",
          timezone: "GMT+07:00",
          country: "VN",
          currency: "VND",
          locale: "en_US"
        },
        {
          countryCode: "MY",
          countryName: "Malaysia",
          callingCode: "+60",
          languageCode: "ms",
          currencyCode: "MYR",
          currencySymbol: "RM",
          timezone: "GMT+08:00",
          country: "MY",
          currency: "MYR",
          locale: "en_US"
        },
        {
          countryCode: "SG",
          countryName: "Singapore",
          callingCode: "+65",
          languageCode: "zh",
          currencyCode: "SGD",
          currencySymbol: "S$",
          timezone: "GMT+08:00",
          country: "SG",
          currency: "SGD",
          locale: "zh_SG"
        },
        {
          countryCode: "US",
          countryName: "United States",
          callingCode: "+1",
          languageCode: "en",
          currencyCode: "USD",
          currencySymbol: "$",
          timezone: "GMT-05:00",
          country: "US",
          currency: "USD",
          locale: "en_US"
        }
      ];
      let data = [
        ...sortData,
        ...newData?.filter((i) => !["CN", "ID", "PH", "TH", "VN", "MY", "SG", "US"].includes(i.country))?.map((item) => {
          return {
            countryCode: item.country,
            countryName: item.countryName,
            callingCode: item.timezone,
            currencyCode: item.callingCode,
            currencySymbol: item.currencySymbol,
            timezone: item.timezone,
            country: item.country,
            locale: item.local,
            currency: item.currencyCode
          };
        })
      ];
      persistLocalStorageWithLifetime.set(PERSIST_LIFETIME_KEY.GLOBAL_COUNTRY_LIST, data, 24 * 60 * 60 * 1e3);
      generateCodeToSymbol(data);
      setCountryList(data);
    } else {
      setCountryList(list);
      generateCodeToSymbol(list);
    }
  };
  const getCountryInfo = useMemo(() => {
    if (!isEmpty(country)) {
      return Boolean(["ID", "CN"].includes(country.countryCode));
    } else {
      return true;
    }
  }, [country.countryCode]);
  const initCountry = async () => {
    let newCountry2 = await SessionHelper.getSessionUserInfo()?.country;
    setCountry({
      ...newCountry2,
      currencyCode: newCountry2.currency
    });
  };
  useEffect(() => {
    initCountry();
  }, []);
  return {
    getCountryInfo,
    country,
    email,
    mobile,
    CountryList: filter(CountryList, (item) => !["TW"].includes(item.countryCode)),
    fetchCountryList: getCountryList,
    currencyCodeToSymbol
  };
};
export const useCountryInfoModel = createModel(useCountryInfo);
