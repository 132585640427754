import { e as eventLogger$1, c as customMerge, o as oldExtraStr2Json } from './index-4db7daf4.js';

class Modal extends HTMLElement {
    constructor(props) {
        var _a, _b, _c, _d, _e;
        super();
        this.props = Object.assign(Object.assign({}, props), { cancelText: (_a = props.cancelText) !== null && _a !== void 0 ? _a : "Cancel", openText: (_b = props.openText) !== null && _b !== void 0 ? _b : "Open", okText: (_c = props.okText) !== null && _c !== void 0 ? _c : "OK", showCancelButton: (_d = props.showCancelButton) !== null && _d !== void 0 ? _d : true, showOpenButton: (_e = props.showOpenButton) !== null && _e !== void 0 ? _e : false });
        this.render();
        this.confirmButton =
            this.shadowRoot.querySelector("#confirm-btn");
        this.cancelButton =
            this.shadowRoot.querySelector("#cancel-btn");
        this.openButton =
            this.shadowRoot.querySelector("#open-btn");
        this.bindEvent();
    }
    bindEvent() {
        var _a, _b, _c;
        (_a = this.confirmButton) === null || _a === void 0 ? void 0 : _a.addEventListener("click", (ev) => {
            var _a, _b;
            (_b = (_a = this.props).onOk) === null || _b === void 0 ? void 0 : _b.call(_a, ev);
            // Modal.singleInstance?.remove();
        });
        if (this.props.showCancelButton) {
            (_b = this.cancelButton) === null || _b === void 0 ? void 0 : _b.addEventListener("click", (ev) => {
                var _a, _b, _c;
                (_b = (_a = this.props).onCancel) === null || _b === void 0 ? void 0 : _b.call(_a, ev);
                (_c = Modal.singleInstance) === null || _c === void 0 ? void 0 : _c.remove();
            });
        }
        if (this.props.showOpenButton) {
            (_c = this.openButton) === null || _c === void 0 ? void 0 : _c.addEventListener("click", (ev) => {
                var _a, _b;
                (_b = (_a = this.props).onOpen) === null || _b === void 0 ? void 0 : _b.call(_a, ev);
            });
        }
    }
    render() {
        const template = document.createElement("template");
        template.innerHTML = `
      <div class="mask"></div>
      <div class="modal">
        <div class="title">${this.props.title}</div>
        <div class="content">${this.props.content}</div>
        <div class="timestamp">${this.props.timestamp}</div>
        <div class="footer">
          ${this.props.showCancelButton
            ? `
              <button class="btn" id="cancel-btn">${this.props.cancelText}</button>
            `
            : ""}
          <button class="btn" id="confirm-btn">${this.props.okText}</button>
          ${this.props.showOpenButton
            ? `
              <button class="btn" id="open-btn">${this.props.openText}</button>
            `
            : ""}
        </div>
      </div>
      <style>
        .mask {
          width: 100vw;
          height: 100vh;
          position: fixed;
          top: 0;
          left: 0;
          background: #00000015;
          z-index: 9999;
        }
        .modal {
          display: flex;
          flex-direction: column;
          white-space: pre-wrap;
          word-wrap: break-word;
          width: 400px;
          min-height: 200px;
          max-height: 70vh;
          overflow: auto;
          background: #fff;
          border-radius: 6px;
          position: fixed;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          padding: 24px;
          box-sizing: border-box;
          box-shadow: 2px 2px 10px #00000015;
          z-index: 10000;
        }
        .title {
          font-size: 16px;
          margin-bottom: 12px;
          color: #333;
        }
        .content {
          font-size: 14px;
          color: #666;
          margin-bottom: 12px;
        }
        .timestamp {
          flex: 1;
          font-size: 12px;
          color: #999;
          margin-bottom: 12px;
        }
        .footer {
          display: flex;
          justify-content: flex-end;
          gap: 12px;
        }
        .btn {
          border: none;
          height: 32px;
          line-height: 32px;
          padding: 0px 8px;
          border-radius: 4px;
          cursor: pointer;
          outline: none;
        }
        .btn:hover {
          opacity: 0.8;
        }
        .btn:active {
          opacity: 0.9;
        }
      </style>
    `;
        const shadow = this.attachShadow({ mode: "open" });
        shadow.appendChild(template.content.cloneNode(true));
    }
    static open(props) {
        if (Modal.singleInstance) {
            Modal.singleInstance.remove();
        }
        Modal.singleInstance = new Modal(props);
        document.body.appendChild(Modal.singleInstance);
    }
    static destroy() {
        if (Modal.singleInstance) {
            Modal.singleInstance.remove();
        }
    }
}
window.customElements.define("iam-modal", Modal);

/*! js-cookie v3.0.1 | MIT */
/* eslint-disable no-var */
function assign (target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i];
    for (var key in source) {
      target[key] = source[key];
    }
  }
  return target
}
/* eslint-enable no-var */

/* eslint-disable no-var */
var defaultConverter = {
  read: function (value) {
    if (value[0] === '"') {
      value = value.slice(1, -1);
    }
    return value.replace(/(%[\dA-F]{2})+/gi, decodeURIComponent)
  },
  write: function (value) {
    return encodeURIComponent(value).replace(
      /%(2[346BF]|3[AC-F]|40|5[BDE]|60|7[BCD])/g,
      decodeURIComponent
    )
  }
};
/* eslint-enable no-var */

/* eslint-disable no-var */

function init (converter, defaultAttributes) {
  function set (key, value, attributes) {
    if (typeof document === 'undefined') {
      return
    }

    attributes = assign({}, defaultAttributes, attributes);

    if (typeof attributes.expires === 'number') {
      attributes.expires = new Date(Date.now() + attributes.expires * 864e5);
    }
    if (attributes.expires) {
      attributes.expires = attributes.expires.toUTCString();
    }

    key = encodeURIComponent(key)
      .replace(/%(2[346B]|5E|60|7C)/g, decodeURIComponent)
      .replace(/[()]/g, escape);

    var stringifiedAttributes = '';
    for (var attributeName in attributes) {
      if (!attributes[attributeName]) {
        continue
      }

      stringifiedAttributes += '; ' + attributeName;

      if (attributes[attributeName] === true) {
        continue
      }

      // Considers RFC 6265 section 5.2:
      // ...
      // 3.  If the remaining unparsed-attributes contains a %x3B (";")
      //     character:
      // Consume the characters of the unparsed-attributes up to,
      // not including, the first %x3B (";") character.
      // ...
      stringifiedAttributes += '=' + attributes[attributeName].split(';')[0];
    }

    return (document.cookie =
      key + '=' + converter.write(value, key) + stringifiedAttributes)
  }

  function get (key) {
    if (typeof document === 'undefined' || (arguments.length && !key)) {
      return
    }

    // To prevent the for loop in the first place assign an empty array
    // in case there are no cookies at all.
    var cookies = document.cookie ? document.cookie.split('; ') : [];
    var jar = {};
    for (var i = 0; i < cookies.length; i++) {
      var parts = cookies[i].split('=');
      var value = parts.slice(1).join('=');

      try {
        var foundKey = decodeURIComponent(parts[0]);
        jar[foundKey] = converter.read(value, foundKey);

        if (key === foundKey) {
          break
        }
      } catch (e) {}
    }

    return key ? jar[key] : jar
  }

  return Object.create(
    {
      set: set,
      get: get,
      remove: function (key, attributes) {
        set(
          key,
          '',
          assign({}, attributes, {
            expires: -1
          })
        );
      },
      withAttributes: function (attributes) {
        return init(this.converter, assign({}, this.attributes, attributes))
      },
      withConverter: function (converter) {
        return init(assign({}, this.converter, converter), this.attributes)
      }
    },
    {
      attributes: { value: Object.freeze(defaultAttributes) },
      converter: { value: Object.freeze(converter) }
    }
  )
}

var api = init(defaultConverter, { path: '/' });

/** IAM 配置 */
const IAM_CONFIG_MAP = {
    /* 有独立的IAM域名 */
    "https://accounts.ginee.com": {
        TOKEN_NAME: "_iam_token",
        INFRA_ORIGIN: "https://bizapi.ginee.com",
    },
    "https://accounts.ginee.cn": {
        TOKEN_NAME: "_iam_token",
        INFRA_ORIGIN: "https://bizapi.ginee.cn",
    },
    "https://dev-accounts.advai.cn": {
        TOKEN_NAME: "_iam_token_200",
        INFRA_ORIGIN: "https://dev-infra.advai.cn",
    },
    "https://dev1-accounts.advai.cn": {
        TOKEN_NAME: "_iam_token_99",
        INFRA_ORIGIN: "https://dev1-infra.advai.cn",
    },
    "https://ainee-dev1-accounts.advai.cn": {
        TOKEN_NAME: "_iam_token_41",
        INFRA_ORIGIN: "https://ainee-dev1-infra.advai.cn",
    },
    "https://ainee-prestg1-accounts.advai.cn": {
        TOKEN_NAME: "_iam_token_42",
        INFRA_ORIGIN: "https://ainee-prestg1-infra.advai.cn",
    },
    "https://accounts.advai.net": {
        TOKEN_NAME: "_iam_token_143",
        INFRA_ORIGIN: "https://infra.advai.net",
    },
    "https://prestg1-accounts.advai.cn": {
        TOKEN_NAME: "_iam_token_146",
        INFRA_ORIGIN: "https://prestg1-infra.advai.cn",
    },
    "https://prestg2-accounts.advai.cn": {
        TOKEN_NAME: "_iam_token_98",
        INFRA_ORIGIN: "https://prestg2-infra.advai.cn",
    },
    "https://prestg5-accounts.advai.cn": {
        TOKEN_NAME: "_iam_token_145",
        INFRA_ORIGIN: "https://prestg5-infra.advai.cn",
    },
    "https://prestg6-accounts.advai.cn": {
        TOKEN_NAME: "_iam_token_147",
        INFRA_ORIGIN: "https://prestg6-infra.advai.cn",
    },
    "https://dev-seller.advai.cn": {
        TOKEN_NAME: "_iam_token_200",
        INFRA_ORIGIN: "https://dev-infra.advai.cn",
    },
    "https://dev1-seller.advai.cn": {
        TOKEN_NAME: "_iam_token_99",
        INFRA_ORIGIN: "https://dev1-infra.advai.cn",
    },
    "https://dev2-seller.advai.cn": {
        TOKEN_NAME: "_iam_token_103",
        INFRA_ORIGIN: "https://dev2-infra.advai.cn",
    },
    "https://dev3-seller.advai.cn": {
        TOKEN_NAME: "_iam_token_104",
        INFRA_ORIGIN: "https://dev3-infra.advai.cn",
    },
};

var KEYS;
(function (KEYS) {
    KEYS["ERP"] = "ERP";
    KEYS["WMS"] = "WMS";
    KEYS["PARTNER"] = "PARTNER";
    KEYS["LISTING"] = "LISTING";
    KEYS["CHAT"] = "CHAT";
    KEYS["LLMX"] = "LLMX";
    KEYS["DOOR"] = "DOOR";
    KEYS["POS"] = "POS";
})(KEYS || (KEYS = {}));

var ERP_CONFIG = {
    key: KEYS.ERP,
    clientId: "SYS_ERP",
    accessPath: "/login",
    origins: [
        {
            origin: "https://erp.ginee.com",
            IAM_ORIGIN: "https://accounts.ginee.com",
        },
        { origin: "https://erp.ginee.cn", IAM_ORIGIN: "https://accounts.ginee.cn" },
        {
            origin: "https://ainee-dev1-seller.advai.cn",
            IAM_ORIGIN: "https://ainee-dev1-accounts.advai.cn",
        },
        {
            origin: "https://ainee-prestg1-seller.advai.cn",
            IAM_ORIGIN: "https://ainee-prestg1-accounts.advai.cn",
        },
        {
            origin: "https://dev-seller.advai.cn",
            IAM_ORIGIN: "https://dev-accounts.advai.cn",
        },
        {
            origin: "https://dev1-seller.advai.cn",
            IAM_ORIGIN: "https://dev1-accounts.advai.cn",
        },
        {
            origin: "https://dev2-seller.advai.cn",
            IAM_ORIGIN: "https://dev2-accounts.advai.cn",
        },
        {
            origin: "https://dev3-seller.advai.cn",
            IAM_ORIGIN: "https://dev3-accounts.advai.cn",
        },
        {
            origin: "https://prestg-seller.advai.cn",
            IAM_ORIGIN: "https://prestg-accounts.advai.cn",
        },
        {
            origin: "https://prestg1-seller.advai.cn",
            IAM_ORIGIN: "https://prestg1-accounts.advai.cn",
        },
        {
            origin: "https://prestg2-seller.advai.cn",
            IAM_ORIGIN: "https://prestg2-accounts.advai.cn",
        },
        {
            origin: "https://prestg5-seller.advai.cn",
            IAM_ORIGIN: "https://prestg5-accounts.advai.cn",
        },
        {
            origin: "https://prestg6-seller.advai.cn",
            IAM_ORIGIN: "https://prestg6-accounts.advai.cn",
        },
        {
            origin: "https://seller.advai.net",
            IAM_ORIGIN: "https://accounts.advai.net",
        },
    ],
};

var WMS_CONFIG = {
    key: KEYS.WMS,
    clientId: "SYS_WMS",
    accessPath: "/login",
    origins: [
        {
            origin: "https://wms.ginee.com",
            IAM_ORIGIN: "https://accounts.ginee.com",
        },
        { origin: "https://wms.ginee.cn", IAM_ORIGIN: "https://accounts.ginee.cn" },
        {
            origin: "https://ainee-dev1-wms.advai.cn",
            IAM_ORIGIN: "https://ainee-dev1-accounts.advai.cn",
        },
        {
            origin: "https://ainee-prestg1-wms.advai.cn",
            IAM_ORIGIN: "https://ainee-prestg1-accounts.advai.cn",
        },
        {
            origin: "https://dev-wms.advai.cn",
            IAM_ORIGIN: "https://dev-accounts.advai.cn",
        },
        {
            origin: "https://dev1-wms.advai.cn",
            IAM_ORIGIN: "https://dev1-accounts.advai.cn",
        },
        {
            origin: "https://dev2-wms.advai.cn",
            IAM_ORIGIN: "https://dev2-accounts.advai.cn",
        },
        {
            origin: "https://dev3-wms.advai.cn",
            IAM_ORIGIN: "https://dev3-accounts.advai.cn",
        },
        {
            origin: "https://prestg-wms.advai.cn",
            IAM_ORIGIN: "https://prestg-accounts.advai.cn",
        },
        {
            origin: "https://prestg1-wms.advai.cn",
            IAM_ORIGIN: "https://prestg1-accounts.advai.cn",
        },
        {
            origin: "https://prestg2-wms.advai.cn",
            IAM_ORIGIN: "https://prestg2-accounts.advai.cn",
        },
        {
            origin: "https://prestg5-wms.advai.cn",
            IAM_ORIGIN: "https://prestg5-accounts.advai.cn",
        },
        {
            origin: "https://prestg6-wms.advai.cn",
            IAM_ORIGIN: "https://prestg6-accounts.advai.cn",
        },
        {
            origin: "https://wms.advai.net",
            IAM_ORIGIN: "https://accounts.advai.net",
        },
    ],
};

var LISTING_CONFIG = {
    key: KEYS.LISTING,
    clientId: "TOOLS_DATA",
    accessPath: "/login",
    origins: [
        {
            origin: "https://listing.ginee.com",
            IAM_ORIGIN: "https://accounts.ginee.com",
        },
        {
            origin: "https://ainee-dev1-listing.advai.cn",
            IAM_ORIGIN: "https://ainee-dev1-accounts.advai.cn",
        },
        {
            origin: "https://dev-tools-data.advai.cn",
            IAM_ORIGIN: "https://dev-accounts.advai.cn",
        },
        {
            origin: "https://dev1-tools-data.advai.cn",
            IAM_ORIGIN: "https://dev1-accounts.advai.cn",
        },
        {
            origin: "https://dev2-tools-data.advai.cn",
            IAM_ORIGIN: "https://dev2-accounts.advai.cn",
        },
        {
            origin: "https://dev3-tools-data.advai.cn",
            IAM_ORIGIN: "https://dev3-accounts.advai.cn",
        },
        {
            origin: "https://prestg-tools-data.advai.cn",
            IAM_ORIGIN: "https://prestg-accounts.advai.cn",
        },
        {
            origin: "https://prestg1-tools-data.advai.cn",
            IAM_ORIGIN: "https://prestg1-accounts.advai.cn",
        },
        {
            origin: "https://prestg2-tools-data.advai.cn",
            IAM_ORIGIN: "https://prestg2-accounts.advai.cn",
        },
        {
            origin: "https://prestg5-tools-data.advai.cn",
            IAM_ORIGIN: "https://prestg5-accounts.advai.cn",
        },
        {
            origin: "https://prestg6-tools-data.advai.cn",
            IAM_ORIGIN: "https://prestg6-accounts.advai.cn",
        },
        {
            origin: "https://alistag-tools-data.advai.net",
            IAM_ORIGIN: "https://accounts.advai.net",
        },
    ],
};

var PARTNER_CONFIG = {
    key: KEYS.PARTNER,
    clientId: "GENIE_IAM",
    accessPath: "/auth-list",
    origins: [
        {
            origin: "https://partner.ginee.com",
            IAM_ORIGIN: "https://accounts.ginee.com",
        },
        {
            origin: "https://partner.ginee.cn",
            IAM_ORIGIN: "https://accounts.ginee.cn",
        },
        {
            origin: "https://ainee-dev1-partner.advai.cn",
            IAM_ORIGIN: "https://ainee-dev1-accounts.advai.cn",
        },
        {
            origin: "https://ainee-prestg1-partner.advai.cn",
            IAM_ORIGIN: "https://ainee-prestg1-accounts.advai.cn",
        },
        {
            origin: "https://dev-partner.advai.cn",
            IAM_ORIGIN: "https://dev-accounts.advai.cn",
        },
        {
            origin: "https://dev1-partner.advai.cn",
            IAM_ORIGIN: "https://dev1-accounts.advai.cn",
        },
        {
            origin: "https://dev2-partner.advai.cn",
            IAM_ORIGIN: "https://dev2-accounts.advai.cn",
        },
        {
            origin: "https://dev3-partner.advai.cn",
            IAM_ORIGIN: "https://dev3-accounts.advai.cn",
        },
        {
            origin: "https://prestg-partner.advai.cn",
            IAM_ORIGIN: "https://prestg-accounts.advai.cn",
        },
        {
            origin: "https://prestg1-partner.advai.cn",
            IAM_ORIGIN: "https://prestg1-accounts.advai.cn",
        },
        {
            origin: "https://prestg2-partner.advai.cn",
            IAM_ORIGIN: "https://prestg2-accounts.advai.cn",
        },
        {
            origin: "https://prestg5-partner.advai.cn",
            IAM_ORIGIN: "https://prestg5-accounts.advai.cn",
        },
        {
            origin: "https://prestg6-partner.advai.cn",
            IAM_ORIGIN: "https://prestg6-accounts.advai.cn",
        },
        {
            origin: "https://stg-ginee-partner.advai.net",
            IAM_ORIGIN: "https://accounts.advai.net",
        },
    ],
};

var CHAT_CONFIG = {
    key: KEYS.CHAT,
    clientId: "SYS_CHAT",
    accessPath: "/",
    origins: [
        {
            origin: "https://ainee-dev1-chat.advai.cn",
            IAM_ORIGIN: "https://ainee-dev1-accounts.advai.cn",
        },
        {
            origin: "https://ainee-prestg1-chat.advai.cn",
            IAM_ORIGIN: "https://ainee-prestg1-accounts.advai.cn",
        },
        {
            origin: "https://ainee-prestg2-chat.advai.cn",
            IAM_ORIGIN: "https://ainee-prestg2-accounts.advai.cn",
        },
        {
            origin: "https://prestg5-chat.advai.cn",
            IAM_ORIGIN: "https://prestg5-accounts.advai.cn",
        },
        {
            origin: "https://chat.advai.net",
            IAM_ORIGIN: "https://accounts.advai.net",
        },
        {
            origin: "https://chat.ginee.com",
            IAM_ORIGIN: "https://accounts.ginee.com",
        },
    ],
};

var DOOR_CONFIG = {
    key: KEYS.DOOR,
    clientId: "SYS_DOOR",
    accessPath: "/",
    origins: [
        {
            origin: "https://ainee-dev1-door.advai.cn",
            IAM_ORIGIN: "https://ainee-dev1-accounts.advai.cn",
        },
        {
            origin: "https://ainee-prestg1-door.advai.cn",
            IAM_ORIGIN: "https://ainee-prestg1-accounts.advai.cn",
        },
        {
            origin: "https://ainee-prestg2-door.advai.cn",
            IAM_ORIGIN: "https://ainee-prestg2-accounts.advai.cn",
        },
        {
            origin: "https://prestg5-door.advai.cn",
            IAM_ORIGIN: "https://prestg5-accounts.advai.cn",
        },
        {
            origin: "https://door.advai.net",
            IAM_ORIGIN: "https://accounts.advai.net",
        },
        {
            origin: "https://door.ginee.com",
            IAM_ORIGIN: "https://accounts.ginee.com",
        },
    ],
};

var POS_CONFIG = {
    key: KEYS.POS,
    clientId: "SYS_POS",
    accessPath: "/",
    origins: [
        {
            origin: "https://ainee-dev1-pos.advai.cn",
            IAM_ORIGIN: "https://ainee-dev1-accounts.advai.cn",
        },
        {
            origin: "https://prestg1-pos.advai.cn",
            IAM_ORIGIN: "https://prestg1-accounts.advai.cn",
        },
        {
            origin: "https://prestg2-pos.advai.cn",
            IAM_ORIGIN: "https://prestg2-accounts.advai.cn",
        },
        {
            origin: "https://prestg5-pos.advai.cn",
            IAM_ORIGIN: "https://prestg5-accounts.advai.cn",
        },
        {
            origin: "https://pos.advai.net",
            IAM_ORIGIN: "https://accounts.advai.net",
        },
        {
            origin: "https://pos.ginee.com",
            IAM_ORIGIN: "https://accounts.ginee.com",
        },
    ],
};

var LLMX_CONFIG = {
    key: KEYS.LLMX,
    clientId: "SYS_X",
    accessPath: "/",
    origins: [
        {
            origin: "https://prestg5-ai.advai.cn",
            IAM_ORIGIN: "https://prestg5-accounts.advai.cn",
        },
        {
            origin: "https://ai.advai.net",
            IAM_ORIGIN: "https://accounts.advai.net",
        },
        {
            origin: "https://ai.ginee.com",
            IAM_ORIGIN: "https://accounts.ginee.com",
        },
    ],
};

/** 业务系统域名配置 */
const ORIGINS = [
    LLMX_CONFIG,
    ERP_CONFIG,
    WMS_CONFIG,
    LISTING_CONFIG,
    PARTNER_CONFIG,
    CHAT_CONFIG,
    DOOR_CONFIG,
    POS_CONFIG,
];

/** 域名映射 */
const ORIGIN_MAP = ORIGINS.reduce((result, { origins, key, accessPath, clientId }) => {
    origins.forEach((config) => {
        var _a, _b, _c;
        const { origin, IAM_ORIGIN } = config;
        (_a = result[IAM_ORIGIN]) !== null && _a !== void 0 ? _a : (result[IAM_ORIGIN] = {
            IAM_ORIGIN,
            TOKEN_NAME: (_b = IAM_CONFIG_MAP[IAM_ORIGIN]) === null || _b === void 0 ? void 0 : _b.TOKEN_NAME,
            INFRA_ORIGIN: (_c = IAM_CONFIG_MAP[IAM_ORIGIN]) === null || _c === void 0 ? void 0 : _c.INFRA_ORIGIN,
        });
        result[IAM_ORIGIN][`${key}_CONFIG`] = {
            ORIGIN: origin,
            ACCESS_PATH: accessPath,
            CLIENT_ID: clientId,
        };
        result[origin] = new Proxy(result[IAM_ORIGIN], {
            get(target, name) {
                if (name === "CLIENT_ID") {
                    return clientId;
                }
                return target[name];
            },
        });
    });
    return result;
}, {});

/**
 * 域名映射
 */
const OriginMap = ORIGIN_MAP;

var _a, _b;
const SESSION_HELPER_KEY = `SessionHelper_${Date.now()}`;
/** token 的 Cookie作用域名, 未匹配则为当前域名 */
const COOKIE_DOMAIN = (_a = location.origin.match(/(ginee.com)|(ginee.cn)|(advai.net)|(advai.cn)/)) === null || _a === void 0 ? void 0 : _a[0];
const { TOKEN_NAME = "_iam_token", IAM_ORIGIN = location.origin } = (_b = OriginMap[location.origin]) !== null && _b !== void 0 ? _b : {};
const USER_INFO_NAME = TOKEN_NAME.replace("token", "user");
/** 跳转到IAM登录页不删除token */
const toLoginPage = () => {
    const oURL = new URL(`${IAM_ORIGIN}/`);
    oURL.searchParams.set("system_id", "GENIE_IAM");
    location.href = oURL.toString();
};
/** 打开到IAM登录页 */
const toAccountLoginPageOpen = () => {
    api.remove(TOKEN_NAME, { domain: COOKIE_DOMAIN });
    const oURL = new URL(`${IAM_ORIGIN}/`);
    oURL.searchParams.set("system_id", "GENIE_IAM");
    window.open(oURL.toString());
};

var en = {
    prompt: "Prompt",
    theCurrentUserHasLoggedOut: "The current user has logged out of other tabs;If you encounter problems loading, please refresh the page manually.",
    theCurrentLoginSessionHasExpired: "The current login session has expired;If you encounter problems loading, please refresh the page manually.",
    logBackIn: "Log back in",
    newOpenLogin: "New Window Login",
    theCurrentLoginSessionIsInvalid: "The current login session is invalid;If you encounter problems loading, please refresh the page manually.",
    theCurrentSessionUserIdentityHas: "The current session user identity has changed;If you encounter problems loading, please refresh the page manually.",
    theCurrentUserIsLoggedBack: "The current user is logged back in;If you encounter problems loading, please refresh the page manually.",
    cancel: "Cancel",
    reloadThePage: "Reload the page",
    confirm: "Confirm",
};

var zh = {
    prompt: "提示",
    theCurrentUserHasLoggedOut: "当前用户已在其他标签页退出登录；如果加载遇到问题，请手动刷新页面。",
    theCurrentLoginSessionHasExpired: "当前登录会话已过期；如果加载遇到问题，请手动刷新页面。",
    logBackIn: "重新登录",
    newOpenLogin: "新窗口登录",
    theCurrentLoginSessionIsInvalid: "无效的登录会话；如果加载遇到问题，请手动刷新页面。",
    theCurrentSessionUserIdentityHas: "当前会话用户身份已变更；如果加载遇到问题，请手动刷新页面。",
    theCurrentUserIsLoggedBack: "当前用户已重新登录；如果加载遇到问题，请手动刷新页面。",
    cancel: "取消",
    confirm: "确定",
    reloadThePage: "刷新页面",
};

var id = {
    prompt: "Cepat",
    theCurrentUserHasLoggedOut: "Pengguna saat ini telah log keluar dari tab lain;Jika Anda mengalami masalah saat memuat, harap segarkan halaman secara manual.",
    theCurrentLoginSessionHasExpired: "Sesi daftar masuk saat ini sudah habis;Jika Anda mengalami masalah saat memuat, harap segarkan halaman secara manual.",
    logBackIn: "Masuk kembali",
    newOpenLogin: "Masuk di jendela baru",
    theCurrentLoginSessionIsInvalid: "Sesi daftar masuk saat ini tidak valid;Jika Anda mengalami masalah saat memuat, harap segarkan halaman secara manual.",
    theCurrentSessionUserIdentityHas: "Identitas pengguna sesi saat ini telah berubah;Jika Anda mengalami masalah saat memuat, harap segarkan halaman secara manual.",
    theCurrentUserIsLoggedBack: "Pengguna saat ini masuk kembali;Jika Anda mengalami masalah saat memuat, harap segarkan halaman secara manual.",
    cancel: "Batalkan",
    reloadThePage: "Muat ulang halaman",
    confirm: "Mengonfirmasi",
};

var vi = {
    prompt: "Gợi ý",
    theCurrentUserHasLoggedOut: "Người dùng hiện tại đã thoát đăng nhập trên tab khác;Nếu bạn gặp sự cố khi tải, vui lòng làm mới trang theo cách thủ công.",
    theCurrentLoginSessionHasExpired: "Phiên đăng nhập hiện tại đã hết hạn;Nếu bạn gặp sự cố khi tải, vui lòng làm mới trang theo cách thủ công.",
    logBackIn: "Đăng nhập lại",
    newOpenLogin: "Đăng nhập cửa sổ mới",
    theCurrentLoginSessionIsInvalid: "Phiên chạy đăng nhập hiện thời không hợp lệ;Nếu bạn gặp sự cố khi tải, vui lòng làm mới trang theo cách thủ công.",
    theCurrentSessionUserIdentityHas: "Danh tính người dùng phiên hiện tại đã thay đổi;Nếu bạn gặp sự cố khi tải, vui lòng làm mới trang theo cách thủ công.",
    theCurrentUserIsLoggedBack: "Người dùng hiện thời đã đăng nhập lại;Nếu bạn gặp sự cố khi tải, vui lòng làm mới trang theo cách thủ công.",
    cancel: "Hủy",
    reloadThePage: "Quá tải trang",
    confirm: "Xác nhận",
};

var th = {
    prompt: "พร้อมท์",
    theCurrentUserHasLoggedOut: "ผู้ใช้ปัจจุบันได้ออกจากการเข้าสู่ระบบในหน้าแท็กอื่น ๆ;หากคุณพบปัญหาในการโหลด โปรดรีเฟรชหน้าด้วยตนเอง",
    theCurrentLoginSessionHasExpired: "วาระการเข้าสู่ระบบปัจจุบันหมดอายุแล้ว;หากคุณพบปัญหาในการโหลด โปรดรีเฟรชหน้าด้วยตนเอง",
    logBackIn: "กลับเข้าสู่ระบบ",
    newOpenLogin: "เข้าสู่ระบบหน้าต่างใหม่",
    theCurrentLoginSessionIsInvalid: "เซสชันการเข้าสู่ระบบปัจจุบันไม่ถูกต้อง;หากคุณพบปัญหาในการโหลด โปรดรีเฟรชหน้าด้วยตนเอง",
    theCurrentSessionUserIdentityHas: "ข้อมูลประจําตัวผู้ใช้เซสชันปัจจุบันมีการเปลี่ยนแปลง;หากคุณพบปัญหาในการโหลด โปรดรีเฟรชหน้าด้วยตนเอง",
    theCurrentUserIsLoggedBack: "ผู้ใช้ปัจจุบันถูกล็อกอินกลับเข้ามาใหม่;หากคุณพบปัญหาในการโหลด โปรดรีเฟรชหน้าด้วยตนเอง",
    cancel: "ยกเลิก",
    reloadThePage: "โหลดหน้าเว็บซ้ํา",
    confirm: "ยืนยัน",
};

var locales = { en, zh, id, vi, th };

var __assign = (undefined && undefined.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var getTrackingCid = function () {
    return window.localStorage.getItem("tracking_cid");
};
var eventLogger = {
    push: function (params) {
        var autoSourceInfo = true;
        return eventLogger$1.push(customMerge({ applicationName: "GENIE_IAM" }, params), autoSourceInfo);
    },
    pushOld: function (params) {
        if (typeof params.extra === "string") {
            params.extra = oldExtraStr2Json(params.extra);
        }
        var autoSourceInfo = true;
        return eventLogger$1.pushOld(customMerge({
            applicationName: "GENIE_IAM",
            extra: __assign({ cid: getTrackingCid() }, params.extra),
        }, params), autoSourceInfo);
    },
};

const intl = (key, language = "id") => {
    var _a;
    // @ts-ignore
    return (_a = locales[language]) === null || _a === void 0 ? void 0 : _a[key];
};
let getCompatibleToken;
let errorTimeOut = null;
let optionsMap = null;
/**
 * 登录会话相关工具
 * 监听会话事件、触发会话事件、提供设置/获取token方法
 */
const SessionHelper = {
    /** 安装通信桥, 将会在页面挂载iframe框架, 用于跨域名通信 */
    installBridge(options) {
        var _a;
        console.log("InstallBridge Init Success");
        if (document.getElementById("iam-bridge")) {
            return;
        }
        optionsMap = options;
        if (!SessionHelper.getSessionToken() &&
            window.location.origin !== IAM_ORIGIN) {
            if (options === null || options === void 0 ? void 0 : options.toAccountLoginPage) {
                (_a = options === null || options === void 0 ? void 0 : options.toAccountLoginPage) === null || _a === void 0 ? void 0 : _a.call(options);
            }
            else {
                toLoginPage();
            }
        }
        const bridge = document.createElement("iframe");
        bridge.id = "iam-bridge";
        bridge.src = `${IAM_ORIGIN}/bridge.html`;
        bridge.style.display = "none";
        document.body.append(bridge);
        console.log("InstallBridge Create Success");
        frames.addEventListener("message", (event) => {
            var _a, _b;
            /* 过滤来源 */
            if (event.source !== (bridge === null || bridge === void 0 ? void 0 : bridge.contentWindow) ||
                !((_b = (_a = event.data) === null || _a === void 0 ? void 0 : _a.key) === null || _b === void 0 ? void 0 : _b.includes("SessionHelper_"))) {
                return;
            }
            switch (event.data.action) {
                case "re-login": // 重新登录
                    // Message.open(intl("theCurrentUserIsLoggedBack", event.data.language));
                    Modal.open({
                        title: intl("prompt", event.data.language),
                        timestamp: Date().toLocaleString(),
                        content: intl("theCurrentUserIsLoggedBack", event.data.language),
                        okText: intl("cancel", event.data.language),
                        openText: intl("reloadThePage", event.data.language),
                        showCancelButton: false,
                        showOpenButton: true,
                        onOpen: () => {
                            var _a;
                            let token = SessionHelper.getSessionToken();
                            if (token) {
                                window.location.reload();
                            }
                            else {
                                if (options === null || options === void 0 ? void 0 : options.toAccountLoginPage) {
                                    (_a = options === null || options === void 0 ? void 0 : options.toAccountLoginPage) === null || _a === void 0 ? void 0 : _a.call(options);
                                }
                                else {
                                    toLoginPage();
                                }
                            }
                        },
                        onOk: () => {
                            Modal.destroy();
                        },
                    });
                    break;
                case "logout": // 退出登录
                    Modal.open({
                        title: intl("prompt", event.data.language),
                        content: intl("theCurrentUserHasLoggedOut", event.data.language),
                        okText: intl("newOpenLogin", event.data.language),
                        cancelText: intl("cancel", event.data.language),
                        timestamp: Date().toLocaleString(),
                        onOk: () => {
                            var _a;
                            if (options === null || options === void 0 ? void 0 : options.toAccountLoginPageOpen) {
                                (_a = options === null || options === void 0 ? void 0 : options.toAccountLoginPageOpen) === null || _a === void 0 ? void 0 : _a.call(options);
                                return;
                            }
                            toAccountLoginPageOpen();
                        },
                        onCancel: () => {
                            Modal.destroy();
                        },
                    });
                    break;
                case "session-expired": // 登录会话已过期
                    try {
                        if (parent.document) {
                            console.log("parent.document", parent.document);
                        }
                        Modal.open({
                            title: intl("prompt", event.data.language),
                            content: intl("theCurrentLoginSessionHasExpired", event.data.language),
                            timestamp: Date().toLocaleString(),
                            okText: intl("cancel", event.data.language),
                            openText: intl("newOpenLogin", event.data.language),
                            showCancelButton: false,
                            showOpenButton: true,
                            onOpen: () => {
                                var _a;
                                if (options === null || options === void 0 ? void 0 : options.toAccountLoginPageOpen) {
                                    (_a = options === null || options === void 0 ? void 0 : options.toAccountLoginPageOpen) === null || _a === void 0 ? void 0 : _a.call(options);
                                    return;
                                }
                                toAccountLoginPageOpen();
                            },
                            onOk: () => {
                                // const token = SessionHelper.getSessionToken();
                                // if (token) {
                                //   window.location.reload();
                                // } else {
                                //   toLoginPage();
                                // }
                                Modal.destroy();
                            },
                        });
                    }
                    catch (e) { }
                    break;
                case "session-invalid": // 登录会话已失效
                    try {
                        if (parent.document) {
                            console.log("parent.document", parent.document);
                        }
                        Modal.open({
                            title: intl("prompt", event.data.language),
                            content: intl("theCurrentLoginSessionIsInvalid", event.data.language),
                            timestamp: Date().toLocaleString(),
                            cancelText: intl("cancel", event.data.language),
                            okText: intl("newOpenLogin", event.data.language),
                            onCancel: () => {
                                Modal.destroy();
                            },
                            onOk: () => {
                                var _a;
                                if (options === null || options === void 0 ? void 0 : options.toAccountLoginPageOpen) {
                                    (_a = options === null || options === void 0 ? void 0 : options.toAccountLoginPageOpen) === null || _a === void 0 ? void 0 : _a.call(options);
                                }
                                else {
                                    toAccountLoginPageOpen();
                                }
                            },
                        });
                    }
                    catch (e) { }
                    break;
                case "session-failed": // 登录会话退出登录
                    try {
                        if (parent.document) {
                            console.log("parent.document", parent.document);
                        }
                        Modal.open({
                            title: intl("prompt", event.data.language),
                            content: intl("theCurrentUserHasLoggedOut", event.data.language),
                            timestamp: Date().toLocaleString(),
                            cancelText: intl("cancel", event.data.language),
                            okText: intl("newOpenLogin", event.data.language),
                            onCancel: () => {
                                Modal.destroy();
                            },
                            onOk: () => {
                                var _a;
                                if (options === null || options === void 0 ? void 0 : options.toAccountLoginPageOpen) {
                                    (_a = options === null || options === void 0 ? void 0 : options.toAccountLoginPageOpen) === null || _a === void 0 ? void 0 : _a.call(options);
                                }
                                else {
                                    toAccountLoginPageOpen();
                                }
                            },
                        });
                    }
                    catch (e) { }
                    break;
                case "user-change": // 用户身份已变更
                    Modal.open({
                        title: intl("prompt", event.data.language),
                        content: intl("theCurrentSessionUserIdentityHas", event.data.language),
                        timestamp: Date().toLocaleString(),
                        okText: intl("reloadThePage", event.data.language),
                        cancelText: intl("cancel", event.data.language),
                        showCancelButton: true,
                        onOk: () => {
                            window.location.reload();
                        },
                        onCancel: () => {
                            Modal.destroy();
                        },
                    });
                    break;
            }
        });
    },
    /** 系统访问记录埋点, 依据LocalStorage[_iam_system_visit_tag], 一次会话内只触发一次埋点 */
    recordSystemAccessEventLog(systemId) {
        const token = SessionHelper.getSessionToken();
        const tokenSlice = token === null || token === void 0 ? void 0 : token.slice(-64);
        const latestTokenSlice = localStorage.getItem("_iam_system_visit_tag");
        if (tokenSlice && latestTokenSlice !== tokenSlice) {
            eventLogger.push({
                eventAction: "VisitSuccess",
                eventLocation: "VisitSubSystem",
                eventExtra: {
                    actionExtra: {
                        systemId,
                    },
                },
            });
            localStorage.setItem("_iam_system_visit_tag", tokenSlice);
        }
    },
    /** 获取权限列表 */
    getPermissionList(systemId) {
        return new Promise((resolve, reject) => {
            var _a, _b, _c, _d;
            const origin = (_b = (_a = OriginMap[window.location.origin]) === null || _a === void 0 ? void 0 : _a.INFRA_ORIGIN) !== null && _b !== void 0 ? _b : window.location.origin;
            fetch(`${origin}/infra/iam/account/authority/feature/list?systemId=${systemId}`, {
                headers: {
                    accept: "application/json, text/plain, */*",
                    "accept-language": (_c = SessionHelper.getLanguage()) !== null && _c !== void 0 ? _c : "en",
                    authorization: (_d = SessionHelper.getSessionToken()) !== null && _d !== void 0 ? _d : "",
                    "cache-control": "no-cache",
                    "content-type": "application/json",
                },
                method: "GET",
                mode: "cors",
                credentials: "include",
            })
                .then((res) => res.json())
                .then((res) => {
                var _a;
                if (res.code === "SUCCESS") {
                    if (systemId === "SYS_WMS") {
                        let dataMapList = [];
                        dataMapList = res.data.map((info) => {
                            return info.identifier;
                        });
                        resolve(dataMapList);
                    }
                    else {
                        resolve((_a = res.data) === null || _a === void 0 ? void 0 : _a.map((info) => info.identifier));
                    }
                }
                else {
                    reject(Object.assign(Object.assign({}, res), { response: { data: res } }));
                }
            })
                .catch((e) => {
                reject(e);
            });
        });
    },
    /** 获取权限数列表  1只读，2读+操作*/
    getPermissionMap(systemId) {
        return new Promise((resolve, reject) => {
            var _a, _b, _c, _d;
            const origin = (_b = (_a = OriginMap[window.location.origin]) === null || _a === void 0 ? void 0 : _a.INFRA_ORIGIN) !== null && _b !== void 0 ? _b : window.location.origin;
            fetch(`${origin}/infra/iam/account/authority/feature/list?systemId=${systemId}`, {
                headers: {
                    accept: "application/json, text/plain, */*",
                    "accept-language": (_c = SessionHelper.getLanguage()) !== null && _c !== void 0 ? _c : "en",
                    authorization: (_d = SessionHelper.getSessionToken()) !== null && _d !== void 0 ? _d : "",
                    "cache-control": "no-cache",
                    "content-type": "application/json",
                },
                method: "GET",
                mode: "cors",
                credentials: "include",
            })
                .then((res) => res.json())
                .then((res) => {
                if (res.code === "SUCCESS") {
                    let dataMapList = {};
                    res.data.forEach((info) => {
                        if (info.permissionScope === "READ_WRITE") {
                            dataMapList[info.identifier] = 2;
                        }
                        else {
                            dataMapList[info.identifier] = 1;
                        }
                    });
                    resolve(dataMapList);
                }
                else {
                    reject(Object.assign(Object.assign({}, res), { response: { data: res } }));
                }
            })
                .catch((e) => {
                reject(e);
            });
        });
    },
    /** 获取权限Name数列表  1只读，2读+操作*/
    getPermissionNameMap(systemId) {
        return new Promise((resolve, reject) => {
            var _a, _b, _c, _d;
            const origin = (_b = (_a = OriginMap[window.location.origin]) === null || _a === void 0 ? void 0 : _a.INFRA_ORIGIN) !== null && _b !== void 0 ? _b : window.location.origin;
            fetch(`${origin}/infra/iam/account/authority/feature/list?systemId=${systemId}`, {
                headers: {
                    accept: "application/json, text/plain, */*",
                    "accept-language": (_c = SessionHelper.getLanguage()) !== null && _c !== void 0 ? _c : "en",
                    authorization: (_d = SessionHelper.getSessionToken()) !== null && _d !== void 0 ? _d : "",
                    "cache-control": "no-cache",
                    "content-type": "application/json",
                },
                method: "GET",
                mode: "cors",
                credentials: "include",
            })
                .then((res) => res.json())
                .then((res) => {
                if (res.code === "SUCCESS") {
                    let dataMapList = {};
                    res.data.forEach((info) => {
                        if (info.permissionScope === "READ_WRITE") {
                            dataMapList[info.name] = 2;
                        }
                        else {
                            dataMapList[info.name] = 1;
                        }
                    });
                    resolve(dataMapList);
                }
                else {
                    reject(Object.assign(Object.assign({}, res), { response: { data: res } }));
                }
            })
                .catch((e) => {
                reject(e);
            });
        });
    },
    /** 卸载事件监听 */
    uninstallBridge() {
        const bridge = document.getElementById("iam-bridge");
        if (bridge) {
            bridge.parentNode.removeChild(bridge);
        }
    },
    //  token无效时发送code即可，由插件处理错误 "IAM_FAILED" |
    sendDefaultEvent(actionCode) {
        const sendMap = {
            IAM_LOGOUT: "session-failed",
            TOKEN_INVALID: "session-invalid",
            TOKEN_EXPIRED: "session-expired",
        };
        // token过期及无效的情况，需要删除token，不需要业务系统处理，插件接管
        if (errorTimeOut) {
            clearTimeout(errorTimeOut);
        }
        errorTimeOut = setTimeout(() => {
            SessionHelper.removeSessionToken();
        }, 1000);
        SessionHelper.sendEvent(sendMap[actionCode]);
    },
    /** 发送事件 */
    sendEvent(action) {
        var _a, _b;
        const language = localStorage.getItem("language") || "id";
        const bridge = document.querySelector("#iam-bridge");
        switch (action) {
            case "session-expired": // 登录会话已过期
                Modal.open({
                    title: intl("prompt", language),
                    content: intl("theCurrentLoginSessionHasExpired", language),
                    timestamp: Date().toLocaleString(),
                    okText: intl("cancel", language),
                    openText: intl("newOpenLogin", language),
                    showCancelButton: false,
                    showOpenButton: true,
                    onOpen: () => {
                        var _a;
                        if (optionsMap === null || optionsMap === void 0 ? void 0 : optionsMap.toAccountLoginPageOpen) {
                            (_a = optionsMap === null || optionsMap === void 0 ? void 0 : optionsMap.toAccountLoginPageOpen) === null || _a === void 0 ? void 0 : _a.call(optionsMap);
                        }
                        else {
                            toAccountLoginPageOpen();
                        }
                    },
                    onOk: () => {
                        Modal.destroy();
                    },
                });
                break;
            case "session-invalid": // 登录会话已失效
                try {
                    if (parent.document) {
                        console.log("parent.document", parent.document);
                    }
                    Modal.open({
                        title: intl("prompt", language),
                        content: intl("theCurrentLoginSessionIsInvalid", language),
                        timestamp: Date().toLocaleString(),
                        cancelText: intl("cancel", language),
                        okText: intl("newOpenLogin", language),
                        onCancel: () => {
                            Modal.destroy();
                        },
                        onOk: () => {
                            var _a;
                            if (optionsMap === null || optionsMap === void 0 ? void 0 : optionsMap.toAccountLoginPageOpen) {
                                (_a = optionsMap === null || optionsMap === void 0 ? void 0 : optionsMap.toAccountLoginPageOpen) === null || _a === void 0 ? void 0 : _a.call(optionsMap);
                            }
                            else {
                                toAccountLoginPageOpen();
                            }
                        },
                    });
                }
                catch (e) { }
                break;
            case "session-failed": // 登录会话已失效
                try {
                    if (parent.document) {
                        console.log("parent.document", parent.document);
                    }
                    Modal.open({
                        title: intl("prompt", language),
                        content: intl("theCurrentUserHasLoggedOut", language),
                        timestamp: Date().toLocaleString(),
                        cancelText: intl("cancel", language),
                        okText: intl("newOpenLogin", language),
                        onCancel: () => {
                            Modal.destroy();
                        },
                        onOk: () => {
                            var _a;
                            let token = SessionHelper.getSessionToken();
                            if (token) {
                                window.location.reload();
                            }
                            else {
                                if (optionsMap === null || optionsMap === void 0 ? void 0 : optionsMap.toAccountLoginPageOpen) {
                                    (_a = optionsMap === null || optionsMap === void 0 ? void 0 : optionsMap.toAccountLoginPageOpen) === null || _a === void 0 ? void 0 : _a.call(optionsMap);
                                }
                                else {
                                    toAccountLoginPageOpen();
                                }
                            }
                        },
                    });
                }
                catch (e) { }
                break;
        }
        if (bridge) {
            (_a = bridge.contentWindow) === null || _a === void 0 ? void 0 : _a.postMessage({ key: SESSION_HELPER_KEY, action, timestamp: Date.now() }, IAM_ORIGIN);
            return;
        }
        if (window.location.origin === IAM_ORIGIN) {
            const bc = new BroadcastChannel("Ginee Bridge");
            const language = localStorage.getItem("language") || "en";
            bc.postMessage({
                key: SESSION_HELPER_KEY,
                action,
                timestamp: Date.now(),
                language: (_b = { vn: "vi" }[language]) !== null && _b !== void 0 ? _b : language,
            });
        }
    },
    /** 设置IAM语言 */
    setLanguage(language) {
        var _a;
        const userInfo = this.getSessionUserInfo();
        if (userInfo) {
            this.setSessionUserInfo(Object.assign(Object.assign({}, userInfo), { language }));
        }
        const bridge = document.getElementById("iam-bridge");
        if (bridge) {
            (_a = bridge.contentWindow) === null || _a === void 0 ? void 0 : _a.postMessage({ ["set-language"]: language }, "*");
            return;
        }
        if (window.location.origin === IAM_ORIGIN) {
            localStorage.setItem("language", language);
        }
    },
    /** 获取IAM语言 */
    getLanguage() {
        const userInfo = this.getSessionUserInfo();
        return userInfo === null || userInfo === void 0 ? void 0 : userInfo.language;
    },
    /**
     *  使用兼容的token
     *  @description 为了避免因为接入新的IAM token, 导致用户token失效, 使用该方法返回旧的token
     *  @description 该函数将在未来版本删除
     */
    setCompatibleToken(_getCompatibleToken) {
        getCompatibleToken = _getCompatibleToken;
    },
    /** 设置 token */
    setSessionToken(token) {
        api.set(TOKEN_NAME, token, { domain: COOKIE_DOMAIN, expires: 30 });
    },
    /** 获取 token */
    getSessionToken(prefix = true) {
        var _a;
        let token = api.get(TOKEN_NAME);
        if (!token && getCompatibleToken) {
            const compatibleToken = (_a = getCompatibleToken === null || getCompatibleToken === void 0 ? void 0 : getCompatibleToken()) !== null && _a !== void 0 ? _a : undefined;
            token = compatibleToken === null || compatibleToken === void 0 ? void 0 : compatibleToken.replace(/^Bearer /, "");
        }
        if (!prefix) {
            return token;
        }
        if (token) {
            return `Bearer ${token}`;
        }
        return undefined;
    },
    /** 移除 token */
    removeSessionToken() {
        api.remove(TOKEN_NAME, { domain: COOKIE_DOMAIN });
    },
    /** 设置 用户基本信息 */
    setSessionUserInfo(userInfo) {
        const userInfoJSON = JSON.stringify({
            id: userInfo.id,
            username: userInfo.username,
            email: userInfo.email,
            managementRole: userInfo.managementRole,
            parentId: userInfo.parentId,
            phone: userInfo.phone,
            emailVerification: userInfo.emailVerification,
            phoneVerification: userInfo.phoneVerification,
            avatar: userInfo.avatar,
            language: userInfo.language,
            country: userInfo.country,
        });
        const userInfoBase64 = window.btoa(encodeURIComponent(userInfoJSON));
        api.set(USER_INFO_NAME, userInfoBase64, {
            domain: COOKIE_DOMAIN,
            expires: 30,
        });
    },
    /** 获取 用户基本信息 */
    getSessionUserInfo() {
        const userInfoBase64 = api.get(USER_INFO_NAME);
        if (!userInfoBase64) {
            return null;
        }
        const userInfoJSON = decodeURIComponent(window.atob(userInfoBase64));
        try {
            const userInfo = JSON.parse(userInfoJSON);
            return Object.assign(Object.assign({}, userInfo), { userId: userInfo.id });
        }
        catch (error) {
            console.error("[SessionHelper]: 用户基本信息JSON解析异常!");
            console.error("[SessionHelper]: userInfoJSON:", userInfoJSON);
            console.error(error);
            this.removeSessionUserInfo();
            return null;
        }
    },
    /** 移除 用户基本信息 */
    removeSessionUserInfo() {
        api.remove(USER_INFO_NAME, { domain: COOKIE_DOMAIN });
    },
};
var SessionHelper$1 = Object.freeze(SessionHelper);

export { KEYS as K, OriginMap as O, SessionHelper$1 as S };
