import './common/_commonjsHelpers-64249c85.js';
import { r as react } from './common/index-ae8bfcfb.js';
import { d as __spreadArray, _ as __read, c as __assign, g as __awaiter, h as __generator, e as __rest, f as __values } from './common/tslib.es6-a8e14131.js';
import { a as useMemoizedFn, u as useUpdate } from './common/index-26ab94a9.js';
export { u as useUpdate } from './common/index-26ab94a9.js';
import { i as isObject_1 } from './common/isSymbol-c0accfbe.js';
import './common/toNumber-b0c27a55.js';
import { d as debounce_1 } from './common/debounce-0141acbc.js';
import { d as dayjs_min } from './common/dayjs.min-54cceee3.js';
import './common/_getTag-7fad8ff9.js';
import './common/_baseIsEqual-ceaeef46.js';
import { i as isEqual_1 } from './common/isEqual-368f17f6.js';
import './common/polyfill-node:global-21e5c503.js';
import { i as index$1 } from './common/ResizeObserver.es-1fcba131.js';

var createUpdateEffect = function (hook) {
  return function (effect, deps) {
    var isMounted = react.useRef(false);
    // for react-refresh
    hook(function () {
      return function () {
        isMounted.current = false;
      };
    }, []);
    hook(function () {
      if (!isMounted.current) {
        isMounted.current = true;
      } else {
        return effect();
      }
    }, deps);
  };
};

var isObject = function (value) {
  return value !== null && typeof value === 'object';
};
var isFunction = function (value) {
  return typeof value === 'function';
};
var isString = function (value) {
  return typeof value === 'string';
};
var isNumber = function (value) {
  return typeof value === 'number';
};

var useUpdateEffect = createUpdateEffect(react.useEffect);

// support refreshDeps & ready
var useAutoRunPlugin = function (fetchInstance, _a) {
  var manual = _a.manual,
    _b = _a.ready,
    ready = _b === void 0 ? true : _b,
    _c = _a.defaultParams,
    defaultParams = _c === void 0 ? [] : _c,
    _d = _a.refreshDeps,
    refreshDeps = _d === void 0 ? [] : _d,
    refreshDepsAction = _a.refreshDepsAction;
  var hasAutoRun = react.useRef(false);
  hasAutoRun.current = false;
  useUpdateEffect(function () {
    if (!manual && ready) {
      hasAutoRun.current = true;
      fetchInstance.run.apply(fetchInstance, __spreadArray([], __read(defaultParams), false));
    }
  }, [ready]);
  useUpdateEffect(function () {
    if (hasAutoRun.current) {
      return;
    }
    if (!manual) {
      hasAutoRun.current = true;
      if (refreshDepsAction) {
        refreshDepsAction();
      } else {
        fetchInstance.refresh();
      }
    }
  }, __spreadArray([], __read(refreshDeps), false));
  return {
    onBefore: function () {
      if (!ready) {
        return {
          stopNow: true
        };
      }
    }
  };
};
useAutoRunPlugin.onInit = function (_a) {
  var _b = _a.ready,
    ready = _b === void 0 ? true : _b,
    manual = _a.manual;
  return {
    loading: !manual && ready
  };
};

function depsAreSame(oldDeps, deps) {
  if (oldDeps === deps) return true;
  for (var i = 0; i < oldDeps.length; i++) {
    if (!Object.is(oldDeps[i], deps[i])) return false;
  }
  return true;
}

function useCreation(factory, deps) {
  var current = react.useRef({
    deps: deps,
    obj: undefined,
    initialized: false
  }).current;
  if (current.initialized === false || !depsAreSame(current.deps, deps)) {
    current.deps = deps;
    current.obj = factory();
    current.initialized = true;
  }
  return current.obj;
}

function useLatest(value) {
  var ref = react.useRef(value);
  ref.current = value;
  return ref;
}

var useUnmount = function (fn) {
  var fnRef = useLatest(fn);
  react.useEffect(function () {
    return function () {
      fnRef.current();
    };
  }, []);
};

var cache = new Map();
var setCache = function (key, cacheTime, cachedData) {
  var currentCache = cache.get(key);
  if (currentCache === null || currentCache === void 0 ? void 0 : currentCache.timer) {
    clearTimeout(currentCache.timer);
  }
  var timer = undefined;
  if (cacheTime > -1) {
    // if cache out, clear it
    timer = setTimeout(function () {
      cache.delete(key);
    }, cacheTime);
  }
  cache.set(key, __assign(__assign({}, cachedData), {
    timer: timer
  }));
};
var getCache = function (key) {
  return cache.get(key);
};
var clearCache = function (key) {
  if (key) {
    var cacheKeys = Array.isArray(key) ? key : [key];
    cacheKeys.forEach(function (cacheKey) {
      return cache.delete(cacheKey);
    });
  } else {
    cache.clear();
  }
};

var cachePromise = new Map();
var getCachePromise = function (cacheKey) {
  return cachePromise.get(cacheKey);
};
var setCachePromise = function (cacheKey, promise) {
  // Should cache the same promise, cannot be promise.finally
  // Because the promise.finally will change the reference of the promise
  cachePromise.set(cacheKey, promise);
  // no use promise.finally for compatibility
  promise.then(function (res) {
    cachePromise.delete(cacheKey);
    return res;
  }).catch(function () {
    cachePromise.delete(cacheKey);
  });
};

var listeners = {};
var trigger = function (key, data) {
  if (listeners[key]) {
    listeners[key].forEach(function (item) {
      return item(data);
    });
  }
};
var subscribe = function (key, listener) {
  if (!listeners[key]) {
    listeners[key] = [];
  }
  listeners[key].push(listener);
  return function unsubscribe() {
    var index = listeners[key].indexOf(listener);
    listeners[key].splice(index, 1);
  };
};

var useCachePlugin = function (fetchInstance, _a) {
  var cacheKey = _a.cacheKey,
    _b = _a.cacheTime,
    cacheTime = _b === void 0 ? 5 * 60 * 1000 : _b,
    _c = _a.staleTime,
    staleTime = _c === void 0 ? 0 : _c,
    customSetCache = _a.setCache,
    customGetCache = _a.getCache;
  var unSubscribeRef = react.useRef();
  var currentPromiseRef = react.useRef();
  var _setCache = function (key, cachedData) {
    if (customSetCache) {
      customSetCache(cachedData);
    } else {
      setCache(key, cacheTime, cachedData);
    }
    trigger(key, cachedData.data);
  };
  var _getCache = function (key, params) {
    if (params === void 0) {
      params = [];
    }
    if (customGetCache) {
      return customGetCache(params);
    }
    return getCache(key);
  };
  useCreation(function () {
    if (!cacheKey) {
      return;
    }
    // get data from cache when init
    var cacheData = _getCache(cacheKey);
    if (cacheData && Object.hasOwnProperty.call(cacheData, 'data')) {
      fetchInstance.state.data = cacheData.data;
      fetchInstance.state.params = cacheData.params;
      if (staleTime === -1 || new Date().getTime() - cacheData.time <= staleTime) {
        fetchInstance.state.loading = false;
      }
    }
    // subscribe same cachekey update, trigger update
    unSubscribeRef.current = subscribe(cacheKey, function (data) {
      fetchInstance.setState({
        data: data
      });
    });
  }, []);
  useUnmount(function () {
    var _a;
    (_a = unSubscribeRef.current) === null || _a === void 0 ? void 0 : _a.call(unSubscribeRef);
  });
  if (!cacheKey) {
    return {};
  }
  return {
    onBefore: function (params) {
      var cacheData = _getCache(cacheKey, params);
      if (!cacheData || !Object.hasOwnProperty.call(cacheData, 'data')) {
        return {};
      }
      // If the data is fresh, stop request
      if (staleTime === -1 || new Date().getTime() - cacheData.time <= staleTime) {
        return {
          loading: false,
          data: cacheData === null || cacheData === void 0 ? void 0 : cacheData.data,
          error: undefined,
          returnNow: true
        };
      } else {
        // If the data is stale, return data, and request continue
        return {
          data: cacheData === null || cacheData === void 0 ? void 0 : cacheData.data,
          error: undefined
        };
      }
    },
    onRequest: function (service, args) {
      var servicePromise = getCachePromise(cacheKey);
      // If has servicePromise, and is not trigger by self, then use it
      if (servicePromise && servicePromise !== currentPromiseRef.current) {
        return {
          servicePromise: servicePromise
        };
      }
      servicePromise = service.apply(void 0, __spreadArray([], __read(args), false));
      currentPromiseRef.current = servicePromise;
      setCachePromise(cacheKey, servicePromise);
      return {
        servicePromise: servicePromise
      };
    },
    onSuccess: function (data, params) {
      var _a;
      if (cacheKey) {
        // cancel subscribe, avoid trgger self
        (_a = unSubscribeRef.current) === null || _a === void 0 ? void 0 : _a.call(unSubscribeRef);
        _setCache(cacheKey, {
          data: data,
          params: params,
          time: new Date().getTime()
        });
        // resubscribe
        unSubscribeRef.current = subscribe(cacheKey, function (d) {
          fetchInstance.setState({
            data: d
          });
        });
      }
    },
    onMutate: function (data) {
      var _a;
      if (cacheKey) {
        // cancel subscribe, avoid trigger self
        (_a = unSubscribeRef.current) === null || _a === void 0 ? void 0 : _a.call(unSubscribeRef);
        _setCache(cacheKey, {
          data: data,
          params: fetchInstance.state.params,
          time: new Date().getTime()
        });
        // resubscribe
        unSubscribeRef.current = subscribe(cacheKey, function (d) {
          fetchInstance.setState({
            data: d
          });
        });
      }
    }
  };
};

var useDebouncePlugin = function (fetchInstance, _a) {
  var debounceWait = _a.debounceWait,
    debounceLeading = _a.debounceLeading,
    debounceTrailing = _a.debounceTrailing,
    debounceMaxWait = _a.debounceMaxWait;
  var debouncedRef = react.useRef();
  var options = react.useMemo(function () {
    var ret = {};
    if (debounceLeading !== undefined) {
      ret.leading = debounceLeading;
    }
    if (debounceTrailing !== undefined) {
      ret.trailing = debounceTrailing;
    }
    if (debounceMaxWait !== undefined) {
      ret.maxWait = debounceMaxWait;
    }
    return ret;
  }, [debounceLeading, debounceTrailing, debounceMaxWait]);
  react.useEffect(function () {
    if (debounceWait) {
      var _originRunAsync_1 = fetchInstance.runAsync.bind(fetchInstance);
      debouncedRef.current = debounce_1(function (callback) {
        callback();
      }, debounceWait, options);
      // debounce runAsync should be promise
      // https://github.com/lodash/lodash/issues/4400#issuecomment-834800398
      fetchInstance.runAsync = function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
          args[_i] = arguments[_i];
        }
        return new Promise(function (resolve, reject) {
          var _a;
          (_a = debouncedRef.current) === null || _a === void 0 ? void 0 : _a.call(debouncedRef, function () {
            _originRunAsync_1.apply(void 0, __spreadArray([], __read(args), false)).then(resolve).catch(reject);
          });
        });
      };
      return function () {
        var _a;
        (_a = debouncedRef.current) === null || _a === void 0 ? void 0 : _a.cancel();
        fetchInstance.runAsync = _originRunAsync_1;
      };
    }
  }, [debounceWait, options]);
  if (!debounceWait) {
    return {};
  }
  return {
    onCancel: function () {
      var _a;
      (_a = debouncedRef.current) === null || _a === void 0 ? void 0 : _a.cancel();
    }
  };
};

var useLoadingDelayPlugin = function (fetchInstance, _a) {
  var loadingDelay = _a.loadingDelay,
    ready = _a.ready;
  var timerRef = react.useRef();
  if (!loadingDelay) {
    return {};
  }
  var cancelTimeout = function () {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
  };
  return {
    onBefore: function () {
      cancelTimeout();
      // Two cases:
      // 1. ready === undefined
      // 2. ready === true
      if (ready !== false) {
        timerRef.current = setTimeout(function () {
          fetchInstance.setState({
            loading: true
          });
        }, loadingDelay);
      }
      return {
        loading: false
      };
    },
    onFinally: function () {
      cancelTimeout();
    },
    onCancel: function () {
      cancelTimeout();
    }
  };
};

var isBrowser = !!(typeof window !== 'undefined' && window.document && window.document.createElement);

function isDocumentVisible() {
  if (isBrowser) {
    return document.visibilityState !== 'hidden';
  }
  return true;
}

var listeners$1 = [];
function subscribe$1(listener) {
  listeners$1.push(listener);
  return function unsubscribe() {
    var index = listeners$1.indexOf(listener);
    listeners$1.splice(index, 1);
  };
}
if (isBrowser) {
  var revalidate = function () {
    if (!isDocumentVisible()) return;
    for (var i = 0; i < listeners$1.length; i++) {
      var listener = listeners$1[i];
      listener();
    }
  };
  window.addEventListener('visibilitychange', revalidate, false);
}

var usePollingPlugin = function (fetchInstance, _a) {
  var pollingInterval = _a.pollingInterval,
    _b = _a.pollingWhenHidden,
    pollingWhenHidden = _b === void 0 ? true : _b,
    _c = _a.pollingErrorRetryCount,
    pollingErrorRetryCount = _c === void 0 ? -1 : _c;
  var timerRef = react.useRef();
  var unsubscribeRef = react.useRef();
  var countRef = react.useRef(0);
  var stopPolling = function () {
    var _a;
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    (_a = unsubscribeRef.current) === null || _a === void 0 ? void 0 : _a.call(unsubscribeRef);
  };
  useUpdateEffect(function () {
    if (!pollingInterval) {
      stopPolling();
    }
  }, [pollingInterval]);
  if (!pollingInterval) {
    return {};
  }
  return {
    onBefore: function () {
      stopPolling();
    },
    onError: function () {
      countRef.current += 1;
    },
    onSuccess: function () {
      countRef.current = 0;
    },
    onFinally: function () {
      if (pollingErrorRetryCount === -1 ||
      // When an error occurs, the request is not repeated after pollingErrorRetryCount retries
      pollingErrorRetryCount !== -1 && countRef.current <= pollingErrorRetryCount) {
        timerRef.current = setTimeout(function () {
          // if pollingWhenHidden = false && document is hidden, then stop polling and subscribe revisible
          if (!pollingWhenHidden && !isDocumentVisible()) {
            unsubscribeRef.current = subscribe$1(function () {
              fetchInstance.refresh();
            });
          } else {
            fetchInstance.refresh();
          }
        }, pollingInterval);
      } else {
        countRef.current = 0;
      }
    },
    onCancel: function () {
      stopPolling();
    }
  };
};

function limit(fn, timespan) {
  var pending = false;
  return function () {
    var args = [];
    for (var _i = 0; _i < arguments.length; _i++) {
      args[_i] = arguments[_i];
    }
    if (pending) return;
    pending = true;
    fn.apply(void 0, __spreadArray([], __read(args), false));
    setTimeout(function () {
      pending = false;
    }, timespan);
  };
}

function isOnline() {
  if (isBrowser && typeof navigator.onLine !== 'undefined') {
    return navigator.onLine;
  }
  return true;
}

// from swr
var listeners$2 = [];
function subscribe$2(listener) {
  listeners$2.push(listener);
  return function unsubscribe() {
    var index = listeners$2.indexOf(listener);
    if (index > -1) {
      listeners$2.splice(index, 1);
    }
  };
}
if (isBrowser) {
  var revalidate$1 = function () {
    if (!isDocumentVisible() || !isOnline()) return;
    for (var i = 0; i < listeners$2.length; i++) {
      var listener = listeners$2[i];
      listener();
    }
  };
  window.addEventListener('visibilitychange', revalidate$1, false);
  window.addEventListener('focus', revalidate$1, false);
}

var useRefreshOnWindowFocusPlugin = function (fetchInstance, _a) {
  var refreshOnWindowFocus = _a.refreshOnWindowFocus,
    _b = _a.focusTimespan,
    focusTimespan = _b === void 0 ? 5000 : _b;
  var unsubscribeRef = react.useRef();
  var stopSubscribe = function () {
    var _a;
    (_a = unsubscribeRef.current) === null || _a === void 0 ? void 0 : _a.call(unsubscribeRef);
  };
  react.useEffect(function () {
    if (refreshOnWindowFocus) {
      var limitRefresh_1 = limit(fetchInstance.refresh.bind(fetchInstance), focusTimespan);
      unsubscribeRef.current = subscribe$2(function () {
        limitRefresh_1();
      });
    }
    return function () {
      stopSubscribe();
    };
  }, [refreshOnWindowFocus, focusTimespan]);
  useUnmount(function () {
    stopSubscribe();
  });
  return {};
};

var useRetryPlugin = function (fetchInstance, _a) {
  var retryInterval = _a.retryInterval,
    retryCount = _a.retryCount;
  var timerRef = react.useRef();
  var countRef = react.useRef(0);
  var triggerByRetry = react.useRef(false);
  if (!retryCount) {
    return {};
  }
  return {
    onBefore: function () {
      if (!triggerByRetry.current) {
        countRef.current = 0;
      }
      triggerByRetry.current = false;
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    },
    onSuccess: function () {
      countRef.current = 0;
    },
    onError: function () {
      countRef.current += 1;
      if (retryCount === -1 || countRef.current <= retryCount) {
        // Exponential backoff
        var timeout = retryInterval !== null && retryInterval !== void 0 ? retryInterval : Math.min(1000 * Math.pow(2, countRef.current), 30000);
        timerRef.current = setTimeout(function () {
          triggerByRetry.current = true;
          fetchInstance.refresh();
        }, timeout);
      } else {
        countRef.current = 0;
      }
    },
    onCancel: function () {
      countRef.current = 0;
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    }
  };
};

/** Error message constants. */
var FUNC_ERROR_TEXT = 'Expected a function';

/**
 * Creates a throttled function that only invokes `func` at most once per
 * every `wait` milliseconds. The throttled function comes with a `cancel`
 * method to cancel delayed `func` invocations and a `flush` method to
 * immediately invoke them. Provide `options` to indicate whether `func`
 * should be invoked on the leading and/or trailing edge of the `wait`
 * timeout. The `func` is invoked with the last arguments provided to the
 * throttled function. Subsequent calls to the throttled function return the
 * result of the last `func` invocation.
 *
 * **Note:** If `leading` and `trailing` options are `true`, `func` is
 * invoked on the trailing edge of the timeout only if the throttled function
 * is invoked more than once during the `wait` timeout.
 *
 * If `wait` is `0` and `leading` is `false`, `func` invocation is deferred
 * until to the next tick, similar to `setTimeout` with a timeout of `0`.
 *
 * See [David Corbacho's article](https://css-tricks.com/debouncing-throttling-explained-examples/)
 * for details over the differences between `_.throttle` and `_.debounce`.
 *
 * @static
 * @memberOf _
 * @since 0.1.0
 * @category Function
 * @param {Function} func The function to throttle.
 * @param {number} [wait=0] The number of milliseconds to throttle invocations to.
 * @param {Object} [options={}] The options object.
 * @param {boolean} [options.leading=true]
 *  Specify invoking on the leading edge of the timeout.
 * @param {boolean} [options.trailing=true]
 *  Specify invoking on the trailing edge of the timeout.
 * @returns {Function} Returns the new throttled function.
 * @example
 *
 * // Avoid excessively updating the position while scrolling.
 * jQuery(window).on('scroll', _.throttle(updatePosition, 100));
 *
 * // Invoke `renewToken` when the click event is fired, but not more than once every 5 minutes.
 * var throttled = _.throttle(renewToken, 300000, { 'trailing': false });
 * jQuery(element).on('click', throttled);
 *
 * // Cancel the trailing throttled invocation.
 * jQuery(window).on('popstate', throttled.cancel);
 */
function throttle(func, wait, options) {
  var leading = true,
      trailing = true;

  if (typeof func != 'function') {
    throw new TypeError(FUNC_ERROR_TEXT);
  }
  if (isObject_1(options)) {
    leading = 'leading' in options ? !!options.leading : leading;
    trailing = 'trailing' in options ? !!options.trailing : trailing;
  }
  return debounce_1(func, wait, {
    'leading': leading,
    'maxWait': wait,
    'trailing': trailing
  });
}

var throttle_1 = throttle;

var useThrottlePlugin = function (fetchInstance, _a) {
  var throttleWait = _a.throttleWait,
    throttleLeading = _a.throttleLeading,
    throttleTrailing = _a.throttleTrailing;
  var throttledRef = react.useRef();
  var options = {};
  if (throttleLeading !== undefined) {
    options.leading = throttleLeading;
  }
  if (throttleTrailing !== undefined) {
    options.trailing = throttleTrailing;
  }
  react.useEffect(function () {
    if (throttleWait) {
      var _originRunAsync_1 = fetchInstance.runAsync.bind(fetchInstance);
      throttledRef.current = throttle_1(function (callback) {
        callback();
      }, throttleWait, options);
      // throttle runAsync should be promise
      // https://github.com/lodash/lodash/issues/4400#issuecomment-834800398
      fetchInstance.runAsync = function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
          args[_i] = arguments[_i];
        }
        return new Promise(function (resolve, reject) {
          var _a;
          (_a = throttledRef.current) === null || _a === void 0 ? void 0 : _a.call(throttledRef, function () {
            _originRunAsync_1.apply(void 0, __spreadArray([], __read(args), false)).then(resolve).catch(reject);
          });
        });
      };
      return function () {
        var _a;
        fetchInstance.runAsync = _originRunAsync_1;
        (_a = throttledRef.current) === null || _a === void 0 ? void 0 : _a.cancel();
      };
    }
  }, [throttleWait, throttleLeading, throttleTrailing]);
  if (!throttleWait) {
    return {};
  }
  return {
    onCancel: function () {
      var _a;
      (_a = throttledRef.current) === null || _a === void 0 ? void 0 : _a.cancel();
    }
  };
};

var useMount = function (fn) {
  react.useEffect(function () {
    fn === null || fn === void 0 ? void 0 : fn();
  }, []);
};

var Fetch = /** @class */function () {
  function Fetch(serviceRef, options, subscribe, initState) {
    if (initState === void 0) {
      initState = {};
    }
    this.serviceRef = serviceRef;
    this.options = options;
    this.subscribe = subscribe;
    this.initState = initState;
    this.count = 0;
    this.state = {
      loading: false,
      params: undefined,
      data: undefined,
      error: undefined
    };
    this.state = __assign(__assign(__assign({}, this.state), {
      loading: !options.manual
    }), initState);
  }
  Fetch.prototype.setState = function (s) {
    if (s === void 0) {
      s = {};
    }
    this.state = __assign(__assign({}, this.state), s);
    this.subscribe();
  };
  Fetch.prototype.runPluginHandler = function (event) {
    var rest = [];
    for (var _i = 1; _i < arguments.length; _i++) {
      rest[_i - 1] = arguments[_i];
    }
    // @ts-ignore
    var r = this.pluginImpls.map(function (i) {
      var _a;
      return (_a = i[event]) === null || _a === void 0 ? void 0 : _a.call.apply(_a, __spreadArray([i], __read(rest), false));
    }).filter(Boolean);
    return Object.assign.apply(Object, __spreadArray([{}], __read(r), false));
  };
  Fetch.prototype.runAsync = function () {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
    var params = [];
    for (var _i = 0; _i < arguments.length; _i++) {
      params[_i] = arguments[_i];
    }
    return __awaiter(this, void 0, void 0, function () {
      var currentCount, _l, _m, stopNow, _o, returnNow, state, servicePromise, res, error_1;
      var _p;
      return __generator(this, function (_q) {
        switch (_q.label) {
          case 0:
            this.count += 1;
            currentCount = this.count;
            _l = this.runPluginHandler('onBefore', params), _m = _l.stopNow, stopNow = _m === void 0 ? false : _m, _o = _l.returnNow, returnNow = _o === void 0 ? false : _o, state = __rest(_l, ["stopNow", "returnNow"]);
            // stop request
            if (stopNow) {
              return [2 /*return*/, new Promise(function () {})];
            }
            this.setState(__assign({
              loading: true,
              params: params
            }, state));
            // return now
            if (returnNow) {
              return [2 /*return*/, Promise.resolve(state.data)];
            }
            (_b = (_a = this.options).onBefore) === null || _b === void 0 ? void 0 : _b.call(_a, params);
            _q.label = 1;
          case 1:
            _q.trys.push([1, 3,, 4]);
            servicePromise = this.runPluginHandler('onRequest', this.serviceRef.current, params).servicePromise;
            if (!servicePromise) {
              servicePromise = (_p = this.serviceRef).current.apply(_p, __spreadArray([], __read(params), false));
            }
            return [4 /*yield*/, servicePromise];
          case 2:
            res = _q.sent();
            if (currentCount !== this.count) {
              // prevent run.then when request is canceled
              return [2 /*return*/, new Promise(function () {})];
            }
            // const formattedResult = this.options.formatResultRef.current ? this.options.formatResultRef.current(res) : res;
            this.setState({
              data: res,
              error: undefined,
              loading: false
            });
            (_d = (_c = this.options).onSuccess) === null || _d === void 0 ? void 0 : _d.call(_c, res, params);
            this.runPluginHandler('onSuccess', res, params);
            (_f = (_e = this.options).onFinally) === null || _f === void 0 ? void 0 : _f.call(_e, params, res, undefined);
            if (currentCount === this.count) {
              this.runPluginHandler('onFinally', params, res, undefined);
            }
            return [2 /*return*/, res];
          case 3:
            error_1 = _q.sent();
            if (currentCount !== this.count) {
              // prevent run.then when request is canceled
              return [2 /*return*/, new Promise(function () {})];
            }
            this.setState({
              error: error_1,
              loading: false
            });
            (_h = (_g = this.options).onError) === null || _h === void 0 ? void 0 : _h.call(_g, error_1, params);
            this.runPluginHandler('onError', error_1, params);
            (_k = (_j = this.options).onFinally) === null || _k === void 0 ? void 0 : _k.call(_j, params, undefined, error_1);
            if (currentCount === this.count) {
              this.runPluginHandler('onFinally', params, undefined, error_1);
            }
            throw error_1;
          case 4:
            return [2 /*return*/];
        }
      });
    });
  };

  Fetch.prototype.run = function () {
    var _this = this;
    var params = [];
    for (var _i = 0; _i < arguments.length; _i++) {
      params[_i] = arguments[_i];
    }
    this.runAsync.apply(this, __spreadArray([], __read(params), false)).catch(function (error) {
      if (!_this.options.onError) {
        console.error(error);
      }
    });
  };
  Fetch.prototype.cancel = function () {
    this.count += 1;
    this.setState({
      loading: false
    });
    this.runPluginHandler('onCancel');
  };
  Fetch.prototype.refresh = function () {
    // @ts-ignore
    this.run.apply(this, __spreadArray([], __read(this.state.params || []), false));
  };
  Fetch.prototype.refreshAsync = function () {
    // @ts-ignore
    return this.runAsync.apply(this, __spreadArray([], __read(this.state.params || []), false));
  };
  Fetch.prototype.mutate = function (data) {
    var targetData = isFunction(data) ? data(this.state.data) : data;
    this.runPluginHandler('onMutate', targetData);
    this.setState({
      data: targetData
    });
  };
  return Fetch;
}();

function useRequestImplement(service, options, plugins) {
  if (options === void 0) {
    options = {};
  }
  if (plugins === void 0) {
    plugins = [];
  }
  var _a = options.manual,
    manual = _a === void 0 ? false : _a,
    rest = __rest(options, ["manual"]);
  var fetchOptions = __assign({
    manual: manual
  }, rest);
  var serviceRef = useLatest(service);
  var update = useUpdate();
  var fetchInstance = useCreation(function () {
    var initState = plugins.map(function (p) {
      var _a;
      return (_a = p === null || p === void 0 ? void 0 : p.onInit) === null || _a === void 0 ? void 0 : _a.call(p, fetchOptions);
    }).filter(Boolean);
    return new Fetch(serviceRef, fetchOptions, update, Object.assign.apply(Object, __spreadArray([{}], __read(initState), false)));
  }, []);
  fetchInstance.options = fetchOptions;
  // run all plugins hooks
  fetchInstance.pluginImpls = plugins.map(function (p) {
    return p(fetchInstance, fetchOptions);
  });
  useMount(function () {
    if (!manual) {
      // useCachePlugin can set fetchInstance.state.params from cache when init
      var params = fetchInstance.state.params || options.defaultParams || [];
      // @ts-ignore
      fetchInstance.run.apply(fetchInstance, __spreadArray([], __read(params), false));
    }
  });
  useUnmount(function () {
    fetchInstance.cancel();
  });
  return {
    loading: fetchInstance.state.loading,
    data: fetchInstance.state.data,
    error: fetchInstance.state.error,
    params: fetchInstance.state.params || [],
    cancel: useMemoizedFn(fetchInstance.cancel.bind(fetchInstance)),
    refresh: useMemoizedFn(fetchInstance.refresh.bind(fetchInstance)),
    refreshAsync: useMemoizedFn(fetchInstance.refreshAsync.bind(fetchInstance)),
    run: useMemoizedFn(fetchInstance.run.bind(fetchInstance)),
    runAsync: useMemoizedFn(fetchInstance.runAsync.bind(fetchInstance)),
    mutate: useMemoizedFn(fetchInstance.mutate.bind(fetchInstance))
  };
}

// function useRequest<TData, TParams extends any[], TFormated, TTFormated extends TFormated = any>(
//   service: Service<TData, TParams>,
//   options: OptionsWithFormat<TData, TParams, TFormated, TTFormated>,
//   plugins?: Plugin<TData, TParams>[],
// ): Result<TFormated, TParams>
// function useRequest<TData, TParams extends any[]>(
//   service: Service<TData, TParams>,
//   options?: OptionsWithoutFormat<TData, TParams>,
//   plugins?: Plugin<TData, TParams>[],
// ): Result<TData, TParams>
function useRequest(service, options, plugins) {
  return useRequestImplement(service, options, __spreadArray(__spreadArray([], __read(plugins || []), false), [useDebouncePlugin, useLoadingDelayPlugin, usePollingPlugin, useRefreshOnWindowFocusPlugin, useThrottlePlugin, useAutoRunPlugin, useCachePlugin, useRetryPlugin], false));
}

var usePagination = function (service, options) {
  var _a;
  if (options === void 0) {
    options = {};
  }
  var _b = options.defaultPageSize,
    defaultPageSize = _b === void 0 ? 10 : _b,
    _c = options.defaultCurrent,
    defaultCurrent = _c === void 0 ? 1 : _c,
    rest = __rest(options, ["defaultPageSize", "defaultCurrent"]);
  var result = useRequest(service, __assign({
    defaultParams: [{
      current: defaultCurrent,
      pageSize: defaultPageSize
    }],
    refreshDepsAction: function () {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      changeCurrent(1);
    }
  }, rest));
  var _d = result.params[0] || {},
    _e = _d.current,
    current = _e === void 0 ? 1 : _e,
    _f = _d.pageSize,
    pageSize = _f === void 0 ? defaultPageSize : _f;
  var total = ((_a = result.data) === null || _a === void 0 ? void 0 : _a.total) || 0;
  var totalPage = react.useMemo(function () {
    return Math.ceil(total / pageSize);
  }, [pageSize, total]);
  var onChange = function (c, p) {
    var toCurrent = c <= 0 ? 1 : c;
    var toPageSize = p <= 0 ? 1 : p;
    var tempTotalPage = Math.ceil(total / toPageSize);
    if (toCurrent > tempTotalPage) {
      toCurrent = Math.max(1, tempTotalPage);
    }
    var _a = __read(result.params || []),
      _b = _a[0],
      oldPaginationParams = _b === void 0 ? {} : _b,
      restParams = _a.slice(1);
    result.run.apply(result, __spreadArray([__assign(__assign({}, oldPaginationParams), {
      current: toCurrent,
      pageSize: toPageSize
    })], __read(restParams), false));
  };
  var changeCurrent = function (c) {
    onChange(c, pageSize);
  };
  var changePageSize = function (p) {
    onChange(current, p);
  };
  return __assign(__assign({}, result), {
    pagination: {
      current: current,
      pageSize: pageSize,
      total: total,
      totalPage: totalPage,
      onChange: useMemoizedFn(onChange),
      changeCurrent: useMemoizedFn(changeCurrent),
      changePageSize: useMemoizedFn(changePageSize)
    }
  });
};

var useAntdTable = function (service, options) {
  var _a;
  if (options === void 0) {
    options = {};
  }
  var form = options.form,
    _b = options.defaultType,
    defaultType = _b === void 0 ? 'simple' : _b,
    defaultParams = options.defaultParams,
    _c = options.manual,
    manual = _c === void 0 ? false : _c,
    _d = options.refreshDeps,
    refreshDeps = _d === void 0 ? [] : _d,
    _e = options.ready,
    ready = _e === void 0 ? true : _e,
    rest = __rest(options, ["form", "defaultType", "defaultParams", "manual", "refreshDeps", "ready"]);
  var result = usePagination(service, __assign({
    manual: true
  }, rest));
  var _f = result.params,
    params = _f === void 0 ? [] : _f,
    run = result.run;
  var cacheFormTableData = params[2] || {};
  var _g = __read(react.useState((cacheFormTableData === null || cacheFormTableData === void 0 ? void 0 : cacheFormTableData.type) || defaultType), 2),
    type = _g[0],
    setType = _g[1];
  var allFormDataRef = react.useRef({});
  var defaultDataSourceRef = react.useRef([]);
  var isAntdV4 = !!(form === null || form === void 0 ? void 0 : form.getInternalHooks);
  // get current active field values
  var getActiveFieldValues = function () {
    if (!form) {
      return {};
    }
    // antd 4
    if (isAntdV4) {
      return form.getFieldsValue(null, function () {
        return true;
      });
    }
    // antd 3
    var allFieldsValue = form.getFieldsValue();
    var activeFieldsValue = {};
    Object.keys(allFieldsValue).forEach(function (key) {
      if (form.getFieldInstance ? form.getFieldInstance(key) : true) {
        activeFieldsValue[key] = allFieldsValue[key];
      }
    });
    return activeFieldsValue;
  };
  var validateFields = function () {
    if (!form) {
      return Promise.resolve({});
    }
    var activeFieldsValue = getActiveFieldValues();
    var fields = Object.keys(activeFieldsValue);
    // antd 4
    if (isAntdV4) {
      return form.validateFields(fields);
    }
    // antd 3
    return new Promise(function (resolve, reject) {
      form.validateFields(fields, function (errors, values) {
        if (errors) {
          reject(errors);
        } else {
          resolve(values);
        }
      });
    });
  };
  var restoreForm = function () {
    if (!form) {
      return;
    }
    // antd v4
    if (isAntdV4) {
      return form.setFieldsValue(allFormDataRef.current);
    }
    // antd v3
    var activeFieldsValue = {};
    Object.keys(allFormDataRef.current).forEach(function (key) {
      if (form.getFieldInstance ? form.getFieldInstance(key) : true) {
        activeFieldsValue[key] = allFormDataRef.current[key];
      }
    });
    form.setFieldsValue(activeFieldsValue);
  };
  var changeType = function () {
    var activeFieldsValue = getActiveFieldValues();
    allFormDataRef.current = __assign(__assign({}, allFormDataRef.current), activeFieldsValue);
    setType(function (t) {
      return t === 'simple' ? 'advance' : 'simple';
    });
  };
  var _submit = function (initPagination) {
    if (!ready) {
      return;
    }
    setTimeout(function () {
      validateFields().then(function (values) {
        if (values === void 0) {
          values = {};
        }
        var pagination = initPagination || __assign(__assign({
          pageSize: options.defaultPageSize || 10
        }, (params === null || params === void 0 ? void 0 : params[0]) || {}), {
          current: 1
        });
        if (!form) {
          // @ts-ignore
          run(pagination);
          return;
        }
        // record all form data
        allFormDataRef.current = __assign(__assign({}, allFormDataRef.current), values);
        // @ts-ignore
        run(pagination, values, {
          allFormData: allFormDataRef.current,
          type: type
        });
      }).catch(function (err) {
        return err;
      });
    });
  };
  var reset = function () {
    var _a, _b;
    if (form) {
      form.resetFields();
    }
    _submit(__assign(__assign({}, (defaultParams === null || defaultParams === void 0 ? void 0 : defaultParams[0]) || {}), {
      pageSize: options.defaultPageSize || ((_b = (_a = options.defaultParams) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.pageSize) || 10,
      current: 1
    }));
  };
  var submit = function (e) {
    var _a;
    (_a = e === null || e === void 0 ? void 0 : e.preventDefault) === null || _a === void 0 ? void 0 : _a.call(e);
    _submit();
  };
  var onTableChange = function (pagination, filters, sorter, extra) {
    var _a = __read(params || []),
      oldPaginationParams = _a[0],
      restParams = _a.slice(1);
    run.apply(void 0, __spreadArray([__assign(__assign({}, oldPaginationParams), {
      current: pagination.current,
      pageSize: pagination.pageSize,
      filters: filters,
      sorter: sorter,
      extra: extra
    })], __read(restParams), false));
  };
  // init
  react.useEffect(function () {
    // if has cache, use cached params. ignore manual and ready.
    if (params.length > 0) {
      allFormDataRef.current = (cacheFormTableData === null || cacheFormTableData === void 0 ? void 0 : cacheFormTableData.allFormData) || {};
      restoreForm();
      // @ts-ignore
      run.apply(void 0, __spreadArray([], __read(params), false));
      return;
    }
    if (!manual && ready) {
      allFormDataRef.current = (defaultParams === null || defaultParams === void 0 ? void 0 : defaultParams[1]) || {};
      restoreForm();
      _submit(defaultParams === null || defaultParams === void 0 ? void 0 : defaultParams[0]);
    }
  }, []);
  // change search type, restore form data
  useUpdateEffect(function () {
    if (!ready) {
      return;
    }
    restoreForm();
  }, [type]);
  // refresh & ready change on the same time
  var hasAutoRun = react.useRef(false);
  hasAutoRun.current = false;
  useUpdateEffect(function () {
    if (!manual && ready) {
      hasAutoRun.current = true;
      if (form) {
        form.resetFields();
      }
      allFormDataRef.current = (defaultParams === null || defaultParams === void 0 ? void 0 : defaultParams[1]) || {};
      restoreForm();
      _submit(defaultParams === null || defaultParams === void 0 ? void 0 : defaultParams[0]);
    }
  }, [ready]);
  useUpdateEffect(function () {
    if (hasAutoRun.current) {
      return;
    }
    if (!ready) {
      return;
    }
    if (!manual) {
      hasAutoRun.current = true;
      result.pagination.changeCurrent(1);
    }
  }, __spreadArray([], __read(refreshDeps), false));
  return __assign(__assign({}, result), {
    tableProps: {
      dataSource: ((_a = result.data) === null || _a === void 0 ? void 0 : _a.list) || defaultDataSourceRef.current,
      loading: result.loading,
      onChange: useMemoizedFn(onTableChange),
      pagination: {
        current: result.pagination.current,
        pageSize: result.pagination.pageSize,
        total: result.pagination.total
      }
    },
    search: {
      submit: useMemoizedFn(submit),
      type: type,
      changeType: useMemoizedFn(changeType),
      reset: useMemoizedFn(reset)
    }
  });
};

function useToggle(defaultValue, reverseValue) {
  if (defaultValue === void 0) {
    defaultValue = false;
  }
  var _a = __read(react.useState(defaultValue), 2),
    state = _a[0],
    setState = _a[1];
  var actions = react.useMemo(function () {
    var reverseValueOrigin = reverseValue === undefined ? !defaultValue : reverseValue;
    var toggle = function () {
      return setState(function (s) {
        return s === defaultValue ? reverseValueOrigin : defaultValue;
      });
    };
    var set = function (value) {
      return setState(value);
    };
    var setLeft = function () {
      return setState(defaultValue);
    };
    var setRight = function () {
      return setState(reverseValueOrigin);
    };
    return {
      toggle: toggle,
      set: set,
      setLeft: setLeft,
      setRight: setRight
    };
    // useToggle ignore value change
    // }, [defaultValue, reverseValue]);
  }, []);
  return [state, actions];
}

function useBoolean(defaultValue) {
  if (defaultValue === void 0) {
    defaultValue = false;
  }
  var _a = __read(useToggle(!!defaultValue), 2),
    state = _a[0],
    _b = _a[1],
    toggle = _b.toggle,
    set = _b.set;
  var actions = react.useMemo(function () {
    var setTrue = function () {
      return set(true);
    };
    var setFalse = function () {
      return set(false);
    };
    return {
      toggle: toggle,
      set: function (v) {
        return set(!!v);
      },
      setTrue: setTrue,
      setFalse: setFalse
    };
  }, []);
  return [state, actions];
}

function getTargetElement(target, defaultElement) {
  if (!isBrowser) {
    return undefined;
  }
  if (!target) {
    return defaultElement;
  }
  var targetElement;
  if (isFunction(target)) {
    targetElement = target();
  } else if ('current' in target) {
    targetElement = target.current;
  } else {
    targetElement = target;
  }
  return targetElement;
}

var createEffectWithTarget = function (useEffectType) {
  /**
   *
   * @param effect
   * @param deps
   * @param target target should compare ref.current vs ref.current, dom vs dom, ()=>dom vs ()=>dom
   */
  var useEffectWithTarget = function (effect, deps, target) {
    var hasInitRef = react.useRef(false);
    var lastElementRef = react.useRef([]);
    var lastDepsRef = react.useRef([]);
    var unLoadRef = react.useRef();
    useEffectType(function () {
      var _a;
      var targets = Array.isArray(target) ? target : [target];
      var els = targets.map(function (item) {
        return getTargetElement(item);
      });
      // init run
      if (!hasInitRef.current) {
        hasInitRef.current = true;
        lastElementRef.current = els;
        lastDepsRef.current = deps;
        unLoadRef.current = effect();
        return;
      }
      if (els.length !== lastElementRef.current.length || !depsAreSame(els, lastElementRef.current) || !depsAreSame(deps, lastDepsRef.current)) {
        (_a = unLoadRef.current) === null || _a === void 0 ? void 0 : _a.call(unLoadRef);
        lastElementRef.current = els;
        lastDepsRef.current = deps;
        unLoadRef.current = effect();
      }
    });
    useUnmount(function () {
      var _a;
      (_a = unLoadRef.current) === null || _a === void 0 ? void 0 : _a.call(unLoadRef);
      // for react-refresh
      hasInitRef.current = false;
    });
  };
  return useEffectWithTarget;
};

var useEffectWithTarget = createEffectWithTarget(react.useEffect);

function useControllableValue(props, options) {
  if (props === void 0) {
    props = {};
  }
  if (options === void 0) {
    options = {};
  }
  var defaultValue = options.defaultValue,
    _a = options.defaultValuePropName,
    defaultValuePropName = _a === void 0 ? 'defaultValue' : _a,
    _b = options.valuePropName,
    valuePropName = _b === void 0 ? 'value' : _b,
    _c = options.trigger,
    trigger = _c === void 0 ? 'onChange' : _c;
  var value = props[valuePropName];
  var isControlled = props.hasOwnProperty(valuePropName);
  var initialValue = react.useMemo(function () {
    if (isControlled) {
      return value;
    }
    if (props.hasOwnProperty(defaultValuePropName)) {
      return props[defaultValuePropName];
    }
    return defaultValue;
  }, []);
  var stateRef = react.useRef(initialValue);
  if (isControlled) {
    stateRef.current = value;
  }
  var update = useUpdate();
  function setState(v) {
    var args = [];
    for (var _i = 1; _i < arguments.length; _i++) {
      args[_i - 1] = arguments[_i];
    }
    var r = isFunction(v) ? v(stateRef.current) : v;
    if (!isControlled) {
      stateRef.current = r;
      update();
    }
    if (props[trigger]) {
      props[trigger].apply(props, __spreadArray([r], __read(args), false));
    }
  }
  return [stateRef.current, useMemoizedFn(setState)];
}

var calcLeft = function (target) {
  if (!target) {
    return 0;
  }
  // https://stackoverflow.com/questions/4310953/invalid-date-in-safari
  var left = dayjs_min(target).valueOf() - Date.now();
  return left < 0 ? 0 : left;
};
var parseMs = function (milliseconds) {
  return {
    days: Math.floor(milliseconds / 86400000),
    hours: Math.floor(milliseconds / 3600000) % 24,
    minutes: Math.floor(milliseconds / 60000) % 60,
    seconds: Math.floor(milliseconds / 1000) % 60,
    milliseconds: Math.floor(milliseconds) % 1000
  };
};
var useCountdown = function (options) {
  if (options === void 0) {
    options = {};
  }
  var _a = options || {},
    leftTime = _a.leftTime,
    targetDate = _a.targetDate,
    _b = _a.interval,
    interval = _b === void 0 ? 1000 : _b,
    onEnd = _a.onEnd;
  var target = react.useMemo(function () {
    if ('leftTime' in options) {
      return isNumber(leftTime) && leftTime > 0 ? Date.now() + leftTime : undefined;
    } else {
      return targetDate;
    }
  }, [leftTime, targetDate]);
  var _c = __read(react.useState(function () {
      return calcLeft(target);
    }), 2),
    timeLeft = _c[0],
    setTimeLeft = _c[1];
  var onEndRef = useLatest(onEnd);
  react.useEffect(function () {
    if (!target) {
      // for stop
      setTimeLeft(0);
      return;
    }
    // 立即执行一次
    setTimeLeft(calcLeft(target));
    var timer = setInterval(function () {
      var _a;
      var targetLeft = calcLeft(target);
      setTimeLeft(targetLeft);
      if (targetLeft === 0) {
        clearInterval(timer);
        (_a = onEndRef.current) === null || _a === void 0 ? void 0 : _a.call(onEndRef);
      }
    }, interval);
    return function () {
      return clearInterval(timer);
    };
  }, [target, interval]);
  var formattedRes = react.useMemo(function () {
    return parseMs(timeLeft);
  }, [timeLeft]);
  return [timeLeft, formattedRes];
};

function useDebounceFn(fn, options) {
  var _a;
  var fnRef = useLatest(fn);
  var wait = (_a = options === null || options === void 0 ? void 0 : options.wait) !== null && _a !== void 0 ? _a : 1000;
  var debounced = react.useMemo(function () {
    return debounce_1(function () {
      var args = [];
      for (var _i = 0; _i < arguments.length; _i++) {
        args[_i] = arguments[_i];
      }
      return fnRef.current.apply(fnRef, __spreadArray([], __read(args), false));
    }, wait, options);
  }, []);
  useUnmount(function () {
    debounced.cancel();
  });
  return {
    run: debounced,
    cancel: debounced.cancel,
    flush: debounced.flush
  };
}

var depsEqual = function (aDeps, bDeps) {
  if (aDeps === void 0) {
    aDeps = [];
  }
  if (bDeps === void 0) {
    bDeps = [];
  }
  return isEqual_1(aDeps, bDeps);
};

var createDeepCompareEffect = function (hook) {
  return function (effect, deps) {
    var ref = react.useRef();
    var signalRef = react.useRef(0);
    if (deps === undefined || !depsEqual(deps, ref.current)) {
      ref.current = deps;
      signalRef.current += 1;
    }
    hook(effect, [signalRef.current]);
  };
};

var index = createDeepCompareEffect(react.useEffect);

var useDeepCompareEffectWithTarget = function (effect, deps, target) {
  var ref = react.useRef();
  var signalRef = react.useRef(0);
  if (!depsEqual(deps, ref.current)) {
    ref.current = deps;
    signalRef.current += 1;
  }
  useEffectWithTarget(effect, [signalRef.current], target);
};

var isAppleDevice = /(mac|iphone|ipod|ipad)/i.test(typeof navigator !== 'undefined' ? navigator === null || navigator === void 0 ? void 0 : navigator.platform : '');

// 键盘事件 keyCode 别名
var aliasKeyCodeMap = {
  '0': 48,
  '1': 49,
  '2': 50,
  '3': 51,
  '4': 52,
  '5': 53,
  '6': 54,
  '7': 55,
  '8': 56,
  '9': 57,
  backspace: 8,
  tab: 9,
  enter: 13,
  shift: 16,
  ctrl: 17,
  alt: 18,
  pausebreak: 19,
  capslock: 20,
  esc: 27,
  space: 32,
  pageup: 33,
  pagedown: 34,
  end: 35,
  home: 36,
  leftarrow: 37,
  uparrow: 38,
  rightarrow: 39,
  downarrow: 40,
  insert: 45,
  delete: 46,
  a: 65,
  b: 66,
  c: 67,
  d: 68,
  e: 69,
  f: 70,
  g: 71,
  h: 72,
  i: 73,
  j: 74,
  k: 75,
  l: 76,
  m: 77,
  n: 78,
  o: 79,
  p: 80,
  q: 81,
  r: 82,
  s: 83,
  t: 84,
  u: 85,
  v: 86,
  w: 87,
  x: 88,
  y: 89,
  z: 90,
  leftwindowkey: 91,
  rightwindowkey: 92,
  meta: isAppleDevice ? [91, 93] : [91, 92],
  selectkey: 93,
  numpad0: 96,
  numpad1: 97,
  numpad2: 98,
  numpad3: 99,
  numpad4: 100,
  numpad5: 101,
  numpad6: 102,
  numpad7: 103,
  numpad8: 104,
  numpad9: 105,
  multiply: 106,
  add: 107,
  subtract: 109,
  decimalpoint: 110,
  divide: 111,
  f1: 112,
  f2: 113,
  f3: 114,
  f4: 115,
  f5: 116,
  f6: 117,
  f7: 118,
  f8: 119,
  f9: 120,
  f10: 121,
  f11: 122,
  f12: 123,
  numlock: 144,
  scrolllock: 145,
  semicolon: 186,
  equalsign: 187,
  comma: 188,
  dash: 189,
  period: 190,
  forwardslash: 191,
  graveaccent: 192,
  openbracket: 219,
  backslash: 220,
  closebracket: 221,
  singlequote: 222
};
// 修饰键
var modifierKey = {
  ctrl: function (event) {
    return event.ctrlKey;
  },
  shift: function (event) {
    return event.shiftKey;
  },
  alt: function (event) {
    return event.altKey;
  },
  meta: function (event) {
    if (event.type === 'keyup') {
      return aliasKeyCodeMap.meta.includes(event.keyCode);
    }
    return event.metaKey;
  }
};
// 根据 event 计算激活键数量
function countKeyByEvent(event) {
  var countOfModifier = Object.keys(modifierKey).reduce(function (total, key) {
    if (modifierKey[key](event)) {
      return total + 1;
    }
    return total;
  }, 0);
  // 16 17 18 91 92 是修饰键的 keyCode，如果 keyCode 是修饰键，那么激活数量就是修饰键的数量，如果不是，那么就需要 +1
  return [16, 17, 18, 91, 92].includes(event.keyCode) ? countOfModifier : countOfModifier + 1;
}
/**
 * 判断按键是否激活
 * @param [event: KeyboardEvent]键盘事件
 * @param [keyFilter: any] 当前键
 * @returns Boolean
 */
function genFilterKey(event, keyFilter, exactMatch) {
  var e_1, _a;
  // 浏览器自动补全 input 的时候，会触发 keyDown、keyUp 事件，但此时 event.key 等为空
  if (!event.key) {
    return false;
  }
  // 数字类型直接匹配事件的 keyCode
  if (isNumber(keyFilter)) {
    return event.keyCode === keyFilter;
  }
  // 字符串依次判断是否有组合键
  var genArr = keyFilter.split('.');
  var genLen = 0;
  try {
    for (var genArr_1 = __values(genArr), genArr_1_1 = genArr_1.next(); !genArr_1_1.done; genArr_1_1 = genArr_1.next()) {
      var key = genArr_1_1.value;
      // 组合键
      var genModifier = modifierKey[key];
      // keyCode 别名
      var aliasKeyCode = aliasKeyCodeMap[key.toLowerCase()];
      if (genModifier && genModifier(event) || aliasKeyCode && aliasKeyCode === event.keyCode) {
        genLen++;
      }
    }
  } catch (e_1_1) {
    e_1 = {
      error: e_1_1
    };
  } finally {
    try {
      if (genArr_1_1 && !genArr_1_1.done && (_a = genArr_1.return)) _a.call(genArr_1);
    } finally {
      if (e_1) throw e_1.error;
    }
  }
  /**
   * 需要判断触发的键位和监听的键位完全一致，判断方法就是触发的键位里有且等于监听的键位
   * genLen === genArr.length 能判断出来触发的键位里有监听的键位
   * countKeyByEvent(event) === genArr.length 判断出来触发的键位数量里有且等于监听的键位数量
   * 主要用来防止按组合键其子集也会触发的情况，例如监听 ctrl+a 会触发监听 ctrl 和 a 两个键的事件。
   */
  if (exactMatch) {
    return genLen === genArr.length && countKeyByEvent(event) === genArr.length;
  }
  return genLen === genArr.length;
}
/**
 * 键盘输入预处理方法
 * @param [keyFilter: any] 当前键
 * @returns () => Boolean
 */
function genKeyFormatter(keyFilter, exactMatch) {
  if (isFunction(keyFilter)) {
    return keyFilter;
  }
  if (isString(keyFilter) || isNumber(keyFilter)) {
    return function (event) {
      return genFilterKey(event, keyFilter, exactMatch);
    };
  }
  if (Array.isArray(keyFilter)) {
    return function (event) {
      return keyFilter.some(function (item) {
        return genFilterKey(event, item, exactMatch);
      });
    };
  }
  return function () {
    return Boolean(keyFilter);
  };
}
var defaultEvents = ['keydown'];
function useKeyPress(keyFilter, eventHandler, option) {
  var _a = option || {},
    _b = _a.events,
    events = _b === void 0 ? defaultEvents : _b,
    target = _a.target,
    _c = _a.exactMatch,
    exactMatch = _c === void 0 ? false : _c,
    _d = _a.useCapture,
    useCapture = _d === void 0 ? false : _d;
  var eventHandlerRef = useLatest(eventHandler);
  var keyFilterRef = useLatest(keyFilter);
  useDeepCompareEffectWithTarget(function () {
    var e_2, _a;
    var _b;
    var el = getTargetElement(target, window);
    if (!el) {
      return;
    }
    var callbackHandler = function (event) {
      var _a;
      var genGuard = genKeyFormatter(keyFilterRef.current, exactMatch);
      if (genGuard(event)) {
        return (_a = eventHandlerRef.current) === null || _a === void 0 ? void 0 : _a.call(eventHandlerRef, event);
      }
    };
    try {
      for (var events_1 = __values(events), events_1_1 = events_1.next(); !events_1_1.done; events_1_1 = events_1.next()) {
        var eventName = events_1_1.value;
        (_b = el === null || el === void 0 ? void 0 : el.addEventListener) === null || _b === void 0 ? void 0 : _b.call(el, eventName, callbackHandler, useCapture);
      }
    } catch (e_2_1) {
      e_2 = {
        error: e_2_1
      };
    } finally {
      try {
        if (events_1_1 && !events_1_1.done && (_a = events_1.return)) _a.call(events_1);
      } finally {
        if (e_2) throw e_2.error;
      }
    }
    return function () {
      var e_3, _a;
      var _b;
      try {
        for (var events_2 = __values(events), events_2_1 = events_2.next(); !events_2_1.done; events_2_1 = events_2.next()) {
          var eventName = events_2_1.value;
          (_b = el === null || el === void 0 ? void 0 : el.removeEventListener) === null || _b === void 0 ? void 0 : _b.call(el, eventName, callbackHandler, useCapture);
        }
      } catch (e_3_1) {
        e_3 = {
          error: e_3_1
        };
      } finally {
        try {
          if (events_2_1 && !events_2_1.done && (_a = events_2.return)) _a.call(events_2);
        } finally {
          if (e_3) throw e_3.error;
        }
      }
    };
  }, [events], target);
}

function useRafState(initialState) {
  var ref = react.useRef(0);
  var _a = __read(react.useState(initialState), 2),
    state = _a[0],
    setState = _a[1];
  var setRafState = react.useCallback(function (value) {
    cancelAnimationFrame(ref.current);
    ref.current = requestAnimationFrame(function () {
      setState(value);
    });
  }, []);
  useUnmount(function () {
    cancelAnimationFrame(ref.current);
  });
  return [state, setRafState];
}

var NetworkEventType;
(function (NetworkEventType) {
  NetworkEventType["ONLINE"] = "online";
  NetworkEventType["OFFLINE"] = "offline";
  NetworkEventType["CHANGE"] = "change";
})(NetworkEventType || (NetworkEventType = {}));
function getConnection() {
  var nav = navigator;
  if (!isObject(nav)) return null;
  return nav.connection || nav.mozConnection || nav.webkitConnection;
}
function getConnectionProperty() {
  var c = getConnection();
  if (!c) return {};
  return {
    rtt: c.rtt,
    type: c.type,
    saveData: c.saveData,
    downlink: c.downlink,
    downlinkMax: c.downlinkMax,
    effectiveType: c.effectiveType
  };
}
function useNetwork() {
  var _a = __read(react.useState(function () {
      return __assign({
        since: undefined,
        online: navigator === null || navigator === void 0 ? void 0 : navigator.onLine
      }, getConnectionProperty());
    }), 2),
    state = _a[0],
    setState = _a[1];
  react.useEffect(function () {
    var onOnline = function () {
      setState(function (prevState) {
        return __assign(__assign({}, prevState), {
          online: true,
          since: new Date()
        });
      });
    };
    var onOffline = function () {
      setState(function (prevState) {
        return __assign(__assign({}, prevState), {
          online: false,
          since: new Date()
        });
      });
    };
    var onConnectionChange = function () {
      setState(function (prevState) {
        return __assign(__assign({}, prevState), getConnectionProperty());
      });
    };
    window.addEventListener(NetworkEventType.ONLINE, onOnline);
    window.addEventListener(NetworkEventType.OFFLINE, onOffline);
    var connection = getConnection();
    connection === null || connection === void 0 ? void 0 : connection.addEventListener(NetworkEventType.CHANGE, onConnectionChange);
    return function () {
      window.removeEventListener(NetworkEventType.ONLINE, onOnline);
      window.removeEventListener(NetworkEventType.OFFLINE, onOffline);
      connection === null || connection === void 0 ? void 0 : connection.removeEventListener(NetworkEventType.CHANGE, onConnectionChange);
    };
  }, []);
  return state;
}

function useSelections(items, defaultSelected) {
  if (defaultSelected === void 0) {
    defaultSelected = [];
  }
  var _a = __read(react.useState(defaultSelected), 2),
    selected = _a[0],
    setSelected = _a[1];
  var selectedSet = react.useMemo(function () {
    return new Set(selected);
  }, [selected]);
  var isSelected = function (item) {
    return selectedSet.has(item);
  };
  var select = function (item) {
    selectedSet.add(item);
    return setSelected(Array.from(selectedSet));
  };
  var unSelect = function (item) {
    selectedSet.delete(item);
    return setSelected(Array.from(selectedSet));
  };
  var toggle = function (item) {
    if (isSelected(item)) {
      unSelect(item);
    } else {
      select(item);
    }
  };
  var selectAll = function () {
    items.forEach(function (o) {
      selectedSet.add(o);
    });
    setSelected(Array.from(selectedSet));
  };
  var unSelectAll = function () {
    items.forEach(function (o) {
      selectedSet.delete(o);
    });
    setSelected(Array.from(selectedSet));
  };
  var noneSelected = react.useMemo(function () {
    return items.every(function (o) {
      return !selectedSet.has(o);
    });
  }, [items, selectedSet]);
  var allSelected = react.useMemo(function () {
    return items.every(function (o) {
      return selectedSet.has(o);
    }) && !noneSelected;
  }, [items, selectedSet, noneSelected]);
  var partiallySelected = react.useMemo(function () {
    return !noneSelected && !allSelected;
  }, [noneSelected, allSelected]);
  var toggleAll = function () {
    return allSelected ? unSelectAll() : selectAll();
  };
  return {
    selected: selected,
    noneSelected: noneSelected,
    allSelected: allSelected,
    partiallySelected: partiallySelected,
    setSelected: setSelected,
    isSelected: isSelected,
    select: useMemoizedFn(select),
    unSelect: useMemoizedFn(unSelect),
    toggle: useMemoizedFn(toggle),
    selectAll: useMemoizedFn(selectAll),
    unSelectAll: useMemoizedFn(unSelectAll),
    toggleAll: useMemoizedFn(toggleAll)
  };
}

var useEffectWithTarget$1 = createEffectWithTarget(react.useLayoutEffect);

var useIsomorphicLayoutEffectWithTarget = isBrowser ? useEffectWithTarget$1 : useEffectWithTarget;

function useSize(target) {
  var _a = __read(useRafState(function () {
      var el = getTargetElement(target);
      return el ? {
        width: el.clientWidth,
        height: el.clientHeight
      } : undefined;
    }), 2),
    state = _a[0],
    setState = _a[1];
  useIsomorphicLayoutEffectWithTarget(function () {
    var el = getTargetElement(target);
    if (!el) {
      return;
    }
    var resizeObserver = new index$1(function (entries) {
      entries.forEach(function (entry) {
        var _a = entry.target,
          clientWidth = _a.clientWidth,
          clientHeight = _a.clientHeight;
        setState({
          width: clientWidth,
          height: clientHeight
        });
      });
    });
    resizeObserver.observe(el);
    return function () {
      resizeObserver.disconnect();
    };
  }, [], target);
  return state;
}

export { clearCache, useAntdTable, useBoolean, useControllableValue, useCountdown as useCountDown, useDebounceFn, index as useDeepCompareEffect, useKeyPress, useMount, useNetwork, useRequest, useSelections, useSize, useUnmount, useUpdateEffect };
