import React, {memo} from "../../../../__snowpack__/pkg/react.js";
import logoSmallSvg from "../assets/logo-small.svg";
import logoSvg from "../assets/logo.svg";
export const SiderLogo = memo((props) => {
  return /* @__PURE__ */ React.createElement("div", {
    className: `logo-wrapper ${props.collapsed ? "logo-wrapper-collapsed" : ""}`
  }, !props.collapsed && /* @__PURE__ */ React.createElement("div", {
    className: "logo"
  }, /* @__PURE__ */ React.createElement("img", {
    src: logoSvg,
    alt: ""
  })), props.collapsed && /* @__PURE__ */ React.createElement("img", {
    className: "logo-small",
    src: logoSmallSvg,
    alt: ""
  }));
});
