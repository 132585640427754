import {lazy} from "../../lib/lazy.js";
export const productShopifyRoutes = [
  {
    path: "/product-shopify",
    component: lazy(() => import(/* webpackChunkName: "productShopifyRoutes" */ "../../views/product-shopify/list/index.js")),
    auth: "list-product"
  },
  {
    path: "/product-shopify-:action(add)",
    component: lazy(() => import(/* webpackChunkName: "productShopifyRoutes" */ "../../views/product-shopify/edit/index.js")),
    auth: "list-product"
  },
  {
    path: "/product-shopify-:action(edit)/:id",
    component: lazy(() => import(/* webpackChunkName: "productShopifyRoutes" */ "../../views/product-shopify/edit/index.js")),
    auth: "list-product"
  }
];
