import {lazy} from "../../lib/lazy.js";
export const customerRoutes = [
  {
    path: "/customer-management",
    auth: "list-customer",
    component: lazy(() => import(/* webpackChunkName: "customerRoutes" */ "../../views/customer-management/index.js"))
  },
  {
    path: "/customer-management/group-management",
    component: lazy(() => import(/* webpackChunkName: "customerRoutes" */ "../../views/customer-management/GroupManagement.js")),
    auth: "list-customer-group"
  },
  {
    path: "/customer-management/customer-detail",
    component: lazy(() => import(/* webpackChunkName: "customerRoutes" */ "../../views/customer-management/CustomerDetail.js")),
    auth: "list-customer"
  },
  {
    path: "/customer-management/add-customer",
    component: lazy(() => import(/* webpackChunkName: "customerRoutes" */ "../../views/customer-management/NewCustomer.js")),
    auth: "add-customer"
  },
  {
    path: "/customer-management/edit-customer",
    component: lazy(() => import(/* webpackChunkName: "customerRoutes" */ "../../views/customer-management/NewCustomer.js")),
    auth: "edit-customer"
  }
];
