import {useState} from "../../__snowpack__/pkg/react.js";
import {createModel} from "../../__snowpack__/pkg/hox.js";
import {SessionHelper} from "../../__snowpack__/pkg/@genie/lib-iam-utils.js";
export const useLanguage = () => {
  let languageQuery = SessionHelper.getLanguage();
  const languageLocalStorage = localStorage.getItem("language");
  const [language, setLanguage] = useState(languageQuery === "vi" ? "vn" : languageQuery || languageLocalStorage || "en");
  return {
    language,
    setLanguage
  };
};
export const useLanguageModel = createModel(useLanguage);
