import { c as createCommonjsModule } from './common/_commonjsHelpers-64249c85.js';

var en = createCommonjsModule(function (module, exports) {
exports.__esModule = true;
exports.en = void 0;
exports.en = {
    "globalNoData": "No Data",
    "globalPleaseSelect": "Please Select",
    "globalPleaseEnter": "Please Enter",
    "globalsYearly": "Yearly",
    "globalsMonthly": "Monthly",
    "globalsWeekly": "Weekly",
    "globalsYesterday": "Yesterday",
    "globalsExportDisabled": "Expired",
    "globalsExportFailed": "Download failed",
    "globalsExportFinished": "Active",
    "globalsExportINProcess": "Processing",
    "globalsExportStatus": "Status",
    "globalsPackageExpired": "Your package has expired",
    "globalsLater": "Later",
    "globalsContactNow": "Contact Now",
    "globalsContactUsPackage": "Please contact us for package information and quotation",
    "isRequired": "This field is required",
    "globalsGoContinue": "Continue",
    "globalsBrowserBlockedInfo": "It is detected that the new pop-up window has been blocked by the browser, please click \"Continue\" to open a new page window (you can also search for \"Remove Browser Blocking Method/ Cara \" in the Help Center to unblock it)",
    "globalsNotification": "Notification",
    "globalsPleaseSelect": "Please Select",
    "globalsIsRequired": "This field is required",
    "globalsOperationGuide": "Operation guide",
    "globalsSyncError": "Sync failed",
    "globalsSynchronizing": "Syncing",
    "globalsSync": "Sync",
    "globalsSyncSuccess": "Sync completed",
    "globalsNoProductYet": "No product yet",
    "globalsSeeMore": "See { more } Items",
    "globalsSeeMoreRetract": "View less",
    "globalsSeeMoreExpand": "View more",
    "globalsNoName": "No name",
    "globalsTotalPage": "Total { total }",
    "globalsReset": "Reset",
    "globalsSearch": "Search",
    "globalNoEmptyText": "No Data",
    "globalNoPermission": "Your account does not have this permission, please contact store administrator to ask or upgrade",
    "globalAction": "Action",
    "globalTaskResult": "Result",
    "globalTaskFailedNumber": "Failed",
    "globalTaskTotalNumber": "Total",
    "globalTaskSuccessNumber": "Success",
    "globalTaskFailed": "Some orders process failed, please try again later",
    "globalTaskSuccess": "Operation Success",
    "globalTaskWait": "Processing, please wait",
    "globalStatus_unpublished": "Unpublished",
    "globalStatus_update_failed": "Publish Failed",
    "globalStatus_delete": "Deleted",
    "globalStatus_draft": "Draft",
    "globalStatus_disabled": "Disabled",
    "globalStatus_banned": "Banned",
    "globalStatus_sold_out": "Sold Out",
    "globalStatus_live": "Live",
    "globalStatus_process": "In Process",
    "globalStatus_all": "All",
    "globalName": "Global Name EN",
    "goToLinkStoreTip": "No integrated store, please go to and integrate store before add product",
    "goToLinkStore": "Go to integrated store",
    "remove": "Remove",
    "distributionSettings": "Distribution Settings",
    "menuThirdPartyWarehouseProductList": "Third Party Warehouse Product List",
    "serviceLink": "Group Ginee Support",
    "thirdPartyWarehouseStocktakingList": "Third-party Warehouse Stock Pull",
    "explore": "Explore",
    "authPlatform": "Authoriztion",
    "yilianFulfillment": "YiLian Fulfillment",
    "syncdiconnected": "Disconnected",
    "syncsuccess": "Sync completed",
    "syncing": "Synchronizing",
    "commissionSetting": "Commission Settings",
    "LAInvoiceSettingsTab": "Invoice Settings",
    "ExportTemplateTab": "Export Templates",
    "documentTemplatSeettings": "Document Templates",
    "selectfulfillment": "Please select a fulfillment service provider",
    "haventBound": "You haven't bound a fulfillment service provider yet, go to bind now?",
    "newRule": "New Rule",
    "menuProductTiki": "Products / Tiki",
    "logout": "Logout",
    "productTools": "Product Listing",
    "menuCustomer": "Customers",
    "menuPromotion": "Promotions",
    "menuWarehouse": "Warehouses",
    "GineeChat": "Chat",
    "menuGineeCapital": "Capital",
    "addIntegration": "Add Integration",
    "thirdpartyWarehouseList": "Third-Party Warehouse List",
    "menuIntegration": "Integrations",
    "logisticsReport": "Logistics Report",
    "customerReport": "Customer Report",
    "inventoryReport": "Stock Reports",
    "salesReport": "Sales Reports",
    "blacklist": "Blacklist",
    "customerList": "Customer List",
    "discountList": "Discount List",
    "discountManagement": "Discount Management",
    "transfer": "Transfer",
    "stoktaking": "Stocktaking",
    "menuInbound": "Inbound Management",
    "menuOutbound": "Outbound Management",
    "menuProductLocation": "Shelf Management",
    "warehouseList": "Warehouse List",
    "stockPullRecord": "Third-party Warehouse Stock Pull Records",
    "safetyStockManagement": "Safety Stock Management",
    "stock": "Stocks",
    "storeMoving": "Store Clone",
    "productScrape": "Product Scrape",
    "channelProduct": "Channel Products",
    "masterProductList": "Master Product List",
    "orderProcess": "Order Process",
    "allOrders": "All Orders",
    "MenuOrders": "Orders",
    "putAside": "On Hold",
    "autoBump": "Auto Bump",
    "oneStoresProductsToAnyStores": "Copy one store's products to any stores for open shop fast",
    "blibliProductList": "Blibli Product List",
    "FulfillSetting": "Configuration Management",
    "MassAdd": "Mass Add By Template",
    "PromotionEditTpl": "Edit Template",
    "PromotionAddTpl": "New Template",
    "PromotionTpl": "Promotion Template",
    "IntegrationsUpdateStore": "Update Store Information",
    "PrintPickingListTemplate": "Picking List Template",
    "PrintInvoiceTemplate": "Invoice Template",
    "PrintPackingListTemplate": "Packing List Template",
    "PrintLabelTemplate": "Label Template",
    "addBundleISKU": "Add Bundle ISKU",
    "editBundleISKU": "Edit Bundle ISKU",
    "menuInboundManagementSubmit": "Inbound Management / Inbound List Inbound",
    "menuInboundManagementDetail": "Inbound Management / Inbound List Detail",
    "menuInboundManagementEdit": "Inbound Management / Edit Inbound List",
    "menuInboundManagementAdd": "Inbound Management / Create Inbound List",
    "menuOutboundManagementSubmit": "Outbound Management / Outbound List Outbound",
    "menuOutboundManagementDetail": "Outbound Management / Outbound List Detail",
    "menuOutboundManagementEdit": "Outbound Management / Edit Outbound List",
    "menuOutboundManagementAdd": "Outbound Management / Create Outbound List",
    "safetyStockSettings": "Safety Stock Settings",
    "storeStockChangeHistory": "Stock Push Records",
    "stockPullRecordDetail": "Stock Pull Record Detail",
    "thirdpartyWarehouseInventoryPullRecord": "Stock Pull Records",
    "stockInOutRecord": "Stock In/Out Record",
    "editWarehouseStock": "Edit Warehouse Stock",
    "editLocalISKU": "Edit Master SKU",
    "editISKU": "Edit Inventory SKU",
    "binPsku": "Bind Store SKU",
    "addISKU": "Add Inventory SKU",
    "EditOrderTpl": "Edit Template",
    "AddOrderTpl": "Add Template",
    "CRMHistory": "History",
    "CRMSales": "Sales Management",
    "CRMTelemarketing": "Telemarketing Management",
    "SubscribeNow": "Subscribe now",
    "RenewNow": "Renew Now",
    "PaymentSubscriptionPrice": "Package & Price",
    "PaymentSubscriptionResult": "Payment Result",
    "AccountPackageSetting": "Account & Current Package",
    "PackageSubscription": "Subscribe",
    "TransactionHistory": "Transaction History",
    "menuScrapingSettings": "Scraping Settings",
    "ExclusiveSubscription": "Popular Following",
    "TopPopularStore": "Popular Stores",
    "AccountManagement": "Package Management",
    "OrderBatchLabel": "Mass Print",
    "GineeChartRoom1": "Omni Chat",
    "InvoicePrintTemplateSettings": "Invoice Print Template Settings",
    "PackingListPrintTemplateSettings": "Packing List Print Template Settings",
    "ShippingLabelTemplateSettings": "Shipping Label Template Settings",
    "SenderInformationSettings": "Sender Information",
    "ShippingRuleSettings": "Shipping Methods",
    "GINEEFulfillment": "Ginee Fulfillment",
    "YUEHAIFulfillment": "YUEHAI Fulfillment",
    "FASTOCKFulfillment": "FASTOCK Fulfillment",
    "JDFulfillment": "JD Fulfillment",
    "OrderSettings": "Order Settings",
    "CollectEdit": "Collect to Master Product",
    "CollectSetting": "Scraping Settings",
    "ScrapeList": "Scrape List",
    "ReportChart": "Reports",
    "ProductsCollection": "Product Scrape",
    "editrole": "Edit Role",
    "addrole": "Add Role",
    "RoleSetting": "Role Settings",
    "StaffSetting": "Staff Settings",
    "addstaff": "Add Staff",
    "staffManagementAdd": "Staff Management/Add Staff",
    "staffManagementEdit": "Staff Management/ Edit Staff",
    "editstaff": "Edit Staff",
    "subscribe": "Subscribe",
    "days": "Days",
    "freetrial": "Free Trial",
    "contactus": "Contact Us",
    "EditCustomer": "Edit Customer",
    "PromotionManagement": "Promotion Management",
    "mProductSettings": "Master Product Settings",
    "productSettings": "Product Settings",
    "CustomerDetail": "Customer Detail",
    "NewCustomer": "Add Customer",
    "GroupManagement": "Customer Group",
    "CustomerManagement": "Customer",
    "MasterProductSettings": "Master Product Setting",
    "StockSetting": "Stock Settings",
    "menuAddProductToWarehouseEditStock": "Fill in Stock",
    "menuAddProductToWarehouse": "Add product to warehouse",
    "stockManageList": "Stock List",
    "stockManage": "Inventory Management",
    "BarChart": "Business Analytics",
    "Masseditcategories": "Mass edit categories or attributes",
    "MassEdit": "Mass Edit",
    "ShippingSetting": "Shipping Settings",
    "BasicInformation": "Basic Information",
    "BasicSetting": "Basic Settings",
    "ToolTipInfo": "Includes: Available, Unavailable, Sold out, Banned products",
    "Result": "Result",
    "PublishWait": "Please wait for the publish result of channel",
    "UpdateFailed": "Update Failed",
    "Publishing": "Products Copied as Draft",
    "TryAgain": "Try Again",
    "SomeStoresCopyFailed": "Some Stores Copy Failed",
    "CopySuccess": "Copy Success",
    "ViewNow": "View Now",
    "Close": "Close",
    "NoCopyStore": "There's no other linked stores can be copied.Hurry and authorize the store to try",
    "CopyPublish": "Publish",
    "CopyAsDraft": "Copy as Draft",
    "CopyCount": "Products are waiting to be copied",
    "Live": "Live",
    "All": "All",
    "Product": "Product",
    "Store": "Store",
    "Channel": "Channel",
    "CopyTo": "Copy To",
    "CopyFrom": "Copy From",
    "CopyAlertInfo": "Copy one store's products to any stores in the same channel for open shop fast.",
    "OperationGuide": "Operation Guide",
    "CopyStore": "Copy Store",
    "AddStore": "Add Store",
    "Copy": "Copy",
    "StoreList": "Store List",
    "MyStores": "Store Clone",
    "customerCenter": "Hi, how can we help you? Please feel free to contact us at 09:00-18:00",
    "addStore": "Add Store",
    "helpInfo": "Help Center",
    "addSyncStore": "Add Integrations",
    "lastSyncTime": "Last Sync Time",
    "alreadyStore": "Your Integrated Stores",
    "menuProductLocalUpdateAutoBind": "Master / Update Auto-Bind rules",
    "menuInboundManagement": "Inbound Management",
    "menuOutboundManagement": "Outbound Management",
    "menuLowStockReport": "Slow Selling",
    "menuLogisticsCostStatistics": "Logistics Cost Statistics",
    "menuOutStockForecast": "Out-of-Stock Forecast",
    "menuInventoryStatistics": "Inventory Statistics",
    "menuLogisticsStatistics": "Logistics Statistics",
    "menuCustomerAreaAnalysis": "Customer Area Analysis",
    "menuCustomerAnalysis": "Customer Analysis",
    "menuCustomerStatistics": "Customer Statistics",
    "menuStoreSaleReport": "Store Sales",
    "menuPlatformSaleReport": "Channel Sales",
    "menuIncomeStatementReport": "Income Statement",
    "menuCategorySaleReport": "Variation Sales",
    "menuProductSaleReport": "Product Sales",
    "menuVariantsSalesStatistics": "Variation Sales",
    "menuOrderReport": "Order Report",
    "menuSetting": "Settings",
    "ShippingOrderNew": "Ship Orders",
    "status_ready_to_ship": "Ready To Ship",
    "printOrderPending": "Pending Print Orders",
    "beCollectedTo": "Waiting for Pickup",
    "orderAbnormal": "Abnormal Order",
    "PendingOrder": "Pending Order",
    "pending": "Pending",
    "operationRecord": "Operation Records",
    "historyOperation": "Shipping Warehouse Operation Log",
    "shipped": "Shipped",
    "stored": "On Hold",
    "warehouseSettingShipping": "Shipping Warehouse",
    "orderDetails": "Order Details",
    "manualOrder": "Manual Order",
    "menuOrder": "Orders",
    "menuIntegrations": "Integrations",
    "menuProductLocationManagement": "Shelf Management",
    "menuProductLocationManagementOld": "Product Location Management",
    "menuWarehouseStockDetails": "Warehouse Stock Details",
    "menuStockPushRules": "Stock Push Rules",
    "menuStoreShippingWarehouseSettings": "Store Shipping Warehouse Settings",
    "menuWarehouseManagement": "Warehouse Management",
    "menuPriceManagement": "Price Management",
    "menuProductLocalImportToCreate": "Master / Import to Create Product",
    "menuProductLocalImportToBind": "Master / Import to Bind Product",
    "menuProductLocalEditBundle": "Master / Edit Bundle Product",
    "menuProductLocalEdit": "Master / Edit Product",
    "menuProductLocalAddBundle": "Master / Add Bundle Product",
    "menuProductLocalEditCombined": "Master / Edit Combination Products",
    "menuProductLocalAddCombined": "Master / Add Combined Product",
    "menuProductLocalAdd": "Master / Add Product",
    "menuProductWooCommerce": "Products / WooCommerce",
    "menuBindHistory": "Bind History",
    "menuProductLocal": "Master Products",
    "menuProduct": "Product List",
    "menuPoints": "Points",
    "menuMemberShipPage": "Membership Page",
    "InventoryLog": "Stock Change Records",
    "menuMemberList": "Member List",
    "noticeList": "Notice",
    "menuHomePage": "Dashboard",
    "productmassEditMaster": "Master Product/Mass Edit",
    "productPublishToTheStoreMaster": "Master Product/Publish to the store",
    "resultsPublish": "Publish Results",
    "operationHistoryMass": "Mass Operation History",
    "menuCapital": "Capital",
    "menuProductTikTok": "Products / TikTok",
    "menuProductTikTokEdit": "Products / TikTok/ Edit Product",
    "menuProductTikTokAdd": "Products / TikTok / Add Product",
    "menuProductZalora": "Products / Zalora",
    "menuProductShopee": "Products / Shopee",
    "ImportAdd": "Mass Add By Import",
    "menuProductBlibliEdit": "Products / Blibli/ Edit Product",
    "menuProductShopeeEdit": "Products / Shopee/ Edit Product",
    "menuProductBlibliAdd": "Products / Blibli / Add Product",
    "menuProductShopeeAdd": "Products / Shopee / Add Product",
    "publishToStoreTip": "Master Product/Publish to the store",
    "changePassword": "Change Password",
    "passworderror": "Password must be at least 8 characters, numbers, and special characters.",
    "pwdErrorInfo": "Password must be between 8 - 20 characters",
    "UpdateSuccess": "Update Success",
    "storeDiagnose": "Store Diagnosis",
    "shopeeAutoBump": "Shopee Auto Bump",
    "menuscrapeList": "Scrape List",
    "chromeExtension": "Chrome Extension",
    "getFreeDataTool": "Get Free Data Tool",
    "bigdata": "Explore",
    "free": "Free",
    "DataExtension": "Data Scraper Extension",
    "confirm": "Confirm",
    "noIndonesianLineGroup": "No Indonesian Line Group",
    "noEnglishLineGroupTemporarily": "No English Line Group Temporarily",
    "noThaiGroupsForWhatsapp": "No Thai Groups for Whatsapp",
    "noVietnameseLineGroup": "No Vietnamese Line Group",
    "noVietnameseWhatsappGroups": "No Vietnamese Whatsapp Groups",
    "noChineseWhatsappGroupTemporarily": "No Chinese Whatsapp Group Temporarily",
    "noChineseLineGroupTemporarily": "No Chinese Line Group Temporarily",
    "storeLimitTip": "The package you subscribed has expired. If you want to use the free package, please select 2 stores to continue binding (the free package can be bound maximum to 2 stores, and the rest  will be unbound).",
    "max2Store": "The free package can be bound maximum to 2 stores",
    "publishToTheStore": "Publish to the store",
    "createMasterProduct": "Create Master Product",
    "confirmedNewPassword": "Confirmed new password",
    "newPassword": "new password",
    "oldPassword": "Old Password",
    "detail": "Detail",
    "editProduct": "Edit Product",
    "orderXxxFailedToPushWarehouse": "Order xxx failed to push warehouse, reason: xxx",
    "orderXxxPushedToWarehouseSuccessfully": "Order xxx pushed to warehouse successfully",
    "shippingToWarehouseOperationSucceeded": "Shipping to warehouse operation succeeded",
    "shippingToWarehouseOperationIsSuccessful": "Shipping to warehouse operation is successful, please wait patiently for the warehouse to processing results",
    "currentlyTiktokOrdersAreNotSupported": "Currently, Tiktok orders are not supported to arrange shipment and print label through OpenAPI.",
    "shopeeOrdersWithTheSameLogistics": "Shopee/Tiktok orders with the same logistics, support mass set up pickup time",
    "gineeSupportsSettingUpAutomaticShipping": "Ginee supports setting up automatic shipping methods for Shopee/Bukalapak/Tokopedia/Akulaku/TikTok stores",
    "xxxStockDeductionProcessingEnableddisabled": "XXX  stock deduction processing enabled/disabled successfully",
    "theEffectiveTimeOfXxx": "The effective time of xxx  stock deduction processing is changed from xxx to xxx",
    "pleaseOpenAtLeastOneStore": "Please open at least one store",
    "thereIsAnUnfinishedOrderExport": "There is an unfinished order export task, please go to the export history to view the export record, and operate the export again when the task is completed",
    "theSystemServiceIsAbnormalPlease": "The system service is abnormal, please refresh the page and try again",
    "afterTurnOffTheOrderWill": "After turn off, the order will be allocated to the warehouse according to the priority of the warehouse shipping rule",
    "pleaseConfirmWhetherWantToPush": "Please confirm whether want to push the third-party warehouse for delivery?",
    "pleaseConfirmWhetherWantToRedistribute": "Please confirm whether want to re-distribute?",
    "outboundOrder": "Outbound Order",
    "splitOrder": "Split Order",
    "abnormalDescription": "Abnormal Description",
    "problemOrder": "Problem Order",
    "redistribute": "Re-distribute",
    "deliveryWarehouse": "Delivery Warehouse",
    "nonindonesiaOrdersDoNotSupportXxx": "Non-Indonesia orders do not support xxx warehouse delivery, please adjust the delivery warehouse",
    "theOrderDoesNotSupportDelivery": "The order does not support delivery from xxx, please adjust the delivery warehouse",
    "theAvailableStockOfMskuXxx": "The available stock of MSKU xxx is insufficient, please re-distribute after replenishing the stock",
    "mskuXxxIsNotAssociatedWith": "MSKU xxx is not associated with xxx warehouse, please add product to warehouse and re-distribute the product",
    "channelSkuXxxIsNotBound": "Channel SKU xxx is not bound to MSKU, please add the mapping relationship and re-distribute",
    "channelSkuXxxDoesNotHave": "Channel SKU xxx does not have allocated  warehouse, please add a shipping warehouse rule and re-distribute product",
    "failedCreateOutboundOrder": "Outbound Creation Failed",
    "warehouseAllocationError": "Warehouse Allocation Error",
    "mskuNotAssociatedWithWarehouse": "MSKU Not Associated with Warehouse",
    "mskuNotMatched": "Not Bound to MSKU",
    "unallocatedWarehouse": "Unallocated Warehouse",
    "pushTheThirdPartyWarehouse": "Push to Third-party Warehouse",
    "paidOrder": "Paid Order",
    "waitingPaymentOrder": "Order placed",
    "alreadyInbound": "Already Inbound",
    "pleaseConfirmWhetherItIsAutomatically": "Please confirm whether it is automatically returned and inbound?",
    "inboundReturnProduct": "Inbound Return",
    "abnormalType": "Abnormal Type",
    "printTime": "Print Time",
    "warehouseoutboundOrder": "Warehouse/Outbound Order",
    "variantsku": "Variant/SKU",
    "afterOpeningTheOrderWillBe": "After opening, the order will be automatically pushed to the third-party warehouse if the order meets the delivery conditions.",
    "automaticallyPushToThirdpartyWarehouse": "Automatically Push to Third-Party Warehouse",
    "inventoryVerificationAfterItIsTurned": "Stock Checking: After it is turned on, it will verify whether the available stock in the warehouse is sufficient, and give priority to the warehouse with sufficient inventory for delivery.",
    "availableStockDeliveryDisabledSuccessfully": "'Available stock delivery'  disabled successfully",
    "availableStockDeliveryActivatedSuccessfully": "'Available stock delivery'  activated successfully",
    "shippingWarehouseSettingsDisabledSuccessfully": "Shipping warehouse settings disabled successfully",
    "shippingWarehouseSettingsActivatedSuccessfully": "Shipping warehouse settings activated successfully",
    "orderstockDeductionProcessDisabledSuccessfully": "Order-stock deduction process disabled successfully",
    "theStockDeductionProcessingOfXxx": "The stock deduction processing of xxx store is enabled/disabled successfully, and the effective time is changed from xxx to xxx",
    "orderstockDeductionProcessSuccessfullyActivated": "Order-stock deduction process successfully activated",
    "afterActivatedOrdersWillBeAssigned": "After activated, orders will be assigned to warehouses according to different product, and the same order may be assigned to different warehouses for delivery; otherwise, the order will be assigned to the same warehouse for delivery",
    "shipmentWarehouseSettingsDeterminesWhetherWarehouse": "Shipment Warehouse Settings: Determines whether warehouse distribution is based on orders or products",
    "effectiveTime": "Effective Time",
    "activateStatus": "Activate Status",
    "activateRange": "Activate Range",
    "onceTurnOffAllStoreOrders": "Once turn off, all store orders will not be subject to inventory processing",
    "afterActivatedTheOrderWillGenerate": "After activated, the order will generate an outbound order to deduct the stock according to the process; otherwise, the the stock will not be processed when the order is shipped",
    "orderstockDeductionProcess": "Order-Stock Deduction Process",
    "orderCreatedSuccessfully": "Order Created Successfully",
    "shipmentWarning": "Shipment Warning",
    "withoutUnboxingVideosComplaintsOfLessdamage": "WITHOUT UNBOXING VIDEOS, COMPLAINTS OF LESS/DAMAGE ITEMS WILL NOT BE ACCEPTABLE",
    "shippingRebate": "Shipping Rebate",
    "shippingFeePaidByBuyer": "Shipping Fee Paid by Buyer",
    "operationIsTooFrequentPleaseExport": "Operation is too frequent, please export again after 1 minute",
    "rdPartyLogisticsShippingFee": "3rd Party Logistics Shipping Fee",
    "shippingSubtotal": "Shipping Subtotal",
    "importFailedInternalSystemErrorPlease": "Import failed: Internal system error, please re-import",
    "emailFormatIsIncorrect": "E-mail format is incorrect",
    "supportsNumberslettersMaxCharacters": "Supports numbers. letters, and \"-\". Max. 30 characters",
    "supportsNumberslettersspacesMaxCharacters": "Supports numbers/letters/spaces/-, max. 20 characters",
    "basedOnProductSeparateRowFor": "Based on Product (Separate row for order with multiple product)",
    "basedOnOrderOrderWithMultiple": "Based on Order (Order with multiple product will combined)",
    "exportMethod": "Export Method",
    "pleaseConfirmWhetherTheOrderStatus": "Please confirm whether the order status is set to Returned?",
    "editOrder": "Edit order",
    "insufficientStockOfXxxPleaseMake": "Insufficient stock of xxx, please make up the stock before deliver product",
    "paymentProblem": "Payment problem",
    "invalidOrder": "Invalid order",
    "moveToReturned": "Move To Returned",
    "selectCustomer": "Select customer",
    "CustomerMobile": "Mobile",
    "fyiPleaseDeleteWhenUploading": "FYI, please delete when uploading",
    "pleaseDeleteThisTextBoxBefore": "Please delete this text box before importing",
    "fillInWithTwocharacterShortCode": "Fill in with two-character short code for the country. For payment, fill with pre-payment/payment method. The payment amount cannot be greater than the total order amount",
    "multipleMskusunitPricesquantitiesInTheSame": "Multiple MSKUs/unit prices/quantities in the same order must be entered on a new line. The unit price must not be less than 0, and the quantity must be greater than 0. When entering multiple lines, the remaining fields of the order can be blank.",
    "orderNumberOnlySupportsLettersNumbers": "Order number only supports letters, numbers, - /",
    "fieldWithMarkAreRequired": "Field with * mark are required to fill",
    "pleaseEditInAccordanceWithThe": "Please edit in accordance with the template order",
    "importantNotice": "Important Notice",
    "theNumberOfDataRecordsCannot": "The number of data records cannot exceed 5000",
    "pleaseImportWithDownloadedFormatTemplate": "Please import with downloaded format template, the header cannot be modified",
    "onlySupportFilesInXlsxFormat": "Only support files in xlsx format (manually modifying the file suffix is invalid)",
    "downloadImportTemplate": "Download import template",
    "importOrder": "Import",
    "uploadPaymentProof": "Upload Payment Proof",
    "paymentProof": "Payment Proof",
    "receivingAccount": "Receiving Account",
    "paymentAccount": "Payment Account",
    "receiptNumber": "Receipt Number",
    "theTotalPaymentAmountCannotExceed": "The total payment amount cannot exceed the total order amount",
    "addPaymentRecord": "Add Payment Record",
    "refer": "Refer",
    "useCommaToSeparateMultipleItem": "Use comma to separate multiple item",
    "saveContinueAdd": "Save and Add New Order",
    "save": "Save",
    "paymentSerialNumber": "Payment Serial Number",
    "paymentAmount": "Payment Amount",
    "productTotalAmount": "Product Total Amount",
    "totalQuantity": "Total Quantity",
    "totalPrice": "Total Price",
    "addProduct": "Add Product",
    "recipientArea": "Recipient Area",
    "remarks": "Remarks",
    "paymentInfo": "Payment Info",
    "amountInfo": "Amount Info",
    "productInfo": "Product Info",
    "receiptInfo": "Receipt Info",
    "basicInfo": "Basic Info",
    "referenceOrder": "Reference Order",
    "canBeEnteredQuicklyByImporting": "Can be entered quickly by importing existing information",
    "addOrder": "Add Order",
    "bindStoreNow": "Bind Store Now",
    "bindTips": "Because you have not integrated the channel store yet, the current order list is empty. Please bind the store first and then view or operate the order",
    "PlatformDiscount": "Platform Discount",
    "TotalDiscount": "Total Discount",
    "voidTipss": "When you move an order to On Hold, it no longer supports any order operations within the system",
    "voidTips": "Once the order has been move to On Hold, it will no longer participate in the stock lock/deduction. You must restore the order before the stock can be locked/deducted normally. Please operate with caution.",
    "pickingList": "Picking List",
    "printedBy": "Printed By",
    "supportTikiAndZaloraOrdersToComplete": "Support Tiki and Zalora orders to complete the batch receipt. For Blibli orders, please go to order details to operate",
    "onlyLazadaBlibiliAndZaloraSupportPackaging": "Only Lazada, Blibli and Zalora orders support packaging. And do not support secondary packaging for order that already packed. If you need to modify the quantity of Blibli orders, please update in the order details",
    "confirmWhetherToSetTheOrderStatusAsShipped": "Please confirm whether to set the order status as shipped",
    "markAsShipped": "Mark as Shipped",
    "whetherToUseGineeTemplateToPrintInvoices": "Whether to use Ginee's template to print invoices(Lazada PH orders only support printing invoices using Ginee’s template)",
    "buyerPayment": "Buyer Payment",
    "doAllProductInTheSameOrder": "Do all product in the same order will use same invoice number",
    "clickHereToSetting": "Click here to setting",
    "operationLog": "Operation Records",
    "massSetPickupTime": "Mass Set Pickup Time",
    "numberOfFails": "Number of Fails",
    "totalNumber": "Total Number",
    "invoiceNumberSettingFailureDetails": "Invoice number setting failure details",
    "invoiceNumberIsSetSuccessfully": "Invoice number is set successfully",
    "clickHereToSetWhetherAll": "Click here to set whether all products in the same order use the same invoice number",
    "setInvoiceNumber": "Set Invoice Number",
    "printShippingLabelAndPickingLists": "Print Shipping Label and Picking List",
    "pleaseConfirmWhetherTheReceiptIs": "Please confirm whether the receipt is completed?",
    "onlyTikiOrdersCanBeReceived": "Only Tiki orders can be received in batches. For Blibli orders, please go to order details to operate",
    "malaysiaTemplates": "Malaysia Templates",
    "generalTemplates": "General Templates",
    "unassignedWarehouse": "Unallocated Warehouse",
    "jikaPesananTelahDikaitkanDenganGudang": "Jika pesanan telah dikaitkan dengan gudang pihak ketiga, harap konfirmasi apakah pesanan outbound telah berhasil dibatalkan oleh gudang untuk mencegah kesalahan inventaris",
    "inOrderToEnsureTheNormal": "In order to ensure the normal use of system functions, the order download function will not be available during the 11.11~11.12 promotion period. Thank you for your support",
    "warehouse": "Warehouse",
    "unpairedMasterSKU": "Unpaired Master SKU",
    "postalCode": "Postal Code",
    "detailsAddress": "Details Address",
    "street": "Street",
    "districts": "Districts",
    "city": "City",
    "province": "Province",
    "senderPhone": "Sender Phone",
    "senderName": "Sender name",
    "dataLoadingPleaseWaitOrClick": "Data loading, please wait or click the back button to skip",
    "activatedAllOrdersFromTheAuthorized": "Once activated, all orders from the authorized stores need to be shipped after \"order packing\"",
    "restrictionShippingOfOrdersMustBe": "Shipment Restriction: Shipping of orders must be prioritized after order packing.",
    "shippingDiscountSeller": "Seller Shipping Discount",
    "feeDiscountFromPlShipping": "Shipping Fee Discount From 3pl",
    "taxEscrow": "Escrow Tax",
    "transactionFeeBuyer": "Buyer Transaction Fee",
    "transactionFeeSeller": "Seller Transaction Fee",
    "SetPackStatusTitle": "Set Pack Status",
    "SetPackStatusInMass": "Set Packing Status in mass",
    "timeUpdate": "Update Time",
    "timeCreation": "Create Time",
    "contentOperation": "Operation Content",
    "moduleOperation": "Operation Module",
    "recordOperation": "Operation Records",
    "orderRestore": "Restore Order",
    "inToAlreadyShippedMove1": "Move to Shipped",
    "warehouseThirdparty": "Third-party Warehouse",
    "warehouseOwn1": "Own Warehouse",
    "inToAlreadyShippedMove": "Move to Shipped",
    "inToBeCollectedMove": "Move to Waiting for Pickup",
    "numberEmptyAwb": "AWB number empty",
    "printedNot": "Not printed",
    "youWantToOperateSeparatelyPlease": "If you want to operate separately, please move to the list of all orders to complete the operation",
    "ordersWillBeDirectlyPackagedAnd": "Lazada/Blibli orders will be directly packaged and shipped",
    "processingTheTokopediabukalapakOrderWillBe": "After processing, the Tokopedia/Bukalapak order will be accepted and shipped",
    "youSureToProcessTheSelected": "Are you sure to process the selected X orders together and move them to the pending order?",
    "failedCancel": "Cancel Failed",
    "pushToThirdpartyWarehouseFailed": "Failed push to third-party warehouse",
    "toPendingMove": "Move to pending",
    "asPriorityMark": "Mark as priority",
    "orderOutOfStockAll": "All order out of stock",
    "orderOutOfStockSelected": "Selected order out of stock",
    "stockCheck": "Check stock",
    "skuXxxxIsInsufficientInThe": "Master SKU: {x} is insufficient in the {y} warehouse, please reprocess it after restock in the inventory management operation",
    "skuXxxxDoesNotExistIn": "Master SKU: XXXX does not exist in XXXX warehouse. Please reselect the warehouse by replacing the master SKU or add a new master SKU in the warehouse",
    "warehouseToShipmentPush": "Push Warehouse to Shipment",
    "orderProcessing": "Processing order",
    "processingFailed": "Failed processing",
    "ofStockOut": "Out of Stock",
    "warehouseOwn": "Own Warehouse",
    "failedOrdersView": "View failed orders",
    "failed": "Failed",
    "success": "Success",
    "total": "Total",
    "descriptionXxOrdersHaveBeenSynchronized": "Progress description: XX orders have been synchronized, and the remaining XX orders are waiting to be synchronized",
    "pleaseWaitSyncing": "Syncing, please wait",
    "result": "Result",
    "reasonFailed": "Failed reason",
    "exceptionSynchronization": "Synchronization exception",
    "otherLogisticsProviderNumberEmptyPlaceholder": "Has not yet generate shipping number, please sync it later",
    "theCheckIsRemovedTheIntegrated": "After the check is removed, the integrated master product stock will not increase",
    "selectedTheIntegratedMasterProductStock": "After selected, the integrated master product stock will increase",
    "ifNoRuleIsAddedAfter": "Tip: If no rule is added, after the order is changed to the canceled status, the product stock of all integrated master products in the order will be automatically restocked",
    "forTheProductStockOfThe": "Only for the product stock of the matched master product in the order, if the master product is not integrated, it will not be processed",
    "theReasonForCancellationIs1": "If the reason for cancellation is ##, the product will not be restocked",
    "theReasonForCancellationIs": "If the reason for cancellation is ##, update the product to restock",
    "operationContent": "Operation Content",
    "delete": "Delete",
    "updateTime": "Update Time",
    "createTime": "Create Time",
    "cancelReason": "Cancel Reason",
    "operator": "Operator",
    "pleaseSelectStore": "Please select store",
    "pleasesSelectChannel": "Please select channel",
    "no": "No",
    "yes": "Yes",
    "updateWarehouseStock": "Restock Product",
    "unknownReason": "Unknown Reason",
    "buyerCanceled": "Buyer Canceled",
    "systemCancel": "System Cancel",
    "cancelSetting": "Cancel Setting",
    "kodePos": "Kode Pos",
    "nameFirst": "First Name",
    "nameLast": "Last Name",
    "staff": "Staff",
    "secretary": "Secretary",
    "receptionist": "Receptionist",
    "security": "Security",
    "driver": "Driver",
    "helperDomestic": "Domestic Helper",
    "relationship": "Relationship",
    "mrmrsms": "Mr/Mrs/Ms",
    "spouse": "Spouse",
    "related": "Related",
    "lessThanCharactersRequired": "Required, less than 30 characters",
    "codeSettlement": "Settlement No.",
    "statusReceiver": "Receiver Status",
    "nameReceiver": "Receiver Name",
    "productDateReceived": "Received Product Date",
    "pleaseCompleteTheFollowingInformationAnd": "Tips: Please complete the following information and update the order to completed status",
    "completedOrder": "Order Completed",
    "cancel": "Cancel",
    "noMoreThanCharactersRequired1": "Required, no more than 300 characters",
    "ofThePerformanceReasonsPart": "Part of the Performance Reasons",
    "quantityFinal": "Final Quantity",
    "ifTheCurrentOrderOnlySupports": "Tips: If the current order only supports partial fulfillment, please update the quantity of product. If the quantity of all product in the current order is updated to 0, the order will be cancelled",
    "quantityChange": "Change quantity",
    "endTimeMustBeGreaterThan": "The end time must be greater than the start time",
    "noMoreThanCharactersRequired": "Required, no more than 300 characters",
    "fieldsRequired": "Required Fields",
    "installationnote": "InstallationNote",
    "installationmobile": "InstallationMobile",
    "installationofficer": "InstallationOfficer",
    "endDateInstallation": "Installation End Date",
    "startDateInstallation": "Installation Start Date",
    "endDateShipping": "Shipping End Date",
    "startDateShipping": "Shipping Start Date",
    "nameCourrier": "Courrier Name",
    "toPackSomeOrdersPleaseTry": "Failed to pack some orders. Please try again",
    "successfullyPackaged": "Packaged Successfully",
    "pleaseWaitPacking": "Packing, Please Wait",
    "quantity": "Qty",
    "stockAvailablelocked": "Available/Locked Stock",
    "skuwarehouseMaster": "Master SKU/Warehouse",
    "nameProduct": "Product Name",
    "numberOrder": "Order Number",
    "onlyLazadaAndBlibliOrdersSupport": "Tips: Only Lazada and Blibli orders support packing, and the packaged ones do not support secondary packing. If you need to modify the quantity of Blibli orders, please update in the order details",
    "theProductsOfTheSameOrder": "Pack the products of the same order in Blibli (limited to Regular product / Big product)",
    "pack": "Pack",
    "stockAvailable": "Available",
    "serviceProviderFulfillment": "Fulfillment",
    "skuMaster": "Master SKU",
    "nameWarehouse": "Warehouse Name",
    "serviceProvider": "Service Provider",
    "theMasterSkuReplacing": "Replacing the master SKU",
    "withXxxxxxPaired": "Paired with XXXXXX",
    "reasonReturn": "Return Reason",
    "itemsRestock": "Restock Items",
    "amountRefund": "Refund Amount",
    "shippingReturn": "Return Shipping",
    "quantityReturn": "Return Quantity",
    "totalRefundable": "Total Refundable",
    "sendNotificationsToCustomers": "Send notifications to customers",
    "totalRefund": "Total Refund",
    "productRefund": "Product Refund",
    "ifYouChooseToIncreaseStock": "Tips: If you choose to increase stock, the stock of product with returned quantity will increase",
    "refund": "Refund",
    "buyerInfoEdit": "Edit buyer Info",
    "location": "Location",
    "other": "Other",
    "declinedPayment": "Payment declined",
    "orderFraudulent": "Fraudulent order",
    "unavailableItems": "Items unavailable",
    "changedcanceledOrderCustomer": "Customer changed/canceled order",
    "CreditCardTransactionFee": "Credit Card Service  Fee",
    "moreThan5": "More than 5 days",
    "fiveDays": "Within 5 day",
    "threeDays": "Within 3 day",
    "twoDays": "Within 2 day",
    "oneDays": "Within 1 day",
    "noNotes": "No Notes",
    "hasNotes": "Has Notes",
    "noMessage": "No Message",
    "hasMessage": "Has Message",
    "multipleProduct": "Multiple Product",
    "oneProductWithMoreThanOneQty": "1 Product with more than 1 Qty",
    "singleProduct": "Single Product",
    "jdTip": "Notification: Affected by the Channel API, JD.ID Stores that are integrated from April 29, 2021 will experience orders that can not be synchronized, JD.ID Team is now actively repairing this, sorry for the inconvenience",
    "orderIdSearchTips": "Use \",\" to seperate order numbers",
    "lastSync": "Last Sync",
    "sortBy": "Sort by",
    "asc3": "Ascending",
    "desc2": "Descending",
    "desc4": "Descending",
    "asc2": "Ascending",
    "desc": "Descending",
    "asc": "Ascending",
    "zToA": "(Z-A)",
    "aToZ": "(A-Z)",
    "payTime": "Payment Time",
    "deliverDeadline": "Delivery Deadline",
    "productSku": "Product SKU",
    "failnumber": "Failed Number",
    "sucessnumber": "Success Number",
    "totalnumber": "Total Number",
    "lazadatip": "There may be delays in order synchronization during the Lazada campaign (March 26 to March 31), And during Lazada API restriction, you cannot edit product information (including inventory and price) or publish new products via Ginee. We apologize for the inconvenience",
    "keyword": "Keywords",
    "logisticsProviderNumberEmptyPlaceholder": "Shopee has not yet generate shipping number, please sync it later",
    "logisticsProviderNameEmptyPlaceholder": "Shopee has not yet assigned logistics, please sync it later",
    "PrintPdfErrorMessage": "File generation failed, please try again later",
    "orderDetailNoteLimit": "less than 500 characters",
    "GoContinue": "Continue",
    "BrowserBlockedInfo": "It is detected that the new pop-up window has been blocked by the browser, please click \"Continue\" to open a new page window (you can also search for \"Remove Browser Blocking Method \" in the Help Center to unblock it)",
    "Notification": "Notification",
    "ShowLabelProductInfo": "(Bukalapak)label displays product info",
    "VoucherPlatform": "Channel Voucher",
    "PriceCreditCardServiceFee": "Credit Card Service  Fee",
    "PriceSellerReturnRefundAmount": "Refund Amount",
    "PriceCoinCashBack": "Seller Cashback Coin",
    "PriceCoin": "Coin",
    "AllStatus": "All Status",
    "AllType": "All Type",
    "theMarketplacePromotionPeriodPleaseUnderstand": "During the marketplace promotion period, please understand if there may be delay in order synchronization",
    "pendingTableTips": "Process Order currently only available for Shopee/Bukalapak/Tokopedia/Lazada/JD/Shopify",
    "Filter": "Filter",
    "InvoiceSettings": "Invoice Settings",
    "MultipleProduct": "Multiple Product",
    "OneProductOneQty": "1 Product with more than 1 Qty",
    "paidTime": "Payment Time",
    "packageStatus": "Packing Status",
    "shipBefore": "Ship Before",
    "sellerNote": "Seller Notes",
    "buyerNote": "Buyer Notes",
    "packageType": "Package Type",
    "SingleProduct": "Single Product",
    "orderProductType": "Product Type",
    "ViewFailedList": "View Failed List",
    "AWBTrackingNumber2": "AWB/Invoice",
    "PickUpTime": "Pickup Time",
    "NoEmptyText": "No Data",
    "OrdersFailedTips": "Some orders failed to ship, please try again later",
    "SuccessfullyShipped": "Successfully Shipped",
    "TotalOrdersShipped": "Total orders to be shipped:",
    "ShippingWait": "Shipping, Please wait...",
    "action": "Action",
    "SeeMoreRetract": "View less",
    "FailedReason": "Failed Reason",
    "RecipientMobile": "Recipient Mobile No.",
    "SetNow": "Set Now",
    "SkipNext": "Skip, Next Step",
    "shippingTips3": "Limited by the JD.ID API, shipping label needs to be printed before shipping, if not printed, will not be able to ship",
    "shippingTips2": "If you haven't set it, system will automatically assign the shipping method to Pickup (Highest priority) or Dropoff or Manual Ship",
    "shippingTips1": "Part of Logistics does not have shipping method, please complete the Shipping Method setting first before arranging shipment",
    "FilterOrder": "Filter Order",
    "setAddress": "No address yet, please go to Seller Center to set",
    "ArrangeShipment2": "Arrange Shipment",
    "MassUpload": "Mass Upload",
    "ShippingCarrier": "Shipping Carrier",
    "OrderQuantity": "Order Quantity",
    "DownloadTemplate": "Download Template",
    "FileSizeLimitTips": "Only supports xlsx/xls files smaller than 5M",
    "ImportFile": "Import File",
    "FileFormatInvalid": "The import file format is incorrect",
    "ProcessFailed": "Process Failed",
    "ToBeDelivered": "Pending Shipment Arrangement",
    "ToBeAccepted": "To be accepted",
    "PickUpAddress": "Pickup Address",
    "axiosTips": "Tips",
    "moreMaxLength": "Max. { maxLength } characters",
    "PleaseSelect": "Please Select",
    "SyncError": "Sync failed",
    "syncSuccess": "Sync completed",
    "Synchronizing": "Syncing",
    "reset": "Reset",
    "search": "Search",
    "status_failed": "Failed",
    "status_returned": "Returned",
    "status_cancelled": "Cancelled",
    "status_delivered": "Delivered",
    "status_shipping": "Shipping",
    "status_paid": "Paid",
    "status_pending_payment": "Pending Payment",
    "CancellingProcess": "Cancelling",
    "Delete": "Delete",
    "Unpicked": "Unpicked",
    "Picked": "Picked",
    "ShippingMethodLink": "set it now",
    "ExportDisabled": "Expired",
    "ExportFailed": "Download failed",
    "ExportFinished": "Active",
    "ExportINProcess": "Processing",
    "ExportStatus": "Status",
    "AddTemplate": "Add Template",
    "ExportFiledReason": "Filed Reason：",
    "FileSaveHistoryDays": "The file will be displayed in the Export History List, and will only be kept for 15 days, please download in time",
    "ExportFileFailed": "File generation failed, please try again later",
    "ExportFileGenerated": "The file has been generated, please click to download",
    "ExportFileInProcess": "Generating File, Please Wait",
    "TokoOrderApiUnstable": "Affected by the Tokopedia channel update, Ginee will suspend the order synchronization service of this channel for 1 hour from 22:00 Indonesia time on November 3rd.",
    "ExportResultTitle": "Results",
    "OrderStatus": "Order Status",
    "ExportDownload": "Download",
    "ExportAllOrders": "Export All",
    "ExportBySelected": "Export Selected",
    "Export": "Export",
    "OtherInformation": "Other Information",
    "AmountInformation": "Amount Information",
    "LogisticsInformation": "Logistics information",
    "ProductInformation": "Product Information",
    "BuyerInformation": "Buyer Information",
    "ExportSelectedDate": "Selected Time",
    "ExportSelectedTemplate": "Selected Template",
    "ExportOrder": "Export Order",
    "ExportCreator": "Creator",
    "ExportContent": "Content",
    "ExportTime": "Export Time",
    "ExportHistory": "Export History",
    "TaxationFee": "Tax",
    "CancelNoteText": "Cancel Reason",
    "FinalShippingFee": "Shipping Fee Paid by System",
    "PickingListStatus": "Picking List Status",
    "PackingListStatus": "Packing List Status",
    "InvoiceStatus": "Invoice Status",
    "LabelStatus": "Label Status",
    "AfterClickPrint": "After clicking print, those orders in the list below will be marked as printed",
    "SetToPrintedMass": "Set to Printed (Mass)",
    "SetPrintStatusTips": "Tip: Once it is set to printed, it is no longer supported to change the printing status, please be aware.",
    "SetPrintStatus": "Set print status",
    "SetStatus": "Set Status",
    "PickStatus": "Picking Status",
    "PrintStatus": "Print Status",
    "DiscountedTotalPrice": "Discount Price",
    "InvoiceAlreadyPrinted": "Invoice Printed",
    "InvoiceNotPrinted": "Invoice Not printed",
    "PackingListAlreadyPrinted": "Packing List Printed",
    "PackingListNotPrinted": "Packing List Not printed",
    "PickingListAlreadyPrinted": "Picking List Printed",
    "PickingListNotPrinted": "Picking List Not printed",
    "LabelAlreadyPrinted": "Label Printed",
    "LabelNotPrinted": "Label Not printed",
    "NotPrinted": "Not printed",
    "AlreadyPrinted": "Printed",
    "Rebate": "Rebate",
    "NewBuyerNote": "Buyer Notes",
    "OrderTotal": "Order Total",
    "BuyPhone": "Phone",
    "ProductInfo": "Product Info",
    "SellerNote": "Seller Notes",
    "NoContentYet": "No content yet",
    "OnlyVisibleToPicking": "Only Visible in the Picking List",
    "OnlyVisibleToSellers": "Only visible to sellers",
    "CurrentlyNotSupportedShipping": "Currently not supported to view shipping status",
    "AWB": "AWB",
    "Voucher": "Voucher",
    "ServiceFee": "Service Fee",
    "CommissionFee": "Commission Fee",
    "UnstableLazadaAPI": "Due to the unstable Lazada API, recently, some store orders cannot be fully synchronized. Lazada is now repairing it. If you encounter this problem, please go to the Seller Center to process the order.",
    "HideProductInfo": "Hide product info",
    "ShippingWeight": "Weight",
    "ShippingSender": "Sender",
    "ShippingRecipient": "Recipient",
    "BatchLabelInfo": "After clicking print, those orders in the list below will be marked as printed",
    "PrintBatchLabel": "Mass Print Label",
    "ViewAllProduct": "See all { productCount } products",
    "ShipBefore": "Ship Before",
    "PackFailedInfo": "Packaging Failed, Please Refresh or Snyc and try again",
    "PleaseSelectProduct": "Please select product",
    "SkipPrint": "Skip & Print",
    "SomeOrderInvoiceEmpty": "Some order invoice number are empty, can not print, please complete",
    "SearchPlaceholder": "Please enter content",
    "Unpacked": "Unpacked",
    "PackingStatus": "Packing Status",
    "ReceiverName": "Receiver Name",
    "BuyerEmail": "Buyer Email",
    "BuyerContact": "Buyer Contact",
    "BuyerName": "Buyer Name",
    "TrackIngNumber": "Tracking Number",
    "InvortySKU": "MSKU",
    "ParentSKU": "Parent SKU",
    "ItemName": "Product Name",
    "productName": "Product Name",
    "OrdersID": "Order ID",
    "Keywords": "Keywords",
    "OrderTypeNormal": "Normal",
    "OrderTypePreOrder": "Pre-order",
    "AllOrderType": "All Order Type",
    "PaymentPrePaid": "Prepaid",
    "AllPayment": "All Payment",
    "LdProductStatusInfo": "Products Statuses in the order are inconsistent, please check the Order Detail.",
    "PackingFailedInfo": "Packing Failed, Please Refresh Page and Try Again.",
    "BillingAddress": "Billing Address",
    "InvoiceNumber": "Invoice Number",
    "ProductsItemPack": "All items packed in one Order can't be repacked",
    "OnlyUnPackagedOrders": "Only for unpackaged orders",
    "AllProductsPacked": "All items in one Order use the same Invoice Number",
    "PackMethod": "Pack Method",
    "PackOrderTips": "Tips：Lazada's orders must be packaged before can be shipped, and Ginee only supports packaging items under same order.",
    "AlreadyPacked": "Packed",
    "Pack": "Pack",
    "PackOrder": "Pack Order",
    "LWrongPricePricingError": "Wrong Price or Pricing Error",
    "LDuplicateOrder": "Duplicate order",
    "LCustomerUnreachable": "Customer unreachable",
    "order_98c90235ea6e3d054f70d2ffdfa9e703": "Out of Stock",
    "LOutOfDeliveryArea": "Out of Delivery Area",
    "LIncorrectOr": "Incorrect or Incomplete Shipping Address",
    "LSystemError": "System Error",
    "LSourcingDelay": "Sourcing Delay(cannot meet deadline)",
    "NoName": "No name",
    "ProductSyncFailedAgain": "Product Sync Failed, Please Try Again",
    "ShopCancelOrderStockInfo": "Out of stock — The selected products stock will be 0 in corresponding store",
    "TokoCancelOrderStockInfo": "Out of stock — The selected products stock will be 0 in corresponding store",
    "BLCancelOrderStockInfo": "When cancel orders,the product's stock in that order will be 0 in corresponding store",
    "AlreadyProcessed": "Processed",
    "Grab": "Grab [9:00-16:00]",
    "GOSENDInstant": "GO-SEND Instant [6:00-18:00]",
    "GOSENDSameDay": "GO-SEND Same Day [6:00-15:00]",
    "AvailableTimeToPickup": "Available time to pickup",
    "ProductUnitPrice": "Unit Price",
    "ShopCloseTime": "Shop closing time",
    "ToCancellationInfo": "Seller can't reject order cancellation application from buyers in Tokopedia",
    "ToTrackingNoInfo": "8-17 characters",
    "trackingNoErrorMessage": "{min}-{max} characters",
    "BLTrackingNewNoInfo": "8-25 characters",
    "BLTrackingNoInfo": "8-25 characters using both alphabet and number",
    "TBuyerRequest": "Buyer’s request",
    "TCourierProblem": "Courier problem",
    "TOthers": "Others",
    "shopClosed": "Shop closed",
    "TWrongPriceOrWeight": "Wrong price or weight",
    "TProductVariantUnavailable": "Product variant unavailable",
    "TProductOutOfStock": "Product(s) out of stock",
    "TRejectShipping": "Reject Shipping",
    "BLBusy": "Busy",
    "BLOutdatedPrice": "Outdated Price",
    "BLInappropriatePrice": "Inappropriate Price",
    "BLVariantOutOfStock": "Variant Out Of Stock",
    "BlSuppliersOutOfStock": "Suppliers Out Of Stock",
    "BLNoStock": "No Stock",
    "ReceiveInfo": "You can accept Tokopedia,\nAkulaku, Bukalapak and Tiki orders at the same time, but Bukalapak and Tiki orders cannot be cancelled once accepted",
    "SelectStockEmpty": "The selected product stock will be cleared",
    "DeleteKeepOneData": "Please keep at least one piece of data",
    "CourierTracking": "Courier tracking",
    "NoReturnOrder": "No returning Request to process",
    "InReturn": "Returning",
    "InCancel": "Cancelling",
    "ReturnTips": "Only one return order at a time is supported. When multiple orders are selected, only the first order is processed",
    "NoCancellationOrder": "No Cancellation Request to process",
    "PrintDate": "Print date",
    "BuyerNote": "Buyer Notes",
    "message": "Message",
    "seeMore": "See { more } Items",
    "HistoryOrderCompleted": "Order completed",
    "HistoryOrderCancellation": "Cancellation request has been rejected",
    "HistoryOrderReturned": "Order returned",
    "HistoryOrderShipping": "The order has been shipped",
    "HistoryCancelOrder": "Cancelled Order",
    "HistoryReject": "Seller refused to cancel order",
    "HistoryCreateOrder": "Create Order",
    "Reject": "Reject",
    "Confirm": "Confirm",
    "AcceptInfo": "This order will be cancelled immediately, and any payment made by buyer will be refunded. Please do not ship out this order. If you already shipped out, please reject cancellation request.",
    "Images": "Images",
    "AcceptReturn": "Accept Return",
    "Respond": "Respond",
    "Reason": "Reason",
    "RequestID": "Request ID",
    "ReasonFromBuyer": "Reason From Buyer",
    "qtyToShip": "Qty to Ship",
    "Qty": "Qty",
    "buyer": "Buyer",
    "orders": "Orders",
    "CODNotSupported": "COD Not Supported",
    "UndeliverableArea": "Undeliverable Area",
    "CustomerRequest": "Customer Request",
    "OutOfStock": "Out of Stock",
    "History": "History",
    "NoNext": "No,next",
    "YesNext": "Yes,next",
    "Print": "Print",
    "Deleted": "Deleted",
    "Retry": "Retry",
    "AcceptRejectInfo": "Do you want to accept the cancellation request?",
    "receiveOrderTip": "Do you want to receive the order?",
    "selectCancelTips": "Do you want to cancel all orders with the same reason?",
    "AddPickingStatus": "Add Status",
    "SetPickStatusConfirm": "Confirm",
    "PickApplyToAll": "Apply to All",
    "SetPickStatusTitle": "Set Pick Status",
    "SetPickStatusInMass": "Set Picking Status in mass",
    "PickingSetTip": "Tip: Please know that once it is set to Picked, you can't change the status anymore.",
    "PrintPicking": "Print Picking List",
    "PrintPackingList": "Print Packing List",
    "PrintInvoice": "Print Invoice",
    "PrintLabel": "Print Label",
    "ProcessingCancellation": "Process Cancellation",
    "ProcessingReturns": "Processing Returns",
    "ReceiveOrder": "Receive Order",
    "orderSelected": "Orders Selected",
    "shippingfeetip": "Bukalapak's logistic fee will be added after being sent",
    "variationdeleted": "The Item currently deleted",
    "Sync": "Sync",
    "tipsWait": "'Arrange Shipment' feature will be live soon. Please proceed to the marketplace for order processing",
    "variation": "Variations",
    "MSKU": "MSKU",
    "SKU": "SKU",
    "Total": "Total",
    "SellerDiscountTotal": "Seller Discount",
    "Insurance": "Insurance",
    "Tax": "Tax",
    "Shipping": "Shipping Fee",
    "subtotal": "Subtotal",
    "readyToShip": "Ready to ship",
    "timeSlots": "Time Slots",
    "selectAddress": "Select Address",
    "address": "Address",
    "selectCourier": "Select Courier",
    "logistics": "Logistics",
    "reasonForCancellation": "Reason for Cancellation",
    "qtyToCancel": "Qty to Cancel",
    "qtyOrdered": "Qty Ordered",
    "ArrangeShipment": "Arrange Shipment",
    "updateOrder": "Update",
    "CancelOrder": "Cancel Order",
    "notes": "Notes",
    "item": "Items",
    "shippingAddress": "Shipping Address",
    "customerEmail": "Email",
    "contactNo": "Contact No.",
    "promiseToShipBefore": "Promised to Ship Before",
    "AWBTrackingNumber": "AWB / Tracking Number",
    "shippingType": "Shipping Type",
    "paymentType": "Payment",
    "orderType": "Order Type",
    "store": "Store",
    "date": "Date",
    "customerDetails": "Customer Details",
    "orderInfo": "Order Info",
    "processingFlow": "Processing Flow",
    "orderDetailId": "Order { orderId }",
    "updateAt": "Update At",
    "createAt": "Created At",
    "orderStatus": "Status",
    "customerName": "Buyer",
    "orderId": "Order ID",
    "amountMax": "Max Total",
    "amountMin": "Min Total",
    "amount": "Amount",
    "createDate": "Create Time",
    "shippingCarrier": "Courier",
    "channelName": "Channel",
    "keyWord": "Search order ID, SKU or customer name",
    "refundableShippingFeeIsCalculatedIn": "The refundable shipping fee is calculated in the refundable amount, please filter to see",
    "youDontHaveOperationAuthorityFeature": "During effective period of the package, the cumulative number of orders created has reached {x} orders. After {y} orders are exceeded, the order feature will not be available. Please subscribe to the package in advance or contact customer service to upgrade the package",
    "understood": "Understood",
    "disableDefaultRules": "Disable default rules",
    "enableDefaultRules": "Enable default rules",
    "theDefaultRuleIsSuccessfullyDeactivated": "The default rule is successfully deactivated",
    "theDefaultRuleIsSuccessfullyActivated": "The default rule is successfully activated",
    "ifTheOrderDoesNotMeet": "If the order does not meet any of the created rules, it will not create an outbound document to deduct stock",
    "afterDisableTheOrderWillOnly": "After disable, the order will only allocate warehouses according to the created warehouse allocation rules, and will create an outbound document to deduct stock",
    "inOrderToEnsureThatThe": "In order to ensure that the Master Product stock can be deducted normally, when the warehouse allocation rule is not configured, it will not support to disable the default rule",
    "defaultRuleWhenNoRuleIs": "Default rule: When no rule is created manually, warehouse allocation will be according to the warehouse of MSKU that created in most recent inbound list",
    "noticeWhenTheRuleNotManually": "Notice: When the rule not manually created, it will not disable the default rule. After manually creating the rule, the order will not be processed according to the default rule. If there is more than one allocation rule in the same warehouse, it will assign according to the rule priority",
    "country": "Country/Region",
    "IntegrateNow": "Integrate Now",
    "noconnected": "You don't have an integrated Store, please integrate a Store",
    "enableTips": "After enabled, this rule will affect order warehouse allocation. Please be careful to operate",
    "youSureToEnableAre": "Are you sure to enable?",
    "setAtLeastOneConditionPlease": "Please set at least one condition",
    "deletedSuccessfully": "Successfully Deleted",
    "youSureToDeleteAre": "Are you sure to delete?",
    "mskuAdd": "Add MSKU",
    "logisticAdd": "Add Logistic",
    "storeAdd": "Add Store",
    "disabledWarehouseWillNoLongerBe": "After disabled, warehouse will no longer be allocated according to this rule",
    "youSureYouWantToDisable": "Are you sure you want to disable this rule?",
    "deleteMskuRule": "Rule XXX delete MSKU: #XXX#",
    "addMskuRule": "Rule XXX add MSKU: #XXX#",
    "orderAmountIsAdjustedFrom": "Rule XXX order amount is adjusted from XX ~ XX to XX ~ XX",
    "deleteLogisticsRule": "Rule XXX delete #XXX#logistics",
    "addLogisticRule": "Rule XXX add #XXX# logistic",
    "ruleNameOfRules": "The rule name of rule XXX is updated to #XXX#",
    "deleteStoreRule": "Rule XXX delete #XXX# store",
    "addStoreRule": "Rule XXX add #XXX# store",
    "priorityOfRuleUpdated": "The priority of rule XXXX is updated from X to X",
    "historyOperation1": "Operation Records",
    "logisticSelectedX": "{x} Logistic Selected",
    "storeSelectedX": "X Store Selected",
    "warehouseAllocation": "Allocation Warehouse",
    "add": "Add",
    "amountOrder": "Order Amount",
    "nameLogistic": "Logistic Name",
    "nameStore": "Store Name",
    "conditionSelect": "Select condition",
    "masterSkuDesignated": "Designated Master SKU",
    "orderAmountRangeSpecify": "Specify order amount range",
    "logisticDesignated": "Designated Logistic",
    "storeDesignated": "Designated Store",
    "conditionOptional": "Optional Condition",
    "setAtLeastOneAllocationCondition": "Please set at least one allocation condition to ensure the completion of the rule setting",
    "settingCondition": "Condition Setting",
    "informationBasic": "Basic Information",
    "ifTheOrderShippingWarehouseRule": "Tip: If the order delivery warehouse rule is not added or the Master SKU does not exist in the warehouse configured in the rule, the outbound document will not be generated to deduct the inventory. Please make sure to set the rule！If there are multiple allocation rules in the same warehouse, it will assign based on the rule priority",
    "ruleAdd": "Add Rule",
    "disable": "Disabled",
    "enable": "Enabled",
    "edit": "Edit",
    "operate": "Operate",
    "time": "Time",
    "conditionAllocation": "Allocation Condition",
    "nameRule": "Rule Name",
    "priority": "Priority",
    "upTheSearch": "Single up the search{X}",
    "moreMax": "Max. { maxLength }",
    "separateMultipleInfoWithCommasOr": "Separate multiple info with commas or spaces",
    "addSearchType": "Add search type",
    "advancedSearch": "Advanced Search",
    "mskuIsNotEqualTo": "MSKU is not equal to",
    "skuIsNotEqualTo": "SKU is not equal to",
    "mskuIsEqualTo": "MSKU is equal to",
    "skuIsEquals": "SKU is equals",
    "shopeebukalapakOrdersUseGineeTemplateFor": "Shopee/Bukalapak orders use Ginee template for printing shipping label (Tokopedia currently only supports Ginee template)",
    "selectPrinter": "Select printer",
    "printCompleted1": "Print completed",
    "printing2": "Printing",
    "checkTheDetails": "View Details",
    "action1": "Action",
    "printQuantity": "Print Quantity",
    "printStatus": "Print Status",
    "printingAdmin": "Print Operator",
    "highspeedBatchPrintingHistory": "High-speed batch printing history",
    "highspeedPrintingLabelHistory": "High-speed printing label history",
    "printAgain": "Reprint",
    "failureReason": "Failure reason",
    "shipmentNumber": "Tracking Number",
    "order": "Order",
    "printFailed": "Print failed",
    "printSuccessfully": "Print successfully",
    "printing1": "Printing",
    "cautionToEnsureNormalPrintingOperation": "Caution: To ensure normal printing operation, please do not close/refresh the current page, otherwise the current printing task will be terminated or the same side will be printed repeatedly!",
    "IfPrintingFailsPleaseMove": "3. If printing fails, please move to the print failure list to check the reason",
    "DuringPrintingIfSomeOrders": "2. During printing, if some orders fail to print, it will not affect the overall printing progress",
    "AfterThePrintingIsCompleted": "1. After the printing is completed, the order will be automatically marked as printed. If you need to change it, please go to the order list to modify it yourself",
    "printingInstructions": "Printing instructions",
    "totalXxxAndXxPrinted": "Total { total } , and { x }  printed",
    "printCompleted": "Print completed",
    "printing": "Printing",
    "printingProgress": "Printing progress:",
    "highSpeedPrintingTaskCenter": "High speed printing task center",
    "recheck": "Recheck",
    "ifYouConfirmThatThePlugin": "If you confirm that the plug-in has been installed and enabled, please click the re-detection button to print",
    "notInstalledClickHereToDownload": "Not installed, click here to download the plugin",
    "installed": "Installed",
    "detectedWeFoundThatYouHave": "Detected, we found that you have not installed or enabled the high-speed printing plug-in, please try the following method:",
    "tips": "Tips",
    "inOrderToImproveThePrinting": "In order to improve the printing speed and experience, recommended to use fast printing,",
    "startPrinting": "Start printing",
    "printer": "Printer",
    "noteToEnableHighspeedPrintingThe": "Note: To enable high-speed printing, the high-speed printing plug-in must be downloaded and activated",
    "highspeedPrinting": "High-speed printing (unlimited number of single prints, but does not support print preview)",
    "regularPrintingPrintUpTo": "Regular printing (print up to 100 orders in one time, generate a PDF label for all orders, and will takes more time)",
    "printingMethod": "Printing Method",
    "haveBeenPrinted": "{ x } of them have been printed",
    "dataHaveBeenSelectedAnd": "{ x }data have been selected",
    "printData": "Print data",
    "customLabelOnlySupports": "Custom label (Only supports Shopee/Tokopedia/Bukalapak orders)",
    "channelLabelAllChannelOrdersSupport": "Channel label (All channel orders support for printing except for Shopify/Woocommerce orders)",
    "setUpPrintTemplates": "Set up print templates",
    "printShippingLabelAll": "Print Shipping Label (All)",
    "printShippingLabelSelected": "Print Shipping Label (Selected)",
    "selectAllOrders": "Select all orders",
    "pleaseConfirmWhetherToTurnOff": "Please confirm whether to turn off the order-stock deduction process?",
    "putOnHold": "Put On Hold",
    "pickingNote": "Picking Notes",
    "dropshipper": "Dropshipper",
    "storeName": "Store Name",
    "setReadyToShip": "Set Ready To Ship",
    "becauseYouHaveNotIntegrateThe": "Because you have not integrate the channel store, you cannot use the clone function, please complete the integration first",
    "integrateStore": "Integrate Store",
    "integrationGuide": "Integration Guide",
    "MinutesToLearnAboutGinee": "1 minutes to learn about Ginee",
    "shortcuts": "Shortcuts",
    "theStoreHasNotBeenAuthorised": "Not yet authorized stores, unable to manage online products and orders",
    "inventoryManagement": "Inventory Management",
    "productManagement": "Product Management",
    "scrapeProduct": "Scrape Product",
    "masterProductSales": "Master Product Sales",
    "try": "Try It Now",
    "productActivitySetting": "Product Activity Settings",
    "capitalOccupationRatio": "Capital occupation ratio (%)",
    "stockTakesUpFunds": "Stock takes up funds",
    "numberOfMskus": "Number of MSKUs",
    "exportMsku": "Export MSKU",
    "purchaseDuration": "Purchase Duration",
    "doesItNeedToBeReplenished": "Does it need to be replenished?",
    "availableDaysDays": "Available days (days)",
    "averageDailySales": "Average daily sales",
    "lastMonth": "Last 1 month",
    "lastTwoWeeks": "Last 2 weeks",
    "lastWeeks": "Last 7 days",
    "lastThreeDays": "Last 3 days",
    "numberOfRatings": "Number of ratings",
    "ratings": "Ratings",
    "likes": "Likes",
    "pageViews": "Page Views",
    "productActivityHasBeenSetFor": "Product activity has been set for this category, please reset",
    "sales": "Sales",
    "effectiveSales": "Effective Sales",
    "newItem": "New Item",
    "lowSellingItem": "Low Selling Item",
    "normalItem": "Normal Item",
    "trendingItem": "Trending Item",
    "bestSellingItem": "Best Selling Item",
    "productActivity": "Product Activity",
    "accounting": "Accounting Management",
    "categoryRange": "Category Range",
    "timeRange": "Time Range",
    "productDimension": "Product-level",
    "salesType": "Sales Type",
    "salesRange": "Sales Range",
    "allCategory": "All Category",
    "recent": "Recent",
    "oneDay": "One Day",
    "threeDay": "Three Days",
    "aWeek": "A Week",
    "twoWeeks": "Two Weeks",
    "aMonth": "A Month",
    "threeMonths": "Three Months",
    "halfYear": "Half Year",
    "onlySupportForIndonesia": "Only support for Indonesia",
    "addProductActivity": "Add Product Activity",
    "cSupportToManuallyPullAccurate": "c. Support enabling the sync of Accurate's stock changes to Ginee, and updating the bound Ginee warehouse stock (disabled by default, please enable with caution)",
    "bAfterTheOrderIsPlaced": "b. Ginee's master product that outbound from the warehouse will reduce Accurate's stock synchronously",
    "aInboundStockForMasterProduct": "a. Inbound stock for Master Product in Ginee will also sync and increase the stock of Accurate",
    "StockSync": "Stock Sync",
    "noteOrderInformationSynchronizationIsA": "Note: Sales invoice sync is a one-way sync that is automatically pushed from Ginee to Accurate every day",
    "createdTheWarehousePairingRelationship": "After the store is successfully mapped with Accurate, a sales invoice will be automatically created when Ginee orders are shipped out of the warehouse and pushed to Accurate.",
    "OrderDistribution": "4. Order Distribution",
    "noteTheSynchronizationOfMasterProduct": "Note: The synchronization of Master Product information  is a one-way synchronization from Ginee to Accurate. After creating a new Master Product, it will be directly synchronized in time.",
    "afterCreatedTheWarehousePairingRelationship": "After created the warehouse pairing relationship, it supports synchronizing Master Product of the Ginee warehouse to the corresponding Accurate warehouse",
    "ProductPlacement": "3. Product Distribution",
    "psItIsNotRecommendedTo": "PS: It is not recommended to bind the third-party warehouse to the Accurate warehouse, there will be a risk of inventory confusion",
    "afterTheAuthorizationIsCompletedYou": "After the authorization is complete, you can manage the mapping of warehouses and stores in [Settings - Financial Settings], and set sync rules. Synch products/stock/sales invoices can only be performed normally after mapping",
    "BasicConfiguration": "2. Basic Configuration",
    "pleaseMakeSureToFillIn": "Please make sure to fill in the Accurate account/password correctly, and select Database to complete the authorization",
    "Authorization": "1. Authorization",
    "accurateOperationGuide": "Accurate Operation Guide",
    "averageDailySalesSettings": "Average Daily Sales Settings",
    "averageSalesForTheDayAs": "Average sales for the day as daily sales",
    "clickAuthorizeYouWillBeRedirected": "Click [Authorize], you will be redirected to the Accurate page, please be sure to fill in the Accurate account/password correctly and complete the authorization after log in",
    "onSaleDaysDays": "On Sale Days (Days)",
    "purchaseTimedays": "Purchase Time (Days)",
    "AfterTheAuthorizationIsCompleted": "2. After the authorization is completed, please complete the warehouse pairing in Accurate configuration management;",
    "PleaseSelectAtLeast": "1. Please select at least 1 Database to complete the authorization. If there is no Database, please go to Accurate to add it",
    "remainder": "Remainder",
    "authorizationSucceeded": "Authorization Succeeded",
    "theAccurateWarehouseInformationIsBeing": "Accurate's warehouse and accounting are being synchronized for you, please proceed to the financial initialization mapping settings!",
    "continueAuthorization": "Continue Authorization",
    "reauthorize": "Reauthorize",
    "channelFailureReason": "Channel failure reason:",
    "authorizationFailed": "Authorization Failed",
    "deleteAuthorization": "Delete Authorization",
    "authorizer": "Authorizer",
    "authorizationTime": "Authorization Time",
    "connected": "Connected",
    "authorizationStatus": "Authorization Status",
    "ServiceProvider": "Service Provider",
    "accountName": "Account Name",
    "deleteFailed": "Delete Failed",
    "theBindingRelationshipBetweenTheAccurate": "The binding relationship between the Accurate warehouse and the Ginee warehouse has been released, and the product/order/stock information synchronization with ACCURATE is no longer performed",
    "onceDeletedTheBindingRelationshipBetween": "Once deleted, the binding relationship between the Ginee warehouse and the Accurate warehouse will be released, and the product/order/inventory information will no longer be synchronized with ACCURATE",
    "areYouSureWantToDelete": "Are you sure want to delete?",
    "productSuccessfullySynchronized": "Product successfully synchronized",
    "whenDistributeGineeWarehouseProductGinees": "When distribute Ginee warehouse product, Ginee's MSKU warehouse stock will be directly used to cover the Accurate warehouse stock, please operate carefully",
    "areYouSureToSyncGinee": "Are you sure to sync Ginee warehouse product to Accurate warehouse?",
    "channelReturnFailureReason": "Channel Return Failure Reason:",
    "pullFailed": "Pull failed",
    "pullSuccessfully": "Pull successfully",
    "pull": "Pull",
    "afterManuallyPullingTheWarehouseInformation": "After manually pulling the warehouse information, please make sure to bind the newly added warehouse. Please be sure to know that the deactivated warehouse will be deleted directly and the binding relationship will be cleared synchronously, data synchronization also will no longer be performed.",
    "pullWarehouse": "Pull Warehouse",
    "warehouseStockSuccessfullyUpdated": "Warehouse stock successfully updated",
    "onceTheStockOfTheThirdparty": "Once the stock of the third-party warehouse is inconsistent with the system, this operation will directly update the warehouse stock of the existing product to third-party warehouse stock value, and generate an adjustment record. The specific information can be viewed in the pull record.",
    "areYouSureToPullStock": "Are you sure to pull stock from the Accurate Warehouse?",
    "theWarehouseBindingRelationshipHasBeen": "The warehouse binding relationship has been successfully released",
    "afterTheUnbindingIsSuccessfulThe": "After the unbinding is successful, the received product/order information in the Accurate warehouse will not be affected, and the data generated in the original bound Ginee warehouse will not be synchronized to Accurate",
    "areYouSureToUnbindThe": "Are you sure to unbind the current warehouse?",
    "reasonTheCurrentWarehouseHasBeen": "Reason: The current warehouse has been bound to another Accurate warehouse, please unbind and try again",
    "bindingFailed": "Binding Failed",
    "bindingIsSuccessfulPleaseDoNot": "Binding is successful, please do not repeat the operation",
    "bind": "Bind",
    "tipOnlySupportsSelectingWarehouseThat": "Tip: Only supports selecting warehouse that are enabled in the Ginee warehouse list",
    "syncWarehouse": "Sync Warehouse",
    "unbindWarehouse": "Unbind Warehouse",
    "bindTime": "Bind Time",
    "accurateWarehouse": "Accurate Warehouse",
    "accurateWarehouseInformationWillNotBe": "Accurate warehouse information will not be synchronized to the Ginee warehouse list, and master product/order/stock information will be synchronized according to the binding relationship with Ginee's existing warehouse",
    "accurateSettingManagement": "Accurate Configuration Management",
    "synchronizeWarehouseInformationUnderAccurateDatebase": "Synchronize warehouse information under Accurate Datebase *, and  add * warehouse, delete * warehouse",
    "syncGineeWarehouseProductTo": "Sync Ginee * warehouse product to Accurate",
    "syncStockFromAccurateWarehouse": "Sync stock from Accurate warehouse * to Ginee warehouse *",
    "accurateWarehouseUnbindFrom": "Accurate warehouse * unbind from * warehouse",
    "accurateWarehouseBindTo": "Accurate warehouse * bind to * warehouse",
    "operationLogIsOnlyRetainedFor": "Operation log is only retained for the past 15 days, and information after more than 15 days cannot be viewed",
    "send": "Send",
    "translate": "Translate",
    "failedToSendMessage": "Failed to send message",
    "resend": "Resend",
    "sendToBuyer": "Send to buyer",
    "translationFailed": "Translation failed",
    "translatedx": "Translated：{x}",
    "translationInProgress": "Translation in progress",
    "automaticTranslationHasBeenTurnedOn": "Automatic translation has been turned on",
    "autoTranslate": "Auto Translate",
    "buyersLanguage": "Buyer's Language",
    "afterCloseItThereWill": "After close it,  there will be no sound prompt when there is new message from the buyer",
    "notificationSound": "Notification Sound",
    "uploadedNowlengthSheets": "Uploaded {nowLength} sheets",
    "isAUniqueIdentifierForThe": "is a unique identifier for the main product and cannot be changed once it has been identified",
    "pleaseConfirmWhetherTheStoresChannel": "Please confirm whether the store's channel SKU has a value, if not we will take the channel item title as the MSKU of the main item",
    "errnameToko": "Product name must be  between 1 - 70 characters",
    "sizeSettingTemplate": "Template size setting",
    "mappingCategory": "Category mapping",
    "templateSettingLogistic": "Logistic template setting",
    "rangeEnable": "Product Settings",
    "globalsMobileErrorInfoLength": "Mobile must be between {min}-{max} digits",
    "globalsMobileErrorInfo": "Please enter valid Phone Number",
    "masterSku": "Master SKU",
    "outboundPurchasePrice": "Unit Purchase Price",
    "hasbinded": "This variant has been binded to the Master SKU: {sku} Please update the stock in \"Stock List\"",
    "deletecustomersuccess": "Delected successfully!",
    "reasonforpushedfailed": "Failed Reason: Product has been deleted or banned from the store, or it is on a promotion campaign (Stock can be updated after the promotion ends). Please check the product",
    "purchasePricerequired": "Must be between 0 and 1,000,000,000!",
    "createsuccess": "Created Successfully",
    "atmost20tags": "Max.20 Tag",
    "AddTag": "Add Tag",
    "tagexist": "Tag already exists",
    "fenotempty": "Cannot use Space in front and end of the Tag",
    "taginputlimit": "Tag must be between 3-40 characters",
    "selecttag": "Select tag",
    "withordernostock": "Have Order, No Stock",
    "withisku": "Have Inventory SKU",
    "noisku": "No Inventory SKU",
    "withskuwithoutisku": "Have SKU and no inventory SKU",
    "nosku": "No SKU",
    "selectskusit": "Select SKU Conditions",
    "Pleaseenter": "Please Enter",
    "selectsku": "Select Inventory SKU",
    "emailreminder": "Email Reminder",
    "safetystockarea": "When available Stock<=",
    "bindedisku": "Binded Inventory SKU:",
    "most20bundles": "Max.20",
    "NAME": "Name",
    "PleaseEnter": "Please Enter",
    "disablebundletip": "To ensure accuracy of stock deduction, the bound Inventory SKU and Qty can‘t be modified when editing Bundle ISKU",
    "updatesuccess": "Updated successfully ！",
    "ISKUtype": "ISKU Type",
    "bundleisku": "Bundle ISKU",
    "isku": "MSKU",
    "ProductNameISKU": "Product Name & ISKU",
    "Image": "Image",
    "bundledproduct": "Bundled Product",
    "selectbundle": "Select Inventory SKU",
    "AddBundleISKU": "Add Bundle ISKU",
    "bukadisabled": "The order processing time follows the shop’s settings",
    "pleasecheckpreorderpart": "Please check the pre-order information",
    "shopeedisabled": "I will ship out within 2 working days. (excluding public holidays and courier service non-working days)",
    "tokodisabled": "Activate Preorder for this product if the process order took longer time",
    "UNKNOWNunittip": "ime unit in the Channel doesn't exist, Please contact Ginee",
    "day": "day",
    "week": "week",
    "bukreorderdayworkday": "working days to ship",
    "bukreorderday": "days to ship",
    "bukainstanhour": "working hours.",
    "bukaregular": "I will ship out within 3 working days. (excluding public holidays and courier service non-working days)",
    "bukreorder": "I need",
    "bukainstan": "I will ship out within",
    "tokopreorderday": "days to ship",
    "tokopreorderweek": "weeks to ship",
    "tokopreorder": "I need",
    "shopeepreorderday": "working days to ship",
    "shopeepreorder": "I need",
    "max8pictures": "Upload up to 8 images",
    "max5pictures": "Upload up to 5 images",
    "uploadtip": "Uploading Product Photo according to Channels rules will be: For Tokopedia, Bukalapak, Shopify, TikTok Shop, Blibli will take first 5, Lazada first 8 and Shopee first 9 product photos",
    "regular": "Regular",
    "instan": "Instant",
    "between18hours": "Must be between 1-8",
    "between330days": "Between 3-30",
    "max13weeks": "Between 1-13 weeks",
    "shopeepreorderdayAdd": "Star, Star+, Mall support up to 90 working days",
    "between790": "Between 7-90 working days",
    "max90days": "Between 1-90 days",
    "preorderdeadline": "Order Processing Deadline",
    "preorder": "Preorder",
    "novariationforlazd": "Can't add pictures because this product doesn't have variant",
    "SafetystockHead": "Set a stock limit and receive email notification to restock when Available Stock of the product reach the set number",
    "SpareStockkHead": "The total amount of Stock reserved in the actual warehouse, which will not be sold in the channel.",
    "WarehouseStockHead": "The Total Actual Stock in your Warehouse, Warehouse Stock = Spare Stock + Locked Stock + Available Stock+Promotion Stock",
    "inventorySkuHead": "The first 50 characters of the Store SKU are mapped by default",
    "NameHead": "The name of the inventory SKU is taken automatically from the first 160 characters of [product name-variant name]",
    "BrandLogoTips": "Upload suggestion: 360*140, jpg/png/jpeg images smaller than 2M",
    "MobileMustErrorInfo": "Must be started with 8, contains 9-12 numbers",
    "bindnow": "Bind now",
    "Nospacesfront": "No spaces in the first part, please check",
    "spaceforbidden": "Cannot have space button, please check",
    "range": "Range: Live, Disabled, Sold Out, Banned",
    "inventorySkunotempty": "ISKU cannot be empty!",
    "limitsafety": "Safety stock Should be between 0 ~ 999,999!",
    "mustlessthanwarehouse": "Spare Stock cannot be greater than the Warehouse Stock",
    "sparelimit": "Spare Stock Should be between 0 and 999,999!",
    "mustbiggerthanspare": "Spare Stock cannot be greater than the Warehouse Stock",
    "warehouselimit": "Warehouse Stock Should be between 0 and 999,999!",
    "masterSkuMost200": "Master SKU harus dibawah 200 karakter",
    "masterSkuMost50": "Master SKU harus dibawah 50 karakter",
    "most50": "Inventory SKU should be less than 50 character",
    "most160": "Name should be less than 300 character",
    "clicktoedit": "Click to edit",
    "alreadyexist": "Inventory SKU already exists, Variant SKU will be bound to the Inventory SKU",
    "generaterules": "Inventory SKU generation rules by default: 1. Inventory SKU will take the first 50 characters of SKU by default. 2. The Warehouse Stock will be equal to the Variant Stock by default, and the Safety Stock will be empty by default. 3. When the Inventory SKU is generated, the SKU of the variant will be bound.",
    "channel": "Channel",
    "Operationguide": "Operation Guide",
    "storeexplain": "Generate Inventory SKU for product in one store to manage inventory in multiple stores",
    "bystoremodal": "Generate Inventory SKUs by store",
    "ImportByStore": "Import By Store",
    "Gotit": "Got it!",
    "Safetystock": "Safety Stock",
    "SpareStock": "Spare Stock",
    "WarehouseStock": "Warehouse Stock",
    "inventorySku": "MSKU",
    "Name": "Name",
    "image": "Image",
    "GenerationRules": "Generation Rules",
    "ClickContenttoEdit": "Click the Content to Edit",
    "generatecount": "{productSum} products ( {availableNum} of {variationSum} Variants can generate Master Product).Variant that is bound to Master SKU or empty SKU can't generate Master SKU.",
    "generateisku": "Generate Inventory SKU",
    "draftorfialed": "Please select only drafts or products that failed to publish",
    "uploadlimit": "The largest file: xlsx, xls is only 5MB. Import up to 1000 data at a time.",
    "canbedownloaded": "products",
    "selectedstatus": "Selected product in current status:",
    "donwloadtemp": "Download import template",
    "DownloadFailedData": "Download Failed Data",
    "importfail": "Some Datas Import Failed",
    "importfinish": "Datas Import Success",
    "Cancel": "Cancel",
    "fileerror": "The import file format is incorrect",
    "close": "Close",
    "importing": "Importing",
    "importlabel": "Import File",
    "upload": "Upload",
    "download": "Download",
    "templatetip": "Download Template",
    "import": "Import",
    "themaxtip2": "records per upload.The customers have same phone will becombined.",
    "themaxtip1": "Tips: Max limit is",
    "ActionsSuccessInfo": "Successful operation！",
    "ShopeeTips": "Click \"Start Sync\" and you will be redirected to Shopee where you can login and authorize",
    "AlreadyChecked": "{selected} Selected",
    "SubscriptionExtended": "Subscription can be extended, please click \"subscribe\" or contact us to upgrade your account",
    "SubscribeSuccess": "If you subscribe successfully, you can activate advanced features. Please click \"Subscribe\" or contact us to upgrade your account",
    "SubscriptionExpired": "Sorry, your subscription has expired, please click \"Subscribe\" or contact us to upgrade your account",
    "PayNow": "Pay now",
    "ThisFeatureIsOnlyPaidUsers": "This Feature is only for Paid Users, Please Pay to Use This Feature.",
    "FoundANewGreatFeature": "Found a new great feature!",
    "urlplaceholder": "Please enter the Shop Name",
    "urltip": "Name must be between 2-30 characters Only lower case letters and numeric numbers are allowed",
    "urldesciption": "Please create a Member Link of the shop",
    "memberurlmodaltitle": "Create member page link",
    "CategoryLevel3": "Category Level 3",
    "CategoryLevel2": "Category Level 2",
    "RichTextDescription": "Type your description here and apply it to your product",
    "PleaseInput": "Please Input",
    "selectall": "Select All",
    "noauth": "Your account does not have this permission, please contact store administrator to ask or upgrade",
    "NoProductYet": "No product yet",
    "SeeMore": "See { more } Items",
    "SeeMoreExpand": "View more",
    "SaveAndNext": "Save & Next",
    "NoHaveAccount": "Don't have an account?",
    "Requiredfields": "Required fields cannot be empty!",
    "Somerequiredfields": "Some required fields are empty, please fill in",
    "Removetheseproducts": "Remove these products",
    "GotoShippingSettings": "Go to Shipping Setting",
    "Somewithoutshipping": "Some products cannot be saved without shipping options.Please open Shipping Options before editing the product.",
    "Discardcontent": "Discard means the product's information will be the same as the original one in the store",
    "Discardtip": "Are sure you want to discard this change?",
    "with": "with",
    "Changepriceto": "Change price to",
    "Reducebypercent": "Reduce by percent",
    "Reducebyamount": "Reduce by amount",
    "Addbypercent": "Add by percent",
    "Addbyamount": "Add by amount",
    "Basedoncurrentprice": "Based on current price",
    "replace": "Replace",
    "addaftername": "Add after name",
    "addbeforename": "Add before name",
    "Changestockto": "Change stock to",
    "Example": "Example:",
    "errsize": "Required fields cannot be empty!",
    "BukalapakPrice": "Price  must be  between 1 ～ 1.000.000.000",
    "phLazadaPrice": "Price must be between 1～ 1.000.000.000",
    "LazadaPrice": "Price must be between 1000～ 1.000.000.000",
    "TokopediaPrice": "Price must be between 100 ～ 1.00.000.000",
    "phShopeePrice": "Price  must be  between 2 ～ 1.000.000.000",
    "ShopeePrice": "Price  must be  between 99 ～ 1.000.000.000",
    "pricetipBukalapak": "Price  must be  between 1 ～1.000.000.000",
    "pricetipShopee": "Price  must be  between 99 ～1.000.000.000",
    "errdescriptionBukalapak": "Description must be  between 30 - 3000 characters",
    "errdescriptionShopee": "Description must be  between 30 - 3000 characters",
    "errnameBukalapak": "Product Name must be  between 6 - 150 characters",
    "errnamelazada": "Product Name must be  between 2 - 255 characters",
    "allemptyorfilled": "The values in length, width, and height are all should be empty or all have values",
    "errnameShopee": "Product Name must be  between 5 - 100 characters",
    "spaceskuerr": "SKU cannot have space button, please check",
    "PleaseOneShipping": "Please enable at least one shipping option for your product.",
    "pricetip": "The price should be between 99 〜 1.000.000.000",
    "stocktip": "The stock should be between 0 〜 999,999",
    "Discardchanges": "Discard changes",
    "update": "Update",
    "draft": "Save as Draft",
    "publish": "Publish",
    "Logistic": "Logistic",
    "ParcelSize": "Parcel Size",
    "Width": "Width",
    "Length": "Length",
    "Height": "Height",
    "Weight": "Weight",
    "price": "Price",
    "VariantName": "Variant Name",
    "parentSku": "Parent SKU",
    "productImg": "Image",
    "Refresh": "Refresh",
    "PlatformUpdateNotification": "Ginee update tips",
    "WaitAMoment": "Later",
    "GoAuth": "Integrate",
    "NewVersionsInfo": "Shopee Channel Management 2.0 is now Available! Come integrate your account! Data synchronization is now more stable and new order processing feature!",
    "NoDataInfo": "No data available to process",
    "ActionsErrorInfo": "Operation failed, please try again later",
    "and": "and",
    "PrivacyPolicy": "Privacy Policy",
    "TermsConditions": "Terms of Use",
    "agree": "By registering, I agree to the",
    "BLDescription": "Description must be between 30-3000 characters",
    "markDownImageSize": "the size must be at least 300 x 300 pixels ，less than 5M ，formatin JPG, PNG  format",
    "markDownAddImage": "Add Image",
    "noProductStore": "You don't have a connected store yet, connect your store now and add products",
    "noStoreInfo": "You can link your online store and start the synchronize products, stocks, and orders immediately",
    "noMemberInfo": "After customer register as member, you can manage their details and points here. You can also manually add members",
    "noProductInfo": "You haven't create any product yet. Add your first product now",
    "TextDescription": "Text Description",
    "memberInfo": "Member Information",
    "jdDisableTips": "Can't integrate for now",
    "Coming": "Coming soon",
    "editPointRuleInfo": "Are you sure to edit point  rule?",
    "previewImg": "Preview",
    "addRule": "Add Rule",
    "methodTypeAll": "All",
    "sendSuccess": "Sent successfully",
    "editPointsRule": "Edit points rules",
    "pointeReduceErrorInfo": "Not all selected users have sufficient points to deduct",
    "ruleFormatErrorInfo": "Only lower case letters and numeric numbers are allowed",
    "rewardDeductPoints": "Reward / Deduct Points",
    "alreadyExists": "{ name } address already exists",
    "signNext": "Next",
    "copySuccess": "Copy Success: { copyUrl }",
    "deleted": "deleted",
    "rule": "Rule",
    "noName": "No Name",
    "copyUrl": "Copy",
    "linkInfo": "Link",
    "laseSevenDaysInfo": "Number of members added in the last 7 days",
    "editimage": "Edit Variant Image",
    "packageContentplaceholder": "Less than 1000 characters",
    "packageContent": "What's in the box",
    "VariantImage": "Variant Image",
    "brand": "Brand",
    "brandLogo": "Brand Logo",
    "brandName": "Brand Name",
    "pointsHistory": "Points history",
    "editShopInfo": "Edit Shop Information",
    "alreadyShopOwn": "Congratulations! Your shop is ready.",
    "shopSetInfo": "Shop Information",
    "contentWrapper": "Content",
    "newSections": "New Sections",
    "accumulatePoints": "Accumulate Points",
    "chooseCardBg": "Theme",
    "shopLogo": "Shop logo",
    "shopName": "Shop Name",
    "membershipPageSettings": "Membership Page Configuration",
    "uploadImageError": "File { fileName } image size too small, minimum {limitMax}x{limitMin}",
    "uploadInfo": "Requirement: {maxWH} * {maxWH}, less than {isLt}M, format supports jpg, png, jpeg",
    "enterPointMax": "The maximum value that can be entered is 999999",
    "enterPointNum": "Enter a positive integer between 1- 999999",
    "updateSuccessFul": "Updated successfully",
    "pointsType": "Reward points",
    "redeemablePoints": "Redeemable Points",
    "reason": "Reason",
    "deductPoints": "Deduct Points",
    "rewardPoints": "Reward Points",
    "joinedMethod": "Joined Method",
    "dateOfBirth": "Date of birth",
    "remarksErrorInfo": "Max. 100 characters",
    "editDetailInformation": "Edit Detail Information",
    "editMemberInformation": "Edit Member Information",
    "profileDetails": "Profile Details",
    "accountInformation": "Account Information",
    "rewardCondition": "Reward condition",
    "customizeAlertInfo": "Below rules will be shown on the membership page; however, points need to be rewarded manually",
    "customizePointRules": "Customize Point Rules",
    "defaultAlertInfo": "Customers will be rewarded points automatically when they perform the below tasks",
    "defaultPointRules": "Default Point Rules",
    "joinMemberTime": "Member joined time",
    "inputNameOrMobile": "Input name/mobile",
    "editPoints": "Edit Points",
    "addNewMember": "Add New Member",
    "totalPage": "Total { total }",
    "description": "Description",
    "points": "Points",
    "memberNo": "Member No.",
    "mobile": "Phone Number",
    "shopname": "Shop Name",
    "name": "Name",
    "genderFemale": "Female",
    "genderMale": "Male",
    "gender": "Gender",
    "deleteRuleInfo": "Are you sure to delete this rule?",
    "status": "Status",
    "checkBoxTableSelected": "{ selected } selected",
    "create": "Create",
    "dashboardPointCumulativeRedeem": "Cumulative redeemed points",
    "dashboardPointCumulativeGiven": "Cumulative given points",
    "dashboardPointToRedeem": "Points to redeem",
    "dashboardNewMember": "New Member",
    "dashboardAllMember": "Total Members",
    "hello": "Hello, world!",
    "mobileErrorInfoLength": "Mobile must be between 9-12 digits",
    "fullnameerror": "Shop name should be 1～30 characters",
    "fullname": "Shop name",
    "mobileplaceholder": "Mobile must be 11 digits",
    "mobileErrorInfochina": "Phone Number Invalid",
    "mobileErrorInfo": "Must start with 8",
    "nameErrorInfo": "Name must be between 2-30 characters",
    "emailErrorInfo": "Invalid Email address",
    "resetPwdSuccess": "Reset password successfull",
    "resetPwd": "Reset Password",
    "alreadyInfo": "Already have a Ginee account?",
    "applyConfirm": "Confirm",
    "forgotPwd": "Retrieve/reset password",
    "codeSendSuccess": "A verification code has been sent via Email to { email }",
    "noReceiveCountDown": "Didn’t receive the verification code",
    "waitCountDown": "Please wait { someone }s to resend",
    "resendCode": "Resend",
    "sendCode": "Send",
    "signBy": "By registering ,I agree Terms and Conditions and Privacy Policy",
    "verificationCode": "Enter Verification Code",
    "verification": "Verification",
    "userName": "Name",
    "password": "Password",
    "eMail": "E-mail",
    "signUp": "Sign Up",
    "login": "Log in",
    "welcomeRegister": "Welcome to Ginee",
    "welcomeLogin": "Welcome to Ginee",
    "welcome": "Welcome to Ginee",
    "copyProduct": "Copy Listing",
    "orderDetail": "Order Detail",
    "productDetail": "Product Information",
    "status_unpublished": "Unpublished",
    "status_update_failed": "Publish Failed",
    "status_delete": "Deleted",
    "status_draft": "Draft",
    "status_disabled": "Disabled",
    "status_banned": "Banned",
    "status_sold_out": "Sold Out",
    "status_live": "Live",
    "status_process": "In Process",
    "status_all": "All",
    "staffDnotExists": "The staff account does not have permission, please contact the merchant to add permission (go to staff management-add staff-add this account)",
    "fastPrinting": "Fast Printing",
    "printPluginHasBeenUpdatedPlease": "Print plug-in has been updated, please download again",
    "afterUnbindTheStockWillNot": "After unbind, the stock will not be pushed to the channel products.",
    "areYouSureYouWantTo": "Are you sure you want to unbind?",
    "MSKUAutoBindSettingTip": "Click here to jump to the page for setting up Master Product automatic binding",
    "masterSkuInventoryInventorySynchronizationOf": "Master SKU inventory: Inventory synchronization of Lazada multi-warehouse products is temporarily not supported. If necessary, please go to the seller center to modify.",
    "selectGenieCategory": "Select Master Category",
    "dayDuration": "Duration (day)",
    "unbindedChannelVariant": "Unbinded Channel Variant",
    "deleteSkuConfirm": "Are you sure want to delete the SKU?",
    "thirdpartyWarehouseProductDetails": "Third-party Warehouse Product Details:",
    "thereAreThirdpartyWarehouseProductAmong": "There are third-party warehouse product among selected item, so it cannot be deleted.",
    "canNotDeleteThirdStock": "Product stock in the third-party warehouse is still exist, not allowed to delete",
    "skuAffectedOutbound": "Total of {val} product in the outbound, inbound list will process as invalid product, do you want to set product as invalid in the outbound list and delete the master SKU and stock data?",
    "deleteSkuAlert": "If the master SKU has an unfinished outbound, inbound list, the system will process the product in the outbound list as invalid product, and at the same time will delete the master product and stock data",
    "proceedToDelete": "Set as invalid and Delete",
    "productAffectedOutbound": "Total of {val} product in the outbound, inbound list will process as invalid product, do you want to set product as invalid in the outbound list and delete the master product and stock data?",
    "nextStep1": "Next step",
    "deleteProductAlert": "If there is an unfinished outbound, inbound list, the system will process the product in the outbound list as invalid product, and at the same time will delete the master product and stock data",
    "iNeed": "I need",
    "youCanImportDataFormat": "You can import 10,000 data (format: XLSX, XLS) every time. You can edit master product name description, master category in mass.",
    "WorkingDaysBetween": "Between 7-90 working days",
    "workingDays": "working days",
    "withoutMasterSku": "Without Master SKU",
    "processTime": "Process Time",
    "width": "Width",
    "whenCreatingInTheProVersion": "When creating in the Pro version, you need to upload the required product fields, which can be used in the scenario where the Master Product is created and then copied to the channel for sale;",
    "whenAvailableStockInfo": "When Available Stock<=X send Email Reminder",
    "weightLimit": "Should be between 1-5,000,000!",
    "weight": "Weight",
    "inboundLimitTips": "When using Fulfillment by JD, please be sure to fill Shelf Life, For example, if the product shelf life is 100 days, and the inbound limit is 0.5, then if the shelf life is less than 50 days it won't be available for inbound.",
    "outboundLimitTips": "When using Fulfillment by JD, please be sure to fill Shelf Life, For example, if the product shelf life is 100 days, and the outbound limit is 0.5, then if the shelf life is less than 50 days it won't be available for outbound.",
    "inboundLimit": "Inbound limit",
    "outboundLimit": "Outbound Limit",
    "shelfLife": "Shelf Life",
    "custom": "Custom",
    "warehouseStockTip": "The Total Actual Stock in your After Master SKU is created, Master SKU will automatically binded variations that SKU exactly match with the Master SKU, Warehouse Stock = Spare Stock + Locked Stock + Available Stock+Promotion Stock",
    "warehouseStockLimit": "Warehouse Stock should be between0-999,999",
    "yearly": "Yearly",
    "monthly": "Monthly",
    "weekly": "Weekly",
    "today": "Today",
    "warehouseStock": "Warehouse Stock",
    "warehouseName": "Warehouse Name",
    "viewNow": "View Now",
    "viewMore": "View More",
    "viewLess": "View less",
    "view": "View",
    "videoName2": "How to manage Purchasing Costs?",
    "variationTypeInfo": "Variation Type",
    "variationStatus": "Variation Status",
    "variationImageSizeLimit": "Multiple images can be uploaded at once . At least 300 x 300 pix,up to 5MB（up to 8photos)",
    "variationImageInfo": "Edit Variant Image:",
    "variationCountDesc": "Sort the Number of Binded variation from high to low",
    "variationCountAsc": "Sort the Number of Binded variation from low to high",
    "variationAlreadyBound": "This variation has been Binded with the Master SKU ， please remove it first！",
    "exportLimit2": "Can only export 50000 Master Product each time, please sort again",
    "exportLimit": "Please enter the number of page to download the item, and you can export max. 50000 Master Product.",
    "selectChannel": "Select Channel",
    "variation1": "Variations",
    "variantTypeRangeIs": "Variant type 1 range is 1-14 characters",
    "variantTypeIsMissingPlease14": "Variant type 2 is missing, please check!",
    "variantTypeIsMissingPlease": "Variant type 1 is missing, please check!",
    "variantsInfoB": "10 products ( 50 of 100 Variants can generate Master SKU)",
    "variantsInfoA": "{productSum} products ( {availableNum} of {variationSum} Variants can generate Master SKU).Variant that is bound to Master SKU can't generate Master SKU.",
    "numberOfProducts": "{productSum} Products ({variationSum} Specifications)",
    "variantPictureLinkCannotBe": "Variant picture 1 link cannot be empty!",
    "uploadFile": "Upload File",
    "downloadTemplate": "Download Template",
    "step2Limit": "Please follow the download template to Import rules, you can upload up to 10,000 rules each time (format xlsx, xls)",
    "step2Dec": "Step 2: Import new Auto-Bind rules in mass",
    "step1Dec": "Step 1: Export rules",
    "variantOptionsLimit": "Up to 20 variant options",
    "variantOptionShouldBe": "Variant option 2 should be 1-20 characters",
    "import10000Tip": "Import up to 10000 data at a time.",
    "variantNameChannel": "Channel Specification Name",
    "variantName": "Variant Name",
    "variantImage": "Variant Image",
    "username": "Name",
    "useImageFor": "Use image for",
    "used": "Used",
    "operationReason": "Operation Reason",
    "goToSettings": "Go to settings",
    "stores": "Please go to [Product Settings] and turn on the \"Automatically bind the Master SKU\" setting before using this function",
    "storeSKU": "Feature is temporarily unavailable",
    "channelVID": "Channel ID Variant",
    "enabledAutoBind": "The Auto-Bind of the Master SKU setting is not enabled, and there are no rules yet!",
    "editAutoBindRule": "Edit Auto-Bind rules",
    "updateAutoBindRules": "Update Auto-Bind rules",
    "exportAllAutoBindRules": "Export all Auto-Bind rules",
    "enterStoreSKU": "Please enter Store SKU",
    "bindingError": "The Store SKU has been bound to other Master SKU,please check",
    "storeSkuLimit": "Store SKU should be less than 250 character",
    "bindMasterSku": "The related but not binded channel product will automatically bind Master SKU",
    "bindedStore": "Binded Store",
    "bindedProduct": "Binded Product",
    "autoBind": "Auto-Bind",
    "stepExportRules": "Store SKU",
    "variant": "Variations",
    "updateStock": "Update Stock",
    "updateSafetyStock2": "Update Safety Stock",
    "updateSafetyStock": "Import and Update Safety Stock",
    "updateInventoryInfo2": "Please update the inventory in \"Inventory Management\"",
    "updateInventoryInfo": "Please edit the inventory of bundled products by updating the inventory of Master SKU",
    "updatedSuccessfully": "Updated Successfully",
    "updateAvailableStock": "Update to store's available stock",
    "unitPurchasPrice": "Unit Purchase Price",
    "unitPurchasePrice": "Unit Purchase Price of the product",
    "unbindInfo": "Product has been unbinded from Master SKU, and locked stock will be released",
    "unbind": "Unbind",
    "turnOnStock": "Turn on Stock Monitoring and Sync settings, and initialize channel product inventory",
    "totalNum": "Total {val}",
    "toShip": "To Ship",
    "timeInfoWorkingDay": "working day",
    "timeInfoMonth": "Month",
    "thisVariationOnly": "This variation only",
    "theSameNameIsRegardedAs": "(The same name is regarded as the same product. The maximum length is 300 characters)",
    "theNameAlreadyExistsInTheStore": "The name already exists in the store",
    "theMasterSKUDoesNotExist": "The Master SKU does not exist, please check!",
    "theLinkOfVariantPicture": "The link of Variant picture 1 is invalid, please re-enter!",
    "theInventorySkuAlreadyExists": "The Master SKU already exists!",
    "theCurrencyUnitOfTheDefault": "(The currency unit of the Default Price and purchase unit price can be Rp, PHP, ¥)",
    "theCategoryIDDoesNotExist": "The category ID does not exist, please download the category table and check",
    "theBasicVersionCanImportA": "The basic version can import a small number of required fields, and can quickly create a Master SKU to enable inventory management feature",
    "syncInfoB": "{6787686756 Disable Inventory Sync",
    "syncInfoA": "{6787686756}Enable Inventory Sync",
    "successNumber": "Success Number",
    "successfullyUnbindInfo": "Successfully unbind {val} Variation(s)",
    "successfullyBindInfo": "Successfully bind {val} Variation(s)",
    "storeWithoutSales": "Store Without Sales",
    "storeUpdateResult": "Store Update Result",
    "storeSku": "Store SKU",
    "storeNameB": "Store Name",
    "storeNameA": "Store Name",
    "storeCountDesc": "Sort Selling Store from high to low",
    "storeCountAsc": "Sort Selling Store from low to high",
    "stockSettingsStockMonitorAndSyncOn": "Stock Settings-Stock Monitor and Sync，turned on：26-10-2020 17:17",
    "stockSettingsStockMonitorAndSyncOff": "Stock Settings-Stock Monitor and Sync，turned off：26-10-2020 17:17",
    "stockChannelInfo": "The stock that can be sold in Channel，Available Stock = Warehouse Stock-Spare Stock-Locked Stock-Promotion Stock",
    "stockChanges": "Stock Changes",
    "stepImportAndCreateThe": "Step 2: Import and create the Master Product",
    "stepDownloadTheMasterCategory": "Step 1: Download the Master Category ID",
    "step3Text": "Step 3: Import to Bind Product",
    "step2Text": "Step 2: Export the master SKU",
    "step1Text": "Step 1: Select and export the Channel Products that you want to Bind to master SKU",
    "spareStockTip": "The total amount of Stock reserved in the actual warehouse, which will not be sold in the channel.",
    "spareStock": "Spare Stock",
    "sourceUrl": "Source URL",
    "sortMasterProduct": "Sort Master Product",
    "someWarehouseTheWarehouseStockOf": "In some warehouse, the warehouse stock of the master SKU is not 0, please update to 0 before deleting.",
    "somePartUpdateFailed": "Some part update failed",
    "somePartReviewFailed": "Some part review failed",
    "someDatasImportFailed": "Some Datas Import Failed",
    "someCreateFailed": "Some Create Failed",
    "skuStoreSku": "SKU (store SKU)",
    "skuAlreadyExistsPleaseDeleteThe": "SKU already exists, please delete the existing product or rename it!",
    "singlePurchasePriceOfTheProduct": "Unit Purchase Price of the product",
    "singlePurchasePrice": "Unit Purchase Price",
    "shouldBeBetweenLimitB": "Should be between 0-1000,000,000",
    "shouldBeBetweenLimitA": "Should be between 0-999,999",
    "shouldBeBetweenB": "Should be between 1-999,999!",
    "shouldBeBetweenA": "Should be between 1-999,999",
    "shippingFeeInformation": "Shipping Fee Information",
    "setPageTip2": "Please enter a valid number of pages",
    "setPageTip": "Set Page To Export",
    "selling": "Selling",
    "selectProductBundle": "Select Product Bundle",
    "selectGineeCategory": "Select Master Category",
    "selectFile": "Select File",
    "selectedMax20": "Selected(Max.20)",
    "selected": "Selected",
    "selectCategories": "Select Categories",
    "selectAtLeastOneInventorySku": "Select at least one Master SKU",
    "editRule": "Edit Rule",
    "selectAtLeastOneImage": "Select at least one Image",
    "createDates": "Create Time",
    "seeXProducts": "See X Products",
    "seeMoreTips": "See {val} Products",
    "saveUpdate": "Save Update",
    "saveForReview": "Save for review",
    "savedSuccessfully": "Saved successfully",
    "saveAsReviewed": "Save as reviewed",
    "salesTaxAmount": "Sales tax amount",
    "safetyStockStatus": "Safety Stock Status",
    "safetyStock": "Safety Stock",
    "rupiah": "Rp",
    "reviewPassed": "Review Passed",
    "reviewed": "Reviewed",
    "reReview": "Re-review",
    "reasonForFailure": "Reason for failure: missing required information, please complete the information",
    "rangeLimitA": "Should be between 0-1,000,000,000",
    "rangeIsLimit": "Range is 5,000,000",
    "quantityLimit": "Quantity should be between 1-999,999",
    "qty": "Qty",
    "purchaseTime": "Purchase Time",
    "purchasePriceShouldLimit": "Unit Purchase Price should be between Rp0-Rp1000,000,000",
    "purchasePriceLimit": "Price Should be between 0-1,000,000,000!",
    "purchasePriceInfo": "(No need to input, the Unit purchase price will be calculated at the average cost)",
    "proVersionImportToCreate": "Pro Version Import to Create",
    "promotionStockTip": "Promotion Stock is the total stock reserved for Campaign or Flash Sale promotions",
    "promotionStock": "Promotion Stock",
    "productType": "Product type",
    "productsWithoutVariantsCanBeEmpty": "(Products without Variants can be empty)",
    "productSources": "Product Sources",
    "productNameLimit": "Product Name must be between 5 - 300 characters",
    "productNameInventorySku": "Product Name&Master SKU",
    "productInformation": "Product Info",
    "productImageLinkIsInvalid": "Product image 1 link is invalid, please check!",
    "productImageLinkCannotBe": "Product image 1 link cannot be empty!",
    "productImage": "Product Image",
    "productFailedToCreatePleaseDownload": "Master Product failed to create, please download the failed data and view the failed reason",
    "productEditSuccess": "Product Edit Success",
    "productEditFailed": "Product Edit Failed",
    "productDescriptionShouldBeCharacters": "Long Description should be 1-7000 characters",
    "productDescription": "Long Description",
    "productCreatedSuccessfully": "Product Created Successfully",
    "product": "Product",
    "preorder11": "Pre-order",
    "popupResultButton": "view Failed Data",
    "pleaseEnterTheAmount": "Please enter the amount",
    "pleaseEnterKeywordsToSearchChannelProducts": "Please enter keywords to search channel products",
    "pleaseEnter": "Please Enter",
    "pleaseDownloadTheTemplateAndComplete": "Please download the template and complete the complete data, and then import the created product (10000 datas can be imported each time).",
    "pleaseChoose": "Please choose",
    "partiallyFailedInfo": "Partially failed, status still Reviewed",
    "packageSize": "Package Size",
    "orderToEnsureTheIntegrity": "In order to ensure the integrity of the process and statistical report data, some master SKUs cannot be deleted, so the variant type of this product cannot be edited. It is recommended to \"refer\" this product to generate multiple variant of the master product, and then set this product as \"temporary untracked\".",
    "orderToEnsureTheIntegrityOf": "In order to ensure the integrity of data, it is recommended to use \"Temporary Untracked\".",
    "optionLimit": "The option must be between 1-30 characters",
    "option": "Option",
    "operationTime": "Operation Time",
    "operationGuide": "Operation Guide",
    "noShelfLife": "No Shelf Life",
    "onSaleShop": "On Sale Shop",
    "onReview": "On Review",
    "onlyShowBindedVariations": "Only show variations without MSKU",
    "numVariationsSelected": "0 variations selected",
    "numVariations": "{val} Variation(s)",
    "numSelected": "{val} Selected",
    "numEditBindInformation": "{val}-Edit bind information",
    "nowInbound": "Inbound Now",
    "normalProducts": "Normal Products",
    "noData": "No Data",
    "noChannelVariationId": "No Channel variation ID",
    "newProduct": "New Product",
    "newAveragePurchasePrice": "New Average Purchase Price",
    "newAverageCostPrice": "New Average Cost Price",
    "new": "New",
    "mutiVariations": "The product has variations, if your product has different colors and sizes variations, please choose this",
    "mustBeBetween": "Must be between 1-8",
    "mskuAndstockVideo": "What is the connection between Master Product and Inventory Management?",
    "mSku": "MSKU",
    "more": "More",
    "minPurchaseQuantityShouldBe": "Min. purchase quantity should be 1-1000",
    "minimumPurchaseQuantity": "Minimum Purchase Quantity",
    "mediaSettings": "Media Settings",
    "maxNumVal": "Max.{val}",
    "maxNum": "Max.{val}",
    "maxLengthNum": "Max. {val} characters",
    "maxLengthCharacters": "Max.{max} characters",
    "maxLength14": "Max. 14 characters",
    "maximumIs": "(Maximum is 1,000,000,000)",
    "maximumCharacters": "(Maximum 7000 characters)",
    "max9": "Max.9",
    "masterSkuHasBeenMatchedWith": "The master SKU has been mapped with the third-party warehouse product",
    "masterProductsImage": "Master Products & Image",
    "masterProductName": "Master Product Name",
    "masterProduct": "Master Product",
    "master": "Master Product",
    "massSettings": "Mass Settings",
    "mainPhotos": "Main Photos",
    "lockedStockTip": "Stock is waiting to be fulfilled but not shipped yet, New paid or New unpaid order synced to Ginee will add to Locked Stock",
    "lockedStock": "Locked Stock",
    "liveTips": "Range: Includes Out-of-stock and Live Products",
    "live": "Live",
    "length": "Length",
    "iWillShipOutWithinX": "I will ship out within x working hours.",
    "iWillShipOutWithin22": "I will ship out within 2 working days. (excluding public holidays and courier service non-working days)",
    "iWillShipOutWithin": "I will ship out within 3 working days. (excluding public holidays and courier service non-working days)",
    "iskuStandsForInventorySku": "MSKU Stands for Master SKU",
    "iskuCannotBeEmpty": "Master SKU cannot be empty!",
    "isAnUnprocessedOutboundInThe": "There is an unprocessed outbound in the master SKU.",
    "inventorySkuSpaceLimit": "Master SKU contains space or enter, please edit",
    "inventorySkuLimit": "Master SKU should be less than 300character",
    "inventorySkuGenRules": "Master SKU generation rules by default:\\n1. Master SKU will take the first 50 characters of Variant by default\\n2. The Warehouse Stock will be equal to the Variant Stock by default, and the Safety Stock will be empty by default\\n3. When the Master SKU is generated, the variant will be bound",
    "inventorySkuExistsLimit": "Master SKU already exists, please edit",
    "instructions": "Instructions",
    "iNeedXWorkingDays": "I need X working days to ship",
    "iNeedXWeeksToShip": "I need X weeks to ship",
    "iNeedXDaysToShip": "I need X days to ship",
    "importToEditProduct": "Import to Edit Product",
    "importToBindIntro": "This feature supports merchants to Bind store products to the Master SKU in mass to manage inventory",
    "importToBind": "Import to Bind Product",
    "importTip": "Import up to 1000 data at a time.",
    "importMasterProducts": "Import Master Products",
    "importFileFormatIsIncorrectInfo2": "Incorrect template file imported, please check",
    "importFileFormatIsIncorrect": "The import file format is incorrect, please download import template",
    "importFile": "Import File",
    "importFailed": "Import Failed",
    "importAndCreateProducts": "Import to Create Product",
    "importAndAddInfo": "You can import 10,000 data at once (format:xlsx,xls) to add Master product in mass",
    "imageSizeLimit": "Image size must be at least 300 x 300 pixels，up to 5MB",
    "ifItIsEmptyWillNot": "If it is empty will not updated",
    "hour": "hour",
    "hotSale": "Hot Sale",
    "helpCenterLink": "Operation Guide",
    "height": "Height",
    "hasBindedInfo": "This variant has been binded to the Master SKU xxxxxxxx. Please update the inventory in \"Inventory Management\"",
    "generateMaster": "Generate Master SKU for product in one store to manage inventory in multiple stores",
    "filter": "Filter",
    "fileRules": "The largest file: xlsx, xls only 5MB",
    "faqName2": "How to Create Master Product using Import in basic Version?",
    "faqName1": "How to Create Master Product using Import in Pro Version?",
    "failedReason": "Failed Reason: The product may be deleted, please re-sync the product",
    "failedNumber": "Failed Number",
    "exportUpTo": "You can export up to 10,000 pieces of data (formats xlsx, xls) each time to Bind products in batches",
    "exportTip": "Please check the products you want to export.",
    "exportSelected": "Export Selected",
    "exportOrImport": "Export/Import",
    "exportByPageTip": "Please enter a valid number of pages",
    "exportByPage": "Export by Page",
    "export": "Export",
    "enterTheNameOfTheVariationForExampleColorEtc": "Enter the name of the variation, for example: color, etc.",
    "enterProcessTime": "Enter Process Time",
    "enterPageNumber": "Please enter a number of pages to download a master product.",
    "enterOption": "Enter a option, for example: Red, etc",
    "enableInventorySync": "Enable Inventory Sync",
    "editSafetyFtock": "Edit Safety Ftock",
    "editOptionsBlue": "Edit Options-Blue",
    "editInventory": "Edit Inventory",
    "editAvailableStock": "Edit Available Stock",
    "downloadTip": "Download",
    "downloadTheMasterCategory": "Download the Master Category",
    "downloadTemplateInfo": "Download Template",
    "downloadProduct": "Download product",
    "downloadFailedData": "Download Failed Data",
    "discontinued": "Discontinued",
    "disableInventorySync": "Disable Inventory Sync",
    "descriptionRequired": "Please enter description and apply it to your product",
    "descriptionLimit": "Product Name must be between 1 - 300 characters",
    "delivery": "Delivery",
    "deleteMPTips2": "Master product has been deleted",
    "deleteMPTips": "Delete Failed: {val} Master Product",
    "deleteLimit": "Failed Reason:Please disassociate from ISKU {val}, and delete afterwards",
    "delConfirmTitle": "Are you sure you want to delete the product?",
    "delConfirmContent": "After deleting Master Product, Master SKU in Inventory Management will also be deleted",
    "defaultPrice": "Default Price",
    "dataSources": "Data Sources",
    "datasImportSuccess": "Datas Import Success",
    "datasImport": "Datas Import",
    "creatingProduct": "Creating product",
    "createProductFailed": "Create Product Failed",
    "costInformation": "Cost Information",
    "continueToBindInfo": "You can continue to bind channel products in the Master Product List",
    "condition": "Condition",
    "columnsCannotBeEmpty": "Columns cannot be empty",
    "collectByAdd": "Collect & Add",
    "cny": "CNY",
    "salesTaxAmountLimit": "Price Should be between 0-1,000,000,000!",
    "clearance": "Clearance",
    "clear": "Clear",
    "checkNow": "Check now",
    "channelVariationIdNotEmpty": "Chanel ID variation are required!",
    "channelVariationId": "Channel variation ID",
    "channelSellingStatus": "Channel Selling Status",
    "channelProductsImage": "Channel Products & Image",
    "channelProducts": "Channel Products",
    "channelProductName": "Channel Product Name",
    "changeTo": "Change to",
    "changeReason": "Change Reason:",
    "changeIsku": "Change Master SKU",
    "ceneratedFromChannelProduct": "Generated from Channel Product",
    "categoryID": "Category ID",
    "category": "Category",
    "cancelUpdateConfirm": "Are sure you want to discard this change?",
    "bundledProduct": "Bundled Product",
    "bundle": "Bundle",
    "boundInfo": "Bound",
    "bindProduct": "Bind Product",
    "bindNow": "Bind Now",
    "bindInstruction": "Finished Binding channel product with Master SKU, please download the template and import the data for integration",
    "bindingType": "Binding Type",
    "bindingInProgress": "Binding Product in mass",
    "bindingInformation": "Binding Information",
    "bindInformation": "Bind Information",
    "bindInfoB": "l,gree {id:111234} unbind with {6787686756}",
    "bindInfoA": "l,gree{id:111234} bind {6787686756}",
    "bindHistory": "Bind History",
    "bindedInventorySku": "Binded Master SKU",
    "binded0Variation": "Binded 0 Variation",
    "weightShouldBeBetween": "Weight Should be between 1-5000,000!",
    "heightShouldBeBetween": "Height Should be between 1-999,999!",
    "widthShouldBeBetween": "Width Should be between 1-999,999!",
    "lengthShouldBeBetween": "Length Should be between 1-999,999!",
    "betweenWeeks": "Between 1-13 weeks",
    "betweenDays": "Between 1-90 days",
    "between21": "Between 7-15",
    "between": "Between 3-30",
    "beBetweenWorkingHoursMust": "Must be between 1-8 working hours",
    "basicVersionImportToCreate": "Basic Version Import to Create",
    "theSameNameWillConsideredAs": "(The same name will considered as the same product. The maximum name length is 300 characters)",
    "categoryName": "Category name",
    "basicInformation": "Basic Information",
    "variantPictureLink": "Variant picture 1 link",
    "barcodeLimitC": "Barcode only supports letters, numbers and -_and should not more than 100 characters",
    "productImageLink1": "Product image 1 link",
    "barcodeLimitB": "Barcode should not more than 100 characters",
    "barcodeLimitA": "Barcode only supports letters, numbers and -_",
    "temporaryNotFollowingXProducts": "Temporary not following {X} products",
    "thisBarcodeIsAlreadyOccupiedBy": "This barcode is already occupied by {MSKU}, please re-enter",
    "currency": "Currency",
    "barcode": "Barcode",
    "averagePurchasePrice": "Average Purchase Price",
    "averageCostPriceInfo": "Average Cost Price = Total Unit Purchase Price/Total Warehouse Stock",
    "averageCostPrice": "Average cost price",
    "variantOption1ShouldBe": "Variant option 1 should be 1-20 characters",
    "variantOption1": "Variant option 1",
    "variantOption2": "Variant option 2",
    "variantTypeIs2": "Variant type 2",
    "variantTypeIs": "Variant type 1",
    "unspecifiedProductsCanBeEmpty": "Unspecified products can be empty",
    "availableStockTip": "The stock that can be sold in Channel,Available Stock = Warehouse Stock-Spare Stock-Locked Stock-Promotion Stock",
    "availableStockLimit": "Available Stock cannot be empty!",
    "availableStockInputLimit": "warehouse inventory ≥ spare inventory",
    "availableStockInfo": "Available Stock = Warehouse stock-Spare stock-Locked stock-Promotion stock, Edit the Available stock by updating the Warehouse stock",
    "availableStock": "Available",
    "availableForDownloadCategory": "(Available for download category)",
    "autoMatchVariationInfo": "After Master SKU is created, Master SKU will auto binded variations which store SKU exactly match with the Master SKU",
    "articleName2": "How to integrate channel product to Master SKU?",
    "applyToAllVariation": "Apply to all Variation",
    "aOfb": "1-50 of 219",
    "allVariation": "All Variation",
    "missingVariantPleaseCheck": "Missing 1 variant, please check!",
    "allUpdateFailed": "All update failed",
    "max1000": "Max. 1000",
    "missingMasterProductVariantRed": "Missing Master Product Variant: Red / S, Red / L\nNeed to be completed to add a product",
    "massOperationHistory": "Mass operation history",
    "publishToStore": "Publish to the store",
    "chooseDestinationStore": "Choose destination store",
    "selectStore": "Select Store",
    "nextStep": "Next step",
    "orderProcessingTimeFollowsTheShops": "The order processing time follows the shop’s settings",
    "allUpdatedSuccessfully": "All updated successfully",
    "allSuccessfullyInfo": "All successfully changed to pending review",
    "allSuccessfullyCreated": "All Successfully Created",
    "allReviewFailed": "All review failed",
    "shipTime": "Process Time",
    "allReviewed": "All Reviewed",
    "allRecordsOfChannelProductsInfo": "All records of channel products (that are bound to Master SKU) that have been updated by Ginee in channel stores (last 30 days)",
    "INeed": "I need",
    "allFailedInfo": "All failed, status still Reviewed",
    "allCreateFailed": "All Create Failed",
    "hoursWorking": "working hours",
    "instant": "Instant",
    "orderProcessingDeadline": "Order Processing Deadline",
    "all": "All",
    "toViewTheMassOperationRecords": "Supported to view the mass operation records of the past 7 days, check now!",
    "toPublishToTheStoreHow": "How to \"Publish to the store\"？",
    "toBatchEditingOfMainProducts": "How to \"Batch Editing of Master Products\"？",
    "thePriceOfTheProductIn": "Synchronize the price of the product in the bound channel to Ginee",
    "productSyncronized": "Syncronized Product",
    "isRecommendedToSynchronizeThePromotion": "It is recommended to synchronize the promotion of channel stores first to ensure that the product promotion price is consistent with the seller center",
    "promotionSync": "Sync Promotion",
    "variationIdHasBeenBoundChannel": "Channel variation ID has been bound",
    "productInformationMaster": "Master Product Information",
    "theInformationOfAnExistingMaster": "Reference the information of an existing master product",
    "productReferenceMaster": "Master Product Reference",
    "copy": "Copy Master Product",
    "skuAutobindSettingEnabledMaster": "Master SKU Auto-Bind Setting (Enabled)",
    "skuAutobindSettingNotEnableMaster": "Master SKU Auto-Bind Setting (Not Enable)",
    "MSKUAutoBindSettings": "Master SKU Auto-Bind Settings",
    "matchingExact": "Exact Mapping",
    "matchingFuzzy": "Fuzzy Mapping",
    "Choose": "Choose",
    "stockWarehouse": "Warehouse Stock",
    "afterTheSafetyInfo": "After the safety stock is enabled, if the available stock of the Master SKU is lower than the safety stock, a reminder will be sent by email.",
    "nowIntegrate": "Integrate Now",
    "productHasBeenBoundChannel": "Channel product has been bound",
    "stockSynchronizationSettingsEnable": "Enable stock synchronization settings",
    "inboundToWarehouseAlready": "Already inbound to warehouse",
    "partFailedDelete": "Delete part failed",
    "allFailedDelete": "Delete all failed",
    "allSuccessfulDelete": "Delete all successful",
    "close1": "Close",
    "productHasAOutboundListTo": "The product has a outbound list, to ensure the completeness of the data, it is recommended to use \"temporary untracked\" product",
    "warehouseStockOfThisProductIs": "Some warehouse stock of this product is not 0, please empty it first and then delete it",
    "productHasBeenBoundToA": "This product has been bound to a product in a third-party warehouse.to ensure the completeness of the data, it is recommended to use \"temporary untracked\" product",
    "afterTheChannelInfo": "After the channel product is bound to the Master SKU, the store inventory is initialized",
    "failedDelete": "Delete Failed",
    "productBindingStatusChannel": "Channel product binding status",
    "boundChannelProductAll": "All bound channel product",
    "boundChannelProductPartially": "Partially bound channel product",
    "allChannelProductUnbind": "Unbind all channel product",
    "operationSuccessfulMass": "Mass operation successful",
    "toTheMasterProductAnd": "Recover to the master  product and no longer mark it as \"temporary untracked\"",
    "temporaryUntrackedDisplay": "Display temporary untracked",
    "unbindMasterProductAndChannelProduct": "Mass unbind master product and channel product",
    "recoverMass": "Mass recover",
    "temporaryUntrackedMass": "Mass temporary untracked",
    "unbindMass": "Mass Unbind",
    "deleteMass": "Mass Delete",
    "group": "Group",
    "reference": "Reference",
    "untrackedTemporary": "Temporary Untracked",
    "combination": "Combination",
    "variantMultiple": "Multiple variant",
    "addWarehouseStockLimit": "Warehouse Stock should be between1-999,999",
    "addVariation": "Add Variation",
    "addSpareStockLimit": "Stock should be between 0 and 999,99",
    "supportSelectingMultipleStoresInThe": "Only support selecting multiple stores in the same country",
    "variantNo": "No variant",
    "theProductThatFailedToBe": "Set the product that failed to be deleted as temporary untracked, so the master product will not be displayed in \"all\" list",
    "masterSkuHasBeenBoundBy": "The master SKU has been bound by the combined product, please remove the association and delete it.",
    "masterProductsThatAreSetAs": "All master products that are set as \"temporary untracked\" (including combination or general types of products)",
    "typeOfMasterProductCombined": "Combined type of master product",
    "productSingle": "Single product",
    "theGeneralOfMasterProductType1": "In the general of master product type, there are master product with 1 or 2 variant type",
    "theGeneralOfMasterProductType": "In the general of master product type, there is no master product of variant type",
    "notIncludeTheTemporaryUntrackedMaster": "Does not include the \"temporary untracked\" master product",
    "skuHasBeenBoundByThe": "Master SKU has been bound by the combined product {bundle MSKU}, please unbundle and delete it.",
    "skuHasMatchedTheProductsOf": "Master SKU has matched the products of the third-party warehouse {third-party warehouse name}",
    "skuHasPendingOutboundOrderOr": "Master SKU has pending outbound order or inbound order, please delete it after completion",
    "stockOfTheMasterSkuIn": "Warehouse stock of the master SKU in the {warehouse name 1, warehouse name 2,} warehouse is not 0, please update to 0 before deleting.",
    "errorPleaseRefreshThePageAnd": "Data error, please refresh the page and try again",
    "retry": "Retry",
    "productSuccessfullyCreatedMaster": "Master Product successfully created!",
    "beBetweenMust": "Must be between 1-999,999",
    "addOption": "Add Option",
    "stockMonitoringAndSynchronizationEnable": "Enable stock monitoring and synchronization",
    "toWarehouseInboundAdd": "Add to Warehouse + Inbound",
    "channelProductsRefer": "Refer Channel Products",
    "failedUnbind": "Unbind Failed",
    "addMskuArticle": "How do I add a Master SKU?",
    "unbindAllFailed": "Unbind all failed",
    "allUnbindSuccessfully": "All unbind successfully",
    "partialUnbinding": "Partial unbinding failed",
    "unbinded": "Unbinded",
    "bound": "Bound",
    "unbindedAll": "All Unbinded",
    "boundAll": "All bound",
    "masterSkuHasEnableStockSynchronization": "The master SKU has enable stock synchronization, please go to stock management to modify the stock.",
    "youNeedToEnableStockSynchronization": "If you need to enable stock synchronization of the master product, please continue to complete the operation of add master product to warehouse and inbound",
    "toProductListReturn": "Return to product list",
    "failedDataDownload": "Download failed data",
    "createFailedAll": "All Create Failed!",
    "addManually": "Add Manually",
    "masterProductSuccessfullyCreatedIfYou": "Some master product successfully created! If you need to enable stock synchronization of the master product, please continue to complete the operation of add master product to warehouse and inbound",
    "failedToCreateSome": "Some failed to create!",
    "masterProductView": "View Master Product",
    "successfullyCreatedAll": "All successfully created!",
    "productSuccessfullyCreatedIfYouNeed": "Master Product successfully created! If you need to enable stock synchronization of the master product, please continue to complete the operation of add master product to warehouse and inbound",
    "productCanOnlyUseOneCurrency": "The product can only use one currency, please select again!",
    "creatingTheMasterProductCanCompleted": "After creating the master Product can completed Inbound and enable master SKU stock synchronization.After enable, the stock of the master SKU and channel stores can be automatically updated and synchronized.",
    "masterSkuHasBeenPlacedIn": "Combined Master SKU has been placed in Inbound Warehouse",
    "warehouseDefault": "Default Warehouse",
    "toWarehouseAdd": "Add to Warehouse",
    "toStoreClone": "Clone to Store",
    "enableStockSynchronizationOfMasterProduct": "To enable stock synchronization of Master Product, please continue to complete the operations of Add to Warehouse and Inbound. To quickly publish the Master Product to the channel store for sale, please use \"Clone to  Store\"",
    "quicklyPublishTheMasterProductsTo": "To quickly publish the master products to the channel store for sale, please use \"Clone to Store\".",
    "informationWarehouse": "Warehouse Information",
    "inbounded": "Completed",
    "inboundNot": "Not Inbound",
    "skuHasBeenPlacedInInbound": "Master SKU has been placed in Inbound Warehouse",
    "statusStock": "Stock Status",
    "addByStore": "Auto Add by Store",
    "addBundleProduct": "Add Bundle Product",
    "oneCurrency": "The product can only be used in one currency, please check",
    "variantSingle": "Single variant",
    "productsOfVariants": "10 products ( 3 of 20 Variants can generate Master Product).Variant that is bound to Master SKU or empty SKU can't generate Master SKU.\nClick the Content to Edit",
    "synchronizationOfTheMasterSkuWill": "Stock synchronization of the master SKU will enabled after inbound is completed and master product is created. After enabling, the stock of the master SKU and channel stores can automatically updated and synchronized",
    "toTheNChannelsProductList": "Go to the {n} channel's product list now",
    "channelsProductList": "Go to the product list of the channel:",
    "goNow": "Go now",
    "goToTheChannelProductList": "Please go to the channel product list - tick the channel product you want to refer.",
    "doNotHaveAnIntegratedStore": "You do not have an integrated store, please go to integrate first",
    "toModifyGo": "Go to Modify",
    "skuHasBeenInboundToThe": "Master SKU has been inbound to the warehouse, please go to [Inventory Management] to modify the available stock",
    "productCombined": "variant type already exists, please re-enter",
    "combinedProductAdd": "Combined Product",
    "theContentToEditClick": "Add Combined Product",
    "combinationProductsEdit": "Edit combination products",
    "preview": "Preview",
    "atTheEndAdd": "Add at the end",
    "increaseStartingValueAutomatically": "Automatically increase starting value",
    "atTheBeginningAdd": "Add at the beginning",
    "xxWithYyyReplace": "Replace xx with yyy",
    "massEditSKU": "Mass edit Master SKU",
    "changeStockTo": "Change stock to",
    "massEditStock": "Mass Edit stock",
    "applyToAllProducts": "Apply to all Products",
    "masterDeleteFailedTip": "The product has been successfully removed from the {warehouse name 1}, ⁣{warehouse name 2} warehouse.",
    "masterStockpdateFailed": "The stock update of the Master SKU that has been stored has failed, please go to inventory management to modify",
    "stockLimit": "Stock Should be between 0-999,999!",
    "reduceByPercent": "Reduce by percent",
    "addByPercent": "Add by percent",
    "reduceByAmount": "Reduce by amount",
    "addByAmount": "Add by amount",
    "massEdit": "Mass Edit",
    "mskuNoSpaces": "Master SKU cannot contain spaces, please update",
    "followingXProductsRestore": "{X} items have been restored",
    "productsAreSetAsTemporary": "No longer follow {X} products",
    "masterSkuDoesNotMeetThe": "Master SKU does not meet deletion requirements, so the specification type and specification options cannot be deleted",
    "barcodeExist": "Barcode is already occupied by {MSKU}, please re-enter",
    "massEditPrice": "Mass Edit  Price",
    "changePriceTo": "Change price to",
    "replaceWith": "Replace with",
    "masterSkuLimit": "Master SKU should be less than 200character",
    "numberOfVariants": "Number of variants",
    "yourCurrentPackageDoesNotHave": "Your current package does not have permission to operate, please subscribe to the new package or contact the customer to upgrade the package",
    "yourNumberOfOrdersHasExceeded": "Your number of orders has exceeded the limit of current package (maximum {X}), please subscribe to the new package or contact customer service to upgrade package.",
    "salesTaxAmountTip": "Tax expenses for a single product",
    "purchaseTimeTip": "Time period from delivery of purchase until purchase inbound",
    "sourceUrlTip": "The source of product, such as the supplier’s link or if products scraped from the source link",
    "chatLimitTip2": "Your permission has exceeded the package limit, please go to Ginee OMS to adjust before continuing to use Ginee chat",
    "chatNoResourcesLimitTip": "Your number of stores or staff accounts has exceeded the package limit, please go to Ginee OMS to adjust package before continuing to use Ginee Chat",
    "chatNopermissionLimitTip": "Your account package does not include Ginee Chat feature, please contact customer service to upgrade the package and log in again.",
    "packageLimit": "Package Limitation",
    "masterProductpublishToTheStore": "Master Product/Publish to the store",
    "masterProductmassEdit": "Master Product/Mass edit",
    "stockStatusTip": "Display stock status in this column: Warehouse, stock sync status, Master SKU type information.\nWarehouse: The warehouse where this Master SKU is placed.\nStock sync status: Whether the Master SKU has been enabled for stock adding or dropping sync. The \"stock sync status\" of the newly added Product Master will be disabled by default.\nCombination Master SKU tag: If this Master SKU is a combined Master SKU, the \"combination\" tag will be displayed",
    "synchronizationIsNotEnabledYouNeed": "Disable: Stock sync not enabled\nAll the necessary conditions for activation have not been met:",
    "synchronizationIsEnabledStock": "Enable: Stock Sync is active\nAll the necessary conditions for activation have been met:",
    "stockSynchronizationStatusOfTheMaster": "If the Master SKU stock sync status is not enabled, then the Master SKU will not activate the stock increase or decrease synchronization feature.",
    "stockSynchronizationStatusOfTheCombined": "If the stock sync status of the combined MSKU is not enabled, the combined MSKU will not automatically calculate available stock based on the bundled Master SKU, and stock synchronization will not be enabled.",
    "open": "Enable",
    "settingsManagementForAuthorizedThirdpartyAccounting": "Configuration management for authorized third-party accounting software",
    "accountingSettings": "Accounting Settings",
    "addedBy": "Added By",
    "timeAdded": "Time Added",
    "updater": "Update By",
    "checkLog": "Check Operation Record",
    "accurateOrder": "Accurate Order",
    "accurateProduct": "Accurate Product",
    "failedToGetChannelsLabelUrl": "Failed to get channel's label URL, please push again",
    "bindWarehouse": "Bind Warehouse",
    "Prohibited": "Prohibited",
    "interval": "Interval",
    "productSales": "Product Sales",
    "updatetime": "Update Time",
    "masterProductCreationTime": "Master Product Creation Time",
    "channelProductCreationTime": "Channel  Product Creation Time",
    "salesOfDifferentActivityLevelsOverlap": "Sales of different activity levels overlap, please re-enter",
    "salesRangeOfXCannotBe": "Sales range of {x} cannot be higher than {y}",
    "channelProductDimensionActivitySetting": "Channel product dimension activity setting, currently only supports Shopee",
    "synchronizeallthexxinthewarehousewheremskusarelocatedtochannelskusaccordingtothexxxratiowhenthedefaultruleisenabledmskusthatalreadyexistinotherrulesarenotincluded": "Synchronize all the XX in the warehouse where MSKUs are located to channel SKUs according to the XXX ratio. When the default rule is enabled, MSKUs that already exist in other rules are not included",
    "availablestockforotherstoresispushedto": "Available stock for other stores is pushed to 0",
    "allExceptxx": "All except XX",
    "whentheavailablestockislessthanorequaltoxx": "When the available stock is less than or equal to XX",
    "whenmsku": "When MSKU",
    "stockThreshold": "Stock Threshold",
    "availablestocktransferstock": "Available Stock + Transfer Stock",
    "availablestock": "Available",
    "pleaseSelectawarehouse": "Please select a warehouse",
    "addPolicy": "Add Policy",
    "pushratio": "Push Ratio",
    "warehousename": "Warehouse Name",
    "pushbyfixedvalue": "Push By Fixed Value",
    "pushbybatio": "Push By Ratio",
    "setSyncPolicy": "Set sync policy",
    "setSynchronizationconditions": "Set synchronization conditions",
    "selectMsku": "Select MSKU",
    "addMsku": "Add MSKU",
    "ruleNamecannotbeempty": "Rule name cannot be empty",
    "pleaseenterarulename": "Please enter a rule name",
    "executeRules": "Execute Rules",
    "pushLog": "Push Log",
    "default": "Default",
    "defaultRules": "Default Rules",
    "storeName1": "Store Name",
    "stockType": "Stock Type",
    "pushType": "Push Type",
    "mainStoreStockLocked": "Main Store Stock Locking",
    "log": "Log",
    "addrule": "Add Rule",
    "stocklistpromptwhenadjustingstockstockadjustment": "[Stock List] Prompt when adjusting stock: Stock adjustment [(+)/(-)X]",
    "promptwhenaddingstockwithmanualinboundmanualinbound": "Prompt when adding stock with manual inbound: Manual inbound【121133】",
    "whenmanuallypushinginventorymanuallyexecutestockpushrules": "When manually pushing inventory: Manually execute stock push rules",
    "promptwhentheordercomesinneworder": "Prompt when the order comes in: New order [576512290177977223]",
    "promptwhenorderiscancelledordercanceled": "Prompt when order is cancelled: Order canceled [576512290177977223]",
    "promptwhenbindingforthefirsttimeenablestockmonitoringandsyncsettingsthenpushdatatostoresstock": "Prompt when binding for the first time: Enable stock monitoring and sync settings, then push data to store‘s stock",
    "xxofchannelproductsfailed": "XX of channel products failed",
    "creationtime": "Create Time",
    "pushquantity": "Push Quantity",
    "reasonforchange": "Reason for change",
    "seemore": "See more",
    "imageMasterProduct": "Image & Master Product",
    "partialSuccess": "Partial Success",
    "storeupdateresults": "Store Update Results",
    "pleaseentersearchcontent": "Please enter search content",
    "storepushrecord": "Store Push Record",
    "stockValue": "Stock Value",
    "isBestSellingItem": "Best Selling Item",
    "isTrendingItem": "Trending Item",
    "isNewItem": "New Item",
    "isLowSellingItem": "Low Selling Item",
    "isNormalItem": "Normal Item",
    "mskuXAlreadyExistsInRule": "MSKU: {X} already exists in rule {Y}",
    "ruleNameAlreadyExists": "Rule name already exists",
    "thereAreDuplicateRuleNameOr": "There are duplicate rule name or Master Product",
    "noteWhenTheInventoryThresholdIs": "Note: When the stock threshold is enabled, when the stock in the \"Synchronization Policy\" is less than or equal to the \"Threshold\", the available stock of other stores except the main store is pushed to 0",
    "totalCapitalUsedFromAllWarehouses": "The total capital occupied by all warehouses",
    "storeIntegrationIsInvalidPleaseReintegrate": "Store integration is invalid, please re-integrate",
    "mskuAndStoreAreNotBound": "MSKU and store are not bound",
    "warehouseAndStoreAreNotBound": "Warehouse and store are not bound",
    "warehouseAndMskuAreNotBound": "Warehouse and MSKU are not bound",
    "productChannelAndMskuAreNot": "Product channel and MSKU are not bound",
    "deleteRule": "Are you sure you want to delete XX rule?",
    "ruleNameXChangedToY": "Rule name: {X} changed to {Y}",
    "pushTypeChangeFromFixedValue": "Push type: change from fixed value push to ratio push, push at {X}% of the available stock in {X} warehouse + {y} warehouse",
    "pushTypeChangeFromProportionalPush": "Push type: change from push ratio to fixed value push, the fixed value is XXX",
    "warehouseNameWarehouseXHasBeen": "Warehouse name: {X} warehouse changed to {Y} warehouse",
    "addRuleNewRule": "Add rule: New rule",
    "statusChangeStatusHasBeenChanged": "Status change: Status has been changed from OFF to ON",
    "statusChangeStatus": "Status change: Status has been changed from ON to OFF",
    "deleteRuleRuleHasBeenDeleted": "Delete rule: Rule has been deleted",
    "ruleChangeTheTotalOfStock": "Rule change: The total of stock available in {X} warehouse has been changed from {X}% to {Y}%",
    "policyChangeTheAvailableStock": "Policy change: The available stock + in-transit stock of the {X} warehouse is changed from the push ratio {X}% to {Y}%",
    "editAddedXMskuThresholdIs": "Edit: Added {X} MSKU, threshold is {X}, select main store is: {Y}",
    "editXMskuThresholdChangedFrom": "Edit: {X} MSKU threshold changed from {Y} to {Z}",
    "editXMskuMainStoreHas": "Edit: Main store {X} MSKU  has been changed from {X} to {Y}",
    "editXMskuHasBeenDeleted": "Edit: {X} MSKU has been deleted",
    "afterDeletionThePolicyNeedsTo": "After deletion, the policy needs to be reconfigured, please operate with caution",
    "thirdpartyWarehouseReceptionTime": "Third-party warehouse reception time",
    "whetherToReplaceProduct": "Whether to replace product",
    "whetherItIsAGift": "Is a Gift",
    "addGiftBForOrder": "Add gift  {B] for order {A}, quantity is {C}, and the selected warehouse is {D}",
    "orderAWithProductBIs": "Order {A} with product {B} is replaced by {C], and the selected warehouse is {D}",
    "changeSelectedWarehouseCToD": "Change selected warehouse {C} to {D} for order {A} with product {B}",
    "gift": "Gift",
    "specifiedWarehouse": "Specified Warehouse",
    "specifiedQuantity": "Specified Quantity",
    "massAddForOrdersSpecifiedMsku": "Mass add for orders: Specified MSKU and quantity!",
    "replace1": "replace",
    "and1": "and",
    "replaceInBatchForTheSpecified": "Replace in batch for the specified products under the order!",
    "changeWarehouseTo": "Change warehouse to",
    "specifiedProduct": "Specified Product",
    "doWarehouseModificationForSpecifiedProduct": "Do warehouse modification for specified product under the order! (The modified product must be associated with the corresponding warehouse)",
    "doWarehouseModificationForAllProduct": "Do warehouse modification for all product under the order! (The modified product needs to be associated with the corresponding warehouse)",
    "byProduct": "By product",
    "byOrder": "By order",
    "processingMethod": "Processing Method",
    "addGift": "Add Gift",
    "replaceProduct": "Replace Product",
    "changeWarehouse": "Change Warehouse",
    "modifyProductInformation": "Edit Product Info",
    "weightkg": "Weight(kg)",
    "youMustAddAllTheVariation": "You must add all the variation images or leave all of them empty.",
    "masterVariationName": "主商品规格名称",
    "operationSuccessPrompt": "Operation success prompt",
    "checkProduct": "Check Product",
    "productHasBeenSubmittedAndIs": "Product has been submitted and is waiting for the {channel} to process, you can check the product on tab In process, Live or Publish Failed",
    "minimumStockLimit": "Minimum Stock Should be between 0-999,999",
    "noNeedToProvideAnyTransport": "No need to provide any transport services, such as logistics credentials, on-site service, delivery point service, etc.",
    "thisTypeCanBeSelectedTo": "This type can be selected to ship a product that weighs more than 50kg, or one that need a special treatment such as installation.",
    "notShipped": "Not Shipped",
    "sellerShipping": "Seller Shipping",
    "shipYourOrderToTheBLIBLI": "Ship your order to the BLIBLI assigned logistics partner. For Sameday / Instant, the order will be picked up in pinned point.",
    "shippingUsingBlibliLogisticPartner": "Shipping using Blibli Logistic Partner",
    "minimumStock": "Minimum Stock",
    "enterSellingPrice": "Please Enter Selling Price",
    "sellingPriceLimit": "Selling Price Should be between  1-100.000.000",
    "originalPrice": "Original Price",
    "enterOriginalPrice": "Please Enter Original Price",
    "changeMinimumStockTo": "Change Minimum stock to",
    "restrictionsTheProductCannotBeEdited": "API restrictions, the product cannot be edited",
    "unboundMSKU": "Unbound Master SKU",
    "bindMSKU": "Bind Master SKU",
    "Country": "Country/Region",
    "ContinuetoPublish": "Continue to Publish",
    "CreateTimeNewest": "Create Time Newest To Oldest",
    "CreateTimeOldest": "Create Time Oldest To Newest",
    "notification": "Notification!",
    "NoBrand": "No Brand",
    "PromotionActivity": "Activity",
    "PromotionCampaign": "Campaign",
    "PromotionDuration": "Duration",
    "PromotionPrice": "Promotion Price",
    "PromotionOriginalPrice": "Original Price",
    "cannot0": "Your product cannot be seen by potential buyers because there are 0 stocks",
    "EditStock": "Edit Stock",
    "ResultsFail": "The mass operation has been stopped due to network or server problem. Try again later.",
    "SomeOperationsFailed": "Some product operations failed",
    "Sort": "Sort by",
    "MostStock": "Most Stock",
    "LeastStock": "Least Stock",
    "Oldest": "Update Time Newest To Oldest",
    "Newest": "Update Time Oldest To Newest",
    "Within7Days": "Within 7 days",
    "Yesterday": "Yesterday",
    "Today": "Today",
    "CountDraftFailed": "Products publish failed",
    "CountDraftSuccess": "Products publish succeeded",
    "CountRemoveFailed": "Products remove failed",
    "CountRemoveSuccess": "Products removed successfully",
    "CountUnAvailableFailed": "Products failed to be set unavailable",
    "CountUnAvailableSuccess": "Products have been successfully set unavailable",
    "CountAvailableFailed": "Products failed to be set available",
    "CountAvailableSuccess": "Products have been successfully set available",
    "PartDraftFailed": "Some product publish failed！",
    "DraftFailed": "Publish Failed",
    "DraftSuccess": "Publish succeeded！",
    "PartRemoveFailed": "Some products remove failed",
    "RemoveFailed": "Remove failed",
    "RemoveSuccess": "Removed successfully",
    "PartUnAvailableFailed": "Failed to set some products unavailable",
    "UnAvailableFailed": "Failed to be set unavailable",
    "UnAvailableSuccess": "Successfully set unavailable",
    "PartAvailableFailed": "Some products failed to be set available",
    "AvailableFailed": "Failed to be set available",
    "AvailableSuccess": "Successfully set available",
    "InProcessDraftPublishModalTitle": "Publishing products in bulk in stores",
    "InProcessRemoveModalTitle": "Removing products in store",
    "InProcessDisableModalTitle": "Setting products to be unavailable in the store",
    "InProcessPublishModalTitle": "Setting products to be available in the store",
    "StockRulesInfo": "The stock should be between {minNum}～999999",
    "ResultsClose": "Close",
    "ResultsTitle": "Result",
    "ApplyToAll": "Apply To All",
    "MassEditStock": "Mass Edit",
    "SetUnAvailable": "Set unavailable",
    "SetAvailable": "Set available",
    "DeletedDesc": "Removing Product in Ginee will Remove this product in this channel store.",
    "DeletedTitle": "Are you sure you want to delete the product?",
    "PublicDesc": "Setting this product available in Ginee will set the product available in this channel store",
    "PublicTitle": "Are you sure to set this product available?",
    "DisabledDesc": "Setting this product unavailable in Ginee will set the product unavailable in this channel store",
    "DisabledTitle": "Are you sure to set this product unavailable?",
    "productTime": "Time",
    "ProductSelected": "Product(S) Selected",
    "ProductParentSku": "Parent SKU",
    "ProductNameAndStore": "Product Name and Store",
    "adjustSyncFailed": "Stock Syncronization Failed, Please try again.",
    "UpdateStock": "Update",
    "NewStock": "New Stock",
    "ModifyStock": "Modify Stock in Inventory management",
    "CurrentStock": "Current Stock",
    "adjustStock": "Adjust Stock",
    "categories": "Categories",
    "removeList": "Delete",
    "productStatus": "Status",
    "savedAsdraft": "Saved as draft successfully",
    "pleaseSelect": "Please Select",
    "ProductDescription": "Long Description",
    "masterCategory": "Master Category",
    "ProductImage": "Product Image",
    "defaultPriceError": "The Default Price range should be 0-1.000.000.000",
    "editDefaultPrice": "Edit Default Price",
    "editDescription": "Edit Description",
    "addAfterName": "Add after name",
    "addBeforeName": "Add before name",
    "weightShouldBeBetweenKg": "Weight should be between 0.001-1000",
    "workingHours": "Working hours",
    "shippingSettings": "Logistic Settings",
    "massEditProductStatus": "Mass Edit Product status",
    "goToEdit": "Go to Edit",
    "theInventoryOfTheMasterProduct": "The inventory of the Master Product is managed in [Inventory Management], please go there to edit stock of the selected Product",
    "changeMinPurchaseQuantityTo": "Change Min. purchase quantity to",
    "massEditMinPurchaseQuantity": "Mass Edit Min. purchase quantity",
    "massEditDimensionsCm": "Mass Edit dimensions (cm)",
    "massEditProductName": "Mass Edit Product name",
    "editProductImage": "Edit Product Image:",
    "customDisplayInformation": "Custom display information",
    "logisticsChoose": "Choose logistics",
    "selectionOfStoreswarehousesMass": "Mass selection of stores/warehouses",
    "toMatchCategoryNeed": "Need to map category",
    "categoriesMatched": "Mapped categories",
    "productsSelected": "Selected products",
    "categoryAttributesShopee": "Shopee category attributes",
    "categoryAttributesEdit": "category attributes",
    "theValueJustEnteredWantIt": "The value just entered, want it applied to all the products of the same category?",
    "matchingCategoryAndVariantAttributesProduct": "After mapping category and variant attributes, product information can automatically be mapped, such as variant price, inventory, etc.",
    "productInfoSource": "Source Product Info",
    "noLogisticOptions": "No Logistic Options",
    "sellingPrice": "Selling Price",
    "productUpdateFailed": "Product Update failed！",
    "publishFailed": "Publish failed",
    "publishSucceeded": "Publish succeeded",
    "massEditMinimumStock": "Mass Edit Minimum Stock",
    "skuLimit": "SKU should be less than 100 characters",
    "sku": "SKU",
    "packageSizeCM": "Package Size(cm)",
    "main": "Main",
    "maxval": "Max.{val}",
    "priceBetweenMy": "Should be between 0.1-1.000.000.000",
    "priceBetweenTh": "Should be between 1-500.000",
    "priceBetweenVn": "Should be between 1000-120.000.000",
    "priceBetweenPh": "Should be between 5-1,000,000",
    "priceBetweenId": "Should be between 99-150.000.000",
    "weightg": "Weight(g)",
    "selectABrandPlease": "Please select a brand",
    "uploadAVariantPicturePlease": "Please upload a variant picture",
    "uploadProductImagePlease": "Please upload product image",
    "enterADescriptionPlease": "Please enter a description",
    "enterTheProductNamePlease": "Please enter the product name",
    "selectTheStatusOptionPlease": "Please select the status option",
    "selectPreorderOptionPlease": "Please select pre-order option",
    "enterLogisticsOptionsPlease": "Please enter logistics options",
    "enterThePackageSizePlease": "Please enter the package size",
    "enterTheWeightPlease": "Please enter the weight",
    "enterstock": "Please Enter stock",
    "enterPrice": "Please Enter  Price",
    "enterSKU": "Please Enter  SKU",
    "theProductHasVariationsIfYour": "The product has variations, if your product has different colors and sizes variations, please choose this",
    "enterAOptionForExampleRed": "Select a option, for example: Red, etc",
    "addVariationType": "Add  Variation Type",
    "variationType": "Variation Type",
    "productDescriptionLimit": "Product Description must be between 6- 3500 characters",
    "productDescriptionLimitTh": "Product Description must be between 25- 5000 characters",
    "productDescriptionLimitVn": "Product Description must be between 100- 3000 characters",
    "productDescriptionLimitPh": "Product Description must be between 2- 3000 characters",
    "productDescriptionLimitIdMy": "Product Description must be between 20- 3000 characters",
    "selectBrand": "Select Brand",
    "ShopeeCategory": "Shopee Category",
    "addAttributeValue": "Add attribute value",
    "saveUpdateAndNextStep": "Save and next step",
    "productNameLimitVn": "Product Name must be between 10- 120 characters",
    "productNameLimitPh": "Product Name must be between 20- 100 characters",
    "productNameLimitOthers": "Product Name must be between 20- 120 characters",
    "productNameLimitId": "Product Name must be between5-255 characters",
    "stocktLimitOhters": "Stock Should be between 0-999.999.999!",
    "stocktLimit": "Stock Should be between 0-999,999!",
    "setUnavailable": "Set Unavailable",
    "setAvailable": "Set Available",
    "saveAsDraft": "Save as draft",
    "percentageLimit": "The percentage range should be 0-100,000",
    "attributesNo": "No attributes",
    "categoryNo": "No category",
    "categoryAndAttributeApply": "Apply the same category and attribute options to other products of the same source category ({N})?",
    "detailsView": "view details",
    "pleaseSetUpLogisticsInThe": "Please set up logistics in the Shopee seller center.",
    "optionMaximum": "Max.20 characters",
    "typeMaximum": "Max.14 characters",
    "vietnam": "Vietnam",
    "thailand": "Thailand",
    "philippines": "Philippines",
    "indonesia": "Indonesia",
    "imageMustBeJOGPNGFormat": "Image must be JPG, PNG format, max. 4MB, Resolution: 300*300px～1024*1024px",
    "imageTip": "API limitation, the maximum size is 4MB, and the resolution must be more than 300*300px",
    "preOrderLimit": "According to category requirements, the time should be {min} to {max} working days",
    "preOrderTip": "Star, Star+, Mall support up to 90 working days",
    "malaysia": "Malaysia",
    "dangerousGoods": "Dangerous Goods",
    "dangerousGoodsTip": "Please fill in DG accurately. Inaccurate DG may result in additional shipping fee or failed delivery.",
    "dangerousGoodsOption": "Contains battery/magnet/liquid/flammable materials",
    "attributeTip": "API Limitation, maximum 5 options",
    "massEditTip1": "Please select the product category and product attributes to avoid the failure of the product to be published",
    "theMaximumRangeAccordingToApi": "Limit the maximum range according to API requirement",
    "requiresToThisInformationToBe": "API requires to this information to be filled in",
    "chooseTheStoreFirstPlease": "Please choose the Store first",
    "variantImageTip": "Size: 4MB, Resolution: Min.300*300px.(API requires to this information to be filled in)",
    "variationTypeTip2": "variant type already exists, please re-enter",
    "variationoptionTip1": "Variant Option already exists, please re-enter",
    "applyAttribute": "Apply the same attribute option to other products in the same source category({N})?",
    "noBrandBatch": "Are you sure to change the brand options of this list of products to \"No Brand\"?",
    "noBrandBatchTip": "Due to API restrictions, only batch selection of brands as unbranded is supported.",
    "massEditBrand": "Mass Edit Brand",
    "previous": "Previous",
    "emptyMass": "Mass Empty",
    "singapore": "Singapore",
    "canUploadMultiplePicturesAtOnce": "You can upload multiple pictures at once, drag and drop to change the picture order",
    "contentToEditClick": "Click content to edit",
    "Policydetail": "When the same product is published to multiple stores on the same channel\nWe suggest you to change and differentiate product name and product information to avoid violating related marketplace policies",
    "PolicyReminder": "Policy Reminder",
    "Clonesuccess": "Product Successfully Cloned!",
    "copyFailedResult": "Products failed to clone",
    "copyCompete": "Cloning completed",
    "amountMustBeGreaterThanDiscount": "Amount must be greater than discount amount",
    "wholeStoreProduct": "Whole store product",
    "valueMustBeGreaterThanThe": "Value must be greater than the previous gradient",
    "theSumOfTheAvailableStock": "The sum of the available stock in the warehouse where all MSKUs are located, according to",
    "ratioSyncToAllChannelSku": "Ratio sync to all channel SKU",
    "whenthedefaultruleisonmskusthatalreadyexistinotherrulesarenotincluded": "When the default rule is on, MSKUs that already exist in other rules are not included",
    "statusDeleted": "deleted",
    "statusInactive": "inactive",
    "statusActive": "active",
    "optionsDoNotSupportEditAnd": "Options do not support edit and delete due to API limitations",
    "dueToApiRestrictionsLabel": "Due to API restrictions, { label } in the variation type under this category is required.",
    "dueToTheChannelDelayIn": "Due to the channel delay in updating data, the edited data will take a few seconds to be updated, please be patient. If it has not been updated, you can manually click the sync button or go to the seller center to check whether it is in the review state. The data in the review state will not be synchronized for the time being.",
    "deletedProductCannotBeRecoveredAre": "Deleted product cannot be recovered, are you sure you want to delete it?",
    "canNotInputChineseLanguage": "Can not input Chinese language",
    "areYouSureYouWantToZalora": "Are you sure you want to delete this variant information? Deletion will change the SKU structure, price, and stock information, you need to enter the relevant information of the SKU again.",
    "failedToPublishGineeProductThat": "Failed to publish: Ginee product that failed to publish",
    "publishingGineeProductThatAreBeing": "Publishing: Ginee product that are being publish or product that under review in Seller Central",
    "draftAProductDraftCreatedBy": "Draft: A product draft created by Ginee",
    "suspendedThisIsAProductWith": "Suspended: This is a product with Suspended status in Seller Center",
    "soldOutThisIsAProduct": "Sold out: This is a product which has 0 stock with Live/Deactivated status in Seller Center",
    "deactivatedThisIsAProductWith": "Deactivated: This is a product with Deactivated status in Seller Center",
    "liveThisIsAProductWith": "Live: This is a product with Live status in Seller Center",
    "currencyCannotBeEmpty": "Currency cannot be empty",
    "failedToUploadQualification": "Failed to upload qualification {0}",
    "failedToUploadImage": "Failed to upload image {0}",
    "thereCannotBeTheSameOption": "There cannot be the same option in one specification group",
    "numberOfVariationsInEachGroup": "Number of variations in each group must be {0}-{1}",
    "numberOfGroupsVariationMustBe": "Number of groups variation must be {0}-{1}",
    "qualificationCertificateOfTheCurrentProduct": "Qualification certificate of the current product category is required and cannot be empty",
    "forOneTypeOfQualificationCertificate": "For one type of qualification certificate, the total number of files and images must not exceed {0}",
    "apiLimitationMaxSizeMb": "API limitation, max size. 5 MB, and the resolution is more than 600*600 pixels",
    "totalSkuMustBeBetween": "Total SKU must be between 1-100",
    "pleaseSelectTheTypeOfVariation": "Please select the type of variation",
    "pleaseInputWeight": "Please input weight",
    "pleaseInputLengthWidthAndHeight": "Please input length, width and height information",
    "reviewing": "Reviewing",
    "processing": "Processing",
    "reviewFailed": "Review Failed",
    "thisCategoryDoesNotSupportCod": "This category does not support COD",
    "skuMustBeCharacters": "SKU must be 1-50 characters",
    "warrantyPolicyMustBeCharacters": "Warranty policy must be 0-99 characters",
    "priceRangeShouldBe": "Price range should be 100-100000000",
    "stocktLimitOhtersZalora": "Stock Should be between 0-99999",
    "weightShouldBeBetweenKgZalora": "Weight should be between 0.01-20",
    "heightShouldBeBetweenZalora": "Height Should be between 1-35",
    "widthShouldBeBetweenZalora": "Width Should be between 1-40",
    "lengthShouldBeBetweenZalora": "Length Should be between 1-60",
    "pleaseUploadASizePicture": "Please upload a size picture",
    "onlySupportsImages": "Only supports 1:1 images",
    "pleaseEnterAtLeastOneVariation": "Please enter at least one variation option",
    "variationOptionMustBeCharacters": "Variation option must be 1-20 characters",
    "productDescriptionLimitIdMyZalora": "Product Description must be between 1-10.000 characters",
    "fileSizeCannotExceedM": "File size cannot exceed 10M",
    "onlySupportToUploadFilesIn": "Only support to upload files in pdf format",
    "onlySupportToUploadImagesWith": "Only support to upload images with jpg, png, jpeg formats",
    "imageMustBePxpx": "Image must be 600*600px-20000*20000px",
    "pictureSizeCannotExceedM": "Picture size cannot exceed 5M",
    "pleaseUploadAtLeastOneImage": "Please upload at least one image or pdf file",
    "supportCOD": "Support COD",
    "sizeChart": "Size Chart",
    "pleaseSelectTheVariantTypeAnd": "Please select the variant type and option first",
    "warrantyPolicy": "Warranty Policy",
    "warrantyPeriod": "Warranty Period",
    "qualificationCertificate": "Qualification Certificate",
    "productNameLimitIdZalora": "Product Name must be between1-188 characters",
    "zaloraCategory": "Zalora Category",
    "numberOfComments": "Number of comments",
    "keywordType": "Filter",
    "sortType": "Sort by",
    "number": "Number",
    "newRuleMasterProduct": "New rule {0}; master product {1}",
    "deleteRuleMasterProduct": "Delete rule {0}; Master product {1}",
    "storePolicyAddPolicyNew": "Store policy {0}; Add policy: add new policy",
    "storePolicyDeletePolicyPolicy": "Store policy {0}; delete policy: policy deleted",
    "ruleNameChangedFromTo": "Rule name: changed from {0} to {1}",
    "masterProductNameChangedFrom": "Master product name: changed from {0} to {1}",
    "pushTypeNameChangedFrom": "Push type name: changed from {0} to {1}",
    "storePolicyWarehouseNameChanged": "Store policy {0}; warehouse name: changed from {1} warehouse to {2} warehouse",
    "storePolicyInventoryTypeName": "Store policy {0}; Stock type name: changed from {1} to {2}",
    "storePolicyStoreNameChanged": "Store policy {0}; Store name: changed from {1} to {2}",
    "storeStrategyPushRatioChanged": "Store policy {0}; push ratio: changed from {1} to {2}",
    "storePolicyPushFixedValue": "Store policy {0}; push fixed value: changed from {1} to {2}",
    "addmskuthresholdIsselectmainstoreIs": "Add {0} MSKU, threshold is {1}, select main store is: {2}",
    "mskuhasbeendeleted": "MSKU has been deleted",
    "masterproductchangedfromto": "Master product changed from {0} to {1}",
    "mainstorechangedfromto": "Main store changed from {0} to {1}",
    "thresholdchangedfromto": "Threshold changed from {0} to {1}",
    "masterProductActivity": "Master Product Activity",
    "operationType": "Operation Type",
    "salesTypeId": "Sales Type",
    "salesRangeId": "Sales Range",
    "modifyChannelProductPrices": "Edit channel product price",
    "areyousureyouwanttodeletetheruleafterdeletedyouneedtoreconfigurethesynchronizationrules": "Are you sure you want to delete the rule? After deleted, you need to reconfigure the synchronization rules",
    "orderPushRecord": "Order Push Record",
    "productPushRecord": "Product Push Record",
    "youHaveNotActivatedStockMonitoring": "You have not activated \"Stock Monitoring and Sync\" yet",
    "turnOnNow": "Enable",
    "turnOn": "Active",
    "theSalesRangeOfCannot": "The sales range of {} cannot be higher than {}",
    "theValueOnTheRightSide": "The value on the right side of the input box cannot be less than the left side",
    "pushRecord": "Push Record",
    "allWarehouse": "All Warehouse",
    "allStoresExceptCustomStores": "All stores except custom stores: {x}%",
    "pushFailed": "Push Failed",
    "pushed": "Pushed",
    "bindwarehouse": "Bind Warehouse",
    "afterTurnOnTheProductsThat": "After turn on, the products that meet this rule will be pushed to the store backend",
    "afterDeactivationProductsThatMeetThis": "After turn off, products that meet this rule will no longer be pushed to the store backend",
    "afterTheSettingIsCompleteAverage": "After the setting is complete, average daily sales will be calculated on the next day according to the new setting",
    "productDescriptionMustBeBetweenMinmaxcharacters": "Product Description must be between {min}-{max}characters",
    "repush": "Re-Push",
    "pullAccurateStock": "Pull Accurate Stock",
    "accounting1": "Accounting",
    "returnManagement": "Return Management",
    "returnOrderXxxRejectedToRefund": "Return order xxx rejected to refund the buyer",
    "returnOrderXxxAgreedToRefund": "Return order xxx agreed to refund the buyer",
    "returnOrderXxxRejectedRefundreturnRequest": "Return order xxx rejected refund/return request",
    "returnOrderXxxAcceptedRefundreturnRequest": "Return order xxx accepted refund/return request",
    "returnOrderXxxSuccessfullySyncedThe": "Return order xxx successfully synced, the return type is \"xxx\", the processing status is \"xxx\"",
    "rejectRefund": "Reject Refund",
    "acceptRefund": "Accept Refund",
    "acceptRequest": "Accept Request",
    "syncReturnOrder": "Sync Return Order",
    "confirmInbound": "Confirm Inbound",
    "mass": "Mass",
    "returnWarehouse1": "Return Warehouse",
    "returnProduct": "Return Product",
    "doYouAgreeToRefund": "Do you agree to refund?",
    "pleaseSelectRejectionReason": "Please select rejection reason",
    "doYouAcceptBuyersReturnRefund": "Do you accept buyer's return refund request?",
    "onceYouAgreeItCannotBe": "Once you agree, it cannot be revoked",
    "doYouAcceptBuyersRefundRequest": "Do you accept buyer's refund request?",
    "exportAll": "Export All",
    "viewReturnRecords": "View Return Log",
    "accept": "Accept",
    "isItADisputedOrder": "Is it a disputed order?",
    "inboundNo": "Inbound No.",
    "returnWarehouse": "Return Warehouse",
    "returnAwb": "Return AWB",
    "returnStatus": "Return Status",
    "processingDeadline": "Processing Deadline",
    "processingStatus": "Processing Status",
    "closed": "Closed",
    "finished": "Complete",
    "inDispute": "In Dispute",
    "returnRemark": "Return Remark",
    "variantchannelSkumsku": "Variant/Channel SKU/MSKU",
    "returnNo": "Return No.",
    "disputedOrder": "Disputed Order",
    "cod": "COD",
    "separateMultipleInfoWithSpace": "Separate multiple info with space",
    "returnRefund": "Return & Refund",
    "returnOrder": "Return Order",
    "xRuleHasBeenDeletedPlease": "{x} rule has been deleted, please check the record in the operation log",
    "checknow": "Check Now",
    "pushTime": "Push Time",
    "theActivityLevelOfTheChannel": "The activity level of the channel product has been set and cannot be set repeatedly.",
    "deleteMasterProduct": "Delete Master Product",
    "pleaseEnterRemarks": "Please enter remarks",
    "refundType": "Refund Type",
    "refundOnly": "Refund Only",
    "refundStatus": "Refund Status",
    "returnInformation": "Return Information",
    "refundReason": "Refund Reason",
    "returned": "Returned",
    "returning": "Returning",
    "waitingBuyerToReturn": "Waiting Buyer to Return",
    "returnRecord": "Return Log",
    "refundList": "Refund List",
    "mskuBindingStatus": "MSKU Binding Status",
    "pleaseConfirmTheReturnedProductBefore": "Please confirm the returned product before inbound it to the warehouse. If there is an error, please synchronize the order before returning it to the warehouse.",
    "combinedProductSales": "Sub-Product Sales",
    "includingCombineProductSalesAndIndividual": "Including combine product sales and individual sales from sub-product of combine product",
    "countTheSalesOfCombinedProducts": "Count the sales of combined products sold individually and in bundles",
    "pleaseSelectTheDataToBe": "Please select data to export",
    "salesOfIndividuallySoldItems": "Product Sales of Single Product",
    "salesOfProductsSoldByThe": "Product Sales of Combined Product",
    "combinedProductMsku": "Combined Product MSKU",
    "combinationProductMsku": "Combine Product MSKU",
    "combinedProductName": "Combined Product Name",
    "mainCommoditySalesAnalysis": "Master Product Sales Analysis",
    "productDetailsOfThePortfolio": "Combined Product Details",
    "combinationProductDetails": "Combine Product Details",
    "combinedProduct": "Combined Product",
    "exportChoice": "Export Selected",
    "combinationProducts": "Combined Product",
    "refundRate": "Refund rate",
    "refundAmount": "Refund Amount",
    "refundQuantity": "Return Quantity",
    "refundOrderQuantity": "Returned Order Quantity",
    "numberOfFailedOrders": "Number of failed orders",
    "numberOfReturnedOrder": "Number of returned order",
    "numberOfCanceledOrder": "Number of canceled order",
    "numberOfShippedOrders": "Number of shipped orders",
    "numberOfOrdersInDelivery": "Number of orders in delivery",
    "numberOfReadyToShipOrder": "Number of ready to ship order",
    "numberOfPaidOrder": "Number of paid order",
    "numberOfPendingPaymentOrder": "Number of pending payment order",
    "effectiveSalesAmount": "Effective Sales Amount",
    "totalSales": "Total Sales",
    "totalProductSales": "Total Product Sales",
    "relatedOrderQuantity": "Related Order Quantity",
    "msku": "MSKU",
    "creationTime": "Create Time",
    "keywords": "Keywords",
    "isACombinationProduct": "Is a combination product",
    "salesQTY": "Sales",
    "failedGenerateInboundList": "Failed generate inbound list",
    "someMasterProductDoNotExist": "Some Master Product not exist in the selected warehouse",
    "masterProductIsEmptyPleaseRebind": "Master Product is empty, please re-bind Master Product and try again",
    "refundInformationNotCompletePleaseRefresh": "Refund information not complete, please refresh the page",
    "informapping": "Information Mapping",
    "mappingtip": "Select Categories and Variation Type or you will not get variant informations (such as: stock, price, etc)",
    "Mappingcategory": "Mapping Category",
    "tiktokCategoryAttributes": "Tiktok category attributes",
    "enterBetween": "Enter between 1-60",
    "salesInformation": "Sales Information",
    "tikTokCategory": "TikTok Category",
    "tiktokfailedToPublishGineeProductThat": "Failed to publish: Ginee product that failed to publish",
    "tiktokpublishingGineeProductThatAreBeing": "Publishing: Ginee product that are being publish or product that under review in Seller Central",
    "tiktokdraftAProductDraftCreatedBy": "Draft: A product draft created by Ginee",
    "tiktoksuspendedThisIsAProductWith": "Suspended: This is a product with Suspended status in Seller Center",
    "tiktoksoldOutThisIsAProduct": "Sold out: This is a product which has 0 stock with Live/Deactivated status in Seller Center",
    "tiktokdeactivatedThisIsAProductWith": "Deactivated: This is a product with Deactivated status in Seller Center",
    "tiktokliveThisIsAProductWith": "Live: This is a product with Live status in Seller Center",
    "tiktokskuMustBeCharacters": "SKU must be 1-50 characters",
    "tiktokwarrantyPolicyMustBeCharacters": "Warranty policy must be 0-99 characters",
    "tiktokpriceRangeShouldBe": "Price range should be 100-100000000",
    "tiktokstocktLimitOhters": "Stock Should be between 0-99999",
    "tiktokweightShouldBeBetweenKg": "Weight should be between 0.01-100",
    "tiktokheightShouldBeBetween": "Height Should be between 1-35",
    "tiktokwidthShouldBeBetween": "Width Should be between 1-40",
    "tiktoklengthShouldBeBetween": "Length Should be between 1-60",
    "tiktokproductDescriptionLimitIdMy": "Product Description must be between 1-10.000 characters",
    "productNameMinMaxCharacters": "Product Name must be between {min}-{max} characters",
    "mpType": "MSKU Binding Status",
    "updateDates": "Update Time",
    "returnOrderXHasSuccessfullyCreated": "Return order {x} has successfully created a return inbound list, and the inbound list number is {x}",
    "createInboundList": "Create Inbound List",
    "noteThereIsADelayIn": "Note: There is a delay in the update of the channel order status and shipping label, which may cause the push failed. Once the push fails, please try to push again in the failed list.",
    "theOrderIsBeingPushedTo": "The order is being pushed to the third-party warehouse. Please do not repeat the operation and please go to order list to check the push progress",
    "pushTaskSuccessfullyCreated": "Push task successfully created",
    "refundLog": "Refund Log",
    "refundNo": "Refund No.",
    "refundOrder": "Refund Order",
    "theServiceIsBusyOrYou": "The service is busy, or you may have reached the request rate limit, please do the push manually",
    "cancellationIsNotAllowedWhilePush": "Cancellation is not allowed while push process is in progress",
    "synchronizeAllMskuInWarehouseTo": "Synchronize all MSKU in warehouse to all channel SKU according to the ratio of {x} and {y}",
    "youDoNotHavePermissionAt": "You do not have permission at the moment, please contact the administrator to configure permissions in [Role Srettings]",
    "tikiproductsCreatedInGineeExcludingProducts": "Draft products created in Ginee, excluding products in the Seller Center - Draft status",
    "tikiproductsFailedPublishByGinee": "Products failed publish by Ginee",
    "tikiproductsPublishingByGinee": "Products publishing by Ginee",
    "tikiproductLockedInSellerCenterApi": "Tiki currently has no Banned products",
    "tikiamongActiveAndInactiveProductsAll": "Among live and disabled products, all product variants whose total inventory = 0;\nDue to API interface issues, FBT products will temporarily be placed on sold out list",
    "tikinonactiveProductsInSellerCenter": "Products of OFF and locked status in Seller Center",
    "tikiactiveProductInSellerCenter": "Products of ON status on seller center",
    "skuProductCode": "SKU (Product Code)",
    "lowstockthresholdValueCannotHigherThan": "lowStockThreshold value cannot higher than stock quantity",
    "descriptionUpToCharacters": "Description up to {max} characters",
    "skuPromotionPriceCannotHigherThan": "SKU promotion price cannot higher than original price, please edit",
    "skuPromotionStartTimeCannotBe": "SKU promotion start time cannot be empty",
    "skuPromotionEndTimeCannotBe": "SKU promotion end time cannot be empty",
    "skuLowstockthresholdValueCannotHigherThan": "SKU lowStockThreshold value cannot higher than stock quantity",
    "variantSkuCannotBeEmpty": "Variant SKU cannot be empty",
    "productSkuCannotBeEmpty": "Product SKU cannot be empty",
    "thisSkuAlreadyExistsInThis": "This sku:${0} already exists in this store, please edit again",
    "productMainImageCannotBeEmpty": "Product main image cannot be empty",
    "promotionPriceCannotBeHigherThan": "Promotion price cannot be higher than the original price",
    "theInputRangeFromMin": "The input range from { min } - { max}",
    "addVariants": "Add Variants",
    "shippingClass": "Shipping class",
    "crosssells": "Cross-sells",
    "upsells": "Upsells",
    "enableThisToOnlyAllowOne": "Enable this to only allow one of this item to be bought in a single order",
    "soldIndividually": "Sold individually",
    "lowStockThreshold": "Low stock threshold",
    "allowBackorders": "Allow backorders",
    "enableStockManagementAtProductLevel": "Enable stock management at product level",
    "manageStock": "Manage stock",
    "taxClass": "Tax class",
    "taxStatus": "Tax status",
    "usedForVariations": "Used for variations",
    "visibleOnTheProductPage": "Visible on the product page",
    "thisIsAFeaturedProduct": "This is a featured product",
    "showSharingButtons": "Show sharing buttons",
    "showLikes": "Show likes",
    "likeAndShares": "Like and Shares",
    "hidden": "Hidden",
    "searchResultsOnly": "Search results only",
    "shopOnly": "Shop only",
    "shopAndSearchResults": "Shop and search results",
    "catalogVisibility": "Catalog visibility",
    "heightMinMax": "Height Should be between{min}-{max}",
    "widthMinMax": "Width Should be between{min}-{max}",
    "lengthMinMax": "Length Should be between{min}-{max}",
    "weightMinMax": "Weight should be between {min}-{max}",
    "pleaseEnterProductDescription": "Please enter description",
    "productNameMinMax": "Product Name must be between {min}-{max} characters",
    "variantPicture": "Variant Picture",
    "productImageMax": "Product image max. {number}",
    "promotionTime": "Promotion Time",
    "promotionPrice": "Promotion Price",
    "options": "Options",
    "variantType": "Variant Type",
    "productAttributes": "Product Attributes",
    "productShortDescription": "Product Short Description",
    "productTag": "Product Tag",
    "updatedateasc": "Update Time Oldest To Newest",
    "updatedatedesc": "Update Time Newest To Oldest",
    "createdateasc": "Create Time Newest To Oldest",
    "createdatedesc": "Create Time Oldest To Newest",
    "withoutmp": "Unbound Master SKU",
    "withmp": "Bind Master SKU",
    "generateInventorySKU": "Generate inventory SKU",
    "onBackorder": "On backorder",
    "productNameAndStore": "Product Name & Store",
    "sync": "Sync",
    "inStock": "In stock",
    "soldOut": "Sold Out",
    "disabled": "Disabled",
    "china": "China",
    "wooPublishStatus": "In the store, the Pending products are displayed in the IN Process tab",
    "wooActiveStatus": "In the store, the published products are displayed in the live tab",
    "wooDeactiveStatus": "In the store, private goods will be displayed in the tab that Ginee has taken off the shelf.",
    "wooDraftStatus": "In the shop, items in draft will be displayed in the Ginee draft tab.",
    "inProcess": "In Process",
    "stockStatus": "Stock Status",
    "externalProduct": "External product",
    "groupProduct": "Group product",
    "variable": "Variable",
    "simpleVariation": "Simple",
    "editStatusError": "Please click the Confirm button first, and then click Publish To Store",
    "productInfoSetting": "Product Information Settings",
    "choiceMskuTip": "After selecting Master SKU, the system will adding Master SKU’s price and stock information, and bind Blibli SKU information to Master SKU.",
    "categoryMatch": "Map Category",
    "variationLimit": "The selected category does not support adding variation",
    "clickToEdit": "Click on the information in the table to edit.",
    "whatsInTheBox": "What's in the box",
    "MinPurchaseQuantity": "Min. purchase quantity",
    "ProductDescriptionShouldBeCharacters": "Long Description should be 6-25000 characters",
    "chooseStoreLimit": "Choose at least 1 destination store to copy Product",
    "replaceWith38": "Replace with",
    "addBetweenTheDescription": "Add between the description",
    "addAtTheEndOfThe": "Add at the end of the description",
    "addAtTheStartOfThe": "Add at the start of the description",
    "addBetweenName": "Add between name",
    "editProductName": "Edit Product Name",
    "theFileWillOnlyBeReserved": "The file will only be reserved for 7 days, please check or download in time",
    "theResultOfThePublishWill": "The result of the publish will be synchronized in [Master Products - Mass Operation History]",
    "successfulQtyAllQty": "Successful Qty / All Qty",
    "publishing": "Publishing",
    "taskStatus": "Task status",
    "publishedStoreStoreStore": "Published Store: Store 1, store 2",
    "content": "Content",
    "creator": "Creator",
    "taskType": "Task type",
    "allPublishFailed": "All publish failed",
    "somePublishFailed": "Some publish failed",
    "allPublished": "All published",
    "productsWereSuccessfullyPublished": "29 Products were successfully published",
    "publishingToChannelStores": "Publishing to channel stores",
    "massEditOfShopeesCategoryAttribute": "Mass Edit of Shopee's category attribute",
    "matchTokopediaVariantType": "Map Tokopedia Variant type",
    "matchLazadaVariantType": "Map Lazada Variant type",
    "masterProductVariantType": "Master Product Variant type",
    "massEditProductDescription": "Mass Edit Product Description",
    "shopeeCategoryAttribute": "Shopee category attribute",
    "productNameInThisStoreIs": "Product Name in this Store is already used",
    "partOfTheMasterProductIs": "Part of the Master Product is empty, please make up to the store",
    "chooseACountry": "Choose a Country/Region",
    "max11": "Max. 1000",
    "massEditWeightG": "Mass Edit weight (g)",
    "max7": "Max. 500,000",
    "empty": "Empty",
    "editLogisticsMass": "Mass edit logistics",
    "editBliblidraftmass": "Blibli/ Mass Edit",
    "categoryAttributesBlibli": "blibli category attributes",
    "copyMass": "Mass Copy",
    "categoryChoose": "Choose category",
    "matchCategoryBlibli": "Blibli Category Mapping",
    "storeWarehouse": "Store / Warehouse",
    "buyable": "Buyable",
    "MinimumStock": "Minimum Stock",
    "originalPriceLimit2": "Original Price should be higher than Selling Price",
    "sellingPriceLimit2": "Selling Price can't be higher than Original Price",
    "originalPriceLimit": "Original Price Should be between 1-100.000.000",
    "enterYoutubeLinkForExampleYouTubecomipadview": "Enter Youtube link, for example: YouTube.com/ipadview",
    "youtubeVideoLink": "Youtube Video Link",
    "exampleTheBatteryLifeIsUp": "Example: the battery life is up to 10 hours",
    "sellingPoint": "Selling Point",
    "continueToAddProducts": "Continue to add products",
    "jdChannelIsUnderReviewCheck": "blibli channel is under review. Check the products in the In Process, Update Failed, or Live list",
    "failedReasons": "Failed reason(s)",
    "productsUpdateSucceeded": "Products Update succeeded",
    "changeSKUTo": "Change SKU to",
    "massEditSellingPrice": "Mass Edit Selling Price",
    "massEditOriginalPrice": "Mass Edit Original Price",
    "massEditSku": "Mass Edit SKU",
    "editVariantImage": "Edit Variant Image:",
    "multipleImagesCanBeUploadedAt": "Multiple images can be uploaded at once . At least 600 x 600pix,up to 4MB（up to 8 photos)",
    "shouldBeBetween": "Should be between 0-1.000.000.000",
    "optionsnLimit": "Options should be less than 20",
    "bigItem": "BIg item",
    "smallItem": "Small item",
    "qualitydays": "QualityDays",
    "afterSale": "After Sale",
    "Min1": "Min.1",
    "minpurchasePerOrder": "Minimum Purchase Quantity",
    "pleaseEnterProductName": "Please Enter Product Name",
    "blibliCategory": "BliBli Category",
    "partOfTheProductIs": "As requirements of the channel store to publish products, some fields  must be filled. Please fill the required fields before publish it to the store.",
    "blibliShouldBeBetweenA": "Should be between 0.001-999.999",
    "blibliWeightLimit": "Should be between 1-999,999!",
    "blibliStocktLimit": "Stock Should be between 0-999,999!",
    "blibliImageSizeLimit": "Image size must be at least 600 x 600 pixels，up to 4MB",
    "serviceErrorPleaseContactGineeTechnology": "Service error, please contact Ginee Technology",
    "minPurchaseLimit": "Min. purchase quantity should be 1-1000,000,000",
    "enterThenChange": "Enter the value first then change the unit afterwards",
    "goToTheBlibliSellerCenter": "Please go to the Blibli Seller Center to complete the warehouse address before selecting the logistics",
    "regularStoreSupport": "Support Regular Store",
    "toApiRestrictionsSomeVariantOption": "Due to API restrictions, some variant option cannot be cloned. Please add them if necessary.",
    "theCategoryAndVariantTypeApply": "Does the category and variant type apply to all products of the same Source category  (Source category： xxx/xxxx/xxxx of the same site)?",
    "toApiRestrictionsSomeFieldsDo": "Due to API restrictions, Some fields do not support editing",
    "shippingMethod": "Shipping Methods",
    "backToProductList": "Back To Product List",
    "chooseTheShippingMethodFirstPlease": "Please choose the shipping method first",
    "needToChooseNo": "No Need to Choose",
    "fAq1": "Blibli: How to add or edit products?",
    "fAq2": "Blibli: How to clone products?",
    "fAq3": "Blibli: how to edit products in bulk",
    "synchronizationForThisProductHasBeen": "Stock synchronization for this product has been enabled, please modify the stock in Inventory Management",
    "productDeleting": "Deleting Product",
    "operationWillNotDeleteTheProduct": "This operation will not delete the product in the seller center",
    "deleteTip": "Due to API restrictions, only products in draft, in process, and publish failed status can be deleted.",
    "productOrVariationImage": "Products/ Variation Image",
    "imageTip2": "Products without variants, the pictures refer to the pictures of the products, and products with variants refer to the pictures of the variant",
    "blibliSkuLimit": "SKU should be less than 50 characters",
    "exampleTheAvailableInventoryOfWarehouse": "Example: The available inventory of warehouse A is 100, the available inventory of warehouse B is 200, and the push warehouse is set to A+B, and the push ratio is 50%, then the stock pushed to the target store C is 150",
    "exampleThePushFixedValueIs": "Example: The push fixed value is 100, the push target store is A, and when the push is executed, the stock pushed to store A is 100",
    "ProductStatus": "Product Status",
    "productsThatFailToBePushed": "Products that fail to be pushed are not allowed to be pushed again, please contact ECCANG WMS staff to review the products first",
    "returnTime": "Return Time",
    "theProductHasNotPassedThe": "The product has not passed the review",
    "shippingFeePaidBySeller": "Shipping Fee Paid By Seller",
    "skucanonlybenumberslettersandhyphenandthemaximumcharacters": "SKU can only be numbers, letters, and hyphen, and the maximum 30 characters.",
    "skuNameMaxCharactersOnly": "SKU name max. 50 characters, only supports Chinese and English, numbers, hyphen, and underscore",
    "productThatFailToPassThe": "Product that fail to pass the review of ECCANG WMS will fail to be sent",
    "skuCanOnlyBeInUppercase": "SKU can only be in uppercase letters",
    "afterFailedToSendTheInbound": "After failed to send the inbound list, it not support to re-push",
    "productEffectiveSales": "Product Effective Sales",
    "pleaseNote": "Please Note:",
    "dueToTheLimitationOfThe": "Due to the limitation of the ECCANG WMS API, the SKU maximum 30 character and can only be numbers, letters, and dash.",
    "someOrdersAreNotAssignedWith": "Some orders are not assigned with sub-logistics, please re-arrange shipment after synchronization",
    "ordersWithoutSublogisticsAreNotSupported": "Orders without sub-logistics are not supported for delivery, please synchronize the order to obtain sub-logistics",
    "waitingForDistributionLogisticsPleaseSynchronize": "Waiting for distribution logistics by channel, please synchronize",
    "productInformationSettingError": "Product information setting error",
    "userAddressInformationError": "User address information error",
    "notInShippingAddress": "The address is not within the delivery range",
    "deliveryFailure": "Delivery failure (delivery failure due to the user not answering the phone or the address mismatch)",
    "logisticsCannotSupportDistribution": "Logistics cannot support distribution (for example flood in rainy season)",
    "theMerchantSentTheWrongProduct": "Merchant sent the wrong product",
    "productDamage": "Product damage (not yet delivered but already damaged)",
    "productDescriptionDoesNotMatch": "Product description does not match",
    "businessAndUserPhoneToCancelOrder": "Merchant negotiates with the user to cancel the order",
    "goodsDamageDuringLogisticsDelivery": "Product damage/lost during logistics delivery",
    "theUserAskedToCancelTheOrder": "The user asked to cancel the order",
    "logisticsAndDistributionCannotContactTheRecipient": "Logistics and distribution cannot contact the recipient",
    "incompleteRecipientAddress": "Incomplete recipient address",
    "thirdPartyMerchantsHaveNoInventory": "Third party merchants have no inventory",
    "outOfDeliveryRange": "Out of delivery range",
    "itIsOutOfStockAndCannotBeTraded": "It is out of stock and cannot be traded",
    "theBuyerSInformationIsFilledInWrong": "The buyers information is filled in wrong",
    "latePayment": "Late payment",
    "maliciousBuyers": "Malicious Buyers / peers",
    "theBuyerHasNoSincerityToCompleteTheTransaction": "The buyer has no sincerity to complete the transaction",
    "theBuyerMadeAMistakeOrRemake": "The buyer made a mistake or remake",
    "unableToContactBuyer": "Unable to contact buyer",
    "otherReasons": "Other reasons",
    "unableToCompletePayment": "Unable to complete payment / unable to receive payment code",
    "thePaymentMethodNeedsToBeChanged": "The payment method needs to be changed",
    "commodityPriceReduction": "Product price reduction",
    "wrongAddressInformation": "Wrong address information",
    "wrongProductInformation": "Wrong product information",
    "iDonTWantItAnymore": "I don't want it anymore",
    "goodsOutOfStock": "Product out of stock",
    "synchronizeAllMskusUnderXTo": "Synchronize all MSKUs under {x} to {w} by {y} and according to {z}",
    "pushInProcess": "Push in process",
    "waitingToPush": "Waiting to push",
    "rdWarehousePushStatus": "3rd Warehouse Push Status",
    "pushStatus": "Push Status",
    "printable": "Printable",
    "productInboundStockCannotBeNegative": "Product inbound stock cannot be negative",
    "andAccordingToZ": "and according to the ratio of {z} will synchronize to {w}",
    "synchronizeallmskuunder": "All MSKUs under {x} using {y}",
    "pushSucess": "Push Success",
    "stockPushRuleaddRule": "Stock push rule/add rule",
    "peraturanTolakStokubahSuai": "Stock push rule/edit",
    "peraturanTolakStoklihatPerincian": "Stock push rule/view detail",
    "negativeInventoryCannotBeManipulatedInto": "Negative inventory cannot be manipulated into stock",
    "channelCategoryAttributes": "{channelName} category attributes",
    "switchingMskuRequiresReselectingWarehouseInformation": "Switching MSKU requires re-selecting warehouse information",
    "pleaseAuthorizeTheStoreFirst": "Please authorize the store first",
    "thereIsNoAlternativeStorePlease": "There is no alternative store, please authorize the store first",
    "stockTikiTips": "Tiki stores that do not support modifying product information and FBT products will not be synchronized.",
    "stocksettingtip2Local": "After Stock synchronization is turned on, the stock of all products bound to the same Master SKU will be adjusted to be consistent, and when the Master SKU generates a new order or the order is cancelled, the stock of all products bound to the Master SKU will be uniformly deducted or added. Please make sure that the synchronization of the product and the order has been completed before opening, and the stock quantity of the Master SKU has been adjusted. \nAfter the stock synchronization is turned on, the store SKU stocks will be pushed according to the stock ratio and master SKU stock in the inventory management (store shipping warehouse settings). If stock has not been set up in the inventory management, the store SKU stock will be pushed to 0 and cannot be restored. Please confirm whether it is turned on.",
    "afterTheStockSynchronizationIsTurned": "After the stock synchronization is turned on, the store SKU stocks will be pushed according to the stock ratio and master SKU stock in the inventory management (store shipping warehouse settings). If stock has not been set up in the inventory management, the store SKU stock will be pushed to 0 and cannot be restored. Please confirm whether it is turned on.",
    "doesntSupportCustomShippingLabelTemplate": "Currently doesn't support custom Shipping Label template fields for Philippines, only supports the size setting of A4/A6",
    "commissionTips": "Please enter a valid number between 0-100",
    "invoiceNumberTips": "All items in one Order use the same Invoice Number",
    "SettingTips2": "please click here",
    "SettingTips": "This setting is only valid when shipping channel orders are processed using Ginee(Lazada only supports settings in the Seller Center). To edit the store's default / pickup address,",
    "SettingLogo": "Click here to set Brand Logo",
    "SellerNotes": "Seller Notes",
    "PickingNotes": "Picking Notes",
    "TotalProduct": "Total Product",
    "PrintMan": "Printed By",
    "DeliverySettingPickupAddress": "Please go to Seller Center to set address",
    "DeliverySettingStoreName": "Store Name",
    "PickFormReset": "Reset",
    "ChangePickStatusConfirm": "Confirm",
    "PickDeleteSuccess": "Delected successfully!!",
    "PickDeleteTipTwo": "Once deleted can't be restored.",
    "PickDeleteTip": "Are you sure to delete the Status Information?",
    "PickOverCharacters": "Can't exceed 20 characters",
    "AddPickStatus": "Add Rule",
    "PickUpdateTime": "Update Time",
    "PickCreateTime": "Create Time",
    "PickStatusOperatorName": "Creator",
    "PickStatusName": "Status Name",
    "PrintUnitPrice": "Unit Price",
    "PrintVariationName": "Variations",
    "PrintShipping": "Logistic",
    "PrintSellerInfo": "Seller",
    "PrintBuyerInfo": "Buyer",
    "BuyerNotes": "Buyer Notes",
    "ShippingFee": "Shipping Fee",
    "SenderInfo": "Sender",
    "GoToSellerCenter": "Please go to Seller Center to set address",
    "AutoShippingTips": "Ginee supports setting up automatic shipping methods for stores, set it now",
    "ShippingAddRule": "Add Rule",
    "OperationFailed": "Operation Failed, Please try again later",
    "OperationSuccess": "Operation Success",
    "ApplyToAllStores": "Apply to All Stores",
    "PickupAddress": "Pickup Address",
    "ChooseAStore": "Choose a Store",
    "ShippingSettingTips": "Tips: Shopee only gave SPX's Pickup method access to selected seller",
    "ShopName": "Shop Name",
    "ShippingMethod": "Shipping Methods",
    "Courier": "Courier",
    "ShippingMethodDescription": "According to your settings, Ginee will automatically select the shipping method for you after the order is synchronized to improve efficiency.",
    "laStoreName": "Store Name",
    "proalertclosed": "Inventory Warning is turned off",
    "proalertopend": "Inventory Warning has been turned on. When the available stock of the Inventory SKU is lower than the Safety Stock, an email reminder will be sent",
    "PleaseSelectLeast": "Select at least one",
    "DeleteTplTitle": "Are you sure to delete the Template?",
    "DeleteTplDesc": "Template cannot be recovered once deleted",
    "LaRuleBindingStoreSearchPlaceholder": "Please enter the Store name",
    "LaRuleBindingStoreModalTitle": "Please Select Invoice Rules",
    "methodSettingDoubleCheckDescription": "After the rule is deleted, the shipping rule of the corresponding store will become invalid",
    "LaRuleDoubleCheckDescription": "After the rule is deleted, the invoice generation rules of the corresponding store will become invalid.",
    "LaRuleDoubleCheck": "Are you sure to delete the rule?",
    "ExportField": "Export Content",
    "SelectChannel": "Select Channel",
    "OrderExportingDragging": "You can change the order while exporting by dragging up and down.",
    "BasicInfo": "Basic Info",
    "EditTemplate": "Edit Template",
    "UpdateTime": "Update Time",
    "CreateTime": "Create Time",
    "TemplateName": "Template Name",
    "Creator": "Creator",
    "LAInvoiceSettingSave": "Confirm",
    "ApplyToAllLazadaStores": "Apply to All Lazada Stores",
    "NextNumber": "Next Number",
    "EnterNumberManually": "Enter Number Manually",
    "UseCustomNumber": "Use Custom Number",
    "UseOrderNumber": "Use Order Number",
    "StoreManagement": "Store Management",
    "RuleApplicationStoreNumber": "Applicable Number of Stores",
    "RuleCustomNumber": "Custom Number",
    "RulePrefix": "Prefix",
    "RuleType": "Rule Type",
    "RuleName": "Rule Name",
    "AddRule": "Add Rule",
    "commissionPercent": "Commission Rate (%)",
    "InvoiceRules": "Invoice Rules",
    "ShippingSettingsTab": "Shipping Settings",
    "TemplateShippingAddress": "Shipping Address",
    "TemplateLogistics": "Logistics",
    "TemplateTotal": "Total",
    "TemplateSellerDiscountTotal": "Seller Discount",
    "TemplateTax": "Tax",
    "TemplateShipping": "Shipping",
    "TemplateSubtotal": "Subtotal",
    "TemplateQty": "Qty",
    "TemplateCreatedAt": "Create Time",
    "TemplatePayment": "Payment",
    "TemplateContactNo": "Contact No.",
    "TemplateUnitPrice": "Unit Price",
    "TemplateBuyer": "Buyer",
    "TemplateAWBTrackingNumber": "AWB/Tracking Number",
    "Quantity": "Qty",
    "Specification": "Variations",
    "ProductName": "Product Name",
    "TemplateProductItem": "Item",
    "TemplateInsurance": "Insurance",
    "TemplateWeight": "Weight",
    "TemplateShippingType": "Logistic",
    "OrderId": "Order ID",
    "TemplateSender": "Sender",
    "TemplateRecipient": "Recipient",
    "TemplateInformation": "Information",
    "LogisticsLOGO": "Logistics LOGO",
    "ChannelLogo": "Channel LOGO",
    "TemplateIcon": "Icon",
    "TemplatePrintDate": "Print date",
    "TemplateDate": "Date",
    "ViewBoxItemTitle": "Print View",
    "TemplateSettingsTitle": "Template Settings",
    "SetPickUpAddress": "Set Pickup Address",
    "SetDefaultAddress": "Set Default Address",
    "SenderAddress": "Address",
    "SenderPhone": "Phone",
    "SenderName": "Name",
    "DefaultAddress": "Default Address",
    "BlSenderInfo": "i18n: BlSenderInfo",
    "TokoSenderInfo": "Tips: The set name/phone will be used to display the information in Ginee's own courier list.",
    "ShopeeSenderInfo": "Tips: Name/phone of the default address will be used for delivery, and after the Default/Pickup Address is set, the address information will be synchronized to Shopee.",
    "PickingListPrintTemplateSettings": "Picking List Template Settings",
    "ShippingLabelTemplateAlertTips": "Only valid for Ginee's own templates, not applicable to third-party e-commerce platform logistics forms",
    "PrintTemplateSettings": "Print Template Settings",
    "ShippingLabelAlertTips": "If you want to use Ginee's Shipping Label Template or to edit the store's default / pickup address, Please complete the Information Settings",
    "ShippingLabelInformationSettings": "Shipping Label Information Settings",
    "htar": "How to add a role?",
    "htas": "How to add a staff?",
    "htmr": "How to manage permissions?",
    "htms": "How to manage staff?",
    "Mustbestartedwith8": "Must be started with 8",
    "atleastonerole": "Please select at least one permission",
    "noroles": "No Data, Please add role",
    "deleteroletip": "After deleted, staff can no longer select the role",
    "suretodeleterole": "Are you sure to delete this role?",
    "EditRole": "Edit Role",
    "Roleadded": "Role added",
    "Permissionlimit": "Please select at least one permission",
    "Permission": "Permission",
    "roledesclimit": "Please enter a description of less than 600 characters",
    "roleNamelimit": "Name should be 1-150 characters",
    "EnterRoleName": "Enter Role Name",
    "DeleteFailed": "Delete Failed",
    "staffDeleted": "Staff Deleted",
    "RoleDeleted": "Role Deleted",
    "AddRole": "Add Role",
    "Numberofstaff": "Number of staff",
    "RoleDescription": "Role Description",
    "RoleName": "Role Name",
    "Submit": "Submit",
    "Staffhasbeenadded": "Staff has been added",
    "Nextstep": "Next step",
    "DiscardChange": "Discard Change",
    "suretodiscard": "Are sure you want to discard this change?",
    "AddNewRole": "Add New Role",
    "Existingpermissions": "Existing permissions",
    "EnterthePassword": "Staff use this password to log in to Ginee",
    "passwordlimit": "Password contains at least 8 characters, numbers, and symbols",
    "Staffsusethemailbox": "Staffs use the mailbox as an account to log in to the Ginee background",
    "Gineeaccountcannotbeempty": "Ginee account cannot be empty",
    "Pleaseinputemail": "Please input the email address",
    "Contactphonenumber": "Contact phone number",
    "namelimit": "Staff name should be 1-30 characters",
    "EnterStaffName": "NameofStaff",
    "locktip": "This account has been disabled, please contact Administrator",
    "deletetip": "After deletion, the Staff will not be able to manage the store and you will no longer be able to add this Staff",
    "suretodeletestaff": "Are you sure to delete this Staff?",
    "Disablenow": "Disable now",
    "disabletip": "After suspension, the Staff will not be able to log in to Ginee to manage the store",
    "suretodisabled": "Are you sure to disable this Staff account?",
    "Edit": "Edit",
    "Enabled": "Enabled",
    "Disabled": "Disabled",
    "Action": "Action",
    "LastLogin": "Last Online",
    "NameofStaff": "Name of Staff",
    "AddStaff": "Add Staff",
    "Search": "Search",
    "Roles": "Roles",
    "GineeAccount": "Ginee Account",
    "AccountStatus": "Account Status",
    "ActivationFailed": "Activation Failed, Please Set Up first",
    "SavedSuccessfully": "Successful operation！",
    "donwloadlist": "Click here to download the full Stock list",
    "InventoryWarnings": "Stock Alert",
    "moresetting": "More Settings",
    "suretoclosemainlock": "You sure want to close Main Store Stock Lock?",
    "suretoopenstocklock": "You sure you want to activate the Main Store Stock lock? After activating, when the Stock is below the threshold, only the main store Stock is retained, and all stocks in other stores will be emptied",
    "whenempty2": ", Empty all stock in other stores, except this store",
    "whenempty1": "When the Stock is '<'=",
    "MainStoreStockLock": "Main Store Stock Lock",
    "suretoclosestockwarning": "Are you sure you want to turn off Stock warning?",
    "emailreminderstocklow": "Email reminder when product Stock is low, are you sure you want to open it?",
    "Setup": "Set up",
    "whenstock2": ", notify me that stock is insufficient",
    "whenstock1": "When stock <=",
    "suretoclosealertsold": "Are you sure you want to close out of stock alerts?",
    "notifyme": "Notify me when the product is sold out",
    "stocksettingtip3Local": "After the Stock synchronization is turned off, the product stock in all stores will no longer be deducted or added uniformly.",
    "stocksettingtip3": "After the Stock synchronization is turned off, the product stock in all stores will no longer be deducted or added uniformly, and the Stock list will be automatically cleared!",
    "stocksettingtip2LocalOld": "After Stock synchronization is turned on, the stock of all products bound to the same Master SKU will be adjusted to be consistent, and when the Master SKU generates a new order or the order is cancelled, the stock of all products bound to the Master SKU will be uniformly deducted or added. Please make sure that the synchronization of the product and the order has been completed before opening, and the stock quantity of the Master SKU has been adjusted",
    "stocksettingtip2": "After Stock Synchronization is turned on, the stock of the same SKU in all stores will be adjusted to be consistent, and when a new order is generated by the SKU or the order is cancelled, the stock of the SKU in all stores will be uniformly deducted or added. Please make sure that the synchronization of the products and orders has been completed before activated, and the number of Inventory SKUs has been adjusted",
    "stocksettingtip1Local": "To increase stock accuracy, before enabling Stock Synchronization please complete all products and orders synchronization, then set the MSKU stock. After enabling, when Ginee is synchronizing the orders, the MSKU stock will be automatically deducted or added according to the order status, and will synchronize available stock of the MSKU to the store where the product has been bound.",
    "stocksettingtip1": "To increase Stock accuracy, please complete the synchronization of all products and orders, then set the Inventory SKU before enable Stock Synchronization. After that, Ginee will automatically deduct or add the Inventory SKU according to Order's Status, and synchronize the Stock quantity to Store SKUs that are integrated to all bound stores",
    "monitorsync": "Stock Monitor and Sync",
    "noteCreateBind": "Note: If the Auto-Bind of the Master SKU is turned on, it will be executed first",
    "autoCreateSKUValue": "Creation rule: if the Store SKU of the product has the same name as a Master SKU, then the product will be bound to this Master SKU, otherwise a new Master SKU will be automatically added.It is only valid for channel products that are synchronized to Ginee and are not bound to the Master SKU.",
    "automaticallyCreateTheMasterSKU": "Automatically create Master SKU",
    "deleteAutoCreateRules": "Are you sure you want to turn off the Auto-Add Master SKU setting?",
    "turnAutoCreateRules": "After the Auto-Add of the Master SKU is turned on, for channel products that are not bound to the Master SKU If the Store SKU of the product has the same name as a Master SKU, the product will be bound to this Master SKU, otherwise a new Master SKU is automatically added.",
    "goToDownloadTheRules": "Go to [Import/Export-Export all Auto-Bind rules] to download the rules]",
    "downloadAllAutoBindRules": "Download all Auto-Bind rules",
    "deleteAutoBindRules": "Are you sure to turn off the Auto-Bind of the Master SKU setting?",
    "turnOnAutoBindRules": "After the Auto-Bind of the Master SKU is turned on, for channel products that are not bound to the Master SKU, we will automatically bind the products to the Master SKU according to the rule that the Master SKU has the same name as the Store SKU.",
    "autoBindSKUValue": "After enabling the setting, if the Store SKU has the same name as the Master SKU, it will be automatically bound, and it also supports custom automatic binding rules. It is only valid for channel products that are synchronized to Ginee and are not bound to the Master SKU.",
    "automaticallyBindTheMasterSKU": "Automatically bind the Master SKU",
    "SyncSuccess": "Sync completed",
    "AreSureEnableCOD": "Are you sure you want to enable COD?",
    "StoreName": "Store Name",
    "ExcludeShippingInfo3": "Confirm to open { JNEOKE }?",
    "ExcludeShippingInfo2": "Products with { JNEOKEList } enabled will switch to { JNEOKE }.",
    "ExcludeShippingInfo1": "If you open { JNEOKE }, then { JNEOKEList } will be closed.",
    "NoShippingInfo": "There is no integrated Shopee store, integrate now!",
    "CodNo": "No",
    "CodYes": "Yes",
    "DisabledCODClick": "click here for details",
    "DisabledCODDesc4": "J&T COD order is covered with insurance.",
    "DisabledCODDesc3": "Orders with COD payment will be easier to conduct a transaction.",
    "DisabledCODDesc2": "Abusive COD buyers will be blacklisted automatically",
    "DisabledCODDesc1": "Seller with COD will typically get more orders and buyers.",
    "DisabledCODTitle": "Are you sure you want to disable COD?",
    "PleaseGoToProductEnable": "Please go to each product to enable newly selected shipping options.",
    "UnEnabledTitle": "Once this option is unselected, it will be removed from all your existing products. Any products without shipping options will be Unlisted and will not be visible to buyers.",
    "CODDisabled": "COD disabled",
    "CODEnabled": "COD enabled",
    "COD": "COD",
    "ShippingSupportedDesc": "With Shopee Supported shipping options, buyers will be notified by Shopee of the latest tracking information of their packages.",
    "ShopeSupportedLogistics": "Shopee Supported Logistics",
    "ShippingTopTips": "Note: You can manage the logistics settings of the integrated stores here. It is recommended that the shipping settings of multiple stores in the same channel to be consistent. Please enable at least one logistic.",
    "MembershipPage": "Membership Page",
    "BasicLogo": "Logo",
    "Account": "Account",
    "afterClosingTheAutomaticBindingRules": "After closing, the automatic binding rules will be cleared and channel products will no longer be automatically bound to the Master SKU.",
    "ShippingRuleSettings1": "Shipping Rule Settings",
    "TheWarehouseAllocatedByThe": "2. The warehouse allocated by the order has been bound to Accurate warehouse",
    "MskuHasSuccessfullyPushedTo": "1. MSKU has successfully pushed to Accurate",
    "orderRulesToPushToAccurate": "Order rules to push to Accurate:",
    "OnlySupports": "3. Only supports \"(\", \")\", \"-\", \".\", \"_\" and \"/\"",
    "OnlySupportsLetternumberCombination": "2. Only supports letter/number combination",
    "NoMoreThanCharacters": "1. No more than 30 characters",
    "mskuRulesToPushProductTo": "MSKU rules to push product to Accurate :",
    "ifNoRulesAreAddedThe": "If no rules are added, this rules will be implemented. Once rules are added by yourself, the warehouse will be assigned according to the rules you set.",
    "afterTheWarehouseSettingIsModified": "After the warehouse setting is modified, the warehouse will be allocated according to the new rules. Please be careful.",
    "afterConfigurationWhenAnOrderContains1": "After configuration, when an order contains multiple product,  different product can be allocated different warehouses",
    "particularCommodity": "Single Product",
    "afterConfigurationWhenAnOrderContains": "After configuration, when an order contains multiple products, it can only be allocated to one warehouse, and cannot be allocated multiple warehouses at the same time",
    "overallOrder": "Overall Order",
    "compartmentDimension": "Compartment dimension",
    "afterConfigurationWhenMultipleWarehousedividingRules": "After configuration, when multiple warehouse allocation rules are met, warehouse with the lowest priority number will be allocated first to generate outbound list and process stock",
    "warehouseWithHighRulePriority": "Warehouse with high rule priority",
    "afterConfigurationWhenMultipleWarehouseAllocation": "After configuration, when multiple warehouse allocation rules are met, priority will be given to warehouses with sufficient available stock to generate outbound list and process stock",
    "fullyStockedWarehouse": "Fully stocked warehouse",
    "priorityOfWarehouseDivision": "Priority of warehouse allocation",
    "promptThisSettingAffectsWarehouseAllocation": "Prompt: This setting affects warehouse allocation rules, so please be careful.",
    "temporarilyNotOpen": "Temporarily not turn on",
    "theSwitchIsNotTurnedOn": "The switch is not turned on, and the warehouse allocation rule will not be executed after it is successfully created.",
    "theSwitchOrderSettingsdistributionSettingsorderAssociated": "The switch \"Order Settings-Distribution Settings-Stock Order Deduction Process\" has not been turned on.",
    "separateWarehouseSetting": "Warehouse Allocation Setting",
    "afterAddingRulesByYourselfWhen": "After adding rules by yourself, when the added rules are met, the warehouse will be assigned according to the rules you set.",
    "matchAccordingToTheWarehouseIn": "Map according to the MSKU  latest inbound warehouse by default.",
    "downloadApp": "Download APP",
    "manageYourStoreAnytimeAnywhereGinee": "Manage your store anytime, anywhere, Ginee App is officially launched",
    "shippingMethodPriorityPickUp": "Shipping Method Priority: Pickup > Dropoff > Manual Ship",
    "logisticsCompaniesThatDoNotSet": "Logistics companies that haven't set shipping rules yet will ship according to supported shipping methods",
    "pleaseSelectALogisticsCompanyFor": "Please select a logistics company for your Akulaku order",
    "pullThreepartyWarehouseForStorage": "Pull 3rd Party Warehouse Stock",
    "expired": "Expired",
    "deactivated": "Restricted",
    "ecommercePlatform": "Marketplace",
    "selfbuiltStation": "Webstore",
    "financialSoftware": "Accounting",
    "originalName": "Original",
    "editStore": "Edit store",
    "pullData": "Pull data",
    "reenable": "Re-enable",
    "deleteStore": "Delete store",
    "areyousureyouwanttodeletethisstore": "Are you sure you want to delete this store?",
    "TheBindingRelationshipBetweenThe": "1. The binding relationship between the store's channel products and the master product will be released.\n2. The store channel product list information will be deleted in Ginee.\n3. The stock in stock list will not be pushed to the store.\n4. You will not be able to see the order information for this store.\n5. You will not find the store in store settings",
    "theDefinitionOfDisabledIsStore": "Restricted: The store has been authorized but exceeds package limit. This status will not affect the store's sales in the channel. However, the store’s channel products will be automatically unbound from the MSKU, and data such as products/orders will not be displayed on Ginee",
    "authorizationInfo": "Authorization Info",
    "configManagement": "Config Management",
    "germany": "Germany",
    "belgium": "Belgium",
    "italy": "Italy",
    "poland": "poland",
    "authorized": "Authorized",
    "authTips": "After your Shop successfully connected, you can see your products and orders synchronization process on the Product and Order page",
    "youDoNotHavePermissionTo": "You do not have permission to store, so you cannot modify the store price.",
    "pleaseWaitPatientlyWhileTheStore": "Please wait patiently while the store data is being pulled.",
    "startPullingTheStoreDataIt": "Start pulling the store data, it is expected to take a few minutes, please pay attention to the synchronization of the store data later",
    "editSucceeded": "Edit succeeded.",
    "AreYouSureYouWantToDeleteTheAuthorization": "Are you sure you want to delete the authorization?",
    "areYouSureToModifyThe": "Are you sure to modify the warehouse distribution settings?",
    "CharactersLong": "10-12 characters long",
    "pleaseStartWithTwoLettersOr": "Please start with two letters or numbers \"1\" and include 10 numbers",
    "pleaseEnterTheCorrectAkulakuTracking": "Please enter the correct Akulaku tracking number format",
    "transportStockChange": "Transit Stock：",
    "tipavailablestock": "The stock that can be sold in Channel，Available Stock = Warehouse Stock-Spare Stock-Locked Stock-Promotion Stock",
    "tiplockedStock": "Stock is waiting to be fulfilled but not shipped yet, New paid or New unpaid order synced to Ginee will add to Locked Stock",
    "tipsafetyStock": "Set a stock limit and receive email notification to restock when Available Stock of the product reach the set number",
    "tipspareStock": "The total amount of Stock reserved in the actual warehouse, which will not be sold in the channel.",
    "tipwarehouseStock": "The Total Actual Stock in your Warehouse, Warehouse Stock = Spare Stock + Locked Stock + Available Stock + Promotion Stock",
    "totalAvailableStock": "Total Available Stock",
    "totalPromotionStock": "Total Promotion Stock",
    "totalLockedStock": "Total Locked Stock",
    "totalSpareStock": "Total Spare Stock",
    "totalWarehouseStock": "Total Warehouse Stock",
    "ImageName": "Image&Name",
    "case": "Case",
    "purchasePricecalrules": "Average Purchase Price = Total Inbound Purchase Price/Warehouse Stock",
    "ISKUmeaning": "MSKU Stands for Master SKU",
    "nochange": "Available Stock has not changed, so the stock in the store doesn't need to be updated",
    "manulupdate": "No Change",
    "updatetimetip": "Update Time",
    "reasonforfailed": "Failed Reason: Product has been deleted or banned from the channel store, or it is on a promotion campaign (the synchronization can be successful after the promotion ends)",
    "TotalPromotionStock": "Total Promotion Stock",
    "StoreSKU": "Store SKU",
    "tippromotionStock": "Promotion Stock is the total stock reserved for Campaign or Flash Sale promotions",
    "remark": "Remarks",
    "transportStock": "Transit Stock",
    "warehouseStockChange": "Warehouse：",
    "spareStockChange": "Spare：",
    "promotionStockChange": "Promotion：",
    "lockedStockChange": "Locked：",
    "availableStockChange": "Available：",
    "oversell": "The product is oversold, please adjust the Stock.Now update Stock in the channel to 0.",
    "Seethedetails": "See the details",
    "warehouseStockerror": "Stock should be between 0 and 999,999",
    "warehouseStockrequired": "Stock should be between 0 and 999,999",
    "remarkplaceholder": "Please enter reason for edit, should be less than 300 characters.",
    "availablestockshort": "Available",
    "promotionstockshort": "Promotion",
    "lockedStockshort": "Locked",
    "spareStockshort": "Spare",
    "warehouseStockshort": "Warehouse",
    "priceplaceholder": "Input Price",
    "Barcodeplaceholder": "Supports letters, numbers and -_",
    "weightplaceholder": "Range is 5,000,000",
    "upgradeResultClose": "Close",
    "upgradeResultViewNow": "View Now",
    "upgradeResultRetry": "Retry",
    "upgradeResultFailedTitle": "Inventory Management Pro Upgrade Failed！",
    "upgradeResultSuccessTitle": "Successfully Upgrade to Inventory Management Pro！",
    "upgradeModalUpgradeNow": "Upgrade Now",
    "upgradeModalCancel": "Cancel",
    "upgradeModalAbout": "About Inventory Management Pro",
    "january5th": "will be upgraded automatically On January 5th 2021.",
    "syncmanually": "sync the orders",
    "upgradeModalFooterTitle": "Please manually {syncorder} first before upgrade, so existing orders will deduct the inventory.Ginee accounts with Basic Version will be {updatedate}",
    "upgradeModalLabelStockWarning": "Stock Warning:",
    "upgradeModalLabelSoldOut": "Sold Out:",
    "upgradeModalLabelAll": "All:",
    "upgradeModalContentTitle": "View your existing Master SKU quantity:",
    "upgradeModalTitleSub": "Inventory Management Pro is an advanced version with {val} new features added to Inventory Management.",
    "upgradeModalTitle": "Upgrade to Inventory Management Pro",
    "upgradeBtn": "Free Upgrade to Pro，View now!",
    "setstock": "Set Stock",
    "setsafetyStock": "Set Safety Stock",
    "setspareStock": "Set Spare Stock",
    "setwarehouseStock": "Set Warehouse Stock",
    "skusselected": "SKUs selected",
    "skuselected": "SKU selected",
    "skus": "SKU",
    "Viemore": "View",
    "VariationStatus": "Variation Status",
    "createnext": "Create & Next",
    "sizelimittip": "Should be between 1-999,99!",
    "pricelimittip": "Purchase Price should be between 0-1,000,000,000!",
    "weightlimittip": "The weight should be between 1-5,000,000!",
    "validatorsize": "Should be between 1-999,99",
    "Barcodelengthtip": "Barcode should not more than 30 characters",
    "Barcodeerror": "Barcode only supports letters, numbers and -_",
    "mustbiggerthan": "Can not be less than (Spare Stock + Locked Stock)",
    "mustlessthan": "Should be less than ( Warehouse Stock-Locked Stock)",
    "PackageSize": "Package Size",
    "inputsafetyStock": "Should be between 0 and 999,999",
    "inputspareStock": "Should be between 0 and 999,999",
    "inputwarehouseStock": "Should be between 0 and 999,999",
    "safetyStockisRequired": "Safety stock Should be between 0 ~ 999,999!",
    "spareStockisRequired": "Spare Stock Should be between 0 and 999,999!",
    "warehouseStockisRequired": "Warehouse Stock Should be between 0 and 999,999!",
    "namerequired": "Name should be  between 1 - 160 characters",
    "Barcode": "Barcode",
    "addprice": "Inbound Purchase Price(Rp)",
    "averageprice": "Average Purchase Price(Rp)",
    "ItemInformation": "Product Info",
    "stockimage": "Format: jpg, jpeg, png. Picture size: less than 5MB",
    "noresult": "No Change",
    "EMPTY": "Store products not binded",
    "FAILEDcontent": "Failed: Channel doesn't allow to edit this stock",
    "inventorylogtip": "Ginee will automatically retry stock update for stores that failed. You can view the update details to check the retry or if you want manually retry.",
    "FAILED": "Failed",
    "PART_FAILED": "Some Failed",
    "UpdateCompleted": "Update Completed",
    "StockWarning": "Stock Warning",
    "EXPIRED": "Retries Failed",
    "RETRY_SUCCEEDED": "Retry Successful",
    "SUCCEEDED": "Success",
    "NEED_RETRY": "Failed, trying again...",
    "PROCESSING": "Processing",
    "UpdateDetails": "Store Stock Update Details",
    "ManualUpdate": "Manual Update",
    "StoreUpdateResult": "Store Update Result",
    "Operator": "Operator",
    "Latest": "Latest",
    "Movement": "Movement",
    "Original": "Original",
    "updateDatetime": "Update Time",
    "StockUpdateFailed": "Update Failed",
    "StockSuccessfullyUpdated": "Successfully Updated",
    "Updating": "Updating",
    "UpdateToStore": "Update to Store",
    "OrderCancel": "Order { OrderId } was Cancelled, Reason: { ReasonInfo }",
    "NewPaidOrder": "New Paid Order",
    "UserEditStock": "User edit Stock",
    "System": "System",
    "ChangeReason": "Change Reason",
    "OriginalStock": "Original Stock",
    "createDatetime": "Create Time",
    "StockTime": "Time",
    "StockUserName": "Username",
    "StockChangeHistory": "Stock Change Records",
    "clicktoset": "Click here",
    "synclosed": "Stock synchronization has been closed, you modify the stock quantity of the Master SKU will not affect the stock quantity sold on the platform; want to use stock synchronization?",
    "syncopend": "The Stock synchronization has been turned on, and when the stock quantity of the Master SKU changes, the stock quantity sold by the platform will also change accordingly",
    "skurelation": "Please enter the Channel SKU to establish the binding relationship between the SKU and the Master SKU. When creating the Master SKU, the platform SKU with the same name will be automatically bound",
    "stockrequired": "Stock must be between 0-999,999!",
    "inventorySkurequired": "Master SKU cannot be empty!",
    "inputpurchasePrice": "Input Price",
    "inputname": "Input Name",
    "inputstock": "Only Integers 0 - 999,999",
    "inputinventorySku": "Input Master SKU",
    "addsuccess": "Successfully Added!",
    "deletesuccess": "Successfully Deleted!",
    "editsuccess": "Successfully Edited!",
    "afterdelete": "After deleting the Master SKU, the binding relationship with the platform SKU will also be deleted",
    "suretodelete": "Are you sure you want to delete the Master SKU?",
    "products": "Master SKU",
    "failimport": "Import Failed:",
    "sucessimport": "Import Success:",
    "totalimport": "Total Import:",
    "picture": "Product picture",
    "skuList": "SKU (Store SKU)",
    "purchasePrice": "Inbound Purchase Price",
    "nameStock": "Name",
    "Stock": "Stocks",
    "SoldOut": "Sold Out",
    "AddInventorySKU": "Add Master SKU",
    "seacrh": "Search",
    "ExportAll": "Export All",
    "Exportbyselected": "Export Selected",
    "DownloadImportTemplate": "Download Import Template",
    "ImportInventorySKU": "Import Master SKU",
    "Import": "Import",
    "ok": "OK",
    "editsku": "Edit Master SKU",
    "addsku": "Add Master SKU",
    "skuseacrh": "Search Master SKU、SKU、Title",
    "skuplaceholder": "Input SKU (Your Store SKU)",
    "accordingToTheQueryConditionsYou": "According to the query conditions you set, the estimated shipping cost from logistic company as follows:",
    "calculationResults": "Calculation results",
    "destination": "Ship to",
    "shipFrom": "Ship from",
    "calculationFormula": "Calculation formula",
    "checkShippingFee": "Check Shipping Fee",
    "addAddress": "Add address",
    "setAsDefault": "Set as default",
    "theBindingRelationshipBetweenJtExpress": "The binding relationship between J&T Express (ID) and Ginee has been lifted, and you will no longer be able to use the logistics-related services in Ginee, please be aware.",
    "onceDeletedYouWillNoLonger": "Once deleted, you will no longer be able to use the J&T Express(ID) logistics service in Ginee, and the orders that have been placed will not be emptied, please operate carefully",
    "confirmToDelete": "Confirm to delete?",
    "shippingSettings1": "Shipping Settings",
    "reasonTheAuthorizationInformationIsIncorrect": "Reason: The authorization information is incorrect, please edit and re-authorize",
    "youCanUseTheJtExpress": "You can use the J&T Express (ID) service to estimate the shipping cost > place an order > get AWB number > print label > track the package",
    "maxCharactersOnlyNumbers": "Max. {length} characters, only numbers & letters",
    "requiredToFillInPleaseGet": "Required to fill in, please get and fill information according to the authorization instruction",
    "withQuickAuthorizationYouDontNeed": "With Quick Authorization, you don't need to contact J&T Express (ID) to obtain the required information, and Ginee will support the required information to authorize",
    "clickToViewAuthorizationInstruction": "Click to view authorization instruction",
    "clickAuthorizeToBindAndIt": "To successfully binding please select [Authorize], and it will  support to placing an order / get the courier number / checking shipping fee / tracking the package",
    "toUseAStandardAuthorizationPlease": "To use a standard authorization, please contact J&T Express (ID) to get required  information for the authorization",
    "recomend": "Recomend",
    "standardAuthorization": "Standard Authorization",
    "quickAuthorization": "Quick Authorization",
    "theSystemDetectsThatChannelProducts": "The system detects that channel product's SKU is empty. Please confirm whether to use the variation name and product name as the MSKU?",
    "yesApplyToAllProducts": "Yes, apply to all products",
    "productInbound": "Product Inbound",
    "manualOutbound": "Manual Outbound",
    "salesOutbound": "Sales Outbound",
    "theNumberOfConnectedStoresThat": "The number of connected stores that have exceeded the effective package limit, please continue to subscribe to the package or contact customer service to upgrade the package before enabling the store;",
    "subscriptionPackage": "Subscription Package",
    "theStoreHasBeenActivated": "The store has been activated!",
    "deleteSuccess": "Successfully Deleted",
    "fulfillConfirmDisconnectContent": "Once deleted, the corresponding {fulfillment} warehouse and fulfillment service will not be available, if you want to re-enable, please re-authorize first",
    "unfold": "View More",
    "collapse": "Fold",
    "expiredStatusComingSoon": "Expire Soon",
    "ForLogisticCompanyPleaseAdd": "6. For logistic company please add logistic that can be choose in manual order",
    "WarehouseAsWarehouseNameNot": "4. Warehouse use warehouse name, not warehouse number",
    "jnePleaseFillTheLogisticThat": "JNE (Please fill the logistic that can be select in manual order)",
    "whetherBlacklist": "Whether Blacklist",
    "pleaseSetupShipmentSettingsFirstBefore": "Please setup shipment settings first before select Pickup Address",
    "authorizeNow": "Authorize now",
    "pleaseRecheckAfterAuthorizeLogistic": "Please re-check after authorize logistic",
    "temporaryNotAuthorize": "Temporary not authorize",
    "oddNumbers": "Inbound Number",
    "wholesalePrice": "Wholesale Price",
    "addWholesalePrice": "Add Wholesale Price",
    "maxNumber": "Max. number cannot be less than or equal to Min. number",
    "maxNumberCannotBeLessThan": "Max. number cannot be less than or equal to Min. number",
    "theWholesalePriceCannotBeHigher": "The wholesale price cannot be higher than product selling price.",
    "theWholesalePriceOfCannot": "The wholesale price of {a} cannot be higher than the wholesale price of {b}.",
    "wholesalePricesAreOnlyAvailableTo": "Wholesale prices are only available to add if all variants have the same price",
    "productNameMinCharacter": "Product name can not less than {min} character",
    "productNotCanNotLessThan": "Product not can not less than {min} image",
    "breadCrumbProductList": "Products / {channel}",
    "storePermission": "Store Permission",
    "channelPermission": "Channel Permission",
    "masterProductPermission": "Master Product Permission",
    "priceManagementPermission": "Price Management Permission",
    "toEnsureTheAccuracyOfAvailable": "To ensure that the available inventory is accurate, please click \"Update Available Inventory\" to get the latest inventory value.",
    "masterUpdateAvailableStock": "Update available stock",
    "pleaseSelectCompleteReceiverArea": "Please select a complete recipient region",
    "toCalculateShippingCostNeedTo": "To calculate shipping cost need to fill in shipping information and package weight",
    "calculateShippingFee": "Calculate Shipping Fee",
    "detailAddress": "Detail Address",
    "needToSelectAreaDimension": "Need to select area dimension",
    "maximumCharactersNumbersOnly": "Maximum 30 characters, numbers only",
    "maximumCharactersLettersOnly": "Maximum 30 characters, letters only",
    "site": "Site",
    "gineeErrorUrllabelEmptyPleaseTry": "Ginee Error: Label URL is empty. Please try to sync order",
    "singleProductWeight": "Single product weight",
    "calculate": "Calculate",
    "youDontHaveThisStorePermission": "You don't have this store permission",
    "logisticsServices": "Logistics Services",
    "theMaximumLengthIsCharacters": "The maximum length is {maxLength} characters",
    "averagePurchasePriceCurrentInbound": "Average purchase price = (Current Inbound Quantity  * Current Inbound Purchase Price + Original Quantity * Original Purchase Price) / (Current Inbound Quantity + Original Quantity)",
    "tiktokMassDeleteTip": "Due to API limitation from Tiktok, the number of items to be deleted at one time cannot exceed 20, please select again.",
    "validityofauthorization": "Authorization Validity Period",
    "channelProductMassEdit": "Products / {channel}/Mass Edit",
    "dataPermission": "Data permission",
    "ruleTypeSpecifyMsku": "Rule Type: Specify MSKU",
    "ruleTypeShopRules": "Rule Type: Shop Rules",
    "addNewRules": "Add new rules",
    "delete1": "Delete",
    "requiredItemLockSettingIf": "Required item. Lock Setting - if the main store inventory lock setting is enabled, when the MSKU stock in the \"Push Strategy\" is less than or equal to the \"Threshold\", the available stock push for other stores except the selected store is 0",
    "setupPushStore": "Setup Push Store",
    "requiredItemPleaseEnterAnInventory": "Required item, please enter an inventory value greater than or equal to 0 and less than or equal to 99999",
    "setStockThresholds": "Set Stock Thresholds",
    "pleaseEnterTheMskuToBe": "Please enter the MSKU to be added, if there are multiple MSKU please put in different row. It will take time to save too much at one time, please wait patiently",
    "manualSelection": "Manual Selection",
    "textRecognition": "Text Recognition",
    "addingMethod": "Adding Method",
    "specifyMsku1": "Specify MSKU",
    "allMskus": "All MSKUs",
    "addedMainStoreLock": "Added main store lock",
    "afterTurnOnPleaseSetUp": "After turn on, please set up main store lock rule, single MSKU only support setting one main store lock. Once turn off this rule, all main store rule will failed by default",
    "turnOnMainStoreLocked": "Turn on main store locked",
    "lockSettings": "Lock Settings",
    "clickToView": "Click  to view",
    "addMsku1": "Add MSKU",
    "deleteMsku": "Delete MSKU",
    "masterProductPicture": "Master Product & Picture",
    "pushMsku": "Push MSKU",
    "ruleType": "Rule Type",
    "ruleInformation": "Rule Information",
    "massAdd": "Mass Add",
    "add1": "Add",
    "addNewRule": "Add New Rule",
    "mainStoreLocked": "Main Store Locked",
    "operateBy": "Operate By",
    "operationModule": "Operation Module",
    "tipOnlySupportForQueryingOperation": "Tip: Only support for querying operation logs in last 30 days",
    "theRuleWasDeletedSuccessfully": "The rule was deleted successfully",
    "theRuleWasSavedSuccessfully": "The rule was saved successfully",
    "ruleDisabledSuccessfully": "Rule disabled successfully",
    "afterEnablingTheInventoryWillBe": "After enabling, the inventory will be calculated and pushed to the channel store according to the current rules",
    "areYouSureYouWantTo1": "Are you sure you want to enable the rule?",
    "afterDeletionTheRulesWillNo": "After deletion, the rules will no longer support management and use",
    "afterDisablingTheInventoryWillNo": "After disabling, the inventory will no longer be calculated and pushed to the channel store according to the current rules",
    "doYouConfirmToTurnOff": "Do you confirm to turn off the rules?",
    "ifTheCustomRuleIsDisabled": "If the custom rule is disabled, MSKU inventory will no longer be pushed to the channel",
    "afterCheckingIfACustomRule": "After checking, if a custom rule is added to MSKU and enabled, the inventory will be calculated according to the custom rule and pushed to the channel store;",
    "afterAddingACustomRuleTo": "After adding a custom rule to MSKU, the inventory is no longer pushed according to the default rule",
    "applicationSettings": "Application Settings",
    "newMskuAddedToWarehouseLater": "New MSKU: Added to warehouse later than activate time",
    "allMskusExcludingMskuThatAdded": "All MSKUs (excluding MSKU that added later)",
    "allMskusIncludingMskuThatAdded": "All MSKUs (including MSKU that added later)",
    "newStoresStoresWhichAuthorizationTime": "New stores: stores which authorization time is later than the activate time;",
    "allAuthorizedStoresConnectedStoresWhose": "All authorized stores: connected stores whose authorization time is earlier than the activate time;",
    "allConnectedStoresExcludingNewStores": "All connected stores, excluding new stores authorized later",
    "allConnectedStoresIncludingNewStores": "All connected stores, including new stores authorized later",
    "pushRatio": "Push ratio",
    "availableStockTransitStock": "Available Stock + Transit Stock",
    "allWarehousesWithMskusInInventory": "All warehouses with MSKUs in inventory and enabled (excluding new warehouses added later)",
    "newWarehouseWarehouseWhoseCreationTime": "New Warehouse: Warehouse whose creation time is later than their activate time",
    "allWarehousesWithMskuThatHave": "All warehouses with MSKU that have been inbound and has active status (including new warehouses added later)",
    "ruleStatus": "Rule Status",
    "noteThisTimeIsTheTime": "Note: This time is the time when the \"Stock Settings\" - \"Stock Monitoring and Synchronization\" switch is turned on",
    "activateTime": "Activate Time",
    "viewFailureData": "View failure data",
    "backToRuleList": "Back to rule list",
    "fail": "Fail",
    "sucess": "Success",
    "dataProcessingCompleted": "Data processing completed",
    "saveCurrentlyNotActive": "Save, currently not active",
    "afterActivatePleaseAddMskuAs": "After activate, please add MSKU as soon as possible, otherwise the rule will not go properly",
    "areYouSureWantToSave": "Are you sure want to save and activate the rule?",
    "stockType1": "Stock Type",
    "warehouseName1": "Warehouse Name",
    "specifyMskuAfterCreatingTheRule": "Specify MSKU (after creating the rule, you need to add MSKU by yourself, only the added MSKU will be pushed according to the set rule)",
    "storeRulesAfterCreatingTheRules": "Store rules (after creating the rules, you don't need to add MSKU, the channel SKUs that have bound MSKU in the store are pushed according to the set rules)",
    "tipsWhenAddingRulesIn": "Tips: 1. When adding rules in batches, the rule name will be automatically added by the system; 2. When adding rules in batches, only the rule information is saved, and after saving, please set the applied MSKU in the rule list; 3. Max. 100  can be added in 1 time;",
    "clickToViewMsku": "Click to view MSKU",
    "showAllStore": "Show all store",
    "showAllWarehouses": "Show all warehouses",
    "executeTheRules": "Execute the rules",
    "fixedValue": "Fixed value",
    "storeRule": "Store Rule",
    "specifyMsku": "Specify MSKU",
    "operation": "Operation",
    "createBy": "Create By",
    "ratioFixValue": "Ratio / Fix value",
    "ruleName": "Rule Name",
    "exactMatch": "Exact mapping",
    "multipleMskusSeparatedBySpacesMax": "Multiple MSKUs separated by spaces, max. 50",
    "pleaseFillInTheCompleteRecipient": "Please fill in the complete recipient address information",
    "pleaseSelectAPickupAddress": "Please select a pickup address",
    "addMainStoreLock": "Add main store lock",
    "editMainStoreLock": "Edit main store lock",
    "ratio": "Ratio",
    "thisAddressDoesNotCurrentlySupport": "This address does not currently support the calculation of shipping costs",
    "tExpressIdIsNotAuthorized": "J&T Express ID is not authorized, cancellation will not notify the logistics company",
    "channelSku": "Channel SKU",
    "inventoryPushRulesMainStore": "Stock Push Rules/ Main Store Stock Locking",
    "inventoryPushRuleOperationLog": "Inventory push rule / operation log",
    "keywordsOfOperationContent": "Keywords of Operation Content",
    "pleaseEnterMskuPleaseWrapMultiple": "Please enter msku, please wrap multiple msku",
    "skuExactSearch": "SKU exact search",
    "viewMskuApplicationRules": "View Push Rules",
    "showAllStores": "Show all stores",
    "bindingCommodityInformation": "Binding commodity information",
    "analogComputation": "analog computation",
    "unmatchedChannelGoods": "Unmatched channel goods",
    "normal": "Normal",
    "viewRules": "View rules",
    "promptThisFunctionSupportsViewingThe": "Prompt: This function supports viewing the rules actually used by the current MSKU when pushing inventory, and viewing up to 50 MSKUs at a time.",
    "newStoresWithoutSubsequentAuthorization": "(New stores without subsequent authorization)",
    "newStoreWithSubsequentAuthorization": "(New stores with subsequent authorization)",
    "allConnectedStores": "All connected stores",
    "pleaseTurnOnTheInventorySynchronization": "Please turn on the inventory synchronization switch under the inventory setting menu before operating.",
    "Ruledetails": "Rule details",
    "Default": "Default",
    "thereAreDuplicates": "There are duplicates.",
    "PleaseentertheMSKU": "Please enter the MSKU to be added, multiple newlines, no more than 200 characters in a single line, the system will automatically truncate if it exceeds",
    "noNeedToPush": "No need to push",
    "mskuDoesNotExist": "MSKU does not exist",
    "mskuInventorypushInventory": "MSKU Inventory/Push Inventory",
    "proportional": "Proportional",
    "IfTheMskuForThe": "4. If the MSKU for the department is not pushed to some channels, please create a disabled specified MSKU rule;",
    "IfSomeMskusNeedTo": "3. If some MSKUs need to be pushed according to the specified warehouse/designated store/specified proportion or fixed value, please create a specified MSKU rule;",
    "IfTheChannelStoreNeeds": "2. If the channel store needs to use the available inventory of the designated enabled warehouse for pushing, please create a store rule, set the relationship between the store and the warehouse and the push method;",
    "IfTheInventoryOfAll": "1. If the inventory of all channel stores is pushed according to the percentage of available inventory in the warehouse that is fully enabled, please directly use the default rules and set the push ratio;",
    "directionsForUse": "Directions for use:",
    "youCanClickViewMskuApplication": "You can click \"View Push Rules\" button to query the rules that the specified MSKU actually uses to push.",
    "thirdPriority": "Third priority",
    "secondPriority": "Second priority",
    "firstPrioritySpecifyMsku": "First priority: specify MSKU",
    "inventoryPushInstructions": "Stock push instructions",
    "mskuCreationTimeIsLaterThan": "MSKU creation time is later than the opening time of the inventory push switch.",
    "theStoreBindingTimeIsLater": "The store binding time is later than the opening time of the inventory push switch.",
    "theWarehouseCreationTimeIsLater": "The warehouse creation time is later than the opening time of the inventory push switch.",
    "reasonTheDefaultRuleConditionsAre": "Reason: The default rule conditions are not met. Please create your own rules and push them.",
    "reasonTheChannelStoreStatusIs": "Reason: The channel store status is not authorized. Please reconnect the store and push it.",
    "reasonThePushRuleForChannel": "Reason: The push rule for channel goods has been disabled. Please open the rule and push it.",
    "reasonInventorySettinginventoryPushSwitchIs": "Reason: Inventory setting-inventory push switch is not turned on. Please turn on the switch and push it.",
    "reasonTheStatusOfChannelGoods": "Reason: The status of channel goods is draft, please put them on the shelves and push them.",
    "xpriceProtection": "{X}%price protection",
    "order_628344ee0de79247bef8cb6694d1cd38": "Wrong price/promotion",
    "fuzzySearch": "Fuzzy search",
    "preciseSearch": "Precise Search",
    "historicalOrders": "Historical Orders",
    "nearlyDaysOrder": "Past 90 Days Orders",
    "tipChannelInterfaceRestrictionOnlySupports": "Tip: Channel interface restriction only supports updating the inventory of goods that have been put on or off shelves.",
    "theChannelStatusIsAbnormalPlease": "The channel status is abnormal. Please confirm and push it.",
    "theWarehouseHasBeenDeactivatedPlease": "The warehouse has been deactivated. Please enable it and push it.",
    "whenSearchingAccuratelyTheMaximumNumber": "When searching accurately, the maximum number of queries in a single time is {X}. Please use enter to feed multiple messages, and each line represents one message.",
    "pleaseEnterTheOrderNumber": "Please enter the order number.",
    "settingSuccess": "Setting Success",
    "insured": "Insured",
    "insuranceRation": "Insurance Ratio",
    "pleaseSelectInsuranceService": "Please select insurance service",
    "pleaseSettingInsuranceFeeForAkulaku": "Please setting insurance fee for Akulaku order",
    "settingInsurance": "Setting Insurance",
    "proportionalInsurance": "Proportional Insurance",
    "originalInsurancePrice": "Original Insurance Price",
    "notInsured": "Not Insured",
    "plannedInboundAmount": "Planned Inbound Amount",
    "actualInboundAmount": "Actual Inbound Amount",
    "shippingSubsidy": "Shipping Fee Rebate",
    "platformSubsidy": "Platform Subsidy",
    "spuAlreadyExistsPleaseRename": "SPU already exists, please rename",
    "templateType": "Template Type",
    "labelSize": "Label Size",
    "barcodePrintingTemplateSettings": "Barcode Printing Template Settings",
    "fontSize": "Font Size",
    "howToGenerate": "How to generate",
    "barcodeHeightInsideLabel": "Barcode height inside label",
    "qrCode": "QR Code",
    "qrCodeLocation": "QR Code Location",
    "left": "Left",
    "top": "Top",
    "qrCodeSize": "QR Code Size",
    "generatedByMsku": "Generated by MSKU",
    "generatedByBarcode": "Generated by Barcode",
    "generatedBySpu": "Generated by SPU",
    "mskuCode": "MSKU Code",
    "printContent": "Print Content",
    "customAdd": "Custom Add",
    "goToTheMasterProductPage": "Go to the Master Product page and select the product to print",
    "customContent": "Custom Content",
    "pleaseInsertTheContentToBe": "Please insert the content to be printed, multiple newlines, no more than 200 characters in a single line, the system will automatically truncate if it exceeds",
    "numberOfPrintsreferringToTheNumber": "Number of prints(referring to the number of labels for a barcode)",
    "paperPrintColumn": "Paper Print Column",
    "columnGap": "Column Gap",
    "barcodePrinting": "Barcode Printing",
    "atLeastCharactersOrMore": "At least 3 characters or more.",
    "theFuzzySearchContentMustExceed": "The fuzzy search content must exceed 3 characters. Please adjust and try again.",
    "ordersCreatedForMoreThan": "Orders created for more than 90 days will be moved into historical orders and cannot be processed.",
    "areYouSureYouWantTo2": "Are you sure you want to move the order to Delivered?",
    "moveToDelivered": "Move to Delivered",
    "firstPriority": "First priority",
    "supportAddingMaxDataAt": "Support adding max. 2000 data at one time",
    "supportAddingMaxDataAt1": "Support adding max. 1000 data at one time",
    "stockWillBeIncreasedAfterInbound": "Stock will be increased after inbound, please operate with caution, only mass inbound from local warehouse is supported",
    "afterConfirmationTheDocumentWillBe": "After confirmation, the document will be re-pushed to the warehouse",
    "stockWillBeDeductedAfterOutbound": "Stock will be deducted after outbound, please operate with caution",
    "Days1": "30 days",
    "Days": "7 days",
    "clickToDownloadAttachment": "Click to download attachment",
    "attachment": "Attachment",
    "addToTheFulfillmentWarehouse": "Add to the fulfillment warehouse",
    "lockedTime": "Locked Time",
    "LockedStock": "Locked Stock",
    "lockStockOrder": "Lock Stock Order",
    "onlySupportsFilesDocXlsXlsx": "Only supports files *.doc, *.xls, .xlsx, PDF, Excel, png, jpeg, JPG less than 15M",
    "clickOrDragFilesHereTo": "Click or drag files here to upload",
    "clickToDeleteAttachment": "Click to delete attachment",
    "uploadAttachment": "Upload Attachment",
    "vatRate": "VAT Rate (%)",
    "availableStockLocationCode": "Available Stock Location Code",
    "stockWil": "Stock will be deducted after outbound, please operate with caution, only mass outbound from local warehouse is supported",
    "stockWillBeReleasedAfterBeing": "Stock will be released after being set as Invalid, please operate with caution",
    "xDataSelected": "{x} data selected",
    "massSetAsInvalid": "Mass Set as Invalid",
    "massPush": "Mass Push",
    "massRefreshStatus": "Mass Refresh Status",
    "oneItemPerLineSupportExcel": "One item per line (Support Excel copy and paste, up to 500)",
    "clickToMassSearch": "Click to mass search",
    "salesOutboundList": "Sales Outbound List",
    "manualOutboundList": "Manual Outbound List",
    "addTemplate": "Add Template",
    "massOutbound": "Mass Outbound",
    "afterConfirmationTheSelectedDataWill": "After confirmation, the selected data will be added to the fulfillment warehouse again, please operate carefully",
    "massInbound": "Mass Inbound",
    "itIsDetectedThatTheIssue": "It is detected that the inbound list has been updated to be Waiting Inbound or Invalid, so push is not supported. Please refresh and try again.",
    "itIsDetectedThatTheReceipt": "It is detected that the inbound list has been updated to be Completed or Invalid, so the push is not supported. Please refresh and try again.",
    "refunding": "Refunding",
    "maxUploadDocuments": "Max. upload 3 documents",
    "thirdPartyWarehousePushTime": "Push Time",
    "thirdPartyNumber": "hird Party Warehouse Number",
    "bulkSearch": "Mass Search",
    "thisSizeAlreadyExists": "This size already exists",
    "theRangeOfSupportedInputIs": "The range of supported input is {min}-{max}",
    "skuName": "SKU Name",
    "skusellerSKUInJDSellerCenter": "SKU = seller SKU in JD Seller Center",
    "barcodeHeight": "Barcode Height",
    "QRCodeLocation": "QR code location",
    "beLeft": "Left",
    "beTop": "Top",
    "actualPrintingPerformanceDependsOnPrintingResults": "Actual printing performance depends on printing results",
    "printingFailedReasonIsEmpty": "Printing failed, reason: {} is empty and printing is not supported.",
    "goUpgrade": "Go Upgrade",
    "jtExpressIdOnlySupportsPaid": "J&T Express ID only supports paid package merchants, please re-authorize after upgrading ERP package",
    "ordersAreOutOfStockAnd": "The order is out of stock, push stock is not allowed",
    "thereAreCurrentlyXPiecesOf": "There are currently {x} pieces of data, and a maximum of 50,000 pieces of data can be exported at a time. If you need to export more data, please contact the customer service",
    "returnInboundListXSuccessfullyCreated": "Return Inbound List: {x} successfully created",
    "successXFailedX": "Success {x}, failed {x}",
    "homeCustomerTips": "During the 9.9 promotion period (2022-09-08-20: 00-2022-09-10-23: 59), the customer statistics service went offline, and the result will be displayed as 0.",
    "orderOperationLogTips": "During the 9.9 promotion period (2022-09-08-20: 00-2022-09-10-23: 59), only the operation logs of nearly 7 days can be viewed.",
    "stockPushLogTips": "During the 99 promotion period (2022-09-08 20:00-2022-09-10 23:59), only support viewing the operation log of the day",
    "adjustmentMethodAll": "Adjustment Method: All",
    "adjustmentMethodOutbound": "Adjustment Method: Outbound",
    "adjustmentMethodInbound": "Adjustment Method: Inbound",
    "numberRow1": "Row 1",
    "numberRow2": "Row 2",
    "numberRow3": "Row 3",
    "numberRow4": "Row 4",
    "theStoreAuthorizationIsAbnormalPlease": "The store authorization is abnormal, please go to the store backend to check the authorization",
    "pleaseSetUpYourJtExpress": "Please set up your J&T Express ID shipping settings first",
    "theRangeOfProductImagesThat": "The range of product images that can be uploaded is from {min} to {max}",
    "barcodeTemplate": "Barcode Template",
    "typeDimension": "{type} dimension",
    "channelOrderInTheCurrentOutbound": "Channel order in the current outbound list is out of stock and does not meet the push conditions. Please push it after doing re-distribution in tab \"Order Process-Pending Order-Problem Order\"",
    "supportsInputNumberFromMinToMax": "Supports input number from {min} to {max}",
    "inboundListNumber": "Inbound Number",
    "inboundQuantity": "Inbound Quantity",
    "inboundWarehouse": "Inbound Warehouse",
    "theTotalQuantityOfASingle": "The total quantity of a single print cannot exceed {0}, please reduce the number of products or prints",
    "aPrintTemplateWithTheSame": "A print template with the same name already exists, please rename it",
    "printTemplateTip": "If the MSKU/Barcode/SPU exceeds 23 characters, the printed barcode may not be recognized. The QR code does not have this limitation. Please select the template type according to the actual situation.",
    "printMaxlengthTip": "If the number of characters exceeds 23, the printed barcode may not be recognized.",
    "pleaseSelectTheMainSku": "Please select the main SKU",
    "adjustmentMethod": "Adjustment Method",
    "purchaseInbound": "Purchase Inbound",
    "outbound": "Outbound",
    "inbound": "Inbound",
    "manualAdjustment": "Manual Adjustment",
    "sell": "Sell",
    "pleaseTickTheDataFirst": "Please tick the data first",
    "changeValue": "Movement",
    "latestLocationStock": "Latest Location Stock",
    "locationNumber": "Location Number",
    "postcode": "Postcode",
    "sucGeneratedOrFailed": "{success} successfully generated, {fail} failed",
    "printProductBarcode": "Print Product Barcode",
    "allPermissions": "No sub permissions",
    "pleaseSelectAtLeastOneExport": "Please select at least one export content",
    "pleaseSelectAtLeastOneBasic": "Please select at least one basic information",
    "inboundType": "Inbound type",
    "orderIdXSuccessfullyReprintedThe": "Order ID: {X} Successfully reprinted the label",
    "orderIdXSuccessfullyPrintedThe": "Order ID: {X} successfully printed the label",
    "setAsInvalidPopulerPrintOrder": "Set as invalid popular product print order task: {X}",
    "createPopularProductPrintOrderTask": "Create popular product print order task: {X}",
    "theOrderIsAlreadyInThe": "The order is already in the task of print orders for popular product, please print carefully to prevent repeated orders",
    "orderQuantity": "Order Quantity",
    "numberOFProducts": "Number of products",
    "taskId": "Task ID",
    "printProgress": "Print Progress",
    "setAsInvalid": "Set as Invalid",
    "alreadySetAsInvalid": "Invalid",
    "unfinished": "Unfinished",
    "creating": "Creating",
    "labelPrintingStatus": "Label printing status",
    "additionalPrintLabel": "Additional print label",
    "size": "Size",
    "ordinaryPrintingButPrintingUpTo": "Ordinary printing (but printing up to 300 orders, which takes a long time)",
    "selectedPrintDataXTheSystem": "Selected print data: {X}, the system detects that {Y} label have been marked and printed successfully, and {Z} have not been printed",
    "taskDetails": "Task Details",
    "toPrintLabel": "To print label",
    "manyProductsWithManyQuantity": "Multiple product",
    "singleProductWithManyQuantity": "Single product (multi quantity）",
    "singleProductWithSingleQuantity": "Single product (single quantity)",
    "theTaskIsBeingCreatedPlease": "The task is being created, please check from the print popular product label list  later",
    "createTask": "Create task",
    "noteOrdersThatHaveBeenPlaced": "Note: Orders that have been placed in the print popular product shipping label task will not enter other tasks",
    "totalOrders": "Total Orders:",
    "createdByFilter": "Created by filter",
    "createByTick": "Created by tick option",
    "printPopularProduct": "Print Hot Items Label",
    "adjustmentNo": "Adjustment No.",
    "warehouseReplacementTask": "Warehouse replacement task",
    "warehouseChangeSucceeded": "Warehouse change succeeded",
    "warehouseChangeFailed": "Warehouse change failed",
    "currentWarehouse": "Current warehouse",
    "newWarehouse": "New warehouse",
    "processingProgress": "Processing progress",
    "adjustmentType": "Adjustment Type",
    "thisIsTheInboundPurchasePrice": "inbound Purchase Price or outbound Purchase Price",
    "theFinalLocationInventoryOnlyIndicates": "Latest Location stock only represents the product stock that is in location, SKU warehouse stock=total stock of the SKU accumulated from each locations in the warehouse",
    "changeDimension": "Change dimension",
    "Replace": "Replace",
    "completeInboundStatus": "Complete",
    "setTemplate": "Set Template",
    "onlyNumberAndMaxLimit": "Maximum {max} characters, numbers only",
    "minimalOrderForWholesaleMustBe": "Minimal order for wholesale must be more than 1",
    "pleaseConfirmWhetherTheOrderMoved": "Please confirm whether want the order moved to Shipped",
    "moveToShipping": "Move to Shipping",
    "priceMustBeCheaperThanThe": "Price must be cheaper than the previous tier",
    "specificationInformation": "Variant Information",
    "theThirdPartyLogisticsIsNot": "The third party logistics is not authorized, order cancellation will not notify to the logistics company",
    "deliverBy": "Deliver By",
    "pickupBy": "PickUp By",
    "ParcelInformation": "Package Information",
    "theSameOptionValueExists": "Some option value are same",
    "pleaseEnterOptionsSuchAsRed": "Please enter options, such as red",
    "uploadFailed": "Upload Failed",
    "youCanUploadUpToMaxcount": "Max. {maxCount} files can be uploaded, file upload cancelled:",
    "fileSizeExceedsLimitMaxsize": "File size exceeds limit: {maxSize}",
    "pictureResolutionExceedsTheLimit": "Image resolution exceeds limit",
    "maxOrderCannotBeLessThan": "Max order cannot be less than min order",
    "clickDownloadAndTheSystemWill": "Click download, the system will export according to the filter conditions selected in the order list and the order creation time, please operate after knowing",
    "wholesalePriceCanNotBeLower": "Wholesale Price can not be lower than {m}% of the original price, please set a reasonable wholesale price",
    "mustBeAtLeastX": "Size must be at least 300 x 300 pixels, less than 2MB, JPG or PNG format",
    "thereAreDocumentsThatHaveNot": "Some files have not been uploaded successfully",
    "ifYouContinueToSubmitThe": "If you continue to submit, the submitted data will ignore the files that were not uploaded successfully",
    "continueToSubmit": "Continue To Submit",
    "thirdPartyLogisticsOnlySupportsThe": "Third party logistics only supports to use by paid package merchants, please re-authorize after upgrading the ERP package",
    "numbering": "Code number",
    "thereIsNoStoreThatSupports": "There is no store that supports cloning, please bind the store first",
    "productCloneInProgress": "Product clone in progress...",
    "countProductClonedTo": "{count} product cloned to",
    "exportProgress": "Export Progress",
    "exportType": "Export Type",
    "copyToDraft": "Copy to draft",
    "invalid": "Invalid",
    "editImage": "Edit Image",
    "editName": "Edit Name",
    "editDesc": "Edit Description",
    "editInfo": "Edit Info",
    "printLabelStatus": "Print Label Status",
    "platformOrderNumber": "Platform Order Number",
    "markAsFakeOrder": "Fake order",
    "noteOrdersThatHaveBeenPlaced1": "Note: Orders that have been placed in the task will not enter other tasks",
    "theNumberOfVariantCannotExceed": "The number of variant cannot exceed {count}",
    "stepConfirmThePictureInformation": "Step 1: Confirm the picture information",
    "stepSelectTheProductYou": "Step 2: Select the product you want to apply",
    "editImagesInBatches": "Edit images in batches",
    "productImageTip": "Due to API limitation, for product image max. {maxLength} images, maximum size {size}MB and resolution must be greater than {width}*{height}px",
    "fakeOrder": "Fake Order",
    "whetherFakeOrder": "Is a Fake Order",
    "massSettingFakeOrder": "Mass setting fake order",
    "copyAsDraftTip": "products copied as draft",
    "orderXCancelsTheFakeOrder": "Order {X} cancels the fake order tag",
    "orderXIsMarkedAsA": "Order {X} is marked as a fake order",
    "fakeOrders": "Fake Order",
    "menuProductShopify": "Products / Shopify",
    "menuProductShopifyAdd": "Products / Shopify / Add Product",
    "menuProductShopifyEdit": "Products / Shopify / Edit Product",
    "restrictedByJtExpressTheChinese": "Restricted by \"J&T Express\", the \"Chinese name of the product\" cannot exceed 100; the \"English name of the product\" cannot exceed 200\"; the \"product code\" cannot exceed 50; please modify the product information and re-submit the product",
    "tryingToGetChannelShippingLabel": "Trying to get channel shipping label information, please re-repush later",
    "rangeFieldMessage": "Should be between {min}〜{max}",
    "duringThePromotionPeriod": "During the promotion period (2022-10-09 20: 00-2022-10-10 23: 59), the customer statistics service went offline, and the result will be displayed as 0.",
    "onlySupportedDuringTheBigPromotion": "During the promotion period (2022-12-31 20: 00-2023-01-01 23: 59), only the operation logs of nearly 7 days can be viewed.",
    "onlyViewingTheOperationLogOf": "During the promotion period (2022-12-31 20: 00-2023-01-01 23: 59), only support viewing the operation log of the day",
    "gineeIsPushingInformationToThe": "Ginee is pushing info to the warehouse for you, and waiting for the push results from the third-party warehouse. You can continue to process other orders or stay in the order list to view the push results.",
    "theDistributedWarehouseTaskWasCreated": "The push task has been created successfully",
    "clickToConfirmTheSystemWill": "Click to confirm, the system will call the third-party warehouse interface to push info for you, and the push result needs to wait for the third-party warehouse interface to return. Please be patient.",
    "confirmThatTheSelectedOrderWill": "Confirm that the selected order will be notified to the third-party warehouse for processing?",
    "tier": "Tier",
    "min": "Min",
    "max": "Max",
    "afterConfirmationTheOrderWillBe": "After confirmation, the order will be pushed, please refresh the page later to view the push results",
    "onceThePushFailsPleaseTry": "Once the push fails, please try to push again in Push Warehouse > Push Failed",
    "noteThe": "Note: There is a delay in the channel order status and label update, which may cause the push to fail.",
    "theOrderIs": "The order is being pushed to the third-party warehouse for you, please don't repeat the operation, please open the push warehouse > check the push progress",
    "shipmentFailed": "Shipment Failed",
    "afterAutomaticDeliveryIsEnabledThe": "After automatic delivery is enabled, the orders that meet the delivery conditions will be automatically filled in the delivery information and then delivered. If the delivery information does not meet the delivery conditions, it need to do manual delivery.",
    "defaultFirst": "Default use the first",
    "defaultLast": "Default use the last",
    "pickUpTime": "Pickup Time",
    "automaticShipping": "Automatic Shipping",
    "warehouseToBeAssigned": "Warehouse To Be Assigned",
    "pushWarehouse": "Push Warehouse",
    "tryingToLoad": "Trying to load...",
    "orderTotalTotalProductAmount": "Order total = Total product amount + shipping + tax + insurance + service fee + commission - total discount",
    "onceVoidedTheOrderWillBe": "Once invalid, the order will be moved to Invalid",
    "addedChannelProducts": "Added channel products",
    "syncedChannelProducts": "Synced channel products",
    "syncedChannelProductsChangedFrom": "Synced channel products, {1} changed from {2} to {3}",
    "produkChannelTelahDisinkronkanDanInformasi": "Channel products have been synced, and SKU {} information has been deleted",
    "theChannelProductsAreSynchronizedAnd": "Channel products are synchronized, and the channel returns to the new channel SKU{}",
    "editedProductNameFromTo": "Updated product name from {0} to {1}",
    "editingProductCategoryFromTo": "Editing product category from {1} to {2}",
    "editingLongDesecriptionForVariant": "Updated product Long Description, from {0} to {1}",
    "editingPriceForVariantFrom": "Editing price for variant {0}, from {1} to {2}",
    "editedStockChannelVariantName": "Updated stock channel variant name {0} from {1} to {2}",
    "editedChannelSkuVariantName": "Update channel SKU variant name {0} from {1} to {2}",
    "deletedVariantType": "Deleted variant type {}",
    "addedVariantType": "Added variant type {}",
    "deletedOption": "Delete option {2} under variant type {1}",
    "addedOption": "On variant type {1} added option {2}",
    "updatedProductImage": "Updated product image",
    "updatedVariantImageForVariatName": "Updated variant image for variat name {}",
    "updatedOfProductSizeFrom": "Updated {1} of product size from {2} to {3}",
    "updatedProductWeightChangedFrom": "Updated product weight, changed from {0} to {1}",
    "addTheCheckedLogisticAnd": "Add the checked logistic {1}, and unchecked logistic {2}",
    "deleteChannelProduct": "Delete channel product {}",
    "copyProductToChannelStore": "Copy product {1} to channel {2} store {3}",
    "thisItemIsListed": "This item is listed{}",
    "thisItemHasBeenRemoved": "This product has been delist{}",
    "variationNa": "Variation name {0}, the channel SKU {1}is met the auto bind rule, and it bind to MSKU {2}",
    "variationNameTheChannelSku": "Variation name {0}, the channel SKU {1}is met the automatic creation rule, and it created MSKU {2}",
    "variatio": "Variation name is {0}, channel SKU is {1}, manually bind MSKU{2}",
    "variationNameIsChannelSku": "Variation name is {0}, channel SKU is {1}, manually created MSKU{2}",
    "mskumanuallyUnboundTheCorrespondingChannelSku": "MSKU{1}manually unbound the corresponding channel SKU{2} on the Master Product page",
    "sinceStoreHasBeenDeletedchannel": "Since store {1} has been deleted,\nchannel SKU{2}was unbind from MSKU{3}",
    "sinceMskuHasBeenRemovedchannelSkuwas": "Since MSKU{1} has been deleted,\nchannel SKU{2}was unbind from MSKU{3}",
    "manuallyAddedMasterProduct": "Manually added Master Product",
    "manuallyCreatedMasterProductByStore": "Manually created Master Product by store",
    "manuallyCreatedMasterProductByChannel": "Manually created Master Product by channel product",
    "createdMasterProductByImportData": "Created Master Product by importing data",
    "autoCreateMasterProduct": "Auto create Master Product",
    "createdMasterProductByScrapping": "Created Master Product by scrapping",
    "editedMasterProductNameFrom": "Edited Master Product name from {0} to {1}",
    "editedMasterProductCategoryFrom": "Edited Master Product category from {0} to {1}",
    "editedMasterProductSpuFrom": "Edited Master Product SPU from {0} to {1}",
    "editedMasterProductShortDescriptionFrom": "Edited Master Product Short Description from {0} to {1}",
    "editedTheMasterProductLongDescription": "Edited the Master Product Long Description from {1} to {2}",
    "editedMskuPriceFromTo": "Edited MSKU{0} price from {1} to {2}",
    "editedAvailableStockForMskuFrom": "Edited available stock for MSKU{0} from {1} to {2}",
    "editedBarcodeOfMskuFrom": "Edited MSKU barcode {0} from {1} to {2}",
    "updatedOfMasterProductSize": "Updated {1} of master product size from {2} to {3}",
    "updatedTheWeightOfMasterProduct": "Updated the weight of Master Product from {1} to {2}",
    "updatedAvailableStockTheUpdatedStock": "Updated available stock, the updated stock is {}",
    "deleteMaste": "Delete Master Product {}",
    "deleteMskus": "Delete MSKU{}",
    "printingSpus": "Printing SPU {} barcode",
    "printingMskuBarcode": "Printing MSKU{} barcode",
    "printingBarcode": "Printing barcode {}",
    "mskuHasBeenAddedTo": "MSKU{1} has been added to {2} warehouse",
    "mskuHasBeenAddedAndInbound": "MSKU{1} has been added and inbound to the {2} warehouse.",
    "theAutomaticBindingOfMasterSku": "The automatic binding of Master SKU rule is enabled, the rule is: {}",
    "allStoresEnabledDefaultConfigurationFor": "All stores enabled (default configuration for newly authorized stores)",
    "activateByChanelsChanels": "Activate by chanels, chanels {}",
    "openByStoreStore": "Activate by store, store {}",
    "theAutomaticBindingOfTheMaster": "The automatic binding of the Master SKU rule is turned off.",
    "theAutomaticCreateMasterSkuRule": "The automatic create master SKU rule is turn on, the rule is：{}",
    "turnOffTheAutomaticCreateMaster": "The automatic create master SKU rule is turn off",
    "newPrice": "New price",
    "priceCannotBeModifiedDuringThe": "Price cannot be modified during the promotion period.",
    "thereAreDuplicateExportTasksRemaining": "There are duplicate export tasks, remaining expiration time: {0}s",
    "theSingleUrlCannotBeEmpty": "The label's URL cannot be empty, please push again later",
    "theLogisticsProviderCannotBeEmpty": "The logistics provider cannot be empty, please contact the warehouse service provider to add the logistics provider information",
    "awbNumberCannotBeEmptyPlease": "AWB number cannot be empty, please push again later",
    "syncSuccessfully": "Sync Successfully",
    "syncFailed": "Sync Failed",
    "sourceOfError": "Source of error",
    "channelErrorPleaseContactTheChannel": "Channel error: please contact the channel for troubleshooting",
    "warehouseErrorPleaseContactTheWarehouse": "Warehouse error: please contact the warehouse service provider for troubleshooting",
    "unknownErrorPleaseContactGineeErp": "Unknown error: Please contact Ginee OMS customer service for troubleshooting",
    "recreate": "Re-create",
    "failedRecreate": "Failed recreate",
    "outboun": "Outbound order number: {x} associated order number {y} created successfully; Performer: {z} Outbound warehouse: {s}",
    "outOrderNumberXAssociatedOrder": "Outbound order number: {x} associated order number {y} is successfully recreate; Performer: {z} Outbound warehouse: {s}",
    "orderNumberXAssociatedOrder": "Outbound order number: {x} associated order number {y} failed to recreate",
    "rderNumberXAssociatedOrder": "Outbound order number: {x} associated order number {y} \"Not Pushed\"; Performer: {z} outbound warehouse: {s};",
    "oer": "Outbound order number: {x} associated order number {y} \"Push success\"; Performer: {z} outbound warehouse: {s}",
    "tboundder": "Outbound order number: {x} associated order number {y} \"Push failed\"; Performer: {z} outbound warehouse: {s}; failure reason: {a}",
    "odOrderNumberXAssociatedOrder": "Outbound order number: {x} associated order number {y} \"outbound successful\"; Performer: {z} outbound warehouse: {s}",
    "outboundOrderNumberXAssociatedOrder": "Outbound order number: {x} Associated order number {y} \"refreshed successfully\"; Performer: {z} Outbound warehouse: {s}",
    "inboundOrderNumberXAssociatedOrder": "Inbound order number: {x} associated order number {y} \"refresh failed\"; Performer: {z} outbound warehouse: {s}; Failure reason: {a}",
    "orderwe": "Outbound order number: {x} related order number {y} \"Invalid\"; Performer: {z} outbound warehouse: {s};",
    "outboundOrderNumberXRelatedOrder": "Outbound order number: {x} related order number {y}; SKU: {w} \"Invalid\"; Performer: {z} Outbound warehouse: {s};",
    "outboundOrderNumberXHasBeen": "Outbound order number: {0} has been deleted, associated order {1} Reason for deletion: order has been put on hold",
    "inboundNoXPerformerYInbound": "Inbound No.: {x} Performer: {y}, Inbound Warehouse: {z} Create Inbound List",
    "inboundNoXNotPushedPerformer": "Inbound No.: {x}; \"Not Pushed\"; Performer: {y}, Inbound Warehouse: {z}",
    "inboundNumberXSuccessfulPushPerformer": "Inbound number: {x}; \"Successful push\"; Performer: {y}, Inbound warehouse: {z}",
    "inboundNoXPushFailedPerformer": "Inbound No.: {x}; \"Push Failed\"; Performer: {y}, Inbound Warehouse: {z}; Failure Reason {a}",
    "inboundNoXRefreshSuccessfulPerformer": "Inbound No.: {x}; \"Refresh successful\"; Performer: {y}, Inbound warehouse: {z}",
    "nomorInboundXRefreshGagalPerformer": "Inbound No.: {x}; \"Refresh Failed\"; Performer: {y}, Inbound Warehouse: {z}; Failure Reason: {a}",
    "inboundNoXinvalidperformerYInboundWarehouse": "Inbound No.: {x};\"Invalid\";Performer: {y}, Inbound Warehouse: {z}",
    "inboundNoXskuainvalidperformerYInboundWarehouse": "Inbound No.: {x};SKU:{a};\"Invalid\";Performer: {y}, Inbound Warehouse: {z}",
    "inboundNoXwaitingInboundPerformerY": "Inbound No.: {x};\"Waiting Inbound\"; Performer: {y}, Inbound Warehouse: {z}",
    "receiptNoXinboundPerformerYInbound": "Inbound No.: {x};\"Inbound\"; Performer: {y}, Inbound Warehouse: {z}",
    "push": "Push",
    "productsChannelEditProduct": "Products / {channel} / Edit Product",
    "productsChannelAddProduct": "Products / {channel} / Add Product",
    "dueToPossibleDelaysInSyncing": "Due to possible delays in syncing data, please wait a few seconds after syncing before selecting the brand.",
    "pleaseEnterBrandKeywordsToSync": "Please enter brand keywords to sync",
    "cantFindTheBrandClickMe": "Can't find the brand? click me to sync",
    "outboundCompletionTime": "Outbound completion time",
    "theImageAspectRatioIsInvalid": "The image aspect ratio is invalid, only images with an aspect ratio of {ratio} are supported",
    "theFileWillBeDisplayedSynchronously": "The file will be displayed synchronously in List, and the file will only be retained for 15 days, so please download it in time",
    "exportRecords": "Export History",
    "theFollowingOrdersNeedToBe": "The following orders need to add shipping information before arranging the shipment. Ginee has automatically filtered {x} Shopee orders whose status is still Pending and is not allowed to be arranged shipment",
    "arrangementFailed": "Arrangement failed",
    "arranging": "Arranging",
    "pleaseGoToPendingPrintOrder": "Please go to Pending Print Order to generate Hot Item Shipping Label print task",
    "limitasiApiSkuTidakDapatDi": "API restrictions, SKU's do not support editing",
    "ordersThatAreNotBoundTo": "Orders that are not bound to MSKU will not enter the hot item printing task",
    "customShop": "Custom Shop",
    "continueCreate": "Continue to create",
    "theStoreWasCreatedSuccessfully": "The store was created successfully",
    "warehouseLevelAllPages": "Warehouse Level - All Pages",
    "warehouseLevelClickToCheck": "Warehouse Level - by Selected",
    "locationLevelAllPages": "Shelf Level - All Pages",
    "locationLevelClickToCheck": "locationLevelClickToCheck",
    "inboundManagementoperationLog": "Inbound management/operation log",
    "outboundManagementoperationLog": "Outbound management/operation log",
    "inboundNumberXxxxPushedToWarehouse": "Inbound number: {xxxx} pushed to warehouse: Task {xxxx} failed",
    "reasonTheOrderNumberXxxxHas": "Reason: The order number {xxxx} has met the push conditions",
    "inboundNoXxxxHasBeenSuccessfully": "Inbound number: {xxxx} has been successfully pushed to the warehouse: {XXXX}, and get the warwhouse number {XXXX}: #xxxxx#",
    "inboundNumberXxxxHasSuccessfullyCreated": "Inbound number: {xxxx} has successfully created a task to push to the warehouse: {XXXX}, and waiting for the response from the {XXX} warehouse API",
    "xxWarehouse": "{XX} warehouse",
    "failureReasonXxxxErrorSourceXxxxx": "Failure Reason: {XXXX}, Error Source: {XXXXX}",
    "outboundNumberFailedToPushXxxx": "Outbound number: Failed to push {xxxx} to {XXXX}",
    "outboundNumberXxxxHasBeenSuccessfully": "Outbound number: {xxxx} has been successfully pushed to {XXXX}, and obtained third party warehouse number: {xxxxx}",
    "outboundNumberXxxxHasSuccessfullyCreated": "Outbound number: {xxxx} has successfully created a task to push to the warehouse: {XXXX}, and is waiting for the response from the warehouse API {fulfilment provider}",
    "inboundOrderPushed": "Inbound order pushed",
    "outboundOrderPushed": "Outbound order pushed",
    "sendersFullAddress": "Sender's full address",
    "sendersPostalCode": "Sender's postal code",
    "senderArea": "Sender Area",
    "manualPush": "Manual Push",
    "pendingOrderOldVer": "Pending Order (Old Ver.)",
    "pleaseGoToTheDeliverySettings": "Please go to the shipping settings to fill in.",
    "noCustomStoreHasBeenDetected": "No custom store has been detected. Please create a custom store first.",
    "goToCreate": "Go to create",
    "enableAtLeastOneVariation": "Enable at least one variation.",
    "createdAChannelProductBy": "Created a channel product {3} for store {2} by {1} method",
    "storeSyncedChannelProduct": "Store {1} synced channel product {2}",
    "ChangedFromTo": "{1} changed from {2} to {3}",
    "deletedSkuWithVariantName": "Deleted SKU with variant name {}",
    "addedSkuWithVarianName": "Added SKU with varian name {}",
    "throughStoreDeleted": "Through {1} method , store {2} deleted channel item {3}",
    "deletedVar": "Deleted variant type {}",
    "addedVa": "Added Variant Type {}",
    "updatedVarianImageWithVarianName": "Updated varian image with varian name {}",
    "updatedProductSizeAndWeightInfo": "Updated product size and weight info, {1} changed from {2} to {3}",
    "logisticsUpdatedChangedFrom": "Logistics updated, {1} changed from {2} to {3}",
    "atLeastOneSpecificationIsEnabled": "At least one specification is enabled",
    "addMasterProduct": "Add Master Product",
    "editMasterProduct": "Edit Master Product",
    "autoCreateMasterSku": "Auto Create Master SKU",
    "autoBindMasterSku": "Auto Bind Master SKU",
    "addToWarehouse": "Add to Warehouse",
    "masterProductOperationLog": "Master Product Operation Log",
    "solutionPleaseFirstCheckWhetherThe": "Solution: Please first check whether the store is Official Store or Power Merchant store type. If not, please upgrade the store in the seller center first. If so, please check whether the store integration has been unbound in the seller center.",
    "updatedProductLengthFromTo": "Updated product length from {0} to {1}",
    "updatedProductWidthFromTo": "Updated product width from {0} to {1}",
    "updatedProductHeightFromTo": "Updated product height from {0} to {1}",
    "addedMsku": "Added MSKU{}",
    "onlyIntegersAreSupported": "Only integers are supported",
    "masstSku": "Mass Edit SKU",
    "newOriginalPrice": "New Original Price",
    "newselingPrice": "New Seling Price",
    "applicationCompletion": "Application completion",
    "editPrice": "Edit Price",
    "theStatusOfProductHas": "The status of product {1} has changed from {2} to {3}",
    "theChannelPriceOfMsku": "The channel price of MSKU {1} in store {2} has changed from {3} to {4}",
    "defaultPriceOfMskuChanged": "Default price of MSKU {1} changed from {2} to {3}",
    "solutionPleaseSetThePickupWarehouse": "Solution: Please set the Pickup warehouse information in My account>seller profile>warehouse in the seller center.",
    "afterSuccessfulAuthorizationYouCanUse": "After successful authorization you can use the Keeppack fulfillment service to manage inbound and outbound product; the stock and product information of the Keeppack warehouse will be automatically two-way synchronized to improve management efficiency",
    "clickTheGoToAuthorizationButton": "Click the [Go to Authorization] button, you will visit the Keeppack login page, please enter the account password used to log in to the Keeppack system, and after the Keeppack system confirms that your login information is correct, you will successfully authorize Keeppack to the Ginee system.",
    "beSureToCompleteTheMatching": "Please be sure to complete the mapping of master product in [Warehouse-Keeppack Warehouse-Product Information]",
    "ifThereIsTooMuchCommodity": "If there is too much product data, it will take a long time, please be patient",
    "imAutomaticallyPullingTheKeeppackWarehouse": "Automatically pulling the Keeppack warehouse list and product information for you",
    "automaticallyPullCommodityInformation": "Automatically pull product info",
    "automaticallyPullWarehouseInformationValidWarehouse": "Automatically pull warehouse information (limited to valid warehouses)",
    "senderMobilePhone": "Sender Phone",
    "theBindingRecordIsOnlyValid": "The Binding History is only valid for 30 days, and the binding record beyond 30 days will not be viewable",
    "pullRecord": "Pull Record",
    "batchPulling": "Mass Pull",
    "mskuBinding": "MSKU Binding",
    "bindingMsku": "Binding MSKU",
    "pullTime": "Pull Time",
    "commodityValue": "Product Value",
    "theTotalValueOfGoodsReturned": "The total value of the products returned by the third-party warehouse interface in this warehouse",
    "totalWarehouseInventoryReturnedByThreeparty": "The total warehouse stock returned by the third-party warehouse interface",
    "inventoryWaitingForReceiptReturnedBy": "Stock waiting inbound returned by the third-party warehouse interface",
    "availableInventoryReturnedByThreepartyWarehouse": "Available stock returned by the third-party warehouse interface",
    "gineePushesItToTheWarehouse": "Ginee push to create warehouse",
    "sanfangcangSku": "Third Party Warehouse SKU",
    "nameOfGoodsInTheWarehouse": "The product name in the warehouse returned by the third-party warehouse interface",
    "commodityNameOfWarehouse": "Product Name",
    "mskuIsBound": "MSKU is bound",
    "unboundMsku": "MSKU not bound",
    "startWithAndBeNo": "Start with 0, max 12 digits",
    "noMoreThanEnglishCharacters": "No more than 35 alphabet characters",
    "usedToPushReceiptDocumentsTo": "Used to push the inbound list to Keeppack",
    "xxxWarehousesHaveBeenAddedWith": "Add {XXX} warehouses, warehouse ID: {XXX},{XXX}",
    "OnceTheKeeppackInterfaceUpdates": "2. Once the Keeppack interface updates the warehouse status to Disabled, the warehouse in Ginee will be disabled synchronously, and the product/stock information cannot be synchronized anymore",
    "gineeOnlyReturnsTheWarehouseWith": "1. Ginee only syncs the Keeppack interface for you to return warehouses whose channel status is Active",
    "warmTip": "Tips",
    "startPullingTheLatestWarehouseInformation": "After clicking \"Confirm\", it will start to pull the latest repository information of Keeppack for you, wait for the Keeppack interface to return the information, please be patient",
    "pullWarehouseInformation": "Pull warehouse information",
    "pullLog": "Pull log",
    "warehouseUpdateTimeReturnedByKeeppack": "The update time of the warehouse returned by the Keeppack\ninterface",
    "warehouseCreationTimeReturnedByKeeppack": "The warehouse creation time returned by the Keeppack interface",
    "channelStatus": "Channel status",
    "warehouseId": "Warehouse ID",
    "senderInformation": "Sender Information",
    "automaticCreationOfMainCommodity": "Automatically create master product",
    "automaticUnbindingMsku": "Automatically unbind MSKU",
    "automaticBindingMsku": "Automatically bind MSKU",
    "manuallyCreateTheMainCommodity": "Manually create master product",
    "manuallyUnbindMsku": "Manually Unbind MSKU",
    "manuallyBindMsku": "Manually bind MSKU",
    "startBinding": "Start Binding",
    "andAllUnpairedThreepartyWarehouseSkus": "All current and newly added Keeppack SKU that unpaired",
    "allCurrentlyUnpairedThreepartyWarehouseSkus": "All Keeppack SKUs that are not currently paired",
    "appliedRange": "Range",
    "whenTheThreepartyWarehouseSkuIs": "When the SKU of the third-party warehouse is exactly the same as the MSKU of the main product that has been created, it will be automatically bound",
    "bindingRules": "Binding Rule",
    "reasonItConformsToTheMsku1": "Reason: In line with the product settings - the warehouse product automatically creates MSKU rules",
    "reasonThreepartyWarehouseHasBeenUnbound": "Reason: The third-party warehouse has been unbound",
    "reasonItConformsToTheMsku": "Reason: In line with product settings - warehouse products are automatically bound to MSKU rules",
    "goodsOfWarehouseXxxxxXxxxxSku": "Warehouse: Product of {XXXXX}: {XXXXX}, SKU: {XXXXX} of the third-party warehouse successfully created the master product in the system: {XXXXX}, MSKU: {XXXXX}",
    "commodityXxxxxOfWarehouseXxxxxThreeparty": "Warehouse: Products of {XXXXX}: {XXXXX}, SKU: {XXXXX} and MSKU: {XXXXX} are unbound in the third-party warehouse",
    "commodityXxxxxOfWarehouseXxxxxAnd": "Warehouse: Products of {XXXXX}: {XXXXX}, SKU: {XXXXX} and MSKU: {XXXXX} of the third-party warehouse are successfully bound",
    "viewThePullRecord": "View the pull records",
    "gineeIsPullingCommodityInformationFrom": "Ginee is pulling the product information from the warehouse for you, and waiting for the third-party warehouse interface to return the results, you can continue to process other matters or view the pull progress and results in the pull record",
    "createAPullTask": "Create a pull task",
    "commodityCreationTime": "product creation time",
    "inOrderToImproveThePulling": "In order to improve the pulling efficiency, the duration of a single pulling should not exceed 15 days;",
    "bindingResultXSkusHaveBeen": "Binding result: The automatic binding of {X} SKUs and MSKUs has been successfully completed for you",
    "automaticBindingSucceeded": "Automatic binding succeeded",
    "mskuCreationIsNotSupportedFor": "The third-party warehouse SKU that has bound MSKU does not support the creation of MSKU, the system will automatically filter it for you, please be sure to know",
    "mainProductImage": "Master product image",
    "AfterUnbindingTheStatusOf": "3. After unbinding, the status of the inbound and outbound list corresponding to the MSKU of the product in the third-party warehouse is updated to Invalid.",
    "AfterUnbindingTheInventoryList": "2. After unbinding, the stock data generated by the binding of MSKU to the third-party warehouse product will be cleared.",
    "AfterUnbindingTheBindingRelationship": "1. After unbinding, the binding relationship between the third-party warehouse product and the main product will be released.",
    "systemRecommended": "System recommendation",
    "listingPullFailed": "Failed to pull product information",
    "theListingWasPulledSuccessfully": "Product information pulled successfully",
    "pullResultTheSkuInformationOf": "Pull result: The SKU information of the third party warehouse has been pulled, SKU: XXXX/XXXX",
    "sourceOfErrorRepository": "Source of error: Warehouse",
    "reasonForFailureXxxxxx": "Failed reason: XXXXXX",
    "pullResultAddedXxxPiecesOf": "Pull result: Successfully pulled {XXX} pieces of data",
    "resultcause": "result/reason",
    "pullStatus": "pull status",
    "pullDuration": "Pull duration",
    "noteThatPullRecordsAreOnly": "Please note that the pull record will only be kept in the system for 7 days, and the pull record beyond 7 days cannot be queried",
    "xxxData": "{XXX} data",
    "viewFailureInformation": "View failure information",
    "upToCommoditiesCanBe": "Supports selecting a maximum of 50 products at a time to pull promotions",
    "activityInformationPullFailed": "Failed to pull promotion information",
    "pullResultXxxChannelCommodityActivity": "Pull result: {X} channel product promotion information has been successfully pulled for you",
    "activityInformationPullSucceeded": "Promotion information pulled successfully",
    "onlyTheActivityInformationOfChannel": "Only support to pull the promotion information of channel products that have been synced to Ginee and whose product status is listed",
    "pullByCommodity": "Pull by product",
    "pullByStore": "Pull by store",
    "pullActivity": "Pull Promotion",
    "activeReservationSettings": "Promotion Reservation Settings",
    "synchronizationTime": "Synchronised Time",
    "mskuReserved": "MSKU & Reserved",
    "skuApplication": "SKU & Application",
    "notReserved": "Not Reserved",
    "partialReservation": "Partially Reserved",
    "reserved": "Reserved",
    "activeReserveInventory": "Reserved Promotion Stock",
    "pleaseTurnOnTheInventoryMonitoring": "Please activate [Stock Monitor and Sync] to operate",
    "afterTheSetupIsCompletedThe": "After completing the setting, stock won't be reserved for Upcoming promotion in advance. Ginee will only automatically reserve promotion stock for Ongoing promotions and promotions that will start within 24 hours.",
    "automaticallyReservedOnlyForInprogressActivities": "Automatic Reservations Only For Ongoing Promotions",
    "onceSetUpTheSystemWill": "After completing the setting, both Upcoming and Ongoing (Already started) promotions will automatically reserve the promotion stock",
    "unstartedAndInprogressActivitiesAreAutomatically": "Upcoming and Ongoing Promotions Are Automatically Reserved",
    "beSureToSetAReservation": "Please be sure to set the reservation strategy to ensure that the promotion stock is normally reserved according to your needs;",
    "reservationPolicySettings": "Reservation Strategy Settings",
    "reservationPolicy": "Reservation Strategy",
    "afterItIsEnabledTheSystem1": "After enabling, the system will automatically reserve promotion stock according to platform promotions (Only supports Shopee), which can be viewed in the [Stock - Reserved Promotion Stock] / [Stock - Stock List]",
    "automaticallyReserveActiveInventory": "Automatically Reserve Promotion Stock",
    "afterItIsEnabledTheSystem": "After enabled, the system will automatically pull the Shopee platform promotion information for you, which can be viewed in the [Stock - Reserved Stock] list",
    "automaticallyPullPlatformActivity": "Automatically pull platform promotion",
    "theClosedEventDataSystemIs": "The closed promotion data system will only be kept for 30 days, beyond 30 days, it cannot be viewed in this list",
    "reasonNotReserved": "Reason Not Reserved",
    "releaseTheReservation": "Release Reservation",
    "TheActiveInventoryReservedBy": "3. The reserved promotion stock of MSKU in the warehouse will be updated to 0",
    "AfterReleaseTheChannelActivity1": "2. After the release, the stock applied for channel promotions will not be affected and can still be sold on the platform, with the risk of oversold",
    "AfterReleaseTheChannelActivity": "1. After the release, the channel promotion status will not be affected",
    "confirmTheReleaseOfCurrentlyActive": "Are you sure to release the currently reserved promotion stock?",
    "theActiveInventoryThatWasReserved": "The reserved active stock has been cleared successfully",
    "theActiveReservedInventoryWasEdited": "Reserved Promotion Stock edited successfully",
    "obligate": "reserved",
    "application": "Apply",
    "reservedInventoryEditsAreOnlySupported": "Upcoming promotions can edit reserved stock, including modifying reserved warehouse and reserved stock value",
    "mskuHasNoWarehouseInformationAnd": "MSKU has no warehouse information and cannot be reserved successfully",
    "mskuWithUnboundChannelSkuPlease": "If the channel SKU is not bound to the MSKU, please go to the master product list/channel product list to complete the binding and inbound to the warehouse",
    "automaticReservationOfActiveInventorySetting": "The automatic reserve promotion stock setting is not turned on",
    "commodityXxxMoveoutActivityIdxxx": "Channel Product: {XXX} Removed Promotion ID: {XXX}",
    "xxxManuallyReleaseTheReservedActive": "{XXX} Manually release reserved promotion stock",
    "meetTheActiveInventoryReleaseRules": "In line with active stock release rules, automatic release",
    "complyWithTheRulesOfAutomatic": "In line with the rules of automatic reservation of active stock, automatic reservation",
    "deleteProduct": "Delete product",
    "unbindTheMasterProduct": "Unbind the master product",
    "bindTheMasterProduct": "Bind the master product",
    "editedTheAutoBindMasterSku": "Edited the Auto Bind Master SKU rule, the rule changed from {1} to {2}",
    "editedTheAutoCreateMasterSku": "Edited the auto create Master SKU rule, the rule changed from {1} to {2}",
    "createdMasterProductViaOpenapi": "Created Master Product {0} via Open-API",
    "manuallyCreatedMasterProductFrom": "Manually created Master Product {} from warehouse product",
    "automaticallyCreatedMasterProductFromWarehouse": "Automatically created Master Product{} from warehouse product",
    "commonSearches": "Common Searches",
    "moreSearches": "More Filters",
    "becauseChannelSkuHasBeen": "Because channel SKU {0} has been deleted, the channel SKU {1} is unbound from MSKU {2}",
    "updatedProductPriceFrom": "Updated product price {0} from {1} to {2}",
    "editedStockForProductFrom": "Edited stock for product {0} from {1} to {2}",
    "editedChannelSkuForProduct": "Update channel SKU for product {0} from {1} to {2}",
    "channelProductMatchToAutobind": "Channel product {0} map to auto-bind rule and is bound to MSKU {1}",
    "channelProductMatchesTheAutocreatedRule": "Channel product{0} matches the auto-created rule, and creating MSKU {1}",
    "channelProductIsManuallyBound": "Channel product {0} is manually bound to MSKU {1}",
    "channelProductManuallyCreatedMsku": "Channel product {0} manually created MSKU {1}",
    "distribution": "distribution",
    "removeSelectedLogistics": "Remove selected logistics{}",
    "PickListIsPrinted": "Printed Picking List",
    "printedPacking": "Printed Packing List",
    "InvoiceIsPrinted": "Printed Invoice",
    "printeLabels": "Printed Labels",
    "bopis": "BOPIS",
    "bigProduct": "Big Product",
    "saveSearch": "Save search",
    "commonSearchNames": "Common search names",
    "radio": "Radio",
    "multiplechoice": "mult-choice",
    "updateTheOrderStatus": "Update order status",
    "updatedStockForProductFrom": "Updated stock for product {0} from {1} to {2}",
    "mengeditDeskripsiPanjangMasterProdukDari": "Edited Master Product Long Description from {0} to {1}",
    "updatedAvailableStockForMskuTo": "Updated available stock for MSKU{} to {}",
    "nameManagement": "Name management",
    "nameList": "Name List",
    "ForChannelProductsWithVariations": "2. For channel products with variations, the variation name and product name are used as MSKU.",
    "ChannelProductsWithoutVariationsUse": "1. Channel products without variations use SPU as MSKU.",
    "theSystemDetectsThatSomeSkus": "The system detects that some SKUs of channel products are empty, please confirm whether to enable the system default rules:",
    "updatedProductShortDescriptionFrom": "Updated product Short Description from {0} to {1}",
    "fuzzyOnlySupportsSingle": "Fuzzy only supports single",
    "scanShipment": "Scan and Ship",
    "editMasterProductName": "Edit Master Product Name",
    "editChannelProductName": "Edit channel product name",
    "newProductName": "new product name",
    "utilityTool": "Utilities",
    "TimesScanningIsRequiredFor": "2 times: scanning is required for 2 times. The first scanning displays the order information, and the second scanning record is generated, and the order is moved Waiting for Pickup according to the settings.",
    "TimeScanOnceGenerateA": "1 time: scan once, generate a scan record, and move the order to Waiting for Pickup according to the settings",
    "twice": "Twice",
    "once": "Once",
    "scanResultEffectiveTimes": "Scan result effective times",
    "afterScanningItWillAutomaticallyMove": "After scanning, it will automatically move to Waiting for Pickup",
    "scanSettings": "Scan Settings",
    "scanned": "Scanned",
    "pleaseScanOrEnterTheCourier": "Please scan or enter the AWB/Tracking Number",
    "scanByCourier": "Scan AWB/Tracking Number",
    "afterTheScanIsSuccessfulThe": "After the scan is successful, the order will be automatically moved into the [Waiting for Pickup] list according to the settings. Please make sure that the settings have been completed, and the default setting is unopened.",
    "scanRecord": "Scan Records",
    "scanSucceeded": "Scan succeeded.",
    "xxxxnumberDoesNotExist": "{XXXX}Number does not exist",
    "xxxxxHasBeenScannedPleaseDo": "{XXXXX} has been scanned. Please do not repeat the scan.",
    "xxxxxIsNotASelfownedWarehouse": "{XXXXX} is not a self-owned warehouse fulfillment order, so shipment cannot be scanned.",
    "xxxxxIsNotAnOrderIn": "{XXXXX} is not an order in pending order status, and shipment cannot be scanned.",
    "xxxxxDoesNotExistPleaseCheck": "{XXXXX} does not exist, please check and rescan",
    "scanFailed": "Scan failed.",
    "pleaseScanOrEnterMskubarcode": "Please scan or enter MSKU/Barcode.",
    "useState": "Use state",
    "shelfName": "Shelf name",
    "informationShelf": "Information shelf",
    "modifyShelves": "Modify shelves",
    "commodityDetails": "Commodity details",
    "storageRack": "Shelf",
    "pleaseSelectAWarehouseAndAdd": "Please select a warehouse and add goods.",
    "pleaseChangeToEnInputMethod": "Please change to [EN] input method before scanning",
    "scanResults": "Scan Results",
    "notScanned": "Not Scanned",
    "scanStatus": "Scan status",
    "shelfNumber": "Shelf number",
    "courierNumber": "Tracking Number",
    "sellerCashbackCoin": "Seller Cashback Coin",
    "tax": "Tax",
    "sunTotal": "Subtotal",
    "currentShelf": "Current shelf",
    "vacant": "vacant",
    "inboundAndOutboundRecordAdjustmentMethod": "Inbound and outbound record adjustment method = sum of the manual outbound records (average purchase price * change value).",
    "adjustmentMethodOfInboundAndOutbound": "Adjustment method of inbound and outbound records = sum of the records of sales outbound (average purchase price * change value).",
    "calculationDescriptionAccordingToTheExport": "Calculation description: According to the export template, the order creation time is within the filter time period, and the status = The total of completed orders {XXXX} amount",
    "allScanned": "All Scanned",
    "partiallyScanned": "Partially Scanned",
    "warehousesKeeppackWarehousesListings": "Warehouses / Keeppack warehouses / Listings / Create master products",
    "warehouseKeeppackWarehouseProduct": "Warehouse / Keeppack warehouse / product information",
    "inOrderToImproveThePull": "In order to improve the pull efficiency, the duration of a single pull should not exceed 15 days;",
    "bindingRecordsAreOnlyValidFor": "Binding records are only valid for 30 days, and binding records beyond 30 days cannot be viewed",
    "pullItems": "Pull items",
    "onlyProductsIssuedByGineeAre": "Only products published from Ginee are displayed",
    "synchronizeTimeInDescendingOrder": "Newest synchronize time",
    "synchronizeTimeInAscendingOrder": "Oldest synchronize time",
    "areYouSureUnbindYouWantTo": "Are you sure you want to unbind?",
    "mskuCreationIsNotSupportedForTip": "MSKU creation is not supported for third-party warehouse SKUs bound to MSKUs, and the system will automatically filter them for you",
    "pullTheProduct": "Pull Product",
    "beforeBatchEditingYoudBetterSynchronize": "Before batch editing, you'd better synchronize the products to keep the data consistent with the seller center",
    "syncAndEdit": "Sync & Edit",
    "skipAndEdit": "Skip & Edit",
    "connectWithAllOfYourCustomer": "Connect with all of your customer from one system",
    "makesItEasyToMonitorThe": "Makes it easy to monitor the activities of many warehouses and stock management",
    "goBackToTheList": "Go back to the list",
    "xxxxDoesNotExistPleaseScan": "{XXXX} does not exist. Please scan again after the Master Product is added",
    "theCombinationXxxCannotBePut": "The combination {XXX} cannot be put in the warehouse manually. Please delete it and try again.",
    "theTrackingNumberXxxxWasSuccessfully1": "The Tracking Number {0} was scanned successfully, the scan status of the order {1} was updated to Scanned and moved to Waiting for Pickup",
    "theTrackingNumberXxxxWasSuccessfully": "The tracking number {XXXX} was successfully scanned, and the scan status of the order {XXXX} was updated to Scanned",
    "waitingInbound": "Waiting Inbound",
    "upgrade": "Upgrade",
    "about": "About",
    "pleaseSelectACategoryFirst": "Please select a category first",
    "subAccount": "Sub account",
    "authorizedStores": "Store quota",
    "availableQuantity": "Order quota",
    "addImagesToEachVariantOption": "Add images to each variant option",
    "productInformationDisplayedOnTheLogistics": "Product information displayed on the logistics label (JD/Bukalapak)",
    "tiktokPrintShippingLabelAndPick": "(Tiktok) print shipping label and pick list",
    "downloadDocument": "Download document:{0}",
    "thereAreOrdersThatStillBeing": "There are orders that still being printed. After closing the pop-up window, you can download the shipping label in the print history.",
    "printHistory": "Print History",
    "printAll": "Print All",
    "maximumOrdersCanBePrinted": "Maximum 1,000 orders can be printed at one time, and shipping label PDF is generated for each channel, which takes a long time",
    "unlimited": "unlimited",
    "otherCharges": "Other Fee",
    "bearTheCost": "Payment Fee",
    "authorizationStatusTotalNumberOf": "Authorization status = Total number of marketplace store/webstore that are \"inactive\"",
    "expiredInfo": "Authorization status = Total number of marketplace store/webstore that are \"expired\"",
    "storeStatusOfAllAuthorizedChannels": "Store status of all authorized channels = Total of \"sold out\" products",
    "status_banned_info": "Store status of all authorized channels = Total of \"banned\" products",
    "theSumOfTheNumberOf": "The sum of the number of products that failed to be published to the channel in all authorized channel stores",
    "stockListPageNotInboundTab": "MSKU quantity in [Stock list] page and [Not Inbound] tab",
    "theNumberOfMskusThatHave": "The number of MSKUs that have been added to the warehouse and have not been created to increase stock",
    "stockListPageStockWarningTab": "MSKU quantity in [Stock List] page and  [Stock Warning] tab",
    "totalMskuWhichTheAvailableStock": "Total MSKU which the available stock is less than safety stock",
    "totalMskuOfStockListPage": "Total MSKU of [Stock List] page in [Sold Out] tab",
    "totalMskusWithAvailableStock": "Total MSKUs with 0 available stock",
    "TotalOrderOf": "Total order of [Return Management - Return & Refund] in [Pending] tab",
    "returnRequestInfo": "Shopee/Lazada/Tiktok orders that require the seller to handle when the buyer submit a return application",
    "totalOrderOfReturnManagement": "Total order of [Return Management - Refund List] in [Pending] tab",
    "shopeelazadatiktokOrdersThatRequireTheSeller": "Shopee/Lazada/Tiktok orders that require the seller to handle when the buyer initiates a refund request",
    "ordersThatHaveBeenPushed": "Total order of [Pending-Push Warehouse] page in [Push Failed] tab, please be sure to give priority to processing",
    "ordersThatHaveBeenPushedTo": "Orders that have been pushed to the third-party warehouse manually or automatically by the system, but the third-party warehouse API returns a failure result",
    "totalOrderOfPendingpushWarehousePage": "Total order of [Pending-Push Warehouse] page in [Pending Push] tab, please be sure to push it on time",
    "ordersThatNeedToBePushed": "Orders that need to be pushed to the third-party warehouse to fulfil, but have not yet been pushed",
    "totalOrderOnAbnormalOrdersPage": "Total order on [Abnormal orders] page, if there is related data, please be sure to manually synchronize the order",
    "ordersWithIncompleteInformationDueTo": "Orders with incomplete information due to QPS restrictions on the channel API",
    "theNumberOfOrdersInThe": "Total order of【Pending Print Order】page in 【Printable】tab, can be quickly and uniformly processed",
    "allPrintableShippingLabelsAreDisplayed": "All order which shipping label are printable will be displayed in this list",
    "toBeProcessedArrangeShipment": "[Pending - Arrange Shipment] The sum of the order quantity in each tab of the page can be prioritized and unified for multi-channel and multi-store delivery",
    "allShippableOrdersAreDisplayedIn": "All shippable orders are displayed in this list",
    "pendingproblemOrdersTheSumOfThe": "Total order in [Pending-Problem Order] tab, please be sure to give priority to processing",
    "whenTheOutboundListCannotBe": "When the outbound order cannot be created or the MSKU is out of stock, it will be defined as a problem order",
    "xxxxThereIsMoreThanOne": "{XXXX} There is more than one item in the system, and there are duplicates between MSKU and Barcode. Please check and try again.",
    "servicePH": "Click And Contact With Us",
    "serviceCN": "Your dedicated advisor  wechat:Ginee888",
    "serviceTH": "Group Ginee-Thailand",
    "serviceVN": "Zalo Tutorial Group",
    "serviceMYandID": "Ginee Support Group",
    "accountInfo": "Account Info",
    "refundApplication": "Refund application",
    "returnRequest": "Return Request",
    "orderManagement": "Order Management",
    "sumOfTheNumberOfShopeelazada": "Sum of the number of Shopee/Lazada stores with authorization status=\"authorized\" and the remaining time of authorization validity is less than 30 days",
    "shopeebukalapaktokopediaOrdersUseTheGineeTemplate": "Shopee/Bukalapak/Tokopedia orders use the Ginee template to print shipping labels, and other marketplaces currently only support print shipping labels using marketplace template",
    "highSpeedPrintTaskDetails": "High Speed Print Task Details",
    "printTaskDetails": "Print Task Details",
    "regularPrint": "Regular Print",
    "printUnlimitedShippingLabelInSingle": "Print unlimited shipping label in single prints, but it does not support print preview",
    "clickHereToViewDetailedInstructions": "Click here to view detailed instructions",
    "inOrderToImprovePrintingSpeed": "In order to improve printing speed and experience, it is recommended to use high-speed printing,",
    "itIsDetectedThatYouHave": "It is detected that you have select more than 300 orders, and currently it is not support to print using Ginee shipping label template",
    "thePrintingTaskHasExpiredPlease": "The printing task has expired, please go to [Nearly 90 days order > historical order] to check the printing record",
    "imageSizeLengthLimit": "Multiple images can be uploaded at once . At least {width} x {height} pix,up to {size}MB（up to {maxLength}photos)",
    "imageManagement": "Image Management",
    "noMskuInTheDocumentHas": "No MSKU in the document has inventory variance, so there is no need to generate an adjustment document.",
    "inventoryHasNotBeenCompletedPlease": "Stocktaking has not been completed. Please give finish stocktaking first before do the operation",
    "stocktakingNumber": "Stocktaking number",
    "checkingStock": "Checking Stock",
    "waitingStocktaking": "Waiting Stocktaking",
    "importStockTakingResult": "Import Stocktaking Result",
    "mskuNumber": "MSKU number",
    "stocktakingType": "Stocktaking Type",
    "numberOfMskuWithCountingDifferences": "MSKU difference amount",
    "numberOfDifferences": "Difference number",
    "generateInventoryAdjustmentDocument": "Generate Stock Adjustment List",
    "pleaseCheckTheSpecificInformationIn": "Please check the specific information in [Warehouse-Stock Adjustment List], make manual adjustment, and update MSKU stock according to the stocktaking result.",
    "inventoryAdjustmentDocGeneratedSuccessfully": "Inventory adjustment doc generated successfully.",
    "voidSuccessfully": "Void successfully.",
    "afterVoidingItIsNotSupported": "After invalid, it is not supported to continue stocktaking, and the counted MSKU does not support to generate a stock adjustment list to adjust stock.",
    "AfterTheCountingTheSystem": "3. After stocktaking, the system will automatically check the items that failed to outbound during the stocktaking period for you, The actual order has been shipped/cancelled and the sales outbound order is automatically issued/voided, the system will correct the MSKU stock",
    "TheMskuInventoryThatHas": "2. The MSKU for stocktaking that has not been registered will not be updated, and the MSKU stocktaking result will be updated to \"haven't stocktaken\". Please be careful.",
    "AfterTheInventoryIsFinished": "1. After the stocktaking is finished, the status of the stocktaking list will be changed to complete, and the operation cannot be continued.",
    "confirmTheEndOfTheInventory": "Confirm the end of stocktaking?",
    "theCurrentTaskIsStillBeing": "The current task is still being created, please operate later",
    "clickStartCountingAndTheStatus": "Click [start counting], and the status of stocktaking will be updated to [counting], and the counting operation page will be automatically opened for you.",
    "printInventoryList": "Print stocktaking list",
    "endInventory": "Finish Stocktaking",
    "startCounting": "Start stocktaking",
    "inventoryByMsku": "Checking By MSKU",
    "wholeWarehouseInventory": "Checking By Warehouse",
    "automaticallyFilterMskuXCombinationsAnd": "MSKU [ {bundleSku} ] have been automatically filtered, and MSKU [ {countingSKU} ] are still waiting for stocktaking in the unfinished stocktaking task, and MSKU [ {canCountSKU} ] can be stocktaken this time",
    "aTotalOfXMskuCan": "A total of {X} MSKU available for stocktaking this time.",
    "createInventoryTask": "Create Stocktaking List",
    "aTotalOfXMskuAre": "A total of {count} MSKU are waiting for stoktaking this time, and the stocktaking list is being applied for. Please wait and move to the stocktaking list to continue the operation.",
    "inventoryCreationTaskSubmittedSuccessfully": "stocktaking creation task submitted successfully.",
    "afterConfirmationTheFilledInformationWill": "After confirmation, the filled information will not be saved.",
    "areYouSureYouDontWant": "Are you sure you don't want to save the current information?",
    "currentWarehouseInventory": "Current warehouse stock",
    "inventoryIsNotSupportedForCombination": "Combination products and MSKUs in unfinished stocktaking tasks do not support waiting for stocktaking",
    "mskuDetails": "MSKU details",
    "inventory": "Warehouse",
    "afterSavingTheInformationWillOnly": "After saving, the information will only be saved in the stocktaking list, and it can be modified again.",
    "inventoryLoss": "Stock Loss",
    "inventoryProfit": "Stock increase",
    "panping": "Stock Match",
    "inventoryResults": "stocktaking results",
    "takeStockInTheWarehouse": "Stock counting",
    "takeStockOf": "Stocktaking",
    "counting": "Stocktaking amount",
    "inventorySheet": "Stocktaking Number",
    "notCounted": "Haven't stocktaken",
    "TheCurrentAdjustmentDocumentStatus": "3. The current adjustment document status is updated to [Adjusting]",
    "TheCurrentMskuAdjustmentStatus1": "3. The current MSKU adjustment status is updated to Unadjusted.",
    "TheSystemWillNotChange": "2. The system will not change the current MSKU warehouse inventory.",
    "OnceItIsConfirmedThat": "1. Once it is confirmed that no adjustment is needed, it cannot be operated again.",
    "confirmThatTheCurrentMskuInventory": "Confirm that the current MSKU inventory does not need to be adjusted?",
    "TheCurrentMskuAdjustmentStatus": "3. The current MSKU adjustment status is updated to [Completed]",
    "withoutAdjustment": "Without adjustment",
    "confirmAdjustment": "Confirm adjustment",
    "unadjusted": "Unadjusted",
    "singleAdjustment": "Mass adjustment",
    "continueToAdjust": "Continue to adjust",
    "startAdjusting": "Start adjusting",
    "inventoryReductionMsku": "MSKU Stock Reduction",
    "increaseInventoryMsku": "MSKU Stock Increasement",
    "totalMsku": "Total MSKU",
    "adjusting": "Adjusting",
    "toBeAdjusted": "Waiting Adjustment",
    "adjustTheSingleNumber": "Adjustment number",
    "afterItIsTurnedOnMsku": "After it is turned on, MSKU does not support inventory increase and decrease during stocktaking, which will not affect stock locking. Please be sure to know.",
    "mskuDuringTheCountingPeriodDoes": "MSKU during the stocktaking period does not support inbound/outbound operation.",
    "afterAdjustment": "After adjustment",
    "beforeAdjustment": "Before adjustment",
    "inventoryVariance": "Inventory variance",
    "stockAdjustment": "Stock Adjustment",
    "continueCounting": "Continue stocktaking",
    "confirmIntegrations": "Confirm Integrations",
    "mskuXDoesNotExistIn": "MSKU {X} does not exist in the {X} warehouse, please add MSKU to the warehouse first",
    "orderXxxxxStatusChangedToShipping": "Order {XXXXX} status changed to Shipping on {xxxx-xx-xx: xx}.",
    "xxxAfterTheInventoryCountIs": "{XXX} finish the stocktaking, MSKU can be operated for inbound and outbound, and the stock value will be automatically corrected according to the change of the order status",
    "xxxGeneratesTheInventoryAdjustmentDocument": "{XXX} generates the inventory adjustment document number: {XXXX}, and the counting result: stock loss.",
    "xxxGenerateTheInventoryAdjustmentDocument": "{XXX} Generate the Stocktaking adjustment list number: {XXXX}, and the stocktaking result: stock is in a surplus",
    "theCurrentRepositoryIsDeactivatedPlease": "The current repository is deactivated, please do so after the repository list is enabled",
    "theCurrentWarehouseIsNotIts": "The current warehouse is not its own warehouse and cannot stocktaking",
    "theCurrentRepositoryDoesNotExist": "The current repository does not exist, please do so after adding the repository list",
    "theInventoryHasBeenTaken": "Complete",
    "AreYouSureToDelete": "Are you sure to delete the current information?",
    "defaultShelf": "Default shelf",
    "AreYouSureToEnd": "Are you sure to end the stocktaking?",
    "mskuInInventoryExistsInThe": "There are MSKUs for stocktaking in the current list, please inbound directly after finishing the stocktaking",
    "thereIsMskuInCountingIn": "There are MSKUs for stocktaking in the current list, please inbound or outbound directly after finishing the stocktaking",
    "AreYouSureToVoid": "Are you sure to void the current information?",
    "keywordid": "Keyword",
    "onceTheAdjustmentIsConfirmedIt": "Once the adjustment is confirmed, it cannot be suspended.",
    "atTheBeginningOfAdjustmentThe": "At the beginning of adjustment, the system will automatically update the MSKU warehouse inventory to the adjusted inventory value.",
    "addedInventoryList": "Add stocktaking list",
    "noMoreThanMaxCharacters": "No more than {max} characters",
    "confirmAndUpdateMskuInventory": "Confirm and update MSKU inventory?",
    "areYouSureNotToSave": "Are you sure not to save the current information?",
    "createAnInventoryTask": "Create Stocktaking Task",
    "countingStatus": "Stocktaking Status",
    "counted": "Done",
    "saving": "Saving...",
    "areYouCancel": "Are you sure you want to cancel?",
    "notCount": "The current document is not in waiting stocktaking and cannot be operated. Please refresh the page and try again.",
    "shouldBeBetweenXToX": "Should be between X to X",
    "offlineOrder": "Offline order",
    "duringTheUnboundPeriodTheOutbound": "During the unbound period, the outbound order cannot be generated to lock/deduct stock and stock synchronization cannot be performed",
    "tokocabangStockChannelInterfaceDoesNot": "Please do not bind Tokopedia O2O warehouse with Ginee WMS/third-party warehouse, otherwise it will cause duplicate shipments",
    "disabledChannelWarehousesCannotUpdateStock": "Disabled channel warehouses cannot update stock due to channel interface restrictions;",
    "pleaseBeSureToCompleteThe1": "Please be sure to complete the one-to-one binding between the channel warehouse and the Ginee warehouse to realize order division and stock synchronization;",
    "systemWarehouseAddress": "System warehouse address",
    "systemWarehouseName": "System warehouse name",
    "channelWarehouseAddress": "Channel warehouse address",
    "channelWarehouseName": "Channel warehouse name",
    "channelWarehouseManagement": "Channel Warehouse Management",
    "upgradePackage": "Upgrade Package",
    "askCustomerService": "Ask Customer Service",
    "theCurrentStoreHasOpenedThe": "The current store has opened the channel multi-warehouse function, please upgrade to a package that already supports the multi-warehouse management function before operating",
    "storeAuthorizationFailed": "Store authorization failed",
    "youCanDirectlyAddProductsIn": "You can directly add products in the system, process orders, or open stock synchronization to experience one-click management of multi-channel stock functions",
    "pleaseBeSureToCompleteThe": "Please be sure to complete the binding of channel warehouse and Ginee warehouse immediately, otherwise, you will not be able to process orders/synchronize stock",
    "theStoreHasOpenedTheChannel": "The store has opened the channel multi-warehouse service and is using the stock synchronization service",
    "authorizing": "Authorizing",
    "multiWarehouse": "Multi Warehouse",
    "theGineeSystemReportsAnError": "The Ginee system reports an error, the reason is as follows: {XXXXX}",
    "theChannelInterfaceReportsAnError": "The channel interface reports an error, the reason is as follows: {XXXXX}",
    "failedToPullChannelWarehouseInformation": "Failed to pull channel warehouse information",
    "deactivateWarehouse": "Disable warehouse",
    "addWarehouse": "Add warehouse",
    "channelWarehouseInformationPulledSuccessfully": "Channel warehouse information pulled successfully",
    "pullTheChannelWarehouseAddXxx": "Pull the channel warehouse, add {AAA}, disable {BBB}",
    "channelWarehouseXxxBindingWarehouseIs": "The bound warehouse of channel warehouse {AAA} is updated from {BBB} to {CCC}",
    "theChannelWarehouseXxxIsBound": "The channel warehouse {AAA} is bound to the system warehouse {BBB}",
    "updateWarehouse": "update warehouse",
    "pleaseNoteThatTheOperationLog": "Please note that the operation log will only be kept in the system for 30 days, and the pull records beyond 30 days cannot be queried",
    "thereIsAnUnfinishedSalesDelivery": "There is an unfinished sales delivery order in the current warehouse, and the binding relationship cannot be updated",
    "OrdersAllocated": "1. Orders that have been allocated to channel warehouses will be automatically allocated to mapped warehouses according to the new binding relationship, no need to set warehouse allocation rules",
    "areYouSureToUpdateThe": "Are you sure to update the bound warehouse?",
    "OrdersThatHaveBeenAllocated": "1. Orders that have been allocated to channel warehouses will be automatically allocated to mapping warehouses according to the binding relationship, no need to set warehouse allocation rules",
    "areYouSureToBindThe": "Are you sure to bind the current warehouse?",
    "ownWarehouse": "Own Warehouse",
    "warehouseMarketplace": "Channel Warehouse",
    "TheStatusOfTheCurrent": "3. The status of the current adjustment document is updated to Completed.",
    "lastSaleDateTips": "The creation date of the latest order for the current product",
    "notification74": "The total amount of orders (including freight, etc.) of valid orders during the time period that meets the slow-sales conditions",
    "notification72": "The number of current products sold in a valid order during the time period that meets the slow-sales conditions",
    "salesVolume": "Sales volume",
    "notification70": "The total number of buyers in all orders during the time period that meets the slow-sales conditions, and the number of buyers is deduplicated according to the mobile phone number",
    "totalNumberOfBuyers": "Total number of buyers",
    "validOrder": "Valid order",
    "notification66": "The total number of orders in the time period that meets the slow-sales conditions, including all statuses",
    "totalNumberOfOrders": "Total number of orders",
    "notification64": "The creation date of the latest order for the current product",
    "lastSaleDate": "Last sale date",
    "notification58": "Only show products with Inventory SKU",
    "notification55": "Products sold in {day} days less than {number}",
    "notification54": "Before using the slow sales statistics table, the slow sales condition setting must be completed first",
    "slowSalesSettings": "Slow sales settings",
    "commodityCategory": "Commodity category",
    "slowSalesStatisticsTable": "Slow Sales Statistics Table",
    "notification34": "File generation failed, please try again later",
    "notification33": "The file will be displayed in the Export History List, and will only be kept for 15 days, please download in time",
    "notification32": "The file has been generated, please click to download",
    "generatingFilePleaseWait": "Generating File, Please Wait",
    "replenishStock": "Replenish Stock",
    "unableEstimate": "Unable to Estimate",
    "estimatedDaysTips": "(Available Stock-safety stock)/daily sales quantity, the remaining days to reach safety stock estimated according to the daily average sales quantity,If the daily sales quantity is less than 1, then 1 will be used for the Estimation",
    "estimatedDays": "Estimated date",
    "avgQtySoldTips": "Quantity sold/sales period, average daily sales inventory",
    "avgQtySold": "Daily sales quantity",
    "qtySoldTips": "The total number of inventories sold out of the current inventory SKU in the selected sales period",
    "qtySold": "Quantity sold",
    "safetyStockTips": "The safety stock quantity of the current inventory SKU, safety stock is consistent with the inventory management list",
    "availableStockTips": "The Available Stock quantity of the current inventory SKU, Available Stock is consistent with in the inventory management list",
    "inventoryName": "Inventory Name",
    "inventorySKU": "MSKU",
    "notification151": "Learn How To Use Safety Stock",
    "days90": "90 days",
    "days60": "60 days",
    "days30": "30 days",
    "days15": "15 days",
    "days7": "7 days",
    "salesPeriod": "Sales Period",
    "outOfStockEstimation": "Out of Stock Estimation",
    "profitTips": "Note: Income Statement only counts Delivered orders and supports filter by time max. 30 day.",
    "commissionSettingTips": "Note: Now support to set store commission from binded Tokopedia and Shopify store, this data is only used for income data statistics, the data of the other channel commissions are based on the channel API",
    "netProfitIncomeStatementTips2": "Net Profit/Income Statement=Operating Income + Non-Operating Income - Operating Costs - Non-Operating Expenses - Other Expenses",
    "netProfitIncomeStatementTips": "Operating Income + Non-Operating Income - Operating Costs - Non-Operating Expenses - Other Expenses",
    "netProfitIncomeStatement": "Net Profit/Income Statement",
    "outboundStockTips": "Inbound Order Record Date = Outbound stock quantity * COGS Average Price on the Selected Date",
    "outboundStock": "Outbound Stock",
    "refundFeeTips": "Order Create Date = Sum of Return Refund Amount on the Selected Date",
    "refundFee": "Refund Fee",
    "cashbackTips": "Order Create Date = Sum of Cashback on the Selected Date",
    "cashback": "Cashback",
    "commissionFeeTips": "Order Create Date = Sum of Commission Fee on the Selected Date",
    "commissionFee": "Commission",
    "serviceFeeTips": "Order Create Date = Sum of Service Fee on the Selected Date",
    "serviceFee": "Service Fee",
    "otherExpensesTips": "Other Expenses = Service Fee + Commission Fee + Cashback + Refund Fee + Outbound Stock",
    "otherExpenses": "Other Expenses",
    "taxTips": "Order Create Date = Sum of Tax on the Selected Date",
    "insuranceTips": "Order Create Date = Sum of Insurance Fee on the Selected Date",
    "insurance": "Insurance",
    "nonOperatingExpensesTips": "Non-Operating Expenses = Insurance + Tax",
    "nonOperatingExpenses": "Non-Operating Expenses",
    "costOfGoodSoldTips": "Outbound Order Record Date = Outbound stock quantity * COGS Average Price on the Selected Date",
    "costOfGoodSold": "Cost of Good Sold (COGS)",
    "operatingExpenseTips": "Operating Expense = Cost of Good Sold (COGS)",
    "operatingExpense": "Operating Expense",
    "inboundStockTips": "Inbound Order Record Date = Inbound stock quantity * Cost Price on the Selected Date",
    "inboundStock": "Inbound Stock",
    "rebateTips": "Order Create Date = Sum of Rebate on the Selected Date",
    "rebate": "Rebate",
    "nonOperatingIncomeTips": "Non-Operating Income = Shipping Fee",
    "nonOperatingIncome": "Non-Operating Income",
    "coinTips": "Order Create Date = Sum of Coin on the Selected Date",
    "coin": "Coin",
    "promotionTips": "Order Create Date = Sum of Voucher amount on the Selected Date",
    "promotion": "Promotion",
    "discountTips": "Order Create Date = Sum of Promotion on the Selected Date",
    "discount": "Discount",
    "salesAmountTips": "Order Create Time = Sum of Amount and Subtotal on the Selected date",
    "salesAmount": "Sales Amount",
    "operatingIncomeTips": "Income = Sales Amount-Discount-Promotion+Coins + Rebate",
    "operatingIncome": "Operating Income",
    "incomeStatement": "Income Statement",
    "refundOrdersTips": "The total number of orders with return status",
    "validBuyersTips": "The total number of buyers for valid orders",
    "buyersTips": "The total number of buyers for valid orders",
    "salesRateTips": "Current product sales as a percentage of total sales",
    "grossSalesTips": "Total amount of money per order in a valid order, including shipping fee and other",
    "qtySalesRateTips": "1. Product Sales Statistics: single/total product sales\n2. Category Sales Statistics: single/total category sales\n3. Store Sales Statistics: single/total store sales\n4. Channel  Sales Statistics: single/total channel sales",
    "qtySalesTips": "Total Goods Sold in Valid Orders",
    "validOrdersTips": "Paid/Ready To Ship/Shipping/Delivered Orders",
    "totalOrdersTips": "The total number of products corresponding to the order",
    "refundOrders": "Refund Orders",
    "validBuyers": "Valid Buyer",
    "buyers": "Buyer",
    "salesRate": "Sales (%)",
    "grossSales": "Gross Income",
    "qtySalesRate": "Qty Sales (%)",
    "qtySales": "Qty Sales",
    "validOrders": "Valid Orders",
    "validOrderNumStatisticsTips": "Paid/Ready To Ship/Shipping/Delivered Orders",
    "totalProductNumStatisticsTips": "The total number of products contained in all orders, duplicated data according to the store + product name     If 2 products of the same product are purchased at the same time in an order, the total number of products will be 1",
    "totalValidBuyerTips": "The total number of buyers in a valid order, the buyer has been duplicated according to the same phone number",
    "shopNameTips": "The name of the store to which the product belongs",
    "mskuTips": "Has been synchronized to the master SKU information corresponding to the variation  under the product in the order, and will not change according to product information updates",
    "skuTips": "Has been synchronized to the SKU information corresponding to the variation under the product in the order, and will not change according to product information updates",
    "variationTips": "Has been synchronized to the variation name under the product in the order, and will not change according to product information updates",
    "productNameTips": "The name of the product in the order synced to Ginee",
    "totalValidBuyerStatisticsTips": "The total number of buyers in a valid order, the buyer has been duplicated according to the same phone number",
    "totalQtySoldStatisticsTips": "The total number of products sold in a valid order    If two products of the same product are purchased at the same time in one order, the total sales volume will be 2",
    "searchPlaceholder": "Please enter content",
    "pleaseSelectCategory": "Please select category",
    "productVariantsSalesStatistics": "Variation Sales Statistics",
    "action73": "Action",
    "datesPerPage": "Dates per page",
    "setPage": "Set page",
    "time69": "Time",
    "notification68": "Tips: Please set the range of data pages to download",
    "variationName": "Variation Name",
    "fieldDescription": "Field Description",
    "detial": "Detail",
    "action61": "Action",
    "downloadFailed": "Download failed",
    "active": "Active",
    "notification49": "The file will be displayed in the Export History List, and will only be kept for 15 days, please download in time",
    "returnRateTips": "Total Orders Returned / All Orders",
    "returnRate": "Return (%)",
    "refundOrderNumTips": "The total number of orders with return status",
    "refundOrderNum": "Refund Orders",
    "repurchaseRateTips": "Percentage of Customers more than 1 Transaction",
    "repurchaseRate": "Repurchase (%)",
    "grossSalesBuyer": "Gross Sales/ Buyer",
    "customerAverageTransaction": "Customer Average Transaction",
    "buyerNumTips": "The total number of buyers for valid orders",
    "buyerNum": "Valid Buyer",
    "totalBuyerNumTips": "The total number of buyers for orders",
    "totalBuyerNum": "Total Buyer",
    "saleRateTips": "1. Product Sales Statistics: single/total product gross sales\n2. Category Sales Statistics: single/total category gross sales\n3. Store Sales Statistics: single/total store gross sales\n4. Channel Sales Statistics: single/total channel gross sales",
    "saleRate": "Sales (%)",
    "grossIncomeTips": "Total amount of money per order in a valid order, including shipping fee and other fields",
    "grossIncome": "Gross Income",
    "qtySaleRateTips": "1. Product Sales Statistics: single/total product sales\n2. Category Sales Statistics: single/total category sales\n3. Store Sales Statistics: single/total store sales\n4. Channel Sales Statistics: single/total channel sales",
    "qtySaleRate": "Qty Sales (%)",
    "qtySaleNumTips": "Total Goods Sold in Valid Orders",
    "qtySaleNum": "Qty Sales",
    "effectiveRateTips": "Valid Orders / Total Orders",
    "effectiveRate": "Effective (%)",
    "validOrderNumTips": "Paid/Ready To Ship/Shipping/Delivered Orders",
    "validOrderNum": "Valid Orders",
    "totalOrderNumTips": "The total number of products corresponding to the order",
    "totalOrderNum": "Total Orders",
    "totalProductNumTips": "live/disabled/sold out/banned Products",
    "totalProductNum": "Total Products",
    "totalValidBuyer": "Total Valid Buyer",
    "netIncomeTips": "Sum of valid order product original price",
    "netIncome": "Product Original Price Income",
    "notification15": "The Qty sold of the valid orders",
    "totalQtySold": "Total Qty Sold",
    "lowToHigh": "Low to high",
    "highToLow": "High to low",
    "storeSaleReport": "Store Sale Report",
    "channelSaleReport": "Channel Sale Report",
    "revenuePerBuyerTips": "Total Order Amount/Number of Customers",
    "revenuePerBuyer": "Customer Average Transaction",
    "monetaryUnit": "Currency",
    "productOriginalPriceIncome": "Product Original Price Income: Sum of valid order product original price; Gross Income: Total amount of money per order in a valid order, including shipping fee and other fields",
    "totalNetIncomeTips": "Sum of valid order product original price",
    "totalNetIncome": "Product Original Price Income",
    "exportPdf": "Export to PDF",
    "exportToPDF": "Export to PDF",
    "countTips": "Sales Quantity cannot be less than 0",
    "selectTime": "Select Time",
    "exportExcel": "Export to Excel",
    "exportTime": "Export Time",
    "exportHistory": "Export History",
    "title": "Title",
    "moveEnd": "End",
    "moveTop": "Top",
    "moveDown": "Move down",
    "moveUp": "Move up",
    "customField": "Custom Field",
    "taxTotalAmountTips": "The sum of taxes amount in orders created on the current date",
    "taxTotalAmount": "Total Taxes Amount",
    "systemShippingTotalAmountTips": "The sum of shipping fee by system amount in the order created on the current date",
    "systemShippingTotalAmount": "Total Shipping Fee by System Amount",
    "serviceTotalAmountTips": "The sum of service fees within the order created on the current date",
    "serviceTotalAmount": "Total Service Fee Amount",
    "commissionTotalAmountTips": "The sum of commissions in orders created on the current date",
    "commissionTotalAmount": "Total Commission Amount",
    "insuranceTotalAmountTips": "The sum of insurance in the order created on the current date",
    "insuranceTotalAmount": "Total Insurance Amount",
    "buyerTotalNumTips": "The total number of customers in the order created on the current date (remove by phone number)",
    "buyerTotalNum": "Number of Customers",
    "cancelOrderRateTips": "Cancelled orders / total number of orders",
    "cancelOrderRate": "Cancellation Rate",
    "cancelOrderTotalAmountTips": "The total amount of orders cancelled on the current date (including shipping/discounts, etc.), Partially cancelled order amount is not included",
    "cancelOrderTotalAmount": "Total Amount Cancelled",
    "cancelOrderTotalNumTips": "The total number of orders cancelled on the current date. Partially cancelled order is not included",
    "cancelOrderTotalNum": "Cancel Order",
    "returnOrderRateTips": "Return orders / total number of orders",
    "returnOrderRate": "Return Rate",
    "returnOrderTotalAmountTips": "The total amount of the order (including shipping/discount, etc.) of the return status which create date is equal to the current date, partially returned amount is not counted",
    "returnOrderTotalAmount": "Total Refund",
    "returnOrderTotalNumTips": "The total number of orders that have been successfully returned on the current date, partially returned is not counted",
    "returnOrderTotalNum": "Return Order",
    "paymentTotalAmountTips": "Paid/Ready to Ship/Shipping/Delivered orders which create date is equalto the current date (including shipping fee, etc.)",
    "paymentTotalAmount": "Total Valid Order Amount",
    "paymentTotalNumTips": "The total number of Paid/Ready to Ship/Shipping/Delivered orders which create date is equal to the current date",
    "paymentTotalNum": "Valid Order",
    "shippingTotalAmountTips": "The total amount of shipping fee in the order created on the current date",
    "shippingTotalAmount": "Shipping Fee",
    "goodsTotalAmountTips": "The total amount of products in the order created on the current date (excluding shipping/discounts, etc.)",
    "goodsTotalAmount": "Total Product Amount",
    "orderTotalAmountTips": "The total amount of orders created on the current date (including shipping/discounts, etc.)",
    "orderTotalAmount": "Total Order Amount",
    "orderTotalNumTips": "The total number of orders in the time period that meets the slow-sales conditions, including all statuses",
    "orderTotalNum": "Total Order Number",
    "dayTips": "Order Create Date",
    "yesterday": "Yesterday",
    "totalRefundAmountTips": "Return date equals to total amount of the returned in the current date The amount of partial returned orders (involving Lazada) is not included",
    "totalRefundAmount": "Total Refund Amount",
    "returnOrderTips": "The total number of return orders that is filtered",
    "shippingFeeTips": "Create date equals to the total amount of shipping fee in the order on the current date (the total shipping fee in the order details)",
    "shippingFee": "Shipping Fee",
    "totalOrderAmountTips": "Create date equals to the total amount of the order on the current date (including shipping fee, etc.)",
    "totalOrderAmount": "Total Order Amount",
    "totalOrderNumberTips": "Create date equals to total number of orders on the current date (all order status)",
    "totalOrderNumber": "Total Order Number",
    "trendChart": "Trend Chart",
    "theSizeVariantDoesNotSupport": "The \"Size\" variant does not support adding images",
    "onlyShowAuthorizedStores": "Only show authorized stores",
    "untickWithOneClick": "Untick with one click",
    "pleaseUntickAndReoperate": "Please untick and re-operate",
    "thereAreXExpireddeactivatedStoresIn": "There are {X} expired/deactivated stores in the current integration",
    "failedToSave": "Failed to save",
    "shortDescription": "Short Description",
    "Item": "Item",
    "shippingFeeTips2": "Order Create Date = Sum of Shipping Fee and Shipping Fee paid by System on the Selected Date",
    "whenTheProductsInTheChannel": "When the products in the channel warehouse are automatically created/bound to MSKU, it will be automatically added to the paired system warehouse, but you still need to operate the inbound by yourself to ensure accurate inventory",
    "whenThereIsAStockChange": "When there is a stock change in the system warehouse that has been bound, the stock value will directly be pushed to the channel warehouse",
    "channelWarehouseId": "Channel Warehouse ID",
    "forChannelProductsThatAreBound": "For channel products that are bound to warehouses, once the MSKU is created, they will be automatically added to the bound warehouses.",
    "forMultiwarehouseChannelsAndChannelProducts": "For multi-warehouse channels and channel products with bound warehouse relationship, after binding MSKU, it will be automatically added to the bound warehouse.",
    "forMultiwarehouseChannelsWithWarehouseboundChannel": "For multi-warehouse channels with warehouse-bound channel products, MSKUs will be automatically added to the bound warehouse after creation.",
    "existXwarehouse": "{X} warehouses exist",
    "amountUnit": "Amount ( Unit :",
    "thePriceOfTokoWillBe": "The price of Toko will be updated to each warehouse of the channel.",
    "priceAndStockWillBeUpdated": "Price and stock will be updated to the default warehouse",
    "dueToTheDelayInChannel": "Due to the delay in channel update data, it will take a few seconds for the synchronized data to be updated, please wait patiently.",
    "theStoreWhereTheOrderIs1": "The store where the order is located has opened channel multi-warehouse, please bind the Ginee warehouse and operate",
    "theStoreWhereTheOrderIs": "The store where the order is located has opened channel multi-warehouse, and only supports changing to the bound Ginee warehouse {XXXX}",
    "theWarehouseXxxxAlreadyHasA": "The warehouse {XXXX} already has a pull task, which can be viewed in the pull record. Please wait until the task is completed.",
    "youNeedToIntegrateAstore": "You need to integrate a {x} store before you can use {y} payment",
    "pleaseSelectTheStoreaccountTo": "Please select the {x} store/account to pay",
    "theProductsXxxxInTheOrder": "The products {XXXX} in the order {XXXX} are distributed to the warehouse {XXXX} according to the binding relationship between the channel warehouse and the system warehouse.",
    "theCurrentProductsWarehouseIsEmpty": "The product's warehouse is empty. Please inbound in keeppack first.",
    "xIsOutOfStockIn": "{X} is out of stock in {X} , please replenish",
    "shopeebukalapaktokopediaOrdersUseGineeTemplateFor": "Shopee/Bukalapak/Tokopedia orders use Ginee template for printing shipping label",
    "buyerPhone": "Buyer Phone",
    "xNewWarehouses": "{X} new warehouses, warehouse ID:{X}",
    "editStock": "Edit Stock",
    "warehouseIsEmpty": "No warehouse info",
    "dueToTheLimitationsOfThe": "Due to Keeppack API limitation, the products cannot be operated in Ginee, please be sure to operate after inbound in Keeppack",
    "pleaseFindTheBindingEmailIn": "Please goto Tokopedia Seller Center [Settings - Third-party Application Settings] or find email in your mailbox to accept the binding application first",
    "created": "Create",
    "allstocktakingissuccessful": "All stocktaking is successful",
    "partialstocktaking": "Partial stocktaking",
    "allstocktakingfailed": "All stocktaking failed",
    "someexecute": "Some execute",
    "faileds": "Failed",
    "thePullTaskHasBeenCreated": "The pull task has been created successfully",
    "executed": "Executed",
    "keeppackWarehouse": "Keeppack warehouse",
    "unbindMsku": "Unbind MSKU",
    "PullRecord": "Stock Pull Records",
    "thisStoreHasBeenConnectedTo": "This Store has been connected to other Ginee Account please disconnect this store from below Ginee Accounts before operating:",
    "ManualInbound": "Manual Inbound",
    "stocktakingInbound": "Stocktaking Inbound",
    "NormalInbound": "Normal Inbound",
    "notPushed": "Not Pushed",
    "partComplete": "Part Complete",
    "partInvalid": "Part Invalid",
    "tripartiteSingleNumber": "{warehouse} Number",
    "storageInformation": "Inbound Info",
    "types": "Types",
    "plannedInbound": "Planned Inbound",
    "receiptCost": "Receipt cost",
    "withAttachments": "With attachments",
    "automaticallyCreateMsku": "Automatically create MSKU",
    "createMskuManually": "Create MSKU manually",
    "currentlyThirdPartyWarehouseSkuCode": "Currently, third party warehouse SKU code need to be the same with MSKU to support the binding",
    "warehouseKeeppackWarehouseInboundManagement": "Warehouse / Keeppack warehouse /Inbound Management",
    "warehouseKeeppackWarehouseOutboundManagement": "Warehouse / Keeppack warehouse /Outbound management",
    "warehouseKeeppackWarehouse": "Warehouse / Keeppack Warehouse /Stock Pull Record",
    "theCurrentCommodityWarehouseIsEmpty": "The current commodity warehouse is empty, and the storage is completed in keeppack",
    "YouWillNotBeAble": "1. You will not be able to see the order information for this store.\n2. You will not find the store in store settings",
    "temperatureControlled": "Temperature Controlled",
    "standard": "Standard",
    "storageType": "Storage Type",
    "enableWare": "Are you sure you want to enable multi-warehouse mode?",
    "afterEnablingTheMultiwarehouseModeAnd": "After enabling the multi-warehouse mode and publishing products, the multi-warehouse mode cannot be turned off.",
    "activateMultiwarehouse": "Activate Multiwarehouse",
    "turnOffMultiwarehouse": "Turn Off Multiwarehouse",
    "wantTo": "Are you sure you want to turn off multi-warehouse mode?",
    "afterTheMultiwarehouseModeIsTurned": "After the multi-warehouse mode is turned off, multi-warehouse and stock information will be cleared.",
    "kongfuWarehouse": "KONGFU Warehouse",
    "useTheStockInformationOfThe": "Use the stock information of the KONGFU warehouse to enable automatic synchronization to improve management efficiency",
    "afterTheAuthorizationIsSuccessfulYou": "After the authorization is successful, you can use the KONGFU fulfillment service to manage the product inbound and outbound;",
    "afterClickingTheAuthorizeButtonKongfus": "After clicking the [Authorize] button, KONGFU's warehouse information will be pulled for you, please select the required warehouse to complete the authorization process",
    "PleaseContactKongfuCustomerService": "2. Please contact KONGFU customer service to obtain the secretKey",
    "ItIsRecommendedToFill": "1. It is recommended to fill in the information agreed with KONGFU for the account name and consignor name",
    "informationDescription": "Info description",
    "consignorName": "Consignor name",
    "acuntName": "Account name",
    "completeTheRepositoryAuthorizationConfirmationIn": "Complete warehouse authorization confirmation in KONGFU's configuration management",
    "afterSelectingAndSavingSubjectTo": "After selecting and saving, please contact KONGFU customer service offline to complete the warehouse authorization review, and return to the Ginee system, in [Integration-Store List] as required by the KONGFU authorization process",
    "pleaseSelectTheWarehouseInformationThat": "Please select the warehouse information that needs to be authorized and complete the online authorization process",
    "pleaseSelectTheWarehouseThatNeeds": "Please select the warehouse that needs to be authorized",
    "applyForAuthorization": "Apply for authorization",
    "afterTheAuthorizationIsCompletedPlease": "After the approval is completed, please be sure to return to the Ginee system and complete the warehouse authorization confirmation in the configuration management of [Integration-Store List] KONGFU",
    "pleaseContactKongfuCustomerServiceOffline": "Please contact KONGFU customer service offline to complete the warehouse authorization review",
    "approvalStatus": "Approval Status",
    "reasonForFailureTheThirdParty": "Reason for failure: The third party Warehouse rejected the warehouse authorization application, please contact third party Warehouse customer service to confirm",
    "timeToOperateAuthorizedWarehouseInformation": "Time to operate authorized warehouse information in Ginee",
    "theTimeToUpdateWarehouseInformation": "The time to update warehouse information in Ginee",
    "consignorCode": "Consignor Code",
    "theSystemWillAutomaticallyUpdateThe": "The system will automatically update the approval status of the third-party warehouse for you every hour. If you need to view the approval results in real time, you can manually click [Update Approval Status] to view",
    "passed": "Passed",
    "theWarehouseAuthorizationApplicationInformationHas": "The warehouse authorization application information has been successfully notified to the third party warehouse",
    "afterConfirmationTheSystemWillCall": "After confirmation, the system will call the KONGFU interface for you to initiate a warehouse authorization application. After confirming the application, please contact KONGFU customer service personnel to complete the authorization review. The system will automatically synchronize the review status for you every hour. If you need to check in real time Audit status, please operate [Update Audit Status] on the configuration management page to obtain the audit results",
    "areYouSureToInitiateA": "Are you sure to initiate a warehouse authorization application to the third-party warehouse?",
    "initiateAuthorizationApplication": "Initiate authorization application",
    "theListOnlyShowsUnauthorizedWarehouse": "The list only shows unauthorized warehouse information, please contact Kongfu customer service offline to complete the review and confirmation after confirmation",
    "selectAuthorizedWarehouse": "Select authorized warehouse",
    "gineeWillPullTheWarehouseInformation": "Ginee will pull the warehouse information in KONGFU for you by calling the KONGFU interface, please select the required warehouse authorization, and contact KONGFU customer service to complete the authorization review of the new warehouse",
    "tipsa": "Tips",
    "startToPullTheLatestWarehouse": "Start to pull the latest warehouse information of KONGFU for you, wait for the KONGFU interface to return information, please wait patiently",
    "updateApprovalStatus": "Update Approval Status",
    "confirmAuthorizedWarehouseIdXxxx": "Confirm authorized warehouse ID: XXXX , XXXX",
    "authorizationStatusXxxx": "Authorization Status: {XXXX}",
    "addWarehousesForApplicationAuthorization": "Add 3 warehouses for approving authorization, warehouse ID: XXXX , XXXX",
    "noMoreThanXxxCharacters": "No more than {X} characters",
    "customsWeight": "Gross Weight",
    "customsDeclarationAmount": "Invoice Amount",
    "customsCode": "HS Code",
    "customsEnglishName": "Customs English Name",
    "customsDeclarationChineseName": "Customs Chinese Name",
    "ifTheWarehouseForThisDelivery": "If the warehouse for this delivery is KONGFU, and the products need customs declaration, please fill in the customs declaration information",
    "customsDeclarationInformation": "Customs Information",
    "theProductDeclarationInformationIsMissing": "The product customs information is missing, please move to the master product editing page to complete and complete the operation",
    "mskuDoesNotConformToThe": "MSKU does not conform to the third-party warehouse interface specification, please modify and operate",
    "theProductNameDoesNotConform": "The product name does not conform to the third-party warehouse interface specification, please modify it before operation",
    "TheMskuDoesNotExceed": "The MSKU does not exceed 50 characters, and only numbers, letters, and special symbols \"-\" are supported",
    "ProductNameDoesNotExceed": "Product name does not exceed 200 characters",
    "channelRequirements": "Channel Requirements",
    "ifTheProductsDeliveredToThe": "If the products delivered to the warehouse this time are for the fulfillment or need to be shipped across borders in China, please be sure to select Declaration and fill in the declaration information",
    "needToDeclare": "Need to declare",
    "noDeclarationRequired": "No declaration required",
    "totalNumberOfSkus": "Qty of MSKU",
    "awb": "AWB",
    "outboundAmount": "Outbound Amount",
    "outboundQuantity": "Outbound Qty",
    "outboundInformation": "Outbound info",
    "doNotEnableAutomaticBinding": "Do not enable automatic binding",
    "thisFieldIsRequiredWhenThe": "This field is required when the third-party warehouse interface requires returning inbound into the warehouse",
    "returnTrackingNumber": "Return No",
    "thirdpartyWarehouseTransactionReturn": "Third-party warehouse transaction return",
    "manualSync": "Manual Sync",
    "timingSynchronization": "Time Sync",
    "reasonReceivedAThirdpartyWarehouseInterface": "Reason: Received a third-party warehouse interface prompt{X}",
    "actualInbound": "Actual Inbound",
    "theOrderIsInconsistentWithThe": "The order is inconsistent with the country where the warehouse is located, please change the warehouse in time",
    "theChannelInterfaceCannotGenerateThe": "The channel interface cannot generate the label, and the current warehouse cannot fulfill. Please change the warehouse in time",
    "mskuIsNotAddToThe": "MSKU is not add to the warehouse. Please add first.",
    "rejected": "Rejected",
    "outboundListOutboundListInThe": "3.Outbound list: Outbound list in the warehouse, including number, status and details",
    "inboundListInboundListInThe": "2.Inbound list: Inbound list in the warehouse, including number, status and details",
    "productsProductsInTheWarehouseIncluding": "1.Products: Products in the warehouse, including details, stock and value in the warehouse",
    "agreeToAuthorizeGineeToObtain": "Agree Ginee to obtain the following info in the warehouse",
    "editMultiwarehouseStock": "Edit Multiple warehouse Stock",
    "totalStock": "Total Stock",
    "add2": "Add",
    "openMultiwarehouses": "Open Multiple warehouses",
    "closeMultipleWarehouses": "Close Multiple warehouses",
    "noteHighSpeedPrintingDoesNot": "Note: fast printing does not support printing of Ginee customized Shipping label(Tokopedia only supports Ginee customized Shipping label)",
    "massEditStockIsAvailableFor": "Mass edit stock is available for single warehouse stock",
    "agreecontinueAuthorization": "Agree,continue authorization",
    "theStockAndProductInformationOf": "The stock and product information of Fastock warehouse will be automatically synchronized in two directions to improve management efficiency",
    "afterClickingTheAuthorizeButtonYou": "After clicking the Authorize button, you can use Fastock fulfillment service to manage product inbound and outbound；",
    "PleaseContactTheWarehouseService": "2. Please contact the warehouse service provider for customer code/warehouse code/AppKey/SecretKey",
    "BeSureToSelectThe": "1. Be sure to select the service site correctly, otherwise the authorization will fail",
    "ifYouNeedToChangeWarehouse": "If you need to change warehouse information, please go to Config Management",
    "theFastockFulfillmentWarehouseHasBeen": "The Fastock fulfillment warehouse has been successfully created for you, and you can start using it",
    "received": "Received",
    "warehouseBreadcrumbProduct": "Warehouse / {warehouseName} warehouse /  product information",
    "warehouseBreadcrumbInbound": "Warehouse / {warehouseName} warehouse / Inbound Management",
    "warehouseBreadcrumbOutbound": "Warehouse / {warehouseName} warehouse / Outbound management",
    "warehouseBreadcrumbPullRecord": "Warehouse / {warehouseName} Warehouse / Stock Pull Record",
    "unboundStore": "Unbound Store",
    "storeAuthorizationHasExpired": "Store authorization has expired",
    "hongkong": "Hong Kong",
    "whetherItIsTurnedOnOr": "Whether it is turned on or not",
    "theSkuRemovalQuantityCannotExceed": "The sku removal quantity cannot exceed the order quantity",
    "productCodeRepeatedModification": "Product code repeated modification",
    "cantDeleteAllDetails": "Can't delete all details",
    "theOutboundListHasBeenAllocated": "The outbound list has been allocated and can't be modified",
    "failedToRequestGxb": "Failed to request gxb",
    "theDetailsOfTheOutboundList": "The details of the outbound list have already been allocated and cannot be modified",
    "outboundListDetailsDontExist": "Outbound list details don't exist",
    "theMergingSkuOfInboundList": "The merging sku of inbound list is abnormal, please contact the administrator",
    "theSkuAcceptanceQuantityOfThe": "The sku acceptance quantity of the inbound list has changed, please try again",
    "orderDetailsCannotBeChecked": "Order details cannot be checked",
    "productInformationDoesNotExist": "Product information does not exist",
    "returnAwbNumberCannotBeEmpty": "Return AWB number cannot be empty",
    "theInboundListHasBeenAccepted": "The inbound list has been accepted",
    "theInboundListDoesntExist": "The inbound list doesn't exist",
    "multipleLastMileScacCodesCannot": "Multiple last mile SCAC codes cannot appear under the same order",
    "theDomesticAwbNumberUnderThe": "The domestic AWB number under the same order cannot be repeated",
    "homeDeliveryDoesntNeedToFill": "Home delivery doesn't need to fill in the Pickup person's information",
    "weightCanOnlyBeAPositive": "Weight can only be a positive integer",
    "theLastMilesTrackingNumberIs": "The last mile's tracking number is not empty, and the last mile's SCAC code cannot be empty",
    "theLabelPdfIsntEmptyAnd": "The label PDF isn't empty, and the last mile number cannot be empty",
    "theSelectedWarehouseDoesNotSupport": "The selected warehouse does not support this business",
    "lastMileInformationlogisticsProductCannotBe": "Last mile information/logistics product cannot be empty at the same time",
    "documentNumberCannotBeEmpty": "Document number cannot be empty",
    "productQuantityCanOnlyBeA": "Product quantity can only be a positive integer",
    "theDestinationCountryMustBeConsistent": "The destination country must be consistent with the receiver country",
    "recipientCountryprovincecityParameterIsMissing": "Recipient Country/Province/City parameter is missing",
    "theOrderHasBeenProcessedAnd": "The order has been processed and cannot be modified",
    "matchesMultipleOrders": "mapping with multiple orders",
    "notWeighedWithoutWeight": "Not weighed without weight",
    "theDocumentNumberAndLastMile": "The document number and last mile order number are empty",
    "lastLegNumberAlreadyExists": "Last mile number already exists",
    "orderShipped": "order shipped",
    "pickupPersonInformationCannotBeEmpty": "Pickup person information cannot be empty",
    "theOrderHasBeenShippedAnd": "The order has been shipped and cannot be canceled",
    "senderCountryprovincecityParameterIsMissing": "Sender Country/Province/City parameter is missing",
    "logisticsChannelDoesntSupport": "Logistics channel doesn't support",
    "thisStatusDoesntSupportModification": "This status doesn't support modification",
    "pleaseCheckThePickupMethod": "Please check the pickup method",
    "outboundTypeNotMapped": "Outbound type not mapped",
    "pleaseCheckTheScac": "Please check the SCAC",
    "productcombinedProductQuantityMustBe": "Product/combined product quantity must be>0",
    "documentNumberAlreadyExists": "Document number already exists",
    "pleaseCheckTheReceiver": "Please check the receiver",
    "warehouseCodeDoesNotExist": "Warehouse code does not exist",
    "codAmountAndCodCurrencyCan": "COD amount and COD currency can not only fill in one",
    "pleaseCheckTheBusinessType": "Please check the business type",
    "failedToCheckDestinationCountryprovinceregion": "Failed to check destination country/province/region",
    "logisticsChannelTrackingFailed": "Logistics channel checking failed",
    "noPermissionToOperateThisOrder": "No permission to process this order",
    "thePlatformHasAuthorizedTheConsignor": "The platform has authorized the consignor",
    "tokenError": "token error",
    "noValidAuthorizedWarehouse": "No valid authorized warehouse",
    "platformMismatch": "platform mismatch",
    "noConsignorPermission": "No consignor permission",
    "theTokenDoesntMatch": "The token doesn't match",
    "thePlatformConsignorDoesntMatch": "The platform consignor doesn't match",
    "pleaseCheckIfThereIsError": "Please check if there is an error in the warehouse",
    "authorizationHasBeenApprovedPleaseDont": "Authorization has been approved, please don't repeat the operation",
    "noConsignorOnThisPlatform": "no consignor on this platform",
    "userHasNo": "user has no authorization for this warehouse",
    "userHasNoAuthorizationForThis": "user has no authorization for this consignor",
    "userDoesntHaveConsignorsAuthorization": "user doesn't have consignor's authorization",
    "warehouseAuthorizationRecordDoesNotExist": "Warehouse authorization record does not exist",
    "duplicateAuthorizationApplication": "Duplicate authorization application",
    "warehouseDoesntExist": "Warehouse doesn't exist",
    "consignorDoesntExists": "Consignor doesn't exist",
    "platformInformationVerificationFailedPleaseContact": "Platform information verification failed, please contact the administrator",
    "consignorAlreadyExists": "Consignor already exists",
    "noTrackingNumber": "No Tracking number",
    "hasTrackingNumber": "Has Tracking number",
    "trackingNumberStatus": "Tracking number status",
    "rulesBetween": "{name} should be between{range}!",
    "waitingOutbound": "Waiting Outbound",
    "productPriceShouldBeBetween": "Product price should be between 1-1,000,000,000",
    "pleaseMoveToTheMasterProduct": "Please move to the master product editing page to complete and complete the operation",
    "kongfuRequiresThatTheLabelMust": "*KONGFU requires that the label must end with \".pdf\". If the authorization is successful, it means that you agree that KONGFU can obtain a long-term valid label",
    "outboundListNumber": "Outbound Number",
    "inventoryChangesDueToManualOperation": "Stock changes due to manual operation by warehouse personnel",
    "totalDiscountedPrice": "Total Discounted Price",
    "inventoryChangesDueToWarehousePersonnel": "Stock changes due to warehouse personnel manually canceling inbound and changing the inbound Qty",
    "thirdpartyWarehouseInboundAdjustment": "Third-party Warehouse inbound adjustment",
    "sinceTheKongfuApiLimitsThe": "Since the KONGFU API limits the inbound cost to 999,999, please be aware that if the cost is more than 999,999, we will use 999,999",
    "pleaseCheckTheDocumentType": "Please check the document type.",
    "tryNow": "Try Now",
    "talkLater": "Talk later",
    "congratulationsYouWillGetADay": "Congratulations, you will get a 7-day free trial with full system functions",
    "theTrialVersionHasExpiredAnd": "The trial version has expired, and the package has been downgraded to a free version. If you need to upgrade, please purchase a package",
    "theTrialPackageWasUpdatedSuccessfully": "The trial package was updated successfully.",
    "warehouseWarehousenameWarehouseActive": "Warehouse / {warehouseName} Warehouse / {active}",
    "theSalesTypeNameIsEmpty": "The sales type name is empty",
    "generalInformation": "General Information",
    "imageForPos": "Image for POS",
    "onlineAttributes": "Online Attributes",
    "refurbished": "Refurbished",
    "insertProductWeightIncludingThePackaging": "Insert product weight including the packaging to calculate shipping rate.",
    "maximumMax": "Maximum: {max}",
    "kilogramKg": "Kilogram (kg)",
    "gramGr": "Gram (gr)",
    "insertProductDimensionAfterPackagingTo": "Insert product dimension after packaging to calculate shipping rate.",
    "activatingThePreorderFeatureAllowsYou": "Activating the Pre-Order feature allows you to extend the processing time of orders up to 90 days (includes weekends and public holidays).",
    "productIsReadyStock": "Product is Ready Stock.",
    "productNeedLongerShippingProcess": "Product need longer shipping process.",
    "processingTime": "Processing Time",
    "mokaOrderBetweenDays": "Moka Order: between 1-90 days.",
    "mokaProductImageTooltip": "Add up to 12 photos . Size min 500x500px and max 8MB. Format: PNG, JPEG, BMP.",
    "Inventory": "Inventory",
    "cost": "Cost",
    "isMultiplePricePerSalesType": "Is multiple price per sales type",
    "trackStock": "Track stock",
    "alert": "Alert",
    "alertAt": "Alert at",
    "itemStockCanNotBeChanged": "Item stock can not be changed after saving the item, so please make sure that it is correct!",
    "thisItemCanNotBeTracked": "This item can not be tracked because the inventory stock is not tracked.",
    "avgCostCanNotBeChanged": "Avg cost can not be changed after saving the item, so please make sure that is correct!",
    "trackCogs": "Track COGS",
    "avgCost": "Avg Cost",
    "theSynchronizationIsCompleteAndThe": "The synchronization is complete and the following sales types are no longer valid",
    "Empty": "Empty",
    "domesticLogisticsTrackingNumberCannotBe": "Domestic logistics tracking number cannot be empty",
    "consignorDoesntMatch": "Consignor doesn't match",
    "documentNumberDoesNotExist": "Document number does not exist",
    "trackingNumberAlreadyExists": "Tracking number already exists",
    "packageAlreadyExists": "package already exists",
    "paketTambahanTidakBolehLebihBesar": "The additional package can't be greater than the total quantity of the product",
    "theOrderHasBeenShippedNo": "The order has been shipped, no additional packages can be added",
    "productInformationCantBeEmpty": "Product information can't be empty",
    "theresEmptyDataInTheCombination": "There's empty data in the combination product information package number",
    "declaredAmount": "Declared amount",
    "declaredWeight": "Declared weight",
    "menuProductMoka": "Products / Moka POS",
    "menuProductMokaAdd": "Products / Moka POS / Add Product",
    "menuProductMokaEdit": "Products / Moka POS / Edit Product",
    "stockInOutAlert": "Stock In/Out Records will show all changes in Warehouse Stock for the past 3 months",
    "theStockCanBePushedTo": "The stock can be pushed to Moka only after the track stock switch is turned on",
    "youHaveADayFullfeaturedTrial": "You have a 7-day full-feature trial for free!",
    "youAreUsingTheFreeVersion": "Now you are using the free version of Ginee. \nIf you need functions such as warehouse management and inventory synchronization, you can apply once for a 7 day free trial of Ginee's full functions. \nAre you sure you want to apply for a trial? (Expected to take effect within 15 minutes after applying)",
    "Cname": "Channel Name",
    "thisStaffAccountHasNoPermission": "This staff account has no permission to access the system, please contact the merchant/admin to add the staff account to the system and assign permissions",
    "makeSureTheSwitchIsTurned": "Make sure the switch is turned on when modifying the stock, otherwise the modification will fail",
    "printTaskCompletionTime": "Print Task Completion Time",
    "printTaskCreationTime": "Print Task Creation Time",
    "noteLazadaAndBlibliOrdersDo": "Note: Lazada and Blibli orders do not support setting packing status",
    "prefixMatch": "prefix match",
    "prefixSearch": "Prefix Search",
    "GineeAccountCanOnlyAuthorize": "1 Ginee account can only authorize 1 Accurate account, if you have more needs, please contact the CS",
    "matchingFailedThePreviousMatchingOption": "Mapping failed: the previous mapping option can't be found, please confirm whether the option has been deleted in Accurate",
    "itemPriceCredit": "Item Price Credit",
    "freeShippingMaxFee": "Free Shipping Promotion Fee",
    "paymentFee": "Payment Fee",
    "lazpicklaztopCommission": "Lazpick/LazTop commission",
    "platformRequirementsTheDescriptionLengthMust": "Platform requirements: the description length must be between 100 and 3000 characters",
    "currentlyBumped": "Currently Bumped",
    "numberOfBumpProducts": "Number of bump products",
    "bumpFixedSupportMaximumProducts": "Bump Fixed support maximum 5 products",
    "bumpfixed": "bumpFixed",
    "bumpByTurns": "Bump By Turns",
    "setAsBumpFixed": "Set as bump fixed",
    "setAsBumpByTurns": "Set as bump by turns",
    "tipsYouCanAddUpTo": "Tips: You can add up to 30 products at a time, please prioritize the synchronization of product information in 'the' store",
    "editingInformationSuchAsStockAnd": "Editing information such as stock and purchase price of combined products is not supported",
    "failedToUploadImagePleaseReupload": "Failed to upload image, please re-upload image and try again",
    "solutionSteps": "Solution Steps",
    "addBinding": "Add Binding",
    "pleaseClickToRedistribute": "Please click to redistribute",
    "pleaseAddBindingRelationship": "Please add binding relationship",
    "pleaseEnableDistributionSettings": "Please enable distribution settings",
    "distributionSettingsArentEnabled": "Distribution settings aren't enabled",
    "pleaseRestoreTheOrder": "Please restore the order",
    "ordersThatHaveBeenPlacedOn": "Orders that have been put on hold do not participate in stock locks or deductions",
    "problemCauses": "Problem Causes",
    "pleaseCreateAnInboundListAnd": "Please create an inbound list and complete the inbound",
    "pleaseAdjustTheShippingWarehouse": "Please adjust the shipping warehouse",
    "pleaseCompleteTheInboundInThe": "Please complete the inbound in the third-party warehouse",
    "pleaseCreateAnInboundList": "Please create an inbound list",
    "mskuInXWarehouseYOut": "MSKU {y} in {x} warehouse is out of stock",
    "theOrderStatusOfTheX": "The order status of the {x} channel is: {y} does not support creating an outbound list",
    "currentlyDoesNotSupportTheCreation": "Currently does not support the creation of outbound lists for {x} channel",
    "theXThirdpartyWarehouseDoesNot": "The {x} third-party warehouse does not currently support the creation of outbound lists for {y} countries",
    "theXThirdpartyWarehouseDoesntCurrently": "The {x} third-party warehouse doesn't currently support the creation of outbound lists for the {y} channel",
    "bumpFixed": "Bump Fixed",
    "serviceFeeMapping": "Service Fee Mapping",
    "platformFulfillmentFeeMapping": "Shipping Fee Paid by System Mapping",
    "platformRebateMapping": "Platform Rebate Mapping",
    "voucherSellerMapping": "Voucher Seller Mapping",
    "shippingDifferenceCashlessMapping": "Shipping Fee Rebate Mapping",
    "shippingFeeMapping": "Shipping Fee Mapping",
    "shippingPriceSetting": "Shipping Price",
    "taxSetting": "Tax Setting",
    "salesInvoiceSetting": "Sales invoice Setting",
    "storeMapping": "Store Mapping",
    "warehouseMapping": "Warehouse Mapping",
    "database": "Database",
    "newCompleteOrdersAfterTheSt": "\"Delivered Orders\" after the 1st day of the month",
    "newCompleteOrderAfterMappingStore": "Mapped Store's \"Delivered Orders\"",
    "afterEnablingIfAccurateHasStock": "After enabling, if Accurate has stock changes:\n1. Push it to Ginee in real time, and modify Ginee's stock;\n2. Support to manually  pull the latest stock value of Accurate on stock list.",
    "stockSyncRules": "Stock Sync Rules",
    "mappingTime": "Mapping Time",
    "branch": "Branch",
    "chartOfAccounts": "Chart of Accounts",
    "pleaseCompleteAtLeast": "Please complete at least 1 store mapping. After the store mapping is successful, the completed order of the store will automatically create a Sales invoice and push it to Accurate",
    "gineeWarehouseCanOnly": "1 Ginee warehouse can only be mapped with 1 Accurate warehouse, please complete at least 1 warehouse mapping",
    "enabledByDefault": "Enabled By Default",
    "autoCreateRulesForSalesInvoice": "Auto create rules for Sales invoice",
    "syncFromAccurateToGinee": "Sync from Accurate to Ginee",
    "syncFromGineeToAccurate": "Sync from Ginee to Accurate",
    "finance": "Finances",
    "mapFailed": "Map Failed",
    "notMapped": "Not Mapped",
    "mapped": "Mapped",
    "stockChange": "Stock Change",
    "pushMapMsku": "Push & Map MSKU",
    "repushStock": "Re-push stock",
    "upToCharactersAndOnly": "Max 50 characters and only support: a-z, A-Z, 0-9, \"(\", \")\", \"-\", \".\", \"_\", \"/\", and Unit Cost must be >=1",
    "allUncreatedAndUnmappedMskusCurrently": "All uncreated and unmapped MSKUs currently and subsequently added",
    "afterItIsEnabledAllMskus": "After it is enabled, all MSKUs in the mapped Ginee warehouse will be pushed to the Accurate warehouse, new SKUs will be automatically created in Accurate, and existing SKUs will automatically map with Accurate",
    "mappingRules": "Mapping Rules",
    "autoPushMap": "Auto Push & Map",
    "thisSkuAlreadyExists": "This SKU already exists",
    "unitCostMustBe": "Unit Cost Must Be >=1",
    "salesInvoice": "Sales Invoice",
    "syncStock": "Sync Stock",
    "theStoreIsMissingMappingInformation": "The store is missing mapping information (Warehouse, Branch, Chart of Account, Customer)",
    "source": "Source",
    "associatedOrderNumber": "Associated Order Number",
    "itIsRecommendedToConvertTo": "It is recommended to convert to the amount currency set by the system",
    "theCurrencySymbolIsBasedOn": "The currency symbol is based on the currency set in the system.\\n The amount is the average cost price of inbound product",
    "bulkrepush": "Bulk re-push",
    "alasanGagalPushGudangPihakKetiga": "Reasons for the failure to push the third-party warehouse",
    "noShelf": "Default Shelf",
    "plannedQuantityTip": "Fill in the planned outbound quantity of product",
    "plannedQuantityTipOld": "Planned outbound quantity: fill in the planned outbound quantity of product",
    "selectLocationTip": "Select Location of SKU Outbound",
    "exportSelectedOrderDetail": "Outbund List Detail - Selected",
    "exportAllOrderDetail": "Outbound List Detail - All",
    "exportSelectedOrderList": "Outbound List - Selected",
    "exportAllOrderList": "Outbound List - All",
    "mskuAndBarcode": "MSKU/Barcode",
    "confirmToSetAsInvalid": "Confirm to set this as invalid?",
    "bulkSubmitSuccess": "Submitted, please refresh the page to view the processing result",
    "bindFailed": "Binding failed, Please re-select the location",
    "bindSuccess": "Binding successful",
    "autoCreateAndBind": "Automatically create a location and bind to master SKU",
    "warehouseName2": "Warehouse Name",
    "warehouseCode": "Warehouse number",
    "quantityAdded": "Quantity added",
    "sureToDelete": "Are you sure to delete?",
    "selectLocation": "Select the location of  SKU in the warehouse",
    "enterQuantity": "Enter quantity of this outbound",
    "createLocationTips": "If you need to mass create new locations, you can create them in the location management.",
    "locationManagement": "Location Management",
    "warehouseStockColumnName": "Warehouse Stock",
    "stockNotEnough": "Insufficient inventory in the warehouse, unable to outbound",
    "externalOrderNo": "Order ID",
    "numSelectedWithValue": "{val} Selected",
    "column3TipOld": "Column 3: Location (optional) if not filled in, will select the location with the largest Available Stock",
    "column2TipOld": "Column 2: Quantity (required)",
    "column1TipOld": "Column 1: SKU (required)",
    "column3Tip": "Column 3: Shelf (optional). If you don’t fill in, it will use the default location",
    "column2Tip": "Column 2: Quantity (required)",
    "column1Tip": "Column 1: SKU (required)",
    "copyTip": "Copy SKU, outbound quantity, shelf, and cargo location from excel to the text box, which can be quickly analyzed and submitted",
    "printDetails": "Print product details",
    "copyTip2": "First column is SKU, second column is quantity, third column is location",
    "logisticsNumberLengthError": "The maximum length of shippment number is 200 characters",
    "duplicateLocationError": "The location information is duplicate. Only one line can be submitted for the same location.",
    "setPushRatio": "Warehouse Shipping Settings-Warehouse Shipping Settings-if you have multiple stores, you can bind warehouses for each store here, and you can also set the stock push ratio for each store",
    "ifOnlyOneWarehouse": "If you only have one warehouse, and you don’t need to bind the location, then when creating the Master Product, the system will automatically create a location for each SKU for you, which can be used directly.",
    "addProductsToLocation": "Warehouse stock details-After the location are set up, you can put the produts on the location. On the warehouse stock details page, click \\\"Add produts to the location\\\" to bind the produts with the location. After the binding is complete, you can manage all of products stock on these locations in Inventory management.",
    "setUpLocation": "Location management-produts are placed on some location in the warehouse, you need to set up warehouse location",
    "setUpWarehouse": "Warehouse management-you need to set up your warehouse in the warehouse management feature",
    "helpShippingSettings": "Warehouse Shipping Settings",
    "helpStockDetails": "Warehouse stock details",
    "helpLocationManage": "Location Management",
    "helpWarehouseManage": "Warehouse Management",
    "warehouseManagementIntro": "Introduction to warehouse management related features",
    "moreThanPlannedError": "Exceeded Planned Outbound Amount",
    "INVALID": "Invalid",
    "PART_INVALID": "Part Invalid",
    "COMPLETE": "Completed",
    "PART_COMPLETE": "Part Complete",
    "PENDING": "Waiting Outbound",
    "CREATE": "Processing",
    "changeWarehouseAlert": "Switching Warehouse will need to add SKU information again, please confirm",
    "processResult": "Process Result",
    "complete": "Completed",
    "pushComplete": "Complete",
    "pushing": "Pushing",
    "workingStatusCANCEL_FAIL": "Cancel Failed",
    "workingStatusCANCEL_SUCCESS": "Cancel Success",
    "workingStatusCANCELED": "Cancelled",
    "workingStatusCANCEL": "Cancelled",
    "workingStatusOUTBOUND_DONE": "Outbound Done",
    "workingStatusCANCELING": "Canceling",
    "workingStatusWAITING_PICKUP": "Waiting Pickup",
    "workingStatusPACKED": "Packed",
    "workingStatusSORTING_AND_REVIEWING": "Sorting and reviewing",
    "workingStatusPICKING_PROCESS": "Picking Process",
    "workingStatusALLOCATED": "Allocated",
    "workingStatusRECEIVED": "Received",
    "workingStatusSYNC_FAIL": "Sync Fail",
    "workingStatusSYNC_SUCCESS": "Sync Success",
    "workingStatusCREATE": "Processing",
    "workingStatus": "Working Status",
    "express": "Express",
    "editOutboundList": "Edit Outbound List",
    "defaultDelivery": "Default",
    "selfPickup": "Self Pickup",
    "outboundListDetail": "Outbound List Detail",
    "failedLineTip": "Failed (this line cannot be added to the outbound list)",
    "ifMoreThanStock1": "If the number of SKU is greater than the available stock, will follow the maximum available stock",
    "ifTwoColumns1": "If there are only two columns, then will follow the SKU + Qty analysis",
    "copyTipOld": "Copy SKU, Qty, Location, Ginee analysis from Excel",
    "outboundListInvalid": "Outbound List Invalid",
    "outboundAll": "Outbound All",
    "outboundListOutbound": "Outbound List Outbound",
    "outboundManagement": "Outbound Management",
    "scanInstruction": "Can scan the barcode using scanner and press Enter, if it is successfully anaylsed, the product actual stock will +1",
    "receivedProduct": "Warehouse",
    "goBack": "Previous",
    "addToOutboundList": "Add to the Outbound List",
    "row": "Row",
    "operationSuccessful": "Successful operation！",
    "editSuccess": "Successfully Edited!",
    "identify": "Identify",
    "identifyResults": "Identify Results",
    "ifMoreThanStock": "If the number of SKUs is greater than the available stock, will use the maximum available stock",
    "ifTwoColumns": "If there are only two columns, will use SKU + qty to analyse",
    "submit": "Submit",
    "directOutbound": "Direct Outbound",
    "locationStockNotEnough": "Location Available Stock not enough",
    "locationAvailableStock": "Location Available Stock",
    "warehouseAvailableStock": "Warehouse Available Stock",
    "note": "Notes",
    "plannedTotalAmount": "Planned Total Amount",
    "unitPrice": "Unit Price",
    "associatedDocumentNumber": "Associated document number",
    "refreshStatus": "Refresh status",
    "outboundTime": "Outbound Time",
    "deliveryMethod": "Delivery Method",
    "actualOutboundQty": "Actual Outbound Qty",
    "plannedOutboundQty": "Planned Outbound Qty",
    "actualOutboundAmount": "Actual Outbound amount",
    "plannedOutboundAmount": "Planned Outbound amount",
    "searchContent": "Search content",
    "sortListSelected": "Sort list - Selected",
    "sortListAll": "Sort list - All",
    "pickListSelected": "Pick list - Selected",
    "pickListAll": "Pick list - All",
    "outboundListSelected": "Outbound list - Selected",
    "outboundListAll": "Outbound list - All",
    "createOutboundList": "Create Outbound List",
    "greaterThan30Days": "> 30 days",
    "lessThan30Days": "≤ 30 days",
    "lessThan7Days": "≤7 days",
    "lessThan3Days": "≤ 3 days",
    "listNumber": "List Number",
    "searchType": "Search type",
    "allocateOutboundList": "Allocate Outbound List",
    "documentType": "Document Type",
    "shippedOld": "Outbound",
    "documentStatus": "Document status",
    "pleaseSelectData": "Please check the data you want to export.",
    "basicFeaturesFreeForever": "Free Lifetime",
    "nMinutesRemaining": "{minus} minutes remaining",
    "gineeAndAccurateAreMappedBy": "Ginee and Accurate are mapped by SKU. Since Accurate is not case-sensitive, please make sure that there is no SKU with the same name under this warehouse before mapping.",
    "accurateRequiresSkuToHaveA": "Accurate requires MSKU to have a max of 50 characters and only supports: a-z, A-Z, 0-9, \"(\", \")\", \"-\", \".\", \"_\", \"/\"",
    "otherSettingsAndMapping": "Other settings and mapping",
    "mapping": "Mapping",
    "automapSettings": "Auto-Map Settings",
    "pleaseSetAndMapTheCorrect": "Please set and map the correct information",
    "basedOnAccurateStock": "Based on Accurate Stock",
    "basedOnGineeStock": "Based on Ginee Stock",
    "whenTheSkuOfGineeAnd": "When the SKU of Ginee and Accurate are mapped, if the stock of the two systems is different, please select which system’s stock as reference, and Ginee will automatically push or pull the stock after mapping",
    "startingStock": "Starting Stock",
    "theSubproductsOfTheCombinedProduct": "The sub-products of the combined product have not been mapped yet, please complete the mapping of the sub-products before mapping the combined product",
    "theWarehouseHasMatchedTheAccurate": "The warehouse has been mapped with the Accurate warehouse, and the stock and cost price of the product must be >0",
    "accurateAuthorizationHasExpiredPleaseReauthorize": "Accurate authorization has expired, please re-authorize!",
    "accurateRequiresInventoryToBe": "Accurate requires stock and cost price to be >0",
    "goToChange": "Go to Change",
    "inboundWhenAddedToWarehouse": "Inbound when added to warehouse",
    "youHaveNotCompletedTheInitial": "You have not completed the initial configuration of Accurate, the relevant features can only be used after configuration",
    "failedToDeleteThisProductHas": "Failed to delete! This product has been associated with a combined product, please delete the combined product or unbind the combined product first",
    "noteIfTheWarehouseIsAdded": "Note: If inbound when added to warehouse is the WMS warehouse or the third-party warehouse, after inbound, you need to confirm the inbound in the WMS/third-party warehouse to successfully start the stock sync",
    "partnerAuthorizationFailed": "Partner authorization failed",
    "customerMustBeFilled": "Customer must be filled",
    "itemXSuccessfullySaved": "Item \"{x}\" successfully saved",
    "unitQtyMustBeFilled": "Unit Qty must be filled",
    "itemXNotFoundOrHas": "Item {x} not found or has been removed",
    "accurateConfigurationInformationIsMissingPlease": "Accurate Config information is missing, please check Config Management and try again",
    "theWarehouseXWasNotFound": "The Warehouse \"{x}\" was not found or has been deleted",
    "brazil": "Brazil",
    "taiwan": "Taiwan",
    "savedSuccessfullyThisPageWillClose": "Saved successfully! This page will close automatically",
    "salesInvoicePushRecord": "Sales Invoice Push Record",
    "orderPushTime": "Order Push Time",
    "orderCompleteTime": "Order Completion Time",
    "completeTime": "Complete Time",
    "updateResult": "Update Result",
    "productManage": "Product Management",
    "becauseTheNumberOfStoresExceeds": "Your order quantity has reached the package limit and this feature can't be used. Please contact customer service to subscribe to a new package",
    "theAuthorizationInformationIsFilledIncorrectly": "The authorization information is filled incorrectly, please refer to the Integration Help Center and fill in again",
    "channelItemXMoveOutPromotion": "Store:{x}'s product:{y} moved out from Promotion ID:{z}",
    "xManualReleaseOfReservedPromotion": "{x} Manual Release of Reserved Promotion Stock",
    "promotionIdXHasEnded": "Store:{x}'s product:{y} participated in the Promotion ID:{z} has ended",
    "xManuallyEditedReservedPromotionStock": "{x} manually edited reserved promotion stock, updated from {y} to {z}",
    "channelProductXActivityIdY": "Store:{x}'s product:{y} Promotion ID:{z}",
    "unknownSystemException": "Unknown system abnormality, please contact customer service",
    "theChannelProductSpecificationCorrespondingTo": "The channel product specification corresponding to the promotion wasn't found",
    "theChannelProductCorrespondingToThe": "The channel product corresponding to the promotionwas not found",
    "hasNoWarehouseInformationAndCannot": "MSKU has no warehouse information and cannot reserve Promotion Stock",
    "channelSkuHasNoBoundMsku": "Channel SKU has no bound MSKU, please go to the master product list to bind the SKU first",
    "notCompliantWithPromotionReservation": "Not compliant with promotion reservation strategy: \"Upcoming and Ongoing Promotions Are Automatically Reserved\"",
    "promotionReservationPolicyNotMet": "Not compliant with promotion reservation strategy: \"Automatically reserving Ongoing promotions only\"",
    "theCategoryOrAttributeInformationIs": "The category or attribute information is incorrect, please try again after synchronizing the category",
    "setUpOrderInvoices": "Set up order invoices",
    "orderPacking": "order packing",
    "orderDistribution": "Order Distribution",
    "syncOrder": "Sync Order",
    "theOrderSSuccessfullyPrintedThe": "The order [%s] successfully printed the picking list",
    "theOrderssuccessfullyPrintedThePackingList": "The order [%s] successfully printed the packing list",
    "orderSuccessInvoice": "The order [%s] successfully printed the invoice",
    "orderSuccessLabel": "The order [%s] successfully printed the label",
    "ordersreturnedInboundTheReturnInboundList": "Order [%s] returned inbound, the return inbound list was successfully created, the list number: <a href=\"/erp/main/outbound-management-detail/%s/SELL\" target=\"_blank\">%s</a>",
    "ordersredistributedSuccessfully": "Manually re-distributed order [{0}], please refer to the next operation record for the result",
    "ordersfailedToPushWarehouseForDelivery": "Order [%s] failed to push warehouse for delivery, reason: %s",
    "theOrdersisSuccessfullyPushedToThe": "The order [%s] is successfully pushed to the warehouse",
    "orderspushWarehouseOperationSuccessfully": "Order【%s】Push warehouse operation successfully",
    "theOrderSSuccessfullySetThe": "The order [%s] successfully set the invoice",
    "ordersarrangedDeliverySuccessfully": "Order [%s] arranged delivery successfully",
    "orderspackedSuccessfully": "Order [%s] packed successfully",
    "orderscancelledSuccessfully": "Order [%s] cancelled successfully",
    "theWarehouseSelectedForProductS": "The warehouse selected for product [%s] in the order [%s] is changed from [%s] to [%s]",
    "theProductSInTheOrder": "The product [%s] in the order [%s] is replaced by [%s], and the selected warehouse is [%s]",
    "theOrderSDeletesTheGift": "The order [%s] removes the gift [%s], the quantity is [%s], and the selected warehouse is [%s]",
    "addGiftSToOrderS": "Add gift [%s] to order [%s], the quantity is [%s], and the selected warehouse is [%s]",
    "theOrderSHasBeenCancelled": "The order [{0}] has been cancelled (Restore Order)",
    "ordersmarkedAsPutOnHold": "Order [{0}] marked as \"On Hold\"",
    "theOrderSIsMarkedAs": "Order [%s] is marked as [Problem Order-Out of stock], reason: Master product [%s] corresponding to channel product [%s] is out of stock",
    "orderSIsMarkedAsOut": "Order [%s] is marked as [Problem Order-Warehouse allocation error], reason: [%s] doesn't support using  [%s] fulfillment warehouse",
    "theOrderSIsAPromotion": "The order [%s] is a promotion order, and the channel product [%s] is allocated to the warehouse [%s]",
    "theChannelProductNotOutbound": "Order [%s] is marked as [Problem Order-MSKU not associated with warehouse], Channel Product  [%s] cannot generate outbound list, reason: the associated master product [%s] is not in the [%s] warehouse",
    "theChannelProductCantGen": "Order [%s] is marked as [Problem Order-Unallocated warehouse], Channel Product  [%s] cannot generate outbound list, reason: no corresponding Shipping Warehouse rule",
    "theChannelProductAllocate": "The Channel Product [%s] in order [%s], according to the Shipping Warehouse Rule [%s] is allocated to warehouse [%s]",
    "theChannelProductSInThe": "Order [%s] is marked as [Problem Order-Not Bound to MSKU]，Channel Product [%s] cannot generate outbound list, reason: not associated with Master Product",
    "anOutboundListHasBeenCreated": "An outbound list has been created for order [%s], order number: <a href=\"/erp/main/outbound-management-detail/%s/SELL\" target=\"_blank\">%s</a>",
    "theOrderSHasBeenSuccessfully": "The order [%s] has been successfully synchronized, and the order status has been updated from [%s] to [%s]",
    "syncToTheNewOrderS": "Synchronized to the new order [%s], the order status is [%s]",
    "salesTaxAmountMustBeBetween": "Sales tax amount must be between 0-1000000000",
    "incorrectUnitFormat": "Incorrect unit format, only supported content is \"hour\", \"weekday\", \"day\", \"week\", \"month\"",
    "purchaseDurationMustBeBetween": "Purchase Duration must be between 1-365",
    "selectedWarehouse": "Selected Warehouse",
    "addingToRepositoryXFailedReason": "Adding to repository \"{x}\" failed! Reason for failure:",
    "purchaseDurationUnit": "Purchase Duration Unit(Only supported content is \"hour\", \"working day\", \"day\", \"week\", \"month\")",
    "thisCustomerHasOrdersInThe": "This customer has orders in the past 60 days, you can initiate a session",
    "theProductInboundStockCannotBe": "The product inbound stock cannot be negative",
    "theBundledProductHasNotBeen": "The bundled product has not been sent to the warehouse!",
    "pleaseAddMskuToTheWarehouse": "Please add MSKU to the warehouse and complete the Inbound",
    "createSalesInvoice": "Create Sales Invoice",
    "beforePushingMatchingCombinedProducts": "Before pushing & mapping combination products, it is necessary to ensure that all combined products have been pushed & mapped",
    "theSystemWillAutomaticallyPushSales": "The system will automatically push Sales invoice to Accurate at JKT 03:00, 11:00, and 19:00. If all three attempts fail, it won't be pushed again",
    "ifGineeAndAccurateHaveThe": "Currently only supports Ginee's stock as the Starting Stock",
    "thePushedMappedMskuSupports": "The pushed & mapped MSKU supports enabling sync stock, and temporarily only supports Ginee's stock pushed to Accurate when Ginee stock changes",
    "afterSettingTheTaxTypeWill": "After setting, the tax type will be included when creating MSKU from Ginee to Accurate",
    "thisMappingCorrespondsToTheService": "This mapping corresponds to the Service Fee field in the order",
    "thisMappingCorrespondsToTheShipBySys": "This mapping corresponds to the Shipping Fee Paid by System field in the order",
    "thisMappingCorrespondsToTheRebate": "This mapping corresponds to the Rebate field in the order",
    "thisMappingCorrespondsToTheCommission": "This mapping corresponds to the Commission Fee field in the order",
    "thisMappingCorrespondsToTheShipReb": "This mapping corresponds to the Shipping Fee Rebate field in the order",
    "thisMappingCorrespondsToTheShipping": "This mapping corresponds to the Shipping Fee field in the order",
    "thePromotionHasExpiredOrThe": "The promotion has expired or the promotion type is incorrect",
    "invoiceBuyerPhoneNo": "Invoice Buyer Phone No",
    "invoiceType": "Invoice Type",
    "invoiceAddress": "Invoice Address",
    "invoiceBuyerEmail": "Invoice Buyer Email",
    "invoiceBuyerName": "Invoice Buyer Name",
    "upcoming": "Upcoming",
    "finish": "Finish",
    "promoTime": "Promotion Time",
    "promotionid": "Promotion ID",
    "webstore": "WebStore",
    "orderShasBeenMovedToReturned": "Order[%s] has been moved to Returned",
    "orderShasBeenMovedToDelivered": "Order[%s] has been moved to Delivered",
    "orderShasBeenMovedToShipping": "Order [%s] has been moved to Shipping",
    "orderShasBeenMovedToTo": "Order[%s] has been moved to Waiting for Pickup",
    "orderSisMarkedAsNotPacked": "Order [%s] is marked as \"Not Packed\"",
    "orderSisMarkedAsPacked": "Order [%s] is marked as \"Packed\"",
    "ordersmarkedPickingStatus": "Order[%s] marked picking status",
    "orderSisMarkedAsNotA": "Order[%s] is marked as \"Not a Fake Order\"",
    "orderSisMarkedAsFakeOrder": "Order[%s] is marked as \"Fake Order\"",
    "orderSHasBeenOperatedDelivery": "Order[%s] has been operated [Delivery Warehouse]",
    "orderSmarkedAsPickingListNot": "Order[%s] is marked as Picking List Not Printed",
    "orderSmarkedAsPickingListPrinted": "Order[%s] is marked as Picking List Printed",
    "orderSmarkedAsPackingListNot": "Order[%s] is marked as Packing List Not Printed",
    "orderSmarkedAsPackingListPrinted": "Order[%s] is marked as Packing List Printed",
    "orderSmarkedAsInvoiceNotPrinted": "Order[%s] is marked as Invoice Not Printed",
    "orderSmarkedAsInvoicePrinted": "Order[%s] is marked as Invoice Printed",
    "orderSmarkedAsLabelNotPrinted": "Order[%s] is marked as Label Not Printed",
    "orderSmarkedAsLabelPrinted": "Order[%s] is marked as label printed",
    "pleaseConfirmTheInstallationOfGinee": "Please confirm the installation of Ginee on the Accurate page, and refresh the page after installation",
    "allActivities": "All Promotions",
    "eventStartTimeAscending": "Promotion Start Time Ascending",
    "eventStartTimeDescending": "Promotion Start Time Descending",
    "eventEndTimeAscending": "Promotion End Time Ascending",
    "eventEndTimeDescending": "Promotion End Time Descending",
    "sortEventStartTime": "Sort Promotion Start Time",
    "thisOperationOnlyMarksWhetherThe": "This operation only marks whether it is a fake order or not, after marking, it will not affect the outbound list and the warehouse delivery",
    "IsTheHighestPriority": "1 is the highest priority, 20 is the lowest priority",
    "optional": "Optional",
    "mandatory": "Mandatory",
    "shippingInsurance": "Shipping Insurance",
    "xModifiedWarehouseStockOutboundOrder": "{x} modified warehouse stock, outbound order completed, remark:",
    "xModifiedWarehouseStockCreatedOutbound": "{x} modified warehouse stock, created outbound order, remark:",
    "xManuallyReplacesTheReservedWarehouse": "{x} manually replaces the reserved warehouse, from warehouse {y} to warehouse {z}",
    "pleaseModifyTheReservedPromotionStock": "Please modify the reserved promotion stock quantity carefully!\n- Reducing reserved stock will increase available inventory, and promotion stock may to be deducted from other channels stock during multi-channel sales which leads in oversell\n- Modifying reserved stock in Ginee will only change promotion stock quantity in Ginee. It won't affect reserved stock in Seller Center",
    "commissionFeeMapping": "Commission Fee Mapping",
    "priorityDelivery": "Priority Delivery",
    "flashSale": "Flash Sale",
    "productPromotion": "Product Promotion",
    "eventStartTime": "Promotion Start Time",
    "eventEndTime": "Promotion End Time",
    "notPrintable": "Not Printable",
    "theFaceSheetCanBePrinted": "Shipping Label can be printed",
    "thTrackingNumberNotBeen": "The tracking number has not been generated yet",
    "theOrderStatusCannotPrintThe": "The order status do not support printing Shipping Label",
    "platformFulfillmentOrdersDoNotSupport": "Platform fulfillment orders do not support printing Shipping Label",
    "thisChannelDoesNotSupportPrinting": "This channel does not support printing Shipping Label",
    "theChannelHasNotReturnedThe": "The channel has not returned the AWB yet, please wait patiently. If the AWB is still not synced after a long time, please go to the Seller Center to confirm whether it has been generated, then sync the order and try again.",
    "theChannelShippingLabelHasNot": "The channel shipping label has not been generated, please try again later by synchronizing the order",
    "syncAll": "Sync All",
    "noShippingLabelToBePrint": "No shipping label to be print",
    "promotionEnd": "Expired",
    "handleProblem": "Handle Problem",
    "stockMonitoringAndSynchronizationSettingsAre": "Stock Monitor and Sync settings are not turned on",
    "thereAreUpToFixed": "There are up to 5 fixed bump products",
    "afterTurningOffTheAutomaticallyReserve": "After turning off the automatically Reserve Promotion stock, Shopee's promotion stock will not be synchronized and will not be reserved",
    "turnOffTheSwitchToAutomatically": "Turn off the switch to Automatically Reserve Promotion Stock",
    "setTheReservationPolicyToUpcoming": "Set the Reservation Strategy to \"Upcoming and Ongoing Promotions Are Automatically Reserved\"",
    "setTheReservationPolicyToAutoreserve": "Set the Reservation Strategy to \"Automatically reserving Ongoing promotions only\"",
    "turnOnTheSwitchToAutomatically": "Turn on the switch to Automatically Reserve Promotion Stock",
    "processingPromotionOnlySupportModifyThe": "Ongoing promotion only support modifying warehouse, do not support to modifying reserved stock",
    "displayingWarehouseWhereTheMskuOf": "Displaying warehouse where the MSKU of the promotional product is allocated, and the warehouse can be changed.",
    "displayingTheBoundMskuOfThe": "Displaying the bound MSKU of the promotional product and the promotion stock quantity reserved in Ginee",
    "displayTheChannelSkuOfThe": "Displaying the channel SKU of the promotional product and promotion stock quantity that has successfully registered in the platform promotion",
    "productParticipatedInPromotionButIs": "Product participated in promotion, but is not reserving promotion stock. Expired promotion data will only remain for 30 days, after 30 days the data can't be viewed in the list",
    "productParticipatedInPromotionAndOnly": "Product participated in promotion, and only some variations are reserving promotion stock",
    "productsIncludingAllVariationsAreParticipating": "Products (including all variations) participated in promotion, the promotion stock has been reserved",
    "thisPageCanOnlyViewAnd": "This page can only view and manage promotions created after June 9, 2023",
    "channelReturnFailedReasonX": "Channel return failed, Reason: {x}",
    "storeWillExp": "{m} Store Will Expire Soon",
    "storeHasExp": "{m} Store Has Expired",
    "big": "Big",
    "small": "Small",
    "standa": "Standard",
    "onlySupportsAdjustingSkuAndQuantity": "Only supports adjusting SKU and quantity font size",
    "fontSiz": "Font Size:",
    "paperSize": "Paper Size",
    "disableHighspeedPrintingForSshop": "Disable high-speed printing for [%s] shop",
    "enableHighspeedPrintingForSstore": "Enable high-speed printing for [%s] store",
    "disableTheHighSpeedPrintingButton": "Disable the [High Speed Printing] button",
    "enableTheHighSpeedPrintingSwitch": "Enable the [High Speed Printing] button",
    "afterDisablingAllStoresWillNot": "After disabling, all stores will not be able to use high-speed printing, and the time to obtain channel shipping label when mass printing will increase",
    "areYouSureToTurnOff": "Are you sure to turn off high-speed printing?",
    "shopeeWillMarkThePredownloadedOrder": "Shopee/Lazada/Tiktok will mark the pre-downloaded order as Printed, please choose whether to turn on the switch according to your needs",
    "afterEnablingGineeWillDownloadThe": "After enabling, Ginee will download the channel shipping label in advance that will allow high-speed printing, and can greatly reduce the waiting time when mass printing",
    "syncToObtainLatestLogisticsInformation": "Sync to obtain latest logistics information",
    "massSyncToObtainTheLatest": "Mass sync to obtain the latest Shopee and Tokopedia orders' logistics information",
    "syncLogistics": "Sync Logistics",
    "package": "Package",
    "storeAuthorizedtotalAmount": "Store (Authorized/Total Amount)",
    "subaccountUsedtotalAmount": "Sub-account (Used/Total Amount)",
    "remainingOrderQuantity": "Remaining Order Quantity",
    "remainingMDays": "Remaining {m} Days",
    "packageRemainingMDays": "Package Remaining {m} Days",
    "toEnsureNormalUsePleaseRenew": "After the package expires, stock synchronization switch will automatically turn off. To ensure normal use, please renew in time",
    "packageRemainingValidityMDays": "Package Remaining Validity {m} Days",
    "noReminderToday": "No Reminder Today",
    "theOperationIsTooFrequentPlease": "The operation is too frequent, please try again in 5 minutes",
    "yourCurrentPackageDoesNotSupport": "Your current package does not support authorization of this channel, please upgrade the OMS package before authorizing",
    "thisMethodIsOnlyOpenTo": "This method is only open to designated merchants. Please set it carefully. For more information, refer to {m}",
    "shopeeOfficialInstructions": "Shopee official instructions",
    "actualShippingFee": "Actual Shipping Fee",
    "ifAutomaticallyReserveShopeePromotionStock": "If [Automatically Reserve Shopee Promotion Stock] is enabled, the Shopee promotion order will not be allocated according to the Shipping Warehouse rules, but will be locked according to the warehouse reserved for the promotion",
    "afterEnablingGineeWillAutomaticallyPull": "After enabling, Ginee will automatically pull Tokopedia order's BookingCode",
    "turnOffTheAutomaticallyPullLogistics": "Turn off the [Automatically Pull Logistics Information] button",
    "turnOnTheAutomaticallyPullLogistics": "Turn on the [Automatically Pull Logistics Information] button",
    "automaticallyPullLogisticsInformation": "Automatically Pull Logistics Information",
    "disabled1": "Disabled",
    "enabled": "Enabled",
    "newlyAuthorizedStoresAreAutomaticallySet": "Newly authorized stores are automatically set to \"Enabled\"",
    "confirmToReauthorize": "Confirm to reauthorize",
    "noteTiktokReauthorizationDoesNotExtend": "Note: TikTok reauthorization does not extend the validity period. To extend the authorization validity period, please go to the TikTok Seller Center to unintegrate Ginee first, and then reauthorize",
    "sellerFullAddress": "Seller full address",
    "thereIsNoOrderThatCan": "No orders can be printed using fast printing, please confirm that the store where the order is located has enabled [Fast Printing]",
    "startSynchronizingLatestOrderLogisticsInformation": "Start synchronizing latest order logistics information, it is expected to take a few minutes, please refresh the page later to view the latest logistics information",
    "video": "Video",
    "tryHighlightingCoreSellingPoints": "Try highlighting 1-2 main selling points for your product. This will be displayed in the product details section.",
    "weRecommendTheVideoDurationIs": "We recommend that the video duration should be less than 20 seconds, and the longest should not exceed 60 seconds.",
    "recommendedRatioIsAndRecommended": "The recommended ratio is 1:1 and recommended resolution is above HD 720p.",
    "ifYouUploadAVideoIt": "The aspect ratio of the uploaded video should be 9:16 or 16:9. The file size cannot exceed 30MB and the format is MP4.",
    "tiktokRequired": "TikTok Requirements：",
    "changingCategoryWillNotAffectYour": "Changing category will not affect your product information such as sales performance and product ratings, but will clear the product attributes. Are you sure you want to change it?",
    "basedOnTheTitleYouEntered": "Based on the Product Name you entered, the product category could be (please choose as needed)",
    "economical": "Economical",
    "cargo": "Cargo",
    "standardShipping": "Standard Shipping",
    "pleaseInputTheValueOfWeight": "Please input the value of weight greater than 5.00kg",
    "deliveryOptions": "Delivery Options",
    "lineShoppingOnlySupportsSettingThe": "Line Shopping only supports setting the instant discount amount uniformly for all variations",
    "compulsoryOutbound": "The outbound list has been pushed to the fulfillment warehouse, please perform outbound in the fulfillment warehouse. If you continue to operate, only the outbound order in OMS will be forced out, which may cause inconsistency with the actual outbound data of the fulfillment warehouse. Are you sure you want to continue?",
    "compulsoryCancel": "The outbound list has been pushed to the fulfillment warehouse, please perform outbound in the fulfillment warehouse. If you continue to operate, you will only forcibly cancel the outbound list in OMS, which may cause inconsistency with the actual outbound data of the fulfillment warehouse. Are you sure you want to continue?",
    "noPermission": "You do not have permission to access this page, please refresh the page and try again after upgrading your package. For staff accounts, please contact the main account to add the role permissions",
    "refuseToAddWms": "You do not have permission to add this warehouse, please contact your Warehouse Service Provider to add the Consignor Account for you and authorize the warehouse",
    "theProductDescriptionShouldGiveThe": "The product description should give the customer useful information about the product to ensure a purchase",
    "missingOrderInformation": "Missing Order Information",
    "compartmentDimensionInShippingWarehouseSetting": "Compartment dimension in Shipping Warehouse setting changed from {1} to {2}",
    "priorityOfWarehouseAlocationInShipping": "Priority of warehouse allocation in Shipping Warehouse setting changed from {1} to {2}",
    "warehouseInRuleChangedFrom": "Warehouse in rule {0} changed from {1} to {2}",
    "startPullingTheOrderDataIt": "It is estimated to take a few minutes to start to retrieve order data. So, please pay attention to the synchronization of store data later.",
    "theRecipientsInformationCanBeDisplayed": "The recipient's information can be displayed in text on the shipping label, but the recipient's information may be masked.",
    "theCompleteRecipientInformationCanBe": "The complete recipient information can be displayed in the form of a picture on the shipping label, but some printers may not recognize the picture.",
    "customShipmentWarning": "Custom Shipment Warning",
    "thisSystemWarehouseHasBeenBound": "This System Warehouse has been bound by other Channel Warehouses!",
    "completeRecipientInformationCanBe": "The complete recipient information can be displayed in the form of a picture on the shipping label",
    "recipientsInformationCanBeDisplayed": "The recipient's information can be displayed in text on the shipping label",
    "instantDiscountAmount": "instant discount amount",
    "originalPriceShouldBeHigherThan": "Original Price should be higher than instant discount amount",
    "YourOmsWillBeUpgraded": "1. Your OMS will be upgraded to a full-featured version, which can authorize multi-channel stores, process orders, and manage inventory;",
    "YouWillAutomaticallyBindThe": "2. You will automatically bind the service provider's warehouse, and you can push the order to the service provider's warehouse, and the service provider will fulfill the orders;",
    "theFulfillmentServiceProviderXInvites": "The fulfillment service provider {x} invites you to open the fulfillment warehouse {y}",
    "afterAgreeing": "After agreeing:",
    "areYouSureYouAgreeTo": "Are you sure you agree to activate?",
    "confirmActivation": "Confirm Activation",
    "youHaveNewApplication": "You Have {m} New Application",
    "thisChannelCanBeAuthorizedAfter": "This channel can be authorized after package upgrade",
    "theCurrentPackageDoesNotSupport": "The current package does not support authorizing this channel",
    "enableFailedYouCanOnlyAuthorize": "Enable failed! The store exceeds the package resource limit",
    "afterUpgradingThePackageMoreMaster": "After upgrading the package, more Master Products can be added",
    "addFailedMasterProducts": "Add failed! Master Products quantity has reached the package limit",
    "masterProductQuantityHasReachedThe": "Master Product quantity has reached the package limit",
    "additionalXConsignorAccountsbusiness": "Additional x 5 Consignor Accounts",
    "additionalXGineeWmsOrders": "Additional x 10,000 Ginee WMS Orders",
    "additionalXStores": "Additional x 10 Stores",
    "additionalXStaffAccounts": "Additional x 5 Staff Accounts",
    "additionalXGineeOmsOrders": "Additional x 10,000 Ginee OMS Orders",
    "addons": "AddOns",
    "offlineOnboardingOn": "Offline Onboarding (1 on 1)",
    "accountManagerOn": "Account Manager (1 on 1)",
    "onlineTraining": "Online Training",
    "liveChatSupport": "Live Chat Support",
    "implementationAndCustomerCare": "Implementation and Customer Care",
    "XConsignorAccountsBusiness": "10 x Consignor Accounts (Business)",
    "servicesToExternalBrands": "Services to External Brands",
    "serviceProviderManagement": "Service Provider Management",
    "gineeWmsOpenApi": "Ginee WMS Open API",
    "pdaapp": "PDA/APP",
    "fifofefo": "FIFO/FEFO",
    "smartReplenishment": "Smart Replenishment",
    "stocklocationTransfer": "Stock/Location Transfer",
    "waveShipping": "Wave Shipping",
    "gineeWmsWarehouseManagementSystem": "Ginee WMS (Warehouse Management System)",
    "gineeOmsOpenApi": "Ginee OMS Open API",
    "theThirdPartyIntegrationposLogisticsFulfillment": "Third Party Integration (POS, Logistics, Fulfillment, OMS, Accounting）",
    "multiwarehouseManagement": "Multi-Warehouse Management",
    "reporting": "Reporting",
    "stockManagement": "Stock Management",
    "gineeOmsOrderManagementSystem": "Ginee OMS (Order Management System)",
    "noOfSalesChannels": "No. Of Sales Channels",
    "noOfMskus": "No. Of MSKUs",
    "noOfStores": "No. Of Stores",
    "noOfStaffAccounts": "No. Of Staff Accounts",
    "noOfCountriesinternational": "No. Of Countries(International)",
    "noOfGineeWmsOrders": "No. Of Ginee WMS Orders",
    "noOfGineeOmsOrders": "No. Of Ginee OMS Orders",
    "ecommerceOperations": "Ecommerce Operations",
    "buyItNow": "Buy It Now",
    "clickMeToGetDiscount": "Get Discount",
    "customization": "Customization",
    "youHaveMNewMessages": "You have {m} new messages",
    "officialAccounts": "Official Accounts",
    "theThirdPartyIntegration": "Third Party Integration",
    "thereAreUnrecognizableCharactersSuchAs": "There are unrecognizable characters,please modify and submit",
    "currentlyDoNotSupportIntegratingCnsc": "Currently do not support integrating CNSC or 3PF stores",
    "manuallyRepushThePushFailedData": "Manually re-push the \"push failed\" data",
    "paymentTerms": "Payment Term",
    "bankAccount": "Bank Account",
    "issuingBank": "Issuing Bank",
    "taxIdNumber": "Tax ID Number",
    "contactPerson": "Contact Person",
    "website": "Website",
    "onlyLettersAndNumbersAreSupported": "Only letters, numbers and '- _ . /' are supported, please re-enter",
    "theContentYouEnteredContainsIllegal": "The content you entered contains illegal characters, please re-enter",
    "autoGenerate": "Auto Generate",
    "supplierName": "Supplier Name",
    "supplierCode": "Supplier Code",
    "createNewSupplier": "Create New Supplier",
    "importSupplier": "Import Supplier",
    "supplierList": "Supplier List",
    "purchaseManagement": "Purchase Management",
    "supplierManagement": "Supplier Management",
    "purchase": "Purchases",
    "disableenable": "Disable/Enable",
    "supplierCodeDoesNotExist": "Supplier Code does not exist",
    "supplierNameAlreadyExists": "Supplier Name already exists",
    "supplierCodeAlreadyExists": "Supplier Code already exists",
    "failedToDeleteTheSupplierAlready": "Failed to delete! The Supplier already has Purchase Order or is not in Disabled status",
    "onlySuppliersWithDisabledStatusAnd": "Only Suppliers with Disabled status and no Purchase Orders can be deleted",
    "wantToDeleteTheSupplier": "Are you sure you want to delete the Supplier?",
    "wantToEnableTheSupplier": "Are you sure you want to enable the Supplier?",
    "failedToDisableThisSupplierHas": "Failed to disable! This Supplier has unfinished Purchase Order",
    "suppliersWithUnfinishedPurchaseOrdersCannot": "Suppliers with unfinished Purchase Orders cannot be disabled, please proceed with caution",
    "wantToDisableTheSupplier": "Are you sure you want to disable the Supplier?",
    "thisTypeHasBeenSelectedBy": "This Supplier Type has been selected for a Supplier and cannot be deleted",
    "wantToDeleteTheSupplierType": "Are you sure you want to delete the Supplier Type?",
    "typeName": "Type Name",
    "addNewType": "Add New Type",
    "fullPayment": "Full Payment",
    "supplierCodeCannotBeEditedAfter": "Supplier Code cannot be edited after creation",
    "xAlreadyExists": "{x} already exists",
    "pleaseFillInThisField": "Please fill in this field",
    "theAmountMustBeBetweenXy": "The amount must be between {x}~{y}, 2 decimal places are allowed",
    "amountMustBeBetweenXyAnd": "The amount must be between {x}~{y}, and decimals are not allowed.",
    "partialArrival": "Partial Delivery",
    "inTransit": "In Transit",
    "paymentStatus": "Payment Status",
    "purchaseOrder": "Purchase Order",
    "paymentMethod": "Payment Method",
    "paid": "Paid",
    "partialPayment": "Partially Paid",
    "unpaid": "Unpaid",
    "feeInformation": "Fee Information",
    "deliveredQuantity": "Delivered Quantity",
    "estimatedDateOfArrival": "Estimated Date of Arrival",
    "purchasingStaff": "Purchasing Staff",
    "receivingWarehouse": "Receiving Warehouse",
    "puchaseDate": "Puchase Date",
    "purchaseOrderSource": "Purchase Order Source",
    "noSupplier": "No Supplier",
    "purchaseOrderNumberAlreadyExists": "Purchase Order Number already exists",
    "purchaseOrderNumberCannotBeEdited": "Purchase Order Number cannot be edited after it is created",
    "pleaseEnterPurchaseOrderNumber": "Please enter Purchase Order Number",
    "purchaseOrderNumber": "Purchase Order Number",
    "basicInfoma": "Basic Information",
    "otherNoncash": "Other Non-Cash",
    "virtualAccount": "Virtual Account",
    "paymentLink": "Payment Link",
    "qris": "QRIS",
    "edc": "EDC",
    "bankTransfer": "Bank Transfer",
    "chequeclearing": "Cheque/Clearing",
    "cash": "Cash",
    "discou": "Discount",
    "productPurchasePrice": "Product Purchase Price",
    "purchaseOrderTotalPrice": "Purchase Order Total Price",
    "skuQuantity": "SKU Quantity",
    "totalPriceInformation": "Total Price Information",
    "inboundCostPrice": "Inbound Cost",
    "individualCostSharing": "Individual Allocated Cost",
    "taxRate": "Tax Rate",
    "onlyNumbersFromTo": "Only numbers from {x}~{y} are allowed, decimals are not supported",
    "discountRate": "Discount Rate",
    "purcPrice": "Purchase Price",
    "quantityPcs": "Quantity (pcs)",
    "unit": "Unit",
    "volumeM": "Volume (cm³)",
    "productPriceIncludesTax": "Product price includes tax",
    "massAddd": "Mass Add",
    "addProdu": "Add Product",
    "dividedByQuantity": "Allocate Based On Quantity",
    "dividedByVolume": "Allocate Based On Volume",
    "dividedByWeight": "Allocate Based On Weight",
    "dividedByPrice": "Allocate Based On Price",
    "notDivided": "Not Allocated",
    "fillInmodifyTrackingNumber": "Fill in/modify Tracking Number",
    "purchaseNote": "Purchase Note",
    "thePurchaseOrderWarehouseIsWmsthirdparty": "The Purchase Order warehouse is WMS/Third-Party Warehouse. Please notify the fulfillment warehouse to cancel the Purchase Order before proceeding.",
    "failedToCancelThePurchaseOrder": "Failed to cancel the Purchase Order",
    "cancellationFailedInboundProductsInThis": "Cancellation failed! Inbound products in this Purchase Order has been completed. Please refresh the page to check the status of the Purchase Order again",
    "whenAPurchaseOrderIsCancelled": "When a Purchase Order is cancelled, Inbound purchases will become invalid and Transit Stock will be released",
    "wantToCancelThePurchaseOrder": "Are you sure you want to cancel the Purchase Order?",
    "wantToDeleteThePurchaseOrder": "Are you sure you want to delete the Purchase Order?",
    "IfTheReceivingWarehouseIs": "2. If the receiving warehouse is a Third Party Warehouse, Ginee will automatically push the Inbound purchase to the fulfillment warehouse",
    "AfterSubmissionThePurchaseOrder": "1. After submission, the Purchase Order will become In Transit status and a Inbound purchase will be generated, and the product will be added to the Transit Stock",
    "youWantToSubmitThePurchase": "Are you sure you want to submit the Purchase Order?",
    "identify1": "Identify",
    "copyMskuFromExcelAndPaste": "Copy MSKU from Excel and paste it here. Please use enter to input multiple MSKUs. Each line represents one information.",
    "EstimatedTimeOfArrivalFormat": "6. Estimated Time of Arrival format: Year/Month/Day (For example: 2023/08/23)",
    "WhenTheReceivingWarehouseIs": "3. When the receiving warehouse is a Third-Party Warehouse, if the MSKU is not pushed to the Third-Party Warehouse, please go to the Stock List page to add the SKU to the Third-Party Warehouse;",
    "ExplanationOfTheShippingFeeother": "4. Explanation of the Shipping Fee/other cost allocation method: \n- When selecting \"Price\", allocation will be based on the price of each SKU\n- When selecting \"Quantity\", allocation will be based on the purchase quantity of each SKU\n- When selecting \"Volume\", allocation will be based on the volume of each SKU\n- When selecting \"Weight\", allocation will be based on the weight of each SKU. \nIf Shipping Fee/other expenses are not filled in, by default it won't be allocated;",
    "purchaseOrderContainsMultipleProductsYou": "3. When a Purchase Order contains multiple products, you need to fill in the complete product information for each data. For public information (basic information, cost information, other information), only the first data needs to be filled in. When the public information of multiple data is inconsistent, the first data will prevail;",
    "WhenAPurchaseOrderContains": "2. When a Purchase Order contains multiple products, please use the same Purchase Order Number. When importing, multiple rows of data with the same Purchase Order Number will be merged into one Purchase Order;",
    "exportPurchaseOrder": "Import Purchase Order",
    "printPurchaseOrder": "Print Purchase Order",
    "changePaymentStatus": "Change Payment Status",
    "signature": "Signature",
    "merchantWarehouseAddress": "Merchant Warehouse Address",
    "contactInformation": "Contact",
    "supplierAddress": "Supplier Address",
    "supplier": "Supplier",
    "supplierInformation": "Supplier Information",
    "merchantInformation": "Merchant Information",
    "explanation": "Explanation:",
    "UpToDataCan": "1. Up to 10,000 data can be imported at once (format: xlsx, xls);",
    "estimatedTimeOfArrival": "Estimated Time of Arrival",
    "warehouseType": "Warehouse Type",
    "theEntireInboundWillBeInvalidated": "The entire Inbound will be invalidated and the transit stock will be released",
    "completeInbound": "Complete Inbound",
    "partialInbound": "Partial Inbound",
    "afterInvalidationIsInitiatedOnlyThe": "After invalidation is initiated, only the OMS Inbound will be invalided, and the status of the Inbound in the fulfillment warehouse will not change",
    "onlyTheErpInboundIsInvalided": "Only the OMS Inbound is invalided",
    "afterTheInvalidationIsInitiatedThe": "After the invalidation is initiated, the invalidation will be notified to fulfillment warehouse. After the invalidation is successful, the OMS status of the fulfillment warehouse will change to invalid",
    "invalidErpAndFulfillmentInbound": "Invalidate OMS and fulfillment Inbound",
    "invalidationMethod": "Invalidation Method:",
    "theSkuWillBecameInvalidAnd": "The SKU will be invalided and Transit Stock will be released",
    "pleaseSelectTheInvalidationMethod": "Please select the Invalidation Method",
    "inboundFullAmount": "Inbound Full Amount",
    "presentInboundQuantity": "Current Inbound Quantity",
    "pendingInboundinboundCompletedplannedInbound": "Pending Inbound/Inbound Completed/Planned Inbound",
    "afterTheInboundIsCompletedThe": "After the Inbound is completed, the Inbound status will change to \"Inbound Complete\" and cannot be continued",
    "sureYouWantToEndThe": "Are you sure you want to end the Inbound?",
    "pushResult": "Store Update Result",
    "receiveItem": "Receive Item",
    "afterDisablingThePrintedPurchaseOrder": "After disabling, the printed Purchase Order will not display the product amount and fees (Shipping Fee/other fees/Total Amount)",
    "amountDisplay": "Amount Display",
    "purchaseOrderPrintingSettings": "Purchase Order Printing Settings",
    "purchaseSetting": "Purchase Settings",
    "receivingTime": "Receiving Time",
    "inboundTotalAmount": "Total Inbound Quantity",
    "receiveItemNumber": "Receive Item  Number",
    "supplierTypeAlreadyExists": "Supplier Type already exists",
    "supplierType": "Supplier Type",
    "supplierTypeDoesNotExist": "Supplier Type does not exist",
    "theDataIsAbnormalAndThe": "The data is abnormal, the export task does not exist",
    "xParameterIsIncorrect": "{x} parameter is incorrect",
    "xParameterCannotBeEmpty": "{x} parameter cannot be empty",
    "thePurchaseOrderAlreadyExists": "The Purchase Order already exists",
    "purchaseOrderDoesNotExist": "The Purchase Order does not exist",
    "failedTheWarehouseTypeWasNot": "Failed, the warehouse type was not obtained",
    "purchaseOrderCancellationFailed": "Purchase Order cancellation failed",
    "purchaseOrderDeletionFailed": "Purchase Order deletion failed",
    "purchaseOrderSubmissionFailed": "Purchase Order submission failed",
    "purchaseOrderIsMissingProductInformation": "Purchase Order is missing product information",
    "purchaseOrderIsNotInDraft": "Purchase Order is not in \"Draft\" status",
    "purchaseOrderCancellationFailedPleaseRefresh": "Purchase Order cancellation failed. Please refresh the page and try again",
    "purchaseOrderDeletionFailedPleaseRefresh": "Purchase Order deletion failed. Please refresh the page and try again",
    "purchaseOrderSubmissionFailedPleaseRefresh": "Purchase Order submission failed. Please refresh the page and try again",
    "theSupplierDoesNotExists": "Supplier does not exist",
    "supplierAlreadyExists": "Supplier already exists",
    "theImportedDataIsEmpty": "The imported data is empty",
    "theImportedFileFormatIsNot": "The imported file format is not supported",
    "theOperationTaskDoesNotExist": "Operation task does not exist",
    "theImportTaskDoesNotExist": "Import task does not exist",
    "theExportTaskDoesNotExist": "Export task does not exist",
    "createAndPushSalesInvoicesWhen": "Create and push Sales Invoices when the outbound is completed",
    "inboundAmount": "Inbound Amount",
    "goodsReceivedNoteNumber": "Receive Item  Number",
    "quotationGeneratedSuccessfully": "The Quotation is generated successfully!",
    "theChannelWarehouseIsNotAssociated": "The Channel Warehouse is not associated with the Ginee Warehouse",
    "multiWarehouseChannelProductsAreNot": "Multi Warehouse Channel Products are not associated with warehouse",
    "doesNotComplyWithTheRules": "Does not comply with the rules: All connected stores, excluding new stores authorized later",
    "matchingDefaultPushRulesAreDisabled": "Matching Default Push Rules are disabled",
    "matchingStorePushRulesAreDisabled": "Matching Store Push Rules are disabled",
    "matchingCustomPushRulesAreDisabled": "Matching Custom Push Rules are disabled",
    "shopifyProductInventoryIdDoesNot": "Shopify Product Inventory ID does not exist, stock update is not allowed",
    "blibliProductsAreDisabledAndStock": "Blibli products are disabled and stock updates are not allowed",
    "returnFromTikiChannelForThis": "Return from Tiki channel for this product: Updates not allowed",
    "theStoreHasBeenDisconnected": "The store has been disconnected",
    "tikiProductInboundStatusDoesNot": "Tiki product Inbound status does not allow pushing stock",
    "masterProductNotFound": "Master Product not found",
    "thePushTaskAlreadyExistsAnd": "The push task already exists and does not need to be pushed again.",
    "failedAlreadyRepushed": "Failed, already re-pushed",
    "pleaseSelectTheReceivingWarehouseFirst": "Please select the receiving warehouse first",
    "ifNotFilledInItWill": "If not filled in, it will be automatically generated",
    "region": "Region",
    "cityNew": "City",
    "provinc": "Province",
    "districtsNew": "Districts",
    "purchaseQuantity": "Purchase Quantity",
    "otherCostAllocationMethodsPleaseSelect": "Other Cost Allocation Methods (Please select)",
    "otherCostTotal": "Other Cost (Total)",
    "shippingFeeAllocationMethodPleaseSelect": "Shipping Fee Allocation Method (Please select)",
    "shippingFeeTotal": "Shipping Fee (Total)",
    "wantToUnbindTheWarehouse": "Are you sure you want to unbind the warehouse?",
    "afterUnbindingChangesInStockIn": "After unbinding, changes in stock in the System Warehouse will not be pushed to the Channel Warehouse.",
    "unbindTheChannelWarehouseAaaFrom": "Unbind the Channel Warehouse {AAA} from the System Warehouse",
    "thisFeatureIsOnlyAvailableFor": "This feature is only available for Tokopedia Official Store",
    "deleteSupplier": "Delete Supplier",
    "enableSupplier": "Enable Supplier",
    "disableSupplier": "Disable Supplier",
    "editSupplier": "Edit Supplier",
    "manualDeleteSupplierX": "Manual Delete Supplier [{x}]",
    "enableSupplierX": "Enable Supplier [{x}]",
    "disableSupplierX": "Disable Supplier [{x}]",
    "importToUpdateSupplierX": "Import to Update Supplier [{x}]",
    "manualEditSupplierX": "Manual Edit Supplier [{x}]",
    "importToAddSupplierX": "Import to Add Supplier [{x}]",
    "manualAddSupplierX": "Manual Add Supplier [{x}]",
    "exportSupplierList": "Export Supplier List",
    "exportPurchaseOrderX": "Export Purchase Order  [{x}]",
    "printPurchaseOrderX": "Print Purchase Order  [{x}]",
    "cancelPurchaseOrderX": "Cancel Purchase Order  [{x}]",
    "deletePurchaseOrderX": "Delete Purchase Order  [{x}]",
    "submitPurchaseOrderX": "Submit Purchase Order  [{x}]",
    "editPurchaseOrderXManually": "Manual Edit Purchase Order  [{x}]",
    "importPurchaseOrderX": "Import to Add Purchase Order [{x}]",
    "addPurchaseOrderXManually": "Manual Add Purchase Order  [{x}]",
    "exportPurchaseO": "Export Purchase Order",
    "cancelPurchaseOrder": "Cancel Purchase Order",
    "deletePurchaseOrder": "Delete Purchase Order",
    "submitPurchaseOrder": "Submit Purchase Order",
    "editPurchaseOrder": "Edit Purchase Order",
    "addPurchaseOrder": "Add Purchase Order",
    "shopeeRequirements": "Shopee Requirements：",
    "formatMp": "Format: MP4",
    "durationSs": "Duration: 10s-60s",
    "sizeMaxMbResolutionShouldNot": "Size: Max 30Mb, resolution should not exceed 1280x1280px",
    "myShopeeSellerCenterCanInsert": "Only some Shopee stores can support picture and descriptions, so please make sure your store supports it before turning on this option.",
    "copyCopy": "Copy",
    "thankYouForYourCooperationPlease": "Thank you for your cooperation, please copy the unique ID and send it to Ginee Support team.",
    "weNeedYourConsentToRecord": "We need your consent to record the screen and capture the operation procedure, then identify and solve your issues.",
    "weOnlyRecordThePagesIn": "We only record the pages in Ginee system.",
    "needAuthorization": "Need Authorization",
    "approveStartToRecord": "Approve and start to record",
    "done": "Completed",
    "repeatTheOperationStepsToReproduce": "Repeat the operation steps to reproduce the issue, stop the record after capturing the full flow.",
    "capturingTheProcessingFlowOnThe": "Capturing the operating procedure on the screen",
    "stop": "Stop",
    "uploading": "Uploading",
    "importToUpdateSupplier": "Import to Update Supplier",
    "importToAddSupplier": "Import to Add Supplier",
    "feedback": "Feedback",
    "uploadVideo": "Upload Video",
    "wereNotPerfectButWereListening": "We're not perfect, but we're listening",
    "feedbackForm": "Feedback Form",
    "pleaseSubmitATicektFormWith": "Please submit a ticket form with text description and images attached",
    "feedbackVideo": "Feedback Video",
    "pleaseGrantGineeThePermissionRecord": "- Please grant Ginee the permission to record your operation to diagnose",
    "enterTheSpecificPageStartTo": "- Enter the specific page, start to record and repeat your operation to reproduce the issue",
    "stopTheRecordCopyTheUnique": "- Stop the recording, copy the Unique ID and send to Ginee Support team",
    "faqfeedback": "FAQ/Feedback",
    "needHelp": "Need Help?",
    "detailedDescription": "Detailed Description",
    "trackingNumber": "Tracking Number",
    "enableLongDescription": "Enable picture and description",
    "DetailProductDescription": "Product Description",
    "dueToApiLimitationsBlibliMust": "Due to API limitations, Blibli must use fast printing to download the channel shipping label in advance. Please go to Order Settings - > Fast printing to enable high-speed printing in Blibli stores, and try it again.",
    "abnormal": "Abnormal",
    "theInformationCannotBeFoundIn": "If the information can not be found in Ginee, please confirm whether the content of each field has been matched, or log in to Accurate to modify the information and then synchronize again.",
    "purchasePurchaseOrder": "Purchase / Purchase Order",
    "addToList": "Add To List",
    "modifyingAccuratePurchaseOrderInformationIn": "Modifying Accurate Purchase Order information in Ginee will only update Ginee's data and will not be synchronized to Accurate; Modifying Purchase Order in Accurate in non-draft status will not be synchronized to Ginee.",
    "pleaseSelectProduct": "Please Select Product",
    "gineeOnlySupportsCreatingPurchaseOrder": "Ginee only supports creating Purchase Order for multiple products from the same warehouse. Please log in to Accurate and modify all products to the same warehouse.",
    "supplierContactDetails": "Supplier Contact Details",
    "operationFailed": "Operation failed",
    "whenSelectingMultipleShopeeStoresThe": "When selecting multiple Shopee stores, the stock value can only be updated to the default warehouse; if you need to update the stock to multiple Shopee warehouses, please select only 1 store",
    "requestTimedOutPleaseCheckThe": "Request timed out, please check the network and try again.",
    "manageMultiwarehouseStock": "Manage Multi-warehouse Stock",
    "supplierCodeSupplierCodeCannotBe": "Supplier Code (Supplier Code cannot be edited after creation)",
    "hasReturnrefund": "Has Return/Refund",
    "batchEditingOnlySupportsModifyingA": "Mass editing only supports editing stock in one warehouse. If you need to edit stock in a few warehouses, please use single product edit",
    "invalidationIsInitiatedOnlyTheOms": "After invalidation is initiated, only the OMS Outbound will be invalided, and the status of the Outbound in the fulfillment warehouse will not change",
    "onlyTheOmsOutboundIsInvalided": "Only the OMS Outbound is invalided",
    "invalidateOmsAndFulfillmentOutbound": "Invalidate OMS and fulfillment Outbound",
    "installationTutorial": "Installation Tutorial",
    "howToUse": "How to Use",
    "downloadAndInstallThePlugin": "Download and install the plugin",
    "goToChromeWebStoreTo": "Go to Chrome Web Store to install",
    "ForPluginsHowToUse": "4. For plugin's How to Use, please refer to:",
    "IfYouCannotAccessThe": "3. If you cannot access the Chrome Web Store, download the plugin here:",
    "PluginInstallationLink": "2. Plugin installation link:",
    "RecommendedBrowserGoogleChrome": "1. Recommended browser: Google Chrome",
    "pleaseDownloadAndUseTheScrape": "Please download and use the Scrape plugin, the function is stable and fast!",
    "editAndPublishFromTheStore": "Edit and publish from the store product draft",
    "EditAndPublish": "4. Edit and publish",
    "selectAStoreAndCopyThe": "Select a store and copy the Master Product to the store product draft",
    "CopyAndSellInStores": "3. Copy and sell in stores",
    "massClaimAndCreateMasterProduct": "Mass claim and create Master Product",
    "ClaimTheMasterProduct": "2. Claim the Master Product",
    "visitTheProductPageYouWant": "Visit the product page you want to scrape and use the plugin to scrape it with one click",
    "ScrapeProduct": "1. Scrape product",
    "returnrefund": "Return/Refund",
    "editWarehouseStockInStockList": "Edit Warehouse Stock in Stock List",
    "importToUpdateStockIncreasedecrease": "Import to Update Stock - Increase/Decrease in Value",
    "importToUpdateStockFinal": "Import to Update Stock - Final Value",
    "purchaseOrdersSourcedFromAccurateDo": "Purchase Orders sourced from Accurate do not support cancellation in Ginee. If you need to cancel, please log in to Accurate to cancel.",
    "failedToCreateOmsInboundThe": "Failed to create OMS Inbound: the SKU in the WMS Inbound does not exist in OMS or is a combination product",
    "IfYouHaveDoneThe": "5. If you have done the steps above, please contact  customer service",
    "IfEverythingIsNormalPlease": "4. If everything is normal, please try again after 1 minute",
    "PleaseSwitchTheNetworkAnd": "3. Please switch the network and try again",
    "PleaseConfirmThatTheBrowser": "2. Please confirm that the browser has no special settings or try again after changing the browser",
    "PleaseCheckWhetherYouAre": "1. Please check whether you are using a third-party plug-in such as advertising/domain blocking, etc. It is recommended to close the plug-in and try again",
    "inComplianceWithShopeePolicyBuyer": "After store authorization, Ginee does not actively sync historical orders. If you need to view historical orders, click the [Sync Order] button to sync historical orders as needed. In compliance with Shopee policy requirements, customer information will no longer be displayed for historical orders that exceed 90 days. Thank you for your understanding",
    "inComplianceWithShopeePolicyThe": "In compliance with Shopee policy, the Customer List will not provide Shopee order data.",
    "massAddHistory": "Mass Add History",
    "cancellation": "Cancellation",
    "failedDelivery": "Failed Delivery",
    "ordersHasBeenMovedToReturnrefund": "Order[%s] has been moved to Return/Refund",
    "moveToReturnrefund": "Move To Return/Refund",
    "ordersHasBeenMovedToCompleted": "Order[%s] has been moved to Completed",
    "moveToCompleted": "Move to Completed",
    "youWantToMoveTheOrder": "Are you sure you want to move the order to Completed?",
    "confirmToSync": "Confirm to sync?",
    "purchaseSupplierManagement": "Purchase & Supplier Management",
    "afterClosingAllStoresWillNot": "After disabling, all stores will not be synced and Shopee promotion stock will not be reserved",
    "wantToDisableAutomaticallyReservePromotion": "Are you sure you want to disable Automatically Reserve Promotion Stock?",
    "pushingPleaseRefreshThePageLater": "Pushing, please refresh the page later to view the latest Push Status",
    "wantToMassDisableAutoPush": "Are you sure you want to mass disable Auto Push?",
    "wantToMassEnableAutoPush": "Are you sure you want to mass enable Auto Push?",
    "onlyGineeWmsWarehouseSupportsEditing": "Only Ginee WMS warehouse supports editing Push Strategy",
    "editPushStrategy": "Edit Push Strategy",
    "disableAutoPush": "Disable Auto Push",
    "enableAutoPush": "Enable Auto Push",
    "autoPush": "Auto Push",
    "pushStrategy": "Push Strategy",
    "theOrderWillBePushedTo": "The order will be pushed to the third-party warehouse early according to the configuration conditions. Other orders will still be pushed according to the Normal Push. Problem Orders will not be pushed",
    "regardlessOfWhetherTheOrderIs": "Regardless of whether the order is eligible for shipping (no shipping arranged, no AWB, no shipping label), all orders that are synchronized with \"Paid\" and \"Ready to Ship\" status, after the Outbound List are generated will be immediately pushed to the third party warehouse, Problem Orders will not be pushed",
    "theDefaultPushRuleIsThat": "The default push rule is that only orders that have obtained shipping label are allowed to be pushed to third-party warehouses, and Problem Orders will not be pushed",
    "strategyDescription": "Strategy Description",
    "deleteFailedThereIsAWarehouse": "Delete failed! There is a warehouse in use!",
    "onlyStrategiesThatAreNotAssociated": "Only strategies that are not associated with a warehouse can be deleted",
    "wantToDeleteTheStrategy": "Are you sure you want to delete the strategy?",
    "allowEarlyPushWhenTheOrder": "Allow early push when the order status is \"Paid\"",
    "pleaseSetAtLeastOneAdvance": "Please set at least one Early Push condition. Only orders that meet the selected conditions will be triggered for Early Push. Otherwise, Normal Push will be performed",
    "upToCustomStrategiesCan": "Up to 100 custom strategies can be added",
    "warehouseUsed": "Warehouse Used",
    "pushPaidOrdersInAdvance": "Push \"Paid\" Orders Early",
    "strategyName": "Strategy Name",
    "manageCustomPolicies": "Manage Custom Strategy",
    "customAdvancePush": "Custom Early Push",
    "pushAllInAdvance": "Push All Early",
    "basicPush": "Normal Push",
    "normalPushPushAllEarlyCustom": "Normal Push, Push All Early, Custom Early Push can be set",
    "thirdpartyWarehousePushSettings": "Third-party Warehouse Push Settings",
    "quantityLimitInfo": "quantity purchase must not be more than 1000",
    "valueShouldBeAtLeastThe": "The value should be at least {count}",
    "purchaseLimit": "Purchase Limit",
    "maxPerOrder": "Max. per order",
    "storesAreAutomaticallySetToEnable": "[Newly Authorized Stores Are Automatically Set To \"Enable\"] Disabled",
    "authorizedStoresAreAutomaticallySetTo": "[Newly Authorized Stores Are Automatically Set To \"Enable\"] Enabled",
    "storeXDisableTheAutomaticallyReserve": "Store {x} disable the Automatically Reserve Promotion Stock main switch",
    "storeXEnableTheAutomaticallyReserve": "Store {x} enable the Automatically Reserve Promotion Stock main switch",
    "disableAutomaticallyReservePromotionStockMain": "Disable Automatically Reserve Promotion Stock Main Switch",
    "enableAutomaticallyReservePromotionStockMain": "Enable Automatically Reserve Promotion Stock Main Switch",
    "enabledisableAutomaticallyReservePromotionStock": "Enable/Disable Automatically Reserve Promotion Stock",
    "change": "Change",
    "superLinkAsAnOpenDoor": "Super link as an open door to connect all your businesses storelinks, contents, etc in one page!",
    "actualInboundQty": "Actual Inbound Qty",
    "plannedInboundQty": "Planned Inbound Qty",
    "tipShopeePendingWaitingForShopee": "Tips: Shopee Pending: Waiting for Shopee to provide logistics information or Shopee is still conducting fraud checks. Please manually sync the order later to get the latest status",
    "shopeeOrdersAreCurrentlyInPending": "Shopee orders are currently in Pending status. Obtaining shipping labels is not supported at the moment. Please try again later",
    "gineeHasAutomaticallyFilteredXShopee": "Ginee has automatically filtered {x} Shopee orders whose status is still Pending and is not allowed to be arranged shipment",
    "Template": "Template",
    "promotionname": "Promotion Name",
    "EndTimeLeastDay": "The end time must be greater than the start time by at least one day",
    "theEndTimeMustBeLater": "The end time must be later than the start time",
    "TokoStartMinutesTips": "Start time must be at least 5 minutes later than current time",
    "startTimeMustBeAtLeast": "Start time must be at least 5 minutes later than current time",
    "OnceTheDiscountPromotion": "Once the discount promotion saved successfully, the activity time can only be shortened",
    "theEndTimeMustBeGreater": "The end time must be greater than the start time by at least an hour.",
    "EntitledProducts": "Entitled Products",
    "RemoveProducts": "Remove products",
    "removeSelectedProducts": "Remove selected products",
    "RemoveAllProducts": "Remove all products",
    "promotionDetails": "Promotion Details",
    "UpdateAllProducts": "Update all products",
    "UpdateSelectedProducts": "Update selected products",
    "SetDiscountAt": "Set Discount at",
    "stockMonitorAndSyncIsNot": "Stock Monitor and Sync is not enabled",
    "inboundHistory": "Inbound History",
    "inboundDetails": "Inbound Details",
    "currentlyDoesNotSupportUploadingeditingLazada": "Currently does not support uploading/editing Lazada video",
    "noteYouCanPublishThisListing": "Note: You can publish this listing while the video is being processed. Video will be shown in listing once successfully processed",
    "pageNumber": "/ page",
    "rejectionReason": "rejection reason",
    "theShippingLabelIsEmptyPush": "The Shipping Label is empty, push failed",
    "theTrackingNumberIsEmptyPush": "The Tracking Number is empty, push failed",
    "doesntMeetTheOrderStatusRequirement": "Doesn't meet the order status requirement, push failed",
    "problemOrderStoredOrderPush": "Problem Order & On Hold Order, push failed",
    "reminderOnceTheStoreStockIs": "Reminder: Once the store stock is pushed successfully, it cannot be restored. Please ensure that each MSKU is associated with the warehouse and has the correct inventory set up.",
    "whenANewOrderIsPlaced": "When a new order is placed or an order is canceled for an MSKU, the stock of all channel products bound to the MSKU will be uniformly reduced or increased",
    "theStockOfAllChannelProducts": "The stock of all channel products bound to the same MSKU will be pushed immediately according to the \"{x}\"",
    "afterTurningOnStockMonitorAnd": "After turning on Stock Monitor and Sync:",
    "addingeditingProductsOrStockInVivo": "Adding/editing products or stock in Vivo warehouse is not supported. If you need to edit, please log in to the Vivo system",
    "beforeOutboundItHasBeenVerified": "Before outbound, it has been verified that Vivo warehouse has insufficient stock, the push failed",
    "areYouSureToEnableStock": "Are you sure to enable Stock Monitor and Sync?",
    "areYouSureToDisableStock": "Are you sure to disable Stock Monitor and Sync?",
    "plannedInboundCost": "Planned Inbound Cost",
    "onlyTheGineeWmsWarehouseSupports": "Only the Ginee WMS / Keeppack / FASTOCK warehouse supports editing the Push Strategy. Editing the push strategy will only take effect on new orders. Existing orders can re-trigger the Push Strategy through \"Sync\"",
    "editingThePushStrategyWillOnly": "Editing the Push Strategy will only take effect on new orders. Existing orders can re-trigger the Push Strategy through \"Sync\"",
    "userManuallyEditedTheWarehouseStock": "User manually edited the Warehouse Stock, causing the Available Stock to become negative",
    "multipleChannelProductsAreBoundTo": "Multiple store/channel products are bound to the same MSKU, which will cause the same stock to be sold at the same time. If customers buy at the same time, it will cause oversell",
    "orderSyncDelayDueToDelay": "Order sync delay: due to delay in syncing orders from marketplace, the stock actually has been used for other order",
    "failedPushStockStockNotPushed": "Failed push stock, stock not pushed to the store, causing store stock to be inconsistent with Ginee stock",
    "TheStockWasEditedIn": "2. The stock was edited in the Seller Center. Editing stock in the Seller Center won't be synced to Ginee MSKU stock, which can cause inconsistent stock between Ginee and the channel.",
    "AbnormalitiesWhenTheChannelAdjusts": "1. Abnormalities when the channel adjusts the stock, resulting in the stock not being adjusted completely.",
    "gineeSuccessfullyPushedTheStockAnd": "Ginee successfully pushed the stock, and marketplace received the stock successfully. There are 3 possibilities:",
    "pushDelayDueToTheLarge": "Push delay due to the large number of push tasks, the stock was not pushed to the marketplace on time",
    "noPushRecordFoundStockNot": "No push record found, stock not pushed to the store, causing store stock to be inconsistent with Ginee stock",
    "thisStoreHasReservedPromotionStock": "This store has Reserved Promotion Stock, but Ginee's Automatically Reserve Promotion Stock is disabled, causing store stock to be inconsistent with Ginee stock",
    "theStockMonitorAndSyncIs": "The Stock Monitor and Sync is disabled, so Ginee's updated stock is not pushed to stores",
    "missingOrderInformationsCurrentlyUnableTo": "Missing order informations. Currently unable to determine the reason",
    "pleaseCheckTheChangeReasonOn": "Please check the Change Reason on the right side of this column",
    "pleaseCheckThePushFailedReason": "Please check the push failed reason in Stock Push Records",
    "forOtherChannelsPleaseDirectlyContact": "For other channels, please directly contact Ginee Customer Service or your Key Account for further checking",
    "PleaseContactGineeCustomerService": "3. Please contact Ginee Customer Service or your Key Account for further checking",
    "PleaseSyncTheProductThrough": "1. Please sync the product through Product Channel",
    "forShopeeBukalapakAndLazadaProducts": "For Shopee, Bukalapak and Lazada products:",
    "ifYouDidntEditTheStock": "If you didn't edit the stock from Seller Center, please follow the steps below:",
    "preventTheSameIssueHappeningIn": "To prevent the same issue happening in the future, please enable",
    "toPreventTheSameIssueHappening": "To prevent the same issue happening in the future, please keep the Stock Monitor and Sync enabled",
    "pleaseEnableStockMonitorAndSync": "so the stores stock will be updated according to the Stock Push Rules",
    "notesIfTheStockIsSmaller": "Notes: If the stock is smaller than (-1), please check the Stock Change Record for the first (-1) available stock",
    "pleaseContactGineeCustomerServiceOr": "Please contact Ginee Customer Service or your Key Account for further checking",
    "gineeOmsOrderusedtotal": "Ginee OMS Order(Used/Total)",
    "mskuUsedtotal": "MSKU (Used/Total)",
    "currentlyDoesNotSupportUploadingeditingShopee": "Currently does not support uploading/editing Shopee video",
    "gineeWmsOrderusedtotal": "Ginee WMS Order(Used/Total)",
    "walletCredit": "Wallet Credit",
    "gineeWmsConsignorusedtotal": "Ginee WMS Consignor(Used/Total)",
    "onlySupportViewingCustomerDataFrom": "Only support viewing customer data from January 1, 2023",
    "packageSplit": "Package Split",
    "theMskuWasNotFoundIn": "The MSKU was not found in the third-party warehouse. Please check the push status of the SKU in the stock list page. If the push fails, please operate \"Add to Fulfillment Warehouse\" manually.",
    "changePrice": "Change Price",
    "discAfterTax": "Tax After Discount",
    "discBeforeTax": "Tax Before Discount",
    "productTaxIncluded": "VAT included",
    "additionalTax": "Add Tax to Price",
    "vatRules": "Tax Rules",
    "vat": "VAT",
    "selectAndAdd": "Select and Add",
    "customerNotFoundDoYouWant": "Customer not found, do you want to add it now?",
    "selectFromExistingCustomerList": "Select from existing Customer List",
    "enterCustomerPhoneNumberToAdd": "Enter Customer Phone Number to add a Customer or search an existing Customer",
    "addBuyer": "Add Customer",
    "unknown": "Unknown",
    "afterDeduction": "After Deduction",
    "byPercentage": "By Percentage",
    "byAmount": "By Amount",
    "deductionMethod": "Deduction Method",
    "deductionAmount": "Deduction Amount",
    "actualPayment": "Actual Payment",
    "pleaseAddProductsFirst": "Please add products first",
    "youWantToClearYourCart": "Are you sure you want to clear your cart?",
    "vatIncluded": "VAT Included",
    "payable": "Amount Due",
    "productSubtotal": "Product Subtotal",
    "viewDetails": "View Details",
    "productDiscount": "Product Discount",
    "wholeOrderReduction": "Whole Order Deduction",
    "youSureYouWantToDelete": "You sure you want to delete?",
    "switchingStores": "Switching stores...",
    "wantToChangeTheStoreTo": "Are you sure you want to change the store to {x}?",
    "clearShoppingCart": "Clear Cart",
    "disc": "Disc.",
    "totalXItems": "Total {x} items",
    "deduction": "Deduction",
    "checkOut": "Checkout",
    "submitOrder": "Submit Order",
    "productBarcode": "Product Barcode",
    "pleaseScanTheProductBarcode": "Please scan the Product Barcode",
    "cashier": "Cashier",
    "signalStrength": "Signal Strength",
    "language": "Language",
    "loginToTheStoreSuccessfully": "Login to the store successfully!",
    "pleaseSelectAStore": "Please select a store",
    "thereIsNoAccessibleStorePlease": "There is no accessible store, please contact the administrator to activate it",
    "partialRefund": "Partial Refund",
    "refunded": "Refunded",
    "partialReturn": "Partial Return",
    "completedDone": "Completed",
    "printReceipt": "Print Receipt",
    "noNeedToPrintReceipt": "No Need to Print Receipt",
    "cashReceived": "Amount Received",
    "transactionAmount": "Transaction Amount",
    "numberOfItems": "Number of Items",
    "customer": "Customer",
    "transactionTime": "Transaction Time",
    "transactionSuccessful": "Transaction successful!",
    "reorder": "Re-order",
    "theShoppingCartWillBeAutomatically": "The shopping cart will be automatically cleared, please select the product again to place an order",
    "theStoreHasChangedItsWarehouse": "The warehouse store has changed and orders cannot be placed!",
    "continueToPlaceAnOrder": "Continue to place an order",
    "removeOutOfStockProducts": "Remove out of stock products",
    "outOfStockQuantity": "Out of Stock Quantity",
    "theFollowingProductsAreOutOf": "The following products are out of stock! Continuing to place an order will result in oversale",
    "card": "Card",
    "returnFailedTheReturnedItemHas": "Return failed! The returned item has been deleted. Please choose Refund Only and manually return the product to the warehouse",
    "returnFailedTheReturnWarehouseIs": "Return failed! The return warehouse is disabled. Please choose Refund Only and manually return the product to the warehouse",
    "refundToTheOriginalCard": "Refund to the Original Card",
    "refundMethod": "Refund Method",
    "vatRefund": "Tax Refund",
    "afterOperatingReturnTheStockThe": "After operating Return, the stock the returned products will be added back immediately to the warehouse",
    "wantToReturn": "Are you sure you want to Return?",
    "return": "Return",
    "completed": "Completed",
    "cancelEntireOrder": "Cancel Entire Order",
    "onceTheEntireOrderIsCancelled": "Once the entire order is cancelled, the order will be fully refunded and the stock of the products in the order will be added back immediately to the warehouse",
    "cancelTheEntireOrder": "Are you sure you want to cancel the entire order?",
    "onlyRefundsWillOnlyAffectThe": "Refund Only will only affect the Order Actual Payment and will not affect the product stock",
    "youWantToRefund": "Are you sure you want to Refund?",
    "orderActualPayment": "Order Actual Payment",
    "amountExcludeVat": "Amount Exclude VAT",
    "companyPhoneNumber": "Company Phone Number",
    "companymerchantTin": "Company/Merchant TIN",
    "companymerchantLegalAddress": "Company/Merchant Legal Address",
    "companymerchantName": "Company/Merchant Name",
    "totalAmountPaid": "TOTAL AMOUNT PAID",
    "addVatX": "Add: VAT ({x}%)",
    "amountDue": "Amount Due",
    "lessDiscounts": "Less: Disc.",
    "lessVatx": "Less: VAT({x}%)",
    "totalAmount": "Total Amount",
    "vatExemptSales": "VAT exempt Sales",
    "zeroratedSales": "Zero-Rated Sales",
    "vatableSales": "VATable Sales",
    "itemBig": "ITEM",
    "siNo": "SI No",
    "businessStyle": "Business Style",
    "tinNo": "TIN No.",
    "soldTo": "Sold to",
    "changes": "Change",
    "totalPaid": "Total Paid",
    "totalOrder": "Total Order",
    "vatx": "VAT({x}%)",
    "totalDisc": "Total Disc.",
    "totalItem": "Subtotal",
    "cashierPerson": "Cashier",
    "orderNo": "Order No.",
    "insufficientStockUnableToPurchaseProducts": "Insufficient stock, unable to purchase products",
    "isNotCorrectPleaseCheckPhone": "The number is invalid, please check",
    "mobileRangeLengthWithPrefix": "The mobile phone number should start with {prefix} and consist of {min} to {max} digits",
    "mobileLengthWithPrefix": "The mobile phone number should start with {prefix} and consist of {length} digits",
    "mobileRangeLength": "Mobile phone number must be {min} to {max} digits",
    "posSettings": "POS Settings",
    "manageGineePosStore": "Manage Ginee POS Store",
    "changingWarehousesWillReplaceTheProducts": "Changing warehouses will replace the products in the POS Store with the products and stock in the new warehouse, please operate with caution",
    "taxCollectionPreference": "Tax Collection Preference",
    "orderTotalAfterRefund": "Order Total After Refund",
    "productQuantity": "Product Quantity",
    "selectReturnProduct": "Select Return Product",
    "receipt": "Receipt",
    "newDiscountedPrice": "New Discount Price",
    "taxDiscountPreference": "Tax-Discount Preference",
    "pleaseEnterCorrectUsername": "Please enter correct Customer Name",
    "orderPhoneNumber": "Order Phone Number",
    "customerNam": "Customer Name",
    "theAmountDueCannotBeLess": "The Amount Received cannot be less than the Amount Due",
    "excessiveReturnIsNotSupported": "Excessive return is not supported",
    "theStoreIsNotBoundTo": "The store is not bound to the warehouse",
    "orderDoesNotExist": "Order does not exist",
    "theStoreDoesNotExist": "The store does not exist",
    "productBarcodeXDoesNotExist": "Product barcode {x} does not exist in the warehouse",
    "notExistInTheWarehouse": "MSKU {x} does not exist in the warehouse",
    "addMskuToTheWarehouseFirst": "MSKU does not exist in the warehouse, please add MSKU to the warehouse first",
    "pay": "Pay",
    "aOperationToCancelTheEntire": "{a} Cancel the entire order, Remarks {f}",
    "aOperationReturnAndRefundMsku": "{a} operate Return and Refund, MSKU {b}, Quantity {c}, Refund Amount {d}, Tax Refund {e}, Remarks {f}",
    "aOperationRefundRefundAmountB": "{a} operate Refund, Refund Amount {b}, Tax Refund {c}, Remarks {d}",
    "discountPriceCannotBeHigherThan": "Discount price cannot be higher than the original price",
    "supportsInputNumNoMore": "Only supports inputting numbers/letters/spaces/\"-\"/\"_\"",
    "ooOrders": "O2O Orders",
    "ifYouDoNotHaveOms": "If you do not have OMS Customer Management permissions, please contact the administrator to activate it",
    "totalVatInc": "TOTAL",
    "netDue": "NET DUE",
    "lessOtherDiscounts": "Less: Other Discounts",
    "customerPhoneNo": "Customer Phone No",
    "doNotAllowLazadaEase": "Channel Limitations: Do not allow updating stock for products Lazada Ease Mode store",
    "doNotAllowTokoOO": "Channel Limitations: Do not allow updating stock for products in Tokopedia O2O warehouse",
    "youHaveAddedAChannelSku": "You have added a Channel SKU with a different name. Binding different products to MSKU may lead to oversell. Please confirm that the Channel SKU and MSKU are the same product",
    "bindingSkuWithDifferentNamesMay": "Binding SKU with different names may lead to oversell, please operate with caution",
    "channelReturnedErrorReachTheChannel": "{Channel} returned error: Reach the channel API QPS limitation, stock push failed.",
    "expiredStoresWillNotPushInventory": "Expired stores will not push stock, please reauthorize as soon as possible!",
    "massReleaseWillReleaseTheReserved": "Mass Release will release the reserved Promotion Stock according to the product level (including all variations under the selected product), please operate with caution!",
    "recommendedOperation": "Recommended Operation",
    "oversoldReasons": "Oversold Reasons",
    "pleaseEnable": "Please enable",
    "ThereAreCancellationreturnrefundOrdersOn": "3. There are cancellation/return/refund orders on the channel, which causes the channel stock to automatically increase. Buyers can place an order, but the stock in Ginee is still 0, resulting in oversold.",
    "PleaseCheckWhetherThereAre": "2. Please check whether there are any cancelled/returned/refunded orders in the Seller Center after the stock is updated to 0",
    "skuxAlreadyExistsInOmsAnd": "SKU{x} already exists in OMS and is a combination product. Combination products are not allowed to be outbound/inbound!",
    "oneclickOversoldCheck": "Oversold Check",
    "massRelease": "Mass Release",
    "thisOperationWillReleaseTheReserved": "This operation will release the Reserved Promotion Stock of all products in the store, please operate with caution!",
    "imageSizeMustBeGreaterThan": "Image size must be greater than height {x}, width {y}",
    "clickToUpdateBasicInformationSuch": "Click to update basic information such as packages, permissions, etc",
    "clickRefreshToObtainTheLatest": "Click Refresh to obtain the latest packages, permissions, consignor binding request and other information",
    "bySelectedProducts": "By Selected Products",
    "byStoreName": "By Store",
    "settingProductDescription": "Master Product and Clone Settings",
    "paymentReconciliation": "Payment Reconciliation",
    "partialRelease": "Partially Released",
    "released": "Released",
    "toRelease": "To Release",
    "marketplaceReleasedAmountForTheOrder": "Marketplace Released Amount for the Order",
    "orderPackageNumber": "Order Package Number",
    "marketplaceReleasedAmountEstimatedRelease": "Amount Difference = Marketplace Released Amount - Estimated Release Amount",
    "afterTheOrderIsCompletedThe": "After the order is completed, the marketplace releases the order income to the seller",
    "settlementDetails": "Settlement Details",
    "amountDifference": "Amount Difference",
    "marketplaceReleasedAmount": "Marketplace Released Amount",
    "reconciliationStatusProvidedByTheChannel": "Reconciliation Status Provided by the Marketplace",
    "feesCharges": "Fees & Charges",
    "voucherRebates": "Voucher & Rebates",
    "totalExpenses": "Total Expenses",
    "estimatedReleaseAmountTotalRevenue": "Estimated Release Amount = Total Revenue - Total Expenses",
    "estimatedReleaseAmount": "Estimated Release Amount",
    "timeoutPaymentIsNotSettledWithin": "Timeout: Payment is not settled within 24 hours after the order is completed",
    "settlementDataIsSyncedOnceA": "Settlement data is synced once a day and some released orders may still remain unreleased",
    "xDigitsOfEnglishChineseNumbers": "{x} digits of English, Chinese, numbers, spaces and - _ & %",
    "pushSuccessnoShippingLabel": "Push Success(No Shipping Label)",
    "pushSuccesshasShippingLabel": "Push Success(Has Shipping Label)",
    "clickTheOkButtonToRecreate": "Click the [OK] button to recreate a Sales Outbound based on the latest order data",
    "youSureYouWantToRecreate": "Are you sure you want to recreate the Sales Outbound?",
    "atchEditingOfBrandsInMultiple": "atch editing of brands in multiple stores only supports selecting \"No Brand\". If you need to select other brands, please select products from the same store.",
    "editingOfBrandsInMultiple": "batch editing of brands in multiple stores only supports selecting \"No Brand\". If you need to select other brands, please select products from the same store.",
    "masstRemark": "Mass Edit Remarks",
    "pushFailedKeeppackOutboundOrderStatus": "Push failed, Keeppack does not allow this operation",
    "printingFailedTheOrderIsMissing": "Printing failed, the order is missing Shipping Label PDF",
    "dearCustomer": "Dear customer,",
    "yourFollowingResourcesHaveExceededThe": "Your following resources have exceeded the package limit, please adjust or upgrade the package before continuing to use Ginee OMS",
    "selectXStoresToContinueBinding": "Select {x} stores to continue binding, and the rest will be Disabled (after deactivation, it will not affect store sales, but Ginee OMS will not display the store’s products/orders and other data)",
    "selectedXStore": "Selected {x} store",
    "staffAccount": "Staff Account",
    "selectXStaffAccountsToContinue": "Select {x} staff accounts that will continue to be used, the rest will be removed (only the Ginee OMS system permissions of the staff accounts will be removed, other Ginee systems will not be affected)",
    "onlySupportsUploadingPdfFilesMax": "Only supports uploading PDF files, max. 1MB",
    "shippingLabelHasBeenUploaded": "Shipping Label has been uploaded",
    "uploadShippingLabel": "Upload Shipping Label",
    "shippingLabelPdf": "Shipping Label PDF",
    "cancellationTime": "Cancellation Time",
    "shippingTime": "Shipping Time",
    "inventorySynchronizationSwitchTurnedOff": "Stock Monitor and Sync button: turned off",
    "theFollowingFunctionsWillBeAutomatically": "The following functions will be automatically turned off due to package expiration and need to be manually activated after renewing the package.",
    "afterThePackageExpiresStockSynchronization": "After the package expires, stock synchronization switch will automatically turn off.",
    "lazadaPhOrdersOnlySupportPrinting": "The selected orders include orders from the PH site, and only support printing invoices using Ginee's template",
    "companymerchantVatTinNo": "Company/Merchant VAT TIN No.",
    "afterTheSwitchIsTurnedOn": "After enabling, before pushing the Outbound List to the warehouse, system will first pull the latest stock of the synced third-party warehouses . If the Warehouse Stock is insufficient, the push will be canceled, the OMS Warehouse Stock will be updated and the order will be moved to the Problem Order-Out of Stock;\nAfter disabling, the Outbound List will be pushed directly to the warehouse when it meets the push conditions and system will not verify whether the stock in the third-party warehouse is sufficient or not",
    "checkStockBeforePushing": "Check Stock Before Pushing",
    "theStrategyImplementedByTheWarehouse": "The strategy applied for the warehouse, please see the Strategy Description for explanation",
    "whenTheOrderMeetsThePush": "The order will be pushed automatically when it meets the push conditions, no need to push manually",
    "shopeeApiRequiresThatAllVariation": "Shopee API requires that all variation names are not allowed to be changed when editing a product (at least one original variation name must be retained)",
    "sellerOwnFleet": "Seller Own Fleet",
    "addedToday": "Added Today",
    "globalProduct": "Global Product",
    "publishedSite": "Published Site",
    "sellerStock": "Seller Stock",
    "globalProductPrice": "Global Product Price",
    "verifyMskuBeforePushingYIs": "Verify MSKU before pushing: {y} is out of stock in WMS/Third-party warehouse and the push is stopped",
    "theStockVerificationFailedBeforePushing": "The stock verification failed before pushing, and the pushed outbound order was intercepted. Reason: MSKU:{x} is out of stock in WMS/third-party warehouse.",
    "enablePush": "Enable Push",
    "disablePush": "Disable Push",
    "changeToDisable": "Change to Disable",
    "changeToEnable": "Change to Enable",
    "productNameAccountNumber": "Product Name & Account Number",
    "dueToShopeeApiLimitationsProducts": "Due to Shopee API limitations, products that have more than 50 variations cannot be published or edited from Ginee",
    "accurateWh": "Accurate Warehouse",
    "gineeWarehouse": "Ginee Warehouse",
    "gineeWarehouseAddress": "Ginee Warehouse Address",
    "gineeWarehouseName": "Ginee Warehouse Name",
    "manageTheMappingRelationshipBetweenChannel": "Manage the mapping relationship between Channel Warehouse and Ginee Warehouse",
    "youCanSelectTheChannelStore": "You can select the channel store to push stock to. CNSC/3PF stores only support setting Stock Push Rules according to the Merchant Account",
    "bindingStatus": "Binding Status",
    "channelWarehouseStatus": "Channel Warehouse Status",
    "thePartiallyReleasedListRefersTo": "The Partially Released list refers to Lazada orders that contain multiple products. Some products have been released and some have not yet been released. The data will be placed in this status.",
    "theReleasedListDisplaysTheReleased": "The Released list displays the released data obtained from the API",
    "customTax": "Custom Tax",
    "promotionFee": "Campaign Fee",
    "transactionFee": "Transaction Fee",
    "highQuality": "High Quality",
    "commissionF": "Commission Fee",
    "amsCommissionFee": "AMS Commission Fee",
    "reverseShippingFeeForReturnedOrders": "Reverse Shipping Fee",
    "plShippingFeeDiscount": "Shipping Fee Discount from 3PL",
    "shopeeShippingRebate": "Shipping Rebate From Shopee",
    "sellerAbsorbedCoinCashback": "Coin Cashback Sponsored by Seller",
    "productDiscountsAndCashbackByShopee": "Rebate Provided by Shopee",
    "productRefundAmount": "Refund Amount",
    "productDiscountedPrice": "Your Seller Product Promotion",
    "productOriginalPrice": "Original Product Price",
    "totalReleaseAmount": "Total Released Amount",
    "settlementNo": "Settlement No.",
    "settlementTime": "Settlement Time",
    "whetherTimeout": "Whether Timeout",
    "releasing": "Releasing",
    "orderItem": "Order Item",
    "settlementDetailsAllPages": "Settlement Details - All Pages",
    "settlementDetailsBySelected": "Settlement Details - by Selected",
    "ordersAllPages": "Orders - All Pages",
    "ordersBySelected": "Orders - by Selected",
    "compatibleModels": "Compatible Models",
    "noticeTitle": "Title",
    "promotionStockCannotBeGreaterThan": "Promotion Stock cannot be greater than Available Stock",
    "pleaseEnterTheTitle": "Please enter the title",
    "theOutboundListHasBeenCompleted": "The Outbound List has been completed at the fulfillment warehouse and cannot be re-pushed",
    "commissionChargedBasedOnItemUnit": "Commission charged based on item unit price (% charged on Unit Price - Seller Discount)",
    "theOrderHasBeenCompletedBut": "The order has been completed, but the channel has not yet settled the payment.",
    "paymentFeeForProcessingCharges": "Payment fee for processing charges (% charged on Unit Price + Buyer Shipping Fee - Seller Vouchers)",
    "listedPriceOfTheItemSold": "Listed price of the item sold before any discounts or charges to buyers",
    "productVat": "Product VAT",
    "buyerRealName": "Buyer Real Name",
    "salesInvoiceWillUseTheSelected": "Sales Invoice will use the selected fixed customer information when disabled. When enabled, Sales invoice will use the real customer information provided by the channel order. Please note that customer information in some channels will be masked. It is recommended to disable this feature for stores that masked customer information",
    "realCustomerInformationInTheOrder": "Real Customer Information in the Order",
    "excludingVat": "Excluding VAT",
    "theSkuIsNotOversoldReason": "The SKU is not oversold. Reason: Since TikTok Shop will delay releasing stock after an order is cancelled, Ginee will also delay releasing the Locked Stock for 12 hours after cancelled order synced to Ginee (the status will be displayed as \"Releasing\"). Locked stock quantity being released ≥ the negative stock quantity is not oversold.",
    "ifYouNeedToReleaseThe": "If you need to release the Locked Stock with  \"Releasing\" status in advance, you can invalidate the corresponding Outbound List in the Outbound Management to force release the Locked Stock (there is a risk of oversold, please operate with caution)",
    "publishedGlobalProduct": "Published Global Products",
    "editingTheGlobalProductPriceWill": "Editing the Global Product price will update the price of the store product according to the calculation formula",
    "editingTheGlobalProductStockWill": "Editing the Global Product stock will update store product stock",
    "pleaseNotePleaseUseMAuthorization": "Please note: Please use {m} authorization for general local stores, and {u} authorization for CNSC/3PF stores",
    "theSkuIsOutOfStock": "The SKU is out of stock in WMS and cannot be pushed",
    "theSkuDoesNotExistIn": "The SKU does not exist in WMS and cannot be pushed",
    "theOrdersOutboundListHasBeen": "The order's Outbound List has been completed in WMS and cannot be pushed",
    "wmsIsProcessingTheOrder": "WMS is processing the order",
    "authorizeMainAccount": "Authorize Main Account",
    "authorizeShopAccount": "Authorize Shop Account",
    "customOrNo": "Custom OR No.",
    "onlySupportsFilesPdfPngJpeg": "Only supports files PDF, png, jpeg, JPG less than 5M",
    "gineeWillAutomaticallyCreateAnInbound": "Ginee will automatically create an Inbound List according to the input Inbound Quantity, directly complete the Inbound List and increase the Warehouse Stock, please confirm!",
    "youWantToAddToThe": "Are you sure you want to add to the warehouse and inbound directly?",
    "feeRefundreturn": "Refund/Return Fee",
    "fulfillmentFee": "Fulfillment Fee",
    "shippingFeeChargedToSeller": "Shipping Fee Charged to Seller",
    "otherFee": "Other Fee",
    "serviceFee1": "Service Fee",
    "rebateFromMarketplace": "Rebate from Marketplace",
    "sellerPromotion": "Seller Promotion",
    "productDiscountPrice": "Product Discount Price",
    "pleaseRenewOrPurchaseOrderQuantity": "Please renew or purchase order quantity as soon as possible, otherwise you will not be able to use order-related functions!",
    "yourGineeWmsOrderQuotaHas": "Your Ginee WMS order quota has been exceeded!",
    "yourGineeOmsOrderQuotaHas": "Your Ginee OMS order quota has been exceeded!",
    "orderQuotaExceeded": "Order Quota Exceeded",
    "insufficientOrderQuota": "Order Quota < 10%",
    "theOrderQuotaOfYourCurrent": "Please renew or purchase the order quota add-on as soon as possible, otherwise you will not be able to use the outbound related functions!",
    "bySelectingAllLazadaWillConsider": "By selecting ALL, Lazada will consider it as including all options below, with no need to tick each sub-item individually.",
    "abnormalExpiration": "Abnormal Expiration",
    "pushOutboundOrders": "Push Outbound List",
    "pushInboundOrders": "Push Inbound List",
    "directInbound": "Direct Inbound",
    "updatedToBePushed": "(Updated) To Be Pushed",
    "addressNameAndPhoneNumberWill": "Address, Name, and Phone Number will be used for the sender information in Ginee's customized Shipping Label",
    "syncChannelAddress": "Sync Channel Address",
    "withholdingTax": "Withholding Tax",
    "theCnscpfsChannelWarehouseIsNot": "The CNSC/3PF's Channel Warehouse is not bound to Ginee Warehouse, so the Outbound List cannot be generated",
    "afterSettingTheShippingMethodWhen": "After setting the shipping method, when arranges shipping in Ginee, the shipping method and pickup address will be automatically selected to improve shipping efficiency.",
    "theOutboundOrderIsInvalidIn": "The outbound order is invalid in WMS and cannot be pushed again.",
    "progress": "Progress",
    "setShippingInfo": "Set Shipping Info",
    "outboundOrderHasBeenSuccessfullyPushed": "Outbound order has been successfully pushed to the warehouse to process, and you cannot be modified the outbound order information. Please manually do the outbound according to the order change information",
    "mStoreAbnormallyExpired": "{m} store abnormally expired",
    "oneClickToChangeTheStores": "One click to change the store's Pickup Address",
    "changeAddressByStore": "Change Store Address",
    "youCanQuicklyChangePickupAddress": "You can quickly change Pickup Address for all logistics in one Shopee store to another address",
    "invalidTrackingNumberPleaseGoTo": "Invalid Tracking Number, please go to the Seller Center to resubmit a valid Tracking Number as soon as possible!",
    "invalidTrackingNumberawb": "Invalid Tracking Number/AWB",
    "channelAbnormalOrders": "Channel Abnormal Orders",
    "tryNoww": "Try Now",
    "congratulationsYouHaveReceivedADay": "Congratulations! You have received a 7-day free trial of the full features",
    "mskuTotal": "Total MSKU Quantity",
    "updateUsageEveryHours": "Usage Updates Every 4 Hours",
    "dailyUpdateUsage": "Daily Usage Updates",
    "replacementSuccessful": "Replacement successful!",
    "theFollowingOrdersNeedToAdd": "The following orders need to add shipping information before arranging the shipment.",
    "toEnsureNormalUse": "To ensure normal use, please renew in time",
    "invoiceNumberMustBeDigits": "Invoice Number must be 8 digits",
    "companymerchantInformation": "Company/Merchat Information",
    "orDate": "OR Date",
    "orNo": "OR No",
    "pleaseEnterTheWarehouseCodeNo": "Please enter the warehouse code, no more than 50 characters",
    "failedToPrintInvoice": "Failed to print Invoice",
    "thatIsOfficialReceiptNumberYou": "That is, Official Receipt Number. You can set a prefix of up to 3 letters (prefix can be left blank) + 8 digits (must be 8 digits) to form OR No. Each time an order is printed, it will start to increase according to the set number. Please note: After each modification, it will be printed according to the latest rules, please modify it carefully;",
    "theCompanyInformationSetInGinee": "The Company Information set in Ginee Accounts will be displayed",
    "setCompanyInformation": "Set Company Information",
    "atTheRequestOfThePhilippine": "At the request of the Philippine government, all invoices of Philippine stores must use the Philippine invoice template. The module can choose whether to include Consumption Tax (VAT)",
    "orderDate": "Order Date",
    "thisReceiptShallBeValidFor": "THIS RECEIPT SHALL BE VALID FOR FIVE (5) YEARS FROM THE DATE OF ACKNOWLEDGMENT CERTIFICATE",
    "acknowledgementCertificateNo": "Acknowledgement Certificate No",
    "dateIssued": "Date Issued",
    "seriesRange": "Series Range",
    "handleProb": "Handle Problem",
    "checksFailed": "Checks Failed!",
    "successfullyDeletedTheWarehouse": "Successfully deleted the Warehouse!",
    "startDeletion": "Start Deletion",
    "checksPassed": "Checks Passed!",
    "inventoryClearanceAllProductStocksAre": "Inventory Clearance: All product stocks are now 0",
    "manualInboundListAllDocumentsHave": "Manual Inbound List: All documents have been completed",
    "purchaseInboundListAllDocumentsHave": "Purchase Inbound List: All documents have been completed",
    "manualOutboundListAllDocumentsHave": "Manual Outbound List: All documents have been completed",
    "salesOutboundListAllDocumentsHave": "Sales Outbound List: All documents have been completed",
    "deletingAWarehouseRequiresCheckingThe": "Deleting a Warehouse requires checking the data in the Warehouse, completing all Inbound/Outbound List, and clearing all SKU stocks before deletion",
    "checkingWarehouseDeletion": "Checking Warehouse Deletion",
    "beforeDeletingAWarehouseYouNeed": "Before deleting a Warehouse, you need to complete all Inbound/Outbound List and clear all SKU stock",
    "oneAreYouSureYouWantTo": "Are you sure you want to delete the Warehouse?",
    "theNumberOfLocalWarehouseHas": "The number of Local Warehouse has reached the limit (max. 50). Please delete the excess warehouses and try again!",
    "oneFailedToSaveTheNumberOf": "Failed to save! The number of Channel SKU bound to the Combined Product has reached the limit (max. 500)",
    "failedToSaveTheNumberOf": "Failed to save! The number of products added to the Combined Product has reached the limit (max. 10)",
    "failedToSaveTheBoundCombined": "Failed to save! The bound Combined Product of single product {x} has reached the limit (can be bound to max. 100 Combined Products)",
    "failedToSaveTheVariationsOf": "Failed to save! The variations of the Master Product have reached the limit (max. 200)",
    "bindingFailedTheChannelSkuBound": "Binding failed! The number of Channel SKU bound to MSKU has reached the limit (max. 500)",
    "deletingWarehouse": "Deleting warehouse...",
    "deletingWarehouseProducts": "Deleting Warehouse Product...",
    "deletingMIO": "Deleting Manual Inbound List...",
    "deletingPIO": "Deleting Purchase Inbound List...",
    "deletingMOO": "Deleting Manual Outbound Lst...",
    "deletingSOO": "Deleting Sales Outbound List...",
    "none": "None",
    "InboundStockIsOnlyValid": "1. Inbound stock is only valid for adding warehouses and inbound, and inbound stock data will be input when inbound 2. Combine products can only be added to the warehouse, and not support to inbound. 3. Products that do not need to be bind can be deleted directly. 4. After submitting, if the product already exists in the warehouse, system will automatically filter it, so the product will not be inbound again.",
    "differentWeightdimensionsForVariations": "Different weight/dimensions for variations",
    "becauseAllVariationsHaveExactlyThe": "Because all variations have exactly the same weight and dimension, the system will automatically help you switch to product level weight and dimension",
    "mskuXWarehouseNameOrWarehouse": "MSKU {0}, Warehouse Name or Warehouse Code does not exist",
    "mskuDoNotExist": "MSKU {0} does not exist in warehouse {1}, please add the SKU to the warehouse first",
    "masterProductXDoesNotExist": "Master Product {x} does not exist",
    "theOutboundListHasBeenPushed": "The Outbound List has been pushed, and the order is no longer allowed to be edited",
    "orderXStatusDoesNotAllow": "Order {0} status does not allow editing",
    "orderXDoesNotExist": "Order {0} does not exist",
    "thePaymentAmountShouldBeLess": "The Payment Amount should be less than the Order Total, please check",
    "theTotalAmountOfProductsExceeds": "The Total Amount of Products exceeds the limit",
    "theOrderNoIsDuplicatedPlease": "The Order No. is duplicated, please edit the Order No.",
    "theStoreCannotBeEmpty": "The Store cannot be empty",
    "channelRequirementsXMustBeBetween": "Channel requirements {x} must be between {y}~{z}",
    "itemCount": "Item count",
    "variationOptionLength": "Variation Option length",
    "variationTypeLength": "Variation Type length",
    "itemDescriptionLength": "Item description length",
    "itemImageCount": "Item image count",
    "itemNameLength": "Item name length",
    "wholesalePriceThresholdPercentage": "Wholesale price threshold percentage",
    "sinceSomeProductsHaveTheSame": "Since some products have the same Weight and Dimensions for all variations, the system will automatically help you switch to product-level Weight and Dimensions",
    "toEnableDifferentWeightdimensionsForEach": "To enable different Weight/Dimensions for each variation, please go to Shopee product's single-edit page and enable the \"Different Weight/Dimensions for variations\" button",
    "mskuHasBeenInbounded": "MSKU has been inbounded",
    "exportFailed": "Export Failed",
    "staffToRemovePermissions": "Staff to Remove Permissions:",
    "staffsToRetainPermissions": "Staffs to Retain Permissions:",
    "storesToDeactivate": "Stores to Deactivate:",
    "storesToRetain": "Stores to Retain:",
    "staffAccountsToContinueUsing": "Staff Accounts to Continue Using:",
    "storesToContinueBinding": "Stores to Continue Binding:",
    "pleaseConfirmTheStoreOrStaff": "Please confirm the Store or Staff  you wish to retain",
    "theDefaultPickupTimeLatest": "The Pickup Time time is the latest",
    "theDefaultPickupTimeEarliest": "The default Pickup Time is the earliest",
    "doNotAutomaticallySelectThePickup": "Do not automatically select the Pickup Time",
    "setTo": "Set to:",
    "onlyApplicableToOrdersWithPickup": "Only applicable to orders with pickup service on Shopee and TikTok",
    "automaticallySelectThePickupTime": "Automatically Select The Pickup Time",
    "whenYouSelectLatest": "When you select this option, the latest available Pickup Time will be automatically filled in on the shipping page.",
    "whenYouSelectEarliest": "When you select this option, the earliest available Pickup Time will be automatically filled in on the shipping page.",
    "thisOrderIsFromAMultiwarehouse": "This order is from a multi-warehouse store. Changing the address may incur additional shipping costs. Are you sure you want to update it?",
    "noManualSyncTask": "No Manual Sync Task",
    "taskIds": "Task ID",
    "manualSyncProduct": "Manual Sync Product",
    "manualSyncOrder": "Manual Sync Order",
    "syncHistoricalOrders": "Sync Historical Orders",
    "sinceTiktokShopWillDelayReleasing": "Since TikTok Shop will delay releasing stock after an order is cancelled, Ginee will also delay releasing the Locked Stock for 12 hours after cancelled order synced to Ginee (the status will be displayed as \"Releasing\").",
    "syncProgress": "Sync Progress",
    "notAbnormal": "Not Abnormal",
    "reservationFailedTheBoundGineeWarehouse": "Reservation failed, the bound Ginee warehouse was not found (Shopee CNSC/3PF stores reserve Promotion Stock according to the binding relationship between the store warehouse and the Ginee warehouse)",
    "discountType": "Discount Type",
    "percentageOff": "Percentage Off",
    "fixedPrice": "Fixed Price",
    "variationlevel": "Variation-level",
    "productlevel": "Product-level",
    "youWantToSwitchTheDatabase": "Are you sure you want to switch the database to {x}?",
    "allSettingsAndMappingsWillBe": "All settings and mappings will be deleted and the MSKU will need to be re-matched",
    "changeDatabase": "Switch Database",
    "pleaseSelectTheDatabaseToChange": "Please select the database to switch to",
    "ifYouSwitchToANew": "If you switch to a new database, all settings and mappings will need to be reconfigured. But don't worry, the data that has been pushed to Accurate will not be deleted.",
    "mappingS": "Mapping",
    "channelStoreName": "Channel Store Name",
    "customersRealName": "Customer's Real Name",
    "productTaxSettings": "Product Tax Settings",
    "whetherTheProductWillIncludeVat": "Whether the product will include VAT on the Sales invoice",
    "noVat": "No VAT",
    "addVat": "Add VAT",
    "gineeWarehouseCanOnlyBeMapped": "Ginee warehouse can only be mapped with 1 Accurate warehouse, please complete at least 1 warehouse mapping",
    "dataLinkedWithBranchesBanksAnd": "Data linked with branches, banks, and COA when order transaction sent to Accurate",
    "shippingFeeChargedToSellersCalculated": "Shipping Fee Charged to Seller calculated based on Marketpalce Settlement Details",
    "otherDeductedFeesProvidedByThe": "Other deductible fees  as provided in the Marketplace Settlement Details",
    "rebateAndDiscountAmountAsProvided": "Rebate and Discount Amount as provided in the Marketplace Settlement Details",
    "sellerPromotionFeeAsProvided": "Seller Promotion Fee as provided in the Marketplace Settlement Details",
    "serviceFeeCommissionFeeAndOther": "Service Fee, Commission Fee and Other Fees deducted by marketplace as provided in the Marketplace Settlement Details",
    "importantNotesErrorNotificationTheCumulative": "Important Notes: Error notification \"The cumulative order period cannot exceed 18 months\" when purchasing Lazada-Authorization-only means that you've bought 18 month Authorization-only. Please {x}, then click Use service to finish the integration",
    "historicalOrdersSettlementDataWillNot": "Historical Orders' Settlement Data will not be synced",
    "variousServiceCharges": "Various Service Charges",
    "thePromotionPeriodMustBeLonger": "The promotion period must be longer than 10 minutes and shorter than 365 days",
    "purchaseLimitCannotBeGreaterThan": "Purchase Limit cannot be greater than Available Stock",
    "fulfilledByChannel": "Fulfilled by Channel",
    "jitPo": "JIT PO",
    "advanceFulfillment": "Advance Fulfillment",
    "notSpecial": "Not Special",
    "createdByShopee": "Created by Shopee",
    "matchPending": "Match Pending",
    "discountPriceIsLowerThanM": "Discount Price is lower than {m}",
    "theDiscountRateMustNotbeHigher": "The discount rate must not be higher than {m}%",
    "limitationMethod": "Limitation Method",
    "variationDimension": "Variation Dimension",
    "noLimit": "No limit",
    "invalidDiscount": "invalid discount",
    "invalidPrice": "invalid price",
    "TheStartTimeMustBe": "1. The start time must be later than the current time",
    "ThePromotionPeriodMustBe": "2. The promotion period must be longer than 10 minutes and shorter than 365 days",
    "productId": "Product ID",
    "variationId": "Variation ID",
    "thePromotionsPeriodMustBeLonger": "The promotion period must be longer than 1 hours",
    "thePromotionuPeriodMustBeLonger": "The promotion period must be longer than 10 minutes and shorter than 365 days",
    "unavailableStockIntransit": "Unavailable Stock (In-transit)",
    "stockTransferredToSortingCenterBy": "Stock transferred to sorting center by Shopee in advance",
    "advanceStock": "Advance Stock",
    "outboundQuantityMustBe": "Outbound quantity must be>0",
    "needHelpWereHereForYou": "Need help? We're here for you. Contact us M-F, 9AM-6PM",
    "orderXIsMatchedWithShopee": "Order [{0}] is matched with Shopee Advance Fulfillment Order [{1}], the orders are merged",
    "bookingId": "Booking ID",
    "availStock": "Available Stock",
    "notMatchedToOrder": "Not Matched to Order",
    "importToCreateCombinedProduct": "Import to Create Combined Product",
    "youCanImportUpTo": "You can import up to 10,000 data at a time (format: xlsx, xls) to mass create Combined Product",
    "matched": "Matched",
    "marketplaceSpecialOrder": "Marketplace Special Order"
};
});

var id = createCommonjsModule(function (module, exports) {
exports.__esModule = true;
exports.id = void 0;
exports.id = {
    "globalNoData": "Tidak Ada Data",
    "globalPleaseSelect": "Silahkan Pilih",
    "globalPleaseEnter": "Masukkan",
    "globalsYearly": "Tahunan",
    "globalsMonthly": "Bulanan",
    "globalsWeekly": "Mingguan",
    "globalsYesterday": "Kemarin",
    "globalsExportDisabled": "Kedaluwarsa",
    "globalsExportFailed": "Download Gagal",
    "globalsExportFinished": "Aktif",
    "globalsExportINProcess": "Diproses",
    "globalsExportStatus": "Status",
    "globalsPackageExpired": "Paket Anda telah kedaluwarsa",
    "globalsLater": "Lain Kali",
    "globalsContactNow": "Hubungi Sekarang",
    "globalsContactUsPackage": "Silakan hubungi kami untuk Informasi Paket dan penawaran",
    "isRequired": "Harus diisi",
    "globalsGoContinue": "Lanjutkan",
    "globalsBrowserBlockedInfo": "Terdeteksi bahwa jendela pop-up Anda di blok oleh browser, silakan klik \"Lanjutkan\" untuk membuka jendela pop-up Anda (Anda juga dapat mencari \"Cara Mengatasi Masalah Halaman Diblok di Browser Anda\" di Pusat Bantuan untuk mengatasinya)",
    "globalsNotification": "Notifikasi",
    "globalsPleaseSelect": "Silahkan Pilih",
    "globalsIsRequired": "Harus diisi",
    "globalsOperationGuide": "Tips pengoperasian",
    "globalsSyncError": "Sinkronisasi Gagal",
    "globalsSynchronizing": "Sedang Sinkronisasi",
    "globalsSync": "Sinkronkan",
    "globalsSyncSuccess": "Sinkronisasi Berhasil",
    "globalsNoProductYet": "Belum ada produk",
    "globalsSeeMore": "Lihat { more } produk lagi",
    "globalsSeeMoreRetract": "lihat lebih singkat",
    "globalsSeeMoreExpand": "lihat lebih banyak",
    "globalsNoName": "Tidak memiliki nama",
    "globalsTotalPage": "Total { total }",
    "globalsReset": "Reset",
    "globalsSearch": "Cari",
    "globalNoEmptyText": "Tidak ada data",
    "globalNoPermission": "Akun Anda tidak memiliki izin ini, silakan hubungi administrator Toko untuk bertanya atau upgrade",
    "globalAction": "Atur",
    "globalTaskResult": "Hasil",
    "globalTaskFailedNumber": "Gagal",
    "globalTaskTotalNumber": "Total",
    "globalTaskSuccessNumber": "Sukses",
    "globalTaskFailed": "Beberapa pesanan gagal diproses, coba lagi nanti",
    "globalTaskSuccess": "Pengaturan Berhasil",
    "globalTaskWait": "Sedang diproses, mohon tunggu",
    "globalStatus_unpublished": "Tidak diterbitkan",
    "globalStatus_update_failed": "Gagal Ditampilkan",
    "globalStatus_delete": "Dihapus",
    "globalStatus_draft": "Draft",
    "globalStatus_disabled": "Nonaktif",
    "globalStatus_banned": "Dilarang",
    "globalStatus_sold_out": "Habis",
    "globalStatus_live": "Aktif",
    "globalStatus_process": "Sedang diproses",
    "globalStatus_all": "Semua",
    "globalName": "Global Name ID",
    "goToLinkStoreTip": "Tidak ada toko yang terintegrasi, silakan integrasikan toko terlebih dahulu lalu tambahkan produk",
    "goToLinkStore": "Integrasikan Toko Sekarang",
    "remove": "Hapus",
    "distributionSettings": "Pengaturan Distribusi",
    "menuThirdPartyWarehouseProductList": "Daftar Produk Gudang Pihak Ketiga",
    "serviceLink": "Grup Ginee Support",
    "thirdPartyWarehouseStocktakingList": "Penarikan Stok Gudang Pihak Ketiga",
    "explore": "Analisis Pasar",
    "authPlatform": "Otorisasi",
    "yilianFulfillment": "YiLian Fulfillment",
    "syncdiconnected": "Terputus",
    "syncsuccess": "Sinkronisasi Berhasil",
    "syncing": "Sinkronisasi",
    "commissionSetting": "Pengaturan Komisi",
    "LAInvoiceSettingsTab": "Pengaturan Invoice",
    "ExportTemplateTab": "Export Template",
    "documentTemplatSeettings": "Template Dokumen",
    "selectfulfillment": "Silahkan pilih provider layanan fulfillment",
    "haventBound": "Anda belum mengintegrasikan provider layanan fulfillment, ingin mengintegrasikan sekarang?",
    "newRule": "Aturan Baru",
    "menuProductTiki": "Produk / Tiki",
    "logout": "Keluar",
    "productTools": "Tools Produk",
    "menuCustomer": "Pelanggan",
    "menuPromotion": "Promosi",
    "menuWarehouse": "Gudang",
    "GineeChat": "Chat",
    "menuGineeCapital": "Modal",
    "addIntegration": "Tambah Integrasi",
    "thirdpartyWarehouseList": "Daftar Gudang Pihak Ketiga",
    "menuIntegration": "Integrasi",
    "logisticsReport": "Laporan Logistik",
    "customerReport": "Laporan Pelanggan",
    "inventoryReport": "Laporan Stok",
    "salesReport": "Laporan Penjualan",
    "blacklist": "Blacklist",
    "customerList": "Daftar Pelanggan",
    "discountList": "Daftar Diskon",
    "discountManagement": "Manajemen Diskon",
    "transfer": "Transfer",
    "stoktaking": "Stock Opname",
    "menuInbound": "Manajemen Inbound",
    "menuOutbound": "Manajemen Outbound",
    "menuProductLocation": "Manajemen Rak",
    "warehouseList": "Daftar Gudang",
    "stockPullRecord": "Riwayat Penarikan Stok Gudang Pihak Ketiga",
    "safetyStockManagement": "Manajemen Stok Pengaman",
    "stock": "Stok",
    "storeMoving": "Salin Toko",
    "productScrape": "Scrape Produk",
    "channelProduct": "Produk Channel",
    "masterProductList": "Daftar Master Produk",
    "orderProcess": "Proses Pesanan",
    "allOrders": "Seluruh Pesanan",
    "MenuOrders": "Pesanan",
    "putAside": "Sisihkan",
    "autoBump": "Naikkan Produk Otomatis",
    "oneStoresProductsToAnyStores": "Salin produk dari satu toko ke toko mana pun untuk membuka toko dengan cepat",
    "blibliProductList": "Daftar Produk Blibli",
    "FulfillSetting": "Manajemen Konfigurasi",
    "MassAdd": "Tambah Massal Dengan Template",
    "PromotionEditTpl": "Template Edit",
    "PromotionAddTpl": "Template Baru",
    "PromotionTpl": "Template Promosi",
    "IntegrationsUpdateStore": "Perbarui Informasi Toko",
    "PrintPickingListTemplate": "Template Picking List",
    "PrintInvoiceTemplate": "Template Invoice",
    "PrintPackingListTemplate": "Template Packing List",
    "PrintLabelTemplate": "Template Label",
    "addBundleISKU": "Tambah Bundle ISKU",
    "editBundleISKU": "Edit Bundle ISKU",
    "menuInboundManagementSubmit": "Manajemen Inbound / Inbound Daftar Inbound",
    "menuInboundManagementDetail": "Manajemen Inbound / Edit Daftar Inbound",
    "menuInboundManagementEdit": "Manajemen Inbound / Edit Daftar Inbound",
    "menuInboundManagementAdd": "Manajemen Inbound / Buat Daftar Inbound",
    "menuOutboundManagementSubmit": "Manajemen Outbound / Outbound Daftar Outbound",
    "menuOutboundManagementDetail": "Manajemen Outbound / Edit Daftar Outbound",
    "menuOutboundManagementEdit": "Manajemen Outbound / Edit Daftar Outbound",
    "menuOutboundManagementAdd": "Manajemen Outbound / Buat Daftar Outbound",
    "safetyStockSettings": "Pengaturan Stok Pengaman",
    "storeStockChangeHistory": "Riwayat Push Stok",
    "stockPullRecordDetail": "Riwayat Tarik Stok Detail",
    "thirdpartyWarehouseInventoryPullRecord": "Riwayat Tarik Stok",
    "stockInOutRecord": "Riwayat Stok Keluar/Masuk",
    "editWarehouseStock": "Ubah Stok Gudang",
    "editLocalISKU": "Edit Master SKU",
    "editISKU": "Edit Stok SKU",
    "binPsku": "Ikat SKU Toko",
    "addISKU": "Add Inventory SKU",
    "EditOrderTpl": "Ubah Template",
    "AddOrderTpl": "Tambah Template",
    "CRMHistory": "Riwayat",
    "CRMSales": "Manajemen Penjualan",
    "CRMTelemarketing": "Manajemen Telemarketing",
    "SubscribeNow": "Berlangganan Sekarang",
    "RenewNow": "Perpanjang",
    "PaymentSubscriptionPrice": "Paket & Harga",
    "PaymentSubscriptionResult": "Status Pembayaran",
    "AccountPackageSetting": "Akun & Paket saat ini",
    "PackageSubscription": "Berlangganan",
    "TransactionHistory": "Riwayat Transaksi",
    "menuScrapingSettings": "Pengaturan Scraping",
    "ExclusiveSubscription": "Populer Diikuti",
    "TopPopularStore": "Toko Populer",
    "AccountManagement": "Manajemen paket",
    "OrderBatchLabel": "Cetak Massal",
    "GineeChartRoom1": "Omni Chat",
    "InvoicePrintTemplateSettings": "Pengaturan Template Cetak Invoice",
    "PackingListPrintTemplateSettings": "Pengaturan Template Cetak Packing List",
    "ShippingLabelTemplateSettings": "Pengaturan Template Label Pengiriman",
    "SenderInformationSettings": "Informasi Pengirim",
    "ShippingRuleSettings": "Metode Pengiriman",
    "GINEEFulfillment": "Ginee Fulfillment",
    "YUEHAIFulfillment": "YUEHAI Fulfillment",
    "FASTOCKFulfillment": "FASTOCK Fulfillment",
    "JDFulfillment": "JD Fulfillment",
    "OrderSettings": "Pengaturan Pesanan",
    "CollectEdit": "Collect to Master Product",
    "CollectSetting": "Pengaturan Scrape",
    "ScrapeList": "Daftar Scrape",
    "ReportChart": "Laporan",
    "ProductsCollection": "Scrape Produk",
    "editrole": "Edit Peran",
    "addrole": "Tambahkan Peran",
    "RoleSetting": "Pengaturan Peran",
    "StaffSetting": "Pengaturan Staf",
    "addstaff": "Tambahkan Staf",
    "staffManagementAdd": "Manajemen Staf/Tambahkan Staf",
    "staffManagementEdit": "Manajemen Staf / Edit Staf",
    "editstaff": "Edit Staf",
    "subscribe": "Berlangganan",
    "days": "Hari",
    "freetrial": "Uji coba gratis",
    "contactus": "Hubungi Kami",
    "EditCustomer": "Ubah Pelanggan",
    "PromotionManagement": "Pengaturan Promosi",
    "mProductSettings": "Pengaturan Master Produk",
    "productSettings": "Pengaturan Produk",
    "CustomerDetail": "Rincian Pelanggan",
    "NewCustomer": "Tambahkan Pelanggan",
    "GroupManagement": "Grup Pelanggan",
    "CustomerManagement": "Pelanggan",
    "MasterProductSettings": "Pengaturan Master Produk",
    "StockSetting": "Pengaturan Stok",
    "menuAddProductToWarehouseEditStock": "Isi Stok",
    "menuAddProductToWarehouse": "Tambahkan produk ke gudang",
    "stockManageList": "Daftar Stok",
    "stockManage": "Manajemen Stok",
    "BarChart": "Analisa Bisnis",
    "Masseditcategories": "Edit Massal Kategori dan Atribut",
    "MassEdit": "Edit Massal",
    "ShippingSetting": "Pengaturan Pengiriman",
    "BasicInformation": "Informasi Dasar",
    "BasicSetting": "Pengaturan Dasar",
    "ToolTipInfo": "Meliputi: Produk Aktif, Nonaktif, Habis, Dilarang",
    "Result": "Hasil",
    "PublishWait": "Sedang memproses produk, Silahkan tunggu",
    "UpdateFailed": "Gagal Diperbarui",
    "Publishing": "Produk Tersalin Sebagai Draf",
    "TryAgain": "Coba Lagi",
    "SomeStoresCopyFailed": "Beberapa Toko Gagal Salin",
    "CopySuccess": "Berhasil Disalin",
    "ViewNow": "Lihat Sekarang",
    "Close": "Tutup",
    "NoCopyStore": "Tidak ada Toko untuk disalin. Integrasikan Toko dan coba fitur ini",
    "CopyPublish": "Tampilkan",
    "CopyAsDraft": "Salin sebagai Draf",
    "CopyCount": "Produk sedang menunggu disalin",
    "Live": "Aktif",
    "All": "Semua",
    "Product": "Produk",
    "Store": "Toko",
    "Channel": "Channel",
    "CopyTo": "Salin ke",
    "CopyFrom": "Salin dari",
    "CopyAlertInfo": "Salin produk dari satu Toko ke Toko lainnya di Channel yang sama dan buka Toko baru Anda dengan cepat",
    "OperationGuide": "Panduan Operasi",
    "CopyStore": "Salin Toko",
    "AddStore": "Tambah Toko",
    "Copy": "Salin",
    "StoreList": "Daftar Toko",
    "MyStores": "Salin Toko",
    "customerCenter": "Halo! Ada yang bisa kami bantu? Anda bisa menghubungi kami setiap hari pada jam operasional 09:00-18:00",
    "addStore": "Tambah Toko",
    "helpInfo": "Pusat Bantuan",
    "addSyncStore": "Tambah Toko",
    "lastSyncTime": "Terakhir Sinkronisasi",
    "alreadyStore": "Toko Terhubung",
    "menuProductLocalUpdateAutoBind": "Master Produk / Perbarui aturan Ikat Otomatis",
    "menuInboundManagement": "Manajemen Inbound",
    "menuOutboundManagement": "Manajemen Outbound",
    "menuLowStockReport": "Produk Tak Laku",
    "menuLogisticsCostStatistics": "Statistik Biaya Pengiriman",
    "menuOutStockForecast": "Estimasi Stok Habis",
    "menuInventoryStatistics": "Statistik Stok",
    "menuLogisticsStatistics": "Statistik Logistik",
    "menuCustomerAreaAnalysis": "Analisis Area Pelanggan",
    "menuCustomerAnalysis": "Analisis Pelanggan",
    "menuCustomerStatistics": "Statistik Pelanggan",
    "menuStoreSaleReport": "Penjualan Toko",
    "menuPlatformSaleReport": "Penjualan Channel",
    "menuIncomeStatementReport": "Laba Rugi",
    "menuCategorySaleReport": "Penjualan Varian",
    "menuProductSaleReport": "Penjualan Produk",
    "menuVariantsSalesStatistics": "Penjualan Varian",
    "menuOrderReport": "Laporan Pesanan",
    "menuSetting": "Pengaturan",
    "ShippingOrderNew": "Kirim Pesanan",
    "status_ready_to_ship": "Siap Dikirim",
    "printOrderPending": "Menunggu Dicetak",
    "beCollectedTo": "Menunggu Dipickup",
    "orderAbnormal": "Pesanan Tidak Normal",
    "PendingOrder": "Menunggu Diproses",
    "pending": "Tertunda",
    "operationRecord": "Riwayat Operasi",
    "historyOperation": "Log operasi gudang pengiriman",
    "shipped": "Dikirim",
    "stored": "Disisihkan",
    "warehouseSettingShipping": "Gudang Pengiriman",
    "orderDetails": "Detail Order",
    "manualOrder": "Pesanan Manual",
    "menuOrder": "Pesanan",
    "menuIntegrations": "Integrasi",
    "menuProductLocationManagement": "Manajemen Rak",
    "menuProductLocationManagementOld": "Manajemen Lokasi Produk",
    "menuWarehouseStockDetails": "Rincian Stok Gudang",
    "menuStockPushRules": "Aturan Push Stok",
    "menuStoreShippingWarehouseSettings": "Simpan Pengaturan Gudang Pengiriman",
    "menuWarehouseManagement": "Manajemen Gudang",
    "menuPriceManagement": "Manajemen Harga",
    "menuProductLocalImportToCreate": "Master Produk / Import untuk Buat Produk",
    "menuProductLocalImportToBind": "Master Produk / Import untuk Ikat Produk",
    "menuProductLocalEditBundle": "Master Produk / Edit Produk Bundel",
    "menuProductLocalEdit": "Master Produk / Edit Product",
    "menuProductLocalAddBundle": "Master Produk / Tambah Produk Bundle",
    "menuProductLocalEditCombined": "Master Produk / Edit Produk Gabungan",
    "menuProductLocalAddCombined": "Master Produk / Tambahkan Produk Gabungan",
    "menuProductLocalAdd": "Master Produk / Tambah Produk",
    "menuProductWooCommerce": "Produk / WooCommerce",
    "menuBindHistory": "Riwayat Pengikatan",
    "menuProductLocal": "Master Produk",
    "menuProduct": "Daftar Produk",
    "menuPoints": "Poin",
    "menuMemberShipPage": "Halaman Member",
    "InventoryLog": "Riwayat Perubahan Stok",
    "menuMemberList": "List Member",
    "noticeList": "Pemberitahuan",
    "menuHomePage": "Dasbor",
    "productmassEditMaster": "Produk Maste/Edit Massal",
    "productPublishToTheStoreMaster": "Produk Master/Tampilkan ke toko",
    "resultsPublish": "Publikasikan hasil",
    "operationHistoryMass": "Riwayat Operasi Massal",
    "menuCapital": "Modal",
    "menuProductTikTok": "Produk / TikTok",
    "menuProductTikTokEdit": "Produk / TikTok/ Edit Product",
    "menuProductTikTokAdd": "Produk / TikTok / Tambah Produk",
    "menuProductZalora": "Produk / Zalora",
    "menuProductShopee": "Produk / Shopee",
    "ImportAdd": "Tambah Massal dengan Impor",
    "menuProductBlibliEdit": "Produk / Blibli/ Edit Product",
    "menuProductShopeeEdit": "Produk / Shopee/ Edit Product",
    "menuProductBlibliAdd": "Produk / Blibli/Tambah Produk",
    "menuProductShopeeAdd": "Produk / Shopee / Tambah Produk",
    "publishToStoreTip": "Master Produk / Ditampilkan Ke Toko",
    "changePassword": "Ganti Kata Sandi",
    "passworderror": "Harus berjumlah 8-20 karakter mengandung huruf, angka, dan simbol.",
    "pwdErrorInfo": "Password harus berjumlah 8-20 Karakter",
    "UpdateSuccess": "Berhasil Diperbarui",
    "storeDiagnose": "Diagnosis Toko",
    "shopeeAutoBump": "Shopee Naikkan Produk Otomatis",
    "menuscrapeList": "Daftar Scrape",
    "chromeExtension": "Ekstensi Chrome",
    "getFreeDataTool": "Dapatkan Tool Data Gratis",
    "bigdata": "Analisis Pasar",
    "free": "Gratis",
    "DataExtension": "Ekstensi Data Scrape",
    "confirm": "Konfirmasi",
    "noIndonesianLineGroup": "Sementara tidak ada grup Line Bahasa Indonesia",
    "noEnglishLineGroupTemporarily": "Sementara tidak ada grup Line Bahasa Inggris",
    "noThaiGroupsForWhatsapp": "Sementara tidak ada grup WA Bahasa Thailand",
    "noVietnameseLineGroup": "Sementara tidak ada grup Line Bahasa Vietnam",
    "noVietnameseWhatsappGroups": "Sementara tidak ada grup WA Bahasa Vietnam",
    "noChineseWhatsappGroupTemporarily": "Sementara tidak ada grup WA Mandarin",
    "noChineseLineGroupTemporarily": "Sementara tidak ada grup Line Mandarin",
    "storeLimitTip": "Paket langganan Anda telah kedaluwarsa. Jika Anda ingin menggunakan paket gratis, silakan pilih 2 toko untuk melanjutkan integrasi (paket gratis hanya dapat diikat paling banyak ke 2 toko, dan toko lainnya tidak terikat).",
    "max2Store": "Paket gratis hanya dapat diikat paling banyak ke 2 toko",
    "publishToTheStore": "Tampilkan ke toko",
    "createMasterProduct": "Buat Master Produk",
    "confirmedNewPassword": "Kata sandi baru yang dikonfirmasi",
    "newPassword": "kata sandi baru",
    "oldPassword": "password lama",
    "detail": "Rincian",
    "editProduct": "Edit Product",
    "orderXxxFailedToPushWarehouse": "Pesanan xxx gagal dipush ke gudang, alasan: xxx",
    "orderXxxPushedToWarehouseSuccessfully": "Pesanan xxx berhasil dikirim ke gudang",
    "shippingToWarehouseOperationSucceeded": "Pengiriman ke gudang berhasil",
    "shippingToWarehouseOperationIsSuccessful": "Pengiriman ke gudang berhasil, harap bersabar menunggu gudang memproses hasilnya",
    "currentlyTiktokOrdersAreNotSupported": "Saat ini, pesanan Tiktok tidak dapat mengatur pengiriman dan mencetak label melalui OpenAPI.",
    "shopeeOrdersWithTheSameLogistics": "Pesanan Shopee/Tiktok dengan ekspedisi yang sama dapat diatur Waktu Pickup secara masal",
    "gineeSupportsSettingUpAutomaticShipping": "Ginee dapat mengatur metode pengiriman secara otomatis untuk Toko Shopee/Bukalapak/Tokopedia/Akulaku/TikTok",
    "xxxStockDeductionProcessingEnableddisabled": "Pemrosesan pengurangan stok XXX berhasil diaktifkan/dinonaktifkan",
    "theEffectiveTimeOfXxx": "Waktu efektif pemrosesan pengurangan stok xxx diubah dari xxx menjadi xxx",
    "pleaseOpenAtLeastOneStore": "Silakan buka minimal satu toko",
    "thereIsAnUnfinishedOrderExport": "Terdapat operasi ekspor pesanan yang belum selesai, silahkan buka riwayat ekspor untuk melihat catatan ekspor, dan ekspor kembali setelah pengopersian selesai",
    "theSystemServiceIsAbnormalPlease": "Layanan sistem sedang bermasalah, silahkan refresh halaman dan coba lagi",
    "afterTurnOffTheOrderWill": "Setelah dimatikan, pesanan akan dialokasikan ke gudang sesuai dengan prioritas aturan gudang pengiriman",
    "pleaseConfirmWhetherWantToPush": "Mohon konfirmasi apakah ingin mendorong ke gudang pihak ketiga untuk melakukan pengiriman?",
    "pleaseConfirmWhetherWantToRedistribute": "Mohon konfirmasi apakah ingin mendistribusikan ulang?",
    "outboundOrder": "Daftar Outbound",
    "splitOrder": "Pisahkan Pesanan",
    "abnormalDescription": "Deskripsi Masalah",
    "problemOrder": "Pesanan Bermasalah",
    "redistribute": "Distribusikan Ulang",
    "deliveryWarehouse": "Gudang Pengiriman",
    "nonindonesiaOrdersDoNotSupportXxx": "Pesanan non-Indonesia tidak dapat melakukan pengiriman dari gudang xxx, harap sesuaikan gudang pengiriman",
    "theOrderDoesNotSupportDelivery": "Pesanan tidak mendukung pengiriman dari xxx, harap sesuaikan gudang pengiriman",
    "theAvailableStockOfMskuXxx": "Stok MSKU xxx yang tersedia tidak mencukupi, mohon didistribusikan kembali setelah stok tersedia kembali",
    "mskuXxxIsNotAssociatedWith": "MSKU xxx tidak terkait dengan gudang xxx, harap tambahkan produk ke gudang dan distribusikan ulang",
    "channelSkuXxxIsNotBound": "Channel SKU xxx tidak terikat dengan MSKU, harap tambahkan hubungan pemetaan dan distribusikan ulang",
    "channelSkuXxxDoesNotHave": "Channel SKU xxx tidak memiliki gudang yang dialokasikan, harap tambahkan aturan gudang pengiriman dan distribusikan ulang produk",
    "failedCreateOutboundOrder": "Gagal Membuat Daftar Outbound",
    "warehouseAllocationError": "Kesalahan Alokasi Gudang",
    "mskuNotAssociatedWithWarehouse": "MSKU Tidak Terkait Dengan Gudang",
    "mskuNotMatched": "Tidak Terikat MSKU",
    "unallocatedWarehouse": "Gudang Belum Dialokasikan",
    "pushTheThirdPartyWarehouse": "Push ke Gudang Pihak Ketiga",
    "paidOrder": "Lunas",
    "waitingPaymentOrder": "Dipesan",
    "alreadyInbound": "Sudah Inbound",
    "pleaseConfirmWhetherItIsAutomatically": "Mohon konfirmasi apakah otomatis inbound barang retur?",
    "inboundReturnProduct": "Inbound Retur",
    "abnormalType": "Tipe Abnormal",
    "printTime": "Waktu Cetak",
    "warehouseoutboundOrder": "Gudang/Daftar Outbound",
    "variantsku": "Varian/SKU",
    "afterOpeningTheOrderWillBe": "Setelah diaktifkan, pesanan akan otomatis dikirim ke gudang pihak ketiga jika pesanan memenuhi ketentuan pengiriman.",
    "automaticallyPushToThirdpartyWarehouse": "Secara Otomatis Mendorong ke Gudang Pihak Ketiga",
    "inventoryVerificationAfterItIsTurned": "Pengecekan Stok: Setelah diaktifkan, maka akan memverifikasi apakah stok yang tersedia di gudang cukup, dan memprioritaskan gudang dengan stok yang cukup untuk pengiriman.",
    "availableStockDeliveryDisabledSuccessfully": "'Pengiriman stok yang tersedia' berhasil dinonaktifkan",
    "availableStockDeliveryActivatedSuccessfully": "'Pengiriman stok yang tersedia' berhasil diaktifkan",
    "shippingWarehouseSettingsDisabledSuccessfully": "Pengaturan gudang pengiriman berhasil dinonaktifkan",
    "shippingWarehouseSettingsActivatedSuccessfully": "Pengaturan gudang pengiriman berhasil diaktifkan",
    "orderstockDeductionProcessDisabledSuccessfully": "Proses pengurangan pesanan-stok berhasil dinonaktifkan",
    "theStockDeductionProcessingOfXxx": "Pemrosesan pengurangan stok toko xxx berhasil diaktifkan/dinonaktifkan, dan waktu efektif diubah dari xxx ke xxx",
    "orderstockDeductionProcessSuccessfullyActivated": "Proses pengurangan stok-pesanan berhasil diaktifkan",
    "afterActivatedOrdersWillBeAssigned": "Setelah diaktifkan, pesanan akan didistribusikan ke gudang sesuai dengan produk yang berbeda, dan pesanan yang sama bisa saja didistribusikan dari gudang yang berbeda untuk melakukan pengiriman; jika tidak diaktifkan, pesanan akan didisytibusikan ke gudang yang sama untuk pengiriman",
    "shipmentWarehouseSettingsDeterminesWhetherWarehouse": "Pengaturan Gudang Pengiriman: Menentukan apakah pendistribusian gudang dilakukan berdasarkan pesanan atau produk",
    "effectiveTime": "Waktu Efektif",
    "activateStatus": "Status Pengaktifkan",
    "activateRange": "Aktifkan Jangkauan",
    "onceTurnOffAllStoreOrders": "Setelah dimatikan, semua pesanan toko tidak akan diproses inventaris",
    "afterActivatedTheOrderWillGenerate": "Setelah diaktifkan, pesanan akan membuat daftar outbound untuk mengurangi stok ; jika tidak diaktifkan, stok tidak akan berkurang saat pesanan dikirim",
    "orderstockDeductionProcess": "Proses Pemotongan Stok-Pesanan",
    "orderCreatedSuccessfully": "Pesanan Manual Berhasil Dibuat",
    "shipmentWarning": "Peringatan Pengiriman",
    "withoutUnboxingVideosComplaintsOfLessdamage": "TANPA VIDEO UNBOXING,KOMPLAN KURANG BARANG/RUSAK TIDAK AKAN TERIMA",
    "shippingRebate": "Potongan Biaya Kirim",
    "shippingFeePaidByBuyer": "Biaya Kirim Dibayar Pembeli",
    "operationIsTooFrequentPleaseExport": "Pengoperasian terlalu sering, silakan ekspor kembali setelah 1 menit",
    "rdPartyLogisticsShippingFee": "Biaya Pengiriman Logistik Pihak ke-3",
    "shippingSubtotal": "Subtotal Pengiriman",
    "importFailedInternalSystemErrorPlease": "Impor gagal: Kesalahan sistem internal, harap impor ulang",
    "emailFormatIsIncorrect": "Format email salah",
    "supportsNumberslettersMaxCharacters": "Diisi angka, huruf, dan \"-\". Maks. 30 karakter",
    "supportsNumberslettersspacesMaxCharacters": "Diisi angka/huruf/spasi/-, maks. 20 karakter",
    "basedOnProductSeparateRowFor": "Berdasarkan Produk (Baris terpisah untuk pesanan dengan multi-produk)",
    "basedOnOrderOrderWithMultiple": "Berdasarkan Pesanan (Pesanan dengan multi-produk akan digabungkan)",
    "exportMethod": "Metode Ekspor",
    "pleaseConfirmWhetherTheOrderStatus": "Silakan konfirmasi apakah status pesanan diatur sebagai Telah Dikembalikan",
    "editOrder": "Edit pesanan",
    "insufficientStockOfXxxPleaseMake": "Stok xxx tidak cukup. Silakan lengkapi stok sebelum melakukan pengiriman",
    "paymentProblem": "Masalah pembayaran",
    "invalidOrder": "Pesanan invalid",
    "moveToReturned": "Pindah Ke Dikembalikan",
    "selectCustomer": "Pilih pelanggan",
    "CustomerMobile": "No. Handphone",
    "fyiPleaseDeleteWhenUploading": "FYI, tolong hapus saat mengunggah",
    "pleaseDeleteThisTextBoxBefore": "Harap hapus kotak teks ini sebelum mengimpor",
    "fillInWithTwocharacterShortCode": "Untuk negara, isi dengan kode singkat dua karakter. Untuk pembayaran, isi dengan metode pra-pembayaran/pembayaran. Jumlah pembayaran tidak boleh lebih besar dari total nominal pesanan",
    "multipleMskusunitPricesquantitiesInTheSame": "Beberapa MSKU/harga satuan/jumlah dalam pesanan yang sama harus dimasukkan pada baris baru. Harga satuan tidak boleh kurang dari 0, dan jumlahnya harus lebih besar dari 0. Saat memasukkan beberapa baris, informasi pesanan yang tersisa dapat dikosongkan.",
    "orderNumberOnlySupportsLettersNumbers": "Nomor pesanan hanya dapat diisi huruf, angka, -/",
    "fieldWithMarkAreRequired": "Kolom dengan tanda * wajib diisi",
    "pleaseEditInAccordanceWithThe": "Silahkan edit sesuai dengan susunan template",
    "importantNotice": "Pemberitahuan Penting",
    "theNumberOfDataRecordsCannot": "Jumlah catatan data tidak boleh melebihi 5000",
    "pleaseImportWithDownloadedFormatTemplate": "Harap impor dengan template format yang diunduh, header tidak dapat dimodifikasi",
    "onlySupportFilesInXlsxFormat": "Hanya mendukung file dalam format xlsx (tidak valid jika mengubah sufiks file secara manual)",
    "downloadImportTemplate": "Unduh template impor",
    "importOrder": "Import",
    "uploadPaymentProof": "Unggah Bukti Pembayaran",
    "paymentProof": "Bukti Pembayaran",
    "receivingAccount": "Rekening Penerima",
    "paymentAccount": "Rekening Pembayaran",
    "receiptNumber": "Nomor Tanda Terima Pembayaran",
    "theTotalPaymentAmountCannotExceed": "Jumlah total pembayaran tidak dapat melebihi jumlah total pesanan",
    "addPaymentRecord": "Tambahkan Catatan Pembayaran",
    "refer": "Rujukan",
    "useCommaToSeparateMultipleItem": "Gunakan koma untuk memisahkan beberapa item",
    "saveContinueAdd": "Simpan & Lanjut Tambahkan",
    "save": "Simpan",
    "paymentSerialNumber": "Nomor Seri Pembayaran",
    "paymentAmount": "Jumlah Pembayaran",
    "productTotalAmount": "Jumlah Produk Total",
    "totalQuantity": "Jumlah Total",
    "totalPrice": "Total Harga",
    "addProduct": "Tambah Produk",
    "recipientArea": "Daerah Penerima",
    "remarks": "Catatan",
    "paymentInfo": "Info Pembayaran",
    "amountInfo": "Info Nominal",
    "productInfo": "Info Produk",
    "receiptInfo": "Info Tanda Terima",
    "basicInfo": "Info Dasar",
    "referenceOrder": "Pesanan Referensi",
    "canBeEnteredQuicklyByImporting": "Dapat dimasukkan dengan cepat dengan mengimpor informasi yang ada",
    "addOrder": "Tambah Pesanan",
    "bindStoreNow": "Lkat Toko Sekarang",
    "bindTips": "Karena Anda belum mengintegrasikan toko channel, daftar pesanan saat ini masih kosong. Harap ikat toko terlebih dahulu, kemudian lihat atau operasikan pesanan",
    "PlatformDiscount": "Diskon Platform",
    "TotalDiscount": "Total Diskon",
    "voidTipss": "Setelah menyisihkan pesanan tersebut ke menu Disisihkan, maka sistem tidak lagi mendukung semua operasi pesanan.",
    "voidTips": "Ketika memindahkan pesanan ke menu Disisihkan, maka tidak akan lagi dilakukan penguncian/pengurangan stok untuk pesanan tersebut. Anda harus mengembalikan pesanan kembali sebelum stok dapat dikunci/dikurangi secara normal. Harap mengoprasikannya dengan hati-hati.",
    "pickingList": "Picking List",
    "printedBy": "Dicetak Oleh",
    "supportTikiAndZaloraOrdersToComplete": "Papat menyelesaikan penerimaan produk secara massal untuk pesanan Tiki dan Zalora. Untuk pesanan Blibli, silakan buka detail pesanan untuk pengoprasian",
    "onlyLazadaBlibiliAndZaloraSupportPackaging": "Hanya pesanan Lazada, Blibli dan Zalora yang dapat dikemas, dan pesanan yang sudah dikemas tidak dapat dikemas ulang. Jika Anda perlu mengubah jumlah produk pesanan Blibli, harap perbarui detail pesanan",
    "confirmWhetherToSetTheOrderStatusAsShipped": "Silahkan konfirmasi apakah status pesanan diatur menjadi sudah dikirim",
    "markAsShipped": "Tandai sudah dikirim",
    "whetherToUseGineeTemplateToPrintInvoices": "Apakah ingin menggunakan template Ginee untuk mencetak invoice(Pesanan Lazada PH hanya mendukung pencetakan invoice menggunakan template Ginee)",
    "buyerPayment": "Jumlah Pembayaran Pembeli",
    "doAllProductInTheSameOrder": "Apakah semua produk dalam pesanan yang sama akan menggunakan nomor invoice yang sama",
    "clickHereToSetting": "Klik di sini untuk mengatur",
    "operationLog": "Riwayat Operasi",
    "massSetPickupTime": "Atur Masal Waktu Pickup",
    "numberOfFails": "Jumlah yang Gagal",
    "totalNumber": "Jumlah Total",
    "invoiceNumberSettingFailureDetails": "Rincian kegagalan pengaturan nomor invoice",
    "invoiceNumberIsSetSuccessfully": "Nomor invoice berhasil diatur",
    "clickHereToSetWhetherAll": "Klik di sini untuk mengatur apakah semua produk dalam pesanan yang sama akan menggunakan nomor invoice yang sama",
    "setInvoiceNumber": "Atur Nomor Invoice",
    "printShippingLabelAndPickingLists": "Cetak Shipping Label dan Picking List",
    "pleaseConfirmWhetherTheReceiptIs": "Mohon konfirmasi penerimaan barang telah selesai.",
    "onlyTikiOrdersCanBeReceived": "Hanya pesanan Tiki yang dapat diterima secara masal. Untuk pesanan Blibli, silakan atur di detail pesanan",
    "malaysiaTemplates": "Template Malaysia",
    "generalTemplates": "Template Umum",
    "unassignedWarehouse": "Gudang Belum Dialokasikan",
    "jikaPesananTelahDikaitkanDenganGudang": "If the order has been associated with third-party warehouse, please confirm whether the outbound order has been successfully canceled by the warehouse to prevent inventory errors",
    "inOrderToEnsureTheNormal": "Demi memastikan penggunaan fungsi sistem berjalan secara normal, selama periode promosi tepatnya tanggal 11-12 November untuk fitur pengunduhan pesanan tidak tersedia . Terima kasih atas dukungan Anda",
    "warehouse": "Gudang",
    "unpairedMasterSKU": "Master SKU Belum Dipasangkan",
    "postalCode": "Kode Pos",
    "detailsAddress": "Rincian Alamat",
    "street": "Jalan",
    "districts": "Kecamatan",
    "city": "Kota",
    "province": "Provinsi",
    "senderPhone": "Tel Pengirim",
    "senderName": "Nama Pengirim",
    "dataLoadingPleaseWaitOrClick": "Memuat data, harap tunggu atau klik tombol kembali untuk lewati",
    "activatedAllOrdersFromTheAuthorized": "Setelah diaktifkan, pesanan dari semua toko yang terintegrasi harus dikirim setelah \"Pesanan Dikemas\"",
    "restrictionShippingOfOrdersMustBe": "Limitasi Pengiriman: Prioritas harus diberikan pada Atur Pesanan setelah Pengemasan, dan kemudian Atur Pengiriman",
    "shippingDiscountSeller": "Diskon Pengiriman Penjual",
    "feeDiscountFromPlShipping": "Diskon Pengiriman Logistik Pihak Ketiga",
    "taxEscrow": "Pajak Escrow",
    "transactionFeeBuyer": "Biaya Transasksi Pembeli",
    "transactionFeeSeller": "Biaya Transaksi Penjual",
    "SetPackStatusTitle": "Atur Status Packing",
    "SetPackStatusInMass": "Atur Massal Status Packing",
    "timeUpdate": "Waktu Pembaruan",
    "timeCreation": "Dibuat Pada",
    "contentOperation": "Konten",
    "moduleOperation": "Modul Operasi",
    "recordOperation": "Riwayat Operasi",
    "orderRestore": "Kembalikan Pesanan",
    "inToAlreadyShippedMove1": "Pindah ke sudah dikirim",
    "warehouseThirdparty": "Gudang pihak ketiga",
    "warehouseOwn1": "Warehouse sendiri",
    "inToAlreadyShippedMove": "Pindah ke Dikirim",
    "inToBeCollectedMove": "Pindahkan ke Menunggu Dipickup",
    "numberEmptyAwb": "Nomor AWB kosong",
    "printedNot": "Tidak dicetak",
    "youWantToOperateSeparatelyPlease": "Jika Anda ingin mengaturnya secara terpisah, silakan pindah ke daftar semua pesanan untuk menyelesaikan pengaturan",
    "ordersWillBeDirectlyPackagedAnd": "Pesanan Lazada/Blibli akan langsung dikemas dan dikirim",
    "processingTheTokopediabukalapakOrderWillBe": "Setelah diproses, pesanan Tokopedia/Bukalapak akan diterima dan dikirim",
    "youSureToProcessTheSelected": "Apakah Anda yakin untuk memproses pesanan X yang dipilih bersama dan memindahkannya ke pesanan yang tertunda?",
    "failedCancel": "Pembatalan gagal",
    "pushToThirdpartyWarehouseFailed": "Gagal push ke gudang pihak ketiga",
    "toPendingMove": "Pindah ke bagian tertunda",
    "asPriorityMark": "Tandai sebagai prioritas",
    "orderOutOfStockAll": "Semua pesanan stok habis",
    "orderOutOfStockSelected": "Pesanan dipilih stok habis",
    "stockCheck": "Periksa stok",
    "skuXxxxIsInsufficientInThe": "Master SKU: {x} tidak mencukupi di gudang {y}, harap proses ulang setelah restock pada halaman manajemen stok",
    "skuXxxxDoesNotExistIn": "Master SKU: XXXX tidak ada di gudang XXXX. Silakan pilih ulang gudang dengan mengganti master SKU atau menambahkan master SKU baru di gudang",
    "warehouseToShipmentPush": "Push gudang untuk pengiriman",
    "orderProcessing": "Pesanan diproses",
    "processingFailed": "Gagal diproses",
    "ofStockOut": "Stok habis",
    "warehouseOwn": "Warehouse Sendiri",
    "failedOrdersView": "Lihat order gagal",
    "failed": "Gagal",
    "success": "Berhasil",
    "total": "Total",
    "descriptionXxOrdersHaveBeenSynchronized": "Deskripsi progress: XX pesanan telah disinkronkan, dan XX pesanan yang tersisa sedang menunggu untuk disinkronkan",
    "pleaseWaitSyncing": "Sedang sinkronisasi, mohon tunggu",
    "result": "Hasil",
    "reasonFailed": "Alasan gagal",
    "exceptionSynchronization": "Sinkronisasi pengecualian",
    "otherLogisticsProviderNumberEmptyPlaceholder": "Belum memberikan no. resi, harap sinkronisasikan lagi",
    "theCheckIsRemovedTheIntegrated": "Setelah ceklis dihapus, stok master produk yang terintegrasi tidak akan bertambah",
    "selectedTheIntegratedMasterProductStock": "Setelah dipilih, stok master produk yang terintegrasi akan bertambah",
    "ifNoRuleIsAddedAfter": "Tips: Jika tidak ada aturan yang ditambahkan, setelah pesanan diubah ke status dibatalkan, stok produk dari semua master produk yang terintegrasi dalam pesanan, akan secara otomatis direstock",
    "forTheProductStockOfThe": "Hanya untuk stok produk dari master produk yang sesuai pesanan, jika master produk tidak diintegrasikan maka tidak akan diproses",
    "theReasonForCancellationIs1": "Jika alasan pembatalan adalah ##, produk tidak akan direstock",
    "theReasonForCancellationIs": "Jika alasan pembatalan adalah ##, perbarui produk untuk restock",
    "operationContent": "Konten",
    "delete": "Hapus",
    "updateTime": "Waktu Pembaruan",
    "createTime": "Dibuat Pada",
    "cancelReason": "Alasan Pembatalan",
    "operator": "Operator",
    "pleaseSelectStore": "Silakan pilih toko",
    "pleasesSelectChannel": "Silakan pilih channel",
    "no": "Tidak",
    "yes": "Ya",
    "updateWarehouseStock": "Restock Produk",
    "unknownReason": "Alasan Tidak Diketahui",
    "buyerCanceled": "Dibatalkan oleh Pembeli",
    "systemCancel": "Pembatalan Sistem",
    "cancelSetting": "Batalkan Pengaturan",
    "kodePos": "Kode Pos",
    "nameFirst": "Nama Depan",
    "nameLast": "Nama Keluarga",
    "staff": "Staff",
    "secretary": "Sekretaris",
    "receptionist": "Resepsionis",
    "security": "Satpam",
    "driver": "Supir",
    "helperDomestic": "Asisten",
    "relationship": "Hubungan",
    "mrmrsms": "Tuan/Nyonya",
    "spouse": "Pasangan",
    "related": "Keluarga",
    "lessThanCharactersRequired": "Harus diisi, tidak lebih dari 30 karakter",
    "codeSettlement": "No. Pelepasan",
    "statusReceiver": "Status Penerimaan",
    "nameReceiver": "Nama Penerima",
    "productDateReceived": "Tanggal Diterima",
    "pleaseCompleteTheFollowingInformationAnd": "Tips: Harap lengkapi informasi berikut dan perbarui status pesanan menjadi selesai",
    "completedOrder": "Pesanan Selesai",
    "cancel": "Batalkan",
    "noMoreThanCharactersRequired1": "Harus Diisi, tidak lebih dari 300 karakter",
    "ofThePerformanceReasonsPart": "Bagian dari Alasan Kinerja",
    "quantityFinal": "Jumlah Akhir",
    "ifTheCurrentOrderOnlySupports": "Tips: Jika pesanan saat ini hanya mendukung fulfillment sebagian, harap perbarui jumlah produk. Jika jumlah semua produk dalam pesanan saat ini diperbarui menjadi 0, pesanan akan dibatalkan",
    "quantityChange": "Ubah Jumlah",
    "endTimeMustBeGreaterThan": "Waktu selesai harus lebih besar dari waktu dimulai",
    "noMoreThanCharactersRequired": "Harus Diisi, tidak lebih dari 300 karakter",
    "fieldsRequired": "Harus Diisi",
    "installationnote": "Catatan Instalasi",
    "installationmobile": "Nomor Telepon Petugas Instalasi",
    "installationofficer": "Petugas Instalasi",
    "endDateInstallation": "Tanggal Selesai Instalasi",
    "startDateInstallation": "Tanggal Mulai Instalasi",
    "endDateShipping": "Tanggal Selesai Pengiriman",
    "startDateShipping": "Tanggal Mulai Pengiriman",
    "nameCourrier": "Nama Kurir",
    "toPackSomeOrdersPleaseTry": "Beberapa pesanan gagal dikemas, silakan coba lagi nanti",
    "successfullyPackaged": "Berhasil Dikemas",
    "pleaseWaitPacking": "Dikemas, harap menunggu",
    "quantity": "Jumlah",
    "stockAvailablelocked": "Tersedia/Stok Terkunci",
    "skuwarehouseMaster": "Master SKU/Gudang",
    "nameProduct": "Nama Produk",
    "numberOrder": "Nomor Pesanan",
    "onlyLazadaAndBlibliOrdersSupport": "Tip: Hanya pesanan Lazada dan Blibli yang mendukung pengemasan, dan yang dikemas tidak mendukung pengemasan dua kali. Jika Anda perlu mengubah jumlah pesanan Blibli, harap perbarui di detail pesanan",
    "theProductsOfTheSameOrder": "Kemas produk dengan pesanan yang sama di Blibli (Hanya Produk Reguler/Produk besar)",
    "pack": "Dikemas",
    "stockAvailable": "Tersedia",
    "serviceProviderFulfillment": "Fulfillment",
    "skuMaster": "Master SKU",
    "nameWarehouse": "Nama Gudang",
    "serviceProvider": "Penyedia Layanan",
    "theMasterSkuReplacing": "Ganti master SKU",
    "withXxxxxxPaired": "Dipasangkan dengan XXXXXX",
    "reasonReturn": "Alasan Retur",
    "itemsRestock": "Nominal yang Dikembalikan",
    "amountRefund": "Nominal Refund",
    "shippingReturn": "Retur Pengiriman",
    "quantityReturn": "Jumlah Retur",
    "totalRefundable": "Total dapat Direfund",
    "sendNotificationsToCustomers": "Kirim Notifikasi ke Pelanggan",
    "totalRefund": "Total Refund",
    "productRefund": "Refund Produk",
    "ifYouChooseToIncreaseStock": "Tips: Jika Anda memilih untuk menambahkan stok, stok produk dengan jumlah yang dikembalikan akan meningkat",
    "refund": "Pengembalian Dana",
    "buyerInfoEdit": "Edit Informasi Pembeli",
    "location": "Lokasi",
    "other": "Lainnya",
    "declinedPayment": "Pembayaran Ditolak",
    "orderFraudulent": "Pesanan Palsu",
    "unavailableItems": "Produk Tidak Tersedia",
    "changedcanceledOrderCustomer": "Pelanggan dibatalkan",
    "CreditCardTransactionFee": "Biaya Layanan Kartu Kredit",
    "moreThan5": "Lebih dari 5 hari",
    "fiveDays": "Dalam 5 hari",
    "threeDays": "Dalam 3 hari",
    "twoDays": "Dalam 2 hari",
    "oneDays": "Dalam 1 hari",
    "noNotes": "Tidak ada Catatan",
    "hasNotes": "Ada Catatan",
    "noMessage": "Tidak ada Pesan",
    "hasMessage": "Ada Pesan",
    "multipleProduct": "Banyak Produk",
    "oneProductWithMoreThanOneQty": "1 Produk lebih dari 1 pc",
    "singleProduct": "Single Produk",
    "jdTip": "Pengumuman: Akibat masalah di API Channel, Toko JD.ID yang terintegrasi sejak 29 April 2021 akan mengalami masalah pesanan yang tidak dapat tersinkronisasi, Tim JD.ID sedang melakukan perbaikan sekarang ini, mohon maaf atas ketidaknyamanannya.",
    "orderIdSearchTips": "Gunakan \",\" untuk pisahkan No. Pesanan",
    "lastSync": "Sinkronisasi Terakhir",
    "sortBy": "Urutkan",
    "asc3": "Terbesar",
    "desc2": "Terkecil",
    "desc4": "Terdekat",
    "asc2": "Terlama",
    "desc": "Terbaru",
    "asc": "Terlama",
    "zToA": "(Z-A)",
    "aToZ": "(A-Z)",
    "payTime": "Waktu Pembayaran",
    "deliverDeadline": "Batas Waktu Pengiriman",
    "productSku": "SKU Produk",
    "failnumber": "Jumlah Gagal",
    "sucessnumber": "Jumlah Berhasil",
    "totalnumber": "Jumlah Total",
    "lazadatip": "Selama campaign Lazada (26 Maret hingga 31 Maret) akan ada delay dalam sinkronisasi pesanan, Dan selama pembatasan API Lazada, Anda tidak dapat mengedit informasi produk (termasuk stok dan harga) atau menampilkan produk baru melalui Ginee. Mohon maaf atas ketidaknyamanannya",
    "keyword": "Kata Kunci",
    "logisticsProviderNumberEmptyPlaceholder": "Shopee belum memberikan no. resi, harap sinkronisasikan lagi",
    "logisticsProviderNameEmptyPlaceholder": "Shopee belum mengatur jasa kirim, harap sinkronisasikan lagi",
    "PrintPdfErrorMessage": "文件生成失败，请稍后重试",
    "orderDetailNoteLimit": "kurang dari 500 karakter",
    "GoContinue": "Lanjutkan",
    "BrowserBlockedInfo": "Terdeteksi bahwa jendela pop-up Anda di blok oleh browser, silakan klik \"Lanjutkan\" untuk membuka jendela pop-up Anda (Anda juga dapat mencari \"Cara Mengatasi Masalah Halaman Diblok di Browser Anda\" di Pusat Bantuan untuk mengatasinya)",
    "Notification": "Notifikasi",
    "ShowLabelProductInfo": "(Bukalapak)Cetak Label dengan detail barang",
    "VoucherPlatform": "Voucher Channel",
    "PriceCreditCardServiceFee": "Biaya Layanan Kartu Kredit",
    "PriceSellerReturnRefundAmount": "Nominal Refund",
    "PriceCoinCashBack": "Koin Cashback Penjual",
    "PriceCoin": "Koin",
    "AllStatus": "Semua Status",
    "AllType": "Semua Tipe",
    "theMarketplacePromotionPeriodPleaseUnderstand": "Selama periode promosi di marketplace, harap dimengerti jika ada keterlambatan pada sinkronisasi pesanan",
    "pendingTableTips": "Proses pesanan sementara hanya tersedia untuk Shopee/Bukalapak/Tokopedia/Lazada/JD/Shopify",
    "Filter": "Filter",
    "InvoiceSettings": "Pengaturan Invoice",
    "MultipleProduct": "Banyak Produk",
    "OneProductOneQty": "1 Produk lebih dari 1 pc",
    "paidTime": "Waktu Pembayaran",
    "packageStatus": "Status Kemas",
    "shipBefore": "Kirim Sebelum",
    "sellerNote": "Catatan Penjual",
    "buyerNote": "Catatan Pembeli",
    "packageType": "Jenis Paket",
    "SingleProduct": "Single Produk",
    "orderProductType": "Tipe Produk",
    "ViewFailedList": "Lihat Daftar Gagal",
    "AWBTrackingNumber2": "AWB/Invoice",
    "PickUpTime": "Waktu Pickup",
    "NoEmptyText": "Tidak ada data",
    "OrdersFailedTips": "Beberapa pesanan gagal dikirim, coba lagi nanti",
    "SuccessfullyShipped": "Berhasil Dikirim",
    "TotalOrdersShipped": "Jumlah total pesanan yang akan dikirim:",
    "ShippingWait": "Sedang Dikirim, Mohon Tunggu",
    "action": "Atur",
    "SeeMoreRetract": "lihat lebih singkat",
    "FailedReason": "Alasan Gagal",
    "RecipientMobile": "No. Handphone Penerima",
    "SetNow": "Atur Sekarang",
    "SkipNext": "Lewati, Selanjutnya",
    "shippingTips3": "Karena batasan API JD.ID, Label pengiriman harus dicetak sebelum mengirim pesanan. Apabila belum dicetak, maka tidak akan bisa dikirim.",
    "shippingTips2": "Apabila Anda tidak ingin mengatur metode pengiriman otomatis, sistem akan secara otomatis menerapkan metode pengiriman Pickup (prioritas) atau Dropoff atau Manual Ship.",
    "shippingTips1": "Anda belum mengatur metode pengiriman, klik Atur Sekarang untuk pengaturan pengiriman otomatis.",
    "FilterOrder": "Filter Pesanan",
    "setAddress": "Belum ada Alamat, silakan ke Seller Center untuk atur",
    "ArrangeShipment2": "Atur Pengiriman",
    "MassUpload": "Unggah Massal",
    "ShippingCarrier": "Kurir Pengiriman",
    "OrderQuantity": "Jumlah pesanan",
    "DownloadTemplate": "Unduh Template",
    "FileSizeLimitTips": "Hanya mendukung file xlsx/xls dan lebih kecil dari 5M",
    "ImportFile": "Import File",
    "FileFormatInvalid": "Format file impor tidak sesuai",
    "ProcessFailed": "Gagal Diproses",
    "ToBeDelivered": "Menunggu Mengatur Pengiriman",
    "ToBeAccepted": "Lunas",
    "PickUpAddress": "Alamat Pickup",
    "axiosTips": "Kiat",
    "moreMaxLength": "Maksimum { maxLength } Karakter",
    "PleaseSelect": "Silahkan Pilih",
    "SyncError": "Sinkronisasi Gagal",
    "syncSuccess": "Sinkronisasi Berhasil",
    "Synchronizing": "Sedang Sinkronisasi",
    "reset": "Reset",
    "search": "Cari",
    "status_failed": "Gagal",
    "status_returned": "Dikembalikan",
    "status_cancelled": "Dibatalkan",
    "status_delivered": "Sampai",
    "status_shipping": "Sedang Dikirim",
    "status_paid": "Lunas",
    "status_pending_payment": "Menunggu Pembayaran",
    "CancellingProcess": "Sedang Dibatalkan",
    "Delete": "Hapus",
    "Unpicked": "Belum Diambil",
    "Picked": "Diambil",
    "ShippingMethodLink": "Atur sekarang",
    "ExportDisabled": "Kedaluwarsa",
    "ExportFailed": "Download Gagal",
    "ExportFinished": "Aktif",
    "ExportINProcess": "Diproses",
    "ExportStatus": "Status",
    "AddTemplate": "Tambah Template",
    "ExportFiledReason": "Alasan Gagal：",
    "FileSaveHistoryDays": "File akan ditampilkan dalam Daftar Riwayat Export, dan hanya akan disimpan selama 15 hari, silakan klik untuk mengunduh",
    "ExportFileFailed": "File gagal disediakan, coba lagi nanti",
    "ExportFileGenerated": "File telah tersedia, silakan klik untuk mengunduh",
    "ExportFileInProcess": "Membuat file, silahkan tunggu",
    "TokoOrderApiUnstable": "Karena Pembaruan Channel Tokopedia, Ginee akan stop sementara sinkronisasi pesanan dari Tokopedia selama 1 jam pada 3 November dari jam 22:00 WIB.",
    "ExportResultTitle": "Hasil",
    "OrderStatus": "Status Pesanan",
    "ExportDownload": "Unduh",
    "ExportAllOrders": "Export Semua",
    "ExportBySelected": "Export yang Dipilih",
    "Export": "Export",
    "OtherInformation": "Informasi Lainnya",
    "AmountInformation": "Informasi Nilai Jumlah",
    "LogisticsInformation": "Informasi Pengiriman",
    "ProductInformation": "Informasi Produk",
    "BuyerInformation": "Informasi Pembeli",
    "ExportSelectedDate": "Pilih Waktu",
    "ExportSelectedTemplate": "Pilih Template",
    "ExportOrder": "Export Pesanan",
    "ExportCreator": "Pembuat",
    "ExportContent": "Konten",
    "ExportTime": "Waktu Export",
    "ExportHistory": "Riwayat Export",
    "TaxationFee": "Tax",
    "CancelNoteText": "Alasan Pembatalan",
    "FinalShippingFee": "Ongkir Dibayar Sistem",
    "PickingListStatus": "Status Picking List",
    "PackingListStatus": "Status Packing List",
    "InvoiceStatus": "Status Invoice",
    "LabelStatus": "Status Label",
    "AfterClickPrint": "Setelah klik Cetak, semua pesanan di daftar ini akan ditandai sebagai Telah Dicetak",
    "SetToPrintedMass": "Atur jadi Telah Dicetak (massal)",
    "SetPrintStatusTips": "Tip: Setelah diatur sebagai Telah Dicetak, maka Anda tidak dapat mengubah Status Cetak, harap diperhatikan.",
    "SetPrintStatus": "Atur Status Cetak",
    "SetStatus": "Atur Status",
    "PickStatus": "Status Picking",
    "PrintStatus": "Status Cetak",
    "DiscountedTotalPrice": "Harga Diskon",
    "InvoiceAlreadyPrinted": "Invoice Telah Dicetak",
    "InvoiceNotPrinted": "Invoice Belum Dicetak",
    "PackingListAlreadyPrinted": "Packing List Telah Dicetak",
    "PackingListNotPrinted": "Packing List Belum Dicetak",
    "PickingListAlreadyPrinted": "Picking List Telah Dicetak",
    "PickingListNotPrinted": "Picking List Belum Dicetak",
    "LabelAlreadyPrinted": "Label Telah Dicetak",
    "LabelNotPrinted": "Label Belum Dicetak",
    "NotPrinted": "Belum Dicetak",
    "AlreadyPrinted": "Telah Dicetak",
    "Rebate": "Potongan Harga",
    "NewBuyerNote": "Catatan Pembeli",
    "OrderTotal": "Total Pesanan",
    "BuyPhone": "Telepon",
    "ProductInfo": "Info Produk",
    "SellerNote": "Catatan Penjual",
    "NoContentYet": "Belum ada konten",
    "OnlyVisibleToPicking": "Hanya terlihat di Picking List",
    "OnlyVisibleToSellers": "Hanya dapat dilihat Penjual",
    "CurrentlyNotSupportedShipping": "Sementara tidak dapat lihat status pengiriman",
    "AWB": "AWB",
    "Voucher": "Voucher",
    "ServiceFee": "Biaya Layanan",
    "CommissionFee": "Biaya Komisi",
    "UnstableLazadaAPI": "Karena API Lazada tidak stabil, sementara ini beberapa pesanan Toko Lazada tidak dapat disinkronkan seluruhnya. Lazada sedang melakukan perbaikan. Jika Anda mengalami masalah ini silakan ke Seller Center untuk proses pesanan.",
    "HideProductInfo": "Sembunyikan info produk",
    "ShippingWeight": "Berat",
    "ShippingSender": "Pengirim",
    "ShippingRecipient": "Penerima",
    "BatchLabelInfo": "Setelah klik Cetak, semua pesanan di daftar ini akan ditandai sebagai Telah Dicetak",
    "PrintBatchLabel": "Cetak Massal Label",
    "ViewAllProduct": "Lihat semua { productCount } produk",
    "ShipBefore": "Kirim Sebelum",
    "PackFailedInfo": "Pengemasan Gagal, Silahkan Refresh atau Sinkronisasi dan coba lagi.",
    "PleaseSelectProduct": "Silahkan Pilih Produk",
    "SkipPrint": "Lewati & Cetak",
    "SomeOrderInvoiceEmpty": "Beberapa Invoice Pesanan kosong, tidak dapat di print, mohon lengkapi",
    "SearchPlaceholder": "Silakan masukkan konten",
    "Unpacked": "Belum Dikemas",
    "PackingStatus": "Status Pengemasan",
    "ReceiverName": "Nama Penerima",
    "BuyerEmail": "Email Pembeli",
    "BuyerContact": "Telepon Pembeli",
    "BuyerName": "Nama Pembeli",
    "TrackIngNumber": "AWB/Nomor Tracking",
    "InvortySKU": "MSKU",
    "ParentSKU": "SKU Induk",
    "ItemName": "Nama Produk",
    "productName": "Nama Produk",
    "OrdersID": "ID Pesanan",
    "Keywords": "Kata Kunci",
    "OrderTypeNormal": "Pesanan Normal",
    "OrderTypePreOrder": "Pre-Order",
    "AllOrderType": "Semua Tipe Pesanan",
    "PaymentPrePaid": "Prabayar",
    "AllPayment": "Semua Pembayaran",
    "LdProductStatusInfo": "Status Proses Produk dalam Pesanan berbeda-beda, silahkan cek Detail Pesanan.",
    "PackingFailedInfo": "Pengemasan Gagal, Silahkan Refresh Laman dan Coba lagi.",
    "BillingAddress": "Alamat Tagihan",
    "InvoiceNumber": "Nomor Faktur",
    "ProductsItemPack": "Semua produk yang dikemas dalam satu Pesanan tidak dapat dikemas ulang",
    "OnlyUnPackagedOrders": "Hanya Pesanan yang belum dikemas",
    "AllProductsPacked": "Semua produk dalam satu pesanan menggunakan Nomor Invoice yang sama",
    "PackMethod": "Metode Pengemasan",
    "PackOrderTips": "Tips: Pesanan Lazada harus dikemas sebelum dikirim, dan Ginee hanya dapat mengemas produk dalam satu pesanan yang sama.",
    "AlreadyPacked": "Telah Dikemas",
    "Pack": "Kemas",
    "PackOrder": "Kemas",
    "LWrongPricePricingError": "Salah Harga",
    "LDuplicateOrder": "Pesanan Double",
    "LCustomerUnreachable": "Customer Tidak Dapat Dihubungi",
    "order_98c90235ea6e3d054f70d2ffdfa9e703": "Produk Habis Terjual",
    "LOutOfDeliveryArea": "Lokasi di luar jangkauan pengiriman",
    "LIncorrectOr": "Alamat Pengiriman Tidak Lengkap",
    "LSystemError": "Gangguan sistem",
    "LSourcingDelay": "Penjual Tidak Dapat Memenuhi Pesanan",
    "NoName": "Tanpa nama",
    "ProductSyncFailedAgain": "Gagal Sinkronisasi Produk, Silahkan Coba Lagi",
    "ShopCancelOrderStockInfo": "Stok Habis — Stok produk yang dipilih akan menjadi 0 di Toko terkait",
    "TokoCancelOrderStockInfo": "Stok Habis — Stok produk yang dipilih akan menjadi 0 di Toko terkait",
    "BLCancelOrderStockInfo": "Saat membatalkan pesanan, stok produk di pesanan tersebut akan menjadi 0 di Toko terkait",
    "AlreadyProcessed": "Diproses",
    "Grab": "Grab [9:00-16:00]",
    "GOSENDInstant": "GO-SEND Instant [6:00-18:00]",
    "GOSENDSameDay": "GO-SEND Same Day [ 6:00-15:00]",
    "AvailableTimeToPickup": "Waktu untuk terima pesanan",
    "ProductUnitPrice": "Harga Satuan",
    "ShopCloseTime": "Waktu tutup toko",
    "ToCancellationInfo": "Penjual tidak dapat menolak permintaan pembatalan pesanan dari pembeli di Tokopedia",
    "ToTrackingNoInfo": "8-17 karakter",
    "trackingNoErrorMessage": "{min}-{max} karakter",
    "BLTrackingNewNoInfo": "8-25 karakter",
    "BLTrackingNoInfo": "8-25 karakter mengandung huruf dan angka",
    "TBuyerRequest": "Permintaan Pembeli",
    "TCourierProblem": "Masalah Kurir",
    "TOthers": "Lainnya",
    "shopClosed": "Toko Tutup",
    "TWrongPriceOrWeight": "Ada kesalahan harga atau berat",
    "TProductVariantUnavailable": "Varian yang dipesan tidak tersedia",
    "TProductOutOfStock": "Stok barang belum diperbarui",
    "TRejectShipping": "Pengiriman Ditolak",
    "BLBusy": "Sibuk",
    "BLOutdatedPrice": "Harga belum terupdate",
    "BLInappropriatePrice": "Harga salah",
    "BLVariantOutOfStock": "Stok varian habis",
    "BlSuppliersOutOfStock": "Stok supplier habis",
    "BLNoStock": "Stok habis",
    "ReceiveInfo": "Anda dapat menerima pesanan dari Tokopedia, \nAkulaku, Bukalapak dan Tiki secara bersamaan, tetapi pesanan Bukalapak dan Tiki tidak dapat dibatalkan setelah diterima",
    "SelectStockEmpty": "Persediaan produk yang dipilih akan dihapus",
    "DeleteKeepOneData": "Harap simpan setidaknya satu data",
    "CourierTracking": "Lacak pengiriman",
    "NoReturnOrder": "Tidak ada permintaan pengembalian untuk diproses",
    "InReturn": "Sedang Dikembalikan",
    "InCancel": "Sedang Dibatalkan",
    "ReturnTips": "Hanya dapat memproses orderan retur satu persatu. Apabila memilih lebih dari satu, maka hanya orderan pertama yang akan diproses",
    "NoCancellationOrder": "Tidak ada Permintaan Pembatalan untuk diproses",
    "PrintDate": "Tanggal cetak",
    "BuyerNote": "Catatan Pembeli",
    "message": "Pesan",
    "seeMore": "Lihat { more } produk lagi",
    "HistoryOrderCompleted": "Pesanan selesai",
    "HistoryOrderCancellation": "Permintaan pembatalan pesanan telah ditolak",
    "HistoryOrderReturned": "Pesanan dikembalikan",
    "HistoryOrderShipping": "Pesanan telah dikirim",
    "HistoryCancelOrder": "Pesanan dibatalkan",
    "HistoryReject": "Penjual menolak pembatalan pesanan",
    "HistoryCreateOrder": "Buat Pesanan",
    "Reject": "Tolak",
    "Confirm": "Konfirmasi",
    "AcceptInfo": "Pesanan ini akan segera dibatalkan, pembayaran oleh pembeli akan dikembalikan. Jangan kirim pesanan ini. Apabila Anda telah terlanjur mengirimkannya, silahkan tolak permintaan pembatalan pesanan.",
    "Images": "Gambar",
    "AcceptReturn": "Setujui Retur",
    "Respond": "Respon",
    "Reason": "Alasan",
    "RequestID": "Request ID",
    "ReasonFromBuyer": "Alasan Pembeli",
    "qtyToShip": "Jumlah untuk dikirim",
    "Qty": "Jumlah",
    "buyer": "Pembeli",
    "orders": "Pesanan",
    "CODNotSupported": "COD tidak tersedia",
    "UndeliverableArea": "Area tidak didukung",
    "CustomerRequest": "Permintaan Pelanggan",
    "OutOfStock": "Stok Habis",
    "History": "Riwayat",
    "NoNext": "Tidak, Lanjutkan",
    "YesNext": "Ya, Lanjutkan",
    "Print": "Cetak",
    "Deleted": "Hapus",
    "Retry": "Coba lagi",
    "AcceptRejectInfo": "Apakah Anda ingin menyetujui permintaan Pembatalan Pesanan?",
    "receiveOrderTip": "Apakah Anda ingin menerima Pesanan?",
    "selectCancelTips": "Apakah Anda ingin membatalkan semua Pesanan dengan alasan yang sama?",
    "AddPickingStatus": "Tambahkan Status",
    "SetPickStatusConfirm": "Konfirmasi",
    "PickApplyToAll": "Terapkan ke Semua",
    "SetPickStatusTitle": "Atur Status Pengambilan",
    "SetPickStatusInMass": "Atur Status Pengambilan secara Massal",
    "PickingSetTip": "Tip: Perlu diketahui bahwa setelah diatur sebagai Diambil, Status tidak dapat diubah lagi.",
    "PrintPicking": "Cetak Picking List",
    "PrintPackingList": "Cetak Packing List",
    "PrintInvoice": "Cetak Invoice",
    "PrintLabel": "Cetak Label",
    "ProcessingCancellation": "Proses Pembatalan",
    "ProcessingReturns": "Processing Returns",
    "ReceiveOrder": "Terima Pesanan",
    "orderSelected": "Orders Selected",
    "shippingfeetip": "Ongkos Kirim Bukalapak akan ditambahkan setelah dikirim",
    "variationdeleted": "Item saat ini dihapus",
    "Sync": "Sinkronkan",
    "tipsWait": "Fitur \"Atur Pengiriman\" akan segera hadir. Sementara silahkan proses pesanan langsung di marketplace anda.",
    "variation": "Variasi",
    "MSKU": "MSKU",
    "SKU": "SKU",
    "Total": "Total",
    "SellerDiscountTotal": "Diskon Penjual",
    "Insurance": "Asuransi",
    "Tax": "Pajak",
    "Shipping": "Biaya Pengiriman",
    "subtotal": "Subtotal",
    "readyToShip": "Siap Dikirim",
    "timeSlots": "Slot Waktu",
    "selectAddress": "Pilih Alamat",
    "address": "Alamat",
    "selectCourier": "Pilih Kurir",
    "logistics": "Logistik",
    "reasonForCancellation": "Alasan Pembatalan",
    "qtyToCancel": "Dumlah Dibatalkan",
    "qtyOrdered": "Jumlah Dipesan",
    "ArrangeShipment": "Atur Pengiriman",
    "updateOrder": "Update",
    "CancelOrder": "Batalkan Pesanan",
    "notes": "Catatan",
    "item": "Barang",
    "shippingAddress": "Alamat Pengiriman",
    "customerEmail": "Email",
    "contactNo": "No. Kontak",
    "promiseToShipBefore": "Dikirimkan Sebelum",
    "AWBTrackingNumber": "AWB / Nomor Tracking",
    "shippingType": "Jenis Pengiriman",
    "paymentType": "Pembayaran",
    "orderType": "Tipe Pesanan",
    "store": "Toko",
    "date": "Tanggal",
    "customerDetails": "Rincian Pelanggan",
    "orderInfo": "Info Pesanan",
    "processingFlow": "Alur Pemrosesan",
    "orderDetailId": "Pesanan { orderId }",
    "updateAt": "Diperbarui pada",
    "createAt": "Dibuat Pada",
    "orderStatus": "Status",
    "customerName": "Pembeli",
    "orderId": "ID Pesanan",
    "amountMax": "Maks Total",
    "amountMin": "Min Total",
    "amount": "Nominal",
    "createDate": "Dibuat Pada",
    "shippingCarrier": "Kurir",
    "channelName": "Channel",
    "keyWord": "Cari ID pesanan, SKU atau Nama Pelanggan",
    "refundableShippingFeeIsCalculatedIn": "Biaya pengiriman dari total yang dapat refund dihitung dalam jumlah yang dapat direfund, bisa difilter untuk melihat.",
    "youDontHaveOperationAuthorityFeature": "Selama masa berlangganan paket, jumlah kumulatif pesanan yang dibuat telah mencapai {x} pesanan. Setelah {y} pesanan terlampaui, fitur pesanan tidak akan bisa digunakan. Silakan berlangganan paket terlebih dahulu atau hubungi customer service untuk meningkatkan paket",
    "understood": "Mengerti",
    "disableDefaultRules": "Nonaktifkan pengaturan default",
    "enableDefaultRules": "Aktifkan pengaturan default",
    "theDefaultRuleIsSuccessfullyDeactivated": "Aturan default berhasil dinonaktifkan",
    "theDefaultRuleIsSuccessfullyActivated": "Aturan default berhasil diaktifkan",
    "ifTheOrderDoesNotMeet": "Jika pesanan tidak memenuhi salah satu aturan yang dibuat, maka tidak akan membuat dokumen outbound untuk mengurangi stok",
    "afterDisableTheOrderWillOnly": "Setelah dinonaktifkan, pesanan hanya akan mengalokasikan gudang sesuai dengan aturan alokasi gudang yang dibuat, dan akan membuat dokumen outbound untuk mengurangi stok",
    "inOrderToEnsureThatThe": "Untuk memastikan bahwa stok Master Produk dapat dikurangi secara normal, ketika aturan alokasi gudang tidak dikonfigurasi, maka tidak akan bisa untuk menonaktifkan aturan default",
    "defaultRuleWhenNoRuleIs": "Aturan default: Ketika tidak ada aturan yang dibuat secara manual, alokasi gudang akan sesuai dengan gudang yang sesuai dengan MSKU yang terdapat di daftar inbound terbaru",
    "noticeWhenTheRuleNotManually": "Pemberitahuan: Jika aturan tidak dibuat secara manual, maka akan menggunakan aturan default atau aturan default tidak akan dinonaktifkan. Setelah membuat aturan secara manual, pesanan tidak akan diproses sesuai dengan aturan default. Jika ada lebih dari satu aturan alokasi di gudang yang sama, maka akan ditetapkan sesuai dengan prioritas aturan",
    "country": "Negara/Wilayah",
    "IntegrateNow": "Integrasikan Sekarang",
    "noconnected": "Anda tidak memiliki Toko yang terintegrasi, silahkan integrasikan Toko",
    "enableTips": "Setelah diaktifkan, aturan ini akan mempengaruhi alokasi gudang pesanan. Harap atur dengan hati-hati",
    "youSureToEnableAre": "Apakah anda yakin ingin mengaktifkan?",
    "setAtLeastOneConditionPlease": "Harap tetapkan setidaknya satu kondisi",
    "deletedSuccessfully": "Berhasil Dihapus",
    "youSureToDeleteAre": "Apakah Anda ingin menghapusnya?",
    "mskuAdd": "Tambahkan MSKU",
    "logisticAdd": "Tambahkan Logistik",
    "storeAdd": "Tambahkan Toko",
    "disabledWarehouseWillNoLongerBe": "Setelah dinonaktifkan, gudang tidak akan dialokasikan menurut aturan ini",
    "youSureYouWantToDisable": "Apakah Anda yakin untuk menonaktifkan aturan ini?",
    "deleteMskuRule": "Aturan XXX menghapus MSKU: #XXX#",
    "addMskuRule": "Aturan XXX menambahkan MSKU: #XXX#",
    "orderAmountIsAdjustedFrom": "Aturan XXX jumlah pesanan disesuaikan dari xx~xx ke XX~XX",
    "deleteLogisticsRule": "Aturan XXX menghapus #XXX# logistik",
    "addLogisticRule": "Aturan XXX menambahkan #XXX# logistik",
    "ruleNameOfRules": "Nama aturan dari aturan XXX diperbarui menjadi #XXX#",
    "deleteStoreRule": "Aturan XXX menghapus #XXX# toko",
    "addStoreRule": "Aturan XXX menambahkan #XXX# toko",
    "priorityOfRuleUpdated": "Prioritas aturan XXXX diperbarui dari X ke X",
    "historyOperation1": "Riwayat Operasi",
    "logisticSelectedX": "{x} Logistik Dipilih",
    "storeSelectedX": "X Toko Dipilih",
    "warehouseAllocation": "Alokasi Gudang",
    "add": "Tambah",
    "amountOrder": "Jumlah Pesanan",
    "nameLogistic": "Nama Logistik",
    "nameStore": "Nama Toko",
    "conditionSelect": "Pilih kondisi",
    "masterSkuDesignated": "Master SKU tujuan",
    "orderAmountRangeSpecify": "Tentukan kisaran jumlah pesanan",
    "logisticDesignated": "Logistik Tujuan",
    "storeDesignated": "Toko Tujuan",
    "conditionOptional": "Kondisi Opsional",
    "setAtLeastOneAllocationCondition": "Harap tetapkan setidaknya satu kondisi alokasi untuk memastikan kelengkapan pengaturan",
    "settingCondition": "Pengaturan Kondisi",
    "informationBasic": "Informasi Dasar",
    "ifTheOrderShippingWarehouseRule": "Tip: Jika aturan gudang pengiriman pesanan belum ditambahkan atau Master SKU tidak ada di gudang yang dikonfigurasi dalam aturan, maka daftar outbond tidak akan dibuat untuk mengurangi stok. Pastikan untuk menetapkan aturan！Jika ada beberapa aturan alokasi di gudang yang sama, maka akan ditetapkan berdasarkan prioritas aturan",
    "ruleAdd": "Tambahkan Aturan",
    "disable": "Nonaktif",
    "enable": "Aktif",
    "edit": "Edit",
    "operate": "Atur",
    "time": "Waktu",
    "conditionAllocation": "Kondisi Alokasi",
    "nameRule": "Nama Aturan",
    "priority": "Prioritas",
    "upTheSearch": "Paling banyak pencarian tunggal{X}",
    "moreMax": "Maksimum { maxLength }",
    "separateMultipleInfoWithCommasOr": "Pisahkan beberapa informasi dengan koma atau spasi",
    "addSearchType": "Tambah jenis pencarian",
    "advancedSearch": "Pencarian Lanjutan",
    "mskuIsNotEqualTo": "MSKU tidak sama dengan",
    "skuIsNotEqualTo": "SKU tidak sama dengan",
    "mskuIsEqualTo": "MSKU sama dengan",
    "skuIsEquals": "SKU sama dengan",
    "shopeebukalapakOrdersUseGineeTemplateFor": "Pesanan Shopee/Bukalapak menggunakan template Ginee untuk mencetak label pengiriman (Tokopedia saat ini hanya mendukung template Ginee)",
    "selectPrinter": "Pilih printer",
    "printCompleted1": "Cetak selesai",
    "printing2": "Sedang mencetak",
    "checkTheDetails": "Lihat Detail",
    "action1": "Tindakan",
    "printQuantity": "Jumlah Cetak",
    "printStatus": "Status Cetak",
    "printingAdmin": "Operator Cetak",
    "highspeedBatchPrintingHistory": "Histori pencetakan massal Cetak Cepat",
    "highspeedPrintingLabelHistory": "Histori pencetakan Cetak Cepat",
    "printAgain": "Cetak Ulang",
    "failureReason": "Alasan kegagalan",
    "shipmentNumber": "Nomor Tracking",
    "order": "Pesanan",
    "printFailed": "Cetak gagal",
    "printSuccessfully": "Cetak berhasil",
    "printing1": "Sedang mencetak",
    "cautionToEnsureNormalPrintingOperation": "Perhatian: Untuk memastikan operasi pencetakan berjalan normal, jangan menutup/melakukan refresh halaman untuk saat ini, jika tidak, operasi pencetakan yang sedang berjalan akan dihentikan atau sisi yang sama akan dicetak berulang kali!",
    "IfPrintingFailsPleaseMove": "3. Jika pencetakan gagal, silakan pindah ke daftar gagal cetak untuk memeriksa alasannya",
    "DuringPrintingIfSomeOrders": "2. Selama pencetakan, jika beberapa pesanan gagal dicetak, itu tidak akan mempengaruhi proses pencetakan secara keseluruhan",
    "AfterThePrintingIsCompleted": "1. Setelah pencetakan selesai, pesanan akan secara otomatis ditandai sebagai telah dicetak. Jika Anda perlu mengubahnya, silakan buka daftar pesanan untuk memodifikasinya",
    "printingInstructions": "Instruksi pencetakan",
    "totalXxxAndXxPrinted": "Total { total } , dan { x }  telah dicetak",
    "printCompleted": "Pencetakan selesai",
    "printing": "Sedang mencetak",
    "printingProgress": "Kemajuan pencetakan:",
    "highSpeedPrintingTaskCenter": "Pusat tugas pencetakan Cetak Cepat",
    "recheck": "Periksa kembali",
    "ifYouConfirmThatThePlugin": "Jika Anda telah mengonfirmasi bahwa plug-in telah diinstal dan diaktifkan, silakan klik tombol deteksi ulang untuk mencetak",
    "notInstalledClickHereToDownload": "Belum terpasang. Kklik di sini untuk mengunduh plugin",
    "installed": "Terpasang",
    "detectedWeFoundThatYouHave": "Telah terdeteksi. Kami menemukan bahwa Anda belum menginstal atau mengaktifkan plug-in Cetak Cepat, silakan coba metode berikut:",
    "tips": "Tips",
    "inOrderToImproveThePrinting": "Untuk meningkatkan kecepatan dan hasil pencetakan, disarankan untuk menggunakan fitur Cetak Cepat,",
    "startPrinting": "Mulai mencetak",
    "printer": "Printer",
    "noteToEnableHighspeedPrintingThe": "Catatan: Untuk mengaktifkan Cetak Cepat, Anda harus mengunduh dan mengaktifkan plug-in Cetak Cepat",
    "highspeedPrinting": "Cetak cepat (jumlah cetakan tunggal yang tidak terbatas, tetapi tidak dapat melihat pratinjau cetak)",
    "regularPrintingPrintUpTo": "Cetak reguler (mencetak hingga 100 pesanan dalam satu waktu, menampilkan label PDF untuk semua pesanan, dan akan memakan waktu lebih lama)",
    "printingMethod": "Metode Pencetakan",
    "haveBeenPrinted": "dan { x }  diantaranya telah dicetak",
    "dataHaveBeenSelectedAnd": "{ x }  data telah dipilih,",
    "printData": "Data cetak",
    "customLabelOnlySupports": "Label khusus (Hanya untuk pesanan Shopee/Tokopedia/Bukalapak)",
    "channelLabelAllChannelOrdersSupport": "Label pengiriman channel (Semua pesanan saluran mendukung pencetakan kecuali untuk pesanan Shopify/Woocommerce)",
    "setUpPrintTemplates": "Atur template cetak",
    "printShippingLabelAll": "Cetak Label Pengiriman (Semua)",
    "printShippingLabelSelected": "Cetak Label Pengiriman (Yang Dipilih)",
    "selectAllOrders": "Pilih semua pesanan",
    "pleaseConfirmWhetherToTurnOff": "Mohon konfirmasi apakah akan mematikan proses pemotongan stok-pesanan?",
    "putOnHold": "Sisihkan",
    "pickingNote": "Catatan Pengambilan",
    "dropshipper": "Dropshipper",
    "storeName": "Nama Toko",
    "setReadyToShip": "Atur Siap Dikirim",
    "becauseYouHaveNotIntegrateThe": "Karena Anda belum mengintegrasikan toko, Anda tidak dapat menggunakan fungsi salin produk, harap selesaikan integrasi terlebih dahulu",
    "integrateStore": "Integrasi Toko",
    "integrationGuide": "Panduan Integrasi",
    "MinutesToLearnAboutGinee": "1 menit untuk belajar tentang Ginee",
    "shortcuts": "Shortcuts",
    "theStoreHasNotBeenAuthorised": "Belum mengintegrasikan toko, tidak dapat mengelola produk dan pesanan online",
    "inventoryManagement": "Manajemen Stok",
    "productManagement": "Manajemen Produk",
    "scrapeProduct": "Scrape Produk",
    "masterProductSales": "Penjualan Master Produk",
    "try": "Coba Sekarang",
    "productActivitySetting": "Pengaturan Aktifitas Produk",
    "capitalOccupationRatio": "Proporsi dana yang digunakan",
    "stockTakesUpFunds": "Dana yang digunakan untuk stok",
    "numberOfMskus": "Jumlah MSKU",
    "exportMsku": "Ekspor MSKU",
    "purchaseDuration": "Durasi Pembelian",
    "doesItNeedToBeReplenished": "Apakah perlu direstock?",
    "availableDaysDays": "Hari yang tersedia (hari)",
    "averageDailySales": "Rata-rata penjualan harian",
    "lastMonth": "1 bulan terakhir",
    "lastTwoWeeks": "2 minggu terakhir",
    "lastWeeks": "7 hari terakhir",
    "lastThreeDays": "3 hari terakhir",
    "numberOfRatings": "Jumlah Penilaian",
    "ratings": "Penilaian",
    "likes": "Jumlah Like",
    "pageViews": "Jumlah Kunjungan",
    "productActivityHasBeenSetFor": "Aktivitas produk telah diatur untuk kategori ini, silahkan atur ulang",
    "sales": "Penjualan",
    "effectiveSales": "Penjualan Efektif",
    "newItem": "Item Baru",
    "lowSellingItem": "Item Tidak Laku",
    "normalItem": "Item Biasa",
    "trendingItem": "Item Trending",
    "bestSellingItem": "Item Terlaris",
    "productActivity": "Aktivitas Produk",
    "accounting": "Manajemen Akuntansi",
    "categoryRange": "Rentang Kategori",
    "timeRange": "Rentang Waktu",
    "productDimension": "Product-level",
    "salesType": "Tipe Penjualan",
    "salesRange": "Jangkauan Penjualan",
    "allCategory": "Semua Kategori",
    "recent": "Terdekat",
    "oneDay": "Satu Hari",
    "threeDay": "Tiga Hari",
    "aWeek": "Satu Minggu",
    "twoWeeks": "Dua Minggu",
    "aMonth": "Satu Bulan",
    "threeMonths": "Tiga Bulan",
    "halfYear": "Setengah Tahun",
    "onlySupportForIndonesia": "Hanya mendukung Indonesia",
    "addProductActivity": "Tambah Aktivitas Produk",
    "cSupportToManuallyPullAccurate": "c. Mendukung pengaktifan sinkronisasi perubahan stok Accurate ke Ginee, dan memperbarui stok gudang Ginee terikat (dinonaktifkan secara default, harap aktifkan dengan hati-hati)",
    "bAfterTheOrderIsPlaced": "b. Master Produk Ginee yang outbound dari gudang akan mengurangi stok Accurate secara sinkron",
    "aInboundStockForMasterProduct": "a. Melakukan Inbound stok Master Produk di Ginee akan sinkron dengan stok di Accurate",
    "StockSync": "Sinkronisasi Stok",
    "noteOrderInformationSynchronizationIsA": "Catatan: Sinkronisasi faktur penjualan adalah sinkronisasi satu arah yang didorong secara otomatis dari Ginee ke Accurate setiap hari",
    "createdTheWarehousePairingRelationship": "Setelah toko berhasil dipetakan dengan Accurate, faktur penjualan akan otomatis dibuat ketika pesanan Ginee dikirim keluar gudang dan didorong ke Accurate.",
    "OrderDistribution": "4. Distribusi Pesanan",
    "noteTheSynchronizationOfMasterProduct": "Catatan: Sinkronisasi informasi master produk adalah sinkronisasi satu arah dari Ginee ke Accurate, setelah membuat master produk baru akan langsung sinkronisasi",
    "afterCreatedTheWarehousePairingRelationship": "Setelah pembuatan hubungan antar gudang, maka akan mendukung untuk melakukan sinkronisasi Master Produk gudang Ginee ke gudang Accurate yang sesuai",
    "ProductPlacement": "3. Penempatan Produk",
    "psItIsNotRecommendedTo": "PS: Tidak disarankan untuk mengikat gudang fulfilment pihak ketiga ke gudang Accurate, akan ada risiko masalah stok",
    "afterTheAuthorizationIsCompletedYou": "Setelah otorisasi selesai, Anda dapat mengelola pemetaan gudang dan toko di [Pengaturan - Pengaturan Keuangan], dan mengatur aturan sinkronisasi. Sinkronisasi produk/stok/faktur penjualan hanya dapat dilakukan secara normal setelah pemetaan",
    "BasicConfiguration": "2. Konfigurasi Dasar",
    "pleaseMakeSureToFillIn": "Pastikan untuk mengisi akun/kata sandi Accurate dengan benar, dan pilih database untuk menyelesaikan otorisasi",
    "Authorization": "1. Otorisasi",
    "accurateOperationGuide": "Panduan Operasi Accurate",
    "averageDailySalesSettings": "Pengaturan Penjualan Harian Rata-rata",
    "averageSalesForTheDayAs": "Rata-rata penjualan untuk hari itu sebagai penjualan harian",
    "clickAuthorizeYouWillBeRedirected": "Klik [Otorisasi], Anda akan diarahkan ke halaman Accurate, pastikan untuk mengisi akun/kata sandi Accurate dengan benar dan menyelesaikan otorisasi setelah login",
    "onSaleDaysDays": "Jumlah Hari Penjualan (Hari)",
    "purchaseTimedays": "Waktu Pembelian (Hari)",
    "AfterTheAuthorizationIsCompleted": "2. Setelah otorisasi selesai, selesaikan pemasangan gudang di manajemen konfigurasi Accurate;",
    "PleaseSelectAtLeast": "1.Silakan pilih setidaknya 1 Database untuk menyelesaikan otorisasi. Jika tidak ada Database, silakan buka Accurate untuk menambahkannya",
    "remainder": "Tips",
    "authorizationSucceeded": "Integrasi Berhasil",
    "theAccurateWarehouseInformationIsBeing": "Gudang dan akuntansi Accurate sedang disinkronkan untuk Anda, silakan lanjutkan ke pengaturan pemetaan inisialisasi keuangan!",
    "continueAuthorization": "Lanjutkan Otorisasi",
    "reauthorize": "Otorisasi Ulang",
    "channelFailureReason": "Alasan gagal channel:",
    "authorizationFailed": "Integrasi Gagal",
    "deleteAuthorization": "Hapus Otorisasi",
    "authorizer": "Pemberi Otorisasi",
    "authorizationTime": "Waktu Otorisasi",
    "connected": "Terhubung",
    "authorizationStatus": "Status Otorisasi",
    "ServiceProvider": "Penyedia Layanan",
    "accountName": "Nama Akun",
    "deleteFailed": "Gagal Menghapus",
    "theBindingRelationshipBetweenTheAccurate": "Hubungan pengikatan antara gudang Accurate dan gudang Ginee telah dilepaskan, dan sinkronisasi informasi produk/pesanan/stok dengan ACCURATE tidak lagi dilakukan",
    "onceDeletedTheBindingRelationshipBetween": "Setelah dihapus, hubungan pengikatan antara gudang Ginee dan gudang Accurate akan dilepaskan, dan informasi produk/pesanan/persediaan tidak akan lagi disinkronkan dengan ACCURATE",
    "areYouSureWantToDelete": "Yakin ingin menghapus?",
    "productSuccessfullySynchronized": "Sinkronisasi produk berhasil",
    "whenDistributeGineeWarehouseProductGinees": "Saat mendistribusikan produk gudang Ginee, stok gudang MSKU Ginee akan langsung digunakan untuk menutupi stok gudang Accurate, harap operasikan dengan hati-hati",
    "areYouSureToSyncGinee": "Apakah Anda yakin untuk menyinkronkan produk gudang Ginee ke gudang Accurate?",
    "channelReturnFailureReason": "Alasan Kegagalan Channel:",
    "pullFailed": "Penarikan gagal",
    "pullSuccessfully": "Penarikan berhasil",
    "pull": "Tarik",
    "afterManuallyPullingTheWarehouseInformation": "Setelah menarik informasi gudang secara manual, pastikan untuk mengikat gudang yang baru ditambahkan. Harap pastikan untuk mengetahui bahwa gudang yang dinonaktifkan akan dihapus secara langsung dan hubungan pengikatan akan dihapus, serta tidak akan dilakukan lagi sinkronisasi data.",
    "pullWarehouse": "Tarik Gudang",
    "warehouseStockSuccessfullyUpdated": "Stok gudang berhasil diperbarui",
    "onceTheStockOfTheThirdparty": "Jika stok gudang pihak ketiga tidak sesuai dengan sistem, operasi ini akan langsung memperbarui stok gudang produk yang ada ke nilai stok gudang pihak ketiga, dan menghasilkan catatan penyesuaian. Informasi spesifik dapat dilihat di catatan penarikan.",
    "areYouSureToPullStock": "Apakah Anda yakin akan menarik stok dari Gudang Accurate?",
    "theWarehouseBindingRelationshipHasBeen": "Hubungan ikatan gudang telah berhasil dilepas",
    "afterTheUnbindingIsSuccessfulThe": "Setelah pelepasan berhasil, informasi produk/pesanan yang diterima di gudang Accurate tidak akan terpengaruh, dan data yang dihasilkan di gudang Ginee terikat sebelumnya tidak akan disinkronkan ke Accurate",
    "areYouSureToUnbindThe": "Apakah Anda yakin untuk melepaskan ikatan gudang saat ini?",
    "reasonTheCurrentWarehouseHasBeen": "Alasan: Gudang saat ini telah terikat ke gudang Akurat lainnya, harap lepas ikatan dan coba lagi",
    "bindingFailed": "Pengikatan Gagal",
    "bindingIsSuccessfulPleaseDoNot": "Pengikatan berhasil, tolong jangan ulangi operasi ini",
    "bind": "Ikatkan",
    "tipOnlySupportsSelectingWarehouseThat": "Tip: Hanya mendukung untuk memilih gudang yang diaktifkan di daftar gudang Ginee",
    "syncWarehouse": "Sinkronisasi Gudang",
    "unbindWarehouse": "Lepas Ikatan Gudang",
    "bindTime": "Waktu Pengikatan",
    "accurateWarehouse": "Gudang Accurate",
    "accurateWarehouseInformationWillNotBe": "Informasi gudang Accurate tidak akan disinkronkan ke daftar gudang Ginee, dan informasi master produk/pesanan/stok akan disinkronkan ke gudang yang telah memiliki ikatan hubungan \ndengan gudang Ginee",
    "accurateSettingManagement": "Manajemen Konfigurasi Accurate",
    "synchronizeWarehouseInformationUnderAccurateDatebase": "Sinkronisasi informasi gudang di bawah Accurate Database *, dan ditambahkan * gudang, hapus * gudang",
    "syncGineeWarehouseProductTo": "Sinkronisasi stok gudang Ginee * ke Accurate",
    "syncStockFromAccurateWarehouse": "Sinkronisasi stok dari gudang Accurate * ke gudang Ginee *",
    "accurateWarehouseUnbindFrom": "Melepas ikatan gudang Accurate * dengan gudang *",
    "accurateWarehouseBindTo": "Gudang Accurate * mengikat ke * gudang",
    "operationLogIsOnlyRetainedFor": "Log operasi hanya disimpan selama 15 hari terakhir, dan informasi setelah lebih dari 15 hari tidak dapat dilihat",
    "send": "Kirim",
    "translate": "Terjemahkan",
    "failedToSendMessage": "Gagal mengirim pesan",
    "resend": "Kirim ulang",
    "sendToBuyer": "Kirim ke pembeli",
    "translationFailed": "Gagal menerjemahkan",
    "translatedx": "Selesai menerjemahkan:{x}",
    "translationInProgress": "Sedang menerjemahkan",
    "automaticTranslationHasBeenTurnedOn": "Terjemahan Otomatis sudah diaktifkan",
    "autoTranslate": "Terjemahan Otomatis",
    "buyersLanguage": "Bahasa Pembeli",
    "afterCloseItThereWill": "Setelah ditutup, tidak akan ada notifikasi suara ketika ada pesan baru dari pembeli",
    "notificationSound": "Suara Notifikasi",
    "uploadedNowlengthSheets": "Lembar {nowLength} yang diunggah",
    "isAUniqueIdentifierForThe": "adalah pengidentifikasi unik untuk produk utama dan tidak dapat diubah setelah diidentifikasi",
    "pleaseConfirmWhetherTheStoresChannel": "Silakan periksa apakah ada nilai untuk SKU saluran di toko, jika tidak kami akan mengambil judul item saluran sebagai MSKU untuk item utama",
    "errnameToko": "Nama produk harus diantara 1-70 karakter",
    "sizeSettingTemplate": "Pengaturan ukuran template",
    "mappingCategory": "Pemetaan kategori",
    "templateSettingLogistic": "Pengaturan template logistik",
    "rangeEnable": "Pengaturan Produk",
    "globalsMobileErrorInfoLength": "Nomor HP harus Berjumlah {min}-{max} Angka",
    "globalsMobileErrorInfo": "Silahkan masukkan Nomor HP yang valid",
    "masterSku": "Master SKU",
    "outboundPurchasePrice": "Harga Beli Satuan",
    "hasbinded": "Varian ini telah diikat ke Stok SKU: {sku} Harap perbarui stok di \"Manajemen Stok\"",
    "deletecustomersuccess": "Berhasil dihapus!",
    "reasonforpushedfailed": "Alasan Gagal: Produk telah dihapus atau dilarang di Toko, atau sedang dalam masa promosi (Stok akan diperbarui setelah promosi berakhir). Silakan cek Produk",
    "purchasePricerequired": "Harus diantara 0 dan 1.000.000.000!",
    "createsuccess": "Berhasil Dibuat",
    "atmost20tags": "Maks.20 Tag",
    "AddTag": "Tambahkan Tag",
    "tagexist": "Tag sudah ada",
    "fenotempty": "Depan dan belakang Tag tidak boleh gunakan Spasi",
    "taginputlimit": "Tag harus diantara 3-40 karakter",
    "selecttag": "Pilih Tag",
    "withordernostock": "Ada Pesanan, Stok Kosong",
    "withisku": "Ada Stok SKU",
    "noisku": "Tidak ada Stok SKU",
    "withskuwithoutisku": "Ada SKU tapi tidak ada Stok SKU",
    "nosku": "Tidak ada SKU",
    "selectskusit": "Pilih filter SKU",
    "Pleaseenter": "Silakan Masukkan",
    "selectsku": "Pilih Stok SKU",
    "emailreminder": "Kirim Email Pengingat",
    "safetystockarea": "Stok Tersedia <=",
    "bindedisku": "Stok SKU Terikat:",
    "most20bundles": "Maks.20",
    "NAME": "Nama",
    "PleaseEnter": "Silakan Masukkan",
    "disablebundletip": "Untuk memastikan akurasi pengurangan Stok, Stok SKU dan jumlah tidak dapat diubah ketika edit Bundle ISKU",
    "updatesuccess": "Berhasil Diperbarui",
    "ISKUtype": "Tipe ISKU",
    "bundleisku": "Bundle ISKU",
    "isku": "MSKU",
    "ProductNameISKU": "Nama Produk & Stok SKU",
    "Image": "Gambar",
    "bundledproduct": "Produk di Bundle",
    "selectbundle": "Pilih Stok SKU",
    "AddBundleISKU": "Tambah Bundle ISKU",
    "bukadisabled": "Waktu proses pesanan mengikuti pengaturan di lapak",
    "pleasecheckpreorderpart": "Silakan periksa informasi pemesanan di muka",
    "shopeedisabled": "Saya akan mengirimkan pesanan dalam 2 hari kerja (tidak termasuk hari libur dan jadwal libur logistik)",
    "tokodisabled": "Aktifkan pesanan preorder apabila membutuhkan waktu proses yang lebih lama",
    "UNKNOWNunittip": "Unit waktu di Channel tidak tersedia, Silahkan hubungi Ginee",
    "day": "Hari",
    "week": "Minggu",
    "bukreorderdayworkday": "hari kerja untuk melakukan pengiriman",
    "bukreorderday": "hari untuk melakukan pengiriman",
    "bukainstanhour": "jam kerja",
    "bukaregular": "Saya akan mengirimkan pesanan dalam 3 hari kerja (tidak termasuk hari libur dan jadwal libur logistik)",
    "bukreorder": "Saya butuh",
    "bukainstan": "Saya akan mengirimkan pesanan dalam",
    "tokopreorderday": "hari untuk melakukan pengiriman",
    "tokopreorderweek": "minggu untuk melakukan pengiriman",
    "tokopreorder": "Saya butuh",
    "shopeepreorderday": "hari kerja untuk melakukan pengiriman",
    "shopeepreorder": "Saya butuh",
    "max8pictures": "Unggah sampai 8 gambar",
    "max5pictures": "Unggah sampai 5 gambar",
    "uploadtip": "Unggah Foto Produk berdasarkan batasan Channel: Tokopedia, Bukalapak, Shopify, TikTok Shop, Blibli mengambil 5 foto, Lazada 8 foto, dan Shopee 9 foto pertama",
    "regular": "Reguler",
    "instan": "Instan",
    "between18hours": "Harus diantara 1-8",
    "between330days": "Antara 3-30",
    "max13weeks": "Antara 1-13 minggu",
    "shopeepreorderdayAdd": "Star, Star+, Mall mendukung hingga 90 hari kerja",
    "between790": "Antara 7-90 hari kerja",
    "max90days": "Antara 1-90 Hari",
    "preorderdeadline": "Batas Waktu Proses Pesanan",
    "preorder": "Preorder",
    "novariationforlazd": "Tidak dapat tambah foto karena Produk ini tidak memiliki varian",
    "SafetystockHead": "Tetapkan batas stok dan terima pemberitahuan email untuk restok ketika Stok Tersedia produk mencapai batas",
    "SpareStockkHead": "Jumlah Total Stok yang dicadangkan di gudang, yang tidak akan dijual di Channel Toko.",
    "WarehouseStockHead": "Total Stok Aktual di Gudang Anda , Stok Gudang = Stok Cadangan + Stok Terkunci + Stok Tersedia + Stok Promosi",
    "inventorySkuHead": "50 karakter pertama dari SKU Toko dimapping secara otomatis",
    "NameHead": "Nama Stok SKU diambil secara otomatis dari 160 karakter pertama [nama-spesifikasi nama produk]",
    "BrandLogoTips": "Saran ukuran: 360 * 140, format jpg/png/jpeg maksimal 2M",
    "MobileMustErrorInfo": "Harus diawali dengan angka 8, berisi 9-12 Angka",
    "bindnow": "Ikat sekarang",
    "Nospacesfront": "Kepala tidak boleh berisi spasi, silakan cek",
    "spaceforbidden": "Tidak boleh ada spasi, silakan cek",
    "range": "Batasan: Aktif, Nonaktifkan, Habis, Dilarang",
    "inventorySkunotempty": "Stok SKU wajib diisi!",
    "limitsafety": "Stok Pengaman harus antara 0 ~ 999,999!",
    "mustlessthanwarehouse": "Stok Cadangan tidak boleh lebih besar dari Stok Gudang",
    "sparelimit": "Stok Cadangan harus antara 0 dan 999,999!",
    "mustbiggerthanspare": "Stok Cadangan tidak boleh lebih besar dari Stok Gudang",
    "warehouselimit": "Stok Gudang harus antara 0 dan 999,999!",
    "masterSkuMost200": "Master SKU harus dibawah 200 karakter",
    "masterSkuMost50": "Master SKU harus dibawah 50 karakter",
    "most50": "Stok SKU harus dibawah 50 karakter",
    "most160": "Nama harus dibawah 300 karakter",
    "clicktoedit": "Klik untuk edit",
    "alreadyexist": "Stok SKU sudah ada, SKU varian akan terikat ke Stok SKU",
    "generaterules": "Aturan Generate Stok SKU secara default: 1. Stok SKU akan mengambil 50 karakter pertama dari SKU secara default. 2. Stok Gudang akan sama dengan Stok Varian secara default, dan Stok Pengaman akan kosong secara default. 3. Saat Stok SKU dibuat, SKU dari varian akan terikat.",
    "channel": "Channel",
    "Operationguide": "Panduan Operasi",
    "storeexplain": "Generate Stok SKU produk di satu Toko untuk mengelola Stok di berbagai Toko secara terpadu",
    "bystoremodal": "Generate Stok SKU berdasarkan Stok",
    "ImportByStore": "Import per Toko",
    "Gotit": "Mengerti",
    "Safetystock": "Stok Pengaman",
    "SpareStock": "Stok Cadangan",
    "WarehouseStock": "Stok Gudang",
    "inventorySku": "MSKU",
    "Name": "Nama",
    "image": "Gambar",
    "GenerationRules": "Aturan Generate",
    "ClickContenttoEdit": "Klik Konten untuk edit",
    "generatecount": "{productSum} produk ({availableNum} dari {variationSum} Varian dapat generate Master Produk). Varian yang terikat dengan Master SKU atau SKU kosong tidak dapat generate Master SKU",
    "generateisku": "Generate Stok SKU",
    "draftorfialed": "Harap pilih hanya draf atau Produk yang gagal ditampilkan",
    "uploadlimit": "Batasan File: format xlsx, xls ukuran 5MB. Import hingga 1000 data sekali jalan.",
    "canbedownloaded": "Produk",
    "selectedstatus": "Produk dipilih di Status ini: Produk",
    "donwloadtemp": "Unduh template impor",
    "DownloadFailedData": "Unduh Data yang Gagal",
    "importfail": "Beberapa Data Gagal Diimpor",
    "importfinish": "Data Berhasil Diimpor",
    "Cancel": "Batalkan",
    "fileerror": "Format file impor tidak sesuai",
    "close": "Tutup",
    "importing": "Importing",
    "importlabel": "Import File",
    "upload": "Unggah",
    "download": "Unduh",
    "templatetip": "Unduh Template",
    "import": "Import",
    "themaxtip2": "data per unggahan. Pelanggan dengan no. telepon yang sama akan digabungkan",
    "themaxtip1": "Tip: Batas maksimum adalah",
    "ActionsSuccessInfo": "Operasi Berhasil!",
    "ShopeeTips": "Klik \"Mulai Sinkronisasi\" dan Anda akan diarahkan ke laman Shopee untuk login dan otorisasi.",
    "AlreadyChecked": "{selected} Dipilih",
    "SubscriptionExtended": "Langganan dapat diperpanjang, silakan klik \"Berlangganan Sekarang\" atau hubungi kami untuk meningkatkan akun Anda",
    "SubscribeSuccess": "Jika Anda berhasil berlangganan, Anda dapat mengaktifkan fitur lanjutan. Silakan klik \"Berlangganan Sekarang\" atau hubungi kami untuk melanjutkan Langganan Anda",
    "SubscriptionExpired": "Maaf, paket langganan Anda telah kedaluwarsa, silakan klik \"Berlangganan Sekarang\" atau hubungi kami untuk melanjutkan langganan Anda",
    "PayNow": "Bayar Sekarang",
    "ThisFeatureIsOnlyPaidUsers": "Fitur ini hanya untuk Pengguna Berbayar, Harap Bayar untuk Menggunakan Fitur Ini.",
    "FoundANewGreatFeature": "Ada Fitur Baru!",
    "urlplaceholder": "Silakan masukkan Nama Toko",
    "urltip": "Nama harus berjumlah 2-30 Karakter Hanya huruf kecil dan angka angka yang diperbolehkan",
    "urldesciption": "Harap buat Link Member Toko",
    "memberurlmodaltitle": "Link Halaman Member",
    "CategoryLevel3": "Kategori Level 3",
    "CategoryLevel2": "Kategori Level 2",
    "RichTextDescription": "Tuliskan deskripsi di sini dan terapkan pada produk",
    "PleaseInput": "Mohon Masukkan",
    "selectall": "Pilih Semua",
    "noauth": "Akun Anda tidak memiliki izin ini, silakan hubungi administrator Toko untuk bertanya atau upgrade",
    "NoProductYet": "Belum ada produk",
    "SeeMore": "Lihat { more } produk lagi",
    "SeeMoreExpand": "lihat lebih banyak",
    "SaveAndNext": "Simpan & Lanjutkan",
    "NoHaveAccount": "Belum punya akun?",
    "Requiredfields": "Kolom ini wajib diisi!",
    "Somerequiredfields": "Beberapa bidang wajib diisi, harap isi",
    "Removetheseproducts": "Hapus produk ini",
    "GotoShippingSettings": "Pergi ke Pengaturan Jasa Pengiriman",
    "Somewithoutshipping": "Beberapa produk tidak dapat disimpan karena tidak ada jasa pengiriman.Silakan buka Pengaturan Jasa Pengiriman sebelum edit produk.",
    "Discardcontent": "Membatalkan perubahan disini maka informasi produk akan dikembalikan sama dengan yang asli di toko",
    "Discardtip": "Yakin ingin membatalkan perubahan ini?",
    "with": "Dengan",
    "Changepriceto": "Edit harga jadi",
    "Reducebypercent": "Kurangi berdasarkan presentase",
    "Reducebyamount": "Kurangi berdasarkan jumlah",
    "Addbypercent": "Tambah berdasarkan presentase",
    "Addbyamount": "Tambah berdasarkan jumlah",
    "Basedoncurrentprice": "Berdasar harga sekarang",
    "replace": "Ganti",
    "addaftername": "Tambahkan setelah nama",
    "addbeforename": "Tambahkan sebelum nama",
    "Changestockto": "Ganti stok jadi",
    "Example": "Contoh:",
    "errsize": "Kolom ini wajib diisi!",
    "BukalapakPrice": "Harga harus berada diantara 1-1.000.000.000",
    "phLazadaPrice": "Harga harus berada diantara 1-1.000.000.000",
    "LazadaPrice": "Harga harus berada diantara 1.000-1.000.000.000",
    "TokopediaPrice": "Harga harus berada diantara 1.00-1.00.000.000",
    "phShopeePrice": "Harga harus berada diantara 2-1.000.000.000",
    "ShopeePrice": "Harga harus berada diantara 99-1.000.000.000",
    "pricetipBukalapak": "Harga harus berada diantara 1 ～1.000.000.000",
    "pricetipShopee": "Harga harus berada diantara 99 ～1.000.000.000",
    "errdescriptionBukalapak": "Deskripsi harus dalam  30 - 3000 karakter",
    "errdescriptionShopee": "Deskripsi harus dalam  30 - 3000 karakter",
    "errnameBukalapak": "Nama Produk harus diantara 6-150 Karakter",
    "errnamelazada": "Nama Produk harus diantara 2-255 Karakter",
    "allemptyorfilled": "Ukuran Lebar Panjang Tinggi harus diisi semua atau semua dikosongkan",
    "errnameShopee": "Nama Produk harus diantara 5-100 Karakter",
    "spaceskuerr": "SKU tidak boleh ada spasi, silakan cek",
    "PleaseOneShipping": "Aktifkan setidaknya satu jasa kirim untuk produk Anda",
    "pricetip": "Harga harus di antara 99 - 1.000.000.000",
    "stocktip": "Stok harus di antara 0-999999",
    "Discardchanges": "Membuang perubahan",
    "update": "Perbarui",
    "draft": "Simpan sebagai Draf",
    "publish": "Tampilkan",
    "Logistic": "Logistik",
    "ParcelSize": "Ukuran Paket",
    "Width": "Lebar",
    "Length": "Panjang",
    "Height": "Tinggi",
    "Weight": "Berat",
    "price": "Harga",
    "VariantName": "Nama Varian",
    "parentSku": "SKU Induk",
    "productImg": "Gambar",
    "Refresh": "Refresh",
    "PlatformUpdateNotification": "Ginee Notifikasi Update",
    "WaitAMoment": "Nanti Saja",
    "GoAuth": "Integrasikan",
    "NewVersionsInfo": "Shopee Channel Management 2.0 sudah bisa diakses! Yuk Integrasikan Toko Shopee Anda dan rasakan sinkronisasi data yang lebih stabil dan fitur untuk memproses pesanan Anda!",
    "NoDataInfo": "Tidak ada data untuk diproses",
    "ActionsErrorInfo": "Operasi gagal, silakan coba lagi nanti",
    "and": "dan",
    "PrivacyPolicy": "Kebijakan Privasi",
    "TermsConditions": "Ketentuan Penggunaan",
    "agree": "Dengan mendaftar, Saya setuju dengan",
    "BLDescription": "Deskripsi harus diantara 30-3000 karakter",
    "markDownImageSize": "ukurannya harus minimal 300 x 300 piksel, kurang dari 5M, format dalam format JPG, PNG",
    "markDownAddImage": "Tambah Gambar",
    "noProductStore": "Anda belum memiliki Toko yang terhubung, hubungkan Toko anda sekarang dan tambah produk）",
    "noStoreInfo": "Hubungkan toko online anda dan sinkronisasikan produk, stok, dan pesanan secara langsung",
    "noMemberInfo": "Setelah customer daftar menjadi member, anda bisa mengatur informasi dan poin mereka disini. Anda juga bisa menambahkan member secara manual",
    "noProductInfo": "Anda belum memiliki produk. Tambahkan produk pertama anda sekarang",
    "TextDescription": "Deskripsi",
    "memberInfo": "Informasi Member",
    "jdDisableTips": "Tidak bisa integrasi",
    "Coming": "Akan Datang",
    "editPointRuleInfo": "Apakah kamu yakin mau merubah Peraturan Poin?",
    "previewImg": "Pratinjau",
    "addRule": "Tambahkan Aturan",
    "methodTypeAll": "Semua",
    "sendSuccess": "Berhasil dikirim",
    "editPointsRule": "Edit Peraturan Poin",
    "pointeReduceErrorInfo": "Tidak semua pengguna yang dipilih memiliki poin yang cukup untuk dikurangi",
    "ruleFormatErrorInfo": "Hanya huruf kecil dan angka angka yang diperbolehkan",
    "rewardDeductPoints": "Tambah / Kurangi Poin",
    "alreadyExists": "{ name } sudah terdaftar",
    "signNext": "Selanjutnya",
    "copySuccess": "Salin Sukses: { copyUrl }",
    "deleted": "Dihapus",
    "rule": "Peraturan",
    "noName": "Tidak memiliki nama",
    "copyUrl": "Salinan",
    "linkInfo": "Link",
    "laseSevenDaysInfo": "Jumlah member yang ditambahkan dalam 7 hari terakhir",
    "editimage": "Edit Gambar Varian",
    "packageContentplaceholder": "Kurang dari 1000 karakter",
    "packageContent": "Isi Paket",
    "VariantImage": "Gambar Varian",
    "brand": "Merek",
    "brandLogo": "Logo Merek",
    "brandName": "Nama Merek",
    "pointsHistory": "Riwayat Poin",
    "editShopInfo": "Ubah Informasi Toko",
    "alreadyShopOwn": "Selamat! Toko kamu sudah siap.",
    "shopSetInfo": "Informasi Toko",
    "contentWrapper": "Bagian Baru",
    "newSections": "Bagian Baru",
    "accumulatePoints": "Akumulasi Poin",
    "chooseCardBg": "Tema",
    "shopLogo": "Logo Toko",
    "shopName": "Nama Toko",
    "membershipPageSettings": "Konfigurasi Halaman Member",
    "uploadImageError": "File { fileName } ukuran gambar terlalu kecil, minimal {limitMax}x{limitMin}",
    "uploadInfo": "Ukuran: {maxWH}x{maxWH}, Kurang dari {isLt}M, Format yang didukung jpg, png, jpeg",
    "enterPointMax": "Nilai maksimum yang bisa dimasukkan adalah 999999",
    "enterPointNum": "Masukkan bilangan positif antara 1-999999",
    "updateSuccessFul": "Berhasil diperbarui",
    "pointsType": "Tipe integral",
    "redeemablePoints": "Poin Aktif",
    "reason": "Kegiatan",
    "deductPoints": "Kurangi Poin",
    "rewardPoints": "Tambah poin",
    "joinedMethod": "Metode Bergabung",
    "dateOfBirth": "Tanggal Lahir",
    "remarksErrorInfo": "Maksimum 100 karakter",
    "editDetailInformation": "Edit Detail Informasi",
    "editMemberInformation": "Edit Informasi Member",
    "profileDetails": "Detail Profil",
    "accountInformation": "Informasi Akun",
    "rewardCondition": "Syarat Hadiah",
    "customizeAlertInfo": "Peraturan ini akan muncul di Halaman Member, Poin akan diberikan secara manual.",
    "customizePointRules": "Peraturan Poin (Customized)",
    "defaultAlertInfo": "Pelanggan akan mendapatkan poin secara otomatis apabila melakukan aktivitas dibawah ini",
    "defaultPointRules": "Peraturan Poin (Default)",
    "joinMemberTime": "Waktu Bergabung",
    "inputNameOrMobile": "Masukkan Nama/Nomor HP",
    "editPoints": "Ubah Poin",
    "addNewMember": "Tambah Member Baru",
    "totalPage": "Total { total }",
    "description": "Deskripsi",
    "points": "Poin",
    "memberNo": "Nomor Member",
    "mobile": "No. Handphone",
    "shopname": "Nama Toko",
    "name": "Nama Member",
    "genderFemale": "Perempuan",
    "genderMale": "Laki-laki",
    "gender": "Jenis Kelamin",
    "deleteRuleInfo": "Apa kamu ingin menghapus Peraturan ini?",
    "status": "Status",
    "checkBoxTableSelected": "{ selected } Dipilih",
    "create": "Buat",
    "dashboardPointCumulativeRedeem": "Poin yang telah digunakan",
    "dashboardPointCumulativeGiven": "Poin yang diberikan",
    "dashboardPointToRedeem": "Poin untuk digunakan",
    "dashboardNewMember": "Member Baru",
    "dashboardAllMember": "Total Member",
    "hello": "Halo semua!",
    "mobileErrorInfoLength": "No. Handphone harus Berjumlah 9-12 Angka",
    "fullnameerror": "Nama Toko harus 1-30 karakter",
    "fullname": "Nama Toko",
    "mobileplaceholder": "No. Telepon harus 11 digit.",
    "mobileErrorInfochina": "No. Handphone Invalid",
    "mobileErrorInfo": "Harus dimulai dengan 8",
    "nameErrorInfo": "Nama harus berjumlah 2-30 Karakter",
    "emailErrorInfo": "Email invalid",
    "resetPwdSuccess": "Password berhasil diatur ulang",
    "resetPwd": "Atur ulang password",
    "alreadyInfo": "Sudah punya Akun Ginee?",
    "applyConfirm": "Konfirmasi",
    "forgotPwd": "Lupa password?",
    "codeSendSuccess": "Kode Verifikasi sudah dikirimkan via Email ke { email }",
    "noReceiveCountDown": "Tidak mendapat Kode Verifikasi?",
    "waitCountDown": "Dalam { someone }s Detik",
    "resendCode": "Kirim Ulang",
    "sendCode": "Kirim",
    "signBy": "Dengan mendaftar, Saya setuju dengan Syarat & Ketentuan dan Kebijakan Privasi dari Ginee",
    "verificationCode": "Masukkan Kode Verifikasi",
    "verification": "Kode Verifikasi",
    "userName": "Nama",
    "password": "Kata sandi",
    "eMail": "Email",
    "signUp": "Registrasi",
    "login": "Masuk",
    "welcomeRegister": "Registrasi di Ginee",
    "welcomeLogin": "Login di Ginee",
    "welcome": "Selamat datang di Ginee",
    "copyProduct": "Salin Produk",
    "orderDetail": "Rincian Pesanan",
    "productDetail": "Informasi Produk",
    "status_unpublished": "Tidak diterbitkan",
    "status_update_failed": "Gagal Ditampilkan",
    "status_delete": "Dihapus",
    "status_draft": "Draft",
    "status_disabled": "Nonaktif",
    "status_banned": "Dilarang",
    "status_sold_out": "Habis",
    "status_live": "Aktif",
    "status_process": "Sedang diproses",
    "status_all": "Semua",
    "staffDnotExists": "Akun staf tidak memiliki izin, silakan hubungi pedagang untuk menambahkan izin (buka manajemen staf-tambah staf-tambahkan akun ini)",
    "fastPrinting": "Cetak cepat",
    "printPluginHasBeenUpdatedPlease": "Cetak sudah diperbarui, silahkan download ulang",
    "afterUnbindTheStockWillNot": "Setelah dilepas, stok tidak akan dipush ke produk channel.",
    "areYouSureYouWantTo": "Anda yakin ingin melepaskan ikatan?",
    "MSKUAutoBindSettingTip": "Klik di sini untuk menautkan ke halaman pengaturan ikat otomatis Master Produk",
    "masterSkuInventoryInventorySynchronizationOf": "Stok Master SKU: Untuk sementara produk multi gudang Lazada tidak dapat disinkronisasikan. Jika diperlukan, silahkan edit di seller center.",
    "selectGenieCategory": "Pilih Kategori Utama",
    "dayDuration": "Durasi (hari)",
    "unbindedChannelVariant": "Variant Channel Tidak Terikat",
    "deleteSkuConfirm": "Apakah Anda yakin untuk menghapus SKU?",
    "thirdpartyWarehouseProductDetails": "Detail Produk Pihak Ketiga",
    "thereAreThirdpartyWarehouseProductAmong": "Tidak dapat dihapus karena diantara produk yang dipilih terdapat produk gudang pihak ketiga",
    "canNotDeleteThirdStock": "Stok produk di gudang pihak ketiga masih tersedia, tidak dapat dihapus",
    "skuAffectedOutbound": "Total jumlah {val} produk dalam daftar outbound dan inbound akan diproses sebagai produk invalid. Apakah Anda ingin set sebagai invalid dalam daftar outbound dan menghapus master SKU dan data stok?",
    "deleteSkuAlert": "Jika pada master SKU terdapat daftar outbound dan inbound yang belum selesai, sistem akan memproses produk dalam daftar outbound sebagai produk invalid,  dan pada saat yang sama akan menghapus master produk, data stok",
    "proceedToDelete": "Set sebagai invalid dan hapus",
    "productAffectedOutbound": "Total jumlah {val} produk dalam daftar outbound dan inbound akan diproses sebagai produk invalid. Apakah Anda ingin set sebagai invalid dalam daftar outbound dan menghapus master produk dan data stok?",
    "nextStep1": "Langkah berikutnya",
    "deleteProductAlert": "Jika terdapat daftar outbound dan inbound yang belum selesai, sistem akan memproses produk dalam daftar outbound sebagai produk invalid, dan pada saat yang sama akan menghapus master produk, data stok",
    "iNeed": "Saya harus",
    "youCanImportDataFormat": "Anda dapat import 10.000 data (format: XLSX, XLS) setiap kalinya. Anda dapat Edit secara massal nama master produk, deskripsi, Master kategori",
    "WorkingDaysBetween": "Antara 7-90  hari kerja",
    "workingDays": "hari kerja",
    "withoutMasterSku": "Tanpa Master SKU",
    "processTime": "Waktu Proses",
    "width": "Lebar",
    "whenCreatingInTheProVersion": "Saat membuat di Versi Pro, Anda perlu unggah informasi produk yang wajib diisi, yang akan digunakan dalam skenario di mana Master Produk dibuat dan kemudian disalin ke channel untuk dijual",
    "whenAvailableStockInfo": "Stok Tersedia <=X Kirim Email Pengingat",
    "weightLimit": "Harus antara 1-5,000,000!",
    "weight": "Berat",
    "inboundLimitTips": "Saat menggunakan Fulfillment by JD, pastikan untuk mengisi informasi Shelf Life, Misalnya jika shelf life produk adalah 100 hari, dan batas inbound adalah 0,5, maka jika shelf life kurang dari 50 hari tidak akan diperbolehkan untuk inbound.",
    "outboundLimitTips": "Saat menggunakan Fulfillment by JD pastikan untuk mengisi informasi Shelf Life, Misalnya jika shelf life produk adalah 100 hari, dan batas outbound adalah 0,5, maka jika shelf life kurang dari 50 hari tidak akan diperbolehkan untuk outbound.",
    "inboundLimit": "Batasan Inbound",
    "outboundLimit": "Batasan Outbound",
    "shelfLife": "Shelf Life",
    "custom": "Custom",
    "warehouseStockTip": "Total Stok Aktual di Gudang Anda , Stok Gudang = Stok Cadangan + Stok Terkunci + Stok Tersedia + Stok Promosi",
    "warehouseStockLimit": "Stok Gudang harus diantara 0-999,999",
    "yearly": "Tahunan",
    "monthly": "Bulanan",
    "weekly": "Mingguan",
    "today": "Hari Ini",
    "warehouseStock": "Stok Gudang",
    "warehouseName": "Nama Gudang",
    "viewNow": "Lihat sekarang",
    "viewMore": "Lihat Lebih Banyak",
    "viewLess": "Lihat lebih sedikit",
    "view": "Lihat",
    "videoName2": "Bagaimana cara mengelola Biaya Pembelian?",
    "variationTypeInfo": "Tipe Variasi",
    "variationStatus": "Status Variasi",
    "variationImageSizeLimit": "Anda dapat upload beberapa foto secara bersamaan. minimal 300 x 300 pixel ，hingga 5MB (maks 8 foto)",
    "variationImageInfo": "Edit Gambar Varian:",
    "variationCountDesc": "Urutkan Jumlah varian terikat dari tinggi ke rendah",
    "variationCountAsc": "Urutkan Jumlah varian terikat dari rendah ke tinggi",
    "variationAlreadyBound": "Varian ini sudah terikat dengan Master SKU，silakan lepaskan dulu！",
    "exportLimit2": "Hanya dapat Export 50000 Master Produk setiap kali, harap sortir kembali",
    "exportLimit": "Silakan masukkan jumlah halaman untuk export, dan Anda dapat export maks. 50000 Master Produk.",
    "selectChannel": "Pilih Channel",
    "variation1": "Variasi",
    "variantTypeRangeIs": "Tipe Varian 1 harus diantara 1-14 karakter",
    "variantTypeIsMissingPlease14": "Tipe Varian 2 tidak ada, harap periksa!",
    "variantTypeIsMissingPlease": "Tipe Varian 1 tidak ada, harap periksa!",
    "variantsInfoB": "10 produk (50 dari 100 Varian dapat generate Master SKU)",
    "variantsInfoA": "Produk {productSum} ({availableNum} dari {variationSum} Variasi dapat menghasilkan SKU Master). Variasi yang terikat pada SKU Master tidak dapat menghasilkan SKU Master",
    "numberOfProducts": "{productSum}Produk ({variationSum}variation)",
    "variantPictureLinkCannotBe": "Tautan Varian gambar 1 tidak boleh kosong!",
    "uploadFile": "Unggah File",
    "downloadTemplate": "Unduh Template",
    "step2Limit": "Silakan ikuti template unduhan untuk Import aturan, Anda dapat unggah hingga 10.000 aturan setiap kali (format xlsx, xls)",
    "step2Dec": "Langkah 2: Import aturan ikat otomatis baru secara massal",
    "step1Dec": "Langkah 1: Ekspor semua aturan Ikat Otomatis",
    "variantOptionsLimit": "Hingga 20 pilihan Varian",
    "variantOptionShouldBe": "Pilihan Varian 2 harus berupa 1-20 karakter",
    "import10000Tip": "Import hingga 10000 data sekali jalan.",
    "variantNameChannel": "Nama Spesifikasi Channel",
    "variantName": "Nama Varian",
    "variantImage": "Gambar Varian",
    "username": "Nama",
    "useImageFor": "Gunakan gambar untuk",
    "used": "Bekas",
    "operationReason": "Alasan Pengoperasian",
    "goToSettings": "Pergi ke pengaturan",
    "stores": "Silakan pergi ke [Pengaturan Produk] dan aktifkan pengaturan \"Secara otomatis mengikat Master SKU\" sebelum menggunakan fungsi ini",
    "storeSKU": "Fitur tidak tersedia untuk sementara",
    "channelVID": "Varian ID Channel",
    "enabledAutoBind": "Pengaturan Ikat Otomatis dari Master SKU tidak diaktifkan, dan belum ada aturan!",
    "editAutoBindRule": "Ubah Ada aturan Ikat Otomatis",
    "updateAutoBindRules": "Perbarui aturan Ikat Otomatis",
    "exportAllAutoBindRules": "Ekspor semua aturan Ikat Otomatis",
    "enterStoreSKU": "Silakan masukkan SKU Toko",
    "bindingError": "SKU Toko telah terikat dengan SKU Utama lainnya ,silakan periksa",
    "storeSkuLimit": "SKU Toko harus dibawah 250 karakter",
    "bindMasterSku": "Produk channel yang terkait yang belum terikat akan secara otomatis mengikat Master SKU",
    "bindedStore": "Toko Terikat",
    "bindedProduct": "Produk Terikat",
    "autoBind": "Ikat Otomatis",
    "stepExportRules": "SKU Toko",
    "variant": "Variasi",
    "updateStock": "Perbarui Stok",
    "updateSafetyStock2": "Perbarui Stok Pengaman",
    "updateSafetyStock": "Impor dan perbarui stok pengaman",
    "updateInventoryInfo2": "Harap perbarui stok di \"Manajemen Stok\"",
    "updateInventoryInfo": "Harap edit stok produk bundle dengan mengubah Stok Master SKU",
    "updatedSuccessfully": "Berhasil diperbarui",
    "updateAvailableStock": "Perbarui untuk menyimpan stok yang tersedia",
    "unitPurchasPrice": "Harga Beli Satuan",
    "unitPurchasePrice": "Harga Beli Satuan produk",
    "unbindInfo": "Produk telah dilepas ikatannya dari Master SKU, Stok Terkunci akan dibebaskan",
    "unbind": "Lepas Ikatan",
    "turnOnStock": "Aktifkan Monitor Stok dan Sinkronisasi, dan inisialisasi Stok Produk Channel",
    "totalNum": "Total {val}",
    "toShip": "Perlu Dikirim",
    "timeInfoWorkingDay": "hari kerja",
    "timeInfoMonth": "Bulan",
    "thisVariationOnly": "Variasi ini saja",
    "theSameNameIsRegardedAs": "(Nama yang sama dianggap sebagai produk yang sama. Panjang maksimum adalah 300 karakter)",
    "theNameAlreadyExistsInTheStore": "Nama produk sudah ada di Toko",
    "theMasterSKUDoesNotExist": "Master SKU tidak ada, harap periksa!",
    "theLinkOfVariantPicture": "Tautan gambar Varian 1 tidak valid, silakan masukkan kembali!",
    "theInventorySkuAlreadyExists": "Master SKU sudah ada!",
    "theCurrencyUnitOfTheDefault": "(Satuan mata uang dari harga jual default dan harga satuan pembelian bisa menggunakan USD/IDR/PHP/THB/VND/MYR/SGD/CNY）",
    "theCategoryIDDoesNotExist": "ID Kategori tidak ada, silakan unduh tabel kategori dan periksa",
    "theBasicVersionCanImportA": "Versi Basic dapat mengimpor sejumlah kecil bidang yang diperlukan, dan dapat dengan cepat membuat Master SKU untuk mengaktifkan fitur manajemen stok",
    "syncInfoB": "{6787686756 Nonaktifkan Sinkronisasi Stok",
    "syncInfoA": "{6787686756} Aktifkan Sinkronisasi Stok",
    "successNumber": "Jumlah Berhasil",
    "successfullyUnbindInfo": "Berhasil melepaskan Varian {val}",
    "successfullyBindInfo": "Berhasil ikat Varian {val}",
    "storeWithoutSales": "Toko tanpa penjualan",
    "storeUpdateResult": "Hasil Pembaruan Toko",
    "storeSku": "SKU Toko",
    "storeNameB": "Nama Toko",
    "storeNameA": "Nama Toko",
    "storeCountDesc": "Urutkan Toko dengan Penjualan dari tinggi ke rendah",
    "storeCountAsc": "Urutkan Toko dengan Penjualan dari rendah ke tinggi",
    "stockSettingsStockMonitorAndSyncOn": "Pengaturan Stok-Monitor Stok dan Sinkronisasi，",
    "stockSettingsStockMonitorAndSyncOff": "Pengaturan Stok-Monitor Stok dan Sinkronisasi，",
    "stockChannelInfo": "Stok yang bisa dijual di Channel, Stok Tersedia = Stok Gudang-Stok Cadangan-Stok Terkunci-Stok Promosi",
    "stockChanges": "Perubahan Stok",
    "stepImportAndCreateThe": "Langkah 2: Import dan buat Master Produk",
    "stepDownloadTheMasterCategory": "Langkah 1: Unduh ID Master Kategori",
    "step3Text": "Langkah 3: Import untuk Ikat Produk",
    "step2Text": "Langkah 2: Ekspor Master SKU",
    "step1Text": "Langkah 1: Pilih dan export Produk Channel yang ingin diikat ke master SKU",
    "spareStockTip": "Jumlah Total Stok yang dicadangkan di gudang, yang tidak akan dijual di Channel Toko.",
    "spareStock": "Stok Cadangan",
    "sourceUrl": "Sumber URL",
    "sortMasterProduct": "Sortir Master Produk",
    "someWarehouseTheWarehouseStockOf": "Di beberapa gudang, stok gudang master SKU tidak 0, harap perbarui ke 0 sebelum menghapus.",
    "somePartUpdateFailed": "Beberapa gagal diperbarui",
    "somePartReviewFailed": "Beberapa Gagal Ditinjau",
    "someDatasImportFailed": "Beberapa Data Gagal Diimpor",
    "someCreateFailed": "Sebagian Gagal Dibuat",
    "skuStoreSku": "SKU (SKU Toko)",
    "skuAlreadyExistsPleaseDeleteThe": "SKU sudah ada, hapus produk yang ada atau ganti namanya!",
    "singlePurchasePriceOfTheProduct": "Harga Beli Satuan produk",
    "singlePurchasePrice": "Harga Beli Satuan",
    "shouldBeBetweenLimitB": "Harus antara 0-1000,000,000",
    "shouldBeBetweenLimitA": "Harus antara 0-99,999",
    "shouldBeBetweenB": "Harus antara 1-999,999!",
    "shouldBeBetweenA": "Harus antara 1-999,999",
    "shippingFeeInformation": "Informasi Biaya Pengiriman",
    "setPageTip2": "Silakan masukkan angka halaman yang valid",
    "setPageTip": "Pilih halaman untuk di export",
    "selling": "Dijual",
    "selectProductBundle": "Pilih Produk Bundle",
    "selectGineeCategory": "Pilih Kategori Master",
    "selectFile": "Pilih file",
    "selectedMax20": "Dipilih(Maks.20)",
    "selected": "Dipilih",
    "selectCategories": "Pilih Kategori",
    "selectAtLeastOneInventorySku": "Pilih minimal 1 Master SKU",
    "editRule": "Ubah Otomati",
    "selectAtLeastOneImage": "Pilih minimal 1 Gambar",
    "createDates": "Dibuat Pada",
    "seeXProducts": "Lihat X Produk",
    "seeMoreTips": "Lihat {val} Produk",
    "saveUpdate": "Simpan Pembaruan",
    "saveForReview": "Simpan untuk ditinjau",
    "savedSuccessfully": "Berhasil disimpan",
    "saveAsReviewed": "Simpan, telah ditinjau",
    "salesTaxAmount": "Jumlah pajak penjualan",
    "safetyStockStatus": "Status Stok Pengaman",
    "safetyStock": "Stok Pengaman",
    "rupiah": "Rp",
    "reviewPassed": "Telah Selesai Ditinjau",
    "reviewed": "Telah Ditinjau",
    "reReview": "Tinjau Ulang",
    "reasonForFailure": "Alasan gagalan: informasi yang diperlukan tidak ada, mohon dilengkapi",
    "rangeLimitA": "Harus diantara 0-1,000,000,000",
    "rangeIsLimit": "Rentang 5,000,000",
    "quantityLimit": "Kuantitas harus diantara 1-999.999",
    "qty": "Jumlah",
    "purchaseTime": "Waktu Pembelian",
    "purchasePriceShouldLimit": "Harga Beli Satuan harus diantara Rp0-Rp1000.000.000",
    "purchasePriceLimit": "Harga Beli Harus diantara 0-1,000,000,000",
    "purchasePriceInfo": "(Tidak perlu input, Harga Beli Satuan akan dihitung berdasarkan biaya rata-rata)",
    "proVersionImportToCreate": "Versi Pro Import untuk Buat",
    "promotionStockTip": "Stok Promosi adalah stok total yang direserve untuk Promosi Campaign atau Flash Sale",
    "promotionStock": "Stok Promosi",
    "productType": "Tipe Produk",
    "productsWithoutVariantsCanBeEmpty": "(Produk tanpa Varian boleh kosong)",
    "productSources": "Sumber Produk",
    "productNameLimit": "Nama Produk harus diantara 5-300 Karakter",
    "productNameInventorySku": "Nama Produk & Master SKU",
    "productInformation": "Info Produk",
    "productImageLinkIsInvalid": "Tautan gambar produk 1 tidak valid, silakan cek kembali!",
    "productImageLinkCannotBe": "Tautan gambar produk 1 tidak boleh kosong!",
    "productImage": "Gambar Produk",
    "productFailedToCreatePleaseDownload": "Master Produk gagal dibuat, silakan unduh data yang gagal dan lihat alasan gagal",
    "productEditSuccess": "Produk Berhasil Diedit",
    "productEditFailed": "Gagal Edit Produk",
    "productDescriptionShouldBeCharacters": "Deskripsi Panjang harus terdiri dari 1-7000 karakter",
    "productDescription": "Deskripsi Panjang",
    "productCreatedSuccessfully": "Produk Berhasil Dibuat",
    "product": "Produk",
    "preorder11": "Pre-order",
    "popupResultButton": "lihat Data yang Gagal",
    "pleaseEnterTheAmount": "Harap masukkan jumlahnya",
    "pleaseEnterKeywordsToSearchChannelProducts": "Harap masukkan kata kunci untuk mencari produk Channel",
    "pleaseEnter": "Silakan Masukkan",
    "pleaseDownloadTheTemplateAndComplete": "Silakan unduh template dan lengkapi data lengkap, lalu import untuk buat produk (10000 data dapat diimport sekali jalan)",
    "pleaseChoose": "Tolong pilih",
    "partiallyFailedInfo": "Gagal sebagian, status masih telah ditinjau",
    "packageSize": "Ukuran Paket",
    "orderToEnsureTheIntegrity": "Untuk memastikan integritas proses dan data laporan statistik, beberapa master SKU tidak dapat dihapus, sehingga jenis varian produk ini tidak dapat diedit. Disarankan untuk \"refer\" produk ini untuk menghasilkan beberapa varian master produk, dan kemudian menetapkan produk ini sebagai \"tidak terlacak\".",
    "orderToEnsureTheIntegrityOf": "Untuk memastikan integritas data, disarankan untuk menggunakan \"Tidak Terlacak\".",
    "optionLimit": "Pilihan harus antara 1-30 karakter",
    "option": "Pilihan",
    "operationTime": "Waktu Operasi",
    "operationGuide": "Panduan Operasi",
    "noShelfLife": "Tidak Ada Shelf Life",
    "onSaleShop": "Di Toko Penjualan",
    "onReview": "Sedang Ditinjau",
    "onlyShowBindedVariations": "Hanya tampilkan varian tanpa MSKU",
    "numVariationsSelected": "0 variasi dipilih",
    "numVariations": "{val} Varian",
    "numSelected": "{val} Dipilih",
    "numEditBindInformation": "{val}-Informasi Edit Ikatan",
    "nowInbound": "Inbound Sekarang",
    "normalProducts": "Produk Normal",
    "noData": "Tidak ada data",
    "noChannelVariationId": "Tidak ada ID varian channel",
    "newProduct": "Produk Baru",
    "newAveragePurchasePrice": "Harga Pembelian Rata-rata Baru",
    "newAverageCostPrice": "Harga Biaya Rata-Rata Baru",
    "new": "Baru",
    "mutiVariations": "Produk memiliki beberapa variasi, jika produk Anda memiliki warna dan ukuran yang berbeda, silakan pilih ini",
    "mustBeBetween": "Harus diantara 1-8",
    "mskuAndstockVideo": "Apa hubungan antara Master Produk dan Manajemen Stok?",
    "mSku": "MSKU",
    "more": "Lebih",
    "minPurchaseQuantityShouldBe": "Min. Jumlah Pembelian harus 1-1000",
    "minimumPurchaseQuantity": "Jumlah Pembelian Minimum",
    "mediaSettings": "Pengaturan Media",
    "maxNumVal": "Maks.{val}",
    "maxNum": "Maks.{val}",
    "maxLengthNum": "Maks. {val} karakter",
    "maxLengthCharacters": "Maks. {max} karakter",
    "maxLength14": "Maks.14 karakter",
    "maximumIs": "(Maksimum 1.000.000.000)",
    "maximumCharacters": "(Maksimum 7000 karakter)",
    "max9": "Maks.9",
    "masterSkuHasBeenMatchedWith": "Master SKU telah dimapping dengan produk gudang pihak ketiga",
    "masterProductsImage": "Master Produk & Gambar",
    "masterProductName": "Nama Master Produk",
    "masterProduct": "Master Produk",
    "master": "Master Produk",
    "massSettings": "Pengaturan Massal",
    "mainPhotos": "Foto Utama",
    "lockedStockTip": "Stok belum dipenuhi dan dikirim, Pesanan lunas baru/belum bayar tersinkronkan ke Ginee akan ditambahkan ke Stok Terkunci",
    "lockedStock": "Stok Terkunci",
    "liveTips": "Batasan: Termasuk Produk Stok Habis dan Aktif",
    "live": "Aktif",
    "length": "Panjang",
    "iWillShipOutWithinX": "Saya akan mengirimkan pesanan dalam x jam kerja",
    "iWillShipOutWithin22": "Saya akan mengirimkan pesanan dalam 2 hari kerja (tidak termasuk hari libur dan jadwal libur logistik)",
    "iWillShipOutWithin": "Saya akan mengirimkan pesanan dalam 3 hari kerja (tidak termasuk hari libur dan jadwal libur logistik)",
    "iskuStandsForInventorySku": "MSKU singkatan dari Master SKU",
    "iskuCannotBeEmpty": "Master SKU wajib diisi!",
    "isAnUnprocessedOutboundInThe": "Ada outbound yang belum diproses pada master SKU",
    "inventorySkuSpaceLimit": "Master SKU memiliki spasi atau enter, harap edit",
    "inventorySkuLimit": "Master SKU harus dibawah 300 karakter",
    "inventorySkuGenRules": "Aturan Generate Stok SKU secara default:\\n1. Master SKU akan mengambil 50 karakter pertama dari Varian secara default\\n2. Stok Gudang akan sama dengan Stok Varian secara default, dan Stok Pengaman akan kosong secara default\\n3. Saat Master SKU dibuat, varian akan terikat",
    "inventorySkuExistsLimit": "Master SKU sudah ada, harap edit",
    "instructions": "Instruksi",
    "iNeedXWorkingDays": "Saya butuh X hari kerja untuk melakukan pengiriman",
    "iNeedXWeeksToShip": "Saya butuh X minggu untuk melakukan pengiriman",
    "iNeedXDaysToShip": "Saya butuh X hari untuk melakukan pengiriman",
    "importToEditProduct": "Import untuk edit produk",
    "importToBindIntro": "Fitur ini membantu Anda untuk mengikat produk toko ke Master SKU secara massal untuk mengelola stok",
    "importToBind": "Import untuk Ikat Produk",
    "importTip": "Import hingga 1000 data sekali jalan.",
    "importMasterProducts": "Import Produk Master",
    "importFileFormatIsIncorrectInfo2": "Template file yang diimpor salah, harap periksa",
    "importFileFormatIsIncorrect": "Format file impor tidak sesuai, silakan unduh template impor",
    "importFile": "Import File",
    "importFailed": "Import Gagal",
    "importAndCreateProducts": "Import untuk Buat Produk",
    "importAndAddInfo": "Anda dapat import 10.000 data sekaligus (format:xlsx,xls) untuk tambah Master produk secara massal",
    "imageSizeLimit": "Resolusi gambar minimal 300 x 300 pixel ，hingga 5MB",
    "ifItIsEmptyWillNot": "Jika kosong maka tidak diperbarui",
    "hour": "jam",
    "hotSale": "Laris",
    "helpCenterLink": "Panduan Operasi",
    "height": "Tinggi",
    "hasBindedInfo": "Varian ini telah diikat ke Master SKU: xxxxxxxx. Harap perbarui stok di \"Manajemen Stok\"",
    "generateMaster": "Generate Master SKU produk di satu Toko untuk mengelola Stok di berbagai Toko secara terpadu",
    "filter": "Filter",
    "fileRules": "Format xlsx, xls. maksimal 5MB",
    "faqName2": "Bagaimana Cara Membuat Produk Master menggunakan Impor dalam Versi dasar?",
    "faqName1": "Bagaimana Cara Membuat Produk Master menggunakan Impor dalam Versi Pro?",
    "failedReason": "Alasan Gagal: Produk mungkin telah dihapus, harap sinkronkan ulang produk",
    "failedNumber": "Jumlah Gagal",
    "exportUpTo": "Anda dapat export hingga 10.000 data (format xlsx, xls) setiap kalinya untuk ikat produk secara massal",
    "exportTip": "Silakan periksa Produk yang ingin Anda Export.",
    "exportSelected": "Export yang dipilih",
    "exportOrImport": "Export/Import",
    "exportByPageTip": "Silakan masukkan jumlah halaman yang valid",
    "exportByPage": "Export berdasarkan halaman",
    "export": "Export",
    "enterTheNameOfTheVariationForExampleColorEtc": "Masukkan nama variasi, misalnya: warna, dll.",
    "enterProcessTime": "Masukkan Waktu Proses",
    "enterPageNumber": "Silakan masukkan jumlah halaman untuk mendownload produk master",
    "enterOption": "Masukkan Pilihan, misalnya: Merah, dll.",
    "enableInventorySync": "Aktifkan Sinkronisasi Stok",
    "editSafetyFtock": "Edit Safety Ftock",
    "editOptionsBlue": "Edit Pilihan-Biru",
    "editInventory": "Edit Stok",
    "editAvailableStock": "Edit Stok Tersedia",
    "downloadTip": "Unduh",
    "downloadTheMasterCategory": "Unduh Master Kategori",
    "downloadTemplateInfo": "Unduh Template",
    "downloadProduct": "Unduh produk",
    "downloadFailedData": "Unduh Data yang Gagal",
    "discontinued": "Dihentikan",
    "disableInventorySync": "Nonaktifkan Sinkronisasi Stok",
    "descriptionRequired": "Harap masukkan deskripsi dan terapkan ke produk Anda",
    "descriptionLimit": "Deskripsi harus dalam 30 - 5000 karakter",
    "delivery": "Pengiriman",
    "deleteMPTips2": "Master Produk telah dihapus",
    "deleteMPTips": "Gagal Dihapus: {val} Master Produk",
    "deleteLimit": "Alasan Gagal:Silahkan lepaskan ikatan dari MSKU {val}, kemudian hapus",
    "delConfirmTitle": "Anda yakin ingin menghapus Produk ini?",
    "delConfirmContent": "Setelah manghapus Master Produk, maka Master SKU di Manajemen Stok juga aka terhapus",
    "defaultPrice": "Harga Default",
    "dataSources": "Sumber Data",
    "datasImportSuccess": "Data Berhasil Diimpor",
    "datasImport": "Data Diimpor",
    "creatingProduct": "Sedang Membuat Produk",
    "createProductFailed": "Buat Produk Gagal",
    "costInformation": "Informasi Biaya",
    "continueToBindInfo": "Anda dapat lanjut mengikat produk Channel ke Daftar Produk Master",
    "condition": "Kondisi",
    "columnsCannotBeEmpty": "Kolom tidak boleh kosong",
    "collectByAdd": "Collect & Tambahkan",
    "cny": "CNY",
    "salesTaxAmountLimit": "Harga Beli Harus diantara 0-1,000,000,000",
    "clearance": "Cuci Gudang",
    "clear": "Kosongkan",
    "checkNow": "Cek Sekarang",
    "channelVariationIdNotEmpty": "ID Varian Channel wajib diisi!",
    "channelVariationId": "ID Varian Channel",
    "channelSellingStatus": "Status Penjualan Channel",
    "channelProductsImage": "Produk & Gambar Channel",
    "channelProducts": "Produk Channel",
    "channelProductName": "Nama Produk Channel",
    "changeTo": "Ubah menjadi",
    "changeReason": "Alasan Edit:",
    "changeIsku": "Ubah Master SKU",
    "ceneratedFromChannelProduct": "Generate dari Channel Produk",
    "categoryID": "ID Kategori",
    "category": "Kategori",
    "cancelUpdateConfirm": "Yakin ingin membatalkan perubahan ini?",
    "bundledProduct": "Produk di Bundle",
    "bundle": "Bundle",
    "boundInfo": "Terikat",
    "bindProduct": "Ikat Produk",
    "bindNow": "Ikat Sekarang",
    "bindInstruction": "Selesai mengikatkan produk channel dengan Master SKU, silahkan unduh tempate dan import data untuk ikat",
    "bindingType": "Jenis Ikatan",
    "bindingInProgress": "Sedang mengikat Produk secara massal",
    "bindingInformation": "Informasi Integrasi",
    "bindInformation": "Informasi Ikatan",
    "bindInfoB": "l, gree {id: 111234} lepaskan ikatan {6787686756}",
    "bindInfoA": "l, gree {id: 111234} ikat {6787686756}",
    "bindHistory": "Riwayat Pengikatan",
    "bindedInventorySku": "Master SKU Terikat",
    "binded0Variation": "0 Varian Terikat",
    "weightShouldBeBetween": "Berat Harus antara 1-5,000,000",
    "heightShouldBeBetween": "Tinggi Harus antara 1-999,999!",
    "widthShouldBeBetween": "Lebar Harus antara 1-999,999!",
    "lengthShouldBeBetween": "Panjang Harus antara 1-999,999!",
    "betweenWeeks": "Antara 1-13 minggu",
    "betweenDays": "Antara 1-90 Hari",
    "between21": "Antara 7-15",
    "between": "Antara 3-30",
    "beBetweenWorkingHoursMust": "Harus diantara 1-8 jam kerja",
    "basicVersionImportToCreate": "Versi Basic Import untuk buat",
    "theSameNameWillConsideredAs": "(Nama yang sama dianggap sebagai produk yang sama. Panjang nama maksimal 300 karakter)",
    "categoryName": "Nama Kategori",
    "basicInformation": "Informasi dasar",
    "variantPictureLink": "Tautan Varian gambar 1",
    "barcodeLimitC": "Barcode hanya boleh menggunakan huruf, angka dan -_ dan dibawah 100 karakter",
    "productImageLink1": "Tautan gambar produk 1",
    "barcodeLimitB": "Barcode tidak boleh lebih dari 100 karakter",
    "barcodeLimitA": "Barcode hanya boleh menggunakan huruf, angka dan -_",
    "temporaryNotFollowingXProducts": "Sementara tidak mengikuti {X} produk",
    "thisBarcodeIsAlreadyOccupiedBy": "Barcode ini sudah digunakan oleh {MSKU}, silakan masukkan kembali",
    "currency": "Mata Uang",
    "barcode": "Barcode",
    "averagePurchasePrice": "Harga Pembelian Rata-rata",
    "averageCostPriceInfo": "Harga Biaya Rata-Rata = Total Harga Beli Satuan/ Total Stok Gudang",
    "averageCostPrice": "Rata-rata Harga Biaya",
    "variantOption1ShouldBe": "Pilihan Varian 1 harus berupa 1-20 karakter",
    "variantOption1": "Pilihan Varian 1",
    "variantOption2": "Pilihan Varian 2",
    "variantTypeIs2": "Tipe Varian 2",
    "variantTypeIs": "Tipe Varian 1",
    "unspecifiedProductsCanBeEmpty": "Produk yang tidak ditentukan boleh kosong",
    "availableStockTip": "Stok yang bisa dijual di Channel, Stok Tersedia = Stok Gudang-Stok Cadangan-Stok Terkunci-Stok Promosi",
    "availableStockLimit": "Stok Tersedia wajib diisi",
    "availableStockInputLimit": "persediaan gudang ≥ persediaan cadangan",
    "availableStockInfo": "Stok Tersedia = Stok Gudang-Stok Cadangan-Stok Terkunci-Stok Promosi, Edit Stok Tersedia dengan memperbarui Stok Gudang",
    "availableStock": "Tersedia",
    "availableForDownloadCategory": "(Tersedia untuk kategori unduhan)",
    "autoMatchVariationInfo": "Setelah Master SKU dibuat, Master SKU akan secara otomatis mengikat variasi yang SKU sama persis dengan Master SKU",
    "articleName2": "Bagaimana cara mengintegrasikan produk channel ke Master SKU?",
    "applyToAllVariation": "Berlaku untuk semua Variasi",
    "aOfb": "1-50 dari 219",
    "allVariation": "Semua Variasi",
    "missingVariantPleaseCheck": "Varian hilang 1, Silakan cek!",
    "allUpdateFailed": "Semua agagal diperbarui",
    "max1000": "Maks. 1000",
    "missingMasterProductVariantRed": "Master Produk varian hilang: Merah / S, Merah / L\nPerlu dilengkapi untuk buat produk",
    "massOperationHistory": "Riwayat Operasi Massal",
    "publishToStore": "Tampilkan ke toko",
    "chooseDestinationStore": "Pilih Toko tujuan salin produk",
    "selectStore": "Pilih Toko",
    "nextStep": "Selanjutnya",
    "orderProcessingTimeFollowsTheShops": "Waktu proses pesanan mengikuti pengaturan di lapak",
    "allUpdatedSuccessfully": "Semua berhasil diperbarui",
    "allSuccessfullyInfo": "Semua berhasil diubah menjadi menunggu ditinjau",
    "allSuccessfullyCreated": "Semua Berhasil Dibuat",
    "allReviewFailed": "Semua Gagal Ditinjau",
    "shipTime": "Waktu Proses",
    "allReviewed": "Semua Telah Ditinjau",
    "allRecordsOfChannelProductsInfo": "Semua riwayat produk Channel (yang terikat dengan Master SKU) yang telah diperbarui oleh Ginee di Toko Channel (30 hari terakhir)",
    "INeed": "Saya butuh",
    "allFailedInfo": "Semua gagal, status masih telah ditinjau",
    "allCreateFailed": "Semua Gagal Dibuat",
    "hoursWorking": "jam kerja",
    "instant": "Instan",
    "orderProcessingDeadline": "Batas Waktu Proses Pesanan",
    "all": "Semua",
    "toViewTheMassOperationRecords": "Sekarang dapat melihat riwayat pengoperasian massal Anda, cek sekarang!",
    "toPublishToTheStoreHow": "Bagaimana Cara \"Tampilkan ke Toko\"？",
    "toBatchEditingOfMainProducts": "Bagaimana Cara \"Edit Massal Master Product\"？",
    "thePriceOfTheProductIn": "Sinkronkan harga produk di channel yang terikat ke Ginee",
    "productSyncronized": "Sinkronkan Produk",
    "isRecommendedToSynchronizeThePromotion": "Disarankan untuk menyinkronkan promosi toko channel terlebih dahulu untuk memastikan bahwa harga promosi produk sesuai dengan seller center",
    "promotionSync": "Sinkronisasikan Promosi",
    "variationIdHasBeenBoundChannel": "ID Varian Channel Telah Diikat",
    "productInformationMaster": "Informasi Master Produk",
    "theInformationOfAnExistingMaster": "Referensi informasi dari master produk yang ada",
    "productReferenceMaster": "Referensi Master Produk",
    "copy": "Salin Master Produk",
    "skuAutobindSettingEnabledMaster": "Pengaturan Ikat Otomatis Master SKU (Sudah Diaktifkan)",
    "skuAutobindSettingNotEnableMaster": "Pengaturan Ikat Otomatis Master SKU (Belum Diaktifkan)",
    "MSKUAutoBindSettings": "Pengaturan Ikat Otomatis Master SKU",
    "matchingExact": "Exact Mapping",
    "matchingFuzzy": "Fuzzy Mapping",
    "Choose": "Pilih",
    "stockWarehouse": "Stok Gudang",
    "afterTheSafetyInfo": "Setelah Stok Pengaman diaktifkan, jika Master SKU lebih rendah dari Stok Pengaman, email pengingat akan dikirimkan.",
    "nowIntegrate": "Integrasikan sekarang",
    "productHasBeenBoundChannel": "Produk channel telah diikat",
    "stockSynchronizationSettingsEnable": "Aktifkan pengaturan sinkronisasi stok",
    "inboundToWarehouseAlready": "Sudah inbound ke gudang",
    "partFailedDelete": "Gagal menghapus sebagian",
    "allFailedDelete": "Gagal menghapus semua",
    "allSuccessfulDelete": "Berhasil menghapus semua",
    "close1": "Tutup",
    "productHasAOutboundListTo": "Produk ini memiliki daftar outboud, untuk memastikan kelengkapan data, disarankan untuk menggunakan produk \"tidak terlacak\"",
    "warehouseStockOfThisProductIs": "Beberapa stok gudang produk ini tidak 0, harap kosongkan terlebih dahulu lalu hapus",
    "productHasBeenBoundToA": "Produk ini telah diikat ke produk di gudang pihak ketiga. Untuk memastikan kelengkapan data, disarankan untuk menggunakan produk \"tidak terlacak\"",
    "afterTheChannelInfo": "Setelah produk channel terikat ke Master SKU, Stok Toko akan diinisialisasi",
    "failedDelete": "Gagal Dihapus",
    "productBindingStatusChannel": "Status pengikatan produk channel",
    "boundChannelProductAll": "Semua produk channel terikat",
    "boundChannelProductPartially": "Sebagian produk channel terikat",
    "allChannelProductUnbind": "Lepas ikatan semua produk channel",
    "operationSuccessfulMass": "Atur massal berhasil",
    "toTheMasterProductAnd": "Kembalikan ke master produk dan tidak lagi ditandai sebagai \"tidak terlacak\"",
    "temporaryUntrackedDisplay": "Tampilkan tidak terlacak",
    "unbindMasterProductAndChannelProduct": "Lepas ikatan master produk dan channel produk secara massal",
    "recoverMass": "Kembalikan secara massal",
    "temporaryUntrackedMass": "Tidak terlacak secara massal",
    "unbindMass": "Lepas ikatan secara massal",
    "deleteMass": "Hapus Secara Massal",
    "group": "Kelompok",
    "reference": "Referensi",
    "untrackedTemporary": "Tidak Terlacak",
    "combination": "Kombinasi",
    "variantMultiple": "Banyak Varian",
    "addWarehouseStockLimit": "Stok Gudang harus diantara 1-999,999",
    "addVariation": "Tambah Variasi",
    "addSpareStockLimit": "Stok Harus antara 0-999,999",
    "supportSelectingMultipleStoresInThe": "Hanya dapat memilih beberapa toko di negara yang sama",
    "variantNo": "Tidak ada varian",
    "theProductThatFailedToBe": "Tetapkan produk yang gagal dihapus sebagai tidak terlacak, sehingga master produk tidak akan ditampilkan pada daftar \"Semua\"",
    "masterSkuHasBeenBoundBy": "Master SKU telah diikat ke produk gabungan, harap lepas ikatan kemudian hapus",
    "masterProductsThatAreSetAs": "Semua master produk yang ditetapkan sebagai \"tidak terlacak\" (termasuk produk gabungan atau produk biasa)",
    "typeOfMasterProductCombined": "Tipe master produk gabungan",
    "productSingle": "Produk tunggal",
    "theGeneralOfMasterProductType1": "Dalam tipe master produk yang biasa, ada master produk dengan 1 atau 2 tipe varian",
    "theGeneralOfMasterProductType": "Dalam tipe master produk yang biasa, tidak ada master produk dari tipe varian",
    "notIncludeTheTemporaryUntrackedMaster": "Tidak termasuk produk yang \"tidak terlacak\"",
    "skuHasBeenBoundByThe": "Master SKU telah diikat oleh produk gabungan {bundle MSKU}, harap lepaskan bundle dan hapus.",
    "skuHasMatchedTheProductsOf": "Master SKU telah cocok dengan produk di gudang pihak-ketiga {nama gudang pihak-ketiga}",
    "skuHasPendingOutboundOrderOr": "Master SKU memiliki pesanan outbound atau pesanan inbound yang tertunda, harap hapus setelah selesai",
    "stockOfTheMasterSkuIn": "Stok gudang master SKU di gudang {nama gudang 1, nama gudang 2,}tidak 0, harap perbarui ke 0 sebelum menghapus.",
    "errorPleaseRefreshThePageAnd": "Kesalahan data, silakan refresh halaman dan coba lagi",
    "retry": "Coba lagi",
    "productSuccessfullyCreatedMaster": "Master Produk berhasil dibuat",
    "beBetweenMust": "Harus diantara 1-999,999",
    "addOption": "Tambah Pilihan",
    "stockMonitoringAndSynchronizationEnable": "Aktifkan stok monitor dan sinkronisasi",
    "toWarehouseInboundAdd": "Tambah ke Gudang + Inbound",
    "channelProductsRefer": "Refer Produk Channel",
    "failedUnbind": "Gagal Lepas Ikatan",
    "addMskuArticle": "Bagaimana cara menambahkan Master SKU?",
    "unbindAllFailed": "Lepas semua gagal",
    "allUnbindSuccessfully": "Semua berhasil dilepaskan",
    "partialUnbinding": "Pemutusan sebagian gagal",
    "unbinded": "Tidak Terikat",
    "bound": "Terikat",
    "unbindedAll": "Semua Tidak Terikat",
    "boundAll": "Semua sudah diikat",
    "masterSkuHasEnableStockSynchronization": "Master SKU sudah mengaktifkan sinkronisasi stok, silakan ke Manajemen Stok untuk mengedit stok",
    "youNeedToEnableStockSynchronization": "Jika Anda perlu mengaktifkan sinkronisasi stok master produk, silakan lanjutkan untuk mengatur tambah master produk ke gudang dan inbound",
    "toProductListReturn": "Kembalikan ke daftar produk",
    "failedDataDownload": "Unduh data yang gagal",
    "createFailedAll": "Semua gagal dibuat!",
    "addManually": "Tambahkan Manual",
    "masterProductSuccessfullyCreatedIfYou": "Sebagian master produk berhasil dibuat! Jika Anda perlu mengaktifkan sinkronisasi stok master produk, silakan lanjutkan untuk mengatur tambah master produk ke gudang dan inbound",
    "failedToCreateSome": "Sebagian gagal dibuat!",
    "masterProductView": "Lihat Master Produk",
    "successfullyCreatedAll": "Semua berhasil dibuat!",
    "productSuccessfullyCreatedIfYouNeed": "Master Produk berhasil dibuat! Jika Anda perlu mengaktifkan sinkronisasi stok master produk, silakan lanjutkan untuk mengatur tambah master produk ke gudang dan inbound",
    "productCanOnlyUseOneCurrency": "Produk hanya dapat menggunakan satu mata uang, silakan pilih lagi!",
    "creatingTheMasterProductCanCompleted": "Setelah membuat Master Produk dapat menyelesaikan Inbound dan mengaktifkan sinkronisasi stok master SKU.\nSetelah diaktifkan, stok master SKU dan toko channel dapat diperbarui dan disinkronkan secara otomatis.",
    "masterSkuHasBeenPlacedIn": "Master SKU gabungan sudah ditempatkan di gudang Inbound",
    "warehouseDefault": "Gudang Default",
    "toWarehouseAdd": "Tambahkan ke Gudang",
    "toStoreClone": "Salin ke Toko",
    "enableStockSynchronizationOfMasterProduct": "Untuk mengaktifkan sinkronisasi stok Master Produk, silakan lanjutkan untuk mengatur Tambah ke Gudang dan Inbound. Untuk menampilkan Master Produk dengan cepat ke toko channel untuk dijual, silakan gunakan \"Salin ke Toko\"",
    "quicklyPublishTheMasterProductsTo": "Untuk menampilkan master produk ke toko channel dengan cepat, silakan gunakan \"Salin ke Toko\"",
    "informationWarehouse": "Informasi Gudang",
    "inbounded": "Sudah Inbound",
    "inboundNot": "Belum Inbound",
    "skuHasBeenPlacedInInbound": "Master SKU belum ditempatkan ke gudang inbound",
    "statusStock": "Status Stok",
    "addByStore": "Tambah Berdasarkan Toko",
    "addBundleProduct": "Tambah Produk Bundle",
    "oneCurrency": "Produk hanya dapat digunakan dalam satu mata uang, silakan periksa",
    "variantSingle": "Varian tunggal",
    "productsOfVariants": "10 produk (3 dari 20 Varian dapat generate Master Produk). Varian yang terikat dengan Master SKU atau SKU kosong tidak dapat generate Master SKU\nKlik Konten untuk edit",
    "synchronizationOfTheMasterSkuWill": "Sinkronisasi stok master SKU akan diaktifkan setelah inbound selesai dan master produk dibuat. Setelah diaktifkan, stok master SKU dan toko channel dapat diperbarui dan disinkronkan secara otomatis",
    "toTheNChannelsProductList": "Segera ke halaman daftar produk channel {n}",
    "channelsProductList": "Pergi ke daftar produk saluran:",
    "goNow": "Pergi sekarang",
    "goToTheChannelProductList": "Silakan ke halaman Daftar Produk Channel - centang produk channel yang ingin Anda refer",
    "doNotHaveAnIntegratedStore": "Anda belum memiliki toko yang terintegrasi, silakan integrasikan toko terlebih dahulu",
    "toModifyGo": "Ubah Sekarang",
    "skuHasBeenInboundToThe": "Master SKU sudah diinbound ke gudang, silakan ke halaman [Manajemen Stok] untuk mengubah stok yang tersedia",
    "productCombined": "jenis varian sudah ada, silakan masukkan kembali",
    "combinedProductAdd": "Produk Gabungan",
    "theContentToEditClick": "Tambahkan Produk Kombinasi",
    "combinationProductsEdit": "Edit Produk Gabungan",
    "preview": "Preview",
    "atTheEndAdd": "Tambahkan di akhir",
    "increaseStartingValueAutomatically": "Secara otomatis meningkatkan nilai awal",
    "atTheBeginningAdd": "Tambahkan di awal",
    "xxWithYyyReplace": "Ganti xx dengan yyy",
    "massEditSKU": "Edit Massal Master SKU",
    "changeStockTo": "Edit stok jadi",
    "massEditStock": "Edit Massal Stok",
    "applyToAllProducts": "Terapkan ke semua produk",
    "masterDeleteFailedTip": "Produk telah berhasil dikeluarkan dari gudang {warehouse name 1}, ⁣{warehouse name 2}.",
    "masterStockpdateFailed": "Pembaruan stok Master SKU yang telah disimpan gagal, silakan buka manajemen inventaris untuk memodifikasi",
    "stockLimit": "Stok Harus antara 0-999,999!",
    "reduceByPercent": "Kurangi berdasarkan presentase",
    "addByPercent": "Tambah berdasarkan presentase",
    "reduceByAmount": "Kurangi berdasarkan jumlah",
    "addByAmount": "Tambah berdasarkan jumlah",
    "massEdit": "Edit Massal",
    "mskuNoSpaces": "SKU utama tidak boleh berisi spasi, silahkan perbarui",
    "followingXProductsRestore": "{X} item telah dipulihkan",
    "productsAreSetAsTemporary": "Untuk sementara tidak mengikuti (X) produk",
    "masterSkuDoesNotMeetThe": "Master SKU tidak memenuhi kriteria penghapusan, sehingga jenis spesifikasi dan opsi spesifikasi tidak dapat dihapus",
    "barcodeExist": "Barcode sudah digunakan oleh {MSKU}, silakan masukkan kembali",
    "massEditPrice": "Edit Massal Harga",
    "changePriceTo": "Edit harga jadi",
    "replaceWith": "Ganti dengan",
    "masterSkuLimit": "Master SKU harus dibawah 200 karakter",
    "numberOfVariants": "Jumlah varian",
    "yourCurrentPackageDoesNotHave": "Paket Anda saat ini tidak memiliki izin untuk beroperasi, silakan berlangganan paket baru atau hubungi customer service untuk upgrade  paket",
    "yourNumberOfOrdersHasExceeded": "Jumlah pesanan Anda telah melebihi batas paket Anda saat ini (maksimum {X}), silakan berlangganan paket baru atau hubungi customer service untuk upgrade paket.",
    "salesTaxAmountTip": "Pengeluaran pajak untuk satu produk",
    "purchaseTimeTip": "Jangka waktu dari pengiriman pembelian sampai pembelian masuk",
    "sourceUrlTip": "Sumber produk, seperti link supplier atau jika produk discrape dari link sumber",
    "chatLimitTip2": "Kewenangan Anda telah melebihi batas paket, silakan buka Ginee OMS untuk menyesuaikan sebelum melanjutkan penggunaan Ginee Chat",
    "chatNoResourcesLimitTip": "Jumlah toko atau akun staf Anda telah melebihi batas paket langganan, silakan buka Ginee OMS untuk menyesuaikan paket langganan sebelum melanjutkan penggunaan Ginee Chat",
    "chatNopermissionLimitTip": "Fitur Ginee Chat tidak termasuk dalam paket akun Anda, silakan hubungi customer service untuk meningkatkan paket langganan Anda",
    "packageLimit": "Limitasi Paket",
    "masterProductpublishToTheStore": "Master Produk/Tampilkan ke toko",
    "masterProductmassEdit": "Master Produk/Edit Massal",
    "stockStatusTip": "Tampilan status stok di kolom ini: gudang, status sinkronisasi stok, informasi jenis Master SKU.\nGudang: Gudang tempat Master SKU ini ditempatkan.\nStatus sinkronisasi stok: Apakah Master SKU telah diaktifkan untuk sinkronisasi penambahan atau penurunan stok, \"status sinkronisasi stok\" dari Master Produk yang baru ditambahkan akan dinonaktifkan secara default.\nTanda Master SKU kombinasi: Jika Master SKU ini adalah gabungan Master SKU, tanda \"kombinasi\" akan ditampilkan.",
    "synchronizationIsNotEnabledYouNeed": "Non-Aktif: Sinkronisasi stok tidak diaktifkan\nSemua persyaratan yang diperlukan untuk aktivasi belum terpenuhi:",
    "synchronizationIsEnabledStock": "Aktif: Sinkronisasi Stok telah aktif\nSemua persyaratan yang diperlukan untuk aktivasi telah dipenuhi:",
    "stockSynchronizationStatusOfTheMaster": "Jika status sinkronisasi stok Master SKU tidak diaktifkan, maka Master SKU tidak akan mengaktifkan fitur sinkronisasi penambanan atau pengurangan stok.",
    "stockSynchronizationStatusOfTheCombined": "Jika status sinkronisasi stok dari MSKU kombinasi tidak diaktifkan, MSKU kombinasi tidak akan secara otomatis menghitung stok yang tersedia berdasarkan Master SKU yang dibundel, dan sinkronisasi stok tidak akan diaktifkan.",
    "open": "Aktif",
    "settingsManagementForAuthorizedThirdpartyAccounting": "Manajemen konfigurasi untuk software akuntansi pihak ketiga yang telah terintegrasi",
    "accountingSettings": "Pengaturan Akuntansi",
    "addedBy": "Ditambah Oleh",
    "timeAdded": "Waktu Penambahan",
    "updater": "Diperbarui Oleh",
    "checkLog": "Periksa Catatan Operasi",
    "accurateOrder": "Pesanan Accurate",
    "accurateProduct": "Produk Accurate",
    "failedToGetChannelsLabelUrl": "Gagal mendapatkan label pengiriman channel, silahkan dipush lagi",
    "bindWarehouse": "Ikat Gudang",
    "Prohibited": "Diharamkan",
    "interval": "Interval",
    "productSales": "Penjualan Produk",
    "updatetime": "Waktu Pembaruan",
    "masterProductCreationTime": "Waktu Pembuatan Master Produk",
    "channelProductCreationTime": "Waktu Pembuatan Produk Channel",
    "salesOfDifferentActivityLevelsOverlap": "Penjualan dari tingkat aktivitas yang berbeda tumpang tindih, silakan masukkan kembali",
    "salesRangeOfXCannotBe": "Rentang penjualan {x} tidak boleh lebih tinggi dari {y}",
    "channelProductDimensionActivitySetting": "Pengaturan aktivitas produk channel, saat ini hanya mendukung untuk Shopee",
    "synchronizeallthexxinthewarehousewheremskusarelocatedtochannelskusaccordingtothexxxratiowhenthedefaultruleisenabledmskusthatalreadyexistinotherrulesarenotincluded": "Sinkronkan semua XX di gudang tempat MSKU berada ke SKU channel sesuai dengan rasio XXX. Saat aturan default diaktifkan, MSKU yang sudah ada di aturan lain tidak disertakan",
    "availablestockforotherstoresispushedto": "Stok yang tersedia untuk toko lain didorong ke 0",
    "allExceptxx": "Semua kecuali XX",
    "whentheavailablestockislessthanorequaltoxx": "Ketika stok yang tersedia kurang dari atau sama dengan XX",
    "whenmsku": "Ketika MSKU",
    "stockThreshold": "Ambang Stok",
    "availablestocktransferstock": "Stok Tersedia + Stok Transfer",
    "availablestock": "Tersedia",
    "pleaseSelectawarehouse": "Silakan pilih gudang",
    "addPolicy": "Tambah Kebijakan",
    "pushratio": "Rasio Push",
    "warehousename": "Nama Gudang",
    "pushbyfixedvalue": "Push Berdasarkan Nilai Tetap",
    "pushbybatio": "Push Berdasarkan Rasio",
    "setSyncPolicy": "Atur kebijakan sinkronisasi",
    "setSynchronizationconditions": "Atur kondisi sinkronisasi",
    "selectMsku": "Pilih MSKU",
    "addMsku": "Tambah MSKU",
    "ruleNamecannotbeempty": "Nama aturan tidak boleh kosong",
    "pleaseenterarulename": "Harap masukkan nama aturan",
    "executeRules": "Jalankan Aturan",
    "pushLog": "Riwayat Push",
    "default": "Default",
    "defaultRules": "Aturan Default",
    "storeName1": "Nama Toko",
    "stockType": "Jenis Stok",
    "pushType": "Jenis Dorong",
    "mainStoreStockLocked": "Penguncian Stok Toko Utama",
    "log": "Catatan",
    "addrule": "Tambahkan Aturan",
    "stocklistpromptwhenadjustingstockstockadjustment": "[Daftar Stok] Prompt saat menyesuaikan stok: Penyesuaian stok [(+)/(-)X]",
    "promptwhenaddingstockwithmanualinboundmanualinbound": "Prompt saat menambahkan stok dengan inbound manual: Manual inbound【121133】",
    "whenmanuallypushinginventorymanuallyexecutestockpushrules": "Saat push stok manual: Menjalankan aturan push stok secara manual",
    "promptwhentheordercomesinneworder": "Prompt saat pesanan masuk: Pesanan baru [576512290177977223]",
    "promptwhenorderiscancelledordercanceled": "Prompt saat pesanan dibatalkan: Pesanan dibatalkan [576512290177977223]",
    "promptwhenbindingforthefirsttimeenablestockmonitoringandsyncsettingsthenpushdatatostoresstock": "Prompt saat pengikatan untuk pertama kalinya: Aktivasi stok monitoring dan sinkronisasi, kemudian push data ke stok toko",
    "xxofchannelproductsfailed": "XX produk channel gagal",
    "creationtime": "Dibuat Pada",
    "pushquantity": "Jumlah Push",
    "reasonforchange": "Alasan perubahan",
    "seemore": "Lihat lainnya",
    "imageMasterProduct": "Gambar & Master Produk",
    "partialSuccess": "Sukses Sebagian",
    "storeupdateresults": "Hasil Pembaruan Toko",
    "pleaseentersearchcontent": "Silakan masukkan konten pencarian",
    "storepushrecord": "Riwayat Push Stok",
    "stockValue": "Nilai Stok",
    "isBestSellingItem": "Item Terlaris",
    "isTrendingItem": "Item Trending",
    "isNewItem": "Item Baru",
    "isLowSellingItem": "Item Tidak Laku",
    "isNormalItem": "Item Biasa",
    "mskuXAlreadyExistsInRule": "MSKU: {X} sudah ada di aturan {Y}",
    "ruleNameAlreadyExists": "Nama aturan sudah ada",
    "thereAreDuplicateRuleNameOr": "Terdapat pengulangan pada nama aturan atau Master Produk yang",
    "noteWhenTheInventoryThresholdIs": "Catatan: Saat ambang batas stok diaktifkan, ketika stok di \"Kebijakan Sinkronisasi\" kurang dari atau sama dengan \"Ambang Stok\", stok yang tersedia dari toko lain kecuali toko utama didorong menjadi 0",
    "totalCapitalUsedFromAllWarehouses": "Total modal yang terpakai oleh semua gudang",
    "storeIntegrationIsInvalidPleaseReintegrate": "Integrasi toko tidak sah, silahkan integrasi ulang",
    "mskuAndStoreAreNotBound": "MSKU dan toko tidak terikat",
    "warehouseAndStoreAreNotBound": "Gudang dan toko tidak terikat",
    "warehouseAndMskuAreNotBound": "Gudang dan MSKU tidak terikat",
    "productChannelAndMskuAreNot": "Produk Channel dan MSKU tidak terikat",
    "deleteRule": "Anda yakin ingin menghapus peraturan XX?",
    "ruleNameXChangedToY": "Nama aturan: {X} diubah menjadi {Y}",
    "pushTypeChangeFromFixedValue": "Jenis push: ubah dari push nilai tetap menjadi push rasio, push {X}% dari stok yang tersedia di gudang {X} + gudang {y}",
    "pushTypeChangeFromProportionalPush": "Jenis push: Diubah dari push rasio ke push nilai tetap, nilai tetapnya adalah XXX",
    "warehouseNameWarehouseXHasBeen": "Nama gudang: Gudang {X} diubah menjadi gudang {Y}",
    "addRuleNewRule": "Tambah aturan: Aturan baru",
    "statusChangeStatusHasBeenChanged": "Perubahan status: Status telah diubah dari tidak aktif menjadi aktif",
    "statusChangeStatus": "Perubahan status: Status telah diubah dari aktif menjadi tidak aktif",
    "deleteRuleRuleHasBeenDeleted": "Hapus aturan: Aturan telah dihapus",
    "ruleChangeTheTotalOfStock": "Perubahan aturan: Total stok tersedia di gudang {X} diubah dari {X}% menjadi {Y}%",
    "policyChangeTheAvailableStock": "Perubahan pengaturan: stok tersedia + stok transit dari gudang {X} diubah dari rasio push {X}% menjadi {Y}%",
    "editAddedXMskuThresholdIs": "EDIT: Menambahkan {X} MSKU, ambang batas adalah {X}, pilih toko utama adalah: {Y}",
    "editXMskuThresholdChangedFrom": "Edit: {X} ambang batas MSKU diubah dari {Y} menjadi {Z}",
    "editXMskuMainStoreHas": "Ubah: Toko utama MSKU {X} telah diubah dari {X} menjadi {Y}",
    "editXMskuHasBeenDeleted": "Edit: MSKU {X} telah dihapus",
    "afterDeletionThePolicyNeedsTo": "Setelah penghapusan, kebijakan perlu dikonfigurasi ulang, harap operasikan dengan hati-hati",
    "thirdpartyWarehouseReceptionTime": "Waktu penerimaan gudang pihak ketiga",
    "whetherToReplaceProduct": "Apakah akan mengganti produk",
    "whetherItIsAGift": "Adalah Hadiah",
    "addGiftBForOrder": "Tambah hadiah {B] untuk pesanan {A}, dengan jumlah {C}, dan gudang yang dipilih adalah {D}",
    "orderAWithProductBIs": "Pesanan {A} dengan produk {B} diganti dengan {C], dan gudang yang dipilih adalah {D}",
    "changeSelectedWarehouseCToD": "Ubah gudang {C} menjadi {D} untuk pesanan {A} dengan produk {B}",
    "gift": "Hadiah",
    "specifiedWarehouse": "Gudang yang Ditentukan",
    "specifiedQuantity": "Jumlah yang Ditentukan",
    "massAddForOrdersSpecifiedMsku": "Tambah massal untuk pesanan: MSKU dan jumlah yang ditentukan!",
    "replace1": "ganti",
    "and1": "dan",
    "replaceInBatchForTheSpecified": "Ganti secara masal untuk produk tertentu di dalam pesanan!",
    "changeWarehouseTo": "Ubah gudang menjadi",
    "specifiedProduct": "Produk Tertentu",
    "doWarehouseModificationForSpecifiedProduct": "Melakukan modifikasi gudang untuk produk tertentu di dalam pesanan! (Produk yang dimodifikasi harus dikaitkan dengan gudang yang sesuai)",
    "doWarehouseModificationForAllProduct": "Melakukan modifikasi gudang untuk semua produk yang dipesan! (Produk yang dimodifikasi perlu dikaitkan dengan gudang yang sesuai)",
    "byProduct": "Berdasarkan produk",
    "byOrder": "Berdasarkan pesanan",
    "processingMethod": "Metode Pemrosesan",
    "addGift": "Hadiah",
    "replaceProduct": "Ganti Produk",
    "changeWarehouse": "Ganti Gudang",
    "modifyProductInformation": "Edit Info Produk",
    "weightkg": "Berat(kg)",
    "youMustAddAllTheVariation": "Kamu harus menambahkan/ mengosongkan semua foto variasi.",
    "masterVariationName": "主商品规格名称",
    "operationSuccessPrompt": "Notifikasi operasi berhasil",
    "checkProduct": "Cek Produk",
    "productHasBeenSubmittedAndIs": "Produk sudah disubmit dan menunggu proses dari {channel}, Anda bisa mengecek produk di tab Sedang Diproses, Aktif atau Gagal Ditampilkan",
    "minimumStockLimit": "Stok Minimum Harus antara 0-999,999",
    "noNeedToProvideAnyTransport": "Tidak perlu sediakan layanan pengiriman apa pun, seperti informasi jasa kirim, layanan di tempat, titik pengiriman, dll.",
    "thisTypeCanBeSelectedTo": "Tipe ini dapat dipilih untuk mengirimkan produk yang memiliki berat lebih dari 50kg, atau yang membutuhkan perawatan khusus seperti instalasi.",
    "notShipped": "Tidak Dikirim",
    "sellerShipping": "Dikirim oleh Penjual",
    "shipYourOrderToTheBLIBLI": "Kirim pesanan Anda ke Jasa Kirim yang ditunjuk Blibli. Untuk Sameday / Instan, pesanan akan diambil di titik pengambilan.",
    "shippingUsingBlibliLogisticPartner": "Pengiriman Menggunakan Mitra Logistik Blibli",
    "minimumStock": "Stok Minimum",
    "enterSellingPrice": "Silakan Masukkan Harga Jual",
    "sellingPriceLimit": "Harga Jual  Harus antara  1-100.000.000",
    "originalPrice": "Harga Awal",
    "enterOriginalPrice": "Silakan Masukkan Harga Awal",
    "changeMinimumStockTo": "Edit  Stok Minimum jadi",
    "restrictionsTheProductCannotBeEdited": "Dikarenakan keterbatasan API, produk tidak dapat diedit",
    "unboundMSKU": "Master SKU Tidak Terikat",
    "bindMSKU": "Ikat Master SKU",
    "Country": "Negara/Wilayah",
    "ContinuetoPublish": "Lanjut Tampilkan",
    "CreateTimeNewest": "Waktu Pembuatan Terbaru ke Terlama",
    "CreateTimeOldest": "Waktu Pembuatan Terlama ke Terbaru",
    "notification": "Pemberitahuan",
    "NoBrand": "Tidak Ada Merek",
    "PromotionActivity": "Aktivitas",
    "PromotionCampaign": "Aktivitas",
    "PromotionDuration": "Durasi",
    "PromotionPrice": "Harga Diskon",
    "PromotionOriginalPrice": "Harga Awal",
    "cannot0": "Produkmu tidak bisa dilihat calon pembeli karena stok 0",
    "EditStock": "Edit Stok",
    "ResultsFail": "Operasi dihentikan karena masalah jaringan atau server. Coba lagi nanti.",
    "SomeOperationsFailed": "Beberapa operasi produk gagal",
    "Sort": "Urutkan",
    "MostStock": "Stok terbanyak",
    "LeastStock": "Stok terkecil",
    "Oldest": "Waktu Update Terbaru ke Terlama",
    "Newest": "Waktu Update Terlama ke Terbaru",
    "Within7Days": "Dalam 7 Hari",
    "Yesterday": "Kemarin",
    "Today": "Hari ini",
    "CountDraftFailed": "Produk gagal ditampilkan",
    "CountDraftSuccess": "Produk berhasil ditampilkan",
    "CountRemoveFailed": "Produk gagal dihapus",
    "CountRemoveSuccess": "Produk telah dihapus",
    "CountUnAvailableFailed": "Produk gagal dinonaktifkan",
    "CountUnAvailableSuccess": "Produk telah dinonaktifkan",
    "CountAvailableFailed": "Produk gagal diaktifkan di Toko",
    "CountAvailableSuccess": "Produk telah berhasil diaktifkan di Toko",
    "PartDraftFailed": "Sebagian produk gagal ditampilkan!",
    "DraftFailed": "Gagal ditampilkan",
    "DraftSuccess": "Berhasil ditampilkan!",
    "PartRemoveFailed": "Beberapa produk gagal dihapus",
    "RemoveFailed": "Gagal dihapus!",
    "RemoveSuccess": "Berhasil dihapus!",
    "PartUnAvailableFailed": "Gagal menonaktifkan beberapa produk",
    "UnAvailableFailed": "Gagal menonaktifkan produk",
    "UnAvailableSuccess": "Berhasil menonaktifkan produk",
    "PartAvailableFailed": "Beberapa produk gagal diaktifkan",
    "AvailableFailed": "Gagal mengaktifkan produk",
    "AvailableSuccess": "Berhasil mengaktifkan produk",
    "InProcessDraftPublishModalTitle": "Dalam proses menampilkan produk secara massal di Toko",
    "InProcessRemoveModalTitle": "Menghapus produk di Toko",
    "InProcessDisableModalTitle": "Sedang proses menonaktifkan produk di Toko",
    "InProcessPublishModalTitle": "Sedang proses mengaktifkan produk di Toko",
    "StockRulesInfo": "Stok harus berada diantara {minNum}～999999",
    "ResultsClose": "tutup",
    "ResultsTitle": "Hasil",
    "ApplyToAll": "Terapkan ke semua",
    "MassEditStock": "Edit Massal",
    "SetUnAvailable": "Nonaktifkan",
    "SetAvailable": "Aktifkan",
    "DeletedDesc": "Menghapus Produk di Ginee akan menghapus produk ini di Toko ini.",
    "DeletedTitle": "Anda yakin ingin menghapus produk?",
    "PublicDesc": "Mengaktifkan produk ini di Ginee akan mengaktifkan produk di Toko juga",
    "PublicTitle": "Apa Anda yakin mengaktifkan produk ini?",
    "DisabledDesc": "Menonaktifkan produk ini di Ginee akan menonaktifkan produk di Toko juga",
    "DisabledTitle": "Apa Anda yakin menonaktifkan produk ini?",
    "productTime": "Waktu",
    "ProductSelected": "Produk Dipilih",
    "ProductParentSku": "SKU Induk",
    "ProductNameAndStore": "Nama Produk dan Toko",
    "adjustSyncFailed": "Sinkronisasi Stok Gagal. Silahkan coba lagi.",
    "UpdateStock": "Perbarui",
    "NewStock": "Stok Baru",
    "ModifyStock": "Ubah Stock dalam manajemen Inventaris",
    "CurrentStock": "Stok Sekarang",
    "adjustStock": "Atur Stok",
    "categories": "Kategori",
    "removeList": "Hapus",
    "productStatus": "Status",
    "savedAsdraft": "Berhasil disimpan sebagai draf",
    "pleaseSelect": "Silahkan pilih",
    "ProductDescription": "Deskripsi Panjang",
    "masterCategory": "Kategori Master",
    "ProductImage": "Gambar Produk",
    "defaultPriceError": "Kisaran Harga Default harus diantara 0-1.000.000.000",
    "editDefaultPrice": "Edit Harga Awal",
    "editDescription": "Edit Deskripsi",
    "addAfterName": "Tambahkan setelah nama",
    "addBeforeName": "Tambahkan sebelum nama",
    "weightShouldBeBetweenKg": "Berat Harus antara 0.001-1000",
    "workingHours": "Jam Kerja",
    "shippingSettings": "Pengaturan Logistik",
    "massEditProductStatus": "Edit Massal Status Produk",
    "goToEdit": "Edit Sekarang",
    "theInventoryOfTheMasterProduct": "Stok produk induk dikelola dalam [Manajemen Stok], silakan pergi ke manajemen stok untuk edit stok dari produk yang dipilih",
    "changeMinPurchaseQuantityTo": "Edit Min. Jumlah Pembelian jadi",
    "massEditMinPurchaseQuantity": "Edit Massal min. Jumlah pembelian",
    "massEditDimensionsCm": "Edit Massal Dimensi (cm)",
    "massEditProductName": "Edit Massal Nama Produk",
    "editProductImage": "Edit Gambar Produk:",
    "customDisplayInformation": "Kustomisasi Tampilan Informasi",
    "logisticsChoose": "Pilih Jasa Kirim",
    "selectionOfStoreswarehousesMass": "Pilih massal toko/gudang",
    "toMatchCategoryNeed": "Perlu mappingkan kategori",
    "categoriesMatched": "Kategori sudah di mapping",
    "productsSelected": "Produk dipilih",
    "categoryAttributesShopee": "Shopee Atribut kategori",
    "categoryAttributesEdit": "Atribut kategori",
    "theValueJustEnteredWantIt": "Apa Anda ingin menerapkan data ini pada semua produk dari kategori yang sama?",
    "matchingCategoryAndVariantAttributesProduct": "Setelah pencocokkan atribut kategori dan varian, informasi produk dapat dicocokkan secara otomatis, seperti harga varian, stok, dll.",
    "productInfoSource": "Informasi Sumber Produk",
    "noLogisticOptions": "Tidak ada pilihan Jasa Kriim",
    "sellingPrice": "Harga Jual",
    "productUpdateFailed": "Pembaruan Produk gagal",
    "publishFailed": "Gagal Produk ditampilkan",
    "publishSucceeded": "Berhasil Produk ditampilkan!",
    "massEditMinimumStock": "Edit Massal Stok Minimum",
    "skuLimit": "SKU harus kurang dari 100 Karakter",
    "sku": "SKU",
    "packageSizeCM": "Ukuran Paket(cm)",
    "main": "Utama",
    "maxval": "Maks.{val}",
    "priceBetweenMy": "Harus antara 0.1-1.000.000.000",
    "priceBetweenTh": "Harus antara 1-500.000",
    "priceBetweenVn": "Harus antara 1000-120.000.000",
    "priceBetweenPh": "Harus antara5-1.000.000",
    "priceBetweenId": "Harus antara 99-150,000,000",
    "weightg": "Berat(g)",
    "selectABrandPlease": "Silakan pilih merek",
    "uploadAVariantPicturePlease": "Silakan unggah gambar varian",
    "uploadProductImagePlease": "Silakan unggah gambar produk",
    "enterADescriptionPlease": "Silakan masukkan deskripsi",
    "enterTheProductNamePlease": "Silakan masukkan nama produk",
    "selectTheStatusOptionPlease": "Silakan pilih opsi status",
    "selectPreorderOptionPlease": "Silakan pilih opsi pre-order",
    "enterLogisticsOptionsPlease": "Silakan masukkan opsi logistik",
    "enterThePackageSizePlease": "Silakan masukkan ukuran paket",
    "enterTheWeightPlease": "Silakan masukkan beratnya",
    "enterstock": "Silahkan masukkan stok",
    "enterPrice": "Silahkan Masukkan Harga",
    "enterSKU": "Silahkan Masukkan SKU",
    "theProductHasVariationsIfYour": "Produk memiliki beberapa variasi, jika produk Anda memiliki warna dan ukuran yang berbeda, silakan pilih ini",
    "enterAOptionForExampleRed": "Masukkan Pilih, misalnya: Merah, dll.",
    "addVariationType": "Tambah Tipe Variasi",
    "variationType": "Tipe Variasi",
    "productDescriptionLimit": "Deskripsi Produk harus diantara 6-3500 Karakter",
    "productDescriptionLimitTh": "Deskripsi Produk harus diantara 25- 5000 Karakter",
    "productDescriptionLimitVn": "Deskripsi Produk harus diantara 100- 3000  Karakter",
    "productDescriptionLimitPh": "Deskripsi Produk harus diantara 2- 3000  Karakter",
    "productDescriptionLimitIdMy": "Deskripsi Produk harus diantara 20- 3000  Karakter",
    "selectBrand": "Pilih Merek",
    "ShopeeCategory": "Shopee Kategori",
    "addAttributeValue": "Tambahkan nilai atribut",
    "saveUpdateAndNextStep": "Simpan dan Selanjutnya",
    "productNameLimitVn": "Nama Produk harus diantara 10- 120 Karakter",
    "productNameLimitPh": "Nama Produk harus diantara 20-100 Karakter",
    "productNameLimitOthers": "Nama Produk harus diantara 2-120 Karakter",
    "productNameLimitId": "Nama Produk harus diantara 5-255 Karakter",
    "stocktLimitOhters": "Stok Harus antara 0-999.999.999!",
    "stocktLimit": "Stok Harus antara 0-999,999!",
    "setUnavailable": "Nonaktifkan",
    "setAvailable": "Aktifkan",
    "saveAsDraft": "Simpan sebagai draf",
    "percentageLimit": "Kisaran persentase harus 0-100,000",
    "attributesNo": "Tidak ada atribut",
    "categoryNo": "Tidak ada kategori",
    "categoryAndAttributeApply": "Terapkan kategori dan opsi atribut yang sama ke produk lain dari kategori sumber yang sama ({N})?",
    "detailsView": "melihat rincian",
    "pleaseSetUpLogisticsInThe": "Silakan atur Jasa Kirim di Sellercenter Shopee.",
    "optionMaximum": "Maksimal 20 karakter",
    "typeMaximum": "Maksimal 14 karakter",
    "vietnam": "Vietnam",
    "thailand": "Thailand",
    "philippines": "Philippines",
    "indonesia": "Indonesia",
    "imageMustBeJOGPNGFormat": "Gambar harus dalam format JPG, PNG, maks. 4MB, Resolusi: 300*300px～1024*1024px",
    "imageTip": "API dibatasi, ukuran maksimal adalah 4mb, dan resolusi harus lebih besar dari 300*300px",
    "preOrderLimit": "Menurut persyaratan kategori, waktunya harus {min} hingga {max} hari kerja",
    "preOrderTip": "Star, Star+, Mall mendukung hingga 90 hari kerja",
    "malaysia": "Malaysia",
    "dangerousGoods": "Produk Berbahaya",
    "dangerousGoodsTip": "Mohon isi keterangan Produk Berbahaya dengan tepat. Keterangan Produk Berbahaya yang tidak tepat dapat menambah biaya pengiriman atau gagal pengiriman.",
    "dangerousGoodsOption": "Mengandung baterai/magnet/cairan/bahan mudah terbakar",
    "attributeTip": "Limitasi API, maksimal 5 pilihan",
    "massEditTip1": "Silakan pilih kategori produk dan atribut produk untuk menghindari kegagalan produk untuk dipublikasikan",
    "theMaximumRangeAccordingToApi": "Cakupan maksimum dibatasi sesuai dengan kebutuhan API",
    "requiresToThisInformationToBe": "API mewajibkan bagian ini harus diisi",
    "chooseTheStoreFirstPlease": "Silakan pilih toko terlebih dahulu",
    "variantImageTip": "Ukuran: 4MB, Resolusi: Min. 300*300px(Beritahu saya di grup, Anda dapat bertanya)",
    "variationTypeTip2": "jenis varian sudah ada, silakan masukkan kembali",
    "variationoptionTip1": "Opsi Varian sudah ada, silakan masukkan kembali",
    "applyAttribute": "Terapkan opsi atribut yang sama ke produk lain dalam kategori sumber yang sama({N})?",
    "noBrandBatch": "Apakah Anda yakin untuk mengubah opsi merek dari daftar produk ini menjadi \"Tanpa Merek\"?",
    "noBrandBatchTip": "Karena pembatasan API, hanya pemilihan batch merek sebagai tidak bermerek yang didukung.",
    "massEditBrand": "Merek Edit Massal",
    "previous": "Sebelumnya",
    "emptyMass": "Kosongkan Massal",
    "singapore": "Singapur",
    "canUploadMultiplePicturesAtOnce": "Anda dapat menggunggah banyak gambar sekaligus, tarik dan lepas untuk mengubah urutan gambar",
    "contentToEditClick": "Klik konten untuk edit",
    "Policydetail": "Saat produk yang sama dipublikasikan ke beberapa toko di channel yang sama\nKami sarankan untuk mengubah dan membedakan nama produk dan informasi produk untuk menghindari pelanggaran kebijakan marketplace terkait",
    "PolicyReminder": "Kebijakan",
    "Clonesuccess": "Produk Berhasil Disalin!",
    "copyFailedResult": "Produk gagal digandakan",
    "copyCompete": "Selesai disalin",
    "amountMustBeGreaterThanDiscount": "Nominalnya harus lebih besar dari jumlah diskon",
    "wholeStoreProduct": "Seluruh produk toko",
    "valueMustBeGreaterThanThe": "Nilainya harus lebih besar dari gradien sebelumnya",
    "theSumOfTheAvailableStock": "Jumlah stok yang tersedia di gudang di mana semua MSKU berada, menurut",
    "ratioSyncToAllChannelSku": "Rasio disinkronkan ke seluruh SKU channel",
    "whenthedefaultruleisonmskusthatalreadyexistinotherrulesarenotincluded": "Saat aturan default aktif, MSKU yang sudah ada di aturan lain tidak disertakan",
    "statusDeleted": "Dihapus",
    "statusInactive": "Tidak Aktif",
    "statusActive": "Aktif",
    "optionsDoNotSupportEditAnd": "Opsi tidak dapat diedit dan dihapus karena keterbatasan API",
    "dueToApiRestrictionsLabel": "Karena pembatasan API, { label } dalam tipe variasi di kategori ini harus dipilih.",
    "dueToTheChannelDelayIn": "Karena keterlambatan channel dalam memperbarui data, data yang diedit akan memakan waktu beberapa menit untuk diperbarui, harap bersabar. Jika belum diperbarui, Anda dapat mengklik tombol sinkronisasi secara manual atau pergi ke seller centre untuk memeriksa apakah statusnya dalam peninjauan. Data dalam status peninjauan tidak akan disinkronkan untuk sementara.",
    "deletedProductCannotBeRecoveredAre": "Produk tidak bisa dipulihkan setelah dihapus, yakin menghapus?",
    "canNotInputChineseLanguage": "Tidak bisa menginput Mandarin",
    "areYouSureYouWantToZalora": "Anda yakin ingin menghapus informasi varian ini? Penghapusan akan mengubah struktur SKU, informasi stok dan harga, Anda perlu menginput ulang informasi SKU yang relevan.",
    "failedToPublishGineeProductThat": "Gagal Menampilkan: Produk Ginee yang gagal ditampilkan",
    "publishingGineeProductThatAreBeing": "Sedang Menampilkan: Produk yang sedang ditampilkan Ginee atau produk yang sedang ditinjau di seller center",
    "draftAProductDraftCreatedBy": "Draft: Draft produk yang dibuat di Ginee",
    "suspendedThisIsAProductWith": "Dilarang: Produk dengan status Dimoderasi di seller centre",
    "soldOutThisIsAProduct": "Habis: Produk yang stoknya 0 dengan status Diterbitkan/Nonaktif di seller centre",
    "deactivatedThisIsAProductWith": "Nonaktif: Produk dengan status Nonaktif di seller centre",
    "liveThisIsAProductWith": "Aktif: Produk dengan status Diterbitkan di seller centre",
    "currencyCannotBeEmpty": "Mata uang tidak boleh kosong",
    "failedToUploadQualification": "Gagal mengunggah kualifikasi {0}",
    "failedToUploadImage": "Unggah gambar {0} gagal",
    "thereCannotBeTheSameOption": "Tidak boleh ada opsi yang sama di dalam satu grup spesifkasi",
    "numberOfVariationsInEachGroup": "Jumlah variasi dalam setiap grup harus {0}-{1}",
    "numberOfGroupsVariationMustBe": "Jumlah grup dari jenis variasi harus {0}-{1}",
    "qualificationCertificateOfTheCurrentProduct": "Sertifikat kualifikasi kategori produk wajib diisi dan tidak boleh kosong",
    "forOneTypeOfQualificationCertificate": "Untuk satu jenis sertifikat kualifikasi, jumlah total file dan gambar tidak boleh melebihi {0}",
    "apiLimitationMaxSizeMb": "Limitasi API, ukuran max. 5 MB, dan resolusinya lebih dari 600*600 pixel",
    "totalSkuMustBeBetween": "Total SKU harus antara 1-100",
    "pleaseSelectTheTypeOfVariation": "Silakan pilih jenis variasi",
    "pleaseInputWeight": "Silakan input berat",
    "pleaseInputLengthWidthAndHeight": "Silakan masukan informasi panjang, lebar dan tinggi",
    "reviewing": "Sedang Ditinjau",
    "processing": "Sedang Diproses",
    "reviewFailed": "Gagal Diperiksa",
    "thisCategoryDoesNotSupportCod": "Kategori ini tidak mendukung COD",
    "skuMustBeCharacters": "SKU harus 1-50 karakter",
    "warrantyPolicyMustBeCharacters": "Kebijakan garansi harus 0-99 karakter",
    "priceRangeShouldBe": "Kisaran harga harus diantara 100-100000000",
    "stocktLimitOhtersZalora": "Stok Harus antara 0-99999",
    "weightShouldBeBetweenKgZalora": "Berat Harus antara 0.01-20",
    "heightShouldBeBetweenZalora": "Tinggi Harus antara 1-35",
    "widthShouldBeBetweenZalora": "Lebar Harus antara 1-40",
    "lengthShouldBeBetweenZalora": "Panjang Harus antara 1-60",
    "pleaseUploadASizePicture": "Harap unggah gambar ukuran",
    "onlySupportsImages": "Hany bisa gambar dengan perbandingan 1:1",
    "pleaseEnterAtLeastOneVariation": "Harap masukkan setidaknya satu opsi varian",
    "variationOptionMustBeCharacters": "Opsi variasi harus 1-20 karakter",
    "productDescriptionLimitIdMyZalora": "Deskripsi Produk harus diantara 1-10.000 karakter",
    "fileSizeCannotExceedM": "Ukuran file tidak boleh melebihi 10M",
    "onlySupportToUploadFilesIn": "Hanya dapat mengunggah file dengan format pdf",
    "onlySupportToUploadImagesWith": "Hanya dapat mengunggah gambar dengan format jpg, png, jpeg",
    "imageMustBePxpx": "Gambar harus berukuran 600*600px-20000*20000px",
    "pictureSizeCannotExceedM": "Ukuran gambar tidak boleh melebihi 5M",
    "pleaseUploadAtLeastOneImage": "Silahkan unggah setidaknya satu gambar atau file pdf",
    "supportCOD": "Mendukung COD",
    "sizeChart": "Gambar Ukuran",
    "pleaseSelectTheVariantTypeAnd": "Silakan pilih tipe dan opsi variasi terlebih dahulu",
    "warrantyPolicy": "Kebijakan Garansi",
    "warrantyPeriod": "Masa Garansi",
    "qualificationCertificate": "Sertifikat Kualifikasi",
    "productNameLimitIdZalora": "Nama Produk harus diantara1-188 Karakter",
    "zaloraCategory": "Zalora Kategori",
    "numberOfComments": "Jumlah komentar",
    "keywordType": "Filter",
    "sortType": "Urutkan",
    "number": "Nomor",
    "newRuleMasterProduct": "Aturan baru {0}：Master produk {1}",
    "deleteRuleMasterProduct": "Hapus aturan {0}：Master produk {1}",
    "storePolicyAddPolicyNew": "Kebijakan toko {0}; Tambah kebijakan: kebijakan baru",
    "storePolicyDeletePolicyPolicy": "Kebijakan toko {0}; hapus kebijakan: kebijakan dihapus",
    "ruleNameChangedFromTo": "Nama Aturan: diubah dari{0} menjadi {1}",
    "masterProductNameChangedFrom": "Nama Master Produk: diubah dari{0} menjadi {1}",
    "pushTypeNameChangedFrom": "Nama tipe push: diubah dari{0} menjadi {1}",
    "storePolicyWarehouseNameChanged": "Kebijakan toko {0}; Nama gudang: diubah dari {1} menjadi {2}",
    "storePolicyInventoryTypeName": "Kebijakan toko {0}; Nama tipe stok: diubah dari {1} menjadi {2}",
    "storePolicyStoreNameChanged": "Kebijakan toko {0}; Nama toko: diubah dari {1} menjadi {2}",
    "storeStrategyPushRatioChanged": "Kebijakan toko {0}; push rasio: diubah dari {1} menjadi {2}",
    "storePolicyPushFixedValue": "Kebijakan toko {0}; push fix: diubah dari {1} menjadi {2}",
    "addmskuthresholdIsselectmainstoreIs": "Tambahkan {0} MSKU, ambang batas adalah {1}, pilih toko utama adalah: {2}",
    "mskuhasbeendeleted": "MSKU sudah dihapus",
    "masterproductchangedfromto": "Master produk diubah dari {0} menjadi {1}",
    "mainstorechangedfromto": "Toko utama diubah dari {0} menjadi {1}",
    "thresholdchangedfromto": "Ambang batas ditukar dari {0} menjadi {1}",
    "masterProductActivity": "Aktifitas Master Produk",
    "operationType": "Tipe Operasi",
    "salesTypeId": "Tipe Penjualan",
    "salesRangeId": "Rentang Penjualan",
    "modifyChannelProductPrices": "Edit harga produk channel",
    "areyousureyouwanttodeletetheruleafterdeletedyouneedtoreconfigurethesynchronizationrules": "Anda yakin ingin menghapus aturan? Setelah penghapusan, Anda perlu mengkonfigurasi ulang aturan sinkronisasi",
    "orderPushRecord": "Catatan Push Pesanan",
    "productPushRecord": "Riwayat Push Produk",
    "youHaveNotActivatedStockMonitoring": "Anda belum mengaktifkan \"Monitoring dan Sinkronisasi Stok\"",
    "turnOnNow": "Aktifkan",
    "turnOn": "Aktif",
    "theSalesRangeOfCannot": "Rentang penjualan {} tidak boleh lebih tinggi dari {}",
    "theValueOnTheRightSide": "Nilai di sisi kanan kotak input tidak boleh kurang dari sisi kiri",
    "pushRecord": "Catatan Push",
    "allWarehouse": "Semua Gudang",
    "allStoresExceptCustomStores": "Semua toko kecuali toko khusus: {x}%",
    "pushFailed": "Push Gagal",
    "pushed": "Sudah Dipush",
    "bindwarehouse": "Gudang Terikat",
    "afterTurnOnTheProductsThat": "Setelah diaktifkan, produk yang memenuhi aturan ini akan didorong ke backend toko",
    "afterDeactivationProductsThatMeetThis": "Setelah dinonaktifkan, produk yang memenuhi aturan ini tidak akan didorong lagi ke backend toko",
    "afterTheSettingIsCompleteAverage": "Setelah pengaturan selesai, penjualan harian rata-rata akan dihitung pada hari berikutnya sesuai dengan pengaturan baru",
    "productDescriptionMustBeBetweenMinmaxcharacters": "Deskripsi Produk harus diantara {min}-{max}karakter",
    "repush": "Push Ulang",
    "pullAccurateStock": "Tarik Stok Accurate",
    "accounting1": "Akuntansi",
    "returnManagement": "Manajemen Retur",
    "returnOrderXxxRejectedToRefund": "Pengembalian xxx menolak pengembalian dana kepada pembeli",
    "returnOrderXxxAgreedToRefund": "Pengembalian xxx menyetujui pengembalian dana kepada pembeli",
    "returnOrderXxxRejectedRefundreturnRequest": "Pengembalian xxx menolak permintaan pengembalian dana/barang",
    "returnOrderXxxAcceptedRefundreturnRequest": "Pengembalian xxx menerima permintaan pengembalian dana/barang",
    "returnOrderXxxSuccessfullySyncedThe": "Pengembalian xxx Berhasil Disinkronisasi, Tipe Pengembalian adalah \"xxx\", Status Pemrosesan adalah \"xxx\"",
    "rejectRefund": "Tolak Pengembalian Dana",
    "acceptRefund": "Terima Pengembalian Dana",
    "acceptRequest": "Terima Permintaan",
    "syncReturnOrder": "Sinkronisasi Pengembalian",
    "confirmInbound": "Konfirmasi Inbound",
    "mass": "Massal",
    "returnWarehouse1": "Gudang Retur",
    "returnProduct": "Barang Retur",
    "doYouAgreeToRefund": "Yakin untuk mengembalikan dana?",
    "pleaseSelectRejectionReason": "Pilih alasan penolakan",
    "doYouAcceptBuyersReturnRefund": "Terima permintaan pengembalian barang dan dana?",
    "onceYouAgreeItCannotBe": "Setelah disetujui, maka tidak dapat dibatalkan",
    "doYouAcceptBuyersRefundRequest": "Terima permintaan pengembalian dana?",
    "exportAll": "Export Semua",
    "viewReturnRecords": "Lihat Log Retur",
    "accept": "Terima",
    "isItADisputedOrder": "Apakah pesanan sedang ditinjau ?",
    "inboundNo": "Nomor Inbound",
    "returnWarehouse": "Gudang Pengembalian",
    "returnAwb": "Nomor Resi Pengembalian",
    "returnStatus": "Status Retur",
    "processingDeadline": "Batas Waktu Pemrosesan",
    "processingStatus": "Status Pemrosesan",
    "closed": "Ditutup",
    "finished": "Selesai",
    "inDispute": "Sedang Ditinjau",
    "returnRemark": "Catatan Retur",
    "variantchannelSkumsku": "Variasi/SKU Channel/MSKU",
    "returnNo": "Nomor Retur",
    "disputedOrder": "Pesanan yang disengketakan",
    "cod": "COD",
    "separateMultipleInfoWithSpace": "Pisahkan beberapa informasi dengan spasi",
    "returnRefund": "Retur Barang & Dana",
    "returnOrder": "Pesanan Retur",
    "xRuleHasBeenDeletedPlease": "{x} aturan telah dihapus, harap periksa catatan di log operasi",
    "checknow": "Cek Sekarang",
    "pushTime": "Waktu Push",
    "theActivityLevelOfTheChannel": "Tingkat aktivitas produk saluran telah diatur dan tidak dapat diatur berulang kali.",
    "deleteMasterProduct": "Hapus Master Produc",
    "pleaseEnterRemarks": "Silahkan masukkan tambahan",
    "refundType": "Tipe Pengembalian Dana",
    "refundOnly": "Hanya Pengembalian Dana",
    "refundStatus": "Status Pengembalian Dana",
    "returnInformation": "Informasi Retur",
    "refundReason": "Alasan Pengembalian Dana",
    "returned": "Sudah Dikembalikan",
    "returning": "Sedang Dikembalikan",
    "waitingBuyerToReturn": "Menunggu Pembeli Melakukan Pengembalian",
    "returnRecord": "Log Retur",
    "refundList": "Pengembalian Dana",
    "mskuBindingStatus": "Status Pengikatan MSKU",
    "pleaseConfirmTheReturnedProductBefore": "Silakan konfirmasi produk yang diretur sebelum diinbound ke gudang. Jika ada kesalahan, mohon sinkronkan pesanan sebelum dikembalikan ke gudang.",
    "combinedProductSales": "Penjualan Sub-Produk",
    "includingCombineProductSalesAndIndividual": "Termasuk penjualan produk kombinasi dan penjualan individu dari sub produk produk gabungan",
    "countTheSalesOfCombinedProducts": "Menghitung penjualan produk kombinasi yang dijual secara individual dan dalam bundel",
    "pleaseSelectTheDataToBe": "Silakan pilih data untuk diexport",
    "salesOfIndividuallySoldItems": "Penjualan Produk Tunggal",
    "salesOfProductsSoldByThe": "Penjualan Produk Gabungan",
    "combinedProductMsku": "MSKU Produk Yang Dikombinasi",
    "combinationProductMsku": "MSKU Produk Kombinasi",
    "combinedProductName": "Nama Produk Yang Dikombinasi",
    "mainCommoditySalesAnalysis": "Analisis Penjualan Master Produk",
    "productDetailsOfThePortfolio": "Detail Produk Yang Dikombinasi",
    "combinationProductDetails": "Detail Produk Kombinasi",
    "combinedProduct": "Produk Yang Dikombinasikan",
    "exportChoice": "Export Yang Dipilih",
    "combinationProducts": "Produk Kombinasi",
    "refundRate": "Tingkat pengembalian",
    "refundAmount": "Nominal Refund",
    "refundQuantity": "Jumlah Retur",
    "refundOrderQuantity": "Jumlah Pesanan Dikembalikan",
    "numberOfFailedOrders": "Jumlah pesanan gagal",
    "numberOfReturnedOrder": "Jumlah pesanan dikembalikan",
    "numberOfCanceledOrder": "Jumlah pesanan dibatalkan",
    "numberOfShippedOrders": "Jumlah pesanan diterima",
    "numberOfOrdersInDelivery": "Jumlah pesanan dalam pengiriman",
    "numberOfReadyToShipOrder": "Jumlah pesanan menunggu pengiriman",
    "numberOfPaidOrder": "Jumlah pesanan lunas",
    "numberOfPendingPaymentOrder": "Jumlah pesanan menunggu pembayaran",
    "effectiveSalesAmount": "Jumlah Penjualan Efektif",
    "totalSales": "Total Penjualan",
    "totalProductSales": "Total Penjualan Produk",
    "relatedOrderQuantity": "Jumlah Pesanan Terkait",
    "msku": "MSKU",
    "creationTime": "Dibuat Pada",
    "keywords": "Kata Kunci",
    "isACombinationProduct": "Apakah produk kombinasi",
    "salesQTY": "Penjualan",
    "failedGenerateInboundList": "Gagal membuat daftar inbound",
    "someMasterProductDoNotExist": "Beberapa Master Produk tidak ada di gudang yang dipilih",
    "masterProductIsEmptyPleaseRebind": "Master Produk kosong, harap ikat ulang Master Produk dan coba lagi",
    "refundInformationNotCompletePleaseRefresh": "Informasi pengembalian dana tidak lengkap, harap refresh halaman",
    "informapping": "informasi dicocokkan",
    "mappingtip": "Pilih Kategori dan Tipe Variasi atau Anda tidak dapat mengisi informasi variant (cth: stok, harga, dll)",
    "Mappingcategory": "Kategori Pemetaan",
    "tiktokCategoryAttributes": "Tiktok Atribut kategori",
    "enterBetween": "Input antara 1-60",
    "salesInformation": "Informasi Penjualan",
    "tikTokCategory": "TikTok Kategori",
    "tiktokfailedToPublishGineeProductThat": "Gagal Menampilkan: Produk Ginee yang gagal ditampilkan",
    "tiktokpublishingGineeProductThatAreBeing": "Sedang Menampilkan: Produk yang sedang ditampilkan Ginee atau produk yang sedang ditinjau di seller center",
    "tiktokdraftAProductDraftCreatedBy": "Draft: Draft produk yang dibuat di Ginee",
    "tiktoksuspendedThisIsAProductWith": "Dilarang: Produk dengan status Dimoderasi di seller centre",
    "tiktoksoldOutThisIsAProduct": "Habis: Produk yang stoknya 0 dengan status Diterbitkan/Nonaktif di seller centre",
    "tiktokdeactivatedThisIsAProductWith": "Nonaktif: Produk dengan status Nonaktif di seller centre",
    "tiktokliveThisIsAProductWith": "Aktif: Produk dengan status Diterbitkan di seller centre",
    "tiktokskuMustBeCharacters": "SKU harus 1-50 karakter",
    "tiktokwarrantyPolicyMustBeCharacters": "Kebijakan garansi harus 0-99 karakter",
    "tiktokpriceRangeShouldBe": "Kisaran harga harus diantara 100-100000000",
    "tiktokstocktLimitOhters": "Stok Harus antara 0-99999",
    "tiktokweightShouldBeBetweenKg": "Berat Harus antara 0.01-100",
    "tiktokheightShouldBeBetween": "Tinggi Harus antara 1-35",
    "tiktokwidthShouldBeBetween": "Lebar Harus antara 1-40",
    "tiktoklengthShouldBeBetween": "Panjang Harus antara 1-60",
    "tiktokproductDescriptionLimitIdMy": "Deskripsi Produk harus diantara 1-10.000 karakter",
    "productNameMinMaxCharacters": "Nama Produk harus diantara {min}-{max} Karakter",
    "mpType": "Status Pengikatan MSKU",
    "updateDates": "Waktu Pembaruan",
    "returnOrderXHasSuccessfullyCreated": "Daftar inbound retur telah berhasil dibuat untuk pesanan retur {x}, dan nomor daftar inboundnya adalah {x}",
    "createInboundList": "Buat Daftar Inbound",
    "noteThereIsADelayIn": "Catatan: Ada penundaan dalam pembaruan status pesanan saluran dan label pengiriman, yang dapat menyebabkan push gagal. Jika push gagal, silakan coba push lagi di daftar gagal.",
    "theOrderIsBeingPushedTo": "Pesanan sedang dipush ke gudang pihak ketiga. Tolong jangan ulangi operasi dan silakan buka daftar pesanan untuk memeriksa kemajuan push",
    "pushTaskSuccessfullyCreated": "Tugas push berhasil dibuat",
    "refundLog": "Catatan Pengembalian Dana",
    "refundNo": "Nomor Refund",
    "refundOrder": "Pesanan Refund",
    "theServiceIsBusyOrYou": "Layanan sedang sibuk, atau Anda mungkin telah mencapai permintaan telah mencapai batas, harap lakukan push secara manual",
    "cancellationIsNotAllowedWhilePush": "Pembatalan tidak diperbolehkan saat proses push sedang berlangsung",
    "synchronizeAllMskuInWarehouseTo": "Sinkronkan semua MSKU di gudang ke semua channel SKU sesuai dengan rasio {x} dan {y}",
    "youDoNotHavePermissionAt": "Anda tidak memiliki izin saat ini, harap hubungi administrator untuk mengonfigurasi izin di [Pengaturan Peran]",
    "tikiproductsCreatedInGineeExcludingProducts": "Produk Draf yang dibuat di Ginee, tidak termasuk produk di Pusat Penjual - Draf Status",
    "tikiproductsFailedPublishByGinee": "Produk yang gagal Ginee tampilkan",
    "tikiproductsPublishingByGinee": "Produk yang sedang Ginee tampilkan",
    "tikiproductLockedInSellerCenterApi": "Tiki sementara tidak memiliki produk yang dilarang",
    "tikiamongActiveAndInactiveProductsAll": "Di antara produk aktif dan tidak aktif, semua varian produk yang jumlah persediaannya = 0;\nKarena masalah interface API, produk FBT untuk sementara akan ditempatkan pada daftar produk habis",
    "tikinonactiveProductsInSellerCenter": "Produk yang statusnya OFF dan terkunci di seller center",
    "tikiactiveProductInSellerCenter": "Produk Aktif di Seller Center",
    "skuProductCode": "SKU (Kode Product)",
    "lowstockthresholdValueCannotHigherThan": "Nilai  lowStockThreshold tidak boleh lebih tinggi dari jumlah stok",
    "descriptionUpToCharacters": "Deskripsi maksimal  {max}  karakter",
    "skuPromotionPriceCannotHigherThan": "Harga promosi SKU tidak boleh lebih tinggi dari harga aslinya, harap edit",
    "skuPromotionStartTimeCannotBe": "Waktu mulai promosi SKU tidak boleh kosong",
    "skuPromotionEndTimeCannotBe": "Waktu berakhir promosi SKU tidak boleh kosong",
    "skuLowstockthresholdValueCannotHigherThan": "Nilai SKU lowStockThreshold tidak boleh lebih tinggi dari jumlah stok",
    "variantSkuCannotBeEmpty": "Varian SKU tidak boleh kosong",
    "productSkuCannotBeEmpty": "SKU produk tidak boleh kosong",
    "thisSkuAlreadyExistsInThis": "SKU ini:${0} sudah ada di toko ini, mohon edit kembali",
    "productMainImageCannotBeEmpty": "Gambar utama produk tidak boleh kosong",
    "promotionPriceCannotBeHigherThan": "Harga promosi tidak boleh lebih tinggi dari harga aslinya",
    "theInputRangeFromMin": "Rentang dari { min } - { max}",
    "addVariants": "Tambah Varian",
    "shippingClass": "Kelas pengiriman",
    "crosssells": "Cross-sells",
    "upsells": "Upsells",
    "enableThisToOnlyAllowOne": "Aktifkan ini untuk mengizinkan hanya salah satu dari item ini yang dibeli dalam satu pesanan",
    "soldIndividually": "Dijual satuan",
    "lowStockThreshold": "Ambang batas stok rendah",
    "allowBackorders": "Izinkan pemesanan backorders",
    "enableStockManagementAtProductLevel": "Aktifkan manajemen stok di tingkat produk",
    "manageStock": "Kelola stok",
    "taxClass": "Kelas pajak",
    "taxStatus": "Status pajak",
    "usedForVariations": "Digunakan untuk variasi",
    "visibleOnTheProductPage": "Terlihat di halaman produk",
    "thisIsAFeaturedProduct": "Ini adalah produk unggulan",
    "showSharingButtons": "Tampilkan tombol Bagikan",
    "showLikes": "Tampilkan Like",
    "likeAndShares": "Suka dan Bagikan",
    "hidden": "Tersembunyi",
    "searchResultsOnly": "Hanya hasil pencarian",
    "shopOnly": "Hanya toko",
    "shopAndSearchResults": "Toko dan hasil pencarian",
    "catalogVisibility": "Visibilitas katalog",
    "heightMinMax": "Tinggi Harus antara {min}-{max}",
    "widthMinMax": "Lebar Harus antara {min}-{max}",
    "lengthMinMax": "Panjang Harus antara {min}-{max}",
    "weightMinMax": "Berat Harus antara{min}-{max}",
    "pleaseEnterProductDescription": "Silakan Masukkan Deskripsi Produk",
    "productNameMinMax": "Nama Produk harus diantara{min}-{max} Karakter",
    "variantPicture": "Gambar Varian",
    "productImageMax": "Gambar produk maks. {number}",
    "promotionTime": "Waktu Promosi",
    "promotionPrice": "Harga Promosi",
    "options": "Opsi",
    "variantType": "Tipe Varian",
    "productAttributes": "Atribut Produk",
    "productShortDescription": "Deskripsi Pendek Produk",
    "productTag": "Produk Tag",
    "updatedateasc": "Waktu Update Terlama ke Terbaru",
    "updatedatedesc": "Waktu Update Terbaru ke Terlama",
    "createdateasc": "Waktu Pembuatan Terbaru ke Terlama",
    "createdatedesc": "Waktu Pembuatan Terlama ke Terbaru",
    "withoutmp": "Tanpa Master SKU",
    "withmp": "Ikat Master SKU",
    "generateInventorySKU": "Hasilkan SKU inventaris",
    "onBackorder": "Dalam order tunda",
    "productNameAndStore": "Nama Produk&Toko",
    "sync": "Sinkronkan",
    "inStock": "Tersedia",
    "soldOut": "Stok habis",
    "disabled": "Nonaktif",
    "china": "China",
    "wooPublishStatus": "Di toko, produk Pending ditampilkan di tab IN Process IN",
    "wooActiveStatus": "Di toko, produk yang diterbitkan ditampilkan di tab langsung",
    "wooDeactiveStatus": "Di toko, produk pribadi akan ditampilkan di tab nonaktif di Ginee.",
    "wooDraftStatus": "Di toko, produk dalam draf akan ditampilkan di tab Ginee draf.",
    "inProcess": "Sedang diproses",
    "stockStatus": "Status stok",
    "externalProduct": "Produk External",
    "groupProduct": "Grup Produk",
    "variable": ">1 varian",
    "simpleVariation": "1 varian",
    "editStatusError": "Silakan klik tombol Konfirmasi terlebih dahulu, lalu klik Tampilkan Ke Toko",
    "productInfoSetting": "Pengaturan Informasi Produk",
    "choiceMskuTip": "Setelah memilih Master SKU, sistem akan memasukkan informasi harga dan stok dari Master SKU, dan mengikatkan informasi SKU Blibli ke Master SKU.",
    "categoryMatch": "Cocokkan Kategori",
    "variationLimit": "Kategori yang dipilih tidak mendukung penambahan variasi",
    "clickToEdit": "Klik pada informasi di tabel untuk mengedit.",
    "whatsInTheBox": "Apa yang didalam kemasan",
    "MinPurchaseQuantity": "Jumlah pembelian Min.",
    "ProductDescriptionShouldBeCharacters": "Deskripsi Panjang harus terdiri dari 6-25000 karakter",
    "chooseStoreLimit": "Pilih setidaknya 1 Toko tujuan untuk salin produk",
    "replaceWith38": "Ganti dengan",
    "addBetweenTheDescription": "Tambahkan di antara deskripsi",
    "addAtTheEndOfThe": "Tambahkan di akhir deskripsi",
    "addAtTheStartOfThe": "Tambahkan di awal deskripsi",
    "addBetweenName": "Tambahkan di antara nama",
    "editProductName": "Edit Nama Produk",
    "theFileWillOnlyBeReserved": "File hanya akan disediakan selama 7 hari, silakan periksa atau unduh tepat waktu.",
    "theResultOfThePublishWill": "Hasil Ditampilkan akan disinkronkan di [Produk Master - Riwayat Operasi Massal]",
    "successfulQtyAllQty": "Jumlah Sukses / Jumlah Total",
    "publishing": "Menampilkan",
    "taskStatus": "Status Tugas",
    "publishedStoreStoreStore": "Ditampilkan ke Toko: Toko 1, Toko 2",
    "content": "Konten",
    "creator": "Pembuat",
    "taskType": "Tipe Tugas",
    "allPublishFailed": "Semua gagal ditampilkan",
    "somePublishFailed": "Beberapa gagal ditampilkan",
    "allPublished": "Semua berhasil ditampilkan",
    "productsWereSuccessfullyPublished": "29 produk berhasil ditampilkan",
    "publishingToChannelStores": "Menampilkan ke Toko Channel",
    "massEditOfShopeesCategoryAttribute": "Edit Massal atribut kategori Shopee",
    "matchTokopediaVariantType": "Cocokan dengan Tipe Varian Tokopedia",
    "matchLazadaVariantType": "Cocokan dengan Tipe Varian lazada",
    "masterProductVariantType": "Tipe Varian Produk Master",
    "massEditProductDescription": "Edit Massal Deskripsi Produk",
    "shopeeCategoryAttribute": "Atribut kategori Shopee",
    "productNameInThisStoreIs": "Nama Produk di Toko ini telah ada",
    "partOfTheMasterProductIs": "Ada Informasi Produk Master yang kosong, lengkapi sebelum ditampilkan",
    "chooseACountry": "Pilih Negara/Wilayah",
    "max11": "Maks. 1000",
    "massEditWeightG": "Edit Massal Berat (G)",
    "max7": "Maks. 500.000",
    "empty": "Kosongkan",
    "editLogisticsMass": "Edit Massal Jasa Kirim",
    "editBliblidraftmass": "Blibli/Edit Massal",
    "categoryAttributesBlibli": "blibli Atribut kategori",
    "copyMass": "Salin Massal",
    "categoryChoose": "Pilih Kategori",
    "matchCategoryBlibli": "Mapping Kategori Blibli",
    "storeWarehouse": "Toko/Gudang",
    "buyable": "Dapat Dibeli",
    "MinimumStock": "Stok Minimum Harus antara 0-999,999",
    "originalPriceLimit2": "Harga Awal harus lebih besar dari Harga Jual",
    "sellingPriceLimit2": "Harga Jual tidak boleh lebih dari Harga Awal",
    "originalPriceLimit": "Harga Awal Harus antara  1-100.000.000",
    "enterYoutubeLinkForExampleYouTubecomipadview": "Masukkan tautan YouTube, misalnya: youtube.com/ipadview",
    "youtubeVideoLink": "Tautan Video YouTube",
    "exampleTheBatteryLifeIsUp": "Contoh: Baterai tahan hingga 10 jam",
    "sellingPoint": "Poin Penjualan",
    "continueToAddProducts": "Terus tambahkan produk",
    "jdChannelIsUnderReviewCheck": "Saluran blibli sedang ditinjau. Anda dapat cek produk di list bagian In Process, Update Failed, atau Live",
    "failedReasons": "Alasan gagal",
    "productsUpdateSucceeded": "Pembaruan Produk berhasil",
    "changeSKUTo": "Edit SKU jadi",
    "massEditSellingPrice": "Edit Massal Harga Jual",
    "massEditOriginalPrice": "Edit Massal Harga Awal",
    "massEditSku": "Edit Massal SKU",
    "editVariantImage": "Edit Gambar Varian:",
    "multipleImagesCanBeUploadedAt": "Anda dapat upload beberapa foto secara bersamaan. minimal 600 x 600pixel ，hingga 4MB (maks 8 foto)",
    "shouldBeBetween": "Harus antara 0-1.000.000.000",
    "optionsnLimit": "Opsi harus kurang dari 20",
    "bigItem": "Barang Besar",
    "smallItem": "Barang Kecil",
    "qualitydays": "Waktu garansi kualitas",
    "afterSale": "Layanan Aftersale",
    "Min1": "Min.1",
    "minpurchasePerOrder": "Jumlah Pembelian Minimum",
    "pleaseEnterProductName": "Silakan Masukkan Nama Produk",
    "blibliCategory": "BliBli Kategori",
    "partOfTheProductIs": "Menurut aturan Channel toko, beberapa informasi di  Produk wajib diisi. Silahkan isi informasi yang diperlukan sebelum ditampilkan di toko.",
    "blibliShouldBeBetweenA": "Harus antara 0.001-999.999",
    "blibliWeightLimit": "Harus antara 1-999,999!",
    "blibliStocktLimit": "Stok Harus antara 0-999,999!",
    "blibliImageSizeLimit": "Resolusi gambar minimal 600 x 600 pixel ，hingga 4MB",
    "serviceErrorPleaseContactGineeTechnology": "Kesalahan layanan, silakan hubungi Ginee Technology",
    "minPurchaseLimit": "Min. Jumlah Pembelian harus 1-1000,000,000",
    "enterThenChange": "Masukkan angka terlebih dahulu baru ganti satuan",
    "goToTheBlibliSellerCenter": "Silakan ke Blibli Seller Center untuk melengkapi alamat gudang sebelum memilih jasa kirim",
    "regularStoreSupport": "Mendukung Toko Reguler",
    "toApiRestrictionsSomeVariantOption": "Karena pembatasan API, beberapa opsi varian tidak dapat dikloning. Silakan tambahkan jika perlu.",
    "theCategoryAndVariantTypeApply": "Apakah kategori dan jenis varian berlaku untuk semua produk dari kategori Sumber yang sama (Kategori Sumber： xxx/xxxx/xxxx dari situs yang sama)?",
    "toApiRestrictionsSomeFieldsDo": "Karena pembatasan API，Beberapa bidang tidak mendukung pengeditan",
    "shippingMethod": "Metode Pengiriman",
    "backToProductList": "Kembali ke Daftar Produk",
    "chooseTheShippingMethodFirstPlease": "Silakan pilih opsi pengiriman terlebih dahulu",
    "needToChooseNo": "Tidak perlu dipilih",
    "fAq1": "Blibli: Bagaimana cara menambah atau mengedit produk?",
    "fAq2": "Blibli: Bagaimana cara mengkloning produk?",
    "fAq3": "Blibli: cara mengedit produk secara massal",
    "synchronizationForThisProductHasBeen": "Sinkronisasi Stok telah diaktifkan untuk produk ini, silakan ubah stok pada halaman Manajemen Stok",
    "productDeleting": "Sedang Menghapus Produk",
    "operationWillNotDeleteTheProduct": "Pengaturan ini tidak akan menghapus produk di Seller Center",
    "deleteTip": "Karena batasan API, hanya produk dalam draft, sedang diproses, dan gagal ditampilkan yang dapat dihapus.",
    "productOrVariationImage": "Gambar Produk/ Variasi",
    "imageTip2": "Produk tanpa varian, gambar mengacu pada gambar produk, dan produk dengan varian mengacu pada gambar varian",
    "blibliSkuLimit": "SKU harus kurang dari 50 Karakter",
    "exampleTheAvailableInventoryOfWarehouse": "Contoh: Stok yang tersedia di gudang A adalah 100, stok tersedia di gudang B adalah 200, gudang yang dipush adalah gudang A+B, dan rasio push adalah 50%, maka stok yang didorong ke toko target C adalah 150",
    "exampleThePushFixedValueIs": "Contoh: Nilai push tetap adalah 100, toko target push adalah A, dan ketika push dijalankan, stok yang didorong ke toko A adalah 100",
    "ProductStatus": "Status Produk",
    "productsThatFailToBePushed": "Produk yang gagal di-push tidak boleh di-push lagi, mohon hubungi staff ECCANG WMS untuk meninjau produk terlebih dahulu",
    "returnTime": "Waktu Pengembalian",
    "theProductHasNotPassedThe": "Produk belum lulus peninjauan",
    "shippingFeePaidBySeller": "Ongkos Kirim Ditanggung Penjual",
    "skucanonlybenumberslettersandhyphenandthemaximumcharacters": "SKU hanya boleh berupa angka, huruf, dan tanda hubung,dan maksimal 30 karakter.",
    "skuNameMaxCharactersOnly": "Nama SKU max. 50 karakter, hanya dapat diisi bahasa Cina dan Inggris, angka, tanda hubung, dan garis bawah",
    "productThatFailToPassThe": "Produk yang tidak lolos peninjauan ECCANG WMS akan gagal dikirim",
    "skuCanOnlyBeInUppercase": "SKU hanya boleh dalam huruf besar",
    "afterFailedToSendTheInbound": "Setelah gagal mengirim daftar inbound, maka tidak mendukung untuk dipush kembali",
    "productEffectiveSales": "Penjualan Efektif Produk",
    "pleaseNote": "Perhatian:",
    "dueToTheLimitationOfThe": "Karena keterbatasan ECCANG WMS API, SKU maksimal 30 karakter dan hanya bisa berupa angka, huruf, dan tanda hubung.",
    "someOrdersAreNotAssignedWith": "Beberapa pesanan belum ditetapkan sub-logistiknya, harap atur ulang pengiriman setelah sinkronisasi",
    "ordersWithoutSublogisticsAreNotSupported": "Pesanan tanpa sub-logistik tidak dapat dikirim, harap sinkronkan pesanan untuk mendapatkan sub-logistik",
    "waitingForDistributionLogisticsPleaseSynchronize": "Menunggu distribusi logistik oleh channel, mohon lakukan sinkronisasi",
    "productInformationSettingError": "Pengturan informasi produk eror",
    "userAddressInformationError": "Informasi alamat pengguna eror",
    "notInShippingAddress": "Alamat tidak dalam jangkauan pengiriman",
    "deliveryFailure": "Kegagalan pengiriman (kegagalan pengiriman karena pengguna tidak menjawab telepon atau alamat tidak cocok)",
    "logisticsCannotSupportDistribution": "Logistik tidak dapat melakukan pengiriman (misalnya banjir saat musim hujan)",
    "theMerchantSentTheWrongProduct": "Penjual mengirim produk yang salah",
    "productDamage": "Produk rusak (barang rusak sebelum keluar)",
    "productDescriptionDoesNotMatch": "Deskripsi produk tidak sesuai",
    "businessAndUserPhoneToCancelOrder": "Pedagang bernegosiasi dengan pengguna untuk membatalkan pesanan",
    "goodsDamageDuringLogisticsDelivery": "Produk hilang/rusak saat pengiriman",
    "theUserAskedToCancelTheOrder": "Pengguna meminta untuk membatalkan pesanan",
    "logisticsAndDistributionCannotContactTheRecipient": "Pihak logistik tidak dapat menghubungi penerima",
    "incompleteRecipientAddress": "Alamat penerima tidak lengkap",
    "thirdPartyMerchantsHaveNoInventory": "Merchant pihak ketiga tidak memiliki stok",
    "outOfDeliveryRange": "Di luar jangkauan pengiriman",
    "itIsOutOfStockAndCannotBeTraded": "Barang habis dan tidak dapat dilakukan transaksi",
    "theBuyerSInformationIsFilledInWrong": "Informasi yang diisi salah silahkan ambil foto kembali",
    "latePayment": "Pembayaran terlambat",
    "maliciousBuyers": "Pembeli berbahaya/penipuan",
    "theBuyerHasNoSincerityToCompleteTheTransaction": "Pembeli tidak bersungguh-sungguh ingin menyelesaikan transaksi",
    "theBuyerMadeAMistakeOrRemake": "Pembeli melakukan kesalahan",
    "unableToContactBuyer": "Tidak bisa menghubungi pembeli",
    "otherReasons": "Alasan lain",
    "unableToCompletePayment": "Tidak dapat menyelesaikan pembayaran/tidak menerima kode pembayaran",
    "thePaymentMethodNeedsToBeChanged": "Metode pembayaran harus diganti",
    "commodityPriceReduction": "Harga produk turun",
    "wrongAddressInformation": "Informasi alamat salah",
    "wrongProductInformation": "Informasi prdouk salah",
    "iDonTWantItAnymore": "Berubah pikiran, tidak mau lagi",
    "goodsOutOfStock": "Produk habis",
    "synchronizeAllMskusUnderXTo": "Sinkronkan semua MSKU di {x} ke {w} oleh {y} dan menurut {z}",
    "pushInProcess": "Sedang push",
    "waitingToPush": "Menunggu Dipush",
    "rdWarehousePushStatus": "Status Push Gudang Pihak ke-3",
    "pushStatus": "Status Push",
    "printable": "Bisa Dicetak",
    "productInboundStockCannotBeNegative": "Stok produk inbound tidak boleh negatif",
    "andAccordingToZ": "dan berdasarkan {z} rasio akan disinkronisasikan ke {w}",
    "synchronizeallmskuunder": "Semua MSKU di {x} dengan {y}",
    "pushSucess": "Push Berhasil",
    "stockPushRuleaddRule": "Aturan push stok/tambah aturan",
    "peraturanTolakStokubahSuai": "Aturan push stok/edit aturan",
    "peraturanTolakStoklihatPerincian": "Aturan push stok/lihat detail",
    "negativeInventoryCannotBeManipulatedInto": "Persediaan negatif tidak dapat dimanipulasi menjadi stok",
    "channelCategoryAttributes": "{channelName} Atribut kategori",
    "switchingMskuRequiresReselectingWarehouseInformation": "Perlu memilih gudang kembali jika mengganti MSKU",
    "pleaseAuthorizeTheStoreFirst": "Silahkan integrasikan toko terlebih dahulu",
    "thereIsNoAlternativeStorePlease": "Tidak ada toko yang bisa dipilih, silahkan integrasikan toko terlebih dahulu",
    "stockTikiTips": "Tidak akan dilakukan sinkronasi stok untuk seluruh produk FBT dan Toko Tiki yang tidak dapat diubah informasi produknya.",
    "stocksettingtip2Local": "Setelah sinkronisasi Stok diaktifkan, Stok semua produk yang terikat pada Master SKU yang sama akan disesuaikan agar konsisten, dan ketika Master SKU memiliki pesanan baru atau pesanan dibatalkan, Stok semua produk terikat pada Master SKU akan dikurangi atau ditambahkan secara bersamaan. Mohon pastikan sinkronisasi produk dan order sudah selesai sebelum dibuka, dan jumlah stok dari Master SKU sudah disesuaikan. \nSetelah sinkronisasi stok diaktifkan, stok SKU toko akan di-push sesuai dengan rasio stok dan stok Master SKU yang terikat dalam manajemen stok (pengaturan gudang pengiriman toko). Jika stok dalam manajemen stok belum diatur, maka stok SKU toko akan di-push ke 0 dan tidak dapat dipulihkan. Silahkan konfirmasi apakah sinkronisasi stok diaktifkan.",
    "afterTheStockSynchronizationIsTurned": "Setelah sinkronisasi stok diaktifkan, stok SKU toko akan di-push sesuai dengan rasio stok dan stok Master SKU yang terikat dalam manajemen stok (pengaturan gudang pengiriman toko). Jika stok dalam manajemen stok belum diatur, maka stok SKU toko akan di-push ke 0 dan tidak dapat dipulihkan. Silahkan konfirmasi apakah sinkronisasi stok diaktifkan.",
    "doesntSupportCustomShippingLabelTemplate": "Saat ini tidak mendukung custom template untuk Label Pengiriman di Filipina, hanya mendukung pengaturan ukuran A4/A6",
    "commissionTips": "Mohon masukkan angka valid antara 0-100",
    "invoiceNumberTips": "Semua produk dalam satu pesanan menggunakan Nomor Invoice yang sama",
    "SettingTips2": "silakan klik di sini",
    "SettingTips": "Pengaturan ini hanya berlaku ketika pesanan pengiriman dari Channel diproses di Ginee(Lazada hanya mendukung pengaturan di Seller Center). Untuk edit alamat default / alamat pickup toko,",
    "SettingLogo": "Klik disini untuk set Logo Merek",
    "SellerNotes": "Catatan Penjual",
    "PickingNotes": "Catatan Pengambilan",
    "TotalProduct": "Total Produk",
    "PrintMan": "Dicetak Oleh",
    "DeliverySettingPickupAddress": "Silahkan set alamat di Seller Center",
    "DeliverySettingStoreName": "Nama Toko",
    "PickFormReset": "Reset",
    "ChangePickStatusConfirm": "Konfirmasi",
    "PickDeleteSuccess": "Berhasil dihapus!",
    "PickDeleteTipTwo": "Setelah dihapus tidak dapat dikembalikan.",
    "PickDeleteTip": "Anda yakin akan menghapus informasi Status?",
    "PickOverCharacters": "Maksimal 20 Karakter",
    "AddPickStatus": "Tambah Aturan",
    "PickUpdateTime": "Waktu Pembaruan",
    "PickCreateTime": "Dibuat Pada",
    "PickStatusOperatorName": "Pembuat",
    "PickStatusName": "Nama Status",
    "PrintUnitPrice": "Harga Satuan",
    "PrintVariationName": "Variasi",
    "PrintShipping": "Logistik",
    "PrintSellerInfo": "Penjual",
    "PrintBuyerInfo": "Pembeli",
    "BuyerNotes": "Catatan Pembeli",
    "ShippingFee": "Biaya Pengiriman",
    "SenderInfo": "Pengirim",
    "GoToSellerCenter": "Silahkan set alamat di Seller Center",
    "AutoShippingTips": "Ginee dapat  mengatur metode pengiriman secara otomatis untuk Toko, Atur sekarang",
    "ShippingAddRule": "Tambahkan Aturan",
    "OperationFailed": "Pengaturan Gagal, Silakan coba lagi nanti",
    "OperationSuccess": "Pengaturan Berhasil",
    "ApplyToAllStores": "Terapkan ke Semua Toko",
    "PickupAddress": "Alamat Pickup",
    "ChooseAStore": "Pilih Toko",
    "ShippingSettingTips": "Tips: Shopee hanya memberikan akseS metode Pickup kurir SPX kepada penjual terpilih",
    "ShopName": "Nama Toko",
    "ShippingMethod": "Logistik",
    "Courier": "Kurir",
    "ShippingMethodDescription": "Sesuai dengan pengaturan Anda, Ginee akan secara otomatis memilih metode pengiriman untukmu setelah pesanan disinkronkan untuk meningkatkan efisiensi",
    "laStoreName": "Nama Toko",
    "proalertclosed": "Pengingat Stok dimatikan",
    "proalertopend": "Pengingat Stok telah dimatikan. Ketika stok yang tersedia dari Stok SKU tersebut lebih rendah dari Stok Pengaman, maka email notifikasi akan dikirim",
    "PleaseSelectLeast": "Pilih minimal satu",
    "DeleteTplTitle": "Anda yakin ingin menghapus Template?",
    "DeleteTplDesc": "Setelah dihapus tidak dapat dikembalikan lagi.",
    "LaRuleBindingStoreSearchPlaceholder": "Masukkan Nama Toko",
    "LaRuleBindingStoreModalTitle": "Silakan Pilih Aturan Faktur",
    "methodSettingDoubleCheckDescription": "Setelah Peraturan dihapus, Metode Pengiriman Toko terkait akan menjadi tidak valid",
    "LaRuleDoubleCheckDescription": "Setelah Peraturan dihapus, aturan pembuatan faktur dari Toko terkait akan menjadi tidak valid.",
    "LaRuleDoubleCheck": "Anda yakin ingin menghapus Peraturan?",
    "ExportField": "Konten Export",
    "SelectChannel": "Pilih Channel",
    "OrderExportingDragging": "Anda dapat mengubah urutan saat mengekspor dengan menyeret ke atas dan ke bawah",
    "BasicInfo": "Info Dasar",
    "EditTemplate": "Edit Template",
    "UpdateTime": "Waktu Pembaruan",
    "CreateTime": "Dibuat Pada",
    "TemplateName": "Nama Template",
    "Creator": "Pembuat",
    "LAInvoiceSettingSave": "Konfirmasi",
    "ApplyToAllLazadaStores": "Terapkan ke Semua Toko Lazada",
    "NextNumber": "Nomor Berikutnya",
    "EnterNumberManually": "Masukkan Nomor Secara Manual",
    "UseCustomNumber": "Gunakan Nomor Kustom",
    "UseOrderNumber": "Gunakan Nomor Pesanan",
    "StoreManagement": "Manajemen Toko",
    "RuleApplicationStoreNumber": "Jumlah Toko yang didukung",
    "RuleCustomNumber": "Nomor Kustom",
    "RulePrefix": "Prefix",
    "RuleType": "Jenis Aturan",
    "RuleName": "Nama Aturan",
    "AddRule": "Tambah Aturan",
    "commissionPercent": "Persentase Komisi (%)",
    "InvoiceRules": "Peraturan Faktur",
    "ShippingSettingsTab": "Pengaturan Pengiriman",
    "TemplateShippingAddress": "Alamat Pengiriman",
    "TemplateLogistics": "Jasa Pengiriman",
    "TemplateTotal": "Total",
    "TemplateSellerDiscountTotal": "Diskon Penjual",
    "TemplateTax": "Pajak",
    "TemplateShipping": "Sedang dikirimkan",
    "TemplateSubtotal": "Subtotal",
    "TemplateQty": "Jumlah",
    "TemplateCreatedAt": "Dibuat Pada",
    "TemplatePayment": "Pembayaran",
    "TemplateContactNo": "No. Kontak",
    "TemplateUnitPrice": "Harga Satuan",
    "TemplateBuyer": "Pembeli",
    "TemplateAWBTrackingNumber": "AWB/Nomor Tracking",
    "Quantity": "Jml.",
    "Specification": "Variasi",
    "ProductName": "Nama Produk",
    "TemplateProductItem": "Barang",
    "TemplateInsurance": "Asuransi",
    "TemplateWeight": "Berat",
    "TemplateShippingType": "Logistik",
    "OrderId": "Pesanan",
    "TemplateSender": "Pengirim",
    "TemplateRecipient": "Penerima",
    "TemplateInformation": "Informasi",
    "LogisticsLOGO": "LOGO Jasa Pengiriman",
    "ChannelLogo": "Logo Channel",
    "TemplateIcon": "Ikon",
    "TemplatePrintDate": "Tanggal Cetak",
    "TemplateDate": "Tanggal",
    "ViewBoxItemTitle": "Tampilan Cetak",
    "TemplateSettingsTitle": "Pengaturan Template",
    "SetPickUpAddress": "Jadikan Alamat Pickup",
    "SetDefaultAddress": "Jadikan Alamat Default",
    "SenderAddress": "Alamat",
    "SenderPhone": "Telepon",
    "SenderName": "Nama",
    "DefaultAddress": "Alamat Default",
    "BlSenderInfo": "i18n: BlSenderInfo",
    "TokoSenderInfo": "Tips: Nama/telepon telah diatur akan ditampilkan di informasi pada label pengiriman di Ginee.",
    "ShopeeSenderInfo": "Tips: Nama/telepon di Alamat Default akan digunakan untuk pengiriman, Setelah Alamat Default/Pickup diatur, Informasi alamat akan disinkronkan ke Shopee.",
    "PickingListPrintTemplateSettings": "Pengaturan Template Picking List",
    "ShippingLabelTemplateAlertTips": "Hanya bisa menggunakan Template dari Ginee sendiri, tidak bisa menggunakan template dari pihak ketiga",
    "PrintTemplateSettings": "Pengaturan Template Pencetakan",
    "ShippingLabelAlertTips": "Jika Anda ingin menggunakan Template Label Pengiriman Ginee atau edit alamat default / alamat pickup toko, Silakan lengkapi Pengaturan Informasi",
    "ShippingLabelInformationSettings": "Pengaturan Informasi Label Pengiriman",
    "htar": "Bagaimana cara menambahkan peran?",
    "htas": "Bagaimana cara menambahkan staf?",
    "htmr": "Bagaimana cara mengatur kewenangan?",
    "htms": "Bagaimana cara mengatur staf?",
    "Mustbestartedwith8": "Harus diawali dengan angka 8,",
    "atleastonerole": "Silakan pilih setidaknya satu kewenangan",
    "noroles": "Tidak Ada Data, Silahkan tambahkan peran",
    "deleteroletip": "Setelah dihapus, Staf tidak dapat memilih peran ini lagi",
    "suretodeleterole": "Anda yakin akan menghapus peran ini?",
    "EditRole": "Edit Peran",
    "Roleadded": "Peran ditambahkan",
    "Permissionlimit": "Silakan pilih setidaknya satu kewenangan",
    "Permission": "Kewenangan",
    "roledesclimit": "Silakan masukkan deskripsi kurang dari 600 karakter",
    "roleNamelimit": "Nama harus 1-150 karakter",
    "EnterRoleName": "Masukkan Nama Peran",
    "DeleteFailed": "Hapus Gagal",
    "staffDeleted": "Staff telah dihapus",
    "RoleDeleted": "Peran Dihapus",
    "AddRole": "Tambahkan Peran",
    "Numberofstaff": "Jumlah Staf",
    "RoleDescription": "Deskripsi Peran",
    "RoleName": "Nama Peran",
    "Submit": "Kirimkan",
    "Staffhasbeenadded": "Staf telah ditambahkan",
    "Nextstep": "Selanjutnya",
    "DiscardChange": "Batalkan Perubahan",
    "suretodiscard": "Yakin ingin membatalkan perubahan ini?",
    "AddNewRole": "Tambahkan Peran Baru",
    "Existingpermissions": "Kewenangan",
    "EnterthePassword": "Staf menggunakan kata sandi ini untuk masuk ke Ginee",
    "passwordlimit": "Kata sandi harus mengandung setidaknya 8 karakter, angka, dan simbol",
    "Staffsusethemailbox": "Staf akan menggunakan email ini untuk login ke Ginee",
    "Gineeaccountcannotbeempty": "Akun Ginee tidak boleh kosong",
    "Pleaseinputemail": "Silakan masukkan alamat email",
    "Contactphonenumber": "No. HP",
    "namelimit": "Nama Staf harus 1-30 karakter",
    "EnterStaffName": "输入员工姓名",
    "locktip": "Akun ini telah dinonaktifkan, silakan hubungi Administrator",
    "deletetip": "Setelah dihapus, Staf tidak akan dapat mengelola toko dan Anda tidak lagi dapat menambahkan Staf ini",
    "suretodeletestaff": "Apakah Anda yakin akan menghapus Staf ini?",
    "Disablenow": "Nonaktifkan Sekarang",
    "disabletip": "Setelah dinonaktifkan, Staf tidak dapat masuk ke Ginee untuk mengelola Toko",
    "suretodisabled": "Apakah Anda yakin untuk menonaktifkan akun Staf ini?",
    "Edit": "Edit",
    "Enabled": "Aktif",
    "Disabled": "Nonaktif",
    "Action": "Atur",
    "LastLogin": "Terakhir Online",
    "NameofStaff": "Nama Staf",
    "AddStaff": "Tambahkan Staf",
    "Search": "Cari",
    "Roles": "Peran",
    "GineeAccount": "Akun Ginee",
    "AccountStatus": "Status Akun",
    "ActivationFailed": "Gagal Diaktifkan, Silahkan Atur terlebih dulu",
    "SavedSuccessfully": "Operasi Berhasil!",
    "donwloadlist": "Klik disini untuk mengunduh List Stok Anda",
    "InventoryWarnings": "Pengingat Stok",
    "moresetting": "Pengaturan Lainnya",
    "suretoclosemainlock": "Anda ingin menonaktifkan Kunci Stok Toko Utama?",
    "suretoopenstocklock": "Anda yakin ingin mengaktifkan Kunci Stok Toko Utama? Setelah diaktifkan, maka ketika stok di bawah batas angka yang ditentukan, hanya stok Toko Utama yang tetap, dan stok di Toko lain akan dikosongkan",
    "whenempty2": ", kosongkan semua stok di Toko lain, kecuali Toko",
    "whenempty1": "Saat stok '<'=",
    "MainStoreStockLock": "Kunci Stok Toko Utama",
    "suretoclosestockwarning": "Yakin ingin mematikan Peringatan Stok?",
    "emailreminderstocklow": "Anda ingin mengaktifkan pengingat lewat email untuk stok produk yang tinggal sedikit?",
    "Setup": "Atur",
    "whenstock2": ", beri tahu saya bahwa stok tidak mencukupi",
    "whenstock1": "Saat stok <=",
    "suretoclosealertsold": "Apakah Anda yakin ingin menonaktifkan peringatan stok?",
    "notifyme": "Beritahu saya ketika produk terjual habis",
    "stocksettingtip3Local": "Setelah Sinkronisasi Stok dinonaktifkan, maka Stok SKU di semua Toko tidak akan ditambahkan atau dikurangi secara bersamaan lagi.",
    "stocksettingtip3": "Setelah Sinkronisasi Stok dinonaktifkan, maka Stok SKU di semua Toko tidak akan ditambahkan atau dikurangi secara bersamaan lagi. Lalu List Stok akan dikosongkan!",
    "stocksettingtip2LocalOld": "Setelah sinkronisasi Stok diaktifkan, Stok semua produk yang terikat pada Master SKU yang sama akan disesuaikan agar konsisten, dan ketika Master SKU memiliki pesanan baru atau pesanan dibatalkan, Stok semua produk terikat pada Master SKU akan dikurangi atau ditambahkan secara bersamaan. Mohon pastikan sinkronisasi produk dan order sudah selesai sebelum dibuka, dan jumlah stok dari Master SKU sudah disesuaikan.",
    "stocksettingtip2": "Setelah Sinkronisasi Stok diaktifkan, Stok SKU yang sama di semua Toko akan disinkronisasikan agar konsisten, dan ketika ada pesanan baru atau pesanan dibatalkan dari satu SKU, maka stok SKU di semua toko akan dikurangi atau ditambah secara bersamaan. Pastikan sinkronisasi produk dan pesanan sudah selesai sebelum aktivasi, dan jumlah Stok SKU sudah disesuaikan",
    "stocksettingtip1Local": "Untuk meningkatkan akurasi stok, sebelum mengaktifkan Sinkronisasi Stok harap selesaikan semua sinkronisasi produk dan pesanan, lalu atur stok MSKU. Setelah diaktifkan, ketika Ginee sedang menyinkronkan pesanan, stok MSKU akan dikurangi atau ditambahkan secara otomatis sesuai dengan status pesanan, dan akan menyinkronkan stok tersedia MSKU tersebut ke toko dimana produk telah terikat.",
    "stocksettingtip1": "Untuk memastikan akurasi Stok SKU, mohon menyelesaikan proses sinkronisasi semua produk dan pesanan, lalu atur stok SKU sebelum mengaktifkan Sinkronsisasi Stok SKU. Setelah diaktifkan, Ginee akan secara otomatis mengurangi atau menambah stok SKU sesuai dengan status pesanan, dan menyinkronkan jumlah stok SKU ke semua Toko yang terintegrasi dengan SKU yang sama di semua Channel.",
    "monitorsync": "Monitor Stok dan Sinkronisasi",
    "noteCreateBind": "Catatan: Jika Ikat Otomatis dari Master SKU diaktifkan, maka ikat otomatis akan dijalankan terlebih dahulu",
    "autoCreateSKUValue": "Aturan pembuatannya: jika SKU Toko memiliki nama yang sama dengan Master SKU, maka produk akan terikat ke Master SKU ini, jika tidak, Master SKU baru akan otomatis dibuat.Hanya berlaku untuk produk channel yang tersinkronisasi dengan Ginee dan tidak terikat pada Master SKU.",
    "automaticallyCreateTheMasterSKU": "Buat Master SKU secara otomatis",
    "deleteAutoCreateRules": "Anda yakin ingin menonaktifkan pengaturan Buat Otomatis Master SKU?",
    "turnAutoCreateRules": "Setelah Buat Otomatis Master SKU diaktifkan, untuk produk saluran yang tidak terikat ke Master SKU Jika SKU Toko produk memiliki nama yang sama dengan Master SKU, produk terikat ke Master SKU ini, jika tidak, Master SKU baru secara otomatis dibuat.",
    "goToDownloadTheRules": "Buka [Impor / Ekspor-Ekspor semua aturan Ikat Otomatis] untuk mengunduh aturan]",
    "downloadAllAutoBindRules": "Unduh semua aturan Auto-Bind",
    "deleteAutoBindRules": "Apakah Anda yakin untuk mematikan pengaturan Ikat Otomatis Master SKU?",
    "turnOnAutoBindRules": "Setelah Ikat Otomatis Master SKU dinyalakan, untuk channel product yang tidak terikat dengan Master SKU, Ginee akan otomatis mengikat produk ke Master SKU sesuai aturan bahwa Master SKU memiliki nama yang sama dengan SKU Toko.",
    "autoBindSKUValue": "Setelah mengaktifkan setelan, jika SKU Toko memiliki nama yang sama dengan Master SKU, maka secara otomatis akan terikat, dan juga mendukung filter otomatis khusus. Hanya berlaku untuk produk channel yang tersinkronisasi dengan Ginee dan tidak terikat pada Master SKU.",
    "automaticallyBindTheMasterSKU": "Secara otomatis mengikat Master SKU",
    "SyncSuccess": "Sinkronisasi Berhasil",
    "AreSureEnableCOD": "Anda yakin ingin mengaktifkan metode COD?",
    "StoreName": "Nama Toko",
    "ExcludeShippingInfo3": "Yakin untuk mengaktifkan Jasa Kirim { JNEOKE }?",
    "ExcludeShippingInfo2": "Produk dengan jasa kirim { JNEOKEList } akan digantikan ke { JNEOKE }.",
    "ExcludeShippingInfo1": "Bila Anda mengaktifkan Jasa Kirim  { JNEOKE }, maka jasa kirim { JNEOKEList } akan dinonaktifkan.",
    "NoShippingInfo": "Belum ada Toko Shopee yang terintegrasi, Silahkan integrasikan sekarang.",
    "CodNo": "Tidak",
    "CodYes": "Iya",
    "DisabledCODClick": "Lihat informasi lengkapnya di sini",
    "DisabledCODDesc4": "Fitur asuransi pengiriman tersedia untuk J&T COD.",
    "DisabledCODDesc3": "Tidak terdapat perbedaan pada proses pesanan & pengiriman, pembayaran akan diterima sesuai ketentuan.",
    "DisabledCODDesc2": "Pembeli yang melanggar ketentuan akan diblokir sistem.",
    "DisabledCODDesc1": "Opsi pengiriman COD dapat meningkatkan penjualan.",
    "DisabledCODTitle": "Yakin ingin menonaktifkan COD?",
    "PleaseGoToProductEnable": "Silakan aktifkan Jasa Pengiriman yang pada masing-masing produk.",
    "UnEnabledTitle": "Produk yang tidak memiliki jasa kirim akan dinonaktifkan dan tidak terlihat pembeli. Mohon pastikan semua produk Anda memiliki jasa kirim yang aktif.",
    "CODDisabled": "COD nonaktif",
    "CODEnabled": "COD aktif",
    "COD": "COD",
    "ShippingSupportedDesc": "Menggunakan Jasa Kirim yang Didukung Shopee Pembeli dapat cek informasi terbaru dari Shopee mengenai pelacakan paket.",
    "ShopeSupportedLogistics": "Jasa Kirim yang didukung Shopee",
    "ShippingTopTips": "Catatan: Anda dapat mengelola pengaturan Jasa Pengiriman Toko yang terintagrasi di sini. Pengaturan Jasa Pengiriman setiap Toko diharapkan sama. Harap pilih setidaknya satu Jasa Pengiriman.",
    "MembershipPage": "Halaman Member",
    "BasicLogo": "Logo",
    "Account": "Akun",
    "afterClosingTheAutomaticBindingRules": "Setelah ditutup, aturan pengikatan otomatis akan dihapus dan produk saluran tidak lagi terikat secara otomatis ke SKU Master.",
    "ShippingRuleSettings1": "Pengaturan Aturan Pengiriman",
    "TheWarehouseAllocatedByThe": "2. Gudang yang dialokasikan untuk pesanan tersebut sudah diikatkan dengan gudang Accurate",
    "MskuHasSuccessfullyPushedTo": "1. MSKU sudah berhasil dipush ke Accurate",
    "orderRulesToPushToAccurate": "Aturan pesanan yang dapat dipush ke Accurate:",
    "OnlySupports": "3. Hanya boleh menggunakan penghubung \"(\", \")\", \"-\", \".\", \"_\" dan \"/\"",
    "OnlySupportsLetternumberCombination": "2. Hanya bisa kombinasi huruf/angka",
    "NoMoreThanCharacters": "1. Tidak lebih dari 30 karakter",
    "mskuRulesToPushProductTo": "Aturan MSKU untuk push produk ke Accurate :",
    "ifNoRulesAreAddedThe": "Jika tidak ada aturan yang ditambahkan, aturan ini akan diimplementasikan. Setelah aturan ditambahkan sendiri, gudang akan ditetapkan sesuai dengan aturan yang Anda tetapkan.",
    "afterTheWarehouseSettingIsModified": "Setelah pengaturan gudang diubah, gudang akan dialokasikan sesuai dengan aturan baru. Tolong hati-hati.",
    "afterConfigurationWhenAnOrderContains1": "Setelah konfigurasi, ketika pesanan berisi beberapa produk, produk yang berbeda dapat dialokasikan ke gudang yang berbeda",
    "particularCommodity": "Produk Satuan",
    "afterConfigurationWhenAnOrderContains": "Setelah konfigurasi, saat pesanan memiliki beberapa produk, pesanan hanya dapat dialokasikan ke satu gudang, dan tidak dapat dialokasikan ke beberapa gudang sekaligus",
    "overallOrder": "Keselruhan Pesanan",
    "compartmentDimension": "Tampilan Pembagian Gudang",
    "afterConfigurationWhenMultipleWarehousedividingRules": "Setelah konfigurasi, ketika beberapa aturan alokasi gudang terpenuhi, gudang dengan prioritas terendah akan terlebih dahulu dialokasikan untuk membuat daftar outbound dan memproses stok",
    "warehouseWithHighRulePriority": "Gudang dengan prioritas aturan tinggi",
    "afterConfigurationWhenMultipleWarehouseAllocation": "Setelah konfigurasi, ketika beberapa aturan alokasi gudang terpenuhi, prioritas akan diberikan ke gudang dengan stok tersedia yang cukup untuk membuat daftar outbound dan memproses stok",
    "fullyStockedWarehouse": "Gudang dengan stok memadai",
    "priorityOfWarehouseDivision": "Prioritas alokasi gudang",
    "promptThisSettingAffectsWarehouseAllocation": "Prompt: Pengaturan ini memengaruhi aturan alokasi gudang, jadi harap berhati-hati.",
    "temporarilyNotOpen": "Untuk sementara tidak diaktifkan",
    "theSwitchIsNotTurnedOn": "Tombol tidak diaktifkan, dan aturan alokasi gudang tidak akan dijalankan setelah berhasil dibuat.",
    "theSwitchOrderSettingsdistributionSettingsorderAssociated": "Tombol \"Pengaturan Pesanan-Pengaturan Distribusi-Proses Pemotongan Stok Pesanan\" belum diaktifkan",
    "separateWarehouseSetting": "Pengaturan Alokasi Gudang",
    "afterAddingRulesByYourselfWhen": "Setelah menambahkan aturan, ketika kondisi sesuai dengan aturan yang akan ditambahkan, gudang akan ditetapkan sesuai dengan aturan yang Anda tetapkan.",
    "matchAccordingToTheWarehouseIn": "Secara default mencocokkan menurut gudang inbound terbaru dari MSKU tersebut.",
    "downloadApp": "Download APP",
    "manageYourStoreAnytimeAnywhereGinee": "Kelola tokomu kapan pun di mana pun dengan Ginee App",
    "shippingMethodPriorityPickUp": "Proritas Metode Pengiriman： Pickup > Dropoff > Manual Ship",
    "logisticsCompaniesThatDoNotSet": "Logistik yang belum disetting aturan pengirimannya, akan dikirimkan berdasarkan metode pengiriman yang tersedia",
    "pleaseSelectALogisticsCompanyFor": "Silahkan pilih logistik untuk pesanan Akulaku",
    "pullThreepartyWarehouseForStorage": "Tarik Stok Gudang Pihak Ke-3",
    "expired": "Kedaluwarsa",
    "deactivated": "Terbatas",
    "ecommercePlatform": "Marketplace",
    "selfbuiltStation": "Webstore",
    "financialSoftware": "Accounting",
    "originalName": "Original",
    "editStore": "Sunting toko",
    "pullData": "Tarik data",
    "reenable": "Aktifkan kembali",
    "deleteStore": "Hapus toko",
    "areyousureyouwanttodeletethisstore": "Anda yakin ingin menghapus toko ini?",
    "TheBindingRelationshipBetweenThe": "1. Hubungan ikatan antara produk channel toko dan master produk akan dilepaskan.\n2. Informasi daftar produk toko channel akan dihapus di Ginee.\n3. Stok dalam daftar stok tidak akan dipush ke toko.\n4. Anda tidak akan dapat melihat informasi pesanan untuk toko ini.\n5. Anda tidak akan menemukan toko ini di pengaturan toko",
    "theDefinitionOfDisabledIsStore": "Dibatasi: Toko telah diotorisasi tetapi melebihi batas paket. Status ini tidak akan mempengaruhi penjualan toko di channel tersebut. Namun, channel produk toko akan otomatis terlepas dari MSKU, dan data seperti produk/pesanan tidak akan ditampilkan di Ginee",
    "authorizationInfo": "Informasi otorisasi",
    "configManagement": "Manajemen Konfigurasi",
    "germany": "Jerman",
    "belgium": "Belgia",
    "italy": "Italia",
    "poland": "poland",
    "authorized": "Telah Diotorisasi",
    "authTips": "Setelah Toko berhasil terhubung, anda dapat melihat proses sinkronisasi produk dan pesanan anda di bagian Produk dan Pesanan",
    "youDoNotHavePermissionTo": "Anda tidak memiliki akses untuk toko ini, sehingga tidak dapat mengeddit harga toko",
    "pleaseWaitPatientlyWhileTheStore": "Data toko sedang ditarik. Harap menunggu dengan sabar",
    "startPullingTheStoreDataIt": "Mulailah menarik data toko, diharapkan memakan waktu beberapa menit, harap perhatikan sinkronisasi data toko nanti",
    "editSucceeded": "Edit berhasil",
    "AreYouSureYouWantToDeleteTheAuthorization": "Apakah Anda yakin untuk menghapus otorisasi?",
    "areYouSureToModifyThe": "Apakah anda yakin ingin mengubah pengaturan distribusi gudang?",
    "CharactersLong": "10-12 karakter panjang",
    "pleaseStartWithTwoLettersOr": "Silakan mulai dengan dua huruf atau angka \"1\" dan sertakan 10 angka",
    "pleaseEnterTheCorrectAkulakuTracking": "Harap masukkan format nomor pelacakan Akulaku yang benar",
    "transportStockChange": "Stok Transit：",
    "tipavailablestock": "Stok yang bisa dijual di Channel, Stok Tersedia = Stok Gudang-Stok Cadangan-Stok Terkunci-Stok Promosi",
    "tiplockedStock": "Stok belum dipenuhi dan dikirim, Pesanan lunas baru/belum bayar tersinkronkan ke Ginee akan ditambahkan ke Stok Terkunci",
    "tipsafetyStock": "Tetapkan batas stok dan terima pemberitahuan email untuk restok ketika Stok Tersedia produk mencapai batas",
    "tipspareStock": "Jumlah Total Stok yang dicadangkan di gudang, yang tidak akan dijual di Channel Toko.",
    "tipwarehouseStock": "Total Stok Aktual di Gudang Anda , Stok Gudang = Stok Cadangan + Stok Terkunci + Stok Tersedia + Stok Promosi",
    "totalAvailableStock": "Total Stok Tersedia",
    "totalPromotionStock": "Total Stok Promosi",
    "totalLockedStock": "Total Stok Terkunci",
    "totalSpareStock": "Total Stok Cadangan",
    "totalWarehouseStock": "Total Stok Gudang",
    "ImageName": "Gambar&Nama",
    "case": "Kasus",
    "purchasePricecalrules": "Harga Pembelian Rata-rata = Total Harga Pembelian Inbound / Stok Gudang",
    "ISKUmeaning": "MSKU singkatan dari Master SKU (Master SKU)",
    "nochange": "Stok Tersedia tidak berubah, jadi stok di toko tidak perlu diperbarui",
    "manulupdate": "Tidak Berubah",
    "updatetimetip": "Waktu Pembaruan",
    "reasonforfailed": "Alasan Gagal: Produk telah dihapus atau dilarang di Channel Toko, atau sedang dalam masa promosi (sinkronisasi dapat berhasil setelah promosi berakhir).",
    "TotalPromotionStock": "Total Stok Promosi",
    "StoreSKU": "Toko SKU",
    "tippromotionStock": "Stok Promosi adalah stok total yang disediakan untuk Promosi Campaign atau Flash Sale",
    "remark": "Catatan",
    "transportStock": "Stok Transit",
    "warehouseStockChange": "Gudang：",
    "spareStockChange": "Cadangan：",
    "promotionStockChange": "Promosi：",
    "lockedStockChange": "Terkunci：",
    "availableStockChange": "Tersedia：",
    "oversell": "Produk sudah oversold, harap atur ulang Stok.Sekarang memperbarui Stok di Channel menjadi 0.",
    "Seethedetails": "Lihat selengkapnya",
    "warehouseStockerror": "Stok Harus antara 0-999,999",
    "warehouseStockrequired": "Stok Harus antara 0-999,999",
    "remarkplaceholder": "Harap masukkan alasan perubahan, harus kurang dari 300 karakter.",
    "availablestockshort": "Tersedia",
    "promotionstockshort": "Promosi",
    "lockedStockshort": "Terkunci",
    "spareStockshort": "Cadangan",
    "warehouseStockshort": "Gudang",
    "priceplaceholder": "Masukkan Harga",
    "Barcodeplaceholder": "Menggunakan huruf, angka dan -_",
    "weightplaceholder": "Rentang 5,000,000",
    "upgradeResultClose": "Tutup",
    "upgradeResultViewNow": "Lihat Sekarang",
    "upgradeResultRetry": "Coba Lagi",
    "upgradeResultFailedTitle": "Upgrade ke Manajemen Stok Pro Gagal!",
    "upgradeResultSuccessTitle": "Berhasil Upgrade ke Manajemen Stok Pro!",
    "upgradeModalUpgradeNow": "Upgrade Sekarang",
    "upgradeModalCancel": "Batalkan",
    "upgradeModalAbout": "Tentang Manajemen Stok Pro",
    "january5th": "diupgrade secara otomatis pada tanggal 5 Januari 2021",
    "syncmanually": "sinkronkan pesanan secara manual sebelum",
    "upgradeModalFooterTitle": "Harap {syncorder} upgrade, sehingga pesanan yang ada akan mengurangi stok sekarang.Akun Ginee dengan Versi Basic akan {updatedate}",
    "upgradeModalLabelStockWarning": "Pengingat Stok:",
    "upgradeModalLabelSoldOut": "Stok Habis:",
    "upgradeModalLabelAll": "Semua:",
    "upgradeModalContentTitle": "Lihat Jumlah Master SKU Anda:",
    "upgradeModalTitleSub": "Manajemen Stok Pro adalah versi lanjutan dengan {val} fitur baru yang ditambahkan ke Manajemen Stok.",
    "upgradeModalTitle": "Upgrade ke Manajemen Stok Pro",
    "upgradeBtn": "Gratis Upgrade ke Pro, Lihat sekarang!",
    "setstock": "Set Stok",
    "setsafetyStock": "Set Stok Pengaman",
    "setspareStock": "Set Stok Cadangan",
    "setwarehouseStock": "Set Stok Gudang",
    "skusselected": "SKUs Dipilih",
    "skuselected": "SKU Dipilih",
    "skus": "SKU",
    "Viemore": "Lihat",
    "VariationStatus": "Variasi Status",
    "createnext": "Buat & Lanjutkan",
    "sizelimittip": "Harus antara 1-99,999!",
    "pricelimittip": "Harga Beli Harus diantara 0-1,000,000,000",
    "weightlimittip": "Berat harus antara 1-5,000,000!",
    "validatorsize": "Harus antara 1-99,999",
    "Barcodelengthtip": "Barcode tidak boleh lebih dari 30 karakter",
    "Barcodeerror": "Barcode hanya boleh menggunakan huruf, angka dan -_",
    "mustbiggerthan": "Tidak boleh kurang dari (Stok Cadangan + Stok Terkunci)",
    "mustlessthan": "Harus kurang dari (Stok Gudang-Stok Terkunci)",
    "PackageSize": "Ukuran Paket",
    "inputsafetyStock": "Stok Harus antara 0-999,999",
    "inputspareStock": "Stok Harus antara 0-999,999",
    "inputwarehouseStock": "Stok Harus antara 0-999,999",
    "safetyStockisRequired": "Stok Pengaman harus antara 0 ~ 999,999!",
    "spareStockisRequired": "Stok Cadangan harus antara 0 dan 999,999!",
    "warehouseStockisRequired": "Stok Gudang harus antara 0 dan 999,999!",
    "namerequired": "名称必须是1到160个字符",
    "Barcode": "Barcode",
    "addprice": "Harga Pembelian Inbound (Rp)",
    "averageprice": "Harga Pembelian Rata-rata (Rp)",
    "ItemInformation": "Info Produk",
    "stockimage": "Format: jpg, jpeg, png. Ukuran gambar: kurang dari 5MB",
    "noresult": "Tidak Berubah",
    "EMPTY": "Produk Toko tidak terikat",
    "FAILEDcontent": "Gagal: Channel tidak mengizinkan untuk edit stok produk",
    "inventorylogtip": "Ginee akan secara otomatis memperbarui ulang stok untuk Toko yang gagal diperbarui. Anda dapat melihat detail pembaruan untuk cek pembaruan atau apabila ingin memperbarui lagi secara manual.",
    "FAILED": "Gagal",
    "PART_FAILED": "Beberapa Gagal",
    "UpdateCompleted": "Pembaruan Berhasil",
    "StockWarning": "Pengingat Stok",
    "EXPIRED": "Gagal Dicoba Lagi",
    "RETRY_SUCCEEDED": "Berhasil dicoba lagi",
    "SUCCEEDED": "Berhasil",
    "NEED_RETRY": "Gagal, Mencoba lagi...",
    "PROCESSING": "Sedang Dipush",
    "UpdateDetails": "Detail Pembaruan Stok Toko",
    "ManualUpdate": "Pembaruan Manual",
    "StoreUpdateResult": "Hasil Pembaruan Toko",
    "Operator": "Operator",
    "Latest": "Terbaru",
    "Movement": "Perubahan",
    "Original": "Awal",
    "updateDatetime": "Waktu Pembaruan",
    "StockUpdateFailed": "Gagal Diperbarui",
    "StockSuccessfullyUpdated": "Berhasil Diperbarui",
    "Updating": "Sedang Memperbarui",
    "UpdateToStore": "Perbarui ke Toko",
    "OrderCancel": "Pesanan { OrderId } telah Dibatalkan, Alasan: { ReasonInfo }",
    "NewPaidOrder": "Pesanan Lunas",
    "UserEditStock": "Pengguna mengedit Stock",
    "System": "Sistem",
    "ChangeReason": "Alasan Edit",
    "OriginalStock": "Stok Awal",
    "createDatetime": "Dibuat Pada",
    "StockTime": "Waktu",
    "StockUserName": "Username",
    "StockChangeHistory": "Riwayat Perubahan Stok",
    "clicktoset": "Klik disini",
    "synclosed": "Sinkronisasi Stok nonaktif. Apabila Anda edit jumlah stok satu SKU, maka tidak akan mempengaruhi stok di Channel. Ingin menggunakan fitur Sinkronisasi Stok?",
    "syncopend": "Sinkronisasi Stok telah diaktifkan. Perubahan stok setiap SKU di Channel akan tersinkronisasikan dengan jumlah stok di Ginee.",
    "skurelation": "Silakan masukkan SKU Toko dari Channel untuk menghubungkan SKU Toko dan Master SKU. Saat membuat Master SKU, SKU Toko dengan SKU yang sama akan secara otomatis dihubungkan.",
    "stockrequired": "Jumlah Stok harus diantara 0-999,999",
    "inventorySkurequired": "Master SKU wajib diisi!",
    "inputpurchasePrice": "Masukkan Harga",
    "inputname": "Masukkan Nama",
    "inputstock": "Jumlah Stok harus diantara 0-999,999",
    "inputinventorySku": "Masukkan Master SKU",
    "addsuccess": "Berhasil Ditambahkan",
    "deletesuccess": "Berhasil Dihapus",
    "editsuccess": "Berhasil Diedit",
    "afterdelete": "Setelah menghapus Master SKU, maka hubungan dengan SKU di Channel akan terputus",
    "suretodelete": "Apakah Anda ingin menghapus Master SKU ini?",
    "products": "Master SKU",
    "failimport": "Import Gagal:",
    "sucessimport": "Import Berhasil:",
    "totalimport": "Total Import:",
    "picture": "Gambar Produk",
    "skuList": "SKU (Toko SKU)",
    "purchasePrice": "Harga Pembelian Inbound",
    "nameStock": "Nama",
    "Stock": "Stok",
    "SoldOut": "Habis",
    "AddInventorySKU": "Tambah Master SKU",
    "seacrh": "Cari",
    "ExportAll": "Export semua",
    "Exportbyselected": "Export yang Dipilih",
    "DownloadImportTemplate": "Unduh Template Import",
    "ImportInventorySKU": "Import Master SKU",
    "Import": "Import",
    "ok": "OK",
    "editsku": "Edit Master SKU",
    "addsku": "Tambah Master SKU",
    "skuseacrh": "Cari Master SKU, SKU, Nama",
    "skuplaceholder": "Masukkan SKU (SKU Toko)",
    "accordingToTheQueryConditionsYou": "Berdasarkan pencarian yang sudah Anda atur, estimasi ongkos kirim logistik adalah sebagai berikut:",
    "calculationResults": "Hasil perhitungan",
    "destination": "Dikirim ke",
    "shipFrom": "Dikirim dari",
    "calculationFormula": "Rumus perhitungan",
    "checkShippingFee": "Cek Biaya Pengiriman",
    "addAddress": "Tambah alamat",
    "setAsDefault": "Atur sebagai default",
    "theBindingRelationshipBetweenJtExpress": "Perlu diketahui: Hubungan J&T Express (ID) dengan Ginee telah terputus, dan Anda tidak akan bisa lagi menggunakan layanan yang berhubungan dengan logistik di Ginee",
    "onceDeletedYouWillNoLonger": "Setelah dihapus, Anda tidak akan bisa lagi menggunakan layanan J&T Express (ID) di Ginee, dan pesanan yang sudah dibuat tidak akan kosong. Harap operasikan dengan hati-hati",
    "confirmToDelete": "Anda yakin ingin menghapus?",
    "shippingSettings1": "Pengaturan Pengiriman",
    "reasonTheAuthorizationInformationIsIncorrect": "Alasan: Informasi otorisasi salah, silahkan edit dan otorisasi ulang",
    "youCanUseTheJtExpress": "Anda dapat menggunakan layanan J&T Express (ID) untuk memperkirakan biaya pengiriman > buat pesanan > proses nomor resi > cetak label > lacak paket",
    "maxCharactersOnlyNumbers": "Hanya diisi angka & huruf, maks.{length} karakter",
    "requiredToFillInPleaseGet": "Wajib diisi. Silahkan isi informasi berdasarkan panduan otorisasi",
    "withQuickAuthorizationYouDontNeed": "Dengan Otorisasi Cepat Anda tidak perlu menghubungi pihak J&T Express untuk mendapatkan informasi yang dibutuhkan. Ginee akan menyediakan informasi tersebut",
    "clickToViewAuthorizationInstruction": "Klik untuk melihat panduan otorisasi",
    "clickAuthorizeToBindAndIt": "Klik [Otorisasi] untuk integrasi, dan setelah berhasil akan bisa untuk melakukan pesanan/menarik nomor resi/mengecek ongkos kirim/dan melacak paket",
    "toUseAStandardAuthorizationPlease": "Untuk menggunakan otorisasi standar, silahkan kontak J&T Express (ID) untuk mendapatkan informasi untuk otorisasi",
    "recomend": "Direkomendasi",
    "standardAuthorization": "Otorisasi Standar",
    "quickAuthorization": "Otorisasi Cepat",
    "theSystemDetectsThatChannelProducts": "Sistem mendeteksi bahwa SKU produk saluran kosong. Harap konfirmasi apakah akan menggunakan nama spesifikasi dan nama produk sebagai MSKU?",
    "yesApplyToAllProducts": "Ya, berlaku untuk semua produk",
    "productInbound": "Inbound Produk",
    "manualOutbound": "Outbound Manual",
    "salesOutbound": "Outbound Penjualan",
    "theNumberOfConnectedStoresThat": "Jumlah toko terhubung yang telah melebihi batas paket efektif, silakan lanjutkan berlangganan paket atau hubungi layanan pelanggan untuk meningkatkan paket sebelum mengaktifkan toko;",
    "subscriptionPackage": "Paket Langganan",
    "theStoreHasBeenActivated": "Toko telah diaktifkan!",
    "deleteSuccess": "Berhasil Dihapus",
    "fulfillConfirmDisconnectContent": "Setelah dihapus, Gudang {fulfillment} dan layanan fulfillment terkait tidak akan tersedia lagi, jika Anda ingin mengaktifkan kembali, harap otorisasi ulang terlebih dahulu",
    "unfold": "Lihat Lebih Banyak",
    "collapse": "Tutup",
    "expiredStatusComingSoon": "Segera Kedaluwarsa",
    "ForLogisticCompanyPleaseAdd": "6. Untuk logistik silahkan tambah logistik yang dapat dipilih dari pesanan manual",
    "WarehouseAsWarehouseNameNot": "4. Gudang diisi dengan nama gudang, bukan kode gudang",
    "jnePleaseFillTheLogisticThat": "JNE (Silahkan isi logistik yang dapat diisi di pesanan manual)",
    "whetherBlacklist": "Apakah termasuk blacklist",
    "pleaseSetupShipmentSettingsFirstBefore": "Silahkan atur pengaturan pengiriman terlebih dahulu sebelum memilih Alamat Pickup",
    "authorizeNow": "Otorisasi sekarang",
    "pleaseRecheckAfterAuthorizeLogistic": "Silahkan cek ulang setelah otorisasi logistik",
    "temporaryNotAuthorize": "Sementara belum otorisasi",
    "oddNumbers": "Nomor Inbound",
    "wholesalePrice": "Harga Grosir",
    "addWholesalePrice": "Tambah Harga Grosir",
    "maxNumber": "Jumlah Maks. tidak boleh kurang dari atau sama dengan jumlah Min.",
    "maxNumberCannotBeLessThan": "Jumlah Maks. tidak boleh kurang dari atau sama dengan jumlah Min.",
    "theWholesalePriceCannotBeHigher": "Harga grosir tidak boleh lebih tinggi dari harga jual",
    "theWholesalePriceOfCannot": "Harga grosir {a} tidak boleh lebih tinggi dari harga grosir {b}.",
    "wholesalePricesAreOnlyAvailableTo": "Harga grosir hanya bisa ditambahkan jika semua varian memiliki harga yang sama",
    "productNameMinCharacter": "Nama produk tidak boleh kurang dari  {min} karakter",
    "productNotCanNotLessThan": "Produk tidak boleh kurang dari{min} gambar",
    "breadCrumbProductList": "Produk / {channel}",
    "storePermission": "Izin Toko",
    "channelPermission": "Izin Channel",
    "masterProductPermission": "Izin Master Produk",
    "priceManagementPermission": "Izin Manajemen Harga",
    "toEnsureTheAccuracyOfAvailable": "Untuk memastikan bahwa stok yang tersedia akurat, silakan klik \"Perbarui Stok yang Tersedia\" untuk mendapatkan nilai stok terbaru",
    "masterUpdateAvailableStock": "Perbarui stok yang tersedia",
    "pleaseSelectCompleteReceiverArea": "Silakan pilih wilayah penerima yang lengkap",
    "toCalculateShippingCostNeedTo": "Untuk menghitung ongkos kirim diperlukan mengisi informasi pengiriman dan berat paket",
    "calculateShippingFee": "Hitung Ongkos Kirim",
    "detailAddress": "Alamat Lengkap",
    "needToSelectAreaDimension": "Dibutuhkan untuk memilih dimensi area",
    "maximumCharactersNumbersOnly": "Maksimal 30 karakter, hanya angka",
    "maximumCharactersLettersOnly": "Maksimal 30 karakter, hanya huruf",
    "site": "Situs",
    "gineeErrorUrllabelEmptyPleaseTry": "Ginee Error: URL label kosong. Silahkan coba untuk sinkronisasi pesanan",
    "singleProductWeight": "Berat produk tunggal",
    "calculate": "Hitung",
    "youDontHaveThisStorePermission": "Anda tidak memiliki akses untuk toko ini",
    "logisticsServices": "Layanan Logistik",
    "theMaximumLengthIsCharacters": "Panjang maksimum adalah {maxLength} karakter",
    "averagePurchasePriceCurrentInbound": "Harga pembelian rata-rata = (Jumlah Inbound Saat Ini * Harga Pembelian Inbound Saat Ini + Jumlah Awal * Harga Pembelian Awal) / (Jumlah Inbound Saat Ini + Jumlah Awal)",
    "tiktokMassDeleteTip": "Karena keterbatasan API dari Tiktok, dalam sekali penghapusan jumlah item yang akan dihapus tidak bisa melebihi 20, silhakan pilih kembali.",
    "validityofauthorization": "Masa Berlaku Otorisasi",
    "channelProductMassEdit": "Produk / {channel}/Edit Massal",
    "dataPermission": "Izin data",
    "ruleTypeSpecifyMsku": "Jenis Aturan : MSKU Tertentu",
    "ruleTypeShopRules": "Jenis Aturan : Pengaturan Toko",
    "addNewRules": "Tambah Aturan Baru",
    "delete1": "Hapus",
    "requiredItemLockSettingIf": "Item Wajib. Pengaturan Kunci - Jika pengaturan kunci stok dari toko utama diaktifkan, Ketika stok MSKU di \"Ketentuan Push\" kurang dari atau sama dengan \"Batas Ambang\", maka stok tersedia yang di push ke toko lain selain toko terpilih adalah 0",
    "setupPushStore": "Atur Push Toko",
    "requiredItemPleaseEnterAnInventory": "Item Wajib, Silahkan masukan nilai stok lebih besar dari atau sama dengan 0 dan kurang dari atau sama dengan 99999",
    "setStockThresholds": "Atur Batas Stok",
    "pleaseEnterTheMskuToBe": "Silahkan Masukan MSKU yang ingin ditambahkan, input dalam baris berbeda jika MSKU lebih dari satu. Penyimpanan membutuhkan waktu lebih lama, mohon bersabar menunggu",
    "manualSelection": "Pilih Manual",
    "textRecognition": "Pengenalan Teks",
    "addingMethod": "Tambahkan Metode",
    "specifyMsku1": "MSKU Tertentu",
    "allMskus": "Semua MSKU",
    "addedMainStoreLock": "Tambah kunci toko utama",
    "afterTurnOnPleaseSetUp": "Setelah diaktifkan, silakan atur aturan kunci toko utama, MSKU tunggal hanya mendukung pengaturan satu kunci toko utama. Setelah mematikan aturan ini, semua aturan toko utama akan gagal secara default",
    "turnOnMainStoreLocked": "Aktifkan Kunci Toko Utama",
    "lockSettings": "Pengaturan Penguncian",
    "clickToView": "Klik untuk melihat",
    "addMsku1": "Tambah MSKU",
    "deleteMsku": "Hapus MSKU",
    "masterProductPicture": "Master Produk & Gambar",
    "pushMsku": "Push MSKU",
    "ruleType": "Jenis Aturan",
    "ruleInformation": "Informasi Aturan",
    "massAdd": "Tambah Massal",
    "add1": "Tambah",
    "addNewRule": "Tambah Aturan Baru",
    "mainStoreLocked": "Kunci Toko Utama",
    "operateBy": "Dioperasikan Oleh",
    "operationModule": "Modul Operasi",
    "tipOnlySupportForQueryingOperation": "Tip: Hanya mendukung untuk menampilkan log operasi 30 hari terakhir",
    "theRuleWasDeletedSuccessfully": "Aturan berhasil dihapus",
    "theRuleWasSavedSuccessfully": "Aturan berhasil disimpan",
    "ruleDisabledSuccessfully": "Aturan berhasil dinonaktifkan",
    "afterEnablingTheInventoryWillBe": "Setelah diaktifkan, stok akan dihitung dan dipush ke toko sesuai dengan aturan saat ini",
    "areYouSureYouWantTo1": "Yakin ingin mengaktifkan aturan?",
    "afterDeletionTheRulesWillNo": "Setelah dihapus, aturan tidak lagi mendukung pengelolaan dan penggunaan",
    "afterDisablingTheInventoryWillNo": "Setelah dinonaktifkan, stok tidak akan lagi dihitung dan dipush ke toko sesuai dengan aturan saat ini",
    "doYouConfirmToTurnOff": "Apakah yakin untuk menonaktifkan aturan?",
    "ifTheCustomRuleIsDisabled": "Jika aturan khusus dinonaktifkan,stok MSKU tidak akan lagi dipush ke channel",
    "afterCheckingIfACustomRule": "Setelah dipilih, jika aturan khusus ditambahkan ke MSKU dan diaktifkan, stok akan dihitung sesuai dengan aturan khusus dan didorong ke toko channel;",
    "afterAddingACustomRuleTo": "Setelah menambahkan aturan khusus ke MSKU, stok tidak lagi dipush sesuai dengan aturan default",
    "applicationSettings": "Pengaturan Pengaplikasian",
    "newMskuAddedToWarehouseLater": "MSKU Baru: Yang ditambahkan ke gudang melebih  dari waktu aktivasi",
    "allMskusExcludingMskuThatAdded": "Semua MSKU (tidak termasuk MSKU yang ditambahkan setelahnya)",
    "allMskusIncludingMskuThatAdded": "Semua MSKU (termasuk MSKU yang ditambahkan setelahnya)",
    "newStoresStoresWhichAuthorizationTime": "Toko baru: Toko yang waktu otorisasinya lebih dari waktu aktivasi;",
    "allAuthorizedStoresConnectedStoresWhose": "Semua toko terintegrasi: Toko terhubung yang waktu otorisasinya lebih awal dari waktu aktivasi;",
    "allConnectedStoresExcludingNewStores": "Semua toko yang terhubung, tidak termasuk toko baru yang baru di otorisasi",
    "allConnectedStoresIncludingNewStores": "Semua toko yang terhubung, termasuk toko baru yang baru di otorisasi",
    "pushRatio": "Rasio Push",
    "availableStockTransitStock": "Stok Tersedia + Stok Transit",
    "allWarehousesWithMskusInInventory": "Semua Gudang dengan MSKU yang telah di inbound dan status aktif (Tidak termasuk gudang yang baru di tambahkan)",
    "newWarehouseWarehouseWhoseCreationTime": "Gudang Baru : Dimana waktu aktif lebih awal dari waktu pembuatan gudang",
    "allWarehousesWithMskuThatHave": "Semua Gudang dengan MSKU yang telah di inbound dan status aktif (Termasuk gudang yang baru ditambahkan)",
    "ruleStatus": "Status Pengaturan",
    "noteThisTimeIsTheTime": "Catatan : Waktu ini adalah waktu dimana tombol \"Pengaturan Stok\" - \"Stok Monitoring dan Sinkronisasi\" diaktifkan",
    "activateTime": "Waktu Aktif",
    "viewFailureData": "Lihat data gagal",
    "backToRuleList": "Kembali ke Daftar Pengaturan",
    "fail": "Gagal",
    "sucess": "Sukses / Berhasil",
    "dataProcessingCompleted": "Proses Data Berhasil",
    "saveCurrentlyNotActive": "Tersimpan, tetapi non-aktif",
    "afterActivatePleaseAddMskuAs": "Setelah diaktifkan, mohon segera tambah MSKU, jika ingin pengaturan teraplikasi sempurna",
    "areYouSureWantToSave": "Konfirmasi untuk menyimpan dan mengaktifkan pengaturan?",
    "stockType1": "Jenis Stok",
    "warehouseName1": "Nama Gudang",
    "specifyMskuAfterCreatingTheRule": "Penetapan MSKU (Setelah membuat peraturan, anda perlu menambahkan MSKU secara manual, hanya MSKU yang telah ditambahkan yang dapat di push sesuai dengan pengaturan peraturan)",
    "storeRulesAfterCreatingTheRules": "Pengaturan Toko (Setelah membuat peraturan, Anda tidak perlu menambahkan MSKU, SKU channel yang sudah terikat dengan MSKU di toko akan dipush sesuai dengan pengaturan peraturan)",
    "tipsWhenAddingRulesIn": "Tips : 1. Ketika melakukan penambahan pengaturan secara massal, nama pengaturan akan otomatis dibentuk oleh sistem; 2. Ketika menambahkan pengaturan secara massal, hanya informasi pengaturan yang tersimpan, setelah menyimpan mohon atur MSKU ke dalam pengaturan dalam daftar pengaturan; 3. Maksimal hanya 100 pengaturan dalam sekali penambahan;",
    "clickToViewMsku": "Klik untuk melihat MSKU",
    "showAllStore": "Tampilkan Semua Toko",
    "showAllWarehouses": "Tampilkan Semua Gudang",
    "executeTheRules": "Eksekusi Pengaturan",
    "fixedValue": "Nilai Tetap",
    "storeRule": "Pengaturan Toko",
    "specifyMsku": "MSKU Tertentu",
    "operation": "Operasi",
    "createBy": "Dibuat oleh",
    "ratioFixValue": "Rasio/Nilai Tetap",
    "ruleName": "Nama Pengaturan",
    "exactMatch": "Mapping Tepat",
    "multipleMskusSeparatedBySpacesMax": "MSKU lebih dari satu dipisahkan dengan spasi, maks. 50 MSKU",
    "pleaseFillInTheCompleteRecipient": "Mohon isi informasi penerima dengan lengkap",
    "pleaseSelectAPickupAddress": "Silahkan pilih Alamat Pickup",
    "addMainStoreLock": "Tambah kunci toko utama",
    "editMainStoreLock": "Edit kunci toko utama",
    "ratio": "Rasio",
    "thisAddressDoesNotCurrentlySupport": "Alamat ini tidak mendukung perhitungan biaya pengiriman",
    "tExpressIdIsNotAuthorized": "J&T Express ID tidak diotorisasi, pembatalan tidak akan memberi tahu perusahaan logistik",
    "channelSku": "SKU Channel",
    "inventoryPushRulesMainStore": "Aturan Push Stok/Penguncian Stok Toko Utama",
    "inventoryPushRuleOperationLog": "Aturan dorong inventori / log operasi",
    "keywordsOfOperationContent": "Kata kunci dari Konten Operasi",
    "pleaseEnterMskuPleaseWrapMultiple": "Silakan masukkan msku, silakan membungkus multiple msku",
    "skuExactSearch": "Pencarian tepat SKU",
    "viewMskuApplicationRules": "Lihat Aturan Push",
    "showAllStores": "Tampilkan semua toko",
    "bindingCommodityInformation": "Mengikat informasi produk",
    "analogComputation": "Perhitungan simulasi",
    "unmatchedChannelGoods": "Produk saluran yang tidak dipasangkan",
    "normal": "Biasa",
    "viewRules": "Meninjau aturan",
    "promptThisFunctionSupportsViewingThe": "Tip: Fitur ini mendukung tampilan aturan yang sebenarnya digunakan MSKUs saat ini saat mendorong inventaris, dengan maksimum 50 MSKUs dilihat sekaligus",
    "newStoresWithoutSubsequentAuthorization": "(Tidak termasuk toko baru untuk otorisasi berikutnya)",
    "newStoreWithSubsequentAuthorization": "(Toko baru dengan otorisasi berikutnya)",
    "allConnectedStores": "Semua toko yang terhubung",
    "pleaseTurnOnTheInventorySynchronization": "Silakan aktifkan sakelar sinkronisasi inventaris di bawah menu Pengaturan Inventaris",
    "Ruledetails": "Rincian peraturan",
    "Default": "Default",
    "thereAreDuplicates": "Ada duplikat",
    "PleaseentertheMSKU": "Silakan masukkan MSKU yang akan ditambahkan, beberapa jeda baris, tidak lebih dari 200 karakter dalam satu baris, sistem akan otomatis terpotong jika melebihi",
    "noNeedToPush": "Tidak perlu mendorong",
    "mskuDoesNotExist": "MSKU tidak ada",
    "mskuInventorypushInventory": "Inventaris MSKU/Inventaris Dorong",
    "proportional": "Sebanding",
    "IfTheMskuForThe": "4. Jika MSKU untuk departemen tidak didorong ke beberapa saluran, harap buat aturan MSKU yang ditentukan dinonaktifkan;",
    "IfSomeMskusNeedTo": "3. Jika beberapa MSKUs perlu didorong sesuai dengan gudang yang ditentukan / toko yang ditunjuk / proporsi yang ditentukan atau nilai tetap, silakan buat aturan MSKU yang ditentukan;",
    "IfTheChannelStoreNeeds": "2. Jika toko saluran perlu menggunakan inventaris yang tersedia dari gudang yang ditunjuk untuk mendorong, silakan buat aturan toko, atur hubungan antara toko dan gudang dan metode push;",
    "IfTheInventoryOfAll": "1. Jika inventaris semua toko saluran didorong sesuai dengan persentase inventaris yang tersedia di gudang yang diaktifkan sepenuhnya, silakan langsung gunakan aturan default dan tetapkan rasio push;",
    "directionsForUse": "Petunjuk penggunaan:",
    "youCanClickViewMskuApplication": "Anda dapat mengklik tombol \"Lihat Aturan Push\" untuk menanyakan aturan bahwa MSKU yang ditentukan sebenarnya digunakan untuk push",
    "thirdPriority": "Prioritas ketiga",
    "secondPriority": "Prioritas kedua",
    "firstPrioritySpecifyMsku": "Prioritas Pertama: Tentukan MSKU",
    "inventoryPushInstructions": "Petunjuk penggunaan push inventaris",
    "mskuCreationTimeIsLaterThan": "Pembuatan MSKU terjadi lebih lambat dari sakelar push inventaris tepat waktu",
    "theStoreBindingTimeIsLater": "Waktu pengikatan toko lebih lambat dari waktu pembukaan sakelar dorong inventaris",
    "theWarehouseCreationTimeIsLater": "Gudang dibuat lebih lambat dari sakelar push inventaris tepat waktu",
    "reasonTheDefaultRuleConditionsAre": "Penyebab: Kondisi aturan default tidak terpenuhi, buat aturan dan dorong sendiri",
    "reasonTheChannelStoreStatusIs": "Alasan: Status toko saluran tidak diotorisasi, harap sambungkan kembali toko dan dorong",
    "reasonThePushRuleForChannel": "Penyebab: Aturan push yang digunakan oleh produk saluran dinonaktifkan, harap aktifkan aturan dan dorong",
    "reasonInventorySettinginventoryPushSwitchIs": "Penyebab: Pengaturan inventaris - Sakelar push inventaris tidak dihidupkan, silakan nyalakan sakelar setelah mendorong",
    "reasonTheStatusOfChannelGoods": "Penyebab: Status produk saluran adalah draf, harap dorong produk setelah dicantumkan",
    "xpriceProtection": "{X}% jaminan harga",
    "order_628344ee0de79247bef8cb6694d1cd38": "Harga/promosi salah",
    "fuzzySearch": "Pencarian Acak",
    "preciseSearch": "Pencarian Lengkap",
    "historicalOrders": "Riwayat Pesanan",
    "nearlyDaysOrder": "Pesanan 90 Hari Terakhir",
    "tipChannelInterfaceRestrictionOnlySupports": "Tips: Pembatasan antarmuka channel hanya mendukung pembaruan inventaris item yang tercantum atau dihapus",
    "theChannelStatusIsAbnormalPlease": "Status produk saluran tidak normal, harap konfirmasi dan dorong",
    "theWarehouseHasBeenDeactivatedPlease": "Repositori dinonaktifkan, harap aktifkan push setelah",
    "whenSearchingAccuratelyTheMaximumNumber": "Saat mencari dengan tepat, Anda dapat mengkueri {X} paling banyak, dan menggunakan carriage return untuk beberapa pesan, dan setiap baris mewakili pesan",
    "pleaseEnterTheOrderNumber": "Silakan masukkan nomor pesanan",
    "settingSuccess": "Pengaturan berhasil",
    "insured": "Asuransi",
    "insuranceRation": "Rasio Asuransi",
    "pleaseSelectInsuranceService": "Silahkan pilih layanan asuransi",
    "pleaseSettingInsuranceFeeForAkulaku": "Silahkan atur jumlah asuransi untuk pesanan Akulaku",
    "settingInsurance": "Atur Jumlah Asuransi",
    "proportionalInsurance": "Asuransi Proporsional",
    "originalInsurancePrice": "Harga Awal Asuransi",
    "notInsured": "Tidak Diasuransikan",
    "plannedInboundAmount": "Nominal Inbound Yang Direncanakan",
    "actualInboundAmount": "Nominal Inbound Aktual",
    "shippingSubsidy": "Potongan Biaya Pengiriman",
    "platformSubsidy": "Subsidi Marketplace",
    "spuAlreadyExistsPleaseRename": "SPU sudah ada, silakan ganti nama",
    "templateType": "Jenis Template",
    "labelSize": "Ukuran Label",
    "barcodePrintingTemplateSettings": "Pengaturan Template Pencetakan Barcode",
    "fontSize": "Ukuran Font",
    "howToGenerate": "Cara menghasilkan",
    "barcodeHeightInsideLabel": "Tinggi Barcode di dalam label",
    "qrCode": "Kode QR",
    "qrCodeLocation": "Lokasi Kode QR",
    "left": "Kiri",
    "top": "Atas",
    "qrCodeSize": "Ukuran Kode QR",
    "generatedByMsku": "Dihasilkan oleh MSKU",
    "generatedByBarcode": "Dihasilkan oleh Barcode",
    "generatedBySpu": "Dihasilkan oleh SPU",
    "mskuCode": "Kode MSKU",
    "printContent": "Konten Print",
    "customAdd": "Tambahan Kustom",
    "goToTheMasterProductPage": "Buka Halaman Master Produk dan pilih produk yang akan di cetak",
    "customContent": "Konten Khusus",
    "pleaseInsertTheContentToBe": "Silakan masukkan konten yang akan dicetak, beberapa baris baru, tidak lebih dari 200 karakter dalam satu baris, sistem akan otomatis terpotong jika melebihi",
    "numberOfPrintsreferringToTheNumber": "Jumlah cetakan(mengacu pada jumlah label untuk barcode)",
    "paperPrintColumn": "Kolom Print Kertas",
    "columnGap": "Celah Kolom",
    "barcodePrinting": "Pencetakan Barcode",
    "atLeastCharactersOrMore": "Setidaknya 3 karakter atau lebih",
    "theFuzzySearchContentMustExceed": "Konten pencarian kabur harus lebih dari 3 karakter, silakan sesuaikan dan coba lagi",
    "ordersCreatedForMoreThan": "Pesanan yang dibuat lebih dari 90 hari akan dipindah ke riwayat pesanan dan tidak dapat diproses",
    "areYouSureYouWantTo2": "Yakin ingin memindahkan pesanan ke Sampai?",
    "moveToDelivered": "Pindah ke Sampai",
    "firstPriority": "Prioritas Pertama",
    "supportAddingMaxDataAt": "Maksimal dapat 2000 data dalam sekali penambahan",
    "supportAddingMaxDataAt1": "Maksimal dapat 1000 data dalam sekali penambahan",
    "stockWillBeIncreasedAfterInbound": "Stok akan meningkat setelah inbound, harap beroperasi dengan hati-hati, hanya inbound massal dari gudang lokal yang didukung",
    "afterConfirmationTheDocumentWillBe": "Setelah konfirmasi, dokumen akan di push kembali ke gudang",
    "stockWillBeDeductedAfterOutbound": "Stok akan dikurangi setelah outbound, harap beroperasi dengan hati-hati",
    "Days1": "30 hari",
    "Days": "7 hari",
    "clickToDownloadAttachment": "Klik untuk mengunduh lampiran",
    "attachment": "Lampiran",
    "addToTheFulfillmentWarehouse": "Tambahkan ke  gudang fulfillment",
    "lockedTime": "Waktu Terkunci",
    "LockedStock": "Stok Terkunci",
    "lockStockOrder": "Kunci Pesanan Stok",
    "onlySupportsFilesDocXlsXlsx": "Hanya Mendukung File *.doc, *.xls, .xlsx, PDF, Excel, png, jpeg, JPG  kurang dari 15M",
    "clickOrDragFilesHereTo": "Klik atau drag file ke sini untuk unggah",
    "clickToDeleteAttachment": "Klik untuk menghapus lampiran",
    "uploadAttachment": "Unggah Lampiran",
    "vatRate": "Tarif Pajak (%)",
    "availableStockLocationCode": "Kode Lokasi Stok Tersedia",
    "stockWil": "Stok akan dikurangi setelah outbound, harap beroperasi dengan hati-hati, hanya outbound massal dari gudang lokal yang didukung",
    "stockWillBeReleasedAfterBeing": "Stok akan dirilis setelah set sebagai Invalid, harap beroperasi dengan hati-hati",
    "xDataSelected": "{x} data dipilih",
    "massSetAsInvalid": "Set sebagai Invalid Massal",
    "massPush": "Push Massal",
    "massRefreshStatus": "Refresh Status Massal",
    "oneItemPerLineSupportExcel": "Satu item per baris (Mendukung salin dan tempel dari Excel, hingga 500)",
    "clickToMassSearch": "Klik untuk mencari secara massal",
    "salesOutboundList": "Daftar Outbound Penjualan",
    "manualOutboundList": "Daftar Outbound Manual",
    "addTemplate": "Tambahkan Template",
    "massOutbound": "Outbound Massal",
    "afterConfirmationTheSelectedDataWill": "Setelah dikonfirmasi, data yang dipilih akan ditambahkan ke gudang fulfillment. Harap operasikan dengan hati-hati",
    "massInbound": "Inbound Massal",
    "itIsDetectedThatTheIssue": "Terdeteksi bahwa pesanan keluar telah diperbarui ke perpustakaan yang tertunda atau telah dihapus, push tidak didukung, harap segarkan dan coba lagi",
    "itIsDetectedThatTheReceipt": "Pesanan pengiriman telah terdeteksi untuk diperbarui ke keadaan tertunda atau pensiun, dorongan tidak didukung, harap segarkan dan coba lagi",
    "refunding": "Pengembalian Dana",
    "maxUploadDocuments": "Maks. upload 3 dokumen",
    "thirdPartyWarehousePushTime": "Waktu Push",
    "thirdPartyNumber": "Nomor Gudang Pihak Ketiga",
    "bulkSearch": "Pencarian masal",
    "thisSizeAlreadyExists": "Ukuran ini sudah ada",
    "theRangeOfSupportedInputIs": "Rentang input yang didukung adalah {min}-{max}",
    "skuName": "Nama SKU",
    "skusellerSKUInJDSellerCenter": "SKU = SKU penjual di JD Seller Center",
    "barcodeHeight": "Tinggi Barcode",
    "QRCodeLocation": "Lokasi kode QR",
    "beLeft": "Kiri",
    "beTop": "Atas",
    "actualPrintingPerformanceDependsOnPrintingResults": "Performa pencetakan yang sebenarnya tergantung pada hasil pencetakan",
    "printingFailedReasonIsEmpty": "Pencetakan gagal, alasan: {} kosong dan tidak mendukung untuk dicetak.",
    "goUpgrade": "Upgrade",
    "jtExpressIdOnlySupportsPaid": "J&T Express ID hanya mendukung merchant paket berbayar, mohon otorisasi ulang setelah mengupgrade paket ERP",
    "ordersAreOutOfStockAnd": "Stok pesanan habis, push stok tidak diperbolehkan",
    "thereAreCurrentlyXPiecesOf": "Saat ini ada {x} data, dan maksimum 50.000 data dapat diekspor sekaligus. Jika Anda perlu mengekspor lebih banyak data, silahkan hubungi customer service",
    "returnInboundListXSuccessfullyCreated": "Daftar Inbound Retur: {x} berhasil dibuat",
    "successXFailedX": "Berhasil {x}, gagal {x}",
    "homeCustomerTips": "Selama periode promosi 99 (2022-09-08 20: 00-2022-09-10 23: 59), layanan statistik pelanggan offline dan hasilnya akan ditampilkan sebagai 0",
    "orderOperationLogTips": "Selama promosi 99 (2022-09-08 20:00-2022-09-10 23:59), hanya log operasi 7 hari terakhir dapat dilihat",
    "stockPushLogTips": "Selama periode promosi 99 (2022-09-08 20: 00-2022-09-10 23: 59), hanya mendukung melihat log operasi hari itu",
    "adjustmentMethodAll": "Metode Penyesuaian: Semua",
    "adjustmentMethodOutbound": "Metode Penyesuaian: Outbound",
    "adjustmentMethodInbound": "Metode Penyesuaian: Inbound",
    "numberRow1": "Baris 1",
    "numberRow2": "Baris 2",
    "numberRow3": "Baris 3",
    "numberRow4": "Baris 4",
    "theStoreAuthorizationIsAbnormalPlease": "Otorisasi toko tidak normal, silahkan kunjungi backend toko untuk memeriksa otorisasi",
    "pleaseSetUpYourJtExpress": "Silhakan atur pengaturan pengiriman J&T Express ID Anda terlebih dahulu",
    "theRangeOfProductImagesThat": "Kisaran gambar produk yang dapat diunggah adalah dari {min} hingga {max}",
    "barcodeTemplate": "Templat Kode Batang",
    "typeDimension": "{type}dimensi",
    "channelOrderInTheCurrentOutbound": "Pesanan channel dalam daftar outbound tersebut telah kehabisan stok dan tidak memenuhi kondisi push. Silahkan push setelah melakukan distribusi ulang di tab \"Proses Pesanan-Menunggu Diproses-Pesanan Bermasalah\"",
    "supportsInputNumberFromMinToMax": "Mendukung untuk masukan angka {min}-{max}",
    "inboundListNumber": "Nomor Inbound",
    "inboundQuantity": "Jumlah Inbound",
    "inboundWarehouse": "Gudang Inbound",
    "theTotalQuantityOfASingle": "Jumlah total cetak satuan tidak boleh melebihi {0}, harap kurangi jumlah produk atau cetakan",
    "aPrintTemplateWithTheSame": "Template percetakan dengan nama yang sama sudah ada, silakan ganti namanya",
    "printTemplateTip": "Jika MSKU/Barcode/SPU melebihi 23 karakter, barcode yang dicetak mungkin tidak dikenali. Kode QR tidak memiliki batasan ini. Silakan pilih jenis template sesuai dengan situasi yang sebenarnya.",
    "printMaxlengthTip": "Jika jumlah karakter melebihi 23, barcode yang dicetak mungkin tidak dikenali.",
    "pleaseSelectTheMainSku": "Silakan pilih SKU utama",
    "adjustmentMethod": "Metode Penyesuaian",
    "purchaseInbound": "Inbound Pembelian",
    "outbound": "Outbound",
    "inbound": "Inbound",
    "manualAdjustment": "Atur Manual",
    "sell": "Menjual",
    "pleaseTickTheDataFirst": "Silahkan centang datanya terlebih dahulu",
    "changeValue": "Perubahan",
    "latestLocationStock": "Stok Lokasi Terakhir",
    "locationNumber": "Nomor Lokasi",
    "postcode": "Kode Pos",
    "sucGeneratedOrFailed": "Berhasil membuat {success} pcs, gagal {fail} pcs",
    "printProductBarcode": "Print Produk Barcode",
    "allPermissions": "Semua izin",
    "pleaseSelectAtLeastOneExport": "Silakan pilih setidaknya satu konten export",
    "pleaseSelectAtLeastOneBasic": "Silakan pilih setidaknya satu informasi dasar",
    "inboundType": "Jenis inbound",
    "orderIdXSuccessfullyReprintedThe": "ID Pesanan: {X} Berhasil mencetak ulang label",
    "orderIdXSuccessfullyPrintedThe": "ID Pesanan: {X} Berhasil mencetak label",
    "setAsInvalidPopulerPrintOrder": "Set tugas cetak produk terlaris sebagai invalid：{X}",
    "createPopularProductPrintOrderTask": "Buat tugas cetak produk terlaris: {X}",
    "theOrderIsAlreadyInThe": "Pesanan sudah dalam tugas mencetak pesanan untuk produk terlaris, silakan cetak dengan hati-hati untuk mencegah pesanan berulang",
    "orderQuantity": "Jumlah Pesanan",
    "numberOFProducts": "Jumlah produk",
    "taskId": "ID Tugas",
    "printProgress": "Progress Cetak",
    "setAsInvalid": "Set Sebagai Invalid",
    "alreadySetAsInvalid": "Invalid",
    "unfinished": "Belum Selesai",
    "creating": "Sedang Dibuat",
    "labelPrintingStatus": "Status cetak label",
    "additionalPrintLabel": "Cetak label tambahan",
    "size": "Ukuran",
    "ordinaryPrintingButPrintingUpTo": "Pencetakan biasa (tetapi mencetak hingga 300 pesanan, semua pesanan akan menghasilkan file PDF, yang membutuhkan waktu lama)",
    "selectedPrintDataXTheSystem": "Data cetak yang dipilih: {X}, sistem mendeteksi bahwa {Y} label telah ditandai dan berhasil dicetak, dan {Z} belum dicetak",
    "taskDetails": "Detail Tugas",
    "toPrintLabel": "Untuk mencetak label",
    "manyProductsWithManyQuantity": "Multi Produk",
    "singleProductWithManyQuantity": "Produk tunggal （jumlah >1)",
    "singleProductWithSingleQuantity": "Produk tunggal （jumlah tunggal）",
    "theTaskIsBeingCreatedPlease": "Tugas sedang dibuat, silakan periksa di list cetak label produk terlaris nanti",
    "createTask": "Buat Tugas",
    "noteOrdersThatHaveBeenPlaced": "Catatan: Pesanan yang telah ditempatkan di tugas cetak label produk terlaris tidak akan masuk tugas lain",
    "totalOrders": "Jumlah Pesanan:",
    "createdByFilter": "Dibuat berdasarkan filter",
    "createByTick": "Dibuat berdasarkan opsi centang",
    "printPopularProduct": "Cetak Label Produk Terlaris",
    "adjustmentNo": "No. Penyesuaian",
    "warehouseReplacementTask": "Tugas pengganti gudang",
    "warehouseChangeSucceeded": "Perubahan gudang berhasil",
    "warehouseChangeFailed": "Gagal mengubah gudang",
    "currentWarehouse": "Gudang saat ini",
    "newWarehouse": "Gudang baru",
    "processingProgress": "Memproses kemajuan",
    "adjustmentType": "Tipe Penyesuaian",
    "thisIsTheInboundPurchasePrice": "Harga Pembelian Inbound/Harga Pembelian outbound",
    "theFinalLocationInventoryOnlyIndicates": "Stok Lokasi terakhir hanya mewakili stok produk yang ada di lokasi, Stok Gudang SKU=total stok SKU yang diakumulasi dari setiap lokasi di gudang",
    "changeDimension": "Ubah dimensi",
    "Replace": "Ganti",
    "completeInboundStatus": "Selesai",
    "setTemplate": "Atur Template",
    "onlyNumberAndMaxLimit": "Maksimal {max} karakter, hanya angka",
    "minimalOrderForWholesaleMustBe": "Jumlah pesanan minimal untuk grosir harus lebih besar dari 1",
    "pleaseConfirmWhetherTheOrderMoved": "Harap konfirmasi apakah ingin pesanan dipindahkan ke Dikirim",
    "moveToShipping": "Pindah ke Sedang Dikirim",
    "priceMustBeCheaperThanThe": "Harga harus lebih murah daripada tier sebelumnya",
    "specificationInformation": "Informasi Varian",
    "theThirdPartyLogisticsIsNot": "Logistik pihak ketiga belum diotoriasasi, sehingga pesanan yang dibatalkan tidak akan diinfokan ke perusahaan logistik",
    "deliverBy": "Diantar oleh",
    "pickupBy": "Dipickup oleh",
    "ParcelInformation": "Informasi Paket",
    "theSameOptionValueExists": "Terdapat nilai pilihan yang sama",
    "pleaseEnterOptionsSuchAsRed": "Silahkan masukkan pilihan, seperti  merah",
    "uploadFailed": "Upload gagal",
    "youCanUploadUpToMaxcount": "Maks. {maxCount} file dapat diunggah, unggahan file dibatalkan:",
    "fileSizeExceedsLimitMaxsize": "Ukuran file melebihi batas: {maxSize}",
    "pictureResolutionExceedsTheLimit": "Resolusi gambar melebihi batas",
    "maxOrderCannotBeLessThan": "Jumlah Maks. tidak boleh kurang dari atau sama dengan jumlah Min",
    "clickDownloadAndTheSystemWill": "Klik download, sistem akan mengekspor sesuai dengan kondisi filter yang dipilih dalam daftar pesanan dan waktu pembuatan pesanan",
    "wholesalePriceCanNotBeLower": "Harga grosir tidak boleh lebih rendah dari {m}% dari harga asli, harap tetapkan harga grosir yang sesuai",
    "mustBeAtLeastX": "Ukuran minimal harus 300 x 300 piksel, kurang dari 2 MB, format JPG atau PNG",
    "thereAreDocumentsThatHaveNot": "Ada file yang gagal diupload",
    "ifYouContinueToSubmitThe": "Jika Anda lanjut kirim, maka data akan dikirimkan dan mengabaikan data yang gagal diunggah",
    "continueToSubmit": "Lanjut Ajukan",
    "thirdPartyLogisticsOnlySupportsThe": "Logistik pihak ketiga hanya bisa digunakan oleh merchant berbayar. Silahkan upgrade paket ERP Anda",
    "numbering": "Penomoran",
    "thereIsNoStoreThatSupports": "Tidak ada toko yang mendukung kloning, silahkan ikat toko terlebih dahulu",
    "productCloneInProgress": "Produk sedang dicopy...",
    "countProductClonedTo": "{count} produk dicopy ke",
    "exportProgress": "Hasil Export",
    "exportType": "Tipe Export",
    "copyToDraft": "Copy ke draft",
    "invalid": "Tidak Valid",
    "editImage": "Edit Gambar",
    "editName": "Edit Nama",
    "editDesc": "Edit Deskripsi",
    "editInfo": "Edit Info",
    "printLabelStatus": "Status Print Label",
    "platformOrderNumber": "Nomor Pesanan Platform",
    "markAsFakeOrder": "Pesanan Palsu",
    "noteOrdersThatHaveBeenPlaced1": "Catatan: Pesanan yang telah ditempatkan di tugas pencetakan  tidak akan masuk tugas lain",
    "theNumberOfVariantCannotExceed": "Jumlah varian tidak boleh melebihi {count}",
    "stepConfirmThePictureInformation": "Langkah 1: Konfirmasi informasi gambar",
    "stepSelectTheProductYou": "Langkah 2: Pilih produk yang ingin Anda terapkan",
    "editImagesInBatches": "Edit gambar massal",
    "productImageTip": "Karena keterbatasan API, untuk gambar produk maks. {maxLength} gambar, ukuran maksimum {size} MB dan resolusi harus lebih besar dari {width}*{height}piksel",
    "fakeOrder": "Pesanan Palsu",
    "whetherFakeOrder": "Adalah Pesanan Palsu",
    "massSettingFakeOrder": "Atur massal tag pesanan palsu",
    "copyAsDraftTip": "produk disalin sebagai Draf",
    "orderXCancelsTheFakeOrder": "Membatalkan tag pesanan palsu untuk pesanan {X}",
    "orderXIsMarkedAsA": "Pesanan {X} ditandai sebagai pesanan palsu",
    "fakeOrders": "Pesanan Palsu",
    "menuProductShopify": "Produk / Shopify",
    "menuProductShopifyAdd": "Produk / Shopify / Tambah Produk",
    "menuProductShopifyEdit": "Produk / Shopify / Edit Product",
    "restrictedByJtExpressTheChinese": "Dibatasi oleh \"J&T Express\", \"Nama produk dalam Mandarin\" tidak boleh melebihi 100; \"Nama produk dalam bahasa Inggris\" tidak boleh melebihi 200\"; \"Kode produk\" tidak boleh melebihi 50; harap ubah informasi produk dan kirimkan produk kembali",
    "tryingToGetChannelShippingLabel": "Sedang menarik informasi label pengiriman channel, silahkan re-push kembali setelah beberapa saat",
    "rangeFieldMessage": "Harus antara {min}〜{max}",
    "duringThePromotionPeriod": "Selama periode promosi (2022-10-09 20: 00-2022-10-10 23: 59), layanan statistik pelanggan offline dan hasilnya akan ditampilkan sebagai 0",
    "onlySupportedDuringTheBigPromotion": "Selama promosi (2022-12-31 20: 00-2023-01-01 23: 59), hanya log operasi 7 hari terakhir dapat dilihat",
    "onlyViewingTheOperationLogOf": "Selama periode promosi (2022-12-31 20: 00-2023-01-01 23: 59), hanya mendukung melihat log operasi hari itu",
    "gineeIsPushingInformationToThe": "Ginee mendorong informasi ke gudang untuk Anda, dan menunggu hasil dorongan dari antarmuka gudang pihak ketiga. Anda dapat terus memproses pesanan lain atau tetap berada di daftar pesanan untuk melihat hasil push.",
    "theDistributedWarehouseTaskWasCreated": "Tugas push telah berhasil dibuat",
    "clickToConfirmTheSystemWill": "Klik untuk mengonfirmasi, sistem akan memanggil API gudang pihak ketiga untuk mendorong info untuk Anda, dan hasil push harus menunggu API gudang pihak ketiga kembali. Harap bersabar.",
    "confirmThatTheSelectedOrderWill": "Konfirmasikan bahwa pesanan yang dipilih akan diberitahukan ke gudang pihak ketiga untuk diproses?",
    "tier": "Tier",
    "min": "Min",
    "max": "Maks",
    "afterConfirmationTheOrderWillBe": "Setelah konfirmasi, pesanan akan didorong, harap refresh halaman nanti untuk melihat hasil push",
    "onceThePushFailsPleaseTry": "Setelah push gagal, silahkan coba push lagi di Push Gudang > Push Gagal",
    "noteThe": "Catatan: Ada penundaan di pembaruan status pesanan dan label dari channel, yang dapat menyebabkan kegagalan push.",
    "theOrderIs": "Pesanan sedang didorong ke gudang pihak ketiga, harap jangan ulangi operasi, silahkan buka push gudang > periksa kemajuan push",
    "shipmentFailed": "Pengiriman Gagal",
    "afterAutomaticDeliveryIsEnabledThe": "Setelah pengiriman otomatis diaktifkan, pesanan yang memenuhi ketentuan pengiriman akan secara otomatis diisi dengan informasi pengiriman dan kemudian dikirimkan. Jika informasi pengiriman tidak memenuhi kondisi pengiriman, perlu dilakukan pengiriman manual.",
    "defaultFirst": "Pilih waktu pertama",
    "defaultLast": "Pilih waktu terakhir",
    "pickUpTime": "Waktu Pickup",
    "automaticShipping": "Pengiriman Otomatis",
    "warehouseToBeAssigned": "Gudang Yang Akan Dialokasikan",
    "pushWarehouse": "Push Gudang",
    "tryingToLoad": "Mencoba memuat",
    "orderTotalTotalProductAmount": "Total pesanan = Jumlah produk total + pengiriman + pajak + asuransi + biaya layanan + komisi - total diskon",
    "onceVoidedTheOrderWillBe": "Setelah tidak valid, pesanan akan dipindahkan ke Sudah Tidak Valid",
    "addedChannelProducts": "Menambah produk channel",
    "syncedChannelProducts": "Mensinkronkan produk channel",
    "syncedChannelProductsChangedFrom": "Produk channel yang disinkronkan, {1} diubah dari {2} menjadi {3}",
    "produkChannelTelahDisinkronkanDanInformasi": "Produk channel telah disinkronkan, dan informasi SKU {} telah dihapus",
    "theChannelProductsAreSynchronizedAnd": "Produk channel disinkronkan, dan channel mengembalikan SKU baru{}",
    "editedProductNameFromTo": "Memperbarui nama produk dari {1} menjadi {2}",
    "editingProductCategoryFromTo": "Mengedit katogori produk dari {1} ke {2}",
    "editingLongDesecriptionForVariant": "Memperbarui Deskripsi Panjang produk, dari {0} ke {1}",
    "editingPriceForVariantFrom": "Mengedit harga untuk varaiant {0}, dari {1} menjadi {2}",
    "editedStockChannelVariantName": "Memperbarui stok untuk variant {0}, dari {1} menjadi {2}",
    "editedChannelSkuVariantName": "Memperbarui SKU untuk varaiant {0}, dari {1} menjadi {2}",
    "deletedVariantType": "Menghapus tipe varian {}",
    "addedVariantType": "Menambahkan tipe varian {}",
    "deletedOption": "Hapus opsi {2} di bawah jenis varian {1}",
    "addedOption": "Pada variant {1} ditambahkan opsi {2}",
    "updatedProductImage": "Memperbarui gambar produk",
    "updatedVariantImageForVariatName": "Gambar varian  diperbarui untuk nama varian {}",
    "updatedOfProductSizeFrom": "Ukuran {1} produk diperbarui, diubah dari {2} menjadi {3}",
    "updatedProductWeightChangedFrom": "Berat produk diperbarui, diubah dari {0} menjadi {1}",
    "addTheCheckedLogisticAnd": "Menambahkan logistik {1} yang dipilih, dan tidak memilih logistik {2}",
    "deleteChannelProduct": "Menghapus produk channel {}",
    "copyProductToChannelStore": "Menyalin produk {1} ke channel {2} toko {3}",
    "thisItemIsListed": "Produk ini telah diaktifkan{}",
    "thisItemHasBeenRemoved": "Produk ini telah dinonaktifkan{}",
    "variationNa": "Nama variasi {0}, SKU channel {1}memenuhi aturan pengikatan otomatis, dan mengikat ke MSKU {2}",
    "variationNameTheChannelSku": "Nama variasi {0}, SKU channel {1}memenuhi aturan pembuatan otomatis, dan membuat MSKU {2}",
    "variatio": "Nama varian adalah {0}, SKU channel adalah {1}, secara manual mengikatkan MSKU{2}",
    "variationNameIsChannelSku": "Nama varian adalah {0}, SKU channel adalah {1}, secara manual membuat MSKU{2}",
    "mskumanuallyUnboundTheCorrespondingChannelSku": "MSKU{1}secara manual melepas ikatan SKU channel terkait {3} pada halaman Master Produk",
    "sinceStoreHasBeenDeletedchannel": "Karena toko {1} telah dihapus,\nchannel SKU{2}telah dihapus dari MSKU{3}",
    "sinceMskuHasBeenRemovedchannelSkuwas": "Karena MSKU{1} telah dihapus,\nchannel SKU{2}telah dihapus dari MSKU{3}",
    "manuallyAddedMasterProduct": "Menambahkan Master Produk secara manual",
    "manuallyCreatedMasterProductByStore": "Membuat Master Produk manual berdasarkan toko",
    "manuallyCreatedMasterProductByChannel": "Membuat Master Produk manual dari produk channel",
    "createdMasterProductByImportData": "Membuat Master Produk  dengan mengimpor data",
    "autoCreateMasterProduct": "Membuat Master Produk otomatis",
    "createdMasterProductByScrapping": "Membuat Master Produk dari scrape",
    "editedMasterProductNameFrom": "Mengedit nama Master Produk dari {0} menjadi {1}",
    "editedMasterProductCategoryFrom": "Mengedit kategori Master Produk dari {0} menjadi {1}",
    "editedMasterProductSpuFrom": "Mengedit SPU Master Produk dari {0} menjadi {1}",
    "editedMasterProductShortDescriptionFrom": "Mengedit Deskripsi Singkat Master Produk dari {0} ke {1}",
    "editedTheMasterProductLongDescription": "Mengedit Deskripsi Panjang Master \nProduk dari {1} menjadi {2}",
    "editedMskuPriceFromTo": "Mengedit harga  MSKU {0} dari {1} ke {2}",
    "editedAvailableStockForMskuFrom": "Mengedit Stok Tersedia dari  MSKU {0} dari {1} ke {2}",
    "editedBarcodeOfMskuFrom": "Mengedit MSKU {0} dari {1} ke {2}",
    "updatedOfMasterProductSize": "Memperbarui ukuran Master Produk {1} dari {2} ke {3}",
    "updatedTheWeightOfMasterProduct": "Memperbarui berat Master Produk  dari {1} menjadi {2}",
    "updatedAvailableStockTheUpdatedStock": "Stok yang tersedia telah \ndiperbarui, stok yang diperbarui adalah {}",
    "deleteMaste": "Hapus Master Produk {}",
    "deleteMskus": "Hapus MSKU {}",
    "printingSpus": "Mencetak barcode SPU{}",
    "printingMskuBarcode": "Mencetak barcode MSKU{}",
    "printingBarcode": "Mencetak barcode {}",
    "mskuHasBeenAddedTo": "MSKU{1} telah ditambahkan ke gudang {2}",
    "mskuHasBeenAddedAndInbound": "MSKU{1} telah ditambahkan dan inbound ke gudang {2}.",
    "theAutomaticBindingOfMasterSku": "Pengikatan otomatis  Master SKU diaktifkan, aturannya adalah: {}",
    "allStoresEnabledDefaultConfigurationFor": "Semua toko diaktifkan (konfigurasi default untuk toko yang baru diotorisasi)",
    "activateByChanelsChanels": "Aktifkan berdasarkan channel, channel{}",
    "openByStoreStore": "Aktifkan berdasarkan toko, toko {}",
    "theAutomaticBindingOfTheMaster": "Pengikatan otomatis aturan SKU Master dimatikan.",
    "theAutomaticCreateMasterSkuRule": "Aturan pembuatan master SKU otomatis diaktifkan, aturannya adalah：{}",
    "turnOffTheAutomaticCreateMaster": "Aturan buat master SKU otomatis dimatikan",
    "newPrice": "Harga Baru",
    "priceCannotBeModifiedDuringThe": "Harga tidak dapat diubah selama periode promosi",
    "thereAreDuplicateExportTasksRemaining": "Terdapat tugas export yang berulang, sisa waktu kedaluwarsa: {0}s",
    "theSingleUrlCannotBeEmpty": "URL tidak boleh kosong, harap push lagi nanti",
    "theLogisticsProviderCannotBeEmpty": "Penyedia logistik tidak boleh kosong, silakan hubungi penyedia layanan gudang untuk menambahkan informasi penyedia logistik",
    "awbNumberCannotBeEmptyPlease": "Nomor AWB tidak boleh kosong, silahkan tekan push  lagi  nanti",
    "syncSuccessfully": "Sinkronisasi Sukses",
    "syncFailed": "Sinkronisasi Gagal",
    "sourceOfError": "Sumber eror",
    "channelErrorPleaseContactTheChannel": "Kesalahan marketplace: harap hubungi marketplace untuk pemecahan masalah",
    "warehouseErrorPleaseContactTheWarehouse": "Gudang eror: silahkan hubungi penyedia layanan gudang untuk pemecahan masalah",
    "unknownErrorPleaseContactGineeErp": "Eror tidak diketahui: Silakan hubungi layanan pelanggan Ginee OMS untuk pemecahan masalah",
    "recreate": "Buat Ulang",
    "failedRecreate": "Gagal dibuat ulang",
    "outboun": "Nomor outbound: {x} nomor pesanan terkait {y} berhasil dibuat; Performer: {z} Gudang outbound: {s}",
    "outOrderNumberXAssociatedOrder": "Nomor outbound: {x} nomor pesanan terkait {y} berhasil dibuat ulang; Performer: {z} Gudang Outbound: {s}",
    "orderNumberXAssociatedOrder": "Nomor outbound: {x} nomor pesanan terkait {y} gagal dibuat ulang",
    "rderNumberXAssociatedOrder": "Nomor outbound: {x} nomor pesanan terkait {y} \"Tidak dipush; Performer: {z} Gudang outbound: {s};",
    "oer": "Nomor outbound: {x} nomor pesanan terkait {y} \"Push berhasil\"; Performer: {z} Gudang outbound: {s};",
    "tboundder": "Nomor outbound: {x} nomor pesanan terkait {y} \"Push gagal\"; Performer: {z} Gudang outbound: {s}; alasan kegagalan: {a}",
    "odOrderNumberXAssociatedOrder": "Nomor outbound: {x} nomor pesanan terkait {y} \"berhasil outbound\"; Performer: {z} gudang outbound: {s}",
    "outboundOrderNumberXAssociatedOrder": "Nomor outbound: {x} nomor pesanan terkait {y} \"berhasil direfresh\"; Performer: {z} Gudang outbound: {s}",
    "inboundOrderNumberXAssociatedOrder": "Nomor Inbound: {x} nomor pesanan terkait {y} \"gagal refresh\"; Performer: {z} Gudang outbound: {s}; Alasan kegagalan: {a}",
    "orderwe": "Nomor Outbound: {x} nomor pesanan terkait {y} \"Tidak valid\"; Performer: {z} gudang outbound: {s};",
    "outboundOrderNumberXRelatedOrder": "Nomor outbound: {x} nomor pesanan terkait {y}; SKU: {w} \"Tidak Valid\"; Performer: {z} Gudang Outbound: {s};",
    "outboundOrderNumberXHasBeen": "Nomor outbound: {0} telah dihapus, pesanan terkait {1} Alasan penghapusan: pesanan telah disisihkan",
    "inboundNoXPerformerYInbound": "Nomor Inbound: {x} Performer: {y}, Gudang Inbound: {z} Buat Daftar Inbound",
    "inboundNoXNotPushedPerformer": "Nomor Inbound: {x}; \"Belum Dipush\"; Performer: {y}, Gudang Inbound: {z}",
    "inboundNumberXSuccessfulPushPerformer": "Nomor Inbound: {x}; \"Push berhasil\"; Performer: {y}, Gudang Inbound: {z}",
    "inboundNoXPushFailedPerformer": "Nomor Inbound: {x}; \"Gagal Push\"; Performer: {y}, Gudang Inbound: {z}; Alasan Gagal{a}",
    "inboundNoXRefreshSuccessfulPerformer": "Nomor Inbound: {x}; \"Refresh berhasil\"; Performer: {y}, Gudang inbound: {z}",
    "nomorInboundXRefreshGagalPerformer": "Nomor Inbound: {x}; \"Refresh Gagal\"; Performer: {y}, Gudang Inbound: {z}; Alasan Kegagalan: {a}",
    "inboundNoXinvalidperformerYInboundWarehouse": "Nomor Inbound: {x};\"Tidak Valid\";Performer: {y}, Gudang Inbound: {z}",
    "inboundNoXskuainvalidperformerYInboundWarehouse": "Nomor Inbound: {x};SKU:{a};\"Tidak Valid\";Performer {y}, Gudang Inbound: {z}",
    "inboundNoXwaitingInboundPerformerY": "Inbound No.: {x};\"Menunggu Inbound\"; Performer: {y}, Gudang Inbound: {z}",
    "receiptNoXinboundPerformerYInbound": "Inbound No.: {x};\"Sudah Inbound\"; Performer: {y}, Gudang Inbound: {z}",
    "push": "Push",
    "productsChannelEditProduct": "Produk / {channel} / Edit Product",
    "productsChannelAddProduct": "Produk / {channel} / Tambah Produk",
    "dueToPossibleDelaysInSyncing": "Dikarenakan adanya  kemungkinan penundaan dalam sinkronisasi data, harap tunggu beberapa detik setelah sinkronisasi sebelum memilih brand.",
    "pleaseEnterBrandKeywordsToSync": "Harap masukkan kata kunci brand untuk disinkronkan",
    "cantFindTheBrandClickMe": "Tidak dapat menemukan brand Anda? klik di sini untuk menyinkronkan",
    "outboundCompletionTime": "Waktu penyelesaian pengiriman",
    "theImageAspectRatioIsInvalid": "Rasio aspek gambar tidak valid, hanya gambar dengan rasio aspek {rasio} yang didukung",
    "theFileWillBeDisplayedSynchronously": "File akan ditampilkan secara sinkron dalam Daftar Riwayat Export, dan hanya akan disimpan selama 15 hari, mohon unduh file tepat waktu",
    "exportRecords": "Riwayat Export",
    "theFollowingOrdersNeedToBe": "Sebelum mengatur pengiriman, informasi pengiriman perlu ditambahkan pada pesanan dibawah ini. Ginee telah memfilter secara otomatis {x} pesanan Shopee yang statusnya masih Pending dan tidak diizinkan untuk diatur pengiriman",
    "arrangementFailed": "Pengaturan gagal",
    "arranging": "Sedang diatur",
    "pleaseGoToPendingPrintOrder": "Silahkan ke halaman Menunggu Dicetak untuk membuat tugas cetak Label Pengiriman Produk Populer",
    "limitasiApiSkuTidakDapatDi": "Limitasi API, SKU tidak dapat di edit",
    "ordersThatAreNotBoundTo": "Pesanan yang tidak terikat dengan MSKU tidak akan masuk ke tugas cetak label produk populer",
    "customShop": "Toko Custom",
    "continueCreate": "Lanjut buat",
    "theStoreWasCreatedSuccessfully": "Toko berhasil dibuat",
    "warehouseLevelAllPages": "Tingkatan Gudang - Semua Halaman",
    "warehouseLevelClickToCheck": "Tingkatan Gudang - Export yang Dipilih",
    "locationLevelAllPages": "Tingkatan Lokasi - Semua Halaman",
    "locationLevelClickToCheck": "Tingkatan Lokasi - Export yang dipilih",
    "inboundManagementoperationLog": "Log manajemen/operasi masuk",
    "outboundManagementoperationLog": "Log manajemen/operasi keluar",
    "inboundNumberXxxxPushedToWarehouse": "Nomor inbound: {xxxx} didorong ke gudang: {xxxx} gagal",
    "reasonTheOrderNumberXxxxHas": "Alasan: Nomor pesanan {xxxx} telah memenuhi ketentuan push",
    "inboundNoXxxxHasBeenSuccessfully": "Nomor inbound: {xxxx} telah berhasil dikirim ke gudang: {XXXX}, dan mendapatkan nomor gudang {XXXX}: #xxxxx#",
    "inboundNumberXxxxHasSuccessfullyCreated": "Nomor inbound: {xxxx} telah berhasil membuat tugas untuk mendorong ke gudang: {XXXX}, dan menunggu respon dari API gudang {XXX}",
    "xxWarehouse": "{XX} gudang",
    "failureReasonXxxxErrorSourceXxxxx": "Alasan Gagal: {XXXX}, Sumber Eror: {XXXXX}",
    "outboundNumberFailedToPushXxxx": "Nomor outbound: Gagal mendorong {xxxx} ke {XXXX}",
    "outboundNumberXxxxHasBeenSuccessfully": "Nomor outbound: {xxxx} telah berhasil dikirim ke {XXXX}, dan memperoleh nomor gudang pihak ketiga: {xxxxx}",
    "outboundNumberXxxxHasSuccessfullyCreated": "Nomor outbound: {xxxx} telah berhasil membuat tugas untuk mendorong ke gudang: {XXXX}, dan sedang menunggu tanggapan dari API gudang {penyedia fulfillment}",
    "inboundOrderPushed": "Push dari inbound list",
    "outboundOrderPushed": "Push dari outbound list",
    "sendersFullAddress": "Alamat Pengirim",
    "sendersPostalCode": "Kode Pos Pengirim",
    "senderArea": "Area Pengirim",
    "manualPush": "Push Manual",
    "pendingOrderOldVer": "Menunggu Diproses (Ver. Lama)",
    "pleaseGoToTheDeliverySettings": "Silakan buka pengaturan pengiriman untuk mengisi",
    "noCustomStoreHasBeenDetected": "Jika tidak ada toko khusus yang terdeteksi, harap buat toko khusus terlebih dahulu.",
    "goToCreate": "Pergi ke Buat",
    "enableAtLeastOneVariation": "Enapur Atlis Onevariatiang.",
    "createdAChannelProductBy": "Membuat produk saluran {3} untuk toko {2}dengan metode {1}",
    "storeSyncedChannelProduct": "Toko {1}mensinkronisasikan produk {2}",
    "ChangedFromTo": "{1} diubah dari {2} menjadi {3}",
    "deletedSkuWithVariantName": "Menghapus SKU dengan nama varian {}",
    "addedSkuWithVarianName": "Menambahkan SKU dengan nama varian {}",
    "throughStoreDeleted": "Melalui metode {1}, toko {2} menghapus produk {3}",
    "deletedVar": "Menghapis tipe varian {}",
    "addedVa": "Menambahkan tipe varian {}",
    "updatedVarianImageWithVarianName": "Gambar varian  dengan nama varian {} diperbarui",
    "updatedProductSizeAndWeightInfo": "Info ukuran dan berat produk diperbarui, {1} diubah dari {2} menjadi {3}",
    "logisticsUpdatedChangedFrom": "Logistik diperbarui, {1} diubah dari {2} menjadi {3}",
    "atLeastOneSpecificationIsEnabled": "Setidaknya satu spesifikasi diaktifkan",
    "addMasterProduct": "Tambah Master Produk",
    "editMasterProduct": "Edit Master Produk",
    "autoCreateMasterSku": "Auto Buat Master SKU",
    "autoBindMasterSku": "Auto Ikat Master SKU",
    "addToWarehouse": "Tambah ke Gudang",
    "masterProductOperationLog": "Log Operasi Master Produk",
    "solutionPleaseFirstCheckWhetherThe": "Solusi: Silahkan cek dulu apakah toko tersebut bertipe Official Store atau Power Merchant store. Jika tidak, harap perbarui toko di seller center terlebih dahulu. Jika sudah, periksa apakah integrasi toko telah dilepaskan di seller center.",
    "updatedProductLengthFromTo": "Panjang produk diperbarui dari {0} ke {1}",
    "updatedProductWidthFromTo": "Lebar produk diperbarui dari {0} ke {1}",
    "updatedProductHeightFromTo": "Tinggi produk diperbarui dari {0} ke {1}",
    "addedMsku": "Menambahkan MSKU{}",
    "onlyIntegersAreSupported": "Hanya bilangan bulat yang didukung",
    "masstSku": "Edit Masal SKU",
    "newOriginalPrice": "Harga asli baru",
    "newselingPrice": "Harga jual baru",
    "applicationCompletion": "Aplikasi selesai",
    "editPrice": "Sunting Harga",
    "theStatusOfProductHas": "Status produk {1} telah berubah dari {2} menjadi {3}",
    "theChannelPriceOfMsku": "Harga chanel MSKU {1} di toko {2} telah berubah dari {3} menjadi {4}",
    "defaultPriceOfMskuChanged": "Harga default MSKU {1} diubah dari {2} menjadi {3}",
    "solutionPleaseSetThePickupWarehouse": "Solusi: Silakan atur informasi gudang Pickup di My account>seller profile>warehouse di seller center.",
    "afterSuccessfulAuthorizationYouCanUse": "Setelah otorisasi berhasil, Anda dapat menggunakan layanan fulfillment Keeppack untuk mengelola produk masuk dan keluar; informasi stok dan produk gudang Keeppack akan secara otomatis disinkronkan dua arah untuk meningkatkan efisiensi manajemen",
    "clickTheGoToAuthorizationButton": "Klik tombol [otorisasi], Anda akan mengunjungi halaman login Keeppack, silahkan masukkan password akun yang digunakan untuk login ke sistem Keeppack, dan setelah sistem Keeppack mengkonfirmasi bahwa informasi login Anda sudah benar, Anda akan berhasil mengotorisasi Keeppack untuk sistem Ginee.",
    "beSureToCompleteTheMatching": "Pastikan untuk melengkapi pencocokkan produk master di [Gudang-Gudang Keeppack-Informasi Produk]",
    "ifThereIsTooMuchCommodity": "Jika ada terlalu banyak data produk, itu akan memakan waktu lama, harap bersabar",
    "imAutomaticallyPullingTheKeeppackWarehouse": "Secara otomatis menarik daftar gudang Keeppack dan informasi produk untuk Anda",
    "automaticallyPullCommodityInformation": "Secara otomatis menarik info produk",
    "automaticallyPullWarehouseInformationValidWarehouse": "Secara otomatis menarik informasi gudang (terbatas pada gudang yang valid)",
    "senderMobilePhone": "Ponsel Pengirim",
    "theBindingRecordIsOnlyValid": "Riwayat Pengikatan hanya berlaku selama 30 hari, dan catatan pengikatan lebih dari 30 hari tidak akan dapat dilihat",
    "pullRecord": "Riwayat Penarikan",
    "batchPulling": "Tarik Massal",
    "mskuBinding": "Pengikatan MSKU",
    "bindingMsku": "Ikat MSKU",
    "pullTime": "Waktu Penarikan",
    "commodityValue": "Nilai Produk",
    "theTotalValueOfGoodsReturned": "Nilai total produk yang dikembalikan oleh antarmuka gudang pihak ketiga di gudang ini",
    "totalWarehouseInventoryReturnedByThreeparty": "Total stok gudang yang dikembalikan oleh antarmuka gudang pihak ketiga",
    "inventoryWaitingForReceiptReturnedBy": "Stok menunggu inbound dikembalikan oleh antarmuka gudang pihak ketiga",
    "availableInventoryReturnedByThreepartyWarehouse": "Stok yang tersedia dikembalikan oleh antarmuka gudang pihak ketiga",
    "gineePushesItToTheWarehouse": "Ginee push untuk membuat gudang",
    "sanfangcangSku": "SKU Gudang Pihak Ketiga",
    "nameOfGoodsInTheWarehouse": "Nama produk di gudang dikembalikan oleh antarmuka gudang pihak ketiga",
    "commodityNameOfWarehouse": "Nama Produk Gudang",
    "mskuIsBound": "MSKU terikat",
    "unboundMsku": "MSKU tidak terikat",
    "startWithAndBeNo": "Mulai dengan 0 maks. 12 digit",
    "noMoreThanEnglishCharacters": "Tidak lebih dari 35 huruf",
    "usedToPushReceiptDocumentsTo": "Digunakan untuk push daftar inbound ke Keeppack",
    "xxxWarehousesHaveBeenAddedWith": "Tambahkan gudang {XXX}, ID gudang: {XXX},{XXX}",
    "OnceTheKeeppackInterfaceUpdates": "2. Setelah antarmuka Keeppack memperbarui status gudang menjadi Dinonaktifkan, gudang di Ginee akan dinonaktifkan secara sinkron, dan informasi produk/stok tidak dapat disinkronkan lagi",
    "gineeOnlyReturnsTheWarehouseWith": "1. Ginee hanya menyinkronkan antarmuka Keeppack agar Anda mengembalikan gudang yang status salurannya Aktif",
    "warmTip": "Tips",
    "startPullingTheLatestWarehouseInformation": "Setelah mengklik \"Konfirmasi\", itu akan mulai menarik informasi repositori terbaru keeppack untuk Anda, tunggu antarmuka Keeppack mengembalikan informasi, harap bersabar",
    "pullWarehouseInformation": "Tarik informasi gudang",
    "pullLog": "Tarik log",
    "warehouseUpdateTimeReturnedByKeeppack": "Waktu pembaruan gudang yang dikembalikan oleh antarmuka Keeppack",
    "warehouseCreationTimeReturnedByKeeppack": "Waktu pembuatan gudang dikembalikan oleh antarmuka Keeppack",
    "channelStatus": "Status channel",
    "warehouseId": "ID Gudang",
    "senderInformation": "Informasi Pengirim",
    "automaticCreationOfMainCommodity": "Secara otomatis membuat produk master",
    "automaticUnbindingMsku": "Lepaskan Ikatan MSKU secara otomatis",
    "automaticBindingMsku": "Secara otomatis mengikat MSKU",
    "manuallyCreateTheMainCommodity": "Buat produk master secara manual",
    "manuallyUnbindMsku": "Lepas MSKU Manual",
    "manuallyBindMsku": "Ikat MSKU secara manual",
    "startBinding": "Mulai Ikat",
    "andAllUnpairedThreepartyWarehouseSkus": "Semua SKU gudang Keeppack yang saat ini dan selanjutnya baru ditambah yang belum terikat",
    "allCurrentlyUnpairedThreepartyWarehouseSkus": "Semua SKU gudang Keeppack yang saat ini tidak diikat",
    "appliedRange": "Jangkauan",
    "whenTheThreepartyWarehouseSkuIs": "Ketika SKU gudang pihak ketiga sama persis dengan MSKU produk utama yang telah dibuat, maka secara otomatis akan terikat",
    "bindingRules": "Aturan Ikat",
    "reasonItConformsToTheMsku1": "Alasan: Sesuai dengan pengaturan produk - produk gudang secara otomatis membuat aturan MSKU",
    "reasonThreepartyWarehouseHasBeenUnbound": "Alasan: Gudang pihak ketiga tidak terikat",
    "reasonItConformsToTheMsku": "Alasan: Sesuai dengan pengaturan produk - produk gudang secara otomatis terikat pada aturan MSKU",
    "goodsOfWarehouseXxxxxXxxxxSku": "Gudang: Produk {XXXXX}: {XXXXX}, SKU: {XXXXX} gudang pihak ketiga berhasil membuat produk master dalam sistem: {XXXXX}, MSKU: {XXXXX}",
    "commodityXxxxxOfWarehouseXxxxxThreeparty": "Gudang: Produk {XXXXX}: {XXXXX}, SKU: {XXXXX}, dan MSKU: {XXXXX} tidak terikat di gudang pihak ketiga",
    "commodityXxxxxOfWarehouseXxxxxAnd": "Gudang: Produk {XXXXX}: {XXXXX}, SKU: {XXXXX} dan MSKU: {XXXXX} dari gudang pihak ketiga berhasil diikat",
    "viewThePullRecord": "Lihat catatan penarikan",
    "gineeIsPullingCommodityInformationFrom": "Ginee menarik informasi produk dari gudang untuk Anda, dan menunggu antarmuka gudang pihak ketiga mengembalikan hasilnya, Anda dapat melanjutkan memproses hal-hal lain atau melihat kemajuan penarikan dan hasil dalam catatan penarikan",
    "createAPullTask": "Buat tugas penarikan",
    "commodityCreationTime": "waktu pembuatan produk",
    "inOrderToImproveThePulling": "Untuk meningkatkan efisiensi penarikan, durasi sekali penarikan tidak boleh melebihi 15 hari;",
    "bindingResultXSkusHaveBeen": "Hasil pengikatan: Pengikatan otomatis {X} SKU dan MSKU telah berhasil diselesaikan untuk Anda",
    "automaticBindingSucceeded": "Pengikatan otomatis berhasil",
    "mskuCreationIsNotSupportedFor": "SKU gudang pihak ketiga yang telah mengikat MSKU tidak mendukung pembuatan MSKU, sistem akan secara otomatis memfilternya untuk Anda, pastikan untuk mengetahuinya",
    "mainProductImage": "Gambar Master Produk",
    "AfterUnbindingTheStatusOf": "3. Setelah melepas ikatan, status daftar masuk dan keluar yang sesuai dengan MSKU produk di gudang pihak ketiga diperbarui menjadi Tidak Valid.",
    "AfterUnbindingTheInventoryList": "2. Setelah melepas ikatan, data stok yang dihasilkan oleh pengikatan MSKU ke produk gudang pihak ketiga akan dihapus.",
    "AfterUnbindingTheBindingRelationship": "1. Setelah melepas ikatan, hubungan pengikatan antara produk gudang pihak ketiga dan produk utama akan dilepaskan.",
    "systemRecommended": "Rekomendasi sistem",
    "listingPullFailed": "Gagal menarik informasi produk",
    "theListingWasPulledSuccessfully": "Informasi produk berhasil ditarik",
    "pullResultTheSkuInformationOf": "Hasil tarik: Informasi SKU gudang pihak ketiga telah ditarik, SKU: XXXX/XXXX",
    "sourceOfErrorRepository": "Sumber kesalahan: Gudang",
    "reasonForFailureXxxxxx": "Alasan gagal: XXXXXX",
    "pullResultAddedXxxPiecesOf": "Tarik hasil: Tarik ke {XXX} data",
    "resultcause": "hasil/alasan",
    "pullStatus": "tarik status",
    "pullDuration": "Tarik Durasi",
    "noteThatPullRecordsAreOnly": "Harap dicatat bahwa catatan penarikan hanya akan disimpan dalam sistem selama 7 hari, dan catatan penarikan lebih dari 7 hari tidak dapat dicari",
    "xxxData": "{XXX} data",
    "viewFailureInformation": "Lihat informasi yang gagal",
    "upToCommoditiesCanBe": "Mendukung pemilihan maksimum 50 produk sekaligus untuk menarik informasi promosi",
    "activityInformationPullFailed": "Gagal menarik informasi promosi",
    "pullResultXxxChannelCommodityActivity": "Hasil tarik: {X} informasi promosi produk channel telah berhasil ditarik untuk Anda",
    "activityInformationPullSucceeded": "Informasi promosi berhasil ditarik",
    "onlyTheActivityInformationOfChannel": "Hanya dukungan untuk menarik informasi promosi produk channel yang telah disinkronkan ke Ginee dan yang status produknya terdaftar",
    "pullByCommodity": "Tarik berdasarkan produk",
    "pullByStore": "Tarik berdasarkan toko",
    "pullActivity": "Tarik Promosi",
    "activeReservationSettings": "Pengaturan Reservasi Promosi",
    "synchronizationTime": "Waktu Sinkronisasi",
    "mskuReserved": "MSKU & Disimpan",
    "skuApplication": "SKU & Pengajuan",
    "notReserved": "Tidak Direserve",
    "partialReservation": "Direserve Sebagian",
    "reserved": "Direserve",
    "activeReserveInventory": "Stok Promosi yang Direserve",
    "pleaseTurnOnTheInventoryMonitoring": "Silakan aktifkan [Monitor Stok dan Sinkronisasi] untuk beroperasi",
    "afterTheSetupIsCompletedThe": "Setelah pengaturan selesai, stok promosi yang Akan Datang tidak akan direserve terlebih dahulu. Ginee hanya akan merserve stok otomatis untuk promosi yang Sedang Berjalan dan yang akan berjalan dalam waktu 24 jam kedepan",
    "automaticallyReservedOnlyForInprogressActivities": "Reservasi Otomatis Hanya Untuk Promosi yang Sedang Berjalan",
    "onceSetUpTheSystemWill": "Setelah pengaturan selesai, promosi yang Akan Datang dan yang Sedang Berjalan  akan secara otomatis mereserve stok promosi",
    "unstartedAndInprogressActivitiesAreAutomatically": "Otomatis Mereserve Promosi yang Akan Datang dan yang Sedang Berjalan",
    "beSureToSetAReservation": "Harap pastikan untuk mengatur strategi reservasi untuk menjamin bahwa stok promosi telah direserve sesuai dengan kebutuhan Anda;",
    "reservationPolicySettings": "Pengaturan Strategi Reservasi",
    "reservationPolicy": "Strategi Reservasi",
    "afterItIsEnabledTheSystem1": "Setelah diaktifkan, sistem akan secara otomatis mereserve stok promosi sesuai dengan promosi platform (Hanya mendukung Shopee), dan dapat dilihat di [Stok - Stok Promosi yang Direserve]/[Stok - Daftar Stok]",
    "automaticallyReserveActiveInventory": "Reservasi Stok Promosi Otomatis",
    "afterItIsEnabledTheSystem": "Setelah diaktifkan, sistem akan secara otomatis menarik informasi promosi platform Shopee untuk Anda, yang dapat dilihat di daftar [Stok - Stock yang Direserve]",
    "automaticallyPullPlatformActivity": "Tarik promosi platform secara otomatis",
    "theClosedEventDataSystemIs": "Sistem data promosi tertutup hanya akan disimpan selama 30 hari, di luar 30 hari, tidak dapat dilihat di daftar ini",
    "reasonNotReserved": "Alasan tidak disimpan",
    "releaseTheReservation": "Rilis Stok yang Direserve",
    "TheActiveInventoryReservedBy": "3. Stok promosi MSKU yang direserve di gudang akan diperbarui menjadi 0",
    "AfterReleaseTheChannelActivity1": "2. Setelah rilis, stok yang diterapkan untuk channel promosi tidak akan terpengaruh dan masih dapat dijual di platform, dengan risiko oversold",
    "AfterReleaseTheChannelActivity": "1. Setelah rilis, status promosi channel tidak akan terpengaruh",
    "confirmTheReleaseOfCurrentlyActive": "Apakah Anda yakin akan merilis Stok Promosi yang Direserve ini dipesan?",
    "theActiveInventoryThatWasReserved": "Stok aktif yang disimpan telah berhasil dihapus",
    "theActiveReservedInventoryWasEdited": "Stok Promosi yang Direserve berhasil diedit",
    "obligate": "Simpan",
    "application": "Ajukan",
    "reservedInventoryEditsAreOnlySupported": "Promosi yang belum dimulai dapat mengedit stok cadangan, termasuk memodifikasi gudang yang disimpan dan nilai stok yang disimpan",
    "mskuHasNoWarehouseInformationAnd": "MSKU tidak memiliki informasi gudang dan tidak dapat berhasil disimpan",
    "mskuWithUnboundChannelSkuPlease": "Jika SKU Channel tidak terikat ke MSKU, silakan masuk ke daftar master produk/daftar produk channel untuk menyelesaikan pengikatan dan masuk ke gudang",
    "automaticReservationOfActiveInventorySetting": "Pengaturan stok promosi yang direserve otomatis tidak diaktifkan",
    "commodityXxxMoveoutActivityIdxxx": "Produk Channel: {XXX} ID Promosi yang Dihapus: {XXX}",
    "xxxManuallyReleaseTheReservedActive": "{XXX} Rilis secara manual stok promosi yang disimpan",
    "meetTheActiveInventoryReleaseRules": "Sesuaikan dengan aturan rilis stok aktif, rilis otomatis",
    "complyWithTheRulesOfAutomatic": "Sesuaikan dengan aturan reservasi otomatis stok aktif, reservasi otomatis",
    "deleteProduct": "Hapus Produk",
    "unbindTheMasterProduct": "Lepas ikatan Master Produk",
    "bindTheMasterProduct": "Ikat Master Produk",
    "editedTheAutoBindMasterSku": "Mengedit pengaturan pengikatan otomatis Master SKU, pehaturan diubah dari {1} menjadi {2}",
    "editedTheAutoCreateMasterSku": "Mengedit pengaturan pembuatan otomatis Master SKU, pehaturan diubah dari {1} menjadi {2}",
    "createdMasterProductViaOpenapi": "Master Produk {} dibuat melalui Open-API",
    "manuallyCreatedMasterProductFrom": "Master Produk {} dibuat secara manual dari produk gudang",
    "automaticallyCreatedMasterProductFromWarehouse": "Master Produk {} dibuat secara otomatis dari produk gudang",
    "commonSearches": "Pencarian Umum",
    "moreSearches": "Filter Lainnya",
    "becauseChannelSkuHasBeen": "Karena SKU channel {0} telah diahpus, maka chanel SKU {1} dilepas ikatannya dari MSKU {2}",
    "updatedProductPriceFrom": "memoerbarui harga produk {0} dari {1} menjadi {2}",
    "editedStockForProductFrom": "Mengedit stok untuk produk {0} dari {1} menjadi {2}",
    "editedChannelSkuForProduct": "Mengedit SKU channel untuk produk {0} dari {1} menjadi {2}",
    "channelProductMatchToAutobind": "Produk channel {0} di mapping dengan aturan Pengikatan Otomatis, dan mengikat MSKU {1}",
    "channelProductMatchesTheAutocreatedRule": "Produk channel {0} cocok dengan aturan Pembuatan Otomatis, dan membuat MSKU {1}",
    "channelProductIsManuallyBound": "Produk channel {0} mengikat ke MSKU {1} secara manual",
    "channelProductManuallyCreatedMsku": "Produk channel {0} membuat MSKU {1} secara manual",
    "distribution": "distribusi",
    "removeSelectedLogistics": "Hapus logistik yang dipilih{}",
    "PickListIsPrinted": "Daftar pilih dicetak",
    "printedPacking": "Slip pengepakan tercetak",
    "InvoiceIsPrinted": "Faktur dicetak",
    "printeLabels": "Mencetak catatan kurir",
    "bopis": "BOPIS",
    "bigProduct": "Big Product",
    "saveSearch": "Simpan pencarian",
    "commonSearchNames": "Nama pencarian umum",
    "radio": "Radio",
    "multiplechoice": "mult-pilihan",
    "updateTheOrderStatus": "Perbarui status pesanan",
    "updatedStockForProductFrom": "Memperbarui stok produk {0} dari {1} menjadi {2}",
    "mengeditDeskripsiPanjangMasterProdukDari": "Mengedit Deskripsi Panjang Master Produk dari {0} menjadi {1}",
    "updatedAvailableStockForMskuTo": "Memperbarui stok yang tersedia untuk MSKU{} ke {}",
    "nameManagement": "Manajemen Nama",
    "nameList": "List Nama",
    "ForChannelProductsWithVariations": "2. Untuk produk channel dengan variasi, nama variasi dan nama produk digunakan sebagai MSKU.",
    "ChannelProductsWithoutVariationsUse": "1. Produk channel tanpa variasi menggunakan SPU sebagai MSKU.",
    "theSystemDetectsThatSomeSkus": "Sistem mendeteksi bahwa beberapa SKU produk channel kosong, harap konfirmasi apakah akan mengaktifkan aturan default sistem:",
    "updatedProductShortDescriptionFrom": "Memperbarui Deskripsi Singkat produk dari {0} menjadi {1)",
    "fuzzyOnlySupportsSingle": "Blur hanya mendukung satu",
    "scanShipment": "Scan dan Kirim",
    "editMasterProductName": "Edit Nama Master Produk",
    "editChannelProductName": "Edit nama produk saluran",
    "newProductName": "nama produk baru",
    "utilityTool": "Utilitas",
    "TimesScanningIsRequiredFor": "2 kali: 2 kali perlu dipindai, pemindaian pertama menampilkan informasi pesanan, catatan pemindaian kedua dihasilkan, dan pesanan dipindahkan ke koleksi yang tertunda sesuai dengan pengaturan",
    "TimeScanOnceGenerateA": "1 kali: scan 1 kali, buat catatan scan, dan pindahkan pesanan ke Menunggu Dipickup sesuai dengan pengaturan",
    "twice": "Dua kali",
    "once": "Sekali",
    "scanResultEffectiveTimes": "Berapa kali hasil pemindaian telah berlaku",
    "afterScanningItWillAutomaticallyMove": "Secara otomatis pindah ke Menunggu Dipickup setelah pemindaian",
    "scanSettings": "Pengaturan Scan",
    "scanned": "Scan",
    "pleaseScanOrEnterTheCourier": "Silakan scan atau masukkan AWB/Nomor Tracking",
    "scanByCourier": "Scan AWB/Nomor Tracking",
    "afterTheScanIsSuccessfulThe": "Setelah pemindaian berhasil, pesanan akan secara otomatis dipindahkan ke daftar [Menunggu Dipickup] sesuai dengan pengaturan, pastikan bahwa pengaturan telah selesai dan pengaturan default tidak dihidupkan",
    "scanRecord": "Riwayat Scan",
    "scanSucceeded": "Pemindaian berhasil",
    "xxxxnumberDoesNotExist": "Nomor {XXXX} tidak ada",
    "xxxxxHasBeenScannedPleaseDo": "{XXXXX} telah discan, jangan ulangi scan",
    "xxxxxIsNotASelfownedWarehouse": "{XXXXX} bukan merupakan pesanan dari gudang sendiri, tidak dapat discan untuk pengiriman",
    "xxxxxIsNotAnOrderIn": "{XXXXX} Pesanan status pesanan tidak tertunda, tidak dapat discan untuk pengiriman",
    "xxxxxDoesNotExistPleaseCheck": "{XXXXX} tidak ada, silakan periksa dan scan ulang",
    "scanFailed": "Pemindaian gagal",
    "pleaseScanOrEnterMskubarcode": "Silakan scan atau masukkan MSKU / Barcode",
    "useState": "Status penggunaan",
    "shelfName": "Nama rak",
    "informationShelf": "Informasi rak",
    "modifyShelves": "Ubah rak",
    "commodityDetails": "Detail produk",
    "storageRack": "Rak",
    "pleaseSelectAWarehouseAndAdd": "Silakan pilih gudang dan tambahkan produk",
    "pleaseChangeToEnInputMethod": "Pastikan metode input keyboard diubah ke [EN] sebelum memindai",
    "scanResults": "Hasil Pemindaian",
    "notScanned": "Tidak Discan",
    "scanStatus": "Status pemindaian",
    "shelfNumber": "Nomor rak",
    "courierNumber": "Nomor Tracking",
    "sellerCashbackCoin": "Koin Cashback Penjual",
    "tax": "Pajak",
    "sunTotal": "Subtotal",
    "currentShelf": "Rak saat ini",
    "vacant": "kosong",
    "inboundAndOutboundRecordAdjustmentMethod": "Metode penyesuaian catatan masuk dan keluar = jumlah catatan keluar manual (harga pembelian rata-rata * nilai perubahan).",
    "adjustmentMethodOfInboundAndOutbound": "Metode penyesuaian riwayat keluar masuk stok= jumlah catatan penjualan keluar (harga pembelian rata-rata * nilai perubahan)",
    "calculationDescriptionAccordingToTheExport": "Penjelasan perhitungan: Berdasarkan yang ada di template export, waktu pembuatan pesanan berada dalam periode waktu filter, dan status = jumlah pesanan yang sudah selesai {XXXX}",
    "allScanned": "Discan Semua",
    "partiallyScanned": "Discan Sebagian",
    "warehousesKeeppackWarehousesListings": "Gudang / Gudang keeppack / Daftar / Buat produk master",
    "warehouseKeeppackWarehouseProduct": "Gudang / Gudang keeppack / informasi produk",
    "inOrderToImproveThePull": "Untuk meningkatkan efisiensi tarikan, durasi satu tarikan tidak boleh lebih dari 15 hari;",
    "bindingRecordsAreOnlyValidFor": "Catatan yang mengikat hanya berlaku selama 30 hari, dan catatan yang mengikat lebih dari 30 hari tidak dapat dilihat",
    "pullItems": "Menarik item",
    "onlyProductsIssuedByGineeAre": "Hanya menampilkan produk yang diterbitkan dari Ginee",
    "synchronizeTimeInDescendingOrder": "Waktu sinkron terbaru",
    "synchronizeTimeInAscendingOrder": "Waktu sinkron terlama",
    "areYouSureUnbindYouWantTo": "Apakah Anda yakin ingin melepaskan ikatan?",
    "mskuCreationIsNotSupportedForTip": "Pembuatan MSKU tidak didukung untuk SKU gudang pihak ketiga yang terikat ke MSKU, dan sistem akan secara otomatis memfilternya untuk Anda",
    "pullTheProduct": "Tarik Produk",
    "beforeBatchEditingYoudBetterSynchronize": "Sebelum mengedit batch, Anda sebaiknya menyinkronkan produk agar data tetap konsisten dengan pusat penjual",
    "syncAndEdit": "Sinkronkan & Edit",
    "skipAndEdit": "Lewati & Edit",
    "connectWithAllOfYourCustomer": "Hubungi semua pelanggan dalam satu sistem",
    "makesItEasyToMonitorThe": "Memudahkan untuk memonitor aktifitas dari banyak gudang dan stok",
    "goBackToTheList": "Kembali ke daftar",
    "xxxxDoesNotExistPleaseScan": "{XXXX} tidak ada, mohon scan lagi setelah Master Produk ditambahkan",
    "theCombinationXxxCannotBePut": "Produk kombinasi {XXX} tidak dapat disimpan secara manual, harap hapus dan coba lagi",
    "theTrackingNumberXxxxWasSuccessfully1": "Nomor Tracking {0} berhasil discan dan status scan pesanan {1} diperbarui menjadi Discan dan dipindahkan ke Menunggu Dipickup",
    "theTrackingNumberXxxxWasSuccessfully": "Nomor Tracking {XXXX} berhasil discan dan status scan pesanan {XXXX} diperbarui menjadi Discan",
    "waitingInbound": "Menunggu Inbound",
    "upgrade": "Upgrade",
    "about": "Tentang",
    "pleaseSelectACategoryFirst": "Silakan pilih kategori terlebih dahulu",
    "subAccount": "Sub akun",
    "authorizedStores": "Kuota toko",
    "availableQuantity": "Kuota pesanan",
    "addImagesToEachVariantOption": "Tambahkan gambar ke setiap opsi varian",
    "productInformationDisplayedOnTheLogistics": "Informasi produk ditampilkan di label logistik (JD/Bukalapak)",
    "tiktokPrintShippingLabelAndPick": "(Tiktok) cetak label pengiriman dan pick list",
    "downloadDocument": "Unduh dokumen:{0}",
    "thereAreOrdersThatStillBeing": "Ada pesanan yang masih dicetak. Setelah menutup tampilan pop-up, Anda dapat mengunduh label pengiriman di riwayat cetak.",
    "printHistory": "Riwayat Cetak",
    "printAll": "Cetak Semua",
    "maximumOrdersCanBePrinted": "Maksimal 1.000 pesanan dapat dicetak sekaligus, dan membutuhkan waktu lama karena PDF label pengiriman  dibuat untuk masing-masing channel",
    "unlimited": "Unlimited",
    "otherCharges": "Biaya Lainnya",
    "bearTheCost": "Biaya Pembayaran",
    "authorizationStatusTotalNumberOf": "Status otorisasi = Jumlah total toko marketplace/webstore yang \"sedang tidak aktif\"",
    "expiredInfo": "Status otorisasi = Jumlah total toko marketplace/webstore yang \"kedaluwarsa\"",
    "storeStatusOfAllAuthorizedChannels": "Status toko semua channel terintegrasi = Total produk \"terjual habis\".",
    "status_banned_info": "Status toko semua channel resmi = Total produk \"dilarang\".",
    "theSumOfTheNumberOf": "Total jumlah produk yang gagal dipublikasikan ke channel di semua channel toko resmi",
    "stockListPageNotInboundTab": "Jumlah MSKU di halaman [Daftar Stok] dan di tab [Belum Inbound]",
    "theNumberOfMskusThatHave": "Jumlah MSKU yang telah ditambahkan ke gudang dan belum dibuat untuk menambah stok",
    "stockListPageStockWarningTab": "Jumlah MSKU di halaman [Daftar Stok] dan di tab [Pengingat Stok]",
    "totalMskuWhichTheAvailableStock": "Jumlah MSKU yang stok tersedianya kurang dari stok pengaman",
    "totalMskuOfStockListPage": "Total MSKU halaman [Daftar Stok] di tab [Habis]",
    "totalMskusWithAvailableStock": "Total MSKU yang stok tersedianya 0",
    "TotalOrderOf": "Total pesanan [Manajemen Retur - Retur Barang & Dana] di tab [Tertunda].",
    "returnRequestInfo": "Pesanan Shopee/Lazada/Tiktok yang harus ditangani penjual saat pembeli mengajukan permohonan pengembalian",
    "totalOrderOfReturnManagement": "Total pesanan [Manajemen Retur - Daftar Pengembalian Dana] di tab [Tertunda].",
    "shopeelazadatiktokOrdersThatRequireTheSeller": "Pesanan Shopee/Lazada/Tiktok yang harus ditangani penjual saat pembeli melakukanpermintaan pengembalian dana",
    "ordersThatHaveBeenPushed": "Total pesanan halaman [Tertunda -Push Gudang] di tab [Gagal Dipush], harap pastikan untuk memprioritaskan pemrosesan",
    "ordersThatHaveBeenPushedTo": "Pesanan yang telah didorong ke gudang pihak ketiga secara manual atau otomatis oleh sistem, tetapi API gudang pihak ketiga mengembalikannya karena gagal",
    "totalOrderOfPendingpushWarehousePage": "Total pesanan halaman [Tertunda -Push Gudang] di tab [Menunggu Dipush], harap pastikan untuk mendorongnya tepat waktu",
    "ordersThatNeedToBePushed": "Pesanan yang perlu dipush ke gudang pihak ketiga untuk dipenuhi, tetapi belum dipush",
    "totalOrderOnAbnormalOrdersPage": "Total pesanan di halaman [Pesanan tidak normal], jika ada data terkait, pastikan untuk menyinkronkan pesanan secara manual",
    "ordersWithIncompleteInformationDueTo": "Pesanan dengan informasi yang tidak lengkap karena pembatasan QPS pada API channel",
    "theNumberOfOrdersInThe": "Jumlah pesanan pada tab [Bisa Dicetak] pada halaman [Menunggu Dicetak] dapat diproses dengan cepat dan seragam",
    "allPrintableShippingLabelsAreDisplayed": "Semua label pengiriman yang dapat dicetak ditampilkan dalam daftar ini",
    "toBeProcessedArrangeShipment": "[Tertunda - Atur Pengiriman] Jumlah pesanan di setiap tab halaman dapat diprioritaskan dan disatukan untuk pengiriman multi-channel dan multi-toko",
    "allShippableOrdersAreDisplayedIn": "Semua pesanan yang dapat dikirim ditampilkan dalam daftar ini",
    "pendingproblemOrdersTheSumOfThe": "Jumlah pesanan di halaman [Tertunda -Pesanan Bermasalah]  harap pastikan untuk memprioritaskan pemrosesan",
    "whenTheOutboundListCannotBe": "Ketika daftar outbound tidak dapat dibuat atau MSKU kehabisan stok, itu akan ditetapkan sebagai pesanan bermasalah",
    "xxxxThereIsMoreThanOne": "{XXXX} memiliki lebih dari satu di sistem, MSKU dan Barcode diduplikasi, silakan periksa dan coba lagi",
    "servicePH": "Klik dan hubungi kami",
    "serviceCN": "CS khusus untuk klien wechat:Ginee888",
    "serviceTH": "Grup Ginee Thailand",
    "serviceVN": "Grup tutorial Zalo",
    "serviceMYandID": "Grup Ginee Support",
    "accountInfo": "Informasi Akun",
    "refundApplication": "Aplikasi Balas",
    "returnRequest": "Permintaan Kembali",
    "orderManagement": "Manajemen Pesanan",
    "sumOfTheNumberOfShopeelazada": "Jumlah dari jumlah toko Shopee/Lazada dengan status otorisasi=\"otorisasi\" dan sisa waktu validitas otorisasi kurang dari 30 hari",
    "shopeebukalapaktokopediaOrdersUseTheGineeTemplate": "Pesanan Shopee/Bukalapak/Tokopedia menggunakan template Ginee untuk mencetak label pengiriman, dan marketplace lain saat ini hanya mendukung pencetakan label pengiriman menggunakan template dari marketplace",
    "highSpeedPrintTaskDetails": "Detail Tugas Cetak Cepat",
    "printTaskDetails": "Detail Tugas Cetak",
    "regularPrint": "Cetak Biasa",
    "printUnlimitedShippingLabelInSingle": "Mencetak label pengiriman tanpa batasan jumlah pesanan dalam sekali cetak, tetapi tidak dapat melihat tampilan pratinjau",
    "clickHereToViewDetailedInstructions": "Klik di sini untuk melihat detail petunjuk",
    "inOrderToImprovePrintingSpeed": "Untuk meningkatkan kecepatan dan pengalaman pencetakan, disarankan untuk menggunakan fitur cetak cepat,",
    "itIsDetectedThatYouHave": "Terdeteksi bahwa Anda telah memilih lebih dari 300 pesanan, dan untuk saat ini tidak mendukung untuk mencetak menggunakan templat label pengiriman Ginee",
    "thePrintingTaskHasExpiredPlease": "Tugas pencetakan telah kedaluwarsa, silahkan buka [pesanan 90 hari terakhir > riwayat pesanan] untuk memeriksa catatan pencetakan",
    "imageSizeLengthLimit": "Anda dapat upload beberapa foto secara bersamaan. minimal {width} x {height} pixel ，hingga {size}MB (maks {maxLength} foto)",
    "imageManagement": "Manajemen Gambar",
    "noMskuInTheDocumentHas": "Tidak ada perbedaan inventaris dalam MSKU apa pun dalam dokumen, dan tidak perlu menghasilkan pesanan penyesuaian",
    "inventoryHasNotBeenCompletedPlease": "Stock Opname belum selesai, harap selesaikan stock opname terlebih dahulu sebelum mengoperasikan",
    "stocktakingNumber": "Nomor stock opname",
    "checkingStock": "Sedang Dicek",
    "waitingStocktaking": "Menunggu Stock Opname",
    "importStockTakingResult": "Impor Hasil Stock Opname",
    "mskuNumber": "Jumlah MSKU",
    "stocktakingType": "Jenis Stock Opname",
    "numberOfMskuWithCountingDifferences": "Selisih jumlah MSKU",
    "numberOfDifferences": "Perbedaan Jumlah",
    "generateInventoryAdjustmentDocument": "Buat Daftar Penyesuaian Stok",
    "pleaseCheckTheSpecificInformationIn": "Silakan periksa informasi spesifik di [Gudang - Daftar Penyesuaian Stok], lakukan penyesuaian manual, dan perbarui stok MSKU sesuai dengan hasil stok opname",
    "inventoryAdjustmentDocGeneratedSuccessfully": "Pesanan penyesuaian inventaris berhasil dibuat",
    "voidSuccessfully": "Batal sukses",
    "afterVoidingItIsNotSupported": "Setelah invalid, stok opname tidak dapat dilanjutkan, dan MSKU yang telah dihitung tidak mendukung pembuatan daftar penyesuaian stok untuk menyesuaikan stok",
    "AfterTheCountingTheSystem": "3. Setelah stok opname, sistem akan secara otomatis memeriksa item yang gagal outbound selama periode stok opname untuk Anda, Pesanan aktual telah dikirim/dibatalkan dan pesanan secara otomatis dikeluarkan/dibatalkan, system akan mengoreksi stok MSKU",
    "TheMskuInventoryThatHas": "2. Persediaan MSKU yang belum terdaftar untuk stok opname tidak akan diperbarui, dan hasil persediaan MSKU akan diperbarui menjadi \"Belum Stok Opname\", harap berhati-hati",
    "AfterTheInventoryIsFinished": "1. Setelah stok opname selesai, status stok opname akan diubah menjadi Selesai, dan operasi tidak dapat dilanjutkan",
    "confirmTheEndOfTheInventory": "Konfirmasikan akhiri stok opname?",
    "theCurrentTaskIsStillBeing": "Tugas saat ini masih dibuat, silakan lakukan nanti",
    "clickStartCountingAndTheStatus": "Setelah Anda mengklik Mulai Stok opname, status stock opname akan diperbarui menjadi Sedang Dicek, dan halaman operasi stock opname akan secara otomatis dibuka untuk Anda",
    "printInventoryList": "Cetak daftar stock opname",
    "endInventory": "Selesaikan Stock Opname",
    "startCounting": "Mulai stok opname",
    "inventoryByMsku": "Periksa Berdasarkan MSKU",
    "wholeWarehouseInventory": "Periksa Berdasarkan Gudang",
    "automaticallyFilterMskuXCombinationsAnd": "MSKU [ {bundleSku} ] telah difilter secara otomatis, dan MSKU [ {countingSKU} ] masih menunggu stok opname dalam tugas stok opname yang belum selesai, dan MSKU [ {canCountSKU} ] dapat dilakukan stok opname kali ini",
    "aTotalOfXMskuCan": "Kali ini, Anda dapat menghitung jumlah total {X} MSKU",
    "createInventoryTask": "Buat Daftar Stock Opname",
    "aTotalOfXMskuAre": "Sebanyak {count} MSKU sedang menunggu stok opname, daftar stok opname sedang diminta, harap tunggu, dan pindah ke daftar stok opname untuk melanjutkan operasi",
    "inventoryCreationTaskSubmittedSuccessfully": "Tugas pembuatan daftar stok opname berhasil dikirimkan",
    "afterConfirmationTheFilledInformationWill": "Setelah konfirmasi, informasi yang diisi tidak akan disimpan",
    "areYouSureYouDontWant": "Apakah Anda yakin tidak menyimpan informasi saat ini?",
    "currentWarehouseInventory": "Stok gudang saat ini",
    "inventoryIsNotSupportedForCombination": "Produk kombinasi dan MSKU dalam tugas stok opname yang belum selesai tidak mendukung menunggu stok opname",
    "mskuDetails": "Detail MSKU",
    "inventory": "Gudang",
    "afterSavingTheInformationWillOnly": "Setelah disimpan, informasi hanya akan disimpan dalam daftar stok opname, dan dapat dimodifikasi lagi",
    "inventoryLoss": "Stok Kurang",
    "inventoryProfit": "Stok bertambah",
    "panping": "Stock Sesuai",
    "inventoryResults": "Hasil Stok Opname",
    "takeStockInTheWarehouse": "Hitung Stok",
    "takeStockOf": "Stok Opname",
    "counting": "Jumlah stok opname",
    "inventorySheet": "Nomor Stok Opname",
    "notCounted": "Belum stok opname",
    "TheCurrentAdjustmentDocumentStatus": "3. Status urutan penyesuaian saat ini diperbarui ke [Sedang Disesuaikan]",
    "TheCurrentMskuAdjustmentStatus1": "3. Status penyesuaian MSKU saat ini diperbarui ke [Tidak disesuaikan]",
    "TheSystemWillNotChange": "2. Sistem tidak akan mengubah persediaan gudang MSKU saat ini",
    "OnceItIsConfirmedThat": "1. Setelah dipastikan bahwa tidak diperlukan penyesuaian, itu tidak dapat dioperasikan lagi",
    "confirmThatTheCurrentMskuInventory": "Konfirmasikan bahwa persediaan MSKU saat ini tidak perlu disesuaikan?",
    "TheCurrentMskuAdjustmentStatus": "3. Status penyesuaian MSKU saat ini diperbarui ke [Selesai]",
    "withoutAdjustment": "Tidak diperlukan penyesuaian",
    "confirmAdjustment": "Konfirmasikan penyesuaian",
    "unadjusted": "Tidak disesuaikan",
    "singleAdjustment": "Penyesuaian massal",
    "continueToAdjust": "Terus sesuaikan",
    "startAdjusting": "Mulai menyesuaikan",
    "inventoryReductionMsku": "Stok MSKU Berkurang",
    "increaseInventoryMsku": "Stok MSKU Bertambah",
    "totalMsku": "Jumlah total MSKU",
    "adjusting": "Sedang Disesuaikan",
    "toBeAdjusted": "Menunggu Disesuaikan",
    "adjustTheSingleNumber": "Nomor penyesuaian",
    "afterItIsTurnedOnMsku": "Setelah diaktifkan, MSKU selama periode stok opname tidak mendukung penambahan dan pengurangan stok, yang tidak mempengaruhi penguncian stok",
    "mskuDuringTheCountingPeriodDoes": "MSKU selama stok opname tidak mendukung operasi inbound dan outbound",
    "afterAdjustment": "Setelah penyesuaian",
    "beforeAdjustment": "Sebelum penyesuaian",
    "inventoryVariance": "Perbedaan inventaris",
    "stockAdjustment": "Penyesuaian Stok",
    "continueCounting": "Lanjutkan stock opname",
    "confirmIntegrations": "Konfirmasi Integrasi",
    "mskuXDoesNotExistIn": "MSKU {X} tidak ada di gudang {X}, harap tambahkan MSKU ke gudang terlebih dahulu",
    "orderXxxxxStatusChangedToShipping": "Status pesanan {XXXXX} berubah menjadi Pengiriman di {XXXX-XX-XX XX:XX}",
    "xxxAfterTheInventoryCountIs": "{XXX} Setelah stok opname, MSKU dapat dioperasikan untuk inbound dan outbound, dan secara otomatis memperbaiki nilai stok sesuai dengan perubahan status pesanan",
    "xxxGeneratesTheInventoryAdjustmentDocument": "{XXX} menghasilkan nomor penyesuaian stok opname: {XXXX}, hasil stok opname: stock kekurangan",
    "xxxGenerateTheInventoryAdjustmentDocument": "{XXX} menghasilkan nomor penyesuaian Stock Opname: {XXXX}, hasil stok opname: stock kelebihan",
    "theCurrentRepositoryIsDeactivatedPlease": "Repositori saat ini dinonaktifkan, silakan lakukan setelah daftar repositori diaktifkan",
    "theCurrentWarehouseIsNotIts": "Gudang yang ada saat ini bukan gudang sendiri dan tidak dapat melakukan stok opname",
    "theCurrentRepositoryDoesNotExist": "Repositori saat ini tidak ada, silakan lakukan setelah menambahkan daftar repositori",
    "theInventoryHasBeenTaken": "Selesai",
    "AreYouSureToDelete": "Apakah Anda yakin untuk menghapus informasi saat ini?",
    "defaultShelf": "Rak default",
    "AreYouSureToEnd": "Apakah Anda yakin untuk mengakhiri stok opname?",
    "mskuInInventoryExistsInThe": "Ada MSKU untuk stok opname dalam daftar saat ini, silakan inbound langsung setelah selesai stok opname",
    "thereIsMskuInCountingIn": "Ada MSKU untuk stok opname dalam daftar saat ini, silakan inbound atau outbound langsung setelah selesai stok opname",
    "AreYouSureToVoid": "Apakah Anda yakin untuk membatalkan informasi saat ini?",
    "keywordid": "Kata kunci",
    "onceTheAdjustmentIsConfirmedIt": "Setelah penyesuaian dikonfirmasi, penyesuaian tidak dapat ditangguhkan",
    "atTheBeginningOfAdjustmentThe": "Ketika penyesuaian dimulai, sistem akan secara otomatis memperbarui inventaris gudang MSKU ke nilai inventaris yang disesuaikan",
    "addedInventoryList": "Tambahkan Daftar Stok Opname",
    "noMoreThanMaxCharacters": "Tidak lebih dari karakter {max}",
    "confirmAndUpdateMskuInventory": "Apakah Anda yakin untuk menyesuaikan dan memperbaharui inventaris MSKU?",
    "areYouSureNotToSave": "Anda yakin tidak menyimpan informasi saat ini?",
    "createAnInventoryTask": "Buat Tugas Stok Opname",
    "countingStatus": "Status Stok Opname",
    "counted": "Selesai",
    "saving": "Menyimpan...",
    "areYouCancel": "Apakah Anda yakin ingin membatalkan?",
    "notCount": "Dokumen saat ini tidak dalam menunggu stok opname dan tidak dapat dioperasikan. Silakan refresh halaman dan coba lagi.",
    "shouldBeBetweenXToX": "Harus antara X hingga X",
    "offlineOrder": "Pesanan Offline",
    "duringTheUnboundPeriodTheOutbound": "Selama periode tidak terikat, pesanan keluar tidak dapat dibuat untuk mengunci/mengurangi stok dan sinkronisasi stok tidak dapat dilakukan",
    "tokocabangStockChannelInterfaceDoesNot": "Mohon tidak mengikat gudang Tokopedia o2o dengan gudang Ginee WMS/pihak ketiga karena dapat menyebabkan double pengiriman",
    "disabledChannelWarehousesCannotUpdateStock": "Gudang Channel yang dinonaktifkan tidak dapat memperbarui stok karena pembatasan antarmuka channel;",
    "pleaseBeSureToCompleteThe1": "Harap pastikan untuk menyelesaikan pengikatan satu-ke-satu antara gudang channel dan gudang Ginee untuk mewujudkan pembagian pesanan dan sinkronisasi stok;",
    "systemWarehouseAddress": "Alamat Gudang System",
    "systemWarehouseName": "Nama Gudang System",
    "channelWarehouseAddress": "Alamat Gudang Channel",
    "channelWarehouseName": "Nama Gudang Channel",
    "channelWarehouseManagement": "Manajemen Gudang Channel",
    "upgradePackage": "Upgrade Paket",
    "askCustomerService": "Tanyakan ke Customer Service",
    "theCurrentStoreHasOpenedThe": "Toko saat ini telah membuka fungsi multi-gudang channel, harap tingkatkan ke paket yang sudah mendukung fungsi manajemen multi-gudang sebelum beroperasi",
    "storeAuthorizationFailed": "Otorisasi toko gagal",
    "youCanDirectlyAddProductsIn": "Anda dapat langsung menambahkan produk dalam sistem, memproses pesanan, atau membuka sinkronisasi stok untuk mengalami pengelolaan satu-klik fungsi stok multi-saluran",
    "pleaseBeSureToCompleteThe": "Harap pastikan untuk segera menyelesaikan pengikatan gudang channel dan gudang Ginee, jika tidak, Anda tidak akan dapat memproses pesanan/menyinkronkan stok",
    "theStoreHasOpenedTheChannel": "Toko telah membuka layanan multi-gudang channel dan menggunakan layanan sinkronisasi stok",
    "authorizing": "Otorisasi",
    "multiWarehouse": "Multi Gudang",
    "theGineeSystemReportsAnError": "Sistem Ginee melaporkan kesalahan, alasannya adalah sebagai berikut: {XXXXX}",
    "theChannelInterfaceReportsAnError": "Interface channel melaporkan kesalahan, alasannya adalah sebagai berikut: {XXXXX}",
    "failedToPullChannelWarehouseInformation": "Gagal menarik informasi gudang channel",
    "deactivateWarehouse": "Nonaktifkan gudang",
    "addWarehouse": "Tambah gudang",
    "channelWarehouseInformationPulledSuccessfully": "Channel informasi gudang berhasil ditarik",
    "pullTheChannelWarehouseAddXxx": "Tarik gudang saluran, tambah {AAA}, nonaktifkan {BBB}",
    "channelWarehouseXxxBindingWarehouseIs": "Gudang terikat gudang channel {AAA} diperbarui dari {BBB} menjadi {CCC}",
    "theChannelWarehouseXxxIsBound": "Gudang channel {AAA} terikat ke gudang sistem {BBB}",
    "updateWarehouse": "perbarui gudang",
    "pleaseNoteThatTheOperationLog": "Harap perhatikan bahwa log operasi hanya akan disimpan dalam sistem selama 30 hari, dan catatan penarikan setelah 30 hari tidak dapat diminta",
    "thereIsAnUnfinishedSalesDelivery": "Ada pesanan pengiriman penjualan yang belum selesai di gudang saat ini, dan hubungan yang mengikat tidak dapat diperbarui",
    "OrdersAllocated": "1. Pesanan yang telah dialokasikan ke gudang channel akan secara otomatis dialokasikan ke gudang yang cocok sesuai dengan hubungan pengikatan yang baru, tidak perlu menetapkan aturan alokasi gudang",
    "areYouSureToUpdateThe": "Apakah Anda yakin untuk memperbarui gudang terikat?",
    "OrdersThatHaveBeenAllocated": "1. Pesanan yang telah dialokasikan ke gudang channel akan secara otomatis dialokasikan ke gudang yang cocok sesuai dengan ikatan hubungan, tidak perlu menetapkan aturan alokasi gudang",
    "areYouSureToBindThe": "Apakah Anda yakin untuk mengikat gudang saat ini?",
    "ownWarehouse": "Gudang Sendiri",
    "warehouseMarketplace": "Gudang Channel",
    "TheStatusOfTheCurrent": "3. Status pesanan penyesuaian saat ini diperbarui ke [Selesai]",
    "lastSaleDateTips": "Tanggal pembuatan pesanan terakhir untuk produk ini",
    "notification74": "Jumlah total pesanan (termasuk pengiriman, dll.) Dari pesanan yang valid selama periode waktu yang terhitung sebagai tidak laku",
    "notification72": "Jumlah produk saat ini yang dijual dalam pesanan yang valid selama jangka waktu yang terhitung sebagai tidak laku",
    "salesVolume": "Volume penjualan",
    "notification70": "Jumlah total pembeli di semua pesanan dalam periode waktu yang terhitung sebagai tidak laku, dan jumlah pembeli dihitung per nomor ponsel",
    "totalNumberOfBuyers": "Jumlah total pembeli",
    "validOrder": "Pesanan Valid",
    "notification66": "Jumlah total pesanan dalam jangka waktu yang memenuhi ketentuan penjualan lambat, termasuk semua status",
    "totalNumberOfOrders": "Jumlah total pesanan",
    "notification64": "Tanggal pembuatan pesanan terakhir untuk produk ini",
    "lastSaleDate": "Tanggal penjualan terakhir",
    "notification58": "Hanya tampilkan produk dengan Stok SKU",
    "notification55": "Produk terjual dalam {day} hari kurang dari {number}",
    "notification54": "Sebelum menggunakan tabel statistik produk tidak laku, harap menyelesaikan pengaturan kondisi produk tidak laku terlebih dahulu",
    "slowSalesSettings": "Pengaturan Produk Tidak Laku",
    "commodityCategory": "Kategori Produk",
    "slowSalesStatisticsTable": "Tabel Statistik Produk Tidak Laku",
    "notification34": "File gagal disediakan, Coba lagi nanti",
    "notification33": "File akan ditampilkan dalam Daftar Riwayat Export, dan hanya akan disimpan selama 15 hari, silakan klik untuk mengunduh",
    "notification32": "File telah tersedia, silakan klik untuk mengunduh",
    "generatingFilePleaseWait": "Membuat File, Silahkan Tunggu",
    "replenishStock": "Restock",
    "unableEstimate": "Tidak dapat Dikalkulasi",
    "estimatedDaysTips": "(Stok tersedia-Stok pengaman) / Jumlah penjualan harian, sisa hari untuk mencapai Stok pengaman diperkirakan sesuai dengan jumlah penjualan rata-rata harian,Apabila penjualan harian kurang dari 1, maka 1 akan dijadikan estimasi",
    "estimatedDays": "Tanggal perkiraan",
    "avgQtySoldTips": "Jumlah terjual / periode penjualan, Stok penjualan harian rata-rata",
    "avgQtySold": "Jumlah penjualan harian",
    "qtySoldTips": "Jumlah total Stok yang terjual dari Stok SKU saat ini dalam periode penjualan yang dipilih",
    "qtySold": "Jumlah Terjual",
    "safetyStockTips": "Jumlah stok pengaman dari Stok SKU saat ini, Stok pengaman sesuai dengan daftar manajemen Stok",
    "availableStockTips": "Jumlah Stok Tersedia dari Stok SKU saat ini, Stok Tersedia sesuai dengan yang di Daftar Manajemen Stok",
    "inventoryName": "Nama Stok",
    "inventorySKU": "MSKU",
    "notification151": "Pahami Cara Menggunakan Stok Pengaman",
    "days90": "90 hari",
    "days60": "60 hari",
    "days30": "30 hari",
    "days15": "15 hari",
    "days7": "7 hari",
    "salesPeriod": "Periode Penjualan",
    "outOfStockEstimation": "Estimasi Stok Habis",
    "profitTips": "Catatan: Laporan Laba Rugi hanya menghitung pesanan Sampai, dan dapat filter jangka waktu maks. 30 hari.",
    "commissionSettingTips": "Catatan: Sekarang dukungan untuk menetapkan komisi toko dari toko terikat Tokopedia dan Shopify, data ini hanya digunakan untuk statistik data pendapatan, data komisi saluran lainnya didasarkan pada API saluran",
    "netProfitIncomeStatementTips2": "Laba Rugi=Pendapatan Operasional + Pendapatan Non Operasional - Biaya Operasional - Biaya non-operasional - Biaya Lainnya",
    "netProfitIncomeStatementTips": "Pendapatan Operasional + Pendapatan Non Operasional - Biaya Operasional - Biaya non-operasional - Biaya Lainnya",
    "netProfitIncomeStatement": "Laba Rugi",
    "outboundStockTips": "Riwayat Tanggal Pesanan Masuk = Jumlah Stok Keluar * Harga Pembelian Rata-rata sesuai tanggal yang di pilih",
    "outboundStock": "Stok Keluar",
    "refundFeeTips": "Tanggal pesanan dibuat = Total Jumlah Biaya Pengembalian sesuai tanggal yang di pilih",
    "refundFee": "Biaya Pengembalian",
    "cashbackTips": "Tanggal pesanan dibuat = Total Jumlah Pengembalian Dana sesuai tanggal yang di pilih",
    "cashback": "Pengembalian Dana",
    "commissionFeeTips": "Tanggal pesanan dibuat = Total Jumlah Biaya Komisi sesuai tanggal yang di pilih",
    "commissionFee": "Komisi",
    "serviceFeeTips": "Tanggal pesanan dibuat = Total Jumlah Biaya Layanan sesuai tanggal yang di pilih",
    "serviceFee": "Biaya Layanan",
    "otherExpensesTips": "Biaya Lainnya = Biaya Layanan + Biaya Komisi + Pengembalian Dana + Biaya Retur + Stok Keluar",
    "otherExpenses": "Biaya Lainnya",
    "taxTips": "Tanggal pesanan dibuat = Total Pajak sesuai tanggal yang di pilih",
    "insuranceTips": "Tanggal pesanan dibuat = Total Jumlah Asuransi sesuai tanggal yang di pilih",
    "insurance": "Asuransi",
    "nonOperatingExpensesTips": "Biaya Non-Operasional = Asuransi + Pajak",
    "nonOperatingExpenses": "Biaya Non-Operasional",
    "costOfGoodSoldTips": "Riwayat Tanggal Pesanan Keluar = Jumlah Stok Keluar * Harga sesuai tanggal yang di pilih",
    "costOfGoodSold": "Harga Pokok Penjualan",
    "operatingExpenseTips": "Biaya Operasional = Harga Pokok Penjualan",
    "operatingExpense": "Biaya Operasional",
    "inboundStockTips": "Riwayat Tanggal Pesanan Masuk = Jumlah Stok Masuk * Harga sesuai tanggal yang di pilih",
    "inboundStock": "Stok Masuk",
    "rebateTips": "Tanggal pesanan dibuat = Total Jumlah Potongan Harga sesuai tanggal yang di pilih",
    "rebate": "Potongan Harga",
    "nonOperatingIncomeTips": "Pendapatan Non-Operasional = Biaya Kirim",
    "nonOperatingIncome": "Pendapatan Non-Operasional",
    "coinTips": "Tanggal pesanan dibuat = Total Jumlah Koin sesuai tanggal yang di pilih",
    "coin": "Koin",
    "promotionTips": "Tanggal pesanan dibuat = Total Jumlah Voucher sesuai tanggal yang di pilih",
    "promotion": "Promosi",
    "discountTips": "Tanggal pesanan dibuat =Total Jumlah Promosi sesuai tanggal yang di pilih",
    "discount": "Diskon",
    "salesAmountTips": "Tanggal pesanan dibuat = Total Jumlah Pendapatan Produk dan Subtotal sesuai tanggal yang di pilih",
    "salesAmount": "Jumlah penjualan",
    "operatingIncomeTips": "Pendapatan = Jumlah Penjualan - Diskon - Promosi +Koin+ Potongan Harga",
    "operatingIncome": "Pendapatan Operasional",
    "incomeStatement": "Laporan Laba Rugi",
    "refundOrdersTips": "Total pesanan dengan Status Retur",
    "validBuyersTips": "Total Pembeli untuk Pesanan Valid",
    "buyersTips": "Total Pembeli untuk Pesanan",
    "salesRateTips": "Presentase penjualan produk saat ini dari total penjualan",
    "grossSalesTips": "Jumlah Total Nilai Pesanan semua Pesanan Valid, termasuk Ongkos Kirim dll.",
    "qtySalesRateTips": "1. Statistik Penjualan Produk: Penjualan Produk satuan/total\n2. Statistik Penjualan Per Kategori: Penjualan Per Kategori satuan/total\n3. Statistik Penjualan Toko: Penjualan Toko satuan/total\n4. Statistik Penjualan Channel: Penjualan Channel satuan/total",
    "qtySalesTips": "Total Barang Terjual dalam Pesanan Valid",
    "validOrdersTips": "Lunas/Siap Dikirim/Sedang Dikirim/Sampai",
    "totalOrdersTips": "Total Produk sesuai dengan Pesanan",
    "refundOrders": "Pesanan Diretur",
    "validBuyers": "Buyer Valid",
    "buyers": "Pembeli",
    "salesRate": "Penjualan (%)",
    "grossSales": "Pendapatan Kotor",
    "qtySalesRate": "Jumlah Penjualan (%)",
    "qtySales": "Total Penjualan",
    "validOrders": "Pesanan Valid",
    "validOrderNumStatisticsTips": "Lunas/Siap Dikirim/Sedang Dikirim/Sampai",
    "totalProductNumStatisticsTips": "Jumlah total produk yang terdapat dalam semua pesanan, data yang dihapus duplikatnya sesuai dengan toko + nama produk     Jika dua produk dari produk yang sama dibeli pada waktu yang sama dalam satu pesanan, jumlah total product adalah 1",
    "totalValidBuyerTips": "Jumlah total pembeli dalam pesanan yang valid, data pembeli yang sama akan dijadikan 1 sesuai dengan nomor HP yang sama",
    "shopNameTips": "Nama Toko tempat produk itu berada",
    "mskuTips": "Sudah disinkronkan dengan informasi master SKU yang sesuai dengan varian pada produk dalam pesanan, dan tidak akan berubah sesuai dengan pembaruan informasi produk",
    "skuTips": "Sudah disinkronkan dengan informasi SKU yang sesuai dengan varian pada produk dalam pesanan, dan tidak akan berubah sesuai dengan pembaruan informasi produk",
    "variationTips": "Sudah disinkronkan ke nama varian pada produk dalam pesanan, dan tidak akan berubah sesuai dengan pembaruan informasi produk",
    "productNameTips": "Nama produk yang terdapat dalam pesanan yang disinkronkan ke Ginee",
    "totalValidBuyerStatisticsTips": "Jumlah total pembeli dalam pesanan yang valid, data pembeli yang sama akan dijadikan 1 sesuai dengan nomor HP yang sama",
    "totalQtySoldStatisticsTips": "Jumlah total product yang terjual dalam pesanan yang valid    Jika dua produk dari produk yang sama dibeli pada waktu yang sama dalam satu pesanan, total volume penjualan akan menjadi 2",
    "searchPlaceholder": "Silakan masukkan konten",
    "pleaseSelectCategory": "Silakan pilih kategori",
    "productVariantsSalesStatistics": "Statistik Penjualan Varian Produk",
    "action73": "Atur",
    "datesPerPage": "Data per halaman",
    "setPage": "Atur Halaman",
    "time69": "Waktu",
    "notification68": "Harap atur halaman data yang akan diunduh",
    "variationName": "Nama Variasi",
    "fieldDescription": "Kolom Deskripsi",
    "detial": "Rincian",
    "action61": "Atur",
    "downloadFailed": "Download Gagal",
    "active": "Aktif",
    "notification49": "File akan ditampilkan dalam Daftar Riwayat Export, dan hanya akan disimpan selama 15 hari, silakan klik untuk mengunduh",
    "returnRateTips": "Total Pesanan Diretur / Seluruh Pesanan",
    "returnRate": "Retur (%)",
    "refundOrderNumTips": "Total pesanan dengan Status Retur",
    "refundOrderNum": "Pesanan Diretur",
    "repurchaseRateTips": "Presentase Pelanggan dengan lebih dari 1 Transaksi",
    "repurchaseRate": "Pembelian Kembali (%)",
    "grossSalesBuyer": "Total Penjualan Kotor / Jumlah Pelanggan",
    "customerAverageTransaction": "Rata-rata Transaksi Pelanggan",
    "buyerNumTips": "Total Pembeli untuk Pesanan Valid",
    "buyerNum": "Buyer Valid",
    "totalBuyerNumTips": "Total Pembeli untuk Pesanan",
    "totalBuyerNum": "Total Pembeli",
    "saleRateTips": "1. Statistik Penjualan Produk: Penjualan Kotor Produk satuan/total\n2. Statistik Penjualan Per Kategori: Penjualan Kotor Per Kategori satuan/total\n3. Statistik Penjualan Toko: Penjualan Kotor Toko satuan/total\n4. Statistik Penjualan Channel: Penjualan Kotor Channel satuan/total",
    "saleRate": "Penjualan (%)",
    "grossIncomeTips": "Jumlah Total Nilai Pesanan semua Pesanan Valid, termasuk Ongkos Kirim dll.",
    "grossIncome": "Pendapatan Kotor",
    "qtySaleRateTips": "1. Statistik Penjualan Produk: Penjualan Produk satuan/total\n2. Statistik Penjualan Per Kategori: Penjualan Per Kategori satuan/total\n3. Statistik Penjualan Toko: Penjualan Toko satuan/total\n4. Statistik Penjualan Channel: Penjualan Channel satuan/total",
    "qtySaleRate": "Jumlah Penjualan (%)",
    "qtySaleNumTips": "Total Barang Terjual dalam Pesanan Valid",
    "qtySaleNum": "Total Penjualan",
    "effectiveRateTips": "Pesanan Valid / Total Pesanan",
    "effectiveRate": "Efektifitas(%)",
    "validOrderNumTips": "Lunas/Siap Dikirim/Sedang Dikirim/Sampai",
    "validOrderNum": "Pesanan Valid",
    "totalOrderNumTips": "Total Produk sesuai dengan Pesanan",
    "totalOrderNum": "Total Pesanan",
    "totalProductNumTips": "aktif/nonaktif/habis/dilarang",
    "totalProductNum": "Total Produk",
    "totalValidBuyer": "Total Buyer Valid",
    "netIncomeTips": "Jumlah harga original produk dari pesanan valid, tidak termasuk diskon, voucher,ongkir, dan biaya lainnya",
    "netIncome": "Harga Asli Produk",
    "notification15": "Total Barang Terjual dalam Pesanan Valid",
    "totalQtySold": "Total Penjualan",
    "lowToHigh": "Rendah ke tinggi",
    "highToLow": "Tinggi ke rendah",
    "storeSaleReport": "Laporan Penjualan Toko",
    "channelSaleReport": "Laporan Penjualan Channel",
    "revenuePerBuyerTips": "Nilai Total Pesanan / Jumlah Pelanggan",
    "revenuePerBuyer": "Rata-rata Transaksi Pelanggan",
    "monetaryUnit": "Mata Uang",
    "productOriginalPriceIncome": "Total Harga Produk: Jumlah harga original produk dari pesanan valid; Pendapatan Kotor: Jumlah Total Nilai Pesanan semua Pesanan Valid, termasuk Ongkos Kirim dll.",
    "totalNetIncomeTips": "Jumlah harga original produk dari pesanan valid, tidak termasuk diskon, voucher,ongkir, dan biaya lainnya",
    "totalNetIncome": "Harga Asli Produk",
    "exportPdf": "Export ke PDF",
    "exportToPDF": "Export ke PDF",
    "countTips": "Jumlah Penjualan tidak boleh kurang dari 0",
    "selectTime": "Pilih Waktu",
    "exportExcel": "Export ke Excel",
    "exportTime": "Waktu Export",
    "exportHistory": "Riwayat Export",
    "title": "Judul",
    "moveEnd": "Bawah",
    "moveTop": "Atas",
    "moveDown": "Kebawah",
    "moveUp": "Keatas",
    "customField": "Custom",
    "taxTotalAmountTips": "Jumlah jumlah pajak dalam pesanan yang dibuat pada tanggal sekarang",
    "taxTotalAmount": "Nilai Total Pajak",
    "systemShippingTotalAmountTips": "Jumlah biaya pengiriman oleh sistem dalam pesanan yang dibuat pada tanggal sekarang",
    "systemShippingTotalAmount": "Total Biaya Pengiriman oleh Sistem",
    "serviceTotalAmountTips": "Jumlah biaya layanan dalam pesanan yang dibuat pada tanggal saat ini",
    "serviceTotalAmount": "Nilai Total Biaya Layanan",
    "commissionTotalAmountTips": "Jumlah komisi dalam pesanan yang dibuat pada tanggal saat ini",
    "commissionTotalAmount": "Nilai Total Komisi",
    "insuranceTotalAmountTips": "Jumlah biaya asuransi dalam pesanan yang dibuat pada tanggal sekarang",
    "insuranceTotalAmount": "Nilai Total Asuransi",
    "buyerTotalNumTips": "Jumlah total pelanggan dalam pesanan yang dibuat pada tanggal sekarang (hapus dengan nomor telepon)",
    "buyerTotalNum": "Jumlah Pelanggan",
    "cancelOrderRateTips": "Pesanan dibatalkan / Jumlah nilai total pesanan",
    "cancelOrderRate": "Tingkat Pembatalan",
    "cancelOrderTotalAmountTips": "Jumlah total nilai pesanan yang dibatalkan pada tanggal saat ini (termasuk pengiriman / diskon, dll.), Tidak termasuk nilai jumlah pesanan yang dibatalkan",
    "cancelOrderTotalAmount": "Nilai Total Pesanan Batal",
    "cancelOrderTotalNumTips": "Jumlah total pesanan yang dibatalkan pada tanggal saat ini. Tidak termasuk pesanan yang dibatalkan sebagian",
    "cancelOrderTotalNum": "Pesanan Batal",
    "returnOrderRateTips": "Pesanan retur / Jumlah nilai total pesanan",
    "returnOrderRate": "Tingkat Retur",
    "returnOrderTotalAmountTips": "Jumlah nilai total pesanan retur (termasuk pengiriman / diskon, dll.) yang tanggal dibuatnya sama dengan tanggal saat ini, nilai pesanan yang diretur sebagian tidak dihitung.",
    "returnOrderTotalAmount": "Nilai Total Refund",
    "returnOrderTotalNumTips": "Jumlah total pesanan yang telah berhasil dikembalikan pada tanggal ini, Pesanan yang diretur sebagian tidak dihitung",
    "returnOrderTotalNum": "Pesanan Retur",
    "paymentTotalAmountTips": "Pesanan Lunas / Siap Dikirim / Sedang Dikirim / Sampai yang tanggal dibuatnya sama dengan tanggal saat ini (termasuk biaya pengiriman, dll.)",
    "paymentTotalAmount": "Nilai Total Pesanan Valid",
    "paymentTotalNumTips": "Jumlah total pesanan Lunas / Siap Dikirim / Sedang Dikirim / Sampai yang tanggal dibuatnya sama dengan tanggal saat ini",
    "paymentTotalNum": "Pesanan Valid",
    "shippingTotalAmountTips": "Jumlah nilai total biaya pengiriman dalam pesanan yang dibuat pada tanggal sekarang",
    "shippingTotalAmount": "Biaya Pengiriman",
    "goodsTotalAmountTips": "Jumlah nilai total produk dalam pesanan yang dibuat pada tanggal saat ini (tidak termasuk pengiriman / diskon, dll.)",
    "goodsTotalAmount": "Nilai Total Produk",
    "orderTotalAmountTips": "Jumlah nilai total pesanan yang dibuat pada tanggal sekarang (termasuk pengiriman / diskon, dll.)",
    "orderTotalAmount": "Nilai Total Pesanan",
    "orderTotalNumTips": "Jumlah total pesanan dalam jangka waktu yang memenuhi ketentuan penjualan lambat, termasuk semua status",
    "orderTotalNum": "Jumlah Total Pesanan",
    "dayTips": "Tanggal Pembuatan Pesanan",
    "yesterday": "Kemarin",
    "totalRefundAmountTips": "Tanggal retur sama dengan jumlah total yang diretur pada tanggal tersebut Jumlah pesanan yang diretur sebagian (Lazada) tidak akan dihitung",
    "totalRefundAmount": "Nilai Total Refund",
    "returnOrderTips": "Jumlah total pengembalian pesanan yang difilter",
    "shippingFeeTips": "Tanggal Dibuat  sama dengan jumlah total biaya pengiriman dalam pesanan pada tanggal tersebut (total biaya pengiriman di detail pesanan)",
    "shippingFee": "Biaya Pengiriman",
    "totalOrderAmountTips": "Tanggal Dibuat  sama dengan jumlah nilai total pesanan pada tanggal tersebut (termasuk biaya pengiriman, dll.)",
    "totalOrderAmount": "Nilai Total Pesanan",
    "totalOrderNumberTips": "Tanggal Dibuat sama dengan jumlah total pesanan pada tanggal tersebut (semua status pesanan)",
    "totalOrderNumber": "Jumlah Total Pesanan",
    "trendChart": "Chart Tren",
    "theSizeVariantDoesNotSupport": "Varian \"Ukuran\" tidak mendukung penambahan gambar",
    "onlyShowAuthorizedStores": "Hanya tampilkan toko yang sudah diotorisasi",
    "untickWithOneClick": "Hapus centang dengan satu klik",
    "pleaseUntickAndReoperate": "Harap hapus centang dan operasikan kembali",
    "thereAreXExpireddeactivatedStoresIn": "Ada {X} toko yang kedaluwarsa/dinonaktifkan dalam integrasi saat ini",
    "failedToSave": "Gagal menyimpan",
    "shortDescription": "Deskripsi Singkat",
    "Item": "Item",
    "shippingFeeTips2": "Tanggal pesanan dibuat = Total Jumlah Biaya Kirim dan Total Biaya Pengiriman oleh Sistem sesuai tanggal yang di pilih",
    "whenTheProductsInTheChannel": "Ketika produk di gudang channel dibuat/diikat secara otomatis ke MSKU, produk tersebut akan ditambahkan secara otomatis juga ke gudang sistem yang dipasangkan, tetapi Anda masih perlu mengoperasikan sendiri inbound untuk memastikan inventaris yang akurat",
    "whenThereIsAStockChange": "Ketika terjadi perubahan stok di gudang channel yang telah diikat, nilai stok akan langsung didorong ke gudang channel",
    "channelWarehouseId": "ID Gudang  Channel",
    "forChannelProductsThatAreBound": "Untuk produk channel yang sudah terikat dengan gudang, setelah MSKU dibuat, akan otomatis ditambahkan ke gudang terikat.",
    "forMultiwarehouseChannelsAndChannelProducts": "Untuk multi-gudang channel dan produk channel dengan hubungan gudang terikat, setelah mengikat MSKU, maka akan ditambahkan secara otomatis ke gudang terikat.",
    "forMultiwarehouseChannelsWithWarehouseboundChannel": "Untuk multi-gudang channel dengan produk channel terikat gudang, MSKU akan ditambahkan secara otomatis ke gudang terikat setelah dibuat.",
    "existXwarehouse": "ada {X} gudang",
    "amountUnit": "Jumlah (Satuan:",
    "thePriceOfTokoWillBe": "Harga Toko akan diperbarui ke setiap gudang channel.",
    "priceAndStockWillBeUpdated": "Harga dan stok akan diperbarui ke gudang default",
    "dueToTheDelayInChannel": "Karena keterlambatan data pembaruan channel, diperlukan beberapa detik untuk memperbarui data yang disinkronkan, harap tunggu dengan sabar.",
    "theStoreWhereTheOrderIs1": "Toko tempat pesanan berada telah membuka fitur multi-gudang marketplace, mohon ikatkan dengan gudang Ginee terlebih dulu dan operasikan",
    "theStoreWhereTheOrderIs": "Toko tempat pesanan berada telah membuka saluran multi-gudang, dan hanya mendukung perubahan ke gudang Ginee terikat {XXXX}",
    "theWarehouseXxxxAlreadyHasA": "Repositori {XXXX} sudah memiliki tugas dalam tarikan dan dapat dilihat di catatan tarik, harap tunggu tugas selesai",
    "youNeedToIntegrateAstore": "Anda harus mengikat toko {x} sebelum Anda dapat menggunakan {y} Payments",
    "pleaseSelectTheStoreaccountTo": "Silakan pilih toko/akun {x} untuk melakukan pembayaran",
    "theProductsXxxxInTheOrder": "Pesanan {XXXX} di produk channel {XXXX} didistribusikan ke gudang {XXXX} sesuai dengan hubungan yang mengikat antara gudang channel dan gudang sistem.",
    "theCurrentProductsWarehouseIsEmpty": "Gudang produk kosong. Silakan masuk di keeppack terlebih dahulu.",
    "xIsOutOfStockIn": "{X} sudah kehabisan stok dalam {X}, mohon restock",
    "shopeebukalapaktokopediaOrdersUseGineeTemplateFor": "Pesanan Shopee/Bukalapak /Tokopedia menggunakan template Ginee untuk mencetak label pengiriman",
    "buyerPhone": "Nomor Pembeli",
    "xNewWarehouses": "{X} gudang baru ditambahkan.ID Gudang:{X}",
    "editStock": "Edit Stock",
    "warehouseIsEmpty": "Tidak memiliki gudang",
    "dueToTheLimitationsOfThe": "Karena keterbatasan API Keeppack, produk ini tidak dapat dioperasikan di Ginee, mohon mengoperasikan setelah Inbound di Keeppack",
    "pleaseFindTheBindingEmailIn": "Silakan pergi ke Tokopedia Seller Center [Pengaturan - Pengaturan Aplikasi Pihak Ketiga] atau temukan email di kotak surat Anda untuk menerima aplikasi pengikatan terlebih dahulu",
    "created": "Buat",
    "allstocktakingissuccessful": "Semua stock opname berhasil",
    "partialstocktaking": "Parsial stock opname",
    "allstocktakingfailed": "Semua stock opname gagal",
    "someexecute": "Sebagian terlaksana",
    "faileds": "Telah gagal",
    "thePullTaskHasBeenCreated": "Sukses dalam menciptakan tugas tarik",
    "executed": "Telah terlaksana",
    "keeppackWarehouse": "Gudang keeppack",
    "unbindMsku": "Lepas Ikatan MSKU",
    "PullRecord": "Riwayat Tarik Stok",
    "thisStoreHasBeenConnectedTo": "Toko ini tersinkronisasi ke Akun Ginee lainnya putuskan sinkronisasi Toko dari Akun Ginee dibawah ini sebelum melanjutkan:",
    "ManualInbound": "Inbound Manual",
    "stocktakingInbound": "Inbound Stockopname",
    "NormalInbound": "Inbound Normal",
    "notPushed": "Belum Dipush",
    "partComplete": "Sebagian Selesai",
    "partInvalid": "Sebagian Invalid",
    "tripartiteSingleNumber": "Nomor Inbound {warehouse}",
    "storageInformation": "Info Inbound",
    "types": "Tipe",
    "plannedInbound": "Penerimaan direncanakan",
    "receiptCost": "Harga penerimaan",
    "withAttachments": "Dengan lampiran",
    "automaticallyCreateMsku": "Buat MSKU secara otomatis",
    "createMskuManually": "Buat MSKU secara manual",
    "currentlyThirdPartyWarehouseSkuCode": "Saat ini, kode SKU gudang Keeppack harus sama dengan MSKU untuk dapat diikatkan",
    "warehouseKeeppackWarehouseInboundManagement": "Gudang / Gudang keeppack /Manajemen Inbound",
    "warehouseKeeppackWarehouseOutboundManagement": "Gudang / Gudang keeppack /Manajemen Outbound",
    "warehouseKeeppackWarehouse": "Gudang / Gudang Keeppack /Catatan Informasi Stok",
    "theCurrentCommodityWarehouseIsEmpty": "Gudang komoditas saat ini kosong, dan penyimpanan selesai dalam keeppack",
    "YouWillNotBeAble": "1. Anda tidak akan dapat melihat informasi pesanan untuk toko ini.\n2. Anda tidak akan menemukan toko ini di pengaturan toko",
    "temperatureControlled": "Suhu Terkontrol",
    "standard": "Standar",
    "storageType": "Tipe Penyimpanan",
    "enableWare": "Yakin ingin mengaktifkan mode multigudang?",
    "afterEnablingTheMultiwarehouseModeAnd": "Setelah mengaktifkan mode multi-gudang dan mengirimkan info produk, mode multi-gudang tidak dapat dimatikan.",
    "activateMultiwarehouse": "Aktifkan Multi-gudang",
    "turnOffMultiwarehouse": "Matikan Multi-gudang",
    "wantTo": "Yakin ingin mematikan mode multigudang?",
    "afterTheMultiwarehouseModeIsTurned": "Setelah mode multi-gudang dimatikan, informasi multi-gudang dan stok akan dikosongkan.",
    "kongfuWarehouse": "Gudang KONGFU",
    "useTheStockInformationOfThe": "Gunakan informasi stok gudang KONGFU untuk mengaktifkan sinkronisasi otomatis untuk meningkatkan efisiensi manajemen",
    "afterTheAuthorizationIsSuccessfulYou": "Setelah otorisasi berhasil, Anda dapat menggunakan layanan pemenuhan KONGFU untuk mengelola inbound dan outbound produk dari gudang;",
    "afterClickingTheAuthorizeButtonKongfus": "Setelah mengklik tombol [Otorisasi], informasi gudang KONGFU akan ditarik untuk Anda, silakan pilih gudang yang diperlukan untuk menyelesaikan proses otorisasi",
    "PleaseContactKongfuCustomerService": "2. Silakan hubungi layanan pelanggan KONGFU untuk mendapatkan secretKey",
    "ItIsRecommendedToFill": "1. Direkomendasikan untuk mengisi informasi yang disepakati dengan KONGFU untuk nama akun dan nama consignor",
    "informationDescription": "Deskripsi informasi:",
    "consignorName": "Nama Consignor",
    "acuntName": "Nama Akun",
    "completeTheRepositoryAuthorizationConfirmationIn": "Konfirmasi otorisasi gudang lengkap dalam manajemen konfigurasi KONGFU",
    "afterSelectingAndSavingSubjectTo": "Setelah memilih dan menyimpan, silakan hubungi layanan pelanggan KONGFU secara offline untuk menyelesaikan peninjauan otorisasi gudang, dan kembali ke sistem Ginee, di [Otorisasi-Daftar Toko] seperti yang dipersyaratkan oleh proses otorisasi KONGFU",
    "pleaseSelectTheWarehouseInformationThat": "Pilih informasi gudang yang perlu diotorisasi dan selesaikan proses otorisasi online",
    "pleaseSelectTheWarehouseThatNeeds": "Pilih gudang yang perlu diotorisasi",
    "applyForAuthorization": "Pengajuan untuk otorisasi",
    "afterTheAuthorizationIsCompletedPlease": "Setelah audit selesai, pastikan untuk kembali ke sistem Ginee dan menyelesaikan konfirmasi otorisasi gudang di manajemen konfigurasi [Otorisasi-Daftar Toko] KONGFU",
    "pleaseContactKongfuCustomerServiceOffline": "Silakan hubungi layanan pelanggan KONGFU secara offline untuk menyelesaikan tinjauan otorisasi gudang",
    "approvalStatus": "Status pengajuan",
    "reasonForFailureTheThirdParty": "Alasan kegagalan: Gudang pihak ketiga menolak pengajuan otorisasi gudang, silakan hubungi layanan pelanggan Gudang pihak ketiga untuk mengonfirmasi",
    "timeToOperateAuthorizedWarehouseInformation": "Saatnya mengoperasikan informasi gudang terotorisasi di Ginee",
    "theTimeToUpdateWarehouseInformation": "Saatnya memperbarui informasi gudang di Ginee",
    "consignorCode": "Kode Consignor",
    "theSystemWillAutomaticallyUpdateThe": "Sistem akan secara otomatis memperbarui status audit gudang pihak ketiga untuk Anda setiap jamnya. Jika Anda perlu melihat hasil audit secara real time, Anda dapat mengklik [Perbarui Status Audit] secara manual untuk melihat",
    "passed": "Sudah Lolos",
    "theWarehouseAuthorizationApplicationInformationHas": "Informasi pengajuan otorisasi gudang telah berhasil diberitahukan ke gudang pihak ketiga",
    "afterConfirmationTheSystemWillCall": "Setelah konfirmasi, sistem akan memanggil antarmuka KONGFU untuk Anda memulai pengajuan otorisasi gudang. Setelah mengonfirmasi aplikasi, silakan hubungi petugas layanan pelanggan KONGFU untuk menyelesaikan peninjauan otorisasi. Sistem akan secara otomatis menyinkronkan status peninjauan untuk Anda setiap jam. Jika Anda perlu memeriksa status Audit waktu nyata, harap operasikan [Perbarui Status Audit] di halaman manajemen konfigurasi untuk mendapatkan hasil audit",
    "areYouSureToInitiateA": "Apakah Anda yakin untuk memulai mengajukan otorisasi gudang ke gudang pihak ketiga?",
    "initiateAuthorizationApplication": "Mulai pengajuan otorisasi",
    "theListOnlyShowsUnauthorizedWarehouse": "Daftar hanya menampilkan informasi gudang yang tidak terotorisasi, silakan hubungi layanan pelanggan Kongfu secara offline untuk menyelesaikan peninjauan dan konfirmasi setelah konfirmasi",
    "selectAuthorizedWarehouse": "Pilih gudang yang diotorisasi",
    "gineeWillPullTheWarehouseInformation": "Ginee akan menarik informasi gudang di KONGFU untuk Anda dengan memanggil antarmuka KONGFU, pilih otorisasi gudang yang diperlukan, dan hubungi petugas layanan pelanggan KONGFU untuk menyelesaikan tinjauan otorisasi gudang baru",
    "tipsa": "Tips",
    "startToPullTheLatestWarehouse": "Mulai menarik informasi gudang KONGFU terbaru untuk Anda, tunggu antarmuka KONGFU mengembalikan informasi, harap menunggu",
    "updateApprovalStatus": "Perbarui Status Persetujuan",
    "confirmAuthorizedWarehouseIdXxxx": "Konfirmasikan ID gudang terotorisasi: XXXX , XXXX",
    "authorizationStatusXxxx": "Status Otorisasi: {XXXX}",
    "addWarehousesForApplicationAuthorization": "Tambahkan 3 gudang untuk pengajuan otorisasi, ID gudang: XXXX , XXXX",
    "noMoreThanXxxCharacters": "Tidak lebih dari {X} karakter",
    "customsWeight": "Berat Kotor",
    "customsDeclarationAmount": "Nilai Invoice",
    "customsCode": "Kode HS",
    "customsEnglishName": "Nama Barang Inggris",
    "customsDeclarationChineseName": "Nama Barang Mandarin",
    "ifTheWarehouseForThisDelivery": "Jika gudang untuk pengiriman ini adalah KONGFU, dan barang memerlukan bea cukai, harap isi informasi bea cukai",
    "customsDeclarationInformation": "Info Bea Cukai",
    "theProductDeclarationInformationIsMissing": "Informasi bea cukai produk tidak ada, harap pindah ke halaman pengeditan master produk untuk menyelesaikan dan menyelesaikan operasi",
    "mskuDoesNotConformToThe": "MSKU tidak sesuai dengan spesifikasi antarmuka gudang pihak ketiga, harap ubah dan operasikan",
    "theProductNameDoesNotConform": "Nama produk tidak sesuai dengan spesifikasi antarmuka gudang pihak ketiga, harap ubah sebelum pengoperasian",
    "TheMskuDoesNotExceed": "MSKU tidak melebihi 50 karakter, dan hanya angka, huruf, dan simbol khusus \"-\" yang didukung",
    "ProductNameDoesNotExceed": "Nama produk tidak melebihi 200 karakter",
    "channelRequirements": "Persyaratan Channel",
    "ifTheProductsDeliveredToThe": "Jika barang yang dikirim ke gudang kali ini adalah untuk fulfillment atau perlu dikirim melintasi perbatasan di Tiongkok, pastikan untuk memilih Deklarasi dan isi informasi deklarasi",
    "needToDeclare": "Perlu Deklarasi",
    "noDeclarationRequired": "Tidak Perlu Deklarasi",
    "totalNumberOfSkus": "Jumlah total SKU",
    "awb": "AWB",
    "outboundAmount": "Nominal Outbound",
    "outboundQuantity": "Jumlah Outbound",
    "outboundInformation": "Info Outbound",
    "doNotEnableAutomaticBinding": "Jangan aktifkan pengikatan otomatis",
    "thisFieldIsRequiredWhenThe": "Kolom ini diperlukan saat antarmuka gudang pihak ketiga mengharuskan retur barang ke gudang",
    "returnTrackingNumber": "Nomor Retur",
    "thirdpartyWarehouseTransactionReturn": "Pengembalian transaksi gudang pihak ketiga",
    "manualSync": "Sinkronisasi Manual",
    "timingSynchronization": "Singkronisasi Waktu",
    "reasonReceivedAThirdpartyWarehouseInterface": "Alasan: Menerima permintaan antarmuka gudang pihak ketiga{x}",
    "actualInbound": "Nominal Inbound",
    "theOrderIsInconsistentWithThe": "Pesanan tidak sesuai dengan negara tempat gudang berada, harap ubah gudang tepat waktu",
    "theChannelInterfaceCannotGenerateThe": "Channel tidak dapat menghasilkan label, dan gudang saat ini tidak bisa memenuhi pesanan. Mohon ubah gudang tepat waktu",
    "mskuIsNotAddToThe": "MSKU tidak ditambahkan ke gudang. Silakan tambahkan terlebih dahulu.",
    "rejected": "Ditolak",
    "outboundListOutboundListInThe": "3.Manajemen Outbound: Daftar outbound di gudang, termasuk nomor, status and detil",
    "inboundListInboundListInThe": "2.Manajemen Inbound: Daftar inbound di gudang, termasuk nomor, status and detil",
    "productsProductsInTheWarehouseIncluding": "1.Produk: Produk di gudang, termasuk detil, stok, dan nilai di gudang",
    "agreeToAuthorizeGineeToObtain": "Setuju untuk mendapatkan info gudang",
    "editMultiwarehouseStock": "Edit Stok Multigudang",
    "totalStock": "Total Stok",
    "add2": "Tambah",
    "openMultiwarehouses": "Buka Fitur Multigudang",
    "closeMultipleWarehouses": "Tutup Fitur Multigudang",
    "noteHighSpeedPrintingDoesNot": "Catatan: cetak cepat tidak mendukung pencetakan label pengiriman template Ginee (Tokopedia hanya mendukung label Pengiriman template Ginee)",
    "massEditStockIsAvailableFor": "Edit stok massal tersedia untuk stok gudang satuan",
    "agreecontinueAuthorization": "Setuju, lanjutkan otorisasi",
    "theStockAndProductInformationOf": "Informasi stok dan produk gudang Fastock akan disinkronkan secara otomatis dalam dua arah untuk meningkatkan efisiensi manajemen",
    "afterClickingTheAuthorizeButtonYou": "Setelah klik Otorisasi, Anda dapat menggunakan fulfillment Fastock untuk mengelola inbound dan outbound produk;",
    "PleaseContactTheWarehouseService": "2. Silakan hubungi penyedia layanan gudang untuk mendapatkan kode pelanggan/ no. gudang/AppKey/SecretKey",
    "BeSureToSelectThe": "1. Pastikan untuk memilih lokasi layanan dengan benar, jika tidak, integrasi akan gagal",
    "ifYouNeedToChangeWarehouse": "Jika Anda perlu mengubah informasi gudang, silakan ke Manajemen Konfigurasi",
    "theFastockFulfillmentWarehouseHasBeen": "Gudang fulfillment Fastock telah berhasil dibuat untuk Anda, dan dapat mulai digunakan",
    "received": "Diterima",
    "warehouseBreadcrumbProduct": "Gudang / Gudang {warehouseName} / informasi produk",
    "warehouseBreadcrumbInbound": "Gudang / Gudang{warehouseName} / Manajemen Inbound",
    "warehouseBreadcrumbOutbound": "Gudang / Gudang{warehouseName} / Manajemen Outbound",
    "warehouseBreadcrumbPullRecord": "Gudang / Gudang {warehouseName} / Catatan Informasi Stok",
    "unboundStore": "Toko Tidak Terikat",
    "storeAuthorizationHasExpired": "Otorisasi toko telah kedaluwarsa",
    "hongkong": "Hong Kong",
    "whetherItIsTurnedOnOr": "Apakah itu dihidupkan atau tidak",
    "theSkuRemovalQuantityCannotExceed": "Jumlah penghapusan sku tidak boleh melebihi jumlah pesanan",
    "productCodeRepeatedModification": "Modifikasi kode produk berulang kali",
    "cantDeleteAllDetails": "Tidak dapat menghapus semua detail",
    "theOutboundListHasBeenAllocated": "Daftar outbound telah dialokasikan dan tidak dapat diubah",
    "failedToRequestGxb": "Gagal meminta gxb",
    "theDetailsOfTheOutboundList": "Detail daftar outbound telah dialokasikan dan tidak dapat diubah",
    "outboundListDetailsDontExist": "Detail daftar outbound tidak ada",
    "theMergingSkuOfInboundList": "Penggabungan sku dari daftar inbound tidak normal, silakan hubungi administrator",
    "theSkuAcceptanceQuantityOfThe": "Jumlah penerimaan sku daftar inbound telah berubah, silakan coba lagi",
    "orderDetailsCannotBeChecked": "Detail pesanan tidak dapat dicek",
    "productInformationDoesNotExist": "Informasi produk tidak ada",
    "returnAwbNumberCannotBeEmpty": "Nomor AWB retur tidak boleh kosong",
    "theInboundListHasBeenAccepted": "Daftar inbound telah diterima",
    "theInboundListDoesntExist": "Daftar inbound tidak ada",
    "multipleLastMileScacCodesCannot": "Beberapa kode SCAC last mile tidak dapat muncul di bawah urutan yang sama",
    "theDomesticAwbNumberUnderThe": "Nomor AWB logistik domestik dengan pesanan yang sama tidak dapat diulang",
    "homeDeliveryDoesntNeedToFill": "Pengiriman ke rumah tidak perlu mengisi informasi penjemput",
    "weightCanOnlyBeAPositive": "Berat hanya bisa berupa bilangan bulat positif",
    "theLastMilesTrackingNumberIs": "Nomor AWB last mile tidak boleh kosong, dan kode SCAC last mile tidak boleh kosong",
    "theLabelPdfIsntEmptyAnd": "PDF label tidak boleh kosong, dan nomor pesanan last mile tidak boleh kosong",
    "theSelectedWarehouseDoesNotSupport": "Gudang yang dipilih tidak mendukung bisnis ini",
    "lastMileInformationlogisticsProductCannotBe": "Informasi last mile/produk logistik tidak boleh kosong pada waktu yang bersamaan",
    "documentNumberCannotBeEmpty": "Nomor dokumen tidak boleh kosong",
    "productQuantityCanOnlyBeA": "Kuantitas produk hanya dapat berupa bilangan bulat positif",
    "theDestinationCountryMustBeConsistent": "Negara tujuan harus konsisten dengan negara penerima",
    "recipientCountryprovincecityParameterIsMissing": "Parameter Negara/Provinsi/Kota Penerima tidak ada",
    "theOrderHasBeenProcessedAnd": "Pesanan telah diproses dan tidak dapat diubah",
    "matchesMultipleOrders": "mapping dengan banyak pesanan",
    "notWeighedWithoutWeight": "Tidak ditimbang tanpa berat",
    "theDocumentNumberAndLastMile": "Nomor dokumen dan nomor last mile kosong",
    "lastLegNumberAlreadyExists": "Nomor last mile sudah ada",
    "orderShipped": "Pesanan dikirimkan",
    "pickupPersonInformationCannotBeEmpty": "Informasi penjemput tidak boleh kosong",
    "theOrderHasBeenShippedAnd": "Pesanan telah dikirim dan tidak dapat dibatalkan",
    "senderCountryprovincecityParameterIsMissing": "Parameter Negara/Provinsi/Kota Pengirim tidak ada",
    "logisticsChannelDoesntSupport": "Channel logistik tidak mendukung",
    "thisStatusDoesntSupportModification": "Status ini tidak mendukung modifikasi",
    "pleaseCheckThePickupMethod": "Silakan periksa metode pickup",
    "outboundTypeNotMapped": "Jenis outbound tidak dipetakan",
    "pleaseCheckTheScac": "Silakan cek SCAC",
    "productcombinedProductQuantityMustBe": "Jumlah produk/produk kombinasi harus >0",
    "documentNumberAlreadyExists": "Nomor dokumen sudah ada",
    "pleaseCheckTheReceiver": "silakan cek penerimanya",
    "warehouseCodeDoesNotExist": "Kode gudang tidak ada",
    "codAmountAndCodCurrencyCan": "Jumlah COD dan mata uang COD tidak hanya bisa diisi satu",
    "pleaseCheckTheBusinessType": "Harap periksa jenis bisnisnya",
    "failedToCheckDestinationCountryprovinceregion": "Gagal cek negara/provinsi/wilayah tujuan",
    "logisticsChannelTrackingFailed": "Pengecekan channel logistik gagal",
    "noPermissionToOperateThisOrder": "Tidak ada izin untuk memprosess pesanan ini",
    "thePlatformHasAuthorizedTheConsignor": "Platform telah mengotorisasi consignornya",
    "tokenError": "token error",
    "noValidAuthorizedWarehouse": "Tidak ada gudang otorisasi yang valid",
    "platformMismatch": "ketidakcocokan platform",
    "noConsignorPermission": "Tidak ada izin consignor",
    "theTokenDoesntMatch": "Token platform tidak sesuai",
    "thePlatformConsignorDoesntMatch": "consignor platform tidak sesuai",
    "pleaseCheckIfThereIsError": "Mohon dicek apakah ada kesalahan di gudang",
    "authorizationHasBeenApprovedPleaseDont": "Otorisasi telah disetujui, tolong jangan ulangi operasi",
    "noConsignorOnThisPlatform": "Tidak ada consignor di platform ini",
    "userHasNo": "user tidak memiliki otorisasi untuk gudang ini",
    "userHasNoAuthorizationForThis": "user tidak memiliki otorisasi untuk consignor ini",
    "userDoesntHaveConsignorsAuthorization": "user tidak memiliki otorisasi consignor",
    "warehouseAuthorizationRecordDoesNotExist": "Catatan otorisasi gudang tidak ada",
    "duplicateAuthorizationApplication": "Pengajuan otorisasi terduplikasi",
    "warehouseDoesntExist": "Gudang tidak ada",
    "consignorDoesntExists": "Consignor tidak ada",
    "platformInformationVerificationFailedPleaseContact": "Verifikasi informasi platform gagal, harap hubungi administrator",
    "consignorAlreadyExists": "Consignor sudah ada",
    "noTrackingNumber": "Tidak ada nomor AWB",
    "hasTrackingNumber": "Ada nomor AWB",
    "trackingNumberStatus": "Status nomor AWB",
    "rulesBetween": "{name} Harus diantara {range}",
    "waitingOutbound": "Menunggu Outbound",
    "productPriceShouldBeBetween": "Harga produk harus antara 1-1,000,000,000",
    "pleaseMoveToTheMasterProduct": "Harap pindah ke halaman pengeditan master produk untuk menyelesaikan dan menyelesaikan operasi",
    "kongfuRequiresThatTheLabelMust": "*KONGFU mengharuskan label diakhiri dengan \".pdf\". Jika otorisasi berhasil, berarti Anda setuju bahwa KONGFU dapat memperoleh label valid jangka panjang",
    "outboundListNumber": "Nomor Outbound",
    "inventoryChangesDueToManualOperation": "Perubahan inventaris karena operasi manual oleh personel gudang",
    "totalDiscountedPrice": "Total Harga Diskon",
    "inventoryChangesDueToWarehousePersonnel": "Perubahan stok karena personel gudang secara manual membatalkan masuk dan mengubah Qty masuk",
    "thirdpartyWarehouseInboundAdjustment": "Penyesuaian masuk Gudang pihak ketiga",
    "sinceTheKongfuApiLimitsThe": "Karena KONGFU API membatasi biaya masuk menjadi 999.999, perlu diketahui bahwa jika biayanya lebih dari 999.999, kami akan menggunakan 999.999",
    "pleaseCheckTheDocumentType": "Silakan periksa jenis dokumen.",
    "tryNow": "Coba Sekarang",
    "talkLater": "Bicara lagi nanti",
    "congratulationsYouWillGetADay": "Selamat, Anda akan mendapatkan free trial gratis selama 7 hari dengan fitur sistem lengkap",
    "theTrialVersionHasExpiredAnd": "Versi free trial telah kedaluwarsa, dan paket telah diturunkan ke versi gratis. Jika Anda perlu upgrade, silakan beli paket",
    "theTrialPackageWasUpdatedSuccessfully": "Pembaruan rencana uji coba berhasil",
    "warehouseWarehousenameWarehouseActive": "Gudang / Gudang {warehouseName} / {active}",
    "theSalesTypeNameIsEmpty": "Nama jenis penjualan kosong",
    "generalInformation": "Informasi Dasar",
    "imageForPos": "Gambar untuk POS",
    "onlineAttributes": "Atribut Online",
    "refurbished": "Diperbaharui",
    "insertProductWeightIncludingThePackaging": "Masukkan berat produk termasuk packaging untuk menghitung tarif pengiriman.",
    "maximumMax": "Maksimum: {max}",
    "kilogramKg": "Kilogram (kg)",
    "gramGr": "Gram (gr)",
    "insertProductDimensionAfterPackagingTo": "Masukkan dimensi produk setelah pengemasan untuk menghitung tarif pengiriman.",
    "activatingThePreorderFeatureAllowsYou": "Mengaktifkan fitur Pre-Order memungkinkan Anda untuk memperpanjang waktu pemrosesan pesanan hingga 90 hari (termasuk akhir pekan dan hari libur nasional)",
    "productIsReadyStock": "Produk Ready Stok",
    "productNeedLongerShippingProcess": "Produk membutuhkan proses pengiriman yang lebih lama.",
    "processingTime": "Waktu Proses",
    "mokaOrderBetweenDays": "Pemesanan Moka: antara 1-90 hari.",
    "mokaProductImageTooltip": "Tambahkan hingga 12 foto . Ukuran min 500x500px dan maks 8MB. Format: PNG, JPEG, BMP.",
    "Inventory": "Inventori",
    "cost": "Biaya",
    "isMultiplePricePerSalesType": "Adalah beberapa harga per jenis penjualan",
    "trackStock": "Track stok",
    "alert": "Pengaman",
    "alertAt": "Pengaman di",
    "itemStockCanNotBeChanged": "Stok barang tidak dapat diubah setelah menyimpan barang tersebut!",
    "thisItemCanNotBeTracked": "Item ini tidak dapat dilacak karena stok inventaris tidak dilacak.",
    "avgCostCanNotBeChanged": "Biaya rata-rata tidak dapat diubah setelah menyimpan item!",
    "trackCogs": "Track COGS",
    "avgCost": "Biaya Rata-rata",
    "theSynchronizationIsCompleteAndThe": "Sinkronisasi selesai dan jenis penjualan berikut tidak berlaku lagi",
    "Empty": "Kosong",
    "domesticLogisticsTrackingNumberCannotBe": "Nomor pelacakan logistik domestik tidak boleh kosong",
    "consignorDoesntMatch": "Consignor tidak cocok",
    "documentNumberDoesNotExist": "Nomor dokumen tidak ada",
    "trackingNumberAlreadyExists": "Nomor pelacakan sudah ada",
    "packageAlreadyExists": "paket sudah ada",
    "paketTambahanTidakBolehLebihBesar": "Paket tambahan tidak boleh lebih besar dari jumlah total produk",
    "theOrderHasBeenShippedNo": "Pesanan telah dikirim, tidak ada paket tambahan yang dapat ditambahkan",
    "productInformationCantBeEmpty": "Informasi produk tidak boleh kosong",
    "theresEmptyDataInTheCombination": "Ada data kosong di nomor paket informasi produk kombinasi",
    "declaredAmount": "Jumlah yang dideklarasikan",
    "declaredWeight": "Berat yang dideklarasikan",
    "menuProductMoka": "Produk / Moka POS",
    "menuProductMokaAdd": "Produk / Moka POS / Tambah Produk",
    "menuProductMokaEdit": "Produk / Moka POS / Edit Product",
    "stockInOutAlert": "Riwayat Stok Masuk/Keluar akan menampilkan semua perubahan di Stok Gudang selama 3 bulan terakhir",
    "theStockCanBePushedTo": "Stok dapat didorong ke Moka hanya setelah menyalakan track stok",
    "youHaveADayFullfeaturedTrial": "Anda memiliki 7 hari gratis free trial untuk fitur lengkap!",
    "youAreUsingTheFreeVersion": "Anda sekarang memakai Ginee versi Free. \nJika Anda memerlukan fitur seperti manajemen gudang dan sinkronisasi stok, Anda dapat mendaftar *satu kali* untuk mendapatkan free trial 7 hari dengan fitur lengkap Ginee.\nApakah anda setuju untuk mengajukan trial? (Mulai berlaku dalam 15 menit setelah pengajuan)",
    "Cname": "Nama Channel",
    "thisStaffAccountHasNoPermission": "Akun staf ini tidak memiliki izin untuk mengakses sistem, harap hubungi merchant/admin untuk menambahkan akun staf ke sistem dan menetapkan izin",
    "makeSureTheSwitchIsTurned": "Pastikan tombol switch dinyalakan saat memodifikasi stock, jika tidak, modifikasi akan gagal",
    "printTaskCompletionTime": "Cetak waktu penyelesaian tugas",
    "printTaskCreationTime": "Cetak waktu pembuatan tugas",
    "noteLazadaAndBlibliOrdersDo": "Catatan: Pesanan Lazada dan Blibli tidak mendukung pengaturan status pengemasan",
    "prefixMatch": "Pencocokkan prefiks",
    "prefixSearch": "Pencarian dengan Awalan",
    "GineeAccountCanOnlyAuthorize": "1 akun Ginee hanya dapat mengotorisasi 1 akun Accurate, jika Anda memiliki lebih banyak kebutuhan, silakan hubungi CS",
    "matchingFailedThePreviousMatchingOption": "Pencocokkan gagal: pilihan pencocokkan sebelumnya tidak dapat ditemukan, harap konfirmasikan apakah pilihan tersebut telah dihapus di Accurate",
    "itemPriceCredit": "Omset Penjualan",
    "freeShippingMaxFee": "Biaya Promosi Gratis Ongkir",
    "paymentFee": "Biaya Pembayaran",
    "lazpicklaztopCommission": "Komisi Lazpick/LazTop",
    "platformRequirementsTheDescriptionLengthMust": "Persyaratan platform: panjang deskripsi harus antara 100 dan 3000 karakter",
    "currentlyBumped": "Dinaikkan Saat Ini",
    "numberOfBumpProducts": "Jumlah Produk Dinaikkan",
    "bumpFixedSupportMaximumProducts": "Naikkan Konstan mendukung hingga 5 produk",
    "bumpfixed": "Naikkan Konstan",
    "bumpByTurns": "Naikkan Selang Seling",
    "setAsBumpFixed": "Tetapkan sebagai naikkan konstan",
    "setAsBumpByTurns": "Tetapkan sebagai naikkan selang seling",
    "tipsYouCanAddUpTo": "Tips: Anda dapat menambahkan hingga 30 produk sekaligus, harap prioritaskan sinkronisasi informasi produk di toko",
    "editingInformationSuchAsStockAnd": "Tidak support mengedit informasi seperti stok dan harga pembelian produk kombinasi",
    "failedToUploadImagePleaseReupload": "Gagal mengupload gambar, harap upload ulang gambar dan coba lagi",
    "solutionSteps": "Langkah solusi",
    "addBinding": "Tambah Ikatan",
    "pleaseClickToRedistribute": "Silakan klik untuk distribusi ulang",
    "pleaseAddBindingRelationship": "Harap tambah ikatan",
    "pleaseEnableDistributionSettings": "Harap aktifkan pengaturan distribusi",
    "distributionSettingsArentEnabled": "Pengaturan Distribusi tidak diaktifkan",
    "pleaseRestoreTheOrder": "Harap pulihkan pesanan",
    "ordersThatHaveBeenPlacedOn": "Stok dari pesanan yang telah disisihkan tidak dapat dikunci atau dipotong",
    "problemCauses": "Penyebab Masalah",
    "pleaseCreateAnInboundListAnd": "Harap buat daftar inbound dan selesaikan inbound",
    "pleaseAdjustTheShippingWarehouse": "Harap sesuaikan gudang pengiriman",
    "pleaseCompleteTheInboundInThe": "Harap selesaikan inbound di gudang pihak ketiga",
    "pleaseCreateAnInboundList": "Harap buat daftar inbound",
    "mskuInXWarehouseYOut": "Stok MSKU {y} di gudang {x} habis",
    "theOrderStatusOfTheX": "Status pesanan channel {x} adalah: {y} tidak mendukung pembuatan daftar outbound",
    "currentlyDoesNotSupportTheCreation": "Saat ini tidak mendukung pembuatan daftar outbound untuk channel {x}",
    "theXThirdpartyWarehouseDoesNot": "Gudang pihak ketiga {x} saat ini tidak mendukung pembuatan daftar outbound untuk negara {y}",
    "theXThirdpartyWarehouseDoesntCurrently": "Gudang pihak ketiga {x} saat ini tidak mendukung pembuatan daftar outbound untuk channel {y}",
    "bumpFixed": "Naikkan Konstan",
    "serviceFeeMapping": "Pemetaan Biaya Layanan",
    "platformFulfillmentFeeMapping": "Pemetaan Ongkir Dibayar Sistem",
    "platformRebateMapping": "Pemetaan Rabat Platform",
    "voucherSellerMapping": "Pemetaan Voucher Seller",
    "shippingDifferenceCashlessMapping": "Pemetaan Selisih Pengiriman (Non Tunai).",
    "shippingFeeMapping": "Pemetaan Biaya Pengiriman",
    "shippingPriceSetting": "Biaya pengiriman",
    "taxSetting": "pengaturan pajak",
    "salesInvoiceSetting": "Pengaturan faktur penjualan",
    "storeMapping": "Pemetaan Toko",
    "warehouseMapping": "Pemetaan Gudang",
    "database": "Database",
    "newCompleteOrdersAfterTheSt": "\"Pesanan Sampai\" setelah tanggal 1 bulan tersebut",
    "newCompleteOrderAfterMappingStore": "\"Pesanan Sampai\" untuk toko yang sudah dicocokkan",
    "afterEnablingIfAccurateHasStock": "Setelah diaktifkan, jika Accurate mengalami perubahan stok:\n1. Push ke Ginee secara real time, dan ubah stok Ginee;\n2. Dukungan untuk secara manual mengambil nilai stok terbaru dari Accurate di halaman daftar stok.",
    "stockSyncRules": "Aturan Sinkronisasi Stok",
    "mappingTime": "Waktu Pencocokkan",
    "branch": "Cabang",
    "chartOfAccounts": "Chart of Accounts",
    "pleaseCompleteAtLeast": "Harap selesaikan setidaknya 1 pencocokkan toko. Setelah pencocokkan toko berhasil, pesanan toko yang sudah selesai akan secara otomatis membuat Faktur Penjualan dan push ke Accurate",
    "gineeWarehouseCanOnly": "1 gudang Ginee hanya bisa dicocokkan dengan 1 gudang Accurate, harap cocokkan minimal 1 gudang",
    "enabledByDefault": "Diaktifkan secara default",
    "autoCreateRulesForSalesInvoice": "Secara otomatis membuat aturan untuk Faktur penjualan",
    "syncFromAccurateToGinee": "Sinkronkan dari Accurate ke Ginee",
    "syncFromGineeToAccurate": "Sinkronkan dari Ginee ke Accurate",
    "finance": "Keuangan",
    "mapFailed": "Pencocokkan Gagal",
    "notMapped": "Belum Dicocokkan",
    "mapped": "Sudah Dicocokkan",
    "stockChange": "Perubahan Stok",
    "pushMapMsku": "Push & Cocokkan MSKU",
    "repushStock": "Push ulang stok",
    "upToCharactersAndOnly": "Maks 50 karakter dan hanya mendukung: a-z, A-Z, 0-9, \"(\", \")\", \"-\", \".\", \"_\", \"/\", dan harga satuan harus >=1",
    "allUncreatedAndUnmappedMskusCurrently": "Semua MSKU yang tidak dibuat dan tidak dicocokkan saat ini dan selanjutnya ditambahkan",
    "afterItIsEnabledAllMskus": "Setelah diaktifkan, semua MSKU di gudang Ginee yang dicocokkan akan dipush ke gudang Accurate, SKU baru akan dibuat secara otomatis di Accurate, dan SKU yang ada akan dicocokkan secara otomatis dengan Accurate",
    "mappingRules": "Aturan Pencocokkan",
    "autoPushMap": "Push & Cocokkan Otomatis",
    "thisSkuAlreadyExists": "SKU ini sudah ada",
    "unitCostMustBe": "Biaya Satuan Harus >=1",
    "salesInvoice": "Faktur penjualan",
    "syncStock": "Sinkronisasi Stok",
    "theStoreIsMissingMappingInformation": "Toko tidak memiliki informasi pemetaan (Gudang, Cabang, Chart of Account, Pelanggan)",
    "source": "Sumber",
    "associatedOrderNumber": "Nomor Pesanan Terkait",
    "itIsRecommendedToConvertTo": "Disarankan untuk mengonversi ke jumlah mata uang yang sudah ditetapkan di sistem",
    "theCurrencySymbolIsBasedOn": "Simbol mata uang didasarkan pada mata uang yang diatur dalam sistem\\n    Jumlah harga adalah harga biaya rata-rata produk yang diinbound",
    "bulkrepush": "Re-push Massal",
    "alasanGagalPushGudangPihakKetiga": "Alasan gagal push gudang pihak ketiga",
    "noShelf": "Rak Default",
    "plannedQuantityTip": "Isi Perkiraan Jumlah Outbound Produk",
    "plannedQuantityTipOld": "Perkiraan Jumlah Outbound: Isi Perkiraan Jumlah Outbound Produk",
    "selectLocationTip": "Pilih Lokasi SKU Outbound",
    "exportSelectedOrderDetail": "Detail Daftar Outbound - Dipilih",
    "exportAllOrderDetail": "Detail Daftar Outbound - Semua",
    "exportSelectedOrderList": "Daftar Outbound - Dipilih",
    "exportAllOrderList": "Daftar Outbound - Semua",
    "mskuAndBarcode": "MSKU/Barcode",
    "confirmToSetAsInvalid": "Konfirmasi untuk mengaturnya sebagai invalid?",
    "bulkSubmitSuccess": "Berhasil dikirim, harap refresh halaman untuk lihat hasil proses",
    "bindFailed": "Gagal diikat, silakan pilih kembali lokasi",
    "bindSuccess": "Berhasil diikat",
    "autoCreateAndBind": "Buat lokasi secara otomatis dan ikat ke master SKU",
    "warehouseName2": "Nama Gudang",
    "warehouseCode": "Nomor gudang",
    "quantityAdded": "Jumlah yang ditambahkan",
    "sureToDelete": "Apakah Anda ingin menghapusnya?",
    "selectLocation": "Pilih lokasi SKU pada gudang",
    "enterQuantity": "Masukan jumlah dari outbound ini",
    "createLocationTips": "Jika Anda perlu membuat lokasi baru secara massal, Anda dapat membuatnya di manajemen lokasi.",
    "locationManagement": "Manajemen Lokasi",
    "warehouseStockColumnName": "Stok Gudang",
    "stockNotEnough": "Stok di gudang tidak mencukupi, tidak dapat outbound",
    "externalOrderNo": "No. Pesanan",
    "numSelectedWithValue": "{val} Dipilih",
    "column3TipOld": "Kolom 3: Lokasi (opsional) jika tidak diisi, sistem akan memilih lokasi dengan Stok Tersedia terbesar",
    "column2TipOld": "Kolom 2: Kuantitas (wajib)",
    "column1TipOld": "Kolom 1: SKU (wajib)",
    "column3Tip": "Kolom 3: Rak (opsional). Jika tidak diisi akan menggunakan lokasi default",
    "column2Tip": "Kolom 2: Kuantitas (wajib)",
    "column1Tip": "Kolom 1: SKU (wajib)",
    "copyTip": "Salin SKU, kuantitas jumlah outbound, rak, dan lokasi dari excel ke kotak teks, yang dapat dengan cepat dianalisis dan dikirim",
    "printDetails": "Cetak detail produk",
    "copyTip2": "Kolom pertama adalah SKU, kolom kedua kuantitas, kolom ketiga lokasi",
    "logisticsNumberLengthError": "Jumlah maksimum untuk nomor pengiriman adalah 200 karakter",
    "duplicateLocationError": "Duplikat informasi lokasi, hanya satu baris yang dapat dikirimkan untuk lokasi yang sama.",
    "setPushRatio": "Pengaturan Pengiriman Gudang-Pengaturan Pengiriman Gudang-jika Anda memiliki beberapa toko, Anda dapat mengikat gudang untuk setiap toko Anda di sini, dan Anda juga dapat mengatur rasio stok untuk setiap toko",
    "ifOnlyOneWarehouse": "Jika Anda hanya memiliki satu gudang, dan Anda tidak perlu mengikat lokasi, maka saat membuat Master Produk, sistem akan secara otomatis membuatkan satu lokasi per SKU untuk Anda, sehingga dapat langsung digunakan.",
    "addProductsToLocation": "Detail Stok Gudang-Setelah lokasi diatur, Anda dapat menaruh produk di lokasi. Pada halaman detail stok gudang, klik \\\"Tambah Produk ke Lokasi\\\" untuk mengikat produk dengan lokasi. Setelah pengikatan selesai, Anda dapat mengelola stok semua produk di lokasi ini di Manajemen Stok",
    "setUpLocation": "Manajemen lokasi-produk Anda ditempatkan di beberapa lokasi di gudang, Anda dapat mengatur lokasi gudang Anda",
    "setUpWarehouse": "Manajemen gudang-Anda perlu mengatur gudang Anda di fitur manajemen gudang",
    "helpShippingSettings": "Pengaturan Pengiriman Gudang",
    "helpStockDetails": "Detail Stok Gudang",
    "helpLocationManage": "Manajemen Lokasi",
    "helpWarehouseManage": "Manajemen Gudang",
    "warehouseManagementIntro": "Pengenalan fitur terkait Manajemen Gudang",
    "moreThanPlannedError": "Melebihi Perkiraan Jumlah Outbound",
    "INVALID": "Invalid",
    "PART_INVALID": "Sebagian Invalid",
    "COMPLETE": "Selesai",
    "PART_COMPLETE": "Sebagian Selesai",
    "PENDING": "Menunggu Outbound",
    "CREATE": "Memproses",
    "changeWarehouseAlert": "Ganti Gudang akan butuh isi ulang informasi SKU, mohon konfirmasi",
    "processResult": "Hasil Proses",
    "complete": "Selesai",
    "pushComplete": "Push Berhasil",
    "pushing": "Proses Push",
    "workingStatusCANCEL_FAIL": "Batalkan kegagalan",
    "workingStatusCANCEL_SUCCESS": "Batalkan kesuksesan",
    "workingStatusCANCELED": "Dibatalkan",
    "workingStatusCANCEL": "Dibatalkan",
    "workingStatusOUTBOUND_DONE": "Sudah Outbound",
    "workingStatusCANCELING": "Dibatalkan",
    "workingStatusWAITING_PICKUP": "Menunggu Pickup",
    "workingStatusPACKED": "Telah Dikemas",
    "workingStatusSORTING_AND_REVIEWING": "Sortir dan Periksa",
    "workingStatusPICKING_PROCESS": "Sedang Diambil",
    "workingStatusALLOCATED": "Dialokasikan",
    "workingStatusRECEIVED": "Telah Diterima",
    "workingStatusSYNC_FAIL": "Sinkronisasi gagal",
    "workingStatusSYNC_SUCCESS": "Sinkronisasi berhasil",
    "workingStatusCREATE": "Memproses",
    "workingStatus": "Status Kerja",
    "express": "Pengiriman Cepat",
    "editOutboundList": "Edit Daftar Outbound",
    "defaultDelivery": "Default",
    "selfPickup": "Ambil Ditempat",
    "outboundListDetail": "Detail Daftar Outbound",
    "failedLineTip": "Gagal (baris ini tidak dapat ditambahkan ke daftar Outbound)",
    "ifMoreThanStock1": "Jika jumlah SKU lebih besar dari stok tersedia, akan mengikuti stok maksimum yang tersedia",
    "ifTwoColumns1": "Jika hanya ada dua kolom, maka akan mengikuti analisis SKU + Jumlah",
    "copyTipOld": "Salin SKU, Jumlah, Lokasi, Analisis Ginee dari Excel",
    "outboundListInvalid": "Daftar Outbound Invalid",
    "outboundAll": "Outbound Semua",
    "outboundListOutbound": "Keluarkan Daftar Outbound",
    "outboundManagement": "Manajemen Outbound",
    "scanInstruction": "Dapat scan dengan menggunakan scanner dan tekan Enter, jika berhasil dianalisa, stok aktual produk akan +1",
    "receivedProduct": "Gudang",
    "goBack": "Sebelumnya",
    "addToOutboundList": "Tambahkan ke daftar Outbound",
    "row": "Baris",
    "operationSuccessful": "Operasi Berhasil!",
    "editSuccess": "Berhasil Diedit",
    "identify": "Analisa",
    "identifyResults": "Hasil",
    "ifMoreThanStock": "Jika jumlah sku lebih besar dari stok yang tersedia, akan menggunakan stok maksimum yang tersedia",
    "ifTwoColumns": "Jika hanya ada dua kolom, akan menggunakan SKU + Jumlah untuk menganalisis",
    "submit": "Ajukan",
    "directOutbound": "Outbound langsung",
    "locationStockNotEnough": "Stok Tersedia Lokasi Tidak Cukup",
    "locationAvailableStock": "Stok Tersedia Lokasi",
    "warehouseAvailableStock": "Stok Tersedia Gudang",
    "note": "Catatan",
    "plannedTotalAmount": "Perkiraan Nilai Total",
    "unitPrice": "Harga Satuan",
    "associatedDocumentNumber": "Nomor dokumen terkait",
    "refreshStatus": "Refresh Status",
    "outboundTime": "Waktu Outbound",
    "deliveryMethod": "Metode Pengiriman",
    "actualOutboundQty": "Jumlah Outbound Sebenarnya",
    "plannedOutboundQty": "Perkiraan Jumlah Outbound",
    "actualOutboundAmount": "Nilai Outbound Sebenarnya",
    "plannedOutboundAmount": "Perkiraan Nilai Outbound",
    "searchContent": "Cari konten",
    "sortListSelected": "Sortir daftar - Dipilih",
    "sortListAll": "Sortir daftar - Semua",
    "pickListSelected": "Daftar Pilih - Dipilih",
    "pickListAll": "Daftar Pilih - Semua",
    "outboundListSelected": "Daftar Outbound - Dipilih",
    "outboundListAll": "Daftar Outbound - Semua",
    "createOutboundList": "Buat Daftar Outbound",
    "greaterThan30Days": "> 30 hari",
    "lessThan30Days": "≤ 30 hari",
    "lessThan7Days": "≤7 hari",
    "lessThan3Days": "≤ 3 hari",
    "listNumber": "Daftar nomor",
    "searchType": "Jenis pencarian",
    "allocateOutboundList": "Alokasi Daftar Outbound",
    "documentType": "Tipe Dokumen",
    "shippedOld": "Outbound",
    "documentStatus": "Status Dokumen",
    "pleaseSelectData": "Silakan periksa data yang ingin Anda Export.",
    "basicFeaturesFreeForever": "Gratis Selamanya",
    "nMinutesRemaining": "{minus} menit tersisa",
    "gineeAndAccurateAreMappedBy": "Ginee dan Accurate dicocokkan dengan SKU. Karena Accurate tidak peka huruf besar/kecil, pastikan tidak ada nama SKU yang sama di gudang ini sebelum pencocokan.",
    "accurateRequiresSkuToHaveA": "Accurate mengharuskan MSKU maks 50 karakter dan hanya mendukung: a-z, A-Z, 0-9, \"(\", \")\", \"-\", \".\", \"_\", \"/\"",
    "otherSettingsAndMapping": "Pengaturan dan pencocokkan lainnya",
    "mapping": "Cocokkan",
    "automapSettings": "Pengaturan pencocokan otomatis",
    "pleaseSetAndMapTheCorrect": "Harap atur dan cocokkan dengan informasi yang benar",
    "basedOnAccurateStock": "Berdasarkan Stok Accurate",
    "basedOnGineeStock": "Berdasarkan Stok Ginee",
    "whenTheSkuOfGineeAnd": "Ketika SKU Ginee dan Accurate sudah cocok, jika stok di kedua sistem berbeda, silakan pilih stok dari sistem mana sebagai acuan, dan Ginee akan secara otomatis mendorong atau menarik stok setelah pencocokkan",
    "startingStock": "Stok Awal",
    "theSubproductsOfTheCombinedProduct": "Subproduk dalam produk kombinasi belum dicocokkan, selesaikan pencocokkan subproduk sebelum mencocokkan produk kombinasi",
    "theWarehouseHasMatchedTheAccurate": "Gudang telah dicocokkan dengan gudang Accurate, dan stok serta hpp harus > 0",
    "accurateAuthorizationHasExpiredPleaseReauthorize": "Otorisasi Accurate telah kedaluwarsa, mohon otorisasi ulang!",
    "accurateRequiresInventoryToBe": "Accurate mensyaratkan stok dan hpp >0",
    "goToChange": "Mengubah",
    "inboundWhenAddedToWarehouse": "Inbound saat ditambahkan ke gudang",
    "youHaveNotCompletedTheInitial": "Anda belum menyelesaikan konfigurasi awal Accurate, fitur yang relevan hanya dapat digunakan setelah tahap konfigurasi",
    "failedToDeleteThisProductHas": "Gagal dihapus! Produk ini telah dikaitkan dengan produk kombinasi, harap hapus produk kombinasi atau batalkan ikatan produk kombinasi terlebih dahulu",
    "noteIfTheWarehouseIsAdded": "Catatan: Jika inbound saat ditambahkan ke gudang adalah gudang WMS atau gudang pihak ketiga, setelah inbound, Anda perlu mengonfirmasi masuk di gudang WMS/pihak ketiga agar berhasil memulai sinkronisasi stok",
    "partnerAuthorizationFailed": "Partner auth failed",
    "customerMustBeFilled": "Pelanggan harus diisi",
    "itemXSuccessfullySaved": "Barang & Jasa \"{x}\" berhasil disimpan",
    "unitQtyMustBeFilled": "Nilai Satuan harus diisi",
    "itemXNotFoundOrHas": "Barang & Jasa {x} tidak ditemukan atau sudah dihapus",
    "accurateConfigurationInformationIsMissingPlease": "Informasi Config yang akurat tidak ada, harap periksa Manajemen Config dan coba lagi",
    "theWarehouseXWasNotFound": "Gudang \"{x}\" tidak ditemukan atau sudah dihapus",
    "brazil": "Brazil",
    "taiwan": "Taiwan",
    "savedSuccessfullyThisPageWillClose": "Berhasil disimpan! Halaman ini akan tertutup secara otomatis",
    "salesInvoicePushRecord": "Catatan Push Faktur penjualan",
    "orderPushTime": "Waktu Push Pesanan",
    "orderCompleteTime": "Waktu Penyelesaian Pesanan",
    "completeTime": "Waktu Penyelesaian",
    "updateResult": "Hasil Pembaruan",
    "productManage": "Manajemen Produk",
    "becauseTheNumberOfStoresExceeds": "Jumlah pesanan Anda telah mencapai batas paket dan fitur ini tidak dapat digunakan. Silakan hubungi layanan pelanggan untuk berlangganan paket baru",
    "theAuthorizationInformationIsFilledIncorrectly": "Informasi otorisasi tidak diisi dengan benar, silakan masuk ke pusat bantuan integrasi dan isi kembali",
    "channelItemXMoveOutPromotion": "Toko: Produk {x}: {y} pindah ID Promosi: {z}",
    "xManualReleaseOfReservedPromotion": "{x} Rilis Manual Stok Promosi yang Direserve",
    "promotionIdXHasEnded": "Toko: Produk {x}: {y} ikut dalam ID Promosi: {z} telah berakhir",
    "xManuallyEditedReservedPromotionStock": "{x} mengubah stok promosi yang direserve  secara manual, diperbarui dari {y} menjadi {z}",
    "channelProductXActivityIdY": "Toko: {x} Produk: {y} ID Promosi: {z}",
    "unknownSystemException": "Ketidak normalan sistem tidak diketahui, mohon hubungi customer service",
    "theChannelProductSpecificationCorrespondingTo": "Spesifikasi produk channel yang sesuai dengan promosi tidak ditemukan",
    "theChannelProductCorrespondingToThe": "Produk channel yang sesuai dengan promosi tidak ditemukan",
    "hasNoWarehouseInformationAndCannot": "MSKU tidak memiliki informasi gudang dan tidak dapat cadangkan stok aktif",
    "channelSkuHasNoBoundMsku": "SKU channel tidak memiliki MSKU terikat, buka daftar master produk untuk mengikat SKU terlebih dahulu",
    "notCompliantWithPromotionReservation": "Tidak sesuai dengan strategi reservasi promosi: \"Otomatis Mereserve Promosi yang Akan Datang dan yang Sedang Berjalan\"",
    "promotionReservationPolicyNotMet": "Tidak sesuai dengan strategy reservasi promosi: \"Otomatis mereservasi hanya untuk promosi yang Sedang Berjalan\"",
    "theCategoryOrAttributeInformationIs": "Informasi kategori atau atribut salah, harap coba lagi setelah menyinkronkan kategori",
    "setUpOrderInvoices": "Menyiapkan faktur pesanan",
    "orderPacking": "kemas pesanan",
    "orderDistribution": "Distribusi Pesanan",
    "syncOrder": "Sinkron Pesanan",
    "theOrderSSuccessfullyPrintedThe": "Urutan [%s] berhasil mencetak picking list",
    "theOrderssuccessfullyPrintedThePackingList": "Pesanan [%s] berhasil mencetak packing list",
    "orderSuccessInvoice": "Pesanan [%s] berhasil mencetak faktur",
    "orderSuccessLabel": "Pesanan [%s] berhasil mencetak label",
    "ordersreturnedInboundTheReturnInboundList": "Pesanan [%s] inbound retur, daftar inbound retur berhasil dibuat, nomor daftar: <a href=\"/erp/main/outbound-management-detail/%s/SELL\" target=\"_blank\"> %s</a>",
    "ordersredistributedSuccessfully": "Pesanan [{0}] didistribusikan ulang secara manual , mohon lihat riwayat operasi berikutnya untuk mengetahui hasilnya",
    "ordersfailedToPushWarehouseForDelivery": "Pesanan [%s] gagal push pengiriman dari gudang, alasan: %s",
    "theOrdersisSuccessfullyPushedToThe": "Pesanan [%s] berhasil dipush ke gudang",
    "orderspushWarehouseOperationSuccessfully": "Pesanan【%s】Berhasil mengoperasikan push gudang",
    "theOrderSSuccessfullySetThe": "Pesanan [%s] berhasil menetapkan faktur",
    "ordersarrangedDeliverySuccessfully": "Pesanan [%s] pengiriman berhasil diatur",
    "orderspackedSuccessfully": "Pesanan [%s] berhasil dikemas",
    "orderscancelledSuccessfully": "Pesanan [%s] berhasil dibatalkan",
    "theWarehouseSelectedForProductS": "Gudang yang dipilih untuk produk [%s] dalam pesanan [%s] diubah dari [%s] menjadi [%s]",
    "theProductSInTheOrder": "Produk [%s] dalam pesanan [%s] digantikan oleh [%s], dan gudang yang dipilih adalah [%s]",
    "theOrderSDeletesTheGift": "Pesanan [%s] menghapus hadiah [%s], jumlahnya [%s], dan gudang yang dipilih [%s]",
    "addGiftSToOrderS": "Tambahkan hadiah [%s] ke pesanan [%s], jumlahnya [%s], dan gudang yang dipilih [%s]",
    "theOrderSHasBeenCancelled": "Pesanan [{0}] telah dibatalkan (Memulihkan Pesanan)",
    "ordersmarkedAsPutOnHold": "Pesanan [{0}] ditandai \"Disisihkan\"",
    "theOrderSIsMarkedAs": "Pesanan [%s] ditandai sebagai [Pesanan Bermasalah-Stok habis], alasan: Stok Master produk [%s] yang terikat dengan Produk Channel [%s] habis",
    "orderSIsMarkedAsOut": "Pesanan [%s] ditandai sebagai [Pesanan Bermasalah-Kesalahan alokasi gudang], alasan: [%s] tidak mendukung penggunaan gudang fulfillment [%s]",
    "theOrderSIsAPromotion": "Pesanan [%s] adalah pesanan promosi, dan produk channel [%s] dialokasikan ke gudang [%s]",
    "theChannelProductNotOutbound": "Pesanan [%s] ditandai sebagai [Pesanan Bermasalah-MSKU tidak terkait dengan gudang], Produk Channel  [%s] tidak dapat membuat daftar outbound, alasan: Master Produk yang terikat [%s] tidak ada di gudang [%s]",
    "theChannelProductCantGen": "Pesanan [%s] ditandai sebagai [Pesanan Bermasalah-Gudang belum dialokasikan], Produk Channel  [%s] tidak dapat membuat daftar outbound, alasan: tidak ada aturan Gudang Pengiriman yang sesuai",
    "theChannelProductAllocate": "Produk Channel [%s] pada pesanan [%s], sesuai Aturan Gudang Pengiriman [%s] dialokasikan ke gudang [%s]",
    "theChannelProductSInThe": "Pesanan [%s] ditandai sebagai [Pesanan Bermasalah-Tidak Terikat MSKU], Produk Channel  [%s] tidak dapat membuat daftar outbound, alasan: tidak terikat Master Produk",
    "anOutboundListHasBeenCreated": "Daftar outbound telah dibuat untuk pesanan [%s], nomor pesanan: <a href=\"/erp/main/outbound-management-detail/%s/SELL\" target=\"_blank\">%s</a>",
    "theOrderSHasBeenSuccessfully": "Pesanan [%s] telah berhasil disinkronkan, dan status pesanan telah diperbarui dari [%s] menjadi [%s]",
    "syncToTheNewOrderS": "Disinkronkan dengan pesanan baru [%s], status pesanan adalah [%s]",
    "salesTaxAmountMustBeBetween": "'salesTax.amount' must be between 0 and 1000000000",
    "incorrectUnitFormat": "Format satuan salah, hanya konten yang didukung adalah \"jam\", \"hari kerja\", \"hari\", \"minggu\", \"bulan\"",
    "purchaseDurationMustBeBetween": "Durasi pembelian harus antara 1-365",
    "selectedWarehouse": "Gudang Dipilih",
    "addingToRepositoryXFailedReason": "Menambahkan ke repositori \"{x}\" gagal! Alasan kegagalan:",
    "purchaseDurationUnit": "Unit Durasi Pembelian (Hanya konten yang didukung adalah \"hour\", \"working day\", \"day\", \"week\", \"month\")",
    "thisCustomerHasOrdersInThe": "Pelanggan ini memiliki pesanan dalam 60 hari terakhir, Anda dapat memulai sesi",
    "theProductInboundStockCannotBe": "Stok inbound produk tidak boleh negatif",
    "theBundledProductHasNotBeen": "Produk kombinasi belum dikirim ke gudang!",
    "pleaseAddMskuToTheWarehouse": "Silakan tambahkan MSKU ke gudang dan selesaikan Inbound",
    "createSalesInvoice": "Membuat faktur penjualan",
    "beforePushingMatchingCombinedProducts": "Sebelum push & pencocokan produk kombinasi, perlu dipastikan bahwa semua produk kombinasi telah dipush & dicocokkan",
    "theSystemWillAutomaticallyPushSales": "Sistem akan secara otomatis push Faktur Penjualan ke Accurate pada pukul JKT time 03:00, 11:00, dan 19:00. Jika ketiganya gagal, tidak akan dipush lagi.",
    "ifGineeAndAccurateHaveThe": "Saat ini hanya mendukung stok Ginee sebagai Stok Awal",
    "thePushedMappedMskuSupports": "MSKU yang dipush dan dicocokkan mendukung pengaktifan sinkroniasi stok, dan untuk sementara  hanya mendukung sinkronisasi otomatis dari Ginee ke Accurate saat stok di Ginee berubah",
    "afterSettingTheTaxTypeWill": "Setelah pengaturan, jenis pajak akan disertakan saat membuat MSKU dari Ginee ke Accurate",
    "thisMappingCorrespondsToTheService": "Pencocokan ini sesuai dengan bagian Biaya Layanan Sistem pada pesanan",
    "thisMappingCorrespondsToTheShipBySys": "Pencocokan ini sesuai dengan bagian Ongkir Dibayar Sistem pada pesanan",
    "thisMappingCorrespondsToTheRebate": "Pencocokan ini sesuai dengan bagian Potongan Harga pada pesanan",
    "thisMappingCorrespondsToTheCommission": "Pencocokan ini sesuai dengan bagian Komisi pada pesanan",
    "thisMappingCorrespondsToTheShipReb": "Pencocokan ini sesuai dengan bagian Subsidi Biaya Pengiriman pada pesanan",
    "thisMappingCorrespondsToTheShipping": "Pencocokan ini sesuai dengan bagian Biaya Pengiriman pada pesanan",
    "thePromotionHasExpiredOrThe": "Promosi telah kedaluwarsa atau jenis promosi salah",
    "invoiceBuyerPhoneNo": "Nomor Telepon di Faktur",
    "invoiceType": "Jenis Faktur",
    "invoiceAddress": "Alamat Faktur",
    "invoiceBuyerEmail": "Email Pembeli di Faktur",
    "invoiceBuyerName": "Nama Pembeli di Faktur",
    "upcoming": "Akan Datang",
    "finish": "Selesai",
    "promoTime": "Waktu Promosi",
    "promotionid": "ID Promosi",
    "webstore": "WebStore",
    "orderShasBeenMovedToReturned": "Pesanan[%s] telah dipindahkan ke Dikembalikan",
    "orderShasBeenMovedToDelivered": "Pesanan[%s] telah dipindahkan ke Sampai",
    "orderShasBeenMovedToShipping": "Pesanan [%s] telah dipindahkan ke Sedang Dikirim",
    "orderShasBeenMovedToTo": "Pesanan[%s] telah dipindahkan ke Menunggu Dipickup",
    "orderSisMarkedAsNotPacked": "Pesanan [%s] ditandai sebagai \"Belum Dikemas\"",
    "orderSisMarkedAsPacked": "Pesanan [%s] ditandai sebagai \"Telah Dikemas\"",
    "ordersmarkedPickingStatus": "Pesanan[%s] menandai status pengambilan",
    "orderSisMarkedAsNotA": "Pesanan[%s] ditandai sebagai \"Bukan Pesanan Palsu\"",
    "orderSisMarkedAsFakeOrder": "Pesanan[%s] ditandai sebagai \"Pesanan Palsu\"",
    "orderSHasBeenOperatedDelivery": "Pesanan[%s] telah dioperasikan [Gudang Pengiriman]",
    "orderSmarkedAsPickingListNot": "Pesanan[%s] ditandai sebagai Picking List Belum Dicetak",
    "orderSmarkedAsPickingListPrinted": "Pesanan[%s] ditandai sebagai Picking List Telah Dicetak",
    "orderSmarkedAsPackingListNot": "Pesanan[%s] ditandai sebagai Daftar Kemas Belum Dicetak",
    "orderSmarkedAsPackingListPrinted": "Pesanan[%s] ditandai sebagai Daftar Kemas Telah Dicetak",
    "orderSmarkedAsInvoiceNotPrinted": "Pesanan[%s] ditandai sebagai Invoice Belum Dicetak",
    "orderSmarkedAsInvoicePrinted": "Pesanan[%s] ditandai sebagai Invoice Telah Dicetak",
    "orderSmarkedAsLabelNotPrinted": "Pesanan[%s] ditandai sebagai Label Belum Dicetak",
    "orderSmarkedAsLabelPrinted": "Pesanan[%s] ditandai sebagai Label Telah Dicetak",
    "pleaseConfirmTheInstallationOfGinee": "Harap konfirmasi pemasangan Ginee di halaman Akurat, dan segarkan halaman setelah pemasangan",
    "allActivities": "Semua Promosi",
    "eventStartTimeAscending": "Waktu Promosi Mulai Tercepat",
    "eventStartTimeDescending": "Waktu Promosi Mulai Terlama",
    "eventEndTimeAscending": "Waktu Promosi Berakhir Tercepat",
    "eventEndTimeDescending": "Waktu Promosi Berakhir Terlama",
    "sortEventStartTime": "Urutkan Tanggal Mulai  Promosi",
    "thisOperationOnlyMarksWhetherThe": "Operasi ini hanya menandai apakah orderan ini pesanan palsu atau bukan, dan tidak akan mempengaruhi daftar outbound dan pengiriman ke gudang",
    "IsTheHighestPriority": "1 adalah prioritas tertinggi, 20 adalah prioritas terendah",
    "optional": "Opsional",
    "mandatory": "Wajib",
    "shippingInsurance": "Asuransi Pengiriman",
    "xModifiedWarehouseStockOutboundOrder": "{x} mengubah stok gudang, daftar outbound selesai, catatan:",
    "xModifiedWarehouseStockCreatedOutbound": "{x} mengubah stok gudang, membuat daftar outbound, catatan:",
    "xManuallyReplacesTheReservedWarehouse": "{x} secara manual mengubah gudang yang direserve, dari gudang {y} ke gudang {z}",
    "pleaseModifyTheReservedPromotionStock": "Harap ubah jumlah stok promosi yang direserve dengan hati-hati! \n- Mengurangi stok yang direserve akan menambah stok tersedia, dan stok promosi mungkin diambil dari stok channel lain selama ada penjualan multi-chan\nnel yang akan menyebabkan oversell\n- Mengubah stok reserve di Ginee hanya akan mengubah jumlah stok promosi di Ginee dan tidak akan memengaruhi stok reserve di Seller Center",
    "commissionFeeMapping": "Pemetaan Biaya Komisi",
    "priorityDelivery": "Pengiriman Prioritas",
    "flashSale": "Flash Sale",
    "productPromotion": "Promosi Produk",
    "eventStartTime": "Waktu Promosi Mulai",
    "eventEndTime": "Waktu Promosi Berakhir",
    "notPrintable": "Tidak Dapat Dicetak",
    "theFaceSheetCanBePrinted": "Label Pengiriman dapat dicetak",
    "thTrackingNumberNotBeen": "Nomor resi belum tergenerate",
    "theOrderStatusCannotPrintThe": "Status pesanan tidak mendukung pencetakan Label Pengiriman",
    "platformFulfillmentOrdersDoNotSupport": "Pesanan platform fulfillment tidak mendukung pencetakan Label Pengiriman",
    "thisChannelDoesNotSupportPrinting": "Channel ini tidak mendukung pencetakan Label Pengiriman",
    "theChannelHasNotReturnedThe": "Channel belum mengembalikan informasi AWB, harap menunggu dengan sabar. Jika AWB masih belum tersinkron setelah waktu yang lama, harap kunjungi Seller Center untuk mengonfirmasi apakah AWB sudah tampil, lalu sinkronkan pesanan dan coba lagi.",
    "theChannelShippingLabelHasNot": "Label pengiriman Channel belum tergenerate, harap coba lagi setelah menyinkronkan pesanan",
    "syncAll": "Sinkronisasi Semua",
    "noShippingLabelToBePrint": "Tidak ada label pengiriman untuk dicetak",
    "promotionEnd": "Telah Berakhir",
    "handleProblem": "Tangani Masalah",
    "stockMonitoringAndSynchronizationSettingsAre": "Monitor dan Sinkronisasi Stok Tidak Diaktifkan",
    "thereAreUpToFixed": "Ada hingga 5 produk dinaikkan konstan",
    "afterTurningOffTheAutomaticallyReserve": "Setelah mematikan Reservasi Stok Promosi Otomatis, stok promosi Shopee tidak akan tersinkron dan tidak akan direserve",
    "turnOffTheSwitchToAutomatically": "Matikan tombol menjadi Reservasi Stok Promosi Otomatis",
    "setTheReservationPolicyToUpcoming": "Atur Strategi Reservasi menjadi \"Otomatis Mereserve Promosi yang Akan Datang dan yang Sedang Berjalan\"",
    "setTheReservationPolicyToAutoreserve": "Atur Strategi Reservasi menjadi \"Otomatis mereservasi hanya untuk promosi yang Sedang Berjalan\"",
    "turnOnTheSwitchToAutomatically": "Nyalakan tombol menjadi Reservasi Stok Promosi Otomatis",
    "processingPromotionOnlySupportModifyThe": "Promosi yang Sedang Berjalan hanya mendukung perubahan gudang, tidak mendukung perubahan stok yang direserve",
    "displayingWarehouseWhereTheMskuOf": "Menampilkan gudang dimana MSKU produk dalam promosi dialokasikan, dan gudang tersebut dapat diubah.",
    "displayingTheBoundMskuOfThe": "Menampilkan MSKU yang terikat dengan produk dalam promosi dan jumlah stok promosi yang direserve di Ginee",
    "displayTheChannelSkuOfThe": "Menampilkan SKU channel dari produk dalam promosi dan jumlah stok promosi yang telah berhasil didaftarkan pada promosi platform",
    "productParticipatedInPromotionButIs": "Produk berpartisipasi dalam promosi, tapi tidak mereserve stok promosi. Promosi yang Telah Berakhir hanya akan disimpan selama 30 hari, setelah 30 hari data promosi tidak dapat dilihat di daftar ini",
    "productParticipatedInPromotionAndOnly": "Produk berpartisipasi dalam promosi, dan hanya beberapa variasi yang mereserve stok promosi",
    "productsIncludingAllVariationsAreParticipating": "Produk (termasuk semua variasi) berpartisipasi dalam promosi, stok promosi telah direserve",
    "thisPageCanOnlyViewAnd": "Halaman ini hanya dapat melihat dan mengelola promosi yang dibuat setelah 9 Juni 2023",
    "channelReturnFailedReasonX": "Return Channel Gagal, Alasan: {x}",
    "storeWillExp": "Toko {m} Akan Kedaluwarsa",
    "storeHasExp": "Toko {m} Telah Kedaluwarsa",
    "big": "Besar",
    "small": "Kecil",
    "standa": "Standar",
    "onlySupportsAdjustingSkuAndQuantity": "Hanya mendukung penyesuaian ukuran font untuk SKU dan jumlah barang",
    "fontSiz": "Ukuran Huruf:",
    "paperSize": "Ukuran Kertas",
    "disableHighspeedPrintingForSshop": "Nonaktifkan cetak cepat untuk toko [%s]",
    "enableHighspeedPrintingForSstore": "Aktifkan cetak cepat untuk toko [%s]",
    "disableTheHighSpeedPrintingButton": "Nonaktifkan tombol [Cetak Cepat]",
    "enableTheHighSpeedPrintingSwitch": "Aktifkan tombol [Cetak Cepat]",
    "afterDisablingAllStoresWillNot": "Setelah dinonaktifkan, semua toko tidak akan dapat menggunakan fitur cetak cepat, dan waktu untuk mendapatkan label pengiriman channel saat pencetakan massal akan bertambah",
    "areYouSureToTurnOff": "Apakah Anda yakin untuk mematikan fitur cetak cepat?",
    "shopeeWillMarkThePredownloadedOrder": "Shopee/Lazada/Tiktok akan menandai pesanan yang telah diunduh sebelumnya sebagai pesanan yang Telah Dicetak. Silahkan aktifkan/nonaktifkan tombol ini sesuai kebutuhan.",
    "afterEnablingGineeWillDownloadThe": "Setelah diaktifkan, Ginee akan mengunduh label pengiriman channel terlebih dahulu sehingga cetak cepat dapat dilakukan, dan dapat  mengurangi banyaknya waktu tunggu saat cetak massal",
    "syncToObtainLatestLogisticsInformation": "Sinkronisasi untuk mendapatkan informasi logistik terbaru",
    "massSyncToObtainTheLatest": "Sinkronisasi massal untuk mendapatkan informasi logistik terbaru untuk pesanan Shopee dan Tokopedia",
    "syncLogistics": "Sinkronisasi Logistik",
    "package": "Paket",
    "storeAuthorizedtotalAmount": "Toko (Terintegrasi/Jumlah Total)",
    "subaccountUsedtotalAmount": "Sub-akun (Telah Digunakan/Jumlah Total)",
    "remainingOrderQuantity": "Jumlah Order Tersisa",
    "remainingMDays": "Tersisa {m} Hari",
    "packageRemainingMDays": "Paket Tersisa {m} Hari",
    "toEnsureNormalUsePleaseRenew": "Setelah paket habis, tombol sinkronisasi stok akan mati secara otomatis. Untuk memastikan penggunaan normal, mohon perbarui paket tepat waktu",
    "packageRemainingValidityMDays": "Masa Berlaku Paket Tersisa {m} Hari",
    "noReminderToday": "Tidak ada pengingat hari ini",
    "theOperationIsTooFrequentPlease": "Operasi terlalu sering, silakan coba lagi dalam 5 menit",
    "yourCurrentPackageDoesNotSupport": "Paket Anda saat ini tidak mendukung otorisasi saluran ini, harap perbarui paket OMS sebelum melakukan otorisasi",
    "thisMethodIsOnlyOpenTo": "Metode ini hanya terbuka untuk pedagang yang ditunjuk. Harap atur dengan hati-hati. Untuk informasi lebih lanjut, lihat {m}",
    "shopeeOfficialInstructions": "Instruksi resmi Shopee",
    "actualShippingFee": "Biaya Pengiriman Sebenarnya",
    "ifAutomaticallyReserveShopeePromotionStock": "Jika [Otomatis Mereserve Stok Promosi Shopee] diaktifkan, pesanan promosi Shopee tidak akan dialokasikan sesuai dengan aturan Gudang Pengiriman, tetapi akan dikunci sesuai dengan gudang yang direserve untuk promosi",
    "afterEnablingGineeWillAutomaticallyPull": "Setelah diaktifkan, Ginee akan menarik Kode Booking pesanan Tokopedia secara otomatis",
    "turnOffTheAutomaticallyPullLogistics": "Nonaktifkan tombol [Tarik Informasi Logistik Secara Otomatis]",
    "turnOnTheAutomaticallyPullLogistics": "Aktifkan tombol [Tarik Informasi Logistik Secara Otomatis]",
    "automaticallyPullLogisticsInformation": "Tarik Informasi Logistik Secara Otomatis",
    "disabled1": "Nonaktif",
    "enabled": "Diaktifkan",
    "newlyAuthorizedStoresAreAutomaticallySet": "Toko yang baru diotorisasi secara otomatis disetel ke \"Aktif\"",
    "confirmToReauthorize": "Konfirmasi untuk mengotorisasi ulang",
    "noteTiktokReauthorizationDoesNotExtend": "Catatan: Otorisasi ulang TikTok tidak memperpanjang masa berlaku. Untuk memperpanjang masa berlaku otorisasi, batalkan integrasi Ginee di TikTok Seller Center terlebih dahulu, lalu otorisasi ulang",
    "sellerFullAddress": "Alamat lengkap penjual",
    "thereIsNoOrderThatCan": "Tidak ada pesanan yang dapat dicetak menggunakan cetak cepat, harap konfirmasi bahwa toko dimana pesanan berada telah mengaktifkan [Cetak Cepat]",
    "startSynchronizingLatestOrderLogisticsInformation": "Mulai menyinkronkan informasi logistik pesanan terbaru, diperkirakan akan membutuhkan beberapa menit, refresh halaman untuk melihat informasi logistik terbaru",
    "video": "Video",
    "tryHighlightingCoreSellingPoints": "Tonjolkan 1-2 keunggulan utama produk Anda. Ini akan ditampilkan di bagian detail produk.",
    "weRecommendTheVideoDurationIs": "Kami merekomendasikan sebaiknya durasi video kurang dari 20 detik, dan durasi terpanjang tidak boleh lebih dari 60 detik.",
    "recommendedRatioIsAndRecommended": "Rasio yang disarankan adalah 1:1 dan resolusi yang disarankan adalah di atas HD 720p.",
    "ifYouUploadAVideoIt": "Aspek rasio video yang Anda unggah harus 9:16 atau 16:9. Ukuran file tidak boleh melebihi 30MB dan formatnya adalah MP4.",
    "tiktokRequired": "Persyaratan TikTok:",
    "changingCategoryWillNotAffectYour": "Mengubah kategori tidak akan memengaruhi informasi produk Anda seperti kinerja penjualan dan peringkat produk, namun akan menghapus atribut produk. Apakah Anda yakin ingin mengubahnya?",
    "basedOnTheTitleYouEntered": "Berdasarkan Nama Produk yang Anda masukkan, rekomendasi kategori produknya adalah (silakan pilih sesuai kebutuhan)",
    "economical": "Ekonomi",
    "cargo": "Kargo",
    "standardShipping": "Pengiriman Standar",
    "pleaseInputTheValueOfWeight": "Berat harus lebih dari 5.00kg",
    "deliveryOptions": "Opsi Pengiriman",
    "lineShoppingOnlySupportsSettingThe": "Line Shopping hanya mendukung pengaturan jumlah diskon instan yang sama untuk semua variasi",
    "compulsoryOutbound": "Daftar outbound telah dipush ke gudang fulfillment, harap lakukan outbound di gudang fulfillment. Jika Anda meneruskan operasi ini, hanya daftar outbound di OMS yang akan dipaksa keluar, yang dapat menyebabkan ketidakkonsistenan dengan data outbound sebenarnya dari gudang fulfillment. Apakah anda yakin ingin melanjutkan?",
    "compulsoryCancel": "Daftar outbound telah dipush ke gudang fulfillment, harap lakukan outbound di gudang fulfillment. Jika Anda meneruskan operasi ini, Anda hanya akan secara paksa membatalkan daftar outbound di OMS, yang dapat menyebabkan ketidakkonsistenan dengan data outbound sebenarnya dari gudang fulfillment. Apakah anda yakin ingin melanjutkan?",
    "noPermission": "Anda tidak memiliki izin untuk mengakses halaman ini, harap resfresh halaman dan coba lagi setelah upgrade paket Anda. Untuk akun staf, harap hubungi akun utama untuk menambahkan izin peran",
    "refuseToAddWms": "Anda tidak memiliki izin untuk menambahkan gudang ini, silakan hubungi Penyedia Layanan Gudang Anda untuk menambahkan Akun Consignor untuk Anda dan mengotorisasi gudang",
    "theProductDescriptionShouldGiveThe": "Deskripsi produk harus memberikan informasi yang berguna kepada pelanggan tentang produk untuk memastikan pembelian",
    "missingOrderInformation": "Informasi Pesanan Tidak Ada",
    "compartmentDimensionInShippingWarehouseSetting": "Tampilan Pembagian Gudang pada pengaturan Gudang Pengiriman diubah dari {1} menjadi {2}",
    "priorityOfWarehouseAlocationInShipping": "Prioritas alokasi gudang pada pengaturan Gudang Pengiriman diubah dari {1} menjadi {2}",
    "warehouseInRuleChangedFrom": "Gudang dalam aturan {0} diubah dari {1} menjadi {2}",
    "startPullingTheOrderDataIt": "Diperkirakan perlu beberapa menit untuk menarik data pesanan. Dimohon untuk memperhatikan kondisi sinkronisasi data toko nanti.",
    "theRecipientsInformationCanBeDisplayed": "Informasi penerima dapat ditampilkan dalam bentuk teks pada label pengiriman , tetapi informasi penerima mungkin akan tersembunyikan.",
    "theCompleteRecipientInformationCanBe": "Informasi lengkap data penerima dapat ditampilkan dalam bentuk gambar di label pengiriman , tetapi beberapa printer mungkin tidak dapat mengenali gambar pengiriman tersebut.",
    "customShipmentWarning": "Peringatan Pengiriman Kustom",
    "thisSystemWarehouseHasBeenBound": "Gudang Sistem ini telah terikat dengan Gudang Saluran lainnya!",
    "completeRecipientInformationCanBe": "Informasi lengkap data penerima dapat ditampilkan dalam bentuk gambar di label pengiriman",
    "recipientsInformationCanBeDisplayed": "Informasi penerima dapat ditampilkan dalam bentuk teks pada label pengiriman",
    "instantDiscountAmount": "jumlah diskon instant",
    "originalPriceShouldBeHigherThan": "Harga asal seharusnya lebih tinggi dari jumlah diskon instan",
    "YourOmsWillBeUpgraded": "1. OMS Anda akan ditingkatkan ke versi berfitur lengkap, yang dapat mengotorisasi toko dari multi-channel, memproses pesanan, dan mengelola stok;",
    "YouWillAutomaticallyBindThe": "2. Anda akan secara otomatis mengikat gudang penyedia layanan, dan Anda dapat push pesanan ke gudang penyedia layanan, dan penyedia layanan akan melakukan pemenuhan pesanan;",
    "theFulfillmentServiceProviderXInvites": "Penyedia layanan pemenuhan {x} mengundang Anda untuk membuka gudang pemenuhan {y}",
    "afterAgreeing": "Setelah menyetujui:",
    "areYouSureYouAgreeTo": "Apakah Anda yakin setuju untuk aktivasi?",
    "confirmActivation": "Konfirmasi Aktivasi",
    "youHaveNewApplication": "Anda Memiliki {m} Aplikasi Baru",
    "thisChannelCanBeAuthorizedAfter": "Saluran ini dapat diotorisasi setelah pembaruan paket",
    "theCurrentPackageDoesNotSupport": "Paket saat ini tidak mendukung otorisasi channel ini",
    "enableFailedYouCanOnlyAuthorize": "Pengaktifan gagal! Penyimpanan melebihi batas sumber daya paket",
    "afterUpgradingThePackageMoreMaster": "Setelah pembaruan paket, lebih banyak Master Produk dapat ditambahkan",
    "addFailedMasterProducts": "Gagal menambahkan! Jumlah Master Produk telah mencapai batas paket",
    "masterProductQuantityHasReachedThe": "Jumlah Master Produk telah mencapai batas paket",
    "additionalXConsignorAccountsbusiness": "Tambahan x 5 Akun Consignor",
    "additionalXGineeWmsOrders": "Tambahan x 10,000 Pesanan Ginee WMS",
    "additionalXStores": "Tambahan x 10 Toko",
    "additionalXStaffAccounts": "Tambahan x 5 Akun Staf",
    "additionalXGineeOmsOrders": "Tambahan x 10,000 Pesanan Ginee OMS",
    "addons": "AddOns",
    "offlineOnboardingOn": "Offline Onboarding (1 on 1)",
    "accountManagerOn": "Account Manager (1 on 1)",
    "onlineTraining": "Online Training",
    "liveChatSupport": "Dukungan Live Chat",
    "implementationAndCustomerCare": "Implementasi dan Layanan Pelanggan",
    "XConsignorAccountsBusiness": "10 x Akun Consignor (Bisnis)",
    "servicesToExternalBrands": "Layanan untuk Brand Eksternal",
    "serviceProviderManagement": "Manajemen Penyedia Layanan",
    "gineeWmsOpenApi": "Ginee WMS Open API",
    "pdaapp": "PDA/APP",
    "fifofefo": "FIFO/FEFO",
    "smartReplenishment": "Smart Restock",
    "stocklocationTransfer": "Transfer Stok/Lokasi",
    "waveShipping": "Manajemen Wave",
    "gineeWmsWarehouseManagementSystem": "Ginee OMS (Order Management System)",
    "gineeOmsOpenApi": "Ginee OMS Open API",
    "theThirdPartyIntegrationposLogisticsFulfillment": "Integrasi Pihak Ketiga (POS, Logistik, Fulfillment, OMS, Akunting)",
    "multiwarehouseManagement": "Manajemen Multi-Gudang",
    "reporting": "Laporan",
    "stockManagement": "Manajemen Stok",
    "gineeOmsOrderManagementSystem": "Ginee OMS (Order Management System)",
    "noOfSalesChannels": "Jumlah Channel Penjualan",
    "noOfMskus": "Jumlah MSKU",
    "noOfStores": "Jumlah Toko",
    "noOfStaffAccounts": "Jumlah Akun Staf",
    "noOfCountriesinternational": "Jumlah Negara(Internasional)",
    "noOfGineeWmsOrders": "Jumlah Pesanan Ginee WMS",
    "noOfGineeOmsOrders": "Jumlah Pesanan Ginee OMS",
    "ecommerceOperations": "Operasi Ecommerce",
    "buyItNow": "Beli Sekarang",
    "clickMeToGetDiscount": "Dapatkan Diskon",
    "customization": "Kustomisasi",
    "youHaveMNewMessages": "Anda mendapat {m} pesan baru",
    "officialAccounts": "Akun resmi",
    "theThirdPartyIntegration": "Integrasi Pihak Ketiga",
    "thereAreUnrecognizableCharactersSuchAs": "Ada karakter yang tidak dapat dikenali, silakan diubah",
    "currentlyDoNotSupportIntegratingCnsc": "Saat ini tidak mendukung integrasi toko CNSC atau 3PF",
    "manuallyRepushThePushFailedData": "Dorong ulang data \"push gagal\" secara manual",
    "paymentTerms": "Jangka Waktu Pembayaran",
    "bankAccount": "Akun Bank",
    "issuingBank": "Bank Penerbit",
    "taxIdNumber": "NPWP",
    "contactPerson": "Contact Person",
    "website": "Website",
    "onlyLettersAndNumbersAreSupported": "Hanya huruf, angka dan '- _ . /' didukung, silakan masuk kembali",
    "theContentYouEnteredContainsIllegal": "Konten yang Anda masukkan memiliki karakter ilegal, harap masukkan kembali",
    "autoGenerate": "Buat Otomatis",
    "supplierName": "Nama Supplier",
    "supplierCode": "Kode Supplier",
    "createNewSupplier": "Tambah Supplier Baru",
    "importSupplier": "Impor Supplier",
    "supplierList": "Daftar Supplier",
    "purchaseManagement": "Manajemen Pembelian",
    "supplierManagement": "Manajemen Supplier",
    "purchase": "Pembelian",
    "disableenable": "Nonaktif/Aktif",
    "supplierCodeDoesNotExist": "Kode Supplier tidak ada",
    "supplierNameAlreadyExists": "Nama Supplier sudah ada",
    "supplierCodeAlreadyExists": "Kode Supplier sudah ada",
    "failedToDeleteTheSupplierAlready": "Penghapusan gagal! Supplier telah memiliki Pesanan Pembelian atau tidak dalam status Nonaktif",
    "onlySuppliersWithDisabledStatusAnd": "Hanya Supplier dengan status Nonaktif dan tidak ada Pesanan Pembelian yang dapat dihapus",
    "wantToDeleteTheSupplier": "Apakah Anda yakin ingin menghapus Supplier?",
    "wantToEnableTheSupplier": "Apakah Anda yakin ingin mengaktifkan Supplier?",
    "failedToDisableThisSupplierHas": "Gagal menonaktifkan! Supplier ini memiliki Pesanan Pembelian yang belum selesai",
    "suppliersWithUnfinishedPurchaseOrdersCannot": "Supplier dengan Pesanan Pembelian yang belum selesai tidak dapat dinonaktifkan, harap operasikan dengan hati-hati",
    "wantToDisableTheSupplier": "Apakah Anda yakin ingin menonaktifkan Supplier?",
    "thisTypeHasBeenSelectedBy": "Jenis Supplier ini telah dipilih untuk Supplier sehingga tidak dapat dihapus",
    "wantToDeleteTheSupplierType": "Apakah Anda yakin ingin menghapus Jenis Supplier ini?",
    "typeName": "Nama Jenis",
    "addNewType": "Tambah Jenis Baru",
    "fullPayment": "Pembayaran Lunas",
    "supplierCodeCannotBeEditedAfter": "Kode Supplier tidak dapat diubah setelah dibuat",
    "xAlreadyExists": "{x} sudah ada",
    "pleaseFillInThisField": "Harap isi kolom ini",
    "theAmountMustBeBetweenXy": "Jumlahnya harus antara {x}~{y}, 2 tempat desimal diperbolehkan",
    "amountMustBeBetweenXyAnd": "Jumlahnya harus antara {x}~{y}, dan desimal tidak diperbolehkan.",
    "partialArrival": "Sampai Sebagian",
    "inTransit": "Dalam Perjalanan",
    "paymentStatus": "Status Pembayaran",
    "purchaseOrder": "Pesanan Pembelian",
    "paymentMethod": "Metode Pembayaran",
    "paid": "Lunas",
    "partialPayment": "Pembayaran Sebagian",
    "unpaid": "Belum Dibayar",
    "feeInformation": "Informasi Biaya",
    "deliveredQuantity": "Jumlah yang Dikirim",
    "estimatedDateOfArrival": "Perkiraan Tanggal Sampai",
    "purchasingStaff": "Staf Pembelian",
    "receivingWarehouse": "Gudang Penerima",
    "puchaseDate": "Tanggal Pembelian",
    "purchaseOrderSource": "Asal Pesanan Pembelian",
    "noSupplier": "Tidak Ada Supplier",
    "purchaseOrderNumberAlreadyExists": "Nomor Pesanan Pembelian sudah ada",
    "purchaseOrderNumberCannotBeEdited": "Nomor Pesanan Pembelian tidak dapat diubah setelah dibuat",
    "pleaseEnterPurchaseOrderNumber": "Harapan masukkan Nomor Pesanan Pembelian",
    "purchaseOrderNumber": "Nomor Pesanan Pembelian",
    "basicInfoma": "Informasi Dasar",
    "otherNoncash": "Non Tunai Lainnya",
    "virtualAccount": "Virtual Account",
    "paymentLink": "Link Pembayaran",
    "qris": "QRIS",
    "edc": "EDC",
    "bankTransfer": "Transfer Bank",
    "chequeclearing": "Cek",
    "cash": "Uang Tunai",
    "discou": "Diskon",
    "productPurchasePrice": "Harga Beli Produk",
    "purchaseOrderTotalPrice": "Total Harga Pesanan Pembelian",
    "skuQuantity": "Jumlah SKU",
    "totalPriceInformation": "Informasi Total Harga",
    "inboundCostPrice": "Modal Inbound",
    "individualCostSharing": "Pembagian Biaya Individu",
    "taxRate": "Pajak",
    "onlyNumbersFromTo": "Hanya angka dari {x}~{y} yang boleh dimasukkan, tidak mendukung desimal",
    "discountRate": "Presentase Diskon",
    "purcPrice": "Harga Beli",
    "quantityPcs": "Jumlah (pcs)",
    "unit": "Satuan",
    "volumeM": "Volume (cm³)",
    "productPriceIncludesTax": "Harga produk sudah termasuk pajak",
    "massAddd": "Tambah Massal",
    "addProdu": "Tambah Produk",
    "dividedByQuantity": "Dibagi Berdasarkan Jumlah",
    "dividedByVolume": "Dibagi Berdasarkan Volume",
    "dividedByWeight": "Dibagi Berdasarkan Berat",
    "dividedByPrice": "Dibagi Berdasarkan Harga",
    "notDivided": "Tidak Dibagi",
    "fillInmodifyTrackingNumber": "Isi/ubah Nomor Resi",
    "purchaseNote": "Catatan Pembelian",
    "thePurchaseOrderWarehouseIsWmsthirdparty": "Gudang Pesanan Pembelian merupakan Gudang WMS/Pihak Ketiga. Harap hubungi gudang Fulfillment untuk membatalkan Pesanan Pembelian sebelum melanjutkan",
    "failedToCancelThePurchaseOrder": "Pembatalan Pesanan Pembelian Gagal",
    "cancellationFailedInboundProductsInThis": "Pembatalan gagal! Inbound Produk dalam Pesanan Pembelian ini telah selesai. Silakan segarkan halaman untuk memeriksa kembali status Pesanan Pembelian",
    "whenAPurchaseOrderIsCancelled": "Ketika Pesanan Pembelian dibatalkan, Inbound pembelian akan menjadi invalid dan Stok Transit akan dirilis",
    "wantToCancelThePurchaseOrder": "Apakah Anda yakin ingin membatalkan pesanan pembelian?",
    "wantToDeleteThePurchaseOrder": "Apakah Anda yakin ingin menghapus pesanan pembelian?",
    "IfTheReceivingWarehouseIs": "2. Jika gudang penerima adalah Gudang Pihak Ketiga, Ginee akan secara otomatis mengirimkan Inbound pembelian ke gudang fulfillment",
    "AfterSubmissionThePurchaseOrder": "1. Setelah diajukan, Pesanan Pembelian akan berstatus In Transit dan Inbound pembelian akan dihasilkan dan produk akan ditambahkan ke Stok Transit",
    "youWantToSubmitThePurchase": "Apakah Anda yakin ingin mengajukan Pesanan Pembelian?",
    "identify1": "Analisa",
    "copyMskuFromExcelAndPaste": "Salin MSKU dari Excel dan tempel di sini. Harap gunakan enter untuk memasukkan beberapa MSKU. Setiap baris mewakili satu informasi.",
    "EstimatedTimeOfArrivalFormat": "6. Format Perkiraan Waktu Sampai: Tahun/Bulan/Tanggal (Misalnya: 23/08/2023)",
    "WhenTheReceivingWarehouseIs": "3. Apabila gudang penerima merupakan Gudang Pihak Ketiga, apabila MSKU belum di-push ke Gudang Pihak Ketiga, harap masuk ke halaman Daftar Stok untuk menambahkan SKU ke Gudang Pihak Ketiga;",
    "ExplanationOfTheShippingFeeother": "4. Penjelasan metode alokasi Biaya Pengiriman/biaya lainnya:\n- Saat memilih \"Harga\", alokasi akan berdasarkan harga masing-masing SKU\n- Saat memilih \"Kuantitas\", alokasi akan berdasarkan jumlah pembelian masing-masing SKU\n- Saat memilih \"Volume\", alokasi akan berdasarkan volume masing-masing SKU\n- Saat memilih \"Berat\", alokasi akan berdasarkan berat masing-masing SKU.\nJika Ongkos Kirim/biaya lainnya tidak diisi, maka secara default tidak akan dialokasikan;",
    "purchaseOrderContainsMultipleProductsYou": "3. Apabila pesanan pembelian berisi beberapa produk, Anda perlu mengisi informasi produk secara lengkap untuk setiap datanya. Untuk informasi publik (informasi dasar, informasi biaya, informasi lainnya), hanya data pertama yang perlu diisi. Apabila informasi publik dari beberapa data tidak konsisten, data pertama yang akan digunakan;",
    "WhenAPurchaseOrderContains": "2. Apabila Pesanan Pembelian berisi beberapa produk, harap gunakan Nomor Pesanan Pembelian yang sama. Saat mengimpor, beberapa baris data dengan Nomor Pesanan Pembelian yang sama akan digabungkan menjadi satu Pesanan Pembelian;",
    "exportPurchaseOrder": "Impor Pesanan Pembelian",
    "printPurchaseOrder": "Cetak Pesanan Pembelian",
    "changePaymentStatus": "Ubah Status Pembayaran",
    "signature": "Tanda Tangan",
    "merchantWarehouseAddress": "Alamat Gudang Penjual",
    "contactInformation": "Kontak",
    "supplierAddress": "Alamat Supplier",
    "supplier": "Supplier",
    "supplierInformation": "Informasi Supplier",
    "merchantInformation": "Infomarsi Penjual",
    "explanation": "Penjelasan:",
    "UpToDataCan": "1. Dapat mengimpor hingga  10.000 data dalam sekali impor (format: xlsx, xls);",
    "estimatedTimeOfArrival": "Perkiraan Waktu Sampai",
    "warehouseType": "Jenis Gudang",
    "theEntireInboundWillBeInvalidated": "Seluruh Inbound akan dibatalkan dan stok transit akan dirilis",
    "completeInbound": "Inbound Selesai",
    "partialInbound": "Inbound Sebagian",
    "afterInvalidationIsInitiatedOnlyThe": "Setelah invalidasi dimulai, hanya Inbound OMS yang akan diinvalid, dan status Inbound di gudang fulfillment tidak akan berubah",
    "onlyTheErpInboundIsInvalided": "Hanya Inbound OMS yang menjadi invalid",
    "afterTheInvalidationIsInitiatedThe": "Setelah invalidasi dimulai, pembatalan akan diinformasikan ke gudang fulfillment. Setelah invalidasi berhasil maka status OMS gudang fulfillment akan berubah menjadi invalid",
    "invalidErpAndFulfillmentInbound": "Invalid Inbound OMS dan fulfillment",
    "invalidationMethod": "Metode Invalidasi:",
    "theSkuWillBecameInvalidAnd": "SKU akan menjadi tidak valid dan Stok Transit akan dirilis",
    "pleaseSelectTheInvalidationMethod": "Harap pilih Metode Invalidasi",
    "inboundFullAmount": "Seluruh Jumlah Inbound",
    "presentInboundQuantity": "Jumlah Inbound Saat Ini",
    "pendingInboundinboundCompletedplannedInbound": "Menunggu Inbound/Inbound Selesai/Rencana Inbound",
    "afterTheInboundIsCompletedThe": "Setelah Inbound selesai, status Inbound akan berubah menjadi \"Inbound Selesai\" dan tidak dapat dilanjutkan",
    "sureYouWantToEndThe": "Apakah Anda yakin ingin mengakhiri Inbound?",
    "pushResult": "Hasil Pembaruan Toko",
    "receiveItem": "Penerimaan Barang",
    "afterDisablingThePrintedPurchaseOrder": "Setelah dinonaktifkan, pencetakan Pesanan Pembelian tidak akan menampilkan jumlah produk dan biaya (Biaya Pengiriman/biaya lainnya/Jumlah Total)",
    "amountDisplay": "Tampilan Jumlah",
    "purchaseOrderPrintingSettings": "Pengaturan Pencetakan Pesanan Pembelian",
    "purchaseSetting": "Pengaturan Pembelian",
    "receivingTime": "Waktu Penerimaan",
    "inboundTotalAmount": "Jumlah Total Inbound",
    "receiveItemNumber": "Nomor Penerimaan Barang",
    "supplierTypeAlreadyExists": "Jenis Supplier sudah ada",
    "supplierType": "Jenis Supplier",
    "supplierTypeDoesNotExist": "Jenis Supplier tidak ada",
    "theDataIsAbnormalAndThe": "Data abnormal, tugas export ini tidak ada",
    "xParameterIsIncorrect": "Parameter {x} salah",
    "xParameterCannotBeEmpty": "Parameter {x} tidak boleh kosong",
    "thePurchaseOrderAlreadyExists": "Pesanan Pembelian sudah ada",
    "purchaseOrderDoesNotExist": "Tidak ada Pesanan Pembelian ini",
    "failedTheWarehouseTypeWasNot": "Gagal, tipe gudang tidak diperoleh",
    "purchaseOrderCancellationFailed": "Pembatalan Pesanan Pembelian gaga",
    "purchaseOrderDeletionFailed": "Penghapusan Pesanan Pembelian gagal",
    "purchaseOrderSubmissionFailed": "Pengajuan Pesanan Pembelian gagal",
    "purchaseOrderIsMissingProductInformation": "Pesanan Pembelian tidak memiliki informasi produk",
    "purchaseOrderIsNotInDraft": "Pesanan Pembelian tidak dalam status \"Draf\".",
    "purchaseOrderCancellationFailedPleaseRefresh": "Pembatalan Pesanan Pembelian gagal. Harap segarkan halaman dan coba lagi",
    "purchaseOrderDeletionFailedPleaseRefresh": "Penghapusan Pesanan Pembelian gagal. Harap segarkan halaman dan coba lagi",
    "purchaseOrderSubmissionFailedPleaseRefresh": "Pengajuan Pesanan Pembelian gagal. Harap segarkan halaman dan coba lagi",
    "theSupplierDoesNotExists": "Supplier tidak ada",
    "supplierAlreadyExists": "Supplier sudah ada",
    "theImportedDataIsEmpty": "Data yang diimpor kosong",
    "theImportedFileFormatIsNot": "Format file yang diimpor tidak didukung",
    "theOperationTaskDoesNotExist": "Tugas operasi tidak ada",
    "theImportTaskDoesNotExist": "Tugas impor tidak ada",
    "theExportTaskDoesNotExist": "Tugas export tidak ada",
    "createAndPushSalesInvoicesWhen": "Buat dan push Faktur Penjualan ketika outbound selesai",
    "inboundAmount": "Jumlah Inbound",
    "goodsReceivedNoteNumber": "Nomor Penerimaan Barang",
    "quotationGeneratedSuccessfully": "Quotation berhasil dibuat!",
    "theChannelWarehouseIsNotAssociated": "Gudang Channel tidak terkait dengan Gudang Ginee",
    "multiWarehouseChannelProductsAreNot": "Produk Channel Multi Gudang tidak terkait dengan gudang",
    "doesNotComplyWithTheRules": "Tidak memenuhi aturan: Semua toko yang terhubung, tidak termasuk toko baru yang baru di otorisasi",
    "matchingDefaultPushRulesAreDisabled": "Aturan Push Default yang cocok dinonaktifkan",
    "matchingStorePushRulesAreDisabled": "Aturan Push Toko yang cocok dinonaktifkan",
    "matchingCustomPushRulesAreDisabled": "Aturan Push Kustom yang cocok dinonaktifkan",
    "shopifyProductInventoryIdDoesNot": "ID Inventaris Produk Shopify tidak ada, pembaruan stok tidak diizinkan",
    "blibliProductsAreDisabledAndStock": "Produk Blibli dinonaktifkan dan update stok tidak diizinkan",
    "returnFromTikiChannelForThis": "Informasi yang dikembalik dari Channel Tiki untuk produk ini: Pembaruan tidak diizinkan",
    "theStoreHasBeenDisconnected": "Toko telah terputus",
    "tikiProductInboundStatusDoesNot": "Status Inbound produk Tiki tidak mengizinkan push stock",
    "masterProductNotFound": "Master Produk tidak ditemukan",
    "thePushTaskAlreadyExistsAnd": "Tugas push sudah ada dan tidak perlu dipush lagi",
    "failedAlreadyRepushed": "Gagal, telah dipush ulang",
    "pleaseSelectTheReceivingWarehouseFirst": "Silakan pilih gudang penerima terlebih dahulu",
    "ifNotFilledInItWill": "Jika tidak diisi, maka akan dihasilkan secara otomatis",
    "region": "Wilayah",
    "cityNew": "Kota",
    "provinc": "Provinsi",
    "districtsNew": "Kecamatan",
    "purchaseQuantity": "Jumlah Pembelian",
    "otherCostAllocationMethodsPleaseSelect": "Metode Alokasi Biaya Lainnya (Harap pilih)",
    "otherCostTotal": "Biaya Lainnya (Total)",
    "shippingFeeAllocationMethodPleaseSelect": "Metode Alokasi Biaya Pengiriman (Harap pilih)",
    "shippingFeeTotal": "Biaya Pengiriman (Total)",
    "wantToUnbindTheWarehouse": "Apakah Anda yakin ingin melepas ikatan gudang?",
    "afterUnbindingChangesInStockIn": "Setelah ikatan dilepas, perubahan stok di Gudang Sistem tidak akan dipush ke Gudang Channel",
    "unbindTheChannelWarehouseAaaFrom": "Lepas ikatan Gudang Channel {AAA} dari Gudang Sistem",
    "thisFeatureIsOnlyAvailableFor": "Hanya berlaku untuk Tokopedia Official Store",
    "deleteSupplier": "Hapus Supplier",
    "enableSupplier": "Aktifkan Supplier",
    "disableSupplier": "Nonaktifkan Supplier",
    "editSupplier": "Ubah Supplier",
    "manualDeleteSupplierX": "Hapus Manual Supplier [{x}]",
    "enableSupplierX": "Aktifkan Supplier [{x}]",
    "disableSupplierX": "Nonaktifkan Supplier [{x}]",
    "importToUpdateSupplierX": "Import untuk Memperbarui Supplier [{x}]",
    "manualEditSupplierX": "Ubah Manual Supplier [{x}]",
    "importToAddSupplierX": "Impor untuk Menambahkan  Supplier [{x}]",
    "manualAddSupplierX": "Tambah Manual Supplier [{x}]",
    "exportSupplierList": "Export Daftar Supplier",
    "exportPurchaseOrderX": "Export Pesanan Pembelian [{x}]",
    "printPurchaseOrderX": "Cetak Pesanan Pembelian [{x}]",
    "cancelPurchaseOrderX": "Batalkan Pesanan Pembelian [{x}]",
    "deletePurchaseOrderX": "Hapus Pesanan Pembelian [{x}]",
    "submitPurchaseOrderX": "Ajukan Pesanan Pembelian [{x}]",
    "editPurchaseOrderXManually": "Ubah Manual Pesanan Pembelian [{x}]",
    "importPurchaseOrderX": "Impor untuk Menambahkan Pesanan Pembelian [{x}]",
    "addPurchaseOrderXManually": "Tambah Manual Pesanan Pembelian [{x}]",
    "exportPurchaseO": "Export Pesanan Pembelian",
    "cancelPurchaseOrder": "Batalkan Pesanan Pembelian",
    "deletePurchaseOrder": "Hapus Pesanan Pembelian",
    "submitPurchaseOrder": "Ajukan Pesanan Pembelian",
    "editPurchaseOrder": "Ubah Pesanan Pembelian",
    "addPurchaseOrder": "Tambah Pesanan Pembelian",
    "shopeeRequirements": "Persyaratan Shopee:",
    "formatMp": "Format: MP4",
    "durationSs": "Durasi: 10-60 Detik",
    "sizeMaxMbResolutionShouldNot": "Ukuran: Maks 30Mb dan resolusi tidak boleh melebihi 1280x1280px",
    "myShopeeSellerCenterCanInsert": "Hanya beberapa toko Shopee yang mendukung gambar dan deskripsi, jadi pastikan toko Anda mendukungnya sebelum mengaktifkan opsi ini.",
    "copyCopy": "Salin",
    "thankYouForYourCooperationPlease": "Terima kasih atas kerja sama Anda, mohon salin Unique ID dan kirim ke tim Support Ginee.",
    "weNeedYourConsentToRecord": "Kami memerlukan izin Anda untuk merekam layar dan merekam operasi untuk mengidentifikasi dan menyelesaikan masalah Anda.",
    "weOnlyRecordThePagesIn": "Kami hanya merekam halaman di sistem Ginee.",
    "needAuthorization": "Butuh Otorisasi",
    "approveStartToRecord": "Izinkan dan mulai merekam layar",
    "done": "Selesai",
    "repeatTheOperationStepsToReproduce": "Ulangi langkah operasi untuk mereproduksi masalah, hentikan rekaman setelah merekam seluruh operasi.",
    "capturingTheProcessingFlowOnThe": "Merekam operasi yang dilakukan di layar",
    "stop": "Hentikan",
    "uploading": "Sedang Mengunggah",
    "importToUpdateSupplier": "Import untuk Memperbarui Supplier",
    "importToAddSupplier": "Impor untuk Menambahkan  Supplier",
    "feedback": "Feedback",
    "uploadVideo": "Unggah Video",
    "wereNotPerfectButWereListening": "Ginee tidak sempurna, tapi kami siap mendengarkan",
    "feedbackForm": "Formulir Feedback",
    "pleaseSubmitATicektFormWith": "Mohon kirim formulir tiket dengan deskripsi teks dan gambar terlampir",
    "feedbackVideo": "Feedback Video",
    "pleaseGrantGineeThePermissionRecord": "- Mohon beri Ginee izin untuk merekam operasi Anda untuk mendiagnosis",
    "enterTheSpecificPageStartTo": "- Masuk ke halaman tertentu, mulai rekaman dan ulangi operasi Anda untuk mereproduksi masalah",
    "stopTheRecordCopyTheUnique": "- Hentikan rekaman, salin Unique ID dan kirim ke tim Support Ginee",
    "faqfeedback": "FAQ/Feedback",
    "needHelp": "Perlu Bantuan?",
    "detailedDescription": "Deskripsi Detail",
    "trackingNumber": "nomor pelacak",
    "enableLongDescription": "Aktifkan gambar dan deskripsi",
    "DetailProductDescription": "Deskripsi Produk",
    "dueToApiLimitationsBlibliMust": "Karena keterbatasan API, Blibli harus menggunakan pencetakan cepat untuk mengunduh label pengiriman terlebih dahulu. Silakan masuk ke Pengaturan Pesanan -> Cetak cepat untuk mengaktifkan pencetakan kecepatan tinggi di toko Blibli dan coba kembali.",
    "abnormal": "Abnormal",
    "theInformationCannotBeFoundIn": "Jika informasi tidak dapat ditemukan di Ginee, harap konfirmasikan apakah konten setiap kolom telah cocok, atau masuk ke sistem Accurate untuk mengubah informasi dan kemudian melakukan sinkronisasi kembali.",
    "purchasePurchaseOrder": "Pembelian / Pesanan Pembelian",
    "addToList": "Tambah ke Daftar",
    "modifyingAccuratePurchaseOrderInformationIn": "Mengubah informasi Pesanan Pembelian Accurate di Ginee hanya akan mengubah data di Ginee dan tidak akan disinkronkan ke Akurat; Memodifikasi Pesanan Pembelian di Accurate dengan status non-draft tidak akan disinkronkan ke Ginee.",
    "pleaseSelectProduct": "Silakan Pilih Produk",
    "gineeOnlySupportsCreatingPurchaseOrder": "Ginee hanya mendukung pembuatan Pesanan Pembelian untuk beberapa produk dari gudang yang sama. Harap masuk ke Accurate dan modifikasi semua produk ke gudang yang sama.",
    "supplierContactDetails": "Detail Kontak Supplier",
    "operationFailed": "Operasi gagal",
    "whenSelectingMultipleShopeeStoresThe": "Saat memilih beberapa toko Shopee, stok hanya dapat diperbarui ke gudang default; jika Anda perlu memperbarui stok ke beberapa gudang Shopee, mohon hanya pilih 1 toko",
    "requestTimedOutPleaseCheckThe": "Waktu permintaan habis, harap periksa jaringan dan coba lagi.",
    "manageMultiwarehouseStock": "Kelola Stok Multi-gudang",
    "supplierCodeSupplierCodeCannotBe": "Kode Supplier (Kode Supplier tidak dapat diedit setelah dibuat)",
    "hasReturnrefund": "Ada Return/Refund",
    "batchEditingOnlySupportsModifyingA": "Edit massal hanya mendukung pengeditan stok di satu gudang. Jika Anda ingin mengedit stok di beberapa gudang, mohon gunakan pengeditan produk tunggal",
    "invalidationIsInitiatedOnlyTheOms": "Setelah invalidasi dimulai, hanya Outbound OMS yang akan dibatalkan dan status Outbound di gudang fulfillment tidak akan berubah",
    "onlyTheOmsOutboundIsInvalided": "Hanya Outbound OMS yang diinvalid",
    "invalidateOmsAndFulfillmentOutbound": "Invalid Outbound OMS dan fulfillment",
    "installationTutorial": "Tutorial Instalasi",
    "howToUse": "Cara Penggunaan",
    "downloadAndInstallThePlugin": "Unduh dan instal plugin",
    "goToChromeWebStoreTo": "Buka Chrome Web Store untuk instalasi",
    "ForPluginsHowToUse": "4. Untuk Cara Penggunaan plugin, mohon lihat: ",
    "IfYouCannotAccessThe": "3. Jika Anda tidak dapat mengakses Chrome Web Store, unduh pluginnya di sini:",
    "PluginInstallationLink": "2. Tautan instalasi plugin:",
    "RecommendedBrowserGoogleChrome": "1. Browser yang disarankan: Google Chrome",
    "pleaseDownloadAndUseTheScrape": "Harap unduh dan gunakan plugin Scrape, stabil dan cepat!",
    "editAndPublishFromTheStore": "Edit dan publikasikan dari draft produk toko",
    "EditAndPublish": "4. Edit dan publikasikan",
    "selectAStoreAndCopyThe": "Pilih toko dan salin Master Produk ke draft produk toko",
    "CopyAndSellInStores": "3. Salin dan jual di toko",
    "massClaimAndCreateMasterProduct": "Klaim dan buat Master Produk secara massal",
    "ClaimTheMasterProduct": "2. Klaim Master Produk",
    "visitTheProductPageYouWant": "Kunjungi halaman produk yang ingin Anda scrape dan gunakan plugin untuk scrape dengan satu klik",
    "ScrapeProduct": "1. Scrape produk",
    "returnrefund": "Return/Refund",
    "editWarehouseStockInStockList": "Edit Stok Gudang di Daftar Stok",
    "importToUpdateStockIncreasedecrease": "Impor untuk Memperbarui Stok - Pertambahan/Pengurangan Nilai",
    "importToUpdateStockFinal": "Impor untuk Memperbarui Stok - Nilai Akhir",
    "purchaseOrdersSourcedFromAccurateDo": "Pesanan Pembelian yang bersumber dari Accurate tidak dapat dibatalkan dari Ginee. Jika Anda ingin membatalkan, silakan masuk ke Accurate untuk membatalkan.",
    "failedToCreateOmsInboundThe": "Gagal membuat Inbound List OMS : SKU di Inbound List WMS tidak ada di OMS atau merupakan produk kombinasi",
    "IfYouHaveDoneThe": "5. Jika sudah melakukan langkah-langkah di atas, silahkan hubungi customer service",
    "IfEverythingIsNormalPlease": "4. Jika semuanya normal, harap coba lagi setelah 1 menit",
    "PleaseSwitchTheNetworkAnd": "3. Silakan ganti jaringan dan coba lagi",
    "PleaseConfirmThatTheBrowser": "2. Mohon pastikan bahwa browser tidak memiliki pengaturan khusus atau coba lagi setelah mengganti browser",
    "PleaseCheckWhetherYouAre": "1. Harap periksa apakah Anda menggunakan plug-in pihak ketiga seperti pemblokiran iklan/domain, dll. Disarankan untuk menutup plug-in terlebih dahulu dan coba lagi",
    "inComplianceWithShopeePolicyBuyer": "Setelah integrasi toko, Ginee tidak secara aktif menyinkronkan riwayat pesanan. Jika Anda perlu melihat riwayat pesanan, klik tombol [Sinkron Pesanan] untuk menyinkronkan pesanan historis sesuai kebutuhan. Sesuai dengan persyaratan kebijakan Shopee, informasi pelanggan tidak akan ditampilkan lagi untuk riwayat pesanan yang melebihi 90 hari. Terima kasih atas pengertian Anda",
    "inComplianceWithShopeePolicyThe": "Sesuai dengan kebijakan Shopee, Daftar Pelanggan tidak akan menampilkan data pesanan Shopee.",
    "massAddHistory": "Riwayat Penambahan Massal",
    "cancellation": "Pembatalan",
    "failedDelivery": "Pengiriman Gagal",
    "ordersHasBeenMovedToReturnrefund": "Pesanan [%s] telah dipindahkan ke Return/Refund",
    "moveToReturnrefund": "Pindahkan ke Return/Refund",
    "ordersHasBeenMovedToCompleted": "Pesanan [%s] telah dipindahkan ke Selesai",
    "moveToCompleted": "Pindahkan ke Selesai",
    "youWantToMoveTheOrder": "Apakah Anda yakin untuk memindahkan pesanan ke Selesai?",
    "confirmToSync": "Yakin untuk menyinkronkan?",
    "purchaseSupplierManagement": "Manajemen Pembelian & Supplier",
    "afterClosingAllStoresWillNot": "Setelah dinonaktifkan, semua toko tidak akan disinkronkan dan stok promosi Shopee tidak akan direserve",
    "wantToDisableAutomaticallyReservePromotion": "Yakin ingin menonaktifkan Reservasi Stok Promosi Otomatis?",
    "pushingPleaseRefreshThePageLater": "Sedang dipush, harap segarkan halaman untuk melihat Status Push terbaru",
    "wantToMassDisableAutoPush": "Yakin ingin menonaktifkan Push Otomatis secara massal?",
    "wantToMassEnableAutoPush": "Yakin ingin mengaktifkan Push Otomatis secara massal?",
    "onlyGineeWmsWarehouseSupportsEditing": "Hanya gudang Ginee WMS yang mendukung perubahan Strategi Push",
    "editPushStrategy": "Ubah Strategi Push",
    "disableAutoPush": "Nonaktifkan Push Otomatis",
    "enableAutoPush": "Aktifkan Push Otomatis",
    "autoPush": "Push Otomatis",
    "pushStrategy": "Strategi Push",
    "theOrderWillBePushedTo": "Pesanan akan dipush ke gudang pihak ketiga lebih awal sesuai dengan ketentuan konfigurasi. Order lainnya akan tetap dipush sesuai dengan Push Normal. Pesanan Bermasalah tidak akan dipush",
    "regardlessOfWhetherTheOrderIs": "Terlepas dari apakah pesanan memenuhi syarat untuk pengiriman (tidak diatur pengiriman, tidak ada nomor resi, tidak ada label pengiriman), semua pesanan yang disinkronkan dengan status \"Lunas\" dan \"Siap Dikirim\" dan setelah Daftar Outbound-nya terbuat akan segera dipush ke gudang pihak ketiga, Pesanan Bermasalah tidak akan dipush",
    "theDefaultPushRuleIsThat": "Aturan push default adalah hanya pesanan yang telah mendapatkan label pengiriman yang diizinkan untuk dipush ke gudang pihak ketiga, dan Pesanan Bermasalah tidak akan dipush",
    "strategyDescription": "Deskripsi Strategi",
    "deleteFailedThereIsAWarehouse": "Gagal dihapus! Ada gudang yang digunakan!",
    "onlyStrategiesThatAreNotAssociated": "Hanya strategi yang tidak terkait dengan gudang yang dapat dihapus",
    "wantToDeleteTheStrategy": "Yakin ingin menghapus strategi ini?",
    "allowEarlyPushWhenTheOrder": "Izinkan push lebih awal ketika status pesanan \"Lunas\"",
    "pleaseSetAtLeastOneAdvance": "Harap tetapkan setidaknya satu ketentuan Push Lebih Awal. Hanya pesanan yang memenuhi ketentuan yang dipilih yang dipush terlebih dahulu. Jika tidak, Push Normal akan dilakukan",
    "upToCustomStrategiesCan": "Dapat menambahkan hingga 100 strategi kustom",
    "warehouseUsed": "Gudang yang Digunakan",
    "pushPaidOrdersInAdvance": "Push Pesanan \"Lunas\" Terlebih Dahulu",
    "strategyName": "Nama Strategi",
    "manageCustomPolicies": "Kelola Strategi Kustom",
    "customAdvancePush": "Kustom Push Lebih Awal",
    "pushAllInAdvance": "Push Semua Lebih Awal",
    "basicPush": "Push Normal",
    "normalPushPushAllEarlyCustom": "Push Normal, Push Semua Lebih Awal, Kustom Push Lebih Awal dapat diatur",
    "thirdpartyWarehousePushSettings": "Pengaturan Push Gudang Pihak Ketiga",
    "quantityLimitInfo": "quantity pembelian tidak boleh lebih dari 1000",
    "valueShouldBeAtLeastThe": "Tidak boleh kurang dari  {count}",
    "purchaseLimit": "Batas Pembelian",
    "maxPerOrder": "Maks. per pesanan",
    "storesAreAutomaticallySetToEnable": "[Toko Yang Baru Diintegrasi Secara Otomatis Diset menjadi \"Aktif\"] Nonaktif",
    "authorizedStoresAreAutomaticallySetTo": "[Toko Yang Baru Diintegrasi Secara Otomatis Diset menjadi \"Aktif\"] Diaktifkan",
    "storeXDisableTheAutomaticallyReserve": "Toko {x} menonaktifkan Reservasi Stok Promosi Otomatis",
    "storeXEnableTheAutomaticallyReserve": "Toko {x} mengaktifkan Reservasi Stok Promosi Otomatis",
    "disableAutomaticallyReservePromotionStockMain": "Nonaktifkan Tombol Utama Reservasi Stok Promosi Otomatis",
    "enableAutomaticallyReservePromotionStockMain": "Aktifkan Tombol Utama Reservasi Stok Promosi Otomatis",
    "enabledisableAutomaticallyReservePromotionStock": "Aktifkan/Nonaktifkan Reservasi Stok Promosi Otomatis",
    "change": "Ubah",
    "superLinkAsAnOpenDoor": "Super Link sebagai pintu terbuka untuk menghubungkan semua tautan toko dan konten bisnis Anda, dalam satu halaman!",
    "actualInboundQty": "Jumlah Inbound Sebenarnya",
    "plannedInboundQty": "Jumlah Inbound yang Direncanakan",
    "tipShopeePendingWaitingForShopee": "Tips: Shopee Pending: Menunggu Shopee untuk memberikan informasi logistik atau Shopee sedang melakukan verifikasi apakah pesanan ini merupakan pesanan palsu (fraud). Harap sinkronkan pesanan secara manual untuk mendapatkan status terbaru",
    "shopeeOrdersAreCurrentlyInPending": "Pesanan Shopee saat ini berstatus Pending. Saat ini tidak mendukung penarikan label pengiriman. Mohon coba lagi nanti",
    "gineeHasAutomaticallyFilteredXShopee": "Ginee telah memfilter secara otomatis {x} pesanan Shopee yang statusnya masih Pending dan tidak diizinkan untuk diatur pengiriman",
    "Template": "Template",
    "promotionname": "Nama Promosi",
    "EndTimeLeastDay": "Tanggal Selesai promosi harus minimal 1 hari setelah Tanggal Mulai",
    "theEndTimeMustBeLater": "Waktu berakhir harus lebih lambat dari waktu mulai",
    "TokoStartMinutesTips": "Waktu mulai setidaknya harus 5 menit setelah waktu saat ini",
    "startTimeMustBeAtLeast": "Waktu mulai setidaknya harus 5 menit setelah waktu saat ini",
    "OnceTheDiscountPromotion": "Setelah Promosi berhasil disimpan, Waktu Promosi hanya dapat dipersingkat.",
    "theEndTimeMustBeGreater": "Waktu Selesai harus setidaknya satu jam setelah Waktu Mulai",
    "EntitledProducts": "Produk Promosi",
    "RemoveProducts": "Hapus Produk",
    "removeSelectedProducts": "Hapus Semua Produk dipilih",
    "RemoveAllProducts": "Hapus Semua Produk",
    "promotionDetails": "Detail Promosi",
    "UpdateAllProducts": "Perbarui semua produk",
    "UpdateSelectedProducts": "Perbarui Produk dipilih",
    "SetDiscountAt": "Atur Diskon menjadi",
    "stockMonitorAndSyncIsNot": "Monitor Stok dan Sinkronisasi tidak diaktifkan",
    "inboundHistory": "Riwayat Inbound",
    "inboundDetails": "Detail Inbound",
    "currentlyDoesNotSupportUploadingeditingLazada": "Saat ini upload/edit video Lazada tidak didukung",
    "noteYouCanPublishThisListing": "Catatan: Anda dapat menampilkan produk saat video sedang diproses. Video akan muncul setelah berhasil diproses.",
    "pageNumber": "/ halaman",
    "rejectionReason": "alasan penolakan",
    "theShippingLabelIsEmptyPush": "Label Pengiriman kosong, push gagal",
    "theTrackingNumberIsEmptyPush": "Nomor Tracking kosong, push gagal",
    "doesntMeetTheOrderStatusRequirement": "Tidak memenuhi ketentuan status pesanan, push gagal",
    "problemOrderStoredOrderPush": "Pesanan Bermasalah & Pesanan Disisihkan, push gagal",
    "reminderOnceTheStoreStockIs": "Pengingat: Setelah stok toko berhasil dipush, stok tersebut tidak dapat dikembalikan. Harap pastikan bahwa setiap MSKU dikaitkan dengan gudang dan memiliki stock yang benar",
    "whenANewOrderIsPlaced": "Ketika terdapat pesanan baru atau pesanan dibatalkan untuk sebuah MSKU, stok semua produk channel yang terikat pada MSKU akan berkurang atau bertambah secara bersamaan",
    "theStockOfAllChannelProducts": "Stok semua produk channel yang terikat pada MSKU yang sama akan segera dipush sesuai dengan \"{x}\"",
    "afterTurningOnStockMonitorAnd": "Setelah menyalakan Monitor Stok dan Sinkronisasi:",
    "addingeditingProductsOrStockInVivo": "Menambah/mengedit produk atau stok di gudang Vivo tidak didukung. Jika Anda perlu mengedit, harap masuk ke sistem Vivo",
    "beforeOutboundItHasBeenVerified": "Sebelum outbound, telah terverifikasi bahwa stok di gudang Vivo tidak cukup, push gagal",
    "areYouSureToEnableStock": "Apakah Anda yakin untuk mengaktifkan Monitor Stok dan Sinkronisasi?",
    "areYouSureToDisableStock": "Apakah Anda yakin untuk menonaktifkan Monitor Stok dan Sinkronisasi?",
    "plannedInboundCost": "Perkiraan Harga Inbound",
    "onlyTheGineeWmsWarehouseSupports": "Hanya gudang Ginee WMS / Keeppack / FASTOCK yang mendukung pengeditan Strategi Push. Mengedit strategi push hanya akan berlaku pada pesanan baru. Untuk memberlakukan Strategi Push baru untuk pesanan yang sudah, harap gunakan \"Sinkronisasi\"",
    "editingThePushStrategyWillOnly": "Mengedit Strategi Push hanya akan berlaku pada pesanan baru. Untuk memberlakukan Strategi Push baru untuk pesanan yang sudah, harap gunakan \"Sinkronisasi\"",
    "userManuallyEditedTheWarehouseStock": "Pengguna mengedit Stok Gudang secara manual sehingga menyebabkan Stok Tersedia menjadi negatif",
    "multipleChannelProductsAreBoundTo": "Beberapa produk toko/channel terikat pada MSKU yang sama, yang akan menyebabkan stok yang sama terjual pada waktu yang bersamaan. Jika pelanggan membeli secara bersamaan akan menyebabkan ov ersell",
    "orderSyncDelayDueToDelay": "Keterlambatan sinkronisasi pesanan: karena keterlambatan sinkronisasi pesanan dari marketplace.Sebenarnya stok sudah terpakai untuk pesanan lain",
    "failedPushStockStockNotPushed": "Gagal push stock, stok tidak dipush ke toko sehingga menyebabkan stok toko tidak sesuai dengan stok Ginee",
    "TheStockWasEditedIn": "2. Stok telah diedit di Seller Center. Pengeditan stok di Seller Center tidak akan tersinkronisasi ke stok MSKU Ginee, sehingga dapat menyebabkan perbedaan stok antara Ginee dan marketplace.",
    "AbnormalitiesWhenTheChannelAdjusts": "1. Ketidaknormalan pada saat marketplace melakukan penyesuaian stok sehingga mengakibatkan stok tidak dapat disesuaikan sepenuhnya.",
    "gineeSuccessfullyPushedTheStockAnd": "Ginee berhasil push stok, dan marketplace berhasil menerima stok tersebut. Ada 3 kemungkinan:",
    "pushDelayDueToTheLarge": "Keterlambatan push karena banyaknya tugas push, stok tidak dikirim ke marketplace tepat waktu",
    "noPushRecordFoundStockNot": "Tidak ditemukan riwayat push, stok tidak dikirim ke toko, menyebabkan stok toko tidak sesuai dengan stok Ginee",
    "thisStoreHasReservedPromotionStock": "Toko ini memiliki Reservasi Stok Promosi, namun Reservasi Stok Promosi Otomatis Ginee dinonaktifkan sehingga menyebabkan stok toko tidak sesuai dengan stok Ginee",
    "theStockMonitorAndSyncIs": "Monitor Stok dan Sinkronisasi\ndinonaktifkan, sehingga stok terbaru Ginee tidak dipush ke toko",
    "missingOrderInformationsCurrentlyUnableTo": "Informasi pesanan tidak ada. Saat ini tidak dapat menentukan alasan oversell",
    "pleaseCheckTheChangeReasonOn": "Mohon periksa Alasan Edit di sisi kanan kolom ini",
    "pleaseCheckThePushFailedReason": "Silakan periksa alasan push gagal pada Riwayat Push Stok",
    "forOtherChannelsPleaseDirectlyContact": "Untuk marketplace lainnya mohon langsung menghubungi Customer Service Ginee atau Key Account Anda untuk pengecekan lebih lanjut",
    "PleaseContactGineeCustomerService": "3. Mohon menghubungi Customer Service Ginee atau Key Account Anda untuk pengecekan lebih lanjut",
    "PleaseSyncTheProductThrough": "1. Mohon sinkronkan produk melalui Saluran Produk",
    "forShopeeBukalapakAndLazadaProducts": "Untuk produk Shopee, Bukalapak dan Lazada:",
    "ifYouDidntEditTheStock": "Jika Anda tidak mengedit stok dari Seller Center, mohon ikuti langkah-langkah di bawah ini:",
    "preventTheSameIssueHappeningIn": "Untuk mencegah masalah yang sama terjadi kembali, mohon tetap aktifkan",
    "toPreventTheSameIssueHappening": "Untuk mencegah masalah yang sama terjadi kembali, mohon tetap aktifkan Monitor Stok dan Sinkronisasi",
    "pleaseEnableStockMonitorAndSync": "sehingga stok toko akan terupdate sesuai Aturan Push Stock",
    "notesIfTheStockIsSmaller": "Catatan : Jika stok lebih kecil dari (-1), mohon cek Riwayat Perubahan Stok untuk mengetahui stok tersedia (-1) yang pertama",
    "pleaseContactGineeCustomerServiceOr": "Mohon hubungi Customer Service Ginee atau Key Account anda untuk pengecekan lebih lanjut",
    "gineeOmsOrderusedtotal": "Pesanan Ginee OMS (Terpakai/Total)",
    "mskuUsedtotal": "MSKU (Terpakai/Total)",
    "currentlyDoesNotSupportUploadingeditingShopee": "Saat ini upload/edit video Shopee tidak didukung",
    "gineeWmsOrderusedtotal": "Pesanan Ginee WMS (Terpakai/Total)",
    "walletCredit": "Dompet",
    "gineeWmsConsignorusedtotal": "Consignor Ginee WMS(Terpakai/Total)",
    "onlySupportViewingCustomerDataFrom": "Hanya mendukung pencarian data dari 1 Januari 2023",
    "packageSplit": "Paket Terpisah",
    "theMskuWasNotFoundIn": "MSKU ini tidak ditemukan di gudang pihak ketiga. Silahkan cek status push SKU tersebut pada halaman daftar stok. Jika push gagal, silahkan lakukan “Tambah ke Gudang Fulfillment” secara manual.",
    "changePrice": "Ubah Harga",
    "discAfterTax": "Pajak Setelah Diskon",
    "discBeforeTax": "Pajak Sebelum Diskon",
    "productTaxIncluded": "Termasuk PPN",
    "additionalTax": "Harga Belum Termasuk Pajak",
    "vatRules": "Aturan Pajak",
    "vat": "PPN",
    "selectAndAdd": "Pilih dan Tambahkan",
    "customerNotFoundDoYouWant": "Pelanggan tidak ditemukan, apakah Anda ingin tambahkan sekarang?",
    "selectFromExistingCustomerList": "Pilih dari Daftar Pelanggan yang ada",
    "enterCustomerPhoneNumberToAdd": "Masukkan Nomor Telepon Pelanggan untuk menambahkan Pelanggan atau mencari Pelanggan yang sudah ada",
    "addBuyer": "Tambah Pelanggan",
    "unknown": "Tidak Dikenal",
    "afterDeduction": "Setelah Pengurangan",
    "byPercentage": "Persentase",
    "byAmount": "Jumlah",
    "deductionMethod": "Metode Pengurangan",
    "deductionAmount": "Jumlah Pengurangan",
    "actualPayment": "Pembayaran Sebenarnya",
    "pleaseAddProductsFirst": "Mohon tambahkan produk terlebih dahulu",
    "youWantToClearYourCart": "Apakah Anda yakin ingin menghapus keranjang Anda?",
    "vatIncluded": "Termasuk PPN",
    "payable": "Yang Harus Dibayar",
    "productSubtotal": "Subtotal Produk",
    "viewDetails": "Lihat Rincian",
    "productDiscount": "Diskon Produk",
    "wholeOrderReduction": "Pengurangan Seluruh Pesanan",
    "youSureYouWantToDelete": "Anda yakin ingin menghapus?",
    "switchingStores": "Sedang mengubah toko...",
    "wantToChangeTheStoreTo": "Apakah Anda yakin ingin mengubah toko ke {x}?",
    "clearShoppingCart": "Hapus Keranjang",
    "disc": "Disc.",
    "totalXItems": "Total {x} item",
    "deduction": "Pengurangan",
    "checkOut": "Checkout",
    "submitOrder": "Ajukan Pesanan",
    "productBarcode": "Barcode Produk",
    "pleaseScanTheProductBarcode": "Mohon scan Barcode Produk",
    "cashier": "Kasir",
    "signalStrength": "Kekuatan Sinyal",
    "language": "Bahasa",
    "loginToTheStoreSuccessfully": "Berhasil masuk ke toko!",
    "pleaseSelectAStore": "Mohon pilih toko",
    "thereIsNoAccessibleStorePlease": "Tidak ada toko yang dapat diakses, mohon hubungi administrator untuk mengaktifkan toko",
    "partialRefund": "Refund Parsial",
    "refunded": "Telah Direfund",
    "partialReturn": "Retur Barang Parsial",
    "completedDone": "Selesai",
    "printReceipt": "Cetak Struk",
    "noNeedToPrintReceipt": "Tidak Perlu Cetak Struk",
    "cashReceived": "Nominal Diterima",
    "transactionAmount": "Jumlah Transaksi",
    "numberOfItems": "Jumlah Item",
    "customer": "Pelanggan",
    "transactionTime": "Waktu Transaksi",
    "transactionSuccessful": "Transaksi berhasil!",
    "reorder": "Pesan Ulang",
    "theShoppingCartWillBeAutomatically": "Keranjang Belanja akan otomatis terhapus, harap pilih produk lagi untuk membuat pesanan",
    "theStoreHasChangedItsWarehouse": "Gudang toko telah diubah dan pesanan tidak dapat dibuat!",
    "continueToPlaceAnOrder": "Lanjutkan pembuatan pesanan",
    "removeOutOfStockProducts": "Hapus produk yang kehabisan stok",
    "outOfStockQuantity": "Jumlah Stok Habis",
    "theFollowingProductsAreOutOf": "Produk berikut kehabisan stok! Melanjutkan pesanan akan mengakibatkan oversale",
    "card": "Kartu",
    "returnFailedTheReturnedItemHas": "Retur Barang gagal! Produk yang dikembalikan telah dihapus. Mohon pilih Refund Saja dan kembalikan produk ke gudang secara manual",
    "returnFailedTheReturnWarehouseIs": "Retur Barang gagal! Gudang pengembalian dinonaktifkan. Mohon pilih Refund Saja dan kembalikan produk ke gudang secara manual",
    "refundToTheOriginalCard": "Refund ke Kartu Asal",
    "refundMethod": "Metode Refund",
    "vatRefund": "Pajak Direfund",
    "afterOperatingReturnTheStockThe": "Setelah Retur Barang dilakukan, stok produk yang diretur akan langsung ditambahkan kembali ke gudang",
    "wantToReturn": "Apakah Anda yakin ingin Retur Barang?",
    "return": "Retur Barang",
    "completed": "Selesai",
    "cancelEntireOrder": "Batalkan Seluruh Pesanan",
    "onceTheEntireOrderIsCancelled": "Jika seluruh pesanan dibatalkan, maka pesanan akan direfund seluruhnya dan stok produk yang dipesan akan langsung ditambahkan kembali ke gudang",
    "cancelTheEntireOrder": "Apakah Anda yakin ingin membatalkan seluruh pesanan?",
    "onlyRefundsWillOnlyAffectThe": "Refund Saja hanya akan mempengaruhi Pembayaran Sebenarnya dan tidak akan mempengaruhi stok produk",
    "youWantToRefund": "Apakah Anda yakin ingin Refund?",
    "orderActualPayment": "Pembayaran Sebenarnya",
    "amountExcludeVat": "Total diluar PPN",
    "companyPhoneNumber": "No. Telepon Perusahaan",
    "companymerchantTin": "NPWP",
    "companymerchantLegalAddress": "Alamat Perusahaan",
    "companymerchantName": "Nama Perusahaan",
    "totalAmountPaid": "PEMBAYARAN",
    "addVatX": "Tambah: PPN ({x}%)",
    "amountDue": "Yang Harus Dibayar",
    "lessDiscounts": "Dikurangi: Diskon",
    "lessVatx": "Dikurangi: PPN({x}%)",
    "totalAmount": "Jumlah Total",
    "vatExemptSales": "Penjualan Bebas PPN",
    "zeroratedSales": "Penjualan PPN 0%",
    "vatableSales": "Penjualan dikenakan PPN",
    "itemBig": "ITEM",
    "siNo": "No. Invoice",
    "businessStyle": "Nama Perusahaan",
    "tinNo": "NPWP",
    "soldTo": "Dijual Kepada",
    "changes": "Kembalian",
    "totalPaid": "Pembayaran",
    "totalOrder": "Total Belanja",
    "vatx": "PPN({x}%)",
    "totalDisc": "Total Disc.",
    "totalItem": "Subtotal",
    "cashierPerson": "Kasir",
    "orderNo": "No. Pesanan",
    "insufficientStockUnableToPurchaseProducts": "Stok tidak cukup, produk tidak dapat dibeli",
    "isNotCorrectPleaseCheckPhone": "Nomornya tidak valid, silakan periksa",
    "mobileRangeLengthWithPrefix": "Nomor ponsel seharusnya dimulai dengan {prefix} dan terdiri dari {min} sampai {max} digit",
    "mobileLengthWithPrefix": "Nomor ponsel seharusnya dimulai dengan {prefix} dan terdiri dari {panjang} digit",
    "mobileRangeLength": "Nomor ponsel harus {min} sampai {max} digit",
    "posSettings": "Pengaturan POS",
    "manageGineePosStore": "Kelola Toko Ginee POS",
    "changingWarehousesWillReplaceTheProducts": "Pergantian gudang akan mengganti produk di Toko POS dengan produk dan stok di gudang baru, harap operasikan dengan hati-hati",
    "taxCollectionPreference": "Aturan Pemungutan Pajak",
    "orderTotalAfterRefund": "Total Pesanan Setelah Refund",
    "productQuantity": "Jumlah Produk",
    "selectReturnProduct": "Pilih Produk Retur",
    "receipt": "Struk",
    "newDiscountedPrice": "Harga Diskon Baru",
    "taxDiscountPreference": "Aturan Pajak-Diskon",
    "pleaseEnterCorrectUsername": "Harap masukkan Nama Pelanggan yang benar",
    "orderPhoneNumber": "Nomor Telepon",
    "customerNam": "Nama Pelanggan",
    "theAmountDueCannotBeLess": "Nominal Diterima tidak boleh kurang dari Nominal Yang Harus Dibayar",
    "excessiveReturnIsNotSupported": "Pengembalian berlebih tidak didukung",
    "theStoreIsNotBoundTo": "Toko tidak terikat pada gudang",
    "orderDoesNotExist": "Pesanan tidak ada",
    "theStoreDoesNotExist": "Toko tidak ada",
    "productBarcodeXDoesNotExist": "Barcode produk {x} tidak ada di gudang",
    "notExistInTheWarehouse": "MSKU {x} tidak ada di gudang",
    "addMskuToTheWarehouseFirst": "MSKU tidak ada di gudang, harap tambahkan MSKU di gudang terlebih dahulu",
    "pay": "Bayar",
    "aOperationToCancelTheEntire": "{a} membatalkan seluruh pesanan, Catatan {f}",
    "aOperationReturnAndRefundMsku": "{a} melakukan Retur Barang dan Refund, MSKU {b}, Jumlah {c}, Nominal Refund {d}, Pajak Direfund {e}, Catatan {f}",
    "aOperationRefundRefundAmountB": "{a} melakukan Refund, Nominal Refund {b}, Pajak Direfund {c}, Catatan {d}",
    "discountPriceCannotBeHigherThan": "Harga diskon tidak boleh lebih tinggi dari harga aslinya",
    "supportsInputNumNoMore": "Hanya mendukung input angka/huruf/spasi/\"-\"/\"_\"",
    "ooOrders": "Pesanan O2O",
    "ifYouDoNotHaveOms": "Jika Anda tidak memiliki izin Manajemen Pelanggan OMS, mohon hubungi administrator untuk mengaktifkannya",
    "totalVatInc": "TOTAL",
    "netDue": "TOTAL BERSIH",
    "lessOtherDiscounts": "Dikurangi: Disc. Lainnya",
    "customerPhoneNo": "No. Telepon Pelanggan",
    "doNotAllowLazadaEase": "Limitasi Marketplace: Tidak mengizinkan update stok produk toko Lazada Ease Mode",
    "doNotAllowTokoOO": "Limitasi Marketplace: Tidak mengizinkan update stok produk di gudang O2O Tokopedia",
    "youHaveAddedAChannelSku": "Anda telah menambahkan SKU Channel dengan nama berbeda. Pengikatan produk yang berbeda ke MSKU dapat menyebabkan oversell. Harap konfirmasi bahwa SKU Channel dan MSKU adalah produk yang sama",
    "bindingSkuWithDifferentNamesMay": "Mengikat SKU dengan nama berbeda dapat menyebabkan oversell, harap operasikan dengan hati-hati",
    "channelReturnedErrorReachTheChannel": "{Channel} returned error: Mencapai limitatis QPS API Channel, push stok gagal",
    "expiredStoresWillNotPushInventory": "Toko kedaluwarsa tidak akan push stok, harap otorasi ulang sesegera mungkin!",
    "massReleaseWillReleaseTheReserved": "Rilis Massal akan merilis Stok Promosi yang direserve untuk produk tersebut (termasuk semua variasi pada produk yang dipilih), mohon operasikan dengan hati-hati!",
    "recommendedOperation": "Tindakan yang Disarankan",
    "oversoldReasons": "Penyebab Oversell",
    "pleaseEnable": "Harap aktifkan",
    "ThereAreCancellationreturnrefundOrdersOn": "3. Ada yang  dibatalkan/diretur/direfund di Seller Center, menyebabkan stok di Seller Center otomatis bertambah. Pembeli dapat melakukan pemesanan, namun stok di Ginee masih 0 sehingga mengakibatkan oversell.",
    "PleaseCheckWhetherThereAre": "2. Harap periksa apakah ada pesanan yang dibatalkan/diretur/direfund di Seller Center setelah stok diperbarui menjadi 0",
    "skuxAlreadyExistsInOmsAnd": "SKU{x} sudah ada di OMS dan merupakan produk kombinasi. Produk kombinasi tidak boleh outbound/inbound!",
    "oneclickOversoldCheck": "Cek Oversell",
    "massRelease": "Rilis Massal",
    "thisOperationWillReleaseTheReserved": "Operasi ini akan melepaskanStok Promosi yang Direserve dari semua produk di toko, harap operasikan dengan hati-hati!",
    "imageSizeMustBeGreaterThan": "Ukuran gambar harus lebih besar dari tinggi {x}, lebar {y}",
    "clickToUpdateBasicInformationSuch": "Klik untuk memperbarui informasi dasar seperti paket, izin, dll",
    "clickRefreshToObtainTheLatest": "Klik Refresh untuk mendapatkan paket, izin, request consignor dan informasi terbaru lainnya",
    "bySelectedProducts": "Berdasarkan Produk yang Dipilih",
    "byStoreName": "Berdasarkan Toko",
    "settingProductDescription": "Pengaturan Master Produk dan Salin",
    "paymentReconciliation": "Rekonsiliasi Pembayaran",
    "partialRelease": "Pelepasan Dana Sebagian",
    "released": "Telah Dilepas",
    "toRelease": "Menunggu Pelepasan Dana",
    "marketplaceReleasedAmountForTheOrder": "Jumlah Pelepasan Marketplace untuk Pesanan",
    "orderPackageNumber": "Nomor Paket Pesanan",
    "marketplaceReleasedAmountEstimatedRelease": "Perbedaan Jumlah = Jumlah Pelepasan Dana Marketplace - Perkiraan Jumlah Pelepasan Dana",
    "afterTheOrderIsCompletedThe": "Setelah pesanan selesai, marketplace melepaskan pendapatan pesanan kepada penjual",
    "settlementDetails": "Rincian Pelepasan Dana",
    "amountDifference": "Perbedaan Jumlah",
    "marketplaceReleasedAmount": "Jumlah Pelepasan Dana Marketplace",
    "reconciliationStatusProvidedByTheChannel": "Status Rekonsiliasi yang Disediakan Marketplace",
    "feesCharges": "Biaya & Ongkos",
    "voucherRebates": "Voucher & Cashback",
    "totalExpenses": "Total Pengeluaran",
    "estimatedReleaseAmountTotalRevenue": "Perkiraan Jumlah Dana Dilepas = Total Penghasilan - Total Pengeluaran",
    "estimatedReleaseAmount": "Perkiraan Jumlah Pelepasan Dana",
    "timeoutPaymentIsNotSettledWithin": "Timeout: Pembayaran tidak diselesaikan dalam waktu 24 jam setelah pesanan selesai",
    "settlementDataIsSyncedOnceA": "Data pelepasan dana disinkronkan sehari sekali dan beberapa pesanan yang telah dilepas mungkin masih belum lepas",
    "xDigitsOfEnglishChineseNumbers": "{x} digit bahasa Inggris, Mandarin, angka, spasi dan - _ & %",
    "pushSuccessnoShippingLabel": "Push Berhasil(Tidak Ada Label Pengiriman)",
    "pushSuccesshasShippingLabel": "Push Berhasil(Memiliki Label Pengiriman)",
    "clickTheOkButtonToRecreate": "Klik tombol [OK] untuk membuat ulang Outbound Penjualan berdasarkan data pesanan terbaru",
    "youSureYouWantToRecreate": "Apakah Anda yakin ingin membuat ulang Outbound Penjualan?",
    "atchEditingOfBrandsInMultiple": "pengeditan batch merek di beberapa toko hanya mendukung pemilihan \"No Brand\". Jika Anda perlu memilih merek lain, silakan pilih produk dari toko yang sama.",
    "editingOfBrandsInMultiple": "pengeditan batch merek di beberapa toko hanya mendukung pemilihan \"No Brand\". Jika Anda perlu memilih merek lain, silakan pilih produk dari toko yang sama.",
    "masstRemark": "Edit Masal Catatan",
    "pushFailedKeeppackOutboundOrderStatus": "Push gagal, Keeppack tidak mengizinkan operasi ini",
    "printingFailedTheOrderIsMissing": "Pencetakan gagal, pesanan tidak memiliki PDF Label Pengiriman",
    "dearCustomer": "Pelanggan yang terhormat,",
    "yourFollowingResourcesHaveExceededThe": "Penggunaan fitur Anda telah melampaui batas paket, silahkan sesuaikan atau tingkatkan paket sebelum melanjutkan penggunaan \n  Ginee OMS",
    "selectXStoresToContinueBinding": "Pilih toko {x} untuk melanjutkan pengikatan, dan sisanya akan Dinonaktifkan ( Setelah toko dinonaktifkan, tidak akan berpengaruh ke penjualan toko di marketplace. Produk toko tersebut akan otomatis terlepas dari Master SKU.Produk/pesanan toko dan data lainnya tidak akan ditampilkan di Ginee OMS)",
    "selectedXStore": "Toko {x} terpilih",
    "staffAccount": "Akun Staf",
    "selectXStaffAccountsToContinue": "Pilih {x} akun staf yang akan tetap digunakan, sisanya akan dihapus (hanya izin sistem Ginee OMS untuk akun staf yang akan dihapus, sistem Ginee lainnya tidak akan terpengaruh)",
    "onlySupportsUploadingPdfFilesMax": "Hanya mendukung pengunggahan file PDF, maks. 1MB",
    "shippingLabelHasBeenUploaded": "Label Pengiriman telah diunggah",
    "uploadShippingLabel": "Unggah Label Pengiriman",
    "shippingLabelPdf": "PDF Label Pengiriman",
    "cancellationTime": "Waktu Pembatalan",
    "shippingTime": "Waktu Pengiriman",
    "inventorySynchronizationSwitchTurnedOff": "Tombol Monitor dan Sinkronisasi Stok: dimatikan",
    "theFollowingFunctionsWillBeAutomatically": "Fitur berikut akan otomatis dimatikan karena paket kedaluwarsa dan perlu dihidupkan secara manual setelah paket telah diperbarui.",
    "afterThePackageExpiresStockSynchronization": "Setelah paket habis, tombol sinkronisasi stok akan mati secara otomatis.",
    "lazadaPhOrdersOnlySupportPrinting": "Pesanan yang dipilih termasuk pesanan dari situs PH, dan hanya mendukung pencetakan invoice menggunakan template Ginee",
    "companymerchantVatTinNo": "NPWP",
    "afterTheSwitchIsTurnedOn": "Setelah diaktifkan, sebelum Daftar Outbound dipush ke gudang, sistem akan terlebih dahulu menarik stok terbaru dari gudang pihak ketiga yang tersinkron. Jika Stok Gudang tidak mencukupi, push akan dibatalkan, Stok Gudang OMS akan diperbarui dan pesanan akan dipindahkan ke Pesanan Bermasalah-Stok Habis;\nSetelah dinonaktifkan, Daftar Outbound akan langsung dipush ke gudang ketika memenuhi kondisi push dan sistem tidak akan memverifikasi apakah stok di gudang pihak ketiga mencukupi atau tidak",
    "checkStockBeforePushing": "Periksa Stok Sebelum Push",
    "theStrategyImplementedByTheWarehouse": "Strategi yang diterapkan untuk gudang, mohon lihat Deskripsi Strategi untuk penjelasannya",
    "whenTheOrderMeetsThePush": "Pesanan akan dipush secara otomatis bila memenuhi syarat push, tidak perlu dipush secara manual",
    "shopeeApiRequiresThatAllVariation": "API Shopee mewajibkan semua nama variasi tidak boleh diubah saat mengedit produk (setidaknya satu nama variasi asli harus dipertahankan)",
    "sellerOwnFleet": "Jasa Kirim Toko",
    "addedToday": "Ditambahkan hari ini",
    "globalProduct": "Produk Global",
    "publishedSite": "Situs yang Telah Ditampilkan",
    "sellerStock": "Stok Penjual",
    "globalProductPrice": "Harga Produk Global",
    "verifyMskuBeforePushingYIs": "Verifikasi MSKU sebelum dipush: Stok {y} di WMS/Gudang Pihak Ketiga habis dan push dihentikan",
    "theStockVerificationFailedBeforePushing": "Verifikasi stok gagal sebelum pengiriman, dan pesanan keluar yang dikirim dicegat. Alasan: MSKU:{x} kehabisan stok di WMS/gudang pihak ketiga.",
    "enablePush": "Push Aktif",
    "disablePush": "Push Nonaktif",
    "changeToDisable": "Ubah Menjadi Nonaktif",
    "changeToEnable": "Ubah Menjadi Aktif",
    "productNameAccountNumber": "Nama Produk & Nomor Akun",
    "dueToShopeeApiLimitationsProducts": "Karena keterbatasan API Shopee, produk yang memiliki lebih dari 50 variasi tidak dapat dipublikasikan atau diedit dari Ginee",
    "accurateWh": "Gudang Accurate",
    "gineeWarehouse": "Gudang Ginee",
    "gineeWarehouseAddress": "Alamat Gudang Ginee",
    "gineeWarehouseName": "Nama Gudang Ginee",
    "manageTheMappingRelationshipBetweenChannel": "Kelola hubungan pemetaan antara Gudang Channel dan Gudang Ginee",
    "youCanSelectTheChannelStore": "Anda dapat memilih toko marketplace untuk push stok. Toko CNSC/3PF hanya mendukung pengaturan Aturan Push Stok sesuai dengan Akun Merchant",
    "bindingStatus": "Status Ikatan",
    "channelWarehouseStatus": "Status Gudang Channel",
    "thePartiallyReleasedListRefersTo": "Daftar Pelepasan Dana Sebagian mengacu pada pesanan Lazada yang memiliki beberapa produk. Ada produk yang sudah dilepas dan ada yang belum dilepas. Data ini akan masuk ke dalam status ini.",
    "theReleasedListDisplaysTheReleased": "Daftar Telah Dilepas menampilkan data dana yang telah dilepas yang diperoleh dari API",
    "customTax": "Bea Masuk, PPN & PPh",
    "promotionFee": "Biaya Kampanye",
    "transactionFee": "Biaya Kartu Kredit",
    "highQuality": "Kualitas Tinggi",
    "commissionF": "Biaya Administrasi",
    "amsCommissionFee": "Biaya Komisi AMS",
    "reverseShippingFeeForReturnedOrders": "Ongkos Kirim Pengembalian Barang",
    "plShippingFeeDiscount": "Diskon Ongkir Ditanggung Jasa Kirim",
    "shopeeShippingRebate": "Gratis Ongkir dari Shopee",
    "sellerAbsorbedCoinCashback": "Cashback Koin yang Ditanggung Penjual",
    "productDiscountsAndCashbackByShopee": "Diskon Produk dari Shopee",
    "productRefundAmount": "Jumlah Pengembalian Dana ke Pembeli",
    "productDiscountedPrice": "Total Diskon Produk",
    "productOriginalPrice": "Harga Asli Produk",
    "totalReleaseAmount": "Total yang Dilepas",
    "settlementNo": "No. Pelepasan",
    "settlementTime": "Waktu Pelepasan",
    "whetherTimeout": "Apakah Timeout?",
    "releasing": "Sedang Dirilis",
    "orderItem": "Item Pesanan",
    "settlementDetailsAllPages": "Rincian Pelepasan Dana - Semua Halaman",
    "settlementDetailsBySelected": "Rincian Pelepasan Dana - Export yang dipilih",
    "ordersAllPages": "Pesanan - Semua Halaman",
    "ordersBySelected": "Pesanan - Export yang dipilih",
    "compatibleModels": "Model yang Bisa Digunakan",
    "noticeTitle": "Keterangan",
    "promotionStockCannotBeGreaterThan": "Stok Promosi tidak boleh lebih besar dari Stok Tersedia",
    "pleaseEnterTheTitle": "Silakan masukkan judul",
    "theOutboundListHasBeenCompleted": "Daftar Outboud telah selesai di gudang fulfillment dan tidak dapat dipush kembali",
    "commissionChargedBasedOnItemUnit": "Komisi dibebankan berdasarkan harga satuan barang (% dibebankan pada Harga Satuan - Diskon Penjual)",
    "theOrderHasBeenCompletedBut": "Pesanan telah selesai, tetapi marketplace belum melepaskan dana",
    "paymentFeeForProcessingCharges": "Biaya pembayaran untuk biaya pemrosesan (% dibebankan pada Harga Satuan + Biaya Pengiriman Pembeli - Voucher Penjual)",
    "listedPriceOfTheItemSold": "Harga yang tercantum untuk barang yang dijual sebelum diskon atau biaya apa pun kepada pembeli",
    "productVat": "PPN Produk",
    "buyerRealName": "Nama Pembeli yang Sebenarnya",
    "salesInvoiceWillUseTheSelected": "Jika dinonaktifkan, Faktur Penjualan akan menggunakan informasi pelanggan tetap yang telah dipilih. Jika diaktifkan, Faktur Penjualan akan menggunakan informasi pelanggan sebenarnya yang diberikan oleh pesanan marketplace. Mohon diingat bahwa informasi pelanggan di beberapa marketplace akan disensor. Disarankan untuk menonaktifkan fitur ini untuk toko yang menyensor informasi pelanggan",
    "realCustomerInformationInTheOrder": "Informasi Pelanggan yang Sebenarnya dalam Pesanan",
    "excludingVat": "Tidak Termasuk PPN",
    "theSkuIsNotOversoldReason": "SKU tidak oversell. Alasan: Karena TikTok Shop akan menunda perilisan stok setelah pesanan dibatalkan, Ginee juga akan menunda perilisan Stok Terkunci selama 12 jam setelah pesanan yang dibatalkan disinkronkan ke Ginee (status akan ditampilkan sebagai \"Sedang Dirilis\"). Kuantitas stok terkunci yang akan dirilis ≥ kuantitas stok negatif bukan merupakan oversell.",
    "ifYouNeedToReleaseThe": "Jika Anda perlu merilis Stok Terkunci dengan status \"Sedang Dilepas\" lebih awal, Anda dapat set Daftar Outbound tersebut sebagai Invalid melalui Manajemen Outbound (ada risiko oversell, mohon operasikan dengan hati-hati)",
    "publishedGlobalProduct": "Produk Global yang Ditampilkan",
    "editingTheGlobalProductPriceWill": "Mengedit harga Produk Global akan memperbarui harga produk toko sesuai rumus perhitungan",
    "editingTheGlobalProductStockWill": "Mengedit stok Produk Global akan memperbarui stok produk toko",
    "pleaseNotePleaseUseMAuthorization": "Harap diingat: Mohon gunakan otorisasi {m} untuk toko lokal biasa, dan otorisasi {u} untuk toko CNSC/3PF",
    "theSkuIsOutOfStock": "Stok SKU di WMS habis dan tidak dapat dipush",
    "theSkuDoesNotExistIn": "SKU tidak ada di WMS dan tidak dapat dipush",
    "theOrdersOutboundListHasBeen": "Daftar Outbound pesanan ini telah selesai di WMS dan tidak dapat dipush",
    "wmsIsProcessingTheOrder": "WMS sedang memproses pesanan",
    "authorizeMainAccount": "Otorisasi Akun Utama",
    "authorizeShopAccount": "Otorisasi Akun Toko",
    "customOrNo": "Custom OR No.",
    "onlySupportsFilesPdfPngJpeg": "Hanya Mendukung File PDF, png, jpeg, JPG  kurang dari 5M",
    "gineeWillAutomaticallyCreateAnInbound": "Ginee akan otomatis membuat Daftar Inbound sesuai dengan Jumlah Inbound yang diinput, sekaligus menyelesaikan Daftar Inbound dan menambah Stok Gudang, mohon konfirmasi!",
    "youWantToAddToThe": "Apakah Anda yakin ingin tambah ke gudang sekaligus inbound?",
    "feeRefundreturn": "Biaya Refund/Return",
    "fulfillmentFee": "Biaya Fulfillment",
    "shippingFeeChargedToSeller": "Biaya Pengiriman Ditanggung Penjual",
    "otherFee": "Biaya Lainnya",
    "serviceFee1": "Biaya Layanan",
    "rebateFromMarketplace": "Subsidi dari Marketplace",
    "sellerPromotion": "Promosi Ditanggung Penjual",
    "productDiscountPrice": "Harga Diskon Produk",
    "pleaseRenewOrPurchaseOrderQuantity": "Harap perbarui atau beli kuota pesanan sesegera mungkin, jika tidak, Anda tidak akan dapat menggunakan fungsi terkait pesanan!",
    "yourGineeWmsOrderQuotaHas": "Kuota pesanan Ginee WMS Anda telah terlampaui!",
    "yourGineeOmsOrderQuotaHas": "Kuota pesanan Ginee OMS Anda telah terlampaui!",
    "orderQuotaExceeded": "Kuota Pesanan Terlampaui",
    "insufficientOrderQuota": "Kuota Pesanan < 10%",
    "theOrderQuotaOfYourCurrent": "Harap perbarui atau beli kuota pesanan tambahan secepatnya. Jika tidak, Anda tidak akan dapat menggunakan fitur yang berhubungan dengan Outbound!",
    "bySelectingAllLazadaWillConsider": "Dengan memilih SEMUA, Lazada akan menganggapnya termasuk semua pilihan di bawah ini tanpa perlu centang setiap sub-item secara individual.",
    "abnormalExpiration": "Kedaluwarsa Abnormal",
    "pushOutboundOrders": "Push Daftar Outbound",
    "pushInboundOrders": "Push Daftar Inbound",
    "directInbound": "Inbound langsung",
    "updatedToBePushed": "(Update) Menunggu Push",
    "addressNameAndPhoneNumberWill": "Alamat, Nama, dan No. Handphone akan digunakan sebagai informasi pengirim pada Label Pengiriman template Ginee",
    "syncChannelAddress": "Sinkronisasi Alamat Channel",
    "withholdingTax": "Pemotongan Pajak",
    "theCnscpfsChannelWarehouseIsNot": "Gudang Channel CNSC/3PF tidak terikat dengan Gudang Ginee, sehingga Daftar Outbound tidak dapat dibuat",
    "afterSettingTheShippingMethodWhen": "Setelah mengatur metode pengiriman, ketika mengatur pengiriman di Ginee, metode pengiriman dan alamat penjemputan akan dipilih secara otomatis untuk meningkatkan efisiensi pengiriman",
    "theOutboundOrderIsInvalidIn": "Outbound sudah tidak valid di WMS dan tidak dapat didorong lagi.",
    "progress": "Progress",
    "setShippingInfo": "Atur Info Pengiriman",
    "outboundOrderHasBeenSuccessfullyPushed": "Outbound pesanan telah berhasil didorong ke gudang untuk diproses, dan Anda tidak dapat memodifikasi informasi outbound pesanan. Harap lakukan outbound secara manual sesuai dengan informasi perubahan pesanan",
    "mStoreAbnormallyExpired": "Toko {m} kedaluwarsa secara tidak normal",
    "oneClickToChangeTheStores": "Satu klik untuk mengubah Alamat Pickup toko",
    "changeAddressByStore": "Ubah Alamat Toko",
    "youCanQuicklyChangePickupAddress": "Anda dapat dengan praktis mengubah Alamat Pickup semua logistik di satu toko Shopee menjadi alamat lain",
    "invalidTrackingNumberPleaseGoTo": "Nomor Tracking/Resi tidak valid, mohon kunjungi Seller Center untuk menginput kembali Nomor Tracking/Resi yang valid secepatnyaa!",
    "invalidTrackingNumberawb": "Nomor Tracking/Resi Tidak Valid",
    "channelAbnormalOrders": "Pesanan Abnormal Marketplace",
    "tryNoww": "Coba Sekarang",
    "congratulationsYouHaveReceivedADay": "Selamat! Anda telah mendapatkan uji coba gratis semua fitur selama 7 hari ",
    "mskuTotal": "Jumlah Total MSKU",
    "updateUsageEveryHours": "Pembaruan Penggunaan Setiap 4 Jam",
    "dailyUpdateUsage": "Pembaruan Harian Penggunaan",
    "replacementSuccessful": "Perubahan berhasil!",
    "theFollowingOrdersNeedToAdd": "Sebelum mengatur pengiriman, informasi pengiriman perlu ditambahkan pada pesanan dibawah ini.",
    "toEnsureNormalUse": "Untuk memastikan penggunaan normal, mohon perbarui paket tepat waktu",
    "invoiceNumberMustBeDigits": "Nomor Invoice harus memiliki 8 digit",
    "companymerchantInformation": "Informasi Perusahaan/Penjual",
    "orDate": "OR Date",
    "orNo": "OR No",
    "pleaseEnterTheWarehouseCodeNo": "Silakan masukkan kode gudang, maksimal 50 karakter",
    "failedToPrintInvoice": "Gagal mencetak Invoice",
    "thatIsOfficialReceiptNumberYou": "Artinya, Nomor Tanda Terima Resmi. Anda dapat mengatur awalan maksimal 3 huruf (awalan boleh dikosongkan) + 8 digit (harus 8 digit) sehingga membentuk No. OR. Akan bertambah setiap pesanan dicetak sesuai nomor yang diatur. Harap diperhatikan: Setelah setiap perubahan, akan dicetak sesuai dengan aturan terbaru, mohon ubah dengan hati-hati;",
    "theCompanyInformationSetInGinee": "Informasi Perusahaan yang diatur di Ginee Accounts akan ditampilkan",
    "setCompanyInformation": "Atur Informasi Perusahaan",
    "atTheRequestOfThePhilippine": "Atas permintaan pemerintah Filipina, semua Invoice toko Filipina harus menggunakan template Invoice Filipina. Dapat dipilih apakah akan memasukkan PPN atau tidak pada template",
    "orderDate": "Tanggal Pemesanan",
    "thisReceiptShallBeValidFor": "TANDA TERIMA INI BERLAKU SELAMA LIMA (5) TAHUN DARI TANGGAL ACKNOWLEDGMENT CERTIFICATE",
    "acknowledgementCertificateNo": "Acknowledgement Certificate No.",
    "dateIssued": "Tanggal Terbit",
    "seriesRange": "Series Range",
    "handleProb": "Tangani Masalah",
    "checksFailed": "Pemeriksaan Gagal!",
    "successfullyDeletedTheWarehouse": "Berhasil menghapus Gudang!",
    "startDeletion": "Mulai Penghapusan",
    "checksPassed": "Lulus Pemeriksaan!",
    "inventoryClearanceAllProductStocksAre": "Pengosongan Stok: Izin Persediaan: Semua stok produk telah menjadi 0",
    "manualInboundListAllDocumentsHave": "Daftar Inbound Manual: Semua dokumen telah selesai",
    "purchaseInboundListAllDocumentsHave": "Daftar Inbound Pembelian: Semua dokumen telah selesai",
    "manualOutboundListAllDocumentsHave": "Daftar Outbound Manual: Semua dokumen telah selesai",
    "salesOutboundListAllDocumentsHave": "Daftar Outbound Penjualan: Semua dokumen telah selesai",
    "deletingAWarehouseRequiresCheckingThe": "Menghapus Gudang memerlukan pemeriksaan data Gudang, menyelesaikan semua Daftar Inbound/Outbound, dan mengosongkan seluruh stok SKU sebelum penghapusan",
    "checkingWarehouseDeletion": "Penghapusan Gudang sedang Diperiksa",
    "beforeDeletingAWarehouseYouNeed": "Sebelum menghapus Gudang, Anda perlu menyelesaikan semua Daftar Inbound/Outbound dan mengosongkan semua stok SKU",
    "oneAreYouSureYouWantTo": "Apakah Anda yakin ingin menghapus Gudang?",
    "theNumberOfLocalWarehouseHas": "Jumlah Gudang Lokal telah mencapai batas (maks. 50). Harap hapus gudang dan coba lagi!",
    "oneFailedToSaveTheNumberOf": "Gagal menyimpan! Jumlah SKU Channel yang terikat pada Produk Kombinasi telah mencapai batas (maks. 500)",
    "failedToSaveTheNumberOf": "Gagal menyimpan! Jumlah produk yang ditambahkan ke Produk Kombinasi telah mencapai batas (maks. 10)",
    "failedToSaveTheBoundCombined": "Gagal menyimpan! Produk Kombinasi yang terikat pada produk satuan {x} telah mencapai batas(dapat diikat dengan max. 100 Produk Kombinasi)",
    "failedToSaveTheVariationsOf": "Gagal menyimpan! Variasi Master Produk telah mencapai batas (maks. 200)",
    "bindingFailedTheChannelSkuBound": "Pengikatan gagal! SKU Channel yang terikat pada MSKU telah mencapai batas (maks. 500)",
    "deletingWarehouse": "Menghapus Gudang...",
    "deletingWarehouseProducts": "Menghapus Produk Gudang...",
    "deletingMIO": "Menghapus Daftar Inbound Manual...",
    "deletingPIO": "Menghapus Daftar Inbound Pembelian...",
    "deletingMOO": "Menghapus Daftar Outbound Manual...",
    "deletingSOO": "Menghapus Daftar Outbound Penjualan...",
    "none": "Tidak Ada",
    "InboundStockIsOnlyValid": "1. Stok inbound hanya berlaku untuk penambahan gudang dan inbound, dan data stok inbound akan dimasukan saat proses inbound. 2. Produk gabungan hanya dapat ditambahkan ke gudang, dan tidak dapat melakukan inbound. 3. Produk yang tidak perlu diikat dapat dihapus secara langsung. 4. Setelah diajukan, jika produk sudah ada di gudang, sistem akan otomatis memfilter produk tersebut, sehingga produk tidak diinbound lagi.",
    "differentWeightdimensionsForVariations": "Berat/Dimensi berbeda untuk tiap variasi",
    "becauseAllVariationsHaveExactlyThe": "Karena semua variasi memiliki Berat dan Dimensi yang sama persis, sistem akan secara otomatis bantu sesuaikan ke Berat dan Dimensi di tingkat produk",
    "mskuXWarehouseNameOrWarehouse": "MSKU {0}, Nama Gudang atau Kode Gudang tidak ada",
    "mskuDoNotExist": "MSKU {0} tidak ada di gudang {1}, harap tambahkan SKU ke gudang terlebih dahulu",
    "masterProductXDoesNotExist": "Master Produk {x} tidak ada",
    "theOutboundListHasBeenPushed": "Daftar Outbound telah dipush, dan pengeditan pesanan tidak lagi diizinkan",
    "orderXStatusDoesNotAllow": "Status Pesanan {0} tidak mengizinkan pengeditan",
    "orderXDoesNotExist": "Pesanan {0} tidak ada",
    "thePaymentAmountShouldBeLess": "Jumlah Pembayaran harus kurang dari Total Pesanan, silakan periksa",
    "theTotalAmountOfProductsExceeds": "Jumlah Total Produk melebihi batas",
    "theOrderNoIsDuplicatedPlease": "No. Pesanan terduplikasi, mohon ubah No. Pesanan",
    "theStoreCannotBeEmpty": "Toko tidak boleh kosong",
    "channelRequirementsXMustBeBetween": "Ketentuan Marketplace {x} harus antara {y}~{z}",
    "itemCount": "Jumlah Produk",
    "variationOptionLength": "Panjang Nama Opsi Variasi",
    "variationTypeLength": "Panjang Nama Tipe Variasi",
    "itemDescriptionLength": "Panjang Deskripsi Produk",
    "itemImageCount": "Jumlah Gambar Produk",
    "itemNameLength": "Panjang Nama Produk",
    "wholesalePriceThresholdPercentage": "Persentase Batas Harga Grosir",
    "sinceSomeProductsHaveTheSame": "Karena beberapa produk memiliki Berat dan Dimensi yang sama persis pada semua variasi, sistem akan secara otomatis bantu sesuaikan ke Berat dan Dimensi di tingkat produk",
    "toEnableDifferentWeightdimensionsForEach": "Untuk mengaktifkan Berat/Dimensi yang berbeda pada setiap variasi, mohon akses halaman edit satuan produk Shopee dan aktifkan tombol \"Berat/Dimensi berbeda untuk tiap variasi\"",
    "mskuHasBeenInbounded": "MSKU telah di inbound",
    "exportFailed": "Gagal Export",
    "staffToRemovePermissions": "Staf yang Izinnya akan Dihapus:",
    "staffsToRetainPermissions": "Staf yang Izinnya akan Dipertahankan:",
    "storesToDeactivate": "Toko yang Akan Dinonaktifkan:",
    "storesToRetain": "Toko yang Akan Dipertahankan:",
    "staffAccountsToContinueUsing": "Akun Staf yang akan Tetap Digunakan:",
    "storesToContinueBinding": "Toko yang akan tetap Diikat:",
    "pleaseConfirmTheStoreOrStaff": "Mohon konfirmasi Toko atau Staf yang ingin Anda pertahankan",
    "theDefaultPickupTimeLatest": "Waktu Pickup default adalah yang paling akhir",
    "theDefaultPickupTimeEarliest": "Waktu Pickup default adalah yang paling awal",
    "doNotAutomaticallySelectThePickup": "Tidak otomatis memilih Waktu Pickup",
    "setTo": "Atur ke:",
    "onlyApplicableToOrdersWithPickup": "Hanya berlaku untuk pesanan dengan layanan pickup di Shopee dan TikTok",
    "automaticallySelectThePickupTime": "Otomatis Memilih Waktu Pickup",
    "whenYouSelectLatest": "Ketika Anda memilih opsi ini, waktu pengambilan paling akhir yang tersedia akan diisi secara otomatis pada halaman pengiriman.",
    "whenYouSelectEarliest": "Ketika Anda memilih opsi ini, waktu pengambilan paling awal yang tersedia akan diisi secara otomatis pada halaman pengiriman.",
    "thisOrderIsFromAMultiwarehouse": "Pesanan ini berasal dari toko multi-gudang. Mengganti alamat mungkin akan menimbulkan biaya pengiriman tambahan. Apakah Anda yakin ingin memperbaruinya?",
    "noManualSyncTask": "Tidak Ada Tugas Sinkronisasi Manual",
    "taskIds": "Task ID",
    "manualSyncProduct": "Sinkronisasi Manual Produk",
    "manualSyncOrder": "Sinkronisasi Manual Pesanan",
    "syncHistoricalOrders": "Sinkronisasi Riwayat Pesanan",
    "sinceTiktokShopWillDelayReleasing": "Karena TikTok Shop akan menunda perilisan stok setelah pesanan dibatalkan, Ginee juga akan menunda perilisan Stok Terkunci selama 12 jam setelah pesanan yang dibatalkan disinkronkan ke Ginee (status akan ditampilkan sebagai \"Sedang Dirilis\").",
    "syncProgress": "Progress Sinkronisasi",
    "notAbnormal": "Bukan Abnormal",
    "reservationFailedTheBoundGineeWarehouse": "Reservasi gagal, gudang Ginee yang terikat tidak ditemukan (Toko Shopee CNSC/3PF mereserve Stok Promosi sesuai hubungan ikatan antara gudang toko dan gudang Ginee)",
    "discountType": "Jenis Diskon",
    "percentageOff": "Diskon Persentase",
    "fixedPrice": "Harga Tetap",
    "variationlevel": "Variation-level",
    "productlevel": "Product-level",
    "youWantToSwitchTheDatabase": "Apakah Anda yakin ingin mengubah database menjadi {x}?",
    "allSettingsAndMappingsWillBe": "Semua pengaturan dan pemetaan akan dihapus dan MSKU perlu dicocokkan ulang",
    "changeDatabase": "Ubah Database",
    "pleaseSelectTheDatabaseToChange": "Mohon pilih database baru",
    "ifYouSwitchToANew": "Jika Anda mengubah ke database baru, semua pengaturan dan pemetaan perlu dikonfigurasi ulang. Namun tidak perlu khawatir, data yang sudah di push ke Accurate tidak akan terhapus.",
    "mappingS": "Pemetaan",
    "channelStoreName": "Nama Toko Marketplace",
    "customersRealName": "Nama Asli Pelanggan",
    "productTaxSettings": "Pengaturan Pajak Produk",
    "whetherTheProductWillIncludeVat": "Apakah PPN sudah termasuk dalam Faktur Penjualan produk ini?",
    "noVat": "Tidak termasuk PPN",
    "addVat": "Termasuk PPN",
    "gineeWarehouseCanOnlyBeMapped": "Gudang Ginee hanya dapat diikat dengan 1 gudang Accurate, mohon menyelesaikan minimal 1 pemetaan gudang",
    "dataLinkedWithBranchesBanksAnd": "Data terhubung dengan cabang, bank, dan COA saat transaksi pesanan dikirim ke Accurate",
    "shippingFeeChargedToSellersCalculated": "Biaya Pengiriman Ditanggung Penjual dihitung berdasarkan Rincian Pelepasan Dana  Marketplace",
    "otherDeductedFeesProvidedByThe": "Biaya-biaya lain yang dipotong yang tercantum pada Rincian Pelepasan Dana Marketplace",
    "rebateAndDiscountAmountAsProvided": "Potongan Harga dan Jumlah Diskon yang tercantum pada Rincian Pelepasan Dana Marketplace",
    "sellerPromotionFeeAsProvided": "Biaya Promosi Ditanggung Penjual yang tercantum pada Rincian Pelepasan Dana Marketplace",
    "serviceFeeCommissionFeeAndOther": "Biaya Layanan, Biaya Komisi dan Biaya Lainnya yang dipotong oleh marketplace yang tercantum pada Rincian Pelepasan Dana Marketplace",
    "importantNotesErrorNotificationTheCumulative": "Catatan Penting: Notifikasi error \"Periode siklus pesanan tidak dapat melebihi 18 bulan\" saat pembelian Lazada-Otorisasi saja berarti bahwa Anda telah membeli 18 bulan \"Otorisasi saja\". Mohon {x}, lalu klik Layanan penggunaan untuk menyelesaikan proses integrasi",
    "historicalOrdersSettlementDataWillNot": "Data Pelepasan Dana untuk Riwayat Pesanan tidak akan di sinkronisasi",
    "variousServiceCharges": "Berbagai Biaya Layanan",
    "thePromotionPeriodMustBeLonger": "Periode promosi minimum adalah 10 menit dan maksimum 365 hari",
    "purchaseLimitCannotBeGreaterThan": "Batas Pembelian tidak boleh lebih besar dari Stok Tersedia",
    "fulfilledByChannel": "Pemenuhan oleh Marketplace",
    "jitPo": "JIT PO",
    "advanceFulfillment": "Advance Fulfillment",
    "notSpecial": "Tidak Spesial",
    "createdByShopee": "Dibuat oleh Shopee",
    "matchPending": "Menunggu Pencocokan",
    "discountPriceIsLowerThanM": "Harga Diskon dibawah {m}",
    "theDiscountRateMustNotbeHigher": "Diskon harus dibawah {m}%",
    "limitationMethod": "Metode Limitasi",
    "variationDimension": "Dimensi Variasi",
    "noLimit": "Tidak ada batas",
    "invalidDiscount": "Diskon tidak valid",
    "invalidPrice": "Harga tidak valid",
    "TheStartTimeMustBe": "1. Waktu mulai harus setelah waktu saat ini",
    "ThePromotionPeriodMustBe": "2. Periode promosi minimum adalah 10 menit dan maksimum 365 hari",
    "productId": "ID Produk",
    "variationId": "ID Variasi",
    "thePromotionsPeriodMustBeLonger": "Periode promosi harus lebih dari 1 jam",
    "thePromotionuPeriodMustBeLonger": "Periode promosi minimum adalah 10 menit dan maksimum 365 hari",
    "unavailableStockIntransit": "Stok Tidak Tersedia (Sedang Transit)",
    "stockTransferredToSortingCenterBy": "Stok terlebih dahulu ditransfer ke pusat sortir oleh Shopee",
    "advanceStock": "Advance Stock",
    "outboundQuantityMustBe": "Jumlah keluar harus >0",
    "needHelpWereHereForYou": "Butuh bantuan? Kami siap membantu Anda. Hubungi kami dari Senin sampai Jumat, pukul 9AM-6PM",
    "orderXIsMatchedWithShopee": "Pesanan [{0}] sesuai dengan Pesanan Advance Fulfillment Shopee [{1}], pesanan digabungkan",
    "bookingId": "Booking ID",
    "availStock": "Stok Tersedia",
    "notMatchedToOrder": "Tidak Dicocokkan dengan Pesanan",
    "importToCreateCombinedProduct": "Impor untuk Buat Produk Kombinasi",
    "youCanImportUpTo": "Anda dapat mengimpor hingga 10.000 data sekaligus (format: xlsx, xls) untuk membuat Produk Kombinasi secara massal",
    "matched": "Telah Dicocokkan",
    "marketplaceSpecialOrder": "Pesanan Spesial Marketplace"
};
});

var my = createCommonjsModule(function (module, exports) {
exports.__esModule = true;
exports.my = void 0;
exports.my = {
    "globalNoData": "Tidak Ada Data",
    "globalPleaseSelect": "Sila pilih",
    "globalPleaseEnter": "Masukkan",
    "globalsYearly": "Tahunan",
    "globalsMonthly": "Bulanan",
    "globalsWeekly": "Mingguan",
    "globalsYesterday": "Semalam",
    "globalsExportDisabled": "Tamat tempoh",
    "globalsExportFailed": "Muat turun telah digagal",
    "globalsExportFinished": "Aktif",
    "globalsExportINProcess": "Diproses",
    "globalsExportStatus": "Status",
    "globalsPackageExpired": "Pakej anda telah tamat tempoh",
    "globalsLater": "Kemudian",
    "globalsContactNow": "Sila Hubungi sekarang",
    "globalsContactUsPackage": "Sila hubungi kami untuk dapatkan maklumat dan semak harga pakej",
    "isRequired": "Bidang ini diperlukan",
    "globalsGoContinue": "Teruskan",
    "globalsBrowserBlockedInfo": "Window timbul baru dikesan oleh penyemak imbas,sila klik \"Continue\" untuk membuka halaman baru (anda juga dapat mencari \"Remove Browser Blocking Method/ Cara\"",
    "globalsNotification": "Pemberitahuan",
    "globalsPleaseSelect": "Sila tekan",
    "globalsIsRequired": "Bidang ini diperlukan",
    "globalsOperationGuide": "Panduan operasi",
    "globalsSyncError": "Penyegerakan telah digagal",
    "globalsSynchronizing": "Menyegerakkan",
    "globalsSync": "Segerakkan",
    "globalsSyncSuccess": "Penyegerakan selesai",
    "globalsNoProductYet": "Barang belum tersedia",
    "globalsSeeMore": "Lihat {lagi} barang",
    "globalsSeeMoreRetract": "Lihat lebih sedikit",
    "globalsSeeMoreExpand": "Lihat lebih banyak",
    "globalsNoName": "Tiada Nama",
    "globalsTotalPage": "Jumlah",
    "globalsReset": "Tetapkan semula",
    "globalsSearch": "Cari",
    "globalNoEmptyText": "Data tidak sah",
    "globalNoPermission": "Akaun anda tidak mempunyai kebenaran ini, sila hubungi pentadbir kedai untuk meminta atau menaik taraf",
    "globalAction": "Tindakan",
    "globalTaskResult": "Keputusan",
    "globalTaskFailedNumber": "Gagal",
    "globalTaskTotalNumber": "Jumlah",
    "globalTaskSuccessNumber": "Pencapaian",
    "globalTaskFailed": "Beberapa proses pesanan telah digagal, sila cuba sebentar lagi",
    "globalTaskSuccess": "Kejayaan Operasi",
    "globalTaskWait": "new",
    "globalStatus_unpublished": "Tidak diterbitkan",
    "globalStatus_update_failed": "Penerbitan Gagal",
    "globalStatus_delete": "Dipadamkan",
    "globalStatus_draft": "Draft",
    "globalStatus_disabled": "Tidak Aktif",
    "globalStatus_banned": "Dilarang",
    "globalStatus_sold_out": "Habis dijual",
    "globalStatus_live": "Aktif",
    "globalStatus_process": "Dalam proses",
    "globalStatus_all": "Semua",
    "globalName": "Global Name MY",
    "goToLinkStoreTip": "",
    "goToLinkStore": "",
    "remove": "Padam",
    "orderXxxFailedToPushWarehouse": "Pesanan xxx gagal menolak ke gudang, sebab: xxx",
    "orderXxxPushedToWarehouseSuccessfully": "Pesanan xxx berjaya ditolak ke gudang",
    "shippingToWarehouseOperationSucceeded": "Operasi penghantaran gudang berjaya",
    "shippingToWarehouseOperationIsSuccessful": "Operasi penghantaran gudang berjaya, sila tunggu dengan sabar untuk keputusan pemprosesan gudang",
    "currentlyTiktokOrdersAreNotSupported": "Pada masa ini, pesanan Tiktok tidak disokong untuk menghantar dan mencetak label penghantaran melalui OpenAPI.",
    "shopeeOrdersWithTheSameLogistics": "Pesanan Shopee/TikTok menyokong untuk menetapkan masa pengambilan secara besar-besaran untuk logistik yang sama",
    "gineeSupportsSettingUpAutomaticShipping": "Ginee menyokong untuk menetapkan kaedah penghantaran automatik untuk kedai Shopee/Bukalapak/Tokopedia/Akulaku/TikTok",
    "xxxStockDeductionProcessingEnableddisabled": "Pemprosesan stok kedai XXX berjaya diaktifkan/ditutupkan",
    "theEffectiveTimeOfXxx": "Masa berkesan pemprosesan stok kedai xxx ditukar daripada xxx kepada xxx",
    "pleaseOpenAtLeastOneStore": "Sila buka sekurang-kurangnya satu kedai",
    "thereIsAnUnfinishedOrderExport": "Terdapat tugas eksport pesanan yang belum selesai, sila pergi ke sejarah eksport untuk melihat rekod eksport, dan eksport semula selepas tugasan selesai",
    "theSystemServiceIsAbnormalPlease": "Perkhidmatan sistem tidak normal, sila muat semula halaman dan cuba lagi",
    "afterTurnOffTheOrderWill": "Selepas ditutup, pesanan akan diperuntukkan ke gudang dengan mengikuti keutamaan peraturan penghantaran gudang",
    "pleaseConfirmWhetherWantToPush": "Sila pastikan adakah anda ingin menolak gudang pihak ketiga untuk penghantaran?",
    "pleaseConfirmWhetherWantToRedistribute": "Sila pastikan adakah anda ingin mengedarkan stok semula?",
    "outboundOrder": "Nombor Keluaran Stok",
    "splitOrder": "Asingkan Pesanan",
    "abnormalDescription": "Penerangan Tidak Normal",
    "problemOrder": "Pesanan yang mempunyai masalah",
    "redistribute": "Padan stok semula",
    "deliveryWarehouse": "Gudang Penghantaran",
    "nonindonesiaOrdersDoNotSupportXxx": "Pesanan bukan Indonesia tidak menyokong penghantaran gudang xxx, sila laraskan gudang penghantaran",
    "theOrderDoesNotSupportDelivery": "Pesanan tidak menyokong penghantaran gudang xxx, sila laraskan gudang penghantaran",
    "theAvailableStockOfMskuXxx": "Stok tersedia MSKU xxx tidak mencukupi, sila edarkan stok semula selepas mengisi semula stok",
    "mskuXxxIsNotAssociatedWith": "MSKU xxx tidak dikaitkan dengan gudang xxx, sila tambahkan perhubungan persatuan dan edarkan semula barang tersebut",
    "channelSkuXxxIsNotBound": "Saluran SKU xxx belum mengikat dengan MSKU, sila tambahkan hubungan pemetaan dan edarkan stok semula",
    "channelSkuXxxDoesNotHave": "Gudang belum diberikan untuk saluran SKU xxx, sila tambah peraturan penghantaran gudang dan edarkan semula barangan",
    "failedCreateOutboundOrder": "Pembuatan senarai keluaran stok gagal",
    "warehouseAllocationError": "Gudang salah diberikan",
    "mskuNotAssociatedWithWarehouse": "MSKU belum dikaitkan dengan gudang",
    "mskuNotMatched": "MSKU belum dipadankan",
    "unallocatedWarehouse": "Gudang belum diberikan",
    "pushTheThirdPartyWarehouse": "Tolak ke gudang pihak ketiga",
    "paidOrder": "Bayaran",
    "waitingPaymentOrder": "Memesan",
    "alreadyInbound": "Telah dimasukkan ke gudang",
    "pleaseConfirmWhetherItIsAutomatically": "Sila pastikan sama ada pesanan yang dikembalikan akan dimasukkan ke dalam gudang secara automatik?",
    "inboundReturnProduct": "Kemasukan stok yang dikembali",
    "abnormalType": "Jenis Tidak Normal",
    "printTime": "Masa Cetak",
    "warehouseoutboundOrder": "Gudang/Nombor Keluaran Stok",
    "variantsku": "Variasi/SKU",
    "afterOpeningTheOrderWillBe": "Selepas diaktif, pesanan akan ditolak secara automatik ke gudang pihak ketiga, jika pesanan itu memenuhi syarat penghantaran.",
    "automaticallyPushToThirdpartyWarehouse": "Tolak ke gudang pihak ketiga secara automatik",
    "inventoryVerificationAfterItIsTurned": "Pengesahan Stok: Selepas diaktifkan, akan mengesahkan sama ada stok yang tersedia di gudang adalah mencukupi, dan memberi keutamaan kepada gudang dengan stok yang mencukupi untuk penghantaran.",
    "availableStockDeliveryDisabledSuccessfully": "Tetapan hantar dahulu dari gudang yang mempuyai stok berjaya ditutup",
    "availableStockDeliveryActivatedSuccessfully": "Tetapan hantar dahulu dari gudang yang mempuyai stok berjaya diaktifkan",
    "shippingWarehouseSettingsDisabledSuccessfully": "Tetapan Penghantaran Gudang berjaya ditutupkan",
    "shippingWarehouseSettingsActivatedSuccessfully": "Tetapan Penghantaran Gudang berjaya diaktifkan",
    "orderstockDeductionProcessDisabledSuccessfully": "Pemprosesan stok berkaitan pesanan berjaya ditutup",
    "theStockDeductionProcessingOfXxx": "Pemprosesan stok kedai xxx berjaya diaktifkan/ditutupkan, dan masa berkesan ditukar daripada xxx kepada xxx",
    "orderstockDeductionProcessSuccessfullyActivated": "Pemprosesan stok berkaitan pesanan berjaya diaktifkan",
    "afterActivatedOrdersWillBeAssigned": "Selepas diaktifkan, pesanan akan dipadankan dengan gudang mengikut produk yang berbeza, dan pesanan yang sama mungkin diberikan kepada gudang yang berbeza untuk penghantaran; jika tidak, pesanan akan diberikan kepada gudang yang sama untuk penghantaran",
    "shipmentWarehouseSettingsDeterminesWhetherWarehouse": "Tetapan Penghantaran Gudang: Tentukan sama ada padanan gudang ialah dimensi pesanan atau dimensi produk",
    "effectiveTime": "Masa yang berkesan",
    "activateStatus": "Keadaan Pembukaan",
    "activateRange": "Julat Pembukaan",
    "onceTurnOffAllStoreOrders": "Setelah ditutup, semua pesanan kedai tidak akan memproses stok",
    "afterActivatedTheOrderWillGenerate": "Selepas diaktifkan, pesanan akan menjana senarai keluaran stok untuk menolak stok dengan mengikuti proses pemprosesan; jika tidak, stok tidak akan diproses apabila pesanan dihantar",
    "orderstockDeductionProcess": "Pemprosesan stok yang berkaitan dengan pesanan",
    "orderCreatedSuccessfully": "Pesanan manual berjaya dibuat",
    "shipmentWarning": "Amaran Penghantaran",
    "withoutUnboxingVideosComplaintsOfLessdamage": "Jika tiada video unboxing, tidak akan terima aduan tentang barang hilang/rosak",
    "shippingRebate": "Rebat Penghantaran",
    "shippingFeePaidByBuyer": "Yuran penghantaran yang dibayar oleh pembeli",
    "operationIsTooFrequentPleaseExport": "Operasi terlalu kerap, sila eksport semula selepas 1 minit",
    "rdPartyLogisticsShippingFee": "Yuran Penghantaran Logistik Pihak Ketiga",
    "shippingSubtotal": "Subjumlah Penghantaran",
    "importFailedInternalSystemErrorPlease": "Import gagal: masalah sistem dalaman, sila import semula",
    "emailFormatIsIncorrect": "Format e-mel tidak betul",
    "supportsNumberslettersMaxCharacters": "Menyokong nombor. huruf, dan \"-\". Maks. 30 aksara",
    "supportsNumberslettersspacesMaxCharacters": "Hanya menyokong untuk memasukan nombor/huruf/ruang/-, tidak boleh melebihi daripada 20 perkataan",
    "basedOnProductSeparateRowFor": "Eksport mengikut produk (pesanan yang mempunyai berbilang produk dipaparkan secara berasingan)",
    "basedOnOrderOrderWithMultiple": "Eksport mengikut pesanan (pesanan yang mempunyai berbilang produk dipaparkan secara bergabungan)",
    "exportMethod": "Kaedah Eksport",
    "pleaseConfirmWhetherTheOrderStatus": "Sila pastikan anda ingin menetapkan keadaan pesanan sebagai Dikembalikan?",
    "editOrder": "Ubah pesanan",
    "insufficientStockOfXxxPleaseMake": "Stok xxx tidak mencukupi, sila lengkapkan stok sebelum menghantar bungkusan",
    "paymentProblem": "Masalah pembayaran",
    "invalidOrder": "Pesanan Tidak Sah",
    "moveToReturned": "Pindah ke Dikembalikan",
    "selectCustomer": "Pilih pelanggan",
    "CustomerMobile": "Nombor Telefon",
    "fyiPleaseDeleteWhenUploading": "Untuk rujukan, sila padam semasa memuat naik",
    "pleaseDeleteThisTextBoxBefore": "Sila padamkan kotak teks ini sebelum mengimport",
    "fillInWithTwocharacterShortCode": "Isi dengan kod singkat dua perkataan untuk negara, dan mengisi kaedah prabayar/kaedah pembayaran untuk kaedah pembayaran. Jumlah pembayaran tidak boleh besar daripada jumlah harga pesanan.",
    "multipleMskusunitPricesquantitiesInTheSame": "Berbilang MSKU/harga seunit/bilangan dalam susunan yang sama mesti dimasukkan pada baris baru, harga unit mestilah tidak kurang daripada 0, dan bilangan mestilah lebih besar daripada 0; apabila memasukkan berbilang baris, maklumat pesanan yang terlebih boleh dikosongkan",
    "orderNumberOnlySupportsLettersNumbers": "Nombor pesanan hanya menyokong huruf, nombor, - /",
    "fieldWithMarkAreRequired": "Ruang dengan tanda * perlu diisi",
    "pleaseEditInAccordanceWithThe": "Sila mengubah dengan mengikut susunan templat",
    "importantNotice": "Pemberitahuan Penting",
    "theNumberOfDataRecordsCannot": "Bilangan rekod data tidak boleh melebihi 5000",
    "pleaseImportWithDownloadedFormatTemplate": "Sila import dalam format templat yang dimuat turun, pengepala tidak boleh diubah",
    "onlySupportFilesInXlsxFormat": "Hanya boleh mengimport fail dalam format xlsx (mengubah suai akhiran fail secara manual adalah tidak sah)",
    "downloadImportTemplate": "Muat turun templat import",
    "importOrder": "Import Pesanan",
    "uploadPaymentProof": "Muat Naik Bukti Pembayaran",
    "paymentProof": "Bukti Pembayaran",
    "receivingAccount": "Akaun Penerimaan",
    "paymentAccount": "Akaun Pembayaran",
    "receiptNumber": "Nombor Resit",
    "theTotalPaymentAmountCannotExceed": "Jumlah pembayaran tidak boleh melebihi jumlah harga pesanan",
    "addPaymentRecord": "Tambah Rekod Pembayaran",
    "refer": "Rujukan",
    "useCommaToSeparateMultipleItem": "Gunakan koma untuk memisahkan berbilang produk",
    "saveContinueAdd": "Simpan dan teruskan menambah",
    "save": "Simpan",
    "paymentSerialNumber": "Nombor Siri Pembayaran",
    "paymentAmount": "Jumlah Pembayaran",
    "productTotalAmount": "Jumlah Harga Produk",
    "totalQuantity": "Jumlah Bilangan",
    "totalPrice": "Jumlah Harga",
    "addProduct": "Tambah Produk",
    "recipientArea": "Kawasan Penerima",
    "remarks": "Catatan",
    "paymentInfo": "Maklumat Pembayaran",
    "amountInfo": "Maklumat Harga",
    "productInfo": "Maklumat Produk",
    "receiptInfo": "Maklumat Penerimaan",
    "basicInfo": "Maklumat Asas",
    "referenceOrder": "Pesanan Rujukan",
    "canBeEnteredQuicklyByImporting": "Boleh dimasukkan dengan cepat dengan mengimport maklumat yang sedia ada",
    "addOrder": "Tambah Pesanan",
    "bindStoreNow": "",
    "bindTips": "",
    "PlatformDiscount": "Diskaun Platform",
    "TotalDiscount": "Jumlah Diskaun",
    "voidTipss": "Pesanan yang dipindah ke ditangguh tidak lagi menyokong sebarang operasi pesanan dalam sistem",
    "voidTips": "Apabila pesanan telah ditangguhkan, pesanan tersebut tidak akan mengambil bahagian dalam kunci/potongan stok lagi, anda harus memulihkan pesanan sebelum stok dapat dikunci/dipotong seperti biasa. Sila beroperasi dengan berhati-hati.",
    "pickingList": "",
    "printedBy": "",
    "supportTikiAndZaloraOrdersToComplete": "Sokong pesanan Tiki dan Zalora untuk melengkapkan resit kumpulan, untuk pesanan Blibli, sila pergi ke perincian pesanan untuk beroperasi",
    "onlyLazadaBlibiliAndZaloraSupportPackaging": "Hanya pesanan Lazada, Blibli dan Zalora menyokong pembungkusan, dan pesanan yang telah dibungkus tidak sokong pembungkusan lagi, jika ingin mengubah bilangan pesanan Blibli, sila kemas kini dalam perinician pesanan",
    "confirmWhetherToSetTheOrderStatusAsShipped": "Sila pastikan adakah status pesanan ditetap sebagai dihantar",
    "markAsShipped": "Tanda sebagai dihantar",
    "whetherToUseGineeTemplateToPrintInvoices": "Adakah ingin menggunakan templat Ginee untuk mencetak invois",
    "buyerPayment": "Jumlah Pembayaran Pembeli",
    "doAllProductInTheSameOrder": "Adakah semua produk dalam pesanan yang sama akan menggunakan nombor invois yang sama",
    "clickHereToSetting": "Klik tetapan ini",
    "operationLog": "",
    "massSetPickupTime": "",
    "numberOfFails": "",
    "totalNumber": "",
    "invoiceNumberSettingFailureDetails": "",
    "invoiceNumberIsSetSuccessfully": "",
    "clickHereToSetWhetherAll": "",
    "setInvoiceNumber": "",
    "printShippingLabelAndPickingLists": "",
    "pleaseConfirmWhetherTheReceiptIs": "",
    "onlyTikiOrdersCanBeReceived": "",
    "malaysiaTemplates": "",
    "generalTemplates": "",
    "unassignedWarehouse": "",
    "jikaPesananTelahDikaitkanDenganGudang": "",
    "inOrderToEnsureTheNormal": "",
    "warehouse": "Gudang",
    "unpairedMasterSKU": "",
    "postalCode": "",
    "detailsAddress": "",
    "street": "",
    "districts": "",
    "city": "",
    "province": "",
    "senderPhone": "",
    "senderName": "",
    "dataLoadingPleaseWaitOrClick": "",
    "activatedAllOrdersFromTheAuthorized": "",
    "restrictionShippingOfOrdersMustBe": "",
    "shippingDiscountSeller": "",
    "feeDiscountFromPlShipping": "",
    "taxEscrow": "",
    "transactionFeeBuyer": "",
    "transactionFeeSeller": "",
    "SetPackStatusTitle": "",
    "SetPackStatusInMass": "",
    "timeUpdate": "",
    "timeCreation": "",
    "contentOperation": "",
    "moduleOperation": "",
    "recordOperation": "",
    "orderRestore": "",
    "inToAlreadyShippedMove1": "",
    "warehouseThirdparty": "",
    "warehouseOwn1": "",
    "inToAlreadyShippedMove": "",
    "inToBeCollectedMove": "",
    "numberEmptyAwb": "",
    "printedNot": "",
    "youWantToOperateSeparatelyPlease": "",
    "ordersWillBeDirectlyPackagedAnd": "",
    "processingTheTokopediabukalapakOrderWillBe": "",
    "youSureToProcessTheSelected": "",
    "failedCancel": "",
    "pushToThirdpartyWarehouseFailed": "",
    "toPendingMove": "",
    "asPriorityMark": "",
    "orderOutOfStockAll": "",
    "orderOutOfStockSelected": "",
    "stockCheck": "",
    "skuXxxxIsInsufficientInThe": "",
    "skuXxxxDoesNotExistIn": "",
    "warehouseToShipmentPush": "",
    "orderProcessing": "",
    "processingFailed": "",
    "ofStockOut": "",
    "warehouseOwn": "",
    "failedOrdersView": "",
    "failed": "",
    "success": "",
    "total": "",
    "descriptionXxOrdersHaveBeenSynchronized": "",
    "pleaseWaitSyncing": "",
    "result": "",
    "reasonFailed": "",
    "exceptionSynchronization": "",
    "otherLogisticsProviderNumberEmptyPlaceholder": "",
    "theCheckIsRemovedTheIntegrated": "",
    "selectedTheIntegratedMasterProductStock": "",
    "ifNoRuleIsAddedAfter": "",
    "forTheProductStockOfThe": "",
    "theReasonForCancellationIs1": "",
    "theReasonForCancellationIs": "",
    "operationContent": "",
    "delete": "",
    "updateTime": "",
    "createTime": "",
    "cancelReason": "",
    "operator": "",
    "pleaseSelectStore": "",
    "pleasesSelectChannel": "",
    "no": "",
    "yes": "",
    "updateWarehouseStock": "",
    "unknownReason": "",
    "buyerCanceled": "",
    "systemCancel": "",
    "cancelSetting": "",
    "kodePos": "poskod",
    "nameFirst": "Nama pertama",
    "nameLast": "Nama terakhir",
    "staff": "Kakitangan",
    "secretary": "Setiausaha",
    "receptionist": "Penyambut Tetamu",
    "security": "Keselamatan",
    "driver": "Pemandu",
    "helperDomestic": "Pembantu domestik",
    "relationship": "Perhubungan",
    "mrmrsms": "Tuan / Puan / Cik",
    "spouse": "Pasangan suami isteri",
    "related": "Berkaitan",
    "lessThanCharactersRequired": "Diperlukan, kurang daripada 30 aksara",
    "codeSettlement": "",
    "statusReceiver": "Status Penerima",
    "nameReceiver": "Nama Penerima",
    "productDateReceived": "Tarikh Produk Diterima",
    "pleaseCompleteTheFollowingInformationAnd": "Petua: Sila lengkapkan maklumat berikut dan kemas kini pesanan ke status selesai",
    "completedOrder": "Pesanan Selesai",
    "cancel": "membatalkan",
    "noMoreThanCharactersRequired1": "Diperlukan, tidak lebih daripada 300 perkataan",
    "ofThePerformanceReasonsPart": "Sebahagian daripada Sebab Prestasi",
    "quantityFinal": "Kuantiti Akhir",
    "ifTheCurrentOrderOnlySupports": "Petua: Sekiranya pesanan semasa hanya menyokong pemenuhan sebahagian, sila kemas kini kuantiti produk. Sekiranya kuantiti semua produk dalam pesanan semasa dikemas kini menjadi 0, pesanan akan dibatalkan",
    "quantityChange": "Tukar kuantiti",
    "endTimeMustBeGreaterThan": "Waktu tamat mestilah lebih besar daripada waktu mula",
    "noMoreThanCharactersRequired": "Diperlukan, tidak lebih daripada 300 aksara",
    "fieldsRequired": "Ruangan wajib",
    "installationnote": "Nota Pemasangan",
    "installationmobile": "PemasanganMobile",
    "installationofficer": "PemasanganOfficer",
    "endDateInstallation": "Tarikh Akhir Pemasangan",
    "startDateInstallation": "Tarikh Mula Pemasangan",
    "endDateShipping": "Tarikh Akhir Penghantaran",
    "startDateShipping": "Tarikh Mula Penghantaran",
    "nameCourrier": "Nama Pembawa",
    "toPackSomeOrdersPleaseTry": "Gagal mengemas beberapa pesanan. Sila cuba lagi",
    "successfullyPackaged": "Dikemas dengan jayanya",
    "pleaseWaitPacking": "Pembungkusan, Tunggu",
    "quantity": "Kuantiti",
    "stockAvailablelocked": "Stok Ada / Berkunci",
    "skuwarehouseMaster": "Master SKU / Gudang",
    "nameProduct": "Nama Produk",
    "numberOrder": "Nombor Pesanan",
    "onlyLazadaAndBlibliOrdersSupport": "Petua: Hanya pesanan Lazada dan Blibli yang menyokong pembungkusan, dan pesanan yang dibungkus tidak menyokong pembungkusan sekunder. Sekiranya anda perlu mengubah kuantiti pesanan Blibli, sila kemas kini butiran pesanan",
    "theProductsOfTheSameOrder": "Kemas produk dengan pesanan yang sama di Blibli (terhad kepada Produk Biasa)",
    "pack": "Pek",
    "stockAvailable": "Stok ada",
    "serviceProviderFulfillment": "Penyedia Perkhidmatan Pemenuhan",
    "skuMaster": "SKU Induk",
    "nameWarehouse": "Nama Gudang",
    "serviceProvider": "",
    "theMasterSkuReplacing": "Menggantikan SKU induk",
    "withXxxxxxPaired": "更新绑定关系",
    "reasonReturn": "Sebab Kembali",
    "itemsRestock": "Barangan Restock",
    "amountRefund": "Amaun Bayaran Balik",
    "shippingReturn": "Penghantaran Balik",
    "quantityReturn": "Pulangan Kuantiti",
    "totalRefundable": "Jumlah dikembalikan",
    "sendNotificationsToCustomers": "Hantarkan pemberitahuan kepada pelanggan",
    "totalRefund": "Jumlah Bayaran Balik",
    "productRefund": "Bayaran Balik Produk",
    "ifYouChooseToIncreaseStock": "Petua: Sekiranya anda memilih untuk menambah stok, stok produk dengan kuantiti yang dikembalikan akan meningkat",
    "refund": "Bayaran balik",
    "buyerInfoEdit": "Edit Maklumat pembeli",
    "location": "Lokasi",
    "other": "Yang lain",
    "declinedPayment": "Pembayaran ditolak",
    "orderFraudulent": "Pesanan palsu",
    "unavailableItems": "Item tidak tersedia",
    "changedcanceledOrderCustomer": "Pelanggan menukar / membatalkan pesanan",
    "CreditCardTransactionFee": "Bayaran Troli Kredit",
    "moreThan5": "Lebih dari 5 hari",
    "fiveDays": "Dalam 5 hari",
    "threeDays": "Dalam 3 hari",
    "twoDays": "Dalam 2 hari",
    "oneDays": "Dalam 1 hari",
    "noNotes": "Tiada Catatan",
    "hasNotes": "Mempunyai Catatan",
    "noMessage": "Tiada Mesej",
    "hasMessage": "Mempunyai Mesej",
    "multipleProduct": "Pelbagai Produk",
    "oneProductWithMoreThanOneQty": "1 Produk dengan lebih daripada 1 Kuantiti",
    "singleProduct": "Produk Tunggal",
    "jdTip": "Pemberitahuan: Terpengaruh oleh Channel API, Kedai JD.ID yang disatukan mulai 29 April 2021 akan mengalami pesanan yang tidak dapat diselaraskan, Pasukan JD.ID kini sedang aktif memperbaiki ini, maaf atas kesulitan yang berlaku",
    "orderIdSearchTips": "Gunakan \",\" untuk memisahkan nombor pesanan",
    "lastSync": "Penyegerakan Terakhir",
    "sortBy": "Disusun mengikut",
    "asc3": "Menaik",
    "desc2": "Menurun",
    "desc4": "Menurun",
    "asc2": "Menaik",
    "desc": "Menurun",
    "asc": "Menaik",
    "zToA": "(Z-A)",
    "aToZ": "(A-Z)",
    "payTime": "Masa Pembayaran",
    "deliverDeadline": "Tarikh Akhir Penghantaran",
    "productSku": "SKU Produk",
    "failnumber": "Nombor Gagal",
    "sucessnumber": "Nombor Kejayaan",
    "totalnumber": "Jumlah nombor",
    "lazadatip": "Mungkin terdapat kelewatan penyegerakan pesanan semasa kempen Lazada (26 Mac hingga 31 Mac), Dan semasa sekatan API Lazada, anda tidak dapat mengedit maklumat produk (termasuk inventori dan harga) atau menerbitkan produk baru melalui Ginee. Kami mohon maaf atas kesulitan",
    "keyword": "kata kunci",
    "logisticsProviderNumberEmptyPlaceholder": "Shopee belum menghasilkan nombor penghantaran, sila segerakkan kemudian",
    "logisticsProviderNameEmptyPlaceholder": "Shopee belum memberikan logistik, sila segerakkan kemudian",
    "PrintPdfErrorMessage": "Penjanaan fail gagal, sila cuba sebentar lagi",
    "orderDetailNoteLimit": "kurang daripada 500 perkataan",
    "GoContinue": "Teruskan",
    "BrowserBlockedInfo": "Ia dikesan bahawa tetingkap pop timbul baru telah disekat oleh penyemak imbas, sila klik \"Lanjutkan\" untuk membuka tetingkap halaman baru (anda juga dapat mencari \"Hapus Kaedah Penyekat Penyemak Imbas\" di Pusat Bantuan untuk membuka blokirnya)",
    "Notification": "Pemberitahuan",
    "ShowLabelProductInfo": "(Bukalapak)Label memaparkan maklumat produk",
    "VoucherPlatform": "Baucar Saluran",
    "PriceCreditCardServiceFee": "Bayaran Troli Kredit",
    "PriceSellerReturnRefundAmount": "Amaun Bayaran Balik",
    "PriceCoinCashBack": "Syiling Pulangan Tunai Penjual",
    "PriceCoin": "Syiling",
    "AllStatus": "Semua Status",
    "AllType": "Semua Jenis",
    "theMarketplacePromotionPeriodPleaseUnderstand": "",
    "pendingTableTips": "Proses Pesanan hanya tersedia untuk Shopee / Bukalapak / Tokopedia / Lazada / JD / Shopify",
    "Filter": "Tapis",
    "InvoiceSettings": "Tetapan Invois",
    "MultipleProduct": "Pelbagai Produk",
    "OneProductOneQty": "1 Produk dengan lebih daripada 1 Kuantiti",
    "paidTime": "Masa Dibayar",
    "packageStatus": "Status Pakej",
    "shipBefore": "Hantar Sebelum",
    "sellerNote": "Nota Penjual",
    "buyerNote": "Nota Pembeli",
    "packageType": "Jenis Pakej",
    "SingleProduct": "Produk Tunggal",
    "orderProductType": "jenis produk",
    "ViewFailedList": "Lihat Senarai Gagal",
    "AWBTrackingNumber2": "AWB / Invois",
    "PickUpTime": "Masa Pengambilan",
    "NoEmptyText": "Tiada data",
    "OrdersFailedTips": "Sebilangan pesanan gagal dihantar, sila cuba sebentar lagi",
    "SuccessfullyShipped": "Berjaya Dihantar",
    "TotalOrdersShipped": "Jumlah pesanan yang akan dihantar:",
    "ShippingWait": "Penghantaran, Sila tunggu ...",
    "action": "Tindakan",
    "SeeMoreRetract": "Lihat lebih sedikit",
    "FailedReason": "Sebab Gagal",
    "RecipientMobile": "Nombor telefon bimbit Penerima",
    "SetNow": "Tetapkan Sekarang",
    "SkipNext": "Langkau, Langkah Seterusnya",
    "shippingTips3": "",
    "shippingTips2": "Sekiranya anda belum menetapkannya, sistem akan secara automatik menetapkan kaedah penghantaran ke Pickup sebagai keutamaan atau lain-lain Dropoff atau Penghantaran Manual.",
    "shippingTips1": "Sebahagian dari Logistik tidak mempunyai kaedah penghantaran, sila lengkapkan tetapan Kaedah Penghantaran terlebih dahulu sebelum mengatur penghantaran",
    "FilterOrder": "Pesanan Penapis",
    "setAddress": "Belum ada alamat, sila ke Pusat Penjual untuk menetapkan",
    "ArrangeShipment2": "Susun Penghantaran",
    "MassUpload": "Muat Naik Massa",
    "ShippingCarrier": "Pembawa Penghantaran",
    "OrderQuantity": "Kuantiti pesanan",
    "DownloadTemplate": "Muat turun Templat",
    "FileSizeLimitTips": "Hanya menyokong fail xlsx / xls yang lebih kecil daripada 5M",
    "ImportFile": "Import Fail",
    "FileFormatInvalid": "Format fail import tidak betul",
    "ProcessFailed": "Proses Gagal",
    "ToBeDelivered": "Akan dihantar",
    "ToBeAccepted": "Untuk diterima",
    "PickUpAddress": "Alamat Pengambilan",
    "axiosTips": "Petua",
    "moreMaxLength": "Maks. aksara {maksimum panjang}",
    "PleaseSelect": "Sila Pilih",
    "SyncError": "Penyegerakan gagal",
    "syncSuccess": "Segerakkan",
    "Synchronizing": "Menyegerakkan",
    "reset": "Tetapkan semula",
    "search": "Cari",
    "status_failed": "Gagal",
    "status_returned": "Dikembalikan",
    "status_cancelled": "Dibatalkan",
    "status_delivered": "Dihantar",
    "status_shipping": "penghantaran",
    "status_paid": "Dibayar",
    "status_pending_payment": "Pembayaran Belum Selesai",
    "CancellingProcess": "Membatalkan",
    "Delete": "Padam",
    "Unpicked": "Tidak terpilih",
    "Picked": "Dipilih",
    "ShippingMethodLink": "tetapkan sekarang",
    "ExportDisabled": "Tamat tempoh",
    "ExportFailed": "Muat turun gagal",
    "ExportFinished": "Aktif",
    "ExportINProcess": "Memproses",
    "ExportStatus": "Status",
    "AddTemplate": "Tambah Templat",
    "ExportFiledReason": "Sebab difailkan:",
    "FileSaveHistoryDays": "Fail akan dipaparkan dalam senarai sejarah eksport, dan hanya akan disimpan selama 15 hari, sila klik untuk memuat turun dalam masa",
    "ExportFileFailed": "Penjanaan fail gagal, sila cuba sebentar lagi",
    "ExportFileGenerated": "Fail telah dihasilkan, sila klik untuk memuat turun",
    "ExportFileInProcess": "Menjana Fail, Tunggu",
    "TokoOrderApiUnstable": "",
    "ExportResultTitle": "Keputusan",
    "OrderStatus": "Status pesanan",
    "ExportDownload": "Muat turun",
    "ExportAllOrders": "Eksport Semua",
    "ExportBySelected": "Eksport mengikut pilihan",
    "Export": "Eksport",
    "OtherInformation": "Maklumat lain",
    "AmountInformation": "Jumlah Maklumat",
    "LogisticsInformation": "Maklumat logistik",
    "ProductInformation": "informasi produk",
    "BuyerInformation": "Maklumat Pembeli",
    "ExportSelectedDate": "Masa Terpilih",
    "ExportSelectedTemplate": "Templat Terpilih",
    "ExportOrder": "Pesanan Eksport",
    "ExportCreator": "Pencipta",
    "ExportContent": "Kandungan",
    "ExportTime": "Masa Eksport",
    "ExportHistory": "Sejarah Eksport",
    "TaxationFee": "Cukai",
    "CancelNoteText": "Batal Sebab",
    "FinalShippingFee": "Bayaran Penghantaran Dibayar oleh Sistem",
    "PickingListStatus": "Status Senarai Memilih",
    "PackingListStatus": "Status Senarai Pembungkusan",
    "InvoiceStatus": "Status Invois",
    "LabelStatus": "Status Label",
    "AfterClickPrint": "Setelah mengklik cetak, pesanan dalam senarai di bawah akan ditandakan sebagai dicetak",
    "SetToPrintedMass": "Tetapkan ke Dicetak (Mass)",
    "SetPrintStatusTips": "Petua: Setelah diset ke dicetak, tidak lagi disokong untuk mengubah status pencetakan, harap maklum.",
    "SetPrintStatus": "Tetapkan status cetakan",
    "SetStatus": "Tetapkan Status",
    "PickStatus": "Memilih Status",
    "PrintStatus": "Status Cetakan",
    "DiscountedTotalPrice": "Harga diskaun",
    "InvoiceAlreadyPrinted": "Invois Dicetak",
    "InvoiceNotPrinted": "Invois Tidak dicetak",
    "PackingListAlreadyPrinted": "Senarai Pembungkusan Dicetak",
    "PackingListNotPrinted": "Senarai Pembungkusan Tidak dicetak",
    "PickingListAlreadyPrinted": "Senarai Pilihan Dicetak",
    "PickingListNotPrinted": "Senarai Pilihan Tidak dicetak",
    "LabelAlreadyPrinted": "Label Dicetak",
    "LabelNotPrinted": "Label Tidak dicetak",
    "NotPrinted": "Tidak dicetak",
    "AlreadyPrinted": "Bercetak",
    "Rebate": "Rebat",
    "NewBuyerNote": "Nota Pembeli",
    "OrderTotal": "jumlah pesanan",
    "BuyPhone": "Telefon",
    "ProductInfo": "Maklumat Produk",
    "SellerNote": "Nota Penjual",
    "NoContentYet": "Belum ada kandungan",
    "OnlyVisibleToPicking": "Hanya Boleh Dilihat dalam Senarai Pilihan",
    "OnlyVisibleToSellers": "Hanya dapat dilihat oleh penjual",
    "CurrentlyNotSupportedShipping": "Pada masa ini tidak disokong untuk melihat status penghantaran",
    "AWB": "AWB",
    "Voucher": "Baucar",
    "ServiceFee": "Bayaran perkhidmatan",
    "CommissionFee": "Bayaran Komisen",
    "UnstableLazadaAPI": "Kerana Lazada API yang tidak stabil, baru-baru ini, beberapa pesanan kedai tidak dapat diselaraskan sepenuhnya. Lazada kini membaikinya. Sekiranya anda menghadapi masalah ini, pergi ke Pusat Penjual untuk memproses pesanan.",
    "HideProductInfo": "Sembunyikan maklumat produk",
    "ShippingWeight": "Berat",
    "ShippingSender": "Penghantar",
    "ShippingRecipient": "Penerima",
    "BatchLabelInfo": "Setelah mengklik cetak, pesanan dalam senarai di bawah akan ditandakan sebagai dicetak",
    "PrintBatchLabel": "Label Cetakan Massa",
    "ViewAllProduct": "Lihat semua produk {productCount}",
    "ShipBefore": "Hantar Sebelum",
    "PackFailedInfo": "Pembungkusan Gagal, Segarkan atau Segerakkan dan cuba lagi",
    "PleaseSelectProduct": "Sila pilih produk",
    "SkipPrint": "Langkau & Cetak",
    "SomeOrderInvoiceEmpty": "Beberapa nombor invois pesanan kosong, tidak dapat dicetak, sila lengkapkan",
    "SearchPlaceholder": "Sila masukan kandungan",
    "Unpacked": "Tidak berkemas",
    "PackingStatus": "Status Pembungkusan",
    "ReceiverName": "Nama Penerima",
    "BuyerEmail": "E-mel Pembeli",
    "BuyerContact": "Kenalan Pembeli",
    "BuyerName": "Nama Pembeli",
    "TrackIngNumber": "Nombor pengesanan",
    "InvortySKU": "MSKU",
    "ParentSKU": "SKU Induk",
    "ItemName": "Nama Produk",
    "productName": "Nama Produk",
    "OrdersID": "ID Pesanan",
    "Keywords": "Kata kunci",
    "OrderTypeNormal": "Biasa",
    "OrderTypePreOrder": "Pra-pesanan",
    "AllOrderType": "Semua Jenis Pesanan",
    "PaymentPrePaid": "Prabayar",
    "AllPayment": "Semua Bayaran",
    "LdProductStatusInfo": "Status Produk dalam pesanan tidak konsisten, sila periksa Detail Pesanan.",
    "PackingFailedInfo": "Pembungkusan Gagal, Sila Segarkan Halaman dan Cuba Lagi.",
    "BillingAddress": "Alamat Pengebilan",
    "InvoiceNumber": "Nombor invois",
    "ProductsItemPack": "Semua item yang dibungkus dalam satu Pesanan tidak boleh dikemas semula",
    "OnlyUnPackagedOrders": "Hanya untuk pesanan yang tidak dibungkus",
    "AllProductsPacked": "Semua item dalam satu Pesanan menggunakan Nombor Invois yang sama",
    "PackMethod": "Kaedah Pek",
    "PackOrderTips": "Petua: Pesanan Lazada mesti dikemas sebelum dapat dikirimkan, dan Ginee hanya mendukung kemasan barang dalam pesanan yang sama.",
    "AlreadyPacked": "Dikemas",
    "Pack": "Pek",
    "PackOrder": "Pesanan Pek",
    "LWrongPricePricingError": "Kesalahan Harga atau Harga Yang Salah",
    "LDuplicateOrder": "Susunan pendua",
    "LCustomerUnreachable": "Pelanggan tidak dapat dihubungi",
    "order_98c90235ea6e3d054f70d2ffdfa9e703": "Kehabisan Stok",
    "LOutOfDeliveryArea": "Di luar Kawasan Penghantaran",
    "LIncorrectOr": "Alamat Penghantaran Tidak Betul atau Tidak Lengkap",
    "LSystemError": "Ralat Sistem",
    "LSourcingDelay": "Kelewatan Sumber (tidak dapat memenuhi tarikh akhir)",
    "NoName": "Tiada nama",
    "ProductSyncFailedAgain": "Penyegerakan Produk Gagal, Cuba Lagi",
    "ShopCancelOrderStockInfo": "Kehabisan stok - Stok produk yang dipilih akan 0 di kedai yang sesuai",
    "TokoCancelOrderStockInfo": "Kehabisan stok - Stok produk yang dipilih akan 0 di kedai yang sesuai",
    "BLCancelOrderStockInfo": "Apabila membatalkan pesanan, stok produk dalam pesanan itu akan menjadi 0 di kedai yang sesuai",
    "AlreadyProcessed": "Telah di proses",
    "Grab": "Rebut [9: 00-16: 00]",
    "GOSENDInstant": "GO-HANTAR Segera [6: 00-18: 00]",
    "GOSENDSameDay": "GO-HANTAR Pada Hari yang Sama [6: 00-15: 00]",
    "AvailableTimeToPickup": "Masa yang ada untuk mengambil",
    "ProductUnitPrice": "Harga seunit",
    "ShopCloseTime": "Waktu tutup kedai",
    "ToCancellationInfo": "Penjual tidak dapat menolak permohonan pembatalan pesanan dari pembeli di Tokopedia",
    "ToTrackingNoInfo": "8-17 perkataan",
    "trackingNoErrorMessage": "{min}-{max} perkataan",
    "BLTrackingNewNoInfo": "8-25 perkataan",
    "BLTrackingNoInfo": "8-25 perkataan menggunakan abjad dan nombor",
    "TBuyerRequest": "Permintaan pembeli",
    "TCourierProblem": "Masalah kurier",
    "TOthers": "Yang lain",
    "shopClosed": "Kedai ditutup",
    "TWrongPriceOrWeight": "Harga atau berat yang salah",
    "TProductVariantUnavailable": "Varian produk tidak tersedia",
    "TProductOutOfStock": "Produk tidak ada stok",
    "TRejectShipping": "Tolak Penghantaran",
    "BLBusy": "Sibuk",
    "BLOutdatedPrice": "Harga Tertinggal",
    "BLInappropriatePrice": "Harga Tidak Sesuai",
    "BLVariantOutOfStock": "Varian Tidak Ada Stok",
    "BlSuppliersOutOfStock": "Pembekal Tidak Ada Stok",
    "BLNoStock": "Tiada stok",
    "ReceiveInfo": "",
    "SelectStockEmpty": "Stok produk yang dipilih akan dibersihkan",
    "DeleteKeepOneData": "Sila simpan sekurang-kurangnya satu data",
    "CourierTracking": "Penjejakan kurier",
    "NoReturnOrder": "Tiada Permintaan yang dikembalikan untuk diproses",
    "InReturn": "Seeding Dikembalikan",
    "InCancel": "Membatalkan",
    "ReturnTips": "Hanya satu pesanan balik yang disokong. Apabila beberapa pesanan dipilih, hanya pesanan pertama yang diproses",
    "NoCancellationOrder": "Tiada Permintaan Pembatalan untuk diproses",
    "PrintDate": "Tarikh cetak",
    "BuyerNote": "Nota Pembeli",
    "message": "Mesej",
    "seeMore": "Lihat {lagi} Item",
    "HistoryOrderCompleted": "Pesanan selesai",
    "HistoryOrderCancellation": "Permintaan pembatalan telah ditolak",
    "HistoryOrderReturned": "Pesanan dikembalikan",
    "HistoryOrderShipping": "Pesanan telah dihantar",
    "HistoryCancelOrder": "Pesanan dibatalkan",
    "HistoryReject": "Penjual enggan membatalkan pesanan",
    "HistoryCreateOrder": "Buat Pesanan",
    "Reject": "Tolak",
    "Confirm": "Sahkan",
    "AcceptInfo": "Pesanan ini akan dibatalkan serta-merta, dan pembayaran yang dibuat oleh pembeli akan dikembalikan. Jangan menghantar pesanan ini. Sekiranya anda sudah dihantar, tolak permintaan pembatalan.",
    "Images": "Gambar",
    "AcceptReturn": "Terima Pulangan",
    "Respond": "Membalas",
    "Reason": "Sebab",
    "RequestID": "Minta ID",
    "ReasonFromBuyer": "Sebab Dari Pembeli",
    "qtyToShip": "Kuantiti untuk Dihantar",
    "Qty": "Kuantiti",
    "buyer": "Pembeli",
    "orders": "Pesanan",
    "CODNotSupported": "COD Tidak Disokong",
    "UndeliverableArea": "Kawasan Tidak Boleh Dihantar",
    "CustomerRequest": "Permintaan pelanggan",
    "OutOfStock": "Kehabisan Stok",
    "History": "Sejarah",
    "NoNext": "Tidak, seterusnya",
    "YesNext": "Ya, seterusnya",
    "Print": "Cetak",
    "Deleted": "Dipadamkan",
    "Retry": "Cuba lagi",
    "AcceptRejectInfo": "Adakah anda mahu menerima permintaan pembatalan?",
    "receiveOrderTip": "Adakah anda mahu menerima pesanan?",
    "selectCancelTips": "Adakah anda ingin membatalkan semua pesanan dengan alasan yang sama?",
    "AddPickingStatus": "Tambah Status",
    "SetPickStatusConfirm": "Sahkan",
    "PickApplyToAll": "Terpakai kepada semua",
    "SetPickStatusTitle": "Tetapkan Status Pilih",
    "SetPickStatusInMass": "Tetapkan Status Memilih secara beramai-ramai",
    "PickingSetTip": "Petua: Perlu diketahui bahawa setelah ditetapkan ke Dipilih, anda tidak dapat mengubah statusnya lagi.",
    "PrintPicking": "Mencetak Senarai Memilih",
    "PrintPackingList": "Cetak Senarai Pembungkusan",
    "PrintInvoice": "Mencetak Invois",
    "PrintLabel": "Cetak Label",
    "ProcessingCancellation": "Pembatalan Proses",
    "ProcessingReturns": "Memproses Pulangan",
    "ReceiveOrder": "Terima Pesanan",
    "orderSelected": "Pesanan Dipilih",
    "shippingfeetip": "Bayaran logistik Bukalapak akan ditambah setelah dihantar",
    "variationdeleted": "Item sedang dipadamkan",
    "Sync": "Segerakkan",
    "tipsWait": "Ciri 'Atur Penghantaran' akan disiarkan dalam masa terdekat. Sila terus ke pasar untuk memproses pesanan",
    "variation": "Variasi",
    "MSKU": "MSKU",
    "SKU": "SKU",
    "Total": "Jumlah",
    "SellerDiscountTotal": "Jumlah Diskaun Penjual",
    "Insurance": "Insurans",
    "Tax": "Cukai",
    "Shipping": "Kos penghantaran",
    "subtotal": "jumlah kecil",
    "readyToShip": "Bersedia untuk dihantar",
    "timeSlots": "Slot Masa",
    "selectAddress": "Pilih Alamat",
    "address": "Alamat",
    "selectCourier": "Pilih Kurier",
    "logistics": "Logistik",
    "reasonForCancellation": "Sebab Pembatalan",
    "qtyToCancel": "Kuantiti untuk Dibatalkan",
    "qtyOrdered": "Kuantiti Dipesan",
    "ArrangeShipment": "Susun Penghantaran",
    "updateOrder": "Kemas kini",
    "CancelOrder": "Batalkan Pesanan",
    "notes": "Catatan",
    "item": "Barang",
    "shippingAddress": "Alamat Penghantaran",
    "customerEmail": "E-mel",
    "contactNo": "No. Perhubungan",
    "promiseToShipBefore": "Berjanji untuk Menghantar Sebelum",
    "AWBTrackingNumber": "AWB / Nombor Penjejakan",
    "shippingType": "Jenis Penghantaran",
    "paymentType": "Pembayaran",
    "orderType": "Jenis Pesanan",
    "store": "Kedai",
    "date": "Tarikh",
    "customerDetails": "butiran pelanggan",
    "orderInfo": "Maklumat Pesanan",
    "processingFlow": "Aliran Pemprosesan",
    "orderDetailId": "Pesanan {pesanan d}",
    "updateAt": "Kemas kini Pada",
    "createAt": "Dibuat pada",
    "orderStatus": "Status",
    "customerName": "Pembeli",
    "orderId": "Id Pesanan",
    "amountMax": "Jumlah Maksimum",
    "amountMin": "Jumlah Minimum",
    "amount": "Jumlah",
    "createDate": "Tarikh Dibuat",
    "shippingCarrier": "kurier",
    "channelName": "Saluran",
    "keyWord": "ID pesanan carian, SKU atau nama pelanggan",
    "refundableShippingFeeIsCalculatedIn": "Bayaran penghantaran yang dikembalikan dikira dalam jumlah yang dapat dikembalikan, sila tapis untuk melihat",
    "youDontHaveOperationAuthorityFeature": "Semasa berlangganan pakej, jumlah kumulatif pesanan yang dibuat telah mencapai {x} pesanan. Selepas {y} pesanan telah melebihi, ciri pesanan tidak akan disediakan. Sila langgan pakej terlebih dahulu atau hubungi khidmat pelanggan untuk menaik taraf pakej.",
    "understood": "Faham",
    "disableDefaultRules": "Tutup peraturan lalai",
    "enableDefaultRules": "Aktifkan peraturan lalai",
    "theDefaultRuleIsSuccessfullyDeactivated": "Peraturan lalai berjaya ditutupkan",
    "theDefaultRuleIsSuccessfullyActivated": "Peraturan lalai berjaya diaktifkan",
    "ifTheOrderDoesNotMeet": "Jika pesanan tidak memenuhi mana-mana peraturan yang dibuat, senarai keluaran stok yang digunakan untuk menolak stok tidak dapat dibuat",
    "afterDisableTheOrderWillOnly": "Selepas ditutup, pesanan hanya akan memperuntukkan gudang mengikut peraturan peruntukan gudang yang dibuat dan membuat senarai keluaran stok untuk memotong stok",
    "inOrderToEnsureThatThe": "Untuk memastikan stok Master Produk boleh ditolak seperti biasa, apabila peraturan peruntukan gudang tidak dikonfigurasikan, peraturan lalai tidak akan menyokong penutupan",
    "defaultRuleWhenNoRuleIs": "Peraturan lalai: Apabila tiada peraturan dibuat secara manual, gudang akan dibahagikan dengan mengikuti gudang kemasukan stok MSKU yang baru dibuat",
    "noticeWhenTheRuleNotManually": "Tip: Peraturan lalai tidak menyokong penutupan apabila peraturan yang dibuat secara manual adalah kosong; selepas membuat peraturan secara manual, pesanan tidak akan diproses dengan mengikuti peraturan lalai; jika terdapat berbilang peraturan peruntukan dalam gudang yang sama, peraturan tersebut akan diperuntukkan mengikut keutamaan peraturan",
    "country": "Negara",
    "IntegrateNow": "Integrasikan sekarang!",
    "noconnected": "Anda tidak memiliki kedai terhubung, sila hubungkan Kedai",
    "enableTips": "",
    "youSureToEnableAre": "",
    "setAtLeastOneConditionPlease": "",
    "deletedSuccessfully": "",
    "youSureToDeleteAre": "",
    "mskuAdd": "",
    "logisticAdd": "",
    "storeAdd": "",
    "disabledWarehouseWillNoLongerBe": "",
    "youSureYouWantToDisable": "",
    "deleteMskuRule": "",
    "addMskuRule": "",
    "orderAmountIsAdjustedFrom": "",
    "deleteLogisticsRule": "",
    "addLogisticRule": "",
    "ruleNameOfRules": "",
    "deleteStoreRule": "",
    "addStoreRule": "",
    "priorityOfRuleUpdated": "",
    "historyOperation1": "",
    "logisticSelectedX": "",
    "storeSelectedX": "",
    "warehouseAllocation": "",
    "add": "",
    "amountOrder": "",
    "nameLogistic": "",
    "nameStore": "",
    "conditionSelect": "",
    "masterSkuDesignated": "",
    "orderAmountRangeSpecify": "",
    "logisticDesignated": "",
    "storeDesignated": "",
    "conditionOptional": "",
    "setAtLeastOneAllocationCondition": "",
    "settingCondition": "",
    "informationBasic": "",
    "ifTheOrderShippingWarehouseRule": "Tip: Jika peraturan gudang penghantaran pesanan belum ditambahkan atau Master SKU tidak wujud dalam gudang yang dikonfigurasikan dalam peraturan, senarai keluaran tidak dapat dijana untuk menolak stok, sila pastikan anda menetapkan peraturan; jika terdapat beberapa peruntukan peraturan di gudang yang sama, ia akan berdasarkan kepada tahap keutamaan untuk menetapkan",
    "ruleAdd": "",
    "disable": "",
    "enable": "",
    "edit": "",
    "operate": "",
    "time": "",
    "conditionAllocation": "",
    "nameRule": "",
    "priority": "",
    "upTheSearch": "",
    "moreMax": "Maks. aksara {maksimum panjang}",
    "separateMultipleInfoWithCommasOr": "",
    "addSearchType": "",
    "advancedSearch": "",
    "mskuIsNotEqualTo": "",
    "skuIsNotEqualTo": "",
    "mskuIsEqualTo": "",
    "skuIsEquals": "",
    "shopeebukalapakOrdersUseGineeTemplateFor": "",
    "selectPrinter": "Pilih mesin cetak",
    "printCompleted1": "Selesai mencetak",
    "printing2": "Sedang mencetak",
    "checkTheDetails": "Lihat perincian",
    "action1": "Operasi",
    "printQuantity": "Bilangan cetakan",
    "printStatus": "Keadaan cetakan",
    "printingAdmin": "Ahli mencetak",
    "highspeedBatchPrintingHistory": "Sejarah pencetakan secara besar-besaran berkelajuan tinggi",
    "highspeedPrintingLabelHistory": "Sejarah pesanan berkelajuan tinggi",
    "printAgain": "Cetak semula",
    "failureReason": "Sebab kegagalan",
    "shipmentNumber": "Nombor Penghantaran",
    "order": "Pesanan",
    "printFailed": "Gagal mencetak",
    "printSuccessfully": "Berjaya mencetak",
    "printing1": "Sedang mencetak",
    "cautionToEnsureNormalPrintingOperation": "",
    "IfPrintingFailsPleaseMove": "3. Jika pencetakan gagal, sila beralih ke senarai kegagalan cetakan untuk menyemak sebab kegagalan",
    "DuringPrintingIfSomeOrders": "2. Semasa mencetak, beberapa pesanan gagal dicetak, tetapi tidak menjejaskan kemajuan pencetakan keseluruhan",
    "AfterThePrintingIsCompleted": "1. Selepas cetakan selesai, pesanan akan ditanda sebagai Telah dicetak secara automatik. Jika anda perlu menukar, sila pergi ke senarai pesanan untuk mengubah sendiri",
    "printingInstructions": "Arahan pencetakan",
    "totalXxxAndXxPrinted": "Jumlah { total } , { x }  telah dicetak",
    "printCompleted": "Selesai mencetak",
    "printing": "Sedang mencetak",
    "printingProgress": "Kemajuan pencetakan:",
    "highSpeedPrintingTaskCenter": "Pusat petugas pencetakan berkelajuan tinggi",
    "recheck": "Semak semula",
    "ifYouConfirmThatThePlugin": "Jika anda pasti telah memasang sambungan dan telah diaktifkan, sila klik butang semak semula untuk mencetak",
    "notInstalledClickHereToDownload": "Belum dipasang, klik di sini untuk memuat turun sambungan",
    "installed": "Telah dipasang,",
    "detectedWeFoundThatYouHave": "Selepas menyemak, didapati bahawa anda belum memasang atau mengaktifkan sambungan pencetakan berkelajuan tinggi, sila cuba kaedah berikut:",
    "tips": "",
    "inOrderToImproveThePrinting": "",
    "startPrinting": "Mula mencetak",
    "printer": "",
    "noteToEnableHighspeedPrintingThe": "Nota: Untuk mengaktifkan pencetakan berkelajuan tinggi, sambungan pencetakan berkelajuan tinggi mesti dimuat turun dan diaktifkan",
    "highspeedPrinting": "Pencetakan berkelajuan tinggi (bilangan cetakan tunggal tanpa had, tidak menyokong pratonton cetakan)",
    "regularPrintingPrintUpTo": "Pencetakan biasa (cetak sehingga 100 pesanan dalam satu masa, dan hasilkan label penghantaran PDF untuk semua pesanan, dan akan mengambil masa yang lama)",
    "printingMethod": "Kaedah pencetakan",
    "haveBeenPrinted": "{ x }  diantaranya telah dicetak",
    "dataHaveBeenSelectedAnd": "{ x }  data telah dipilih",
    "printData": "",
    "customLabelOnlySupports": "Penyesuaian label penghantaran (hanya menyokong pesanan Shopee/Tokopedia/Bukalapak)",
    "channelLabelAllChannelOrdersSupport": "Label penghantaran saluran (Semua pesanan saluran menyokong pencetakan, kecuali pesanan Shopify/Woocommerce)",
    "setUpPrintTemplates": "Tetap templat cetakan",
    "printShippingLabelAll": "",
    "printShippingLabelSelected": "",
    "selectAllOrders": "Pilih semua pesanan",
    "pleaseConfirmWhetherToTurnOff": "Sila pastikan sama ada hendak menutup pemprosesan stok?",
    "putOnHold": "",
    "pickingNote": "Memetik Nota",
    "dropshipper": "Dropshipper",
    "storeName": "Nama kedai",
    "setReadyToShip": "Tetap Sedia Untuk Dihantar",
    "becauseYouHaveNotIntegrateThe": "",
    "integrateStore": "",
    "integrationGuide": "",
    "MinutesToLearnAboutGinee": "",
    "shortcuts": "",
    "theStoreHasNotBeenAuthorised": "",
    "inventoryManagement": "",
    "productManagement": "",
    "scrapeProduct": "",
    "masterProductSales": "",
    "try": "Cuba sekarang",
    "productActivitySetting": "",
    "capitalOccupationRatio": "",
    "stockTakesUpFunds": "",
    "numberOfMskus": "",
    "exportMsku": "",
    "purchaseDuration": "",
    "doesItNeedToBeReplenished": "",
    "availableDaysDays": "",
    "averageDailySales": "",
    "lastMonth": "",
    "lastTwoWeeks": "",
    "lastWeeks": "",
    "lastThreeDays": "",
    "numberOfRatings": "",
    "ratings": "",
    "likes": "",
    "pageViews": "",
    "productActivityHasBeenSetFor": "",
    "sales": "",
    "effectiveSales": "",
    "newItem": "",
    "lowSellingItem": "",
    "normalItem": "",
    "trendingItem": "",
    "bestSellingItem": "",
    "productActivity": "",
    "accounting": "",
    "categoryRange": "",
    "timeRange": "",
    "productDimension": "",
    "salesType": "",
    "salesRange": "",
    "allCategory": "",
    "recent": "",
    "oneDay": "",
    "threeDay": "",
    "aWeek": "",
    "twoWeeks": "",
    "aMonth": "",
    "threeMonths": "",
    "halfYear": "",
    "onlySupportForIndonesia": "",
    "addProductActivity": "",
    "cSupportToManuallyPullAccurate": "",
    "bAfterTheOrderIsPlaced": "",
    "aInboundStockForMasterProduct": "",
    "StockSync": "",
    "noteOrderInformationSynchronizationIsA": "",
    "createdTheWarehousePairingRelationship": "",
    "OrderDistribution": "",
    "noteTheSynchronizationOfMasterProduct": "",
    "afterCreatedTheWarehousePairingRelationship": "",
    "ProductPlacement": "",
    "psItIsNotRecommendedTo": "",
    "afterTheAuthorizationIsCompletedYou": "",
    "BasicConfiguration": "",
    "pleaseMakeSureToFillIn": "",
    "Authorization": "",
    "accurateOperationGuide": "",
    "averageDailySalesSettings": "",
    "averageSalesForTheDayAs": "",
    "clickAuthorizeYouWillBeRedirected": "",
    "onSaleDaysDays": "",
    "purchaseTimedays": "",
    "AfterTheAuthorizationIsCompleted": "",
    "PleaseSelectAtLeast": "",
    "remainder": "",
    "authorizationSucceeded": "",
    "theAccurateWarehouseInformationIsBeing": "",
    "continueAuthorization": "",
    "reauthorize": "",
    "channelFailureReason": "",
    "authorizationFailed": "",
    "deleteAuthorization": "",
    "authorizer": "",
    "authorizationTime": "",
    "connected": "",
    "authorizationStatus": "",
    "ServiceProvider": "",
    "accountName": "",
    "deleteFailed": "",
    "theBindingRelationshipBetweenTheAccurate": "",
    "onceDeletedTheBindingRelationshipBetween": "",
    "areYouSureWantToDelete": "",
    "productSuccessfullySynchronized": "",
    "whenDistributeGineeWarehouseProductGinees": "",
    "areYouSureToSyncGinee": "",
    "channelReturnFailureReason": "",
    "pullFailed": "",
    "pullSuccessfully": "",
    "pull": "",
    "afterManuallyPullingTheWarehouseInformation": "",
    "pullWarehouse": "",
    "warehouseStockSuccessfullyUpdated": "",
    "onceTheStockOfTheThirdparty": "",
    "areYouSureToPullStock": "",
    "theWarehouseBindingRelationshipHasBeen": "",
    "afterTheUnbindingIsSuccessfulThe": "",
    "areYouSureToUnbindThe": "",
    "reasonTheCurrentWarehouseHasBeen": "",
    "bindingFailed": "",
    "bindingIsSuccessfulPleaseDoNot": "",
    "bind": "",
    "tipOnlySupportsSelectingWarehouseThat": "",
    "syncWarehouse": "",
    "unbindWarehouse": "",
    "bindTime": "",
    "accurateWarehouse": "",
    "accurateWarehouseInformationWillNotBe": "",
    "accurateSettingManagement": "",
    "synchronizeWarehouseInformationUnderAccurateDatebase": "",
    "syncGineeWarehouseProductTo": "",
    "syncStockFromAccurateWarehouse": "",
    "accurateWarehouseUnbindFrom": "",
    "accurateWarehouseBindTo": "",
    "operationLogIsOnlyRetainedFor": "",
    "uploadedNowlengthSheets": "",
    "isAUniqueIdentifierForThe": "",
    "pleaseConfirmWhetherTheStoresChannel": "",
    "errnameToko": "Nama produk harus di antara 1 hingga 70 aksara",
    "sizeSettingTemplate": "Tetapan Templat Saiz",
    "mappingCategory": "Pemetaan Kategori",
    "templateSettingLogistic": "Tetapan Templat Logistik",
    "rangeEnable": "Tetapan Produk",
    "globalsMobileErrorInfoLength": "Nombor telefon harus di antara {min}-{max} angka",
    "globalsMobileErrorInfo": "Sila masukkan nombor telefon yang sah",
    "masterSku": "Master SKU",
    "outboundPurchasePrice": "Unit Harga Pembelian",
    "hasbinded": "Varian ini telah terikat dengan SKU Inventori: {sku} Sila kemas kini inventori dalam \"Pengurusan Inventori\"",
    "deletecustomersuccess": "Berjaya dipadamkan!",
    "reasonforpushedfailed": "Sebab Gagal: Produk telah dipadam atau dilarang dari kedai, atau ada dalam kempen promosi (Stok dapat dikemas kini setelah promosi tamat). Sila semak produk",
    "purchasePricerequired": "Harus di antara 0 dan 1,000,000,000!",
    "createsuccess": "Berjaya Dibuat",
    "atmost20tags": "Maksimum 20 Tag",
    "AddTag": "Tambah Tag",
    "tagexist": "Tag sudah ada",
    "fenotempty": "Depan dan belakang tag tidak boleh ada ruangan",
    "taginputlimit": "Tag harus di antara 3-40 perkataan",
    "selecttag": "Pilih tag",
    "withordernostock": "Ada Pesanan, Tiada Stok",
    "withisku": "Ada Stok SKU",
    "noisku": "Tiada Stok SKU",
    "withskuwithoutisku": "Ada SKU tapi tiada SKU Inventori",
    "nosku": "Tiada SKU",
    "selectskusit": "Pilih Syarat SKU",
    "Pleaseenter": "Sila Masukkan",
    "selectsku": "Pilih SKU Inventori",
    "emailreminder": "Peringatan E-mel",
    "safetystockarea": "Apabila Stok tersedia <=",
    "bindedisku": "SKU Stok Terikat",
    "most20bundles": "Maks. 20",
    "NAME": "Nama",
    "PleaseEnter": "Sila Masukkan",
    "disablebundletip": "Untuk memastikan ketepatan pemotongan stok, SKU Inventori terikat dan Bilangan tidak dapat diubah semasa ubah ISKU Gabungan",
    "updatesuccess": "Kemaskini berjaya!",
    "ISKUtype": "Jenis ISKU",
    "bundleisku": "ISKU Gabungan",
    "isku": "MSKU",
    "ProductNameISKU": "Nama Produk & ISKU",
    "Image": "Gambar",
    "bundledproduct": "Produk Gabungan",
    "selectbundle": "Pilih Stok SKU",
    "AddBundleISKU": "Tambah SKU Gabungan",
    "bukadisabled": "Masa memproseskan pesanan adalah mengikuti tetapan kedai",
    "pleasecheckpreorderpart": "Sila semak maklumat pesanan terdahulu",
    "shopeedisabled": "Saya akan menghantar dalam 2 hari masa bekerja. (tidak termasuk hari kelepasan am dan perkhidmatan kurier bukan hari bekerja)",
    "tokodisabled": "Aktifkan Praorder untuk produk ini jika masa prosesnya lebih lama.",
    "UNKNOWNunittip": "Unit masa di Saluran tidak tersedia, Sila hubungi Ginee",
    "day": "hari",
    "week": "minggu",
    "bukreorderdayworkday": "waktu bekerja untuk penghantaran",
    "bukreorderday": "hari untuk penghantaran",
    "bukainstanhour": "waktu bekerja",
    "bukaregular": "Saya akan hantarkan dalam masa 3 hari bekerja. (tidak termasuk hari kelepasan am dan perkhidmatan kurier bukan hari bekerja)",
    "bukreorder": "Saya perlu",
    "bukainstan": "Saya akan menghantar dalam",
    "tokopreorderday": "Hari untuk penghantaran",
    "tokopreorderweek": "Minggu untuk penghantaran",
    "tokopreorder": "Saya perlu",
    "shopeepreorderday": "hari bekerja untuk penghantaran",
    "shopeepreorder": "Saya perlu",
    "max8pictures": "Muat naik sehingga 8 gambar",
    "max5pictures": "Muat naik sehingga 5 gambar",
    "uploadtip": "Memuat naik Gambar Produk mengikut peraturan Saluran adalah: Untuk Tokopedia, Bukalapak, Shopify, TikTok Shop, Blibli akan mengambil 5 gamabr pertama, Lazada 8 gambar pertama dan Shopee 9 gambar pertama",
    "regular": "Biasa",
    "instan": "Serta-merta",
    "between18hours": "Harus di antara 1-8",
    "between330days": "Di antara 3-30",
    "max13weeks": "Di antara 1-13 minggu",
    "shopeepreorderdayAdd": "Star, Star+, Mall mendukung hingga 90 hari bekerja",
    "between790": "Di antara 7-90 hari bekerja",
    "max90days": "Di antara 1-90 hari",
    "preorderdeadline": "Tarikh Akhir Pemprosesan Pesanan",
    "preorder": "Pesanan Terdahulu",
    "novariationforlazd": "Tidak dapat tambah gambar kerana produk ini tidak memiliki varian",
    "SafetystockHead": "Tetapkan had stok dan terima pemberitahuan e-mel untuk mengisi semula apabila Stok Tersedia produk mencapai nombor yang ditetapkan",
    "SpareStockkHead": "Jumlah Stok yang disimpan di gudang sebenar, tidak akan dijual di saluran.",
    "WarehouseStockHead": "Jumlah Stok Sebenar di Gudang Anda, Stok Gudang = Stok Ganti + Stok Terkunci + Stok Tersedia + Stok Promosi",
    "inventorySkuHead": "50 perkataan pertama dari SKU Kedai dipadankan secara lalai",
    "NameHead": "Nama SKU Inventori diambil secara automatik dari 160 kata pertama [nama produk-nama varian]",
    "BrandLogoTips": "Cadangan Muat Naik: 360*140, jpg/png/jpeg gambar kecil daripada 2M",
    "MobileMustErrorInfo": "Harus mula dengan 8, mengandungi 9-12 nombor",
    "bindnow": "Ikat sekarang",
    "Nospacesfront": "Tidak boleh ada butang ruang di bahagian pertama, sila semak",
    "spaceforbidden": "Tidak boleh ada butang ruang, sila semak",
    "range": "Julat: Aktif, Tidak Aktif, Habis Jualan, Dilarang",
    "inventorySkunotempty": "ISKU tidak boleh dikosongkan!",
    "limitsafety": "Stok Pengaman harus di antara 0 ~ 999,999!",
    "mustlessthanwarehouse": "Stok Ganti tidak boleh besar daripada Stok Gudang",
    "sparelimit": "Stok Ganti harus di antara 0 dan 999,999!",
    "mustbiggerthanspare": "Stok Ganti tidak boleh lebih daripada Stok Gudang",
    "warehouselimit": "Stok Gudang Harus di antara 0 dan 999,999!",
    "masterSkuMost200": "Master SKU harus kurang daripada 200 perkataan",
    "masterSkuMost50": "Master SKU harus kurang daripada 50 perkataan",
    "most50": "SKU Inventori harus kurang daripada 50 perkataan",
    "most160": "Nama harus kurang daripada 300 perkataan",
    "clicktoedit": "Tekan untuk ubah",
    "alreadyexist": "SKU Inventori sudah ada, SKU Varian akan terikat dengan SKU Inventori",
    "generaterules": "Peraturan penjanaan SKU Inventori secara lalai: 1. SKU Inventori akan mengambil 50 perkataan SKU pertama secara lalai. 2. Stok Gudang akan sama dengan Stok Varian secara lalai, dan Stok Pengaman akan kosong secara lalai. 3. Apabila SKU Inventori dihasilkan, SKU varian akan terikat.",
    "channel": "Saluran",
    "Operationguide": "Panduan Operasi",
    "storeexplain": "Hasilkan SKU Inventori untuk produk di satu kedai untuk menguruskan inventori di beberapa kedai",
    "bystoremodal": "Hasilkan SKU Inventori mengikut kedai",
    "ImportByStore": "Import Mengikut Kedai",
    "Gotit": "Faham!",
    "Safetystock": "Stok Pengaman",
    "SpareStock": "Stok Ganti",
    "WarehouseStock": "Stok Gudang",
    "inventorySku": "MSKU",
    "Name": "Nama",
    "image": "Gambar",
    "GenerationRules": "Peraturan Hasilan",
    "ClickContenttoEdit": "Tekan kandungan untuk mengubah",
    "generatecount": "{productSum} produk ({availableNum} daripada {variationSum} Varian boleh menghasilkan Master Produk). Varian yang terikat dengan Master SKU atau SKU kosong tidak dapat menghasilkan Master SKU.",
    "generateisku": "Hasilkan Stok SKU",
    "draftorfialed": "Sila pilih hanya draf atau produk yang gagal diterbitkan",
    "uploadlimit": "Fail terbesar: xlsx, xls hanya 5MB. Import sehingga 1000 data dalam satu kali.",
    "canbedownloaded": "Produk",
    "selectedstatus": "Produk dipilih di status sekarang:",
    "donwloadtemp": "Muat Turun Templat Import",
    "DownloadFailedData": "Muat Turun Data Gagal",
    "importfail": "Beberapa Data Import Gagal",
    "importfinish": "Data Import Berjaya",
    "Cancel": "Batalkan",
    "fileerror": "Format fail import tidak betul",
    "close": "Tutup",
    "importing": "Sedang mengimport",
    "importlabel": "Import Fail",
    "upload": "Muat Naik",
    "download": "Muat Turun",
    "templatetip": "Muat Turun Templat",
    "import": "Import",
    "themaxtip2": "rekod setiap muat naik. Pelanggan mempunyai telefon yang sama akan digabungkan.",
    "themaxtip1": "Tip: Had Maksimum adalah",
    "ActionsSuccessInfo": "Operasi Berjaya!",
    "ShopeeTips": "Tekan \"Mulai Menyegerak\" dan anda akan diarahkan ke Shopee di mana anda boleh log masuk dan memberi kebenaran",
    "AlreadyChecked": "{selected} Dipilih",
    "SubscriptionExtended": "Langganan dapat diperpanjang, sila tekan \"langganan\" atau hubungi kami untuk menaik taraf akaun anda",
    "SubscribeSuccess": "Jika anda berjaya melanggan, anda boleh mengaktifkan ciri lanjutan. Tekan \"Langgan\" atau hubungi kami untuk menaik taraf akaun anda",
    "SubscriptionExpired": "Maaf, langganan anda telah tamat, tekan \"Langgan\" atau hubungi kami untuk menaik taraf akaun anda",
    "PayNow": "Bayar sekarang",
    "ThisFeatureIsOnlyPaidUsers": "Ciri ini hanya untuk Pengguna Berbayar, Sila Bayar untuk Menggunakan Ciri ini.",
    "FoundANewGreatFeature": "Menemui ciri hebat baru!",
    "urlplaceholder": "Sila masukan Nama Kedai",
    "urltip": "Nama harus di antara 2-30 kata Hanya huruf kecil dan angka yang dibenarkan",
    "urldesciption": "Sila buat Link Ahli Kedai",
    "memberurlmodaltitle": "Buat link halaman ahli",
    "CategoryLevel3": "Kategori Tahap 3",
    "CategoryLevel2": "Kategori Tahap 1",
    "RichTextDescription": "Taipkan keterangan anda di sini dan terapkan pada produk anda",
    "PleaseInput": "Sila Masukkan",
    "selectall": "Pilih Semua",
    "noauth": "Akaun anda tidak mempunyai kebenaran ini, sila hubungi pentadbir kedai untuk bertanya atau menaik taraf",
    "NoProductYet": "Tiada produk lagi",
    "SeeMore": "Lihat { more } barang",
    "SeeMoreExpand": "Lihat lebih banyak",
    "SaveAndNext": "Simpan dan seterusnya",
    "NoHaveAccount": "Belum ada akaun?",
    "Requiredfields": "Ruang yang dikehendaki mengisi tidak boleh dikosongkan!",
    "Somerequiredfields": "Sebahagian ruang yang perlu diisi telah dikosongkan, sila isikan",
    "Removetheseproducts": "Padamkan produk tersebut",
    "GotoShippingSettings": "Pergi ke Tetapan Penghantaran",
    "Somewithoutshipping": "Beberapa produk tidak dapat disimpan tanpa pilihan penghantaran. Sila buka Pilihan Penghantaran sebelum mengubah produk.",
    "Discardcontent": "Batalkan bermaksud maklumat produk akan sama dengan maklumat asal di kedai",
    "Discardtip": "Adakah anda pasti membatalkan perubahan ini?",
    "with": "sebagai",
    "Changepriceto": "Tukar harga jadi",
    "Reducebypercent": "Kurang bedasarkan peratus",
    "Reducebyamount": "Kurang berdasarkan jumlah",
    "Addbypercent": "Tambah berdasarkan peratus",
    "Addbyamount": "Tambah berdasarkan jumlah",
    "Basedoncurrentprice": "Berdasar harga sekarang",
    "replace": "Ganti",
    "addaftername": "Tambah selepas nama",
    "addbeforename": "Tambah sebelum nama",
    "Changestockto": "Tukar stok ke",
    "Example": "Contoh:",
    "errsize": "Ruang yang dikehendaki mengisi tidak boleh dikosongkan!",
    "BukalapakPrice": "Harga harus di antara 1 ~ 1.000.000.000",
    "phLazadaPrice": "Harga harus di antara 1 ~ 1.000.000.000",
    "LazadaPrice": "Harga harus di antara 1000 ~ 1.000.000.000",
    "TokopediaPrice": "Harga harus di antara 100 ~ 1.000.000.000",
    "phShopeePrice": "Harga harus di antara 2 ~ 1.000.000.000",
    "ShopeePrice": "Harga harus di antara 99 ~ 1.000.000.0000",
    "pricetipBukalapak": "Harga harus di antara 1 ~ 1.000.000.000",
    "pricetipShopee": "Harga harus di antara 99 ~ 1.000.000.000",
    "errdescriptionBukalapak": "Penerangan harus di antara 30 - 3000 kata",
    "errdescriptionShopee": "Harga harus di antara 99 ~ 1.000.000.000",
    "errnameBukalapak": "Nama Produk harus di antara 6-150 perkataan",
    "errnamelazada": "Nama Produk harus di antara 2 - 2555 perkataan",
    "allemptyorfilled": "Ukuran panjang, lebar, dan tinggi harus semua diisi atau semua dikosongkan",
    "errnameShopee": "Nama Produk harus di antara 5 - 100 perkataan",
    "spaceskuerr": "SKU tidak boleh mempunyai butang ruang, sila semak",
    "PleaseOneShipping": "Sila tetapkan sekurang-kurangnya satu pilihan untuk penghantaran produk anda",
    "pricetip": "Harga harus di antara 99 ~ 1.000.000.000",
    "stocktip": "Stok harus di antara 0 〜 999,999",
    "Discardchanges": "Buang perubahan",
    "update": "Kemaskini",
    "draft": "Simpan sebagai Draf",
    "publish": "Terbit",
    "Logistic": "Logistik",
    "ParcelSize": "Ukuran Bungkusan",
    "Width": "Lebar",
    "Length": "Panjang",
    "Height": "Tinggi",
    "Weight": "Berat",
    "price": "Harga",
    "VariantName": "Nama Varian",
    "parentSku": "SKU Induk",
    "productImg": "Gambar",
    "Refresh": "Segarkan",
    "PlatformUpdateNotification": "Tips kemaskini Ginee",
    "WaitAMoment": "Nanti",
    "GoAuth": "Integrasikan",
    "NewVersionsInfo": "Pengurusan Saluran Shopee 2.0 kini tersedia! Mari intergrasikan akaun anda! Penyegerakan data kini lebih stabil dan ciri untuk memproses pesanan baru!",
    "NoDataInfo": "Tiada data untuk diproses",
    "ActionsErrorInfo": "Operasi gagal, sila cuba sebentar lagi",
    "and": "dan",
    "PrivacyPolicy": "Dasar Privasi",
    "TermsConditions": "Syarat Penggunaan",
    "agree": "Dengan mendaftar, Saya setuju dengan",
    "BLDescription": "Keterangan harus di antara 30 - 3000 kata",
    "markDownImageSize": "ukurannya mestilah sekurang-kurangnya 300 x 300 piksel ， kurang daripada 5M ， format dalam format JPG, PNG",
    "markDownAddImage": "Tambah Gambar",
    "noProductStore": "Anda belum mempunyai kedai yang terhubung, hubungkan kedai anda sekarang dan tambahkan produk",
    "noStoreInfo": "Anda boleh menghubungkan kedai dalam talian anda dan memulakan penyegerakan produk, stok, dan pesanan dengan segera",
    "noMemberInfo": "Selepas pelanggan mendaftar sebagai ahli, anda boleh menguruskan maklumat terperinci dan mata mereka di sini. Anda juga boleh menambahkan ahli secara manual",
    "noProductInfo": "Anda belum membuat sebarang produk. Tambahkan produk pertama anda sekarang",
    "TextDescription": "Penerangan Teks",
    "memberInfo": "Maklumat Ahli",
    "jdDisableTips": "Tidak boleh integrasi sekarang",
    "Coming": "Akan Datang",
    "editPointRuleInfo": "Adakah anda pasti untuk mengubah peraturan mata?",
    "previewImg": "Pratonton",
    "addRule": "Tambah Peraturan",
    "methodTypeAll": "Semua",
    "sendSuccess": "Berjaya dihantar",
    "editPointsRule": "Ubah peraturan mata",
    "pointeReduceErrorInfo": "Bukan semua pengguna terpilih mempunyai mata yang cukup untuk dipotongkan",
    "ruleFormatErrorInfo": "Hanya huruf kecil dan angka dibenarkan",
    "rewardDeductPoints": "Mata Ganjaran / Potongan",
    "alreadyExists": "{ name } telah didaftar",
    "signNext": "Seterusnya",
    "copySuccess": "Salin Berjaya: { copyUrl }",
    "deleted": "Dipadam",
    "rule": "Peraturan",
    "noName": "Tiada Nama",
    "copyUrl": "Salin",
    "linkInfo": "Link",
    "laseSevenDaysInfo": "Bilangan ahli yang ditambahkan dalam 7 hari terakhir",
    "editimage": "Ubah Gambar Varian",
    "packageContentplaceholder": "Kurang daripada 1000 kata",
    "packageContent": "Isi Pakej",
    "VariantImage": "Gambar Varian",
    "brand": "Jenama",
    "brandLogo": "Logo Jenama",
    "brandName": "Nama Jenama",
    "pointsHistory": "Sejarah Mata Ganjaran",
    "editShopInfo": "Ubah Maklumat Kedai",
    "alreadyShopOwn": "Tahniah! Kedai anda sudah siap.",
    "shopSetInfo": "Maklumat Kedai",
    "contentWrapper": "Kandungan",
    "newSections": "Bahagian Baru",
    "accumulatePoints": "Mata Ganjaran Akumulasi",
    "chooseCardBg": "Tema",
    "shopLogo": "Logo Kedai",
    "shopName": "Nama Kedai",
    "membershipPageSettings": "Tetapan Halaman Keahlian",
    "uploadImageError": "Fail {fileName} ukuran gambar terlalu kecil, minimum {limitMax} x {limitMin}",
    "uploadInfo": "Keperluan: {maxWH} * {maxWH}, kurang dari {isLt} M, format menyokong jpg, png, jpeg",
    "enterPointMax": "Nilai maksimum yang boleh dimasukkan adalah 999999",
    "enterPointNum": "Masukkan bilangan positif antara 1-999999",
    "updateSuccessFul": "Kemaskini Berjaya",
    "pointsType": "Mata Ganjaran",
    "redeemablePoints": "Mata Ganjaran yang boleh digunakan",
    "reason": "Sebab",
    "deductPoints": "Mata Ganjaran Potongan",
    "rewardPoints": "Mata Ganjaran",
    "joinedMethod": "Kaedah Benambah",
    "dateOfBirth": "Tarikh Lahir",
    "remarksErrorInfo": "Maksimum 100 kata",
    "editDetailInformation": "Ubah Maklumat Perincian",
    "editMemberInformation": "Ubah Maklumat Ahli",
    "profileDetails": "Perincian Profil",
    "accountInformation": "Maklumat Akaun",
    "rewardCondition": "Syarat mata ganjaran",
    "customizeAlertInfo": "Peraturan di bawah akan ditunjukkan di halaman keahlian; namun, mata perlu diberikan secara manual",
    "customizePointRules": "Sesuaikan Peraturan Mata",
    "defaultAlertInfo": "Pelanggan akan mendapat mata ganjaran secara automatik apabila mereka melakukan aktiviti di bawah",
    "defaultPointRules": "Peraturan Mata Lalai",
    "joinMemberTime": "Masa masuk keahlian",
    "inputNameOrMobile": "Masukan nama/nombor telefon",
    "editPoints": "Ubah Mata",
    "addNewMember": "Tambah Ahli Baru",
    "totalPage": "Jumlah { total }",
    "description": "Penerangan",
    "points": "Mata",
    "memberNo": "Nombor Ahli",
    "mobile": "Nombor Telefon",
    "shopname": "Nama Kedai",
    "name": "Nama",
    "genderFemale": "Perempuan",
    "genderMale": "Lelaki",
    "gender": "Jantina",
    "deleteRuleInfo": "Adakah anda pasti memadamkan peraturan ini?",
    "status": "Status",
    "checkBoxTableSelected": "{ selected } dipilih",
    "create": "Buat",
    "dashboardPointCumulativeRedeem": "Mata Ganjaran kumulatif yang digunakan",
    "dashboardPointCumulativeGiven": "Mata Ganjaran kumulatif yang diberikan",
    "dashboardPointToRedeem": "Mata Ganjaran boleh digunakan",
    "dashboardNewMember": "Ahli Baru",
    "dashboardAllMember": "Jumlah Keahlian",
    "hello": "Hai, dunia!",
    "mobileErrorInfoLength": "Nombor telefon harus di antara 9 - 12 angka",
    "fullnameerror": "Nama kedai harus 1 ~ 30 kata",
    "fullname": "Nama kedai",
    "mobileplaceholder": "Nombor telefon harus 11 angka",
    "mobileErrorInfochina": "Nombor telefon tidak sah",
    "mobileErrorInfo": "harus mulai dari 8",
    "nameErrorInfo": "Nama harus di antara 2-30 kata",
    "emailErrorInfo": "Alamat e-mel tidak sah",
    "resetPwdSuccess": "Kata laluan berjaya ditetapkan",
    "resetPwd": "Tetapkan semula kata laluan",
    "alreadyInfo": "Sudah mempunyai akaun Ginee?",
    "applyConfirm": "Pasti",
    "forgotPwd": "Lupa Kata Laluan?",
    "codeSendSuccess": "Kod pengesahan telah dihantar melalui e-mel ke { email }",
    "noReceiveCountDown": "Belum menerima kod pengesahan?",
    "waitCountDown": "Sila tunggu { someone } kiraan detik",
    "resendCode": "Hantar Semula",
    "sendCode": "Hantar",
    "signBy": "Dengan mendaftar, Saya setuju Terma dan Syarat dan Dasar Privasi",
    "verificationCode": "Masukan Kod Pengesahan",
    "verification": "Pengesahan",
    "userName": "Nama",
    "password": "Kata Laluan",
    "eMail": "E-mel",
    "signUp": "Daftar",
    "login": "Log Masuk",
    "welcomeRegister": "Selamat Datang ke Ginee",
    "welcomeLogin": "Selamat Datang ke Ginee",
    "welcome": "Selamat Datang ke Ginee",
    "copyProduct": "Salin Produk",
    "orderDetail": "Perincian Pesanan",
    "productDetail": "Maklumat Produk",
    "status_unpublished": "Belum diterbitkan",
    "status_update_failed": "Gagal diterbitkan",
    "status_delete": "Dipadamkan",
    "status_draft": "Draf",
    "status_disabled": "Tidak Aktif",
    "status_banned": "Dilarang",
    "status_sold_out": "Habis Jualan",
    "status_live": "Aktif",
    "status_process": "Sedang Diproses",
    "status_all": "Semua",
    "staffDnotExists": "Akaun pekerja tersebut tidak mempunyai kebenaran, sila hubungi peniaga untuk menambah kebenaran (pergi ke Senarai Pekerja - Tambah Pekerja - Tambah Akaun Ini)",
    "fastPrinting": "",
    "printPluginHasBeenUpdatedPlease": "",
    "afterUnbindTheStockWillNot": "",
    "areYouSureYouWantTo": "",
    "MSKUAutoBindSettingTip": "Klik sini untuk menyambung ke halaman tetapan pengikatan automatik Master Produk",
    "masterSkuInventoryInventorySynchronizationOf": "",
    "selectGenieCategory": "",
    "dayDuration": "",
    "unbindedChannelVariant": "",
    "deleteSkuConfirm": "Adakah anda pasti anda mahu memadam SKU?",
    "thirdpartyWarehouseProductDetails": "",
    "thereAreThirdpartyWarehouseProductAmong": "",
    "canNotDeleteThirdStock": "Stok produk di gudang pihak ketiga masih ada, tidak dibenarkan dipadamkan",
    "skuAffectedOutbound": "",
    "deleteSkuAlert": "",
    "proceedToDelete": "",
    "productAffectedOutbound": "",
    "nextStep1": "",
    "deleteProductAlert": "",
    "iNeed": "saya perlu",
    "youCanImportDataFormat": "Anda boleh mengimport 10,000 data (format: XLSX, XLS) setiap kali. Anda boleh mengedit produk master penerangan nama, kategori master dalam jisim.",
    "WorkingDaysBetween": "Antara 7-90 hari bekerja",
    "workingDays": "hari bekerja",
    "withoutMasterSku": "Tanpa Master SKU",
    "processTime": "proses Masa",
    "width": "lebar",
    "whenCreatingInTheProVersion": "Semasa membuat dalam versi Pro, anda perlu memuat naik bidang produk yang diperlukan, yang boleh digunakan dalam senario di mana Master Produk dicipta dan kemudian disalin ke saluran untuk dijual;",
    "whenAvailableStockInfo": "Apabila Tersedia Stock <= X send Email Reminder",
    "weightLimit": "Hendaklah antara 1-5,000,000!",
    "weight": "Berat",
    "inboundLimitTips": "Apabila menggunakan Fulfillment oleh JD, sila pastikan anda mengisi Shelf Life, Sebagai contoh, jika jangka hayat produk adalah 100 hari, dan had inbound adalah 0.5, maka jika jangka hayat adalah kurang daripada 50 hari ia tidak akan disediakan untuk negara ini.",
    "outboundLimitTips": "Apabila menggunakan Fulfillment oleh JD, sila pastikan anda mengisi Shelf Life, Sebagai contoh, jika jangka hayat produk adalah 100 hari, dan had keluar adalah 0.5, maka jika jangka hayat adalah kurang daripada 50 hari ia tidak akan disediakan untuk keluar.",
    "inboundLimit": "had inbound",
    "outboundLimit": "Had Outbound",
    "shelfLife": "Jangka hayat",
    "custom": "Custom",
    "warehouseStockTip": "Jumlah Saham Sebenar dalam variasi yang anda Selepas Master SKU dicipta, Master SKU secara automatik akan binded yang SKU betul-betul sepadan dengan Master SKU, Warehouse Stock = ganti Stock + Dikunci Stock + Available Stock + Promosi Stock",
    "warehouseStockLimit": "Warehouse Stock harus between0-999,999",
    "yearly": "tahunan",
    "monthly": "bulanan",
    "weekly": "Setiap minggu",
    "today": "hari ini",
    "warehouseStock": "Warehouse Stock",
    "warehouseName": "Nama gudang",
    "viewNow": "Lihat sekarang",
    "viewMore": "Lihat lebih banyak",
    "viewLess": "Lihat kurang",
    "view": "",
    "videoName2": "Bagaimana untuk menguruskan Kos Pembelian?",
    "variationTypeInfo": "Perubahan Jenis",
    "variationStatus": "Perubahan Status",
    "variationImageSizeLimit": "Berbilang imej boleh dimuat naik sekaligus. Sekurang-kurangnya 300 x 300 pix, sehingga 5MB (sehingga 8photos)",
    "variationImageInfo": "Edit Variant Image:",
    "variationCountDesc": "Menyusun Bilangan binded Perubahan dari tinggi ke rendah",
    "variationCountAsc": "Menyusun Bilangan binded Perubahan dari rendah ke tinggi",
    "variationAlreadyBound": "Perubahan ini telah diikat dengan Master SKU, sila alih keluar pertama!",
    "exportLimit2": "hanya boleh mengeksport 50000 Master produk setiap kali, sila jenis lagi",
    "exportLimit": "Sila masukkan jumlah halaman untuk memuat turun item, dan anda boleh mengeksport max. 50000 Master Produk.",
    "selectChannel": "Pilih Channel",
    "variation1": "Perubahan",
    "variantTypeRangeIs": "Variant jenis 1 pelbagai adalah 1-14 watak-watak",
    "variantTypeIsMissingPlease14": "Variant jenis 2 yang hilang, sila periksa!",
    "variantTypeIsMissingPlease": "Variant jenis 1 yang hilang, sila periksa!",
    "variantsInfoB": "10 produk (50 daripada 100 Kelainan boleh menjana Master SKU)",
    "variantsInfoA": "{ProductSum} produk ({availableNum} daripada {variationSum} Kelainan boleh menjana Master SKU) .Variant yang bakal Master SKU tidak boleh menjana Master SKU.",
    "numberOfProducts": "{ProductSum} Products ({} variationSum Spesifikasi)",
    "variantPictureLinkCannotBe": "Variant picture 1 pautan tidak boleh kosong!",
    "uploadFile": "Muat naik fail",
    "downloadTemplate": "muat turun Template",
    "step2Limit": "Sila ikut turun template peraturan Import, anda boleh memuat naik sehingga 10,000 peraturan setiap kali (format xlsx, xls)",
    "step2Dec": "Langkah 2: Import baru Auto-Bind semesta dalam jisim",
    "step1Dec": "Langkah 1: peraturan Eksport",
    "variantOptionsLimit": "Sehingga 20 pilihan varian",
    "variantOptionShouldBe": "Variant pilihan 2 harus 1-20 watak-watak",
    "import10000Tip": "Import sehingga 10.000 data pada satu masa.",
    "variantNameChannel": "Variant Nama Channel",
    "variantName": "Nama Variant",
    "variantImage": "imej varian",
    "username": "Nama pengguna",
    "useImageFor": "Gunakan imej untuk",
    "used": "digunakan",
    "operationReason": "operasi Sebab",
    "goToSettings": "Pergi ke tetapan",
    "stores": "Sila pergi ke [Tetapan produk] dan menghidupkan \"secara automatik mengikat Master SKU\" menetapkan sebelum menggunakan fungsi ini",
    "storeSKU": "Ciri tidak tersedia buat sementara waktu",
    "channelVID": "Channel ID Variant",
    "enabledAutoBind": "Auto-Bind penetapan Master SKU itu tidak dibolehkan, dan tidak ada peraturan lagi!",
    "editAutoBindRule": "peraturan Edit Auto-Bind",
    "updateAutoBindRules": "peraturan Update Auto-Bind",
    "exportAllAutoBindRules": "Mengeksport semua peraturan Auto-Bind",
    "enterStoreSKU": "Sila masukkan Store SKU",
    "bindingError": "The Store SKU telah terikat untuk lain Master SKU, sila periksa",
    "storeSkuLimit": "Kedai SKU harus kurang daripada 250 aksara",
    "bindMasterSku": "produk saluran yang berkaitan tetapi tidak terikat secara automatik mengikat Master SKU",
    "bindedStore": "binded Store",
    "bindedProduct": "binded Produk",
    "autoBind": "Auto-Bind",
    "stepExportRules": "kedai SKU",
    "variant": "Pelbagai",
    "updateStock": "Update Stock",
    "updateSafetyStock2": "Update Stock Keselamatan",
    "updateSafetyStock": "Import dan Update Stock Keselamatan",
    "updateInventoryInfo2": "Sila kemas kini inventori dalam \"Pengurusan Inventori\"",
    "updateInventoryInfo": "Sila edit inventori produk digabungkan dengan mengemas kini inventori Master SKU",
    "updatedSuccessfully": "dikemaskini Berjaya",
    "updateAvailableStock": "Update stok kedai sedia",
    "unitPurchasPrice": "Unit Harga Belian",
    "unitPurchasePrice": "Unit Harga Belian produk",
    "unbindInfo": "Produk ini telah unbinded daripada Master SKU, dan saham dikunci akan dikeluarkan",
    "unbind": "",
    "turnOnStock": "Menghidupkan Pemantauan Stock and Tetapan penyegerakan, dan inventori produk saluran Memula",
    "totalNum": "Jumlah {val}",
    "toShip": "hantar ke",
    "timeInfoWorkingDay": "hari bekerja",
    "timeInfoMonth": "bulan",
    "thisVariationOnly": "Perubahan ini hanya",
    "theSameNameIsRegardedAs": "(Nama yang sama dianggap sebagai produk yang sama. Panjang maksimum 300 huruf)",
    "theNameAlreadyExistsInTheStore": "nama yang sudah wujud di kedai",
    "theMasterSKUDoesNotExist": "The Master SKU tidak wujud, sila periksa!",
    "theLinkOfVariantPicture": "Pautan untuk Variant gambar 1 adalah tidak sah, sila masukkan semula!",
    "theInventorySkuAlreadyExists": "The Master SKU telah wujud!",
    "theCurrencyUnitOfTheDefault": "(The unit mata wang harga jualan lalai dan harga unit pembelian boleh menjadi Rp, PHP, ¥)",
    "theCategoryIDDoesNotExist": "kategori ID tidak wujud, sila muat turun jadual kategori dan menyemak",
    "theBasicVersionCanImportA": "Versi asas boleh mengimport sebilangan kecil medan yang diperlukan, dan dengan cepat boleh membuat Master SKU untuk membolehkan ciri pengurusan inventori",
    "syncInfoB": "{6787686756 Disable Inventori Sync",
    "syncInfoA": "{6787686756} Dayakan Inventori Sync",
    "successNumber": "Nombor kejayaan",
    "successfullyUnbindInfo": "Berjaya membuka ikatan {val} variasi (s)",
    "successfullyBindInfo": "Berjaya mengikat {val} variasi (s)",
    "storeWithoutSales": "Store Tanpa Jualan",
    "storeUpdateResult": "Store Update Keputusan",
    "storeSku": "kedai SKU",
    "storeNameB": "Nama kedai",
    "storeNameA": "Nama kedai",
    "storeCountDesc": "Susun Store Jualan dari tinggi ke rendah",
    "storeCountAsc": "Susun Store Jualan dari rendah ke tinggi",
    "stockSettingsStockMonitorAndSyncOn": "Saham Settings-Stock Monitor dan Sync, dihidupkan: 26-10-2020 17:17",
    "stockSettingsStockMonitorAndSyncOff": "Saham Settings-Stock Monitor dan Sync, dimatikan: 26-10-2020 17:17",
    "stockChannelInfo": "Saham yang boleh dijual di Channel, terdapat Stock = Warehouse Saham-ganti Stock-Dikunci Stock Stock-Promotion",
    "stockChanges": "Stock Perubahan",
    "stepImportAndCreateThe": "Langkah 2: Import dan mewujudkan Master Produk",
    "stepDownloadTheMasterCategory": "Langkah 1: Muat turun Master Kategori ID",
    "step3Text": "Langkah 3: Import untuk Bind Produk",
    "step2Text": "Langkah 2: Eksport master SKU yang",
    "step1Text": "Langkah 1: Pilih dan mengeksport Produk Channel yang anda ingin Ikat kepada master SKU",
    "spareStockTip": "Jumlah Saham terpelihara dalam gudang sebenar, yang tidak akan dijual dalam saluran.",
    "spareStock": "Stock ganti",
    "sourceUrl": "Source URL",
    "sortMasterProduct": "Susun Master Produk",
    "someWarehouseTheWarehouseStockOf": "",
    "somePartUpdateFailed": "Beberapa maklumat bahagian gagal",
    "somePartReviewFailed": "Beberapa kajian sebahagian gagal",
    "someDatasImportFailed": "Sesetengah Data yang Import Gagal",
    "someCreateFailed": "Beberapa Cipta Gagal",
    "skuStoreSku": "SKU (kedai SKU)",
    "skuAlreadyExistsPleaseDeleteThe": "SKU sudah tersedia, sila memadamkan produk sedia ada atau menamakan semula!",
    "singlePurchasePriceOfTheProduct": "Unit Harga Belian produk",
    "singlePurchasePrice": "Unit Harga Belian",
    "shouldBeBetweenLimitB": "Hendaklah antara 0-1000,000,000",
    "shouldBeBetweenLimitA": "Hendaklah antara 0-999,999",
    "shouldBeBetweenB": "Hendaklah antara 1-999,999!",
    "shouldBeBetweenA": "Hendaklah antara 1-999,999",
    "shippingFeeInformation": "Penghantaran Maklumat Bayaran",
    "setPageTip2": "Sila masukkan nombor yang sah halaman",
    "setPageTip": "Set Page Untuk Eksport",
    "selling": "Menjual",
    "selectProductBundle": "Pilih Produk Fail",
    "selectGineeCategory": "Pilih Master Kategori",
    "selectFile": "Pilih fail",
    "selectedMax20": "Dipilih (Max.20)",
    "selected": "dipilih",
    "selectCategories": "Pilih Kategori",
    "selectAtLeastOneInventorySku": "Pilih sekurang-kurangnya satu Master SKU",
    "editRule": "Edit Peraturan",
    "selectAtLeastOneImage": "Pilih sekurang-kurangnya satu Image",
    "createDates": "Buat masa",
    "seeXProducts": "Lihat X Produk",
    "seeMoreTips": "Lihat {val} Produk",
    "saveUpdate": "Simpan Update",
    "saveForReview": "Simpan untuk semakan",
    "savedSuccessfully": "Berjaya disimpan",
    "saveAsReviewed": "Simpan sebagai disemak",
    "salesTaxAmount": "jumlah cukai jualan",
    "safetyStockStatus": "Stock keselamatan Status",
    "safetyStock": "Stok keselamatan",
    "rupiah": "Rp",
    "reviewPassed": "ulasan Lulus",
    "reviewed": "Diulas",
    "reReview": "Re-ulasan",
    "reasonForFailure": "Sebab bagi kegagalan: tiada maklumat yang diperlukan, sila lengkapkan maklumat yang",
    "rangeLimitA": "Hendaklah antara 0-1,000,000,000",
    "rangeIsLimit": "Range ialah 5,000,000",
    "quantityLimit": "Kuantiti hendaklah antara 1-999,999",
    "qty": "qty",
    "purchaseTime": "pembelian Masa",
    "purchasePriceShouldLimit": "Unit Harga Belian hendaklah antara Rp0-Rp1000,000,000",
    "purchasePriceLimit": "Harga Sekiranya mengandungi antara 0-1,000,000,000!",
    "purchasePriceInfo": "(Tidak perlu input, harga pembelian Unit akan dikira pada kos purata)",
    "proVersionImportToCreate": "Pro Versi Import Buat",
    "promotionStockTip": "Promosi Stock adalah jumlah saham yang dikhaskan untuk Kempen atau Flash promosi Jualan",
    "promotionStock": "Stock promosi",
    "productType": "Jenis produk",
    "productsWithoutVariantsCanBeEmpty": "(Produk tanpa Kelainan boleh kosong)",
    "productSources": "Sumber produk",
    "productNameLimit": "Nama produk mestilah antara 5 - 300 aksara",
    "productNameInventorySku": "Nama produk & Master SKU",
    "productInformation": "informasi produk",
    "productImageLinkIsInvalid": "Imej produk 1 pautan tidak sah, sila periksa!",
    "productImageLinkCannotBe": "Imej produk 1 pautan tidak boleh kosong!",
    "productImage": "Imej produk",
    "productFailedToCreatePleaseDownload": "",
    "productEditSuccess": "Produk Edit Kejayaan",
    "productEditFailed": "Produk Edit Gagal",
    "productDescriptionShouldBeCharacters": "penerangan produk harus 1-7000 aksara",
    "productDescription": "Penerangan Produk",
    "productCreatedSuccessfully": "Produk Dibuat Berjaya",
    "product": "produk",
    "preorder11": "Pre-order",
    "popupResultButton": "Pamer Gagal Data",
    "pleaseEnterTheAmount": "Sila masukkan jumlah",
    "pleaseEnterKeywordsToSearchChannelProducts": "Sila masukkan kata kunci untuk mencari produk saluran",
    "pleaseEnter": "Sila Masukkan",
    "pleaseDownloadTheTemplateAndComplete": "Sila muat turun template dan melengkapkan data yang lengkap, dan kemudian mengimport produk dicipta (10000 Data-data boleh diimport setiap masa).",
    "pleaseChoose": "Sila pilih",
    "partiallyFailedInfo": "Sebahagiannya gagal, status masih Diulas",
    "packageSize": "saiz bungkusan",
    "orderToEnsureTheIntegrity": "",
    "orderToEnsureTheIntegrityOf": "",
    "optionLimit": "pilihan mesti mengandungi antara 1-30 watak-watak",
    "option": "pilihan",
    "operationTime": "Masa operasi",
    "operationGuide": "Panduan operasi",
    "noShelfLife": "No Shelf Life",
    "onSaleShop": "Jualan Shop",
    "onReview": "Semakan",
    "onlyShowBindedVariations": "Hanya menunjukkan variasi tanpa MSKU",
    "numVariationsSelected": "0 variasi yang dipilih",
    "numVariations": "{Val} variasi (s)",
    "numSelected": "{Val} Dipilih",
    "numEditBindInformation": "{Val} maklumat mengikat -Edit",
    "nowInbound": "",
    "normalProducts": "Produk normal",
    "noData": "Tiada data",
    "noChannelVariationId": "No Channel ID Perubahan",
    "newProduct": "Produk baru",
    "newAveragePurchasePrice": "New Purata Harga Belian",
    "newAverageCostPrice": "New Purata Harga Kos",
    "new": "Baru",
    "mutiVariations": "Produk ini mempunyai variasi, jika produk anda mempunyai pelbagai warna dan saiz variasi, sila pilih ini",
    "mustBeBetween": "Mesti mengandungi antara 1-8",
    "mskuAndstockVideo": "Apakah hubungan antara Master Produk dan Pengurusan Inventori?",
    "mSku": "MSKU",
    "more": "lebih",
    "minPurchaseQuantityShouldBe": "Min. kuantiti pembelian harus 1-1000",
    "minimumPurchaseQuantity": "kuantiti pembelian minimum",
    "mediaSettings": "Tetapan Media",
    "maxNumVal": "Max. {Val}",
    "maxNum": "Max. {Val}",
    "maxLengthNum": "Max. {Val} aksara",
    "maxLengthCharacters": "Max. {max} aksara",
    "maxLength14": "Max. 14 aksara",
    "maximumIs": "(Maksimum adalah 1,000,000,000)",
    "maximumCharacters": "(Maksimum 7000 aksara)",
    "max9": "Max.9",
    "masterSkuHasBeenMatchedWith": "",
    "masterProductsImage": "Master Produk & Image",
    "masterProductName": "Master Nama Produk",
    "masterProduct": "Master Produk",
    "master": "Master Produk",
    "massSettings": "Tetapan Mass",
    "mainPhotos": "Main Foto",
    "lockedStockTip": "Stock sedang menunggu untuk dipenuhi tetapi tidak dihantar lagi, New dibayar atau New perintah yang belum dibayar disegerakkan ke Ginee akan menambah saham Dikunci",
    "lockedStock": "Stock dikunci",
    "liveTips": "Julat: Termasuk Out-of-saham dan Produk Live",
    "live": "Live",
    "length": "Negara",
    "iWillShipOutWithinX": "Saya akan menghantar dalam tempoh x jam bekerja.",
    "iWillShipOutWithin22": "Saya akan menghantar dalam tempoh 2 hari bekerja. (Tidak termasuk cuti umum dan hari Perkhidmatan kurier tidak bekerja)",
    "iWillShipOutWithin": "Saya akan menghantar dalam tempoh 3 hari bekerja. (Tidak termasuk cuti umum dan hari Perkhidmatan kurier tidak bekerja)",
    "iskuStandsForInventorySku": "MSKU Tempat Letak untuk Master SKU",
    "iskuCannotBeEmpty": "Master SKU tidak boleh kosong!",
    "isAnUnprocessedOutboundInThe": "",
    "inventorySkuSpaceLimit": "Master SKU mengandungi ruang atau masukkan, sila edit",
    "inventorySkuLimit": "Master SKU harus kurang daripada 300character",
    "inventorySkuGenRules": "peraturan Master SKU generasi secara lalai: \\ n1. Master SKU akan mengambil yang pertama 50 watak-watak Varian secara lalai \\ n2. The Warehouse Stock akan sama kepada Stok Variant secara lalai, dan Stock Keselamatan akan kosong secara lalai \\ n3. Apabila Master SKU dihasilkan, varian akan terikat",
    "inventorySkuExistsLimit": "Master SKU sudah wujud, sila edit",
    "instructions": "arahan",
    "iNeedXWorkingDays": "Saya memerlukan X hari bekerja untuk dihantar",
    "iNeedXWeeksToShip": "Saya memerlukan X minggu untuk dihantar",
    "iNeedXDaysToShip": "Saya memerlukan X hari untuk dihantar",
    "importToEditProduct": "Import untuk Edit Produk",
    "importToBindIntro": "Ciri ini menyokong peniaga untuk produk kedai Bind kepada Master SKU dalam jisim untuk menguruskan inventori",
    "importToBind": "Import untuk Bind Produk",
    "importTip": "Mengimport sehingga 1000 data pada satu masa.",
    "importMasterProducts": "Import Master Produk",
    "importFileFormatIsIncorrectInfo2": "file template yang tidak betul diimport, sila periksa",
    "importFileFormatIsIncorrect": "Format fail import tidak betul, sila muat turun template import",
    "importFile": "import Fail",
    "importFailed": "import Gagal",
    "importAndCreateProducts": "Import untuk Buat Produk",
    "importAndAddInfo": "Anda boleh mengimport 10,000 data sekaligus (format: xlsx, xls) untuk menambah produk Master dalam jisim",
    "imageSizeLimit": "Saiz imej mestilah sekurang-kurangnya 300 x 300 piksel, sehingga 5MB",
    "ifItIsEmptyWillNot": "Jika ia kosong tidak akan dikemaskini",
    "hour": "jam",
    "hotSale": "Jualan hebat",
    "helpCenterLink": "Panduan operasi",
    "height": "tinggi",
    "hasBindedInfo": "Varian ini telah terikat untuk yang xxxxxxxx Master SKU. Sila kemas kini inventori dalam \"Pengurusan Inventori\"",
    "generateMaster": "Menjana Master SKU untuk produk dalam satu kedai untuk menguruskan inventori di pelbagai kedai-kedai",
    "filter": "penapis",
    "fileRules": "Fail yang terbesar: xlsx, xls hanya 5MB",
    "faqName2": "Cara Buat Master Produk menggunakan Import dalam asas Version?",
    "faqName1": "Cara Buat Master Produk menggunakan Import dalam Pro Version?",
    "failedReason": "Gagal Sebab: Produk ini boleh dihapuskan, sila semula penyegerakan produk",
    "failedNumber": "Nombor gagal",
    "exportUpTo": "Anda boleh mengeksport sehingga 10,000 keping data (format XLSX, xls) setiap kali untuk produk Bind dalam kelompok",
    "exportTip": "Sila semak produk yang anda hendak eksport.",
    "exportSelected": "eksport Dipilih",
    "exportOrImport": "Ekspot impot",
    "exportByPageTip": "Sila masukkan nombor yang sah halaman",
    "exportByPage": "Eksport oleh halaman",
    "export": "Eksport",
    "enterTheNameOfTheVariationForExampleColorEtc": "Masukkan nama bagi perubahan, sebagai contoh: warna, dan lain-lain",
    "enterProcessTime": "Masukkan Proses Masa",
    "enterPageNumber": "Sila masukkan beberapa laman untuk memuat turun produk master.",
    "enterOption": "Masukkan pilihan, sebagai contoh: merah, dan lain-lain",
    "enableInventorySync": "Membolehkan Inventori Sync",
    "editSafetyFtock": "Edit Ftock Keselamatan",
    "editOptionsBlue": "Edit Pilihan-Blue",
    "editInventory": "Edit Inventori",
    "editAvailableStock": "Edit Stock Available",
    "downloadTip": "muat turun",
    "downloadTheMasterCategory": "Muat turun Kategori Induk",
    "downloadTemplateInfo": "muat turun Template",
    "downloadProduct": "muat-turun",
    "downloadFailedData": "Muat Turun Gagal Data",
    "discontinued": "dihentikan",
    "disableInventorySync": "Melumpuhkan Inventori Sync",
    "descriptionRequired": "Sila masukkan penerangan dan memohon kepada produk anda",
    "descriptionLimit": "Nama produk mestilah antara 1 - 300 aksara",
    "delivery": "penghantaran",
    "deleteMPTips2": "produk Master telah dipadam",
    "deleteMPTips": "Memadam Gagal: {val} Master Produk",
    "deleteLimit": "Gagal Sebab: memisahkan Sila dari Isku {val}, dan memadam selepas itu",
    "delConfirmTitle": "Adakah anda pasti anda mahu memadam produk?",
    "delConfirmContent": "Selepas memadamkan Master Produk, Master SKU dalam Inventory Management juga akan dipadamkan",
    "defaultPrice": "harga lalai",
    "dataSources": "Sumber data",
    "datasImportSuccess": "Kejayaan Data-data Import",
    "datasImport": "Data-data Import",
    "creatingProduct": "produk mewujudkan",
    "createProductFailed": "Buat Produk Gagal",
    "costInformation": "Maklumat kos",
    "continueToBindInfo": "Anda boleh terus mengikat produk saluran dalam Master Senarai Produk",
    "condition": "Keadaan",
    "columnsCannotBeEmpty": "Tiang tidak boleh mengosongkan",
    "collectByAdd": "Kumpul & Add",
    "cny": "Tahun Baru Cina",
    "salesTaxAmountLimit": "Harga Sekiranya mengandungi antara 0-1,000,000,000!",
    "clearance": "pelepasan",
    "clear": "jelas",
    "checkNow": "Periksa sekarang",
    "channelVariationIdNotEmpty": "Saluran ID Perubahan dikehendaki!",
    "channelVariationId": "Channel ID Perubahan",
    "channelSellingStatus": "Channel Jualan Status",
    "channelProductsImage": "Channel Produk & Image",
    "channelProducts": "Channel Produk",
    "channelProductName": "Channel Nama Produk",
    "changeTo": "Bertukar kepada",
    "changeReason": "Tukar Sebab:",
    "changeIsku": "Tukar Master SKU",
    "ceneratedFromChannelProduct": "Dijana daripada Channel Produk",
    "categoryID": "kategori ID",
    "category": "kategori",
    "cancelUpdateConfirm": "Adakah pasti anda mahu membuang perubahan ini?",
    "bundledProduct": "Sertakan Produk",
    "bundle": "Fail",
    "boundInfo": "Terikat",
    "bindProduct": "Bind Produk",
    "bindNow": "Bind sekarang",
    "bindInstruction": "produk saluran Binding siap dengan Master SKU, sila muat turun template dan mengimport data untuk integrasi",
    "bindingType": "Jenis mengikat",
    "bindingInProgress": "Mengikat produk dalam jisim",
    "bindingInformation": "Maklumat mengikat",
    "bindInformation": "Maklumat Bind",
    "bindInfoB": "l, gree {id: 111.234} membuka ikatan dengan {6787686756}",
    "bindInfoA": "l, gree {id: 111.234} mengikat {6787686756}",
    "bindHistory": "Sejarah Bind",
    "bindedInventorySku": "Binded Master SKU",
    "binded0Variation": "Binded 0 Variasi",
    "weightShouldBeBetween": "Berat Sekiranya mengandungi antara 1-5000,000!",
    "heightShouldBeBetween": "Ketinggian Sekiranya mengandungi antara 1-999,999!",
    "widthShouldBeBetween": "Lebar Sekiranya mengandungi antara 1-999,999!",
    "lengthShouldBeBetween": "Negara Sekiranya mengandungi antara 1-999,999!",
    "betweenWeeks": "Antara 1-13 minggu",
    "betweenDays": "Antara 1-90 hari",
    "between21": "antara 7-15",
    "between": "antara 3-30",
    "beBetweenWorkingHoursMust": "Mesti mengandungi antara 1-8 jam kerja",
    "basicVersionImportToCreate": "Versi asas Import Buat",
    "theSameNameWillConsideredAs": "#VALUE!",
    "categoryName": "Nama kategori",
    "basicInformation": "Maklumat asas",
    "variantPictureLink": "Variant picture 1 pautan",
    "barcodeLimitC": "Kod bar hanya menyokong huruf, nombor dan -_and tidak harus lebih daripada 100 aksara",
    "productImageLink1": "Imej produk 1 pautan",
    "barcodeLimitB": "Barcode sepatutnya tidak lebih daripada 100 aksara",
    "barcodeLimitA": "Kod bar hanya menyokong huruf, nombor dan -_",
    "temporaryNotFollowingXProducts": "",
    "thisBarcodeIsAlreadyOccupiedBy": "",
    "currency": "mata wang",
    "barcode": "Barcode",
    "averagePurchasePrice": "Purata Harga Belian",
    "averageCostPriceInfo": "Purata Kos Harga = Unit Jumlah Harga Belian / Jumlah Warehouse Stock",
    "averageCostPrice": "harga kos purata",
    "variantOption1ShouldBe": "Variant pilihan 1 harus 1-20 watak-watak",
    "variantOption1": "Variant pilihan 1",
    "variantOption2": "Variant pilihan 2",
    "variantTypeIs2": "Variant jenis 2",
    "variantTypeIs": "Variant jenis 1",
    "unspecifiedProductsCanBeEmpty": "produk yang tidak dinyatakan boleh kosong",
    "availableStockTip": "Saham yang boleh dijual di Channel, terdapat Stock = Warehouse Saham-ganti Stock-Dikunci Stock Stock-Promotion",
    "availableStockLimit": "Ada stok tidak boleh kosong!",
    "availableStockInputLimit": "inventori gudang ≥ inventori lapang",
    "availableStockInfo": "Stock Available = saham saham-Promotion saham-saham Dikunci-ganti Warehouse, Edit saham didapati dengan mengemas kini stok Gudang",
    "availableStock": "Stock tersedia",
    "availableForDownloadCategory": "(Disediakan untuk kategori muat turun)",
    "autoMatchVariationInfo": "Selepas Master SKU dicipta, Master SKU kehendak auto binded variasi yang menyimpan SKU betul-betul sepadan dengan Master SKU",
    "articleName2": "Bagaimana untuk mengintegrasikan produk saluran untuk Master SKU?",
    "applyToAllVariation": "Terpakai kepada semua Variasi",
    "aOfb": "1-50 daripada 219",
    "allVariation": "semua Perubahan",
    "missingVariantPleaseCheck": "Hilang 1 varian, sila periksa!",
    "allUpdateFailed": "Semua maklumat gagal",
    "max1000": "Max. 1000",
    "missingMasterProductVariantRed": "Hilang Master Produk Variant: Red / S, Red / L",
    "massOperationHistory": "sejarah operasi besar-besaran",
    "publishToStore": "Terbitkan ke kedai",
    "chooseDestinationStore": "Memilih kedai destinasi",
    "selectStore": "Pilih Store",
    "nextStep": "Langkah seterusnya",
    "orderProcessingTimeFollowsTheShops": "Masa pemprosesan pesanan berikut tetapan kedai tersebut",
    "allUpdatedSuccessfully": "Semua berjaya dikemas kini",
    "allSuccessfullyInfo": "Semua berjaya ditukar kepada kajian yang belum selesai",
    "allSuccessfullyCreated": "Semua Berjaya Dibuat",
    "allReviewFailed": "Semua ulasan gagal",
    "shipTime": "proses Masa",
    "allReviewed": "semua Diulas",
    "allRecordsOfChannelProductsInfo": "Semua rekod produk saluran (yang pasti Master SKU) yang telah dikemas kini oleh Ginee di kedai-kedai saluran (30 hari terakhir)",
    "INeed": "saya perlu",
    "allFailedInfo": "Semua gagal, status masih Diulas",
    "allCreateFailed": "Semua Cipta Gagal",
    "hoursWorking": "jam bekerja",
    "instant": "segera",
    "orderProcessingDeadline": "Tarikh akhir Order Processing",
    "all": "",
    "toViewTheMassOperationRecords": "Disokong untuk melihat rekod operasi besar-besaran tempoh 7 hari yang lalu, daftar sekarang!",
    "toPublishToTheStoreHow": "Bagaimana untuk \"Terbitkan ke kedai\"?",
    "toBatchEditingOfMainProducts": "Bagaimana untuk \"Batch Mengedit Produk utama\"?",
    "thePriceOfTheProductIn": "",
    "productSyncronized": "",
    "isRecommendedToSynchronizeThePromotion": "",
    "promotionSync": "",
    "variationIdHasBeenBoundChannel": "",
    "productInformationMaster": "",
    "theInformationOfAnExistingMaster": "",
    "productReferenceMaster": "",
    "copy": "",
    "skuAutobindSettingEnabledMaster": "",
    "skuAutobindSettingNotEnableMaster": "",
    "MSKUAutoBindSettings": "",
    "matchingExact": "",
    "matchingFuzzy": "",
    "Choose": "Sila pilih",
    "stockWarehouse": "",
    "afterTheSafetyInfo": "Selepas saham keselamatan diaktifkan, jika saham yang ada bagi Master SKU adalah lebih rendah daripada saham keselamatan, peringatan akan dihantar melalui e-mel.",
    "nowIntegrate": "",
    "productHasBeenBoundChannel": "",
    "stockSynchronizationSettingsEnable": "",
    "inboundToWarehouseAlready": "",
    "partFailedDelete": "",
    "allFailedDelete": "",
    "allSuccessfulDelete": "",
    "close1": "",
    "productHasAOutboundListTo": "",
    "warehouseStockOfThisProductIs": "",
    "productHasBeenBoundToA": "",
    "afterTheChannelInfo": "Selepas produk saluran adalah terikat kepada Master SKU, inventori kedai itu dimulakan",
    "failedDelete": "",
    "productBindingStatusChannel": "",
    "boundChannelProductAll": "",
    "boundChannelProductPartially": "",
    "allChannelProductUnbind": "",
    "operationSuccessfulMass": "",
    "toTheMasterProductAnd": "",
    "temporaryUntrackedDisplay": "",
    "unbindMasterProductAndChannelProduct": "",
    "recoverMass": "",
    "temporaryUntrackedMass": "",
    "unbindMass": "",
    "deleteMass": "",
    "group": "",
    "reference": "",
    "untrackedTemporary": "",
    "combination": "",
    "variantMultiple": "",
    "addWarehouseStockLimit": "Warehouse Stock harus between1-999,999",
    "addVariation": "menambah Variasi",
    "addSpareStockLimit": "Saham hendaklah antara 0 dan 999,99",
    "supportSelectingMultipleStoresInThe": "",
    "variantNo": "",
    "theProductThatFailedToBe": "",
    "masterSkuHasBeenBoundBy": "",
    "masterProductsThatAreSetAs": "",
    "typeOfMasterProductCombined": "",
    "productSingle": "",
    "theGeneralOfMasterProductType1": "",
    "theGeneralOfMasterProductType": "",
    "notIncludeTheTemporaryUntrackedMaster": "",
    "skuHasBeenBoundByThe": "",
    "skuHasMatchedTheProductsOf": "",
    "skuHasPendingOutboundOrderOr": "",
    "stockOfTheMasterSkuIn": "",
    "errorPleaseRefreshThePageAnd": "",
    "retry": "",
    "productSuccessfullyCreatedMaster": "",
    "beBetweenMust": "",
    "addOption": "Pilihan tambahan",
    "stockMonitoringAndSynchronizationEnable": "",
    "toWarehouseInboundAdd": "",
    "channelProductsRefer": "",
    "failedUnbind": "",
    "addMskuArticle": "Bagaimana saya boleh menambah Master SKU?",
    "unbindAllFailed": "",
    "allUnbindSuccessfully": "",
    "partialUnbinding": "",
    "unbinded": "",
    "bound": "",
    "unbindedAll": "",
    "boundAll": "",
    "masterSkuHasEnableStockSynchronization": "",
    "youNeedToEnableStockSynchronization": "",
    "toProductListReturn": "",
    "failedDataDownload": "",
    "createFailedAll": "",
    "addManually": "menambah secara manual",
    "masterProductSuccessfullyCreatedIfYou": "",
    "failedToCreateSome": "",
    "masterProductView": "",
    "successfullyCreatedAll": "",
    "productSuccessfullyCreatedIfYouNeed": "",
    "productCanOnlyUseOneCurrency": "",
    "creatingTheMasterProductCanCompleted": "",
    "masterSkuHasBeenPlacedIn": "",
    "warehouseDefault": "",
    "toWarehouseAdd": "",
    "toStoreClone": "",
    "enableStockSynchronizationOfMasterProduct": "",
    "quicklyPublishTheMasterProductsTo": "",
    "informationWarehouse": "",
    "inbounded": "",
    "inboundNot": "",
    "skuHasBeenPlacedInInbound": "",
    "statusStock": "",
    "addByStore": "Auto add Oleh Store",
    "addBundleProduct": "Tambah Fail Produk",
    "oneCurrency": "",
    "variantSingle": "",
    "productsOfVariants": "",
    "synchronizationOfTheMasterSkuWill": "",
    "toTheNChannelsProductList": "",
    "channelsProductList": "",
    "goNow": "",
    "goToTheChannelProductList": "",
    "doNotHaveAnIntegratedStore": "",
    "toModifyGo": "",
    "skuHasBeenInboundToThe": "",
    "productCombined": "",
    "combinedProductAdd": "",
    "theContentToEditClick": "",
    "combinationProductsEdit": "",
    "preview": "",
    "atTheEndAdd": "",
    "increaseStartingValueAutomatically": "",
    "atTheBeginningAdd": "",
    "xxWithYyyReplace": "",
    "massEditSKU": "",
    "changeStockTo": "",
    "massEditStock": "",
    "applyToAllProducts": "",
    "masterDeleteFailedTip": "",
    "masterStockpdateFailed": "",
    "stockLimit": "",
    "reduceByPercent": "Mengurangkan sebanyak peratus",
    "addByPercent": "Tambah mengikut peratus",
    "reduceByAmount": "Mengurangkan sebanyak jumlah",
    "addByAmount": "Tambah mengikut jumlah",
    "massEdit": "",
    "mskuNoSpaces": "",
    "followingXProductsRestore": "",
    "productsAreSetAsTemporary": "",
    "masterSkuDoesNotMeetThe": "",
    "barcodeExist": "",
    "massEditPrice": "Harga Edit Massa",
    "changePriceTo": "Tukar harga ke",
    "replaceWith": "Gantikan dengan",
    "masterSkuLimit": "Master SKU harus kurang daripada 200character",
    "numberOfVariants": "Bilangan Variasi",
    "yourCurrentPackageDoesNotHave": "Pakej anda sekarang tiada keizinan untuk mengoperasi, sila langgan ke pakej baharu atau hubungi khidmat pelanggan untuk menaik taraf pakej",
    "yourNumberOfOrdersHasExceeded": "Bilangan pesanan anda telah melebihi had untuk pakej semasa (maksimum {X}), sila langgan atau hubungi khidmat pelanggan untuk menaik taraf pakej.",
    "salesTaxAmountTip": "Cukai harga unit produk",
    "purchaseTimeTip": "Tempoh masa dari penghantaran pembelian hingga pembelian masuk",
    "sourceUrlTip": "Sumber produk, seperti link pembekal atau jika produk pengikis dari link sumber",
    "chatLimitTip2": "Keizinan anda telah melebihi had pakej, sila pergi ke Ginee OMS untuk melaraskan sebelum terus menggunakan Ginee Chat",
    "chatNoResourcesLimitTip": "Bilangan kedai atau akaun pekerja telah melebihi had pakej, sila pergi ke Ginee OMS untuk melaraskan pakej sebelum terus menggunakan Ginee Chat",
    "chatNopermissionLimitTip": "Akaun anda tidak termasuk dengan ciri Ginee Chat, sila hubungi khidmat pelanggan untuk menaik taraf pakej tersebut dan log masuk lagi.",
    "packageLimit": "Had Pakej",
    "masterProductpublishToTheStore": "",
    "masterProductmassEdit": "",
    "stockStatusTip": "",
    "synchronizationIsNotEnabledYouNeed": "",
    "synchronizationIsEnabledStock": "",
    "stockSynchronizationStatusOfTheMaster": "",
    "stockSynchronizationStatusOfTheCombined": "",
    "open": "",
    "settingsManagementForAuthorizedThirdpartyAccounting": "",
    "accountingSettings": "",
    "addedBy": "",
    "timeAdded": "",
    "updater": "",
    "checkLog": "",
    "accurateOrder": "",
    "accurateProduct": "",
    "failedToGetChannelsLabelUrl": "",
    "bindWarehouse": "",
    "Prohibited": "",
    "interval": "",
    "productSales": "",
    "updatetime": "Masa Kemas Kini",
    "masterProductCreationTime": "",
    "channelProductCreationTime": "",
    "salesOfDifferentActivityLevelsOverlap": "",
    "salesRangeOfXCannotBe": "",
    "channelProductDimensionActivitySetting": "",
    "synchronizeallthexxinthewarehousewheremskusarelocatedtochannelskusaccordingtothexxxratiowhenthedefaultruleisenabledmskusthatalreadyexistinotherrulesarenotincluded": "Segerakkan semua XX dalam gudang di mana semua MSKU terletak pada semua SKU saluran mengikut nisbah XXX. Apabila peraturan lalai telah aktif, ia tidak akan termasuk MSKU yang sudah wujud dalam peraturan lain",
    "availablestockforotherstoresispushedto": "Stok kedai lain yang tersedia ditolak kepada 0",
    "allExceptxx": "Semua kecuali XX",
    "whentheavailablestockislessthanorequaltoxx": "Apabila stok tersedia kurang daripada atau sama dengan XX",
    "whenmsku": "Ketika MSKU",
    "stockThreshold": "Ambang Stok",
    "availablestocktransferstock": "Stok Tersedia + Stok Transit",
    "availablestock": "Stok Tersedia",
    "pleaseSelectawarehouse": "Sila pilih gudang",
    "addPolicy": "Tambah Dasar",
    "pushratio": "Nisbah Penolakan",
    "warehousename": "Nama Gudang",
    "pushbyfixedvalue": "Tolak stok dengan mengikuti nilai tetap",
    "pushbybatio": "Tolak stok berdasarkan nisbah",
    "setSyncPolicy": "Tetapkan dasar penyegerakan",
    "setSynchronizationconditions": "Tetapkan syarat penyegerakan",
    "selectMsku": "Pilih MSKU",
    "addMsku": "Tambah MSKU",
    "ruleNamecannotbeempty": "Nama peraturan tidak boleh dikosongkan",
    "pleaseenterarulename": "Sila masukkan nama peraturan",
    "executeRules": "Laksanakan Peraturan",
    "pushLog": "Log Penolakan",
    "default": "Lalai",
    "defaultRules": "Peraturan Lalai",
    "storeName1": "Nama Kedai",
    "stockType": "Jenis Stok",
    "pushType": "Jenis Penolakan",
    "mainStoreStockLocked": "Stok Kedai Utama Dikunci",
    "log": "Log",
    "addrule": "Tambah Peraturan",
    "stocklistpromptwhenadjustingstockstockadjustment": "[Senarai Inventori] Beri petua apabila melompat ke halaman untuk melaraskan stok: Pelarasan stok [(+)/(-)X]",
    "promptwhenaddingstockwithmanualinboundmanualinbound": "Petua semasa memasuk stok secara manual: Kemasukan stok secara manual【121133】",
    "whenmanuallypushinginventorymanuallyexecutestockpushrules": "Apabila menolak stok secara manual: Laksanakan peraturan tolak stok secara manual",
    "promptwhentheordercomesinneworder": "Petua apabila dapat pesanan baru: Pesanan baru [576512290177977223]",
    "promptwhenorderiscancelledordercanceled": "Petua apabila pesanan dibatalkan: Pembatalan pesanan [576512290177977223]",
    "promptwhenbindingforthefirsttimeenablestockmonitoringandsyncsettingsthenpushdatatostoresstock": "Petua untuk pengikatan kali pertama: Aktifkan tetapan pemantauan dan penyegerakan stok, simpan permulaan stok",
    "xxofchannelproductsfailed": "XX produk saluran gagal",
    "creationtime": "Masa Pembuatan",
    "pushquantity": "Bilangan Penolakan",
    "reasonforchange": "Sebab perubahan",
    "seemore": "Lihat Lebih",
    "imageMasterProduct": "Gambar & Master Produk",
    "partialSuccess": "Sebahagian Berjaya",
    "storeupdateresults": "Hasil kemas kini kedai",
    "pleaseentersearchcontent": "Sila masukkan kandungan carian",
    "storepushrecord": "Rekod Penolakan Stok",
    "stockValue": "",
    "isBestSellingItem": "",
    "isTrendingItem": "",
    "isNewItem": "",
    "isLowSellingItem": "",
    "isNormalItem": "",
    "mskuXAlreadyExistsInRule": "MSKU: {X} telah wujud dalam peraturan {Y}",
    "ruleNameAlreadyExists": "Nama peraturan telah wujud",
    "thereAreDuplicateRuleNameOr": "Terdapat pengulangan pada nama peraturan  atau Master Produk",
    "noteWhenTheInventoryThresholdIs": "Perhati: Apabila ambang stok diaktifkan, jika stok dalam \"Dasar Penyegerakan\" adalah kurang daripada atau sama dengan \"Ambang\", stok yang tersedia untuk kedai lain kecuali kedai utama akan ditolak kepada 0",
    "totalCapitalUsedFromAllWarehouses": "Jumlah pendudukan modal semua gudang",
    "storeIntegrationIsInvalidPleaseReintegrate": "Integrasi kedai tidak sah, sila integrasi semula",
    "mskuAndStoreAreNotBound": "MSKU dan kedai tidak terikat",
    "warehouseAndStoreAreNotBound": "Gudang dan kedai tidal terikat",
    "warehouseAndMskuAreNotBound": "Gudang dan MSKU tidak terikat",
    "productChannelAndMskuAreNot": "Produk saluran dan MSKU tidak terikat",
    "deleteRule": "Adakah anda pasti untuk menghapuskan peraturan {x}?",
    "ruleNameXChangedToY": "Nama peraturan: {X} diubah menjadi {Y}",
    "pushTypeChangeFromFixedValue": "",
    "pushTypeChangeFromProportionalPush": "",
    "warehouseNameWarehouseXHasBeen": "",
    "addRuleNewRule": "",
    "statusChangeStatusHasBeenChanged": "",
    "statusChangeStatus": "",
    "deleteRuleRuleHasBeenDeleted": "",
    "ruleChangeTheTotalOfStock": "",
    "policyChangeTheAvailableStock": "",
    "editAddedXMskuThresholdIs": "",
    "editXMskuThresholdChangedFrom": "",
    "editXMskuMainStoreHas": "",
    "editXMskuHasBeenDeleted": "",
    "afterDeletionThePolicyNeedsTo": "",
    "thirdpartyWarehouseReceptionTime": "",
    "whetherToReplaceProduct": "Pasti untuk menukar produk?",
    "whetherItIsAGift": "Pasti untuk memberi hadiah?",
    "addGiftBForOrder": "Tambahkan hadiah {A} untuk pesanan {B}, bilangan ialah {C}, dan gudang yang dipilih ialah {D}",
    "orderAWithProductBIs": "Pesanan {A} produk {B} digantikan dengan {C}, dan gudang yang dipilih ialah {D}",
    "changeSelectedWarehouseCToD": "Pesanan {A} barang {B} gudang yang dipilih ditukar daripada {C} kepada {D}",
    "gift": "Hadiah",
    "specifiedWarehouse": "Gudang yang ditentukan",
    "specifiedQuantity": "Bilangan yang ditentukan",
    "massAddForOrdersSpecifiedMsku": "Untuk peningkatan pukal pesanan: tentukan MSKU dan bilangan!",
    "replace1": "Tukar",
    "and1": "dan",
    "replaceInBatchForTheSpecified": "Untuk produk yang ditentukan di bawah pesanan, gantikannya dalam kelompok!",
    "changeWarehouseTo": "Tukar gudang sebagai",
    "specifiedProduct": "Produk yang ditentukan",
    "doWarehouseModificationForSpecifiedProduct": "Buatkan pengubahsuaian gudang untuk produk yang ditentukan di bawah pesanan! (Produk yang diubah suai mesti dikaitkan dengan gudang yang sepadan)",
    "doWarehouseModificationForAllProduct": "Buatkan pengubahsuaian gudang untuk semua produk di bawah pesanan! (Produk yang diubah suai perlu dikaitkan dengan gudang yang sepadan)",
    "byProduct": "Bedasarkan Produk",
    "byOrder": "Berdasarkan Pesanan",
    "processingMethod": "Kaedah Memproses",
    "addGift": "Tambah Hadiah",
    "replaceProduct": "Tukar Produk",
    "changeWarehouse": "Tukar Gudang",
    "modifyProductInformation": "Edit Product Info",
    "weightkg": "",
    "youMustAddAllTheVariation": "",
    "masterVariationName": "主商品规格名称",
    "operationSuccessPrompt": "",
    "checkProduct": "",
    "productHasBeenSubmittedAndIs": "",
    "minimumStockLimit": "",
    "noNeedToProvideAnyTransport": "",
    "thisTypeCanBeSelectedTo": "",
    "notShipped": "",
    "sellerShipping": "",
    "shipYourOrderToTheBLIBLI": "",
    "shippingUsingBlibliLogisticPartner": "",
    "minimumStock": "",
    "enterSellingPrice": "",
    "sellingPriceLimit": "",
    "originalPrice": "",
    "enterOriginalPrice": "",
    "changeMinimumStockTo": "",
    "restrictionsTheProductCannotBeEdited": "",
    "unboundMSKU": "",
    "bindMSKU": "",
    "Country": "",
    "ContinuetoPublish": "",
    "CreateTimeNewest": "",
    "CreateTimeOldest": "",
    "notification": "",
    "NoBrand": "",
    "PromotionActivity": "",
    "PromotionCampaign": "",
    "PromotionDuration": "",
    "PromotionPrice": "",
    "PromotionOriginalPrice": "",
    "cannot0": "",
    "EditStock": "",
    "ResultsFail": "",
    "SomeOperationsFailed": "",
    "Sort": "",
    "MostStock": "",
    "LeastStock": "",
    "Oldest": "",
    "Newest": "",
    "Within7Days": "",
    "Yesterday": "",
    "Today": "",
    "CountDraftFailed": "",
    "CountDraftSuccess": "",
    "CountRemoveFailed": "",
    "CountRemoveSuccess": "",
    "CountUnAvailableFailed": "",
    "CountUnAvailableSuccess": "",
    "CountAvailableFailed": "",
    "CountAvailableSuccess": "",
    "PartDraftFailed": "",
    "DraftFailed": "",
    "DraftSuccess": "",
    "PartRemoveFailed": "",
    "RemoveFailed": "",
    "RemoveSuccess": "",
    "PartUnAvailableFailed": "",
    "UnAvailableFailed": "",
    "UnAvailableSuccess": "",
    "PartAvailableFailed": "",
    "AvailableFailed": "",
    "AvailableSuccess": "",
    "InProcessDraftPublishModalTitle": "",
    "InProcessRemoveModalTitle": "",
    "InProcessDisableModalTitle": "",
    "InProcessPublishModalTitle": "",
    "StockRulesInfo": "",
    "ResultsClose": "",
    "ResultsTitle": "",
    "ApplyToAll": "",
    "MassEditStock": "",
    "SetUnAvailable": "",
    "SetAvailable": "",
    "DeletedDesc": "",
    "DeletedTitle": "",
    "PublicDesc": "",
    "PublicTitle": "",
    "DisabledDesc": "",
    "DisabledTitle": "",
    "productTime": "",
    "ProductSelected": "",
    "ProductParentSku": "",
    "ProductNameAndStore": "",
    "adjustSyncFailed": "",
    "UpdateStock": "",
    "NewStock": "",
    "ModifyStock": "",
    "CurrentStock": "",
    "adjustStock": "",
    "categories": "",
    "removeList": "",
    "productStatus": "",
    "savedAsdraft": "",
    "pleaseSelect": "",
    "ProductDescription": "",
    "masterCategory": "",
    "ProductImage": "",
    "defaultPriceError": "",
    "editDefaultPrice": "",
    "editDescription": "",
    "addAfterName": "",
    "addBeforeName": "",
    "weightShouldBeBetweenKg": "",
    "workingHours": "",
    "shippingSettings": "",
    "massEditProductStatus": "",
    "goToEdit": "",
    "theInventoryOfTheMasterProduct": "",
    "changeMinPurchaseQuantityTo": "",
    "massEditMinPurchaseQuantity": "",
    "massEditDimensionsCm": "",
    "massEditProductName": "",
    "editProductImage": "",
    "customDisplayInformation": "",
    "logisticsChoose": "",
    "selectionOfStoreswarehousesMass": "",
    "toMatchCategoryNeed": "",
    "categoriesMatched": "",
    "productsSelected": "",
    "categoryAttributesShopee": "",
    "categoryAttributesEdit": "",
    "theValueJustEnteredWantIt": "",
    "matchingCategoryAndVariantAttributesProduct": "",
    "productInfoSource": "",
    "noLogisticOptions": "",
    "sellingPrice": "",
    "productUpdateFailed": "",
    "publishFailed": "",
    "publishSucceeded": "",
    "massEditMinimumStock": "",
    "skuLimit": "",
    "sku": "",
    "packageSizeCM": "",
    "main": "",
    "maxval": "",
    "priceBetweenMy": "",
    "priceBetweenTh": "",
    "priceBetweenVn": "",
    "priceBetweenPh": "",
    "priceBetweenId": "",
    "weightg": "",
    "selectABrandPlease": "",
    "uploadAVariantPicturePlease": "",
    "uploadProductImagePlease": "",
    "enterADescriptionPlease": "",
    "enterTheProductNamePlease": "",
    "selectTheStatusOptionPlease": "",
    "selectPreorderOptionPlease": "",
    "enterLogisticsOptionsPlease": "",
    "enterThePackageSizePlease": "",
    "enterTheWeightPlease": "",
    "enterstock": "",
    "enterPrice": "",
    "enterSKU": "",
    "theProductHasVariationsIfYour": "",
    "enterAOptionForExampleRed": "",
    "addVariationType": "",
    "variationType": "",
    "productDescriptionLimit": "",
    "productDescriptionLimitTh": "",
    "productDescriptionLimitVn": "",
    "productDescriptionLimitPh": "",
    "productDescriptionLimitIdMy": "",
    "selectBrand": "",
    "ShopeeCategory": "",
    "addAttributeValue": "",
    "saveUpdateAndNextStep": "",
    "productNameLimitVn": "",
    "productNameLimitPh": "",
    "productNameLimitOthers": "",
    "productNameLimitId": "",
    "stocktLimitOhters": "",
    "stocktLimit": "",
    "setUnavailable": "",
    "setAvailable": "",
    "saveAsDraft": "",
    "percentageLimit": "",
    "attributesNo": "",
    "categoryNo": "",
    "categoryAndAttributeApply": "",
    "detailsView": "",
    "pleaseSetUpLogisticsInThe": "",
    "optionMaximum": "",
    "typeMaximum": "",
    "vietnam": "",
    "thailand": "",
    "philippines": "",
    "indonesia": "",
    "imageMustBeJOGPNGFormat": "",
    "imageTip": "",
    "preOrderLimit": "",
    "preOrderTip": "",
    "malaysia": "",
    "dangerousGoods": "",
    "dangerousGoodsTip": "",
    "dangerousGoodsOption": "",
    "attributeTip": "",
    "massEditTip1": "",
    "theMaximumRangeAccordingToApi": "",
    "requiresToThisInformationToBe": "",
    "chooseTheStoreFirstPlease": "",
    "variantImageTip": "",
    "variationTypeTip2": "",
    "variationoptionTip1": "",
    "applyAttribute": "",
    "noBrandBatch": "",
    "noBrandBatchTip": "",
    "massEditBrand": "",
    "previous": "",
    "emptyMass": "",
    "singapore": "",
    "canUploadMultiplePicturesAtOnce": "",
    "contentToEditClick": "Klik kandungan untuk mengedit",
    "Policydetail": "",
    "PolicyReminder": "Peringatan Dasar",
    "Clonesuccess": "Produk Berjaya Disalin!",
    "copyFailedResult": "",
    "copyCompete": "",
    "amountMustBeGreaterThanDiscount": "Jumlah harga mesti lebih besar daripada jumlah diskaun",
    "wholeStoreProduct": "Seluruh produk kedai",
    "valueMustBeGreaterThanThe": "Nilai mestilah lebih besar daripada kecerunan sebelumnya",
    "theSumOfTheAvailableStock": "Jumlah stok yang tersedia di gudang di mana semua MSKU berada, menurut",
    "ratioSyncToAllChannelSku": "Perkadaran segerak kepada semua SKU saluran",
    "whenthedefaultruleisonmskusthatalreadyexistinotherrulesarenotincluded": "Apabila peraturan lalai diaktifkan, tiddak akan mengandungi MSKU yang telah wujud dalam peraturan lain",
    "numberOfComments": "",
    "keywordType": "",
    "sortType": "",
    "number": "",
    "newRuleMasterProduct": "Peraturan baharu {0}; Master Produk {1}",
    "deleteRuleMasterProduct": "Padamkan peraturan {0}; Master Produk {1}",
    "storePolicyAddPolicyNew": "Dasar kedai {0}; Tambah dasar: tambah dasar",
    "storePolicyDeletePolicyPolicy": "Dasar kedai {0}; padam dasar: dasar dipadamkan",
    "ruleNameChangedFromTo": "Nama peraturan: ditukar daripada {0} kepada {1}",
    "masterProductNameChangedFrom": "Nama Master Produk: ditukar daripada {0} kepada {1}",
    "pushTypeNameChangedFrom": "Nama jenis penolakan: ditukar daripada {0} kepada {1}",
    "storePolicyWarehouseNameChanged": "Dasar kedai {0}; nama gudang: ditukar daripada {1} gudang kepada {2} gudang",
    "storePolicyInventoryTypeName": "Dasar kedai {0}; Nama Jenis Stok: Ditukar daripada {1} kepada {2}",
    "storePolicyStoreNameChanged": "Dasar kedai {0}; Nama kedai: ditukar daripada {1} kepada {2}",
    "storeStrategyPushRatioChanged": "Strategi kedai {0}; nisbah tolak: ditukar daripada {1} kepada {2}",
    "storePolicyPushFixedValue": "Dasar kedai {0}; nilai tetap penolakan: ditukar daripada {1} kepada {2}",
    "addmskuthresholdIsselectmainstoreIs": "Tambahkan {0} MSKU, ambang ialah {1}, pilih kedai utama ialah: {2}",
    "mskuhasbeendeleted": "MSKU telah dipadamkan",
    "masterproductchangedfromto": "Master Produk ditukar daripada {0} kepada {1}",
    "mainstorechangedfromto": "Kedai utama ditukar daripada {0} kepada {1}",
    "thresholdchangedfromto": "Ambang ditukar daripada {0} kepada {1}",
    "masterProductActivity": "Keaktifan Master Produk",
    "operationType": "Jenis Operasi",
    "salesTypeId": "Jenis Jualan",
    "salesRangeId": "Julat Jualan",
    "modifyChannelProductPrices": "Ubah suai harga produk saluran",
    "areyousureyouwanttodeletetheruleafterdeletedyouneedtoreconfigurethesynchronizationrules": "Adakah anda pasti untuk memadamkan peraturan? Selepas pemadaman, anda perlu mengkonfigurasi semula peraturan penyegerakan",
    "orderPushRecord": "Rekod Penolakan Pesanan",
    "productPushRecord": "Rekod Penolakan Produk",
    "youHaveNotActivatedStockMonitoring": "Anda belum mengaktifkan \"Pemantauan dan Penyegerakan Stok\"",
    "turnOnNow": "Aktifkan",
    "turnOn": "Aktif",
    "theSalesRangeOfCannot": "Julat jualan {} tidak boleh lebih tinggi daripada {}",
    "theValueOnTheRightSide": "Nilai di sebelah kanan kotak input tidak boleh kurang daripada nilai di sebelah kiri",
    "pushRecord": "Rekod Penolakan",
    "allWarehouse": "Semua Gudang",
    "allStoresExceptCustomStores": "Semua kedai kecuali kedai tersuai: {x}%",
    "pushFailed": "Penolakan Gagal",
    "pushed": "Telah Ditolak",
    "bindwarehouse": "Gudang Terikat",
    "afterTurnOnTheProductsThat": "Selepas dibuka, produk yang memenuhi peraturan ini akan ditolak ke bahagian belakang kedai",
    "afterDeactivationProductsThatMeetThis": "Selepas ditutup, produk yang memenuhi peraturan ini tidak lagi akan ditolak ke bahagian belakang kedai",
    "afterTheSettingIsCompleteAverage": "Selepas tetapan selesai, ia akan mengambil masa pada hari berikutnya untuk mengira purata jualan harian mengikut tetapan baharu.",
    "productDescriptionMustBeBetweenMinmaxcharacters": "Penerangan produk harus {min} hingga {maks} aksara",
    "repush": "",
    "pullAccurateStock": "",
    "accounting1": "",
    "returnManagement": "Pengurusan Pengembalian Barang",
    "returnOrderXxxRejectedToRefund": "Pengembalian xxx menolak bayaran balik kepada pembeli",
    "returnOrderXxxAgreedToRefund": "Pengembalian xxx menyetujui bayaran balik kepada pembeli",
    "returnOrderXxxRejectedRefundreturnRequest": "Pengembalian xxx menolak permintaan bayaran balik/pengembalian barang",
    "returnOrderXxxAcceptedRefundreturnRequest": "Pengembalian xxx menerima permintaan bayaran balik/pengembalian barang",
    "returnOrderXxxSuccessfullySyncedThe": "Pengembalian xxx berjaya disegerak, jenis pengembalian adalah \"xxx\", status pemprosesan adalah \"xxx\"",
    "rejectRefund": "Tolak Bayaran Balik",
    "acceptRefund": "Terima Bayaran Balik",
    "acceptRequest": "Terima Permintaan",
    "syncReturnOrder": "Segerak Pesanan Pengembalian",
    "confirmInbound": "Pasti Kemasukan Stok",
    "mass": "Secara Besar-besaran",
    "returnWarehouse1": "Gudang Pengembalian",
    "returnProduct": "Produk yang Dikembalikan",
    "doYouAgreeToRefund": "Pasti untuk menerima bayaran balik?",
    "pleaseSelectRejectionReason": "Pilih sebab penolakan",
    "doYouAcceptBuyersReturnRefund": "Sila pastikan untuk menerima permintaan bayaran balik pemulangan pembeli?",
    "onceYouAgreeItCannotBe": "Setelah disetujui, tidak akan dapat dibatalkan",
    "doYouAcceptBuyersRefundRequest": "Sila pastikan untuk menerima permintaan bayaran balik pembeli?",
    "exportAll": "Eksport Semua",
    "viewReturnRecords": "Lihat Log Pengembalian",
    "accept": "Terima",
    "isItADisputedOrder": "Adakah ini pesanan yang mempertikaikan?",
    "inboundNo": "Nombor Kemasukan Stok",
    "returnWarehouse": "Gudang Pengembalian",
    "returnAwb": "Nombor Penghantaran Pengembalian",
    "returnStatus": "Status Pengembalian",
    "processingDeadline": "Tarikh Akhir Pemprosesan",
    "processingStatus": "Status Pemprosesan",
    "closed": "Ditutup",
    "finished": "Selesai",
    "inDispute": "Sedang Mempertikai",
    "returnRemark": "Catatan Pengembalian",
    "variantchannelSkumsku": "Variasi/SKU Saluran/MSKU",
    "returnNo": "Nombor Pengembalian Barang",
    "disputedOrder": "Pesanan yang dipertikaikan",
    "cod": "COD",
    "separateMultipleInfoWithSpace": "Pisahkan berbilang mesej dengan bar ruang",
    "returnRefund": "Pengembalian Barang & Bayaran Balik",
    "returnOrder": "",
    "xRuleHasBeenDeletedPlease": "Peraturan {x} telah dipadamkan, sila semak rekod dalam log operasi",
    "checknow": "",
    "pushTime": "",
    "theActivityLevelOfTheChannel": "",
    "deleteMasterProduct": "",
    "pleaseEnterRemarks": "",
    "refundType": "",
    "refundOnly": "",
    "refundStatus": "",
    "returnInformation": "",
    "refundReason": "",
    "returned": "",
    "returning": "",
    "waitingBuyerToReturn": "",
    "returnRecord": "",
    "refundList": "",
    "mskuBindingStatus": "",
    "pleaseConfirmTheReturnedProductBefore": "",
    "combinedProductSales": "",
    "includingCombineProductSalesAndIndividual": "",
    "countTheSalesOfCombinedProducts": "",
    "pleaseSelectTheDataToBe": "",
    "salesOfIndividuallySoldItems": "",
    "salesOfProductsSoldByThe": "",
    "combinedProductMsku": "",
    "combinationProductMsku": "",
    "combinedProductName": "",
    "mainCommoditySalesAnalysis": "",
    "productDetailsOfThePortfolio": "",
    "combinationProductDetails": "",
    "combinedProduct": "",
    "exportChoice": "",
    "combinationProducts": "Produk Gabungan",
    "refundRate": "Kadar bayaran balik",
    "refundAmount": "Jumlah bayaran balik",
    "refundQuantity": "Bilangan bayaran balik",
    "refundOrderQuantity": "Bilangan pesanan yang dibayar balik",
    "numberOfFailedOrders": "Bilangan pesanan gagal",
    "numberOfReturnedOrder": "Bilangan pesanan dikembalikan",
    "numberOfCanceledOrder": "Bilangan pesanan dibatalkan",
    "numberOfShippedOrders": "Bilangan pesanan diterima",
    "numberOfOrdersInDelivery": "Bilangan pesanan sedang menghantar",
    "numberOfReadyToShipOrder": "Bilangan pesanan menunggu penghantaran",
    "numberOfPaidOrder": "Bilangan pesanan telah dibayar",
    "numberOfPendingPaymentOrder": "Bilangan pesanan menunggu pembayaran",
    "effectiveSalesAmount": "Jumlah Penjualan Efektif",
    "totalSales": "Jumlah Penjualan",
    "totalProductSales": "Jumlah Penjualan Produk",
    "relatedOrderQuantity": "Bilangan pesanan yang berkaitan",
    "msku": "MSKU",
    "creationTime": "Masa Pembuatan",
    "keywords": "Kata Kunci",
    "isACombinationProduct": "Adakah anda pasti untuk menggabungkan produk?",
    "salesQTY": "",
    "failedGenerateInboundList": "",
    "someMasterProductDoNotExist": "",
    "masterProductIsEmptyPleaseRebind": "",
    "refundInformationNotCompletePleaseRefresh": "",
    "mpType": "",
    "updateDates": "Masa Kemas Kini",
    "returnOrderXHasSuccessfullyCreated": "",
    "createInboundList": "",
    "noteThereIsADelayIn": "",
    "theOrderIsBeingPushedTo": "",
    "pushTaskSuccessfullyCreated": "",
    "refundLog": "",
    "refundNo": "",
    "refundOrder": "",
    "theServiceIsBusyOrYou": "",
    "cancellationIsNotAllowedWhilePush": "Pembatalan tidak dibenarkan apabila sedang menolak",
    "synchronizeAllMskuInWarehouseTo": "Segerakkan  semua gudang MSKU ke semua sku saluran mengikut nisbah {x} dan {y}",
    "youDoNotHavePermissionAt": "Anda tiada kebenaran, sila hubungi pengurus untuk mengkonfigurasi kebenaran dalam [Pengurusan Peranan]",
    "lowstockthresholdValueCannotHigherThan": "lowStockThresold tidak boleh tinggi daripada bilangan stok",
    "descriptionUpToCharacters": "Penerangan maksimum 1000 perkataan",
    "skuPromotionPriceCannotHigherThan": "Harga jualan SKU tidak boleh tinggi daripada harga asal, sila ubah",
    "skuPromotionStartTimeCannotBe": "Masa mula promosi SKU tidak boleh dikosongkan",
    "skuPromotionEndTimeCannotBe": "Masa tamat promosi SKU tidak boleh dikosongkan",
    "skuLowstockthresholdValueCannotHigherThan": "SKU lowStockThresold tidak boleh tinggi daripada bilangan stok",
    "variantSkuCannotBeEmpty": "SKU variasi tidak boleh dikosongkan",
    "productSkuCannotBeEmpty": "SKU produk tidak boleh dikosongkan",
    "thisSkuAlreadyExistsInThis": "SKU:${0} ini telah wujud di kedai ini, sila ubah sekali lagi",
    "productMainImageCannotBeEmpty": "Gambar utama produk tidak boleh dikosongkan",
    "promotionPriceCannotBeHigherThan": "Harga promosi tidak boleh tinggi daripada harga asal",
    "theInputRangeFromMin": "Julat masukan ialah { min } - { maks}",
    "addVariants": "Tambah Variasi",
    "shippingClass": "Kelas penghantaran",
    "crosssells": "Cross-sells",
    "upsells": "Upsells",
    "enableThisToOnlyAllowOne": "Aktifkan ini untuk membenarkan hanya satu daripada item ini dibeli dalam satu pesanan",
    "soldIndividually": "Dijual secara individu",
    "lowStockThreshold": "Ambang stok rendah",
    "allowBackorders": "Izinkan backorders",
    "enableStockManagementAtProductLevel": "Aktifkan pengurusan stok pada peringkat produk",
    "manageStock": "Urus stok",
    "taxClass": "Kelas cukai",
    "taxStatus": "Keadaan cukai",
    "usedForVariations": "Digunakan untuk variasi",
    "visibleOnTheProductPage": "Terlihat pada halaman produk",
    "thisIsAFeaturedProduct": "Ini ialah produk unggulan",
    "showSharingButtons": "Tunjukkan butang kongsi",
    "showLikes": "Tunjukkan suka",
    "likeAndShares": "Suka dan Kongsi",
    "hidden": "Tersembunyi",
    "searchResultsOnly": "Hasil pencarian sahaja",
    "shopOnly": "Kedai sahaja",
    "shopAndSearchResults": "Kedai dan hasil carian",
    "catalogVisibility": "Keterlihatan katalog",
    "heightMinMax": "Tinggi harus diantara {min}-{max}",
    "widthMinMax": "Lebar harus diantara{min}-{max}",
    "lengthMinMax": "Panjang harus diantara {min}-{max}",
    "weightMinMax": "Berat harus diantara {min}-{max}",
    "pleaseEnterProductDescription": "Sila masukkan penerangan",
    "productNameMinMax": "Nama produk harus di antara {min}-{max}  perkataan",
    "variantPicture": "Gambar Variasi",
    "productImageMax": "Gambar produk maks. {number} sahaja",
    "promotionTime": "Masa Promosi",
    "promotionPrice": "Harga Promosi",
    "options": "Pilihan",
    "variantType": "Jenis Variasi",
    "productAttributes": "Atribut Produk",
    "productShortDescription": "Penerangan pendek produk",
    "productTag": "Tag Produk",
    "updatedateasc": "Kemas kini Masa Tertua hingga Terkini",
    "updatedatedesc": "Kemas kini Masa Terkini hingga Paling Lama",
    "createdateasc": "Buat Masa Terbaru hingga Paling Lama",
    "createdatedesc": "Buat Masa Tertua hingga Terkini",
    "withoutmp": "SKU Induk Tidak Terikat",
    "withmp": "Mengikat SKU Induk",
    "generateInventorySKU": "Menjana SKU inventori",
    "onBackorder": "Pada pesanan belakang",
    "productNameAndStore": "Nama produk & Kedai",
    "sync": "segerak",
    "inStock": "dalam stok",
    "soldOut": "habis dijual",
    "disabled": "dilumpuhkan",
    "china": "China",
    "wooPublishStatus": "Di kedai, produk Tertunda dipaparkan di tab Proses IN",
    "wooActiveStatus": "Di kedai, produk yang diterbitkan dipaparkan di tab langsung",
    "wooDeactiveStatus": "Di kedai, barang peribadi akan dipaparkan di tab yang telah dilepaskan Ginee dari rak.",
    "wooDraftStatus": "Di kedai, barang-barang dalam draf akan dipaparkan dalam tab draf Ginee.",
    "inProcess": "dalam proses",
    "stockStatus": "Status Saham",
    "externalProduct": "Produk luaran",
    "groupProduct": "Produk kumpulan",
    "variable": "pemboleh ubah",
    "simpleVariation": "sederhana",
    "editStatusError": "Sila klik butang Pasti, lalu klik Terbit ke Kedai",
    "productInfoSetting": "Tetapan Maklumat Produk",
    "choiceMskuTip": "Selepas memilih Master SKU, sistem akan membawa masuk maklumat harga dan stok Master SKU, dan mengikat maklumat SKU Blibli dengan Master SKU tersebut.",
    "categoryMatch": "Kategori Padanan",
    "variationLimit": "Kategori yang dipilih tidak menyokong penambahan variasi",
    "clickToEdit": "tekan pada maklumat dalam jadual untuk edit.",
    "whatsInTheBox": "Apa yang ada di dalam kotak",
    "MinPurchaseQuantity": "Min. kuantiti pembelian",
    "ProductDescriptionShouldBeCharacters": "Product description should be 6-25000 characters",
    "chooseStoreLimit": "Pilih sekurang-kurangnya 1 kedai tujuan untuk menyalin Produk",
    "replaceWith38": "Gantikan dengan",
    "addBetweenTheDescription": "Tambahkan antara keterangan",
    "addAtTheEndOfThe": "Tambahkan pada akhir penerangan",
    "addAtTheStartOfThe": "Tambahkan pada permulaan penerangan",
    "addBetweenName": "Tambah antara nama",
    "editProductName": "Edit Nama Produk",
    "theFileWillOnlyBeReserved": "Fail hanya akan disimpan selama 7 hari, sila semak atau muat turun tepat pada waktunya",
    "theResultOfThePublishWill": "Hasil penerbitan akan diselaraskan dalam [Master Products - Mass Operation History]",
    "successfulQtyAllQty": "Qty yang berjaya / All Qty",
    "publishing": "Penerbitan",
    "taskStatus": "Status tugas",
    "publishedStoreStoreStore": "Kedai Terbitan: Kedai 1, kedai 2",
    "content": "Kandungan",
    "creator": "Pencipta",
    "taskType": "Jenis tugas",
    "allPublishFailed": "Semua penerbitan gagal",
    "somePublishFailed": "Beberapa penerbitan gagal",
    "allPublished": "Semua diterbitkan",
    "productsWereSuccessfullyPublished": "29 Produk berjaya diterbitkan",
    "publishingToChannelStores": "Penerbitan ke kedai saluran",
    "massEditOfShopeesCategoryAttribute": "Edit Massa atribut kategori Shopee",
    "matchTokopediaVariantType": "Padankan jenis Tokopedia Varian",
    "matchLazadaVariantType": "Padankan jenis Varian Lazada",
    "masterProductVariantType": "Jenis Varian Produk Utama",
    "massEditProductDescription": "Penerangan Produk Mass Mass",
    "shopeeCategoryAttribute": "Atribut kategori Shopee",
    "productNameInThisStoreIs": "Nama Produk di Kedai ini sudah digunakan",
    "partOfTheMasterProductIs": "Sebahagian dari Produk Utama kosong, sila ganti ke kedai",
    "chooseACountry": "Pilih Country/Region",
    "max11": "Maks. 1000",
    "massEditWeightG": "Berat Edit Massa (g)",
    "max7": "Maks. 500,000",
    "empty": "Kosong",
    "editLogisticsMass": "Logistik penyuntingan secara besar-besaran",
    "editBliblidraftmass": "Blibli / Mass Edit",
    "categoryAttributesBlibli": "atribut kategori blibli",
    "copyMass": "Salinan Massa",
    "categoryChoose": "Pilih kategori",
    "matchCategoryBlibli": "Pilih kategori",
    "storeWarehouse": "Stor / Gudang",
    "buyable": "Boleh dibeli",
    "MinimumStock": "Stok Minimum",
    "originalPriceLimit2": "Harga Asal mestilah lebih tinggi daripada Harga Jual",
    "sellingPriceLimit2": "Harga Jual tidak boleh lebih tinggi daripada Harga Asal",
    "originalPriceLimit": "Harga Asal Harus antara 1-100.000.000",
    "enterYoutubeLinkForExampleYouTubecomipadview": "Masukkan pautan Youtube, misalnya: YouTube.com/ipadview",
    "youtubeVideoLink": "Pautan Video Youtube",
    "exampleTheBatteryLifeIsUp": "Contoh: jangka hayat bateri sehingga 10 jam",
    "sellingPoint": "Titik Jual",
    "continueToAddProducts": "Terus menambah produk",
    "jdChannelIsUnderReviewCheck": "saluran blibli sedang dalam kajian. Periksa produk dalam senarai Dalam Proses, Kemas kini Gagal, atau Langsung",
    "failedReasons": "Sebab yang gagal",
    "productsUpdateSucceeded": "Kemas kini Produk berjaya",
    "changeSKUTo": "Tukar SKU menjadi",
    "massEditSellingPrice": "Mass Edit Selling Price",
    "massEditOriginalPrice": "Edit Harga Asal",
    "massEditSku": "SKU Edit Massa",
    "editVariantImage": "Edit Gambar Varian:",
    "multipleImagesCanBeUploadedAt": "Banyak gambar boleh dimuat naik sekaligus. Sekurang-kurangnya 600 x 600 piksel, sehingga 4MB to hingga 8 foto)",
    "shouldBeBetween": "Sepatutnya antara 0-1.000.000.000",
    "optionsnLimit": "Pilihan hendaklah kurang dari 20",
    "bigItem": "Item Besar",
    "smallItem": "Barang kecil",
    "qualitydays": "Hari Berkualiti",
    "afterSale": "Selepas Dijual",
    "Min1": "Min.1",
    "minpurchasePerOrder": "Min. Pembelian Per Pesanan",
    "pleaseEnterProductName": "Sila Masukkan Nama Produk",
    "blibliCategory": "Kategori BliBli",
    "partOfTheProductIs": "Sebagai keperluan kedai saluran untuk menerbitkan produk, beberapa bidang dalam Master Produk mesti diisi. Sila isikan maklumat yang diperlukan sebelum menerbitkannya ke kedai.",
    "blibliShouldBeBetweenA": "Sepatutnya antara 0.001-999.999",
    "blibliWeightLimit": "Sepatutnya antara 1-999,999!",
    "blibliStocktLimit": "Stok mestilah antara 0-999,999!",
    "blibliImageSizeLimit": "Ukuran gambar mestilah sekurang-kurangnya 600 x 600 piksel ， hingga 4MB",
    "serviceErrorPleaseContactGineeTechnology": "Kesalahan perkhidmatan, sila hubungi Ginee Technology",
    "minPurchaseLimit": "Min. kuantiti pembelian mestilah 1-1000,000,000",
    "enterThenChange": "Masukkan nilai terlebih dahulu kemudian ubah unit selepas itu",
    "goToTheBlibliSellerCenter": "Sila pergi ke Pusat Penjual Blibli untuk melengkapkan alamat gudang sebelum memilih logistik",
    "regularStoreSupport": "Menyokong Kedai Biasa",
    "toApiRestrictionsSomeVariantOption": "Oleh kerana sekatan API, beberapa pilihan varian tidak dapat diklon. Sila tambahkan jika perlu",
    "theCategoryAndVariantTypeApply": "Apakah kategori dan jenis varian berlaku untuk semua produk dari kategori Sumber yang sama (Kategori sumber: xxx / xxxx / xxxx dari laman web yang sama)?",
    "toApiRestrictionsSomeFieldsDo": "Oleh kerana sekatan API, Beberapa medan tidak menyokong penyuntingan",
    "shippingMethod": "Cara penghantaran",
    "backToProductList": "Kembali ke Senarai Produk",
    "chooseTheShippingMethodFirstPlease": "",
    "needToChooseNo": "",
    "fAq1": "",
    "fAq2": "",
    "fAq3": "",
    "synchronizationForThisProductHasBeen": "",
    "productDeleting": "",
    "operationWillNotDeleteTheProduct": "",
    "deleteTip": "",
    "productOrVariationImage": "Imej Produk",
    "imageTip2": "",
    "blibliSkuLimit": "SKU tidak boleh melebihi 50 aksara",
    "exampleTheAvailableInventoryOfWarehouse": "Contoh: Inventori gudang A yang tersedia ialah 100, inventori gudang B yang tersedia ialah 200, dan gudang tolak ditetapkan kepada A+B, dan nisbah tolak ialah 50%, kemudian data yang ditolak ke kedai sasaran C ialah 150 inventori",
    "exampleThePushFixedValueIs": "Contoh: Nilai tolakan tetap ialah 100, kedai sasaran tolak ialah A, dan apabila tolakan dilaksanakan, stok yang ditolak ke kedai A ialah 100",
    "ProductStatus": "Keadaan Produk",
    "productsThatFailToBePushed": "Produk yang gagal ditolak tidak dibenarkan ditolak lagi, sila hubungi pekerja ECCANG WMS untuk menyemak produk terlebih dahulu",
    "returnTime": "Masa Dikembalikan",
    "theProductHasNotPassedThe": "Produk ini tidak diluluskan",
    "shippingFeePaidBySeller": "Penjual membayar penghantaran",
    "skucanonlybenumberslettersandhyphenandthemaximumcharacters": "SKU hanya boleh terdiri daripada nombor, huruf dan garis mendatar, dan panjang maksimum SKU tidak boleh melebihi 30 aksara.",
    "skuNameMaxCharactersOnly": "Nama SKU, panjang terhad 50 aksara, hanya menyokong bahasa Cina dan Inggeris, nombor, garis tenha dan garis bawah",
    "productThatFailToPassThe": "Produk yang gagal lulus semakan Easy Gudang WMS akan gagal dihantar",
    "skuCanOnlyBeInUppercase": "SKU hanya boleh dalam huruf besar",
    "afterFailedToSendTheInbound": "Tidak sokong untuk menolak semula selepas penghantaran kemasukan stok gagal",
    "productEffectiveSales": "Jualan efektif produk",
    "pleaseNote": "Sila perhati:",
    "dueToTheLimitationOfThe": "Disebabkan oleh pengehadan API ECCANG WMS, SKU hanya sokong nombor, huruf dan garis mendatar, dan panjang SKU tidak boleh melebihi 30 aksara",
    "someOrdersAreNotAssignedWith": "",
    "ordersWithoutSublogisticsAreNotSupported": "",
    "waitingForDistributionLogisticsPleaseSynchronize": "",
    "productInformationSettingError": "",
    "userAddressInformationError": "",
    "notInShippingAddress": "",
    "deliveryFailure": "",
    "logisticsCannotSupportDistribution": "",
    "theMerchantSentTheWrongProduct": "",
    "productDamage": "",
    "productDescriptionDoesNotMatch": "",
    "businessAndUserPhoneToCancelOrder": "",
    "goodsDamageDuringLogisticsDelivery": "",
    "theUserAskedToCancelTheOrder": "",
    "logisticsAndDistributionCannotContactTheRecipient": "",
    "incompleteRecipientAddress": "",
    "thirdPartyMerchantsHaveNoInventory": "",
    "outOfDeliveryRange": "",
    "itIsOutOfStockAndCannotBeTraded": "",
    "theBuyerSInformationIsFilledInWrong": "",
    "latePayment": "",
    "maliciousBuyers": "",
    "theBuyerHasNoSincerityToCompleteTheTransaction": "",
    "theBuyerMadeAMistakeOrRemake": "",
    "unableToContactBuyer": "",
    "otherReasons": "",
    "unableToCompletePayment": "",
    "thePaymentMethodNeedsToBeChanged": "",
    "commodityPriceReduction": "",
    "wrongAddressInformation": "",
    "wrongProductInformation": "",
    "iDonTWantItAnymore": "",
    "goodsOutOfStock": "",
    "synchronizeAllMskusUnderXTo": "Segerakkan jumlah semua MSKU di bawah {x} ke {w} pada nisbah {y} kepada {z}",
    "pushInProcess": "",
    "waitingToPush": "",
    "rdWarehousePushStatus": "",
    "pushStatus": "",
    "printable": "",
    "productInboundStockCannotBeNegative": "Stok kemasukan produk tidak boleh negatif",
    "andAccordingToZ": "Segerakkan ke {w} pada skala {z}",
    "synchronizeallmskuunder": "Tambahkan jumlah semua MSKU di bawah {x} kepada {y}",
    "pushSucess": "Berjaya ditolak",
    "stockPushRuleaddRule": "Peraturan Tolak Stok/Tambah Peraturan",
    "peraturanTolakStokubahSuai": "Peraturan Tolak Stok/Ubah Suai",
    "peraturanTolakStoklihatPerincian": "Peraturan Tolak Stok/Lihat Perincian",
    "negativeInventoryCannotBeManipulatedInto": "Stok adalah negatif dan tidak boleh dimasukkan ke dalam gudang",
    "channelCategoryAttributes": "atribut kategori {channelName}",
    "switchingMskuRequiresReselectingWarehouseInformation": "Menukar MSKU memerlukan pemilihan semula maklumat gudang",
    "ShippingRuleSettings1": "",
    "TheWarehouseAllocatedByThe": "2. Gudang yang diperuntukkan oleh pesanan telah diikat ke gudang Accurate",
    "MskuHasSuccessfullyPushedTo": "1. MSKU telah berjaya menolak ke Accurate",
    "orderRulesToPushToAccurate": "Peraturan tolak pesanan Accurate adalah seperti berikut:",
    "OnlySupports": "3. Hanya menyokong \"(\", \")\", \"-\", \".\", \"_\" dan \"/\"",
    "OnlySupportsLetternumberCombination": "2. Hanya menyokong gabungan huruf/nombor",
    "NoMoreThanCharacters": "1. Tidak lebih daripada 30 aksara",
    "mskuRulesToPushProductTo": "Peraturan tolak MSKU produk Accurate adalah seperti berikut:",
    "downloadApp": "",
    "manageYourStoreAnytimeAnywhereGinee": "",
    "shippingMethodPriorityPickUp": "",
    "logisticsCompaniesThatDoNotSet": "",
    "pleaseSelectALogisticsCompanyFor": "",
    "expired": "Tamat tempoh",
    "deactivated": "Menyahaktifkan",
    "ecommercePlatform": "Platform Perniagaan Elektronik",
    "selfbuiltStation": "Stesen terbina sendiri",
    "financialSoftware": "Perisian keuangan",
    "originalName": "Asal",
    "editStore": "Edit storan",
    "pullData": "Tarik data",
    "reenable": "Benarkan semula",
    "deleteStore": "Padam storan",
    "areyousureyouwanttodeletethisstore": "Saya tak tahu. Anda pasti ingin menghapuskan kedai ini?",
    "theDefinitionOfDisabledIsStore": "",
    "authorizationInfo": "Maklumat kebenaran",
    "configManagement": "",
    "germany": "Jerman",
    "belgium": "Belgium",
    "italy": "Itali",
    "poland": "Poland",
    "authorized": "Diizinkan",
    "authTips": "",
    "youDoNotHavePermissionTo": "",
    "pleaseWaitPatientlyWhileTheStore": "Data kedai sedang ditarik. Sila tunggu dengan sabar",
    "startPullingTheStoreDataIt": "Ia dijangka mengambil beberapa minit untuk mula menarik data storan. Sila perhatikan penyegerakan data storan kemudian",
    "editSucceeded": "Edit berjaya",
    "AreYouSureYouWantToDeleteTheAuthorization": "Saya tak tahu. Adakah anda pasti untuk memadam keizinan?",
    "areYouSureToModifyThe": "",
    "CharactersLong": "",
    "pleaseStartWithTwoLettersOr": "",
    "pleaseEnterTheCorrectAkulakuTracking": "",
    "ImageName": "",
    "accordingToTheQueryConditionsYou": "",
    "calculationResults": "",
    "destination": "",
    "shipFrom": "",
    "calculationFormula": "",
    "checkShippingFee": "",
    "addAddress": "",
    "setAsDefault": "",
    "theBindingRelationshipBetweenJtExpress": "",
    "onceDeletedYouWillNoLonger": "",
    "confirmToDelete": "",
    "shippingSettings1": "",
    "reasonTheAuthorizationInformationIsIncorrect": "",
    "youCanUseTheJtExpress": "",
    "maxCharactersOnlyNumbers": "",
    "requiredToFillInPleaseGet": "",
    "withQuickAuthorizationYouDontNeed": "",
    "clickToViewAuthorizationInstruction": "",
    "clickAuthorizeToBindAndIt": "",
    "toUseAStandardAuthorizationPlease": "",
    "recomend": "",
    "standardAuthorization": "",
    "quickAuthorization": "",
    "theSystemDetectsThatChannelProducts": "",
    "yesApplyToAllProducts": "",
    "productInbound": "Kemasukan Produk",
    "manualOutbound": "Keluaran Stok Manual",
    "salesOutbound": "Keluaran Stok Jualan",
    "theNumberOfConnectedStoresThat": "",
    "subscriptionPackage": "",
    "theStoreHasBeenActivated": "",
    "deleteSuccess": "",
    "fulfillConfirmDisconnectContent": "",
    "unfold": "",
    "collapse": "",
    "expiredStatusComingSoon": "",
    "ForLogisticCompanyPleaseAdd": "",
    "WarehouseAsWarehouseNameNot": "",
    "jnePleaseFillTheLogisticThat": "",
    "whetherBlacklist": "",
    "pleaseSetupShipmentSettingsFirstBefore": "",
    "authorizeNow": "",
    "pleaseRecheckAfterAuthorizeLogistic": "",
    "temporaryNotAuthorize": "",
    "wholesalePrice": "",
    "addWholesalePrice": "",
    "maxNumber": "",
    "maxNumberCannotBeLessThan": "",
    "theWholesalePriceCannotBeHigher": "",
    "theWholesalePriceOfCannot": "",
    "wholesalePricesAreOnlyAvailableTo": "",
    "productNameMinCharacter": "",
    "productNotCanNotLessThan": "",
    "breadCrumbProductList": "",
    "storePermission": "",
    "channelPermission": "",
    "masterProductPermission": "",
    "priceManagementPermission": "",
    "toEnsureTheAccuracyOfAvailable": "",
    "masterUpdateAvailableStock": "",
    "pleaseSelectCompleteReceiverArea": "",
    "toCalculateShippingCostNeedTo": "",
    "calculateShippingFee": "",
    "detailAddress": "",
    "needToSelectAreaDimension": "",
    "maximumCharactersNumbersOnly": "",
    "maximumCharactersLettersOnly": "",
    "site": "",
    "gineeErrorUrllabelEmptyPleaseTry": "",
    "singleProductWeight": "",
    "calculate": "",
    "youDontHaveThisStorePermission": "",
    "logisticsServices": "",
    "theMaximumLengthIsCharacters": "",
    "averagePurchasePriceCurrentInbound": "Harga belian purata = (bilangan untuk kali ini dalam kemauskan stok * harga belian untuk kali ini dalam kemauskan stok + bilangan asal * harga belian asal) / (bilangan untuk kali ini dalam kemasukan stok + bilangan asal)",
    "tiktokMassDeleteTip": "",
    "validityofauthorization": "",
    "channelProductMassEdit": "",
    "dataPermission": "",
    "pleaseFillInTheCompleteRecipient": "",
    "pleaseSelectAPickupAddress": "",
    "thisAddressDoesNotCurrentlySupport": "",
    "tExpressIdIsNotAuthorized": "",
    "channelSku": "",
    "inventoryPushRulesMainStore": "Peraturan tolak inventori / kunci inventori kedai utama",
    "inventoryPushRuleOperationLog": "Peraturan tolak inventori / log operasi",
    "keywordsOfOperationContent": "Kata kunci kandungan operasi",
    "pleaseEnterMskuPleaseWrapMultiple": "Sila masukkan msku, sila balik msku berbilang",
    "skuExactSearch": "Carian tepat SKU",
    "viewMskuApplicationRules": "",
    "showAllStores": "Papar semua kedai",
    "bindingCommodityInformation": "Ikat maklumat produk",
    "analogComputation": "Pengiraan simulasi",
    "unmatchedChannelGoods": "Produk saluran yang tidak dipasangkan",
    "normal": "Biasa",
    "viewRules": "Semak semula peraturan",
    "promptThisFunctionSupportsViewingThe": "Petua: Ciri ini menyokong melihat peraturan yang sebenarnya digunakan oleh MSKU semasa semasa menolak inventori, dengan maksimum 50 MSTU dilihat pada satu masa",
    "newStoresWithoutSubsequentAuthorization": "(Tidak termasuk kedai baru untuk kebenaran berikutnya)",
    "newStoreWithSubsequentAuthorization": "(Kedai baru dengan kebenaran berikutnya)",
    "allConnectedStores": "Semua kedai yang bersambung",
    "pleaseTurnOnTheInventorySynchronization": "Sila hidupkan suis penyegerakan inventori di bawah menu Tetapan Inventori",
    "Ruledetails": "Butiran Peraturan",
    "Default": "",
    "thereAreDuplicates": "Terdapat pendua",
    "PleaseentertheMSKU": "Sila masukkan MSKU untuk ditambah, pemisah baris berbilang, tidak lebih daripada 200 aksara dalam satu baris, sistem akan dipotong secara automatik jika melebihi",
    "noNeedToPush": "Tidak perlu menolak",
    "mskuDoesNotExist": "",
    "mskuInventorypushInventory": "Inventori MSKU/Push Inventory",
    "proportional": "Berkadar",
    "IfTheMskuForThe": "4. Jika MSKU bagi jabatan tidak ditolak ke beberapa saluran, sila wujudkan peraturan MSKU yang ditetapkan;",
    "IfSomeMskusNeedTo": "3. Sekiranya beberapa MSTU perlu ditolak mengikut gudang / kedai yang ditetapkan / bahagian tertentu atau nilai tetap, sila buat peraturan MSKU yang ditentukan;",
    "IfTheChannelStoreNeeds": "2. Sekiranya kedai saluran perlu menggunakan inventori gudang yang ditentukan untuk menolak, sila buat peraturan kedai, tetapkan hubungan antara kedai dan gudang dan kaedah tolak;",
    "IfTheInventoryOfAll": "1. Sekiranya inventori semua kedai saluran ditolak mengikut peratusan inventori yang ada di gudang yang diaktifkan sepenuhnya, sila gunakan peraturan lalai secara langsung dan tetapkan nisbah tolak;",
    "directionsForUse": "Arahan untuk digunakan:",
    "youCanClickViewMskuApplication": "Anda boleh mengklik butang \"Lihat Peraturan Aplikasi MSKU\" untuk bertanya peraturan bahawa MSKU yang ditentukan sebenarnya digunakan untuk tekan",
    "thirdPriority": "Keutamaan ketiga",
    "secondPriority": "Keutamaan kedua",
    "firstPrioritySpecifyMsku": "Keutamaan Pertama: Tentukan MSKU",
    "inventoryPushInstructions": "Arahan penggunaan tolak inventori",
    "mskuCreationTimeIsLaterThan": "Penciptaan MsKU berlaku lebih lewat daripada suis tolak inventori tepat pada masanya",
    "theStoreBindingTimeIsLater": "Masa mengikat kedai adalah lebih lewat daripada masa pembukaan suis tolak inventori",
    "theWarehouseCreationTimeIsLater": "Gudang dibuat lebih lewat daripada suis tolak inventori tepat pada masanya",
    "reasonTheDefaultRuleConditionsAre": "Sebab: Syarat peraturan lalai tidak dipenuhi, sila buat peraturan dan tolak sendiri",
    "reasonTheChannelStoreStatusIs": "Sebab: Status kedai saluran tidak dibenarkan, sila sambungkan semula kedai dan tolak",
    "reasonThePushRuleForChannel": "Sebab: Peraturan tolak yang digunakan oleh produk saluran dilumpuhkan, sila hidupkan peraturan dan tolak",
    "reasonInventorySettinginventoryPushSwitchIs": "Sebab: Tetapan inventori - Suis tekan inventori tidak dihidupkan, sila hidupkan suis selepas menolak",
    "reasonTheStatusOfChannelGoods": "Sebab: Status produk saluran adalah draf, sila tolak produk selepas penyenaraian",
    "xpriceProtection": "",
    "order_628344ee0de79247bef8cb6694d1cd38": "",
    "fuzzySearch": "Carian kabur",
    "preciseSearch": "Perhalusi carian anda",
    "historicalOrders": "Perintah Sejarah",
    "nearlyDaysOrder": "Pesanan hampir 90 hari",
    "tipChannelInterfaceRestrictionOnlySupports": "Petua: Sekatan antara muka saluran hanya menyokong mengemas kini inventori item yang disenaraikan atau dikeluarkan",
    "theChannelStatusIsAbnormalPlease": "Status produk saluran tidak normal, sila sahkan dan tolak",
    "theWarehouseHasBeenDeactivatedPlease": "Repositori dinyahaktifkan, sila dayakan tolak selepas",
    "whenSearchingAccuratelyTheMaximumNumber": "Apabila mencari dengan tepat, anda boleh bertanya {X} paling banyak, dan menggunakan pulangan pengangkutan untuk berbilang mesej dan setiap baris mewakili mesej",
    "pleaseEnterTheOrderNumber": "Sila masukkan nombor pesanan",
    "settingSuccess": "",
    "insured": "",
    "insuranceRation": "",
    "pleaseSelectInsuranceService": "",
    "pleaseSettingInsuranceFeeForAkulaku": "",
    "settingInsurance": "",
    "proportionalInsurance": "",
    "originalInsurancePrice": "",
    "notInsured": "",
    "plannedInboundAmount": "Harga Kemasukan Stok Terancang",
    "actualInboundAmount": "Harga Kemasukan Stok Sebenar",
    "shippingSubsidy": "",
    "platformSubsidy": "",
    "spuAlreadyExistsPleaseRename": "",
    "templateType": "",
    "labelSize": "",
    "barcodePrintingTemplateSettings": "",
    "fontSize": "",
    "howToGenerate": "",
    "barcodeHeightInsideLabel": "",
    "qrCode": "",
    "qrCodeLocation": "",
    "left": "",
    "top": "",
    "qrCodeSize": "",
    "generatedByMsku": "",
    "generatedByBarcode": "",
    "generatedBySpu": "",
    "mskuCode": "",
    "printContent": "",
    "customAdd": "",
    "goToTheMasterProductPage": "",
    "customContent": "",
    "pleaseInsertTheContentToBe": "",
    "numberOfPrintsreferringToTheNumber": "",
    "paperPrintColumn": "",
    "columnGap": "",
    "barcodePrinting": "",
    "atLeastCharactersOrMore": "Sekurang-kurangnya 3 aksara atau lebih",
    "theFuzzySearchContentMustExceed": "Kandungan carian kabur mestilah lebih daripada 3 aksara, sila laraskan dan cuba lagi",
    "ordersCreatedForMoreThan": "Pesanan yang dibuat lebih daripada 90 hari akan dipindahkan ke perintah sejarah dan tidak boleh diproses",
    "areYouSureYouWantTo2": "",
    "moveToDelivered": "",
    "firstPriority": "Keutamaan Pertama",
    "stockWillBeIncreasedAfterInbound": "",
    "afterConfirmationTheDocumentWillBe": "",
    "stockWillBeDeductedAfterOutbound": "",
    "Days1": "30 hari",
    "Days": "7 hari",
    "clickToDownloadAttachment": "",
    "attachment": "",
    "addToTheFulfillmentWarehouse": "",
    "lockedTime": "",
    "LockedStock": "",
    "lockStockOrder": "",
    "onlySupportsFilesDocXlsXlsx": "",
    "clickOrDragFilesHereTo": "",
    "clickToDeleteAttachment": "",
    "uploadAttachment": "",
    "vatRate": "",
    "availableStockLocationCode": "",
    "stockWil": "",
    "stockWillBeReleasedAfterBeing": "",
    "xDataSelected": "",
    "massSetAsInvalid": "",
    "massPush": "",
    "massRefreshStatus": "",
    "oneItemPerLineSupportExcel": "",
    "clickToMassSearch": "",
    "salesOutboundList": "",
    "manualOutboundList": "",
    "addTemplate": "",
    "massOutbound": "",
    "afterConfirmationTheSelectedDataWill": "Selepas dipastikan, data yang dipilih akan ditambahkan semula ke gudang pemenuhan, sila beroperasi dengan berhati-hati",
    "massInbound": "",
    "itIsDetectedThatTheIssue": "Mengesan bahawa pesanan keluar telah dikemas kini kepada pustaka yang belum selesai atau telah dibatalkan, push tidak disokong, sila segar semula dan cuba lagi",
    "itIsDetectedThatTheReceipt": "Pesanan penghantaran telah dikesan untuk dikemas kini ke keadaan yang belum selesai atau bersara, push tidak disokong, sila segarkan semula dan cuba lagi",
    "refunding": "",
    "maxUploadDocuments": "Maks. muat naik 3 dokumen",
    "thirdPartyWarehousePushTime": "Tolak masa gudang pihak ketiga",
    "thirdPartyNumber": "Nombor Gudang Pihak Ketiga",
    "bulkSearch": "Cari secara kelompok",
    "thisSizeAlreadyExists": "",
    "theRangeOfSupportedInputIs": "",
    "skuName": "Nama SKU",
    "skusellerSKUInJDSellerCenter": "",
    "barcodeHeight": "",
    "QRCodeLocation": "",
    "beLeft": "",
    "beTop": "",
    "actualPrintingPerformanceDependsOnPrintingResults": "",
    "printingFailedReasonIsEmpty": "",
    "goUpgrade": "",
    "jtExpressIdOnlySupportsPaid": "",
    "ordersAreOutOfStockAnd": "Stok pesanan telah habis, penolakan stok tidak dibenarkan",
    "thereAreCurrentlyXPiecesOf": "Pada masa ini terdapat {x} data dan maksimum 50,000 data boleh dieksport dengan sekaligus. Jika anda perlu mengeksport lebih banyak data, sila hubungi khidmat pelanggan",
    "returnInboundListXSuccessfullyCreated": "",
    "successXFailedX": "",
    "homeCustomerTips": "",
    "orderOperationLogTips": "",
    "stockPushLogTips": "",
    "adjustmentMethodAll": "Kaedah Penyesuaian: Semua",
    "adjustmentMethodOutbound": "Kaedah Penyesuaian: Keluaran Stok",
    "adjustmentMethodInbound": "Kaedah Penyesuaian: Kemasukan Stok",
    "numberRow1": "Baris 1",
    "numberRow2": "Baris 2",
    "numberRow3": "Baris 3",
    "numberRow4": "Baris 4",
    "theStoreAuthorizationIsAbnormalPlease": "",
    "pleaseSetUpYourJtExpress": "",
    "theRangeOfProductImagesThat": "",
    "barcodeTemplate": "",
    "typeDimension": "",
    "channelOrderInTheCurrentOutbound": "Pesanan saluran dalam senarai keluaran stok tersebut telah habis stok dan tidak memenuhi syarat tolak. Sila tolakan selepas melakukan pengedaran semula dalam tab \"Proses Pesanan-Menunggu Diproses-Pesanan Bermasalah\"",
    "supportsInputNumberFromMinToMax": "",
    "inboundListNumber": "Nombor Kemasukan",
    "inboundQuantity": "Bilangan Kemasukan",
    "inboundWarehouse": "Gudang Kemasukan",
    "theTotalQuantityOfASingle": "",
    "aPrintTemplateWithTheSame": "",
    "printTemplateTip": "",
    "printMaxlengthTip": "",
    "adjustmentMethod": "Kaedah Penyesuaian",
    "purchaseInbound": "Pembelian Masuk Stok",
    "outbound": "Keluaran Stok",
    "inbound": "",
    "manualAdjustment": "Penyesuaian Manual",
    "sell": "Jual",
    "pleaseTickTheDataFirst": "",
    "changeValue": "Perubahan",
    "latestLocationStock": "Stok Lokasi Terakhir",
    "locationNumber": "Nombor Lokasi",
    "postcode": "",
    "sucGeneratedOrFailed": "",
    "printProductBarcode": "",
    "pleaseSelectAtLeastOneExport": "",
    "pleaseSelectAtLeastOneBasic": "",
    "inboundType": "Jenis Kemasukan",
    "orderIdXSuccessfullyReprintedThe": "",
    "orderIdXSuccessfullyPrintedThe": "",
    "setAsInvalidPopulerPrintOrder": "",
    "createPopularProductPrintOrderTask": "",
    "theOrderIsAlreadyInThe": "",
    "orderQuantity": "",
    "numberOFProducts": "",
    "taskId": "",
    "printProgress": "",
    "setAsInvalid": "",
    "alreadySetAsInvalid": "",
    "unfinished": "",
    "creating": "",
    "labelPrintingStatus": "",
    "additionalPrintLabel": "",
    "size": "",
    "ordinaryPrintingButPrintingUpTo": "",
    "selectedPrintDataXTheSystem": "",
    "taskDetails": "",
    "toPrintLabel": "",
    "manyProductsWithManyQuantity": "",
    "singleProductWithManyQuantity": "",
    "singleProductWithSingleQuantity": "",
    "theTaskIsBeingCreatedPlease": "",
    "createTask": "",
    "noteOrdersThatHaveBeenPlaced": "",
    "totalOrders": "",
    "createdByFilter": "",
    "createByTick": "",
    "printPopularProduct": "",
    "warehouseReplacementTask": "",
    "warehouseChangeSucceeded": "",
    "warehouseChangeFailed": "",
    "currentWarehouse": "",
    "newWarehouse": "",
    "processingProgress": "",
    "changeDimension": "",
    "Replace": "",
    "setTemplate": "",
    "onlyNumberAndMaxLimit": "",
    "minimalOrderForWholesaleMustBe": "",
    "pleaseConfirmWhetherTheOrderMoved": "",
    "moveToShipping": "",
    "priceMustBeCheaperThanThe": "",
    "theThirdPartyLogisticsIsNot": "",
    "deliverBy": "",
    "pickupBy": "",
    "maxOrderCannotBeLessThan": "",
    "clickDownloadAndTheSystemWill": "",
    "wholesalePriceCanNotBeLower": "",
    "thirdPartyLogisticsOnlySupportsThe": "",
    "thereIsNoStoreThatSupports": "",
    "productCloneInProgress": "",
    "countProductClonedTo": "",
    "exportProgress": "",
    "exportType": "",
    "copyToDraft": "",
    "invalid": "",
    "editImage": "",
    "editName": "",
    "editDesc": "",
    "editInfo": "",
    "printLabelStatus": "",
    "platformOrderNumber": "",
    "markAsFakeOrder": "",
    "noteOrdersThatHaveBeenPlaced1": "",
    "theNumberOfVariantCannotExceed": "",
    "stepConfirmThePictureInformation": "",
    "stepSelectTheProductYou": "",
    "editImagesInBatches": "",
    "productImageTip": "",
    "fakeOrder": "",
    "whetherFakeOrder": "",
    "massSettingFakeOrder": "",
    "copyAsDraftTip": "",
    "orderXCancelsTheFakeOrder": "",
    "orderXIsMarkedAsA": "",
    "fakeOrders": "",
    "menuProductShopify": "",
    "menuProductShopifyAdd": "",
    "menuProductShopifyEdit": "",
    "restrictedByJtExpressTheChinese": "",
    "tryingToGetChannelShippingLabel": "",
    "rangeFieldMessage": "",
    "tier": "",
    "min": "",
    "max": "",
    "afterConfirmationTheOrderWillBe": "",
    "onceThePushFailsPleaseTry": "",
    "noteThe": "",
    "theOrderIs": "",
    "shipmentFailed": "",
    "afterAutomaticDeliveryIsEnabledThe": "",
    "defaultFirst": "",
    "defaultLast": "",
    "pickUpTime": "",
    "automaticShipping": "",
    "warehouseToBeAssigned": "",
    "pushWarehouse": "",
    "tryingToLoad": "",
    "orderTotalTotalProductAmount": "",
    "onceVoidedTheOrderWillBe": "",
    "addedChannelProducts": "",
    "syncedChannelProducts": "",
    "syncedChannelProductsChangedFrom": "",
    "produkChannelTelahDisinkronkanDanInformasi": "",
    "theChannelProductsAreSynchronizedAnd": "",
    "editedProductNameFromTo": "",
    "editingProductCategoryFromTo": "",
    "editingLongDesecriptionForVariant": "",
    "editingPriceForVariantFrom": "",
    "editedStockChannelVariantName": "",
    "editedChannelSkuVariantName": "",
    "deletedVariantType": "",
    "addedVariantType": "",
    "deletedOption": "",
    "addedOption": "",
    "updatedProductImage": "",
    "updatedVariantImageForVariatName": "",
    "updatedOfProductSizeFrom": "",
    "updatedProductWeightChangedFrom": "",
    "addTheCheckedLogisticAnd": "",
    "deleteChannelProduct": "",
    "copyProductToChannelStore": "",
    "thisItemIsListed": "",
    "thisItemHasBeenRemoved": "",
    "variationNa": "",
    "variationNameTheChannelSku": "",
    "variatio": "",
    "variationNameIsChannelSku": "",
    "mskumanuallyUnboundTheCorrespondingChannelSku": "",
    "sinceStoreHasBeenDeletedchannel": "",
    "sinceMskuHasBeenRemovedchannelSkuwas": "",
    "manuallyAddedMasterProduct": "",
    "manuallyCreatedMasterProductByStore": "",
    "manuallyCreatedMasterProductByChannel": "",
    "createdMasterProductByImportData": "",
    "autoCreateMasterProduct": "",
    "createdMasterProductByScrapping": "",
    "editedMasterProductNameFrom": "",
    "editedMasterProductCategoryFrom": "",
    "editedMasterProductSpuFrom": "",
    "editedMasterProductShortDescriptionFrom": "",
    "editedTheMasterProductLongDescription": "",
    "editedMskuPriceFromTo": "",
    "editedAvailableStockForMskuFrom": "",
    "editedBarcodeOfMskuFrom": "",
    "updatedOfMasterProductSize": "",
    "updatedTheWeightOfMasterProduct": "",
    "updatedAvailableStockTheUpdatedStock": "",
    "deleteMaste": "",
    "deleteMskus": "",
    "printingSpus": "",
    "printingMskuBarcode": "",
    "printingBarcode": "",
    "mskuHasBeenAddedTo": "",
    "mskuHasBeenAddedAndInbound": "",
    "theAutomaticBindingOfMasterSku": "",
    "allStoresEnabledDefaultConfigurationFor": "",
    "activateByChanelsChanels": "",
    "openByStoreStore": "",
    "theAutomaticBindingOfTheMaster": "",
    "theAutomaticCreateMasterSkuRule": "",
    "turnOffTheAutomaticCreateMaster": "",
    "newPrice": "",
    "priceCannotBeModifiedDuringThe": "",
    "thereAreDuplicateExportTasksRemaining": "",
    "theSingleUrlCannotBeEmpty": "",
    "theLogisticsProviderCannotBeEmpty": "",
    "awbNumberCannotBeEmptyPlease": "",
    "syncSuccessfully": "",
    "syncFailed": "",
    "sourceOfError": "",
    "channelErrorPleaseContactTheChannel": "",
    "warehouseErrorPleaseContactTheWarehouse": "",
    "unknownErrorPleaseContactGineeErp": "",
    "recreate": "",
    "failedRecreate": "",
    "outboun": "",
    "outOrderNumberXAssociatedOrder": "",
    "orderNumberXAssociatedOrder": "",
    "rderNumberXAssociatedOrder": "",
    "oer": "",
    "tboundder": "",
    "odOrderNumberXAssociatedOrder": "",
    "outboundOrderNumberXAssociatedOrder": "",
    "inboundOrderNumberXAssociatedOrder": "",
    "orderwe": "",
    "outboundOrderNumberXRelatedOrder": "",
    "outboundOrderNumberXHasBeen": "",
    "inboundNoXPerformerYInbound": "",
    "inboundNoXNotPushedPerformer": "",
    "inboundNumberXSuccessfulPushPerformer": "",
    "inboundNoXPushFailedPerformer": "",
    "inboundNoXRefreshSuccessfulPerformer": "",
    "nomorInboundXRefreshGagalPerformer": "",
    "inboundNoXinvalidperformerYInboundWarehouse": "",
    "inboundNoXskuainvalidperformerYInboundWarehouse": "",
    "inboundNoXwaitingInboundPerformerY": "",
    "receiptNoXinboundPerformerYInbound": "",
    "push": "",
    "productsChannelEditProduct": "",
    "productsChannelAddProduct": "",
    "dueToPossibleDelaysInSyncing": "",
    "pleaseEnterBrandKeywordsToSync": "",
    "cantFindTheBrandClickMe": "",
    "theImageAspectRatioIsInvalid": "Nisbah aspek imej tidak sah, hanya imej dengan nisbah aspek {ratio} disokong",
    "theFollowingOrdersNeedToBe": "",
    "arrangementFailed": "",
    "arranging": "",
    "pleaseGoToPendingPrintOrder": "",
    "limitasiApiSkuTidakDapatDi": "",
    "ordersThatAreNotBoundTo": "",
    "customShop": "Sesuaikan kedai anda",
    "continueCreate": "",
    "theStoreWasCreatedSuccessfully": "",
    "inboundNumberXxxxPushedToWarehouse": "",
    "reasonTheOrderNumberXxxxHas": "",
    "inboundNoXxxxHasBeenSuccessfully": "",
    "inboundNumberXxxxHasSuccessfullyCreated": "",
    "xxWarehouse": "",
    "failureReasonXxxxErrorSourceXxxxx": "",
    "outboundNumberFailedToPushXxxx": "",
    "outboundNumberXxxxHasBeenSuccessfully": "",
    "outboundNumberXxxxHasSuccessfullyCreated": "",
    "inboundOrderPushed": "",
    "outboundOrderPushed": "",
    "sendersFullAddress": "",
    "sendersPostalCode": "",
    "senderArea": "",
    "manualPush": "Tolak secara manual",
    "pendingOrderOldVer": "",
    "pleaseGoToTheDeliverySettings": "Sila pergi ke tetapan penghantaran untuk mengisi",
    "noCustomStoreHasBeenDetected": "Sekiranya tiada kedai tersuai dikesan, sila buat kedai tersuai terlebih dahulu.",
    "goToCreate": "Pergi ke Buat",
    "enableAtLeastOneVariation": "Enapur Atlis Onevariatiang.",
    "createdAChannelProductBy": "",
    "storeSyncedChannelProduct": "",
    "ChangedFromTo": "",
    "deletedSkuWithVariantName": "",
    "addedSkuWithVarianName": "",
    "throughStoreDeleted": "",
    "deletedVar": "",
    "addedVa": "",
    "updatedVarianImageWithVarianName": "",
    "updatedProductSizeAndWeightInfo": "",
    "logisticsUpdatedChangedFrom": "",
    "atLeastOneSpecificationIsEnabled": "",
    "addMasterProduct": "",
    "editMasterProduct": "",
    "autoCreateMasterSku": "",
    "autoBindMasterSku": "",
    "addToWarehouse": "",
    "masterProductOperationLog": "",
    "solutionPleaseFirstCheckWhetherThe": "",
    "updatedProductLengthFromTo": "",
    "updatedProductWidthFromTo": "",
    "updatedProductHeightFromTo": "",
    "addedMsku": "",
    "onlyIntegersAreSupported": "Hanya integer disokong",
    "masstSku": "",
    "newOriginalPrice": "Harga asal baru",
    "newselingPrice": "Harga Newseling",
    "applicationCompletion": "Aplikasi ini lengkap",
    "editPrice": "Edit Harga",
    "theStatusOfProductHas": "",
    "theChannelPriceOfMsku": "",
    "defaultPriceOfMskuChanged": "",
    "solutionPleaseSetThePickupWarehouse": "",
    "deleteProduct": "",
    "unbindTheMasterProduct": "",
    "bindTheMasterProduct": "",
    "editedTheAutoBindMasterSku": "",
    "editedTheAutoCreateMasterSku": "",
    "createdMasterProductViaOpenapi": "",
    "manuallyCreatedMasterProductFrom": "",
    "automaticallyCreatedMasterProductFromWarehouse": "",
    "commonSearches": "Carian biasa",
    "moreSearches": "Carian lain",
    "becauseChannelSkuHasBeen": "",
    "updatedProductPriceFrom": "",
    "editedStockForProductFrom": "",
    "editedChannelSkuForProduct": "",
    "channelProductMatchToAutobind": "",
    "channelProductMatchesTheAutocreatedRule": "",
    "channelProductIsManuallyBound": "",
    "channelProductManuallyCreatedMsku": "",
    "distribution": "",
    "removeSelectedLogistics": "",
    "PickListIsPrinted": "Senarai pilih dicetak",
    "printedPacking": "Slip pembungkusan bercetak",
    "InvoiceIsPrinted": "Invois dicetak",
    "printeLabels": "Mencetak nota kurier",
    "bopis": "BOPIS",
    "bigProduct": "Big Product",
    "saveSearch": "Simpan kriteria carian",
    "commonSearchNames": "Nama carian biasa",
    "radio": "Radio",
    "multiplechoice": "Pelbagai pilihan",
    "updateTheOrderStatus": "Mengemas kini status pesanan",
    "updatedStockForProductFrom": "",
    "mengeditDeskripsiPanjangMasterProdukDari": "",
    "updatedAvailableStockForMskuTo": "",
    "nameManagement": "",
    "nameList": "",
    "ForChannelProductsWithVariations": "",
    "ChannelProductsWithoutVariationsUse": "",
    "theSystemDetectsThatSomeSkus": "",
    "updatedProductShortDescriptionFrom": "",
    "fuzzyOnlySupportsSingle": "Blur hanya menyokong satu sahaja",
    "scanShipment": "Imbas penghantaran",
    "editMasterProductName": "",
    "editChannelProductName": "",
    "newProductName": "",
    "utilityTool": "Utiliti",
    "TimesScanningIsRequiredFor": "2 kali: 2 kali perlu diimbas, imbasan pertama memaparkan maklumat pesanan, rekod imbasan kedua dihasilkan, dan pesanan dipindahkan ke koleksi belum selesai mengikut tetapan",
    "TimeScanOnceGenerateA": "1 kali: Imbas 1 kali, jana rekod imbasan dan alihkan pesanan ke dalam koleksi belum selesai mengikut tetapan",
    "twice": "Dua kali ganda",
    "once": "Sekali",
    "scanResultEffectiveTimes": "Bilangan kali keputusan imbasan telah berkuat kuasa",
    "afterScanningItWillAutomaticallyMove": "Beralih masuk untuk pengumpulan secara automatik selepas mengimbas",
    "scanSettings": "Seting imbasan",
    "scanned": "Diimbas",
    "pleaseScanOrEnterTheCourier": "Sila imbas atau masukkan nombor penjejakan",
    "scanByCourier": "Imbas melalui nota kurier",
    "afterTheScanIsSuccessfulThe": "Selepas pengimbasan berjaya, pesanan akan dipindahkan secara automatik ke senarai Belum selesai mengikut tetapan, sila pastikan bahawa tetapan telah selesai dan tetapan lalai tidak dihidupkan",
    "scanRecord": "Imbas rekod",
    "scanSucceeded": "Imbasan berjaya",
    "xxxxnumberDoesNotExist": "",
    "xxxxxHasBeenScannedPleaseDo": "{XXXXX} telah diimbas, jangan ulangi imbasan",
    "xxxxxIsNotASelfownedWarehouse": "{XXXXX}Pesanan pemenuhan gudang bukan milik, tidak boleh diimbas untuk penghantaran",
    "xxxxxIsNotAnOrderIn": "{XXXXX} Pesanan status pesanan tidak tertunda, tidak boleh diimbas untuk penghantaran",
    "xxxxxDoesNotExistPleaseCheck": "{XXXXX} tidak wujud, sila semak dan lihat semula",
    "scanFailed": "Imbas gagal",
    "pleaseScanOrEnterMskubarcode": "Sila imbas atau masukkan MSKU / Kod Bar",
    "useState": "Status penggunaan",
    "shelfName": "Nama rak",
    "informationShelf": "Maklumat rak",
    "modifyShelves": "Ubah suai rak",
    "commodityDetails": "Maklumat produk",
    "storageRack": "",
    "pleaseSelectAWarehouseAndAdd": "Sila pilih gudang dan tambah produk",
    "pleaseChangeToEnInputMethod": "",
    "scanResults": "Imbas keputusan",
    "notScanned": "Tidak diimbas",
    "scanStatus": "Status imbasan",
    "shelfNumber": "Nombor rak",
    "courierNumber": "Nombor Penghantaran",
    "sellerCashbackCoin": "",
    "tax": "",
    "sunTotal": "",
    "currentShelf": "Rak semasa",
    "vacant": "Kosong",
    "inboundAndOutboundRecordAdjustmentMethod": "Kaedah pelarasan rekod masuk dan keluar = jumlah rekod keluar manual (purata harga belian * nilai perubahan).",
    "adjustmentMethodOfInboundAndOutbound": "Kaedah pelarasan rekod masuk dan keluar = jumlah rekod jualan keluar (purata harga belian * nilai perubahan).",
    "calculationDescriptionAccordingToTheExport": "",
    "allScanned": "Semua diimbas",
    "partiallyScanned": "Sebahagiannya diimbas",
    "beforeBatchEditingYoudBetterSynchronize": "",
    "syncAndEdit": "",
    "skipAndEdit": "",
    "connectWithAllOfYourCustomer": "",
    "makesItEasyToMonitorThe": "",
    "xxxxDoesNotExistPleaseScan": "{XXXX} tidak wujud, sila imbas semula selepas produk utama ditambah",
    "theCombinationXxxCannotBePut": "Produk gabungan {XXX} tidak boleh disimpan secara manual, sila padam dan cuba lagi",
    "theTrackingNumberXxxxWasSuccessfully1": "Nombor penjejakan {XXXX} telah berjaya diimbas, dan status imbasan pesanan {XXXX} telah dikemas kini kepada Diimbas dan dialihkan ke Koleksi Belum Selesai",
    "theTrackingNumberXxxxWasSuccessfully": "Nombor penjejakan {XXXX} berjaya diimbas, dan status imbasan pesanan {XXXX} telah dikemas kini kepada Diimbas",
    "upgrade": "",
    "about": "",
    "pleaseSelectACategoryFirst": "",
    "subAccount": "",
    "authorizedStores": "",
    "availableQuantity": "",
    "addImagesToEachVariantOption": "",
    "productInformationDisplayedOnTheLogistics": "",
    "tiktokPrintShippingLabelAndPick": "",
    "downloadDocument": "",
    "thereAreOrdersThatStillBeing": "",
    "printHistory": "",
    "printAll": "",
    "maximumOrdersCanBePrinted": "",
    "unlimited": "",
    "otherCharges": "Kos lain",
    "bearTheCost": "Bayar yuran",
    "authorizationStatusTotalNumberOf": "",
    "expiredInfo": "",
    "storeStatusOfAllAuthorizedChannels": "",
    "status_banned_info": "",
    "theSumOfTheNumberOf": "",
    "stockListPageNotInboundTab": "",
    "theNumberOfMskusThatHave": "",
    "stockListPageStockWarningTab": "",
    "totalMskuWhichTheAvailableStock": "",
    "totalMskuOfStockListPage": "",
    "totalMskusWithAvailableStock": "",
    "TotalOrderOf": "",
    "returnRequestInfo": "",
    "totalOrderOfReturnManagement": "",
    "shopeelazadatiktokOrdersThatRequireTheSeller": "",
    "ordersThatHaveBeenPushed": "",
    "ordersThatHaveBeenPushedTo": "",
    "totalOrderOfPendingpushWarehousePage": "",
    "ordersThatNeedToBePushed": "",
    "totalOrderOnAbnormalOrdersPage": "",
    "ordersWithIncompleteInformationDueTo": "",
    "theNumberOfOrdersInThe": "",
    "allPrintableShippingLabelsAreDisplayed": "",
    "toBeProcessedArrangeShipment": "",
    "allShippableOrdersAreDisplayedIn": "",
    "pendingproblemOrdersTheSumOfThe": "",
    "whenTheOutboundListCannotBe": "",
    "xxxxThereIsMoreThanOne": "{XXXX} mempunyai lebih daripada satu dalam sistem, MSKU dan Kod Bar diduplikasi, sila semak dan cuba lagi",
    "servicePH": "",
    "serviceCN": "",
    "serviceTH": "",
    "serviceVN": "",
    "serviceMYandID": "",
    "accountInfo": "",
    "refundApplication": "",
    "returnRequest": "",
    "orderManagement": "",
    "sumOfTheNumberOfShopeelazada": "",
    "shopeebukalapaktokopediaOrdersUseTheGineeTemplate": "",
    "highSpeedPrintTaskDetails": "",
    "printTaskDetails": "",
    "regularPrint": "",
    "printUnlimitedShippingLabelInSingle": "",
    "clickHereToViewDetailedInstructions": "",
    "inOrderToImprovePrintingSpeed": "",
    "itIsDetectedThatYouHave": "",
    "thePrintingTaskHasExpiredPlease": "",
    "imageSizeLengthLimit": "",
    "imageManagement": "",
    "noMskuInTheDocumentHas": "Tidak ada percanggahan inventori dalam mana-mana MSKU dalam dokumen itu, dan tidak perlu menghasilkan pesanan pelarasan",
    "inventoryHasNotBeenCompletedPlease": "Inventori belum selesai, sila lengkapkan operasi pasca inventori terlebih dahulu",
    "stocktakingNumber": "",
    "checkingStock": "",
    "waitingStocktaking": "",
    "importStockTakingResult": "",
    "mskuNumber": "Bilangan MSKU",
    "stocktakingType": "",
    "numberOfMskuWithCountingDifferences": "Kirakan bilangan MSKU yang mempunyai perbezaan",
    "numberOfDifferences": "",
    "generateInventoryAdjustmentDocument": "",
    "pleaseCheckTheSpecificInformationIn": "Sila semak maklumat khusus di [Gudang - Lembaran Pelarasan Inventori], buat pelarasan manual, dan kemas kini inventori MSKU mengikut hasil pengiraan",
    "inventoryAdjustmentDocGeneratedSuccessfully": "Pesanan pelarasan inventori berjaya dijana",
    "voidSuccessfully": "Kejayaan yang tidak sah",
    "afterVoidingItIsNotSupported": "Selepas inventori tidak sah, inventori tidak dapat diteruskan, dan MSKU yang telah dikira tidak menyokong penjanaan pesanan pelarasan inventori untuk menyesuaikan inventori",
    "AfterTheCountingTheSystem": "3. Setelah inventori selesai, sistem secara automatik akan membetulkan inventori MSKU untuk pesanan jualan dan keluar yang gagal dihantar secara normal semasa tempoh inventori, dan pesanan sebenar yang telah dihantar / dibatalkan",
    "TheMskuInventoryThatHas": "2. Inventori MSKU yang belum didaftarkan untuk pengiraan inventori tidak akan dikemas kini, dan keputusan inventori MSKU akan dikemas kini kepada \"Tidak Dikira\", sila berhati-hati",
    "AfterTheInventoryIsFinished": "1. Selepas inventori selesai, status pesanan pengiraan akan ditukar kepada Selesai, dan operasi tidak dapat diteruskan",
    "confirmTheEndOfTheInventory": "Sahkan akhir inventori?",
    "theCurrentTaskIsStillBeing": "Tugas semasa masih dibuat, sila berbuat demikian kemudian",
    "clickStartCountingAndTheStatus": "Selepas anda mengklik Mulakan Inventori, status senarai inventori akan dikemas kini ke Inventori, dan halaman operasi inventori akan dibuka secara automatik untuk anda",
    "printInventoryList": "Mencetak senarai inventori",
    "endInventory": "",
    "startCounting": "Mulakan inventori",
    "inventoryByMsku": "",
    "wholeWarehouseInventory": "",
    "automaticallyFilterMskuXCombinationsAnd": "",
    "aTotalOfXMskuCan": "Kali ini, anda boleh mengira jumlah bilangan {X} MSKUs",
    "createInventoryTask": "",
    "aTotalOfXMskuAre": "Sejumlah {count} MSKU sedang menunggu inventori, senarai inventori diminta, sila tunggu, dan beralih ke senarai kiraan inventori untuk meneruskan operasi",
    "inventoryCreationTaskSubmittedSuccessfully": "Tugas penciptaan senarai inventori berjaya diserahkan",
    "afterConfirmationTheFilledInformationWill": "Selepas pengesahan, maklumat yang diisi tidak akan disimpan",
    "areYouSureYouDontWant": "Adakah anda pasti tidak menyimpan maklumat semasa?",
    "currentWarehouseInventory": "",
    "inventoryIsNotSupportedForCombination": "Inventori tidak disokong untuk MSKU dalam perhimpunan dan tugas inventori yang tidak lengkap",
    "mskuDetails": "Butiran MSKU",
    "inventory": "",
    "afterSavingTheInformationWillOnly": "Selepas menyimpan, maklumat itu hanya akan disimpan dalam senarai inventori, dan ia boleh diubah suai lagi",
    "inventoryLoss": "Kehilangan inventori",
    "inventoryProfit": "Keuntungan dari kuali",
    "panping": "",
    "inventoryResults": "Ambil stok keputusan",
    "takeStockInTheWarehouse": "Kira inventori",
    "takeStockOf": "Inventori",
    "counting": "Bilangan kiraan",
    "inventorySheet": "",
    "notCounted": "Tidak dikira",
    "TheCurrentAdjustmentDocumentStatus": "3. Status pesanan pelarasan semasa dikemas kini kepada [Pelarasan]",
    "TheCurrentMskuAdjustmentStatus1": "3. Status pelarasan MSKU semasa dikemas kini kepada [Tidak diselaraskan]",
    "TheSystemWillNotChange": "2. Sistem tidak akan mengubah inventori gudang MSKU semasa",
    "OnceItIsConfirmedThat": "1. Sebaik sahaja disahkan bahawa tiada pelarasan diperlukan, ia tidak boleh dikendalikan lagi",
    "confirmThatTheCurrentMskuInventory": "Sahkan bahawa inventori MSKU semasa tidak perlu diselaraskan?",
    "TheCurrentMskuAdjustmentStatus": "3. Status pelarasan MSKU semasa dikemaskini kepada [Selesai]",
    "withoutAdjustment": "Tiada pelarasan diperlukan",
    "confirmAdjustment": "Sahkan pelarasan",
    "unadjusted": "Tidak diselaraskan",
    "singleAdjustment": "Pelarasan tunggal keseluruhan",
    "continueToAdjust": "Teruskan menyesuaikan diri",
    "startAdjusting": "Mula melaraskan",
    "inventoryReductionMsku": "Pengurangan inventori MSKU",
    "increaseInventoryMsku": "Peningkatan inventori MSKU",
    "totalMsku": "Jumlah bilangan MSKU",
    "adjusting": "Membetulkan",
    "toBeAdjusted": "Untuk diselaraskan",
    "adjustTheSingleNumber": "",
    "afterItIsTurnedOnMsku": "Selepas diaktifkan, MSKU dalam tempoh inventori tidak menyokong peningkatan inventori dan potongan inventori, yang tidak menjejaskan penguncian inventori",
    "mskuDuringTheCountingPeriodDoes": "MSKU semasa inventori tidak menyokong operasi masuk dan keluar",
    "afterAdjustment": "Selepas pelarasan",
    "beforeAdjustment": "Sebelum pelarasan",
    "inventoryVariance": "Percanggahan inventori",
    "stockAdjustment": "",
    "continueCounting": "Teruskan mengambil stok",
    "confirmIntegrations": "",
    "mskuXDoesNotExistIn": "",
    "orderXxxxxStatusChangedToShipping": "Status pesanan {XXXXX} berubah kepada Penghantaran dalam {XXXX-XX-XX XX:XX}",
    "xxxAfterTheInventoryCountIs": "{XXX} Selepas kiraan inventori, MSKU boleh beroperasi masuk dan keluar dari gudang, dan secara automatik membetulkan nilai inventori mengikut perubahan status pesanan",
    "xxxGeneratesTheInventoryAdjustmentDocument": "{XXX} menjana nombor pelarasan inventori: {XXXX}, dan hasil kiraan: kehilangan inventori",
    "xxxGenerateTheInventoryAdjustmentDocument": "{XXX} menjana nombor pelarasan inventori: {XXXX}, hasil kiraan: keuntungan inventori",
    "theCurrentRepositoryIsDeactivatedPlease": "Repositori semasa dinyahaktifkan, sila berbuat demikian selepas senarai repositori diaktifkan",
    "theCurrentWarehouseIsNotIts": "Gudang semasa bukan gudang sendiri dan tidak boleh inventori",
    "theCurrentRepositoryDoesNotExist": "Repositori semasa tidak wujud, sila berbuat demikian selepas menambah senarai repositori",
    "AreYouSureToDelete": "Adakah anda pasti akan memadamkan maklumat semasa?",
    "defaultShelf": "Rak lalai",
    "AreYouSureToEnd": "Adakah anda pasti untuk mengakhiri kiraan?",
    "mskuInInventoryExistsInThe": "Sekiranya MSKU dalam dokumen semasa berada dalam inventori, sila lengkapkan inventori dan terus masukkan inventori",
    "thereIsMskuInCountingIn": "Sekiranya MSKU dalam dokumen semasa berada dalam inventori, sila lengkapkan inventori dan terus masukkan atau tinggalkan inventori",
    "AreYouSureToVoid": "Adakah anda pasti akan membatalkan maklumat semasa?",
    "keywordid": "",
    "onceTheAdjustmentIsConfirmedIt": "Sebaik sahaja pelarasan disahkan, ia tidak boleh digantung",
    "atTheBeginningOfAdjustmentThe": "Apabila pelarasan bermula, sistem akan mengemas kini inventori gudang MSKU secara automatik kepada nilai inventori yang diselaraskan",
    "addedInventoryList": "",
    "noMoreThanMaxCharacters": "Tiada lebih daripada aksara {max}",
    "confirmAndUpdateMskuInventory": "Anda pasti untuk menyesuaikan dan kemaskini inventori MSKU?",
    "areYouSureNotToSave": "Anda pasti tidak menyimpan maklumat semasa?",
    "createAnInventoryTask": "",
    "countingStatus": "Status inventori",
    "counted": "Dikira",
    "saving": "Menyimpan",
    "areYouCancel": "",
    "notCount": "",
    "shouldBeBetweenXToX": "",
    "offlineOrder": "",
    "duringTheUnboundPeriodTheOutbound": "",
    "tokocabangStockChannelInterfaceDoesNot": "",
    "disabledChannelWarehousesCannotUpdateStock": "",
    "pleaseBeSureToCompleteThe1": "",
    "systemWarehouseAddress": "",
    "systemWarehouseName": "",
    "channelWarehouseAddress": "",
    "channelWarehouseName": "",
    "channelWarehouseManagement": "",
    "upgradePackage": "",
    "askCustomerService": "",
    "theCurrentStoreHasOpenedThe": "",
    "storeAuthorizationFailed": "",
    "youCanDirectlyAddProductsIn": "",
    "pleaseBeSureToCompleteThe": "",
    "theStoreHasOpenedTheChannel": "",
    "authorizing": "",
    "multiWarehouse": "",
    "theGineeSystemReportsAnError": "",
    "theChannelInterfaceReportsAnError": "",
    "failedToPullChannelWarehouseInformation": "",
    "deactivateWarehouse": "",
    "addWarehouse": "",
    "channelWarehouseInformationPulledSuccessfully": "",
    "pullTheChannelWarehouseAddXxx": "",
    "channelWarehouseXxxBindingWarehouseIs": "",
    "theChannelWarehouseXxxIsBound": "",
    "updateWarehouse": "",
    "pleaseNoteThatTheOperationLog": "",
    "thereIsAnUnfinishedSalesDelivery": "",
    "OrdersAllocated": "",
    "areYouSureToUpdateThe": "",
    "OrdersThatHaveBeenAllocated": "",
    "areYouSureToBindThe": "",
    "ownWarehouse": "",
    "warehouseMarketplace": "",
    "TheStatusOfTheCurrent": "",
    "theSizeVariantDoesNotSupport": "Varian \"Saiz\" tidak menyokong penambahan imej",
    "onlyShowAuthorizedStores": "",
    "untickWithOneClick": "",
    "pleaseUntickAndReoperate": "",
    "thereAreXExpireddeactivatedStoresIn": "",
    "failedToSave": "",
    "shortDescription": "",
    "Item": "",
    "shippingFeeTips2": "",
    "whenTheProductsInTheChannel": "",
    "whenThereIsAStockChange": "",
    "channelWarehouseId": "",
    "forChannelProductsThatAreBound": "",
    "forMultiwarehouseChannelsAndChannelProducts": "",
    "forMultiwarehouseChannelsWithWarehouseboundChannel": "",
    "existXwarehouse": "",
    "amountUnit": "",
    "thePriceOfTokoWillBe": "",
    "priceAndStockWillBeUpdated": "",
    "dueToTheDelayInChannel": "",
    "theStoreWhereTheOrderIs1": "",
    "theStoreWhereTheOrderIs": "",
    "theWarehouseXxxxAlreadyHasA": "",
    "youNeedToIntegrateAstore": "",
    "pleaseSelectTheStoreaccountTo": "",
    "theProductsXxxxInTheOrder": "",
    "theCurrentProductsWarehouseIsEmpty": "",
    "xIsOutOfStockIn": "",
    "shopeebukalapaktokopediaOrdersUseGineeTemplateFor": "",
    "buyerPhone": "",
    "xNewWarehouses": "",
    "editStock": "",
    "warehouseIsEmpty": "",
    "dueToTheLimitationsOfThe": "",
    "pleaseFindTheBindingEmailIn": "",
    "created": "",
    "allstocktakingissuccessful": "",
    "partialstocktaking": "",
    "allstocktakingfailed": "",
    "someexecute": "",
    "faileds": "",
    "thePullTaskHasBeenCreated": "",
    "executed": "",
    "keeppackWarehouse": "",
    "unbindMsku": "",
    "PullRecord": "",
    "thisStoreHasBeenConnectedTo": "",
    "ManualInbound": "",
    "stocktakingInbound": "",
    "NormalInbound": "",
    "notPushed": "",
    "partComplete": "",
    "partInvalid": "",
    "tripartiteSingleNumber": "",
    "storageInformation": "",
    "types": "",
    "plannedInbound": "",
    "receiptCost": "",
    "withAttachments": "",
    "automaticallyCreateMsku": "",
    "createMskuManually": "",
    "currentlyThirdPartyWarehouseSkuCode": "",
    "warehouseKeeppackWarehouseInboundManagement": "",
    "warehouseKeeppackWarehouseOutboundManagement": "",
    "warehouseKeeppackWarehouse": "",
    "theCurrentCommodityWarehouseIsEmpty": "",
    "YouWillNotBeAble": "",
    "temperatureControlled": "",
    "standard": "",
    "storageType": "",
    "enableWare": "",
    "afterEnablingTheMultiwarehouseModeAnd": "",
    "activateMultiwarehouse": "",
    "turnOffMultiwarehouse": "",
    "wantTo": "",
    "afterTheMultiwarehouseModeIsTurned": "",
    "kongfuWarehouse": "",
    "useTheStockInformationOfThe": "",
    "afterTheAuthorizationIsSuccessfulYou": "",
    "afterClickingTheAuthorizeButtonKongfus": "",
    "PleaseContactKongfuCustomerService": "",
    "ItIsRecommendedToFill": "",
    "informationDescription": "",
    "consignorName": "",
    "acuntName": "",
    "pleaseSelectTheWarehouseThatNeeds": "",
    "applyForAuthorization": "",
    "afterTheAuthorizationIsCompletedPlease": "",
    "pleaseContactKongfuCustomerServiceOffline": "",
    "approvalStatus": "",
    "reasonForFailureTheThirdParty": "",
    "timeToOperateAuthorizedWarehouseInformation": "",
    "theTimeToUpdateWarehouseInformation": "",
    "consignorCode": "",
    "theSystemWillAutomaticallyUpdateThe": "",
    "passed": "",
    "theWarehouseAuthorizationApplicationInformationHas": "",
    "afterConfirmationTheSystemWillCall": "",
    "areYouSureToInitiateA": "",
    "initiateAuthorizationApplication": "",
    "theListOnlyShowsUnauthorizedWarehouse": "",
    "selectAuthorizedWarehouse": "",
    "gineeWillPullTheWarehouseInformation": "",
    "tipsa": "",
    "startToPullTheLatestWarehouse": "",
    "updateApprovalStatus": "",
    "confirmAuthorizedWarehouseIdXxxx": "",
    "authorizationStatusXxxx": "",
    "addWarehousesForApplicationAuthorization": "",
    "noMoreThanXxxCharacters": "",
    "customsWeight": "",
    "customsDeclarationAmount": "",
    "customsCode": "",
    "customsEnglishName": "",
    "customsDeclarationChineseName": "",
    "ifTheWarehouseForThisDelivery": "",
    "customsDeclarationInformation": "",
    "theProductDeclarationInformationIsMissing": "",
    "mskuDoesNotConformToThe": "",
    "theProductNameDoesNotConform": "",
    "TheMskuDoesNotExceed": "",
    "ProductNameDoesNotExceed": "",
    "channelRequirements": "",
    "ifTheProductsDeliveredToThe": "",
    "needToDeclare": "",
    "noDeclarationRequired": "",
    "totalNumberOfSkus": "",
    "awb": "",
    "outboundAmount": "",
    "outboundQuantity": "",
    "outboundInformation": "",
    "doNotEnableAutomaticBinding": "",
    "thisFieldIsRequiredWhenThe": "",
    "returnTrackingNumber": "",
    "thirdpartyWarehouseTransactionReturn": "",
    "manualSync": "",
    "timingSynchronization": "",
    "reasonReceivedAThirdpartyWarehouseInterface": "",
    "actualInbound": "",
    "theOrderIsInconsistentWithThe": "",
    "theChannelInterfaceCannotGenerateThe": "",
    "mskuIsNotAddToThe": "",
    "rejected": "",
    "outboundListOutboundListInThe": "",
    "inboundListInboundListInThe": "",
    "productsProductsInTheWarehouseIncluding": "",
    "agreeToAuthorizeGineeToObtain": "",
    "editMultiwarehouseStock": "",
    "totalStock": "",
    "add2": "",
    "openMultiwarehouses": "",
    "closeMultipleWarehouses": "",
    "noteHighSpeedPrintingDoesNot": "",
    "massEditStockIsAvailableFor": "",
    "agreecontinueAuthorization": "",
    "theStockAndProductInformationOf": "",
    "afterClickingTheAuthorizeButtonYou": "",
    "PleaseContactTheWarehouseService": "",
    "BeSureToSelectThe": "",
    "ifYouNeedToChangeWarehouse": "",
    "theFastockFulfillmentWarehouseHasBeen": "",
    "received": "",
    "warehouseBreadcrumbProduct": "",
    "warehouseBreadcrumbInbound": "",
    "warehouseBreadcrumbOutbound": "",
    "warehouseBreadcrumbPullRecord": "",
    "unboundStore": "",
    "storeAuthorizationHasExpired": "",
    "hongkong": "",
    "whetherItIsTurnedOnOr": "",
    "theSkuRemovalQuantityCannotExceed": "",
    "productCodeRepeatedModification": "",
    "cantDeleteAllDetails": "",
    "theOutboundListHasBeenAllocated": "",
    "failedToRequestGxb": "",
    "theDetailsOfTheOutboundList": "",
    "outboundListDetailsDontExist": "",
    "theMergingSkuOfInboundList": "",
    "theSkuAcceptanceQuantityOfThe": "",
    "orderDetailsCannotBeChecked": "",
    "productInformationDoesNotExist": "",
    "returnAwbNumberCannotBeEmpty": "",
    "theInboundListHasBeenAccepted": "",
    "theInboundListDoesntExist": "",
    "multipleLastMileScacCodesCannot": "",
    "theDomesticAwbNumberUnderThe": "",
    "homeDeliveryDoesntNeedToFill": "",
    "weightCanOnlyBeAPositive": "",
    "theLastMilesTrackingNumberIs": "",
    "theLabelPdfIsntEmptyAnd": "",
    "theSelectedWarehouseDoesNotSupport": "",
    "lastMileInformationlogisticsProductCannotBe": "",
    "documentNumberCannotBeEmpty": "",
    "productQuantityCanOnlyBeA": "",
    "theDestinationCountryMustBeConsistent": "",
    "recipientCountryprovincecityParameterIsMissing": "",
    "theOrderHasBeenProcessedAnd": "",
    "matchesMultipleOrders": "",
    "notWeighedWithoutWeight": "",
    "theDocumentNumberAndLastMile": "",
    "lastLegNumberAlreadyExists": "",
    "orderShipped": "",
    "pickupPersonInformationCannotBeEmpty": "",
    "theOrderHasBeenShippedAnd": "",
    "senderCountryprovincecityParameterIsMissing": "",
    "logisticsChannelDoesntSupport": "",
    "thisStatusDoesntSupportModification": "",
    "pleaseCheckThePickupMethod": "",
    "outboundTypeNotMapped": "",
    "pleaseCheckTheScac": "",
    "productcombinedProductQuantityMustBe": "",
    "documentNumberAlreadyExists": "",
    "pleaseCheckTheReceiver": "",
    "warehouseCodeDoesNotExist": "",
    "codAmountAndCodCurrencyCan": "",
    "pleaseCheckTheBusinessType": "",
    "failedToCheckDestinationCountryprovinceregion": "",
    "logisticsChannelTrackingFailed": "",
    "noPermissionToOperateThisOrder": "",
    "thePlatformHasAuthorizedTheConsignor": "",
    "tokenError": "",
    "noValidAuthorizedWarehouse": "",
    "platformMismatch": "",
    "noConsignorPermission": "",
    "theTokenDoesntMatch": "",
    "thePlatformConsignorDoesntMatch": "",
    "pleaseCheckIfThereIsError": "",
    "authorizationHasBeenApprovedPleaseDont": "",
    "noConsignorOnThisPlatform": "",
    "userHasNo": "",
    "userHasNoAuthorizationForThis": "",
    "userDoesntHaveConsignorsAuthorization": "",
    "warehouseAuthorizationRecordDoesNotExist": "",
    "duplicateAuthorizationApplication": "",
    "warehouseDoesntExist": "",
    "consignorDoesntExists": "",
    "platformInformationVerificationFailedPleaseContact": "",
    "consignorAlreadyExists": "",
    "noTrackingNumber": "",
    "hasTrackingNumber": "",
    "trackingNumberStatus": "",
    "rulesBetween": "",
    "waitingOutbound": "",
    "productPriceShouldBeBetween": "",
    "pleaseMoveToTheMasterProduct": "",
    "kongfuRequiresThatTheLabelMust": "",
    "outboundListNumber": "",
    "inventoryChangesDueToManualOperation": "",
    "totalDiscountedPrice": "",
    "inventoryChangesDueToWarehousePersonnel": "",
    "thirdpartyWarehouseInboundAdjustment": "",
    "sinceTheKongfuApiLimitsThe": "",
    "pleaseCheckTheDocumentType": "",
    "tryNow": "",
    "talkLater": "",
    "congratulationsYouWillGetADay": "",
    "theTrialVersionHasExpiredAnd": "",
    "theTrialPackageWasUpdatedSuccessfully": "",
    "warehouseWarehousenameWarehouseActive": "",
    "theSalesTypeNameIsEmpty": "",
    "generalInformation": "",
    "imageForPos": "",
    "onlineAttributes": "",
    "refurbished": "",
    "insertProductWeightIncludingThePackaging": "",
    "kilogramKg": "",
    "gramGr": "",
    "insertProductDimensionAfterPackagingTo": "",
    "activatingThePreorderFeatureAllowsYou": "",
    "productIsReadyStock": "",
    "productNeedLongerShippingProcess": "",
    "processingTime": "",
    "mokaOrderBetweenDays": "",
    "mokaProductImageTooltip": "",
    "Inventory": "",
    "cost": "",
    "isMultiplePricePerSalesType": "",
    "trackStock": "",
    "alert": "",
    "alertAt": "",
    "itemStockCanNotBeChanged": "",
    "thisItemCanNotBeTracked": "",
    "avgCostCanNotBeChanged": "",
    "trackCogs": "",
    "avgCost": "",
    "Empty": "Kosong",
    "domesticLogisticsTrackingNumberCannotBe": "",
    "consignorDoesntMatch": "",
    "documentNumberDoesNotExist": "",
    "trackingNumberAlreadyExists": "",
    "packageAlreadyExists": "",
    "paketTambahanTidakBolehLebihBesar": "",
    "theOrderHasBeenShippedNo": "",
    "productInformationCantBeEmpty": "",
    "theresEmptyDataInTheCombination": "",
    "declaredAmount": "",
    "declaredWeight": "",
    "menuProductMoka": "",
    "menuProductMokaAdd": "",
    "menuProductMokaEdit": "",
    "stockInOutAlert": "",
    "theStockCanBePushedTo": "",
    "youHaveADayFullfeaturedTrial": "",
    "youAreUsingTheFreeVersion": "",
    "Cname": "",
    "thisStaffAccountHasNoPermission": "",
    "makeSureTheSwitchIsTurned": "",
    "printTaskCompletionTime": "",
    "printTaskCreationTime": "",
    "noteLazadaAndBlibliOrdersDo": "",
    "prefixMatch": "",
    "prefixSearch": "",
    "GineeAccountCanOnlyAuthorize": "",
    "matchingFailedThePreviousMatchingOption": "",
    "itemPriceCredit": "",
    "freeShippingMaxFee": "",
    "paymentFee": "",
    "lazpicklaztopCommission": "",
    "platformRequirementsTheDescriptionLengthMust": "",
    "currentlyBumped": "Bump Semasa",
    "numberOfBumpProducts": "Bilangan Produk Bump",
    "bumpFixedSupportMaximumProducts": "Bump Tetap sokong untuk bump dengan maksimum 5 produk",
    "bumpfixed": "Bump Tetap",
    "bumpByTurns": "Bump secara bergilir-gilir",
    "setAsBumpFixed": "Tetapkan sebagai bump tetap",
    "setAsBumpByTurns": "Tetapkan sebagai bump secara bergilir-gilir",
    "tipsYouCanAddUpTo": "Tips: tambah sehingga 30 produk pada satu masa, sila segerakkan maklumat produk di kedai dahulu",
    "editingInformationSuchAsStockAnd": "",
    "failedToUploadImagePleaseReupload": "",
    "solutionSteps": "",
    "addBinding": "",
    "pleaseClickToRedistribute": "",
    "pleaseAddBindingRelationship": "",
    "pleaseEnableDistributionSettings": "",
    "distributionSettingsArentEnabled": "",
    "pleaseRestoreTheOrder": "",
    "ordersThatHaveBeenPlacedOn": "",
    "problemCauses": "",
    "pleaseCreateAnInboundListAnd": "",
    "pleaseAdjustTheShippingWarehouse": "",
    "pleaseCompleteTheInboundInThe": "",
    "pleaseCreateAnInboundList": "",
    "mskuInXWarehouseYOut": "",
    "theOrderStatusOfTheX": "",
    "currentlyDoesNotSupportTheCreation": "",
    "theXThirdpartyWarehouseDoesNot": "",
    "theXThirdpartyWarehouseDoesntCurrently": "",
    "bumpFixed": "Bump Tetap",
    "serviceFeeMapping": "",
    "platformFulfillmentFeeMapping": "",
    "platformRebateMapping": "",
    "voucherSellerMapping": "",
    "shippingDifferenceCashlessMapping": "",
    "shippingFeeMapping": "",
    "shippingPriceSetting": "",
    "taxSetting": "",
    "salesInvoiceSetting": "",
    "storeMapping": "",
    "warehouseMapping": "",
    "database": "",
    "newCompleteOrdersAfterTheSt": "",
    "newCompleteOrderAfterMappingStore": "",
    "afterEnablingIfAccurateHasStock": "",
    "stockSyncRules": "",
    "mappingTime": "",
    "branch": "",
    "chartOfAccounts": "",
    "pleaseCompleteAtLeast": "",
    "gineeWarehouseCanOnly": "",
    "enabledByDefault": "",
    "autoCreateRulesForSalesInvoice": "",
    "syncFromAccurateToGinee": "",
    "syncFromGineeToAccurate": "",
    "finance": "",
    "mapFailed": "",
    "notMapped": "",
    "mapped": "",
    "stockChange": "",
    "pushMapMsku": "",
    "repushStock": "",
    "upToCharactersAndOnly": "",
    "allUncreatedAndUnmappedMskusCurrently": "",
    "afterItIsEnabledAllMskus": "",
    "mappingRules": "",
    "autoPushMap": "",
    "thisSkuAlreadyExists": "",
    "unitCostMustBe": "",
    "salesInvoice": "",
    "syncStock": "",
    "theStoreIsMissingMappingInformation": "",
    "bulkSubmitSuccess": "",
    "refreshStatus": "",
    "basicFeaturesFreeForever": "",
    "nMinutesRemaining": "Tertinggal {minus} minit",
    "gineeAndAccurateAreMappedBy": "",
    "accurateRequiresSkuToHaveA": "",
    "otherSettingsAndMapping": "",
    "mapping": "",
    "automapSettings": "",
    "pleaseSetAndMapTheCorrect": "",
    "basedOnAccurateStock": "",
    "basedOnGineeStock": "",
    "whenTheSkuOfGineeAnd": "",
    "startingStock": "",
    "theSubproductsOfTheCombinedProduct": "",
    "theWarehouseHasMatchedTheAccurate": "",
    "accurateAuthorizationHasExpiredPleaseReauthorize": "",
    "accurateRequiresInventoryToBe": "",
    "goToChange": "",
    "inboundWhenAddedToWarehouse": "",
    "youHaveNotCompletedTheInitial": "",
    "failedToDeleteThisProductHas": "",
    "noteIfTheWarehouseIsAdded": "",
    "partnerAuthorizationFailed": "",
    "customerMustBeFilled": "",
    "itemXSuccessfullySaved": "",
    "unitQtyMustBeFilled": "",
    "itemXNotFoundOrHas": "",
    "accurateConfigurationInformationIsMissingPlease": "",
    "theWarehouseXWasNotFound": "",
    "brazil": "",
    "taiwan": "",
    "savedSuccessfullyThisPageWillClose": "",
    "salesInvoicePushRecord": "",
    "orderPushTime": "",
    "orderCompleteTime": "",
    "completeTime": "",
    "updateResult": "",
    "productManage": "",
    "becauseTheNumberOfStoresExceeds": "",
    "theAuthorizationInformationIsFilledIncorrectly": "",
    "channelItemXMoveOutPromotion": "",
    "xManualReleaseOfReservedPromotion": "",
    "promotionIdXHasEnded": "",
    "xManuallyEditedReservedPromotionStock": "",
    "channelProductXActivityIdY": "",
    "unknownSystemException": "",
    "theChannelProductSpecificationCorrespondingTo": "",
    "theChannelProductCorrespondingToThe": "",
    "hasNoWarehouseInformationAndCannot": "",
    "channelSkuHasNoBoundMsku": "",
    "notCompliantWithPromotionReservation": "",
    "promotionReservationPolicyNotMet": "",
    "theCategoryOrAttributeInformationIs": "",
    "setUpOrderInvoices": "",
    "orderPacking": "",
    "orderDistribution": "",
    "syncOrder": "",
    "theOrderSSuccessfullyPrintedThe": "",
    "theOrderssuccessfullyPrintedThePackingList": "",
    "orderSuccessInvoice": "",
    "orderSuccessLabel": "",
    "ordersreturnedInboundTheReturnInboundList": "",
    "ordersredistributedSuccessfully": "",
    "ordersfailedToPushWarehouseForDelivery": "",
    "theOrdersisSuccessfullyPushedToThe": "",
    "orderspushWarehouseOperationSuccessfully": "",
    "theOrderSSuccessfullySetThe": "",
    "ordersarrangedDeliverySuccessfully": "",
    "orderspackedSuccessfully": "",
    "orderscancelledSuccessfully": "",
    "theWarehouseSelectedForProductS": "",
    "theProductSInTheOrder": "",
    "theOrderSDeletesTheGift": "",
    "addGiftSToOrderS": "",
    "theOrderSHasBeenCancelled": "",
    "ordersmarkedAsPutOnHold": "",
    "theOrderSIsMarkedAs": "",
    "orderSIsMarkedAsOut": "",
    "theOrderSIsAPromotion": "",
    "theChannelProductNotOutbound": "",
    "theChannelProductCantGen": "",
    "theChannelProductAllocate": "",
    "theChannelProductSInThe": "",
    "anOutboundListHasBeenCreated": "",
    "theOrderSHasBeenSuccessfully": "",
    "syncToTheNewOrderS": "",
    "salesTaxAmountMustBeBetween": "",
    "incorrectUnitFormat": "",
    "purchaseDurationMustBeBetween": "",
    "selectedWarehouse": "",
    "addingToRepositoryXFailedReason": "",
    "purchaseDurationUnit": "",
    "thisCustomerHasOrdersInThe": "",
    "theProductInboundStockCannotBe": "",
    "theBundledProductHasNotBeen": "",
    "pleaseAddMskuToTheWarehouse": "",
    "createSalesInvoice": "",
    "beforePushingMatchingCombinedProducts": "",
    "theSystemWillAutomaticallyPushSales": "",
    "ifGineeAndAccurateHaveThe": "",
    "thePushedMappedMskuSupports": "",
    "afterSettingTheTaxTypeWill": "",
    "thisMappingCorrespondsToTheService": "",
    "thisMappingCorrespondsToTheShipBySys": "",
    "thisMappingCorrespondsToTheRebate": "",
    "thisMappingCorrespondsToTheCommission": "",
    "thisMappingCorrespondsToTheShipReb": "",
    "thisMappingCorrespondsToTheShipping": "",
    "thePromotionHasExpiredOrThe": "",
    "invoiceBuyerPhoneNo": "",
    "invoiceType": "",
    "invoiceAddress": "",
    "invoiceBuyerEmail": "",
    "invoiceBuyerName": "",
    "upcoming": "",
    "finish": "",
    "promoTime": "",
    "promotionid": "",
    "webstore": "",
    "orderShasBeenMovedToReturned": "",
    "orderShasBeenMovedToDelivered": "",
    "orderShasBeenMovedToShipping": "",
    "orderShasBeenMovedToTo": "",
    "orderSisMarkedAsNotPacked": "",
    "orderSisMarkedAsPacked": "",
    "ordersmarkedPickingStatus": "",
    "orderSisMarkedAsNotA": "",
    "orderSisMarkedAsFakeOrder": "",
    "orderSHasBeenOperatedDelivery": "",
    "orderSmarkedAsPickingListNot": "",
    "orderSmarkedAsPickingListPrinted": "",
    "orderSmarkedAsPackingListNot": "",
    "orderSmarkedAsPackingListPrinted": "",
    "orderSmarkedAsInvoiceNotPrinted": "",
    "orderSmarkedAsInvoicePrinted": "",
    "orderSmarkedAsLabelNotPrinted": "",
    "orderSmarkedAsLabelPrinted": "",
    "pleaseConfirmTheInstallationOfGinee": "",
    "allActivities": "",
    "eventStartTimeAscending": "",
    "eventStartTimeDescending": "",
    "eventEndTimeAscending": "",
    "eventEndTimeDescending": "",
    "sortEventStartTime": "",
    "thisOperationOnlyMarksWhetherThe": "",
    "IsTheHighestPriority": "",
    "optional": "",
    "mandatory": "",
    "shippingInsurance": "",
    "xModifiedWarehouseStockOutboundOrder": "",
    "xModifiedWarehouseStockCreatedOutbound": "",
    "xManuallyReplacesTheReservedWarehouse": "",
    "pleaseModifyTheReservedPromotionStock": "",
    "commissionFeeMapping": "",
    "priorityDelivery": "",
    "flashSale": "",
    "productPromotion": "",
    "eventStartTime": "",
    "eventEndTime": "",
    "notPrintable": "",
    "theFaceSheetCanBePrinted": "",
    "thTrackingNumberNotBeen": "",
    "theOrderStatusCannotPrintThe": "",
    "platformFulfillmentOrdersDoNotSupport": "",
    "thisChannelDoesNotSupportPrinting": "",
    "theChannelHasNotReturnedThe": "",
    "theChannelShippingLabelHasNot": "",
    "syncAll": "",
    "noShippingLabelToBePrint": "",
    "promotionEnd": "",
    "handleProblem": "",
    "stockMonitoringAndSynchronizationSettingsAre": "",
    "thereAreUpToFixed": "Maksimum 5 produk bump tetap",
    "afterTurningOffTheAutomaticallyReserve": "",
    "turnOffTheSwitchToAutomatically": "",
    "setTheReservationPolicyToUpcoming": "",
    "setTheReservationPolicyToAutoreserve": "",
    "turnOnTheSwitchToAutomatically": "",
    "processingPromotionOnlySupportModifyThe": "",
    "displayingWarehouseWhereTheMskuOf": "",
    "displayingTheBoundMskuOfThe": "",
    "displayTheChannelSkuOfThe": "",
    "productParticipatedInPromotionButIs": "",
    "productParticipatedInPromotionAndOnly": "",
    "productsIncludingAllVariationsAreParticipating": "",
    "thisPageCanOnlyViewAnd": "",
    "channelReturnFailedReasonX": "",
    "storeWillExp": "",
    "storeHasExp": "",
    "big": "",
    "small": "",
    "standa": "",
    "onlySupportsAdjustingSkuAndQuantity": "",
    "fontSiz": "",
    "paperSize": "",
    "disableHighspeedPrintingForSshop": "",
    "enableHighspeedPrintingForSstore": "",
    "disableTheHighSpeedPrintingButton": "",
    "enableTheHighSpeedPrintingSwitch": "",
    "afterDisablingAllStoresWillNot": "",
    "areYouSureToTurnOff": "",
    "shopeeWillMarkThePredownloadedOrder": "",
    "afterEnablingGineeWillDownloadThe": "",
    "syncToObtainLatestLogisticsInformation": "",
    "massSyncToObtainTheLatest": "",
    "syncLogistics": "",
    "package": "",
    "storeAuthorizedtotalAmount": "",
    "subaccountUsedtotalAmount": "",
    "remainingOrderQuantity": "",
    "remainingMDays": "",
    "packageRemainingMDays": "",
    "toEnsureNormalUsePleaseRenew": "",
    "packageRemainingValidityMDays": "",
    "noReminderToday": "",
    "theOperationIsTooFrequentPlease": "",
    "yourCurrentPackageDoesNotSupport": "",
    "thisMethodIsOnlyOpenTo": "",
    "shopeeOfficialInstructions": "",
    "actualShippingFee": "",
    "ifAutomaticallyReserveShopeePromotionStock": "",
    "afterEnablingGineeWillAutomaticallyPull": "",
    "turnOffTheAutomaticallyPullLogistics": "",
    "turnOnTheAutomaticallyPullLogistics": "",
    "automaticallyPullLogisticsInformation": "",
    "disabled1": "",
    "enabled": "",
    "newlyAuthorizedStoresAreAutomaticallySet": "",
    "confirmToReauthorize": "",
    "noteTiktokReauthorizationDoesNotExtend": "",
    "sellerFullAddress": "",
    "thereIsNoOrderThatCan": "",
    "startSynchronizingLatestOrderLogisticsInformation": "",
    "video": "",
    "tryHighlightingCoreSellingPoints": "",
    "weRecommendTheVideoDurationIs": "",
    "recommendedRatioIsAndRecommended": "",
    "ifYouUploadAVideoIt": "",
    "tiktokRequired": "",
    "changingCategoryWillNotAffectYour": "",
    "basedOnTheTitleYouEntered": "",
    "economical": "",
    "cargo": "",
    "standardShipping": "",
    "pleaseInputTheValueOfWeight": "",
    "deliveryOptions": "",
    "lineShoppingOnlySupportsSettingThe": "",
    "compulsoryOutbound": "",
    "compulsoryCancel": "",
    "noPermission": "",
    "refuseToAddWms": "",
    "theProductDescriptionShouldGiveThe": "",
    "missingOrderInformation": "",
    "compartmentDimensionInShippingWarehouseSetting": "",
    "priorityOfWarehouseAlocationInShipping": "",
    "warehouseInRuleChangedFrom": "",
    "startPullingTheOrderDataIt": "",
    "theRecipientsInformationCanBeDisplayed": "",
    "theCompleteRecipientInformationCanBe": "",
    "customShipmentWarning": "",
    "thisSystemWarehouseHasBeenBound": "",
    "completeRecipientInformationCanBe": "",
    "recipientsInformationCanBeDisplayed": "",
    "instantDiscountAmount": "",
    "originalPriceShouldBeHigherThan": "",
    "YourOmsWillBeUpgraded": "",
    "YouWillAutomaticallyBindThe": "",
    "theFulfillmentServiceProviderXInvites": "",
    "afterAgreeing": "",
    "areYouSureYouAgreeTo": "",
    "confirmActivation": "",
    "youHaveNewApplication": "",
    "thisChannelCanBeAuthorizedAfter": "",
    "theCurrentPackageDoesNotSupport": "",
    "enableFailedYouCanOnlyAuthorize": "",
    "afterUpgradingThePackageMoreMaster": "",
    "addFailedMasterProducts": "",
    "masterProductQuantityHasReachedThe": "",
    "additionalXConsignorAccountsbusiness": "",
    "additionalXGineeWmsOrders": "",
    "additionalXStores": "",
    "additionalXStaffAccounts": "",
    "additionalXGineeOmsOrders": "",
    "addons": "",
    "offlineOnboardingOn": "",
    "accountManagerOn": "",
    "onlineTraining": "",
    "liveChatSupport": "",
    "implementationAndCustomerCare": "",
    "XConsignorAccountsBusiness": "",
    "servicesToExternalBrands": "",
    "serviceProviderManagement": "",
    "gineeWmsOpenApi": "",
    "pdaapp": "",
    "fifofefo": "",
    "smartReplenishment": "",
    "stocklocationTransfer": "",
    "waveShipping": "",
    "gineeWmsWarehouseManagementSystem": "",
    "gineeOmsOpenApi": "",
    "theThirdPartyIntegrationposLogisticsFulfillment": "",
    "multiwarehouseManagement": "",
    "reporting": "",
    "stockManagement": "",
    "gineeOmsOrderManagementSystem": "",
    "noOfSalesChannels": "",
    "noOfMskus": "",
    "noOfStores": "",
    "noOfStaffAccounts": "",
    "noOfCountriesinternational": "",
    "noOfGineeWmsOrders": "",
    "noOfGineeOmsOrders": "",
    "ecommerceOperations": "",
    "buyItNow": "",
    "clickMeToGetDiscount": "",
    "customization": "",
    "youHaveMNewMessages": "",
    "officialAccounts": "",
    "theThirdPartyIntegration": "",
    "thereAreUnrecognizableCharactersSuchAs": "",
    "currentlyDoNotSupportIntegratingCnsc": "",
    "manuallyRepushThePushFailedData": "",
    "paymentTerms": "",
    "bankAccount": "",
    "issuingBank": "",
    "taxIdNumber": "",
    "contactPerson": "",
    "website": "",
    "onlyLettersAndNumbersAreSupported": "",
    "theContentYouEnteredContainsIllegal": "",
    "autoGenerate": "",
    "supplierName": "",
    "supplierCode": "",
    "createNewSupplier": "",
    "importSupplier": "",
    "supplierList": "",
    "purchaseManagement": "",
    "supplierManagement": "",
    "purchase": "",
    "disableenable": "",
    "supplierCodeDoesNotExist": "",
    "supplierNameAlreadyExists": "",
    "supplierCodeAlreadyExists": "",
    "failedToDeleteTheSupplierAlready": "",
    "onlySuppliersWithDisabledStatusAnd": "",
    "wantToDeleteTheSupplier": "",
    "wantToEnableTheSupplier": "",
    "failedToDisableThisSupplierHas": "",
    "suppliersWithUnfinishedPurchaseOrdersCannot": "",
    "wantToDisableTheSupplier": "",
    "thisTypeHasBeenSelectedBy": "",
    "wantToDeleteTheSupplierType": "",
    "typeName": "",
    "addNewType": "",
    "fullPayment": "",
    "supplierCodeCannotBeEditedAfter": "",
    "xAlreadyExists": "",
    "pleaseFillInThisField": "",
    "theAmountMustBeBetweenXy": "",
    "amountMustBeBetweenXyAnd": "",
    "partialArrival": "",
    "inTransit": "",
    "paymentStatus": "",
    "purchaseOrder": "",
    "paymentMethod": "",
    "paid": "",
    "partialPayment": "",
    "unpaid": "",
    "feeInformation": "",
    "deliveredQuantity": "",
    "estimatedDateOfArrival": "",
    "purchasingStaff": "",
    "receivingWarehouse": "",
    "puchaseDate": "",
    "purchaseOrderSource": "",
    "noSupplier": "",
    "purchaseOrderNumberAlreadyExists": "",
    "purchaseOrderNumberCannotBeEdited": "",
    "pleaseEnterPurchaseOrderNumber": "",
    "purchaseOrderNumber": "",
    "basicInfoma": "",
    "otherNoncash": "",
    "virtualAccount": "",
    "paymentLink": "",
    "qris": "",
    "edc": "",
    "bankTransfer": "",
    "chequeclearing": "",
    "cash": "",
    "discou": "",
    "productPurchasePrice": "",
    "purchaseOrderTotalPrice": "",
    "skuQuantity": "",
    "totalPriceInformation": "",
    "inboundCostPrice": "",
    "individualCostSharing": "",
    "taxRate": "",
    "onlyNumbersFromTo": "",
    "discountRate": "",
    "purcPrice": "",
    "quantityPcs": "",
    "unit": "",
    "volumeM": "",
    "productPriceIncludesTax": "",
    "massAddd": "",
    "addProdu": "",
    "dividedByQuantity": "",
    "dividedByVolume": "",
    "dividedByWeight": "",
    "dividedByPrice": "",
    "notDivided": "",
    "fillInmodifyTrackingNumber": "",
    "purchaseNote": "",
    "thePurchaseOrderWarehouseIsWmsthirdparty": "",
    "failedToCancelThePurchaseOrder": "",
    "cancellationFailedInboundProductsInThis": "",
    "whenAPurchaseOrderIsCancelled": "",
    "wantToCancelThePurchaseOrder": "",
    "wantToDeleteThePurchaseOrder": "",
    "IfTheReceivingWarehouseIs": "",
    "AfterSubmissionThePurchaseOrder": "",
    "youWantToSubmitThePurchase": "",
    "identify1": "",
    "copyMskuFromExcelAndPaste": "",
    "EstimatedTimeOfArrivalFormat": "",
    "WhenTheReceivingWarehouseIs": "",
    "ExplanationOfTheShippingFeeother": "",
    "purchaseOrderContainsMultipleProductsYou": "",
    "WhenAPurchaseOrderContains": "",
    "exportPurchaseOrder": "",
    "printPurchaseOrder": "",
    "changePaymentStatus": "",
    "signature": "",
    "merchantWarehouseAddress": "",
    "contactInformation": "",
    "supplierAddress": "",
    "supplier": "",
    "supplierInformation": "",
    "merchantInformation": "",
    "explanation": "",
    "UpToDataCan": "",
    "estimatedTimeOfArrival": "",
    "warehouseType": "",
    "theEntireInboundWillBeInvalidated": "",
    "completeInbound": "",
    "partialInbound": "",
    "afterInvalidationIsInitiatedOnlyThe": "",
    "onlyTheErpInboundIsInvalided": "",
    "afterTheInvalidationIsInitiatedThe": "",
    "invalidErpAndFulfillmentInbound": "",
    "invalidationMethod": "",
    "theSkuWillBecameInvalidAnd": "",
    "pleaseSelectTheInvalidationMethod": "",
    "inboundFullAmount": "",
    "presentInboundQuantity": "",
    "pendingInboundinboundCompletedplannedInbound": "",
    "afterTheInboundIsCompletedThe": "",
    "sureYouWantToEndThe": "",
    "pushResult": "",
    "receiveItem": "",
    "afterDisablingThePrintedPurchaseOrder": "",
    "amountDisplay": "",
    "purchaseOrderPrintingSettings": "",
    "purchaseSetting": "",
    "receivingTime": "",
    "inboundTotalAmount": "",
    "receiveItemNumber": "",
    "supplierTypeAlreadyExists": "",
    "supplierType": "",
    "supplierTypeDoesNotExist": "",
    "theDataIsAbnormalAndThe": "",
    "xParameterIsIncorrect": "",
    "xParameterCannotBeEmpty": "",
    "thePurchaseOrderAlreadyExists": "",
    "purchaseOrderDoesNotExist": "",
    "failedTheWarehouseTypeWasNot": "",
    "purchaseOrderCancellationFailed": "",
    "purchaseOrderDeletionFailed": "",
    "purchaseOrderSubmissionFailed": "",
    "purchaseOrderIsMissingProductInformation": "",
    "purchaseOrderIsNotInDraft": "",
    "purchaseOrderCancellationFailedPleaseRefresh": "",
    "purchaseOrderDeletionFailedPleaseRefresh": "",
    "purchaseOrderSubmissionFailedPleaseRefresh": "",
    "theSupplierDoesNotExists": "",
    "supplierAlreadyExists": "",
    "theImportedDataIsEmpty": "",
    "theImportedFileFormatIsNot": "",
    "theOperationTaskDoesNotExist": "",
    "theImportTaskDoesNotExist": "",
    "theExportTaskDoesNotExist": "",
    "createAndPushSalesInvoicesWhen": "",
    "inboundAmount": "",
    "goodsReceivedNoteNumber": "",
    "quotationGeneratedSuccessfully": "",
    "theChannelWarehouseIsNotAssociated": "",
    "multiWarehouseChannelProductsAreNot": "",
    "doesNotComplyWithTheRules": "",
    "matchingDefaultPushRulesAreDisabled": "",
    "matchingStorePushRulesAreDisabled": "",
    "matchingCustomPushRulesAreDisabled": "",
    "shopifyProductInventoryIdDoesNot": "",
    "blibliProductsAreDisabledAndStock": "",
    "returnFromTikiChannelForThis": "",
    "theStoreHasBeenDisconnected": "",
    "tikiProductInboundStatusDoesNot": "",
    "masterProductNotFound": "",
    "thePushTaskAlreadyExistsAnd": "",
    "failedAlreadyRepushed": "",
    "pleaseSelectTheReceivingWarehouseFirst": "",
    "ifNotFilledInItWill": "",
    "region": "",
    "cityNew": "",
    "provinc": "",
    "districtsNew": "",
    "purchaseQuantity": "",
    "otherCostAllocationMethodsPleaseSelect": "",
    "otherCostTotal": "",
    "shippingFeeAllocationMethodPleaseSelect": "",
    "shippingFeeTotal": "",
    "wantToUnbindTheWarehouse": "",
    "afterUnbindingChangesInStockIn": "",
    "unbindTheChannelWarehouseAaaFrom": "",
    "thisFeatureIsOnlyAvailableFor": "",
    "deleteSupplier": "",
    "enableSupplier": "",
    "disableSupplier": "",
    "editSupplier": "",
    "manualDeleteSupplierX": "",
    "enableSupplierX": "",
    "disableSupplierX": "",
    "importToUpdateSupplierX": "",
    "manualEditSupplierX": "",
    "importToAddSupplierX": "",
    "manualAddSupplierX": "",
    "exportSupplierList": "",
    "exportPurchaseOrderX": "",
    "printPurchaseOrderX": "",
    "cancelPurchaseOrderX": "",
    "deletePurchaseOrderX": "",
    "submitPurchaseOrderX": "",
    "editPurchaseOrderXManually": "",
    "importPurchaseOrderX": "",
    "addPurchaseOrderXManually": "",
    "exportPurchaseO": "",
    "cancelPurchaseOrder": "",
    "deletePurchaseOrder": "",
    "submitPurchaseOrder": "",
    "editPurchaseOrder": "",
    "addPurchaseOrder": "",
    "shopeeRequirements": "",
    "formatMp": "",
    "durationSs": "",
    "sizeMaxMbResolutionShouldNot": "",
    "myShopeeSellerCenterCanInsert": "",
    "copyCopy": "",
    "thankYouForYourCooperationPlease": "",
    "weNeedYourConsentToRecord": "",
    "weOnlyRecordThePagesIn": "",
    "needAuthorization": "",
    "approveStartToRecord": "",
    "done": "",
    "repeatTheOperationStepsToReproduce": "",
    "capturingTheProcessingFlowOnThe": "",
    "stop": "",
    "uploading": "",
    "importToUpdateSupplier": "",
    "importToAddSupplier": "",
    "feedback": "",
    "uploadVideo": "",
    "wereNotPerfectButWereListening": "",
    "feedbackForm": "",
    "pleaseSubmitATicektFormWith": "",
    "feedbackVideo": "",
    "pleaseGrantGineeThePermissionRecord": "",
    "enterTheSpecificPageStartTo": "",
    "stopTheRecordCopyTheUnique": "",
    "faqfeedback": "",
    "needHelp": "",
    "detailedDescription": "",
    "trackingNumber": "",
    "enableLongDescription": "",
    "DetailProductDescription": "",
    "dueToApiLimitationsBlibliMust": "",
    "abnormal": "",
    "theInformationCannotBeFoundIn": "",
    "purchasePurchaseOrder": "",
    "addToList": "",
    "modifyingAccuratePurchaseOrderInformationIn": "",
    "pleaseSelectProduct": "",
    "gineeOnlySupportsCreatingPurchaseOrder": "",
    "supplierContactDetails": "",
    "operationFailed": "",
    "whenSelectingMultipleShopeeStoresThe": "",
    "requestTimedOutPleaseCheckThe": "",
    "manageMultiwarehouseStock": "",
    "supplierCodeSupplierCodeCannotBe": "",
    "hasReturnrefund": "",
    "batchEditingOnlySupportsModifyingA": "",
    "invalidationIsInitiatedOnlyTheOms": "",
    "onlyTheOmsOutboundIsInvalided": "",
    "invalidateOmsAndFulfillmentOutbound": "",
    "installationTutorial": "",
    "howToUse": "",
    "downloadAndInstallThePlugin": "",
    "goToChromeWebStoreTo": "",
    "ForPluginsHowToUse": "",
    "IfYouCannotAccessThe": "",
    "PluginInstallationLink": "",
    "RecommendedBrowserGoogleChrome": "",
    "pleaseDownloadAndUseTheScrape": "",
    "editAndPublishFromTheStore": "",
    "EditAndPublish": "",
    "selectAStoreAndCopyThe": "",
    "CopyAndSellInStores": "",
    "massClaimAndCreateMasterProduct": "",
    "ClaimTheMasterProduct": "",
    "visitTheProductPageYouWant": "",
    "ScrapeProduct": "",
    "returnrefund": "",
    "editWarehouseStockInStockList": "",
    "importToUpdateStockIncreasedecrease": "",
    "importToUpdateStockFinal": "",
    "purchaseOrdersSourcedFromAccurateDo": "",
    "failedToCreateOmsInboundThe": "",
    "IfYouHaveDoneThe": "",
    "IfEverythingIsNormalPlease": "",
    "PleaseSwitchTheNetworkAnd": "",
    "PleaseConfirmThatTheBrowser": "",
    "PleaseCheckWhetherYouAre": "",
    "inComplianceWithShopeePolicyBuyer": "",
    "inComplianceWithShopeePolicyThe": "",
    "massAddHistory": "",
    "cancellation": "",
    "failedDelivery": "",
    "ordersHasBeenMovedToReturnrefund": "",
    "moveToReturnrefund": "",
    "ordersHasBeenMovedToCompleted": "",
    "moveToCompleted": "",
    "youWantToMoveTheOrder": "",
    "confirmToSync": "",
    "purchaseSupplierManagement": "",
    "afterClosingAllStoresWillNot": "",
    "wantToDisableAutomaticallyReservePromotion": "",
    "pushingPleaseRefreshThePageLater": "",
    "wantToMassDisableAutoPush": "",
    "wantToMassEnableAutoPush": "",
    "onlyGineeWmsWarehouseSupportsEditing": "",
    "editPushStrategy": "",
    "disableAutoPush": "",
    "enableAutoPush": "",
    "autoPush": "",
    "pushStrategy": "",
    "theOrderWillBePushedTo": "",
    "regardlessOfWhetherTheOrderIs": "",
    "theDefaultPushRuleIsThat": "",
    "strategyDescription": "",
    "deleteFailedThereIsAWarehouse": "",
    "onlyStrategiesThatAreNotAssociated": "",
    "wantToDeleteTheStrategy": "",
    "allowEarlyPushWhenTheOrder": "",
    "pleaseSetAtLeastOneAdvance": "",
    "upToCustomStrategiesCan": "",
    "warehouseUsed": "",
    "pushPaidOrdersInAdvance": "",
    "strategyName": "",
    "manageCustomPolicies": "",
    "customAdvancePush": "",
    "pushAllInAdvance": "",
    "basicPush": "",
    "normalPushPushAllEarlyCustom": "",
    "thirdpartyWarehousePushSettings": "",
    "quantityLimitInfo": "",
    "valueShouldBeAtLeastThe": "",
    "purchaseLimit": "",
    "maxPerOrder": "",
    "storesAreAutomaticallySetToEnable": "",
    "authorizedStoresAreAutomaticallySetTo": "",
    "storeXDisableTheAutomaticallyReserve": "",
    "storeXEnableTheAutomaticallyReserve": "",
    "disableAutomaticallyReservePromotionStockMain": "",
    "enableAutomaticallyReservePromotionStockMain": "",
    "enabledisableAutomaticallyReservePromotionStock": "",
    "change": "",
    "superLinkAsAnOpenDoor": "",
    "actualInboundQty": "",
    "plannedInboundQty": "",
    "tipShopeePendingWaitingForShopee": "",
    "shopeeOrdersAreCurrentlyInPending": "",
    "gineeHasAutomaticallyFilteredXShopee": "",
    "Template": "",
    "promotionname": "",
    "EndTimeLeastDay": "",
    "theEndTimeMustBeLater": "",
    "TokoStartMinutesTips": "",
    "startTimeMustBeAtLeast": "",
    "OnceTheDiscountPromotion": "",
    "theEndTimeMustBeGreater": "",
    "EntitledProducts": "",
    "RemoveProducts": "",
    "removeSelectedProducts": "",
    "RemoveAllProducts": "",
    "promotionDetails": "",
    "UpdateAllProducts": "",
    "UpdateSelectedProducts": "",
    "SetDiscountAt": "",
    "stockMonitorAndSyncIsNot": "",
    "inboundHistory": "",
    "inboundDetails": "",
    "currentlyDoesNotSupportUploadingeditingLazada": "",
    "noteYouCanPublishThisListing": "",
    "pageNumber": "",
    "rejectionReason": "alasan penolakan",
    "theShippingLabelIsEmptyPush": "",
    "theTrackingNumberIsEmptyPush": "",
    "doesntMeetTheOrderStatusRequirement": "",
    "problemOrderStoredOrderPush": "",
    "reminderOnceTheStoreStockIs": "",
    "whenANewOrderIsPlaced": "",
    "theStockOfAllChannelProducts": "",
    "afterTurningOnStockMonitorAnd": "",
    "addingeditingProductsOrStockInVivo": "",
    "beforeOutboundItHasBeenVerified": "",
    "areYouSureToEnableStock": "",
    "areYouSureToDisableStock": "",
    "plannedInboundCost": "",
    "onlyTheGineeWmsWarehouseSupports": "",
    "editingThePushStrategyWillOnly": "",
    "userManuallyEditedTheWarehouseStock": "",
    "multipleChannelProductsAreBoundTo": "",
    "orderSyncDelayDueToDelay": "",
    "failedPushStockStockNotPushed": "",
    "TheStockWasEditedIn": "",
    "AbnormalitiesWhenTheChannelAdjusts": "",
    "gineeSuccessfullyPushedTheStockAnd": "",
    "pushDelayDueToTheLarge": "",
    "noPushRecordFoundStockNot": "",
    "thisStoreHasReservedPromotionStock": "",
    "theStockMonitorAndSyncIs": "",
    "missingOrderInformationsCurrentlyUnableTo": "",
    "pleaseCheckTheChangeReasonOn": "",
    "pleaseCheckThePushFailedReason": "",
    "forOtherChannelsPleaseDirectlyContact": "",
    "PleaseContactGineeCustomerService": "",
    "PleaseSyncTheProductThrough": "",
    "forShopeeBukalapakAndLazadaProducts": "",
    "ifYouDidntEditTheStock": "",
    "preventTheSameIssueHappeningIn": "",
    "toPreventTheSameIssueHappening": "",
    "pleaseEnableStockMonitorAndSync": "",
    "notesIfTheStockIsSmaller": "",
    "pleaseContactGineeCustomerServiceOr": "",
    "gineeOmsOrderusedtotal": "",
    "mskuUsedtotal": "",
    "currentlyDoesNotSupportUploadingeditingShopee": "",
    "gineeWmsOrderusedtotal": "",
    "walletCredit": "",
    "gineeWmsConsignorusedtotal": "",
    "onlySupportViewingCustomerDataFrom": "",
    "packageSplit": "",
    "theMskuWasNotFoundIn": "",
    "changePrice": "",
    "discAfterTax": "",
    "discBeforeTax": "",
    "productTaxIncluded": "",
    "additionalTax": "",
    "vatRules": "",
    "vat": "",
    "selectAndAdd": "",
    "customerNotFoundDoYouWant": "",
    "selectFromExistingCustomerList": "",
    "enterCustomerPhoneNumberToAdd": "",
    "addBuyer": "",
    "unknown": "",
    "afterDeduction": "",
    "byPercentage": "",
    "byAmount": "",
    "deductionMethod": "",
    "deductionAmount": "",
    "actualPayment": "",
    "pleaseAddProductsFirst": "",
    "youWantToClearYourCart": "",
    "vatIncluded": "",
    "payable": "",
    "productSubtotal": "",
    "viewDetails": "",
    "productDiscount": "",
    "wholeOrderReduction": "",
    "youSureYouWantToDelete": "",
    "switchingStores": "",
    "wantToChangeTheStoreTo": "",
    "clearShoppingCart": "",
    "disc": "",
    "totalXItems": "",
    "deduction": "",
    "checkOut": "",
    "submitOrder": "",
    "productBarcode": "",
    "pleaseScanTheProductBarcode": "",
    "cashier": "",
    "signalStrength": "",
    "language": "",
    "loginToTheStoreSuccessfully": "",
    "pleaseSelectAStore": "",
    "thereIsNoAccessibleStorePlease": "",
    "partialRefund": "",
    "refunded": "",
    "partialReturn": "",
    "completedDone": "",
    "printReceipt": "",
    "noNeedToPrintReceipt": "",
    "cashReceived": "",
    "transactionAmount": "",
    "numberOfItems": "",
    "customer": "",
    "transactionTime": "",
    "transactionSuccessful": "",
    "reorder": "",
    "theShoppingCartWillBeAutomatically": "",
    "theStoreHasChangedItsWarehouse": "",
    "continueToPlaceAnOrder": "",
    "removeOutOfStockProducts": "",
    "outOfStockQuantity": "",
    "theFollowingProductsAreOutOf": "",
    "card": "",
    "returnFailedTheReturnedItemHas": "",
    "returnFailedTheReturnWarehouseIs": "",
    "refundToTheOriginalCard": "",
    "refundMethod": "",
    "vatRefund": "",
    "afterOperatingReturnTheStockThe": "",
    "wantToReturn": "",
    "return": "",
    "completed": "",
    "cancelEntireOrder": "",
    "onceTheEntireOrderIsCancelled": "",
    "cancelTheEntireOrder": "",
    "onlyRefundsWillOnlyAffectThe": "",
    "youWantToRefund": "",
    "orderActualPayment": "",
    "amountExcludeVat": "",
    "companyPhoneNumber": "",
    "companymerchantTin": "",
    "companymerchantLegalAddress": "",
    "companymerchantName": "",
    "totalAmountPaid": "",
    "addVatX": "",
    "amountDue": "",
    "lessDiscounts": "",
    "lessVatx": "",
    "totalAmount": "",
    "vatExemptSales": "",
    "zeroratedSales": "",
    "vatableSales": "",
    "itemBig": "",
    "siNo": "",
    "businessStyle": "",
    "tinNo": "",
    "soldTo": "",
    "changes": "",
    "totalPaid": "",
    "totalOrder": "",
    "vatx": "",
    "totalDisc": "",
    "totalItem": "",
    "cashierPerson": "",
    "orderNo": "",
    "insufficientStockUnableToPurchaseProducts": "",
    "isNotCorrectPleaseCheckPhone": "",
    "mobileRangeLengthWithPrefix": "",
    "mobileLengthWithPrefix": "",
    "mobileRangeLength": "",
    "posSettings": "",
    "manageGineePosStore": "",
    "changingWarehousesWillReplaceTheProducts": "",
    "taxCollectionPreference": "",
    "orderTotalAfterRefund": "",
    "productQuantity": "",
    "selectReturnProduct": "",
    "receipt": "",
    "newDiscountedPrice": "",
    "taxDiscountPreference": "",
    "pleaseEnterCorrectUsername": "",
    "orderPhoneNumber": "",
    "customerNam": "",
    "theAmountDueCannotBeLess": "",
    "excessiveReturnIsNotSupported": "",
    "theStoreIsNotBoundTo": "",
    "orderDoesNotExist": "",
    "theStoreDoesNotExist": "",
    "productBarcodeXDoesNotExist": "",
    "notExistInTheWarehouse": "",
    "addMskuToTheWarehouseFirst": "",
    "pay": "",
    "aOperationToCancelTheEntire": "",
    "aOperationReturnAndRefundMsku": "",
    "aOperationRefundRefundAmountB": "",
    "discountPriceCannotBeHigherThan": "",
    "supportsInputNumNoMore": "",
    "ooOrders": "",
    "ifYouDoNotHaveOms": "",
    "totalVatInc": "",
    "netDue": "",
    "lessOtherDiscounts": "",
    "customerPhoneNo": "",
    "doNotAllowLazadaEase": "",
    "doNotAllowTokoOO": "",
    "youHaveAddedAChannelSku": "",
    "bindingSkuWithDifferentNamesMay": "",
    "channelReturnedErrorReachTheChannel": "",
    "expiredStoresWillNotPushInventory": "",
    "massReleaseWillReleaseTheReserved": "",
    "recommendedOperation": "",
    "oversoldReasons": "",
    "pleaseEnable": "",
    "ThereAreCancellationreturnrefundOrdersOn": "",
    "PleaseCheckWhetherThereAre": "",
    "skuxAlreadyExistsInOmsAnd": "",
    "oneclickOversoldCheck": "",
    "massRelease": "",
    "thisOperationWillReleaseTheReserved": "",
    "imageSizeMustBeGreaterThan": "",
    "clickToUpdateBasicInformationSuch": "",
    "clickRefreshToObtainTheLatest": "",
    "bySelectedProducts": "",
    "byStoreName": "",
    "settingProductDescription": "",
    "paymentReconciliation": "",
    "partialRelease": "",
    "released": "",
    "toRelease": "",
    "marketplaceReleasedAmountForTheOrder": "",
    "orderPackageNumber": "",
    "marketplaceReleasedAmountEstimatedRelease": "",
    "afterTheOrderIsCompletedThe": "",
    "settlementDetails": "",
    "amountDifference": "",
    "marketplaceReleasedAmount": "",
    "reconciliationStatusProvidedByTheChannel": "",
    "feesCharges": "",
    "voucherRebates": "",
    "totalExpenses": "",
    "estimatedReleaseAmountTotalRevenue": "",
    "estimatedReleaseAmount": "",
    "timeoutPaymentIsNotSettledWithin": "",
    "settlementDataIsSyncedOnceA": "",
    "xDigitsOfEnglishChineseNumbers": "",
    "pushSuccessnoShippingLabel": "",
    "pushSuccesshasShippingLabel": "",
    "clickTheOkButtonToRecreate": "",
    "youSureYouWantToRecreate": "",
    "atchEditingOfBrandsInMultiple": "",
    "editingOfBrandsInMultiple": "",
    "masstRemark": "",
    "pushFailedKeeppackOutboundOrderStatus": "",
    "printingFailedTheOrderIsMissing": "",
    "dearCustomer": "",
    "yourFollowingResourcesHaveExceededThe": "",
    "selectXStoresToContinueBinding": "",
    "selectedXStore": "",
    "staffAccount": "",
    "selectXStaffAccountsToContinue": "",
    "onlySupportsUploadingPdfFilesMax": "",
    "shippingLabelHasBeenUploaded": "",
    "uploadShippingLabel": "",
    "shippingLabelPdf": "",
    "cancellationTime": "",
    "shippingTime": "",
    "inventorySynchronizationSwitchTurnedOff": "",
    "theFollowingFunctionsWillBeAutomatically": "",
    "afterThePackageExpiresStockSynchronization": "",
    "lazadaPhOrdersOnlySupportPrinting": "",
    "companymerchantVatTinNo": "",
    "afterTheSwitchIsTurnedOn": "",
    "checkStockBeforePushing": "",
    "theStrategyImplementedByTheWarehouse": "",
    "whenTheOrderMeetsThePush": "",
    "shopeeApiRequiresThatAllVariation": "",
    "sellerOwnFleet": "",
    "addedToday": "",
    "globalProduct": "",
    "publishedSite": "",
    "sellerStock": "",
    "globalProductPrice": "",
    "verifyMskuBeforePushingYIs": "",
    "theStockVerificationFailedBeforePushing": "",
    "enablePush": "",
    "disablePush": "",
    "changeToDisable": "",
    "changeToEnable": "",
    "productNameAccountNumber": "",
    "dueToShopeeApiLimitationsProducts": "",
    "accurateWh": "",
    "gineeWarehouse": "",
    "gineeWarehouseAddress": "",
    "gineeWarehouseName": "",
    "manageTheMappingRelationshipBetweenChannel": "",
    "youCanSelectTheChannelStore": "",
    "bindingStatus": "",
    "channelWarehouseStatus": "",
    "thePartiallyReleasedListRefersTo": "",
    "theReleasedListDisplaysTheReleased": "",
    "customTax": "",
    "promotionFee": "",
    "transactionFee": "",
    "highQuality": "",
    "commissionF": "",
    "amsCommissionFee": "",
    "reverseShippingFeeForReturnedOrders": "",
    "plShippingFeeDiscount": "",
    "shopeeShippingRebate": "",
    "sellerAbsorbedCoinCashback": "",
    "productDiscountsAndCashbackByShopee": "",
    "productRefundAmount": "",
    "productDiscountedPrice": "",
    "productOriginalPrice": "",
    "totalReleaseAmount": "",
    "settlementNo": "",
    "settlementTime": "",
    "whetherTimeout": "",
    "releasing": "",
    "orderItem": "",
    "settlementDetailsAllPages": "",
    "settlementDetailsBySelected": "",
    "ordersAllPages": "",
    "ordersBySelected": "",
    "compatibleModels": "",
    "noticeTitle": "",
    "promotionStockCannotBeGreaterThan": "",
    "pleaseEnterTheTitle": "",
    "theOutboundListHasBeenCompleted": "",
    "commissionChargedBasedOnItemUnit": "",
    "theOrderHasBeenCompletedBut": "",
    "paymentFeeForProcessingCharges": "",
    "listedPriceOfTheItemSold": "",
    "productVat": "",
    "buyerRealName": "",
    "salesInvoiceWillUseTheSelected": "",
    "realCustomerInformationInTheOrder": "",
    "excludingVat": "",
    "theSkuIsNotOversoldReason": "",
    "ifYouNeedToReleaseThe": "",
    "publishedGlobalProduct": "",
    "editingTheGlobalProductPriceWill": "",
    "editingTheGlobalProductStockWill": "",
    "pleaseNotePleaseUseMAuthorization": "",
    "theSkuIsOutOfStock": "",
    "theSkuDoesNotExistIn": "",
    "theOrdersOutboundListHasBeen": "",
    "wmsIsProcessingTheOrder": "",
    "authorizeMainAccount": "",
    "authorizeShopAccount": "",
    "customOrNo": "",
    "onlySupportsFilesPdfPngJpeg": "",
    "gineeWillAutomaticallyCreateAnInbound": "",
    "youWantToAddToThe": "",
    "feeRefundreturn": "",
    "fulfillmentFee": "",
    "shippingFeeChargedToSeller": "",
    "otherFee": "",
    "serviceFee1": "",
    "rebateFromMarketplace": "",
    "sellerPromotion": "",
    "productDiscountPrice": "",
    "pleaseRenewOrPurchaseOrderQuantity": "",
    "yourGineeWmsOrderQuotaHas": "",
    "yourGineeOmsOrderQuotaHas": "",
    "orderQuotaExceeded": "",
    "insufficientOrderQuota": "",
    "theOrderQuotaOfYourCurrent": "",
    "bySelectingAllLazadaWillConsider": "",
    "abnormalExpiration": "",
    "pushOutboundOrders": "",
    "pushInboundOrders": "",
    "directInbound": "",
    "updatedToBePushed": "",
    "addressNameAndPhoneNumberWill": "",
    "syncChannelAddress": "",
    "withholdingTax": "",
    "theCnscpfsChannelWarehouseIsNot": "",
    "afterSettingTheShippingMethodWhen": "",
    "theOutboundOrderIsInvalidIn": "",
    "progress": "",
    "setShippingInfo": "",
    "outboundOrderHasBeenSuccessfullyPushed": "",
    "mStoreAbnormallyExpired": "",
    "oneClickToChangeTheStores": "",
    "changeAddressByStore": "",
    "youCanQuicklyChangePickupAddress": "",
    "invalidTrackingNumberPleaseGoTo": "",
    "invalidTrackingNumberawb": "",
    "channelAbnormalOrders": "",
    "tryNoww": "",
    "congratulationsYouHaveReceivedADay": "",
    "mskuTotal": "",
    "updateUsageEveryHours": "",
    "dailyUpdateUsage": "",
    "replacementSuccessful": "",
    "theFollowingOrdersNeedToAdd": "",
    "toEnsureNormalUse": "",
    "invoiceNumberMustBeDigits": "",
    "companymerchantInformation": "",
    "orDate": "",
    "orNo": "",
    "pleaseEnterTheWarehouseCodeNo": "",
    "failedToPrintInvoice": "",
    "thatIsOfficialReceiptNumberYou": "",
    "theCompanyInformationSetInGinee": "",
    "setCompanyInformation": "",
    "atTheRequestOfThePhilippine": "",
    "orderDate": "",
    "thisReceiptShallBeValidFor": "",
    "acknowledgementCertificateNo": "",
    "dateIssued": "",
    "seriesRange": "",
    "handleProb": "",
    "checksFailed": "",
    "successfullyDeletedTheWarehouse": "",
    "startDeletion": "",
    "checksPassed": "",
    "inventoryClearanceAllProductStocksAre": "",
    "manualInboundListAllDocumentsHave": "",
    "purchaseInboundListAllDocumentsHave": "",
    "manualOutboundListAllDocumentsHave": "",
    "salesOutboundListAllDocumentsHave": "",
    "deletingAWarehouseRequiresCheckingThe": "",
    "checkingWarehouseDeletion": "",
    "beforeDeletingAWarehouseYouNeed": "",
    "oneAreYouSureYouWantTo": "",
    "theNumberOfLocalWarehouseHas": "",
    "oneFailedToSaveTheNumberOf": "",
    "failedToSaveTheNumberOf": "",
    "failedToSaveTheBoundCombined": "",
    "failedToSaveTheVariationsOf": "",
    "bindingFailedTheChannelSkuBound": "",
    "deletingWarehouse": "",
    "deletingWarehouseProducts": "",
    "deletingMIO": "",
    "deletingPIO": "",
    "deletingMOO": "",
    "deletingSOO": "",
    "none": "",
    "InboundStockIsOnlyValid": "",
    "differentWeightdimensionsForVariations": "",
    "becauseAllVariationsHaveExactlyThe": "",
    "mskuXWarehouseNameOrWarehouse": "",
    "mskuDoNotExist": "",
    "masterProductXDoesNotExist": "",
    "theOutboundListHasBeenPushed": "",
    "orderXStatusDoesNotAllow": "",
    "orderXDoesNotExist": "",
    "thePaymentAmountShouldBeLess": "",
    "theTotalAmountOfProductsExceeds": "",
    "theOrderNoIsDuplicatedPlease": "",
    "theStoreCannotBeEmpty": "",
    "channelRequirementsXMustBeBetween": "",
    "itemCount": "",
    "variationOptionLength": "",
    "variationTypeLength": "",
    "itemDescriptionLength": "",
    "itemImageCount": "",
    "itemNameLength": "",
    "wholesalePriceThresholdPercentage": "",
    "sinceSomeProductsHaveTheSame": "",
    "toEnableDifferentWeightdimensionsForEach": "",
    "mskuHasBeenInbounded": "",
    "exportFailed": "",
    "staffToRemovePermissions": "",
    "staffsToRetainPermissions": "",
    "storesToDeactivate": "",
    "storesToRetain": "",
    "staffAccountsToContinueUsing": "",
    "storesToContinueBinding": "",
    "pleaseConfirmTheStoreOrStaff": "",
    "theDefaultPickupTimeLatest": "",
    "theDefaultPickupTimeEarliest": "",
    "doNotAutomaticallySelectThePickup": "",
    "setTo": "",
    "onlyApplicableToOrdersWithPickup": "",
    "automaticallySelectThePickupTime": "",
    "whenYouSelectLatest": "",
    "whenYouSelectEarliest": "",
    "thisOrderIsFromAMultiwarehouse": "",
    "noManualSyncTask": "",
    "taskIds": "",
    "manualSyncProduct": "",
    "manualSyncOrder": "",
    "syncHistoricalOrders": "",
    "sinceTiktokShopWillDelayReleasing": "",
    "syncProgress": "",
    "notAbnormal": "",
    "reservationFailedTheBoundGineeWarehouse": "",
    "discountType": "",
    "percentageOff": "",
    "fixedPrice": "",
    "variationlevel": "",
    "productlevel": "",
    "youWantToSwitchTheDatabase": "",
    "allSettingsAndMappingsWillBe": "",
    "changeDatabase": "",
    "pleaseSelectTheDatabaseToChange": "",
    "ifYouSwitchToANew": "",
    "mappingS": "",
    "channelStoreName": "",
    "customersRealName": "",
    "productTaxSettings": "",
    "whetherTheProductWillIncludeVat": "",
    "noVat": "",
    "addVat": "",
    "gineeWarehouseCanOnlyBeMapped": "",
    "dataLinkedWithBranchesBanksAnd": "",
    "shippingFeeChargedToSellersCalculated": "",
    "otherDeductedFeesProvidedByThe": "",
    "rebateAndDiscountAmountAsProvided": "",
    "sellerPromotionFeeAsProvided": "",
    "serviceFeeCommissionFeeAndOther": "",
    "importantNotesErrorNotificationTheCumulative": "",
    "historicalOrdersSettlementDataWillNot": "",
    "variousServiceCharges": "",
    "thePromotionPeriodMustBeLonger": "",
    "purchaseLimitCannotBeGreaterThan": "",
    "fulfilledByChannel": "",
    "jitPo": "",
    "advanceFulfillment": "",
    "notSpecial": "",
    "createdByShopee": "",
    "matchPending": "",
    "discountPriceIsLowerThanM": "",
    "theDiscountRateMustNotbeHigher": "",
    "limitationMethod": "",
    "variationDimension": "",
    "noLimit": "",
    "invalidDiscount": "",
    "invalidPrice": "",
    "TheStartTimeMustBe": "",
    "ThePromotionPeriodMustBe": "",
    "productId": "",
    "variationId": "",
    "thePromotionsPeriodMustBeLonger": "",
    "thePromotionuPeriodMustBeLonger": "",
    "unavailableStockIntransit": "",
    "stockTransferredToSortingCenterBy": "",
    "advanceStock": "",
    "outboundQuantityMustBe": "",
    "needHelpWereHereForYou": "",
    "orderXIsMatchedWithShopee": "",
    "bookingId": "",
    "availStock": "",
    "notMatchedToOrder": "",
    "importToCreateCombinedProduct": "",
    "youCanImportUpTo": "",
    "matched": "",
    "marketplaceSpecialOrder": ""
};
});

var th = createCommonjsModule(function (module, exports) {
exports.__esModule = true;
exports.th = void 0;
exports.th = {
    "globalNoData": "ไม่มีข้อมูล",
    "globalPleaseSelect": "กรุณาเลือก",
    "globalPleaseEnter": "กรุณากรอก",
    "globalsYearly": "รายปี",
    "globalsMonthly": "ราย 1 เดือน",
    "globalsWeekly": "รายสัปดาห์",
    "globalsYesterday": "เมื่อวาน",
    "globalsExportDisabled": "หมดอายุแล้ว",
    "globalsExportFailed": "ดาวน์โหลดล้มเหลว",
    "globalsExportFinished": "ถูกต้อง",
    "globalsExportINProcess": "กำลังประมวลผล",
    "globalsExportStatus": "สถานะ",
    "globalsPackageExpired": "แพ็คเกจคุณหมดอายุแล้ว",
    "globalsLater": "ภายหลัง",
    "globalsContactNow": "ติดต่อทันที",
    "globalsContactUsPackage": "กรุณาติดต่อเราสำหรับข้อมูลแพ็คเกจและใบเสนอราคา",
    "isRequired": "จำเป็นต้องกรอก",
    "globalsGoContinue": "ดำเนินการต่อ",
    "globalsBrowserBlockedInfo": "ตรวจพบว่าหน้าต่างป๊อปอัปใหม่ถูกบล็อกโดยเบราว์เซอร์ โปรดคลิก \"ดำเนินการต่อ\" เพื่อเปิดหน้าต่างหน้าใหม่ (คุณสามารถค้นหา \"Remove Browser Blocking Method/ Cara \" ในศูนย์ช่วยเหลือเพื่อปลดบล็อกได้)",
    "globalsNotification": "คำแจ้งเตือน",
    "globalsPleaseSelect": "กรุณาเลือก",
    "globalsIsRequired": "จำเป็นต้องกรอก",
    "globalsOperationGuide": "คู่มือการใช้งาน",
    "globalsSyncError": "เชื่อมต่อล้มเหลว",
    "globalsSynchronizing": "กำลังเชื่อมต่อ",
    "globalsSync": "เชื่อมต่อ",
    "globalsSyncSuccess": "เชื่อมต่อสำเร็จ",
    "globalsNoProductYet": "ยังไม่เพิ่มสินค้า",
    "globalsSeeMore": "ดู{ more }รายงาน",
    "globalsSeeMoreRetract": "ย่อเนื้อหา",
    "globalsSeeMoreExpand": "ดูเพิ่มเติม",
    "globalsNoName": "ไม่มีชื่อ",
    "globalsTotalPage": "ทั้งหมด { total }",
    "globalsReset": "ตั้งค่าใหม่",
    "globalsSearch": "ค้นหา",
    "globalNoEmptyText": "ไม่มีข้อมูล",
    "globalNoPermission": "บัญชีของคุณไม่ได้รับอนุญาตให้เข้าถึง โปรดติดต่อผู้ดูแลระบบร้านค้าเพื่อถามหรืออัปเกรด",
    "globalAction": "ดำเนินการ",
    "globalTaskResult": "ผลลัพธ์",
    "globalTaskFailedNumber": "ล้มเหลว",
    "globalTaskTotalNumber": "ทั้งหมด",
    "globalTaskSuccessNumber": "สำเร็จ",
    "globalTaskFailed": "ดำเนินการล้มเหลว กรุณาลองใหม่อีกครั้งภายหลัง",
    "globalTaskSuccess": "ดำเนินการสำเร็จ",
    "globalTaskWait": "กำลังดำเนินการ กรุณารอสักครู่",
    "globalStatus_unpublished": "ยังไม่เผบแพร่",
    "globalStatus_update_failed": "เผบแพร่ล้มเหลว",
    "globalStatus_delete": "ลบแล้ว",
    "globalStatus_draft": "ฉบับร่าง",
    "globalStatus_disabled": "เลิกจำหน่าย",
    "globalStatus_banned": "ห้ามขาย",
    "globalStatus_sold_out": "ขายหมด",
    "globalStatus_live": "เพิ่มแล้ว",
    "globalStatus_process": "กำลังเผยแพร่",
    "globalStatus_all": "ทั้งหมด",
    "globalName": "Global Name TH",
    "goToLinkStoreTip": "ไม่มีร้านค้าที่เชื่อมต่อ กรุณาเชื่อมต่อร้านค้าก่อนเพิ่มสินค้า",
    "goToLinkStore": "เชื่อมต่อร้านค้า",
    "remove": "ลบ",
    "distributionSettings": "การตั้งค่าการเติมเต็ม",
    "menuThirdPartyWarehouseProductList": "รายการสินค้าของคลังฝากสต็อกสินค้า",
    "serviceLink": "กลุ่มGinee Support",
    "thirdPartyWarehouseStocktakingList": "คลังสินค้าที่ 3 ดึงสต็อก",
    "explore": "วิเคราะห์ตลาด",
    "authPlatform": "การอนุญาตให้เข้าถึง",
    "yilianFulfillment": "YiLian Fulfillment",
    "syncdiconnected": "ยกเลิกการเชื่อมต่อ",
    "syncsuccess": "เชื่อมต่อสำเร็จ",
    "syncing": "กำลังเชื่อมต่อ",
    "commissionSetting": "กฎคอมมิชชั่น",
    "LAInvoiceSettingsTab": "กฎใบกำกับภาษี",
    "ExportTemplateTab": "เทมเพลตนำออก",
    "documentTemplatSeettings": "เทมเพลตเอกสาร",
    "selectfulfillment": "กรุณาเลือกผู้ให้บริการคลังสินค้าพร้อมจัดส่ง",
    "haventBound": "คุณยังไม่ได้เชื่อมต่อผู้ให้บริการคลังสินค้าพร้อมจัดส่ง ตอนนี้ไปเชื่อมต่อไหม?",
    "newRule": "เพิ่มกฎ",
    "menuProductTiki": "รายการสินค้า / Tiki",
    "logout": "ออกจากระบบ",
    "productTools": "เครื่องมือจัดการสินค้า",
    "menuCustomer": "ลูกค้า",
    "menuPromotion": "โปรโมชั่น",
    "menuWarehouse": "คลังสินค้า",
    "GineeChat": "แชท",
    "menuGineeCapital": "เงินสนับสนุน",
    "addIntegration": "เพิ่มร้านค้าที่เชื่อมต่อ",
    "thirdpartyWarehouseList": "รายการคลังฝากสต็อกสินค้า",
    "menuIntegration": "เชื่อมต่อร้านค้า",
    "logisticsReport": "รายงานการขนส่ง",
    "customerReport": "รายงานลูกค้า",
    "inventoryReport": "รายงานสต็อก",
    "salesReport": "รายงานการขาย",
    "blacklist": "บัญชีดำ",
    "customerList": "รายชื่อลูกค้า",
    "discountList": "รายการส่วนลด",
    "discountManagement": "การจัดการส่วนลด",
    "transfer": "โอนย้ายสินค้า",
    "stoktaking": "ตรวจนับสต็อก",
    "menuInbound": "การจัดการใบเข้าคลัง",
    "menuOutbound": "การจัดการใบออกคลัง",
    "menuProductLocation": "การจัดการชั้นวางสินค้า",
    "warehouseList": "รายการคลังสินค้า",
    "stockPullRecord": "บันทึกข้อมูลสต็อก",
    "safetyStockManagement": "การจัดการสต็อกปลอดภัย",
    "stock": "สต็อก",
    "storeMoving": "คัดลอกร้านค้า",
    "productScrape": "การคัดลอกสินค้า",
    "channelProduct": "สินค้าช่องทางการขาย",
    "masterProductList": "รายการสินค้าหลัก",
    "orderProcess": "การจัดการคำสั่งซื้อ",
    "allOrders": "คำสั่งซื้อทั้งหมด",
    "MenuOrders": "คำสั่งซื้อ",
    "putAside": "ยังไม่จัดการ",
    "autoBump": "โปรโมทสินค้าโดยอัตโนมัติ",
    "oneStoresProductsToAnyStores": "คัดลอกสินค้าของร้านค้าใดไปยังร้านค้าอื่นเพื่อเปิดร้านอย่างรวดเร็ว",
    "blibliProductList": "รายการสินค้าของ Blibli",
    "FulfillSetting": "การจัดการเพื่อกำหนดค่า",
    "MassAdd": "สร้างเทพเพลตเป็นกลุ่ม",
    "PromotionEditTpl": "แก้ไขเทมเพลต",
    "PromotionAddTpl": "เทมเพลตใหม่",
    "PromotionTpl": "เทมเพลตโปรโมชั่น",
    "IntegrationsUpdateStore": "อัปเดตข้อมูลร้านค้า",
    "PrintPickingListTemplate": "เทมเพลตใบหยิบสินค้า",
    "PrintInvoiceTemplate": "เทมเพลตใบกำกับสินค้า",
    "PrintPackingListTemplate": "เทมเพลตใบกำลับหีบห่อ",
    "PrintLabelTemplate": "เทมเพลตใบปะหน้าพัสดุ",
    "addBundleISKU": "เพิ่มสต็อกSKU",
    "editBundleISKU": "แก้ไขสต็อกSKU",
    "menuInboundManagementSubmit": "การจัดการสต็อกคงคลังขาเข้า /รายการสต็อกขาเข้า",
    "menuInboundManagementDetail": "การจัดการสต็อกคงคลังขาเข้า /รายละเอียดรายการสต็อก",
    "menuInboundManagementEdit": "การจัดการสต็อกคงคลังขาเข้า / แก้ไขใบรายการสต็อก",
    "menuInboundManagementAdd": "การจัดการสต็อกคงคลังขาเข้า / สร้างใบรายการสต็อก",
    "menuOutboundManagementSubmit": "การจัดการขนส่งขาออก /รายการขนส่งขาออก",
    "menuOutboundManagementDetail": "การจัดการขนส่งขาออก / รายละเอียดใบรายการขนส่ง",
    "menuOutboundManagementEdit": "การจัดการขนส่งขาออก / แก้ไขใบรายการขนส่ง",
    "menuOutboundManagementAdd": "การจัดการขนส่ง / สร้างใบรายการขนส่ง",
    "safetyStockSettings": "การตั้งค่าสต็อกความปลอดภัย",
    "storeStockChangeHistory": "บันทึกการดันสต็อกร้านค้า",
    "stockPullRecordDetail": "บันทึกการดึงสต็อกรายละเอียด",
    "thirdpartyWarehouseInventoryPullRecord": "บันทึกการดึงสต็อก",
    "stockInOutRecord": "บันทึกสต็อกเข้า / ออก",
    "editWarehouseStock": "แก้ไขสต็อกคงคลัง",
    "editLocalISKU": "แก้ไข SKU หลัก",
    "editISKU": "แก้ไขสต็อก SKU",
    "binPsku": "เชื่อมร้านค้าSKU",
    "addISKU": "เพิ่มสต็อกSKU",
    "EditOrderTpl": "แก้ไขเทมเพลต",
    "AddOrderTpl": "เพิ่มเทมเพลต",
    "CRMHistory": "ประวัติ",
    "CRMSales": "การจัดการการขาย",
    "CRMTelemarketing": "การจัดการการตลาดทางโทรศัพท์",
    "SubscribeNow": "เป็นสมาชิกตอนนี้",
    "RenewNow": "ต่ออายุตอนนี้",
    "PaymentSubscriptionPrice": "แพ็คเกจ & ราคา",
    "PaymentSubscriptionResult": "สถานะการชำระเงิน",
    "AccountPackageSetting": "บัญชี & แพ็คเกจปัจจุบัน",
    "PackageSubscription": "สมัครสมาชิก",
    "TransactionHistory": "บันทึกการทำธุรกรรม",
    "menuScrapingSettings": "การตั้งค่าคัดลอก",
    "ExclusiveSubscription": "ติดตามยอดนิยม",
    "TopPopularStore": "ร้านค้ายอดนิยม",
    "AccountManagement": "การจัดการแพ็คเกจ",
    "OrderBatchLabel": "พิมพ์เป็นกลุ่ม",
    "GineeChartRoom1": "แชทหลายช่องทาง",
    "InvoicePrintTemplateSettings": "การตั้งค่าเทมเพลตใบกำกับภาษี",
    "PackingListPrintTemplateSettings": "การตั้งค่าเทมเพลตใบกำลับหีบห่อ",
    "ShippingLabelTemplateSettings": "การตั้งค่าเทมเพลตฉลากพัสดุ",
    "SenderInformationSettings": "ข้อมูลการจัดส่ง",
    "ShippingRuleSettings": "วิธีการจัดส่ง",
    "GINEEFulfillment": "Ginee Fulfillment",
    "YUEHAIFulfillment": "YUEHAI Fulfillment",
    "FASTOCKFulfillment": "FASTOCK Fulfillment",
    "JDFulfillment": "JD Fulfillment",
    "OrderSettings": "การตั้งค่าคำสั่งซื้อ",
    "CollectEdit": "คัดลอกไปยังสินค้าหลัก",
    "CollectSetting": "การตั้งค่าคัดลอก",
    "ScrapeList": "รายการคัดลอก",
    "ReportChart": "รายงาน",
    "ProductsCollection": "คัดลอกสินค้า",
    "editrole": "แก้ไขหน้าที่",
    "addrole": "เพิ่มหน้าที่",
    "RoleSetting": "การตั้งค่าหน้าที่",
    "StaffSetting": "การตั้งค่าพนักงาน",
    "addstaff": "เพิ่มทีมงาน",
    "staffManagementAdd": "การจัดการพนักงาน/ เพิ่มทีมงาน",
    "staffManagementEdit": "การจัดการพนักงาน / แก้ไขทีมงาน",
    "editstaff": "แก้ไขทีมงาน",
    "subscribe": "สมัครสมาชิก",
    "days": "วัน",
    "freetrial": "ทดลองใช้งานฟรี",
    "contactus": "ติดต่อเรา",
    "EditCustomer": "แก้ไขลูกค้า",
    "PromotionManagement": "การจัดการโปรโมชั่น",
    "mProductSettings": "การตั้งค่าสินค้าหลัก",
    "productSettings": "การตั้งค่าสินค้า",
    "CustomerDetail": "รายละเอียดลูกค้า",
    "NewCustomer": "เพิ่มลูกค้า",
    "GroupManagement": "กลุ่มลูกค้า",
    "CustomerManagement": "การจัดการลูกค้า",
    "MasterProductSettings": "การตั้งค่าสินค้าหลัก",
    "StockSetting": "การตั้งค่าสต็อก",
    "menuAddProductToWarehouseEditStock": "กรอกสต็อกสินค้า",
    "menuAddProductToWarehouse": "เพิ่มสินค้าเข้าคลัง",
    "stockManageList": "รายการสต็อกสินค้า",
    "stockManage": "การจัดการสต็อก",
    "BarChart": "วิเคราะห์ธุรกิจ",
    "Masseditcategories": "แก้ไขเป็นกลุ่มตามหมวดหมู่",
    "MassEdit": "แก้ไขเป็นกลุ่ม",
    "ShippingSetting": "การตั้งค่าการจัดส่ง",
    "BasicInformation": "ข้อมุลพื้นฐาน",
    "BasicSetting": "การตั้งค่าพื้นฐาน",
    "ToolTipInfo": "รวมถึง: สินค้าที่ขายอยู่, สินค้าที่ขายหมด, สินค้าที่ละเมิด",
    "Result": "ผล",
    "PublishWait": "กำลังดำเนินการเผยแพร่ โปรดรอสักครู่",
    "UpdateFailed": "การอัปเดตล้มเหลว",
    "Publishing": "สินค้าที่คัดลอกเป็นฉบับร่าง",
    "TryAgain": "ลองใหม่",
    "SomeStoresCopyFailed": "บางร้านคัดลอกไม่สำเร็จ",
    "CopySuccess": "คัดลอกสำเร็จ",
    "ViewNow": "ดูทันที",
    "Close": "ปิด",
    "NoCopyStore": "ไม่พบร้านค้าที่เชื่อมต่อ โปรดเชื่อมต่อร้านค้าก่อนใช้งานระบบคัดลอกร้านค้า",
    "CopyPublish": "เผยแพร่",
    "CopyAsDraft": "คัดลอกเป็นฉบับร่าง",
    "CopyCount": "สินค้ากำลังรอการคัดลอก",
    "Live": "สินค้าที่ขายอยู่",
    "All": "สินค้าทั้งหมด",
    "Product": "สินค้า",
    "Store": "ร้านค้า",
    "Channel": "ช่องทาง",
    "CopyTo": "คัดลอกไปยัง",
    "CopyFrom": "คัดลอกจาก",
    "CopyAlertInfo": "คัดลอกสินค้าของร้านหนึ่งไปยังร้านค้าอื่นในช่องแพลตฟอร์มเดียวกันเพื่อให้เปิดร้านใหม่ได้รวดเร็วขึ้น",
    "OperationGuide": "คู่มือการใช้งาน",
    "CopyStore": "คัดลอกร้านค้า",
    "AddStore": "เพิ่มร้านค้า",
    "Copy": "คัดลอก",
    "StoreList": "รายชื่อร้านค้า",
    "MyStores": "คัดลอกร้านค้า",
    "customerCenter": "สวัสดี เราจะช่วยคุณได้อย่างไรบ้าง? สามารถติดต่อเราได้เวลา 09.00-18.00 น.",
    "addStore": "เพิ่มร้านค้า",
    "helpInfo": "ศูนย์ช่วยเหลือ",
    "addSyncStore": "เพิ่มการเชื่อมต่อร้านค้า",
    "lastSyncTime": "เวลาเชื่อมต่อล่าสุด",
    "alreadyStore": "ร้านค้าที่เชื่อมต่อ",
    "menuProductLocalUpdateAutoBind": "สินค้าหลัก / อัปเดตการเชื่อมโดยอัตโนมัติ",
    "menuInboundManagement": "การจัดการใบเข้าคลัง",
    "menuOutboundManagement": "การจัดการใบออกคลัง",
    "menuLowStockReport": "สถิติยอดขายตำ่",
    "menuLogisticsCostStatistics": "รายงานสถิติค่าขนส่ง",
    "menuOutStockForecast": "รายงานพยากรณ์สต็อก",
    "menuInventoryStatistics": "รายงานสถิติสต็อกคงคลัง",
    "menuLogisticsStatistics": "รายงานสถิติการขนส่ง",
    "menuCustomerAreaAnalysis": "ตารางวิเคราะห์ภูมิภาคของลูกค้า",
    "menuCustomerAnalysis": "ตารางการวิเคราะห์ห์ลูกค้า",
    "menuCustomerStatistics": "สถิติลูกค้า",
    "menuStoreSaleReport": "ยอดขายร้านค้า",
    "menuPlatformSaleReport": "ยอดขายช่องทางการขาย",
    "menuIncomeStatementReport": "สถิติงบกำไรขาดทุน",
    "menuCategorySaleReport": "สถิติการขายตัวเลือกสินค้า",
    "menuProductSaleReport": "สถิติการขายสินค้า",
    "menuVariantsSalesStatistics": "สถิติการขายตัวเลือกสินค้า",
    "menuOrderReport": "สถิติคำสั่งซื้อ",
    "menuSetting": "การตั้งค่า",
    "ShippingOrderNew": "จัดส่งคำสั่งซื้อ",
    "status_ready_to_ship": "รอการจัดส่ง",
    "printOrderPending": "รอพิมพ์คำสั่งซื้อ",
    "beCollectedTo": "ที่จะรับ",
    "orderAbnormal": "คำสั่งซื้อที่ผิดปกติ",
    "PendingOrder": "คำสั่งซื้อรอดำเนินการ",
    "pending": "รอดำเนินการ",
    "operationRecord": "บันทึกการทำงาน",
    "historyOperation": "บันทึกการดำเนินงานคลังสินค้าจัดส่ง",
    "shipped": "จัดส่งแล้ว",
    "stored": "ยังไม่จัดการ",
    "warehouseSettingShipping": "กฎกำหนดคลังสินค้า",
    "orderDetails": "รายละเอียดคำสั่งซื้อ",
    "manualOrder": "เพิ่มคำสั่งซื้อด้วยตนเอง",
    "menuOrder": "รายการคำสั่งซื้อ",
    "menuIntegrations": "เชื่อมต่อร้านค้า",
    "menuProductLocationManagement": "การจัดการชั้นวางสินค้า",
    "menuProductLocationManagementOld": "การจัดการตำแหน่งจัดเก็บสินค้า",
    "menuWarehouseStockDetails": "รายละเอียดสต็อกคงคลัง",
    "menuStockPushRules": "กฎการผลักดันสต็อก",
    "menuStoreShippingWarehouseSettings": "การตั้งค่าคลังสินค้าจัดส่ง",
    "menuWarehouseManagement": "การจัดการคลัง",
    "menuPriceManagement": "การจัดการราคา",
    "menuProductLocalImportToCreate": "สินค้าหลัก / นำเข้าเพื่อสร้างสินค้า",
    "menuProductLocalImportToBind": "สินค้าหลัก / นำเข้าสินค้าที่ต้องการเชื่อมต่อ",
    "menuProductLocalEditBundle": "สินค้าหลัก / แก้ไขชุดสินค้า",
    "menuProductLocalEdit": "สินค้าหลัก / แก้ไขสินค้า",
    "menuProductLocalAddBundle": "สินค้าหลัก / เพิ่มสินค้าเป็นชุด",
    "menuProductLocalEditCombined": "สินค้าหลัก / แก้ไขสินค้ารวม",
    "menuProductLocalAddCombined": "สินค้าหลัก / เพิ่มสินค้ารวม",
    "menuProductLocalAdd": "สินค้าหลัก / เพิ่มสินค้า",
    "menuProductWooCommerce": "รายการสินค้า / WooCommerce",
    "menuBindHistory": "ประวัติการเชื่อมต่อ",
    "menuProductLocal": "สินค้าหลัก",
    "menuProduct": "รายการสินค้า",
    "menuPoints": "คะแนน",
    "menuMemberShipPage": "หน้าสมาชิก",
    "InventoryLog": "บันทึกการเปลี่ยนแปลงสต็อก",
    "menuMemberList": "รายชื่อสมาชิก",
    "noticeList": "ประกาศ",
    "menuHomePage": "แดชบอร์ด",
    "productmassEditMaster": "สินค้าหลัก/แก้ไขเป็นกลุ่ม",
    "productPublishToTheStoreMaster": "สินค้าหลัก/เผยแพร่ไปยังร้านค้า",
    "resultsPublish": "ผลลัพธ์เผยแพร่",
    "operationHistoryMass": "ประวัติการดำเนินงานเป็นกลุ่ม",
    "menuCapital": "เงินสนับสนุน",
    "menuProductTikTok": "รายการสินค้า / TikTok",
    "menuProductTikTokEdit": "รายการสินค้า / TikTok / แก้ไขสินค้า",
    "menuProductTikTokAdd": "รายการสินค้า / TikTok / เพิ่มสินค้า",
    "menuProductZalora": "รายการสินค้า / Zalora",
    "menuProductShopee": "รายการสินค้า / Shopee",
    "ImportAdd": "นำเข้าและสร้างเป็นกลุ่ม",
    "menuProductBlibliEdit": "รายการสินค้า / Blibli / แก้ไขสินค้า",
    "menuProductShopeeEdit": "รายการสินค้า / Shopee / แก้ไขสินค้า",
    "menuProductBlibliAdd": "รายการสินค้า / Blibli/ เพิ่มสินค้า",
    "menuProductShopeeAdd": "รายการสินค้า / Shopee / เพิ่มสินค้า",
    "publishToStoreTip": "สินค้าหลัก/เผยแพร่ไปยังร้านค้า",
    "changePassword": "เปลี่ยนรหัสผ่าน",
    "passworderror": "รหัสผ่านต้องมีอย่างน้อย 8 ตัวอักษร สัญลักษณ์และตัวเลข",
    "pwdErrorInfo": "รหัสผ่านต้องอยู่ระหว่าง 8-20 ตัว",
    "UpdateSuccess": "อัปเดตสำเร็จ",
    "storeDiagnose": "การวินิจฉัยร้านค้า",
    "shopeeAutoBump": "Shopee โปรโมทสินค้าโดยอัตโนมัติ",
    "menuscrapeList": "รายการคัดลอก",
    "chromeExtension": "Chrome Extension",
    "getFreeDataTool": "รับเครื่องมือข้อมูลฟรี",
    "bigdata": "วิเคราะห์ตลาด",
    "free": "ฟรี",
    "DataExtension": "ส่วนขยายคัดลอกข้อมูล",
    "confirm": "ยืนยัน",
    "noIndonesianLineGroup": "ไม่มีกลุ่มไลน์ภาษาอินโดนีเซีย",
    "noEnglishLineGroupTemporarily": "ไม่มีกลุ่มไลน์ภาษาอังกฤษ",
    "noThaiGroupsForWhatsapp": "ไม่มีกลุ่ม whatsapp ภาษาไทย",
    "noVietnameseLineGroup": "ไม่มีกลุ่มไลน์เวียดนาม",
    "noVietnameseWhatsappGroups": "ไม่มีกลุ่ม whatsapp เวียดนาม",
    "noChineseWhatsappGroupTemporarily": "ไม่มีกลุ่ม Whatsapp ภาษาจีน",
    "noChineseLineGroupTemporarily": "ไม่มีกลุ่มไลน์ภาษาจีน",
    "storeLimitTip": "แพ็คเกจที่คุณสมัครใช้งานหมดอายุแล้ว หากคุณต้องการใช้แพ็คเกจฟรี กรุณาเลือก 2 ร้านค้าเชื่อมต่อไป (แพ็คเกจฟรีสามารถเชื่อมต่อ 2 ร้านค้าได้มากที่สุด ร้านค้าที่เหลือจะถูกเลิกการเชื่อมต่อ）",
    "max2Store": "แพ็กเกจฟรีผูกได้สูงสุด 2 ร้านค้า",
    "publishToTheStore": "เผยแพร่ไปยังร้านค้า",
    "createMasterProduct": "สร้างสินค้าหลัก",
    "confirmedNewPassword": "ยืนยันรหัสผ่านใหม่",
    "newPassword": "รหัสผ่านใหม่",
    "oldPassword": "รหัสผ่านเก่า",
    "detail": "รายละเอียด",
    "editProduct": "แก้ไขสินค้า",
    "orderXxxFailedToPushWarehouse": "คำสั่งซื้อ xxx ล้มเหลวในการนำเข้าคลังสินค้า สาเหตุ: xxx",
    "orderXxxPushedToWarehouseSuccessfully": "คำสั่งซื้อ xxx เข้าคลังสินค้าสำเร็จ",
    "shippingToWarehouseOperationSucceeded": "จัดส่งไปยังคลังสินค้าสำเร็จ",
    "shippingToWarehouseOperationIsSuccessful": "ดำเนินการจัดส่งไปยังคลังสินค้าเรียบร้อยแล้ว โปรดรอผลการประมวลผลลัพธ์จากคลังสินค้า",
    "currentlyTiktokOrdersAreNotSupported": "ปัจจุบัน คำสั่งซื้อใน Tiktok ยังไม่รองรับการจัดส่งและพิมพ์ฉลากผ่าน OpenAPI",
    "shopeeOrdersWithTheSameLogistics": "คำสั่งซื้อของ Shopee/Tiktok รองรับการตั้งค่าเวลาการนัดรับสินค้า สำหรับการจัดส่งผ่านบริษัทขนส่งเดียวกัน",
    "gineeSupportsSettingUpAutomaticShipping": "Ginee รองรับการตั้งค่าวิธีการจัดส่งอัตโนมัติสำหรับร้านค้าShopee/Bukalapak/Tokopedia/Akulaku/TikTok",
    "xxxStockDeductionProcessingEnableddisabled": "เปิดใช้งาน/ปิดใช้งานการประมวลผลสินค้าคงคลังของร้าน XXX สำเร็จ",
    "theEffectiveTimeOfXxx": "เวลาที่มีผลในการประมวลผลสินค้าคงคลังในร้าน xxx เปลี่ยนจาก xxx เป็น xxx",
    "pleaseOpenAtLeastOneStore": "โปรดเปิดใช้งานอย่างน้อยหนึ่งร้านค้า",
    "thereIsAnUnfinishedOrderExport": "การส่งออกคำสั่งซื้อที่กำลังดำเนินการอยู่ โปรดไปที่ประวัติการส่งออกเพื่อดูบันทึกการส่งออก และกดส่งออกอีกครั้งหลังจากเสร็จสิ้น",
    "theSystemServiceIsAbnormalPlease": "ระบบผิดปกติ โปรดรีเฟรชหน้าจอแล้วลองอีกครั้ง",
    "afterTurnOffTheOrderWill": "หลังจากปิด คำสั่งซื้อจะถูกจัดไปยังคลังสินค้าตามลำดับความสำคัญของกฎการแยกคลังสินค้า",
    "pleaseConfirmWhetherWantToPush": "โปรดยืนยันว่าจะใช้คลังสินค้าThird Partyสำหรับการจัดส่งหรือไม่",
    "pleaseConfirmWhetherWantToRedistribute": "โปรดยืนยันว่าจะจัดส่งอีกครั้งหรือไม่",
    "outboundOrder": "หมายเลขคำสั่งซื้อออกจากคลัง",
    "splitOrder": "แยกคำสั่ง",
    "abnormalDescription": "อธิบายข้อผิดปกติ",
    "problemOrder": "คำสั่งซื้อที่มีปัญหา",
    "redistribute": "จัดส่งอีกครั้ง",
    "deliveryWarehouse": "คลังสินค้าที่ใช้จัดส่ง",
    "nonindonesiaOrdersDoNotSupportXxx": "คำสั่งซื้อที่ไม่ใช่ของอินโดนีเซียไม่รองรับการส่งมอบคลังสินค้า xxx โปรดปรับคลังสินค้าการส่งมอบ",
    "theOrderDoesNotSupportDelivery": "คำสั่งซื้อไม่รองรับการส่งสินค้าจากคลังสินค้า xxx โปรดปรับคลังสินค้าที่ใช้ในการจัดส่ง",
    "theAvailableStockOfMskuXxx": "สต็อกของ MSKU xxx ไม่เพียงพอ โปรดเติมสต็อกและลองอีกครั้ง",
    "mskuXxxIsNotAssociatedWith": "MSKU xxx ไม่เกี่ยวข้องกับคลังสินค้า xxx โปรดเชื่อมต่อคลังสินค้าและจัดส่งสินค้าอีกครั้ง",
    "channelSkuXxxIsNotBound": "ช่องทางการขายของ SKU xxx ไม่ได้เชื่อมไว้กับ MSKU โปรดเพิ่มความสัมพันธ์ในการจับคู่และสต็อกใหม่",
    "channelSkuXxxDoesNotHave": "ช่องทางการขายของ SKU xxx ไม่ได้จัดสรรคลังสินค้า โปรดเพิ่มกฎของคลังสินค้าย่อยและกระจายสินค้าอีกครั้ง",
    "failedCreateOutboundOrder": "การสร้างคำสั่งซื้อขาออกล้มเหลว",
    "warehouseAllocationError": "ข้อผิดพลาดในการจัดสรรคลังสินค้า",
    "mskuNotAssociatedWithWarehouse": "MSKU ไม่เกี่ยวข้องกับคลังสินค้า",
    "mskuNotMatched": "ไม่ได้เชื่อมกับ MSKU",
    "unallocatedWarehouse": "คลังสินค้าที่ยังไม่ได้จัดสรร",
    "pushTheThirdPartyWarehouse": "ดันคลังสินค้าThird Party",
    "paidOrder": "การชำระเงิน",
    "waitingPaymentOrder": "คำสั่งซื้อ",
    "alreadyInbound": "เพิ่มสินค้าเข้าคลังแล้ว",
    "pleaseConfirmWhetherItIsAutomatically": "โปรดยืนยันว่าจะส่งคืนโดยอัตโนมัติและเก็บไว้ในที่จัดเก็บหรือไม่",
    "inboundReturnProduct": "สินค้าคืนเข้าคลัง",
    "abnormalType": "ประเภทข้อยกเว้น",
    "printTime": "เวลาพิมพ์",
    "warehouseoutboundOrder": "เลขที่ใบสั่งซื้อคลังสินค้า/ขาออก",
    "variantsku": "ข้อมูลจำเพาะ/SKU",
    "afterOpeningTheOrderWillBe": "หลังจากเปิด คำสั่งซื้อจะถูกดันและเชื่อมไปยังคลังสินค้าของบริษัทอื่นโดยอัตโนมัติ หากตรงตามเงื่อนไขการจัดส่ง",
    "automaticallyPushToThirdpartyWarehouse": "ดันคลังสินค้าThird Partyโดยอัตโนมัติ",
    "inventoryVerificationAfterItIsTurned": "การตรวจสอบสินค้าคงคลัง: หลังจากที่เปิดใช้งานแล้ว จะตรวจสอบว่าสินค้าคงคลังที่พร้อมใช้งานในคลังสินค้าเพียงพอหรือไม่ และให้ความสำคัญกับคลังสินค้าที่มีสินค้าคงคลังเพียงพอสำหรับการจัดส่ง",
    "availableStockDeliveryDisabledSuccessfully": "ปิดในสต็อก เรือลำแรกสำเร็จแล้ว",
    "availableStockDeliveryActivatedSuccessfully": "เปิดในสต็อก จัดส่งครั้งแรกเรียบร้อยแล้ว",
    "shippingWarehouseSettingsDisabledSuccessfully": "ปิดการตั้งค่าตำแหน่งย่อยสำเร็จ",
    "shippingWarehouseSettingsActivatedSuccessfully": "เปิดการตั้งค่าตำแหน่งย่อยสำเร็จ",
    "orderstockDeductionProcessDisabledSuccessfully": "ปิดการประมวลผลสินค้าคงคลังที่เกี่ยวข้องกับคำสั่งซื้อเรียบร้อยแล้ว",
    "theStockDeductionProcessingOfXxx": "การประมวลผลสินค้าคงคลังของร้านค้า xxx ถูกเปิดใช้งาน/ปิดใช้งานเรียบร้อยแล้ว และเวลาที่มีประสิทธิภาพจะเปลี่ยนจาก xxx เป็น xxx",
    "orderstockDeductionProcessSuccessfullyActivated": "เปิดการประมวลผลสินค้าคงคลังที่เกี่ยวข้องกับคำสั่งซื้อเรียบร้อยแล้ว",
    "afterActivatedOrdersWillBeAssigned": "หลังจากเปิดแล้ว คำสั่งซื้อจะถูกจับคู่กับคลังสินค้าตามสินค้าที่แตกต่างกัน และอาจมีการกำหนดคำสั่งซื้อเดียวกันไปยังคลังสินค้าที่แตกต่างกันสำหรับการจัดส่ง มิฉะนั้น คำสั่งซื้อจะถูกกำหนดไปยังคลังสินค้าเดียวกันสำหรับการจัดส่ง",
    "shipmentWarehouseSettingsDeterminesWhetherWarehouse": "การตั้งค่าคลังสินค้าย่อย: กำหนดว่าการจับคู่คลังสินค้าเป็นโดยคำสั่งซื้อหรือสินค้า",
    "effectiveTime": "เวลาที่มีผลการใช้งาน",
    "activateStatus": "สถานะการใช้งาน",
    "activateRange": "ขอบเขตที่เปิดใช้งาน",
    "onceTurnOffAllStoreOrders": "เมื่อปิดแล้ว คำสั่งซื้อของร้านค้าทั้งหมดจะไม่ขึ้นอยู่กับการประมวลผลสินค้าคงคลัง",
    "afterActivatedTheOrderWillGenerate": "หลังจากเปิดใช้งาน คำสั่งซื้อจะสร้างเอกสารการออกเพื่อหักสินค้าคงคลังตามขั้นตอนการประมวลผล มิฉะนั้น สินค้าคงคลังจะไม่ได้รับการประมวลผลเมื่อจัดส่งสินค้า",
    "orderstockDeductionProcess": "การประมวลผลสินค้าคงคลังที่เชื่อมกับคำสั่งซื้อ",
    "orderCreatedSuccessfully": "สร้างคำสั่งซื้อด้วยตนเองสำเร็จ",
    "shipmentWarning": "แจ้งเตือนการจัดส่ง",
    "withoutUnboxingVideosComplaintsOfLessdamage": "หากไม่มีวิดีโอขณะแกะกล่อง จะไม่สามารถร้องเรียนสินค้าที่สูญหาย/เสียหายได้",
    "shippingRebate": "ส่วนลดค่าจัดส่ง",
    "shippingFeePaidByBuyer": "ผู้ขายชำระค่าขนส่ง",
    "operationIsTooFrequentPleaseExport": "ดำเนินการบ่อยเกินไป โปรดลองอีกครั้งใน 1 นาที",
    "rdPartyLogisticsShippingFee": "ค่าธรรมเนียมการจัดส่งของ Third Party",
    "shippingSubtotal": "ยอดรวมการจัดส่งสินค้า",
    "importFailedInternalSystemErrorPlease": "การอัปโหลดล้มเหลว: เกิดข้อผิดพลาดภายในระบบ โปรดอัปโหลดอีกครั้ง",
    "emailFormatIsIncorrect": "รูปแบบอีเมลไม่ถูกต้อง",
    "supportsNumberslettersMaxCharacters": "รองรับตัวเลข ตัวอักษรและ \"-\" สูงสุด 30 ตัวอักษร",
    "supportsNumberslettersspacesMaxCharacters": "รองรับเฉพาะตัวเลข ตัวอักษร ช่องว่าง และเครื่องหมาย - ไม่เกิน 20 ตัวอักษร",
    "basedOnProductSeparateRowFor": "ส่งออกตามสินค้า(แสดงคำสั่งซื้อสินค้าหลายรายการแยกกัน)",
    "basedOnOrderOrderWithMultiple": "ส่งออกตามคำสั่งซื้อ (แสดงคำสั่งซื้อสินค้าหลายรายการรวมกัน)",
    "exportMethod": "วิธีการส่งออก",
    "pleaseConfirmWhetherTheOrderStatus": "โปรดยืนยันแน่ใจว่า จะตั้งค่าสถานะคำสั่งซื้อนี้เป็นคืนสินค้า?",
    "editOrder": "แก้ไขคำสั่งซื้อ",
    "insufficientStockOfXxxPleaseMake": "สต็อก xxx ไม่เพียงพอ โปรดเติมสต็อกก่อนส่งสินค้า",
    "paymentProblem": "การชำระเงินขัดข้อง",
    "invalidOrder": "ไม่มีคำสั่งซื้อ",
    "moveToReturned": "ย้ายไปยังแถบสินค้าคืน",
    "selectCustomer": "เลือกลูกค้า",
    "CustomerMobile": "หมายเลขโทรศัพท์",
    "fyiPleaseDeleteWhenUploading": "*หมายเหตุ: กรุณาลบก่อนอัปโหลด",
    "pleaseDeleteThisTextBoxBefore": "โปรดลบกล่องข้อความนี้ก่อนนำเข้าข้อมูล",
    "fillInWithTwocharacterShortCode": "กรอกตัวอักษรย่อสำหรับรหัสประเทศ สำหรับการชำระเงิน ให้กรอกวิธีชำระเงินล่วงหน้า/ชำระเงิน จำนวนเงินที่ชำระต้องไม่เกินยอดสั่งซื้อทั้งหมด",
    "multipleMskusunitPricesquantitiesInTheSame": "หากมี SKUหลัก/ราคาต่อหน่วย/จำนวน หลายรายการในหนึ่งคำสั่งซื้อ จะต้องกรอกในบรรทัดใหม่ ราคาต่อหน่วยห้ามน้อยกว่า 0 และ จำนวนจะต้องมากกว่า 0 เมื่อกรอกหลายรายการ ช่องที่เหลือของใบสั่งซื้อ สามารถเว้นว่างไว้ได้",
    "orderNumberOnlySupportsLettersNumbers": "หมายเลขคำสั่งซื้อรองรับ ตัวอักษร ตัวเลข และเครื่อหมาย - /เท่านั้น",
    "fieldWithMarkAreRequired": "ช่องที่มีเครื่องหมาย (*) คือข้อมูลที่จำเป็นต้องกรอก",
    "pleaseEditInAccordanceWithThe": "โปรดแก้ไขตามเทมเพลต",
    "importantNotice": "หมายเหตุ",
    "theNumberOfDataRecordsCannot": "บันทึกข้อมูลได้ไม่เกิน 5000 รายการ",
    "pleaseImportWithDownloadedFormatTemplate": "กรุณาอัปโหลดไฟล์ โดยใช้เทมเพลตของ Ginee เท่านั้น และกรุณาอย่าปรับแต่งส่วนหัวกระดาษของเทมเพลต",
    "onlySupportFilesInXlsxFormat": "รองรับไฟล์สกุล xlsx เท่านั้น (กรุณาอย่าแก้ไขสกุลไฟล์ด้วยตนเอง)",
    "downloadImportTemplate": "ดาวน์โหลดเทมเพลตคำสั่งซื้อ",
    "importOrder": "นำเข้า",
    "uploadPaymentProof": "อัปโหลดหลักฐานการชำระเงิน",
    "paymentProof": "หลักฐานการชำระเงิน",
    "receivingAccount": "บัญชีที่รับเงิน",
    "paymentAccount": "บัญชีที่ชำระเงิน",
    "receiptNumber": "หมายเลขใบเสร็จรับเงิน",
    "theTotalPaymentAmountCannotExceed": "ยอดชำระรวมไม่สามารถมากกว่ายอดเงินรวมของคำสั่งซื้อได้",
    "addPaymentRecord": "เพิ่มบันทึกการชำระเงิน",
    "refer": "อ้างอิง",
    "useCommaToSeparateMultipleItem": "ใช้เครื่องหมาย comma( , ) ในการแยก",
    "saveContinueAdd": "บันทึก & เพิ่มรายการต่อไป",
    "save": "บันทึก",
    "paymentSerialNumber": "หมายเลขการชำระเงิน",
    "paymentAmount": "ยอดที่ชำระ",
    "productTotalAmount": "จำนวนสินค้ารวมทั้งหมด",
    "totalQuantity": "จำนวนรวม",
    "totalPrice": "ราคารวม",
    "addProduct": "เพิ่มสินค้า",
    "recipientArea": "ที่อยู่ผู้รับ",
    "remarks": "หมายเหตุ",
    "paymentInfo": "ข้อมูลการชำระเงิน",
    "amountInfo": "ข้อมูลยอดชำระ",
    "productInfo": "ข้อมูลสินค้า",
    "receiptInfo": "ข้อมูลใบเสร็จรับเงิน",
    "basicInfo": "ข้อมูลทั่วไป",
    "referenceOrder": "คำสั่งซื้ออ้างอิง",
    "canBeEnteredQuicklyByImporting": "กรอกข้อมูลได้สะดวกยิ่งขึ้น โดยการนำเข้าข้อมูลที่มีอยู่แล้ว",
    "addOrder": "เพิ่มคำสั่งซื้อ",
    "bindStoreNow": "เชื่อมต่อร้านค้า",
    "bindTips": "เนื่องจากคุณไม่ได้เชื่อมต่อกับร้านค้าช่องทางการขาย รายการคำสั่งซื้อปัจจุบันจึงว่างเปล่า โปรดเชื่อมต่อร้านค้าก่อนเพื่อดูหรือดำเนินการคำสั่งซื้อ",
    "PlatformDiscount": "ส่วนลดของช่องทางการขาย",
    "TotalDiscount": "ส่วนลดทั้งหมด",
    "voidTipss": "เมื่อคำสั่งซื้อถูกย้ายไปยังยังไม่จัดการแล้วจะไม่รองรับการดำเนินการคำสั่งซื้อใดๆ ภายในระบบอีกต่อไป",
    "voidTips": "เมื่อคำสั่งซื้อถูกย้ายไปที่ยังไม่จัดการแล้ว คำสั่งซื้อจะไม่เข้าร่วมในการล็อค/หักสต็อกอีกต่อไป คุณต้องกู้คืนคำสั่งซื้อก่อนจึงจะสามารถล็อค/หักสต็อกได้ตามปกติ โปรดดำเนินการด้วยความระมัดระวัง",
    "pickingList": "ใบหยิบสินค้า",
    "printedBy": "พิมพ์โดย",
    "supportTikiAndZaloraOrdersToComplete": "รองรับคำสั่งซื้อ Tiki และ Zalora รับเป็นกลุ่ม สำหรับคำสั่งซื้อ Blibli โปรดไปที่รายละเอียดคำสั่งซื้อเพื่อดำเนินการ",
    "onlyLazadaBlibiliAndZaloraSupportPackaging": "เฉพาะ Lzada Blibli และ Zalora เท่านั้นที่คำสั่งซื้อรองรับบรรจุ และสินค้าที่บรรจุแล้วไม่รองรับบรรจุสำรอง หากคุณต้องการแก้ไขจำนวนคำสั่งซื้อของ Blibli โปรดอัปเดตในรายละเอียดคำสั่งซื้อ",
    "confirmWhetherToSetTheOrderStatusAsShipped": "โปรดยืนยันว่าจะตั้งค่าสถานะคำสั่งซื้อเป็นจัดส่งหรือไม่",
    "markAsShipped": "ทำเครื่องหมายว่าจัดส่ง",
    "whetherToUseGineeTemplateToPrintInvoices": "จะใช้เทมเพลตของ Ginee ในการพิมพ์ใบกำกับสินค้าหรือไม่(คำสั่งซื้อ Lazada PH รองรับเฉพาะการพิมพ์ใบแจ้งหนี้โดยใช้เทมเพลตของ Ginee)",
    "buyerPayment": "จำนวนเงินที่ผู้ซื้อชำระ",
    "doAllProductInTheSameOrder": "สินค้าทั้งหมดในคำสั่งเดียวกันโดยใช้หมายเลขใบกำกับสินค้าเดียวกันหรือไหม่",
    "clickHereToSetting": "คลิกที่นี่เพื่อตั้งค่า",
    "operationLog": "บันทึกการทำงาน",
    "massSetPickupTime": "ตั้งค่าเวลารับเป็นกลุ่ม",
    "numberOfFails": "จำนวนความล้มเหลว",
    "totalNumber": "จำนวนทั้งหมด",
    "invoiceNumberSettingFailureDetails": "รายละเอียดความล้มเหลวในการตั้งค่าหมายเลขใบกำกับสินค้า",
    "invoiceNumberIsSetSuccessfully": "ตั้งค่าหมายเลขใบกำกับสินค้าสำเร็จ",
    "clickHereToSetWhetherAll": "คลิกที่นี่เพื่อตั้งค่าสินค้าทั้งหมดในคำสั่งซื้อเดียวกันใช้หมายเลขใบกำกับสินค้าเดียวกันหรือไม่",
    "setInvoiceNumber": "ตั้งค่าใบแจ้งหนี้",
    "printShippingLabelAndPickingLists": "พิมพ์ใบปะหน้าพัสดุและใบหยิบสินค้า",
    "pleaseConfirmWhetherTheReceiptIs": "โปรดยืนยันว่ารับเสร็จสมบูรณ์หรือไม่",
    "onlyTikiOrdersCanBeReceived": "เฉพาะคำสั่งซื้อ Tiki เท่านั้นที่สามารถรับเป็นกลุ่มได้ สำหรับคำสั่งซื้อ Blibli โปรดไปที่รายละเอียดคำสั่งซื้อเพื่อดำเนินการ",
    "malaysiaTemplates": "เทมเพลตมาเลเซีย",
    "generalTemplates": "เทมเพลตทั่วไป",
    "unassignedWarehouse": "คลังสินค้าที่ยังไม่ได้จัดสรร",
    "jikaPesananTelahDikaitkanDenganGudang": "หากคำสั่งซื้อเชื่อมต่อกับคลังฝากสต็อกสินค้าแล้ว โปรดยืนยันว่าใบคลังสินค้าขาออกได้ถูกยกเลิกโดยคลังสินค้าเรียบร้อยแล้วหรือไม่ เพื่อป้องกันข้อผิดพลาดของสต็อก",
    "inOrderToEnsureTheNormal": "เพื่อให้แน่ใจว่าการใช้งานระบบตามปกติ ฟังก์ชั่นดาวน์โหลดคำสั่งซื้อจะไม่สามารถใช้ได้ในช่วงระยะเวลาโปรโมชั่น 11.11~11.12 ขอขอบคุณที่เข้าใจ",
    "warehouse": "คลังสินค้า",
    "unpairedMasterSKU": "ไม่ได้จับคู่ SKU หลัก",
    "postalCode": "รหัสไปรษณีย์",
    "detailsAddress": "รายละเอียดที่อยู่",
    "street": "ถนน",
    "districts": "อำเภอ",
    "city": "เมือง",
    "province": "จังหวัด",
    "senderPhone": "โทรศัพท์ผู้ขาย",
    "senderName": "ชื่อผู้ขาย",
    "dataLoadingPleaseWaitOrClick": "กำลังโหลดข้อมูล กรุณารอสักครู่หรือคลิกปุ่มเพื่อข้ามไปโดยตรง",
    "activatedAllOrdersFromTheAuthorized": "เมื่อเปิดแล้ว คำสั่งซื้อจากร้านค้าที่เชื่อมต่อทั้งหมดจะจัดส่งหลังจากดำเนินการ \"แพ็คคำสั่งซื้อ\"",
    "restrictionShippingOfOrdersMustBe": "ข้อจำกัดในการจัดส่ง: คุณต้องแพ็คสินค้าตามคำสั่งซื้อก่อนจัดส่ง",
    "shippingDiscountSeller": "ส่วนลดการจัดส่งของผู้ขาย",
    "feeDiscountFromPlShipping": "ส่วนลดค่าจัดส่งของพาร์ทเนอร์การจัดส่ง",
    "taxEscrow": "ภาษีข้ามพรมแดน",
    "transactionFeeBuyer": "ค่าการทำธุรกรรมของผู้ซื้อ",
    "transactionFeeSeller": "ค่าการทำธุรกรรมของผู้ขาย",
    "SetPackStatusTitle": "ตั้งค่าสถานะการแพ็คของ",
    "SetPackStatusInMass": "ตั้งค่าสถานะการแพ็คของเป็นกลุ่ม",
    "timeUpdate": "เวลาที่อัปเดต",
    "timeCreation": "สร้างเมื่อ",
    "contentOperation": "เนื้อหาการดำเนินการ",
    "moduleOperation": "โมดูลการทำงาน",
    "recordOperation": "บันทึกการทำงาน",
    "orderRestore": "เรียกคืนคำสั่งซื้อ",
    "inToAlreadyShippedMove1": "ย้ายไปที่จัดส่งแล้ว",
    "warehouseThirdparty": "คลังฝากสต็อกสินค้า",
    "warehouseOwn1": "คลังสินค้าของตนเอง",
    "inToAlreadyShippedMove": "ย้ายไปที่จัดส่งแล้ว",
    "inToBeCollectedMove": "ย้ายไปที่รอที่จะรับ",
    "numberEmptyAwb": "หมายเลข AWB ว่างเปล่า",
    "printedNot": "ยังไม่ได้พิมพ์",
    "youWantToOperateSeparatelyPlease": "หากคุณต้องการดำเนินการแยกกัน โปรดย้ายไปยังรายการคำสั่งซื้อทั้งหมดเพื่อดำเนินการให้เสร็จสิ้น",
    "ordersWillBeDirectlyPackagedAnd": "คำสั่งซื้อ Lazada/Blibli จะถูกแพ็คและจัดส่งโดยตรง",
    "processingTheTokopediabukalapakOrderWillBe": "หลังจากประมวลผลแล้ว คำสั่งซื้อของ Tokopedia/Bukalapak จะได้รับการยอมรับและจัดส่ง",
    "youSureToProcessTheSelected": "คุณแน่ใจหรือไม่ว่าได้ประมวลผลคำสั่งซื้อ X ที่เลือกไว้ด้วยกันและย้ายไปยังคำสั่งซื้อที่รอดำเนินการ",
    "failedCancel": "ยกเลิกไม่สำเร็จ",
    "pushToThirdpartyWarehouseFailed": "ส่งไปยังคลังฝากสต็อกสินค้าไม่สำเร็จ",
    "toPendingMove": "ย้ายไปที่รอดำเนินการ",
    "asPriorityMark": "ทำเครื่องหมายเป็นลำดับความสำคัญ",
    "orderOutOfStockAll": "คำสั่งซื้อที่หมดสต็อกทั้งหมด",
    "orderOutOfStockSelected": "คำสั่งซื้อที่หมดสต็อกที่เลือกไว้",
    "stockCheck": "เช็คสต็อก",
    "skuXxxxIsInsufficientInThe": "SKU หลัก: {x} ไม่เพียงพอในคลังสินค้า {y} กรุณาประมวลผลใหม่หลังจากการเติมสต็อกในการดำเนินการจัดการสต็อก",
    "skuXxxxDoesNotExistIn": "SKU หลัก: XXXX ไม่มีอยู่ในคลังสินค้า XXXX กรุณาเลือกคลังสินค้าอีกครั้งโดยเปลี่ยน SKU หลักหรือเพิ่ม SKU หลักในคลังสินค้าแล้วดำเนินการอีกครั้ง",
    "warehouseToShipmentPush": "ผลักดันคลังสินค้าเพื่อจัดส่ง",
    "orderProcessing": "ดำเนินการคำสั่งซื้อ",
    "processingFailed": "ดำเนินการล้มเหลว",
    "ofStockOut": "หมดสต็อก",
    "warehouseOwn": "คลังสินค้าของตนเอง",
    "failedOrdersView": "ดูคำสั่งซื้อที่ล้มเหลว",
    "failed": "ล้มเหลว",
    "success": "สำเร็จ",
    "total": "ทั้งหมด",
    "descriptionXxOrdersHaveBeenSynchronized": "คำอธิบายความคืบหน้า: คำสั่งซื้อ XX ได้เชื่อมต่อแล้ว และคำสั่งซื้อ XX ที่เหลือกำลังรอเชื่อมต่อ",
    "pleaseWaitSyncing": "กำลังเชื่อมต่อ กรุณารอสักครู่",
    "result": "ผลลัพธ์",
    "reasonFailed": "เหตุผลที่ล้มเหลว",
    "exceptionSynchronization": "เชื่อมต่อผิดปกติ",
    "otherLogisticsProviderNumberEmptyPlaceholder": "ยังไม่ได้สร้างหมายเลขจัดส่ง กรุณาเชื่อมต่อในภายหลัง",
    "theCheckIsRemovedTheIntegrated": "หลังจากยกเลิกการเลือกแล้ว สต็อกของสินค้าหลักที่เชื่อมต่อจะไม่เพิ่มขึ้น",
    "selectedTheIntegratedMasterProductStock": "หลังจากเลือกแล้ว สต็อกของสินค้าหลักที่เชื่อมต่อจะเพิ่มขึ้น",
    "ifNoRuleIsAddedAfter": "ทิป: หากไม่มีการเพิ่มกฎ หลังจากที่คำสั่งซื้อเปลี่ยนเป็นสถานะยกเลิกแล้ว สต็อกของสินค้าหลักที่เชื่อมต่อทั้งหมดในคำสั่งซื้อจะเข้าคลังใหม่โดยอัตโนมัติ",
    "forTheProductStockOfThe": "สำหรับสต็อกสินค้าของสินค้าหลักที่จับคู่กันในคำสั่งซื้อเท่านั้น หากไม่เชื่อมต่อสินค้าหลักจะไม่ได้รับการประมวลผล",
    "theReasonForCancellationIs1": "หากเหตุผลในการยกเลิกคือ ##ให้อัปเดตสินค้าไม่เข้าคลังใหม่",
    "theReasonForCancellationIs": "หากเหตุผลในการยกเลิกคือ ## ให้อัปเดตสินค้าเข้าคลังใหม่",
    "operationContent": "เนื้อหาดำเนินการ",
    "delete": "ลบ",
    "updateTime": "เวลาอัปเดต",
    "createTime": "สร้างเมื่อ",
    "cancelReason": "เหุตผลที่ยกเลิก",
    "operator": "ผู้ดำเนินการ",
    "pleaseSelectStore": "กรุณาเลือกร้านค้า",
    "pleasesSelectChannel": "กรุณาเลือกช่องทาง",
    "no": "ไม่",
    "yes": "ใช่",
    "updateWarehouseStock": "สินค้าเข้าคลังใหม่",
    "unknownReason": "เหตุผลที่ไม่ทราบ",
    "buyerCanceled": "ลูกค้ายกเลิก",
    "systemCancel": "ระบบยกเลิก",
    "cancelSetting": "ยกเลิกการตั้งค่า",
    "kodePos": "รหัสไปรษณีย์",
    "nameFirst": "ชื่อ",
    "nameLast": "นามสกุล",
    "staff": "พนักงาน",
    "secretary": "เลขานุการ",
    "receptionist": "พนักงานต้อนรับ",
    "security": "ผู้รักษาความปลอดภัย",
    "driver": "คนขับรถ",
    "helperDomestic": "ผู้ช่วย",
    "relationship": "ความสัมพันธ์",
    "mrmrsms": "นาย/นาง/นางสาว",
    "spouse": "คู่สมรส",
    "related": "ครอบครัว",
    "lessThanCharactersRequired": "จำเป็นต้องกรอก ไม่เกิน 30 หลัก",
    "codeSettlement": "เลขที่การชำระบัญชี",
    "statusReceiver": "สถานะผู้รับสินค้า",
    "nameReceiver": "ชื่อผู้รับ",
    "productDateReceived": "วันที่ได้รับสินค้า",
    "pleaseCompleteTheFollowingInformationAnd": "กรุณากรอกข้อมูลต่อไปนี้และอัปเดตสถานะคำสั่งซื้อเป็น \"เสร็จสมบูรณ์\"",
    "completedOrder": "คำสั่งซื้อจัดส่งเสร็จสมบูรณ์",
    "cancel": "ยกเลิก",
    "noMoreThanCharactersRequired1": "จำเป็นต้องกรอก ไม่เกิน 300 หลัก",
    "ofThePerformanceReasonsPart": "ส่วนหนึ่งของเหตุผลด้านfulfillment",
    "quantityFinal": "จำนวนสุดท้าย",
    "ifTheCurrentOrderOnlySupports": "tip:หากคำสั่งซื้อปัจจุบันรองรับบริการfulfillmentเพียงบางส่วนเท่านั้น โปรดอัปเดตจำนวนของสินค้า, หากจำนวนของสินค้าทั้งหมดในคำสั่งซื้อปัจจุบันอัปเดตเป็น 0 คำสั่งซื้อจะถูกยกเลิก",
    "quantityChange": "แก้ไขจำนวน",
    "endTimeMustBeGreaterThan": "เวลาสิ้นสุดต้องมากกว่าเวลาเริ่มต้น",
    "noMoreThanCharactersRequired": "จำเป็นต้องกรอก ไม่เกิน 300 หลัก",
    "fieldsRequired": "ช่องที่จำเป็นต้องกรอก",
    "installationnote": "โน้ตการติดตั้ง",
    "installationmobile": "โทรศัพท์มือถือของผู้ติดตั้ง",
    "installationofficer": "ผู้ติดตั้ง",
    "endDateInstallation": "วันที่สิ้นสุดการติดตั้ง",
    "startDateInstallation": "วันที่เริ่มการติดตั้ง",
    "endDateShipping": "วันที่สิ้นสุดการจัดส่ง",
    "startDateShipping": "วันที่เริ่มจัดส่ง",
    "nameCourrier": "ชื่อบริษัทขนส่ง",
    "toPackSomeOrdersPleaseTry": "คำสั่งซื้อบางรายการแพ็คสินค้าล้มเหลว กรุณาลองอีกครั้งในภายหลัง",
    "successfullyPackaged": "แพ็คสำเร็จแล้ว",
    "pleaseWaitPacking": "กำลังแพ็คอยู่  กรุณารอสักครู่",
    "quantity": "จำนวน",
    "stockAvailablelocked": "สต็อกพร้อมส่ง/ ล็อคสต็อก",
    "skuwarehouseMaster": "SKUหลัก /คลังสินค้า",
    "nameProduct": "ชื่อสินค้า",
    "numberOrder": "หมายเลขคำสั่งซื้อ",
    "onlyLazadaAndBlibliOrdersSupport": "ทิป: เฉพาะคำสั่งซื้อของ Lazada และ Blibli เท่านั้นที่สนับสนุนการแพ็ค และคำสั่งซื้อที่แพ็คแล้วไม่สนับสนุนการแพ็คอีกครั้ง หากคุณต้องการแก้ไขจำนวนคำสั่งซื้อของ Blibli โปรดอัปเดตในรายละเอียดคำสั่งซื้อ",
    "theProductsOfTheSameOrder": "แพ็คสินค้าในคำสั่งซื้อเดียวกันใน Blibli (จำกัดเฉพาะสินค้าธรรมดา/สินค้าขนาดใหญ่)",
    "pack": "แพ็ค",
    "stockAvailable": "สต็อกพร้อมส่ง",
    "serviceProviderFulfillment": "บริษัทรองรับบริการFulfillment",
    "skuMaster": "SKU หลัก",
    "nameWarehouse": "ชื่อคลังสินค้า",
    "serviceProvider": "Service Provider",
    "theMasterSkuReplacing": "เปลี่ยน SKU หลัก",
    "withXxxxxxPaired": "จับคู่กับ XXXXXX",
    "reasonReturn": "เหตุผลในการคืนสินค้า",
    "itemsRestock": "จำนวนที่เพิ่มสต็อก",
    "amountRefund": "ยอดรวมเงินคืนทั้งหมด",
    "shippingReturn": "คืนค่าขนส่ง",
    "quantityReturn": "จำนวนสินค้าที่ส่งคืน",
    "totalRefundable": "ยอดรวมเงินคืนทั้งหมด",
    "sendNotificationsToCustomers": "ส่งการแจ้งเตือนไปยังลูกค้า",
    "totalRefund": "ยอดรวมเงินที่ได้รับคืน",
    "productRefund": "สินค้าที่ถูกส่งคืน",
    "ifYouChooseToIncreaseStock": "Tip: หากคุณเลือกเพิ่มสต็อก จำนวนสต็อกที่ถูกส่งคืนจะเพิ่มขึ้น",
    "refund": "คืนเงิน",
    "buyerInfoEdit": "แก้ไขข้อมูลลูกค้า",
    "location": "สถานที่ตั้ง",
    "other": "อื่นๆ",
    "declinedPayment": "การชำระเงินถูกปฏิเสธ",
    "orderFraudulent": "คำสั่งซื้อฉ้อโกง",
    "unavailableItems": "ไม่มีสินค้า",
    "changedcanceledOrderCustomer": "เปลี่ยน/ยกเลิกคำสั่งซื้อ",
    "CreditCardTransactionFee": "ค่าบริการบัตรเครดิต",
    "moreThan5": "มากกว่า 5 วัน",
    "fiveDays": "ภายใน5วัน",
    "threeDays": "ภายใน3วัน",
    "twoDays": "ภายใน2วัน",
    "oneDays": "ภายใน1วัน",
    "noNotes": "ไม่มีโน้ต",
    "hasNotes": "มีโน้ต",
    "noMessage": "ไม่มีข้อความ",
    "hasMessage": "มีข้อความ",
    "multipleProduct": "สินค้าหลายชิ้น",
    "oneProductWithMoreThanOneQty": "สินค้าประเภทเดียวกันหลายชิ้น",
    "singleProduct": "สินค้าประเภทเดียวกัน 1 ชิ้น",
    "jdTip": "การแจ้งเตือน: เนื่องจากการเชื่อมต่อระหว่างร้านค้าบนJD เกิดปัญหา. JD ID ร้านค้าที่เชื่อมตั้งแต่วันที่ 29 เมษายน 2021 คำสั่งซื้อที่ไม่สามารถเชื่อมต่อได้ ทีม JD.ID กำลังดำเนินการซ่อมแซมอยู่ในขณะนี้ ขออภัยในความไม่สะดวก",
    "orderIdSearchTips": "ใช้ \",\" เพื่อแยกหมายเลขคำสั่งซื้อ",
    "lastSync": "การเชื่อมต่อล่าสุด",
    "sortBy": "เรียงตาม",
    "asc3": "จากน้อยไปมาก",
    "desc2": "จากมากไปน้อย",
    "desc4": "จากมากไปน้อย",
    "asc2": "จากน้อยไปมาก",
    "desc": "จากมากไปน้อย",
    "asc": "จากน้อยไปมาก",
    "zToA": "(Z-A)",
    "aToZ": "(A-Z)",
    "payTime": "เวลาที่ชำระ",
    "deliverDeadline": "วันที่กำหนดส่ง",
    "productSku": "สินค้า SKU",
    "failnumber": "จำนวนล้มเหลวทั้งหมด",
    "sucessnumber": "จำนวนสำเร็จทั้งหมด",
    "totalnumber": "จำนวนทั้งหมด",
    "lazadatip": "อาจมีความล่าช้าในการเชื่อมต่อคำสั่งซื้อระหว่างแคมเปญ Lazada (26 มีนาคม - 31 มีนาคม) เนื่องจากนโยบายที่จำกัดการเชื่อมต่อของ Lazada  คุณไม่สามารถแก้ไขข้อมูลสินค้า(รวมถึงสต็อกและราคา) หรือเผยแพร่สินค้าใหม่ผ่าน Ginee เราต้องขออภัยในความไม่สะดวก",
    "keyword": "คีย์เวิร์ด",
    "logisticsProviderNumberEmptyPlaceholder": "Shopee ยังไม่ได้สร้างหมายเลขจัดส่ง กรุณาเชื่อมต่อในภายหลัง",
    "logisticsProviderNameEmptyPlaceholder": "Shopee ยังไม่ได้ตั้งค่าขนส่งให้คุณ กรุณาเชื่อมต่อในภายหลัง",
    "PrintPdfErrorMessage": "สร้างไฟล์ล้มเหลว กรุณาลองอีกครั้งในภายหลัง",
    "orderDetailNoteLimit": "ไม่เกิน500หลัก",
    "GoContinue": "ดำเนินการต่อ",
    "BrowserBlockedInfo": "ตรวจพบว่าหน้าต่างป๊อปอัปใหม่ถูกบล็อกโดยเบราว์เซอร์ กรุณาคลิก \"ดำเนินการต่อ\" เพื่อเปิดหน้าต่างหน้าใหม่ (คุณยังสามารถค้นหา \" วิธีการปลดบล็อกเบราว์เซอร์ \" ในศูนย์ช่วยเหลือ)",
    "Notification": "คำแจ้งเตือน",
    "ShowLabelProductInfo": "(Bukalapak)แสดงข้อมูลสินค้าบนใบปะหน้าพัสดุ",
    "VoucherPlatform": "คูปองแพลตฟอร์ม",
    "PriceCreditCardServiceFee": "ค่าบริการบัตรเครดิต",
    "PriceSellerReturnRefundAmount": "จำนวนเงินคืน",
    "PriceCoinCashBack": "เงินคืนจากผู้ขาย",
    "PriceCoin": "เหรียญ",
    "AllStatus": "สถานะทั้งหมด",
    "AllType": "ทุกประเภท",
    "theMarketplacePromotionPeriodPleaseUnderstand": "ในช่วงระยะเวลาโปรโมชั่นของช่องทาง อาจมีความล่าช้าในการเชื่อมต่อคำสั่งซื้อ ขออภัยที่เกิดความไม่สะดวก",
    "pendingTableTips": "ฟีเจอร์จัดการสั่งซื้อขณะนี้ใช้ได้เฉพาะกับ Shopee/Bukalapak/Tokopedia/Lazada/JD/Shopify",
    "Filter": "ฟิลเตอร์",
    "InvoiceSettings": "การตั้งค่าใบกำกับสินค้า",
    "MultipleProduct": "สินค้าหลายรายการ",
    "OneProductOneQty": "สินค้าเดียวหลายรายการ",
    "paidTime": "เวลาที่ชำระเงินสำเร็จ",
    "packageStatus": "สถานะพัสดุ",
    "shipBefore": "ส่งถึงก่อน",
    "sellerNote": "โน้ตของผู้ขาย",
    "buyerNote": "โน้ตของผู้ซื้อ",
    "packageType": "รูปแบบพัสดุ",
    "SingleProduct": "สินค้าชิ้นเดียว",
    "orderProductType": "ประเภทสินค้า",
    "ViewFailedList": "ดูคำสั่งซื้อที่ล้มเหลว",
    "AWBTrackingNumber2": "AWB/หมายเลขใบกำกับสินค้า",
    "PickUpTime": "เวลาเข้ารับพัสดุ",
    "NoEmptyText": "ยังไม่มีสข้อมูล",
    "OrdersFailedTips": "คำสั่งซื้อบางส่วนจัดส่งล้มเหลว กรุณาลองใหม่ในภายหลัง",
    "SuccessfullyShipped": "จัดส่งสำเร็จ",
    "TotalOrdersShipped": "จำนวนทั้งหมดของคำสั่งซื้อที่รอการจัดส่ง",
    "ShippingWait": "กำลังจัดส่ง กรุณารอสักครู่",
    "action": "ดำเนินการ",
    "SeeMoreRetract": "ย่อเนื้อหา",
    "FailedReason": "เหตุผลที่ล้มเหลว",
    "RecipientMobile": "หมายเลขโทรศัพท์ของผู้รับพัสดุ",
    "SetNow": "เริ่มตั้งค่าตอนนี้",
    "SkipNext": "ข้าม, ขั้นตอนต่อไป",
    "shippingTips3": "เนื่องจากข้อจำกัดของอินเทอร์เฟซ JD จึงรองรับเฉพาะการจัดส่งหลังจากพิมพ์ใบปะหน้าพัสดุเท่านั้น หากไม่พิมพ์ จะไม่รองรับการจัดส่ง",
    "shippingTips2": "หากคุณยังไม่ได้ตั้งค่ารูปแบบการจัดส่ง ระบบจะตั้งค่ารูปแบบจัดส่งเป็น\"เข้ารับพัสดุ\" เป็นรูปแบบตั้งต้น หรือ\"นำฝากส่งที่บริษัทขนส่งใกล้เคียง\" หรือ\"จัดส่งด้วยตัวเอง\"",
    "shippingTips1": "ข้อมูลการขนส่งบางส่วนยังไม่ได้ตั้งค่ารูปแบบการจัดส่ง กรุณาตั้งค่ารูปแบบการจัดส่งก่อนดำเนินการจัดส่งสินค้า",
    "FilterOrder": "ฟิลเตอร์คำสั่งซื้อ",
    "setAddress": "ยังไม่มีที่อยู่ กรุณาไปที่Seller center บนแพลตฟอร์มร้านค้าเพื่อตั้งค่าที่อยู่",
    "ArrangeShipment2": "ดำเนินการจัดส่ง",
    "MassUpload": "อัปโหลดเป็นกลุ่ม",
    "ShippingCarrier": "บริษัทขนส่ง",
    "OrderQuantity": "จำนวนคำสั่งซื้อ",
    "DownloadTemplate": "ดาวน์โหลดเทมเพลต",
    "FileSizeLimitTips": "รองรับไฟล์ xlsx/xls ที่น้อยกว่า 5M",
    "ImportFile": "นำเข้าไฟล์",
    "FileFormatInvalid": "รูปแบบไฟล์นำเข้าข้อมูลไม่ถูกต้อง",
    "ProcessFailed": "จัดการล้มเหลว",
    "ToBeDelivered": "รอการจัดส่ง",
    "ToBeAccepted": "รอการยืนยัน",
    "PickUpAddress": "ที่อยู่รับพัสดุ",
    "axiosTips": "ทิป",
    "moreMaxLength": "เยอะสุด{ maxLength }หลัก",
    "PleaseSelect": "กรุณาเลือก",
    "SyncError": "เชื่อมต่อล้มเหลว",
    "syncSuccess": "เชื่อมต่อสำเร็จ",
    "Synchronizing": "กำลังเชื่อมต่อ",
    "reset": "ตั้งค่าใหม่",
    "search": "ค้นหา",
    "status_failed": "ล้มเหลว",
    "status_returned": "ส่งคืนสินค้า",
    "status_cancelled": "ยกเลิก",
    "status_delivered": "ส่งถึง",
    "status_shipping": "กำลังจัดส่ง",
    "status_paid": "ชำระแล้ว",
    "status_pending_payment": "รอการชำระ",
    "CancellingProcess": "กำลังยกเลิก",
    "Delete": "ลบ",
    "Unpicked": "ยังไม่หยิบสินค้า",
    "Picked": "หยิบสินค้าแล้ว",
    "ShippingMethodLink": "ตั้งค่าทันที",
    "ExportDisabled": "สิ้นสุด",
    "ExportFailed": "ดาวน์โหลดล้มเหลว",
    "ExportFinished": "เปิดใช้งานอยู่",
    "ExportINProcess": "กำลังดำเนิน",
    "ExportStatus": "สถานะ",
    "AddTemplate": "เพิ่มเทมเพลตใหม่",
    "ExportFiledReason": "เหตุผลที่ล้มเหลว",
    "FileSaveHistoryDays": "ไฟล์จะแสดงในรายงานประวัติส่งออก และจะเก็บเพียง15วัน กรุณาคลิกดาวน์โหลดก่อนสิ้นสุดอายุ",
    "ExportFileFailed": "สร้างไฟล์ไม่สำเร็จ กรุณาลองใหม่ในภายหลัง",
    "ExportFileGenerated": "สร้างไฟล์สำเร็จแล้ว กรุณาคลิกดาวน์โหลด",
    "ExportFileInProcess": "กำลังสร้างไฟล์ กรุณารอสักครู่",
    "TokoOrderApiUnstable": "ได้รับผลกระทบจากการอัปเดตช่องทาง Tokopedia Ginee จะระงับบริการการเชื่อมต่อคำสั่งซื้อของช่องทางนี้เป็นเวลา 1 ชั่วโมงตั้งแต่เวลา 22:00 น. ตามเวลาอินโดนีเซียในวันที่ 3 พฤศจิกายน",
    "ExportResultTitle": "ผลลัพธ์",
    "OrderStatus": "สถานะคำสั่งซื้อ",
    "ExportDownload": "ดาวน์โหลด",
    "ExportAllOrders": "ส่งออกข้อมูลทั้งหมด",
    "ExportBySelected": "ส่งออกตามข้อเลือก",
    "Export": "ส่งออก",
    "OtherInformation": "ข้อมูลอื่น",
    "AmountInformation": "ข้อมูลบัญชี",
    "LogisticsInformation": "ข้อมูลการขนส่ง",
    "ProductInformation": "ข้อมูลสินค้า",
    "BuyerInformation": "ข้อมูลผู้ซื้อ",
    "ExportSelectedDate": "เลือกเวลา",
    "ExportSelectedTemplate": "เลือกเทมเพลต",
    "ExportOrder": "ส่งออกคำสั่งซื้อ",
    "ExportCreator": "ผู้สร้าง",
    "ExportContent": "เนื้อหา",
    "ExportTime": "เวลาที่ส่งออก",
    "ExportHistory": "ประวัติส่งออก",
    "TaxationFee": "ค่าภาษี",
    "CancelNoteText": "เหตุผลที่ยกเลิก",
    "FinalShippingFee": "ค่าจัดส่งชำระโดยระบบ",
    "PickingListStatus": "สถานะใบหยิบสินค้า",
    "PackingListStatus": "สถานะใบกำลับหีบห่อ",
    "InvoiceStatus": "สถานะใบกำกับสินค้า",
    "LabelStatus": "สถานะฉลาก",
    "AfterClickPrint": "หลังจากคลิกพิมพ์แล้ว สถานะคำสั่งซื้อในข้างล่างจะกลายเป็นสถานะพิมพ์แล้ว",
    "SetToPrintedMass": "ตั้งค่าสถานะเป็นพิมพ์แล้วเป็นกลุ่ม",
    "SetPrintStatusTips": "Tip：โปรดทราบหากสถานะเปลี่ยนเป็นพิมพ์เสร็จแล้วจะไม่สามารถแก้ไขสถานะพิมพ์",
    "SetPrintStatus": "ตั้งค่าสถานะพิมพ์",
    "SetStatus": "ตั้งค่าสถานะ",
    "PickStatus": "สถานะการรับของ",
    "PrintStatus": "สถานะการพิมพ์",
    "DiscountedTotalPrice": "ราคาส่วนลด",
    "InvoiceAlreadyPrinted": "พิมพ์ใบกำกับสินค้าพิมพ์แล้ว",
    "InvoiceNotPrinted": "ยังไม่ได้พิมพ์ใบกำกับสืนค้า",
    "PackingListAlreadyPrinted": "พิมพ์ใบกำกับหีบห่อพิมพ์แล้ว",
    "PackingListNotPrinted": "ยังไม่ได้พิมพ์ใบกำกับหีบห่อ",
    "PickingListAlreadyPrinted": "ใบหยิบสินค้าพิมพ์แล้ว",
    "PickingListNotPrinted": "ยังไม่ได้พิมพ์ใบหยิบสินค้า",
    "LabelAlreadyPrinted": "พิมพ์ใบปะหน้าพัสดุพิมพ์แล้ว",
    "LabelNotPrinted": "ยังไม่ได้พิมพ์ใบปะหน้าพัสดุ",
    "NotPrinted": "ยังไม่ได้พิมพ์",
    "AlreadyPrinted": "พิมพ์แล้ว",
    "Rebate": "ส่วนลด",
    "NewBuyerNote": "โน้ตของผู้ซื้อ",
    "OrderTotal": "ยอดรวมคำสั่งซื้อทั้งหมด",
    "BuyPhone": "หมายเลขโทรศัพท์",
    "ProductInfo": "ข้อมูลสินค้า",
    "SellerNote": "โน้ตผู้ขาย",
    "NoContentYet": "ยังไม่มีเนื้อหา",
    "OnlyVisibleToPicking": "มองเห็นได้ในใบรายการหยิบสินค้าเท่านั้น",
    "OnlyVisibleToSellers": "ผู้ขายเท่านั้นที่มองเห็นได้",
    "CurrentlyNotSupportedShipping": "ขณะนี้ระบบไม่รองรับการดูสถานะการจัดส่ง",
    "AWB": "AWB",
    "Voucher": "คูปอง",
    "ServiceFee": "ค่าบริการ",
    "CommissionFee": "ค่าคอมมิชชั่น",
    "UnstableLazadaAPI": "ขณะนี้การเชื่อมต่อกับ Lazada ไม่เสถึยร คำสั่งซื้อในบางร้านค้าไม่สามารถเชื่อมต่อได้ Lazada กำลังซ่อมอยู่ หากเจอปัญหานี้ กรุณาไปที่ Seller Center เพื่อจัดการคำสั่งซื้อ",
    "HideProductInfo": "ย่อข้อมูลสินค้า",
    "ShippingWeight": "นำ้หนัก",
    "ShippingSender": "ผู้ส่งพัสดุ",
    "ShippingRecipient": "ผู้รับพัสดุ",
    "BatchLabelInfo": "หลังจากคลิกพิมพ์แล้ว คำสั่งซื้อในรายการด้านล่างจะเปลี่ยนสถานะเป็นพิมพ์แล้ว",
    "PrintBatchLabel": "พิมพ์ใบปะหน้าพัสดุเป็นกลุ่ม",
    "ViewAllProduct": "ดูสินค้า { productCount } ทั้งหมด",
    "ShipBefore": "จะส่งถึงก่อน",
    "PackFailedInfo": "แพ็คสินค้าล้มเหลว กรุณารีเฟรชหรือลองอีกครั้งหลังจากเชื่อมต่อ",
    "PleaseSelectProduct": "กรุณาเลือกสินค้า",
    "SkipPrint": "ข้ามไป & พิมพ์",
    "SomeOrderInvoiceEmpty": "เลขใบกำกับสินค้าในบางคำสั่งซื้อว่างเปล่า ไม่สามารถพิมพ์ได้ กรุณากรอกให้สมบูรณ์",
    "SearchPlaceholder": "กรุณากรอกเนื้อหา",
    "Unpacked": "ยังไม่แพ็ค",
    "PackingStatus": "สถานะแพ็ค",
    "ReceiverName": "ชื่อผู้รับพัสดุ",
    "BuyerEmail": "อีเมลลูกค้า",
    "BuyerContact": "หมายเลขโทรศัพท์",
    "BuyerName": "ชื่อลูกค้า",
    "TrackIngNumber": "หมายเลขพัสดุ",
    "InvortySKU": "MSKU",
    "ParentSKU": "SKUหลัก",
    "ItemName": "ชื่อสินค้า",
    "productName": "ชื่อสินค้า",
    "OrdersID": "หมายเลขคำสั่งซื้อ",
    "Keywords": "คีย์เวิร์ด",
    "OrderTypeNormal": "คำสั่งปกติ",
    "OrderTypePreOrder": "Pre-order",
    "AllOrderType": "รูปแบบคำสั่งซื้อทั้งหมด",
    "PaymentPrePaid": "ชำระล่วงหน้า",
    "AllPayment": "รูปแบบทั้งหมด",
    "LdProductStatusInfo": "สถานะการจัดการสินค้าในคำสั่งซื้อไม่สอดคล้องกัน กรุณาตรวจสอบรายละเอียดคำสั่งซื้อ",
    "PackingFailedInfo": "การแพ็คสินค้าล้มเหลว กรุณากดรีเฟรชแล้วลองใหม่อีกครั้ง",
    "BillingAddress": "ที่อยู่เรียกเก็บเงิน",
    "InvoiceNumber": "หมายเลขใบกำกับสินค้า",
    "ProductsItemPack": "สินค้าที่แพ็คแล้วในคำสั่งซื้อไม่สนับสนุนแพ็คอีกครั้ง",
    "OnlyUnPackagedOrders": "สำหรับคำสั่งซื้อที่ยังไม่ได้แพ็ค",
    "AllProductsPacked": "รายการสินค้าในคำสั่งซื้อเดียวกันจะมีหมายเลขใบกำกับสินค้าเดียวกัน",
    "PackMethod": "รูปแบบการแพ็ค",
    "PackOrderTips": "Tip：คำสั่งซื้อของ Lazada ต้องได้รับการแพ็คก่อนจึงจะสามารถจัดส่งได้และ Ginee รองรับเฉพาะสินค้าที่แพ็คแล้วในคำสั่งซื้อเดียวกัน",
    "AlreadyPacked": "แพ็คแล้ว",
    "Pack": "แพ็ค",
    "PackOrder": "แพ็คสินค้า",
    "LWrongPricePricingError": "ราคา/กำหนดราคาไม่ถูกต้อง",
    "LDuplicateOrder": "คำสั่งซื้อซ้ำ",
    "LCustomerUnreachable": "ไม่สามารถติดต่อลูกค้าได้",
    "order_98c90235ea6e3d054f70d2ffdfa9e703": "สต็อกหมด",
    "LOutOfDeliveryArea": "พื้นที่นี้ไม่อยู่ในพื้นที่ที่สามารถจัดส่งได้",
    "LIncorrectOr": "ที่อยู่รับพัสดุไม่ถูกต้อง",
    "LSystemError": "ระบบเกิดความผิดพลาด",
    "LSourcingDelay": "มีความล่าช้าในการจัดซื้อ (ไม่สามารถติดตามเวลากำหนด)",
    "NoName": "ไม่มีชื่อ",
    "ProductSyncFailedAgain": "เชื่อมต่อสินค้าไม่สำเร็จ กรุณาเชื่อมต่อสินค้าบนร้านค้าอีกครั้ง",
    "ShopCancelOrderStockInfo": "สินค้าขายหมด — สต็อกของสินค้าที่ถูกเลือกจะกลายเป็น 0 ในร้านค้าที่เกี่ยวข้อง",
    "TokoCancelOrderStockInfo": "สินค้าขายหมด — สต็อกของสินค้าที่ถูกเลือกจะกลายเป็น 0 ในร้านค้าที่เกี่ยวข้อง",
    "BLCancelOrderStockInfo": "เมื่อยกเลิกคำสั่งซื้อสต็อกในคำสั่งซื้อนั้นจะเป็น 0 ในร้านค้าที่เกี่ยวข้อง",
    "AlreadyProcessed": "ดำเนินการสำเร็จ",
    "Grab": "Grab [9:00-16:00]",
    "GOSENDInstant": "GO-SEND Instant [6:00-18:00]",
    "GOSENDSameDay": "GO-SEND Same Day [6:00-15:00]",
    "AvailableTimeToPickup": "เวลาเข้ารับพัสดุ",
    "ProductUnitPrice": "ราคาต่อหน่วย",
    "ShopCloseTime": "เวลาปิดร้าน",
    "ToCancellationInfo": "ผู้ขายไม่สามารถปฏิเสธคำขอยกเลิกคำสั่งซื้อจากลูกค้าใน Tokopedia",
    "ToTrackingNoInfo": "8-17 หลัก",
    "trackingNoErrorMessage": "{min}-{max} หลัก",
    "BLTrackingNewNoInfo": "8-25 หลัก",
    "BLTrackingNoInfo": "จำเป็นต้องใส่จำนวน 8-25 หลักทั้งตัวอักษรและตัวเลข",
    "TBuyerRequest": "ความต้องการของลูกค้า",
    "TCourierProblem": "ปัญหาของขนส่ง",
    "TOthers": "เหตุผลอื่น",
    "shopClosed": "ปิดร้านค้า",
    "TWrongPriceOrWeight": "ราคาหรือนำ้หนักไม่ถูกต้อง",
    "TProductVariantUnavailable": "สต็อกตัวเลือกสินค้าหมด",
    "TProductOutOfStock": "สต็อกหมด",
    "TRejectShipping": "ปฏิเสธการจัดส่ง",
    "BLBusy": "ไม่สามารถจัดการคำสั่งซื้อ",
    "BLOutdatedPrice": "ราคายังไม่อัปเดต",
    "BLInappropriatePrice": "ราคาไม่ถูกต้อง",
    "BLVariantOutOfStock": "สต็อกตัวเลือกสินค้าหมด",
    "BlSuppliersOutOfStock": "ซัพพลายเออร์หมดสต็อก",
    "BLNoStock": "สต็อกหมด",
    "ReceiveInfo": "คุณสามารถรับคำสั่งซื้อของ Tokopedia,\nAkulaku, Bukalapak และ Tiki พร้อมกันได้ แต่เมื่อได้รับคำสั่งซื้อของ Bukalapak และ Tiki แล้วจะไม่สามารถยกเลิกได้",
    "SelectStockEmpty": "สต็อกสินค้าที่เลือกไว้จะถูกลบ",
    "DeleteKeepOneData": "โปรดใส่ข้อมูลไว้อย่างน้อย 1 อย่าง",
    "CourierTracking": "ติดตามการจัดส่ง",
    "NoReturnOrder": "ไม่มีคำขอยกเลิกที่ต้องดำเนินการ",
    "InReturn": "กำลังส่งคืน",
    "InCancel": "กำลังยกเลิก",
    "ReturnTips": "สนับสนุนคำสั่งซื้อคืนสินค้าได้ครั้งละหนึ่งรายการเท่านั้น เมื่อเลือกคำสั่งซื้อหลายรายการ ระบบจะจัดการคำสั่งซื้อแรกโดยค่าเริ่มต้น",
    "NoCancellationOrder": "ไม่มีคำขอยกเลิกที่ต้องดำเนินการ",
    "PrintDate": "วันที่พิมพ์",
    "BuyerNote": "หมายเหตุลูกค้า",
    "message": "ข้อความ",
    "seeMore": "ดู { more } รายการ",
    "HistoryOrderCompleted": "คำสั่งซื้อเสร็จสมบูรณ์",
    "HistoryOrderCancellation": "คำขอยกเลิกถูกปฏิเสธ",
    "HistoryOrderReturned": "สินค้าถูกส่งคืนแล้ว",
    "HistoryOrderShipping": "คำสั่งซื้อถูกจัดส่งแล้ว",
    "HistoryCancelOrder": "ยกเลิกคำสั่งซื้อแล้ว",
    "HistoryReject": "ผู้ขายปฏิเสธการยกเลิกคำสั่งซื้อ",
    "HistoryCreateOrder": "สร้างคำสั่งซื้อ",
    "Reject": "ปฏิเสธ",
    "Confirm": "ยืนยัน",
    "AcceptInfo": "คำสั่งซื้อนี้จะถูกยกเลิกทันทีและผู้ซื้อจะได้รับเงินคืน โปรดอย่าจัดส่งสินค้าออกตามคำสั่งซื้อนี้ หากคุณจัดส่งไปแล้ว กรุณาปฏิเสธคำขอยกเลิกนี้",
    "Images": "รูปภาพ",
    "AcceptReturn": "ยอมรับการคืนสินค้า",
    "Respond": "ตอบ",
    "Reason": "เหตุผล",
    "RequestID": "ยื่นขอ ID",
    "ReasonFromBuyer": "เหตุผลของผู้ซื้อ",
    "qtyToShip": "จำนวนที่ต้องจัดส่ง",
    "Qty": "จำนวนทั้งหมด",
    "buyer": "ชื่อลูกค้า",
    "orders": "คำสั่งซื้อ",
    "CODNotSupported": "ไม่รับรองCOD",
    "UndeliverableArea": "พื้นที่ที่ไม่สามารถจัดส่งได้",
    "CustomerRequest": "ความต้องการของลูกค้า",
    "OutOfStock": "สต็อกหมด",
    "History": "ประวัติการดำเนิน",
    "NoNext": "ไม่ใช่ ต่อไป",
    "YesNext": "ใช่ ต่อไป",
    "Print": "พิมพ์",
    "Deleted": "ลบ",
    "Retry": "ลองอีกครั้ง",
    "AcceptRejectInfo": "คุณต้องการยอมรับคำขอยกเลิกหรือไม่?",
    "receiveOrderTip": "คุณต้องการที่จะรับคำสั่งซื้อหรือไม่?",
    "selectCancelTips": "คุณต้องการยกเลิกคำสั่งซื้อทั้งหมดด้วยเหตุผลเดียวกันหรือไม่?",
    "AddPickingStatus": "เพิ่มสถานะใหม่",
    "SetPickStatusConfirm": "ยืนยัน",
    "PickApplyToAll": "ใช้กับทั้งหมด",
    "SetPickStatusTitle": "ตั้งค่าสถานะหยิบสินค้า",
    "SetPickStatusInMass": "ตั้งค่าสถานะหยิบสินค้าเป็นกลุ่ม",
    "PickingSetTip": "Tip: หากตั้งค่าสถานะคำสั่งซื้อเป็น \"หยิบสินค้าแล้ว\"คุณจะไม่สามารถเปลี่ยนสถานะได้อีก",
    "PrintPicking": "พิมพ์ใบหยิบสินค้า",
    "PrintPackingList": "พิมพ์ใบกำกับหีบห่อ",
    "PrintInvoice": "พิมพ์ใบแจ้งหนี้",
    "PrintLabel": "พิมพ์ฉลาก",
    "ProcessingCancellation": "การยกเลิกกระบวนการ",
    "ProcessingReturns": "ดำเนินการส่งคืนสินค้า",
    "ReceiveOrder": "ได้รับคำสั่งซื้อ",
    "orderSelected": "ถูกเเลือก",
    "shippingfeetip": "ค่าจัดส่งของ Bukalapak จะรวมอยู่ในยอดรวมหลังจากส่งสินค้าแล้ว",
    "variationdeleted": "ลบสินค้านี้แล้ว",
    "Sync": "เชื่อมต่อ",
    "tipsWait": "ฟังก์ชั่น“จัดส่งสินค้า”จะใช้ได้เร็วๆ นี้ กรุณาไปที่ seller center บนแฟลตฟอร์มร้านค้าเพื่อดำเนินการคำสั่งซื้อ",
    "variation": "ตัวเลือกสินค้า",
    "MSKU": "MSKU",
    "SKU": "SKU",
    "Total": "ทั้งหมด",
    "SellerDiscountTotal": "ส่วนลดผู้ขาย",
    "Insurance": "ประกันภัย",
    "Tax": "ค่าภาษี",
    "Shipping": "ค่าจัดส่ง",
    "subtotal": "ยอดรวมทั้งหมด",
    "readyToShip": "พร้อมจัดส่ง",
    "timeSlots": "ช่วงเวลา",
    "selectAddress": "เลือกที่อยู่",
    "address": "ที่อยู่",
    "selectCourier": "เลือกบริษัทการจัดส่ง",
    "logistics": "การจัดส่ง",
    "reasonForCancellation": "เหตุผลที่ยกเลิก",
    "qtyToCancel": "จำนวนที่ถูกยกเลิก",
    "qtyOrdered": "จำนวนที่สั่งซื้อ",
    "ArrangeShipment": "จัดส่งสินค้า",
    "updateOrder": "อัปเดต",
    "CancelOrder": "ยกเลิกคำสั่งซื้อ",
    "notes": "หมายเหตุ",
    "item": "สินค้า",
    "shippingAddress": "ที่อยู่จัดส่ง",
    "customerEmail": "อีเมล",
    "contactNo": "หมายเลขโทรศัพท์",
    "promiseToShipBefore": "ยืนยันว่าจะส่งถึงก่อน...",
    "AWBTrackingNumber": "หมายเลขพัสดุ",
    "shippingType": "รูปแบบการจัดส่ง",
    "paymentType": "รูปแบบการชำระ",
    "orderType": "รูปแบบคำสั่งซื้อ",
    "store": "ชื่อร้านค้า",
    "date": "วันที่",
    "customerDetails": "ข้อมูลลูกค้า",
    "orderInfo": "ข้อมูลคำสั่งซื้อ",
    "processingFlow": "กระบวนการจัดการ",
    "orderDetailId": "หมายเลขคำสั่งซื้อ { orderId }",
    "updateAt": "เวลาที่อัปเดต",
    "createAt": "สร้างเมื่อ",
    "orderStatus": "สถานะ",
    "customerName": "ชื่อลูกค้า",
    "orderId": "หมายเลขคำสั่งซื้อ",
    "amountMax": "ยอดรวมสูงสุด",
    "amountMin": "ยอดรวมต่ำสุด",
    "amount": "จำนวนเงิน",
    "createDate": "สร้างเมื่อ",
    "shippingCarrier": "บริษัทขนส่ง",
    "channelName": "ช่องทาง",
    "keyWord": "ค้นหาหมายเลขคำสั่งซื้อ SKU หรือชื่อลูกค้า",
    "refundableShippingFeeIsCalculatedIn": "ค่าจัดส่งที่เรียกคืนได้จะคำนวณในยอดรวมเงินที่เรียกคืนได้ โปรดกรองเพื่อดู",
    "youDontHaveOperationAuthorityFeature": "ในช่วงระยะเวลาที่มีผลบังคับใช้ของแพ็คเกจ จำนวนคำสั่งซื้อสะสมที่สร้างขึ้นมีถึง {x} คำสั่งซื้อแล้ว หลังจากเกิน {y} คำสั่งซื้อแล้ว ฟังก์ชั่นคำสั่งซื้อจะไม่สามารถใช้ได้ กรุณาสมัครสมาชิกแพ็คเกจล่วงหน้าหรือติดต่อฝ่ายบริการลูกค้าเพื่ออัปเกรดแพ็คเกจ",
    "understood": "ตกลง",
    "disableDefaultRules": "ปิดใช้งานกฎเริ่มต้น",
    "enableDefaultRules": "เปิดใช้งานกฎเริ่มต้น",
    "theDefaultRuleIsSuccessfullyDeactivated": "ปิดใช้งานกฎเริ่มต้นสำเร็จแล้ว",
    "theDefaultRuleIsSuccessfullyActivated": "เปิดใช้งานกฎเริ่มต้นสำเร็จแล้ว",
    "ifTheOrderDoesNotMeet": "หากคำสั่งซื้อไม่เป็นไปตามกฎที่สร้างขึ้น จะไม่สามารถสร้างใบคลังสินค้าขาออกเพื่อหักสต็อกได้",
    "afterDisableTheOrderWillOnly": "หลังจากปิดแล้ว คำสั่งซื้อจะถูกหมอบหมายไปยังคลังสินค้าตามกฎที่สร้างขึ้นเพื่อหมอบหมายคลังสินค้าต่าง ๆ เพื่อจัดส่ง และสร้างใบคลังสินค้าขาออกเพื่อหักสต็อก",
    "inOrderToEnsureThatThe": "เพื่อให้แน่ใจว่าสามารถหักสต็อกของสินค้าหลักได้ตามปกติ เมื่อไม่ได้กำหนดค่ากฎการหมอบหมายคลังสินค้า จะไม่รองรับการปิดกฎเริ่มต้น",
    "defaultRuleWhenNoRuleIs": "กฎเริ่มต้น: เมื่อไม่มีการสร้างกฎด้วยตนเอง คลังสินค้าจะได้รับการหมอบหมายตามคลังสินค้าของใบคลังสินค้าขาเข้า MSKU ล่าสุด",
    "noticeWhenTheRuleNotManually": "หมายเหตุ: เมื่อกฎที่สร้างด้วยตนเองว่างเปล่า จะไม่รองรับปิดกฎเริ่มต้น หลังจากสร้างกฎด้วยตนเอง คำสั่งซื้อจะไม่ได้รับการจัดการตามกฎเริ่มต้น หากมีกฎการมอบหมายคำสั่งซื้อหลายกฎในคลังสินค้าเดียวกัน จะได้รับการมอบหมายคำสั่งซื้อตามลำดับความสำคัญของกฎ",
    "country": "ประเทศ/ภูมิภาค",
    "IntegrateNow": "เชื่อมต่อเดี๋ยวนี้",
    "noconnected": "คุณยังไม่มีร้านค้าที่เชื่อมต่อ, โปรดเชื่อมต่อร้านค้า",
    "enableTips": "หลังจากเปิดใช้งาน กฎนี้จะส่งผลต่อการมอบหมายคำสั่งซื้อของคลังสินค้า กรุณาใช้ด้วยความระมัดระวัง",
    "youSureToEnableAre": "ยืนยันเปิดใช้งาน?",
    "setAtLeastOneConditionPlease": "กรุณาตั้งค่าเงื่อนไขการจัดสรรอย่างน้อยหนึ่งเงื่อนไขเพื่อ",
    "deletedSuccessfully": "ลบสำเร็จ",
    "youSureToDeleteAre": "ยืนยันว่าจะลบไหม",
    "mskuAdd": "เพิ่ม MSKU",
    "logisticAdd": "เพิ่มการจัดส่ง",
    "storeAdd": "เพิ่มร้านค้า",
    "disabledWarehouseWillNoLongerBe": "หลังจากปิดการใช้งานแล้ว จะไม่จัดสรรคลังสินค้าตามกฎนี้อีกต่อไป",
    "youSureYouWantToDisable": "คุณแน่ใจหรือไม่ว่าจะปิดใช้งานกฎนี้",
    "deleteMskuRule": "กฎ XXX ลบ MSKU: #XXX#",
    "addMskuRule": "กฎ XXX เพิ่ม MSKU: #XXX#",
    "orderAmountIsAdjustedFrom": "กฎ XXX จำนวนเงินของคำสั่งซื้อถูกปรับจาก xx~xx เป็น XX~XX",
    "deleteLogisticsRule": "กฎ XXX ลบ #XXX#การจัดส่ง",
    "addLogisticRule": "กฎ XXX เพิ่ม #XXX#การจัดส่ง",
    "ruleNameOfRules": "ชื่อกฎของกฎ XXX ได้รับการอัปเดตเป็น #XXX#",
    "deleteStoreRule": "กฎ XXX ลบ #XXX# ร้านค้า",
    "addStoreRule": "กฎ XXX เพิ่ม #XXX# ร้านค้า",
    "priorityOfRuleUpdated": "ลำดับความสำคัญของกฎ XXXX ได้รับการอัปเดตจาก X เป็น X",
    "historyOperation1": "บันทึกการทำงาน",
    "logisticSelectedX": "{x} การจัดส่งที่เลือกไว้",
    "storeSelectedX": "X Store ที่เลือกไว้",
    "warehouseAllocation": "จัดสรรคลังสินค้า",
    "add": "เพิ่ม",
    "amountOrder": "ยอดรวมเงินของคำสั่งซื้อ",
    "nameLogistic": "ชื่อการจัดส่ง",
    "nameStore": "ชื่อร้านค้า",
    "conditionSelect": "เงื่อนไขที่เลือกไว้",
    "masterSkuDesignated": "SKUหลักที่กำหนด",
    "orderAmountRangeSpecify": "ระบุช่วงยอดสั่งซื้อ",
    "logisticDesignated": "การจัดส่งที่กำหนด",
    "storeDesignated": "ร้านค้าที่กำหนด",
    "conditionOptional": "เงื่อนไขทางเลือก",
    "setAtLeastOneAllocationCondition": "กรุณาตั้งค่าเงื่อนไขการจัดสรรอย่างน้อยหนึ่งเงื่อนไขเพื่อให้แน่ใจว่าการตั้งค่ากฎเสร็จสมบูรณ์",
    "settingCondition": "การตั้งค่าเงื่อนไข",
    "informationBasic": "ข้อมูลพื้นฐาน",
    "ifTheOrderShippingWarehouseRule": "หมายเหตุ: เมื่อไม่ได้เพิ่มกฎคลังสินค้าการจัดส่งตามคำสั่งซื้อหรือ SKU หลักไม่มีอยู่ในคลังสินค้าที่กำหนดค่าไว้ในกฎ ใบคลังสินค้าขาออกจะไม่สามารถสร้างเพื่อหักสต็อกได้ โปรดตั้งค่ากฎ ถ้าคลังสินค้าเดียวกันมีกฎคลังสินค้าการจัดส่งหลายรายการ กำหนดตามลำดับความสำคัญของกฎ",
    "ruleAdd": "เพิ่มกฎ",
    "disable": "ปิดการใช้งาน",
    "enable": "เปิดใช้งาน",
    "edit": "แก้ไข",
    "operate": "ดำเนินการ",
    "time": "เวลา",
    "conditionAllocation": "เงื่อนไขการจัดสรร",
    "nameRule": "ชื่อกฎ",
    "priority": "ลำดับความสำคัญ",
    "upTheSearch": "การค้นหาแบบเดียว{X}",
    "moreMax": "เยอะสุด{ maxLength }",
    "separateMultipleInfoWithCommasOr": "แยกข้อความหลายข้อความด้วยเครื่องหมายจุลภาคหรือเว้นวรรค",
    "addSearchType": "เพิ่มประเภทการค้นหา",
    "advancedSearch": "การค้นหาขั้นสูง",
    "mskuIsNotEqualTo": "MSKU ไม่เท่ากับ",
    "skuIsNotEqualTo": "SKU ไม่เท่ากับ",
    "mskuIsEqualTo": "MSKU เท่ากับ",
    "skuIsEquals": "SKU เท่ากับ",
    "shopeebukalapakOrdersUseGineeTemplateFor": "คำสั่งซื้อ Shopee/Bukalapak ใช้เทมเพลต Ginee สำหรับการพิมพ์ใบปะหน้าพัสดุ (ปัจจุบัน Tokopedia รองรับเฉพาะเทมเพลต Ginee)",
    "selectPrinter": "เลือกเครื่องพิมพ์",
    "printCompleted1": "พิมพ์สำเร็จ",
    "printing2": "กำลังพิมพ์",
    "checkTheDetails": "ตรวจสอบรายละเอียด",
    "action1": "ดำเนินการ",
    "printQuantity": "จำนวนที่พิมพ์",
    "printStatus": "สถานะการพิมพ์",
    "printingAdmin": "ผู้พิมพ์",
    "highspeedBatchPrintingHistory": "ประวัติการพิมพ์แบบความเร็วสูง ทีละจำนวนมาก",
    "highspeedPrintingLabelHistory": "ประวัติการพิมพ์แบบความเร็วสูง",
    "printAgain": "พิมพ์อีกครั้ง",
    "failureReason": "สาเหตุที่ล้มเหลว",
    "shipmentNumber": "หมายเลขพัสดุ",
    "order": "คำสั่งซื้อ",
    "printFailed": "พิมพ์ล้มเหลว",
    "printSuccessfully": "พิมพ์สำเร็จ",
    "printing1": "กำลังพิมพ์",
    "cautionToEnsureNormalPrintingOperation": "ข้อควรระวัง: เพื่อให้แน่ใจว่าการพิมพ์ปกติ โปรดอย่าปิด/รีเฟรชหน้าปัจจุบัน มิฉะนั้น การพิมพ์จะถูกยกเลิกหรือพิมพ์หน้าเดียวกันซ้ำๆ!",
    "IfPrintingFailsPleaseMove": "3.การพิมพ์ล้มเหลว กรุณาไปที่รายการพิมพ์ไม่สำเร็จเพื่อตรวจสอบสาเหตุเพิ่มเติม",
    "DuringPrintingIfSomeOrders": "2.ระหว่างการพิมพ์ หากมีการพิมพ์คำสั่งซื้อบางรายการล้มเหลว จะไม่ส่งผลต่อความคืบหน้าในการพิมพ์โดยรวม",
    "AfterThePrintingIsCompleted": "1.หลังจากการพิมพ์เสร็จสิ้น ใบคำสั่งซื้อจะถูกทำเครื่องหมายโดยอัตโนมัติว่า 'พิมพ์แล้ว' หากคุณต้องการเปลี่ยนแปลง กรุณาไปที่รายการคำสั่งซื้อเพื่อแก้ไขด้วยตนเอง",
    "printingInstructions": "อธิบายเพิ่มเติมเกี่ยวกับการพิมพ์",
    "totalXxxAndXxPrinted": "ทั้งหมด { total }  รายการ，พิมพ์แล้ว { x }  รายการ",
    "printCompleted": "พิมพ์สำเร็จ",
    "printing": "กำลังพิมพ์",
    "printingProgress": "ขั้นตอนการพิมพ์:",
    "highSpeedPrintingTaskCenter": "ศูนย์งานพิมพ์แบบความเร็วสูง",
    "recheck": "ตรวจสอบอีกครั้ง",
    "ifYouConfirmThatThePlugin": "หากคุณแน่ใจว่าติดตั้งและเปิดการใช้งานปลั๊กอินเรียบร้อยแล้ว กรุณาคลิกปุ่ม 'ตรวจสอบอีกครั้ง' เพื่อดำเนินการพิมพ์",
    "notInstalledClickHereToDownload": "ยังไม่ได้ติดตั้งปลั๊กอิน คลิกเพื่อดาวน์โหลดและติดตั้งปลั๊กอิน",
    "installed": "ติดตั้งเรียบร้อย",
    "detectedWeFoundThatYouHave": "จากการตรวจสอบพบว่า คุณยังไม่ได้ติดตั้งหรือเปิดใช้งานปลั๊กอินการพิมพ์แบบความเร็วสูง โปรดลองวิธีต่อไปนี้:",
    "tips": "แจ้งเตือน",
    "inOrderToImproveThePrinting": "เพื่อปรับปรุงความเร็วและประสบการณ์การพิมพ์แนะนำให้ใช้การพิมพ์ที่รวดเร็ว,",
    "startPrinting": "เริ่มพิมพ์",
    "printer": "เครื่องพิมพ์",
    "noteToEnableHighspeedPrintingThe": "หมายเหตุ: กรุณาดาวน์โหลด 'ปลั๊กอินการพิมพ์แบบความเร็วสูง' พร้อมติดตั้งและเปิดใช้งานการพิมพ์แบบความเร็วสูง ก่อนใช้งานฟีเจอร์พิมพ์แบบความเร็วสูง",
    "highspeedPrinting": "พิมพ์แบบความเร็วสูง (พิมพ์ได้ไม่จำกัดออเดอร์ต่อครั้ง แต่ไม่รองรับการดู Preview ก่อนพิมพ์)",
    "regularPrintingPrintUpTo": "พิมพ์แบบปกติ (พิมพ์ได้สูงสุด 100 ออเดอร์/ครั้ง, สร้างไฟล์ PDF สำหรับใบปะหน้าพัสดุของคำสั่งซื้อทั้งหมด อาจใช้เวลามากขึ้น)",
    "printingMethod": "วิธีการพิมพ์",
    "haveBeenPrinted": "{ x }  จากทั้งหมดพิมพ์เรียบร้อยแล้ว",
    "dataHaveBeenSelectedAnd": "{ x }  ที่เลือก และ",
    "printData": "พิมพ์ข้อมูล",
    "customLabelOnlySupports": "กำหนดเทมเพลตใบปะหน้าพัสดุเอง (รองรับการพิมพ์คำสั่งซื้อจาก Shopee/Tokopedia/Bukalapak เท่านั้น)",
    "channelLabelAllChannelOrdersSupport": "ใบปะหน้าพัสดุตามช่องทางการขาย (รองรับการพิมพ์คำสั่งซื้อจากทุกช่องทางการขาย ยกเว้นคำสั่งซื้อจาก Shopify/Woocommerce)",
    "setUpPrintTemplates": "ตั้งค่าเทมเพลตการพิมพ์",
    "printShippingLabelAll": "พิมพ์ใบปะหน้าพัสดุ(ทั้งหมด)",
    "printShippingLabelSelected": "พิมพ์ใบปะหน้าพัสดุ (เลือก)",
    "selectAllOrders": "เลือกคำสั่งซื้อทั้งหมด",
    "pleaseConfirmWhetherToTurnOff": "โปรดยืนยันว่าจะปิดการประมวลผลสินค้าคงคลังหรือไม่",
    "putOnHold": "ไม่จัดการชั่วคราว",
    "pickingNote": "โน้ตการหยิบสินค้า",
    "dropshipper": "Dropshipper",
    "storeName": "ชื่อร้านค้า",
    "setReadyToShip": "ตั้งค่าพร้อมจัดส่ง",
    "becauseYouHaveNotIntegrateThe": "เนื่องจากคุณยังไม่ได้เชื่อมต่อกับร้านค้า คุณจึงไม่สามารถใช้ฟังก์ชันโคลนได้ โปรดทำการเชื่อมต่อก่อน",
    "integrateStore": "การเชื่อมต่อร้านค้า",
    "integrationGuide": "คู่มือการเชื่อมต่อ",
    "MinutesToLearnAboutGinee": "1 นาทีเพื่อเรียนรู้เกี่ยวกับ Ginee",
    "shortcuts": "ทางลัด",
    "theStoreHasNotBeenAuthorised": "ยังไม่ได้เชื่อมต่อร้านค้า ไม่สามารถจัดการสินค้าออนไลน์และคำสั่งซื้อได้",
    "inventoryManagement": "การจัดการสต็อก",
    "productManagement": "การจัดการสินค้า",
    "scrapeProduct": "คัดลอกสินค้า",
    "masterProductSales": "การขายสินค้าหลัก",
    "try": "ลองทันที",
    "productActivitySetting": "การตั้งค่าความเคลื่อนไหวสินค้า",
    "capitalOccupationRatio": "อัตราส่วนทุนการทำงาน (%)",
    "stockTakesUpFunds": "สต็อกที่ใช้เงินทุน",
    "numberOfMskus": "จำนวนของ MSKUs",
    "exportMsku": "ส่งออก MSKU",
    "purchaseDuration": "ระยะเวลาการซื้อ",
    "doesItNeedToBeReplenished": "จำเป็นต้องเติมหรือไม่?",
    "availableDaysDays": "วันที่ว่าง (วัน)",
    "averageDailySales": "ยอดขายเฉลี่ยต่อวัน",
    "lastMonth": "1 เดือนที่ผ่านมา",
    "lastTwoWeeks": "2 สัปดาห์ที่ผ่านมา",
    "lastWeeks": "7 วันที่ผ่านมา",
    "lastThreeDays": "3 วันที่ผ่านมา",
    "numberOfRatings": "จำนวนคะแนน",
    "ratings": "คะแนน",
    "likes": "ชอบ",
    "pageViews": "หน้าที่ดู",
    "productActivityHasBeenSetFor": "มีการตั้งค่าความเคลื่อนไหวของสินค้าสำหรับหมวดหมู่นี้ โปรดรีเซ็ต",
    "sales": "ฝ่ายขาย",
    "effectiveSales": "การขายที่มีประสิทธิภาพ",
    "newItem": "สินค้าใหม่",
    "lowSellingItem": "สินค้ายอดขายต่ำ",
    "normalItem": "สินค้าทั่วไป",
    "trendingItem": "สินค้าที่กำลังมาแรง",
    "bestSellingItem": "สินค้าขายดี",
    "productActivity": "ความเคลื่อนไหวของสินค้า",
    "accounting": "การจัดการบัญชี",
    "categoryRange": "ช่วงประเภท",
    "timeRange": "ช่วงเวลา",
    "productDimension": "ระดับสินค้า",
    "salesType": "ประเภทการขาย",
    "salesRange": "ช่วงการขาย",
    "allCategory": "หมวดหมู่ทั้งหมด",
    "recent": "ล่าสุด",
    "oneDay": "1 วัน",
    "threeDay": "3 วัน",
    "aWeek": "1 สัปดาห์",
    "twoWeeks": "2 สัปดาห์",
    "aMonth": "1 เดือน",
    "threeMonths": "3 เดือน",
    "halfYear": "ครึ่งปี",
    "onlySupportForIndonesia": "รองรับเฉพาะอินโดนีเซีย",
    "addProductActivity": "เพิ่มการเคลื่อนไหวสินค้า",
    "cSupportToManuallyPullAccurate": "C. รองรับการเปิดใช้งานการซิงค์การเปลี่ยนแปลงสต็อกของ Accurate กับ Ginee และอัปเดตสต็อกคลังสินค้า Ginee ที่ผูกไว้ (ปิดใช้งานตามค่าเริ่มต้น โปรดเปิดใช้งานด้วยความระมัดระวัง)",
    "bAfterTheOrderIsPlaced": "B. ผลิตภัณฑ์หลักของ Ginee ที่ออกจากคลังสินค้าจะลดสต็อกของ Accurate พร้อมกัน",
    "aInboundStockForMasterProduct": "a. สต็อกขาเข้าสำหรับสินค้าหลักใน Ginee จะซิงค์และเพิ่มสต็อกของ Accurate",
    "StockSync": "ซิงค์สต็อก",
    "noteOrderInformationSynchronizationIsA": "หมายเหตุ: การเชื่อมต่อใบแจ้งหนี้การขายเป็นการเชื่อมต่อทางเดียวที่ผลักโดยอัตโนมัติจาก Ginee เป็น Accurate ทุกวัน",
    "createdTheWarehousePairingRelationship": "หลังจากที่ร้านค้าได้รับการแมปด้วย Accurate เรียบร้อยแล้ว ใบแจ้งหนี้การขายจะถูกสร้างขึ้นโดยอัตโนมัติเมื่อมีการจัดส่งคำสั่งซื้อ Ginee ออกจากคลังสินค้าและผลักดันไปที่ Accurate",
    "OrderDistribution": "4. การกระจายคำสั่งซื้อ",
    "noteTheSynchronizationOfMasterProduct": "หมายเหตุ: การซิงค์ข้อมูลสินค้าหลักเป็นการซิงค์ทางเดียวจาก Ginee ไปจนถึง Accurate หลังจากสร้างสินค้าหลักใหม่แล้ว จะซิงค์ตรงเวลา",
    "afterCreatedTheWarehousePairingRelationship": "หลังจากสร้างความสัมพันธ์ในการจับคู่คลังสินค้าแล้ว จะสนับสนุนการซิงโครไนซ์สินค้าหลักของคลังสินค้า Ginee กับคลังสินค้า Accurate ที่สอดคล้องกัน",
    "ProductPlacement": "3. จำหน่ายสินค้า",
    "psItIsNotRecommendedTo": "PS: ไม่แนะนำให้ผูกคลังสินค้าบุคคลที่สามกับคลังสินค้า Accurate จะมีความเสี่ยงต่อความสับสนของสินค้าคงคลัง",
    "afterTheAuthorizationIsCompletedYou": "หลังจากการอนุญาตเสร็จสมบูรณ์ คุณสามารถจัดการจับคู่คลังสินค้าและร้านค้าใน [การตั้งค่า - การตั้งค่าทางการเงิน] และตั้งค่ากฎการเชื่อมต่อ เชื่อมต่อสินค้า/สต็อก/ใบแจ้งหนี้การขายสามารถทำได้ตามปกติหลังจากการจับคู่เท่านั้น",
    "BasicConfiguration": "2. โครงสร้างพื้นฐาน",
    "pleaseMakeSureToFillIn": "โปรดตรวจสอบให้แน่ใจว่าได้กรอกบัญชี/รหัสผ่าน Accurate ให้ถูกต้อง และเลือกฐานข้อมูลเพื่อดำเนินการให้อนุญาต",
    "Authorization": "1. การอนุญาต",
    "accurateOperationGuide": "คู่มือการใช้งาน Accurate",
    "averageDailySalesSettings": "การตั้งค่ายอดขายรายวันโดยเฉลี่ย",
    "averageSalesForTheDayAs": "ยอดขายเฉลี่ยต่อวันเป็นยอดขายรายวัน",
    "clickAuthorizeYouWillBeRedirected": "คลิก [อนุมัติ] คุณจะถูกเปลี่ยนไปยังหน้า Accurate โปรดอย่าลืมกรอกบัญชี/รหัสผ่านที่ถูกต้อง และทำการให้อนุญาตหลังจากเข้าสู่ระบบ",
    "onSaleDaysDays": "ในวันที่ขาย (วัน)",
    "purchaseTimedays": "เวลาซื้อ (วัน)",
    "AfterTheAuthorizationIsCompleted": "2. หลังจากการอนุญาตเสร็จสมบูรณ์ โปรดดำเนินการจับคู่คลังสินค้าในการจัดการการกำหนดค่า Accurate",
    "PleaseSelectAtLeast": "1. โปรดเลือกอย่างน้อย 1 ฐานข้อมูลเพื่อให้การอนุญาตสมบูรณ์",
    "remainder": "ส่วนที่เหลือ",
    "authorizationSucceeded": "อนุมัติสำเร็จ",
    "theAccurateWarehouseInformationIsBeing": "กำลังเชื่อมต่อคลังสินค้าและการบัญชีของ Accurate ให้คุณ โปรดดำเนินการต่อที่การตั้งค่าการจับคู่การเริ่มต้นทางการเงิน!",
    "continueAuthorization": "ดำเนินการอนุมัติต่อไป",
    "reauthorize": "ให้การอนุญาตใหม่",
    "channelFailureReason": "สาเหตุความล้มเหลวของช่องทางการขาย:",
    "authorizationFailed": "การอนุญาตล้มเหลว",
    "deleteAuthorization": "ลบการอนุญาต",
    "authorizer": "ผู้อนุญาต",
    "authorizationTime": "เวลาอนุญาต",
    "connected": "เชื่อมต่อ",
    "authorizationStatus": "สถานะการอนุญาต",
    "ServiceProvider": "ผู้ให้บริการ",
    "accountName": "ชื่อบัญชี",
    "deleteFailed": "การลบล้มเหลว",
    "theBindingRelationshipBetweenTheAccurate": "ความสัมพันธ์ที่มีผลผูกพันระหว่างคลังสินค้า Accurate และคลังสินค้า Ginee ได้รับการนำออกแล้ว และไม่มีการซิงโครไนซ์ข้อมูลสินค้า/คำสั่งซื้อ/สต็อกกับ ACCURATE อีกต่อไป",
    "onceDeletedTheBindingRelationshipBetween": "เมื่อลบแล้ว ความสัมพันธ์ที่มีผลผูกพันระหว่างคลังสินค้า Ginee และคลังสินค้า Accurate จะถูกปล่อย และข้อมูลสินค้า/คำสั่งซื้อ/สินค้าคงคลังจะไม่ถูกซิงโครไนซ์กับอีกต่อไปกับ ACCURATE",
    "areYouSureWantToDelete": "คุณแน่ใจหรือไม่ว่าต้องการลบ?",
    "productSuccessfullySynchronized": "ซิงโครไนซ์สินค้าสำเร็จ",
    "whenDistributeGineeWarehouseProductGinees": "เมื่อจัดจำหน่ายสินค้าคลังสินค้า Ginee สต็อกคลังสินค้า MSKU ของ Ginee จะถูกใช้โดยตรงเพื่อให้ครอบคลุมสต็อกคลังสินค้า Accurate โปรดดำเนินการด้วยความระมัดระวัง",
    "areYouSureToSyncGinee": "คุณแน่ใจว่าจะซิงค์สินค้าคลังสินค้า Ginee กับคลังสินค้า Accurate?",
    "channelReturnFailureReason": "สาเหตุความล้มเหลวในการกลับช่องทางการขาย:",
    "pullFailed": "การดึงล้มเหลว",
    "pullSuccessfully": "การดึงสำเร็จ",
    "pull": "ดึง",
    "afterManuallyPullingTheWarehouseInformation": "หลังจากดึงข้อมูลคลังสินค้าด้วยตนเองแล้ว โปรดตรวจสอบให้แน่ใจว่าได้ผูกคลังสินค้าที่เพิ่มใหม่แล้ว โปรดตรวจสอบให้แน่ใจว่าคลังสินค้าที่ปิดใช้งานจะถูกลบออกโดยตรง และความสัมพันธ์การผูกจะถูกล้างแบบซิงโครนัส การซิงโครไนซ์ข้อมูลจะไม่ถูกดำเนินการอีกต่อไป",
    "pullWarehouse": "ดึงคลังสินค้า",
    "warehouseStockSuccessfullyUpdated": "สต็อกคลังสินค้าได้รับการอัพเดทสำเร็จ",
    "onceTheStockOfTheThirdparty": "เมื่อสต็อกของคลังสินค้าบุคคลที่สามไม่สอดคล้องกับระบบ การดำเนินการนี้จะอัปเดตสต็อกคลังสินค้าของสินค้าที่มีอยู่โดยตรงเป็นมูลค่าสต็อกคลังสินค้าบุคคลที่สาม และสร้างบันทึกการปรับปรุง ข้อมูลเฉพาะสามารถดูได้ในบันทึกการดึง",
    "areYouSureToPullStock": "คุณแน่ใจหรือว่าดึงสต็อกจาก คลังสินค้า Accurate?",
    "theWarehouseBindingRelationshipHasBeen": "ความสัมพันธ์ที่มีผลผูกพันคลังสินค้าได้ถูกนำออกเรียบร้อยแล้ว",
    "afterTheUnbindingIsSuccessfulThe": "หลังจากยกเลิกการผูกสำเร็จแล้ว ข้อมูลสินค้า/คำสั่งซื้อที่ได้รับในคลังสินค้า Accurate จะไม่ได้รับผลกระทบ และข้อมูลที่สร้างขึ้นในคลังสินค้า Ginee ที่ถูกผูกไว้เดิมจะไม่ถูกซิงโครไนซ์กับ Accurate",
    "areYouSureToUnbindThe": "คุณแน่ใจหรือว่าจะยกเลิกการเชื่อมโยงคลังสินค้าปัจจุบัน?",
    "reasonTheCurrentWarehouseHasBeen": "เหตุผล: คลังสินค้าปัจจุบันถูกผูกไว้กับคลังสินค้า Accurate อื่น โปรดยกเลิกการเชื่อมต่อแล้วลองอีกครั้ง",
    "bindingFailed": "การผูกล้มเหลว",
    "bindingIsSuccessfulPleaseDoNot": "การผูกสำเร็จ โปรดอย่าดำเนินการซ้ำ",
    "bind": "ผูก",
    "tipOnlySupportsSelectingWarehouseThat": "เคล็ดลับ: รองรับเฉพาะการเลือกคลังสินค้าที่เปิดใช้งานในรายการคลังสินค้าของ Ginee",
    "syncWarehouse": "ซิงค์คลังสินค้า",
    "unbindWarehouse": "เลิกเชื่อมต่อคลังสินค้า",
    "bindTime": "เวลาเชื่อมต่อ",
    "accurateWarehouse": "คลังสินค้า Accurate",
    "accurateWarehouseInformationWillNotBe": "ข้อมูลคลังสินค้า Accurate จะไม่ถูกซิงโครไนซ์กับรายการคลังสินค้าของ Ginee และข้อมูลสินค้าหลัก/ข้อมูลคำสั่งซื้อ/ข้อมูลสต็อกจะถูกซิงโครไนซ์ตามความสัมพันธ์ที่ผูกมัดกับคลังสินค้าที่มีอยู่ของ Ginee",
    "accurateSettingManagement": "การจัดการการตั้งค่า\nAccurate",
    "synchronizeWarehouseInformationUnderAccurateDatebase": "ซิงโครไนซ์ข้อมูลคลังสินค้าภายใต้ฐานข้อมูล Accurate * และเพิ่ม * คลังสินค้า, ลบ * คลังสินค้า",
    "syncGineeWarehouseProductTo": "ซิงค์ Ginee * สินค้าคลังสินค้าไปยัง Accurate",
    "syncStockFromAccurateWarehouse": "ซิงค์สต็อกจาก คลังสินค้า Accurate * ไปยัง คลังสินค้า Ginee  *",
    "accurateWarehouseUnbindFrom": "คลังสินค้า Accurate * เลิกเชื่อมต่อจาก * คลังสินค้า",
    "accurateWarehouseBindTo": "คลังสินค้า Accurate * เชื่อมต่อกับ * คลังสินค้า",
    "operationLogIsOnlyRetainedFor": "บันทึกการทำงานจะถูกเก็บไว้เพียง 15 วันที่ผ่านมา และข้อมูลหลังจากผ่านไป 15 วันจะไม่สามารถดูได้",
    "send": "ส่ง",
    "translate": "แปลภาษา",
    "failedToSendMessage": "ไม่สามารถส่งข้อความได้",
    "resend": "ส่งอีกครั้ง",
    "sendToBuyer": "ส่งหาผู้ซื้อ",
    "translationFailed": "การแปลล้มเหลว",
    "translatedx": "แปลเรียบร้อย:{x}",
    "translationInProgress": "อยู่ระหว่างการแปล",
    "automaticTranslationHasBeenTurnedOn": "เปิดการแปลอัตโนมัติแล้ว",
    "autoTranslate": "แปลอัตโนมัติ",
    "buyersLanguage": "ภาษาของผู้ซื้อ",
    "afterCloseItThereWill": "หลังจากปิดแล้วจะไม่มีเสียงเตือนเมื่อมีข้อความใหม่จากผู้ซื้อ",
    "notificationSound": "เสียงแจ้งเตือน",
    "uploadedNowlengthSheets": "{nowLength} อัปโหลดแล้ว",
    "isAUniqueIdentifierForThe": "รหัสประจำตัวเฉพาะของผลิตภัณฑ์หลัก ไม่สามารถแก้ไขได้หลังจากการยืนยัน",
    "pleaseConfirmWhetherTheStoresChannel": "โปรดยืนยันว่า SKU ช่องทางของร้านค้ามีมูลค่าหรือไม่ ถ้าไม่ เราจะใช้ชื่อผลิตภัณฑ์ของช่องเป็น MSKU ของผลิตภัณฑ์หลัก",
    "errnameToko": "ชื่อสินค้าต้องมีอักษรระหว่าง 1-70ตัว",
    "sizeSettingTemplate": "การตั้งค่าขนาดเทมเพลต",
    "mappingCategory": "การจับคู่หมวดหมู่",
    "templateSettingLogistic": "ตั้งค่าเทมเพลตการจัดส่ง",
    "rangeEnable": "การตั้งค่าสินค้า",
    "globalsMobileErrorInfoLength": "หมายเลขโทรศัพท์ต้องอยู่ระหว่าง {min}-{max}  หลัก",
    "globalsMobileErrorInfo": "กรุณากรอกหมายเลขโทรศัพท์ที่ถูกต้อง",
    "masterSku": "SKU หลัก",
    "outboundPurchasePrice": "ราคาต้นทุน",
    "hasbinded": "ตัวเลือกสินค้าเชื่อมต่อกับสต็อกSKUแล้ว: {sku} โปรดอัปเดตสต็อกใน \"การจัดการสต็อก\"",
    "deletecustomersuccess": "ลบข้อมูลสำเร็จ!",
    "reasonforpushedfailed": "สาเหตุที่ล้มเหลว: สินค้าถูกลบหรือห้ามไม่ให้ขายในร้านค้าหรือเข้าร่วมแคมเปญ (หลังจากสิ้นสุดแคมเปญสามารถอัปเดตสต็อกได้สำเร็จ) โปรดตรวจสอบสินค้า",
    "purchasePricerequired": "ต้องอยู่ระหว่าง 0 ถึง 1,000,000,000!",
    "createsuccess": "สร้างสำเร็จ",
    "atmost20tags": "เพิ่มได้ถึง 20 tag",
    "AddTag": "เพิ่ม tag",
    "tagexist": "มี",
    "fenotempty": "ด้านหน้าและส่วนท้ายของ Tag ไม่สามารถเว้นวรรคได้",
    "taginputlimit": "Tag ต้องมีความยาวระหว่าง 3-40 ตัวอักษร",
    "selecttag": "เลือก tag",
    "withordernostock": "มีคำสั่งซื้อ,ไม่มีสต็อก",
    "withisku": "มีสต็อกSKU",
    "noisku": "ไม่มีสต็อกSKU",
    "withskuwithoutisku": "มี SKU แต่ไม่มีสต็อก SKU",
    "nosku": "ไม่มี SKU",
    "selectskusit": "เลือกเงื่อนไข SKU",
    "Pleaseenter": "กรอกข้อมูล",
    "selectsku": "เลือกสต็อกSKU",
    "emailreminder": "อีเมลแจ้งเตือน",
    "safetystockarea": "เมื่อมีสต็อกพร้อมส่ง<=",
    "bindedisku": "สต็อกSKUที่เชื่อมต่อ:",
    "most20bundles": "จำนวนสูงสุด 20 ชิ้น",
    "NAME": "ชื่อ",
    "PleaseEnter": "ใส่ข้อมูล",
    "disablebundletip": "เพื่อความแม่นยำของการตัดสต็อก SKU ที่เชื่อมต่อไว้ จำนวนสต็อกจะไม่สามารถแก้ไขได้เมื่อต้องการแก้ไขสต็อก SKU เป็นชุด",
    "updatesuccess": "อัปเดตสำเร็จ ！",
    "ISKUtype": "หมวดหมู่สต็อก SKU",
    "bundleisku": "สต็อก SKU เป็นชุด",
    "isku": "MSKU",
    "ProductNameISKU": "ชื่อสินค้าและสต็อก SKU",
    "Image": "รูปภาพ",
    "bundledproduct": "สินค้าเป็นชุด",
    "selectbundle": "เลือกสต็อก SKU",
    "AddBundleISKU": "เพิ่มสต็อก SKU เป็นชุด",
    "bukadisabled": "ระยะเวลาในการดำเนินการคำสั่งซื้อของร้านจะเป็นไปตามการตั้งค่าของร้าน",
    "pleasecheckpreorderpart": "โปรดตรวจสอบข้อมูลพรีออเดอร์",
    "shopeedisabled": "ฉันจะจัดส่งสินค้าภายใน2วันทำการ (ยกเว้นวันหยุดนักขัตฤกษ์และวันหยุดตามนโยบายบริษัทขนส่ง)",
    "tokodisabled": "เปิดใช้งานโหมดพรีออเดอร์สำหรับสินค้านี้หากใช้เวลาในการดำเนินการนานกว่านี้",
    "UNKNOWNunittip": "หน่วยเวลาของแพลฟอร์มไม่มีอยู่ในระบบ Ginee โปรดติดต่อ Ginee เพื่อแก้ไข",
    "day": "วัน",
    "week": "สัปดาห์",
    "bukreorderdayworkday": "วันทำการในการจัดส่ง",
    "bukreorderday": "วันที่ส่ง",
    "bukainstanhour": "เวลาทำการ",
    "bukaregular": "ฉันจะจัดส่งออกภายใน3วันทำการ (ยกเว้นวันหยุดนักขัตฤกษ์และวันหยุดตามนโยบายบริษัทขนส่ง)",
    "bukreorder": "ฉันต้องการ",
    "bukainstan": "ฉันจะส่งสินค้าภายใน",
    "tokopreorderday": "วันที่จัดส่ง",
    "tokopreorderweek": "อาทิตย์ที่จัดส่ง",
    "tokopreorder": "ฉันต้องการ",
    "shopeepreorderday": "วันทำการในการจัดส่ง",
    "shopeepreorder": "ฉันต้องการ",
    "max8pictures": "อัพโหลดได้สูงสุด 8 ภาพ",
    "max5pictures": "อัพโหลดได้สูงสุด 8 ภาพ",
    "uploadtip": "การอัปโหลดรูปภาพสินค้าตามเงื่อนไขของแพลตฟอร์มจะเป็น: สำหรับ Tokopedia, Bukalapak, Shopify, TikTok Shop, Blibli จะถ่าย 5 ภาพแรก, Lazada  8 รูปแรก และ Shopee 9 รูปแรก",
    "regular": "ปรกติ",
    "instan": "ทันที",
    "between18hours": "ต้องอยู่ระหว่าง 1-8",
    "between330days": "ระหว่าง 3-30",
    "max13weeks": "ระหว่าง 1-13 สัปดาห์",
    "shopeepreorderdayAdd": "Star, Star+, Mall รองรับสูงสุด 90 วันทำการ",
    "between790": "ระหว่าง 7-90 วันทำการ",
    "max90days": "ระหว่าง 1-90 วัน",
    "preorderdeadline": "กำหนดวันดำเนินคำสั่งซื้อ",
    "preorder": "พรีออเดอร์",
    "novariationforlazd": "เพิ่มรูปภาพไม่ได้เนื่องจากสินค้านี้ไม่มีรายละเอียดตัวเลือกสินค้า",
    "SafetystockHead": "กำหนดจำนวนสต็อกและรับอีเมลแจ้งการเติมสต็อกเมื่อสต็อกที่พร้อมส่งใกล้หมดตามที่คุณตั้งค่าไว้",
    "SpareStockkHead": "จำนวนสต็อกทั้งหมดที่สำรองไว้ในคลังจะไม่วางขายบนร้านค้า",
    "WarehouseStockHead": "สต็อคตามจริงทั้งหมดในคลังของคุณ,สต็อกคงคลัง = สต็อกสำรอง + สต็อกที่ถูกล็อค + สต็อกพร้อมส่ง + สต็อกโปรโมชั่น",
    "inventorySkuHead": "50 อักขระแรกของ SKU ร้านค้าจะตรงกันเป็นค่าตั้งต้น",
    "NameHead": "ชื่อของสต็อก SKU จะมาจากอักขระ 160 ตัวแรกของ [ชื่อสินค้า-ชื่อตัวเลือกสินค้า] โดยอัตโนมัติ",
    "BrandLogoTips": "คำแนะนำในการอัปโหลด: รูปภาพ 360*140, jpg/png/jpeg ที่เล็กกว่า 2M",
    "MobileMustErrorInfo": "หมายเลขโทรศัพท์ต้องเป็นตัวเลข 9-12 หลัก ขึ้นต้นด้วย 8",
    "bindnow": "เชื่อมต่อ",
    "Nospacesfront": "ไม่สามารถเว้นว่างในส่วนแรก โปรดตรวจสอบ",
    "spaceforbidden": "ไม่สามารถเว้นว่าง โปรดตรวจสอบ",
    "range": "อยู่ระหว่าง: ขายอยู่ : ปิดใช้งาน :  ขายหมดแล้ว :ละเมิด",
    "inventorySkunotempty": "สต็อก SKU ไม่สามารถเว้นว่าง",
    "limitsafety": "สต็อกปลอดภัยควรมีจำนวน 0 - 999,999!",
    "mustlessthanwarehouse": "สต็อกสำรองไม่สามารถมีมากกว่าสต็อกคงคลัง",
    "sparelimit": "สต็อกสำรองควรมีจำนวน 0 - 999,999!",
    "mustbiggerthanspare": "สต็อกสำรองไม่สามารถมีมากกว่าสต็อกคงคลัง",
    "warehouselimit": "สต้อกคงคลังควรมีจำนวน 0 - 999,999!",
    "masterSkuMost200": "SKU หลักควรความยาวน้อยกว่า 200 ตัวอักษร",
    "masterSkuMost50": "SKU หลักควรความยาวน้อยกว่า 50 ตัวอักษร",
    "most50": "สต็อกSKU ควรมีความยาวน้อยกว่า 50 ตัวอักษร",
    "most160": "ชื่อควรน้อยกว่า 300 ตัวอักษร",
    "clicktoedit": "คลิกเพื่อแก้ไข",
    "alreadyexist": "หากมีสต็อก SKU อยู่แล้ว SKUของตัวเลือกสินค้าจะถูกเชื่อมต่อไว้กับสต็อก SKU",
    "generaterules": "เงื่อนไขการสร้างสต็อก SKU โดยค่าเริ่มต้น: 1. สต็อก SKU จะใช้อักขระ 50 ตัวแรกของ SKU เป็นค่าเริ่มต้น. 2. สต็อกคงคลังจะเท่ากับสต็อกของตัวเลือกสินค้า(Variant) เป็นค่าตั้งต้นและสต็อกปลอดภัยจะเป็น 0 เป็นค่าตั้งต้น. 3. เมื่อสร้างสต็อก SKU, SKU ของรูปแบบหรือตัวเลือกสินค้าจะถูกเชื่อมต่อไว้.",
    "channel": "ช่องทาง",
    "Operationguide": "คู่มือการใช้งาน",
    "storeexplain": "สร้างสต็อก SKU สำหรับสินค้าในร้านค้าเดียวเพื่อจัดการสต็อกในหลายๆร้านค้าพร้อมกัน",
    "bystoremodal": "สร้างคลังสินค้า SKU โดยร้านค้า",
    "ImportByStore": "นำเข้าโดยร้านค้า",
    "Gotit": "เข้าใจแล้ว!",
    "Safetystock": "สต็อกปลอดภัย",
    "SpareStock": "สต็อกสำรอง",
    "WarehouseStock": "สต็อกคงคลัง",
    "inventorySku": "MSKU",
    "Name": "ชื่อ",
    "image": "รูปภาพ",
    "GenerationRules": "เงื่อนไขการสร้าง",
    "ClickContenttoEdit": "คลิกเพื่อแก้ไขเนื้อหา",
    "generatecount": "สินค้า{productSum} ( {availableNum} จาก {variationSum} ตัวเลือกสินค้าสามารถสร้างสินค้าหลักได้)ตัวเลือกสินค้าที่ผูกกับ SKU หลักหรือไม่มี SKU จะสร้าง SKU หลักไม่ได้",
    "generateisku": "สร้างสต็อก SKU",
    "draftorfialed": "โปรดเลือกเฉพาะฉบับร่างหรือสินค้าที่เผยแพร่ล้มเหลว",
    "uploadlimit": "ไฟล์ที่ใหญ่ที่สุด: xlsx, xls มีขนาดเพียง 5MB นำเข้าข้อมูลได้มากถึง 1,000 รายการในแต่ละครั้ง",
    "canbedownloaded": "สินค้า",
    "selectedstatus": "สินค้าที่เลือกในสถานะตอนนี้:",
    "donwloadtemp": "ดาวน์โหลดเทมเพลตนำเข้า",
    "DownloadFailedData": "ดาวน์โหลดข้อมูลที่ล้มเหลว",
    "importfail": "ข้อมูลบางส่วนนำเข้าล้มเหลว",
    "importfinish": "นำเข้าข้อมูลสำเร็จ",
    "Cancel": "ยกเลิก",
    "fileerror": "รูปแบบไฟล์นำเข้าไม่ถูกต้อง",
    "close": "ปิด",
    "importing": "กำลังนำเข้า",
    "importlabel": "นำเข้าไฟล์",
    "upload": "อัพโหลด",
    "download": "ดาวน์โหลด",
    "templatetip": "ดาวน์โหลดเทมเพลต",
    "import": "นำเข้า",
    "themaxtip2": "บันทึกข้อมูลต่อการอัพโหลด1 ครั้ง ลูกค้าที่มีเบอร์ติดต่อดียวกันจะถูกรวมเข้าด้วยกัน",
    "themaxtip1": "Tip: จำนวนสูงสุดคือ",
    "ActionsSuccessInfo": "ดำเนินการสำเร็จ!",
    "ShopeeTips": "คลิก \"เริ่มเชื่อมต่อ\" และคุณจะถูกนำไปยัง Shopee ซึ่งคุณสามารถเข้าสู่ระบบและขอการเข้าถึงอนุญาตร้านค้า",
    "AlreadyChecked": "{selected} เลือก",
    "SubscriptionExtended": "สามารถขยายเวลาการสมัครสมาชิกได้ โปรดคลิก \"สมัครสมาชิก\" หรือติดต่อเราเพื่ออัปเกรดบัญชีของคุณ upgrade",
    "SubscribeSuccess": "หากคุณสมัครสำเร็จ คุณสามารถเปิดใช้งานฟีเจอร์ขั้นสูงได้ กรุณาคลิก \"สมัครสมาชิก\" หรือติดต่อเราเพื่ออัปเกรดบัญชีของคุณ",
    "SubscriptionExpired": "ขออภัย การสมัครของคุณหมดอายุแล้ว โปรดคลิก \"สมัครสมาชิก\" หรือติดต่อเราเพื่ออัปเกรดบัญชีของคุณ",
    "PayNow": "ชำระตอนนี้",
    "ThisFeatureIsOnlyPaidUsers": "ฟีเจอร์นี้นี้มีไว้สำหรับผู้ใช้ที่ชำระเงินเท่านั้น โปรดชำระเงินเพื่อใช้ฟีเจอร์นี้",
    "FoundANewGreatFeature": "พบฟีเจอร์ใหม่ที่ยอดเยี่ยม!",
    "urlplaceholder": "ใส่ชื่อร้านค้า",
    "urltip": "ชื่อต้องมีความยาวระหว่าง 2-30 อักษร อนุญาตให้ใช้เฉพาะตัวอักษรพิมพ์เล็กและตัวเลขเท่านั้น",
    "urldesciption": "โปรดสร้างลิงค์สมาชิกของร้านค้า",
    "memberurlmodaltitle": "สร้างลิงค์เพจสมาชิก",
    "CategoryLevel3": "หมวดหมู่ระดับ 3",
    "CategoryLevel2": "หมวดหมู่ระดับ 2",
    "RichTextDescription": "พิมพ์คำอธิบายของคุณที่นี่และนำไปใช้กับสินค้าของคุณ",
    "PleaseInput": "ใส่ข้อมูล",
    "selectall": "เลือกทั้งหมด",
    "noauth": "บัญชีของคุณไม่ได้รับอนุญาต โปรดติดต่อผู้ดูแลระบบร้านค้าเพื่อขอหรืออัปเกรด",
    "NoProductYet": "ยังไม่มีสินค้า",
    "SeeMore": "ดู{ more } รายการเพิ่มเติม",
    "SeeMoreExpand": "ดูเพิ่มเติม",
    "SaveAndNext": "บันทึก & ถัดไป",
    "NoHaveAccount": "ยังไม่มีบัญชี?",
    "Requiredfields": "ต้องกรอกข้อมูลในช่องนี้!",
    "Somerequiredfields": "ช่องที่ต้องกรอกบางช่องยังว่าง โปรดใส่ข้อมูล",
    "Removetheseproducts": "ลบสินค้าเหล่านี้",
    "GotoShippingSettings": "ไปยังตั้งค่าขนส่ง",
    "Somewithoutshipping": "ไม่สามารถบันทึกสินค้าบางรายการได้หากไม่มีตัวเลือกการจัดส่ง โปรดเปิดตัวเลือกการจัดส่งก่อนแก้ไขสินค้า",
    "Discardcontent": "ยกเลิกหมายถึงข้อมูลของสินค้าจะยังเหมือนกับข้อมูลเดิมบนร้านค้า",
    "Discardtip": "คุณแน่ใจไหมว่าต้องการยกเลิกการเปลี่ยนแปลงนี้?",
    "with": "แทนที่ด้วย",
    "Changepriceto": "เปลี่ยนราคาเป็น",
    "Reducebypercent": "ลดราคาตามเปอร์เซ็นต์",
    "Reducebyamount": "ลดราคาตามจำนวน",
    "Addbypercent": "เพิ่มราคาตามเปอร์เซ็นต์",
    "Addbyamount": "เพิ่มราคาตามจำนวน",
    "Basedoncurrentprice": "ตามราคาปัจจุบัน",
    "replace": "แทนที่",
    "addaftername": "เพิ่มหลังชื่อ",
    "addbeforename": "เพิ่มหน้าชื่อ",
    "Changestockto": "เปลี่ยนสต็อกเป็น",
    "Example": "ตัวอย่าง :",
    "errsize": "ต้องกรอกข้อมูลในช่องนี้!",
    "BukalapakPrice": "ราคาต้องอยู่ระหว่าง 1 ～1.000.000.000",
    "phLazadaPrice": "ราคาต้องอยู่ระหว่าง 1 ～1.000.000.000",
    "LazadaPrice": "ราคาต้องอยู่ระหว่าง 1000 ～1.000.000.000",
    "TokopediaPrice": "ราคาต้องอยู่ระหว่าง 100 ～1.000.000.000",
    "phShopeePrice": "ราคาต้องอยู่ระหว่าง 2 ～1.000.000.000",
    "ShopeePrice": "ราคาต้องอยู่ระหว่าง 99 ～1.000.000.000",
    "pricetipBukalapak": "ราคาต้องอยู่ระหว่าง 99 ～1.000.000.000",
    "pricetipShopee": "ราคาต้องอยู่ระหว่าง 99 ～1.000.000.000",
    "errdescriptionBukalapak": "คำอธิบายต้องมีความยาวระหว่าง 30 - 3000 ตัวอักษร",
    "errdescriptionShopee": "คำอธิบายต้องมีความยาวระหว่าง 30 - 3000 ตัวอักษร",
    "errnameBukalapak": "ชื่อสินค้าต้องมีความยาวระหว่าง 6 - 150 ตัวอักษร",
    "errnamelazada": "ชื่อสินค้าต้องมีความยาวระหว่าง 2 - 255 ตัวอักษร",
    "allemptyorfilled": "ความยาว ความกว้าง และความสูงควรเว้นว่างทั้งหมดหรือใส่ข้อมูลทั้งหมด",
    "errnameShopee": "ชื่อสินค้าต้องมีอักษรระหว่าง 5-100ตัว",
    "spaceskuerr": "จำเป็นต้องกรอก SKU",
    "PleaseOneShipping": "กรุณาเปิดใช้งานวิธีการจัดส่งอย่างน้อย 1 ประเภท",
    "pricetip": "ราคาต้องอยู่ระหว่าง 99-1.000.000.000",
    "stocktip": "สต็อกต้องอยู่ระหว่าง 0-999,999",
    "Discardchanges": "ยกเลิกการเปลี่ยนแปลง",
    "update": "อัปเดต",
    "draft": "บันทึกเแบบร่าง",
    "publish": "เพิ่ม",
    "Logistic": "การขนส่ง",
    "ParcelSize": "ขนาดพัสดุ",
    "Width": "ความยาว",
    "Length": "ความยาว",
    "Height": "ส่วนสูง",
    "Weight": "น้ำหนัก",
    "price": "ราคา",
    "VariantName": "ชื่อรูปแบบสินค้า",
    "parentSku": "SKU หลัก",
    "productImg": "รูปภาพ",
    "Refresh": "รีเฟรช",
    "PlatformUpdateNotification": "คำแนะนำใหม่ของGinee",
    "WaitAMoment": "ภายหลัง",
    "GoAuth": "เชื่อมต่อ",
    "NewVersionsInfo": "ระบบจัดการ Shopee  2.0 ใช้ได้แล้ว! สัมผัสประสบการณ์การเชื่อมต่อข้อมูลและดำเนินการสั่งซื้อที่เสถียรยิ่งขึ้น!",
    "NoDataInfo": "ไม่มีข้อมูลที่สามารถดำเนินการได้",
    "ActionsErrorInfo": "ไม่สำเร็จ โปรดลองอีกครั้ง",
    "and": "และ",
    "PrivacyPolicy": "นโยบายความเป็นส่วนตัว",
    "TermsConditions": "เงื่อนไขการใช้งาน",
    "agree": "ด้วยการลงทะเบียนฉันยอมรับเงื่อนไข",
    "BLDescription": "คำอธิบายต้องมีความยาวระหว่าง 30-3000 ตัวอักษร",
    "markDownImageSize": "ขนาดต้องมีอย่างน้อย 300 x 300 พิกเซล ， น้อยกว่า 5M， รูปแบบ JPG, รูปแบบ PNG",
    "markDownAddImage": "เพิ่มภาพ",
    "noProductStore": "คุณยังไม่มีร้านค้าที่เกี่ยวข้อง โปรดเชื่อมต่อร้านค้าก่อนเพิ่มสินค้า",
    "noStoreInfo": "คุณสามารถเชื่อมต่อร้านค้าออนไลน์ของคุณและเริ่มต้นการเชื่อมต่อสินค้า สต็อกและคำสั่งซื้อได้ทันที",
    "noMemberInfo": "หลังจากที่ลูกค้าลงทะเบียนเป็นสมาชิกแล้ว คุณสามารถบันทึกข้อมูลรายละเอียดและการเปลี่ยนแปลงคะแนนได้ที่นี่ หรือคุณสามารถเพิ่มสมาชิกด้วยตนเอง",
    "noProductInfo": "คุณยังไม่ได้สร้างสินค้า เพิ่มสินค้าชิ้นแรกของคุณตอนนี้",
    "TextDescription": "คำบรรยาย",
    "memberInfo": "ข้อมูลสมาชิก",
    "jdDisableTips": "ไม่สามารถเชื่อมต่อได้ในขณะนี้",
    "Coming": "เร็วๆนี้",
    "editPointRuleInfo": "แน่ใจไหมที่จะแก้ไขเงื่อนไขการให้คะแนน?",
    "previewImg": "ดูตัวอย่าง",
    "addRule": "เพิ่มเงื่อนไข",
    "methodTypeAll": "ทั้งหมด",
    "sendSuccess": "ดำเนินการสำเร็จ",
    "editPointsRule": "แก้ไขเงื่อนไขการให้คะแนน",
    "pointeReduceErrorInfo": "ผู้ใช้ที่เลือกไม่ใช่ทุกคนมีคะแนนเพียงพอที่จะหัก",
    "ruleFormatErrorInfo": "ใช้ได้เฉพาะตัวอักษรพิมพ์เล็กและตัวเลขเท่านั้น",
    "rewardDeductPoints": "เพิ่มคะแนน/หักคะแนน",
    "alreadyExists": "{ name } มีอยู่แล้ว",
    "signNext": "ถัดไป",
    "copySuccess": "คัดลอกสำเร็จ: { copyUrl }",
    "deleted": "ลบ",
    "rule": "เงื่อนไข",
    "noName": "ไม่มีชื่อ",
    "copyUrl": "คัดลอก URL",
    "linkInfo": "ลิงค์",
    "laseSevenDaysInfo": "จำนวนสมาชิกล่าสุดใน 7 วันที่ผ่านมา",
    "editimage": "แก้ไขรูปภาพตัวเลือกสินค้า",
    "packageContentplaceholder": "น้อยกว่า 1000 ตัวอักษร",
    "packageContent": "ข้างในพัสดุบรรจุอะไร",
    "VariantImage": "ภาพรูปแบบสินค้า",
    "brand": "แบรนด์",
    "brandLogo": "โลโก้แบรนด์",
    "brandName": "ชื่อแบรนด์",
    "pointsHistory": "ประวัติสะสมคะแนน",
    "editShopInfo": "แก้ไขข้อมูลร้านค้า",
    "alreadyShopOwn": "ยินดีด้วย! ร้านของคุณพร้อมแล้ว",
    "shopSetInfo": "ข้อมูลร้านค้า",
    "contentWrapper": "เนื้อหา",
    "newSections": "ส่วนใหม่",
    "accumulatePoints": "รวมคะแนน",
    "chooseCardBg": "ธีม",
    "shopLogo": "โลโก้ร้านค้า",
    "shopName": "ชื่อร้านค้า",
    "membershipPageSettings": "การตั้งค่าหน้าสมาชิก",
    "uploadImageError": "ขนาดไฟล์ภาพ{ fileName }เล็กเกินไป, ขนาดเล็กสุด {limitMax}x{limitMin}",
    "uploadInfo": "ข้อกำหนด: {maxWH} * {maxWH} น้อยกว่า {isLt}M รูปแบบที่รองรับ jpg, png, jpeg",
    "enterPointMax": "ค่าสูงสุดที่สามารถใส่ได้คือ 999999",
    "enterPointNum": "ใส่จำนวนเต็มบวก 1- 999999",
    "updateSuccessFul": "อัพเดทสำเร็จ",
    "pointsType": "ประเภทคะแนน",
    "redeemablePoints": "ใช้คะแนน",
    "reason": "เหตุผล",
    "deductPoints": "หักคะแนน",
    "rewardPoints": "คะแนนสะสม",
    "joinedMethod": "วิธีการเข้าร่วม",
    "dateOfBirth": "วัน เดืน ปี เกิด",
    "remarksErrorInfo": "สามารถมีได้ถึง 30 ตัวอักษร",
    "editDetailInformation": "แก้ไขรายละเอียดข้อมูล",
    "editMemberInformation": "แก้ไขข้อมูลสมาชิก",
    "profileDetails": "ข้อมูลโปรไฟล์",
    "accountInformation": "ข้อมูลบัญชี",
    "rewardCondition": "เงื่อนไขการให้รางวัล",
    "customizeAlertInfo": "เงื่อนไขคะแนนที่กำหนดเองจะแสดงบนหน้าสมาชิก",
    "customizePointRules": "ออกแบบเงื่อนไขการให้คะแนน",
    "defaultAlertInfo": "ผู้ใช้งาน",
    "defaultPointRules": "เงื่อนไขคะแนนตั้งต้น",
    "joinMemberTime": "เวลาที่เริ่มเป็นสมาชิก",
    "inputNameOrMobile": "ใส่ชื่อ/เบอร์โทร",
    "editPoints": "แก้ไขคะแนน",
    "addNewMember": "เพิ่มสมาชิก",
    "totalPage": "ทั้งหมด (total)",
    "description": "คำอธิบาย",
    "points": "คะแนน",
    "memberNo": "หมายเลขสมาชิก",
    "mobile": "เบอร์โทรศัพท์",
    "shopname": "ชื่อร้านค้า",
    "name": "ชื่อ",
    "genderFemale": "หญิง",
    "genderMale": "ชาย",
    "gender": "เพศ",
    "deleteRuleInfo": "คุณแน่ใจหรือไม่ว่าต้องการลบเงื่อนไขนี้?",
    "status": "สถานะ",
    "checkBoxTableSelected": "{ selected } ที่เลือก",
    "create": "สร้าง",
    "dashboardPointCumulativeRedeem": "คะแนนสะสมที่สามารถแลกได้",
    "dashboardPointCumulativeGiven": "คะแนนสะสมที่ได้รับ",
    "dashboardPointToRedeem": "คะแนนที่สามารถแลกได้",
    "dashboardNewMember": "สมาชิกใหม่",
    "dashboardAllMember": "สมาชิกทั้งหมด",
    "hello": "สวัสดี!",
    "mobileErrorInfoLength": "หมายเลขโทรศัพท์ต้องอยู่ระหว่าง 9-12 หลัก",
    "fullnameerror": "ชื่อร้านค้าต้องมีจำนวนอักษรระหว่าง 1-30 ตัว",
    "fullname": "ชื่อร้านค้า",
    "mobileplaceholder": "เบอร์โทรศัพท์ต้องมี 10 หลัก",
    "mobileErrorInfochina": "เบอร์โทรศัพท์ไม่ถูกต้อง",
    "mobileErrorInfo": "ต้องเริ่มต้นด้วยรหัสหมายเลข  (+66)",
    "nameErrorInfo": "ชื่อต้องมีจำนวนระหว่างอักษร 2-30 ตัว",
    "emailErrorInfo": "อีเมลไม่ถูกต้อง",
    "resetPwdSuccess": "เปลี่ยนรหัสผ่านสำเร็จ",
    "resetPwd": "เปลี่ยนรหัสผ่าน",
    "alreadyInfo": "มีบัญชี Ginee อยู่แล้ว",
    "applyConfirm": "ยืนยัน",
    "forgotPwd": "ลืมรหัสผ่าน",
    "codeSendSuccess": "รหัสยืนยันตัวตนส่งเข้าอีเมลสำเร็จ",
    "noReceiveCountDown": "ไม่ได้รับรหัสยืนยันตัวตน",
    "waitCountDown": "กรุณารอ (someone) ส่งใหม่อีกครั้ง",
    "resendCode": "ส่งใหม่อีกครั้ง",
    "sendCode": "ส่ง",
    "signBy": "เมื่อลงทะเบียนฉันได้ยอมรับเงื่อนไขการใช้งานและนโยบายความเป็นส่วนตัว",
    "verificationCode": "ใส่รหัสยืนยันตัวตน",
    "verification": "ยืนยันตัวตน",
    "userName": "ชื่อ",
    "password": "รหัสผ่าน",
    "eMail": "อีเมล",
    "signUp": "ลงทะเบียน",
    "login": "เข้าสู่ระบบ",
    "welcomeRegister": "ยินดีต้อนรับเข้าสู่ Ginee",
    "welcomeLogin": "ยินดีต้อนรับเข้าสู่ Ginee",
    "welcome": "ยินดีต้อนรับเข้าสู่ Ginee",
    "copyProduct": "คัดลอกสินค้า",
    "orderDetail": "ข้อมูลคำสั่งซื้อ",
    "productDetail": "ข้อมูลสินค้า",
    "status_unpublished": "ยังไม่เผยแพร่",
    "status_update_failed": "เผยแพร่ไม่สำเร็จ",
    "status_delete": "ลบ",
    "status_draft": "ร่าง",
    "status_disabled": "ไม่แสดง",
    "status_banned": "ละเมิด",
    "status_sold_out": "หมด",
    "status_live": "ขายอยู่",
    "status_process": "กำลังดำเนินการ",
    "status_all": "ทั้งหมด",
    "staffDnotExists": "บัญชีพนักงานไม่ได้รับอนุญาต โปรดติดต่อร้านค้าเพื่อเพิ่มการอนุญาต (ไปที่การจัดการพนักงาน - เพิ่มพนักงาน - เพิ่มบัญชีนี้)",
    "fastPrinting": "พิมพ์แบบความเร็วสูง",
    "printPluginHasBeenUpdatedPlease": "ส่วนเสริมการพิมพ์ถูกปรับปรุงแล้วโปรดดาวน์โหลดใหม่อีกครั้ง",
    "afterUnbindTheStockWillNot": "หลังจากยกเลิกการเชื่อมต่อแล้ว สต็อกจะไม่ถูกผลักไปที่สินค้าช่องทางการขาย",
    "areYouSureYouWantTo": "คุณแน่ใจหรือไม่ว่าต้องการยกเลิกการเชื่อมต่อ?",
    "MSKUAutoBindSettingTip": "คลิกที่นี่เพื่อไปยังหน้าการตั้งค่าการเชื่อมต่อSKUหลักโดยอัตโนมัติ",
    "masterSkuInventoryInventorySynchronizationOf": "สต็อก SKU หลัก: ไม่รองรับการเชื่อมต่อสต็อกของสินค้าหลายคลังสินค้าของ Lazada ชั่วคราว หากจำเป็น กรุณาไปที่ศูนย์ผู้ขายเพื่อแก้ไข",
    "selectGenieCategory": "เลือกหมวดหมู่หลัก",
    "dayDuration": "อายุ（วัน）",
    "unbindedChannelVariant": "ยังไม่ได้เชื่อมต่อตัวเลือกสินค้าของช่องทาง",
    "deleteSkuConfirm": "คุณแน่ใจหรือไม่ว่าจะลบSKU",
    "thirdpartyWarehouseProductDetails": "รายละเอียดสินค้าของคลังฝากสต็อกสินค้า:",
    "thereAreThirdpartyWarehouseProductAmong": "สินค้าที่เลือกไว้มีสินค้าของคลังฝากสต็อกสินค้า ไม่สามารถลบได้",
    "canNotDeleteThirdStock": "สินค้าในคลังฝากสต็อกยังมีสินค้าอยู่ จึงไม่สามารถลบข้อมูลได้",
    "skuAffectedOutbound": "มีใบออกคลังใบเข้าคลังทั้งหมด {val} รายการที่จะถูกโมฆะสินค้า จะโมฆะใบออกคลังใบเข้าคลังและลบข้อมูล SKU หลักและสต็อกหรือไม่",
    "deleteSkuAlert": "หาก SKU มีใบออกคลังใบเข้าคลังที่ยังไม่เสร็จ ระบบจะโมฆะสินค้าในใบออกคลัง และลบสินค้าหลักและข้อมูลสต็อกพร้อมกัน",
    "proceedToDelete": "โมฆะและลบ",
    "productAffectedOutbound": "มีใบออกคลังใบเข้าคลังทั้งหมด {val} รายการที่จะถูกโมฆะสินค้า จะโมฆะใบออกคลังและลบข้อมูลสินค้าหลักและสต็อกหรือไม่",
    "nextStep1": "ขั้นตอนต่อไป",
    "deleteProductAlert": "หากสินค้ามีใบออกคลังใบเข้าคลังที่ยังไม่เสร็จ ระบบจะโมฆะสินค้าในใบออกคลัง และลบสินค้าหลักและข้อมูลสต็อกพร้อมกัน",
    "iNeed": "ฉันต้องการ",
    "youCanImportDataFormat": "คุณสามารถนำเข้าข้อมูลสูงสุดได้10,000 รายการในไฟล์เดียว（รูปแบบ：xlsx，xls）คุณสามารถแก้ไขชื่อSKU หลักและคำบรรยายสินค้าในหมวดหมู่หลักเป็นกลุ่ม",
    "WorkingDaysBetween": "ภายใน 7-90 วันทำการ",
    "workingDays": "วันทำงาน",
    "withoutMasterSku": "ไม่ได้เชื่อมกับSKU หลัก",
    "processTime": "เวลาจัดการ",
    "width": "ความกว้าง",
    "whenCreatingInTheProVersion": "เมื่อสร้างสินค้าในเวอร์ชัน Pro คุณต้องการอัปโหลดฟิลด์ที่จำเป็นต้องกรอก เพื่อสร้างสินค้าหลักและคัดลอกไปยังช่องทางร้านค้า",
    "whenAvailableStockInfo": "เมื่อสต็อกพร้อมส่ง<=X จะมีการแจ้งเตือนทางอีเมล",
    "weightLimit": "ต้องอยู่ระหว่าง 1-5,000,000",
    "weight": "นำ้หนัก",
    "inboundLimitTips": "เมื่อใช้ Fulfillment by JD กรุณาตรวจสอบให้แน่ใจว่าได้กรอกขัอมูลอายุการเก็บรักษา ตัวอย่างเช่น หากอายุการเก็บรักษาสินค้าคือ 100 วัน และขีดจำกัดเข้าคลังคือ 0.5 ดังนั้นหากอายุการเก็บรักษาน้อยกว่า 50 วัน จะไม่สามารถเข้าคลังได้",
    "outboundLimitTips": "เมื่อใช้ Fulfillment by JD กรุณาตรวจสอบให้แน่ใจว่าได้กรอกขัอมูลอายุการเก็บรักษา ตัวอย่างเช่น หากอายุการเก็บรักษาสินค้าคือ 100 วัน และขีดจำกัดออกคลังคือ 0.5 ดังนั้นหากอายุการเก็บรักษาน้อยกว่า 50 วัน จะไม่สามารถออกคลังได้",
    "inboundLimit": "ขัอจำกัดคลังขาเข้า",
    "outboundLimit": "ข้อจำกัดคลังขาออก",
    "shelfLife": "อายุการเก็บรักษา",
    "custom": "กำหนดเอง",
    "warehouseStockTip": "สต็อกจริงในคลังของคุณ，สต็อกคลัง=สต็อกสำรอง+สต็อกล็อก+สต็อกพร้อมส่ง+สต็อกโปรโมชั่น",
    "warehouseStockLimit": "ขอบเขตสต็อกสินค้าควรอยู่ระหว่าง 0-999,99",
    "yearly": "ภายใน 1 ปี",
    "monthly": "ภายใน 1 เดือน",
    "weekly": "ภายใน 1 สัปดาห์",
    "today": "วันนี้",
    "warehouseStock": "สต็อกคลัง",
    "warehouseName": "ชื่อคลัง",
    "viewNow": "ดูทันที",
    "viewMore": "ดูเพิ่มเติม",
    "viewLess": "ย่อเนื้อหา",
    "view": "ดู",
    "videoName2": "ฉันจะจัดการต้นทุนการจัดซื้อได้อย่างไร",
    "variationTypeInfo": "ประเภทตัวเลือกสินค้า",
    "variationStatus": "สถานะตัวเลือกสินค้า",
    "variationImageSizeLimit": "คุณสามารถอัปโหลดรูปภาพหลายรูปในครั้งเดียว จำนวนพิกเซลไม่น้อยกว่า 300 x 300 พิกเซล ใหญ่สุด 5MB",
    "variationImageInfo": "แก้ไขรูปภาพตัวเลือกของสินค้า",
    "variationCountDesc": "เรียงลำดับจำนวนตัวเลือกสินค้าที่เชื่อมไว้จากเยอะไปน้อย",
    "variationCountAsc": "เรียงลำดับจำนวนตัวเลือกสินค้าที่เชื่อมไว้\nจากตำ่ถึงสูง",
    "variationAlreadyBound": "ตัวเลือกสินค้านี้เชื่อมกับSKU หลักแล้ว กรุณายกเลิกการเชื่อมต่อก่อน",
    "exportLimit2": "ส่งออกได้สูงสุดครั้งละ 50000 สินค้าหลัก กรุณากรองใหม่",
    "exportLimit": "กรุณากรอกจำนวนหน้าเพื่อดาวน์โหลดสินค้า และคุณสามารถส่งออกได้สูงสุดครั้งละ 50000 สินค้าหลัก",
    "selectChannel": "เลือกช่องทางร้านค้า",
    "variation1": "ตัวเลือกสินค้า",
    "variantTypeRangeIs": "ขอบเขตประเภทตัวเลือกสินค้าที่1 ควรเป็น 1-14 หลัก",
    "variantTypeIsMissingPlease14": "ขาดประเภทตัวเลือกสินค้าที่ 2 กรุณาตรวจสอบ!",
    "variantTypeIsMissingPlease": "ขาดประเภทตัวเลือกสินค้าที่1 กรุณาตรวจสอบ!",
    "variantsInfoB": "สินค้า 10 ชิ้น （มีตัวเลือกของสินค้า 100 โดยมี 50 รายการสร้างSKU หลัก ได้）",
    "variantsInfoA": "สินค้า {productSum} ชิ้น （{availableNum}ในตัวเลือกสินค้า{variationSum} สามารถสร้างSKU หลัก ได้) ตัวเลือกสินค้าที่เชื่อมกับSKU หลัก แล้วจะไม่สามารถสร้างSKU หลัก ได้อีก",
    "numberOfProducts": "{productSum} สินค้า {variationSum})ตัวเลือกสินค้า",
    "variantPictureLinkCannotBe": "จำเป็นต้องกรอกลิงก์ของรูปภาพตัวเลือกสินค้าที่1!",
    "uploadFile": "อัปโหลดไฟล์",
    "downloadTemplate": "ดาวน์โหลดเทมเพลต",
    "step2Limit": "กรุณานำเข้ากฎตามเทมเพลตการดาวน์โหลด คุณสามารถอัปโหลดกฎได้สูงสุด 10,000 กฎในแต่ละครั้ง (รูปแบบ xlsx, xls)",
    "step2Dec": "ขั้นตอนที่ 2: นำเข้าเงื่อนไขเชื่อมต่อโดยอัตโนมัติใหม่เป็นกลุ่ม",
    "step1Dec": "ขั้นตอนที่ 1: ส่งออกเงื่อนไขที่เชื่อมต่อ",
    "variantOptionsLimit": "ตัวเลือกสินค้าสูงสุด 20",
    "variantOptionShouldBe": "ตัวเลือกสินค้าที่2 ควรเป็น 1-20 หลัก",
    "import10000Tip": "นำเข้าข้อมูลได้มากถึง 1,0000 รายการในแต่ละครั้ง",
    "variantNameChannel": "ชื่อตัวเลือกสินค้าในช่องทางร้านค้า",
    "variantName": "ชื่อตัวเลือกสินค้า",
    "variantImage": "รูปภาพตัวเลือกสินค้า",
    "username": "ชื่อบัญชี",
    "useImageFor": "ใช้รูปภาพกับ",
    "used": "มือสอง",
    "operationReason": "เหุตผลดำเนินการ",
    "goToSettings": "ตั้งค่า",
    "stores": "กรุณาไปที่ [การตั้งค่าสินค้า] และเปิดใช้งาน \"การเชื่อมต่อ SKU หลักโดยอัตโนมัติ\" ก่อนใช้ฟังก์ชั่นนี้",
    "storeSKU": "ฟังก์ชั่นนี้ยังไม่สามารถเปิดใช้งานได้",
    "channelVID": "IDตัวเลือกสินค้าบนช่องทางร้านค้า",
    "enabledAutoBind": "ไม่ได้เปิดใช้งานการตั้งค่าการเชื่อมต่อSKUหลักโดยอัตโนมัติและยังไม่มีกฎใดๆ!",
    "editAutoBindRule": "แก้ไขเงื่อนไขการเชื่อมต่อโดยอัตโนมัติ",
    "updateAutoBindRules": "อัปเดตเงื่อนไขการเชื่อมต่อโดยอัตโนมัติ",
    "exportAllAutoBindRules": "ส่งออกเงื่อนไขการเชื่อมต่อโดยอัตโนมัติ",
    "enterStoreSKU": "กรุณากรอกSKU ที่อยู่บนช่องทางร้านค้า",
    "bindingError": "SKUร้านค้าถูกเชื่อมไวกับ Master SKU อื่นๆ โปรดตรวจสอบ",
    "storeSkuLimit": "SKU ของร้านค้าควรมีความยาวน้อยกว่า 250 หลัก",
    "bindMasterSku": "สินค้าที่จับคู่แต่ไม่ได้เชื่อมต่อจะเชื่อมต่อSKUหลักโดยอัตโนมัติ",
    "bindedStore": "ร้านค้าที่เชื่อมต่อ",
    "bindedProduct": "สินค้าที่เชื่อมต่อ",
    "autoBind": "เชื่อมต่ออัตโนมัติ",
    "stepExportRules": "SKUบนร้านค้า",
    "variant": "ตัวเลือกสินค้า",
    "updateStock": "อัปเดตสต็อก",
    "updateSafetyStock2": "อัปเดตสต็อกปลอดภัย",
    "updateSafetyStock": "นำเข้าและอัปเดตสต็อกปลอดภัย",
    "updateInventoryInfo2": "กรุณาอัปเดตสต็อกที่ “การจัดการสต็อก”",
    "updateInventoryInfo": "กรุณาแก้ไขสต็อกของสินค้าที่เชื่อมไว้ด้วยวิธีการอัปเดตสต็อกที่เชื่อมSKU หลัก",
    "updatedSuccessfully": "อัปเดตสำเร็จ",
    "updateAvailableStock": "สต็อกพร้อมใช้ที่อัปเดตไปยังร้านค้า",
    "unitPurchasPrice": "ราคาจัดซื้อต่อหน่วย",
    "unitPurchasePrice": "ราคาซื้อต่อหน่วยของสินค้า",
    "unbindInfo": "สินค้าได้ยกเลิกเชื่อมกับSKU หลัก แล้ว จึงปล่อยสต็อกล็อก",
    "unbind": "เลิกเชื่อม",
    "turnOnStock": "เปิดใช้งานฟีเจอร์ติดตามและเชื่อมต่อสต็อก ตั้งค่าการเริ่มต้นสต็อกสินค้าบนช่องทางร้านค้า",
    "totalNum": "ข้อมูลทั้งหมด{val} รายการ",
    "toShip": "ที่จะจัดส่ง",
    "timeInfoWorkingDay": "วันทำงาน",
    "timeInfoMonth": "เดือน",
    "thisVariationOnly": "แค่ตัวเลือกสินค้า",
    "theSameNameIsRegardedAs": "(ชื่อเดียวกันถือเป็นสินค้าเดียวกัน จำกัดความยาวที่ 300 หลัก)",
    "theNameAlreadyExistsInTheStore": "ชื่อสินค้าซำ้กัน",
    "theMasterSKUDoesNotExist": "ไม่มี SKU หลักนี้ กรุณาตรวจสอบ!",
    "theLinkOfVariantPicture": "ลิงก์ของรูปภาพตัวเลือกสินค้าที่ 1 ไม่ถูกต้อง กรุณากรอกอีกครั้ง!",
    "theInventorySkuAlreadyExists": "SKU หลัก นี้ซำ้กัน",
    "theCurrencyUnitOfTheDefault": "(หน่วยเงินตราของราคาค่าเริ่มต้นและราคาจัดซื้อต่อหน่วยมีดังนี้ได้:USD/IDR/PHP/THB/VND/MYR/SGD/CNY)",
    "theCategoryIDDoesNotExist": "ไม่มี ID ประเภทนี้ กรุณาดาวน์โหลดตารางประเภทและตรวจสอบ",
    "theBasicVersionCanImportA": "เวอร์ชันพื้นฐานสามารถนำเข้าฟิลด์ที่จำเป็นต้องกรอกจำนวนน้อย ซึ่งคุณจะสามารถสร้าง SKU หลัก เพื่อเปิดใช้ฟังก์ชั่นการจัดการสต็อก",
    "syncInfoB": "{6787686756}ปิดการใช้งานเชื่อมต่อสต็อก",
    "syncInfoA": "{6787686756}เปิดการใช้งานเชื่อมต่อสต็อก",
    "successNumber": "จำนวนที่สำเร็จ",
    "successfullyUnbindInfo": "ยกเลิกการเชื่อมต่อตัวเลือกสินค้าได้สำเร็จเป็นจำนวน {val}",
    "successfullyBindInfo": "เชื่อมต่อตัวเลือกสินค้าได้สำเร็จเป็นจำนวน {val}",
    "storeWithoutSales": "ร้านค้าที่ยังไม่มียอดขาย",
    "storeUpdateResult": "ผลการอัปเดตร้านค้า",
    "storeSku": "SKU  ช่องทางร้านค้า",
    "storeNameB": "ชื่อร้านค้าที่กำลังขายอยู่",
    "storeNameA": "ชื่อร้านค้า",
    "storeCountDesc": "จำนวนร้านค้าจากสูงถึงตำ่",
    "storeCountAsc": "จำนวนร้านค้าจากตำ่ถึงสูง",
    "stockSettingsStockMonitorAndSyncOn": "การตั้งค่าสต็อก-ติดตามและเชื่อมต่อสต็อก，เปิด：26-10-2020 17:17",
    "stockSettingsStockMonitorAndSyncOff": "การตั้งค่าสต็อก-ติดตามและเชื่อมต่อสต็อก，ปิด：26-10-2020 17:17",
    "stockChannelInfo": "ยอดสต็อกสินค้าที่ขายได้บนช่องทางร้านค้า, สต็อกสินค้าพร้อมส่ง=สต็อกคลัง-สต็อกสำรอง-สต็อกล็อก-สต็อกโปรโมชั่น",
    "stockChanges": "สต็อกเปลี่ยน",
    "stepImportAndCreateThe": "ขั้นตอนที่ 2 ：นำเข้าและสร้างสินค้าหลัก",
    "stepDownloadTheMasterCategory": "ขั้นตอนต่อไป：ดาวน์โหลด ID ประเภทหลัก",
    "step3Text": "ขั้นตอน3: นำเข้าสินค้าที่เชื่อมไว้",
    "step2Text": "ขั้นตอน2：นำออกSKU หลัก",
    "step1Text": "ขั้นตอนแรก：เลือกและนำออกสินค้าช่องทางที่ต้องการเชื่อมกับSKU หลัก",
    "spareStockTip": "สต็อกทั้งหมดที่เตรียมไว้ก่อนในคลังจริงจะไม่มีขายบนช่องทางร้านค้า",
    "spareStock": "สต็อกเตรียมไว้",
    "sourceUrl": "ที่มา URL",
    "sortMasterProduct": "เลือกสินค้าหลัก",
    "someWarehouseTheWarehouseStockOf": "ในคลังสินค้าบางแห่ง สต็อกคลังสินค้าของ SKU หลักไม่ใช่ 0 โปรดอัปเดตเป็น 0 ก่อนลบ",
    "somePartUpdateFailed": "อัปเดตสำเร็จบางส่วน",
    "somePartReviewFailed": "ไม่ได้ผ่านการตรวจสอบบางส่วน",
    "someDatasImportFailed": "นำเข้าข้อมูลบางส่วนล้มเหลว",
    "someCreateFailed": "สร้างล้มเหลวบางส่วน",
    "skuStoreSku": "SKU (SKU บนร้านค้า)",
    "skuAlreadyExistsPleaseDeleteThe": "มี SKUนี้อยู่แล้ว กรุณาลบสินค้าที่มีอยู่หรือตั้งชื่อใหม่!",
    "singlePurchasePriceOfTheProduct": "ราคาจัดซื้อต่อหน่วย",
    "singlePurchasePrice": "ราคาจัดซื้อต่อหน่วย",
    "shouldBeBetweenLimitB": "ขอบเขตควรอยู่ระหว่าง0-1000,000,000",
    "shouldBeBetweenLimitA": "ขอบเขตควรอยู่ระหว่าง0-999,999",
    "shouldBeBetweenB": "ต้องอยู่ระหว่าง1-999,999",
    "shouldBeBetweenA": "ต้องอยู่ระหว่าง1-999,999",
    "shippingFeeInformation": "ข้อมูลค่าจัดส่ง",
    "setPageTip2": "กรุณากรอกเลขหน้าที่ถูกต้อง",
    "setPageTip": "กรุณาเลือกเลขหน้าที่ต้องการนำออก",
    "selling": "กำลังขายอยู่",
    "selectProductBundle": "เลือกและเชื่อมสินค้า",
    "selectGineeCategory": "เลือกหมวดหมู่หลัก",
    "selectFile": "เลือกไฟล์",
    "selectedMax20": "ที่เลือก(สูงสุด20)",
    "selected": "ที่เลือกไว้",
    "selectCategories": "กรุณาเลือกหมวดหมู่",
    "selectAtLeastOneInventorySku": "เลือกSKU หลักอย่างน้อย 1",
    "editRule": "แก้ไขเงื่อนไข",
    "selectAtLeastOneImage": "เลือกรูปภาพอย่างน้อย1รูป",
    "createDates": "สร้างเมื่อ",
    "seeXProducts": "ดูสินค้าจำนวน X",
    "seeMoreTips": "ดูสินค้าจำนวน{val}",
    "saveUpdate": "บันทึกการเปลี่ยนแปลง",
    "saveForReview": "บันทึกเป็นรอการตรวจสอบ",
    "savedSuccessfully": "บันทึกสำเร็จ",
    "saveAsReviewed": "บันทึกเป็นตรวจสอบแล้ว",
    "salesTaxAmount": "ยอดเงินภาษีการค้าขาย",
    "safetyStockStatus": "สถานะสต็อกปลอดภัย",
    "safetyStock": "สต็อกปลอดภัย",
    "rupiah": "รูเปียห์",
    "reviewPassed": "ผ่านการตรวจสอบ",
    "reviewed": "ตรวจสอบแล้ว",
    "reReview": "ตรวจสอบใหม่",
    "reasonForFailure": "เหตุผลล้มเหลว：ขาดข้อมูลจำเป็นต้องกรอก กรุณากรอกให้สมบูรณ์",
    "rangeLimitA": "ขอบเขตควรอยู่ระหว่าง 0-1,000,000,000",
    "rangeIsLimit": "ต้องอยู่ระหว่าง 0-5,000,000",
    "quantityLimit": "จำนวนสินค้าควรอยู่ระหว่าง1-999999",
    "qty": "จำนวน",
    "purchaseTime": "เวลาการจัดซื้อ",
    "purchasePriceShouldLimit": "ราคาจัดซื้อต่อหน่วยควรอยู่ระหว่าง0-1000,000,000 รูเปียห์",
    "purchasePriceLimit": "จำนวนเงินต้องอยู่ระหว่าง0-1,000,000,000",
    "purchasePriceInfo": "ไม่ต้องกรอก ราคาจัดซื้อออกคลังจะคิดตามราคาต้นทุนเฉลี่ย",
    "proVersionImportToCreate": "การนำเข้าและสร้างสินค้าเวอร์ชัน Pro",
    "promotionStockTip": "สต็อกโปรโมชั่นคือสต็อกทั้งหมดที่เตรียมไว้ก่อนเพื่อCampaign หรือโปรโมชั่น Flash sale",
    "promotionStock": "สต็อกโปรโมชั่น",
    "productType": "ประเภทสินค้า",
    "productsWithoutVariantsCanBeEmpty": "(สินค้าที่ไมมีตัวเลือกสามารถเว้นว่างได้)",
    "productSources": "ที่มาของสินค้า",
    "productNameLimit": "ชื่อสินค้าต้องอยู่ระหว่าง 5-300 หลัก",
    "productNameInventorySku": "ชื่อสินค้า & SKU หลัก",
    "productInformation": "ข้อมูลสินค้า",
    "productImageLinkIsInvalid": "ลิงก์ของรูปภาพสินค้าที่1 ไม่ถูกต้อง กรุณากรอกอีกครั้ง!",
    "productImageLinkCannotBe": "จำเป็นต้องกรอกลิงก์ของรูปภาพสินค้าที่1",
    "productImage": "รูปภาพสินค้า",
    "productFailedToCreatePleaseDownload": "ไม่สามารถสร้างสินค้าหลัก โปรดดาวน์โหลดข้อมูลที่ล้มเหลวและดูเหตุผลที่ล้มเหลว",
    "productEditSuccess": "แก้ไขสินค้าสำเร็จ",
    "productEditFailed": "แก้ไขสินค้าไม่สำเร็จ",
    "productDescriptionShouldBeCharacters": "คำแนะนำสินค้าควรอยู่ระหว่าง 1 - 7000 หลัก",
    "productDescription": "คำแนะนำสินค้า",
    "productCreatedSuccessfully": "สร้างสินค้าสำเร็จ",
    "product": "สินค้า",
    "preorder11": "Pre-order",
    "popupResultButton": "ดูข้อมูลล้มเหลว",
    "pleaseEnterTheAmount": "กรุณากรอกจำนวนเงิน",
    "pleaseEnterKeywordsToSearchChannelProducts": "กรุณากรอกคีย์เวิร์ดเพื่อค้นหาสินค้าบนช่องทางร้านค้า",
    "pleaseEnter": "กรุณากรอก",
    "pleaseDownloadTheTemplateAndComplete": "กรุณาดาวน์โหลดเทมเพลตและกรอกข้อมูลให้สมบูรณ์ จากนั้นนำเข้าและสร้างสินค้า（คุณสามารถนำเข้าข้อมูลครั้งละ 10000 รายการ ）",
    "pleaseChoose": "กรุณาเลือก",
    "partiallyFailedInfo": "บางส่วนล้มเหลว,สถานะยังกำลังตรวจสอบ",
    "packageSize": "ขนาดพัสดุ",
    "orderToEnsureTheIntegrity": "เพื่อให้ยืนยันความสมบูรณ์ของกระบวนการและข้อมูลรายงานทางสถิติ ส่วนหนึ่งของ SKU หลักไม่สามารถลบได้ ดังนั้นประเภทตัวเลือกสินค้าของสินค้านี้จึงไม่สามารถแก้ไขได้ ขอแนะนำให้ \"อ้างอิง\" สินค้านี้เพื่อสร้างตัวเลือกสินค้าหลายรายการของสินค้าหลัก จากนั้นตั้งค่าสินค้านี้เป็น \"ยังไม่ติดตาม\"",
    "orderToEnsureTheIntegrityOf": "เพื่อให้มั่นใจถึงความสมบูรณ์ของข้อมูล ขอแนะนำให้ใช้ \"ยังไม่ติดตามชั่วคราว\"",
    "optionLimit": "ข้อเลือกต้องอยู่ระหว่าง1-30 หลัก",
    "option": "ข้อเลือก",
    "operationTime": "เวลาทำการ",
    "operationGuide": "คำแนะนำของการดำเนิน",
    "noShelfLife": "ไม่มีอายุการเก็บรักษา",
    "onSaleShop": "ร้านค้าที่กำลังขายอยู่",
    "onReview": "กำลังตรวจสอบอยู่",
    "onlyShowBindedVariations": "แสดงตัวเลือกสินค้าที่ไม่มี MSKU เท่านั้น",
    "numVariationsSelected": "0 ตัวเลือกของสินค้าถูกเลือก",
    "numVariations": "{val}ตัวเลือกสินค้า",
    "numSelected": "{val} ถูกเลือก",
    "numEditBindInformation": "{val}-แก้ไขข้อมูลการเชื่อม",
    "nowInbound": "ขาเข้าทันที",
    "normalProducts": "สินค้าปกติ",
    "noData": "ไม่มีข้อมูล",
    "noChannelVariationId": "ไม่มีIDของตัวเลือกสินค้าในช่องทางร้านค้า！",
    "newProduct": "สินค้าใหม่",
    "newAveragePurchasePrice": "ราคาจัดซื้อต่อเฉลี่ยใหม่",
    "newAverageCostPrice": "ราคาต้นทุนเฉลี่ยใหม่",
    "new": "ใหม่",
    "mutiVariations": "หากสินค้าของคุณมีสีขนาดที่แตกต่างกัน มีตัวเลือกมากกว่า 1 รายการ กรุณาเลือกข้อนี้",
    "mustBeBetween": "ควรอยู่ระหว่าง1-8",
    "mskuAndstockVideo": "สินค้าหลักสัมพันธ์กับการจัดการสต็อกอย่างไร",
    "mSku": "SKU หลัก",
    "more": "เพิ่มเติม",
    "minPurchaseQuantityShouldBe": "จำนวนซื้อน้อยสุดควรอยู่ระหว่าง1-1000",
    "minimumPurchaseQuantity": "จำนวนซื้อขั้นต่ำ",
    "mediaSettings": "ตั้งค่ามีเดีย",
    "maxNumVal": "สูงสุด {val} รูป",
    "maxNum": "สูงสุด{val}",
    "maxLengthNum": "สูงสุด1{val} หลัก",
    "maxLengthCharacters": "สูงสุด{max} หลัก",
    "maxLength14": "สูงสุด14 หลัก",
    "maximumIs": "(สูงสุด1,000,000,000)",
    "maximumCharacters": "(สูงสุด 7000 หลัก)",
    "max9": "สูงสุด 9 รูป",
    "masterSkuHasBeenMatchedWith": "SKU หลักได้รับการจับคู่กับสินค้าจากคลังฝากสต็อกสินค้าแล้ว",
    "masterProductsImage": "สินค้าหลัก&รูปภาพ",
    "masterProductName": "ชื่อสินค้าหลัก",
    "masterProduct": "สินค้าหลัก",
    "master": "สินค้าหลัก",
    "massSettings": "ตั้งค่าเป็นกลุ่ม",
    "mainPhotos": "รูปภาพปก",
    "lockedStockTip": "สต็อกกำลังรอดำเนินการ แต่ยังไม่ได้จัดส่ง คำสั่งซื้อ“ชำระเสร็จ”ใหม่หรือ“รอชำระ”ใหม่จะเพื่มไปยังสต็อกล็อก",
    "lockedStock": "ล็อคสต็อก",
    "liveTips": "ขอบเขต：รวมสินค้าที่หมดสต็อกและสินค้าที่ขายอยู่",
    "live": "สินค้าที่ขายอยู่",
    "length": "ความยาว",
    "iWillShipOutWithinX": "ฉันจะจัดส่งสินค้าภายใน x ชั่วโมงเวลาทำการ",
    "iWillShipOutWithin22": "ฉันจะจัดส่งสินคัาภายใน 2 วันทำงาน(ไม่รวมถึงวันหยุดนักขัตฤกษ์และวันหยุดตามนโยบายบริษัทขนส่ง)",
    "iWillShipOutWithin": "ฉันจะจัดส่งสินค้าภายใน3วัน(ไม่รวมถึงวันหยุดนักขัตฤกษ์และวันหยุดตามนโยบายบริษัทขนส่ง)",
    "iskuStandsForInventorySku": "MSKU คือ SKU หลัก",
    "iskuCannotBeEmpty": "จำเป็นต้องกรอกSKU หลัก",
    "isAnUnprocessedOutboundInThe": "มีใบออกคลังที่ยังไม่ได้ดำเนินการใน SKU หลัก",
    "inventorySkuSpaceLimit": "SKU หลัก มีเว้นว่างหรือข้อมูลไม่ถูกต้อง กรุณาแก้ไขอีกครั้ง",
    "inventorySkuLimit": "SKU หลัก ควรน้อยกว่า 300 หลัก",
    "inventorySkuGenRules": "เงื่อนไขการสร้างSKU หลัก โดยค่าตั้งต้น：\\n1.SKU หลัก จะเลือก 50 อักษรแรกของ​​​​​​​​​​​​​​​​​​​ตัวเลือกสินค้าโดยค่าเริ่มต้น\\n2.จำนวนสต็อกคลังจะเท่ากับสต็อกตัวเลือกสินค้าโดยค่าตั้งต้น สต็อกปลอดภัยจะเป็น 0 \\n3.เมื่อสร้างSKU หลัก จะเชื่อมกับ SKU ของตัวเลือกสินค้า",
    "inventorySkuExistsLimit": "SKU หลักซำ้ กรุณาแก้ไขอีกครั้ง",
    "instructions": "คู่มือการใช้งาน",
    "iNeedXWorkingDays": "ฉันต้องการ x วันทำงานถึงจะจัดส่งสินค้าได้",
    "iNeedXWeeksToShip": "ฉันต้องการ x สัปดาห์ถึงจะจัดส่งสินค้าได้",
    "iNeedXDaysToShip": "ฉันต้องการ x วันถึงจะจัดส่งสินค้าได้",
    "importToEditProduct": "นำเข้าและแก้ไขสินค้า",
    "importToBindIntro": "ฟังก์ชั่นนี้สนับสนุนให้ผู้ขายเชื่อมสินค้าในร้านกับSKU หลัก เป็นกลุ่ม เพื่อจัดการสต็อก",
    "importToBind": "นำเข้าสินค้าที่เชื่อมต่อไว้",
    "importTip": "หนึ่งไฟล์สามารถนำออกเข้าข้อมูลสูงสุด1000",
    "importMasterProducts": "นำออกสินค้าหลัก",
    "importFileFormatIsIncorrectInfo2": "เทมเพลตการนำเข้าไฟล์ไม่ถูกต้อง กรุณาตรวจสอบ",
    "importFileFormatIsIncorrect": "รูปแบบการนำเข้าไฟล์ไม่ถูกต้อง กรุณาดาวน์โหลดเทมเพลตการนำเข้า",
    "importFile": "นำเข้าไฟล์",
    "importFailed": "นำเข้าล้มเหลว",
    "importAndCreateProducts": "นำเข้าและสร้างสินค้า",
    "importAndAddInfo": "คุณสามารถนำเข้าข้อมูลไม่เกิน10,000ในหนึ่งไฟล์（รูปแบบ：xlsx,xls）ไปเพิ่มสินค้าหลักเป็นกลุ่ม",
    "imageSizeLimit": "จำนวนพิกเซลไม่น้อยกว่า 300 x 300 พิกเซล，ใหญ่สุด 5MB",
    "ifItIsEmptyWillNot": "หากไม่มีข้อมูลแสดงว่ายังอัปเดต",
    "hour": "ชั่วโมง",
    "hotSale": "สินค้ายอดฮิต",
    "helpCenterLink": "คำอธิบายการดำเนินการ",
    "height": "ความสูง",
    "hasBindedInfo": "ตัวเลือกสินค้านี้เชื่อมกับSKU หลัก: xxxxxxxx แล้ว กรุณาอัปเดตสต็อกใน\"จัดการสต็อก\"",
    "generateMaster": "สร้างSKU หลัก สำหรับสินค้าในร้านค้าเดียว เพื่อจัดการสต็อกในร้านค้าหลายร้าน",
    "filter": "กรอง",
    "fileRules": "ใหญ่สุด：xlsx，xls ไม่เกิน 5 MB",
    "faqName2": "วิธีการสร้างสินค้าหลักในเวอร์ชั่น Basic ด้วยการนำเข้าข้อมูล",
    "faqName1": "วิธีการสร้างสินค้าหลักในเวอร์ชัน Pro ด้วยการนำเข้าข้อมูล",
    "failedReason": "เหตุผลที่ล้มเหลว:สินค้าอาจจะถูกลบไปแล้ว กรุณาเชื่อมต่อสินค้าอีกครั้ง",
    "failedNumber": "จำนวนที่ไม่สำเร็จ",
    "exportUpTo": "คุณสามารถนำออกข้อมูลเยอะสุด10,000(รูปแบบxlsx，xls)ในไฟล์เดียวไปเชื่อมสินค้าเป็นกลุ่ม",
    "exportTip": "กรุณาเลือกสินค้าที่ต้องการนำออก",
    "exportSelected": "นำออกตามข้อเลือก",
    "exportOrImport": "นำออก/นำเข้า",
    "exportByPageTip": "กรุณากรอกเลขหน้าที่ถูกต้อง",
    "exportByPage": "นำออกตามเลขหน้า",
    "export": "นำออก",
    "enterTheNameOfTheVariationForExampleColorEtc": "กรุณากรอกชื่อตัวเลือกของสินค้า เช่นสี ฯลฯ",
    "enterProcessTime": "กรอกเวลาดำเนินการ",
    "enterPageNumber": "กรุณากรอกเลขหน้าดาวน์โหลดสินค้าหลัก",
    "enterOption": "กรุณากรอกข้อเลือกเช่น สีแดง ฯลฯ",
    "enableInventorySync": "เปิดการใช้งานเชื่อมต่อสต็อก",
    "editSafetyFtock": "แก้ไขสต็อกปลอดภัย",
    "editOptionsBlue": "แก้ไขข้อเลือก-สีนำ้เงิน",
    "editInventory": "อัปเดตสต็อก",
    "editAvailableStock": "แก้ไขสต็อกพร้อมส่ง",
    "downloadTip": "ดาวน์โหลด",
    "downloadTheMasterCategory": "ดาวน์โหลดประเภทหลัก",
    "downloadTemplateInfo": "ดาวน์โหลดเทมเพลต",
    "downloadProduct": "ดาวน์โหลดสินค้า",
    "downloadFailedData": "ดาวน์โหลดข้อมูลล้มเหลว",
    "discontinued": "หยุดขาย",
    "disableInventorySync": "ปิดการใช้งานเชื่อมต่อสต็อก",
    "descriptionRequired": "กรุณากรอกคำบรรยายและปรับใช้กับทุกรายการสินค้า",
    "descriptionLimit": "คำบรรยายสินค้าควรอยู่ระหว่าง 30-5000 หลัก",
    "delivery": "ข้อมูลจัดส่งสินค้า",
    "deleteMPTips2": "สินค้าหลักถูกลบแล้ว",
    "deleteMPTips": "ลบล้มเหลว：สินค้าหลัก {val}",
    "deleteLimit": "เหตุผลที่ล้มเหลว：กรุณายกเลิอกการเชื่อมจากสต็อก SKU แล้วค่อยลบใหม่อีกครั้ง",
    "delConfirmTitle": "กรุณายืนยันว่าต้องการลบสินค้า?",
    "delConfirmContent": "หลังจากลบสินค้าหลัก, SKUหลักในเมนูการจัดการสต็อกจะถูกลบด้วย",
    "defaultPrice": "ราคาค่าตั้งต้น",
    "dataSources": "ที่มาของข้อมูล",
    "datasImportSuccess": "นำเข้าข้อมูลสำเร็จ",
    "datasImport": "นำเข้าข้อมูล",
    "creatingProduct": "กำลังสร้างสินค้าอยู่",
    "createProductFailed": "สร้างสินค้าไม่สำเร็จ",
    "costInformation": "ข้อมูลต้นทุน",
    "continueToBindInfo": "คุณสามารถเชื่อมสินค้าบนช่องทางร้านค้าในรายการสินค้าหลักต่อไปได้",
    "condition": "สถานะอายุการเก็บรักษาของสินค้า",
    "columnsCannotBeEmpty": "ไม่สามารถเว้นว่างในคอลัมน์นี้ได้",
    "collectByAdd": "เก็บ&สร้าง",
    "cny": "หยวน",
    "salesTaxAmountLimit": "จำนวนเงินต้องอยู่ระหว่าง0-1,000,000,000",
    "clearance": "ล้างสต็อก",
    "clear": "ลบ",
    "checkNow": "ตรวจสอบเดี๋ยวนี้",
    "channelVariationIdNotEmpty": "จำเป็นต้องกรอกIDของตัวเลือกสินค้าในช่องทางร้านค้า！",
    "channelVariationId": "ID ของตัวเลือกสินค้าในช่องทางร้านค้า",
    "channelSellingStatus": "สถานะการขายในช่องทางร้านค้า",
    "channelProductsImage": "สินค้าบนช่องทางร้านค้า&รูปภาพ",
    "channelProducts": "สินค้าบนช่องทางร้านค้า",
    "channelProductName": "ชื่อสินค้าในช่องทางร้านค้า",
    "changeTo": "แก้ไขเป็น",
    "changeReason": "เหตุผลที่เปลี่ยน",
    "changeIsku": "เปลี่ยนSKU หลัก",
    "ceneratedFromChannelProduct": "สร้างขึ้นตามช่องทางร้านค้า",
    "categoryID": "ID หมวดหมู่",
    "category": "ประเภท",
    "cancelUpdateConfirm": "คุณยืนยันไหมจะยกเลิกการอัปเดตครั้งนี้หรือไม่?",
    "bundledProduct": "สินค้าที่เชื่อมแล้ว",
    "bundle": "เชื่อมต่อ",
    "boundInfo": "เชื่อมต่อแล้ว",
    "bindProduct": "เชื่อมต่อสินค้า",
    "bindNow": "เชื่อมทันที",
    "bindInstruction": "สินค้าบนช่องทางร้านค้าเชื่อมกับSKU หลัก เสร็จแล้ว กรุณานำเข้าข้อมูลตามเทมเพลตดาวน์โหลดมาเชื่อมสินค้า",
    "bindingType": "ประเภทการเชื่อมต่อ",
    "bindingInProgress": "กำลังเชื่อมสินค้าเป็นกลุ่มอยู่",
    "bindingInformation": "ข้อมูลการเชื่อม",
    "bindInformation": "เชื่อมข้อมูล",
    "bindInfoB": "ยกเลิกการเชื่อมต่อ l,gree{id:111234}กับ{6787686756}",
    "bindInfoA": "l,gree{id:111234}เชื่อมกับ{6787686756}",
    "bindHistory": "ประวัติการเชื่อมต่อ",
    "bindedInventorySku": "เชื่อมSKU หลัก ไว้แล้ว",
    "binded0Variation": "เชื่อม 0 ตัวเลือกสินค้า",
    "weightShouldBeBetween": "ขอบเขตนำ้หนักควรอยู่ระหว่าง1-5,000,000!",
    "heightShouldBeBetween": "ขอบเขตความสูงควรอยู่ระหว่าง1-999,999!",
    "widthShouldBeBetween": "ขอบเขตความกว้างควรอยู่ระหว่าง1-999,999!",
    "lengthShouldBeBetween": "ขอบเขตความยาวควรอยู่ระหว่าง1-999,999!",
    "betweenWeeks": "ภายใน1-13 สัปดาห์",
    "betweenDays": "ภายใน1-90วัน",
    "between21": "ภายใน7～15",
    "between": "ระหว่าง 3-30",
    "beBetweenWorkingHoursMust": "ช่วงควรเป็น 1～8 ชั่วโมงทำงาน",
    "basicVersionImportToCreate": "การนำเข้าและสร้างสินค้าเวอร์ชันพื้นฐาน",
    "theSameNameWillConsideredAs": "(ชื่อเดียวกันถือเป็นสินค้าเดียวกัน จำกัดความยาวที่ 300 หลัก)",
    "categoryName": "ชื่อหมวดหมู่",
    "basicInformation": "ข้อมูลพื้นฐาน",
    "variantPictureLink": "ลิงก์ของรูปภาพตัวเลือกสินค้าที่1",
    "barcodeLimitC": "รูปแบบบาร์โค้ดสนับสนุนตัวอักษร ตัวเลขและ-_ เท่านั้นและไม่ควรเกินกว่า 100 หลัก",
    "productImageLink1": "ลิงก์ของรูปภาพสินค้าที่1",
    "barcodeLimitB": "บาร์โค้ดไม่ควรเกินกว่า 100 หลัก",
    "barcodeLimitA": "รูปแบบบาร์โค้ดสนับสนุนตัวอักษร ตัวเลขและ-_ เท่านั้น",
    "temporaryNotFollowingXProducts": "ไม่ติดตาม {X} สินค้าชั่วคราว",
    "thisBarcodeIsAlreadyOccupiedBy": "บาร์โค้ดนี้ถูกใช้โดย {MSKU} โปรดป้อนอีกครั้ง",
    "currency": "สกุลเงิน",
    "barcode": "บาร์โค้ด",
    "averagePurchasePrice": "ราคาจัดซื้อเฉลี่ย",
    "averageCostPriceInfo": "ราคาต้นทุนเฉลี่ย=ราคาจัดซื้อเข้าคลัง/สต็อกคลัง",
    "averageCostPrice": "ราคาต้นทุนเฉลี่ย",
    "variantOption1ShouldBe": "ตัวเลือกสินค้าที่1 ควรเป็น 1-20 หลัก",
    "variantOption1": "ข้อเลือกตัวเลือกสินค้า1",
    "variantOption2": "ข้อเลือกตัวเลือกสินค้า2",
    "variantTypeIs2": "ประเภทตัวเลือกสินค้า2",
    "variantTypeIs": "ประเภทตัวเลือกสินค้า1",
    "unspecifiedProductsCanBeEmpty": "สินค้าที่ไมมีตัวเลือกสินค้าสามารถว่างเปล่าได้",
    "availableStockTip": "สต็อกทั้งหมดที่ขายได้ในช่องทางร้านค้า，สต็อกพร้อมส่ง=สต็อกคลัง-สต็อกเตรียมไว้-สต็อกล็อก-สต็อกโปรโมชั่น",
    "availableStockLimit": "จำเป็นต้องกรอกจำนวนสต็อกพร้อมส่ง",
    "availableStockInputLimit": "สต็อกคลัง≥สต็อกเตรียมไว้",
    "availableStockInfo": "สต็อกพร้อมส่ง=สต็อกคลัง-สต็อกสำรอง-ล็อคสต็อก-สต็อกโปรโมชั่น，กรุณาแก้ไขสต็อกพร้อมส่งโดยอัปเดตสต็อกคลัง",
    "availableStock": "สต็อกพร้อมส่ง",
    "availableForDownloadCategory": "(หมวดหมู่สามารถดาวน์โหลดได้)",
    "autoMatchVariationInfo": "หลังจากสร้างSKUหลัก แล้ว SKUหลัก จะเชื่อมกับตัวเลือกสินค้าที่มีชื่อSKUบนร้านค้าตรงกับ SKU หลัก",
    "articleName2": "วิธีการเชื่อมสินค้าบนช่องทางร้านค้ากับSKU หลัก",
    "applyToAllVariation": "ใช้กับตัวเลือกสินค้าทั้งหมด",
    "aOfb": "1-50 หน้า ทั้งหมด219",
    "allVariation": "ตัวเลือกสินค้าทั้งหมด",
    "missingVariantPleaseCheck": "ขาดข้อเลือกตัวเลือกสินค้า 1 รายการ กรุณาตรวจสอบ！",
    "allUpdateFailed": "อัปเดตล้มเหลวทั้งหมด",
    "max1000": "สูงสุด 1000",
    "missingMasterProductVariantRed": "สินค้าหลักขาดตัวเลือกสินค้า：red/s、red/L \nคุณต้องการกรอกให้สมบูรณ์แล้วสร้างสินค้าใหม่",
    "massOperationHistory": "ประวัติการดำเนินการเป็นกลุ่ม",
    "publishToStore": "เผยแพร่ไปยังร้านค้า",
    "chooseDestinationStore": "เลือกร้านค้าที่ต้องการ",
    "selectStore": "เลือกร้านค้า",
    "nextStep": "ขั้นตอนต่อไป",
    "orderProcessingTimeFollowsTheShops": "เวลาดำเนินการคำสั่งซื้อจะเป็นไปตามการตั้งค่าของร้านค้า",
    "allUpdatedSuccessfully": "อัปเดตสำเร็จทั้งหมด",
    "allSuccessfullyInfo": "เปลี่ยนเป็นรอการตรวจสอบสำเร็จแล้ว",
    "allSuccessfullyCreated": "สร้างสำเร็จทั้งหมด",
    "allReviewFailed": "ไม่ได้ผ่านการตรวจสอบทั้งหมด",
    "shipTime": "เวลาที่ดำเนินการ",
    "allReviewed": "ผ่านการตรวจสอบทั้งหมด",
    "allRecordsOfChannelProductsInfo": "สต็อกสินค้าบนช่องทางร้านค้า（สินค้าบนช่องทางที่เชื่อมต่อกับSKU หลัก ）ได้รับการอัปเดตโดย Ginee ในแพลตฟอร์มร้านค้า (30 วันที่ผ่านมา)",
    "INeed": "ฉันต้องการ",
    "allFailedInfo": "ล้มเหลวทั้งหมด, สถานะยังกำลังตรวจสอบ",
    "allCreateFailed": "สร้างล้มเหลวทั้งหมด",
    "hoursWorking": "ชั่วโมงทำงาน",
    "instant": "ทันที",
    "orderProcessingDeadline": "เวลาสิ้นสุดของคำสั่งซื้อ",
    "all": "ทั้งหมด",
    "toViewTheMassOperationRecords": "รองรับการดูประวัติการดำเนินการใน 7 วันย้อนหลัง ดูทันที",
    "toPublishToTheStoreHow": "วิธี \"เผยแพร่ไปยังร้านค้า\"",
    "toBatchEditingOfMainProducts": "วิธี \"การแก้ไขสินค้าหลักเป็นกลุ่ม\"",
    "thePriceOfTheProductIn": "เชื่อมต่อราคาสินค้าที่เชื่อมต่อกับ Ginee",
    "productSyncronized": "เชื่อมต่อสินค้า",
    "isRecommendedToSynchronizeThePromotion": "ขอแนะนำให้เชื่อมต่อโปรโมชั่นของร้านค้าช่องทางก่อนเพื่อให้แน่ใจว่าราคาของโปรโมชั่นสินค้าสอดคล้องกับศูนย์ผู้ขาย",
    "promotionSync": "เชื่อมต่อโปรโมชั่น",
    "variationIdHasBeenBoundChannel": "id ตัวเลือกสินค้าที่เชื่อมต่อช่องทาง",
    "productInformationMaster": "ข้อมูลของสินค้าหลัก",
    "theInformationOfAnExistingMaster": "คัดลอกข้อมูลของสินค้าหลักที่มีอยู่",
    "productReferenceMaster": "คัดลอกสินค้าหลัก",
    "copy": "คัดลอกผลิตภัณฑ์หลัก",
    "skuAutobindSettingEnabledMaster": "การตั้งค่าเชื่อมต่อSKU หลัก โดยอัตโนมัติ(เปิดใช้งาน)",
    "skuAutobindSettingNotEnableMaster": "การตั้งค่าเชื่อมต่อSKU หลัก โดยอัตโนมัติ(ยังไม่เปิดใช้งาน)",
    "MSKUAutoBindSettings": "การตั้งค่าเชื่อมต่อSKU หลัก โดยอัตโนมัติ",
    "matchingExact": "การจับคู่แม่นยำ",
    "matchingFuzzy": "การจับคู่คลุมเครือ",
    "Choose": "เลือก",
    "stockWarehouse": "สต็อกคลัง",
    "afterTheSafetyInfo": "เมื่อเปิดใช้งานสต็อกปลอดภัย,หากจำนวนสต็อกพร้อมส่งในสินค้าหลักน้อยกว่าสต็อกปลอดภัย, ระบบจะทำการแจ้งเตือนผ่านอีเมล",
    "nowIntegrate": "ไปเชื่อมต่อ",
    "productHasBeenBoundChannel": "เชื่อมต่อสินค้าช่องทางแล้ว",
    "stockSynchronizationSettingsEnable": "เปิดใช้งานการตั้งค่าเชื่อมต่อสต็อก",
    "inboundToWarehouseAlready": "วางเข้าในคลังสินค้าแล้ว",
    "partFailedDelete": "ลบบางส่วนล้มเหลว",
    "allFailedDelete": "ลบทั้งหมดล้มเหลว",
    "allSuccessfulDelete": "ลบทั้งหมดสำเร็จ",
    "close1": "ปิด",
    "productHasAOutboundListTo": "สินค้ามีใบเข้าคลังใบออกคลังแล้ว เพื่อให้แน่ใจว่าข้อมูลครบถ้วน แนะนำให้ใช้ \"ยังไม่ติดตาม\" กับสินค้า",
    "warehouseStockOfThisProductIs": "สต็อกบางส่วนของสินค้านี้ไม่ใช่ 0 กรุณาเว้นว่างก่อนลบ",
    "productHasBeenBoundToA": "สินค้านี้ได้เชื่อมต่อกับสินค้าของคลังฝากสต็อกสินค้าแล้ว  เพื่อให้แน่ใจว่าข้อมูลครบถ้วน แนะนำให้ใช้ \"ยังไม่ติดตาม\" กับสินค้า",
    "afterTheChannelInfo": "หลังจากสินค้าบนแพลตฟอร์มถูกเชื่อมต่อกับสินค้าหลัก, ระบบจัดการสต็อกบนร้านค้าจะเริ่มทำงาน",
    "failedDelete": "ลบล้มเหลว",
    "productBindingStatusChannel": "สถานะการเชื่อมตอสินค้าช่องทาง",
    "boundChannelProductAll": "สินค้าช่องทางที่เชื่อมต่อทั้งหมด",
    "boundChannelProductPartially": "สินค้าช่องทางที่เชื่อมต่อบางส่วน",
    "allChannelProductUnbind": "สินค้าช่องทางที่ไม่ได้เชื่อมต่อทั้งหมด",
    "operationSuccessfulMass": "ดำเนินการเป็นกลุ่มสำเร็จ",
    "toTheMasterProductAnd": "เรียกคืนความติดตามไปที่สินค้าหลักและไม่ทำเครื่องหมายว่า \"ยังไม่ติดตาม\" อีกต่อไป",
    "temporaryUntrackedDisplay": "แสดงเป็นยังไม่ติดตาม",
    "unbindMasterProductAndChannelProduct": "เลิกเชื่อมต่อสินค้าหลักและสินค้าช่องทางเป็นกลุ่ม",
    "recoverMass": "รีเซ็ตเป็นกลุ่ม",
    "temporaryUntrackedMass": "ยังไม่ติดตามเป็นกลุ่ม",
    "unbindMass": "เลิกเชื่อมต่อเป็นกลุ่ม",
    "deleteMass": "ลบเป็นกลุ่ม",
    "group": "กลุ่ม",
    "reference": "อ้างอิง",
    "untrackedTemporary": "ยังไม่ติดตาม",
    "combination": "กลุ่ม",
    "variantMultiple": "ตัวเลือกสินค้าหลายรายการ",
    "addWarehouseStockLimit": "ขอบเขตสต็อกสินค้าควรอยู่ระหว่าง 1-999,999",
    "addVariation": "เพิ่มตัวเลือกสินค้า",
    "addSpareStockLimit": "จำนวนสต็อกต้องอยู่ระหว่าง 0-999,999",
    "supportSelectingMultipleStoresInThe": "รองรับเฉพาะการเลือกร้านค้าหลายร้านในประเทศเดียวกัน",
    "variantNo": "ไม่มีตัวเลือกสินค้า",
    "theProductThatFailedToBe": "ตั้งค่าสินค้าที่ไม่สามารถลบออกเป็นยังไม่ติดตาม ดังนั้นสินค้าหลักจะไม่ปรากฏในรายการ \"ทั้งหมด\"",
    "masterSkuHasBeenBoundBy": "SKU หลักถูกเชื่อมต่อโดยสินค้าที่เป็นเซต กรุณาเลิกเชื่อมต่อและลบออก",
    "masterProductsThatAreSetAs": "สินค้าหลักทั้งหมดที่ถูกตั้งค่าเป็น \"ยังไม่ติดตาม\" (รวมถึงสินค้าประเภทรวมหรือทั่วไป)",
    "typeOfMasterProductCombined": "สินค้าหลักประเภทรวม",
    "productSingle": "สินค้าเดียว",
    "theGeneralOfMasterProductType1": "โดยทั่วไปในประเภทสินค้าหลัก มีสินค้าหลักที่มีประเภทตัวเลือก 1 หรือ 2 รายการ",
    "theGeneralOfMasterProductType": "โดยทั่วไปในประเภทสินค้าหลัก ไม่มีสินค้าหลักของประเภทตัวเลือกสินค้า",
    "notIncludeTheTemporaryUntrackedMaster": "ไม่มีสินค้าหลักที่ \"ยังไม่ติดตาม\"",
    "skuHasBeenBoundByThe": "SKU หลักถูกเชื่อมต่อโดยสินค้าที่เป็นเซต{bundle MSKU} กรุณาเลิกเชื่อมต่อและลบออก",
    "skuHasMatchedTheProductsOf": "SKU หลักตรงกับสินค้าของคลังฝากสต็อกสินค้า {ชื่อคลังฝากสต็อกสินค้า}",
    "skuHasPendingOutboundOrderOr": "SKU หลักมีใบออกคลังหรือใบเข้าคลังที่รอดำเนินการ กรุณาลบออกหลังจากเสร็จสิ้น",
    "stockOfTheMasterSkuIn": "สต็อกคลังสินค้าของ SKU หลักในคลังสินค้า {ชื่อคลังสินค้า 1 ชื่อคลังสินค้า 2} ไม่ใช่ 0 กรุณาอัปเดตเป็น 0 ก่อนลบ",
    "errorPleaseRefreshThePageAnd": "ข้อมูลมีความผิดปกติ โปรดรีเฟรชหน้าแล้วลองอีกครั้ง",
    "retry": "ลองอีกครั้ง",
    "productSuccessfullyCreatedMaster": "สร้างสินค้าหลักสำเร็จแล้ว!",
    "beBetweenMust": "ต้องอยู่ระหว่าง 1-999,999",
    "addOption": "เพิ่ตัวเลือก",
    "stockMonitoringAndSynchronizationEnable": "เปิดใช้งานการตรวจสอบสต็อกและการเชื่อมต่อ",
    "toWarehouseInboundAdd": "เพิ่มในคลังสินค้า + ขาเข้า",
    "channelProductsRefer": "อ้างอิงสินค้าช่องทาง",
    "failedUnbind": "เลิกเชื่อมต่อล้มเหลว",
    "addMskuArticle": "ฉันจะเพิ่มSKU หลัก ได้อย่างไร",
    "unbindAllFailed": "เลิกผูกทั้งหมดล้มเหลว",
    "allUnbindSuccessfully": "ยกเลิกการผูกทั้งหมดเรียบร้อยแล้ว",
    "partialUnbinding": "การเลิกผูกบางส่วนล้มเหลว",
    "unbinded": "ยังไม่เชื่อมต่อ",
    "bound": "เชื่อมต่อแล้ว",
    "unbindedAll": "ไม่เชื่อมต่อทั้งหมด",
    "boundAll": "เชื่อมต่อทั้งหมด",
    "masterSkuHasEnableStockSynchronization": "SKU หลักเปิดใช้งานการเชื่อมต่อสต็อก โปรดไปที่การจัดการสต็อกเพื่อแก้ไขสต็อก",
    "youNeedToEnableStockSynchronization": "หากคุณต้องการเปิดใช้งานการเชื่อมต่อสต็อกของสินค้าหลัก โปรดดำเนินการเพิ่มสินค้าหลักไปยังคลังสินค้าและขาเข้าต่อไป",
    "toProductListReturn": "กลับไปที่รายการสินค้า",
    "failedDataDownload": "ดาวน์โหลดข้อมูลล้มเหลว",
    "createFailedAll": "สร้างทั้งหมดล้มเหลว!",
    "addManually": "สร้างเอง",
    "masterProductSuccessfullyCreatedIfYou": "สร้างสินค้าหลักบางอย่างสำเร็จแล้ว! หากคุณต้องการเปิดใช้งานการเชื่อมต่อสต็อกของสินค้าหลัก กรุณาดำเนินการเพิ่มสินค้าหลักไปยังคลังสินค้าและขาเข้าต่อไป",
    "failedToCreateSome": "สร้างบางส่วนล้มเหลว",
    "masterProductView": "ดูสินค้าหลัก",
    "successfullyCreatedAll": "สร้างสำเร็จทั้งหมด!",
    "productSuccessfullyCreatedIfYouNeed": "สร้างสินค้าหลักสำเร็จแล้ว! หากคุณต้องการเปิดใช้งานการเชื่อมต่อสต็อกของสินค้าหลัก กรุณาดำเนินการเพิ่มสินค้าหลักไปยังคลังสินค้าและขาเข้าต่อไป",
    "productCanOnlyUseOneCurrency": "ผลิตภัณฑ์สามารถใช้ได้เพียงสกุลเงินเดียวเท่านั้น กรุณาเลือกอีกครั้ง!",
    "creatingTheMasterProductCanCompleted": "หลังจากสร้างสินค้าหลักแล้วสามารถดำเนินการขาเข้าและเปิดใช้งานการเชื่อมต่อสต็อก SKU หลักได้\nหลังจากเปิดใช้งาน สต็อกของ SKU หลักและร้านค้าช่องทางสามารถอัปเดตและเชื่อมต่อได้โดยอัตโนมัติ",
    "masterSkuHasBeenPlacedIn": "คลังสินค้าที่วาง SKU หลักที่รวมกันแล้ว",
    "warehouseDefault": "คลังสินค้าค่าเริ่มต้น",
    "toWarehouseAdd": "เพิ่มไปยังคลังสินค้า",
    "toStoreClone": "คัดลอกไปยังร้านค้า",
    "enableStockSynchronizationOfMasterProduct": "หากต้องการเปิดใช้งานการเชื่อมต่อสต็อกของสินค้าหลัก กรุณาดำเนินการเพิ่มไปยังคลังสินค้า และขาเข้า ให้เสร็จสิ้น หากต้องการเผยแพร่สินค้าหลักไปยังช่องทางร้านค้าเพื่อขายอย่างรวดเร็ว โปรดใช้ \"คัดลอกไปยังร้านค้า\"",
    "quicklyPublishTheMasterProductsTo": "หากต้องการเผยแพร่สินค้าหลักไปยังร้านค้าช่องทางเพื่อขายอย่างรวดเร็ว กรุณาใช้ \"คัดลอกไปยังร้านค้า\"",
    "informationWarehouse": "ข้อมูลคลังสินค้า",
    "inbounded": "ขาเข้าแล้ว",
    "inboundNot": "ไม่ขาเข้า",
    "skuHasBeenPlacedInInbound": "คลังสินค้าที่วาง SKU หลัก",
    "statusStock": "สถานะสต็อก",
    "addByStore": "สร้างตามร้านค้า",
    "addBundleProduct": "เพิ่มสินค้าที่เชื่อมต่อ",
    "oneCurrency": "สินค้าสามารถใช้ได้ในสกุลเงินเดียวเท่านั้น โปรดตรวจสอบ",
    "variantSingle": "ตัวเลือกสินค้าเดียว",
    "productsOfVariants": "สินค้า10 ( 3 จาก 20 ตัวเลือกสินค้าสามารถสร้างสินค้าหลักได้)ตัวเลือกสินค้าที่ผูกกับ SKU หลักหรือไม่มี SKU จะสร้าง SKU หลักไม่ได้\nคลิกเพื่อแก้ไขเนื้อหา",
    "synchronizationOfTheMasterSkuWill": "การเชื่อมต่อสต็อกของ SKU หลักจะเปิดใช้งานหลังจากขาเข้าเรียบร้อยแล้ว และสร้างสินค้าหลัก หลังจากเปิดใช้งาน สต็อกของ SKU หลักและร้านค้าช่องทางสามารถอัปเดตและเชื่อมต่อได้โดยอัตโนมัติ",
    "toTheNChannelsProductList": "ไปที่รายการสินค้าของช่องทาง {n} ทันที",
    "channelsProductList": "ไปที่รายการสินค้าของช่อง:",
    "goNow": "ไปเดี๋ยวนี้",
    "goToTheChannelProductList": "โปรดไปที่รายการสินค้าช่องทาง - เลือกสินค้าช่องทางที่คุณต้องการอ้างอิง",
    "doNotHaveAnIntegratedStore": "คุณไม่มีร้านค้าที่เชื่อมต่อ กรุณาเชื่อมต่อก่อน",
    "toModifyGo": "ไปแก้ไข",
    "skuHasBeenInboundToThe": "SKU หลักถูกนำเข้าไปยังคลังสินค้าแล้ว กรุณาไปที่ [การจัดการสต็อกสินค้า] เพื่อแก้ไขสต็อกพร้อมส่ง",
    "productCombined": "มีประเภทตัวเลือกสินค้าอยู่แล้ว กรุณาป้อนอีกครั้ง",
    "combinedProductAdd": "สินค้าที่เป็นเซต",
    "theContentToEditClick": "เพิ่มสินค้าที่เป็นเซต",
    "combinationProductsEdit": "แก้ไขสินค้าที่เป็นเซต",
    "preview": "ดูตัวอย่าง",
    "atTheEndAdd": "เพิ่มในตอนท้าย",
    "increaseStartingValueAutomatically": "เพิ่มมูลค่าที่เริ่มต้นโดยอัตโนมัติ",
    "atTheBeginningAdd": "เพิ่มในตอนต้น",
    "xxWithYyyReplace": "แทนที่ xx ด้วย yyy",
    "massEditSKU": "แก้ไขจำนวนมาก Master SKU",
    "changeStockTo": "แก้ไขสต็อกเป็น",
    "massEditStock": "แก้ไขสต็อกเป็นกลุ่ม",
    "applyToAllProducts": "นำไปใช้กับสินค้าทั้งหมด",
    "masterDeleteFailedTip": "นำผลิตภัณฑ์ออกจากคลังสินค้า {warehouse name 1}, ⁣{warehouse name 2} เรียบร้อยแล้ว",
    "masterStockpdateFailed": "การอัปเดตสต็อคของ Master SKU ที่จัดเก็บไว้ล้มเหลว โปรดไปที่การจัดการสินค้าคงคลังเพื่อแก้ไข",
    "stockLimit": "สต็อกควรอยู่ระหว่าง 0-999,999!",
    "reduceByPercent": "ลดตามเปอร์เซ็นต์",
    "addByPercent": "เพิ่มตามเปอร์เซ็นต์",
    "reduceByAmount": "ลดตามจำนวนเงิน",
    "addByAmount": "เพิ่มตามจำนวนเงิน",
    "massEdit": "แก้ไขเป็นกลุ่ม",
    "mskuNoSpaces": "SKU หลักไม่สามารถมีช่องว่างได้ โปรดอัปเดต",
    "followingXProductsRestore": "ติดตามต่อ{X} รายการ",
    "productsAreSetAsTemporary": "ไม่ติดตามสินค้า {X} อีกต่อไป",
    "masterSkuDoesNotMeetThe": "SKU หลักไม่ตรงตามเงื่อนไขการลบ ดังนั้นจึงไม่สามารถลบประเภทตัวเลือกสินค้าและตัวเลือกทางตัวเลือกสินค้าได้",
    "barcodeExist": "{MSKU} ใช้บาร์โค้ดแล้ว โปรดป้อนใหม่อีกครั้ง",
    "massEditPrice": "แก้ไขราคาเป็นกลุ่ม",
    "changePriceTo": "แก้ไขราคาเป็น",
    "replaceWith": "แทนด้วย",
    "masterSkuLimit": "SKU หลัก สูงสุด200 หลัก",
    "numberOfVariants": "จำนวนตัวเลือกสินค้า",
    "yourCurrentPackageDoesNotHave": "แพ็คเกจปัจจุบันของคุณไม่ได้รับอนุญาตให้ดำเนินการ โปรดสมัครแพ็คเกจใหม่หรือติดต่อลูกค้าเพื่ออัปเกรดแพ็คเกจ",
    "yourNumberOfOrdersHasExceeded": "จำนวนคำสั่งซื้อของคุณเกินขีดจำกัดของแพ็คเกจที่ใช้งานได้ (สูงสุด {X}) โปรดสมัครแพ็คเกจใหม่หรือติดต่อฝ่ายบริการลูกค้าเพื่ออัปเกรดแพ็คเกจ",
    "salesTaxAmountTip": "ค่าภาษีสำหรับสินค้าชิ้นเดียว",
    "purchaseTimeTip": "ระยะเวลาตั้งแต่เริ่มซื้อจนถึงซื้อและขาเข้า",
    "sourceUrlTip": "แหล่งที่มาของสินค้า เช่น การเชื่อมต่อของซัพพลายเออร์หรือลิงก์แหล่งที่มาของสินค้าที่คัดลอก",
    "chatLimitTip2": "สิทธิ์ของคุณเกินขีดจำกัดของแพ็คเกจ กรุณาไปที่ Ginee OMS เพื่อปรับก่อนใช้งาน Ginee chat ต่อไป",
    "chatNoResourcesLimitTip": "จำนวนร้านค้าหรือบัญชีพนักงานของคุณเกินขีดจำกัดของแพ็คเกจ โปรดไปที่ Ginee OMS เพื่อปรับแพ็คเกจก่อนใช้งาน Ginee Chat ต่อไป",
    "chatNopermissionLimitTip": "แพ็คเกจบัญชีของคุณไม่มีฟีเจอร์ Ginee Chat กรุณาติดต่อฝ่ายบริการลูกค้าเพื่ออัปเกรดแพ็คเกจและเข้าสู่ระบบอีกครั้ง",
    "packageLimit": "ข้อจำกัดของแพ็คเกจ",
    "masterProductpublishToTheStore": "สินค้าหลัก/เผยแพร่ไปยังร้านค้า",
    "masterProductmassEdit": "สินค้าหลัก/แก้ไขเป็นกลุ่ม",
    "stockStatusTip": "สถานะสต็อกจะแสดงในคอลัมน์นี้: คลังสินค้า สถานะการเชื่อมต่อสต็อก และข้อมูลประเภท SKU หลัก\nคลังสินค้า: คลังสินค้าที่มีการวาง SKU หลัก\nสถานะการเชื่อมต่อสต็อก: ไม่ว่าจะเปิดใช้งาน SKU หลักสำหรับการเพิ่มและลดสต็อกหรือไม่ \"สถานะการเชื่อมต่อสต็อก\" ของสินค้าหลักที่เพิ่มใหม่จะถูกปิดโดยค่าเริ่มต้น\nเครื่องหมาย SKU หลักเป็นเซต: หาก SKU หลักนี้เป็น SKU หลักเป็นเซต เครื่องหมาย \"เป็นเซต\" จะปรากฏขึ้น",
    "synchronizationIsNotEnabledYouNeed": "ปิด: ไม่ได้เปิดการเชื่อมต่อสต็อก\nไม่ตรงตามเงื่อนไขที่จำเป็นสำหรับการเปิดใช้งาน:",
    "synchronizationIsEnabledStock": "เปิด: เปิดการเชื่อมต่อสต็อก\nเป็นไปตามเงื่อนไขที่จำเป็นสำหรับการเปิดแล้ว:",
    "stockSynchronizationStatusOfTheMaster": "หากไม่ได้เปิดสถานะการเชื่อมต่อสต็อกของ SKU หลัก SKU หลักจะไม่เปิดใช้งานฟังก์ชันเพิ่มหรือลดสต็อกพร้อมกัน",
    "stockSynchronizationStatusOfTheCombined": "หากไม่ได้เปิดใช้งานสถานะการเชื่อมต่อสต็อกของ MSKU ที่เป็นเซต MSKU ที่เป็นเซตจะไม่คำนวณสต็อกพร้อมส่งเป็นกลุ่มโดยอัตโนมัติตาม SKU หลักที่เป็นเซต และฟังก์ชันการเชื่อมต่อสต็อกจะไม่ถูกเปิดใช้งาน",
    "open": "เปิด",
    "settingsManagementForAuthorizedThirdpartyAccounting": "การจัดการการกำหนดค่าสำหรับซอฟต์แวร์บัญชีคลังสินค้าที่สามที่ได้รับอนุญาต",
    "accountingSettings": "การตั้งค่าบัญชี",
    "addedBy": "เพิ่มโดย",
    "timeAdded": "เวลาที่เพิ่ม",
    "updater": "อัพเดทโดย",
    "checkLog": "ตรวจสอบบันทึกการทำงาน",
    "accurateOrder": "คำสั่ง Accurate",
    "accurateProduct": "สินค้า Accurate",
    "failedToGetChannelsLabelUrl": "ไม่สามารถรับการติดฉลากช่องทางได้ กรุณาผลักอีกครั้ง",
    "bindWarehouse": "เชื่อมคลังสินค้า",
    "Prohibited": "ห้ามใช้",
    "interval": "ช่วงเวลา",
    "productSales": "ขายสินค้า",
    "updatetime": "อัพเดทเวลา",
    "masterProductCreationTime": "เวลาในการสร้างสินค้าหลัก",
    "channelProductCreationTime": "เวลาในการสร้างสินค้าช่องทางการขาย",
    "salesOfDifferentActivityLevelsOverlap": "ระดับงานการขายของต่างๆ ทับซ้อนกัน โปรดป้อนอีกครั้ง",
    "salesRangeOfXCannotBe": "ช่วงการขายของ {x} ไม่สามารถสูงกว่า {y}",
    "channelProductDimensionActivitySetting": "การตั้งค่ามิติงานของสินค้าช่องทางการขายปัจจุบันรองรับเฉพาะ Shopee",
    "synchronizeallthexxinthewarehousewheremskusarelocatedtochannelskusaccordingtothexxxratiowhenthedefaultruleisenabledmskusthatalreadyexistinotherrulesarenotincluded": "ซิงโครไนซ์ผลรวมของ XX ในคลังสินค้าที่มี MSKU ทั้งหมดอยู่ใน SKU ของช่องทางทั้งหมดตามสัดส่วนของ XXX เมื่อเปิดใช้กฎเริ่มต้น จะไม่รวม MSKU ที่มีอยู่แล้วในกฎอื่นๆ",
    "availablestockforotherstoresispushedto": "สต็อกที่มีสำหรับร้านค้าอื่น ๆ จะถูกผลักไปที่ 0",
    "allExceptxx": "ทั้งหมดยกเว้น XX",
    "whentheavailablestockislessthanorequaltoxx": "เมื่อสต็อกที่มีอยู่น้อยกว่าหรือเท่ากับ XX",
    "whenmsku": "เมื่อ MSKU",
    "stockThreshold": "เกณฑ์สต๊อก",
    "availablestocktransferstock": "สต๊อกสินค้าที่มี + โอนสต็อก",
    "availablestock": "สต็อกสินค้าที่มี",
    "pleaseSelectawarehouse": "โปรดเลือกคลังสินค้า",
    "addPolicy": "เพิ่มนโยบาย",
    "pushratio": "อัตราส่วนการกด",
    "warehousename": "ชื่อคลังสินค้า",
    "pushbyfixedvalue": "ผลักดันด้วยค่าคงที่",
    "pushbybatio": "ผลักดันตามอัตราส่วน",
    "setSyncPolicy": "กำหนดนโยบายการซิงค์",
    "setSynchronizationconditions": "ตั้งค่าเงื่อนไขการซิงโครไนซ์",
    "selectMsku": "เลือก MSKU",
    "addMsku": "เพิ่ม MSKU",
    "ruleNamecannotbeempty": "ชื่อกฎไม่สามารถเว้นว่างได้",
    "pleaseenterarulename": "โปรดป้อนชื่อกฎ",
    "executeRules": "ปฏิบัติตามกฎ",
    "pushLog": "กดบันทึก",
    "default": "ค่าเริ่มต้น",
    "defaultRules": "กฎเริ่มต้น",
    "storeName1": "ชื่อร้าน",
    "stockType": "ประเภทสต็อก",
    "pushType": "ประเภทการกด",
    "mainStoreStockLocked": "ล็อคสต็อกร้านค้าหลัก",
    "log": "บันทึก",
    "addrule": "เพิ่มกฎ",
    "stocklistpromptwhenadjustingstockstockadjustment": "[รายการสินค้าคงคลัง] แจ้งเมื่อข้ามไปปรับสินค้าคงคลัง: การปรับปรุงสินค้าคงคลัง [(+)/(-)X]",
    "promptwhenaddingstockwithmanualinboundmanualinbound": "การแจ้งเมื่อเพิ่มสินค้าคงคลังในเอกสารสต็อกสินค้าด้วยตนเอง: สต็อกสินค้าด้วยตนเอง【121133】",
    "whenmanuallypushinginventorymanuallyexecutestockpushrules": "เมื่อผลักสินค้าคงคลังด้วยตนเอง: ดำเนินการกฎการผลักสินค้าคงคลังด้วยตนเอง",
    "promptwhentheordercomesinneworder": "เมื่อคำสั่งซื้อเข้ามาระบบจะแจ้งว่า: เพิ่มคำสั่งซื้อ [576512290177977223]",
    "promptwhenorderiscancelledordercanceled": "แจ้งเมื่อคำสั่งซื้อถูกยกเลิก: การยกเลิกคำสั่งซื้อ [576512290177977223]",
    "promptwhenbindingforthefirsttimeenablestockmonitoringandsyncsettingsthenpushdatatostoresstock": "แจ้งเมื่อผูกเป็นครั้งแรก: เปิดใช้งานการตั้งค่าการตรวจสอบสินค้าคงคลังและการซิงโครไนซ์ การจัดเก็บการเริ่มต้นสินค้าคงคลัง",
    "xxofchannelproductsfailed": "ช่องสินค้าล้มเหลวXX",
    "creationtime": "สร้างเมื่อ",
    "pushquantity": "ปริมาณการผลัก",
    "reasonforchange": "สาเหตุที่เปลี่ยน",
    "seemore": "ดูเพิ่มเติม",
    "imageMasterProduct": "รูปภาพ & สินค้าหลัก",
    "partialSuccess": "สำเร็จบางส่วน",
    "storeupdateresults": "ผลการอัพเดทร้านค้า",
    "pleaseentersearchcontent": "โปรดป้อนเนื้อหาการค้นหา",
    "storepushrecord": "บันทึกการผลักร้านค้า",
    "stockValue": "มูลค่าสต็อก",
    "isBestSellingItem": "สินค้าขายดี",
    "isTrendingItem": "สินค้าที่กำลังมาแรง",
    "isNewItem": "สินค้าใหม่",
    "isLowSellingItem": "สินค้ายอดขายต่ำ",
    "isNormalItem": "สินค้าทั่วไป",
    "mskuXAlreadyExistsInRule": "MSKU: {X} มีอยู่แล้วในกฎ {Y}",
    "ruleNameAlreadyExists": "มีชื่อกฎอยู่แล้ว",
    "thereAreDuplicateRuleNameOr": "มีชื่อกฎหรือสินค้าหลักซ้ำกัน",
    "noteWhenTheInventoryThresholdIs": "หมายเหตุ: เมื่อเปิดใช้งานเกณฑ์สินค้าคงคลัง เมื่อสินค้าคงคลังใน \"กฎการซิงค์\" น้อยกว่าหรือเท่ากับ \"เกณฑ์\" สินค้าคงคลังที่มีอยู่ของร้านค้าอื่น ๆ ยกเว้นร้านค้าหลักจะถูกผลักไปที่ 0",
    "totalCapitalUsedFromAllWarehouses": "ทุนรวมถูกครอบครองที่คลังสินค้าทั้งหมด",
    "storeIntegrationIsInvalidPleaseReintegrate": "การเชื่อมต่อร้านค้าไม่ถูกต้อง โปรดเชื่อมต่อใหม่",
    "mskuAndStoreAreNotBound": "MSKU และ ร้านค้ายังไม่เชื่อมต่อ",
    "warehouseAndStoreAreNotBound": "คลังสินค้าและร้านค้ายังไม่เชื่อมต่อ",
    "warehouseAndMskuAreNotBound": "คลังสินค้าและ MSKU ยังไม่เชื่อมต่อ",
    "productChannelAndMskuAreNot": "สินค้าช่องทางการขายและ MSKU ยังไม่เชื่อมต่อ",
    "deleteRule": "คุณแน่ใจหรือว่าต้องการลบกฎ XX?",
    "ruleNameXChangedToY": "ชื่อกฎ: เปลี่ยนจาก {X} เป็น {Y}",
    "pushTypeChangeFromFixedValue": "ประเภทพลัก: เปลี่ยนจากการพลักมูลค่าคงที่เป็นการพลักตามสัดส่วน พลักที่ {X}% ของสต็อกที่มีอยู่ในคลังสินค้า {X}  + คลังสินค้า {y}",
    "pushTypeChangeFromProportionalPush": "ประเภทการพลัก: เปลี่ยนจากการพลักตามสัดส่วนเป็นการพลักค่าคงที่ ค่าคงที่คือ XXX",
    "warehouseNameWarehouseXHasBeen": "ชื่อคลังสินค้า: คลังสินค้า {X} เปลี่ยนเป็น คลังสินค้า {Y}",
    "addRuleNewRule": "เพิ่มกฎ: กฎใหม่",
    "statusChangeStatusHasBeenChanged": "เปลี่ยนสถานะ: สถานะถูกเปลี่ยนจาก ปิด เป็น เปิด",
    "statusChangeStatus": "เปลี่ยนสถานะ: สถานะถูกเปลี่ยนจาก เปิด เป็น ปิด",
    "deleteRuleRuleHasBeenDeleted": "ลบกฎ: กฎถูกลบแล้ว",
    "ruleChangeTheTotalOfStock": "การเปลี่ยนแปลงกฎ: จำนวนสต็อกที่มีอยู่ในคลังสินค้า {X} มีการเปลี่ยนแปลงจาก {X}% เป็น {Y}%",
    "policyChangeTheAvailableStock": "การเปลี่ยนแปลงนโยบาย: สต็อกที่มีอยู่ + สต็อกระหว่างทางของคลังสินค้า {X} มีการเปลี่ยนแปลงจากอัตราส่วนการกด {X}% เป็น {Y}%",
    "editAddedXMskuThresholdIs": "แก้ไข: เพิ่ม {X} MSKU, เกณฑ์คือ\n {X}, เลือกร้านหลักคือ\n: {Y}",
    "editXMskuThresholdChangedFrom": "แก้ไข: {X} เกณฑ์ MSKU เปลี่ยนจาก {Y} เป็น {Z}",
    "editXMskuMainStoreHas": "แก้ไข: ร้านค้าหลัก {X} MSKU ถูกเปลี่ยนจาก {X} เป็น {Y}",
    "editXMskuHasBeenDeleted": "แก้ไข: {X} MSKU ถูกลบไปแล้ว",
    "afterDeletionThePolicyNeedsTo": "หลังจากลบแล้ว จะต้องกำหนดค่านโยบายใหม่ โปรดดำเนินการด้วยความระมัดระวัง",
    "thirdpartyWarehouseReceptionTime": "เวลารับคลังสินค้าบุคคลที่สาม",
    "whetherToReplaceProduct": "ไม่ว่าจะเปลี่ยนสินค้า",
    "whetherItIsAGift": "ไม่ว่าจะเป็นของสมนาคุณ",
    "addGiftBForOrder": "เพิ่มของสมนาคุณ {B] สำหรับคำสั่งซื้อ {A} ปริมาณคือ {C} และคลังสินค้าที่เลือกคือ {D}",
    "orderAWithProductBIs": "คำสั่งซื้อ {A} ด้วยสินค้า {B} จะถูกแทนที่ด้วย {C] และคลังสินค้าที่เลือกคือ {D}",
    "changeSelectedWarehouseCToD": "เปลี่ยนคลังสินค้าที่เลือก {C} เป็น {D} สำหรับคำสั่งซื้อ {A} ที่มีสินค้า {B}",
    "gift": "สมนาคุณ",
    "specifiedWarehouse": "คลังสินค้าที่ระบุ",
    "specifiedQuantity": "ปริมาณที่กำหนด",
    "massAddForOrdersSpecifiedMsku": "เพิ่มจำนวนมากสำหรับคำสั่งซื้อ: ระบุ MSKU และปริมาณ!",
    "replace1": "แทนที่",
    "and1": "และ",
    "replaceInBatchForTheSpecified": "เปลี่ยนสินค้าเป็นชุดตามคำสั่งซื้อ!",
    "changeWarehouseTo": "เปลี่ยนคลังสินค้าเป็น",
    "specifiedProduct": "สินค้าที่ระบุ",
    "doWarehouseModificationForSpecifiedProduct": "แก้ไขคลังสินค้าสำหรับสินค้าที่กำหนดภายใต้คำสั่งซื้อ! (สินค้าที่แก้ไขต้องเชื่อมโยงกับคลังสินค้าที่เกี่ยวข้อง)",
    "doWarehouseModificationForAllProduct": "แก้ไขคลังสินค้าทุกชิ้นภายใต้คำสั่งซื้อ! (สินค้าที่แก้ไขต้องเชื่อมโยงกับคลังสินค้าที่เกี่ยวข้อง)",
    "byProduct": "ตามสินค้า",
    "byOrder": "ตามคำสั่งซื้อ",
    "processingMethod": "วิธีการประมวลผล",
    "addGift": "เพิ่มของขวัญ",
    "replaceProduct": "เปลี่ยนสินค้า",
    "changeWarehouse": "เปลี่ยนคลังสินค้า",
    "modifyProductInformation": "แก้ไขข้อมูล",
    "weightkg": "นำ้หนัก(kg)",
    "youMustAddAllTheVariation": "คุณต้องเพิ่มรูปภาพรูปตัวเลือกสินค้าทั้งหมดหรือเว้นว่างไว้ทั้งหมด",
    "masterVariationName": "ชื่อตัวเลือกสินค้าหลัก",
    "operationSuccessPrompt": "แจ้งความสำเร็จในการดำเนินงาน",
    "checkProduct": "ตรวจสอบสินค้า",
    "productHasBeenSubmittedAndIs": "ส่งสินค้าแล้วและกำลังรอให้ {channel} ดำเนินการ คุณสามารถตรวจสอบสินค้าได้ที่หน้ากำลังดำเนินการ, เผยแพร่ หรือ การเผยแพร่ล้มเหลว",
    "minimumStockLimit": "สต็อกขั้นต่ำควรอยู่ระหว่าง 0-999,999",
    "noNeedToProvideAnyTransport": "ไม่จำเป็นต้องมีบริการจัดส่งใดๆ เช่นใบปะหน้าพัสดุ บริการนอกสถานที่ บริการจุดรับพัสดุ ฯลฯ",
    "thisTypeCanBeSelectedTo": "เลือกประเภทนี้เพื่อจัดส่งสินค้าที่มีน้ำหนักมากกว่า 50 kg หรือสินค้าที่ต้องการการดำเนินการเป็นพิเศษ เช่น การติดตั้ง",
    "notShipped": "ไม่จัดส่ง",
    "sellerShipping": "การจัดส่งของผู้ค้า",
    "shipYourOrderToTheBLIBLI": "จะจัดส่งคำสั่งซื้อของคุณไปยังพาร์ทเนอร์การจัดส่งที่ได้รับมอบหมายจาก Blibli สำหรับ จัดส่งภายในวันเดียวกัน / ทันที คำสั่งซื้อจะถูกหยิบขึ้นมาในจุดที่ตรึงไว้",
    "shippingUsingBlibliLogisticPartner": "การจัดส่งโดยใช้พาร์ทเนอร์การจัดส่งของ Blibli",
    "minimumStock": "สต็อกชั้นตำ่",
    "enterSellingPrice": "กรุณากรอกราคาขาย",
    "sellingPriceLimit": "ราคาขายควรอยู่ระหว่าง 1-100.000.000",
    "originalPrice": "ราคาเดิม",
    "enterOriginalPrice": "กรุณากรอกราคาเดิม",
    "changeMinimumStockTo": "แก้ไขสต็อกขั้นต่ำเป็น",
    "restrictionsTheProductCannotBeEdited": "เนื่องจากข้อจำกัดของ API สินค้านี้จึงไม่สามารถแก้ไขได้",
    "unboundMSKU": "ยกเลิกการเชื่อมต่อ SKU หลัก",
    "bindMSKU": "เชื่อมต่อ SKU หลัก",
    "Country": "ประเทศ/ภูมิภาค",
    "ContinuetoPublish": "ดำเนินการเผยแพร่",
    "CreateTimeNewest": "สร้างเวลาจากล่าสุดไปเก่าที่สุด",
    "CreateTimeOldest": "สร้างเวลาจากเก่าที่สุดไปล่าสุด",
    "notification": "การแจ้งเตือน!",
    "NoBrand": "ไม่มียี่ห้อ",
    "PromotionActivity": "กิจกรรม",
    "PromotionCampaign": "แคมเปญ",
    "PromotionDuration": "เวลา",
    "PromotionPrice": "ราคาโปรโมชั่น",
    "PromotionOriginalPrice": "ราคาเดิม",
    "cannot0": "ผู้มีโอกาสเป็นผู้ซื้อไม่สามารถเห็นสินค้าของคุณได้เนื่องจากมีสต็อกเป็น 0",
    "EditStock": "แก้ไขสต็อก",
    "ResultsFail": "การดำเนินการเป็นกลุ่มหยุดลงเนื่องจากปัญหาเครือข่ายหรือเซิร์ฟเวอร์ ลองอีกครั้งในภายหลัง",
    "SomeOperationsFailed": "ดำเนินการสินค้าบางรายการล้มเหลว",
    "Sort": "เรียงตาม",
    "MostStock": "สต็อกมากที่สุด",
    "LeastStock": "สต็อกน้อยที่สุด",
    "Oldest": "อัปเดตเวลาจากใหม่สุดไปยังเก่าสุด",
    "Newest": "อัปเดตเวลาจากเก่าสุดไปยังใหม่สุด",
    "Within7Days": "ภายใน 7 วัน",
    "Yesterday": "เมื่อวาน",
    "Today": "วันนี้",
    "CountDraftFailed": "เผยแพร่สินค้าล้มเหลว",
    "CountDraftSuccess": "เผยแพร่สินค้าสำเร็จ",
    "CountRemoveFailed": "ลบสินค้าล้มเหลว",
    "CountRemoveSuccess": "ลบสินค้าสำเร็จ",
    "CountUnAvailableFailed": "สินค้าเลิกจำหน่ายล้มเหลว",
    "CountUnAvailableSuccess": "สินค้าเลิกจำหน่ายสำเร็จ",
    "CountAvailableFailed": "เผยแพร่สินค้าล้มเหลว",
    "CountAvailableSuccess": "เผยแพร่สินค้าสำเร็จ",
    "PartDraftFailed": "เผยแพร่สินค้าบางรายการล้มเหลว！",
    "DraftFailed": "เผยแพร่ล้มเหลว",
    "DraftSuccess": "เผยแพร่สำเร็จ！",
    "PartRemoveFailed": "ลบสินค้าบางรายการล้มเหลว",
    "RemoveFailed": "ลบล้มเหลว",
    "RemoveSuccess": "ลบสำเร็จ",
    "PartUnAvailableFailed": "เลิกจำหน่ายสินค้าบางรายการล้มเหลว",
    "UnAvailableFailed": "เลิกจำหน่ายล้มเหลว",
    "UnAvailableSuccess": "เลิกจำหน่ายสำเร็จ",
    "PartAvailableFailed": "เผยแพร่สินค้าบางรายการล้มเหลว",
    "AvailableFailed": "เผยแพร่ล้มเหลว",
    "AvailableSuccess": "เผยแพร่สำเร็จ",
    "InProcessDraftPublishModalTitle": "เผยแพร่สินค้าเป็นกลุ่มไปยังร้านค้า",
    "InProcessRemoveModalTitle": "กำลังลบสินค้าบนร้านค้า",
    "InProcessDisableModalTitle": "ตั้งค่าสินค้าที่ยังไม่มีขายบนร้านค้า",
    "InProcessPublishModalTitle": "ตั้งค่าสินค้าที่จะขายบนร้านค้า",
    "StockRulesInfo": "สต็อกควรอยู่ระหว่าง {minNum}～999999",
    "ResultsClose": "ปิด",
    "ResultsTitle": "ผลลัพธ์",
    "ApplyToAll": "ใช้งานกับทั้งหมด",
    "MassEditStock": "แก้ไขเป็นกลุ่ม",
    "SetUnAvailable": "เลิกจำหน่าย",
    "SetAvailable": "เผยแพร่สินค้า",
    "DeletedDesc": "การลบสินค้าใน Ginee จะลบสินค้าในร้านค้าช่องทางนี้ด้วย",
    "DeletedTitle": "คุณแน่ใจหรือไม่ว่าจะลบสินค้านี้?",
    "PublicDesc": "การตั้งค่าเผยแพร่สินค้านี้ใน Ginee จะทำให้สินค้าเผยแพร่ในร้านค้าช่องทางนี้ด้วย",
    "PublicTitle": "คุณแน่ใจหรือไม่ว่าจะเผยแพร่สินค้านี้?",
    "DisabledDesc": "การตั้งค่าเลิกจำหน่ายสินค้านี้ใน Ginee จะทำให้สินค้าเลิกจำหน่ายบนช่องทางร้านค้านี้ด้วย",
    "DisabledTitle": "คุณแน่ใจหรือไม่ว่าจะเลิกจำหน่ายสินค้านี้?",
    "productTime": "เวลา",
    "ProductSelected": "สินค้าที่เลือกไว้",
    "ProductParentSku": "SKUหลัก",
    "ProductNameAndStore": "ชื่อสินค้า&ร้านค้า",
    "adjustSyncFailed": "การเชื่อมต่อสต็อกล้มเหลว กรุณาลองใหม่",
    "UpdateStock": "อัปเดต",
    "NewStock": "สต็อกใหม่",
    "ModifyStock": "แก้ไขสต็อกในการจัดการสต็อกคลังสินค้า",
    "CurrentStock": "สต็อกปัจจุบัน",
    "adjustStock": "ปรับสต็อก",
    "categories": "หมวดหมู่",
    "removeList": "ลบ",
    "productStatus": "สถานะ",
    "savedAsdraft": "บันทึกเป็นฉบับร่างสำเร็จ",
    "pleaseSelect": "กรุณาเลือก",
    "ProductDescription": "คำอธิบายของสินค้า",
    "masterCategory": "หมวดหมู่หลัก",
    "ProductImage": "รูปภาพหลักของสินค้า",
    "defaultPriceError": "ขอบเขตราคาขายโดยค่าเริ่มต้นควรอยู่ระหว่าง0-1000,000,000",
    "editDefaultPrice": "แก้ไขราคาโดยค่าเริ่มต้น",
    "editDescription": "แก้ไขคำอธิบาย",
    "addAfterName": "เพิ่มส่วนท้ายของชื่อ",
    "addBeforeName": "เพิ่มที่จุดเริ่มต้นของชื่อ",
    "weightShouldBeBetweenKg": "น้ำหนักควรอยู่ระหว่าง 0.001-1000",
    "workingHours": "ชั่วโมงทำงาน",
    "shippingSettings": "การตั้งค่าการจัดส่ง",
    "massEditProductStatus": "แก้ไชสถานะสินค้าเป็นกลุ่ม",
    "goToEdit": "ไปที่แก้ไข",
    "theInventoryOfTheMasterProduct": "การจัดการสต็อกของสินค้าหลักอยู่ใน [การจัดการสต็อก] กรุณาไปแก้ไขสต็อกของสินค้าที่เลือก",
    "changeMinPurchaseQuantityTo": "แก้ไขจำนวนการซื้อขั้นต่ำเป็น",
    "massEditMinPurchaseQuantity": "แก้ไขจำนวนการซื้อขั้นต่ำเป็นกลุ่ม",
    "massEditDimensionsCm": "แก้ไขขนาดเป็นกลุ่ม (cm)",
    "massEditProductName": "แก้ไขชื่อสินค้าเป็นกลุ่ม",
    "editProductImage": "แก้ไขรูปภาพของสินค้า",
    "customDisplayInformation": "ข้อมูลการแสดงที่กำหนดเอง",
    "logisticsChoose": "เลือกการจัดส่ง",
    "selectionOfStoreswarehousesMass": "เลือกร้านค้า/คลังสินค้า",
    "toMatchCategoryNeed": "ต้องจับคู่หมวดหมู่",
    "categoriesMatched": "จับคู่หมวดหมู่แล้ว",
    "productsSelected": "สินค้าที่เลือก",
    "categoryAttributesShopee": "ข้อมูลจำเพาะหมวดหมู่ของ Shopee",
    "categoryAttributesEdit": "ข้อมูลจำเพาะหมวดหมู่",
    "theValueJustEnteredWantIt": "ค่าที่เพิ่งกรอกต้องการใช้กับสินค้าทั้งหมดในหมวดหมู่เดียวกันหรือไม่",
    "matchingCategoryAndVariantAttributesProduct": "หลังจากจับคู่ข้อมูลจำเพาะหมวดหมู่และตัวเลือกสินค้าแล้ว ข้อมูลสินค้าจะถูกจับคู่โดยอัตโนมัติ เช่น ราคาตัวเลือกสินค้า สินค้าสต็อก ฯลฯ",
    "productInfoSource": "แหล่งที่มาข้อมูลสินค้า",
    "noLogisticOptions": "ยังไม่มีตัวเลือกการจัดส่ง",
    "sellingPrice": "ราคาขาย",
    "productUpdateFailed": "อัปเดตสินค้าล้มเหลว",
    "publishFailed": "เผยแพร่สินค้าล้มเหลว",
    "publishSucceeded": "เผยแพร่สินค้าล้มสำเร็จ",
    "massEditMinimumStock": "แก้ไขสต็อกขั้นต่ำเป็นกลุ่ม",
    "skuLimit": "SKU ควรมีความยาวน้อยกว่า 100 หลัก",
    "sku": "SKU",
    "packageSizeCM": "ขนาดพัสดุ(cm)",
    "main": "รูปภาพหลัก",
    "maxval": "สูงสุด {val} รูป",
    "priceBetweenMy": "ต้องอยู่ระหว่าง0.1-1.000.000.000",
    "priceBetweenTh": "ต้องอยู่ระหว่าง1-500.000",
    "priceBetweenVn": "ต้องอยู่ระหว่าง1000-120.000.000",
    "priceBetweenPh": "ต้องอยู่ระหว่าง5-1.000.000",
    "priceBetweenId": "ต้องอยู่ระหว่าง99-150,000,000",
    "weightg": "นำ้หนัก(g)",
    "selectABrandPlease": "กรุณาเลือกยี่ห้อ",
    "uploadAVariantPicturePlease": "โปรดอัปโหลดรูปภาพแบบต่างๆ",
    "uploadProductImagePlease": "กรุณาอัพโหลดภาพสินค้า",
    "enterADescriptionPlease": "กรุณาใส่คำอธิบาย",
    "enterTheProductNamePlease": "กรุณากรอกชื่อสินค้า",
    "selectTheStatusOptionPlease": "โปรดเลือกตัวเลือกสถานะ",
    "selectPreorderOptionPlease": "โปรดเลือกตัวเลือกการสั่งจองล่วงหน้า",
    "enterLogisticsOptionsPlease": "โปรดป้อนตัวเลือกด้านลอจิสติกส์",
    "enterThePackageSizePlease": "กรุณาระบุขนาดบรรจุภัณฑ์",
    "enterTheWeightPlease": "กรุณาใส่น้ำหนัก",
    "enterstock": "กรุณาใส่สต็อก",
    "enterPrice": "กรุณาใส่ราคา",
    "enterSKU": "กรุณากรอก SKU",
    "theProductHasVariationsIfYour": "สินค้ามีตัวเลือกหลากหลาย หากสินค้าของคุณมีสี ขนาดแตกต่างกัน กรุณาเลือกข้อนี้",
    "enterAOptionForExampleRed": "เลือกตัวเลือก เช่น สีแดง ฯลฯ",
    "addVariationType": "เพิ่มประเภทตัวเลือกสินค้า",
    "variationType": "ประเภทตัวเลือกสินค้า",
    "productDescriptionLimit": "คำอธิบายสินค้าต้องมีความยาวระหว่าง 6-3500 หลัก",
    "productDescriptionLimitTh": "คำอธิบายสินค้าต้องมีความยาวระหว่าง25- 5000  หลัก",
    "productDescriptionLimitVn": "คำอธิบายสินค้าต้องมีความยาวระหว่าง 100- 3000  หลัก",
    "productDescriptionLimitPh": "คำอธิบายสินค้าต้องมีความยาวระหว่าง 2- 3000  หลัก",
    "productDescriptionLimitIdMy": "คำอธิบายสินค้าต้องมีความยาวระหว่าง20- 3000  หลัก",
    "selectBrand": "เลือกแบรนด์",
    "ShopeeCategory": "หมวดหมู่ Shopee",
    "addAttributeValue": "เพิ่มค่าของข้อมูลจำเพาะ",
    "saveUpdateAndNextStep": "บันทึกและขั้นตอนต่อไป",
    "productNameLimitVn": "ชื่อสินค้าต้องอยู่ระหว่าง 10- 120 อักษร",
    "productNameLimitPh": "ชื่อสินค้าต้องอยู่ระหว่าง 20-100อักษร",
    "productNameLimitOthers": "ชื่อสินค้าต้องอยู่ระหว่าง 20-120อักษร",
    "productNameLimitId": "ชื่อสินค้าต้องอยู่ระหว่าง 5-255อักษร",
    "stocktLimitOhters": "สต็อกควรอยู่ระหว่าง 0-999.999.999!",
    "stocktLimit": "สต็อกควรอยู่ระหว่าง 0-999,999!",
    "setUnavailable": "เลิกจำหน่าย",
    "setAvailable": "วางจำหน่าย",
    "saveAsDraft": "บันทึกเป็นฉบับร่าง",
    "percentageLimit": "ขอบเขตเปอร์เซ็นควรอยู่ระหว่าง 0-100,000",
    "attributesNo": "ไม่มีข้อมูลจำเพาะ",
    "categoryNo": "ไม่มีหมวดหมู่",
    "categoryAndAttributeApply": "ใช้ตัวเลือกหมวดหมู่และแอตทริบิวต์เดียวกันกับผลิตภัณฑ์อื่นในหมวดหมู่แหล่งที่มาเดียวกัน ({N}) หรือไม่",
    "detailsView": "ดูรายละเอียด",
    "pleaseSetUpLogisticsInThe": "กรุณาตั้งค่าการจัดส่งในศูนย์ผู้ขายของ Shopee",
    "optionMaximum": "สูงสุด 20 หลัก",
    "typeMaximum": "สูงสุด 14 หลัก",
    "vietnam": "เวียดนาม",
    "thailand": "ประเทศไทย",
    "philippines": "ฟิลิปปินส์",
    "indonesia": "อินโดนีเซีย",
    "imageMustBeJOGPNGFormat": "รูปภาพต้องเป็นรูปแบบ JOG, PNG, สูงสุด 4MB ความละเอียด: 300*300px～1024*1024px",
    "imageTip": "ขีดจำกัด API ,ขนาด: สูงสุด 4MB ความละเอียดต้องมากกว่า 300*300px",
    "preOrderLimit": "ตามข้อกำหนดของหมวดหมู่ เวลาควรอยู่ที่ {min} ถึง {max} วันทำการ",
    "preOrderTip": "Star, Star+, Mall รองรับสูงสุด 90 วันทำการ",
    "malaysia": "มาเลเซีย",
    "dangerousGoods": "สินค้าอันตราย",
    "dangerousGoodsTip": "กรุณากรอกข้อมูลสินค้าอันตรายให้ถูกต้อง สินค้าอันตรายที่ไม่ถูกต้องอาจส่งผลให้มีค่าจัดส่งเพิ่มเติมหรือการจัดส่งล้มเหลว",
    "dangerousGoodsOption": "ประกอบด้วยแบตเตอรี่/แม่เหล็ก/ของเหลว/วัสดุไวไฟ",
    "attributeTip": "ขีดจำกัด API สูงสุด 5 ตัวเลือก",
    "massEditTip1": "โปรดเลือกประเภทผลิตภัณฑ์และคุณลักษณะของผลิตภัณฑ์เพื่อหลีกเลี่ยงความล้มเหลวของผลิตภัณฑ์ที่จะเผยแพร่",
    "theMaximumRangeAccordingToApi": "จำกัดช่วงสูงสุดตามข้อกำหนดของ API",
    "requiresToThisInformationToBe": "API กำหนดให้ต้องกรอกข้อมูลนี้",
    "chooseTheStoreFirstPlease": "กรุณาเลือกร้านค้าก่อน",
    "variantImageTip": "ขนาด: 4MB ความละเอียด: Min.300*300px(API กำหนดให้ต้องกรอกข้อมูลนี้)",
    "variationTypeTip2": "มีประเภทตัวแปรอยู่แล้ว โปรดป้อนอีกครั้ง",
    "variationoptionTip1": "ตัวเลือกตัวเลือกมีอยู่แล้ว โปรดป้อนอีกครั้ง",
    "applyAttribute": "ใช้ตัวเลือกแอตทริบิวต์เดียวกันกับผลิตภัณฑ์อื่นๆ ในหมวดหมู่แหล่งที่มาเดียวกัน({N})หรือไม่",
    "noBrandBatch": "คุณแน่ใจหรือไม่ว่าจะเปลี่ยนตัวเลือกแบรนด์ของรายการผลิตภัณฑ์นี้เป็น \"ไม่มีแบรนด์\"",
    "noBrandBatchTip": "เนื่องจากข้อจำกัดของ API เฉพาะแบทช์เท่านั้นที่สามารถเลือกเป็น No Brand.",
    "massEditBrand": "แก้ไขแบรนด์เป็นกลุ่ม",
    "previous": "ก่อนหน้า",
    "emptyMass": "ว่างเปล่าเป็นกลุ่ม",
    "singapore": "สิงคโปร์",
    "canUploadMultiplePicturesAtOnce": "คุณสามารถอัปโหลดรูปภาพได้หลายภาพพร้อมกัน ลากและวางเพื่อเปลี่ยนลำดับรูปภาพ",
    "contentToEditClick": "คลิกเนื้อหาเพื่อแก้ไข",
    "Policydetail": "เมื่อสินค้าเดียวกันเผยแพร่ไปยังร้านค้าหลายร้านบนช่องทางเดียวกัน\nเราขอแนะนำให้คุณเปลี่ยนแปลงและแก้ไขชื่อและข้อมูลสินค้าเล็กน้อยเพื่อหลีกเลี่ยงการละเมิดนโยบายบน Marketplace",
    "PolicyReminder": "แจ้งเตือนเรื่องนโยบาย",
    "Clonesuccess": "คัดลอกสินค้าสำเร็จ!",
    "copyFailedResult": "ผลิตภัณฑ์ไม่สามารถโคลน",
    "copyCompete": "คัดลอกสำเร็จ",
    "amountMustBeGreaterThanDiscount": "ราคาต้องมากกว่าจำนวนส่วนลด",
    "wholeStoreProduct": "สินค้าจากทั้งร้าน",
    "valueMustBeGreaterThanThe": "ค่าต้องมากกว่าการไล่ระดับสีก่อนหน้า",
    "theSumOfTheAvailableStock": "ผลรวมของสต็อกที่มีอยู่ในคลังสินค้าที่ MSKU ทั้งหมดตั้งอยู่ ตาม",
    "ratioSyncToAllChannelSku": "อัตราส่วนซิงค์กับทุกช่องทาง SKU",
    "whenthedefaultruleisonmskusthatalreadyexistinotherrulesarenotincluded": "เมื่อกฎเริ่มต้นเปิดอยู่ จะไม่รวม MSKU ที่มีอยู่แล้วในกฎอื่น",
    "statusDeleted": "ลบแล้ว",
    "statusInactive": "ปิดใช้งาน",
    "statusActive": "เปิดใช้งาน",
    "optionsDoNotSupportEditAnd": "เนื่องจากข้อจำกัด API ตัวเลือกไม่รองรับการแก้ไขและลบ",
    "dueToApiRestrictionsLabel": "เนื่องจากข้อจำกัดของ API จำเป็นต้องมีประเภทตัวเลือกสินค้า { label } ภายใต้หมวดหมู่นี้",
    "dueToTheChannelDelayIn": "เนื่องจากความล่าช้าในการอัปเดตข้อมูลช่องทางการขาย ข้อมูลที่แก้ไขจะใช้เวลาสองสามวินาทีในการอัปเดต โปรดอดใจรอ หากยังไม่มีการอัปเดต คุณสามารถคลิกปุ่มเชื่อมต่อด้วยตนเองหรือไปที่ศูนย์ผู้ขายเพื่อตรวจสอบว่าอยู่ในสถานะตรวจทานหรือไม่ ข้อมูลในสถานะตรวจสอบจะไม่เชื่อมต่อในขณะนี้",
    "deletedProductCannotBeRecoveredAre": "สินค้าที่ลบไปแล้วไม่สามารถกู้คืนได้ คุณต้องการลบหรือไม่",
    "canNotInputChineseLanguage": "ไม่สามารถป้อนภาษาจีนได้",
    "areYouSureYouWantToZalora": "คุณแน่ใจหรือไม่ว่าต้องการลบข้อมูลตัวเลือกสินค้านี้ การลบจะเปลี่ยนโครงสร้าง SKU ข้อมูลราคา และข้อมูลสต็อก คุณต้องกรอกข้อมูลที่เกี่ยวข้องของ SKU อีกครั้ง",
    "failedToPublishGineeProductThat": "เผยแพร่ล้มเหลว: สินค้า Ginee ที่เผยแพร่ล้มเหลว",
    "publishingGineeProductThatAreBeing": "กำลังเผยแพร่:สินค้า Ginee ที่กำลังเผยแพร่หรือสินค้าอยู่ระหว่างรีวิวในศูนย์ผู้ขาย",
    "draftAProductDraftCreatedBy": "แบบร่าง: ร่างสินค้าที่สร้างขึ้นโดย Ginee",
    "suspendedThisIsAProductWith": "ถูกระงับ: นี่คือสินค้าที่มีสถานะถูกระงับในศูนย์ผู้ขาย",
    "soldOutThisIsAProduct": "หมด: นี่คือสินค้าที่มี 0 สต็อกพร้อมสถานะขายอยู่/ปิดใช้งานในศูนย์ผู้ขาย",
    "deactivatedThisIsAProductWith": "ไม่ได้ใช้งาน: นี่คือสินค้าที่มีสถานะปิดใช้งานในศูนย์ผู้ขาย",
    "liveThisIsAProductWith": "ขายอยู่：นี่คือสินค้าที่มีสถานะขายอยู่ในศูนย์ผู้ขาย",
    "currencyCannotBeEmpty": "สกุลเงินไม่สามารถเว้นว่างได้",
    "failedToUploadQualification": "ไม่สามารถอัปโหลดใบรับรองคุณสมบัติ {0}",
    "failedToUploadImage": "อัปโหลดรูปภาพ {0} ล้มเหลว",
    "thereCannotBeTheSameOption": "ภายใต้ประเภทตัวเลือกสินค้ากลุ่มเดียวกันไม่สามารถมีตัวเลือกที่ซ้ำกัน",
    "numberOfVariationsInEachGroup": "จำนวนประเภทตัวเลือกสินค้าในแต่ละกลุ่มควรเป็น {0}-{1}",
    "numberOfGroupsVariationMustBe": "จำนวนกลุ่มของประเภทตัวเลือกสินค้าควรเป็น {0}-{1}",
    "qualificationCertificateOfTheCurrentProduct": "ต้องมีใบรับรองคุณสมบัติในช่องหมวดหมู่สินค้าปัจจุบันและไม่สามารถเว้นว่างได้",
    "forOneTypeOfQualificationCertificate": "สำหรับใบรับรองคุณสมบัติประเภทเดียว จำนวนไฟล์และรูปภาพทั้งหมดต้องไม่เกิน {0}",
    "apiLimitationMaxSizeMb": "เนื่องจากข้อจำกัดของ API ขนาดสูงสุดคือ 5MB และความละเอียดต้องมากกว่า 600*600px",
    "totalSkuMustBeBetween": "จำนวน SKU ทั้งหมดต้องอยู่ระหว่าง 1 ถึง 100",
    "pleaseSelectTheTypeOfVariation": "กรุณาเลือกประเภทตัวเลือกสินค้า",
    "pleaseInputWeight": "กรุณากรอกน้ำหนัก",
    "pleaseInputLengthWidthAndHeight": "กรุณากรอกข้อมูลความยาว ความกว้าง และความสูงให้ครบถ้วน",
    "reviewing": "กำลังตรวจสอบ",
    "processing": "กำลังดำเนินการ",
    "reviewFailed": "การตรวจสอบล้มเหลว",
    "thisCategoryDoesNotSupportCod": "หมวดหมู่นี้ไม่รองรับเก็บเงินปลายทาง",
    "skuMustBeCharacters": "SKU ต้องมีอักขระ 1-50 หลัก",
    "warrantyPolicyMustBeCharacters": "กฎการรับประกันต้องมีอักขระ 0-99 หลัก",
    "priceRangeShouldBe": "ช่วงราคาควรเป็น 100-100000000",
    "stocktLimitOhtersZalora": "สต็อกควรอยู่ระหว่าง 0-99999",
    "weightShouldBeBetweenKgZalora": "น้ำหนักควรอยู่ระหว่าง 0.01-20",
    "heightShouldBeBetweenZalora": "ความสูงควรอยู่ระหว่าง 1-35",
    "widthShouldBeBetweenZalora": "ความกว้างควรอยู่ระหว่าง 1-40",
    "lengthShouldBeBetweenZalora": "ความยาวควรอยู่ระหว่าง 1-60",
    "pleaseUploadASizePicture": "กรุณาอัปโหลดรูปภาพขนาด",
    "onlySupportsImages": "รองรับเฉพาะขนาดรูปภาพเป็น 1:1",
    "pleaseEnterAtLeastOneVariation": "โปรดกรอกตัวเลือกสินค้าย่างน้อยหนึ่งรายการ",
    "variationOptionMustBeCharacters": "ตัวเลือกสินค้าต้องมีอักขระ 1-20 หลัก",
    "productDescriptionLimitIdMyZalora": "คำอธิบายสินค้าต้องมีความยาวระหว่าง1- 10000  หลัก",
    "fileSizeCannotExceedM": "ขนาดไฟล์ต้องไม่เกิน 10M",
    "onlySupportToUploadFilesIn": "รองรับการอัปโหลดไฟล์รูปแบบ pdf เท่านั้น",
    "onlySupportToUploadImagesWith": "รองรับการอัปโหลดรูปภาพรูปแบบ jpg, png, jpeg เท่านั้น",
    "imageMustBePxpx": "รูปภาพจะต้องมีขนาด 600*600px-20000*20000px",
    "pictureSizeCannotExceedM": "ขนาดรูปภาพไม่เกิน 5M",
    "pleaseUploadAtLeastOneImage": "โปรดอัปโหลดรูปภาพอย่างน้อยหนึ่งภาพหรือไฟล์ pdf",
    "supportCOD": "สนับสนุน COD",
    "sizeChart": "ขนาดแผนภูมิ",
    "pleaseSelectTheVariantTypeAnd": "กรุณาเลือกประเภทตัวเลือสินค้าและตัวเลือกก่อน",
    "warrantyPolicy": "นโยบายการรับประกัน",
    "warrantyPeriod": "ระยะเวลาการรับประกันสินค้า",
    "qualificationCertificate": "ใบรับรองคุณสมบัติ",
    "productNameLimitIdZalora": "ชื่อสินค้าต้องอยู่ระหว่าง 1-1885อักษร",
    "zaloraCategory": "หมวดหมู่ Zalora",
    "numberOfComments": "จำนวนความคิดเห็น",
    "keywordType": "กรอง",
    "sortType": "เรียงตาม",
    "number": "ลำดับ",
    "newRuleMasterProduct": "กฎใหม่ {0}; สินค้าหลัก {1}",
    "deleteRuleMasterProduct": "ลบกฎ {0}; สินค้าหลัก {1}",
    "storePolicyAddPolicyNew": "นโยบายร้านค้า {0}; เพิ่มนโยบาย: เพิ่มนโยบายใหม่",
    "storePolicyDeletePolicyPolicy": "นโยบายร้านค้า {0}; ลบนโยบาย: นโยบายถูกลบ",
    "ruleNameChangedFromTo": "ชื่อกฎ: เปลี่ยนจาก {0} เป็น {1}",
    "masterProductNameChangedFrom": "ชื่อสินค้าหลัก: เปลี่ยนจาก {0} เป็น {1}",
    "pushTypeNameChangedFrom": "ชื่อประเภทการผลัก: เปลี่ยนจาก {0} เป็น {1}",
    "storePolicyWarehouseNameChanged": "นโยบายร้านค้า {0}; ชื่อคลังสินค้า: เปลี่ยนจาก คลังสินค้า {1} เป็นคลังสินค้า {2}",
    "storePolicyInventoryTypeName": "นโยบายร้านค้า {0}; ชื่อประเภทสต็อก: เปลี่ยนจาก {1} เป็น {2}",
    "storePolicyStoreNameChanged": "นโยบายร้านค้า {0}; ชื่อร้านค้า: เปลี่ยนจาก {1} เป็น {2}",
    "storeStrategyPushRatioChanged": "นโยบายร้านค้า {0}; อัตราส่วนการผลัก: เปลี่ยนจาก {1} เป็น {2}",
    "storePolicyPushFixedValue": "นโยบายร้านค้า {0}; ดันค่าคงที่: เปลี่ยนจาก {1} เป็น {2}",
    "addmskuthresholdIsselectmainstoreIs": "เพิ่ม {0} MSKU เกณฑ์คือ {1} เลือกร้านค้าหลักคือ: {2}",
    "mskuhasbeendeleted": "MSKU ถูกลบแล้ว",
    "masterproductchangedfromto": "สินค้าหลักเปลี่ยนจาก{0} เป็น {1}",
    "mainstorechangedfromto": "ร้านค้าหลักเปลี่ยนจาก {0} เป็น {1}",
    "thresholdchangedfromto": "เกณฑ์เปลี่ยนจาก {0} เป็น {1}",
    "masterProductActivity": "ความเคลื่อนไหวสินค้าหลัก",
    "operationType": "ประเภทการดำเนินงาน",
    "salesTypeId": "ประเภทการขาย",
    "salesRangeId": "ช่วงการขาย",
    "modifyChannelProductPrices": "แก้ไขราคาสินค้าช่องทางการขาย",
    "areyousureyouwanttodeletetheruleafterdeletedyouneedtoreconfigurethesynchronizationrules": "คุณแน่ใจหรือไม่ว่าต้องการลบกฎ? หลังจากลบแล้ว คุณต้องกำหนดค่ากฎการซิงโครไนซ์ใหม่",
    "orderPushRecord": "บันทึกการผลักคำสั่งซื้อ",
    "productPushRecord": "บันทึกการผลักสินค้า",
    "youHaveNotActivatedStockMonitoring": "คุณยังไม่ได้เปิดใช้งาน \"ตรวจสอบสต็อกและซิงค์\"",
    "turnOnNow": "เปิดใช้งาน",
    "turnOn": "ใช้งานอยู่",
    "theSalesRangeOfCannot": "ช่วงการขายของ {} ต้องไม่สูงกว่า {}",
    "theValueOnTheRightSide": "ค่าทางด้านขวาของช่องใส่ต้องไม่น้อยกว่าด้านซ้าย",
    "pushRecord": "บันทึกการผลักดัน",
    "allWarehouse": "คลังสินค้าทั้งหมด",
    "allStoresExceptCustomStores": "ร้านค้าทั้งหมดยกเว้นร้านค้าที่กำหนดเอง: {x}%",
    "pushFailed": "ผลักดันล้มเหลว",
    "pushed": "ผลักดันแล้ว",
    "bindwarehouse": "ผูกคลังสินค้า",
    "afterTurnOnTheProductsThat": "หลังจากเปิดแล้ว สินค้าที่ตรงตามกฎนี้จะถูกผลักไปที่แบ็กเอนด์ของร้านค้า",
    "afterDeactivationProductsThatMeetThis": "หลังจากปิดแล้ว สินค้าที่ตรงตามกฎนี้จะไม่ถูกผลักไปที่แบ็กเอนด์ของร้านค้าอีกต่อไป",
    "afterTheSettingIsCompleteAverage": "หลังจากการตั้งค่าเสร็จสิ้นแล้ว ยอดขายรายวันเฉลี่ยจะถูกคำนวณในวันถัดไปตามการตั้งค่าใหม่",
    "productDescriptionMustBeBetweenMinmaxcharacters": "คำอธิบายสินค้าต้องมีความยาวระหว่าง {min}-{max}  หลัก",
    "repush": "กดซ้ำ",
    "pullAccurateStock": "ดึงสต็อกที่แม่นยำ",
    "accounting1": "ระบบบัญชี",
    "returnManagement": "การจัดการการคืนสินค้า",
    "returnOrderXxxRejectedToRefund": "คำสั่งซื้อที่ส่งคืน xxx ปฏิเสธที่จะคืนเงินให้ผู้ซื้อ",
    "returnOrderXxxAgreedToRefund": "คำสั่งซื้อที่ส่งคืน xxx ตกลงที่จะคืนเงินให้ผู้ซื้อ",
    "returnOrderXxxRejectedRefundreturnRequest": "คำสั่งซื้อที่ส่งคืน xxx ปฏิเสธการขอคืนเงิน/คืนสินค้า",
    "returnOrderXxxAcceptedRefundreturnRequest": "คำสั่งซื้อส่งคืน xxx ยอมรับการคืนเงิน/คืนสินค้า",
    "returnOrderXxxSuccessfullySyncedThe": "ซิงค์คำสั่งซื้อที่ส่งคืน xxx สำเร็จแล้ว ประเภทการส่งคืนคือ \"xxx\" และสถานะการประมวลผลคือ \"xxx\"",
    "rejectRefund": "ปฏิเสธการคืนเงิน",
    "acceptRefund": "ยอมรับการคืนเงิน",
    "acceptRequest": "ยอมรับคำขอ",
    "syncReturnOrder": "ซิงค์รายการคำสั่งซื้อที่ส่งคืน",
    "confirmInbound": "ยืนยันขาเข้า",
    "mass": "จำนวนมาก",
    "returnWarehouse1": "ส่งคืนคลังสินค้า",
    "returnProduct": "สินค้าส่งคืน",
    "doYouAgreeToRefund": "คุณยืนยันที่จะคืนเงินหรือไม่?",
    "pleaseSelectRejectionReason": "โปรดเลือกสาเหตุการปฏิเสธ",
    "doYouAcceptBuyersReturnRefund": "คุณยอมรับคำขอคืนสินค้าของผู้ซื้อหรือไม่?",
    "onceYouAgreeItCannotBe": "เมื่อคุณยอมรับแล้วไม่สามารถเพิกถอนได้",
    "doYouAcceptBuyersRefundRequest": "โปรดยืนยันคำขอคืนเงินของผู้ซื้อ",
    "exportAll": "ส่งออกทั้งหมด",
    "viewReturnRecords": "ดูบันทึกการส่งคืน",
    "accept": "ยอมรับ",
    "isItADisputedOrder": "เป็นคำสั่งพิพาทหรือไม่?",
    "inboundNo": "เลขที่ขาเข้า",
    "returnWarehouse": "ส่งคืนคลังสินค้า",
    "returnAwb": "ส่งคืน AWB",
    "returnStatus": "สถานะการส่งคืน",
    "processingDeadline": "หมดเขตดำเนินการ",
    "processingStatus": "สถานะการดำเนินการ",
    "closed": "ปิด",
    "finished": "เสร็จสิ้น",
    "inDispute": "อยู่ในข้อพิพาท",
    "returnRemark": "หมายเหตุการส่งคืน",
    "variantchannelSkumsku": "ตัวแปล/ช่องทาง SKU/MSKU",
    "returnNo": "เลขที่ส่งคืน",
    "disputedOrder": "เลขที่คำสั่งซื้อที่ถูกค้าน",
    "cod": "COD",
    "separateMultipleInfoWithSpace": "แยกข้อมูลหลายรายการด้วยการเว้นวรรค",
    "returnRefund": "คืนสินค้า & คืนเงิน",
    "returnOrder": "คำสั่งซื้อที่ส่งคืน",
    "xRuleHasBeenDeletedPlease": "(X) กฎถูกลบ โปรดตรวจสอบบันทึกในบันทึกการดำเนินงาน",
    "checknow": "ตรวจสอบตอนนี้",
    "pushTime": "เวลาที่ผลักดัน",
    "theActivityLevelOfTheChannel": "สินค้าในช่องทางการขายถูกตั้งค่าการทำงานแล้ว ไม่สามารถตั้งค่าซ้ำได้",
    "deleteMasterProduct": "ลบสินค้าหลัก",
    "pleaseEnterRemarks": "โปรดกรอกหมายเหตุ",
    "refundType": "ประเภทการคืนเงิน",
    "refundOnly": "คืนเงินเท่านั้น",
    "refundStatus": "สถานะการคืนเงิน",
    "returnInformation": "รายละเอียดการส่งคืนสินค้า",
    "refundReason": "สาเหตุการคืนเงิน",
    "returned": "คืนสินค้า",
    "returning": "การคืนสินค้า",
    "waitingBuyerToReturn": "รอผู้ซื้อส่งคืนสินค้า",
    "returnRecord": "บันทึกการคืนสินค้า",
    "refundList": "รายการคืนเงิน",
    "mskuBindingStatus": "สถานะการเชื่อมของ SKU หลัก",
    "pleaseConfirmTheReturnedProductBefore": "โปรดยืนยันการส่งคืนสินค้าก่อนส่งคืนไปยังคลังสินค้า หากมีข้อผิดพลาด โปรดซิงโครไนซ์คำสั่งซื้อก่อนส่งคืนไปยังคลังสินค้า",
    "combinedProductSales": "การขายสินค้าย่อย",
    "includingCombineProductSalesAndIndividual": "รวมสินค้าขายรวมกันและขายแยกจากสินค้าย่อยของสินค้าขายรวมกัน",
    "countTheSalesOfCombinedProducts": "นับสินค้าขายรวมกันที่ขายรายชิ้นและเป็นกลุ่ม",
    "pleaseSelectTheDataToBe": "โปรดเลือกข้อมูลที่จะส่งออก",
    "salesOfIndividuallySoldItems": "สินค้าแบ่งขายแยก",
    "salesOfProductsSoldByThe": "สินค้าขายรวมกัน",
    "combinedProductMsku": "สินค้ารวม MSKU",
    "combinationProductMsku": "รวมสินค้า MSKU",
    "combinedProductName": "รวมชื่อสินค้า",
    "mainCommoditySalesAnalysis": "การวิเคราะห์การขายของสินค้าหลัก",
    "productDetailsOfThePortfolio": "รายละเอียดสินค้าที่รวมกัน",
    "combinationProductDetails": "รายละเอียดรวมสินค้า",
    "combinedProduct": "สินค้ารวม",
    "exportChoice": "ส่งออกที่เลือก",
    "combinationProducts": "สินค้ากลุ่ม",
    "refundRate": "อัตราผลตอบแทน",
    "refundAmount": "จำนวนเงินคืน",
    "refundQuantity": "ปริมาณการส่งคืน",
    "refundOrderQuantity": "ปริมาณการสั่งซื้อคืน",
    "numberOfFailedOrders": "จำนวนคำสั่งซื้อที่ล้มเหลว",
    "numberOfReturnedOrder": "จำนวนคำสั่งซื้อคืน",
    "numberOfCanceledOrder": "จำนวนคำสั่งซื้อที่ยกเลิก",
    "numberOfShippedOrders": "จำนวนคำสั่งซื้อที่ได้รับแล้ว",
    "numberOfOrdersInDelivery": "จำนวนคำสั่งซื้อที่กำลังจัดส่ง",
    "numberOfReadyToShipOrder": "จำนวนคำสั่งซื้อที่จะจัดส่ง",
    "numberOfPaidOrder": "จำนวนคำสั่งซื้อที่ชำระเงิน",
    "numberOfPendingPaymentOrder": "จำนวนคำสั่งซื้อที่รอดำเนินการ",
    "effectiveSalesAmount": "การขายที่สำเร็จ",
    "totalSales": "ยอดขายทั้งหมด",
    "totalProductSales": "ยอดขายสินค้าทั้งหมด",
    "relatedOrderQuantity": "จำนวนคำสั่งซื้อที่เกี่ยวข้อง",
    "msku": "MSKU",
    "creationTime": "สร้างเมื่อ",
    "keywords": "คีย์เวิร์ด",
    "isACombinationProduct": "เป็นสินค้ากลุ่มหรือไม่",
    "salesQTY": "ฝ่ายขาย",
    "failedGenerateInboundList": "สร้างรายการขาเข้าไม่สำเร็จ",
    "someMasterProductDoNotExist": "สินค้าหลักบางรายการไม่มีอยู่ในคลังสินค้าที่เลือก",
    "masterProductIsEmptyPleaseRebind": "สินค้าหลักว่างเปล่า โปรดผูกสินค้าหลักใหม่และลองอีกครั้ง",
    "refundInformationNotCompletePleaseRefresh": "ข้อมูลการคืนเงินไม่สมบูรณ์ โปรดรีเฟรชอีกครั้ง",
    "informapping": "จับคู่ข้อมูล",
    "mappingtip": "เลือกหมวดหมู่และประเภทตัวเลือกสินค้า มิฉะนั้นคุณจะไม่ได้รับข้อมูลตัวเลือกสินค้า (เช่น สต็อก ราคา ฯลฯ)",
    "Mappingcategory": "หมวดหมู่จับคู่",
    "tiktokCategoryAttributes": "ข้อมูลจำเพาะหมวดหมู่ของ Tiktok",
    "enterBetween": "กรอกระหว่าง 1-60",
    "salesInformation": "ข้อมูลการขาย",
    "tikTokCategory": "หมวดหมู่ TikTok",
    "tiktokfailedToPublishGineeProductThat": "เผยแพร่ล้มเหลว: สินค้า Ginee ที่เผยแพร่ล้มเหลว",
    "tiktokpublishingGineeProductThatAreBeing": "กำลังเผยแพร่:สินค้า Ginee ที่กำลังเผยแพร่หรือสินค้าอยู่ระหว่างรีวิวในศูนย์ผู้ขาย",
    "tiktokdraftAProductDraftCreatedBy": "แบบร่าง: ร่างสินค้าที่สร้างขึ้นโดย Ginee",
    "tiktoksuspendedThisIsAProductWith": "ถูกระงับ: นี่คือสินค้าที่มีสถานะถูกระงับในศูนย์ผู้ขาย",
    "tiktoksoldOutThisIsAProduct": "หมด: นี่คือสินค้าที่มี 0 สต็อกพร้อมสถานะใช้งานอยู่/ปิดใช้งานในศูนย์ผู้ขาย",
    "tiktokdeactivatedThisIsAProductWith": "ไม่ได้ใช้งาน: นี่คือสินค้าที่มีสถานะไม่ได้ใช้งานในศูนย์ผู้ขาย",
    "tiktokliveThisIsAProductWith": "เปิดการใช้งาน：นี่คือสินค้าที่มีสถานะใช้งานอยู่ในศูนย์ผู้ขาย",
    "tiktokskuMustBeCharacters": "SKU ต้องมีอักขระ 1-50 หลัก",
    "tiktokwarrantyPolicyMustBeCharacters": "กฎการรับประกันต้องมีอักขระ 0-99 หลัก",
    "tiktokpriceRangeShouldBe": "ช่วงราคาควรเป็น 100-100000000",
    "tiktokstocktLimitOhters": "สต็อกควรอยู่ระหว่าง 0-99999",
    "tiktokweightShouldBeBetweenKg": "น้ำหนักควรอยู่ระหว่าง 0.01-100",
    "tiktokheightShouldBeBetween": "ความสูงควรอยู่ระหว่าง 1-35",
    "tiktokwidthShouldBeBetween": "ความกว้างควรอยู่ระหว่าง 1-40",
    "tiktoklengthShouldBeBetween": "ความยาวควรอยู่ระหว่าง 1-60",
    "tiktokproductDescriptionLimitIdMy": "คำอธิบายสินค้าต้องมีความยาวระหว่าง1- 10000  หลัก",
    "productNameMinMaxCharacters": "ชื่อสินค้าต้องอยู่ระหว่าง {min}-{max}  หลัก",
    "mpType": "สถานะการเชื่อมของ SKU หลัก",
    "updateDates": "เวลาอัปเดต",
    "returnOrderXHasSuccessfullyCreated": "ใบสั่งส่งคืนสินค้า {x} ได้สร้างเอกสารการรับคืนสินค้าเรียบร้อยแล้ว และหมายเลขเอกสารการรับสินค้าคือ {x}",
    "createInboundList": "สร้างใบรายการสต็อก",
    "noteThereIsADelayIn": "หมายเหตุ: มีความล่าช้าในการอัปเดตสถานะการสั่งซื้อช่องทางและป้ายกำกับการจัดส่ง   ซึ่งอาจทำให้เกิดการล้มเหลวเมื่อกดล้มเหลว โปรดลองกดอีกครั้งในรายการที่ล้มเหลว",
    "theOrderIsBeingPushedTo": "คำสั่งซื้อจะถูกผลักไปที่คลังสินค้าของบริษัทอื่น กรุณาอย่าดำเนินการซ้ำและโปรดไปที่รายการคำสั่งซื้อเพื่อตรวจสอบความคืบหน้าการผลักดัน",
    "pushTaskSuccessfullyCreated": "ผลักดันงานที่สร้างสำเร็จ",
    "refundLog": "บันทึกการคืนเงิน",
    "refundNo": "เลขที่คืนเงิน",
    "refundOrder": "การคืนเงินคำสั่งซื้อ",
    "theServiceIsBusyOrYou": "Server ขัดข้อง หรือ คุณอาจถึงขีดจำกัดอัตราคำขอ โปรดดำเนินการด้วยตนเอง",
    "cancellationIsNotAllowedWhilePush": "ไม่อนุญาตให้ยกเลิกขณะดำเนินการผลักดัน",
    "synchronizeAllMskuInWarehouseTo": "เชื่อมต่อ MSKU ทั้งหมดในคลังสินค้ากับ SKU ทุกช่องทางตามส่วนของ{x} และ {y}",
    "youDoNotHavePermissionAt": "คุณไม่มีสิทธิ์เข้าถึงในขณะนี้ โปรดติดต่อผู้ดูแลระบบเพื่อกำหนดค่าการอนุญาตใน [การจัดการบทบาท]",
    "tikiproductsCreatedInGineeExcludingProducts": "ผลิตภัณฑ์ฉบับร่างที่สร้างขึ้นใน Ginee ยกเว้นสินค้าในศูนย์ผู้ขาย - สถานะร่าง",
    "tikiproductsFailedPublishByGinee": "สินค้าที่เผยแพร่ล้มเหลวของGinee",
    "tikiproductsPublishingByGinee": "สินค้าที่กำลังเผยแพร่ของGinee",
    "tikiproductLockedInSellerCenterApi": "ปัจจุบัน Tiki ไม่มีสินค้าละเมิด",
    "tikiamongActiveAndInactiveProductsAll": "ในบรรดาสินค้าที่ขายอยู่และสินค้าที่หยุดขาย ตัวเลือกสินค้าทั้งหมดที่มีจำนวนสต็อก = 0;\nเนื่องจากปัญหาอินเทอร์เฟซ API สินค้า FBT จะถูกวางลงในรายการขายหมดชั่วคราว",
    "tikinonactiveProductsInSellerCenter": "สินค้าที่มีสถานะศูนย์ผู้ขายปิดและล็อคอยู่",
    "tikiactiveProductInSellerCenter": "สินค้าที่มีสถานะศูนย์ผู้ขายเปิดอยู่",
    "skuProductCode": "SKU (รหัสสินค้า)",
    "lowstockthresholdValueCannotHigherThan": "มูลค่าสต็อกต่ำไม่สามารถสูงกว่าปริมาณสต็อคได้",
    "descriptionUpToCharacters": "คำอธิบายไม่เกิน {max}  ตัวอักษร",
    "skuPromotionPriceCannotHigherThan": "ราคาโปรโมชัน SKU ต้องไม่มากกว่าราคาปกติ โปรดแก้ไข",
    "skuPromotionStartTimeCannotBe": "เวลาเริ่มต้นโปรโมชัน SKU ต้องไม่เว้นว่าง",
    "skuPromotionEndTimeCannotBe": "เวลาสิ้นสุดของโปรโมชัน SKU ต้องไม่เว้นว่าง",
    "skuLowstockthresholdValueCannotHigherThan": "SKU มูลค่าสต็อกต่ำไม่สามารถสูงกว่าปริมาณสต็อคได้",
    "variantSkuCannotBeEmpty": "ตัวแปร SKU ไม่สามารถเว้นว่างได้",
    "productSkuCannotBeEmpty": "SKU สินค้าต้องไม่เว้นว่าง",
    "thisSkuAlreadyExistsInThis": "SKU นี้ :${0} มีอยู่แล้วในร้านค้านี้ โปรดแก้ไขอีกครั้ง",
    "productMainImageCannotBeEmpty": "ไม่สามารถเว้นว่างรูปภาพหลักของสินค้าได้",
    "promotionPriceCannotBeHigherThan": "ราคาขายต้องไม่สูงกว่าราคาเดิม",
    "theInputRangeFromMin": "ช่วงที่กรอกคือ { min } - { max}",
    "addVariants": "เพิ่มตัวเลือกสินค้า",
    "shippingClass": "Shipping class",
    "crosssells": "Cross-sells",
    "upsells": "Upsells",
    "enableThisToOnlyAllowOne": "เปิดใช้งานตัวเลือกนี้เพื่อจำกัดการซื้อสินค้านี้ได้เพียงรายการเดียว",
    "soldIndividually": "ขายแยก",
    "lowStockThreshold": "เกณฑ์สต็อกต่ำ",
    "allowBackorders": "อนุญาตให้มีสินค้าค้างส่ง",
    "enableStockManagementAtProductLevel": "เปิดใช้งานการจัดการสต็อกที่ระดับสินค้า",
    "manageStock": "จัดการสต็อก",
    "taxClass": "Tax class",
    "taxStatus": "Tax status",
    "usedForVariations": "ใช้สำหรับตัวเลือกสินค้า",
    "visibleOnTheProductPage": "มองเห็นได้ในหน้าสินค้า",
    "thisIsAFeaturedProduct": "นี่คือสินค้าแนะนำ",
    "showSharingButtons": "แสดงปุ่มแชร์",
    "showLikes": "แสดงไลก์",
    "likeAndShares": "ไลก์และแชร์",
    "hidden": "ที่ซ่อนอยู่",
    "searchResultsOnly": "ผลการค้นหาเท่านั้น",
    "shopOnly": "เฉพาะร้านค้า",
    "shopAndSearchResults": "ร้านค้าและผลการค้นหา",
    "catalogVisibility": "การมองเห็นแคตตาล็อก",
    "heightMinMax": "ความสูงควรอยู่ระหว่าง{min}-{max}",
    "widthMinMax": "ความกว้างควรอยู่ระหว่าง{min}-{max}",
    "lengthMinMax": "ความยาวควรอยู่ระหว่าง {min}-{max}",
    "weightMinMax": "น้ำหนักควรอยู่ระหว่าง {min}-{max}",
    "pleaseEnterProductDescription": "กรุณากรอกคำอธิบาย",
    "productNameMinMax": "ชื่อสินค้าต้องอยู่ระหว่าง {min}-{max} หลัก",
    "variantPicture": "รูปภาพตัวเลือกสินค้า",
    "productImageMax": "รูปภาพสินค้า สูงสุด {number} รูป",
    "promotionTime": "ระยะเวลาโปรโมชัน",
    "promotionPrice": "ราคาโปรโมชัน",
    "options": "ตัวเลือก",
    "variantType": "ประเภทตัวเลือกสินค้า",
    "productAttributes": "คุณสมบัติสินค้า",
    "productShortDescription": "คำอธิบายสินค้าโดยสังเขป",
    "productTag": "Tag",
    "updatedateasc": "เวลาอัปเดตจากเก่าสุดไปล่าสุด",
    "updatedatedesc": "เวลาอัปเดตจากล่าสุดไปเก่าสุด",
    "createdateasc": "เวลาสร้างจากล่าสุดไปเก่าสุด",
    "createdatedesc": "เวลาสร้างจากเก่าสุดไปล่าสุด",
    "withoutmp": "ยกเลิกการเชื่อมต่อ SKU หลัก",
    "withmp": "เชื่อม SKU หลัก",
    "generateInventorySKU": "สร้างสต็อก SKU",
    "onBackorder": "จัดส่งสินค้าล่าช้า",
    "productNameAndStore": "ชื่อสินค้า&ร้านค้า",
    "sync": "เชื่อมต่อ",
    "inStock": "มีสต็อก",
    "soldOut": "หมดสต็อก",
    "disabled": "เลิกจำหน่าย",
    "china": "ประเทศจีน",
    "wooPublishStatus": "สินค้าในร้านค้าที่รอการตรวจสอบจะแสดงในแท็บกำลังเผยแพร่",
    "wooActiveStatus": "สินค้าที่เผยแพร่แล้วในร้านค้า จะโชว์ในแท็บวางจำหน่ายแล้ว",
    "wooDeactiveStatus": "สินค้าส่วนตัวในร้านค้าจะแสดงในแท็บเลิกจำหน่ายของGinee",
    "wooDraftStatus": "สินค้าฉบับร่างในร้านค้าจะโชว์ในแท็บฉบับร่างของ Ginee",
    "inProcess": "กำลังเผยแพร่",
    "stockStatus": "สถานะสต็อก",
    "externalProduct": "สินค้าที่เกี่ยวข้อง",
    "groupProduct": "สินค้ากลุ่ม",
    "variable": ">1 ตัวเลือกสินค้า",
    "simpleVariation": "ตัวเลือกสินค้าเดียว",
    "editStatusError": "โปรดคลิกปุ่มยืนยันก่อน จากนั้นคลิกเผยแพร่ไปยังร้านค้า",
    "productInfoSetting": "ตั้งค่าข้อมูลสินค้า",
    "choiceMskuTip": "หลังจากเลือก SKUหลัก ระบบจะนำข้อมูลราคาและสินค้าคงคลังของ SKUหลัก เข้ามาและผูกข้อมูล SKU ของ blibli กับ SKUหลัก",
    "categoryMatch": "จับคู่หมวดหมู่",
    "variationLimit": "หมวดหมู่ที่เลือกไว้ไม่รองรับการเพิ่มตัวเลือกสินค้า",
    "clickToEdit": "คุณสามารถคลิกเนื้อหาในแบบฟอร์มและแก้ไขได้",
    "whatsInTheBox": "อะไรอยู่ในพัสดุ",
    "MinPurchaseQuantity": "จำนวนการซื้อขั้นต่ำ",
    "ProductDescriptionShouldBeCharacters": "คำอธิบายสินค้าควรมีความยาว 6-25,000 หลัก",
    "chooseStoreLimit": "เลือกร้านเป้าหมายอย่างน้อย 1 แห่งเพื่อคัดลอกสินค้า",
    "replaceWith38": "แทนด้วย",
    "addBetweenTheDescription": "เพิ่มระหว่างคำอธิบาย",
    "addAtTheEndOfThe": "เพิ่มที่ส่วนท้ายของคำอธิบาย",
    "addAtTheStartOfThe": "เพิ่มที่จุดเริ่มต้นของคำอธิบาย",
    "addBetweenName": "เพิ่มระหว่างชื่อ",
    "editProductName": "แก้ไขชื่อสินค้า",
    "theFileWillOnlyBeReserved": "ไฟล์จะถูกเก็บไว้ 7 วันเท่านั้น กรุณาตรวจสอบหรือดาวน์โหลดให้ทันเวลา",
    "theResultOfThePublishWill": "ผลลัพธ์ของการเผยแพร่จะถูกเชื่อมต่อใน [สินค้าหลัก - ประวัติการดำเนินการเป็นกลุ่ม]",
    "successfulQtyAllQty": "จำนวนที่สำเร็จ / จำนวนทั้งหมด",
    "publishing": "กำลังเผยแพร่",
    "taskStatus": "สถานะงาน",
    "publishedStoreStoreStore": "ร้านค้าที่เผยแพร่: ร้านค้า 1 ร้านค้า 2",
    "content": "เนื้อหา",
    "creator": "ผู้สร้าง",
    "taskType": "ประเภทงาน",
    "allPublishFailed": "เผยแพร่ล้มเหลวทั้งหมด",
    "somePublishFailed": "เผยแพร่ล้มเหลวบางส่วน",
    "allPublished": "เผยแพร่สำเร็จทั้งหมด",
    "productsWereSuccessfullyPublished": "เผยแพร่สำเร็จสินค้า29รายการ",
    "publishingToChannelStores": "กำลังเผยแพร่ไปยังร้านค้าช่องทางเป็นกลุ่ม",
    "massEditOfShopeesCategoryAttribute": "แก้ไขข้อมูลจำเพาะหมวดหมู่ของ Shopee เป็นกลุ่ม",
    "matchTokopediaVariantType": "จับคู่ประเภทตัวเลือกสินค้าของ Tokopedia",
    "matchLazadaVariantType": "จับคู่ประเภทตัวเลือกสินค้าของ lazada",
    "masterProductVariantType": "สินค้าหลัก ประเภทตัวเลือกสินค้า",
    "massEditProductDescription": "แก้ไขคำอธิบายของสินค้าเป็นกลุ่ม",
    "shopeeCategoryAttribute": "ข้อมูลจำเพาะหมวดหมู่ของ Shopee",
    "productNameInThisStoreIs": "มีชื่อสินค้าอยู่ในร้านค้านี้แล้ว",
    "partOfTheMasterProductIs": "ข้อมูลที่จำเป็นสำหรับสินค้าหลักบางส่วนว่างเปล่า กรุณากรอกข้อมูลให้สมบูรณ์ก่อนเผยแพร่ไปยังร้านค้า",
    "chooseACountry": "เลือกประเทศ/ภูมิภาค",
    "max11": "สูงสุด 1,000",
    "massEditWeightG": "แก้ไขน้ำหนักเป็นกลุ่ม(g)",
    "max7": "สูงสุด 500,000",
    "empty": "ล้าง",
    "editLogisticsMass": "แก้ไขการจัดส่งเป็นกลุ่ม",
    "editBliblidraftmass": "Blibli/ฉบับร่าง เป็นกลุ่ม",
    "categoryAttributesBlibli": "ข้อมูลจำเพาะหมวดหมู่ของ blibli",
    "copyMass": "คัดลอกเป็นกลุ่ม",
    "categoryChoose": "เลือกหมวดหมุ่",
    "matchCategoryBlibli": "จับคู่หมวดหมู่ของ Blibli",
    "storeWarehouse": "ร้านค้า/คลังสินค้า",
    "buyable": "ซื้อได้",
    "MinimumStock": "สต็อกขั้นต่ำควรเป็น 0-999,999",
    "originalPriceLimit2": "ราคาเดิมควรสูงกว่าราคาขาย",
    "sellingPriceLimit2": "ราคาขายควรตำ่กว่าราคาเดิม",
    "originalPriceLimit": "ราคาเดิมควรอยู่ระหว่าง 1-100.000.000",
    "enterYoutubeLinkForExampleYouTubecomipadview": "กรอกลิงก์ของ Youtube เช่น YouTube.com/ipadview",
    "youtubeVideoLink": "ลิงก์ของ Youtube",
    "exampleTheBatteryLifeIsUp": "ตัวอย่าง: อายุการใช้งานแบตเตอรี่สูงสุด 10 ชั่วโมง",
    "sellingPoint": "จุดขาย",
    "continueToAddProducts": "เพิ่มสินค้าต่อไป",
    "jdChannelIsUnderReviewCheck": "ช่องทาง blibli กำลังตรวจสอบ ตรวจสอบสินค้าในรายการกำลังเผยแพร่/เผยแพร่ล้มเหลว/เลิกจำหน่าย",
    "failedReasons": "เหตุผลที่ล้มเหลว",
    "productsUpdateSucceeded": "อัปเดตสินค้าสำเร็จ",
    "changeSKUTo": "แก้ไข SKU เป็น",
    "massEditSellingPrice": "แก้ไขราคาขายเป็นกลุ่ม",
    "massEditOriginalPrice": "แก้ไขราคาเดิมเป็นกลุ่ม",
    "massEditSku": "แก้ไข SKU เป็นกลุ่ม",
    "editVariantImage": "แก้ไขรูปภาพตัวเลือกของสินค้า",
    "multipleImagesCanBeUploadedAt": "คุณสามารถอัปโหลดรูปภาพหลายรูปในครั้งเดียว จำนวนพิกเซลไม่น้อยกว่า600 x 600 พิกเซล ใหญ่สุด 4MB",
    "shouldBeBetween": "ต้องอยู่ระหว่าง0-1.000.000.000",
    "optionsnLimit": "ตัวเลือกควรน้อยกว่า 20",
    "bigItem": "สินค้าใหญ่",
    "smallItem": "สินค้าเล็ก",
    "qualitydays": "เวลารับประกันคุณภาพ",
    "afterSale": "บริการหลังการขาย",
    "Min1": "ขั้นต่ำ 1 รายการ",
    "minpurchasePerOrder": "จำนวนซื้อขั้นต่ำ",
    "pleaseEnterProductName": "กรุณากรอกชื่อสินค้า",
    "blibliCategory": "หมวดหมู่ BliBli",
    "partOfTheProductIs": "ตามข้อกำหนดที่เผยแพร่สินค้าของช่องทางร้านค้า ต้องกรอกช่องคอลัมน์บางช่องของสินค้าหลัก กรุณากรอกข้อมูลในช่องคอลัมน์ที่จำเป็นก่อนเผยแพร่ไปยังร้านค้า",
    "blibliShouldBeBetweenA": "ต้องอยู่ระหว่าง 0.001-999.999",
    "blibliWeightLimit": "ต้องอยู่ระหว่าง 1-999,999",
    "blibliStocktLimit": "สต็อกควรอยู่ระหว่าง 0-999,999!",
    "blibliImageSizeLimit": "จำนวนพิกเซลไม่น้อยกว่า 600 x 600พิกเซล，ใหญ่สุด 4MB",
    "serviceErrorPleaseContactGineeTechnology": "เซิร์ฟเวอร์ผิดพลาด กรุณาติดต่อ Ginee Technology",
    "minPurchaseLimit": "จำนวนการซื้อน้อยสุดควรเป็น 1-1000,000,000",
    "enterThenChange": "ใส่ค่าก่อนแล้วค่อยเปลี่ยนหน่วยทีหลัง",
    "goToTheBlibliSellerCenter": "กรุณาไปที่ศูนย์ผู้ขายของ Blibli เพื่อกรอกที่อยู่คลังสินค้าก่อนเลือกการจัดส่ง",
    "regularStoreSupport": "รองรับร้านค้าปกติ",
    "toApiRestrictionsSomeVariantOption": "เนื่องจากข้อจำกัดของ API จึงไม่สามารถคัดลอกตัวเลือกสินค้าบางส่วนได้ กรุณาเพิ่มด้วยตนเองหากจำเป็น",
    "theCategoryAndVariantTypeApply": "หมวดหมู่และประเภทตัวเลือกสินค้าใช้กับสินค้าทั้งหมดในหมวดหมู่แหล่งที่มาเดียวกันหรือไม่ (หมวดหมู่แหล่งที่มา： xxx/xxxx/xxxx ของไซต์เดียวกัน)",
    "toApiRestrictionsSomeFieldsDo": "เนื่องจากข้อจำกัดของ Blibli API ทำให้บางช่องคอลัมน์ไม่สามารถแก้ไขได้",
    "shippingMethod": "วิธีการจัดส่ง",
    "backToProductList": "กลับไปที่รายการสินค้า",
    "chooseTheShippingMethodFirstPlease": "กรุณาเลือกวิธีการจัดส่งก่อน",
    "needToChooseNo": "ไม่ต้องเลือก",
    "fAq1": "วิธีเพิ่มหรือแก้ไขสินค้าของ Blibli",
    "fAq2": "วิธีคัดลอกสินค้าของ Blibli",
    "fAq3": "วิธีแก้ไขสินค้าเป็นกลุ่มของ Blibli",
    "synchronizationForThisProductHasBeen": "เปิดใช้งานการเชื่อมต่อสต็อกสำหรับสินค้านี้แล้ว โปรดแก้ไขสต็อกใน การจัดการสต็อแกสินค้า",
    "productDeleting": "กำลังลบสินค้า",
    "operationWillNotDeleteTheProduct": "การดำเนินการนี้จะไม่ลบสินค้าในศูนย์ผู้ขาย",
    "deleteTip": "เนื่องจากข้อจำกัดของ API คุณจึงลบได้เฉพาะสินค้าที่อยู่ในสถานะฉบับร่าง เผยแพร่ และเผยแพร่ที่ล้มเหลวเท่านั้น",
    "productOrVariationImage": "รูปภาพสินค้า/ข้อมูลจำเพาะ",
    "imageTip2": "สินค้าที่ไม่มีตัวเลือกสินค้า รูปภาพอ้างอิงถึงรูปภาพของผลิตภัณฑ์ และผลิตภัณฑ์ที่มีตัวเลือกสินค้าหมายถึงรูปภาพของตัวเลือกสินค้า",
    "blibliSkuLimit": "SKU ควรมีความยาวน้อยกว่า 50 หลัก",
    "exampleTheAvailableInventoryOfWarehouse": "ตัวอย่าง: สินค้าคงคลังที่มีอยู่ของคลังสินค้า A คือ 100 ชิ้น คงคลังที่พร้อมใช้งานของคลังสินค้า B คือ 200 และคลังสินค้าแบบผลักดันถูกตั้งค่าเป็น A+B และอัตราส่วนการผลักคือ 50% จากนั้นสต็อกที่ผลักไปยังร้าน C คือ 150",
    "exampleThePushFixedValueIs": "ตัวอย่าง: ค่าคงที่ของการผลักดันคือ 100, ผลักดันสต็อกไปที่ร้าน A และเมื่อดำเนินการผลักดัน สต็อคที่ไปเก็บ A คือ 100",
    "ProductStatus": "สถานะสินค้า",
    "productsThatFailToBePushed": "สินค้าที่ผลักดันไม่ผ่านจะไม่ได้รับอนุญาตให้ผลักดันอีก โปรดติดต่อเจ้าหน้าที่ ECCANG WMS เพื่อตรวจสอบสินค้าก่อน",
    "returnTime": "เวลาส่งคืน",
    "theProductHasNotPassedThe": "สินค้ายังไม่ผ่านการตรวจสอบ",
    "shippingFeePaidBySeller": "ค่าธรรมเนียมขนส่งจ่ายโดยผู้ขาย",
    "skucanonlybenumberslettersandhyphenandthemaximumcharacters": "SKU ต้องเป็นตัวเลข ตัวอักษร และเครื่องหมายขีดเท่านั้น และต้องมีความยาวไม่เกิน 30 อักขระ",
    "skuNameMaxCharactersOnly": "ชื่อ SKU สูงสุดได้ 50 ตัวอักษร รองรับเฉพาะภาษาจีนและอังกฤษ ตัวเลข เครื่องหมายขีด และขีดล่าง",
    "productThatFailToPassThe": "สินค้าที่ไม่ผ่านการสอบ จะไม่สามารถจัดส่ง ECCANG WMS  ได้",
    "skuCanOnlyBeInUppercase": "SKU ต้องเป็นอักษรตัวพิมพ์ใหญ่เท่านั้น",
    "afterFailedToSendTheInbound": "ล้มเหลวเนื่องจากไม่รองรับการผลักดันสต็อกเข้ารายการขาเข้า",
    "productEffectiveSales": "การขายสินค้าอย่างมีประสิทธิภาพ",
    "pleaseNote": "โปรดทราบ:",
    "dueToTheLimitationOfThe": "เนื่องจากข้อจำกัดของ ECCANG WMS API SKU สูงสุด 30 ตัวอักขระ ต้องเป็นตัวเลข ตัวอักษร และขีดเท่านั้น",
    "someOrdersAreNotAssignedWith": "คำสั่งซื้อบางรายการไม่ได้กำหนดไว้กับ sub-logistics โปรดจัดเรียงการจัดส่งใหม่หลังจากการซิงโครไนซ์",
    "ordersWithoutSublogisticsAreNotSupported": "คำสั่งซื้อที่ไม่มีการขนส่งย่อยไม่ได้รับการสนับสนุนสำหรับการจัดส่ง โปรดซิงโครไนซ์คำสั่งซื้อเพื่อรับโลจิสติกส์ย่อย",
    "waitingForDistributionLogisticsPleaseSynchronize": "กำลังรอการขนส่งตามช่องทางที่เลือก กรุณาซิงโครไนซ์",
    "productInformationSettingError": "ข้อผิดพลาดในการตั้งค่าข้อมูลสินค้า",
    "userAddressInformationError": "ข้อผิดพลาดด้านข้อมูลที่อยู่ผู้ใช้",
    "notInShippingAddress": "ไม่มีที่อยู่ในการจัดส่ง",
    "deliveryFailure": "การจัดส่งล้มเหลว (การจัดส่งล้มเหลวเนื่องจากผู้ใช้ไม่รับโทรศัพท์หรือที่อยู่ไม่ตรงกัน)",
    "logisticsCannotSupportDistribution": "ลอจิสติกส์ไม่สามารถรองรับการกระจายสินค้าได้ (หากมีน้ำท่วมในฤดูฝน)",
    "theMerchantSentTheWrongProduct": "ผู้ขายจัดส่งสินค้าผิด",
    "productDamage": "สินค้าเกิดการชำรุด(ไม่ได้จัดส่งเสียหาย)",
    "productDescriptionDoesNotMatch": "รายละเอียดสินค้าไม่ตรงกัน",
    "businessAndUserPhoneToCancelOrder": "คนขายและคนใช้งานโทรศัพท์เพื่อยกเลิกคำสั่งซื้อ",
    "goodsDamageDuringLogisticsDelivery": "สินค้าเสียหายระหว่างการขนส่ง",
    "theUserAskedToCancelTheOrder": "ผู้ใช้ขอยกเลิกคำสั่งซื้อ",
    "logisticsAndDistributionCannotContactTheRecipient": "การขนส่งและการกระจายสินค้าไม่สามารถติดต่อผู้รับได้",
    "incompleteRecipientAddress": "ที่อยู่ผู้รับไม่ครบถ้วน",
    "thirdPartyMerchantsHaveNoInventory": "ผู้ค้าบุคคลที่สามไม่มีสินค้าคงคลัง",
    "outOfDeliveryRange": "อยู่นอกพื้นที่ช่วงจัดส่ง",
    "itIsOutOfStockAndCannotBeTraded": "สินค้าหมดและไม่สามารถซื้อขายได้",
    "theBuyerSInformationIsFilledInWrong": "ผู้ซื้อกรอกข้อมูลผิด กรุณาใช้รูปใหม่",
    "latePayment": "ชำระเงินช้า",
    "maliciousBuyers": "ผู้ซื้อ / เพื่อน",
    "theBuyerHasNoSincerityToCompleteTheTransaction": "ผู้ซื้อไม่ได้ทำธุรกรรมให้เสร็จสิ้น",
    "theBuyerMadeAMistakeOrRemake": "ผู้ซื้อทำผิดพลาดหรือทำการสร้างใหม่",
    "unableToContactBuyer": "ติดต่อผู้ซื้อไม่ได้",
    "otherReasons": "เหตุผลอื่น",
    "unableToCompletePayment": "ไม่สามารถชำระเงินได้ / ไม่สามารถรับรหัสการชำระเงินได้",
    "thePaymentMethodNeedsToBeChanged": "จำเป็นต้องเปลี่ยนวิธีการชำระเงิน",
    "commodityPriceReduction": "สินค้าลดราคา",
    "wrongAddressInformation": "ข้อมูลที่อยู่ไม่ถูกต้อง",
    "wrongProductInformation": "ข้อมูลสินค้าไม่ถูกต้อง",
    "iDonTWantItAnymore": "ฉันไม่ต้องการมันแล้ว",
    "goodsOutOfStock": "สินค้าหมด",
    "synchronizeAllMskusUnderXTo": "การเชื่อมต่อผลรวมของ MSKU ทั้งหมดภายใต้ {x} ถึง {w} ในอัตราส่วน {y} ถึง {z}",
    "pushInProcess": "อยู่ระหว่างผลักดัน",
    "waitingToPush": "รอการผลักดัน",
    "rdWarehousePushStatus": "สถานะการผลักดันคลังสินค้าที่ 3",
    "pushStatus": "สถานะการผลักดัน",
    "printable": "สามารถพิมพ์ได้",
    "productInboundStockCannotBeNegative": "สินค้าคงคลังขาเข้าไม่สามารถติดลบได้",
    "andAccordingToZ": "เชื่อมต่อกับ {w} ตามอัตราส่วนของ {z}",
    "synchronizeallmskuunder": "เพิ่ม MSKU ทั้งหมดภายใต้ {x} ถึง {y}",
    "pushSucess": "ดันสำเร็จ",
    "stockPushRuleaddRule": "กฎการผลักดันสต็อก / เพิ่มกฎ",
    "peraturanTolakStokubahSuai": "กฎการผลักดันสต็อก / แก้ไขกฎ",
    "peraturanTolakStoklihatPerincian": "กฎการผลักดันสต็อก / ดูรายละเอียด",
    "negativeInventoryCannotBeManipulatedInto": "สินค้าคงคลังติดลบและไม่สามารถจัดเก็บได้",
    "channelCategoryAttributes": "ข้อมูลจำเพาะหมวดหมู่ของ {channelName}",
    "switchingMskuRequiresReselectingWarehouseInformation": "การเปลี่ยน MSKU ต้องเลือกข้อมูลคลังสินค้าอีกครั้ง",
    "pleaseAuthorizeTheStoreFirst": "กรุณาเชื่อมต่อร้านค้าก่อน",
    "thereIsNoAlternativeStorePlease": "ไม่มีร้านค้าให้เลือก กรุณาเชื่อมต่อร้านค้าก่อน",
    "stockTikiTips": "ร้านค้า Tiki และสินค้า FBT ที่ไม่รองรับการแก้ไขข้อมูลสินค้าจะไม่สามารถเชื่อมต่อสต็อก",
    "stocksettingtip2Local": "หลังจากเปิดใช้งานระบบเชื่อมต่อ SKU หลักแล้ว สินค้าที่มีชื่อ SKU เดียวกันในร้านค้าทั้งหมดจะถูกปรับให้เชื่อมต่อกัน และเมื่อมีการสร้างคำสั่งซื้อใหม่หรือคำสั่งซื้อถูกยกเลิก สต็อกของสินค้าที่มี SKU เดียวกันในร้านค้าทั้งหมดจะอัปเดตไปพร้อมๆกัน ไม่ว่าจะเป็นการตัดสต็อกหรือเพิ่ม โปรดตรวจสอบให้แน่ใจว่าการเชื่อมต่อสินค้า คำสั่งซื้อและการตั้งค่าจำนวนสต็อกเสร็จสมบูรณ์ก่อนเปิดใช้งานฟีเจอร์นี้ \nหลังจากที่เปิดการเชื่อมต่อสต็อกแล้ว สต็อก SKU ของร้านค้าจะถูกผลักตามอัตราส่วนสต็อกและสต็อก SKU หลักที่เชื่อมต่อไว้ในรายการการจัดการสต็อก(การตั้งค่าคลังจัดส่งของร้านค้า) หากไม่ได้ตั้งค่าสต็อกในรายการการจัดการสต็อก สต็อก SKU ของร้านค้าจะถูกผลักเป็น 0 และไม่สามารถกู้คืนได้ กรุณายืนยันว่าเปิดหรือไม่",
    "afterTheStockSynchronizationIsTurned": "หลังจากที่เปิดการเชื่อมต่อสต็อกแล้ว สต็อก SKU ของร้านค้าจะถูกผลักตามอัตราส่วนสต็อกและสต็อก SKU หลักที่เชื่อมต่อไว้ในรายการการจัดการสต็อก(การตั้งค่าคลังจัดส่งของร้านค้า) หากไม่ได้ตั้งค่าสต็อกในรายการการจัดการสต็อก สต็อก SKU ของร้านค้าจะถูกผลักเป็น 0 และไม่สามารถกู้คืนได้ กรุณายืนยันว่าเปิดหรือไม่",
    "doesntSupportCustomShippingLabelTemplate": "ปัจจุบันไม่รองรับคอลัมน์ช่องทางแบบกำหนดเองของเทมเพลตใบปะหน้าของฟิลิปปินส์ รองรับการตั้งค่าขนาด A4/A6 เท่านั้น",
    "commissionTips": "โปรดใส่หมายเลขที่ถูกต้องระหว่าง 1-100",
    "invoiceNumberTips": "ทุกรายการสินค้าในหนึ่งคำสั่งซื้อใช้หมายเลขใบกำกับภาษีเดียวกัน",
    "SettingTips2": "คลิกที่นี้",
    "SettingTips": "ฟีเจอร์อัปเดตที่อยู่ตั้งต้นหรือที่อยู่เข้ารับพัสดุใช้ได้เฉพาะเมื่อมีการจัดส่งคำสั่งซื้อในร้านค้าผ่านGinee(Lazada รองรับเฉพาะการตั้งค่าในศูนย์ผู้ขาย)",
    "SettingLogo": "คลิกที่นี่เพื่อตั้งค่าโลโก้แบรนด์",
    "SellerNotes": "บันทึกผู้ขาย",
    "PickingNotes": "บันทึกการหยิบสินค้า",
    "TotalProduct": "สินค้าทั้งหมด",
    "PrintMan": "พิมพ์โดย",
    "DeliverySettingPickupAddress": "กรุณาไปที่ Seller Center บนแพลตฟอร์มร้านค้าเพื่อกำหนดที่อยู่",
    "DeliverySettingStoreName": "ชื่อร้านค้า",
    "PickFormReset": "ตั้งค่าใหม่",
    "ChangePickStatusConfirm": "ยืนยัน",
    "PickDeleteSuccess": "ลบข้อมูลสำเร็จ!",
    "PickDeleteTipTwo": "เมื่อลบแล้วไม่สามารถกู้คืนได้",
    "PickDeleteTip": "คุณแน่ใจหรือไม่ว่าจะลบข้อมูลสถานะ?",
    "PickOverCharacters": "ไม่สามารถใส่เกิน 20 ตัวอักษร",
    "AddPickStatus": "เพิ่มเงื่อนไข",
    "PickUpdateTime": "เวลาที่อัปเดต",
    "PickCreateTime": "สร้างเมื่อ",
    "PickStatusOperatorName": "ผู้สร้าง",
    "PickStatusName": "สถานะร้านค้า",
    "PrintUnitPrice": "ราคาต่อหน่วย",
    "PrintVariationName": "ตัวเลือกสินค้า",
    "PrintShipping": "การขนส่ง",
    "PrintSellerInfo": "ผู้ขาย",
    "PrintBuyerInfo": "ลูกค้า",
    "BuyerNotes": "บันทึกสำหรับลูกค้า",
    "ShippingFee": "ยอดรวมค่าจัดส่ง",
    "SenderInfo": "ผู้ส่ง",
    "GoToSellerCenter": "ไปที่หน้า Seller Center บนแพลตฟอร์มร้านค้าเพื่อตั้งค่า",
    "AutoShippingTips": "Ginee รองรับการตั้งค่าวิธีการจัดส่งสำหรับร้านค้า ตั้งค่าเดี๋ยวนี้",
    "ShippingAddRule": "เพิ่มเงื่อนไข",
    "OperationFailed": "ดำเนินการล้มเหลว, ลองใหม่อีกครั้ง",
    "OperationSuccess": "ดำเนินการสำเร็จ",
    "ApplyToAllStores": "นำไปใช้กับทุกร้านค้า",
    "PickupAddress": "ที่อยู่เข้ารับพัสดุ",
    "ChooseAStore": "เลือกร้านค้า",
    "ShippingSettingTips": "Tips: Shopee เท่านั้นที่สามารถเลืก SPX เข้าถึงผู้ขายได้",
    "ShopName": "ชื่อร้านค้า",
    "ShippingMethod": "วิธีจัดส่ง",
    "Courier": "บริษัทขนส่ง",
    "ShippingMethodDescription": "ตามการตั้งค่าของคุณ Ginee จะเลือกวิธีการจัดส่งให้คุณโดยอัตโนมัติหลังจากที่คำสั่งซื้อได้รับการเชื่อมต่อเพื่อประสิทธิภาพสูงสุด",
    "laStoreName": "ชื่อร้านค้า",
    "proalertclosed": "การแจ้งเตือนสต็อกถูกปิดอยู่",
    "proalertopend": "เปิดคำเตือนสต็อกแล้ว เมื่อสต็อกที่มีอยู่ต่ำกว่าสต็อกความปลอดภัยที่ตั้งไว้ ระบบจะส่งอีเมลแจ้งเตือน",
    "PleaseSelectLeast": "เลือกอย่างน้อย 1 อย่าง",
    "DeleteTplTitle": "คุณแน่ใจหรือไม่ว่าต้องการลบเทมเพลตนี้?",
    "DeleteTplDesc": "ไม่สามารถเรียกดูเทมเพลตที่ถูกลบออกไปแล้วได้อีก",
    "LaRuleBindingStoreSearchPlaceholder": "กรอกชื่อร้าน",
    "LaRuleBindingStoreModalTitle": "เลือกเงื่อนไขการใช้ใบกำกับภาษี",
    "methodSettingDoubleCheckDescription": "หากเงื่อนไขการตั้งค่าขนส่งถูกลบออกไปแล้ว จะไม่สามารถนำมาใช้ได้",
    "LaRuleDoubleCheckDescription": "หากเงื่อนไขการสร้างใบกำกับภาษีถูกลบออกไปแล้ว จะไม่สามารถนำมาใช้ได้",
    "LaRuleDoubleCheck": "คุณแน่ใจหรือไม่ว่าต้องการลบเงื่อนไขนี้?",
    "ExportField": "ส่งออกเนื้อหา",
    "SelectChannel": "เลือกช่องทาง",
    "OrderExportingDragging": "คุณสามารถเปลี่ยนลำดับขณะส่งออกได้โดยการลากขึ้นและลง",
    "BasicInfo": "ข้อมูลพื้นฐาน",
    "EditTemplate": "แก้ไขเทมเพลต",
    "UpdateTime": "เวลาที่อัปเดต",
    "CreateTime": "สร้างเมื่อ",
    "TemplateName": "ชื่อเทมเพลต",
    "Creator": "ผู้สร้าง",
    "LAInvoiceSettingSave": "ยืนยัน",
    "ApplyToAllLazadaStores": "นำไปใช้กับร้านค้า Lazada ทั้งหมด",
    "NextNumber": "หมายเลขถัดไป",
    "EnterNumberManually": "กรอกหมายเลขด้วยตัวเอง",
    "UseCustomNumber": "ใช้หมายเลขที่กำหนดเอง",
    "UseOrderNumber": "ใช้หมายเลขคำสั่งซื้อ",
    "StoreManagement": "การจัดการร้านค้า",
    "RuleApplicationStoreNumber": "หมายเลขร้านค้า",
    "RuleCustomNumber": "หมายเลขที่กำหนดเอง",
    "RulePrefix": "คำนำหน้า",
    "RuleType": "ประเภทเงื่อนไข",
    "RuleName": "ชื่อเงื่อนไข",
    "AddRule": "เพิ่มเงื่อนไข",
    "commissionPercent": "เปอร์เซ็นต์ค่าคอมมิชชั่น (%)",
    "InvoiceRules": "เงื่อนไขใบกำกับภาษี",
    "ShippingSettingsTab": "ตั้งค่าขนส่ง",
    "TemplateShippingAddress": "ที่อยู่จัดส่ง",
    "TemplateLogistics": "ขนส่ง",
    "TemplateTotal": "ยอดรวม",
    "TemplateSellerDiscountTotal": "ส่วนลดผู้ขาย",
    "TemplateTax": "ภาษี",
    "TemplateShipping": "ขนส่ง",
    "TemplateSubtotal": "ยอดรวม",
    "TemplateQty": "จำนวน",
    "TemplateCreatedAt": "สร้างเมื่อ",
    "TemplatePayment": "ชำระเงิน",
    "TemplateContactNo": "เบอร์ติดต่อ",
    "TemplateUnitPrice": "ราคาต่อหน่วย",
    "TemplateBuyer": "ลูกค้า",
    "TemplateAWBTrackingNumber": "AWB/หมายเลขติดตามพัสดุ",
    "Quantity": "จำนวน",
    "Specification": "ตัวเลือกสินค้า",
    "ProductName": "ชื่อสินค้า",
    "TemplateProductItem": "รายการสินค้า",
    "TemplateInsurance": "ประกันภัย",
    "TemplateWeight": "น้ำหนัก",
    "TemplateShippingType": "การขนส่ง",
    "OrderId": "หมายเลขคำสั่งซื้อ",
    "TemplateSender": "ผู้ส่ง",
    "TemplateRecipient": "ผู้รับ",
    "TemplateInformation": "ข้อมูล",
    "LogisticsLOGO": "โลโก้บริษัทขนส่ง",
    "ChannelLogo": "โลโก้ของช่องทางการขาย",
    "TemplateIcon": "ไอคอน",
    "TemplatePrintDate": "วันที่พิมพ์",
    "TemplateDate": "วันที่",
    "ViewBoxItemTitle": "ดูตัวอย่างการพิมพ์",
    "TemplateSettingsTitle": "ตั้งค่าเทมเพลต",
    "SetPickUpAddress": "ตั้งค่าที่อยู่เข้ารับพัสดุ",
    "SetDefaultAddress": "ตั้งค่าที่อยู่ตั้งต้น",
    "SenderAddress": "ที่อยู่",
    "SenderPhone": "เบอร์โทร",
    "SenderName": "ชื่อ",
    "DefaultAddress": "ที่อยู่ตั้งต้น",
    "BlSenderInfo": "i18n: BlSenderInfo",
    "TokoSenderInfo": "Tips: ชื่อ/หมายเลขโทรศัพท์จะแสดงข้อมูลในใบรายการขนส่งของ Ginee เอง",
    "ShopeeSenderInfo": "Tips: ชื่อ/หมายเลขโทรศัพท์ของที่อยู่จัดส่งตั้งต้นจะถูกใช้เพื่อแสดงในใบรายการขนส่งและข้อมูลที่อยู่จะถูกเชื่อมกับ Shopee หลังจากตั้งค่าที่อยู่ตั้งต้นและที่อยู่เข้ารับพัสดุ",
    "PickingListPrintTemplateSettings": "ตั้งค่ารายการหยิบสินค้า",
    "ShippingLabelTemplateAlertTips": "ใช้ได้เฉพาะกับเทมเพลตของ Ginee เท่านั้น ไม่สามารถใช้กับเทมเพลตคำสั่งซื้อจากขนส่งหรือแพลตฟอร์มอื่นๆ",
    "PrintTemplateSettings": "ตั้งค่าการพิมพ์เทมเพลต",
    "ShippingLabelAlertTips": "หากคุณต้องการใช้เทมเพลตการจัดส่งของ Ginee หรืออัปเดตที่อยู่ตั้งต้น/ที่อยู่รับเข้ารับพัสดุ โปรดตั้งค่าข้อมูลนี้ให้ครบถ้วน",
    "ShippingLabelInformationSettings": "การตั้งค่าข้อมูลฉลากการจัดส่ง",
    "htar": "วิธีเพิ่มหน้าที่?",
    "htas": "วิธีเพิ่มทีมงาน?",
    "htmr": "วิธีการจัดการสิทธิ์และเข้าถึงหน้าที่?",
    "htms": "บริหารจัดการทีมงานอย่างไร?",
    "Mustbestartedwith8": "ต้องเริ่มต้นด้วย 8",
    "atleastonerole": "โปรดเลือกอย่างน้อย 1 หน้าที่",
    "noroles": "ไม่มีข้อมูล, โปรดเพิ่มหน้าที่",
    "deleteroletip": "หลังจากลบหน้าที่แล้ว,ทีมงานไม่สามารถเลือกหน้าที่นี้ได้",
    "suretodeleterole": "คุณแน่ใจหรือไม่ว่าต้องการลบหน้าที่นี้",
    "EditRole": "แก้ไขหน้าที่",
    "Roleadded": "เพิ่มหน้าที่เรียบร้อย",
    "Permissionlimit": "โปรดเลือกอย่างน้อย 1 หน้าที่",
    "Permission": "อนุญาตให้เข้าถึงหน้าที่",
    "roledesclimit": "ใส่รายละเอียดได้มากสุด 600 ตัวอักษร",
    "roleNamelimit": "ชื่อต้องอยู่ระหว่าง 1-150 ตัวอักษร",
    "EnterRoleName": "ใส่ชื่อหน้าที่",
    "DeleteFailed": "ลบไม่สำเร็จ",
    "staffDeleted": "ลบทีมงาน",
    "RoleDeleted": "ลบหน้าที่",
    "AddRole": "เพิ่มหน้าที่",
    "Numberofstaff": "จำนวนทีมงาน",
    "RoleDescription": "คำอธิบายหน้าที่",
    "RoleName": "ชื่อของหน้าที่",
    "Submit": "บันทึก",
    "Staffhasbeenadded": "เพิ่มทีมงานสำเร็จ",
    "Nextstep": "ถัดไป",
    "DiscardChange": "ยกเลิกการเปลี่ยนแปลง",
    "suretodiscard": "แน่ใจไหมว่าต้องการยกเลิกการเปลี่ยนแปลงนี้?",
    "AddNewRole": "เพิ่มหน้าที่ใหม่",
    "Existingpermissions": "สิทธิ์เข้าถึงที่มีอยู่",
    "EnterthePassword": "ทีมงานใช้รหัสผ่านนี้เพื่อเข้าสู่ระบบ Ginee",
    "passwordlimit": "รหัสผ่านต้องมีอย่างน้อย 8 ตัวอักษร, สัญลักษณ์และตัวเลข",
    "Staffsusethemailbox": "ทีมงานใช้ที่อยู่อีเมลนี้เป็นบัญชีเพื่อเข้าสู่ระบบ Ginee",
    "Gineeaccountcannotbeempty": "บัญชี Ginee ไม่สามารถเว้นว่าง",
    "Pleaseinputemail": "กรอกอีเมล",
    "Contactphonenumber": "เบอร์ติดต่อ",
    "namelimit": "ใส่ชื่อพนักงานระหว่าง 1-30 ตัวอักษร",
    "EnterStaffName": "ชื่อพนักงาน",
    "locktip": "บัญชีนี้ถูกปิดใช้งาน โปรดติดต่อผู้ดูแลระบบ",
    "deletetip": "หลังจากลบข้อมูล พนักงานจะไม่สามารถจัดการร้านค้าได้ และคุณจะไม่สามารถเพิ่มพนักงานคนนี้ได้อีกต่อไป",
    "suretodeletestaff": "คุณแน่ใจหรือไม่ว่าต้องการลบพนักงานคนนี้?",
    "Disablenow": "ปิดการใช้งานตอนนี้",
    "disabletip": "หลังจากปิดใช้งาน พนักงานจะไม่สามารถเข้าสู่ระบบ Ginee เพื่อจัดการร้านค้าได้",
    "suretodisabled": "คุณแน่ใจหรือว่าปิดการใช้งานบัญชีพนักงานนี้?",
    "Edit": "แก้ไข",
    "Enabled": "เปิดใช้งาน",
    "Disabled": "ปิดใช้งาน",
    "Action": "ดำเนินการ",
    "LastLogin": "เข้าสู่ระบบครั้งล่าสุด",
    "NameofStaff": "ชื่อทีมงาน",
    "AddStaff": "เพิ่มทีมงาน",
    "Search": "ค้นหา",
    "Roles": "หน้าที่",
    "GineeAccount": "บัญชี Ginee",
    "AccountStatus": "สถานะบัญชี",
    "ActivationFailed": "การเปิดใช้งานล้มเหลว กรุณาตั้งค่าก่อน",
    "SavedSuccessfully": "ดำเนินการสำเร็จ!",
    "donwloadlist": "คลิกที่นี่เพื่อดาวน์โหลดรายการสต็อกทั้งหมด",
    "InventoryWarnings": "การแจ้งเตือนสต็อก",
    "moresetting": "การตั้งค่าเพิ่มเติม",
    "suretoclosemainlock": "คุณแน่ใจหรือว่าต้องการปิดฟีเจอร์ล็อคสต็อกเพื่อร้านค้าหลัก?",
    "suretoopenstocklock": "คุณแน่ใจหรือไม่ว่าต้องการเปิดฟีเจอร์ล็อกสต็อกของร้านค้าหลัก ? หลังจากเปิดแล้ว เมื่อสต็อกต่ำกว่าเกณฑ์ ระบบจะสำรองสต็อกไว้ให้ร้านค้าหลักเท่านั้น และสต็อกทั้งหมดในร้านค้าอื่นๆ จะว่างเปล่า",
    "whenempty2": ", ล้างสต็อกในร้านอื่นหมด ยกเว้นร้านนี้",
    "whenempty1": "เมื่อสต็อกเหลือ '<'=",
    "MainStoreStockLock": "ล็อคสต็อกเพื่อร้านค้าหลัก",
    "suretoclosestockwarning": "คุณแน่ใจหรือไม่ว่าต้องการปิดการแจ้งเตือนสต็อก",
    "emailreminderstocklow": "อีเมลแจ้งเตือนเมื่อสินค้ามีสต็อกใกล้หมด คุณแน่ใจหรือไม่ว่าต้องการเปิด?",
    "Setup": "ตั้งค่า",
    "whenstock2": ",แจ้งเตือนด้วยว่าสต็อกไม่เพียงพอ",
    "whenstock1": "เมื่อสต็อกเหลือ<=",
    "suretoclosealertsold": "คุณแน่ใจหรือไม่ว่าต้องการปิดการแจ้งเตือนสต็อกหมด",
    "notifyme": "แจ้งเตือนเมื่อสต็อกหมด",
    "stocksettingtip3Local": "เมื่อปิดการใช้งานระบบเชื่อมต่อสต็อกแล้ว สต็อกในร้านค้าทั้งหมดของคุณจะไม่เชื่อมต่อกันอีก คำสั่งซื้อและจำนวนสต็อกสินค้าในแต่ละร้านค้าจะไม่อัปเดตไปพร้อมๆกันและรายการสต็อกของคุณจะหายไปโดยอัตโนมัติ",
    "stocksettingtip3": "เมื่อปิดการใช้งานระบบเชื่อมต่อสต็อกแล้ว สต็อกในร้านค้าทั้งหมดของคุณจะไม่เชื่อมต่อกันอีก คำสั่งซื้อและจำนวนสต็อกสินค้าในแต่ละร้านค้าจะไม่อัปเดตไปพร้อมๆกันและรายการสต็อกของคุณจะหายไปโดยอัตโนมัติ",
    "stocksettingtip2LocalOld": "หลังจากเปิดใช้งานระบบเชื่อมต่อ SKU หลักแล้ว สินค้าที่มีชื่อ SKU เดียวกันในร้านค้าทั้งหมดจะถูกปรับให้เชื่อมต่อกัน และเมื่อมีการสร้างคำสั่งซื้อใหม่หรือคำสั่งซื้อถูกยกเลิก สต็อกของสินค้าที่มี SKU เดียวกันในร้านค้าทั้งหมดจะอัปเดตไปพร้อมๆกัน ไม่ว่าจะเป็นการตัดสต็อกหรือเพิ่ม โปรดตรวจสอบให้แน่ใจว่าการเชื่อมต่อสินค้า คำสั่งซื้อและการตั้งค่าจำนวนสต็อกเสร็จสมบูรณ์ก่อนเปิดใช้งานฟีเจอร์นี้",
    "stocksettingtip2": "หลังจากเปิดใช้งานระบบเชื่อมต่อสต็อกแล้ว สินค้าที่มี SKU เดียวกันในร้านค้าทั้งหมดจะถูกปรับให้เชื่อมต่อกัน และเมื่อมีการสร้างคำสั่งซื้อใหม่หรือคำสั่งซื้อถูกยกเลิก สต็อกของสินค้าที่มี SKU เดียวกันในร้านค้าทั้งหมดจะอัปเดตไปพร้อมๆกัน ไม่ว่าจะเป็นการตัดสต็อกหรือเพิ่ม โปรดตรวจสอบให้แน่ใจว่าการเชื่อมต่อสินค้า คำสั่งซื้อและการตั้งค่าจำนวนสต็อกเสร็จสมบูรณ์ก่อนเปิดใช้งานฟีเจอร์นี้",
    "stocksettingtip1Local": "หากต้องการเพิ่มความแม่นยำของสต็อค โปรดดำเนินการซิงโครไนซ์สินค้าและคำสั่งซื้อทั้งหมดให้เสร็จสิ้น หลังจากนั้นตั้งค่า SKU หลักก่อนที่จะเปิดใช้งานการซิงโครไนซ์สต็อค หลังจากนั้น Ginee จะหักหรือเพิ่ม Master SKU โดยอัตโนมัติตามสถานะของคำสั่งซื้อ และซิงโครไนซ์สต็อคที่มีอยู่ของ Master SKU ไปยังร้านค้าที่มีการผูกรูปแบบช่องทางสินค้าไว้",
    "stocksettingtip1": "เพื่อให้แน่ใจว่าสต็อกถูกต้อง โปรดทำการเชื่อมต่อร้านค้า,สินค้า,คำสั่งซื้อทั้งหมดและกำหนดจำนวนสต็อก SKU ก่อนเปิดใช้งานระบบเชื่อมต่อสต็อก ระบบ Ginee จะเพิ่มหรือตัดสต็อกสินค้าโดยอัตโนมัติตามสถานะคำสั่งซื้อและจำนวนสต็อกของคุณกับร้านค้าที่คุณเชื่อมต่อไว้ทั้งหมด",
    "monitorsync": "ติดตามและเชื่อมต่อสต็อกอัตโนมัติ",
    "noteCreateBind": "หมายเหตุ:  หากเปิดการใช้งานเชื่อมต่อSKUหลักอัตโนมัติ สินค้าในหน้าSKUหลักจะถูกดำเนินการก่อน",
    "autoCreateSKUValue": "เงื่อนไขการสร้าง :  หากร้านค้ามีสินค้าที่มีรหัส SKU เหมือนกับ SKU หลัก ในระบบ Ginee รายการสินค้านั้นจะถูกเชื่อมต่อโดยอัตโนมัติ สามารถใช้ฟีเจอร์นี้ได้กับสินค้าในร้านค้าที่เชื่อมต่อกับระบบ Ginee เท่านั้น หากชื่อ SKU ไม่เหมือนกันระบบจะสร้าง SKU  หลักใหม่อัตโนมัติทันที",
    "automaticallyCreateTheMasterSKU": "สร้าง SKU หลักอัตโนมัติ",
    "deleteAutoCreateRules": "คุณแน่ใจหรือไม่ว่าต้องการปิดการตั้งค่าเพิ่ม SKU หลักอัตโนมัติ?",
    "turnAutoCreateRules": "หลังจากเปิดใช้งานการเพิ่ม SKU หลักโดยอัตโนมัติแล้ว, หากชื่อ SKU ของสินค้าในร้านค้าของคุณมีชื่อเดียวกับ SKU หลักในระบบ Ginee รายการสินค้านั้นจะถูกเชื่อมต่อกับ SKU หลัก,  หากชื่อ SKU ไม่เหมือนกันระบบจะสร้าง SKU หลักใหม่อัตโนมัติทันที",
    "goToDownloadTheRules": "ไปยัง【นำเข้า/ส่งออก-ส่งออกเงื่อนไขการเชื่อมต่ออัตโนมัติ】เพื่อดาวน์โหลดเงื่อนไข",
    "downloadAllAutoBindRules": "ดาวน์โหลดเงื่อนไขการเชื่อมต่ออัตโนมัติทั้งหมด",
    "deleteAutoBindRules": "คุณแน่ใจหรือไม่ว่าต้องการปิดการเชื่อมโยงอัตโนมัติของ SKU หลัก?",
    "turnOnAutoBindRules": "หลังจากที่เปิดการเชื่อมต่ออัตโนมัติของ SKU หลักแล้ว สำหรับสินค้าในร้านค้าที่ไม่ได้เชื่อมต่อกับ SKU หลักเราจะเชื่อมรายการสินค้ากับ SKU หลักที่มีชื่อเหมือนกันโดยอัตโนมัติตามเงื่อนไขของ SKU หลัก",
    "autoBindSKUValue": "หลังจากเปิดใช้งานการตั้งค่าแล้ว, หากร้านค้ามีสินค้าที่มีรหัส SKU เหมือนกับ SKU หลัก ในระบบGinee รายการสินค้านั้นจะถูกเชื่อมต่อโดยอัตโนมัติ สามารถใช้ฟีเจอร์นี้ได้กับรายการสินค้าที่อยู่ในร้านค้าของคุณที่ได้เชื่อมต่อกับระบบ Ginee เท่านั้น",
    "automaticallyBindTheMasterSKU": "เชื่อมต่อ SKU หลักโดยอัตโนมัติ",
    "SyncSuccess": "เชื่อมต่อสำเร็จ",
    "AreSureEnableCOD": "คุณแน่ใจหรือไม่ว่าต้องการเปิดใช้งาน COD?",
    "StoreName": "ชื่อร้านค้า",
    "ExcludeShippingInfo3": "ยืนยันที่จะเปิด { JNEOKE }?",
    "ExcludeShippingInfo2": "หากเปิดรายการสินค้าแล้ว {JNEOKEList} จะเปลี่ยนเป็น {JNEOKE } โดยอัตโนมัติ",
    "ExcludeShippingInfo1": "ถ้าคุณเปิด { JNEOKE }, { JNEOKEList } จะปิด.",
    "NoShippingInfo": "ยังไม่มีการเชื่อมต่อร้านค้าบนShopee เชื่อมต่อเดี๋ยวนี้ !",
    "CodNo": "ไม่",
    "CodYes": "ใช่",
    "DisabledCODClick": "ดูรายละเอียดเพิ่ม",
    "DisabledCODDesc4": "คำสั่งซื้อ J&T แบบ COD มีประกันรับรอง",
    "DisabledCODDesc3": "ไม่มีขั้นตอนจัดการคำสั่งซื้อเพิ่มเติม ยอดชำระจะเข้าระบบตามเวลาที่กำหนด",
    "DisabledCODDesc2": "ลูกค้าที่ละเมิดเงื่อนไข COD จะถูกขึ้นบัญชีดำโดยอัตโนมัติ",
    "DisabledCODDesc1": "ผู้ขายที่มี COD มักจะได้รับคำสั่งซื้อและลูกค้ามากขึ้น",
    "DisabledCODTitle": "คุณแน่ใจหรือไม่ว่าต้องการปิดใช้งาน COD?",
    "PleaseGoToProductEnable": "โปรดไปที่แต่ละรายการสินค้าเพื่อเปิดใช้งานตัวเลือกการจัดส่ง",
    "UnEnabledTitle": "เมื่อปิดตัวเลือกขนส่งทั้งหมด ข้อมูลขนส่งจะถูกลบออกจากรายการสินค้าที่มีอยู่ทั้งหมด สินค้าที่ไม่มีตัวเลือกการจัดส่งจะถูกลบออกจากรายการและลูกค้าจะไม่สามารถมองเห็นรายการสินค้าได้อีก",
    "CODDisabled": "ปิดใช้การงาน COD",
    "CODEnabled": "เปิดใช้งาน COD",
    "COD": "COD",
    "ShippingSupportedDesc": "เมื่อใช้ตัวเลือกการจัดส่งที่ Shopee รองรับ ลูกค้าจะได้รับการแจ้งเตือนจาก Shopee และสามารถติดตามพัสดุได้",
    "ShopeSupportedLogistics": "บริษัทขนส่งที่ Shopee รองรับ",
    "ShippingTopTips": "หมายเหตุ: คุณสามารถตั้งค่าขนส่งร้านค้าของคุณได้ที่นี้โดยเปิดการเชื่อมต่อร้านค้าผ่านGineeได้เลย ขอแนะนำให้ตั้งค่าขนส่งในทุกร้านค้าที่คุณมีให้สอดคล้องกัน และโปรดเปิดใช้งานตัวเลือกขนส่งอย่างน้อยหนึ่งตัวเลือก",
    "MembershipPage": "หน้าเพจสมาชิก",
    "BasicLogo": "โลโก้",
    "Account": "บัญชี",
    "afterClosingTheAutomaticBindingRules": "หลังจากปิดการใช้งานระบบเชื่อมต่อสต็อกอัตโนมัติ รายการสินค้าจากร้านค้าทั้งหมดของคุณจะไม่เชื่อมต่อสต็อกแบบอัตโนมัติกับ SKU หลักอีกต่อไป",
    "ShippingRuleSettings1": "ตั้งค่าเงื่อนไขการจัดส่ง",
    "TheWarehouseAllocatedByThe": "2. เมื่อคลังสินค้าที่ถูกจัดสรรตามคำสั่งซื้อถูกผูกไว้กับคลังสินค้าที่ถูกต้อง",
    "MskuHasSuccessfullyPushedTo": "1. เมื่อ MSKU ถูกผลักดันสำเร็จ",
    "orderRulesToPushToAccurate": "กฎการผลักดันคำสั่งซื้อที่ถูกต้องมีดังนี้:",
    "OnlySupports": "3. รองรับเฉพาะตัวเชื่อมต่อ \" (\", \")\", \"-\", \"\".., \"_\" และ \"/\"",
    "OnlySupportsLetternumberCombination": "2. รองรับเฉพาะชุดตัวอักษร / ตัวเลขเท่านั้น",
    "NoMoreThanCharacters": "1. ไม่เกิน 30 ตัวอักษร",
    "mskuRulesToPushProductTo": "กฎการผลักดัน MSKU สำหรับสินค้าที่ถูกต้องมีดังนี้:",
    "ifNoRulesAreAddedThe": "หากไม่มีการเพิ่มกฎ กฎนี้จะถูกนำไปใช้ เมื่อเพิ่มกฎด้วยตัวเองแล้ว คลังสินค้าจะถูกกำหนดตามกฎที่คุณตั้งไว้",
    "afterTheWarehouseSettingIsModified": "หลังจากแก้ไขการตั้งค่าคลังสินค้าแล้ว คลังสินค้าจะได้รับการจัดสรรตามกฎใหม่ โปรดระวัง.",
    "afterConfigurationWhenAnOrderContains1": "หลังจากตั้งค่า เมื่อมีคำสั่งซื้อหลายสินค้า สามารถจับคู่คลังสินค้าที่แตกต่างกันตามสินค้าที่แตกต่างกัน",
    "particularCommodity": "สินค้าเดี่ยว",
    "afterConfigurationWhenAnOrderContains": "หลังจากตั้งค่า เมื่อคำสั่งซื้อมีสินค้าหลายรายการ จะสามารถจัดสรรให้กับคลังสินค้าเดียวเท่านั้น และไม่สามารถจับคู่คลังสินค้าหลายรายการพร้อมกันได้",
    "overallOrder": "คำสั่งซื้อโดยรวม",
    "compartmentDimension": "ขนาดช่อง",
    "afterConfigurationWhenMultipleWarehousedividingRules": "หลังจากตั้งค่า เมื่อตรงตามกฎการแบ่งคลังสินค้าหลายรายการ คลังสินค้าที่มีหมายเลขลำดับความสำคัญต่ำจะได้รับการจัดสรรเพื่อสร้างเอกสารการตัดสินค้าและประมวลผลสินค้าคงคลัง",
    "warehouseWithHighRulePriority": "คลังสินค้าที่มีลำดับความสำคัญสูง",
    "afterConfigurationWhenMultipleWarehouseAllocation": "หลังจากตั้งค่า เมื่อเป็นไปตามกฎการจัดสรรคลังสินค้าหลายรายการ คลังสินค้าที่มีสินค้าคงคลังเพียงพอจะได้รับการจัดสรรก่อนเพื่อสร้างเอกสารขาออกและประมวลผลสินค้าคงคลัง",
    "fullyStockedWarehouse": "คลังสินค้าครบครัน",
    "priorityOfWarehouseDivision": "ลำดับความสำคัญของที่ตั้งคลังสินค้า",
    "promptThisSettingAffectsWarehouseAllocation": "เตือน : การตั้งค่านี้มีผลกับกฎการจัดสรรคลังสินค้า ดังนั้นโปรดใช้ความระมัดระวัง",
    "temporarilyNotOpen": "ไม่เปิดชั่วคราว",
    "theSwitchIsNotTurnedOn": "ไม่ได้เปิด และกฎการจัดสรรคลังสินค้าจะไม่ถูกดำเนินการหลังจากสร้างสำเร็จแล้ว",
    "theSwitchOrderSettingsdistributionSettingsorderAssociated": "ยังไม่ได้เปิดสวิตช์ \"การตั้งค่าคำสั่งซื้อ-การตั้งค่าการจัดจำหน่าย-กระบวนการหักคำสั่งซื้อในสต็อก\"",
    "separateWarehouseSetting": "ตั้งค่าแยกคลังสินค้า",
    "afterAddingRulesByYourselfWhen": "หลังจากเพิ่มกฎด้วยตัวเองแล้ว เมื่อตรงตามกฎที่เพิ่มเข้ามา คลังสินค้าจะถูกกำหนดตามกฎที่คุณตั้งไว้",
    "matchAccordingToTheWarehouseIn": "จับคู่ตามคลังสินค้าขาเข้าล่าสุดของ MSKU โดยค่าเริ่มต้น",
    "downloadApp": "ดาวน์โหลดAPP",
    "manageYourStoreAnytimeAnywhereGinee": "จัดการร้านค้าของคุณได้ทุกที่ทุกเวลา ด้วย Ginee App เปิดตัวอย่างเป็นทางการ",
    "shippingMethodPriorityPickUp": "ลำดับความสำคัญของวิธีการจัดส่ง: Pickup > Dropoff > Manual Ship",
    "logisticsCompaniesThatDoNotSet": "บริษัทลอจิสติกส์ที่ไม่ได้กำหนดกฎการจัดส่งจะจัดส่งตามวิธีการจัดส่งที่รองรับ",
    "pleaseSelectALogisticsCompanyFor": "โปรดเลือกบริษัทขนส่งสำหรับคำสั่งซื้อของคุณ",
    "pullThreepartyWarehouseForStorage": "ดึงสต็อกคลังสินค้าของบุคคลที่ 3",
    "expired": "หมด อายุ",
    "deactivated": "ถูกจำกัด",
    "ecommercePlatform": "ตลาด",
    "selfbuiltStation": "สร้างสถานี",
    "financialSoftware": "ซอฟต์แวร์ทางการเงิน",
    "originalName": "Original",
    "editStore": "แก้ไขร้าน",
    "pullData": "ดึงข้อมูล",
    "reenable": "เปิดใช้งานอีกครั้ง",
    "deleteStore": "ลบร้าน",
    "areyousureyouwanttodeletethisstore": "กิจวัตร คุณแน่ใจหรือว่าต้องการจะลบร้านนี้",
    "TheBindingRelationshipBetweenThe": "1. การเชื่อมต่อระหว่างสินค้าช่องทางการขายและสินค้าหลักจะถูกเปิดเผย\n2. ข้อมูลรายการสินค้าช่องทางการขายจะถูกลบออกใน Ginee\n3. สินค้าในสต๊อกจะไม่ถูกผลักเข้าร้าน\n4. คุณจะไม่สามารถดูข้อมูลการสั่งซื้อของร้านค้านี้ได้\n5. คุณจะไม่พบร้านค้าในการตั้งค่าร้านค้า",
    "theDefinitionOfDisabledIsStore": "จำกัด: ร้านค้าถูกจำกัดเนื่องจากทรัพยากรร้านค้าเกินขีดจำกัด สถานะนี้จะไม่ส่งผลกระทบต่อยอดขายของร้านค้าในช่อง สินค้าช่องทางของร้านค้าจะถูกยกเลิกโดยอัตโนมัติกับ Master SKU; สินค้า/คำสั่งซื้อและข้อมูลอื่นๆ ของร้านค้าจะไม่แสดงใน Ginee ERP",
    "authorizationInfo": "อัตโนมัติ",
    "configManagement": "การจัดการการกำหนดค่า",
    "germany": "เยอรมัน",
    "belgium": "เบลเยียม Name",
    "italy": "อิตาลี",
    "poland": "ชาวโปแลนด์",
    "authorized": "อนุญาต",
    "authTips": "หลังจากเชื่อมต่อร้านค้าของคุณสำเร็จแล้ว คุณสามารถดูการเชื่อมต่อข้อมูลในหน้าเมนูสินค้าและหน้าเมนูคำสั่งซื้อ",
    "youDoNotHavePermissionTo": "คุณไม่ได้รับอนุญาตเข้าถึงร้านค้า คุณจึงไม่สามารถแก้ไขราคาร้านค้าได้",
    "pleaseWaitPatientlyWhileTheStore": "จัดเก็บข้อมูลการดึงโปรดรออย่างอดทน",
    "startPullingTheStoreDataIt": "เริ่มดึงข้อมูลร้านค้า คาดว่าจะใช้เวลาหลายนาที โปรดดูข้อมูลในการปรับเทียบข้อมูลภายหลัง",
    "editSucceeded": "แก้ไขสําเร็จ",
    "AreYouSureYouWantToDeleteTheAuthorization": "กิจวัตร ยืนยันการลบใบอนุญาต",
    "areYouSureToModifyThe": "คุณแน่ใจหรือไม่ว่าต้องการแก้ไขการตั้งค่าการกระจายคลังสินค้า",
    "CharactersLong": "ยาว 10-12 ตัวอักษร",
    "pleaseStartWithTwoLettersOr": "โปรดเริ่มต้นด้วยตัวอักษรหรือตัวเลขสองตัว \"1\" และใส่ตัวเลข 10 ตัว",
    "pleaseEnterTheCorrectAkulakuTracking": "โปรดป้อนรูปแบบหมายเลขติดตาม Akulaku ที่ถูกต้อง",
    "transportStockChange": "รอสต็อกนำเข้า：",
    "tipavailablestock": "สต็อกที่สามารถขายบนช่องทางร้านค้า สต็อกที่มีอยู่ = สต็อกคลังสินค้า-สต็อกสำรอง -ล็อคสต็อก-สต็อกโปรโมชั่น",
    "tiplockedStock": "สต็อกกำลังรอดำเนินการแต่ยังไม่ได้จัดส่ง คำสั่งซื้อใหม่ที่ชำระเงินแล้วหรือคำสั่งซื้อที่ยังไม่ได้ชำระเงินใหม่ที่เชื่อมต่อกับ Ginee จะเพิ่มในล็อคสต็อก",
    "tipsafetyStock": "กำหนดจำนวนสต็อกเหลือน้อยและรับอีเมลแจ้งเตือนให้เติมสต็อกเมื่อสต็อกที่มีของสินค้าถึงจำนวนที่ตั้งค่าไว้",
    "tipspareStock": "จำนวนสต็อกทั้งหมดที่สำรองไว้ในคลังสินค้าจริงซึ่งจะไม่มีการจำหน่ายในช่องทางร้านค้า",
    "tipwarehouseStock": "สต็อกจริงทั้งหมดในโกดังของคุณ สต็อกคงคลัง = สต็อกสำรอง + สต็อกที่ถูกล็อค + สต็อกพร้อมส่ง + สต็อกโปรโมชั่น",
    "totalAvailableStock": "สต็อกพร้อมส่งทั้งหมด",
    "totalPromotionStock": "สต็อกโปรโมชั่นทั้งหมด",
    "totalLockedStock": "สต็อกที่ล็อคทั้งหมด",
    "totalSpareStock": "สต็อกสำรองทั้งหมด",
    "totalWarehouseStock": "สต็อกสินค้าทั้งหมด",
    "ImageName": "ภาพและชื่อ",
    "case": "กรณีที่",
    "purchasePricecalrules": "ราคาซื้อโดยเฉลี่ย = ยอดรวมราคาซื้อขาเข้า/ สต็อกคงคลัง",
    "ISKUmeaning": "MSKU ย่อมาจาก SKU หลัก",
    "nochange": "สต็อกพร้อมส่งไม่มีการเปลี่ยนแปลงดังนั้นสต็อกบนร้านค้าจึงยังไม่จำเป็นต้องอัปเดต",
    "manulupdate": "ไม่มีการเปลี่ยนแปลง",
    "updatetimetip": "อัปเดตเวลา",
    "reasonforfailed": "สาเหตุที่ล้มเหลว: สินค้าถูกลบหรือแบนจากช่องทางร้านค้า หรืออยู่ในแคมเปญส่งเสริมการขาย (การเชื่อมต่อะสำเร็จหลังจากสิ้นสุดโปรโมชั่น)",
    "TotalPromotionStock": "สต็อกโปรโมชั่นทั้งหมด",
    "StoreSKU": "SKU ของร้านค้า",
    "tippromotionStock": "สต็อกโปรโมชั่นคือจำนวนสต็อกทั้งหมดที่สำรองไว้สำหรับช่วงโปรโมชั่น หรือ Flash Sale",
    "remark": "หมายเหตุ",
    "transportStock": "รอสต็อกนำเข้า",
    "warehouseStockChange": "คลังสินค้า:",
    "spareStockChange": "สำรอง:",
    "promotionStockChange": "โปรโมชั่น：",
    "lockedStockChange": "ล็อค：",
    "availableStockChange": "พร้อมส่ง：",
    "oversell": "มีคำสั่งซื้อแต่ไม่มีสต็อก! โปรดปรับสต็อก ตอนนี้อัปเดทสต็อกบนช่องทางร้านค้าเป็น 0",
    "Seethedetails": "ดูรายละเอียด",
    "warehouseStockerror": "สต็อกควรอยู่ระหว่าง 0 ถึง 999,999",
    "warehouseStockrequired": "สต็อกควรอยู่ระหว่าง 0 ถึง 999,999",
    "remarkplaceholder": "โปรดป้อนเหตุผลในการแก้ไข ซึ่งควรมีความยาวน้อยกว่า 300 อักขระ",
    "availablestockshort": "พร้อมส่ง",
    "promotionstockshort": "โปรโมชั่น",
    "lockedStockshort": "ล็อค",
    "spareStockshort": "สำรอง",
    "warehouseStockshort": "คลังสินค้า",
    "priceplaceholder": "ใส่ราคา",
    "Barcodeplaceholder": "รองรับตัวอักษร ตัวเลข และ -_",
    "weightplaceholder": "ขอบเขตคือ 5,000,000",
    "upgradeResultClose": "ปิด",
    "upgradeResultViewNow": "ดูตอนนี้",
    "upgradeResultRetry": "ลองอีกครั้ง",
    "upgradeResultFailedTitle": "อัปเกรดเป็นจัดการสต็อก Pro ล้มเหลว！",
    "upgradeResultSuccessTitle": "อัปเกรดเป็นจัดการสต็อก Pro สำเร็จ！",
    "upgradeModalUpgradeNow": "อัพเกรดเดี๋ยวนี้",
    "upgradeModalCancel": "ยกเลิก",
    "upgradeModalAbout": "เกี่ยวกับการจัดการสต็อก Pro",
    "january5th": "จะได้รับการอัปเกรดโดยอัตโนมัติในวันที่ 5 มกราคม 2021",
    "syncmanually": "เชื่อมต่อคำสั่งซื้อ",
    "upgradeModalFooterTitle": "โปรดใช้ {syncorder} ด้วยตนเองก่อนอัปเกรด ดังนั้นคำสั่งซื้อที่มีอยู่จะตัดสต็อกบัญชี Ginee เวอร์ชั่น Basic จะเป็น {updatedate}",
    "upgradeModalLabelStockWarning": "การแจ้งเตือนสต็อก:",
    "upgradeModalLabelSoldOut": "ขายหมดแล้ว:",
    "upgradeModalLabelAll": "ทั้งหมด:",
    "upgradeModalContentTitle": "ดูจำนวน SKU หลักที่มีอยู่ของคุณ:",
    "upgradeModalTitleSub": "จัดการสต็อก Pro เป็นเวอร์ชันขั้นสูงพร้อม {val} ฟีเจอร์ใหม่ที่เพิ่มเข้ามาในการจัดการสต็อก",
    "upgradeModalTitle": "อัปเกรดเป็นจัดการสต็อกแบบ Pro",
    "upgradeBtn": "อัปเกรดเป็น Pro ฟรี ดูตอนนี้!",
    "setstock": "ตั้งค่าสต็อก",
    "setsafetyStock": "ตั้งค่าสต็อกปลอดภัย",
    "setspareStock": "ตั้งสต็อกสำรอง",
    "setwarehouseStock": "ตั้งค่าคลังสินค้า",
    "skusselected": "SKU ที่เลือก",
    "skuselected": "SKU ที่เลือก",
    "skus": "SKU",
    "Viemore": "ดู",
    "VariationStatus": "สถานะตัวเลือกสินค้า",
    "createnext": "สร้าง & ถัดไป",
    "sizelimittip": "น่าจะอยู่ระหว่าง 1-999,99!",
    "pricelimittip": "ราคาซื้อควรอยู่ระหว่าง 0-1,000,000,000!",
    "weightlimittip": "น้ำหนักควรอยู่ระหว่าง 1-5,000,000!",
    "validatorsize": "ควรอยู่ระหว่าง 1-999,99",
    "Barcodelengthtip": "บาร์โค้ดไม่ควรเกิน 30 ตัวอักษร",
    "Barcodeerror": "บาร์โค้ดรองรับเฉพาะตัวอักษร ตัวเลข และ -_",
    "mustbiggerthan": "ไม่น้อยกว่า (สต็อกสำรอง + ล็อกสต็อก)",
    "mustlessthan": "ควรน้อยกว่า (คลังสินค้า Stock-Locked Stock)",
    "PackageSize": "ขนาดพัสดุ",
    "inputsafetyStock": "ควรอยู่ระหว่าง 0 ถึง 999,999",
    "inputspareStock": "ควรอยู่ระหว่าง 0 ถึง 999,999",
    "inputwarehouseStock": "ควรอยู่ระหว่าง 0 ถึง 999,999",
    "safetyStockisRequired": "สต็อกปลอดภัย ควรอยู่ระหว่าง 0 ~ 999,999!",
    "spareStockisRequired": "สต็อกสำรองควรอยู่ระหว่าง 0 ถึง 999,999!",
    "warehouseStockisRequired": "สต็อกคลังสินค้าควรอยู่ระหว่าง 0 ถึง 999,999!",
    "namerequired": "ชื่อควรอยู่ระหว่าง 1 - 160 ตัวอักษร",
    "Barcode": "บาร์โค้ด",
    "addprice": "ราคาซื้อขาเข้า (Rp)",
    "averageprice": "ราคาซื้อเฉลี่ย (Rp)",
    "ItemInformation": "ข้อมูลสินค้า",
    "stockimage": "รูปแบบ: jpg, jpeg, png. ขนาดภาพ: น้อยกว่า 5MB",
    "noresult": "ไม่มีการเปลี่ยนแปลง",
    "EMPTY": "สินค้าบนร้านค้ายังไม่ได้เชื่อมต่อ",
    "FAILEDcontent": "ล้มเหลว: ช่องทางร้านค้าไม่อนุญาตให้แก้ไขสต็อกนี้",
    "inventorylogtip": "Ginee จะลองอัปเดตสต็อกอีกครั้งโดยอัตโนมัติสำหรับร้านค้าที่ล้มเหลว คุณสามารถดูรายละเอียดการอัปเดตเพื่อตรวจสอบการอัปเดตใหม่ หรืออัปเดตใหม่ด้วยตนเอง",
    "FAILED": "ล้มเหลว",
    "PART_FAILED": "ล้มเหลวบางส่วน",
    "UpdateCompleted": "อัปเดตเสร็จแล้ว",
    "StockWarning": "คำเตือนสต็อก",
    "EXPIRED": "การลองใหม่ล้มเหลว",
    "RETRY_SUCCEEDED": "ลองอีกครั้งสำเร็จ",
    "SUCCEEDED": "ความสำเร็จ",
    "NEED_RETRY": "ล้มเหลว ลองอีกครั้ง...",
    "PROCESSING": "กำลังดำเนินการ",
    "UpdateDetails": "รายละเอียดการอัปเดตสต็อกของร้านค้า",
    "ManualUpdate": "อัปเดตด้วยตนเอง",
    "StoreUpdateResult": "ผลการอัปเดตร้านค้า",
    "Operator": "ผู้ดำเนินการ",
    "Latest": "ล่าสุด",
    "Movement": "การเปลี่ยนแปลง",
    "Original": "ต้นฉบับ",
    "updateDatetime": "อัพเดทเวลา",
    "StockUpdateFailed": "การอัพเดทล้มเหลว",
    "StockSuccessfullyUpdated": "อัปเดตเรียบร้อยแล้ว",
    "Updating": "กำลังอัปเดต",
    "UpdateToStore": "อัปเดตไปยังร้านค้า",
    "OrderCancel": "คำสั่งซื้อ { รหัสคำสั่งซื้อ } ถูกยกเลิก เหตุผล: { ReasonInfo }",
    "NewPaidOrder": "คำสั่งซื้อที่เพิ่งชำระใหม่",
    "UserEditStock": "ผู้ใช้แก้ไขสต็อก",
    "System": "ระบบ",
    "ChangeReason": "เหตุผลการเปลี่ยนแปลง",
    "OriginalStock": "สต็อกเดิม",
    "createDatetime": "สร้างเมื่อ",
    "StockTime": "เวลา",
    "StockUserName": "ชื่อผู้ใช้",
    "StockChangeHistory": "บันทึกการเปลี่ยนแปลงสต็อก",
    "clicktoset": "คลิกที่นี่",
    "synclosed": "ปิดใช้งานการเชื่อมต่อสต็อกอัตโนมัติแล้ว หากคุณแก้ไขจำนวนสต็อกของ SKU หลัก จะไม่ส่งผลต่อจำนวนสต็อกที่ขายบนช่องทางร้านค้า ต้องการเปิดใช้งานการเชื่อมต่อสต็อกอัตโนมัติหรือไม่?",
    "syncopend": "เปิดใช้งานการเชื่อมต่อสต็อกแล้ว เมื่อจำนวนสต็อกของ SKU หลักมีการอัปเดต จำนวนสต็อกที่มีขายบนช่องทางร้านค้าก็จะถูกอัปเดตตามไปด้วย",
    "skurelation": "โปรดใส่ SKUบนช่องทางร้านค้า เพื่อเชื่อมต่อกับ SKU และ SKUหลัก เมื่อสร้างSKUหลักสำเร็จ SKUบนช่องทางร้านค้าที่มีชื่อเดียวกันจะถูกเชื่อมต่อโดยอัตโนมัติ",
    "stockrequired": "สต็อกสินค้าต้องอยู่ระหว่าง 0-999,999!",
    "inventorySkurequired": "ต้องระบุ SKU หลัก!",
    "inputpurchasePrice": "ใส่ราคา",
    "inputname": "ใส่ชื่อ",
    "inputstock": "เฉพาะจำนวนเต็ม 0 - 999,999",
    "inputinventorySku": "กรอก SKU หลัก",
    "addsuccess": "เพิ่มสำเร็จแล้ว!",
    "deletesuccess": "ลบสำเร็จ!",
    "editsuccess": "แก้ไขสำเร็จแล้ว!",
    "afterdelete": "หลังจากลบSKU หลักแล้ว SKU บนช่องทางร้านค้าจะถูกลบออกด้วย",
    "suretodelete": "คุณแน่ใจหรือไม่ว่าต้องการลบSKU หลัก?",
    "products": "SKU หลัก",
    "failimport": "นำเข้าล้มเหลว:",
    "sucessimport": "นำเข้าสำเร็จ:",
    "totalimport": "การนำเข้าทั้งหมด:",
    "picture": "รูปภาพสินค้า",
    "skuList": "SKU (SKU ของร้านค้า)",
    "purchasePrice": "ราคาซื้อขาเข้า",
    "nameStock": "ชื่อ",
    "Stock": "สต็อก",
    "SoldOut": "ขายหมดแล้ว",
    "AddInventorySKU": "เพิ่ม SKU หลัก",
    "seacrh": "ค้นหา",
    "ExportAll": "ส่งออกทั้งหมด",
    "Exportbyselected": "ส่งออกตามที่เลือก",
    "DownloadImportTemplate": "ดาวน์โหลดเทมเพลตนำเข้าข้อมูล",
    "ImportInventorySKU": "นำเข้า SKU หลัก",
    "Import": "นำเข้า",
    "ok": "ยืนยัน",
    "editsku": "แก้ไข SKU หลัก",
    "addsku": "เพิ่ม SKU หลัก",
    "skuseacrh": "ค้นหาSKUหลัก、SKU、หัวข้อ",
    "skuplaceholder": "กรอก SKU (SKU ร้านค้าของคุณ)",
    "accordingToTheQueryConditionsYou": "ตามเงื่อนไขจากแบบสอบถามที่คุณกำหนดไว้ ค่าจัดส่งโดยประมาณจาก บริษัท โลจิสติกส์ดังนี้:",
    "calculationResults": "ผลการคำนวณ",
    "destination": "ส่งไปที่",
    "shipFrom": "ส่งจาก",
    "calculationFormula": "สูตรการคำนวณ",
    "checkShippingFee": "ตรวจสอบค่าจัดส่ง",
    "addAddress": "เพิ่มที่อยู่",
    "setAsDefault": "ตั้งค่าเป็นเริ่มต้น",
    "theBindingRelationshipBetweenJtExpress": "ความเกี่ยวข้องที่มีผลกันระหว่าง J&T Express (ID) และ GINEE ได้รับการยกขึ้นและคุณจะไม่สามารถใช้บริการที่เกี่ยวข้องกับโลจิสติกส์ใน GINEE ได้อีกต่อไปโปรดระวัง",
    "onceDeletedYouWillNoLonger": "เมื่อถูกลบแล้วคุณจะไม่สามารถใช้บริการโลจิสติกส์ J&T Express (ID) ใน GINEE และคำสั่งซื้อที่วางไว้จะไม่ว่างเปล่าโปรดดำเนินการอย่างระมัดระวัง",
    "confirmToDelete": "ยืนยันการลบ?",
    "shippingSettings1": "การตั้งค่าการจัดส่ง",
    "reasonTheAuthorizationInformationIsIncorrect": "เหตุผล: ข้อมูลการอนุญาตไม่ถูกต้องโปรดแก้ไขและอนุญาตใหม่",
    "youCanUseTheJtExpress": "คุณสามารถใช้บริการ J&T Express (ID) เพื่อประเมินค่าจัดส่ง> สั่งซื้อ> รับหมายเลข AWB> พิมพ์ฉลาก> ติดตามแพ็คเกจ",
    "maxCharactersOnlyNumbers": "สูงสุด {length} อักขระเฉพาะตัวเลขและตัวอักษร",
    "requiredToFillInPleaseGet": "จำเป็นต้องกรอก กรุณารับและกรอกข้อมูลตามคำสั่งอนุญาต",
    "withQuickAuthorizationYouDontNeed": "ด้วยการอนุญาตด่วน คุณไม่จำเป็นต้องติดต่อ J&T Express (ID) เพื่อรับข้อมูลที่จำเป็น และ Ginee จะสนับสนุนข้อมูลที่จำเป็นในการอนุญาต",
    "clickToViewAuthorizationInstruction": "คลิกเพื่อดูคำแนะนำการอนุญาต",
    "clickAuthorizeToBindAndIt": "เพื่อให้มีผลผูกพัน โปรดเลือก [อนุญาต] และจะรองรับการสั่งซื้อ / รับหมายเลขผู้จัดส่ง / ตรวจสอบค่าจัดส่ง / ติดตามพัสดุ",
    "toUseAStandardAuthorizationPlease": "หากต้องการใช้การอนุญาตมาตรฐาน โปรดติดต่อ J&T Express (ID) เพื่อรับข้อมูลที่จำเป็นสำหรับการอนุญาต",
    "recomend": "แนะนำ",
    "standardAuthorization": "อนุญาตมาตราฐาน",
    "quickAuthorization": "อนุญาตด่วน",
    "theSystemDetectsThatChannelProducts": "ระบบตรวจพบว่า SKU ของผลิตภัณฑ์ช่องว่างเปล่า โปรดยืนยันว่าจะใช้ชื่อข้อมูลจำเพาะและชื่อผลิตภัณฑ์เป็น MSKU?",
    "yesApplyToAllProducts": "ใช่ใช้กับสินค้าทั้งหมด",
    "productInbound": "สินค้าขาเข้า",
    "manualOutbound": "ขาออกด้วยตนเอง",
    "salesOutbound": "การขายขาออก",
    "theNumberOfConnectedStoresThat": "จำนวนร้านค้าที่เชื่อมต่อซึ่งเกินขีดจำกัดแพ็กเกจที่มีผล โปรดสมัครแพ็กเกจต่อไปหรือติดต่อฝ่ายบริการลูกค้าเพื่ออัปเกรดแพ็กเกจก่อนเปิดใช้งานร้านค้า",
    "subscriptionPackage": "สมัครสมาชิกแพ็คเกจ",
    "theStoreHasBeenActivated": "ร้านค้าเปิดใช้งานแล้ว!",
    "deleteSuccess": "ลบสำเร็จ",
    "fulfillConfirmDisconnectContent": "เมื่อลบแล้ว คลังสินค้า {fulfillment} และบริการคลังสินค้าพร้อมจัดส่งจะไม่สามารถใช้งานได้ หากคุณต้องการเปิดใช้งานอีกครั้ง โปรดให้สิทธิ์อีกครั้ง",
    "unfold": "เพิ่มเติม",
    "collapse": "ย่อ",
    "expiredStatusComingSoon": "ใกล้จะหมดอายุ",
    "ForLogisticCompanyPleaseAdd": "6. สำหรับบริษัทโลจิสติก โปรดเพิ่มการขนส่งที่สามารถเลือกในคำสั่งซื้อที่เพิ่มด้วยตนเอง",
    "WarehouseAsWarehouseNameNot": "4.คลังสินค้าใช้ชื่อคลังสินค้าไม่ใช่เลขที่คลังสินค้า",
    "jnePleaseFillTheLogisticThat": "JNE (กรุณากรอกโลจิสติกที่สามารถเลือกได้ด้วยตนเอง)",
    "whetherBlacklist": "ไม่ว่าจะเป็นบัญชีดำ",
    "pleaseSetupShipmentSettingsFirstBefore": "กรุณาติดตั้งการตั้งค่าการขนส่งเป็นอันดับแรก ก่อนที่จะเลือกสถานที่ที่จะรับพัสดุ",
    "authorizeNow": "อนุญาตทันที",
    "pleaseRecheckAfterAuthorizeLogistic": "กรุณาตรวจสอบอีกครั้งหลังจากอนุญาตระบบขนส่ง",
    "temporaryNotAuthorize": "ไม่ได้รับอนุญาตชั่วคราว",
    "oddNumbers": "เลขเดี่ยว",
    "wholesalePrice": "ราคาขายส่ง",
    "addWholesalePrice": "เพิ่มราคาส่ง",
    "maxNumber": "จำนวนน้อยสุดต้องไม่น้อยกว่าหรือเท่ากับค่าต่ำสุด ตัวเลข",
    "maxNumberCannotBeLessThan": "จำนวนสูงสุดต้องไม่น้อยกว่าหรือเท่ากับค่าต่ำสุด ตัวเลข",
    "theWholesalePriceCannotBeHigher": "ราคาขายส่งต้องไม่สูงกว่าราคาขายสินค้า",
    "theWholesalePriceOfCannot": "ราคาขายส่งของ {a} ไม่สามารถสูงกว่าราคาขายส่งของ {b}",
    "wholesalePricesAreOnlyAvailableTo": "ราคาขายส่งจะเพิ่มได้ก็ต่อเมื่อทุกแบบมีราคาเท่ากัน",
    "productNameMinCharacter": "ชื่อสินค้าต้องไม่น้อยกว่า  {min}  ตัวอักษร",
    "productNotCanNotLessThan": "สินค้าไม่น้อยกว่า {min} ภาพ",
    "breadCrumbProductList": "รายการสินค้า /{channel}",
    "storePermission": "สิทธิ์การเข้าถึงร้านค้า",
    "channelPermission": "สิทธิ์การเข้าถึงช่องทาง",
    "masterProductPermission": "สิทธิ์การเข้าถึงสินค้าหลัก",
    "priceManagementPermission": "สิทธิ์การจัดการราคา",
    "toEnsureTheAccuracyOfAvailable": "เพื่อให้แน่ใจว่าสต็อคที่มีอยู่ถูกต้อง โปรดคลิก \"อัพเดตสต็อคที่มีอยู่\" เพื่อรับมูลค่าสต็อคล่าสุด",
    "masterUpdateAvailableStock": "อัพเดทสต๊อกสินค้า",
    "pleaseSelectCompleteReceiverArea": "โปรดเลือกภูมิภาคผู้รับ",
    "toCalculateShippingCostNeedTo": "ในการคำนวณค่าขนส่งต้องกรอกข้อมูลในการจัดส่งและน้ำหนักหีบห่อ",
    "calculateShippingFee": "คำนวณค่าขนส่ง",
    "detailAddress": "รายละเอียดที่อยู่",
    "needToSelectAreaDimension": "ต้องเลือกขนาดพื้นที่",
    "maximumCharactersNumbersOnly": "สูงสุด 30 ตัวอักษร ตัวเลขเท่านั้น",
    "maximumCharactersLettersOnly": "สูงสุด 30 ตัวอักษร ตัวอักษรเท่านั้น",
    "site": "ตำแหน่ง",
    "gineeErrorUrllabelEmptyPleaseTry": "ginee error: URL คำสั่งซื้อว่างเปล่า โปรดลองเชื่อมต่อคำสั่งซื้ออีกครั้ง",
    "singleProductWeight": "น้ำหนักผลิตภัณฑ์เดียว",
    "calculate": "คำนวณ",
    "youDontHaveThisStorePermission": "คุณไม่ได้รับอนุญาตจากร้านนี้",
    "logisticsServices": "บริการโลจิสติกส์",
    "theMaximumLengthIsCharacters": "ความยาวสูงสุดคือ {maxLength} อักขระ",
    "averagePurchasePriceCurrentInbound": "ราคาซื้อเฉลี่ย = (จำนวนขาเข้าปัจจุบัน * ราคาซื้อขาเข้าปัจจุบัน + จำนวนเดิม * ราคาซื้อเดิม) / (จำนวนขาเข้าปัจจุบัน + จำนวนเดิม)",
    "tiktokMassDeleteTip": "เนื่องจากข้อจำกัด API จาก Tiktok จำนวนรายการที่จะลบต่อครั้งต้องไม่เกิน 20 รายการ โปรดเลือกอีกครั้ง",
    "validityofauthorization": "ระยะเวลาการอนุญาต",
    "channelProductMassEdit": "รายการสินค้า /{channel}/ฉบับร่าง เป็นกลุ่ม",
    "dataPermission": "สิทธิ์ของข้อมูล",
    "ruleTypeSpecifyMsku": "ประเภทกฎ: ระบุ MSKU",
    "ruleTypeShopRules": "ประเภทกฎ: กฎของร้านค้า",
    "addNewRules": "เพิ่มกฏใหม่",
    "delete1": "ลบ",
    "requiredItemLockSettingIf": "รายการที่จำเป็น การตั้งค่าการล็อก - หากเปิดใช้การตั้งค่าการล็อกสินค้าคงคลังของร้านค้าหลัก เมื่อสต็อค MSKU ใน \"นโยบายผลักดัน\" น้อยกว่าหรือเท่ากับ \"เกณฑ์\" การพุชสต็อคที่มีอยู่สำหรับร้านค้าอื่นยกเว้นร้านค้าที่เลือกคือ 0",
    "setupPushStore": "ตั้งค่าผลักดันร้านค้า",
    "requiredItemPleaseEnterAnInventory": "รายการที่จำเป็น โปรดป้อนมูลค่าสินค้าคงคลังที่มากกว่าหรือเท่ากับ 0 และน้อยกว่าหรือเท่ากับ 99999",
    "setStockThresholds": "กำหนดเกณฑ์สต็อค",
    "pleaseEnterTheMskuToBe": "กรุณาใส่ MSKU ที่จะเพิ่ม หากมี MSKU หลายรายการ โปรดใส่ในแถวที่ต่างกัน จะใช้เวลาในการบันทึก",
    "manualSelection": "การเลือกด้วยตนเอง",
    "textRecognition": "บันทึกข้อความ",
    "addingMethod": "เพิ่มวิธีขั้นตอน",
    "specifyMsku1": "ระบุ MSKU",
    "allMskus": "MSKU ทั้งหมด",
    "addedMainStoreLock": "เพิ่มล็อคสต็อกร้านค้าหลัก",
    "afterTurnOnPleaseSetUp": "หลังจากเปิดแล้ว โปรดตั้งค่ากฎการล็อกร้านค้าหลัก MSKU เดียวรองรับการตั้งค่าการล็อกร้านค้าหลักเพียงรายการเดียว เมื่อปิดกฎนี้ กฎร้านค้าหลักทั้งหมดจะล้มเหลวโดยค่าเริ่มต้น",
    "turnOnMainStoreLocked": "เปิดล็อคร้านค้าหลัก",
    "lockSettings": "ล็อคการตั้งค่า",
    "clickToView": "คลิกเพื่อดู",
    "addMsku1": "เพิ่ม MSKU",
    "deleteMsku": "ลบ MSKU",
    "masterProductPicture": "สินค้าหลักและรูปภาพ",
    "pushMsku": "ผลักดัน MSKU",
    "ruleType": "ประเภทกฎ",
    "ruleInformation": "ข้อมูลกฎ",
    "massAdd": "เพิ่มแบบกลุ่ม",
    "add1": "เพิ่ม",
    "addNewRule": "เพิ่มกฎใหม่",
    "mainStoreLocked": "ล็อคร้านค้าหลัก",
    "operateBy": "ดำเนินการโดย",
    "operationModule": "โมดูลการทำงาน",
    "tipOnlySupportForQueryingOperation": "เคล็ดลับ: รองรับเฉพาะการสืบค้นบันทึกการดำเนินการในช่วง 30 วันที่ผ่านมา",
    "theRuleWasDeletedSuccessfully": "ลบกฎเรียบร้อยแล้ว",
    "theRuleWasSavedSuccessfully": "บันทึกกฎเรียบร้อยแล้ว",
    "ruleDisabledSuccessfully": "กฎปิดการใช้งานเรียบร้อยแล้ว",
    "afterEnablingTheInventoryWillBe": "หลังจากเปิดใช้งาน สินค้าคงคลังจะถูกคำนวณและผลักไปที่ร้านค้าช่องทางตามกฎปัจจุบัน",
    "areYouSureYouWantTo1": "คุณแน่ใจหรือไม่ว่าต้องการเปิดใช้งานกฎ",
    "afterDeletionTheRulesWillNo": "หลังจากลบแล้ว กฎจะไม่รองรับการจัดการและการใช้งานอีกต่อไป",
    "afterDisablingTheInventoryWillNo": "หลังจากปิดใช้งาน สินค้าคงคลังจะไม่ถูกคำนวณและผลักไปที่ร้านค้าช่องทางตามกฎปัจจุบัน",
    "doYouConfirmToTurnOff": "ยืนยันกฎการแบน?",
    "ifTheCustomRuleIsDisabled": "หากปิดใช้กฎที่กำหนดเอง สินค้าคงคลัง MSKU จะไม่ถูกผลักไปที่ช่องทางอีกต่อไป",
    "afterCheckingIfACustomRule": "หลังจากตรวจสอบแล้ว หากกฎที่กำหนดเองถูกเพิ่มลงใน MSKU และเปิดใช้งาน สินค้าคงคลังจะถูกคำนวณตามกฎที่กำหนดเองและผลักไปที่ร้านค้าช่องทาง",
    "afterAddingACustomRuleTo": "หลังจากเพิ่มกฎที่กำหนดเองลงใน MSKU แล้ว สินค้าคงคลังจะไม่ถูกผลักตามกฎเริ่มต้นอีกต่อไป",
    "applicationSettings": "การตั้งค่าแอพพลิเคชั่น",
    "newMskuAddedToWarehouseLater": "MSKU ใหม่: เพิ่มในคลังสินค้าช้ากว่าเวลาเปิดใช้งาน",
    "allMskusExcludingMskuThatAdded": "MSKU ทั้งหมด (ยกเว้น MSKU ที่เพิ่มในภายหลัง)",
    "allMskusIncludingMskuThatAdded": "MSKU ทั้งหมด (รวมถึง MSKU ที่เพิ่มในภายหลัง)",
    "newStoresStoresWhichAuthorizationTime": "ร้านค้าใหม่: ร้านค้าที่เวลาอนุญาตช้ากว่าเวลาเปิดใช้งาน",
    "allAuthorizedStoresConnectedStoresWhose": "ร้านค้าที่ได้รับอนุญาตทั้งหมด: ร้านค้าที่เชื่อมต่อซึ่งมีเวลาอนุญาตเร็วกว่าเวลาเปิดใช้งาน",
    "allConnectedStoresExcludingNewStores": "ร้านค้าที่เชื่อมต่อทั้งหมด ยกเว้นร้านใหม่ที่ได้รับอนุญาตในภายหลัง",
    "allConnectedStoresIncludingNewStores": "ร้านค้าที่เชื่อมต่อทั้งหมด รวมถึงร้านใหม่ที่ได้รับอนุญาตในภายหลัง",
    "pushRatio": "อัตราส่วนการผลักดัน",
    "availableStockTransitStock": "คลังสินค้าพร้อมส่ง + สต๊อกนำเข้า",
    "allWarehousesWithMskusInInventory": "คลังสินค้าทั้งหมดที่มี MSKUs อยู่ในสินค้าคงคลังและเปิดใช้งาน (ไม่รวมคลังสินค้าใหม่ที่เพิ่มในภายหลัง)",
    "newWarehouseWarehouseWhoseCreationTime": "คลังสินค้าใหม่: คลังสินค้าที่สร้างช้ากว่าเวลาเปิดใช้งาน",
    "allWarehousesWithMskuThatHave": "คลังสินค้าทั้งหมดที่มี MSKU ที่ขาเข้าและมีสถานะใช้งานอยู่ (รวมถึงคลังสินค้าใหม่ที่เพิ่มในภายหลัง)",
    "ruleStatus": "สถานะกฎ",
    "noteThisTimeIsTheTime": "หมายเหตุ: เวลานี้เป็นเวลาที่สวิตช์ \"การตั้งค่าสต็อค\" - \"การตรวจสอบสต็อคและการซิงโครไนซ์\" เปิดอยู่",
    "activateTime": "เวลาเปิดใช้งาน",
    "viewFailureData": "ดูข้อมูลฟีเจอร์",
    "backToRuleList": "กลับไปยังรายการกฎ",
    "fail": "ล้มเหลว",
    "sucess": "สำเร็จ",
    "dataProcessingCompleted": "การประมวลผลข้อมูลเสร็จสิ้น",
    "saveCurrentlyNotActive": "บันทึก,ไม่ได้ใช้งานอยู่ในขณะนี้",
    "afterActivatePleaseAddMskuAs": "หลังจากเปิดใช้งานแล้ว โปรดเพิ่ม MSKU โดยเร็วที่สุด มิฉะนั้นกฎจะไม่ทำงานอย่างถูกต้อง",
    "areYouSureWantToSave": "คุณแน่ใจหรือไม่ว่าต้องการบันทึกและเปิดใช้งานกฎ",
    "stockType1": "ชนิดสต๊อก",
    "warehouseName1": "ชื่อคลังสินค้า",
    "specifyMskuAfterCreatingTheRule": "ระบุ MSKU (หลังจากสร้างกฎ คุณต้องเพิ่ม MSKU ด้วยตัวเอง เฉพาะ MSKU ที่เพิ่มเท่านั้นที่จะถูกผลักตามกฎที่ตั้งไว้)",
    "storeRulesAfterCreatingTheRules": "กฎของร้านค้า (หลังจากสร้างกฎ คุณไม่จำเป็นต้องเพิ่ม MSKU ช่องทาง SKU ที่มี MSKU ที่ผูกไว้ในร้านค้าจะถูกผลักตามกฎที่ตั้งไว้)",
    "tipsWhenAddingRulesIn": "เคล็ดลับ: 1. เมื่อเพิ่มกฎในกลุ่ม ระบบจะเพิ่มชื่อกฎโดยอัตโนมัติ 2. เมื่อเพิ่มกฎ ระบบจะบันทึกเฉพาะข้อมูลกฎเท่านั้น และหลังจากบันทึกแล้ว โปรดตั้งค่า MSKU ที่ใช้ในรายการกฎ 3. สามารถเพิ่มได้สูงสุด 100 ใน 1 ครั้ง;",
    "clickToViewMsku": "คลิกเพื่อดู MSKU",
    "showAllStore": "แสดงร้านค้าทั้งหมด",
    "showAllWarehouses": "แสดงคลังสินค้าทั้งหมด",
    "executeTheRules": "ปฏิบัติตามกฎ",
    "fixedValue": "ค่าคงที่",
    "storeRule": "กฎของร้านค้า",
    "specifyMsku": "ระบุ MSKU",
    "operation": "ดำเนินการ",
    "createBy": "สร้างโดย",
    "ratioFixValue": "อัตราส่วน / ค่าคงที่",
    "ruleName": "ชื่อกฎ",
    "exactMatch": "คู่ที่เหมาะสม",
    "multipleMskusSeparatedBySpacesMax": "MSKU หลายรายการคั่นด้วยช่องว่าง สูงสุด 50",
    "pleaseFillInTheCompleteRecipient": "กรุณากรอกข้อมูลที่อยู่ผู้รับให้ครบถ้วน",
    "pleaseSelectAPickupAddress": "กรุณาเลือกที่อยู่เข้ารับพัสดุ",
    "addMainStoreLock": "เพิ่มการล็อคร้านค้าหลัก",
    "editMainStoreLock": "แก้ไขการล็อคร้านค้าหลัก",
    "ratio": "อัตราส่วน",
    "thisAddressDoesNotCurrentlySupport": "ที่อยู่นี้ไม่รองรับการคำนวณค่าขนส่ง",
    "tExpressIdIsNotAuthorized": "J&T Express ID ไม่ได้รับอนุญาต การยกเลิกจะไม่แจ้งให้บริษัทขนส่งทราบ",
    "channelSku": "ช่องทาง SKU",
    "inventoryPushRulesMainStore": "สินค้าคงคลังผลักดันกฎการล็อคสินค้าคงคลังของร้านค้าหลัก",
    "inventoryPushRuleOperationLog": "สินค้าคงคลังผลักดันกฎและบันทึกการดำเนินงาน",
    "keywordsOfOperationContent": "คําสําคัญเนื้อหาการดําเนินงาน",
    "pleaseEnterMskuPleaseWrapMultiple": "กรุณาป้อน mscu หลาย mscu กรุณาตัดบรรทัดใหม่",
    "skuExactSearch": "การค้นหาที่แม่นยำสำหรับ SKU",
    "viewMskuApplicationRules": "เรียกดูกฎการผลักดันสต็อก",
    "showAllStores": "แสดงร้านค้าทั้งหมด",
    "bindingCommodityInformation": "ผูกข้อมูลผลิตภัณฑ์",
    "analogComputation": "การคํานวณการจําลอง",
    "unmatchedChannelGoods": "ผลิตภัณฑ์ช่องทางที่ไม่ได้จับคู่",
    "normal": "ปกติ",
    "viewRules": "ทบทวนกฎ",
    "promptThisFunctionSupportsViewingThe": "เคล็ดลับ: คุณลักษณะนี้รองรับการดูกฎที่ MSKUs ปัจจุบันใช้จริงเมื่อพุชสินค้าคงคลัง โดยมี MSKUs สูงสุด 50 รายการดูในแต่ละครั้ง",
    "newStoresWithoutSubsequentAuthorization": "(ไม่รวมร้านค้าใหม่สําหรับการอนุญาตในภายหลัง)",
    "newStoreWithSubsequentAuthorization": "(ร้านค้าใหม่ที่มีการอนุญาตในภายหลัง)",
    "allConnectedStores": "ร้านค้าที่เชื่อมต่อทั้งหมด",
    "pleaseTurnOnTheInventorySynchronization": "โปรดเปิดสวิตช์ซิงค์สินค้าคงคลังภายใต้เมนูการตั้งค่าสินค้าคงคลัง",
    "Ruledetails": "รายละเอียดกฎ",
    "Default": "ค่าเริ่มต้น",
    "thereAreDuplicates": "มีรายการที่ซ้ํากัน",
    "PleaseentertheMSKU": "กรุณากรอก MSKU ที่จะเพิ่ม ขึ้นบรรทัดใหม่หลายบรรทัด ไม่เกิน 200 ตัวอักษรในบรรทัดเดียว ระบบจะตัดให้อัตโนมัติหากเกิน",
    "noNeedToPush": "ไม่ต้องกด",
    "mskuDoesNotExist": "MSKU ไม่ได้อยู่",
    "mskuInventorypushInventory": "สินค้าคงคลัง MSKU/พุชสินค้าคงคลัง",
    "proportional": "สัดส่วน",
    "IfTheMskuForThe": "4. หาก MSKU สําหรับแผนกไม่ได้ถูกผลักไปยังบางช่องทางโปรดสร้างกฎ MSKU ที่ระบุที่ปิดใช้งาน",
    "IfSomeMskusNeedTo": "3. หากจําเป็นต้องผลักดัน MSKUs บางส่วนตามคลังสินค้า / ร้านค้าที่กําหนด / สัดส่วนที่ระบุหรือค่าคงที่โปรดสร้างกฎ MSKU ที่ระบุ",
    "IfTheChannelStoreNeeds": "2. หากร้านค้าช่องทางจําเป็นต้องใช้สินค้าคงคลังที่มีอยู่ของคลังสินค้าที่เปิดใช้งานที่กําหนดสําหรับการผลักดันโปรดสร้างกฎร้านค้ากําหนดความสัมพันธ์ระหว่างร้านค้ากับคลังสินค้าและวิธีการผลักดัน",
    "IfTheInventoryOfAll": "1. หากสินค้าคงคลังของร้านค้าช่องทางทั้งหมดถูกผลักตามเปอร์เซ็นต์ของสินค้าคงคลังที่มีอยู่ในคลังสินค้าที่เปิดใช้งานอย่างสมบูรณ์โปรดใช้กฎเริ่มต้นโดยตรงและกําหนดอัตราส่วนการผลักดัน",
    "directionsForUse": "คําแนะนําสําหรับการใช้งาน:",
    "youCanClickViewMskuApplication": "คุณสามารถคลิกปุ่ม \"ดูกฎแอปพลิเคชัน MSKU\" เพื่อสอบถามกฎที่ MSKU ที่ระบุใช้สําหรับการกดจริง",
    "thirdPriority": "ลําดับความสําคัญที่สาม",
    "secondPriority": "ลําดับความสําคัญที่สอง",
    "firstPrioritySpecifyMsku": "ลําดับความสําคัญแรก: ระบุ MSKU",
    "inventoryPushInstructions": "คําแนะนําการใช้งานแบบพุชสินค้าคงคลัง",
    "mskuCreationTimeIsLaterThan": "การสร้าง MsKU เกิดขึ้นช้ากว่าสวิตช์พุชสินค้าคงคลังตรงเวลา",
    "theStoreBindingTimeIsLater": "เวลาในการผูกร้านค้าช้ากว่าเวลาเปิดสวิตช์พุชสินค้าคงคลัง",
    "theWarehouseCreationTimeIsLater": "คลังสินค้าถูกสร้างขึ้นช้ากว่าสวิตช์พุชสินค้าคงคลังตรงเวลา",
    "reasonTheDefaultRuleConditionsAre": "สาเหตุ: เงื่อนไขกฎเริ่มต้นไม่เป็นไปตามโปรดสร้างกฎและผลักดันด้วยตัวคุณเอง",
    "reasonTheChannelStoreStatusIs": "เหตุผล: สถานะร้านค้าช่องไม่ได้รับอนุญาตโปรดเชื่อมต่อร้านค้าอีกครั้งและกด",
    "reasonThePushRuleForChannel": "สาเหตุ: กฎพุชที่ใช้โดยผลิตภัณฑ์ช่องทางถูกปิดใช้งานโปรดเปิดกฎและกด",
    "reasonInventorySettinginventoryPushSwitchIs": "สาเหตุ: การตั้งค่าสินค้าคงคลัง - สวิตช์กดสินค้าคงคลังไม่ได้เปิดอยู่โปรดเปิดสวิตช์หลังจากกด",
    "reasonTheStatusOfChannelGoods": "สาเหตุ: สถานะผลิตภัณฑ์ของช่องทางเป็นแบบร่างโปรดผลักดันผลิตภัณฑ์หลังจากลงรายการ",
    "xpriceProtection": "{X}% การปกป้องราคา",
    "order_628344ee0de79247bef8cb6694d1cd38": "ราคา/โปรโมชั่นไม่ถูกต้อง",
    "fuzzySearch": "ค้นหาแบบฟัซซี่",
    "preciseSearch": "ค้นหาแน่นอน",
    "historicalOrders": "คําสั่งซื้อในอดีต",
    "nearlyDaysOrder": "'คำสั่งซื้อทั้งหมดใน 90 วัน'",
    "tipChannelInterfaceRestrictionOnlySupports": "เคล็ดลับ: ข้อจํากัดอินเทอร์เฟซของช่องรองรับเฉพาะการอัปเดตสินค้าคงคลังของรายการที่แสดงหรือนําออกเท่านั้น",
    "theChannelStatusIsAbnormalPlease": "สถานะของผลิตภัณฑ์ช่องทางผิดปกติโปรดยืนยันและผลักดัน",
    "theWarehouseHasBeenDeactivatedPlease": "พื้นที่เก็บข้อมูลถูกปิดใช้งานโปรดเปิดใช้งานการผลักดันหลังจาก",
    "whenSearchingAccuratelyTheMaximumNumber": "เมื่อค้นหาอย่างแม่นยํา คุณสามารถสืบค้น {X} ได้สูงสุด และใช้การส่งกลับค่าขนส่งสําหรับหลายข้อความ และแต่ละบรรทัดจะแสดงข้อความ",
    "pleaseEnterTheOrderNumber": "กรุณากรอกหมายเลขคําสั่งซื้อ",
    "settingSuccess": "การตั้งค่าสำเร็จ",
    "insured": "มีประกัน",
    "insuranceRation": "อัตราประกัน",
    "pleaseSelectInsuranceService": "กรุณาเลือกบริการประกัน",
    "pleaseSettingInsuranceFeeForAkulaku": "กรุฯาตั้งค่าค่าธรรมเนียมประกันสำหรับออเดอร์",
    "settingInsurance": "การตั้งค่าประกัน",
    "proportionalInsurance": "ประกันตามสัดส่วน",
    "originalInsurancePrice": "ราคาประกันเดิม",
    "notInsured": "ไม่ได้รับประกัน",
    "plannedInboundAmount": "จำนวนขาเข้าที่วางแผนไว้",
    "actualInboundAmount": "จำนวนขาเข้าจริง",
    "shippingSubsidy": "เงินอุดหนุนค่าจัดส่ง",
    "platformSubsidy": "เงินอุดหนุนแพลตฟอร์ม",
    "spuAlreadyExistsPleaseRename": "SPU นี้มีอยู่แล้ว โปรดเปลี่ยนชื่อใหม่",
    "templateType": "ชนิดเทมเพลต",
    "labelSize": "ขนาดใบปะหน้า",
    "barcodePrintingTemplateSettings": "ตั้งค่าการพิมพ์เทมเพลตบาร์โค้ด",
    "fontSize": "ขนาดตัวอักษร",
    "howToGenerate": "วิธีสร้าง",
    "barcodeHeightInsideLabel": "ขนาดความสูงของบาร์โค้ดภายในของใบปะหน้า",
    "qrCode": "QR code",
    "qrCodeLocation": "QR Code สถานที่ตั้ง",
    "left": "ด้านล่าง",
    "top": "ด้านบน",
    "qrCodeSize": "ขนาด QR code",
    "generatedByMsku": "สร้างขึ้นโดย MSKU",
    "generatedByBarcode": "สร้างขึ้นโดย บาร์โค้ด",
    "generatedBySpu": "สร้างขึ้นโดย SPU",
    "mskuCode": "โค้ด MSKU",
    "printContent": "พิมพ์เนื้อหา",
    "customAdd": "กำหนดเพิ่ม",
    "goToTheMasterProductPage": "ไปที่หน้าสินค้าหลักและเลือกสินค้าที่จะพิมพ์",
    "customContent": "เนื้อหาที่กำหนดเอง",
    "pleaseInsertTheContentToBe": "กรุณาใส่เนื้อหาที่จะพิมพ์ ขึ้นบรรทัดใหม่หลายบรรทัด ไม่เกิน 200 ตัวอักษรในบรรทัดเดียว ระบบจะตัดให้อัตโนมัติหากเกิน",
    "numberOfPrintsreferringToTheNumber": "จำนวนพิมพ์ (หมายถึงจำนวนใบสำหรับบาร์โค้ด)",
    "paperPrintColumn": "คอลัมน์กระดาษพิมพ์",
    "columnGap": "ช่องว่างของคอลัมน์",
    "barcodePrinting": "การพิมพ์บาร์โค้ด",
    "atLeastCharactersOrMore": "อย่างน้อย 3 ตัวอักษรขึ้นไป",
    "theFuzzySearchContentMustExceed": "เนื้อหาการค้นหาที่คลุมเครือต้องมีอักขระมากกว่า 3 ตัว โปรดปรับและลองอีกครั้ง",
    "ordersCreatedForMoreThan": "คําสั่งซื้อที่สร้างมามากกว่า 90 วันจะถูกย้ายไปยังคําสั่งซื้อในอดีตและไม่สามารถดําเนินการได้",
    "areYouSureYouWantTo2": "คุณแน่ใจหรือไม่ว่าต้องการย้ายคำสั่งซื้อไปที่จัดส่งแล้ว",
    "moveToDelivered": "ย้ายไปจัดส่ง",
    "firstPriority": "ลําดับความสําคัญแรก",
    "supportAddingMaxDataAt": "รองรับการเพิ่มสูงสุด 2000 ข้อมูลในครั้งเดียว",
    "supportAddingMaxDataAt1": "รองรับการเพิ่มสูงสุด 1000 ข้อมูลในครั้งเดียว",
    "stockWillBeIncreasedAfterInbound": "สต็อกจะเพิ่มขึ้นหลังจากขาเข้า โปรดดำเนินการด้วยความระมัดระวัง รองรับเฉพาะขาเข้าจำนวนมากจากคลังสินค้าในพื้นที่",
    "afterConfirmationTheDocumentWillBe": "หลังจากยืนยัน เอกสารจะถูกส่งไปยังคลังสินค้าอีกครั้ง",
    "stockWillBeDeductedAfterOutbound": "สต็อคจะถูกหักหลังจากขาออก โปรดดำเนินการด้วยความระมัดระวัง",
    "Days1": "30 วัน",
    "Days": "7 วัน",
    "clickToDownloadAttachment": "คลิกเพื่อดาวน์โหลดไฟล์แนบ",
    "attachment": "ไฟล์แนบ",
    "addToTheFulfillmentWarehouse": "เพิ่มไปยังคลังสินค้า fulfillment",
    "lockedTime": "ล็อคเวลา",
    "LockedStock": "ล็อคสต็อก",
    "lockStockOrder": "ล็อคสต็อกคำสั่งซื้อ",
    "onlySupportsFilesDocXlsXlsx": "รองรับเฉพาะไฟล์ *.doc, *.xls, .xlsx, PDF, Excel, png, jpeg, JPG ขนาดน้อยกว่า 15M",
    "clickOrDragFilesHereTo": "คลิกหรือลากไฟล์ที่นี่เพื่ออัพโหลด",
    "clickToDeleteAttachment": "คลิกเพื่อลบเอกสารแนบ",
    "uploadAttachment": "อัพโหลดเอกสารแนบ",
    "vatRate": "อัตราภาษี (%)",
    "availableStockLocationCode": "รหัสที่ตั้งสต็อคพร้อมส่ง",
    "stockWil": "สต็อคจะถูกหักหลังจากส่งออก โปรดดำเนินการด้วยความระมัดระวัง รองรับเฉพาะขาออกจำนวนมากจากคลังสินค้าในพื้นที่",
    "stockWillBeReleasedAfterBeing": "สต็อกจะถูกปล่อยออกมาหลังจากถูกตั้งค่าเป็น Invalid โปรดดำเนินการด้วยความระมัดระวัง",
    "xDataSelected": "{x} ข้อมูลที่เลือก",
    "massSetAsInvalid": "การตั้งค่าไม่ถูกต้อง",
    "massPush": "ผลักดันจำนวนมาก",
    "massRefreshStatus": "รีเฟรชสถานะจำนวนมาก",
    "oneItemPerLineSupportExcel": "หนึ่งรายการต่อบรรทัด (รองรับการคัดลอกและวาง Excel สูงสุด 500 รายการ)",
    "clickToMassSearch": "คลิกเพื่อค้นหาจำนวนมาก",
    "salesOutboundList": "ใบคลังสินค้าขาออกโดยการขาย",
    "manualOutboundList": "ใบคลังสินค้าขาออกโดยการขาย",
    "addTemplate": "เพิ่มเทมเพลต",
    "massOutbound": "ส่งออกจำนวนมาก",
    "afterConfirmationTheSelectedDataWill": "หลังจากการยืนยันข้อมูลที่เลือกแล้ว จะถูกเพิ่มไปยังคลังสินค้า Fulfillment อีกครั้งโปรดดำเนินการอย่างระมัดระวัง",
    "massInbound": "สินค้าขาเข้าจำนวนมาก",
    "itIsDetectedThatTheIssue": "ตรวจพบว่าคําสั่งซื้อขาออกได้รับการอัปเดตไปยังไลบรารีที่รอดําเนินการหรือถูกทิ้งไปไม่รองรับการพุชโปรดรีเฟรชและลองอีกครั้ง",
    "itIsDetectedThatTheReceipt": "ตรวจพบว่าคําสั่งซื้อการจัดส่งได้รับการอัปเดตเป็นสถานะรอดําเนินการหรือเกษียณอายุแล้วไม่รองรับการกดโปรดรีเฟรชและลองอีกครั้ง",
    "refunding": "การคืนเงิน",
    "maxUploadDocuments": "สามารถอัปโหลดไฟล์ได้สูงสุด 3 ไฟล์",
    "thirdPartyWarehousePushTime": "เวลาที่ดันคลังสินค้าThird Party",
    "thirdPartyNumber": "คลังสินค้าที่สาม",
    "bulkSearch": "ค้นหาจำนวนมาก",
    "thisSizeAlreadyExists": "ขนาดนี้มีอยู่แล้ว",
    "theRangeOfSupportedInputIs": "ช่วงของอินพุตที่รองรับคือ {min}-{max}",
    "skuName": "ชื่อ SKU",
    "skusellerSKUInJDSellerCenter": "SKU = SKU ผู้ขายใน JD ศูนย์ผู้ขาย",
    "barcodeHeight": "ความสูงของบาร์โค้ด",
    "QRCodeLocation": "ที่ตั้งรหัส QR",
    "beLeft": "ซ้าย",
    "beTop": "ด้านบน",
    "actualPrintingPerformanceDependsOnPrintingResults": "ผลการพิมพ์จริงขึ้นอยู่กับผลการพิมพ์",
    "printingFailedReasonIsEmpty": "การพิมพ์ล้มเหลว สาเหตุ: {} ว่างเปล่าและไม่รองรับการพิมพ์",
    "goUpgrade": "อัพเกรด",
    "jtExpressIdOnlySupportsPaid": "J&T Express ID รองรับเฉพาะผู้ใช้งานแพ็คเกจแบบชำระเงิน โปรดอนุญาตอีกครั้งหลังจากอัปเกรดแพ็คเกจ ERP",
    "ordersAreOutOfStockAnd": "สินค้าหมดสต็อก ไม่อนุญาติให้ดันสต็อก",
    "thereAreCurrentlyXPiecesOf": "ขณะนี้มีข้อมูล {x} ชิ้น และสามารถส่งออกข้อมูลได้สูงสุด 50,000 ชิ้นในแต่ละครั้ง หากคุณต้องการส่งออกข้อมูลเพิ่มเติม โปรดติดต่อเจ้าหน้าที่บริการลูกค้า",
    "returnInboundListXSuccessfullyCreated": "ส่งคืนรายการขาเข้า: {x} สร้างสำเร็จ",
    "successXFailedX": "{x} สำเร็จ {y} ล้มเหลว",
    "homeCustomerTips": "ในระหว่างการส่งเสริมขนาดใหญ่ 99 ( 2022 - 08 - 20 : 00 - 2022 - 09 - 10 : 59 ) ผลลัพธ์จะออกมาเป็น 0",
    "orderOperationLogTips": "ระหว่างการส่งเสริมครั้งใหญ่ 99 ( 2022 - 09 - 08 : 00 - 2022 - 09 - 10 - 23 : 59 ) สนับสนุนเฉพาะในการดูบันท",
    "stockPushLogTips": "ในช่วงระยะเวลาโปรโมชั่น 99 (2022-09-08 20:00-2022-09-10 23:59) รองรับเฉพาะการดูบันทึกการดําเนินงานประจําวันเท่านั้น",
    "adjustmentMethodAll": "วิธีการปรับ: ทั้งหมด",
    "adjustmentMethodOutbound": "วิธีการปรับ: ขาออก",
    "adjustmentMethodInbound": "วิธีการปรับ: ขาเข้า",
    "numberRow1": "แถว 1",
    "numberRow2": "แถว 2",
    "numberRow3": "แถว 3",
    "numberRow4": "แถว 4",
    "theStoreAuthorizationIsAbnormalPlease": "การอนุญาตร้านค้าผิดปกติ โปรดไปที่ร้านของคุณเพื่อตรวจสอบการอนุญาต",
    "pleaseSetUpYourJtExpress": "โปรดตั้งค่าการจัดส่ง J&T Express ID ของคุณก่อน",
    "theRangeOfProductImagesThat": "ช่วงของรูปภาพผลิตภัณฑ์ที่สามารถอัปโหลดได้คือ {min}-{max}",
    "barcodeTemplate": "แม่แบบบาร์โค้ด",
    "typeDimension": "{type} มิติ",
    "channelOrderInTheCurrentOutbound": "คำสั่งซื้อช่องในรายการขาออกปัจจุบันหมดสต็อกและไม่เป็นไปตามเงื่อนไขการผลักดัน โปรดผลักดันหลังจากทำการแจกจ่ายในแท็บ \"คำสั่งกระบวนการสั่งซื้อที่รอดำเนินการสั่งซื้อ - ปัญหาการสั่งซื้อ\"",
    "supportsInputNumberFromMinToMax": "รองรับการใส่ตัวเลขตั้งแต่ {min} ถึง {max}",
    "inboundListNumber": "รายการขาเข้า",
    "inboundQuantity": "ปริมาณขาเข้า",
    "inboundWarehouse": "คลังสินค้าขาเข้า",
    "theTotalQuantityOfASingle": "จำนวนพิมพ์ทั้งหมดต้องไม่เกิน {0} โปรดลดจำนวนสินค้าหรือจำนวนการพิมพ์",
    "aPrintTemplateWithTheSame": "ชื่อเทมเพลตการพิมพ์มีอยู่แล้ว โปรดเปลี่ยนเป็นชื่ออื่น",
    "printTemplateTip": "หาก MSKU/บาร์โค้ด/SPU เกิน 23 อักขระ ระบบอาจไม่สามารถระบุบาร์โค้ดที่พิมพ์ได้ รหัส QR ไม่มีข้อจำกัดนี้ กรุณาเลือกประเภทรุ่นตามจริง",
    "printMaxlengthTip": "หากจำนวนอักขระเกิน 23 บาร์โค้ดที่พิมพ์ออกมาอาจไม่สามารถระบุได้",
    "pleaseSelectTheMainSku": "โปรดเลือก SKU หลัก",
    "adjustmentMethod": "วิธีการปรับ",
    "purchaseInbound": "ซื้อรายการขาเข้า",
    "outbound": "สินค้าหมด",
    "inbound": "คลังสินค้า",
    "manualAdjustment": "ปรับด้วยตนเอง",
    "sell": "ขาย",
    "pleaseTickTheDataFirst": "กรุณาเลือกข้อมูลก่อน",
    "changeValue": "ความเคลื่อนไหว",
    "latestLocationStock": "ตำแหน่งสต็อกล่าสุด",
    "locationNumber": "หมายเลขสล็อต",
    "postcode": "รหัสไปรษณีย์",
    "sucGeneratedOrFailed": "สร้าง {success} สำเร็จ {fail} ล้มเหลว",
    "printProductBarcode": "พิมพ์บาร์โค้ดสินค้า",
    "allPermissions": "ไม่มีสิทธิ์ย่อย",
    "pleaseSelectAtLeastOneExport": "โปรดเลือกเนื้อหาส่งออกอย่างน้อยหนึ่งรายการ",
    "pleaseSelectAtLeastOneBasic": "โปรดเลือกข้อมูลอย่างน้อยหนึ่งรายการ",
    "inboundType": "ประเภทขาเข้า",
    "orderIdXSuccessfullyReprintedThe": "หมายเลขคำสั่งซื้อ: {X} พิมพ์คำสั่งด่วนซ้ำเรียบร้อยแล้ว",
    "orderIdXSuccessfullyPrintedThe": "รหัสคำสั่งซื้อ: {X} พิมพ์ฉลากสำเร็จ",
    "setAsInvalidPopulerPrintOrder": "ตั้งเป็นงานสั่งพิมพ์สินค้ายอดนิยมที่ไม่ถูกต้อง: {X}",
    "createPopularProductPrintOrderTask": "สร้างงานสั่งพิมพ์สินค้ายอดนิยม: {X}",
    "theOrderIsAlreadyInThe": "งานสั่งพิมพ์สำหรับสินค้ายอดนิยมอยู่แล้ว กรุณาสั่งพิมพ์อย่างระมัดระวัง เพื่อป้องกันคำสั่งซื้อซ้ำ",
    "orderQuantity": "ปริมาณคำสั่งซื้อ",
    "numberOFProducts": "จำนวนสินค้า",
    "taskId": "ID งาน",
    "printProgress": "ความคืบหน้าการพิมพ์",
    "setAsInvalid": "ตั้งเป็นโมฆะ",
    "alreadySetAsInvalid": "ตั้งเป็นโมฆะแล้ว",
    "unfinished": "ยังไม่เสร็จ",
    "creating": "การสร้าง",
    "labelPrintingStatus": "สถานะการพิมพ์ใบปะหน้า",
    "additionalPrintLabel": "ป้ายพิมพ์เพิ่มเติม",
    "size": "ขนาด",
    "ordinaryPrintingButPrintingUpTo": "พิมพ์ธรรมดาแต่พิมพ์เท่าที่ 300 สั่งซื้อใช้เวลานาน",
    "selectedPrintDataXTheSystem": "ข้อมูลการพิมพ์ที่เลือก: {X} ระบบตรวจพบว่าใบปะหน้า {Y} ถูกทำเครื่องหมายและพิมพ์สำเร็จแล้ว และ {Z} ยังไม่ได้พิมพ์",
    "taskDetails": "รายละเอียดงาน",
    "toPrintLabel": "พิมพ์ใบปะหน้า",
    "manyProductsWithManyQuantity": "สินค้าตัวเลือกหลากหลาย หลายปริมาณ",
    "singleProductWithManyQuantity": "สินค้าชิ้นเดียวหลายปริมาณ",
    "singleProductWithSingleQuantity": "สินค้าชิ้นเดียวปริมาณเดียว",
    "theTaskIsBeingCreatedPlease": "กำลังสร้างงาน โปรดตรวจสอบจากรายการใบปะหน้าสินค้ายอดนิยมในภายหลัง",
    "createTask": "สร้างงาน",
    "noteOrdersThatHaveBeenPlaced": "หมายเหตุ: คำสั่งซื้อที่วางไว้ในงานพิมพ์ใบปะหน้าสินค้ายอดนิยมจะไม่เข้าสู่งานอื่น",
    "totalOrders": "คำสั่งซื้อทั้งหมด",
    "createdByFilter": "สร้างโดยการกรอง",
    "createByTick": "สร้างโดยการตรวจสอบ",
    "printPopularProduct": "พิมพ์ใบปะหน้าที่ขายดี",
    "adjustmentNo": "การปรับหมายเลขสต็อกเข้าออกใหม่",
    "warehouseReplacementTask": "งานเปลี่ยนคลังสินค้า",
    "warehouseChangeSucceeded": "การเปลี่ยนแปลงคลังสินค้าสำเร็จ",
    "warehouseChangeFailed": "การเปลี่ยนแปลงคลังสินค้าล้มเหลว",
    "currentWarehouse": "คลังสินค้าในปัจจุบัน",
    "newWarehouse": "ลังสินค้าใหม่",
    "processingProgress": "ความคืบหน้าในการประมวลผล",
    "adjustmentType": "ประเภทการปรับเปลี่ยน",
    "thisIsTheInboundPurchasePrice": "นี่คือราคาจัดซื้อเข้าคลังหรือราคาซื้อออกคลัง",
    "theFinalLocationInventoryOnlyIndicates": "สต็อกในตำแหน่งจัดเก็บสินค้าล่าสุดแสดงเฉพาะสต็อคสินค้าที่อยู่ในตำแหน่งจัดเก็บสินค้า สต็อคคลัง SKU=สต็อกรวมทั้งหมดของ SKU ในตำแหน่งจัดเก็บสินค้าในคลัง",
    "changeDimension": "เปลี่ยนมิติ",
    "Replace": "เปลี่ยนใหม่",
    "completeInboundStatus": "สำเร็จแล้ว",
    "setTemplate": "ตั้งค่าเทมเพลต",
    "onlyNumberAndMaxLimit": "สูงสุด {max} ตัวอักษร ตัวเลขเท่านั้น",
    "minimalOrderForWholesaleMustBe": "สั่งซื้อขั้นต่ำสำหรับการขายส่งต้องมากกว่า1",
    "pleaseConfirmWhetherTheOrderMoved": "โปรดยืนยันว่าต้องการย้ายคำสั่งซื้อไปที่จัดส่งแล้วหรือไม่",
    "moveToShipping": "ย้ายเข้าขนส่ง",
    "priceMustBeCheaperThanThe": "ราคาจะต้องถูกกว่าก่อนหน้า",
    "specificationInformation": "ข้อมูลตัวแปร",
    "theThirdPartyLogisticsIsNot": "โลจิสติกส์ของบุคคลที่สามไม่ได้รับอนุญาต การยกเลิกคำสั่งซื้อจะไม่แจ้งให้บริษัทขนส่งทราบ",
    "deliverBy": "ส่งโดย",
    "pickupBy": "รับโดย",
    "ParcelInformation": "ข้อมูลแพ็คเกจสินค้า",
    "theSameOptionValueExists": "มีตัวเลือกเดียวกัน",
    "pleaseEnterOptionsSuchAsRed": "โปรดป้อนตัวเลือก เช่น สีแดง",
    "uploadFailed": "การอัพโหลดล้มเหลว",
    "youCanUploadUpToMaxcount": "สูงสุด สามารถอัปโหลดไฟล์ได้ {maxCount} ไฟล์ ยกเลิกการอัปโหลดไฟล์:",
    "fileSizeExceedsLimitMaxsize": "ขนาดไฟล์เกินขีดจำกัด: {maxSize}",
    "pictureResolutionExceedsTheLimit": "ความละเอียดของภาพเกินขีดจำกัด",
    "maxOrderCannotBeLessThan": "คำสั่งซื้อสูงสุดต้องไม่น้อยกว่าคำสั่งซื้อขั้นต่ำ",
    "clickDownloadAndTheSystemWill": "คลิกดาวน์โหลดระบบจะส่งออกตามเงื่อนไขตามตัวกรองที่เลือกในรายการคำสั่งซื้อและเวลาในการสร้างคำสั่งซื้อโปรดดำเนินการหลังจากทราบแล้ว",
    "wholesalePriceCanNotBeLower": "ราคาขายส่งต้องไม่ต่ำกว่า {m}% ของราคาเดิม โปรดตั้งราคาขายส่งที่เหมาะสม",
    "mustBeAtLeastX": "ขนาดต้องมีอย่างน้อย 300 x 300 พิกเซล ไม่เกิน 2MB รูปแบบ JPG หรือ PNG",
    "thereAreDocumentsThatHaveNot": "ไฟล์บางไฟล์ไม่ได้รับการอัปโหลดสำเร็จ",
    "ifYouContinueToSubmitThe": "หากคุณยังคงยืนยันส่งข้อมูล ข้อมูลที่ส่งจะละเว้นไฟล์ที่อัปโหลดไม่สำเร็จ",
    "continueToSubmit": "ยืนยันส่งข้อมูล",
    "thirdPartyLogisticsOnlySupportsThe": "โลจิสติกส์ของบุคคลที่สามรองรับการใช้งานจากผู้ใช้งานแพ็คเกจแบบชำระเงินเท่านั้น โปรดอนุญาตอีกครั้งหลังจากอัปเกรดแพ็คเกจ ERP",
    "numbering": "หมาย เลข",
    "thereIsNoStoreThatSupports": "ไม่มีร้านรองรับการโคลน กรุณาเชื่อมร้านก่อน",
    "productCloneInProgress": "กำลังโคลนสินค้า...",
    "countProductClonedTo": "{count} สินค้าโคลนไปยัง",
    "exportProgress": "ความคืบหน้าการส่งออก",
    "exportType": "ประเภทการส่งออก",
    "copyToDraft": "คัดลอกไปยังร่าง",
    "invalid": "ไม่ถูกต้อง",
    "editImage": "แก้ไขภาพ",
    "editName": "แก้ไขชื่อ",
    "editDesc": "แก้ไขคำอธิบาย",
    "editInfo": "แก้ไขข้อมูล",
    "printLabelStatus": "สถานะการพิมพ์ใบปะ",
    "platformOrderNumber": "หมายเลขคำสั่งซื้อของแพลตฟอร์ม",
    "markAsFakeOrder": "สั่งซื้อของปลอม",
    "noteOrdersThatHaveBeenPlaced1": "หมายเหตุ: คำสั่งซื้อที่วางไว้ในงานพิมพ์รายการยอดนิยมจะไม่เข้าสู่งานอื่น",
    "theNumberOfVariantCannotExceed": "จำนวนตัวแปรต้องไม่เกิน {count}",
    "stepConfirmThePictureInformation": "ขั้นตอนที่ 1: ยืนยันข้อมูลรูปภาพ",
    "stepSelectTheProductYou": "ขั้นตอนที่ 2: เลือกสินค้าที่คุณต้องการนำมาใช้",
    "editImagesInBatches": "แก้ไขภาพเป็นชุด",
    "productImageTip": "เนื่องจากข้อจำกัดของ API สำหรับรูปภาพสินค้า สามารถลงได้ {maxLength} รูป ขนาดสูงสุด {size}MB และความละเอียดต้องมากกว่า {width}*{height}px",
    "fakeOrder": "คำสั่งซื้อปลอม",
    "whetherFakeOrder": "เป็นคำสั่งซื้อปลอมหรือไม่",
    "massSettingFakeOrder": "แท็กคำสั่งซื้อปลอม",
    "copyAsDraftTip": "สินค้าคัดลอกเป็นฉบับร่าง",
    "orderXCancelsTheFakeOrder": "คำสั่งซื้อ {X} ยกเลิกแท็กคำสั่งซื้อปลอม",
    "orderXIsMarkedAsA": "คำสั่งซื้อ {X} ถูกทำเครื่องหมายว่าเป็นคำสั่งซื้อปลอม",
    "fakeOrders": "คำสั่งซื้อปลอม",
    "menuProductShopify": "รายการสินค้า / Shopify",
    "menuProductShopifyAdd": "รายการสินค้า / Shopify / เพิ่มสินค้า",
    "menuProductShopifyEdit": "รายการสินค้า / Shopify / แก้ไขสินค้า",
    "restrictedByJtExpressTheChinese": "จำกัดโดย \"J&T Express\" \"ชื่อสินค้าภาษาจีน\" ต้องไม่เกิน 100 \"ชื่อสินค้าภาษาอังกฤษ\" ไม่เกิน 200 \"รหัสสินค้า\" ไม่เกิน 50 โปรดแก้ไขข้อมูลสินค้าและส่งสินค้าใหม่",
    "tryingToGetChannelShippingLabel": "พยายามที่จะรับข้อมูลใบจัดส่งจากช่องทางโปรด กรุณาผลักดันอีกครั้งในภายหลัง",
    "rangeFieldMessage": "ควรอยู่ระหว่าง {min}〜{max}",
    "duringThePromotionPeriod": "ในระหว่างการส่งเสริมขนาดใหญ่ ( 2022-10-09 20: 00-2022-10-10 23: 59 ) ผลลัพธ์จะออกมาเป็น 0",
    "onlySupportedDuringTheBigPromotion": "ระหว่างการส่งเสริมครั้งใหญ่ (2022-12-31 20: 00-2023-01-01 23: 59) สนับสนุนเฉพาะในการดูบันท",
    "onlyViewingTheOperationLogOf": "ในช่วงระยะเวลาโปรโมชั่น (2022-12-31 20: 00-2023-01-01 23: 59) รองรับเฉพาะการดูบันทึกการดําเนินงานประจําวันเท่านั้น",
    "gineeIsPushingInformationToThe": "จีนีกำลังส่งข้อความไปยังคลังสินค้าของคุณ รอผลลัพธ์จากโกดังของบุคคลที่สาม คุณสามารถดำเนินการต่อไปเกี่ยวกับการสั่งซื้ออื่น ๆ หรืออยู่ในรายการคำสั่ง ดูผลการส่ง",
    "theDistributedWarehouseTaskWasCreated": "การสร้างงานการขับเคลื่อนเรียบร้อยแล้ว",
    "clickToConfirmTheSystemWill": "หลังจากคลิกยืนยันระบบจะเรียกอินเทอร์เฟซคลังสินค้าของบุคคลที่สามเพื่อส่งข้อมูลให้คุณและผลการพุชต้องรอให้อินเทอร์เฟซคลังสินค้าของบุคคลที่สามกลับมาโปรดรออย่างอดทน",
    "confirmThatTheSelectedOrderWill": "ยืนยันว่าจะแจ้งใบสั่งซื้อที่เลือกไว้ให้ทราบถึงโกดังของบุคคลที่สาม ？",
    "tier": "ชั้น",
    "min": "ขั้นต่ำ",
    "max": "ขั้นสูง",
    "afterConfirmationTheOrderWillBe": "หลังจากยืนยันคำสั่งซื้อจะถูกผลัก โปรดรีเฟรชหน้าในภายหลังเพื่อดูผลการผลักดัน",
    "onceThePushFailsPleaseTry": "เมื่อการพุชล้มเหลว โปรดลองกดอีกครั้งใน Push Warehouse > Push Failed",
    "noteThe": "หมายเหตุ: มีความล่าช้าในสถานะการสั่งซื้อช่องและการอัปเดตป้ายกำกับ ซึ่งอาจทำให้การพุชล้มเหลว",
    "theOrderIs": "กำลังส่งคำสั่งซื้อไปยังคลังสินค้าบุคคลที่สามสำหรับคุณ โปรดอย่าดำเนินการซ้ำ โปรดเปิดคลังสินค้าพุช > ตรวจสอบความคืบหน้าในการพุช",
    "shipmentFailed": "การจัดส่งล้มเหลว",
    "afterAutomaticDeliveryIsEnabledThe": "หลังจากเปิดใช้งานการจัดส่งอัตโนมัติ คำสั่งซื้อที่ตรงตามเงื่อนไขการจัดส่งจะถูกกรอกข้อมูลในการจัดส่งโดยอัตโนมัติแล้วจึงจัดส่ง หากข้อมูลการจัดส่งไม่ตรงตามเงื่อนไขการจัดส่ง จะต้องดำเนินการจัดส่งด้วยตนเอง",
    "defaultFirst": "ค่าเริ่มต้นแรก",
    "defaultLast": "ค่าเริ่มต้นล่าสุด",
    "pickUpTime": "เวลารับพัสดุ",
    "automaticShipping": "การจัดส่งสินค้าอัตโนมัติ",
    "warehouseToBeAssigned": "คลังสินค้าที่จะได้รับมอบหมาย",
    "pushWarehouse": "ผลักดันคลังสินค้า",
    "tryingToLoad": "กำลังพยายามโหลด...",
    "orderTotalTotalProductAmount": "ยอดสั่งซื้อ = ยอดรวมสินค้า + ค่าขนส่ง + ภาษี + ประกัน + ค่าบริการ + ค่าคอมมิชชั่น - ส่วนลดทั้งหมด",
    "onceVoidedTheOrderWillBe": "เมื่อยกเลิกคำสั่งจะถูกย้ายไปเป็นยกเลิก",
    "addedChannelProducts": "เพิ่มช่องทางสินค้า",
    "syncedChannelProducts": "ซิงค์ช่องทางสินค้าแล้ว",
    "syncedChannelProductsChangedFrom": "ช่องทางสินค้าที่ซิงค์แล้ว {1} เปลี่ยนจาก {2} เป็น {3}",
    "produkChannelTelahDisinkronkanDanInformasi": "ซิงค์ช่องทางสินค้าแล้ว และข้อมูล SKU {} ถูกลบแล้ว",
    "theChannelProductsAreSynchronizedAnd": "ช่องทางสินค้ามีการซิงโครไนซ์ และช่องทางจะกลับสู่ SKU ของช่องใหม่{}",
    "editedProductNameFromTo": "แก้ไขชื่อสินค้าจาก {1} เป็น {2}",
    "editingProductCategoryFromTo": "การแก้ไขหมวดหมู่สินค้าจาก {1} เป็น {2}",
    "editingLongDesecriptionForVariant": "การแก้ไขคำอธิบายแบบยาวสำหรับตัวแปรจาก {0} ถึง {1}",
    "editingPriceForVariantFrom": "การแก้ไขราคาสำหรับตัวเลือกสินค้า {0} จาก {1} ถึง {2}",
    "editedStockChannelVariantName": "แก้ไขชื่อตัวแปรช่องสต็อก {0} จาก {1} เป็น {2}",
    "editedChannelSkuVariantName": "แก้ไขชื่อตัวแปรช่อง SKU {0} จาก {1} เป็น {2}",
    "deletedVariantType": "ประเภทตัวแปรที่ถูกลบ {}",
    "addedVariantType": "เพิ่มประเภทตัวแปร {}",
    "deletedOption": "ประเภทข้อมูลจำเพาะ {1} ลบตัวเลือก {2}",
    "addedOption": "ประเภทข้อมูลจำเพาะ  {1} เพิ่มตัวเลือก {2}",
    "updatedProductImage": "อัพเดทภาพสินค้า",
    "updatedVariantImageForVariatName": "อัปเดตรูปภาพตัวแปรสำหรับชื่อตัวแปร {}",
    "updatedOfProductSizeFrom": "อัปเดต {1} ขนาดสินค้าจาก {2} เป็น {3}",
    "updatedProductWeightChangedFrom": "อัปเดตน้ำหนักสินค้าเปลี่ยนจาก {0} เป็น {1}",
    "addTheCheckedLogisticAnd": "เพิ่มลอจิสติกส์ที่ตรวจสอบแล้ว {1} และโลจิสติกที่ไม่ได้ตรวจสอบ {2}",
    "deleteChannelProduct": "ลบช่องทางของสินค้า {}",
    "copyProductToChannelStore": "คัดลอกสินค้า {1} ไปยังช่อง {2} ร้านค้า {3}",
    "thisItemIsListed": "สินค้านี้อยู่ในรายการ{}",
    "thisItemHasBeenRemoved": "สินค้านี้ถูกเพิกถอน{}",
    "variationNa": "ชื่อรูปแบบ {0}, SKU ของช่อง {1}ตรงตามกฎการเชื่อมโยงอัตโนมัติ และเชื่อมโยงกับ MSKU {2}",
    "variationNameTheChannelSku": "ชื่อรูปแบบ {0}, SKU ของช่อง {1}เป็นไปตามกฎการสร้างอัตโนมัติ และสร้าง MSKU {2}",
    "variatio": "ชื่อรูปแบบคือ {0}, SKU ของช่องคือ {1}, ผูก MSKU ด้วยตนเอง{2}",
    "variationNameIsChannelSku": "ชื่อรูปแบบคือ {0}, SKU ของช่องคือ {1}, MSKU ที่สร้างด้วยตนเอง{2}",
    "mskumanuallyUnboundTheCorrespondingChannelSku": "MSKU{1}ยกเลิกการผูก SKU ของช่องที่เกี่ยวข้องด้วยตนเอง{2} ในหน้าสินค้าหลัก",
    "sinceStoreHasBeenDeletedchannel": "เนื่องจากร้านค้า {1} ถูกลบไปแล้ว\nSKU ของช่อง{2}ถูกเลิกผูกกับ MSKU{3}",
    "sinceMskuHasBeenRemovedchannelSkuwas": "เนื่องจาก MSKU{1} ถูกลบไปแล้ว\nSKU ของช่อง{2}ถูกเลิกผูกกับ MSKU{3}",
    "manuallyAddedMasterProduct": "เพิ่มสินค้าหลักด้วยตนเอง",
    "manuallyCreatedMasterProductByStore": "สร้างสินค้าหลักโดยร้านค้าด้วยตนเอง",
    "manuallyCreatedMasterProductByChannel": "สร้างสินค้าหลักด้วยตนเองตามช่องทางสินค้า",
    "createdMasterProductByImportData": "สร้างสินค้าหลักโดยการนำเข้าข้อมูล",
    "autoCreateMasterProduct": "สร้างสินค้าหลักโดยอัตโนมัติ",
    "createdMasterProductByScrapping": "สร้างสินค้าหลักโดยการ scrapping",
    "editedMasterProductNameFrom": "แก้ไขชื่อสินค้าหลักจาก {0} เป็น {1}",
    "editedMasterProductCategoryFrom": "แก้ไขหมวดหมู่สินค้าหลักจาก {0} ถึง {1}",
    "editedMasterProductSpuFrom": "แก้ไข SPU สินค้าหลักจาก {0} เป็น {1}",
    "editedMasterProductShortDescriptionFrom": "แก้ไขคำอธิบายสั้น ๆ ของสินค้าหลักจาก {0} ถึง {1}",
    "editedTheMasterProductLongDescription": "แก้ไขคำอธิบายแบบยาวของสินค้าหลักจาก {1} เป็น {2}",
    "editedMskuPriceFromTo": "แก้ไขราคา MSKU{0} จาก {1} เป็น {2}",
    "editedAvailableStockForMskuFrom": "แก้ไขสต็อคที่พร้อมใช้งานสำหรับ MSKU{0} จาก {1} ถึง {2}",
    "editedBarcodeOfMskuFrom": "แก้ไขบาร์โค้ด MSKU {0} จาก {1} เป็น {2}",
    "updatedOfMasterProductSize": "อัปเดต {1} ของขนาดสินค้าหลักจาก {2} เป็น {3}",
    "updatedTheWeightOfMasterProduct": "อัปเดตน้ำหนักของสินค้าหลักจาก {1} เป็น {2}",
    "updatedAvailableStockTheUpdatedStock": "อัปเดตสต็อกที่มีอยู่ สต็อกที่อัปเดตคือ {}",
    "deleteMaste": "ลบสินค้าหลัก {}",
    "deleteMskus": "ลบ MSKU{}",
    "printingSpus": "พิมพ์บาร์โค้ด SPU {}",
    "printingMskuBarcode": "พิมพ์บาร์โค้ด MSKU{}",
    "printingBarcode": "พิมพ์บาร์โค้ด {}",
    "mskuHasBeenAddedTo": "เพิ่ม MSKU{1} ใน คลังสินค้า{2} แล้ว",
    "mskuHasBeenAddedAndInbound": "เพิ่ม MSKU{1} และขาเข้าไปยังคลังสินค้า {2} แล้ว",
    "theAutomaticBindingOfMasterSku": "เปิดใช้งานการเชื่อมโยงอัตโนมัติของกฎ SKU หลัก กฎคือ: {}",
    "allStoresEnabledDefaultConfigurationFor": "ร้านค้าทั้งหมดเปิดใช้งาน (การกำหนดค่าเริ่มต้นสำหรับร้านค้าที่ได้รับอนุญาตใหม่)",
    "activateByChanelsChanels": "เปิดใช้งานโดยช่อง, ช่องทาง {}",
    "openByStoreStore": "เปิดใช้งานโดยร้านค้า ร้านค้า {}",
    "theAutomaticBindingOfTheMaster": "การผูกอัตโนมัติของกฎ SKU หลักถูกปิด",
    "theAutomaticCreateMasterSkuRule": "กฎการสร้าง SKU หลักโดยอัตโนมัติเปิดอยู่ กฎคือ：{}",
    "turnOffTheAutomaticCreateMaster": "กฎการสร้าง SKU หลักโดยอัตโนมัติปิดอยู่",
    "newPrice": "ราคาใหม่",
    "priceCannotBeModifiedDuringThe": "ไม่สามารถปรับเปลี่ยนราคาสินค้าได้ในช่วงระยะเวลาโปรโมชั่น",
    "thereAreDuplicateExportTasksRemaining": "งานส่งออกซ้ำ ที่มีอยู่ในปัจจุบันเวลาหมดอายุที่เหลือ: {0} s",
    "theSingleUrlCannotBeEmpty": "ไม่สามารถเว้น URL ได้ กรุณากดอีกครั้งในภายหลัง",
    "theLogisticsProviderCannotBeEmpty": "ผู้ให้บริการโลจิสติกส์ไม่สามารถเว้นว่างได้ โปรดติดต่อผู้ให้บริการคลังสินค้าเพื่อเพิ่มข้อมูลผู้ให้บริการโลจิสติกส์",
    "awbNumberCannotBeEmptyPlease": "ต้องระบุหมายเลข AWB โปรดกดอีกครั้งในภายหลัง",
    "syncSuccessfully": "ดันสต็อกสำเร็จ",
    "syncFailed": "ดันสต็อกไม่สำเร็จ",
    "sourceOfError": "ที่มาของความผิดพลาด",
    "channelErrorPleaseContactTheChannel": "ข้อผิดพลาดของช่อง: โปรดติดต่อเพื่อแก้ไขปัญหา",
    "warehouseErrorPleaseContactTheWarehouse": "ข้อผิดพลาดของคลังสินค้า: โปรดติดต่อผู้ให้บริการคลังสินค้าเพื่อแก้ไขปัญหา",
    "unknownErrorPleaseContactGineeErp": "ข้อผิดพลาดที่ไม่รู้จัก: โปรดติดต่อฝ่ายบริการลูกค้าของ Ginee OMS สำหรับการแก้ไขปัญหา",
    "recreate": "สร้างใหม่",
    "failedRecreate": "ล้มเหลวในการสร้างใหม่",
    "outboun": "หมายเลขคำสั่งซื้อขาออก: {x} หมายเลขคำสั่งซื้อที่เกี่ยวข้อง {y} สร้างสำเร็จแล้ว ผู้ดำเนินการ: {z} คลังสินค้าขาออก: {s}",
    "outOrderNumberXAssociatedOrder": "หมายเลขคำสั่งซื้อขาออก: {x} หมายเลขคำสั่งซื้อที่เกี่ยวข้อง {y} สร้างใหม่ได้สำเร็จ ผู้ดำเนินการ: {z} คลังสินค้าขาออก: {s}",
    "orderNumberXAssociatedOrder": "หมายเลขคำสั่งซื้อขาออก: {x} หมายเลขคำสั่งซื้อที่เกี่ยวข้อง {y} ไม่สามารถสร้างใหม่ได้",
    "rderNumberXAssociatedOrder": "หมายเลขคำสั่งซื้อขาออก: {x} หมายเลขคำสั่งซื้อที่เกี่ยวข้อง {y} \"ไม่ได้ถูกผลัก\"; ผู้ดำเนินการ: {z} คลังสินค้าขาออก: {s};",
    "oer": "หมายเลขคำสั่งซื้อขาออก: {x} หมายเลขคำสั่งซื้อที่เกี่ยวข้อง {y} \"ผลักดันสำเร็จ\"; ผู้ดำเนินการ: {z} คลังสินค้าขาออก: {s}",
    "tboundder": "หมายเลขคำสั่งซื้อขาออก: {x} หมายเลขคำสั่งซื้อที่เกี่ยวข้อง {y} \"การกดล้มเหลว\"; ผู้ดำเนินการ: {z} คลังสินค้าขาออก: {s}; สาเหตุความล้มเหลว: {a}",
    "odOrderNumberXAssociatedOrder": "หมายเลขคำสั่งซื้อขาออก: {x} หมายเลขคำสั่งซื้อที่เกี่ยวข้อง {y} \"ขาออกสำเร็จ\"; ผู้ดำเนินการ: {z} คลังสินค้าขาออก: {s}",
    "outboundOrderNumberXAssociatedOrder": "หมายเลขคำสั่งซื้อขาออก: {x} หมายเลขคำสั่งซื้อที่เกี่ยวข้อง {y} \"รีเฟรชสำเร็จแล้ว\"; ผู้ดำเนินการ: {z} คลังสินค้าขาออก: {s}",
    "inboundOrderNumberXAssociatedOrder": "หมายเลขคำสั่งซื้อขาเข้า: {x} หมายเลขคำสั่งซื้อที่เกี่ยวข้อง {y} \"การรีเฟรชล้มเหลว\"; ผู้ดำเนินการ: {z} คลังสินค้าขาออก: {s}; สาเหตุของความล้มเหลว: {a}",
    "orderwe": "หมายเลขคำสั่งซื้อขาออก: {x} หมายเลขคำสั่งซื้อที่เกี่ยวข้อง {y} \"ไม่ถูกต้อง\"; ผู้ดำเนินการ: {z} คลังสินค้าขาออก: {s};",
    "outboundOrderNumberXRelatedOrder": "หมายเลขคำสั่งซื้อขาออก: {x} หมายเลขคำสั่งซื้อที่เกี่ยวข้อง {y}; SKU: {w} \"ไม่ถูกต้อง\"; ผู้ดำเนินการ: {z} คลังสินค้าขาออก: {s};",
    "outboundOrderNumberXHasBeen": "หมายเลขคำสั่งซื้อขาออก: {0} ถูกลบแล้ว คำสั่งซื้อที่เกี่ยวข้อง {1} เหตุผลในการลบ: คำสั่งซื้อถูกจัดเก็บแล้ว",
    "inboundNoXPerformerYInbound": "หมายเลขขาเข้า: {x} ผู้ดำเนินการ: {y}, คลังสินค้าขาเข้า: {z} สร้างรายการขาเข้า",
    "inboundNoXNotPushedPerformer": "หมายเลขขาเข้า: {x}; \"ไม่ผลักดัน\"; ผู้ดำเนินการ: {y} คลังสินค้าขาเข้า: {z}",
    "inboundNumberXSuccessfulPushPerformer": "หมายเลขขาเข้า: {x}; \"ดันสำเร็จ\"; ผู้ดำเนินการ: {y} คลังสินค้าขาเข้า: {z}",
    "inboundNoXPushFailedPerformer": "หมายเลขขาเข้า: {x}; \"การกดล้มเหลว\"; ผู้ดำเนินการ: {y} คลังสินค้าขาเข้า: {z}; สาเหตุของความล้มเหลว {a}",
    "inboundNoXRefreshSuccessfulPerformer": "หมายเลขขาเข้า: {x}; \"รีเฟรชสำเร็จ\"; ผู้ดำเนินการ: {y} คลังสินค้าขาเข้า: {z}",
    "nomorInboundXRefreshGagalPerformer": "หมายเลขขาเข้า: {x}; \"การรีเฟรชล้มเหลว\"; ผู้ดำเนินการ: {y} คลังสินค้าขาเข้า: {z}; สาเหตุของความล้มเหลว: {a}",
    "inboundNoXinvalidperformerYInboundWarehouse": "หมายเลขขาเข้า: {x};\"ไม่ถูกต้อง\";ผู้ดำเนินการ: {y} คลังสินค้าขาเข้า: {z}",
    "inboundNoXskuainvalidperformerYInboundWarehouse": "หมายเลขขาเข้า: {x};SKU:{a};\"ไม่ถูกต้อง\";ผู้ดำเนินการ: {y} คลังสินค้าขาเข้า: {z}",
    "inboundNoXwaitingInboundPerformerY": "หมายเลขขาเข้า: {x};\"กำลังรอขาเข้า\"; ผู้ดำเนินการ: {y} คลังสินค้าขาเข้า: {z}",
    "receiptNoXinboundPerformerYInbound": "หมายเลขขาเข้า: {x};\"ขาเข้า\"; ผู้ดำเนินการ: {y} คลังสินค้าขาเข้า: {z}",
    "push": "ผลักดัน",
    "productsChannelEditProduct": "รายการสินค้า / {channel} / แก้ไขสินค้า",
    "productsChannelAddProduct": "รายการสินค้า / {channel} / เพิ่มสินค้า",
    "dueToPossibleDelaysInSyncing": "เนื่องจากอาจเกิดความล่าช้าในการซิงค์ข้อมูล โปรดรอสักครู่หลังจากการซิงค์ก่อนที่จะเลือกแบรนด์",
    "pleaseEnterBrandKeywordsToSync": "โปรดป้อนคีย์เวิร์ดของแบรนด์เพื่อซิงค์",
    "cantFindTheBrandClickMe": "ไม่พบแบรนด์? คลิกฉันเพื่อซิงค์",
    "outboundCompletionTime": "เวลาเสร็จสิ้นการออกจากห้องสมุด",
    "theImageAspectRatioIsInvalid": "อัตราส่วนภาพไม่ถูกต้องรองรับเฉพาะรูปภาพที่มีอัตราส่วนกว้างยาว {ratio} เท่านั้น",
    "theFileWillBeDisplayedSynchronously": "ไฟล์จะแสดงพร้อมกันในรายการประวัติการส่งออกและไฟล์จะถูกเก็บไว้เป็นเวลา 15 วันเท่านั้นดังนั้นโปรดดาวน์โหลดให้ทันเวลา",
    "exportRecords": "ส่งออกระเบียน",
    "theFollowingOrdersNeedToBe": "คำสั่งซื้อต่อไปนี้จำเป็นต้องเพิ่มข้อมูลการจัดส่งก่อนจัดการการจัดส่ง Ginee ได้กรองคำสั่งซื้อ Shopee {x} โดยอัตโนมัติซึ่งสถานะยังคงรอดำเนินการอยู่และไม่ได้รับอนุญาตให้จัดส่ง",
    "arrangementFailed": "การเตรียมการล้มเหลว",
    "arranging": "การเตรียมการ",
    "pleaseGoToPendingPrintOrder": "โปรดไปที่ คำสั่งพิมพ์ที่รอดำเนินการ เพื่อสร้างงานพิมพ์ใบปะหน้าพัสดุรายการด่วน",
    "limitasiApiSkuTidakDapatDi": "เนื่องจากข้อจำกัดของ API จึงไม่รองรับการแก้ไข SKUในขณะนี้",
    "ordersThatAreNotBoundTo": "สั่งซื้อที่ไม่ผูกพันกับ MSKU จะไม่ป้อนงานพิมพ์รายการร้อน",
    "customShop": "ร้าน",
    "continueCreate": "ดำเนินการสร้างต่อไป",
    "theStoreWasCreatedSuccessfully": "สร้างร้านค้าสำเร็จแล้ว",
    "warehouseLevelAllPages": "ระดับคลังสินค้า - ทุกหน้า",
    "warehouseLevelClickToCheck": "ระดับคลังสินค้า - โดยเลือกไว้",
    "locationLevelAllPages": "ระดับชั้นวาง - ทุกหน้า",
    "locationLevelClickToCheck": "ตำแหน่ง ระดับ คลิกเพื่อตรวจสอบ",
    "inboundManagementoperationLog": "บันทึกการจัดการ/การดําเนินการขาเข้า",
    "outboundManagementoperationLog": "บันทึกการจัดการ/การดําเนินการขาออก",
    "inboundNumberXxxxPushedToWarehouse": "หมายเลขขาเข้า: {xxxx} ถูกพุชไปยังคลังสินค้า: งาน {xxxx} ล้มเหลว",
    "reasonTheOrderNumberXxxxHas": "เหตุผล: หมายเลขคำสั่งซื้อ {xxxx} ตรงตามเงื่อนไขการผลักดัน",
    "inboundNoXxxxHasBeenSuccessfully": "หมายเลขขาเข้า: {xxxx} ถูกผลักไปที่คลังสินค้าเรียบร้อยแล้ว: {XXXX} และรับหมายเลขคลังสินค้า {XXXX}: #xxxxx#",
    "inboundNumberXxxxHasSuccessfullyCreated": "หมายเลขขาเข้า: {xxxx} ได้สร้างงานเพื่อส่งไปยังคลังสินค้าเรียบร้อยแล้ว: {XXXX} และรอการตอบกลับจาก {XXX} คลังสินค้า API",
    "xxWarehouse": "{XX} คลังสินค้า",
    "failureReasonXxxxErrorSourceXxxxx": "สาเหตุของความล้มเหลว: {XXXX} แหล่งที่มาของข้อผิดพลาด: {XXXXX}",
    "outboundNumberFailedToPushXxxx": "หมายเลขขาออก: ที่ไม่สามารถผลักดัน {xxxx} ถึง {XXXX}",
    "outboundNumberXxxxHasBeenSuccessfully": "หมายเลขขาออก: {xxxx} ถูกผลักไปที่ {XXXX} สำเร็จแล้ว และได้รับหมายเลขคลังสินค้าบุคคลที่สาม: {xxxxx}",
    "outboundNumberXxxxHasSuccessfullyCreated": "หมายเลขขาออก: {xxxx} ได้สร้างงานเพื่อส่งไปยังคลังสินค้าเรียบร้อยแล้ว: {XXXX} และกำลังรอการตอบกลับจาก API คลังสินค้า {fulfilment Provider}",
    "inboundOrderPushed": "คำสั่งซื้อขาเข้าถูกผลัก",
    "outboundOrderPushed": "คำสั่งขาออกที่ถูกผลัก",
    "sendersFullAddress": "ที่อยู่ผู้ส่ง",
    "sendersPostalCode": "รหัสไปรษณีย์ผู้ส่ง",
    "senderArea": "พื้นที่ผู้ส่ง",
    "manualPush": "กดด้วยตนเอง",
    "pendingOrderOldVer": "คำสั่งซื้อที่รอดำเนินการ (เวอร์ชันเก่า)",
    "pleaseGoToTheDeliverySettings": "โปรดไปที่การตั้งค่าการจัดส่งเพื่อกรอกข้อมูล",
    "noCustomStoreHasBeenDetected": "หากตรวจไม่พบร้านค้าที่กําหนดเองโปรดสร้างร้านค้าที่กําหนดเองก่อน",
    "goToCreate": "ไปที่สร้าง",
    "enableAtLeastOneVariation": "Enapur Atlis Onevariatiang.",
    "createdAChannelProductBy": "สร้างช่องทางสินค้า {} โดย {} วิธี จัดเก็บ {}",
    "storeSyncedChannelProduct": "จัดเก็บ {} ช่องทางสินค้าที่เชื่อมต่อ {}",
    "ChangedFromTo": "{} เปลี่ยนจาก {} เป็น {}",
    "deletedSkuWithVariantName": "SKU ที่ถูกลบด้วยชื่อตัวแปร {}",
    "addedSkuWithVarianName": "เพิ่ม SKU ด้วยชื่อตัวแปร {}",
    "throughStoreDeleted": "ผ่านวิธี {} เก็บ {} รายการช่องที่ถูกลบ {}",
    "deletedVar": "ลบชนิดตัวแปร {}",
    "addedVa": "เพิ่มชนิดตัวแปร {}",
    "updatedVarianImageWithVarianName": "อัปเดตรูปภาพตัวเลือกสินค้า พร้อมชื่อตัวเลือกสินค้า {}",
    "updatedProductSizeAndWeightInfo": "อัปเดตข้อมูลขนาดและน้ำหนักของสินค้า {1} เปลี่ยนจาก {2} เป็น {3}",
    "logisticsUpdatedChangedFrom": "อัปเดตการขนส่งแล้ว {1} เปลี่ยนจาก {2} เป็น {3}",
    "atLeastOneSpecificationIsEnabled": "เปิดใช้งานข้อมูลจำเพาะอย่างน้อยหนึ่งรายการ",
    "addMasterProduct": "เพิ่มสินค้าหลัก",
    "editMasterProduct": "แก้ไขสินค้าหลัก",
    "autoCreateMasterSku": "สร้าง SKU หลักโดยอัตโนมัติ",
    "autoBindMasterSku": "ผูก SKU หลักโดยอัตโนมัติ",
    "addToWarehouse": "เพิ่มในคลังสินค้า",
    "masterProductOperationLog": "บันทึกการดำเนินงานสินค้าหลัก",
    "solutionPleaseFirstCheckWhetherThe": "วิธีแก้ไข: โปรดตรวจสอบก่อนว่าร้านค้านั้นเป็นร้านค้าอย่างเป็นทางการหรือประเภทร้านค้าของผู้ค้าหรือไม่ หากไม่เป็นเช่นนั้น โปรดอัปเกรดร้านค้าในศูนย์ผู้ขายก่อน หากเป็นเช่นนั้น โปรดตรวจสอบว่าการอนุญาตของร้านค้าถูกเลิกผูกในศูนย์ผู้ขายหรือไม่",
    "updatedProductLengthFromTo": "อัปเดตความยาวของสินค้าจาก {0} เป็น {1}",
    "updatedProductWidthFromTo": "อัปเดตความกว้างของสินค้าจาก {0} เป็น {1}",
    "updatedProductHeightFromTo": "อัปเดตความสูงของสินค้าจาก {0} เป็น {1}",
    "addedMsku": "เพิ่ม MSKU{}",
    "onlyIntegersAreSupported": "รองรับเฉพาะจํานวนเต็มเท่านั้น",
    "masstSku": "แก้ไข SKU จำนวนมาก",
    "newOriginalPrice": "ราคาเดิมใหม่",
    "newselingPrice": "ราคานิวส์เซลิง",
    "applicationCompletion": "แอปเสร็จสมบูรณ์แล้ว",
    "editPrice": "แก้ไขราคา",
    "theStatusOfProductHas": "สถานะของสินค้า {1} เปลี่ยนจาก {2} เป็น {3}",
    "theChannelPriceOfMsku": "ราคาช่องของ MSKU {1} ในร้านค้า {2} ได้เปลี่ยนจาก {3} เป็น {4}",
    "defaultPriceOfMskuChanged": "ราคาเริ่มต้นของ MSKU {1} เปลี่ยนจาก {2} เป็น {3}",
    "solutionPleaseSetThePickupWarehouse": "วิธีแก้ไข: โปรดตั้งค่าข้อมูลคลังสินค้ารับสินค้าในบัญชีของฉัน>โปรไฟล์ผู้ขาย>คลังสินค้าในศูนย์ผู้ขาย",
    "afterSuccessfulAuthorizationYouCanUse": "หลังจากการอนุญาตสำเร็จ คุณสามารถใช้บริการเติมเต็มของ Keeppack เพื่อจัดการผลิตภัณฑ์ขาเข้าและขาออก ข้อมูลสต็อกและผลิตภัณฑ์ของคลังสินค้า Keeppack จะถูกซิงโครไนซ์แบบสองทางโดยอัตโนมัติเพื่อปรับปรุงประสิทธิภาพการจัดการ",
    "clickTheGoToAuthorizationButton": "คลิกปุ่ม [ไปที่การอนุญาต] คุณจะเข้าสู่หน้าเข้าสู่ระบบ Keeppack โปรดป้อนรหัสผ่านบัญชีที่ใช้เข้าสู่ระบบ Keeppack และหลังจากที่ระบบ Keeppack ยืนยันว่าข้อมูลการเข้าสู่ระบบของคุณถูกต้อง คุณจะอนุญาต Keeppack ได้สำเร็จ ระบบ Ginee",
    "beSureToCompleteTheMatching": "โปรดตรวจสอบให้แน่ใจว่าได้จับคู่สินค้าหลักใน [Warehouse-Keeppack Warehouse-Product Information]",
    "ifThereIsTooMuchCommodity": "หากมีข้อมูลสินค้ามากเกินไป จะใช้เวลานาน โปรดรอสักครู่",
    "imAutomaticallyPullingTheKeeppackWarehouse": "ดึงรายการคลังสินค้าและข้อมูลผลิตภัณฑ์ Keeppack ให้คุณโดยอัตโนมัติ",
    "automaticallyPullCommodityInformation": "ดึงข้อมูลสินค้าโดยอัตโนมัติ",
    "automaticallyPullWarehouseInformationValidWarehouse": "ดึงข้อมูลคลังสินค้าโดยอัตโนมัติ (จำกัดเฉพาะคลังสินค้าที่ถูกต้อง)",
    "senderMobilePhone": "โทรศัพท์ผู้ส่ง",
    "theBindingRecordIsOnlyValid": "บันทึกการผูกมัดมีอายุ 30 วันเท่านั้น และจะไม่สามารถดูบันทึกการผูกที่เกิน 30 วันได้",
    "pullRecord": "บันทึกการดึง",
    "batchPulling": "ดึงจำนวนมาก",
    "mskuBinding": "การรวม MSKU",
    "bindingMsku": "รวม MSKU",
    "pullTime": "เวลาในการดึง",
    "commodityValue": "มูลค่าสินค้า",
    "theTotalValueOfGoodsReturned": "มูลค่ารวมของสินค้าที่ส่งคืนโดยอินเทอร์เฟซคลังสินค้าบุคคลที่สามในคลังสินค้านี้",
    "totalWarehouseInventoryReturnedByThreeparty": "สต็อคคลังสินค้าทั้งหมดที่ส่งคืนโดยอินเทอร์เฟซคลังสินค้าบุคคลที่สาม",
    "inventoryWaitingForReceiptReturnedBy": "สต็อกที่รอขาเข้าที่ส่งคืนโดยอินเทอร์เฟซคลังสินค้าบุคคลที่สาม",
    "availableInventoryReturnedByThreepartyWarehouse": "สต็อกสินค้าที่ส่งคืนโดยอินเทอร์เฟซคลังสินค้าบุคคลที่สาม",
    "gineePushesItToTheWarehouse": "Ginee ดันการสร้างคลังสินค้า",
    "sanfangcangSku": "SKU คลังสินค้าบุคคลที่สาม",
    "nameOfGoodsInTheWarehouse": "ชื่อสินค้าในคลังสินค้าที่ส่งคืนโดยอินเทอร์เฟซคลังสินค้าบุคคลที่สาม",
    "commodityNameOfWarehouse": "ชื่อคลังสินค้า",
    "mskuIsBound": "MSKU ถูกผูกไว้",
    "unboundMsku": "MSKU ไม่ผูก",
    "startWithAndBeNo": "เริ่มต้นด้วย 0 และไม่เกิน 12 หลัก",
    "noMoreThanEnglishCharacters": "ไม่เกิน 35 ตัวอักษรภาษาอังกฤษ",
    "usedToPushReceiptDocumentsTo": "ใช้เพื่อผลักรายการขาเข้าไปยัง Keeppack",
    "xxxWarehousesHaveBeenAddedWith": "เพิ่มคลังสินค้า {XXX} รหัสคลังสินค้า: {XXX},{XXX}",
    "OnceTheKeeppackInterfaceUpdates": "2. เมื่ออินเทอร์เฟซ Keeppack อัปเดตสถานะคลังสินค้าเป็น Disabled คลังสินค้าใน Ginee จะถูกปิดใช้งานพร้อมกัน และข้อมูลสินค้า/สต็อกจะไม่สามารถซิงโครไนซ์ได้อีกต่อไป",
    "gineeOnlyReturnsTheWarehouseWith": "2. Ginee จะซิงค์เฉพาะอินเทอร์เฟซ Keeppack เพื่อให้คุณส่งคืนคลังสินค้าที่มีสถานะช่องเป็น Active",
    "warmTip": "เคล็ดลับ",
    "startPullingTheLatestWarehouseInformation": "หลังจากคลิก \"ยืนยัน\" มันจะเริ่มดึงข้อมูลที่เก็บล่าสุดของ Keeppack สําหรับคุณรอให้อินเทอร์เฟซ Keeppack ส่งคืนข้อมูลโปรดอดใจรอ",
    "pullWarehouseInformation": "ดึงข้อมูลคลังสินค้า",
    "pullLog": "ประวัติการดึง",
    "warehouseUpdateTimeReturnedByKeeppack": "เวลาอัปเดตของคลังสินค้าที่ส่งคืนโดยอินเทอร์เฟซ Keeppack",
    "warehouseCreationTimeReturnedByKeeppack": "เวลาสร้างคลังสินค้าที่ส่งคืนโดยอินเทอร์เฟซ Keeppack",
    "channelStatus": "สถานะช่องทาง",
    "warehouseId": "รหัสคลังสินค้า",
    "senderInformation": "ข้อมูลผู้ส่ง",
    "automaticCreationOfMainCommodity": "สร้างสินค้าหลักโดยอัตโนมัติ",
    "automaticUnbindingMsku": "ยกเลิกการเชื่อมโยง MSKU . โดยอัตโนมัติ",
    "automaticBindingMsku": "ผูก MSKU . โดยอัตโนมัติ",
    "manuallyCreateTheMainCommodity": "สร้างสินค้าหลักด้วยตนเอง",
    "manuallyUnbindMsku": "ยกเลิกการเชื่อมโยง MSKU . ด้วยตนเอง",
    "manuallyBindMsku": "ผูก MSKU . ด้วยตนเอง",
    "startBinding": "เริ่มการผูก",
    "andAllUnpairedThreepartyWarehouseSkus": "SKU ของคลังสินค้าบุคคลที่สามที่ยังไม่ได้จับคู่ใหม่ทั้งหมดในปัจจุบันและที่ตามมา",
    "allCurrentlyUnpairedThreepartyWarehouseSkus": "SKU ของคลังสินค้าบุคคลที่สามทั้งหมดที่ไม่ได้จับคู่ในขณะนี้",
    "appliedRange": "ขอบเขตการใช้งาน",
    "whenTheThreepartyWarehouseSkuIs": "เมื่อ SKU ของคลังสินค้าภายนอกตรงกับ MSKU ของสินค้าหลักที่สร้างไว้ทุกประการ จะถูกผูกไว้โดยอัตโนมัติ",
    "bindingRules": "กฎการผูกมัด",
    "reasonItConformsToTheMsku1": "เหตุผล: สอดคล้องกับการตั้งค่าสินค้า - คลังสินค้าสร้างกฎ MSKU โดยอัตโนมัติ",
    "reasonThreepartyWarehouseHasBeenUnbound": "เหตุผล: คลังสินค้าบุคคลที่สามถูกปลดออก",
    "reasonItConformsToTheMsku": "เหตุผล: สอดคล้องกับการตั้งค่าสินค้า - คลังสินค้าถูกผูกไว้กับกฎของ MSKU โดยอัตโนมัติ",
    "goodsOfWarehouseXxxxxXxxxxSku": "คลังสินค้า: สินค้าของ {XXXXX}: {XXXXX}, SKU: {XXXXXX} ของคลังสินค้าบุคคลที่สามสร้างสินค้าหลักในระบบสำเร็จ: {XXXXX}, MSKU: {XXXXXX}",
    "commodityXxxxxOfWarehouseXxxxxThreeparty": "คลังสินค้า: สินค้าของ {XXXXX}: {XXXX}, SKU: {XXXXX} และ MSKU: {XXXXX} ไม่ถูกผูกไว้ในคลังสินค้าบุคคลที่สาม",
    "commodityXxxxxOfWarehouseXxxxxAnd": "คลังสินค้า: สินค้าของ {XXXXX}: {XXXX}, SKU: {XXXXXX} และ MSKU: {XXXXXX} ของคลังสินค้าบุคคลที่สามถูกผูกไว้เรียบร้อยแล้ว",
    "viewThePullRecord": "ดูบันทึกการดึง",
    "gineeIsPullingCommodityInformationFrom": "Ginee กำลังดึงข้อมูลสินค้าจากคลังสินค้าให้คุณและรออินเทอร์เฟซคลังสินค้าบุคคลที่สามเพื่อส่งคืนผลลัพธ์ คุณสามารถดำเนินการประมวลผลเรื่องอื่น ๆ ต่อไปหรือดูความคืบหน้าในการดึงและผลลัพธ์ในบันทึกการดึง",
    "createAPullTask": "สร้างงานดึง",
    "commodityCreationTime": "เวลาสร้างสินค้า",
    "inOrderToImproveThePulling": "เพื่อปรับปรุงประสิทธิภาพการดึง ระยะเวลาของการดึงครั้งเดียวไม่ควรเกิน 15 วัน",
    "bindingResultXSkusHaveBeen": "ผลการผูก: การผูก {X} SKU และ MSKU อัตโนมัติเสร็จสมบูรณ์สำหรับคุณ",
    "automaticBindingSucceeded": "ผูกอัตโนมัติสำเร็จ",
    "mskuCreationIsNotSupportedFor": "SKU คลังสินค้าบุคคลที่สามที่ผูกกับ MSKU ไม่รองรับการสร้าง MSKU ระบบจะกรองให้คุณโดยอัตโนมัติ โปรดรับทราบ",
    "mainProductImage": "ภาพสินค้าหลัก",
    "AfterUnbindingTheStatusOf": "3. หลังจากยกเลิกการเชื่อมโยง สถานะของรายการขาเข้าและขาออกที่สอดคล้องกับ MSKU ของสินค้าในคลังสินค้าบุคคลที่สามจะถูกอัปเดตเป็น \"ไม่ถูกต้อง\"",
    "AfterUnbindingTheInventoryList": "2. หลังจากยกเลิกการเชื่อมโยง ข้อมูลสต็อคที่สร้างโดยการผูกมัดของ MSKU กับคลังสินค้าของบริษัทอื่นจะถูกล้าง",
    "AfterUnbindingTheBindingRelationship": "1. หลังจากยกเลิกการเชื่อมโยงแล้ว ความสัมพันธ์ที่มีผลผูกพันระหว่างคลังสินค้าบุคคลที่สามกับสินค้าหลักจะถูกปล่อยออกมา",
    "systemRecommended": "แนะนำระบบ",
    "listingPullFailed": "ไม่สามารถดึงข้อมูลสินค้า",
    "theListingWasPulledSuccessfully": "ดึงข้อมูลสินค้าเรียบร้อยแล้ว",
    "pullResultTheSkuInformationOf": "ผลการดึง: ข้อมูล SKU ของคลังสินค้าบุคคลที่สามถูกดึงออกมาแล้ว SKU: XXXX/XXXX",
    "sourceOfErrorRepository": "แหล่งที่มาของข้อผิดพลาด: คลังสินค้า",
    "reasonForFailureXxxxxx": "เหตุผลที่ล้มเหลว: XXXXXX",
    "pullResultAddedXxxPiecesOf": "ผลลัพธ์ดึง: ดึงข้อมูลไปยัง {XXX} ชิ้น",
    "resultcause": "ผลลัพธ์/เหตุผล",
    "pullStatus": "สถานะการดึง",
    "pullDuration": "ระยะเวลาดึง",
    "noteThatPullRecordsAreOnly": "โปรดทราบว่าบันทึกการดึงจะถูกเก็บไว้ในระบบเป็นเวลา 7 วันเท่านั้นและไม่สามารถสอบถามบันทึกการดึงที่เกิน 7 วันได้",
    "xxxData": "{XXX} ข้อมูล",
    "viewFailureInformation": "ดูข้อมูลความล้มเหลว",
    "upToCommoditiesCanBe": "รองรับการเลือกสูงสุดครั้งละ 50 รายการสินค้า เพื่อดึงข้อมูลเหตุการณ์",
    "activityInformationPullFailed": "ดึงข้อมูลโปรโมชันไม่สำเร็จ",
    "pullResultXxxChannelCommodityActivity": "ดึงผลลัพธ์: {X} ข้อมูลการโปรโมตสินค้าช่องได้รับการดึงเรียบร้อยแล้ว",
    "activityInformationPullSucceeded": "ดึงข้อมูลโปรโมชั่นเรียบร้อยแล้ว",
    "onlyTheActivityInformationOfChannel": "รองรับเฉพาะดึงข้อมูลโปรโมชั่นของผลิตภัณฑ์ช่องทางที่ซิงค์กับ Ginee และมีสถานะผลิตภัณฑ์อยู่ในรายการ",
    "pullByCommodity": "ดึงโดยสินค้า",
    "pullByStore": "ดึงโดยร้านค้า",
    "pullActivity": "ดึงโปรโมชั่น",
    "activeReservationSettings": "การตั้งค่าการจองโปรโมชั่น",
    "synchronizationTime": "เวลาซิงโครไนซ์",
    "mskuReserved": "MSKU & จองแล้ว",
    "skuApplication": "SKU & แอปพลิเคชัน",
    "notReserved": "ไม่ได้จอง",
    "partialReservation": "จองไว้บางส่วน",
    "reserved": "จองแล้ว",
    "activeReserveInventory": "สต็อกที่ถูกจอง",
    "pleaseTurnOnTheInventoryMonitoring": "โปรดเปิดสวิตช์ [ตรวจสอบสต็อกและซิงค์]] เพื่อใช้งาน",
    "afterTheSetupIsCompletedThe": "หลังจากเสร็จสิ้นการตั้งค่า สินค้าจะไม่ถูกจองล่วงหน้าสำหรับโปรโมชันที่กำลังจะมีขึ้น Ginee จะจองสต็อกโปรโมชั่นโดยอัตโนมัติสำหรับโปรโมชั่นต่อเนื่องและโปรโมชั่นที่จะเริ่มภายใน 24 ชั่วโมง",
    "automaticallyReservedOnlyForInprogressActivities": "สงวนไว้โดยอัตโนมัติสำหรับโปรโมชันที่กำลังดำเนินการ (เริ่มต้น) เท่านั้น",
    "onceSetUpTheSystemWill": "หลังจากตั้งค่าเสร็จ ระบบจะจองสต๊อกสินค้าที่ยังไม่ได้เริ่ม/อยู่ระหว่างดำเนินการโดยอัตโนมัติ",
    "unstartedAndInprogressActivitiesAreAutomatically": "โปรโมชันที่ยังไม่ได้เริ่มและต่อเนื่องจะถูกจองโดยอัตโนมัติ",
    "beSureToSetAReservation": "ตรวจสอบให้แน่ใจว่าได้กำหนดนโยบายการจองเพื่อให้แน่ใจว่าปกติสต็อกสินค้าที่ใช้งานอยู่นั้นได้รับการจองไว้ตามความต้องการทางธุรกิจ",
    "reservationPolicySettings": "ตั้งค่านโยบายการจอง",
    "reservationPolicy": "นโยบายการจอง",
    "afterItIsEnabledTheSystem1": "หลังจากเปิดใช้งาน ระบบจะจองสต็อกโปรโมชั่นโดยอัตโนมัติตามโปรโมชั่นแพลตฟอร์ม (รองรับเฉพาะแพลตฟอร์ม Shopee) ซึ่งสามารถดูได้ในรายการ [สต็อก - สต็อกที่ถูกจอง\n]/[สต็อก - รายชื่อสต็อก]",
    "automaticallyReserveActiveInventory": "จองสต็อกโปรโมชั่นโดยอัตโนมัติ",
    "afterItIsEnabledTheSystem": "หลังจากเปิดใช้งาน ระบบจะดึงข้อมูลกิจกรรมบนแพลตฟอร์ม Shopee ให้คุณโดยอัตโนมัติ ซึ่งสามารถดูได้ในรายการ [สินค้าคงคลัง - สินค้าสำรองสำหรับโปรโมชัน]",
    "automaticallyPullPlatformActivity": "ดึงกิจกรรมแพลตฟอร์มโดยอัตโนมัติ",
    "theClosedEventDataSystemIs": "ระบบข้อมูลเหตุการณ์ปิด จะถูกเก็บไว้เพียง 30 วัน เกิน 30 วันไม่สามารถดูในรายการนี้ได้",
    "reasonNotReserved": "เหตุผลที่ไม่จอง",
    "releaseTheReservation": "ปล่อยการจอง",
    "TheActiveInventoryReservedBy": "3. สต็อกที่ใช้งานที่จองไว้ของ MSKU ในคลังสินค้าจะถูกอัปเดตเป็น 0",
    "AfterReleaseTheChannelActivity1": "2. หลังการเผยแพร่แล้ว สินค้าคงคลังที่ใช้สำหรับการโปรโมตช่องทางจะไม่ได้รับผลกระทบและยังสามารถขายบนแพลตฟอร์มได้ โดยมีความเสี่ยงที่จะขายเกิน",
    "AfterReleaseTheChannelActivity": "1. หลังจากเผยแพร่แล้ว สถานะการโปรโมตช่องจะไม่ได้รับผลกระทบ",
    "confirmTheReleaseOfCurrentlyActive": "คุณแน่ใจว่าจะปล่อยสต็อกที่จองไว้ซึ่งใช้งานอยู่ในปัจจุบันหรือไม่?",
    "theActiveInventoryThatWasReserved": "เคลียร์สต๊อกสินค้าที่จองไว้เรียบร้อยแล้ว",
    "theActiveReservedInventoryWasEdited": "แก้ไขกิจกรรมสต็อกที่จองไว้เรียบร้อยแล้ว",
    "obligate": "จอง",
    "application": "แอปพลิเคชัน",
    "reservedInventoryEditsAreOnlySupported": "การส่งเสริมการขายที่ยังไม่เริ่มต้นสามารถแก้ไขสต็อกที่จองไว้ได้ รวมถึงการแก้ไขคลังสินค้าที่จองไว้และมูลค่าสต็อกที่จองไว้",
    "mskuHasNoWarehouseInformationAnd": "MSKU ไม่มีข้อมูลในคลังสินค้าและไม่สามารถจองได้สำเร็จ",
    "mskuWithUnboundChannelSkuPlease": "หาก SKU ของช่องทางไม่ได้เชื่อมกับ MSKU โปรดไปที่รายการสินค้าหลัก/รายการสินค้าของช่องทางเพื่อทำการเชื่อมโยงและสินค้าคงคลังให้สมบูรณ์",
    "automaticReservationOfActiveInventorySetting": "ยังไม่ได้เปิดใช้การตั้งค่าสินค้าคงคลังโปรโมชันการจองอัตโนมัติ",
    "commodityXxxMoveoutActivityIdxxx": "สินค้าช่อง: {XXX} รหัสรายการที่ถูกลบ: {XXX}",
    "xxxManuallyReleaseTheReservedActive": "{XXX} หักสินค้าคงคลังที่ใช้งานอยู่ที่จองด้วยตนเอง",
    "meetTheActiveInventoryReleaseRules": "การปฏิบัติตามกฎการหักสินค้าคงคลังที่ใช้งานอยู่ การหักอัตโนมัติ",
    "complyWithTheRulesOfAutomatic": "สอดคล้องกับกฎการจองอัตโนมัติของสินค้าคงคลังที่ใช้งานอยู่ การจองอัตโนมัติ",
    "deleteProduct": "ลบรายการ",
    "unbindTheMasterProduct": "เลิกผูกสินค้าหลัก",
    "bindTheMasterProduct": "ผูกสินค้าหลัก",
    "editedTheAutoBindMasterSku": "แก้ไขกฎ Auto Bind Master SKU กฎเปลี่ยนจาก {1} เป็น {2}",
    "editedTheAutoCreateMasterSku": "แก้ไขกฎการสร้าง Master SKU อัตโนมัติ กฎเปลี่ยนจาก {1} เป็น {2}",
    "createdMasterProductViaOpenapi": "สร้างสินค้าหลัก {0} ผ่าน Open-API",
    "manuallyCreatedMasterProductFrom": "สร้างสินค้าหลัก {} ด้วยตนเองจากคลังสินค้า",
    "automaticallyCreatedMasterProductFromWarehouse": "สร้างสินค้าหลักโดยอัตโนมัติ{}จากคลังสินค้า",
    "commonSearches": "การค้นหาทั่วไป",
    "moreSearches": "กรองเพิ่มเติม",
    "becauseChannelSkuHasBeen": "เนื่องจาก SKU ของช่อง {0} ถูกลบไปแล้ว SKU ของช่อง {1} จึงไม่ถูกผูกไว้กับ MSKU {2}",
    "updatedProductPriceFrom": "อัปเดตราคาสินค้า {0} จาก {1} เป็น {2}",
    "editedStockForProductFrom": "แก้ไขสต็อกสำหรับสินค้า {0} จาก {1} ถึง {2}",
    "editedChannelSkuForProduct": "อัปเดต SKU ของช่องสำหรับสินค้า {0} จาก {1} เป็น {2}",
    "channelProductMatchToAutobind": "ช่องทางสินค้า {0} ตรงกับกฎการเชื่อมโยงอัตโนมัติและเชื่อมโยงกับ MSKU {1}",
    "channelProductMatchesTheAutocreatedRule": "ช่องทางสินค้า{0} ตรงกับกฎที่สร้างอัตโนมัติ และสร้าง MSKU {1}",
    "channelProductIsManuallyBound": "ช่องทางสินค้า {0} ถูกผูกไว้กับ MSKU {1} ด้วยตนเอง",
    "channelProductManuallyCreatedMsku": "ช่องทางสินค้า {0} สร้าง MSKU ด้วยตนเอง {1}",
    "distribution": "การกระจาย",
    "removeSelectedLogistics": "ลบโลจิสติกส์ที่เลือก{}",
    "PickListIsPrinted": "รายการเบิกสินค้าถูกพิมพ์ออกมา",
    "printedPacking": "พิมพ์บันทึกการจัดส่ง",
    "InvoiceIsPrinted": "พิมพ์ใบแจ้งหนี้แล้ว",
    "printeLabels": "พิมพ์บันทึกการจัดส่ง",
    "bopis": "BOPIS",
    "bigProduct": "Big Product",
    "saveSearch": "บันทึกการค้นหา",
    "commonSearchNames": "ชื่อการค้นหาทั่วไป",
    "radio": "วิทยุ",
    "multiplechoice": "ทางเลือกมาก",
    "updateTheOrderStatus": "อัปเดตสถานะคําสั่งซื้อ",
    "updatedStockForProductFrom": "อัปเดตสินค้าคงคลังสำหรับรายการ {0} จาก {1} เป็น {2}",
    "mengeditDeskripsiPanjangMasterProdukDari": "แก้ไขคำอธิบายแบบยาวของสินค้าหลักจาก {0} ถึง {1}",
    "updatedAvailableStockForMskuTo": "อัปเดตสต็อคที่พร้อมใช้งานสำหรับ MSKU{} เป็น {}",
    "nameManagement": "การจัดการชื่อ",
    "nameList": "รายชื่อ",
    "ForChannelProductsWithVariations": "2. สำหรับช่องทางสินค้าที่มีรูปแบบต่างๆ ชื่อรูปแบบและชื่อสินค้าจะถูกใช้เป็น MSKU",
    "ChannelProductsWithoutVariationsUse": "1. ช่องทางสินค้าที่ไม่มีรูปแบบต่างๆ ใช้ SPU เป็น MSKU",
    "theSystemDetectsThatSomeSkus": "ระบบตรวจพบว่า SKU ของช่องทางสินค้าบางส่วนว่างเปล่า โปรดยืนยันว่าจะเปิดใช้งานกฎเริ่มต้นของระบบหรือไม่:",
    "updatedProductShortDescriptionFrom": "อัปเดตคำอธิบายสั้นๆ ของสินค้าจาก {0} เป็น {1}",
    "fuzzyOnlySupportsSingle": "เบลอรองรับเพียงอันเดียว",
    "scanShipment": "สแกนและจัดส่ง",
    "editMasterProductName": "แก้ไขชื่อสินค้าหลัก",
    "editChannelProductName": "แก้ไขชื่อสินค้าช่อง",
    "newProductName": "ชื่อผลิตภัณฑ์ใหม่",
    "utilityTool": "ยูทิลิตี้",
    "TimesScanningIsRequiredFor": "2 ครั้ง: ต้องสแกน 2 ครั้งการสแกนครั้งแรกจะแสดงข้อมูลคําสั่งซื้อบันทึกการสแกนครั้งที่สองจะถูกสร้างขึ้นและคําสั่งซื้อจะถูกย้ายไปยังคอลเลกชันที่รอดําเนินการตามการตั้งค่า",
    "TimeScanOnceGenerateA": "1 ครั้ง: สแกน 1 ครั้งสร้างบันทึกการสแกนและย้ายคําสั่งซื้อไปยังคอลเลกชันที่รอดําเนินการตามการตั้งค่า",
    "twice": "สองเท่า",
    "once": "หนึ่งครั้ง",
    "scanResultEffectiveTimes": "จํานวนครั้งที่ผลการสแกนมีผล",
    "afterScanningItWillAutomaticallyMove": "ย้ายเข้าโดยอัตโนมัติสําหรับคอลเลกชันหลังจากการสแกน",
    "scanSettings": "การตั้งค่าการสแกน",
    "scanned": "สแกน",
    "pleaseScanOrEnterTheCourier": "โปรดสแกนหรือป้อนหมายเลขติดตาม",
    "scanByCourier": "สแกนด้วยหมายเลขติดตาม",
    "afterTheScanIsSuccessfulThe": "หลังจากการสแกนสําเร็จคําสั่งซื้อจะถูกย้ายไปยังรายการรอดําเนินการโดยอัตโนมัติตามการตั้งค่าโปรดตรวจสอบให้แน่ใจว่าการตั้งค่าเสร็จสมบูรณ์แล้วและการตั้งค่าเริ่มต้นไม่ได้เปิดอยู่",
    "scanRecord": "สแกนบันทึก",
    "scanSucceeded": "การสแกนสําเร็จ",
    "xxxxnumberDoesNotExist": "ไม่มีหมายเลขคำสั่งซื้อ{xxxx}",
    "xxxxxHasBeenScannedPleaseDo": "{XXXXX} ได้รับการสแกนแล้วอย่าสแกนซ้ํา",
    "xxxxxIsNotASelfownedWarehouse": "{XXXXX}คําสั่งซื้อการจัดการคลังสินค้าที่ไม่ใช่ของเจ้าของ ไม่สามารถสแกนหาการจัดส่งได้",
    "xxxxxIsNotAnOrderIn": "{XXXXX} คําสั่งซื้อสถานะคําสั่งซื้อที่ไม่รอดําเนินการไม่สามารถสแกนหาการจัดส่งได้",
    "xxxxxDoesNotExistPleaseCheck": "ไม่มี {XXXXX} โปรดตรวจสอบและสแกนใหม่อีกครั้ง",
    "scanFailed": "การสแกนล้มเหลว",
    "pleaseScanOrEnterMskubarcode": "กรุณาสแกนหรือใส่ MSKU/บาร์โค้ด",
    "useState": "สถานะการใช้งาน",
    "shelfName": "ชื่อชั้นวาง",
    "informationShelf": "ข้อมูลชั้นวาง",
    "modifyShelves": "ปรับเปลี่ยนชั้นวาง",
    "commodityDetails": "รายละเอียดสินค้า",
    "storageRack": "ชั้น วาง",
    "pleaseSelectAWarehouseAndAdd": "โปรดเลือกคลังสินค้าและเพิ่มผลิตภัณฑ์",
    "pleaseChangeToEnInputMethod": "โปรดเปลี่ยนไปใช้วิธีการป้อนข้อมูล [EN] ก่อนทำการสแกน",
    "scanResults": "ผลการสแกน",
    "notScanned": "ไม่ได้สแกน",
    "scanStatus": "สถานะการสแกน",
    "shelfNumber": "หมายเลขชั้นวาง",
    "courierNumber": "หมายเลขพัสดุ",
    "sellerCashbackCoin": "เงินคืนจากผู้ขาย",
    "tax": "ยอดรวมภาษี",
    "sunTotal": "ยอดรวมราคาของสินค้า",
    "currentShelf": "ชั้นวางปัจจุบัน",
    "vacant": "ว่าง",
    "inboundAndOutboundRecordAdjustmentMethod": "วิธีการปรับปรุงเรกคอร์ดขาเข้าและขาออก = ผลรวมของเรกคอร์ดขาออกด้วยตนเอง (ราคาซื้อเฉลี่ย * ค่าการเปลี่ยนแปลง)",
    "adjustmentMethodOfInboundAndOutbound": "วิธีการปรับปรุงของเรกคอร์ดขาเข้าและขาออก = ผลรวมของเรกคอร์ดของยอดขายขาออก (ราคาซื้อเฉลี่ย * มูลค่าการเปลี่ยนแปลง)",
    "calculationDescriptionAccordingToTheExport": "คำอธิบายการคำนวณ: ตามเทมเพลตการส่งออกเวลาในการสร้างคำสั่งซื้ออยู่ภายในระยะเวลาตัวกรองและสถานะ = ยอดรวมของคำสั่งซื้อที่เสร็จสมบูรณ์ {XXXX} จำนวน",
    "allScanned": "สแกนทั้งหมด",
    "partiallyScanned": "สแกนบางส่วน",
    "warehousesKeeppackWarehousesListings": "คลังสินค้า / คลังสินค้า Keeppack / รายชื่อ / สร้างผลิตภัณฑ์หลัก",
    "warehouseKeeppackWarehouseProduct": "ข้อมูลคลังสินค้า / คลังสินค้า Keeppack / ผลิตภัณฑ์",
    "inOrderToImproveThePull": "เพื่อปรับปรุงประสิทธิภาพการดึงระยะเวลาของการดึงครั้งเดียวไม่ควรเกิน 15 วัน",
    "bindingRecordsAreOnlyValidFor": "บันทึกการผูกมัดมีอายุเพียง 30 วันเท่านั้นและไม่สามารถดูบันทึกที่มีผลผูกพันเกิน 30 วันได้",
    "pullItems": "ดึงรายการ",
    "onlyProductsIssuedByGineeAre": "แสดงเฉพาะผลิตภัณฑ์ที่ออกโดย Ginee เท่านั้น",
    "synchronizeTimeInDescendingOrder": "ซิงโครไนซ์เวลาตามลําดับจากมากไปหาน้อย",
    "synchronizeTimeInAscendingOrder": "ซิงโครไนซ์เวลาตามลําดับจากน้อยไปมาก",
    "areYouSureUnbindYouWantTo": "คุณแน่ใจหรือว่าต้องการยกเลิกการผูก?",
    "mskuCreationIsNotSupportedForTip": "การสร้าง MSKU ไม่ได้รับการสนับสนุนสําหรับ SKU คลังสินค้าของบริษัทอื่นที่ผูกกับ MSKUs และระบบจะกรองให้คุณโดยอัตโนมัติ",
    "pullTheProduct": "ดึงผลิตภัณฑ์",
    "beforeBatchEditingYoudBetterSynchronize": "ก่อนแก้ไขเป็นกลุ่ม คุณควรเชื่อมต่อสินค้าเพื่อให้ข้อมูลสอดคล้องกับศูนย์ผู้ขาย",
    "syncAndEdit": "เชื่อมต่อและแก้ไข",
    "skipAndEdit": "ข้ามและแก้ไข",
    "connectWithAllOfYourCustomer": "เชื่อมต่อกับลูกค้าทั้งหมดของคุณได้ในระบบเดียว",
    "makesItEasyToMonitorThe": "ทำให้ง่ายต่อการตรวจสอบการดำเนินการของคลังสินค้าและการจัดการสต็อก",
    "goBackToTheList": "กลับไปที่รายการ",
    "xxxxDoesNotExistPleaseScan": "ไม่มี {XXXX} โปรดสแกนอีกครั้งหลังจากเพิ่มผลิตภัณฑ์หลักแล้ว",
    "theCombinationXxxCannotBePut": "ผลิตภัณฑ์ผสม {XXX} ไม่สามารถจัดเก็บด้วยตนเองได้โปรดลบและลองอีกครั้ง",
    "theTrackingNumberXxxxWasSuccessfully1": "หมายเลขติดตาม {0} ถูกสแกนเรียบร้อยแล้ว และสถานะการสแกนคำสั่งซื้อ {1} ได้รับการอัปเดตเป็นสแกนและย้ายไปที่รอรับสินค้า",
    "theTrackingNumberXxxxWasSuccessfully": "หมายเลขติดตาม {XXXX} ถูกสแกนเรียบร้อยแล้ว และสถานะการสแกนคำสั่งซื้อ {XXXX} ได้รับการอัปเดตเป็นสแกนแล",
    "waitingInbound": "รอเข้าคลังอยู่",
    "upgrade": "อัพเกรด",
    "about": "เกี่ยวกับ",
    "pleaseSelectACategoryFirst": "กรุณาเลือกหมวดหมู่ก่อน",
    "subAccount": "เลขบัญชีย่อย",
    "authorizedStores": "ร้านค้าที่ได้รับอนุญาต",
    "availableQuantity": "ปริมาณเดี่ยวที่มีอยู่",
    "addImagesToEachVariantOption": "เพิ่มรูปภาพในแต่ละตัวเลือกสินค้า",
    "productInformationDisplayedOnTheLogistics": "ข้อมูลสินค้าแสดงอยู่ในรายการโลจิสติกส์ (JD/Bukalapak)",
    "tiktokPrintShippingLabelAndPick": "(Tiktok) พิมพ์ด่วนและเลือกรายการ",
    "downloadDocument": "ดาวน์โหลดไฟล์:{0}",
    "thereAreOrdersThatStillBeing": "มีการสั่งซื้อที่ยังคงพิมพ์อยู่ หลังจากปิดหน้าต่างป๊อปอัป คุณสามารถดาวน์โหลดใบปะหน้าสำหรับการจัดส่งในประวัติการพิมพ์",
    "printHistory": "ประวัติการสั่งซื้อ",
    "printAll": "พิมพ์ทั้งหมด",
    "maximumOrdersCanBePrinted": "สามารถพิมพ์คำสั่งซื้อได้สูงสุด 1,000 รายการในครั้งเดียว และสร้างใบปะกำกับการจัดส่ง PDF สำหรับแต่ละช่องซึ่งใช้เวลานาน",
    "unlimited": "ไม่จำกัด",
    "otherCharges": "ค่าใช้จ่ายอื่นๆ",
    "bearTheCost": "ค่าธรรมเนียม",
    "authorizationStatusTotalNumberOf": "สถานะการอนุญาต = จำนวนรวมของร้านค้าในmarketplace/เว็บสโตร์ที่ \"ไม่ใช้งาน\"",
    "expiredInfo": "สถานะการอนุญาต = จำนวนรวมของร้านค้าในmarketplace/เว็บสโตร์ที่ \"หมดอายุ\"",
    "storeStatusOfAllAuthorizedChannels": "สถานะร้านค้าของทุกช่องทางที่ได้รับอนุญาต = สินค้าที่ \"ขายหมดแล้ว\" ทั้งหมด",
    "status_banned_info": "สถานะร้านค้าของช่องทางที่ได้รับอนุญาตทั้งหมด = สินค้าที่ \"ถูกแบน\" ทั้งหมด",
    "theSumOfTheNumberOf": "จำนวนสินค้าทั้งหมดที่ล้มเหลวในการเผยแพร่ไปยังช่องทางในร้านค้าช่องทางที่ได้รับอนุญาตทั้งหมด",
    "stockListPageNotInboundTab": "ปริมาณ MSKU ในหน้า [รายการสต็อค] และแท็บ [ไม่ขาเข้า]",
    "theNumberOfMskusThatHave": "จำนวน MSKU ที่เพิ่มเข้าในคลังสินค้าและยังไม่ได้สร้างเพื่อเพิ่มสต็อก",
    "stockListPageStockWarningTab": "ปริมาณ MSKU ในหน้า [รายการสต็อค] และแท็บ [คำเตือนสต็อค]",
    "totalMskuWhichTheAvailableStock": "MSKU ทั้งหมดซึ่งสต็อคที่มีอยู่น้อยกว่าสต็อคความปลอดภัย",
    "totalMskuOfStockListPage": "MSKU ทั้งหมดของหน้า [รายการสินค้าในคลัง] ในแท็บ [สินค้าหมด]",
    "totalMskusWithAvailableStock": "MSKU ทั้งหมดที่มี 0 สต็อคที่พร้อมใช้งาน",
    "TotalOrderOf": "คำสั่งซื้อทั้งหมดของ [การจัดการการคืนสินค้า - การคืนและการคืนเงิน] ในแท็บ [รอดำเนินการ]",
    "returnRequestInfo": "Shopee/Lazada/Tiktok คำสั่งซื้อที่กำหนดให้ผู้ขายจัดการเมื่อผู้ซื้อยื่นคำขอคืนสินค้า",
    "totalOrderOfReturnManagement": "คำสั่งซื้อทั้งหมดของ [การจัดการการคืนสินค้า - รายการคืนเงิน] ในแท็บ [รอดำเนินการ]",
    "shopeelazadatiktokOrdersThatRequireTheSeller": "Shopee/Lazada/Tiktok คำสั่งซื้อที่กำหนดให้ผู้ขายจัดการเมื่อผู้ซื้อเริ่มคำขอคืนเงิน",
    "ordersThatHaveBeenPushed": "คำสั่งซื้อทั้งหมดของหน้า [คลังสินค้าพุชที่รอดำเนินการ] ในแท็บ [ผลักดันล้มเหลว] โปรดให้ความสำคัญกับการประมวลผล",
    "ordersThatHaveBeenPushedTo": "คำสั่งซื้อที่ส่งไปยังคลังสินค้าของบริษัทอื่นด้วยตนเองหรือโดยอัตโนมัติโดยระบบ แต่ API คลังสินค้าของบริษัทอื่นส่งคืนผลลัพธ์ความล้มเหลว",
    "totalOrderOfPendingpushWarehousePage": "คำสั่งซื้อทั้งหมดของหน้า [คลังสินค้าที่ผลักดันรอดำเนินการ] ในแท็บ [การกดที่รอดำเนินการ] โปรดอย่าลืมส่งให้ตรงเวลา",
    "ordersThatNeedToBePushed": "คำสั่งซื้อที่ต้องผลักไปยังคลังสินค้าที่สามเพื่อดำเนินการให้สำเร็จ แต่ยังไม่ได้ผลัก",
    "totalOrderOnAbnormalOrdersPage": "คำสั่งซื้อทั้งหมดในหน้า [คำสั่งซื้อที่ผิดปกติ] หากมีข้อมูลที่เกี่ยวข้อง โปรดเชื่อมต่อคำสั่งซื้อด้วยตนเอง",
    "ordersWithIncompleteInformationDueTo": "คำสั่งซื้อที่มีข้อมูลไม่ครบถ้วนเนื่องจากข้อจำกัด QPS บนช่องทาง API",
    "theNumberOfOrdersInThe": "คำสั่งซื้อทั้งหมดของ【รอดำเนินการสั่งพิมพ์】หน้าในแท็บ【พิมพ์ได้】สามารถประมวลผลได้อย่างรวดเร็วและสม่ำเสมอ",
    "allPrintableShippingLabelsAreDisplayed": "คำสั่งซื้อทั้งหมดที่พิมพ์ใบปะหน้าการจัดส่งได้จะปรากฏในรายการนี้",
    "toBeProcessedArrangeShipment": "[รอดำเนินการ - จัดเตรียมการจัดส่ง] ผลรวมของปริมาณการสั่งซื้อในแต่ละแท็บ สามารถจัดลำดับความสำคัญและรวมเป็นสำหรับการจัดส่งหลายช่องทางและหลายร้าน",
    "allShippableOrdersAreDisplayedIn": "คำสั่งซื้อที่จัดส่งได้ทั้งหมดจะแสดงอยู่ในรายการนี้",
    "pendingproblemOrdersTheSumOfThe": "[คำสั่งซื้อที่มีปัญหารอดำเนินการ] ผลรวมของคำสั่งซื้อในแต่ละแท็บของหน้า โปรดให้ความสำคัญกับการประมวลผล",
    "whenTheOutboundListCannotBe": "เมื่อไม่สามารถสร้างคำสั่งซื้อขาออกหรือ MSKU หมดสต็อก จะถูกกำหนดเป็นคำสั่งที่มีปัญหา",
    "xxxxThereIsMoreThanOne": "{XXXX} มีมากกว่าหนึ่งรายการในระบบ MSKU และบาร์โค้ดซ้ํากัน โปรดตรวจสอบและลองอีกครั้ง",
    "servicePH": "คลิกและติดต่อกับเรา",
    "serviceCN": "ที่ปรึกษาเฉพาะของคุณ wechat:Ginee888",
    "serviceTH": "Ginee-Thailand",
    "serviceVN": "กลุ่มสอน Zalo",
    "serviceMYandID": "กลุ่ม Ginee Support",
    "accountInfo": "ข้อมูลเกี่ยวกับบัญชี",
    "refundApplication": "การขอคืนเงิน",
    "returnRequest": "การขอคืนสินค้า",
    "orderManagement": "การจัดการคำสั่งซื้อ",
    "sumOfTheNumberOfShopeelazada": "ผลรวมของจำนวนร้านค้า Shopee/Lazada ที่มีสถานะการอนุมัติ = \"ได้รับอนุญาต\" และมีระยะเวลาการอนุมัติเหลือน้อยกว่า 30 วัน",
    "shopeebukalapaktokopediaOrdersUseTheGineeTemplate": "คำสั่งซื้อ Shopee/Bukalapak/Tokopedia ใช้เทมเพลต Ginee เพื่อพิมพ์ปะหน้าสำหรับการจัดส่ง และช่องทางอื่นๆ ในปัจจุบันรองรับเฉพาะการพิมพ์ฉลากการจัดส่งโดยใช้เทมเพลต Marketplace",
    "highSpeedPrintTaskDetails": "รายละเอียดการพิมพ์ความเร็วสูง",
    "printTaskDetails": "รายละเอียดการพิมพ์",
    "regularPrint": "พิมพ์ปกติ",
    "printUnlimitedShippingLabelInSingle": "พิมพ์ไม่จำกัดจำนวน ไม่รองรับการแสดงตัวอย่างก่อนพิมพ์",
    "clickHereToViewDetailedInstructions": "คลิกที่นี่เพื่อดูคำแนะนำโดยละเอียด",
    "inOrderToImprovePrintingSpeed": "เพื่อปรับปรุงความเร็วและประสบการณ์การพิมพ์ ขอแนะนำให้ใช้การพิมพ์ความเร็วสูง",
    "itIsDetectedThatYouHave": "ตรวจพบว่าคุณได้ตรวจสอบคำสั่งซื้อมากกว่า 300 รายการ และไม่รองรับการพิมพ์คำสั่งซื้อด่วนแบบกำหนดเองของ Ginee ชั่วคราว",
    "thePrintingTaskHasExpiredPlease": "งานพิมพ์หมดอายุแล้ว โปรดไปที่ [คำสั่งเกือบ 90 วัน > คำสั่งย้อนหลัง] เพื่อตรวจสอบบันทึกการพิมพ์",
    "imageSizeLengthLimit": "คุณสามารถอัปโหลดรูปภาพหลายรูปในครั้งเดียว จำนวนพิกเซลไม่น้อยกว่า {width} x {height} พิกเซล ใหญ่สุด {size}",
    "imageManagement": "การจัดการรูปภาพ",
    "noMskuInTheDocumentHas": "ไม่มีความคลาดเคลื่อนของสินค้าคงคลังใน MSKU ใดๆ ในเอกสาร และไม่จําเป็นต้องสร้างใบสั่งการปรับปรุง",
    "inventoryHasNotBeenCompletedPlease": "สินค้าคงคลังยังไม่เสร็จสมบูรณ์โปรดดําเนินการหลังสินค้าคงคลังให้เสร็จสิ้นก่อน",
    "stocktakingNumber": "หมายเลขตรวจนับสต๊อก",
    "checkingStock": "กำลังตรวจนับสต็อก",
    "waitingStocktaking": "รอการตรวจนับสต็อก",
    "importStockTakingResult": "นำเข้าสต๊อกสินค้า",
    "mskuNumber": "จํานวน MSKU",
    "stocktakingType": "ประเภทตรวจนับสต็อก",
    "numberOfMskuWithCountingDifferences": "นับจํานวน MSKU ที่มีความแตกต่าง",
    "numberOfDifferences": "จำนวนความแตกต่าง",
    "generateInventoryAdjustmentDocument": "สร้างใบปรับปรุงสต็อก",
    "pleaseCheckTheSpecificInformationIn": "โปรดตรวจสอบข้อมูลเฉพาะใน [คลังสินค้า - แผ่นปรับปรุงสินค้าคงคลัง] ทําการปรับปรุงด้วยตนเองและอัปเดตสินค้าคงคลัง MSKU ตามผลการนับ",
    "inventoryAdjustmentDocGeneratedSuccessfully": "ใบสั่งปรับปรุงสินค้าคงคลังถูกสร้างขึ้นเรียบร้อยแล้ว",
    "voidSuccessfully": "ความสําเร็จเป็นโมฆะ",
    "afterVoidingItIsNotSupported": "สินค้าคงคลังไม่สามารถดําเนินการต่อได้ และ MSKU ที่ถูกนับแล้วไม่สนับสนุนการสร้างใบสั่งปรับปรุงสินค้าคงคลังเพื่อปรับสินค้าคงคลัง",
    "AfterTheCountingTheSystem": "3. หลังจากสินค้าคงคลังเสร็จสมบูรณ์ระบบจะแก้ไขสินค้าคงคลัง MSKU โดยอัตโนมัติสําหรับการขายและคําสั่งซื้อขาออกที่ไม่สามารถจัดส่งได้ตามปกติในระหว่างรอบระยะเวลาสินค้าคงคลังและคําสั่งซื้อจริงที่ได้รับการจัดส่ง / ยกเลิก",
    "TheMskuInventoryThatHas": "2. สินค้าคงคลัง MSKU ที่ยังไม่ได้ลงทะเบียนสําหรับการนับสินค้าคงคลังจะไม่ได้รับการอัปเดต และผลลัพธ์สินค้าคงคลัง MSKU จะได้รับการอัปเดตเป็น \"ไม่นับ\" โปรดใช้ความระมัดระวัง",
    "AfterTheInventoryIsFinished": "1. หลังจากสินค้าคงคลังเสร็จสมบูรณ์สถานะของใบสั่งนับจะถูกเปลี่ยนเป็นเสร็จสมบูรณ์และไม่สามารถดําเนินการต่อได้",
    "confirmTheEndOfTheInventory": "ยืนยันการสิ้นสุดของสินค้าคงคลัง?",
    "theCurrentTaskIsStillBeing": "งานปัจจุบันยังคงถูกสร้างขึ้นโปรดทําในภายหลัง",
    "clickStartCountingAndTheStatus": "หลังจากที่คุณคลิก เริ่มสินค้าคงคลัง สถานะของรายการสินค้าคงคลังจะได้รับการอัปเดตเป็นสินค้าคงคลัง และหน้าการดําเนินการสินค้าคงคลังจะถูกเปิดให้คุณโดยอัตโนมัติ",
    "printInventoryList": "พิมพ์ใบตรวจนับสต็อก",
    "endInventory": "สิ้นสุดการตรวจนับสต็อก",
    "startCounting": "เริ่มสินค้าคงคลัง",
    "inventoryByMsku": "สินค้าคงคลังโดย MSKU",
    "wholeWarehouseInventory": "ตรวจนับสต็อกตามคลังสินค้า",
    "automaticallyFilterMskuXCombinationsAnd": "MSKU [ {bundleSku} ] ถูกกรองโดยอัตโนมัติ และ MSKU [ {countingSKU} ] ยังคงรอสินค้าคงคลังในงานสินค้าคงคลังที่ยังไม่เสร็จ และ MSKU [ {canCountSKU} ] สามารถประดิษฐ์ได้ในครั้งนี้",
    "aTotalOfXMskuCan": "คราวนี้คุณสามารถนับจํานวน {X} MSKUs ทั้งหมดได้",
    "createInventoryTask": "สร้างใบตรวจนับสต็อก",
    "aTotalOfXMskuAre": "MSKUs {count} ทั้งหมดกําลังรอสินค้าคงคลังรายการสินค้าคงคลังกําลังถูกร้องขอโปรดรอสักครู่และย้ายไปยังรายการนับสินค้าคงคลังเพื่อดําเนินการต่อไป",
    "inventoryCreationTaskSubmittedSuccessfully": "งานการสร้างรายการสินค้าคงคลังถูกส่งเรียบร้อยแล้ว",
    "afterConfirmationTheFilledInformationWill": "หลังจากยืนยันข้อมูลที่กรอกแล้วจะไม่ถูกบันทึก",
    "areYouSureYouDontWant": "คุณแน่ใจหรือไม่ว่าคุณไม่ได้บันทึกข้อมูลปัจจุบัน?",
    "currentWarehouseInventory": "สต็อกคลังสินค้าปัจจุบัน",
    "inventoryIsNotSupportedForCombination": "สินค้าคงคลังไม่ได้รับการสนับสนุนสําหรับ MSKUs ในแอสเซมบลีและงานสินค้าคงคลังที่ไม่สมบูรณ์",
    "mskuDetails": "รายละเอียด MSKU",
    "inventory": "คลังสินค้า",
    "afterSavingTheInformationWillOnly": "หลังจากบันทึกข้อมูลจะถูกบันทึกไว้ในรายการสินค้าคงคลังเท่านั้นและสามารถแก้ไขได้อีกครั้ง",
    "inventoryLoss": "การสูญเสียสินค้าคงคลัง",
    "inventoryProfit": "กําไรจากกระทะ",
    "panping": "สต็อกแบน",
    "inventoryResults": "เก็บสต็อกผลลัพธ์",
    "takeStockInTheWarehouse": "นับสินค้าคงคลัง",
    "takeStockOf": "สินค้า คง คลัง",
    "counting": "จํานวนการนับ",
    "inventorySheet": "หมายเลขตรวจนับสต๊อก",
    "notCounted": "ไม่นับ",
    "TheCurrentAdjustmentDocumentStatus": "3. สถานะของคําสั่งปรับค่าปัจจุบันจะอัปเดตเป็น [การปรับ]",
    "TheCurrentMskuAdjustmentStatus1": "3. สถานะการปรับ MSKU ปัจจุบันได้รับการอัปเดตเป็น [ไม่ได้ปรับ]",
    "TheSystemWillNotChange": "2. ระบบจะไม่เปลี่ยนสินค้าคงคลังคลังสินค้า MSKU ปัจจุบัน",
    "OnceItIsConfirmedThat": "1. เมื่อได้รับการยืนยันว่าไม่จําเป็นต้องปรับเปลี่ยนแล้วจะไม่สามารถดําเนินการได้อีก",
    "confirmThatTheCurrentMskuInventory": "ยืนยันว่าสินค้าคงคลัง MSKU ปัจจุบันไม่จําเป็นต้องปรับ?",
    "TheCurrentMskuAdjustmentStatus": "3. สถานะการปรับ MSKU ปัจจุบันได้รับการอัปเดตเป็น [เสร็จสมบูรณ์]",
    "withoutAdjustment": "ไม่ต้องปรับเปลี่ยน",
    "confirmAdjustment": "ยืนยันการปรับ",
    "unadjusted": "ไม่ปรับ",
    "singleAdjustment": "การปรับเปลี่ยนแบบกลุ่ม",
    "continueToAdjust": "ปรับไปเรื่อยๆ",
    "startAdjusting": "เริ่มปรับ",
    "inventoryReductionMsku": "การลดสินค้าคงคลัง MSKU",
    "increaseInventoryMsku": "สินค้าคงคลังเพิ่ม MSKU",
    "totalMsku": "จํานวน MSKU ทั้งหมด",
    "adjusting": "ปรับ",
    "toBeAdjusted": "ที่จะปรับ",
    "adjustTheSingleNumber": "ไม่มีการปรับ",
    "afterItIsTurnedOnMsku": "หลังจากเปิดใช้งานแล้ว MSKU จะไม่รองรับการเพิ่มและลดสินค้าคงคลังในระหว่างการตรวจนับสต็อก ซึ่งจะไม่ส่งผลกระทบต่อสต็อกล็อค เรียนแจ้งเพื่อทราบ",
    "mskuDuringTheCountingPeriodDoes": "MSKU ในช่วงระยะเวลาการตรวจนับสต็อกจะไม่รองรับการดำเนินการขาเข้า/ขาออก",
    "afterAdjustment": "หลังจากการปรับ",
    "beforeAdjustment": "ก่อนการปรับ",
    "inventoryVariance": "ความคลาดเคลื่อนของสินค้าคงคลัง",
    "stockAdjustment": "การปรับสต็อก",
    "continueCounting": "ดําเนินการสต็อกต่อไป",
    "confirmIntegrations": "ยืนยันการผสานรวม",
    "mskuXDoesNotExistIn": "MSKU {X} ไม่มีอยู่ในคลังสินค้า {X} โปรดเพิ่ม MSKU ลงในคลังสินค้าก่อน",
    "orderXxxxxStatusChangedToShipping": "สถานะของคําสั่งซื้อ {XXXXX} จะเปลี่ยนเป็นการจัดส่งใน {XXXX-XX-XX XX:XX}",
    "xxxAfterTheInventoryCountIs": "{XXX} หลังจากการนับสินค้าคงคลัง MSKU สามารถดําเนินการเข้าและออกจากคลังสินค้าและแก้ไขมูลค่าสินค้าคงคลังโดยอัตโนมัติตามการเปลี่ยนแปลงสถานะคําสั่งซื้อ",
    "xxxGeneratesTheInventoryAdjustmentDocument": "{XXX} สร้างหมายเลขการปรับปรุงสินค้าคงคลัง: {XXXX} และผลลัพธ์การนับ: การสูญเสียสินค้าคงคลัง",
    "xxxGenerateTheInventoryAdjustmentDocument": "{XXX} สร้างหมายเลขการปรับปรุงสินค้าคงคลัง: {XXXX}, นับผลลัพธ์: กําไรสินค้าคงคลัง",
    "theCurrentRepositoryIsDeactivatedPlease": "พื้นที่เก็บข้อมูลปัจจุบันถูกปิดใช้งานโปรดทําหลังจากเปิดใช้งานรายการที่เก็บแล้ว",
    "theCurrentWarehouseIsNotIts": "คลังสินค้าปัจจุบันไม่ใช่คลังสินค้าของตนเองและไม่สามารถจัดเก็บได้",
    "theCurrentRepositoryDoesNotExist": "ไม่มีที่เก็บปัจจุบันโปรดทําหลังจากเพิ่มรายการที่เก็บ",
    "theInventoryHasBeenTaken": "ตรวจนับแล้ว",
    "AreYouSureToDelete": "คุณแน่ใจหรือไม่ที่จะลบข้อมูลปัจจุบัน?",
    "defaultShelf": "ชั้นวางเริ่มต้น",
    "AreYouSureToEnd": "ยืนยันการนับสิ้นสุด?",
    "mskuInInventoryExistsInThe": "หาก MSKU ในเอกสารปัจจุบันอยู่ในสินค้าคงคลังโปรดกรอกสินค้าคงคลังและป้อนสินค้าคงคลังโดยตรง",
    "thereIsMskuInCountingIn": "หาก MSKU ในเอกสารปัจจุบันอยู่ในสินค้าคงคลังโปรดกรอกสินค้าคงคลังและป้อนหรือออกจากสินค้าคงคลังโดยตรง",
    "AreYouSureToVoid": "แน่ใจว่าข้อมูลปัจจุบันเป็นโมฆะหรือไม่",
    "keywordid": "คีย์เวิร์ด",
    "onceTheAdjustmentIsConfirmedIt": "เมื่อการปรับได้รับการยืนยันแล้วจะไม่สามารถระงับได้",
    "atTheBeginningOfAdjustmentThe": "เมื่อการปรับปรุงเริ่มต้นระบบจะปรับปรุงสินค้าคงคลังคลังสินค้า MSKU โดยอัตโนมัติเป็นค่าสินค้าคงคลังที่ปรับปรุงแล้ว",
    "addedInventoryList": "เพิ่มรายการสินค้าคงคลัง",
    "noMoreThanMaxCharacters": "ไม่เกิน {max} ตัวอักษร",
    "confirmAndUpdateMskuInventory": "ยืนยันการปรับและอัปเดตสินค้าคงคลัง MSKU หรือไม่?",
    "areYouSureNotToSave": "แน่ใจนะว่าจะไม่บันทึกข้อมูลปัจจุบัน?",
    "createAnInventoryTask": "สร้างใบตรวจนับสต็อก",
    "countingStatus": "สถานะการนับ",
    "counted": "ตรวจนับแล้ว",
    "saving": "การประหยัด...",
    "areYouCancel": "คุณแน่ใจหรือว่าต้องการยกเลิก?",
    "notCount": "เอกสารปัจจุบันไม่ได้อยู่ในระหว่างรอการกักตุนและไม่สามารถดําเนินการได้ โปรดรีเฟรชหน้านี้แล้วลองอีกครั้ง",
    "shouldBeBetweenXToX": "ขอบเขตควรระหว่าง X ถึง X",
    "offlineOrder": "สั่งซื้อออนไลน์",
    "duringTheUnboundPeriodTheOutbound": "ในช่วงเวลาที่ไม่ได้ผูกไว้ จะไม่สามารถสร้างใบสั่งขาออกเพื่อล็อก/หักสต็อกและไม่สามารถดำเนินการเชื่อมสต็อกได้",
    "tokocabangStockChannelInterfaceDoesNot": "โปรดอย่าเชื่อมคลังสินค้า Tokopedia O2O กับ Ginee WMS คลังสินค้าของบุคคลที่สาม มิฉะนั้นจะทำให้เกิดการจัดส่งที่ซ้ำกันได้",
    "disabledChannelWarehousesCannotUpdateStock": "ช่องทางคลังสินค้าที่ปิดใช้งานจะไม่สามารถอัปเดตสต็อกได้เนื่องจากข้อจำกัดอินเทอร์เฟซของช่องทาง",
    "pleaseBeSureToCompleteThe1": "โปรดตรวจสอบให้แน่ใจว่าได้ทำการเชื่อมโยงแบบตัวต่อตัวระหว่างคลังสินค้าของช่องทางและคลังสินค้าของ Ginee เพื่อให้เกิดการแบ่งคำสั่งซื้อและเชื่อมสต็อค",
    "systemWarehouseAddress": "ที่อยู่ระบบคลังสินค้า",
    "systemWarehouseName": "ชื่อระบบคลังสินค้า",
    "channelWarehouseAddress": "ที่อยู่คลังสินค้า",
    "channelWarehouseName": "ชื่อช่องคลังสินค้า",
    "channelWarehouseManagement": "การจัดการช่องทางคลังสินค้า",
    "upgradePackage": "อัพเกรดแพ็คเกจ",
    "askCustomerService": "สอบถามฝ่ายบริการลูกค้า",
    "theCurrentStoreHasOpenedThe": "ร้านค้าปัจจุบันได้เปิดฟังก์ชั่นคลังสินค้าหลายช่องทาง โปรดอัปเกรดเป็นแพ็คเกจที่รองรับฟังก์ชั่นการจัดการหลายคลังสินค้าก่อนดำเนินการ",
    "storeAuthorizationFailed": "การอนุญาตร้านค้าล้มเหลว",
    "youCanDirectlyAddProductsIn": "คุณสามารถเพิ่มสินค้าในระบบ ดำเนินการตามคำสั่ง หรือเปิดการเชื่อมต่อสต็อคได้โดยตรง เพื่อสัมผัสประสบการณ์การจัดการสต็อคแบบหลายช่องทางเพียงคลิกเดียว",
    "pleaseBeSureToCompleteThe": "โปรดตรวจสอบว่าได้ดำเนินการเชื่อมคลังสินค้าของช่องและคลังสินค้าของ Ginee ให้เสร็จสิ้นทันที มิฉะนั้น คุณจะไม่สามารถดำเนินการตามเชื่อมคำสั่งซื้อ/สต็อกได้",
    "theStoreHasOpenedTheChannel": "ร้านค้าได้เปิดบริการคลังสินค้าหลายช่องทางและกำลังใช้บริการการเชื่อมต่อสต็อก",
    "authorizing": "กำลังอนุมัติ",
    "multiWarehouse": "หลายคลังสินค้า",
    "theGineeSystemReportsAnError": "ระบบ Ginee รายงานข้อผิดพลาด โดยมีสาเหตุดังนี้: {XXXXXX}",
    "theChannelInterfaceReportsAnError": "อินเทอร์เฟซช่องรายงานข้อผิดพลาด สาเหตุมีดังนี้: {XXXXXX}",
    "failedToPullChannelWarehouseInformation": "ไม่สามารถดึงข้อมูลคลังช่องทาง",
    "deactivateWarehouse": "ปิดใช้งานคลังสินค้า",
    "addWarehouse": "เพิ่มคลังสินค้า",
    "channelWarehouseInformationPulledSuccessfully": "ดึงข้อมูลคลังสินค้าของช่องทางสำเร็จแล้ว",
    "pullTheChannelWarehouseAddXxx": "ดึงคลังช่อง เพิ่ม {AAA} ปิดใช้งาน {BBB}",
    "channelWarehouseXxxBindingWarehouseIs": "คลังสินค้าที่ถูกผูกไว้คลังสินค้า{AAA} ได้รับการอัปเดตจาก {BBB} เป็น {CCC}",
    "theChannelWarehouseXxxIsBound": "คลังสินค้าช่อง {AAA} เชื่อมโยงกับระบบคลังสินค้า{BBB}",
    "updateWarehouse": "อัปเดตคลังสินค้า",
    "pleaseNoteThatTheOperationLog": "โปรดทราบว่าบันทึกการทำงานจะถูกเก็บไว้ในระบบเป็นเวลา 30 วันเท่านั้น และไม่สามารถดูบันทึกการดึงที่เกิน 30 วันได้",
    "thereIsAnUnfinishedSalesDelivery": "มีคำสั่งจัดส่งการขายที่ยังไม่เสร็จในคลังสินค้าปัจจุบัน และไม่สามารถอัพเดตความสัมพันธ์ที่มีผลผูกพันได้",
    "OrdersAllocated": "คำสั่งซื้อที่ได้รับการปันส่วนไปยังคลังสินค้าของช่องทางจะถูกปันส่วนไปยังคลังสินค้าที่ตรงกันโดยอัตโนมัติตามความสัมพันธ์ที่มีผลผูกพันใหม่ ไม่จำเป็นต้องตั้งค่ากฎการปันส่วนคลังสินค้า",
    "areYouSureToUpdateThe": "คุณแน่ใจหรือที่จะปรับปรุงคลังสินค้าที่ถูกผูกไว้?",
    "OrdersThatHaveBeenAllocated": "คำสั่งซื้อที่ได้รับการจัดไปยังคลังสินค้าทางจะจัดไปยังคลังสินค้าที่ตรงกันโดยอัตโนมัติตามความสัมพันธ์ที่มีผลเชื่อมกันไม่จำเป็นต้องตั้งค่ากฎการปันส่วนคลังสินค้า",
    "areYouSureToBindThe": "คุณแน่ใจหรือที่จะผูกมัดคลังสินค้าปัจจุบัน?",
    "ownWarehouse": "คลังสินค้าของตัวเอง",
    "warehouseMarketplace": "ช่องคลังสินค้า",
    "TheStatusOfTheCurrent": "3. สถานะของคําสั่งปรับค่าปัจจุบันจะได้รับการอัปเดตเป็น [เสร็จสมบูรณ์]",
    "lastSaleDateTips": "วันที่สร้างคำสั่งซื้อล่าสุดสำหรับสินค้าปัจจุบัน",
    "notification74": "จำนวนคำสั่งซื้อทั้งหมด (รวมถึงค่าขนส่ง ฯลฯ) ของคำสั่งซื้อเรียบร้อยในช่วงเวลาที่ตรงตามเงื่อนไขการยอดขายตำ่",
    "notification72": "จำนวนสินค้าปัจจุบันที่ขายในคำสั่งซื้อเรียบร้อยในระยะเวลาที่ตรงตามเงื่อนไขการยอดขายต่ำ",
    "salesVolume": "จำนวนการขาย",
    "notification70": "จำนวนผู้ซื้อทั้งหมดในทุกคำสั่งซื้อในระยะเวลาที่ตรงตามเงื่อนไขการยอดขายตำ่ และจำนวนผู้ซื้อจะถูกลบข้อมูลซำ้ซ้อนตามหมายเลขโทรศัพท์มือถือ",
    "totalNumberOfBuyers": "จำนวนผู้ซื้อทั้งหมด",
    "validOrder": "คำสั่งซื้อเรียบร้อย",
    "notification66": "จำนวนคำสั่งซื้อทั้งหมดในระยะเวลาที่ตรงตามเงื่อนไขการยอดขายตำ่รวมถึงสถานะทั้งหมด",
    "totalNumberOfOrders": "จำนวนคำสั่งซื้อทั้งหมด",
    "notification64": "วันที่สร้างคำสั่งซื้อล่าสุดสำหรับสินค้าปัจจุบัน",
    "lastSaleDate": "วันที่ขายล่าสุด",
    "notification58": "แสดงเฉพาะสินค้าที่มีสต็อก SKU",
    "notification55": "จำนวนที่ขายใน {day} วันน้อยกว่า {number}",
    "notification54": "ก่อนใช้ตารางสถิติการยอดขายตำ่ ต้องตั้งค่าเงื่อนไขการยอดขายตำ่ก่อน",
    "slowSalesSettings": "การตั้งค่าการยอดขายตำ่",
    "commodityCategory": "หมวดหมู่สินค้า",
    "slowSalesStatisticsTable": "รายงานยอดขายต่ำ",
    "notification34": "สร้างไฟล์ล้มเหลว กรุณาลองใหม่ในภายหลัง",
    "notification33": "ไฟล์จะแสดงในรายการประวัติการส่งออกและจะถูกเก็บไว้เป็นเวลา 15 วันเท่านั้น กรุณาคลิกเพื่อดาวน์โหลดให้ทันเวลา",
    "notification32": "สร้างไฟล์เสร็จแล้ว กรุณาคลิกเพื่อดาวน์โหลด",
    "generatingFilePleaseWait": "กำลังสร้างไฟล์ กรุณารอสักครู่",
    "replenishStock": "เติมสต็อก",
    "unableEstimate": "ไม่สามารถประเมินได้",
    "estimatedDaysTips": "(สต็อกพร้องใช้-สต็อกเพื่อปลอดภัย)/จำนวนการขายรายวัน วันที่เหลือในการเข้าถึงสต็อกเพื่อปลอดภัยที่ประเมินตามจำนวนการขายเฉลี่ยรายวัน หากจำนวนการขายรายวันน้อยกว่า 1 จะใช้ 1 สำหรับการประมาณการ",
    "estimatedDays": "วันที่โดยประมาณ",
    "avgQtySoldTips": "จำนวนการขาย/ระยะเวลาการขาย จำนวนการขายสต็อกเฉลี่ยต่อวัน",
    "avgQtySold": "จำนวนการขายรายวัน",
    "qtySoldTips": "จำนวนสินค้าคงคลังที่ขายหมดจาก SKU สินค้าคงคลังปัจจุบันในช่วงเวลาการขายที่เลือก",
    "qtySold": "จำนวนการขาย",
    "safetyStockTips": "จำนวนสต็อกเพื่อปลอดภัยของสต็อก SKU ปัจจุบัน สต็อกเพื่อความปลอดภัยสอดคล้องกับรายการการจัดการสต็อก",
    "availableStockTips": "จำนวนสต็อกพร้อมใช้ของ สต็อก SKU สินค้าปัจจุบัน สต็อกพร้อมใช้สอดคล้องกับรายการการจัดการสต็อก",
    "inventoryName": "ชื่อสต็อก",
    "inventorySKU": "MSKU",
    "notification151": "เรียนรู้วิธีเปิดใช้สต็อกเพื่อความปลอดภัย",
    "days90": "90วัน",
    "days60": "60วัน",
    "days30": "30วัน",
    "days15": "15วัน",
    "days7": "7วัน",
    "salesPeriod": "ระยะเวลาขาย",
    "outOfStockEstimation": "พยากรณ์สต็อกหมด",
    "profitTips": "หมายเหตุ:：รายงานกำไรนับเฉพาะคำสั่งซื้อที่สำเร็จและรองรับการเรียกดูข้อมูลสูงสุด 30 วัน",
    "commissionSettingTips": "หมายเหตุ: ขณะนี้รองรับการตั้งค่าคอมมิชชั่นร้านค้าจากร้านค้า Tokopedia และ Shopify ที่เชื่อมต่อ ข้อมูลนี้ใช้สำหรับสถิติข้อมูลรายได้เท่านั้น ข้อมูลของค่าคอมมิชชั่นช่องทางอื่นๆ อิงตาม API ช่องทาง",
    "netProfitIncomeStatementTips2": "งบกำไรขาดทุนสุทธิ = รายได้จากการดำเนินงาน + รายได้นอกดำเนินการ - ต้นทุนการดำเนินงาน - ค่าใช้จ่ายที่มิใช่การดำเนินงาน - ค่าใช้จ่ายอื่น\nงบกำไร/ขาดทุนสุทธิ = รายได้จากการดำเนินงาน + รายจ่ายเพิ่มนอกจากการขาย-ต้นทุนการดำเนินงาน-ค่าใช้จ่ายที่ไม่ใช่การดำเนินงานธุรกิจ-ค่าใช้จ่ายอื่นๆ",
    "netProfitIncomeStatementTips": "กำไรสุทธิ/งบกำไรขาดทุน=รายได้การดำเนินงาน+รายได้อื่น-ต้นทุนการดำเนินงาน-รายจ่ายเพิ่มนอกจากการขาย-ค่าใช้จ่ายอื่น",
    "netProfitIncomeStatement": "กำไรสุทธิ/งบกำไรขาดทุน",
    "outboundStockTips": "เวลาบันทึกสต็อก=จำนวนเพิ่มเติมสต็อกในเวลาที่เลือกไว้ คิดจากต้นทุน*จำนวนเพิ่มเติม",
    "outboundStock": "สต็อกเปลี่ยน(ลด)",
    "refundFeeTips": "เวลาสร้างคำสั่งซื้อ=ยอดค่าธรรมเนียมการคืนเงินในเวลาที่เลือกไว้",
    "refundFee": "ค่าธรรมเนียมการคืนเงิน",
    "cashbackTips": "เวลาสร้างคำสั่งซื้อ=ยอดเงินแคชแบ็คในเวลาเลือกไว้",
    "cashback": "แคชแบ็ค",
    "commissionFeeTips": "เวลาสร้างคำสั่งซื้อ=ยอดเงินค่าคอมมิชชั่นในเวลาเลือกไว้",
    "commissionFee": "ค่าคอมมิชชั่น",
    "serviceFeeTips": "เวลาสร้างคำสั่งซื้อ=ยอดเงินค่าบริการในเวลาที่เลือกไว้",
    "serviceFee": "ค่าบริการ",
    "otherExpensesTips": "ค่าใช้จ่ายอื่นๆ=ค่าบริการ+คอมมิชชั่น + เงินสดคืน (แคชแบ็ก) + ค่าธรรมเนียมการคืนเงิน + การเปลี่ยนแปลงสต็อก(ลด)",
    "otherExpenses": "ค่าใช้จ่ายอื่นๆ",
    "taxTips": "เวลาสร้างคำสั่งซื้อ=ยอดรวมค่าภาษีในเวลาที่เลือกไว้",
    "insuranceTips": "เวลาสร้างคำสั่งซื้อ=ยอดเงินต่าประกันภัยในเวลาที่เลือกไว้",
    "insurance": "ค่าประกันภัย",
    "nonOperatingExpensesTips": "รายจ่ายเพิ่มนอกจากการขาย=ค่าประกันภัย+ค่าภาษี",
    "nonOperatingExpenses": "รายจ่ายเพิ่มนอกจากการขาย",
    "costOfGoodSoldTips": "เวลาบันทึกสต็อก=จำนวนสินค้าจัดส่งในเวลาเลือกไว้ * ต้นทุน",
    "costOfGoodSold": "ต้นทุนการขาย (COGS)",
    "operatingExpenseTips": "ต้นทุนดำเนินกิจการ=ต้นทุนการขาย",
    "operatingExpense": "ต้นทุนดำเนินกิจการ",
    "inboundStockTips": "เวลาบันทึกสต็อก=ยอดการเพิ่มสต็อก * ต้นทุนในเวลาเลือกไว้",
    "inboundStock": "การเปลี่ยนแปลงสต็อก（เพิ่ม）",
    "rebateTips": "เวลาการสร้างคำสั่งซื้อ=ยอดรวมของส่วนลดในเวลาเลือกไว้",
    "rebate": "ส่วนลด",
    "nonOperatingIncomeTips": "รายได้เพิ่มนอกจากการขาย=ค่าจัดส่ง",
    "nonOperatingIncome": "รายได้เพิ่มนอกจากการขาย",
    "coinTips": "เวลาการสร้างคำสั่งซื้อ=ยอดขายเหรียญในเวลาเลือกไว้",
    "coin": "เหรียญ",
    "promotionTips": "วันที่สร้างคำสั่งซื้อ=ยอดขายรวมคูปองในเวลาเลือกไว้",
    "promotion": "โปรโมชั่นอื่น",
    "discountTips": "วันที่สร้างคำสั่งซื้อ=ยอดขายรวมโปรโมชั่นของวันที่เลือก",
    "discount": "ส่วนลด",
    "salesAmountTips": "เวลาการสร้างคำสั่งซื้อ=ยอดขายสินค้าของวันที่เลือก",
    "salesAmount": "ยอดขาย",
    "operatingIncomeTips": "รายได้ดำเนินงาน=ยอดเงินการค้าขาย-ยอดเงินโปรโมชั่น-โปรโมชั่นอื่นๆ+ เหรียญ+ส่วนลด",
    "operatingIncome": "รายได้จากการดำเนินงาน",
    "incomeStatement": "งบกำไรขาดทุน",
    "refundOrdersTips": "จำนวนคำสั่งซื้อทั้งหมดที่มีสถานะการส่งคืนสินค้า",
    "validBuyersTips": "จำนวนผู้ซื้อทั้งหมดสำหรับคำสั่งซื้อเรียบร้อย",
    "buyersTips": "จำนวนผู้ซื้อทั้งหมดในคำสั่งซื้อทั้งหมด",
    "salesRateTips": "สัดส่วนที่ยอดขายสินค้าปัจจุบันต่อในยอดขายทั้งหมด",
    "grossSalesTips": "จำนวนเงินทั้งหมดต่อคำสั่งซื้อเรียบร้อย รวมทั้งค่าขนส่งและอื่นๆ",
    "qtySalesRateTips": "1. สถิติการขายสินค้า: ยอดขายสินค้าเดียว/ทั้งหมด\n2. สถิติการขายหมวดหมู่: ยอดขายหมวดหมู่เดียว/ทั้งหมด\n3. สถิติการขายของร้าน: ยอดขายร้านเดียว/ทั้งหมด\n4. สถิติการขายช่องทาง: ยอดขายช่องทางเดียว/ทั้งหมด",
    "qtySalesTips": "สินค้าทั้งหมดที่ขายในคำสั่งซื้อเรียบร้อย",
    "validOrdersTips": "ชำระเสร็จ/พร้อมส่ง/กำลังจัดส่ง/ส่งถึง",
    "totalOrdersTips": "จำนวนสินค้าทั้งหมดที่ตรงกับคำสั่งซื้อ",
    "refundOrders": "คำสั่งซื้อส่งคืนสินค้า",
    "validBuyers": "ผู้ซื้อสำหรับคำสั่งซื้อเรียบร้อย",
    "buyers": "จำนวนผู้ซื้อทั้งหมด",
    "salesRate": "เปอร์เซ็นต์ของการขาย (%)",
    "grossSales": "รายได้รวม",
    "qtySalesRate": "สัดส่วนการขาย",
    "qtySales": "จำนวนการขายทั้งหมด",
    "validOrders": "คำสั่งซื้อเรียบร้อย",
    "validOrderNumStatisticsTips": "ชำระเสร็จ/พร้อมส่ง/กำลังจัดส่ง/ส่งถึง",
    "totalProductNumStatisticsTips": "จำนวนสินค้าทั้งหมดที่มีอยู่ในคำสั่งซื้อทั้งหมด ตามข้อมูลหลังการลบข้อมูลซำ้ซ้อนร้านค้า + ชื่อสินค้า หากซื้อสินค้ารายการเดียวกันสองรายการพร้อมกันในการสั่งซื้อ จำนวนรวมของสินค้าคือ 1",
    "totalValidBuyerTips": "จำนวนลูกค้าทั้งหมดสำหรับคำสั่งซื้อสำเร็จ เบอร์โทรศัพท์ 1 รายการ ระบบจะบันทึกเป็นจำนวนลูกค้า 1 ท่าน",
    "shopNameTips": "ชื่อร้านค้าที่เป็นของรายการสินค้านี้",
    "mskuTips": "ข้อมูล SKU หลักที่ได้เชื่อมต่อกับตัวเลือกสินค้าภายใต้สินค้าในคำสั่งซื้อ  จะไม่เปลี่ยนแปลงตามการอัปเดตข้อมูลสินค้า",
    "skuTips": "ข้อมูล SKU หลักที่ได้เชื่อมต่อกับตัวเลือกสินค้าภายใต้สินค้าในคำสั่งซื้อ  จะไม่เปลี่ยนแปลงตามการอัปเดตข้อมูลสินค้า",
    "variationTips": "ชื่อตัวลือกสินค้าที่ได้เชื่อมต่อกับสินค้าในคำสั่งซื้อ  จะไม่เปลี่ยนแปลงตามการอัปเดตข้อมูลสินค้า",
    "productNameTips": "ชื่อสินค้าในคำสั่งซื้อที่เชื่อมต่อบน Ginee",
    "totalValidBuyerStatisticsTips": "จำนวนลูกค้าทั้งหมดสำหรับคำสั่งซื้อสำเร็จ เบอร์โทรศัพท์ 1 รายการ ระบบจะบันทึกเป็นจำนวนลูกค้า 1 ท่าน",
    "totalQtySoldStatisticsTips": "จำนวนสินค้าทั้งหมดที่มีอยู่ในคำสั่งซื้อทั้งหมด หากซื้อสินค้าชนิดเดียวกันมีการสั่งซื้อ 2 รายการ ระบบจะคำนวนเป็นสินค้าเพียง 1 รายการ",
    "searchPlaceholder": "โปรดใส่เนื้อหา",
    "pleaseSelectCategory": "กรุณาเลือกหมวดหมู่",
    "productVariantsSalesStatistics": "สถิติการขายตัวเลือกสินค้าต่างๆ",
    "action73": "ดำเนินการ",
    "datesPerPage": "จำนวนสถิติต่อหน้า",
    "setPage": "เลือกหน้า",
    "time69": "เวลา",
    "notification68": "คำแจ้งเตือน：กรุณาตั้งค่าขอบเขตของการดาวน์โหลดสถิติ",
    "variationName": "ชื่อคุณลักษณะสินค้า",
    "fieldDescription": "คำอธิบายสั้น",
    "detial": "ข้อมูลละเอียด",
    "action61": "ดำเนินการ",
    "downloadFailed": "ดาวน์โหลดไม่สำเร็จ",
    "active": "เปิดใช้งานได้",
    "notification49": "ไฟล์จะโชว์ในรายการประวัติส่งออกโดยซิงโครไนซ์ ไฟล์จะเก็บไว้15วันเท่านั้น กรุณาคลิกและดาวน์โหลดก่อนเสร็จสิ้นอายุ",
    "returnRateTips": "คำสั่งซื้อการส่งคืนสินค้า/คำสั่งซื้อทั้งหมด",
    "returnRate": "อัตราการส่งคืน",
    "refundOrderNumTips": "คำสั่งซื้อทั้งหมดที่อยู่ในสถานะการส่งคืน",
    "refundOrderNum": "คำสั่งซื้อการส่งคืน",
    "repurchaseRateTips": "เปอร์เซ็นของผู้ซื้อที่มีจำนวนธุรกรรมที่สำเร็จมากกว่า 1",
    "repurchaseRate": "อัตราซื้ออีก",
    "grossSalesBuyer": "ยอดขายทั้งหมด/จำนวนผู้ซื้อ",
    "customerAverageTransaction": "ธุรกรรมเฉลี่ยของลูกค้า",
    "buyerNumTips": "ผู้ซื้อทั้งหมดจากคำสั่งซื้อเรียบร้อย",
    "buyerNum": "ผู้ซื้อสำหรับคำสั่งซื้อเรียบร้อย",
    "totalBuyerNumTips": "จำนวนผู้ซื้อทั้งหมดในคำสั่งซื้อทั้งหมด",
    "totalBuyerNum": "จำนวนผู้ซื้อทั้งหมด",
    "saleRateTips": "1.ตารางสถิติขายสินค้า：ยอดเงินการขายของสินค้าเดียว/ยอดเงินการขายสินค้าทั้งหมด\n2.ตารางสถิติของหมวดหมู่：ยอดเงินการขายของหมวดหมู่เดียว/ยอดเงินการขายหมวดหมู่ทั้งหมด\n3.ตารางสถิติของร้านค้า：ยอดเงินการขายของร้านค้าเดียว/ยอดเงินการขายของร้านค้าทั้งหมด\n4.ตารางสถิติของช่องทาง：ยอดเงินการขายของช่องทางเดียว/ยอดเงินการขายของช่องทางทั้งหมด",
    "saleRate": "ยอดเงินการขาย（%）",
    "grossIncomeTips": "ยอดเงินสินค้าทั้งหมดในคำสั่งซื้อเรียบร้อย รวมค่าจัดส่ง ฯลฯ",
    "grossIncome": "รายได้ทั้งหมด",
    "qtySaleRateTips": "1.ตารางสถิติขายสินค้า：ยอดเงินการขายของสินค้าเดียว/ยอดเงินการขายสินค้าทั้งหมด\n2.ตารางสถิติของหมวดหมู่：ยอดเงินการขายของหมวดหมู่เดียว/ยอดเงินการขายหมวดหมู่ทั้งหมด\n3.ตารางสถิติของร้านค้า：ยอดเงินการขายของร้านค้าเดียว/ยอดเงินการขายของร้านค้าทั้งหมด\n4.ตารางสถิติของช่องทาง：ยอดเงินการขายของช่องทางเดียว/ยอดเงินการขายของช่องทางทั้งหมด",
    "qtySaleRate": "จำนวนการค้าขาย(%)",
    "qtySaleNumTips": "จำนวนสินค้าขายแล้วทั้งหมดในคำสั่งซื้อเรียบร้อย",
    "qtySaleNum": "จำนวนการขายทั้งหมด",
    "effectiveRateTips": "คำสั่งซื้อเรียบร้อย/คำสั่งซื้อทั้งหมด",
    "effectiveRate": "ประสิทธิผล(%)",
    "validOrderNumTips": "ชำระเสร็จ/พร้อมส่ง/กำลังจัดส่ง/ส่งถึง",
    "validOrderNum": "คำสั่งซื้อเรียบร้อย",
    "totalOrderNumTips": "จำนวนสินค้าทั้งหมดที่ตรงกับคำสั่งซื้อ",
    "totalOrderNum": "จำนวนคำสั่งซื้อทั้งหมด",
    "totalProductNumTips": "จำนวนสินค้าทั้งหมด ขายอยู่/ขายไม่ได้/ขายหมด/ห้ามขาย",
    "totalProductNum": "จำนวนสินค้าทั้งหมด",
    "totalValidBuyer": "ผู้ซื้อทั้งหมดจากคำสั่งซื้อเรียบร้อย",
    "netIncomeTips": "ผลรวมของจำนวนเงินสินค้าในคำสั่งซื้อเรียบร้อย",
    "netIncome": "ยอดขายสินค้าทั้งหมด",
    "notification15": "จำนวนสินค้าขายแล้วทั้งหมดในคำสั่งซื้อเรียบร้อย",
    "totalQtySold": "จำนวนการขายทั้งหมด",
    "lowToHigh": "จากตำ่ถึงสูง",
    "highToLow": "จากสูงถึงตำ่",
    "storeSaleReport": "รายงานการค้าขายของร้านค้า",
    "channelSaleReport": "รายงานการค้าขายของช่องทาง",
    "revenuePerBuyerTips": "จำนวนเงินสั่งซื้อทั้งหมด/จำนวนลูกค้า",
    "revenuePerBuyer": "ธุรกรรมเฉลี่ยของลูกค้า",
    "monetaryUnit": "หน่วยสกุลเงิน",
    "productOriginalPriceIncome": "ยอดขายสินค้าทั้งหมด: ผลรวมของจำนวนเงินสินค้าในคำสั่งซื้อเรียบร้อย รายได้ทั้งหมด: \n ยอดเงินสินค้าทั้งหมดในคำสั่งซื้อเรียบร้อย รวมค่าจัดส่ง ฯลฯ",
    "totalNetIncomeTips": "ผลรวมของจำนวนเงินสินค้าในคำสั่งซื้อเรียบร้อย",
    "totalNetIncome": "ยอดขายสินค้าทั้งหมด",
    "exportPdf": "ส่งออกเป็นPDF",
    "exportToPDF": "ส่งออกเป็นPDF",
    "countTips": "จำนวนการขายไม่สามารถน้อยกว่า 0",
    "selectTime": "เลือกเวลา",
    "exportExcel": "ส่งออกเป็นExcel",
    "exportTime": "เวลาส่งออก",
    "exportHistory": "ประวัติการส่งออก",
    "title": "ชื่อ",
    "moveEnd": "ปลาย",
    "moveTop": "ท็อป",
    "moveDown": "ย้ายลง",
    "moveUp": "ขยับขึ้น",
    "customField": "กำหนดเอง",
    "taxTotalAmountTips": "ผลรวมภาษีในคำสั่งซื้อที่สร้างขึ้นในวันที่ปัจจุบัน",
    "taxTotalAmount": "จำนวนเงินภาษี",
    "systemShippingTotalAmountTips": "ผลรวมของค่าจัดส่งจ่ายโดยระบบในคำสั่งซื้อที่สร้างในวันที่ปัจจุบัน",
    "systemShippingTotalAmount": "ผลรวมของค่าจัดส่งจ่ายโดยระบบ",
    "serviceTotalAmountTips": "ผลรวมของค่าบริการในคำสั่งซื้อที่สร้างขึ้นในวันที่ปัจจุบัน",
    "serviceTotalAmount": "ผลรวมของค่าบริการ",
    "commissionTotalAmountTips": "ผลรวมของค่าคอมมิชชั่นในคำสั่งซื้อที่สร้างขึ้นในวันที่ปัจจุบัน",
    "commissionTotalAmount": "ผลรวมของค่าคอมมิชชั่น",
    "insuranceTotalAmountTips": "ผลรวมของประกันภัยในคำสั่งซื้อที่สร้างขึ้นในวันที่ปัจจุบัน",
    "insuranceTotalAmount": "ผลรวมของประกันภัย",
    "buyerTotalNumTips": "จำนวนลูกค้าทั้งหมดในคำสั่งซื้อที่สร้างขึ้นในวันที่ปัจจุบัน (การลบข้อมูลซำ้ซ้อนด้วยหมายเลขโทรศัพท์)",
    "buyerTotalNum": "จำนวนลูกค้า",
    "cancelOrderRateTips": "คำสั่งซื้อยกเลิก/จำนวนคำสั่งซื้อทั้งหมด",
    "cancelOrderRate": "อัตรายกเลิก",
    "cancelOrderTotalAmountTips": "ยอดรวมของคำสั่งซื้อที่ยกเลิกซึ่งวันที่สร้างเท่ากับวันที่ปัจจุบัน (รวมถึงค่าจัดส่ง ส่วนลดฯลฯ) จะไม่นับคำสั่งซื้อที่ยกเลิกบางส่วน",
    "cancelOrderTotalAmount": "จำนวนเงินยกเลิกทั้งหมด",
    "cancelOrderTotalNumTips": "จำนวนทั้งหมดของคำสั่งซื้อที่ยกเลิกซึ่งมีวันที่สร้างเท่ากับวันที่ปัจจุบัน และจะไม่นับคำสั่งซื้อที่ยกเลิกบางส่วน",
    "cancelOrderTotalNum": "ยกเลิกคำสั่งซื้อ",
    "returnOrderRateTips": "คำสั่งซื้อส่งคืนสินค้า/จำนวนคำสั่งซื้อทั้งหมด",
    "returnOrderRate": "อัตราส่งคืนสินค้า",
    "returnOrderTotalAmountTips": "ยอดรวมคำสั่งซื้อ (รวมถึงค่าจัดส่ง/ส่วนลด ฯลฯ) ของสถานะการส่งคืนโดยมีวันที่สร้างเท่ากับวันที่ปัจจุบัน จะไม่นับส่วนหนึ่งของจำนวนเงินที่ส่งคืน",
    "returnOrderTotalAmount": "คืนเงินทั้งหมด",
    "returnOrderTotalNumTips": "จำนวนคำสั่งซื้อทั้งหมด ซึ่งวันที่สร้างเท่ากับวันที่ปัจจุบันของสถานะส่งคืนสินค้า จะไม่นับคำสั่งซื้อที่ส่งคืนบางรายการ",
    "returnOrderTotalNum": "คำสั่งซื้อส่งคืนสินค้า",
    "paymentTotalAmountTips": "ยอดรวมของคำสั่งซื้อที่ชำระเสร็จ/พร้อมจัดส่ง/กำลังจัดส่ง/ส่งถึง ซึ่งวันที่สร้างเท่ากับวันที่ปัจจุบัน (รวมถึงค่าจัดส่ง ฯลฯ)",
    "paymentTotalAmount": "จำนวนเงินทั้งหมดของคำสั่งซื้อเรียบร้อย",
    "paymentTotalNumTips": "จำนวนรวมของคำสั่งซื้อที่ชำระเสร็จ/พร้อมส่ง/กำลังจัดส่ง/ส่งถึง ซึ่งสร้างวันที่เท่ากับวันที่ปัจจุบัน",
    "paymentTotalNum": "คำสั่งซื้อเรียบร้อย",
    "shippingTotalAmountTips": "จำนวนเงินทั้งหมดของค่าจัดส่งในคำสั่งซื้อที่สร้างในวันที่ปัจจุบัน",
    "shippingTotalAmount": "ค่าจัดส่ง",
    "goodsTotalAmountTips": "ยอดเงินรวมของสินค้าในคำสั่งซื้อที่สร้างในวันที่ปัจจุบัน (ไม่รวมค่าจัดส่ง/ส่วนลด ฯลฯ)",
    "goodsTotalAmount": "ยอดเงินรวมสินค้า",
    "orderTotalAmountTips": "ยอดเงินคำสั่งซื้อทั้งหมดที่สร้างขึ้นในวันที่ปัจจุบัน (รวมถึงค่าจัดส่ง/ส่วนลด ฯลฯ)",
    "orderTotalAmount": "ยอดเงินคำสั่งซื้อ",
    "orderTotalNumTips": "จำนวนคำสั่งซื้อทั้งหมดในช่วงเวลาที่ตรงตามเงื่อนไขการยอดขายตำ่ รวมถึงสถานะทั้งหมด",
    "orderTotalNum": "จำนวนคำสั่งซื้อทั้งหมด",
    "dayTips": "วันที่สร้างคำสั่งซื้อ",
    "yesterday": "เมื่อวาน",
    "totalRefundAmountTips": "ยอดรวมคืนเงิน ซึ่งวันที่ส่งคืนเท่ากับวันที่ปัจจุบัน จะไม่นับคำสั่งซื้อที่ส่งคืนบางรายการในสถิติจำนวนเงินที่คืน(ที่เกี่ยวข้องกับLazada )",
    "totalRefundAmount": "คืนเงินทั้งหมด",
    "returnOrderTips": "จำนวนรวมของคำสั่งซื้อที่ส่งคืนซึ่งมีวันที่สร้างเท่ากับวันที่ปัจจุบัน (สถานะคำสั่งซื้อทั้งหมด)",
    "shippingFeeTips": "วันที่สร้างเท่ากับจำนวนเงินรวมของค่าจัดส่งในคำสั่งซื้อในวันที่ปัจจุบัน (ค่าจัดส่งทั้งหมดในรายละเอียดคำสั่งซื้อ)",
    "shippingFee": "ค่าจัดส่ง",
    "totalOrderAmountTips": "วันที่สร้างเท่ากับยอดรวมของคำสั่งซื้อในวันที่ปัจจุบัน (รวมค่าจัดส่ง ฯลฯ)",
    "totalOrderAmount": "ยอดเงินคำสั่งซื้อ",
    "totalOrderNumberTips": "วันที่สร้างเท่ากับจำนวนคำสั่งซื้อทั้งหมดในวันที่ปัจจุบัน (สถานะคำสั่งซื้อทั้งหมด)",
    "totalOrderNumber": "จำนวนคำสั่งซื้อทั้งหมด",
    "trendChart": "แผนภูมิเทรนด์",
    "theSizeVariantDoesNotSupport": "ตัวแปร \"ขนาด\" ไม่รองรับการเพิ่มรูปภาพ",
    "onlyShowAuthorizedStores": "แสดงเฉพาะร้านค้าที่ได้รับอนุญาต",
    "untickWithOneClick": "ยกเลิกคลิกด้วยคลิกเดียว",
    "pleaseUntickAndReoperate": "กรุณายกเลิกคลิกและดำเนินการใหม่",
    "thereAreXExpireddeactivatedStoresIn": "มี {X} ร้านค้าที่หมดอายุ / ปิดใช้งานในการรวมปัจจุบัน",
    "failedToSave": "ไม่สามารถบันทึก",
    "shortDescription": "คำบรรยายสินค้า",
    "Item": "รายการ",
    "shippingFeeTips2": "เวลาการสร้างคำสั่งซื้อ=ค่าจัดส่งที่ชำระโดยระบบและยอดรวมของค่าจัดส่งในเวลาเลือกไว้",
    "whenTheProductsInTheChannel": "เมื่อสินค้าในคลังของช่องทางต่างๆถูกสร้างขึ้นโดยอัตโนมัติ / ผูกกับ MSKU มันจะถูกเพิ่มเข้าไปในคลังสินค้าระบบที่จับคู่โดยอัตโนมัติ แต่คุณยังต้องดำเนินการขาเข้าด้วยตัวเองเพื่อให้แน่ใจว่าสินค้าคงคลังที่ถูกต้อง",
    "whenThereIsAStockChange": "เมื่อมีการเปลี่ยนแปลงสต็อกในคลังสินค้าระบบที่ถูกผูกไว้จำนวนสต๊อกจะถูกผลักไปยังคลังสินค้าในช่องทางต่างๆโดยตรง",
    "channelWarehouseId": "ID ช่องทางคลังสินค้า",
    "forChannelProductsThatAreBound": "สำหรับช่องทางสินค้าที่ผูกไว้กับคลังสินค้า เมื่อสร้าง MSKU แล้ว สินค้าเหล่านั้นจะถูกเพิ่มไปยังคลังสินค้าที่ผูกไว้โดยอัตโนมัติ",
    "forMultiwarehouseChannelsAndChannelProducts": "สำหรับช่องทางคลังสินค้าหลายแห่งและช่องทางสินค้าที่มีความสัมพันธ์กับคลังสินค้าที่ถูกผูกไว้ หลังจากผูก MSKU แล้ว มันจะถูกเพิ่มไปยังคลังสินค้าที่ผูกไว้โดยอัตโนมัติ",
    "forMultiwarehouseChannelsWithWarehouseboundChannel": "สำหรับช่องทางหลายคลังสินค้าที่มีช่องทางสินค้าที่ผูกกับคลังสินค้า MSKU จะถูกเพิ่มไปยังคลังสินค้าที่ผูกไว้โดยอัตโนมัติหลังจากสร้าง",
    "existXwarehouse": "มีคลังสินค้า {X} แห่ง",
    "amountUnit": "จำนวนเงิน ( หน่วย :",
    "thePriceOfTokoWillBe": "ราคาของ Toko จะอัพเดทในแต่ละช่องคลังสินค้า",
    "priceAndStockWillBeUpdated": "ราคาและสต็อกจะถูกอัพเดตเป็นคลังสินค้าเริ่มต้น",
    "dueToTheDelayInChannel": "เนื่องจากความล่าช้าในการอัปเดตข้อมูลช่อง จะใช้เวลาสองสามวินาทีในการอัปเดตข้อมูลที่ซิงโครไนซ์ โปรดรอสักครู่",
    "theStoreWhereTheOrderIs1": "ร้านค้าที่มีคำสั่งซื้อได้เปิดช่องทางหลายคลังสินค้าโปรดผูกคลังสินค้ากับ Ginee และดำเนินการ",
    "theStoreWhereTheOrderIs": "ร้านค้าที่มีคำสั่งซื้ออยู่ได้เปิดช่องทางหลายคลังสินค้า และสนับสนุนเฉพาะการเปลี่ยนไปใช้คลังสินค้า Ginee ที่ถูกผูกไว้ {XXXX}",
    "theWarehouseXxxxAlreadyHasA": "ที่เก็บ {XXXX} มีงานในการดึงอยู่แล้วและสามารถดูได้ในบันทึกการดึงโปรดรอให้งานเสร็จสมบูรณ์",
    "youNeedToIntegrateAstore": "คุณต้องเชื่อมร้านค้า {x} ก่อนจึงจะสามารถชำระเงินด้วย {y} ได้",
    "pleaseSelectTheStoreaccountTo": "โปรดเลือกร้านค้า / บัญชี {x} ที่จะชำระเงิน",
    "theProductsXxxxInTheOrder": "คำสั่งซื้อ {XXXX} ช่องทางสินค้า {XXXX} ถูกไปกระจายไปยังคลังสินค้า {XXXX} ตามความสัมพันธ์ที่มีผลผูกพันระหว่างช่องทางคลังสินค้าและระบบคลังสินค้า",
    "theCurrentProductsWarehouseIsEmpty": "โกดังผลิตภัณฑ์ว่างเปล่า กรุณาป้อนใน keeppack ก่อน",
    "xIsOutOfStockIn": "สินค้าคงคลังของ {X} ไม่เพียงพอใน {X}",
    "shopeebukalapaktokopediaOrdersUseGineeTemplateFor": "คำสั่งซื้อ Shopee/Bukalapak/Tokopedia ใช้เทมเพลต Ginee สำหรับการพิมพ์ใบปะหน้าพัสดุ",
    "buyerPhone": "โทรศัพท์มือถือของผู้ซื้อ",
    "xNewWarehouses": "เพิ่มที่เก็บ {X}, รหัสที่เก็บ: {X}",
    "editStock": "แก้ไขสต็อก",
    "warehouseIsEmpty": "คลังสินค้าว่างเปล่า",
    "dueToTheLimitationsOfThe": "เนื่องจากข้อ จํากัด ของอินเทอร์เฟซ Keeppack สินค้าดังกล่าวไม่สามารถดําเนินการใน Ginee ได้โปรดดําเนินการหลังจาก Keeppack เสร็จสิ้นการจัดเก็บ",
    "pleaseFindTheBindingEmailIn": "โปรดไปที่ศูนย์ผู้ขาย Tokopedia [การตั้งค่า - การตั้งค่าแอปพลิเคชันของบุคคลที่สาม] หรือค้นหาอีเมลในกล่องจดหมายของคุณเพื่อยอมรับแอปพลิเคชันที่มีผลผูกพันก่อน",
    "created": "สร้าง",
    "allstocktakingissuccessful": "นับสต็อกทั้งหมดเรียบร้อย",
    "partialstocktaking": "นับสต็อกบางส่วน",
    "allstocktakingfailed": "การนับสต็อกล้มเหลวทั้งหมด",
    "someexecute": "ดำเนินการบางส่วน",
    "faileds": "ล้มเหลว",
    "thePullTaskHasBeenCreated": "ดึงงานสร้างความสำเร็จ",
    "executed": "ดำเนินการแล้ว",
    "keeppackWarehouse": "คลังสินค้า Keeppack",
    "unbindMsku": "เลิกเชื่อม MSKU",
    "PullRecord": "บันทึกการดึงสต็อก",
    "thisStoreHasBeenConnectedTo": "ร้านค้านี้เชื่อมต่อกับบัญชี Ginee อื่นแล้ว กรุณายกเลิกการเชื่อมต่อร้านนี้จากบัญชี Ginee ด้านล่างก่อนดำเนินการ:",
    "ManualInbound": "จัดการขาเข้าด้วยตนเอง",
    "stocktakingInbound": "ตรวจนับสต๊อกขาเข้า",
    "NormalInbound": "การจัดเก็บปกติ",
    "notPushed": "ยังไม่ส่งต่อ",
    "partComplete": "เสร็จบางส่วน",
    "partInvalid": "ข้อมูลบางส่วนไม่ถูกต้อง",
    "tripartiteSingleNumber": "หมายเลข{warehouse}",
    "storageInformation": "ข้อมูลขาเข้า",
    "types": "ประเภท",
    "plannedInbound": "วางแผนเข้าคลัง",
    "receiptCost": "ค่าใช้จ่ายในคลังสินค้า",
    "withAttachments": "มีอุปกรณ์เสริม",
    "automaticallyCreateMsku": "สร้าง MSKU โดยอัตโนมัติ",
    "createMskuManually": "สร้าง MSKU ด้วยตนเอง",
    "currentlyThirdPartyWarehouseSkuCode": "ในปัจจุบัน มีเพียงรหัส SKU ของคลังสินค้าบุคคลที่สามเท่านั้นที่ได้รับการสนับสนุนให้เหมือนกับรหัส MSKU ที่จะถูกผูกไว้",
    "warehouseKeeppackWarehouseInboundManagement": "ข้อมูลคลังสินค้า / คลังสินค้า Keeppack /การจัดการใบเข้าคลัง",
    "warehouseKeeppackWarehouseOutboundManagement": "ข้อมูลคลังสินค้า / คลังสินค้า Keeppack /การจัดการใบออกคลัง",
    "warehouseKeeppackWarehouse": "ข้อมูลคลังสินค้า / คลังสินค้า Keeppack /บันทึกการดึงสต็อก",
    "theCurrentCommodityWarehouseIsEmpty": "โกดังสินค้าว่างเปล่า เข้าไปในคลังสินค้าที่ keeppack",
    "YouWillNotBeAble": "1. คุณจะไม่สามารถดูข้อมูลการสั่งซื้อของร้านค้านี้ได้\n2. คุณจะไม่พบร้านค้าในการตั้งค่าร้านค้า",
    "temperatureControlled": "การควบคุมอุณหภูมิ",
    "standard": "มาตรฐาน",
    "storageType": "ประเภทการจัดเก็บ",
    "enableWare": "คุณแน่ใจหรือไม่ว่าต้องการเปิดใช้งานโหมดหลายคลังสินค้า",
    "afterEnablingTheMultiwarehouseModeAnd": "หลังจากเปิดใช้งานโหมดหลายคลังสินค้าและเผยแพร่สินค้าแล้ว โหมดหลายคลังสินค้าจะไม่สามารถปิดได้",
    "activateMultiwarehouse": "เปิดใช้งานหลายคลังสินค้า",
    "turnOffMultiwarehouse": "ปิดคลังสินค้าหลายแห่ง",
    "wantTo": "คุณแน่ใจหรือไม่ว่าต้องการปิดโหมดหลายคลังสินค้า",
    "afterTheMultiwarehouseModeIsTurned": "หลังจากปิดโหมดหลายคลังสินค้า ข้อมูลคลังสินค้าและสินค้าคงคลังหลายรายการจะถูกลบ",
    "kongfuWarehouse": "คลังสินค้า KONGFU",
    "useTheStockInformationOfThe": "ใช้ข้อมูลสต็อกของคลังสินค้า KONGFU เพื่อเปิดใช้งานการเชื่อมต่ออัตโนมัติเพื่อปรับปรุงประสิทธิภาพการจัดการ",
    "afterTheAuthorizationIsSuccessfulYou": "หลังจากคลิกปุ่ม [อนุญาต] ข้อมูลคลังสินค้าของ KONGFU จะถูกดึงมาให้คุณ โปรดเลือกคลังสินค้าที่จำเป็นเพื่อดำเนินการตามขั้นตอนการอนุญาต",
    "afterClickingTheAuthorizeButtonKongfus": "หลังจากคลิกปุ่ม [อนุญาต] ข้อมูลคลังสินค้าของ KONGFU จะถูกดึงมาให้คุณ โปรดเลือกคลังสินค้าที่จำเป็นเพื่อดำเนินการตามขั้นตอนการอนุญาต",
    "PleaseContactKongfuCustomerService": "2. โปรดติดต่อฝ่ายบริการลูกค้าของ KONGFU เพื่อรับรหัส",
    "ItIsRecommendedToFill": "1. ขอแนะนำให้กรอกข้อมูลที่ตกลงกับ KONGFU สำหรับชื่อบัญชีและชื่อผู้ฝากขาย",
    "informationDescription": "คำอธิบายข้อมูล",
    "consignorName": "ชื่อผู้ฝากส่ง",
    "acuntName": "ชื่อบัญชี",
    "completeTheRepositoryAuthorizationConfirmationIn": "ยืนยันการอนุญาตคลังสินค้าให้เสร็จสมบูรณ์ในการจัดการการกำหนดค่าของ KONGFU",
    "afterSelectingAndSavingSubjectTo": "หลังจากเลือกและบันทึกแล้ว โปรดติดต่อฝ่ายบริการลูกค้าของ KONGFU แบบออฟไลน์เพื่อดำเนินการตรวจสอบการอนุญาตคลังสินค้าให้เสร็จสิ้น และกลับไปที่ระบบ Ginee ใน [รายการการรวมระบบ-Store] ตามที่กำหนดโดยกระบวนการอนุญาตของ KONGFU",
    "pleaseSelectTheWarehouseInformationThat": "โปรดเลือกข้อมูลคลังสินค้าที่ต้องได้รับอนุญาตและดำเนินการตามขั้นตอนการอนุญาตออนไลน์ให้เสร็จสิ้น",
    "pleaseSelectTheWarehouseThatNeeds": "โปรดเลือกคลังสินค้าที่ต้องได้รับอนุญาต",
    "applyForAuthorization": "ยื่นขออนุญาต",
    "afterTheAuthorizationIsCompletedPlease": "หลังจากการอนุมัติเสร็จสิ้น โปรดแน่ใจว่าได้กลับไปที่ระบบ Ginee และดำเนินการยืนยันการอนุญาตคลังสินค้าให้เสร็จสิ้นในการจัดการการกำหนดค่าของ [รายชื่อร้านค้าการเชื่อมต่อ] KONGFU",
    "pleaseContactKongfuCustomerServiceOffline": "โปรดติดต่อฝ่ายบริการลูกค้าของ KONGFU แบบออฟไลน์เพื่อดำเนินการตรวจสอบการอนุญาตคลังสินค้าให้เสร็จสิ้น",
    "approvalStatus": "สถานะการอนุมัติ",
    "reasonForFailureTheThirdParty": "สาเหตุของความล้มเหลว: คลังสินค้าบุคคลที่สามปฏิเสธใบสมัครการอนุญาตคลังสินค้า โปรดติดต่อฝ่ายบริการลูกค้าของคลังสินค้าบุคคลที่สามเพื่อยืนยัน",
    "timeToOperateAuthorizedWarehouseInformation": "เวลาในการดำเนินการข้อมูลคลังสินค้าที่ได้รับอนุญาตใน Ginee",
    "theTimeToUpdateWarehouseInformation": "เวลาในการอัปเดตข้อมูลคลังสินค้าใน Ginee",
    "consignorCode": "รหัสผู้ฝากส่ง",
    "theSystemWillAutomaticallyUpdateThe": "ระบบจะอัปเดตสถานะการอนุมัติของคลังสินค้าภายนอกให้คุณโดยอัตโนมัติทุกชั่วโมง หากคุณต้องการดูผลการอนุมัติแบบเรียลไทม์ คุณสามารถคลิก [อัปเดตสถานะการอนุมัติ] ด้วยตนเองเพื่อดู",
    "passed": "ผ่าน",
    "theWarehouseAuthorizationApplicationInformationHas": "ข้อมูลแอปพลิเคชันการอนุญาตคลังสินค้าได้รับการแจ้งไปยังคลังสินค้าของบุคคลภายนอกเรียบร้อยแล้ว",
    "afterConfirmationTheSystemWillCall": "หลังจากการยืนยัน ระบบจะเรียกอินเทอร์เฟซ KONGFU เพื่อให้คุณเริ่มต้นการอนุญาตคลังสินค้า หลังจากยืนยันการสมัคร โปรดติดต่อเจ้าหน้าที่ฝ่ายบริการลูกค้าของ KONGFU เพื่อดำเนินการตรวจสอบการอนุญาต ระบบจะซิงโครไนซ์สถานะการตรวจสอบให้คุณโดยอัตโนมัติทุกชั่วโมง หากคุณต้องการตรวจสอบสถานะการตรวจสอบตามเวลาจริง โปรดดำเนินการ [อัปเดตสถานะการตรวจสอบ] ในหน้าการจัดการการกำหนดค่าเพื่อรับผลการตรวจสอบ",
    "areYouSureToInitiateA": "คุณแน่ใจหรือไม่ว่าจะเริ่มการให้สิทธิ์คลังสินค้ากับคลังสินค้าของบุคคลที่สาม",
    "initiateAuthorizationApplication": "เริ่มต้นการให้สิทธิ์",
    "theListOnlyShowsUnauthorizedWarehouse": "รายการแสดงเฉพาะข้อมูลคลังสินค้าที่ไม่ได้รับอนุญาต โปรดติดต่อฝ่ายบริการลูกค้าของ Kongfu แบบออฟไลน์เพื่อทำการตรวจสอบและยืนยันให้เสร็จสิ้นหลังจากการยืนยัน",
    "selectAuthorizedWarehouse": "เลือกคลังสินค้าที่ได้รับอนุญาต",
    "gineeWillPullTheWarehouseInformation": "Ginee จะดึงข้อมูลคลังสินค้าใน KONGFU ให้คุณโดยเรียกอินเทอร์เฟซ KONGFU โปรดเลือกการอนุญาตคลังสินค้าที่จำเป็น และติดต่อฝ่ายบริการลูกค้าของ KONGFU เพื่อดำเนินการตรวจสอบการอนุญาตของคลังสินค้าใหม่",
    "tipsa": "เคล็ดลับ",
    "startToPullTheLatestWarehouse": "เริ่มดึงข้อมูลคลังสินค้าล่าสุดของ KONGFU ให้คุณ รอให้อินเทอร์เฟซ KONGFU ส่งคืนข้อมูล โปรดรอ",
    "updateApprovalStatus": "อัปเดตสถานะการอนุมัติ",
    "confirmAuthorizedWarehouseIdXxxx": "ยืนยัน ID คลังสินค้าที่ได้รับอนุญาต: XXXX , XXXX",
    "authorizationStatusXxxx": "สถานะการให้สิทธิ์: {XXXX}",
    "addWarehousesForApplicationAuthorization": "เพิ่ม 3 คลังสินค้าสำหรับการอนุมัติรหัสคลังสินค้า: XXXX , XXXX",
    "noMoreThanXxxCharacters": "ไม่เกิน {X} อักขระ",
    "customsWeight": "น้ำหนักศุลกากร",
    "customsDeclarationAmount": "จำนวนเงินที่ประกาศศุลกากร",
    "customsCode": "รหัสศุลกากร",
    "customsEnglishName": "ชื่อภาษาอังกฤษของประกาศศุลกากร",
    "customsDeclarationChineseName": "ประกาศศุลกากรชื่อภาษาจีน",
    "ifTheWarehouseForThisDelivery": "หากคลังสินค้าสำหรับการจัดส่งนี้คือ KONGFU และสินค้าต้องมีการสำแดงศุลกากร โปรดกรอกข้อมูลประกาศศุลกากร",
    "customsDeclarationInformation": "ข้อมูลประกาศกรมศุลกากร",
    "theProductDeclarationInformationIsMissing": "ข้อมูลการสำแดงสินค้าหายไป โปรดย้ายไปที่หน้าการแก้ไขสินค้าหลักเพื่อดำเนินการให้เสร็จสิ้นและเสร็จสิ้น",
    "mskuDoesNotConformToThe": "MSKU ไม่เป็นไปตามข้อกำหนดอินเทอร์เฟซคลังสินค้าของบุคคลที่สาม โปรดแก้ไขและดำเนินการ",
    "theProductNameDoesNotConform": "ชื่อสินค้าไม่เป็นไปตามข้อกำหนดอินเทอร์เฟซคลังสินค้าของบุคคลที่สาม โปรดแก้ไขก่อนดำเนินการ",
    "TheMskuDoesNotExceed": "MSKU มีความยาวไม่เกิน 50 อักขระ และรองรับเฉพาะตัวเลข ตัวอักษร และสัญลักษณ์พิเศษ \"-\" เท่านั้น",
    "ProductNameDoesNotExceed": "ชื่อสินค้าไม่เกิน 200 ตัวอักษร",
    "channelRequirements": "ข้อกำหนดของช่อง",
    "ifTheProductsDeliveredToThe": "หากสินค้าที่จัดส่งไปยังคลังสินค้าในครั้งนี้มีไว้เพื่อดำเนินการตามคำสั่งซื้อหรือจำเป็นต้องจัดส่งข้ามพรมแดนในประเทศจีน โปรดเลือกการสำแดงและกรอกข้อมูลการสำแดง",
    "needToDeclare": "จำเป็นต้องสำแดง",
    "noDeclarationRequired": "ไม่จำเป็นต้องมีการสำแดง",
    "totalNumberOfSkus": "จำนวน SKU ทั้งหมด",
    "awb": "หมายเลข การจัดส่ง",
    "outboundAmount": "จำนวนขาออก",
    "outboundQuantity": "ปริมาณขาออก",
    "outboundInformation": "ข้อมูลขาออก",
    "doNotEnableAutomaticBinding": "อย่าเปิดใช้การผูกอัตโนมัติ",
    "thisFieldIsRequiredWhenThe": "ช่องทางนี้จำเป็นเมื่ออินเทอร์เฟซคลังสินค้าของบริษัทอื่นต้องการส่งคืนสินค้าขาเข้าไปยังคลังสินค้า",
    "returnTrackingNumber": "หมายเลขติดตามการส่งคืน",
    "thirdpartyWarehouseTransactionReturn": "การส่งคืนธุรกรรมคลังสินค้าของบุคคลที่สาม",
    "manualSync": "เชื่อมต่อด้วยตนเอง",
    "timingSynchronization": "เวลาการเชื่อมต่อ",
    "reasonReceivedAThirdpartyWarehouseInterface": "เหตุผล: ได้รับการแจ้งเตือนอินเทอร์เฟซคลังสินค้าของบุคคลที่สาม{x}",
    "actualInbound": "จำนวนขาเข้าจริง",
    "theOrderIsInconsistentWithThe": "คำสั่งซื้อไม่สอดคล้องกับประเทศที่คลังสินค้าตั้งอยู่ โปรดเปลี่ยนคลังสินค้าให้ทันเวลา",
    "theChannelInterfaceCannotGenerateThe": "API ของช่องทางไม่สามารถสร้างใบตราส่งและคลังสินค้าปัจจุบันไม่สามารถปฏิบัติตามสัญญาได้ กรุณาเปลี่ยนสินค้าให้ทันเวลา",
    "mskuIsNotAddToThe": "Msku ไม่ได้เพิ่มไปยังคลังสินค้า กรุณาเพิ่ม",
    "rejected": "ปฏิเสธ",
    "outboundListOutboundListInThe": "3.รายการขาออก: รายการขาออกในคลังสินค้า รวมถึงหมายเลข สถานะ และรายละเอียดต่างๆ",
    "inboundListInboundListInThe": "2.รายการขาเข้า: รายการขาเข้าในคลังสินค้า รวมถึงจำนวน สถานะ และรายละเอียดต่างๆ",
    "productsProductsInTheWarehouseIncluding": "1.สินค้า: สินค้าในคลังสินค้า พร้อมรายละเอียด สต็อก และมูลค่าในคลังสินค้า.",
    "agreeToAuthorizeGineeToObtain": "ยินยอมให้สิทธิ์ Ginee ในการรับข้อมูลต่อไปนี้ในคลังสินค้า",
    "editMultiwarehouseStock": "แก้ไขสต็อกหลายคลังสินค้า",
    "totalStock": "สต็อกทั้งหมด",
    "add2": "เพิ่ม",
    "openMultiwarehouses": "เปิดหลายคลังสินค้า",
    "closeMultipleWarehouses": "ปิดหลายคลังสินค้า",
    "noteHighSpeedPrintingDoesNot": "หมายเหตุ: การพิมพ์ความเร็วสูง ไม่รองรับการพิมพ์ฉลากการจัดส่งแบบกำหนดเองของ Ginee (Tokopedia รองรับเฉพาะฉลากการจัดส่งแบบกำหนดเองของ Ginee)",
    "massEditStockIsAvailableFor": "มีการแก้ไขสต็อกจำนวนมากสำหรับสต็อกในคลังสินค้าเดียว",
    "agreecontinueAuthorization": "เห็นด้วย ทำต่อไป ใบอนุญาต",
    "theStockAndProductInformationOf": "ข้อมูลสต็อกและสินค้าของคลังสินค้า Fastock จะถูกเชื่อมต่อโดยอัตโนมัติในสองทิศทางเพื่อปรับปรุงประสิทธิภาพการจัดการ",
    "afterClickingTheAuthorizeButtonYou": "หลังจากคลิกปุ่มอนุญาต คุณสามารถใช้บริการFastock เพื่อจัดการสินค้าขาเข้าและขาออก；",
    "PleaseContactTheWarehouseService": "2. โปรดติดต่อผู้ให้บริการคลังสินค้าเพื่อขอรหัสลูกค้า/รหัสคลังสินค้า/AppKey/SecretKey",
    "BeSureToSelectThe": "1. ตรวจสอบให้แน่ใจว่าได้เลือกไซต์บริการอย่างถูกต้อง มิฉะนั้น การอนุญาตจะล้มเหลว",
    "ifYouNeedToChangeWarehouse": "หากคุณต้องการเปลี่ยนข้อมูลคลังสินค้า โปรดไปที่ Config Management",
    "theFastockFulfillmentWarehouseHasBeen": "สร้างคลังสินค้า Fulfillment Fastock ให้กับคุณเรียบร้อยแล้ว และคุณสามารถเริ่มใช้งานได้",
    "received": "ได้รับ",
    "warehouseBreadcrumbProduct": "ข้อมูลคลังสินค้า / คลังสินค้า {warehouseName} / ผลิตภัณฑ์",
    "warehouseBreadcrumbInbound": "ข้อมูลคลังสินค้า / คลังสินค้า {warehouseName} / การจัดการใบเข้าคลัง",
    "warehouseBreadcrumbOutbound": "ข้อมูลคลังสินค้า / คลังสินค้า {warehouseName} / การจัดการใบออกคลัง",
    "warehouseBreadcrumbPullRecord": "ข้อมูลคลังสินค้า / คลังสินค้า{warehouseName} / บันทึกการดึงสต็อก",
    "unboundStore": "ร้านค้าที่ไม่ผูกมัด",
    "storeAuthorizationHasExpired": "การอนุญาตของร้านค้าหมดอายุแล้ว",
    "hongkong": "Hong Kong",
    "whetherItIsTurnedOnOr": "ไม่ว่าจะเปิดอยู่หรือไม่ก็ตาม",
    "theSkuRemovalQuantityCannotExceed": "ปริมาณการลบ sku ต้องไม่เกินปริมาณการสั่งซื้อ",
    "productCodeRepeatedModification": "แก้ไขรหัสสินค้าซ้ำ",
    "cantDeleteAllDetails": "ไม่สามารถลบรายละเอียดทั้งหมด",
    "theOutboundListHasBeenAllocated": "รายการขาออกได้รับการจัดสรรและไม่สามารถแก้ไขได้",
    "failedToRequestGxb": "ขออนุญาต gxb ไม่สำเร็จ",
    "theDetailsOfTheOutboundList": "รายละเอียดของรายการขาออกได้รับการจัดสรรแล้วและไม่สามารถแก้ไขได้",
    "outboundListDetailsDontExist": "ไม่มีรายละเอียดรายการขาออก",
    "theMergingSkuOfInboundList": "Sku ที่รวมกับของรายการขาเข้าผิดปกติ โปรดติดต่อผู้ดูแลระบบ",
    "theSkuAcceptanceQuantityOfThe": "ปริมาณการรับ sku ของรายการขาเข้ามีการเปลี่ยนแปลง โปรดลองอีกครั้ง",
    "orderDetailsCannotBeChecked": "ไม่สามารถตรวจสอบรายละเอียดการสั่งซื้อได้",
    "productInformationDoesNotExist": "ไม่มีข้อมูลสินค้า",
    "returnAwbNumberCannotBeEmpty": "หมายเลข AWB ที่ส่งคืนต้องไม่ว่างเปล่า",
    "theInboundListHasBeenAccepted": "รายการขาเข้าได้รับการยอมรับ",
    "theInboundListDoesntExist": "ไม่มีรายการขาเข้า",
    "multipleLastMileScacCodesCannot": "รหัส SCAC ไมล์สุดท้ายหลายรายการไม่สามารถปรากฏในคำสั่งซื้อเดียวกันได้",
    "theDomesticAwbNumberUnderThe": "หมายเลข AWB ในประเทศภายใต้คำสั่งซื้อเดียวกันไม่สามารถทำซ้ำได้",
    "homeDeliveryDoesntNeedToFill": "ส่งถึงบ้านไม่ต้องกรอกข้อมูลคนรับ",
    "weightCanOnlyBeAPositive": "น้ำหนักต้องเป็นจำนวนบวกเท่านั้น",
    "theLastMilesTrackingNumberIs": "หมายเลขติดตามของไมล์สุดท้ายต้องไม่ว่างเปล่า และรหัส SCAC ของไมล์สุดท้ายต้องไม่ว่างเปล่า",
    "theLabelPdfIsntEmptyAnd": "ใบปะหน้า PDF ต้องไม่ว่างเปล่า และเลขไมล์สุดท้ายต้องไม่ว่างเปล่า",
    "theSelectedWarehouseDoesNotSupport": "คลังสินค้าที่เลือกไม่รองรับธุรกิจนี้",
    "lastMileInformationlogisticsProductCannotBe": "ข้อมูลไมล์สุดท้าย/โลจิสติกของสินค้าต้องไม่ว่างเปล่าในเวลาเดียวกัน",
    "documentNumberCannotBeEmpty": "หมายเลขเอกสารต้องไม่ว่างเปล่า",
    "productQuantityCanOnlyBeA": "ปริมาณสินค้าต้องเป็นจำนวนเต็มบวกเท่านั้น",
    "theDestinationCountryMustBeConsistent": "ประเทศปลายทางต้องสอดคล้องกับประเทศผู้รับ",
    "recipientCountryprovincecityParameterIsMissing": "ไม่มีพารามิเตอร์ของประเทศ/จังหวัด/เมืองของผู้รับ",
    "theOrderHasBeenProcessedAnd": "คำสั่งซื้อได้รับการดำเนินการแล้วและไม่สามารถแก้ไขได้",
    "matchesMultipleOrders": "ตรงกับคำสั่งซื้อหลายรายการ",
    "notWeighedWithoutWeight": "ไม่ชั่งน้ำหนักโดยไม่มีน้ำหนัก",
    "theDocumentNumberAndLastMile": "ไม่มีหมายเลขเอกสารและหมายเลขคำสั่งซื้อไมล์สุดท้าย",
    "lastLegNumberAlreadyExists": "มีเลขไมล์ล่าสุดอยู่แล้ว",
    "orderShipped": "จัดส่งคำสั่งซื้อ",
    "pickupPersonInformationCannotBeEmpty": "ข้อมูลผู้มารับต้องไม่ว่างเปล่า",
    "theOrderHasBeenShippedAnd": "จัดส่งคำสั่งซื้อแล้วและไม่สามารถยกเลิกได้",
    "senderCountryprovincecityParameterIsMissing": "ไม่มีพารามิเตอร์ประเทศ/จังหวัด/เมืองของผู้ส่ง",
    "logisticsChannelDoesntSupport": "ไม่รองรับช่องทางโลจิสติกส์",
    "thisStatusDoesntSupportModification": "สถานะนี้ไม่รองรับการแก้ไข",
    "pleaseCheckThePickupMethod": "กรุณาตรวจสอบวิธีการรับสินค้า",
    "outboundTypeNotMapped": "ไม่ได้เจาะจงประเภทขาออก",
    "pleaseCheckTheScac": "โปรดตรวจสอบ SCAC",
    "productcombinedProductQuantityMustBe": "สินค้า/ปริมาณสินค้ารวมต้องเป็น>0",
    "documentNumberAlreadyExists": "มีหมายเลขเอกสารอยู่แล้ว",
    "pleaseCheckTheReceiver": "กรุณาตรวจสอบผู้รับ",
    "warehouseCodeDoesNotExist": "ไม่มีรหัสคลังสินค้า",
    "codAmountAndCodCurrencyCan": "จำนวน COD และสกุลเงิน COD ไม่สามารถกรอกได้เพียงรายการเดียว",
    "pleaseCheckTheBusinessType": "กรุณาตรวจสอบประเภทธุรกิจ",
    "failedToCheckDestinationCountryprovinceregion": "ตรวจสอบประเทศ/จังหวัด/ภูมิภาคปลายทางไม่สำเร็จ",
    "logisticsChannelTrackingFailed": "การตรวจสอบช่องทางลอจิสติกส์ล้มเหลว",
    "noPermissionToOperateThisOrder": "ไม่อนุญาตให้ดำเนินการตามคำสั่งซื้อนี้",
    "thePlatformHasAuthorizedTheConsignor": "แพลตฟอร์มได้อนุญาตให้ผู้ตราส่ง",
    "tokenError": "token error",
    "noValidAuthorizedWarehouse": "ไม่มีคลังสินค้าที่ได้รับอนุญาตที่ถูกต้อง",
    "platformMismatch": "แพลตฟอร์มไม่ตรงกัน",
    "noConsignorPermission": "ไม่ได้รับอนุญาตจากผู้ตราส่ง",
    "theTokenDoesntMatch": "โทเค็นไม่ตรงกัน",
    "thePlatformConsignorDoesntMatch": "ผู้ฝากขายแพลตฟอร์มไม่ตรงกัน",
    "pleaseCheckIfThereIsError": "โปรดตรวจสอบว่ามีข้อผิดพลาดในคลังสินค้าหรือไม่",
    "authorizationHasBeenApprovedPleaseDont": "การอนุญาตได้รับการอนุมัติแล้ว โปรดอย่าดำเนินการซ้ำ",
    "noConsignorOnThisPlatform": "ไม่มีผู้ตราส่งบนแพลตฟอร์มนี้",
    "userHasNo": "ผู้ใช้ไม่ได้รับอนุญาตสำหรับคลังสินค้านี้",
    "userHasNoAuthorizationForThis": "ผู้ใช้ไม่มีสิทธิ์สำหรับผู้ส่งนี้",
    "userDoesntHaveConsignorsAuthorization": "ผู้ใช้ไม่ได้รับอนุญาตจากผู้ตราส่ง",
    "warehouseAuthorizationRecordDoesNotExist": "ไม่มีบันทึกการอนุญาตคลังสินค้า",
    "duplicateAuthorizationApplication": "การขออนุญาตที่ซ้ำกัน",
    "warehouseDoesntExist": "ไม่มีคลังสินค้า",
    "consignorDoesntExists": "ไม่มีผู้ส่งสินค้า",
    "platformInformationVerificationFailedPleaseContact": "การตรวจสอบข้อมูลแพลตฟอร์มล้มเหลว โปรดติดต่อผู้ดูแลระบบ",
    "consignorAlreadyExists": "มีผู้ขายฝากอยู่แล้ว",
    "noTrackingNumber": "ไม่มีหมายเลขจัดส่ง",
    "hasTrackingNumber": "มีหมายเลขการจัดส่ง",
    "trackingNumberStatus": "สถานะหมายเลขจัดส่ง",
    "rulesBetween": "{name}อควรอยู่ระหว่าง {range}!",
    "waitingOutbound": "รอการออกคลัง",
    "productPriceShouldBeBetween": "ราคาสินค้าควรอยู่ระหว่าง 1-1,000,000,000",
    "pleaseMoveToTheMasterProduct": "โปรดย้ายไปที่หน้าการแก้ไขสินค้าหลักเพื่อดำเนินการให้เสร็จสิ้นและเสร็จสิ้น",
    "kongfuRequiresThatTheLabelMust": "*KONGFU กำหนดให้ใบปะหน้านามสกุลไฟล์ต้องลงท้ายด้วย \".pdf\" หากการอนุญาตสำเร็จหมายความว่าคุณยอมรับว่า KONGFU สามารถรับใบปะหน้าที่ถูกต้องในระยะยาวได้",
    "outboundListNumber": "หมายเลขรายการสต็อกคลังขาออก",
    "inventoryChangesDueToManualOperation": "การเปลี่ยนแปลงสินค้าคงคลังเนื่องจากการดําเนินการด้วยตนเองโดยบุคลากรคลังสินค้า",
    "totalDiscountedPrice": "ยอดรวมส่วนลดสินค้า",
    "inventoryChangesDueToWarehousePersonnel": "เจ้าหน้าที่คลังสินค้ายกเลิกการเข้าไปในคลังสินค้าด้วยตัวเอง",
    "thirdpartyWarehouseInboundAdjustment": "บุคคลที่สาม กำลังปรับคลัง",
    "sinceTheKongfuApiLimitsThe": "เนื่องจากค่าใช้จ่ายกังฟู api จะถูกจำกัดไว้ที่ 999，999 โปรดทราบว่าหากต้นทุนมากกว่า 999，999 เราจะใช้ 999，999",
    "pleaseCheckTheDocumentType": "โปรดตรวจสอบชนิดของเอกสาร",
    "tryNow": "ลองเลย",
    "talkLater": "คุยกันทีหลัง",
    "congratulationsYouWillGetADay": "ยินดีด้วยค่ะ คุณจะได้รับทดลองใช้ฟรี 7 วันพร้อมฟังก์ชั่นระบบเต็มรูปแบบ",
    "theTrialVersionHasExpiredAnd": "ระบบทดลองหมดอายุและแพ็คเกจถูกลดระดับเป็นรุ่นฟรี หากคุณต้องการอัปเกรดโปรดซื้อแพ็คเกจ",
    "theTrialPackageWasUpdatedSuccessfully": "การอัปเดตแผนทดลองใช้สําเร็จแล้ว",
    "warehouseWarehousenameWarehouseActive": "ข้อมูลคลังสินค้า / คลังสินค้า{warehouseName} / {active}",
    "theSalesTypeNameIsEmpty": "ชื่อประเภทการขายว่างเปล่า",
    "generalInformation": "ข้อมูลทั่วไป",
    "imageForPos": "รูปภาพสำหรับ POS",
    "onlineAttributes": "คุณลักษณะออนไลน์",
    "refurbished": "ต่ออายุ",
    "insertProductWeightIncludingThePackaging": "ใส่น้ำหนักสินค้ารวมบรรจุเพื่อคำนวณอัตราค่าขนส่ง",
    "maximumMax": "สูงสุด: {สูงสุด}",
    "kilogramKg": "กิโลกรัม (กก.)",
    "gramGr": "กรัม (กรัม)",
    "insertProductDimensionAfterPackagingTo": "ใส่ขนาดสินค้าหลังบรรจุเพื่อคำนวณอัตราค่าจัดส่ง",
    "activatingThePreorderFeatureAllowsYou": "การเปิดใช้งานฟีเจอร์คำสั่งซื้อล่วงหน้าช่วยให้คุณขยายเวลาดำเนินการของคำสั่งซื้อได้สูงสุด 90 วัน (รวมวันหยุดสุดสัปดาห์และวันหยุดนักขัตฤกษ์)",
    "productIsReadyStock": "มีสต็อคสินค้าพร้อม",
    "productNeedLongerShippingProcess": "ต้องใช้กระบวนการจัดส่งสินค้าที่นานขึ้น",
    "processingTime": "ระยะเวลาดำเนินการ",
    "mokaOrderBetweenDays": "คำสั่งซื้อ Moka : ระหว่าง 1-90 วัน",
    "mokaProductImageTooltip": "เพิ่มได้สูงสุด 12 ภาพ ขนาดต่ำสุด 500x500px และสูงสุด 8MB รูปแบบ: PNG, JPEG, BMP",
    "Inventory": "สินค้าคงคลัง",
    "cost": "ต้นทุน",
    "isMultiplePricePerSalesType": "มีหลายราคาต่อประเภทการขาย",
    "trackStock": "ติดตามสต็อก",
    "alert": "แจ้งเตือน",
    "alertAt": "แจ้งเตือนที่",
    "itemStockCanNotBeChanged": "ไม่สามารถเปลี่ยนแปลงสต็อกสินค้าได้หลังจากบันทึกสินค้าแล้ว ดังนั้นโปรดตรวจสอบให้แน่ใจว่าถูกต้อง!",
    "thisItemCanNotBeTracked": "ไม่สามารถติดตามรายการนี้ได้เนื่องจากไม่มีการติดตามสต็อคสินค้าคงคลัง",
    "avgCostCanNotBeChanged": "ราคาเฉลี่ยไม่สามารถเปลี่ยนแปลงได้หลังจากบันทึกรายการ ดังนั้นโปรดตรวจสอบว่าถูกต้อง!",
    "trackCogs": "ติดตาม COGS",
    "avgCost": "ค่าใช้จ่ายเฉลี่ย",
    "theSynchronizationIsCompleteAndThe": "การเชื่อมต่อเสร็จสมบูรณ์ และประเภทการขายต่อไปนี้ใช้ไม่ได้อีกต่อไป",
    "Empty": "เปล่า",
    "domesticLogisticsTrackingNumberCannotBe": "ต้องระบุหมายเลขติดตามการขนส่งภายในประเทศ",
    "consignorDoesntMatch": "ผู้ส่งไม่ตรงกัน",
    "documentNumberDoesNotExist": "ไม่มีหมายเลขเอกสาร",
    "trackingNumberAlreadyExists": "มีหมายเลขพัสดุอยู่แล้ว",
    "packageAlreadyExists": "มีแพ็คเกจอยู่แล้ว",
    "paketTambahanTidakBolehLebihBesar": "แพ็กเกจเสริมต้องไม่เกินจำนวนรวมของสินค้า",
    "theOrderHasBeenShippedNo": "จัดส่งคำสั่งซื้อแล้วไม่สามารถเพิ่มแพ็คเกจเพิ่มเติมได้",
    "productInformationCantBeEmpty": "ต้องระบุข้อมูลสินค้า",
    "theresEmptyDataInTheCombination": "มีข้อมูลว่างเปล่าในหมายเลขแพ็คเกจข้อมูลสินค้าแบบรวม",
    "declaredAmount": "จำนวนเงินที่ประกาศ",
    "declaredWeight": "น้ำหนักที่ประกาศ",
    "menuProductMoka": "รายการสินค้า / Moka POS",
    "menuProductMokaAdd": "รายการสินค้า / Moka POS / เพิ่มสินค้า",
    "menuProductMokaEdit": "รายการสินค้า / Moka POS / แก้ไขสินค้า",
    "stockInOutAlert": "บันทึกการเข้า / ออกของสต็อกจะแสดงการเปลี่ยนแปลงทั้งหมดในสต็อกคลังสินค้าในช่วง 3 เดือนที่ผ่านมา",
    "theStockCanBePushedTo": "สามารถผลักดันสต็อกไปที่ Moka ได้หลังจากเปิดสวิตช์ติดตามสต็อกแล้วเท่านั้น",
    "youHaveADayFullfeaturedTrial": "คุณสามารถทดลองใช้ฟีเจอร์เต็มรูปแบบฟรี 7 วัน!",
    "youAreUsingTheFreeVersion": "ตอนนี้คุณกำลังใช้ Ginee แบบฟรี\nหากคุณต้องการฟังก์ชั่นเช่นการจัดการคลังสินค้าและการเชื่อมต่อคลังสินค้า คุณสามารถสมัครหนึ่งครั้งสำหรับการทดลองใช้ฟังก์ชั่นเต็มรูปแบบของ Ginee ฟรี 7 วัน\nคุณแน่ใจหรือว่าต้องการสมัครทดลองใช้งาน? (คาดว่าจะมีผลภายใน 15 นาทีหลังจากสมัคร)",
    "Cname": "ชื่อช่องทาง",
    "thisStaffAccountHasNoPermission": "บัญชีพนักงานนี้ไม่ได้รับอนุญาตให้เข้าถึงระบบโปรดติดต่อผู้ใช้งาน / ผู้ดูแลระบบเพื่อเพิ่มบัญชีพนักงานลงในระบบและกำหนดสิทธิการเข้าถึง",
    "makeSureTheSwitchIsTurned": "ตรวจสอบให้แน่ใจว่าสวิตช์เปิดอยู่เมื่อแก้ไขสต็อค มิฉะนั้น การแก้ไขจะล้มเหลว",
    "printTaskCompletionTime": "เวลาพิมพ์เสร็จ",
    "printTaskCreationTime": "เวลาพิมพ์งาน",
    "noteLazadaAndBlibliOrdersDo": "หมายเหตุ: คำสั่งซื้อ Lazada และ Blibli ไม่รองรับการตั้งค่าสถานะการบรรจุ",
    "prefixMatch": "จับคู่คำนำหน้า",
    "prefixSearch": "ค้นหาคำนำหน้า",
    "GineeAccountCanOnlyAuthorize": "1 บัญชี Ginee สามารถอนุญาตได้เพียง 1 บัญชีที่ถูกต้อง หากคุณมีความต้องการเพิ่มเติมโปรดติดต่อฝ่ายบริการลูกค้า",
    "matchingFailedThePreviousMatchingOption": "การจับคู่ล้มเหลว: ไม่พบตัวเลือกการจับคู่ก่อนหน้า โปรดยืนยันว่าตัวเลือกนั้นถูกลบไปแล้วหรือไม่",
    "itemPriceCredit": "ยอดการซื้อขาย",
    "freeShippingMaxFee": "ฟรีค่าโปรโมชั่นจัดส่ง",
    "paymentFee": "ค่าธรรมเนียมการชำระเงิน",
    "lazpicklaztopCommission": "ค่าคอมมิชชั่น Lazpick / LazTop",
    "platformRequirementsTheDescriptionLengthMust": "ข้อกำหนดของแพลตฟอร์ม: ความยาวคำอธิบายต้องอยู่ระหว่าง 100 ถึง 3000 ตัวอักษร",
    "currentlyBumped": "สินค้าที่โปรโมทอยู่",
    "numberOfBumpProducts": "จำนวนสินค้าที่โปรโมท",
    "bumpFixedSupportMaximumProducts": "โปรโมทสินค้าคงที่สูงสุด 5 รายการ",
    "bumpfixed": "โปรโมทสินค้าคงที่",
    "bumpByTurns": "โปรโมทสินค้าผลัดกัน",
    "setAsBumpFixed": "ตั้งค่าเป็นโปรโมทสินค้าคงที่",
    "setAsBumpByTurns": "ตั้งค่าเป็นโปรโมทสินค้าผลัดกัน",
    "tipsYouCanAddUpTo": "ทิป: คุณสามารถเพิ่มสินค้าได้สูงสุดครั้งละ 30 รายการ กรุณาจัดลำดับความสำคัญการเชื่อมต่อข้อมูลสินค้าในร้านค้า",
    "editingInformationSuchAsStockAnd": "ไม่รองรับการแก้ไขข้อมูลเช่นสต็อกและราคาซื้อของสินค้าโดยรวม",
    "failedToUploadImagePleaseReupload": "ล้มเหลวในการอัปโหลดภาพ โปรดอัปโหลดภาพใหม่และลองอีกครั้ง",
    "solutionSteps": "ขั้นตอนการแก้ปัญหา",
    "addBinding": "เพิ่มการผูกมัด",
    "pleaseClickToRedistribute": "กรุณาคลิกเพื่อแจกจ่ายอีกครั้ง",
    "pleaseAddBindingRelationship": "โปรดเพิ่มความเชื่อมโยง",
    "pleaseEnableDistributionSettings": "โปรดเปิดใช้งานการตั้งค่าการกระจาย",
    "distributionSettingsArentEnabled": "ไม่ได้เปิดใช้งานการตั้งค่าการแจกจ่าย",
    "pleaseRestoreTheOrder": "โปรดเรียกคืนคำสั่งซื้อ",
    "ordersThatHaveBeenPlacedOn": "คำสั่งซื้อที่ถูกระงับจะไม่เข้าร่วมการล็อคสต็อกหรือการหักเงิน",
    "problemCauses": "สาเหตุของปัญหา",
    "pleaseCreateAnInboundListAnd": "โปรดสร้างรายการขาเข้าและกรอกขาเข้า",
    "pleaseAdjustTheShippingWarehouse": "กรุณาปรับคลังสินค้าจัดส่ง",
    "pleaseCompleteTheInboundInThe": "กรุณากรอกข้อมูลขาเข้าในคลังสินค้าของบุคคลที่สาม",
    "pleaseCreateAnInboundList": "โปรดสร้างรายการขาเข้า",
    "mskuInXWarehouseYOut": "MSKU {y} ใน {x} คลังสินค้านี้หมด",
    "theOrderStatusOfTheX": "สถานะการสั่งซื้อของช่อง {x} คือ: {y} ไม่รองรับการสร้างรายการขาออก",
    "currentlyDoesNotSupportTheCreation": "ขณะนี้ไม่รองรับการสร้างรายการขาออกสำหรับช่อง {x}",
    "theXThirdpartyWarehouseDoesNot": "ขณะนี้คลังสินค้าบุคคลที่สาม {x} ไม่รองรับการสร้างรายการขาออกสำหรับ {y} ประเทศ",
    "theXThirdpartyWarehouseDoesntCurrently": "ขณะนี้คลังสินค้าบุคคลที่สาม {x} ไม่สนับสนุนการสร้างรายการขาออกสำหรับช่องทาง {y}",
    "bumpFixed": "โปรโมทสินค้าคงที่",
    "serviceFeeMapping": "การจับคู่ค่าธรรมการบริการ",
    "platformFulfillmentFeeMapping": "การจับคู่ค่าธรรมเนียมการปฏิบัติตามแพลตฟอร์ม",
    "platformRebateMapping": "การจับคู่เงินคืนแพลตฟอร์ม",
    "voucherSellerMapping": "การจับคู่ผู้ขายบัตรกำนัล",
    "shippingDifferenceCashlessMapping": "การจับคู่ส่วนต่างการจัดส่ง (ไร้เงินสด)",
    "shippingFeeMapping": "ตั้งค่าค่าธรรมเนียมค่าขนส่ง",
    "shippingPriceSetting": "ตั้งค่าค่าขนส่ง",
    "taxSetting": "ตั้งค่าภาษี",
    "salesInvoiceSetting": "ตั้งค่าใบแจ้งหนี้การขาย",
    "storeMapping": "จับคู่ร้านค้า",
    "warehouseMapping": "จับคู่คลังสินค้า",
    "database": "ฐานข้อมูล",
    "newCompleteOrdersAfterTheSt": "ใหม่ \"คำสั่งซื้อที่สมบูรณ์\" หลังจากวันที่ 1 ของเดือน",
    "newCompleteOrderAfterMappingStore": "ใหม่ \"คำสั่งซื้อที่สมบูรณ์\" หลังจากจับคู่ร้านค้า",
    "afterEnablingIfAccurateHasStock": "หลังจากเปิดใช้งาน หาก Accurate มีการเปลี่ยนแปลงสต็อค:\n1. ส่งไปที่ Ginee แบบเรียลไทม์ และแก้ไขสต็อกของ Ginee\n2. รองรับการดึงค่าสต็อกล่าสุดของ Accurate ในรายการสต็อกด้วยตนเอง",
    "stockSyncRules": "กฎการเชื่อมต่อสต็อก",
    "mappingTime": "เวลาการจับคู่",
    "branch": "สาขา",
    "chartOfAccounts": "ผังบัญชี",
    "pleaseCompleteAtLeast": "โปรดทำการจับคู่ร้านค้าอย่างน้อย 1 แห่ง หลังจากการจับคู่ร้านค้าสำเร็จ คำสั่งซื้อที่เสร็จสมบูรณ์ของร้านค้าจะสร้างใบแจ้งหนี้การขายโดยอัตโนมัติและผลักดันไปยัง ถูกต้อง",
    "gineeWarehouseCanOnly": "คลังสินค้า Ginee 1 แห่งสามารถจับคู่กับคลังสินค้าที่ถูกต้อง 1 แห่งเท่านั้น โปรดดำเนินการจับคู่คลังสินค้าอย่างน้อย 1 แห่ง",
    "enabledByDefault": "เปิดใช้งานโดยค่าเริ่มต้น",
    "autoCreateRulesForSalesInvoice": "สร้างกฎสำหรับใบแจ้งหนี้การขายโดยอัตโนมัติ",
    "syncFromAccurateToGinee": "เชื่อม Accurate เข้ากับ Ginee",
    "syncFromGineeToAccurate": "เชื่อม Ginee เข้ากับ Accurate",
    "finance": "ด้านการเงิน",
    "mapFailed": "จับคู่ล้มเหลว",
    "notMapped": "ไม่ได้ถูกจับคู่",
    "mapped": "จับคู่แล้ว",
    "stockChange": "การเปลี่ยนแปลงสต็อก",
    "pushMapMsku": "ผลักดัน & จับคู่ MSKU",
    "repushStock": "ผลักดันสต็อกอีกครั้ง",
    "upToCharactersAndOnly": "สูงสุด 50 ตัวอักษรและรองรับเฉพาะ: a-z, A-Z, 0-9, \"(\", \")\", \"-\", \".\", \"_\", \"/\" และต้นทุนต่อหน่วยต้องเป็น >=1",
    "allUncreatedAndUnmappedMskusCurrently": "MSKU ที่ไม่ได้สร้างและไม่ได้จับคู่ทั้งหมดในปัจจุบันและที่เพิ่มในภายหลัง",
    "afterItIsEnabledAllMskus": "หลังจากเปิดใช้งานแล้ว MSKU ทั้งหมดในคลังสินค้า Ginee ที่แมปจะถูกพุชไปยังคลังสินค้าที่ถูกต้อง SKU ใหม่จะถูกสร้างขึ้นโดยอัตโนมัติใน Accurate และ SKU ที่มีอยู่จะถูกจับคู่โดยอัตโนมัติด้วย Accurate",
    "mappingRules": "กฎการจับคู่",
    "autoPushMap": "ผลักดันและจับคู่อัตโนมัติ",
    "thisSkuAlreadyExists": "SKU นี้มีอยู่แล้ว",
    "unitCostMustBe": "ต้นทุนต่อหน่วยต้องเป็น >=1",
    "salesInvoice": "ใบแจ้งหนี้การขาย",
    "syncStock": "เชื่อมต่อสต็อก",
    "theStoreIsMissingMappingInformation": "ร้านค้าไม่มีการจับคู่ด้านข้อมูล(คลังสินค้า สาขา ผังบัญชี ลูกค้า)",
    "source": "แหล่งที่มา",
    "associatedOrderNumber": "หมายเลขคำสั่งซื้อที่เกี่ยวข้อง",
    "itIsRecommendedToConvertTo": "แนะนำให้แปลงเป็นสกุลเงินที่ระบบกำหนดไว้",
    "theCurrencySymbolIsBasedOn": "สกุลเงินจะปรับตามสกุลเงินที่ตั้งค่าไว้ในระบบ\\nจำนวนเงินคือราคาต้นทุนเฉลี่ยของสินค้าขาเข้า\\\",\"Biểu tượng tiền tệ dựa trên đơn vị tiền tệ được thiết lập trong hệ thống.",
    "bulkrepush": "ดันสต็อกอีกครั้ง",
    "alasanGagalPushGudangPihakKetiga": "เหตุผลล้มเหลวในการดันสต็อกไปยังคลังฝากสต็อกสินค้า",
    "noShelf": "ชั้นวางเริ่มต้น",
    "plannedQuantityTip": "กรอกจำนวนสินค้าขาออกที่วางแผนไว้",
    "plannedQuantityTipOld": "จำนวนขาออกที่วางแผนไว้: กรอกจำนวนสินค้าขาออกที่วางแผนไว้",
    "selectLocationTip": "เลือกตำแหน่งที่จัดเก็บสินค้าของ SKU ที่จะขาออกจากคลังสินค้า",
    "exportSelectedOrderDetail": "รายละเอียดใบคลังสินค้าขาเข้า-เลือก",
    "exportAllOrderDetail": "รายละเอียดใบคลังสินค้าขาเข้า-ทั้งหมด",
    "exportSelectedOrderList": "รายการใบคลังสินค้าขาเข้า-เลือก",
    "exportAllOrderList": "รายการใบคลังสินค้าขาเข้า-ทั้งหมด",
    "mskuAndBarcode": "SKU หลัก/บาร์โค้ด",
    "confirmToSetAsInvalid": "กรุณายืนยันว่าจะทิ้งหรือไม่？",
    "bulkSubmitSuccess": "อัปเดตสำเร็จ กรุณารีเฟรชหน้าเพื่อดูผลการประมวลผล",
    "bindFailed": "เชื่อมต่อล้มเหลว กรุณาเลือกตำแหน่งจัดเก็บสินค้าอีกครั้ง",
    "bindSuccess": "เชื่อมต่อสำเร็จ",
    "autoCreateAndBind": "สร้างตำแหน่งจัดเก็บสินค้าโดยอัตโนมัติและเชื่อมต่อกับSKU หลัก",
    "warehouseName2": "ชื่อคลังสินค้า",
    "warehouseCode": "หมายเลขของคลังสินค้า",
    "quantityAdded": "จำนวนที่เพิ่ม",
    "sureToDelete": "ยืนยันว่าจะลบไหม?",
    "selectLocation": "เลือกตำแหน่งจัดเก็บสินค้าของ SKU ในคลังสินค้า",
    "enterQuantity": "กรอกจำนวนที่เข้าคลังสินค้าครั้งนี้",
    "createLocationTips": "หากคุณต้องสร้างตำแหน่งจัดเก็บสินค้าใหม่เป็นกลุ่ม คุณสามารถสร้างได้ในการจัดการตำแหน่งจัดเก็บสินค้า",
    "locationManagement": "การจัดการตำแหน่งจัดเก็บสินค้า",
    "warehouseStockColumnName": "สต็อกคลังสินค้า",
    "stockNotEnough": "สต็อกคลังสินค้าไม่เพียงพอ ไม่สามารถออกคลังสินค้าได้",
    "externalOrderNo": "หมายเลขคำสั่งซื้อ",
    "numSelectedWithValue": "{val} ถูกเลือก",
    "column3TipOld": "คอลัมน์ที่ 3: ตำแหน่งจัดเก็บสินค้า(ไม่บังคับ) หากไม่กรอกข้อมูล จะเลือกตำแหน่งจัดเก็บสินค้าที่ใหญ่ที่สุดในสต็อกพร้อมส่ง",
    "column2TipOld": "คอลัมน์ที่ 2: จำนวน (จำเป็นต้องกรอก)",
    "column1TipOld": "คอลัมน์ที่ 1: SKU (จำเป็นต้องกรอก)",
    "column3Tip": "คอลัมน์ที่ 3: ชั้นวางสินค้า (ไม่บังคับ) หากคุณไม่กรอกข้อมูล ให้ใช้ตำแหน่งที่จัดเก็บสินค้าเริ่มต้น",
    "column2Tip": "คอลัมน์ที่ 2: จำนวน (จำเป็นต้องกรอก)",
    "column1Tip": "คอลัมน์ที่ 1: SKU (จำเป็นต้องกรอก)",
    "copyTip": "คัดลอก SKU จำนวนออกคล้ง ชั้นวาง และตำแหน่งจัดวางสินค้าจาก excel ไปยังกล่องข้อความ ซึ่งสามารถวิเคราะห์และส่งได้อย่างรวดเร็ว",
    "printDetails": "พิมพ์รายละเอียดสินค้า",
    "copyTip2": "คอลัมน์แรกคือ SKU คอลัมน์ที่สองคือจำนวน คอลัมน์ที่สามคือสถานที่ตั้ง",
    "logisticsNumberLengthError": "ความยาวสูงสุดของหมายเลขการจัดส่งคือ 200 หลัก",
    "duplicateLocationError": "ตำแหน่งจัดวางสินค้าซำ้ สามารถส่งได้เพียงบรรทัดเดียวสำหรับตำแหน่งจัดวางสินค้าเดียวกัน",
    "setPushRatio": "การตั้งค่าคลังจัดส่งของร้านค้า—การตั้งค่าคลังจัดส่งของร้านค้า—หากมีหลายร้านค้า คุณสามารถตั้งค่าและเชื่อมคลังสำหรับทุกร้าน และยังมาสารถตั้งค่าอัตราการส่งต่อสต็อกให้กับทุกร้านค้า",
    "ifOnlyOneWarehouse": "หากคุณมีเพียงคลังหนึ่ง ไม่จำเป็นต้องการเชื่อมต่อตำแหน่งจัดวางสินค้า ระบบจะช่วยสร้างตำแหน่งจัดวางสินค้าสำหรับทุก SKU เมื่อสร้างสินค้าหลัก ซึ่งคุณสามารถใช้ได้โดยตรง",
    "addProductsToLocation": "รายละเอียดของสต็อกคลัง—หลังจากตั้งค่าสำเร็จแล้ว คุณสามารถวางสินค้าเข้าไปในสถานที่ตั้ง ในหน้ารายละเอียดสต็อกคลัง คลิก“เพิ่มสินค้าไปยังตำแหน่งจัดวางสินค้า” จากนั้นเชื่อมสินค้ากับตำแหน่งจัดวางสินค้า หลังจากเชื่อมสำเร็จ คุณก็สามารถจัดการสต็อกของสินค้าในตำแหน่งจัดวางสินค้าได้แล้ว",
    "setUpLocation": "การจัดการตำแหน่งจัดวางสินค้า—ตำแหน่งที่จัดวางสินค้าในคลัง คุณต้องตั้งค่าตำแหน่งจัดวางสินค้า",
    "setUpWarehouse": "การจัดการคลัง—คุณต้องการตั้งค่าคลังในฟังก์ชั่นการจัดการคลัง",
    "helpShippingSettings": "การตั้งค่าคลังจัดส่งของร้านค้า",
    "helpStockDetails": "รายละเอียดสต็อกในคลัง",
    "helpLocationManage": "การจัดการพื้นที่วางสินค้า",
    "helpWarehouseManage": "การจัดการคลังสินค้า",
    "warehouseManagementIntro": "แนะนำฟีเจอร์การจัดการคลังสินค้า",
    "moreThanPlannedError": "เกินกว่าจำนวนออกคลังตามแผน",
    "INVALID": "ข้อมูลไม่ถูกต้อง",
    "PART_INVALID": "ข้อมูลบางส่วนไม่ถูกต้อง",
    "COMPLETE": "เสร็จแล้ว",
    "PART_COMPLETE": "เสร็จบางส่วน",
    "PENDING": "รอการออกคลัง",
    "CREATE": "กำลังจัดการ",
    "changeWarehouseAlert": "หากเปลี่ยนคลังสินค้าต้องเพิ่มข้อมูล SKU อีกครั้ง กรุณายืนยันก่อน",
    "processResult": "สถานะการดำเนินการ",
    "complete": "ดำเนินการสำเร็จแล้ว",
    "pushComplete": "ส่งต่อสำเร็จ",
    "pushing": "กำลังส่งต่อ",
    "workingStatusCANCEL_FAIL": "ยกเลิกล้มเหลว",
    "workingStatusCANCEL_SUCCESS": "ยกเลิกสำเร็จ",
    "workingStatusCANCELED": "ยกเลิก",
    "workingStatusCANCEL": "ยกเลิก",
    "workingStatusOUTBOUND_DONE": "ออกคลังสำเร็จ",
    "workingStatusCANCELING": "กำลังยกเลิก",
    "workingStatusWAITING_PICKUP": "สินค้ารอรับอยู่",
    "workingStatusPACKED": "แพ็คสินค้าแล้ว",
    "workingStatusSORTING_AND_REVIEWING": "จัดเรียงและรีวิวอยู่",
    "workingStatusPICKING_PROCESS": "กำลังหยิบสินค้าอยู่",
    "workingStatusALLOCATED": "ดำเนินการสำเร็จ",
    "workingStatusRECEIVED": "ได้รับแล้ว",
    "workingStatusSYNC_FAIL": "เชื่อมต่อล้มเหลว",
    "workingStatusSYNC_SUCCESS": "เชื่อมต่อสำเร็จ",
    "workingStatusCREATE": "กำลังดำเนินการ",
    "workingStatus": "สถานะทำงาน",
    "express": "ขนส่ง",
    "editOutboundList": "แก้ไขรายการออกคลัง",
    "defaultDelivery": "ค่าเริ่มต้น",
    "selfPickup": "รับเอง",
    "outboundListDetail": "รายละเอียดของรายการสินค้าออกคลัง",
    "failedLineTip": "ล้มเหลว（ไม่สามารถเพิ่มไปยังรายการสินค้าออกคลัง）",
    "ifMoreThanStock1": "หากจำนวน SKU เยอะกว่าสต็อกพร้อมส่ง ระบบจะบันทึกตามจำนวนสต็อกพร้อมส่งสูงสุด",
    "ifTwoColumns1": "หากมีเพียงสองคอลัมน์ ระบบก็จะจำแนกตาม SKU + จำนวน",
    "copyTipOld": "คัดลอก SKU, จำนวน, ตำแหน่งพื้นที่วางสินค้า, และการประมวลผลของ Ginee จากexcel",
    "outboundListInvalid": "รายการสินค้าออกคลังไม่ถูกต้อง",
    "outboundAll": "นำสินค้าออกคลังทั้งหมด",
    "outboundListOutbound": "รายการออกคลัง",
    "outboundManagement": "การจัดการใบคลังสินค้าขาออก",
    "scanInstruction": "สามารถสแกนบาร์โค้ดโดยใช้เครื่องสแกนและกดEnter หากจำแนกสำเร็จ จำนวนสินค้าออกคลังจริงจะเพิ่มขึ้น 1 ชิ้น",
    "receivedProduct": "สินค้าเข้าคลัง",
    "goBack": "กลับ",
    "addToOutboundList": "เพิ่มไปยังรายการออกคลัง",
    "row": "แถว",
    "operationSuccessful": "ดำเนินการสำเร็จ!",
    "editSuccess": "แก้ไขสำเร็จ",
    "identify": "จำแนก",
    "identifyResults": "ผลลัพธ์การประมวลผล",
    "ifMoreThanStock": "หากจำนวนSKUเยอะกว่าสต็อกพร้อมส่ง ระบบจะใช้จำนวนสต็อกพร้อมส่งสุงสุด",
    "ifTwoColumns": "หากมีเพียง 2 คอลัมน์จะใช้ SKU + จำนวนเพื่อประมวลผลข้อมูล",
    "submit": "บันทึก",
    "directOutbound": "ออกคลังโดยตรง",
    "locationStockNotEnough": "สต็อกพร้อมส่งในตำแหน่งจัดวางสินค้าไม่เพียงพอ",
    "locationAvailableStock": "สต็อกในพื้นที่จัดวางสินค้าที่พร้อมส่ง",
    "warehouseAvailableStock": "สต็อกในคลังที่พร้อมส่ง",
    "note": "โน้ด",
    "plannedTotalAmount": "ยอดเงินทั้งหมดที่วางแผนไว้",
    "unitPrice": "ราคาต่อหน่วย",
    "associatedDocumentNumber": "หมายเลขเอกสารที่เกี่ยวข้อง",
    "refreshStatus": "สถานะตั้งค่าใหม่",
    "outboundTime": "เวลาออกคลัง",
    "deliveryMethod": "รูปแบบการจัดส่ง",
    "actualOutboundQty": "จำนวนที่ออกคลังจริง",
    "plannedOutboundQty": "จำนวนสต็อกที่ต้องออกคลัง",
    "actualOutboundAmount": "ยอดเงินที่ออกคลังจริง",
    "plannedOutboundAmount": "ยอดเงินที่วางแผนจะออกคลัง",
    "searchContent": "ค้นหาเนื้อหา",
    "sortListSelected": "รายการจัดเรียงสินค้า—ส่วนที่เลือกไว้",
    "sortListAll": "รายการจัดเรียงสินค้า—ทั้งหมด",
    "pickListSelected": "รายการหยิบสินค้า—ส่วนที่เลือกไว้",
    "pickListAll": "รายการหยิบสินค้า—ทั้งหมด",
    "outboundListSelected": "รายการออกคลัง—ส่วนที่เลือกไว้",
    "outboundListAll": "รายการออกคลัง—ทั้งหมด",
    "createOutboundList": "สร้างรายการสต็อกคลังขาออก",
    "greaterThan30Days": "＞30วัน",
    "lessThan30Days": "≤ 30วัน",
    "lessThan7Days": "≤ 7วัน",
    "lessThan3Days": "≤ 3วัน",
    "listNumber": "หมายเลขรายการ",
    "searchType": "ประเภทการค้นหา",
    "allocateOutboundList": "ใบคลังสินค้าขาออกโดยการโอนสต็อก",
    "documentType": "ประเภทเอกสาร",
    "shippedOld": "ออกคลังแล้ว",
    "documentStatus": "สถานะเอกสาร",
    "pleaseSelectData": "กรุณาเลือกข้อมูลที่ต้องการส่งออก",
    "basicFeaturesFreeForever": "ใช้ฟรีตลอด",
    "nMinutesRemaining": "เหลืออีก {minus} นาที",
    "gineeAndAccurateAreMappedBy": "Ginee จับคู่กับ Accurate ผ่าน SKU เนื่องจาก Accurate ไม่แยกความแตกต่างระหว่างตัวพิมพ์ใหญ่และตัวพิมพ์เล็กโปรดตรวจสอบให้แน่ใจว่าไม่มี SKU ที่มีชื่อเดียวกันภายใต้คลังสินค้านี้ก่อนที่จะจับคู่",
    "accurateRequiresSkuToHaveA": "Accurate ต้องการ MSKU สูงสุด 50 ตัวอักษรและรองรับเฉพาะ: a-z A-Z 0-9 \" (\",)\" \"-\" \"\" _ \" / \"",
    "otherSettingsAndMapping": "การตั้งค่าและการจับคู่อื่น ๆ",
    "mapping": "การจับคู่",
    "automapSettings": "การตั้งค่าจับคู่อัตโนมัติ",
    "pleaseSetAndMapTheCorrect": "โปรดตั้งค่าและจับคู่ข้อมูลที่ถูกต้อง",
    "basedOnAccurateStock": "ขึ้นอยู่กับสต็อก Accurate",
    "basedOnGineeStock": "ขึ้นอยู่กับสต็อก Ginee",
    "whenTheSkuOfGineeAnd": "เมื่อมีการจับคู่ SKU ของ Ginee และแม่นยำ หากสต็อกของทั้งสองระบบแตกต่างกัน โปรดเลือกสต็อกของระบบที่เป็นข้อมูลอ้างอิง และ Ginee จะดันหรือดึงสต็อกโดยอัตโนมัติหลังจากการจับคู่",
    "startingStock": "สต็อกเริ่มต้น",
    "theSubproductsOfTheCombinedProduct": "สินค้าย่อยของสินค้าที่รวมกันยังไม่ได้ทำการจับคู่โปรดจับคู่ของสินค้าย่อยให้เสร็จก่อนทำการจับคู่รวมสินค้า",
    "theWarehouseHasMatchedTheAccurate": "คลังสินค้าได้รับการจับคู่กับคลังสินค้าที่ถูกต้องและสต็อกและราคาต้นทุนของสินค้าจะต้องเป็น> 0",
    "accurateAuthorizationHasExpiredPleaseReauthorize": "การอนุญาตที่หมดอายุ โปรดอนุญาตอีกครั้ง!",
    "accurateRequiresInventoryToBe": "Accurate ต้องใช้สต็อกและราคาต้นทุนเป็น >0",
    "goToChange": "แก้ไขปรับแต่ง",
    "inboundWhenAddedToWarehouse": "ขาเข้าเมื่อเพิ่มไปยังคลังสินค้า",
    "youHaveNotCompletedTheInitial": "คุณยังไม่เสร็จสิ้นการกำหนดค่าเริ่มต้นของ Accurate คุณลักษณะที่เกี่ยวข้องจะสามารถใช้ได้หลังจากการกำหนดค่าเท่านั้น",
    "failedToDeleteThisProductHas": "ลบไม่สำเร็จ! ผลิตภัณฑ์นี้เชื่อมโยงกับผลิตภัณฑ์ที่รวมกัน โปรดลบผลิตภัณฑ์ที่รวมกันหรือยกเลิกการเชื่อมโยงผลิตภัณฑ์ที่รวมกันก่อน",
    "noteIfTheWarehouseIsAdded": "หมายเหตุ: หากขาเข้าเมื่อเพิ่มไปยังคลังสินค้าเป็นคลังสินค้า WMS หรือคลังสินค้าของบุคคลที่สาม หลังจากขาเข้า คุณต้องยืนยันขาเข้าใน WMS/คลังสินค้าของบุคคลที่สามเพื่อเริ่มการซิงค์สต็อกให้สำเร็จ",
    "partnerAuthorizationFailed": "การให้สิทธิ์พันธมิตรล้มเหลว",
    "customerMustBeFilled": "ลูกค้าต้องกรอก",
    "itemXSuccessfullySaved": "บันทึกรายการ \"{x}\" สำเร็จแล้ว",
    "unitQtyMustBeFilled": "ต้องกรอกจำนวนหน่วย",
    "itemXNotFoundOrHas": "ไม่พบรายการ {x} หรือถูกลบไปแล้ว",
    "accurateConfigurationInformationIsMissingPlease": "ไม่มีข้อมูลการกำหนดค่าที่ถูกต้อง โปรดตรวจสอบการจัดการการกำหนดค่าแล้วลองอีกครั้ง",
    "theWarehouseXWasNotFound": "ไม่พบคลังสินค้า \"{x}\" หรือถูกลบไปแล้ว",
    "brazil": "Brazil",
    "taiwan": "Taiwan",
    "savedSuccessfullyThisPageWillClose": "บันทึกเรียบร้อยแล้ว! หน้านี้จะปิดโดยอัตโนมัติ",
    "salesInvoicePushRecord": "บันทึกพุชใบแจ้งหนี้การขาย",
    "orderPushTime": "เวลาสั่งซื้อสินค้า",
    "orderCompleteTime": "เวลาที่สั่งซื้อสำเร็จ",
    "completeTime": "เวลาที่สำเร็จ",
    "updateResult": "อัปเดตผลลัพธ์",
    "productManage": "การจัดการสินค้า",
    "becauseTheNumberOfStoresExceeds": "จำนวนคำสั่งซื้อของคุณถึงขีดจำกัดของแพ็คเกจและไม่สามารถใช้ฟีเจอร์นี้ได้ โปรดติดต่อฝ่ายบริการลูกค้าเพื่อสมัครรับแพ็คเกจใหม่",
    "theAuthorizationInformationIsFilledIncorrectly": "ข้อมูลการอนุญาตไม่ได้กรอกอย่างถูกต้องโปรดดูที่ศูนย์ช่วยเหลือการรวมและกรอกข้อมูลอีกครั้ง",
    "channelItemXMoveOutPromotion": "รายการช่อง: {x} ย้ายออก รหัสโปรโมชัน: {y}",
    "xManualReleaseOfReservedPromotion": "{x} การปล่อยสต็อคโปรโมชันที่สงวนไว้ด้วยตนเอง",
    "promotionIdXHasEnded": "รหัสส่งเสริมการขาย: {x} สิ้นสุดลงแล้ว",
    "xManuallyEditedReservedPromotionStock": "{x} แก้ไขสต็อกโปรโมชันที่สงวนไว้ด้วยตนเอง อัปเดตจาก {y} เป็น {z}",
    "channelProductXActivityIdY": "ช่องทางสินค้า: {x} รหัสโปรโมชัน: {y}",
    "unknownSystemException": "ไม่ทราบความผิดปกติของระบบ โปรดติดต่อฝ่ายบริการลูกค้า",
    "theChannelProductSpecificationCorrespondingTo": "ไม่พบข้อกำหนดช่องทางสินค้าที่สอดคล้องกับกิจกรรม",
    "theChannelProductCorrespondingToThe": "ไม่พบช่องทางสินค้าที่ตรงกับเหตุการณ์",
    "hasNoWarehouseInformationAndCannot": "MSKU ไม่มีข้อมูลคลังสินค้าและไม่สามารถจองสต็อคส่งเสริมการขายได้",
    "channelSkuHasNoBoundMsku": "Channel SKU ไม่มี MSKU ที่ผูกไว้ โปรดไปที่รายการสินค้าหลักเพื่อผูก SKU ก่อน",
    "notCompliantWithPromotionReservation": "ไม่เป็นไปตามนโยบายการจองโปรโมชัน: \"ไม่ได้เริ่มต้นและโปรโมชันต่อเนื่องจะถูกจองโดยอัตโนมัติ\"",
    "promotionReservationPolicyNotMet": "ไม่เป็นไปตามนโยบายการจองโปรโมชั่น: \"สงวนโดยอัตโนมัติสำหรับโปรโมชั่นที่ใช้งานอยู่เท่านั้น\"",
    "theCategoryOrAttributeInformationIs": "ข้อมูลหมวดหมู่หรือลักษณะต์ไม่ถูกต้อง โปรดลองอีกครั้งหลังจากเชื่อมต่อหมวดหมู่",
    "setUpOrderInvoices": "ตั้งค่าใบแจ้งหนี้การสั่งซื้อ",
    "orderPacking": "บรรจุคำสั่งซื้อ",
    "orderDistribution": "การกระจายคำสั่งซื้อ",
    "syncOrder": "เชื่อมต่อคำสั่งซื้อ",
    "theOrderSSuccessfullyPrintedThe": "คำสั่งซื้อ [%s] พิมพ์รายการหยิบสินค้าสำเร็จแล้ว",
    "theOrderssuccessfullyPrintedThePackingList": "คำสั่งซื้อ [%s] พิมพ์รายการบรรจุเรียบร้อยแล้ว",
    "orderSuccessInvoice": "คำสั่งซื้อ [%s] พิมพ์ใบแจ้งหนี้สำเร็จแล้ว",
    "orderSuccessLabel": "คำสั่ง [%s] พิมพ์ใบปะหน้าสำเร็จ",
    "ordersreturnedInboundTheReturnInboundList": "คำสั่งซื้อ [%s] ส่งคืนขาเข้า สร้างรายการขากลับสำเร็จ หมายเลขรายการ: <a href=\"/erp/main/outbound-management-detail/%s/SELL\" target=\"_blank\">%s</a>",
    "ordersredistributedSuccessfully": "คำสั่งซื้อที่แจกจ่ายซ้ำด้วยตนเอง [{0}] โปรดดูบันทึกการดำเนินการถัดไปสำหรับผลลัพธ์",
    "ordersfailedToPushWarehouseForDelivery": "คำสั่งซื้อ [%s] ล้มเหลวในการผลักดันคลังสินค้าสำหรับการจัดส่ง เหตุผล: %s",
    "theOrdersisSuccessfullyPushedToThe": "ส่งคำสั่งซื้อ [%s] ไปยังคลังสินค้าสำเร็จแล้ว",
    "orderspushWarehouseOperationSuccessfully": "คำสั่งซื้อ【%s】ส่งการดำเนินการคลังสินค้าสำเร็จ",
    "theOrderSSuccessfullySetThe": "คำสั่งซื้อ [%s] ตั้งค่าใบแจ้งหนี้สำเร็จ",
    "ordersarrangedDeliverySuccessfully": "คำสั่งซื้อ [%s] จัดส่งเรียบร้อยแล้ว",
    "orderspackedSuccessfully": "คำสั่งซื้อ [%s] บรรจุเรียบร้อยแล้ว",
    "orderscancelledSuccessfully": "คำสั่งซื้อ [%s] ถูกยกเลิกเรียบร้อยแล้ว",
    "theWarehouseSelectedForProductS": "คลังสินค้าที่เลือกสำหรับสินค้า [%s] ในคำสั่งซื้อ [%s] เปลี่ยนจาก [%s] เป็น [%s]",
    "theProductSInTheOrder": "สินค้า [%s] ในคำสั่งซื้อ [%s] ถูกแทนที่ด้วย [%s] และคลังสินค้าที่เลือกคือ [%s]",
    "theOrderSDeletesTheGift": "คำสั่งซื้อ [%s] นำของขวัญออก [%s] ปริมาณคือ [%s] และคลังสินค้าที่เลือกคือ [%s]",
    "addGiftSToOrderS": "เพิ่มของขวัญ [%s] เพื่อสั่งซื้อ [%s] จำนวนคือ [%s] และคลังสินค้าที่เลือกคือ [%s]",
    "theOrderSHasBeenCancelled": "คำสั่งซื้อ [{0}] ถูกยกเลิกแล้ว (กู้คืนคำสั่งซื้อ)",
    "ordersmarkedAsPutOnHold": "คำสั่งซื้อ [{0}] ทำเครื่องหมายว่า \"จัดเก็บแล้ว\"",
    "theOrderSIsMarkedAs": "คำสั่งซื้อ [%s] ถูกทำเครื่องหมายว่า [คำสั่งซื้อมีปัญหา-สินค้าหมด] เหตุผล: สินค้าหลัก [%s] ที่สอดคล้องกับสินค้าช่องทาง [%s] หมดสต็อก",
    "orderSIsMarkedAsOut": "คำสั่งซื้อ [%s] ถูกทำเครื่องหมายเป็น [คำสังซื้อที่มีปัญหาข้อผิดพลาดในการจัดสรร-คลังสินค้า] เหตุผล: [%s] ไม่รองรับการใช้คลังสินค้าเติมสินค้า [%s]",
    "theOrderSIsAPromotion": "ใบสั่ง [%s] เป็นใบสั่งส่งเสริมการขาย และสินค้าช่องทาง [%s] ได้รับการปันส่วนไปยังคลังสินค้า [%s]",
    "theChannelProductNotOutbound": "คำสั่งซื้อ [%s] ถูกทำเครื่องหมายว่า [คำสั่งซื้อที่มีปัญหา-MSKU ไม่เกี่ยวข้องกับคลังสินค้า] ช่องทางสินค้า  [%s] ไม่สามารถสร้างรายการขาออกได้ เหตุผล: สินค้าหลักที่เกี่ยวข้อง [%s] ไม่ได้อยู่ในคลังสินค้า [%s]",
    "theChannelProductCantGen": "คำสั่งซื้อ [%s] ถูกทำเครื่องหมายเป็น [คำสั่งซื้อที่มีปัญหา-คลังสินค้าที่ไม่ได้ปันส่วน]  ช่องทางสินค้า [%s] ไม่สามารถสร้างรายการขาออกได้ เหตุผล: ไม่มีกฎคลังสินค้าการจัดส่งที่เกี่ยวข้อง",
    "theChannelProductAllocate": "สินค้าช่องทางการขาย [%s] ตามลำดับ [%s] ตามกฎคลังสินค้าจัดส่ง [%s] ถูกจัดสรรให้กับคลังสินค้า [%s]",
    "theChannelProductSInThe": "คำสั่งซื้อ [%s] ถูกทำเครื่องหมายเป็น [คำสั่งซื้อที่มีปัญหา-ไม่ได้เชื่อมกับ MSKU]，ช่องทางสินค้า [%s] ไม่สามารถสร้างรายการขาออก เหตุผล: ไม่เกี่ยวข้องกับสินค้าหลัก",
    "anOutboundListHasBeenCreated": "รายการขาออกถูกสร้างขึ้นสำหรับคำสั่งซื้อ [%s] หมายเลขคำสั่งซื้อ: <a href=\"/erp/main/outbound-management-detail/%s/SELL\" target=\"_blank\">%s</a>",
    "theOrderSHasBeenSuccessfully": "เชื่อมต่อคำสั่งซื้อ [%s] สำเร็จแล้ว และสถานะคำสั่งซื้อได้รับการอัปเดตจาก [%s] เป็น [%s]",
    "syncToTheNewOrderS": "เชื่อมต่อกับคำสั่งซื้อใหม่ [%s] สถานะคำสั่งซื้อคือ [%s]",
    "salesTaxAmountMustBeBetween": "ยอดเงินภาษีการค้าขาย ต้องมีค่า 0-1000000000",
    "incorrectUnitFormat": "รูปแบบหน่วยไม่ถูกต้อง เนื้อหาที่รองรับคือ \"ชั่วโมง\" \"วันธรรมดา\" \"วัน\" \"สัปดาห์\" \"เดือน\"",
    "purchaseDurationMustBeBetween": "ระยะเวลาการซื้อต้องอยู่ระหว่าง 1-365",
    "selectedWarehouse": "คลังสินค้าที่เลือก",
    "addingToRepositoryXFailedReason": "การเพิ่มพื้นที่เก็บข้อมูล \"{x}\" ล้มเหลว! เหตุผลของความล้มเหลว:",
    "purchaseDurationUnit": "หน่วยระยะเวลาการซื้อ(เฉพาะเนื้อหาที่รองรับคือ \"hour\", \"working day\", \"day\", \"week\", \"month\")",
    "thisCustomerHasOrdersInThe": "ลูกค้ารายนี้มีคำสั่งซื้อในช่วง 60 วันที่ผ่านมา คุณสามารถเริ่มเซสชันได้",
    "theProductInboundStockCannotBe": "สต็อกสินค้าขาเข้าไม่สามารถเป็นค่าลบได้",
    "theBundledProductHasNotBeen": "สินค้าที่เป็นชุดยังไม่ได้ส่งไปยังคลังสินค้า!",
    "pleaseAddMskuToTheWarehouse": "โปรดเพิ่ม MSKU ลงในคลังสินค้าและกรอกขาเข้าให้สมบูรณ์",
    "createSalesInvoice": "สร้างใบแจ้งหนี้การขาย",
    "beforePushingMatchingCombinedProducts": "ก่อนที่จะผลักดันและจับคู่สินค้าแบบผสม จำเป็นต้องตรวจสอบให้แน่ใจว่าสินค้าแบบผสมทั้งหมดได้รับการผลักดันและจับคู่แล้ว",
    "theSystemWillAutomaticallyPushSales": "ระบบจะส่งใบแจ้งหนี้การขายไปที่ Accurate โดยอัตโนมัติในเวลา 4:00 น. 12:00 น. และ 20:00 น. หากความพยายามทั้งสามล้มเหลว จะไม่มีการผลักดันอีก",
    "ifGineeAndAccurateHaveThe": "ปัจจุบันรองรับเฉพาะสต็อก Ginee เป็นสต็อกเริ่มต้น",
    "thePushedMappedMskuSupports": "MSKU ที่ผลักดัน & จับคู่นั้นรองรับการเปิดใช้งานการซิงค์สต็อก และรองรับเฉพาะสต็อคของ Ginee ที่ผลักเป็น Accurate เป็นการชั่วคราวเมื่อการเปลี่ยนแปลงสต็อคของ Ginee",
    "afterSettingTheTaxTypeWill": "หลังจากการตั้งค่า ประเภทภาษีจะถูกรวมไว้เมื่อสร้าง MSKU จาก Ginee เป็น Accurate",
    "thisMappingCorrespondsToTheService": "การจับคู่นี้สอดคล้องกับค่าบริการในคำสั่งซื้อ",
    "thisMappingCorrespondsToTheShipBySys": "การจับคู่นี้สอดคล้องกับค่าธรรมเนียมการจัดส่งที่ชำระโดยระบบในคำสั่งซื้อ",
    "thisMappingCorrespondsToTheRebate": "การจับคู่นี้สอดคล้องกับเงินคืนในคำสั่งซื้อ",
    "thisMappingCorrespondsToTheCommission": "การจับคู่นี้สอดคล้องกับค่าคอมมิชชันในคำสั่งซื้อ",
    "thisMappingCorrespondsToTheShipReb": "การจับคู่นี้สอดคล้องกับเงินคืนค่าธรรมเนียมการจัดส่งในคำสั่งซื้อ",
    "thisMappingCorrespondsToTheShipping": "การจับคู่นี้สอดคล้องกับค่าธรรมเนียมการจัดส่งในคำสั่งซื้อ",
    "thePromotionHasExpiredOrThe": "โปรโมชั่นหมดอายุหรือประเภทโปรโมชั่นไม่ถูกต้อง",
    "invoiceBuyerPhoneNo": "หมายเลขโทรศัพท์ผู้ซื้อใบแจ้งหนี้",
    "invoiceType": "ประเภทใบแจ้งหนี้",
    "invoiceAddress": "ที่อยู่ใบแจ้งหนี้",
    "invoiceBuyerEmail": "อีเมลผู้ซื้อใบแจ้งหนี้",
    "invoiceBuyerName": "ชื่อผู้ซื้อใบแจ้งหนี้",
    "upcoming": "กำลังจะมาถึง",
    "finish": "เสร็จ",
    "promoTime": "เวลาโปรโมชั่น",
    "promotionid": "รหัสโปรโมชั่น",
    "webstore": "เว็บสโตร์",
    "orderShasBeenMovedToReturned": "คำสั่งซื้อ[%s] ถูกย้ายไปที่การส่งคืนแล้ว",
    "orderShasBeenMovedToDelivered": "คำสั่ง[%s] ถูกย้ายไปที่จัดส่งแล้ว",
    "orderShasBeenMovedToShipping": "คำสั่งซื้อ [%s] ถูกย้ายไปที่การจัดส่ง",
    "orderShasBeenMovedToTo": "คำสั่ง[%s] ถูกย้ายไปยังการรวบรวมแล้ว",
    "orderSisMarkedAsNotPacked": "คำสั่งซื้อ [%s] ถูกทำเครื่องหมายว่า \"ยังไม่ได้บรรจุ\"",
    "orderSisMarkedAsPacked": "คำสั่งซื้อ [%s] ถูกทำเครื่องหมายว่า \"บรรจุแล้ว\"",
    "ordersmarkedPickingStatus": "คำสั่งซื้อ[%s] ทำเครื่องหมายไว้ที่สถานะการหยิบสินค้าแล้ว",
    "orderSisMarkedAsNotA": "คำสั่งซื้อ[%s] ถูกทำเครื่องหมายว่า \"ไม่ใช่คำสั่งซื้อปลอม\"",
    "orderSisMarkedAsFakeOrder": "คำสั่งซื้อ[%s] ถูกระบุว่าเป็น \"คำสั่งซื้อปลอม\"",
    "orderSHasBeenOperatedDelivery": "คำสั่งซื้อ[%s] ได้รับการดำเนินการแล้ว [คลังสินค้าที่ใช้จัดส่ง]",
    "orderSmarkedAsPickingListNot": "คำสั่งซื้อ[%s] ถูกทำเครื่องหมายว่าไม่ได้พิมพ์รายการหยิบสินค้า",
    "orderSmarkedAsPickingListPrinted": "คำสั่งซื้อ[%s] ถูกทำเครื่องหมายว่าพิมพ์รายการหยิบสินค้าแล้ว",
    "orderSmarkedAsPackingListNot": "คำสั่งซื้อ[%s] ถูกทำเครื่องหมายยังไม่ได้พิมพ์บรรจุหีบห่อ",
    "orderSmarkedAsPackingListPrinted": "คำสั่งซื้อ[%s] ถูกทำเครื่องหมายเป็นบรรจุหีบห่อพิมพ์แล้ว",
    "orderSmarkedAsInvoiceNotPrinted": "คำสั่งซื้อ[%s] ถูกทำเครื่องหมายว่ายังไม่ได้พิมพ์ใบแจ้งหนี้",
    "orderSmarkedAsInvoicePrinted": "คำสั่งซื้อ[%s] ถูกทำเครื่องหมายใบแจ้งหนี้ที่พิมพ์แล้ว",
    "orderSmarkedAsLabelNotPrinted": "คำสั่งซื้อ[%s] ถูกทำเครื่องหมายว่าไม่ได้พิมพ์ใบปะหน้า",
    "orderSmarkedAsLabelPrinted": "คำสั่งซื้อ[%s] ถูกทำเครื่องหมายว่าพิมพ์ใบปะหน้าแล้ว",
    "pleaseConfirmTheInstallationOfGinee": "โปรดยืนยันการติดตั้ง Ginee ในหน้า Accurate และรีเฟรชหน้าหลังการติดตั้ง",
    "allActivities": "กิจกรรมทั้งหมด",
    "eventStartTimeAscending": "เวลาเริ่มต้นกิจกรรมจากน้อยไปหามาก",
    "eventStartTimeDescending": "เวลาเริ่มต้นกิจกรรมจากมากไปน้อย",
    "eventEndTimeAscending": "เวลาสิ้นสุดของเหตุการณ์จากน้อยไปหามาก",
    "eventEndTimeDescending": "เวลาสิ้นสุดของกิจกรรมจากมากไปน้อย",
    "sortEventStartTime": "เรียงเวลาเริ่มต้นกิจกรรม",
    "thisOperationOnlyMarksWhetherThe": "การดำเนินการนี้จะทำเครื่องหมายว่าเป็นคำสั่งซื้อปลอมหรือไม่ หลังจากทำเครื่องหมายแล้วจะไม่ส่งผลกระทบต่อรายการขาออกและการจัดส่งไปคลังสินค้า",
    "IsTheHighestPriority": "1 คือลำดับความสำคัญสูงสุด 20 คือลำดับความสำคัญต่ำสุด",
    "optional": "ไม่จำเป็น",
    "mandatory": "บังคับ",
    "shippingInsurance": "ประกันการจัดส่ง",
    "xModifiedWarehouseStockOutboundOrder": "{x} แก้ไขสต็อคคลังสินค้า คำสั่งซื้อขาออกเสร็จสมบูรณ์ หมายเหตุ:",
    "xModifiedWarehouseStockCreatedOutbound": "{x} แก้ไขสต็อคคลังสินค้า สร้างคำสั่งซื้อขาออก หมายเหตุ:",
    "xManuallyReplacesTheReservedWarehouse": "{x} แทนที่คลังสินค้าที่จองด้วยตนเอง จากคลังสินค้า {y} ไปยังคลังสินค้า {z}",
    "pleaseModifyTheReservedPromotionStock": "โปรดแก้ไขจำนวนสต็อกโปรโมชันที่จองไว้อย่างระมัดระวัง! \n- การลดสต็อกที่จองไว้จะเพิ่มสินค้าคงคลังที่มีอยู่ และสต็อกส่งเสริมการขายอาจถูกหักออกจากสต็อกช่องทางอื่นๆ ในระหว่างการขายหลายช่องทางซึ่งนำไปสู่การขายเกิน\n- การปรับเปลี่ยนสต็อกที่จองไว้ใน Ginee จะเปลี่ยนปริมาณสต็แกโปรโมชั่นใน Ginee เท่านั้น มันจะไม่ส่งผลกระทบต่อสต็อกที่จองไว้ในศูนย์ขาย",
    "commissionFeeMapping": "การทำแผนที่ค่าคอมมิชชั่น",
    "priorityDelivery": "การส่งมอบตามลำดับความสำคัญ",
    "flashSale": "แฟลชเซลล์",
    "productPromotion": "โปรโมชั่นสินค้า",
    "eventStartTime": "วันที่เริ่มต้นโปรโมชั่น",
    "eventEndTime": "โปรโมชั่นวันที่สิ้นสุด",
    "notPrintable": "ไม่สามารถพิมพ์ได้",
    "theFaceSheetCanBePrinted": "สามารถพิมพ์ใบปะหน้าจัดส่งได้",
    "thTrackingNumberNotBeen": "ยังไม่ได้สร้างหมายเลขติดตาม",
    "theOrderStatusCannotPrintThe": "สถานะการสั่งซื้อไม่สามารถพิมพ์ใบปะหน้าจัดส่งได้",
    "platformFulfillmentOrdersDoNotSupport": "คำสั่งการปฏิบัติตามแพลตฟอร์มไม่รองรับการพิมพ์ใบปะหน้าจัดส่ง",
    "thisChannelDoesNotSupportPrinting": "ช่องทางนี้ไม่รองรับการพิมพ์ใบปะหน้าจัดส่ง",
    "theChannelHasNotReturnedThe": "ช่องยังไม่ได้ส่ง AWB กลับมา โปรดรอสักครู่ หาก AWB ยังไม่ซิงค์หลังจากผ่านไปนาน โปรดไปที่ศูนย์ขายเพื่อยืนยันว่ามีการสร้างหรือไม่จากนั้นซิงค์คำสั่งซื้อและลองอีกครั้ง",
    "theChannelShippingLabelHasNot": "ช่องทางยังไม่ได้สร้างป้ายกำกับการจัดส่ง โปรดลองอีกครั้งในภายหลังโดยซิงโครไนซ์คำสั่งซื้อ",
    "syncAll": "เชื่อมต่อทั้งหมด",
    "noShippingLabelToBePrint": "ไม่มีใบปะหน้าพัสดุที่ต้องพิมพ์",
    "promotionEnd": "หมดอายุ",
    "handleProblem": "จัดการกับปัญหา",
    "stockMonitoringAndSynchronizationSettingsAre": "ไม่ได้เปิดการตั้งค่า ติดตามสต็อก และ เชื่อมต่อ",
    "thereAreUpToFixed": "โปรโมทสินค้าคงที่สูงสุด 5 รายการ",
    "afterTurningOffTheAutomaticallyReserve": "หลังจากปิดสต็อกโปรโมชันจองโดยอัตโนมัติแล้ว สต็อกโปรโมชันของ Shopee จะไม่ซิงโครไนซ์และจะไม่ถูกจอง",
    "turnOffTheSwitchToAutomatically": "ปิดสวิตช์เพื่อจองสต็อคโปรโมชันโดยอัตโนมัติ",
    "setTheReservationPolicyToUpcoming": "กำหนดนโยบายการจองเป็น \"โปรโมชั่นที่กำลังจะมาถึงและกำลังดำเนินการจะถูกจองโดยอัตโนมัติ\"",
    "setTheReservationPolicyToAutoreserve": "กำหนดนโยบายการจองเป็น \"Automatically reserved for Processing promotion only\"",
    "turnOnTheSwitchToAutomatically": "เปิดสวิตช์เพื่อจองสต็อคโปรโมชันโดยอัตโนมัติ",
    "processingPromotionOnlySupportModifyThe": "โปรโมชันการประมวลผลรองรับเฉพาะการแก้ไขคลังสินค้า ไม่รองรับการแก้ไขสต็อกที่จองไว้",
    "displayingWarehouseWhereTheMskuOf": "แสดงคลังสินค้าที่มีการจัดสรร MSKU ของสินค้าส่งเสริมการขาย และคลังสินค้าสามารถเปลี่ยนแปลงได้",
    "displayingTheBoundMskuOfThe": "การแสดง MSKU ที่ถูกผูกไว้ของสินค้าส่งเสริมการขายและปริมาณสต็อกส่งเสริมการขายที่จองไว้ใน Ginee",
    "displayTheChannelSkuOfThe": "แสดงช่อง SKU ของสินค้าส่งเสริมการขายและปริมาณสต็อกของโปรโมชั่นที่ลงทะเบียนสำเร็จในการส่งเสริมการขายแพลตฟอร์ม",
    "productParticipatedInPromotionButIs": "สินค้าร่วมโปรโมชั่นแต่ไม่ได้จองสต๊อคโปรโมชั่น ข้อมูลโปรโมชันที่หมดอายุจะคงอยู่เพียง 30 วัน หลังจาก 30 วันแล้ว จะไม่สามารถดูข้อมูลในรายการได้",
    "productParticipatedInPromotionAndOnly": "สินค้าที่เข้าร่วมโปรโมชั่น และมีเพียงบางรุ่นเท่านั้นที่จองสินค้าโปรโมชั่น",
    "productsIncludingAllVariationsAreParticipating": "สินค้า (รวมถึงรูปแบบต่างๆ ทั้งหมด) ที่เข้าร่วมโปรโมชั่น สต็อกของโปรโมชั่นได้ถูกจองแล้ว",
    "thisPageCanOnlyViewAnd": "หน้านี้สามารถดูและจัดการโปรโมชันที่สร้างขึ้นหลังวันที่ 9 มิถุนายน 2023 เท่านั้น",
    "channelReturnFailedReasonX": "ช่องทางการส่งคืนล้มเหลว เหตุผล: {x}",
    "storeWillExp": "{m} ร้านค้าจะหมดอายุในไม่ช้า",
    "storeHasExp": "{m} ร้านค้าหมดอายุแล้ว",
    "big": "ใหญ่",
    "small": "เล็ก",
    "standa": "มาตรฐาน",
    "onlySupportsAdjustingSkuAndQuantity": "รองรับเฉพาะการปรับ SKU และขนาดตัวอักษร",
    "fontSiz": "ขนาดตัวอักษร:",
    "paperSize": "ขนาดกระดาษ",
    "disableHighspeedPrintingForSshop": "ปิดใช้งานการพิมพ์ความเร็วสูงสำหรับร้าน [%s]",
    "enableHighspeedPrintingForSstore": "เปิดใช้งานการพิมพ์ความเร็วสูงสำหรับร้าน [%s]",
    "disableTheHighSpeedPrintingButton": "ปิดการใช้งานปุ่ม [การพิมพ์ความเร็วสูง]",
    "enableTheHighSpeedPrintingSwitch": "เปิดใช้งานปุ่ม [การพิมพ์ความเร็วสูง]",
    "afterDisablingAllStoresWillNot": "หลังจากปิดใช้งาน ร้านค้าทั้งหมดจะไม่สามารถใช้การพิมพ์ความเร็วสูงได้ และเวลาในการรับฉลากการจัดส่งของช่องทางเมื่อการพิมพ์จำนวนมากจะเพิ่มขึ้น",
    "areYouSureToTurnOff": "คุณแน่ใจหรือว่าปิดการพิมพ์ความเร็วสูง",
    "shopeeWillMarkThePredownloadedOrder": "Shopee/Lazada/Tiktok จะทำเครื่องหมายคำสั่งที่ดาวน์โหลดล่วงหน้าเป็นพิมพ์ โปรดเลือกว่าจะเปิดสวิตช์ตามความต้องการของคุณหรือไม่",
    "afterEnablingGineeWillDownloadThe": "หลังจากเปิดใช้งาน Ginee จะดาวน์โหลดใบปะหน้าการจัดส่งของช่องทางล่วงหน้า ซึ่งจะช่วยให้พิมพ์ด้วยความเร็วสูงได้ และสามารถลดเวลาการรอลงได้อย่างมากเมื่อพิมพ์จำนวนมาก",
    "syncToObtainLatestLogisticsInformation": "ซิงค์เพื่อรับข้อมูลโลจิสติกส์ล่าสุด",
    "massSyncToObtainTheLatest": "ซิงค์จำนวนมากเพื่อรับข้อมูลการขนส่งของคำสั่งซื้อ Tokopedia ล่าสุด",
    "syncLogistics": "การเชื่อมต่อขนส่ง Tokopedia",
    "package": "แพ็กเกจ",
    "storeAuthorizedtotalAmount": "ร้านค้า (ที่ได้รับอนุญาต/จำนวนร้านค้า)",
    "subaccountUsedtotalAmount": "บัญชีย่อย (ใช้แล้ว/จำนวนบัญชี)",
    "remainingOrderQuantity": "ปริมาณคำสั่งซื้อคงเหลือ",
    "remainingMDays": "เหลืออีก {m} วัน",
    "packageRemainingMDays": "แพ็กเกจเหลือ {m} วัน",
    "toEnsureNormalUsePleaseRenew": "เมื่อแพ็กเกจหมดอายุ  การตั้งค่าเชื่อมต่อสต็อกจะถูกปิดโดยอัตโนมัติ เพื่อให้แน่ใจว่าใช้งานได้ตามปกติ โปรดต่ออายุให้ตรงเวลา",
    "packageRemainingValidityMDays": "อายุคงเหลือของแพ็กเกจ {m} วัน",
    "noReminderToday": "วันนี้ไม่มีการแจ้งเตือน",
    "theOperationIsTooFrequentPlease": "การดำเนินการนี้บ่อยเกินไป โปรดลองอีกครั้งใน 5 นาที",
    "yourCurrentPackageDoesNotSupport": "แพ็คเกจปัจจุบันของคุณไม่รองรับการอนุญาตช่องทางนี้ โปรดอัปเกรดแพ็คเกจ OMS ก่อนอนุญาต",
    "thisMethodIsOnlyOpenTo": "วิธีนี้เปิดให้เฉพาะผู้ค้าที่กำหนดเท่านั้น กรุณาตั้งค่าอย่างระมัดระวัง สำหรับข้อมูลเพิ่มเติม โปรดดูที่ {m}",
    "shopeeOfficialInstructions": "คำแนะนำอย่างเป็นทางการของ Shopee",
    "actualShippingFee": "ค่าจัดส่งตามจริง",
    "ifAutomaticallyReserveShopeePromotionStock": "หากเปิดใช้งาน [สำรองสต็อกโปรโมชั่น Shopee โดยอัตโนมัติ] คำสั่งซื้อสำหรับส่งเสริมการขาย Shopee จะไม่ได้รับการจัดสรรตามกฎคลังสินค้าจัดส่ง แต่จะถูกล็อคตามคลังสินค้าที่สงวนไว้สำหรับการส่งเสริมการขาย",
    "afterEnablingGineeWillAutomaticallyPull": "หลังจากเปิดใช้งาน Ginee จะดึงรหัสการจองคำสั่งซื้อ Tokopedia โดยอัตโนมัติ",
    "turnOffTheAutomaticallyPullLogistics": "ปิด [ดึงข้อมูลโลจิสติกส์โดยอัตโนมัติ]",
    "turnOnTheAutomaticallyPullLogistics": "เปิด [ดึงข้อมูลโลจิสติกส์โดยอัตโนมัติ]",
    "automaticallyPullLogisticsInformation": "ดึงข้อมูลโลจิสติกส์โดยอัตโนมัติ",
    "disabled1": "พิการ",
    "enabled": "เปิดใช้งาน",
    "newlyAuthorizedStoresAreAutomaticallySet": "ร้านค้าที่ได้รับอนุญาตใหม่จะถูกตั้งค่าเป็น \"เปิดใช้งาน\" โดยอัตโนมัติ",
    "confirmToReauthorize": "ยืนยันให้อนุญาตอีกครั้ง",
    "noteTiktokReauthorizationDoesNotExtend": "หมายเหตุ: การอนุญาตซ้ำของ TikTok ไม่ได้ขยายระยะเวลาความถูกต้อง หากต้องการขยายระยะเวลาการอนุญาต โปรดไปที่ศูนย์ผู้ขาย TikTok เพื่อยกเลิกการรวม Ginee ก่อน จากนั้นจึงอนุญาตอีกครั้ง",
    "sellerFullAddress": "ที่อยู่เต็มของผู้ขาย",
    "thereIsNoOrderThatCan": "ไม่สามารถพิมพ์คำสั่งซื้อโดยใช้การพิมพ์ที่รวดเร็วโปรดยืนยันว่าร้านค้าที่มีคำสั่งซื้อได้เปิดใช้งาน [การพิมพ์ที่รวดเร็ว]",
    "startSynchronizingLatestOrderLogisticsInformation": "เริ่มซิงโครไนซ์ข้อมูลโลจิสติกส์คำสั่งซื้อล่าสุดคาดว่าจะใช้เวลาไม่กี่นาทีโปรดรีเฟรชหน้าในภายหลังเพื่อดูข้อมูลโลจิสติกส์ล่าสุด",
    "video": "Video",
    "tryHighlightingCoreSellingPoints": "ลองเน้นจุดขายหลัก 1-2 จุดสำหรับสินค้าของคุณ นี้จะแสดงในส่วนรายละเอียดสินค้า",
    "weRecommendTheVideoDurationIs": "เราแนะนำว่าความยาวของวิดีโอควรไม่น้อยกว่า 20 วินาที และยาวที่สุดไม่ควรเกิน 60 วินาที",
    "recommendedRatioIsAndRecommended": "อัตราส่วนที่แนะนำคือ 1:1 และความละเอียดที่แนะนำคือสูงกว่า HD 720p",
    "ifYouUploadAVideoIt": "อัตราส่วนกว้างยาวของวิดีโอที่อัปโหลดควรเป็น 9:16 หรือ 16:9 ขนาดไฟล์ต้องไม่เกิน 30MB และรูปแบบเป็น MP4",
    "tiktokRequired": "ข้อกำหนดของ TikTok:",
    "changingCategoryWillNotAffectYour": "การเปลี่ยนหมวดหมู่จะไม่ส่งผลต่อข้อมูลสินค้าของคุณ เช่น ประสิทธิภาพการขายและการให้คะแนนสินค้า แต่จะล้างลักษณะของสินค้า คุณแน่ใจหรือไม่ว่าต้องการเปลี่ยน",
    "basedOnTheTitleYouEntered": "ตามชื่อสินค้าที่คุณป้อน หมวดหมู่สินค้าอาจเป็นได้ (โปรดเลือกตามต้องการ)",
    "economical": "แบบประหยัด",
    "cargo": "สินค้า",
    "standardShipping": "การจัดส่งมาตรฐาน",
    "pleaseInputTheValueOfWeight": "โปรดใส่ค่าน้ำหนักที่มากกว่า 5.00 กก",
    "deliveryOptions": "ตัวเลือกการจัดส่ง",
    "lineShoppingOnlySupportsSettingThe": "Line Shopping รองรับเฉพาะการตั้งค่าจำนวนส่วนลดทันทีสำหรับรูปแบบทั้งหมด",
    "compulsoryOutbound": "รายการขาออกถูกผลักไปยังคลังสินค้า fulfillment โปรดดำเนินการขาออกในคลังสินค้า fulfillment หากคุณยังคงดำเนินการเฉพาะคำสั่งขาออกใน OMS นั้นจะถูกบังคับให้ออก ซึ่งอาจทำให้เกิดความไม่สอดคล้องกับข้อมูลขาออกจริงของคลังสินค้า fulfillment  คุณแน่ใจหรือว่าต้องการดำเนินการต่อ?",
    "compulsoryCancel": "รายการขาออกถูกผลักไปยังคลังสินค้า fulfillment โปรดดำเนินการขาออกในคลังสินค้า fulfillment หากคุณยังคงดำเนินการต่อไปคุณจะถูกบังคับให้ยกเลิกรายการขาออกใน OMS ซึ่งอาจทำให้เกิดความไม่สอดคล้องกับข้อมูลขาออกจริงของคลังสินค้าปฏิบัติตาม คุณแน่ใจหรือว่าต้องการดำเนินการต่อ?",
    "noPermission": "คุณไม่ได้รับอนุญาตให้เข้าถึงหน้านี้โปรดรีเฟรชหน้าและลองอีกครั้งหลังจากอัพเกรดแพ็คเกจของคุณ สำหรับบัญชีพนักงานโปรดติดต่อบัญชีหลักเพื่อเพิ่มสิทธิ์บทบาท",
    "refuseToAddWms": "คุณไม่ได้รับอนุญาตให้เพิ่มคลังสินค้านี้โปรดติดต่อผู้ให้บริการคลังสินค้าของคุณเพื่อเพิ่มบัญชีผู้ตราส่งให้คุณและอนุญาตคลังสินค้า",
    "theProductDescriptionShouldGiveThe": "คำอธิบายผลิตภัณฑ์ควรให้ข้อมูลที่เป็นประโยชน์แก่ลูกค้าเกี่ยวกับผลิตภัณฑ์เพื่อให้แน่ใจว่ามีการซื้อ",
    "missingOrderInformation": "ไม่มีข้อมูลคำสั่งซื้อ",
    "compartmentDimensionInShippingWarehouseSetting": "ขนาดช่องในการตั้งค่า Shipping Warehouse เปลี่ยนจาก {1} เป็น {2}",
    "priorityOfWarehouseAlocationInShipping": "ลำดับความสำคัญของการจัดสรรคลังสินค้าในการตั้งค่า Shipping Warehouse เปลี่ยนจาก {1} เป็น {2}",
    "warehouseInRuleChangedFrom": "คลังสินค้าในกฎ {0} เปลี่ยนจาก {1} เป็น {2}",
    "startPullingTheOrderDataIt": "การเริ่มต้นดึงข้อมูลคำสั่งซื้อ  คาดการ์ณว่าต้องใช้ข้อมูลหลายนาที  โปรดเชื่อมต่อข้อมูลร้านค้าใหม่อีกครั้ง",
    "theRecipientsInformationCanBeDisplayed": "แม้ว่าต้นฉบับใบปะหน้าพัสดุจะแสดงข้อมูลผู้รับ  แต่ข้อมูลผู้รับอาจถูกปกปิด",
    "theCompleteRecipientInformationCanBe": "แม้ว่ารูปภาพใบปะหน้าพัสดุมีแสดงข้อมูลผู้รับทั้งหมด  แต่ว่าเครื่องพิมพ์บางรุ่นไม่สามารถจำแนกรูปภาพได้",
    "customShipmentWarning": "กำหนดเองแจ้งเตือนการจัดส่ง",
    "thisSystemWarehouseHasBeenBound": "System Warehouse นี้ถูกรวมไว้กับ Channel Warehouse อื่น!",
    "completeRecipientInformationCanBe": "แม้ว่ารูปภาพใบปะหน้าพัสดุมีแสดงข้อมูลผู้รับทั้งหมด",
    "recipientsInformationCanBeDisplayed": "แม้ว่ารูปภาพใบปะหน้าพัสดุมีแสดงข้อมูลผู้รับทั้งหมด",
    "instantDiscountAmount": "จำนวนส่วนลดทันที",
    "originalPriceShouldBeHigherThan": "ราคาเดิมควรสูงกว่าจำนวนเงินส่วนลดทันที",
    "YourOmsWillBeUpgraded": "1. OMS ของคุณจะได้รับการอัพเกรดเป็นเวอร์ชันที่มีฟีเจอร์ครบถ้วน ซึ่งสามารถอนุญาตร้านค้าหลายช่องทาง ประมวลผลคำสั่งซื้อ และจัดการสินค้าคงคลัง",
    "YouWillAutomaticallyBindThe": "2. คุณจะผูกมัดคลังสินค้าของผู้ให้บริการโดยอัตโนมัติ และคุณสามารถส่งคำสั่งซื้อไปยังคลังสินค้าของผู้ให้บริการได้ และผู้ให้บริการจะปฏิบัติตามคำสั่งซื้อ",
    "theFulfillmentServiceProviderXInvites": "ผู้ให้บริการ fulfillment สินค้า {x} ขอเชิญคุณให้เปิดคลังสินค้าเติมเต็มสินค้า {y}",
    "afterAgreeing": "หลังจากตกลง:",
    "areYouSureYouAgreeTo": "คุณแน่ใจหรือว่าคุณตกลงที่จะเปิดใช้งาน?",
    "confirmActivation": "ยืนยันการเปิดใช้งาน",
    "youHaveNewApplication": "คุณมีใบสมัครใหม่ {m} รายการ",
    "thisChannelCanBeAuthorizedAfter": "สามารถอนุญาตช่องทางนี้ได้หลังจากอัปเกรดแพ็กเกจแล้ว",
    "theCurrentPackageDoesNotSupport": "แพ็คเกจปัจจุบันไม่รองรับการอนุญาตช่องทางนี้",
    "enableFailedYouCanOnlyAuthorize": "เปิดใช้งานล้มเหลว! ร้านค้าเกินขีดจำกัดทรัพยากรแพ็คเกจ",
    "afterUpgradingThePackageMoreMaster": "หลังจากอัปเกรดแพ็คเกจแล้ว จะสามารถเพิ่มสินค้าหลักเพิ่มเติมได้",
    "addFailedMasterProducts": "เพิ่มล้มเหลว! ปริมาณสินค้าหลักถึงขีดจำกัดของบรรจุภัณฑ์แล้ว",
    "masterProductQuantityHasReachedThe": "ปริมาณสินค้าหลักถึงขีดจำกัดของบรรจุภัณฑ์แล้ว",
    "additionalXConsignorAccountsbusiness": "เพิ่มเติม x 5 บัญชีผู้ตราส่ง",
    "additionalXGineeWmsOrders": "เพิ่ม x 10,000 คำสั่งซื้อ Ginee WMS",
    "additionalXStores": "เพิ่มเติม x 10ร้านค้า",
    "additionalXStaffAccounts": "เพิ่มเติม x 5 บัญชีพนักงาน",
    "additionalXGineeOmsOrders": "เพิ่ม x 10,000 คำสั่งซื้อ Ginee OMS",
    "addons": "เพิ่มเติม",
    "offlineOnboardingOn": "การเริ่มต้นใช้งานออฟไลน์ (1 ต่อ 1)",
    "accountManagerOn": "ผู้จัดการบัญชี (1 ต่อ 1)",
    "onlineTraining": "การฝึกอบรมออนไลน์",
    "liveChatSupport": "รองรับ Live Chat",
    "implementationAndCustomerCare": "การนำไปใช้และการดูแลลูกค้า",
    "XConsignorAccountsBusiness": "10 x บัญชีผู้ตราส่ง (ธุรกิจ)",
    "servicesToExternalBrands": "บริการแก่แบรนด์ภายนอก",
    "serviceProviderManagement": "การจัดการผู้ให้บริการ",
    "gineeWmsOpenApi": "Ginee WMS เปิด API",
    "pdaapp": "PDA/APP",
    "fifofefo": "FIFO/FEFO",
    "smartReplenishment": "การเติมเต็มอัจฉริยะ",
    "stocklocationTransfer": "การโอนสต็อก/สถานที่",
    "waveShipping": "คลื่นจัดส่ง",
    "gineeWmsWarehouseManagementSystem": "Ginee WMS (ระบบบริหารจัดการคลังสินค้า)",
    "gineeOmsOpenApi": "Ginee OMS เปิด API",
    "theThirdPartyIntegrationposLogisticsFulfillment": "การเชื่อมต่อบุคคลที่สาม (POS, โลจิสติกส์, การปฏิบัติตาม,OMS, การบัญชี)",
    "multiwarehouseManagement": "การจัดการคลังสินค้าหลายแห่ง",
    "reporting": "การรายงาน",
    "stockManagement": "การจัดการสต็อก",
    "gineeOmsOrderManagementSystem": "Ginee OMS (ระบบจัดการคำสั่งซื้อ)",
    "noOfSalesChannels": "จำนวนช่องทางการขาย",
    "noOfMskus": "จำนวน MSKU",
    "noOfStores": "จำนวนร้านค้า",
    "noOfStaffAccounts": "จำนวนบัญชีพนักงาน",
    "noOfCountriesinternational": "จำนวนประเทศ (นานาชาติ)",
    "noOfGineeWmsOrders": "จำนวนคำสั่งซื้อ Ginee WMS",
    "noOfGineeOmsOrders": "จำนวนคำสั่งซื้อ Ginee OMS",
    "ecommerceOperations": "การดำเนินงานอีคอมเมิร์ซ",
    "buyItNow": "ซื้อเลย",
    "clickMeToGetDiscount": "รับส่วนลด",
    "customization": "ปรับแต่งเพิ่มเติม",
    "youHaveMNewMessages": "คุณมีข้อความใหม่ {m} ข้อความ",
    "officialAccounts": "บัญชีอย่างเป็นทางการ",
    "theThirdPartyIntegration": "การเชื่อมต่อบุคคลที่สาม",
    "thereAreUnrecognizableCharactersSuchAs": "มีอักขระที่ไม่รู้จัก โปรดเปลี่ยน",
    "currentlyDoNotSupportIntegratingCnsc": "ปัจจุบันไม่รองรับการเชื่อมต่อร้านค้า CNSC หรือ 3PF",
    "manuallyRepushThePushFailedData": "พุชข้อมูล \"การพุชล้มเหลว\" อีกครั้งด้วยตนเอง",
    "paymentTerms": "เงื่อนไขการชำระเงิน",
    "bankAccount": "บัญชีธนาคาร",
    "issuingBank": "ธนาคารผู้ออกบัตร",
    "taxIdNumber": "Tax ID Number",
    "contactPerson": "บุคคลที่ติดต่อ",
    "website": "Website",
    "onlyLettersAndNumbersAreSupported": "เฉพาะตัวอักษร ตัวเลข และ '- _ . /' รองรับแล้ว กรุณาป้อนใหม่อีกครั้ง",
    "theContentYouEnteredContainsIllegal": "เนื้อหาที่คุณป้อนมีอักขระที่ไม่ถูกต้อง โปรดป้อนใหม่",
    "autoGenerate": "สร้างอัตโนมัติ",
    "supplierName": "ชื่อซัพพลายเออร์",
    "supplierCode": "รหัสซัพพลายเออร์",
    "createNewSupplier": "สร้างซัพพลายเออร์ใหม่",
    "importSupplier": "นำเข้าซัพพลายเออร์",
    "supplierList": "รายชื่อซัพพลายเออร์",
    "purchaseManagement": "การจัดการการจัดซื้อ",
    "supplierManagement": "การจัดการซัพพลายเออร์",
    "purchase": "ซื้อ",
    "disableenable": "ปิดการใช้งาน/เปิดใช้งาน",
    "supplierCodeDoesNotExist": "ไม่มีรหัสซัพพลายเออร์",
    "supplierNameAlreadyExists": "ชื่อซัพพลายเออร์มีอยู่แล้ว",
    "supplierCodeAlreadyExists": "รหัสซัพพลายเออร์มีอยู่แล้ว",
    "failedToDeleteTheSupplierAlready": "ลบไม่สำเร็จ! ซัพพลายเออร์มีใบสั่งซื้ออยู่แล้วหรือไม่ได้อยู่ในสถานะปิดใช้งาน",
    "onlySuppliersWithDisabledStatusAnd": "เฉพาะซัพพลายเออร์ที่มีสถานะปิดและไม่มีการสั่งซื้อเท่านั้นที่สามารถลบได้",
    "wantToDeleteTheSupplier": "คุณแน่ใจหรือไม่ว่าต้องการลบซัพพลายเออร์",
    "wantToEnableTheSupplier": "คุณแน่ใจหรือไม่ว่าต้องการเปิดใช้งานซัพพลายเออร์",
    "failedToDisableThisSupplierHas": "ล้มเหลวในการปิดการใช้งาน! ซัพพลายเออร์รายนี้มีใบสั่งซื้อที่ยังไม่เสร็จสิ้น",
    "suppliersWithUnfinishedPurchaseOrdersCannot": "ซัพพลายเออร์ที่มีใบสั่งซื้อที่ยังไม่เสร็จไม่สามารถปิดการใช้งานได้ โปรดดำเนินการด้วยความระมัดระวัง",
    "wantToDisableTheSupplier": "คุณแน่ใจหรือไม่ว่าต้องการปิดการใช้งานซัพพลายเออร์",
    "thisTypeHasBeenSelectedBy": "ซัพพลายเออร์เลือกประเภทนี้แล้วและไม่สามารถลบได้",
    "wantToDeleteTheSupplierType": "คุณแน่ใจหรือไม่ว่าต้องการลบประเภทซัพพลายเออร์",
    "typeName": "ชื่อประเภท",
    "addNewType": "เพิ่มประเภทใหม่",
    "fullPayment": "ชำระเงินเต็มจำนวน",
    "supplierCodeCannotBeEditedAfter": "รหัสซัพพลายเออร์ไม่สามารถแก้ไขได้หลังจากสร้างแล้ว",
    "xAlreadyExists": "{x} มีอยู่แล้ว",
    "pleaseFillInThisField": "กรุณากรอกข้อมูลในช่องนี้",
    "theAmountMustBeBetweenXy": "จำนวนเงินต้องอยู่ระหว่าง {x}~{y} อนุญาตให้มีทศนิยม 2 ตำแหน่ง",
    "amountMustBeBetweenXyAnd": "จำนวนเงินต้องอยู่ระหว่าง {x}~{y} และไม่อนุญาตให้ใช้ทศนิยม",
    "partialArrival": "การจัดส่งบางส่วน",
    "inTransit": "ในการขนส่ง",
    "paymentStatus": "สถานะการชำระเงิน",
    "purchaseOrder": "ใบสั่งซื้อ",
    "paymentMethod": "วิธีการชำระเงิน",
    "paid": "จ่ายแล้ว",
    "partialPayment": "ชำระเงินบางส่วน",
    "unpaid": "ค้างชำระ",
    "feeInformation": "ข้อมูลค่าธรรมเนียม",
    "deliveredQuantity": "ปริมาณที่จัดส่ง",
    "estimatedDateOfArrival": "วันที่มาถึงโดยประมาณ",
    "purchasingStaff": "พนักงานจัดซื้อ",
    "receivingWarehouse": "การรับคลังสินค้า",
    "puchaseDate": "วันที่ซื้อ",
    "purchaseOrderSource": "ที่มาของการสั่งซื้อ",
    "noSupplier": "ไม่มีซัพพลายเออร์",
    "purchaseOrderNumberAlreadyExists": "มีหมายเลขใบสั่งซื้ออยู่แล้ว",
    "purchaseOrderNumberCannotBeEdited": "ไม่สามารถแก้ไขหมายเลขใบสั่งซื้อได้หลังจากสร้างแล้ว",
    "pleaseEnterPurchaseOrderNumber": "กรุณากรอกหมายเลขใบสั่งซื้อ",
    "purchaseOrderNumber": "เลขที่ใบสั่งซื้อ",
    "basicInfoma": "ข้อมูลพื้นฐาน",
    "otherNoncash": "อื่นๆ ที่ไม่ใช่เงินสด",
    "virtualAccount": "บัญชีเสมือน",
    "paymentLink": "ลิงค์ชำระเงิน",
    "qris": "QRIS",
    "edc": "EDC",
    "bankTransfer": "โอนเงินผ่านธนาคาร",
    "chequeclearing": "เช็ค/เคลียร์ริ่ง",
    "cash": "เงินสด",
    "discou": "การลดราคา",
    "productPurchasePrice": "ราคาซื้อสินค้า",
    "purchaseOrderTotalPrice": "ราคารวมของคำสั่งซื้อ",
    "skuQuantity": "ปริมาณ SKU",
    "totalPriceInformation": "ข้อมูลราคารวม",
    "inboundCostPrice": "ต้นทุนขาเข้า",
    "individualCostSharing": "ต้นทุนที่จัดสรรส่วนบุคคล",
    "taxRate": "อัตราภาษี",
    "onlyNumbersFromTo": "อนุญาตให้ใช้เฉพาะตัวเลขตั้งแต่ {x}~{y} เท่านั้น ไม่รองรับทศนิยม",
    "discountRate": "อัตราส่วนลด",
    "purcPrice": "ราคาซื้อ",
    "quantityPcs": "ปริมาณ (ชิ้น)",
    "unit": "หน่วย",
    "volumeM": "ปริมาณ (cm³)",
    "productPriceIncludesTax": "ราคาสินค้ารวมภาษีแล้ว",
    "massAddd": "เพิ่มจำนวนเยอะ",
    "addProdu": "เพิ่มสินค้า",
    "dividedByQuantity": "จัดสรรตามปริมาณ",
    "dividedByVolume": "จัดสรรตามขนาด",
    "dividedByWeight": "จัดสรรตามน้ำหนัก",
    "dividedByPrice": "จัดสรรตามราคา",
    "notDivided": "ไม่ได้รับการจัดสรร",
    "fillInmodifyTrackingNumber": "กรอก/แก้ไขหมายเลขติดตาม",
    "purchaseNote": "หมายเหตุการซื้อ",
    "thePurchaseOrderWarehouseIsWmsthirdparty": "คลังสินค้าใบสั่งซื้อคือคลังสินค้า WMS/บุคคลที่สาม กรุณาแจ้งคลังสินค้าที่ดำเนินการเพื่อยกเลิกใบสั่งซื้อก่อนดำเนินการต่อ",
    "failedToCancelThePurchaseOrder": "ไม่สามารถยกเลิกใบสั่งซื้อได้",
    "cancellationFailedInboundProductsInThis": "การยกเลิกล้มเหลว! สินค้าขาเข้าในใบสั่งซื้อนี้เสร็จสมบูรณ์แล้ว โปรดรีเฟรชหน้าเพื่อตรวจสอบสถานะของใบสั่งซื้ออีกครั้ง",
    "whenAPurchaseOrderIsCancelled": "เมื่อใบสั่งซื้อถูกยกเลิก การซื้อขาเข้าจะไม่ถูกต้องและสินค้าคงคลังในการขนส่งจะถูกปล่อย",
    "wantToCancelThePurchaseOrder": "คุณแน่ใจหรือไม่ว่าต้องการยกเลิกใบสั่งซื้อ?",
    "wantToDeleteThePurchaseOrder": "คุณแน่ใจหรือไม่ว่าต้องการลบใบสั่งซื้อ",
    "IfTheReceivingWarehouseIs": "2. หากคลังสินค้าที่รับเป็นคลังสินค้าของบุคคลที่สาม Ginee จะผลักดันการซื้อขาเข้าไปยังคลังสินค้าที่ปฏิบัติตามโดยอัตโนมัติ",
    "AfterSubmissionThePurchaseOrder": "1. หลังจากส่งแล้ว ใบสั่งซื้อจะกลายเป็นสถานะอยู่ระหว่างการขนส่ง และจะมีการสร้างการซื้อขาเข้า และผลิตภัณฑ์จะถูกเพิ่มไปยังสต็อคการขนส่ง",
    "youWantToSubmitThePurchase": "คุณแน่ใจหรือไม่ว่าต้องการส่งใบสั่งซื้อ?",
    "identify1": "ระบุ",
    "copyMskuFromExcelAndPaste": "คัดลอก MSKU จาก Excel แล้ววางที่นี่ โปรดใช้ Enter เพื่อป้อน MSKU หลายรายการ แต่ละบรรทัดแสดงถึงหนึ่งข้อมูล",
    "EstimatedTimeOfArrivalFormat": "6. รูปแบบเวลาที่มาถึงโดยประมาณ: ปี/เดือน/วัน (เช่น: 2023/08/23)",
    "WhenTheReceivingWarehouseIs": "3. เมื่อคลังสินค้าที่รับเป็นคลังสินค้าของบุคคลที่สาม หาก MSKU ไม่ได้ถูกผลักไปยังคลังสินค้าของบุคคลที่สาม โปรดไปที่หน้ารายการสต็อคเพื่อเพิ่ม SKU ไปยังคลังสินค้าของบุคคลที่สาม",
    "ExplanationOfTheShippingFeeother": "4. คำอธิบายค่าธรรมเนียมการจัดส่ง/วิธีการจัดสรรต้นทุนอื่นๆ:\n- เมื่อเลือก \"ราคา\" การจัดสรรจะขึ้นอยู่กับราคาของแต่ละ SKU\n- เมื่อเลือก \"ปริมาณ\" การจัดสรรจะขึ้นอยู่กับปริมาณการซื้อของแต่ละ SKU\n- เมื่อเลือก \"ปริมาณ\" การจัดสรรจะขึ้นอยู่กับปริมาณของ SKU แต่ละรายการ\n- เมื่อเลือก \"น้ำหนัก\" การจัดสรรจะขึ้นอยู่กับน้ำหนักของแต่ละ SKU\nหากไม่ได้กรอกค่าธรรมเนียมการจัดส่ง/ค่าใช้จ่ายอื่นๆ ระบบจะไม่จัดสรรตามค่าเริ่มต้น",
    "purchaseOrderContainsMultipleProductsYou": "3. เมื่อใบสั่งซื้อมีสินค้าหลายรายการ คุณจะต้องกรอกข้อมูลผลิตภัณฑ์ให้ครบถ้วนสำหรับแต่ละข้อมูล สำหรับข้อมูลสาธารณะ (ข้อมูลพื้นฐาน ข้อมูลต้นทุน ข้อมูลอื่นๆ) จะต้องกรอกเฉพาะข้อมูลแรกเท่านั้น เมื่อข้อมูลสาธารณะของข้อมูลหลายรายการไม่สอดคล้องกัน ข้อมูลแรกจะมีผลเหนือกว่า",
    "WhenAPurchaseOrderContains": "2. เมื่อใบสั่งซื้อมีสินค้าหลายรายการ โปรดใช้หมายเลขใบสั่งซื้อเดียวกัน เมื่อนำเข้า ข้อมูลหลายแถวที่มีหมายเลขใบสั่งซื้อเดียวกันจะถูกรวมเป็นใบสั่งซื้อใบเดียว",
    "exportPurchaseOrder": "นำเข้าใบสั่งซื้อ",
    "printPurchaseOrder": "พิมพ์ใบสั่งซื้อ",
    "changePaymentStatus": "เปลี่ยนสถานะการชำระเงิน",
    "signature": "ลายเซ็น",
    "merchantWarehouseAddress": "ที่อยู่คลังสินค้าของผู้ขาย",
    "contactInformation": "ติดต่อ",
    "supplierAddress": "ที่อยู่ของซัพพลายเออร์",
    "supplier": "ซัพพลายเออร์",
    "supplierInformation": "ข้อมูลซัพพลายเออร์",
    "merchantInformation": "ข้อมูลร้านค้า",
    "explanation": "คำอธิบาย:",
    "UpToDataCan": "1. สามารถนำเข้าข้อมูลได้สูงสุด 10,000 ข้อมูลในคราวเดียว (รูปแบบ: xlsx, xls)",
    "estimatedTimeOfArrival": "เวลามาถึงโดยประมาณ",
    "warehouseType": "ประเภทคลังสินค้า",
    "theEntireInboundWillBeInvalidated": "ขาเข้าทั้งหมดจะใช้งานไม่ได้และสต็อกการขนส่งจะถูกปล่อย",
    "completeInbound": "ขาเข้าเรียบร้อยแล้ว",
    "partialInbound": "ขาเข้าบางส่วน",
    "afterInvalidationIsInitiatedOnlyThe": "หลังจากเริ่มต้นการทำให้ใช้ไม่ได้แล้ว เฉพาะ OMS ขาเข้าเท่านั้นที่จะใช้งานไม่ได้ และสถานะของขาเข้าในคลังสินค้าการเติมสินค้าจะไม่เปลี่ยนแปลง",
    "onlyTheErpInboundIsInvalided": "เฉพาะ OMS Inbound เท่านั้นที่ไม่ถูกต้อง",
    "afterTheInvalidationIsInitiatedThe": "หลังจากเริ่มต้นการทำให้เป็นโมฆะแล้ว ความเป็นโมฆะจะถูกแจ้งไปยังคลังสินค้าที่เติมสินค้า หลังจากการยกเลิกการใช้ไม่ได้สำเร็จ สถานะ OMS ของคลังสินค้าการเติมสินค้าจะเปลี่ยนเป็นไม่ถูกต้อง",
    "invalidErpAndFulfillmentInbound": "ทำให้ OMS และการปฏิบัติตามขาเข้าไม่ถูกต้อง",
    "invalidationMethod": "วิธีการใช้ไม่ได้:",
    "theSkuWillBecameInvalidAnd": "SKU จะไม่ถูกต้องและสต็อกการขนส่งจะถูกปล่อยออกมา",
    "pleaseSelectTheInvalidationMethod": "โปรดเลือกวิธีการทำให้ใช้ไม่ได้",
    "inboundFullAmount": "ขาเข้าเต็มจำนวน",
    "presentInboundQuantity": "ปริมาณขาเข้าปัจจุบัน",
    "pendingInboundinboundCompletedplannedInbound": "รอดำเนินการขาเข้า/ขาเข้าเสร็จสมบูรณ์/วางแผนขาเข้า",
    "afterTheInboundIsCompletedThe": "หลังจากที่ขาเข้าเสร็จสมบูรณ์ สถานะขาเข้าจะเปลี่ยนเป็น \"ขาเข้าเสร็จสมบูรณ์\" และไม่สามารถดำเนินการต่อได้",
    "sureYouWantToEndThe": "คุณแน่ใจหรือไม่ว่าต้องการยุติ ขาเข้า",
    "pushResult": "ผลการอัปเดตร้านค้า",
    "receiveItem": "รับรายการ",
    "afterDisablingThePrintedPurchaseOrder": "หลังจากปิดการใช้งาน ใบสั่งซื้อที่พิมพ์ออกมาจะไม่แสดงจำนวนสินค้าและค่าธรรมเนียม (ค่าธรรมเนียมการจัดส่ง/ค่าธรรมเนียมอื่นๆ/ยอดรวม)",
    "amountDisplay": "การแสดงจำนวนเงิน",
    "purchaseOrderPrintingSettings": "การตั้งค่าการพิมพ์ใบสั่งซื้อ",
    "purchaseSetting": "ซื้อการตั้งค่าซื้อ",
    "receivingTime": "เวลารับ",
    "inboundTotalAmount": "ปริมาณขาเข้าทั้งหมด",
    "receiveItemNumber": "รับหมายเลขรายการ",
    "supplierTypeAlreadyExists": "ประเภทซัพพลายเออร์มีอยู่แล้ว",
    "supplierType": "ประเภทซัพพลายเออร์",
    "supplierTypeDoesNotExist": "ไม่มีประเภทซัพพลายเออร์",
    "theDataIsAbnormalAndThe": "ข้อมูลผิดปกติ ไม่มีงานส่งออก",
    "xParameterIsIncorrect": "พารามิเตอร์ {x} ไม่ถูกต้อง",
    "xParameterCannotBeEmpty": "พารามิเตอร์ {x} ไม่สามารถเว้นว่างได้",
    "thePurchaseOrderAlreadyExists": "มีคำสั่งซื้ออยู่แล้ว",
    "purchaseOrderDoesNotExist": "ไม่มีคำสั่งซื้อ",
    "failedTheWarehouseTypeWasNot": "ไม่สำเร็จ ไม่ได้รับประเภทคลังสินค้า",
    "purchaseOrderCancellationFailed": "การยกเลิกคำสั่งซื้อล้มเหลว",
    "purchaseOrderDeletionFailed": "การลบคำสั่งซื้อล้มเหลว",
    "purchaseOrderSubmissionFailed": "การส่งคำสั่งซื้อล้มเหลว",
    "purchaseOrderIsMissingProductInformation": "คำสั่งซื้อไม่มีข้อมูลสินค้า",
    "purchaseOrderIsNotInDraft": "คำสั่งซื้อไม่อยู่ในสถานะ \"ร่าง\"",
    "purchaseOrderCancellationFailedPleaseRefresh": "การยกเลิกใบสั่งซื้อล้มเหลว โปรดรีเฟรชหน้านี้แล้วลองอีกครั้ง",
    "purchaseOrderDeletionFailedPleaseRefresh": "การลบใบสั่งซื้อล้มเหลว โปรดรีเฟรชหน้านี้แล้วลองอีกครั้ง",
    "purchaseOrderSubmissionFailedPleaseRefresh": "การส่งคำสั่งซื้อล้มเหลว โปรดรีเฟรชหน้านี้แล้วลองอีกครั้ง",
    "theSupplierDoesNotExists": "ไม่มีซัพพลายเออร์",
    "supplierAlreadyExists": "มีซัพพลายเออร์อยู่แล้ว",
    "theImportedDataIsEmpty": "ข้อมูลที่นำเข้าว่างเปล่า",
    "theImportedFileFormatIsNot": "ไม่รองรับรูปแบบไฟล์ที่นำเข้า",
    "theOperationTaskDoesNotExist": "ไม่มีงานการดำเนินงาน",
    "theImportTaskDoesNotExist": "ไม่มีงานนำเข้า",
    "theExportTaskDoesNotExist": "ไม่มีงานการส่งออก",
    "createAndPushSalesInvoicesWhen": "สร้างและผลักดันใบแจ้งหนี้การขายเมื่อขาออกเสร็จสมบูรณ์",
    "inboundAmount": "จำนวนเงินขาเข้า",
    "goodsReceivedNoteNumber": "รับหมายเลขรายการ",
    "quotationGeneratedSuccessfully": "สร้างใบเสนอราคาสำเร็จแล้ว!",
    "theChannelWarehouseIsNotAssociated": "ช่องทางคลังสินค้าไม่เกี่ยวข้องกับคลังสินค้า Ginee",
    "multiWarehouseChannelProductsAreNot": "สินค้าหลายช่องคลังสินค้าไม่เกี่ยวข้องกับคลังสินค้า",
    "doesNotComplyWithTheRules": "ไม่ปฏิบัติตามกฎ: ร้านค้าที่เชื่อมต่อทั้งหมดไม่รวมร้านค้าใหม่ที่ได้รับอนุญาตในภายหลัง",
    "matchingDefaultPushRulesAreDisabled": "กฎการผลักดันเริ่มต้นที่ตรงกันถูกปิดใช้งาน",
    "matchingStorePushRulesAreDisabled": "กฎการผลักดันร้านค้าที่ตรงกันถูกปิดใช้งาน",
    "matchingCustomPushRulesAreDisabled": "กฎการผลักดันแบบกำหนดเองที่ตรงกันถูกปิดใช้งาน",
    "shopifyProductInventoryIdDoesNot": "ไม่มี ID สินค้าคงคลังของ Shopfy ไม่อนุญาตให้มีการปรับปรุงสต็อก",
    "blibliProductsAreDisabledAndStock": "สินค้าของ Blibli ถูกปิดใช้งานและไม่อนุญาตให้มีการอัปเดตสต็อก",
    "returnFromTikiChannelForThis": "กลับไปช่องทาง Tiki สำหรับสินค้านี้: ไม่อนุญาตให้อัปเดต",
    "theStoreHasBeenDisconnected": "ร้านค้าถูกตัดการเชื่อมต่อ",
    "tikiProductInboundStatusDoesNot": "สถานะขาเข้าของสินค้า Tiki ไม่อนุญาตให้ผลักดันสต็อก",
    "masterProductNotFound": "ไม่พบสินค้าหลัก",
    "thePushTaskAlreadyExistsAnd": "การผลักดันมีอยู่แล้วและไม่จำเป็นต้องดำเนินซ้ำอีก",
    "failedAlreadyRepushed": "ล้มเหลว ผลักดันใหม่แล้ว",
    "pleaseSelectTheReceivingWarehouseFirst": "กรุณาเลือกคลังสินค้าที่รับสินค้าก่อน",
    "ifNotFilledInItWill": "หากไม่กรอก ระบบจะถูกสร้างขึ้นโดยอัตโนมัติ",
    "region": "ภูมิภาค",
    "cityNew": "เมือง",
    "provinc": "จังหวัด",
    "districtsNew": "อำเภอ",
    "purchaseQuantity": "ปริมาณการซื้อ",
    "otherCostAllocationMethodsPleaseSelect": "วิธีการจัดสรรต้นทุนอื่นๆ (โปรดเลือก)",
    "otherCostTotal": "ต้นทุนอื่นๆ (รวม)",
    "shippingFeeAllocationMethodPleaseSelect": "วิธีการจัดสรรค่าจัดส่ง (กรุณาเลือก)",
    "shippingFeeTotal": "ค่าจัดส่ง (รวม)",
    "wantToUnbindTheWarehouse": "คุณแน่ใจหรือไม่ว่าต้องการแยกคลังสินค้า",
    "afterUnbindingChangesInStockIn": "หลังจากการคลายการเชื่อมโยง การเปลี่ยนแปลงในสต็อกใน System Warehouse จะไม่ถูกผลักไปยัง Channel Warehouse",
    "unbindTheChannelWarehouseAaaFrom": "ยกเลิกการเชื่อมโยง Channel Warehouse {AAA} จาก System Warehouse",
    "thisFeatureIsOnlyAvailableFor": "ฟีเจอร์นี้ใช้ได้เฉพาะกับ Tokopedia Official Store",
    "deleteSupplier": "ลบซัพพลายเออร์",
    "enableSupplier": "เปิดใช้งานซัพพลายเออร์",
    "disableSupplier": "ปิดการใช้งานซัพพลายเออร์",
    "editSupplier": "แก้ไขซัพพลายเออร์",
    "manualDeleteSupplierX": "ลบซัพพลายเออร์ด้วยตนเอง [{x}]",
    "enableSupplierX": "เปิดใช้งานซัพพลายเออร์ [{x}]",
    "disableSupplierX": "ปิดการใช้งานซัพพลายเออร์ [{x}]",
    "importToUpdateSupplierX": "นำเข้าเพื่ออัปเดตซัพพลายเออร์ [{x}]",
    "manualEditSupplierX": "แก้ไขซัพพลายเออร์ด้วยตนเอง [{x}]",
    "importToAddSupplierX": "นำเข้าเพื่อเพิ่มซัพพลายเออร์ [{x}]",
    "manualAddSupplierX": "เพิ่มซัพพลายเออร์ด้วยตนเอง [{x}]",
    "exportSupplierList": "ส่งออกรายชื่อซัพพลายเออร์",
    "exportPurchaseOrderX": "ส่งออกใบสั่งซื้อ [{x}]",
    "printPurchaseOrderX": "พิมพ์ใบสั่งซื้อ [{x}]",
    "cancelPurchaseOrderX": "ยกเลิกใบสั่งซื้อ [{x}]",
    "deletePurchaseOrderX": "ลบใบสั่งซื้อ [{x}]",
    "submitPurchaseOrderX": "ส่งใบสั่งซื้อ [{x}]",
    "editPurchaseOrderXManually": "แก้ไขใบสั่งซื้อด้วยตนเอง [{x}]",
    "importPurchaseOrderX": "นำเข้าเพื่อเพิ่มใบสั่งซื้อ [{x}]",
    "addPurchaseOrderXManually": "เพิ่มใบสั่งซื้อด้วยตนเอง [{x}]",
    "exportPurchaseO": "ส่งออกใบสั่งซื้อ",
    "cancelPurchaseOrder": "ยกเลิกใบสั่งซื้อ",
    "deletePurchaseOrder": "ลบใบสั่งซื้อ",
    "submitPurchaseOrder": "ส่งใบสั่งซื้อ",
    "editPurchaseOrder": "แก้ไขใบสั่งซื้อ",
    "addPurchaseOrder": "เพิ่มใบสั่งซื้อ",
    "shopeeRequirements": "ข้อกำหนดของ Shopee:",
    "formatMp": "Format: MP4",
    "durationSs": "ระยะเวลา: 10วิ-60วิ",
    "sizeMaxMbResolutionShouldNot": "ขนาด: สูงสุด 30Mb ความละเอียดไม่ควรเกิน 1280x1280px",
    "myShopeeSellerCenterCanInsert": "เฉพาะร้านค้า Shopee บางแห่งเท่านั้นที่สามารถรองรับรูปภาพและคําอธิบายได้ดังนั้นโปรดตรวจสอบให้แน่ใจว่าร้านค้าของคุณรองรับแล้ว ก่อนที่จะเปิดตัวเลือกนี้",
    "copyCopy": "คัดลอก",
    "thankYouForYourCooperationPlease": "ขอขอบคุณสำหรับความร่วมมือ โปรดคัดลอก ID เฉพาะและส่งไปที่ทีม Ginee Support",
    "weNeedYourConsentToRecord": "เราต้องการความยินยอมจากคุณในการบันทึกหน้าจอและบันทึกขั้นตอนการทำงาน จากนั้นระบุและแก้ไขปัญหาของคุณ",
    "weOnlyRecordThePagesIn": "เราบันทึกเฉพาะหน้าในระบบ Ginee เท่านั้น",
    "needAuthorization": "ต้องการการอนุญาต",
    "approveStartToRecord": "อนุมัติและเริ่มบันทึก",
    "done": "เสร็จสิ้น",
    "repeatTheOperationStepsToReproduce": "ทำซ้ำขั้นตอนการดำเนินการเพื่อสร้างปัญหาอีกครั้ง หยุดการบันทึกหลังจากบันทึกการดำเนินการทั้งหมด",
    "capturingTheProcessingFlowOnThe": "บันทึกขั้นตอนการทำงานบนหน้าจอ",
    "stop": "หยุด",
    "uploading": "กำลังอัพโหลด",
    "importToUpdateSupplier": "นำเข้าเพื่ออัปเดตซัพพลายเออร์",
    "importToAddSupplier": "นำเข้าเพื่อเพิ่มซัพพลายเออร์",
    "feedback": "Feedback",
    "uploadVideo": "อัปโหลดวิดีโอ",
    "wereNotPerfectButWereListening": "เราไม่ได้สมบูรณ์แบบ แต่เรากำลังรับฟัง",
    "feedbackForm": "Feedback Form",
    "pleaseSubmitATicektFormWith": "กรุณาส่งแบบฟอร์มพร้อมคำอธิบายข้อความและรูปภาพที่แนบมาด้วย",
    "feedbackVideo": "Feedback Video",
    "pleaseGrantGineeThePermissionRecord": "- โปรดอนุญาตให้ Ginee บันทึกการดำเนินการของคุณเพื่อวินิจฉัย",
    "enterTheSpecificPageStartTo": "- เข้าสู่หน้าเฉพาะ เริ่มบันทึกและทำซ้ำการดำเนินการของคุณเพื่อทำให้เกิดปัญหาอีกครั้ง",
    "stopTheRecordCopyTheUnique": "- หยุดการบันทึก คัดลอก ID ที่เฉพาะเจาะจง และส่งไปยังทีมสนับสนุน Ginee",
    "faqfeedback": "FAQ/Feedback",
    "needHelp": "ต้องการความช่วยเหลือ?",
    "detailedDescription": "คำอธิบายโดยละเอียด",
    "trackingNumber": "หมายเลขติดตาม",
    "enableLongDescription": "เปิดใช้งานรูปภาพและคำอธิบาย",
    "DetailProductDescription": "คำอธิบายผลิตภัณฑ์",
    "dueToApiLimitationsBlibliMust": "เนื่องจากข้อจำกัดของ API Blibli จะต้องใช้การพิมพ์ที่รวดเร็วเพื่อดาวน์โหลดฉลากการจัดส่ง ล่วงหน้า โปรดไปที่การตั้งค่าการสั่งซื้อ -> การพิมพ์ที่รวดเร็วเพื่อเปิดใช้งานการพิมพ์ความเร็วสูงในร้านค้า Blibli และลองอีกครั้ง",
    "abnormal": "ผิดปกติ",
    "theInformationCannotBeFoundIn": "หากไม่พบข้อมูลใน Ginee โปรดยืนยันว่าเนื้อหาของแต่ละช่องทางได้รับการจับคู่หรือเข้าสู่ระบบเพื่อแก้ไขข้อมูลที่ถูกต้องแล้วซิงโครไนซ์อีกครั้ง",
    "purchasePurchaseOrder": "ซื้อ / ใบสั่งซื้อ",
    "addToList": "เพิ่มลงในรายการ",
    "modifyingAccuratePurchaseOrderInformationIn": "การแก้ไขข้อมูลคำสั่งซื้อของ Accurate ใน Ginee นั้นจะถูกอัปเดตข้อมูลของ Ginee เท่านั้นและจะไม่ถูกซิงโครไนซ์ให้ถูกต้อง การแก้ไขคำสั่งซื้อของ Accurate ในสถานะที่ไม่ใช่แบบร่างจะไม่ถูกซิงโครไนซ์กับ Ginee",
    "pleaseSelectProduct": "กรุณาเลือกสินค้า",
    "gineeOnlySupportsCreatingPurchaseOrder": "Ginee รองรับเฉพาะการสร้างคำสั่งซื้อสำหรับสินค้าหลายรายการจากคลังสินค้าเดียวกัน โปรดเข้าสู่ระบบเพื่อแก้ไขและแก้ไขสินค้าทั้งหมดไปยังคลังสินค้าเดียวกัน",
    "supplierContactDetails": "รายละเอียดข้อมูลซัพพลายเออร์",
    "operationFailed": "ไม่สำเร็จ",
    "whenSelectingMultipleShopeeStoresThe": "เมื่อเลือกร้านค้า Shopee หลายร้านแล้ว จำนวนของสต็อกสามารถอัปเดตไปเป็นคลังสินค้าเริ่มต้นเท่านั้น หากคุณต้องการอัปเดตสต็อกเป็นคลังสินค้า Shopee หลายร้าน โปรดเลือกเพียง 1 ร้านค้า",
    "requestTimedOutPleaseCheckThe": "คำขอหมดเวลา โปรดตรวจสอบเครือข่ายแล้วลองอีกครั้ง",
    "manageMultiwarehouseStock": "จัดการสต็อกสินค้าหลายคลังสินค้า",
    "supplierCodeSupplierCodeCannotBe": "รหัสซัพพลายเออร์ (รหัสซัพพลายเออร์ไม่สามารถแก้ไขได้หลังจากสร้างแล้ว)",
    "hasReturnrefund": "ส่งคืนกลับ/คืนเงิน",
    "batchEditingOnlySupportsModifyingA": "การแก้ไขจำนวนมากนั้นรองรับเฉพาะการแก้ไขสต็อกในคลังสินค้าเดียวเท่านั้น หากคุณต้องการแก้ไขสต็อกในคลังสินค้าหลายแห่งโปรดใช้การแก้ไขสินค้าทีละรายการ",
    "invalidationIsInitiatedOnlyTheOms": "หลังจากเริ่มต้นการทำให้เป็นให้โมฆะแล้ว เฉพาะขาออก OMS เท่านั้นที่จะเป็นโมฆะ และสถานะของขาออกในคลังสินค้า Fulfillment จะไม่เปลี่ยนแปลง",
    "onlyTheOmsOutboundIsInvalided": "เฉพาะขาออก OMS เท่านั้นที่เป็นโมฆะ",
    "invalidateOmsAndFulfillmentOutbound": "ตรวจสอบโมฆะของ OMS และ Fulfillment ขาออก",
    "installationTutorial": "ขั้นตอนการติดตั้ง",
    "howToUse": "วิธีใช้",
    "downloadAndInstallThePlugin": "ดาวน์โหลดและติดตั้งปลั๊กอิน",
    "goToChromeWebStoreTo": "ไปที่เว็บ Chrome Web Store เพื่อติดตั้ง",
    "ForPluginsHowToUse": "4. สำหรับวิธีใช้งานของปลั๊กอิน โปรดดูที่:",
    "IfYouCannotAccessThe": "3. หากคุณไม่สามารถเข้าถึงเว็บ Chrome Web Store ได้ ให้ดาวน์โหลดปลั๊กอินที่นี่:",
    "PluginInstallationLink": "2. ลิงค์การติดตั้งปลั๊กอิน:",
    "RecommendedBrowserGoogleChrome": "1. เบราว์เซอร์ที่แนะนำ: Google Chrome",
    "pleaseDownloadAndUseTheScrape": "โปรดดาวน์โหลดและใช้ปลั๊กอิน Scrape มีเสถียรภาพและรวดเร็วขึ้น!",
    "editAndPublishFromTheStore": "แก้ไขและเผยแพร่จากร่างของสินค้าในร้านค้า",
    "EditAndPublish": "4. แก้ไขและเผยแพร่",
    "selectAStoreAndCopyThe": "เลือกร้านค้าและคัดลอกสินค้าหลักไปยังร่างสินค้าในร้านค้า",
    "CopyAndSellInStores": "3. คัดลอกและขายในร้านค้า",
    "massClaimAndCreateMasterProduct": "รับสิทธิ์และสร้างสินค้าหลัก",
    "ClaimTheMasterProduct": "2. อ้างสิทธิ์สินค้าหลัก",
    "visitTheProductPageYouWant": "ไปที่หน้าสินค้าที่คุณต้องการดึงข้อมูลและใช้ปลั๊กอินเพื่อดึงด้วยคลิกเดียว",
    "ScrapeProduct": "1. Scrape สินค้า",
    "returnrefund": "การคืน/คืนเงิน",
    "editWarehouseStockInStockList": "แก้ไขคลังสินค้าในรายการสต็อก",
    "importToUpdateStockIncreasedecrease": "นำเข้าเพื่ออัปเดตสต็อก - เพิ่ม / ลดมูลค่า",
    "importToUpdateStockFinal": "นำเข้าเพื่ออัปเดตสต็อก - มูลค่าหลังคิดคำนวณ",
    "purchaseOrdersSourcedFromAccurateDo": "คำสั่งซื้อที่มาจากAccurate ไม่รองรับการยกเลิกในระบบ Ginee หากคุณต้องการยกเลิกโปรดเข้าสู่ระบบ Accurate เพื่อยกเลิก",
    "failedToCreateOmsInboundThe": "ไม่สามารถสร้าง OMS Inbound: SKU ใน WMS Inbound ไม่มีอยู่ใน OMS หรือเป็นผลิตภัณฑ์ที่รวมกัน",
    "IfYouHaveDoneThe": "5. หากคุณได้ทำตามขั้นตอนข้างต้นทั้งหมดแล้ว โปรดติดต่อฝ่ายบริการลูกค้า",
    "IfEverythingIsNormalPlease": "4. หากทุกอย่างถูกต้องโปรดลองอีกครั้งหลังใน 1 นาที",
    "PleaseSwitchTheNetworkAnd": "3. โปรดเปลี่ยนเครือข่ายและลองอีกครั้ง",
    "PleaseConfirmThatTheBrowser": "2. โปรดยืนยันว่าเบราว์เซอร์ไม่มีการตั้งค่าพิเศษหรือลองอีกครั้งหลังจากเปลี่ยนเบราว์เซอร์",
    "PleaseCheckWhetherYouAre": "1. โปรดตรวจสอบว่าคุณกำลังใช้ปลั๊กอินของบุคคลที่สาม เช่น การบล็อกโฆษณา/โดเมน ฯลฯ หรือไม่ ขอแนะนำให้ปิดปลั๊กอินแล้วลองอีกครั้ง",
    "inComplianceWithShopeePolicyBuyer": "หลังจากการอนุญาตจากร้านค้า Ginee จะไม่ซิงค์คำสั่งซื้อในอดีต หากคุณต้องการดูคำสั่งซื้อในอดีต ให้คลิกปุ่ม [ซิงค์คำสั่งซื้อ] เพื่อซิงค์คำสั่งซื้อในอดีตตามต้องการ เพื่อให้เป็นไปตามข้อกำหนดนโยบายของ Shopee ข้อมูลลูกค้าจะไม่แสดงสำหรับคำสั่งซื้อในอดีตที่เกิน 90 วันอีกต่อไป ขอขอบคุณสำหรับความเข้าใจของคุณ",
    "inComplianceWithShopeePolicyThe": "ตามนโยบายข้อกำหนดของ Shopee จะไม่ให้สามารถจัดรายชื่อลูกค้าข้อมูลการสั่งซื้อของ Shopee ได้",
    "massAddHistory": "ประวัติการเพิ่มเป็นกลุ่ม",
    "cancellation": "การยกเลิก",
    "failedDelivery": "การจัดส่งล้มเหลว",
    "ordersHasBeenMovedToReturnrefund": "คำสั่งซื้อ [%s] ถูกย้ายไปส่งคืน / คืนเงิน",
    "moveToReturnrefund": "ย้ายไปคืน/คืนเงิน",
    "ordersHasBeenMovedToCompleted": "คำสั่งซื้อ [%s] ถูกย้ายไปยัง เสร็จสิ้น",
    "moveToCompleted": "ย้ายไปที่เสร็จสิ้น",
    "youWantToMoveTheOrder": "คุณแน่ใจใช่ไหมว่าต้องการย้ายคำสั่งซื้อไปยังที่เสร็จสิ้น?",
    "confirmToSync": "ยืนยันการเชื่อมต่อ ?",
    "purchaseSupplierManagement": "การจัดการจัดซื้อและซัพพลายเออร์",
    "afterClosingAllStoresWillNot": "หลังจากปิดการใช้งาน ร้านค้าทั้งหมดจะไม่ถูกซิงค์และสต็อกโปรโมชั่นของ Shopee จะไม่ถูกจอง",
    "wantToDisableAutomaticallyReservePromotion": "คุณแน่ใจหรือไม่ว่าต้องการปิดใช้งานการจองสต็อกส่งเสริมการขายโดยอัตโนมัติ",
    "pushingPleaseRefreshThePageLater": "กำลังผลักดัน โปรดรีเฟรชหน้าในภายหลังเพื่อดูสถานะผลักดันล่าสุด",
    "wantToMassDisableAutoPush": "คุณแน่ใจหรือไม่ว่าต้องการปิดใช้งานการผลักดันอัตโนมัติจำนวนมาก",
    "wantToMassEnableAutoPush": "คุณแน่ใจหรือไม่ว่าต้องการเปิดใช้งาน ผลักดันอัตโนมัติจำนวนมาก?",
    "onlyGineeWmsWarehouseSupportsEditing": "แก้ไขกลยุทธ์การผลักดัน",
    "editPushStrategy": "แก้ไขกลยุทธ์การผลักดัน",
    "disableAutoPush": "ปิดใช้งานการผลักดันอัตโนมัติ",
    "enableAutoPush": "เปิดใช้งานการผลักดันอัตโนมัติ",
    "autoPush": "ผลักดันอัตโนมัติ",
    "pushStrategy": "ผลักดันกลยุทธ์",
    "theOrderWillBePushedTo": "คำสั่งซื้อจะถูกส่งไปยังคลังสินค้าของบุคคลที่สามล่วงหน้าตามเงื่อนไขการกำหนดค่า คำสั่งอื่นๆ จะยังคงถูกผลักดันตามการผละกดันปกติ คำสั่งที่มีปัญหาจะไม่ถูกผลักดัน",
    "regardlessOfWhetherTheOrderIs": "ไม่ว่าคำสั่งซื้อจะมีสิทธิ์สำหรับการจัดส่งหรือไม่ (ไม่มีการจัดเตรียมการจัดส่ง ไม่มี AWB ไม่มีป้ายกำกับการจัดส่ง) คำสั่งซื้อทั้งหมดที่ซิงโครไนซ์กับสถานะ \"ชำระเงิน\" และ \"พร้อมส่ง\" หลังจากที่รายการขาออกถูกสร้างขึ้นจะถูกผลักไปที่ทันที คลังสินค้าบุคคลที่สาม คำสั่งปัญหาจะไม่ถูกผลักดัน",
    "theDefaultPushRuleIsThat": "กฎการผลักดันเริ่มต้นคืออนุญาตให้ส่งเฉพาะคำสั่งซื้อที่ได้รับใบปะหน้าการจัดส่งไปยังคลังสินค้าบุคคลที่สามเท่านั้น และคำสั่งซื้อที่มีปัญหาจะไม่ถูกผลัก",
    "strategyDescription": "คำอธิบายกลยุทธ์",
    "deleteFailedThereIsAWarehouse": "ลบไม่สำเร็จ! มีคลังสินค้าที่ใช้งาน!",
    "onlyStrategiesThatAreNotAssociated": "เฉพาะกลยุทธ์ที่ไม่เกี่ยวข้องกับคลังสินค้าเท่านั้นที่สามารถลบได้",
    "wantToDeleteTheStrategy": "คุณแน่ใจหรือไม่ว่าต้องการลบกลยุทธ์นี้",
    "allowEarlyPushWhenTheOrder": "อนุญาตให้ผลักดันล่วงหน้าก่อนเมื่อสถานะคำสั่งซื้อเป็น \"ชำระแล้ว\"",
    "pleaseSetAtLeastOneAdvance": "โปรดตั้งค่าเงื่อนไข ผลักดันล่วงหน้า อย่างน้อยหนึ่งรายการ เฉพาะคำสั่งซื้อที่ตรงตามเงื่อนไขที่เลือกเท่านั้นที่จะถูกทริกเกอร์สำหรับการผลักดันล่วงหน้า มิฉะนั้น จะทำการผลักดันปกติ",
    "upToCustomStrategiesCan": "สามารถเพิ่มกลยุทธ์ที่กำหนดเองได้สูงสุด 100 กลยุทธ์",
    "warehouseUsed": "คลังสินค้าใช้แล้ว",
    "pushPaidOrdersInAdvance": "ผลักดันคำสั่งซื้อ \"ชำระแล้ว\" ล่วงหน้า",
    "strategyName": "ชื่อกลยุทธ์",
    "manageCustomPolicies": "จัดการกลยุทธ์ที่กำหนด",
    "customAdvancePush": "ผลักดันล่วงหน้าแบบกำหนดเอง",
    "pushAllInAdvance": "ผลักดันล่วงหน้าทั้งหมด",
    "basicPush": "ผลักดันปกติ",
    "normalPushPushAllEarlyCustom": "สามารถตั้งค่าการผลักดันปกติ, ผลักดันล่วงหน้าทั้งหมด, สามารถตั้งค่าการผลักดันล่วงหน้าแบบกำหนดเอง",
    "thirdpartyWarehousePushSettings": "การตั้งค่าการผลักดันคลังสินค้าของบุคคลที่สาม",
    "quantityLimitInfo": "จำนวน การซื้อต้องไม่เกิน 1,000",
    "valueShouldBeAtLeastThe": "ไม่น้อยกว่า  {count}",
    "purchaseLimit": "จำกัดการซื้อ",
    "maxPerOrder": "สูงสุดต่อคำสั่งซื้อ",
    "storesAreAutomaticallySetToEnable": "[ร้านค้าที่ได้รับอนุญาตใหม่ได้รับการตั้งค่าเป็น \"เปิดใช้งาน\" โดยอัตโนมัติ] ปิดการใช้งาน",
    "authorizedStoresAreAutomaticallySetTo": "[ร้านค้าที่ได้รับอนุญาตใหม่จะได้รับการตั้งค่าเป็น \"เปิดใช้งาน\" โดยอัตโนมัติ] เปิดใช้งานแล้ว",
    "storeXDisableTheAutomaticallyReserve": "ร้านค้า {x} ปิดการใช้งานจองโปรโมชั่นสต็อกโดยอัตโนมัติ",
    "storeXEnableTheAutomaticallyReserve": "ร้านค้า {x} เปิดใช้งานจองสต็อกส่งเสริมการขายโดยอัตโนมัติ",
    "disableAutomaticallyReservePromotionStockMain": "ปิดการใช้งานสต็อกโปรโมชั่นจองโดยอัตโนมัติ",
    "enableAutomaticallyReservePromotionStockMain": "เปิดใช้งานสต็อกโปรโมชั่นจองอัตโนมัติ",
    "enabledisableAutomaticallyReservePromotionStock": "เปิด/ปิดใช้งานการจองสต็อกโปรโมชั่นโดยอัตโนมัติ",
    "change": "เปลี่ยน",
    "superLinkAsAnOpenDoor": "ซุปเปอร์ลิงค์เป็นประตูเปิดเชื่อมต่อทุกที่เก็บธุรกิจของคุณ ลิงค์ทั้งหมด, เนื้อหา, ฯลฯ ในหน้าเดียว!",
    "actualInboundQty": "จำนวนขาเข้าจริง",
    "plannedInboundQty": "จำนวนขาเข้าที่วางแผนไว้",
    "tipShopeePendingWaitingForShopee": "เคล็ดลับ: Shopee รอดำเนินการ: รอให้ Shopee ให้ข้อมูลด้านโลจิสติกส์หรือ Shopee ยังคงดำเนินการตรวจสอบการฉ้อโกง โปรดซิงค์คำสั่งซื้อด้วยตนเองในภายหลังเพื่อรับสถานะล่าสุด",
    "shopeeOrdersAreCurrentlyInPending": "คำสั่งซื้อ Shopee อยู่ในสถานะรอดำเนินการ ไม่รองรับการรับใบปะหน้าการจัดส่งในขณะนี้ โปรดลองอีกครั้งในภายหลัง",
    "gineeHasAutomaticallyFilteredXShopee": "Ginee ได้กรองคำสั่งซื้อ Shopee {x} โดยอัตโนมัติซึ่งสถานะยังคงรอดำเนินการอยู่และไม่ได้รับอนุญาตให้จัดส่ง",
    "Template": "เทมเพลต",
    "promotionname": "ชื่อโปรโมชั่น",
    "EndTimeLeastDay": "เวลาสิ้นสุดต้องมากกว่าเวลาเริ่มต้นอย่างน้อย 1 วัน",
    "theEndTimeMustBeLater": "เวลาสิ้นสุดต้องไม่น้อยกว่าเวลาเริ่มต้น",
    "TokoStartMinutesTips": "เวลาเริ่มต้นต้องมากกว่าเวลาปัจจุบันอย่างน้อย 5 นาที",
    "startTimeMustBeAtLeast": "เวลาเริ่มต้นต้องมากกว่าเวลาปัจจุบันอย่างน้อย 5 นาที",
    "OnceTheDiscountPromotion": "เมื่อสร้างโปรโมชั่นสำเร็จสามารถย่นระยะเวลาโปรโมชั่นได้เท่านั้น",
    "theEndTimeMustBeGreater": "เวลาสิ้นสุดต้องมากกว่าเวลาเริ่มต้นอย่างน้อย 1 ชั่วโมง",
    "EntitledProducts": "สินค้าโปรโมชั่น",
    "RemoveProducts": "ลบสินค้า",
    "removeSelectedProducts": "ลบสินค้าที่เลือก",
    "RemoveAllProducts": "ลบสินค้าทั้งหมด",
    "promotionDetails": "รายละเอียดโปรโมชั่น",
    "UpdateAllProducts": "อัปเดตไปยังสินค้าทั้งหมด",
    "UpdateSelectedProducts": "อัปเดตไปยังสินค้าที่เลือกไว้",
    "SetDiscountAt": "ตั้งค่าส่วนลดเป็น",
    "stockMonitorAndSyncIsNot": "ไม่เปิดใช้งานการตรวจสอบและซิงค์สต็อก",
    "inboundHistory": "ประวัติขาเข้า",
    "inboundDetails": "รายละเอียดขาเข้า",
    "currentlyDoesNotSupportUploadingeditingLazada": "ปัจจุบันไม่รองรับการอัปโหลด/แก้ไขวิดีโอ Lazada",
    "noteYouCanPublishThisListing": "หมายเหตุ: คุณสามารถเผยแพร่รายการสินค้สนี้ในขณะที่วิดีโอกำลังดำเนินการอยู่ วิดีโอจะแสดงในรายการเมื่อประมวลผลเรียบร้อยแล้ว",
    "pageNumber": "/ หน้า",
    "rejectionReason": "เหตุผลในการปฏิเสธ",
    "theShippingLabelIsEmptyPush": "ใบปะหน้าจัดส่งว่างเปล่า,ผลักดันล้มเหลว",
    "theTrackingNumberIsEmptyPush": "หมายเลขติดตามว่างเปล่า, ผลักดันล้มเหลว",
    "doesntMeetTheOrderStatusRequirement": "ไม่ตรงตามเงื่อนไขของสถานะคำสั่งซื้อ, ผลักดันล้มเหลว",
    "problemOrderStoredOrderPush": "คำสั่งซื้อที่มีปัญหา & คำสั่งซื้อที่บันทึกไว้, ผลักดันล้มเหลว",
    "reminderOnceTheStoreStockIs": "คำเตือน: เมื่อผลักดันสต็อกร้านค้าสำเร็จแล้วจะไม่สามารถกู้คืนได้ โปรดตรวจสอบให้แน่ใจว่าแต่ละ MSKU เกี่ยวข้องกับคลังสินค้าและมีการตั้งค่าสินค้าคงคลังที่ถูกต้อง",
    "whenANewOrderIsPlaced": "เมื่อมีคำสั่งซื้อใหม่หรือคำสั่งซื้อถูกยกเลิกสำหรับ MSKU สต็อกของช่องทางสินค้าทั้งหมดที่ผูกกับ MSKU จะลดลงหรือเพิ่มขึ้นอย่างสม่ำเสมอ",
    "theStockOfAllChannelProducts": "สต็อกของช่องทางสินค้าทั้งหมดที่ผูกไว้กับ MSKU เดียวกันจะถูกผลักดันทันทีตาม \"{x}\"",
    "afterTurningOnStockMonitorAnd": "หลังจากการติดตามสต็อกและซิงค์:",
    "addingeditingProductsOrStockInVivo": "ไม่รองรับการเพิ่ม / แก้ไขสินค้าหรือสต็อกในคลังสินค้า Vivo หากคุณต้องการแก้ไขโปรดเข้าสู่ระบบ Vivo",
    "beforeOutboundItHasBeenVerified": "ก่อนการดำเนินขาออก ได้รับการตรวจสอบแล้วว่าคลังสินค้า Vivo มีสต็อกไม่เพียงพอ การผลักดันล้มเหลว",
    "areYouSureToEnableStock": "คุณแน่ใจหรือว่าจะเปิดใช้งานการติดตามและซิงค์สต็อก?",
    "areYouSureToDisableStock": "คุณแน่ใจหรือว่าจะปิดการใช้งานการติดตามและซิงค์สต็อก?",
    "plannedInboundCost": "ต้นทุนขาเข้าตามแผนที่กำหนด",
    "onlyTheGineeWmsWarehouseSupports": "เฉพาะคลังสินค้า Ginee WMS / Keeppack / FASTOCK เท่านั้นที่รองรับการแก้ไขกลยุทธ์การผลักดัน การแก้ไขกลยุทธ์การผลักดันจะมีผลกับคำสั่งซื้อใหม่เท่านั้น คำสั่งซื้อที่มีอยู่สามารถเรียกใช้กลยุทธ์การผลักดันอีกครั้งผ่าน \"ซิงค์\"",
    "editingThePushStrategyWillOnly": "การแก้ไขกลยุทธ์การผลักดันจะมีผลกับคำสั่งซื้อใหม่เท่านั้น คำสั่งซื้อที่มีอยู่สามารถเรียกใช้กลยุทธ์จาดการผลักดันอีกครั้งผ่าน \"ซิงค์\"",
    "userManuallyEditedTheWarehouseStock": "ผู้ใช้แก้ไขสต็อคคลังสินค้าด้วยตนเอง ส่งผลให้สต็อคที่มีอยู่ติดลบ",
    "multipleChannelProductsAreBoundTo": "สินค้าช่องทางการขาย/ช่องทางหลายแห่งถูกผูกไว้กับ MSKU เดียวกัน ซึ่งจะทําให้สต็อกเดียวกันถูกขายในเวลาเดียวกัน หากลูกค้าซื้อพร้อมกันจะทําให้เกิดการขายมากเกินไป",
    "orderSyncDelayDueToDelay": "ความล่าช้าในการซิงค์คำสั่งซื้อ: เนื่องจากความล่าช้าในการซิงค์คำสั่งซื้อจากแต่ละช่องทางสต็อกจึงถูกใช้สำหรับคำสั่งซื้ออื่น",
    "failedPushStockStockNotPushed": "ผลักดันสต๊อกไม่สำเร็จ ไม่ดันสต๊อกเข้าร้าน ทำให้สต๊อกร้านไม่สอดคล้องกับสต๊อก Ginee",
    "TheStockWasEditedIn": "2. แก้ไขสต็อกสินค้าในศูนย์ผู้ขาย การแก้ไขสต็อกในศูนย์ผู้ขาย จะไม่ซิงค์กับสต็อก Ginee MSKU ซึ่งอาจทำให้สต็อกระหว่าง Ginee และช่องทางไม่สอดคล้องกัน",
    "AbnormalitiesWhenTheChannelAdjusts": "1. ความผิดปกติเมื่อช่องปรับสต๊อกส่งผลให้ปรับสต๊อกไม่ครบ",
    "gineeSuccessfullyPushedTheStockAnd": "Ginee ดันสต็อกได้สำเร็จ และ ช่องทางตลาดก็รับสต็อกได้สำเร็จ มีความเป็นไปได้ 3 ทาง:",
    "pushDelayDueToTheLarge": "ความล่าช้าในการผลักดันเนื่องจากมีงานผลักดันจำนวนมาก ทำให้สต็อกสินค้าไม่ได้ถูกผลักออกสู่แต่ละช่องทางตรงเวลา",
    "noPushRecordFoundStockNot": "ไม่พบข้อมูลการผลักดันสต๊อกสินค้าไม่ถูกผลักดันเข้าร้านทำให้สต๊อกร้านไม่สอดคล้องกับสต๊อก Ginee",
    "thisStoreHasReservedPromotionStock": "ร้านค้านี้มีสต็อกโปรโมชั่นที่จองไว้ แต่สต็อกโปรโมชั่นจองอัตโนมัติของ Ginee ถูกปิดใช้งาน ทำให้สต็อกของร้านค้าไม่สอดคล้องกับสต็อกของ Ginee",
    "theStockMonitorAndSyncIs": "การตรวจสอบสต็อกและการซิงค์ถูกปิดใช้งาน ดังนั้นสต็อกที่อัปเดตของ Ginee จะไม่ถูกส่งไปที่ร้านค้า",
    "missingOrderInformationsCurrentlyUnableTo": "ไม่มีข้อมูลการสั่งซื้อ ขณะนี้ไม่สามารถระบุสาเหตุได้",
    "pleaseCheckTheChangeReasonOn": "โปรดตรวจสอบเหตุผลในการเปลี่ยนแปลงทางด้านขวาของคอลัมน์นี้",
    "pleaseCheckThePushFailedReason": "โปรดตรวจสอบสาเหตุการผลักดันล้มเหลวในบันทึกการผลักดันสต็อก",
    "forOtherChannelsPleaseDirectlyContact": "สำหรับช่องทางอื่นๆ โปรดติดต่อฝ่ายบริการลูกค้า Ginee โดยตรงหรือเจ้าหน้าที่ดูแลของคุณเพื่อตรวจสอบเพิ่มเติม",
    "PleaseContactGineeCustomerService": "3. โปรดติดต่อฝ่ายบริการลูกค้า Ginee หรือเจ้าหน้าที่ดูแลของคุณเพื่อตรวจสอบเพิ่มเติม",
    "PleaseSyncTheProductThrough": "1. กรุณาซิงค์สินค้าผ่านช่องทางการขาย",
    "forShopeeBukalapakAndLazadaProducts": "สำหรับสินค้า Shopee, Bukalapak และ Lazada:",
    "ifYouDidntEditTheStock": "หากคุณไม่ได้แก้ไขสต็อกจากศูนย์ผู้ขายของแพลตฟอร์ม โปรดทำตามขั้นตอนด้านล่าง:",
    "preventTheSameIssueHappeningIn": "เพื่อป้องกันปัญหาเดียวกันนี้เกิดขึ้นอีกในอนาคต โปรดเปิดใช้งานโปรโมชันจองอัตโนมัติ",
    "toPreventTheSameIssueHappening": "เพื่อป้องกันปัญหาเดียวกันนี้เกิดขึ้นในอนาคต โปรดเปิดใช้งานติดตามและเชื่อมต่อสต็อกอัตโนมัติไว้",
    "pleaseEnableStockMonitorAndSync": "เพื่อป้องกันปัญหาเดียวกันนี้เกิดขึ้นในอนาคต โปรดเปิดใช้งาน",
    "notesIfTheStockIsSmaller": "หมายเหตุ: หากสต็อกมีจำนวนน้อยกว่า (-1) โปรดตรวจสอบบันทึกการเปลี่ยนแปลงสต็อกสำหรับสต็อกที่มีอยู่ (-1) ก่อน",
    "pleaseContactGineeCustomerServiceOr": "โปรดติดต่อฝ่ายบริการลูกค้า Ginee หรือเจ้าหน้าที่ดูแลของคุณเพื่อตรวจสอบเพิ่มเติม",
    "gineeOmsOrderusedtotal": "คำสั่งซื้อ Ginee OMS(ใช้แล้ว/ทั้งหมด)",
    "mskuUsedtotal": "MSKU (ใช้แล้ว/ทั้งหมด)",
    "currentlyDoesNotSupportUploadingeditingShopee": "ปัจจุบันไม่รองรับการอัปโหลด/แก้ไขวิดีโอ Shopee",
    "gineeWmsOrderusedtotal": "คำสั่งซื้อ Ginee WMS(ใช้แล้ว/ทั้งหมด)",
    "walletCredit": "เครดิตประเป๋าเงิน",
    "gineeWmsConsignorusedtotal": "ผู้ส่ง Ginee WMS(ใช้แล้ว/ทั้งหมด)",
    "onlySupportViewingCustomerDataFrom": "รองรับการดูข้อมูลลูกค้าตั้งแต่วันที่ 1 มกราคม 2023 เท่านั้น",
    "packageSplit": "แยกแพ็คเกจ",
    "theMskuWasNotFoundIn": "ไม่พบ MSKU ในคลังสินค้าของบุคคลที่สาม โปรดตรวจสอบสถานะการผลักดันของ SKU ในหน้ารายการสต็อก หากการผลักดันล้มเหลวโปรดดำเนินการ \"เพิ่มในคลังสินค้า Fulfillment \" ด้วยตนเอง",
    "changePrice": "เปลี่ยนแปลงราคา",
    "discAfterTax": "ภาษีหลังส่วนลด",
    "discBeforeTax": "ส่วนลดก่อนหักภาษี",
    "productTaxIncluded": "รวมภาษีสินค้าแล้ว",
    "additionalTax": "บวกภาษีในราคา",
    "vatRules": "กฎเกณฑ์ด้านภาษี",
    "vat": "ภาษีมูลค่าเพิ่ม",
    "selectAndAdd": "เลือกและเพิ่ม",
    "customerNotFoundDoYouWant": "ไม่พบลูกค้า คุณต้องการเพิ่มตอนนี้หรือไม่",
    "selectFromExistingCustomerList": "เลือกจากรายชื่อลูกค้าที่มีอยู่",
    "enterCustomerPhoneNumberToAdd": "ป้อนหมายเลขโทรศัพท์ของลูกค้าเพื่อเพิ่มลูกค้าหรือค้นหาลูกค้าที่มีอยู่",
    "addBuyer": "เพิ่มผู้ซื้อ",
    "unknown": "ไม่ทราบ",
    "afterDeduction": "หลังจากลดราคาแล้ว",
    "byPercentage": "ตามเปอร์เซ็นต์",
    "byAmount": "ตามจำนวน",
    "deductionMethod": "ลดราคาโดย",
    "deductionAmount": "จำนวนเงินที่หัก",
    "actualPayment": "ชำระเงินตามจริง",
    "pleaseAddProductsFirst": "กรุณาเพิ่มสินค้าก่อน",
    "youWantToClearYourCart": "คุณแน่ใจหรือไม่ว่าต้องการเคลียร์รถเข็นของคุณ?",
    "vatIncluded": "รวมภาษีมูลค่าเพิ่มแล้ว",
    "payable": "จำนวนที่เหลือที่ยังค้างอยู่",
    "productSubtotal": "ผลรวมย่อยของสินค้า",
    "viewDetails": "ดูรายละเอียด",
    "productDiscount": "ส่วนลดสินค้า",
    "wholeOrderReduction": "ส่วนลดในคำสั่งซื้อทั้งหมด",
    "youSureYouWantToDelete": "คุณแน่ใจหรือไม่ว่าต้องการลบ",
    "switchingStores": "สลับร้าน...",
    "wantToChangeTheStoreTo": "คุณแน่ใจหรือไม่ว่าต้องการเปลี่ยนร้านค้าเป็น {x}",
    "clearShoppingCart": "เคลียร์รถเข็น",
    "disc": "ส่วนลด",
    "totalXItems": "รวม {x} รายการ",
    "deduction": "ส่วนลด",
    "checkOut": "เช็คเอาท์",
    "submitOrder": "ส่งคำสั่งซื้อ",
    "productBarcode": "บาร์โค้ดสินค้า",
    "pleaseScanTheProductBarcode": "กรุณาสแกนบาร์โค้ดสินค้า",
    "cashier": "แคชเชียร์",
    "signalStrength": "ความแรงของสัญญาณ",
    "language": "ภาษา",
    "loginToTheStoreSuccessfully": "เข้าสู่ระบบร้านค้าสำเร็จ!",
    "pleaseSelectAStore": "กรุณาเลือกร้านค้า",
    "thereIsNoAccessibleStorePlease": "ไม่มีร้านค้าที่เข้าถึงได้ โปรดติดต่อผู้ดูแลระบบเพื่อเปิดใช้งาน",
    "partialRefund": "คืนเงินบางส่วน",
    "refunded": "คืนเงินแล้ว",
    "partialReturn": "การส่งคืนบางส่วน",
    "completedDone": "เสร็จสิ้น",
    "printReceipt": "พิมพ์ใบเสร็จรับเงิน",
    "noNeedToPrintReceipt": "ไม่จำเป็นต้องพิมพ์ใบเสร็จรับเงิน",
    "cashReceived": "จำนวนที่ได้รับ",
    "transactionAmount": "ยอดจำนวนธุรกรรม",
    "numberOfItems": "จำนวนรายการ",
    "customer": "ลูกค้า",
    "transactionTime": "เวลาทำธุรกรรม",
    "transactionSuccessful": "การทำธุรกรรมสำเร็จ!",
    "reorder": "สั่งซื้อใหม่",
    "theShoppingCartWillBeAutomatically": "ตะกร้าสินค้าจะถูกลบโดยอัตโนมัติ โปรดเลือกผลิตภัณฑ์อีกครั้งเพื่อสั่งซื้อ",
    "theStoreHasChangedItsWarehouse": "คลังสินค้าของร้านค้ามีการเปลี่ยนแปลงและไม่สามารถสั่งซื้อได้!",
    "continueToPlaceAnOrder": "ดำเนินการสั่งซื้อต่อไป",
    "removeOutOfStockProducts": "นำสินค้าที่หมดสต๊อกออก",
    "outOfStockQuantity": "จำนวนสินค้าหมด",
    "theFollowingProductsAreOutOf": "สินค้าต่อไปนี้หมดสต๊อก! การสั่งซื้ออย่างต่อเนื่องจะส่งผลให้มีการขายมากเกินไป",
    "card": "บัตรเครดิต",
    "returnFailedTheReturnedItemHas": "คืนสินค้าล้มเหลว! รายการที่ส่งคืนถูกลบแล้ว โปรดเลือกการคืนเงินเท่านั้นและส่งคืนสินค้าไปยังคลังสินค้าด้วยตนเอง",
    "returnFailedTheReturnWarehouseIs": "คืนสินค้าล้มเหลว! คลังสินค้าส่งคืนถูกปิดใช้งาน โปรดเลือกการคืนเงินเท่านั้นและส่งคืนสินค้าไปยังคลังสินค้าด้วยตนเอง",
    "refundToTheOriginalCard": "คืนเงินเข้าบัตรเครดิตเดิม",
    "refundMethod": "วิธีการคืนเงิน",
    "vatRefund": "การขอคืนภาษีมูลค่าเพิ่ม",
    "afterOperatingReturnTheStockThe": "หลังจากดำเนินการคืนสินค้าแล้ว สต็อกสินค้าที่ส่งคืนจะถูกเพิ่มกลับเข้าไปในคลังสินค้าทันที",
    "wantToReturn": "คุณแน่ใจหรือว่าต้องการคืนสินค้า?",
    "return": "คืนสินค้า",
    "completed": "เสร็จสิ้น",
    "cancelEntireOrder": "ยกเลิกคำสั่งซื้อทั้งหมด",
    "onceTheEntireOrderIsCancelled": "เมื่อยกเลิกคำสั่งซื้อทั้งหมดแล้ว คำสั่งซื้อจะได้รับเงินคืนเต็มจำนวนและสต็อกสินค้าในคำสั่งซื้อจะถูกเพิ่มกลับเข้าคลังสินค้าทันที",
    "cancelTheEntireOrder": "คุณแน่ใจหรือไม่ว่าต้องการยกเลิกคำสั่งซื้อทั้งหมด",
    "onlyRefundsWillOnlyAffectThe": "การคืนเงินเท่านั้นที่จะส่งผลต่อยอดสั่งซื้อการชำระเงินตามจริงเท่านั้นและจะไม่ส่งผลกระทบต่อสต็อกสินค้า",
    "youWantToRefund": "คุณแน่ใจหรือไม่ว่าต้องการคืนเงิน?",
    "orderActualPayment": "ยอดสั่งซื้อชำระเงินตามจริง",
    "amountExcludeVat": "จำนวนเงินไม่รวมภาษีมูลค่าเพิ่ม",
    "companyPhoneNumber": "หมายเลขโทรศัพท์บริษัท",
    "companymerchantTin": "หมายเลขประจำตัวผู้เสียภาษี บริษัท/ผู้ขาย",
    "companymerchantLegalAddress": "ที่อยู่ตามกฎหมายของบริษัท/ผู้ขาย",
    "companymerchantName": "ชื่อบริษัท/ผู้ขาย",
    "totalAmountPaid": "จำนวนเงินที่ชำระทั้งหมด",
    "addVatX": "เพิ่ม: ภาษีมูลค่าเพิ่ม ({x}%)",
    "amountDue": "จำนวนที่เหลือที่ยังค้างอยู่",
    "lessDiscounts": "หัก: ส่วนลด",
    "lessVatx": "หัก: ภาษีมูลค่าเพิ่ม({x}%)",
    "totalAmount": "จำนวนเงินทั้งหมด",
    "vatExemptSales": "ยอดขายที่ได้รับการยกเว้นภาษีมูลค่าเพิ่ม",
    "zeroratedSales": "ยอดขายเป็นศูนย์",
    "vatableSales": "ยอดขายมีภาษีมูลค่าเพิ่ม",
    "itemBig": "คำอธิบาย/รายละเอียด",
    "siNo": "เลขที่ใบแจ้งหนี้",
    "businessStyle": "ประเภทธุรกิจ",
    "tinNo": "หมายเลขประจำตัวผู้เสียภาษี",
    "soldTo": "ขายไป",
    "changes": "ทอน",
    "totalPaid": "ยอดทั้งหมดที่จ่าย",
    "totalOrder": "จำนวนสั่งซื้อทั้งหมด",
    "vatx": "VAT({x}%)",
    "totalDisc": "ยอดส่วนลดทั้งหมด",
    "totalItem": "ผลรวมย่อยของสินค้า",
    "cashierPerson": "แคชเชียร์",
    "orderNo": "หมายเลขคำสั่งซื้อ",
    "insufficientStockUnableToPurchaseProducts": "สต็อกสินค้าไม่เพียงพอไม่สามารถซื้อสินค้าได้",
    "isNotCorrectPleaseCheckPhone": "หมายเลขไม่ถูกต้อง โปรดตรวจสอบ",
    "mobileRangeLengthWithPrefix": "หมายเลขโทรศัพท์มือถือควรขึ้นต้นด้วย {prefix} และประกอบด้วยเลข {min} ถึง {max} หลัก",
    "mobileLengthWithPrefix": "หมายเลขโทรศัพท์มือถือควรเริ่มต้นด้วย {prefix} และประกอบด้วยตัวเลขหลัก {length}",
    "mobileRangeLength": "หมายเลขโทรศัพท์มือถือต้องเป็นเลข {min} ถึง {max} หลัก",
    "posSettings": "การตั้งค่า POS",
    "manageGineePosStore": "จัดการร้าน Ginee POS",
    "changingWarehousesWillReplaceTheProducts": "การเปลี่ยนคลังสินค้าจะเข้ามาแทนที่สินค้าใน ร้าน POS ด้วยสินค้าและสต็อกในคลังสินค้าใหม่ โปรดดำเนินการด้วยความระมัดระวัง",
    "taxCollectionPreference": "การตั้งค่าการเก็บภาษี",
    "orderTotalAfterRefund": "ยอดสั่งซื้อหลังการคืนเงิน",
    "productQuantity": "ปริมาณสินค้า",
    "selectReturnProduct": "เลือกคืนสินค้า",
    "receipt": "ใบเสร็จ",
    "newDiscountedPrice": "ราคาลดใหม่",
    "taxDiscountPreference": "การตั้งค่าส่วนลดภาษี",
    "pleaseEnterCorrectUsername": "กรุณากรอกชื่อผู้ใช้ที่ถูกต้อง",
    "orderPhoneNumber": "หมายเลขโทรศัพท์ของการสั่งซื้อ",
    "customerNam": "ชื่อลูกค้า",
    "theAmountDueCannotBeLess": "จำนวนเงินที่ได้รับต้องไม่ต่ำกว่าจำนวนเงินที่ต้องชำระ",
    "excessiveReturnIsNotSupported": "ไม่รองรับการส่งคืนที่มากเกินไป",
    "theStoreIsNotBoundTo": "ร้านค้าไม่ได้ถูกผูกไว้กับคลังสินค้า",
    "orderDoesNotExist": "ไม่มีคำสั่งซื้อที่อยู่",
    "theStoreDoesNotExist": "ไม่มีร้านค้าที่อยู่",
    "productBarcodeXDoesNotExist": "บาร์โค้ดสินค้า {x} ไม่มีอยู่ในคลังสินค้า",
    "notExistInTheWarehouse": "MSKU {x} ไม่มีอยู่ในคลังสินค้า",
    "addMskuToTheWarehouseFirst": "MSKU ไม่มีอยู่ในคลังสินค้าโปรดเพิ่ม MSKU ในคลังสินค้าก่อน",
    "pay": "จ่าย",
    "aOperationToCancelTheEntire": "{a} การดำเนินการเพื่อยกเลิกคำสั่งซื้อทั้งหมด หมายเหตุ {b}",
    "aOperationReturnAndRefundMsku": "{a} ดำเนินการการคืนและการคืนเงิน, MSKU {b}, ปริมาณ {c}, จำนวนเงินคืน {d}, จำนวนเงินคืน {e}, หมายเหตุ {f}",
    "aOperationRefundRefundAmountB": "{a} การดำเนินการการคืนเงิน จำนวนเงินคืน {b} จำนวนเงินคืน {c} หมายเหตุ {d}",
    "discountPriceCannotBeHigherThan": "ราคาส่วนลดต้องไม่สูงกว่าราคาเดิม",
    "supportsInputNumNoMore": "รองรับการป้อนตัวเลข/ตัวอักษร/ช่องว่าง/\"-\"/\"_\" เท่านั้น",
    "ooOrders": "คำสั่งซื้อ O2O",
    "ifYouDoNotHaveOms": "หากคุณไม่มีสิทธิ์เข้าถึงการจัดการลูกค้าใน OMS โปรดติดต่อผู้ดูแลระบบเพื่อเปิดใช้งาน",
    "totalVatInc": "TOTAL",
    "netDue": "ยอดรวมสุทธิ",
    "lessOtherDiscounts": "น้อยลง: ส่วนลดอื่น ๆ",
    "customerPhoneNo": "หมายเลขโทรศัพท์ลูกค้า",
    "doNotAllowLazadaEase": "ข้อ จำกัดของช่องทาง: ไม่อนุญาตให้อัปเดตสต็อกสำหรับสินค้าร้านค้าโหมด Lazada Ease",
    "doNotAllowTokoOO": "ข้อจำกัดของช่องทาง: ไม่อนุญาตให้อัปเดตสต็อกสำหรับสินค้าในคลังสินค้า Tokopedia O2O",
    "youHaveAddedAChannelSku": "คุณได้เพิ่มช่อง SKU ที่มีชื่ออื่น การเชื่อมสินค้าที่แตกต่างกันไปยัง MSKU อาจนำไปสู่การขายมากเกินไป โปรดยืนยันว่าช่อง SKU และ MSKU เป็นวินค้าเดียวกัน",
    "bindingSkuWithDifferentNamesMay": "การเชื่อม SKU ที่มีชื่อต่างกันอาจนำไปสู่ยอดขายมากเกินไป โปรดดำเนินการด้วยความระมัดระวัง",
    "channelReturnedErrorReachTheChannel": "{ช่องทาง} ข้อผิดพลาดที่ส่งคืน: ถึงข้อจำกัด ช่อง API QPS การผลักดันสต็อกล้มเหลว",
    "expiredStoresWillNotPushInventory": "ร้านค้าที่หมดอายุจะไม่ผลักดันสต็อก โปรดอนุมัติใหม่โดยเร็วที่สุด!",
    "massReleaseWillReleaseTheReserved": "การเปิดขายจำนวนมากจะปล่อยสต็อกโปรโมชั่นที่จองไว้ตามระดับสินค้า (รวมถึงรูปแบบทั้งหมดภายใต้สินค้าที่เลือก) โปรดดำเนินการด้วยความระมัดระวัง!",
    "recommendedOperation": "การดำเนินงานที่แนะนำ",
    "oversoldReasons": "เหตุผลจำนวนขายเกินจริง",
    "pleaseEnable": "กรุณาเปิดใช้งาน",
    "ThereAreCancellationreturnrefundOrdersOn": "3. มีคำสั่งซื้อที่ยกเลิก/คืน/คืนเงินในแต่ละช่องทาง ทำให้สต๊อกเพิ่มขึ้นอัตโนมัติ ลูกค้าสามารถสั่งซื้อได้ แต่สต๊อกใน Ginee ยังคงเป็น 0 ส่งผลให้มีการขายมากเกินไป",
    "PleaseCheckWhetherThereAre": "2.โปรดตรวจสอบว่ามีคำสั่งซื้อที่ยกเลิก/คืน/คืนเงินใน Seller Center หรือไม่ หลังจากอัพเดตสต็อกเป็น 0",
    "skuxAlreadyExistsInOmsAnd": "SKU{x} มีอยู่แล้วใน OMS และเป็นผลิตภัณฑ์ที่รวมกัน สินค้าผสมไม่ได้รับอนุญาตให้ออก/ขาเข้า!",
    "oneclickOversoldCheck": "เช็คขายเกิน",
    "massRelease": "ปลดออกเป็นกลุ่ม",
    "thisOperationWillReleaseTheReserved": "การดำเนินการจะทำให้สต็อกโปรโมชั่นทั้งหมดหายไป, โปรดดำเนินการอย่างระมัดระวัง",
    "imageSizeMustBeGreaterThan": "ขนาดรูปภาพล่าสุดต้องมากกว่าความสูง{x}，ความกว้าง{y}",
    "clickToUpdateBasicInformationSuch": "คลิกเพื่ออัปเดตข้อมูลพื้นฐาน เช่น แพ็คเกจ สิทธิ์ ฯลฯ",
    "clickRefreshToObtainTheLatest": "คลิกรีเฟรชเพื่อรับแพ็คเกจล่าสุด,สิทธิ์,คำขอเชื่อมต่อผู้ตราส่ง และข้อมูลอื่นๆ",
    "bySelectedProducts": "โดยสินค้าที่เลือก",
    "byStoreName": "โดยร้านค้า",
    "settingProductDescription": "สินค้าหลักและการตั้งค่าคัดลอก",
    "paymentReconciliation": "ยอดการชำระเงิน",
    "partialRelease": "เผยแพร่บางส่วน",
    "released": "เผยแพร่แล้ว",
    "toRelease": "ที่จะเผยแพร่",
    "marketplaceReleasedAmountForTheOrder": "ช่องทางตลาดจะเผยแพร่จำนวนเงินสำหรับการสั่งซื้อ",
    "orderPackageNumber": "หมายเลขคำสั่งซื้อแพ็คเกจ",
    "marketplaceReleasedAmountEstimatedRelease": "จำนวนส่วนต่าง = จำนวนที่ปล่อยของ ช่องทางตลาด - จำนวนการปล่อยโดยประมาณ",
    "afterTheOrderIsCompletedThe": "หลังจากคำสั่งซื้อเสร็จสมบูรณ์ ช่องทางตลาดจะเผยแพร่รายได้จากคำสั่งซื้อให้กับผู้ขาย",
    "settlementDetails": "รายละเอียดการชำระ",
    "amountDifference": "จำนวนส่วนต่าง",
    "marketplaceReleasedAmount": "จำนวนที่เผยแพร่ของช่องทางตลาด",
    "reconciliationStatusProvidedByTheChannel": "สถานะการกระทบยอดที่ระบุโดยช่องทางตลาด",
    "feesCharges": "ค่าธรรมเนียม",
    "voucherRebates": "บัตรกำนัลและส่วนลด",
    "totalExpenses": "ค่าใช้จ่ายทั้งหมด",
    "estimatedReleaseAmountTotalRevenue": "จำนวนการเผยแพร่โดยประมาณ = รายได้รวม - ค่าใช้จ่ายทั้งหมด",
    "estimatedReleaseAmount": "จำนวนการเผยแพร่โดยประมาณ",
    "timeoutPaymentIsNotSettledWithin": "หมดเวลา: การชำระเงินจะไม่ถูกชำระภายใน 24 ชั่วโมงหลังจากคำสั่งซื้อเสร็จสมบูรณ์",
    "settlementDataIsSyncedOnceA": "ข้อมูลการชำระเงินจะซิงค์วันละครั้ง แลบางคำสั่งซื้อที่อาจยังคงไม่มีการเผยแพร่",
    "xDigitsOfEnglishChineseNumbers": "{x} หลักภาษาอังกฤษ จีน ตัวเลข ช่องว่าง และ - _ & %",
    "pushSuccessnoShippingLabel": "ดันสำเร็จ(ไม่มีฉลากการจัดส่ง)",
    "pushSuccesshasShippingLabel": "ดันสำเร็จ(มีฉลากจัดส่ง)",
    "clickTheOkButtonToRecreate": "คลิกปุ่ม [ตกลง] เพื่อสร้างการขายขาออก ขึ้นใหม่ตามข้อมูลคำสั่งซื้อล่าสุด",
    "youSureYouWantToRecreate": "คุณแน่ใจหรือไม่ว่าต้องการสร้างการขายขาออกใหม่",
    "atchEditingOfBrandsInMultiple": "การแก้ไขแบรนด์เป็นชุดในร้านค้าหลายแห่งรองรับเฉพาะการเลือก \"No Brand\" เท่านั้น หากต้องการเลือกยี่ห้ออื่น กรุณาเลือกสินค้าจากร้านเดียวกัน",
    "editingOfBrandsInMultiple": "การแก้ไขแบรนด์เป็นชุดในร้านค้าหลายแห่งรองรับเฉพาะการเลือก \"No Brand\" เท่านั้น หากต้องการเลือกยี่ห้ออื่น กรุณาเลือกสินค้าจากร้านเดียวกัน",
    "masstRemark": "แก้ไข หมายเหตุ จำนวนมาก",
    "pushFailedKeeppackOutboundOrderStatus": "การพุชล้มเหลว Keeppack ไม่อนุญาตให้ดำเนินการนี้",
    "printingFailedTheOrderIsMissing": "การพิมพ์ล้มเหลว คำสั่งซื้อหายไป ฉลากการจัดส่ง PDF",
    "dearCustomer": "เรียนลูกค้า",
    "yourFollowingResourcesHaveExceededThe": "แพ็คเกจของคุณจำกัดการเชื่อมต่อบางรายการ กรุณาปรับการเชื่อมต่อหรืออัปเกรดแพ็คเกจเพื่อใช้งาน Ginee OMS ต่อไป",
    "selectXStoresToContinueBinding": "เลือกร้านค้า {x} ร้านที่ต้องการใช้งานต่อ และร้านค้าที่เหลือจะถูกปิดใช้งาน (หลังจากปิดใช้งาน จะไม่ส่งผลต่อยอดขายในร้าน แต่ Ginee OMS จะไม่แสดงสินค้า/คำสั่งซื้อของร้านค้าและข้อมูลอื่นๆ)",
    "selectedXStore": "เลือก {x} ร้านค้า",
    "staffAccount": "บัญชีพนักงาน",
    "selectXStaffAccountsToContinue": "เลือกบัญชีพนักงาน 0 บัญชีที่ต้องการใช้งานต่อ โดยบัญชีที่เหลือจะถูกลบออก (จะถูกลบเฉพาะบัญชีพนักงานของ Ginee OMS เท่านั้น ระบบ Ginee อื่นๆ จะไม่ถูกลบออก)",
    "onlySupportsUploadingPdfFilesMax": "รองรับการอัปโหลดไฟล์ PDF เท่านั้น สูงสุด 1 MB",
    "shippingLabelHasBeenUploaded": "มีการอัปโหลดฉลากการจัดส่ง",
    "uploadShippingLabel": "อัปโหลดป้ายกำกับการจัดส่ง",
    "shippingLabelPdf": "ฉลากการจัดส่ง PDF",
    "cancellationTime": "เวลาที่ยกเลิก",
    "shippingTime": "เวลาการจัดส่ง",
    "inventorySynchronizationSwitchTurnedOff": "ติดตามและเชื่อมต่อสต็อก : ปิด",
    "theFollowingFunctionsWillBeAutomatically": "ฟีเจอร์ดังกล่าวจะปิดการใช้งานโดยอัตโนมัติ และจำเป็นต้องกดเปิดใช้งานอีกครั้งหลังจากต่ออายุแพ็กเกจ",
    "afterThePackageExpiresStockSynchronization": "เมื่อแพ็กเกจหมดอายุ  การตั้งค่าเชื่อมต่อสต็อกจะถูกปิดโดยอัตโนมัติ",
    "lazadaPhOrdersOnlySupportPrinting": "คำสั่งซื้อที่เลือกรวมถึงคำสั่งซื้อจากเว็บไซต์ PH และรองรับเฉพาะการพิมพ์ใบแจ้งหนี้โดยใช้เทมเพลตของ Ginee",
    "companymerchantVatTinNo": "หมายเลขประจำตัวผู้เสียภาษี บริษัท/ผู้ขาย",
    "afterTheSwitchIsTurnedOn": "หลังจากเปิดใช้งานก่อนที่จะผลักดันรายการขาออกไปยังคลังสินค้าระบบจะดึงสต็อกล่าสุดของคลังสินค้าบุคคลที่สามที่ซิงค์ก่อน หากสต็อกคลังสินค้าไม่เพียงพอการผลักดันจะถูกยกเลิกคลังสินค้า OMS จะได้รับการอัปเดตและคำสั่งซื้อจะถูกย้ายไปยังคำสั่งซื้อที่มีปัญหา\nหลังจากปิดการใช้งาน รายการขาออกจะถูกผลักไปยังคลังสินค้าโดยตรงเมื่อเป็นไปตามเงื่อนไขการผลักดึนและระบบจะไม่ตรวจสอบว่าสต็อกในคลังสินค้าของบุคคลที่สามเพียงพอหรือไม่",
    "checkStockBeforePushing": "ตรวจสอบสต็อกก่อนผลักดัน",
    "theStrategyImplementedByTheWarehouse": "กลยุทธ์ที่ใช้สำหรับคลังสินค้าโปรดดูคำอธิบายกลยุทธ์สำหรับคำอธิบาย",
    "whenTheOrderMeetsThePush": "คำสั่งซื้อจะถูกผลักโดยอัตโนมัติเมื่อตรงตามเงื่อนไขการผลักดัน ไม่จำเป็นต้องผลักดันด้วยตนเอง",
    "shopeeApiRequiresThatAllVariation": "Shopee API กำหนดให้ไม่อนุญาตให้เปลี่ยนชื่อรูปแบบทั้งหมดเมื่อแก้ไขสินค้า (ต้องเก็บชื่อรูปแบบเดิมไว้อย่างน้อยหนึ่งชื่อ)",
    "sellerOwnFleet": "บริการจัดส่งสินค้าโดยร้านค้า",
    "addedToday": "เพิ่มวันนี้",
    "globalProduct": "สินค้าทั่วไป",
    "publishedSite": "เว็บไซต์ที่เผยแพร่แล้ว",
    "sellerStock": "สต๊อกของผู้ขาย",
    "globalProductPrice": "ราคาสินค้าทั่วไป",
    "verifyMskuBeforePushingYIs": "กรุณาตรวจสอบ SKU หลักก่อนทำการดันสต๊อก: {y} หมดสต็อกในคลังสินค้า WMS/คลังสินค้าภายนอกและการดันสต๊อกจะถูกหยุด",
    "theStockVerificationFailedBeforePushing": "การตรวจสอบสต็อกล้มเหลวก่อนการพุช และคำสั่งซื้อขาออกที่พุชถูกขัดขวาง เหตุผล: MSKU:{x} สินค้าหมดในคลังสินค้า WMS/บุคคลที่สาม",
    "enablePush": "เปิดใช้งานการดันสต็อก",
    "disablePush": "ปิดใช้งานการดันสต็อก",
    "changeToDisable": "เปลี่ยนเป็นการปิดใช้งาน",
    "changeToEnable": "เปลี่ยนเป็นการเปิดใช้งาน",
    "productNameAccountNumber": "ชื่อสินค้าและเลขบัญชี",
    "dueToShopeeApiLimitationsProducts": "เนื่องจากข้อจำกัดของ Shopee API สินค้าที่มีมากกว่า 50 รูปแบบ จึงไม่สามารถเผยแพร่หรือแก้ไขจาก Ginee ได้",
    "accurateWh": "คลังสินค้าที่แม่นยำ",
    "gineeWarehouse": "คลังสินค้าของ Ginee",
    "gineeWarehouseAddress": "ที่อยู่คลังสินค้าของ Ginee",
    "gineeWarehouseName": "ชื่อคลังสินค้าของ Ginee",
    "manageTheMappingRelationshipBetweenChannel": "จัดการความสัมพันธ์การผนวกระหว่างคลังสินค้าของช่องทางและคลังสินค้าของ Ginee",
    "youCanSelectTheChannelStore": "คุณสามารถเลือกร้านค้าช่องทางเพื่อดันสต๊อกไปยังร้านค้าที่เป็น CNSC/3PF รองรับการตั้งค่ากฎการดันสต็อกตามบัญชีของทางร้านเท่านั้น",
    "bindingStatus": "สถาณะการผูก",
    "channelWarehouseStatus": "สถานะคลังสินค้าของช่องทางการขาย",
    "thePartiallyReleasedListRefersTo": "รายการที่ถูกปล่อยบางส่วน หมายถึง คำสั่งซื้อของ Lazada ที่ประกอบด้วยสินค้าหลายชิ้น บางสินค้าได้ถูกปล่อยแล้ว และบางสินค้ายังไม่ได้ถูกปล่อย โดยข้อมูลจะถูกนำไปวางในสถานะนี้",
    "theReleasedListDisplaysTheReleased": "รายการที่เผยแพร่จะแสดงข้อมูลที่เผยแพร่ที่ได้รับจาก API",
    "customTax": "ภาษีนิเวศน์",
    "promotionFee": "ค่าธรรมเนียมโปรโมชั่น",
    "transactionFee": "ค่าธรรมเนียมการทำธุรกรรม",
    "highQuality": "คุณภาพสูง",
    "commissionF": "ค่าคอมมิชชั่น",
    "amsCommissionFee": "ค่าคอมมิชชั่น AMS",
    "reverseShippingFeeForReturnedOrders": "ค่าจัดส่งคืนสินค้า",
    "plShippingFeeDiscount": "ส่วนลดค่าจัดส่งจาก 3PL",
    "shopeeShippingRebate": "ส่วนลดการจัดส่งที่มาจากช้อปปี้",
    "sellerAbsorbedCoinCashback": "เงินคืน Coin ที่สนับสนุนโดยผู้ขาย",
    "productDiscountsAndCashbackByShopee": "ส่วนลดที่มาจากช้อปปี้",
    "productRefundAmount": "จำนวนเงินคืน",
    "productDiscountedPrice": "โปรโมชั่นสินค้าของทางร้าน",
    "productOriginalPrice": "ราคาตั้งต้น",
    "totalReleaseAmount": "ยอดเงินที่ปล่อยแล้วทั้งหมด",
    "settlementNo": "เลขที่การชำระบัญชี",
    "settlementTime": "ระยะเวลาการชำระบัญชี",
    "whetherTimeout": "หมดเวลาชั่วคราว",
    "releasing": "การปล่อย",
    "orderItem": "รายการสั่งซื้อ",
    "settlementDetailsAllPages": "รายละเอียดการชำระ - ทุกหน้า",
    "settlementDetailsBySelected": "รายละเอียดการชำระ - โดยเลือกไว้",
    "ordersAllPages": "รายการคำสั่งซื้อ - ทุกหน้า",
    "ordersBySelected": "รายการคำสั่งซื้อ - โดยเลือกไว้",
    "compatibleModels": "โมเดลที่สามารถใช้ได้",
    "noticeTitle": "หัวข้อ",
    "promotionStockCannotBeGreaterThan": "สต็อกโปรโมชั่นไม่สามารถมีจำนวนมากกว่าสต็อกที่มีอยู่",
    "pleaseEnterTheTitle": "กรุณากรอกชื่อเรื่อง",
    "theOutboundListHasBeenCompleted": "รายการขาออกเสร็จสมบูรณ์ที่คลังสินค้า Fulfillment และไม่สามารถผลักดันใหม่ได้",
    "commissionChargedBasedOnItemUnit": "ค่าคอมมิชชั่นที่เรียกเก็บตามราคาต่อหน่วยของรายการ (% เรียกเก็บจากราคาต่อหน่วย - ส่วนลดผู้ขาย)",
    "theOrderHasBeenCompletedBut": "คำสั่งซื้อเสร็จสมบูรณ์แล้ว แต่แพลตฟอร์มยังไม่ได้ชำระเงิน",
    "paymentFeeForProcessingCharges": "ค่าธรรมเนียมการชำระเงินสำหรับค่าธรรมเนียมการดำเนินการ (% เรียกเก็บจากราคาต่อหน่วย + ค่าธรรมเนียมการจัดส่งของผู้ซื้อ - บัตรกำนัลผู้ขาย)",
    "listedPriceOfTheItemSold": "ราคาที่ระบุไว้ของสินค้าที่ขายก่อนส่วนลดหรือการเรียกเก็บเงินจากผู้ซื้อ",
    "productVat": "ภาษีมูลค่าเพิ่มของสินค้า",
    "buyerRealName": "ชื่อจริงของผู้ซื้อ",
    "salesInvoiceWillUseTheSelected": "ใบแจ้งหนี้การขายจะใช้ข้อมูลลูกค้าคงที่ที่เลือกไว้เมื่อปิดใช้งาน เมื่อเปิดใช้งาน ใบแจ้งหนี้การขายจะใช้ข้อมูลลูกค้าจริงที่ได้รับจากใบสั่งช่องทาง โปรดทราบว่าข้อมูลลูกค้าในบางช่องทางจะถูกปกปิด ขอแนะนำให้ปิดการใช้งานคุณสมบัตินี้สำหรับร้านค้าที่ปกปิดข้อมูลลูกค้า",
    "realCustomerInformationInTheOrder": "ข้อมูลลูกค้าจริงในคำสั่งซื้อ",
    "excludingVat": "ไม่รวมภาษีมูลค่าเพิ่ม",
    "theSkuIsNotOversoldReason": "SKU ไม่ได้มีการขายมากเกินไป เหตุผล: เนื่องจาก TikTok Shop จะชะลอการปล่อยสต็อกหลังจากคำสั่งซื้อถูกยกเลิก Ginee จะชะลอการปล่อยสต็อกที่ล็อคไว้เป็นเวลา 12 ชั่วโมงหลังจากคำสั่งซื้อที่ยกเลิกซิงค์กับ Ginee (สถานะจะแสดงเป็น \"ปล่อย\") ปริมาณสต็อคที่ถูกล็อคกำลังถูกปล่อยออกมา ≥ ปริมาณสต็อคที่เป็นลบไม่ได้ถูกขายเกิน",
    "ifYouNeedToReleaseThe": "หากคุณต้องการปล่อยสต็อกที่ล็อคด้วยสถานะ \"ปล่อย\" ล่วงหน้า คุณสามารถทำให้รายการขาออกที่เกี่ยวข้องในการจัดการขาออกเป็นโมฆะได้ เพื่อบังคับให้ปล่อยสต็อกที่ถูกล็อค (มีความเสี่ยงที่จะขายเกิน โปรดดำเนินการด้วยความระมัดระวัง)",
    "publishedGlobalProduct": "เผยแพร่ผลิตภัณฑ์",
    "editingTheGlobalProductPriceWill": "การแก้ไขราคาสินค้าทั่วไปจะอัปเดตราคาของสินค้าร้านค้าตามสูตรการคำนวณ",
    "editingTheGlobalProductStockWill": "การแก้ไขสต็อกสินค้าทั่วไปจะอัปเดตสต็อกร้านค้ส",
    "pleaseNotePleaseUseMAuthorization": "โปรดทราบ: โปรดใช้การอนุญาต {m} สำหรับร้านค้าในพื้นที่ทั่วไป และ {u} การอนุญาตสำหรับร้านค้า CNSC/3PF",
    "theSkuIsOutOfStock": "SKU หมดใน WMS และไม่สามารถผลักได้",
    "theSkuDoesNotExistIn": "SKU ไม่มีอยู่ใน WMS และไม่สามารถผลักได้",
    "theOrdersOutboundListHasBeen": "รายการขาออกของคำสั่งซื้อเสร็จสมบูรณ์ใน WMS และไม่สามารถผลักดันได้",
    "wmsIsProcessingTheOrder": "WMS กำลังประมวลผลคำสั่ง",
    "authorizeMainAccount": "อนุญาตบัญชีหลัก",
    "authorizeShopAccount": "อนุญาตบัญชีร้านค้า",
    "customOrNo": "หมายเลข กำหนดเอง",
    "onlySupportsFilesPdfPngJpeg": "รองรับเฉพาะไฟล์ PDF, png, jpeg, JPG ขนาดน้อยกว่า 5M",
    "gineeWillAutomaticallyCreateAnInbound": "Ginee จะสร้างรายการขาเข้าโดยอัตโนมัติตามปริมาณขาเข้าที่ป้อน เติมรายการขาเข้าให้สมบูรณ์โดยตรง และเพิ่มสต็อกคลังสินค้า โปรดยืนยัน!",
    "youWantToAddToThe": "คุณแน่ใจหรือไม่ว่าต้องการเพิ่มไปยังคลังสินค้าและขาเข้าโดยตรง",
    "feeRefundreturn": "การขอคืนเงิน/การคืน ค่าธรรมเนียม",
    "fulfillmentFee": "ค่าธรรมเนียมFulfillment",
    "shippingFeeChargedToSeller": "ค่าจัดส่งที่เรียกเก็บจากผู้ขาย",
    "otherFee": "ค่าธรรมเนียมอื่นๆ",
    "serviceFee1": "ค่าบริการ",
    "rebateFromMarketplace": "ส่วนลดจากช่องทางการขาย",
    "sellerPromotion": "โปรโมชั่นผู้ขาย",
    "productDiscountPrice": "ราคาส่วนลดสินค้า",
    "pleaseRenewOrPurchaseOrderQuantity": "โปรดต่ออายุหรือซื้อเพิ่มปริมาณคำสั่งซื้อโดยเร็วที่สุดมิฉะนั้นคุณจะไม่สามารถใช้ฟังก์ชั่นที่เกี่ยวข้องกับคำสั่งซื้อได้!",
    "yourGineeWmsOrderQuotaHas": "เกินโควต้าคำสั่งซื้อ Ginee WMS ของคุณแล้ว!",
    "yourGineeOmsOrderQuotaHas": "เกินโควต้าคำสั่งซื้อ Ginee OMS ของคุณแล้ว!",
    "orderQuotaExceeded": "เกินโควต้าคำสั่งซื้อ",
    "insufficientOrderQuota": "โควต้าคำสั่งซื้อ <10%",
    "theOrderQuotaOfYourCurrent": "โปรดต่ออายุหรือซื้อโควต้าคำสั่งซื้อเพิ่มโดยเร็วที่สุดมิฉะนั้นคุณจะไม่สามารถใช้ฟังก์ชั่นที่เกี่ยวข้องกับการจัดการขาออกได้!",
    "bySelectingAllLazadaWillConsider": "การเลือกทั้งหมด (ALL) จะทำให้ Lazada ต้องการที่จะพิจารณาเป็นการรวมทุกตัวเลือกด้านล่างโดยไม่จำเป็นต้องทำเครื่องหมายในแต่ละส่วนย่อย",
    "abnormalExpiration": "หมดอายุแบบผิดปกติ",
    "pushOutboundOrders": "ผลักดันรายการขาออก",
    "pushInboundOrders": "ผลักดันรายการขาเข้า",
    "directInbound": "เข้าคลังโดยตรง",
    "updatedToBePushed": "(อัปเดต) ที่จะถูกผลัก",
    "addressNameAndPhoneNumberWill": "ที่อยู่ ชื่อ และหมายเลขโทรศัพท์จะถูกใช้สำหรับข้อมูลผู้ส่งในฉลากการจัดส่งที่กำหนดเองของ Ginee",
    "syncChannelAddress": "ซิงค์ที่อยู่ช่องทาง",
    "withholdingTax": "ภาษีหัก ณ ที่จ่าย",
    "theCnscpfsChannelWarehouseIsNot": "คลังสินค้าของช่องทาง CNSC/3PF ไม่ผูกกับคลังสินค้า Ginee ดังนั้นจึงไม่สามารถสร้างรายการขาออก",
    "afterSettingTheShippingMethodWhen": "หลังจากตั้งค่าวิธีการจัดส่งเมื่อจัดการการจัดส่งใน Ginee วิธีการจัดส่งและที่อยู่จะถูกเลือกโดยอัตโนมัติเพื่อปรับปรุงประสิทธิภาพการจัดส่ง",
    "theOutboundOrderIsInvalidIn": "คำสั่งซื้อขาออกไม่ถูกต้องใน WMS และไม่สามารถผลักได้อีก",
    "progress": "ความคืบหน้า",
    "setShippingInfo": "ตั้งค่าข้อมูลการจัดส่ง",
    "outboundOrderHasBeenSuccessfullyPushed": "คำสั่งซื้อขาออกถูกผลักไปยังคลังสินค้าเพื่อดำเนินการเรียบร้อยแล้ว และคุณไม่สามารถแก้ไขข้อมูลคำสั่งซื้อขาออกได้ โปรดทำขาออกด้วยตนเองตามข้อมูลการเปลี่ยนแปลงคำสั่งซื้อด้วยตนเอง",
    "mStoreAbnormallyExpired": "ร้านค้าหมดอายุผิดปกติ",
    "oneClickToChangeTheStores": "คลิกเดียวเพื่อเปลี่ยนที่อยู่รับสินค้าของร้านค้า",
    "changeAddressByStore": "เปลี่ยนที่อยู่ร้านค้า",
    "youCanQuicklyChangePickupAddress": "คุณสามารถเปลี่ยนที่อยู่รับสินค้าสำหรับขนส่งทั้งหมดได้อย่างรวดเร็วในร้าน Shopee ไปยังที่อยู่อื่น",
    "invalidTrackingNumberPleaseGoTo": "หมายเลขติดตามไม่ถูกต้องโปรดไปที่ศูนย์ผู้ขายเพื่อส่งหมายเลขติดตามที่ถูกต้องอีกครั้งโดยเร็วที่สุด!",
    "invalidTrackingNumberawb": "หมายเลขติดตามไม่ถูกต้อง / AWB",
    "channelAbnormalOrders": "ช่องคำสั่งซื้อผิดปกติ",
    "tryNoww": "ทดลองเลย",
    "congratulationsYouHaveReceivedADay": "ยินดีด้วย! คุณได้รับการทดลองใช้ฟีเจอร์เต็มรูปแบบฟรี 7 วัน",
    "mskuTotal": "ปริมาณ MSKU ทั้งหมด",
    "updateUsageEveryHours": "อัปเดตการใช้งานทุก 4 ชั่วโมง",
    "dailyUpdateUsage": "อัปเดตการใช้งานรายวัน",
    "replacementSuccessful": "แทนที่สำเร็จ!",
    "theFollowingOrdersNeedToAdd": "คำสั่งซื้อต่อไปนี้จำเป็นต้องเพิ่มข้อมูลการจัดส่งก่อนจัดการการจัดส่ง",
    "toEnsureNormalUse": "เพื่อให้แน่ใจว่าใช้งานได้ตามปกติ โปรดต่ออายุให้ตรงเวลา",
    "invoiceNumberMustBeDigits": "หมายเลขใบแจ้งหนี้ต้องเป็น 8 หลัก",
    "companymerchantInformation": "ข้อมูลบริษัท/ผู้ขาย",
    "orDate": "OR Date",
    "orNo": "OR No",
    "pleaseEnterTheWarehouseCodeNo": "กรุณากรอกรหัสคลังสินค้า ไม่เกิน 50 ตัวอักษร",
    "failedToPrintInvoice": "ไม่สามารถพิมพ์ใบแจ้งหนี้",
    "thatIsOfficialReceiptNumberYou": "หมายเลขใบเสร็จทางการ (Official Receipt Number) สามารถตั้งค่าได้โดยใช้ prefix สูงสุด 3 ตัวอักษร (สามารถปล่อยว่างไว้) + ตามด้วยตัวเลข 8 หลัก (จำเป็นต้องมี 8 หลัก) เพื่อกำหนดหมายเลขใบเสร็จที่แตกต่างกันในแต่ละครั้งที่พิมพ์ โปรดทราบว่าหลังจากแก้ไขแล้วจะต้องพิมพ์ตามกฎการปรับปรุงล่าสุด ดังนั้นโปรดแก้ไขอย่างระมัดระวัง",
    "theCompanyInformationSetInGinee": "ข้อมูลบริษัทที่ตั้งไว้ใน Ginee Accounts จะถูกแสดงผล",
    "setCompanyInformation": "ตั้งค่าข้อมูลบริษัท",
    "atTheRequestOfThePhilippine": "ตามคำขอจากรัฐบาลฟิลิปปินส์ ใบแจ้งหนี้ทุกใบของร้านค้าในฟิลิปปินส์จะต้องใช้แม่แบบใบแจ้งหนี้ของฟิลิปปินส์ สามารถเลือกที่จะรวมภาษี (VAT) ได้",
    "orderDate": "วันที่จัดทำคำสั่งซื้อ",
    "thisReceiptShallBeValidFor": "ใบเสร็จนี้จะสามารถใช้ได้ถึงห้า (5) ปี นับจากวันที่รับรองใบเสร็จนี้",
    "acknowledgementCertificateNo": "Acknowledgement Certificate No",
    "dateIssued": "วันที่ออก",
    "seriesRange": "ช่วงชุด",
    "handleProb": "จัดการปัญหา",
    "checksFailed": "มีบางอย่างผิดพลาด",
    "successfullyDeletedTheWarehouse": "ลบคลังสินค้าเรียบร้อย!",
    "startDeletion": "กำลังเริ่มลบ",
    "checksPassed": "ทุกอย่างผ่านแล้ว!",
    "inventoryClearanceAllProductStocksAre": "การเคลียร์สต็อกคลังสินค้า: สต็อกสินค้าทั้งหมดเป็น 0 แล้ว",
    "manualInboundListAllDocumentsHave": "รายการรับเข้าแบบทำด้วยตัวเอง: เอกสารทั้งหมดเสร็จสิ้นแล้ว",
    "purchaseInboundListAllDocumentsHave": "รายการขาเข้า: เอกสารทั้งหมดได้รับการทำเสร็จสิ้นแล้ว",
    "manualOutboundListAllDocumentsHave": "รายการส่งออกแบบทำด้วยตัวเอง: เอกสารทั้งหมดได้รับการทำเสร็จสิ้นแล้ว",
    "salesOutboundListAllDocumentsHave": "รายการส่งออกขาย: เอกสารทั้งหมดเสร็จสิ้นแล้ว",
    "deletingAWarehouseRequiresCheckingThe": "การลบคลังสินค้าจำเป็นต้องตรวจสอบข้อมูลในคลังสินค้า ทำรายการรับเข้า/ส่งออกทั้งหมดให้เสร็จสิ้น และเคลียร์สต็อก SKU ทั้งหมดก่อนการลบ",
    "checkingWarehouseDeletion": "กำลังตรวจสอบการลบคลังสินค้า",
    "beforeDeletingAWarehouseYouNeed": "ก่อนลบคลังสินค้า คุณจำเป็นต้องทำรายการรับเข้า/ส่งออกทั้งหมดให้เสร็จสิ้นและเคลียร์สต็อก SKU ทั้งหมด",
    "oneAreYouSureYouWantTo": "คุณแน่ใจหรือว่าต้องการลบคลังสินค้านี้?",
    "theNumberOfLocalWarehouseHas": "จำนวนคลังสินค้าในพื้นที่ถึงขีดจำกัดแล้ว (สูงสุด 50 แห่ง) กรุณาลบคลังสินค้าที่เกินออกแล้วลองใหม่อีกครั้ง!",
    "oneFailedToSaveTheNumberOf": "การบันทึกล้มเหลว! จำนวน SKU ของสินค้าช่องทางการขายที่เชื่อมโยงกับสินค้ารวมถึงขีดจำกัดแล้ว (สูงสุด 500 รายการ)",
    "failedToSaveTheNumberOf": "การบันทึกล้มเหลว! จำนวนสินค้าที่เพิ่มเข้าไปในสินค้ารวมถึงขีดจำกัดแล้ว (สูงสุด 10 รายการ)",
    "failedToSaveTheBoundCombined": "การบันทึกล้มเหลว! สินค้ารวมที่เชื่อมต่อเป็นสินค้าเดี่ยว {x} ถึงขีดจำกัดแล้ว (สามารถเชื่อมต่อกับสินค้ารวมกันได้สูงสุด 100 รายการ)",
    "failedToSaveTheVariationsOf": "การบันทึกล้มเหลว! จำนวนรูปแบบของสินค้าหลักถึงขีดจำกัดแล้ว (สูงสุด 200)",
    "bindingFailedTheChannelSkuBound": "การเชื่อมต่อล้มเหลว! จำนวน SKU ช่องทางที่เชื่อมต่อกับ MSKU ถึงขีดจำกัดแล้ว (สูงสุด 500)",
    "deletingWarehouse": "กำลังลบคลัง...",
    "deletingWarehouseProducts": "กำลังลบสินค้าคงคลัง...",
    "deletingMIO": "กำลังลบรายการขาเข้าที่ดำเนินการเอง...",
    "deletingPIO": "กำลังลบรายการสินค้าขาเข้า...",
    "deletingMOO": "กำลังลบรายการขาออกที่ดำเนินการเอง...",
    "deletingSOO": "กำลังลบรายการการขายขาออก...",
    "none": "ไม่มี",
    "InboundStockIsOnlyValid": "1. สินค้าคงคลังขาเข้าใช้ได้เฉพาะสำหรับการเพิ่มคลังสินค้าและขาเข้า ข้อมูลสินค้าคงคลังขาเข้าจะถูกนำเข้าไปยังคลังสินค้า 2. สามารถเพิ่มสินค้ากลุ่มได้ในคลังสินค้าเท่านั้น และไม่รองรับนำสินค้ากลุ่มเข้าคลัง 3. สามารถลบสินค้าได้ทันที โดยไม่จำเป็นต้องเชื่อมสินค้า 4. หลังจากส่งข้อมูลแล้ว หากสินค้ามีอยู่ในคลังสินค้าแล้ว ระบบจะกรองโดยอัตโนมัติ ดังนั้นสินค้าจะไม่ถูกนำเข้าอีกครั้ง",
    "differentWeightdimensionsForVariations": "ตัวเลือกต่างๆ มีน้ำหนัก/ขนาดที่แตกต่างกัน (น้ำหนัก/ขนาดที่แตกต่างกัน)",
    "becauseAllVariationsHaveExactlyThe": "เนื่องจากตัวเลือกทั้งหมดมีน้ำหนักและขนาดเท่ากัน ระบบจะช่วยคุณสลับไปใช้ระดับน้ำหนักและขนาดของสินค้าโดยอัตโนมัติ",
    "mskuXWarehouseNameOrWarehouse": "ไม่มี MSKU {0} ชื่อคลังสินค้า หรือรหัสคลังสินค้า",
    "mskuDoNotExist": "MSKU {0} ไม่มีอยู่ในคลังสินค้า {1} โปรดเพิ่ม SKU ลงในคลังสินค้าก่อน",
    "masterProductXDoesNotExist": "ไม่มีสินค้าหลัก {x}",
    "theOutboundListHasBeenPushed": "รายการขาออกได้รับการผลักดัน และไม่ได้รับอนุญาตให้แก้ไขคำสั่งซื้ออีกต่อไป",
    "orderXStatusDoesNotAllow": "สถานะคำสั่งซื้อ {0} ไม่อนุญาตให้แก้ไข",
    "orderXDoesNotExist": "ไม่มีคำสั่งซื้อ {0}",
    "thePaymentAmountShouldBeLess": "จำนวนเงินที่ชำระควรน้อยกว่ายอดรวมคำสั่งซื้อ โปรดตรวจสอบ",
    "theTotalAmountOfProductsExceeds": "จำนวนรวมของ\nสินค้าเกินขีดจำกัด",
    "theOrderNoIsDuplicatedPlease": "หมายเลขคำสั่งซื้อซ้ำ โปรดแก้ไขหมายเลขคำสั่งซื้อ",
    "theStoreCannotBeEmpty": "ร้านค้าไม่สามารถเว้นว่างได้",
    "channelRequirementsXMustBeBetween": "ข้อกำหนดของช่องทาง {x} ต้องอยู่ระหว่าง {y}~{z}",
    "itemCount": "จำนวนรายการ",
    "variationOptionLength": "ความยาวตัวเลือก",
    "variationTypeLength": "ความยาวประเภท",
    "itemDescriptionLength": "ความยาวรายละเอียดสินค้า",
    "itemImageCount": "จำนวนรูปภาพของรายการ",
    "itemNameLength": "ความยาวชื่อรายการ",
    "wholesalePriceThresholdPercentage": "เปอร์เซ็นต์เกณฑ์ราคาขายส่ง",
    "sinceSomeProductsHaveTheSame": "เนื่องจากสินค้าบางรายการมีน้ำหนักและขนาดเท่ากันสำหรับทุกตัวเลือก ระบบจะช่วยคุณสลับไปใช้ระดับน้ำหนักและขนาดของสินค้าโดยอัตโนมัติ",
    "toEnableDifferentWeightdimensionsForEach": "หากต้องการเปิดใช้งานน้ำหนัก/ขนาดที่แตกต่างกันสำหรับแต่ละตัวเลือก โปรดไปที่หน้าการแก้ไขสินค้าเดี่ยวของ Shopee และเปิดการใช้งานตัวเลือก \"น้ำหนัก/ขนาดที่แตกต่างกัน\"",
    "mskuHasBeenInbounded": "MSKU ได้ถูกนำเข้า",
    "exportFailed": "นำออก ล้มเหลว",
    "staffToRemovePermissions": "พนักงานที่จะลบการอนุญาต:",
    "staffsToRetainPermissions": "พนักงานที่จะคงสิทธิ์ไว้:",
    "storesToDeactivate": "ร้านค้าที่จะปิดใช้งาน:",
    "storesToRetain": "ร้านค้าที่จะเก็บรักษา:",
    "staffAccountsToContinueUsing": "บัญชีพนักงานเพื่อใช้งานต่อไป:",
    "storesToContinueBinding": "ร้านค้าที่ผูกต่อไป:",
    "pleaseConfirmTheStoreOrStaff": "โปรดยืนยันร้านค้าหรือพนักงานที่คุณต้องการเก็บไว้",
    "theDefaultPickupTimeLatest": "เริ่มต้นเวลารับเป็นล่าสุด",
    "theDefaultPickupTimeEarliest": "เริ่มต้นเวลารับเร็วที่สุด",
    "doNotAutomaticallySelectThePickup": "อย่าเลือกเวลารับสินค้าโดยอัตโนมัติ",
    "setTo": "ตั้งค่าเป็น:",
    "onlyApplicableToOrdersWithPickup": "ใช้ได้กับคำสั่งซื้อที่มีบริการรับสินค้าบน Shopee และ TikTok เท่านั้น",
    "automaticallySelectThePickupTime": "เลือกเวลารับสินค้าโดยอัตโนมัติ",
    "whenYouSelectLatest": "เมื่อคุณเลือกตัวเลือกนี้ เวลารับสินค้าล่าสุดที่มีอยู่จะถูกกรอกโดยอัตโนมัติในหน้าการจัดส่ง",
    "whenYouSelectEarliest": "เมื่อคุณเลือกตัวเลือกนี้ เวลารับสินค้าที่เร็วที่สุดที่มีอยู่จะถูกกรอกโดยอัตโนมัติในหน้าการจัดส่ง",
    "thisOrderIsFromAMultiwarehouse": "คำสั่งซื้อนี้มาจากร้านค้าหลายคลังสินค้า การเปลี่ยนที่อยู่อาจมีค่าใช้จ่ายในการจัดส่งเพิ่มเติม คุณแน่ใจหรือว่าต้องการอัปเดต?",
    "noManualSyncTask": "ไม่มีงานซิงค์แบบแมนนวล",
    "taskIds": "ID งาน",
    "manualSyncProduct": "ซิงค์สินค้าแบบแมนนวล",
    "manualSyncOrder": "ซิงค์คำสั่งซื้อแบบแมนนวล",
    "syncHistoricalOrders": "ซิงค์คำสั่งซื้อย้อนหลัง",
    "sinceTiktokShopWillDelayReleasing": "นื่องจาก TikTok Shop จะชะลอการปล่อยสต็อกหลังจากคำสั่งซื้อถูกยกเลิก Ginee จะชะลอการปล่อยสต็อกที่ล็อคไว้เป็นเวลา 12 ชั่วโมงหลังจากคำสั่งซื้อที่ยกเลิกซิงค์กับ Ginee (สถานะจะแสดงเป็น \"ปล่อย\")",
    "syncProgress": "ความคืบหน้า",
    "notAbnormal": "ไม่ใช่คำสั่งซื้อผิดปกติ",
    "reservationFailedTheBoundGineeWarehouse": "การจองล้มเหลวไม่พบคลังสินค้า Ginee ที่ถูกผูกไว้ (ร้านค้า Shopee CNSC / 3PF สำรองสต็อกโปรโมชั่นตามเงื่อนไขที่มีผลระหว่างคลังสินค้าร้านค้าและคลังสินค้า Ginee)",
    "discountType": "ประเภทลดราคา",
    "percentageOff": "ส่วนลดเปอร์เซ็นต์",
    "fixedPrice": "ราคาคงที่",
    "variationlevel": "ระดับการเปลี่ยนแปลง",
    "productlevel": "ระดับสินค้า",
    "youWantToSwitchTheDatabase": "คุณแน่ใจหรือไม่ว่าต้องการเปลี่ยนฐานข้อมูลเป็น {x}",
    "allSettingsAndMappingsWillBe": "การตั้งค่าและการจับคู่ทั้งหมดจะถูกลบและจะต้องจับคู่ MSKU อีกครั้ง",
    "changeDatabase": "สลับฐานข้อมูล",
    "pleaseSelectTheDatabaseToChange": "กรุณาเลือกฐานข้อมูลที่ต้องการสลับไป",
    "ifYouSwitchToANew": "หากคุณเปลี่ยนไปใช้ฐานข้อมูลใหม่ การตั้งค่าและการจับคู่ทั้งหมดจะต้องได้รับการกำหนดค่าใหม่ แต่ไม่ต้องกังวล ข้อมูลที่ถูกส่งไปยัง Accurate จะไม่ถูกลบ",
    "mappingS": "กำลังจับคู่",
    "channelStoreName": "ชื่อช่องทางร้านค้า",
    "customersRealName": "ชื่อจริงลูกค้า",
    "productTaxSettings": "ตั้งค่าภาษีสินค้า",
    "whetherTheProductWillIncludeVat": "สินค้าจะรวมภาษีมูลค่าเพิ่มในใบกำกับสินค้า",
    "noVat": "ไม่มีภาษีมูลค่าเพิ่ม",
    "addVat": "เพิ่มภาษีมูลค่าเพิ่ม",
    "gineeWarehouseCanOnlyBeMapped": "คลังสินค้า Ginee สามารถทำการจับคู่ได้กับคลังสินค้า Accurate 1 แห่งเท่านั้น กรุณาทำการจับคู่คลังสินค้าให้เสร็จอย่างน้อย 1 แห่ง",
    "dataLinkedWithBranchesBanksAnd": "ข้อมูลได้เชื่อมโยงกับสาขา ธนาคาร และ COA เมื่อส่งธุรกรรมคำสั่งซื้อไปยัง Accurate",
    "shippingFeeChargedToSellersCalculated": "ค่าธรรมเนียมการจัดส่งที่เรียกเก็บจากผู้ขายคำนวณตามรายละเอียดการชำระเงินของช่องทางตลาด",
    "otherDeductedFeesProvidedByThe": "ค่าธรรมเนียมหักลดหย่อนอื่น ๆ ตามที่ระบุไว้ในรายละเอียดการชำระเงินของช่องทางตลาด",
    "rebateAndDiscountAmountAsProvided": "จำนวนส่วนลดและส่วนลดตามที่ระบุไว้ในรายละเอียดการชำระเงินของช่องทางตลาด",
    "sellerPromotionFeeAsProvided": "ค่าธรรมเนียมการส่งเสริมการขายของผู้ขายตามที่ระบุไว้ในรายละเอียดการชำระเงินของช่องทางตลาด",
    "serviceFeeCommissionFeeAndOther": "ค่าธรรมเนียมบริการ ค่าคอมมิชชัน และค่าธรรมเนียมอื่นๆ ที่หักจากช่องทางตลาดตามที่ระบุไว้ในรายละเอียดการชำระเงินของช่องทางตลาด",
    "importantNotesErrorNotificationTheCumulative": "หมายเหตุสำคัญ: การแจ้งเตือนข้อผิดพลาด \"ช่วงเวลาการสั่งซื้อสะสมไม่สามารถเกิน 18 เดือน\" เมื่อซื้อ Lazada-Authorization-only หมายความว่าคุณได้ซื้อ Authorization-only เป็นเวลา 18 เดือนแล้ว โปรด {x} แล้วคลิก ใช้บริการ เพื่อเสร็จสิ้นการเชื่อมต่อ",
    "historicalOrdersSettlementDataWillNot": "ข้อมูลการชำระเงินของคำสั่งซื้อในอดีตจะไม่ถูกซิงค์",
    "variousServiceCharges": "ค่าบริการต่างๆ",
    "thePromotionPeriodMustBeLonger": "ระยะเวลาโปรโมชั่นต้องยาวนานกว่า 10 นาที และสั้นกว่า 365 วัน",
    "purchaseLimitCannotBeGreaterThan": "การซื้อจำกัดไม่สามารถมากกว่าสต๊อกที่มี",
    "fulfilledByChannel": "ดำเนินการโดย ช่องทาง",
    "jitPo": "JIT PO",
    "advanceFulfillment": "ดำเนินการล่วงหน้า",
    "notSpecial": "ไม่พิเศษเจาะจง",
    "createdByShopee": "สร้างโดย Shopee",
    "matchPending": "กำลังรอการจับคู่",
    "discountPriceIsLowerThanM": "ราคาส่วนลดต่ำกว่า {m}",
    "theDiscountRateMustNotbeHigher": "อัตราส่วนลดต้องไม่สูงกว่า {m}%",
    "limitationMethod": "วิธีการจำกัด",
    "variationDimension": "มิติการเปลี่ยนแปลง",
    "noLimit": "ไม่จำกัด",
    "invalidDiscount": "ส่วนลดไม่ถูกต้อง",
    "invalidPrice": "ราคาไม่ถูกต้อง",
    "TheStartTimeMustBe": "1. เวลาเริ่มต้นต้องช้ากว่าเวลาปัจจุบัน",
    "ThePromotionPeriodMustBe": "2. ระยะเวลาโปรโมชั่นต้องยาวนานกว่า 10 นาที และสั้นกว่า 365 วัน",
    "productId": "ID สินค้า",
    "variationId": "ID ตัวแปร",
    "thePromotionsPeriodMustBeLonger": "ระยะเวลาโปรโมชั่นต้องนานกว่า 1 ชั่วโมง",
    "thePromotionuPeriodMustBeLonger": "ระยะเวลาโปรโมชั่นต้องนานกว่า 10 นาทีและสั้นกว่า 365 วัน",
    "unavailableStockIntransit": "สต็อกไม่พร้อมใช้งาน (อยู่ในระหว่างการขนส่ง)",
    "stockTransferredToSortingCenterBy": "สต็อกถูกโอนไปยังศูนย์คัดแยกโดย Shopee ล่วงหน้า",
    "advanceStock": "สต็อกล่วงหน้า",
    "outboundQuantityMustBe": "จำนวนสินค้าขาออกต้องมากกว่า 0",
    "needHelpWereHereForYou": "Need help? We're here for you. Contact us M-F, 9AM-6PM",
    "orderXIsMatchedWithShopee": "คำสั่งซื้อ [{0}] จับคู่กับ Shopee Advance Fulfillment Order [{1}] คำสั่งซื้อจะถูกรวมเข้าด้วยกัน",
    "bookingId": "รหัสการจอง",
    "availStock": "สต็อกที่มีอยู่",
    "notMatchedToOrder": "ไม่ตรงกับคำสั่งซื้อ",
    "importToCreateCombinedProduct": "นำเข้าเพื่อสร้างสินค้าที่เปนเซต",
    "youCanImportUpTo": "คุณสามารถนำเข้าข้อมูลได้สูงสุด 10,000 รายการในครั้งเดียว (รูปแบบ: xlsx, xls) เพื่อสร้างสินค้าเป็นเซตจำนวนมาก",
    "matched": "จับคู่",
    "marketplaceSpecialOrder": "คำสั่งซื้อพิเศษจากช่องทางการ"
};
});

var vi = createCommonjsModule(function (module, exports) {
exports.__esModule = true;
exports.vi = void 0;
exports.vi = {
    "globalNoData": "Không có Dữ liệu",
    "globalPleaseSelect": "Vui lòng Chọn",
    "globalPleaseEnter": "Vui lòng Nhập",
    "globalsYearly": "Hàng năm",
    "globalsMonthly": "Hàng tháng",
    "globalsWeekly": "Hàng tuần",
    "globalsYesterday": "Hôm qua",
    "globalsExportDisabled": "Hết hạn",
    "globalsExportFailed": "Tải xuống thất bại",
    "globalsExportFinished": "Hoạt động",
    "globalsExportINProcess": "Đang xử lý",
    "globalsExportStatus": "Trạng thái",
    "globalsPackageExpired": "Gói của bạn đã hết hạn",
    "globalsLater": "Để sau",
    "globalsContactNow": "Liên hệ ngay",
    "globalsContactUsPackage": "Vui lòng liên hệ với chúng tôi để biết thông tin và báo giá",
    "isRequired": "Dòng này là bắt buộc",
    "globalsGoContinue": "Tiếp tục",
    "globalsBrowserBlockedInfo": "Phát hiện cửa sổ bật lên mới đã bị trình duyệt chặn, vui lòng nhấp vào \"Tiếp tục\" để mở cửa sổ trang mới (bạn cũng có thể tìm kiếm \"Phương pháp Xóa chặn trình duyệt\" trong Trung tâm trợ giúp để bỏ chặn)",
    "globalsNotification": "Thông báo",
    "globalsPleaseSelect": "Vui lòng Chọn",
    "globalsIsRequired": "Dòng này là bắt buộc",
    "globalsOperationGuide": "Hướng dẫn thao tác",
    "globalsSyncError": "Đồng bộ Thất bại",
    "globalsSynchronizing": "Đang đồng bộ",
    "globalsSync": "Đồng bộ",
    "globalsSyncSuccess": "Hoàn thành Đồng bộ",
    "globalsNoProductYet": "Chưa có sản phẩm",
    "globalsSeeMore": "Xem { more } sản phẩm",
    "globalsSeeMoreRetract": "Thu gọn",
    "globalsSeeMoreExpand": "Xem thêm",
    "globalsNoName": "Không tên",
    "globalsTotalPage": "Tổng { total }",
    "globalsReset": "Đặt lại",
    "globalsSearch": "Tìm kiếm",
    "globalNoEmptyText": "Không có Dữ liệu",
    "globalNoPermission": "Tài khoản của bạn không có quyền truy cập, vui lòng liên hệ với quản trị viên cửa hàng để yêu cầu hoặc nâng cấp",
    "globalAction": "Thao tác",
    "globalTaskResult": "Kết quả",
    "globalTaskFailedNumber": "Thất bại",
    "globalTaskTotalNumber": "Tổng",
    "globalTaskSuccessNumber": "Thành công",
    "globalTaskFailed": "Một số đơn hàng xử lý thất bại, vui lòng thử lại sau",
    "globalTaskSuccess": "Thao tác thành công",
    "globalTaskWait": "Đang xử lý, vui lòng đợi",
    "globalStatus_unpublished": "Chưa Đăng",
    "globalStatus_update_failed": "Đăng Thất bại",
    "globalStatus_delete": "Đã xóa",
    "globalStatus_draft": "Bản nháp",
    "globalStatus_disabled": "Đã hủy bỏ",
    "globalStatus_banned": "Đã cấm bán",
    "globalStatus_sold_out": "Đã bán hết",
    "globalStatus_live": "Hoạt động",
    "globalStatus_process": "Đang xử lý",
    "globalStatus_all": "Tất cả",
    "globalName": "Global Name VN",
    "goToLinkStoreTip": "Không có cửa hàng được ủy quyền, vui lòng ủy quyền cửa hàng trước rồi hãy thêm sản phẩm",
    "goToLinkStore": "Đi đến ủy quyền cửa hàng",
    "remove": "Xóa",
    "distributionSettings": "Cài đặt fulfillment",
    "menuThirdPartyWarehouseProductList": "Danh sách sản phẩm kho bên thứ 3",
    "serviceLink": "tập đoàn Ginee Support",
    "thirdPartyWarehouseStocktakingList": "Kéo tồn từ kho bên thứ ba",
    "explore": "Chọn hàng",
    "authPlatform": "Cấp quyền",
    "yilianFulfillment": "YiLian Fulfillment",
    "syncdiconnected": "Hủy liên kết",
    "syncsuccess": "Đồng bộ thành công",
    "syncing": "Đang đồng bộ",
    "commissionSetting": "Cài đặt Hoa hồng",
    "LAInvoiceSettingsTab": "Cài đặt Hóa đơn",
    "ExportTemplateTab": "Mẫu Xuất",
    "documentTemplatSeettings": "Mẫu tài liệu",
    "selectfulfillment": "Vui lòng chọn Nhà Fulfillment",
    "haventBound": "Bạn chưa liên kết dịch vụ Fulfillment, liên kết ngay?",
    "newRule": "Quy tắc mới",
    "menuProductTiki": "Sản phẩm/Tiki",
    "logout": "Đăng xuất",
    "productTools": "Công cụ",
    "menuCustomer": "Khách hàng",
    "menuPromotion": "Khuyến mãi",
    "menuWarehouse": "Kho",
    "GineeChat": "Chat",
    "menuGineeCapital": "Tiền vốn",
    "addIntegration": "Thêm ủy quyền",
    "thirdpartyWarehouseList": "Kho bên thứ 3",
    "menuIntegration": "Ủy quyền",
    "logisticsReport": "Báo cáo Vận chuyển",
    "customerReport": "Báo cáo Khách hàng",
    "inventoryReport": "Báo cáo Tồn kho",
    "salesReport": "Đối soát Bán hàng",
    "blacklist": "Danh sách đen",
    "customerList": "Danh sách Khách hàng",
    "discountList": "Danh sách Khuyến mãi",
    "discountManagement": "Quản lý Khuyến mãi",
    "transfer": "Chuyển nhượng",
    "stoktaking": "Kiểm kê Tồn kho",
    "menuInbound": "Phiếu nhập kho",
    "menuOutbound": "Phiếu xuất kho",
    "menuProductLocation": "Kệ hàng",
    "warehouseList": "Danh sách kho",
    "stockPullRecord": "Lịch sử kéo tồn kho bên thứ 3",
    "safetyStockManagement": "Quản lý Tồn kho An toàn",
    "stock": "Tồn kho",
    "storeMoving": "Chuyển cửa hàng",
    "productScrape": "Sưu tập Sản phẩm",
    "channelProduct": "Sản phẩm của Kênh",
    "masterProductList": "Danh sách",
    "orderProcess": "Xử lý Đơn hàng",
    "allOrders": "Tất cả Đơn hàng",
    "MenuOrders": "Đơn hàng",
    "putAside": "Tạm gác lại",
    "autoBump": "Tự động đẩy Sản phẩm",
    "oneStoresProductsToAnyStores": "Sao chép sản phẩm của một cửa hàng sang các cửa hàng khác để nhanh chóng mở cửa hàng",
    "blibliProductList": "Danh sách sản phẩm Blibli",
    "FulfillSetting": "Quản lý cấu hình",
    "MassAdd": "Tạo Mẫu Hàng loạt",
    "PromotionEditTpl": "Chỉnh sửa Mẫu",
    "PromotionAddTpl": "Tệp tin mới",
    "PromotionTpl": "Mẫu Khuyến mãi",
    "IntegrationsUpdateStore": "Cập nhật thông tin cửa hàng",
    "PrintPickingListTemplate": "Mẫu Phiếu lấy hàng",
    "PrintInvoiceTemplate": "Mẫu Hóa đơn",
    "PrintPackingListTemplate": "Mẫu Phiếu đóng gói",
    "PrintLabelTemplate": "Mẫu Vận đơn",
    "addBundleISKU": "Thêm ISKU Combo",
    "editBundleISKU": "Chỉnh sửa ISKU Combo",
    "menuInboundManagementSubmit": "Quản lý Phiếu nhập kho / Nhập kho Phiếu nhập kho",
    "menuInboundManagementDetail": "Quản lý Phiếu nhập kho / Chi tiết Phiếu nhập kho",
    "menuInboundManagementEdit": "Quản lý Phiếu nhập kho / Chỉnh sửa Phiếu nhập kho",
    "menuInboundManagementAdd": "Quản lý Phiếu nhập kho / Tạo Phiếu nhập kho",
    "menuOutboundManagementSubmit": "Quản lý Phiếu xuất kho / Xuất Phiếu xuất kho",
    "menuOutboundManagementDetail": "Quản lý Phiếu xuất kho / Chi tiết Phiếu xuất kho",
    "menuOutboundManagementEdit": "Quản lý Phiếu xuất kho / Chỉnh sửa Phiếu xuất kho",
    "menuOutboundManagementAdd": "Quản lý Phiếu xuất kho / Tạo Phiếu xuất kho",
    "safetyStockSettings": "Cài đặt Tồn kho An toàn",
    "storeStockChangeHistory": "Lịch sử đẩy cửa hàng",
    "stockPullRecordDetail": "Lịch sử kéo Tồn kho Chi tiết",
    "thirdpartyWarehouseInventoryPullRecord": "Lịch sử kéo bên thứ 3",
    "stockInOutRecord": "Lịch sử Xuất/Nhập kho",
    "editWarehouseStock": "Chỉnh sửa Tổng tồn kho",
    "editLocalISKU": "Chỉnh sửa SKU chính",
    "editISKU": "Chỉnh sửa Kho sản phẩm",
    "binPsku": "Liên kết SKU Cửa hàng",
    "addISKU": "Thêm Kho sản phẩm",
    "EditOrderTpl": "Chỉnh sửa Mẫu",
    "AddOrderTpl": "Thêm Mẫu",
    "CRMHistory": "Lịch sử",
    "CRMSales": "Quản lý Bán hàng",
    "CRMTelemarketing": "Quản lý CRM",
    "SubscribeNow": "Đăng ký ngay",
    "RenewNow": "Gia hạn ngay",
    "PaymentSubscriptionPrice": "Gói và giá",
    "PaymentSubscriptionResult": "Kết quả Thanh toán",
    "AccountPackageSetting": "Tài khoản & Gói hiện tại",
    "PackageSubscription": "Đăng ký",
    "TransactionHistory": "Lịch sử giao dịch",
    "menuScrapingSettings": "Cài đặt Sưu tập",
    "ExclusiveSubscription": "Theo dõi phổ biến",
    "TopPopularStore": "Cửa hàng bán chạy",
    "AccountManagement": "Quản lý gói",
    "OrderBatchLabel": "In hàng loạt",
    "GineeChartRoom1": "Chat đa kênh",
    "InvoicePrintTemplateSettings": "Mẫu in Hóa đơn",
    "PackingListPrintTemplateSettings": "Mẫu in Phiếu đóng gói",
    "ShippingLabelTemplateSettings": "Mẫu in Vận đơn",
    "SenderInformationSettings": "Thông tin người gửi",
    "ShippingRuleSettings": "Phương thức giao hàng",
    "GINEEFulfillment": "Ginee Fulfillment",
    "YUEHAIFulfillment": "YUEHAI Fulfillment",
    "FASTOCKFulfillment": "FASTOCK Fulfillment",
    "JDFulfillment": "JD Fulfillment",
    "OrderSettings": "Cài đặt Đơn hàng",
    "CollectEdit": "Thu thập vào Sản phẩm chính",
    "CollectSetting": "Cài đặt Thu thập",
    "ScrapeList": "Danh sách Thu thập",
    "ReportChart": "Báo cáo",
    "ProductsCollection": "Thu thập Sản phẩm",
    "editrole": "Chỉnh sửa Vai trò",
    "addrole": "Thêm Vai trò",
    "RoleSetting": "Quản lý Vai trò",
    "StaffSetting": "Quản lý Nhân viên",
    "addstaff": "Thêm Nhân viên",
    "staffManagementAdd": "Quản lý Nhân viên/Thêm Nhân viên",
    "staffManagementEdit": "Quản lý Nhân viên/ Chỉnh sửa Nhân viên",
    "editstaff": "Chỉnh sửa Nhân viên",
    "subscribe": "Đăng ký",
    "days": "Ngày",
    "freetrial": "Dùng thử Miễn phí",
    "contactus": "Liên hệ chúng tôi",
    "EditCustomer": "Chỉnh sửa Khách hàng",
    "PromotionManagement": "Quản lý Khuyến mãi",
    "mProductSettings": "Cài đặt Sản phẩm chính",
    "productSettings": "Cài đặt Sản phẩm",
    "CustomerDetail": "Chi tiết Khách hàng",
    "NewCustomer": "Thêm Khách hàng",
    "GroupManagement": "Nhóm khách hàng",
    "CustomerManagement": "Quản lý Khách hàng",
    "MasterProductSettings": "Cài đặt Sản phẩm chính",
    "StockSetting": "Cài đặt Tồn kho",
    "menuAddProductToWarehouseEditStock": "Điền Tồn kho",
    "menuAddProductToWarehouse": "Thêm Sản phẩm vào kho",
    "stockManageList": "Danh sách Tồn kho",
    "stockManage": "Quản lý Tồn kho",
    "BarChart": "Phân tích kinh doanh",
    "Masseditcategories": "Chỉnh sửa danh mục và thuộc tính hàng loạt",
    "MassEdit": "Chỉnh sửa hàng loạt",
    "ShippingSetting": "Cài đặt Vận chuyển",
    "BasicInformation": "Thông tin cơ bản",
    "BasicSetting": "Cài đặt cơ bản",
    "ToolTipInfo": "Trạng thái: Đang bán, Ngừng bán, Đã bán hết, Cấm bán",
    "Result": "Kết quả",
    "PublishWait": "Đăng sản phẩm tại sàn đang xử lý, vui lòng đợi giây lát",
    "UpdateFailed": "Cập nhật thất bại",
    "Publishing": "Đã sao chép thành nháp",
    "TryAgain": "Thử lại",
    "SomeStoresCopyFailed": "Một vài cửa hàng sao chép thất bại",
    "CopySuccess": "Sao chép thành công",
    "ViewNow": "Xem ngay",
    "Close": "Đóng",
    "NoCopyStore": "Chưa có cửa hàng để sao chép, hãy ủy quyền cửa hàng ngay để sao chép",
    "CopyPublish": "Đăng lên",
    "CopyAsDraft": "Sao chép thành bản nháp",
    "CopyCount": "Sản phẩm chờ sao chép",
    "Live": "Sản phẩm đang bán",
    "All": "Tất cả sản phẩm",
    "Product": "Sản phẩm",
    "Store": "Cửa hàng",
    "Channel": "Kênh bán",
    "CopyTo": "Sao chép đến",
    "CopyFrom": "Sao chép từ",
    "CopyAlertInfo": "Sao chép sản phẩm đến cửa hàng khác trong cùng một kênh để mở kinh doanh nhanh chóng",
    "OperationGuide": "Hướng dẫn thao tác",
    "CopyStore": "Sao chép Cửa hàng",
    "AddStore": "Thêm Cửa hàng",
    "Copy": "Sao chép",
    "StoreList": "Danh sách Cửa hàng",
    "MyStores": "Sao chép Cửa hàng",
    "customerCenter": "Xin chào, chúng tôi có thể giúp gì cho bạn? Vui lòng liên hệ với chúng tôi vào 09:00 - 18:00",
    "addStore": "Thêm cửa hàng",
    "helpInfo": "Trung tâm trợ giúp",
    "addSyncStore": "Thêm cửa hàng liên kết",
    "lastSyncTime": "Thời gian đồng bộ gần nhất",
    "alreadyStore": "Cửa hàng đã liên kết",
    "menuProductLocalUpdateAutoBind": "Sản phẩm chính / Cập nhật quy tắc tự động liên kết",
    "menuInboundManagement": "Quản lý Phiếu nhập kho",
    "menuOutboundManagement": "Quản lý Phiếu xuất kho",
    "menuLowStockReport": "Hàng bán chậm",
    "menuLogisticsCostStatistics": "Chi tiết phí Vận chuyển",
    "menuOutStockForecast": "Dự báo hết hàng",
    "menuInventoryStatistics": "Thống kê Tồn kho",
    "menuLogisticsStatistics": "Thống kê Vận chuyển",
    "menuCustomerAreaAnalysis": "Phân tích Khu vực Khách hàng",
    "menuCustomerAnalysis": "Phân tích Khách hàng",
    "menuCustomerStatistics": "Thống kê Khách hàng",
    "menuStoreSaleReport": "Cửa hàng bán hàng",
    "menuPlatformSaleReport": "Kênh bán hàng",
    "menuIncomeStatementReport": "Đối soát lợi nhuận",
    "menuCategorySaleReport": "Thuộc tính Sản phẩm",
    "menuProductSaleReport": "Thống kê Sản phẩm",
    "menuVariantsSalesStatistics": "Thuộc tính Sản phẩm",
    "menuOrderReport": "Thống kê Đơn hàng",
    "menuSetting": "Cài đặt",
    "ShippingOrderNew": "Giao hàng",
    "status_ready_to_ship": "Sẵn sàng giao",
    "printOrderPending": "Đơn hàng chờ in",
    "beCollectedTo": "Chờ lấy hàng",
    "orderAbnormal": "Đơn hàng Bất thường",
    "PendingOrder": "Đơn hàng Chờ xử lý",
    "pending": "Chờ xử lý",
    "operationRecord": "Lịch sử thao tác",
    "historyOperation": "Nhật ký Vận hành Kho giao hàng",
    "shipped": "Đã giao hàng",
    "stored": "Đã gác lại",
    "warehouseSettingShipping": "Kho giao hàng",
    "orderDetails": "Chi tiết đơn hàng",
    "manualOrder": "Đơn hàng thủ công",
    "menuOrder": "Đơn hàng",
    "menuIntegrations": "Liên kết Cửa hàng",
    "menuProductLocationManagement": "Quản lý Kệ hàng",
    "menuProductLocationManagementOld": "Quản lý Vị trí",
    "menuWarehouseStockDetails": "Chi tiết Tồn kho",
    "menuStockPushRules": "Quy tắc đẩy Tồn kho",
    "menuStoreShippingWarehouseSettings": "Cài đặt Kho giao hàng",
    "menuWarehouseManagement": "Quản lý Kho hàng",
    "menuPriceManagement": "Quản lý Giá bán",
    "menuProductLocalImportToCreate": "Sản phẩm chính / Tạo Sản phẩm Hàng loạt",
    "menuProductLocalImportToBind": "Sản phẩm chính / Liên kết Sản phẩm Hàng loạt",
    "menuProductLocalEditBundle": "Sản phẩm chính / Chỉnh sửa Combo Sản phẩm",
    "menuProductLocalEdit": "Sản phẩm chính / Chỉnh sửa Sản phẩm",
    "menuProductLocalAddBundle": "Sản phẩm chính / Thêm Combo Sản phẩm",
    "menuProductLocalEditCombined": "Sản phẩm chính / Chỉnh sửa Sản phẩm Kết hợp",
    "menuProductLocalAddCombined": "Sản phẩm chính / Thêm Sản phẩm Kết hợp",
    "menuProductLocalAdd": "Sản phẩm chính / Thêm Sản phẩm",
    "menuProductWooCommerce": "Sản phẩm / WooCommerce",
    "menuBindHistory": "Lịch sử liên kết",
    "menuProductLocal": "Sản phẩm chính",
    "menuProduct": "Sản phẩm",
    "menuPoints": "Tích điểm",
    "menuMemberShipPage": "Trang thành viên",
    "InventoryLog": "Lịch sử biến động tồn kho",
    "menuMemberList": "Danh sách thành viên",
    "noticeList": "Thông báo",
    "menuHomePage": "Tổng quan",
    "productmassEditMaster": "Sản phẩm chính/Chỉnh sửa hàng loạt",
    "productPublishToTheStoreMaster": "Sản phẩm chính/Đăng lên cửa hàng",
    "resultsPublish": "Kết quả đăng",
    "operationHistoryMass": "Lịch sử hoạt động hàng loạt",
    "menuCapital": "Tiền vốn",
    "menuProductTikTok": "Sản phẩm/ TikTok",
    "menuProductTikTokEdit": "Sản phẩm/ TikTok/ Chỉnh sửa sản phẩm",
    "menuProductTikTokAdd": "Sản phẩm/ TikTok/  Thêm sản phẩm",
    "menuProductZalora": "Sản phẩm/ Zalora",
    "menuProductShopee": "Sản phẩm/ Shopee",
    "ImportAdd": "Nhập để tạo hàng loạt",
    "menuProductBlibliEdit": "Sản phẩm/ Blibli/ Chỉnh sửa sản phẩm",
    "menuProductShopeeEdit": "Sản phẩm / Shopee / Chỉnh sửa sản phẩm",
    "menuProductBlibliAdd": "Sản phẩm / Blibli/  Thêm sản phẩm",
    "menuProductShopeeAdd": "Sản phẩm / Shopee /  Thêm sản phẩm",
    "publishToStoreTip": "Sản phẩm chính / Đăng lên cửa hàng",
    "changePassword": "Đổi mật khẩu",
    "passworderror": "Mật khẩu phải bao gồm ít nhất 8 chữ cái, chữ số và ký hiệu đặc biệt",
    "pwdErrorInfo": "Mật khẩu phải gồm 8 - 20 ký tự",
    "UpdateSuccess": "Cập nhật thành công",
    "storeDiagnose": "Chẩn đoán cửa hàng",
    "shopeeAutoBump": "Shopee Tự động đẩy SP",
    "menuscrapeList": "Bộ sưu tập",
    "chromeExtension": "Chrome Extension",
    "getFreeDataTool": "Công cụ dữ liệu miễn phí",
    "bigdata": "Chọn hàng",
    "free": "Miễn phí",
    "DataExtension": "Thu thập dữ liệu mở rộng",
    "confirm": "Xác nhận",
    "noIndonesianLineGroup": "Không có nhóm Line tiếng Indonesia",
    "noEnglishLineGroupTemporarily": "Không có nhóm Line tiếng Anh",
    "noThaiGroupsForWhatsapp": "Không có nhóm Whatsapp tiếng Thái",
    "noVietnameseLineGroup": "Không có nhóm Line tiếng Việt",
    "noVietnameseWhatsappGroups": "Không có nhóm Whatsapp tiếng Việt",
    "noChineseWhatsappGroupTemporarily": "Không có nhóm Whatsapp tiếng Trung",
    "noChineseLineGroupTemporarily": "Không có nhóm Line tiếng Trung",
    "storeLimitTip": "Gói bạn đăng ký đã hết hạn. Nếu bạn muốn sử dụng gói miễn phí, vui lòng chọn 2 cửa hàng để tiếp tục liên kết (gói miễn phí có thể liên kết tối đa 2 cửa hàng, còn lại sẽ không được liên kết).",
    "max2Store": "Gói miễn phí có thể liên kết tối đa 2 cửa hàng",
    "publishToTheStore": "Đăng lên cửa hàng",
    "createMasterProduct": "Tạo sản phẩm chính",
    "confirmedNewPassword": "Đã xác nhận mật khẩu mới",
    "newPassword": "mật khẩu mới",
    "oldPassword": "Mật khẩu cũ",
    "detail": "Hủy theo dõi",
    "editProduct": "Chỉnh sửa sản phẩm",
    "orderXxxFailedToPushWarehouse": "Đơn xxx đẩy sang kho thất bại, nguyên do: xxx",
    "orderXxxPushedToWarehouseSuccessfully": "Đơn xxx đẩy sang kho thành công",
    "shippingToWarehouseOperationSucceeded": "Nhiệm vụ đẩy đã thành công",
    "shippingToWarehouseOperationIsSuccessful": "Thao tác gửi hàng đến kho thành công. vui lòng kiên nhẫn đợi kho hàng xử lý kết quả",
    "currentlyTiktokOrdersAreNotSupported": "Tạm thời không hỗ trợ thao tác chuẩn bị hàng và in vận đơn cho đơn hàng Tiktok thông qua Open API",
    "shopeeOrdersWithTheSameLogistics": "Hỗ trợ đặt thời gian lấy hàng hàng loạt cho cùng một đơn vị vận chuyển đối với đơn hàng Shopee/Tiktok",
    "gineeSupportsSettingUpAutomaticShipping": "Ginee hỗ trợ thiết lập phương thức vận chuyển tự động cho cửa hàng Shopee/Bukalapak/Tokopedia/Akulaku/TikTok",
    "xxxStockDeductionProcessingEnableddisabled": "Đã bật / tắt xử lý tồn kho cửa hàng XXX thành công",
    "theEffectiveTimeOfXxx": "Thời gian hiệu lực của việc xử lý tồn kho tại cửa hàng xxx được thay đổi từ xxx thành xxx",
    "pleaseOpenAtLeastOneStore": "Vui lòng bật ít nhất một cửa hàng",
    "thereIsAnUnfinishedOrderExport": "Có nhiệm vụ xuất đơn hàng chưa hoàn thành, vui lòng vào lịch sử xuất kho để xem các ghi chép xuất hàng, sau đó thực hiện xuất lại sau khi hoàn thành nhiệm vụ",
    "theSystemServiceIsAbnormalPlease": "Hệ thống không ổn định, vui lòng làm mới trang và thử lại",
    "afterTurnOffTheOrderWill": "Sau khi tắt, đơn hàng sẽ được phân bổ đến kho dựa ưu tiên của quy tắc kho giao hàng",
    "pleaseConfirmWhetherWantToPush": "Vui lòng xác nhận muốn đẩy kho hàng bên thứ 3 cho việc vận chuyển?",
    "pleaseConfirmWhetherWantToRedistribute": "Vui lòng xác nhận muốn phân bổ hàng lại?",
    "outboundOrder": "Mã đơn xuất kho",
    "splitOrder": "Tách đơn",
    "abnormalDescription": "Mô tả bất thường",
    "problemOrder": "Đơn hàng có vấn đề",
    "redistribute": "Phân bổ lại hàng hóa",
    "deliveryWarehouse": "Kho giao hàng",
    "nonindonesiaOrdersDoNotSupportXxx": "Đơn hàng Philippines không hỗ trợ giao hàng kho xxx, vui lòng điều chỉnh kho giao hàng",
    "theOrderDoesNotSupportDelivery": "Đơn hàng không hỗ trợ giao hàng kho xxx, vui lòng điều chỉnh kho giao hàng",
    "theAvailableStockOfMskuXxx": "MSKU xxx tồn kho có sẵn không đủ, vui lòng bổ sung tồn kho và phân bổ lại hàng hóa",
    "mskuXxxIsNotAssociatedWith": "MSKU xxx không liên kết với kho xxx, vui lòng thêm quan hệ liên kết và phân phối lại hàng hóa",
    "channelSkuXxxIsNotBound": "SKU kênh xxx chưa liên kết ràng buộc MSKU, vui lòng liên kết và phân bổ lại hàng hóa",
    "channelSkuXxxDoesNotHave": "SKU kênh xxx chưa phân bổ vào kho, vui lòng thêm quy tắc phân kho và phân bổ lại hàng hóa",
    "failedCreateOutboundOrder": "Tạo đơn xuất kho thất bại",
    "warehouseAllocationError": "Lỗi phân bổ kho hàng",
    "mskuNotAssociatedWithWarehouse": "MSKU chưa liên kết kho hàng",
    "mskuNotMatched": "MSKU chưa trùng khớp",
    "unallocatedWarehouse": "Tồn kho chưa phân bổ",
    "pushTheThirdPartyWarehouse": "Đẩy kho bên thứ ba",
    "paidOrder": "Thanh toán",
    "waitingPaymentOrder": "Đơn hàng",
    "alreadyInbound": "Đã nhập kho",
    "pleaseConfirmWhetherItIsAutomatically": "Vui lòng xác nhận có tự động đẩy hàng vào kho không?",
    "inboundReturnProduct": "Đẩy hàng vào kho",
    "abnormalType": "Loại khác",
    "printTime": "Thời gian in",
    "warehouseoutboundOrder": "Kho hàng/Mã đơn xuất kho",
    "variantsku": "Quy tắc/ SKU",
    "afterOpeningTheOrderWillBe": "Sau khi bật, đơn hàng đáp ứng điều kiện giao hàng sẽ tự động đẩy & liên kết với kho bên thứ ba",
    "automaticallyPushToThirdpartyWarehouse": "Tự động đẩy kho bên thứ ba",
    "inventoryVerificationAfterItIsTurned": "Kiểm tra hàng tồn kho: Sau khi được bật, tồn kho có sẵn trong kho hàng sẽ được kiểm tra xem có đủ hay không, và ưu tiên kho có đủ hàng để xuất kho.",
    "availableStockDeliveryDisabledSuccessfully": "Tắt thành công có hàng mới giao hàng",
    "availableStockDeliveryActivatedSuccessfully": "Bật thành công có hàng mới giao hàng",
    "shippingWarehouseSettingsDisabledSuccessfully": "Tắt thành công thiết lập phân kho",
    "shippingWarehouseSettingsActivatedSuccessfully": "Bật thành công thiết lập phân kho",
    "orderstockDeductionProcessDisabledSuccessfully": "Đã tắt thành công Xử lý đơn hàng tồn kho",
    "theStockDeductionProcessingOfXxx": "Quá trình xử lý tồn kho của cửa hàng xxx được bật / tắt thành công và thời gian có hiệu lực được thay đổi từ xxx thành xxx",
    "orderstockDeductionProcessSuccessfullyActivated": "Đã bật thành công Xử lý đơn hàng tồn kho",
    "afterActivatedOrdersWillBeAssigned": "Sau khi mở, các đơn hàng sẽ được khớp với các kho hàng với các hàng hóa khác nhau và cùng một đơn hàng có thể được giao cho các kho khác nhau để gửi hàng, nếu không, đơn hàng sẽ được giao cho cùng một kho để tiến hành giao.",
    "shipmentWarehouseSettingsDeterminesWhetherWarehouse": "Cài đặt phân kho: xác định khớp kho hàng dựa trên đơn hàng hoặc sản phẩm",
    "effectiveTime": "Thời gian hiệu quả",
    "activateStatus": "Bật trạng thái",
    "activateRange": "Bật phạm vi",
    "onceTurnOffAllStoreOrders": "Sau khi tắt, tất cả đơn hàng của các cửa hàng sẽ không thể xử lý tồn kho",
    "afterActivatedTheOrderWillGenerate": "Sau khi nó được bật, đơn đặt hàng sẽ tạo ra một chứng từ phát hành để khấu trừ hàng tồn kho theo quy trình xử lý, nếu không, hàng tồn kho sẽ không được xử lý khi đơn đặt hàng được chuyển đi.",
    "orderstockDeductionProcess": "Xử lý Đơn hàng liên kết tồn kho",
    "orderCreatedSuccessfully": "Tạo đơn hàng thủ công thành công",
    "shipmentWarning": "Cảnh báo giao hàng",
    "withoutUnboxingVideosComplaintsOfLessdamage": "Các phàn nàn về sản phẩm bị thiếu/ thiệt hại mà không có quay video mở kiện hàng thì sẽ không được chấp thuận",
    "shippingRebate": "Giảm giá vận chuyển",
    "shippingFeePaidByBuyer": "Người mua trả phí vận chuyển",
    "operationIsTooFrequentPleaseExport": "Thao tác quá thường xuyên, vui lòng xuất lại sau 1 phút",
    "rdPartyLogisticsShippingFee": "Phí vận chuyển bên đơn vị vận chuyển thứ 3",
    "shippingSubtotal": "Tổng phụ phí vận chuyển",
    "importFailedInternalSystemErrorPlease": "Nhập thất bại: lỗi hệ thống nội bộ, vui lòng nhập lại",
    "emailFormatIsIncorrect": "Định dạng email chưa chính xác",
    "supportsNumberslettersMaxCharacters": "Hỗ trợ số. chữ cái và \"-\". Tối đa. 30 ký tự",
    "supportsNumberslettersspacesMaxCharacters": "Chỉ hỗ trợ nhập số thứ tự/ chữ cái/ khoảng cách/ -, không vượt quá 20 ký tự",
    "basedOnProductSeparateRowFor": "Xuất theo sản phẩm (Hiển thị riêng lẻ phân loại đơn hàng)",
    "basedOnOrderOrderWithMultiple": "Xuất theo đơn hàng (Hiển thị tổng hợp đơn hàng kết hợp)",
    "exportMethod": "Phương thức xuất kho",
    "pleaseConfirmWhetherTheOrderStatus": "Vui lòng xác nhận việc đặt trạng thái đơn hàng là đã hoàn trả?",
    "editOrder": "Chỉnh sửa đơn hàng",
    "insufficientStockOfXxxPleaseMake": "Tồn kho của xxx không đủ, vui lòng bổ sung hàng hóa trước khi giao hàng",
    "paymentProblem": "Lỗi thanh toán",
    "invalidOrder": "Đơn hàng không hợp lệ",
    "moveToReturned": "Chuyển vào mục Hoàn",
    "selectCustomer": "Chọn khách hàng",
    "CustomerMobile": "Số điện thoại",
    "fyiPleaseDeleteWhenUploading": "Vui lòng xóa khi tải lên (chỉ để tham khảo)",
    "pleaseDeleteThisTextBoxBefore": "Vui lòng xóa văn bản trước khi nhập",
    "fillInWithTwocharacterShortCode": "Tên quốc gia điền vào 2 ký tự viết tắt của quốc gia đó,  phương thức thanh toán điền vào phương thức thanh toán trả trước/khác. Số tiền thanh toán không được vượt quá tổng số tiền đơn hàng.",
    "multipleMskusunitPricesquantitiesInTheSame": "Thông tin MSKU / đơn giá / số lượng trong cùng một đơn hàng phải được nhập trên một dòng, đơn giá không được nhỏ hơn 0 và số lượng phải lớn hơn 0; khi nhập nhiều dòng, các trường còn lại của đơn hàng có thể để trống.",
    "orderNumberOnlySupportsLettersNumbers": "Số đơn hàng chỉ hỗ trợ chữ cái, số thứ tự và dấu - /",
    "fieldWithMarkAreRequired": "Trường thông tin * là bắt buộc điền",
    "pleaseEditInAccordanceWithThe": "Vui lòng chỉnh sửa theo thứ tự có sẵn trong mẫu",
    "importantNotice": "Quan trọng",
    "theNumberOfDataRecordsCannot": "Số lượng thông tin nhập không quá 5000 dòng",
    "pleaseImportWithDownloadedFormatTemplate": "Vui lòng nhập theo mẫu đã tải xuống, không thay đổi tiêu đề",
    "onlySupportFilesInXlsxFormat": "Chỉ hỗ trợ các tệp dưới dạng xlsx (thay đổi thủ công đuôi tệp sẽ không hợp lệ)",
    "downloadImportTemplate": "Tải xuống mẫu nhập",
    "importOrder": "Nhập",
    "uploadPaymentProof": "Tải lên chứng từ thanh toán",
    "paymentProof": "Chứng từ thanh toán",
    "receivingAccount": "Số tài khoản nhận tiền",
    "paymentAccount": "Số tài khoản thanh toán",
    "receiptNumber": "Mã biên nhận",
    "theTotalPaymentAmountCannotExceed": "Tổng Số tiền thanh toán không được phép vượt quá Tổng tiền đơn hàng",
    "addPaymentRecord": "Thêm thông tin thanh toán",
    "refer": "Tham khảo",
    "useCommaToSeparateMultipleItem": "Thêm dấu phẩy để phân cách",
    "saveContinueAdd": "Lưu và Tiếp tục thêm",
    "save": "Lưu",
    "paymentSerialNumber": "Số seri thanh toán",
    "paymentAmount": "Số tiền thanh toán",
    "productTotalAmount": "Tổng tiền hàng",
    "totalQuantity": "Tổng số lượng",
    "totalPrice": "Tổng tiền",
    "addProduct": "Thêm hàng",
    "recipientArea": "Địa chỉ người nhận",
    "remarks": "Chú thích",
    "paymentInfo": "Thông tin thanh toán",
    "amountInfo": "Thông tin giá tiền",
    "productInfo": "Thông tin mục",
    "receiptInfo": "Thông tin nhận hàng",
    "basicInfo": "Thông tin khác",
    "referenceOrder": "Đơn hàng tham khảo",
    "canBeEnteredQuicklyByImporting": "Nhập nhanh bằng thông tin có sẵn",
    "addOrder": "Thêm lệnh",
    "bindStoreNow": "Liên kết cửa hàng ngay",
    "bindTips": "Do bạn chưa tích hợp cửa hàng kênh nên danh sách đơn hàng hiện đang trống. Vui lòng liên kết cửa hàng trước, sau đó mới xem hoặc vận hành đơn hàng",
    "PlatformDiscount": "Sàn TMĐT khuyến mãi",
    "TotalDiscount": "Tổng giảm",
    "voidTipss": "Đơn hàng đã chuyển vào Tạm gác lại sẽ không còn hỗ trợ bất kỳ thao tác đơn hàng nào trong hệ thống",
    "voidTips": "Một khi đơn hàng chuyển đến Tạm gác lại, nó sẽ không còn tham gia vào việc khóa / khấu trừ hàng tồn kho nữa. Bạn phải khôi phục đơn hàng mới có thể được khóa / khấu trừ bình thường. Vui lòng thao tác thận trọng.",
    "pickingList": "Picking List",
    "printedBy": "Được in bởi",
    "supportTikiAndZaloraOrdersToComplete": "Hỗ trợ đơn hàng Tiki và Zalora hoàn tất nhận hàng hàng loạt, đơn hàng Blibli vui lòng thao tác trong Chi tiết đơn hàng",
    "onlyLazadaBlibiliAndZaloraSupportPackaging": "Chỉ hỗ trợ đơn hàng Lazada, Blibli và Zalora đóng gói, đã đóng gói thì không hỗ trợ đóng gói lần 2, nếu muốn chỉnh sửa số lượng sản phẩm của đơn hàng, vui lòng cập nhập trong Chi tiết đơn hàng",
    "confirmWhetherToSetTheOrderStatusAsShipped": "Vui lòng xác nhận có đặt trạng thái đơn hàng thành Đã giao hàng không",
    "markAsShipped": "Đánh dấu hóa đơn",
    "whetherToUseGineeTemplateToPrintInvoices": "Có sử dụng mẫu riêng của Ginee để in hóa đơn hay không(Đơn hàng Lazada PH chỉ hỗ trợ in hóa đơn theo mẫu của Ginee)",
    "buyerPayment": "Giá trị người mua thanh toán",
    "doAllProductInTheSameOrder": "Tất cả sản phẩm trong cùng một đơn hàng có sử dụng cùng một số hóa đơn hay không",
    "clickHereToSetting": "Nhấn cài đặt",
    "operationLog": "Lịch sử thao tác",
    "massSetPickupTime": "Đặt thời gian lấy hàng hàng loạt",
    "numberOfFails": "Số thất bại",
    "totalNumber": "Tổng số",
    "invoiceNumberSettingFailureDetails": "Chi tiết cài đặt mã hóa đơn thất bại",
    "invoiceNumberIsSetSuccessfully": "Cài đặt mã hóa đơn thành công",
    "clickHereToSetWhetherAll": "Nhấn vào đây để cài đặt tất cả các sản phẩm trong cùng một đơn hàng có sử dụng cùng một số hóa đơn hay không",
    "setInvoiceNumber": "Đặt số hóa đơn",
    "printShippingLabelAndPickingLists": "In vận đơn và phiếu lấy hàng",
    "pleaseConfirmWhetherTheReceiptIs": "Vui lòng xác nhận có đã hoàn tất nhận hàng chưa?",
    "onlyTikiOrdersCanBeReceived": "Chỉ hỗ trợ đơn hàng Tiki hoàn tất nhận hàng hàng loạt, đơn hàng Blibli vui lòng vào chi tiết đơn hàng để thao tác",
    "malaysiaTemplates": "Mẫu Malaysia",
    "generalTemplates": "Mẫu thông dụng",
    "unassignedWarehouse": "Tồn kho chưa phân bổ",
    "jikaPesananTelahDikaitkanDenganGudang": "Nếu đơn hàng đã liên kết kho bên thứ ba, vui lòng xác nhận kho đã hủy phiếu xuất kho thành công hay chưa để tránh sai sót tồn kho",
    "inOrderToEnsureTheNormal": "Để đảm bảo các chức năng của hệ thống sử dụng bình thường, chức năng tải đơn hàng sẽ không hoạt động trong thời gian khuyến mãi 11.11 ~ 11.12, cảm ơn quý khách hàng đã ủng hộ",
    "warehouse": "Kho",
    "unpairedMasterSKU": "Master SKU chưa ghép đôi",
    "postalCode": "Mã bưu điện",
    "detailsAddress": "Địa chỉ chi tiết",
    "street": "Đường",
    "districts": "Quận",
    "city": "Thành phố",
    "province": "Tỉnh",
    "senderPhone": "Số điện thoại nhà bán",
    "senderName": "Tên nhà bán",
    "dataLoadingPleaseWaitOrClick": "Đang tải dữ liệu xuống, vui lòng đợi hoặc nhấn nút bên dưới để bỏ qua",
    "activatedAllOrdersFromTheAuthorized": "Một khi bật thao tác giao hàng, tất cả đơn hàng của cửa hàng đã ủy quyền cần thực hiện \"Đóng gói đơn hàng\" trước khi tiến hành thao tác giao hàng",
    "restrictionShippingOfOrdersMustBe": "Giới hạn giao hàng: Bắt buộc ưu tiên thực hiện đóng gói đơn hàng trước khi giao hàng",
    "shippingDiscountSeller": "Chiết khấu Phí ship Nhà bán",
    "feeDiscountFromPlShipping": "Chiết khấu Phí ship bên thứ 3",
    "taxEscrow": "Thuế TMĐT xuyên biên giới",
    "transactionFeeBuyer": "Phí giao dịch Người mua",
    "transactionFeeSeller": "Phí Giao dịch Nhà bán",
    "SetPackStatusTitle": "Cài đặt Trạng thái Đóng gói",
    "SetPackStatusInMass": "Cài đặt Trạng thái Đóng gói Hàng loạt",
    "timeUpdate": "Thời gian cập nhật",
    "timeCreation": "Giờ tạo",
    "contentOperation": "Nội dung thao tác",
    "moduleOperation": "Mô-đun hoạt động",
    "recordOperation": "Lịch sử thao tác",
    "orderRestore": "Khôi phục đơn hàng",
    "inToAlreadyShippedMove1": "Chuyển vào Đã giao hàng",
    "warehouseThirdparty": "Kho hàng bên thứ 3",
    "warehouseOwn1": "Kho tự do",
    "inToAlreadyShippedMove": "Chuyển vào Đã giao hàng",
    "inToBeCollectedMove": "Chuyển vào chờ sưu tập",
    "numberEmptyAwb": "Thiếu mã vận đơn",
    "printedNot": "Chưa in",
    "youWantToOperateSeparatelyPlease": "Nếu bạn muốn thao tác riêng lẻ, vui lòng chuyển đến danh sách đơn hàng để hoàn tất thao tác.",
    "ordersWillBeDirectlyPackagedAnd": "Các đơn hàng Lazada/Blibli sẽ được trực tiếp đóng gói và gửi hàng",
    "processingTheTokopediabukalapakOrderWillBe": "Sau khi xử lý, đơn hàng Tokopedia/Bukalapak sẽ được chấp nhận và gửi hàng",
    "youSureToProcessTheSelected": "Bạn có chắc muốn xử lý X đơn hàng đã được chọn cùng lúc và chuyển chúng đến đơn hàng chờ in?",
    "failedCancel": "Hủy thất bại",
    "pushToThirdpartyWarehouseFailed": "Đẩy sang kho của bên thứ 3 thất bại",
    "toPendingMove": "Chuyển vào chờ xử lý",
    "asPriorityMark": "Đánh dấu thành ưu tiên xử lý",
    "orderOutOfStockAll": "Tất cả đơn hết hàng",
    "orderOutOfStockSelected": "Đơn hết hàng đã chọn",
    "stockCheck": "Kiểm tra tồn kho",
    "skuXxxxIsInsufficientInThe": "Master SKU: {x} trong kho {y} bị thiếu tồn kho, vui lòng bổ sung hàng trong Quản lý tồn kho rồi xử lý lại",
    "skuXxxxDoesNotExistIn": "Master SKU: XXXX không tồn tại trong kho XXXX. Vui lòng chọn lại kho hàng bằng cách thay thế Master SKU hoặc thêm Master SKU mới trong kho trước khi xử lý lại",
    "warehouseToShipmentPush": "Đẩy kho để gửi hàng",
    "orderProcessing": "Xử lý đơn hàng",
    "processingFailed": "Xử lý thất bại",
    "ofStockOut": "Hết hàng",
    "warehouseOwn": "Kho tự do",
    "failedOrdersView": "Kiểm tra nguyên nhân thất bại",
    "failed": "Thất bại",
    "success": "Thành công",
    "total": "Tổng",
    "descriptionXxOrdersHaveBeenSynchronized": "Mô tả tiến độ: đã đồng bộ XX đơn, còn XX đơn chờ đồng bộ",
    "pleaseWaitSyncing": "Đang đồng bộ, vui lòng đợi",
    "result": "Kết quả",
    "reasonFailed": "Nguyên nhân thất bại",
    "exceptionSynchronization": "Đồng bộ ngoại lệ",
    "otherLogisticsProviderNumberEmptyPlaceholder": "Chưa tạo mã vận chuyển, vui lòng đồng bộ sau",
    "theCheckIsRemovedTheIntegrated": "Sau khi bỏ chọn, tồn kho sản phẩm chính sẽ tăng lên",
    "selectedTheIntegratedMasterProductStock": "Sau khi chọn, tồn kho sản phẩm đã liên kết sẽ tăng lên",
    "ifNoRuleIsAddedAfter": "Mẹo: Nếu chưa thêm quy tắc, sau khi đơn hàng chuyển sang trạng thái bị hủy, tất cả tồn kho của sản phẩm chính đã được liên kết trong đơn hàng sẽ tự động được nhập kho lại",
    "forTheProductStockOfThe": "Chỉ dành cho tồn kho của sản phẩm chính phù hợp trong đơn hàng, nếu sản phẩm chính không được liên kết, sẽ không được xử lý",
    "theReasonForCancellationIs1": "Khi Lý do hủy là ##, cập nhật thành sản phẩm không nhập kho lại",
    "theReasonForCancellationIs": "Khi Lý do hủy là ##, cập nhật thành sản phẩm nhập kho lại",
    "operationContent": "Nội dung thực hiện",
    "delete": "Xóa",
    "updateTime": "Thời gian cập nhật",
    "createTime": "Giờ tạo",
    "cancelReason": "Lý do hủy",
    "operator": "Người thực hiện",
    "pleaseSelectStore": "Vui lòng chọn Cửa hàng",
    "pleasesSelectChannel": "Vui lòng chọn Kênh",
    "no": "Không",
    "yes": "Có",
    "updateWarehouseStock": "Sản phẩm nhập kho lại",
    "unknownReason": "Chưa rõ nguyên nhân",
    "buyerCanceled": "Người mua Hủy",
    "systemCancel": "Hệ thống Hủy",
    "cancelSetting": "Hủy cài đặt",
    "kodePos": "Mã bưu điện",
    "nameFirst": "Tên",
    "nameLast": "Họ",
    "staff": "Nhân viên",
    "secretary": "Thư ký",
    "receptionist": "Lễ tân",
    "security": "Bảo vệ",
    "driver": "Lái xe",
    "helperDomestic": "Phụ tá",
    "relationship": "Mối quan hệ",
    "mrmrsms": "Mr/Mrs/Ms",
    "spouse": "Vợ/chồng",
    "related": "Người thân",
    "lessThanCharactersRequired": "Bắt buộc, ít hơn 30 kí tự",
    "codeSettlement": "Quyết toán số",
    "statusReceiver": "Trạng thái gửi hàng",
    "nameReceiver": "Tên người nhận hàng",
    "productDateReceived": "Ngày gửi hàng",
    "pleaseCompleteTheFollowingInformationAnd": "Chú ý: Vui lòng hoàn tất thông tin như sau và cập nhật đơn hàng sang trạng thái Đã hoàn thành",
    "completedOrder": "Hoàn thành đơn hàng",
    "cancel": "Hủy",
    "noMoreThanCharactersRequired1": "Bắt buộc điền, không vượt quá 300 kí tự",
    "ofThePerformanceReasonsPart": "Nguyên nhân",
    "quantityFinal": "Số lượng cuối cùng",
    "ifTheCurrentOrderOnlySupports": "Chú ý: Nếu đơn hàng chỉ hỗ trợ một phần fulfillment, vui lòng cập nhật số lượng sản phẩm, nếu số lượng của tất cả sản phẩm trong đơn hàng hiện tại được cập nhật là 0, thì sẽ hủy đơn hàng.",
    "quantityChange": "Chỉnh sửa số lượng",
    "endTimeMustBeGreaterThan": "Thời gian kết thúc phải lớn hơn thời gian bắt đầu",
    "noMoreThanCharactersRequired": "Bắt buộc điền, không vượt quá 300 kí tự",
    "fieldsRequired": "Cột bắt buộc điền",
    "installationnote": "Ghi chú cài đặt",
    "installationmobile": "Số điện thoại Người cài đặt",
    "installationofficer": "Người cài đặt",
    "endDateInstallation": "Ngày kết thúc cài đặt",
    "startDateInstallation": "Ngày bắt đầu cài đặt",
    "endDateShipping": "Ngày kết thúc giao hàng",
    "startDateShipping": "Ngày bắt đầu giao hàng",
    "nameCourrier": "Tên công ty vận chuyển",
    "toPackSomeOrdersPleaseTry": "Một số đơn hàng đóng gói thất bại",
    "successfullyPackaged": "Đóng gói thành công",
    "pleaseWaitPacking": "Đang đóng gói",
    "quantity": "Số lượng",
    "stockAvailablelocked": "Tồn kho Có sẵn/Bị Khóa",
    "skuwarehouseMaster": "Master SKU/Kho hàng",
    "nameProduct": "Tên sản phẩm",
    "numberOrder": "Mã đơn hàng",
    "onlyLazadaAndBlibliOrdersSupport": "Chú ý: Chỉ có đơn hàng của Lazada và Blibli hỗ trợ đóng gói, đơn hàng đã đóng gói không hỗ trợ đóng gói lần 2, nếu chỉnh sửa số lượng của đơn hàng Blibli, vui lòng cập nhật trong chi tiết đơn hàng",
    "theProductsOfTheSameOrder": "Đóng gói những sản phẩm của cùng một đơn hàng trong Blibli (giới hạn cho Sản phẩm Thông dụngt/Hàng cồng kềnh)",
    "pack": "Đóng gói",
    "stockAvailable": "Tồn kho có sẵn",
    "serviceProviderFulfillment": "Nhà cung cấp dịch vụ Fulfillment",
    "skuMaster": "Master SKU",
    "nameWarehouse": "Tên kho hàng",
    "serviceProvider": "Nhà cung cấp dịch vụ",
    "theMasterSkuReplacing": "Thay đổi Master SKU",
    "withXxxxxxPaired": "Đã ghép đôi với XXXXXX",
    "reasonReturn": "Nguyên nhân hoàn",
    "itemsRestock": "Hàng tồn kho hoàn lại",
    "amountRefund": "Tổng số tiền hoàn lại",
    "shippingReturn": "Hoàn phí ship",
    "quantityReturn": "Số lượng hàng hoàn",
    "totalRefundable": "Tổng số tiền có thể hoàn",
    "sendNotificationsToCustomers": "Gửi tin nhắn cho khách hàng",
    "totalRefund": "Tổng số tiền hoàn",
    "productRefund": "Sản phẩm hoàn",
    "ifYouChooseToIncreaseStock": "Chú ý: Nếu chọn thêm hàng tồn kho, thì hàng tồn kho của sản phẩm có hàng hoàn sẽ tăng lên",
    "refund": "Hoàn tiền",
    "buyerInfoEdit": "Chỉnh sửa thông tin người mua",
    "location": "Vị trí",
    "other": "Khác",
    "declinedPayment": "Thanh toán bị từ chối",
    "orderFraudulent": "Lừa đảo",
    "unavailableItems": "Sản phẩm không có sẵn",
    "changedcanceledOrderCustomer": "Khách hàng thay đổi/hủy đơn hàng",
    "CreditCardTransactionFee": "Phí thẻ tín dụng",
    "moreThan5": "Trên 5 ngày",
    "fiveDays": "Trong 5 ngày",
    "threeDays": "Trong 3 ngày",
    "twoDays": "Trong 2 ngày",
    "oneDays": "Trong 1 ngày",
    "noNotes": "Không có ghi chú",
    "hasNotes": "Có ghi chú",
    "noMessage": "Không có tin nhắn",
    "hasMessage": "Có tin nhắn",
    "multipleProduct": "Nhiều sản phẩm",
    "oneProductWithMoreThanOneQty": "Nhiều sản phẩm cùng loại",
    "singleProduct": "Một sản phẩm",
    "jdTip": "Chú ý: Do ảnh hưởng của kênh API, JD.ID cửa hàng được liên kết từ 29/04/2021 có một phần không thể đồng bộ, nhóm JD.ID sẽ sửa chữa vấn đề này ngay, xin lỗi vì sự bất tiện.",
    "orderIdSearchTips": "Vui lòng dùng \",\" giữa các mã đơn hàng",
    "lastSync": "Thời gian đồng bộ",
    "sortBy": "Thứ tự",
    "asc3": "Tăng lên",
    "desc2": "Giảm xuống",
    "desc4": "Giảm xuống",
    "asc2": "Tăng lên",
    "desc": "Giảm xuống",
    "asc": "Tăng lên",
    "zToA": "(Z-A)",
    "aToZ": "(A-Z)",
    "payTime": "Thời gian thanh toán",
    "deliverDeadline": "Thời hạn gửi hàng",
    "productSku": "Sản phẩm SKU",
    "failnumber": "Số lượng thất bại",
    "sucessnumber": "Số lượng thành công",
    "totalnumber": "Tổng số lượng",
    "lazadatip": "Khi Lazada khuyến mãi (26/3 đến 31/3) việc đồng bộ đơn hàng có thể bị trễ, và trong khi Lazada API bị hạn chế, bạn không thể chỉnh sửa thông tin sản phẩm (bao gồmHàng tồn kho và Giá) hoặc đăng sản phẩm mới thông qua Ginee. Chúng tôi rất xin lỗi vì sự bất tiện này.",
    "keyword": "Từ khóa",
    "logisticsProviderNumberEmptyPlaceholder": "Shopee chưa tạo mã vận chuyển, vui lòng đồng bộ sau",
    "logisticsProviderNameEmptyPlaceholder": "Shopee chưa phân phối vận chuyển, vui lòng đồng bộ sau",
    "PrintPdfErrorMessage": "Tạo tệp thất bại, vui lòng thử lại sau",
    "orderDetailNoteLimit": "Không vượt quá 500 kí tự",
    "GoContinue": "Tiếp tục",
    "BrowserBlockedInfo": "Cửa sổ mới mở ra đã bị trình duyệt chặn lại, vui lòng nhấp vào \"Tiếp tục\" để mở cửa sổ của trang mới (bạn cũng có thể  tìm \"Xóa phương thức chặn của trình duyệt\" trong Trung tâm trợ giúp)",
    "Notification": "Hiển thị",
    "ShowLabelProductInfo": "(Bukalapak)Vận đơn hiển thị thông tin sản phẩm",
    "VoucherPlatform": "Mã khuyến mãi sàn",
    "PriceCreditCardServiceFee": "Phí thẻ tín dụng",
    "PriceSellerReturnRefundAmount": "Số tiền hoàn trả",
    "PriceCoinCashBack": "Xu nhà bán hoàn trả",
    "PriceCoin": "Coin",
    "AllStatus": "Tất cả trạng thái",
    "AllType": "Tất cả các loại",
    "theMarketplacePromotionPeriodPleaseUnderstand": "Trong thời gian quảng bá nền tảng có thể bị chậm trễ trong việc đồng bộ đơn hàng, xin lỗi vì sự bất tiện",
    "pendingTableTips": "Hiện chỉ hỗ trợ xử lý đơn hàng Shopee/Bukalapak/Tokopedia/Lazada/JD/Shopify",
    "Filter": "Lọc",
    "InvoiceSettings": "Cài đặt hóa đơn",
    "MultipleProduct": "Nhiều sản phẩm",
    "OneProductOneQty": "Nhiều sản phẩm cùng loại",
    "paidTime": "Thời gian thanh toán",
    "packageStatus": "Trạng thái gói",
    "shipBefore": "Hẹn giao hàng",
    "sellerNote": "Ghi chú của người bán",
    "buyerNote": "Ghi chú",
    "packageType": "Loại đóng gói",
    "SingleProduct": "Một sản phẩm",
    "orderProductType": "Loại sản phẩm",
    "ViewFailedList": "Xem đơn hàng thất bại",
    "AWBTrackingNumber2": "Mã vận đơn/Số hóa đơn",
    "PickUpTime": "Thời gian lấy hàng",
    "NoEmptyText": "Không có dữ liệu",
    "OrdersFailedTips": "Một phần đơn hàng gửi đi thất bại, vui lòng thử lại sau",
    "SuccessfullyShipped": "Gửi hàng thành công",
    "TotalOrdersShipped": "Tổng số đơn đặt hàng sẽ được vận chuyển lần này",
    "ShippingWait": "Đang vận chuyển",
    "action": "Thao tác",
    "SeeMoreRetract": "Thu gọn",
    "FailedReason": "Nguyên nhân thất bại",
    "RecipientMobile": "Số điện thoại người nhận",
    "SetNow": "Cài đặt ngay",
    "SkipNext": "Bỏ qua, tiếp",
    "shippingTips3": "Do hạn chế bởi giao diện JD, chỉ hỗ trợ thao tác giao hàng sau khi in Vận đơn, nếu chưa in thì sẽ không hỗ trợ giao hàng",
    "shippingTips2": "Nếu bạn chưa thiết lập, hệ thống sẽ tự động chỉ định phương thức vận chuyển là ưu tiên Lấy hàng tận nơi > Gửi hàng tại bưu cục > Vận chuyển thủ công.",
    "shippingTips1": "Một phần chuyển phát nhanh chưa được cài đặt phương thức vận chuyển, vui lòng hoàn thành cài đặt phương thức vận chuyển trước khi gửi hàng",
    "FilterOrder": "Lọc đơn hàng",
    "setAddress": "Chưa có địa chỉ, vui lòng đi đến Trung tâm người bán để cài đặt",
    "ArrangeShipment2": "Sắp xết gửi hàng",
    "MassUpload": "Tải lên hàng loạt",
    "ShippingCarrier": "Công ty vận chuyển",
    "OrderQuantity": "Số lượng đơn hàng",
    "DownloadTemplate": "Tải xuống mẫu",
    "FileSizeLimitTips": "Chỉ hỗ trợ tệp xlsx/xls nhỏ hơn 5M",
    "ImportFile": "Nhập tệp",
    "FileFormatInvalid": "Nhập sai định dạng tệp",
    "ProcessFailed": "Xử lý thất bại",
    "ToBeDelivered": "Chờ giao hàng",
    "ToBeAccepted": "Chờ xác nhận",
    "PickUpAddress": "Địa chỉ lấy hàng",
    "axiosTips": "Chú ý",
    "moreMaxLength": "Tối đa { maxLength } kí tự",
    "PleaseSelect": "Vui lòng chọn",
    "SyncError": "Động bộ thất bại",
    "syncSuccess": "Đồng bộ thành công",
    "Synchronizing": "Đang đồng bộ",
    "reset": "Đặt lại",
    "search": "Tìm kiếm",
    "status_failed": "Thất bại",
    "status_returned": "Hoàn",
    "status_cancelled": "Hủy",
    "status_delivered": "Đã nhận",
    "status_shipping": "Đang vận chuyển",
    "status_paid": "Đã thanh toán",
    "status_pending_payment": "Chờ thanh toán",
    "CancellingProcess": "Đang hủy",
    "Delete": "Xóa",
    "Unpicked": "Chờ lấy hàng",
    "Picked": "Đã lấy hàng",
    "ShippingMethodLink": "Cài đặt ngay",
    "ExportDisabled": "Quá hạn",
    "ExportFailed": "Tải xuống thất bại",
    "ExportFinished": "Hoạt động",
    "ExportINProcess": "Đang xử lý",
    "ExportStatus": "Trạng thái",
    "AddTemplate": "Thêm mẫu",
    "ExportFiledReason": "Nguyên nhân thất bại",
    "FileSaveHistoryDays": "Tệp sẽ được hiển thị trong danh sách lịch sử xuất, và sẽ chỉ lưu giữ trong 15 ngày, vui lòng nhấp vào để tải xuống ngay",
    "ExportFileFailed": "Tạo tệp thất bại, vui lòng thử lại sau",
    "ExportFileGenerated": "Tệp đã được tạo, vui lòng nhấp vào tải xuống",
    "ExportFileInProcess": "Đang tạo tệp, vui lòng đợi",
    "TokoOrderApiUnstable": "Do ảnh hưởng bởi bản cập nhật kênh Tokopedia, Ginee sẽ tạm dừng dịch vụ đồng bộ đơn hàng của kênh này trong 1 giờ kể từ 22:00 giờ Indonesia ngày 3 tháng 11. Chúng tôi rất xin lỗi vì sự bất tiện này.",
    "ExportResultTitle": "Kết quả",
    "OrderStatus": "Trạng thái đơn hàng",
    "ExportDownload": "Tải xuống",
    "ExportAllOrders": "Xuất tất cả",
    "ExportBySelected": "Xuất mục đã chọn",
    "Export": "Xuất",
    "OtherInformation": "Thông tin khác",
    "AmountInformation": "Thông tin số tiền",
    "LogisticsInformation": "Thông tin vận chuyển",
    "ProductInformation": "Thông tin sản phẩm",
    "BuyerInformation": "Thông tin khách hàng",
    "ExportSelectedDate": "Chọn thời gian",
    "ExportSelectedTemplate": "Chọn mẫu",
    "ExportOrder": "Xuất đơn hàng",
    "ExportCreator": "Người tạo",
    "ExportContent": "Nội dung",
    "ExportTime": "Thời gian xuất",
    "ExportHistory": "Lịch sử xuất",
    "TaxationFee": "Thuế",
    "CancelNoteText": "Nguyên nhân hủy",
    "FinalShippingFee": "Phí vận chuyển đã trả bởi hệ thống",
    "PickingListStatus": "Trạng thái phiếu lấy hàng",
    "PackingListStatus": "Trạng thái phiếu đóng gói",
    "InvoiceStatus": "Trạng thái hóa đơn",
    "LabelStatus": "Trạng thái thẻ",
    "AfterClickPrint": "Sau khi nhấp vào in, các đơn hàng trong danh sách dưới đây sẽ đánh dấu thành đã in",
    "SetToPrintedMass": "Cài đặt hàng loạt thành đã in",
    "SetPrintStatusTips": "Chú ý: Khi cài đặt thành Đã in, không còn được hỗ trợ để thay đổi trạng thái in nữa, hãy lưu ý.",
    "SetPrintStatus": "Cài đặt trạng thái in",
    "SetStatus": "Cài đặt trạng thái",
    "PickStatus": "Trạng thái lấy hàng",
    "PrintStatus": "Trạng thái in",
    "DiscountedTotalPrice": "Giảm giá",
    "InvoiceAlreadyPrinted": "Hóa đơn đã in",
    "InvoiceNotPrinted": "Hóa đơn chưa in",
    "PackingListAlreadyPrinted": "Phiếu đóng gói đã in",
    "PackingListNotPrinted": "Phiếu đóng gói chưa in",
    "PickingListAlreadyPrinted": "Phiếu lấy hàng đã in",
    "PickingListNotPrinted": "Phiếu lấy hàng chưa in",
    "LabelAlreadyPrinted": "Vận đơn đã in",
    "LabelNotPrinted": "Vận đơn chưa in",
    "NotPrinted": "Chưa in",
    "AlreadyPrinted": "Đã in",
    "Rebate": "Chiết khấu",
    "NewBuyerNote": "Ghi chú",
    "OrderTotal": "Tổng tiền đơn hàng",
    "BuyPhone": "Số điện thoại",
    "ProductInfo": "Thông tin mục",
    "SellerNote": "Ghi chú của người bán",
    "NoContentYet": "Hiện không có nội dung",
    "OnlyVisibleToPicking": "Chỉ có thể xem phiếu lấy hàng",
    "OnlyVisibleToSellers": "Chỉ người mua có thể xem",
    "CurrentlyNotSupportedShipping": "Hiện tại không hỗ trợ xem trạng thái vận chuyển",
    "AWB": "Mã vận đơn",
    "Voucher": "Voucher",
    "ServiceFee": "Phí dịch vụ",
    "CommissionFee": "Phí hoa hồng",
    "UnstableLazadaAPI": "Gần đây, do API của Lazada không ổn định, một số đơn hàng của cửa hàng không thể đồng bộ đầy đủ. Lazada sẽ sớm khắc phục. Nếu gặp bấn đề, vui lòng vào Trung tâm người bán để xử lý đơn hàng.",
    "HideProductInfo": "Ẩn thông tin sản phẩm",
    "ShippingWeight": "Trọng lượng",
    "ShippingSender": "Người gửi",
    "ShippingRecipient": "Người nhận",
    "BatchLabelInfo": "Sau khi nhấp vào in, những đơn hàng trong danh sách dưới sẽ được đánh dấu đã in",
    "PrintBatchLabel": "In nhiều vận đơn",
    "ViewAllProduct": "Xem tất cả { productCount } sản phẩm",
    "ShipBefore": "Hẹn ship",
    "PackFailedInfo": "Đóng gói thất bại, vui lòng làm mới hoặc đồng bộ trước khi thử lại",
    "PleaseSelectProduct": "Vui lòng chọn sản phẩm",
    "SkipPrint": "Bỏ qua & in",
    "SomeOrderInvoiceEmpty": "Một phần của số hóa đơn của hàng bị trống và không thể in được, vui lòng thêm phần đó trước",
    "SearchPlaceholder": "Vui lòng nhập nội dung",
    "Unpacked": "Chưa đóng gói",
    "PackingStatus": "Trạng thái đóng gói",
    "ReceiverName": "Họ tên người nhận",
    "BuyerEmail": "Email của khách hàng",
    "BuyerContact": "Số liên lạc của khách hàng",
    "BuyerName": "Tên khách hàng",
    "TrackIngNumber": "Mã vận đơn",
    "InvortySKU": "MSKU",
    "ParentSKU": "Master SKU",
    "ItemName": "Tên sản phẩm",
    "productName": "Tên sản phẩm",
    "OrdersID": "Mã đơn hàng",
    "Keywords": "Từ khóa",
    "OrderTypeNormal": "Bình thường",
    "OrderTypePreOrder": "Đơn hàng đặt trước",
    "AllOrderType": "Tất cả các loại đơn hàng",
    "PaymentPrePaid": "Thanh toán trước",
    "AllPayment": "Tất cả thanh toán",
    "LdProductStatusInfo": "Trạng thái sản phẩm trong đơn hàng là không tương ứng, vui lòng kiểm tra Chi tiết đơn hàng.",
    "PackingFailedInfo": "Đóng gói thất bại, vui lòng làm mới trang trước khi thử lại",
    "BillingAddress": "Địa chỉ thanh toán",
    "InvoiceNumber": "Số hóa đơn",
    "ProductsItemPack": "Tất cả sản phẩm đã đóng gói trong 1 đơn hàng không được đóng gói lại",
    "OnlyUnPackagedOrders": "Chỉ những đơn hàng chưa đóng gói",
    "AllProductsPacked": "Tất cả sản phẩm trong 1 đơn hàng có cùng mã hóa đơn",
    "PackMethod": "Cách đóng gói",
    "PackOrderTips": "Chú ý: Các đơn hàng của Lazada phải được đóng gói trước khi gửi đi, và Ginee chỉ hỗ trợ đóng gói các mặt hàng của cùng một đơn hàng.",
    "AlreadyPacked": "Đã đóng gói",
    "Pack": "Đóng gói",
    "PackOrder": "Đóng gói",
    "LWrongPricePricingError": "Giá / Tiền cọc không đúng",
    "LDuplicateOrder": "Đặt hàng trùng lặp",
    "LCustomerUnreachable": "Không thể liên lạc khách hàng",
    "order_98c90235ea6e3d054f70d2ffdfa9e703": "Hết hàng",
    "LOutOfDeliveryArea": "Vượt phạm vi giao hàng",
    "LIncorrectOr": "Địa chỉ giao hàng không chính xác hoặc không đầy đủ",
    "LSystemError": "Lỗi hệ thống",
    "LSourcingDelay": "Mua hàng chậm trễ",
    "NoName": "Không tên",
    "ProductSyncFailedAgain": "Đồng bộ sản phẩm thất bại, vui lòng thử lại",
    "ShopCancelOrderStockInfo": "Hết hàng - hàng tồn kho của sản phẩm đã chọn sẽ bằng 0 trong cửa hàng tương ứng",
    "TokoCancelOrderStockInfo": "Hết hàng - hàng tồn kho của sản phẩm đã chọn sẽ bằng 0 trong cửa hàng tương ứng",
    "BLCancelOrderStockInfo": "Khi hủy đơn hàng, hàng tồn kho của sản phẩm trong đơn hàng sẽ bằng 0 trong cửa hàng tương ứng",
    "AlreadyProcessed": "Đã xử lý",
    "Grab": "Grab [9:00-16:00]",
    "GOSENDInstant": "GO-SEND Instant [6:00-18:00]",
    "GOSENDSameDay": "GO-SEND Same Day [6:00-15:00]",
    "AvailableTimeToPickup": "Thời gian sẵn sàng nhận đơn",
    "ProductUnitPrice": "Đơn giá",
    "ShopCloseTime": "Thời gian cửa hàng đóng cửa",
    "ToCancellationInfo": "Tokopedia không hỗ trợ người bán từ chối yêu cầu hủy đơn hàng của người mua",
    "ToTrackingNoInfo": "8-17 kí tự",
    "trackingNoErrorMessage": "{min}-{max} kí tự",
    "BLTrackingNewNoInfo": "8-25 kí tự",
    "BLTrackingNoInfo": "Bắt buộc tạo thành từ 8-25 chữ cái và số",
    "TBuyerRequest": "Khách hàng yêu cầu",
    "TCourierProblem": "Nguyên nhân vận chuyển",
    "TOthers": "Nguyên nhân khác",
    "shopClosed": "Cửa hàng đóng cửa",
    "TWrongPriceOrWeight": "Giá hoặc trọng lượng không phù hợp",
    "TProductVariantUnavailable": "Thuộc tính hiện tại đã hết hàng",
    "TProductOutOfStock": "Sản phẩm đã hết hàng",
    "TRejectShipping": "Từ chối chuyển phát nhanh",
    "BLBusy": "Không thể xử lý đơn hàng",
    "BLOutdatedPrice": "Giá chưa cập nhật",
    "BLInappropriatePrice": "Giá không phù hợp",
    "BLVariantOutOfStock": "Sản phẩm hiện tại đã hết hàng",
    "BlSuppliersOutOfStock": "Nhà cung cấp hết hàng",
    "BLNoStock": "Sản phẩm đã bán hết",
    "ReceiveInfo": "Bạn có thể đồng thời nhận đơn hàng của Tokopedia, \nAkulaku, Bukalapak và Tiki, nhưng một khi đã nhận đơn hàng của Tokopedia và Tiki thì không thể hủy",
    "SelectStockEmpty": "Hàng tồn kho của sản phẩm đã chọn sẽ được làm trống",
    "DeleteKeepOneData": "Vui lòng giữ ít nhất 1 dữ liệu",
    "CourierTracking": "Mã vận đơn",
    "NoReturnOrder": "Không có yêu cầu hoàn nào cần xử lý",
    "InReturn": "Đang hoàn",
    "InCancel": "Đang hủy",
    "ReturnTips": "Chỉ hỗ trợ hủy một đơn hàng, khi chọn nhiều, mặc định xử lý đơn đầu tiên",
    "NoCancellationOrder": "Không có yêu cầu hủy nào cần xử lý",
    "PrintDate": "Ngày in",
    "BuyerNote": "Ghi chú của người bán",
    "message": "Tin nhắn",
    "seeMore": "Xem { more } sản phẩm",
    "HistoryOrderCompleted": "Đơn hàng đã hoàn thành",
    "HistoryOrderCancellation": "Từ chối hoàn thành công",
    "HistoryOrderReturned": "Đơn hàng đã hoàn",
    "HistoryOrderShipping": "Đơn hàng đã được gửi",
    "HistoryCancelOrder": "Đã hủy đơn hàng",
    "HistoryReject": "Người bán từ chối hủy đơn hàng",
    "HistoryCreateOrder": "Thêm đơn hàng",
    "Reject": "Từ chối",
    "Confirm": "Xác nhận",
    "AcceptInfo": "Nếu chấp nhận thì đơn hàng này sẽ bị hủy ngay lập tức, và bất kì khoản thanh toán nào được tạo bởi khách hàng sẽ bị hoàn lại. Vui lòng không tiếp tục gửi hàng. Nếu bạn đã gửi hàng, vui lòng từ chối yêu cầu hủy này.",
    "Images": "Hình ảnh",
    "AcceptReturn": "Chấp nhận hoàn",
    "Respond": "Phản hồi",
    "Reason": "Nguyên nhân",
    "RequestID": "Yêu cầu ID",
    "ReasonFromBuyer": "Nguyên nhân từ khách hàng",
    "qtyToShip": "Số lượng hàng gửi đi",
    "Qty": "Số lượng",
    "buyer": "Tên khách hàng",
    "orders": "Đơn hàng",
    "CODNotSupported": "Không hỗ trợ COD",
    "UndeliverableArea": "Khu vực không thể giao hàng",
    "CustomerRequest": "Yêu cầu của khách hàng",
    "OutOfStock": "Hết hàng",
    "History": "Lịch sử",
    "NoNext": "Không, tiếp tục",
    "YesNext": "Có, tiếp tục",
    "Print": "In",
    "Deleted": "Đã xóa",
    "Retry": "Thử lại",
    "AcceptRejectInfo": "Bạn có chấp nhận lý do hủy đơn hàng?",
    "receiveOrderTip": "Bạn có xác nhận xử lý đơn hàng đã chọn không?",
    "selectCancelTips": "Bạn có muốn hủy tất cả đơn hàng với cùng 1 lý do?",
    "AddPickingStatus": "Thêm trạng thái",
    "SetPickStatusConfirm": "Xác nhận",
    "PickApplyToAll": "Áp dụng cho tất cả",
    "SetPickStatusTitle": "Cài đặt trang thái lấy hàng",
    "SetPickStatusInMass": "Cài đặt Trạng thái lấy hàng hàng loạt",
    "PickingSetTip": "Chú ý: Một khi cài đặt thành Đã chọn sẽ không còn được hỗ trợ thay đổi trạng thái nữa, vui lòng lưu ý.",
    "PrintPicking": "In phiếu lấy hàng",
    "PrintPackingList": "In phiếu đóng gói",
    "PrintInvoice": "In hóa đơn",
    "PrintLabel": "In vận đơn",
    "ProcessingCancellation": "Quá trình hủy bỏ",
    "ProcessingReturns": "Xử lý hoàn",
    "ReceiveOrder": "Đơn hàng đã nhận",
    "orderSelected": "Đơn hàng đã chọn",
    "shippingfeetip": "Tổng phí ship của Bukalapak sẽ được tính sau khi giao hàng",
    "variationdeleted": "Đã xóa sản phẩm hiện tại",
    "Sync": "Đồng bộ",
    "tipsWait": "Tính năng \"Sắp xếp giao hàng\" sẽ sớm hoạt động. Vui lòng vào csàn TMĐT của cửa hàng này để xử lý giao hàng",
    "variation": "Thuộc tính",
    "MSKU": "MSKU",
    "SKU": "SKU",
    "Total": "Tổng",
    "SellerDiscountTotal": "Người bán giảm giá",
    "Insurance": "Bảo hiểm",
    "Tax": "Thuế",
    "Shipping": "Phí ship",
    "subtotal": "Tổng phụ",
    "readyToShip": "Sẵn sàng giao hàng",
    "timeSlots": "Khoảng thời gian",
    "selectAddress": "Chọn địa chỉ",
    "address": "Địa chỉ",
    "selectCourier": "Chọn công ty vận chuyển",
    "logistics": "Vận chuyển",
    "reasonForCancellation": "Nguyên nhân hủy",
    "qtyToCancel": "Số lượng hủy",
    "qtyOrdered": "Số lượng mua",
    "ArrangeShipment": "Chuẩn bị hàng",
    "updateOrder": "Cập nhật",
    "CancelOrder": "Hủy đơn hàng",
    "notes": "Ghi chú",
    "item": "Sản phẩm",
    "shippingAddress": "Địa chỉ giao hàng",
    "customerEmail": "Email",
    "contactNo": "Số điện thoại liên lạc",
    "promiseToShipBefore": "Hẹn trước .. giao đến",
    "AWBTrackingNumber": "AWB / Mã vận đơn",
    "shippingType": "Loại vận chuyển",
    "paymentType": "Phương thức thanh toán",
    "orderType": "Loại đơn hàng",
    "store": "Tên cửa hàng",
    "date": "Ngày",
    "customerDetails": "Chi tiết khách hàng",
    "orderInfo": "Thông tin đơn hàng",
    "processingFlow": "Quy trình xử lý",
    "orderDetailId": "Mã đơn hàng  { orderId }",
    "updateAt": "Thời gian cập nhật",
    "createAt": "Giờ tạo",
    "orderStatus": "Trạng thái",
    "customerName": "Tên người mua",
    "orderId": "Mã đơn hàng",
    "amountMax": "Số tiền tối đa",
    "amountMin": "Số tiền tối thiểu",
    "amount": "Số tiền",
    "createDate": "Giờ tạo",
    "shippingCarrier": "Nhà vận chuyển",
    "channelName": "Kênh",
    "keyWord": "Tìm kiếm mã đơn hàng, SKU hoặc Tên khách hàng",
    "refundableShippingFeeIsCalculatedIn": "Phí vận chuyển đã được bao gồm trong số tiền hoàn trả, vui lòng lọc để xem",
    "youDontHaveOperationAuthorityFeature": "Trong thời gian gói còn hiệu lực, lượng đơn hàng tích lũy được tạo ra đã đạt {x} đơn hàng. Sau khi vượt quá {y} đơn hàng, chức năng Đơn hàng sẽ không khả dụng. Vui lòng đăng ký gói hoặc liên hệ với bộ phận CSKH để nâng cấp gói.",
    "understood": "Đã hiểu",
    "disableDefaultRules": "Tắt quy tắc mặc định",
    "enableDefaultRules": "Bật quy tắc mặc định",
    "theDefaultRuleIsSuccessfullyDeactivated": "Tắt thành công Quy tắc mặc định",
    "theDefaultRuleIsSuccessfullyActivated": "Bật thành công Quy tắc mặc định",
    "ifTheOrderDoesNotMeet": "Nếu đơn hàng không đáp ứng bất kỳ quy tắc nào đã tạo, sẽ không thể tạo phiếu xuất kho để khấu trừ tồn kho",
    "afterDisableTheOrderWillOnly": "Sau khi đóng, đơn hàng sẽ chỉ phân bổ kho theo quy tắc phân kho đã tạo, lập chứng từ xuất kho để khấu trừ kho.",
    "inOrderToEnsureThatThe": "Để đảm bảo kho hàng chính có thể được khấu trừ bình thường, khi quy tắc kho phụ không được định cấu hình, quy tắc mặc định không hỗ trợ đóng",
    "defaultRuleWhenNoRuleIs": "Quy tắc mặc định: Khi không có quy tắc nào được tạo thủ công, kho sẽ được phân chia theo kho của phiếu nhập kho mới được tạo của MSKU.",
    "noticeWhenTheRuleNotManually": "Mẹo: Quy tắc mặc định không hỗ trợ đóng khi quy tắc được tạo thủ công trống; sau khi tạo quy tắc theo cách thủ công, đơn đặt hàng sẽ không được xử lý theo quy tắc mặc định; nếu có nhiều quy tắc phân bổ trong cùng một kho, chúng sẽ được phân bổ theo quy tắc ưu tiên",
    "country": "Quốc gia/Khu vực",
    "IntegrateNow": "Tích hợp ngay",
    "noconnected": "Bạn không có Cửa hàng nào được tích hợp, vui lòng tích hợp một cửa hàng",
    "enableTips": "Sau khi bật, quy tắc này sẽ ảnh hưởng đến phân bổ kho và đơn hàng, hãy thận trọng khi thao tác",
    "youSureToEnableAre": "Xác nhận bật?",
    "setAtLeastOneConditionPlease": "Vui lòng cài đặt ít nhất một điều kiện phân phối",
    "deletedSuccessfully": "Xóa thành công",
    "youSureToDeleteAre": "Bạn có chắc chắn xóa?",
    "mskuAdd": "Thêm MSKU",
    "logisticAdd": "Thêm vận chuyển",
    "storeAdd": "Thêm Cửa hàng",
    "disabledWarehouseWillNoLongerBe": "Sau khi vô hiệu hóa, các kho hàng sẽ không được phân bổ theo quy tắc này nữa",
    "youSureYouWantToDisable": "Xác nhận vô hiệu hóa Quy tắc này?",
    "deleteMskuRule": "Quy tắc XXX Xóa MSKU: #XXX#",
    "addMskuRule": "Quy tắc XXX Thêm MSKU: #XXX#",
    "orderAmountIsAdjustedFrom": "Quy tắc XXX Giá trị đơn hàng từ xx~xx điều chỉnh thành XX~XX",
    "deleteLogisticsRule": "Quy tắc XXX Xóa #XXX# công ty vận chuyển",
    "addLogisticRule": "Quy tắc XXX Thêm #XXX# công ty vận chuyển",
    "ruleNameOfRules": "Quy tắc XXX đổi tên thành #XXX#",
    "deleteStoreRule": "Quy tắc XXX Xóa #XXX# cửa hàng",
    "addStoreRule": "Quy tắc XXX Thêm #XXX# cửa hàng",
    "priorityOfRuleUpdated": "Mức ưu tiên của Quy tắc XXXX từ X chuyển sang X",
    "historyOperation1": "Lịch sử thao tác",
    "logisticSelectedX": "Đã chọn {x} Công ty vận chuyển",
    "storeSelectedX": "Đã chọn X cửa hàng",
    "warehouseAllocation": "Kho phân phối",
    "add": "Thêm",
    "amountOrder": "Giá trị đơn hàng",
    "nameLogistic": "Tên vận chuyển",
    "nameStore": "Tên cửa hàng",
    "conditionSelect": "Điều kiện đã chọn",
    "masterSkuDesignated": "Chỉ định Master SKU",
    "orderAmountRangeSpecify": "Chỉ định Phạm vi Giá trị đơn hàng",
    "logisticDesignated": "Chỉ định Vận chuyển",
    "storeDesignated": "Chỉ định Cửa hàng",
    "conditionOptional": "Điều kiện được chọn",
    "setAtLeastOneAllocationCondition": "Vui lòng cài đặt ít nhất một điều kiện phân phối để đảm bảo hoàn thành quy tắc cài đặt",
    "settingCondition": "Cài đặt điều kiện",
    "informationBasic": "Thông tin cơ bản",
    "ifTheOrderShippingWarehouseRule": "Mẹo: Nếu chưa thêm quy tắc giao hàng hoặc SKU chính không tồn tại trong kho đã đặt quy tắc, thì không thể tạo phiếu xuất kho để khấu trừ hàng tồn kho, vui lòng đặt quy tắc; nếu có nhiều quy tắc phân bổ trong cùng một kho, thì chỉ định dựa vào Mức độ ưu tiên của quy tắc",
    "ruleAdd": "Quy tắc Thêm vào",
    "disable": "Tắt",
    "enable": "Bật",
    "edit": "Chỉnh sửa",
    "operate": "Thao tác",
    "time": "Thời gian",
    "conditionAllocation": "Điều kiện phân phối",
    "nameRule": "Tên quy tắc",
    "priority": "Mức ưu tiên",
    "upTheSearch": "Tìm kiếm duy nhất{X}",
    "moreMax": "Tối đa { maxLength }",
    "separateMultipleInfoWithCommasOr": "Phân cách nhiều thông tin bằng dấu phẩy hoặc dấu cách",
    "addSearchType": "Thêm loại tìm kiếm",
    "advancedSearch": "Tìm kiếm nâng cao",
    "mskuIsNotEqualTo": "MSKU không bằng",
    "skuIsNotEqualTo": "SKU không bằng",
    "mskuIsEqualTo": "MSKU bằng",
    "skuIsEquals": "SKU bằng",
    "shopeebukalapakOrdersUseGineeTemplateFor": "Đơn hàng Shopee/ Bukalapal/ Tokopedia sử dụng mẫu của Ginee để in vận đơn",
    "selectPrinter": "Chọn máy in",
    "printCompleted1": "In hoàn tất",
    "printing2": "Đang in",
    "checkTheDetails": "Kiểm tra chi tiết",
    "action1": "Thao tác",
    "printQuantity": "Số lượng in",
    "printStatus": "Trạng thái in",
    "printingAdmin": "Người in",
    "highspeedBatchPrintingHistory": "Lịch sử in tốc độ cao hàng loạt",
    "highspeedPrintingLabelHistory": "Lịch sử nhãn in tốc độ cao",
    "printAgain": "In lại",
    "failureReason": "Nguyên nhân thất bại",
    "shipmentNumber": "Mã vận đơn",
    "order": "Đơn hàng",
    "printFailed": "In thất bại",
    "printSuccessfully": "In thành công",
    "printing1": "Đang in",
    "cautionToEnsureNormalPrintingOperation": "Cảnh báo: Để đảm bảo việc in đơn diễn ra bình thường, vui lòng không đóng/ làm mới trang hiện tại, nếu không nhiệm vụ in hiện tại sẽ bị dừng hoặc in lặp lại cũng một mặt!",
    "IfPrintingFailsPleaseMove": "3. Nếu in không thành công, vui lòng chuyển đến danh sách lỗi in để kiểm tra nguyên nhân lỗi",
    "DuringPrintingIfSomeOrders": "2. Trong quá trình in, một số đơn hàng in thất bại, điều này không ảnh hưởng đến tiến độ in chung",
    "AfterThePrintingIsCompleted": "1. Sau khi in xong, đơn hàng sẽ tự động được đánh dấu là đã in, quý khách có nhu cầu thay đổi vui lòng vào danh sách đặt hàng để chỉnh sửa thủ công",
    "printingInstructions": "Hướng dẫn in ấn",
    "totalXxxAndXxPrinted": "Tổng { total } , đã in { x }",
    "printCompleted": "In hoàn tất",
    "printing": "Đang in",
    "printingProgress": "Tiến trình in ấn:",
    "highSpeedPrintingTaskCenter": "Trung tâm trợ giúp in tốc độ cao",
    "recheck": "Kiểm tra lại",
    "ifYouConfirmThatThePlugin": "Nếu bạn xác nhận rằng trình cắm đã được cài đặt và kích hoạt, vui lòng nhấp vào nút phát hiện lại để in",
    "notInstalledClickHereToDownload": "Chưa được cài đặt, hãy nhấn vào đây để tải xuống plugin",
    "installed": "Đã cài đặt",
    "detectedWeFoundThatYouHave": "Sau khi kiểm tra, phát hiện ra rằng bạn chưa cài đặt hoặc kích hoạt trình cắm in tốc độ cao, vui lòng thử các phương pháp sau:",
    "tips": "Gợi ý",
    "inOrderToImproveThePrinting": "Để cải thiện tốc độ và trải nghiệm in, khuyến khích sử dụng in tốc độ cao,",
    "startPrinting": "Bắt đầu in",
    "printer": "Máy in",
    "noteToEnableHighspeedPrintingThe": "Lưu ý: Để kích hoạt in tốc độ cao, phần mềm in tốc độ cao plug-in phải được tải xuống và kích hoạt",
    "highspeedPrinting": "In tốc độ cao (in đơn lẻ không giới hạn, nhưng không hỗ trợ xem trước khi in)",
    "regularPrintingPrintUpTo": "In thường (in đến 100 đơn hàng trong một lần in, tạo nhãn PDF cho tất cả đơn hàng, tốn nhiều thời gian hơn)",
    "printingMethod": "Hình thức in",
    "haveBeenPrinted": "và { x }  trong số đó đã được in",
    "dataHaveBeenSelectedAnd": "Dữ liệu { x }  đã được chọn",
    "printData": "In dữ liệu",
    "customLabelOnlySupports": "Tùy chỉnh nhãn (Chỉ hỗ trợ đơn Shopee/Tokopedia/Bukalapak)",
    "channelLabelAllChannelOrdersSupport": "Nhãn kênh (Tất cả đơn hàng trên kênh đều hỗ trợ in trừ đơn của Shopify/Woocommerce)",
    "setUpPrintTemplates": "Thiết lập mẫu in",
    "printShippingLabelAll": "In vận đơn (tất cả)",
    "printShippingLabelSelected": "In vận đơn (đã chọn)",
    "selectAllOrders": "Chọn tất cả đơn hàng",
    "pleaseConfirmWhetherToTurnOff": "Vui lòng xác nhận có tắt xử lý tồn kho không?",
    "putOnHold": "Gác lại",
    "pickingNote": "Ghi chú lấy hàng",
    "dropshipper": "Dropshipper",
    "storeName": "Tên cửa hàng",
    "setReadyToShip": "Đặt sẵn sàng lên tàu",
    "becauseYouHaveNotIntegrateThe": "Do chưa ủy quyền cho cửa hàng kênh, bạn không thể sử dụng tính năng nhân bản, vui lòng hoàn thành ủy quyền trước",
    "integrateStore": "Cửa hàng ủy quyền",
    "integrationGuide": "Hướng dẫn ủy quyền",
    "MinutesToLearnAboutGinee": "1 phút tìm hiểu về Ginee",
    "shortcuts": "Phím tắt",
    "theStoreHasNotBeenAuthorised": "Cửa hàng chưa được ủy quyền, không thể quản lý sản phẩm và đơn hàng online",
    "inventoryManagement": "Quản lý hàng tồn kho",
    "productManagement": "Quản lý sản phẩm",
    "scrapeProduct": "Sản phẩm Sưu tập",
    "masterProductSales": "Thống kê doanh số sản phẩm chính",
    "try": "Thử ngay",
    "productActivitySetting": "Cài đặt hoạt động sản phẩm",
    "capitalOccupationRatio": "Tỷ lệ chiếm dụng vốn (%)",
    "stockTakesUpFunds": "Vốn tồn kho chiếm dụng",
    "numberOfMskus": "Số lượng MSKU",
    "exportMsku": "Xuất MSKU",
    "purchaseDuration": "Thời gian mua hàng",
    "doesItNeedToBeReplenished": "Có phải cần bổ sung?",
    "availableDaysDays": "Có sẵn trong ngày (ngày)",
    "averageDailySales": "Doanh thu trung bình hàng ngày",
    "lastMonth": "Doanh số 1 tháng",
    "lastTwoWeeks": "Doanh số bán hàng trong 14 ngày qua",
    "lastWeeks": "Doanh số bán hàng trong 7 ngày qua",
    "lastThreeDays": "Doanh số bán hàng trong 3 ngày qua",
    "numberOfRatings": "Số lượt đánh giá",
    "ratings": "Điểm",
    "likes": "Lượt thích",
    "pageViews": "Lượt xem",
    "productActivityHasBeenSetFor": "Hoạt động sản phẩm đã được cài đặt cho danh mục này, vui lòng cài lại",
    "sales": "Doanh số",
    "effectiveSales": "Bán hàng hiệu quả",
    "newItem": "Mới",
    "lowSellingItem": "Bán chậm",
    "normalItem": "Bình thường",
    "trendingItem": "Mạnh",
    "bestSellingItem": "Bùng nổ",
    "productActivity": "Hoạt động sản phẩm",
    "accounting": "Quản lý kế toán",
    "categoryRange": "Phạm vi danh mục",
    "timeRange": "Khung thời gian",
    "productDimension": "Cấp sản phẩm",
    "salesType": "Loại bán hàng",
    "salesRange": "Phạm vi bán hàng",
    "allCategory": "Tất cả danh mục",
    "recent": "Gần đây",
    "oneDay": "1 ngày",
    "threeDay": "3 ngày",
    "aWeek": "1 tuần",
    "twoWeeks": "2 tuần",
    "aMonth": "1 tháng",
    "threeMonths": "3 tháng",
    "halfYear": "Nửa năm",
    "onlySupportForIndonesia": "Chỉ hỗ trợ Indonesia",
    "addProductActivity": "Thêm hoạt động sản phẩm",
    "cSupportToManuallyPullAccurate": "c. Hỗ trợ bật đồng bộ các thay đổi tồn kho của Accurate đến Ginee, và cập nhật tồn kho đã liên kết của Ginee (tắt theo mặc định, vui lòng bật một cách thận trọng)",
    "bAfterTheOrderIsPlaced": "b. Sản phẩm Chính của Ginee được xuất từ kho hàng sẽ làm giảm đồng bộ hóa tồn kho của Accurate",
    "aInboundStockForMasterProduct": "a. Khi nhập kho sản phẩm Chính tại Ginee sẽ đồng bộ nhập tồn kho tại Accurate",
    "StockSync": "Đồng bộ Tồn kho",
    "noteOrderInformationSynchronizationIsA": "Lưu ý: Đồng bộ hóa đơn bán hàng là đồng bộ một chiều được tự động đẩy từ Ginee sang Accurate mỗi ngày",
    "createdTheWarehousePairingRelationship": "Sau khi ánh xạ cửa hàng thành công với Chính xác, hóa đơn bán hàng sẽ được tạo tự động khi đơn hàng Ginee được vận chuyển ra khỏi kho và được chuyển sang Chính xác.",
    "OrderDistribution": "4. Phân bổ đơn hàng",
    "noteTheSynchronizationOfMasterProduct": "Lưu ý: Đồng bộ thông tin sản phẩm Chính là đồng bộ một chiều từ Ginee sang Accurate. Sau khi tạo sản phẩm Chính mới, nó sẽ được đồng bộ ngay",
    "afterCreatedTheWarehousePairingRelationship": "Sau khi mối liên kết kho được tạo, nó hỗ trợ đồng bộ sản phẩm Chính của kho Ginee với kho Accurate",
    "ProductPlacement": "3. Phân bổ sản phẩm",
    "psItIsNotRecommendedTo": "PS: Khuyến khích không nên liên kết kho hàng bên thứ ba với kho Accurate, sẽ có nguy cơ rối loạn tồn kho",
    "afterTheAuthorizationIsCompletedYou": "Sau khi ủy quyền hoàn tất, bạn có thể quản lý việc khớp kho và cửa hàng trong [Cài đặt - Cài đặt tài chính] và đặt quy tắc đồng bộ. Đồng bộ sản phẩm/tồn kho/hóa đơn bán hàng chỉ có thể thực hiện bình thường sau khi khớp",
    "BasicConfiguration": "2. Cấu hình cơ bản",
    "pleaseMakeSureToFillIn": "Vui lòng đảm bảo điền tài khoản/ mật khẩu Accurate chính xác và chọn Cơ sở dữ liệu để hoàn tất ủy quyền",
    "Authorization": "1. Ủy quyền",
    "accurateOperationGuide": "Hướng dẫn vận hành Accurate",
    "averageDailySalesSettings": "Cài đặt doanh số trung bình hàng ngày",
    "averageSalesForTheDayAs": "Doanh số trung bình trong ngày, còn gọi là doanh số hàng ngày",
    "clickAuthorizeYouWillBeRedirected": "Nhấn [Ủy quyền], bạn sẽ được dẫn đến trang Accurate, vui lòng đảm bảo điền tài khoản/ mật khẩu Accurate chính xác và hoàn tất ủy quyền sau khi đăng nhập",
    "onSaleDaysDays": "Số ngày có sẵn (ngày)",
    "purchaseTimedays": "Thời gian mua (ngày)",
    "AfterTheAuthorizationIsCompleted": "2. Sau khi hoàn tất ủy quyền, vui lòng hoàn thành kết nối kho hàng trong quản lý cấu hình Accurate",
    "PleaseSelectAtLeast": "1. Vui lòng chọn ít nhất 1 Cơ sở dữ liệu để hoàn tất ủy quyền",
    "remainder": "Nhắc nhở",
    "authorizationSucceeded": "Ủy quyền thành công",
    "theAccurateWarehouseInformationIsBeing": "Kho và kế toán của Accurate đang được đồng bộ, vui lòng tiến hành cài đặt khớp lệnh khởi tạo tài chính!",
    "continueAuthorization": "Tiếp tục ủy quyền",
    "reauthorize": "Ủy quyền lại",
    "channelFailureReason": "Nguyên nhân thất bại từ kênh:",
    "authorizationFailed": "Ủy quyền thất bại",
    "deleteAuthorization": "Xóa ủy quyền",
    "authorizer": "Người ủy quyền",
    "authorizationTime": "Thời gian ủy quyền",
    "connected": "Đã liên kết",
    "authorizationStatus": "Trạng thái đồng bộ",
    "ServiceProvider": "Nhà cung cấp dịch vụ",
    "accountName": "Tên tài khoản",
    "deleteFailed": "Xóa thất bại",
    "theBindingRelationshipBetweenTheAccurate": "Mối liên kết giữa kho Accurate và kho Ginee đã bị hủy, việc đồng bộ thông tin sản phẩm/ đơn hàng/ tồn kho với ACCURATE sẽ không còn được thực hiện",
    "onceDeletedTheBindingRelationshipBetween": "Một khi xóa, mối liên kết giữa kho Ginee và kho Accurate sẽ hủy, và các thông tin sản phẩm/ đơn hàng/ tồn kho sẽ không còn đồng bộ với Accurate",
    "areYouSureWantToDelete": "Xác nhận xóa chứ?",
    "productSuccessfullySynchronized": "Đồng bộ sản phẩm thành công",
    "whenDistributeGineeWarehouseProductGinees": "Khi xuất kho sản phẩm từ kho Ginee, hàng hóa MSKU trong kho Ginee sẽ được sử dụng trực tiếp bao gồm tồn kho của kho Accurate, vui lòng vận hành cẩn thận",
    "areYouSureToSyncGinee": "Xác nhận đồng bộ sản phẩm trong kho Ginee đến kho Accurate?",
    "channelReturnFailureReason": "Nguyên nhân thất bại trả từ kênh:",
    "pullFailed": "Kéo thất bại",
    "pullSuccessfully": "Kéo thành công",
    "pull": "Kéo",
    "afterManuallyPullingTheWarehouseInformation": "Sau khi kéo thủ công thông tin kho hàng, vui lòng đảm bảo hãy liên kết kho hàng mới thêm trước và biết rằng kho hàng vô hiệu sẽ bị xóa trực tiếp, đồng thời mối liên kết sẽ bị xóa đồng bộ, việc đồng bộ dữ liệu cũng sẽ không còn được thực hiện.",
    "pullWarehouse": "Kéo kho hàng",
    "warehouseStockSuccessfullyUpdated": "Tồn kho trong kho hàng cập nhật thành công",
    "onceTheStockOfTheThirdparty": "Khi tồn kho của kho bên thứ ba không khớp với hệ thống, thao tác này sẽ cập nhật tồn kho trong kho trực tiếp của sản phẩm hiện có đến giá trị tồn kho của kho bên thứ ba, và tạo ra một nhật ký điều chỉnh. Thông tin cụ thể có thể được xem trong nhật ký kéo tồn.",
    "areYouSureToPullStock": "Xác nhận kéo tồn kho từ kho Accurate?",
    "theWarehouseBindingRelationshipHasBeen": "Mối liên kết kho hàng đã được hủy thành công",
    "afterTheUnbindingIsSuccessfulThe": "Sau khi hủy liên kết thành công, thông tin sản phẩm / đơn hàng đã nhận trong kho Accurate sẽ không bị ảnh hưởng và dữ liệu được tạo trong kho Ginee liên kết ban đầu sẽ không được đồng bộ đến Accurate",
    "areYouSureToUnbindThe": "Xác nhận hủy liên kết kho hàng hiện tại?",
    "reasonTheCurrentWarehouseHasBeen": "Nguyên nhân: Kho lưu trữ hiện tại đã liên kết với kho Accurate khác, vui lòng hủy liên kết và thử lại",
    "bindingFailed": "Liên kết thất bại",
    "bindingIsSuccessfulPleaseDoNot": "Liên kết thành công, vui lòng không lặp lại thao tác",
    "bind": "Liên kết",
    "tipOnlySupportsSelectingWarehouseThat": "Mẹo: Chỉ hỗ trợ chọn kho lưu trữ được bật trong danh sách kho Ginee",
    "syncWarehouse": "Đồng bộ kho hàng",
    "unbindWarehouse": "Hủy liên kết kho",
    "bindTime": "Thời gian liên kết",
    "accurateWarehouse": "Kho hàng Accurate",
    "accurateWarehouseInformationWillNotBe": "Thông tin kho Accurate sẽ không được đồng bộ với danh sách kho Ginee, và thông tin sản phẩm Chính/ đơn hàng/ tồn kho sẽ được đồng bộ theo quan hệ liên kết với kho hiện có của Ginee",
    "accurateSettingManagement": "Quản lý cấu hình Accurate",
    "synchronizeWarehouseInformationUnderAccurateDatebase": "Đồng bộ thông tin kho trong Cơ sở dữ liệu Accurate {x}, thêm {x} kho, xóa {x} kho",
    "syncGineeWarehouseProductTo": "Đồng bộ sản phẩm trong kho Ginee {x} đến kho Accurate",
    "syncStockFromAccurateWarehouse": "Đồng bộ tồn kho từ kho hàng Accurate *đến kho Ginee*",
    "accurateWarehouseUnbindFrom": "Kho hàng Accurate \"hủy liên kết\" với kho",
    "accurateWarehouseBindTo": "Kho hàng Accurate \"liên kết* kho",
    "operationLogIsOnlyRetainedFor": "Nhật ký hoạt động chỉ được giữ lại trong 15 ngày qua và không thể xem thông tin hoạt động sau hơn 15 ngày",
    "send": "Gửi",
    "translate": "dịch",
    "failedToSendMessage": "Gửi tin nhắn thất bại",
    "resend": "Gửi lại",
    "sendToBuyer": "Gửi cho người mua",
    "translationFailed": "Dịch thất bại",
    "translatedx": "Dịch thành công: {x}",
    "translationInProgress": "Đang dịch",
    "automaticTranslationHasBeenTurnedOn": "Dịch tự động đã được bật cho bạn",
    "autoTranslate": "Bản dịch tự động",
    "buyersLanguage": "Ngôn ngữ người mua",
    "afterCloseItThereWill": "Sau khi tắt, khi người mua có tin nhắn mới cần gửi, sẽ không còn âm báo tin nhắn nữa",
    "notificationSound": "Âm báo tin nhắn",
    "uploadedNowlengthSheets": "Đã tải lên {nowLength}",
    "isAUniqueIdentifierForThe": "Mã nhận dạng duy nhất của sản phẩm chính, không thể sửa đổi sau khi xác nhận",
    "pleaseConfirmWhetherTheStoresChannel": "Vui lòng xác nhận xem SKU kênh của cửa hàng có giá trị hay không, nếu không, chúng tôi sẽ lấy tiêu đề kênh sản phẩm làm MSKU của sản phẩm chính",
    "errnameToko": "Tên Sản phẩm phải giữa 1 - 70 kí tự",
    "sizeSettingTemplate": "Cài đặt Mẫu Kích thước",
    "mappingCategory": "Danh mục tương ứng",
    "templateSettingLogistic": "Cài đặt Mẫu Vận chuyển",
    "rangeEnable": "Cài đặt sản phẩm",
    "globalsMobileErrorInfoLength": "Số điện thoại phải từ giữa {min}-{max} chữ số",
    "globalsMobileErrorInfo": "Vui lòng nhập số điện thoại hợp lệ",
    "masterSku": "Master SKU",
    "outboundPurchasePrice": "Đơn giá mua hàng",
    "hasbinded": "Thuộc tính này đã được liên kết với MSKU: {sku} Vui lòng cập nhật hàng tồn kho trong \"Quản lý tồn kho\"",
    "deletecustomersuccess": "Đã xóa thành công!",
    "reasonforpushedfailed": "Lý do thất bại: Sản phẩm đã bị xóa hoặc bị cấm hoặc đang trong chiến dịch khuyến mãi (Có thể cập nhật hàng tồn kho sau khi chương trình khuyến mãi kết thúc). Vui lòng kiểm tra sản phẩm",
    "purchasePricerequired": "Phải từ 0 đến 1000.000.000!",
    "createsuccess": "Tạo thành công",
    "atmost20tags": "Tối đa 20 Tag",
    "AddTag": "Thêm tag",
    "tagexist": "Tag đã tồn tại",
    "fenotempty": "Không thể dùng Dấu cách ở đầu và cuối Tag",
    "taginputlimit": "Tag phải từ giữa 3-40 kí tự",
    "selecttag": "Chọn tag",
    "withordernostock": "Có Đơn nhưng hết hàng",
    "withisku": "Có MSKU",
    "noisku": "Không có MSKU",
    "withskuwithoutisku": "Có SKU và không có MSKU",
    "nosku": "Không có SKU",
    "selectskusit": "Chọn Điều kiện SKU",
    "Pleaseenter": "Vui lòng nhập",
    "selectsku": "Chọn MSKU",
    "emailreminder": "Email Nhắc nhở",
    "safetystockarea": "Khi Tồn kho có sẵn <=",
    "bindedisku": "MSKU đã đính kèm",
    "most20bundles": "Tối đa 20 sản phẩm",
    "NAME": "Tên",
    "PleaseEnter": "Vui lòng nhập",
    "disablebundletip": "Để đảm bảo tính chính xác của việc khấu trừ hàng tồn kho, không thể sửa đổi SKU và Số lượng hàng tồn kho được liên kết khi chỉnh sửa MSKU đính kèm",
    "updatesuccess": "Cập nhật thành công!",
    "ISKUtype": "Loại MSKU",
    "bundleisku": "MSKU Đính kèm",
    "isku": "MSKU",
    "ProductNameISKU": "Tên sản phẩm & ISKU",
    "Image": "Hình ảnh",
    "bundledproduct": "Sản phẩm Đính kèm",
    "selectbundle": "Chọn MSKU",
    "AddBundleISKU": "Thêm SKU đính kèm",
    "bukadisabled": "Cài đặt thời gian xử lý đơn hàng theo cửa hàng",
    "pleasecheckpreorderpart": "Vui lòng kiểm tra thông tin đặt trước",
    "shopeedisabled": "Tôi sẽ gửi trong vòng 2 ngày làm việc. (không bao gồm các ngày nghỉ lễ và ngày nghỉ dịch vụ chuyển phát nhanh)",
    "tokodisabled": "Kích hoạt Đặt hàng trước cho sản phẩm này nếu quá trình xử lý mất nhiều thời gian.",
    "UNKNOWNunittip": "Đơn vị thời gian không tồn tại, Vui lòng liên hệ với Ginee để giải quyết",
    "day": "ngày",
    "week": "tuần",
    "bukreorderdayworkday": "ngày làm việc để gửi đi",
    "bukreorderday": "ngày để gửi đi",
    "bukainstanhour": "giờ làm việc",
    "bukaregular": "Tôi sẽ gửi đi trong vòng 3 ngày làm việc. (không bao gồm các ngày nghỉ lễ và ngày nghỉ của dịch vụ chuyển phát nhanh)",
    "bukreorder": "Tôi cần",
    "bukainstan": "Tôi sẽ gửi đi trong",
    "tokopreorderday": "Ngày để gửi hàng",
    "tokopreorderweek": "Tuần để gửi hàng",
    "tokopreorder": "Tôi cần",
    "shopeepreorderday": "ngày làm việc để gửi hàng",
    "shopeepreorder": "Tôi cần",
    "max8pictures": "Tải lên tối đa 8 hình ảnh",
    "max5pictures": "Tải lên tối đa 5 hình ảnh",
    "uploadtip": "Tải hình ảnh lên theo quy tắc của Kênh sẽ là: Đối với Tokopedia, Bukalapak, Shopify, TikTok Shop, Blibli sẽ lấy 5 ảnh đầu tiên, Lazada 8 ảnh đầu tiên và Shopee 9 ảnh sản phẩm đầu tiên",
    "regular": "Định kỳ",
    "instan": "Ngay lập tức",
    "between18hours": "Trong 1-8 tiếng",
    "between330days": "Trong vòng 3-30",
    "max13weeks": "Trong vòng 1-13 tuần",
    "shopeepreorderdayAdd": "Star, Star +, Mall hỗ trợ lên đến 90 ngày làm việc",
    "between790": "Trong vòng 7-90 ngày làm việc",
    "max90days": "Trong 1-90 ngày",
    "preorderdeadline": "Thời hạn xử lý đơn hàng",
    "preorder": "Đặt trước",
    "novariationforlazd": "Không thể thêm hình ảnh vì sản phẩm này không có thuộc tính",
    "SafetystockHead": "Đặt giới hạn hàng tồn kho và nhận thông báo qua email để bổ sung khi Tồn kho có sẵn đạt đến số lượng thiết lập",
    "SpareStockkHead": "Tổng lượng Hàng tồn kho trong kho thực tế, sẽ không được bán trong kênh.",
    "WarehouseStockHead": "Tổng tồn kho thực tế, Tổng tồn kho = Kho dự phòng + Kho bị khóa + Kho có sẵn + Kho khuyến mãi",
    "inventorySkuHead": "50 ký tự đầu tiên của SKU cửa hàng trùng khớp theo mặc định",
    "NameHead": "Tên của MSKU được lấy tự động từ 160 ký tự đầu tiên của [tên sản phẩm-tên thuộc tính]",
    "BrandLogoTips": "Đề xuất tải lên: Hình ảnh 360 * 140, jpg / png / jpeg nhỏ hơn 2M",
    "MobileMustErrorInfo": "Phải được bắt đầu bằng 8, chứa 9-12 số",
    "bindnow": "Liên kết ngay",
    "Nospacesfront": "Phần đầu không được có dấu cách, vui lòng kiểm tra",
    "spaceforbidden": "Không được để trống, vui lòng kiểm tra",
    "range": "Trạng thái : Hoạt động, Đã hủy bỏ, Đã bán hết, Đã cấm bán",
    "inventorySkunotempty": "ISKU không được để trống!",
    "limitsafety": "Tồn kho an toàn phải từ giữa 0 ~ 999,999!",
    "mustlessthanwarehouse": "Tồn kho dự phòng không được nhiều hơn Tổng tồn kho",
    "sparelimit": "Tồn kho dự phòng phải từ giữa 0 và 999.999!",
    "mustbiggerthanspare": "Tồn kho dự phòng không thể lớn hơn Tổng tồn kho",
    "warehouselimit": "Tổng tồn kho phải dao động từ 0 đến 999.999!",
    "masterSkuMost200": "MSKU phải ít hơn 200 kí tự",
    "masterSkuMost50": "MSKU phải ít hơn 50 kí tự",
    "most50": "MSKU phải ít hơn 50 kí tự",
    "most160": "Tên phải ít hơn 300 kí tự",
    "clicktoedit": "Nhấp vào để chỉnh sửa",
    "alreadyexist": "MSKU đã tồn tại, SKU thuộc tính sẽ ràng buộc với MSKU này",
    "generaterules": "Quy tắc tạo MSKU theo mặc định: 1. MSKU sẽ lấy 50 ký tự đầu tiên của SKU theo mặc định. 2. Tổng tồn kho sẽ bằng với Tồn kho của thuộc tính, Tồn kho an toàn sẽ trống. 3. Khi MSKU được tạo, SKU của thuộc tính sẽ được liên kết.",
    "channel": "Kênh bán",
    "Operationguide": "Hướng dẫn Thao tác",
    "storeexplain": "Tạo MSKU cho một sản phẩm để quản lý tồn kho ở nhiều cửa hàng",
    "bystoremodal": "Tạo tồn kho SKU theo cửa hàng",
    "ImportByStore": "Tải lên theo cửa hàng",
    "Gotit": "Đã hiểu!",
    "Safetystock": "Tồn kho an toàn",
    "SpareStock": "Tồn kho dự phòng",
    "WarehouseStock": "Tổng tồn kho",
    "inventorySku": "MSKU",
    "Name": "Tên",
    "image": "Hình ảnh",
    "GenerationRules": "Quy tắc Tạo",
    "ClickContenttoEdit": "Nhấp vào nội dung để chỉnh sửa",
    "generatecount": "{productSum} sản phẩm ({availableNum} trong số {VariantSum} thuộc tính có thể tạo Sản phẩm chính). Thuộc tính bị ràng buộc với MSKU sẽ không thể Master SKU tại Ginee",
    "generateisku": "Tạo kho sản phẩm",
    "draftorfialed": "Vui lòng chỉ chọn bản nháp hoặc sản phẩm đăng lên thất bại",
    "uploadlimit": "Tệp lớn nhất: xlsx, xls chỉ 5MB. Có thể tải lên tối đa 1000 dữ liệu cùng một lúc.",
    "canbedownloaded": "Sản phẩm",
    "selectedstatus": "Trạng thái hiện tại của Sản phẩm đã chọn:",
    "donwloadtemp": "Download tệp tải lên",
    "DownloadFailedData": "Download dữ liệu thất bại",
    "importfail": "Một số dữ liệu thất bại",
    "importfinish": "Tải lên thành công",
    "Cancel": "Hủy",
    "fileerror": "Định dạng tệp không đúng",
    "close": "Đóng",
    "importing": "Đang tải lên",
    "importlabel": "Tải tệp lên",
    "upload": "Tải lên",
    "download": "Tải xuống",
    "templatetip": "Tải xuống tệp mẫu",
    "import": "Tải lên",
    "themaxtip2": "Mỗi lần tải lên, khách hàng có cùng một số điện thoại sẽ được hợp nhất.",
    "themaxtip1": "Gợi ý: Giới hạn tối đa tải lên là",
    "ActionsSuccessInfo": "Thao tác thành công!",
    "ShopeeTips": "Nhấp vào \"Bắt đầu Đồng bộ\" và hệ thống sẽ tự động mở cửa sổ Shopee, giúp bạn tiến hành đăng ký và ủy quyền.",
    "AlreadyChecked": "{selected} Đã chọn",
    "SubscriptionExtended": "Có thể gia hạn gói sử dụng, vui lòng nhấp vào \"Đăng ký\" hoặc liên hệ với chúng tôi để nâng cấp tài khoản của bạn",
    "SubscribeSuccess": "Nếu đăng ký thành công, bạn có thể kích hoạt các tính năng nâng cao. Vui lòng nhấp vào \"Đăng ký\" hoặc liên hệ với chúng tôi để nâng cấp tài khoản của bạn.",
    "SubscriptionExpired": "Xin lỗi, gói dùng thử đã hết hạn, vui lòng nhấp vào \"Đăng ký\" hoặc liên hệ với Ginee để nâng cấp tài khoản.",
    "PayNow": "Thanh toán ngay",
    "ThisFeatureIsOnlyPaidUsers": "Tính năng này chỉ dành cho nhà bán Ginee, vui lòng mua gói để sử dụng tính năng này!",
    "FoundANewGreatFeature": "Tính năng mới!",
    "urlplaceholder": "Vui lòng nhập Tên cửa hàng",
    "urltip": "Tên phải gồm 2-30 ký tự. Chỉ được phép sử dụng chữ viết thường và số",
    "urldesciption": "Vui lòng tạo Link Thành viên của cửa hàng",
    "memberurlmodaltitle": "Link trang thành viên",
    "CategoryLevel3": "Danh mục cấp 3",
    "CategoryLevel2": "Danh mục cấp 2",
    "RichTextDescription": "Nhập mô tả của bạn ở đây và áp dụng cho sản phẩm của bạn",
    "PleaseInput": "Vui lòng nhập",
    "selectall": "Chọn tất cả",
    "noauth": "Tài khoản của bạn không có quyền truy cập, vui lòng liên hệ với quản trị viên cửa hàng để yêu cầu hoặc nâng cấp",
    "NoProductYet": "Chưa có sản phẩm",
    "SeeMore": "Xem { more } sản phẩm",
    "SeeMoreExpand": "Xem thêm",
    "SaveAndNext": "Lưu & Tiếp tục",
    "NoHaveAccount": "Chưa có tài khoản?",
    "Requiredfields": "Bắt buộc điền, không được để trống!",
    "Somerequiredfields": "Thiếu thông tin bắt buộc, vui lòng điền vào",
    "Removetheseproducts": "Xóa các sản phẩm này",
    "GotoShippingSettings": "Cài đặt vận chuyển",
    "Somewithoutshipping": "Không thể lưu sản phẩm nếu không có tùy chọn vận chuyển. Vui lòng mở Tùy chọn vận chuyển trước khi chỉnh sửa sản phẩm.",
    "Discardcontent": "Bỏ qua có nghĩa là thông tin của sản phẩm sẽ giống như thông tin ban đầu trong cửa hàng",
    "Discardtip": "Bạn có chắc muốn bỏ qua thay đổi này?",
    "with": "Thay thế thành",
    "Changepriceto": "Giá thay đổi thành",
    "Reducebypercent": "Theo tỷ lệ phần trăm giảm xuống",
    "Reducebyamount": "Theo số tiền giảm xuống",
    "Addbypercent": "Theo tỷ lệ phần trăm tăng lên",
    "Addbyamount": "Theo số tiền tăng lên",
    "Basedoncurrentprice": "Theo giá hiện tại",
    "replace": "Thay thế",
    "addaftername": "Thêm vào sau tên",
    "addbeforename": "Thêm vào trước tên",
    "Changestockto": "Đổi tồn kho thành",
    "Example": "Ví dụ:",
    "errsize": "Bắt buộc điền, không được để trống",
    "BukalapakPrice": "Giá bán phải dao động từ 1 ～1.000.000.000",
    "phLazadaPrice": "Giá bán phải dao động từ 1 ～1.000.000.000",
    "LazadaPrice": "Giá bán phải dao động từ 1000 ～1.000.000.000",
    "TokopediaPrice": "Giá bán phải dao động từ 100～1.000.000.000",
    "phShopeePrice": "Giá bán phải dao động từ 2 ～1.000.000.000",
    "ShopeePrice": "Giá bán phải dao động từ 99 ～1.000.000.000",
    "pricetipBukalapak": "Giá bán phải dao động từ 1 ～1.000.000.000",
    "pricetipShopee": "Giá bán phải dao động từ 99 ～1.000.000.000",
    "errdescriptionBukalapak": "Mô tả sản phẩm phải từ 30 - 3000 kí tự",
    "errdescriptionShopee": "Mô tả sản phẩm phải từ 30 - 3000 kí tự",
    "errnameBukalapak": "Tên sản phẩm phải giữa 6 - 150 kí tự",
    "errnamelazada": "Tên sản phẩm phải giữa 2 - 255 kí tự",
    "allemptyorfilled": "Giá trị dài, rộng, cao để trống, hoặc đều điền đầy đủ giá trị",
    "errnameShopee": "Tên Sản phẩm phải giữa 5 - 100 kí tự",
    "spaceskuerr": "SKU không được có khoảng cách, vui lòng kiểm tra",
    "PleaseOneShipping": "Vui lòng cài đặt ít nhất một lựa chọn vận chuyển cho sản phẩm của bạn.",
    "pricetip": "Giá phải từ giữa 99 〜 1.000.000.000",
    "stocktip": "Hàng tồn kho phải từ 0 〜 999,999",
    "Discardchanges": "Bỏ qua thay đổi",
    "update": "Cập nhật",
    "draft": "Lưu thành bản nháp",
    "publish": "Đăng lên",
    "Logistic": "Vận chuyển",
    "ParcelSize": "Kích thước gói hàng",
    "Width": "Chiều rộng",
    "Length": "Chiều dài",
    "Height": "Chiều cao",
    "Weight": "Trọng lượng",
    "price": "Giá bán",
    "VariantName": "Tên thuộc tính",
    "parentSku": "Parent SKU",
    "productImg": "Hình ảnh",
    "Refresh": "Làm mới",
    "PlatformUpdateNotification": "Gợi ý cập nhật Ginee",
    "WaitAMoment": "Đợi giây lát",
    "GoAuth": "Liên kết",
    "NewVersionsInfo": "Quản lý kênh Shopee 2.0 hiện đã có sẵn! Hãy tích hợp tài khoản của bạn! Hãy thử nghiệm ngay tính năng Đồng hộ dữ liệu và xử lý đơn hàng!",
    "NoDataInfo": "Không có dữ liệu xử lý",
    "ActionsErrorInfo": "Thao tác thất bại, vui lòng thử lại sau",
    "and": "và",
    "PrivacyPolicy": "Chính sách bảo mật",
    "TermsConditions": "Điều khoản người dùng",
    "agree": "Tôi đồng ý với",
    "BLDescription": "Mô tả chi tiết phải từ 30-3000 kí tự",
    "markDownImageSize": "Kích cỡ ít nhất phải 300 x 300 pixels, nhỏ hơn 5M, định dạng JPG, PNG",
    "markDownAddImage": "Thêm hình ảnh",
    "noProductStore": "Chưa có liên kết cửa hàng nào, vui lòng liên kết cửa hàng của bạn và thêm sản phẩm",
    "noStoreInfo": "Bạn có thể liên kết cửa hàng online của bạn và bắt đầu đồng bộ sản phẩm, kho hàng và đơn hàng ngay lập tức.",
    "noMemberInfo": "Sau khi khách hàng đăng ký thành viên, bạn có thể quản lý thông tin và điểm tích lũy của họ tại đây, đồng thời cũng có thể thêm thành viên thủ công.",
    "noProductInfo": "Bạn chưa tạo bất kỳ sản phẩm nào. Vui lòng tạo sản phẩm mới.",
    "TextDescription": "Mô tả sản phẩm",
    "memberInfo": "Trang thông tin thành viên",
    "jdDisableTips": "Tạm thời không thể liên kết",
    "Coming": "Sắp ra mắt",
    "editPointRuleInfo": "Bạn có chắc muốn chỉnh sửa quy tắc?",
    "previewImg": "Xem trước",
    "addRule": "Thêm quy tắc",
    "methodTypeAll": "Tất cả",
    "sendSuccess": "Gửi thành công",
    "editPointsRule": "Chỉnh sửa quy tắc điểm",
    "pointeReduceErrorInfo": "Không phải tất cả người dùng đều có đủ điểm để trừ",
    "ruleFormatErrorInfo": "Chỉ được sử dụng chữ viết thường và số",
    "rewardDeductPoints": "Thưởng điểm/ Trừ điểm",
    "alreadyExists": "{ name } địa chỉ đã đăng ký",
    "signNext": "Tiếp theo",
    "copySuccess": "Sao chép thành công: { copyUrl }",
    "deleted": "Đã xóa",
    "rule": "Quy tắc",
    "noName": "Không có tên",
    "copyUrl": "Sao chép",
    "linkInfo": "Link",
    "laseSevenDaysInfo": "Số lượng thành viên đã thêm trong 7 ngày gần đây",
    "editimage": "Chỉnh sửa hình ảnh thuộc tính",
    "packageContentplaceholder": "Tối thiểu 1000 kí tự",
    "packageContent": "Nội dung gói hàng",
    "VariantImage": "Hình ảnh thuộc tính",
    "brand": "Thương hiệu",
    "brandLogo": "Logo Thương hiệu",
    "brandName": "Tên thương hiệu",
    "pointsHistory": "Lịch sử điểm tích lũy",
    "editShopInfo": "Chỉnh sửa thông tin cửa hàng",
    "alreadyShopOwn": "Chúc mừng! Cửa hàng bạn đã tạo thành công!",
    "shopSetInfo": "Thông tin cửa hàng",
    "contentWrapper": "Nội dung",
    "newSections": "Phiên bản mới",
    "accumulatePoints": "Điểm tích lũy",
    "chooseCardBg": "Chủ đề",
    "shopLogo": "Logo cửa hàng",
    "shopName": "Tên cửa hàng",
    "membershipPageSettings": "Cài đặt trang thành viên",
    "uploadImageError": "Kích thước tệp hình ảnh {fileName} quá nhỏ, tối thiểu {limitMax} x {limitMin}",
    "uploadInfo": "Yêu cầu: {maxWH} * {maxWH}, nhỏ hơn {isLt} M, hỗ trợ định dạng jpg, png, jpeg",
    "enterPointMax": "Có thể nhập tối đa lên đến 999.999",
    "enterPointNum": "Nhập một số nguyên từ giữa 1-999.999",
    "updateSuccessFul": "Cập nhật thành công",
    "pointsType": "Loại điểm thưởng",
    "redeemablePoints": "Số Điểm có hiệu lực",
    "reason": "Nguyên nhân",
    "deductPoints": "Trừ điểm",
    "rewardPoints": "Điểm thưởng",
    "joinedMethod": "Phương thức thêm thành viên",
    "dateOfBirth": "Ngày sinh",
    "remarksErrorInfo": "Tối đa 100 kí tự",
    "editDetailInformation": "Chỉnh sửa thông tin chi tiết",
    "editMemberInformation": "Chỉnh sửa thông tin thành viên",
    "profileDetails": "Chi tiết hồ sơ",
    "accountInformation": "Thông tin tài khoản",
    "rewardCondition": "Điều kiện nhận thưởng",
    "customizeAlertInfo": "Quy tắc dưới đây sẽ sớm hiển thị trên trang thành viên; tuy nhiên, điểm sẽ cần được đổi thủ công",
    "customizePointRules": "Thiết lập quy tắc tích lũy",
    "defaultAlertInfo": "Sau khi quy tắc tích lũy mặc định được bật, Khách hàng sẽ tự động nhận được điểm thưởng khi thực hiện các nhiệm vụ tương ứng",
    "defaultPointRules": "Quy tắc tích lũy mặc định",
    "joinMemberTime": "Thời gian tham gia",
    "inputNameOrMobile": "Nhập tên/số điện thoại",
    "editPoints": "Chỉnh sửa điểm",
    "addNewMember": "Thêm thành viên mới",
    "totalPage": "Tổng { total } dữ liệu",
    "description": "Mô tả",
    "points": "Điểm thành viên",
    "memberNo": "Mã thành viên",
    "mobile": "Số điện thoại",
    "shopname": "Tên cửa hàng",
    "name": "Họ tên",
    "genderFemale": "Nữ",
    "genderMale": "Nam",
    "gender": "Giới tính",
    "deleteRuleInfo": "Bạn có chắc chắn muốn xóa quy tắc này？",
    "status": "Trạng thái",
    "checkBoxTableSelected": "Đã chọn { selected }",
    "create": "Tạo",
    "dashboardPointCumulativeRedeem": "Điểm tích lũy đã đổi thưởng",
    "dashboardPointCumulativeGiven": "Điểm tích lũy",
    "dashboardPointToRedeem": "Điểm đổi thưởng",
    "dashboardNewMember": "Thành viên mới",
    "dashboardAllMember": "Tổng số thành viên",
    "hello": "Chào bạn!",
    "mobileErrorInfoLength": "Số điện thoại phải gồm 9-12 kí tự",
    "fullnameerror": "Tên cửa hàng phải từ 1～30 kí tự",
    "fullname": "Tên cửa hàng",
    "mobileplaceholder": "Số điện thoại bắt buộc gồm 11 số",
    "mobileErrorInfochina": "Số điện thoại không đúng",
    "mobileErrorInfo": "Phải bắt đầu với số 8",
    "nameErrorInfo": "Tên giới hạn từ 2-30 kí tự",
    "emailErrorInfo": "Địa chỉ email không đúng",
    "resetPwdSuccess": "Đặt lại Mật khẩu thành công",
    "resetPwd": "Đặt lại Mật khẩu",
    "alreadyInfo": "Đã có một tài khoản Ginee?",
    "applyConfirm": "Xác nhận",
    "forgotPwd": "Quên mật khẩu?",
    "codeSendSuccess": "Mã OTP đã gửi đến email này { email }",
    "noReceiveCountDown": "Không nhận được mã OTP?",
    "waitCountDown": "Vui lòng đợi { someone }s gửi lại",
    "resendCode": "Gửi lại",
    "sendCode": "Gửi",
    "signBy": "Đồng ý với Điều khoản và Chính sách Bảo mật",
    "verificationCode": "Nhập mã OTP",
    "verification": "Mã OTP",
    "userName": "Họ tên",
    "password": "Mật khẩu",
    "eMail": "E-mail",
    "signUp": "Đăng ký",
    "login": "Đăng nhập",
    "welcomeRegister": "Chào mừng đến với Ginee",
    "welcomeLogin": "Chào mừng đến với Ginee",
    "welcome": "Chào mừng đến với Ginee",
    "copyProduct": "Sao chép sản phẩm",
    "orderDetail": "Chi tiết đơn hàng",
    "productDetail": "Thông tin sản phẩm",
    "status_unpublished": "Chưa đăng",
    "status_update_failed": "Đăng thất bại",
    "status_delete": "Đã xóa",
    "status_draft": "Bản nháp",
    "status_disabled": "Ngừng bán",
    "status_banned": "Cấm bán",
    "status_sold_out": "Đã bán hết",
    "status_live": "Đang bán",
    "status_process": "Đang xử lý",
    "status_all": "Tất cả",
    "staffDnotExists": "Tài khoản nhân viên không có quyền, vui lòng liên hệ với người bán để thêm quyền (vào phần quản lý nhân viên-thêm nhân viên-thêm tài khoản này)",
    "fastPrinting": "In tốc độ cao",
    "printPluginHasBeenUpdatedPlease": "Bổ sung in đã được cập nhật, xin vui lòng tải về một lần nữa",
    "afterUnbindTheStockWillNot": "Sau khi hủy liên kếy, thao tác đẩy tồn kho sẽ không được đẩy sang các sản phẩm trên sàn.",
    "areYouSureYouWantTo": "Xác nhận hủy liên kết?",
    "MSKUAutoBindSettingTip": "Nhấn vào đây để dẫn đến trang thiết lập tự động liên kết Sản phẩm Chính",
    "masterSkuInventoryInventorySynchronizationOf": "Tồn kho Master SKU: Không hỗ trợ đồng bộ tồn kho cho sản phẩm đa kho Lazada, nếu cần, vui lòng đi đến trung tâm người bán để chỉnh sửa.",
    "selectGenieCategory": "Chọn Danh mục chính",
    "dayDuration": "Thời hạn (ngày)",
    "unbindedChannelVariant": "Chưa Liên kết Thuộc tính của Kênh",
    "deleteSkuConfirm": "Bạn có chắc chắn muốn xóa sản phẩm không?",
    "thirdpartyWarehouseProductDetails": "Chi tiết Sản phẩm của kho bên thứ 3:",
    "thereAreThirdpartyWarehouseProductAmong": "Trong các sản phẩm đã chọn, có sản phẩm của kho hàng bên thứ 3, không thể xóa.",
    "canNotDeleteThirdStock": "Kho sản phẩm trong kho hàng của bên thứ 3 đang tồn tại, không được phép xóa",
    "skuAffectedOutbound": "Có tổng {val} Phiếu xuất kho, Phiếu nhập kho sẽ tiến hành thao tác Hủy bỏ sản phẩm, bạn có muốn Hủy bỏ Phiếu xuất kho, Phiếu nhập kho để Xóa dữ liệu Master SKU và Tồn kho không?",
    "deleteSkuAlert": "Nếu Master SKU có một Phiếu xuất kho, Phiếu nhập kho chưa hoàn chỉnh, hệ thống sẽ tiến hành Hủy bỏ sản phẩm trong Phiếu xuất kho, đồng thời Xóa dữ liệu của Sản phẩm chính, Tồn kho.",
    "proceedToDelete": "Hủy bỏ và Xóa",
    "productAffectedOutbound": "Có tổng {val} đơn hàng đi sẽ bị Hủy. Bạn có muốn Hủy bỏ Phiếu xuất kho, Phiếu nhập kho cùng dữ liệu Sản phẩm chính và Tồn kho không?",
    "nextStep1": "Tiếp",
    "deleteProductAlert": "Nếu sản phẩm có Phiếu xuất kho, Phiếu nhập kho chưa hoàn chỉnh, hệ thống sẽ Hủy bỏ hàng hóa trong Phiếu xuất kho, đồng thời xóa Sản phẩm chính và dữ liệu Tồn kho.",
    "iNeed": "Tôi cần",
    "youCanImportDataFormat": "Có thể chỉnh sửa hàng loạt Tên, Mô tả sản phẩm, Danh mục chính của sản phẩm chính (Tối đa: 10.000 dữ liệu/ lần. Định dạng: xlsx,xls).",
    "WorkingDaysBetween": "Trong vòng 7-90 ngày làm việc",
    "workingDays": "Ngày làm việc",
    "withoutMasterSku": "Chỉ Master SKU chưa liên kết",
    "processTime": "Thời gian xử lý",
    "width": "Chiều rộng",
    "whenCreatingInTheProVersion": "Phiên bản Cao cấp, khi tạo cần tải lên các đoạn bắt buộc, có thể dùng để sau khi tạo sản phẩm chính sẽ nhân bản đến kênh bán hàng",
    "whenAvailableStockInfo": "Tồn kho có sẵn <= X, gửi Email nhắc nhở",
    "weightLimit": "Phải là 1-5.000.000",
    "weight": "Trọng lượng",
    "inboundLimitTips": "Khi sử dụng JD Fulfillment, hãy nhớ điền Hạn sử dụng, Ví dụ: nếu hạn sử dụng của sản phẩm là 100 ngày và giới hạn gửi đi là 0,5, thì khi hạn sử dụng dưới 50 ngày sẽ không thể cho nhập kho.",
    "outboundLimitTips": "Khi sử dụng Fulfillment by JD, hãy nhớ điền Hạn sử dụng, Ví dụ: nếu hạn sử dụng của sản phẩm là 100 ngày và giới hạn gửi đi là 0,5, thì khi hạn sử dụng dưới 50 ngày sẽ không thể cho xuất kho.",
    "inboundLimit": "Giới hạn Nhập kho",
    "outboundLimit": "Giới hạn Xuất kho",
    "shelfLife": "Hạn sử dụng",
    "custom": "Tùy chọn",
    "warehouseStockTip": "Số lượng tồn kho thực tế của kho hàng, Tổng tồn kho = Tồn kho dự phòng + Tồn kho Khóa + Tồn kho có sẵn + Tồn kho khuyến mãi",
    "warehouseStockLimit": "Tổng tồn kho bắt buộc từ 0-999.999",
    "yearly": "1 năm gần nhất",
    "monthly": "1 tháng gần nhất",
    "weekly": "1 tuần qua",
    "today": "Hôm nay",
    "warehouseStock": "Tổng tồn kho",
    "warehouseName": "Tên kho hàng",
    "viewNow": "Xem ngay",
    "viewMore": "Xem thêm",
    "viewLess": "Thu gọn",
    "view": "Xem",
    "videoName2": "Làm thế nào để quản lý chi phí mua hàng?",
    "variationTypeInfo": "Loại thuộc tính",
    "variationStatus": "Trạng thái thuộc tính",
    "variationImageSizeLimit": "Có thể cùng lúc tải lên nhiều hình ảnh. Hình ảnh nhỏ nhất 300 x 300 pixel, tối đa 5MB (Tối đa 8 ảnh)",
    "variationImageInfo": "Chỉnh sửa hình ảnh thuộc tính",
    "variationCountDesc": "Số lượng liên kết thuộc tính từ cao đến thấp",
    "variationCountAsc": "Số lượng liên kết thuộc tính từ thấp đến cao",
    "variationAlreadyBound": "Thuộc tính này đã liên kết với Master SKU, vui lòng hủy liên kết trước!",
    "exportLimit2": "Chỉ có thể xuất 50000 sản phẩm chính cùng lúc, vui lòng chọn lại",
    "exportLimit": "Vui lòng điền số trang để tải xuống, và mỗi lần có thể xuất tối đa 50000 sản phẩm chính",
    "selectChannel": "Chọn Kênh bán",
    "variation1": "Thuộc tính",
    "variantTypeRangeIs": "Loại thuộc tính 1 chỉ được gồm 1-14 ký tự",
    "variantTypeIsMissingPlease14": "Thiếu loại thuộc tính 2, vui lòng kiểm tra!",
    "variantTypeIsMissingPlease": "Thiếu Loại thuộc tính 1, vui lòng kiểm tra!",
    "variantsInfoB": "10 sản phẩm (100 thuộc tính, trong đó 50 thuộc tính có thể tạo Master SKU)",
    "variantsInfoA": "Có {productSum} sản phẩm, ({availableNum} thuộc tính, trong đó {variationSum} thuộc tính có thể tạo Master SKU. Thuộc tính đã được liên kết với Master SKU không thể tạo Master SKU.",
    "numberOfProducts": "{productSum} Sản phẩm ({variationSum} thuộc tính)",
    "variantPictureLinkCannotBe": "Link hình ảnh thuộc tính 1 không thể để trống!",
    "uploadFile": "Tải lên Tệp tin",
    "downloadTemplate": "Tải xuống tệp tin mẫu",
    "step2Limit": "Vui lòng tải xuống tệp tin mẫu thuộc tính, bạn có thể tải lên tối đa 10.000 thuộc tính cùng lúc (định đạng xlsx, xls)",
    "step2Dec": "Bước 3: Thêm hàng loạt các thuộc tính mới đã liên kết tự động",
    "step1Dec": "Bước 2: Xuất toàn bộ thuộc tính tự động liên kết.",
    "variantOptionsLimit": "Tối đa 20 lựa chọn thuộc tính",
    "variantOptionShouldBe": "Không được vượt quá 20 ký tự",
    "import10000Tip": "Mỗi lần thêm tối đa 10000 dữ liệu.",
    "variantNameChannel": "Tên thuộc tính trên kênh",
    "variantName": "Tên thuộc tính",
    "variantImage": "Hình ảnh thuộc tính",
    "username": "Tên người dùng",
    "useImageFor": "Sử dụng hình ảnh cho",
    "used": "Đã sử dụng",
    "operationReason": "Nguyên nhân thao tác",
    "goToSettings": "Đi đến cài đặt",
    "stores": "Vui lòng đi đến [Cài đặt Sản phẩm] và bật cài đặt \"Tự động liên kết Master SKU\" trước khi sử dụng chức năng này",
    "storeSKU": "Tính năng chưa mở",
    "channelVID": "Mã ID kênh bán",
    "enabledAutoBind": "Chưa kích hoạt tính năng tự động liên kết Master SKU, tạm thời không có thuộc tính!",
    "editAutoBindRule": "Quy tắc chỉnh sửa tự động liên kết",
    "updateAutoBindRules": "Quy tắc cập nhật tự động liên kết",
    "exportAllAutoBindRules": "Quy tắc tự động xuất tất cả liên kết",
    "enterStoreSKU": "Vui lòng nhập Mã sản phẩm tại sàn TMĐT",
    "bindingError": "SKU này đã liên kết đến các mã khác, vui lòng kiểm tra",
    "storeSkuLimit": "Mã sản phẩm tối đa chứa 250 ký tự",
    "bindMasterSku": "Sản phẩm chưa được liên kết sẽ tự động liên kết đến Master SKU",
    "bindedStore": "Shop liên kết",
    "bindedProduct": "Sản phẩm liên kết",
    "autoBind": "Tự động liên kết",
    "stepExportRules": "SKU cửa hàng",
    "variant": "Thuộc tính",
    "updateStock": "Cập nhật hàng tồn kho",
    "updateSafetyStock2": "Cập nhật Tồn kho an toàn",
    "updateSafetyStock": "Cập nhật hàng loạt Tồn kho an toàn",
    "updateInventoryInfo2": "Vui lòng cập nhật hàng tồn kho tại \"Quản lý tồn kho\"",
    "updateInventoryInfo": "Vui lòng cập nhật tồn kho Master SKU đã liên kết trước khi chỉnh sửa tồn kho của combo sản phẩm.",
    "updatedSuccessfully": "Cập nhật thành công",
    "updateAvailableStock": "Cập nhật Tồn kho có sẵn của cửa hàng",
    "unitPurchasPrice": "Giá mua hàng",
    "unitPurchasePrice": "Đơn giá mua hàng của sản phẩm",
    "unbindInfo": "Sản phẩm đã hủy liên kết với Master SKU và Tồn kho Khóa sẽ được mở",
    "unbind": "Hủy liên kết",
    "turnOnStock": "Bật Giám sát và Đồng bộ tồn kho, kích hoạt Tồn kho ở kênh sản phẩm",
    "totalNum": "Tổng {val}",
    "toShip": "Gửi hàng",
    "timeInfoWorkingDay": "Ngày làm việc",
    "timeInfoMonth": "Tháng",
    "thisVariationOnly": "Chỉ thuộc tính này",
    "theSameNameIsRegardedAs": "(Tên giống nhau sẽ được gộp thành một sản phẩm. Độ dài tối đa tên sản phẩm là 300 ký tự)",
    "theNameAlreadyExistsInTheStore": "Tên sản phẩm đã tồn tại",
    "theMasterSKUDoesNotExist": "Master SKU không tồn tại, vui lòng kiểm tra!",
    "theLinkOfVariantPicture": "Link hình ảnh thuộc tính 1 không hợp lệ, vui lòng nhập lại!",
    "theInventorySkuAlreadyExists": "Master SKU này đã tồn tại",
    "theCurrencyUnitOfTheDefault": "(Tiền tệ của Giá bán mặc định và Đơn giá mua hàng là USD/IDR/PHP/THB/VND/MYR/SGD/CNY)",
    "theCategoryIDDoesNotExist": "Dang mục ID không tồn tại, vui lòng tải xuống và kiểm tra Bảng danh mục",
    "theBasicVersionCanImportA": "Phiên bản Cơ bản chỉ yêu cầu nhập một vài thông tin cần thiết và hỗ trợ tạo Master SKU nhanh chóng, giúp kích hoạt tính năng Quản lý Tồn kho;",
    "syncInfoB": "{6787686756 Hủy đồng bộ hàng tồn kho",
    "syncInfoA": "{6787686756}Bật đồng bộ hàng tồn kho",
    "successNumber": "Số lượng thành công",
    "successfullyUnbindInfo": "Hủy liên kết thành công {val} thuộc tính",
    "successfullyBindInfo": "Liên kết thành công {val} thuộc tính",
    "storeWithoutSales": "Cửa hàng chưa bán",
    "storeUpdateResult": "Kết quả cập nhật cửa hàng",
    "storeSku": "SKU cửa hàng",
    "storeNameB": "Tên cửa hàng",
    "storeNameA": "Tên cửa hàng",
    "storeCountDesc": "Số cửa hàng đang bán từ cao đến thấp",
    "storeCountAsc": "Số cửa hàng đang bán từ thấp đến cao",
    "stockSettingsStockMonitorAndSyncOn": "Cài đặt Tồn kho - Giám sát và đồng bộ tồn kho, bắt đầu: 26-10-2020 17:17",
    "stockSettingsStockMonitorAndSyncOff": "Cài đặt Tồn kho - Giám sát và Đồng bộ tồn kho, kết thúc: 26-10-2020 17:17",
    "stockChannelInfo": "Tổng tồn kho có thể bán tại kênh = Tổng tồn kho - Tồn kho dự phòng - Tồn kho Khóa - Tồn kho khuyến mãi",
    "stockChanges": "Có sự thay đổi tồn kho",
    "stepImportAndCreateThe": "Bước 2: Nhập tạo Sản phẩm chính",
    "stepDownloadTheMasterCategory": "Bước 1: Tải xuống Id Danh mục chính",
    "step3Text": "Bước 3: Nhập để liên kết sản phẩm",
    "step2Text": "Bước 2: Xuất Master SKU",
    "step1Text": "Bước 1: Chọn và xuất kênh sản phẩm mà bạn muốn liên kết Master SKU",
    "spareStockTip": "Tổng lượng hàng tồn kho trong kho hàng thực tế sẽ không bán ra trên kênh",
    "spareStock": "Tồn kho dự phòng",
    "sourceUrl": "Nguồn URL",
    "sortMasterProduct": "Lọc sản phẩm chính",
    "someWarehouseTheWarehouseStockOf": "Trong một số kho hàng, Tổng tồn kho của Master SKU không phải là 0, vui lòng cập nhật thành 0 rồi xóa.",
    "somePartUpdateFailed": "Cập nhật thành công một phần",
    "somePartReviewFailed": "Một số đánh giá thất bại",
    "someDatasImportFailed": "Một phần dữ liệu nhập thất bại",
    "someCreateFailed": "Một số tạo thất bại",
    "skuStoreSku": "SKU (SKU cửa hàng)",
    "skuAlreadyExistsPleaseDeleteThe": "SKU đã tồn tại, vui lòng xóa sản phẩm hiện có hoặc đổi tên!",
    "singlePurchasePriceOfTheProduct": "Đơn giá mua hàng",
    "singlePurchasePrice": "Đơn giá mua hàng",
    "shouldBeBetweenLimitB": "Dao động từ 0 - 1.000.000.000",
    "shouldBeBetweenLimitA": "Dao động từ 0 - 999.999",
    "shouldBeBetweenB": "Phải từ 1-999.999!",
    "shouldBeBetweenA": "Phạm vi nhập là 1-999.999",
    "shippingFeeInformation": "Phí vận chuyển",
    "setPageTip2": "Vui lòng nhập số trang hợp lệ",
    "setPageTip": "Chọn trang để xuất",
    "selling": "Đang bán",
    "selectProductBundle": "Chọn combo sản phẩm",
    "selectGineeCategory": "Chọn danh mục chính",
    "selectFile": "Chọn tệp",
    "selectedMax20": "Đã chọn (Tối đa 20)",
    "selected": "Đã chọn",
    "selectCategories": "Vui lòng chọn danh mục",
    "selectAtLeastOneInventorySku": "Chọn tối thiểu 1 Master SKU",
    "editRule": "Chỉnh sửa quy tắc",
    "selectAtLeastOneImage": "Chọn tối thiểu 1 hình ảnh",
    "createDates": "Giờ tạo",
    "seeXProducts": "Xem X sản phẩm",
    "seeMoreTips": "Xem {val} sản phẩm",
    "saveUpdate": "Lưu cập nhật",
    "saveForReview": "Lưu thành Chờ đánh giá",
    "savedSuccessfully": "Lưu thành công",
    "saveAsReviewed": "Lưu thành Đã duyệt",
    "salesTaxAmount": "Số tiền thuế bán hàng",
    "safetyStockStatus": "Trạng thái Tồn kho an toàn",
    "safetyStock": "Tồn kho an toàn",
    "rupiah": "Rp",
    "reviewPassed": "Kiểm duyệt thông qua",
    "reviewed": "Đã duyệt",
    "reReview": "Kiểm duyệt lại",
    "reasonForFailure": "Nguyên nhân thất bại: điền thiếu thông tin, vui lòng bổ sung",
    "rangeLimitA": "Phạm vi phải từ 0 - 1.000.000.000",
    "rangeIsLimit": "Phạm vi là 5.000.000",
    "quantityLimit": "Số lượng tương ứng phải là 1-999.999",
    "qty": "Số lượng tương ứng",
    "purchaseTime": "Thời gian mua hàng",
    "purchasePriceShouldLimit": "Đơn giá mua hàng bắt buộc từ 1.000 - 1.000.000.000",
    "purchasePriceLimit": "Giá bắt buộc từ 0 - 1.000.000.000!",
    "purchasePriceInfo": "(Không cần nhập vào, giá mua hàng sẽ tính toán dựa theo chi phí bình quân)",
    "proVersionImportToCreate": "Phiên bản cao cấp nhập để tạo",
    "promotionStockTip": "Tồn kho khuyến mãi là tổng hàng tồn kho dự kiến của hoạt động khuyến mãi cho Campaign hoặc Flash Sale",
    "promotionStock": "Tồn kho khuyến mãi",
    "productType": "Loại sản phẩm",
    "productsWithoutVariantsCanBeEmpty": "(Sản phẩm không có thuộc tính có thể để trống)",
    "productSources": "Nguồn sản phẩm",
    "productNameLimit": "Tên sản phẩm bắt buộc gồm 5 đến 300 ký tự",
    "productNameInventorySku": "Tên sản phẩm & Master SKU",
    "productInformation": "Thông tin mục",
    "productImageLinkIsInvalid": "Link hình ảnh sản phẩm 1 không hợp lệ, vui lòng kiểm tra!",
    "productImageLinkCannotBe": "Link hình ảnh sản phẩm 1 không thể để trống!",
    "productImage": "Hình ảnh sản phẩm",
    "productFailedToCreatePleaseDownload": "Tạo Sản phẩm chính thất bại, vui lòng tải xuống dữ liệu thất bại và xem lý do thất bại",
    "productEditSuccess": "Chỉnh sửa sản phẩm thành công",
    "productEditFailed": "Chỉnh sửa sản phẩm thất bại",
    "productDescriptionShouldBeCharacters": "Mô tả sản phẩm nên gồm 1-7000 ký tự",
    "productDescription": "Mô tả sản phẩm",
    "productCreatedSuccessfully": "Tạo sản phẩm thành công",
    "product": "Sản phẩm",
    "preorder11": "Pre-order",
    "popupResultButton": "Xem dữ liệu thất bại",
    "pleaseEnterTheAmount": "Vui lòng nhập số tiền",
    "pleaseEnterKeywordsToSearchChannelProducts": "Vui lòng nhập từ khóa để tìm kiếm kênh sản phẩm",
    "pleaseEnter": "Vui lòng nhập",
    "pleaseDownloadTheTemplateAndComplete": "Vui lòng tải xuống và nhập đầy đủ thông tin vào tệp Mẫu, sau đó tải lên để tạo sản phẩm (mỗi lần có thể nhập 10.000 dữ liệu).",
    "pleaseChoose": "Vui lòng chọn",
    "partiallyFailedInfo": "Thất bại một phần, trạng thái: Đã duyệt",
    "packageSize": "Kích thước bưu phẩm",
    "orderToEnsureTheIntegrity": "Để đảm bảo tính toàn vẹn của quy trình và dữ liệu báo cáo thống kê, không thể xóa một số Master SKU, do đó không thể chỉnh sửa loại thuộc tính của sản phẩm này. Bạn nên \"Trích dẫn\" sản phẩm này để tạo ra nhiều thuộc tính của sản phẩm chính, và sau đó đặt sản phẩm này là \"Tạm dừng theo dõi\".",
    "orderToEnsureTheIntegrityOf": "Để đảm bảo tính hoàn thiện của số liệu, đề nghị sử dụng \"Tạm dừng theo dõi\"",
    "optionLimit": "Lựa chọn phải từ 1-30 ký tự",
    "option": "Lựa chọn",
    "operationTime": "Thời gian thao tác",
    "operationGuide": "Hướng dẫn thao tác",
    "noShelfLife": "Hạn sử dụng",
    "onSaleShop": "Cửa hàng đang bán",
    "onReview": "Đang đánh giá",
    "onlyShowBindedVariations": "Chỉ hiển thị các thuộc tính không có MSKU",
    "numVariationsSelected": "Đã chon 0 thuộc tính",
    "numVariations": "{val} Thuộc tính",
    "numSelected": "Đã chọn {val}",
    "numEditBindInformation": "{val}-Chỉnh sửa thông tin liên kết",
    "nowInbound": "Nhập kho ngay",
    "normalProducts": "Sản phẩm phổ thông",
    "noData": "Không có dữ liệu",
    "noChannelVariationId": "ID thuộc tính kênh không tồn tại",
    "newProduct": "Hàng mới",
    "newAveragePurchasePrice": "Giá mua hàng bình quân mới",
    "newAverageCostPrice": "Chi phí bình quân mới",
    "new": "Mới",
    "mutiVariations": "Sản phẩm có nhiều loại thuộc tính, nếu sản phẩm của bạn khác nhau về màu sắc, kích thước, vui lòng chọn mục này",
    "mustBeBetween": "Bắt buộc từ 1-8",
    "mskuAndstockVideo": "Sản phẩm chính và Quản lý hàng tồn kho có mối quan hệ gì?",
    "mSku": "MSKU",
    "more": "Thêm",
    "minPurchaseQuantityShouldBe": "Số lượng mua tối thiểu là 1",
    "minimumPurchaseQuantity": "Số lượng mua tối thiểu",
    "mediaSettings": "Quản lý truyền thông",
    "maxNumVal": "Tối đa {val} ảnh",
    "maxNum": "Tối đa {val}",
    "maxLengthNum": "Tối đa {val} ký tự",
    "maxLengthCharacters": "Tối đa {max} ký tự",
    "maxLength14": "Tối đa 14 ký tự",
    "maximumIs": "(Tối đa là 1.000.000.000)",
    "maximumCharacters": "(Độ dài tối đa là 7000 ký tự)",
    "max9": "Tối đa 9 ảnh",
    "masterSkuHasBeenMatchedWith": "Master SKU đã được khớp với các sản phẩm của kho của bên thứ ba.",
    "masterProductsImage": "Sản phẩm & hình ảnh chính",
    "masterProductName": "Tên sản phẩm chính",
    "masterProduct": "Sản phẩm chính",
    "master": "Sản phẩm chính",
    "massSettings": "Cài đặt hàng loạt",
    "mainPhotos": "Ảnh đại diện",
    "lockedStockTip": "Kho hàng đang chờ fulfilled, nhưng chưa gửi hàng. Đồng bộ đến đơn hàng \"Thanh toán mới\" hoặc \"Chờ thanh toán\" của Ginee, sẽ được thêm vào Tồn kho Khóa",
    "lockedStock": "Tồn kho Khóa",
    "liveTips": "Phạm vi: bao gồm Hết hàng và Đang bật bán",
    "live": "Live",
    "length": "Chiều dài",
    "iWillShipOutWithinX": "Tôi sẽ gửi hàng trong vòng X giờ làm việc",
    "iWillShipOutWithin22": "Tôi sẽ gửi hàng trong 2 ngày làm việc. (không bao gồm ngày nghỉ lễ và ngày nghỉ của dịch vụ giao hàng)",
    "iWillShipOutWithin": "Tôi sẽ gửi hàng trong vòng 3 ngày làm việc. (không bao gồm ngày nghỉ lễ và ngày nghỉ của dịch vụ giao hàng)",
    "iskuStandsForInventorySku": "MSKU là Master SKU",
    "iskuCannotBeEmpty": "Master SKU không được để trống",
    "isAnUnprocessedOutboundInThe": "Master SKU tồn tại Phiếu xuất hàng chưa xử lý",
    "inventorySkuSpaceLimit": "Master SKU chứa khoảng cách hoặc xuống dòng, vui lòng đặt lại tên.",
    "inventorySkuLimit": "Master SKU tối đa 300 ký tự",
    "inventorySkuGenRules": "Quy tắc tạo Master SKU thuộc tính mặc định:\\n1. Master SKU sẽ chọn mặc định 50 ký tự đầu tiên của Variation\\n2. Tổng tồn kho sẽ mặc định bằng Tồn kho thuộc tính, Tồn kho an toàn để trống\\n3. Khi tạo Master SKU sẽ liên kết SKU của thuộc tính",
    "inventorySkuExistsLimit": "Master SKU đã tồn tại, vui lòng chỉnh sửa",
    "instructions": "Hướng dẫn chi tiết",
    "iNeedXWorkingDays": "Tôi cần X ngày làm việc mới có thể gửi hàng",
    "iNeedXWeeksToShip": "Tôi cần X tuần mới có thể gửi hàng",
    "iNeedXDaysToShip": "Tôi cần X ngày mới có thể gửi hàng",
    "importToEditProduct": "Chỉnh sửa sản phẩm hàng loạt",
    "importToBindIntro": "Chức năng này hỗ trợ khách hàng liên kết nhiều sản phẩm của cửa hàng với Master SKU, để tiện Quản lý tồn kho.",
    "importToBind": "Nhập liên kết sản phẩm",
    "importTip": "Mỗi lần nhập tối đa 1000 dữ liệu",
    "importMasterProducts": "Nhập sản phẩm chính",
    "importFileFormatIsIncorrectInfo2": "Mẫu của tệp nhập vào không đúng, vui lòng kiểm tra",
    "importFileFormatIsIncorrect": "Nhập sai định dạng tệp, vui lòng tải xuống Mẫu",
    "importFile": "Tải tệp lên",
    "importFailed": "Nhập thất bại",
    "importAndCreateProducts": "Tạo mới sản phẩm hàng loạt",
    "importAndAddInfo": "Có thể thêm hàng loạt Sản phẩm chính (Tối đa: 10.000 dữ liệu/ lần. Định dạng: xlsx,xls).",
    "imageSizeLimit": "Kích cỡ hình ảnh nhỏ nhất 300 x 300 pixel, tối đa 5MB",
    "ifItIsEmptyWillNot": "Thông tin để trống không thể cập nhật!",
    "hour": "Giờ",
    "hotSale": "Hot Sale",
    "helpCenterLink": "Hướng dẫn thao tác",
    "height": "Chiều cao",
    "hasBindedInfo": "Thuộc tính này đã liên kết Master SKU: xxxxxxxx. Vui lòng cập nhật hàng tồn kho trong \"Quản lý tồn kho\"",
    "generateMaster": "Tạo Master SKU giúp quản lý tồn kho của sản phẩm tại các kênh dễ dàng.",
    "filter": "Lọc",
    "fileRules": "Định dạng tệp: xlsx, xls.Tối đa: 5MB",
    "faqName2": "Làm thế nào nhập tạo Sản phẩm chính tại Phiên bản Cơ Bản?",
    "faqName1": "Làm thế nào nhập tạo Sản phẩm chính tại Phiên bản Cao Cấp?",
    "failedReason": "Nguyên nhân thất bại: Sản phẩm có thể đã xóa, vui lòng đồng bộ lại sản phẩm",
    "failedNumber": "Số lượng thất bại",
    "exportUpTo": "Mỗi lần có thể liên kết tối đa 10.000 sản phẩm (Định dạng xlsx, xls).",
    "exportTip": "Vui lòng chọn sản phẩm cần xuất",
    "exportSelected": "Xuất theo mục đã chọn",
    "exportOrImport": "Xuất/Nhập",
    "exportByPageTip": "Vui lòng nhập số trang hợp lệ",
    "exportByPage": "Xuất theo trang",
    "export": "Xuất",
    "enterTheNameOfTheVariationForExampleColorEtc": "Vui lòng nhập tên thuộc tính, ví dụ: màu sắc vv",
    "enterProcessTime": "Nhập thời gian xử lý",
    "enterPageNumber": "Vui lòng nhập số trang để tải xuống sản phẩm chính",
    "enterOption": "Vui lòng nhập lựa chọn, ví dụ: màu đỏ vv",
    "enableInventorySync": "Bật đồng bộ tồn kho",
    "editSafetyFtock": "Chỉnh sửa Tồn kho an toàn",
    "editOptionsBlue": "Chỉnh sửa lựa chọn-Blue",
    "editInventory": "Cập nhật hàng tồn kho",
    "editAvailableStock": "Chỉnh sửa Tồn kho có sẵn",
    "downloadTip": "Tải xuống",
    "downloadTheMasterCategory": "Tải xuống danh mục chính",
    "downloadTemplateInfo": "Tải xuống mẫu",
    "downloadProduct": "Tải xuống sản phẩm",
    "downloadFailedData": "Tải xuống dữ liệu thất bại",
    "discontinued": "Dừng bán",
    "disableInventorySync": "Tắt đồng bộ tồn kho",
    "descriptionRequired": "Vui lòng nhập mô tả của bạn và áp dụng cho sản phẩm của bạn",
    "descriptionLimit": "Mô tả bắt buộc từ giữa 30 đến 5000 ký tự",
    "delivery": "Chi phí vận chuyển",
    "deleteMPTips2": "Đã xóa sản phẩm chính",
    "deleteMPTips": "Xóa thất bại: {val} sản phẩm chính",
    "deleteLimit": "Nguyên nhân thất bại: Vui lòng hủy liên kết với MSKU {val}, sau đó thao tác xóa.",
    "delConfirmTitle": "Bạn chắc chắn muốn xóa sản phẩm không?",
    "delConfirmContent": "Sau khi xóa sản phẩm chính, Master SKU của Quản lý tồn kho cũng sẽ bị xóa",
    "defaultPrice": "Giá bán mặc định",
    "dataSources": "Nguồn dữ liệu",
    "datasImportSuccess": "Nhập dữ liệu thành công",
    "datasImport": "Nhập dữ liệu",
    "creatingProduct": "Đang tạo sản phẩm",
    "createProductFailed": "Tạo sản phẩm thất bại",
    "costInformation": "Thông tin giá gốc",
    "continueToBindInfo": "Bạn có thể tiếp tục liên kết kênh sản phẩm trong danh sách sản phẩm chính",
    "condition": "Trạng thái lưu trữ sản phẩm",
    "columnsCannotBeEmpty": "Lựa chọn này không được để trống",
    "collectByAdd": "Chọn và thêm",
    "cny": "CNY",
    "salesTaxAmountLimit": "Giá bắt buộc từ 0 - 1.000.000.000!",
    "clearance": "Bán hết",
    "clear": "Xóa",
    "checkNow": "Kiểm tra ngay",
    "channelVariationIdNotEmpty": "ID thuộc tính kênh không được để trống",
    "channelVariationId": "ID thuộc tính kênh",
    "channelSellingStatus": "Trạng thái bán hàng của kênh",
    "channelProductsImage": "Sản phẩm & hình ảnh các kênh",
    "channelProducts": "Kênh sản phẩm",
    "channelProductName": "Tên sản phẩm trên kênh",
    "changeTo": "Sửa thành",
    "changeReason": "Nguyên nhân thay đổi",
    "changeIsku": "Thay đổi Master SKU",
    "ceneratedFromChannelProduct": "Tạo từ kênh sản phẩm",
    "categoryID": "ID Danh mục",
    "category": "Danh mục",
    "cancelUpdateConfirm": "Bạn chắc chắn muốn bỏ qua lần cập nhật này không?",
    "bundledProduct": "Combo sản phẩm",
    "bundle": "Combo",
    "boundInfo": "Đã liên kết",
    "bindProduct": "Sản phẩm liên kết",
    "bindNow": "Liên kết ngay",
    "bindInstruction": "Sau khi kết hợp kênh sản phẩm và Master SKU, vui lòng tải xuống Mẫu và nhập dữ liệu để liên kết",
    "bindingType": "Loại liên kết",
    "bindingInProgress": "Đang liên kết hàng loạt",
    "bindingInformation": "Thông tin liên kết",
    "bindInformation": "Liên kết thông tin",
    "bindInfoB": "l,gree {id:111234} hủy liên kết với {6787686756}",
    "bindInfoA": "l,gree{id:111234} liên kết {6787686756}",
    "bindHistory": "Lịch sử liên kết",
    "bindedInventorySku": "Đã liên kết SKU",
    "binded0Variation": "Đã liên kết 0 thuộc tính",
    "weightShouldBeBetween": "Khối lượng bắt buộc từ 1-5.000.000!",
    "heightShouldBeBetween": "Chiều cao bắt buộc từ 1-999.999!",
    "widthShouldBeBetween": "Chiều rộng bắt buộc từ 1-999.999!",
    "lengthShouldBeBetween": "Chiều dài bắt buộc từ 1-999.999!",
    "betweenWeeks": "Trong 1-13 tuần",
    "betweenDays": "Trong 1-90 ngày",
    "between21": "Giữa 7-15",
    "between": "Giữa 3-30",
    "beBetweenWorkingHoursMust": "Trong vòng 1~8 tiếng làm việc",
    "basicVersionImportToCreate": "Nhập tạo sản phẩm (Cơ bản)",
    "theSameNameWillConsideredAs": "(Tên giống nhau sẽ được gộp thành một sản phẩm. Độ dài tối đa tên sản phẩm là 300 ký tự)",
    "categoryName": "Tên danh mục",
    "basicInformation": "Thông tin cơ bản",
    "variantPictureLink": "Link hình ảnh thuộc tính 1",
    "barcodeLimitC": "Mã vạch chỉ hỗ trợ chữ cái, số và-_và không nên vượt quá 100 ký tự",
    "productImageLink1": "Link hình ảnh sản phẩm 1",
    "barcodeLimitB": "Mã vạch không vượt quá 100 ký tự",
    "barcodeLimitA": "Mã vạch chỉ hỗ trợ số, chữ cái và -_",
    "temporaryNotFollowingXProducts": "Đã Tạm dừng theo dõi {X} sản phẩm",
    "thisBarcodeIsAlreadyOccupiedBy": "Mã vạch đã bị {MSKU} chiếm dụng, vui lòng nhập lại",
    "currency": "Tiền tệ",
    "barcode": "Mã vạch",
    "averagePurchasePrice": "Giá mua hàng bình quân",
    "averageCostPriceInfo": "Chi phí bình quân = Tổng đơn giá mua hàng / Tổng hàng tồn kho",
    "averageCostPrice": "Chi phí bình quân",
    "variantOption1ShouldBe": "Không được vượt quá 20 ký tự",
    "variantOption1": "Loại thuộc tính 1",
    "variantOption2": "Loại thuộc tính 3",
    "variantTypeIs2": "Loại thuộc tính 2",
    "variantTypeIs": "Loại thuộc tính 1",
    "unspecifiedProductsCanBeEmpty": "Sản phẩm không có thuộc tính có thể để trống",
    "availableStockTip": "Tổng số lượng hàng tồn kho của kênh có thể bán ra, Tồn kho có sẵn = Tổng tồn kho - Tồn kho dự phòng - Tồn kho Khóa - Tồn kho khuyến mãi",
    "availableStockLimit": "Tồn kho có sẵn không được để trống",
    "availableStockInputLimit": "Tổng tồn kho",
    "availableStockInfo": "Tồn kho có sẵn = Tổng tồn kho - Tồn kho dự phòng - Tồn kho bị Khóa -Tồn kho khuyến mãi, vui lòng cập nhật Tổng tồn kho trước khi điều chỉnh Tồn kho có sẵn",
    "availableStock": "Tồn kho có sẵn",
    "availableForDownloadCategory": "(Tải xuống Danh mục có sẵn)",
    "autoMatchVariationInfo": "Sau khi tạo Master SKU，Master SKU sẽ tự động liên kết những thuộc tính phù hợp của SKU của cửa hàng với Master SKU",
    "articleName2": "Làm thế nào để liên kết Kênh sản phẩm với Master SKU?",
    "applyToAllVariation": "Áp dụng cho tất cả thuộc tính",
    "aOfb": "1-50 trang, tổng 219",
    "allVariation": "Tất cả thuộc tính",
    "missingVariantPleaseCheck": "Thiếu lựa chọn thuộc tính 1, vui lòng kiểm tra!",
    "allUpdateFailed": "Cập nhật thất bại toàn bộ",
    "max1000": "Số lượng mua tối đa là 1000",
    "missingMasterProductVariantRed": "Sản phẩm chính thiếu thuộc tính：red/s、red/L\ncần bổ sung để tạo sản phẩm.",
    "massOperationHistory": "Lịch sử thao tác hàng loạt",
    "publishToStore": "Đăng lên cửa hàng",
    "chooseDestinationStore": "Chọn cửa hàng thao tác",
    "selectStore": "Chọn cửa hàng",
    "nextStep": "Tiếp theo",
    "orderProcessingTimeFollowsTheShops": "Thời gian xử lý đơn hàng tuân theo cài đặt của cửa hàng",
    "allUpdatedSuccessfully": "Cập nhật thành công toàn bộ",
    "allSuccessfullyInfo": "Toàn bộ chuyển thành Đang chờ duyệt",
    "allSuccessfullyCreated": "Tất cả đã tạo thành công",
    "allReviewFailed": "Tất cả đánh giá thất bại",
    "shipTime": "Thời gian xử lý",
    "allReviewed": "Tất cả đánh giá thông qua",
    "allRecordsOfChannelProductsInfo": "Tất cả lịch sử ở các kênh cửa hàng (kênh sản phẩm đã liên kết với Master SKU) sẽ được hệ thống Ginee cập nhật (30 ngày gần nhất)",
    "INeed": "Tôi cần",
    "allFailedInfo": "Tất cả thất bại, trạng thái: Đã duyệt",
    "allCreateFailed": "Tất cả tạo thất bại",
    "hoursWorking": "Giờ làm việc",
    "instant": "Ngay lập tức",
    "orderProcessingDeadline": "Hạn chót xử lý đơn hàng",
    "all": "Tất cả",
    "toViewTheMassOperationRecords": "Hỗ trợ xem chi tiết Lịch sử thao tác trong vòng 7 ngày. Xem ngay!",
    "toPublishToTheStoreHow": "Làm thế nào \"Đăng tải sản phẩm lên cửa hàng\"?",
    "toBatchEditingOfMainProducts": "Làm thế nào \"Chỉnh sửa Sản phẩm chính?",
    "thePriceOfTheProductIn": "Đồng bộ giá của sản phẩm trong kênh đã liên kết với Ginee",
    "productSyncronized": "Đồng bộ sản phẩm",
    "isRecommendedToSynchronizeThePromotion": "Nên đồng bộ các hoạt động của cửa hàng trong kênh trước, để đảm bảo rằng giá khuyến mãi của các sản phẩm phù hợp với trung tâm người bán",
    "promotionSync": "Đồng bộ khuyến mãi",
    "variationIdHasBeenBoundChannel": "Đã liên kết ID thuộc tính của kênh",
    "productInformationMaster": "Thông tin Sản phẩm chính",
    "theInformationOfAnExistingMaster": "Sử dụng thông tin của sản phẩm chính hiện có",
    "productReferenceMaster": "Sử dụng sản phẩm chính",
    "copy": "Sao chép",
    "skuAutobindSettingEnabledMaster": "Tự động liên kết cài đặt Master SKU (Đã bật)",
    "skuAutobindSettingNotEnableMaster": "Tự động liên kết cài đặt Master SKU (Chưa bật)",
    "MSKUAutoBindSettings": "Cài đặt tự động liên kết Master SKU",
    "matchingExact": "Kết hợp chính xác",
    "matchingFuzzy": "Kết hợp mơ hồ",
    "Choose": "Chọn",
    "stockWarehouse": "Tổng tồn kho",
    "afterTheSafetyInfo": "Sau khi kích hoạt Tồn kho an toàn, nếu Tồn kho có sẵn của Master SKU nhỏ hơn Tồn kho an toàn, sẽ gửi email nhắc nhở.",
    "nowIntegrate": "Liên kết ngay",
    "productHasBeenBoundChannel": "Đã liên kết Sản phẩm của Kênh",
    "stockSynchronizationSettingsEnable": "Bật Cài đặt Đồng bộ Tồn kho",
    "inboundToWarehouseAlready": "Đã đặt vào Kho",
    "partFailedDelete": "Xóa thất bại Một phần",
    "allFailedDelete": "Xóa thất bại Tất cả",
    "allSuccessfulDelete": "Xóa thành công Tất cả",
    "close1": "Đóng",
    "productHasAOutboundListTo": "Sản phẩm đã có Phiếu nhập kho, để đảm bảo tính hoàn thiện của dữ liệu, khuyến cáo sử dụng \"Tạm dừng theo dõi\" sản phẩm này",
    "warehouseStockOfThisProductIs": "Một phần Tổng tồn kho của sản phẩm này không phải là 0, vui lòng làm trống và xóa",
    "productHasBeenBoundToA": "Sản phẩm này đã liên kết với sản phẩm trong kho của bên thứ ba. Để đảm bảo tính toàn vẹn của dữ liệu, bạn nên sử dụng \"Tạm dừng theo dõi\" sản phẩm này",
    "afterTheChannelInfo": "Sau khi kênh sản phẩm liên kết Master SKU, Tồn kho cửa hàng sẽ được kích hoạt",
    "failedDelete": "Xóa thất bại",
    "productBindingStatusChannel": "Trạng thái liên kết Sản phẩm của Kênh",
    "boundChannelProductAll": "Tất cả đã liên kết Sản phẩm của Kênh",
    "boundChannelProductPartially": "Một phần đã liên kết Sản phẩm của Kênh",
    "allChannelProductUnbind": "Tất cả chưa liên kết Sản phẩm của Kênh",
    "operationSuccessfulMass": "Thao tác hàng loạt đã thành công!",
    "toTheMasterProductAnd": "Khôi phục theo dõi Sản phẩm chính này, không đánh dấu thành \"Tạm dừng theo dõi\" nữa",
    "temporaryUntrackedDisplay": "Hiển thị Tạm dừng theo dõi",
    "unbindMasterProductAndChannelProduct": "Hủy liên kết giữa Sản phẩm chính và Sản phẩm của kênh hàng loạt",
    "recoverMass": "Khôi phục Hàng loạt",
    "temporaryUntrackedMass": "Tạm dừng theo dõi Hàng loạt",
    "unbindMass": "Hủy liên kết Hàng loạt",
    "deleteMass": "Xóa hàng loạt",
    "group": "Nhóm",
    "reference": "Hướng dẫn",
    "untrackedTemporary": "Tạm dừng theo dõi",
    "combination": "Kết hợp",
    "variantMultiple": "Nhiều Thuộc tính",
    "addWarehouseStockLimit": "Tổng tồn kho bắt buộc từ 1-999.999",
    "addVariation": "Thêm thuộc tính",
    "addSpareStockLimit": "Phạm vi hàng tồn kho từ 0-999.999",
    "supportSelectingMultipleStoresInThe": "Chỉ hỗ trợ chọn nhiều cửa hàng trong cùng một quốc gia",
    "variantNo": "Không có thuộc tính",
    "theProductThatFailedToBe": "Đặt các sản phẩm xóa thất bại là Tạm dừng theo dõi, do đó, các sản phẩm chính sẽ không còn được hiển thị trong \"Tất cả\"",
    "masterSkuHasBeenBoundBy": "Master SKU đã liên kết bởi sản phẩm kết hợp, vui lòng hủy liên kết trước khi xóa.",
    "masterProductsThatAreSetAs": "Tất cả các sản phẩm chính được đặt là \"Tạm dừng theo dõi\" (bao gồm các loại sản phẩm kết hợp hoặc phổ biến)",
    "typeOfMasterProductCombined": "Sản phẩm chính loại kết hợp",
    "productSingle": "Sản phẩm đơn",
    "theGeneralOfMasterProductType1": "Trong số các loại sản phẩm chính phổ biến, có 1 hoặc 2 loại thuộc tính của sản phẩm chính",
    "theGeneralOfMasterProductType": "Trong số các loại sản phẩm chính phổ biến, loại sản phẩm chính không có thuộc tính",
    "notIncludeTheTemporaryUntrackedMaster": "Không bao gồm sản phẩm chính \"Tạm dừng theo dõi\"",
    "skuHasBeenBoundByThe": "Master SKU đã liên kết bởi sản phẩm kết hợp {bundle MSKU}, vui lòng hủy liên kết trước khi xóa.",
    "skuHasMatchedTheProductsOf": "Master SKU đã khớp với các sản phẩm của kho bên thứ ba {tên kho bên thứ ba}",
    "skuHasPendingOutboundOrderOr": "Master SKU có Phiếu xuất kho hoặc Phiếu nhập kho đang chờ xử lý, vui lòng hoàn tất trước khi xóa",
    "stockOfTheMasterSkuIn": "Tổng tồn kho của Master SKU trong kho {tên kho 1, tên kho 2,} khác 0, vui lòng cập nhật về 0 trước khi xóa.",
    "errorPleaseRefreshThePageAnd": "Số liệu bất thường, vui lòng làm mới trang và thử lại",
    "retry": "Thử lại",
    "productSuccessfullyCreatedMaster": "Tạo sản phẩm chính thành công",
    "beBetweenMust": "Phải giữa 1-999.999",
    "addOption": "Thêm lựa chọn",
    "stockMonitoringAndSynchronizationEnable": "Bật giám sát và đồng bộ tồn kho",
    "toWarehouseInboundAdd": "Thêm vào khi + Nhập kho",
    "channelProductsRefer": "Trích dẫn sản phẩm của kênh",
    "failedUnbind": "Hủy liên kết thất bại",
    "addMskuArticle": "Làm thế nào để thêm Master SKU?",
    "unbindAllFailed": "Hủy liên kết tất cả không thành công",
    "allUnbindSuccessfully": "Tất cả hủy liên kết thành công",
    "partialUnbinding": "Không thể hủy liên kết một phần",
    "unbinded": "Chưa liên kết",
    "bound": "Đã liên kết",
    "unbindedAll": "Tất cả chưa liên kết",
    "boundAll": "Tất cả đã liên kết",
    "masterSkuHasEnableStockSynchronization": "Master SKU đã bật đồng bộ tồ kho, vui lòng đi đến quản lý tồn kho để chỉnh sửa tồn kho",
    "youNeedToEnableStockSynchronization": "Nếu bạn cần bật đồng bộ tồn kho của sản phẩm chính, vui lòng tiếp tục hoàn tất thao tác thêm sản phẩm chính vào kho và nhập kho",
    "toProductListReturn": "Quay lại danh sách sản phẩm",
    "failedDataDownload": "Tải xuống dữ liệu thất bại",
    "createFailedAll": "Tất cả tạo thất bại!",
    "addManually": "Thêm thủ công",
    "masterProductSuccessfullyCreatedIfYou": "Một số sản phẩm chính được tạo thành công! Nếu bạn cần bật đồng bộ tồn kho sản phẩm chính, vui lòng tiếp tục hoàn tất thao tác thêm sản phẩm chính vào kho và nhập kho",
    "failedToCreateSome": "Một số tạo thất bại!",
    "masterProductView": "Xem Sản phẩm chính",
    "successfullyCreatedAll": "Tất cả tạo thành công!",
    "productSuccessfullyCreatedIfYouNeed": "Sản phẩm chính được tạo thành công! Nếu bạn cần bật đồng bộ tồn kho của sản phẩm chính, vui lòng tiếp tục hoàn tất thao tác thêm sản phẩm chính vào kho và nhập kho",
    "productCanOnlyUseOneCurrency": "Sản phẩm chỉ có thể sử dụng một loại tiền tệ, vui lòng chọn lại!",
    "creatingTheMasterProductCanCompleted": "Sau khi tạo sản phẩm chính, có thể hoàn tất nhập kho và bật đồng bộ tồn kho Master SKU.\nSau khi bật, tồn kho của Master SKU và cửa hàng của kênh có thể tự động cập nhật và đồng bộ",
    "masterSkuHasBeenPlacedIn": "Master SKU kết hợp đã đặt vào kho hàng",
    "warehouseDefault": "Kho mặc định",
    "toWarehouseAdd": "Thêm vào kho",
    "toStoreClone": "Sao chép đến cửa hàng",
    "enableStockSynchronizationOfMasterProduct": "Để bật đồng bộ tồn kho của sản phẩm chính, vui lòng tiếp tục hoàn tất thao tác thêm vào kho, nhập kho. Nếu muốn nhanh chóng đăng sản phẩm đến cửa hàng của kênh để bán, vui lòng sử dụng \"Sao chép đến cửa hàng\"",
    "quicklyPublishTheMasterProductsTo": "Để nhanh chóng đăng sản phẩm chính đến cửa hàng của kênh và bán, vui lòng sử dụng \"Sao chép đến cửa hàng\"",
    "informationWarehouse": "Thông tin kho",
    "inbounded": "Đã nhập kho",
    "inboundNot": "Chưa nhập kho",
    "skuHasBeenPlacedInInbound": "Master SKU đã đặt vào kho",
    "statusStock": "Trạng thái tồn kho",
    "addByStore": "Thêm theo cửa hàng",
    "addBundleProduct": "Thêm combo",
    "oneCurrency": "Sản phẩm chỉ có thể được sử dụng bằng một loại tiền tệ, vui lòng kiểm tra",
    "variantSingle": "Một thuộc tính",
    "productsOfVariants": "{productSum} sản phẩm ({availableNum} trong số {VariantSum} thuộc tính có thể tạo Sản phẩm chính). Thuộc tính bị ràng buộc với MSKU sẽ không thể Master SKU tại Ginee\nNhấp vào nội dung để chỉnh sửa",
    "synchronizationOfTheMasterSkuWill": "Tạo sản phẩm chính trước khi hoàn tất nhập kho, rồi bật đồng bộ tồn kho của sản phẩm chính. Sau khi bật, tồn kho của Master SKU và cửa hàng của kênh có thể tự động cập nhật, đồng bộ.",
    "toTheNChannelsProductList": "Đến ngay danh sách sản phẩm của kênh {n}",
    "channelsProductList": "Vào danh sách sản phẩm của kênh:",
    "goNow": "Đi ngay",
    "goToTheChannelProductList": "Vui lòng đến Danh sách sản phẩm của kênh - và chọn sản phẩm cần trích dẫn của kênh",
    "doNotHaveAnIntegratedStore": "Bạn tạm thời chưa ủy quyền cửa hàng, vui lòng đến ủy quyền",
    "toModifyGo": "Đến chỉnh sửa",
    "skuHasBeenInboundToThe": "Master SKU đã đặt vào tồn kho, vui lòng đến [Quản lý tồn kho] để chỉnh sửa tồn kho có sẵn",
    "productCombined": "loại thuộc tính đã tồn tại, vui lòng nhập lại",
    "combinedProductAdd": "Sản phẩm kết hợp",
    "theContentToEditClick": "Thêm sản phẩm kết hợp",
    "combinationProductsEdit": "Chỉnh sửa sản phẩm kết hợp",
    "preview": "Xem",
    "atTheEndAdd": "Thêm kết thúc",
    "increaseStartingValueAutomatically": "Tự động tạo giá trị bắt đầu",
    "atTheBeginningAdd": "Thêm mở đầu",
    "xxWithYyyReplace": "xx thay thế thành yyy",
    "massEditSKU": "Chỉnh sửa hàng loạt Master SKU",
    "changeStockTo": "Sửa hàng tồn kho thành",
    "massEditStock": "Chỉnh sửa Tồn kho hàng loạt",
    "applyToAllProducts": "Ứng dụng cho tất cả sản phẩm",
    "masterDeleteFailedTip": "Sản phẩm đã được chuyển thành công khỏi kho {tên kho 1}, ⁣ {tên kho 2}.",
    "masterStockpdateFailed": "Cập nhật hàng tồn kho của Mã hàng chính đã được lưu trữ không thành công, vui lòng chuyển đến phần quản lý hàng tồn kho để sửa đổi",
    "stockLimit": "Phạm vi Hàng tồn kho nên từ 0-999.999!",
    "reduceByPercent": "Theo tỷ lệ phần trăm giảm xuống",
    "addByPercent": "Theo tỷ lệ phần trăm tăng lên",
    "reduceByAmount": "Theo số tiền giảm xuống",
    "addByAmount": "Theo số tiền tăng lên",
    "massEdit": "Chỉnh sửa hàng loạt",
    "mskuNoSpaces": "Master SKU không bao gồm khoảng trắng, vui lòng cập nhật",
    "followingXProductsRestore": "Đã khôi phục theo dõi {X} sản phẩm",
    "productsAreSetAsTemporary": "Đã tạm dừng theo dõi {X} sản phẩm",
    "masterSkuDoesNotMeetThe": "Master SKU không đáp ứng các điều kiện xóa, do đó không thể xóa loại thuộc tính và các tùy chọn thuộc tính",
    "barcodeExist": "Mã vạch đã bị {MSKU} chiếm dụng, vui lòng nhập lại",
    "massEditPrice": "Chỉnh sửa Giá hàng loạt",
    "changePriceTo": "Sửa giá thành",
    "replaceWith": "Thay thế với",
    "masterSkuLimit": "Master SKU tối đa 200 ký tự",
    "numberOfVariants": "Số lượng thuộc tính",
    "yourCurrentPackageDoesNotHave": "Gói hiện tại của bạn không có quyền thao tác, vui lòng đăng ký gói mới hoặc liên hệ với chăm sóc khách hàng để nâng cấp gói",
    "yourNumberOfOrdersHasExceeded": "Số lượng đơn hàng của bạn đã vượt quá giới hạn của gói (tối đa {X}), vui lòng đăng ký gói mới hoặc liên hệ với bộ phận chăm sóc khách hàng để nâng cấp gói.",
    "salesTaxAmountTip": "Chi phí thuế cho một sản phẩm",
    "purchaseTimeTip": "Khoảng thời gian từ khi thu mua đến khi nhập kho",
    "sourceUrlTip": "Nguồn gốc của sản phẩm, Link nhà cung cấp hoặc Link nguồn nếu là sản phẩm sưu tập",
    "chatLimitTip2": "Quyền của bạn đã vượt quá giới hạn gói, vui lòng truy cập Ginee OMS để điều chỉnh trước khi tiếp tục sử dụng Ginee Chat",
    "chatNoResourcesLimitTip": "Số lượng cửa hàng hoặc tài khoản nhân viên của bạn đã vượt quá giới hạn gói, vui lòng truy cập Ginee OMS để điều chỉnh trước khi tiếp tục sử dụng Ginee Chat",
    "chatNopermissionLimitTip": "Gói tài khoản của bạn không bao gồm chức năng trò chuyện Ginee Chat, vui lòng liên hệ bộ phận chăm sóc khách hàng để nâng cấp gói và đăng nhập lại.",
    "packageLimit": "Giới hạn của gói",
    "masterProductpublishToTheStore": "Sản phẩm chính/Đăng lên cửa hàng",
    "masterProductmassEdit": "Sản phẩm chính/Chỉnh sửa hàng loạt",
    "stockStatusTip": "Trạng thái tồn kho hiển thị trong cột này: kho hàng, trạng thái đồng bộ tồn kho và thông tin loại SKU chính.\nKho hàng: Kho nơi SKU chính này được đặt.\nTrạng thái đồng bộ tồn kho: SKU chính đã bật đồng tăng giảm hàng tồn kho hay chưa, \"trạng thái đồng bộ tồn kho\" của sản phẩm chính mới thêm vào được mặc định là Tắt.\nĐánh dấu SKU chính kết hợp: Nếu SKU chính này là SKU chính kết hợp, sẽ hiển thị đánh dấu \"kết hợp\" .",
    "synchronizationIsNotEnabledYouNeed": "Tắt: Đồng bộ tồn kho chưa được bật\nChưa đạt tất cả điều kiện cần để bật",
    "synchronizationIsEnabledStock": "Bật: Đồng bộ tồn kho đã bật\nĐã đạt tất cả điều kiện cần để bật",
    "stockSynchronizationStatusOfTheMaster": "Nếu trạng thái đồng bộ tồn kho của SKU chính chưa được bật, thì SKU chính này sẽ không bật chức năng đồng tăng giảm tồn kho",
    "stockSynchronizationStatusOfTheCombined": "Nếu trạng thái đồng bộ tồn kho của MSKU kết hợp chưa được bật, MSKU kết hợp sẽ không tự động tính toán tồn kho có sẵn dựa theo SKU chính liên kết và cũng sẽ không bật chức năng đồng bộ tồn kho.",
    "open": "Bật",
    "settingsManagementForAuthorizedThirdpartyAccounting": "Được ủy quyền quản lý cấu hình phần mềm tài chính bên thứ ba",
    "accountingSettings": "Cấu hình kế toán",
    "addedBy": "Thêm bởi",
    "timeAdded": "Thời gian thêm",
    "updater": "Cập nhật bởi",
    "checkLog": "Kiểm tra nhật ký thao tác",
    "accurateOrder": "Đơn hàng Accurate",
    "accurateProduct": "Sản phẩm Accurate",
    "failedToGetChannelsLabelUrl": "Kênh nhận đơn thất bại, xin vui lòng đẩy lại",
    "bindWarehouse": "Liên kết kho hàng",
    "Prohibited": "Cấm sử dụng",
    "interval": "Khoảng không",
    "productSales": "Doanh số sản phẩm",
    "updatetime": "Thời gian cập nhật",
    "masterProductCreationTime": "Thời gian tạo sản phẩm Chính",
    "channelProductCreationTime": "Thời gian tạo sản phẩm kênh",
    "salesOfDifferentActivityLevelsOverlap": "Doanh số của các cấp độ hoạt động khác nhau trùng lặp, vui lòng nhập lại",
    "salesRangeOfXCannotBe": "Phạm vi bán hàng của {x} không thể cao hơn {y}",
    "channelProductDimensionActivitySetting": "Hoạt động thiết lập kích thước sản phẩm của kênh, hiện chỉ hỗ trợ Shopee",
    "synchronizeallthexxinthewarehousewheremskusarelocatedtochannelskusaccordingtothexxxratiowhenthedefaultruleisenabledmskusthatalreadyexistinotherrulesarenotincluded": "Đồng bộ hóa tổng XX trong kho chứa tất cả MSKU với tất cả các SKU kênh theo tỷ lệ XXX. Khi quy tắc mặc định được bật,  sẽ không bao gồm các MSKU đã tồn tại trong các quy tắc khác",
    "availablestockforotherstoresispushedto": "Tồn kho có sẵn của các cửa hàng khác bị đẩy về 0",
    "allExceptxx": "Tất cả ngoại trừ XX",
    "whentheavailablestockislessthanorequaltoxx": "Khi tồn kho có sẵn nhỏ hơn hoặc bằng XX",
    "whenmsku": "Khi MSKU",
    "stockThreshold": "Ngưỡng tồn kho",
    "availablestocktransferstock": "Tồn kho có sẵn + Tồn kho đang chuyển",
    "availablestock": "Tồn kho có sẵn",
    "pleaseSelectawarehouse": "Vui lòng chọn kho hàng",
    "addPolicy": "Thêm sách lược",
    "pushratio": "Tỷ lệ đẩy",
    "warehousename": "Tên kho hàng",
    "pushbyfixedvalue": "Đẩy theo giá trị cố định",
    "pushbybatio": "Đẩy theo tỷ lệ",
    "setSyncPolicy": "Đặt sách lược đồng bộ",
    "setSynchronizationconditions": "Đặt điều kiện đồng bộ",
    "selectMsku": "Chọn MSKU",
    "addMsku": "Thêm MSKU",
    "ruleNamecannotbeempty": "Tên quy tắc không được để trống",
    "pleaseenterarulename": "Vui lòng nhập tên quy tắc",
    "executeRules": "Thực thi quy tắc",
    "pushLog": "Nhật ký đẩy ngày",
    "default": "Mặc định",
    "defaultRules": "Quy tắc mặc định",
    "storeName1": "Tên cửa hàng",
    "stockType": "Loại tồn kho",
    "pushType": "Kiểu đẩy",
    "mainStoreStockLocked": "Khóa tồn kho cửa hàng chính",
    "log": "Nhật ký",
    "addrule": "Thêm quy tắc",
    "stocklistpromptwhenadjustingstockstockadjustment": "[Danh sách tồn kho] Lời nhắc khi nhảy đến điều chỉnh tồn kho: tồn kho điều chỉnh [(+) / (-) X]",
    "promptwhenaddingstockwithmanualinboundmanualinbound": "Lời nhắc khi có đơn hàng mới nhập kho thủ công: nhập kho thủ công 【121133】",
    "whenmanuallypushinginventorymanuallyexecutestockpushrules": "Lời nhắc khi đẩy tồn kho tự động: thực hiện thủ công quy tắc đẩy tồn kho",
    "promptwhentheordercomesinneworder": "Lời nhắc khi có đơn hàng mới: đơn hàng mới【576512290177977223】",
    "promptwhenorderiscancelledordercanceled": "Lời nhắc khi đơn hàng bị hủy: hủy đơn hàng [576512290177977223]",
    "promptwhenbindingforthefirsttimeenablestockmonitoringandsyncsettingsthenpushdatatostoresstock": "Lời nhắc khi ràng buộc lần đầu tiên: bật cài đặt giám sát và đồng bộ tồn kho, khởi tạo tồn kho cửa hàng",
    "xxofchannelproductsfailed": "XX sản phẩm kênh đã thất bại",
    "creationtime": "Giờ tạo",
    "pushquantity": "Số lần đẩy",
    "reasonforchange": "Nguyên nhân thay đổi",
    "seemore": "Xem thêm",
    "imageMasterProduct": "Hình ảnh & sản phẩm chính",
    "partialSuccess": "Thành công một phần",
    "storeupdateresults": "Kết quả cập nhật cửa hàng",
    "pleaseentersearchcontent": "Vui lòng nhập nội dung tìm kiếm",
    "storepushrecord": "Nhật ký đẩy của cửa hàng",
    "stockValue": "Giá trị tồn kho",
    "isBestSellingItem": "Bùng nổ",
    "isTrendingItem": "Mạnh",
    "isNewItem": "Mới",
    "isLowSellingItem": "Bán chậm",
    "isNormalItem": "Bình thường",
    "mskuXAlreadyExistsInRule": "MSKU: {X} đã tồn tại trong quy tắc {Y}",
    "ruleNameAlreadyExists": "Tên quy tắc đã tồn tại",
    "thereAreDuplicateRuleNameOr": "Tồn tại trùng lặp tên quy tắc hoặc sản phẩm Chính",
    "noteWhenTheInventoryThresholdIs": "Lưu ý: Khi bật ngưỡng tồn kho, khi tồn kho trong \"Quy tắc đồng bộ\" nhỏ hơn hoặc bằng với \"Ngưỡng\", thì tồn kho có sẵn của các cửa hàng khác ngoại trừ cửa hàng Chính sẽ bị đẩy về 0",
    "totalCapitalUsedFromAllWarehouses": "Tổng vốn chiếm dụng của tất cả kho",
    "storeIntegrationIsInvalidPleaseReintegrate": "Ủy quyền cửa hàng không hợp lệ, vui lòng ủy quyền lại",
    "mskuAndStoreAreNotBound": "Liên kết MSKU và cửa hàng không hợp lệ",
    "warehouseAndStoreAreNotBound": "Chưa liên kết kho hàng và cửa hàng",
    "warehouseAndMskuAreNotBound": "Chưa liên kết kho hàng và MSKU",
    "productChannelAndMskuAreNot": "Chưa liên kết sản phẩm kênh và MSKU",
    "deleteRule": "Xác nhận xóa quy tắc {X}?",
    "ruleNameXChangedToY": "Tên quy tắc: từ {x} đổi thành {Y}",
    "pushTypeChangeFromFixedValue": "Loại đẩy: thay đổi từ đẩy theo giá trị cố định sang đẩy theo tỷ lệ, đẩy ở mức {X}% của tồn kho có sẵn trong kho {X}+kho{y}",
    "pushTypeChangeFromProportionalPush": "Loại đẩy: thay đổi từ đẩy theo tỷ lệ thành đẩy giá trị cố định, giá trị cố định là XXX",
    "warehouseNameWarehouseXHasBeen": "Tên kho hàng: Kho {X} thành kho {Y}",
    "addRuleNewRule": "Thêm quy tắc: Quy tắc mới",
    "statusChangeStatusHasBeenChanged": "Thay đổi trạng thái: Trạng thái đổi từ TẮT sang MỞ",
    "statusChangeStatus": "Thay đổi trạng thái: Trạng thái đổi từ MỞ sang TẮT",
    "deleteRuleRuleHasBeenDeleted": "Xóa quy tắc: Đã xóa quy tắc",
    "ruleChangeTheTotalOfStock": "Thay đổi quy tắc: Tổng tồn kho có sẵn trong kho {X} được thay đổi từ {X}% thành {Y}%",
    "policyChangeTheAvailableStock": "Thay đổi sách lược: Tồn kho có sẵn + tồn kho vận chuyển của kho {X} được thay đổi từ tỷ lệ đẩy {X}% thành {Y}%",
    "editAddedXMskuThresholdIs": "Chỉnh sửa: thêm {X}MSKU, ngưỡng là {X}, chọn cửa hàng chính là: {Y}",
    "editXMskuThresholdChangedFrom": "Chỉnh sửa: ngưỡng {X}MSKU thay đổi từ {Y} sang {Z}",
    "editXMskuMainStoreHas": "Chính sửa: {X}MSKU cửa hàng chính thay đổi từ {X} sang {Y}",
    "editXMskuHasBeenDeleted": "Chỉnh sửa: {X} MSKU đã bị xóa",
    "afterDeletionThePolicyNeedsTo": "Sau khi xóa, sách lược cần được định cấu hình lại, vui lòng thực hiện một cách thận trọng",
    "thirdpartyWarehouseReceptionTime": "Thời gian tiếp nhận kho bên thứ ba",
    "whetherToReplaceProduct": "Có thay thế sản phẩm không",
    "whetherItIsAGift": "Có tặng quà không",
    "addGiftBForOrder": "Đơn hàng {A} được thêm quà {B} vào, số lượng là {C}, kho đã chọn là {D}",
    "orderAWithProductBIs": "Đơn hàng {A} sản phẩm {B} được thay thế bằng {C}, kho hàng đã chọn là {D}",
    "changeSelectedWarehouseCToD": "Đơn hàng {A} sản phẩm {B} các kho hàng đã chọn thay đổi từ {C} thành {D}",
    "gift": "Quà tặng",
    "specifiedWarehouse": "Kho hàng chỉ định",
    "specifiedQuantity": "Số lượng chỉ định",
    "massAddForOrdersSpecifiedMsku": "Đối với việc thêm đơn hàng loạt: chỉ định MSKU và số lượng!",
    "replace1": "Thay thế",
    "and1": "Và",
    "replaceInBatchForTheSpecified": "Đối với các đơn hàng có sản phẩm chỉ định, hãy thay thế hàng loạt!",
    "changeWarehouseTo": "Thay đổi kho hàng thành",
    "specifiedProduct": "Sản phẩm chỉ định",
    "doWarehouseModificationForSpecifiedProduct": "Đối với các đơn hàng có sản phẩm chỉ định, hãy sửa đổi kho hàng! (Sản phẩm sửa đổi phải liên kết với kho hàng tương ứng)",
    "doWarehouseModificationForAllProduct": "Đối với các đơn hàng với mọi sản phẩm, hãy sửa đổi kho hàng! (Sản phẩm sửa đổi cần liên kết với kho hàng tương ứng)",
    "byProduct": "Theo sản phẩm",
    "byOrder": "Theo đơn hàng",
    "processingMethod": "Phương thức xử lý",
    "addGift": "Thêm quà",
    "replaceProduct": "Thay thế sản phẩm",
    "changeWarehouse": "Thay đổi kho hàng",
    "modifyProductInformation": "Sửa sản phẩm",
    "weightkg": "Trọng lượng(kg)",
    "youMustAddAllTheVariation": "Bạn phải thêm tất cả hình ảnh thuộc tính hoặc để trống tất cả chúng.",
    "masterVariationName": "主商品规格名称",
    "operationSuccessPrompt": "Thông báo thao tác thành công",
    "checkProduct": "Kiểm tra sản phẩm",
    "productHasBeenSubmittedAndIs": "Sản phẩm đã được gửi và đang chờ {channel} xử lý, bạn có thể kiểm tra sản phẩm trên tab Đang xử lý, Đang bán hoặc Đăng thất bại",
    "minimumStockLimit": "Phạm vi tồn kho tối thiểu là 0-999.999",
    "noNeedToProvideAnyTransport": "Không cần cung cấp bất kỳ dịch vụ vận chuyển nào, như Chứng chỉ vận chuyển, Dịch vụ tận nơi, Dịch vụ tại điểm nhận hàng, v.v.",
    "thisTypeCanBeSelectedTo": "Chọn loại này có thể vận chuyển với trọng lượng trên 50kg, hoặc tiến hành cài đặt các xử lý đặc biệt khác cho sản phẩm nếu cần.",
    "notShipped": "Không vận chuyển",
    "sellerShipping": "Người bán vận chuyển",
    "shipYourOrderToTheBLIBLI": "Vận chuyển đơn hàng đến đơn vị chuyển phát nhanh chỉ định mà Blibli hợp tác. Đối với Sameday / Instant, đơn hàng sẽ được lấy tại điểm lấy hàng.",
    "shippingUsingBlibliLogisticPartner": "Thông qua hợp tác vận chuyển với Blibli",
    "minimumStock": "Tồn kho tối thiểu",
    "enterSellingPrice": "Vui lòng nhập giá bán",
    "sellingPriceLimit": "Phạm vi giá bán nên là 10.000 - 1000.000.000",
    "originalPrice": "Giá gốc",
    "enterOriginalPrice": "Vui lòng nhập giá gốc",
    "changeMinimumStockTo": "Sửa Tồn kho tối thiểu thành",
    "restrictionsTheProductCannotBeEdited": "API giới hạn, không thể chỉnh sửa sản phẩm này",
    "unboundMSKU": "Chưa liên kết  Master SKU",
    "bindMSKU": "Liên kết Master SKU",
    "Country": "Quốc gia/Khu vực",
    "ContinuetoPublish": "Tiếp tục đăng tải",
    "CreateTimeNewest": "Thời gian tạo giảm dần",
    "CreateTimeOldest": "Thời gian tạo tăng dần",
    "notification": "Nhắc nhở",
    "NoBrand": "OEM",
    "PromotionActivity": "Hoạt động",
    "PromotionCampaign": "Chiến dịch",
    "PromotionDuration": "Thời gian",
    "PromotionPrice": "Giá khuyến mãi",
    "PromotionOriginalPrice": "Giá gốc",
    "cannot0": "Sản phẩm không được hiển thị với người bán. Lý do: Tồn kho bằng 0",
    "EditStock": "Sửa đổi Tồn kho",
    "ResultsFail": "Thao tác sản phẩm có vấn dề do vấn dề mạng, vui lòng đợi giây lát",
    "SomeOperationsFailed": "Thao tác sản phẩm thất bại",
    "Sort": "Thứ tự",
    "MostStock": "Tồn kho tăng dần",
    "LeastStock": "Tồn kho nhỏ dần",
    "Oldest": "Cập nhật mới sang cũ",
    "Newest": "Cập nhật cũ sang mới",
    "Within7Days": "7 ngày gần nhất",
    "Yesterday": "Hôm qua",
    "Today": "Hôm nay",
    "CountDraftFailed": "Đăng tải thất bại",
    "CountDraftSuccess": "Đăng tải thành công",
    "CountRemoveFailed": "Xoá thất bại",
    "CountRemoveSuccess": "Xoá thành công",
    "CountUnAvailableFailed": "Tắt bán thất bại",
    "CountUnAvailableSuccess": "Tắt bán thành công",
    "CountAvailableFailed": "Đăng tải thất bại",
    "CountAvailableSuccess": "Đăng tải thành công",
    "PartDraftFailed": "Vài sản phẩm đăng tải thành công",
    "DraftFailed": "Đăng tải thất bại",
    "DraftSuccess": "Đăng tải thành công",
    "PartRemoveFailed": "Vài sản phẩm xoá thất bại",
    "RemoveFailed": "Xoá thất bại",
    "RemoveSuccess": "Xoá thành công",
    "PartUnAvailableFailed": "Vài sản phẩm tắt bán thất bại",
    "UnAvailableFailed": "Tắt bán thất bại",
    "UnAvailableSuccess": "Tắt bán thành công",
    "PartAvailableFailed": "Vài sản phẩm bật bán thất bại",
    "AvailableFailed": "Tạo và bật bán thất bại",
    "AvailableSuccess": "Bật bán thành công",
    "InProcessDraftPublishModalTitle": "Đang đăng tải sản phẩm hàng loạt",
    "InProcessRemoveModalTitle": "Đang xoá sản phẩm",
    "InProcessDisableModalTitle": "Sản phẩm kênh bán đang tắt bán",
    "InProcessPublishModalTitle": "Sản phẩm kênh bán đang bật bán",
    "StockRulesInfo": "Tồn kho chỉ được nhập từ {minNum}~ 999,999",
    "ResultsClose": "Đóng",
    "ResultsTitle": "Kết quả",
    "ApplyToAll": "Áp dụng tất cả",
    "MassEditStock": "Chỉnh sửa hàng loạt",
    "SetUnAvailable": "Tắt bán",
    "SetAvailable": "Bật bán",
    "DeletedDesc": "Sản phẩm đã bị xoá khỏi Ginee cũng sẽ bị xoá trên các cửa hàng khác.",
    "DeletedTitle": "Bạn có chắc muốn xoá sản phẩm?",
    "PublicDesc": "Sản phẩm đã bật bán trên Ginee cũng sẽ được bật bán trên các kênh khác.",
    "PublicTitle": "Bạn có chắc muốn bật bán sản phẩm?",
    "DisabledDesc": "Sản phẩm đã tắt bán trên Ginee cũng sẽ được tắt bán trên các kênh khác.",
    "DisabledTitle": "Bạn có chắc muốn tắt bán sản phẩm?",
    "productTime": "Thời gian",
    "ProductSelected": "Đã chọn",
    "ProductParentSku": "PSKU",
    "ProductNameAndStore": "Tên sản phẩm và cửa hàng",
    "adjustSyncFailed": "Đồng bộ hóa tồn kho các sản phẩm sau không thành công, vui lòng thử lại",
    "UpdateStock": "Cập nhật",
    "NewStock": "Tồn kho mới",
    "ModifyStock": "Vui lòng sửa đổi tồn kho trong Quản lý Tồn kho",
    "CurrentStock": "Tồn kho hiện có",
    "adjustStock": "Điều chỉnh Tồn kho",
    "categories": "Danh mục",
    "removeList": "Xóa bỏ",
    "productStatus": "Trạng thái",
    "savedAsdraft": "Lưu bản nháp thành công",
    "pleaseSelect": "Vui lòng chọn",
    "ProductDescription": "Mô tả sản phẩm",
    "masterCategory": "Danh mục chính",
    "ProductImage": "Hình ảnh sản phẩm",
    "defaultPriceError": "Phạm vi giá bán mặc định phải là 0-1000.000.000",
    "editDefaultPrice": "Chỉnh sửa giá bán mặc định",
    "editDescription": "Chỉnh sửa mô tả sản phẩm",
    "addAfterName": "Thêm ở cuối tên",
    "addBeforeName": "Thêm ở đầu tên",
    "weightShouldBeBetweenKg": "Phạm vi trọng lượng là 0.001-1000",
    "workingHours": "Giờ làm việc",
    "shippingSettings": "Cài đặt vận chuyển",
    "massEditProductStatus": "Chỉnh sửa trạng thái nhiều sản phẩm",
    "goToEdit": "Đi đến chỉnh sửa",
    "theInventoryOfTheMasterProduct": "Hàng tồn kho của sản phẩm chính trong [Quản lý Hàng tồn kho], vui lòng đi đến để chỉnh sửa hàng loạt hàng tồn kho của Sản phẩm đã chọn",
    "changeMinPurchaseQuantityTo": "Sửa số lượng mua tối thiểu thành",
    "massEditMinPurchaseQuantity": "Chỉnh sửa hàng loạt số lượng mua tối thiểu",
    "massEditDimensionsCm": "Chỉnh sửa kích thước hàng loạt",
    "massEditProductName": "Chỉnh sửa tên nhiều sản phẩm",
    "editProductImage": "Chỉnh sửa hình ảnh nhiều sản phẩm",
    "customDisplayInformation": "Hiển thị thông tin tùy chỉnh",
    "logisticsChoose": "Chọn vận chuyển",
    "selectionOfStoreswarehousesMass": "Chọn nhiều cửa hàng / kho hàng",
    "toMatchCategoryNeed": "Cần phối hợp danh mục",
    "categoriesMatched": "Đã phối hợp danh mục",
    "productsSelected": "Sản phẩm đã chọn",
    "categoryAttributesShopee": "Thuộc tính danh mục Shopee",
    "categoryAttributesEdit": "thuộc tính danh mục",
    "theValueJustEnteredWantIt": "Giá trị vừa thêm vào, có áp dụng cho tất cả sản phẩm cùng loại",
    "matchingCategoryAndVariantAttributesProduct": "Sau khi phối hợp danh mục với thuộc tính, có thể tự động phối hợp thông tin sản phẩm, như Thuộc tính giá cả, hàng tồn kho, vv",
    "productInfoSource": "Phối hợp danh mục",
    "noLogisticOptions": "Hiện không có lựa chọn vận chuyển",
    "sellingPrice": "Giá bán",
    "productUpdateFailed": "Cập nhật sản phẩm thất bại",
    "publishFailed": "Đăng sản phẩm thất bại",
    "publishSucceeded": "Đăng sản phẩm thành công",
    "massEditMinimumStock": "Chỉnh sửa Tồn kho tối thiểu hàng loạt",
    "skuLimit": "SKU phải ít hơn 100 kí tự",
    "sku": "SKU",
    "packageSizeCM": "Kích thước gói hàng(cm)",
    "main": "Hình ảnh",
    "maxval": "Tối đa {val} ảnh",
    "priceBetweenMy": "Pham vi nhập từ 0.1-1000.000.000",
    "priceBetweenTh": "Pham vi nhập từ 1-500.000",
    "priceBetweenVn": "Pham vi nhập từ 1000-120.000.000",
    "priceBetweenPh": "Pham vi nhập từ 5-1.000.000",
    "priceBetweenId": "Pham vi nhập từ 99-150.000.000",
    "weightg": "Trọng lượng(g)",
    "selectABrandPlease": "Vui lòng chọn một thương hiệu",
    "uploadAVariantPicturePlease": "Vui lòng tải lên một hình ảnh biến thể",
    "uploadProductImagePlease": "Vui lòng tải lên hình ảnh sản phẩm",
    "enterADescriptionPlease": "Vui lòng nhập mô tả",
    "enterTheProductNamePlease": "Vui lòng nhập tên sản phẩm",
    "selectTheStatusOptionPlease": "Vui lòng chọn tùy chọn trạng thái",
    "selectPreorderOptionPlease": "Vui lòng chọn tùy chọn đặt hàng trước",
    "enterLogisticsOptionsPlease": "Vui lòng nhập các tùy chọn hậu cần",
    "enterThePackageSizePlease": "Vui lòng nhập kích thước gói hàng",
    "enterTheWeightPlease": "Vui lòng nhập trọng lượng",
    "enterstock": "Vui lòng nhập kho",
    "enterPrice": "Vui lòng nhập giá",
    "enterSKU": "Vui lòng nhập SKU",
    "theProductHasVariationsIfYour": "Sản phẩm có nhiều loại Thuộc tính, nếu sản phẩm của bạn có màu sắc, kích thước khác nhau, vui lòng chọn mục này",
    "enterAOptionForExampleRed": "Vui lòng chọn mục, ví dụ: màu đỏ vv",
    "addVariationType": "Thêm loại Thuộc tính",
    "variationType": "Loại Thuộc tính",
    "productDescriptionLimit": "Mô tả sản phẩm bắt buộc từ 6 đến 25.000 kí tự",
    "productDescriptionLimitTh": "Mô tả sản phẩm bắt buộc từ 25 đến 50.000 kí tự",
    "productDescriptionLimitVn": "Mô tả sản phẩm bắt buộc từ 100 đến 30.000 kí tự",
    "productDescriptionLimitPh": "Mô tả sản phẩm bắt buộc từ 2 đến 30.000 kí tự",
    "productDescriptionLimitIdMy": "Mô tả sản phẩm bắt buộc từ 20 đến 30.000 kí tự",
    "selectBrand": "Vui lòng chọn thương hiệu",
    "ShopeeCategory": "Danh mục Shopee",
    "addAttributeValue": "Thêm giá trị thuộc tính",
    "saveUpdateAndNextStep": "Lưu và tiếp theo",
    "productNameLimitVn": "Tên sản phẩm phải từ 10 đến 120 kí tự",
    "productNameLimitPh": "Tên sản phẩm phải từ 20 đến 100 kí tự",
    "productNameLimitOthers": "Tên sản phẩm phải từ 20 đến 120 kí tự",
    "productNameLimitId": "Tên sản phẩm phải từ 5 đến 255 kí tự",
    "stocktLimitOhters": "Phạm vi Hàng tồn kho nên từ 0-999.999.999!",
    "stocktLimit": "Phạm vi Hàng tồn kho nên từ 0-999.999!",
    "setUnavailable": "Xuống kệ",
    "setAvailable": "Lên kệ",
    "saveAsDraft": "Lưu thành bản nháp",
    "percentageLimit": "Tỷ lệ phần trăm phải từ 0-100.000",
    "attributesNo": "Không có thuộc tính",
    "categoryNo": "Không có danh mục",
    "categoryAndAttributeApply": "Áp dụng cùng một danh mục và các tùy chọn thuộc tính cho các sản phẩm khác của cùng một danh mục nguồn ({N})?",
    "detailsView": "Xem chi tiết",
    "pleaseSetUpLogisticsInThe": "Vui lòng cài đặt vận chuyển trong trung tâm người bán Shopee",
    "optionMaximum": "Tối đa 20 kí tự",
    "typeMaximum": "Tối đa 14 kí tự",
    "vietnam": "Việt Nam",
    "thailand": "Thái Lan",
    "philippines": "Philippines",
    "indonesia": "Indonesia",
    "imageMustBeJOGPNGFormat": "Hình ảnh bắt buộc là định dạng JPG, PNG, tối đa 4MB, Độ phân giải: 300 * 300px ～ 1024 * 1024px",
    "imageTip": "Giới hạn của giao diện, kích cỡ tối đa 4MB, độ phân giải phải lớn hơn 300*300 px",
    "preOrderLimit": "Theo yêu cầu của danh mục, thời gian phải từ {min} đến {max} ngày làm việc",
    "preOrderTip": "Star, Star+, Mall hỗ trợ tối đa 90 ngày làm việc",
    "malaysia": "Malaysia",
    "dangerousGoods": "Hàng nguy hiểm",
    "dangerousGoodsTip": "Vui lòng điền chính xác \"Hàng nguy hiểm\". Điền \"Hàng nguy hiểm\" không chính xác có thể dẫn đến thêm chi phí vận chuyển hoặc giao hàng thất bại.",
    "dangerousGoodsOption": "Bao gồm pin/nam châm/chất lỏng/chất dễ gây cháy nổ",
    "attributeTip": "Giới hạn của API, tối đa 5 lựa chọn",
    "massEditTip1": "Vui lòng chọn danh mục sản phẩm và thuộc tính sản phẩm để tránh trường hợp sản phẩm không được công bố",
    "theMaximumRangeAccordingToApi": "API yêu cầu giới hạn phạm vi tối đa",
    "requiresToThisInformationToBe": "API yêu cầu phần này bắt buộc điền",
    "chooseTheStoreFirstPlease": "Vui lòng chọn cửa hàng trước",
    "variantImageTip": "Kích thước: 4MB, Độ phân giải: Min.300 * 300px (API yêu cầu phần này bắt buộc điền)",
    "variationTypeTip2": "loại biến thể đã tồn tại, vui lòng nhập lại",
    "variationoptionTip1": "Tùy chọn biến thể đã tồn tại, vui lòng nhập lại",
    "applyAttribute": "Áp dụng cùng một tùy chọn thuộc tính cho các sản phẩm khác trong cùng một danh mục nguồn {N}?",
    "noBrandBatch": "Bạn có chắc chắn thay đổi tùy chọn nhãn hiệu của danh sách sản phẩm này thành \"Không có nhãn hiệu\" không?",
    "noBrandBatchTip": "Do các hạn chế của API, chỉ hỗ trợ lựa chọn hàng loạt các nhãn hiệu không có nhãn hiệu.",
    "massEditBrand": "Điều chỉnh nhiều thương hiệu",
    "previous": "Trước",
    "emptyMass": "Xóa hàng loạt",
    "singapore": "Singapore",
    "canUploadMultiplePicturesAtOnce": "Bạn có thể tải lên nhiều ảnh cùng lúc, kéo và thả để thay đổi thứ tự",
    "contentToEditClick": "Nhấp vào nội dung của bảng để chỉnh sửa",
    "Policydetail": "Khi một sản phẩm được đăng tải cùng lúc lên nhiều cửa hàng trên sàn TMĐT",
    "PolicyReminder": "Nhắc nhở chính sách",
    "Clonesuccess": "Sao chép thành công",
    "copyFailedResult": "Sản phẩm sao chép thất bại",
    "copyCompete": "Hoàn tất Đồng bộ",
    "amountMustBeGreaterThanDiscount": "Số tiền phải lớn hơn số tiền giảm giá",
    "wholeStoreProduct": "Sản phẩm toàn cửa hàng",
    "valueMustBeGreaterThanThe": "Giá trị phải lớn hơn gradient trước đó",
    "theSumOfTheAvailableStock": "Tổng số MSKU trong tồn kho có sẵn của kho hàng, dựa theo",
    "ratioSyncToAllChannelSku": "Đồng bộ hóa với tất cả các SKU kênh",
    "whenthedefaultruleisonmskusthatalreadyexistinotherrulesarenotincluded": "Khi bật quy tắc mặc định, nó không bao gồm MSKU đã tồn tại trong các quy tắc khác",
    "statusDeleted": "Xóa",
    "statusInactive": "Không hoạt động",
    "statusActive": "Hoạt động",
    "optionsDoNotSupportEditAnd": "Do hạn chế của API, tùy chọn không hỗ trợ chỉnh sửa và xóa",
    "dueToApiRestrictionsLabel": "Do hạn chế của API, loại thuộc tính { label } là bắt buộc điền",
    "dueToTheChannelDelayIn": "Do sàn TMĐT cập nhật chậm trễ nên dữ liệu sau khi chỉnh sửa sẽ mất vài giây để cập nhật, vui lòng kiên nhẫn chờ đợi. Nếu chưa được cập nhật, bạn có thể nhấp vào nút đồng bộ theo cách thủ công hoặc vào trung tâm người bán để kiểm tra xem nó có ở trạng thái Đang xem xét hay không. Dữ liệu ở trạng thái Đang xem xét tạm thời không được đồng bộ.",
    "deletedProductCannotBeRecoveredAre": "Sau khi xóa sản phẩm sẽ không thể phục hồi, xác nhận xóa không?",
    "canNotInputChineseLanguage": "Không được nhập tiếng Trung",
    "areYouSureYouWantToZalora": "Bạn có chắc chắn muốn xóa thông tin thuộc tính này không? Việc xóa sẽ làm thay đổi cấu trúc SKU và thông tin giá tồn kho, bạn cần nhập lại các thông tin liên quan của SKU.",
    "failedToPublishGineeProductThat": "Đăng thất bại: sản phẩm mà Ginee đăng thất bại",
    "publishingGineeProductThatAreBeing": "Đang đăng: sản phẩm Ginee đang đăng lên hoặc sản phẩm đang xử lý trên Trung tâm người bán",
    "draftAProductDraftCreatedBy": "Bản nháp: bản nháp sản phẩm tạo bởi Ginee",
    "suspendedThisIsAProductWith": "Bị khóa: trong trung tâm người bán là trạng thái Bị khóa",
    "soldOutThisIsAProduct": "Hết hàng: trong trung tâm người bán là sản phẩm có tồn kho = 0 với trạng thái Đang hoạt động/Không hoạt động",
    "deactivatedThisIsAProductWith": "Không hoạt động: trong trung tâm người bán là trạng thái Không hoạt động",
    "liveThisIsAProductWith": "Hoạt động: trong trung tâm người bán là trạng thái Đang hoạt động",
    "currencyCannotBeEmpty": "Đơn vị tiền tệ không được để trống",
    "failedToUploadQualification": "Tải lên chứng nhận {0} thất bại",
    "failedToUploadImage": "Tải lên hình ảnh {0} thất bại",
    "thereCannotBeTheSameOption": "Trong cùng một tập hợp thuộc tính, không thể có tùy chọn trùng lặp",
    "numberOfVariationsInEachGroup": "Số lượng thuộc tính trong mỗi nhóm phải là {0} - {1}",
    "numberOfGroupsVariationMustBe": "Số lượng nhóm của loại thuộc tính phải là {0} - {1}",
    "qualificationCertificateOfTheCurrentProduct": "Chứng chỉ năng lực của danh mục sản phẩm này là bắt buộc, không được để trống",
    "forOneTypeOfQualificationCertificate": "Đối với một loại chứng nhận chất lượng, tổng số tệp và hình ảnh không được vượt quá {0}",
    "apiLimitationMaxSizeMb": "Giới hạn API, kích thước tối đa là 5MB và độ phân giải phải lớn hơn 600 * 600px",
    "totalSkuMustBeBetween": "Tổng số SKU phải giữa 1~100",
    "pleaseSelectTheTypeOfVariation": "Vui lòng chọn loại thuộc tính",
    "pleaseInputWeight": "Nhập để đặt lại",
    "pleaseInputLengthWidthAndHeight": "Vui lòng điền đầy đủ thông tin kích thước",
    "reviewing": "Đang đánh giá",
    "processing": "Đang xử lý",
    "reviewFailed": "Đánh giá thất bại",
    "thisCategoryDoesNotSupportCod": "Danh mục này không hỗ trợ COD",
    "skuMustBeCharacters": "SKU phải từ 1-50 ký tự",
    "warrantyPolicyMustBeCharacters": "Chính sách bảo hành phải từ 0-99 ký tự",
    "priceRangeShouldBe": "Khoảng giá dao động 100-100000000",
    "stocktLimitOhtersZalora": "Tồn kho dao động từ 0-99999",
    "weightShouldBeBetweenKgZalora": "Trọng lượng dao động 0.01-20",
    "heightShouldBeBetweenZalora": "Chiều cao dao động 1-35",
    "widthShouldBeBetweenZalora": "Chiều rộng dao động 1-40",
    "lengthShouldBeBetweenZalora": "Chiều dài dao động 1-60",
    "pleaseUploadASizePicture": "Vui lòng tải lên ảnh kích thước",
    "onlySupportsImages": "Chỉ hỗ trợ hình ảnh 1: 1",
    "pleaseEnterAtLeastOneVariation": "Vui lòng nhập ít nhất một tùy chọn thuộc tính",
    "variationOptionMustBeCharacters": "Tùy chọn thuộc tính phải từ 1-20 ký tự",
    "productDescriptionLimitIdMyZalora": "Mô tả sản phẩm bắt buộc từ 1 đến 10.000 kí tự",
    "fileSizeCannotExceedM": "Kích thước tệp không quá 10M",
    "onlySupportToUploadFilesIn": "Chỉ hỗ trợ tải lên các tệp ở định dạng pdf",
    "onlySupportToUploadImagesWith": "Chỉ hỗ trợ tải ảnh với các định dạng jpg, png, jpeg",
    "imageMustBePxpx": "Hình ảnh phải từ 600 * 600px-20000 * 20000px",
    "pictureSizeCannotExceedM": "Kích cỡ ảnh không quá 5M",
    "pleaseUploadAtLeastOneImage": "Vui lòng tải lên ít nhất một hình ảnh hoặc bản pdf",
    "supportCOD": "Hỗ trợ COD",
    "sizeChart": "Ảnh kích cỡ",
    "pleaseSelectTheVariantTypeAnd": "Vui lòng chọn loại và lựa chọn thuộc tính",
    "warrantyPolicy": "Chính sách bảo hành",
    "warrantyPeriod": "Thời hạn bảo hành",
    "qualificationCertificate": "Chứng nhận chất lượng",
    "productNameLimitIdZalora": "Tên sản phẩm bắt buộc từ 1 đến 188 kí tự",
    "zaloraCategory": "Danh mục Zalora",
    "numberOfComments": "Số bình luận",
    "keywordType": "Lọc",
    "sortType": "Thứ tự",
    "number": "Số seri",
    "newRuleMasterProduct": "Thêm mới quy tắc {0}; Sản phẩm Chính {1}",
    "deleteRuleMasterProduct": "Xóa quy tắc {0}; sản phẩm Chính {1}",
    "storePolicyAddPolicyNew": "Sách lược cửa hàng {0}; thêm sách lược: thêm mới sách lược",
    "storePolicyDeletePolicyPolicy": "Sách lược cửa hàng {0}; xóa sách lược: đã xóa sách lược",
    "ruleNameChangedFromTo": "Tên quy tắc: từ {0} thành {1}",
    "masterProductNameChangedFrom": "Tên cửa hàng Chính: từ {0} thành {1}",
    "pushTypeNameChangedFrom": "Tên loại đẩy: từ {0} thành {1}",
    "storePolicyWarehouseNameChanged": "Sách lược cửa hàng {0}; tên kho hàng: từ kho {1} thành kho {2}",
    "storePolicyInventoryTypeName": "Sách lược cửa hàng {0}; tên loại tồn kho: từ {1} thành {2}",
    "storePolicyStoreNameChanged": "Sách lược cửa hàng {0}; tên cửa hàng: từ {1} thành {2}",
    "storeStrategyPushRatioChanged": "Sách lược cửa hàng {0}; đẩy tỷ lệ: từ {1} thành {2}",
    "storePolicyPushFixedValue": "Sách lược cửa hàng {0}; đẩy giá trị cố định: từ {1} thành {2}",
    "addmskuthresholdIsselectmainstoreIs": "Thêm {0} MSKU, ngưỡng là {1}, chọn cửa hàng Chính là: {2}",
    "mskuhasbeendeleted": "Đã xóa MSKU",
    "masterproductchangedfromto": "Sản phẩm Chính đổi từ {0} thành {1}",
    "mainstorechangedfromto": "Cửa hàng Chính đổi từ {0} thành {1}",
    "thresholdchangedfromto": "Ngưỡng từ {0} thành {1}",
    "masterProductActivity": "Hoạt động sản phẩm chính",
    "operationType": "Loại thao tác",
    "salesTypeId": "Loại bán hàng",
    "salesRangeId": "Phạm vi bán hàng",
    "modifyChannelProductPrices": "Chỉnh sửa giá sản phẩm kênh",
    "areyousureyouwanttodeletetheruleafterdeletedyouneedtoreconfigurethesynchronizationrules": "Xác nhận xóa quy tắc? Sau khi xóa cần cấu hình lại quy tắc đồng bộ",
    "orderPushRecord": "Nhật ký đẩy đơn hàng",
    "productPushRecord": "Nhật ký đẩy sản phẩm",
    "youHaveNotActivatedStockMonitoring": "Bạn chưa bật \"Giám sát và Đồng bộ tồn kho\"",
    "turnOnNow": "Tắt",
    "turnOn": "Bật",
    "theSalesRangeOfCannot": "Phạm vi bán hàng của {} không thể cao hơn {}",
    "theValueOnTheRightSide": "Giá trị nhập bên phải của hộp nhập không thể ít hơn bên trái",
    "pushRecord": "Nhật ký đẩy",
    "allWarehouse": "Toàn bộ kho hàng",
    "allStoresExceptCustomStores": "Tất cả cửa hàng ngoại trừ cửa hàng tùy chỉnh: {x}%",
    "pushFailed": "Tolak Gagal",
    "pushed": "Sudah ditolak\tEmpurrado",
    "bindwarehouse": "Kho liên kết",
    "afterTurnOnTheProductsThat": "Sau khi bật, các sản phẩm đáp ứng quy tắc này sẽ được đẩy sang nền của cửa hàng",
    "afterDeactivationProductsThatMeetThis": "Sau khi tắt,các sản phẩm đáp ứng quy tắc này sẽ không còn được đẩy sang nền của cửa hàng",
    "afterTheSettingIsCompleteAverage": "Sau khi hoàn thành cài đặt, doanh thu trung bình hàng ngày sẽ được tính theo cài đặt mới.",
    "productDescriptionMustBeBetweenMinmaxcharacters": "Mô tả sản phẩm bắt buộc từ {min} đến {max} kí tự",
    "repush": "Đẩy lại",
    "pullAccurateStock": "Kéo tồn kho Accurate",
    "accounting1": "Kế toán",
    "returnManagement": "Quản lý trả hàng",
    "returnOrderXxxRejectedToRefund": "Đơn hàng xxx từ chối hoàn tiền cho người mua",
    "returnOrderXxxAgreedToRefund": "Đơn hàng xxx đồng ý hoàn tiền cho người mua",
    "returnOrderXxxRejectedRefundreturnRequest": "Đơn hàng trả xxx từ chối hoàn tiền  trả hàng",
    "returnOrderXxxAcceptedRefundreturnRequest": "Đơn hàng trả xxx chấp nhận yêu cầu hoàn tiền/ trả hàng",
    "returnOrderXxxSuccessfullySyncedThe": "Đơn hàng trả xxx đồng bộ thành công, phương thức trả hàng là \"xxx\" và trạng thái xử lý là \"xxx\"",
    "rejectRefund": "Từ chối hoàn tiền",
    "acceptRefund": "Đồng ý hoàn tiền",
    "acceptRequest": "Chấp nhận yêu cầu",
    "syncReturnOrder": "Đồng bộ hóa đơn đặt hàng trả lại",
    "confirmInbound": "Xác nhận nhập kho",
    "mass": "Hàng loạt",
    "returnWarehouse1": "Kho trả hàng",
    "returnProduct": "Sản phẩm trả hàng",
    "doYouAgreeToRefund": "Vui lòng xác nhận nếu bạn đồng ý hoàn tiền?",
    "pleaseSelectRejectionReason": "Vui lòng chọn nguyên nhân từ chối",
    "doYouAcceptBuyersReturnRefund": "Vui lòng xác nhận xem có chấp nhận yêu cầu trả hàng của người mua hay không?",
    "onceYouAgreeItCannotBe": "Một khi đồng ý là không thể hủy bỏ!",
    "doYouAcceptBuyersRefundRequest": "Vui lòng xác nhận có chấp nhận yêu cầu hoàn tiền của người mua hay không?",
    "exportAll": "Xuất tất cả trang",
    "viewReturnRecords": "Xem nhật ký trả hàng",
    "accept": "Chấp nhận",
    "isItADisputedOrder": "Có phải là đơn hàng tranh chấp hay không?",
    "inboundNo": "Mã nhập kho (không chỉ hiển thị trên trang hoàn trả)",
    "returnWarehouse": "Kho trả về không chỉ hiển thị trên trang hoàn trả)",
    "returnAwb": "Số AWB hoàn trả",
    "returnStatus": "Tình trạng hoàn trả",
    "processingDeadline": "Thời hạn xử lý",
    "processingStatus": "Trạng thái xử lý",
    "closed": "Đã đóng",
    "finished": "Đã hoàn thành",
    "inDispute": "Đang tranh chấp",
    "returnRemark": "Ghi chú trả hàng",
    "variantchannelSkumsku": "Thuộc tính/ SKU kênh/ MSKU",
    "returnNo": "Số đơn trả hàng",
    "disputedOrder": "Đơn hàng tranh chấp",
    "cod": "COD",
    "separateMultipleInfoWithSpace": "Tách nhiều thông tin bằng dấu cách",
    "returnRefund": "Trả hàng và hoàn tiền",
    "returnOrder": "Đơn hoàn trả",
    "xRuleHasBeenDeletedPlease": "Quy tắc {x} đã xóa, vui lòng kiểm tra lịch sử trong nhật ký thao tác",
    "checknow": "Kiểm tra ngay",
    "pushTime": "Thời gian đẩy",
    "theActivityLevelOfTheChannel": "Mức hoạt động của sản phẩm kênh đã được đặt và không thể đặt trùng lặp.",
    "deleteMasterProduct": "Xóa sản phẩm chính",
    "pleaseEnterRemarks": "Vui lòng nhập ghi chú",
    "refundType": "Phương thức hoàn tiền",
    "refundOnly": "Chỉ hoàn tiền",
    "refundStatus": "Trạng thái hoàn tiền",
    "returnInformation": "Thông tin trả hàng",
    "refundReason": "Nguyên nhân hoàn tiền",
    "returned": "Đã hoàn trả",
    "returning": "Đang hoàn trả",
    "waitingBuyerToReturn": "Chờ người mua hoàn trả",
    "returnRecord": "Nhật ký trả hàng",
    "refundList": "Danh sách hoàn tiền",
    "mskuBindingStatus": "Trạng thái liên kết MSKU",
    "pleaseConfirmTheReturnedProductBefore": "Vui lòng xác nhận sản phẩm trả hàng trước khi nhập lại vào kho. Nếu bị lỗi, vui lòng đồng bộ đơn hàng trước khi nhập lại vào kho.",
    "combinedProductSales": "Doanh số sản phẩm kết hợp",
    "includingCombineProductSalesAndIndividual": "Gồm doanh số bán hàng kết hợp sản phẩm và doanh số bán đơn lẻ của sản phẩm kết hợp.",
    "countTheSalesOfCombinedProducts": "Tổng doanh số của sản phẩm kết hợp đã bán đơn lẻ và theo combo",
    "pleaseSelectTheDataToBe": "Vui lòng chọn dữ liệu xuất",
    "salesOfIndividuallySoldItems": "Doanh số bán hàng của sản phẩm đơn",
    "salesOfProductsSoldByThe": "Doanh số bán hàng của sản phẩm kết hợp",
    "combinedProductMsku": "MSKU sản phẩm kết hợp",
    "combinationProductMsku": "Kết hợp MSKU sản phẩm",
    "combinedProductName": "Tên sản phẩm kết hợp",
    "mainCommoditySalesAnalysis": "Phân tích bán hàng sản phẩm Chính",
    "productDetailsOfThePortfolio": "Chi tiết sản phẩm kết hợp",
    "combinationProductDetails": "Kết hợp chi tiết sản phẩm",
    "combinedProduct": "Sản phẩm kết hợp",
    "exportChoice": "Xuất mục đã chọn",
    "combinationProducts": "Sản phẩm kết hợp",
    "refundRate": "Tỷ lệ hoàn",
    "refundAmount": "Số tiền hoàn",
    "refundQuantity": "Số lượng hoàn",
    "refundOrderQuantity": "Số đơn hoàn",
    "numberOfFailedOrders": "Số đơn thất bại",
    "numberOfReturnedOrder": "Số đơn hoàn",
    "numberOfCanceledOrder": "Số đơn Đã hủy",
    "numberOfShippedOrders": "Số đơn khách Đã nhận",
    "numberOfOrdersInDelivery": "Số đơn Đang vận chuyển",
    "numberOfReadyToShipOrder": "Số đơn Sẵn sàng giao",
    "numberOfPaidOrder": "Số đơn Đã thanh toán",
    "numberOfPendingPaymentOrder": "Số đơn Chờ thanh toán",
    "effectiveSalesAmount": "Tiền bán hàng hiệu quả",
    "totalSales": "Tổng doanh số",
    "totalProductSales": "Tổng doanh số sản phẩm",
    "relatedOrderQuantity": "Số đơn liên quan",
    "msku": "MSKU",
    "creationTime": "Giờ tạo",
    "keywords": "Từ khóa",
    "isACombinationProduct": "Gồm sản phẩm kết hợp?",
    "salesQTY": "Doanh số",
    "failedGenerateInboundList": "Tạo phiếu nhập kho thất bại",
    "someMasterProductDoNotExist": "Vài sản phẩm Chính không tồn tại trong kho hàng đã chọn",
    "masterProductIsEmptyPleaseRebind": "Sản phẩm Chính trống, vui lòng liên kết lại sản phẩm Chính và thử lại",
    "refundInformationNotCompletePleaseRefresh": "Hoàn tiền chưa hoàn tất, vui lòng làm mới trang",
    "informapping": "Trùng khớp",
    "mappingtip": "Chọn danh mục và loại thuộc tính để cập nhật đầy đủ thông tin (ví dụ: Tồn kho, giá cả...)",
    "Mappingcategory": "Đối chiếu danh mục",
    "tiktokCategoryAttributes": "Thuộc tính danh mục Tiktok",
    "enterBetween": "Nhập từ 1-60",
    "salesInformation": "Thông tin bán hàng",
    "tikTokCategory": "Danh mục TikTok",
    "tiktokfailedToPublishGineeProductThat": "Đăng thất bại: sản phẩm mà Ginee đăng thất bại",
    "tiktokpublishingGineeProductThatAreBeing": "Đang đăng: sản phẩm Ginee đang đăng lên hoặc sản phẩm đang xử lý trên Trung tâm người bán",
    "tiktokdraftAProductDraftCreatedBy": "Bản nháp: bản nháp sản phẩm tạo bởi Ginee",
    "tiktoksuspendedThisIsAProductWith": "Bị khóa: trong trung tâm người bán là trạng thái Bị khóa",
    "tiktoksoldOutThisIsAProduct": "Hết hàng: trong trung tâm người bán là sản phẩm có tồn kho = 0 với trạng thái Đang hoạt động/Không hoạt động",
    "tiktokdeactivatedThisIsAProductWith": "Không hoạt động: trong trung tâm người bán là trạng thái Không hoạt động",
    "tiktokliveThisIsAProductWith": "Hoạt động: trong trung tâm người bán là trạng thái Đang hoạt động",
    "tiktokskuMustBeCharacters": "SKU phải từ 1-50 ký tự",
    "tiktokwarrantyPolicyMustBeCharacters": "Chính sách bảo hành phải từ 0-99 ký tự",
    "tiktokpriceRangeShouldBe": "Khoảng giá dao động 100-100000000",
    "tiktokstocktLimitOhters": "Tồn kho dao động từ 0-99999",
    "tiktokweightShouldBeBetweenKg": "Trọng lượng dao động 0.01-100",
    "tiktokheightShouldBeBetween": "Chiều cao dao động 1-35",
    "tiktokwidthShouldBeBetween": "Chiều rộng dao động 1-40",
    "tiktoklengthShouldBeBetween": "Chiều dài dao động 1-60",
    "tiktokproductDescriptionLimitIdMy": "Mô tả sản phẩm bắt buộc từ 1 đến 10.000 kí tự",
    "productNameMinMaxCharacters": "Tên sản phẩm bắt buộc từ {min} đến {max} kí tự",
    "mpType": "Trạng thái liên kết MSKU",
    "updateDates": "Thời gian cập nhật",
    "returnOrderXHasSuccessfullyCreated": "Đơn trả hàng {x} đã tạo thành công phiếu nhập kho hàng trả, số phiếu nhập kho là {x}",
    "createInboundList": "Tạo Phiếu nhập kho",
    "noteThereIsADelayIn": "Lưu ý: Đang chậm trong việc cập nhật trạng thái đơn hàng kênh và vận đơn, có thể khiến  đẩy thất bại. Khi lần đẩy thất bại, vui lòng thử đẩy lại trong danh sách thất bại.",
    "theOrderIsBeingPushedTo": "Đơn hàng đang được đẩy về kho bên thứ ba. Vui lòng không thực hiện lại thao tác và đến danh sách đơn hàng để kiểm tra tiến độ đẩy",
    "pushTaskSuccessfullyCreated": "Tác vụ đẩy được tạo thành công",
    "refundLog": "Nhật ký hoàn tiền",
    "refundNo": "Mã hoàn tiền",
    "refundOrder": "Đơn hoàn tiền",
    "theServiceIsBusyOrYou": "Dịch vụ đang bận hoặc bạn có thể đã đạt đến giới hạn tỷ lệ yêu cầu, vui lòng thực hiện đẩy theo cách thủ công",
    "cancellationIsNotAllowedWhilePush": "Không được phép hủy trong khi đẩy",
    "synchronizeAllMskuInWarehouseTo": "Đồng bộ tất cả MSKU trong kho đến SKU trên kênh dựa trên tỉ lệ {x} và {y}",
    "youDoNotHavePermissionAt": "Bạn tạm không có quyền truy cập, vui lòng liên hệ quản trị viên để đặt quyền truy cập trong [Quản lý vai trò]",
    "tikiproductsCreatedInGineeExcludingProducts": "Sản phẩm nháp được tạo trong Ginee, không bao gồm các sản phẩm trong Trung tâm người bán - Trạng thái nháp",
    "tikiproductsFailedPublishByGinee": "Sản phẩm Ginee đăng thất bại",
    "tikiproductsPublishingByGinee": "Sản phẩm Ginee đang đăng",
    "tikiproductLockedInSellerCenterApi": "Tiki hiện chưa có sản phẩm bị Cấm",
    "tikiamongActiveAndInactiveProductsAll": "Trong các sản phẩm đang và không hoạt động,  sản phẩm mà tất cả thuộc tính có tổng tồn kho = 0;\nDo vấn đề của giao diện, sản phẩm FBT sẽ tạm thời được đưa vào danh sách Hết hàng",
    "tikinonactiveProductsInSellerCenter": "Sản phẩm trong Trung tâm người bán có trạng thái là OFF và bị khóa",
    "tikiactiveProductInSellerCenter": "Sản phẩm Đang hoạt động trong Trung tâm người bán",
    "skuProductCode": "SKU (Mã sản phẩm)",
    "lowstockthresholdValueCannotHigherThan": "Giá trị  lowStockThreshold không thể cao hơn số lượng tồn kho",
    "descriptionUpToCharacters": "Mô tả tối đa {max} ký tự",
    "skuPromotionPriceCannotHigherThan": "Giá SKU khuyến mã không thể cao hơn giá gốc, vui lòng chỉnh sửa",
    "skuPromotionStartTimeCannotBe": "Thời gian bắt đầu SKU khuyến mã không thể trống",
    "skuPromotionEndTimeCannotBe": "Thời gian kết thúc SKU khuyến mã không thể trống",
    "skuLowstockthresholdValueCannotHigherThan": "Giá trị SKU lowStockThreshold không thể cao hơn số lượng tồn kho",
    "variantSkuCannotBeEmpty": "SKU thuộc tính không thể trống",
    "productSkuCannotBeEmpty": "SKU sản phẩm không thể trống",
    "thisSkuAlreadyExistsInThis": "SKU này:${0} đã tồn tại trong cửa hàng, vui lòng chỉnh sửa lại",
    "productMainImageCannotBeEmpty": "Hình đại diện sản phẩm chính không thể trống",
    "promotionPriceCannotBeHigherThan": "Giá khuyến mãi không thể cao hơn giá gốc",
    "theInputRangeFromMin": "Giới hạn nhập từ { min } - { max}",
    "addVariants": "Thêm thuộc tính",
    "shippingClass": "Loại vận chuyển",
    "crosssells": "Bán chéo",
    "upsells": "Bán thêm",
    "enableThisToOnlyAllowOne": "Bật để cho phép một trong những mặt hàng này được mua trong đơn hàng lẻ",
    "soldIndividually": "Bán đơn lẻ",
    "lowStockThreshold": "Ngưỡng tồn kho thấp",
    "allowBackorders": "Cho phép hàng dự trữ",
    "enableStockManagementAtProductLevel": "Bật quản lý tồn kho ở mức sản phẩm",
    "manageStock": "Quản lý tồn kho",
    "taxClass": "Loại thuế",
    "taxStatus": "Tình trạng thuế",
    "usedForVariations": "Được sử dụng cho các thuộc tính",
    "visibleOnTheProductPage": "Xem được ở trang sản phẩm",
    "thisIsAFeaturedProduct": "Đây là một sản phẩm đặc trưng",
    "showSharingButtons": "Hiển thị nút chia sẻ",
    "showLikes": "Hiển thị lượt thích",
    "likeAndShares": "Lượt thích và chia sẻ",
    "hidden": "Ẩn",
    "searchResultsOnly": "Chỉ kết quả tìm kiếm",
    "shopOnly": "Chỉ gian hàng",
    "shopAndSearchResults": "Gian hàng và kết quả tìm kiếm",
    "catalogVisibility": "Hiển thị danh mục",
    "heightMinMax": "Chiều cao dao động {min}-{max}",
    "widthMinMax": "Chiều rộng dao động{min}-{max}",
    "lengthMinMax": "Chiều dài dao động{min}-{max}",
    "weightMinMax": "Trọng lượng dao động {min}-{max}",
    "pleaseEnterProductDescription": "Vui lòng nhập mô tả",
    "productNameMinMax": "Tên sản phẩm bắt buộc từ {min} đến {max}kí tự",
    "variantPicture": "Hình ảnh Thuộc tính",
    "productImageMax": "Hình ảnh sản phẩm tối đa {number}",
    "promotionTime": "Thời gian Khuyến mãi",
    "promotionPrice": "Giá Khuyến mãi",
    "options": "Tùy chọn",
    "variantType": "Loại thuộc tính",
    "productAttributes": "Thuộc tính sản phẩm",
    "productShortDescription": "Mô tả ngắn",
    "productTag": "Tag Sản phẩm",
    "updatedateasc": "Thời gian cập nhật mới nhất",
    "updatedatedesc": "Thời gian cập nhật cũ nhất",
    "createdateasc": "Thời gian tạo cũ nhất",
    "createdatedesc": "Thời gian tạo mới nhất",
    "withoutmp": "Chưa liên kết SKU chính",
    "withmp": "Liên kết SKU chính",
    "generateInventorySKU": "Tạo Master SKU",
    "onBackorder": "Đặt hàng trước",
    "productNameAndStore": "Tên sản phẩm & cửa hàng",
    "sync": "Đồng bộ",
    "inStock": "Còn hàng",
    "soldOut": "Hết hàng",
    "disabled": "Đã xuống kệ",
    "china": "Trung Quốc",
    "wooPublishStatus": "Tại cửa hàng, sản phẩm đang đợi xử lý sẽ hiển thị trong tab Đang đăng của Ginee",
    "wooActiveStatus": "Tại cửa hàng, sản phẩm đã đăng sẽ hiển thị trong tab Đã lên kệ của Ginee",
    "wooDeactiveStatus": "Tại cửa hàng, sản phẩm cá nhân sẽ hiển thị trong tab Đã xuống kệ của Ginee",
    "wooDraftStatus": "Tại cửa hàng, bản nháp của sản phẩm hiển thị trong tab Bản nháp của Ginee",
    "inProcess": "Đã đăng",
    "stockStatus": "Trạng thái hàng tồn kho",
    "externalProduct": "Sản phẩm liên kết",
    "groupProduct": "Nhóm sản phẩm",
    "variable": "Nhiều Thuộc tính",
    "simpleVariation": "Một Thuộc tính",
    "editStatusError": "Vui lòng nhấn nút Xác nhận trước, và nhấn Đăng lên cửa hàng",
    "productInfoSetting": "Cài đặt thông tin sản phẩm",
    "choiceMskuTip": "Sau khi chọn MSKU, hệ thống sẽ sử dụng thông tin giá và tồn kho của MSKU, và  ràng buộc thông tin SKU của blibli với MSKU.",
    "categoryMatch": "Phối hợp Danh mục",
    "variationLimit": "Danh mục đã chọn không hỗ trợ thêm Thuộc tính",
    "clickToEdit": "Nhấp vào nội dung của bảng, có thể thực hiện thao tác chỉnh sửa",
    "whatsInTheBox": "Nội dung bưu phẩm",
    "MinPurchaseQuantity": "Số lượng mua tối thiểu",
    "ProductDescriptionShouldBeCharacters": "Mô tả sản phẩm phải từ 6-25000 kí tự",
    "chooseStoreLimit": "Vui lòng chọn ít nhất 1 cửa hàng đích",
    "replaceWith38": "Thay thế với",
    "addBetweenTheDescription": "Thêm ở giữa mô tả",
    "addAtTheEndOfThe": "Thêm ở cuối mô tả",
    "addAtTheStartOfThe": "Thêm ở đầu mô tả",
    "addBetweenName": "Thêm ở giữa tên",
    "editProductName": "Chỉnh sửa tên sản phẩm",
    "theFileWillOnlyBeReserved": "Tệp chỉ lưu trữ trong 7 ngày, vui lòng kiểm tra hoặc tải về",
    "theResultOfThePublishWill": "Kết quả đăng sẽ đồng bộ hiển thị ở [Sản phẩm chính - Nhiều lịch sử hoạt động]",
    "successfulQtyAllQty": "Số lượng thành công / Tất cả số lượng",
    "publishing": "Đang đăng",
    "taskStatus": "Trạng thái nhiệm vụ",
    "publishedStoreStoreStore": "Đăng lên cửa hàng: cửa hàng 1, cửa hàng 2",
    "content": "Nội dung",
    "creator": "Người tạo",
    "taskType": "Loại nhiệm vụ",
    "allPublishFailed": "Tất cả đăng thất bại",
    "somePublishFailed": "Một phần đăng thất bại",
    "allPublished": "Tất cả đăng thành công",
    "productsWereSuccessfullyPublished": "29 sản phẩm đăng thành công",
    "publishingToChannelStores": "Đang đăng hàng loạt lên kênh cửa hàng",
    "massEditOfShopeesCategoryAttribute": "Chỉnh sửa nhiều thuộc tính danh mục của cửa hàng",
    "matchTokopediaVariantType": "Phối hợp loại Thuộc tính của Tokopedia",
    "matchLazadaVariantType": "Phối hợp loại Thuộc tính của Lazada",
    "masterProductVariantType": "Loại Thuộc tính sản phẩm chính",
    "massEditProductDescription": "Chỉnh sửa mô tả sản phẩm hàng loạt",
    "shopeeCategoryAttribute": "Thuộc tính danh mục của cửa hàng Shopee",
    "productNameInThisStoreIs": "Tên sản phẩm ở cửa hàng này đã tồn tại",
    "partOfTheMasterProductIs": "Một phần thông tin bắt buộc của sản phẩm chính không được để trống, vui lòng bổ sung đầy đủ và đăng lên cửa hàng",
    "chooseACountry": "Chọn quốc gia/Khu vực",
    "max11": "Lớn nhất 1000",
    "massEditWeightG": "Chỉnh sửa trọng lượng hàng loạt (g)",
    "max7": "Lớn nhất 500.000",
    "empty": "Trống",
    "editLogisticsMass": "Chỉnh sửa vận chuyển hàng loạt",
    "editBliblidraftmass": "Blibli / Chỉnh sửa hàng loạt",
    "categoryAttributesBlibli": "Thuộc tính danh mục Blibli",
    "copyMass": "Sao chép hàng loạt",
    "categoryChoose": "Chọn danh mục",
    "matchCategoryBlibli": "Phối hợp danh mục Blibli",
    "storeWarehouse": "Cửa hàng / Kho hàng",
    "buyable": "Có thể mua",
    "MinimumStock": "Phạm vi Hàng tồn kho tối thiểu phải là 0-999.999",
    "originalPriceLimit2": "Giá gốc phải cao hơn giá bán",
    "sellingPriceLimit2": "Giá bán phải nhỏ hơn giá gốc",
    "originalPriceLimit": "Phạm vi giá gốc nên là 10.000 - 1000.000.000",
    "enterYoutubeLinkForExampleYouTubecomipadview": "Nhấn vào Youtube Link, ví dụ: YouTube.com/ipadview",
    "youtubeVideoLink": "Youtube Video Link",
    "exampleTheBatteryLifeIsUp": "Ví dụ: thời lượng pin lên đến 10h",
    "sellingPoint": "Đặc trưng",
    "continueToAddProducts": "Tiếp tục thêm sản phẩm",
    "jdChannelIsUnderReviewCheck": "Kênh Blibli đang xét duyệt, vui lòng kiểm tra sau. Kết quả sẽ hiển thị trên tab \"Đang đăng/Đăng thất bại/Xuống kệ\"",
    "failedReasons": "Nguyên nhân thất bại",
    "productsUpdateSucceeded": "Cập nhật sản phẩm thành công",
    "changeSKUTo": "Sửa SKU thành",
    "massEditSellingPrice": "Chỉnh sửa Giá bán hàng loạt",
    "massEditOriginalPrice": "Chỉnh sửa Giá gốc hàng loạt",
    "massEditSku": "Sửa nhiều SKU",
    "editVariantImage": "Chỉnh sửa hình ảnh Thuộc tính",
    "multipleImagesCanBeUploadedAt": "Có thể tải lên cùng lúc nhiều nhiều ảnh. Hình ảnh nhỏ nhất 600 x 600 pixel, lớn nhất 4MB (Tối đa 8 ảnh)",
    "shouldBeBetween": "Phạm vi nhập từ 0-1000.000.000",
    "optionsnLimit": "Lựa chọn phải ít hơn 20",
    "bigItem": "Sản phẩm cỡ lớn",
    "smallItem": "Sản phẩm cỡ nhỏ",
    "qualitydays": "Thời gian bảo hành chất lượng",
    "afterSale": "Dịch vụ sau bán hàng",
    "Min1": "Tối thiểu là 1",
    "minpurchasePerOrder": "Số lượng mua tối thiểu",
    "pleaseEnterProductName": "Vui lòng nhập tên sản phẩm",
    "blibliCategory": "Danh mục BliBli",
    "partOfTheProductIs": "Theo yêu cầu đăng sản phẩm của kênh cửa hàng, sản phẩm có một số dòng bắt buộc điền. Vui lòng bổ sung đầy đủ các dòng bắt buộc trước khi đăng lên cửa hàng.",
    "blibliShouldBeBetweenA": "Phạm vi nhập là 0,001-999,999",
    "blibliWeightLimit": "Phải từ 1-999.999!",
    "blibliStocktLimit": "Phạm vi Hàng tồn kho nên từ 0-999.999!",
    "blibliImageSizeLimit": "Hình ảnh nhỏ nhất 600 x 600 pixel, lớn nhất 4MB",
    "serviceErrorPleaseContactGineeTechnology": "Lỗi dịch vụ, vui lòng liên hệ nhóm kĩ thuật Ginee",
    "minPurchaseLimit": "Số lượng mua tối thiểu phải từ 1-1000.000.000",
    "enterThenChange": "Nhập giá trị trước rồi thay đổi đơn vị sau",
    "goToTheBlibliSellerCenter": "Vui lòng đi đến Trung tâm người bán của blibli bổ sung địa chỉ kho hàng trước khi có thể chọn dịch vụ vận chuyển",
    "regularStoreSupport": "Hỗ trợ cửa hàng Regular",
    "toApiRestrictionsSomeVariantOption": "Do các hạn chế của API, không thể sao chép một số tùy chọn thuộc tính. Vui lòng thêm chúng nếu cần.",
    "theCategoryAndVariantTypeApply": "Loại danh mục và thuộc tính có áp dụng cho tất cả các sản phẩm thuộc cùng một danh mục Nguồn (Danh mục nguồn: xxx / xxxx / xxxx của cùng một trang web) không?",
    "toApiRestrictionsSomeFieldsDo": "Do các hạn chế của Blibli API, không thể chỉnh sửa một số hàng",
    "shippingMethod": "Phương thức vận chuyển",
    "backToProductList": "Quay lại danh sách sản phẩm",
    "chooseTheShippingMethodFirstPlease": "Vui lòng chọn Phương thức Vận chuyển",
    "needToChooseNo": "Không cần chọn",
    "fAq1": "Blibli: Làm thế nào để thêm hoặc chỉnh sửa sản phẩm?",
    "fAq2": "Blibli: Làm thế nào để sao chép sản phẩm?",
    "fAq3": "Blibli: Làm thế nào để chỉnh sửa sản phẩm hàng loạt?",
    "synchronizationForThisProductHasBeen": "Sản phẩm đã bật đồng bộ tồn kho, vui lòng sửa tồn kho trong quản lý tồn kho",
    "productDeleting": "Đang xóa sản phẩm",
    "operationWillNotDeleteTheProduct": "Thao tác này sẽ không xóa sản phẩm này trong Trung tâm nhà bán",
    "deleteTip": "Do hạn chế của giao diện, chỉ hỗ trợ xóa sản phẩm ở trạng thái bản nháp, đang đăng, đăng thất bại.",
    "productOrVariationImage": "Hình ảnh sản phẩm / thông số kỹ thuật",
    "imageTip2": "Sản phẩm không có biến thể, hình ảnh tham khảo hình ảnh của sản phẩm, và sản phẩm có biến thể tham khảo hình ảnh của biến thể",
    "blibliSkuLimit": "SKU phải ít hơn 50 kí tự",
    "exampleTheAvailableInventoryOfWarehouse": "Ví dụ: Tồn kho có sẵn của kho A là 100, tồn kho có sẵn của kho B là 200, và đẩy kho đặt thành A+B, và tỷ lệ đẩy là 50%, khi đó tồn kho đẩy sang kho hàng đích C là 150",
    "exampleThePushFixedValueIs": "Ví dụ: Giá trị cố định đẩy là 100, cửa hàng đích đẩy đến là A và khi thực hiện lệnh đẩy, tồn kho được đẩy đến cửa hàng A là 100",
    "ProductStatus": "Trạng thái sản phẩm",
    "productsThatFailToBePushed": "Sản phẩm đẩy thất bại không được đẩy lại, vui lòng liên hệ nhân viên ECCANG WMS để kiểm tra sản phẩm trước",
    "returnTime": "Thời gian phản hồi",
    "theProductHasNotPassedThe": "Sản phẩm này không được duyệt",
    "shippingFeePaidBySeller": "Người bán trả tiền vận chuyển",
    "skucanonlybenumberslettersandhyphenandthemaximumcharacters": "SKU chỉ có thể là số thứ tự, chữ cái, gạch ngang và độ dài tối đa 30 ký tự.",
    "skuNameMaxCharactersOnly": "Tên SKU, độ dài tối đa 50 ký tự, chỉ hỗ trợ tiếng Trung và tiếng Anh, số thứ tự , dấu gạch ngang và dấu gạch dưới",
    "productThatFailToPassThe": "Sản phẩm không được duyệt tại Easy Warehouse WMS sẽ không được giao",
    "skuCanOnlyBeInUppercase": "Chỉ hỗ trợ ký tự SKU viết hoa",
    "afterFailedToSendTheInbound": "Không hỗ trợ đẩy lại ho gửi đơn nhập kho thất bại",
    "productEffectiveSales": "Doanh số bán hàng hiệu quả",
    "pleaseNote": "Vui lòng lưu ý:",
    "dueToTheLimitationOfThe": "Do giới hạn của ECCANG WMS, SKU chỉ có thể là số thứ tự, chữ cái, gạch ngang và độ dài tối đa là 30 ký tự;",
    "someOrdersAreNotAssignedWith": "Một số đơn hàng chưa được phân phối đơn vị vận chuyển, vui lòng sắp xếp vận chuyển lại sau khi đồng bộ",
    "ordersWithoutSublogisticsAreNotSupported": "Không hỗ trợ giao hàng cho các đơn chưa phân phối đơn vị vận chuyển, vui lòng đồng bộ đơn hàng để nhận thông tin vận chuyển",
    "waitingForDistributionLogisticsPleaseSynchronize": "Chờ phân phối đơn vị vận chuyển, vui lòng đồng bộ",
    "productInformationSettingError": "Lỗi cài đặt thông tin sản phẩm",
    "userAddressInformationError": "Lỗi thông tin địa chỉ người dùng",
    "notInShippingAddress": "Địa chỉ không nằm trong phạm vi giao hàng",
    "deliveryFailure": "Giao hàng thất bại (giao hàng thất bại do người dùng không trả lời điện thoại hoặc địa chỉ không khớp)",
    "logisticsCannotSupportDistribution": "Đơn vị vận chuyển không thể giao hàng (chẳng hạn như lũ lụt trong mùa mưa)",
    "theMerchantSentTheWrongProduct": "Người bán gửi sai sản phẩm",
    "productDamage": "Sản phẩm bị hỏng (chưa xuất kho đã bị hỏng)",
    "productDescriptionDoesNotMatch": "Mô tả sản phẩm không khớp",
    "businessAndUserPhoneToCancelOrder": "Người bán thương lượng với người dùng để hủy đơn hàng",
    "goodsDamageDuringLogisticsDelivery": "Hàng hóa mất/thiệt hại trong quá trình giao hàng",
    "theUserAskedToCancelTheOrder": "Người dùng yêu cầu hủy đơn",
    "logisticsAndDistributionCannotContactTheRecipient": "Đơn vị vận chuyển không thể liên hệ người nhận",
    "incompleteRecipientAddress": "Không đủ thông tin địa chỉ người nhận",
    "thirdPartyMerchantsHaveNoInventory": "Người bán bên thứ ba không có tồn kho",
    "outOfDeliveryRange": "Vượt khỏi phạm vi giao hàng",
    "itIsOutOfStockAndCannotBeTraded": "Đã hết hàng và không thể giao dịch",
    "theBuyerSInformationIsFilledInWrong": "Điền sai thông tin người mua, vui lòng chụp hình mới",
    "latePayment": "Thanh toán trễ",
    "maliciousBuyers": "Người mua có dụng ý xấu/muốn phá hoại",
    "theBuyerHasNoSincerityToCompleteTheTransaction": "Người mua không có thiện chí hoàn thành giao dịch",
    "theBuyerMadeAMistakeOrRemake": "Người mua chụp nhầm hoặc chụp lại",
    "unableToContactBuyer": "Không thể liên hệ với người mua",
    "otherReasons": "Nguyên nhân khác",
    "unableToCompletePayment": "Không thể hoàn tất thanh toán / không thể nhận mã thanh toán",
    "thePaymentMethodNeedsToBeChanged": "Cần đổi phương thức thanh toán",
    "commodityPriceReduction": "Giảm giá sản phẩm",
    "wrongAddressInformation": "Sai thông tin địa chỉ",
    "wrongProductInformation": "Sai thông tin sản phẩm",
    "iDonTWantItAnymore": "Không cần nữa",
    "goodsOutOfStock": "Hết hàng",
    "synchronizeAllMskusUnderXTo": "Đồng bộ tổng của tất cả MSKU dưới {x} với {w} theo tỷ lệ {y} và {z}",
    "pushInProcess": "Đang đẩy",
    "waitingToPush": "Chờ đẩy",
    "rdWarehousePushStatus": "Trạng thái đẩy kho bên thứ ba",
    "pushStatus": "Trạng thái đẩy",
    "printable": "Có thể in",
    "productInboundStockCannotBeNegative": "Sản phẩm nhập kho không được âm",
    "andAccordingToZ": "Đồng bộ đến {w} dựa trên tỉ lệ của {z}",
    "synchronizeallmskuunder": "Thêm tất cả MSKU dưới {x} vào {y}",
    "pushSucess": "Đẩy thành công",
    "stockPushRuleaddRule": "Quy tắc đẩy tồn kho/ thêm quy tắc",
    "peraturanTolakStokubahSuai": "Quy tắc đẩy tồn kho/ chỉnh sửa",
    "peraturanTolakStoklihatPerincian": "Quy tắc đẩy tồn kho/ xem chi tiết",
    "negativeInventoryCannotBeManipulatedInto": "Hàng tồn kho bị âm và không thể đưa vào kho",
    "channelCategoryAttributes": "Thuộc tính danh mục {channelName}",
    "switchingMskuRequiresReselectingWarehouseInformation": "Để chuyển đổi MSKU, bạn cần chọn lại thông tin kho hàng",
    "pleaseAuthorizeTheStoreFirst": "Vui lòng tiến hành ủy quyền cửa hàng",
    "thereIsNoAlternativeStorePlease": "Không có cửa hàng để chọn, vui lòng tiến hành ủy quyền cửa hàng",
    "stockTikiTips": "ร้านค้า Tiki และสินค้า FBT ที่ไม่รองรับการแก้ไขข้อมูลสินค้าจะไม่สามารถเชื่อมต่อสต็อก",
    "stocksettingtip2Local": "Sau khi bật Đồng bộ Tồn kho, số lượng tồn kho của sản phẩm có cùng MSKU sẽ được điều chỉnh giống nhau. Khi MSKU tạo một đơn hàng mới hoặc đơn hàng bị hủy, tồn kho tất cả sản phẩm liên kết cùng một MSKU sẽ đồng loạt giảm hoặc tăng. Lưu ý, phải hoàn tất quá trình đồng bộ sản phẩm và đơn hàng trước khi bật cài đặt này, và số lượng tồn kho MSKU đã được điều chỉnh. \nSau khi bật đồng bộ tồn kho, tồn kho của SKU cửa hàng sẽ được đẩy theo tỷ lệ tồn kho và tồn kho của MaSKU trong quản lý tồn kho (thiết lập kho giao hàng). Nếu tồn kho chưa được cài đặt trong quản lý tồn kho, tồn kho SKU cửa hàng sẽ bị đẩy về 0 và không thể khôi phục. Vui lòng xác nhận xem nó đã được bật chưa.",
    "afterTheStockSynchronizationIsTurned": "Sau khi bật đồng bộ tồn kho, tồn kho của SKU cửa hàng sẽ được đẩy theo tỷ lệ tồn kho và tồn kho của Master SKU trong quản lý tồn kho (thiết lập kho vận chuyển của cửa hàng). Nếu tồn kho chưa được cài đặt trong quản lý tồn kho, tồn kho SKU của cửa hàng sẽ bị đẩy về 0 và không thể khôi phục. Vui lòng xác nhận xem nó đã được bật chưa.",
    "doesntSupportCustomShippingLabelTemplate": "Hiện tại không hỗ trợ Tùy chọn Mẫu Vận đơn cho Philippines, chỉ hỗ trợ thiết lập kích thước A4/A6",
    "commissionTips": "Nhập một số hợp lệ từ 0-100",
    "invoiceNumberTips": "Sản phẩm trong cùng một đơn hàng sẽ dùng chung số hóa đơn",
    "SettingTips2": "Vui lòng nhấn vào đây",
    "SettingTips": "Cài đặt này chỉ hợp lệ khi đơn hàng được xử lý trên Ginee(Lazada chỉ hỗ trợ cài đặt trong Seller Center). Để chỉnh sửa Địa chỉ mặc định/lấy hàng của cửa hàng,",
    "SettingLogo": "Nhấn vào đây để thiết lập Logo Thương hiệu",
    "SellerNotes": "Ghi chú Người bán",
    "PickingNotes": "Ghi chú lấy hàng",
    "TotalProduct": "Tổng số hàng",
    "PrintMan": "Được in bởi",
    "DeliverySettingPickupAddress": "Vui lòng tới Trung tâm Người bán để thiết lập địa chỉ",
    "DeliverySettingStoreName": "Tên cửa hàng",
    "PickFormReset": "Reset",
    "ChangePickStatusConfirm": "Xác nhận",
    "PickDeleteSuccess": "Xóa thành công!",
    "PickDeleteTipTwo": "Sau khi bị xóa thì không khôi phục lại được",
    "PickDeleteTip": "Bạn có chắc muốn xóa Thông tin Trạng thái",
    "PickOverCharacters": "Không được vượt quá 20 ký tự",
    "AddPickStatus": "Thêm quy tắc",
    "PickUpdateTime": "Giờ cập nhật",
    "PickCreateTime": "Giờ tạo",
    "PickStatusOperatorName": "Người tạo",
    "PickStatusName": "Tên trạng thái",
    "PrintUnitPrice": "Đơn vị giá",
    "PrintVariationName": "Thuộc tính",
    "PrintShipping": "Vận chuyển",
    "PrintSellerInfo": "Người bán",
    "PrintBuyerInfo": "Người mua",
    "BuyerNotes": "Ghi chú của Người mua",
    "ShippingFee": "Phí vận chuyển",
    "SenderInfo": "Người gửi",
    "GoToSellerCenter": "Vui lòng tới Trung tâm Người bán để thiết lập địa chỉ",
    "AutoShippingTips": "Ginee hỗ trợ cài đặt phương thức vận chuyển tự động, thiết lập ngay",
    "ShippingAddRule": "Thêm quy tắc",
    "OperationFailed": "Thất bại, Vui lòng thử lại",
    "OperationSuccess": "Thành công",
    "ApplyToAllStores": "Áp dụng cho Mọi Cửa hàng",
    "PickupAddress": "Địa chỉ lấy hàng",
    "ChooseAStore": "Chọn một Cửa hàng",
    "ShippingSettingTips": "Mẹo: Shopee chỉ cấp quyền truy cập phương thức nhận hàng SPX cho người bán được chọn",
    "ShopName": "Tên Cửa hàng",
    "ShippingMethod": "Phương thức vận chuyển",
    "Courier": "Nhà vận chuyển",
    "ShippingMethodDescription": "Dựa theo cài đặt của bạn, Ginee sẽ tự động lựa chọn phương pháp vận chuyển cho bạn sau khi bạn đã đồng bộ đơn hàng để cải thiện tính hiệu quả",
    "laStoreName": "Tên Cửa hàng",
    "proalertclosed": "Cảnh báo Tồn kho được tắt",
    "proalertopend": "Cảnh báo Tồn kho đã được bật. Khi lượng hàng có sẵn của Inventory SKU nhỏ hơn Lượng Tồn kho An toàn, chúng tôi sẽ gửi một email nhắc nhở",
    "PleaseSelectLeast": "Chọn ít nhất một",
    "DeleteTplTitle": "Bạn có chắc muốn xóa mẫu này?",
    "DeleteTplDesc": "Mẫu không thể hồi phục lại một khi đã bị xóa",
    "LaRuleBindingStoreSearchPlaceholder": "Vui lòng điền tên Cửa hàng",
    "LaRuleBindingStoreModalTitle": "Vui lòng chọn quy tắc Hóa đơn",
    "methodSettingDoubleCheckDescription": "Sau khi xóa bỏ quy tắc, quy tắc vận chuyển của cửa hàng tương ứng sẽ không còn hiệu lực",
    "LaRuleDoubleCheckDescription": "Sau khi xóa bỏ quy tắc, quy tắc tạo hóa đơn của cửa hàng tương ứng sẽ không còn hiệu lực",
    "LaRuleDoubleCheck": "Bạn có chắc muốn xóa bỏ quy tắc?",
    "ExportField": "Xuất Nội dung",
    "SelectChannel": "Lựa chọn kênh bán",
    "OrderExportingDragging": "Bạn có thể thay đổi thứ tự khi Xuất bằng cách kéo lên hoặc xuống",
    "BasicInfo": "Thông tin Cơ bản",
    "EditTemplate": "Chỉnh sửa tệp mẫu",
    "UpdateTime": "Giờ cập nhật",
    "CreateTime": "Giờ tạo",
    "TemplateName": "Tên mẫu",
    "Creator": "Người tạo",
    "LAInvoiceSettingSave": "Xác nhận",
    "ApplyToAllLazadaStores": "Áp dụng với mọi cửa hàng Lazada",
    "NextNumber": "Số tiếp theo",
    "EnterNumberManually": "Thêm số",
    "UseCustomNumber": "Sử dụng số mặc định",
    "UseOrderNumber": "Sử dụng số đơn hàng",
    "StoreManagement": "Quản lý cửa hàng",
    "RuleApplicationStoreNumber": "Số lượng cửa hàng Áp dụng",
    "RuleCustomNumber": "Số tùy chỉnh",
    "RulePrefix": "Mã\nký hiệu đầu",
    "RuleType": "Loại quy tắc",
    "RuleName": "Tên quy tắc",
    "AddRule": "Thêm quy tắc",
    "commissionPercent": "Phần trăm Hoa hồng (%)",
    "InvoiceRules": "quy tắc Hóa đơn",
    "ShippingSettingsTab": "Cài đặt Vận chuyển",
    "TemplateShippingAddress": "Địa chỉ giao hàng",
    "TemplateLogistics": "Logistics",
    "TemplateTotal": "Thành tiền",
    "TemplateSellerDiscountTotal": "Người bán giảm giá",
    "TemplateTax": "Thuế",
    "TemplateShipping": "Vận chuyển",
    "TemplateSubtotal": "Tổng cộng",
    "TemplateQty": "Số lượng",
    "TemplateCreatedAt": "Giờ tạo",
    "TemplatePayment": "Thanh toán",
    "TemplateContactNo": "Số điện thoại liên hệ",
    "TemplateUnitPrice": "Đơn vị",
    "TemplateBuyer": "Người mua",
    "TemplateAWBTrackingNumber": "AWB/Mã vận đơn",
    "Quantity": "Số lượng",
    "Specification": "Thuộc tính",
    "ProductName": "Tên sản phẩm",
    "TemplateProductItem": "Sản phẩm",
    "TemplateInsurance": "Bảo hiểm",
    "TemplateWeight": "Cân nặng",
    "TemplateShippingType": "Vận chuyển",
    "OrderId": "Mã Đơn hàng",
    "TemplateSender": "Người gửi",
    "TemplateRecipient": "Người nhận",
    "TemplateInformation": "Thông tin",
    "LogisticsLOGO": "Logo công ty vận chuyển",
    "ChannelLogo": "Logo kênh bán",
    "TemplateIcon": "Biểu tượng",
    "TemplatePrintDate": "Ngày in",
    "TemplateDate": "Ngày",
    "ViewBoxItemTitle": "Chế độ xem in",
    "TemplateSettingsTitle": "Cài đặt Mẫu in",
    "SetPickUpAddress": "Thiết lập địa chỉ lấy hàng",
    "SetDefaultAddress": "Thiết lập địa chỉ mặc định",
    "SenderAddress": "Địa chỉ",
    "SenderPhone": "Số điện thoại",
    "SenderName": "Tên",
    "DefaultAddress": "Địa chỉ mặc định",
    "BlSenderInfo": "i18n: BlSenderInfo",
    "TokoSenderInfo": "Mẹo: tên/ số điện thoại sẽ được sử dụng để hiển thị thông tin trong danh sách chuyển phát của Ginee",
    "ShopeeSenderInfo": "Mẹo: Tên/ số điện thoại của địa chỉ sẽ được dùng cho việc vận chuyển, và sau khi thiết lập Địa chỉ mặc định/lấy hàng tận nơi, thông tin địa chỉ sẽ được đồng bộ với Shopee",
    "PickingListPrintTemplateSettings": "Cài đặt Mẫu bảng kê lấy hàng",
    "ShippingLabelTemplateAlertTips": "Chỉ áp dụng với các file mẫu riêng của Ginee, không áp dụng với các file mẫu từ các công ty bên thứ 3 logistics tại các sàn thương mại điện tử",
    "PrintTemplateSettings": "Cài đặt mẫu in",
    "ShippingLabelAlertTips": "Nếu bạn muốn sử dụng File mẫu nhãn vận chuyển của Ginee hoặc chỉnh sửa Địa chỉ mặc định/ Địa chỉ lấy hàng,  vui lòng hoàn tất Cài đặt thông tin",
    "ShippingLabelInformationSettings": "Cài đặt thông tin Nhãn vận chuyển",
    "htar": "Làm thế nào để thêm vai trò?",
    "htas": "Làm thế nào để thêm nhân viên?",
    "htmr": "Làm thế nào để quản lý quyền?",
    "htms": "Làm thế nào để quản lý nhân viên?",
    "Mustbestartedwith8": "Phải bắt đầu bằng số 8",
    "atleastonerole": "Vui lòng chọn ít nhất một quyền",
    "noroles": "Không có dữ liệu, vui lòng thêm vai trò",
    "deleteroletip": "Nhân viên bị xóa sẽ không được lựa chọn vai trò",
    "suretodeleterole": "Bạn có chắc muốn xóa vai trò này?",
    "EditRole": "Chỉnh sửa vai trò",
    "Roleadded": "Đã thêm vai trò",
    "Permissionlimit": "Vui lòng chọn ít nhất một quyền",
    "Permission": "Quyền hạn",
    "roledesclimit": "Vui lòng nhập mô tả dưới 600 ký tự",
    "roleNamelimit": "Tên bắt buộc gồm 1-150 ký tự",
    "EnterRoleName": "Điền tên vai trò",
    "DeleteFailed": "Xóa bỏ thất bại",
    "staffDeleted": "Đã xóa nhân viên",
    "RoleDeleted": "Đã xóa vai trò",
    "AddRole": "Thêm vai trò",
    "Numberofstaff": "Số lượng nhân viên",
    "RoleDescription": "Mô tả vai trò",
    "RoleName": "Tên vai trò",
    "Submit": "Gửi",
    "Staffhasbeenadded": "Đã thêm nhân viên",
    "Nextstep": "Tiếp theo",
    "DiscardChange": "Hủy bỏ",
    "suretodiscard": "Bạn có muốn hủy bỏ thay đổi này?",
    "AddNewRole": "Thêm vai trò",
    "Existingpermissions": "Các quyền hiện có",
    "EnterthePassword": "Nhân viên sử dụng mật khẩu này để đăng nhập vào Ginee",
    "passwordlimit": "Mật khẩu phải bao gồm ít nhất 8 chữ cái, chữ số và ký hiệu",
    "Staffsusethemailbox": "nhân viên sử dụng mail để làm tài khoản đăng nhập Ginee",
    "Gineeaccountcannotbeempty": "Không được bỏ trống tài khoản Ginee",
    "Pleaseinputemail": "Vui lòng thêm địa chỉ email",
    "Contactphonenumber": "Điện thoại liên hệ",
    "namelimit": "Tên nhân viên từ 1-30 ký tự",
    "EnterStaffName": "Tên nhân viên",
    "locktip": "Tài khoản này đã bị vô hiệu hóa,  Vui lòng liên hệ Admin",
    "deletetip": "Nhân viên bị xóa sẽ không thể quản lý cửa hàng và bạn không thể thêm nhân viên này vào lại",
    "suretodeletestaff": "Bạn có chắc muốn xóa nhân viên này?",
    "Disablenow": "Vô hiệu hóa ngay",
    "disabletip": "Sau khi tài khoản bị vô hiệu hóa, nhân viên sẽ không thể đăng nhập vào Ginee để quản lý cửa hàng",
    "suretodisabled": "Bạn có chắc muốn vô hiệu hóa tài khoản nhân viên?",
    "Edit": "Chỉnh sửa",
    "Enabled": "Kích hoạt",
    "Disabled": "Vô hiệu hóa",
    "Action": "Thao tác",
    "LastLogin": "Đăng nhập lần cuối",
    "NameofStaff": "Tên nhân viên",
    "AddStaff": "Thêm nhân viên",
    "Search": "Tìm kiếm",
    "Roles": "Vai trò",
    "GineeAccount": "Tài khoản Ginee",
    "AccountStatus": "Trạng thái",
    "ActivationFailed": "Kích hoạt thất bại, vui lòng cài đặt trước",
    "SavedSuccessfully": "Thành công!",
    "donwloadlist": "Bấm vào đây tải xuống danh sách tồn kho đầy đủ",
    "InventoryWarnings": "Cảnh báo Tồn kho",
    "moresetting": "Thêm cài đặt",
    "suretoclosemainlock": "Bạn có chắc muốn tắt Khóa Tồn kho Cửa hàng Chính?",
    "suretoopenstocklock": "Bạn có chắc muốn kích hoạt tính năng Khóa Tồn kho Cửa hàng Chính? Sau khi bật, khi mà lượng tồn kho thấp hơn ngưỡng cài đặt, chỉ có cửa hàng chính có thể tiếp tục bán sản phẩm, số lượng tồn kho ở các cửa hàng còn lại giảm về 0",
    "whenempty2": ", Điều chỉnh số lượng tồn kho tại tất cả các cửa hàng về 0, trừ cửa hàng này",
    "whenempty1": "Khi tồn kho <=",
    "MainStoreStockLock": "Khóa Tồn kho tại cửa hàng chính",
    "suretoclosestockwarning": "Bạn có chắc muốn tắt Cảnh báo Tồn kho?",
    "emailreminderstocklow": "Gửi email nhắc nhở khi tồn kho sản phẩm sắp hết, bạn có chắc muốn bật tính năng này?",
    "Setup": "Cài đặt",
    "whenstock2": ", thông báo cho tôi khi số lượng tồn kho thiếu",
    "whenstock1": "Khi còn tồn <=",
    "suretoclosealertsold": "Bạn có chắc muốn tắt Cảnh báo hết hàng?",
    "notifyme": "Thông báo cho tôi khi sản phẩm được bán hết",
    "stocksettingtip3Local": "Sau khi tắt Đồng bộ Tồn kho, số tồn kho của sản phẩm trong mọi cửa hàng sẽ không được cập nhật hàng loạt",
    "stocksettingtip3": "Sau khi tắt Đồng bộ Tồn kho, số lượng tồn kho của sản phẩm trong các cửa hàng sẽ không được cập nhật hàng loạt, và danh sách tồn kho sẽ tự động xóa",
    "stocksettingtip2LocalOld": "Sau khi bật Đồng bộ Tồn kho, số lượng tồn kho của sản phẩm có cùng MSKU sẽ được điều chỉnh giống nhau. Khi có đơn hàng mới hoặc đơn hàng bị hủy, số lượng tồn kho của các sản phẩm có cùng Master SKU sẽ được đồng loạt thay đổi. Lưu ý, trước khi bật cài đặt này, qquá trình đồng bộ hóa sản phẩm và đơn hàng đã được hoàn tất, và số lượng Master SKU đã được điều chỉnh",
    "stocksettingtip2": "Sau khi bật Đồng bộ Tồn kho, số lượng tồn kho các sản phẩm trùng SKU ở các cửa hàng sẽ được điều chỉnh giống nhau. Khi có một đơn hàng mới hoặc một đơn hàng bị hủy, số lượng tồn kho của SKU ở các cửa hàng sẽ đồng loạt cập nhật. Lưu ý, trước khi bật tính năng này, quá trình đồng bộ hóa sản phẩm và đơn hàng đã được hoàn tất, và số lượng tồn kho SKU đã được điều chỉnh",
    "stocksettingtip1Local": "Để tăng tính chính xác số lượng tồn kho, hãy hoàn tất đồng bộ hóa toàn bộ sản phẩm và đơn hàng, sau đó đặt Master SKU trước khi kích hoạt Đồng bộ Tồn kho. Sau đó, Ginee sẽ tự động trừ hoặc cộng thêm Master SKU dựa theo Trạng thái Đơn hàng, và đồng bộ số lượng tồn kho của Master SKU với các cửa hàng đã được liên kết",
    "stocksettingtip1": "Để tăng tính chính xác số lượng tồn kho, hãy hoàn tất đồng bộ hóa toàn bộ sản phẩm và đơn hàng, sau đó đặt Số lượng tồn SKU trước khi kích hoạt Đồng bộ Tồn kho. Sau đó, Ginee sẽ tự động trừ hoặc cộng thêm Số lượng tồn SKU dựa theo Trạng thái Đơn hàng, và đồng bộ số lượng tồn kho với Store SKU đã được tích hợp",
    "monitorsync": "Giám sát và đồng bộ Tồn kho",
    "noteCreateBind": "Lưu ý: Nếu tính năng Tự động Liên kết Master SKU được bật, tính năng này sẽ được thao tác trước",
    "autoCreateSKUValue": "quy tắc tạo mới: Nếu như Store SKU trùng với Master SKU, sản phẩm sẽ tự động liên kết với Master SKU này (sẽ tạo mới Master SKU nếu không trùng). Chỉ áp dụng với sản phẩm của những cửa hàng đã được đồng bộ với Ginee nhưng chưa liên kết với Master SKU",
    "automaticallyCreateTheMasterSKU": "Tự động tạo Master SKU",
    "deleteAutoCreateRules": "Bạn có chắc muốn tắt tính năng Tự động Thêm mới Master SKU?",
    "turnAutoCreateRules": "Sau khi tính năng Tự động Thêm mới Master SKU được bật, những sản phẩm có Store SKU trùng với Master SKU, hệ thống sẽ tự động liên kết sản phẩm. Nếu sản phẩm có Store SKU chưa có trong Master SKU, hệ thống sẽ tự động tạo mới Master SKU",
    "goToDownloadTheRules": "Đi đến [Import/Export-Export all Auto-Bind rules] để tải xuống quy tắc",
    "downloadAllAutoBindRules": "Tải xuống toàn bộ quy tắc Tự động Liên kết",
    "deleteAutoBindRules": "Bạn có chắc muốn tắt cài đặt Tự động Liên kết Master SKU?",
    "turnOnAutoBindRules": "Sau khi Tự động Liên kết Master SKU được bật, với những sản phẩm chưa được liên kết với Master SKU, chúng tôi sẽ tự động liên kết sản phẩm với Master SKU dựa theo quy tắc: Store SKU trùng tên sản phẩm với Master SKU",
    "autoBindSKUValue": "Sau khi kích hoạt cài đặt này, nếu Store SKU trùng tên sản phẩm với Master SKU, sản phẩm sẽ được tự động liên kết, đồng thời vẫn sẽ hỗ trợ tùy chỉnh liên kết tự động. Tính năng này dành cho sản phẩm trong cửa hàng đã được đồng bộ với Ginee nhưng chưa liên kết với Master SKU",
    "automaticallyBindTheMasterSKU": "Tự động kết nối Master SKU",
    "SyncSuccess": "Đồng bộ thành công",
    "AreSureEnableCOD": "Bạn có chắc muốn kích hoạt COD?",
    "StoreName": "Tên Cửa hàng",
    "ExcludeShippingInfo3": "Xác nhận mở { JNEOKE }",
    "ExcludeShippingInfo2": "Sản phẩm được kích hoạt { JNEOKE } sẽ chuyển sang { JNEOKE }",
    "ExcludeShippingInfo1": "Nếu bạn mở { JNEOKE }, { JNEOKEList } sẽ tự động đóng ngay sau đó",
    "NoShippingInfo": "Hiện tại chưa có cửa hàng Shopee nào được tích hợp, hãy tích hợp ngay!",
    "CodNo": "Không",
    "CodYes": "Có",
    "DisabledCODClick": "Bấm vào đây để xem chi tiết",
    "DisabledCODDesc4": "COD của đơn vị vận chuyển J&T được đảm bảo bằng bảo hiểm",
    "DisabledCODDesc3": "Đơn hàng sử dụng phương thức COD",
    "DisabledCODDesc2": "Người mua sử dụng COD với mục đích xấu sẽ bị tự động cho vào danh sách đen",
    "DisabledCODDesc1": "Người bán chọn phương thức COD sẽ có nhiều người mua và đơn hàng hơn",
    "DisabledCODTitle": "Bạn có chắc muốn vô hiệu hóa COD?",
    "PleaseGoToProductEnable": "Chọn riêng từng sản phẩm để kích hoạt các cài đặt vận chuyển mới",
    "UnEnabledTitle": "Sản phẩm không được chọn sẽ bị loại khỏi danh sách sản phẩm hiện tại. Các sản phẩm không áp dụng vận chuyển sẽ bị loại khỏi danh sách và ẩn hiển thị.",
    "CODDisabled": "Vô hiệu hóa COD",
    "CODEnabled": "Kích hoạt COD",
    "COD": "COD",
    "ShippingSupportedDesc": "Với các cài đặt vận chuyển được hỗ trợ bởi Shopee, người mua sẽ được Shopee thông báo về tình trạng giao hàng của đơn hàng của mình",
    "ShopeSupportedLogistics": "Đơn vị vận chuyển được hỗ trợ bởi Shopee",
    "ShippingTopTips": "Chú ý: Bạn có thể quản lý cài đặt vận chuyển của các cửa hàng đã tích hợp tại đây. Chúng tôi khuyến nghị nên để cài đặt vận chuyển giống nhau cho những cửa hàng thuộc cùng một sàn thương mại. Vui lòng kích hoạt  ít nhất một đơn vị vận chuyển",
    "MembershipPage": "Trang Thành viên",
    "BasicLogo": "Logo",
    "Account": "Tài khoản",
    "afterClosingTheAutomaticBindingRules": "Các quy tắc liên kết tự động sau khi tắt sẽ bị xóa và sản phẩm trên các kênh thương mại điện tử sẽ không tự động liên kết với Master SKU",
    "ShippingRuleSettings1": "Cài đặt quy tắc Vận chuyển",
    "TheWarehouseAllocatedByThe": "2. Kho hàng của đơn hàng cần giao đã liên kết với kho Accurate",
    "MskuHasSuccessfullyPushedTo": "1. MSKU đã đẩy thành công đến Accurate",
    "orderRulesToPushToAccurate": "Quy tắc đẩy đơn hàng Accurate như sau:",
    "OnlySupports": "3. Chỉ hỗ trợ \"(\",\"), \"-\", \".\",\"_\" và \"/\"",
    "OnlySupportsLetternumberCombination": "2. Chỉ hỗ trợ chữ cái, số thứ tự",
    "NoMoreThanCharacters": "1. Không vượt quá 30 ký tự",
    "mskuRulesToPushProductTo": "Quy tắc MSKU để đẩy sản phẩm đến Accurate như sau:",
    "ifNoRulesAreAddedThe": "Khi không có quy tắc nào được thêm vào thì sẽ áp dụng theo quy tắc này. Một khi bạn tự thêm quy tắc, kho hàng sẽ được phân bổ theo quy tắc mà bạn đặt",
    "afterTheWarehouseSettingIsModified": "Sau khi chỉnh sửa cài đặt phân bổ kho, kho sẽ được phân bổ theo quy định mới, vui lòng vận hành cẩn thận",
    "afterConfigurationWhenAnOrderContains1": "Sau khi cấu hình, khi một đơn hàng có nhiều sản phẩm, các kho khác nhau có thể được ghép nối dựa theo các sản phẩm khác nhau",
    "particularCommodity": "Sản phẩm đơn",
    "afterConfigurationWhenAnOrderContains": "Sau khi cấu hình, khi một đơn hàng có nhiều sản phẩm thì chỉ có thể gán cho một kho, không thể ghép nhiều kho cùng lúc",
    "overallOrder": "Đơn hàng tổng thể",
    "compartmentDimension": "Kích thước phân kho",
    "afterConfigurationWhenMultipleWarehousedividingRules": "Sau khi cấu hình, khi nhiều quy tắc phân kho được đáp ứng, kho có số ưu tiên nhỏ hơn sẽ được ưu tiên chỉ định để tạo đơn xuất kho và xử lý tồn kho",
    "warehouseWithHighRulePriority": "Kho hàng có quy tắc số ưu tiên lớn",
    "afterConfigurationWhenMultipleWarehouseAllocation": "Sau khi cấu hình, khi nhiều quy tắc phân kho được đáp ứng, kho hàng có đủ tồn kho có sẵn sẽ được ưu tiên tạo đơn xuất kho và xử lý tồn kho",
    "fullyStockedWarehouse": "Kho đầy đủ hàng hóa",
    "priorityOfWarehouseDivision": "Mức ưu tiên phân kho",
    "promptThisSettingAffectsWarehouseAllocation": "Gợi ý: Cài đặt này ảnh hưởng đến các quy tắc phân bổ kho hàng, vui lòng thực hiện một cách thận trọng",
    "temporarilyNotOpen": "Tạm không bật",
    "theSwitchIsNotTurnedOn": "Nút không được bật, quy tắc phân kho sẽ không được thực hiện sau khi tạo thành công",
    "theSwitchOrderSettingsdistributionSettingsorderAssociated": "Nút \"Cài đặt đơn hàng - Cài đặt phân phối - tồn kho liên kết với đơn hàng\" chưa được bật",
    "separateWarehouseSetting": "Cài đặt phân kho",
    "afterAddingRulesByYourselfWhen": "Sau khi tự thêm các quy tắc và chúng được đáp ứng, thì kho hàng sẽ được phân bổ theo quy tắc bạn đặt ra trước",
    "matchAccordingToTheWarehouseIn": "Khớp theo kho hàng trong phiếu nhập kho mới nhất của MSKU theo mặc định",
    "downloadApp": "Tải App xuống",
    "manageYourStoreAnytimeAnywhereGinee": "Quản lý cửa hàng của bạn mọi lúc mọi nơi, Ginee App chính thức ra mắt rồi này",
    "shippingMethodPriorityPickUp": "Ưu tiên phương thức vận chuyển: Shipper đến lấy hàng > Người bán gửi hàng tại bưu cục > Tự giao hàng",
    "logisticsCompaniesThatDoNotSet": "Các đơn vị vận chuyển chưa cài đặt quy tắc giao hàng sẽ giao hàng dựa trên các phương thức giao hàng được hỗ trợ",
    "pleaseSelectALogisticsCompanyFor": "Vui lòng chọn đơn vị vận chuyển cho đơn hàng Akulaku",
    "pullThreepartyWarehouseForStorage": "Kéo tồn kho của kho bên thứ ba",
    "expired": "Đã hết hạn",
    "deactivated": "Hạn chế",
    "ecommercePlatform": "Thương trường",
    "selfbuiltStation": "Webstore",
    "financialSoftware": "Phần mềm tài chính",
    "originalName": "Gốc",
    "editStore": "Chỉnh sửa cửa hàng",
    "pullData": "Kéo dữ liệu",
    "reenable": "Bật lại",
    "deleteStore": "Xoá kho",
    "areyousureyouwanttodeletethisstore": "Comment Bạn có chắc muốn xoá cửa hàng này không?",
    "TheBindingRelationshipBetweenThe": "1. Mối quan hệ ràng buộc giữa các sản phẩm kênh của cửa hàng và sản phẩm chính sẽ hủy.\n2. Thông tin danh sách sản phẩm kênh của cửa hàng sẽ bị xóa trong Ginee.\n3. Tồn kho trong Danh sách tồn kho sẽ không được đẩy qua xửa hàng.\n4. Bạn sẽ không thể xem thông tin đặt hàng cho cửa hàng này.\n5. Bạn không thể tìm thấy cửa hàng do mô-đun cài đặt cửa hàng.",
    "theDefinitionOfDisabledIsStore": "Bị hạn chế: Cửa hàng bị hạn chế do tài nguyên cửa hàng vượt quá giới hạn. Trạng thái này sẽ không ảnh hưởng đến doanh số của cửa hàng trong kênh. Các sản phẩm kênh của cửa hàng sẽ tự động được hủy liên kết với Master SKU; sản phẩm/đơn hàng của cửa hàng và các dữ liệu khác sẽ không được hiển thị trong Ginee ERP",
    "authorizationInfo": "Thông tin:",
    "configManagement": "Quản lý cấu hình",
    "germany": "Đức",
    "belgium": "BỉStencils",
    "italy": "Ý",
    "poland": "Ba Lan",
    "authorized": "Đã ủy quyền",
    "authTips": "Sau khi cửa hàng của bạn kết nối thành công, bạn có thể nhìn thấy sản phẩm và đơn hàng của bạn đồng bộ thành công trên trang Sản phẩm và Đơn hàng",
    "youDoNotHavePermissionTo": "Bạn không có quyền truy cập cửa hàng, vì vậy bạn không thể chỉnh sửa giá của cửa hàng này.",
    "pleaseWaitPatientlyWhileTheStore": "Lưu trữ dữ liệu kéo, vui lòng kiên nhẫn chờ đợi",
    "startPullingTheStoreDataIt": "Bắt đầu kéo dữ liệu cửa hàng, dự kiến sẽ mất vài phút, xin vui lòng xem lại tình hình đồng bộ dữ liệu cửa hàng",
    "editSucceeded": "Chỉnh sửa thành công",
    "AreYouSureYouWantToDeleteTheAuthorization": "Comment Cô có chắc là sẽ xóa lệnh không?",
    "areYouSureToModifyThe": "Xác nhận sửa cài đặt phân kho chứ?",
    "CharactersLong": "Dài 10-12 ký tự",
    "pleaseStartWithTwoLettersOr": "Vui lòng bắt đầu bằng hai chữ cái hoặc số \"1\" và bao gồm 10 số",
    "pleaseEnterTheCorrectAkulakuTracking": "Vui lòng nhập đúng định dạng số vận đơn Akulaku",
    "transportStockChange": "Tồn kho đang vận chuyển",
    "tipavailablestock": "Lượng hàng có thể được bán trong Kênh ， Hàng có sẵn = Kho hàng-Kho dự phòng-Kho hàng đã khóa-Lượng hàng dành cho khuyến mãi",
    "tiplockedStock": "Hàng đang chờ hoàn thành nhưng chưa được vận chuyển, Đơn đặt hàng mới trả tiền hoặc đơn hàng chưa thanh toán mới được đồng bộ hóa với Ginee sẽ thêm vào Kho hàng đã khóa",
    "tipsafetyStock": "Đặt giới hạn tồn kho tối thiểu và nhận thông báo qua email để bổ sung khi Hàng có sẵn của sản phẩm đạt đến số lượng đã đặt",
    "tipspareStock": "Tổng lượng Hàng dự trữ trong kho thực tế, sẽ không được bán trong kênh.",
    "tipwarehouseStock": "Tổng kho thực tế trong kho của bạn, Kho dự trữ = Kho dự phòng + Kho đã khóa + Kho có sẵn + Kho khuyến mãi",
    "totalAvailableStock": "Tổng tồn kho có sẵn",
    "totalPromotionStock": "Tổng kho khuyến mãi",
    "totalLockedStock": "Tổng tồn kho bị khóa",
    "totalSpareStock": "Tổng kho dự phòng",
    "totalWarehouseStock": "Tổng tồn kho",
    "ImageName": "Hình ảnh & Tên",
    "case": "Trường hợp",
    "purchasePricecalrules": "Giá mua trung bình = Tổng giá mua trong nước / Hàng trong kho",
    "ISKUmeaning": "MSKU là viết tắt của Master SKU",
    "nochange": "Hàng có sẵn không thay đổi, vì vậy hàng trong cửa hàng không cần cập nhật",
    "manulupdate": "Không thay đổi",
    "updatetimetip": "Thời gian cập nhật",
    "reasonforfailed": "Lý do không thành công: Sản phẩm đã bị xóa hoặc bị cấm khỏi kênh bán hàng hoặc đang trong chiến dịch khuyến mại (quá trình đồng bộ hóa có thể thành công sau khi chương trình khuyến mại kết thúc)",
    "TotalPromotionStock": "Tổng kho khuyến mãi",
    "StoreSKU": "Store SKU",
    "tippromotionStock": "Lượng hàng Khuyến mãi là tổng lượng hàng dành riêng cho các chương trình khuyến mãi Chiến dịch hoặc Flash sale",
    "remark": "Nhận xét",
    "transportStock": "Tồn kho đang vận chuyển",
    "warehouseStockChange": "Kho:",
    "spareStockChange": "Bổ sung:",
    "promotionStockChange": "Khuyến mại ：",
    "lockedStockChange": "Đã khóa ：",
    "availableStockChange": "Có sẵn ：",
    "oversell": "Sản phẩm đang bán vượt mức tồn kho, vui lòng điều chỉnh tồn kho. Bây giờ cập nhật Kho hàng trong kênh về 0.",
    "Seethedetails": "Xem chi tiết",
    "warehouseStockerror": "Lượng hàng phải từ 0 đến 999.999",
    "warehouseStockrequired": "Lượng hàng phải từ 0 đến 999.999",
    "remarkplaceholder": "Vui lòng nhập lý do chỉnh sửa, không được dưới 300 ký tự.",
    "availablestockshort": "Có sẵn",
    "promotionstockshort": "Khuyến mại",
    "lockedStockshort": "Đã khóa",
    "spareStockshort": "Bổ sung",
    "warehouseStockshort": "Kho",
    "priceplaceholder": "Giá đầu vào",
    "Barcodeplaceholder": "Hỗ trợ chữ cái, số và -_",
    "weightplaceholder": "Phạm vi là 5.000.000",
    "upgradeResultClose": "Đóng",
    "upgradeResultViewNow": "Xem ngay bây giờ",
    "upgradeResultRetry": "Thử lại",
    "upgradeResultFailedTitle": "Không thành công nâng cấp quản lý khoảng không quảng cáo！",
    "upgradeResultSuccessTitle": "Nâng cấp thành công lên Inventory Management Pro！",
    "upgradeModalUpgradeNow": "Nâng cấp ngay bây giờ",
    "upgradeModalCancel": "Huỷ bỏ",
    "upgradeModalAbout": "Giới thiệu về Quản lý khoảng không quảng cáo Pro",
    "january5th": "sẽ được nâng cấp tự động vào ngày 5 tháng 1 năm 2021.",
    "syncmanually": "đồng bộ hóa các đơn đặt hàng",
    "upgradeModalFooterTitle": "Vui lòng {syncorder} theo cách thủ công trước khi nâng cấp, vì vậy các đơn đặt hàng hiện tại sẽ khấu trừ khoảng không quảng cáo. Tài khoản Ginee với Phiên bản Cơ bản sẽ được {cập nhật}",
    "upgradeModalLabelStockWarning": "Cảnh báo hàng tồn kho:",
    "upgradeModalLabelSoldOut": "Bán hết:",
    "upgradeModalLabelAll": "Tất cả:",
    "upgradeModalContentTitle": "Xem số lượng Master SKU hiện có của bạn:",
    "upgradeModalTitleSub": "Inventory Management Pro là phiên bản nâng cao với {val} các tính năng mới được thêm vào Quản lý khoảng không quảng cáo.",
    "upgradeModalTitle": "Nâng cấp lên Inventory Management Pro",
    "upgradeBtn": "Nâng cấp miễn phí lên Pro ， Xem ngay!",
    "setstock": "Đặt cổ phiếu",
    "setsafetyStock": "Đặt kho an toàn",
    "setspareStock": "Đặt kho dự phòng",
    "setwarehouseStock": "Đặt kho hàng",
    "skusselected": "SKU được chọn",
    "skuselected": "SKU được chọn",
    "skus": "Mã hàng",
    "Viemore": "Lượt xem",
    "VariationStatus": "Trạng thái thuộc tính",
    "createnext": "Tạo & Tiếp theo",
    "sizelimittip": "Phải từ 1-999.99!",
    "pricelimittip": "Giá mua phải từ 0-1.000.000.000!",
    "weightlimittip": "Trọng lượng phải từ 1-5.000.000!",
    "validatorsize": "Phải từ 1-999.99",
    "Barcodelengthtip": "Mã vạch không được nhiều hơn 30 ký tự",
    "Barcodeerror": "Mã vạch chỉ hỗ trợ chữ cái, số và -_",
    "mustbiggerthan": "Không được nhỏ hơn (Kho dự phòng + Kho đã khóa)",
    "mustlessthan": "Nên nhỏ hơn (Kho dự trữ-Kho đã Khoá)",
    "PackageSize": "kích cỡ gói",
    "inputsafetyStock": "Phải nằm trong khoảng từ 0 đến 999,999",
    "inputspareStock": "Phải nằm trong khoảng từ 0 đến 999,999",
    "inputwarehouseStock": "Phải nằm trong khoảng từ 0 đến 999,999",
    "safetyStockisRequired": "Tồn kho an toàn Nên từ 0 ~ 999,999!",
    "spareStockisRequired": "Kho dự phòng phải từ 0 đến 999,999!",
    "warehouseStockisRequired": "Kho dự trữ nên nằm trong khoảng từ 0 đến 999,999!",
    "namerequired": "Tên phải từ 1 - 160 ký tự",
    "Barcode": "Mã vạch",
    "addprice": "Giá mua trong nước (Rp)",
    "averageprice": "Giá mua trung bình (Rp)",
    "ItemInformation": "Thông tin mục",
    "stockimage": "Định dạng: jpg, jpeg, png. Kích thước hình ảnh: dưới 5MB",
    "noresult": "Không thay đổi",
    "EMPTY": "Sản phẩm trong cửa hàng không được ràng buộc",
    "FAILEDcontent": "Không thành công: Kênh không cho phép chỉnh sửa tồn kho này",
    "inventorylogtip": "Ginee sẽ tự động thử cập nhật lại tồn kho cho các cửa hàng không thành công. Bạn có thể xem chi tiết cập nhật để kiểm tra thử lại hoặc nếu bạn muốn thử lại theo cách thủ công.",
    "FAILED": "Thất bại",
    "PART_FAILED": "Một số không thành công",
    "UpdateCompleted": "Hoàn thành cập nhật",
    "StockWarning": "Cảnh báo tồn kho",
    "EXPIRED": "Thử lại không thành công",
    "RETRY_SUCCEEDED": "Thử lại thành công",
    "SUCCEEDED": "Thành công",
    "NEED_RETRY": "Không thành công, đang thử lại ...",
    "PROCESSING": "Đang xử lý",
    "UpdateDetails": "Chi tiết cập nhật kho lưu trữ",
    "ManualUpdate": "Cập nhật thủ công",
    "StoreUpdateResult": "Kết quả cập nhật cửa hàng",
    "Operator": "Nhà điều hành",
    "Latest": "Muộn nhất",
    "Movement": "Biến động",
    "Original": "Ban đầu",
    "updateDatetime": "Thời gian cập nhật",
    "StockUpdateFailed": "Cập nhật không thành công",
    "StockSuccessfullyUpdated": "Cập nhật thành công",
    "Updating": "Đang cập nhật",
    "UpdateToStore": "Cập nhật lên cửa hàng",
    "OrderCancel": "Đơn đặt hàng {OrderId} đã bị Hủy, Lý do: {ReasonInfo}",
    "NewPaidOrder": "Đơn hàng trả phí mới",
    "UserEditStock": "Người dùng chỉnh sửa tồn kho",
    "System": "Hệ thống",
    "ChangeReason": "Thay đổi lý do",
    "OriginalStock": "Tồn kho ban đầu",
    "createDatetime": "Giờ tạo",
    "StockTime": "Thời gian",
    "StockUserName": "tên tài khoản",
    "StockChangeHistory": "Lịch sử biến động tồn kho",
    "clicktoset": "Bấm vào đây",
    "synclosed": "Đồng bộ hóa tồn kho đã bị tắt, việc bạn sửa đổi số lượng tồn kho của Master SKU sẽ không ảnh hưởng đến số lượng tồn kho bán trên kênh; bạn có muốn dùng đồng bộ tồn kho?",
    "syncopend": "Tính năng đồng bộ hóa Kho hàng đã được bật và khi số lượng hàng tồn kho của Master SKU thay đổi, số lượng hàng hóa được bán bởi nền tảng cũng sẽ thay đổi theo",
    "skurelation": "Vui lòng nhập SKU kênh để thiết lập mối quan hệ ràng buộc giữa SKU và Master SKU. Khi tạo Master SKU, SKU trong kênh bán hàng có cùng tên sẽ được tự động liên kết",
    "stockrequired": "Tồn kho phải từ 0-999.999!",
    "inventorySkurequired": "Master SKU không được để trống!",
    "inputpurchasePrice": "Giá đầu vào",
    "inputname": "Tên đầu vào",
    "inputstock": "Chỉ số nguyên 0 - 999.999",
    "inputinventorySku": "Nhập Master SKU",
    "addsuccess": "Thêm thành công!",
    "deletesuccess": "Đã xóa thành công!",
    "editsuccess": "Đã chỉnh sửa thành công!",
    "afterdelete": "Sau khi xóa Master SKU, mối quan hệ ràng buộc với SKU trong kênh bán hàng cũng sẽ bị xóa",
    "suretodelete": "Bạn có chắc chắn muốn xóa Master SKU không?",
    "products": "Master SKU",
    "failimport": "Nhập không thành công:",
    "sucessimport": "Nhập thành công:",
    "totalimport": "Tổng đã nhập:",
    "picture": "Hình ảnh sản phẩm",
    "skuList": "SKU (Store SKU)",
    "purchasePrice": "Giá mua trong nước",
    "nameStock": "Tên",
    "Stock": "Tồn kho",
    "SoldOut": "Bán hết",
    "AddInventorySKU": "Thêm Master SKU",
    "seacrh": "Tìm kiếm",
    "ExportAll": "Xuất tất cả",
    "Exportbyselected": "Xuất theo lựa chọn",
    "DownloadImportTemplate": "Tải xuống Mẫu Nhập",
    "ImportInventorySKU": "Nhập Master SKU",
    "Import": "Nhập",
    "ok": "Đồng ý",
    "editsku": "Chỉnh sửa Master SKU",
    "addsku": "Thêm Master SKU",
    "skuseacrh": "Tìm kiếm Master SKU 、 SKU 、 Title",
    "skuplaceholder": "Nhập SKU (Store SKU của bạn)",
    "accordingToTheQueryConditionsYou": "Theo các điều kiện truy vấn (kiểm tra) bạn đặt, phí vận chuyển ước tính từ  công ty vận chuyển như sau:",
    "calculationResults": "Kết quả tính toán",
    "destination": "Nơi nhận",
    "shipFrom": "Nơi gửi",
    "calculationFormula": "Công thức tính toán",
    "checkShippingFee": "Truy vấn phí vận chuyển",
    "addAddress": "Thêm địa chỉ",
    "setAsDefault": "Đặt làm mặc định",
    "theBindingRelationshipBetweenJtExpress": "Mối quan hệ ràng buộc giữa J&T Express (ID) và Ginee đã được hủy và bạn sẽ không thể sử dụng các dịch vụ vận chuyển liên quan trong Ginee nữa, xin lưu ý.",
    "onceDeletedYouWillNoLonger": "Sau khi xóa, bạn sẽ không thể sử dụng dịch vụ vận chuyển J&T Express (ID) trong Ginee được nữa, và các đơn hàng đã đặt sẽ không được làm trống, vui lòng thao tác cẩn thận",
    "confirmToDelete": "Xác nhận xóa?",
    "shippingSettings1": "Cài đặt vận chuyển",
    "reasonTheAuthorizationInformationIsIncorrect": "Nguyên nhân: Thông tin ủy quyền không chính xác, vui lòng sửa đổi và ủy quyền lại",
    "youCanUseTheJtExpress": "Bạn có thể sử dụng dịch vụ J&T Express (ID) để hỏi về chi phí vận chuyển ước tính> đặt đơn> lấy vận đơn> in vận đơn> theo dõi vận chuyển",
    "maxCharactersOnlyNumbers": "Không vượt quá {length} ký tự, chỉ hỗ trợ số thứ tự và chữ cái",
    "requiredToFillInPleaseGet": "Bắt buộc điền, vui lòng lấy và điền thông tin theo phần trợ giúp ủy quyền",
    "withQuickAuthorizationYouDontNeed": "Với ủy quyền nhanh, không cần liên hệ với J&T Express (ID) để lấy thông tin cần thiết cho việc ủy quyền, hỗ trợ sử dụng Ginee",
    "clickToViewAuthorizationInstruction": "Nhấn để xem trợ giúp ủy quyền",
    "clickAuthorizeToBindAndIt": "Nhấp vào [Ủy quyền] để liên kết thành công, hỗ trợ đặt đơn/ nhận số vận đơn/ truy vấn phí vận chuyển/ truy vấn theo dõi vận chuyển",
    "toUseAStandardAuthorizationPlease": "Để sử dụng ủy quyền tiêu chuẩn, vui lòng liên hệ với J&T Express (ID) để biết thông tin cần thiết cho việc ủy quyền",
    "recomend": "Giới thiệu",
    "standardAuthorization": "Ủy quyền tiêu chuẩn",
    "quickAuthorization": "Ủy quyền nhanh",
    "theSystemDetectsThatChannelProducts": "Hệ thống phát hiện rằng SKU của sản phẩm kênh trống. Vui lòng xác nhận xem có sử dụng tên quy tắc và tên sản phẩm cho MSKU không?",
    "yesApplyToAllProducts": "Có, áp dụng cho tất cả sản phẩm",
    "productInbound": "Nhập kho sản phẩm",
    "manualOutbound": "Xuất kho thủ công",
    "salesOutbound": "Xuất kho bán hàng",
    "theNumberOfConnectedStoresThat": "Số lượng cửa hàng được kết nối đã vượt quá giới hạn gói có hiệu lực, vui lòng tiếp tục đăng ký gói hoặc liên hệ bộ phận chăm sóc khách hàng để nâng cấp gói trước khi kích hoạt cửa hàng;",
    "subscriptionPackage": "Gói đăng ký",
    "theStoreHasBeenActivated": "Cửa hàng đã được kích hoạt!",
    "deleteSuccess": "Xóa thành công",
    "fulfillConfirmDisconnectContent": "Sau khi bị xóa, kho {fulfillment} tương ứng và dịch vụ fulfillment sẽ không khả dụng, nếu bạn muốn kích hoạt lại, vui lòng ủy quyền lại trước",
    "unfold": "Mở rộng",
    "collapse": "Thu gọn",
    "expiredStatusComingSoon": "Sắp hết hạn",
    "ForLogisticCompanyPleaseAdd": "6. Đối với các công ty vận chuyển, vui lòng điền vào các công ty vận chuyển tùy chọn cho các đơn thủ công mới",
    "WarehouseAsWarehouseNameNot": "4. Kho hàng là tên kho, không phải mã số kho",
    "jnePleaseFillTheLogisticThat": "JNE (vui lòng điền vào công ty vận chuyển tùy chọn cho đơn thủ công mới)",
    "whetherBlacklist": "Có phải danh sách đen không",
    "pleaseSetupShipmentSettingsFirstBefore": "Vui lòng đặt cài đặt giao hàng trước rồi chọn địa chỉ lấy hàng",
    "authorizeNow": "Đến ủy quyền",
    "pleaseRecheckAfterAuthorizeLogistic": "Vui lòng sau khi ủy quyền đơn vị vận chuyển, hãy kiểm tra lại",
    "temporaryNotAuthorize": "Tạm chưa ủy quyền",
    "oddNumbers": "Mã đơn",
    "wholesalePrice": "Giá bán buôn",
    "addWholesalePrice": "Thêm giá bán buôn",
    "maxNumber": "Số tối thiểu không được lớn hơn hoặc bằng số tối đa",
    "maxNumberCannotBeLessThan": "Số tối đa không được nhỏ hơn hoặc bằng số tối thiểu",
    "theWholesalePriceCannotBeHigher": "Giá bán buôn không được cao hơn giá bán của sản phẩm",
    "theWholesalePriceOfCannot": "Giá bán buôn của {a} không được cao hơn giá bán buôn của {b}",
    "wholesalePricesAreOnlyAvailableTo": "Giá bán buôn chỉ có sẵn cho tất cả các thuộc tính với cùng một mức giá",
    "productNameMinCharacter": "Tên sản phẩm tối thiểu  {min}  ký tự",
    "productNotCanNotLessThan": "Hình ảnh sản phẩm tối thiểu là {min}",
    "breadCrumbProductList": "Sản phẩm/{channel}",
    "storePermission": "Quyền truy cập Cửa hàng",
    "channelPermission": "Quyền truy cập Kênh",
    "masterProductPermission": "Quyền truy cập Sản phẩm Chính",
    "priceManagementPermission": "Quyền truy cập Quản lý giá",
    "toEnsureTheAccuracyOfAvailable": "Để đảm bảo rằng kho hàng có sẵn là chính xác, vui lòng nhấp vào \"Cập nhật kho hàng có sẵn\" để nhận được giá trị hàng hóa mới nhất",
    "masterUpdateAvailableStock": "Cập nhật kho hàng có sẵn",
    "pleaseSelectCompleteReceiverArea": "Vui lòng chọn khu vực nhận hàng hoàn chỉnh",
    "toCalculateShippingCostNeedTo": "Để tính được phí vận chuyển thì cần điền thông tin vận chuyển và cân nặng",
    "calculateShippingFee": "Tính phí vận chuyển",
    "detailAddress": "Địa chỉ chi tiết",
    "needToSelectAreaDimension": "Cần chọn phạm vi khu vực",
    "maximumCharactersNumbersOnly": "Tối đa 30 ký tự, chỉ hỗ trợ số thứ tự",
    "maximumCharactersLettersOnly": "Tối đa 30 ký tự, chỉ hỗ trợ chữ cái",
    "site": "Quốc gia",
    "gineeErrorUrllabelEmptyPleaseTry": "Lỗi Ginee: URL vận đơn bị trống. Vui lòng đồng bộ lại đơn hàng",
    "singleProductWeight": "Trọng lượng sản phẩm đơn lẻ",
    "calculate": "Tính toán",
    "youDontHaveThisStorePermission": "Bạn không có quyền truy cập này",
    "logisticsServices": "Dịch vụ Logistics",
    "theMaximumLengthIsCharacters": "Độ dài tối đa là {maxLength} ký tự",
    "averagePurchasePriceCurrentInbound": "Giá mua trung bình = (số lượng nhập hàng hiện tại* giá mua nhập hàng hiện tại + số lượng ban đầu * giá mua ban đầu) / (số lượng nhập hàng hiện tại + số lượng ban đầu)",
    "tiktokMassDeleteTip": "Do giới hạn API Tiktok, số lượng có thể xóa tối đa chỉ 20 sản phẩm 1 lần, vui lòng chọn lại",
    "validityofauthorization": "Thời hạn hiệu lực ủy quyền",
    "channelProductMassEdit": "Sản phẩm/{channel}/Chỉnh sửa hàng loạt",
    "dataPermission": "Quyền dữ liệu",
    "ruleTypeSpecifyMsku": "Loại quy tắc: Chỉ định MSKU",
    "ruleTypeShopRules": "Loại quy tắc: Quy tắc cửa hàng",
    "addNewRules": "Thêm quy tắc mới",
    "delete1": "Xóa",
    "requiredItemLockSettingIf": "Bắt buộc điền, cài đặt khóa - khi cài đặt khóa tồn kho của cửa hàng chính được bật, khi tồn kho MSKU trong \"Sách lược đẩy\" nhỏ hơn hoặc bằng \"Ngưỡng\", lượng hàng tồn kho khả dụng đẩy cho các cửa hàng khác ngoại trừ cửa hàng đã chọn là 0",
    "setupPushStore": "Cài đặt cửa hàng đẩy",
    "requiredItemPleaseEnterAnInventory": "Bắt buộc điền, vui lòng nhập giá trị tồn kho lớn hơn hoặc bằng 0 và nhỏ hơn hoặc bằng 99999",
    "setStockThresholds": "Đặt Ngưỡng tồn kho",
    "pleaseEnterTheMskuToBe": "Vui lòng nhập MSKU để thêm vào, sẽ tốn ít thời gian để lưu nếu ngắt nhiều dòng, các bạn kiên nhẫn chờ nhé",
    "manualSelection": "Lựa chọn thủ công",
    "textRecognition": "Nhận dạng văn bản",
    "addingMethod": "Phương pháp thêm",
    "specifyMsku1": "Chỉ định MSKU",
    "allMskus": "Tất cả MSKU",
    "addedMainStoreLock": "Đã thêm khóa cửa hàng chính",
    "afterTurnOnPleaseSetUp": "Sau khi bật, hãy đảm bảo đặt quy tắc khóa cửa hàng chính, chỉ có thể đặt một quy tắc khóa hàng tồn kho của cửa hàng chính cho một MSKU duy nhất. Sau khi tắt cài đặt này, tất cả các quy tắc chính mặc định của cửa hàng sẽ thất bại",
    "turnOnMainStoreLocked": "Bật khóa hàng tồn kho của cửa hàng chính",
    "lockSettings": "Khóa cài đặt",
    "clickToView": "Bấm để xem",
    "addMsku1": "Thêm MSKU",
    "deleteMsku": "Xóa MSKU",
    "masterProductPicture": "Sản phẩm chính và hình ảnh",
    "pushMsku": "Đẩy MSKU",
    "ruleType": "Loại quy tắc",
    "ruleInformation": "Thông tin quy tắc",
    "massAdd": "Thêm hàng loạt",
    "add1": "Thêm",
    "addNewRule": "Thêm quy tắc mới",
    "mainStoreLocked": "Khóa cửa hàng chính",
    "operateBy": "Người thao tác",
    "operationModule": "Mô-đun hoạt động",
    "tipOnlySupportForQueryingOperation": "Mẹo: Chỉ hỗ trợ kiểm tra nhật ký hoạt động trong 30 ngày qua",
    "theRuleWasDeletedSuccessfully": "Quy tắc đã xóa thành công",
    "theRuleWasSavedSuccessfully": "Quy tắc đã lưu thành công",
    "ruleDisabledSuccessfully": "Đã tắt quy tắc thành công",
    "afterEnablingTheInventoryWillBe": "Sau khi bật, tồn kho sẽ được tính toán và đẩy đến cửa hàng kênh theo quy tắc hiện hành",
    "areYouSureYouWantTo1": "Bạn có chắc chắn muốn bật quy tắc này không?",
    "afterDeletionTheRulesWillNo": "Sau khi xóa, các quy tắc sẽ không còn hỗ trợ quản lý và sử dụng",
    "afterDisablingTheInventoryWillNo": "Sau khi bật, tồn kho sẽ không còn được tính và đẩy đến cửa hàng kênh theo quy tắc hiện hành",
    "doYouConfirmToTurnOff": "Xác nhận cấm quy tắc?",
    "ifTheCustomRuleIsDisabled": "Nếu quy tắc tùy chỉnh bị tắt, tồn kho MSKU sẽ không còn được đẩy đến kênh nữa",
    "afterCheckingIfACustomRule": "Sau khi kiểm tra, nếu quy tắc tùy chỉnh được thêm vào MSKU và được bật, tồn kho sẽ được tính theo quy tắc tùy chỉnh và được đẩy đến cửa hàng kênh",
    "afterAddingACustomRuleTo": "Sau khi thêm quy tắc tùy chỉnh vào MSKU, tồn kho không còn được đẩy theo quy tắc mặc định",
    "applicationSettings": "Cài đặt ứng dụng",
    "newMskuAddedToWarehouseLater": "MSKU mới: Được thêm vào kho muộn hơn thời gian kích hoạt",
    "allMskusExcludingMskuThatAdded": "Tất cả MSKU (không bao gồm cả các MSKU được thêm sau)",
    "allMskusIncludingMskuThatAdded": "Tất cả MSKU (bao gồm cả các MSKU được thêm sau)",
    "newStoresStoresWhichAuthorizationTime": "Cửa hàng mới: cửa hàng có thời gian ủy quyền muộn hơn thời gian kích hoạt",
    "allAuthorizedStoresConnectedStoresWhose": "Tất cả các cửa hàng được ủy quyền: các cửa hàng được liên kết mà thời gian ủy quyền sớm hơn thời gian kích hoạt",
    "allConnectedStoresExcludingNewStores": "Tất cả các cửa hàng đã liên kết, không bao gồm cả các cửa hàng mới được ủy quyền sau này",
    "allConnectedStoresIncludingNewStores": "Tất cả các cửa hàng đã liên kết, bao gồm cả các cửa hàng mới được ủy quyền sau này",
    "pushRatio": "Tỷ lệ đẩy",
    "availableStockTransitStock": "Tồn kho có sẵn + Tồn kho vận chuyển",
    "allWarehousesWithMskusInInventory": "Tất cả các kho đã nhập kho MSKU và có trạng thái được kích hoạt (không bao gồm các kho mới được thêm vào sau)",
    "newWarehouseWarehouseWhoseCreationTime": "Kho hàng mới: kho hàng có thời gian tạo muộn hơn thời gian kích hoạt",
    "allWarehousesWithMskuThatHave": "Tất cả các kho đã nhập kho MSKU và có trạng thái được kích hoạt (bao gồm các kho mới được thêm vào sau)",
    "ruleStatus": "Trạng thái quy tắc",
    "noteThisTimeIsTheTime": "Lưu ý: Thời gian này là lúc \"Cài đặt tồn kho\" - \"Giám sát và đồng bộ tồn kho\" được bật",
    "activateTime": "Thời gian bật",
    "viewFailureData": "Xem dữ liệu thất bại",
    "backToRuleList": "Quay lại danh sách quy tắc",
    "fail": "Thất bại",
    "sucess": "Thành công",
    "dataProcessingCompleted": "Xử lý dữ liệu hoàn tất",
    "saveCurrentlyNotActive": "Lưu, hiện chưa được bật",
    "afterActivatePleaseAddMskuAs": "Sau khi được bật, hãy thêm MSKU càng sớm càng tốt, nếu không quy tắc sẽ không hoạt động bình thường",
    "areYouSureWantToSave": "Xác nhận lưu và bật quy tắc chứ?",
    "stockType1": "Loại tồn kho",
    "warehouseName1": "Tên kho hàng",
    "specifyMskuAfterCreatingTheRule": "Chỉ định MSKU (sau khi tạo quy tắc, bạn cần phải tự thêm MSKU, chỉ MSKU đã thêm mới được đẩy theo quy tắc đã đặt)",
    "storeRulesAfterCreatingTheRules": "Quy tắc cửa hàng (sau khi tạo quy tắc, bạn không cần thêm MSKU, các SKU kênh đã liên kết với MSKU trong cửa hàng sẽ được đẩy theo quy tắc đã đặt)",
    "tipsWhenAddingRulesIn": "Mẹo: Khi thêm quy tắc hàng loạt, tên quy tắc sẽ được thêm tự động bởi hệ thống; 2. Khi thêm quy tắc hàng loạt, chỉ thông tin quy tắc được lưu lại, và sau khi lưu, vui lòng đặt các MSKU áp dụng trong danh sách quy tắc; 3. Có thể thêm tối đa 100 mỗi lần",
    "clickToViewMsku": "Bấm để xem MSKU",
    "showAllStore": "Hiển thị tất cả cửa hàng",
    "showAllWarehouses": "Hiển thị tất cả kho hàng",
    "executeTheRules": "Thực hiện các quy tắc",
    "fixedValue": "Giá trị cố định",
    "storeRule": "Quy tắc cửa hàng",
    "specifyMsku": "Chỉ định MSKU",
    "operation": "Thao tác",
    "createBy": "Tạo bởi",
    "ratioFixValue": "Tỷ lệ / Giá trị cố định",
    "ruleName": "Tên quy tắc",
    "exactMatch": "Khớp hoàn toàn",
    "multipleMskusSeparatedBySpacesMax": "Nhiều MSKU được phân tách bằng dấu cách, tối đa 50",
    "pleaseFillInTheCompleteRecipient": "Vui lòng điền địa chỉ người nhận đầy đủ",
    "pleaseSelectAPickupAddress": "Vui lòng chọn địa chỉ lấy hàng",
    "addMainStoreLock": "Thêm khóa cửa hàng chính",
    "editMainStoreLock": "Chỉnh sửa khóa cửa hàng chính",
    "ratio": "Tỉ lệ",
    "thisAddressDoesNotCurrentlySupport": "Địa chỉ này hiện không hỗ trợ tính phí vận chuyển",
    "tExpressIdIsNotAuthorized": "J&T Express ID không được ủy quyền, việc hủy bỏ sẽ không thông báo cho công ty vận chuyển",
    "channelSku": "SKU kênh",
    "inventoryPushRulesMainStore": "Quy tắc đẩy tồn kho/ khóa tồn kho cửa hàng chính",
    "inventoryPushRuleOperationLog": "Quy tắc đẩy tồn kho/ nhật ký thao tác",
    "keywordsOfOperationContent": "Từ khóa của nội dung thao tác",
    "pleaseEnterMskuPleaseWrapMultiple": "Vui lòng nhập MSKU, vui lòng xuống hàng nếu có nhiều MSKU",
    "skuExactSearch": "Tìm kiếm chính xác theo MSKU",
    "viewMskuApplicationRules": "Xem quy tắc đẩy",
    "showAllStores": "Hiển thị tất cả cửa hàng",
    "bindingCommodityInformation": "Liên kết thông tin hàng hóa",
    "analogComputation": "Tính toán mô phỏng",
    "unmatchedChannelGoods": "Sản phẩm kênh không có ghép nối",
    "normal": "Bình thường",
    "viewRules": "Xem quy tắc",
    "promptThisFunctionSupportsViewingThe": "Gợi ý: Tính năng này cho phép xem các quy tắc hiện tại MSKU thực sự sử dụng khi đẩy hàng tồn kho và xem tối đa 50 MSKU mỗi lần",
    "newStoresWithoutSubsequentAuthorization": "(Không bao gồm cửa hàng mới ủy quyền sau này)",
    "newStoreWithSubsequentAuthorization": "(Bao gồm cửa hàng mới ủy quyền sau này)",
    "allConnectedStores": "Tất cả các cửa hàng được kết nối",
    "pleaseTurnOnTheInventorySynchronization": "Vui lòng bật nút đồng bộ tồn kho trong menu cài đặt hàng tồn kho trước khi thao tác",
    "Ruledetails": "Chi tiết quy tắc",
    "Default": "Mặc định",
    "thereAreDuplicates": "Có một sự trùng lặp",
    "PleaseentertheMSKU": "Vui lòng nhập MSKU cần thêm, nhiều ngắt dòng, không quá 200 ký tự trên một dòng, hệ thống sẽ tự động cắt bớt nếu vượt quá",
    "noNeedToPush": "Không cần thúc đẩy",
    "mskuDoesNotExist": "MSKU không tồn tại",
    "mskuInventorypushInventory": "Tồn kho MSKU/ Tồn kho Đẩy",
    "proportional": "Theo tỷ lệ",
    "IfTheMskuForThe": "Ví dụ như việc gửi một phần kênh dành cho bộ phận, hãy tạo ra quy tắc chỉ định MSKU bị tắt;",
    "IfSomeMskusNeedTo": "Bạn có thể tạo ra các quy tắc chỉ định MSKU như thể bạn muốn gửi theo một số cách chỉ rõ một cửa hàng hoặc chỉ định tỷ lệ cố định/ chỉ ra một giá trị",
    "IfTheChannelStoreNeeds": "Bạn có thể sử dụng các kho báu có sẵn trong các cửa hàng được cho phép để thực hiện, tạo ra các quy tắc của cửa hàng, thiết lập quan hệ với các",
    "IfTheInventoryOfAll": "Kho hàng hàng như tất cả các cửa hàng của cửa hàng đều được thực hiện theo phần trăm bảo hàng có sẵn trong tất cả các kho hàng được phép, hãy sử dụng cá",
    "directionsForUse": "Dùng trợ giúp:",
    "youCanClickViewMskuApplication": "Bằng cách nhấp vào nút \"Xem quy tắc ứng dụng MSKU\", bạn có thể truy vấn các quy tắc mà MSKU thực sự sử dụng để đẩy",
    "thirdPriority": "Ưu tiên thứ ba",
    "secondPriority": "Ưu tiên thứ hai",
    "firstPrioritySpecifyMsku": "Ưu tiên đầu tiên: Chỉ định MSKU",
    "inventoryPushInstructions": "Hướng dẫn sử dụng đẩy hàng tồn kho",
    "mskuCreationTimeIsLaterThan": "MSKU được tạo muộn hơn thời gian bật công tắc đẩy hàng tồn kho",
    "theStoreBindingTimeIsLater": "Thời gian ràng buộc cửa hàng muộn hơn thời gian bật công tắc đẩy hàng tồn kho",
    "theWarehouseCreationTimeIsLater": "Kho được tạo muộn hơn thời gian bật công tắc đẩy hàng tồn kho",
    "reasonTheDefaultRuleConditionsAre": "Lý do: Không đáp ứng các điều kiện quy tắc mặc định, hãy tự tạo quy tắc sau khi đẩy",
    "reasonTheChannelStoreStatusIs": "Lý do: Tình trạng cửa hàng kênh không được ủy quyền, xin vui lòng liên kết lại các cửa hàng sau khi đẩy",
    "reasonThePushRuleForChannel": "Lý do: Quy tắc đẩy được sử dụng cho hàng hóa kênh đã bị vô hiệu hóa, vui lòng bật quy tắc và đẩy",
    "reasonInventorySettinginventoryPushSwitchIs": "Lý do: Cài đặt hàng tồn kho - Công tắc đẩy hàng tồn kho không được bật, vui lòng bật công tắc và đẩy sau đó",
    "reasonTheStatusOfChannelGoods": "Lý do: Trạng thái hàng hóa kênh là trạng thái nháp, vui lòng lên kệ hàng và đẩy",
    "xpriceProtection": "Giữ nguyên giá {X}%",
    "order_628344ee0de79247bef8cb6694d1cd38": "Sai giá/ khuyến mãi",
    "fuzzySearch": "Tìm kiếm xấp xỉ",
    "preciseSearch": "Tìm kiếm chính xác",
    "historicalOrders": "Đơn đặt hàng lịch sử",
    "nearlyDaysOrder": "Đơn 90 ngày qua",
    "tipChannelInterfaceRestrictionOnlySupports": "Mẹo: Giới hạn giao diện kênh chỉ hỗ trợ cập nhật hàng tồn kho hàng hóa đã lên kệ hoặc đã lên kệ",
    "theChannelStatusIsAbnormalPlease": "Tình trạng hàng hóa kênh là bất thường, xin vui lòng xác nhận sau khi đẩy",
    "theWarehouseHasBeenDeactivatedPlease": "Kho đã bị hủy kích hoạt, vui lòng bật đẩy sau",
    "whenSearchingAccuratelyTheMaximumNumber": "Khi tìm kiếm chính xác, truy vấn tối đa một lần {X}, nhiều thông tin xin vui lòng sử dụng trở lại để chuyển dòng, mỗi dòng đại diện cho một thông tin",
    "pleaseEnterTheOrderNumber": "Vui lòng nhập số đơn đặt hàng",
    "settingSuccess": "Cài đặt thành công",
    "insured": "Bảo hiểm",
    "insuranceRation": "Tỉ lệ bảo hiểm",
    "pleaseSelectInsuranceService": "Vui lòng chọn dịch vụ bảo hiểm",
    "pleaseSettingInsuranceFeeForAkulaku": "Vui lòng cài đặt phí bảo hiểm cho đơn hàng Akulaku",
    "settingInsurance": "Cài đặt bảo hiểm",
    "proportionalInsurance": "Bảo hiểm theo tỉ lệ",
    "originalInsurancePrice": "Giá bảo hiểm ban đầu",
    "notInsured": "Không bảo hiểm",
    "plannedInboundAmount": "Số tiền nhập kho dự tính",
    "actualInboundAmount": "Số tiền nhập kho thực tế",
    "shippingSubsidy": "Giảm giá vận chuyển",
    "platformSubsidy": "Trợ giá nền tảng",
    "spuAlreadyExistsPleaseRename": "SPU đã tồn tại, vui lòng đổi tên",
    "templateType": "Loại mẫu",
    "labelSize": "Kích thước vận đơn",
    "barcodePrintingTemplateSettings": "Cài đặt mẫu in mã vạch",
    "fontSize": "Kích thước chữ",
    "howToGenerate": "Phương thức tạo",
    "barcodeHeightInsideLabel": "Độ cao mã vạch trên nhãn",
    "qrCode": "Mã QR",
    "qrCodeLocation": "Vị trí mã QR",
    "left": "Trái",
    "top": "Đầu",
    "qrCodeSize": "Kích thước mã QR",
    "generatedByMsku": "Tạo theo MSKU",
    "generatedByBarcode": "Tạo theo mã vạch",
    "generatedBySpu": "Tạo theo SPU",
    "mskuCode": "Mã MSKU",
    "printContent": "In nội dung",
    "customAdd": "Thêm tùy chỉnh",
    "goToTheMasterProductPage": "Đến trang sản phẩm Chính chọn sản phẩm cần in",
    "customContent": "Tùy chỉnh nội dung",
    "pleaseInsertTheContentToBe": "Vui lòng nhập nội dung cần in, nhiều thì ngắt dòng và không quá 200 ký tự trên một dòng, hệ thống sẽ tự động cắt bớt nếu vượt quá.",
    "numberOfPrintsreferringToTheNumber": "Số lượng bản in (tức là số lượng nhãn cho mã vạch)",
    "paperPrintColumn": "Cột giấy in",
    "columnGap": "Khoảng cách cột",
    "barcodePrinting": "In mã vạch",
    "atLeastCharactersOrMore": "Ít nhất 3 ký tự trở lên",
    "theFuzzySearchContentMustExceed": "Tìm kiếm mờ phải dài hơn 3 ký tự, vui lòng điều chỉnh và thử lại",
    "ordersCreatedForMoreThan": "Đơn đặt hàng được tạo trong hơn 90 ngày sẽ được chuyển vào đơn đặt hàng lịch sử và không thể xử lý được",
    "areYouSureYouWantTo2": "Bạn có chắc chắn muốn chuyển đơn đặt hàng sang Đã giao không?",
    "moveToDelivered": "Di chuyển đến Đã gửi",
    "firstPriority": "Ưu tiên đầu tiên",
    "supportAddingMaxDataAt": "Thêm tối đa 2000 dòng dữ liệu một lần",
    "supportAddingMaxDataAt1": "Thêm tối đa 1000 dòng dữ liệu một lần",
    "stockWillBeIncreasedAfterInbound": "Tồn kho sẽ tăng sau khi nhập kho, vui lòng thao tác thận trọng, chỉ hỗ trợ nhập kho theo lô từ kho địa phương",
    "afterConfirmationTheDocumentWillBe": "Sau khi xác nhận, tài liệu sẽ được đẩy lại kho hàng",
    "stockWillBeDeductedAfterOutbound": "Tồn kho sẽ được trừ sau khi xuất kho, vui lòng thao tác thận trọng",
    "Days1": "30 ngày",
    "Days": "7 ngày",
    "clickToDownloadAttachment": "Nhấp để tải tệp",
    "attachment": "Tệp",
    "addToTheFulfillmentWarehouse": "Thêm vào kho fulfillment",
    "lockedTime": "Thời gian khóa",
    "LockedStock": "Tồn kho bị khóa",
    "lockStockOrder": "Đơn tồn kho bị khóa",
    "onlySupportsFilesDocXlsXlsx": "Chỉ hỗ trợ các tệp *.doc, *.xls, .xlsx, PDF, Excel, png, jpeg, JPG nhỏ hơn 15M",
    "clickOrDragFilesHereTo": "Nhấp hoặc kéo tệp vào đây để tải lên",
    "clickToDeleteAttachment": "Nhấp để xóa tệp",
    "uploadAttachment": "Tải tệp lên",
    "vatRate": "Tỉ lệ VAT (%)",
    "availableStockLocationCode": "Mã vị trí tồn kho có sẵn",
    "stockWil": "Tồn kho sẽ được trừ sau khi xuất kho, vui lòng thao tác thận trọng, chỉ hỗ trợ giao hàng theo lô từ kho địa phương",
    "stockWillBeReleasedAfterBeing": "Tồn kho sẽ được giải phóng sau khi đặt là không hợp lệ, vui lòng thao tác thận trọng",
    "xDataSelected": "Đã chọn {x} dữ liệu",
    "massSetAsInvalid": "Cài đặt không hợp lệ hàng loạt",
    "massPush": "Đẩy hàng loạt",
    "massRefreshStatus": "Trạng thái làm mới hàng loạt",
    "oneItemPerLineSupportExcel": "Mỗi dòng 1 mục (hỗ trợ bản sao và dán, tối đa 500)",
    "clickToMassSearch": "Nhấn để tìm kiếm hàng loạt",
    "salesOutboundList": "Phiếu xuất kho bán hàng",
    "manualOutboundList": "Phiếu xuất kho thủ công",
    "addTemplate": "Thêm mẫu",
    "massOutbound": "Xuất kho hàng loạt",
    "afterConfirmationTheSelectedDataWill": "Sau khi xác nhận, dữ liệu đã chọn sẽ được thêm lại vào kho fulfillment, vui lòng thao tác cẩn thận",
    "massInbound": "Nhập kho hàng loạt",
    "itIsDetectedThatTheIssue": "Phát hiện ra rằng hóa đơn xuất kho đã được cập nhật để chờ xuất kho hoặc đã bị hủy bỏ, không hỗ trợ đẩy, vui lòng làm mới và thử lại",
    "itIsDetectedThatTheReceipt": "Phát hiện rằng danh sách nhập kho đã được cập nhật để vào kho hoặc đã hủy bỏ, không hỗ trợ đẩy, vui lòng làm mới và thử lại",
    "refunding": "Đang hoàn tiền",
    "maxUploadDocuments": "Tải lên tối đa 3 tệp",
    "thirdPartyWarehousePushTime": "Thời gian đẩy hàng kho bên thứ ba",
    "thirdPartyNumber": "Mã đơn kho bên thứ ba",
    "bulkSearch": "Tìm kiếm hàng loạt",
    "thisSizeAlreadyExists": "Kích thước này đã tồn tại",
    "theRangeOfSupportedInputIs": "Phạm vi được hỗ trợ nhập là {min} - {max}",
    "skuName": "Tên SKU",
    "skusellerSKUInJDSellerCenter": "SKU = SKU người bán tại sàn JD",
    "barcodeHeight": "Chiều cao mã vạch",
    "QRCodeLocation": "Vị trí mã QR",
    "beLeft": "Bên trái",
    "beTop": "Trên cùng",
    "actualPrintingPerformanceDependsOnPrintingResults": "Hiệu quả in thực tế phụ thuộc vào kết quả in",
    "printingFailedReasonIsEmpty": "In thất bại, lý do: {} trống và không hỗ trợ in",
    "goUpgrade": "Nâng cấp",
    "jtExpressIdOnlySupportsPaid": "J&T Express ID chỉ hỗ trợ người bán trả phí, vui lòng ủy quyền lại sau khi nâng cấp gói ERP",
    "ordersAreOutOfStockAnd": "Đơn đã hết hàng, không thể đẩy tồn kho",
    "thereAreCurrentlyXPiecesOf": "Hiện có {x} dòng dữ liệu, có thể xuất tối đa 50,000 dữ liệu một lần, vui lòng liên hệ đội ngũ CSKH nếu muốn xuất nhiều dữ liệu hơn",
    "returnInboundListXSuccessfullyCreated": "Phiếu nhập kho trả hàng :{x} tạo thành công",
    "successXFailedX": "{x} thành công, {y} thất bại",
    "homeCustomerTips": "Trong giai đoạn thúc đẩy 99 (2022-09-08-20: 00-2022-09-10: 23: 59), kết quả của dịch vụ khách hàng sẽ là 0",
    "orderOperationLogTips": "Trong 99 thời gian khuyến tài (2022-09-08 20:00-2022-09-10 23:59) chỉ hỗ trợ xem nhật ký hoạt động trong gần 7 ngày",
    "stockPushLogTips": "Trong 99 giai đoạn khuyến tài (2022-09-08 20:00-2022-09-10 23:59) chỉ hỗ trợ xem nhật ký hoạt động trong ngày",
    "adjustmentMethodAll": "Phương thức điều chỉnh: Tất cả",
    "adjustmentMethodOutbound": "Phương thức điều chỉnh: Xuất kho",
    "adjustmentMethodInbound": "Phương thức điều chỉnh: Nhập kho",
    "numberRow1": "Hàng 1",
    "numberRow2": "Hàng 2",
    "numberRow3": "Hàng 3",
    "numberRow4": "Hàng 4",
    "theStoreAuthorizationIsAbnormalPlease": "Ủy quyền cửa hàng bất thường, vui lòng đến nền cửa hàng để kiểm tra ủy quyền",
    "pleaseSetUpYourJtExpress": "Vui lòng cài đặt vận chuyển J&TExpress ID trước",
    "theRangeOfProductImagesThat": "Phạm vi hình ảnh sản phẩm có thể được tải lên là 1-9",
    "barcodeTemplate": "Mẫu mã vạch",
    "typeDimension": "{type}Không gian",
    "channelOrderInTheCurrentOutbound": "Đơn hàng kênh trong phiếu xuất kho hiện đang hết hàng, không đáp ứng điều kiện đẩy. Vui lòng thao tác phân bổ hàng lại rồi đẩy tại mục Chờ xử lý - Đơn hàng có vấn đề",
    "supportsInputNumberFromMinToMax": "Hỗ trợ nhập số thứ tự từ {min} đến {max}",
    "inboundListNumber": "Mã phiếu nhập kho",
    "inboundQuantity": "Số lượng nhập kho",
    "inboundWarehouse": "Kho hàng nhập kho",
    "theTotalQuantityOfASingle": "Tổng số bản in không được vượt quá {0}, vui lòng giảm số lượng sản phẩm hoặc số bản in",
    "aPrintTemplateWithTheSame": "Tên mẫu in đã tồn tại, vui lòng đổi tên khác",
    "printTemplateTip": "Nếu MSKU / mã vạch / SPU vượt quá 23 ký tự, mã vạch in ra có thể không được nhận dạng. Mã QR không có giới hạn này. Vui lòng chọn loại mẫu tùy theo tình hình thực tế.",
    "printMaxlengthTip": "Nếu số lượng ký tự vượt quá 23, mã vạch đã in có thể không thể nhận dạng.",
    "pleaseSelectTheMainSku": "Vui lòng chọn SKU chính",
    "adjustmentMethod": "Phương thức điều chỉnh",
    "purchaseInbound": "Mua nhập kho",
    "outbound": "Xuất kho",
    "inbound": "Nhập kho",
    "manualAdjustment": "Điều chỉnh thủ công",
    "sell": "Bán",
    "pleaseTickTheDataFirst": "Vui lòng chọn dữ liệu trước",
    "changeValue": "Thay đổi giá trị",
    "latestLocationStock": "Tồn kho vị trí cuối cùng",
    "locationNumber": "Mã vị trí",
    "postcode": "mã bưu điện",
    "sucGeneratedOrFailed": "Tạo thành công {success}, thất bại {fail}",
    "printProductBarcode": "In mã vạch sản phẩm",
    "allPermissions": "Không cấp quyền",
    "pleaseSelectAtLeastOneExport": "Vui lòng chọn ít nhất một mục nội dung xuất",
    "pleaseSelectAtLeastOneBasic": "Vui lòng chọn ít nhất một thông tin cơ bản",
    "inboundType": "Loại nhập kho",
    "orderIdXSuccessfullyReprintedThe": "Mã đơn hàng: {X} vận đơn in bổ sung thành công",
    "orderIdXSuccessfullyPrintedThe": "Mã đơn hàng: {X} vận đơn in thành công",
    "setAsInvalidPopulerPrintOrder": "Vô hiệu tác vụ in đơn hàng bùng nổ: {X}",
    "createPopularProductPrintOrderTask": "Tạo tác vụ in đơn hàng bùng nổ: {X}",
    "theOrderIsAlreadyInThe": "Đơn hàng nằm trong tác vụ in đơn bùng nổ, vui lòng in cẩn thận để tránh trùng lặp",
    "orderQuantity": "Số lượng đơn",
    "numberOFProducts": "Số lượng sản phẩm",
    "taskId": "ID tác vụ",
    "printProgress": "Tiến độ in",
    "setAsInvalid": "Vô hiệu",
    "alreadySetAsInvalid": "Đã vô hiệu",
    "unfinished": "Chưa hoàn thành",
    "creating": "Đang tạo",
    "labelPrintingStatus": "Trạng thái in vận đơn",
    "additionalPrintLabel": "In bổ sung vận đơn",
    "size": "Kích cỡ",
    "ordinaryPrintingButPrintingUpTo": "In bình thường (nhưng in lên mức 300 lệnh, mất rất nhiều thời gian)",
    "selectedPrintDataXTheSystem": "Chọn dữ liệu in: {X} dòng, hệ thống phát hiện {Y} vận đơn đã được đánh dấu là in thành công, {Z} chưa in",
    "taskDetails": "Chi tiết tác vụ",
    "toPrintLabel": "In vận đơn",
    "manyProductsWithManyQuantity": "Đa SKU",
    "singleProductWithManyQuantity": "SKU đơn (sl >1)",
    "singleProductWithSingleQuantity": "SKU đơn (sl =1)",
    "theTaskIsBeingCreatedPlease": "Đang tạo tác vụ, vui lòng kiểm tra danh sách in đơn bùng nổ sau",
    "createTask": "Tạo tác vụ",
    "noteOrdersThatHaveBeenPlaced": "Chú ý: đơn hàng nằm trong danh sách in đơn bùng nổ sẽ không thể tham gia vào tác vụ nào khác",
    "totalOrders": "Tổng số đơn hàng:",
    "createdByFilter": "Tạo theo bộ lọc",
    "createByTick": "Tạo theo mục đã chọn",
    "printPopularProduct": "In đơn bùng nổ",
    "adjustmentNo": "Mã điều chỉnh",
    "warehouseReplacementTask": "Nhiệm vụ thay thế kho",
    "warehouseChangeSucceeded": "Thay đổi kho thành công",
    "warehouseChangeFailed": "Lỗi đổi nhà kho",
    "currentWarehouse": "Nhà kho hiện tại",
    "newWarehouse": "Nhà kho mới",
    "processingProgress": "Tiến độ xử lý",
    "adjustmentType": "Loại điều chỉnh Jenis Penyesuaian",
    "thisIsTheInboundPurchasePrice": "Đây là Giá mua Nhập kho hoặc giá mua Xuất kho",
    "theFinalLocationInventoryOnlyIndicates": "Hàng tồn kho của Vị trí mới nhất chỉ đại diện cho sản phẩm hàng tồn kho trong vị trí, Master SKU = tổng lượng hàng tồn kho của SKU được tích lũy từ mỗi vị trí trong kho",
    "changeDimension": "Thay đổi kích thước",
    "Replace": "Mẫu",
    "completeInboundStatus": "Hoàn thành",
    "setTemplate": "Cài đặt mẫu",
    "onlyNumberAndMaxLimit": "Tối đa {max} ký tự, chỉ hỗ trợ số thứ tự",
    "minimalOrderForWholesaleMustBe": "Đơn bán sỉ phải lớn hơn 1",
    "pleaseConfirmWhetherTheOrderMoved": "Vui lòng xác nhận xem có muốn chuyển đơn hàng sang Đã giao hàng hay không",
    "moveToShipping": "Chuyển thành Đang giao",
    "priceMustBeCheaperThanThe": "Giá phải rẻ hơn lần trước",
    "specificationInformation": "Thông tin thuộc tính",
    "theThirdPartyLogisticsIsNot": "Đơn vị vận chuyển bên thứ ba chưa được ủy quyền, việc hủy đơn sẽ không báo đến công ty vận chuyển",
    "deliverBy": "Giao từ",
    "pickupBy": "Nhận bởi",
    "ParcelInformation": "Thông tin gói",
    "theSameOptionValueExists": "Một số giá trị bị trùng lặp",
    "pleaseEnterOptionsSuchAsRed": "Vui lòng nhập mục chọn, ví dụ như màu đỏ",
    "uploadFailed": "Tải lên thất bại",
    "youCanUploadUpToMaxcount": "Tải lên tối đa {maxCount} tệp, tệp tải lên đã bị hủy:",
    "fileSizeExceedsLimitMaxsize": "Kích thước tệp vượt quá giới hạn: {maxSize}",
    "pictureResolutionExceedsTheLimit": "Độ phân giải hình ảnh vượt quá giới hạn",
    "maxOrderCannotBeLessThan": "Số lượng đơn tối đa không thể ít hơn số lượng đơn tối thiểu",
    "clickDownloadAndTheSystemWill": "Nhấn tải về, hệ thống sẽ xuất dựa trên điều kiện lọc đã chọn trong danh sách đơn hàng và thời gian tạo đơn hàng, vui lòng thao tác sau khi đã rõ thông tin",
    "wholesalePriceCanNotBeLower": "Giá bán buôn không thể nhỏ hơn {m}% của giá gốc, vui lòng đặt giá bán buôn hợp lý",
    "mustBeAtLeastX": "Kích thước phải ít nhất 300x300 pixels, ít hơn 2MB, ở định dạng JPG hoặc PNG",
    "thereAreDocumentsThatHaveNot": "Một số tệp không được tải lên thành công",
    "ifYouContinueToSubmitThe": "Nếu tiếp tục gửi, dữ liệu được gửi sẽ bỏ qua các tệp không tải lên thành công",
    "continueToSubmit": "Tiếp tục gửi",
    "thirdPartyLogisticsOnlySupportsThe": "Đơn vị vận chuyển bên thứ ba chỉ hỗ trợ cho người dùng trả phí, vui lòng ủy quyền lại sau khi nâng cấp gói ERP",
    "numbering": "Đánh số",
    "thereIsNoStoreThatSupports": "Không có cửa hàng hỗ trợ sao chép, vui lòng liên kết cửa hàng trước",
    "productCloneInProgress": "Đang sao chép sản phẩm...",
    "countProductClonedTo": "{count} sản phẩm được sao chép vào",
    "exportProgress": "Tiến độ xuất",
    "exportType": "Kiểu xuất",
    "copyToDraft": "Sao chép thành bản nháp",
    "invalid": "Không hợp lệ",
    "editImage": "Chỉnh sửa hình ảnh",
    "editName": "Chỉnh sửa tên",
    "editDesc": "Chỉnh sửa mô tả",
    "editInfo": "Chỉnh sửa thông tin",
    "printLabelStatus": "Trạng thái in vận đơn",
    "platformOrderNumber": "Mã đơn sàn",
    "markAsFakeOrder": "Đơn hàng giả",
    "noteOrdersThatHaveBeenPlaced1": "Chú ý:Các đơn hàng trong tác vụ đơn hàng bùng nổ sẽ không tham gia tác vụ nào khác",
    "theNumberOfVariantCannotExceed": "Số lượng thuộc tính không thể vượt quá {count}",
    "stepConfirmThePictureInformation": "Bước 1: Xác nhận thông tin hình ảnh",
    "stepSelectTheProductYou": "Bước 2: Chọn sản phẩm bạn muốn áp dụng",
    "editImagesInBatches": "Chỉnh sửa hình ảnh hàng loạt",
    "productImageTip": "Vì giới hạn API, hình ảnh sản phẩm tối đa {maxLength} ảnh, kích thước tối đa {size}MB, độ phân giải phải lớn hơn {width} * {height}px",
    "fakeOrder": "Đơn hàng ảo",
    "whetherFakeOrder": "Có phải đơn ảo không",
    "massSettingFakeOrder": "Cài đặt đánh dấu hàng loạt đơn hàng ảo",
    "copyAsDraftTip": "sản phẩm sao chép thành bản nháp",
    "orderXCancelsTheFakeOrder": "Đơn {X} hủy đánh dấu là đơn hàng ảo",
    "orderXIsMarkedAsA": "Đơn {X} được đánh dấu là đơn hàng ảo",
    "fakeOrders": "Đơn hàng ảo",
    "menuProductShopify": "Sản phẩm / Shopify",
    "menuProductShopifyAdd": "Sản phẩm / Shopify /  Thêm sản phẩm",
    "menuProductShopifyEdit": "Sản phẩm / Shopify / Chỉnh sửa sản phẩm",
    "restrictedByJtExpressTheChinese": "Bị hạn chế bởi \"J&T Express\", \"tên tiếng Trung của sản phẩm\" không được vượt quá 100; \"tên tiếng Anh của sản phẩm\" không được vượt quá 200\"; \"mã sản phẩm\" không được vượt quá 50; vui lòng sửa đổi thông tin sản phẩm và nộp lại sản phẩm",
    "tryingToGetChannelShippingLabel": "Đang lấy thông tin vận đơn, vui lòng đẩy lại sau",
    "rangeFieldMessage": "Phải từ {min}〜{max}",
    "duringThePromotionPeriod": "Trong giai đoạn thúc đẩy (2022-10-09 20: 00-2022-10-10 23: 59), kết quả của dịch vụ khách hàng sẽ là 0",
    "onlySupportedDuringTheBigPromotion": "Trong thời gian khuyến tài (2022-12-31 20: 00-2023-01-01 23: 59) chỉ hỗ trợ xem nhật ký hoạt động trong gần 7 ngày",
    "onlyViewingTheOperationLogOf": "Trong giai đoạn khuyến tài (2022-12-31 20: 00-2023-01-01 23: 59) chỉ hỗ trợ xem nhật ký hoạt động trong ngày",
    "gineeIsPushingInformationToThe": "Glenn đang gửi thông Ginee cho anh tới nhà kho, chờ đợi kết quả từ nhà kho bên thứ ba. Bạn có thể tiếp tục xử lý các đơn hàng khác hoặc ở lại trong danh sách các đơn hàng để xem các kết quả bỏ qua.",
    "theDistributedWarehouseTaskWasCreated": "Nhiệm vụ đẩy đã thành công",
    "clickToConfirmTheSystemWill": "Nhấn vào để xác nhận, hệ thống sẽ gọi giao diện kho bên thứ ba để đẩy các kết quả là phải chờ đợi giao diện kho bên thứ ba trở lại. Xin hãy kiên nhẫn chờ đợi.",
    "confirmThatTheSelectedOrderWill": "Kiểm tra xem các đơn hàng được chọn sẽ được thông báo về việc xử lý kho báu bên thứ ba?",
    "tier": "Khoảng giá",
    "min": "Tối thiểu",
    "max": "Tối đa",
    "afterConfirmationTheOrderWillBe": "Sau khi xác nhận, đơn hàng sẽ được đẩy, vui lòng làm mới lại trang để xem kết quả đẩy",
    "onceThePushFailsPleaseTry": "Khi đẩy thất bại, vui lòng thử đẩy lại trong mục Đẩy kho hàng > Đẩy thất bại",
    "noteThe": "Lưu ý: Cập nhật trạng thái đơn hàng kênh và vận đơn đang trễ, có thể dẫn đến đẩy thất bại",
    "theOrderIs": "Đơn hàng hiện đang không được đẩy đến kho bên thứ ba, vui lòng không thao tác trùng lặp, hãy mở mục Đẩy kho hàng > xem tiến độ đẩy",
    "shipmentFailed": "Giao hàng thất bại",
    "afterAutomaticDeliveryIsEnabledThe": "Sau khi bật tính năng giao hàng tự động, các đơn hàng đáp ứng điều kiện sẽ tự động điền thông tin giao hàng rồi mới giao. Nếu thông tin giao hàng không đáp ứng các điều kiện giao hàng, thì phải thực hiện giao hàng thủ công.",
    "defaultFirst": "Mặc định thời gian lấy hàng đầu tiên",
    "defaultLast": "Mặc định thời gian lấy hàng cuối",
    "pickUpTime": "Thời gian lấy hàng",
    "automaticShipping": "Giao hàng tự động",
    "warehouseToBeAssigned": "Kho hàng chờ phân bổ",
    "pushWarehouse": "Đẩy kho hàng",
    "tryingToLoad": "Đang tải...",
    "orderTotalTotalProductAmount": "Tổng tiền đơn hàng = tổng tiền sản phẩm + phí vận chuyển + thuế + bảo hiểm + phí dịch vụ + hoa hồng - tổng chiết khấu",
    "onceVoidedTheOrderWillBe": "Sau khi vô hiệu, đơn hàng sẽ được chuyển sang Vô hiệu",
    "addedChannelProducts": "Thêm sản phẩm kênh",
    "syncedChannelProducts": "Đã đồng bộ sản phẩm kênh",
    "syncedChannelProductsChangedFrom": "Đã đồng bộ sản phẩm kênh, từ {} thành {}",
    "produkChannelTelahDisinkronkanDanInformasi": "Đã đồng bộ sản phẩm kênh, SKU {} trả từ kênh đã bị xóa",
    "theChannelProductsAreSynchronizedAnd": "Đã đồng bộ sản phẩm kênh, kênh trả về SKU {} mới",
    "editedProductNameFromTo": "Đã chỉnh sửa tên sản phẩm, từ {} thành {}",
    "editingProductCategoryFromTo": "Đã chỉnh sửa tên danh mục sản phẩm, từ {} thành {}",
    "editingLongDesecriptionForVariant": "Đã chỉnh sửa độ dài mô tả sản phẩm, từ {0} thành {1}",
    "editingPriceForVariantFrom": "Đã chỉnh sửa giá của tên thuộc tính {0}, từ {1} thành {2}",
    "editedStockChannelVariantName": "Đã chỉnh sửa tồn kho của tên thuộc tính {0}, từ {1} thành {2}",
    "editedChannelSkuVariantName": "Đã cập nhật SKU kênh của tên thuộc tính {0}, từ {1} thành {2}",
    "deletedVariantType": "Đã xóa loại thuộc tính {}",
    "addedVariantType": "Đã thêm loại thuộc tính {}",
    "deletedOption": "Tại loại quy tắc {1} đã xóa mục {2}",
    "addedOption": "Tại loại thuộc tính {1} đã thêm mục mới {2}",
    "updatedProductImage": "Đã cập nhật hình ảnh sản phẩm",
    "updatedVariantImageForVariatName": "Đã cập nhật hình ảnh thuộc tính {} của tên thuộc tính",
    "updatedOfProductSizeFrom": "Đã cập nhật {} của kích thước sản phẩm, từ {} thành {}",
    "updatedProductWeightChangedFrom": "Đã cập nhật cân nặng sản phẩm, từ {0} thành {1}",
    "addTheCheckedLogisticAnd": "Đã thêm đơn vị vận chuyển đã chọn là {}, bỏ chọn đơn vị vận chuyển {}",
    "deleteChannelProduct": "Đã xóa sản phẩm kênh {}",
    "copyProductToChannelStore": "Đã sao chép sản phẩm {} sang cửa hàng {} kênh {}",
    "thisItemIsListed": "Sản phẩm này đã lên kệ{}",
    "thisItemHasBeenRemoved": "Sản phẩm này đã xuống kệ{}",
    "variationNa": "Tên thuộc tính là {0}, SKU kênh là {1} đã đáp ứng các quy tắc liên kết tự động, đã liên kết MSKU {2}",
    "variationNameTheChannelSku": "Tên thuộc tính là {0}, SKU kênh là {1} đã đáp ứng các quy tắc tạo tự động, đã tạo MSKU {2}",
    "variatio": "Tên thuộc tính là {0}, SKU kênh là {1}, đã liên kết thủ công MSKU {2}",
    "variationNameIsChannelSku": "Tên thuộc tính là {0}, SKU kênh là {1}, đã tạo thủ công MSKU {2}",
    "mskumanuallyUnboundTheCorrespondingChannelSku": "MSKU {} hủy thủ công liên kết SKU kênh {} tương ứng trên trang sản phẩm chính",
    "sinceStoreHasBeenDeletedchannel": "Do ủy quyền cửa hàng {} đã bị xóa, SKU kênh {} hủy liên kết MSKU {}",
    "sinceMskuHasBeenRemovedchannelSkuwas": "Do MSKU {} đã bị xóa, SKU kênh {} hủy liên kết MSKU {}",
    "manuallyAddedMasterProduct": "Đã thêm thủ công sản phẩm chính",
    "manuallyCreatedMasterProductByStore": "Đã tạo thủ công sản phẩm chính theo cửa hàng",
    "manuallyCreatedMasterProductByChannel": "Đã tạo thủ công sản phẩm chính theo sản phẩm kênh",
    "createdMasterProductByImportData": "Đã tạo sản phẩm chính bằng cách nhập",
    "autoCreateMasterProduct": "Tự động tạo sản phẩm chỉnh",
    "createdMasterProductByScrapping": "Đã tạo sản phẩm chính bằng sưu tập",
    "editedMasterProductNameFrom": "Đã chỉnh sửa tên sản phẩm chính, từ {0} thành {1}",
    "editedMasterProductCategoryFrom": "Đã chỉnh sửa danh mục sản phẩm chính, từ {0} thành {1}",
    "editedMasterProductSpuFrom": "Đã chỉnh sửa SPU sản phẩm chính, từ {0} thành {1}",
    "editedMasterProductShortDescriptionFrom": "Đã chỉnh sửa mô tả ngắn của sản phẩm chính, từ {0} thành {1}",
    "editedTheMasterProductLongDescription": "Đã chỉnh sửa mô tả dài của sản phẩm chính, từ {1} thành {2}",
    "editedMskuPriceFromTo": "Đã chỉnh sửa MSKU từ {} thành {}",
    "editedAvailableStockForMskuFrom": "Đã chỉnh sửa tồn kho có sẵn cho MSKU {0}, từ {1} đến {2}",
    "editedBarcodeOfMskuFrom": "Đã chỉnh sửa mã vạch của MSKU {0}, từ {1} thành {2}",
    "updatedOfMasterProductSize": "Đã cập nhật {} kích thước sản phẩm chính, từ {} thành {}",
    "updatedTheWeightOfMasterProduct": "Đã cập nhật cân nặng sản phẩm chính, từ {} thành {}",
    "updatedAvailableStockTheUpdatedStock": "Đã cập nhật tồn kho có sẵn, tồn kho được cập nhật là {}",
    "deleteMaste": "Đã xóa sản phẩm Chính {}",
    "deleteMskus": "Đã xóa MSKU {}",
    "printingSpus": "Đã in mã vạch SPU {}",
    "printingMskuBarcode": "Đã in mã vạch MSKU {}",
    "printingBarcode": "Mã vạch với mã vạch {} được in",
    "mskuHasBeenAddedTo": "MSKU {} đã được thêm vào kho {}",
    "mskuHasBeenAddedAndInbound": "MSKU {} đã thêm vào kho {} và được đưa vào lưu trữ.",
    "theAutomaticBindingOfMasterSku": "Đã bật liên kết tự động của quy tắc MSKU, quy tắc là: {}",
    "allStoresEnabledDefaultConfigurationFor": "Bật tất cả cửa hàng (cấu hình mặc định áp dụng cho các cửa hàng mới ủy quyền)",
    "activateByChanelsChanels": "Bật theo kênh, kênh {}",
    "openByStoreStore": "Bật theo cửa hàng, cửa hàng {}",
    "theAutomaticBindingOfTheMaster": "Đã tắt quy tắc liên kết tự động MSKU",
    "theAutomaticCreateMasterSkuRule": "Đã bật quy tắc tạo tự động MSKU, quy tắc là:{}",
    "turnOffTheAutomaticCreateMaster": "Đã tắt quy tắc tạo tự động MSKU",
    "newPrice": "Giá mới",
    "priceCannotBeModifiedDuringThe": "Giá sản phẩm không thể thay đổi trong thời gian khuyến mãi",
    "thereAreDuplicateExportTasksRemaining": "Hiện có tác vụ xuất trùng lặp, thời gian hết hạn còn lại: {0}s",
    "theSingleUrlCannotBeEmpty": "URL đơn không thể trống, vui lòng đẩy lại sau",
    "theLogisticsProviderCannotBeEmpty": "Đơn vị vận chuyển không thể trống, vui lòng liên hệ đơn vị cung cấp dịch vụ kho hàng để thêm thông tin đơn vị vận chuyển",
    "awbNumberCannotBeEmptyPlease": "AWB không thể trống, vui lòng đẩy lại sau",
    "syncSuccessfully": "Đồng bộ thành công",
    "syncFailed": "Đồng bộ thất bại",
    "sourceOfError": "Nguồn lỗi",
    "channelErrorPleaseContactTheChannel": "Lỗi kênh bán: vui lòng liên hệ kênh để kiểm tra",
    "warehouseErrorPleaseContactTheWarehouse": "Lỗi kho hàng: vui lòng liên hệ nhà cung cấp dịch vụ kho hàng để kiểm tra",
    "unknownErrorPleaseContactGineeErp": "Lỗi chưa xác định: vui lòng liên hệ CSKH của Ginee OMS để kiểm tra",
    "recreate": "Tạo lại",
    "failedRecreate": "Tạo lại thất bại",
    "outboun": "Mã đơn xuất kho: {x} mã đơn hàng liên kết {y} đã tạo thành công; người thực hiện: {z} Kho hàng xuất kho: {s}",
    "outOrderNumberXAssociatedOrder": "Mã đơn xuất kho: {x} mã đơn hàng liên kết {y} tạo lại thành công; người thực hiện: {z} Kho hàng xuất kho: {s}",
    "orderNumberXAssociatedOrder": "Mã đơn xuất kho: {x} mã đơn hàng liên kết {y} tạo lại thất bại",
    "rderNumberXAssociatedOrder": "Mã đơn xuất kho: {x} mã đơn hàng liên kết {y} \"chưa đẩy\"; người thực hiện: {z} Kho hàng xuất kho: {s}",
    "oer": "Mã đơn xuất kho: {x} mã đơn hàng liên kết {y} \"đẩy thành công\"; người thực hiện: {z} Kho hàng xuất kho: {s}",
    "tboundder": "Mã đơn xuất kho: {x} mã đơn hàng liên kết {y} \"đẩy thất bại\"; người thực hiện: {z} Kho hàng xuất kho: {s}; nguyên nhân thất bại:{a}",
    "odOrderNumberXAssociatedOrder": "Mã đơn xuất kho: {x} mã đơn hàng liên kết {y} \"xuất kho thành công\"; người thực hiện: {z} Kho hàng xuất kho: {s}",
    "outboundOrderNumberXAssociatedOrder": "Mã đơn xuất kho: {x} mã đơn hàng liên kết {y} \"làm mới thành công\"; người thực hiện: {z} Kho hàng xuất kho: {s}",
    "inboundOrderNumberXAssociatedOrder": "Mã đơn xuất kho: {x} mã đơn hàng liên kết {y} \"làm mới thất bại\"; người thực hiện: {z} Kho hàng xuất kho: {s}; nguyên nhân thất bại:{a}",
    "orderwe": "Mã đơn xuất kho: {x} mã đơn hàng liên kết {y} \"đã gác lại\"; người thực hiện: {z} Kho hàng xuất kho: {s}",
    "outboundOrderNumberXRelatedOrder": "Mã đơn xuất kho: {x} mã đơn hàng liên kết {y}; SKU: w \"đã vô hiệu\"; người thực hiện: {z} Kho hàng xuất kho: {s};",
    "outboundOrderNumberXHasBeen": "Mã đơn xuất kho: {0} đã bị xóa, đơn hàng liên kết {1} Nguyên nhân xóa: đơn hàng đã tạm gác",
    "inboundNoXPerformerYInbound": "Mã đơn nhập kho: {x} Người thực hiện: {y}, Kho hàng nhập kho: {z} tạo mới đơn nhập kho",
    "inboundNoXNotPushedPerformer": "Mã đơn nhập kho: {x}, \"chưa đẩy\"; Người thực hiện: {y}, Kho hàng nhập kho: {z}",
    "inboundNumberXSuccessfulPushPerformer": "Mã đơn nhập kho: {x}, \"đẩy thành công\"; Người thực hiện: {y}, Kho hàng nhập kho: {z}",
    "inboundNoXPushFailedPerformer": "Mã đơn nhập kho: {x}, \"đẩy thất bại\"; Người thực hiện: {y}, Kho hàng nhập kho: {z}; Nguyên nhân thất bại {a}",
    "inboundNoXRefreshSuccessfulPerformer": "Mã đơn nhập kho: {x}, \"làm mới thành công\"; Người thực hiện: {y}, Kho hàng nhập kho: {z}",
    "nomorInboundXRefreshGagalPerformer": "Mã đơn nhập kho: {x}, \"làm mới thất bại\"; Người thực hiện: {y}, Kho hàng nhập kho: {z}; Nguyên nhân thất bại: {a}",
    "inboundNoXinvalidperformerYInboundWarehouse": "Mã đơn nhập kho: {x}, SKU: {a}; \"Đã vô hiệu\"; Người thực hiện: {y}, Kho hàng nhập kho: {z}",
    "inboundNoXskuainvalidperformerYInboundWarehouse": "Mã đơn nhập kho: {x}, SKU: {a}; \"Đã gác lại\"; Người thực hiện: {y}, Kho hàng nhập kho: {z}",
    "inboundNoXwaitingInboundPerformerY": "Mã đơn nhập kho: {x}, \"Chờ nhập kho\"; Người thực hiện: {y}, Kho hàng nhập kho: {z}",
    "receiptNoXinboundPerformerYInbound": "Mã đơn nhập kho: {x}, \"Nhập kho\"; Người thực hiện: {y}, Kho hàng nhập kho: {z}",
    "push": "Đẩy",
    "productsChannelEditProduct": "Sản phẩm / {channel} / Chỉnh sửa sản phẩm",
    "productsChannelAddProduct": "Sản phẩm / {channel} /  Thêm sản phẩm",
    "dueToPossibleDelaysInSyncing": "Do có thể có sự chậm trễ trong việc đồng bộ dữ liệu, vui lòng đợi vài giây sau khi đồng bộ trước khi chọn thương hiệu.",
    "pleaseEnterBrandKeywordsToSync": "Vui lòng nhập từ khóa thương hiệu để đồng bộ",
    "cantFindTheBrandClickMe": "Không tìm thấy thương hiệu? Nhấp vào đây để đồng bộ",
    "outboundCompletionTime": "Thời gian hoàn thành xuất kho",
    "theImageAspectRatioIsInvalid": "Tỷ lệ chiều rộng hình ảnh không hợp lệ và chỉ hỗ trợ hình ảnh có tỷ lệ khung hình là {ratio}",
    "theFileWillBeDisplayedSynchronously": "Các tệp sẽ được hiển thị đồng bộ trong danh sách lịch sử xuất và tệp sẽ chỉ được giữ lại trong 15 ngày, vui lòng tải xuống kịp thời",
    "exportRecords": "Xuất bản ghi",
    "theFollowingOrdersNeedToBe": "Các đơn hàng sau cần bổ sung thêm thông tin vận chuyển trước khi sắp xếp vận chuyển. Ginee đã tự động lọc {x} đơn hàng trên Shopee có trạng thái là Đang chờ xử lý và không được phép sắp xếp lô hàng",
    "arrangementFailed": "Sắp xếp thất bại",
    "arranging": "Đang sắp xếp",
    "pleaseGoToPendingPrintOrder": "Vui lòng đến Đơn Chờ in để tạo tác vụ in Vận đơn sản phẩm bùng nổ",
    "limitasiApiSkuTidakDapatDi": "Do hạn chế của API, tạm thời không hỗ trợ chỉnh sửa SKU",
    "ordersThatAreNotBoundTo": "Đơn không liên kết với MSKU sẽ không được nhập vào tác vụ in sản phẩm bùng nổ",
    "customShop": "Cửa hàng tự chọn",
    "continueCreate": "Tiếp tục tạo",
    "theStoreWasCreatedSuccessfully": "Cửa hàng đã được tạo thành công",
    "warehouseLevelAllPages": "Cấp độ kho - tất cả",
    "warehouseLevelClickToCheck": "Cấp độ kho - đã chọn",
    "locationLevelAllPages": "Cấp độ vị trí - tất cả",
    "locationLevelClickToCheck": "Cấp độ vị trí - đã chọn",
    "inboundManagementoperationLog": "Nhật ký quản lý/hoạt động trong kho lưu trữ",
    "outboundManagementoperationLog": "Nhật ký quản lý/hoạt động xuất kho",
    "inboundNumberXxxxPushedToWarehouse": "Mã đơn nhập kho: {xxxx}đã đẩy đến kho: Tác vụ {xxxx} thất bại",
    "reasonTheOrderNumberXxxxHas": "Nguyên nhân: Mã đơn hàng {xxxx} đạt đủ điều kiện đẩy",
    "inboundNoXxxxHasBeenSuccessfully": "Mã đơn nhập kho: {xxxx} đã được đẩy thành công đến kho hàng {XXXX}, và lấy từ kho hàng {XXXX} mã đơn hàng: #xxxxx#",
    "inboundNumberXxxxHasSuccessfullyCreated": "Mã đơn nhập kho: {xxxx} đã tạo thành công tác vụ đẩy đến kho hàng: {XXXX}, và đang chờ phản hồi từ API kho hàng {XXX}",
    "xxWarehouse": "{XX} kho hàng",
    "failureReasonXxxxErrorSourceXxxxx": "Nguyên nhân thất bại: {XXXX}, Nguồn lỗi: {XXXXX}",
    "outboundNumberFailedToPushXxxx": "Mã đơn xuất kho: Đẩy thất bại {xxxx} đến {XXXX}",
    "outboundNumberXxxxHasBeenSuccessfully": "Mã đơn xuất kho:{xxxx} đã được đẩy thành công đến {XXXX}, và lấy mã đơn kho bên thứ ba: {xxxxx}",
    "outboundNumberXxxxHasSuccessfullyCreated": "Mã đơn xuất kho:{xxxx} đã tạo thành công tác vụ để đến kho hàng: {XXXX}, và đang chờ phản hồi từ API kho hàng {nhà cung cấp fulfillment}",
    "inboundOrderPushed": "Đẩy đơn nhập kho",
    "outboundOrderPushed": "Đẩy đơn xuất kho",
    "sendersFullAddress": "Địa chỉ đầy đủ của người gửi",
    "sendersPostalCode": "Mã bưu điện của người gửi",
    "senderArea": "Địa điểm người gửi",
    "manualPush": "Đẩy thủ công",
    "pendingOrderOldVer": "Đơn chờ xử lý (bản cũ)",
    "pleaseGoToTheDeliverySettings": "Vui lòng điền vào cài đặt giao hàng",
    "noCustomStoreHasBeenDetected": "Nếu không có cửa hàng tùy chỉnh, hãy tạo cửa hàng tùy chỉnh trước",
    "goToCreate": "Đi tới tạo",
    "enableAtLeastOneVariation": "Bật ít nhất một thuộc tính",
    "createdAChannelProductBy": "Cửa hàng {2} đã tạo sản phẩm kênh {3} thông qua {1}",
    "storeSyncedChannelProduct": "Cửa hàng {1} đã đồng bộ sản phẩm kênh {2}",
    "ChangedFromTo": "{1} đổi từ {2} thành {3}",
    "deletedSkuWithVariantName": "Đã xóa SKU với tên thuộc tính {}",
    "addedSkuWithVarianName": "Đã thêm SKU với tên thuộc tính {}",
    "throughStoreDeleted": "Thông qua {1}, cửa hàng {2} đã xóa sản phẩm kênh {3}",
    "deletedVar": "Xóa loại thuộc tính {}",
    "addedVa": "Thêm loại thuộc tính {}",
    "updatedVarianImageWithVarianName": "Đã cập nhật hình ảnh thuộc tính {} của tên thuộc tính",
    "updatedProductSizeAndWeightInfo": "Đã cập nhật kích thước và cân nặng sản phẩm, {1} đổi từ {2} thành {3}",
    "logisticsUpdatedChangedFrom": "Đã cập nhật đơn vị vận chuyển, {1} đổi từ {2} thành {3}",
    "atLeastOneSpecificationIsEnabled": "Ít nhất một thuộc tính được bật",
    "addMasterProduct": "Thêm MSKU",
    "editMasterProduct": "Chỉnh sửa MSKU",
    "autoCreateMasterSku": "Tự động tạo MSKU",
    "autoBindMasterSku": "Tự động liên kết MSKU",
    "addToWarehouse": "Thêm vào kho",
    "masterProductOperationLog": "Nhật ký hoạt động sản phẩm Chính",
    "solutionPleaseFirstCheckWhetherThe": "Giải pháp: vui lòng kiểm tra xem cửa hàng thuộc loại cửa hàng Chính thức hay cửa hàng Power Merchant trước. Nếu không, hãy nâng cấp cửa hàng trong trung tâm người bán trước. Nếu thuộc một trong hai, vui lòng kiểm tra xem ủy quyền cửa hàng có phải bị hủy trong trung tâm người bán hay không.",
    "updatedProductLengthFromTo": "Đã cập nhật chiều dài sản phẩm, từ {0} thành {1}",
    "updatedProductWidthFromTo": "Đã cập nhật chiều rộng của sản phẩm, từ {0} thành {1}",
    "updatedProductHeightFromTo": "Đã cập nhật chiều cao sản phẩm, từ {0} thành {1}",
    "addedMsku": "Thêm mới MSKU{}",
    "onlyIntegersAreSupported": "Chỉ hỗ trợ số nguyên",
    "masstSku": "Chỉnh sửa SKU hàng loạt",
    "newOriginalPrice": "Giá gốc mới",
    "newselingPrice": "giá bán mới",
    "applicationCompletion": "Ứng dụng đã hoàn tất",
    "editPrice": "Chính sửa giá",
    "theStatusOfProductHas": "Trạng thái của sản phẩm {1} đã thay đổi từ {2} thành {3}",
    "theChannelPriceOfMsku": "Giá kênh của MSKU {1} tại cửa hàng {2}, đã thay đổi từ {3} thành {4}",
    "defaultPriceOfMskuChanged": "Giá mặc định của MSKU {1} đã thay đổi từ {2} thành {3}",
    "solutionPleaseSetThePickupWarehouse": "Giải pháp: Vui lòng thiết lập thông tin kho Lấy hàng trong Tài khoản của tôi> hồ sơ người bán> kho hàng trong trung tâm người bán.",
    "afterSuccessfulAuthorizationYouCanUse": "Sau khi ủy quyền thành công, dịch vụ Keeppack fulfillment quản lý sản phẩm xuất nhập kho; thông tin tồn kho và sản phẩm của kho Keeppack sẽ được tự động đồng bộ hai chiều để nâng cao hiệu quả quản lý",
    "clickTheGoToAuthorizationButton": "Nhấn vào nút [Đi đến ủy quyền], bạn sẽ truy cập trang đăng nhập Keeppack, hãy nhập mật khẩu tài khoản dùng để đăng nhập vào hệ thống Keeppack, sau khi hệ thống Keeppack xác nhận thông tin đăng nhập của bạn là chính xác thì bạn sẽ ủy quyền thành công Keeppack cho hệ thống Ginee.",
    "beSureToCompleteTheMatching": "Vui lòng đảm bảo hoàn thành ghép nối sản phẩm Chính trong [Kho hàng-Kho hàng Keeppack-Thông tin sản phẩm]",
    "ifThereIsTooMuchCommodity": "Nếu có quá nhiều dữ liệu sản phẩm, sẽ mất nhiều thời gian, vui lòng kiên nhẫn chờ",
    "imAutomaticallyPullingTheKeeppackWarehouse": "Tự động kéo danh sách kho Keeppack và thông tin sản phẩm cho bạn",
    "automaticallyPullCommodityInformation": "Tự động lấy thông tin sản phẩm",
    "automaticallyPullWarehouseInformationValidWarehouse": "Tự động lấy thông tin kho hàng (giới hạn đối với các kho hàng hợp lệ)",
    "senderMobilePhone": "SĐT người gửi",
    "theBindingRecordIsOnlyValid": "Nhật lý liên kết chỉ có hiệu lực trong 30 ngày, sau 30 ngày sẽ không thể xem được",
    "pullRecord": "Nhật ký kéo",
    "batchPulling": "Kéo hàng loạt",
    "mskuBinding": "MSKU liên kết",
    "bindingMsku": "Liên kết MSKU",
    "pullTime": "Thời gian kéo dữ liệu",
    "commodityValue": "Giá trị",
    "theTotalValueOfGoodsReturned": "Tổng giá trị sản phẩm kho này do giao diện kho bên thứ ba trả về",
    "totalWarehouseInventoryReturnedByThreeparty": "Tổng tồn kho trả về từ giao diện kho bên thứ ba",
    "inventoryWaitingForReceiptReturnedBy": "Tồn kho của phiếu chờ nhập kho trả về từ giao diện kho bên thứ ba",
    "availableInventoryReturnedByThreepartyWarehouse": "Tồn kho có sẵn trả từ giao diện kho bên thứ ba",
    "gineePushesItToTheWarehouse": "Ginee đẩy đến tạo kho hàng",
    "sanfangcangSku": "SKU kho bên thứ ba",
    "nameOfGoodsInTheWarehouse": "Tên sản phẩm trong kho hàng được trả từ giao diện kho hàng bên thứ ba",
    "commodityNameOfWarehouse": "Tên sản phẩm kho hàng",
    "mskuIsBound": "Đã liên kết MSKU",
    "unboundMsku": "Chưa liên kết MSKU",
    "startWithAndBeNo": "Bắt đầu bằng 0 và không quá 12 chữ số",
    "noMoreThanEnglishCharacters": "Không quá 35 ký tự tiếng Anh",
    "usedToPushReceiptDocumentsTo": "Được sử dụng để đẩy phiếu nhập kho đến Keeppack",
    "xxxWarehousesHaveBeenAddedWith": "Thêm {XXX} kho hàng, Mã kho: {XXX}, {XXX}",
    "OnceTheKeeppackInterfaceUpdates": "2. Sau khi giao diện Keeppack cập nhật trạng thái kho hàng thành Vô hiệu, kho trong Ginee sẽ bị vô hiệu hóa đồng bộ, và thông tin sản phẩm / kho không thể đồng bộ được nữa",
    "gineeOnlyReturnsTheWarehouseWith": "1. Ginee chỉ đồng bộ giao diện Keeppack để trả về kho hàng có trạng thái kênh đang Hoạt động",
    "warmTip": "Mẹo",
    "startPullingTheLatestWarehouseInformation": "Sau khi nhấp vào Xác nhận, bạn sẽ bắt đầu kéo thông tin kho mới nhất của Keeppack cho bạn và chờ đợi cho giao diện Keppack để trả về thông tin, hãy kiên nhẫn",
    "pullWarehouseInformation": "Kéo thông tin kho hàng",
    "pullLog": "Nhật ký kéo dữ liệu",
    "warehouseUpdateTimeReturnedByKeeppack": "Thời gian cập nhật kho hàng do giao diện Keeppack trả về",
    "warehouseCreationTimeReturnedByKeeppack": "Thời gian tạo kho hàng được trả về bởi giao diện Keeppack",
    "channelStatus": "Trạng thái kênh",
    "warehouseId": "ID kho hàng",
    "senderInformation": "Thông tin người gửi",
    "automaticCreationOfMainCommodity": "Tự động tạo sản phẩm Chính",
    "automaticUnbindingMsku": "Tự động hủy liên kết MSKU",
    "automaticBindingMsku": "Tự động liên kết MSKU",
    "manuallyCreateTheMainCommodity": "Tạo thủ công sản phẩm Chính",
    "manuallyUnbindMsku": "Hủy liên kết thủ công MSKU",
    "manuallyBindMsku": "Liên kết thủ công MSKU",
    "startBinding": "Bắt đầu liên kết",
    "andAllUnpairedThreepartyWarehouseSkus": "Tất cả các SKU kho bên thứ ba mới chưa ghép nối hiện tại và tiếp theo",
    "allCurrentlyUnpairedThreepartyWarehouseSkus": "Tất cả các SKU kho bên thứ ba hiện không được ghép nối",
    "appliedRange": "Phạm vi áp dụng",
    "whenTheThreepartyWarehouseSkuIs": "Khi SKU của kho bên thứ ba hoàn toàn khớp với MSKU của sản phẩm chính đã được tạo, nó sẽ tự động liên kết",
    "bindingRules": "Quy tắc liên kết",
    "reasonItConformsToTheMsku1": "Nguyên nhân: Phù hợp với cài đặt sản phẩm - sản phẩm trong kho hàng tự động tạo quy tắc MSKU",
    "reasonThreepartyWarehouseHasBeenUnbound": "Nguyên nhân: Kho bên thứ ba chưa liên kết",
    "reasonItConformsToTheMsku": "Nguyên nhân: Tuân theo cài đặt sản phẩm - các sản phẩm trong kho tự động liên kết với các quy tắc MSKU",
    "goodsOfWarehouseXxxxxXxxxxSku": "Kho: Sản phẩm của {XXXXX}: {XXXXX}, SKU: {XXXXX} của kho bên thứ ba đã tạo thành công sản phẩm chính trong hệ thống: {XXXXX}, MSKU: {XXXXX}",
    "commodityXxxxxOfWarehouseXxxxxThreeparty": "Kho: Hàng hóa của {XXXXX}: {XXXXX}, SKU: {XXXXX} và MSKU: {XXXXX} bị hủy liên kết trong kho bên thứ ba",
    "commodityXxxxxOfWarehouseXxxxxAnd": "Kho: Các sản phẩm của {XXXXX}: {XXXXX}, SKU: {XXXXX} và MSKU: {XXXXX} của kho bên thứ ba được liên kết thành công",
    "viewThePullRecord": "Xem kết quả lấy dữ liệu",
    "gineeIsPullingCommodityInformationFrom": "Ginee đang lấy thông tin sản phẩm từ kho hàng, và đợi giao diện kho bên thứ ba trả kết quả, bạn có thể tiếp tục giải quyết các vấn đề khác hoặc xem tiến trình kéo và kết quả trong bản ghi kéo",
    "createAPullTask": "Tạo tác vụ kéo dữ liệu",
    "commodityCreationTime": "Thời gian tạo sản phẩm",
    "inOrderToImproveThePulling": "Để nâng cao hiệu quả kéo dữ liệu, thời gian kéo một lần không quá 15 ngày;",
    "bindingResultXSkusHaveBeen": "Kết quả liên kết: Việc liên kết tự động của {X} SKU và MSKU đã  hoàn tất",
    "automaticBindingSucceeded": "Tự động liên kết thành công",
    "mskuCreationIsNotSupportedFor": "SKU kho bên thứ ba đã được liên kết MSKU không hỗ trợ tạo MSKU, hệ thống sẽ tự động lọc giúp bạn, hãy chắc chắn nắm rõ nhé",
    "mainProductImage": "Hình ảnh sản phẩm Chính",
    "AfterUnbindingTheStatusOf": "3. Sau khi hủy liên kết, trạng thái của phiếu xuất và nhập kho tương ứng với MSKU của sản phẩm trong kho của bên thứ ba được cập nhật thành Vô hiệu.",
    "AfterUnbindingTheInventoryList": "2. Sau khi hủy liên kết, dữ liệu tồn kho được tạo ra bởi liên kết của MSKU với sản phẩm kho của bên thứ ba sẽ bị xóa.",
    "AfterUnbindingTheBindingRelationship": "1. Sau khi hủy liên kết, quan hệ ràng buộc giữa sản phẩm trong kho bên thứ ba và sản phẩm Chính sẽ được hủy",
    "systemRecommended": "Đề xuất hệ thống",
    "listingPullFailed": "Không thể lấy thông tin sản phẩm",
    "theListingWasPulledSuccessfully": "Thông tin sản phẩm được lấy thành công",
    "pullResultTheSkuInformationOf": "Kết quả kéo: Thông tin SKU của kho hàng bên thứ ba đã được kéo, SKU: XXXX / XXXX",
    "sourceOfErrorRepository": "Nguồn lỗi: Kho hàng",
    "reasonForFailureXxxxxx": "Nguyên nhân thất bại:XXXXXX",
    "pullResultAddedXxxPiecesOf": "Kết quả kéo: kéo đến {XXX} phần dữ liệu",
    "resultcause": "Kết quả/ nguyên nhân",
    "pullStatus": "Trạng thái kéo",
    "pullDuration": "Thời gian kéo",
    "noteThatPullRecordsAreOnly": "Vui lòng lưu ý bản ghi kéo chỉ lưu trong hệ thống 7 ngày, không thể kiểm tra bản ghi kéo quá 7 ngày",
    "xxxData": "{XXX} dữ liệu",
    "viewFailureInformation": "Kiểm tra thông tin thất bại",
    "upToCommoditiesCanBe": "Hỗ trợ chọn hàng loạt tối đa 50 sản phẩm một lần trong việc lấy thông tin sự kiện",
    "activityInformationPullFailed": "Đã lấy thông tin khuyến mãi thất bại",
    "pullResultXxxChannelCommodityActivity": "Kết quả lấy thông tin: lấy thành công {X} thông tin khuyến mãi của sản phẩm kênh",
    "activityInformationPullSucceeded": "Đã lấy thông tin khuyến mãi thành công",
    "onlyTheActivityInformationOfChannel": "Chỉ hỗ trợ lấy thông tin khuyến mãi của sản phẩm kênh được đồng bộ với Ginee có trạng thái đã lên kệ",
    "pullByCommodity": "Lấy dữ liệu sản phẩm",
    "pullByStore": "Lấy dữ liệu cửa hàng",
    "pullActivity": "Lấy dữ liệu khuyến mãi",
    "activeReservationSettings": "Thiết lập dự trữ khuyến mãi",
    "synchronizationTime": "Thời gian đồng bộ",
    "mskuReserved": "MSKU  & Dự trữ",
    "skuApplication": "SKU & nộp đơn",
    "notReserved": "Chưa dự trữ",
    "partialReservation": "Dự trữ một phần",
    "reserved": "Đã dự trữ",
    "activeReserveInventory": "hàng dự trữ",
    "pleaseTurnOnTheInventoryMonitoring": "Vui lòng bật nút [Quản lý và Đồng bộ tồn kho] để thao tác",
    "afterTheSetupIsCompletedThe": "Sau khi thiết lập xong, hệ thống chỉ tự động dành kho khuyến mãi cho các khuyến mãi Đang xử lý (bắt đầu). Khuyến mãi chưa bắt đầu sẽ không được đặt chỗ trước, nhưng đặt chỗ tự động sẽ được kích hoạt trong vòng 24 giờ trước khi khuyến mãi bắt đầu",
    "automaticallyReservedOnlyForInprogressActivities": "Tự động dành riêng cho các chương trình khuyến mãi Đang xử lý (Đã bắt đầu)",
    "onceSetUpTheSystemWill": "Sau khi thiết lập thành công, hệ thống sẽ tự động dự trữ tồn kho khuyến mãi chưa bắt đầu/ đang diễn ra",
    "unstartedAndInprogressActivitiesAreAutomatically": "Khuyến mãi chưa bắt đầu và đang diễn ra được dự trữ tự động",
    "beSureToSetAReservation": "Đảm bảo thiết lập chính sách dự trữ trước để đảm bảo rằng tồn kho khuyến mãi được dự trữ như thường theo nhu cầu kinh doanh;",
    "reservationPolicySettings": "Cài đặt sách lược dữ trữ",
    "reservationPolicy": "Sách lược dữ trữ",
    "afterItIsEnabledTheSystem1": "Sau khi kích hoạt, hệ thống sẽ tự động trữ hàng khuyến mãi theo khuyến mãi của nền tảng (Chỉ hỗ trợ nền tảng Shopee), có thể xem tại danh mục [Hàng - Hàng dự trữ]/[Hàng - Danh sách hàng]",
    "automaticallyReserveActiveInventory": "Tự động dự trữ cổ phiếu khuyến mãi",
    "afterItIsEnabledTheSystem": "Sau khi bật, hệ thống sẽ tự động kéo thông tin khuyến mãi Shopee cho bạn, có thể xem tại [Tồn kho - tồn kho khuyến mãi dự trữ]",
    "automaticallyPullPlatformActivity": "Kéo khuyến mãi nền tảng tự động",
    "theClosedEventDataSystemIs": "Hệ thống dữ liệu khuyến mãi đã kết thúc sẽ chỉ được lưu trong 30 ngày, quá 30 ngày sẽ không xem được trong danh sách này",
    "reasonNotReserved": "Lý do không dự trữ",
    "releaseTheReservation": "Hủy bỏ dự trữ",
    "TheActiveInventoryReservedBy": "3. Hủy bỏ tồn kho khuyến mãi của MSKU trong kho hàng sẽ được cập nhật thành 0",
    "AfterReleaseTheChannelActivity1": "2. Sau khi hủy bỏ, tồn kho áp dụng cho khuyến mãi của kênh sẽ không bị ảnh hưởng và vẫn có thể bán trên nền tảng, có thể xảy ra bán quá mức",
    "AfterReleaseTheChannelActivity": "1. Sau khi hủy bỏ, trạng thái khuyến mãi của kênh sẽ không bị ảnh hưởng",
    "confirmTheReleaseOfCurrentlyActive": "Bạn có chắc hủy bỏ tồn kho dự trữ khuyến mãi hiện tại?",
    "theActiveInventoryThatWasReserved": "Tồn kho khuyến mãi được dự trữ đã xóa thành công",
    "theActiveReservedInventoryWasEdited": "Chỉnh sửa thành công tồn kho khuyến mãi dự trữ",
    "obligate": "Dự trữ",
    "application": "Nộp đơn",
    "reservedInventoryEditsAreOnlySupported": "Các chương trình khuyến mãi chưa bắt đầu có thể chỉnh sửa kho dự trữ, bao gồm sửa đổi kho dự trữ và giá trị kho dự trữ",
    "mskuHasNoWarehouseInformationAnd": "MSKU không có thông tin kho hàng, không thể dự trữ thành công",
    "mskuWithUnboundChannelSkuPlease": "Nếu SKU kênh không liên kết với MSKU, vui lòng vào danh sách sản phẩm Chính/ danh sách sản phẩm kênh để hoàn thành việc liên kết và nhập kho",
    "automaticReservationOfActiveInventorySetting": "Chưa bật cài đặt tồn kho khuyến mãi dự trữ tự động",
    "commodityXxxMoveoutActivityIdxxx": "Sản phẩm kênh: {XXX} ID hoạt động đã xóa: {XXX}",
    "xxxManuallyReleaseTheReservedActive": "{XXX} Thủ công hủy bỏ tồn kho khuyến mãi dự trữ",
    "meetTheActiveInventoryReleaseRules": "Tuân thủ các quy tắc hủy bỏ tồn kho khuyến mãi, hủy bỏ tự động",
    "complyWithTheRulesOfAutomatic": "Tuân thủ các quy tắc tự động dự trữ của tồn kho đang hoạt động, tự động dự trữ",
    "deleteProduct": "Xóa sản phẩm",
    "unbindTheMasterProduct": "Hủy liên kết sản phẩm Chính",
    "bindTheMasterProduct": "Liên kết sản phẩm Chính",
    "editedTheAutoBindMasterSku": "Đã chỉnh sửa quy tắc liên kết tự động MSKU, quy tắc chuyển từ {1} thành {2}",
    "editedTheAutoCreateMasterSku": "Đã chỉnh sửa quy tắc tạo tự động MSKU, quy tắc chuyển từ {1} thành {2}",
    "createdMasterProductViaOpenapi": "Đã tạo sản phẩm Chính {0} từ Open-Api",
    "manuallyCreatedMasterProductFrom": "Sản phẩm chính được tạo theo cách thủ công {} từ sản phẩm trong kho hàng",
    "automaticallyCreatedMasterProductFromWarehouse": "Sản phẩm Chính được tạo tự động {} từ sản phẩm trong kho hàng",
    "commonSearches": "Tìm kiếm phổ biến",
    "moreSearches": "Thêm bộ lọc",
    "becauseChannelSkuHasBeen": "Vì SKU kênh {0} đã bị xóa, SKU kênh {1} bị hủy liên kết với MSKU {2}",
    "updatedProductPriceFrom": "Đã chỉnh sửa giá của sản phẩm {0}, từ {1} thành {2}",
    "editedStockForProductFrom": "Đã chỉnh sửa tồn kho của sản phẩm {0}, từ {1} thành {2}",
    "editedChannelSkuForProduct": "Đã cập nhật SKU kênh của sản phẩm {0}, từ {1} thành {2}",
    "channelProductMatchToAutobind": "Sản phẩm kênh {0} tuân theo quy tắc liên kết tự động, đã liên kết MSKU{1}",
    "channelProductMatchesTheAutocreatedRule": "Sản phẩm kênh {0} tuân theo quy tắc tạo tự động, đã tạo MSKU{1}",
    "channelProductIsManuallyBound": "Sản phẩm kênh {0} đã liên kết thủ công MSKU{1}",
    "channelProductManuallyCreatedMsku": "Sản phẩm kênh {0} đã tạo thủ công MSKU{1}",
    "distribution": "Phân bổ",
    "removeSelectedLogistics": "Bỏ đơn vị vận chuyển đã chọn {}",
    "PickListIsPrinted": "Phiếu lấy hàng đã in",
    "printedPacking": "Phiếu đóng gói đã in",
    "InvoiceIsPrinted": "Hóa đơn đã in",
    "printeLabels": "Vận đơn đã in",
    "bopis": "BOPIS",
    "bigProduct": "Big Product",
    "saveSearch": "Lưu tìm kiếm",
    "commonSearchNames": "Tên tìm kiếm phổ biến",
    "radio": "Radio",
    "multiplechoice": "Chọn nhiều",
    "updateTheOrderStatus": "Cập nhật trạng thái đơn hàng",
    "updatedStockForProductFrom": "Đã cập nhật tồn kho của sản phẩm {0}, từ {1} thành {2}",
    "mengeditDeskripsiPanjangMasterProdukDari": "Đã chỉnh sử mô tả sản phẩm chính, từ {0} thành {1}",
    "updatedAvailableStockForMskuTo": "Đã cập nhật tồn kho có sẵn của MSKU{}, tồn kho sau cập nhật là {}",
    "nameManagement": "Quản lý tên",
    "nameList": "Quản lý danh sách",
    "ForChannelProductsWithVariations": "2. Đối với các sản phẩm kênh có thuộc tính, tên thuộc tính và tên sản phẩm được sử dụng là MSKU.",
    "ChannelProductsWithoutVariationsUse": "1. Sản phẩm kênh không có thuộc tính sử dụng SPU là MSKU.",
    "theSystemDetectsThatSomeSkus": "Hệ thống phát hiện một số SKU của sản phẩm kênh trống, vui lòng xác nhận xem có bật các quy tắc mặc định của hệ thống hay không:",
    "updatedProductShortDescriptionFrom": "Đã cập nhật mô tả sản phẩm, từ {0} thành {1}",
    "fuzzyOnlySupportsSingle": "Gần đúng chỉ hỗ trợ đơn lẻ",
    "scanShipment": "Quét lô hàng",
    "editMasterProductName": "Sửa Tên Sản phẩm Chính",
    "editChannelProductName": "Chỉnh sửa tên sản phẩm kênh",
    "newProductName": "tên sản phẩm mới",
    "utilityTool": "Tiện ích",
    "TimesScanningIsRequiredFor": "2 lần: Cần quét 2 lần, quét đầu tiên hiển thị thông tin đơn đặt hàng, lần thứ 2 tạo hồ sơ quét và di chuyển đơn đặt hàng vào danh sách đang chờ xử lý theo cài đặt",
    "TimeScanOnceGenerateA": "1 lần: Quét 1 lần, tạo bản ghi quét và di chuyển đơn đặt hàng vào gói đang chờ xử lý theo cài đặt",
    "twice": "Hai lần",
    "once": "Một lần",
    "scanResultEffectiveTimes": "Số lần kết quả quét có hiệu lực",
    "afterScanningItWillAutomaticallyMove": "Sau khi quét tự động di chuyển vào đang chờ nhận",
    "scanSettings": "Quét cài đặt",
    "scanned": "Đã quét",
    "pleaseScanOrEnterTheCourier": "Vui lòng quét hoặc nhập số chuyển phát nhanh",
    "scanByCourier": "Đang quét số theo dõi",
    "afterTheScanIsSuccessfulThe": "Sau khi quét thành công, đơn đặt hàng sẽ tự động di chuyển vào danh sách [đang chờ xử lý] dựa trên cài đặt, hãy đảm bảo rằng cài đặt đã hoàn tất và cài đặt mặc định là không bật",
    "scanRecord": "Quét hồ sơ",
    "scanSucceeded": "Quá trình quét đã thành công",
    "xxxxnumberDoesNotExist": "{xxxxx} mã đơn không tồn tại",
    "xxxxxHasBeenScannedPleaseDo": "{XXXXX}đã được quét, không lặp lại quét",
    "xxxxxIsNotASelfownedWarehouse": "{XXXXX}không phải là đơn đặt hàng thực hiện vị trí riêng của mình, không thể quét lô hàng",
    "xxxxxIsNotAnOrderIn": "{XXXXX}không phải là đơn đặt hàng trạng thái chờ thanh toán, không thể quét lô hàng",
    "xxxxxDoesNotExistPleaseCheck": "{XXXXX}không tồn tại, vui lòng kiểm tra và quét lại",
    "scanFailed": "Quá trình quét thất bại",
    "pleaseScanOrEnterMskubarcode": "Vui lòng quét hoặc nhập MSKU/Barcode",
    "useState": "Trạng thái sử dụng",
    "shelfName": "Tên kệ",
    "informationShelf": "Thông tin kệ",
    "modifyShelves": "Sửa đổi kệ",
    "commodityDetails": "Chi tiết hàng hóa",
    "storageRack": "Kệ",
    "pleaseSelectAWarehouseAndAdd": "Vui lòng chọn thêm mặt hàng sau khi kho",
    "pleaseChangeToEnInputMethod": "Vui lòng chuyển sang phương thức nhập [EN] trước khi quét",
    "scanResults": "Quét kết quả",
    "notScanned": "Không quét",
    "scanStatus": "Trạng thái quét",
    "shelfNumber": "Số kệ",
    "courierNumber": "Mã vận đơn",
    "sellerCashbackCoin": "Xu nhà bán hoàn trả",
    "tax": "Thuế",
    "sunTotal": "Tổng phụ",
    "currentShelf": "Kệ hiện tại",
    "vacant": "Trống rỗng",
    "inboundAndOutboundRecordAdjustmentMethod": "Cách điều chỉnh hồ sơ xuất nhập cảnh = tổng trong hồ sơ xuất kho thủ công (giá mua trung bình * giá trị thay đổi).",
    "adjustmentMethodOfInboundAndOutbound": "Cách điều chỉnh hồ sơ xuất nhập cảnh = tổng trong hồ sơ bán hàng (giá mua trung bình * giá trị thay đổi).",
    "calculationDescriptionAccordingToTheExport": "Hướng dẫn cách tính: Dựa theo mẫu xuất, thời gian tạo đơn hàng nằm trong khoảng thời gian được lọc, và với trạng thái = Tổng tiền {XXXX} của các đơn hàng đã hoàn thành",
    "allScanned": "Tất cả quét",
    "partiallyScanned": "Một phần đã quét",
    "warehousesKeeppackWarehousesListings": "Kho / Kho Keeppack / Thông tin hàng hóa / Tạo hàng hóa chính",
    "warehouseKeeppackWarehouseProduct": "Kho / Kho Keeppack / Thông tin hàng hóa",
    "inOrderToImproveThePull": "Để cải thiện hiệu quả kéo, thời gian kéo duy nhất không quá 15 ngày;",
    "bindingRecordsAreOnlyValidFor": "Bản ghi ràng buộc chỉ có giá trị trong 30 ngày và bản ghi ràng buộc vượt quá 30 ngày sẽ không thể xem được",
    "pullItems": "Kéo hàng hóa",
    "onlyProductsIssuedByGineeAre": "Chỉ hiển thị các mặt hàng được phát hành bởi Ginee",
    "synchronizeTimeInDescendingOrder": "Đồng bộ hóa thời gian giảm dần",
    "synchronizeTimeInAscendingOrder": "Đồng bộ hóa thời gian tăng dần",
    "areYouSureUnbindYouWantTo": "Xác nhận cởi trói?",
    "mskuCreationIsNotSupportedForTip": "SKU ba bên bị ràng buộc MSKU không hỗ trợ việc tạo MSKU, hệ thống sẽ tự động lọc cho bạn, hãy chắc chắn để biết",
    "pullTheProduct": "Kéo sản phẩm",
    "beforeBatchEditingYoudBetterSynchronize": "Trước khi chỉnh sửa hàng loạt, bạn nên đồng bộ các sản phẩm để giữ cho dữ liệu nhất quán với trung tâm người bán",
    "syncAndEdit": "Đồng bộ & Chỉnh sửa",
    "skipAndEdit": "Bỏ qua & Chỉnh sửa",
    "connectWithAllOfYourCustomer": "Kết nối với tất cả khách hàng từ một hệ thống",
    "makesItEasyToMonitorThe": "Thật tiện lợi khi đã có thể giám sát các hoạt động trong nhiều kho hàng và quản lý tồn kho cùng lúc",
    "goBackToTheList": "Quay lại danh sách",
    "xxxxDoesNotExistPleaseScan": "{XXXX}không tồn tại, vui lòng quét lại sau khi sản phẩm chính được thêm vào",
    "theCombinationXxxCannotBePut": "Kết hợp sản phẩm{XXX} không thể được nhập bằng tay, vui lòng xóa và thử lại",
    "theTrackingNumberXxxxWasSuccessfully1": "Số theo dõi {0} đã được quét thành công và trạng thái quét đơn hàng {1} đã được cập nhật thành Đã quét và chuyển sang Đang chờ nhận hàng",
    "theTrackingNumberXxxxWasSuccessfully": "Số theo dõi {XXXX} đã được quét thành công và trạng thái quét của đơn hàng {XXXX} đã được cập nhật thành Đã quét",
    "waitingInbound": "Chờ nhập kho",
    "upgrade": "Nâng cấp",
    "about": "Giới thiệu",
    "pleaseSelectACategoryFirst": "Vui lòng chọn một danh mục trước",
    "subAccount": "Tài khoản con",
    "authorizedStores": "Cửa hàng khả dụng",
    "availableQuantity": "Đơn hàng khả dụng",
    "addImagesToEachVariantOption": "Thêm hình ảnh vào từng tùy chọn thuộc tính",
    "productInformationDisplayedOnTheLogistics": "Thông tin sản phẩm hiển thị trên vận đơn (JD/Bukalapak)",
    "tiktokPrintShippingLabelAndPick": "(Tiktok) in vận đơn và phiếu lấy hàng",
    "downloadDocument": "Tải tài liệu:{0}",
    "thereAreOrdersThatStillBeing": "Vẫn còn đơn đang được in. Sau khi đóng cửa sổ bật lên, bạn có thể tải xuống  vận đơn trong lịch sử in.",
    "printHistory": "Lịch sử in",
    "printAll": "In tất cả",
    "maximumOrdersCanBePrinted": "Có thể in tối đa 1.000 đơn hàng cùng lúc, và bản PDF vận đơn được tạo cho mỗi kênh, sẽ mất nhiều thời gian",
    "unlimited": "Không giới hạn",
    "otherCharges": "Các chi phí khác",
    "bearTheCost": "Thanh toán",
    "authorizationStatusTotalNumberOf": "Trạng thái ủy quyền = Tổng số cửa hàng của sàn/webstore \"không hoạt động\"",
    "expiredInfo": "Trạng thái ủy quyền = Tổng số cửa hàng của sàn/webstore \"đã hết hạn\"",
    "storeStatusOfAllAuthorizedChannels": "Trạng thái cửa hàng của tất cả kênh được ủy quyền = Tổng số sản phẩm \"hết hàng\"",
    "status_banned_info": "Trạng thái cửa hàng của tất cả kênh được ủy quyền = Tổng số sản phẩm \"cấm bán\"",
    "theSumOfTheNumberOf": "Tổng số sản phẩm đăng thất bại lên  kênh đã được ủy quyền",
    "stockListPageNotInboundTab": "[Danh sách hàng tồn kho] Số lượng MSKU trên trang [Chưa nhập kho] tab",
    "theNumberOfMskusThatHave": "Số lượng MSKU đã được thêm vào kho và chưa được tạo để tăng tồn kho",
    "stockListPageStockWarningTab": "Số lượng MSKU trong trang [Hàng tồn kho] và tab [Cảnh báo tồn kho]",
    "totalMskuWhichTheAvailableStock": "Tổng MSKU có tồn kho có sẵn ít hơn tồn kho an toàn",
    "totalMskuOfStockListPage": "Tổng số MSKU của trang [Hàng tồn kho] trong tab [Hết hàng]",
    "totalMskusWithAvailableStock": "Tổng số MSKU có 0 tồn kho",
    "TotalOrderOf": "Tổng số đơn hàng của [Quản lý trả hàng - Trả hàng và hoàn tiền] trong tab [Chờ xử lý]",
    "returnRequestInfo": "Đơn hàng Shopee/Lazada/Tiktok yêu cầu người bán xử lý khi người mua gửi yêu cầu đổi trả",
    "totalOrderOfReturnManagement": "Tổng số đơn hàng của [Quản lý trả hàng - Danh sách hoàn tiền] trong tab [Chờ xử lý]",
    "shopeelazadatiktokOrdersThatRequireTheSeller": "Đơn hàng Shopee/Lazada/Tiktok yêu cầu người bán xử lý khi người mua gửi yêu cầu hoàn tiền",
    "ordersThatHaveBeenPushed": "Tổng số đơn hàng của trang [Chờ xử lý-Kho hàng đẩy] trong tab [Đẩy thất bại], vui lòng đảm bảo ưu tiên xử lý",
    "ordersThatHaveBeenPushedTo": "Các đơn hàng đã được hệ thống đẩy thủ công hoặc tự động vào kho bên thứ ba, nhưng API kho bên thứ ba trả về kết quả thất bại",
    "totalOrderOfPendingpushWarehousePage": "Tổng số đơn hàng của trang [Chờ xử lý-Kho hàng đẩy] trong tab [Chờ đẩy], vui lòng đảm bảo đẩy đúng hạn",
    "ordersThatNeedToBePushed": "Đơn hàng cần được đẩy đến kho bên thứ ba để thực hiện, nhưng chưa được đẩy",
    "totalOrderOnAbnormalOrdersPage": "Tổng số đơn hàng trên trang [Đơn hàng bất thường], nếu có dữ liệu liên quan, vui lòng đảm bảo thủ công đồng bộ đơn hàng",
    "ordersWithIncompleteInformationDueTo": "Đơn hàng không có thông tin đầy đủ do hạn chế QPS trên API kênh",
    "theNumberOfOrdersInThe": "Tổng đơn hàng của trang [Đơn chờ in] trong tab [Có thể in], có thể được xử lý nhanh chóng và thống nhất",
    "allPrintableShippingLabelsAreDisplayed": "Tất cả đơn hàng có thể in vận đơn sẽ được hiển thị trong danh sách này",
    "toBeProcessedArrangeShipment": "[Chờ xử lý - Chuẩn bị hàng] Tổng số đơn hàng trong mỗi tab của trang có thể được ưu tiên và thống nhất để phân phối đa kênh và đa cửa hàng",
    "allShippableOrdersAreDisplayedIn": "Tất cả các đơn hàng có thể vận chuyển được hiển thị trong danh sách này",
    "pendingproblemOrdersTheSumOfThe": "[Đơn hàng Chờ xử lý-Có vấn đề] Tổng số đơn hàng trong mỗi tab của trang, vui lòng đảm bảo ưu tiên xử lý trước",
    "whenTheOutboundListCannotBe": "Khi không thể tạo đơn hàng xuất kho hoặc MSKU hết hàng, nó sẽ được xác định là đơn hàng có vấn đề",
    "xxxxThereIsMoreThanOne": "{XXXX} trong hệ thống có nhiều hơn một sản phẩm, MSKU và Barcode bị trùng lặp, vui lòng kiểm tra và thử lại",
    "servicePH": "Nhấp và liên hệ với chúng tôi",
    "serviceCN": "Cố vấn tận tâm của bạn wechat:Ginee888",
    "serviceTH": "Nhóm hỗ trợ Ginee Thái Lan",
    "serviceVN": "Nhóm hướng dẫn Zalo",
    "serviceMYandID": "Nhóm hỗ trợ Ginee",
    "accountInfo": "Thông tin Tài khoản",
    "refundApplication": "Yêu cầu hoàn tiền",
    "returnRequest": "Yêu cầu hoàn trả",
    "orderManagement": "Quản lý đơn hàng",
    "sumOfTheNumberOfShopeelazada": "Trạng thái ủy quyền=\"Được ủy quyền\" và tổng số lượng cửa hàng Shopee/Lazada có hiệu lực dưới 30 ngày",
    "shopeebukalapaktokopediaOrdersUseTheGineeTemplate": "Đơn hàng Shopee/Bukalapak/Tokopedia sử dụng mẫu Ginee để in đơn nhanh, các kênh khác tạm thời chỉ hỗ trợ in đơn hàng nhanh theo mẫu kênh",
    "highSpeedPrintTaskDetails": "Chi tiết tác vụ in tốc độ cao",
    "printTaskDetails": "Chi tiết tác vụ in",
    "regularPrint": "In thường",
    "printUnlimitedShippingLabelInSingle": "In vận đơn không giới hạn đối với bản in đơn lẻ, không hỗ trợ xem trước bản in",
    "clickHereToViewDetailedInstructions": "Bấm vào đây để xem hướng dẫn chi tiết",
    "inOrderToImprovePrintingSpeed": "Để cải thiện tốc độ và trải nghiệm in, khuyến khích sử dụng in tốc độ cao",
    "itIsDetectedThatYouHave": "Bạn đã chọn hơn 300 đơn hàng, và hiện không hỗ trợ in bằng mẫu vận đơn Ginee",
    "thePrintingTaskHasExpiredPlease": "Tác vụ in hết hạn, vui lòng đến [Đơn 90 ngày qua > lịch sử đơn hàng] để kiểm tra nhật ký in",
    "imageSizeLengthLimit": "Có thể cùng lúc tải lên nhiều hình ảnh. Tối thiểu {width} x {height} pixel, tối đa {size}MB (Tối đa {maxLength} ảnh)",
    "imageManagement": "Quản lý Hình ảnh",
    "noMskuInTheDocumentHas": "Không có msku nào trong tài liệu có sự khác biệt hàng tồn kho mà không cần tạo phiếu điều chỉnh",
    "inventoryHasNotBeenCompletedPlease": "Kiểm kê chưa hoàn tất, hãy ưu tiên hoàn thành thao tác sau khi kiểm kê",
    "stocktakingNumber": "Mã kiểm kê",
    "checkingStock": "Đang kiểm kê",
    "waitingStocktaking": "Chờ kiểm kê",
    "importStockTakingResult": "Nhập kết quả kiểm kê",
    "mskuNumber": "MSKU số",
    "stocktakingType": "Loại kiểm kê",
    "numberOfMskuWithCountingDifferences": "Số lượng MSKU khác nhau trong kiểm kê",
    "numberOfDifferences": "Số lượng chênh lệch",
    "generateInventoryAdjustmentDocument": "Tạo danh sách điều chỉnh kho",
    "pleaseCheckTheSpecificInformationIn": "Vui lòng xem thông tin cụ thể [Kho - Phiếu điều chỉnh hàng tồn kho] và thực hiện điều chỉnh thủ công để cập nhật hàng tồn kho MSKU theo kết quả kiểm kê",
    "inventoryAdjustmentDocGeneratedSuccessfully": "Lệnh điều chỉnh hàng tồn kho đã được tạo ra thành công",
    "voidSuccessfully": "Hủy bỏ thành công",
    "afterVoidingItIsNotSupported": "Sau khi hủy bỏ, không hỗ trợ tiếp tục kiểm kê, MSKU đã kiểm kê cũng không hỗ trợ tạo lệnh điều chỉnh hàng tồn kho để điều chỉnh hàng tồn kho",
    "AfterTheCountingTheSystem": "3. Sau khi kiểm kê, hệ thống sẽ tự kiểm tra sản phẩm xuất kho thất bại trong quá trình kiểm kê, đơn hàng thực tế đã được giao/ đã hủy và đơn hàng xuất kho tự động xuất kho /gác lại, hệ thống sẽ chỉnh sửa tồn kho MSKU",
    "TheMskuInventoryThatHas": "2. MSKU kiểm kê chưa được đăng ký kiểm kê tồn kho sẽ không được cập nhật, và kết quả kiểm kê MSKU được cập nhật thành \"chưa kiểm kê\", hãy thao tác cẩn thận",
    "AfterTheInventoryIsFinished": "1. Sau khi kiểm kê kết thúc, trạng thái danh sách kiểm kê sẽ được thay đổi thành hoàn thành và không thể tiếp tục thao tác",
    "confirmTheEndOfTheInventory": "Xác nhận kết thúc kiểm kê?",
    "theCurrentTaskIsStillBeing": "Tác vụ hiện thời vẫn đang được tạo, hãy thao tác sau",
    "clickStartCountingAndTheStatus": "Sau khi nhấp vào [Bắt đầu kiểm kê], trạng thái danh sách kiểm kê sẽ được cập nhật thành [Kiểm kê] và sẽ tự động mở trang hoạt động kiểm kê cho bạn",
    "printInventoryList": "In đơn kiểm kê",
    "endInventory": "Kết thúc kiểm kê",
    "startCounting": "Bắt đầu kiểm kê",
    "inventoryByMsku": "Kiểm kê theo MSKU",
    "wholeWarehouseInventory": "Kiểm kê theo kho",
    "automaticallyFilterMskuXCombinationsAnd": "Đã tự động lọc kết hợp MSKU [ {bundleSku} ] cá, MSKU [ {countingSKU} ] trong nhiệm vụ kiểm kê chưa hoàn thành, lần này có thể kiểm kê MSKU tổng cộng [ {canCountSKU} ] cá nhân",
    "aTotalOfXMskuCan": "Có thể kiểm kê tổng cộng {X} MSKU lần này",
    "createInventoryTask": "Tạo phiếu kiểm kê",
    "aTotalOfXMskuAre": "Lần này tổng cộng {count} MSKU đang chờ kiểm kê, phiếu kiểm kê đang được áp dụng, vui lòng chờ và di chuyển đến danh sách kiểm kê tồn kho để tiếp tục thao tác",
    "inventoryCreationTaskSubmittedSuccessfully": "Tác vụ tạo phiếu kiểm kê đã gửi thành công",
    "afterConfirmationTheFilledInformationWill": "Sau khi xác nhận, thông tin đã điền sẽ không được lưu",
    "areYouSureYouDontWant": "Xác nhận rằng không cần lưu thông tin hiện tại chứ?",
    "currentWarehouseInventory": "Kho dự trữ hiện tại",
    "inventoryIsNotSupportedForCombination": "Các sản phẩm kết hợp và MSKU trong tác vụ kiểm kê chưa hoàn thành sẽ không hỗ trợ kiểm kê",
    "mskuDetails": "MSKU chi tiết",
    "inventory": "Kho",
    "afterSavingTheInformationWillOnly": "Sau khi lưu, thông tin sẽ chỉ được lưu trong danh sách kiểm kê và hỗ trợ sửa đổi lại",
    "inventoryLoss": "Hụt kho",
    "inventoryProfit": "Dư kho",
    "panping": "Cân đối",
    "inventoryResults": "Kết quả kiểm kê",
    "takeStockInTheWarehouse": "Tồn kho kiểm kê",
    "takeStockOf": "Kiểm kê",
    "counting": "Số lượng kiểm kê",
    "inventorySheet": "Mã kiểm kê",
    "notCounted": "Chưa kiểm kê",
    "TheCurrentAdjustmentDocumentStatus": "3. Trạng thái phiếu điều chỉnh hiện tại được cập nhật thành [Điều chỉnh]",
    "TheCurrentMskuAdjustmentStatus1": "3. Trạng thái điều chỉnh MSKU hiện tại được cập nhật thành [Chưa điều chỉnh]",
    "TheSystemWillNotChange": "2. Hệ thống sẽ không thay đổi tồn kho MSKU hiện tại",
    "OnceItIsConfirmedThat": "1. Một khi xác nhận không cần điều chỉnh, sẽ không thể thao tác lại",
    "confirmThatTheCurrentMskuInventory": "Xác nhận tồn kho MSKU hiện tại không cần điều chỉnh chứ?",
    "TheCurrentMskuAdjustmentStatus": "3. Trạng thái điều chỉnh MSKU hiện tại được cập nhật thành [Đã hoàn thành]",
    "withoutAdjustment": "Không cần điều chỉnh",
    "confirmAdjustment": "Xác nhận điều chỉnh",
    "unadjusted": "Chưa điều chỉnh",
    "singleAdjustment": "Điều chỉnh hàng loạt",
    "continueToAdjust": "Tiếp tục điều chỉnh",
    "startAdjusting": "Bắt đầu điều chỉnh",
    "inventoryReductionMsku": "Giảm tồn kho MSKU",
    "increaseInventoryMsku": "Tăng tồn kho MSKU",
    "totalMsku": "Tổng MSKU",
    "adjusting": "Đang điều chỉnh",
    "toBeAdjusted": "Chờ điều chỉnh",
    "adjustTheSingleNumber": "Điều chỉnh không",
    "afterItIsTurnedOnMsku": "Sau khi mở, MSKU trong quá trình kiểm kê không hỗ trợ tăng hay giảm tồn kho, không ảnh hưởng đến việc khóa tồn kho, hãy chắc chắn bạn biết thông tin nhé",
    "mskuDuringTheCountingPeriodDoes": "MSKU trong quá trình kiểm kê không hỗ trợ các thao tác xuất nhập",
    "afterAdjustment": "Sau điều chỉnh",
    "beforeAdjustment": "Trước điều chỉnh",
    "inventoryVariance": "Chênh lệch tồn kho",
    "stockAdjustment": "Điều chỉnh tồn kho",
    "continueCounting": "Tiếp tục kiểm kê",
    "confirmIntegrations": "Xác nhận tích hợp",
    "mskuXDoesNotExistIn": "MSKU {X} không tồn tại trong kho hàng {X}, vui lòng thêm MSKU vào kho trước",
    "orderXxxxxStatusChangedToShipping": "đặt hàng{XXXXX}trạng在{XXXX-XX-XX:XX}thay đổi thành Shipping",
    "xxxAfterTheInventoryCountIs": "{XXX} kết thúc kiểm kê hàng tồn kho, MSKU có thể hoạt động ra vào kho, theo trạng thái đơn đặt hàng thay đổi, tự động sửa chữa giá trị hàng tồn kho",
    "xxxGeneratesTheInventoryAdjustmentDocument": "{XXX} tạo hàng tồn kho điều chỉnh số duy nhất:{XXXX}, kiểm kê kết quả: thua lỗ",
    "xxxGenerateTheInventoryAdjustmentDocument": "{XXX} tạo ra số duy nhất điều chỉnh hàng tồn kho: {XXXX}, kết quả kiểm kê: doanh thu",
    "theCurrentRepositoryIsDeactivatedPlease": "Kho hiện tại đã bị hủy kích hoạt, hãy làm điều đó khi danh sách kho được bật",
    "theCurrentWarehouseIsNotIts": "Kho hiện tại không phải là kho riêng và không thể kiểm kê",
    "theCurrentRepositoryDoesNotExist": "Kho hiện tại không tồn tại, hãy hành động sau khi thêm vào danh sách kho",
    "theInventoryHasBeenTaken": "Đã kiểm kê",
    "AreYouSureToDelete": "bạn có chắc chắn xóa thông tin hiện tại không?",
    "defaultShelf": "Kệ mặc định",
    "AreYouSureToEnd": "Xác định kết thúc kiểm kê?",
    "mskuInInventoryExistsInThe": "MSKU trong tài liệu hiện tại được lưu trữ, sau khi kết thúc kiểm kê, hoạt động được nhập trực tiếp vào kho",
    "thereIsMskuInCountingIn": "MSKU trong tài liệu hiện tại được lưu trữ, sau khi kết thúc kiểm kê, hoạt động trực tiếp vào hoặc ra khỏi kho",
    "AreYouSureToVoid": "Có chắc là hủy bỏ thông tin hiện tại không?",
    "keywordid": "từ khoá",
    "onceTheAdjustmentIsConfirmedIt": "Một khi điều chỉnh đã được xác nhận, điều chỉnh không thể bị đình chỉ",
    "atTheBeginningOfAdjustmentThe": "Khi điều chỉnh bắt đầu, hàng tồn kho kho MSKU sẽ tự động được cập nhật thành giá trị hàng tồn kho được điều chỉnh",
    "addedInventoryList": "Thêm danh sách hàng tồn kho",
    "noMoreThanMaxCharacters": "Không quá {max} ký tự",
    "confirmAndUpdateMskuInventory": "Xác nhận điều chỉnh và cập nhật hàng tồn kho MSKU?",
    "areYouSureNotToSave": "Bạn có chắc không muốn lưu thông tin hiện thời không?",
    "createAnInventoryTask": "Tạo đơn kiểm kê",
    "countingStatus": "Trạng thái kiểm kê",
    "counted": "Đã kiểm kê",
    "saving": "Lưu trong...",
    "areYouCancel": "Có xác nhận muốn hủy bỏ?",
    "notCount": "Tài liệu hiện tại không phải đang chờ kiểm kê và không thể hoạt động. Vui lòng làm mới trang và thử lại.",
    "shouldBeBetweenXToX": "Phạm vi phải là từ X đến X",
    "offlineOrder": "Đặt hàng ngoại tuyến",
    "duringTheUnboundPeriodTheOutbound": "Trong khoảng thời gian không liên kết, đơn hàng xuất kho không thể được tạo để khóa/khấu trừ  tồn kho và không thể thực hiện đồng bộ tồn kho",
    "tokocabangStockChannelInterfaceDoesNot": "Vui lòng không liên kết kho Tokopedia O2O với kho Ginee WMS/của bên thứ ba, nếu không sẽ gây ra các lô hàng trùng lặp",
    "disabledChannelWarehousesCannotUpdateStock": "Kho kênh đã ngừng hoạt động, không thể cập nhật  tồn kho do các hạn chế API kênh;",
    "pleaseBeSureToCompleteThe1": "Vui lòng đảm bảo hoàn thành liên kết 1:1 giữa kho kênh và kho Ginee để thực hiện phân chia đơn hàng và đồng bộ tồn kho;",
    "systemWarehouseAddress": "Địa chỉ kho hàng hệ thống",
    "systemWarehouseName": "Tên kho hàng hệ thống",
    "channelWarehouseAddress": "Địa chỉ kho hàng kênh",
    "channelWarehouseName": "Tên kho hàng kênh",
    "channelWarehouseManagement": "Quản lý Kho hàng Kênh",
    "upgradePackage": "Nâng cấp gói",
    "askCustomerService": "Hỏi CSKh",
    "theCurrentStoreHasOpenedThe": "Cửa hàng hiện tại đã mở chức năng đa kho kênh, vui lòng nâng cấp lên gói hỗ trợ chức năng quản lý đa kho trước khi vận hành",
    "storeAuthorizationFailed": "Ủy quyền kho hàng thất bại",
    "youCanDirectlyAddProductsIn": "Vui lòng đảm bảo hoàn thành việc liên kết kho kênh và kho Ginee ngay lập tức, nếu không, bạn sẽ không thể xử lý đơn hàng/đồng bộ hàng trong kho",
    "pleaseBeSureToCompleteThe": "Vui lòng đảm bảo hoàn thành việc liên kết kho kênh và kho Ginee ngay, nếu không, bạn sẽ không thể xử lý đơn hàng/đồng bộ tồn kho",
    "theStoreHasOpenedTheChannel": "Cửa hàng đã mở dịch vụ đa kênh kho hàng và đang sử dụng dịch vụ đồng bộ kho",
    "authorizing": "Đang ủy quyền",
    "multiWarehouse": "Đa kho",
    "theGineeSystemReportsAnError": "Hệ thống Ginee báo lỗi, nguyên nhân như sau: {XXXXX}",
    "theChannelInterfaceReportsAnError": "Giao diện kênh báo lỗi, nguyên nhân như sau: {XXXXX}",
    "failedToPullChannelWarehouseInformation": "Kéo thông tin kho hàng kênh thất bại",
    "deactivateWarehouse": "Vô hiệu kho hàng",
    "addWarehouse": "Thêm kho hàng",
    "channelWarehouseInformationPulledSuccessfully": "Kéo thành công thông tin kho hàng kênh",
    "pullTheChannelWarehouseAddXxx": "Kéo kho hàng kênh, thêm {AAA}, vô hiệu {BBB}",
    "channelWarehouseXxxBindingWarehouseIs": "Kho hàng liên kết của kho hàng kênh {AAA} được cập nhật từ {BBB} thành {CCC}",
    "theChannelWarehouseXxxIsBound": "Kho hàng kênh {AAA} được liên kết với kho hàng của hệ thống {BBB}",
    "updateWarehouse": "Cập nhật kho hàng",
    "pleaseNoteThatTheOperationLog": "Xin lưu ý, nhật ký vận hành sẽ chỉ được lưu trong hệ thống trong 30 ngày và các bản ghi trên 30 ngày không thể kéo được",
    "thereIsAnUnfinishedSalesDelivery": "Có một đơn xuất kho chưa hoàn thành trong kho hàng hiện tại, không thể cập nhật mối quan hệ liên kết",
    "OrdersAllocated": "1. Đơn hàng đã phân bổ kho hàng kênh sẽ tự động phân bổ kho khớp theo quan hệ liên kết mới, không cần thiết lập quy tắc phân bổ kho",
    "areYouSureToUpdateThe": "Xác nhận cập nhật kho hàng liên kết chứ?",
    "OrdersThatHaveBeenAllocated": "1. Đơn hàng đã phân bổ kho hàng kênh sẽ tự động phân bổ kho khớp theo quan hệ liên kết, không cần thiết lập quy tắc phân bổ kho",
    "areYouSureToBindThe": "Xác nhận liên kết kho hàng hiện tại chứ?",
    "ownWarehouse": "Kho hàng chính chủ",
    "warehouseMarketplace": "Kho hàng kênh",
    "TheStatusOfTheCurrent": "3. Trạng thái đơn điều chỉnh hiện tại được cập nhật thành [Đã hoàn thành]",
    "lastSaleDateTips": "Ngày tạo của đơn hàng mới nhất của sản phẩm hiện tại",
    "notification74": "Tổng số tiền trong đơn hàng (bao gồm phí ship, v.v.) của các đơn hàng hợp lệ trong khoảng thời gian đáp ứng các điều kiện khó bán",
    "notification72": "Số lượng sản phẩm hiện tại bán ra của các đơn hàng hợp lệ trong một khoảng thời gian phù hợp với điều kiện hàng khó bán",
    "salesVolume": "Khối Lượng Hàng Bán",
    "notification70": "Tổng số người mua của tất cả đơn hàng trong một khoảng thời gian phù hợp với điều kiện hàng khó bán, lọc trùng lặp dựa vào số điện thoại di động",
    "totalNumberOfBuyers": "Tổng số người mua",
    "validOrder": "Đơn hàng hợp lệ",
    "notification66": "Tổng số đơn hàng trong một thời gian phù hợp với điều kiện hàng khó bán, bao gồm tất cả trạng thái",
    "totalNumberOfOrders": "Tổng số đơn hàng",
    "notification64": "Ngày tạo của đơn hàng mới nhất của sản phẩm hiện tại",
    "lastSaleDate": "Ngày bán hàng gần nhất",
    "notification58": "Chỉ hiển thị sản phẩm có Master SKU",
    "notification55": "Sản phẩm đã bán trong {day} ngày nhỏ hơn {number}",
    "notification54": "Trước khi sử dụng bảng thống kê hàng khó bán cần hoàn thành cài đặt điều kiện hàng khó bán",
    "slowSalesSettings": "Cài đặt hàng khó bán",
    "commodityCategory": "Danh mục sản phẩm",
    "slowSalesStatisticsTable": "Bảng thống kê hàng khó bán",
    "notification34": "Tạo tệp thất bại, vui lòng thử lại",
    "notification33": "Tệp sẽ được đồng bộ hiển thị trong danh sách lịch sử xuất, tệp chỉ lưu trữ 15 ngày, vui lòng kịp thời nhấp vào tải xuống",
    "notification32": "Tệp đã được tạo, vui lòng nhấp vào tải xuống",
    "generatingFilePleaseWait": "Đang tạo tệp, vui lòng chờ",
    "replenishStock": "Bổ sung",
    "unableEstimate": "Tạm thời không thể ước tính",
    "estimatedDaysTips": "(Tồn kho có sẵn - Tồn kho an toàn) / Số lượng bán hàng ngày, những ngày còn lại để đạt được lượng Tồn kho an toàn được ước tính theo số lượng hàng bán ra trung bình hàng ngày. Nếu số lượng bán hàng ngày nhỏ hơn 1, thì 1 sẽ được sử dụng để Ước tính",
    "estimatedDays": "Ngày ước tính",
    "avgQtySoldTips": "Số lượng đã bán, chu kì bán, bình quân hàng tồn kho bán ra mỗi ngày",
    "avgQtySold": "Số lượng bán ra mỗi ngày",
    "qtySoldTips": "Tổng số hàng tồn kho đã bán ra của Master SKU hiện tại trong chu kì bán hàng được chọn",
    "qtySold": "Số lượng đã bán",
    "safetyStockTips": "Số lượng Tồn kho an toàn của Master SKU hiện tại, ý nghĩa của Tồn kho an toàn phù hợp với danh sách quản lý hàng tồn kho",
    "availableStockTips": "Số lượng Tồn kho có sẵn của Master SKU hiện tại, ý nghĩa của hàng tồn kho có phù hợp với danh sách quản lý hàng tồn kho",
    "inventoryName": "Tên kho hàng",
    "inventorySKU": "MSKU",
    "notification151": "Hiểu Làm thế nào để Sử dụng Tồn kho an toàn",
    "days90": "90 ngày",
    "days60": "60 ngày",
    "days30": "30 ngày",
    "days15": "15 ngày",
    "days7": "7 ngày",
    "salesPeriod": "Chu kỳ bán hàng",
    "outOfStockEstimation": "Bảng dự báo hết hàng",
    "profitTips": "Chú ý: Lợi nhuận chỉ thống kê từ đơn hàng đã hoàn thành, và thời gian hỗ trợ lọc không vượt quá 30 ngày",
    "commissionSettingTips": "Lưu ý: Hiện đã hỗ trợ đặt hoa hồng cửa hàng từ Tokopedia và cửa hàng Shopify  đã liên kết, dữ liệu này chỉ được sử dụng để thống kê dữ liệu thu nhập, dữ liệu phí hoa hồng ở kênh khác dựa trên API kênh",
    "netProfitIncomeStatementTips2": "Lợi nhuận ròng / Báo cáo thu nhập = Thu nhập hoạt động + Thu nhập phi hoạt động - Chi phí hoạt động - Chi phí phi hoạt động - Chi phí khác",
    "netProfitIncomeStatementTips": "Lợi nhuận ròng / Báo cáo kinh doanh = Thu nhập hoạt động + Thu nhập ngoài kinh doanh - Chi phí hoạt động - Chi phí ngoài kinh doanh - Chi phí khác",
    "netProfitIncomeStatement": "Lợi nhuận ròng / Báo cáo kinh doanh",
    "outboundStockTips": "Ngày ghi nhớ nhập hàng tồn kho = Số lượng hàng nhập kho vào ngày đã chọn * Chi phí",
    "outboundStock": "Biến động hàng tồn kho (giảm xuống)",
    "refundFeeTips": "Ngày tạo đơn hàng = Tổng Phí hoàn lại trong ngày đã chọn",
    "refundFee": "Phí hoàn lại",
    "cashbackTips": "Ngày tạo đơn hàng = Tổng Tiền hoàn trong ngày đã chọn",
    "cashback": "Tiền hoàn (khuyến mãi)",
    "commissionFeeTips": "Ngày tạo đơn hàng = Tổng Phí hoa hồng trong ngày đã chọn",
    "commissionFee": "Phí hoa hồng",
    "serviceFeeTips": "Ngày tạo đơn hàng = Tổng phí dịch vụ trong ngày đã chọn",
    "serviceFee": "Phí dịch vụ",
    "otherExpensesTips": "Chi phí khác = Phí dịch vụ + Phí hoa hồng + Cashback + Phí hoàn lại + Biến động hàng tồn kho (giảm xuống)",
    "otherExpenses": "Chi phí khác",
    "taxTips": "Ngày tạo đơn hàng = Tổng số thuế trong ngày đã chọn",
    "insuranceTips": "Ngày tạo đơn hàng = Tổng phí bảo hiểm trong ngày đã chọn",
    "insurance": "Phí bảo hiểm",
    "nonOperatingExpensesTips": "Chi phí ngoài kinh doanh = Phí bảo hiểm + Thuế",
    "nonOperatingExpenses": "Chi phí ngoài kinh doanh",
    "costOfGoodSoldTips": "Ngày ghi nhớ hàng tồn kho = Số đơn hàng giao hàng vào ngày đã chọn * Chi phí",
    "costOfGoodSold": "Chi phí bán hàng",
    "operatingExpenseTips": "Chi phí hoạt động = Chi phí bán hàng",
    "operatingExpense": "Chi phí hoạt động",
    "inboundStockTips": "Ngày ghi nhớ hàng tồn kho = Số lượng hàng tồn kho tăng vào ngày đã chọn * Chi phí",
    "inboundStock": "Nhập thêm hàng tồn kho",
    "rebateTips": "Ngày tạo đơn hàng = Tổng chiết khấu trong ngày đã chọn",
    "rebate": "Chiết khấu",
    "nonOperatingIncomeTips": "Thu nhập ngoài kinh doanh = Phí ship",
    "nonOperatingIncome": "Thu nhập ngoài kinh doanh",
    "coinTips": "Ngày tạo đơn hàng = Tổng số coin trong ngày đã chọn",
    "coin": "Xu",
    "promotionTips": "Ngày tạo đơn hàng = Tổng số tiền Voucher trong ngày đã chọn",
    "promotion": "Khuyến mãi khác",
    "discountTips": "Ngày tạo đơn hàng = Tổng số tiền khuyến mãi trong ngày đã chọn",
    "discount": "Giảm giá",
    "salesAmountTips": "Ngày tạo đơn hàng = Tổng số tiền và Subtotal của ngày đã chọn",
    "salesAmount": "Doanh thu bán hàng",
    "operatingIncomeTips": "Thu nhập = Số tiền bán hàng - Giảm giá - Khuyến mãi + Coins + Chiết khấu",
    "operatingIncome": "Thu nhập hoạt động",
    "incomeStatement": "Bảng lợi nhuận",
    "refundOrdersTips": "Tổng số đơn hàng có trạng thái hoàn",
    "validBuyersTips": "Tổng số người mua trong đơn hàng hợp lệ",
    "buyersTips": "Tổng số người mua trong đơn hàng hợp lệ",
    "salesRateTips": "Tỷ lệ Doanh thu sản phẩm hiện tại trên Tổng doanh thu",
    "grossSalesTips": "Tổng số tiền",
    "qtySalesRateTips": "1. Thống kê Doanh số Sản phẩm: Doanh số của một sản phẩm / Tổng doanh số sản phẩm\n2. Thống kê bán hàng theo danh mục: Doanh số của 1 danh mục / Tổng doanh số\n3. Thống kê doanh số bán hàng tại cửa hàng: Doanh số của 1 cửa hàng / Tổng doanh số của tất cả cửa hàng\n4. Thống kê bán hàng theo kênh: Doanh số của 1 kênh / Tổng doanh số của tất cả kênh",
    "qtySalesTips": "Tổng sản phẩm bán ra trong Đơn hàng Hợp lệ",
    "validOrdersTips": "Đơn hàng Đã thanh toàn / Chờ Ship / Đang giao hàng / Đã nhận",
    "totalOrdersTips": "Tổng số lượng sản phẩm tương ứng với đơn hàng",
    "refundOrders": "Đơn hàng Hoàn",
    "validBuyers": "Người mua hợp lệ",
    "buyers": "Tổng số người mua",
    "salesRate": "Tỷ lệ doanh thu",
    "grossSales": "Tổng Thu nhập",
    "qtySalesRate": "Số lượng bán ra (%)",
    "qtySales": "Tổng doanh số",
    "validOrders": "Đơn hàng hợp lệ",
    "validOrderNumStatisticsTips": "Đơn hàng Đã thanh toàn / Chờ Ship / Đang giao hàng / Đã nhận",
    "totalProductNumStatisticsTips": "Tổng số sản phẩm có trong tất cả các đơn hàng, dữ liệu trùng lặp theo cửa hàng + tên sản phẩm Nếu mua cùng lúc 2 sản phẩm của cùng một loại sản phẩm trong một đơn hàng thì tổng số sản phẩm sẽ là 1",
    "totalValidBuyerTips": "Tổng người mua trong đơn hàng hợp lệ, lọc trùng lặp dựa vào số điện thoại di động",
    "shopNameTips": "Tên cửa hàng mà sản phẩm thuộc về",
    "mskuTips": "Thông tin Master SKU đã đồng bộ với thuộc tính tương ứng của sản phẩm trong đơn hàng, và sẽ không thay đổi theo cập nhật thông tin sản phẩm",
    "skuTips": "Thông tin SKU đã đồng bộ với thuộc tính tương ứng của sản phẩm trong đơn hàng, và sẽ không thay đổi theo cập nhật thông tin sản phẩm",
    "variationTips": "Tên Thuộc tính đã được đồng bộ tới sản phẩm trong đơn hàng và sẽ không thay đổi theo thông tin cập nhật sản phẩm",
    "productNameTips": "Tên cửa hàng mà sản phẩm thuộc về",
    "totalValidBuyerStatisticsTips": "Tổng người mua trong đơn hàng hợp lệ, lọc trùng lặp dựa vào số điện thoại di động",
    "totalQtySoldStatisticsTips": "Tổng số sản phẩm bán ra trong một đơn hàng hợp lệ Nếu mua đồng thời hai sản phẩm của cùng một loại sản phẩm trong một đơn hàng thì tổng doanh số bán ra sẽ là 2",
    "searchPlaceholder": "Vui lòng nhập nộp dung",
    "pleaseSelectCategory": "Vui lòng chọn danh mục",
    "productVariantsSalesStatistics": "Thống kê Thuộc tính sản phẩm bán hàng",
    "action73": "Thao tác",
    "datesPerPage": "Lượng dữ liệu của mỗi trang",
    "setPage": "Chọn số trang",
    "time69": "Thời gian",
    "notification68": "Nhắc nhở: Vui lòng Cài đặt phạm vi số trang của dữ liệu tải xuống",
    "variationName": "Tên Thuộc tính",
    "fieldDescription": "Đoạn mô tả",
    "detial": "Chi tiết",
    "action61": "Thao tác",
    "downloadFailed": "Tải xuống thất bại",
    "active": "Hợp lệ",
    "notification49": "File sẽ được hiển thị trong danh sách lịch sử xuất, và chỉ được lưu giữ trong 15 ngày, vui lòng bấm vào để tải xuống ngay",
    "returnRateTips": "Tổng đơn hàng hoàn / Tổng số đơn hàng",
    "returnRate": "Tỷ lệ hoàn",
    "refundOrderNumTips": "Tổng số đơn hàng với trạng thái hoàn",
    "refundOrderNum": "Đơn hàng hoàn",
    "repurchaseRateTips": "Phần trăm khách hàng có nhiều hơn 1 giao dịch",
    "repurchaseRate": "Tỷ lệ khách hàng quay lại",
    "grossSalesBuyer": "Tổng doanh thu / Tổng người mua",
    "customerAverageTransaction": "Đơn giá khách hàng",
    "buyerNumTips": "Tổng người mua trong đơn hàng hợp lệ",
    "buyerNum": "Người mua hợp lệ",
    "totalBuyerNumTips": "Tổng người mua của các đơn hàng",
    "totalBuyerNum": "Tổng số người mua",
    "saleRateTips": "1. Thống kê Doanh số Sản phẩm: Doanh số của một sản phẩm / Tổng doanh số sản phẩm\n2. Thống kê bán hàng theo danh mục: Doanh số của 1 danh mục / Tổng doanh số\n3. Thống kê doanh số bán hàng tại cửa hàng: Doanh số của 1 cửa hàng / Tổng doanh số của tất cả cửa hàng\n4. Thống kê bán hàng theo kênh: Doanh số của 1 kênh / Tổng doanh số của tất cả kênh",
    "saleRate": "Phần trăm doanh thu",
    "grossIncomeTips": "Tổng số tiền mỗi đơn hàng trong các đơn hàng hợp lệ, bao gồm chi phí vận chuyển và phí khác",
    "grossIncome": "Tổng doanh thu",
    "qtySaleRateTips": "1. Thống kê Doanh số Sản phẩm: Doanh số của một sản phẩm / Tổng doanh số sản phẩm\n2. Thống kê bán hàng theo danh mục: Doanh số của 1 danh mục / Tổng doanh số\n3. Thống kê doanh số bán hàng tại cửa hàng: Doanh số của 1 cửa hàng / Tổng doanh số của tất cả cửa hàng\n4. Thống kê bán hàng theo kênh: Doanh số của 1 kênh / Tổng doanh số của tất cả kênh",
    "qtySaleRate": "Tỷ lệ bán ra",
    "qtySaleNumTips": "Tổng số hàng đã bán trong các đơn hàng hợp lệ",
    "qtySaleNum": "Tổng số hàng bán ra",
    "effectiveRateTips": "Đơn hàng hợp lệ / Tổng số đơn hàng",
    "effectiveRate": "Tỷ lệ hợp lệ",
    "validOrderNumTips": "Đơn hàng Đã thanh toán / Sẵn sàng giao / Đang giao / Đã nhận",
    "validOrderNum": "Đơn hàng hợp lệ",
    "totalOrderNumTips": "Tổng số lượng sản phẩm tương ứng với đơn hàng",
    "totalOrderNum": "Tổng đơn hàng",
    "totalProductNumTips": "Lên kệ / Xuống kệ / Ban hết / Cấm bán",
    "totalProductNum": "Tổng sản phẩm",
    "totalValidBuyer": "Tổng người mua hợp lệ",
    "netIncomeTips": "Tổng giá trị của sản phẩm trong đơn hàng hợp lệ",
    "netIncome": "Tổng thu nhập bán hàng",
    "notification15": "Số lượng bán ra trong tổng số đơn hàng hợp lệ",
    "totalQtySold": "Tổng số lượng bán ra",
    "lowToHigh": "Từ thấp đến cao",
    "highToLow": "từ cao đến thấp",
    "storeSaleReport": "Bảng thống kê bán hàng của cửa hàng",
    "channelSaleReport": "Bảng thống kê kênh bán hàng",
    "revenuePerBuyerTips": "Tổng số tiền của đơn hàng / Số khách hàng",
    "revenuePerBuyer": "Doanh thu trên mỗi khách hàng",
    "monetaryUnit": "Đơn vị tiền tệ",
    "productOriginalPriceIncome": "Tổng thu nhập bán hàng: Tổng giá trị của sản phẩm trong đơn hàng hợp lệ; Tổng thu nhập: Tổng số tiền mỗi đơn hàng trong các đơn hàng hợp lệ, bao gồm chi phí vận chuyển và phí khác",
    "totalNetIncomeTips": "Tổng giá trị của sản phẩm trong đơn hàng hợp lệ",
    "totalNetIncome": "Tổng thu nhập bán hàng",
    "exportPdf": "Xuất đến PDF",
    "exportToPDF": "Xuất đến PDF",
    "countTips": "Số lượng bán ra không được nhỏ hơn 0",
    "selectTime": "Chọn thời gian",
    "exportExcel": "Xuất đến Excel",
    "exportTime": "Thời gian Xuất",
    "exportHistory": "Lịch sử Xuất",
    "title": "Chủ đề",
    "moveEnd": "Cuối",
    "moveTop": "Đầu",
    "moveDown": "Chuyển xuống",
    "moveUp": "Chuyển lên",
    "customField": "Tùy chọn",
    "taxTotalAmountTips": "Tổng tiền Thuế trong đơn hàng được tạo vào ngày hiện tại",
    "taxTotalAmount": "Tổng tiền Thuế",
    "systemShippingTotalAmountTips": "Tổng tiền ship trên hệ thống trong đơn hàng được tạo vào ngày hiện tại",
    "systemShippingTotalAmount": "Tổng tiền ship trên hệ thống",
    "serviceTotalAmountTips": "Tổng Phí Dịch vụ trong đơn hàng được tạo vào ngày hiện tại",
    "serviceTotalAmount": "Tổng Phí Dịch vụ",
    "commissionTotalAmountTips": "Tổng Phí Hoa hồng trong đơn hàng được tạo vào ngày hiện tại",
    "commissionTotalAmount": "Tổng Phí Hoa hồng",
    "insuranceTotalAmountTips": "Tổng tiền bảo hiểm trong đơn hàng được tạo vào ngày hiện tại",
    "insuranceTotalAmount": "Tổng tiền Bảo hiểm",
    "buyerTotalNumTips": "Tổng số khách hàng trong đơn hàng mà ngày tạo bằng ngày hiện tại (loại trừ trùng lặp bằng số điện thoại)",
    "buyerTotalNum": "Số khách hàng",
    "cancelOrderRateTips": "Đơn hàng Hủy/ tồng đơn hàng",
    "cancelOrderRate": "Tỷ lệ hủy",
    "cancelOrderTotalAmountTips": "Tổng số tiền (bao gồm phí ship/giảm giá v.v) của đơn hàng hủy trong ngày hiện tại, không tính số tiền đơn hàng hủy một phần",
    "cancelOrderTotalAmount": "Tổng tiền Hủy",
    "cancelOrderTotalNumTips": "Tổng số đơn hàng đã hủy trong ngày hiện tại, không tính đơn hàng hủy một phần",
    "cancelOrderTotalNum": "Đơn hủy",
    "returnOrderRateTips": "Đơn hàng Hoàn/tổng đơn hàng",
    "returnOrderRate": "Tỷ lệ Hoàn",
    "returnOrderTotalAmountTips": "Tổng số tiền (bao gồm phí ship/giảm giá v.v) của đơn hàng hoàn mà ngày tạo bằng ngày hiện tại, không tính số tiền đơn hàng hoàn một phần",
    "returnOrderTotalAmount": "Tổng số tiền hoàn",
    "returnOrderTotalNumTips": "Tổng số đơn hàng đã hoàn thành công trong ngày hiện tại, không tính đơn hàng hoàn một phần",
    "returnOrderTotalNum": "Tổng số Đơn Hoàn",
    "paymentTotalAmountTips": "Tổng số tiền của đơn hàng Đã thanh toán/Chờ ship/Đang ship/Đã nhận mà ngày tạo bằng ngày hiện tại (bao gồm phí ship, v.v)",
    "paymentTotalAmount": "Tổng số tiền của đơn hàng hợp lệ",
    "paymentTotalNumTips": "Tổng số đơn hàng Đã thanh toán/Chờ ship/Đang ship/Đã nhận mà ngày tạo bằng ngày hiện tại",
    "paymentTotalNum": "Đơn hàng Hợp lệ",
    "shippingTotalAmountTips": "Tổng tiền ship trong đơn hàng được tạo vào ngày hiện tại",
    "shippingTotalAmount": "Tổng tiền ship",
    "goodsTotalAmountTips": "Tổng số tiền của sản phẩm trong đơn hàng được tạo hiện tại (bao gồm Đang ship/giảm giá, v.v)",
    "goodsTotalAmount": "Tổng số tiền Sản phẩm",
    "orderTotalAmountTips": "Tổng số tiền của đơn hàng được tạo trong thời gian hiện tại (bao gồm Đang ship/giảm giá, v.v)",
    "orderTotalAmount": "Tổng số tiền Đơn hàng",
    "orderTotalNumTips": "Tổng số đơn hàng trong khoảng thời gian đáp ứng các điều kiện hàng khó bán, bao gồm tất cả các trạng thái",
    "orderTotalNum": "Tổng số Đơn hàng",
    "dayTips": "Ngày tạo Đơn hàng",
    "yesterday": "Hôm qua",
    "totalRefundAmountTips": "Ngày hoàn hàng bằng tổng số tiền hoàn  trong ngày hiện tại.Các đơn hàng bị trả lại một phần không được bao gồm trong thống kê số tiền hoàn lại (liên quan đến Lazada)",
    "totalRefundAmount": "Tổng số tiền hoàn",
    "returnOrderTips": "Tổng đơn hàng Hoàn đã được lọc",
    "shippingFeeTips": "Tổng phí ship của đơn hàng mà Ngày tạo bằng ngày hiện tại (tổng phí ship trong chi tiết đơn hàng)",
    "shippingFee": "Phí ship",
    "totalOrderAmountTips": "Ngày tạo bằng tổng tiền đơn hàng vào ngày hiện tại (bao gồm phí vận chuyển, v.v.)",
    "totalOrderAmount": "Tổng tiền Đơn hàng",
    "totalOrderNumberTips": "Tổng số đơn hàng mà Ngày tạo bằng  ngày hiện tại (tất cả trạng thái đơn hàng)",
    "totalOrderNumber": "Tổng số đơn hàng",
    "trendChart": "Biểu đồ xu hướng",
    "theSizeVariantDoesNotSupport": "Không hỗ trợ người đột Size thêm hình ảnh",
    "onlyShowAuthorizedStores": "Chỉ hiển thị các cửa hàng đã ủy quyền",
    "untickWithOneClick": "Bỏ chọn bằng một cú nhấp chuột",
    "pleaseUntickAndReoperate": "Vui lòng bỏ chọn và thao tác lại",
    "thereAreXExpireddeactivatedStoresIn": "Có {X} cửa hàng đã hết hạn/bị vô hiệu trong ủy quyền hiện tại",
    "failedToSave": "Lưu thất bại",
    "shortDescription": "Mô tả ngắn",
    "Item": "Mục",
    "shippingFeeTips2": "Ngày tạo đơn hàng = Tổng phí ship đã thanh toán và chưa thanh toán trong ngày đã chọn",
    "whenTheProductsInTheChannel": "Khi các sản phẩm trong hàng kho kênh được tự động tạo/liên kết với MSKU, chúng sẽ tự động được thêm vào kho hệ thống được ghép nối, nhưng bạn vẫn cần tự vận hành kho để đảm bảo tồn kho luôn chính xác nhất",
    "whenThereIsAStockChange": "Khi có sự thay đổi tồn kho trong kho hàng hệ thống được ghép nối, giá trị tồn kho sẽ được đẩy đến kho hàng kênh kịp thời",
    "channelWarehouseId": "ID kho hàng kênh",
    "forChannelProductsThatAreBound": "Đối với các sản phẩm kênh đã liên kết với kho hàng, sau khi MSKU được tạo, chúng sẽ tự động được thêm vào kho liên kết.",
    "forMultiwarehouseChannelsAndChannelProducts": "Đối với các kênh đa kho và các sản phẩm kênh liên kết với kho, sau khi liên kết MSKU, nó sẽ tự động được thêm vào kho được liên kết.",
    "forMultiwarehouseChannelsWithWarehouseboundChannel": "Đối với các kênh đa kho có sản phẩm kênh liên kết với kho, MSKU sẽ tự động được thêm vào kho liên kết sau khi tạo.",
    "existXwarehouse": "Có {X} kho hàng",
    "amountUnit": "Số tiền: (Đơn vị:",
    "thePriceOfTokoWillBe": "Giá Toko sẽ được cập nhật vào từng kho của kênh.",
    "priceAndStockWillBeUpdated": "Giá và tồn kho sẽ được cập nhật đến kho hàng mặc định",
    "dueToTheDelayInChannel": "Do dữ liệu cập nhật kênh bị chậm nên sẽ mất vài giây để dữ liệu đồng bộ được cập nhật, vui lòng kiên nhẫn đợi.",
    "theStoreWhereTheOrderIs1": "Cửa hàng nơi đặt hàng đã mở kênh đa kho, vui lòng liên kết kho Ginee và vận hành",
    "theStoreWhereTheOrderIs": "Cửa hàng đặt hàng đã mở kênh đa kho, chỉ hỗ trợ đổi sang kho Ginee ràng buộc {XXXX}",
    "theWarehouseXxxxAlreadyHasA": "Nhà kho đã có một nhiệm vụ kéo, có thể xem trong hồ sơ kéo. Hãy chờ đến khi nhiệm vụ hoàn thành.",
    "youNeedToIntegrateAstore": "Bạn cần liên kết cửa hàng {x} trước khi có thể sử dụng thanh toán {y}",
    "pleaseSelectTheStoreaccountTo": "Vui lòng chọn cửa hàng/tài khoản {x} để thanh toán",
    "theProductsXxxxInTheOrder": "Đơn hàng {XXXX} sản phẩm kênh {XXXX} được phân phối đến kho {XXXX} theo quan hệ liên kết giữa kho hàng kênh và kho hệ thống.",
    "theCurrentProductsWarehouseIsEmpty": "Kho hàng sản phẩm hoàn toàn trống rỗng. Đầu tiên, nhập vào KPPP.",
    "xIsOutOfStockIn": "{X} Thiếu hàng tồn kho trong {X}",
    "shopeebukalapaktokopediaOrdersUseGineeTemplateFor": "Đơn hàng Shopee/Bukalapal ?tokopediasử dụng mẫu của Ginee cho việc in vận đơn",
    "buyerPhone": "Số điện thoại khách hàng",
    "xNewWarehouses": "{X}nhà kho mới,ID nhà kho:{X}",
    "editStock": "Chỉnh tồn kho",
    "warehouseIsEmpty": "Nhà kho trống rỗng",
    "dueToTheLimitationsOfThe": "Do có giới hạn của hãng Keeppa API, những sản phẩm này không thể vận hành ở Ginee,Vui lòng thêm hàng tồn kho vào hệ thống Keeppack",
    "pleaseFindTheBindingEmailIn": "Hãy đến Trung tâm mua sắm Kopete (Thiết lập ứng dụng bên thứ ba) hoặc tìm thư điện tử trong hộp thư của bạn, đầu tiên chấp nhận yêu cầu kết nối",
    "created": "Tạo",
    "allstocktakingissuccessful": "Kiểm kê tất cả thành công",
    "partialstocktaking": "Kiểm kê một phần",
    "allstocktakingfailed": "Kiểm kê tất cả thất bại",
    "someexecute": "Một phần được thực hiện",
    "faileds": "Thất bại",
    "thePullTaskHasBeenCreated": "Nhiệm vụ kéo tạo thành công",
    "executed": "Đã hoàn thành",
    "keeppackWarehouse": "Kho Keeppack",
    "unbindMsku": "Hủy liên kết MSKU",
    "PullRecord": "Thành tích kéo kho",
    "thisStoreHasBeenConnectedTo": "Cửa hàng này đã được kết nối với Tài khoản Ginee khác vui lòng ngắt kết nối cửa hàng này khỏi các Tài khoản Ginee bên dưới trước khi thao tác:",
    "ManualInbound": "Nhập kho thủ công",
    "stocktakingInbound": "Nhập kho thặng dư",
    "NormalInbound": "Nhập kho thông thường",
    "notPushed": "Chưa đẩy",
    "partComplete": "Hoàn thành một phần",
    "partInvalid": "Vô hiệu một phần",
    "tripartiteSingleNumber": "Mã đơn {warehouse}",
    "storageInformation": "Thông tin Nhập kho",
    "types": "Loại",
    "plannedInbound": "Lập kế hoạch nhập kho",
    "receiptCost": "Chi phí nhập kho",
    "withAttachments": "Có phụ kiện",
    "automaticallyCreateMsku": "Tự động tạo MSKU",
    "createMskuManually": "Tạo MSKU bằng tay",
    "currentlyThirdPartyWarehouseSkuCode": "Trước mắt chị tạm thời hỗ trợ mã SKU kho bên thứ ba và mã MSKU phù hợp mới có thể liên kết ràng buộc",
    "warehouseKeeppackWarehouseInboundManagement": "Kho / Kho Keeppack /Quản lý Phiếu nhập kho",
    "warehouseKeeppackWarehouseOutboundManagement": "Kho / Kho Keeppack /Quản lý Phiếu xuất kho",
    "warehouseKeeppackWarehouse": "Kho / Kho Keeppack /Thành tích kéo kho",
    "theCurrentCommodityWarehouseIsEmpty": "Kho hàng hiện tại trống rỗng, hoàn thành thư viện nhập khẩu tại KPPP",
    "YouWillNotBeAble": "1. Bạn sẽ không thể xem thông tin đặt hàng cho cửa hàng này.\n2. Bạn không thể tìm thấy cửa hàng do mô-đun cài đặt cửa hàng.",
    "temperatureControlled": "Kiểm soát nhiệt độ",
    "standard": "Tiêu chuẩn",
    "storageType": "Kiểu lưu trữ",
    "enableWare": "Xác nhận bật chế độ đa kho chứ?",
    "afterEnablingTheMultiwarehouseModeAnd": "Sau khi kích hoạt chế độ đa kho và đăng tải sản phẩm, chế độ đa kho sẽ không thể tắt",
    "activateMultiwarehouse": "Bật đa kho",
    "turnOffMultiwarehouse": "Tắt đa kho",
    "wantTo": "Xác nhận tắt chế độ đa kho chứ?",
    "afterTheMultiwarehouseModeIsTurned": "Sau khi tắt chế độ đa kho, thông tin đa kho và tồn kho sẽ bị xóa",
    "kongfuWarehouse": "Kho hàng KONGFU",
    "useTheStockInformationOfThe": "Sử dụng thông tin tồn kho của kho KONGFU để kích hoạt tự động đồng bộ nhằm nâng cao hiệu quả quản lý",
    "afterTheAuthorizationIsSuccessfulYou": "Sau khi ủy quyền thành công, bạn có thể sử dụng dịch vụ fulfillment KONGFU để quản lý hàng hóa xuất nhập kho",
    "afterClickingTheAuthorizeButtonKongfus": "Sau khi nhấn nút [Đến ủy quyền], thông tin kho hàng của KONGFU sẽ được kéo về cho bạn, vui lòng chọn kho hàng cần thiết để hoàn tất quá trình ủy quyền",
    "PleaseContactKongfuCustomerService": "2. Vui lòng liên hệ bộ phận CSKH của KONGFU để lấy khóa bí mật",
    "ItIsRecommendedToFill": "1. Khuyến khích điền các thông tin đã thỏa thuận với KONGFU về tên tài khoản và tên người gửi hàng",
    "informationDescription": "Mô tả thông tin",
    "consignorName": "Tên người gửi hàng",
    "acuntName": "Tên tài khoản",
    "completeTheRepositoryAuthorizationConfirmationIn": "Hoàn thành xác nhận ủy quyền kho trong quản lý cấu hình của KONGFU",
    "afterSelectingAndSavingSubjectTo": "Sau khi chọn và lưu, vui lòng liên hệ với dịch vụ CSKH ngoại tuyến của KONGFU để hoàn tất việc xem xét ủy quyền kho hàng và quay lại hệ thống Ginee, trong [Ủy quyền - Danh sách cửa hàng] theo yêu cầu của quy trình ủy quyền KONGFU",
    "pleaseSelectTheWarehouseInformationThat": "Vui lòng chọn thông tin kho hàng cần ủy quyền và hoàn tất quy trình ủy quyền trực tuyến",
    "pleaseSelectTheWarehouseThatNeeds": "Vui lòng chọn kho hàng cần ủy quyền",
    "applyForAuthorization": "Xin ủy quyền",
    "afterTheAuthorizationIsCompletedPlease": "Sau khi hoàn tất kiểm tra, vui lòng đảm bảo quay lại hệ thống Ginee và hoàn thành xác nhận ủy quyền kho hàng trong phần quản lý cấu hình của [Ủy quyền - Danh sách cửa hàng] KONGFU",
    "pleaseContactKongfuCustomerServiceOffline": "Vui lòng liên hệ với bộ phận CSKH ngoại tuyến của KONGFU để hoàn tất việc xem xét ủy quyền kho hàng",
    "approvalStatus": "Tình trạng kiểm tra",
    "reasonForFailureTheThirdParty": "Lý do thất bại: Kho hàng bên thứ ba đã từ chối đơn đăng ký ủy quyền kho, vui lòng liên hệ với dịch vụ CSKH của kho bên thứ ba để xác nhận",
    "timeToOperateAuthorizedWarehouseInformation": "Thời gian vận hành thông tin kho ủy quyền tại Ginee",
    "theTimeToUpdateWarehouseInformation": "Thời gian cập nhật thông tin kho trong Ginee",
    "consignorCode": "Mã bên gửi hàng",
    "theSystemWillAutomaticallyUpdateThe": "Hệ thống sẽ tự động cập nhật trạng thái kiểm tra của kho bên thứ ba cho bạn mỗi giờ, nếu bạn cần xem kết quả kiểm tra trong thời gian thực, bạn có thể nhấp vào [Cập nhật trạng thái kiểm tra] theo cách thủ công để xem",
    "passed": "Đã thông qua",
    "theWarehouseAuthorizationApplicationInformationHas": "Thông tin ứng dụng ủy quyền kho hàng đã được thông báo thành công cho kho bên thứ ba",
    "afterConfirmationTheSystemWillCall": "Sau khi xác nhận, hệ thống sẽ lấy API KONGFU để bạn khởi tạo đơn xin cấp phép kho, sau khi xác nhận đơn, vui lòng liên hệ với nhân viên CSKH của KONGFU để hoàn tất duyệt xét cấp phép, hệ thống sẽ tự động đồng bộ trạng thái xét duyệt cho bạn mỗi giờ. Nếu bạn cần kiểm tra trạng thái, vui lòng thao tác [Cập nhật Trạng thái Kiểm tra] trên trang quản lý cấu hình để nhận kết quả kiểm tra",
    "areYouSureToInitiateA": "Xác nhận khởi tạo ứng dụng ủy quyền kho hàng cho kho của bên thứ ba chứ?",
    "initiateAuthorizationApplication": "Bắt đầu ứng dụng ủy quyền",
    "theListOnlyShowsUnauthorizedWarehouse": "Danh sách chỉ hiển thị thông tin kho chưa ủy quyền, vui lòng liên hệ với bộ phận CSKH ngoại tuyến của Kongfu để hoàn tất xác nhận kiểm toán sau khi xác nhận",
    "selectAuthorizedWarehouse": "Chọn kho ủy quyền",
    "gineeWillPullTheWarehouseInformation": "Ginee sẽ thông qua API KONGFU lấy thông tin kho hàng trong KONGFU, vui lòng chọn ủy quyền kho hàng cần thiết và liên hệ với bộ phận CSKH của KONGFU để hoàn tất việc xem xét ủy quyền kho hàng mới",
    "tipsa": "Lời nhắc ấm áp",
    "startToPullTheLatestWarehouse": "Bắt đầu kéo thông tin kho mới nhất của KONGFU, đợi giao diện KONGFU trả về thông tin, vui lòng kiên nhẫn chờ",
    "updateApprovalStatus": "Cập nhật trạng thái đánh giá",
    "confirmAuthorizedWarehouseIdXxxx": "Xác nhận ID kho được ủy quyền: XXXX , XXXX",
    "authorizationStatusXxxx": "Trạng thái ủy quyền: {XXXX}",
    "addWarehousesForApplicationAuthorization": "Thêm ủy quyền 3 kho, ID kho: XXXX , XXXX",
    "noMoreThanXxxCharacters": "Không vượt quá {X} ký tự",
    "customsWeight": "Trọng lượng khai báo",
    "customsDeclarationAmount": "Số tiền khai báo",
    "customsCode": "Mã hải quan",
    "customsEnglishName": "Khai báo Tên tiếng Anh",
    "customsDeclarationChineseName": "Khai báo Tên tiếng Trung",
    "ifTheWarehouseForThisDelivery": "Nếu kho giao hàng này là KONGFU và hàng hóa cần khai báo hải quan, vui lòng điền thông tin khai báo hải quan",
    "customsDeclarationInformation": "Thông tin hải quan",
    "theProductDeclarationInformationIsMissing": "Thông tin khai báo hàng hóa còn thiếu, vui lòng chuyển sang trang chỉnh sửa sản phẩm Chính để hoàn thiện và hoàn tất thao tác",
    "mskuDoesNotConformToThe": "MSKU không phù hợp với thông số kỹ thuật giao diện kho của bên thứ ba, vui lòng chỉnh sửa đổi và thao tác",
    "theProductNameDoesNotConform": "Tên sản phẩm không phù hợp với thông số kỹ thuật giao diện kho của bên thứ ba, vui lòng chỉnh sửa trước khi thao tác",
    "TheMskuDoesNotExceed": "Số MSKU không vượt quá 50 ký tự và chỉ hỗ trợ số, chữ cái và ký hiệu đặc biệt \"-\"",
    "ProductNameDoesNotExceed": "Tên sản phẩm không quá 200 ký tự",
    "channelRequirements": "Yêu cầu kênh",
    "ifTheProductsDeliveredToThe": "Nếu hàng về kho lần này là đợt hàng đi đầu tiên hoặc cần vận chuyển qua biên giới Trung Quốc, quý khách vui lòng chọn Khai báo và điền thông tin khai báo",
    "needToDeclare": "Cần khai báo",
    "noDeclarationRequired": "Không cần khai báo",
    "totalNumberOfSkus": "Tổng số SKU",
    "awb": "Số AWB",
    "outboundAmount": "Số tiền xuất kho",
    "outboundQuantity": "Số lượng xuất kho",
    "outboundInformation": "Thông tin xuất kho",
    "doNotEnableAutomaticBinding": "Không bật liên kết tự động",
    "thisFieldIsRequiredWhenThe": "Trường thông tin bắt buộc khi giao diện kho của bên thứ ba yêu cầu trả hàng vào kho",
    "returnTrackingNumber": "Trả mã vận đơn",
    "thirdpartyWarehouseTransactionReturn": "Phản hồi giao dịch kho bên thứ ba",
    "manualSync": "Đồng bộ thủ công",
    "timingSynchronization": "Đồng bộ định kì",
    "reasonReceivedAThirdpartyWarehouseInterface": "Nguyên do: Đã nhận lời nhắc từ API kho của bên thứ ba{x}",
    "actualInbound": "Nhập kho thực tế",
    "theOrderIsInconsistentWithThe": "Đơn hàng không phù hợp với quốc gia nơi đặt kho hàng, vui lòng thay đổi kho hàng kịp thời",
    "theChannelInterfaceCannotGenerateThe": "API kênh không thể tạo vận đơn và kho hiện tại không thể thực hiện hợp đồng. Vui lòng thay đổi kho kịp thời",
    "mskuIsNotAddToThe": "MSKU không thêm vào kho. Xin bổ sung trước.",
    "rejected": "Đã từ chối",
    "outboundListOutboundListInThe": "3. Phiếu xuất kho: Phiếu xuất kho trong kho, bao gồm mã số phiếu, trạng thái, chi tiết",
    "inboundListInboundListInThe": "2. Phiếu nhập kho: Phiếu nhập kho trong kho, bao gồm mã số phiếu, trạng thái, chi tiết",
    "productsProductsInTheWarehouseIncluding": "1. Sản phẩm: Sản phẩm trong kho, bao gồm chi tiết như giá trị tồn kho và giá trị trong kho",
    "agreeToAuthorizeGineeToObtain": "Đồng ý cho phép Ginee lấy các thông tin sau trong kho hàng",
    "editMultiwarehouseStock": "Chỉnh sửa tồn kho đa kho hàng",
    "totalStock": "Tổng tồn kho",
    "add2": "Thêm",
    "openMultiwarehouses": "Bật đa kho",
    "closeMultipleWarehouses": "Tắt đa kho",
    "noteHighSpeedPrintingDoesNot": "Lưu ý: In tốc độ cao không hỗ trợ in vận đơn tùy chỉnh của Ginee (Tokopedia tạm chỉ hỗ trợ in vận đơn tùy chỉnh của Ginee)",
    "massEditStockIsAvailableFor": "Chỉnh sửa tồn kho hàng loạt chỉ áp dụng đối với tồn kho của một kho duy nhất",
    "agreecontinueAuthorization": "Đồng ý, tiếp tục ủy quyền",
    "theStockAndProductInformationOf": "Thông tin tồn kho và sản phẩm của kho Fastock sẽ được tự động đồng bộ 2 chiều nhằm nâng cao hiệu quả quản lý",
    "afterClickingTheAuthorizeButtonYou": "Sau khi nhấn nút [Đến ủy quyền], bạn có thể sử dụng dịch vụ fulfillment Fastock để quản lý sản phẩm xuất nhập kho",
    "PleaseContactTheWarehouseService": "2. Vui lòng liên hệ nhà cung cấp dịch vụ kho  hàng để lấy mã khách hàng/mã kho/AppKey/SecretKey",
    "BeSureToSelectThe": "1. Đảm bảo chọn chính xác trang web dịch vụ, nếu không sẽ ủy quyền thất bại",
    "ifYouNeedToChangeWarehouse": "Nếu cần thay đổi thông tin kho, vui lòng đến Quản lý cấu hình",
    "theFastockFulfillmentWarehouseHasBeen": "Kho fulfillment Fastock đã được tạo thành công và bạn có thể bắt đầu sử dụng",
    "received": "Nhận lúc",
    "warehouseBreadcrumbProduct": "Kho / Kho {warehouseName} / Thông tin hàng hóa",
    "warehouseBreadcrumbInbound": "Kho / Kho {warehouseName} / Quản lý Phiếu nhập kho",
    "warehouseBreadcrumbOutbound": "Kho / Kho {warehouseName} / Quản lý Phiếu xuất kho",
    "warehouseBreadcrumbPullRecord": "Kho / Kho {warehouseName} / Thành tích kéo kho",
    "unboundStore": "Cửa hàng chưa liên kết",
    "storeAuthorizationHasExpired": "Ủy quyền cửa hàng đã hết hạn",
    "hongkong": "Hong Kong",
    "whetherItIsTurnedOnOr": "Có bật hay không",
    "theSkuRemovalQuantityCannotExceed": "Số lượng SKU loại bỏ không thể vượt quá số lượng đơn hàng",
    "productCodeRepeatedModification": "Mã sản phẩm chỉnh sửa nhiều lần",
    "cantDeleteAllDetails": "Không thể xóa tất cả các thông tin chi tiết",
    "theOutboundListHasBeenAllocated": "Phiếu xuất kho đã được phân bổ hoàn tất, không thể chỉnh sửa",
    "failedToRequestGxb": "Yêu cầu gxb thất bại",
    "theDetailsOfTheOutboundList": "Chi tiết của phiếu xuất kho đã được phân bổ hoàn tất, không thể chỉnh sửa",
    "outboundListDetailsDontExist": "Chi tiết phiếu xuất kho không tồn tại",
    "theMergingSkuOfInboundList": "SKU kết hợp của phiếu nhập kho bất thường, vui lòng liên hệ quản trị viên",
    "theSkuAcceptanceQuantityOfThe": "Số lượng chấp nhận SKU của phiếu nhập kho đã thay đổi, vui lòng thử lại",
    "orderDetailsCannotBeChecked": "Chi tiết đơn hàng không thể kiểm tra được",
    "productInformationDoesNotExist": "Thông tin sản phẩm không tồn tại",
    "returnAwbNumberCannotBeEmpty": "Số AWB hoàn trả không thể trống",
    "theInboundListHasBeenAccepted": "Phiếu nhập kho này đã được chấp nhận",
    "theInboundListDoesntExist": "Phiếu nhập kho không tồn tại",
    "multipleLastMileScacCodesCannot": "Nhiều mã SCAC đơn cuối cùng không thể xuất hiện trong cùng một đơn hàng",
    "theDomesticAwbNumberUnderThe": "Không thể lặp lại số AWB nội địa trong cùng một đơn hàng",
    "homeDeliveryDoesntNeedToFill": "Giao tận nhà không cần điền thông tin người nhận",
    "weightCanOnlyBeAPositive": "Trọng lượng chỉ có thể là một số nguyên dương",
    "theLastMilesTrackingNumberIs": "Số AWB của đơn cuối cùng không được trống và mã SCAC của đơn cuối cùng không thể trống",
    "theLabelPdfIsntEmptyAnd": "Vận đơn PDF không trống và mã đơn cuối cùng không thể trống",
    "theSelectedWarehouseDoesNotSupport": "Kho hàng đã chọn không hỗ trợ doanh nghiệp này",
    "lastMileInformationlogisticsProductCannotBe": "Thông tin đơn  cuối/sản phẩm vận chuyển không thể trống cùng lúc",
    "documentNumberCannotBeEmpty": "Số tài liệu không thể trống",
    "productQuantityCanOnlyBeA": "Số lượng sản phẩm chỉ có thể là số nguyên dương",
    "theDestinationCountryMustBeConsistent": "Quốc gia giao hàng đến phải thống nhất với quốc gia người nhận",
    "recipientCountryprovincecityParameterIsMissing": "Thông tin Quốc gia/Tỉnh/Thành phố của người nhận bị thiếu",
    "theOrderHasBeenProcessedAnd": "Đơn hàng đã được xử lý và không thể chỉnh sửa",
    "matchesMultipleOrders": "Khớp nhiều đơn hàng",
    "notWeighedWithoutWeight": "Không cân không trọng lượng",
    "theDocumentNumberAndLastMile": "Số tài liệu và mã đơn cuối cùng trống",
    "lastLegNumberAlreadyExists": "Mã đơn cuối cùng đã tồn tại",
    "orderShipped": "Đơn hàng đã được giao",
    "pickupPersonInformationCannotBeEmpty": "Thông tin người lấy hàng không thể trống",
    "theOrderHasBeenShippedAnd": "Đơn hàng đã được giao và không thể hủy",
    "senderCountryprovincecityParameterIsMissing": "Tham số Quốc gia/Tỉnh/Thành phố của Người gửi bị thiếu",
    "logisticsChannelDoesntSupport": "Kênh vận chuyển không hỗ trợ",
    "thisStatusDoesntSupportModification": "Trạng thái này không hỗ trợ chỉnh sửa",
    "pleaseCheckThePickupMethod": "Vui lòng kiểm tra phương thức lấy hàng",
    "outboundTypeNotMapped": "Kiểu xuất kho không khớp",
    "pleaseCheckTheScac": "Vui lòng kiểm tra SCAC",
    "productcombinedProductQuantityMustBe": "Số lượng sản phẩm/sản phẩm kết hợp phải >0",
    "documentNumberAlreadyExists": "Số tài liệu đã tồn tại",
    "pleaseCheckTheReceiver": "Vui lòng kiểm tra người nhận",
    "warehouseCodeDoesNotExist": "Mã kho hàng không tồn tại",
    "codAmountAndCodCurrencyCan": "Phải điền cả hai số tiền COD và tiền tệ COD",
    "pleaseCheckTheBusinessType": "Vui lòng kiểm tra loại hình kinh doanh",
    "failedToCheckDestinationCountryprovinceregion": "Kiểm tra quốc gia/tỉnh/khu vực giao hàng thất bại",
    "logisticsChannelTrackingFailed": "Kiểm tra vận chuyển kênh thất bại",
    "noPermissionToOperateThisOrder": "Không có quyền xử lý đơn hàng này",
    "thePlatformHasAuthorizedTheConsignor": "Nền tảng đã ủy quyền cho người gửi hàng",
    "tokenError": "Lỗi token",
    "noValidAuthorizedWarehouse": "Không có kho hàng có ủy quyền hợp lệ",
    "platformMismatch": "Nền tảng không khớp",
    "noConsignorPermission": "Không có quyền người gửi hàng",
    "theTokenDoesntMatch": "Token không khớp",
    "thePlatformConsignorDoesntMatch": "Người gửi hàng tại nền tảng không khớp",
    "pleaseCheckIfThereIsError": "Vui lòng kiểm tra xem có lỗi trong kho hàng không",
    "authorizationHasBeenApprovedPleaseDont": "Cấp quyền đã hoàn tất, vui lòng không lặp lại thao tác",
    "noConsignorOnThisPlatform": "Không có người gửi hàng tại nền tảng này",
    "userHasNo": "Người dùng không có quyền đối với kho hàng này",
    "userHasNoAuthorizationForThis": "Người dùng không có quyền đối với người gửi hàng này",
    "userDoesntHaveConsignorsAuthorization": "Người dùng không có quyền của người gửi hàng",
    "warehouseAuthorizationRecordDoesNotExist": "Bản ghi ủy quyền kho hàng không tồn tại",
    "duplicateAuthorizationApplication": "Đơn xin ủy quyền trùng lặp",
    "warehouseDoesntExist": "Kho hàng không tồn tại",
    "consignorDoesntExists": "Người gửi hàng không tồn tại",
    "platformInformationVerificationFailedPleaseContact": "Xác minh thông tin nền tảng thất bại, vui lòng liên hệ với quản trị viên",
    "consignorAlreadyExists": "Người gửi hàng đã tồn tại",
    "noTrackingNumber": "Không có số AWB",
    "hasTrackingNumber": "Có số AWB",
    "trackingNumberStatus": "Trạng thái AWB",
    "rulesBetween": "{name} nên trong khỏang {range}!",
    "waitingOutbound": "Chờ xuất kho",
    "productPriceShouldBeBetween": "Giá sản phẩm phải nằm trong khoảng 1-1.000.000.000",
    "pleaseMoveToTheMasterProduct": "Vui lòng chuyển sang trang chỉnh sửa sản phẩm Chính để hoàn thành và hoàn tất thao tác",
    "kongfuRequiresThatTheLabelMust": "*KONGFU yêu cầu đơn chuyển phát nhanh phải kết thúc bằng đuôi \".pdf\". Nếu ủy quyền thành công, điều đó có nghĩa là bạn đồng ý việc KONGFU có thể nhận đơn chuyển phát nhanh có hiệu lực dài hạn",
    "outboundListNumber": "Mã phiếu xuất kho",
    "inventoryChangesDueToManualOperation": "Thay đổi hàng tồn kho do vận hành thủ công của nhân viên kho",
    "totalDiscountedPrice": "Tổng giá chiết khấu",
    "inventoryChangesDueToWarehousePersonnel": "Tồn kho thay đổi do nhân viên kho thủ công hủy nhập kho và thay đổi số lượng xuất kho",
    "thirdpartyWarehouseInboundAdjustment": "Điều chỉnh kho lưu trữ bên thứ ba",
    "sinceTheKongfuApiLimitsThe": "Vì sự hạn chế chi phí của API sẽ được hạn chế vào gạch 99,99, lưu ý rằng nếu chi phí vượt quá 99,99,99, chúng tôi sẽ sử dụng 99,999",
    "pleaseCheckTheDocumentType": "Vui lòng kiểm tra kiểu tài liệu.",
    "tryNow": "Thử đi",
    "talkLater": "Nói chuyện sau",
    "congratulationsYouWillGetADay": "Chúc mừng, bạn sẽ được thử nghiệm miễn phí một hệ thống trọn vẹn trong 7 ngày",
    "theTrialVersionHasExpiredAnd": "Phiên bản thử nghiệm đã hết hạn, gói này đã được thả tự do. Nếu bạn cần nâng cấp, hãy mua một gói",
    "theTrialPackageWasUpdatedSuccessfully": "Bản cập nhật gói dùng thử đã thành công",
    "warehouseWarehousenameWarehouseActive": "Kho / Kho {warehouseName} / {active}",
    "theSalesTypeNameIsEmpty": "Tên kiểu bán hàng bị trống",
    "generalInformation": "Thông tin chung",
    "imageForPos": "Hình ảnh cho POS",
    "onlineAttributes": "Thuộc tính trực tuyến",
    "refurbished": "Đã tân trang lại",
    "insertProductWeightIncludingThePackaging": "Chèn trọng lượng sản phẩm gồm cả bao bì để tính tỷ lệ vận chuyển.",
    "maximumMax": "Tối đa: {max}",
    "kilogramKg": "Kilogram (kg)",
    "gramGr": "Gram (gr)",
    "insertProductDimensionAfterPackagingTo": "Chèn kích thước sản phẩm sau khi đóng gói để tính tỷ lệ vận chuyển.",
    "activatingThePreorderFeatureAllowsYou": "Kích hoạt tính năng Pre-Order cho phép bạn kéo dài thời gian xử lý đơn hàng lên đến 90 ngày (bao gồm cả cuối tuần và ngày lễ).",
    "productIsReadyStock": "Sản phẩm đã về hàng",
    "productNeedLongerShippingProcess": "Sản phẩm cần quá trình vận chuyển lâu hơn.",
    "processingTime": "Thời gian xử lý",
    "mokaOrderBetweenDays": "Đơn hàng Moka: giữa 1-90 ngày",
    "mokaProductImageTooltip": "Thêm tối đa 12 ảnh. Kích thước tối thiểu 500x500px và tối đa 8MB. Định dạng: PNG, JPEG, BMP.",
    "Inventory": "Kiểm kê",
    "cost": "Giá",
    "isMultiplePricePerSalesType": "Nhiều giá cho mỗi kiểu bán hàng",
    "trackStock": "Kiểm tra tồn kho",
    "alert": "Cảnh báo",
    "alertAt": "Cảnh báo lúc",
    "itemStockCanNotBeChanged": "Tồn kho sản phẩm không thể thay đổi sau khi lưu, vì vậy hãy đảm bảo thông tin chính xác nha!",
    "thisItemCanNotBeTracked": "Sản phẩm này không thể được theo dõi vì tồn kho kiểm kê không được theo dõi.",
    "avgCostCanNotBeChanged": "Không thể thay đổi chi phí trung bình sau khi lưu, vì vậy hãy đảm bảo thông tin chính xác nha!",
    "trackCogs": "Theo dõi giá vốn hàng bán (COGS)",
    "avgCost": "Giá trung bình",
    "theSynchronizationIsCompleteAndThe": "Đồng bộ hoàn tất và các kiểu bán hàng sau không còn hiệu lực",
    "Empty": "Trống",
    "domesticLogisticsTrackingNumberCannotBe": "Số AWB trong nước không thể trống",
    "consignorDoesntMatch": "Người gửi hàng không khớp",
    "documentNumberDoesNotExist": "Số tài liệu không tồn tại",
    "trackingNumberAlreadyExists": "Số AWB đã tồn tại",
    "packageAlreadyExists": "Gói đã tồn tại",
    "paketTambahanTidakBolehLebihBesar": "Gói bổ sung không thể lớn hơn tổng số lượng của sản phẩm",
    "theOrderHasBeenShippedNo": "Đơn hàng đã được giao, không thể thêm gói bổ sung",
    "productInformationCantBeEmpty": "Không được để trống thông tin sản phẩm",
    "theresEmptyDataInTheCombination": "Có dữ liệu trống trong số gói thông tin nhóm hàng hóa",
    "declaredAmount": "Số tiền khai báo",
    "declaredWeight": "Trọng lượng khai báo",
    "menuProductMoka": "Sản phẩm / Moka POS",
    "menuProductMokaAdd": "Sản phẩm / Moka POS /  Thêm sản phẩm",
    "menuProductMokaEdit": "Sản phẩm / Moka POS / Chỉnh sửa sản phẩm",
    "stockInOutAlert": "Bản ghi tồn kho vào/ra sẽ hiển thị tất cả các thay đổi về tồn kho trong 3 tháng qua",
    "theStockCanBePushedTo": "Tồn kho chỉ có thể được đẩy sang Moka sau khi bật nút theo dõi tồn kho",
    "youHaveADayFullfeaturedTrial": "You have a 7-day full-feature trial for free!",
    "youAreUsingTheFreeVersion": "Now you are using the free version of Ginee. \nIf you need functions such as warehouse management and inventory synchronization, you can apply once for a 7-day free trial of Ginee's full functions. \nAre you sure you want to apply for a trial? (Expected to take effect within 15 minutes after applying)",
    "Cname": "Tên kênh bán",
    "thisStaffAccountHasNoPermission": "Tài khoản nhân viên này không có quyền truy cập vào hệ thống, vui lòng liên hệ với người bán/quản trị viên để thêm tài khoản nhân viên vào hệ thống và phân quyền",
    "makeSureTheSwitchIsTurned": "Hãy chắc chắn rằng nút đã được bật khi điều chỉnh tồn kho, nếu không sẽ điều chỉnh thất bại",
    "printTaskCompletionTime": "Thời gian hoàn thành tác vụ in",
    "printTaskCreationTime": "Thời gian tạo tác vụ in",
    "noteLazadaAndBlibliOrdersDo": "Không hỗ trợ cài đặt trạng thái đóng gói cho đơn hàng Lazada & Blibli",
    "prefixMatch": "Khớp tiền tố",
    "prefixSearch": "tìm kiếm tiền tố",
    "GineeAccountCanOnlyAuthorize": "1 tài khoản Ginee chỉ ủy quyền được cho 1 tài khoản Accurate, nếu quý khách có nhu cầu thêm vui lòng liên hệ bộ phận CSKH",
    "matchingFailedThePreviousMatchingOption": "Khớp thất bại: không tìm thấy tùy chọn đã khớp trước đó, vui lòng xác nhận xem tùy chọn đã bị xóa trong Accurate chưa",
    "itemPriceCredit": "hạng mục Giá Tín dụng",
    "freeShippingMaxFee": "Phí khuyến mãi miễn phí vận chuyển",
    "paymentFee": "Phí thanh toán",
    "lazpicklaztopCommission": "Hoa hồng Lazpick/LazTop",
    "platformRequirementsTheDescriptionLengthMust": "Yêu cầu sàn: độ dài mô tả phải từ 100 đến 3000 ký tự",
    "currentlyBumped": "Đang ghim lên đầu",
    "numberOfBumpProducts": "Số sản phẩm ghim lên đầu",
    "bumpFixedSupportMaximumProducts": "Ghim cố định tối đa 5 sản phẩm",
    "bumpfixed": "Ghim cố định lên đầu",
    "bumpByTurns": "Ghim lần lượt lên đầu",
    "setAsBumpFixed": "Đặt thành ghim cố định",
    "setAsBumpByTurns": "Đặt thành ghim lần lượt",
    "tipsYouCanAddUpTo": "Mẹo: Bạn có thể thêm lên đến 30 sản phẩm, hãy ưu tiên đồng bộ thông tin sản phẩm trong cửa hàng",
    "editingInformationSuchAsStockAnd": "Không hỗ trợ chỉnh sửa thông tin như tồn kho và giá mua của sản phẩm kết hợp",
    "failedToUploadImagePleaseReupload": "Đăng tải hình ảnh thất bại, vui lòng đăng tải lại hình ảnh và thử lại",
    "solutionSteps": "Bước giải quyết",
    "addBinding": "Thêm quan hệ liên kết",
    "pleaseClickToRedistribute": "Vui lòng nhấn phân bổ lại",
    "pleaseAddBindingRelationship": "Vui lòng thêm quan hệ liên kết",
    "pleaseEnableDistributionSettings": "Vui lòng bật cài đặt phân bổ",
    "distributionSettingsArentEnabled": "Cài đặt phân bổ không được bật",
    "pleaseRestoreTheOrder": "Vui lòng khôi phục đơn hàng",
    "ordersThatHaveBeenPlacedOn": "Các đơn hàng đã được gác lại, không tham gia khóa hoặc khấu trừ tồn kho",
    "problemCauses": "Nguyên nhân vấn đề",
    "pleaseCreateAnInboundListAnd": "Vui lòng tạo phiếu nhập kho và hoàn tất việc nhập kho",
    "pleaseAdjustTheShippingWarehouse": "Vui lòng điều chỉnh quy tắc giao hàng",
    "pleaseCompleteTheInboundInThe": "Vui lòng hoàn tất việc nhập kho của kho hàng bên thứ ba",
    "pleaseCreateAnInboundList": "vui lòng tạo phiếu nhập kho",
    "mskuInXWarehouseYOut": "MSKU {y} trong kho {y} đã hết hàng",
    "theOrderStatusOfTheX": "Trạng thái đơn hàng của kênh {x} là: {y} không hỗ trợ tạo phiếu xuất kho",
    "currentlyDoesNotSupportTheCreation": "Hiện không hỗ trợ tạo phiếu xuất kho cho kênh {y}",
    "theXThirdpartyWarehouseDoesNot": "Kho của bên thứ ba {x} hiện không hỗ trợ tạo phiếu xuất kho cho quốc gia {y}",
    "theXThirdpartyWarehouseDoesntCurrently": "Kho của bên thứ ba {x} hiện không hỗ trợ tạo phiếu xuất kho cho kênh {y}",
    "bumpFixed": "Ghim cố định lên đầu",
    "serviceFeeMapping": "Khớp phí dịch vụ",
    "platformFulfillmentFeeMapping": "Khớp phí fulfillment nền tảng",
    "platformRebateMapping": "Khớp chiết khấu nền tảng",
    "voucherSellerMapping": "Khớp voucher người bán",
    "shippingDifferenceCashlessMapping": "Khớp chênh lệch phí vận chuyển (không tiền mặt)",
    "shippingFeeMapping": "Khớp phí vận chuyển",
    "shippingPriceSetting": "Cài đặt phí vận chuyển",
    "taxSetting": "Cài đặt thuế",
    "salesInvoiceSetting": "Cài đặt Hóa đơn Bán hàng",
    "storeMapping": "Khớp cửa hàng",
    "warehouseMapping": "Khớp kho hàng",
    "database": "Cơ sở dữ liệu",
    "newCompleteOrdersAfterTheSt": "\"Đơn hàng hoàn tất\" mới sau ngày 1 của tháng",
    "newCompleteOrderAfterMappingStore": "\"Đơn hàng hoàn tất\" mới sau khi khớp cửa hàng",
    "afterEnablingIfAccurateHasStock": "Sau khi bật, nếu Accurate có thay đổi về tồn kho:\n1. Đẩy đến Ginee theo thời gian thực và sửa đổi tồn kho Ginee;\n2. Hỗ trợ kéo thủ công giá trị tồn kho mới nhất của Accurate trên danh sách tồn kho.",
    "stockSyncRules": "Quy tắc Đồng bộ Tồn kho",
    "mappingTime": "Thời gian khớp",
    "branch": "Chi nhánh",
    "chartOfAccounts": "Sơ đồ Tài khoản",
    "pleaseCompleteAtLeast": "Vui lòng hoàn tất ít nhất 1 lệnh khớp cửa hàng. Sau khi khớp cửa hàng thành công, đơn hàng hoàn tất của cửa hàng sẽ tự động tạo Hóa đơn bán hàng và đẩy lên Accurate",
    "gineeWarehouseCanOnly": "1 kho hàng Ginee chỉ có thể khớp với 1 kho Accurate, vui lòng hoàn tất ít nhất 1 lệnh khớp kho hàng",
    "enabledByDefault": "Bật mặc định",
    "autoCreateRulesForSalesInvoice": "Tự động tạo quy tắc for Hóa đơn Bán hàng",
    "syncFromAccurateToGinee": "Đồng bộ từ Accurate đến Ginee",
    "syncFromGineeToAccurate": "Đồng bộ từ Ginee đến Accurate",
    "finance": "Tài chính",
    "mapFailed": "Khớp thất bại",
    "notMapped": "Chưa khớp",
    "mapped": "Đã khớp",
    "stockChange": "Thay đổi tồn kho",
    "pushMapMsku": "Đẩy & Khớp MSKU",
    "repushStock": "Đẩy lại tồn kho",
    "upToCharactersAndOnly": "Tối đa 50 ký tự và chỉ hỗ trợ: a-z, A-Z, 0-9, \"(\", \")\", \"-\", \".\", \"_\", \"/\" và Đơn giá phải >=1",
    "allUncreatedAndUnmappedMskusCurrently": "Tất cả các MSKU chưa được tạo và chưa từng được thêm vào ở hiện tại và sau này",
    "afterItIsEnabledAllMskus": "Sau khi được bật, tất cả các MSKU được khớp trong kho Ginee sẽ được đẩy sang kho Accurate, các SKU mới sẽ được tạo tự động trong Accurate và các SKU hiện có sẽ tự động khớp với Accurate",
    "mappingRules": "Khớp quy tắc",
    "autoPushMap": "Tự động Đẩy & Khớp",
    "thisSkuAlreadyExists": "SKU này đã tồn tại",
    "unitCostMustBe": "Đơn giá phải >=1",
    "salesInvoice": "Hóa đơn Bán hàng",
    "syncStock": "Đồng bộ Tồn kho",
    "theStoreIsMissingMappingInformation": "Cửa hàng thiếu thông tin để khớp (Kho hàng, Chi nhánh, Sơ đồ tải khoản, Khách hàng)",
    "source": "Nguồn",
    "associatedOrderNumber": "Mã đơn hàng liên quan",
    "itIsRecommendedToConvertTo": "Khuyến khích chuyển đổi thành số tiền mà đơn vị tiền tệ được thiết lập trong hệ thống",
    "theCurrencySymbolIsBasedOn": "The currency symbol is based on the currency set in the system.\nThe amount is the average cost price of inbound product",
    "bulkrepush": "Đẩy lại hàng loạt",
    "alasanGagalPushGudangPihakKetiga": "Lý do đẩy cho kho bên thứ 3 thất bại",
    "noShelf": "Kệ hàng mặc định",
    "plannedQuantityTip": "điền số lượng sản phẩm xuất kho dự kiến",
    "plannedQuantityTipOld": "Số lượng xuất kho dự kiến: điền số lượng sản phẩm xuất kho dự kiến",
    "selectLocationTip": "Chọn vị trí của SKU xuất kho",
    "exportSelectedOrderDetail": "Chi tiết phiếu xuất kho-Đã chọn",
    "exportAllOrderDetail": "Chi tiết phiếu xuất kho-Tất cả",
    "exportSelectedOrderList": "Danh sách phiếu xuất kho-Đã chọn",
    "exportAllOrderList": "Danh sách phiếu xuất kho-Tất cả",
    "mskuAndBarcode": "MSKU/Mã vạch",
    "confirmToSetAsInvalid": "Xác nhận cài đặt vô hiệu?",
    "bulkSubmitSuccess": "Đã xong, vui lòng làm mới trang để xem kết quả xử lý",
    "bindFailed": "Liên kết thất bại, vui lòng chọn lại vị trí",
    "bindSuccess": "Liên kết thành công",
    "autoCreateAndBind": "Tự động tạo vị trí và liên kết SKU",
    "warehouseName2": "Tên Kho hàng",
    "warehouseCode": "Mã Kho hàng",
    "quantityAdded": "Số lượng bổ sung",
    "sureToDelete": "Bạn có chắc xóa không?",
    "selectLocation": "Chọn vị trí SKU trong kho",
    "enterQuantity": "Nhập số lượng của lần nhập kho này",
    "createLocationTips": "Nếu bạn cần tạo hàng loạt vị trí mới, bạn có thể tạo chúng trong phần Quản lý vị trí.",
    "locationManagement": "Quản lý Vị trí",
    "warehouseStockColumnName": "Tổng tồn kho",
    "stockNotEnough": "Thiếu hàng tồn kho, không thể xuất kho",
    "externalOrderNo": "Đơn hàng",
    "numSelectedWithValue": "Đã chọn {val}",
    "column3TipOld": "Cột 3: vị trí (tùy chọn) nếu không điền, thì chọn vị trí của Tồn kho có sẵn lớn nhất",
    "column2TipOld": "Cột 2: số lượng (bắt buộc)",
    "column1TipOld": "Cột 1: SKU (bắt buộc)",
    "column3Tip": "Cột 3: Kệ hàng (tuỳ chọn) nếu không điền, sẽ sử dụng kệ mặc định.",
    "column2Tip": "Cột 2: số lượng (bắt buộc)",
    "column1Tip": "Cột 1: SKU (bắt buộc)",
    "copyTip": "Sao chép SKU, chi phí, số lượng, kệ hàng, vị trí từ excel đến ô vắn bản, có thể nhanh chonhs nhận diện, gửi",
    "printDetails": "In chi tiết sản phẩm",
    "copyTip2": "Cột 1 SKU, cột 2 Số lượng, cột 3 Vị trí",
    "logisticsNumberLengthError": "Mã vận chuyển tối đa 200 kí tự",
    "duplicateLocationError": "Trùng thông tin vị trí, cùng một vị trí để hàng chỉ được gửi một hàng.",
    "setPushRatio": "Cài đặt kho vận chuyển của cửa hàng - Cài đặt kho vận chuyển của cửa hàng - nếu bạn có nhiều cửa hàng, bạn có thể cài đặt liên kết kho hàng cho mỗi cửa hàng, cũng có thể cài đặt tỷ lệ đẩy hàng tồn kho cho từng cửa hàng",
    "ifOnlyOneWarehouse": "Nếu bạn chỉ có 1 kho hàng, cũng không cần liên kết vị trí để hàng, thì khi tạo sản phẩm chính, hệ thống sẽ tự động giúp bạn tạo một vị trí để hàng cho mỗi SKU, có thể trực tiếp sử dụng.",
    "addProductsToLocation": "Chi tiết kho hàng tồn kho - Sau khi thiết lập kệ hàng, bạn có thể cho hàng lên kệ, trên trang chi tiết kho hàng tồn kho, bạn bấm vào \\\"Thêm hàng vào vị trí\\\" để liên kết hàng với vị trí để hàng. Sau khi liên kết xong, bạn có thể thực hiện Quản lý hàng tồn kho cho hàng hóa ở các vị trí này.",
    "setUpLocation": "Quản lý vị trí hàng hóa - hàng hóa được đặt trên kệ trong kho, bạn cần thiết lập kệ hàng cho kho ở phần quản lý vị trí hàng hóa",
    "setUpWarehouse": "Quản lý kho hàng - bạn cần cài đặt kho hàng của mình trong chức năng Quản lý kho hàng",
    "helpShippingSettings": "Cài đặt kho hàng vận chuyển",
    "helpStockDetails": "Chi tiết kho hàng tồn kho",
    "helpLocationManage": "Quản lý vị trí",
    "helpWarehouseManage": "Quản lý kho hàng",
    "warehouseManagementIntro": "Giới thiệu chức năng liên quan đến Quản lý kho hàng",
    "moreThanPlannedError": "Đã vượt quá số lượng xuất kho dự kiến",
    "INVALID": "Đã vô hiệu",
    "PART_INVALID": "Vô hiệu một phần",
    "COMPLETE": "Hoàn thành",
    "PART_COMPLETE": "Hoàn thành một phần",
    "PENDING": "Chờ xuất kho",
    "CREATE": "Đang xử lý",
    "changeWarehouseAlert": "Cần thêm lại thông tin SKU khi thay đổi kho hàng, vui lòng xác nhận",
    "processResult": "Trạng thái xử lý",
    "complete": "Hoàn thành",
    "pushComplete": "Đẩy hàng thành công",
    "pushing": "Đang đẩy hàng",
    "workingStatusCANCEL_FAIL": "Hủy thất bại",
    "workingStatusCANCEL_SUCCESS": "Hủy thành công",
    "workingStatusCANCELED": "Đã hủy",
    "workingStatusCANCEL": "Đã hủy",
    "workingStatusOUTBOUND_DONE": "Đã xuất kho",
    "workingStatusCANCELING": "Đang hủy",
    "workingStatusWAITING_PICKUP": "Chờ lấy hàng",
    "workingStatusPACKED": "Đã đóng gói",
    "workingStatusSORTING_AND_REVIEWING": "Sắp xếp và xem xét",
    "workingStatusPICKING_PROCESS": "Đang lấy hàng",
    "workingStatusALLOCATED": "Đã phân phối",
    "workingStatusRECEIVED": "Đã nhận",
    "workingStatusSYNC_FAIL": "Đồng bộ thất bại",
    "workingStatusSYNC_SUCCESS": "Đồng bộ thành công",
    "workingStatusCREATE": "Đang xử lý",
    "workingStatus": "Trạng thái xử lý",
    "express": "Chuyển phát nhanh",
    "editOutboundList": "Chỉnh sửa Phiếu xuất kho",
    "defaultDelivery": "Mặc định",
    "selfPickup": "Tự lấy",
    "outboundListDetail": "Phiếu xuất kho chi tiết",
    "failedLineTip": "Thất bại (Hàng này không thể thêm vào Phiếu xuất kho)",
    "ifMoreThanStock1": "Nếu số lượng SKU lớn hơn Tồn kho có sẵn, thì gửi dựa vào Tồn kho có sẵn lớn nhất",
    "ifTwoColumns1": "Nếu chỉ có hai cột, thì dựa vào SKU + Phân tích số lượng",
    "copyTipOld": "Sao chép SKU, số lượng, vị trí, phân tích Ginee từ excel",
    "outboundListInvalid": "Vô hiệu Phiếu xuất kho",
    "outboundAll": "Xuất kho tất cả",
    "outboundListOutbound": "Xuất kho Phiếu xuất kho",
    "outboundManagement": "Quản lý Phiếu xuất kho",
    "scanInstruction": "Bạn có thể quét mã vạch bằng máy quét và nhấn Enter, nếu phân tích thành công thì số lượng hàng xuất kho thực tế +1",
    "receivedProduct": "Sản phẩm nhập kho",
    "goBack": "Trở về",
    "addToOutboundList": "Thêm vào Phiếu xuất kho",
    "row": "Hàng",
    "operationSuccessful": "Thành công",
    "editSuccess": "Chỉnh sửa thành công",
    "identify": "Nhận diện",
    "identifyResults": "Kết quả Nhận diện",
    "ifMoreThanStock": "Nếu số lượng SKU lớn hơn Tồn kho có sẵn, thì gửi dựa vào Tồn kho có sẵn lớn nhất",
    "ifTwoColumns": "Nếu chỉ có hai cột, thì dựa vào SKU + Phân tích số lượng",
    "submit": "Gửi",
    "directOutbound": "Xuất hàng trực tiếp",
    "locationStockNotEnough": "Thiếu vị trí Tồn kho có sẵn",
    "locationAvailableStock": "Vị trí Tồn kho có sẵn",
    "warehouseAvailableStock": "Kho Tồn kho có sẵn",
    "note": "Ghi chú",
    "plannedTotalAmount": "Tổng số tiền dự kiến",
    "unitPrice": "Đơn giá",
    "associatedDocumentNumber": "Mã tài liệu liên kết",
    "refreshStatus": "Làm mới",
    "outboundTime": "Thời gian xuất kho",
    "deliveryMethod": "Phương thức vận chuyển",
    "actualOutboundQty": "Số lượng xuất thực tế",
    "plannedOutboundQty": "Số lượng xuất dự kiến",
    "actualOutboundAmount": "Số tiền thực tế",
    "plannedOutboundAmount": "Số tiền dự kiến",
    "searchContent": "Nội dung tìm kiếm",
    "sortListSelected": "Phiếu sắp xếp - Đã chọn",
    "sortListAll": "Phiếu sắp xếp - Tất cả",
    "pickListSelected": "Phiếu lấy hàng - Đã chọn",
    "pickListAll": "Phiếu lấy hàng - Tất cả",
    "outboundListSelected": "Phiếu xuất kho - Đã chọn",
    "outboundListAll": "Phiếu xuất kho - Tất cả",
    "createOutboundList": "Tạo phiếu xuất kho",
    "greaterThan30Days": "＞30 ngày",
    "lessThan30Days": "≤ 30 ngày",
    "lessThan7Days": "≤ 7 ngày",
    "lessThan3Days": "≤ 3 ngày",
    "listNumber": "Mã đơn",
    "searchType": "Kiểu tìm kiếm",
    "allocateOutboundList": "Phân bổ phiếu xuất kho",
    "documentType": "Loại tài liệu",
    "shippedOld": "Đã xuất kho",
    "documentStatus": "Tình trạng",
    "pleaseSelectData": "Vui lòng chọn dữ liệu cần xuất",
    "basicFeaturesFreeForever": "Free vĩnh viễn",
    "nMinutesRemaining": "Còn {minus} phút",
    "gineeAndAccurateAreMappedBy": "Ginee và Accuracy đã được khớp SKU. Vì Accurate không phân biệt chữ hoa hay thường, nên hãy đảm bảo không có SKU bị trùng trong khi hàng trước khi khớp.",
    "accurateRequiresSkuToHaveA": "Accurate yêu cầu MSKU chứa tối đa 50 ký tự và chỉ hỗ trợ: a-z, A-Z, 0-9, \"(\",\")\", \"-\", \".\", \"_\", \"/\"",
    "otherSettingsAndMapping": "Các cài đặt và lệnh khớp khác",
    "mapping": "Đang khớp",
    "automapSettings": "Cài đặt tự động khớp",
    "pleaseSetAndMapTheCorrect": "Vui lòng cài đặt và khớp thông tin chính xác",
    "basedOnAccurateStock": "Lấy tồn kho Accurate làm chuẩn",
    "basedOnGineeStock": "Lấy tồn kho Ginee làm chuẩn",
    "whenTheSkuOfGineeAnd": "Khi SKU của Ginee và Accurate khớp, nếu tồn kho của 2 hệ thống khác nhau, vui lòng chọn một kho làm tiêu chuẩn, Ginee sẽ tự động đẩy hoặc kéo tồn kho sau khi khớp",
    "startingStock": "Chuẩn bị tồn kho",
    "theSubproductsOfTheCombinedProduct": "Các sản phẩm con trong sản phẩm kết hợp chưa được khớp, vui lòng hoàn thành việc khớp các sản phẩm con trước khi khớp sản phẩm kết hợp",
    "theWarehouseHasMatchedTheAccurate": "Kho đã khớp với Kho Accurate , tồn kho và giá vốn của sản phẩm phải > 0",
    "accurateAuthorizationHasExpiredPleaseReauthorize": "Ủy quyền Accurate đã hết hạn, vui lòng ủy quyền lại!",
    "accurateRequiresInventoryToBe": "Accurate yêu cầu tồn kho và giá vốn phải >0",
    "goToChange": "Đi chỉnh sửa",
    "inboundWhenAddedToWarehouse": "Inbound khi thêm vào kho",
    "youHaveNotCompletedTheInitial": "Bạn chưa hoàn thành cấu hình ban đầu của Chính xác, các tính năng liên quan chỉ có thể được sử dụng sau khi cấu hình",
    "failedToDeleteThisProductHas": "Không xóa được! Sản phẩm này đã được liên kết với một sản phẩm kết hợp, vui lòng xóa sản phẩm kết hợp hoặc hủy liên kết sản phẩm kết hợp trước",
    "noteIfTheWarehouseIsAdded": "Lưu ý: Nếu đầu vào khi được thêm vào kho là kho WMS hoặc kho của bên thứ ba, sau khi đầu vào, bạn cần xác nhận đầu vào trong kho WMS/kho của bên thứ ba để bắt đầu đồng bộ hóa kho thành công",
    "partnerAuthorizationFailed": "Ủy quyền đối tác không thành công",
    "customerMustBeFilled": "khách hàng phải được lấp đầy",
    "itemXSuccessfullySaved": "Đã lưu thành công mục \"{x}\"",
    "unitQtyMustBeFilled": "Số lượng đơn vị phải được điền",
    "itemXNotFoundOrHas": "Mục {x} không tìm thấy hoặc đã bị xóa",
    "accurateConfigurationInformationIsMissingPlease": "Thiếu thông tin Cấu hình chính xác, vui lòng kiểm tra Quản lý cấu hình và thử lại",
    "theWarehouseXWasNotFound": "Kho \"{x}\" không được tìm thấy hoặc đã bị xóa",
    "brazil": "Brazil",
    "taiwan": "Taiwan",
    "savedSuccessfullyThisPageWillClose": "Đã lưu thành công! Trang này sẽ tự động đóng lại",
    "salesInvoicePushRecord": "Bản ghi đẩy hóa đơn bán hàng",
    "orderPushTime": "Thời gian đẩy đơn",
    "orderCompleteTime": "Thời gian hoàn thành đơn",
    "completeTime": "thời gian hoàn thành",
    "updateResult": "Cập nhật kết quả",
    "productManage": "Quản lý sản phẩm",
    "becauseTheNumberOfStoresExceeds": "Số lượng đơn hàng của bạn đã đạt đến giới hạn của gói và không thể sử dụng tính năng này. Vui lòng liên hệ bộ phận CSKH để đăng ký gói mới",
    "theAuthorizationInformationIsFilledIncorrectly": "Thông tin ủy quyền được điền không chính xác, vui lòng tham khảo trung tâm trợ giúp tích hợp và điền lại thông tin",
    "channelItemXMoveOutPromotion": "Cửa hàng:Sản phẩm của{x}:{y} đã chuyển khỏi ID quảng cáo:{z}",
    "xManualReleaseOfReservedPromotion": "{x} Phát hành thủ công cổ phiếu khuyến mãi dành riêng",
    "promotionIdXHasEnded": "Cửa hàng:Sản phẩm của{x}:{y} đã tham gia ID quảng cáo:{z} đã kết thúc",
    "xManuallyEditedReservedPromotionStock": "{x} kho hàng khuyến mãi dành riêng được chỉnh sửa thủ công, được cập nhật từ {y} thành {z}",
    "channelProductXActivityIdY": "Cửa hàng:Sản phẩm của{x}:{y} ID quảng cáo:{z}",
    "unknownSystemException": "Ngoại lệ hệ thống không xác định,  Vui lòng liên hệ bộ phận CSKH",
    "theChannelProductSpecificationCorrespondingTo": "Không tìm thấy đặc tả sản phẩm kênh tương ứng với chương trình khuyến mãi",
    "theChannelProductCorrespondingToThe": "Không tìm thấy sản phẩm kênh tương ứng với chương trình khuyến mãi",
    "hasNoWarehouseInformationAndCannot": "MSKU không có thông tin kho hàng và không thể đặt trước Cổ Phiếu Khuyến Mãi",
    "channelSkuHasNoBoundMsku": "SKU kênh không có MSKU bị ràng buộc, vui lòng chuyển đến danh sách sản phẩm chính để liên kết SKU trước",
    "notCompliantWithPromotionReservation": "Không tuân thủ chính sách đặt trước Khuyến mãi: \"Các khuyến mãi chưa bắt đầu và đang diễn ra sẽ tự động được đặt trước\"",
    "promotionReservationPolicyNotMet": "Không tuân thủ Chính sách đặt trước Khuyến mãi: \"Chỉ dành riêng cho các khuyến mãi đang hoạt động\"",
    "theCategoryOrAttributeInformationIs": "Thông tin danh mục hoặc thuộc tính không chính xác, vui lòng thử lại sau khi đồng bộ hóa danh mục",
    "setUpOrderInvoices": "Lập hóa đơn đặt hàng",
    "orderPacking": "đặt hàng đóng gói",
    "orderDistribution": "phân phối đơn hàng",
    "syncOrder": "đồng bộ hóa thứ tự",
    "theOrderSSuccessfullyPrintedThe": "Đơn hàng [%s] đã in thành công danh sách lấy hàng",
    "theOrderssuccessfullyPrintedThePackingList": "Đơn hàng [%s] đã in danh sách đóng gói thành công",
    "orderSuccessInvoice": "Đơn đặt hàng [%s] đã in hóa đơn thành công",
    "orderSuccessLabel": "Đơn đặt hàng [%s] đã in nhãn thành công",
    "ordersreturnedInboundTheReturnInboundList": "Đơn đặt hàng [%s] được trả lại trong nước, danh sách hàng được trả lại đã được tạo thành công, số danh sách: <a href=\"/erp/main/outbound-manager-detail/%s/SELL\" target=\"_blank\">%s</a>",
    "ordersredistributedSuccessfully": "Lệnh được phân phối lại theo cách thủ công [{0}], vui lòng tham khảo bản ghi hoạt động tiếp theo để biết kết quả",
    "ordersfailedToPushWarehouseForDelivery": "Đơn hàng [%s] không thể chuyển kho để giao hàng, lý do: %s",
    "theOrdersisSuccessfullyPushedToThe": "Đơn hàng [%s] được đẩy thành công vào kho hàng",
    "orderspushWarehouseOperationSuccessfully": "Đặt hàng【%s】Đẩy kho vận hành thành công",
    "theOrderSSuccessfullySetThe": "Đơn đặt hàng [%s] đã đặt hóa đơn thành công",
    "ordersarrangedDeliverySuccessfully": "Đặt hàng [%s] giao hàng thành công",
    "orderspackedSuccessfully": "Đơn hàng [%s] được đóng gói thành công",
    "orderscancelledSuccessfully": "Đơn hàng [%s] đã được hủy thành công",
    "theWarehouseSelectedForProductS": "Kho được chọn cho sản phẩm [%s] trong đơn hàng [%s] được thay đổi từ [%s] thành [%s]",
    "theProductSInTheOrder": "Sản phẩm [%s] trong đơn hàng [%s] được thay thế bằng [%s] và kho hàng được chọn là [%s]",
    "theOrderSDeletesTheGift": "Đơn hàng [%s] xóa quà tặng [%s], số lượng là [%s], kho hàng được chọn là [%s]",
    "addGiftSToOrderS": "Thêm quà tặng [%s] vào đơn hàng [%s], số lượng là [%s], và kho đã chọn là [%s]",
    "theOrderSHasBeenCancelled": "Đơn đặt hàng [{0}] đã bị hủy (Khôi phục đơn hàng)",
    "ordersmarkedAsPutOnHold": "Đơn hàng [{0}] được đánh dấu là \"Đã lưu trữ\"",
    "theOrderSIsMarkedAs": "Đơn hàng [%s] được đánh dấu là hết hàng, lý do: sản phẩm chính [%s] tương ứng với sản phẩm kênh [%s] đã hết hàng",
    "orderSIsMarkedAsOut": "Đơn hàng [%s] được đánh dấu là [Có vấn đề về đơn hàng-Lỗi phân bổ kho hàng], lý do: [%s] không hỗ trợ sử dụng kho thực hiện [%s]",
    "theOrderSIsAPromotion": "Đơn hàng [%s] là đơn hàng khuyến mãi và sản phẩm kênh [%s] được phân bổ vào kho [%s]",
    "theChannelProductNotOutbound": "Sản phẩm kênh [%s] trong đơn đặt hàng [%s] không thể tạo danh sách gửi đi, lý do: sản phẩm chính được liên kết [%s] không có trong kho [%s]",
    "theChannelProductCantGen": "Sản phẩm kênh [%s] trong đơn hàng [%s] không thể tạo danh sách gửi đi, lý do: không có quy tắc kho vận chuyển tương ứng",
    "theChannelProductAllocate": "Sản phẩm Kênh [%s] theo thứ tự [%s], theo Quy tắc Kho Vận chuyển [%s] được phân bổ vào kho [%s]",
    "theChannelProductSInThe": "Đơn hàng [%s] được đánh dấu là [Đơn hàng có vấn đề-MSKU không được ánh xạ]，Sản phẩm của Kênh [%s] không thể tạo danh sách gửi đi, lý do: không được liên kết với Sản phẩm chính",
    "anOutboundListHasBeenCreated": "Một danh sách gửi đi đã được tạo cho đơn đặt hàng [%s], số đơn đặt hàng: <a href=\"/erp/main/outbound-manager-detail/%s/SELL\" target=\"_blank\">%s</a>",
    "theOrderSHasBeenSuccessfully": "Đơn hàng [%s] đã được đồng bộ hóa thành công và trạng thái đơn hàng đã được cập nhật từ [%s] thành [%s]",
    "syncToTheNewOrderS": "Đã đồng bộ với đơn hàng mới [%s], trạng thái đơn hàng là [%s]",
    "salesTaxAmountMustBeBetween": "'salesTax.amount' must be between 0 and 1000000000",
    "incorrectUnitFormat": "Định dạng đơn vị không chính xác, nội dung chỉ được hỗ trợ là \"giờ\", \"ngày trong tuần\", \"ngày\", \"tuần\", \"tháng\"",
    "purchaseDurationMustBeBetween": "Thời lượng mua hàng phải nằm trong khoảng từ 1-365",
    "selectedWarehouse": "Kho đã chọn",
    "addingToRepositoryXFailedReason": "Thêm vào kho lưu trữ \"{x}\" không thành công! Lý do thất bại:",
    "purchaseDurationUnit": "Đơn vị thời lượng mua (Chỉ nội dung được hỗ trợ là \"hour\", \"working day\", \"day\", \"week\", \"month\")",
    "thisCustomerHasOrdersInThe": "Khách hàng này có đơn đặt hàng trong 60 ngày qua, bạn có thể bắt đầu phiên",
    "theProductInboundStockCannotBe": "Sản phẩm trong kho không được âm",
    "theBundledProductHasNotBeen": "Sản phẩm đi kèm chưa được gửi đến kho!",
    "pleaseAddMskuToTheWarehouse": "Vui lòng thêm MSKU vào kho và hoàn thành Inbound",
    "createSalesInvoice": "Tạo hóa đơn bán hàng",
    "beforePushingMatchingCombinedProducts": "Trước khi đẩy và ánh xạ các sản phẩm kết hợp, cần đảm bảo rằng tất cả các sản phẩm kết hợp đã được đẩy và ánh xạ",
    "theSystemWillAutomaticallyPushSales": "Hệ thống sẽ tự động đẩy Hóa đơn bán hàng về Chính xác vào các thời điểm 4:00, 12:00 và 20:00. Nếu cả ba lần thử đều thất bại, nó sẽ không được đẩy lại",
    "ifGineeAndAccurateHaveThe": "Hiện tại chỉ hỗ trợ Cổ phiếu Ginee làm Cổ phiếu ban đầu",
    "thePushedMappedMskuSupports": "MSKU đã đẩy & ánh xạ hỗ trợ bật cổ phiếu đồng bộ hóa và tạm thời chỉ hỗ trợ cổ phiếu của Ginee được đẩy thành Chính xác khi cổ phiếu của Ginee thay đổi",
    "afterSettingTheTaxTypeWill": "Sau khi thiết lập, loại thuế sẽ được bao gồm khi tạo MSKU từ Ginee sang Chính xác",
    "thisMappingCorrespondsToTheService": "Ánh xạ này tương ứng với trường Phí dịch vụ theo thứ tự",
    "thisMappingCorrespondsToTheShipBySys": "Ánh xạ này tương ứng với trường Phí vận chuyển do hệ thống thanh toán theo thứ tự",
    "thisMappingCorrespondsToTheRebate": "Ánh xạ này tương ứng với trường Giảm giá theo thứ tự",
    "thisMappingCorrespondsToTheCommission": "Ánh xạ này tương ứng với trường Phí hoa hồng theo thứ tự",
    "thisMappingCorrespondsToTheShipReb": "Ánh xạ này tương ứng với trường Giảm phí vận chuyển trong đơn hàng",
    "thisMappingCorrespondsToTheShipping": "Ánh xạ này tương ứng với trường Phí vận chuyển trong đơn hàng",
    "thePromotionHasExpiredOrThe": "Khuyến mãi đã hết hạn hoặc loại khuyến mãi không chính xác",
    "invoiceBuyerPhoneNo": "SĐT Người mua trên Hóa đơn",
    "invoiceType": "Loại Hóa đơn",
    "invoiceAddress": "Địa chỉ trên Hóa đơn",
    "invoiceBuyerEmail": "Email Người mua trên Hóa đơn",
    "invoiceBuyerName": "Tên Người mua trên Hóa đơn",
    "upcoming": "Sắp tới",
    "finish": "Hoàn thành",
    "promoTime": "Thời gian khuyến mãi",
    "promotionid": "ID quảng cáo",
    "webstore": "Cửa hàng trên mạng",
    "orderShasBeenMovedToReturned": "Đơn đặt hàng[%s] đã được chuyển sang Trả lại",
    "orderShasBeenMovedToDelivered": "Đơn đặt hàng[%s] đã được chuyển sang Đã giao hàng",
    "orderShasBeenMovedToShipping": "Đơn đặt hàng[%s] đã được chuyển sang Đang vận chuyển",
    "orderShasBeenMovedToTo": "Đơn đặt hàng[%s] đã được chuyển sang Đang chờ đón",
    "orderSisMarkedAsNotPacked": "Đơn hàng [%s] được đánh dấu là \"chưa đóng gói\"",
    "orderSisMarkedAsPacked": "Đơn hàng [%s] được đánh dấu là \"Đã đóng gói\"",
    "ordersmarkedPickingStatus": "Đơn hàng[%s] đã đánh dấu trạng thái lấy hàng",
    "orderSisMarkedAsNotA": "Đơn hàng[%s] được đánh dấu là \"Không phải đơn hàng giả\"",
    "orderSisMarkedAsFakeOrder": "Đơn hàng[%s] bị đánh dấu là \"Đơn hàng giả mạo\"",
    "orderSHasBeenOperatedDelivery": "Đơn hàng[%s] đã được xử lý [Kho giao hàng]",
    "orderSmarkedAsPickingListNot": "Đơn hàng[%s] được đánh dấu là Danh sách chọn hàng chưa được in",
    "orderSmarkedAsPickingListPrinted": "Đơn hàng[%s] được đánh dấu là Đã in danh sách lấy hàng",
    "orderSmarkedAsPackingListNot": "Đơn hàng[%s] được đánh dấu là Danh sách đóng gói chưa in",
    "orderSmarkedAsPackingListPrinted": "Đơn hàng[%s] được đánh dấu là Đã in Danh sách đóng gói",
    "orderSmarkedAsInvoiceNotPrinted": "Đơn đặt hàng[%s] được đánh dấu là Hóa đơn chưa in",
    "orderSmarkedAsInvoicePrinted": "Đơn đặt hàng[%s] được đánh dấu là Đã in hóa đơn",
    "orderSmarkedAsLabelNotPrinted": "Đơn đặt hàng[%s] được đánh dấu là Chưa in nhãn",
    "orderSmarkedAsLabelPrinted": "Đơn đặt hàng[%s] được đánh dấu là nhãn đã in",
    "pleaseConfirmTheInstallationOfGinee": "Vui lòng xác nhận việc cài đặt Ginee trên trang Chính xác và làm mới trang sau khi cài đặt",
    "allActivities": "Tất cả các hoạt động",
    "eventStartTimeAscending": "Thời gian bắt đầu sự kiện tăng dần",
    "eventStartTimeDescending": "Thời gian bắt đầu sự kiện giảm dần",
    "eventEndTimeAscending": "Thời gian kết thúc sự kiện tăng dần",
    "eventEndTimeDescending": "Thời gian kết thúc sự kiện giảm dần",
    "sortEventStartTime": "Sắp xếp thời gian bắt đầu sự kiện",
    "thisOperationOnlyMarksWhetherThe": "Thao tác này chỉ đánh dấu có phải là đơn hàng giả hay không, sau khi đánh dấu sẽ không ảnh hưởng đến danh sách xuất và xuất kho",
    "IsTheHighestPriority": "1 là ưu tiên cao nhất, 20 là ưu tiên thấp nhất",
    "optional": "Không bắt buộc",
    "mandatory": "Bắt buộc",
    "shippingInsurance": "Bảo hiểm vận chuyển",
    "xModifiedWarehouseStockOutboundOrder": "{x} kho hàng đã sửa đổi, đơn đặt hàng xuất đi đã hoàn thành, nhận xét:",
    "xModifiedWarehouseStockCreatedOutbound": "{x} kho hàng đã sửa đổi, đơn đặt hàng xuất đi đã được tạo, nhận xét:",
    "xManuallyReplacesTheReservedWarehouse": "{x} thay thế thủ công kho dành riêng, từ kho {y} sang kho {z}",
    "pleaseModifyTheReservedPromotionStock": "Vui lòng sửa đổi số lượng cổ phiếu khuyến mãi dành riêng một cách cẩn thận! \n- Giảm hàng dự trữ sẽ làm tăng hàng tồn kho có sẵn và hàng khuyến mãi có thể được khấu trừ khỏi hàng của các kênh khác trong quá trình bán hàng đa kênh dẫn đến tình trạng bán quá mức\n- Sửa đổi hàng dự trữ trong Ginee sẽ chỉ thay đổi số lượng hàng khuyến mãi trong Ginee. Nó sẽ không ảnh hưởng đến hàng dự trữ trong Seller Center",
    "commissionFeeMapping": "Lập bản đồ phí hoa hồng",
    "priorityDelivery": "giao hàng ưu tiên",
    "flashSale": "Giảm giá thần tốc",
    "productPromotion": "khuyến mãi sản phẩm",
    "eventStartTime": "Thời gian bắt đầu sự kiện",
    "eventEndTime": "Thời gian kết thúc sự kiện",
    "notPrintable": "Không in được",
    "theFaceSheetCanBePrinted": "Nhãn vận chuyển có thể được in",
    "thTrackingNumberNotBeen": "Số theo dõi chưa được tạo",
    "theOrderStatusCannotPrintThe": "Trạng thái đơn hàng không hỗ trợ in Nhãn vận chuyển",
    "platformFulfillmentOrdersDoNotSupport": "Nền tảng thực hiện đơn hàng không hỗ trợ in Nhãn vận chuyển",
    "thisChannelDoesNotSupportPrinting": "Kênh này không hỗ trợ in Nhãn vận chuyển",
    "theChannelHasNotReturnedThe": "Kênh vẫn chưa trả lại AWB, các bạn kiên nhẫn chờ nhé. Nếu sau một thời gian dài AWB vẫn chưa được đồng bộ, vui lòng đến Trung tâm người bán để xác nhận đã tạo chưa, sau đó đồng bộ đơn hàng và thử lại.",
    "theChannelShippingLabelHasNot": "Nhãn vận chuyển kênh chưa được tạo, vui lòng thử lại sau bằng cách đồng bộ hóa đơn hàng",
    "syncAll": "Đồng bộ tất cả",
    "noShippingLabelToBePrint": "Tạm không có vận đơn để in",
    "promotionEnd": "Hết hạn",
    "handleProblem": "xử lý vấn đề",
    "stockMonitoringAndSynchronizationSettingsAre": "Cài đặt Stock Monitor và Sync chưa được bật",
    "thereAreUpToFixed": "Tối đa 5 sản phẩm ghim cố định lên đầu",
    "afterTurningOffTheAutomaticallyReserve": "Sau khi tắt tính năng Tự động bảo lưu kho khuyến mãi, kho khuyến mãi của Shopee sẽ không được đồng bộ và không được bảo lưu",
    "turnOffTheSwitchToAutomatically": "Tắt công tắc sang Tự động Dự trữ Cổ phiếu Khuyến mãi",
    "setTheReservationPolicyToUpcoming": "Đặt chính sách đặt trước thành \"Các chương trình khuyến mãi sắp tới và đang xử lý sẽ tự động được đặt trước\"",
    "setTheReservationPolicyToAutoreserve": "Đặt chính sách đặt trước thành \"Chỉ dành riêng cho các chương trình khuyến mãi đang xử lý\"",
    "turnOnTheSwitchToAutomatically": "Bật công tắc sang Tự động đặt trước cổ phiếu khuyến mãi",
    "processingPromotionOnlySupportModifyThe": "Chế biến khuyến mại chỉ hỗ trợ sửa kho, không hỗ trợ sửa kho dự trữ",
    "displayingWarehouseWhereTheMskuOf": "Kho hiển thị nơi MSKU của sản phẩm khuyến mại được phân bổ và kho có thể thay đổi.",
    "displayingTheBoundMskuOfThe": "Hiển thị MSKU bị ràng buộc của sản phẩm khuyến mại và số lượng hàng khuyến mãi được đặt trước trong Ginee",
    "displayTheChannelSkuOfThe": "Hiển thị SKU kênh của sản phẩm khuyến mãi và số lượng hàng khuyến mãi đã đăng ký thành công trong chương trình khuyến mãi nền tảng",
    "productParticipatedInPromotionButIs": "Sản phẩm đã tham gia khuyến mãi nhưng không được bảo lưu hàng khuyến mãi. Data khuyến mãi hết hạn chỉ tồn tại trong 30 ngày, sau 30 ngày sẽ không xem được data trong danh sách",
    "productParticipatedInPromotionAndOnly": "Sản phẩm đã tham gia khuyến mãi, chỉ một số biến thể là dành hàng khuyến mãi",
    "productsIncludingAllVariationsAreParticipating": "Sản phẩm (bao gồm tất cả các biến thể) đã tham gia khuyến mãi, đã hết hàng khuyến mãi",
    "thisPageCanOnlyViewAnd": "Trang này chỉ có thể xem và quản lý các khuyến mãi được tạo sau ngày 9 tháng 6 năm 2023",
    "channelReturnFailedReasonX": "Trả lại kênh không thành công, Lý do: {x}",
    "storeWillExp": "{m} Cửa hàng sắp hết hạn",
    "storeHasExp": "{m} Cửa hàng đã hết hạn",
    "big": "To lớn",
    "small": "Bé nhỏ",
    "standa": "Tiêu chuẩn",
    "onlySupportsAdjustingSkuAndQuantity": "Chỉ hỗ trợ điều chỉnh SKU và cỡ chữ số lượng",
    "fontSiz": "Cỡ chữ:",
    "paperSize": "Khổ giấy",
    "disableHighspeedPrintingForSshop": "Tắt in tốc độ cao cho cửa hàng [%s]",
    "enableHighspeedPrintingForSstore": "Bật tính năng in tốc độ cao cho cửa hàng [%s]",
    "disableTheHighSpeedPrintingButton": "Tắt nút [In tốc độ cao]",
    "enableTheHighSpeedPrintingSwitch": "Bật nút [In tốc độ cao]",
    "afterDisablingAllStoresWillNot": "Sau khi tắt, tất cả các cửa hàng sẽ không thể sử dụng tính năng in tốc độ cao và thời gian để có được nhãn vận chuyển kênh khi in hàng loạt sẽ tăng lên",
    "areYouSureToTurnOff": "Bạn có chắc chắn tắt tính năng in tốc độ cao không?",
    "shopeeWillMarkThePredownloadedOrder": "Shopee/Lazada/Tiktok sẽ đánh dấu đơn hàng tải sẵn là đã in, bạn vui lòng chọn bật công tắc tùy theo nhu cầu của mình",
    "afterEnablingGineeWillDownloadThe": "Sau khi bật, Ginee sẽ tải xuống nhãn vận chuyển kênh trước để cho phép in tốc độ cao và có thể giảm đáng kể thời gian chờ đợi khi in hàng loạt",
    "syncToObtainLatestLogisticsInformation": "Đồng bộ hóa để có được thông tin hậu cần mới nhất",
    "massSyncToObtainTheLatest": "Đồng bộ hóa hàng loạt để có được thông tin hậu cần mới nhất của đơn đặt hàng Shopee và Tokopedia",
    "syncLogistics": "Hậu cần đồng bộ",
    "package": "Bưu kiện",
    "storeAuthorizedtotalAmount": "Cửa hàng (Được ủy quyền/Tổng số tiền)",
    "subaccountUsedtotalAmount": "Tài khoản phụ (Dùng/Tổng số tiền)",
    "remainingOrderQuantity": "Số lượng đặt hàng còn lại",
    "remainingMDays": "{m} ngày còn lại",
    "packageRemainingMDays": "Gói còn lại {m} ngày",
    "toEnsureNormalUsePleaseRenew": "Sau khi gói hết hạn, công tắc đồng bộ hóa kho sẽ tự động tắt. Để đảm bảo sử dụng bình thường, vui lòng gia hạn kịp thời",
    "packageRemainingValidityMDays": "Hiệu lực còn lại của gói {m} ngày",
    "noReminderToday": "hôm nay không nhắc nhở",
    "theOperationIsTooFrequentPlease": "Thao tác quá thường xuyên, vui lòng thử lại sau 5 phút",
    "yourCurrentPackageDoesNotSupport": "Gói hiện tại của bạn không hỗ trợ ủy quyền kênh này, vui lòng nâng cấp gói OMS trước khi ủy quyền",
    "thisMethodIsOnlyOpenTo": "Phương pháp này chỉ dành cho các thương nhân được chỉ định. Hãy đặt nó một cách cẩn thận. Để biết thêm thông tin, tham khảo {m}",
    "shopeeOfficialInstructions": "Hướng dẫn chính thức của Shopee",
    "actualShippingFee": "Phí vận chuyển thực tế",
    "ifAutomaticallyReserveShopeePromotionStock": "Nếu bật [Tự động đặt trước kho khuyến mãi Shopee], đơn hàng khuyến mãi Shopee sẽ không được phân bổ theo quy tắc Kho vận chuyển mà sẽ bị khóa theo kho dành riêng cho khuyến mãi",
    "afterEnablingGineeWillAutomaticallyPull": "Sau khi bật, Ginee sẽ tự động pull BookingCode của đơn hàng Tokopedia",
    "turnOffTheAutomaticallyPullLogistics": "Tắt nút [Tự động kéo thông tin hậu cần]",
    "turnOnTheAutomaticallyPullLogistics": "Bật nút [Tự động kéo thông tin hậu cần]",
    "automaticallyPullLogisticsInformation": "Tự động kéo thông tin hậu cần",
    "disabled1": "Tàn tật",
    "enabled": "Đã bật",
    "newlyAuthorizedStoresAreAutomaticallySet": "Các cửa hàng được ủy quyền mới sẽ tự động được đặt thành \"Đã bật\"",
    "confirmToReauthorize": "Xác nhận để ủy quyền lại",
    "noteTiktokReauthorizationDoesNotExtend": "Lưu ý: Việc ủy quyền lại TikTok không kéo dài thời gian hiệu lực. Để gia hạn thời gian hiệu lực của ủy quyền, vui lòng truy cập Trung tâm người bán TikTok để hủy tích hợp Ginee trước, sau đó ủy quyền lại",
    "sellerFullAddress": "Người bán địa chỉ đầy đủ",
    "thereIsNoOrderThatCan": "Không thể in đơn hàng bằng in nhanh, vui lòng xác nhận rằng cửa hàng nơi đặt đơn hàng đã bật [In nhanh]",
    "startSynchronizingLatestOrderLogisticsInformation": "Bắt đầu đồng bộ hóa thông tin hậu cần đơn hàng mới nhất, dự kiến sẽ mất vài phút, vui lòng làm mới trang sau để xem thông tin hậu cần mới nhất",
    "video": "Băng hình",
    "tryHighlightingCoreSellingPoints": "Hãy thử làm nổi bật 1-2 điểm bán hàng cốt lõi cho sản phẩm của bạn. Điều này sẽ được hiển thị trong phần chi tiết sản phẩm.",
    "weRecommendTheVideoDurationIs": "Chúng tôi khuyên bạn nên sử dụng thời lượng video trong vòng 20 giây và thời lượng dài nhất không được vượt quá 60 giây.",
    "recommendedRatioIsAndRecommended": "Tỷ lệ được đề xuất là 1:1 và độ phân giải được đề xuất là trên HD 720p.",
    "ifYouUploadAVideoIt": "Nếu bạn tải video lên, video đó phải có tỷ lệ khung hình từ 9:16 đến 16:9. Tệp không được lớn hơn 30MB, định dạng phải là MP4.",
    "tiktokRequired": "Yêu cầu TikTok：",
    "changingCategoryWillNotAffectYour": "Việc thay đổi danh mục sẽ không ảnh hưởng đến thông tin sản phẩm của bạn như hiệu suất bán hàng và xếp hạng sản phẩm, nhưng sẽ xóa các thuộc tính sản phẩm. Bạn có chắc chắn muốn thay đổi nó?",
    "basedOnTheTitleYouEntered": "Dựa trên Tiêu đề bạn đã nhập, danh mục sản phẩm có thể là (vui lòng chọn nếu cần)",
    "economical": "Tiết kiệm",
    "cargo": "chở hàng",
    "standardShipping": "tiêu chuẩn vận chuyển",
    "pleaseInputTheValueOfWeight": "Vui lòng nhập giá trị trọng lượng lớn hơn 5.00kg",
    "deliveryOptions": "Tùy chọn giao hàng",
    "lineShoppingOnlySupportsSettingThe": "Line Shopping chỉ hỗ trợ cài đặt số tiền chiết khấu tức thì thống nhất cho tất cả các biến thể",
    "compulsoryOutbound": "Danh sách xuất hàng đã được đẩy vào kho thực hiện, vui lòng thực hiện xuất hàng trong kho thực hiện. Nếu bạn tiếp tục vận hành, chỉ đơn hàng xuất đi trong OMS sẽ bị cưỡng chế, điều này có thể gây ra sự không thống nhất với dữ liệu xuất đi thực tế của kho thực hiện. Bạn có chắc chắn muốn tiếp tục không?",
    "compulsoryCancel": "Danh sách xuất hàng đã được đẩy vào kho thực hiện, vui lòng thực hiện xuất hàng trong kho thực hiện. Nếu tiếp tục vận hành, bạn sẽ chỉ buộc hủy danh sách hàng xuất trong OMS, điều này có thể gây ra sự không thống nhất với dữ liệu hàng xuất thực tế của kho thực hiện. Bạn có chắc chắn muốn tiếp tục không?",
    "noPermission": "Bạn không có quyền truy cập trang này, vui lòng làm mới trang và thử lại sau khi nâng cấp gói của bạn. Đối với tài khoản nhân viên, vui lòng liên hệ với tài khoản chính để thêm quyền vai trò",
    "refuseToAddWms": "Bạn không có quyền thêm kho này, vui lòng liên hệ với Nhà cung cấp dịch vụ kho của bạn để thêm Tài khoản người gửi hàng cho bạn và ủy quyền cho kho",
    "theProductDescriptionShouldGiveThe": "Mô tả sản phẩm nên cung cấp cho khách hàng thông tin hữu ích về sản phẩm để đảm bảo mua hàng",
    "missingOrderInformation": "Thiếu thông tin đặt hàng",
    "compartmentDimensionInShippingWarehouseSetting": "Kích thước ngăn trong cài đặt Kho vận chuyển đã thay đổi từ {1} thành {2}",
    "priorityOfWarehouseAlocationInShipping": "Mức độ ưu tiên của phân bổ kho trong cài đặt Kho vận chuyển đã thay đổi từ {1} thành {2}",
    "warehouseInRuleChangedFrom": "Kho trong quy tắc {0} đã thay đổi từ {1} thành {2}",
    "startPullingTheOrderDataIt": "Ước tính sẽ mất vài phút để bắt đầu truy xuất dữ liệu đơn đặt hàng. Vì vậy, hãy chú ý đến việc đồng bộ hóa dữ liệu cửa hàng sau này.",
    "theRecipientsInformationCanBeDisplayed": "Thông tin của người nhận có thể được hiển thị bằng văn bản trên nhãn vận chuyển nhưng thông tin của người nhận có thể bị che đi.",
    "theCompleteRecipientInformationCanBe": "Thông tin đầy đủ về người nhận có thể được hiển thị dưới dạng ảnh trên nhãn vận chuyển, nhưng một số máy in có thể không nhận dạng được ảnh.",
    "customShipmentWarning": "Tùy chọn Cảnh báo giao hàng",
    "thisSystemWarehouseHasBeenBound": "Kho hệ thống này đã bị ràng buộc bởi các Kho kênh khác!",
    "completeRecipientInformationCanBe": "Thông tin đầy đủ về người nhận có thể được hiển thị dưới dạng ảnh trên nhãn vận chuyển",
    "recipientsInformationCanBeDisplayed": "Thông tin đầy đủ về người nhận có thể được hiển thị dưới dạng ảnh trên nhãn vận chuyển",
    "instantDiscountAmount": "Số tiền giảm giá ngay lập tức",
    "originalPriceShouldBeHigherThan": "Giá gốc phải cao hơn số tiền chiết khấu tức thì",
    "YourOmsWillBeUpgraded": "1. OMS của bạn sẽ được nâng cấp lên phiên bản đầy đủ tính năng, có thể ủy quyền cho các cửa hàng đa kênh, xử lý đơn hàng và quản lý hàng tồn kho;",
    "YouWillAutomaticallyBindThe": "2. Bạn sẽ tự động ràng buộc kho của nhà cung cấp dịch vụ và bạn có thể đẩy đơn hàng đến kho của nhà cung cấp dịch vụ và nhà cung cấp dịch vụ sẽ thực hiện các đơn hàng;",
    "theFulfillmentServiceProviderXInvites": "Nhà cung cấp dịch vụ thực hiện đơn hàng {x} mời bạn mở kho thực hiện đơn hàng {y}",
    "afterAgreeing": "Sau khi đồng ý:",
    "areYouSureYouAgreeTo": "Bạn có chắc chắn đồng ý kích hoạt không?",
    "confirmActivation": "Xác nhận kích hoạt",
    "youHaveNewApplication": "Bạn có {m} đơn đăng ký mới",
    "thisChannelCanBeAuthorizedAfter": "Kênh này có thể được cấp phép sau khi nâng cấp gói",
    "theCurrentPackageDoesNotSupport": "Gói hiện tại không hỗ trợ ủy quyền kênh này",
    "enableFailedYouCanOnlyAuthorize": "Kích hoạt không thành công! Cửa hàng vượt quá giới hạn tài nguyên gói",
    "afterUpgradingThePackageMoreMaster": "Sau khi nâng cấp gói, có thể thêm nhiều Sản phẩm chính",
    "addFailedMasterProducts": "Thêm không thành công! Số lượng Master Products đã đạt giới hạn của gói",
    "masterProductQuantityHasReachedThe": "Số lượng Sản phẩm Chính đã đạt đến giới hạn gói",
    "additionalXConsignorAccountsbusiness": "Bổ sung x 5 Tài khoản người gửi hàng",
    "additionalXGineeWmsOrders": "Thêm x 10.000 đơn đặt hàng Ginee WMS",
    "additionalXStores": "Thêm x 10 cửa hàng",
    "additionalXStaffAccounts": "Bổ sung x 5 Tài khoản nhân viên",
    "additionalXGineeOmsOrders": "Thêm x 10.000 Đơn hàng Ginee OMS",
    "addons": "Tiện ích bổ sung",
    "offlineOnboardingOn": "Tích hợp ngoại tuyến (1 trên 1)",
    "accountManagerOn": "Người quản lý tài khoản (1 trên 1)",
    "onlineTraining": "Đào tạo trực tuyến",
    "liveChatSupport": "Hỗ trợ trò chuyện trực tiếp",
    "implementationAndCustomerCare": "Triển khai và chăm sóc khách hàng",
    "XConsignorAccountsBusiness": "10 x Tài khoản người gửi hàng (Doanh nghiệp)",
    "servicesToExternalBrands": "Dịch vụ cho các thương hiệu bên ngoài",
    "serviceProviderManagement": "Quản lý nhà cung cấp dịch vụ",
    "gineeWmsOpenApi": "API mở Ginee WMS",
    "pdaapp": "PDA/ỨNG DỤNG",
    "fifofefo": "FIFO/FEFO",
    "smartReplenishment": "Bổ sung thông minh",
    "stocklocationTransfer": "Chuyển hàng/địa điểm",
    "waveShipping": "Sóng Vận Chuyển",
    "gineeWmsWarehouseManagementSystem": "Ginee WMS (Hệ thống quản lý kho)",
    "gineeOmsOpenApi": "API mở Ginee OMS",
    "theThirdPartyIntegrationposLogisticsFulfillment": "Tích hợp bên thứ ba（POS, Hậu cần, Thực hiện đơn hàng, OMS, Kế toán）",
    "multiwarehouseManagement": "Quản lý nhiều kho",
    "reporting": "Báo cáo",
    "stockManagement": "Quản lý chứng khoán",
    "gineeOmsOrderManagementSystem": "Ginee OMS (Hệ thống quản lý đơn hàng)",
    "noOfSalesChannels": "Số kênh bán hàng",
    "noOfMskus": "Số MSKU",
    "noOfStores": "Số cửa hàng",
    "noOfStaffAccounts": "Số tài khoản nhân viên",
    "noOfCountriesinternational": "Số quốc gia (Quốc tế)",
    "noOfGineeWmsOrders": "Số đơn đặt hàng Ginee WMS",
    "noOfGineeOmsOrders": "Số đơn đặt hàng Ginee OMS",
    "ecommerceOperations": "Hoạt động thương mại điện tử",
    "buyItNow": "Mua ngay",
    "clickMeToGetDiscount": "Nhận giảm giá",
    "customization": "Tùy chỉnh",
    "youHaveMNewMessages": "Bạn có {m} tin nhắn mới",
    "officialAccounts": "Tài khoản chính thức",
    "theThirdPartyIntegration": "Tích hợp bên thứ ba",
    "thereAreUnrecognizableCharactersSuchAs": "Có những ký tự không nhận dạng được, vui lòng thay đổi",
    "currentlyDoNotSupportIntegratingCnsc": "Hiện tại chưa hỗ trợ tích hợp cửa hàng CNSC hoặc 3PF",
    "manuallyRepushThePushFailedData": "Đẩy lại dữ liệu \"đẩy không thành công\" theo cách thủ công",
    "paymentTerms": "Chính sách thanh toán",
    "bankAccount": "Tài khoản ngân hàng",
    "issuingBank": "Ngân hàng phát hành",
    "taxIdNumber": "Mã số thuế",
    "contactPerson": "Người liên hệ",
    "website": "Trang mạng",
    "onlyLettersAndNumbersAreSupported": "Chỉ có chữ cái, số và '- _ . /' được hỗ trợ, vui lòng nhập lại",
    "theContentYouEnteredContainsIllegal": "Nội dung bạn nhập chứa ký tự không hợp lệ, vui lòng nhập lại",
    "autoGenerate": "Tự động tạo",
    "supplierName": "tên đệm",
    "supplierCode": "Mã nhà cung cấp",
    "createNewSupplier": "Tạo nhà cung cấp mới",
    "importSupplier": "Nhà cung cấp nhập khẩu",
    "supplierList": "Danh sách nhà cung cấp",
    "purchaseManagement": "Quản lý mua hàng",
    "supplierManagement": "Quản lý nhà cung ứng",
    "purchase": "Mua",
    "disableenable": "Tắt/Bật",
    "supplierCodeDoesNotExist": "Mã nhà cung cấp không tồn tại",
    "supplierNameAlreadyExists": "Tên nhà cung cấp đã tồn tại",
    "supplierCodeAlreadyExists": "Mã nhà cung cấp đã tồn tại",
    "failedToDeleteTheSupplierAlready": "Không thể xóa! Nhà cung cấp đã có Đơn đặt hàng hoặc không ở trạng thái Vô hiệu hóa",
    "onlySuppliersWithDisabledStatusAnd": "Chỉ có thể xóa Nhà cung cấp có trạng thái Vô hiệu hóa và không có Đơn đặt hàng nào",
    "wantToDeleteTheSupplier": "Bạn có chắc chắn muốn xóa Nhà cung cấp không?",
    "wantToEnableTheSupplier": "Bạn có chắc chắn muốn kích hoạt Nhà cung cấp không?",
    "failedToDisableThisSupplierHas": "Không thể tắt được! Nhà cung cấp này có Đơn đặt hàng chưa hoàn thành",
    "suppliersWithUnfinishedPurchaseOrdersCannot": "Không thể vô hiệu hóa các nhà cung cấp có Đơn đặt hàng chưa hoàn thành, vui lòng thận trọng khi tiến hành",
    "wantToDisableTheSupplier": "Bạn có chắc chắn muốn vô hiệu hóa Nhà cung cấp không?",
    "thisTypeHasBeenSelectedBy": "Loại này đã được nhà cung cấp lựa chọn và không thể xóa được",
    "wantToDeleteTheSupplierType": "Bạn có chắc chắn muốn xóa Loại nhà cung cấp không?",
    "typeName": "Tên loại",
    "addNewType": "Thêm loại mới",
    "fullPayment": "Thanh toán đầy đủ",
    "supplierCodeCannotBeEditedAfter": "Mã nhà cung cấp không thể được chỉnh sửa sau khi được tạo",
    "xAlreadyExists": "{x} đã tồn tại",
    "pleaseFillInThisField": "Vui lòng điền vào trường này",
    "theAmountMustBeBetweenXy": "Số tiền phải nằm trong khoảng từ {x}~{y}, cho phép 2 chữ số thập phân",
    "amountMustBeBetweenXyAnd": "Số tiền phải nằm trong khoảng từ {x}~{y} và không được phép có số thập phân.",
    "partialArrival": "Giao hàng một phần",
    "inTransit": "Trên đường vận chuyển",
    "paymentStatus": "Tình trạng thanh toán",
    "purchaseOrder": "Đơn đặt hàng",
    "paymentMethod": "Phương thức thanh toán",
    "paid": "Trả",
    "partialPayment": "Thanh toán một phần",
    "unpaid": "Chưa thanh toán",
    "feeInformation": "Thông tin phí",
    "deliveredQuantity": "Số lượng vận chuyển",
    "estimatedDateOfArrival": "Dự kiến ​​Ngày đến",
    "purchasingStaff": "Nhân viên thu mua",
    "receivingWarehouse": "Kho tiếp nhận",
    "puchaseDate": "Ngày mua hàng",
    "purchaseOrderSource": "Nguồn đơn đặt hàng",
    "noSupplier": "Không có nhà cung cấp",
    "purchaseOrderNumberAlreadyExists": "Số đơn đặt hàng đã tồn tại",
    "purchaseOrderNumberCannotBeEdited": "Số đơn đặt hàng không thể được chỉnh sửa sau khi nó được tạo",
    "pleaseEnterPurchaseOrderNumber": "Vui lòng nhập Mã đơn hàng",
    "purchaseOrderNumber": "Số lượng đơn đặt hàng",
    "basicInfoma": "Thông tin cơ bản",
    "otherNoncash": "Không dùng tiền mặt khác",
    "virtualAccount": "Tài khoản ảo",
    "paymentLink": "Liên kết thanh toán",
    "qris": "QRIS",
    "edc": "EDC",
    "bankTransfer": "Chuyển khoản ngân hàng",
    "chequeclearing": "Séc/Thanh toán bù trừ",
    "cash": "Tiền mặt",
    "discou": "Giảm giá",
    "productPurchasePrice": "Giá mua sản phẩm",
    "purchaseOrderTotalPrice": "Đơn đặt hàng Tổng giá",
    "skuQuantity": "Số lượng mã hàng",
    "totalPriceInformation": "Thông tin tổng giá",
    "inboundCostPrice": "Chi phí đầu vào",
    "individualCostSharing": "Chi phí phân bổ cá nhân",
    "taxRate": "Thuế suất",
    "onlyNumbersFromTo": "Chỉ cho phép các số từ {x}~{y}, số thập phân không được hỗ trợ",
    "discountRate": "Tỷ lệ chiết khấu",
    "purcPrice": "Giá mua",
    "quantityPcs": "Số lượng (chiếc)",
    "unit": "Đơn vị",
    "volumeM": "Khối lượng (cm³)",
    "productPriceIncludesTax": "Giá sản phẩm đã bao gồm thuế",
    "massAddd": "Thêm hàng loạt",
    "addProdu": "Thêm sản phẩm",
    "dividedByQuantity": "Phân bổ theo số lượng",
    "dividedByVolume": "Phân bổ dựa trên Khối lượng",
    "dividedByWeight": "Phân bổ dựa trên trọng lượng",
    "dividedByPrice": "Phân bổ dựa trên giá",
    "notDivided": "Không phân bổ",
    "fillInmodifyTrackingNumber": "Điền/sửa đổi Số theo dõi",
    "purchaseNote": "Lưu ý mua hàng",
    "thePurchaseOrderWarehouseIsWmsthirdparty": "Kho Đơn đặt hàng là WMS/Kho của bên thứ ba. Vui lòng thông báo cho kho thực hiện để hủy Đơn đặt hàng trước khi tiếp tục.",
    "failedToCancelThePurchaseOrder": "Không hủy được Đơn đặt hàng",
    "cancellationFailedInboundProductsInThis": "Hủy không thành công! Các sản phẩm gửi đến trong Đơn đặt hàng này đã được hoàn thành. Vui lòng làm mới trang để kiểm tra lại trạng thái của Đơn đặt hàng",
    "whenAPurchaseOrderIsCancelled": "Khi Đơn đặt hàng bị hủy, các giao dịch mua hàng trong nước sẽ không còn hiệu lực và Kho vận chuyển sẽ được giải phóng",
    "wantToCancelThePurchaseOrder": "Bạn có chắc chắn muốn hủy Đơn đặt hàng không?",
    "wantToDeleteThePurchaseOrder": "Bạn có chắc chắn muốn xóa Đơn đặt hàng không?",
    "IfTheReceivingWarehouseIs": "2. Nếu kho nhận hàng là Kho của bên thứ ba, Ginee sẽ tự động đẩy đơn hàng Inbound đến kho thực hiện",
    "AfterSubmissionThePurchaseOrder": "1. Sau khi gửi, Đơn đặt hàng sẽ chuyển sang trạng thái Đang vận chuyển và một giao dịch mua hàng trong nước sẽ được tạo ra và sản phẩm sẽ được thêm vào Kho vận chuyển",
    "youWantToSubmitThePurchase": "Bạn có chắc chắn muốn gửi Đơn đặt hàng không?",
    "identify1": "Nhận dạng",
    "copyMskuFromExcelAndPaste": "Sao chép MSKU từ Excel và dán nó vào đây. Vui lòng sử dụng enter để nhập nhiều MSKU. Mỗi dòng thể hiện một thông tin.",
    "EstimatedTimeOfArrivalFormat": "6. Định dạng Thời gian đến dự kiến: Năm/Tháng/Ngày (Ví dụ: 23/08/2023)",
    "WhenTheReceivingWarehouseIs": "3. Khi kho nhận là Kho bên thứ ba, nếu MSKU không được đẩy sang Kho bên thứ ba, vui lòng vào trang Danh sách hàng tồn kho để thêm SKU vào Kho bên thứ ba;",
    "ExplanationOfTheShippingFeeother": "4. Giải thích về Phí vận chuyển/phương pháp phân bổ chi phí khác:\n- Khi chọn “Giá”, việc phân bổ sẽ dựa trên giá của từng SKU\n- Khi chọn “Số lượng”, việc phân bổ sẽ căn cứ vào số lượng mua của từng SKU\n- Khi chọn “Số lượng”, việc phân bổ sẽ căn cứ vào số lượng của từng SKU\n- Khi chọn “Trọng lượng”, việc phân bổ sẽ dựa trên trọng lượng của từng SKU.\nNếu Phí vận chuyển/các chi phí khác không được điền, theo mặc định, khoản phí này sẽ không được phân bổ;",
    "purchaseOrderContainsMultipleProductsYou": "3. Khi Đơn đặt hàng chứa nhiều sản phẩm, bạn cần điền đầy đủ thông tin sản phẩm cho từng dữ liệu. Đối với thông tin công khai (thông tin cơ bản, thông tin chi phí, thông tin khác) chỉ cần điền dữ liệu đầu tiên. Khi thông tin công khai của nhiều dữ liệu không nhất quán thì dữ liệu đầu tiên sẽ chiếm ưu thế;",
    "WhenAPurchaseOrderContains": "2. Khi một Đơn đặt hàng có nhiều sản phẩm, vui lòng sử dụng cùng một Mã số Đơn đặt hàng. Khi nhập, nhiều hàng dữ liệu có cùng Số đơn đặt hàng sẽ được hợp nhất thành một Đơn đặt hàng;",
    "exportPurchaseOrder": "Xuất đơn đặt hàng",
    "printPurchaseOrder": "In đơn đặt hàng",
    "changePaymentStatus": "Thay đổi trạng thái thanh toán",
    "signature": "Chữ ký",
    "merchantWarehouseAddress": "Địa chỉ kho hàng thương mại",
    "contactInformation": "Liên hệ",
    "supplierAddress": "Địa chỉ nhà cung cấp",
    "supplier": "Nhà cung cấp",
    "supplierInformation": "Thông tin nhà cung cấp",
    "merchantInformation": "Thông tin người bán",
    "explanation": "Giải trình:",
    "UpToDataCan": "1. Có thể nhập tối đa 10.000 dữ liệu cùng một lúc (định dạng: xlsx, xls);",
    "estimatedTimeOfArrival": "Thời gian dự kiến đến",
    "warehouseType": "Loại kho",
    "theEntireInboundWillBeInvalidated": "Toàn bộ Hàng nhập vào sẽ bị vô hiệu và hàng tồn kho quá cảnh sẽ được giải phóng",
    "completeInbound": "Hoàn tất việc nhập vào",
    "partialInbound": "Nhập một phần",
    "afterInvalidationIsInitiatedOnlyThe": "Sau khi bắt đầu vô hiệu, chỉ có Hàng nhập vào OMS sẽ bị vô hiệu và trạng thái của Hàng nhập vào trong kho thực hiện sẽ không thay đổi",
    "onlyTheErpInboundIsInvalided": "Chỉ có OMS Inbound là không hợp lệ",
    "afterTheInvalidationIsInitiatedThe": "Sau khi bắt đầu vô hiệu, việc vô hiệu sẽ được thông báo cho kho thực hiện. Sau khi vô hiệu hóa thành công, trạng thái OMS của kho thực hiện sẽ chuyển thành không hợp lệ",
    "invalidErpAndFulfillmentInbound": "OMS vô hiệu và thực hiện đơn hàng trong nước",
    "invalidationMethod": "Phương pháp vô hiệu:",
    "theSkuWillBecameInvalidAnd": "SKU sẽ không còn hiệu lực và Hàng vận chuyển sẽ được giải phóng",
    "pleaseSelectTheInvalidationMethod": "Vui lòng chọn Phương thức vô hiệu hóa",
    "inboundFullAmount": "Toàn bộ số tiền nhập vào",
    "presentInboundQuantity": "Số lượng nhập hiện tại",
    "pendingInboundinboundCompletedplannedInbound": "Đang chờ nhập vào/Đã hoàn thành nhập vào/Đã lên kế hoạch nhập vào",
    "afterTheInboundIsCompletedThe": "Sau khi Inbound hoàn tất, trạng thái Inbound sẽ thay đổi thành \"Inbound Complete\" và không thể tiếp tục",
    "sureYouWantToEndThe": "Bạn có chắc chắn muốn kết thúc Inbound không?",
    "pushResult": "Kết quả cập nhật cửa hàng",
    "receiveItem": "Nhận hàng",
    "afterDisablingThePrintedPurchaseOrder": "Sau khi vô hiệu hóa, Đơn đặt hàng được in ra sẽ không hiển thị số tiền và phí của sản phẩm (Phí vận chuyển/phí khác/Tổng số tiền)",
    "amountDisplay": "Hiển thị số tiền",
    "purchaseOrderPrintingSettings": "Cài đặt in đơn đặt hàng",
    "purchaseSetting": "Cài đặt mua hàng",
    "receivingTime": "Thời gian nhận",
    "inboundTotalAmount": "Tổng số lượng nhập vào",
    "receiveItemNumber": "Nhận số mặt hàng",
    "supplierTypeAlreadyExists": "Loại nhà cung cấp đã tồn tại",
    "supplierType": "Loại nhà cung cấp",
    "supplierTypeDoesNotExist": "Loại nhà cung cấp không tồn tại",
    "theDataIsAbnormalAndThe": "Dữ liệu bất thường, tác vụ xuất không tồn tại",
    "xParameterIsIncorrect": "Tham số {x} không chính xác",
    "xParameterCannotBeEmpty": "Tham số {x} không được để trống",
    "thePurchaseOrderAlreadyExists": "Đơn đặt hàng đã tồn tại",
    "purchaseOrderDoesNotExist": "Đơn đặt hàng không tồn tại",
    "failedTheWarehouseTypeWasNot": "Không thành công, không lấy được loại kho",
    "purchaseOrderCancellationFailed": "Hủy đơn đặt hàng không thành công",
    "purchaseOrderDeletionFailed": "Xóa đơn đặt hàng không thành công",
    "purchaseOrderSubmissionFailed": "Gửi đơn đặt hàng không thành công",
    "purchaseOrderIsMissingProductInformation": "Đơn đặt hàng thiếu thông tin sản phẩm",
    "purchaseOrderIsNotInDraft": "Đơn đặt hàng không ở trạng thái \"Dự thảo\"",
    "purchaseOrderCancellationFailedPleaseRefresh": "Hủy đơn đặt hàng không thành công. Hãy làm mới trang và thử lại",
    "purchaseOrderDeletionFailedPleaseRefresh": "Xóa đơn đặt hàng không thành công. Hãy làm mới trang và thử lại",
    "purchaseOrderSubmissionFailedPleaseRefresh": "Gửi đơn đặt hàng không thành công. Hãy làm mới trang và thử lại",
    "theSupplierDoesNotExists": "Nhà cung cấp không tồn tại",
    "supplierAlreadyExists": "Nhà cung cấp đã tồn tại",
    "theImportedDataIsEmpty": "Dữ liệu đã nhập trống",
    "theImportedFileFormatIsNot": "Định dạng tệp đã nhập không được hỗ trợ",
    "theOperationTaskDoesNotExist": "Nhiệm vụ vận hành không tồn tại",
    "theImportTaskDoesNotExist": "Tác vụ nhập không tồn tại",
    "theExportTaskDoesNotExist": "Tác vụ xuất không tồn tại",
    "createAndPushSalesInvoicesWhen": "Tạo và đẩy Hóa đơn bán hàng khi hoàn thành việc xuất hóa đơn",
    "inboundAmount": "Số tiền gửi đến",
    "goodsReceivedNoteNumber": "Nhận số mặt hàng",
    "quotationGeneratedSuccessfully": "Báo giá được tạo thành công!",
    "theChannelWarehouseIsNotAssociated": "Kho kênh không được liên kết với Kho Ginee",
    "multiWarehouseChannelProductsAreNot": "Sản phẩm kênh đa kho không được liên kết với kho",
    "doesNotComplyWithTheRules": "Không tuân thủ quy định: Tất cả các cửa hàng được kết nối, ngoại trừ các cửa hàng mới được ủy quyền sau",
    "matchingDefaultPushRulesAreDisabled": "Khớp quy tắc đẩy mặc định bị vô hiệu hóa",
    "matchingStorePushRulesAreDisabled": "Quy tắc đẩy cửa hàng phù hợp bị vô hiệu hóa",
    "matchingCustomPushRulesAreDisabled": "Việc so khớp các quy tắc đẩy tùy chỉnh đã bị vô hiệu hóa",
    "shopifyProductInventoryIdDoesNot": "ID kho sản phẩm Shopify không tồn tại, không được phép cập nhật hàng trong kho",
    "blibliProductsAreDisabledAndStock": "Các sản phẩm Blibli bị vô hiệu hóa và không được phép cập nhật hàng trong kho",
    "returnFromTikiChannelForThis": "Trở về từ kênh Tiki cho sản phẩm này: Không được phép cập nhật",
    "theStoreHasBeenDisconnected": "Cửa hàng đã bị ngắt kết nối",
    "tikiProductInboundStatusDoesNot": "Sản phẩm Tiki trạng thái Inbound không cho phép đẩy hàng",
    "masterProductNotFound": "Không tìm thấy sản phẩm chính",
    "thePushTaskAlreadyExistsAnd": "Tác vụ đẩy đã tồn tại và không cần phải đẩy lại.",
    "failedAlreadyRepushed": "Không thành công, đã được đẩy lại",
    "pleaseSelectTheReceivingWarehouseFirst": "Vui lòng chọn kho nhận hàng trước",
    "ifNotFilledInItWill": "Nếu không điền, nó sẽ được tạo tự động",
    "region": "Khu vực",
    "cityNew": "Thành phố",
    "provinc": "Tỉnh",
    "districtsNew": "Quận",
    "purchaseQuantity": "Số lượng mua",
    "otherCostAllocationMethodsPleaseSelect": "Các phương pháp phân bổ chi phí khác (Vui lòng chọn)",
    "otherCostTotal": "Chi phí khác (Tổng cộng)",
    "shippingFeeAllocationMethodPleaseSelect": "Phương thức phân bổ phí vận chuyển (Vui lòng chọn)",
    "shippingFeeTotal": "Phí vận chuyển (Tổng cộng)",
    "wantToUnbindTheWarehouse": "Bạn có chắc chắn muốn hủy liên kết kho không?",
    "afterUnbindingChangesInStockIn": "Sau khi hủy liên kết, những thay đổi trong kho trong Kho hệ thống sẽ không được đẩy vào Kho kênh.",
    "unbindTheChannelWarehouseAaaFrom": "Hủy liên kết Kho kênh {AAA} khỏi Kho hệ thống",
    "thisFeatureIsOnlyAvailableFor": "Tính năng này chỉ có sẵn cho Tokopedia Official Store",
    "deleteSupplier": "Xóa nhà cung cấp",
    "enableSupplier": "Kích hoạt nhà cung cấp",
    "disableSupplier": "Vô hiệu hóa nhà cung cấp",
    "editSupplier": "Chỉnh sửa nhà cung cấp",
    "manualDeleteSupplierX": "Xóa thủ công nhà cung cấp [{x}]",
    "enableSupplierX": "Bật Nhà cung cấp [{x}]",
    "disableSupplierX": "Vô hiệu hóa nhà cung cấp [{x}]",
    "importToUpdateSupplierX": "Nhập để cập nhật nhà cung cấp [{x}]",
    "manualEditSupplierX": "Chỉnh sửa thủ công Nhà cung cấp [{x}]",
    "importToAddSupplierX": "Nhập để thêm nhà cung cấp [{x}]",
    "manualAddSupplierX": "Thêm nhà cung cấp thủ công [{x}]",
    "exportSupplierList": "Danh sách nhà cung cấp xuất khẩu",
    "exportPurchaseOrderX": "Xuất đơn đặt hàng [{x}]",
    "printPurchaseOrderX": "In đơn đặt hàng [{x}]",
    "cancelPurchaseOrderX": "Hủy đơn đặt hàng [{x}]",
    "deletePurchaseOrderX": "Xóa đơn đặt hàng [{x}]",
    "submitPurchaseOrderX": "Gửi đơn đặt hàng [{x}]",
    "editPurchaseOrderXManually": "Chỉnh sửa thủ công Đơn đặt hàng [{x}]",
    "importPurchaseOrderX": "Nhập để thêm đơn đặt hàng [{x}]",
    "addPurchaseOrderXManually": "Thêm đơn đặt hàng thủ công [{x}]",
    "exportPurchaseO": "Xuất đơn đặt hàng",
    "cancelPurchaseOrder": "Hủy đơn đặt hàng",
    "deletePurchaseOrder": "Xóa đơn đặt hàng",
    "submitPurchaseOrder": "Gửi đơn đặt hàng",
    "editPurchaseOrder": "Chỉnh sửa đơn đặt hàng",
    "addPurchaseOrder": "Thêm đơn đặt hàng",
    "shopeeRequirements": "Yêu cầu Shopee：",
    "formatMp": "Định dạng: MP4",
    "durationSs": "Thời lượng: 10s-60s",
    "sizeMaxMbResolutionShouldNot": "Kích thước: Tối đa 30Mb, độ phân giải không vượt quá 1280x1280px",
    "myShopeeSellerCenterCanInsert": "Chỉ một số cửa hàng Shopee mới có thể hỗ trợ hình ảnh và mô tả, vì vậy hãy đảm bảo cửa hàng của bạn hỗ trợ trước khi bật tùy chọn này.",
    "copyCopy": "Sao chép",
    "thankYouForYourCooperationPlease": "Cảm ơn sự hợp tác của bạn, vui lòng sao chép ID duy nhất và gửi cho nhóm Hỗ trợ Ginee.",
    "weNeedYourConsentToRecord": "Chúng tôi cần sự đồng ý của bạn để ghi lại màn hình và ghi lại quy trình thao tác, sau đó xác định và giải quyết vấn đề của bạn.",
    "weOnlyRecordThePagesIn": "Chúng tôi chỉ ghi lại các trang trong hệ thống Ginee.",
    "needAuthorization": "Cần sự cho phép",
    "approveStartToRecord": "Phê duyệt và bắt đầu ghi lại",
    "done": "Hoàn thành",
    "repeatTheOperationStepsToReproduce": "Lặp lại các bước thao tác để tái tạo sự cố, dừng bản ghi sau khi ghi lại toàn bộ luồng.",
    "capturingTheProcessingFlowOnThe": "Ghi lại quy trình vận hành trên màn hình",
    "stop": "Dừng lại",
    "uploading": "Đang tải lên",
    "importToUpdateSupplier": "Nhập để cập nhật nhà cung cấp",
    "importToAddSupplier": "Nhập để thêm nhà cung cấp",
    "feedback": "Nhận xét",
    "uploadVideo": "Tải video lên",
    "wereNotPerfectButWereListening": "Chúng tôi không hoàn hảo, nhưng chúng tôi đang lắng nghe",
    "feedbackForm": "Mẫu phản hồi",
    "pleaseSubmitATicektFormWith": "Vui lòng gửi mẫu vé có mô tả văn bản và hình ảnh đính kèm",
    "feedbackVideo": "Video phản hồi",
    "pleaseGrantGineeThePermissionRecord": "- Vui lòng cấp cho Ginee quyền ghi lại thao tác của bạn để chẩn đoán",
    "enterTheSpecificPageStartTo": "- Vào trang cụ thể, bắt đầu ghi và lặp lại thao tác của bạn để tái hiện vấn đề",
    "stopTheRecordCopyTheUnique": "- Dừng ghi, sao chép ID duy nhất và gửi cho nhóm Hỗ trợ Ginee",
    "faqfeedback": "Câu hỏi thường gặp/Phản hồi",
    "needHelp": "Cần giúp đỡ?",
    "detailedDescription": "miêu tả cụ thể",
    "trackingNumber": "Số theo dõi",
    "enableLongDescription": "Kích hoạt hình ảnh và mô tả.",
    "DetailProductDescription": "Mô tả sản phẩm",
    "dueToApiLimitationsBlibliMust": "Do hạn chế về API, Blibli phải sử dụng tính năng in nhanh để tải xuống trước nhãn vận chuyển của kênh. Vui lòng đi tới Cài đặt đơn hàng -> In nhanh để bật in tốc độ cao trong các cửa hàng Blibli và thử lại.",
    "abnormal": "Bất thường",
    "theInformationCannotBeFoundIn": "Nếu không tìm thấy thông tin trong Ginee, vui lòng xác nhận xem nội dung của từng trường đã khớp hay chưa hoặc đăng nhập vào Chính xác để sửa đổi thông tin rồi đồng bộ lại.",
    "purchasePurchaseOrder": "Mua / Đơn đặt hàng",
    "addToList": "Thêm vào danh sách",
    "modifyingAccuratePurchaseOrderInformationIn": "Việc sửa đổi thông tin Đơn đặt hàng Chính xác trong Ginee sẽ chỉ cập nhật dữ liệu của Ginee và sẽ không được đồng bộ hóa thành Chính xác; Sửa đổi Đơn đặt hàng ở trạng thái Chính xác ở trạng thái không phải bản nháp sẽ không được đồng bộ hóa với Ginee.",
    "pleaseSelectProduct": "Vui lòng chọn sản phẩm",
    "gineeOnlySupportsCreatingPurchaseOrder": "Ginee chỉ hỗ trợ tạo Đơn đặt hàng cho nhiều sản phẩm trong cùng một kho. Vui lòng đăng nhập vào Chính xác và sửa đổi tất cả các sản phẩm vào cùng một kho.",
    "supplierContactDetails": "Chi tiết liên hệ của nhà cung cấp",
    "operationFailed": "Thao tác thất bại",
    "whenSelectingMultipleShopeeStoresThe": "Khi chọn nhiều cửa hàng Shopee, giá trị hàng tồn kho chỉ có thể cập nhật về kho mặc định; Nếu bạn cần cập nhật hàng lên nhiều kho của Shopee vui lòng chỉ chọn 1 cửa hàng",
    "requestTimedOutPleaseCheckThe": "Yêu cầu đã hết thời gian chờ, vui lòng kiểm tra mạng và thử lại.",
    "manageMultiwarehouseStock": "Quản lý kho nhiều kho",
    "supplierCodeSupplierCodeCannotBe": "Mã nhà cung cấp (Mã nhà cung cấp không thể chỉnh sửa sau khi tạo)",
    "hasReturnrefund": "Đã trả lại/Hoàn tiền",
    "batchEditingOnlySupportsModifyingA": "Chỉnh sửa hàng loạt chỉ hỗ trợ chỉnh sửa kho trong một kho. Nếu bạn cần chỉnh sửa tồn kho ở một vài kho, vui lòng sử dụng chỉnh sửa một sản phẩm",
    "invalidationIsInitiatedOnlyTheOms": "Sau khi quá trình vô hiệu được bắt đầu, chỉ Chuyến đi OMS sẽ bị vô hiệu và trạng thái của Chuyến đi trong kho thực hiện sẽ không thay đổi",
    "onlyTheOmsOutboundIsInvalided": "Chỉ OMS Outbound là không hợp lệ",
    "invalidateOmsAndFulfillmentOutbound": "OMS không hợp lệ và thực hiện gửi đi",
    "installationTutorial": "Hướng dẫn cài đặt",
    "howToUse": "Cách sử dụng",
    "downloadAndInstallThePlugin": "Tải xuống và cài đặt plugin",
    "goToChromeWebStoreTo": "Đi tới Cửa hàng Chrome trực tuyến để cài đặt",
    "ForPluginsHowToUse": "4. Để biết Cách sử dụng plugin, vui lòng tham khảo:",
    "IfYouCannotAccessThe": "3. Nếu bạn không truy cập được Chrome Web Store, hãy tải plugin tại đây:",
    "PluginInstallationLink": "2. Link cài đặt plugin:",
    "RecommendedBrowserGoogleChrome": "1. Trình duyệt khuyên dùng: Google Chrome",
    "pleaseDownloadAndUseTheScrape": "Hãy tải và sử dụng plugin Scrape, chức năng ổn định và nhanh chóng!",
    "editAndPublishFromTheStore": "Chỉnh sửa và xuất bản từ bản nháp sản phẩm của cửa hàng",
    "EditAndPublish": "4. Chỉnh sửa và xuất bản",
    "selectAStoreAndCopyThe": "Chọn cửa hàng và sao chép Sản phẩm chính vào bản nháp sản phẩm của cửa hàng",
    "CopyAndSellInStores": "3. Sao chép và bán tại cửa hàng",
    "massClaimAndCreateMasterProduct": "Yêu cầu đại chúng và tạo ra Sản phẩm chính",
    "ClaimTheMasterProduct": "2. Yêu cầu Sản phẩm Chính",
    "visitTheProductPageYouWant": "Truy cập trang sản phẩm bạn muốn cạo và sử dụng plugin để cạo nó chỉ bằng một cú nhấp chuột",
    "ScrapeProduct": "1. Sản phẩm cạo",
    "returnrefund": "Trở lại/đền bù",
    "editWarehouseStockInStockList": "Chỉnh sửa kho hàng trong danh sách kho",
    "importToUpdateStockIncreasedecrease": "Nhập để cập nhật tồn kho - Tăng/Giảm giá trị",
    "importToUpdateStockFinal": "Nhập để cập nhật hàng tồn kho - Giá trị cuối cùng",
    "purchaseOrdersSourcedFromAccurateDo": "Đơn đặt hàng có nguồn gốc từ Chính xác không hỗ trợ hủy trong Ginee. Nếu bạn cần hủy, vui lòng đăng nhập vào Chính xác để hủy.",
    "failedToCreateOmsInboundThe": "Không tạo được OMS Inbound: SKU trong WMS Inbound không tồn tại trong OMS hoặc là sản phẩm kết hợp",
    "IfYouHaveDoneThe": "5. Nếu bạn đã thực hiện các bước trên, vui lòng liên hệ bộ phận chăm sóc khách hàng",
    "IfEverythingIsNormalPlease": "4. Nếu mọi thứ vẫn bình thường, vui lòng thử lại sau 1 phút",
    "PleaseSwitchTheNetworkAnd": "3. Vui lòng chuyển mạng và thử lại",
    "PleaseConfirmThatTheBrowser": "2. Vui lòng xác nhận rằng trình duyệt không có cài đặt đặc biệt nào hoặc thử lại sau khi thay đổi trình duyệt",
    "PleaseCheckWhetherYouAre": "1. Vui lòng kiểm tra xem bạn có đang sử dụng trình cắm của bên thứ ba như chặn quảng cáo/tên miền, v.v. Bạn nên đóng trình cắm và thử lại",
    "inComplianceWithShopeePolicyBuyer": "Tuân thủ chính sách của Shopee, thông tin người mua sẽ không còn được hiển thị đối với các đơn hàng lịch sử vượt quá 90 ngày. Xin hãy hiểu sự bất tiện do việc này gây ra.",
    "inComplianceWithShopeePolicyThe": "Tuân thủ chính sách của Shop, Danh sách khách hàng sẽ không cung cấp dữ liệu đơn hàng của Shop.",
    "massAddHistory": "Thêm nhiều Lịch sử",
    "cancellation": "Hủy bỏ",
    "failedDelivery": "Chuyển phát thất bại",
    "ordersHasBeenMovedToReturnrefund": "Đơn hàng[%s] đã được chuyển sang Hoàn trả/Hoàn tiền",
    "moveToReturnrefund": "Chuyển sang Trả lại/Hoàn tiền",
    "ordersHasBeenMovedToCompleted": "Đơn hàng[%s] đã được chuyển sang Đã hoàn thành",
    "moveToCompleted": "Chuyển đến Đã hoàn thành",
    "youWantToMoveTheOrder": "Bạn có chắc chắn muốn chuyển đơn hàng sang Đã hoàn thành không?",
    "confirmToSync": "Xác nhận để đồng bộ hóa?",
    "purchaseSupplierManagement": "Purchase & Supplier Management",
    "afterClosingAllStoresWillNot": "Sau khi vô hiệu hóa, tất cả các cửa hàng sẽ không được đồng bộ hóa và hàng khuyến mãi của Shop sẽ không được bảo lưu",
    "wantToDisableAutomaticallyReservePromotion": "Bạn có chắc chắn muốn tắt tính năng Tự động đặt trước hàng khuyến mãi không?",
    "pushingPleaseRefreshThePageLater": "Đang đẩy, vui lòng tải lại trang sau để xem Trạng thái đẩy mới nhất",
    "wantToMassDisableAutoPush": "Bạn có chắc chắn muốn tắt hàng loạt tính năng Tự động đẩy không?",
    "wantToMassEnableAutoPush": "Bạn có chắc chắn muốn bật tự động đẩy hàng loạt không?",
    "onlyGineeWmsWarehouseSupportsEditing": "Chỉ kho Ginee WMS hỗ trợ chỉnh sửa Chiến lược đẩy",
    "editPushStrategy": "Chỉnh sửa chiến lược đẩy",
    "disableAutoPush": "Vô hiệu hóa tự động đẩy",
    "enableAutoPush": "Bật tự động đẩy",
    "autoPush": "Tự động đẩy",
    "pushStrategy": "Chiến lược đẩy",
    "theOrderWillBePushedTo": "Đơn hàng sẽ được đẩy trước đến kho bên thứ ba theo điều kiện cấu hình. Các lệnh khác vẫn sẽ được đẩy theo Đẩy Thông Thường. Đơn đặt hàng có vấn đề sẽ không được đẩy",
    "regardlessOfWhetherTheOrderIs": "Bất kể đơn hàng có đủ điều kiện vận chuyển hay không (không sắp xếp vận chuyển, không AWB, không nhãn vận chuyển), tất cả các đơn hàng được đồng bộ hóa với trạng thái \"Đã thanh toán\" và \"Sẵn sàng giao hàng\", sau khi Danh sách gửi đi được tạo sẽ ngay lập tức được đẩy sang kho của bên thứ ba, Đơn hàng có vấn đề sẽ không được đẩy",
    "theDefaultPushRuleIsThat": "Quy tắc đẩy mặc định là chỉ những đơn hàng đã có nhãn vận chuyển mới được phép đẩy đến kho của bên thứ ba và Đơn hàng có vấn đề sẽ không được đẩy",
    "strategyDescription": "Mô tả chiến lược",
    "deleteFailedThereIsAWarehouse": "Xóa không thành công! Có một nhà kho đang được sử dụng!",
    "onlyStrategiesThatAreNotAssociated": "Chỉ có thể xóa các chiến lược không liên quan đến kho",
    "wantToDeleteTheStrategy": "Bạn có chắc chắn muốn xóa chiến lược này không?",
    "allowEarlyPushWhenTheOrder": "Cho phép đẩy sớm khi trạng thái đơn hàng là \"Đã thanh toán\"",
    "pleaseSetAtLeastOneAdvance": "Vui lòng đặt ít nhất một điều kiện Đẩy sớm. Chỉ những lệnh đáp ứng các điều kiện đã chọn mới được kích hoạt để đẩy trước. Nếu không, Đẩy bình thường sẽ được thực hiện",
    "upToCustomStrategiesCan": "Có thể thêm tối đa 100 chiến lược tùy chỉnh",
    "warehouseUsed": "Kho đã qua sử dụng",
    "pushPaidOrdersInAdvance": "Đẩy đơn hàng \"trả tiền\" trước",
    "strategyName": "Tên chiến lược",
    "manageCustomPolicies": "Quản lý chiến lược tùy chỉnh",
    "customAdvancePush": "Đẩy sớm tùy chỉnh",
    "pushAllInAdvance": "Đẩy tất cả sớm",
    "basicPush": "Đẩy bình thường",
    "normalPushPushAllEarlyCustom": "Có thể đặt Đẩy bình thường, Đẩy tất cả sớm, Đẩy sớm tùy chỉnh",
    "thirdpartyWarehousePushSettings": "Cài đặt đẩy kho của bên thứ ba",
    "quantityLimitInfo": "Số lượng mua không được vượt quá 1000",
    "valueShouldBeAtLeastThe": "Tối thiểu không ít hơn  {count}",
    "purchaseLimit": "Giới hạn mua",
    "maxPerOrder": "Tối đa mỗi đơn hàng",
    "storesAreAutomaticallySetToEnable": "[Cửa hàng được ủy quyền mới tự động được đặt thành \"Bật\"] Đã tắt",
    "authorizedStoresAreAutomaticallySetTo": "[Cửa hàng được ủy quyền mới tự động được đặt thành \"Bật\"] Đã bật",
    "storeXDisableTheAutomaticallyReserve": "Cửa hàng {x} vô hiệu hóa công tắc chính Tự động đặt trước hàng khuyến mãi",
    "storeXEnableTheAutomaticallyReserve": "Cửa hàng {x} bật công tắc chính Tự động đặt trước kho khuyến mãi",
    "disableAutomaticallyReservePromotionStockMain": "Vô hiệu hóa tính năng tự động dự trữ cổ phiếu khuyến mãi Công tắc chính",
    "enableAutomaticallyReservePromotionStockMain": "Kích hoạt tính năng Tự động dự trữ hàng khuyến mãi Chuyển đổi chính",
    "enabledisableAutomaticallyReservePromotionStock": "Bật/Tắt Tự động dự trữ hàng khuyến mãi",
    "change": "Thay đổi",
    "superLinkAsAnOpenDoor": "Siêu liên kết như một cửa mở để kết nối tất cả các cửa hàng, liên kết, nội dung kinh doanh của bạn, v.v., trong một trang!",
    "actualInboundQty": "Số lượng nhập thực tế",
    "plannedInboundQty": "Số lượng nhập vào theo kế hoạch",
    "tipShopeePendingWaitingForShopee": "Mẹo: Shopee Đang chờ xử lý: Đang chờ Shopee cung cấp thông tin hậu cần hoặc Shopee vẫn đang tiến hành kiểm tra gian lận. Vui lòng đồng bộ hóa đơn hàng theo cách thủ công sau để có trạng thái mới nhất",
    "shopeeOrdersAreCurrentlyInPending": "Đơn hàng của Shop hiện đang ở trạng thái Đang chờ xử lý. Hiện tại, việc lấy nhãn vận chuyển không được hỗ trợ. Vui lòng thử lại sau",
    "gineeHasAutomaticallyFilteredXShopee": "Ginee đã tự động lọc {x} đơn hàng trên Shopee có trạng thái là Đang chờ xử lý và không được phép sắp xếp lô hàng",
    "Template": "Tệp tin mẫu",
    "promotionname": "Tên khuyến mãi",
    "EndTimeLeastDay": "Thời điểm kết thúc phải lớn hơn thời gian bắt đầu ít nhất một ngày",
    "theEndTimeMustBeLater": "Thời điểm kết thúc phải sau thời điểm bắt đầu",
    "TokoStartMinutesTips": "Thời điểm bắt đầu phải sau thời điểm hiện tại ít nhất 5 phút",
    "startTimeMustBeAtLeast": "Thời điểm bắt đầu phải sau thời điểm hiện tại ít nhất 5 phút",
    "OnceTheDiscountPromotion": "Một khi chương trình khuyến mãi được lưu thành công, thời gian hoạt động chỉ có thể bị rút ngắn",
    "theEndTimeMustBeGreater": "Thời điểm kết thúc phải lớn hơn thời gian bắt đầu ít nhất một giờ",
    "EntitledProducts": "Sản phẩm áp dụng",
    "RemoveProducts": "Xóa sản phẩm",
    "removeSelectedProducts": "Xóa sản phẩm đã chọn`",
    "RemoveAllProducts": "Xóa tất cả",
    "promotionDetails": "Chi tiết khuyến mãi",
    "UpdateAllProducts": "Cập nhật đến tất cả sản phẩm",
    "UpdateSelectedProducts": "Cập nhật đến sản phẩm đã chọn",
    "SetDiscountAt": "Cài đặt giảm giá thành",
    "stockMonitorAndSyncIsNot": "Giám sát và đồng bộ hóa chứng khoán chưa được bật",
    "inboundHistory": "Lịch sử gửi đến",
    "inboundDetails": "Chi tiết gửi đến",
    "currentlyDoesNotSupportUploadingeditingLazada": "Hiện tại không hỗ trợ tải lên/chỉnh sửa video Lazada",
    "noteYouCanPublishThisListing": "Lưu ý: Bạn có thể xuất bản danh sách này trong khi video đang được xử lý. Video sẽ được hiển thị trong danh sách sau khi được xử lý thành công",
    "pageNumber": "/ trang",
    "rejectionReason": "Lý do từ chối",
    "theShippingLabelIsEmptyPush": "Nhãn vận chuyển trống, đẩy không thành công",
    "theTrackingNumberIsEmptyPush": "Số theo dõi trống, đẩy không thành công",
    "doesntMeetTheOrderStatusRequirement": "Không đáp ứng yêu cầu về trạng thái đơn hàng, đẩy không thành công",
    "problemOrderStoredOrderPush": "Sự cố Đơn hàng & Đơn hàng đã lưu, đẩy không thành công",
    "reminderOnceTheStoreStockIs": "Nhắc nhở: Sau khi đẩy kho hàng thành công, nó sẽ không thể được khôi phục. Hãy đảm bảo rằng mỗi MSKU được liên kết với kho hàng và thiết lập kho hàng chính xác.",
    "whenANewOrderIsPlaced": "Khi một đơn hàng mới được đặt hoặc một đơn hàng bị hủy đối với MSKU, lượng hàng tồn kho của tất cả các sản phẩm trong kênh liên kết với MSKU sẽ giảm hoặc tăng một cách đồng đều",
    "theStockOfAllChannelProducts": "Kho của tất cả các sản phẩm kênh liên kết với cùng một MSKU sẽ được đẩy ngay lập tức theo \"{x}\"",
    "afterTurningOnStockMonitorAnd": "Sau khi bật Stock Monitor và Sync:",
    "addingeditingProductsOrStockInVivo": "Không hỗ trợ thêm/chỉnh sửa sản phẩm hoặc hàng tồn trong kho Vivo. Nếu cần chỉnh sửa vui lòng đăng nhập vào hệ thống Vivo",
    "beforeOutboundItHasBeenVerified": "Trước khi xuất hàng đã xác minh kho Vivo thiếu hàng, đẩy không thành công",
    "areYouSureToEnableStock": "Bạn có chắc chắn bật Stock Monitor và Sync không?",
    "areYouSureToDisableStock": "Bạn có chắc chắn tắt Stock Monitor và Sync không?",
    "plannedInboundCost": "Chi phí đầu vào theo kế hoạch",
    "onlyTheGineeWmsWarehouseSupports": "Chỉ kho Ginee WMS / Keeppack / FASTOCK hỗ trợ chỉnh sửa Chiến lược đẩy. Việc chỉnh sửa chiến lược đẩy sẽ chỉ có hiệu lực đối với các đơn hàng mới. Các lệnh hiện tại có thể kích hoạt lại Chiến lược đẩy thông qua \"Đồng bộ hóa\"",
    "editingThePushStrategyWillOnly": "Việc chỉnh sửa Chiến lược đẩy sẽ chỉ có hiệu lực đối với các đơn hàng mới. Các lệnh hiện tại có thể kích hoạt lại Chiến lược đẩy thông qua \"Đồng bộ hóa\"",
    "userManuallyEditedTheWarehouseStock": "Người dùng chỉnh sửa thủ công Kho hàng khiến Kho hàng có sẵn trở thành số âm",
    "multipleChannelProductsAreBoundTo": "Nhiều sản phẩm tại cửa hàng/kênh được liên kết với cùng một MSKU, điều này sẽ khiến cùng một cổ phiếu được bán cùng một lúc. Nếu khách hàng mua cùng lúc sẽ gây ra hiện tượng bán quá mức",
    "orderSyncDelayDueToDelay": "Trì hoãn đồng bộ hóa đơn hàng: do việc đồng bộ hóa đơn hàng từ thị trường bị chậm trễ, thực tế tồn kho đã được sử dụng cho đơn hàng khác",
    "failedPushStockStockNotPushed": "Đẩy hàng không thành công, hàng không được đẩy về cửa hàng khiến hàng trong kho không khớp với hàng Ginee",
    "TheStockWasEditedIn": "2. Cổ phiếu đã được chỉnh sửa trong Trung tâm người bán. Việc chỉnh sửa hàng trong Trung tâm người bán sẽ không được đồng bộ hóa với hàng trong kho của Ginee MSKU, điều này có thể gây ra tình trạng hàng trong kho không nhất quán giữa Ginee và kênh.",
    "AbnormalitiesWhenTheChannelAdjusts": "1. Bất thường khi kênh điều chỉnh cổ phiếu dẫn đến cổ phiếu không được điều chỉnh hoàn toàn.",
    "gineeSuccessfullyPushedTheStockAnd": "Ginee đã đẩy hàng thành công và thị trường đã nhận được hàng thành công. Có 3 khả năng:",
    "pushDelayDueToTheLarge": "Độ trễ đẩy do số lượng nhiệm vụ đẩy lớn, cổ phiếu không được đẩy ra thị trường đúng thời hạn",
    "noPushRecordFoundStockNot": "Không tìm thấy bản ghi đẩy, hàng không được đẩy vào cửa hàng, khiến hàng trong kho không khớp với hàng của Ginee",
    "thisStoreHasReservedPromotionStock": "Cửa hàng này có Hàng khuyến mại đã đặt trước, nhưng Hàng khuyến mãi dự trữ tự động của Ginee bị vô hiệu hóa, khiến hàng trong kho của cửa hàng không khớp với hàng trong kho của Ginee",
    "theStockMonitorAndSyncIs": "Chức năng Giám sát và Đồng bộ hóa Chứng khoán bị vô hiệu hóa nên kho hàng cập nhật của Ginee không được đẩy đến các cửa hàng",
    "missingOrderInformationsCurrentlyUnableTo": "Thiếu thông tin đơn hàng. Hiện chưa xác định được nguyên nhân",
    "pleaseCheckTheChangeReasonOn": "Vui lòng kiểm tra Lý do thay đổi ở phía bên phải của cột này",
    "pleaseCheckThePushFailedReason": "Vui lòng kiểm tra lý do đẩy không thành công trong Bản ghi đẩy hàng",
    "forOtherChannelsPleaseDirectlyContact": "Đối với các kênh khác, vui lòng liên hệ trực tiếp với bộ phận Dịch vụ khách hàng Ginee hoặc Tài khoản chính của bạn để kiểm tra thêm",
    "PleaseContactGineeCustomerService": "3. Vui lòng liên hệ với Dịch vụ khách hàng Ginee hoặc Tài khoản chính của bạn để kiểm tra thêm",
    "PleaseSyncTheProductThrough": "1. Vui lòng đồng bộ sản phẩm qua Kênh sản phẩm",
    "forShopeeBukalapakAndLazadaProducts": "Đối với sản phẩm Shopee, Bukalapak và Lazada:",
    "ifYouDidntEditTheStock": "Nếu bạn không chỉnh sửa kho hàng từ Trung tâm người bán, vui lòng làm theo các bước bên dưới:",
    "preventTheSameIssueHappeningIn": "Để ngăn vấn đề tương tự xảy ra trong tương lai, vui lòng bật Khuyến mãi tự động đặt trước",
    "toPreventTheSameIssueHappening": "Để ngăn sự cố tương tự xảy ra trong tương lai, vui lòng bật Trình giám sát và đồng bộ hóa chứng khoán",
    "pleaseEnableStockMonitorAndSync": "nên kho của cửa hàng sẽ được cập nhật theo Quy định đẩy hàng",
    "notesIfTheStockIsSmaller": "Lưu ý: Nếu hàng tồn kho nhỏ hơn (-1), vui lòng kiểm tra Bản ghi thay đổi hàng tồn kho để biết (-1) hàng tồn kho đầu tiên",
    "pleaseContactGineeCustomerServiceOr": "Vui lòng liên hệ với Dịch vụ khách hàng Ginee hoặc Tài khoản chính của bạn để kiểm tra thêm",
    "gineeOmsOrderusedtotal": "Đơn hàng Ginee OMS (Đã sử dụng/Tổng cộng)",
    "mskuUsedtotal": "MSKU (Đã sử dụng/Tổng cộng)",
    "currentlyDoesNotSupportUploadingeditingShopee": "Hiện tại không hỗ trợ tải lên/chỉnh sửa video Shopee",
    "gineeWmsOrderusedtotal": "Đơn hàng Ginee WMS (Đã sử dụng/Tổng cộng)",
    "walletCredit": "Tín dụng Ví",
    "gineeWmsConsignorusedtotal": "người gửi hàng Ginee WMS (Đã sử dụng/Tổng cộng)",
    "onlySupportViewingCustomerDataFrom": "Chỉ hỗ trợ xem data khách hàng từ 1/1/2023",
    "packageSplit": "Chia gói",
    "theMskuWasNotFoundIn": "Không tìm thấy MSKU trong kho của bên thứ ba. Vui lòng kiểm tra trạng thái đẩy của SKU trong trang danh sách chứng khoán. Nếu việc đẩy không thành công, vui lòng vận hành thủ công \"Thêm vào kho thực hiện\".",
    "changePrice": "Thay đổi giá",
    "discAfterTax": "Thuế sau khi giảm giá",
    "discBeforeTax": "Thuế trước khi giảm giá",
    "productTaxIncluded": "Đã bao gồm thuế sản phẩm",
    "additionalTax": "Thêm thuế vào giá",
    "vatRules": "Quy tắc thuế",
    "vat": "VAT",
    "selectAndAdd": "Chọn và thêm",
    "customerNotFoundDoYouWant": "Không tìm thấy khách hàng, bạn có muốn thêm nó bây giờ không?",
    "selectFromExistingCustomerList": "Chọn từ Danh sách khách hàng hiện có",
    "enterCustomerPhoneNumberToAdd": "Nhập Số điện thoại của Khách hàng để thêm Khách hàng hoặc tìm kiếm Khách hàng hiện tại",
    "addBuyer": "Thêm người mua",
    "unknown": "không xác định",
    "afterDeduction": "Sau khi khấu trừ",
    "byPercentage": "Theo phần trăm",
    "byAmount": "Theo số tiền",
    "deductionMethod": "Phương pháp khấu trừ",
    "deductionAmount": "Số tiền khấu trừ",
    "actualPayment": "Thanh toán thực tế",
    "pleaseAddProductsFirst": "Vui lòng thêm sản phẩm trước",
    "youWantToClearYourCart": "Bạn có chắc chắn muốn xóa giỏ hàng của mình không?",
    "vatIncluded": "Đã bao gồm VAT",
    "payable": "Số tiền đến hạn",
    "productSubtotal": "Tổng phụ sản phẩm",
    "viewDetails": "Xem chi tiết",
    "productDiscount": "Giảm giá sản phẩm",
    "wholeOrderReduction": "Giảm toàn bộ đơn hàng",
    "youSureYouWantToDelete": "Bạn chắc chắn muốn xóa?",
    "switchingStores": "Chuyển đổi cửa hàng...",
    "wantToChangeTheStoreTo": "Bạn có chắc chắn muốn thay đổi cửa hàng thành {x} không?",
    "clearShoppingCart": "Dọn dẹp giỏ hàng",
    "disc": "Đĩa.",
    "totalXItems": "Tổng số {x} mặt hàng",
    "deduction": "Khấu trừ",
    "checkOut": "Thủ tục thanh toán",
    "submitOrder": "Nộp đơn đặt hàng",
    "productBarcode": "Mã vạch sản phẩm",
    "pleaseScanTheProductBarcode": "Vui lòng quét mã vạch sản phẩm",
    "cashier": "Thu ngân",
    "signalStrength": "Cường độ tín hiệu",
    "language": "Ngôn ngữ",
    "loginToTheStoreSuccessfully": "Đăng nhập vào cửa hàng thành công!",
    "pleaseSelectAStore": "Vui lòng chọn cửa hàng",
    "thereIsNoAccessibleStorePlease": "Không có cửa hàng có thể truy cập, vui lòng liên hệ với quản trị viên để kích hoạt nó",
    "partialRefund": "Hoàn lại một phần",
    "refunded": "Đã hoàn tiền",
    "partialReturn": "Hoàn trả một phần",
    "completedDone": "Hoàn thành",
    "printReceipt": "In biên lai",
    "noNeedToPrintReceipt": "Không cần in biên lai",
    "cashReceived": "Số tiền nhận được",
    "transactionAmount": "số tiền giao dịch",
    "numberOfItems": "Số hạng mục",
    "customer": "Khách hàng",
    "transactionTime": "Thời gian giao dịch",
    "transactionSuccessful": "Giao dịch thành công!",
    "reorder": "Đặt hàng lại",
    "theShoppingCartWillBeAutomatically": "Giỏ hàng sẽ tự động được xóa, vui lòng chọn lại sản phẩm để đặt hàng",
    "theStoreHasChangedItsWarehouse": "Cửa hàng kho đã thay đổi và không thể đặt hàng!",
    "continueToPlaceAnOrder": "Tiếp tục đặt hàng",
    "removeOutOfStockProducts": "Loại bỏ sản phẩm hết hàng",
    "outOfStockQuantity": "Hết hàng Số lượng",
    "theFollowingProductsAreOutOf": "Các sản phẩm sau đã hết hàng! Tiếp tục đặt hàng sẽ dẫn đến bán quá mức",
    "card": "Thẻ",
    "returnFailedTheReturnedItemHas": "Trở về không thành công! Mặt hàng trả lại đã bị xóa. Vui lòng chọn Chỉ hoàn tiền và trả sản phẩm về kho theo cách thủ công",
    "returnFailedTheReturnWarehouseIs": "Trở về không thành công! Kho trả lại bị vô hiệu hóa. Vui lòng chọn Chỉ hoàn tiền và trả sản phẩm về kho theo cách thủ công",
    "refundToTheOriginalCard": "Hoàn tiền vào thẻ gốc",
    "refundMethod": "Phương thức hoàn tiền",
    "vatRefund": "Hoàn thuế GTGT",
    "afterOperatingReturnTheStockThe": "Sau khi vận hành Return, lượng hàng tồn kho sẽ được bổ sung trở lại kho ngay lập tức",
    "wantToReturn": "Bạn có chắc chắn muốn quay lại không?",
    "return": "Trở lại",
    "completed": "Hoàn thành",
    "cancelEntireOrder": "Hủy toàn bộ đơn hàng",
    "onceTheEntireOrderIsCancelled": "Sau khi toàn bộ đơn hàng bị hủy, đơn hàng sẽ được hoàn lại toàn bộ số tiền và lượng sản phẩm trong đơn hàng sẽ được bổ sung ngay vào kho",
    "cancelTheEntireOrder": "Bạn có chắc chắn muốn hủy toàn bộ đơn hàng không?",
    "onlyRefundsWillOnlyAffectThe": "Chỉ hoàn tiền sẽ chỉ ảnh hưởng đến Thanh toán thực tế của đơn hàng và sẽ không ảnh hưởng đến kho sản phẩm",
    "youWantToRefund": "Bạn có chắc chắn muốn Hoàn tiền không?",
    "orderActualPayment": "Đặt hàng Thanh toán thực tế",
    "amountExcludeVat": "Số tiền chưa bao gồm VAT",
    "companyPhoneNumber": "Số điện thoại công ty",
    "companymerchantTin": "Công ty/Thương gia TIN",
    "companymerchantLegalAddress": "Địa chỉ pháp lý của công ty/người bán",
    "companymerchantName": "Tên công ty/người bán",
    "totalAmountPaid": "TỔNG SỐ TIỀN THANH TOÁN",
    "addVatX": "Cộng: VAT ({x}%)",
    "amountDue": "Số tiền đến hạn",
    "lessDiscounts": "Ít hơn: Đĩa.",
    "lessVatx": "Trừ: VAT({x}%)",
    "totalAmount": "Tổng cộng",
    "vatExemptSales": "Bán hàng miễn thuế VAT",
    "zeroratedSales": "Bán hàng không được xếp hạng",
    "vatableSales": "Bán hàng chịu thuế GTGT",
    "itemBig": "MỤC",
    "siNo": "SI Không",
    "businessStyle": "Phong cách kinh doanh",
    "tinNo": "số TÍN",
    "soldTo": "Bán cho",
    "changes": "Thay đổi",
    "totalPaid": "Tổng số chi trả",
    "totalOrder": "Tổng số đơn hàng",
    "vatx": "Thuế GTGT({x}%)",
    "totalDisc": "Tổng đĩa.",
    "totalItem": "Tổng phụ sản phẩm",
    "cashierPerson": "Thu ngân",
    "orderNo": "Số thứ tự",
    "insufficientStockUnableToPurchaseProducts": "Không đủ hàng, không thể mua sản phẩm",
    "isNotCorrectPleaseCheckPhone": "Số không hợp lệ, vui lòng kiểm tra",
    "mobileRangeLengthWithPrefix": "Số điện thoại phải bắt đầu bằng số {prefix} và bao gồm {min} đến {max} chữ số.",
    "mobileLengthWithPrefix": "Số điện thoại phải bắt đầu bằng {prefix} và bao gồm {length} số",
    "mobileRangeLength": "Số điện thoại di động phải có từ {min} đến {max} chữ số",
    "posSettings": "Cài đặt POS",
    "manageGineePosStore": "Quản lý cửa hàng Ginee POS",
    "changingWarehousesWillReplaceTheProducts": "Việc thay đổi kho sẽ thay thế sản phẩm trong POS Store bằng sản phẩm và hàng tồn trong kho mới, vui lòng vận hành thận trọng",
    "taxCollectionPreference": "Ưu đãi thu thuế",
    "orderTotalAfterRefund": "Tổng đơn hàng sau khi hoàn tiền",
    "productQuantity": "Chất lượng sản phẩm",
    "selectReturnProduct": "Chọn trả lại sản phẩm",
    "receipt": "Biên lai",
    "newDiscountedPrice": "Giá ưu đãi mới",
    "taxDiscountPreference": "Ưu đãi giảm thuế",
    "pleaseEnterCorrectUsername": "Vui lòng nhập đúng tên người dùng",
    "orderPhoneNumber": "Số điện thoại đặt hàng",
    "customerNam": "tên khách hàng",
    "theAmountDueCannotBeLess": "Số tiền đến hạn không được nhỏ hơn Số tiền phải thu",
    "excessiveReturnIsNotSupported": "Hoàn trả quá mức không được hỗ trợ",
    "theStoreIsNotBoundTo": "Cửa hàng không bị ràng buộc vào kho",
    "orderDoesNotExist": "Đơn hàng không tồn tại",
    "theStoreDoesNotExist": "Cửa hàng không tồn tại",
    "productBarcodeXDoesNotExist": "Mã vạch sản phẩm {x} không tồn tại trong kho",
    "notExistInTheWarehouse": "MSKU {x} không tồn tại trong kho",
    "addMskuToTheWarehouseFirst": "MSKU không tồn tại trong kho, vui lòng thêm MSKU vào kho trước",
    "pay": "chi trả",
    "aOperationToCancelTheEntire": "{a} Hủy toàn bộ đơn hàng, Ghi chú {b}",
    "aOperationReturnAndRefundMsku": "{a} Hoạt động hoàn trả và hoàn tiền, MSKU {b}, Số lượng {c}, Số tiền hoàn lại {d}, Tiền hoàn thuế {e}, Nhận xét {f}",
    "aOperationRefundRefundAmountB": "{a} Hoạt động hoàn tiền, số tiền hoàn lại {b}, tiền hoàn thuế {c}, nhận xét {d}",
    "discountPriceCannotBeHigherThan": "Giá chiết khấu không được cao hơn giá gốc",
    "supportsInputNumNoMore": "Chỉ hỗ trợ nhập số/chữ cái/dấu cách/\"-\"/\"_\"",
    "ooOrders": "Đơn đặt hàng O2O",
    "ifYouDoNotHaveOms": "Nếu bạn không có quyền Quản lý khách hàng OMS, vui lòng liên hệ với quản trị viên để kích hoạt nó",
    "totalVatInc": "TOTAL",
    "netDue": "DO NET",
    "lessOtherDiscounts": "Ít hơn: Giảm giá khác",
    "customerPhoneNo": "Số điện thoại của khách hàng",
    "doNotAllowLazadaEase": "Giới hạn kênh: Không cho phép cập nhật kho sản phẩm Cửa hàng Chế độ dễ dàng của Lazada",
    "doNotAllowTokoOO": "Hạn chế về kênh: Không cho phép cập nhật hàng tồn kho cho sản phẩm trong kho Tokopedia O2O",
    "youHaveAddedAChannelSku": "Bạn đã thêm SKU kênh bằng một tên khác. Việc liên kết các sản phẩm khác nhau với MSKU có thể dẫn đến tình trạng bán quá mức. Vui lòng xác nhận rằng SKU kênh và MSKU là cùng một sản phẩm",
    "bindingSkuWithDifferentNamesMay": "Việc liên kết SKU với các tên khác nhau có thể dẫn đến bán quá mức, vui lòng thận trọng khi thực hiện",
    "channelReturnedErrorReachTheChannel": "Lỗi trả về {Kênh}: Đạt đến giới hạn QPS API của kênh, việc đẩy cổ phiếu không thành công.",
    "expiredStoresWillNotPushInventory": "Cửa hàng hết hạn sẽ không đẩy hàng, vui lòng ủy quyền lại càng sớm càng tốt!",
    "massReleaseWillReleaseTheReserved": "Mass Release sẽ phát hành Kho khuyến mãi dành riêng theo cấp độ sản phẩm (bao gồm tất cả các biến thể của sản phẩm đã chọn), vui lòng vận hành một cách thận trọng!",
    "recommendedOperation": "Hoạt động được đề xuất",
    "oversoldReasons": "Lý do bán quá mức",
    "pleaseEnable": "Vui lòng kích hoạt",
    "ThereAreCancellationreturnrefundOrdersOn": "3. Có các đơn đặt hàng hủy/trả lại/hoàn tiền trên kênh khiến lượng hàng tồn kho của kênh tự động tăng lên. Người mua có thể đặt hàng nhưng lượng hàng tồn kho ở Ginee vẫn là 0, dẫn đến tình trạng bán quá mức.",
    "PleaseCheckWhetherThereAre": "2. Vui lòng kiểm tra xem có bất kỳ đơn hàng nào bị hủy/trả lại/hoàn tiền trong Trung tâm người bán sau khi số lượng hàng tồn kho được cập nhật về 0 hay không",
    "skuxAlreadyExistsInOmsAnd": "SKU{x} đã tồn tại trong OMS và là sản phẩm kết hợp. Sản phẩm kết hợp không được phép xuất/nhập!",
    "oneclickOversoldCheck": "Kiểm tra bán quá mức",
    "massRelease": "Phát hành đại chúng",
    "thisOperationWillReleaseTheReserved": "Hoạt động này sẽ phát hành Kho khuyến mãi dành riêng của tất cả các sản phẩm trong cửa hàng, vui lòng vận hành một cách thận trọng!",
    "imageSizeMustBeGreaterThan": "Kích thước hình ảnh phải lớn hơn chiều cao {x}, chiều rộng {y}",
    "clickToUpdateBasicInformationSuch": "Nhấp để cập nhật thông tin cơ bản như gói, quyền, v.v.",
    "clickRefreshToObtainTheLatest": "Nhấp vào Làm mới để nhận các gói, quyền, yêu cầu ràng buộc người gửi hàng mới nhất và các thông tin khác",
    "bySelectedProducts": "Theo sản phẩm được chọn",
    "byStoreName": "Theo cửa hàng",
    "settingProductDescription": "Cài đặt Sản phẩm chính và Sao chép",
    "paymentReconciliation": "Đối chiếu thanh toán",
    "partialRelease": "Phát hành một phần",
    "released": "Phát hành",
    "toRelease": "Phát hành",
    "marketplaceReleasedAmountForTheOrder": "Số tiền phát hành trên thị trường cho đơn hàng",
    "orderPackageNumber": "Số gói đặt hàng",
    "marketplaceReleasedAmountEstimatedRelease": "Số tiền chênh lệch = Số tiền phát hành trên thị trường - Số tiền phát hành ước tính",
    "afterTheOrderIsCompletedThe": "Sau khi đơn hàng được hoàn thành, thị trường sẽ chuyển thu nhập từ đơn hàng cho người bán",
    "settlementDetails": "Chi tiết thanh toán",
    "amountDifference": "Số tiền chênh lệch",
    "marketplaceReleasedAmount": "Số tiền phát hành trên thị trường",
    "reconciliationStatusProvidedByTheChannel": "Tình trạng đối chiếu do Thị trường cung cấp",
    "feesCharges": "Lệ phí",
    "voucherRebates": "Phiếu quà tặng & Giảm giá",
    "totalExpenses": "Tổng chi phí",
    "estimatedReleaseAmountTotalRevenue": "Số tiền phát hành ước tính = Tổng doanh thu - Tổng chi phí",
    "estimatedReleaseAmount": "Số tiền phát hành ước tính",
    "timeoutPaymentIsNotSettledWithin": "Thời gian chờ: Thanh toán không được giải quyết trong vòng 24 giờ sau khi đơn hàng được hoàn thành",
    "settlementDataIsSyncedOnceA": "Dữ liệu thanh toán được đồng bộ hóa mỗi ngày một lần và một số đơn hàng đã phát hành có thể vẫn chưa được phát hành",
    "xDigitsOfEnglishChineseNumbers": "{x} chữ số tiếng Anh, tiếng Trung, số, dấu cách và - _ & %",
    "pushSuccessnoShippingLabel": "Đẩy thành công(Không có nhãn vận chuyển)",
    "pushSuccesshasShippingLabel": "Đẩy thành công(Có nhãn vận chuyển)",
    "clickTheOkButtonToRecreate": "Nhấp vào nút [OK] để tạo lại Doanh số bán ra dựa trên dữ liệu đơn hàng mới nhất",
    "youSureYouWantToRecreate": "Bạn có chắc chắn muốn tạo lại Sales Outbound không?",
    "atchEditingOfBrandsInMultiple": "chỉnh sửa hàng loạt nhãn hiệu ở nhiều cửa hàng chỉ hỗ trợ chọn \"No Brand\". Nếu bạn cần chọn thương hiệu khác, vui lòng chọn sản phẩm từ cùng một cửa hàng.",
    "editingOfBrandsInMultiple": "chỉnh sửa hàng loạt nhãn hiệu ở nhiều cửa hàng chỉ hỗ trợ chọn \"No Brand\". Nếu bạn cần chọn thương hiệu khác, vui lòng chọn sản phẩm từ cùng một cửa hàng.",
    "masstRemark": "Chỉnh sửa Nhận xét hàng loạt",
    "pushFailedKeeppackOutboundOrderStatus": "Đẩy không thành công, Keeppack không cho phép thao tác này",
    "printingFailedTheOrderIsMissing": "In không thành công, đơn hàng bị thiếu Nhãn vận chuyển PDF",
    "dearCustomer": "Khách hàng thân mến,",
    "yourFollowingResourcesHaveExceededThe": "Các tài nguyên sau của bạn đã vượt quá giới hạn gói, vui lòng điều chỉnh hoặc nâng cấp gói trước khi tiếp tục sử dụng Ginee OMS",
    "selectXStoresToContinueBinding": "Chọn {x} cửa hàng để tiếp tục ràng buộc và phần còn lại sẽ bị Vô hiệu hóa (sau khi vô hiệu hóa, nó sẽ không ảnh hưởng đến doanh số bán hàng của cửa hàng, nhưng Genie OMS sẽ không hiển thị các sản phẩm / đơn đặt hàng và dữ liệu khác của cửa hàng)",
    "selectedXStore": "Đã chọn {x} cửa hàng",
    "staffAccount": "Tài khoản nhân viên",
    "selectXStaffAccountsToContinue": "Chọn {x} tài khoản nhân viên sẽ tiếp tục được sử dụng, phần còn lại sẽ bị xóa (chỉ xóa quyền hệ thống Ginee OMS của tài khoản nhân viên, các hệ thống Ginee khác sẽ không bị ảnh hưởng)",
    "onlySupportsUploadingPdfFilesMax": "Chỉ hỗ trợ tải lên tệp PDF, tối đa. 1 MB",
    "shippingLabelHasBeenUploaded": "Nhãn vận chuyển đã được tải lên",
    "uploadShippingLabel": "Tải lên nhãn vận chuyển",
    "shippingLabelPdf": "Nhãn vận chuyển PDF",
    "cancellationTime": "Thời gian hủy",
    "shippingTime": "Thời gian gửi hàng",
    "inventorySynchronizationSwitchTurnedOff": "Công tắc đồng bộ hóa và giám sát chứng khoán: đã tắt",
    "theFollowingFunctionsWillBeAutomatically": "Các chức năng sau sẽ tự động tắt do gói hết hạn và cần kích hoạt thủ công sau khi gia hạn gói.",
    "afterThePackageExpiresStockSynchronization": "Sau khi gói hết hạn, công tắc đồng bộ hóa kho sẽ tự động tắt.",
    "lazadaPhOrdersOnlySupportPrinting": "Các đơn hàng được chọn bao gồm các đơn hàng từ trang PH và chỉ hỗ trợ in hóa đơn theo mẫu của Ginee",
    "companymerchantVatTinNo": "Công ty/Thương gia VAT TIN No.",
    "afterTheSwitchIsTurnedOn": "Sau khi kích hoạt, trước khi đẩy Danh sách gửi đi vào kho, trước tiên hệ thống sẽ lấy kho mới nhất của kho bên thứ ba đã đồng bộ hóa. Nếu Kho trong kho không đủ, việc đẩy sẽ bị hủy, Kho trong kho OMS sẽ được cập nhật và đơn hàng sẽ được chuyển sang Đơn hàng có vấn đề - Hết hàng;\nSau khi vô hiệu hóa, Outbound List sẽ được đẩy thẳng vào kho khi đáp ứng đủ điều kiện đẩy và hệ thống sẽ không xác minh lượng hàng trong kho của bên thứ ba có đủ hay không",
    "checkStockBeforePushing": "Kiểm tra hàng trước khi đẩy",
    "theStrategyImplementedByTheWarehouse": "Chiến lược áp dụng cho kho, vui lòng xem Mô tả chiến lược để được giải thích",
    "whenTheOrderMeetsThePush": "Lệnh sẽ được đẩy tự động khi đủ điều kiện đẩy, không cần đẩy thủ công",
    "shopeeApiRequiresThatAllVariation": "API Shopify yêu cầu không được phép thay đổi tất cả các tên biến thể khi chỉnh sửa sản phẩm (phải giữ lại ít nhất một tên biến thể ban đầu)",
    "sellerOwnFleet": "Hạm đội riêng của người bán",
    "addedToday": "Khách hàng mới trong ngày",
    "globalProduct": "Sản phẩm toàn cầu",
    "publishedSite": "Trang web đã xuất bản",
    "sellerStock": "Người bán cổ phiếu",
    "globalProductPrice": "Giá sản phẩm toàn cầu",
    "verifyMskuBeforePushingYIs": "Xác minh MSKU trước khi đẩy: {y} đã hết hàng trong kho WMS/Bên thứ ba và quá trình đẩy đã dừng lại",
    "theStockVerificationFailedBeforePushing": "Việc xác minh hàng tồn kho không thành công trước khi đẩy và đơn hàng gửi đi đã bị chặn. Lý do: MSKU:{x} hết hàng trong kho WMS/bên thứ ba.",
    "enablePush": "Có thể đẩy",
    "disablePush": "Tắt tính năng đẩy",
    "changeToDisable": "Thay đổi thành Tắt",
    "changeToEnable": "Thay đổi thành Kích hoạt",
    "productNameAccountNumber": "Tên sản phẩm & số tài khoản",
    "dueToShopeeApiLimitationsProducts": "Do hạn chế của API Shopee, các sản phẩm có hơn 50 biến thể không thể được xuất bản hoặc chỉnh sửa từ Ginee",
    "accurateWh": "Kho chính xác",
    "gineeWarehouse": "Kho Ginee",
    "gineeWarehouseAddress": "Địa chỉ kho Ginee",
    "gineeWarehouseName": "Tên kho Ginee",
    "manageTheMappingRelationshipBetweenChannel": "Quản lý mối quan hệ ánh xạ giữa Channel Warehouse và Ginee Warehouse",
    "youCanSelectTheChannelStore": "Bạn có thể chọn kênh cửa hàng để đẩy hàng tới. Cửa hàng CNSC/3PF chỉ hỗ trợ thiết lập Quy tắc đẩy hàng theo Tài khoản người bán",
    "bindingStatus": "Trạng thái ràng buộc",
    "channelWarehouseStatus": "Trạng thái kho kênh",
    "thePartiallyReleasedListRefersTo": "Danh sách được phát hành một phần đề cập đến các đơn hàng của Lazada có chứa nhiều sản phẩm. Một số sản phẩm đã được phát hành và một số vẫn chưa được phát hành. Dữ liệu sẽ được đặt ở trạng thái này.",
    "theReleasedListDisplaysTheReleased": "Danh sách Đã phát hành hiển thị dữ liệu đã phát hành thu được từ API",
    "customTax": "Thuế tùy chỉnh",
    "promotionFee": "Phí chiến dịch",
    "transactionFee": "Phí giao dịch",
    "highQuality": "Chất lượng cao",
    "commissionF": "Phí hoa hồng",
    "amsCommissionFee": "Phí hoa hồng AMS",
    "reverseShippingFeeForReturnedOrders": "Phí vận chuyển ngược",
    "plShippingFeeDiscount": "Giảm phí vận chuyển từ 3PL",
    "shopeeShippingRebate": "Giảm giá vận chuyển từ Shopee",
    "sellerAbsorbedCoinCashback": "Hoàn tiền bằng xu được tài trợ bởi người bán",
    "productDiscountsAndCashbackByShopee": "Giảm giá do Shop cung cấp",
    "productRefundAmount": "Số tiền hoàn trả",
    "productDiscountedPrice": "Quảng cáo sản phẩm người bán của bạn",
    "productOriginalPrice": "Giá sản phẩm gốc",
    "totalReleaseAmount": "Tổng số tiền phát hành",
    "settlementNo": "Quyết toán số",
    "settlementTime": "Thời gian giải quyết",
    "whetherTimeout": "Cho dù hết thời gian",
    "releasing": "Phát hành",
    "orderItem": "Mục thứ tự",
    "settlementDetailsAllPages": "Chi tiết thanh toán - tất cả",
    "settlementDetailsBySelected": "Chi tiết thanh toán - đã chọn",
    "ordersAllPages": "Đơn hàng - tất cả",
    "ordersBySelected": "Đơn hàng - đã chọn",
    "compatibleModels": "Các mẫu tương thích",
    "noticeTitle": "Chủ đề",
    "promotionStockCannotBeGreaterThan": "Số lượng hàng khuyến mãi không được lớn hơn số lượng hàng có sẵn",
    "pleaseEnterTheTitle": "Vui lòng nhập tiêu đề",
    "theOutboundListHasBeenCompleted": "Danh sách gửi đi đã được hoàn thành tại kho thực hiện và không thể đẩy lại",
    "commissionChargedBasedOnItemUnit": "Hoa hồng được tính dựa trên đơn giá mặt hàng (% tính trên đơn giá - chiết khấu của người bán)",
    "theOrderHasBeenCompletedBut": "Đơn hàng đã được hoàn thành nhưng nền tảng vẫn chưa thanh toán.",
    "paymentFeeForProcessingCharges": "Phí thanh toán phí xử lý (% tính trên Đơn giá + Phí vận chuyển của Người mua - Phiếu quà tặng của Người bán)",
    "listedPriceOfTheItemSold": "Giá niêm yết của mặt hàng được bán trước khi có bất kỳ khoản giảm giá hoặc phí nào cho người mua",
    "productVat": "VAT sản phẩm",
    "buyerRealName": "Tên thật của người mua",
    "salesInvoiceWillUseTheSelected": "Hóa đơn bán hàng sẽ sử dụng thông tin khách hàng cố định đã chọn khi bị tắt. Khi được bật, Hóa đơn bán hàng sẽ sử dụng thông tin khách hàng thực do đơn hàng kênh cung cấp. Xin lưu ý rằng thông tin khách hàng ở một số kênh sẽ bị che đi. Nên tắt tính năng này đối với những cửa hàng che giấu thông tin khách hàng",
    "realCustomerInformationInTheOrder": "Thông tin khách hàng thật trong đơn hàng",
    "excludingVat": "Chưa bao gồm VAT",
    "theSkuIsNotOversoldReason": "SKU không được bán quá mức. Lý do: Vì TikTok Shop sẽ trì hoãn việc phát hành hàng sau khi đơn hàng bị hủy, Ginee cũng sẽ trì hoãn việc phát hành Hàng bị khóa trong 12 giờ sau khi đơn hàng bị hủy được đồng bộ hóa với Ginee (trạng thái sẽ được hiển thị là \"Đang phát hành\"). Số lượng hàng tồn kho bị khóa được phát hành ≥ số lượng hàng tồn kho âm không phải là quá bán.",
    "ifYouNeedToReleaseThe": "Nếu bạn cần giải phóng trước Kho bị khóa với trạng thái \"Đang phát hành\", bạn có thể vô hiệu hóa Danh sách gửi đi tương ứng trong Quản lý gửi đi để buộc giải phóng Kho bị khóa (có nguy cơ bán quá mức, vui lòng vận hành thận trọng)",
    "publishedGlobalProduct": "Sản phẩm toàn cầu đã xuất bản",
    "editingTheGlobalProductPriceWill": "Chỉnh sửa Giá Sản Phẩm Toàn Cầu sẽ cập nhật giá sản phẩm của cửa hàng theo công thức tính",
    "editingTheGlobalProductStockWill": "Chỉnh sửa kho Sản phẩm Toàn cầu sẽ cập nhật kho sản phẩm của cửa hàng",
    "pleaseNotePleaseUseMAuthorization": "Xin lưu ý: Vui lòng sử dụng ủy quyền {m} cho các cửa hàng địa phương nói chung và ủy quyền {u} cho các cửa hàng CNSC/3PF",
    "theSkuIsOutOfStock": "SKU đã hết hàng trong WMS và không thể đẩy được",
    "theSkuDoesNotExistIn": "SKU không tồn tại trong WMS và không thể đẩy được",
    "theOrdersOutboundListHasBeen": "Danh sách gửi đi của đơn hàng đã được hoàn thành trong WMS và không thể đẩy được",
    "wmsIsProcessingTheOrder": "WMS đang xử lý đơn hàng",
    "authorizeMainAccount": "Ủy quyền tài khoản chính",
    "authorizeShopAccount": "Ủy quyền tài khoản cửa hàng",
    "customOrNo": "Tùy chỉnh HOẶC Không.",
    "onlySupportsFilesPdfPngJpeg": "Chỉ hỗ trợ các tệp PDF, png, jpeg, JPG nhỏ hơn 5M",
    "gineeWillAutomaticallyCreateAnInbound": "Ginee sẽ tự động tạo Danh sách nhập vào theo Số lượng nhập vào đầu vào, trực tiếp hoàn thành Danh sách nhập vào và tăng Hàng tồn kho, vui lòng xác nhận!",
    "youWantToAddToThe": "Bạn có chắc chắn muốn thêm vào kho và nhập hàng trực tiếp không?",
    "feeRefundreturn": "Phí hoàn tiền/trả lại",
    "fulfillmentFee": "Phí thực hiện",
    "shippingFeeChargedToSeller": "Phí vận chuyển được tính cho người bán",
    "otherFee": "Phí khác",
    "serviceFee1": "Phí dịch vụ",
    "rebateFromMarketplace": "Giảm giá từ Marketplace",
    "sellerPromotion": "Khuyến mãi của người bán",
    "productDiscountPrice": "Giá giảm giá sản phẩm",
    "pleaseRenewOrPurchaseOrderQuantity": "Vui lòng gia hạn hoặc mua số lượng đơn hàng càng sớm càng tốt, nếu không bạn sẽ không thể sử dụng các chức năng liên quan đến đơn hàng!",
    "yourGineeWmsOrderQuotaHas": "Hạn ngạch đặt hàng Ginee WMS của bạn đã bị vượt quá!",
    "yourGineeOmsOrderQuotaHas": "Hạn ngạch đặt hàng Ginee OMS của bạn đã bị vượt quá!",
    "orderQuotaExceeded": "Đã vượt quá hạn ngạch đặt hàng",
    "insufficientOrderQuota": "Hạn ngạch đặt hàng < 10%",
    "theOrderQuotaOfYourCurrent": "Vui lòng gia hạn hoặc mua tiện ích bổ sung hạn ngạch đơn hàng càng sớm càng tốt, nếu không bạn sẽ không thể sử dụng các chức năng liên quan đến chuyến đi!",
    "bySelectingAllLazadaWillConsider": "Bằng cách chọn TẤT CẢ, Lazada sẽ coi đó là bao gồm tất cả các tùy chọn bên dưới, không cần đánh dấu từng mục con riêng lẻ.",
    "abnormalExpiration": "Hết hạn bất thường",
    "pushOutboundOrders": "Đẩy danh sách đi",
    "pushInboundOrders": "Đẩy danh sách gửi đến",
    "directInbound": "Trực tiếp nhập kho",
    "updatedToBePushed": "(Cập nhật) Sẽ bị đẩy",
    "addressNameAndPhoneNumberWill": "Địa chỉ, Tên và Số điện thoại sẽ được sử dụng cho thông tin người gửi trong Nhãn vận chuyển tùy chỉnh của Ginee",
    "syncChannelAddress": "Địa chỉ kênh đồng bộ",
    "withholdingTax": "Thuế khấu trừ",
    "theCnscpfsChannelWarehouseIsNot": "Kho kênh của CNSC/3PF không bị ràng buộc với Kho Ginee, do đó không thể tạo Danh sách gửi đi",
    "afterSettingTheShippingMethodWhen": "Sau khi cài đặt phương thức vận chuyển, khi sắp xếp vận chuyển tại Ginee, phương thức vận chuyển và địa chỉ nhận hàng sẽ được chọn tự động để nâng cao hiệu quả vận chuyển.",
    "theOutboundOrderIsInvalidIn": "Lệnh gửi đi không hợp lệ trong WMS và không thể đẩy lại được.",
    "progress": "Tiến triển",
    "setShippingInfo": "Đặt thông tin vận chuyển",
    "outboundOrderHasBeenSuccessfullyPushed": "Đơn hàng gửi đi đã được đẩy thành công về kho để xử lý, bạn không thể sửa đổi thông tin đơn hàng gửi đi. Vui lòng thực hiện thủ công việc xuất hàng theo thông tin thay đổi đơn hàng",
    "mStoreAbnormallyExpired": "cửa hàng {m} hết hạn bất thường",
    "oneClickToChangeTheStores": "Một cú nhấp chuột để thay đổi Địa chỉ nhận hàng của cửa hàng",
    "changeAddressByStore": "Thay đổi địa chỉ cửa hàng",
    "youCanQuicklyChangePickupAddress": "Bạn có thể nhanh chóng thay đổi Địa chỉ nhận hàng cho tất cả dịch vụ hậu cần trong một cửa hàng Shopee sang địa chỉ khác",
    "invalidTrackingNumberPleaseGoTo": "Số theo dõi không hợp lệ, vui lòng đến Trung tâm người bán để gửi lại Số theo dõi hợp lệ càng sớm càng tốt!",
    "invalidTrackingNumberawb": "Số theo dõi/AWB không hợp lệ",
    "channelAbnormalOrders": "Đơn đặt hàng bất thường của kênh",
    "tryNoww": "Thử ngay bây giờ",
    "congratulationsYouHaveReceivedADay": "Chúc mừng! Bạn đã nhận được bản dùng thử miễn phí 7 ngày với đầy đủ các tính năng",
    "mskuTotal": "Tổng số lượng MSKU",
    "updateUsageEveryHours": "Cập nhật sử dụng cứ sau 4 giờ",
    "dailyUpdateUsage": "Cập nhật sử dụng hàng ngày",
    "replacementSuccessful": "Thay thế thành công!",
    "theFollowingOrdersNeedToAdd": "Các đơn hàng sau cần bổ sung thêm thông tin vận chuyển trước khi sắp xếp vận chuyển.",
    "toEnsureNormalUse": "Để đảm bảo sử dụng bình thường, vui lòng gia hạn kịp thời",
    "invoiceNumberMustBeDigits": "Số hóa đơn phải có 8 chữ số",
    "companymerchantInformation": "Thông tin về công ty/hàng hóa",
    "orDate": "OR Date",
    "orNo": "OR No",
    "pleaseEnterTheWarehouseCodeNo": "Vui lòng nhập mã kho, không quá 50 ký tự",
    "failedToPrintInvoice": "In hóa đơn không thành công",
    "thatIsOfficialReceiptNumberYou": "Tức là Số Biên nhận Chính thức. Bạn có thể đặt tiền tố tối đa 3 chữ cái (tiền tố có thể để trống) + 8 chữ số (phải là 8 chữ số) để tạo thành OR No. Mỗi lần in đơn hàng sẽ bắt đầu tăng dần theo số đã đặt. Xin lưu ý: Sau mỗi lần sửa đổi, nó sẽ được in theo các quy định mới nhất, vui lòng sửa đổi cẩn thận;",
    "theCompanyInformationSetInGinee": "Thông tin Công ty được thiết lập trong Tài khoản Ginee sẽ được hiển thị",
    "setCompanyInformation": "Đặt thông tin công ty",
    "atTheRequestOfThePhilippine": "Theo yêu cầu của chính phủ Philippines, tất cả hóa đơn của các cửa hàng Philippine đều phải sử dụng mẫu hóa đơn Philippine. Mô-đun có thể chọn có bao gồm Thuế tiêu thụ (VAT) hay không",
    "orderDate": "Ngày đặt hàng",
    "thisReceiptShallBeValidFor": "BIÊN BẢN NÀY CÓ HIỆU LỰC TRONG NĂM (5) NĂM KỂ TỪ NGÀY CẤP GIẤY CHỨNG NHẬN",
    "acknowledgementCertificateNo": "Acknowledgement Certificate No",
    "dateIssued": "Ngày phát hành",
    "seriesRange": "Phạm vi loạt",
    "handleProb": "Xử lý sự cố",
    "checksFailed": "Kiểm tra không thành công!",
    "successfullyDeletedTheWarehouse": "Đã xóa Kho thành công!",
    "startDeletion": "Bắt đầu xóa",
    "checksPassed": "Kiểm tra đã được thông qua!",
    "inventoryClearanceAllProductStocksAre": "Giải phóng hàng tồn kho: Tất cả hàng tồn kho hiện tại là 0",
    "manualInboundListAllDocumentsHave": "Danh sách gửi đến thủ công: Tất cả các tài liệu đã được hoàn thành",
    "purchaseInboundListAllDocumentsHave": "Danh sách mua hàng trong nước: Tất cả các tài liệu đã được hoàn thành",
    "manualOutboundListAllDocumentsHave": "Danh sách gửi đi thủ công: Tất cả các tài liệu đã được hoàn thành",
    "salesOutboundListAllDocumentsHave": "Danh sách bán hàng đi: Tất cả các tài liệu đã được hoàn thành",
    "deletingAWarehouseRequiresCheckingThe": "Việc xóa Kho yêu cầu kiểm tra dữ liệu trong Kho, hoàn thành tất cả Danh sách Nhập/Xuất và xóa tất cả kho SKU trước khi xóa",
    "checkingWarehouseDeletion": "Kiểm tra việc xóa kho",
    "beforeDeletingAWarehouseYouNeed": "Trước khi xóa Kho, bạn cần hoàn thành tất cả Danh sách Nhập/Xuất và xóa tất cả kho SKU",
    "oneAreYouSureYouWantTo": "Bạn có chắc chắn muốn xóa Kho không?",
    "theNumberOfLocalWarehouseHas": "Số lượng Kho địa phương đã đạt đến giới hạn (tối đa 50). Vui lòng xóa kho dư thừa và thử lại!",
    "oneFailedToSaveTheNumberOf": "Lưu thất bại! Số lượng SKU kênh liên kết với Sản phẩm kết hợp đã đạt đến giới hạn (tối đa 500)",
    "failedToSaveTheNumberOf": "Lưu thất bại! Số lượng sản phẩm được thêm vào Sản phẩm kết hợp đã đạt giới hạn (tối đa 10)",
    "failedToSaveTheBoundCombined": "Lưu thất bại! Sản phẩm Kết hợp bị ràng buộc của một sản phẩm {x} đã đạt đến giới hạn (có thể bị ràng buộc ở mức tối đa 100 Sản phẩm Kết hợp)",
    "failedToSaveTheVariationsOf": "Lưu thất bại! Số lượng biến thể của Sản phẩm chính đã đạt đến giới hạn (tối đa 200)",
    "bindingFailedTheChannelSkuBound": "Ràng buộc không thành công! Số lượng SKU kênh được liên kết với MSKU đã đạt đến giới hạn (tối đa 500)",
    "deletingWarehouse": "Đang xóa kho...",
    "deletingWarehouseProducts": "Xóa sản phẩm trong kho...",
    "deletingMIO": "Đang xóa đơn hàng gửi đến thủ công...",
    "deletingPIO": "Đang xóa đơn hàng gửi đến mua hàng...",
    "deletingMOO": "Đang xóa đơn hàng gửi đi thủ công...",
    "deletingSOO": "Xóa đơn hàng xuất đi...",
    "none": "Không có",
    "InboundStockIsOnlyValid": "1. Phiếu nhập kho chỉ có giá trị thêm kho và nhập hàng kho, còn dữ liệu hàng nhập kho sẽ được đưa vào kho hàng. 2. Sản phẩm kết hợp chỉ có thể được thêm vào kho và không hỗ trợ lưu trữ. 3. Các sản phẩm không cần liên kết có thể xóa trực tiếp. 4. Sau khi gửi, nếu sản phẩm đã có trong kho, hệ thống sẽ tự động lọc nên sản phẩm sẽ không được nhập lại.",
    "differentWeightdimensionsForVariations": "Trọng lượng/kích thước khác nhau cho các biến thể",
    "becauseAllVariationsHaveExactlyThe": "Vì tất cả các biến thể đều có trọng lượng và kích thước giống hệt nhau nên hệ thống sẽ tự động giúp bạn chuyển sang trọng lượng và kích thước cấp sản phẩm",
    "mskuXWarehouseNameOrWarehouse": "MSKU {0}, Tên kho hoặc Mã kho không tồn tại",
    "mskuDoNotExist": "MSKU {0} không tồn tại trong kho {1}, vui lòng thêm SKU vào kho trước",
    "masterProductXDoesNotExist": "Sản phẩm chính {x} không tồn tại",
    "theOutboundListHasBeenPushed": "Danh sách gửi đi đã được đẩy lên và đơn hàng không còn được phép chỉnh sửa",
    "orderXStatusDoesNotAllow": "Trạng thái đơn hàng {0} không cho phép chỉnh sửa",
    "orderXDoesNotExist": "Đơn hàng {0} không tồn tại",
    "thePaymentAmountShouldBeLess": "Số tiền thanh toán phải nhỏ hơn Tổng đơn hàng, vui lòng kiểm tra",
    "theTotalAmountOfProductsExceeds": "Tổng số lượng sản phẩm vượt quá giới hạn",
    "theOrderNoIsDuplicatedPlease": "Số đơn hàng bị trùng lặp, vui lòng chỉnh sửa số đơn hàng.",
    "theStoreCannotBeEmpty": "Cửa hàng không thể trống",
    "channelRequirementsXMustBeBetween": "Yêu cầu về kênh {x} phải nằm trong khoảng {y}~{z}",
    "itemCount": "Số mặt hàng",
    "variationOptionLength": "Độ dài tùy chọn biến thể",
    "variationTypeLength": "Độ dài loại biến thể",
    "itemDescriptionLength": "Độ dài mô tả mặt hàng",
    "itemImageCount": "Số lượng hình ảnh mục",
    "itemNameLength": "Độ dài tên mặt hàng",
    "wholesalePriceThresholdPercentage": "Phần trăm ngưỡng giá bán buôn",
    "sinceSomeProductsHaveTheSame": "Vì một số sản phẩm có cùng Trọng lượng và Kích thước cho tất cả các biến thể nên hệ thống sẽ tự động giúp bạn chuyển sang Trọng lượng và Kích thước ở cấp độ sản phẩm",
    "toEnableDifferentWeightdimensionsForEach": "Để bật Trọng lượng/Kích thước khác nhau cho từng biến thể, vui lòng truy cập trang chỉnh sửa duy nhất của sản phẩm Shopee và bật nút \"Trọng lượng/Kích thước khác nhau cho biến thể\"",
    "mskuHasBeenInbounded": "MSKU đã được gửi đến",
    "exportFailed": "Xuất thất bại",
    "staffToRemovePermissions": "Nhân viên xóa quyền:",
    "staffsToRetainPermissions": "Nhân viên giữ quyền:",
    "storesToDeactivate": "Cửa hàng để hủy kích hoạt:",
    "storesToRetain": "Cửa hàng cần giữ lại:",
    "staffAccountsToContinueUsing": "Tài khoản nhân viên để tiếp tục sử dụng:",
    "storesToContinueBinding": "Cửa hàng để tiếp tục đóng sách:",
    "pleaseConfirmTheStoreOrStaff": "Vui lòng xác nhận Cửa hàng hoặc Nhân viên bạn muốn giữ lại",
    "theDefaultPickupTimeLatest": "Thời gian đón mặc định là muộn nhất.",
    "theDefaultPickupTimeEarliest": "Thời gian đón mặc định là sớm nhất.",
    "doNotAutomaticallySelectThePickup": "Không tự động chọn thời gian đón.",
    "setTo": "Đặt thành:",
    "onlyApplicableToOrdersWithPickup": "Chỉ áp dụng cho các đơn hàng có dịch vụ nhận hàng trên Shopee và TikTok.",
    "automaticallySelectThePickupTime": "Tự động chọn thời gian đón.",
    "whenYouSelectLatest": "Khi bạn chọn tùy chọn này, thời gian lấy hàng muộn nhất sẽ được tự động điền vào trang vận chuyển.",
    "whenYouSelectEarliest": "Khi bạn chọn tùy chọn này, thời gian lấy hàng sớm nhất có thể sẽ được tự động điền vào trang vận chuyển.",
    "thisOrderIsFromAMultiwarehouse": "Đơn hàng này đến từ một cửa hàng có nhiều kho. Việc thay đổi địa chỉ có thể phải chịu thêm chi phí vận chuyển. Bạn có chắc chắn muốn cập nhật nó không?",
    "noManualSyncTask": "Không có tác vụ đồng bộ hóa thủ công",
    "taskIds": "ID nhiệm vụ",
    "manualSyncProduct": "Sản phẩm đồng bộ thủ công",
    "manualSyncOrder": "Thứ tự đồng bộ hóa thủ công",
    "syncHistoricalOrders": "Đồng bộ hóa các đơn đặt hàng lịch sử",
    "sinceTiktokShopWillDelayReleasing": "Vì TikTok Shop sẽ trì hoãn việc phát hành hàng sau khi đơn hàng bị hủy, Ginee cũng sẽ trì hoãn việc phát hành Hàng bị khóa trong 12 giờ sau khi đơn hàng bị hủy được đồng bộ hóa với Ginee (trạng thái sẽ được hiển thị là \"Đang phát hành\").",
    "syncProgress": "Tiến triển",
    "notAbnormal": "Không bất thường",
    "reservationFailedTheBoundGineeWarehouse": "Đặt hàng không thành công, không tìm thấy kho Ginee liên kết (Shopee CNSC/3PF các cửa hàng giữ lại Hàng khuyến mãi theo mối quan hệ liên kết giữa kho cửa hàng và kho Ginee)",
    "discountType": "Loại giảm giá",
    "percentageOff": "Giảm phần trăm",
    "fixedPrice": "Giá cố định",
    "variationlevel": "Cấp biến thể",
    "productlevel": "Cấp sản phẩm",
    "youWantToSwitchTheDatabase": "Bạn có chắc chắn muốn chuyển cơ sở dữ liệu sang {x} không?",
    "allSettingsAndMappingsWillBe": "Tất cả các cài đặt và ánh xạ sẽ bị xóa và MSKU sẽ cần được khớp lại",
    "changeDatabase": "Chuyển cơ sở dữ liệu",
    "pleaseSelectTheDatabaseToChange": "Vui lòng chọn cơ sở dữ liệu để chuyển sang",
    "ifYouSwitchToANew": "Nếu bạn chuyển sang cơ sở dữ liệu mới, tất cả các cài đặt và ánh xạ sẽ cần được định cấu hình lại. Nhưng đừng lo lắng, dữ liệu đã được đẩy đến Accurate sẽ không bị xóa.",
    "mappingS": "Ánh xạ",
    "channelStoreName": "Tên cửa hàng kênh",
    "customersRealName": "Tên thật của khách hàng",
    "productTaxSettings": "Cài đặt thuế sản phẩm",
    "whetherTheProductWillIncludeVat": "Sản phẩm có bao gồm VAT trên hóa đơn bán hàng không",
    "noVat": "Không có VAT",
    "addVat": "Thêm VAT",
    "gineeWarehouseCanOnlyBeMapped": "Kho Ginee chỉ có thể được ánh xạ với 1 kho Accurate, vui lòng hoàn thành ít nhất 1 ánh xạ kho",
    "dataLinkedWithBranchesBanksAnd": "Dữ liệu được liên kết với các chi nhánh, ngân hàng và COA khi giao dịch đơn hàng được gửi đến Accurate",
    "shippingFeeChargedToSellersCalculated": "Phí vận chuyển tính cho người bán được tính dựa trên Chi tiết thanh toán của thị trường",
    "otherDeductedFeesProvidedByThe": "Các loại phí khấu trừ khác theo quy định tại Chi tiết thanh toán của thị trường",
    "rebateAndDiscountAmountAsProvided": "Số tiền hoàn lại và chiết khấu theo quy định tại Chi tiết thanh toán của thị trường",
    "sellerPromotionFeeAsProvided": "Phí khuyến mại của người bán theo quy định tại Chi tiết thanh toán của thị trường",
    "serviceFeeCommissionFeeAndOther": "Phí dịch vụ, Phí hoa hồng và các loại Phí khác do thị trường khấu trừ theo quy định tại Chi tiết thanh toán của thị trường",
    "importantNotesErrorNotificationTheCumulative": "Lưu ý quan trọng: Thông báo lỗi \"Thời hạn đặt hàng tích lũy không được vượt quá 18 tháng\" khi mua Lazada-Authorization-only có nghĩa là bạn đã mua 18 tháng Authorization-only. Vui lòng {x}, sau đó nhấp vào Sử dụng dịch vụ để hoàn tất tích hợp",
    "historicalOrdersSettlementDataWillNot": "Dữ liệu thanh toán của các đơn hàng lịch sử sẽ không được đồng bộ hóa",
    "variousServiceCharges": "Các loại phí dịch vụ khác nhau",
    "thePromotionPeriodMustBeLonger": "Thời gian khuyến mại phải dài hơn 10 phút và ngắn hơn 365 ngày",
    "purchaseLimitCannotBeGreaterThan": "Giới hạn mua hàng không được lớn hơn Hàng có sẵn",
    "fulfilledByChannel": "Hoàn tất bởi Kênh",
    "jitPo": "JIT PO",
    "advanceFulfillment": "Hoàn tất trước",
    "notSpecial": "Không đặc biệt",
    "createdByShopee": "Tạo bởi Shopee",
    "matchPending": "Đang chờ khớp",
    "discountPriceIsLowerThanM": "Giá chiết khấu thấp hơn {m}",
    "theDiscountRateMustNotbeHigher": "Tỷ lệ chiết khấu không được cao hơn {m}%",
    "limitationMethod": "Phương pháp giới hạn",
    "variationDimension": "Kích thước biến thể",
    "noLimit": "Không giới hạn",
    "invalidDiscount": "chiết khấu không hợp lệ",
    "invalidPrice": "giá không hợp lệ",
    "TheStartTimeMustBe": "1. Thời gian bắt đầu phải muộn hơn thời gian hiện tại",
    "ThePromotionPeriodMustBe": "2. Thời gian khuyến mại phải dài hơn 10 phút và ngắn hơn 365 ngày",
    "productId": "Mã sản phẩm",
    "variationId": "Mã biến thể",
    "thePromotionsPeriodMustBeLonger": "Thời gian khuyến mại phải dài hơn 1 giờ",
    "thePromotionuPeriodMustBeLonger": "Thời gian khuyến mại phải dài hơn 10 phút và ngắn hơn 365 ngày",
    "unavailableStockIntransit": "Hàng không có sẵn (Đang vận chuyển)",
    "stockTransferredToSortingCenterBy": "Hàng đã được Shopee chuyển đến trung tâm phân loại trước",
    "advanceStock": "Advance Stock",
    "outboundQuantityMustBe": "Số lượng hàng gửi đi phải>0",
    "needHelpWereHereForYou": "Need help? We're here for you. Contact us M-F, 9AM-6PM",
    "orderXIsMatchedWithShopee": "Đơn hàng [{0}] khớp với Đơn hàng hoàn tất trước của Shopee [{1}], các đơn hàng được hợp nhất",
    "bookingId": "Mã đặt chỗ",
    "availStock": "Cổ phiếu có sẵn",
    "notMatchedToOrder": "Không khớp với Đơn hàng",
    "importToCreateCombinedProduct": "Nhập để tạo Sản phẩm kết hợp",
    "youCanImportUpTo": "Bạn có thể nhập tối đa 10.000 dữ liệu cùng một lúc (định dạng: xlsx, xls) để tạo hàng loạt Sản phẩm kết hợp",
    "matched": "Đã khớp",
    "marketplaceSpecialOrder": "Đơn hàng đặc biệt của Marketplace"
};
});

var vn = createCommonjsModule(function (module, exports) {
exports.__esModule = true;
exports.vn = void 0;
exports.vn = {
    "globalNoData": "Không có Dữ liệu",
    "globalPleaseSelect": "Vui lòng Chọn",
    "globalPleaseEnter": "Vui lòng Nhập",
    "globalsYearly": "Hàng năm",
    "globalsMonthly": "Hàng tháng",
    "globalsWeekly": "Hàng tuần",
    "globalsYesterday": "Hôm qua",
    "globalsExportDisabled": "Hết hạn",
    "globalsExportFailed": "Tải xuống thất bại",
    "globalsExportFinished": "Hoạt động",
    "globalsExportINProcess": "Đang xử lý",
    "globalsExportStatus": "Trạng thái",
    "globalsPackageExpired": "Gói của bạn đã hết hạn",
    "globalsLater": "Để sau",
    "globalsContactNow": "Liên hệ ngay",
    "globalsContactUsPackage": "Vui lòng liên hệ với chúng tôi để biết thông tin và báo giá",
    "isRequired": "Dòng này là bắt buộc",
    "globalsGoContinue": "Tiếp tục",
    "globalsBrowserBlockedInfo": "Phát hiện cửa sổ bật lên mới đã bị trình duyệt chặn, vui lòng nhấp vào \"Tiếp tục\" để mở cửa sổ trang mới (bạn cũng có thể tìm kiếm \"Phương pháp Xóa chặn trình duyệt\" trong Trung tâm trợ giúp để bỏ chặn)",
    "globalsNotification": "Thông báo",
    "globalsPleaseSelect": "Vui lòng Chọn",
    "globalsIsRequired": "Dòng này là bắt buộc",
    "globalsOperationGuide": "Hướng dẫn thao tác",
    "globalsSyncError": "Đồng bộ Thất bại",
    "globalsSynchronizing": "Đang đồng bộ",
    "globalsSync": "Đồng bộ",
    "globalsSyncSuccess": "Hoàn thành Đồng bộ",
    "globalsNoProductYet": "Chưa có sản phẩm",
    "globalsSeeMore": "Xem { more } sản phẩm",
    "globalsSeeMoreRetract": "Thu gọn",
    "globalsSeeMoreExpand": "Xem thêm",
    "globalsNoName": "Không tên",
    "globalsTotalPage": "Tổng { total }",
    "globalsReset": "Đặt lại",
    "globalsSearch": "Tìm kiếm",
    "globalNoEmptyText": "Không có Dữ liệu",
    "globalNoPermission": "Tài khoản của bạn không có quyền truy cập, vui lòng liên hệ với quản trị viên cửa hàng để yêu cầu hoặc nâng cấp",
    "globalAction": "Thao tác",
    "globalTaskResult": "Kết quả",
    "globalTaskFailedNumber": "Thất bại",
    "globalTaskTotalNumber": "Tổng",
    "globalTaskSuccessNumber": "Thành công",
    "globalTaskFailed": "Một số đơn hàng xử lý thất bại, vui lòng thử lại sau",
    "globalTaskSuccess": "Thao tác thành công",
    "globalTaskWait": "Đang xử lý, vui lòng đợi",
    "globalStatus_unpublished": "Chưa Đăng",
    "globalStatus_update_failed": "Đăng Thất bại",
    "globalStatus_delete": "Đã xóa",
    "globalStatus_draft": "Bản nháp",
    "globalStatus_disabled": "Đã hủy bỏ",
    "globalStatus_banned": "Đã cấm bán",
    "globalStatus_sold_out": "Đã bán hết",
    "globalStatus_live": "Hoạt động",
    "globalStatus_process": "Đang xử lý",
    "globalStatus_all": "Tất cả",
    "globalName": "Global Name VN",
    "goToLinkStoreTip": "Không có cửa hàng được ủy quyền, vui lòng ủy quyền cửa hàng trước rồi hãy thêm sản phẩm",
    "goToLinkStore": "Đi đến ủy quyền cửa hàng",
    "remove": "Xóa",
    "distributionSettings": "Cài đặt fulfillment",
    "menuThirdPartyWarehouseProductList": "Danh sách sản phẩm kho bên thứ 3",
    "serviceLink": "tập đoàn Ginee Support",
    "thirdPartyWarehouseStocktakingList": "Kéo tồn từ kho bên thứ ba",
    "explore": "Chọn hàng",
    "authPlatform": "Cấp quyền",
    "yilianFulfillment": "YiLian Fulfillment",
    "syncdiconnected": "Hủy liên kết",
    "syncsuccess": "Đồng bộ thành công",
    "syncing": "Đang đồng bộ",
    "commissionSetting": "Cài đặt Hoa hồng",
    "LAInvoiceSettingsTab": "Cài đặt Hóa đơn",
    "ExportTemplateTab": "Mẫu Xuất",
    "documentTemplatSeettings": "Mẫu tài liệu",
    "selectfulfillment": "Vui lòng chọn Nhà Fulfillment",
    "haventBound": "Bạn chưa liên kết dịch vụ Fulfillment, liên kết ngay?",
    "newRule": "Quy tắc mới",
    "menuProductTiki": "Sản phẩm/Tiki",
    "logout": "Đăng xuất",
    "productTools": "Công cụ",
    "menuCustomer": "Khách hàng",
    "menuPromotion": "Khuyến mãi",
    "menuWarehouse": "Kho",
    "GineeChat": "Chat",
    "menuGineeCapital": "Tiền vốn",
    "addIntegration": "Thêm ủy quyền",
    "thirdpartyWarehouseList": "Kho bên thứ 3",
    "menuIntegration": "Ủy quyền",
    "logisticsReport": "Báo cáo Vận chuyển",
    "customerReport": "Báo cáo Khách hàng",
    "inventoryReport": "Báo cáo Tồn kho",
    "salesReport": "Đối soát Bán hàng",
    "blacklist": "Danh sách đen",
    "customerList": "Danh sách Khách hàng",
    "discountList": "Danh sách Khuyến mãi",
    "discountManagement": "Quản lý Khuyến mãi",
    "transfer": "Chuyển nhượng",
    "stoktaking": "Kiểm kê Tồn kho",
    "menuInbound": "Phiếu nhập kho",
    "menuOutbound": "Phiếu xuất kho",
    "menuProductLocation": "Kệ hàng",
    "warehouseList": "Danh sách kho",
    "stockPullRecord": "Lịch sử kéo tồn kho bên thứ 3",
    "safetyStockManagement": "Quản lý Tồn kho An toàn",
    "stock": "Tồn kho",
    "storeMoving": "Chuyển cửa hàng",
    "productScrape": "Sưu tập Sản phẩm",
    "channelProduct": "Sản phẩm của Kênh",
    "masterProductList": "Danh sách",
    "orderProcess": "Xử lý Đơn hàng",
    "allOrders": "Tất cả Đơn hàng",
    "MenuOrders": "Đơn hàng",
    "putAside": "Tạm gác lại",
    "autoBump": "Tự động đẩy Sản phẩm",
    "oneStoresProductsToAnyStores": "Sao chép sản phẩm của một cửa hàng sang các cửa hàng khác để nhanh chóng mở cửa hàng",
    "blibliProductList": "Danh sách sản phẩm Blibli",
    "FulfillSetting": "Quản lý cấu hình",
    "MassAdd": "Tạo Mẫu Hàng loạt",
    "PromotionEditTpl": "Chỉnh sửa Mẫu",
    "PromotionAddTpl": "Tệp tin mới",
    "PromotionTpl": "Mẫu Khuyến mãi",
    "IntegrationsUpdateStore": "Cập nhật thông tin cửa hàng",
    "PrintPickingListTemplate": "Mẫu Phiếu lấy hàng",
    "PrintInvoiceTemplate": "Mẫu Hóa đơn",
    "PrintPackingListTemplate": "Mẫu Phiếu đóng gói",
    "PrintLabelTemplate": "Mẫu Vận đơn",
    "addBundleISKU": "Thêm ISKU Combo",
    "editBundleISKU": "Chỉnh sửa ISKU Combo",
    "menuInboundManagementSubmit": "Quản lý Phiếu nhập kho / Nhập kho Phiếu nhập kho",
    "menuInboundManagementDetail": "Quản lý Phiếu nhập kho / Chi tiết Phiếu nhập kho",
    "menuInboundManagementEdit": "Quản lý Phiếu nhập kho / Chỉnh sửa Phiếu nhập kho",
    "menuInboundManagementAdd": "Quản lý Phiếu nhập kho / Tạo Phiếu nhập kho",
    "menuOutboundManagementSubmit": "Quản lý Phiếu xuất kho / Xuất Phiếu xuất kho",
    "menuOutboundManagementDetail": "Quản lý Phiếu xuất kho / Chi tiết Phiếu xuất kho",
    "menuOutboundManagementEdit": "Quản lý Phiếu xuất kho / Chỉnh sửa Phiếu xuất kho",
    "menuOutboundManagementAdd": "Quản lý Phiếu xuất kho / Tạo Phiếu xuất kho",
    "safetyStockSettings": "Cài đặt Tồn kho An toàn",
    "storeStockChangeHistory": "Lịch sử đẩy cửa hàng",
    "stockPullRecordDetail": "Lịch sử kéo Tồn kho Chi tiết",
    "thirdpartyWarehouseInventoryPullRecord": "Lịch sử kéo bên thứ 3",
    "stockInOutRecord": "Lịch sử Xuất/Nhập kho",
    "editWarehouseStock": "Chỉnh sửa Tổng tồn kho",
    "editLocalISKU": "Chỉnh sửa SKU chính",
    "editISKU": "Chỉnh sửa Kho sản phẩm",
    "binPsku": "Liên kết SKU Cửa hàng",
    "addISKU": "Thêm Kho sản phẩm",
    "EditOrderTpl": "Chỉnh sửa Mẫu",
    "AddOrderTpl": "Thêm Mẫu",
    "CRMHistory": "Lịch sử",
    "CRMSales": "Quản lý Bán hàng",
    "CRMTelemarketing": "Quản lý CRM",
    "SubscribeNow": "Đăng ký ngay",
    "RenewNow": "Gia hạn ngay",
    "PaymentSubscriptionPrice": "Gói và giá",
    "PaymentSubscriptionResult": "Kết quả Thanh toán",
    "AccountPackageSetting": "Tài khoản & Gói hiện tại",
    "PackageSubscription": "Đăng ký",
    "TransactionHistory": "Lịch sử giao dịch",
    "menuScrapingSettings": "Cài đặt Sưu tập",
    "ExclusiveSubscription": "Theo dõi phổ biến",
    "TopPopularStore": "Cửa hàng bán chạy",
    "AccountManagement": "Quản lý gói",
    "OrderBatchLabel": "In hàng loạt",
    "GineeChartRoom1": "Chat đa kênh",
    "InvoicePrintTemplateSettings": "Mẫu in Hóa đơn",
    "PackingListPrintTemplateSettings": "Mẫu in Phiếu đóng gói",
    "ShippingLabelTemplateSettings": "Mẫu in Vận đơn",
    "SenderInformationSettings": "Thông tin người gửi",
    "ShippingRuleSettings": "Phương thức giao hàng",
    "GINEEFulfillment": "Ginee Fulfillment",
    "YUEHAIFulfillment": "YUEHAI Fulfillment",
    "FASTOCKFulfillment": "FASTOCK Fulfillment",
    "JDFulfillment": "JD Fulfillment",
    "OrderSettings": "Cài đặt Đơn hàng",
    "CollectEdit": "Thu thập vào Sản phẩm chính",
    "CollectSetting": "Cài đặt Thu thập",
    "ScrapeList": "Danh sách Thu thập",
    "ReportChart": "Báo cáo",
    "ProductsCollection": "Thu thập Sản phẩm",
    "editrole": "Chỉnh sửa Vai trò",
    "addrole": "Thêm Vai trò",
    "RoleSetting": "Quản lý Vai trò",
    "StaffSetting": "Quản lý Nhân viên",
    "addstaff": "Thêm Nhân viên",
    "staffManagementAdd": "Quản lý Nhân viên/Thêm Nhân viên",
    "staffManagementEdit": "Quản lý Nhân viên/ Chỉnh sửa Nhân viên",
    "editstaff": "Chỉnh sửa Nhân viên",
    "subscribe": "Đăng ký",
    "days": "Ngày",
    "freetrial": "Dùng thử Miễn phí",
    "contactus": "Liên hệ chúng tôi",
    "EditCustomer": "Chỉnh sửa Khách hàng",
    "PromotionManagement": "Quản lý Khuyến mãi",
    "mProductSettings": "Cài đặt Sản phẩm chính",
    "productSettings": "Cài đặt Sản phẩm",
    "CustomerDetail": "Chi tiết Khách hàng",
    "NewCustomer": "Thêm Khách hàng",
    "GroupManagement": "Nhóm khách hàng",
    "CustomerManagement": "Quản lý Khách hàng",
    "MasterProductSettings": "Cài đặt Sản phẩm chính",
    "StockSetting": "Cài đặt Tồn kho",
    "menuAddProductToWarehouseEditStock": "Điền Tồn kho",
    "menuAddProductToWarehouse": "Thêm Sản phẩm vào kho",
    "stockManageList": "Danh sách Tồn kho",
    "stockManage": "Quản lý Tồn kho",
    "BarChart": "Phân tích kinh doanh",
    "Masseditcategories": "Chỉnh sửa danh mục và thuộc tính hàng loạt",
    "MassEdit": "Chỉnh sửa hàng loạt",
    "ShippingSetting": "Cài đặt Vận chuyển",
    "BasicInformation": "Thông tin cơ bản",
    "BasicSetting": "Cài đặt cơ bản",
    "ToolTipInfo": "Trạng thái: Đang bán, Ngừng bán, Đã bán hết, Cấm bán",
    "Result": "Kết quả",
    "PublishWait": "Đăng sản phẩm tại sàn đang xử lý, vui lòng đợi giây lát",
    "UpdateFailed": "Cập nhật thất bại",
    "Publishing": "Đã sao chép thành nháp",
    "TryAgain": "Thử lại",
    "SomeStoresCopyFailed": "Một vài cửa hàng sao chép thất bại",
    "CopySuccess": "Sao chép thành công",
    "ViewNow": "Xem ngay",
    "Close": "Đóng",
    "NoCopyStore": "Chưa có cửa hàng để sao chép, hãy ủy quyền cửa hàng ngay để sao chép",
    "CopyPublish": "Đăng lên",
    "CopyAsDraft": "Sao chép thành bản nháp",
    "CopyCount": "Sản phẩm chờ sao chép",
    "Live": "Sản phẩm đang bán",
    "All": "Tất cả sản phẩm",
    "Product": "Sản phẩm",
    "Store": "Cửa hàng",
    "Channel": "Kênh bán",
    "CopyTo": "Sao chép đến",
    "CopyFrom": "Sao chép từ",
    "CopyAlertInfo": "Sao chép sản phẩm đến cửa hàng khác trong cùng một kênh để mở kinh doanh nhanh chóng",
    "OperationGuide": "Hướng dẫn thao tác",
    "CopyStore": "Sao chép Cửa hàng",
    "AddStore": "Thêm Cửa hàng",
    "Copy": "Sao chép",
    "StoreList": "Danh sách Cửa hàng",
    "MyStores": "Sao chép Cửa hàng",
    "customerCenter": "Xin chào, chúng tôi có thể giúp gì cho bạn? Vui lòng liên hệ với chúng tôi vào 09:00 - 18:00",
    "addStore": "Thêm cửa hàng",
    "helpInfo": "Trung tâm trợ giúp",
    "addSyncStore": "Thêm cửa hàng liên kết",
    "lastSyncTime": "Thời gian đồng bộ gần nhất",
    "alreadyStore": "Cửa hàng đã liên kết",
    "menuProductLocalUpdateAutoBind": "Sản phẩm chính / Cập nhật quy tắc tự động liên kết",
    "menuInboundManagement": "Quản lý Phiếu nhập kho",
    "menuOutboundManagement": "Quản lý Phiếu xuất kho",
    "menuLowStockReport": "Hàng bán chậm",
    "menuLogisticsCostStatistics": "Chi tiết phí Vận chuyển",
    "menuOutStockForecast": "Dự báo hết hàng",
    "menuInventoryStatistics": "Thống kê Tồn kho",
    "menuLogisticsStatistics": "Thống kê Vận chuyển",
    "menuCustomerAreaAnalysis": "Phân tích Khu vực Khách hàng",
    "menuCustomerAnalysis": "Phân tích Khách hàng",
    "menuCustomerStatistics": "Thống kê Khách hàng",
    "menuStoreSaleReport": "Cửa hàng bán hàng",
    "menuPlatformSaleReport": "Kênh bán hàng",
    "menuIncomeStatementReport": "Đối soát lợi nhuận",
    "menuCategorySaleReport": "Thuộc tính Sản phẩm",
    "menuProductSaleReport": "Thống kê Sản phẩm",
    "menuVariantsSalesStatistics": "Thuộc tính Sản phẩm",
    "menuOrderReport": "Thống kê Đơn hàng",
    "menuSetting": "Cài đặt",
    "ShippingOrderNew": "Giao hàng",
    "status_ready_to_ship": "Sẵn sàng giao",
    "printOrderPending": "Đơn hàng chờ in",
    "beCollectedTo": "Chờ lấy hàng",
    "orderAbnormal": "Đơn hàng Bất thường",
    "PendingOrder": "Đơn hàng Chờ xử lý",
    "pending": "Chờ xử lý",
    "operationRecord": "Lịch sử thao tác",
    "historyOperation": "Nhật ký Vận hành Kho giao hàng",
    "shipped": "Đã giao hàng",
    "stored": "Đã gác lại",
    "warehouseSettingShipping": "Kho giao hàng",
    "orderDetails": "Chi tiết đơn hàng",
    "manualOrder": "Đơn hàng thủ công",
    "menuOrder": "Đơn hàng",
    "menuIntegrations": "Liên kết Cửa hàng",
    "menuProductLocationManagement": "Quản lý Kệ hàng",
    "menuProductLocationManagementOld": "Quản lý Vị trí",
    "menuWarehouseStockDetails": "Chi tiết Tồn kho",
    "menuStockPushRules": "Quy tắc đẩy Tồn kho",
    "menuStoreShippingWarehouseSettings": "Cài đặt Kho giao hàng",
    "menuWarehouseManagement": "Quản lý Kho hàng",
    "menuPriceManagement": "Quản lý Giá bán",
    "menuProductLocalImportToCreate": "Sản phẩm chính / Tạo Sản phẩm Hàng loạt",
    "menuProductLocalImportToBind": "Sản phẩm chính / Liên kết Sản phẩm Hàng loạt",
    "menuProductLocalEditBundle": "Sản phẩm chính / Chỉnh sửa Combo Sản phẩm",
    "menuProductLocalEdit": "Sản phẩm chính / Chỉnh sửa Sản phẩm",
    "menuProductLocalAddBundle": "Sản phẩm chính / Thêm Combo Sản phẩm",
    "menuProductLocalEditCombined": "Sản phẩm chính / Chỉnh sửa Sản phẩm Kết hợp",
    "menuProductLocalAddCombined": "Sản phẩm chính / Thêm Sản phẩm Kết hợp",
    "menuProductLocalAdd": "Sản phẩm chính / Thêm Sản phẩm",
    "menuProductWooCommerce": "Sản phẩm / WooCommerce",
    "menuBindHistory": "Lịch sử liên kết",
    "menuProductLocal": "Sản phẩm chính",
    "menuProduct": "Sản phẩm",
    "menuPoints": "Tích điểm",
    "menuMemberShipPage": "Trang thành viên",
    "InventoryLog": "Lịch sử biến động tồn kho",
    "menuMemberList": "Danh sách thành viên",
    "noticeList": "Thông báo",
    "menuHomePage": "Tổng quan",
    "productmassEditMaster": "Sản phẩm chính/Chỉnh sửa hàng loạt",
    "productPublishToTheStoreMaster": "Sản phẩm chính/Đăng lên cửa hàng",
    "resultsPublish": "Kết quả đăng",
    "operationHistoryMass": "Lịch sử hoạt động hàng loạt",
    "menuCapital": "Tiền vốn",
    "menuProductTikTok": "Sản phẩm/ TikTok",
    "menuProductTikTokEdit": "Sản phẩm/ TikTok/ Chỉnh sửa sản phẩm",
    "menuProductTikTokAdd": "Sản phẩm/ TikTok/  Thêm sản phẩm",
    "menuProductZalora": "Sản phẩm/ Zalora",
    "menuProductShopee": "Sản phẩm/ Shopee",
    "ImportAdd": "Nhập để tạo hàng loạt",
    "menuProductBlibliEdit": "Sản phẩm/ Blibli/ Chỉnh sửa sản phẩm",
    "menuProductShopeeEdit": "Sản phẩm / Shopee / Chỉnh sửa sản phẩm",
    "menuProductBlibliAdd": "Sản phẩm / Blibli/  Thêm sản phẩm",
    "menuProductShopeeAdd": "Sản phẩm / Shopee /  Thêm sản phẩm",
    "publishToStoreTip": "Sản phẩm chính / Đăng lên cửa hàng",
    "changePassword": "Đổi mật khẩu",
    "passworderror": "Mật khẩu phải bao gồm ít nhất 8 chữ cái, chữ số và ký hiệu đặc biệt",
    "pwdErrorInfo": "Mật khẩu phải gồm 8 - 20 ký tự",
    "UpdateSuccess": "Cập nhật thành công",
    "storeDiagnose": "Chẩn đoán cửa hàng",
    "shopeeAutoBump": "Shopee Tự động đẩy SP",
    "menuscrapeList": "Bộ sưu tập",
    "chromeExtension": "Chrome Extension",
    "getFreeDataTool": "Công cụ dữ liệu miễn phí",
    "bigdata": "Chọn hàng",
    "free": "Miễn phí",
    "DataExtension": "Thu thập dữ liệu mở rộng",
    "confirm": "Xác nhận",
    "noIndonesianLineGroup": "Không có nhóm Line tiếng Indonesia",
    "noEnglishLineGroupTemporarily": "Không có nhóm Line tiếng Anh",
    "noThaiGroupsForWhatsapp": "Không có nhóm Whatsapp tiếng Thái",
    "noVietnameseLineGroup": "Không có nhóm Line tiếng Việt",
    "noVietnameseWhatsappGroups": "Không có nhóm Whatsapp tiếng Việt",
    "noChineseWhatsappGroupTemporarily": "Không có nhóm Whatsapp tiếng Trung",
    "noChineseLineGroupTemporarily": "Không có nhóm Line tiếng Trung",
    "storeLimitTip": "Gói bạn đăng ký đã hết hạn. Nếu bạn muốn sử dụng gói miễn phí, vui lòng chọn 2 cửa hàng để tiếp tục liên kết (gói miễn phí có thể liên kết tối đa 2 cửa hàng, còn lại sẽ không được liên kết).",
    "max2Store": "Gói miễn phí có thể liên kết tối đa 2 cửa hàng",
    "publishToTheStore": "Đăng lên cửa hàng",
    "createMasterProduct": "Tạo sản phẩm chính",
    "confirmedNewPassword": "Đã xác nhận mật khẩu mới",
    "newPassword": "mật khẩu mới",
    "oldPassword": "Mật khẩu cũ",
    "detail": "Hủy theo dõi",
    "editProduct": "Chỉnh sửa sản phẩm",
    "orderXxxFailedToPushWarehouse": "Đơn xxx đẩy sang kho thất bại, nguyên do: xxx",
    "orderXxxPushedToWarehouseSuccessfully": "Đơn xxx đẩy sang kho thành công",
    "shippingToWarehouseOperationSucceeded": "Nhiệm vụ đẩy đã thành công",
    "shippingToWarehouseOperationIsSuccessful": "Thao tác gửi hàng đến kho thành công. vui lòng kiên nhẫn đợi kho hàng xử lý kết quả",
    "currentlyTiktokOrdersAreNotSupported": "Tạm thời không hỗ trợ thao tác chuẩn bị hàng và in vận đơn cho đơn hàng Tiktok thông qua Open API",
    "shopeeOrdersWithTheSameLogistics": "Hỗ trợ đặt thời gian lấy hàng hàng loạt cho cùng một đơn vị vận chuyển đối với đơn hàng Shopee/Tiktok",
    "gineeSupportsSettingUpAutomaticShipping": "Ginee hỗ trợ thiết lập phương thức vận chuyển tự động cho cửa hàng Shopee/Bukalapak/Tokopedia/Akulaku/TikTok",
    "xxxStockDeductionProcessingEnableddisabled": "Đã bật / tắt xử lý tồn kho cửa hàng XXX thành công",
    "theEffectiveTimeOfXxx": "Thời gian hiệu lực của việc xử lý tồn kho tại cửa hàng xxx được thay đổi từ xxx thành xxx",
    "pleaseOpenAtLeastOneStore": "Vui lòng bật ít nhất một cửa hàng",
    "thereIsAnUnfinishedOrderExport": "Có nhiệm vụ xuất đơn hàng chưa hoàn thành, vui lòng vào lịch sử xuất kho để xem các ghi chép xuất hàng, sau đó thực hiện xuất lại sau khi hoàn thành nhiệm vụ",
    "theSystemServiceIsAbnormalPlease": "Hệ thống không ổn định, vui lòng làm mới trang và thử lại",
    "afterTurnOffTheOrderWill": "Sau khi tắt, đơn hàng sẽ được phân bổ đến kho dựa ưu tiên của quy tắc kho giao hàng",
    "pleaseConfirmWhetherWantToPush": "Vui lòng xác nhận muốn đẩy kho hàng bên thứ 3 cho việc vận chuyển?",
    "pleaseConfirmWhetherWantToRedistribute": "Vui lòng xác nhận muốn phân bổ hàng lại?",
    "outboundOrder": "Mã đơn xuất kho",
    "splitOrder": "Tách đơn",
    "abnormalDescription": "Mô tả bất thường",
    "problemOrder": "Đơn hàng có vấn đề",
    "redistribute": "Phân bổ lại hàng hóa",
    "deliveryWarehouse": "Kho giao hàng",
    "nonindonesiaOrdersDoNotSupportXxx": "Đơn hàng Philippines không hỗ trợ giao hàng kho xxx, vui lòng điều chỉnh kho giao hàng",
    "theOrderDoesNotSupportDelivery": "Đơn hàng không hỗ trợ giao hàng kho xxx, vui lòng điều chỉnh kho giao hàng",
    "theAvailableStockOfMskuXxx": "MSKU xxx tồn kho có sẵn không đủ, vui lòng bổ sung tồn kho và phân bổ lại hàng hóa",
    "mskuXxxIsNotAssociatedWith": "MSKU xxx không liên kết với kho xxx, vui lòng thêm quan hệ liên kết và phân phối lại hàng hóa",
    "channelSkuXxxIsNotBound": "SKU kênh xxx chưa liên kết ràng buộc MSKU, vui lòng liên kết và phân bổ lại hàng hóa",
    "channelSkuXxxDoesNotHave": "SKU kênh xxx chưa phân bổ vào kho, vui lòng thêm quy tắc phân kho và phân bổ lại hàng hóa",
    "failedCreateOutboundOrder": "Tạo đơn xuất kho thất bại",
    "warehouseAllocationError": "Lỗi phân bổ kho hàng",
    "mskuNotAssociatedWithWarehouse": "MSKU chưa liên kết kho hàng",
    "mskuNotMatched": "MSKU chưa trùng khớp",
    "unallocatedWarehouse": "Tồn kho chưa phân bổ",
    "pushTheThirdPartyWarehouse": "Đẩy kho bên thứ ba",
    "paidOrder": "Thanh toán",
    "waitingPaymentOrder": "Đơn hàng",
    "alreadyInbound": "Đã nhập kho",
    "pleaseConfirmWhetherItIsAutomatically": "Vui lòng xác nhận có tự động đẩy hàng vào kho không?",
    "inboundReturnProduct": "Đẩy hàng vào kho",
    "abnormalType": "Loại khác",
    "printTime": "Thời gian in",
    "warehouseoutboundOrder": "Kho hàng/Mã đơn xuất kho",
    "variantsku": "Quy tắc/ SKU",
    "afterOpeningTheOrderWillBe": "Sau khi bật, đơn hàng đáp ứng điều kiện giao hàng sẽ tự động đẩy & liên kết với kho bên thứ ba",
    "automaticallyPushToThirdpartyWarehouse": "Tự động đẩy kho bên thứ ba",
    "inventoryVerificationAfterItIsTurned": "Kiểm tra hàng tồn kho: Sau khi được bật, tồn kho có sẵn trong kho hàng sẽ được kiểm tra xem có đủ hay không, và ưu tiên kho có đủ hàng để xuất kho.",
    "availableStockDeliveryDisabledSuccessfully": "Tắt thành công có hàng mới giao hàng",
    "availableStockDeliveryActivatedSuccessfully": "Bật thành công có hàng mới giao hàng",
    "shippingWarehouseSettingsDisabledSuccessfully": "Tắt thành công thiết lập phân kho",
    "shippingWarehouseSettingsActivatedSuccessfully": "Bật thành công thiết lập phân kho",
    "orderstockDeductionProcessDisabledSuccessfully": "Đã tắt thành công Xử lý đơn hàng tồn kho",
    "theStockDeductionProcessingOfXxx": "Quá trình xử lý tồn kho của cửa hàng xxx được bật / tắt thành công và thời gian có hiệu lực được thay đổi từ xxx thành xxx",
    "orderstockDeductionProcessSuccessfullyActivated": "Đã bật thành công Xử lý đơn hàng tồn kho",
    "afterActivatedOrdersWillBeAssigned": "Sau khi mở, các đơn hàng sẽ được khớp với các kho hàng với các hàng hóa khác nhau và cùng một đơn hàng có thể được giao cho các kho khác nhau để gửi hàng, nếu không, đơn hàng sẽ được giao cho cùng một kho để tiến hành giao.",
    "shipmentWarehouseSettingsDeterminesWhetherWarehouse": "Cài đặt phân kho: xác định khớp kho hàng dựa trên đơn hàng hoặc sản phẩm",
    "effectiveTime": "Thời gian hiệu quả",
    "activateStatus": "Bật trạng thái",
    "activateRange": "Bật phạm vi",
    "onceTurnOffAllStoreOrders": "Sau khi tắt, tất cả đơn hàng của các cửa hàng sẽ không thể xử lý tồn kho",
    "afterActivatedTheOrderWillGenerate": "Sau khi nó được bật, đơn đặt hàng sẽ tạo ra một chứng từ phát hành để khấu trừ hàng tồn kho theo quy trình xử lý, nếu không, hàng tồn kho sẽ không được xử lý khi đơn đặt hàng được chuyển đi.",
    "orderstockDeductionProcess": "Xử lý Đơn hàng liên kết tồn kho",
    "orderCreatedSuccessfully": "Tạo đơn hàng thủ công thành công",
    "shipmentWarning": "Cảnh báo giao hàng",
    "withoutUnboxingVideosComplaintsOfLessdamage": "Các phàn nàn về sản phẩm bị thiếu/ thiệt hại mà không có quay video mở kiện hàng thì sẽ không được chấp thuận",
    "shippingRebate": "Giảm giá vận chuyển",
    "shippingFeePaidByBuyer": "Người mua trả phí vận chuyển",
    "operationIsTooFrequentPleaseExport": "Thao tác quá thường xuyên, vui lòng xuất lại sau 1 phút",
    "rdPartyLogisticsShippingFee": "Phí vận chuyển bên đơn vị vận chuyển thứ 3",
    "shippingSubtotal": "Tổng phụ phí vận chuyển",
    "importFailedInternalSystemErrorPlease": "Nhập thất bại: lỗi hệ thống nội bộ, vui lòng nhập lại",
    "emailFormatIsIncorrect": "Định dạng email chưa chính xác",
    "supportsNumberslettersMaxCharacters": "Hỗ trợ số. chữ cái và \"-\". Tối đa. 30 ký tự",
    "supportsNumberslettersspacesMaxCharacters": "Chỉ hỗ trợ nhập số thứ tự/ chữ cái/ khoảng cách/ -, không vượt quá 20 ký tự",
    "basedOnProductSeparateRowFor": "Xuất theo sản phẩm (Hiển thị riêng lẻ phân loại đơn hàng)",
    "basedOnOrderOrderWithMultiple": "Xuất theo đơn hàng (Hiển thị tổng hợp đơn hàng kết hợp)",
    "exportMethod": "Phương thức xuất kho",
    "pleaseConfirmWhetherTheOrderStatus": "Vui lòng xác nhận việc đặt trạng thái đơn hàng là đã hoàn trả?",
    "editOrder": "Chỉnh sửa đơn hàng",
    "insufficientStockOfXxxPleaseMake": "Tồn kho của xxx không đủ, vui lòng bổ sung hàng hóa trước khi giao hàng",
    "paymentProblem": "Lỗi thanh toán",
    "invalidOrder": "Đơn hàng không hợp lệ",
    "moveToReturned": "Chuyển vào mục Hoàn",
    "selectCustomer": "Chọn khách hàng",
    "CustomerMobile": "Số điện thoại",
    "fyiPleaseDeleteWhenUploading": "Vui lòng xóa khi tải lên (chỉ để tham khảo)",
    "pleaseDeleteThisTextBoxBefore": "Vui lòng xóa văn bản trước khi nhập",
    "fillInWithTwocharacterShortCode": "Tên quốc gia điền vào 2 ký tự viết tắt của quốc gia đó,  phương thức thanh toán điền vào phương thức thanh toán trả trước/khác. Số tiền thanh toán không được vượt quá tổng số tiền đơn hàng.",
    "multipleMskusunitPricesquantitiesInTheSame": "Thông tin MSKU / đơn giá / số lượng trong cùng một đơn hàng phải được nhập trên một dòng, đơn giá không được nhỏ hơn 0 và số lượng phải lớn hơn 0; khi nhập nhiều dòng, các trường còn lại của đơn hàng có thể để trống.",
    "orderNumberOnlySupportsLettersNumbers": "Số đơn hàng chỉ hỗ trợ chữ cái, số thứ tự và dấu - /",
    "fieldWithMarkAreRequired": "Trường thông tin * là bắt buộc điền",
    "pleaseEditInAccordanceWithThe": "Vui lòng chỉnh sửa theo thứ tự có sẵn trong mẫu",
    "importantNotice": "Quan trọng",
    "theNumberOfDataRecordsCannot": "Số lượng thông tin nhập không quá 5000 dòng",
    "pleaseImportWithDownloadedFormatTemplate": "Vui lòng nhập theo mẫu đã tải xuống, không thay đổi tiêu đề",
    "onlySupportFilesInXlsxFormat": "Chỉ hỗ trợ các tệp dưới dạng xlsx (thay đổi thủ công đuôi tệp sẽ không hợp lệ)",
    "downloadImportTemplate": "Tải xuống mẫu nhập",
    "importOrder": "Nhập",
    "uploadPaymentProof": "Tải lên chứng từ thanh toán",
    "paymentProof": "Chứng từ thanh toán",
    "receivingAccount": "Số tài khoản nhận tiền",
    "paymentAccount": "Số tài khoản thanh toán",
    "receiptNumber": "Mã biên nhận",
    "theTotalPaymentAmountCannotExceed": "Tổng Số tiền thanh toán không được phép vượt quá Tổng tiền đơn hàng",
    "addPaymentRecord": "Thêm thông tin thanh toán",
    "refer": "Tham khảo",
    "useCommaToSeparateMultipleItem": "Thêm dấu phẩy để phân cách",
    "saveContinueAdd": "Lưu và Tiếp tục thêm",
    "save": "Lưu",
    "paymentSerialNumber": "Số seri thanh toán",
    "paymentAmount": "Số tiền thanh toán",
    "productTotalAmount": "Tổng tiền hàng",
    "totalQuantity": "Tổng số lượng",
    "totalPrice": "Tổng tiền",
    "addProduct": "Thêm hàng",
    "recipientArea": "Địa chỉ người nhận",
    "remarks": "Chú thích",
    "paymentInfo": "Thông tin thanh toán",
    "amountInfo": "Thông tin giá tiền",
    "productInfo": "Thông tin mục",
    "receiptInfo": "Thông tin nhận hàng",
    "basicInfo": "Thông tin khác",
    "referenceOrder": "Đơn hàng tham khảo",
    "canBeEnteredQuicklyByImporting": "Nhập nhanh bằng thông tin có sẵn",
    "addOrder": "Thêm lệnh",
    "bindStoreNow": "Liên kết cửa hàng ngay",
    "bindTips": "Do bạn chưa tích hợp cửa hàng kênh nên danh sách đơn hàng hiện đang trống. Vui lòng liên kết cửa hàng trước, sau đó mới xem hoặc vận hành đơn hàng",
    "PlatformDiscount": "Sàn TMĐT khuyến mãi",
    "TotalDiscount": "Tổng giảm",
    "voidTipss": "Đơn hàng đã chuyển vào Tạm gác lại sẽ không còn hỗ trợ bất kỳ thao tác đơn hàng nào trong hệ thống",
    "voidTips": "Một khi đơn hàng chuyển đến Tạm gác lại, nó sẽ không còn tham gia vào việc khóa / khấu trừ hàng tồn kho nữa. Bạn phải khôi phục đơn hàng mới có thể được khóa / khấu trừ bình thường. Vui lòng thao tác thận trọng.",
    "pickingList": "Picking List",
    "printedBy": "Được in bởi",
    "supportTikiAndZaloraOrdersToComplete": "Hỗ trợ đơn hàng Tiki và Zalora hoàn tất nhận hàng hàng loạt, đơn hàng Blibli vui lòng thao tác trong Chi tiết đơn hàng",
    "onlyLazadaBlibiliAndZaloraSupportPackaging": "Chỉ hỗ trợ đơn hàng Lazada, Blibli và Zalora đóng gói, đã đóng gói thì không hỗ trợ đóng gói lần 2, nếu muốn chỉnh sửa số lượng sản phẩm của đơn hàng, vui lòng cập nhập trong Chi tiết đơn hàng",
    "confirmWhetherToSetTheOrderStatusAsShipped": "Vui lòng xác nhận có đặt trạng thái đơn hàng thành Đã giao hàng không",
    "markAsShipped": "Đánh dấu hóa đơn",
    "whetherToUseGineeTemplateToPrintInvoices": "Có sử dụng mẫu riêng của Ginee để in hóa đơn hay không(Đơn hàng Lazada PH chỉ hỗ trợ in hóa đơn theo mẫu của Ginee)",
    "buyerPayment": "Giá trị người mua thanh toán",
    "doAllProductInTheSameOrder": "Tất cả sản phẩm trong cùng một đơn hàng có sử dụng cùng một số hóa đơn hay không",
    "clickHereToSetting": "Nhấn cài đặt",
    "operationLog": "Lịch sử thao tác",
    "massSetPickupTime": "Đặt thời gian lấy hàng hàng loạt",
    "numberOfFails": "Số thất bại",
    "totalNumber": "Tổng số",
    "invoiceNumberSettingFailureDetails": "Chi tiết cài đặt mã hóa đơn thất bại",
    "invoiceNumberIsSetSuccessfully": "Cài đặt mã hóa đơn thành công",
    "clickHereToSetWhetherAll": "Nhấn vào đây để cài đặt tất cả các sản phẩm trong cùng một đơn hàng có sử dụng cùng một số hóa đơn hay không",
    "setInvoiceNumber": "Đặt số hóa đơn",
    "printShippingLabelAndPickingLists": "In vận đơn và phiếu lấy hàng",
    "pleaseConfirmWhetherTheReceiptIs": "Vui lòng xác nhận có đã hoàn tất nhận hàng chưa?",
    "onlyTikiOrdersCanBeReceived": "Chỉ hỗ trợ đơn hàng Tiki hoàn tất nhận hàng hàng loạt, đơn hàng Blibli vui lòng vào chi tiết đơn hàng để thao tác",
    "malaysiaTemplates": "Mẫu Malaysia",
    "generalTemplates": "Mẫu thông dụng",
    "unassignedWarehouse": "Tồn kho chưa phân bổ",
    "jikaPesananTelahDikaitkanDenganGudang": "Nếu đơn hàng đã liên kết kho bên thứ ba, vui lòng xác nhận kho đã hủy phiếu xuất kho thành công hay chưa để tránh sai sót tồn kho",
    "inOrderToEnsureTheNormal": "Để đảm bảo các chức năng của hệ thống sử dụng bình thường, chức năng tải đơn hàng sẽ không hoạt động trong thời gian khuyến mãi 11.11 ~ 11.12, cảm ơn quý khách hàng đã ủng hộ",
    "warehouse": "Kho",
    "unpairedMasterSKU": "Master SKU chưa ghép đôi",
    "postalCode": "Mã bưu điện",
    "detailsAddress": "Địa chỉ chi tiết",
    "street": "Đường",
    "districts": "Quận",
    "city": "Thành phố",
    "province": "Tỉnh",
    "senderPhone": "Số điện thoại nhà bán",
    "senderName": "Tên nhà bán",
    "dataLoadingPleaseWaitOrClick": "Đang tải dữ liệu xuống, vui lòng đợi hoặc nhấn nút bên dưới để bỏ qua",
    "activatedAllOrdersFromTheAuthorized": "Một khi bật thao tác giao hàng, tất cả đơn hàng của cửa hàng đã ủy quyền cần thực hiện \"Đóng gói đơn hàng\" trước khi tiến hành thao tác giao hàng",
    "restrictionShippingOfOrdersMustBe": "Giới hạn giao hàng: Bắt buộc ưu tiên thực hiện đóng gói đơn hàng trước khi giao hàng",
    "shippingDiscountSeller": "Chiết khấu Phí ship Nhà bán",
    "feeDiscountFromPlShipping": "Chiết khấu Phí ship bên thứ 3",
    "taxEscrow": "Thuế TMĐT xuyên biên giới",
    "transactionFeeBuyer": "Phí giao dịch Người mua",
    "transactionFeeSeller": "Phí Giao dịch Nhà bán",
    "SetPackStatusTitle": "Cài đặt Trạng thái Đóng gói",
    "SetPackStatusInMass": "Cài đặt Trạng thái Đóng gói Hàng loạt",
    "timeUpdate": "Thời gian cập nhật",
    "timeCreation": "Giờ tạo",
    "contentOperation": "Nội dung thao tác",
    "moduleOperation": "Mô-đun hoạt động",
    "recordOperation": "Lịch sử thao tác",
    "orderRestore": "Khôi phục đơn hàng",
    "inToAlreadyShippedMove1": "Chuyển vào Đã giao hàng",
    "warehouseThirdparty": "Kho hàng bên thứ 3",
    "warehouseOwn1": "Kho tự do",
    "inToAlreadyShippedMove": "Chuyển vào Đã giao hàng",
    "inToBeCollectedMove": "Chuyển vào chờ sưu tập",
    "numberEmptyAwb": "Thiếu mã vận đơn",
    "printedNot": "Chưa in",
    "youWantToOperateSeparatelyPlease": "Nếu bạn muốn thao tác riêng lẻ, vui lòng chuyển đến danh sách đơn hàng để hoàn tất thao tác.",
    "ordersWillBeDirectlyPackagedAnd": "Các đơn hàng Lazada/Blibli sẽ được trực tiếp đóng gói và gửi hàng",
    "processingTheTokopediabukalapakOrderWillBe": "Sau khi xử lý, đơn hàng Tokopedia/Bukalapak sẽ được chấp nhận và gửi hàng",
    "youSureToProcessTheSelected": "Bạn có chắc muốn xử lý X đơn hàng đã được chọn cùng lúc và chuyển chúng đến đơn hàng chờ in?",
    "failedCancel": "Hủy thất bại",
    "pushToThirdpartyWarehouseFailed": "Đẩy sang kho của bên thứ 3 thất bại",
    "toPendingMove": "Chuyển vào chờ xử lý",
    "asPriorityMark": "Đánh dấu thành ưu tiên xử lý",
    "orderOutOfStockAll": "Tất cả đơn hết hàng",
    "orderOutOfStockSelected": "Đơn hết hàng đã chọn",
    "stockCheck": "Kiểm tra tồn kho",
    "skuXxxxIsInsufficientInThe": "Master SKU: {x} trong kho {y} bị thiếu tồn kho, vui lòng bổ sung hàng trong Quản lý tồn kho rồi xử lý lại",
    "skuXxxxDoesNotExistIn": "Master SKU: XXXX không tồn tại trong kho XXXX. Vui lòng chọn lại kho hàng bằng cách thay thế Master SKU hoặc thêm Master SKU mới trong kho trước khi xử lý lại",
    "warehouseToShipmentPush": "Đẩy kho để gửi hàng",
    "orderProcessing": "Xử lý đơn hàng",
    "processingFailed": "Xử lý thất bại",
    "ofStockOut": "Hết hàng",
    "warehouseOwn": "Kho tự do",
    "failedOrdersView": "Kiểm tra nguyên nhân thất bại",
    "failed": "Thất bại",
    "success": "Thành công",
    "total": "Tổng",
    "descriptionXxOrdersHaveBeenSynchronized": "Mô tả tiến độ: đã đồng bộ XX đơn, còn XX đơn chờ đồng bộ",
    "pleaseWaitSyncing": "Đang đồng bộ, vui lòng đợi",
    "result": "Kết quả",
    "reasonFailed": "Nguyên nhân thất bại",
    "exceptionSynchronization": "Đồng bộ ngoại lệ",
    "otherLogisticsProviderNumberEmptyPlaceholder": "Chưa tạo mã vận chuyển, vui lòng đồng bộ sau",
    "theCheckIsRemovedTheIntegrated": "Sau khi bỏ chọn, tồn kho sản phẩm chính sẽ tăng lên",
    "selectedTheIntegratedMasterProductStock": "Sau khi chọn, tồn kho sản phẩm đã liên kết sẽ tăng lên",
    "ifNoRuleIsAddedAfter": "Mẹo: Nếu chưa thêm quy tắc, sau khi đơn hàng chuyển sang trạng thái bị hủy, tất cả tồn kho của sản phẩm chính đã được liên kết trong đơn hàng sẽ tự động được nhập kho lại",
    "forTheProductStockOfThe": "Chỉ dành cho tồn kho của sản phẩm chính phù hợp trong đơn hàng, nếu sản phẩm chính không được liên kết, sẽ không được xử lý",
    "theReasonForCancellationIs1": "Khi Lý do hủy là ##, cập nhật thành sản phẩm không nhập kho lại",
    "theReasonForCancellationIs": "Khi Lý do hủy là ##, cập nhật thành sản phẩm nhập kho lại",
    "operationContent": "Nội dung thực hiện",
    "delete": "Xóa",
    "updateTime": "Thời gian cập nhật",
    "createTime": "Giờ tạo",
    "cancelReason": "Lý do hủy",
    "operator": "Người thực hiện",
    "pleaseSelectStore": "Vui lòng chọn Cửa hàng",
    "pleasesSelectChannel": "Vui lòng chọn Kênh",
    "no": "Không",
    "yes": "Có",
    "updateWarehouseStock": "Sản phẩm nhập kho lại",
    "unknownReason": "Chưa rõ nguyên nhân",
    "buyerCanceled": "Người mua Hủy",
    "systemCancel": "Hệ thống Hủy",
    "cancelSetting": "Hủy cài đặt",
    "kodePos": "Mã bưu điện",
    "nameFirst": "Tên",
    "nameLast": "Họ",
    "staff": "Nhân viên",
    "secretary": "Thư ký",
    "receptionist": "Lễ tân",
    "security": "Bảo vệ",
    "driver": "Lái xe",
    "helperDomestic": "Phụ tá",
    "relationship": "Mối quan hệ",
    "mrmrsms": "Mr/Mrs/Ms",
    "spouse": "Vợ/chồng",
    "related": "Người thân",
    "lessThanCharactersRequired": "Bắt buộc, ít hơn 30 kí tự",
    "codeSettlement": "Quyết toán số",
    "statusReceiver": "Trạng thái gửi hàng",
    "nameReceiver": "Tên người nhận hàng",
    "productDateReceived": "Ngày gửi hàng",
    "pleaseCompleteTheFollowingInformationAnd": "Chú ý: Vui lòng hoàn tất thông tin như sau và cập nhật đơn hàng sang trạng thái Đã hoàn thành",
    "completedOrder": "Hoàn thành đơn hàng",
    "cancel": "Hủy",
    "noMoreThanCharactersRequired1": "Bắt buộc điền, không vượt quá 300 kí tự",
    "ofThePerformanceReasonsPart": "Nguyên nhân",
    "quantityFinal": "Số lượng cuối cùng",
    "ifTheCurrentOrderOnlySupports": "Chú ý: Nếu đơn hàng chỉ hỗ trợ một phần fulfillment, vui lòng cập nhật số lượng sản phẩm, nếu số lượng của tất cả sản phẩm trong đơn hàng hiện tại được cập nhật là 0, thì sẽ hủy đơn hàng.",
    "quantityChange": "Chỉnh sửa số lượng",
    "endTimeMustBeGreaterThan": "Thời gian kết thúc phải lớn hơn thời gian bắt đầu",
    "noMoreThanCharactersRequired": "Bắt buộc điền, không vượt quá 300 kí tự",
    "fieldsRequired": "Cột bắt buộc điền",
    "installationnote": "Ghi chú cài đặt",
    "installationmobile": "Số điện thoại Người cài đặt",
    "installationofficer": "Người cài đặt",
    "endDateInstallation": "Ngày kết thúc cài đặt",
    "startDateInstallation": "Ngày bắt đầu cài đặt",
    "endDateShipping": "Ngày kết thúc giao hàng",
    "startDateShipping": "Ngày bắt đầu giao hàng",
    "nameCourrier": "Tên công ty vận chuyển",
    "toPackSomeOrdersPleaseTry": "Một số đơn hàng đóng gói thất bại",
    "successfullyPackaged": "Đóng gói thành công",
    "pleaseWaitPacking": "Đang đóng gói",
    "quantity": "Số lượng",
    "stockAvailablelocked": "Tồn kho Có sẵn/Bị Khóa",
    "skuwarehouseMaster": "Master SKU/Kho hàng",
    "nameProduct": "Tên sản phẩm",
    "numberOrder": "Mã đơn hàng",
    "onlyLazadaAndBlibliOrdersSupport": "Chú ý: Chỉ có đơn hàng của Lazada và Blibli hỗ trợ đóng gói, đơn hàng đã đóng gói không hỗ trợ đóng gói lần 2, nếu chỉnh sửa số lượng của đơn hàng Blibli, vui lòng cập nhật trong chi tiết đơn hàng",
    "theProductsOfTheSameOrder": "Đóng gói những sản phẩm của cùng một đơn hàng trong Blibli (giới hạn cho Sản phẩm Thông dụngt/Hàng cồng kềnh)",
    "pack": "Đóng gói",
    "stockAvailable": "Tồn kho có sẵn",
    "serviceProviderFulfillment": "Nhà cung cấp dịch vụ Fulfillment",
    "skuMaster": "Master SKU",
    "nameWarehouse": "Tên kho hàng",
    "serviceProvider": "Nhà cung cấp dịch vụ",
    "theMasterSkuReplacing": "Thay đổi Master SKU",
    "withXxxxxxPaired": "Đã ghép đôi với XXXXXX",
    "reasonReturn": "Nguyên nhân hoàn",
    "itemsRestock": "Hàng tồn kho hoàn lại",
    "amountRefund": "Tổng số tiền hoàn lại",
    "shippingReturn": "Hoàn phí ship",
    "quantityReturn": "Số lượng hàng hoàn",
    "totalRefundable": "Tổng số tiền có thể hoàn",
    "sendNotificationsToCustomers": "Gửi tin nhắn cho khách hàng",
    "totalRefund": "Tổng số tiền hoàn",
    "productRefund": "Sản phẩm hoàn",
    "ifYouChooseToIncreaseStock": "Chú ý: Nếu chọn thêm hàng tồn kho, thì hàng tồn kho của sản phẩm có hàng hoàn sẽ tăng lên",
    "refund": "Hoàn tiền",
    "buyerInfoEdit": "Chỉnh sửa thông tin người mua",
    "location": "Vị trí",
    "other": "Khác",
    "declinedPayment": "Thanh toán bị từ chối",
    "orderFraudulent": "Lừa đảo",
    "unavailableItems": "Sản phẩm không có sẵn",
    "changedcanceledOrderCustomer": "Khách hàng thay đổi/hủy đơn hàng",
    "CreditCardTransactionFee": "Phí thẻ tín dụng",
    "moreThan5": "Trên 5 ngày",
    "fiveDays": "Trong 5 ngày",
    "threeDays": "Trong 3 ngày",
    "twoDays": "Trong 2 ngày",
    "oneDays": "Trong 1 ngày",
    "noNotes": "Không có ghi chú",
    "hasNotes": "Có ghi chú",
    "noMessage": "Không có tin nhắn",
    "hasMessage": "Có tin nhắn",
    "multipleProduct": "Nhiều sản phẩm",
    "oneProductWithMoreThanOneQty": "Nhiều sản phẩm cùng loại",
    "singleProduct": "Một sản phẩm",
    "jdTip": "Chú ý: Do ảnh hưởng của kênh API, JD.ID cửa hàng được liên kết từ 29/04/2021 có một phần không thể đồng bộ, nhóm JD.ID sẽ sửa chữa vấn đề này ngay, xin lỗi vì sự bất tiện.",
    "orderIdSearchTips": "Vui lòng dùng \",\" giữa các mã đơn hàng",
    "lastSync": "Thời gian đồng bộ",
    "sortBy": "Thứ tự",
    "asc3": "Tăng lên",
    "desc2": "Giảm xuống",
    "desc4": "Giảm xuống",
    "asc2": "Tăng lên",
    "desc": "Giảm xuống",
    "asc": "Tăng lên",
    "zToA": "(Z-A)",
    "aToZ": "(A-Z)",
    "payTime": "Thời gian thanh toán",
    "deliverDeadline": "Thời hạn gửi hàng",
    "productSku": "Sản phẩm SKU",
    "failnumber": "Số lượng thất bại",
    "sucessnumber": "Số lượng thành công",
    "totalnumber": "Tổng số lượng",
    "lazadatip": "Khi Lazada khuyến mãi (26/3 đến 31/3) việc đồng bộ đơn hàng có thể bị trễ, và trong khi Lazada API bị hạn chế, bạn không thể chỉnh sửa thông tin sản phẩm (bao gồmHàng tồn kho và Giá) hoặc đăng sản phẩm mới thông qua Ginee. Chúng tôi rất xin lỗi vì sự bất tiện này.",
    "keyword": "Từ khóa",
    "logisticsProviderNumberEmptyPlaceholder": "Shopee chưa tạo mã vận chuyển, vui lòng đồng bộ sau",
    "logisticsProviderNameEmptyPlaceholder": "Shopee chưa phân phối vận chuyển, vui lòng đồng bộ sau",
    "PrintPdfErrorMessage": "Tạo tệp thất bại, vui lòng thử lại sau",
    "orderDetailNoteLimit": "Không vượt quá 500 kí tự",
    "GoContinue": "Tiếp tục",
    "BrowserBlockedInfo": "Cửa sổ mới mở ra đã bị trình duyệt chặn lại, vui lòng nhấp vào \"Tiếp tục\" để mở cửa sổ của trang mới (bạn cũng có thể  tìm \"Xóa phương thức chặn của trình duyệt\" trong Trung tâm trợ giúp)",
    "Notification": "Hiển thị",
    "ShowLabelProductInfo": "(Bukalapak)Vận đơn hiển thị thông tin sản phẩm",
    "VoucherPlatform": "Mã khuyến mãi sàn",
    "PriceCreditCardServiceFee": "Phí thẻ tín dụng",
    "PriceSellerReturnRefundAmount": "Số tiền hoàn trả",
    "PriceCoinCashBack": "Xu nhà bán hoàn trả",
    "PriceCoin": "Coin",
    "AllStatus": "Tất cả trạng thái",
    "AllType": "Tất cả các loại",
    "theMarketplacePromotionPeriodPleaseUnderstand": "Trong thời gian quảng bá nền tảng có thể bị chậm trễ trong việc đồng bộ đơn hàng, xin lỗi vì sự bất tiện",
    "pendingTableTips": "Hiện chỉ hỗ trợ xử lý đơn hàng Shopee/Bukalapak/Tokopedia/Lazada/JD/Shopify",
    "Filter": "Lọc",
    "InvoiceSettings": "Cài đặt hóa đơn",
    "MultipleProduct": "Nhiều sản phẩm",
    "OneProductOneQty": "Nhiều sản phẩm cùng loại",
    "paidTime": "Thời gian thanh toán",
    "packageStatus": "Trạng thái gói",
    "shipBefore": "Hẹn giao hàng",
    "sellerNote": "Ghi chú của người bán",
    "buyerNote": "Ghi chú",
    "packageType": "Loại đóng gói",
    "SingleProduct": "Một sản phẩm",
    "orderProductType": "Loại sản phẩm",
    "ViewFailedList": "Xem đơn hàng thất bại",
    "AWBTrackingNumber2": "Mã vận đơn/Số hóa đơn",
    "PickUpTime": "Thời gian lấy hàng",
    "NoEmptyText": "Không có dữ liệu",
    "OrdersFailedTips": "Một phần đơn hàng gửi đi thất bại, vui lòng thử lại sau",
    "SuccessfullyShipped": "Gửi hàng thành công",
    "TotalOrdersShipped": "Tổng số đơn đặt hàng sẽ được vận chuyển lần này",
    "ShippingWait": "Đang vận chuyển",
    "action": "Thao tác",
    "SeeMoreRetract": "Thu gọn",
    "FailedReason": "Nguyên nhân thất bại",
    "RecipientMobile": "Số điện thoại người nhận",
    "SetNow": "Cài đặt ngay",
    "SkipNext": "Bỏ qua, tiếp",
    "shippingTips3": "Do hạn chế bởi giao diện JD, chỉ hỗ trợ thao tác giao hàng sau khi in Vận đơn, nếu chưa in thì sẽ không hỗ trợ giao hàng",
    "shippingTips2": "Nếu bạn chưa thiết lập, hệ thống sẽ tự động chỉ định phương thức vận chuyển là ưu tiên Lấy hàng tận nơi > Gửi hàng tại bưu cục > Vận chuyển thủ công.",
    "shippingTips1": "Một phần chuyển phát nhanh chưa được cài đặt phương thức vận chuyển, vui lòng hoàn thành cài đặt phương thức vận chuyển trước khi gửi hàng",
    "FilterOrder": "Lọc đơn hàng",
    "setAddress": "Chưa có địa chỉ, vui lòng đi đến Trung tâm người bán để cài đặt",
    "ArrangeShipment2": "Sắp xết gửi hàng",
    "MassUpload": "Tải lên hàng loạt",
    "ShippingCarrier": "Công ty vận chuyển",
    "OrderQuantity": "Số lượng đơn hàng",
    "DownloadTemplate": "Tải xuống mẫu",
    "FileSizeLimitTips": "Chỉ hỗ trợ tệp xlsx/xls nhỏ hơn 5M",
    "ImportFile": "Nhập tệp",
    "FileFormatInvalid": "Nhập sai định dạng tệp",
    "ProcessFailed": "Xử lý thất bại",
    "ToBeDelivered": "Chờ giao hàng",
    "ToBeAccepted": "Chờ xác nhận",
    "PickUpAddress": "Địa chỉ lấy hàng",
    "axiosTips": "Chú ý",
    "moreMaxLength": "Tối đa { maxLength } kí tự",
    "PleaseSelect": "Vui lòng chọn",
    "SyncError": "Động bộ thất bại",
    "syncSuccess": "Đồng bộ thành công",
    "Synchronizing": "Đang đồng bộ",
    "reset": "Đặt lại",
    "search": "Tìm kiếm",
    "status_failed": "Thất bại",
    "status_returned": "Hoàn",
    "status_cancelled": "Hủy",
    "status_delivered": "Đã nhận",
    "status_shipping": "Đang vận chuyển",
    "status_paid": "Đã thanh toán",
    "status_pending_payment": "Chờ thanh toán",
    "CancellingProcess": "Đang hủy",
    "Delete": "Xóa",
    "Unpicked": "Chờ lấy hàng",
    "Picked": "Đã lấy hàng",
    "ShippingMethodLink": "Cài đặt ngay",
    "ExportDisabled": "Quá hạn",
    "ExportFailed": "Tải xuống thất bại",
    "ExportFinished": "Hoạt động",
    "ExportINProcess": "Đang xử lý",
    "ExportStatus": "Trạng thái",
    "AddTemplate": "Thêm mẫu",
    "ExportFiledReason": "Nguyên nhân thất bại",
    "FileSaveHistoryDays": "Tệp sẽ được hiển thị trong danh sách lịch sử xuất, và sẽ chỉ lưu giữ trong 15 ngày, vui lòng nhấp vào để tải xuống ngay",
    "ExportFileFailed": "Tạo tệp thất bại, vui lòng thử lại sau",
    "ExportFileGenerated": "Tệp đã được tạo, vui lòng nhấp vào tải xuống",
    "ExportFileInProcess": "Đang tạo tệp, vui lòng đợi",
    "TokoOrderApiUnstable": "Do ảnh hưởng bởi bản cập nhật kênh Tokopedia, Ginee sẽ tạm dừng dịch vụ đồng bộ đơn hàng của kênh này trong 1 giờ kể từ 22:00 giờ Indonesia ngày 3 tháng 11. Chúng tôi rất xin lỗi vì sự bất tiện này.",
    "ExportResultTitle": "Kết quả",
    "OrderStatus": "Trạng thái đơn hàng",
    "ExportDownload": "Tải xuống",
    "ExportAllOrders": "Xuất tất cả",
    "ExportBySelected": "Xuất mục đã chọn",
    "Export": "Xuất",
    "OtherInformation": "Thông tin khác",
    "AmountInformation": "Thông tin số tiền",
    "LogisticsInformation": "Thông tin vận chuyển",
    "ProductInformation": "Thông tin sản phẩm",
    "BuyerInformation": "Thông tin khách hàng",
    "ExportSelectedDate": "Chọn thời gian",
    "ExportSelectedTemplate": "Chọn mẫu",
    "ExportOrder": "Xuất đơn hàng",
    "ExportCreator": "Người tạo",
    "ExportContent": "Nội dung",
    "ExportTime": "Thời gian xuất",
    "ExportHistory": "Lịch sử xuất",
    "TaxationFee": "Thuế",
    "CancelNoteText": "Nguyên nhân hủy",
    "FinalShippingFee": "Phí vận chuyển đã trả bởi hệ thống",
    "PickingListStatus": "Trạng thái phiếu lấy hàng",
    "PackingListStatus": "Trạng thái phiếu đóng gói",
    "InvoiceStatus": "Trạng thái hóa đơn",
    "LabelStatus": "Trạng thái thẻ",
    "AfterClickPrint": "Sau khi nhấp vào in, các đơn hàng trong danh sách dưới đây sẽ đánh dấu thành đã in",
    "SetToPrintedMass": "Cài đặt hàng loạt thành đã in",
    "SetPrintStatusTips": "Chú ý: Khi cài đặt thành Đã in, không còn được hỗ trợ để thay đổi trạng thái in nữa, hãy lưu ý.",
    "SetPrintStatus": "Cài đặt trạng thái in",
    "SetStatus": "Cài đặt trạng thái",
    "PickStatus": "Trạng thái lấy hàng",
    "PrintStatus": "Trạng thái in",
    "DiscountedTotalPrice": "Giảm giá",
    "InvoiceAlreadyPrinted": "Hóa đơn đã in",
    "InvoiceNotPrinted": "Hóa đơn chưa in",
    "PackingListAlreadyPrinted": "Phiếu đóng gói đã in",
    "PackingListNotPrinted": "Phiếu đóng gói chưa in",
    "PickingListAlreadyPrinted": "Phiếu lấy hàng đã in",
    "PickingListNotPrinted": "Phiếu lấy hàng chưa in",
    "LabelAlreadyPrinted": "Vận đơn đã in",
    "LabelNotPrinted": "Vận đơn chưa in",
    "NotPrinted": "Chưa in",
    "AlreadyPrinted": "Đã in",
    "Rebate": "Chiết khấu",
    "NewBuyerNote": "Ghi chú",
    "OrderTotal": "Tổng tiền đơn hàng",
    "BuyPhone": "Số điện thoại",
    "ProductInfo": "Thông tin mục",
    "SellerNote": "Ghi chú của người bán",
    "NoContentYet": "Hiện không có nội dung",
    "OnlyVisibleToPicking": "Chỉ có thể xem phiếu lấy hàng",
    "OnlyVisibleToSellers": "Chỉ người mua có thể xem",
    "CurrentlyNotSupportedShipping": "Hiện tại không hỗ trợ xem trạng thái vận chuyển",
    "AWB": "Mã vận đơn",
    "Voucher": "Voucher",
    "ServiceFee": "Phí dịch vụ",
    "CommissionFee": "Phí hoa hồng",
    "UnstableLazadaAPI": "Gần đây, do API của Lazada không ổn định, một số đơn hàng của cửa hàng không thể đồng bộ đầy đủ. Lazada sẽ sớm khắc phục. Nếu gặp bấn đề, vui lòng vào Trung tâm người bán để xử lý đơn hàng.",
    "HideProductInfo": "Ẩn thông tin sản phẩm",
    "ShippingWeight": "Trọng lượng",
    "ShippingSender": "Người gửi",
    "ShippingRecipient": "Người nhận",
    "BatchLabelInfo": "Sau khi nhấp vào in, những đơn hàng trong danh sách dưới sẽ được đánh dấu đã in",
    "PrintBatchLabel": "In nhiều vận đơn",
    "ViewAllProduct": "Xem tất cả { productCount } sản phẩm",
    "ShipBefore": "Hẹn ship",
    "PackFailedInfo": "Đóng gói thất bại, vui lòng làm mới hoặc đồng bộ trước khi thử lại",
    "PleaseSelectProduct": "Vui lòng chọn sản phẩm",
    "SkipPrint": "Bỏ qua & in",
    "SomeOrderInvoiceEmpty": "Một phần của số hóa đơn của hàng bị trống và không thể in được, vui lòng thêm phần đó trước",
    "SearchPlaceholder": "Vui lòng nhập nội dung",
    "Unpacked": "Chưa đóng gói",
    "PackingStatus": "Trạng thái đóng gói",
    "ReceiverName": "Họ tên người nhận",
    "BuyerEmail": "Email của khách hàng",
    "BuyerContact": "Số liên lạc của khách hàng",
    "BuyerName": "Tên khách hàng",
    "TrackIngNumber": "Mã vận đơn",
    "InvortySKU": "MSKU",
    "ParentSKU": "Master SKU",
    "ItemName": "Tên sản phẩm",
    "productName": "Tên sản phẩm",
    "OrdersID": "Mã đơn hàng",
    "Keywords": "Từ khóa",
    "OrderTypeNormal": "Bình thường",
    "OrderTypePreOrder": "Đơn hàng đặt trước",
    "AllOrderType": "Tất cả các loại đơn hàng",
    "PaymentPrePaid": "Thanh toán trước",
    "AllPayment": "Tất cả thanh toán",
    "LdProductStatusInfo": "Trạng thái sản phẩm trong đơn hàng là không tương ứng, vui lòng kiểm tra Chi tiết đơn hàng.",
    "PackingFailedInfo": "Đóng gói thất bại, vui lòng làm mới trang trước khi thử lại",
    "BillingAddress": "Địa chỉ thanh toán",
    "InvoiceNumber": "Số hóa đơn",
    "ProductsItemPack": "Tất cả sản phẩm đã đóng gói trong 1 đơn hàng không được đóng gói lại",
    "OnlyUnPackagedOrders": "Chỉ những đơn hàng chưa đóng gói",
    "AllProductsPacked": "Tất cả sản phẩm trong 1 đơn hàng có cùng mã hóa đơn",
    "PackMethod": "Cách đóng gói",
    "PackOrderTips": "Chú ý: Các đơn hàng của Lazada phải được đóng gói trước khi gửi đi, và Ginee chỉ hỗ trợ đóng gói các mặt hàng của cùng một đơn hàng.",
    "AlreadyPacked": "Đã đóng gói",
    "Pack": "Đóng gói",
    "PackOrder": "Đóng gói",
    "LWrongPricePricingError": "Giá / Tiền cọc không đúng",
    "LDuplicateOrder": "Đặt hàng trùng lặp",
    "LCustomerUnreachable": "Không thể liên lạc khách hàng",
    "order_98c90235ea6e3d054f70d2ffdfa9e703": "Hết hàng",
    "LOutOfDeliveryArea": "Vượt phạm vi giao hàng",
    "LIncorrectOr": "Địa chỉ giao hàng không chính xác hoặc không đầy đủ",
    "LSystemError": "Lỗi hệ thống",
    "LSourcingDelay": "Mua hàng chậm trễ",
    "NoName": "Không tên",
    "ProductSyncFailedAgain": "Đồng bộ sản phẩm thất bại, vui lòng thử lại",
    "ShopCancelOrderStockInfo": "Hết hàng - hàng tồn kho của sản phẩm đã chọn sẽ bằng 0 trong cửa hàng tương ứng",
    "TokoCancelOrderStockInfo": "Hết hàng - hàng tồn kho của sản phẩm đã chọn sẽ bằng 0 trong cửa hàng tương ứng",
    "BLCancelOrderStockInfo": "Khi hủy đơn hàng, hàng tồn kho của sản phẩm trong đơn hàng sẽ bằng 0 trong cửa hàng tương ứng",
    "AlreadyProcessed": "Đã xử lý",
    "Grab": "Grab [9:00-16:00]",
    "GOSENDInstant": "GO-SEND Instant [6:00-18:00]",
    "GOSENDSameDay": "GO-SEND Same Day [6:00-15:00]",
    "AvailableTimeToPickup": "Thời gian sẵn sàng nhận đơn",
    "ProductUnitPrice": "Đơn giá",
    "ShopCloseTime": "Thời gian cửa hàng đóng cửa",
    "ToCancellationInfo": "Tokopedia không hỗ trợ người bán từ chối yêu cầu hủy đơn hàng của người mua",
    "ToTrackingNoInfo": "8-17 kí tự",
    "trackingNoErrorMessage": "{min}-{max} kí tự",
    "BLTrackingNewNoInfo": "8-25 kí tự",
    "BLTrackingNoInfo": "Bắt buộc tạo thành từ 8-25 chữ cái và số",
    "TBuyerRequest": "Khách hàng yêu cầu",
    "TCourierProblem": "Nguyên nhân vận chuyển",
    "TOthers": "Nguyên nhân khác",
    "shopClosed": "Cửa hàng đóng cửa",
    "TWrongPriceOrWeight": "Giá hoặc trọng lượng không phù hợp",
    "TProductVariantUnavailable": "Thuộc tính hiện tại đã hết hàng",
    "TProductOutOfStock": "Sản phẩm đã hết hàng",
    "TRejectShipping": "Từ chối chuyển phát nhanh",
    "BLBusy": "Không thể xử lý đơn hàng",
    "BLOutdatedPrice": "Giá chưa cập nhật",
    "BLInappropriatePrice": "Giá không phù hợp",
    "BLVariantOutOfStock": "Sản phẩm hiện tại đã hết hàng",
    "BlSuppliersOutOfStock": "Nhà cung cấp hết hàng",
    "BLNoStock": "Sản phẩm đã bán hết",
    "ReceiveInfo": "Bạn có thể đồng thời nhận đơn hàng của Tokopedia, \nAkulaku, Bukalapak và Tiki, nhưng một khi đã nhận đơn hàng của Tokopedia và Tiki thì không thể hủy",
    "SelectStockEmpty": "Hàng tồn kho của sản phẩm đã chọn sẽ được làm trống",
    "DeleteKeepOneData": "Vui lòng giữ ít nhất 1 dữ liệu",
    "CourierTracking": "Mã vận đơn",
    "NoReturnOrder": "Không có yêu cầu hoàn nào cần xử lý",
    "InReturn": "Đang hoàn",
    "InCancel": "Đang hủy",
    "ReturnTips": "Chỉ hỗ trợ hủy một đơn hàng, khi chọn nhiều, mặc định xử lý đơn đầu tiên",
    "NoCancellationOrder": "Không có yêu cầu hủy nào cần xử lý",
    "PrintDate": "Ngày in",
    "BuyerNote": "Ghi chú của người bán",
    "message": "Tin nhắn",
    "seeMore": "Xem { more } sản phẩm",
    "HistoryOrderCompleted": "Đơn hàng đã hoàn thành",
    "HistoryOrderCancellation": "Từ chối hoàn thành công",
    "HistoryOrderReturned": "Đơn hàng đã hoàn",
    "HistoryOrderShipping": "Đơn hàng đã được gửi",
    "HistoryCancelOrder": "Đã hủy đơn hàng",
    "HistoryReject": "Người bán từ chối hủy đơn hàng",
    "HistoryCreateOrder": "Thêm đơn hàng",
    "Reject": "Từ chối",
    "Confirm": "Xác nhận",
    "AcceptInfo": "Nếu chấp nhận thì đơn hàng này sẽ bị hủy ngay lập tức, và bất kì khoản thanh toán nào được tạo bởi khách hàng sẽ bị hoàn lại. Vui lòng không tiếp tục gửi hàng. Nếu bạn đã gửi hàng, vui lòng từ chối yêu cầu hủy này.",
    "Images": "Hình ảnh",
    "AcceptReturn": "Chấp nhận hoàn",
    "Respond": "Phản hồi",
    "Reason": "Nguyên nhân",
    "RequestID": "Yêu cầu ID",
    "ReasonFromBuyer": "Nguyên nhân từ khách hàng",
    "qtyToShip": "Số lượng hàng gửi đi",
    "Qty": "Số lượng",
    "buyer": "Tên khách hàng",
    "orders": "Đơn hàng",
    "CODNotSupported": "Không hỗ trợ COD",
    "UndeliverableArea": "Khu vực không thể giao hàng",
    "CustomerRequest": "Yêu cầu của khách hàng",
    "OutOfStock": "Hết hàng",
    "History": "Lịch sử",
    "NoNext": "Không, tiếp tục",
    "YesNext": "Có, tiếp tục",
    "Print": "In",
    "Deleted": "Đã xóa",
    "Retry": "Thử lại",
    "AcceptRejectInfo": "Bạn có chấp nhận lý do hủy đơn hàng?",
    "receiveOrderTip": "Bạn có xác nhận xử lý đơn hàng đã chọn không?",
    "selectCancelTips": "Bạn có muốn hủy tất cả đơn hàng với cùng 1 lý do?",
    "AddPickingStatus": "Thêm trạng thái",
    "SetPickStatusConfirm": "Xác nhận",
    "PickApplyToAll": "Áp dụng cho tất cả",
    "SetPickStatusTitle": "Cài đặt trang thái lấy hàng",
    "SetPickStatusInMass": "Cài đặt Trạng thái lấy hàng hàng loạt",
    "PickingSetTip": "Chú ý: Một khi cài đặt thành Đã chọn sẽ không còn được hỗ trợ thay đổi trạng thái nữa, vui lòng lưu ý.",
    "PrintPicking": "In phiếu lấy hàng",
    "PrintPackingList": "In phiếu đóng gói",
    "PrintInvoice": "In hóa đơn",
    "PrintLabel": "In vận đơn",
    "ProcessingCancellation": "Quá trình hủy bỏ",
    "ProcessingReturns": "Xử lý hoàn",
    "ReceiveOrder": "Đơn hàng đã nhận",
    "orderSelected": "Đơn hàng đã chọn",
    "shippingfeetip": "Tổng phí ship của Bukalapak sẽ được tính sau khi giao hàng",
    "variationdeleted": "Đã xóa sản phẩm hiện tại",
    "Sync": "Đồng bộ",
    "tipsWait": "Tính năng \"Sắp xếp giao hàng\" sẽ sớm hoạt động. Vui lòng vào csàn TMĐT của cửa hàng này để xử lý giao hàng",
    "variation": "Thuộc tính",
    "MSKU": "MSKU",
    "SKU": "SKU",
    "Total": "Tổng",
    "SellerDiscountTotal": "Người bán giảm giá",
    "Insurance": "Bảo hiểm",
    "Tax": "Thuế",
    "Shipping": "Phí ship",
    "subtotal": "Tổng phụ",
    "readyToShip": "Sẵn sàng giao hàng",
    "timeSlots": "Khoảng thời gian",
    "selectAddress": "Chọn địa chỉ",
    "address": "Địa chỉ",
    "selectCourier": "Chọn công ty vận chuyển",
    "logistics": "Vận chuyển",
    "reasonForCancellation": "Nguyên nhân hủy",
    "qtyToCancel": "Số lượng hủy",
    "qtyOrdered": "Số lượng mua",
    "ArrangeShipment": "Chuẩn bị hàng",
    "updateOrder": "Cập nhật",
    "CancelOrder": "Hủy đơn hàng",
    "notes": "Ghi chú",
    "item": "Sản phẩm",
    "shippingAddress": "Địa chỉ giao hàng",
    "customerEmail": "Email",
    "contactNo": "Số điện thoại liên lạc",
    "promiseToShipBefore": "Hẹn trước .. giao đến",
    "AWBTrackingNumber": "AWB / Mã vận đơn",
    "shippingType": "Loại vận chuyển",
    "paymentType": "Phương thức thanh toán",
    "orderType": "Loại đơn hàng",
    "store": "Tên cửa hàng",
    "date": "Ngày",
    "customerDetails": "Chi tiết khách hàng",
    "orderInfo": "Thông tin đơn hàng",
    "processingFlow": "Quy trình xử lý",
    "orderDetailId": "Mã đơn hàng  { orderId }",
    "updateAt": "Thời gian cập nhật",
    "createAt": "Giờ tạo",
    "orderStatus": "Trạng thái",
    "customerName": "Tên người mua",
    "orderId": "Mã đơn hàng",
    "amountMax": "Số tiền tối đa",
    "amountMin": "Số tiền tối thiểu",
    "amount": "Số tiền",
    "createDate": "Giờ tạo",
    "shippingCarrier": "Nhà vận chuyển",
    "channelName": "Kênh",
    "keyWord": "Tìm kiếm mã đơn hàng, SKU hoặc Tên khách hàng",
    "refundableShippingFeeIsCalculatedIn": "Phí vận chuyển đã được bao gồm trong số tiền hoàn trả, vui lòng lọc để xem",
    "youDontHaveOperationAuthorityFeature": "Trong thời gian gói còn hiệu lực, lượng đơn hàng tích lũy được tạo ra đã đạt {x} đơn hàng. Sau khi vượt quá {y} đơn hàng, chức năng Đơn hàng sẽ không khả dụng. Vui lòng đăng ký gói hoặc liên hệ với bộ phận CSKH để nâng cấp gói.",
    "understood": "Đã hiểu",
    "disableDefaultRules": "Tắt quy tắc mặc định",
    "enableDefaultRules": "Bật quy tắc mặc định",
    "theDefaultRuleIsSuccessfullyDeactivated": "Tắt thành công Quy tắc mặc định",
    "theDefaultRuleIsSuccessfullyActivated": "Bật thành công Quy tắc mặc định",
    "ifTheOrderDoesNotMeet": "Nếu đơn hàng không đáp ứng bất kỳ quy tắc nào đã tạo, sẽ không thể tạo phiếu xuất kho để khấu trừ tồn kho",
    "afterDisableTheOrderWillOnly": "Sau khi đóng, đơn hàng sẽ chỉ phân bổ kho theo quy tắc phân kho đã tạo, lập chứng từ xuất kho để khấu trừ kho.",
    "inOrderToEnsureThatThe": "Để đảm bảo kho hàng chính có thể được khấu trừ bình thường, khi quy tắc kho phụ không được định cấu hình, quy tắc mặc định không hỗ trợ đóng",
    "defaultRuleWhenNoRuleIs": "Quy tắc mặc định: Khi không có quy tắc nào được tạo thủ công, kho sẽ được phân chia theo kho của phiếu nhập kho mới được tạo của MSKU.",
    "noticeWhenTheRuleNotManually": "Mẹo: Quy tắc mặc định không hỗ trợ đóng khi quy tắc được tạo thủ công trống; sau khi tạo quy tắc theo cách thủ công, đơn đặt hàng sẽ không được xử lý theo quy tắc mặc định; nếu có nhiều quy tắc phân bổ trong cùng một kho, chúng sẽ được phân bổ theo quy tắc ưu tiên",
    "country": "Quốc gia/Khu vực",
    "IntegrateNow": "Tích hợp ngay",
    "noconnected": "Bạn không có Cửa hàng nào được tích hợp, vui lòng tích hợp một cửa hàng",
    "enableTips": "Sau khi bật, quy tắc này sẽ ảnh hưởng đến phân bổ kho và đơn hàng, hãy thận trọng khi thao tác",
    "youSureToEnableAre": "Xác nhận bật?",
    "setAtLeastOneConditionPlease": "Vui lòng cài đặt ít nhất một điều kiện phân phối",
    "deletedSuccessfully": "Xóa thành công",
    "youSureToDeleteAre": "Bạn có chắc chắn xóa?",
    "mskuAdd": "Thêm MSKU",
    "logisticAdd": "Thêm vận chuyển",
    "storeAdd": "Thêm Cửa hàng",
    "disabledWarehouseWillNoLongerBe": "Sau khi vô hiệu hóa, các kho hàng sẽ không được phân bổ theo quy tắc này nữa",
    "youSureYouWantToDisable": "Xác nhận vô hiệu hóa Quy tắc này?",
    "deleteMskuRule": "Quy tắc XXX Xóa MSKU: #XXX#",
    "addMskuRule": "Quy tắc XXX Thêm MSKU: #XXX#",
    "orderAmountIsAdjustedFrom": "Quy tắc XXX Giá trị đơn hàng từ xx~xx điều chỉnh thành XX~XX",
    "deleteLogisticsRule": "Quy tắc XXX Xóa #XXX# công ty vận chuyển",
    "addLogisticRule": "Quy tắc XXX Thêm #XXX# công ty vận chuyển",
    "ruleNameOfRules": "Quy tắc XXX đổi tên thành #XXX#",
    "deleteStoreRule": "Quy tắc XXX Xóa #XXX# cửa hàng",
    "addStoreRule": "Quy tắc XXX Thêm #XXX# cửa hàng",
    "priorityOfRuleUpdated": "Mức ưu tiên của Quy tắc XXXX từ X chuyển sang X",
    "historyOperation1": "Lịch sử thao tác",
    "logisticSelectedX": "Đã chọn {x} Công ty vận chuyển",
    "storeSelectedX": "Đã chọn X cửa hàng",
    "warehouseAllocation": "Kho phân phối",
    "add": "Thêm",
    "amountOrder": "Giá trị đơn hàng",
    "nameLogistic": "Tên vận chuyển",
    "nameStore": "Tên cửa hàng",
    "conditionSelect": "Điều kiện đã chọn",
    "masterSkuDesignated": "Chỉ định Master SKU",
    "orderAmountRangeSpecify": "Chỉ định Phạm vi Giá trị đơn hàng",
    "logisticDesignated": "Chỉ định Vận chuyển",
    "storeDesignated": "Chỉ định Cửa hàng",
    "conditionOptional": "Điều kiện được chọn",
    "setAtLeastOneAllocationCondition": "Vui lòng cài đặt ít nhất một điều kiện phân phối để đảm bảo hoàn thành quy tắc cài đặt",
    "settingCondition": "Cài đặt điều kiện",
    "informationBasic": "Thông tin cơ bản",
    "ifTheOrderShippingWarehouseRule": "Mẹo: Nếu chưa thêm quy tắc giao hàng hoặc SKU chính không tồn tại trong kho đã đặt quy tắc, thì không thể tạo phiếu xuất kho để khấu trừ hàng tồn kho, vui lòng đặt quy tắc; nếu có nhiều quy tắc phân bổ trong cùng một kho, thì chỉ định dựa vào Mức độ ưu tiên của quy tắc",
    "ruleAdd": "Quy tắc Thêm vào",
    "disable": "Tắt",
    "enable": "Bật",
    "edit": "Chỉnh sửa",
    "operate": "Thao tác",
    "time": "Thời gian",
    "conditionAllocation": "Điều kiện phân phối",
    "nameRule": "Tên quy tắc",
    "priority": "Mức ưu tiên",
    "upTheSearch": "Tìm kiếm duy nhất{X}",
    "moreMax": "Tối đa { maxLength }",
    "separateMultipleInfoWithCommasOr": "Phân cách nhiều thông tin bằng dấu phẩy hoặc dấu cách",
    "addSearchType": "Thêm loại tìm kiếm",
    "advancedSearch": "Tìm kiếm nâng cao",
    "mskuIsNotEqualTo": "MSKU không bằng",
    "skuIsNotEqualTo": "SKU không bằng",
    "mskuIsEqualTo": "MSKU bằng",
    "skuIsEquals": "SKU bằng",
    "shopeebukalapakOrdersUseGineeTemplateFor": "Đơn hàng Shopee/ Bukalapal/ Tokopedia sử dụng mẫu của Ginee để in vận đơn",
    "selectPrinter": "Chọn máy in",
    "printCompleted1": "In hoàn tất",
    "printing2": "Đang in",
    "checkTheDetails": "Kiểm tra chi tiết",
    "action1": "Thao tác",
    "printQuantity": "Số lượng in",
    "printStatus": "Trạng thái in",
    "printingAdmin": "Người in",
    "highspeedBatchPrintingHistory": "Lịch sử in tốc độ cao hàng loạt",
    "highspeedPrintingLabelHistory": "Lịch sử nhãn in tốc độ cao",
    "printAgain": "In lại",
    "failureReason": "Nguyên nhân thất bại",
    "shipmentNumber": "Mã vận đơn",
    "order": "Đơn hàng",
    "printFailed": "In thất bại",
    "printSuccessfully": "In thành công",
    "printing1": "Đang in",
    "cautionToEnsureNormalPrintingOperation": "Cảnh báo: Để đảm bảo việc in đơn diễn ra bình thường, vui lòng không đóng/ làm mới trang hiện tại, nếu không nhiệm vụ in hiện tại sẽ bị dừng hoặc in lặp lại cũng một mặt!",
    "IfPrintingFailsPleaseMove": "3. Nếu in không thành công, vui lòng chuyển đến danh sách lỗi in để kiểm tra nguyên nhân lỗi",
    "DuringPrintingIfSomeOrders": "2. Trong quá trình in, một số đơn hàng in thất bại, điều này không ảnh hưởng đến tiến độ in chung",
    "AfterThePrintingIsCompleted": "1. Sau khi in xong, đơn hàng sẽ tự động được đánh dấu là đã in, quý khách có nhu cầu thay đổi vui lòng vào danh sách đặt hàng để chỉnh sửa thủ công",
    "printingInstructions": "Hướng dẫn in ấn",
    "totalXxxAndXxPrinted": "Tổng { total } , đã in { x }",
    "printCompleted": "In hoàn tất",
    "printing": "Đang in",
    "printingProgress": "Tiến trình in ấn:",
    "highSpeedPrintingTaskCenter": "Trung tâm trợ giúp in tốc độ cao",
    "recheck": "Kiểm tra lại",
    "ifYouConfirmThatThePlugin": "Nếu bạn xác nhận rằng trình cắm đã được cài đặt và kích hoạt, vui lòng nhấp vào nút phát hiện lại để in",
    "notInstalledClickHereToDownload": "Chưa được cài đặt, hãy nhấn vào đây để tải xuống plugin",
    "installed": "Đã cài đặt",
    "detectedWeFoundThatYouHave": "Sau khi kiểm tra, phát hiện ra rằng bạn chưa cài đặt hoặc kích hoạt trình cắm in tốc độ cao, vui lòng thử các phương pháp sau:",
    "tips": "Gợi ý",
    "inOrderToImproveThePrinting": "Để cải thiện tốc độ và trải nghiệm in, khuyến khích sử dụng in tốc độ cao,",
    "startPrinting": "Bắt đầu in",
    "printer": "Máy in",
    "noteToEnableHighspeedPrintingThe": "Lưu ý: Để kích hoạt in tốc độ cao, phần mềm in tốc độ cao plug-in phải được tải xuống và kích hoạt",
    "highspeedPrinting": "In tốc độ cao (in đơn lẻ không giới hạn, nhưng không hỗ trợ xem trước khi in)",
    "regularPrintingPrintUpTo": "In thường (in đến 100 đơn hàng trong một lần in, tạo nhãn PDF cho tất cả đơn hàng, tốn nhiều thời gian hơn)",
    "printingMethod": "Hình thức in",
    "haveBeenPrinted": "và { x }  trong số đó đã được in",
    "dataHaveBeenSelectedAnd": "Dữ liệu { x }  đã được chọn",
    "printData": "In dữ liệu",
    "customLabelOnlySupports": "Tùy chỉnh nhãn (Chỉ hỗ trợ đơn Shopee/Tokopedia/Bukalapak)",
    "channelLabelAllChannelOrdersSupport": "Nhãn kênh (Tất cả đơn hàng trên kênh đều hỗ trợ in trừ đơn của Shopify/Woocommerce)",
    "setUpPrintTemplates": "Thiết lập mẫu in",
    "printShippingLabelAll": "In vận đơn (tất cả)",
    "printShippingLabelSelected": "In vận đơn (đã chọn)",
    "selectAllOrders": "Chọn tất cả đơn hàng",
    "pleaseConfirmWhetherToTurnOff": "Vui lòng xác nhận có tắt xử lý tồn kho không?",
    "putOnHold": "Gác lại",
    "pickingNote": "Ghi chú lấy hàng",
    "dropshipper": "Dropshipper",
    "storeName": "Tên cửa hàng",
    "setReadyToShip": "Đặt sẵn sàng lên tàu",
    "becauseYouHaveNotIntegrateThe": "Do chưa ủy quyền cho cửa hàng kênh, bạn không thể sử dụng tính năng nhân bản, vui lòng hoàn thành ủy quyền trước",
    "integrateStore": "Cửa hàng ủy quyền",
    "integrationGuide": "Hướng dẫn ủy quyền",
    "MinutesToLearnAboutGinee": "1 phút tìm hiểu về Ginee",
    "shortcuts": "Phím tắt",
    "theStoreHasNotBeenAuthorised": "Cửa hàng chưa được ủy quyền, không thể quản lý sản phẩm và đơn hàng online",
    "inventoryManagement": "Quản lý hàng tồn kho",
    "productManagement": "Quản lý sản phẩm",
    "scrapeProduct": "Sản phẩm Sưu tập",
    "masterProductSales": "Thống kê doanh số sản phẩm chính",
    "try": "Thử ngay",
    "productActivitySetting": "Cài đặt hoạt động sản phẩm",
    "capitalOccupationRatio": "Tỷ lệ chiếm dụng vốn (%)",
    "stockTakesUpFunds": "Vốn tồn kho chiếm dụng",
    "numberOfMskus": "Số lượng MSKU",
    "exportMsku": "Xuất MSKU",
    "purchaseDuration": "Thời gian mua hàng",
    "doesItNeedToBeReplenished": "Có phải cần bổ sung?",
    "availableDaysDays": "Có sẵn trong ngày (ngày)",
    "averageDailySales": "Doanh thu trung bình hàng ngày",
    "lastMonth": "Doanh số 1 tháng",
    "lastTwoWeeks": "Doanh số bán hàng trong 14 ngày qua",
    "lastWeeks": "Doanh số bán hàng trong 7 ngày qua",
    "lastThreeDays": "Doanh số bán hàng trong 3 ngày qua",
    "numberOfRatings": "Số lượt đánh giá",
    "ratings": "Điểm",
    "likes": "Lượt thích",
    "pageViews": "Lượt xem",
    "productActivityHasBeenSetFor": "Hoạt động sản phẩm đã được cài đặt cho danh mục này, vui lòng cài lại",
    "sales": "Doanh số",
    "effectiveSales": "Bán hàng hiệu quả",
    "newItem": "Mới",
    "lowSellingItem": "Bán chậm",
    "normalItem": "Bình thường",
    "trendingItem": "Mạnh",
    "bestSellingItem": "Bùng nổ",
    "productActivity": "Hoạt động sản phẩm",
    "accounting": "Quản lý kế toán",
    "categoryRange": "Phạm vi danh mục",
    "timeRange": "Khung thời gian",
    "productDimension": "Cấp sản phẩm",
    "salesType": "Loại bán hàng",
    "salesRange": "Phạm vi bán hàng",
    "allCategory": "Tất cả danh mục",
    "recent": "Gần đây",
    "oneDay": "1 ngày",
    "threeDay": "3 ngày",
    "aWeek": "1 tuần",
    "twoWeeks": "2 tuần",
    "aMonth": "1 tháng",
    "threeMonths": "3 tháng",
    "halfYear": "Nửa năm",
    "onlySupportForIndonesia": "Chỉ hỗ trợ Indonesia",
    "addProductActivity": "Thêm hoạt động sản phẩm",
    "cSupportToManuallyPullAccurate": "c. Hỗ trợ bật đồng bộ các thay đổi tồn kho của Accurate đến Ginee, và cập nhật tồn kho đã liên kết của Ginee (tắt theo mặc định, vui lòng bật một cách thận trọng)",
    "bAfterTheOrderIsPlaced": "b. Sản phẩm Chính của Ginee được xuất từ kho hàng sẽ làm giảm đồng bộ hóa tồn kho của Accurate",
    "aInboundStockForMasterProduct": "a. Khi nhập kho sản phẩm Chính tại Ginee sẽ đồng bộ nhập tồn kho tại Accurate",
    "StockSync": "Đồng bộ Tồn kho",
    "noteOrderInformationSynchronizationIsA": "Lưu ý: Đồng bộ hóa đơn bán hàng là đồng bộ một chiều được tự động đẩy từ Ginee sang Accurate mỗi ngày",
    "createdTheWarehousePairingRelationship": "Sau khi ánh xạ cửa hàng thành công với Chính xác, hóa đơn bán hàng sẽ được tạo tự động khi đơn hàng Ginee được vận chuyển ra khỏi kho và được chuyển sang Chính xác.",
    "OrderDistribution": "4. Phân bổ đơn hàng",
    "noteTheSynchronizationOfMasterProduct": "Lưu ý: Đồng bộ thông tin sản phẩm Chính là đồng bộ một chiều từ Ginee sang Accurate. Sau khi tạo sản phẩm Chính mới, nó sẽ được đồng bộ ngay",
    "afterCreatedTheWarehousePairingRelationship": "Sau khi mối liên kết kho được tạo, nó hỗ trợ đồng bộ sản phẩm Chính của kho Ginee với kho Accurate",
    "ProductPlacement": "3. Phân bổ sản phẩm",
    "psItIsNotRecommendedTo": "PS: Khuyến khích không nên liên kết kho hàng bên thứ ba với kho Accurate, sẽ có nguy cơ rối loạn tồn kho",
    "afterTheAuthorizationIsCompletedYou": "Sau khi ủy quyền hoàn tất, bạn có thể quản lý việc khớp kho và cửa hàng trong [Cài đặt - Cài đặt tài chính] và đặt quy tắc đồng bộ. Đồng bộ sản phẩm/tồn kho/hóa đơn bán hàng chỉ có thể thực hiện bình thường sau khi khớp",
    "BasicConfiguration": "2. Cấu hình cơ bản",
    "pleaseMakeSureToFillIn": "Vui lòng đảm bảo điền tài khoản/ mật khẩu Accurate chính xác và chọn Cơ sở dữ liệu để hoàn tất ủy quyền",
    "Authorization": "1. Ủy quyền",
    "accurateOperationGuide": "Hướng dẫn vận hành Accurate",
    "averageDailySalesSettings": "Cài đặt doanh số trung bình hàng ngày",
    "averageSalesForTheDayAs": "Doanh số trung bình trong ngày, còn gọi là doanh số hàng ngày",
    "clickAuthorizeYouWillBeRedirected": "Nhấn [Ủy quyền], bạn sẽ được dẫn đến trang Accurate, vui lòng đảm bảo điền tài khoản/ mật khẩu Accurate chính xác và hoàn tất ủy quyền sau khi đăng nhập",
    "onSaleDaysDays": "Số ngày có sẵn (ngày)",
    "purchaseTimedays": "Thời gian mua (ngày)",
    "AfterTheAuthorizationIsCompleted": "2. Sau khi hoàn tất ủy quyền, vui lòng hoàn thành kết nối kho hàng trong quản lý cấu hình Accurate",
    "PleaseSelectAtLeast": "1. Vui lòng chọn ít nhất 1 Cơ sở dữ liệu để hoàn tất ủy quyền",
    "remainder": "Nhắc nhở",
    "authorizationSucceeded": "Ủy quyền thành công",
    "theAccurateWarehouseInformationIsBeing": "Kho và kế toán của Accurate đang được đồng bộ, vui lòng tiến hành cài đặt khớp lệnh khởi tạo tài chính!",
    "continueAuthorization": "Tiếp tục ủy quyền",
    "reauthorize": "Ủy quyền lại",
    "channelFailureReason": "Nguyên nhân thất bại từ kênh:",
    "authorizationFailed": "Ủy quyền thất bại",
    "deleteAuthorization": "Xóa ủy quyền",
    "authorizer": "Người ủy quyền",
    "authorizationTime": "Thời gian ủy quyền",
    "connected": "Đã liên kết",
    "authorizationStatus": "Trạng thái đồng bộ",
    "ServiceProvider": "Nhà cung cấp dịch vụ",
    "accountName": "Tên tài khoản",
    "deleteFailed": "Xóa thất bại",
    "theBindingRelationshipBetweenTheAccurate": "Mối liên kết giữa kho Accurate và kho Ginee đã bị hủy, việc đồng bộ thông tin sản phẩm/ đơn hàng/ tồn kho với ACCURATE sẽ không còn được thực hiện",
    "onceDeletedTheBindingRelationshipBetween": "Một khi xóa, mối liên kết giữa kho Ginee và kho Accurate sẽ hủy, và các thông tin sản phẩm/ đơn hàng/ tồn kho sẽ không còn đồng bộ với Accurate",
    "areYouSureWantToDelete": "Xác nhận xóa chứ?",
    "productSuccessfullySynchronized": "Đồng bộ sản phẩm thành công",
    "whenDistributeGineeWarehouseProductGinees": "Khi xuất kho sản phẩm từ kho Ginee, hàng hóa MSKU trong kho Ginee sẽ được sử dụng trực tiếp bao gồm tồn kho của kho Accurate, vui lòng vận hành cẩn thận",
    "areYouSureToSyncGinee": "Xác nhận đồng bộ sản phẩm trong kho Ginee đến kho Accurate?",
    "channelReturnFailureReason": "Nguyên nhân thất bại trả từ kênh:",
    "pullFailed": "Kéo thất bại",
    "pullSuccessfully": "Kéo thành công",
    "pull": "Kéo",
    "afterManuallyPullingTheWarehouseInformation": "Sau khi kéo thủ công thông tin kho hàng, vui lòng đảm bảo hãy liên kết kho hàng mới thêm trước và biết rằng kho hàng vô hiệu sẽ bị xóa trực tiếp, đồng thời mối liên kết sẽ bị xóa đồng bộ, việc đồng bộ dữ liệu cũng sẽ không còn được thực hiện.",
    "pullWarehouse": "Kéo kho hàng",
    "warehouseStockSuccessfullyUpdated": "Tồn kho trong kho hàng cập nhật thành công",
    "onceTheStockOfTheThirdparty": "Khi tồn kho của kho bên thứ ba không khớp với hệ thống, thao tác này sẽ cập nhật tồn kho trong kho trực tiếp của sản phẩm hiện có đến giá trị tồn kho của kho bên thứ ba, và tạo ra một nhật ký điều chỉnh. Thông tin cụ thể có thể được xem trong nhật ký kéo tồn.",
    "areYouSureToPullStock": "Xác nhận kéo tồn kho từ kho Accurate?",
    "theWarehouseBindingRelationshipHasBeen": "Mối liên kết kho hàng đã được hủy thành công",
    "afterTheUnbindingIsSuccessfulThe": "Sau khi hủy liên kết thành công, thông tin sản phẩm / đơn hàng đã nhận trong kho Accurate sẽ không bị ảnh hưởng và dữ liệu được tạo trong kho Ginee liên kết ban đầu sẽ không được đồng bộ đến Accurate",
    "areYouSureToUnbindThe": "Xác nhận hủy liên kết kho hàng hiện tại?",
    "reasonTheCurrentWarehouseHasBeen": "Nguyên nhân: Kho lưu trữ hiện tại đã liên kết với kho Accurate khác, vui lòng hủy liên kết và thử lại",
    "bindingFailed": "Liên kết thất bại",
    "bindingIsSuccessfulPleaseDoNot": "Liên kết thành công, vui lòng không lặp lại thao tác",
    "bind": "Liên kết",
    "tipOnlySupportsSelectingWarehouseThat": "Mẹo: Chỉ hỗ trợ chọn kho lưu trữ được bật trong danh sách kho Ginee",
    "syncWarehouse": "Đồng bộ kho hàng",
    "unbindWarehouse": "Hủy liên kết kho",
    "bindTime": "Thời gian liên kết",
    "accurateWarehouse": "Kho hàng Accurate",
    "accurateWarehouseInformationWillNotBe": "Thông tin kho Accurate sẽ không được đồng bộ với danh sách kho Ginee, và thông tin sản phẩm Chính/ đơn hàng/ tồn kho sẽ được đồng bộ theo quan hệ liên kết với kho hiện có của Ginee",
    "accurateSettingManagement": "Quản lý cấu hình Accurate",
    "synchronizeWarehouseInformationUnderAccurateDatebase": "Đồng bộ thông tin kho trong Cơ sở dữ liệu Accurate {x}, thêm {x} kho, xóa {x} kho",
    "syncGineeWarehouseProductTo": "Đồng bộ sản phẩm trong kho Ginee {x} đến kho Accurate",
    "syncStockFromAccurateWarehouse": "Đồng bộ tồn kho từ kho hàng Accurate *đến kho Ginee*",
    "accurateWarehouseUnbindFrom": "Kho hàng Accurate \"hủy liên kết\" với kho",
    "accurateWarehouseBindTo": "Kho hàng Accurate \"liên kết* kho",
    "operationLogIsOnlyRetainedFor": "Nhật ký hoạt động chỉ được giữ lại trong 15 ngày qua và không thể xem thông tin hoạt động sau hơn 15 ngày",
    "send": "Gửi",
    "translate": "dịch",
    "failedToSendMessage": "Gửi tin nhắn thất bại",
    "resend": "Gửi lại",
    "sendToBuyer": "Gửi cho người mua",
    "translationFailed": "Dịch thất bại",
    "translatedx": "Dịch thành công: {x}",
    "translationInProgress": "Đang dịch",
    "automaticTranslationHasBeenTurnedOn": "Dịch tự động đã được bật cho bạn",
    "autoTranslate": "Bản dịch tự động",
    "buyersLanguage": "Ngôn ngữ người mua",
    "afterCloseItThereWill": "Sau khi tắt, khi người mua có tin nhắn mới cần gửi, sẽ không còn âm báo tin nhắn nữa",
    "notificationSound": "Âm báo tin nhắn",
    "uploadedNowlengthSheets": "Đã tải lên {nowLength}",
    "isAUniqueIdentifierForThe": "Mã nhận dạng duy nhất của sản phẩm chính, không thể sửa đổi sau khi xác nhận",
    "pleaseConfirmWhetherTheStoresChannel": "Vui lòng xác nhận xem SKU kênh của cửa hàng có giá trị hay không, nếu không, chúng tôi sẽ lấy tiêu đề kênh sản phẩm làm MSKU của sản phẩm chính",
    "errnameToko": "Tên Sản phẩm phải giữa 1 - 70 kí tự",
    "sizeSettingTemplate": "Cài đặt Mẫu Kích thước",
    "mappingCategory": "Danh mục tương ứng",
    "templateSettingLogistic": "Cài đặt Mẫu Vận chuyển",
    "rangeEnable": "Cài đặt sản phẩm",
    "globalsMobileErrorInfoLength": "Số điện thoại phải từ giữa {min}-{max} chữ số",
    "globalsMobileErrorInfo": "Vui lòng nhập số điện thoại hợp lệ",
    "masterSku": "Master SKU",
    "outboundPurchasePrice": "Đơn giá mua hàng",
    "hasbinded": "Thuộc tính này đã được liên kết với MSKU: {sku} Vui lòng cập nhật hàng tồn kho trong \"Quản lý tồn kho\"",
    "deletecustomersuccess": "Đã xóa thành công!",
    "reasonforpushedfailed": "Lý do thất bại: Sản phẩm đã bị xóa hoặc bị cấm hoặc đang trong chiến dịch khuyến mãi (Có thể cập nhật hàng tồn kho sau khi chương trình khuyến mãi kết thúc). Vui lòng kiểm tra sản phẩm",
    "purchasePricerequired": "Phải từ 0 đến 1000.000.000!",
    "createsuccess": "Tạo thành công",
    "atmost20tags": "Tối đa 20 Tag",
    "AddTag": "Thêm tag",
    "tagexist": "Tag đã tồn tại",
    "fenotempty": "Không thể dùng Dấu cách ở đầu và cuối Tag",
    "taginputlimit": "Tag phải từ giữa 3-40 kí tự",
    "selecttag": "Chọn tag",
    "withordernostock": "Có Đơn nhưng hết hàng",
    "withisku": "Có MSKU",
    "noisku": "Không có MSKU",
    "withskuwithoutisku": "Có SKU và không có MSKU",
    "nosku": "Không có SKU",
    "selectskusit": "Chọn Điều kiện SKU",
    "Pleaseenter": "Vui lòng nhập",
    "selectsku": "Chọn MSKU",
    "emailreminder": "Email Nhắc nhở",
    "safetystockarea": "Khi Tồn kho có sẵn <=",
    "bindedisku": "MSKU đã đính kèm",
    "most20bundles": "Tối đa 20 sản phẩm",
    "NAME": "Tên",
    "PleaseEnter": "Vui lòng nhập",
    "disablebundletip": "Để đảm bảo tính chính xác của việc khấu trừ hàng tồn kho, không thể sửa đổi SKU và Số lượng hàng tồn kho được liên kết khi chỉnh sửa MSKU đính kèm",
    "updatesuccess": "Cập nhật thành công!",
    "ISKUtype": "Loại MSKU",
    "bundleisku": "MSKU Đính kèm",
    "isku": "MSKU",
    "ProductNameISKU": "Tên sản phẩm & ISKU",
    "Image": "Hình ảnh",
    "bundledproduct": "Sản phẩm Đính kèm",
    "selectbundle": "Chọn MSKU",
    "AddBundleISKU": "Thêm SKU đính kèm",
    "bukadisabled": "Cài đặt thời gian xử lý đơn hàng theo cửa hàng",
    "pleasecheckpreorderpart": "Vui lòng kiểm tra thông tin đặt trước",
    "shopeedisabled": "Tôi sẽ gửi trong vòng 2 ngày làm việc. (không bao gồm các ngày nghỉ lễ và ngày nghỉ dịch vụ chuyển phát nhanh)",
    "tokodisabled": "Kích hoạt Đặt hàng trước cho sản phẩm này nếu quá trình xử lý mất nhiều thời gian.",
    "UNKNOWNunittip": "Đơn vị thời gian không tồn tại, Vui lòng liên hệ với Ginee để giải quyết",
    "day": "ngày",
    "week": "tuần",
    "bukreorderdayworkday": "ngày làm việc để gửi đi",
    "bukreorderday": "ngày để gửi đi",
    "bukainstanhour": "giờ làm việc",
    "bukaregular": "Tôi sẽ gửi đi trong vòng 3 ngày làm việc. (không bao gồm các ngày nghỉ lễ và ngày nghỉ của dịch vụ chuyển phát nhanh)",
    "bukreorder": "Tôi cần",
    "bukainstan": "Tôi sẽ gửi đi trong",
    "tokopreorderday": "Ngày để gửi hàng",
    "tokopreorderweek": "Tuần để gửi hàng",
    "tokopreorder": "Tôi cần",
    "shopeepreorderday": "ngày làm việc để gửi hàng",
    "shopeepreorder": "Tôi cần",
    "max8pictures": "Tải lên tối đa 8 hình ảnh",
    "max5pictures": "Tải lên tối đa 5 hình ảnh",
    "uploadtip": "Tải hình ảnh lên theo quy tắc của Kênh sẽ là: Đối với Tokopedia, Bukalapak, Shopify, TikTok Shop, Blibli sẽ lấy 5 ảnh đầu tiên, Lazada 8 ảnh đầu tiên và Shopee 9 ảnh sản phẩm đầu tiên",
    "regular": "Định kỳ",
    "instan": "Ngay lập tức",
    "between18hours": "Trong 1-8 tiếng",
    "between330days": "Trong vòng 3-30",
    "max13weeks": "Trong vòng 1-13 tuần",
    "shopeepreorderdayAdd": "Star, Star +, Mall hỗ trợ lên đến 90 ngày làm việc",
    "between790": "Trong vòng 7-90 ngày làm việc",
    "max90days": "Trong 1-90 ngày",
    "preorderdeadline": "Thời hạn xử lý đơn hàng",
    "preorder": "Đặt trước",
    "novariationforlazd": "Không thể thêm hình ảnh vì sản phẩm này không có thuộc tính",
    "SafetystockHead": "Đặt giới hạn hàng tồn kho và nhận thông báo qua email để bổ sung khi Tồn kho có sẵn đạt đến số lượng thiết lập",
    "SpareStockkHead": "Tổng lượng Hàng tồn kho trong kho thực tế, sẽ không được bán trong kênh.",
    "WarehouseStockHead": "Tổng tồn kho thực tế, Tổng tồn kho = Kho dự phòng + Kho bị khóa + Kho có sẵn + Kho khuyến mãi",
    "inventorySkuHead": "50 ký tự đầu tiên của SKU cửa hàng trùng khớp theo mặc định",
    "NameHead": "Tên của MSKU được lấy tự động từ 160 ký tự đầu tiên của [tên sản phẩm-tên thuộc tính]",
    "BrandLogoTips": "Đề xuất tải lên: Hình ảnh 360 * 140, jpg / png / jpeg nhỏ hơn 2M",
    "MobileMustErrorInfo": "Phải được bắt đầu bằng 8, chứa 9-12 số",
    "bindnow": "Liên kết ngay",
    "Nospacesfront": "Phần đầu không được có dấu cách, vui lòng kiểm tra",
    "spaceforbidden": "Không được để trống, vui lòng kiểm tra",
    "range": "Trạng thái : Hoạt động, Đã hủy bỏ, Đã bán hết, Đã cấm bán",
    "inventorySkunotempty": "ISKU không được để trống!",
    "limitsafety": "Tồn kho an toàn phải từ giữa 0 ~ 999,999!",
    "mustlessthanwarehouse": "Tồn kho dự phòng không được nhiều hơn Tổng tồn kho",
    "sparelimit": "Tồn kho dự phòng phải từ giữa 0 và 999.999!",
    "mustbiggerthanspare": "Tồn kho dự phòng không thể lớn hơn Tổng tồn kho",
    "warehouselimit": "Tổng tồn kho phải dao động từ 0 đến 999.999!",
    "masterSkuMost200": "MSKU phải ít hơn 200 kí tự",
    "masterSkuMost50": "MSKU phải ít hơn 50 kí tự",
    "most50": "MSKU phải ít hơn 50 kí tự",
    "most160": "Tên phải ít hơn 300 kí tự",
    "clicktoedit": "Nhấp vào để chỉnh sửa",
    "alreadyexist": "MSKU đã tồn tại, SKU thuộc tính sẽ ràng buộc với MSKU này",
    "generaterules": "Quy tắc tạo MSKU theo mặc định: 1. MSKU sẽ lấy 50 ký tự đầu tiên của SKU theo mặc định. 2. Tổng tồn kho sẽ bằng với Tồn kho của thuộc tính, Tồn kho an toàn sẽ trống. 3. Khi MSKU được tạo, SKU của thuộc tính sẽ được liên kết.",
    "channel": "Kênh bán",
    "Operationguide": "Hướng dẫn Thao tác",
    "storeexplain": "Tạo MSKU cho một sản phẩm để quản lý tồn kho ở nhiều cửa hàng",
    "bystoremodal": "Tạo tồn kho SKU theo cửa hàng",
    "ImportByStore": "Tải lên theo cửa hàng",
    "Gotit": "Đã hiểu!",
    "Safetystock": "Tồn kho an toàn",
    "SpareStock": "Tồn kho dự phòng",
    "WarehouseStock": "Tổng tồn kho",
    "inventorySku": "MSKU",
    "Name": "Tên",
    "image": "Hình ảnh",
    "GenerationRules": "Quy tắc Tạo",
    "ClickContenttoEdit": "Nhấp vào nội dung để chỉnh sửa",
    "generatecount": "{productSum} sản phẩm ({availableNum} trong số {VariantSum} thuộc tính có thể tạo Sản phẩm chính). Thuộc tính bị ràng buộc với MSKU sẽ không thể Master SKU tại Ginee",
    "generateisku": "Tạo kho sản phẩm",
    "draftorfialed": "Vui lòng chỉ chọn bản nháp hoặc sản phẩm đăng lên thất bại",
    "uploadlimit": "Tệp lớn nhất: xlsx, xls chỉ 5MB. Có thể tải lên tối đa 1000 dữ liệu cùng một lúc.",
    "canbedownloaded": "Sản phẩm",
    "selectedstatus": "Trạng thái hiện tại của Sản phẩm đã chọn:",
    "donwloadtemp": "Download tệp tải lên",
    "DownloadFailedData": "Download dữ liệu thất bại",
    "importfail": "Một số dữ liệu thất bại",
    "importfinish": "Tải lên thành công",
    "Cancel": "Hủy",
    "fileerror": "Định dạng tệp không đúng",
    "close": "Đóng",
    "importing": "Đang tải lên",
    "importlabel": "Tải tệp lên",
    "upload": "Tải lên",
    "download": "Tải xuống",
    "templatetip": "Tải xuống tệp mẫu",
    "import": "Tải lên",
    "themaxtip2": "Mỗi lần tải lên, khách hàng có cùng một số điện thoại sẽ được hợp nhất.",
    "themaxtip1": "Gợi ý: Giới hạn tối đa tải lên là",
    "ActionsSuccessInfo": "Thao tác thành công!",
    "ShopeeTips": "Nhấp vào \"Bắt đầu Đồng bộ\" và hệ thống sẽ tự động mở cửa sổ Shopee, giúp bạn tiến hành đăng ký và ủy quyền.",
    "AlreadyChecked": "{selected} Đã chọn",
    "SubscriptionExtended": "Có thể gia hạn gói sử dụng, vui lòng nhấp vào \"Đăng ký\" hoặc liên hệ với chúng tôi để nâng cấp tài khoản của bạn",
    "SubscribeSuccess": "Nếu đăng ký thành công, bạn có thể kích hoạt các tính năng nâng cao. Vui lòng nhấp vào \"Đăng ký\" hoặc liên hệ với chúng tôi để nâng cấp tài khoản của bạn.",
    "SubscriptionExpired": "Xin lỗi, gói dùng thử đã hết hạn, vui lòng nhấp vào \"Đăng ký\" hoặc liên hệ với Ginee để nâng cấp tài khoản.",
    "PayNow": "Thanh toán ngay",
    "ThisFeatureIsOnlyPaidUsers": "Tính năng này chỉ dành cho nhà bán Ginee, vui lòng mua gói để sử dụng tính năng này!",
    "FoundANewGreatFeature": "Tính năng mới!",
    "urlplaceholder": "Vui lòng nhập Tên cửa hàng",
    "urltip": "Tên phải gồm 2-30 ký tự. Chỉ được phép sử dụng chữ viết thường và số",
    "urldesciption": "Vui lòng tạo Link Thành viên của cửa hàng",
    "memberurlmodaltitle": "Link trang thành viên",
    "CategoryLevel3": "Danh mục cấp 3",
    "CategoryLevel2": "Danh mục cấp 2",
    "RichTextDescription": "Nhập mô tả của bạn ở đây và áp dụng cho sản phẩm của bạn",
    "PleaseInput": "Vui lòng nhập",
    "selectall": "Chọn tất cả",
    "noauth": "Tài khoản của bạn không có quyền truy cập, vui lòng liên hệ với quản trị viên cửa hàng để yêu cầu hoặc nâng cấp",
    "NoProductYet": "Chưa có sản phẩm",
    "SeeMore": "Xem { more } sản phẩm",
    "SeeMoreExpand": "Xem thêm",
    "SaveAndNext": "Lưu & Tiếp tục",
    "NoHaveAccount": "Chưa có tài khoản?",
    "Requiredfields": "Bắt buộc điền, không được để trống!",
    "Somerequiredfields": "Thiếu thông tin bắt buộc, vui lòng điền vào",
    "Removetheseproducts": "Xóa các sản phẩm này",
    "GotoShippingSettings": "Cài đặt vận chuyển",
    "Somewithoutshipping": "Không thể lưu sản phẩm nếu không có tùy chọn vận chuyển. Vui lòng mở Tùy chọn vận chuyển trước khi chỉnh sửa sản phẩm.",
    "Discardcontent": "Bỏ qua có nghĩa là thông tin của sản phẩm sẽ giống như thông tin ban đầu trong cửa hàng",
    "Discardtip": "Bạn có chắc muốn bỏ qua thay đổi này?",
    "with": "Thay thế thành",
    "Changepriceto": "Giá thay đổi thành",
    "Reducebypercent": "Theo tỷ lệ phần trăm giảm xuống",
    "Reducebyamount": "Theo số tiền giảm xuống",
    "Addbypercent": "Theo tỷ lệ phần trăm tăng lên",
    "Addbyamount": "Theo số tiền tăng lên",
    "Basedoncurrentprice": "Theo giá hiện tại",
    "replace": "Thay thế",
    "addaftername": "Thêm vào sau tên",
    "addbeforename": "Thêm vào trước tên",
    "Changestockto": "Đổi tồn kho thành",
    "Example": "Ví dụ:",
    "errsize": "Bắt buộc điền, không được để trống",
    "BukalapakPrice": "Giá bán phải dao động từ 1 ～1.000.000.000",
    "phLazadaPrice": "Giá bán phải dao động từ 1 ～1.000.000.000",
    "LazadaPrice": "Giá bán phải dao động từ 1000 ～1.000.000.000",
    "TokopediaPrice": "Giá bán phải dao động từ 100～1.000.000.000",
    "phShopeePrice": "Giá bán phải dao động từ 2 ～1.000.000.000",
    "ShopeePrice": "Giá bán phải dao động từ 99 ～1.000.000.000",
    "pricetipBukalapak": "Giá bán phải dao động từ 1 ～1.000.000.000",
    "pricetipShopee": "Giá bán phải dao động từ 99 ～1.000.000.000",
    "errdescriptionBukalapak": "Mô tả sản phẩm phải từ 30 - 3000 kí tự",
    "errdescriptionShopee": "Mô tả sản phẩm phải từ 30 - 3000 kí tự",
    "errnameBukalapak": "Tên sản phẩm phải giữa 6 - 150 kí tự",
    "errnamelazada": "Tên sản phẩm phải giữa 2 - 255 kí tự",
    "allemptyorfilled": "Giá trị dài, rộng, cao để trống, hoặc đều điền đầy đủ giá trị",
    "errnameShopee": "Tên Sản phẩm phải giữa 5 - 100 kí tự",
    "spaceskuerr": "SKU không được có khoảng cách, vui lòng kiểm tra",
    "PleaseOneShipping": "Vui lòng cài đặt ít nhất một lựa chọn vận chuyển cho sản phẩm của bạn.",
    "pricetip": "Giá phải từ giữa 99 〜 1.000.000.000",
    "stocktip": "Hàng tồn kho phải từ 0 〜 999,999",
    "Discardchanges": "Bỏ qua thay đổi",
    "update": "Cập nhật",
    "draft": "Lưu thành bản nháp",
    "publish": "Đăng lên",
    "Logistic": "Vận chuyển",
    "ParcelSize": "Kích thước gói hàng",
    "Width": "Chiều rộng",
    "Length": "Chiều dài",
    "Height": "Chiều cao",
    "Weight": "Trọng lượng",
    "price": "Giá bán",
    "VariantName": "Tên thuộc tính",
    "parentSku": "Parent SKU",
    "productImg": "Hình ảnh",
    "Refresh": "Làm mới",
    "PlatformUpdateNotification": "Gợi ý cập nhật Ginee",
    "WaitAMoment": "Đợi giây lát",
    "GoAuth": "Liên kết",
    "NewVersionsInfo": "Quản lý kênh Shopee 2.0 hiện đã có sẵn! Hãy tích hợp tài khoản của bạn! Hãy thử nghiệm ngay tính năng Đồng hộ dữ liệu và xử lý đơn hàng!",
    "NoDataInfo": "Không có dữ liệu xử lý",
    "ActionsErrorInfo": "Thao tác thất bại, vui lòng thử lại sau",
    "and": "và",
    "PrivacyPolicy": "Chính sách bảo mật",
    "TermsConditions": "Điều khoản người dùng",
    "agree": "Tôi đồng ý với",
    "BLDescription": "Mô tả chi tiết phải từ 30-3000 kí tự",
    "markDownImageSize": "Kích cỡ ít nhất phải 300 x 300 pixels, nhỏ hơn 5M, định dạng JPG, PNG",
    "markDownAddImage": "Thêm hình ảnh",
    "noProductStore": "Chưa có liên kết cửa hàng nào, vui lòng liên kết cửa hàng của bạn và thêm sản phẩm",
    "noStoreInfo": "Bạn có thể liên kết cửa hàng online của bạn và bắt đầu đồng bộ sản phẩm, kho hàng và đơn hàng ngay lập tức.",
    "noMemberInfo": "Sau khi khách hàng đăng ký thành viên, bạn có thể quản lý thông tin và điểm tích lũy của họ tại đây, đồng thời cũng có thể thêm thành viên thủ công.",
    "noProductInfo": "Bạn chưa tạo bất kỳ sản phẩm nào. Vui lòng tạo sản phẩm mới.",
    "TextDescription": "Mô tả sản phẩm",
    "memberInfo": "Trang thông tin thành viên",
    "jdDisableTips": "Tạm thời không thể liên kết",
    "Coming": "Sắp ra mắt",
    "editPointRuleInfo": "Bạn có chắc muốn chỉnh sửa quy tắc?",
    "previewImg": "Xem trước",
    "addRule": "Thêm quy tắc",
    "methodTypeAll": "Tất cả",
    "sendSuccess": "Gửi thành công",
    "editPointsRule": "Chỉnh sửa quy tắc điểm",
    "pointeReduceErrorInfo": "Không phải tất cả người dùng đều có đủ điểm để trừ",
    "ruleFormatErrorInfo": "Chỉ được sử dụng chữ viết thường và số",
    "rewardDeductPoints": "Thưởng điểm/ Trừ điểm",
    "alreadyExists": "{ name } địa chỉ đã đăng ký",
    "signNext": "Tiếp theo",
    "copySuccess": "Sao chép thành công: { copyUrl }",
    "deleted": "Đã xóa",
    "rule": "Quy tắc",
    "noName": "Không có tên",
    "copyUrl": "Sao chép",
    "linkInfo": "Link",
    "laseSevenDaysInfo": "Số lượng thành viên đã thêm trong 7 ngày gần đây",
    "editimage": "Chỉnh sửa hình ảnh thuộc tính",
    "packageContentplaceholder": "Tối thiểu 1000 kí tự",
    "packageContent": "Nội dung gói hàng",
    "VariantImage": "Hình ảnh thuộc tính",
    "brand": "Thương hiệu",
    "brandLogo": "Logo Thương hiệu",
    "brandName": "Tên thương hiệu",
    "pointsHistory": "Lịch sử điểm tích lũy",
    "editShopInfo": "Chỉnh sửa thông tin cửa hàng",
    "alreadyShopOwn": "Chúc mừng! Cửa hàng bạn đã tạo thành công!",
    "shopSetInfo": "Thông tin cửa hàng",
    "contentWrapper": "Nội dung",
    "newSections": "Phiên bản mới",
    "accumulatePoints": "Điểm tích lũy",
    "chooseCardBg": "Chủ đề",
    "shopLogo": "Logo cửa hàng",
    "shopName": "Tên cửa hàng",
    "membershipPageSettings": "Cài đặt trang thành viên",
    "uploadImageError": "Kích thước tệp hình ảnh {fileName} quá nhỏ, tối thiểu {limitMax} x {limitMin}",
    "uploadInfo": "Yêu cầu: {maxWH} * {maxWH}, nhỏ hơn {isLt} M, hỗ trợ định dạng jpg, png, jpeg",
    "enterPointMax": "Có thể nhập tối đa lên đến 999.999",
    "enterPointNum": "Nhập một số nguyên từ giữa 1-999.999",
    "updateSuccessFul": "Cập nhật thành công",
    "pointsType": "Loại điểm thưởng",
    "redeemablePoints": "Số Điểm có hiệu lực",
    "reason": "Nguyên nhân",
    "deductPoints": "Trừ điểm",
    "rewardPoints": "Điểm thưởng",
    "joinedMethod": "Phương thức thêm thành viên",
    "dateOfBirth": "Ngày sinh",
    "remarksErrorInfo": "Tối đa 100 kí tự",
    "editDetailInformation": "Chỉnh sửa thông tin chi tiết",
    "editMemberInformation": "Chỉnh sửa thông tin thành viên",
    "profileDetails": "Chi tiết hồ sơ",
    "accountInformation": "Thông tin tài khoản",
    "rewardCondition": "Điều kiện nhận thưởng",
    "customizeAlertInfo": "Quy tắc dưới đây sẽ sớm hiển thị trên trang thành viên; tuy nhiên, điểm sẽ cần được đổi thủ công",
    "customizePointRules": "Thiết lập quy tắc tích lũy",
    "defaultAlertInfo": "Sau khi quy tắc tích lũy mặc định được bật, Khách hàng sẽ tự động nhận được điểm thưởng khi thực hiện các nhiệm vụ tương ứng",
    "defaultPointRules": "Quy tắc tích lũy mặc định",
    "joinMemberTime": "Thời gian tham gia",
    "inputNameOrMobile": "Nhập tên/số điện thoại",
    "editPoints": "Chỉnh sửa điểm",
    "addNewMember": "Thêm thành viên mới",
    "totalPage": "Tổng { total } dữ liệu",
    "description": "Mô tả",
    "points": "Điểm thành viên",
    "memberNo": "Mã thành viên",
    "mobile": "Số điện thoại",
    "shopname": "Tên cửa hàng",
    "name": "Họ tên",
    "genderFemale": "Nữ",
    "genderMale": "Nam",
    "gender": "Giới tính",
    "deleteRuleInfo": "Bạn có chắc chắn muốn xóa quy tắc này？",
    "status": "Trạng thái",
    "checkBoxTableSelected": "Đã chọn { selected }",
    "create": "Tạo",
    "dashboardPointCumulativeRedeem": "Điểm tích lũy đã đổi thưởng",
    "dashboardPointCumulativeGiven": "Điểm tích lũy",
    "dashboardPointToRedeem": "Điểm đổi thưởng",
    "dashboardNewMember": "Thành viên mới",
    "dashboardAllMember": "Tổng số thành viên",
    "hello": "Chào bạn!",
    "mobileErrorInfoLength": "Số điện thoại phải gồm 9-12 kí tự",
    "fullnameerror": "Tên cửa hàng phải từ 1～30 kí tự",
    "fullname": "Tên cửa hàng",
    "mobileplaceholder": "Số điện thoại bắt buộc gồm 11 số",
    "mobileErrorInfochina": "Số điện thoại không đúng",
    "mobileErrorInfo": "Phải bắt đầu với số 8",
    "nameErrorInfo": "Tên giới hạn từ 2-30 kí tự",
    "emailErrorInfo": "Địa chỉ email không đúng",
    "resetPwdSuccess": "Đặt lại Mật khẩu thành công",
    "resetPwd": "Đặt lại Mật khẩu",
    "alreadyInfo": "Đã có một tài khoản Ginee?",
    "applyConfirm": "Xác nhận",
    "forgotPwd": "Quên mật khẩu?",
    "codeSendSuccess": "Mã OTP đã gửi đến email này { email }",
    "noReceiveCountDown": "Không nhận được mã OTP?",
    "waitCountDown": "Vui lòng đợi { someone }s gửi lại",
    "resendCode": "Gửi lại",
    "sendCode": "Gửi",
    "signBy": "Đồng ý với Điều khoản và Chính sách Bảo mật",
    "verificationCode": "Nhập mã OTP",
    "verification": "Mã OTP",
    "userName": "Họ tên",
    "password": "Mật khẩu",
    "eMail": "E-mail",
    "signUp": "Đăng ký",
    "login": "Đăng nhập",
    "welcomeRegister": "Chào mừng đến với Ginee",
    "welcomeLogin": "Chào mừng đến với Ginee",
    "welcome": "Chào mừng đến với Ginee",
    "copyProduct": "Sao chép sản phẩm",
    "orderDetail": "Chi tiết đơn hàng",
    "productDetail": "Thông tin sản phẩm",
    "status_unpublished": "Chưa đăng",
    "status_update_failed": "Đăng thất bại",
    "status_delete": "Đã xóa",
    "status_draft": "Bản nháp",
    "status_disabled": "Ngừng bán",
    "status_banned": "Cấm bán",
    "status_sold_out": "Đã bán hết",
    "status_live": "Đang bán",
    "status_process": "Đang xử lý",
    "status_all": "Tất cả",
    "staffDnotExists": "Tài khoản nhân viên không có quyền, vui lòng liên hệ với người bán để thêm quyền (vào phần quản lý nhân viên-thêm nhân viên-thêm tài khoản này)",
    "fastPrinting": "In tốc độ cao",
    "printPluginHasBeenUpdatedPlease": "Bổ sung in đã được cập nhật, xin vui lòng tải về một lần nữa",
    "afterUnbindTheStockWillNot": "Sau khi hủy liên kếy, thao tác đẩy tồn kho sẽ không được đẩy sang các sản phẩm trên sàn.",
    "areYouSureYouWantTo": "Xác nhận hủy liên kết?",
    "MSKUAutoBindSettingTip": "Nhấn vào đây để dẫn đến trang thiết lập tự động liên kết Sản phẩm Chính",
    "masterSkuInventoryInventorySynchronizationOf": "Tồn kho Master SKU: Không hỗ trợ đồng bộ tồn kho cho sản phẩm đa kho Lazada, nếu cần, vui lòng đi đến trung tâm người bán để chỉnh sửa.",
    "selectGenieCategory": "Chọn Danh mục chính",
    "dayDuration": "Thời hạn (ngày)",
    "unbindedChannelVariant": "Chưa Liên kết Thuộc tính của Kênh",
    "deleteSkuConfirm": "Bạn có chắc chắn muốn xóa sản phẩm không?",
    "thirdpartyWarehouseProductDetails": "Chi tiết Sản phẩm của kho bên thứ 3:",
    "thereAreThirdpartyWarehouseProductAmong": "Trong các sản phẩm đã chọn, có sản phẩm của kho hàng bên thứ 3, không thể xóa.",
    "canNotDeleteThirdStock": "Kho sản phẩm trong kho hàng của bên thứ 3 đang tồn tại, không được phép xóa",
    "skuAffectedOutbound": "Có tổng {val} Phiếu xuất kho, Phiếu nhập kho sẽ tiến hành thao tác Hủy bỏ sản phẩm, bạn có muốn Hủy bỏ Phiếu xuất kho, Phiếu nhập kho để Xóa dữ liệu Master SKU và Tồn kho không?",
    "deleteSkuAlert": "Nếu Master SKU có một Phiếu xuất kho, Phiếu nhập kho chưa hoàn chỉnh, hệ thống sẽ tiến hành Hủy bỏ sản phẩm trong Phiếu xuất kho, đồng thời Xóa dữ liệu của Sản phẩm chính, Tồn kho.",
    "proceedToDelete": "Hủy bỏ và Xóa",
    "productAffectedOutbound": "Có tổng {val} đơn hàng đi sẽ bị Hủy. Bạn có muốn Hủy bỏ Phiếu xuất kho, Phiếu nhập kho cùng dữ liệu Sản phẩm chính và Tồn kho không?",
    "nextStep1": "Tiếp",
    "deleteProductAlert": "Nếu sản phẩm có Phiếu xuất kho, Phiếu nhập kho chưa hoàn chỉnh, hệ thống sẽ Hủy bỏ hàng hóa trong Phiếu xuất kho, đồng thời xóa Sản phẩm chính và dữ liệu Tồn kho.",
    "iNeed": "Tôi cần",
    "youCanImportDataFormat": "Có thể chỉnh sửa hàng loạt Tên, Mô tả sản phẩm, Danh mục chính của sản phẩm chính (Tối đa: 10.000 dữ liệu/ lần. Định dạng: xlsx,xls).",
    "WorkingDaysBetween": "Trong vòng 7-90 ngày làm việc",
    "workingDays": "Ngày làm việc",
    "withoutMasterSku": "Chỉ Master SKU chưa liên kết",
    "processTime": "Thời gian xử lý",
    "width": "Chiều rộng",
    "whenCreatingInTheProVersion": "Phiên bản Cao cấp, khi tạo cần tải lên các đoạn bắt buộc, có thể dùng để sau khi tạo sản phẩm chính sẽ nhân bản đến kênh bán hàng",
    "whenAvailableStockInfo": "Tồn kho có sẵn <= X, gửi Email nhắc nhở",
    "weightLimit": "Phải là 1-5.000.000",
    "weight": "Trọng lượng",
    "inboundLimitTips": "Khi sử dụng JD Fulfillment, hãy nhớ điền Hạn sử dụng, Ví dụ: nếu hạn sử dụng của sản phẩm là 100 ngày và giới hạn gửi đi là 0,5, thì khi hạn sử dụng dưới 50 ngày sẽ không thể cho nhập kho.",
    "outboundLimitTips": "Khi sử dụng Fulfillment by JD, hãy nhớ điền Hạn sử dụng, Ví dụ: nếu hạn sử dụng của sản phẩm là 100 ngày và giới hạn gửi đi là 0,5, thì khi hạn sử dụng dưới 50 ngày sẽ không thể cho xuất kho.",
    "inboundLimit": "Giới hạn Nhập kho",
    "outboundLimit": "Giới hạn Xuất kho",
    "shelfLife": "Hạn sử dụng",
    "custom": "Tùy chọn",
    "warehouseStockTip": "Số lượng tồn kho thực tế của kho hàng, Tổng tồn kho = Tồn kho dự phòng + Tồn kho Khóa + Tồn kho có sẵn + Tồn kho khuyến mãi",
    "warehouseStockLimit": "Tổng tồn kho bắt buộc từ 0-999.999",
    "yearly": "1 năm gần nhất",
    "monthly": "1 tháng gần nhất",
    "weekly": "1 tuần qua",
    "today": "Hôm nay",
    "warehouseStock": "Tổng tồn kho",
    "warehouseName": "Tên kho hàng",
    "viewNow": "Xem ngay",
    "viewMore": "Xem thêm",
    "viewLess": "Thu gọn",
    "view": "Xem",
    "videoName2": "Làm thế nào để quản lý chi phí mua hàng?",
    "variationTypeInfo": "Loại thuộc tính",
    "variationStatus": "Trạng thái thuộc tính",
    "variationImageSizeLimit": "Có thể cùng lúc tải lên nhiều hình ảnh. Hình ảnh nhỏ nhất 300 x 300 pixel, tối đa 5MB (Tối đa 8 ảnh)",
    "variationImageInfo": "Chỉnh sửa hình ảnh thuộc tính",
    "variationCountDesc": "Số lượng liên kết thuộc tính từ cao đến thấp",
    "variationCountAsc": "Số lượng liên kết thuộc tính từ thấp đến cao",
    "variationAlreadyBound": "Thuộc tính này đã liên kết với Master SKU, vui lòng hủy liên kết trước!",
    "exportLimit2": "Chỉ có thể xuất 50000 sản phẩm chính cùng lúc, vui lòng chọn lại",
    "exportLimit": "Vui lòng điền số trang để tải xuống, và mỗi lần có thể xuất tối đa 50000 sản phẩm chính",
    "selectChannel": "Chọn Kênh bán",
    "variation1": "Thuộc tính",
    "variantTypeRangeIs": "Loại thuộc tính 1 chỉ được gồm 1-14 ký tự",
    "variantTypeIsMissingPlease14": "Thiếu loại thuộc tính 2, vui lòng kiểm tra!",
    "variantTypeIsMissingPlease": "Thiếu Loại thuộc tính 1, vui lòng kiểm tra!",
    "variantsInfoB": "10 sản phẩm (100 thuộc tính, trong đó 50 thuộc tính có thể tạo Master SKU)",
    "variantsInfoA": "Có {productSum} sản phẩm, ({availableNum} thuộc tính, trong đó {variationSum} thuộc tính có thể tạo Master SKU. Thuộc tính đã được liên kết với Master SKU không thể tạo Master SKU.",
    "numberOfProducts": "{productSum} Sản phẩm ({variationSum} thuộc tính)",
    "variantPictureLinkCannotBe": "Link hình ảnh thuộc tính 1 không thể để trống!",
    "uploadFile": "Tải lên Tệp tin",
    "downloadTemplate": "Tải xuống tệp tin mẫu",
    "step2Limit": "Vui lòng tải xuống tệp tin mẫu thuộc tính, bạn có thể tải lên tối đa 10.000 thuộc tính cùng lúc (định đạng xlsx, xls)",
    "step2Dec": "Bước 3: Thêm hàng loạt các thuộc tính mới đã liên kết tự động",
    "step1Dec": "Bước 2: Xuất toàn bộ thuộc tính tự động liên kết.",
    "variantOptionsLimit": "Tối đa 20 lựa chọn thuộc tính",
    "variantOptionShouldBe": "Không được vượt quá 20 ký tự",
    "import10000Tip": "Mỗi lần thêm tối đa 10000 dữ liệu.",
    "variantNameChannel": "Tên thuộc tính trên kênh",
    "variantName": "Tên thuộc tính",
    "variantImage": "Hình ảnh thuộc tính",
    "username": "Tên người dùng",
    "useImageFor": "Sử dụng hình ảnh cho",
    "used": "Đã sử dụng",
    "operationReason": "Nguyên nhân thao tác",
    "goToSettings": "Đi đến cài đặt",
    "stores": "Vui lòng đi đến [Cài đặt Sản phẩm] và bật cài đặt \"Tự động liên kết Master SKU\" trước khi sử dụng chức năng này",
    "storeSKU": "Tính năng chưa mở",
    "channelVID": "Mã ID kênh bán",
    "enabledAutoBind": "Chưa kích hoạt tính năng tự động liên kết Master SKU, tạm thời không có thuộc tính!",
    "editAutoBindRule": "Quy tắc chỉnh sửa tự động liên kết",
    "updateAutoBindRules": "Quy tắc cập nhật tự động liên kết",
    "exportAllAutoBindRules": "Quy tắc tự động xuất tất cả liên kết",
    "enterStoreSKU": "Vui lòng nhập Mã sản phẩm tại sàn TMĐT",
    "bindingError": "SKU này đã liên kết đến các mã khác, vui lòng kiểm tra",
    "storeSkuLimit": "Mã sản phẩm tối đa chứa 250 ký tự",
    "bindMasterSku": "Sản phẩm chưa được liên kết sẽ tự động liên kết đến Master SKU",
    "bindedStore": "Shop liên kết",
    "bindedProduct": "Sản phẩm liên kết",
    "autoBind": "Tự động liên kết",
    "stepExportRules": "SKU cửa hàng",
    "variant": "Thuộc tính",
    "updateStock": "Cập nhật hàng tồn kho",
    "updateSafetyStock2": "Cập nhật Tồn kho an toàn",
    "updateSafetyStock": "Cập nhật hàng loạt Tồn kho an toàn",
    "updateInventoryInfo2": "Vui lòng cập nhật hàng tồn kho tại \"Quản lý tồn kho\"",
    "updateInventoryInfo": "Vui lòng cập nhật tồn kho Master SKU đã liên kết trước khi chỉnh sửa tồn kho của combo sản phẩm.",
    "updatedSuccessfully": "Cập nhật thành công",
    "updateAvailableStock": "Cập nhật Tồn kho có sẵn của cửa hàng",
    "unitPurchasPrice": "Giá mua hàng",
    "unitPurchasePrice": "Đơn giá mua hàng của sản phẩm",
    "unbindInfo": "Sản phẩm đã hủy liên kết với Master SKU và Tồn kho Khóa sẽ được mở",
    "unbind": "Hủy liên kết",
    "turnOnStock": "Bật Giám sát và Đồng bộ tồn kho, kích hoạt Tồn kho ở kênh sản phẩm",
    "totalNum": "Tổng {val}",
    "toShip": "Gửi hàng",
    "timeInfoWorkingDay": "Ngày làm việc",
    "timeInfoMonth": "Tháng",
    "thisVariationOnly": "Chỉ thuộc tính này",
    "theSameNameIsRegardedAs": "(Tên giống nhau sẽ được gộp thành một sản phẩm. Độ dài tối đa tên sản phẩm là 300 ký tự)",
    "theNameAlreadyExistsInTheStore": "Tên sản phẩm đã tồn tại",
    "theMasterSKUDoesNotExist": "Master SKU không tồn tại, vui lòng kiểm tra!",
    "theLinkOfVariantPicture": "Link hình ảnh thuộc tính 1 không hợp lệ, vui lòng nhập lại!",
    "theInventorySkuAlreadyExists": "Master SKU này đã tồn tại",
    "theCurrencyUnitOfTheDefault": "(Tiền tệ của Giá bán mặc định và Đơn giá mua hàng là USD/IDR/PHP/THB/VND/MYR/SGD/CNY)",
    "theCategoryIDDoesNotExist": "Dang mục ID không tồn tại, vui lòng tải xuống và kiểm tra Bảng danh mục",
    "theBasicVersionCanImportA": "Phiên bản Cơ bản chỉ yêu cầu nhập một vài thông tin cần thiết và hỗ trợ tạo Master SKU nhanh chóng, giúp kích hoạt tính năng Quản lý Tồn kho;",
    "syncInfoB": "{6787686756 Hủy đồng bộ hàng tồn kho",
    "syncInfoA": "{6787686756}Bật đồng bộ hàng tồn kho",
    "successNumber": "Số lượng thành công",
    "successfullyUnbindInfo": "Hủy liên kết thành công {val} thuộc tính",
    "successfullyBindInfo": "Liên kết thành công {val} thuộc tính",
    "storeWithoutSales": "Cửa hàng chưa bán",
    "storeUpdateResult": "Kết quả cập nhật cửa hàng",
    "storeSku": "SKU cửa hàng",
    "storeNameB": "Tên cửa hàng",
    "storeNameA": "Tên cửa hàng",
    "storeCountDesc": "Số cửa hàng đang bán từ cao đến thấp",
    "storeCountAsc": "Số cửa hàng đang bán từ thấp đến cao",
    "stockSettingsStockMonitorAndSyncOn": "Cài đặt Tồn kho - Giám sát và đồng bộ tồn kho, bắt đầu: 26-10-2020 17:17",
    "stockSettingsStockMonitorAndSyncOff": "Cài đặt Tồn kho - Giám sát và Đồng bộ tồn kho, kết thúc: 26-10-2020 17:17",
    "stockChannelInfo": "Tổng tồn kho có thể bán tại kênh = Tổng tồn kho - Tồn kho dự phòng - Tồn kho Khóa - Tồn kho khuyến mãi",
    "stockChanges": "Có sự thay đổi tồn kho",
    "stepImportAndCreateThe": "Bước 2: Nhập tạo Sản phẩm chính",
    "stepDownloadTheMasterCategory": "Bước 1: Tải xuống Id Danh mục chính",
    "step3Text": "Bước 3: Nhập để liên kết sản phẩm",
    "step2Text": "Bước 2: Xuất Master SKU",
    "step1Text": "Bước 1: Chọn và xuất kênh sản phẩm mà bạn muốn liên kết Master SKU",
    "spareStockTip": "Tổng lượng hàng tồn kho trong kho hàng thực tế sẽ không bán ra trên kênh",
    "spareStock": "Tồn kho dự phòng",
    "sourceUrl": "Nguồn URL",
    "sortMasterProduct": "Lọc sản phẩm chính",
    "someWarehouseTheWarehouseStockOf": "Trong một số kho hàng, Tổng tồn kho của Master SKU không phải là 0, vui lòng cập nhật thành 0 rồi xóa.",
    "somePartUpdateFailed": "Cập nhật thành công một phần",
    "somePartReviewFailed": "Một số đánh giá thất bại",
    "someDatasImportFailed": "Một phần dữ liệu nhập thất bại",
    "someCreateFailed": "Một số tạo thất bại",
    "skuStoreSku": "SKU (SKU cửa hàng)",
    "skuAlreadyExistsPleaseDeleteThe": "SKU đã tồn tại, vui lòng xóa sản phẩm hiện có hoặc đổi tên!",
    "singlePurchasePriceOfTheProduct": "Đơn giá mua hàng",
    "singlePurchasePrice": "Đơn giá mua hàng",
    "shouldBeBetweenLimitB": "Dao động từ 0 - 1.000.000.000",
    "shouldBeBetweenLimitA": "Dao động từ 0 - 999.999",
    "shouldBeBetweenB": "Phải từ 1-999.999!",
    "shouldBeBetweenA": "Phạm vi nhập là 1-999.999",
    "shippingFeeInformation": "Phí vận chuyển",
    "setPageTip2": "Vui lòng nhập số trang hợp lệ",
    "setPageTip": "Chọn trang để xuất",
    "selling": "Đang bán",
    "selectProductBundle": "Chọn combo sản phẩm",
    "selectGineeCategory": "Chọn danh mục chính",
    "selectFile": "Chọn tệp",
    "selectedMax20": "Đã chọn (Tối đa 20)",
    "selected": "Đã chọn",
    "selectCategories": "Vui lòng chọn danh mục",
    "selectAtLeastOneInventorySku": "Chọn tối thiểu 1 Master SKU",
    "editRule": "Chỉnh sửa quy tắc",
    "selectAtLeastOneImage": "Chọn tối thiểu 1 hình ảnh",
    "createDates": "Giờ tạo",
    "seeXProducts": "Xem X sản phẩm",
    "seeMoreTips": "Xem {val} sản phẩm",
    "saveUpdate": "Lưu cập nhật",
    "saveForReview": "Lưu thành Chờ đánh giá",
    "savedSuccessfully": "Lưu thành công",
    "saveAsReviewed": "Lưu thành Đã duyệt",
    "salesTaxAmount": "Số tiền thuế bán hàng",
    "safetyStockStatus": "Trạng thái Tồn kho an toàn",
    "safetyStock": "Tồn kho an toàn",
    "rupiah": "Rp",
    "reviewPassed": "Kiểm duyệt thông qua",
    "reviewed": "Đã duyệt",
    "reReview": "Kiểm duyệt lại",
    "reasonForFailure": "Nguyên nhân thất bại: điền thiếu thông tin, vui lòng bổ sung",
    "rangeLimitA": "Phạm vi phải từ 0 - 1.000.000.000",
    "rangeIsLimit": "Phạm vi là 5.000.000",
    "quantityLimit": "Số lượng tương ứng phải là 1-999.999",
    "qty": "Số lượng tương ứng",
    "purchaseTime": "Thời gian mua hàng",
    "purchasePriceShouldLimit": "Đơn giá mua hàng bắt buộc từ 1.000 - 1.000.000.000",
    "purchasePriceLimit": "Giá bắt buộc từ 0 - 1.000.000.000!",
    "purchasePriceInfo": "(Không cần nhập vào, giá mua hàng sẽ tính toán dựa theo chi phí bình quân)",
    "proVersionImportToCreate": "Phiên bản cao cấp nhập để tạo",
    "promotionStockTip": "Tồn kho khuyến mãi là tổng hàng tồn kho dự kiến của hoạt động khuyến mãi cho Campaign hoặc Flash Sale",
    "promotionStock": "Tồn kho khuyến mãi",
    "productType": "Loại sản phẩm",
    "productsWithoutVariantsCanBeEmpty": "(Sản phẩm không có thuộc tính có thể để trống)",
    "productSources": "Nguồn sản phẩm",
    "productNameLimit": "Tên sản phẩm bắt buộc gồm 5 đến 300 ký tự",
    "productNameInventorySku": "Tên sản phẩm & Master SKU",
    "productInformation": "Thông tin mục",
    "productImageLinkIsInvalid": "Link hình ảnh sản phẩm 1 không hợp lệ, vui lòng kiểm tra!",
    "productImageLinkCannotBe": "Link hình ảnh sản phẩm 1 không thể để trống!",
    "productImage": "Hình ảnh sản phẩm",
    "productFailedToCreatePleaseDownload": "Tạo Sản phẩm chính thất bại, vui lòng tải xuống dữ liệu thất bại và xem lý do thất bại",
    "productEditSuccess": "Chỉnh sửa sản phẩm thành công",
    "productEditFailed": "Chỉnh sửa sản phẩm thất bại",
    "productDescriptionShouldBeCharacters": "Mô tả sản phẩm nên gồm 1-7000 ký tự",
    "productDescription": "Mô tả sản phẩm",
    "productCreatedSuccessfully": "Tạo sản phẩm thành công",
    "product": "Sản phẩm",
    "preorder11": "Pre-order",
    "popupResultButton": "Xem dữ liệu thất bại",
    "pleaseEnterTheAmount": "Vui lòng nhập số tiền",
    "pleaseEnterKeywordsToSearchChannelProducts": "Vui lòng nhập từ khóa để tìm kiếm kênh sản phẩm",
    "pleaseEnter": "Vui lòng nhập",
    "pleaseDownloadTheTemplateAndComplete": "Vui lòng tải xuống và nhập đầy đủ thông tin vào tệp Mẫu, sau đó tải lên để tạo sản phẩm (mỗi lần có thể nhập 10.000 dữ liệu).",
    "pleaseChoose": "Vui lòng chọn",
    "partiallyFailedInfo": "Thất bại một phần, trạng thái: Đã duyệt",
    "packageSize": "Kích thước bưu phẩm",
    "orderToEnsureTheIntegrity": "Để đảm bảo tính toàn vẹn của quy trình và dữ liệu báo cáo thống kê, không thể xóa một số Master SKU, do đó không thể chỉnh sửa loại thuộc tính của sản phẩm này. Bạn nên \"Trích dẫn\" sản phẩm này để tạo ra nhiều thuộc tính của sản phẩm chính, và sau đó đặt sản phẩm này là \"Tạm dừng theo dõi\".",
    "orderToEnsureTheIntegrityOf": "Để đảm bảo tính hoàn thiện của số liệu, đề nghị sử dụng \"Tạm dừng theo dõi\"",
    "optionLimit": "Lựa chọn phải từ 1-30 ký tự",
    "option": "Lựa chọn",
    "operationTime": "Thời gian thao tác",
    "operationGuide": "Hướng dẫn thao tác",
    "noShelfLife": "Hạn sử dụng",
    "onSaleShop": "Cửa hàng đang bán",
    "onReview": "Đang đánh giá",
    "onlyShowBindedVariations": "Chỉ hiển thị các thuộc tính không có MSKU",
    "numVariationsSelected": "Đã chon 0 thuộc tính",
    "numVariations": "{val} Thuộc tính",
    "numSelected": "Đã chọn {val}",
    "numEditBindInformation": "{val}-Chỉnh sửa thông tin liên kết",
    "nowInbound": "Nhập kho ngay",
    "normalProducts": "Sản phẩm phổ thông",
    "noData": "Không có dữ liệu",
    "noChannelVariationId": "ID thuộc tính kênh không tồn tại",
    "newProduct": "Hàng mới",
    "newAveragePurchasePrice": "Giá mua hàng bình quân mới",
    "newAverageCostPrice": "Chi phí bình quân mới",
    "new": "Mới",
    "mutiVariations": "Sản phẩm có nhiều loại thuộc tính, nếu sản phẩm của bạn khác nhau về màu sắc, kích thước, vui lòng chọn mục này",
    "mustBeBetween": "Bắt buộc từ 1-8",
    "mskuAndstockVideo": "Sản phẩm chính và Quản lý hàng tồn kho có mối quan hệ gì?",
    "mSku": "MSKU",
    "more": "Thêm",
    "minPurchaseQuantityShouldBe": "Số lượng mua tối thiểu là 1",
    "minimumPurchaseQuantity": "Số lượng mua tối thiểu",
    "mediaSettings": "Quản lý truyền thông",
    "maxNumVal": "Tối đa {val} ảnh",
    "maxNum": "Tối đa {val}",
    "maxLengthNum": "Tối đa {val} ký tự",
    "maxLengthCharacters": "Tối đa {max} ký tự",
    "maxLength14": "Tối đa 14 ký tự",
    "maximumIs": "(Tối đa là 1.000.000.000)",
    "maximumCharacters": "(Độ dài tối đa là 7000 ký tự)",
    "max9": "Tối đa 9 ảnh",
    "masterSkuHasBeenMatchedWith": "Master SKU đã được khớp với các sản phẩm của kho của bên thứ ba.",
    "masterProductsImage": "Sản phẩm & hình ảnh chính",
    "masterProductName": "Tên sản phẩm chính",
    "masterProduct": "Sản phẩm chính",
    "master": "Sản phẩm chính",
    "massSettings": "Cài đặt hàng loạt",
    "mainPhotos": "Ảnh đại diện",
    "lockedStockTip": "Kho hàng đang chờ fulfilled, nhưng chưa gửi hàng. Đồng bộ đến đơn hàng \"Thanh toán mới\" hoặc \"Chờ thanh toán\" của Ginee, sẽ được thêm vào Tồn kho Khóa",
    "lockedStock": "Tồn kho Khóa",
    "liveTips": "Phạm vi: bao gồm Hết hàng và Đang bật bán",
    "live": "Live",
    "length": "Chiều dài",
    "iWillShipOutWithinX": "Tôi sẽ gửi hàng trong vòng X giờ làm việc",
    "iWillShipOutWithin22": "Tôi sẽ gửi hàng trong 2 ngày làm việc. (không bao gồm ngày nghỉ lễ và ngày nghỉ của dịch vụ giao hàng)",
    "iWillShipOutWithin": "Tôi sẽ gửi hàng trong vòng 3 ngày làm việc. (không bao gồm ngày nghỉ lễ và ngày nghỉ của dịch vụ giao hàng)",
    "iskuStandsForInventorySku": "MSKU là Master SKU",
    "iskuCannotBeEmpty": "Master SKU không được để trống",
    "isAnUnprocessedOutboundInThe": "Master SKU tồn tại Phiếu xuất hàng chưa xử lý",
    "inventorySkuSpaceLimit": "Master SKU chứa khoảng cách hoặc xuống dòng, vui lòng đặt lại tên.",
    "inventorySkuLimit": "Master SKU tối đa 300 ký tự",
    "inventorySkuGenRules": "Quy tắc tạo Master SKU thuộc tính mặc định:\\n1. Master SKU sẽ chọn mặc định 50 ký tự đầu tiên của Variation\\n2. Tổng tồn kho sẽ mặc định bằng Tồn kho thuộc tính, Tồn kho an toàn để trống\\n3. Khi tạo Master SKU sẽ liên kết SKU của thuộc tính",
    "inventorySkuExistsLimit": "Master SKU đã tồn tại, vui lòng chỉnh sửa",
    "instructions": "Hướng dẫn chi tiết",
    "iNeedXWorkingDays": "Tôi cần X ngày làm việc mới có thể gửi hàng",
    "iNeedXWeeksToShip": "Tôi cần X tuần mới có thể gửi hàng",
    "iNeedXDaysToShip": "Tôi cần X ngày mới có thể gửi hàng",
    "importToEditProduct": "Chỉnh sửa sản phẩm hàng loạt",
    "importToBindIntro": "Chức năng này hỗ trợ khách hàng liên kết nhiều sản phẩm của cửa hàng với Master SKU, để tiện Quản lý tồn kho.",
    "importToBind": "Nhập liên kết sản phẩm",
    "importTip": "Mỗi lần nhập tối đa 1000 dữ liệu",
    "importMasterProducts": "Nhập sản phẩm chính",
    "importFileFormatIsIncorrectInfo2": "Mẫu của tệp nhập vào không đúng, vui lòng kiểm tra",
    "importFileFormatIsIncorrect": "Nhập sai định dạng tệp, vui lòng tải xuống Mẫu",
    "importFile": "Tải tệp lên",
    "importFailed": "Nhập thất bại",
    "importAndCreateProducts": "Tạo mới sản phẩm hàng loạt",
    "importAndAddInfo": "Có thể thêm hàng loạt Sản phẩm chính (Tối đa: 10.000 dữ liệu/ lần. Định dạng: xlsx,xls).",
    "imageSizeLimit": "Kích cỡ hình ảnh nhỏ nhất 300 x 300 pixel, tối đa 5MB",
    "ifItIsEmptyWillNot": "Thông tin để trống không thể cập nhật!",
    "hour": "Giờ",
    "hotSale": "Hot Sale",
    "helpCenterLink": "Hướng dẫn thao tác",
    "height": "Chiều cao",
    "hasBindedInfo": "Thuộc tính này đã liên kết Master SKU: xxxxxxxx. Vui lòng cập nhật hàng tồn kho trong \"Quản lý tồn kho\"",
    "generateMaster": "Tạo Master SKU giúp quản lý tồn kho của sản phẩm tại các kênh dễ dàng.",
    "filter": "Lọc",
    "fileRules": "Định dạng tệp: xlsx, xls.Tối đa: 5MB",
    "faqName2": "Làm thế nào nhập tạo Sản phẩm chính tại Phiên bản Cơ Bản?",
    "faqName1": "Làm thế nào nhập tạo Sản phẩm chính tại Phiên bản Cao Cấp?",
    "failedReason": "Nguyên nhân thất bại: Sản phẩm có thể đã xóa, vui lòng đồng bộ lại sản phẩm",
    "failedNumber": "Số lượng thất bại",
    "exportUpTo": "Mỗi lần có thể liên kết tối đa 10.000 sản phẩm (Định dạng xlsx, xls).",
    "exportTip": "Vui lòng chọn sản phẩm cần xuất",
    "exportSelected": "Xuất theo mục đã chọn",
    "exportOrImport": "Xuất/Nhập",
    "exportByPageTip": "Vui lòng nhập số trang hợp lệ",
    "exportByPage": "Xuất theo trang",
    "export": "Xuất",
    "enterTheNameOfTheVariationForExampleColorEtc": "Vui lòng nhập tên thuộc tính, ví dụ: màu sắc vv",
    "enterProcessTime": "Nhập thời gian xử lý",
    "enterPageNumber": "Vui lòng nhập số trang để tải xuống sản phẩm chính",
    "enterOption": "Vui lòng nhập lựa chọn, ví dụ: màu đỏ vv",
    "enableInventorySync": "Bật đồng bộ tồn kho",
    "editSafetyFtock": "Chỉnh sửa Tồn kho an toàn",
    "editOptionsBlue": "Chỉnh sửa lựa chọn-Blue",
    "editInventory": "Cập nhật hàng tồn kho",
    "editAvailableStock": "Chỉnh sửa Tồn kho có sẵn",
    "downloadTip": "Tải xuống",
    "downloadTheMasterCategory": "Tải xuống danh mục chính",
    "downloadTemplateInfo": "Tải xuống mẫu",
    "downloadProduct": "Tải xuống sản phẩm",
    "downloadFailedData": "Tải xuống dữ liệu thất bại",
    "discontinued": "Dừng bán",
    "disableInventorySync": "Tắt đồng bộ tồn kho",
    "descriptionRequired": "Vui lòng nhập mô tả của bạn và áp dụng cho sản phẩm của bạn",
    "descriptionLimit": "Mô tả bắt buộc từ giữa 30 đến 5000 ký tự",
    "delivery": "Chi phí vận chuyển",
    "deleteMPTips2": "Đã xóa sản phẩm chính",
    "deleteMPTips": "Xóa thất bại: {val} sản phẩm chính",
    "deleteLimit": "Nguyên nhân thất bại: Vui lòng hủy liên kết với MSKU {val}, sau đó thao tác xóa.",
    "delConfirmTitle": "Bạn chắc chắn muốn xóa sản phẩm không?",
    "delConfirmContent": "Sau khi xóa sản phẩm chính, Master SKU của Quản lý tồn kho cũng sẽ bị xóa",
    "defaultPrice": "Giá bán mặc định",
    "dataSources": "Nguồn dữ liệu",
    "datasImportSuccess": "Nhập dữ liệu thành công",
    "datasImport": "Nhập dữ liệu",
    "creatingProduct": "Đang tạo sản phẩm",
    "createProductFailed": "Tạo sản phẩm thất bại",
    "costInformation": "Thông tin giá gốc",
    "continueToBindInfo": "Bạn có thể tiếp tục liên kết kênh sản phẩm trong danh sách sản phẩm chính",
    "condition": "Trạng thái lưu trữ sản phẩm",
    "columnsCannotBeEmpty": "Lựa chọn này không được để trống",
    "collectByAdd": "Chọn và thêm",
    "cny": "CNY",
    "salesTaxAmountLimit": "Giá bắt buộc từ 0 - 1.000.000.000!",
    "clearance": "Bán hết",
    "clear": "Xóa",
    "checkNow": "Kiểm tra ngay",
    "channelVariationIdNotEmpty": "ID thuộc tính kênh không được để trống",
    "channelVariationId": "ID thuộc tính kênh",
    "channelSellingStatus": "Trạng thái bán hàng của kênh",
    "channelProductsImage": "Sản phẩm & hình ảnh các kênh",
    "channelProducts": "Kênh sản phẩm",
    "channelProductName": "Tên sản phẩm trên kênh",
    "changeTo": "Sửa thành",
    "changeReason": "Nguyên nhân thay đổi",
    "changeIsku": "Thay đổi Master SKU",
    "ceneratedFromChannelProduct": "Tạo từ kênh sản phẩm",
    "categoryID": "ID Danh mục",
    "category": "Danh mục",
    "cancelUpdateConfirm": "Bạn chắc chắn muốn bỏ qua lần cập nhật này không?",
    "bundledProduct": "Combo sản phẩm",
    "bundle": "Combo",
    "boundInfo": "Đã liên kết",
    "bindProduct": "Sản phẩm liên kết",
    "bindNow": "Liên kết ngay",
    "bindInstruction": "Sau khi kết hợp kênh sản phẩm và Master SKU, vui lòng tải xuống Mẫu và nhập dữ liệu để liên kết",
    "bindingType": "Loại liên kết",
    "bindingInProgress": "Đang liên kết hàng loạt",
    "bindingInformation": "Thông tin liên kết",
    "bindInformation": "Liên kết thông tin",
    "bindInfoB": "l,gree {id:111234} hủy liên kết với {6787686756}",
    "bindInfoA": "l,gree{id:111234} liên kết {6787686756}",
    "bindHistory": "Lịch sử liên kết",
    "bindedInventorySku": "Đã liên kết SKU",
    "binded0Variation": "Đã liên kết 0 thuộc tính",
    "weightShouldBeBetween": "Khối lượng bắt buộc từ 1-5.000.000!",
    "heightShouldBeBetween": "Chiều cao bắt buộc từ 1-999.999!",
    "widthShouldBeBetween": "Chiều rộng bắt buộc từ 1-999.999!",
    "lengthShouldBeBetween": "Chiều dài bắt buộc từ 1-999.999!",
    "betweenWeeks": "Trong 1-13 tuần",
    "betweenDays": "Trong 1-90 ngày",
    "between21": "Giữa 7-15",
    "between": "Giữa 3-30",
    "beBetweenWorkingHoursMust": "Trong vòng 1~8 tiếng làm việc",
    "basicVersionImportToCreate": "Nhập tạo sản phẩm (Cơ bản)",
    "theSameNameWillConsideredAs": "(Tên giống nhau sẽ được gộp thành một sản phẩm. Độ dài tối đa tên sản phẩm là 300 ký tự)",
    "categoryName": "Tên danh mục",
    "basicInformation": "Thông tin cơ bản",
    "variantPictureLink": "Link hình ảnh thuộc tính 1",
    "barcodeLimitC": "Mã vạch chỉ hỗ trợ chữ cái, số và-_và không nên vượt quá 100 ký tự",
    "productImageLink1": "Link hình ảnh sản phẩm 1",
    "barcodeLimitB": "Mã vạch không vượt quá 100 ký tự",
    "barcodeLimitA": "Mã vạch chỉ hỗ trợ số, chữ cái và -_",
    "temporaryNotFollowingXProducts": "Đã Tạm dừng theo dõi {X} sản phẩm",
    "thisBarcodeIsAlreadyOccupiedBy": "Mã vạch đã bị {MSKU} chiếm dụng, vui lòng nhập lại",
    "currency": "Tiền tệ",
    "barcode": "Mã vạch",
    "averagePurchasePrice": "Giá mua hàng bình quân",
    "averageCostPriceInfo": "Chi phí bình quân = Tổng đơn giá mua hàng / Tổng hàng tồn kho",
    "averageCostPrice": "Chi phí bình quân",
    "variantOption1ShouldBe": "Không được vượt quá 20 ký tự",
    "variantOption1": "Loại thuộc tính 1",
    "variantOption2": "Loại thuộc tính 3",
    "variantTypeIs2": "Loại thuộc tính 2",
    "variantTypeIs": "Loại thuộc tính 1",
    "unspecifiedProductsCanBeEmpty": "Sản phẩm không có thuộc tính có thể để trống",
    "availableStockTip": "Tổng số lượng hàng tồn kho của kênh có thể bán ra, Tồn kho có sẵn = Tổng tồn kho - Tồn kho dự phòng - Tồn kho Khóa - Tồn kho khuyến mãi",
    "availableStockLimit": "Tồn kho có sẵn không được để trống",
    "availableStockInputLimit": "Tổng tồn kho",
    "availableStockInfo": "Tồn kho có sẵn = Tổng tồn kho - Tồn kho dự phòng - Tồn kho bị Khóa -Tồn kho khuyến mãi, vui lòng cập nhật Tổng tồn kho trước khi điều chỉnh Tồn kho có sẵn",
    "availableStock": "Tồn kho có sẵn",
    "availableForDownloadCategory": "(Tải xuống Danh mục có sẵn)",
    "autoMatchVariationInfo": "Sau khi tạo Master SKU，Master SKU sẽ tự động liên kết những thuộc tính phù hợp của SKU của cửa hàng với Master SKU",
    "articleName2": "Làm thế nào để liên kết Kênh sản phẩm với Master SKU?",
    "applyToAllVariation": "Áp dụng cho tất cả thuộc tính",
    "aOfb": "1-50 trang, tổng 219",
    "allVariation": "Tất cả thuộc tính",
    "missingVariantPleaseCheck": "Thiếu lựa chọn thuộc tính 1, vui lòng kiểm tra!",
    "allUpdateFailed": "Cập nhật thất bại toàn bộ",
    "max1000": "Số lượng mua tối đa là 1000",
    "missingMasterProductVariantRed": "Sản phẩm chính thiếu thuộc tính：red/s、red/L\ncần bổ sung để tạo sản phẩm.",
    "massOperationHistory": "Lịch sử thao tác hàng loạt",
    "publishToStore": "Đăng lên cửa hàng",
    "chooseDestinationStore": "Chọn cửa hàng thao tác",
    "selectStore": "Chọn cửa hàng",
    "nextStep": "Tiếp theo",
    "orderProcessingTimeFollowsTheShops": "Thời gian xử lý đơn hàng tuân theo cài đặt của cửa hàng",
    "allUpdatedSuccessfully": "Cập nhật thành công toàn bộ",
    "allSuccessfullyInfo": "Toàn bộ chuyển thành Đang chờ duyệt",
    "allSuccessfullyCreated": "Tất cả đã tạo thành công",
    "allReviewFailed": "Tất cả đánh giá thất bại",
    "shipTime": "Thời gian xử lý",
    "allReviewed": "Tất cả đánh giá thông qua",
    "allRecordsOfChannelProductsInfo": "Tất cả lịch sử ở các kênh cửa hàng (kênh sản phẩm đã liên kết với Master SKU) sẽ được hệ thống Ginee cập nhật (30 ngày gần nhất)",
    "INeed": "Tôi cần",
    "allFailedInfo": "Tất cả thất bại, trạng thái: Đã duyệt",
    "allCreateFailed": "Tất cả tạo thất bại",
    "hoursWorking": "Giờ làm việc",
    "instant": "Ngay lập tức",
    "orderProcessingDeadline": "Hạn chót xử lý đơn hàng",
    "all": "Tất cả",
    "toViewTheMassOperationRecords": "Hỗ trợ xem chi tiết Lịch sử thao tác trong vòng 7 ngày. Xem ngay!",
    "toPublishToTheStoreHow": "Làm thế nào \"Đăng tải sản phẩm lên cửa hàng\"?",
    "toBatchEditingOfMainProducts": "Làm thế nào \"Chỉnh sửa Sản phẩm chính?",
    "thePriceOfTheProductIn": "Đồng bộ giá của sản phẩm trong kênh đã liên kết với Ginee",
    "productSyncronized": "Đồng bộ sản phẩm",
    "isRecommendedToSynchronizeThePromotion": "Nên đồng bộ các hoạt động của cửa hàng trong kênh trước, để đảm bảo rằng giá khuyến mãi của các sản phẩm phù hợp với trung tâm người bán",
    "promotionSync": "Đồng bộ khuyến mãi",
    "variationIdHasBeenBoundChannel": "Đã liên kết ID thuộc tính của kênh",
    "productInformationMaster": "Thông tin Sản phẩm chính",
    "theInformationOfAnExistingMaster": "Sử dụng thông tin của sản phẩm chính hiện có",
    "productReferenceMaster": "Sử dụng sản phẩm chính",
    "copy": "Sao chép",
    "skuAutobindSettingEnabledMaster": "Tự động liên kết cài đặt Master SKU (Đã bật)",
    "skuAutobindSettingNotEnableMaster": "Tự động liên kết cài đặt Master SKU (Chưa bật)",
    "MSKUAutoBindSettings": "Cài đặt tự động liên kết Master SKU",
    "matchingExact": "Kết hợp chính xác",
    "matchingFuzzy": "Kết hợp mơ hồ",
    "Choose": "Chọn",
    "stockWarehouse": "Tổng tồn kho",
    "afterTheSafetyInfo": "Sau khi kích hoạt Tồn kho an toàn, nếu Tồn kho có sẵn của Master SKU nhỏ hơn Tồn kho an toàn, sẽ gửi email nhắc nhở.",
    "nowIntegrate": "Liên kết ngay",
    "productHasBeenBoundChannel": "Đã liên kết Sản phẩm của Kênh",
    "stockSynchronizationSettingsEnable": "Bật Cài đặt Đồng bộ Tồn kho",
    "inboundToWarehouseAlready": "Đã đặt vào Kho",
    "partFailedDelete": "Xóa thất bại Một phần",
    "allFailedDelete": "Xóa thất bại Tất cả",
    "allSuccessfulDelete": "Xóa thành công Tất cả",
    "close1": "Đóng",
    "productHasAOutboundListTo": "Sản phẩm đã có Phiếu nhập kho, để đảm bảo tính hoàn thiện của dữ liệu, khuyến cáo sử dụng \"Tạm dừng theo dõi\" sản phẩm này",
    "warehouseStockOfThisProductIs": "Một phần Tổng tồn kho của sản phẩm này không phải là 0, vui lòng làm trống và xóa",
    "productHasBeenBoundToA": "Sản phẩm này đã liên kết với sản phẩm trong kho của bên thứ ba. Để đảm bảo tính toàn vẹn của dữ liệu, bạn nên sử dụng \"Tạm dừng theo dõi\" sản phẩm này",
    "afterTheChannelInfo": "Sau khi kênh sản phẩm liên kết Master SKU, Tồn kho cửa hàng sẽ được kích hoạt",
    "failedDelete": "Xóa thất bại",
    "productBindingStatusChannel": "Trạng thái liên kết Sản phẩm của Kênh",
    "boundChannelProductAll": "Tất cả đã liên kết Sản phẩm của Kênh",
    "boundChannelProductPartially": "Một phần đã liên kết Sản phẩm của Kênh",
    "allChannelProductUnbind": "Tất cả chưa liên kết Sản phẩm của Kênh",
    "operationSuccessfulMass": "Thao tác hàng loạt đã thành công!",
    "toTheMasterProductAnd": "Khôi phục theo dõi Sản phẩm chính này, không đánh dấu thành \"Tạm dừng theo dõi\" nữa",
    "temporaryUntrackedDisplay": "Hiển thị Tạm dừng theo dõi",
    "unbindMasterProductAndChannelProduct": "Hủy liên kết giữa Sản phẩm chính và Sản phẩm của kênh hàng loạt",
    "recoverMass": "Khôi phục Hàng loạt",
    "temporaryUntrackedMass": "Tạm dừng theo dõi Hàng loạt",
    "unbindMass": "Hủy liên kết Hàng loạt",
    "deleteMass": "Xóa hàng loạt",
    "group": "Nhóm",
    "reference": "Hướng dẫn",
    "untrackedTemporary": "Tạm dừng theo dõi",
    "combination": "Kết hợp",
    "variantMultiple": "Nhiều Thuộc tính",
    "addWarehouseStockLimit": "Tổng tồn kho bắt buộc từ 1-999.999",
    "addVariation": "Thêm thuộc tính",
    "addSpareStockLimit": "Phạm vi hàng tồn kho từ 0-999.999",
    "supportSelectingMultipleStoresInThe": "Chỉ hỗ trợ chọn nhiều cửa hàng trong cùng một quốc gia",
    "variantNo": "Không có thuộc tính",
    "theProductThatFailedToBe": "Đặt các sản phẩm xóa thất bại là Tạm dừng theo dõi, do đó, các sản phẩm chính sẽ không còn được hiển thị trong \"Tất cả\"",
    "masterSkuHasBeenBoundBy": "Master SKU đã liên kết bởi sản phẩm kết hợp, vui lòng hủy liên kết trước khi xóa.",
    "masterProductsThatAreSetAs": "Tất cả các sản phẩm chính được đặt là \"Tạm dừng theo dõi\" (bao gồm các loại sản phẩm kết hợp hoặc phổ biến)",
    "typeOfMasterProductCombined": "Sản phẩm chính loại kết hợp",
    "productSingle": "Sản phẩm đơn",
    "theGeneralOfMasterProductType1": "Trong số các loại sản phẩm chính phổ biến, có 1 hoặc 2 loại thuộc tính của sản phẩm chính",
    "theGeneralOfMasterProductType": "Trong số các loại sản phẩm chính phổ biến, loại sản phẩm chính không có thuộc tính",
    "notIncludeTheTemporaryUntrackedMaster": "Không bao gồm sản phẩm chính \"Tạm dừng theo dõi\"",
    "skuHasBeenBoundByThe": "Master SKU đã liên kết bởi sản phẩm kết hợp {bundle MSKU}, vui lòng hủy liên kết trước khi xóa.",
    "skuHasMatchedTheProductsOf": "Master SKU đã khớp với các sản phẩm của kho bên thứ ba {tên kho bên thứ ba}",
    "skuHasPendingOutboundOrderOr": "Master SKU có Phiếu xuất kho hoặc Phiếu nhập kho đang chờ xử lý, vui lòng hoàn tất trước khi xóa",
    "stockOfTheMasterSkuIn": "Tổng tồn kho của Master SKU trong kho {tên kho 1, tên kho 2,} khác 0, vui lòng cập nhật về 0 trước khi xóa.",
    "errorPleaseRefreshThePageAnd": "Số liệu bất thường, vui lòng làm mới trang và thử lại",
    "retry": "Thử lại",
    "productSuccessfullyCreatedMaster": "Tạo sản phẩm chính thành công",
    "beBetweenMust": "Phải giữa 1-999.999",
    "addOption": "Thêm lựa chọn",
    "stockMonitoringAndSynchronizationEnable": "Bật giám sát và đồng bộ tồn kho",
    "toWarehouseInboundAdd": "Thêm vào khi + Nhập kho",
    "channelProductsRefer": "Trích dẫn sản phẩm của kênh",
    "failedUnbind": "Hủy liên kết thất bại",
    "addMskuArticle": "Làm thế nào để thêm Master SKU?",
    "unbindAllFailed": "Hủy liên kết tất cả không thành công",
    "allUnbindSuccessfully": "Tất cả hủy liên kết thành công",
    "partialUnbinding": "Không thể hủy liên kết một phần",
    "unbinded": "Chưa liên kết",
    "bound": "Đã liên kết",
    "unbindedAll": "Tất cả chưa liên kết",
    "boundAll": "Tất cả đã liên kết",
    "masterSkuHasEnableStockSynchronization": "Master SKU đã bật đồng bộ tồ kho, vui lòng đi đến quản lý tồn kho để chỉnh sửa tồn kho",
    "youNeedToEnableStockSynchronization": "Nếu bạn cần bật đồng bộ tồn kho của sản phẩm chính, vui lòng tiếp tục hoàn tất thao tác thêm sản phẩm chính vào kho và nhập kho",
    "toProductListReturn": "Quay lại danh sách sản phẩm",
    "failedDataDownload": "Tải xuống dữ liệu thất bại",
    "createFailedAll": "Tất cả tạo thất bại!",
    "addManually": "Thêm thủ công",
    "masterProductSuccessfullyCreatedIfYou": "Một số sản phẩm chính được tạo thành công! Nếu bạn cần bật đồng bộ tồn kho sản phẩm chính, vui lòng tiếp tục hoàn tất thao tác thêm sản phẩm chính vào kho và nhập kho",
    "failedToCreateSome": "Một số tạo thất bại!",
    "masterProductView": "Xem Sản phẩm chính",
    "successfullyCreatedAll": "Tất cả tạo thành công!",
    "productSuccessfullyCreatedIfYouNeed": "Sản phẩm chính được tạo thành công! Nếu bạn cần bật đồng bộ tồn kho của sản phẩm chính, vui lòng tiếp tục hoàn tất thao tác thêm sản phẩm chính vào kho và nhập kho",
    "productCanOnlyUseOneCurrency": "Sản phẩm chỉ có thể sử dụng một loại tiền tệ, vui lòng chọn lại!",
    "creatingTheMasterProductCanCompleted": "Sau khi tạo sản phẩm chính, có thể hoàn tất nhập kho và bật đồng bộ tồn kho Master SKU.\nSau khi bật, tồn kho của Master SKU và cửa hàng của kênh có thể tự động cập nhật và đồng bộ",
    "masterSkuHasBeenPlacedIn": "Master SKU kết hợp đã đặt vào kho hàng",
    "warehouseDefault": "Kho mặc định",
    "toWarehouseAdd": "Thêm vào kho",
    "toStoreClone": "Sao chép đến cửa hàng",
    "enableStockSynchronizationOfMasterProduct": "Để bật đồng bộ tồn kho của sản phẩm chính, vui lòng tiếp tục hoàn tất thao tác thêm vào kho, nhập kho. Nếu muốn nhanh chóng đăng sản phẩm đến cửa hàng của kênh để bán, vui lòng sử dụng \"Sao chép đến cửa hàng\"",
    "quicklyPublishTheMasterProductsTo": "Để nhanh chóng đăng sản phẩm chính đến cửa hàng của kênh và bán, vui lòng sử dụng \"Sao chép đến cửa hàng\"",
    "informationWarehouse": "Thông tin kho",
    "inbounded": "Đã nhập kho",
    "inboundNot": "Chưa nhập kho",
    "skuHasBeenPlacedInInbound": "Master SKU đã đặt vào kho",
    "statusStock": "Trạng thái tồn kho",
    "addByStore": "Thêm theo cửa hàng",
    "addBundleProduct": "Thêm combo",
    "oneCurrency": "Sản phẩm chỉ có thể được sử dụng bằng một loại tiền tệ, vui lòng kiểm tra",
    "variantSingle": "Một thuộc tính",
    "productsOfVariants": "{productSum} sản phẩm ({availableNum} trong số {VariantSum} thuộc tính có thể tạo Sản phẩm chính). Thuộc tính bị ràng buộc với MSKU sẽ không thể Master SKU tại Ginee\nNhấp vào nội dung để chỉnh sửa",
    "synchronizationOfTheMasterSkuWill": "Tạo sản phẩm chính trước khi hoàn tất nhập kho, rồi bật đồng bộ tồn kho của sản phẩm chính. Sau khi bật, tồn kho của Master SKU và cửa hàng của kênh có thể tự động cập nhật, đồng bộ.",
    "toTheNChannelsProductList": "Đến ngay danh sách sản phẩm của kênh {n}",
    "channelsProductList": "Vào danh sách sản phẩm của kênh:",
    "goNow": "Đi ngay",
    "goToTheChannelProductList": "Vui lòng đến Danh sách sản phẩm của kênh - và chọn sản phẩm cần trích dẫn của kênh",
    "doNotHaveAnIntegratedStore": "Bạn tạm thời chưa ủy quyền cửa hàng, vui lòng đến ủy quyền",
    "toModifyGo": "Đến chỉnh sửa",
    "skuHasBeenInboundToThe": "Master SKU đã đặt vào tồn kho, vui lòng đến [Quản lý tồn kho] để chỉnh sửa tồn kho có sẵn",
    "productCombined": "loại thuộc tính đã tồn tại, vui lòng nhập lại",
    "combinedProductAdd": "Sản phẩm kết hợp",
    "theContentToEditClick": "Thêm sản phẩm kết hợp",
    "combinationProductsEdit": "Chỉnh sửa sản phẩm kết hợp",
    "preview": "Xem",
    "atTheEndAdd": "Thêm kết thúc",
    "increaseStartingValueAutomatically": "Tự động tạo giá trị bắt đầu",
    "atTheBeginningAdd": "Thêm mở đầu",
    "xxWithYyyReplace": "xx thay thế thành yyy",
    "massEditSKU": "Chỉnh sửa hàng loạt Master SKU",
    "changeStockTo": "Sửa hàng tồn kho thành",
    "massEditStock": "Chỉnh sửa Tồn kho hàng loạt",
    "applyToAllProducts": "Ứng dụng cho tất cả sản phẩm",
    "masterDeleteFailedTip": "Sản phẩm đã được chuyển thành công khỏi kho {tên kho 1}, ⁣ {tên kho 2}.",
    "masterStockpdateFailed": "Cập nhật hàng tồn kho của Mã hàng chính đã được lưu trữ không thành công, vui lòng chuyển đến phần quản lý hàng tồn kho để sửa đổi",
    "stockLimit": "Phạm vi Hàng tồn kho nên từ 0-999.999!",
    "reduceByPercent": "Theo tỷ lệ phần trăm giảm xuống",
    "addByPercent": "Theo tỷ lệ phần trăm tăng lên",
    "reduceByAmount": "Theo số tiền giảm xuống",
    "addByAmount": "Theo số tiền tăng lên",
    "massEdit": "Chỉnh sửa hàng loạt",
    "mskuNoSpaces": "Master SKU không bao gồm khoảng trắng, vui lòng cập nhật",
    "followingXProductsRestore": "Đã khôi phục theo dõi {X} sản phẩm",
    "productsAreSetAsTemporary": "Đã tạm dừng theo dõi {X} sản phẩm",
    "masterSkuDoesNotMeetThe": "Master SKU không đáp ứng các điều kiện xóa, do đó không thể xóa loại thuộc tính và các tùy chọn thuộc tính",
    "barcodeExist": "Mã vạch đã bị {MSKU} chiếm dụng, vui lòng nhập lại",
    "massEditPrice": "Chỉnh sửa Giá hàng loạt",
    "changePriceTo": "Sửa giá thành",
    "replaceWith": "Thay thế với",
    "masterSkuLimit": "Master SKU tối đa 200 ký tự",
    "numberOfVariants": "Số lượng thuộc tính",
    "yourCurrentPackageDoesNotHave": "Gói hiện tại của bạn không có quyền thao tác, vui lòng đăng ký gói mới hoặc liên hệ với chăm sóc khách hàng để nâng cấp gói",
    "yourNumberOfOrdersHasExceeded": "Số lượng đơn hàng của bạn đã vượt quá giới hạn của gói (tối đa {X}), vui lòng đăng ký gói mới hoặc liên hệ với bộ phận chăm sóc khách hàng để nâng cấp gói.",
    "salesTaxAmountTip": "Chi phí thuế cho một sản phẩm",
    "purchaseTimeTip": "Khoảng thời gian từ khi thu mua đến khi nhập kho",
    "sourceUrlTip": "Nguồn gốc của sản phẩm, Link nhà cung cấp hoặc Link nguồn nếu là sản phẩm sưu tập",
    "chatLimitTip2": "Quyền của bạn đã vượt quá giới hạn gói, vui lòng truy cập Ginee OMS để điều chỉnh trước khi tiếp tục sử dụng Ginee Chat",
    "chatNoResourcesLimitTip": "Số lượng cửa hàng hoặc tài khoản nhân viên của bạn đã vượt quá giới hạn gói, vui lòng truy cập Ginee OMS để điều chỉnh trước khi tiếp tục sử dụng Ginee Chat",
    "chatNopermissionLimitTip": "Gói tài khoản của bạn không bao gồm chức năng trò chuyện Ginee Chat, vui lòng liên hệ bộ phận chăm sóc khách hàng để nâng cấp gói và đăng nhập lại.",
    "packageLimit": "Giới hạn của gói",
    "masterProductpublishToTheStore": "Sản phẩm chính/Đăng lên cửa hàng",
    "masterProductmassEdit": "Sản phẩm chính/Chỉnh sửa hàng loạt",
    "stockStatusTip": "Trạng thái tồn kho hiển thị trong cột này: kho hàng, trạng thái đồng bộ tồn kho và thông tin loại SKU chính.\nKho hàng: Kho nơi SKU chính này được đặt.\nTrạng thái đồng bộ tồn kho: SKU chính đã bật đồng tăng giảm hàng tồn kho hay chưa, \"trạng thái đồng bộ tồn kho\" của sản phẩm chính mới thêm vào được mặc định là Tắt.\nĐánh dấu SKU chính kết hợp: Nếu SKU chính này là SKU chính kết hợp, sẽ hiển thị đánh dấu \"kết hợp\" .",
    "synchronizationIsNotEnabledYouNeed": "Tắt: Đồng bộ tồn kho chưa được bật\nChưa đạt tất cả điều kiện cần để bật",
    "synchronizationIsEnabledStock": "Bật: Đồng bộ tồn kho đã bật\nĐã đạt tất cả điều kiện cần để bật",
    "stockSynchronizationStatusOfTheMaster": "Nếu trạng thái đồng bộ tồn kho của SKU chính chưa được bật, thì SKU chính này sẽ không bật chức năng đồng tăng giảm tồn kho",
    "stockSynchronizationStatusOfTheCombined": "Nếu trạng thái đồng bộ tồn kho của MSKU kết hợp chưa được bật, MSKU kết hợp sẽ không tự động tính toán tồn kho có sẵn dựa theo SKU chính liên kết và cũng sẽ không bật chức năng đồng bộ tồn kho.",
    "open": "Bật",
    "settingsManagementForAuthorizedThirdpartyAccounting": "Được ủy quyền quản lý cấu hình phần mềm tài chính bên thứ ba",
    "accountingSettings": "Cấu hình kế toán",
    "addedBy": "Thêm bởi",
    "timeAdded": "Thời gian thêm",
    "updater": "Cập nhật bởi",
    "checkLog": "Kiểm tra nhật ký thao tác",
    "accurateOrder": "Đơn hàng Accurate",
    "accurateProduct": "Sản phẩm Accurate",
    "failedToGetChannelsLabelUrl": "Kênh nhận đơn thất bại, xin vui lòng đẩy lại",
    "bindWarehouse": "Liên kết kho hàng",
    "Prohibited": "Cấm sử dụng",
    "interval": "Khoảng không",
    "productSales": "Doanh số sản phẩm",
    "updatetime": "Thời gian cập nhật",
    "masterProductCreationTime": "Thời gian tạo sản phẩm Chính",
    "channelProductCreationTime": "Thời gian tạo sản phẩm kênh",
    "salesOfDifferentActivityLevelsOverlap": "Doanh số của các cấp độ hoạt động khác nhau trùng lặp, vui lòng nhập lại",
    "salesRangeOfXCannotBe": "Phạm vi bán hàng của {x} không thể cao hơn {y}",
    "channelProductDimensionActivitySetting": "Hoạt động thiết lập kích thước sản phẩm của kênh, hiện chỉ hỗ trợ Shopee",
    "synchronizeallthexxinthewarehousewheremskusarelocatedtochannelskusaccordingtothexxxratiowhenthedefaultruleisenabledmskusthatalreadyexistinotherrulesarenotincluded": "Đồng bộ hóa tổng XX trong kho chứa tất cả MSKU với tất cả các SKU kênh theo tỷ lệ XXX. Khi quy tắc mặc định được bật,  sẽ không bao gồm các MSKU đã tồn tại trong các quy tắc khác",
    "availablestockforotherstoresispushedto": "Tồn kho có sẵn của các cửa hàng khác bị đẩy về 0",
    "allExceptxx": "Tất cả ngoại trừ XX",
    "whentheavailablestockislessthanorequaltoxx": "Khi tồn kho có sẵn nhỏ hơn hoặc bằng XX",
    "whenmsku": "Khi MSKU",
    "stockThreshold": "Ngưỡng tồn kho",
    "availablestocktransferstock": "Tồn kho có sẵn + Tồn kho đang chuyển",
    "availablestock": "Tồn kho có sẵn",
    "pleaseSelectawarehouse": "Vui lòng chọn kho hàng",
    "addPolicy": "Thêm sách lược",
    "pushratio": "Tỷ lệ đẩy",
    "warehousename": "Tên kho hàng",
    "pushbyfixedvalue": "Đẩy theo giá trị cố định",
    "pushbybatio": "Đẩy theo tỷ lệ",
    "setSyncPolicy": "Đặt sách lược đồng bộ",
    "setSynchronizationconditions": "Đặt điều kiện đồng bộ",
    "selectMsku": "Chọn MSKU",
    "addMsku": "Thêm MSKU",
    "ruleNamecannotbeempty": "Tên quy tắc không được để trống",
    "pleaseenterarulename": "Vui lòng nhập tên quy tắc",
    "executeRules": "Thực thi quy tắc",
    "pushLog": "Nhật ký đẩy ngày",
    "default": "Mặc định",
    "defaultRules": "Quy tắc mặc định",
    "storeName1": "Tên cửa hàng",
    "stockType": "Loại tồn kho",
    "pushType": "Kiểu đẩy",
    "mainStoreStockLocked": "Khóa tồn kho cửa hàng chính",
    "log": "Nhật ký",
    "addrule": "Thêm quy tắc",
    "stocklistpromptwhenadjustingstockstockadjustment": "[Danh sách tồn kho] Lời nhắc khi nhảy đến điều chỉnh tồn kho: tồn kho điều chỉnh [(+) / (-) X]",
    "promptwhenaddingstockwithmanualinboundmanualinbound": "Lời nhắc khi có đơn hàng mới nhập kho thủ công: nhập kho thủ công 【121133】",
    "whenmanuallypushinginventorymanuallyexecutestockpushrules": "Lời nhắc khi đẩy tồn kho tự động: thực hiện thủ công quy tắc đẩy tồn kho",
    "promptwhentheordercomesinneworder": "Lời nhắc khi có đơn hàng mới: đơn hàng mới【576512290177977223】",
    "promptwhenorderiscancelledordercanceled": "Lời nhắc khi đơn hàng bị hủy: hủy đơn hàng [576512290177977223]",
    "promptwhenbindingforthefirsttimeenablestockmonitoringandsyncsettingsthenpushdatatostoresstock": "Lời nhắc khi ràng buộc lần đầu tiên: bật cài đặt giám sát và đồng bộ tồn kho, khởi tạo tồn kho cửa hàng",
    "xxofchannelproductsfailed": "XX sản phẩm kênh đã thất bại",
    "creationtime": "Giờ tạo",
    "pushquantity": "Số lần đẩy",
    "reasonforchange": "Nguyên nhân thay đổi",
    "seemore": "Xem thêm",
    "imageMasterProduct": "Hình ảnh & sản phẩm chính",
    "partialSuccess": "Thành công một phần",
    "storeupdateresults": "Kết quả cập nhật cửa hàng",
    "pleaseentersearchcontent": "Vui lòng nhập nội dung tìm kiếm",
    "storepushrecord": "Nhật ký đẩy của cửa hàng",
    "stockValue": "Giá trị tồn kho",
    "isBestSellingItem": "Bùng nổ",
    "isTrendingItem": "Mạnh",
    "isNewItem": "Mới",
    "isLowSellingItem": "Bán chậm",
    "isNormalItem": "Bình thường",
    "mskuXAlreadyExistsInRule": "MSKU: {X} đã tồn tại trong quy tắc {Y}",
    "ruleNameAlreadyExists": "Tên quy tắc đã tồn tại",
    "thereAreDuplicateRuleNameOr": "Tồn tại trùng lặp tên quy tắc hoặc sản phẩm Chính",
    "noteWhenTheInventoryThresholdIs": "Lưu ý: Khi bật ngưỡng tồn kho, khi tồn kho trong \"Quy tắc đồng bộ\" nhỏ hơn hoặc bằng với \"Ngưỡng\", thì tồn kho có sẵn của các cửa hàng khác ngoại trừ cửa hàng Chính sẽ bị đẩy về 0",
    "totalCapitalUsedFromAllWarehouses": "Tổng vốn chiếm dụng của tất cả kho",
    "storeIntegrationIsInvalidPleaseReintegrate": "Ủy quyền cửa hàng không hợp lệ, vui lòng ủy quyền lại",
    "mskuAndStoreAreNotBound": "Liên kết MSKU và cửa hàng không hợp lệ",
    "warehouseAndStoreAreNotBound": "Chưa liên kết kho hàng và cửa hàng",
    "warehouseAndMskuAreNotBound": "Chưa liên kết kho hàng và MSKU",
    "productChannelAndMskuAreNot": "Chưa liên kết sản phẩm kênh và MSKU",
    "deleteRule": "Xác nhận xóa quy tắc {X}?",
    "ruleNameXChangedToY": "Tên quy tắc: từ {x} đổi thành {Y}",
    "pushTypeChangeFromFixedValue": "Loại đẩy: thay đổi từ đẩy theo giá trị cố định sang đẩy theo tỷ lệ, đẩy ở mức {X}% của tồn kho có sẵn trong kho {X}+kho{y}",
    "pushTypeChangeFromProportionalPush": "Loại đẩy: thay đổi từ đẩy theo tỷ lệ thành đẩy giá trị cố định, giá trị cố định là XXX",
    "warehouseNameWarehouseXHasBeen": "Tên kho hàng: Kho {X} thành kho {Y}",
    "addRuleNewRule": "Thêm quy tắc: Quy tắc mới",
    "statusChangeStatusHasBeenChanged": "Thay đổi trạng thái: Trạng thái đổi từ TẮT sang MỞ",
    "statusChangeStatus": "Thay đổi trạng thái: Trạng thái đổi từ MỞ sang TẮT",
    "deleteRuleRuleHasBeenDeleted": "Xóa quy tắc: Đã xóa quy tắc",
    "ruleChangeTheTotalOfStock": "Thay đổi quy tắc: Tổng tồn kho có sẵn trong kho {X} được thay đổi từ {X}% thành {Y}%",
    "policyChangeTheAvailableStock": "Thay đổi sách lược: Tồn kho có sẵn + tồn kho vận chuyển của kho {X} được thay đổi từ tỷ lệ đẩy {X}% thành {Y}%",
    "editAddedXMskuThresholdIs": "Chỉnh sửa: thêm {X}MSKU, ngưỡng là {X}, chọn cửa hàng chính là: {Y}",
    "editXMskuThresholdChangedFrom": "Chỉnh sửa: ngưỡng {X}MSKU thay đổi từ {Y} sang {Z}",
    "editXMskuMainStoreHas": "Chính sửa: {X}MSKU cửa hàng chính thay đổi từ {X} sang {Y}",
    "editXMskuHasBeenDeleted": "Chỉnh sửa: {X} MSKU đã bị xóa",
    "afterDeletionThePolicyNeedsTo": "Sau khi xóa, sách lược cần được định cấu hình lại, vui lòng thực hiện một cách thận trọng",
    "thirdpartyWarehouseReceptionTime": "Thời gian tiếp nhận kho bên thứ ba",
    "whetherToReplaceProduct": "Có thay thế sản phẩm không",
    "whetherItIsAGift": "Có tặng quà không",
    "addGiftBForOrder": "Đơn hàng {A} được thêm quà {B} vào, số lượng là {C}, kho đã chọn là {D}",
    "orderAWithProductBIs": "Đơn hàng {A} sản phẩm {B} được thay thế bằng {C}, kho hàng đã chọn là {D}",
    "changeSelectedWarehouseCToD": "Đơn hàng {A} sản phẩm {B} các kho hàng đã chọn thay đổi từ {C} thành {D}",
    "gift": "Quà tặng",
    "specifiedWarehouse": "Kho hàng chỉ định",
    "specifiedQuantity": "Số lượng chỉ định",
    "massAddForOrdersSpecifiedMsku": "Đối với việc thêm đơn hàng loạt: chỉ định MSKU và số lượng!",
    "replace1": "Thay thế",
    "and1": "Và",
    "replaceInBatchForTheSpecified": "Đối với các đơn hàng có sản phẩm chỉ định, hãy thay thế hàng loạt!",
    "changeWarehouseTo": "Thay đổi kho hàng thành",
    "specifiedProduct": "Sản phẩm chỉ định",
    "doWarehouseModificationForSpecifiedProduct": "Đối với các đơn hàng có sản phẩm chỉ định, hãy sửa đổi kho hàng! (Sản phẩm sửa đổi phải liên kết với kho hàng tương ứng)",
    "doWarehouseModificationForAllProduct": "Đối với các đơn hàng với mọi sản phẩm, hãy sửa đổi kho hàng! (Sản phẩm sửa đổi cần liên kết với kho hàng tương ứng)",
    "byProduct": "Theo sản phẩm",
    "byOrder": "Theo đơn hàng",
    "processingMethod": "Phương thức xử lý",
    "addGift": "Thêm quà",
    "replaceProduct": "Thay thế sản phẩm",
    "changeWarehouse": "Thay đổi kho hàng",
    "modifyProductInformation": "Sửa sản phẩm",
    "weightkg": "Trọng lượng(kg)",
    "youMustAddAllTheVariation": "Bạn phải thêm tất cả hình ảnh thuộc tính hoặc để trống tất cả chúng.",
    "masterVariationName": "主商品规格名称",
    "operationSuccessPrompt": "Thông báo thao tác thành công",
    "checkProduct": "Kiểm tra sản phẩm",
    "productHasBeenSubmittedAndIs": "Sản phẩm đã được gửi và đang chờ {channel} xử lý, bạn có thể kiểm tra sản phẩm trên tab Đang xử lý, Đang bán hoặc Đăng thất bại",
    "minimumStockLimit": "Phạm vi tồn kho tối thiểu là 0-999.999",
    "noNeedToProvideAnyTransport": "Không cần cung cấp bất kỳ dịch vụ vận chuyển nào, như Chứng chỉ vận chuyển, Dịch vụ tận nơi, Dịch vụ tại điểm nhận hàng, v.v.",
    "thisTypeCanBeSelectedTo": "Chọn loại này có thể vận chuyển với trọng lượng trên 50kg, hoặc tiến hành cài đặt các xử lý đặc biệt khác cho sản phẩm nếu cần.",
    "notShipped": "Không vận chuyển",
    "sellerShipping": "Người bán vận chuyển",
    "shipYourOrderToTheBLIBLI": "Vận chuyển đơn hàng đến đơn vị chuyển phát nhanh chỉ định mà Blibli hợp tác. Đối với Sameday / Instant, đơn hàng sẽ được lấy tại điểm lấy hàng.",
    "shippingUsingBlibliLogisticPartner": "Thông qua hợp tác vận chuyển với Blibli",
    "minimumStock": "Tồn kho tối thiểu",
    "enterSellingPrice": "Vui lòng nhập giá bán",
    "sellingPriceLimit": "Phạm vi giá bán nên là 10.000 - 1000.000.000",
    "originalPrice": "Giá gốc",
    "enterOriginalPrice": "Vui lòng nhập giá gốc",
    "changeMinimumStockTo": "Sửa Tồn kho tối thiểu thành",
    "restrictionsTheProductCannotBeEdited": "API giới hạn, không thể chỉnh sửa sản phẩm này",
    "unboundMSKU": "Chưa liên kết  Master SKU",
    "bindMSKU": "Liên kết Master SKU",
    "Country": "Quốc gia/Khu vực",
    "ContinuetoPublish": "Tiếp tục đăng tải",
    "CreateTimeNewest": "Thời gian tạo giảm dần",
    "CreateTimeOldest": "Thời gian tạo tăng dần",
    "notification": "Nhắc nhở",
    "NoBrand": "OEM",
    "PromotionActivity": "Hoạt động",
    "PromotionCampaign": "Chiến dịch",
    "PromotionDuration": "Thời gian",
    "PromotionPrice": "Giá khuyến mãi",
    "PromotionOriginalPrice": "Giá gốc",
    "cannot0": "Sản phẩm không được hiển thị với người bán. Lý do: Tồn kho bằng 0",
    "EditStock": "Sửa đổi Tồn kho",
    "ResultsFail": "Thao tác sản phẩm có vấn dề do vấn dề mạng, vui lòng đợi giây lát",
    "SomeOperationsFailed": "Thao tác sản phẩm thất bại",
    "Sort": "Thứ tự",
    "MostStock": "Tồn kho tăng dần",
    "LeastStock": "Tồn kho nhỏ dần",
    "Oldest": "Cập nhật mới sang cũ",
    "Newest": "Cập nhật cũ sang mới",
    "Within7Days": "7 ngày gần nhất",
    "Yesterday": "Hôm qua",
    "Today": "Hôm nay",
    "CountDraftFailed": "Đăng tải thất bại",
    "CountDraftSuccess": "Đăng tải thành công",
    "CountRemoveFailed": "Xoá thất bại",
    "CountRemoveSuccess": "Xoá thành công",
    "CountUnAvailableFailed": "Tắt bán thất bại",
    "CountUnAvailableSuccess": "Tắt bán thành công",
    "CountAvailableFailed": "Đăng tải thất bại",
    "CountAvailableSuccess": "Đăng tải thành công",
    "PartDraftFailed": "Vài sản phẩm đăng tải thành công",
    "DraftFailed": "Đăng tải thất bại",
    "DraftSuccess": "Đăng tải thành công",
    "PartRemoveFailed": "Vài sản phẩm xoá thất bại",
    "RemoveFailed": "Xoá thất bại",
    "RemoveSuccess": "Xoá thành công",
    "PartUnAvailableFailed": "Vài sản phẩm tắt bán thất bại",
    "UnAvailableFailed": "Tắt bán thất bại",
    "UnAvailableSuccess": "Tắt bán thành công",
    "PartAvailableFailed": "Vài sản phẩm bật bán thất bại",
    "AvailableFailed": "Tạo và bật bán thất bại",
    "AvailableSuccess": "Bật bán thành công",
    "InProcessDraftPublishModalTitle": "Đang đăng tải sản phẩm hàng loạt",
    "InProcessRemoveModalTitle": "Đang xoá sản phẩm",
    "InProcessDisableModalTitle": "Sản phẩm kênh bán đang tắt bán",
    "InProcessPublishModalTitle": "Sản phẩm kênh bán đang bật bán",
    "StockRulesInfo": "Tồn kho chỉ được nhập từ {minNum}~ 999,999",
    "ResultsClose": "Đóng",
    "ResultsTitle": "Kết quả",
    "ApplyToAll": "Áp dụng tất cả",
    "MassEditStock": "Chỉnh sửa hàng loạt",
    "SetUnAvailable": "Tắt bán",
    "SetAvailable": "Bật bán",
    "DeletedDesc": "Sản phẩm đã bị xoá khỏi Ginee cũng sẽ bị xoá trên các cửa hàng khác.",
    "DeletedTitle": "Bạn có chắc muốn xoá sản phẩm?",
    "PublicDesc": "Sản phẩm đã bật bán trên Ginee cũng sẽ được bật bán trên các kênh khác.",
    "PublicTitle": "Bạn có chắc muốn bật bán sản phẩm?",
    "DisabledDesc": "Sản phẩm đã tắt bán trên Ginee cũng sẽ được tắt bán trên các kênh khác.",
    "DisabledTitle": "Bạn có chắc muốn tắt bán sản phẩm?",
    "productTime": "Thời gian",
    "ProductSelected": "Đã chọn",
    "ProductParentSku": "PSKU",
    "ProductNameAndStore": "Tên sản phẩm và cửa hàng",
    "adjustSyncFailed": "Đồng bộ hóa tồn kho các sản phẩm sau không thành công, vui lòng thử lại",
    "UpdateStock": "Cập nhật",
    "NewStock": "Tồn kho mới",
    "ModifyStock": "Vui lòng sửa đổi tồn kho trong Quản lý Tồn kho",
    "CurrentStock": "Tồn kho hiện có",
    "adjustStock": "Điều chỉnh Tồn kho",
    "categories": "Danh mục",
    "removeList": "Xóa bỏ",
    "productStatus": "Trạng thái",
    "savedAsdraft": "Lưu bản nháp thành công",
    "pleaseSelect": "Vui lòng chọn",
    "ProductDescription": "Mô tả sản phẩm",
    "masterCategory": "Danh mục chính",
    "ProductImage": "Hình ảnh sản phẩm",
    "defaultPriceError": "Phạm vi giá bán mặc định phải là 0-1000.000.000",
    "editDefaultPrice": "Chỉnh sửa giá bán mặc định",
    "editDescription": "Chỉnh sửa mô tả sản phẩm",
    "addAfterName": "Thêm ở cuối tên",
    "addBeforeName": "Thêm ở đầu tên",
    "weightShouldBeBetweenKg": "Phạm vi trọng lượng là 0.001-1000",
    "workingHours": "Giờ làm việc",
    "shippingSettings": "Cài đặt vận chuyển",
    "massEditProductStatus": "Chỉnh sửa trạng thái nhiều sản phẩm",
    "goToEdit": "Đi đến chỉnh sửa",
    "theInventoryOfTheMasterProduct": "Hàng tồn kho của sản phẩm chính trong [Quản lý Hàng tồn kho], vui lòng đi đến để chỉnh sửa hàng loạt hàng tồn kho của Sản phẩm đã chọn",
    "changeMinPurchaseQuantityTo": "Sửa số lượng mua tối thiểu thành",
    "massEditMinPurchaseQuantity": "Chỉnh sửa hàng loạt số lượng mua tối thiểu",
    "massEditDimensionsCm": "Chỉnh sửa kích thước hàng loạt",
    "massEditProductName": "Chỉnh sửa tên nhiều sản phẩm",
    "editProductImage": "Chỉnh sửa hình ảnh nhiều sản phẩm",
    "customDisplayInformation": "Hiển thị thông tin tùy chỉnh",
    "logisticsChoose": "Chọn vận chuyển",
    "selectionOfStoreswarehousesMass": "Chọn nhiều cửa hàng / kho hàng",
    "toMatchCategoryNeed": "Cần phối hợp danh mục",
    "categoriesMatched": "Đã phối hợp danh mục",
    "productsSelected": "Sản phẩm đã chọn",
    "categoryAttributesShopee": "Thuộc tính danh mục Shopee",
    "categoryAttributesEdit": "thuộc tính danh mục",
    "theValueJustEnteredWantIt": "Giá trị vừa thêm vào, có áp dụng cho tất cả sản phẩm cùng loại",
    "matchingCategoryAndVariantAttributesProduct": "Sau khi phối hợp danh mục với thuộc tính, có thể tự động phối hợp thông tin sản phẩm, như Thuộc tính giá cả, hàng tồn kho, vv",
    "productInfoSource": "Phối hợp danh mục",
    "noLogisticOptions": "Hiện không có lựa chọn vận chuyển",
    "sellingPrice": "Giá bán",
    "productUpdateFailed": "Cập nhật sản phẩm thất bại",
    "publishFailed": "Đăng sản phẩm thất bại",
    "publishSucceeded": "Đăng sản phẩm thành công",
    "massEditMinimumStock": "Chỉnh sửa Tồn kho tối thiểu hàng loạt",
    "skuLimit": "SKU phải ít hơn 100 kí tự",
    "sku": "SKU",
    "packageSizeCM": "Kích thước gói hàng(cm)",
    "main": "Hình ảnh",
    "maxval": "Tối đa {val} ảnh",
    "priceBetweenMy": "Pham vi nhập từ 0.1-1000.000.000",
    "priceBetweenTh": "Pham vi nhập từ 1-500.000",
    "priceBetweenVn": "Pham vi nhập từ 1000-120.000.000",
    "priceBetweenPh": "Pham vi nhập từ 5-1.000.000",
    "priceBetweenId": "Pham vi nhập từ 99-150.000.000",
    "weightg": "Trọng lượng(g)",
    "selectABrandPlease": "Vui lòng chọn một thương hiệu",
    "uploadAVariantPicturePlease": "Vui lòng tải lên một hình ảnh biến thể",
    "uploadProductImagePlease": "Vui lòng tải lên hình ảnh sản phẩm",
    "enterADescriptionPlease": "Vui lòng nhập mô tả",
    "enterTheProductNamePlease": "Vui lòng nhập tên sản phẩm",
    "selectTheStatusOptionPlease": "Vui lòng chọn tùy chọn trạng thái",
    "selectPreorderOptionPlease": "Vui lòng chọn tùy chọn đặt hàng trước",
    "enterLogisticsOptionsPlease": "Vui lòng nhập các tùy chọn hậu cần",
    "enterThePackageSizePlease": "Vui lòng nhập kích thước gói hàng",
    "enterTheWeightPlease": "Vui lòng nhập trọng lượng",
    "enterstock": "Vui lòng nhập kho",
    "enterPrice": "Vui lòng nhập giá",
    "enterSKU": "Vui lòng nhập SKU",
    "theProductHasVariationsIfYour": "Sản phẩm có nhiều loại Thuộc tính, nếu sản phẩm của bạn có màu sắc, kích thước khác nhau, vui lòng chọn mục này",
    "enterAOptionForExampleRed": "Vui lòng chọn mục, ví dụ: màu đỏ vv",
    "addVariationType": "Thêm loại Thuộc tính",
    "variationType": "Loại Thuộc tính",
    "productDescriptionLimit": "Mô tả sản phẩm bắt buộc từ 6 đến 25.000 kí tự",
    "productDescriptionLimitTh": "Mô tả sản phẩm bắt buộc từ 25 đến 50.000 kí tự",
    "productDescriptionLimitVn": "Mô tả sản phẩm bắt buộc từ 100 đến 30.000 kí tự",
    "productDescriptionLimitPh": "Mô tả sản phẩm bắt buộc từ 2 đến 30.000 kí tự",
    "productDescriptionLimitIdMy": "Mô tả sản phẩm bắt buộc từ 20 đến 30.000 kí tự",
    "selectBrand": "Vui lòng chọn thương hiệu",
    "ShopeeCategory": "Danh mục Shopee",
    "addAttributeValue": "Thêm giá trị thuộc tính",
    "saveUpdateAndNextStep": "Lưu và tiếp theo",
    "productNameLimitVn": "Tên sản phẩm phải từ 10 đến 120 kí tự",
    "productNameLimitPh": "Tên sản phẩm phải từ 20 đến 100 kí tự",
    "productNameLimitOthers": "Tên sản phẩm phải từ 20 đến 120 kí tự",
    "productNameLimitId": "Tên sản phẩm phải từ 5 đến 255 kí tự",
    "stocktLimitOhters": "Phạm vi Hàng tồn kho nên từ 0-999.999.999!",
    "stocktLimit": "Phạm vi Hàng tồn kho nên từ 0-999.999!",
    "setUnavailable": "Xuống kệ",
    "setAvailable": "Lên kệ",
    "saveAsDraft": "Lưu thành bản nháp",
    "percentageLimit": "Tỷ lệ phần trăm phải từ 0-100.000",
    "attributesNo": "Không có thuộc tính",
    "categoryNo": "Không có danh mục",
    "categoryAndAttributeApply": "Áp dụng cùng một danh mục và các tùy chọn thuộc tính cho các sản phẩm khác của cùng một danh mục nguồn ({N})?",
    "detailsView": "Xem chi tiết",
    "pleaseSetUpLogisticsInThe": "Vui lòng cài đặt vận chuyển trong trung tâm người bán Shopee",
    "optionMaximum": "Tối đa 20 kí tự",
    "typeMaximum": "Tối đa 14 kí tự",
    "vietnam": "Việt Nam",
    "thailand": "Thái Lan",
    "philippines": "Philippines",
    "indonesia": "Indonesia",
    "imageMustBeJOGPNGFormat": "Hình ảnh bắt buộc là định dạng JPG, PNG, tối đa 4MB, Độ phân giải: 300 * 300px ～ 1024 * 1024px",
    "imageTip": "Giới hạn của giao diện, kích cỡ tối đa 4MB, độ phân giải phải lớn hơn 300*300 px",
    "preOrderLimit": "Theo yêu cầu của danh mục, thời gian phải từ {min} đến {max} ngày làm việc",
    "preOrderTip": "Star, Star+, Mall hỗ trợ tối đa 90 ngày làm việc",
    "malaysia": "Malaysia",
    "dangerousGoods": "Hàng nguy hiểm",
    "dangerousGoodsTip": "Vui lòng điền chính xác \"Hàng nguy hiểm\". Điền \"Hàng nguy hiểm\" không chính xác có thể dẫn đến thêm chi phí vận chuyển hoặc giao hàng thất bại.",
    "dangerousGoodsOption": "Bao gồm pin/nam châm/chất lỏng/chất dễ gây cháy nổ",
    "attributeTip": "Giới hạn của API, tối đa 5 lựa chọn",
    "massEditTip1": "Vui lòng chọn danh mục sản phẩm và thuộc tính sản phẩm để tránh trường hợp sản phẩm không được công bố",
    "theMaximumRangeAccordingToApi": "API yêu cầu giới hạn phạm vi tối đa",
    "requiresToThisInformationToBe": "API yêu cầu phần này bắt buộc điền",
    "chooseTheStoreFirstPlease": "Vui lòng chọn cửa hàng trước",
    "variantImageTip": "Kích thước: 4MB, Độ phân giải: Min.300 * 300px (API yêu cầu phần này bắt buộc điền)",
    "variationTypeTip2": "loại biến thể đã tồn tại, vui lòng nhập lại",
    "variationoptionTip1": "Tùy chọn biến thể đã tồn tại, vui lòng nhập lại",
    "applyAttribute": "Áp dụng cùng một tùy chọn thuộc tính cho các sản phẩm khác trong cùng một danh mục nguồn {N}?",
    "noBrandBatch": "Bạn có chắc chắn thay đổi tùy chọn nhãn hiệu của danh sách sản phẩm này thành \"Không có nhãn hiệu\" không?",
    "noBrandBatchTip": "Do các hạn chế của API, chỉ hỗ trợ lựa chọn hàng loạt các nhãn hiệu không có nhãn hiệu.",
    "massEditBrand": "Điều chỉnh nhiều thương hiệu",
    "previous": "Trước",
    "emptyMass": "Xóa hàng loạt",
    "singapore": "Singapore",
    "canUploadMultiplePicturesAtOnce": "Bạn có thể tải lên nhiều ảnh cùng lúc, kéo và thả để thay đổi thứ tự",
    "contentToEditClick": "Nhấp vào nội dung của bảng để chỉnh sửa",
    "Policydetail": "Khi một sản phẩm được đăng tải cùng lúc lên nhiều cửa hàng trên sàn TMĐT",
    "PolicyReminder": "Nhắc nhở chính sách",
    "Clonesuccess": "Sao chép thành công",
    "copyFailedResult": "Sản phẩm sao chép thất bại",
    "copyCompete": "Hoàn tất Đồng bộ",
    "amountMustBeGreaterThanDiscount": "Số tiền phải lớn hơn số tiền giảm giá",
    "wholeStoreProduct": "Sản phẩm toàn cửa hàng",
    "valueMustBeGreaterThanThe": "Giá trị phải lớn hơn gradient trước đó",
    "theSumOfTheAvailableStock": "Tổng số MSKU trong tồn kho có sẵn của kho hàng, dựa theo",
    "ratioSyncToAllChannelSku": "Đồng bộ hóa với tất cả các SKU kênh",
    "whenthedefaultruleisonmskusthatalreadyexistinotherrulesarenotincluded": "Khi bật quy tắc mặc định, nó không bao gồm MSKU đã tồn tại trong các quy tắc khác",
    "statusDeleted": "Xóa",
    "statusInactive": "Không hoạt động",
    "statusActive": "Hoạt động",
    "optionsDoNotSupportEditAnd": "Do hạn chế của API, tùy chọn không hỗ trợ chỉnh sửa và xóa",
    "dueToApiRestrictionsLabel": "Do hạn chế của API, loại thuộc tính { label } là bắt buộc điền",
    "dueToTheChannelDelayIn": "Do sàn TMĐT cập nhật chậm trễ nên dữ liệu sau khi chỉnh sửa sẽ mất vài giây để cập nhật, vui lòng kiên nhẫn chờ đợi. Nếu chưa được cập nhật, bạn có thể nhấp vào nút đồng bộ theo cách thủ công hoặc vào trung tâm người bán để kiểm tra xem nó có ở trạng thái Đang xem xét hay không. Dữ liệu ở trạng thái Đang xem xét tạm thời không được đồng bộ.",
    "deletedProductCannotBeRecoveredAre": "Sau khi xóa sản phẩm sẽ không thể phục hồi, xác nhận xóa không?",
    "canNotInputChineseLanguage": "Không được nhập tiếng Trung",
    "areYouSureYouWantToZalora": "Bạn có chắc chắn muốn xóa thông tin thuộc tính này không? Việc xóa sẽ làm thay đổi cấu trúc SKU và thông tin giá tồn kho, bạn cần nhập lại các thông tin liên quan của SKU.",
    "failedToPublishGineeProductThat": "Đăng thất bại: sản phẩm mà Ginee đăng thất bại",
    "publishingGineeProductThatAreBeing": "Đang đăng: sản phẩm Ginee đang đăng lên hoặc sản phẩm đang xử lý trên Trung tâm người bán",
    "draftAProductDraftCreatedBy": "Bản nháp: bản nháp sản phẩm tạo bởi Ginee",
    "suspendedThisIsAProductWith": "Bị khóa: trong trung tâm người bán là trạng thái Bị khóa",
    "soldOutThisIsAProduct": "Hết hàng: trong trung tâm người bán là sản phẩm có tồn kho = 0 với trạng thái Đang hoạt động/Không hoạt động",
    "deactivatedThisIsAProductWith": "Không hoạt động: trong trung tâm người bán là trạng thái Không hoạt động",
    "liveThisIsAProductWith": "Hoạt động: trong trung tâm người bán là trạng thái Đang hoạt động",
    "currencyCannotBeEmpty": "Đơn vị tiền tệ không được để trống",
    "failedToUploadQualification": "Tải lên chứng nhận {0} thất bại",
    "failedToUploadImage": "Tải lên hình ảnh {0} thất bại",
    "thereCannotBeTheSameOption": "Trong cùng một tập hợp thuộc tính, không thể có tùy chọn trùng lặp",
    "numberOfVariationsInEachGroup": "Số lượng thuộc tính trong mỗi nhóm phải là {0} - {1}",
    "numberOfGroupsVariationMustBe": "Số lượng nhóm của loại thuộc tính phải là {0} - {1}",
    "qualificationCertificateOfTheCurrentProduct": "Chứng chỉ năng lực của danh mục sản phẩm này là bắt buộc, không được để trống",
    "forOneTypeOfQualificationCertificate": "Đối với một loại chứng nhận chất lượng, tổng số tệp và hình ảnh không được vượt quá {0}",
    "apiLimitationMaxSizeMb": "Giới hạn API, kích thước tối đa là 5MB và độ phân giải phải lớn hơn 600 * 600px",
    "totalSkuMustBeBetween": "Tổng số SKU phải giữa 1~100",
    "pleaseSelectTheTypeOfVariation": "Vui lòng chọn loại thuộc tính",
    "pleaseInputWeight": "Nhập để đặt lại",
    "pleaseInputLengthWidthAndHeight": "Vui lòng điền đầy đủ thông tin kích thước",
    "reviewing": "Đang đánh giá",
    "processing": "Đang xử lý",
    "reviewFailed": "Đánh giá thất bại",
    "thisCategoryDoesNotSupportCod": "Danh mục này không hỗ trợ COD",
    "skuMustBeCharacters": "SKU phải từ 1-50 ký tự",
    "warrantyPolicyMustBeCharacters": "Chính sách bảo hành phải từ 0-99 ký tự",
    "priceRangeShouldBe": "Khoảng giá dao động 100-100000000",
    "stocktLimitOhtersZalora": "Tồn kho dao động từ 0-99999",
    "weightShouldBeBetweenKgZalora": "Trọng lượng dao động 0.01-20",
    "heightShouldBeBetweenZalora": "Chiều cao dao động 1-35",
    "widthShouldBeBetweenZalora": "Chiều rộng dao động 1-40",
    "lengthShouldBeBetweenZalora": "Chiều dài dao động 1-60",
    "pleaseUploadASizePicture": "Vui lòng tải lên ảnh kích thước",
    "onlySupportsImages": "Chỉ hỗ trợ hình ảnh 1: 1",
    "pleaseEnterAtLeastOneVariation": "Vui lòng nhập ít nhất một tùy chọn thuộc tính",
    "variationOptionMustBeCharacters": "Tùy chọn thuộc tính phải từ 1-20 ký tự",
    "productDescriptionLimitIdMyZalora": "Mô tả sản phẩm bắt buộc từ 1 đến 10.000 kí tự",
    "fileSizeCannotExceedM": "Kích thước tệp không quá 10M",
    "onlySupportToUploadFilesIn": "Chỉ hỗ trợ tải lên các tệp ở định dạng pdf",
    "onlySupportToUploadImagesWith": "Chỉ hỗ trợ tải ảnh với các định dạng jpg, png, jpeg",
    "imageMustBePxpx": "Hình ảnh phải từ 600 * 600px-20000 * 20000px",
    "pictureSizeCannotExceedM": "Kích cỡ ảnh không quá 5M",
    "pleaseUploadAtLeastOneImage": "Vui lòng tải lên ít nhất một hình ảnh hoặc bản pdf",
    "supportCOD": "Hỗ trợ COD",
    "sizeChart": "Ảnh kích cỡ",
    "pleaseSelectTheVariantTypeAnd": "Vui lòng chọn loại và lựa chọn thuộc tính",
    "warrantyPolicy": "Chính sách bảo hành",
    "warrantyPeriod": "Thời hạn bảo hành",
    "qualificationCertificate": "Chứng nhận chất lượng",
    "productNameLimitIdZalora": "Tên sản phẩm bắt buộc từ 1 đến 188 kí tự",
    "zaloraCategory": "Danh mục Zalora",
    "numberOfComments": "Số bình luận",
    "keywordType": "Lọc",
    "sortType": "Thứ tự",
    "number": "Số seri",
    "newRuleMasterProduct": "Thêm mới quy tắc {0}; Sản phẩm Chính {1}",
    "deleteRuleMasterProduct": "Xóa quy tắc {0}; sản phẩm Chính {1}",
    "storePolicyAddPolicyNew": "Sách lược cửa hàng {0}; thêm sách lược: thêm mới sách lược",
    "storePolicyDeletePolicyPolicy": "Sách lược cửa hàng {0}; xóa sách lược: đã xóa sách lược",
    "ruleNameChangedFromTo": "Tên quy tắc: từ {0} thành {1}",
    "masterProductNameChangedFrom": "Tên cửa hàng Chính: từ {0} thành {1}",
    "pushTypeNameChangedFrom": "Tên loại đẩy: từ {0} thành {1}",
    "storePolicyWarehouseNameChanged": "Sách lược cửa hàng {0}; tên kho hàng: từ kho {1} thành kho {2}",
    "storePolicyInventoryTypeName": "Sách lược cửa hàng {0}; tên loại tồn kho: từ {1} thành {2}",
    "storePolicyStoreNameChanged": "Sách lược cửa hàng {0}; tên cửa hàng: từ {1} thành {2}",
    "storeStrategyPushRatioChanged": "Sách lược cửa hàng {0}; đẩy tỷ lệ: từ {1} thành {2}",
    "storePolicyPushFixedValue": "Sách lược cửa hàng {0}; đẩy giá trị cố định: từ {1} thành {2}",
    "addmskuthresholdIsselectmainstoreIs": "Thêm {0} MSKU, ngưỡng là {1}, chọn cửa hàng Chính là: {2}",
    "mskuhasbeendeleted": "Đã xóa MSKU",
    "masterproductchangedfromto": "Sản phẩm Chính đổi từ {0} thành {1}",
    "mainstorechangedfromto": "Cửa hàng Chính đổi từ {0} thành {1}",
    "thresholdchangedfromto": "Ngưỡng từ {0} thành {1}",
    "masterProductActivity": "Hoạt động sản phẩm chính",
    "operationType": "Loại thao tác",
    "salesTypeId": "Loại bán hàng",
    "salesRangeId": "Phạm vi bán hàng",
    "modifyChannelProductPrices": "Chỉnh sửa giá sản phẩm kênh",
    "areyousureyouwanttodeletetheruleafterdeletedyouneedtoreconfigurethesynchronizationrules": "Xác nhận xóa quy tắc? Sau khi xóa cần cấu hình lại quy tắc đồng bộ",
    "orderPushRecord": "Nhật ký đẩy đơn hàng",
    "productPushRecord": "Nhật ký đẩy sản phẩm",
    "youHaveNotActivatedStockMonitoring": "Bạn chưa bật \"Giám sát và Đồng bộ tồn kho\"",
    "turnOnNow": "Tắt",
    "turnOn": "Bật",
    "theSalesRangeOfCannot": "Phạm vi bán hàng của {} không thể cao hơn {}",
    "theValueOnTheRightSide": "Giá trị nhập bên phải của hộp nhập không thể ít hơn bên trái",
    "pushRecord": "Nhật ký đẩy",
    "allWarehouse": "Toàn bộ kho hàng",
    "allStoresExceptCustomStores": "Tất cả cửa hàng ngoại trừ cửa hàng tùy chỉnh: {x}%",
    "pushFailed": "Tolak Gagal",
    "pushed": "Sudah ditolak\tEmpurrado",
    "bindwarehouse": "Kho liên kết",
    "afterTurnOnTheProductsThat": "Sau khi bật, các sản phẩm đáp ứng quy tắc này sẽ được đẩy sang nền của cửa hàng",
    "afterDeactivationProductsThatMeetThis": "Sau khi tắt,các sản phẩm đáp ứng quy tắc này sẽ không còn được đẩy sang nền của cửa hàng",
    "afterTheSettingIsCompleteAverage": "Sau khi hoàn thành cài đặt, doanh thu trung bình hàng ngày sẽ được tính theo cài đặt mới.",
    "productDescriptionMustBeBetweenMinmaxcharacters": "Mô tả sản phẩm bắt buộc từ {min} đến {max} kí tự",
    "repush": "Đẩy lại",
    "pullAccurateStock": "Kéo tồn kho Accurate",
    "accounting1": "Kế toán",
    "returnManagement": "Quản lý trả hàng",
    "returnOrderXxxRejectedToRefund": "Đơn hàng xxx từ chối hoàn tiền cho người mua",
    "returnOrderXxxAgreedToRefund": "Đơn hàng xxx đồng ý hoàn tiền cho người mua",
    "returnOrderXxxRejectedRefundreturnRequest": "Đơn hàng trả xxx từ chối hoàn tiền  trả hàng",
    "returnOrderXxxAcceptedRefundreturnRequest": "Đơn hàng trả xxx chấp nhận yêu cầu hoàn tiền/ trả hàng",
    "returnOrderXxxSuccessfullySyncedThe": "Đơn hàng trả xxx đồng bộ thành công, phương thức trả hàng là \"xxx\" và trạng thái xử lý là \"xxx\"",
    "rejectRefund": "Từ chối hoàn tiền",
    "acceptRefund": "Đồng ý hoàn tiền",
    "acceptRequest": "Chấp nhận yêu cầu",
    "syncReturnOrder": "Đồng bộ hóa đơn đặt hàng trả lại",
    "confirmInbound": "Xác nhận nhập kho",
    "mass": "Hàng loạt",
    "returnWarehouse1": "Kho trả hàng",
    "returnProduct": "Sản phẩm trả hàng",
    "doYouAgreeToRefund": "Vui lòng xác nhận nếu bạn đồng ý hoàn tiền?",
    "pleaseSelectRejectionReason": "Vui lòng chọn nguyên nhân từ chối",
    "doYouAcceptBuyersReturnRefund": "Vui lòng xác nhận xem có chấp nhận yêu cầu trả hàng của người mua hay không?",
    "onceYouAgreeItCannotBe": "Một khi đồng ý là không thể hủy bỏ!",
    "doYouAcceptBuyersRefundRequest": "Vui lòng xác nhận có chấp nhận yêu cầu hoàn tiền của người mua hay không?",
    "exportAll": "Xuất tất cả trang",
    "viewReturnRecords": "Xem nhật ký trả hàng",
    "accept": "Chấp nhận",
    "isItADisputedOrder": "Có phải là đơn hàng tranh chấp hay không?",
    "inboundNo": "Mã nhập kho (không chỉ hiển thị trên trang hoàn trả)",
    "returnWarehouse": "Kho trả về không chỉ hiển thị trên trang hoàn trả)",
    "returnAwb": "Số AWB hoàn trả",
    "returnStatus": "Tình trạng hoàn trả",
    "processingDeadline": "Thời hạn xử lý",
    "processingStatus": "Trạng thái xử lý",
    "closed": "Đã đóng",
    "finished": "Đã hoàn thành",
    "inDispute": "Đang tranh chấp",
    "returnRemark": "Ghi chú trả hàng",
    "variantchannelSkumsku": "Thuộc tính/ SKU kênh/ MSKU",
    "returnNo": "Số đơn trả hàng",
    "disputedOrder": "Đơn hàng tranh chấp",
    "cod": "COD",
    "separateMultipleInfoWithSpace": "Tách nhiều thông tin bằng dấu cách",
    "returnRefund": "Trả hàng và hoàn tiền",
    "returnOrder": "Đơn hoàn trả",
    "xRuleHasBeenDeletedPlease": "Quy tắc {x} đã xóa, vui lòng kiểm tra lịch sử trong nhật ký thao tác",
    "checknow": "Kiểm tra ngay",
    "pushTime": "Thời gian đẩy",
    "theActivityLevelOfTheChannel": "Mức hoạt động của sản phẩm kênh đã được đặt và không thể đặt trùng lặp.",
    "deleteMasterProduct": "Xóa sản phẩm chính",
    "pleaseEnterRemarks": "Vui lòng nhập ghi chú",
    "refundType": "Phương thức hoàn tiền",
    "refundOnly": "Chỉ hoàn tiền",
    "refundStatus": "Trạng thái hoàn tiền",
    "returnInformation": "Thông tin trả hàng",
    "refundReason": "Nguyên nhân hoàn tiền",
    "returned": "Đã hoàn trả",
    "returning": "Đang hoàn trả",
    "waitingBuyerToReturn": "Chờ người mua hoàn trả",
    "returnRecord": "Nhật ký trả hàng",
    "refundList": "Danh sách hoàn tiền",
    "mskuBindingStatus": "Trạng thái liên kết MSKU",
    "pleaseConfirmTheReturnedProductBefore": "Vui lòng xác nhận sản phẩm trả hàng trước khi nhập lại vào kho. Nếu bị lỗi, vui lòng đồng bộ đơn hàng trước khi nhập lại vào kho.",
    "combinedProductSales": "Doanh số sản phẩm kết hợp",
    "includingCombineProductSalesAndIndividual": "Gồm doanh số bán hàng kết hợp sản phẩm và doanh số bán đơn lẻ của sản phẩm kết hợp.",
    "countTheSalesOfCombinedProducts": "Tổng doanh số của sản phẩm kết hợp đã bán đơn lẻ và theo combo",
    "pleaseSelectTheDataToBe": "Vui lòng chọn dữ liệu xuất",
    "salesOfIndividuallySoldItems": "Doanh số bán hàng của sản phẩm đơn",
    "salesOfProductsSoldByThe": "Doanh số bán hàng của sản phẩm kết hợp",
    "combinedProductMsku": "MSKU sản phẩm kết hợp",
    "combinationProductMsku": "Kết hợp MSKU sản phẩm",
    "combinedProductName": "Tên sản phẩm kết hợp",
    "mainCommoditySalesAnalysis": "Phân tích bán hàng sản phẩm Chính",
    "productDetailsOfThePortfolio": "Chi tiết sản phẩm kết hợp",
    "combinationProductDetails": "Kết hợp chi tiết sản phẩm",
    "combinedProduct": "Sản phẩm kết hợp",
    "exportChoice": "Xuất mục đã chọn",
    "combinationProducts": "Sản phẩm kết hợp",
    "refundRate": "Tỷ lệ hoàn",
    "refundAmount": "Số tiền hoàn",
    "refundQuantity": "Số lượng hoàn",
    "refundOrderQuantity": "Số đơn hoàn",
    "numberOfFailedOrders": "Số đơn thất bại",
    "numberOfReturnedOrder": "Số đơn hoàn",
    "numberOfCanceledOrder": "Số đơn Đã hủy",
    "numberOfShippedOrders": "Số đơn khách Đã nhận",
    "numberOfOrdersInDelivery": "Số đơn Đang vận chuyển",
    "numberOfReadyToShipOrder": "Số đơn Sẵn sàng giao",
    "numberOfPaidOrder": "Số đơn Đã thanh toán",
    "numberOfPendingPaymentOrder": "Số đơn Chờ thanh toán",
    "effectiveSalesAmount": "Tiền bán hàng hiệu quả",
    "totalSales": "Tổng doanh số",
    "totalProductSales": "Tổng doanh số sản phẩm",
    "relatedOrderQuantity": "Số đơn liên quan",
    "msku": "MSKU",
    "creationTime": "Giờ tạo",
    "keywords": "Từ khóa",
    "isACombinationProduct": "Gồm sản phẩm kết hợp?",
    "salesQTY": "Doanh số",
    "failedGenerateInboundList": "Tạo phiếu nhập kho thất bại",
    "someMasterProductDoNotExist": "Vài sản phẩm Chính không tồn tại trong kho hàng đã chọn",
    "masterProductIsEmptyPleaseRebind": "Sản phẩm Chính trống, vui lòng liên kết lại sản phẩm Chính và thử lại",
    "refundInformationNotCompletePleaseRefresh": "Hoàn tiền chưa hoàn tất, vui lòng làm mới trang",
    "informapping": "Trùng khớp",
    "mappingtip": "Chọn danh mục và loại thuộc tính để cập nhật đầy đủ thông tin (ví dụ: Tồn kho, giá cả...)",
    "Mappingcategory": "Đối chiếu danh mục",
    "tiktokCategoryAttributes": "Thuộc tính danh mục Tiktok",
    "enterBetween": "Nhập từ 1-60",
    "salesInformation": "Thông tin bán hàng",
    "tikTokCategory": "Danh mục TikTok",
    "tiktokfailedToPublishGineeProductThat": "Đăng thất bại: sản phẩm mà Ginee đăng thất bại",
    "tiktokpublishingGineeProductThatAreBeing": "Đang đăng: sản phẩm Ginee đang đăng lên hoặc sản phẩm đang xử lý trên Trung tâm người bán",
    "tiktokdraftAProductDraftCreatedBy": "Bản nháp: bản nháp sản phẩm tạo bởi Ginee",
    "tiktoksuspendedThisIsAProductWith": "Bị khóa: trong trung tâm người bán là trạng thái Bị khóa",
    "tiktoksoldOutThisIsAProduct": "Hết hàng: trong trung tâm người bán là sản phẩm có tồn kho = 0 với trạng thái Đang hoạt động/Không hoạt động",
    "tiktokdeactivatedThisIsAProductWith": "Không hoạt động: trong trung tâm người bán là trạng thái Không hoạt động",
    "tiktokliveThisIsAProductWith": "Hoạt động: trong trung tâm người bán là trạng thái Đang hoạt động",
    "tiktokskuMustBeCharacters": "SKU phải từ 1-50 ký tự",
    "tiktokwarrantyPolicyMustBeCharacters": "Chính sách bảo hành phải từ 0-99 ký tự",
    "tiktokpriceRangeShouldBe": "Khoảng giá dao động 100-100000000",
    "tiktokstocktLimitOhters": "Tồn kho dao động từ 0-99999",
    "tiktokweightShouldBeBetweenKg": "Trọng lượng dao động 0.01-100",
    "tiktokheightShouldBeBetween": "Chiều cao dao động 1-35",
    "tiktokwidthShouldBeBetween": "Chiều rộng dao động 1-40",
    "tiktoklengthShouldBeBetween": "Chiều dài dao động 1-60",
    "tiktokproductDescriptionLimitIdMy": "Mô tả sản phẩm bắt buộc từ 1 đến 10.000 kí tự",
    "productNameMinMaxCharacters": "Tên sản phẩm bắt buộc từ {min} đến {max} kí tự",
    "mpType": "Trạng thái liên kết MSKU",
    "updateDates": "Thời gian cập nhật",
    "returnOrderXHasSuccessfullyCreated": "Đơn trả hàng {x} đã tạo thành công phiếu nhập kho hàng trả, số phiếu nhập kho là {x}",
    "createInboundList": "Tạo Phiếu nhập kho",
    "noteThereIsADelayIn": "Lưu ý: Đang chậm trong việc cập nhật trạng thái đơn hàng kênh và vận đơn, có thể khiến  đẩy thất bại. Khi lần đẩy thất bại, vui lòng thử đẩy lại trong danh sách thất bại.",
    "theOrderIsBeingPushedTo": "Đơn hàng đang được đẩy về kho bên thứ ba. Vui lòng không thực hiện lại thao tác và đến danh sách đơn hàng để kiểm tra tiến độ đẩy",
    "pushTaskSuccessfullyCreated": "Tác vụ đẩy được tạo thành công",
    "refundLog": "Nhật ký hoàn tiền",
    "refundNo": "Mã hoàn tiền",
    "refundOrder": "Đơn hoàn tiền",
    "theServiceIsBusyOrYou": "Dịch vụ đang bận hoặc bạn có thể đã đạt đến giới hạn tỷ lệ yêu cầu, vui lòng thực hiện đẩy theo cách thủ công",
    "cancellationIsNotAllowedWhilePush": "Không được phép hủy trong khi đẩy",
    "synchronizeAllMskuInWarehouseTo": "Đồng bộ tất cả MSKU trong kho đến SKU trên kênh dựa trên tỉ lệ {x} và {y}",
    "youDoNotHavePermissionAt": "Bạn tạm không có quyền truy cập, vui lòng liên hệ quản trị viên để đặt quyền truy cập trong [Quản lý vai trò]",
    "tikiproductsCreatedInGineeExcludingProducts": "Sản phẩm nháp được tạo trong Ginee, không bao gồm các sản phẩm trong Trung tâm người bán - Trạng thái nháp",
    "tikiproductsFailedPublishByGinee": "Sản phẩm Ginee đăng thất bại",
    "tikiproductsPublishingByGinee": "Sản phẩm Ginee đang đăng",
    "tikiproductLockedInSellerCenterApi": "Tiki hiện chưa có sản phẩm bị Cấm",
    "tikiamongActiveAndInactiveProductsAll": "Trong các sản phẩm đang và không hoạt động,  sản phẩm mà tất cả thuộc tính có tổng tồn kho = 0;\nDo vấn đề của giao diện, sản phẩm FBT sẽ tạm thời được đưa vào danh sách Hết hàng",
    "tikinonactiveProductsInSellerCenter": "Sản phẩm trong Trung tâm người bán có trạng thái là OFF và bị khóa",
    "tikiactiveProductInSellerCenter": "Sản phẩm Đang hoạt động trong Trung tâm người bán",
    "skuProductCode": "SKU (Mã sản phẩm)",
    "lowstockthresholdValueCannotHigherThan": "Giá trị  lowStockThreshold không thể cao hơn số lượng tồn kho",
    "descriptionUpToCharacters": "Mô tả tối đa {max} ký tự",
    "skuPromotionPriceCannotHigherThan": "Giá SKU khuyến mã không thể cao hơn giá gốc, vui lòng chỉnh sửa",
    "skuPromotionStartTimeCannotBe": "Thời gian bắt đầu SKU khuyến mã không thể trống",
    "skuPromotionEndTimeCannotBe": "Thời gian kết thúc SKU khuyến mã không thể trống",
    "skuLowstockthresholdValueCannotHigherThan": "Giá trị SKU lowStockThreshold không thể cao hơn số lượng tồn kho",
    "variantSkuCannotBeEmpty": "SKU thuộc tính không thể trống",
    "productSkuCannotBeEmpty": "SKU sản phẩm không thể trống",
    "thisSkuAlreadyExistsInThis": "SKU này:${0} đã tồn tại trong cửa hàng, vui lòng chỉnh sửa lại",
    "productMainImageCannotBeEmpty": "Hình đại diện sản phẩm chính không thể trống",
    "promotionPriceCannotBeHigherThan": "Giá khuyến mãi không thể cao hơn giá gốc",
    "theInputRangeFromMin": "Giới hạn nhập từ { min } - { max}",
    "addVariants": "Thêm thuộc tính",
    "shippingClass": "Loại vận chuyển",
    "crosssells": "Bán chéo",
    "upsells": "Bán thêm",
    "enableThisToOnlyAllowOne": "Bật để cho phép một trong những mặt hàng này được mua trong đơn hàng lẻ",
    "soldIndividually": "Bán đơn lẻ",
    "lowStockThreshold": "Ngưỡng tồn kho thấp",
    "allowBackorders": "Cho phép hàng dự trữ",
    "enableStockManagementAtProductLevel": "Bật quản lý tồn kho ở mức sản phẩm",
    "manageStock": "Quản lý tồn kho",
    "taxClass": "Loại thuế",
    "taxStatus": "Tình trạng thuế",
    "usedForVariations": "Được sử dụng cho các thuộc tính",
    "visibleOnTheProductPage": "Xem được ở trang sản phẩm",
    "thisIsAFeaturedProduct": "Đây là một sản phẩm đặc trưng",
    "showSharingButtons": "Hiển thị nút chia sẻ",
    "showLikes": "Hiển thị lượt thích",
    "likeAndShares": "Lượt thích và chia sẻ",
    "hidden": "Ẩn",
    "searchResultsOnly": "Chỉ kết quả tìm kiếm",
    "shopOnly": "Chỉ gian hàng",
    "shopAndSearchResults": "Gian hàng và kết quả tìm kiếm",
    "catalogVisibility": "Hiển thị danh mục",
    "heightMinMax": "Chiều cao dao động {min}-{max}",
    "widthMinMax": "Chiều rộng dao động{min}-{max}",
    "lengthMinMax": "Chiều dài dao động{min}-{max}",
    "weightMinMax": "Trọng lượng dao động {min}-{max}",
    "pleaseEnterProductDescription": "Vui lòng nhập mô tả",
    "productNameMinMax": "Tên sản phẩm bắt buộc từ {min} đến {max}kí tự",
    "variantPicture": "Hình ảnh Thuộc tính",
    "productImageMax": "Hình ảnh sản phẩm tối đa {number}",
    "promotionTime": "Thời gian Khuyến mãi",
    "promotionPrice": "Giá Khuyến mãi",
    "options": "Tùy chọn",
    "variantType": "Loại thuộc tính",
    "productAttributes": "Thuộc tính sản phẩm",
    "productShortDescription": "Mô tả ngắn",
    "productTag": "Tag Sản phẩm",
    "updatedateasc": "Thời gian cập nhật mới nhất",
    "updatedatedesc": "Thời gian cập nhật cũ nhất",
    "createdateasc": "Thời gian tạo cũ nhất",
    "createdatedesc": "Thời gian tạo mới nhất",
    "withoutmp": "Chưa liên kết SKU chính",
    "withmp": "Liên kết SKU chính",
    "generateInventorySKU": "Tạo Master SKU",
    "onBackorder": "Đặt hàng trước",
    "productNameAndStore": "Tên sản phẩm & cửa hàng",
    "sync": "Đồng bộ",
    "inStock": "Còn hàng",
    "soldOut": "Hết hàng",
    "disabled": "Đã xuống kệ",
    "china": "Trung Quốc",
    "wooPublishStatus": "Tại cửa hàng, sản phẩm đang đợi xử lý sẽ hiển thị trong tab Đang đăng của Ginee",
    "wooActiveStatus": "Tại cửa hàng, sản phẩm đã đăng sẽ hiển thị trong tab Đã lên kệ của Ginee",
    "wooDeactiveStatus": "Tại cửa hàng, sản phẩm cá nhân sẽ hiển thị trong tab Đã xuống kệ của Ginee",
    "wooDraftStatus": "Tại cửa hàng, bản nháp của sản phẩm hiển thị trong tab Bản nháp của Ginee",
    "inProcess": "Đã đăng",
    "stockStatus": "Trạng thái hàng tồn kho",
    "externalProduct": "Sản phẩm liên kết",
    "groupProduct": "Nhóm sản phẩm",
    "variable": "Nhiều Thuộc tính",
    "simpleVariation": "Một Thuộc tính",
    "editStatusError": "Vui lòng nhấn nút Xác nhận trước, và nhấn Đăng lên cửa hàng",
    "productInfoSetting": "Cài đặt thông tin sản phẩm",
    "choiceMskuTip": "Sau khi chọn MSKU, hệ thống sẽ sử dụng thông tin giá và tồn kho của MSKU, và  ràng buộc thông tin SKU của blibli với MSKU.",
    "categoryMatch": "Phối hợp Danh mục",
    "variationLimit": "Danh mục đã chọn không hỗ trợ thêm Thuộc tính",
    "clickToEdit": "Nhấp vào nội dung của bảng, có thể thực hiện thao tác chỉnh sửa",
    "whatsInTheBox": "Nội dung bưu phẩm",
    "MinPurchaseQuantity": "Số lượng mua tối thiểu",
    "ProductDescriptionShouldBeCharacters": "Mô tả sản phẩm phải từ 6-25000 kí tự",
    "chooseStoreLimit": "Vui lòng chọn ít nhất 1 cửa hàng đích",
    "replaceWith38": "Thay thế với",
    "addBetweenTheDescription": "Thêm ở giữa mô tả",
    "addAtTheEndOfThe": "Thêm ở cuối mô tả",
    "addAtTheStartOfThe": "Thêm ở đầu mô tả",
    "addBetweenName": "Thêm ở giữa tên",
    "editProductName": "Chỉnh sửa tên sản phẩm",
    "theFileWillOnlyBeReserved": "Tệp chỉ lưu trữ trong 7 ngày, vui lòng kiểm tra hoặc tải về",
    "theResultOfThePublishWill": "Kết quả đăng sẽ đồng bộ hiển thị ở [Sản phẩm chính - Nhiều lịch sử hoạt động]",
    "successfulQtyAllQty": "Số lượng thành công / Tất cả số lượng",
    "publishing": "Đang đăng",
    "taskStatus": "Trạng thái nhiệm vụ",
    "publishedStoreStoreStore": "Đăng lên cửa hàng: cửa hàng 1, cửa hàng 2",
    "content": "Nội dung",
    "creator": "Người tạo",
    "taskType": "Loại nhiệm vụ",
    "allPublishFailed": "Tất cả đăng thất bại",
    "somePublishFailed": "Một phần đăng thất bại",
    "allPublished": "Tất cả đăng thành công",
    "productsWereSuccessfullyPublished": "29 sản phẩm đăng thành công",
    "publishingToChannelStores": "Đang đăng hàng loạt lên kênh cửa hàng",
    "massEditOfShopeesCategoryAttribute": "Chỉnh sửa nhiều thuộc tính danh mục của cửa hàng",
    "matchTokopediaVariantType": "Phối hợp loại Thuộc tính của Tokopedia",
    "matchLazadaVariantType": "Phối hợp loại Thuộc tính của Lazada",
    "masterProductVariantType": "Loại Thuộc tính sản phẩm chính",
    "massEditProductDescription": "Chỉnh sửa mô tả sản phẩm hàng loạt",
    "shopeeCategoryAttribute": "Thuộc tính danh mục của cửa hàng Shopee",
    "productNameInThisStoreIs": "Tên sản phẩm ở cửa hàng này đã tồn tại",
    "partOfTheMasterProductIs": "Một phần thông tin bắt buộc của sản phẩm chính không được để trống, vui lòng bổ sung đầy đủ và đăng lên cửa hàng",
    "chooseACountry": "Chọn quốc gia/Khu vực",
    "max11": "Lớn nhất 1000",
    "massEditWeightG": "Chỉnh sửa trọng lượng hàng loạt (g)",
    "max7": "Lớn nhất 500.000",
    "empty": "Trống",
    "editLogisticsMass": "Chỉnh sửa vận chuyển hàng loạt",
    "editBliblidraftmass": "Blibli / Chỉnh sửa hàng loạt",
    "categoryAttributesBlibli": "Thuộc tính danh mục Blibli",
    "copyMass": "Sao chép hàng loạt",
    "categoryChoose": "Chọn danh mục",
    "matchCategoryBlibli": "Phối hợp danh mục Blibli",
    "storeWarehouse": "Cửa hàng / Kho hàng",
    "buyable": "Có thể mua",
    "MinimumStock": "Phạm vi Hàng tồn kho tối thiểu phải là 0-999.999",
    "originalPriceLimit2": "Giá gốc phải cao hơn giá bán",
    "sellingPriceLimit2": "Giá bán phải nhỏ hơn giá gốc",
    "originalPriceLimit": "Phạm vi giá gốc nên là 10.000 - 1000.000.000",
    "enterYoutubeLinkForExampleYouTubecomipadview": "Nhấn vào Youtube Link, ví dụ: YouTube.com/ipadview",
    "youtubeVideoLink": "Youtube Video Link",
    "exampleTheBatteryLifeIsUp": "Ví dụ: thời lượng pin lên đến 10h",
    "sellingPoint": "Đặc trưng",
    "continueToAddProducts": "Tiếp tục thêm sản phẩm",
    "jdChannelIsUnderReviewCheck": "Kênh Blibli đang xét duyệt, vui lòng kiểm tra sau. Kết quả sẽ hiển thị trên tab \"Đang đăng/Đăng thất bại/Xuống kệ\"",
    "failedReasons": "Nguyên nhân thất bại",
    "productsUpdateSucceeded": "Cập nhật sản phẩm thành công",
    "changeSKUTo": "Sửa SKU thành",
    "massEditSellingPrice": "Chỉnh sửa Giá bán hàng loạt",
    "massEditOriginalPrice": "Chỉnh sửa Giá gốc hàng loạt",
    "massEditSku": "Sửa nhiều SKU",
    "editVariantImage": "Chỉnh sửa hình ảnh Thuộc tính",
    "multipleImagesCanBeUploadedAt": "Có thể tải lên cùng lúc nhiều nhiều ảnh. Hình ảnh nhỏ nhất 600 x 600 pixel, lớn nhất 4MB (Tối đa 8 ảnh)",
    "shouldBeBetween": "Phạm vi nhập từ 0-1000.000.000",
    "optionsnLimit": "Lựa chọn phải ít hơn 20",
    "bigItem": "Sản phẩm cỡ lớn",
    "smallItem": "Sản phẩm cỡ nhỏ",
    "qualitydays": "Thời gian bảo hành chất lượng",
    "afterSale": "Dịch vụ sau bán hàng",
    "Min1": "Tối thiểu là 1",
    "minpurchasePerOrder": "Số lượng mua tối thiểu",
    "pleaseEnterProductName": "Vui lòng nhập tên sản phẩm",
    "blibliCategory": "Danh mục BliBli",
    "partOfTheProductIs": "Theo yêu cầu đăng sản phẩm của kênh cửa hàng, sản phẩm có một số dòng bắt buộc điền. Vui lòng bổ sung đầy đủ các dòng bắt buộc trước khi đăng lên cửa hàng.",
    "blibliShouldBeBetweenA": "Phạm vi nhập là 0,001-999,999",
    "blibliWeightLimit": "Phải từ 1-999.999!",
    "blibliStocktLimit": "Phạm vi Hàng tồn kho nên từ 0-999.999!",
    "blibliImageSizeLimit": "Hình ảnh nhỏ nhất 600 x 600 pixel, lớn nhất 4MB",
    "serviceErrorPleaseContactGineeTechnology": "Lỗi dịch vụ, vui lòng liên hệ nhóm kĩ thuật Ginee",
    "minPurchaseLimit": "Số lượng mua tối thiểu phải từ 1-1000.000.000",
    "enterThenChange": "Nhập giá trị trước rồi thay đổi đơn vị sau",
    "goToTheBlibliSellerCenter": "Vui lòng đi đến Trung tâm người bán của blibli bổ sung địa chỉ kho hàng trước khi có thể chọn dịch vụ vận chuyển",
    "regularStoreSupport": "Hỗ trợ cửa hàng Regular",
    "toApiRestrictionsSomeVariantOption": "Do các hạn chế của API, không thể sao chép một số tùy chọn thuộc tính. Vui lòng thêm chúng nếu cần.",
    "theCategoryAndVariantTypeApply": "Loại danh mục và thuộc tính có áp dụng cho tất cả các sản phẩm thuộc cùng một danh mục Nguồn (Danh mục nguồn: xxx / xxxx / xxxx của cùng một trang web) không?",
    "toApiRestrictionsSomeFieldsDo": "Do các hạn chế của Blibli API, không thể chỉnh sửa một số hàng",
    "shippingMethod": "Phương thức vận chuyển",
    "backToProductList": "Quay lại danh sách sản phẩm",
    "chooseTheShippingMethodFirstPlease": "Vui lòng chọn Phương thức Vận chuyển",
    "needToChooseNo": "Không cần chọn",
    "fAq1": "Blibli: Làm thế nào để thêm hoặc chỉnh sửa sản phẩm?",
    "fAq2": "Blibli: Làm thế nào để sao chép sản phẩm?",
    "fAq3": "Blibli: Làm thế nào để chỉnh sửa sản phẩm hàng loạt?",
    "synchronizationForThisProductHasBeen": "Sản phẩm đã bật đồng bộ tồn kho, vui lòng sửa tồn kho trong quản lý tồn kho",
    "productDeleting": "Đang xóa sản phẩm",
    "operationWillNotDeleteTheProduct": "Thao tác này sẽ không xóa sản phẩm này trong Trung tâm nhà bán",
    "deleteTip": "Do hạn chế của giao diện, chỉ hỗ trợ xóa sản phẩm ở trạng thái bản nháp, đang đăng, đăng thất bại.",
    "productOrVariationImage": "Hình ảnh sản phẩm / thông số kỹ thuật",
    "imageTip2": "Sản phẩm không có biến thể, hình ảnh tham khảo hình ảnh của sản phẩm, và sản phẩm có biến thể tham khảo hình ảnh của biến thể",
    "blibliSkuLimit": "SKU phải ít hơn 50 kí tự",
    "exampleTheAvailableInventoryOfWarehouse": "Ví dụ: Tồn kho có sẵn của kho A là 100, tồn kho có sẵn của kho B là 200, và đẩy kho đặt thành A+B, và tỷ lệ đẩy là 50%, khi đó tồn kho đẩy sang kho hàng đích C là 150",
    "exampleThePushFixedValueIs": "Ví dụ: Giá trị cố định đẩy là 100, cửa hàng đích đẩy đến là A và khi thực hiện lệnh đẩy, tồn kho được đẩy đến cửa hàng A là 100",
    "ProductStatus": "Trạng thái sản phẩm",
    "productsThatFailToBePushed": "Sản phẩm đẩy thất bại không được đẩy lại, vui lòng liên hệ nhân viên ECCANG WMS để kiểm tra sản phẩm trước",
    "returnTime": "Thời gian phản hồi",
    "theProductHasNotPassedThe": "Sản phẩm này không được duyệt",
    "shippingFeePaidBySeller": "Người bán trả tiền vận chuyển",
    "skucanonlybenumberslettersandhyphenandthemaximumcharacters": "SKU chỉ có thể là số thứ tự, chữ cái, gạch ngang và độ dài tối đa 30 ký tự.",
    "skuNameMaxCharactersOnly": "Tên SKU, độ dài tối đa 50 ký tự, chỉ hỗ trợ tiếng Trung và tiếng Anh, số thứ tự , dấu gạch ngang và dấu gạch dưới",
    "productThatFailToPassThe": "Sản phẩm không được duyệt tại Easy Warehouse WMS sẽ không được giao",
    "skuCanOnlyBeInUppercase": "Chỉ hỗ trợ ký tự SKU viết hoa",
    "afterFailedToSendTheInbound": "Không hỗ trợ đẩy lại ho gửi đơn nhập kho thất bại",
    "productEffectiveSales": "Doanh số bán hàng hiệu quả",
    "pleaseNote": "Vui lòng lưu ý:",
    "dueToTheLimitationOfThe": "Do giới hạn của ECCANG WMS, SKU chỉ có thể là số thứ tự, chữ cái, gạch ngang và độ dài tối đa là 30 ký tự;",
    "someOrdersAreNotAssignedWith": "Một số đơn hàng chưa được phân phối đơn vị vận chuyển, vui lòng sắp xếp vận chuyển lại sau khi đồng bộ",
    "ordersWithoutSublogisticsAreNotSupported": "Không hỗ trợ giao hàng cho các đơn chưa phân phối đơn vị vận chuyển, vui lòng đồng bộ đơn hàng để nhận thông tin vận chuyển",
    "waitingForDistributionLogisticsPleaseSynchronize": "Chờ phân phối đơn vị vận chuyển, vui lòng đồng bộ",
    "productInformationSettingError": "Lỗi cài đặt thông tin sản phẩm",
    "userAddressInformationError": "Lỗi thông tin địa chỉ người dùng",
    "notInShippingAddress": "Địa chỉ không nằm trong phạm vi giao hàng",
    "deliveryFailure": "Giao hàng thất bại (giao hàng thất bại do người dùng không trả lời điện thoại hoặc địa chỉ không khớp)",
    "logisticsCannotSupportDistribution": "Đơn vị vận chuyển không thể giao hàng (chẳng hạn như lũ lụt trong mùa mưa)",
    "theMerchantSentTheWrongProduct": "Người bán gửi sai sản phẩm",
    "productDamage": "Sản phẩm bị hỏng (chưa xuất kho đã bị hỏng)",
    "productDescriptionDoesNotMatch": "Mô tả sản phẩm không khớp",
    "businessAndUserPhoneToCancelOrder": "Người bán thương lượng với người dùng để hủy đơn hàng",
    "goodsDamageDuringLogisticsDelivery": "Hàng hóa mất/thiệt hại trong quá trình giao hàng",
    "theUserAskedToCancelTheOrder": "Người dùng yêu cầu hủy đơn",
    "logisticsAndDistributionCannotContactTheRecipient": "Đơn vị vận chuyển không thể liên hệ người nhận",
    "incompleteRecipientAddress": "Không đủ thông tin địa chỉ người nhận",
    "thirdPartyMerchantsHaveNoInventory": "Người bán bên thứ ba không có tồn kho",
    "outOfDeliveryRange": "Vượt khỏi phạm vi giao hàng",
    "itIsOutOfStockAndCannotBeTraded": "Đã hết hàng và không thể giao dịch",
    "theBuyerSInformationIsFilledInWrong": "Điền sai thông tin người mua, vui lòng chụp hình mới",
    "latePayment": "Thanh toán trễ",
    "maliciousBuyers": "Người mua có dụng ý xấu/muốn phá hoại",
    "theBuyerHasNoSincerityToCompleteTheTransaction": "Người mua không có thiện chí hoàn thành giao dịch",
    "theBuyerMadeAMistakeOrRemake": "Người mua chụp nhầm hoặc chụp lại",
    "unableToContactBuyer": "Không thể liên hệ với người mua",
    "otherReasons": "Nguyên nhân khác",
    "unableToCompletePayment": "Không thể hoàn tất thanh toán / không thể nhận mã thanh toán",
    "thePaymentMethodNeedsToBeChanged": "Cần đổi phương thức thanh toán",
    "commodityPriceReduction": "Giảm giá sản phẩm",
    "wrongAddressInformation": "Sai thông tin địa chỉ",
    "wrongProductInformation": "Sai thông tin sản phẩm",
    "iDonTWantItAnymore": "Không cần nữa",
    "goodsOutOfStock": "Hết hàng",
    "synchronizeAllMskusUnderXTo": "Đồng bộ tổng của tất cả MSKU dưới {x} với {w} theo tỷ lệ {y} và {z}",
    "pushInProcess": "Đang đẩy",
    "waitingToPush": "Chờ đẩy",
    "rdWarehousePushStatus": "Trạng thái đẩy kho bên thứ ba",
    "pushStatus": "Trạng thái đẩy",
    "printable": "Có thể in",
    "productInboundStockCannotBeNegative": "Sản phẩm nhập kho không được âm",
    "andAccordingToZ": "Đồng bộ đến {w} dựa trên tỉ lệ của {z}",
    "synchronizeallmskuunder": "Thêm tất cả MSKU dưới {x} vào {y}",
    "pushSucess": "Đẩy thành công",
    "stockPushRuleaddRule": "Quy tắc đẩy tồn kho/ thêm quy tắc",
    "peraturanTolakStokubahSuai": "Quy tắc đẩy tồn kho/ chỉnh sửa",
    "peraturanTolakStoklihatPerincian": "Quy tắc đẩy tồn kho/ xem chi tiết",
    "negativeInventoryCannotBeManipulatedInto": "Hàng tồn kho bị âm và không thể đưa vào kho",
    "channelCategoryAttributes": "Thuộc tính danh mục {channelName}",
    "switchingMskuRequiresReselectingWarehouseInformation": "Để chuyển đổi MSKU, bạn cần chọn lại thông tin kho hàng",
    "pleaseAuthorizeTheStoreFirst": "Vui lòng tiến hành ủy quyền cửa hàng",
    "thereIsNoAlternativeStorePlease": "Không có cửa hàng để chọn, vui lòng tiến hành ủy quyền cửa hàng",
    "stockTikiTips": "ร้านค้า Tiki และสินค้า FBT ที่ไม่รองรับการแก้ไขข้อมูลสินค้าจะไม่สามารถเชื่อมต่อสต็อก",
    "stocksettingtip2Local": "Sau khi bật Đồng bộ Tồn kho, số lượng tồn kho của sản phẩm có cùng MSKU sẽ được điều chỉnh giống nhau. Khi MSKU tạo một đơn hàng mới hoặc đơn hàng bị hủy, tồn kho tất cả sản phẩm liên kết cùng một MSKU sẽ đồng loạt giảm hoặc tăng. Lưu ý, phải hoàn tất quá trình đồng bộ sản phẩm và đơn hàng trước khi bật cài đặt này, và số lượng tồn kho MSKU đã được điều chỉnh. \nSau khi bật đồng bộ tồn kho, tồn kho của SKU cửa hàng sẽ được đẩy theo tỷ lệ tồn kho và tồn kho của MaSKU trong quản lý tồn kho (thiết lập kho giao hàng). Nếu tồn kho chưa được cài đặt trong quản lý tồn kho, tồn kho SKU cửa hàng sẽ bị đẩy về 0 và không thể khôi phục. Vui lòng xác nhận xem nó đã được bật chưa.",
    "afterTheStockSynchronizationIsTurned": "Sau khi bật đồng bộ tồn kho, tồn kho của SKU cửa hàng sẽ được đẩy theo tỷ lệ tồn kho và tồn kho của Master SKU trong quản lý tồn kho (thiết lập kho vận chuyển của cửa hàng). Nếu tồn kho chưa được cài đặt trong quản lý tồn kho, tồn kho SKU của cửa hàng sẽ bị đẩy về 0 và không thể khôi phục. Vui lòng xác nhận xem nó đã được bật chưa.",
    "doesntSupportCustomShippingLabelTemplate": "Hiện tại không hỗ trợ Tùy chọn Mẫu Vận đơn cho Philippines, chỉ hỗ trợ thiết lập kích thước A4/A6",
    "commissionTips": "Nhập một số hợp lệ từ 0-100",
    "invoiceNumberTips": "Sản phẩm trong cùng một đơn hàng sẽ dùng chung số hóa đơn",
    "SettingTips2": "Vui lòng nhấn vào đây",
    "SettingTips": "Cài đặt này chỉ hợp lệ khi đơn hàng được xử lý trên Ginee(Lazada chỉ hỗ trợ cài đặt trong Seller Center). Để chỉnh sửa Địa chỉ mặc định/lấy hàng của cửa hàng,",
    "SettingLogo": "Nhấn vào đây để thiết lập Logo Thương hiệu",
    "SellerNotes": "Ghi chú Người bán",
    "PickingNotes": "Ghi chú lấy hàng",
    "TotalProduct": "Tổng số hàng",
    "PrintMan": "Được in bởi",
    "DeliverySettingPickupAddress": "Vui lòng tới Trung tâm Người bán để thiết lập địa chỉ",
    "DeliverySettingStoreName": "Tên cửa hàng",
    "PickFormReset": "Reset",
    "ChangePickStatusConfirm": "Xác nhận",
    "PickDeleteSuccess": "Xóa thành công!",
    "PickDeleteTipTwo": "Sau khi bị xóa thì không khôi phục lại được",
    "PickDeleteTip": "Bạn có chắc muốn xóa Thông tin Trạng thái",
    "PickOverCharacters": "Không được vượt quá 20 ký tự",
    "AddPickStatus": "Thêm quy tắc",
    "PickUpdateTime": "Giờ cập nhật",
    "PickCreateTime": "Giờ tạo",
    "PickStatusOperatorName": "Người tạo",
    "PickStatusName": "Tên trạng thái",
    "PrintUnitPrice": "Đơn vị giá",
    "PrintVariationName": "Thuộc tính",
    "PrintShipping": "Vận chuyển",
    "PrintSellerInfo": "Người bán",
    "PrintBuyerInfo": "Người mua",
    "BuyerNotes": "Ghi chú của Người mua",
    "ShippingFee": "Phí vận chuyển",
    "SenderInfo": "Người gửi",
    "GoToSellerCenter": "Vui lòng tới Trung tâm Người bán để thiết lập địa chỉ",
    "AutoShippingTips": "Ginee hỗ trợ cài đặt phương thức vận chuyển tự động, thiết lập ngay",
    "ShippingAddRule": "Thêm quy tắc",
    "OperationFailed": "Thất bại, Vui lòng thử lại",
    "OperationSuccess": "Thành công",
    "ApplyToAllStores": "Áp dụng cho Mọi Cửa hàng",
    "PickupAddress": "Địa chỉ lấy hàng",
    "ChooseAStore": "Chọn một Cửa hàng",
    "ShippingSettingTips": "Mẹo: Shopee chỉ cấp quyền truy cập phương thức nhận hàng SPX cho người bán được chọn",
    "ShopName": "Tên Cửa hàng",
    "ShippingMethod": "Phương thức vận chuyển",
    "Courier": "Nhà vận chuyển",
    "ShippingMethodDescription": "Dựa theo cài đặt của bạn, Ginee sẽ tự động lựa chọn phương pháp vận chuyển cho bạn sau khi bạn đã đồng bộ đơn hàng để cải thiện tính hiệu quả",
    "laStoreName": "Tên Cửa hàng",
    "proalertclosed": "Cảnh báo Tồn kho được tắt",
    "proalertopend": "Cảnh báo Tồn kho đã được bật. Khi lượng hàng có sẵn của Inventory SKU nhỏ hơn Lượng Tồn kho An toàn, chúng tôi sẽ gửi một email nhắc nhở",
    "PleaseSelectLeast": "Chọn ít nhất một",
    "DeleteTplTitle": "Bạn có chắc muốn xóa mẫu này?",
    "DeleteTplDesc": "Mẫu không thể hồi phục lại một khi đã bị xóa",
    "LaRuleBindingStoreSearchPlaceholder": "Vui lòng điền tên Cửa hàng",
    "LaRuleBindingStoreModalTitle": "Vui lòng chọn quy tắc Hóa đơn",
    "methodSettingDoubleCheckDescription": "Sau khi xóa bỏ quy tắc, quy tắc vận chuyển của cửa hàng tương ứng sẽ không còn hiệu lực",
    "LaRuleDoubleCheckDescription": "Sau khi xóa bỏ quy tắc, quy tắc tạo hóa đơn của cửa hàng tương ứng sẽ không còn hiệu lực",
    "LaRuleDoubleCheck": "Bạn có chắc muốn xóa bỏ quy tắc?",
    "ExportField": "Xuất Nội dung",
    "SelectChannel": "Lựa chọn kênh bán",
    "OrderExportingDragging": "Bạn có thể thay đổi thứ tự khi Xuất bằng cách kéo lên hoặc xuống",
    "BasicInfo": "Thông tin Cơ bản",
    "EditTemplate": "Chỉnh sửa tệp mẫu",
    "UpdateTime": "Giờ cập nhật",
    "CreateTime": "Giờ tạo",
    "TemplateName": "Tên mẫu",
    "Creator": "Người tạo",
    "LAInvoiceSettingSave": "Xác nhận",
    "ApplyToAllLazadaStores": "Áp dụng với mọi cửa hàng Lazada",
    "NextNumber": "Số tiếp theo",
    "EnterNumberManually": "Thêm số",
    "UseCustomNumber": "Sử dụng số mặc định",
    "UseOrderNumber": "Sử dụng số đơn hàng",
    "StoreManagement": "Quản lý cửa hàng",
    "RuleApplicationStoreNumber": "Số lượng cửa hàng Áp dụng",
    "RuleCustomNumber": "Số tùy chỉnh",
    "RulePrefix": "Mã\nký hiệu đầu",
    "RuleType": "Loại quy tắc",
    "RuleName": "Tên quy tắc",
    "AddRule": "Thêm quy tắc",
    "commissionPercent": "Phần trăm Hoa hồng (%)",
    "InvoiceRules": "quy tắc Hóa đơn",
    "ShippingSettingsTab": "Cài đặt Vận chuyển",
    "TemplateShippingAddress": "Địa chỉ giao hàng",
    "TemplateLogistics": "Logistics",
    "TemplateTotal": "Thành tiền",
    "TemplateSellerDiscountTotal": "Người bán giảm giá",
    "TemplateTax": "Thuế",
    "TemplateShipping": "Vận chuyển",
    "TemplateSubtotal": "Tổng cộng",
    "TemplateQty": "Số lượng",
    "TemplateCreatedAt": "Giờ tạo",
    "TemplatePayment": "Thanh toán",
    "TemplateContactNo": "Số điện thoại liên hệ",
    "TemplateUnitPrice": "Đơn vị",
    "TemplateBuyer": "Người mua",
    "TemplateAWBTrackingNumber": "AWB/Mã vận đơn",
    "Quantity": "Số lượng",
    "Specification": "Thuộc tính",
    "ProductName": "Tên sản phẩm",
    "TemplateProductItem": "Sản phẩm",
    "TemplateInsurance": "Bảo hiểm",
    "TemplateWeight": "Cân nặng",
    "TemplateShippingType": "Vận chuyển",
    "OrderId": "Mã Đơn hàng",
    "TemplateSender": "Người gửi",
    "TemplateRecipient": "Người nhận",
    "TemplateInformation": "Thông tin",
    "LogisticsLOGO": "Logo công ty vận chuyển",
    "ChannelLogo": "Logo kênh bán",
    "TemplateIcon": "Biểu tượng",
    "TemplatePrintDate": "Ngày in",
    "TemplateDate": "Ngày",
    "ViewBoxItemTitle": "Chế độ xem in",
    "TemplateSettingsTitle": "Cài đặt Mẫu in",
    "SetPickUpAddress": "Thiết lập địa chỉ lấy hàng",
    "SetDefaultAddress": "Thiết lập địa chỉ mặc định",
    "SenderAddress": "Địa chỉ",
    "SenderPhone": "Số điện thoại",
    "SenderName": "Tên",
    "DefaultAddress": "Địa chỉ mặc định",
    "BlSenderInfo": "i18n: BlSenderInfo",
    "TokoSenderInfo": "Mẹo: tên/ số điện thoại sẽ được sử dụng để hiển thị thông tin trong danh sách chuyển phát của Ginee",
    "ShopeeSenderInfo": "Mẹo: Tên/ số điện thoại của địa chỉ sẽ được dùng cho việc vận chuyển, và sau khi thiết lập Địa chỉ mặc định/lấy hàng tận nơi, thông tin địa chỉ sẽ được đồng bộ với Shopee",
    "PickingListPrintTemplateSettings": "Cài đặt Mẫu bảng kê lấy hàng",
    "ShippingLabelTemplateAlertTips": "Chỉ áp dụng với các file mẫu riêng của Ginee, không áp dụng với các file mẫu từ các công ty bên thứ 3 logistics tại các sàn thương mại điện tử",
    "PrintTemplateSettings": "Cài đặt mẫu in",
    "ShippingLabelAlertTips": "Nếu bạn muốn sử dụng File mẫu nhãn vận chuyển của Ginee hoặc chỉnh sửa Địa chỉ mặc định/ Địa chỉ lấy hàng,  vui lòng hoàn tất Cài đặt thông tin",
    "ShippingLabelInformationSettings": "Cài đặt thông tin Nhãn vận chuyển",
    "htar": "Làm thế nào để thêm vai trò?",
    "htas": "Làm thế nào để thêm nhân viên?",
    "htmr": "Làm thế nào để quản lý quyền?",
    "htms": "Làm thế nào để quản lý nhân viên?",
    "Mustbestartedwith8": "Phải bắt đầu bằng số 8",
    "atleastonerole": "Vui lòng chọn ít nhất một quyền",
    "noroles": "Không có dữ liệu, vui lòng thêm vai trò",
    "deleteroletip": "Nhân viên bị xóa sẽ không được lựa chọn vai trò",
    "suretodeleterole": "Bạn có chắc muốn xóa vai trò này?",
    "EditRole": "Chỉnh sửa vai trò",
    "Roleadded": "Đã thêm vai trò",
    "Permissionlimit": "Vui lòng chọn ít nhất một quyền",
    "Permission": "Quyền hạn",
    "roledesclimit": "Vui lòng nhập mô tả dưới 600 ký tự",
    "roleNamelimit": "Tên bắt buộc gồm 1-150 ký tự",
    "EnterRoleName": "Điền tên vai trò",
    "DeleteFailed": "Xóa bỏ thất bại",
    "staffDeleted": "Đã xóa nhân viên",
    "RoleDeleted": "Đã xóa vai trò",
    "AddRole": "Thêm vai trò",
    "Numberofstaff": "Số lượng nhân viên",
    "RoleDescription": "Mô tả vai trò",
    "RoleName": "Tên vai trò",
    "Submit": "Gửi",
    "Staffhasbeenadded": "Đã thêm nhân viên",
    "Nextstep": "Tiếp theo",
    "DiscardChange": "Hủy bỏ",
    "suretodiscard": "Bạn có muốn hủy bỏ thay đổi này?",
    "AddNewRole": "Thêm vai trò",
    "Existingpermissions": "Các quyền hiện có",
    "EnterthePassword": "Nhân viên sử dụng mật khẩu này để đăng nhập vào Ginee",
    "passwordlimit": "Mật khẩu phải bao gồm ít nhất 8 chữ cái, chữ số và ký hiệu",
    "Staffsusethemailbox": "nhân viên sử dụng mail để làm tài khoản đăng nhập Ginee",
    "Gineeaccountcannotbeempty": "Không được bỏ trống tài khoản Ginee",
    "Pleaseinputemail": "Vui lòng thêm địa chỉ email",
    "Contactphonenumber": "Điện thoại liên hệ",
    "namelimit": "Tên nhân viên từ 1-30 ký tự",
    "EnterStaffName": "Tên nhân viên",
    "locktip": "Tài khoản này đã bị vô hiệu hóa,  Vui lòng liên hệ Admin",
    "deletetip": "Nhân viên bị xóa sẽ không thể quản lý cửa hàng và bạn không thể thêm nhân viên này vào lại",
    "suretodeletestaff": "Bạn có chắc muốn xóa nhân viên này?",
    "Disablenow": "Vô hiệu hóa ngay",
    "disabletip": "Sau khi tài khoản bị vô hiệu hóa, nhân viên sẽ không thể đăng nhập vào Ginee để quản lý cửa hàng",
    "suretodisabled": "Bạn có chắc muốn vô hiệu hóa tài khoản nhân viên?",
    "Edit": "Chỉnh sửa",
    "Enabled": "Kích hoạt",
    "Disabled": "Vô hiệu hóa",
    "Action": "Thao tác",
    "LastLogin": "Đăng nhập lần cuối",
    "NameofStaff": "Tên nhân viên",
    "AddStaff": "Thêm nhân viên",
    "Search": "Tìm kiếm",
    "Roles": "Vai trò",
    "GineeAccount": "Tài khoản Ginee",
    "AccountStatus": "Trạng thái",
    "ActivationFailed": "Kích hoạt thất bại, vui lòng cài đặt trước",
    "SavedSuccessfully": "Thành công!",
    "donwloadlist": "Bấm vào đây tải xuống danh sách tồn kho đầy đủ",
    "InventoryWarnings": "Cảnh báo Tồn kho",
    "moresetting": "Thêm cài đặt",
    "suretoclosemainlock": "Bạn có chắc muốn tắt Khóa Tồn kho Cửa hàng Chính?",
    "suretoopenstocklock": "Bạn có chắc muốn kích hoạt tính năng Khóa Tồn kho Cửa hàng Chính? Sau khi bật, khi mà lượng tồn kho thấp hơn ngưỡng cài đặt, chỉ có cửa hàng chính có thể tiếp tục bán sản phẩm, số lượng tồn kho ở các cửa hàng còn lại giảm về 0",
    "whenempty2": ", Điều chỉnh số lượng tồn kho tại tất cả các cửa hàng về 0, trừ cửa hàng này",
    "whenempty1": "Khi tồn kho <=",
    "MainStoreStockLock": "Khóa Tồn kho tại cửa hàng chính",
    "suretoclosestockwarning": "Bạn có chắc muốn tắt Cảnh báo Tồn kho?",
    "emailreminderstocklow": "Gửi email nhắc nhở khi tồn kho sản phẩm sắp hết, bạn có chắc muốn bật tính năng này?",
    "Setup": "Cài đặt",
    "whenstock2": ", thông báo cho tôi khi số lượng tồn kho thiếu",
    "whenstock1": "Khi còn tồn <=",
    "suretoclosealertsold": "Bạn có chắc muốn tắt Cảnh báo hết hàng?",
    "notifyme": "Thông báo cho tôi khi sản phẩm được bán hết",
    "stocksettingtip3Local": "Sau khi tắt Đồng bộ Tồn kho, số tồn kho của sản phẩm trong mọi cửa hàng sẽ không được cập nhật hàng loạt",
    "stocksettingtip3": "Sau khi tắt Đồng bộ Tồn kho, số lượng tồn kho của sản phẩm trong các cửa hàng sẽ không được cập nhật hàng loạt, và danh sách tồn kho sẽ tự động xóa",
    "stocksettingtip2LocalOld": "Sau khi bật Đồng bộ Tồn kho, số lượng tồn kho của sản phẩm có cùng MSKU sẽ được điều chỉnh giống nhau. Khi có đơn hàng mới hoặc đơn hàng bị hủy, số lượng tồn kho của các sản phẩm có cùng Master SKU sẽ được đồng loạt thay đổi. Lưu ý, trước khi bật cài đặt này, qquá trình đồng bộ hóa sản phẩm và đơn hàng đã được hoàn tất, và số lượng Master SKU đã được điều chỉnh",
    "stocksettingtip2": "Sau khi bật Đồng bộ Tồn kho, số lượng tồn kho các sản phẩm trùng SKU ở các cửa hàng sẽ được điều chỉnh giống nhau. Khi có một đơn hàng mới hoặc một đơn hàng bị hủy, số lượng tồn kho của SKU ở các cửa hàng sẽ đồng loạt cập nhật. Lưu ý, trước khi bật tính năng này, quá trình đồng bộ hóa sản phẩm và đơn hàng đã được hoàn tất, và số lượng tồn kho SKU đã được điều chỉnh",
    "stocksettingtip1Local": "Để tăng tính chính xác số lượng tồn kho, hãy hoàn tất đồng bộ hóa toàn bộ sản phẩm và đơn hàng, sau đó đặt Master SKU trước khi kích hoạt Đồng bộ Tồn kho. Sau đó, Ginee sẽ tự động trừ hoặc cộng thêm Master SKU dựa theo Trạng thái Đơn hàng, và đồng bộ số lượng tồn kho của Master SKU với các cửa hàng đã được liên kết",
    "stocksettingtip1": "Để tăng tính chính xác số lượng tồn kho, hãy hoàn tất đồng bộ hóa toàn bộ sản phẩm và đơn hàng, sau đó đặt Số lượng tồn SKU trước khi kích hoạt Đồng bộ Tồn kho. Sau đó, Ginee sẽ tự động trừ hoặc cộng thêm Số lượng tồn SKU dựa theo Trạng thái Đơn hàng, và đồng bộ số lượng tồn kho với Store SKU đã được tích hợp",
    "monitorsync": "Giám sát và đồng bộ Tồn kho",
    "noteCreateBind": "Lưu ý: Nếu tính năng Tự động Liên kết Master SKU được bật, tính năng này sẽ được thao tác trước",
    "autoCreateSKUValue": "quy tắc tạo mới: Nếu như Store SKU trùng với Master SKU, sản phẩm sẽ tự động liên kết với Master SKU này (sẽ tạo mới Master SKU nếu không trùng). Chỉ áp dụng với sản phẩm của những cửa hàng đã được đồng bộ với Ginee nhưng chưa liên kết với Master SKU",
    "automaticallyCreateTheMasterSKU": "Tự động tạo Master SKU",
    "deleteAutoCreateRules": "Bạn có chắc muốn tắt tính năng Tự động Thêm mới Master SKU?",
    "turnAutoCreateRules": "Sau khi tính năng Tự động Thêm mới Master SKU được bật, những sản phẩm có Store SKU trùng với Master SKU, hệ thống sẽ tự động liên kết sản phẩm. Nếu sản phẩm có Store SKU chưa có trong Master SKU, hệ thống sẽ tự động tạo mới Master SKU",
    "goToDownloadTheRules": "Đi đến [Import/Export-Export all Auto-Bind rules] để tải xuống quy tắc",
    "downloadAllAutoBindRules": "Tải xuống toàn bộ quy tắc Tự động Liên kết",
    "deleteAutoBindRules": "Bạn có chắc muốn tắt cài đặt Tự động Liên kết Master SKU?",
    "turnOnAutoBindRules": "Sau khi Tự động Liên kết Master SKU được bật, với những sản phẩm chưa được liên kết với Master SKU, chúng tôi sẽ tự động liên kết sản phẩm với Master SKU dựa theo quy tắc: Store SKU trùng tên sản phẩm với Master SKU",
    "autoBindSKUValue": "Sau khi kích hoạt cài đặt này, nếu Store SKU trùng tên sản phẩm với Master SKU, sản phẩm sẽ được tự động liên kết, đồng thời vẫn sẽ hỗ trợ tùy chỉnh liên kết tự động. Tính năng này dành cho sản phẩm trong cửa hàng đã được đồng bộ với Ginee nhưng chưa liên kết với Master SKU",
    "automaticallyBindTheMasterSKU": "Tự động kết nối Master SKU",
    "SyncSuccess": "Đồng bộ thành công",
    "AreSureEnableCOD": "Bạn có chắc muốn kích hoạt COD?",
    "StoreName": "Tên Cửa hàng",
    "ExcludeShippingInfo3": "Xác nhận mở { JNEOKE }",
    "ExcludeShippingInfo2": "Sản phẩm được kích hoạt { JNEOKE } sẽ chuyển sang { JNEOKE }",
    "ExcludeShippingInfo1": "Nếu bạn mở { JNEOKE }, { JNEOKEList } sẽ tự động đóng ngay sau đó",
    "NoShippingInfo": "Hiện tại chưa có cửa hàng Shopee nào được tích hợp, hãy tích hợp ngay!",
    "CodNo": "Không",
    "CodYes": "Có",
    "DisabledCODClick": "Bấm vào đây để xem chi tiết",
    "DisabledCODDesc4": "COD của đơn vị vận chuyển J&T được đảm bảo bằng bảo hiểm",
    "DisabledCODDesc3": "Đơn hàng sử dụng phương thức COD",
    "DisabledCODDesc2": "Người mua sử dụng COD với mục đích xấu sẽ bị tự động cho vào danh sách đen",
    "DisabledCODDesc1": "Người bán chọn phương thức COD sẽ có nhiều người mua và đơn hàng hơn",
    "DisabledCODTitle": "Bạn có chắc muốn vô hiệu hóa COD?",
    "PleaseGoToProductEnable": "Chọn riêng từng sản phẩm để kích hoạt các cài đặt vận chuyển mới",
    "UnEnabledTitle": "Sản phẩm không được chọn sẽ bị loại khỏi danh sách sản phẩm hiện tại. Các sản phẩm không áp dụng vận chuyển sẽ bị loại khỏi danh sách và ẩn hiển thị.",
    "CODDisabled": "Vô hiệu hóa COD",
    "CODEnabled": "Kích hoạt COD",
    "COD": "COD",
    "ShippingSupportedDesc": "Với các cài đặt vận chuyển được hỗ trợ bởi Shopee, người mua sẽ được Shopee thông báo về tình trạng giao hàng của đơn hàng của mình",
    "ShopeSupportedLogistics": "Đơn vị vận chuyển được hỗ trợ bởi Shopee",
    "ShippingTopTips": "Chú ý: Bạn có thể quản lý cài đặt vận chuyển của các cửa hàng đã tích hợp tại đây. Chúng tôi khuyến nghị nên để cài đặt vận chuyển giống nhau cho những cửa hàng thuộc cùng một sàn thương mại. Vui lòng kích hoạt  ít nhất một đơn vị vận chuyển",
    "MembershipPage": "Trang Thành viên",
    "BasicLogo": "Logo",
    "Account": "Tài khoản",
    "afterClosingTheAutomaticBindingRules": "Các quy tắc liên kết tự động sau khi tắt sẽ bị xóa và sản phẩm trên các kênh thương mại điện tử sẽ không tự động liên kết với Master SKU",
    "ShippingRuleSettings1": "Cài đặt quy tắc Vận chuyển",
    "TheWarehouseAllocatedByThe": "2. Kho hàng của đơn hàng cần giao đã liên kết với kho Accurate",
    "MskuHasSuccessfullyPushedTo": "1. MSKU đã đẩy thành công đến Accurate",
    "orderRulesToPushToAccurate": "Quy tắc đẩy đơn hàng Accurate như sau:",
    "OnlySupports": "3. Chỉ hỗ trợ \"(\",\"), \"-\", \".\",\"_\" và \"/\"",
    "OnlySupportsLetternumberCombination": "2. Chỉ hỗ trợ chữ cái, số thứ tự",
    "NoMoreThanCharacters": "1. Không vượt quá 30 ký tự",
    "mskuRulesToPushProductTo": "Quy tắc MSKU để đẩy sản phẩm đến Accurate như sau:",
    "ifNoRulesAreAddedThe": "Khi không có quy tắc nào được thêm vào thì sẽ áp dụng theo quy tắc này. Một khi bạn tự thêm quy tắc, kho hàng sẽ được phân bổ theo quy tắc mà bạn đặt",
    "afterTheWarehouseSettingIsModified": "Sau khi chỉnh sửa cài đặt phân bổ kho, kho sẽ được phân bổ theo quy định mới, vui lòng vận hành cẩn thận",
    "afterConfigurationWhenAnOrderContains1": "Sau khi cấu hình, khi một đơn hàng có nhiều sản phẩm, các kho khác nhau có thể được ghép nối dựa theo các sản phẩm khác nhau",
    "particularCommodity": "Sản phẩm đơn",
    "afterConfigurationWhenAnOrderContains": "Sau khi cấu hình, khi một đơn hàng có nhiều sản phẩm thì chỉ có thể gán cho một kho, không thể ghép nhiều kho cùng lúc",
    "overallOrder": "Đơn hàng tổng thể",
    "compartmentDimension": "Kích thước phân kho",
    "afterConfigurationWhenMultipleWarehousedividingRules": "Sau khi cấu hình, khi nhiều quy tắc phân kho được đáp ứng, kho có số ưu tiên nhỏ hơn sẽ được ưu tiên chỉ định để tạo đơn xuất kho và xử lý tồn kho",
    "warehouseWithHighRulePriority": "Kho hàng có quy tắc số ưu tiên lớn",
    "afterConfigurationWhenMultipleWarehouseAllocation": "Sau khi cấu hình, khi nhiều quy tắc phân kho được đáp ứng, kho hàng có đủ tồn kho có sẵn sẽ được ưu tiên tạo đơn xuất kho và xử lý tồn kho",
    "fullyStockedWarehouse": "Kho đầy đủ hàng hóa",
    "priorityOfWarehouseDivision": "Mức ưu tiên phân kho",
    "promptThisSettingAffectsWarehouseAllocation": "Gợi ý: Cài đặt này ảnh hưởng đến các quy tắc phân bổ kho hàng, vui lòng thực hiện một cách thận trọng",
    "temporarilyNotOpen": "Tạm không bật",
    "theSwitchIsNotTurnedOn": "Nút không được bật, quy tắc phân kho sẽ không được thực hiện sau khi tạo thành công",
    "theSwitchOrderSettingsdistributionSettingsorderAssociated": "Nút \"Cài đặt đơn hàng - Cài đặt phân phối - tồn kho liên kết với đơn hàng\" chưa được bật",
    "separateWarehouseSetting": "Cài đặt phân kho",
    "afterAddingRulesByYourselfWhen": "Sau khi tự thêm các quy tắc và chúng được đáp ứng, thì kho hàng sẽ được phân bổ theo quy tắc bạn đặt ra trước",
    "matchAccordingToTheWarehouseIn": "Khớp theo kho hàng trong phiếu nhập kho mới nhất của MSKU theo mặc định",
    "downloadApp": "Tải App xuống",
    "manageYourStoreAnytimeAnywhereGinee": "Quản lý cửa hàng của bạn mọi lúc mọi nơi, Ginee App chính thức ra mắt rồi này",
    "shippingMethodPriorityPickUp": "Ưu tiên phương thức vận chuyển: Shipper đến lấy hàng > Người bán gửi hàng tại bưu cục > Tự giao hàng",
    "logisticsCompaniesThatDoNotSet": "Các đơn vị vận chuyển chưa cài đặt quy tắc giao hàng sẽ giao hàng dựa trên các phương thức giao hàng được hỗ trợ",
    "pleaseSelectALogisticsCompanyFor": "Vui lòng chọn đơn vị vận chuyển cho đơn hàng Akulaku",
    "pullThreepartyWarehouseForStorage": "Kéo tồn kho của kho bên thứ ba",
    "expired": "Đã hết hạn",
    "deactivated": "Hạn chế",
    "ecommercePlatform": "Thương trường",
    "selfbuiltStation": "Webstore",
    "financialSoftware": "Phần mềm tài chính",
    "originalName": "Gốc",
    "editStore": "Chỉnh sửa cửa hàng",
    "pullData": "Kéo dữ liệu",
    "reenable": "Bật lại",
    "deleteStore": "Xoá kho",
    "areyousureyouwanttodeletethisstore": "Comment Bạn có chắc muốn xoá cửa hàng này không?",
    "TheBindingRelationshipBetweenThe": "1. Mối quan hệ ràng buộc giữa các sản phẩm kênh của cửa hàng và sản phẩm chính sẽ hủy.\n2. Thông tin danh sách sản phẩm kênh của cửa hàng sẽ bị xóa trong Ginee.\n3. Tồn kho trong Danh sách tồn kho sẽ không được đẩy qua xửa hàng.\n4. Bạn sẽ không thể xem thông tin đặt hàng cho cửa hàng này.\n5. Bạn không thể tìm thấy cửa hàng do mô-đun cài đặt cửa hàng.",
    "theDefinitionOfDisabledIsStore": "Bị hạn chế: Cửa hàng bị hạn chế do tài nguyên cửa hàng vượt quá giới hạn. Trạng thái này sẽ không ảnh hưởng đến doanh số của cửa hàng trong kênh. Các sản phẩm kênh của cửa hàng sẽ tự động được hủy liên kết với Master SKU; sản phẩm/đơn hàng của cửa hàng và các dữ liệu khác sẽ không được hiển thị trong Ginee ERP",
    "authorizationInfo": "Thông tin:",
    "configManagement": "Quản lý cấu hình",
    "germany": "Đức",
    "belgium": "BỉStencils",
    "italy": "Ý",
    "poland": "Ba Lan",
    "authorized": "Đã ủy quyền",
    "authTips": "Sau khi cửa hàng của bạn kết nối thành công, bạn có thể nhìn thấy sản phẩm và đơn hàng của bạn đồng bộ thành công trên trang Sản phẩm và Đơn hàng",
    "youDoNotHavePermissionTo": "Bạn không có quyền truy cập cửa hàng, vì vậy bạn không thể chỉnh sửa giá của cửa hàng này.",
    "pleaseWaitPatientlyWhileTheStore": "Lưu trữ dữ liệu kéo, vui lòng kiên nhẫn chờ đợi",
    "startPullingTheStoreDataIt": "Bắt đầu kéo dữ liệu cửa hàng, dự kiến sẽ mất vài phút, xin vui lòng xem lại tình hình đồng bộ dữ liệu cửa hàng",
    "editSucceeded": "Chỉnh sửa thành công",
    "AreYouSureYouWantToDeleteTheAuthorization": "Comment Cô có chắc là sẽ xóa lệnh không?",
    "areYouSureToModifyThe": "Xác nhận sửa cài đặt phân kho chứ?",
    "CharactersLong": "Dài 10-12 ký tự",
    "pleaseStartWithTwoLettersOr": "Vui lòng bắt đầu bằng hai chữ cái hoặc số \"1\" và bao gồm 10 số",
    "pleaseEnterTheCorrectAkulakuTracking": "Vui lòng nhập đúng định dạng số vận đơn Akulaku",
    "transportStockChange": "Tồn kho đang vận chuyển",
    "tipavailablestock": "Lượng hàng có thể được bán trong Kênh ， Hàng có sẵn = Kho hàng-Kho dự phòng-Kho hàng đã khóa-Lượng hàng dành cho khuyến mãi",
    "tiplockedStock": "Hàng đang chờ hoàn thành nhưng chưa được vận chuyển, Đơn đặt hàng mới trả tiền hoặc đơn hàng chưa thanh toán mới được đồng bộ hóa với Ginee sẽ thêm vào Kho hàng đã khóa",
    "tipsafetyStock": "Đặt giới hạn tồn kho tối thiểu và nhận thông báo qua email để bổ sung khi Hàng có sẵn của sản phẩm đạt đến số lượng đã đặt",
    "tipspareStock": "Tổng lượng Hàng dự trữ trong kho thực tế, sẽ không được bán trong kênh.",
    "tipwarehouseStock": "Tổng kho thực tế trong kho của bạn, Kho dự trữ = Kho dự phòng + Kho đã khóa + Kho có sẵn + Kho khuyến mãi",
    "totalAvailableStock": "Tổng tồn kho có sẵn",
    "totalPromotionStock": "Tổng kho khuyến mãi",
    "totalLockedStock": "Tổng tồn kho bị khóa",
    "totalSpareStock": "Tổng kho dự phòng",
    "totalWarehouseStock": "Tổng tồn kho",
    "ImageName": "Hình ảnh & Tên",
    "case": "Trường hợp",
    "purchasePricecalrules": "Giá mua trung bình = Tổng giá mua trong nước / Hàng trong kho",
    "ISKUmeaning": "MSKU là viết tắt của Master SKU",
    "nochange": "Hàng có sẵn không thay đổi, vì vậy hàng trong cửa hàng không cần cập nhật",
    "manulupdate": "Không thay đổi",
    "updatetimetip": "Thời gian cập nhật",
    "reasonforfailed": "Lý do không thành công: Sản phẩm đã bị xóa hoặc bị cấm khỏi kênh bán hàng hoặc đang trong chiến dịch khuyến mại (quá trình đồng bộ hóa có thể thành công sau khi chương trình khuyến mại kết thúc)",
    "TotalPromotionStock": "Tổng kho khuyến mãi",
    "StoreSKU": "Store SKU",
    "tippromotionStock": "Lượng hàng Khuyến mãi là tổng lượng hàng dành riêng cho các chương trình khuyến mãi Chiến dịch hoặc Flash sale",
    "remark": "Nhận xét",
    "transportStock": "Tồn kho đang vận chuyển",
    "warehouseStockChange": "Kho:",
    "spareStockChange": "Bổ sung:",
    "promotionStockChange": "Khuyến mại ：",
    "lockedStockChange": "Đã khóa ：",
    "availableStockChange": "Có sẵn ：",
    "oversell": "Sản phẩm đang bán vượt mức tồn kho, vui lòng điều chỉnh tồn kho. Bây giờ cập nhật Kho hàng trong kênh về 0.",
    "Seethedetails": "Xem chi tiết",
    "warehouseStockerror": "Lượng hàng phải từ 0 đến 999.999",
    "warehouseStockrequired": "Lượng hàng phải từ 0 đến 999.999",
    "remarkplaceholder": "Vui lòng nhập lý do chỉnh sửa, không được dưới 300 ký tự.",
    "availablestockshort": "Có sẵn",
    "promotionstockshort": "Khuyến mại",
    "lockedStockshort": "Đã khóa",
    "spareStockshort": "Bổ sung",
    "warehouseStockshort": "Kho",
    "priceplaceholder": "Giá đầu vào",
    "Barcodeplaceholder": "Hỗ trợ chữ cái, số và -_",
    "weightplaceholder": "Phạm vi là 5.000.000",
    "upgradeResultClose": "Đóng",
    "upgradeResultViewNow": "Xem ngay bây giờ",
    "upgradeResultRetry": "Thử lại",
    "upgradeResultFailedTitle": "Không thành công nâng cấp quản lý khoảng không quảng cáo！",
    "upgradeResultSuccessTitle": "Nâng cấp thành công lên Inventory Management Pro！",
    "upgradeModalUpgradeNow": "Nâng cấp ngay bây giờ",
    "upgradeModalCancel": "Huỷ bỏ",
    "upgradeModalAbout": "Giới thiệu về Quản lý khoảng không quảng cáo Pro",
    "january5th": "sẽ được nâng cấp tự động vào ngày 5 tháng 1 năm 2021.",
    "syncmanually": "đồng bộ hóa các đơn đặt hàng",
    "upgradeModalFooterTitle": "Vui lòng {syncorder} theo cách thủ công trước khi nâng cấp, vì vậy các đơn đặt hàng hiện tại sẽ khấu trừ khoảng không quảng cáo. Tài khoản Ginee với Phiên bản Cơ bản sẽ được {cập nhật}",
    "upgradeModalLabelStockWarning": "Cảnh báo hàng tồn kho:",
    "upgradeModalLabelSoldOut": "Bán hết:",
    "upgradeModalLabelAll": "Tất cả:",
    "upgradeModalContentTitle": "Xem số lượng Master SKU hiện có của bạn:",
    "upgradeModalTitleSub": "Inventory Management Pro là phiên bản nâng cao với {val} các tính năng mới được thêm vào Quản lý khoảng không quảng cáo.",
    "upgradeModalTitle": "Nâng cấp lên Inventory Management Pro",
    "upgradeBtn": "Nâng cấp miễn phí lên Pro ， Xem ngay!",
    "setstock": "Đặt cổ phiếu",
    "setsafetyStock": "Đặt kho an toàn",
    "setspareStock": "Đặt kho dự phòng",
    "setwarehouseStock": "Đặt kho hàng",
    "skusselected": "SKU được chọn",
    "skuselected": "SKU được chọn",
    "skus": "Mã hàng",
    "Viemore": "Lượt xem",
    "VariationStatus": "Trạng thái thuộc tính",
    "createnext": "Tạo & Tiếp theo",
    "sizelimittip": "Phải từ 1-999.99!",
    "pricelimittip": "Giá mua phải từ 0-1.000.000.000!",
    "weightlimittip": "Trọng lượng phải từ 1-5.000.000!",
    "validatorsize": "Phải từ 1-999.99",
    "Barcodelengthtip": "Mã vạch không được nhiều hơn 30 ký tự",
    "Barcodeerror": "Mã vạch chỉ hỗ trợ chữ cái, số và -_",
    "mustbiggerthan": "Không được nhỏ hơn (Kho dự phòng + Kho đã khóa)",
    "mustlessthan": "Nên nhỏ hơn (Kho dự trữ-Kho đã Khoá)",
    "PackageSize": "kích cỡ gói",
    "inputsafetyStock": "Phải nằm trong khoảng từ 0 đến 999,999",
    "inputspareStock": "Phải nằm trong khoảng từ 0 đến 999,999",
    "inputwarehouseStock": "Phải nằm trong khoảng từ 0 đến 999,999",
    "safetyStockisRequired": "Tồn kho an toàn Nên từ 0 ~ 999,999!",
    "spareStockisRequired": "Kho dự phòng phải từ 0 đến 999,999!",
    "warehouseStockisRequired": "Kho dự trữ nên nằm trong khoảng từ 0 đến 999,999!",
    "namerequired": "Tên phải từ 1 - 160 ký tự",
    "Barcode": "Mã vạch",
    "addprice": "Giá mua trong nước (Rp)",
    "averageprice": "Giá mua trung bình (Rp)",
    "ItemInformation": "Thông tin mục",
    "stockimage": "Định dạng: jpg, jpeg, png. Kích thước hình ảnh: dưới 5MB",
    "noresult": "Không thay đổi",
    "EMPTY": "Sản phẩm trong cửa hàng không được ràng buộc",
    "FAILEDcontent": "Không thành công: Kênh không cho phép chỉnh sửa tồn kho này",
    "inventorylogtip": "Ginee sẽ tự động thử cập nhật lại tồn kho cho các cửa hàng không thành công. Bạn có thể xem chi tiết cập nhật để kiểm tra thử lại hoặc nếu bạn muốn thử lại theo cách thủ công.",
    "FAILED": "Thất bại",
    "PART_FAILED": "Một số không thành công",
    "UpdateCompleted": "Hoàn thành cập nhật",
    "StockWarning": "Cảnh báo tồn kho",
    "EXPIRED": "Thử lại không thành công",
    "RETRY_SUCCEEDED": "Thử lại thành công",
    "SUCCEEDED": "Thành công",
    "NEED_RETRY": "Không thành công, đang thử lại ...",
    "PROCESSING": "Đang xử lý",
    "UpdateDetails": "Chi tiết cập nhật kho lưu trữ",
    "ManualUpdate": "Cập nhật thủ công",
    "StoreUpdateResult": "Kết quả cập nhật cửa hàng",
    "Operator": "Nhà điều hành",
    "Latest": "Muộn nhất",
    "Movement": "Biến động",
    "Original": "Ban đầu",
    "updateDatetime": "Thời gian cập nhật",
    "StockUpdateFailed": "Cập nhật không thành công",
    "StockSuccessfullyUpdated": "Cập nhật thành công",
    "Updating": "Đang cập nhật",
    "UpdateToStore": "Cập nhật lên cửa hàng",
    "OrderCancel": "Đơn đặt hàng {OrderId} đã bị Hủy, Lý do: {ReasonInfo}",
    "NewPaidOrder": "Đơn hàng trả phí mới",
    "UserEditStock": "Người dùng chỉnh sửa tồn kho",
    "System": "Hệ thống",
    "ChangeReason": "Thay đổi lý do",
    "OriginalStock": "Tồn kho ban đầu",
    "createDatetime": "Giờ tạo",
    "StockTime": "Thời gian",
    "StockUserName": "tên tài khoản",
    "StockChangeHistory": "Lịch sử biến động tồn kho",
    "clicktoset": "Bấm vào đây",
    "synclosed": "Đồng bộ hóa tồn kho đã bị tắt, việc bạn sửa đổi số lượng tồn kho của Master SKU sẽ không ảnh hưởng đến số lượng tồn kho bán trên kênh; bạn có muốn dùng đồng bộ tồn kho?",
    "syncopend": "Tính năng đồng bộ hóa Kho hàng đã được bật và khi số lượng hàng tồn kho của Master SKU thay đổi, số lượng hàng hóa được bán bởi nền tảng cũng sẽ thay đổi theo",
    "skurelation": "Vui lòng nhập SKU kênh để thiết lập mối quan hệ ràng buộc giữa SKU và Master SKU. Khi tạo Master SKU, SKU trong kênh bán hàng có cùng tên sẽ được tự động liên kết",
    "stockrequired": "Tồn kho phải từ 0-999.999!",
    "inventorySkurequired": "Master SKU không được để trống!",
    "inputpurchasePrice": "Giá đầu vào",
    "inputname": "Tên đầu vào",
    "inputstock": "Chỉ số nguyên 0 - 999.999",
    "inputinventorySku": "Nhập Master SKU",
    "addsuccess": "Thêm thành công!",
    "deletesuccess": "Đã xóa thành công!",
    "editsuccess": "Đã chỉnh sửa thành công!",
    "afterdelete": "Sau khi xóa Master SKU, mối quan hệ ràng buộc với SKU trong kênh bán hàng cũng sẽ bị xóa",
    "suretodelete": "Bạn có chắc chắn muốn xóa Master SKU không?",
    "products": "Master SKU",
    "failimport": "Nhập không thành công:",
    "sucessimport": "Nhập thành công:",
    "totalimport": "Tổng đã nhập:",
    "picture": "Hình ảnh sản phẩm",
    "skuList": "SKU (Store SKU)",
    "purchasePrice": "Giá mua trong nước",
    "nameStock": "Tên",
    "Stock": "Tồn kho",
    "SoldOut": "Bán hết",
    "AddInventorySKU": "Thêm Master SKU",
    "seacrh": "Tìm kiếm",
    "ExportAll": "Xuất tất cả",
    "Exportbyselected": "Xuất theo lựa chọn",
    "DownloadImportTemplate": "Tải xuống Mẫu Nhập",
    "ImportInventorySKU": "Nhập Master SKU",
    "Import": "Nhập",
    "ok": "Đồng ý",
    "editsku": "Chỉnh sửa Master SKU",
    "addsku": "Thêm Master SKU",
    "skuseacrh": "Tìm kiếm Master SKU 、 SKU 、 Title",
    "skuplaceholder": "Nhập SKU (Store SKU của bạn)",
    "accordingToTheQueryConditionsYou": "Theo các điều kiện truy vấn (kiểm tra) bạn đặt, phí vận chuyển ước tính từ  công ty vận chuyển như sau:",
    "calculationResults": "Kết quả tính toán",
    "destination": "Nơi nhận",
    "shipFrom": "Nơi gửi",
    "calculationFormula": "Công thức tính toán",
    "checkShippingFee": "Truy vấn phí vận chuyển",
    "addAddress": "Thêm địa chỉ",
    "setAsDefault": "Đặt làm mặc định",
    "theBindingRelationshipBetweenJtExpress": "Mối quan hệ ràng buộc giữa J&T Express (ID) và Ginee đã được hủy và bạn sẽ không thể sử dụng các dịch vụ vận chuyển liên quan trong Ginee nữa, xin lưu ý.",
    "onceDeletedYouWillNoLonger": "Sau khi xóa, bạn sẽ không thể sử dụng dịch vụ vận chuyển J&T Express (ID) trong Ginee được nữa, và các đơn hàng đã đặt sẽ không được làm trống, vui lòng thao tác cẩn thận",
    "confirmToDelete": "Xác nhận xóa?",
    "shippingSettings1": "Cài đặt vận chuyển",
    "reasonTheAuthorizationInformationIsIncorrect": "Nguyên nhân: Thông tin ủy quyền không chính xác, vui lòng sửa đổi và ủy quyền lại",
    "youCanUseTheJtExpress": "Bạn có thể sử dụng dịch vụ J&T Express (ID) để hỏi về chi phí vận chuyển ước tính> đặt đơn> lấy vận đơn> in vận đơn> theo dõi vận chuyển",
    "maxCharactersOnlyNumbers": "Không vượt quá {length} ký tự, chỉ hỗ trợ số thứ tự và chữ cái",
    "requiredToFillInPleaseGet": "Bắt buộc điền, vui lòng lấy và điền thông tin theo phần trợ giúp ủy quyền",
    "withQuickAuthorizationYouDontNeed": "Với ủy quyền nhanh, không cần liên hệ với J&T Express (ID) để lấy thông tin cần thiết cho việc ủy quyền, hỗ trợ sử dụng Ginee",
    "clickToViewAuthorizationInstruction": "Nhấn để xem trợ giúp ủy quyền",
    "clickAuthorizeToBindAndIt": "Nhấp vào [Ủy quyền] để liên kết thành công, hỗ trợ đặt đơn/ nhận số vận đơn/ truy vấn phí vận chuyển/ truy vấn theo dõi vận chuyển",
    "toUseAStandardAuthorizationPlease": "Để sử dụng ủy quyền tiêu chuẩn, vui lòng liên hệ với J&T Express (ID) để biết thông tin cần thiết cho việc ủy quyền",
    "recomend": "Giới thiệu",
    "standardAuthorization": "Ủy quyền tiêu chuẩn",
    "quickAuthorization": "Ủy quyền nhanh",
    "theSystemDetectsThatChannelProducts": "Hệ thống phát hiện rằng SKU của sản phẩm kênh trống. Vui lòng xác nhận xem có sử dụng tên quy tắc và tên sản phẩm cho MSKU không?",
    "yesApplyToAllProducts": "Có, áp dụng cho tất cả sản phẩm",
    "productInbound": "Nhập kho sản phẩm",
    "manualOutbound": "Xuất kho thủ công",
    "salesOutbound": "Xuất kho bán hàng",
    "theNumberOfConnectedStoresThat": "Số lượng cửa hàng được kết nối đã vượt quá giới hạn gói có hiệu lực, vui lòng tiếp tục đăng ký gói hoặc liên hệ bộ phận chăm sóc khách hàng để nâng cấp gói trước khi kích hoạt cửa hàng;",
    "subscriptionPackage": "Gói đăng ký",
    "theStoreHasBeenActivated": "Cửa hàng đã được kích hoạt!",
    "deleteSuccess": "Xóa thành công",
    "fulfillConfirmDisconnectContent": "Sau khi bị xóa, kho {fulfillment} tương ứng và dịch vụ fulfillment sẽ không khả dụng, nếu bạn muốn kích hoạt lại, vui lòng ủy quyền lại trước",
    "unfold": "Mở rộng",
    "collapse": "Thu gọn",
    "expiredStatusComingSoon": "Sắp hết hạn",
    "ForLogisticCompanyPleaseAdd": "6. Đối với các công ty vận chuyển, vui lòng điền vào các công ty vận chuyển tùy chọn cho các đơn thủ công mới",
    "WarehouseAsWarehouseNameNot": "4. Kho hàng là tên kho, không phải mã số kho",
    "jnePleaseFillTheLogisticThat": "JNE (vui lòng điền vào công ty vận chuyển tùy chọn cho đơn thủ công mới)",
    "whetherBlacklist": "Có phải danh sách đen không",
    "pleaseSetupShipmentSettingsFirstBefore": "Vui lòng đặt cài đặt giao hàng trước rồi chọn địa chỉ lấy hàng",
    "authorizeNow": "Đến ủy quyền",
    "pleaseRecheckAfterAuthorizeLogistic": "Vui lòng sau khi ủy quyền đơn vị vận chuyển, hãy kiểm tra lại",
    "temporaryNotAuthorize": "Tạm chưa ủy quyền",
    "oddNumbers": "Mã đơn",
    "wholesalePrice": "Giá bán buôn",
    "addWholesalePrice": "Thêm giá bán buôn",
    "maxNumber": "Số tối thiểu không được lớn hơn hoặc bằng số tối đa",
    "maxNumberCannotBeLessThan": "Số tối đa không được nhỏ hơn hoặc bằng số tối thiểu",
    "theWholesalePriceCannotBeHigher": "Giá bán buôn không được cao hơn giá bán của sản phẩm",
    "theWholesalePriceOfCannot": "Giá bán buôn của {a} không được cao hơn giá bán buôn của {b}",
    "wholesalePricesAreOnlyAvailableTo": "Giá bán buôn chỉ có sẵn cho tất cả các thuộc tính với cùng một mức giá",
    "productNameMinCharacter": "Tên sản phẩm tối thiểu  {min}  ký tự",
    "productNotCanNotLessThan": "Hình ảnh sản phẩm tối thiểu là {min}",
    "breadCrumbProductList": "Sản phẩm/{channel}",
    "storePermission": "Quyền truy cập Cửa hàng",
    "channelPermission": "Quyền truy cập Kênh",
    "masterProductPermission": "Quyền truy cập Sản phẩm Chính",
    "priceManagementPermission": "Quyền truy cập Quản lý giá",
    "toEnsureTheAccuracyOfAvailable": "Để đảm bảo rằng kho hàng có sẵn là chính xác, vui lòng nhấp vào \"Cập nhật kho hàng có sẵn\" để nhận được giá trị hàng hóa mới nhất",
    "masterUpdateAvailableStock": "Cập nhật kho hàng có sẵn",
    "pleaseSelectCompleteReceiverArea": "Vui lòng chọn khu vực nhận hàng hoàn chỉnh",
    "toCalculateShippingCostNeedTo": "Để tính được phí vận chuyển thì cần điền thông tin vận chuyển và cân nặng",
    "calculateShippingFee": "Tính phí vận chuyển",
    "detailAddress": "Địa chỉ chi tiết",
    "needToSelectAreaDimension": "Cần chọn phạm vi khu vực",
    "maximumCharactersNumbersOnly": "Tối đa 30 ký tự, chỉ hỗ trợ số thứ tự",
    "maximumCharactersLettersOnly": "Tối đa 30 ký tự, chỉ hỗ trợ chữ cái",
    "site": "Quốc gia",
    "gineeErrorUrllabelEmptyPleaseTry": "Lỗi Ginee: URL vận đơn bị trống. Vui lòng đồng bộ lại đơn hàng",
    "singleProductWeight": "Trọng lượng sản phẩm đơn lẻ",
    "calculate": "Tính toán",
    "youDontHaveThisStorePermission": "Bạn không có quyền truy cập này",
    "logisticsServices": "Dịch vụ Logistics",
    "theMaximumLengthIsCharacters": "Độ dài tối đa là {maxLength} ký tự",
    "averagePurchasePriceCurrentInbound": "Giá mua trung bình = (số lượng nhập hàng hiện tại* giá mua nhập hàng hiện tại + số lượng ban đầu * giá mua ban đầu) / (số lượng nhập hàng hiện tại + số lượng ban đầu)",
    "tiktokMassDeleteTip": "Do giới hạn API Tiktok, số lượng có thể xóa tối đa chỉ 20 sản phẩm 1 lần, vui lòng chọn lại",
    "validityofauthorization": "Thời hạn hiệu lực ủy quyền",
    "channelProductMassEdit": "Sản phẩm/{channel}/Chỉnh sửa hàng loạt",
    "dataPermission": "Quyền dữ liệu",
    "ruleTypeSpecifyMsku": "Loại quy tắc: Chỉ định MSKU",
    "ruleTypeShopRules": "Loại quy tắc: Quy tắc cửa hàng",
    "addNewRules": "Thêm quy tắc mới",
    "delete1": "Xóa",
    "requiredItemLockSettingIf": "Bắt buộc điền, cài đặt khóa - khi cài đặt khóa tồn kho của cửa hàng chính được bật, khi tồn kho MSKU trong \"Sách lược đẩy\" nhỏ hơn hoặc bằng \"Ngưỡng\", lượng hàng tồn kho khả dụng đẩy cho các cửa hàng khác ngoại trừ cửa hàng đã chọn là 0",
    "setupPushStore": "Cài đặt cửa hàng đẩy",
    "requiredItemPleaseEnterAnInventory": "Bắt buộc điền, vui lòng nhập giá trị tồn kho lớn hơn hoặc bằng 0 và nhỏ hơn hoặc bằng 99999",
    "setStockThresholds": "Đặt Ngưỡng tồn kho",
    "pleaseEnterTheMskuToBe": "Vui lòng nhập MSKU để thêm vào, sẽ tốn ít thời gian để lưu nếu ngắt nhiều dòng, các bạn kiên nhẫn chờ nhé",
    "manualSelection": "Lựa chọn thủ công",
    "textRecognition": "Nhận dạng văn bản",
    "addingMethod": "Phương pháp thêm",
    "specifyMsku1": "Chỉ định MSKU",
    "allMskus": "Tất cả MSKU",
    "addedMainStoreLock": "Đã thêm khóa cửa hàng chính",
    "afterTurnOnPleaseSetUp": "Sau khi bật, hãy đảm bảo đặt quy tắc khóa cửa hàng chính, chỉ có thể đặt một quy tắc khóa hàng tồn kho của cửa hàng chính cho một MSKU duy nhất. Sau khi tắt cài đặt này, tất cả các quy tắc chính mặc định của cửa hàng sẽ thất bại",
    "turnOnMainStoreLocked": "Bật khóa hàng tồn kho của cửa hàng chính",
    "lockSettings": "Khóa cài đặt",
    "clickToView": "Bấm để xem",
    "addMsku1": "Thêm MSKU",
    "deleteMsku": "Xóa MSKU",
    "masterProductPicture": "Sản phẩm chính và hình ảnh",
    "pushMsku": "Đẩy MSKU",
    "ruleType": "Loại quy tắc",
    "ruleInformation": "Thông tin quy tắc",
    "massAdd": "Thêm hàng loạt",
    "add1": "Thêm",
    "addNewRule": "Thêm quy tắc mới",
    "mainStoreLocked": "Khóa cửa hàng chính",
    "operateBy": "Người thao tác",
    "operationModule": "Mô-đun hoạt động",
    "tipOnlySupportForQueryingOperation": "Mẹo: Chỉ hỗ trợ kiểm tra nhật ký hoạt động trong 30 ngày qua",
    "theRuleWasDeletedSuccessfully": "Quy tắc đã xóa thành công",
    "theRuleWasSavedSuccessfully": "Quy tắc đã lưu thành công",
    "ruleDisabledSuccessfully": "Đã tắt quy tắc thành công",
    "afterEnablingTheInventoryWillBe": "Sau khi bật, tồn kho sẽ được tính toán và đẩy đến cửa hàng kênh theo quy tắc hiện hành",
    "areYouSureYouWantTo1": "Bạn có chắc chắn muốn bật quy tắc này không?",
    "afterDeletionTheRulesWillNo": "Sau khi xóa, các quy tắc sẽ không còn hỗ trợ quản lý và sử dụng",
    "afterDisablingTheInventoryWillNo": "Sau khi bật, tồn kho sẽ không còn được tính và đẩy đến cửa hàng kênh theo quy tắc hiện hành",
    "doYouConfirmToTurnOff": "Xác nhận cấm quy tắc?",
    "ifTheCustomRuleIsDisabled": "Nếu quy tắc tùy chỉnh bị tắt, tồn kho MSKU sẽ không còn được đẩy đến kênh nữa",
    "afterCheckingIfACustomRule": "Sau khi kiểm tra, nếu quy tắc tùy chỉnh được thêm vào MSKU và được bật, tồn kho sẽ được tính theo quy tắc tùy chỉnh và được đẩy đến cửa hàng kênh",
    "afterAddingACustomRuleTo": "Sau khi thêm quy tắc tùy chỉnh vào MSKU, tồn kho không còn được đẩy theo quy tắc mặc định",
    "applicationSettings": "Cài đặt ứng dụng",
    "newMskuAddedToWarehouseLater": "MSKU mới: Được thêm vào kho muộn hơn thời gian kích hoạt",
    "allMskusExcludingMskuThatAdded": "Tất cả MSKU (không bao gồm cả các MSKU được thêm sau)",
    "allMskusIncludingMskuThatAdded": "Tất cả MSKU (bao gồm cả các MSKU được thêm sau)",
    "newStoresStoresWhichAuthorizationTime": "Cửa hàng mới: cửa hàng có thời gian ủy quyền muộn hơn thời gian kích hoạt",
    "allAuthorizedStoresConnectedStoresWhose": "Tất cả các cửa hàng được ủy quyền: các cửa hàng được liên kết mà thời gian ủy quyền sớm hơn thời gian kích hoạt",
    "allConnectedStoresExcludingNewStores": "Tất cả các cửa hàng đã liên kết, không bao gồm cả các cửa hàng mới được ủy quyền sau này",
    "allConnectedStoresIncludingNewStores": "Tất cả các cửa hàng đã liên kết, bao gồm cả các cửa hàng mới được ủy quyền sau này",
    "pushRatio": "Tỷ lệ đẩy",
    "availableStockTransitStock": "Tồn kho có sẵn + Tồn kho vận chuyển",
    "allWarehousesWithMskusInInventory": "Tất cả các kho đã nhập kho MSKU và có trạng thái được kích hoạt (không bao gồm các kho mới được thêm vào sau)",
    "newWarehouseWarehouseWhoseCreationTime": "Kho hàng mới: kho hàng có thời gian tạo muộn hơn thời gian kích hoạt",
    "allWarehousesWithMskuThatHave": "Tất cả các kho đã nhập kho MSKU và có trạng thái được kích hoạt (bao gồm các kho mới được thêm vào sau)",
    "ruleStatus": "Trạng thái quy tắc",
    "noteThisTimeIsTheTime": "Lưu ý: Thời gian này là lúc \"Cài đặt tồn kho\" - \"Giám sát và đồng bộ tồn kho\" được bật",
    "activateTime": "Thời gian bật",
    "viewFailureData": "Xem dữ liệu thất bại",
    "backToRuleList": "Quay lại danh sách quy tắc",
    "fail": "Thất bại",
    "sucess": "Thành công",
    "dataProcessingCompleted": "Xử lý dữ liệu hoàn tất",
    "saveCurrentlyNotActive": "Lưu, hiện chưa được bật",
    "afterActivatePleaseAddMskuAs": "Sau khi được bật, hãy thêm MSKU càng sớm càng tốt, nếu không quy tắc sẽ không hoạt động bình thường",
    "areYouSureWantToSave": "Xác nhận lưu và bật quy tắc chứ?",
    "stockType1": "Loại tồn kho",
    "warehouseName1": "Tên kho hàng",
    "specifyMskuAfterCreatingTheRule": "Chỉ định MSKU (sau khi tạo quy tắc, bạn cần phải tự thêm MSKU, chỉ MSKU đã thêm mới được đẩy theo quy tắc đã đặt)",
    "storeRulesAfterCreatingTheRules": "Quy tắc cửa hàng (sau khi tạo quy tắc, bạn không cần thêm MSKU, các SKU kênh đã liên kết với MSKU trong cửa hàng sẽ được đẩy theo quy tắc đã đặt)",
    "tipsWhenAddingRulesIn": "Mẹo: Khi thêm quy tắc hàng loạt, tên quy tắc sẽ được thêm tự động bởi hệ thống; 2. Khi thêm quy tắc hàng loạt, chỉ thông tin quy tắc được lưu lại, và sau khi lưu, vui lòng đặt các MSKU áp dụng trong danh sách quy tắc; 3. Có thể thêm tối đa 100 mỗi lần",
    "clickToViewMsku": "Bấm để xem MSKU",
    "showAllStore": "Hiển thị tất cả cửa hàng",
    "showAllWarehouses": "Hiển thị tất cả kho hàng",
    "executeTheRules": "Thực hiện các quy tắc",
    "fixedValue": "Giá trị cố định",
    "storeRule": "Quy tắc cửa hàng",
    "specifyMsku": "Chỉ định MSKU",
    "operation": "Thao tác",
    "createBy": "Tạo bởi",
    "ratioFixValue": "Tỷ lệ / Giá trị cố định",
    "ruleName": "Tên quy tắc",
    "exactMatch": "Khớp hoàn toàn",
    "multipleMskusSeparatedBySpacesMax": "Nhiều MSKU được phân tách bằng dấu cách, tối đa 50",
    "pleaseFillInTheCompleteRecipient": "Vui lòng điền địa chỉ người nhận đầy đủ",
    "pleaseSelectAPickupAddress": "Vui lòng chọn địa chỉ lấy hàng",
    "addMainStoreLock": "Thêm khóa cửa hàng chính",
    "editMainStoreLock": "Chỉnh sửa khóa cửa hàng chính",
    "ratio": "Tỉ lệ",
    "thisAddressDoesNotCurrentlySupport": "Địa chỉ này hiện không hỗ trợ tính phí vận chuyển",
    "tExpressIdIsNotAuthorized": "J&T Express ID không được ủy quyền, việc hủy bỏ sẽ không thông báo cho công ty vận chuyển",
    "channelSku": "SKU kênh",
    "inventoryPushRulesMainStore": "Quy tắc đẩy tồn kho/ khóa tồn kho cửa hàng chính",
    "inventoryPushRuleOperationLog": "Quy tắc đẩy tồn kho/ nhật ký thao tác",
    "keywordsOfOperationContent": "Từ khóa của nội dung thao tác",
    "pleaseEnterMskuPleaseWrapMultiple": "Vui lòng nhập MSKU, vui lòng xuống hàng nếu có nhiều MSKU",
    "skuExactSearch": "Tìm kiếm chính xác theo MSKU",
    "viewMskuApplicationRules": "Xem quy tắc đẩy",
    "showAllStores": "Hiển thị tất cả cửa hàng",
    "bindingCommodityInformation": "Liên kết thông tin hàng hóa",
    "analogComputation": "Tính toán mô phỏng",
    "unmatchedChannelGoods": "Sản phẩm kênh không có ghép nối",
    "normal": "Bình thường",
    "viewRules": "Xem quy tắc",
    "promptThisFunctionSupportsViewingThe": "Gợi ý: Tính năng này cho phép xem các quy tắc hiện tại MSKU thực sự sử dụng khi đẩy hàng tồn kho và xem tối đa 50 MSKU mỗi lần",
    "newStoresWithoutSubsequentAuthorization": "(Không bao gồm cửa hàng mới ủy quyền sau này)",
    "newStoreWithSubsequentAuthorization": "(Bao gồm cửa hàng mới ủy quyền sau này)",
    "allConnectedStores": "Tất cả các cửa hàng được kết nối",
    "pleaseTurnOnTheInventorySynchronization": "Vui lòng bật nút đồng bộ tồn kho trong menu cài đặt hàng tồn kho trước khi thao tác",
    "Ruledetails": "Chi tiết quy tắc",
    "Default": "Mặc định",
    "thereAreDuplicates": "Có một sự trùng lặp",
    "PleaseentertheMSKU": "Vui lòng nhập MSKU cần thêm, nhiều ngắt dòng, không quá 200 ký tự trên một dòng, hệ thống sẽ tự động cắt bớt nếu vượt quá",
    "noNeedToPush": "Không cần thúc đẩy",
    "mskuDoesNotExist": "MSKU không tồn tại",
    "mskuInventorypushInventory": "Tồn kho MSKU/ Tồn kho Đẩy",
    "proportional": "Theo tỷ lệ",
    "IfTheMskuForThe": "Ví dụ như việc gửi một phần kênh dành cho bộ phận, hãy tạo ra quy tắc chỉ định MSKU bị tắt;",
    "IfSomeMskusNeedTo": "Bạn có thể tạo ra các quy tắc chỉ định MSKU như thể bạn muốn gửi theo một số cách chỉ rõ một cửa hàng hoặc chỉ định tỷ lệ cố định/ chỉ ra một giá trị",
    "IfTheChannelStoreNeeds": "Bạn có thể sử dụng các kho báu có sẵn trong các cửa hàng được cho phép để thực hiện, tạo ra các quy tắc của cửa hàng, thiết lập quan hệ với các",
    "IfTheInventoryOfAll": "Kho hàng hàng như tất cả các cửa hàng của cửa hàng đều được thực hiện theo phần trăm bảo hàng có sẵn trong tất cả các kho hàng được phép, hãy sử dụng cá",
    "directionsForUse": "Dùng trợ giúp:",
    "youCanClickViewMskuApplication": "Bằng cách nhấp vào nút \"Xem quy tắc ứng dụng MSKU\", bạn có thể truy vấn các quy tắc mà MSKU thực sự sử dụng để đẩy",
    "thirdPriority": "Ưu tiên thứ ba",
    "secondPriority": "Ưu tiên thứ hai",
    "firstPrioritySpecifyMsku": "Ưu tiên đầu tiên: Chỉ định MSKU",
    "inventoryPushInstructions": "Hướng dẫn sử dụng đẩy hàng tồn kho",
    "mskuCreationTimeIsLaterThan": "MSKU được tạo muộn hơn thời gian bật công tắc đẩy hàng tồn kho",
    "theStoreBindingTimeIsLater": "Thời gian ràng buộc cửa hàng muộn hơn thời gian bật công tắc đẩy hàng tồn kho",
    "theWarehouseCreationTimeIsLater": "Kho được tạo muộn hơn thời gian bật công tắc đẩy hàng tồn kho",
    "reasonTheDefaultRuleConditionsAre": "Lý do: Không đáp ứng các điều kiện quy tắc mặc định, hãy tự tạo quy tắc sau khi đẩy",
    "reasonTheChannelStoreStatusIs": "Lý do: Tình trạng cửa hàng kênh không được ủy quyền, xin vui lòng liên kết lại các cửa hàng sau khi đẩy",
    "reasonThePushRuleForChannel": "Lý do: Quy tắc đẩy được sử dụng cho hàng hóa kênh đã bị vô hiệu hóa, vui lòng bật quy tắc và đẩy",
    "reasonInventorySettinginventoryPushSwitchIs": "Lý do: Cài đặt hàng tồn kho - Công tắc đẩy hàng tồn kho không được bật, vui lòng bật công tắc và đẩy sau đó",
    "reasonTheStatusOfChannelGoods": "Lý do: Trạng thái hàng hóa kênh là trạng thái nháp, vui lòng lên kệ hàng và đẩy",
    "xpriceProtection": "Giữ nguyên giá {X}%",
    "order_628344ee0de79247bef8cb6694d1cd38": "Sai giá/ khuyến mãi",
    "fuzzySearch": "Tìm kiếm xấp xỉ",
    "preciseSearch": "Tìm kiếm chính xác",
    "historicalOrders": "Đơn đặt hàng lịch sử",
    "nearlyDaysOrder": "Đơn 90 ngày qua",
    "tipChannelInterfaceRestrictionOnlySupports": "Mẹo: Giới hạn giao diện kênh chỉ hỗ trợ cập nhật hàng tồn kho hàng hóa đã lên kệ hoặc đã lên kệ",
    "theChannelStatusIsAbnormalPlease": "Tình trạng hàng hóa kênh là bất thường, xin vui lòng xác nhận sau khi đẩy",
    "theWarehouseHasBeenDeactivatedPlease": "Kho đã bị hủy kích hoạt, vui lòng bật đẩy sau",
    "whenSearchingAccuratelyTheMaximumNumber": "Khi tìm kiếm chính xác, truy vấn tối đa một lần {X}, nhiều thông tin xin vui lòng sử dụng trở lại để chuyển dòng, mỗi dòng đại diện cho một thông tin",
    "pleaseEnterTheOrderNumber": "Vui lòng nhập số đơn đặt hàng",
    "settingSuccess": "Cài đặt thành công",
    "insured": "Bảo hiểm",
    "insuranceRation": "Tỉ lệ bảo hiểm",
    "pleaseSelectInsuranceService": "Vui lòng chọn dịch vụ bảo hiểm",
    "pleaseSettingInsuranceFeeForAkulaku": "Vui lòng cài đặt phí bảo hiểm cho đơn hàng Akulaku",
    "settingInsurance": "Cài đặt bảo hiểm",
    "proportionalInsurance": "Bảo hiểm theo tỉ lệ",
    "originalInsurancePrice": "Giá bảo hiểm ban đầu",
    "notInsured": "Không bảo hiểm",
    "plannedInboundAmount": "Số tiền nhập kho dự tính",
    "actualInboundAmount": "Số tiền nhập kho thực tế",
    "shippingSubsidy": "Giảm giá vận chuyển",
    "platformSubsidy": "Trợ giá nền tảng",
    "spuAlreadyExistsPleaseRename": "SPU đã tồn tại, vui lòng đổi tên",
    "templateType": "Loại mẫu",
    "labelSize": "Kích thước vận đơn",
    "barcodePrintingTemplateSettings": "Cài đặt mẫu in mã vạch",
    "fontSize": "Kích thước chữ",
    "howToGenerate": "Phương thức tạo",
    "barcodeHeightInsideLabel": "Độ cao mã vạch trên nhãn",
    "qrCode": "Mã QR",
    "qrCodeLocation": "Vị trí mã QR",
    "left": "Trái",
    "top": "Đầu",
    "qrCodeSize": "Kích thước mã QR",
    "generatedByMsku": "Tạo theo MSKU",
    "generatedByBarcode": "Tạo theo mã vạch",
    "generatedBySpu": "Tạo theo SPU",
    "mskuCode": "Mã MSKU",
    "printContent": "In nội dung",
    "customAdd": "Thêm tùy chỉnh",
    "goToTheMasterProductPage": "Đến trang sản phẩm Chính chọn sản phẩm cần in",
    "customContent": "Tùy chỉnh nội dung",
    "pleaseInsertTheContentToBe": "Vui lòng nhập nội dung cần in, nhiều thì ngắt dòng và không quá 200 ký tự trên một dòng, hệ thống sẽ tự động cắt bớt nếu vượt quá.",
    "numberOfPrintsreferringToTheNumber": "Số lượng bản in (tức là số lượng nhãn cho mã vạch)",
    "paperPrintColumn": "Cột giấy in",
    "columnGap": "Khoảng cách cột",
    "barcodePrinting": "In mã vạch",
    "atLeastCharactersOrMore": "Ít nhất 3 ký tự trở lên",
    "theFuzzySearchContentMustExceed": "Tìm kiếm mờ phải dài hơn 3 ký tự, vui lòng điều chỉnh và thử lại",
    "ordersCreatedForMoreThan": "Đơn đặt hàng được tạo trong hơn 90 ngày sẽ được chuyển vào đơn đặt hàng lịch sử và không thể xử lý được",
    "areYouSureYouWantTo2": "Bạn có chắc chắn muốn chuyển đơn đặt hàng sang Đã giao không?",
    "moveToDelivered": "Di chuyển đến Đã gửi",
    "firstPriority": "Ưu tiên đầu tiên",
    "supportAddingMaxDataAt": "Thêm tối đa 2000 dòng dữ liệu một lần",
    "supportAddingMaxDataAt1": "Thêm tối đa 1000 dòng dữ liệu một lần",
    "stockWillBeIncreasedAfterInbound": "Tồn kho sẽ tăng sau khi nhập kho, vui lòng thao tác thận trọng, chỉ hỗ trợ nhập kho theo lô từ kho địa phương",
    "afterConfirmationTheDocumentWillBe": "Sau khi xác nhận, tài liệu sẽ được đẩy lại kho hàng",
    "stockWillBeDeductedAfterOutbound": "Tồn kho sẽ được trừ sau khi xuất kho, vui lòng thao tác thận trọng",
    "Days1": "30 ngày",
    "Days": "7 ngày",
    "clickToDownloadAttachment": "Nhấp để tải tệp",
    "attachment": "Tệp",
    "addToTheFulfillmentWarehouse": "Thêm vào kho fulfillment",
    "lockedTime": "Thời gian khóa",
    "LockedStock": "Tồn kho bị khóa",
    "lockStockOrder": "Đơn tồn kho bị khóa",
    "onlySupportsFilesDocXlsXlsx": "Chỉ hỗ trợ các tệp *.doc, *.xls, .xlsx, PDF, Excel, png, jpeg, JPG nhỏ hơn 15M",
    "clickOrDragFilesHereTo": "Nhấp hoặc kéo tệp vào đây để tải lên",
    "clickToDeleteAttachment": "Nhấp để xóa tệp",
    "uploadAttachment": "Tải tệp lên",
    "vatRate": "Tỉ lệ VAT (%)",
    "availableStockLocationCode": "Mã vị trí tồn kho có sẵn",
    "stockWil": "Tồn kho sẽ được trừ sau khi xuất kho, vui lòng thao tác thận trọng, chỉ hỗ trợ giao hàng theo lô từ kho địa phương",
    "stockWillBeReleasedAfterBeing": "Tồn kho sẽ được giải phóng sau khi đặt là không hợp lệ, vui lòng thao tác thận trọng",
    "xDataSelected": "Đã chọn {x} dữ liệu",
    "massSetAsInvalid": "Cài đặt không hợp lệ hàng loạt",
    "massPush": "Đẩy hàng loạt",
    "massRefreshStatus": "Trạng thái làm mới hàng loạt",
    "oneItemPerLineSupportExcel": "Mỗi dòng 1 mục (hỗ trợ bản sao và dán, tối đa 500)",
    "clickToMassSearch": "Nhấn để tìm kiếm hàng loạt",
    "salesOutboundList": "Phiếu xuất kho bán hàng",
    "manualOutboundList": "Phiếu xuất kho thủ công",
    "addTemplate": "Thêm mẫu",
    "massOutbound": "Xuất kho hàng loạt",
    "afterConfirmationTheSelectedDataWill": "Sau khi xác nhận, dữ liệu đã chọn sẽ được thêm lại vào kho fulfillment, vui lòng thao tác cẩn thận",
    "massInbound": "Nhập kho hàng loạt",
    "itIsDetectedThatTheIssue": "Phát hiện ra rằng hóa đơn xuất kho đã được cập nhật để chờ xuất kho hoặc đã bị hủy bỏ, không hỗ trợ đẩy, vui lòng làm mới và thử lại",
    "itIsDetectedThatTheReceipt": "Phát hiện rằng danh sách nhập kho đã được cập nhật để vào kho hoặc đã hủy bỏ, không hỗ trợ đẩy, vui lòng làm mới và thử lại",
    "refunding": "Đang hoàn tiền",
    "maxUploadDocuments": "Tải lên tối đa 3 tệp",
    "thirdPartyWarehousePushTime": "Thời gian đẩy hàng kho bên thứ ba",
    "thirdPartyNumber": "Mã đơn kho bên thứ ba",
    "bulkSearch": "Tìm kiếm hàng loạt",
    "thisSizeAlreadyExists": "Kích thước này đã tồn tại",
    "theRangeOfSupportedInputIs": "Phạm vi được hỗ trợ nhập là {min} - {max}",
    "skuName": "Tên SKU",
    "skusellerSKUInJDSellerCenter": "SKU = SKU người bán tại sàn JD",
    "barcodeHeight": "Chiều cao mã vạch",
    "QRCodeLocation": "Vị trí mã QR",
    "beLeft": "Bên trái",
    "beTop": "Trên cùng",
    "actualPrintingPerformanceDependsOnPrintingResults": "Hiệu quả in thực tế phụ thuộc vào kết quả in",
    "printingFailedReasonIsEmpty": "In thất bại, lý do: {} trống và không hỗ trợ in",
    "goUpgrade": "Nâng cấp",
    "jtExpressIdOnlySupportsPaid": "J&T Express ID chỉ hỗ trợ người bán trả phí, vui lòng ủy quyền lại sau khi nâng cấp gói ERP",
    "ordersAreOutOfStockAnd": "Đơn đã hết hàng, không thể đẩy tồn kho",
    "thereAreCurrentlyXPiecesOf": "Hiện có {x} dòng dữ liệu, có thể xuất tối đa 50,000 dữ liệu một lần, vui lòng liên hệ đội ngũ CSKH nếu muốn xuất nhiều dữ liệu hơn",
    "returnInboundListXSuccessfullyCreated": "Phiếu nhập kho trả hàng :{x} tạo thành công",
    "successXFailedX": "{x} thành công, {y} thất bại",
    "homeCustomerTips": "Trong giai đoạn thúc đẩy 99 (2022-09-08-20: 00-2022-09-10: 23: 59), kết quả của dịch vụ khách hàng sẽ là 0",
    "orderOperationLogTips": "Trong 99 thời gian khuyến tài (2022-09-08 20:00-2022-09-10 23:59) chỉ hỗ trợ xem nhật ký hoạt động trong gần 7 ngày",
    "stockPushLogTips": "Trong 99 giai đoạn khuyến tài (2022-09-08 20:00-2022-09-10 23:59) chỉ hỗ trợ xem nhật ký hoạt động trong ngày",
    "adjustmentMethodAll": "Phương thức điều chỉnh: Tất cả",
    "adjustmentMethodOutbound": "Phương thức điều chỉnh: Xuất kho",
    "adjustmentMethodInbound": "Phương thức điều chỉnh: Nhập kho",
    "numberRow1": "Hàng 1",
    "numberRow2": "Hàng 2",
    "numberRow3": "Hàng 3",
    "numberRow4": "Hàng 4",
    "theStoreAuthorizationIsAbnormalPlease": "Ủy quyền cửa hàng bất thường, vui lòng đến nền cửa hàng để kiểm tra ủy quyền",
    "pleaseSetUpYourJtExpress": "Vui lòng cài đặt vận chuyển J&TExpress ID trước",
    "theRangeOfProductImagesThat": "Phạm vi hình ảnh sản phẩm có thể được tải lên là 1-9",
    "barcodeTemplate": "Mẫu mã vạch",
    "typeDimension": "{type}Không gian",
    "channelOrderInTheCurrentOutbound": "Đơn hàng kênh trong phiếu xuất kho hiện đang hết hàng, không đáp ứng điều kiện đẩy. Vui lòng thao tác phân bổ hàng lại rồi đẩy tại mục Chờ xử lý - Đơn hàng có vấn đề",
    "supportsInputNumberFromMinToMax": "Hỗ trợ nhập số thứ tự từ {min} đến {max}",
    "inboundListNumber": "Mã phiếu nhập kho",
    "inboundQuantity": "Số lượng nhập kho",
    "inboundWarehouse": "Kho hàng nhập kho",
    "theTotalQuantityOfASingle": "Tổng số bản in không được vượt quá {0}, vui lòng giảm số lượng sản phẩm hoặc số bản in",
    "aPrintTemplateWithTheSame": "Tên mẫu in đã tồn tại, vui lòng đổi tên khác",
    "printTemplateTip": "Nếu MSKU / mã vạch / SPU vượt quá 23 ký tự, mã vạch in ra có thể không được nhận dạng. Mã QR không có giới hạn này. Vui lòng chọn loại mẫu tùy theo tình hình thực tế.",
    "printMaxlengthTip": "Nếu số lượng ký tự vượt quá 23, mã vạch đã in có thể không thể nhận dạng.",
    "pleaseSelectTheMainSku": "Vui lòng chọn SKU chính",
    "adjustmentMethod": "Phương thức điều chỉnh",
    "purchaseInbound": "Mua nhập kho",
    "outbound": "Xuất kho",
    "inbound": "Nhập kho",
    "manualAdjustment": "Điều chỉnh thủ công",
    "sell": "Bán",
    "pleaseTickTheDataFirst": "Vui lòng chọn dữ liệu trước",
    "changeValue": "Thay đổi giá trị",
    "latestLocationStock": "Tồn kho vị trí cuối cùng",
    "locationNumber": "Mã vị trí",
    "postcode": "mã bưu điện",
    "sucGeneratedOrFailed": "Tạo thành công {success}, thất bại {fail}",
    "printProductBarcode": "In mã vạch sản phẩm",
    "allPermissions": "Không cấp quyền",
    "pleaseSelectAtLeastOneExport": "Vui lòng chọn ít nhất một mục nội dung xuất",
    "pleaseSelectAtLeastOneBasic": "Vui lòng chọn ít nhất một thông tin cơ bản",
    "inboundType": "Loại nhập kho",
    "orderIdXSuccessfullyReprintedThe": "Mã đơn hàng: {X} vận đơn in bổ sung thành công",
    "orderIdXSuccessfullyPrintedThe": "Mã đơn hàng: {X} vận đơn in thành công",
    "setAsInvalidPopulerPrintOrder": "Vô hiệu tác vụ in đơn hàng bùng nổ: {X}",
    "createPopularProductPrintOrderTask": "Tạo tác vụ in đơn hàng bùng nổ: {X}",
    "theOrderIsAlreadyInThe": "Đơn hàng nằm trong tác vụ in đơn bùng nổ, vui lòng in cẩn thận để tránh trùng lặp",
    "orderQuantity": "Số lượng đơn",
    "numberOFProducts": "Số lượng sản phẩm",
    "taskId": "ID tác vụ",
    "printProgress": "Tiến độ in",
    "setAsInvalid": "Vô hiệu",
    "alreadySetAsInvalid": "Đã vô hiệu",
    "unfinished": "Chưa hoàn thành",
    "creating": "Đang tạo",
    "labelPrintingStatus": "Trạng thái in vận đơn",
    "additionalPrintLabel": "In bổ sung vận đơn",
    "size": "Kích cỡ",
    "ordinaryPrintingButPrintingUpTo": "In bình thường (nhưng in lên mức 300 lệnh, mất rất nhiều thời gian)",
    "selectedPrintDataXTheSystem": "Chọn dữ liệu in: {X} dòng, hệ thống phát hiện {Y} vận đơn đã được đánh dấu là in thành công, {Z} chưa in",
    "taskDetails": "Chi tiết tác vụ",
    "toPrintLabel": "In vận đơn",
    "manyProductsWithManyQuantity": "Đa SKU",
    "singleProductWithManyQuantity": "SKU đơn (sl >1)",
    "singleProductWithSingleQuantity": "SKU đơn (sl =1)",
    "theTaskIsBeingCreatedPlease": "Đang tạo tác vụ, vui lòng kiểm tra danh sách in đơn bùng nổ sau",
    "createTask": "Tạo tác vụ",
    "noteOrdersThatHaveBeenPlaced": "Chú ý: đơn hàng nằm trong danh sách in đơn bùng nổ sẽ không thể tham gia vào tác vụ nào khác",
    "totalOrders": "Tổng số đơn hàng:",
    "createdByFilter": "Tạo theo bộ lọc",
    "createByTick": "Tạo theo mục đã chọn",
    "printPopularProduct": "In đơn bùng nổ",
    "adjustmentNo": "Mã điều chỉnh",
    "warehouseReplacementTask": "Nhiệm vụ thay thế kho",
    "warehouseChangeSucceeded": "Thay đổi kho thành công",
    "warehouseChangeFailed": "Lỗi đổi nhà kho",
    "currentWarehouse": "Nhà kho hiện tại",
    "newWarehouse": "Nhà kho mới",
    "processingProgress": "Tiến độ xử lý",
    "adjustmentType": "Loại điều chỉnh Jenis Penyesuaian",
    "thisIsTheInboundPurchasePrice": "Đây là Giá mua Nhập kho hoặc giá mua Xuất kho",
    "theFinalLocationInventoryOnlyIndicates": "Hàng tồn kho của Vị trí mới nhất chỉ đại diện cho sản phẩm hàng tồn kho trong vị trí, Master SKU = tổng lượng hàng tồn kho của SKU được tích lũy từ mỗi vị trí trong kho",
    "changeDimension": "Thay đổi kích thước",
    "Replace": "Mẫu",
    "completeInboundStatus": "Hoàn thành",
    "setTemplate": "Cài đặt mẫu",
    "onlyNumberAndMaxLimit": "Tối đa {max} ký tự, chỉ hỗ trợ số thứ tự",
    "minimalOrderForWholesaleMustBe": "Đơn bán sỉ phải lớn hơn 1",
    "pleaseConfirmWhetherTheOrderMoved": "Vui lòng xác nhận xem có muốn chuyển đơn hàng sang Đã giao hàng hay không",
    "moveToShipping": "Chuyển thành Đang giao",
    "priceMustBeCheaperThanThe": "Giá phải rẻ hơn lần trước",
    "specificationInformation": "Thông tin thuộc tính",
    "theThirdPartyLogisticsIsNot": "Đơn vị vận chuyển bên thứ ba chưa được ủy quyền, việc hủy đơn sẽ không báo đến công ty vận chuyển",
    "deliverBy": "Giao từ",
    "pickupBy": "Nhận bởi",
    "ParcelInformation": "Thông tin gói",
    "theSameOptionValueExists": "Một số giá trị bị trùng lặp",
    "pleaseEnterOptionsSuchAsRed": "Vui lòng nhập mục chọn, ví dụ như màu đỏ",
    "uploadFailed": "Tải lên thất bại",
    "youCanUploadUpToMaxcount": "Tải lên tối đa {maxCount} tệp, tệp tải lên đã bị hủy:",
    "fileSizeExceedsLimitMaxsize": "Kích thước tệp vượt quá giới hạn: {maxSize}",
    "pictureResolutionExceedsTheLimit": "Độ phân giải hình ảnh vượt quá giới hạn",
    "maxOrderCannotBeLessThan": "Số lượng đơn tối đa không thể ít hơn số lượng đơn tối thiểu",
    "clickDownloadAndTheSystemWill": "Nhấn tải về, hệ thống sẽ xuất dựa trên điều kiện lọc đã chọn trong danh sách đơn hàng và thời gian tạo đơn hàng, vui lòng thao tác sau khi đã rõ thông tin",
    "wholesalePriceCanNotBeLower": "Giá bán buôn không thể nhỏ hơn {m}% của giá gốc, vui lòng đặt giá bán buôn hợp lý",
    "mustBeAtLeastX": "Kích thước phải ít nhất 300x300 pixels, ít hơn 2MB, ở định dạng JPG hoặc PNG",
    "thereAreDocumentsThatHaveNot": "Một số tệp không được tải lên thành công",
    "ifYouContinueToSubmitThe": "Nếu tiếp tục gửi, dữ liệu được gửi sẽ bỏ qua các tệp không tải lên thành công",
    "continueToSubmit": "Tiếp tục gửi",
    "thirdPartyLogisticsOnlySupportsThe": "Đơn vị vận chuyển bên thứ ba chỉ hỗ trợ cho người dùng trả phí, vui lòng ủy quyền lại sau khi nâng cấp gói ERP",
    "numbering": "Đánh số",
    "thereIsNoStoreThatSupports": "Không có cửa hàng hỗ trợ sao chép, vui lòng liên kết cửa hàng trước",
    "productCloneInProgress": "Đang sao chép sản phẩm...",
    "countProductClonedTo": "{count} sản phẩm được sao chép vào",
    "exportProgress": "Tiến độ xuất",
    "exportType": "Kiểu xuất",
    "copyToDraft": "Sao chép thành bản nháp",
    "invalid": "Không hợp lệ",
    "editImage": "Chỉnh sửa hình ảnh",
    "editName": "Chỉnh sửa tên",
    "editDesc": "Chỉnh sửa mô tả",
    "editInfo": "Chỉnh sửa thông tin",
    "printLabelStatus": "Trạng thái in vận đơn",
    "platformOrderNumber": "Mã đơn sàn",
    "markAsFakeOrder": "Đơn hàng giả",
    "noteOrdersThatHaveBeenPlaced1": "Chú ý:Các đơn hàng trong tác vụ đơn hàng bùng nổ sẽ không tham gia tác vụ nào khác",
    "theNumberOfVariantCannotExceed": "Số lượng thuộc tính không thể vượt quá {count}",
    "stepConfirmThePictureInformation": "Bước 1: Xác nhận thông tin hình ảnh",
    "stepSelectTheProductYou": "Bước 2: Chọn sản phẩm bạn muốn áp dụng",
    "editImagesInBatches": "Chỉnh sửa hình ảnh hàng loạt",
    "productImageTip": "Vì giới hạn API, hình ảnh sản phẩm tối đa {maxLength} ảnh, kích thước tối đa {size}MB, độ phân giải phải lớn hơn {width} * {height}px",
    "fakeOrder": "Đơn hàng ảo",
    "whetherFakeOrder": "Có phải đơn ảo không",
    "massSettingFakeOrder": "Cài đặt đánh dấu hàng loạt đơn hàng ảo",
    "copyAsDraftTip": "sản phẩm sao chép thành bản nháp",
    "orderXCancelsTheFakeOrder": "Đơn {X} hủy đánh dấu là đơn hàng ảo",
    "orderXIsMarkedAsA": "Đơn {X} được đánh dấu là đơn hàng ảo",
    "fakeOrders": "Đơn hàng ảo",
    "menuProductShopify": "Sản phẩm / Shopify",
    "menuProductShopifyAdd": "Sản phẩm / Shopify /  Thêm sản phẩm",
    "menuProductShopifyEdit": "Sản phẩm / Shopify / Chỉnh sửa sản phẩm",
    "restrictedByJtExpressTheChinese": "Bị hạn chế bởi \"J&T Express\", \"tên tiếng Trung của sản phẩm\" không được vượt quá 100; \"tên tiếng Anh của sản phẩm\" không được vượt quá 200\"; \"mã sản phẩm\" không được vượt quá 50; vui lòng sửa đổi thông tin sản phẩm và nộp lại sản phẩm",
    "tryingToGetChannelShippingLabel": "Đang lấy thông tin vận đơn, vui lòng đẩy lại sau",
    "rangeFieldMessage": "Phải từ {min}〜{max}",
    "duringThePromotionPeriod": "Trong giai đoạn thúc đẩy (2022-10-09 20: 00-2022-10-10 23: 59), kết quả của dịch vụ khách hàng sẽ là 0",
    "onlySupportedDuringTheBigPromotion": "Trong thời gian khuyến tài (2022-12-31 20: 00-2023-01-01 23: 59) chỉ hỗ trợ xem nhật ký hoạt động trong gần 7 ngày",
    "onlyViewingTheOperationLogOf": "Trong giai đoạn khuyến tài (2022-12-31 20: 00-2023-01-01 23: 59) chỉ hỗ trợ xem nhật ký hoạt động trong ngày",
    "gineeIsPushingInformationToThe": "Glenn đang gửi thông Ginee cho anh tới nhà kho, chờ đợi kết quả từ nhà kho bên thứ ba. Bạn có thể tiếp tục xử lý các đơn hàng khác hoặc ở lại trong danh sách các đơn hàng để xem các kết quả bỏ qua.",
    "theDistributedWarehouseTaskWasCreated": "Nhiệm vụ đẩy đã thành công",
    "clickToConfirmTheSystemWill": "Nhấn vào để xác nhận, hệ thống sẽ gọi giao diện kho bên thứ ba để đẩy các kết quả là phải chờ đợi giao diện kho bên thứ ba trở lại. Xin hãy kiên nhẫn chờ đợi.",
    "confirmThatTheSelectedOrderWill": "Kiểm tra xem các đơn hàng được chọn sẽ được thông báo về việc xử lý kho báu bên thứ ba?",
    "tier": "Khoảng giá",
    "min": "Tối thiểu",
    "max": "Tối đa",
    "afterConfirmationTheOrderWillBe": "Sau khi xác nhận, đơn hàng sẽ được đẩy, vui lòng làm mới lại trang để xem kết quả đẩy",
    "onceThePushFailsPleaseTry": "Khi đẩy thất bại, vui lòng thử đẩy lại trong mục Đẩy kho hàng > Đẩy thất bại",
    "noteThe": "Lưu ý: Cập nhật trạng thái đơn hàng kênh và vận đơn đang trễ, có thể dẫn đến đẩy thất bại",
    "theOrderIs": "Đơn hàng hiện đang không được đẩy đến kho bên thứ ba, vui lòng không thao tác trùng lặp, hãy mở mục Đẩy kho hàng > xem tiến độ đẩy",
    "shipmentFailed": "Giao hàng thất bại",
    "afterAutomaticDeliveryIsEnabledThe": "Sau khi bật tính năng giao hàng tự động, các đơn hàng đáp ứng điều kiện sẽ tự động điền thông tin giao hàng rồi mới giao. Nếu thông tin giao hàng không đáp ứng các điều kiện giao hàng, thì phải thực hiện giao hàng thủ công.",
    "defaultFirst": "Mặc định thời gian lấy hàng đầu tiên",
    "defaultLast": "Mặc định thời gian lấy hàng cuối",
    "pickUpTime": "Thời gian lấy hàng",
    "automaticShipping": "Giao hàng tự động",
    "warehouseToBeAssigned": "Kho hàng chờ phân bổ",
    "pushWarehouse": "Đẩy kho hàng",
    "tryingToLoad": "Đang tải...",
    "orderTotalTotalProductAmount": "Tổng tiền đơn hàng = tổng tiền sản phẩm + phí vận chuyển + thuế + bảo hiểm + phí dịch vụ + hoa hồng - tổng chiết khấu",
    "onceVoidedTheOrderWillBe": "Sau khi vô hiệu, đơn hàng sẽ được chuyển sang Vô hiệu",
    "addedChannelProducts": "Thêm sản phẩm kênh",
    "syncedChannelProducts": "Đã đồng bộ sản phẩm kênh",
    "syncedChannelProductsChangedFrom": "Đã đồng bộ sản phẩm kênh, từ {} thành {}",
    "produkChannelTelahDisinkronkanDanInformasi": "Đã đồng bộ sản phẩm kênh, SKU {} trả từ kênh đã bị xóa",
    "theChannelProductsAreSynchronizedAnd": "Đã đồng bộ sản phẩm kênh, kênh trả về SKU {} mới",
    "editedProductNameFromTo": "Đã chỉnh sửa tên sản phẩm, từ {} thành {}",
    "editingProductCategoryFromTo": "Đã chỉnh sửa tên danh mục sản phẩm, từ {} thành {}",
    "editingLongDesecriptionForVariant": "Đã chỉnh sửa độ dài mô tả sản phẩm, từ {0} thành {1}",
    "editingPriceForVariantFrom": "Đã chỉnh sửa giá của tên thuộc tính {0}, từ {1} thành {2}",
    "editedStockChannelVariantName": "Đã chỉnh sửa tồn kho của tên thuộc tính {0}, từ {1} thành {2}",
    "editedChannelSkuVariantName": "Đã cập nhật SKU kênh của tên thuộc tính {0}, từ {1} thành {2}",
    "deletedVariantType": "Đã xóa loại thuộc tính {}",
    "addedVariantType": "Đã thêm loại thuộc tính {}",
    "deletedOption": "Tại loại quy tắc {1} đã xóa mục {2}",
    "addedOption": "Tại loại thuộc tính {1} đã thêm mục mới {2}",
    "updatedProductImage": "Đã cập nhật hình ảnh sản phẩm",
    "updatedVariantImageForVariatName": "Đã cập nhật hình ảnh thuộc tính {} của tên thuộc tính",
    "updatedOfProductSizeFrom": "Đã cập nhật {} của kích thước sản phẩm, từ {} thành {}",
    "updatedProductWeightChangedFrom": "Đã cập nhật cân nặng sản phẩm, từ {0} thành {1}",
    "addTheCheckedLogisticAnd": "Đã thêm đơn vị vận chuyển đã chọn là {}, bỏ chọn đơn vị vận chuyển {}",
    "deleteChannelProduct": "Đã xóa sản phẩm kênh {}",
    "copyProductToChannelStore": "Đã sao chép sản phẩm {} sang cửa hàng {} kênh {}",
    "thisItemIsListed": "Sản phẩm này đã lên kệ{}",
    "thisItemHasBeenRemoved": "Sản phẩm này đã xuống kệ{}",
    "variationNa": "Tên thuộc tính là {0}, SKU kênh là {1} đã đáp ứng các quy tắc liên kết tự động, đã liên kết MSKU {2}",
    "variationNameTheChannelSku": "Tên thuộc tính là {0}, SKU kênh là {1} đã đáp ứng các quy tắc tạo tự động, đã tạo MSKU {2}",
    "variatio": "Tên thuộc tính là {0}, SKU kênh là {1}, đã liên kết thủ công MSKU {2}",
    "variationNameIsChannelSku": "Tên thuộc tính là {0}, SKU kênh là {1}, đã tạo thủ công MSKU {2}",
    "mskumanuallyUnboundTheCorrespondingChannelSku": "MSKU {} hủy thủ công liên kết SKU kênh {} tương ứng trên trang sản phẩm chính",
    "sinceStoreHasBeenDeletedchannel": "Do ủy quyền cửa hàng {} đã bị xóa, SKU kênh {} hủy liên kết MSKU {}",
    "sinceMskuHasBeenRemovedchannelSkuwas": "Do MSKU {} đã bị xóa, SKU kênh {} hủy liên kết MSKU {}",
    "manuallyAddedMasterProduct": "Đã thêm thủ công sản phẩm chính",
    "manuallyCreatedMasterProductByStore": "Đã tạo thủ công sản phẩm chính theo cửa hàng",
    "manuallyCreatedMasterProductByChannel": "Đã tạo thủ công sản phẩm chính theo sản phẩm kênh",
    "createdMasterProductByImportData": "Đã tạo sản phẩm chính bằng cách nhập",
    "autoCreateMasterProduct": "Tự động tạo sản phẩm chỉnh",
    "createdMasterProductByScrapping": "Đã tạo sản phẩm chính bằng sưu tập",
    "editedMasterProductNameFrom": "Đã chỉnh sửa tên sản phẩm chính, từ {0} thành {1}",
    "editedMasterProductCategoryFrom": "Đã chỉnh sửa danh mục sản phẩm chính, từ {0} thành {1}",
    "editedMasterProductSpuFrom": "Đã chỉnh sửa SPU sản phẩm chính, từ {0} thành {1}",
    "editedMasterProductShortDescriptionFrom": "Đã chỉnh sửa mô tả ngắn của sản phẩm chính, từ {0} thành {1}",
    "editedTheMasterProductLongDescription": "Đã chỉnh sửa mô tả dài của sản phẩm chính, từ {1} thành {2}",
    "editedMskuPriceFromTo": "Đã chỉnh sửa MSKU từ {} thành {}",
    "editedAvailableStockForMskuFrom": "Đã chỉnh sửa tồn kho có sẵn cho MSKU {0}, từ {1} đến {2}",
    "editedBarcodeOfMskuFrom": "Đã chỉnh sửa mã vạch của MSKU {0}, từ {1} thành {2}",
    "updatedOfMasterProductSize": "Đã cập nhật {} kích thước sản phẩm chính, từ {} thành {}",
    "updatedTheWeightOfMasterProduct": "Đã cập nhật cân nặng sản phẩm chính, từ {} thành {}",
    "updatedAvailableStockTheUpdatedStock": "Đã cập nhật tồn kho có sẵn, tồn kho được cập nhật là {}",
    "deleteMaste": "Đã xóa sản phẩm Chính {}",
    "deleteMskus": "Đã xóa MSKU {}",
    "printingSpus": "Đã in mã vạch SPU {}",
    "printingMskuBarcode": "Đã in mã vạch MSKU {}",
    "printingBarcode": "Mã vạch với mã vạch {} được in",
    "mskuHasBeenAddedTo": "MSKU {} đã được thêm vào kho {}",
    "mskuHasBeenAddedAndInbound": "MSKU {} đã thêm vào kho {} và được đưa vào lưu trữ.",
    "theAutomaticBindingOfMasterSku": "Đã bật liên kết tự động của quy tắc MSKU, quy tắc là: {}",
    "allStoresEnabledDefaultConfigurationFor": "Bật tất cả cửa hàng (cấu hình mặc định áp dụng cho các cửa hàng mới ủy quyền)",
    "activateByChanelsChanels": "Bật theo kênh, kênh {}",
    "openByStoreStore": "Bật theo cửa hàng, cửa hàng {}",
    "theAutomaticBindingOfTheMaster": "Đã tắt quy tắc liên kết tự động MSKU",
    "theAutomaticCreateMasterSkuRule": "Đã bật quy tắc tạo tự động MSKU, quy tắc là:{}",
    "turnOffTheAutomaticCreateMaster": "Đã tắt quy tắc tạo tự động MSKU",
    "newPrice": "Giá mới",
    "priceCannotBeModifiedDuringThe": "Giá sản phẩm không thể thay đổi trong thời gian khuyến mãi",
    "thereAreDuplicateExportTasksRemaining": "Hiện có tác vụ xuất trùng lặp, thời gian hết hạn còn lại: {0}s",
    "theSingleUrlCannotBeEmpty": "URL đơn không thể trống, vui lòng đẩy lại sau",
    "theLogisticsProviderCannotBeEmpty": "Đơn vị vận chuyển không thể trống, vui lòng liên hệ đơn vị cung cấp dịch vụ kho hàng để thêm thông tin đơn vị vận chuyển",
    "awbNumberCannotBeEmptyPlease": "AWB không thể trống, vui lòng đẩy lại sau",
    "syncSuccessfully": "Đồng bộ thành công",
    "syncFailed": "Đồng bộ thất bại",
    "sourceOfError": "Nguồn lỗi",
    "channelErrorPleaseContactTheChannel": "Lỗi kênh bán: vui lòng liên hệ kênh để kiểm tra",
    "warehouseErrorPleaseContactTheWarehouse": "Lỗi kho hàng: vui lòng liên hệ nhà cung cấp dịch vụ kho hàng để kiểm tra",
    "unknownErrorPleaseContactGineeErp": "Lỗi chưa xác định: vui lòng liên hệ CSKH của Ginee OMS để kiểm tra",
    "recreate": "Tạo lại",
    "failedRecreate": "Tạo lại thất bại",
    "outboun": "Mã đơn xuất kho: {x} mã đơn hàng liên kết {y} đã tạo thành công; người thực hiện: {z} Kho hàng xuất kho: {s}",
    "outOrderNumberXAssociatedOrder": "Mã đơn xuất kho: {x} mã đơn hàng liên kết {y} tạo lại thành công; người thực hiện: {z} Kho hàng xuất kho: {s}",
    "orderNumberXAssociatedOrder": "Mã đơn xuất kho: {x} mã đơn hàng liên kết {y} tạo lại thất bại",
    "rderNumberXAssociatedOrder": "Mã đơn xuất kho: {x} mã đơn hàng liên kết {y} \"chưa đẩy\"; người thực hiện: {z} Kho hàng xuất kho: {s}",
    "oer": "Mã đơn xuất kho: {x} mã đơn hàng liên kết {y} \"đẩy thành công\"; người thực hiện: {z} Kho hàng xuất kho: {s}",
    "tboundder": "Mã đơn xuất kho: {x} mã đơn hàng liên kết {y} \"đẩy thất bại\"; người thực hiện: {z} Kho hàng xuất kho: {s}; nguyên nhân thất bại:{a}",
    "odOrderNumberXAssociatedOrder": "Mã đơn xuất kho: {x} mã đơn hàng liên kết {y} \"xuất kho thành công\"; người thực hiện: {z} Kho hàng xuất kho: {s}",
    "outboundOrderNumberXAssociatedOrder": "Mã đơn xuất kho: {x} mã đơn hàng liên kết {y} \"làm mới thành công\"; người thực hiện: {z} Kho hàng xuất kho: {s}",
    "inboundOrderNumberXAssociatedOrder": "Mã đơn xuất kho: {x} mã đơn hàng liên kết {y} \"làm mới thất bại\"; người thực hiện: {z} Kho hàng xuất kho: {s}; nguyên nhân thất bại:{a}",
    "orderwe": "Mã đơn xuất kho: {x} mã đơn hàng liên kết {y} \"đã gác lại\"; người thực hiện: {z} Kho hàng xuất kho: {s}",
    "outboundOrderNumberXRelatedOrder": "Mã đơn xuất kho: {x} mã đơn hàng liên kết {y}; SKU: w \"đã vô hiệu\"; người thực hiện: {z} Kho hàng xuất kho: {s};",
    "outboundOrderNumberXHasBeen": "Mã đơn xuất kho: {0} đã bị xóa, đơn hàng liên kết {1} Nguyên nhân xóa: đơn hàng đã tạm gác",
    "inboundNoXPerformerYInbound": "Mã đơn nhập kho: {x} Người thực hiện: {y}, Kho hàng nhập kho: {z} tạo mới đơn nhập kho",
    "inboundNoXNotPushedPerformer": "Mã đơn nhập kho: {x}, \"chưa đẩy\"; Người thực hiện: {y}, Kho hàng nhập kho: {z}",
    "inboundNumberXSuccessfulPushPerformer": "Mã đơn nhập kho: {x}, \"đẩy thành công\"; Người thực hiện: {y}, Kho hàng nhập kho: {z}",
    "inboundNoXPushFailedPerformer": "Mã đơn nhập kho: {x}, \"đẩy thất bại\"; Người thực hiện: {y}, Kho hàng nhập kho: {z}; Nguyên nhân thất bại {a}",
    "inboundNoXRefreshSuccessfulPerformer": "Mã đơn nhập kho: {x}, \"làm mới thành công\"; Người thực hiện: {y}, Kho hàng nhập kho: {z}",
    "nomorInboundXRefreshGagalPerformer": "Mã đơn nhập kho: {x}, \"làm mới thất bại\"; Người thực hiện: {y}, Kho hàng nhập kho: {z}; Nguyên nhân thất bại: {a}",
    "inboundNoXinvalidperformerYInboundWarehouse": "Mã đơn nhập kho: {x}, SKU: {a}; \"Đã vô hiệu\"; Người thực hiện: {y}, Kho hàng nhập kho: {z}",
    "inboundNoXskuainvalidperformerYInboundWarehouse": "Mã đơn nhập kho: {x}, SKU: {a}; \"Đã gác lại\"; Người thực hiện: {y}, Kho hàng nhập kho: {z}",
    "inboundNoXwaitingInboundPerformerY": "Mã đơn nhập kho: {x}, \"Chờ nhập kho\"; Người thực hiện: {y}, Kho hàng nhập kho: {z}",
    "receiptNoXinboundPerformerYInbound": "Mã đơn nhập kho: {x}, \"Nhập kho\"; Người thực hiện: {y}, Kho hàng nhập kho: {z}",
    "push": "Đẩy",
    "productsChannelEditProduct": "Sản phẩm / {channel} / Chỉnh sửa sản phẩm",
    "productsChannelAddProduct": "Sản phẩm / {channel} /  Thêm sản phẩm",
    "dueToPossibleDelaysInSyncing": "Do có thể có sự chậm trễ trong việc đồng bộ dữ liệu, vui lòng đợi vài giây sau khi đồng bộ trước khi chọn thương hiệu.",
    "pleaseEnterBrandKeywordsToSync": "Vui lòng nhập từ khóa thương hiệu để đồng bộ",
    "cantFindTheBrandClickMe": "Không tìm thấy thương hiệu? Nhấp vào đây để đồng bộ",
    "outboundCompletionTime": "Thời gian hoàn thành xuất kho",
    "theImageAspectRatioIsInvalid": "Tỷ lệ chiều rộng hình ảnh không hợp lệ và chỉ hỗ trợ hình ảnh có tỷ lệ khung hình là {ratio}",
    "theFileWillBeDisplayedSynchronously": "Các tệp sẽ được hiển thị đồng bộ trong danh sách lịch sử xuất và tệp sẽ chỉ được giữ lại trong 15 ngày, vui lòng tải xuống kịp thời",
    "exportRecords": "Xuất bản ghi",
    "theFollowingOrdersNeedToBe": "Các đơn hàng sau cần bổ sung thêm thông tin vận chuyển trước khi sắp xếp vận chuyển. Ginee đã tự động lọc {x} đơn hàng trên Shopee có trạng thái là Đang chờ xử lý và không được phép sắp xếp lô hàng",
    "arrangementFailed": "Sắp xếp thất bại",
    "arranging": "Đang sắp xếp",
    "pleaseGoToPendingPrintOrder": "Vui lòng đến Đơn Chờ in để tạo tác vụ in Vận đơn sản phẩm bùng nổ",
    "limitasiApiSkuTidakDapatDi": "Do hạn chế của API, tạm thời không hỗ trợ chỉnh sửa SKU",
    "ordersThatAreNotBoundTo": "Đơn không liên kết với MSKU sẽ không được nhập vào tác vụ in sản phẩm bùng nổ",
    "customShop": "Cửa hàng tự chọn",
    "continueCreate": "Tiếp tục tạo",
    "theStoreWasCreatedSuccessfully": "Cửa hàng đã được tạo thành công",
    "warehouseLevelAllPages": "Cấp độ kho - tất cả",
    "warehouseLevelClickToCheck": "Cấp độ kho - đã chọn",
    "locationLevelAllPages": "Cấp độ vị trí - tất cả",
    "locationLevelClickToCheck": "Cấp độ vị trí - đã chọn",
    "inboundManagementoperationLog": "Nhật ký quản lý/hoạt động trong kho lưu trữ",
    "outboundManagementoperationLog": "Nhật ký quản lý/hoạt động xuất kho",
    "inboundNumberXxxxPushedToWarehouse": "Mã đơn nhập kho: {xxxx}đã đẩy đến kho: Tác vụ {xxxx} thất bại",
    "reasonTheOrderNumberXxxxHas": "Nguyên nhân: Mã đơn hàng {xxxx} đạt đủ điều kiện đẩy",
    "inboundNoXxxxHasBeenSuccessfully": "Mã đơn nhập kho: {xxxx} đã được đẩy thành công đến kho hàng {XXXX}, và lấy từ kho hàng {XXXX} mã đơn hàng: #xxxxx#",
    "inboundNumberXxxxHasSuccessfullyCreated": "Mã đơn nhập kho: {xxxx} đã tạo thành công tác vụ đẩy đến kho hàng: {XXXX}, và đang chờ phản hồi từ API kho hàng {XXX}",
    "xxWarehouse": "{XX} kho hàng",
    "failureReasonXxxxErrorSourceXxxxx": "Nguyên nhân thất bại: {XXXX}, Nguồn lỗi: {XXXXX}",
    "outboundNumberFailedToPushXxxx": "Mã đơn xuất kho: Đẩy thất bại {xxxx} đến {XXXX}",
    "outboundNumberXxxxHasBeenSuccessfully": "Mã đơn xuất kho:{xxxx} đã được đẩy thành công đến {XXXX}, và lấy mã đơn kho bên thứ ba: {xxxxx}",
    "outboundNumberXxxxHasSuccessfullyCreated": "Mã đơn xuất kho:{xxxx} đã tạo thành công tác vụ để đến kho hàng: {XXXX}, và đang chờ phản hồi từ API kho hàng {nhà cung cấp fulfillment}",
    "inboundOrderPushed": "Đẩy đơn nhập kho",
    "outboundOrderPushed": "Đẩy đơn xuất kho",
    "sendersFullAddress": "Địa chỉ đầy đủ của người gửi",
    "sendersPostalCode": "Mã bưu điện của người gửi",
    "senderArea": "Địa điểm người gửi",
    "manualPush": "Đẩy thủ công",
    "pendingOrderOldVer": "Đơn chờ xử lý (bản cũ)",
    "pleaseGoToTheDeliverySettings": "Vui lòng điền vào cài đặt giao hàng",
    "noCustomStoreHasBeenDetected": "Nếu không có cửa hàng tùy chỉnh, hãy tạo cửa hàng tùy chỉnh trước",
    "goToCreate": "Đi tới tạo",
    "enableAtLeastOneVariation": "Bật ít nhất một thuộc tính",
    "createdAChannelProductBy": "Cửa hàng {2} đã tạo sản phẩm kênh {3} thông qua {1}",
    "storeSyncedChannelProduct": "Cửa hàng {1} đã đồng bộ sản phẩm kênh {2}",
    "ChangedFromTo": "{1} đổi từ {2} thành {3}",
    "deletedSkuWithVariantName": "Đã xóa SKU với tên thuộc tính {}",
    "addedSkuWithVarianName": "Đã thêm SKU với tên thuộc tính {}",
    "throughStoreDeleted": "Thông qua {1}, cửa hàng {2} đã xóa sản phẩm kênh {3}",
    "deletedVar": "Xóa loại thuộc tính {}",
    "addedVa": "Thêm loại thuộc tính {}",
    "updatedVarianImageWithVarianName": "Đã cập nhật hình ảnh thuộc tính {} của tên thuộc tính",
    "updatedProductSizeAndWeightInfo": "Đã cập nhật kích thước và cân nặng sản phẩm, {1} đổi từ {2} thành {3}",
    "logisticsUpdatedChangedFrom": "Đã cập nhật đơn vị vận chuyển, {1} đổi từ {2} thành {3}",
    "atLeastOneSpecificationIsEnabled": "Ít nhất một thuộc tính được bật",
    "addMasterProduct": "Thêm MSKU",
    "editMasterProduct": "Chỉnh sửa MSKU",
    "autoCreateMasterSku": "Tự động tạo MSKU",
    "autoBindMasterSku": "Tự động liên kết MSKU",
    "addToWarehouse": "Thêm vào kho",
    "masterProductOperationLog": "Nhật ký hoạt động sản phẩm Chính",
    "solutionPleaseFirstCheckWhetherThe": "Giải pháp: vui lòng kiểm tra xem cửa hàng thuộc loại cửa hàng Chính thức hay cửa hàng Power Merchant trước. Nếu không, hãy nâng cấp cửa hàng trong trung tâm người bán trước. Nếu thuộc một trong hai, vui lòng kiểm tra xem ủy quyền cửa hàng có phải bị hủy trong trung tâm người bán hay không.",
    "updatedProductLengthFromTo": "Đã cập nhật chiều dài sản phẩm, từ {0} thành {1}",
    "updatedProductWidthFromTo": "Đã cập nhật chiều rộng của sản phẩm, từ {0} thành {1}",
    "updatedProductHeightFromTo": "Đã cập nhật chiều cao sản phẩm, từ {0} thành {1}",
    "addedMsku": "Thêm mới MSKU{}",
    "onlyIntegersAreSupported": "Chỉ hỗ trợ số nguyên",
    "masstSku": "Chỉnh sửa SKU hàng loạt",
    "newOriginalPrice": "Giá gốc mới",
    "newselingPrice": "giá bán mới",
    "applicationCompletion": "Ứng dụng đã hoàn tất",
    "editPrice": "Chính sửa giá",
    "theStatusOfProductHas": "Trạng thái của sản phẩm {1} đã thay đổi từ {2} thành {3}",
    "theChannelPriceOfMsku": "Giá kênh của MSKU {1} tại cửa hàng {2}, đã thay đổi từ {3} thành {4}",
    "defaultPriceOfMskuChanged": "Giá mặc định của MSKU {1} đã thay đổi từ {2} thành {3}",
    "solutionPleaseSetThePickupWarehouse": "Giải pháp: Vui lòng thiết lập thông tin kho Lấy hàng trong Tài khoản của tôi> hồ sơ người bán> kho hàng trong trung tâm người bán.",
    "afterSuccessfulAuthorizationYouCanUse": "Sau khi ủy quyền thành công, dịch vụ Keeppack fulfillment quản lý sản phẩm xuất nhập kho; thông tin tồn kho và sản phẩm của kho Keeppack sẽ được tự động đồng bộ hai chiều để nâng cao hiệu quả quản lý",
    "clickTheGoToAuthorizationButton": "Nhấn vào nút [Đi đến ủy quyền], bạn sẽ truy cập trang đăng nhập Keeppack, hãy nhập mật khẩu tài khoản dùng để đăng nhập vào hệ thống Keeppack, sau khi hệ thống Keeppack xác nhận thông tin đăng nhập của bạn là chính xác thì bạn sẽ ủy quyền thành công Keeppack cho hệ thống Ginee.",
    "beSureToCompleteTheMatching": "Vui lòng đảm bảo hoàn thành ghép nối sản phẩm Chính trong [Kho hàng-Kho hàng Keeppack-Thông tin sản phẩm]",
    "ifThereIsTooMuchCommodity": "Nếu có quá nhiều dữ liệu sản phẩm, sẽ mất nhiều thời gian, vui lòng kiên nhẫn chờ",
    "imAutomaticallyPullingTheKeeppackWarehouse": "Tự động kéo danh sách kho Keeppack và thông tin sản phẩm cho bạn",
    "automaticallyPullCommodityInformation": "Tự động lấy thông tin sản phẩm",
    "automaticallyPullWarehouseInformationValidWarehouse": "Tự động lấy thông tin kho hàng (giới hạn đối với các kho hàng hợp lệ)",
    "senderMobilePhone": "SĐT người gửi",
    "theBindingRecordIsOnlyValid": "Nhật lý liên kết chỉ có hiệu lực trong 30 ngày, sau 30 ngày sẽ không thể xem được",
    "pullRecord": "Nhật ký kéo",
    "batchPulling": "Kéo hàng loạt",
    "mskuBinding": "MSKU liên kết",
    "bindingMsku": "Liên kết MSKU",
    "pullTime": "Thời gian kéo dữ liệu",
    "commodityValue": "Giá trị",
    "theTotalValueOfGoodsReturned": "Tổng giá trị sản phẩm kho này do giao diện kho bên thứ ba trả về",
    "totalWarehouseInventoryReturnedByThreeparty": "Tổng tồn kho trả về từ giao diện kho bên thứ ba",
    "inventoryWaitingForReceiptReturnedBy": "Tồn kho của phiếu chờ nhập kho trả về từ giao diện kho bên thứ ba",
    "availableInventoryReturnedByThreepartyWarehouse": "Tồn kho có sẵn trả từ giao diện kho bên thứ ba",
    "gineePushesItToTheWarehouse": "Ginee đẩy đến tạo kho hàng",
    "sanfangcangSku": "SKU kho bên thứ ba",
    "nameOfGoodsInTheWarehouse": "Tên sản phẩm trong kho hàng được trả từ giao diện kho hàng bên thứ ba",
    "commodityNameOfWarehouse": "Tên sản phẩm kho hàng",
    "mskuIsBound": "Đã liên kết MSKU",
    "unboundMsku": "Chưa liên kết MSKU",
    "startWithAndBeNo": "Bắt đầu bằng 0 và không quá 12 chữ số",
    "noMoreThanEnglishCharacters": "Không quá 35 ký tự tiếng Anh",
    "usedToPushReceiptDocumentsTo": "Được sử dụng để đẩy phiếu nhập kho đến Keeppack",
    "xxxWarehousesHaveBeenAddedWith": "Thêm {XXX} kho hàng, Mã kho: {XXX}, {XXX}",
    "OnceTheKeeppackInterfaceUpdates": "2. Sau khi giao diện Keeppack cập nhật trạng thái kho hàng thành Vô hiệu, kho trong Ginee sẽ bị vô hiệu hóa đồng bộ, và thông tin sản phẩm / kho không thể đồng bộ được nữa",
    "gineeOnlyReturnsTheWarehouseWith": "1. Ginee chỉ đồng bộ giao diện Keeppack để trả về kho hàng có trạng thái kênh đang Hoạt động",
    "warmTip": "Mẹo",
    "startPullingTheLatestWarehouseInformation": "Sau khi nhấp vào Xác nhận, bạn sẽ bắt đầu kéo thông tin kho mới nhất của Keeppack cho bạn và chờ đợi cho giao diện Keppack để trả về thông tin, hãy kiên nhẫn",
    "pullWarehouseInformation": "Kéo thông tin kho hàng",
    "pullLog": "Nhật ký kéo dữ liệu",
    "warehouseUpdateTimeReturnedByKeeppack": "Thời gian cập nhật kho hàng do giao diện Keeppack trả về",
    "warehouseCreationTimeReturnedByKeeppack": "Thời gian tạo kho hàng được trả về bởi giao diện Keeppack",
    "channelStatus": "Trạng thái kênh",
    "warehouseId": "ID kho hàng",
    "senderInformation": "Thông tin người gửi",
    "automaticCreationOfMainCommodity": "Tự động tạo sản phẩm Chính",
    "automaticUnbindingMsku": "Tự động hủy liên kết MSKU",
    "automaticBindingMsku": "Tự động liên kết MSKU",
    "manuallyCreateTheMainCommodity": "Tạo thủ công sản phẩm Chính",
    "manuallyUnbindMsku": "Hủy liên kết thủ công MSKU",
    "manuallyBindMsku": "Liên kết thủ công MSKU",
    "startBinding": "Bắt đầu liên kết",
    "andAllUnpairedThreepartyWarehouseSkus": "Tất cả các SKU kho bên thứ ba mới chưa ghép nối hiện tại và tiếp theo",
    "allCurrentlyUnpairedThreepartyWarehouseSkus": "Tất cả các SKU kho bên thứ ba hiện không được ghép nối",
    "appliedRange": "Phạm vi áp dụng",
    "whenTheThreepartyWarehouseSkuIs": "Khi SKU của kho bên thứ ba hoàn toàn khớp với MSKU của sản phẩm chính đã được tạo, nó sẽ tự động liên kết",
    "bindingRules": "Quy tắc liên kết",
    "reasonItConformsToTheMsku1": "Nguyên nhân: Phù hợp với cài đặt sản phẩm - sản phẩm trong kho hàng tự động tạo quy tắc MSKU",
    "reasonThreepartyWarehouseHasBeenUnbound": "Nguyên nhân: Kho bên thứ ba chưa liên kết",
    "reasonItConformsToTheMsku": "Nguyên nhân: Tuân theo cài đặt sản phẩm - các sản phẩm trong kho tự động liên kết với các quy tắc MSKU",
    "goodsOfWarehouseXxxxxXxxxxSku": "Kho: Sản phẩm của {XXXXX}: {XXXXX}, SKU: {XXXXX} của kho bên thứ ba đã tạo thành công sản phẩm chính trong hệ thống: {XXXXX}, MSKU: {XXXXX}",
    "commodityXxxxxOfWarehouseXxxxxThreeparty": "Kho: Hàng hóa của {XXXXX}: {XXXXX}, SKU: {XXXXX} và MSKU: {XXXXX} bị hủy liên kết trong kho bên thứ ba",
    "commodityXxxxxOfWarehouseXxxxxAnd": "Kho: Các sản phẩm của {XXXXX}: {XXXXX}, SKU: {XXXXX} và MSKU: {XXXXX} của kho bên thứ ba được liên kết thành công",
    "viewThePullRecord": "Xem kết quả lấy dữ liệu",
    "gineeIsPullingCommodityInformationFrom": "Ginee đang lấy thông tin sản phẩm từ kho hàng, và đợi giao diện kho bên thứ ba trả kết quả, bạn có thể tiếp tục giải quyết các vấn đề khác hoặc xem tiến trình kéo và kết quả trong bản ghi kéo",
    "createAPullTask": "Tạo tác vụ kéo dữ liệu",
    "commodityCreationTime": "Thời gian tạo sản phẩm",
    "inOrderToImproveThePulling": "Để nâng cao hiệu quả kéo dữ liệu, thời gian kéo một lần không quá 15 ngày;",
    "bindingResultXSkusHaveBeen": "Kết quả liên kết: Việc liên kết tự động của {X} SKU và MSKU đã  hoàn tất",
    "automaticBindingSucceeded": "Tự động liên kết thành công",
    "mskuCreationIsNotSupportedFor": "SKU kho bên thứ ba đã được liên kết MSKU không hỗ trợ tạo MSKU, hệ thống sẽ tự động lọc giúp bạn, hãy chắc chắn nắm rõ nhé",
    "mainProductImage": "Hình ảnh sản phẩm Chính",
    "AfterUnbindingTheStatusOf": "3. Sau khi hủy liên kết, trạng thái của phiếu xuất và nhập kho tương ứng với MSKU của sản phẩm trong kho của bên thứ ba được cập nhật thành Vô hiệu.",
    "AfterUnbindingTheInventoryList": "2. Sau khi hủy liên kết, dữ liệu tồn kho được tạo ra bởi liên kết của MSKU với sản phẩm kho của bên thứ ba sẽ bị xóa.",
    "AfterUnbindingTheBindingRelationship": "1. Sau khi hủy liên kết, quan hệ ràng buộc giữa sản phẩm trong kho bên thứ ba và sản phẩm Chính sẽ được hủy",
    "systemRecommended": "Đề xuất hệ thống",
    "listingPullFailed": "Không thể lấy thông tin sản phẩm",
    "theListingWasPulledSuccessfully": "Thông tin sản phẩm được lấy thành công",
    "pullResultTheSkuInformationOf": "Kết quả kéo: Thông tin SKU của kho hàng bên thứ ba đã được kéo, SKU: XXXX / XXXX",
    "sourceOfErrorRepository": "Nguồn lỗi: Kho hàng",
    "reasonForFailureXxxxxx": "Nguyên nhân thất bại:XXXXXX",
    "pullResultAddedXxxPiecesOf": "Kết quả kéo: kéo đến {XXX} phần dữ liệu",
    "resultcause": "Kết quả/ nguyên nhân",
    "pullStatus": "Trạng thái kéo",
    "pullDuration": "Thời gian kéo",
    "noteThatPullRecordsAreOnly": "Vui lòng lưu ý bản ghi kéo chỉ lưu trong hệ thống 7 ngày, không thể kiểm tra bản ghi kéo quá 7 ngày",
    "xxxData": "{XXX} dữ liệu",
    "viewFailureInformation": "Kiểm tra thông tin thất bại",
    "upToCommoditiesCanBe": "Hỗ trợ chọn hàng loạt tối đa 50 sản phẩm một lần trong việc lấy thông tin sự kiện",
    "activityInformationPullFailed": "Đã lấy thông tin khuyến mãi thất bại",
    "pullResultXxxChannelCommodityActivity": "Kết quả lấy thông tin: lấy thành công {X} thông tin khuyến mãi của sản phẩm kênh",
    "activityInformationPullSucceeded": "Đã lấy thông tin khuyến mãi thành công",
    "onlyTheActivityInformationOfChannel": "Chỉ hỗ trợ lấy thông tin khuyến mãi của sản phẩm kênh được đồng bộ với Ginee có trạng thái đã lên kệ",
    "pullByCommodity": "Lấy dữ liệu sản phẩm",
    "pullByStore": "Lấy dữ liệu cửa hàng",
    "pullActivity": "Lấy dữ liệu khuyến mãi",
    "activeReservationSettings": "Thiết lập dự trữ khuyến mãi",
    "synchronizationTime": "Thời gian đồng bộ",
    "mskuReserved": "MSKU  & Dự trữ",
    "skuApplication": "SKU & nộp đơn",
    "notReserved": "Chưa dự trữ",
    "partialReservation": "Dự trữ một phần",
    "reserved": "Đã dự trữ",
    "activeReserveInventory": "hàng dự trữ",
    "pleaseTurnOnTheInventoryMonitoring": "Vui lòng bật nút [Quản lý và Đồng bộ tồn kho] để thao tác",
    "afterTheSetupIsCompletedThe": "Sau khi thiết lập xong, hệ thống chỉ tự động dành kho khuyến mãi cho các khuyến mãi Đang xử lý (bắt đầu). Khuyến mãi chưa bắt đầu sẽ không được đặt chỗ trước, nhưng đặt chỗ tự động sẽ được kích hoạt trong vòng 24 giờ trước khi khuyến mãi bắt đầu",
    "automaticallyReservedOnlyForInprogressActivities": "Tự động dành riêng cho các chương trình khuyến mãi Đang xử lý (Đã bắt đầu)",
    "onceSetUpTheSystemWill": "Sau khi thiết lập thành công, hệ thống sẽ tự động dự trữ tồn kho khuyến mãi chưa bắt đầu/ đang diễn ra",
    "unstartedAndInprogressActivitiesAreAutomatically": "Khuyến mãi chưa bắt đầu và đang diễn ra được dự trữ tự động",
    "beSureToSetAReservation": "Đảm bảo thiết lập chính sách dự trữ trước để đảm bảo rằng tồn kho khuyến mãi được dự trữ như thường theo nhu cầu kinh doanh;",
    "reservationPolicySettings": "Cài đặt sách lược dữ trữ",
    "reservationPolicy": "Sách lược dữ trữ",
    "afterItIsEnabledTheSystem1": "Sau khi kích hoạt, hệ thống sẽ tự động trữ hàng khuyến mãi theo khuyến mãi của nền tảng (Chỉ hỗ trợ nền tảng Shopee), có thể xem tại danh mục [Hàng - Hàng dự trữ]/[Hàng - Danh sách hàng]",
    "automaticallyReserveActiveInventory": "Tự động dự trữ cổ phiếu khuyến mãi",
    "afterItIsEnabledTheSystem": "Sau khi bật, hệ thống sẽ tự động kéo thông tin khuyến mãi Shopee cho bạn, có thể xem tại [Tồn kho - tồn kho khuyến mãi dự trữ]",
    "automaticallyPullPlatformActivity": "Kéo khuyến mãi nền tảng tự động",
    "theClosedEventDataSystemIs": "Hệ thống dữ liệu khuyến mãi đã kết thúc sẽ chỉ được lưu trong 30 ngày, quá 30 ngày sẽ không xem được trong danh sách này",
    "reasonNotReserved": "Lý do không dự trữ",
    "releaseTheReservation": "Hủy bỏ dự trữ",
    "TheActiveInventoryReservedBy": "3. Hủy bỏ tồn kho khuyến mãi của MSKU trong kho hàng sẽ được cập nhật thành 0",
    "AfterReleaseTheChannelActivity1": "2. Sau khi hủy bỏ, tồn kho áp dụng cho khuyến mãi của kênh sẽ không bị ảnh hưởng và vẫn có thể bán trên nền tảng, có thể xảy ra bán quá mức",
    "AfterReleaseTheChannelActivity": "1. Sau khi hủy bỏ, trạng thái khuyến mãi của kênh sẽ không bị ảnh hưởng",
    "confirmTheReleaseOfCurrentlyActive": "Bạn có chắc hủy bỏ tồn kho dự trữ khuyến mãi hiện tại?",
    "theActiveInventoryThatWasReserved": "Tồn kho khuyến mãi được dự trữ đã xóa thành công",
    "theActiveReservedInventoryWasEdited": "Chỉnh sửa thành công tồn kho khuyến mãi dự trữ",
    "obligate": "Dự trữ",
    "application": "Nộp đơn",
    "reservedInventoryEditsAreOnlySupported": "Các chương trình khuyến mãi chưa bắt đầu có thể chỉnh sửa kho dự trữ, bao gồm sửa đổi kho dự trữ và giá trị kho dự trữ",
    "mskuHasNoWarehouseInformationAnd": "MSKU không có thông tin kho hàng, không thể dự trữ thành công",
    "mskuWithUnboundChannelSkuPlease": "Nếu SKU kênh không liên kết với MSKU, vui lòng vào danh sách sản phẩm Chính/ danh sách sản phẩm kênh để hoàn thành việc liên kết và nhập kho",
    "automaticReservationOfActiveInventorySetting": "Chưa bật cài đặt tồn kho khuyến mãi dự trữ tự động",
    "commodityXxxMoveoutActivityIdxxx": "Sản phẩm kênh: {XXX} ID hoạt động đã xóa: {XXX}",
    "xxxManuallyReleaseTheReservedActive": "{XXX} Thủ công hủy bỏ tồn kho khuyến mãi dự trữ",
    "meetTheActiveInventoryReleaseRules": "Tuân thủ các quy tắc hủy bỏ tồn kho khuyến mãi, hủy bỏ tự động",
    "complyWithTheRulesOfAutomatic": "Tuân thủ các quy tắc tự động dự trữ của tồn kho đang hoạt động, tự động dự trữ",
    "deleteProduct": "Xóa sản phẩm",
    "unbindTheMasterProduct": "Hủy liên kết sản phẩm Chính",
    "bindTheMasterProduct": "Liên kết sản phẩm Chính",
    "editedTheAutoBindMasterSku": "Đã chỉnh sửa quy tắc liên kết tự động MSKU, quy tắc chuyển từ {1} thành {2}",
    "editedTheAutoCreateMasterSku": "Đã chỉnh sửa quy tắc tạo tự động MSKU, quy tắc chuyển từ {1} thành {2}",
    "createdMasterProductViaOpenapi": "Đã tạo sản phẩm Chính {0} từ Open-Api",
    "manuallyCreatedMasterProductFrom": "Sản phẩm chính được tạo theo cách thủ công {} từ sản phẩm trong kho hàng",
    "automaticallyCreatedMasterProductFromWarehouse": "Sản phẩm Chính được tạo tự động {} từ sản phẩm trong kho hàng",
    "commonSearches": "Tìm kiếm phổ biến",
    "moreSearches": "Thêm bộ lọc",
    "becauseChannelSkuHasBeen": "Vì SKU kênh {0} đã bị xóa, SKU kênh {1} bị hủy liên kết với MSKU {2}",
    "updatedProductPriceFrom": "Đã chỉnh sửa giá của sản phẩm {0}, từ {1} thành {2}",
    "editedStockForProductFrom": "Đã chỉnh sửa tồn kho của sản phẩm {0}, từ {1} thành {2}",
    "editedChannelSkuForProduct": "Đã cập nhật SKU kênh của sản phẩm {0}, từ {1} thành {2}",
    "channelProductMatchToAutobind": "Sản phẩm kênh {0} tuân theo quy tắc liên kết tự động, đã liên kết MSKU{1}",
    "channelProductMatchesTheAutocreatedRule": "Sản phẩm kênh {0} tuân theo quy tắc tạo tự động, đã tạo MSKU{1}",
    "channelProductIsManuallyBound": "Sản phẩm kênh {0} đã liên kết thủ công MSKU{1}",
    "channelProductManuallyCreatedMsku": "Sản phẩm kênh {0} đã tạo thủ công MSKU{1}",
    "distribution": "Phân bổ",
    "removeSelectedLogistics": "Bỏ đơn vị vận chuyển đã chọn {}",
    "PickListIsPrinted": "Phiếu lấy hàng đã in",
    "printedPacking": "Phiếu đóng gói đã in",
    "InvoiceIsPrinted": "Hóa đơn đã in",
    "printeLabels": "Vận đơn đã in",
    "bopis": "BOPIS",
    "bigProduct": "Big Product",
    "saveSearch": "Lưu tìm kiếm",
    "commonSearchNames": "Tên tìm kiếm phổ biến",
    "radio": "Radio",
    "multiplechoice": "Chọn nhiều",
    "updateTheOrderStatus": "Cập nhật trạng thái đơn hàng",
    "updatedStockForProductFrom": "Đã cập nhật tồn kho của sản phẩm {0}, từ {1} thành {2}",
    "mengeditDeskripsiPanjangMasterProdukDari": "Đã chỉnh sử mô tả sản phẩm chính, từ {0} thành {1}",
    "updatedAvailableStockForMskuTo": "Đã cập nhật tồn kho có sẵn của MSKU{}, tồn kho sau cập nhật là {}",
    "nameManagement": "Quản lý tên",
    "nameList": "Quản lý danh sách",
    "ForChannelProductsWithVariations": "2. Đối với các sản phẩm kênh có thuộc tính, tên thuộc tính và tên sản phẩm được sử dụng là MSKU.",
    "ChannelProductsWithoutVariationsUse": "1. Sản phẩm kênh không có thuộc tính sử dụng SPU là MSKU.",
    "theSystemDetectsThatSomeSkus": "Hệ thống phát hiện một số SKU của sản phẩm kênh trống, vui lòng xác nhận xem có bật các quy tắc mặc định của hệ thống hay không:",
    "updatedProductShortDescriptionFrom": "Đã cập nhật mô tả sản phẩm, từ {0} thành {1}",
    "fuzzyOnlySupportsSingle": "Gần đúng chỉ hỗ trợ đơn lẻ",
    "scanShipment": "Quét lô hàng",
    "editMasterProductName": "Sửa Tên Sản phẩm Chính",
    "editChannelProductName": "Chỉnh sửa tên sản phẩm kênh",
    "newProductName": "tên sản phẩm mới",
    "utilityTool": "Tiện ích",
    "TimesScanningIsRequiredFor": "2 lần: Cần quét 2 lần, quét đầu tiên hiển thị thông tin đơn đặt hàng, lần thứ 2 tạo hồ sơ quét và di chuyển đơn đặt hàng vào danh sách đang chờ xử lý theo cài đặt",
    "TimeScanOnceGenerateA": "1 lần: Quét 1 lần, tạo bản ghi quét và di chuyển đơn đặt hàng vào gói đang chờ xử lý theo cài đặt",
    "twice": "Hai lần",
    "once": "Một lần",
    "scanResultEffectiveTimes": "Số lần kết quả quét có hiệu lực",
    "afterScanningItWillAutomaticallyMove": "Sau khi quét tự động di chuyển vào đang chờ nhận",
    "scanSettings": "Quét cài đặt",
    "scanned": "Đã quét",
    "pleaseScanOrEnterTheCourier": "Vui lòng quét hoặc nhập số chuyển phát nhanh",
    "scanByCourier": "Đang quét số theo dõi",
    "afterTheScanIsSuccessfulThe": "Sau khi quét thành công, đơn đặt hàng sẽ tự động di chuyển vào danh sách [đang chờ xử lý] dựa trên cài đặt, hãy đảm bảo rằng cài đặt đã hoàn tất và cài đặt mặc định là không bật",
    "scanRecord": "Quét hồ sơ",
    "scanSucceeded": "Quá trình quét đã thành công",
    "xxxxnumberDoesNotExist": "{xxxxx} mã đơn không tồn tại",
    "xxxxxHasBeenScannedPleaseDo": "{XXXXX}đã được quét, không lặp lại quét",
    "xxxxxIsNotASelfownedWarehouse": "{XXXXX}không phải là đơn đặt hàng thực hiện vị trí riêng của mình, không thể quét lô hàng",
    "xxxxxIsNotAnOrderIn": "{XXXXX}không phải là đơn đặt hàng trạng thái chờ thanh toán, không thể quét lô hàng",
    "xxxxxDoesNotExistPleaseCheck": "{XXXXX}không tồn tại, vui lòng kiểm tra và quét lại",
    "scanFailed": "Quá trình quét thất bại",
    "pleaseScanOrEnterMskubarcode": "Vui lòng quét hoặc nhập MSKU/Barcode",
    "useState": "Trạng thái sử dụng",
    "shelfName": "Tên kệ",
    "informationShelf": "Thông tin kệ",
    "modifyShelves": "Sửa đổi kệ",
    "commodityDetails": "Chi tiết hàng hóa",
    "storageRack": "Kệ",
    "pleaseSelectAWarehouseAndAdd": "Vui lòng chọn thêm mặt hàng sau khi kho",
    "pleaseChangeToEnInputMethod": "Vui lòng chuyển sang phương thức nhập [EN] trước khi quét",
    "scanResults": "Quét kết quả",
    "notScanned": "Không quét",
    "scanStatus": "Trạng thái quét",
    "shelfNumber": "Số kệ",
    "courierNumber": "Mã vận đơn",
    "sellerCashbackCoin": "Xu nhà bán hoàn trả",
    "tax": "Thuế",
    "sunTotal": "Tổng phụ",
    "currentShelf": "Kệ hiện tại",
    "vacant": "Trống rỗng",
    "inboundAndOutboundRecordAdjustmentMethod": "Cách điều chỉnh hồ sơ xuất nhập cảnh = tổng trong hồ sơ xuất kho thủ công (giá mua trung bình * giá trị thay đổi).",
    "adjustmentMethodOfInboundAndOutbound": "Cách điều chỉnh hồ sơ xuất nhập cảnh = tổng trong hồ sơ bán hàng (giá mua trung bình * giá trị thay đổi).",
    "calculationDescriptionAccordingToTheExport": "Hướng dẫn cách tính: Dựa theo mẫu xuất, thời gian tạo đơn hàng nằm trong khoảng thời gian được lọc, và với trạng thái = Tổng tiền {XXXX} của các đơn hàng đã hoàn thành",
    "allScanned": "Tất cả quét",
    "partiallyScanned": "Một phần đã quét",
    "warehousesKeeppackWarehousesListings": "Kho / Kho Keeppack / Thông tin hàng hóa / Tạo hàng hóa chính",
    "warehouseKeeppackWarehouseProduct": "Kho / Kho Keeppack / Thông tin hàng hóa",
    "inOrderToImproveThePull": "Để cải thiện hiệu quả kéo, thời gian kéo duy nhất không quá 15 ngày;",
    "bindingRecordsAreOnlyValidFor": "Bản ghi ràng buộc chỉ có giá trị trong 30 ngày và bản ghi ràng buộc vượt quá 30 ngày sẽ không thể xem được",
    "pullItems": "Kéo hàng hóa",
    "onlyProductsIssuedByGineeAre": "Chỉ hiển thị các mặt hàng được phát hành bởi Ginee",
    "synchronizeTimeInDescendingOrder": "Đồng bộ hóa thời gian giảm dần",
    "synchronizeTimeInAscendingOrder": "Đồng bộ hóa thời gian tăng dần",
    "areYouSureUnbindYouWantTo": "Xác nhận cởi trói?",
    "mskuCreationIsNotSupportedForTip": "SKU ba bên bị ràng buộc MSKU không hỗ trợ việc tạo MSKU, hệ thống sẽ tự động lọc cho bạn, hãy chắc chắn để biết",
    "pullTheProduct": "Kéo sản phẩm",
    "beforeBatchEditingYoudBetterSynchronize": "Trước khi chỉnh sửa hàng loạt, bạn nên đồng bộ các sản phẩm để giữ cho dữ liệu nhất quán với trung tâm người bán",
    "syncAndEdit": "Đồng bộ & Chỉnh sửa",
    "skipAndEdit": "Bỏ qua & Chỉnh sửa",
    "connectWithAllOfYourCustomer": "Kết nối với tất cả khách hàng từ một hệ thống",
    "makesItEasyToMonitorThe": "Thật tiện lợi khi đã có thể giám sát các hoạt động trong nhiều kho hàng và quản lý tồn kho cùng lúc",
    "goBackToTheList": "Quay lại danh sách",
    "xxxxDoesNotExistPleaseScan": "{XXXX}không tồn tại, vui lòng quét lại sau khi sản phẩm chính được thêm vào",
    "theCombinationXxxCannotBePut": "Kết hợp sản phẩm{XXX} không thể được nhập bằng tay, vui lòng xóa và thử lại",
    "theTrackingNumberXxxxWasSuccessfully1": "Số theo dõi {0} đã được quét thành công và trạng thái quét đơn hàng {1} đã được cập nhật thành Đã quét và chuyển sang Đang chờ nhận hàng",
    "theTrackingNumberXxxxWasSuccessfully": "Số theo dõi {XXXX} đã được quét thành công và trạng thái quét của đơn hàng {XXXX} đã được cập nhật thành Đã quét",
    "waitingInbound": "Chờ nhập kho",
    "upgrade": "Nâng cấp",
    "about": "Giới thiệu",
    "pleaseSelectACategoryFirst": "Vui lòng chọn một danh mục trước",
    "subAccount": "Tài khoản con",
    "authorizedStores": "Cửa hàng khả dụng",
    "availableQuantity": "Đơn hàng khả dụng",
    "addImagesToEachVariantOption": "Thêm hình ảnh vào từng tùy chọn thuộc tính",
    "productInformationDisplayedOnTheLogistics": "Thông tin sản phẩm hiển thị trên vận đơn (JD/Bukalapak)",
    "tiktokPrintShippingLabelAndPick": "(Tiktok) in vận đơn và phiếu lấy hàng",
    "downloadDocument": "Tải tài liệu:{0}",
    "thereAreOrdersThatStillBeing": "Vẫn còn đơn đang được in. Sau khi đóng cửa sổ bật lên, bạn có thể tải xuống  vận đơn trong lịch sử in.",
    "printHistory": "Lịch sử in",
    "printAll": "In tất cả",
    "maximumOrdersCanBePrinted": "Có thể in tối đa 1.000 đơn hàng cùng lúc, và bản PDF vận đơn được tạo cho mỗi kênh, sẽ mất nhiều thời gian",
    "unlimited": "Không giới hạn",
    "otherCharges": "Các chi phí khác",
    "bearTheCost": "Thanh toán",
    "authorizationStatusTotalNumberOf": "Trạng thái ủy quyền = Tổng số cửa hàng của sàn/webstore \"không hoạt động\"",
    "expiredInfo": "Trạng thái ủy quyền = Tổng số cửa hàng của sàn/webstore \"đã hết hạn\"",
    "storeStatusOfAllAuthorizedChannels": "Trạng thái cửa hàng của tất cả kênh được ủy quyền = Tổng số sản phẩm \"hết hàng\"",
    "status_banned_info": "Trạng thái cửa hàng của tất cả kênh được ủy quyền = Tổng số sản phẩm \"cấm bán\"",
    "theSumOfTheNumberOf": "Tổng số sản phẩm đăng thất bại lên  kênh đã được ủy quyền",
    "stockListPageNotInboundTab": "[Danh sách hàng tồn kho] Số lượng MSKU trên trang [Chưa nhập kho] tab",
    "theNumberOfMskusThatHave": "Số lượng MSKU đã được thêm vào kho và chưa được tạo để tăng tồn kho",
    "stockListPageStockWarningTab": "Số lượng MSKU trong trang [Hàng tồn kho] và tab [Cảnh báo tồn kho]",
    "totalMskuWhichTheAvailableStock": "Tổng MSKU có tồn kho có sẵn ít hơn tồn kho an toàn",
    "totalMskuOfStockListPage": "Tổng số MSKU của trang [Hàng tồn kho] trong tab [Hết hàng]",
    "totalMskusWithAvailableStock": "Tổng số MSKU có 0 tồn kho",
    "TotalOrderOf": "Tổng số đơn hàng của [Quản lý trả hàng - Trả hàng và hoàn tiền] trong tab [Chờ xử lý]",
    "returnRequestInfo": "Đơn hàng Shopee/Lazada/Tiktok yêu cầu người bán xử lý khi người mua gửi yêu cầu đổi trả",
    "totalOrderOfReturnManagement": "Tổng số đơn hàng của [Quản lý trả hàng - Danh sách hoàn tiền] trong tab [Chờ xử lý]",
    "shopeelazadatiktokOrdersThatRequireTheSeller": "Đơn hàng Shopee/Lazada/Tiktok yêu cầu người bán xử lý khi người mua gửi yêu cầu hoàn tiền",
    "ordersThatHaveBeenPushed": "Tổng số đơn hàng của trang [Chờ xử lý-Kho hàng đẩy] trong tab [Đẩy thất bại], vui lòng đảm bảo ưu tiên xử lý",
    "ordersThatHaveBeenPushedTo": "Các đơn hàng đã được hệ thống đẩy thủ công hoặc tự động vào kho bên thứ ba, nhưng API kho bên thứ ba trả về kết quả thất bại",
    "totalOrderOfPendingpushWarehousePage": "Tổng số đơn hàng của trang [Chờ xử lý-Kho hàng đẩy] trong tab [Chờ đẩy], vui lòng đảm bảo đẩy đúng hạn",
    "ordersThatNeedToBePushed": "Đơn hàng cần được đẩy đến kho bên thứ ba để thực hiện, nhưng chưa được đẩy",
    "totalOrderOnAbnormalOrdersPage": "Tổng số đơn hàng trên trang [Đơn hàng bất thường], nếu có dữ liệu liên quan, vui lòng đảm bảo thủ công đồng bộ đơn hàng",
    "ordersWithIncompleteInformationDueTo": "Đơn hàng không có thông tin đầy đủ do hạn chế QPS trên API kênh",
    "theNumberOfOrdersInThe": "Tổng đơn hàng của trang [Đơn chờ in] trong tab [Có thể in], có thể được xử lý nhanh chóng và thống nhất",
    "allPrintableShippingLabelsAreDisplayed": "Tất cả đơn hàng có thể in vận đơn sẽ được hiển thị trong danh sách này",
    "toBeProcessedArrangeShipment": "[Chờ xử lý - Chuẩn bị hàng] Tổng số đơn hàng trong mỗi tab của trang có thể được ưu tiên và thống nhất để phân phối đa kênh và đa cửa hàng",
    "allShippableOrdersAreDisplayedIn": "Tất cả các đơn hàng có thể vận chuyển được hiển thị trong danh sách này",
    "pendingproblemOrdersTheSumOfThe": "[Đơn hàng Chờ xử lý-Có vấn đề] Tổng số đơn hàng trong mỗi tab của trang, vui lòng đảm bảo ưu tiên xử lý trước",
    "whenTheOutboundListCannotBe": "Khi không thể tạo đơn hàng xuất kho hoặc MSKU hết hàng, nó sẽ được xác định là đơn hàng có vấn đề",
    "xxxxThereIsMoreThanOne": "{XXXX} trong hệ thống có nhiều hơn một sản phẩm, MSKU và Barcode bị trùng lặp, vui lòng kiểm tra và thử lại",
    "servicePH": "Nhấp và liên hệ với chúng tôi",
    "serviceCN": "Cố vấn tận tâm của bạn wechat:Ginee888",
    "serviceTH": "Nhóm hỗ trợ Ginee Thái Lan",
    "serviceVN": "Nhóm hướng dẫn Zalo",
    "serviceMYandID": "Nhóm hỗ trợ Ginee",
    "accountInfo": "Thông tin Tài khoản",
    "refundApplication": "Yêu cầu hoàn tiền",
    "returnRequest": "Yêu cầu hoàn trả",
    "orderManagement": "Quản lý đơn hàng",
    "sumOfTheNumberOfShopeelazada": "Trạng thái ủy quyền=\"Được ủy quyền\" và tổng số lượng cửa hàng Shopee/Lazada có hiệu lực dưới 30 ngày",
    "shopeebukalapaktokopediaOrdersUseTheGineeTemplate": "Đơn hàng Shopee/Bukalapak/Tokopedia sử dụng mẫu Ginee để in đơn nhanh, các kênh khác tạm thời chỉ hỗ trợ in đơn hàng nhanh theo mẫu kênh",
    "highSpeedPrintTaskDetails": "Chi tiết tác vụ in tốc độ cao",
    "printTaskDetails": "Chi tiết tác vụ in",
    "regularPrint": "In thường",
    "printUnlimitedShippingLabelInSingle": "In vận đơn không giới hạn đối với bản in đơn lẻ, không hỗ trợ xem trước bản in",
    "clickHereToViewDetailedInstructions": "Bấm vào đây để xem hướng dẫn chi tiết",
    "inOrderToImprovePrintingSpeed": "Để cải thiện tốc độ và trải nghiệm in, khuyến khích sử dụng in tốc độ cao",
    "itIsDetectedThatYouHave": "Bạn đã chọn hơn 300 đơn hàng, và hiện không hỗ trợ in bằng mẫu vận đơn Ginee",
    "thePrintingTaskHasExpiredPlease": "Tác vụ in hết hạn, vui lòng đến [Đơn 90 ngày qua > lịch sử đơn hàng] để kiểm tra nhật ký in",
    "imageSizeLengthLimit": "Có thể cùng lúc tải lên nhiều hình ảnh. Tối thiểu {width} x {height} pixel, tối đa {size}MB (Tối đa {maxLength} ảnh)",
    "imageManagement": "Quản lý Hình ảnh",
    "noMskuInTheDocumentHas": "Không có msku nào trong tài liệu có sự khác biệt hàng tồn kho mà không cần tạo phiếu điều chỉnh",
    "inventoryHasNotBeenCompletedPlease": "Kiểm kê chưa hoàn tất, hãy ưu tiên hoàn thành thao tác sau khi kiểm kê",
    "stocktakingNumber": "Mã kiểm kê",
    "checkingStock": "Đang kiểm kê",
    "waitingStocktaking": "Chờ kiểm kê",
    "importStockTakingResult": "Nhập kết quả kiểm kê",
    "mskuNumber": "MSKU số",
    "stocktakingType": "Loại kiểm kê",
    "numberOfMskuWithCountingDifferences": "Số lượng MSKU khác nhau trong kiểm kê",
    "numberOfDifferences": "Số lượng chênh lệch",
    "generateInventoryAdjustmentDocument": "Tạo danh sách điều chỉnh kho",
    "pleaseCheckTheSpecificInformationIn": "Vui lòng xem thông tin cụ thể [Kho - Phiếu điều chỉnh hàng tồn kho] và thực hiện điều chỉnh thủ công để cập nhật hàng tồn kho MSKU theo kết quả kiểm kê",
    "inventoryAdjustmentDocGeneratedSuccessfully": "Lệnh điều chỉnh hàng tồn kho đã được tạo ra thành công",
    "voidSuccessfully": "Hủy bỏ thành công",
    "afterVoidingItIsNotSupported": "Sau khi hủy bỏ, không hỗ trợ tiếp tục kiểm kê, MSKU đã kiểm kê cũng không hỗ trợ tạo lệnh điều chỉnh hàng tồn kho để điều chỉnh hàng tồn kho",
    "AfterTheCountingTheSystem": "3. Sau khi kiểm kê, hệ thống sẽ tự kiểm tra sản phẩm xuất kho thất bại trong quá trình kiểm kê, đơn hàng thực tế đã được giao/ đã hủy và đơn hàng xuất kho tự động xuất kho /gác lại, hệ thống sẽ chỉnh sửa tồn kho MSKU",
    "TheMskuInventoryThatHas": "2. MSKU kiểm kê chưa được đăng ký kiểm kê tồn kho sẽ không được cập nhật, và kết quả kiểm kê MSKU được cập nhật thành \"chưa kiểm kê\", hãy thao tác cẩn thận",
    "AfterTheInventoryIsFinished": "1. Sau khi kiểm kê kết thúc, trạng thái danh sách kiểm kê sẽ được thay đổi thành hoàn thành và không thể tiếp tục thao tác",
    "confirmTheEndOfTheInventory": "Xác nhận kết thúc kiểm kê?",
    "theCurrentTaskIsStillBeing": "Tác vụ hiện thời vẫn đang được tạo, hãy thao tác sau",
    "clickStartCountingAndTheStatus": "Sau khi nhấp vào [Bắt đầu kiểm kê], trạng thái danh sách kiểm kê sẽ được cập nhật thành [Kiểm kê] và sẽ tự động mở trang hoạt động kiểm kê cho bạn",
    "printInventoryList": "In đơn kiểm kê",
    "endInventory": "Kết thúc kiểm kê",
    "startCounting": "Bắt đầu kiểm kê",
    "inventoryByMsku": "Kiểm kê theo MSKU",
    "wholeWarehouseInventory": "Kiểm kê theo kho",
    "automaticallyFilterMskuXCombinationsAnd": "Đã tự động lọc kết hợp MSKU [ {bundleSku} ] cá, MSKU [ {countingSKU} ] trong nhiệm vụ kiểm kê chưa hoàn thành, lần này có thể kiểm kê MSKU tổng cộng [ {canCountSKU} ] cá nhân",
    "aTotalOfXMskuCan": "Có thể kiểm kê tổng cộng {X} MSKU lần này",
    "createInventoryTask": "Tạo phiếu kiểm kê",
    "aTotalOfXMskuAre": "Lần này tổng cộng {count} MSKU đang chờ kiểm kê, phiếu kiểm kê đang được áp dụng, vui lòng chờ và di chuyển đến danh sách kiểm kê tồn kho để tiếp tục thao tác",
    "inventoryCreationTaskSubmittedSuccessfully": "Tác vụ tạo phiếu kiểm kê đã gửi thành công",
    "afterConfirmationTheFilledInformationWill": "Sau khi xác nhận, thông tin đã điền sẽ không được lưu",
    "areYouSureYouDontWant": "Xác nhận rằng không cần lưu thông tin hiện tại chứ?",
    "currentWarehouseInventory": "Kho dự trữ hiện tại",
    "inventoryIsNotSupportedForCombination": "Các sản phẩm kết hợp và MSKU trong tác vụ kiểm kê chưa hoàn thành sẽ không hỗ trợ kiểm kê",
    "mskuDetails": "MSKU chi tiết",
    "inventory": "Kho",
    "afterSavingTheInformationWillOnly": "Sau khi lưu, thông tin sẽ chỉ được lưu trong danh sách kiểm kê và hỗ trợ sửa đổi lại",
    "inventoryLoss": "Hụt kho",
    "inventoryProfit": "Dư kho",
    "panping": "Cân đối",
    "inventoryResults": "Kết quả kiểm kê",
    "takeStockInTheWarehouse": "Tồn kho kiểm kê",
    "takeStockOf": "Kiểm kê",
    "counting": "Số lượng kiểm kê",
    "inventorySheet": "Mã kiểm kê",
    "notCounted": "Chưa kiểm kê",
    "TheCurrentAdjustmentDocumentStatus": "3. Trạng thái phiếu điều chỉnh hiện tại được cập nhật thành [Điều chỉnh]",
    "TheCurrentMskuAdjustmentStatus1": "3. Trạng thái điều chỉnh MSKU hiện tại được cập nhật thành [Chưa điều chỉnh]",
    "TheSystemWillNotChange": "2. Hệ thống sẽ không thay đổi tồn kho MSKU hiện tại",
    "OnceItIsConfirmedThat": "1. Một khi xác nhận không cần điều chỉnh, sẽ không thể thao tác lại",
    "confirmThatTheCurrentMskuInventory": "Xác nhận tồn kho MSKU hiện tại không cần điều chỉnh chứ?",
    "TheCurrentMskuAdjustmentStatus": "3. Trạng thái điều chỉnh MSKU hiện tại được cập nhật thành [Đã hoàn thành]",
    "withoutAdjustment": "Không cần điều chỉnh",
    "confirmAdjustment": "Xác nhận điều chỉnh",
    "unadjusted": "Chưa điều chỉnh",
    "singleAdjustment": "Điều chỉnh hàng loạt",
    "continueToAdjust": "Tiếp tục điều chỉnh",
    "startAdjusting": "Bắt đầu điều chỉnh",
    "inventoryReductionMsku": "Giảm tồn kho MSKU",
    "increaseInventoryMsku": "Tăng tồn kho MSKU",
    "totalMsku": "Tổng MSKU",
    "adjusting": "Đang điều chỉnh",
    "toBeAdjusted": "Chờ điều chỉnh",
    "adjustTheSingleNumber": "Điều chỉnh không",
    "afterItIsTurnedOnMsku": "Sau khi mở, MSKU trong quá trình kiểm kê không hỗ trợ tăng hay giảm tồn kho, không ảnh hưởng đến việc khóa tồn kho, hãy chắc chắn bạn biết thông tin nhé",
    "mskuDuringTheCountingPeriodDoes": "MSKU trong quá trình kiểm kê không hỗ trợ các thao tác xuất nhập",
    "afterAdjustment": "Sau điều chỉnh",
    "beforeAdjustment": "Trước điều chỉnh",
    "inventoryVariance": "Chênh lệch tồn kho",
    "stockAdjustment": "Điều chỉnh tồn kho",
    "continueCounting": "Tiếp tục kiểm kê",
    "confirmIntegrations": "Xác nhận tích hợp",
    "mskuXDoesNotExistIn": "MSKU {X} không tồn tại trong kho hàng {X}, vui lòng thêm MSKU vào kho trước",
    "orderXxxxxStatusChangedToShipping": "đặt hàng{XXXXX}trạng在{XXXX-XX-XX:XX}thay đổi thành Shipping",
    "xxxAfterTheInventoryCountIs": "{XXX} kết thúc kiểm kê hàng tồn kho, MSKU có thể hoạt động ra vào kho, theo trạng thái đơn đặt hàng thay đổi, tự động sửa chữa giá trị hàng tồn kho",
    "xxxGeneratesTheInventoryAdjustmentDocument": "{XXX} tạo hàng tồn kho điều chỉnh số duy nhất:{XXXX}, kiểm kê kết quả: thua lỗ",
    "xxxGenerateTheInventoryAdjustmentDocument": "{XXX} tạo ra số duy nhất điều chỉnh hàng tồn kho: {XXXX}, kết quả kiểm kê: doanh thu",
    "theCurrentRepositoryIsDeactivatedPlease": "Kho hiện tại đã bị hủy kích hoạt, hãy làm điều đó khi danh sách kho được bật",
    "theCurrentWarehouseIsNotIts": "Kho hiện tại không phải là kho riêng và không thể kiểm kê",
    "theCurrentRepositoryDoesNotExist": "Kho hiện tại không tồn tại, hãy hành động sau khi thêm vào danh sách kho",
    "theInventoryHasBeenTaken": "Đã kiểm kê",
    "AreYouSureToDelete": "bạn có chắc chắn xóa thông tin hiện tại không?",
    "defaultShelf": "Kệ mặc định",
    "AreYouSureToEnd": "Xác định kết thúc kiểm kê?",
    "mskuInInventoryExistsInThe": "MSKU trong tài liệu hiện tại được lưu trữ, sau khi kết thúc kiểm kê, hoạt động được nhập trực tiếp vào kho",
    "thereIsMskuInCountingIn": "MSKU trong tài liệu hiện tại được lưu trữ, sau khi kết thúc kiểm kê, hoạt động trực tiếp vào hoặc ra khỏi kho",
    "AreYouSureToVoid": "Có chắc là hủy bỏ thông tin hiện tại không?",
    "keywordid": "từ khoá",
    "onceTheAdjustmentIsConfirmedIt": "Một khi điều chỉnh đã được xác nhận, điều chỉnh không thể bị đình chỉ",
    "atTheBeginningOfAdjustmentThe": "Khi điều chỉnh bắt đầu, hàng tồn kho kho MSKU sẽ tự động được cập nhật thành giá trị hàng tồn kho được điều chỉnh",
    "addedInventoryList": "Thêm danh sách hàng tồn kho",
    "noMoreThanMaxCharacters": "Không quá {max} ký tự",
    "confirmAndUpdateMskuInventory": "Xác nhận điều chỉnh và cập nhật hàng tồn kho MSKU?",
    "areYouSureNotToSave": "Bạn có chắc không muốn lưu thông tin hiện thời không?",
    "createAnInventoryTask": "Tạo đơn kiểm kê",
    "countingStatus": "Trạng thái kiểm kê",
    "counted": "Đã kiểm kê",
    "saving": "Lưu trong...",
    "areYouCancel": "Có xác nhận muốn hủy bỏ?",
    "notCount": "Tài liệu hiện tại không phải đang chờ kiểm kê và không thể hoạt động. Vui lòng làm mới trang và thử lại.",
    "shouldBeBetweenXToX": "Phạm vi phải là từ X đến X",
    "offlineOrder": "Đặt hàng ngoại tuyến",
    "duringTheUnboundPeriodTheOutbound": "Trong khoảng thời gian không liên kết, đơn hàng xuất kho không thể được tạo để khóa/khấu trừ  tồn kho và không thể thực hiện đồng bộ tồn kho",
    "tokocabangStockChannelInterfaceDoesNot": "Vui lòng không liên kết kho Tokopedia O2O với kho Ginee WMS/của bên thứ ba, nếu không sẽ gây ra các lô hàng trùng lặp",
    "disabledChannelWarehousesCannotUpdateStock": "Kho kênh đã ngừng hoạt động, không thể cập nhật  tồn kho do các hạn chế API kênh;",
    "pleaseBeSureToCompleteThe1": "Vui lòng đảm bảo hoàn thành liên kết 1:1 giữa kho kênh và kho Ginee để thực hiện phân chia đơn hàng và đồng bộ tồn kho;",
    "systemWarehouseAddress": "Địa chỉ kho hàng hệ thống",
    "systemWarehouseName": "Tên kho hàng hệ thống",
    "channelWarehouseAddress": "Địa chỉ kho hàng kênh",
    "channelWarehouseName": "Tên kho hàng kênh",
    "channelWarehouseManagement": "Quản lý Kho hàng Kênh",
    "upgradePackage": "Nâng cấp gói",
    "askCustomerService": "Hỏi CSKh",
    "theCurrentStoreHasOpenedThe": "Cửa hàng hiện tại đã mở chức năng đa kho kênh, vui lòng nâng cấp lên gói hỗ trợ chức năng quản lý đa kho trước khi vận hành",
    "storeAuthorizationFailed": "Ủy quyền kho hàng thất bại",
    "youCanDirectlyAddProductsIn": "Vui lòng đảm bảo hoàn thành việc liên kết kho kênh và kho Ginee ngay lập tức, nếu không, bạn sẽ không thể xử lý đơn hàng/đồng bộ hàng trong kho",
    "pleaseBeSureToCompleteThe": "Vui lòng đảm bảo hoàn thành việc liên kết kho kênh và kho Ginee ngay, nếu không, bạn sẽ không thể xử lý đơn hàng/đồng bộ tồn kho",
    "theStoreHasOpenedTheChannel": "Cửa hàng đã mở dịch vụ đa kênh kho hàng và đang sử dụng dịch vụ đồng bộ kho",
    "authorizing": "Đang ủy quyền",
    "multiWarehouse": "Đa kho",
    "theGineeSystemReportsAnError": "Hệ thống Ginee báo lỗi, nguyên nhân như sau: {XXXXX}",
    "theChannelInterfaceReportsAnError": "Giao diện kênh báo lỗi, nguyên nhân như sau: {XXXXX}",
    "failedToPullChannelWarehouseInformation": "Kéo thông tin kho hàng kênh thất bại",
    "deactivateWarehouse": "Vô hiệu kho hàng",
    "addWarehouse": "Thêm kho hàng",
    "channelWarehouseInformationPulledSuccessfully": "Kéo thành công thông tin kho hàng kênh",
    "pullTheChannelWarehouseAddXxx": "Kéo kho hàng kênh, thêm {AAA}, vô hiệu {BBB}",
    "channelWarehouseXxxBindingWarehouseIs": "Kho hàng liên kết của kho hàng kênh {AAA} được cập nhật từ {BBB} thành {CCC}",
    "theChannelWarehouseXxxIsBound": "Kho hàng kênh {AAA} được liên kết với kho hàng của hệ thống {BBB}",
    "updateWarehouse": "Cập nhật kho hàng",
    "pleaseNoteThatTheOperationLog": "Xin lưu ý, nhật ký vận hành sẽ chỉ được lưu trong hệ thống trong 30 ngày và các bản ghi trên 30 ngày không thể kéo được",
    "thereIsAnUnfinishedSalesDelivery": "Có một đơn xuất kho chưa hoàn thành trong kho hàng hiện tại, không thể cập nhật mối quan hệ liên kết",
    "OrdersAllocated": "1. Đơn hàng đã phân bổ kho hàng kênh sẽ tự động phân bổ kho khớp theo quan hệ liên kết mới, không cần thiết lập quy tắc phân bổ kho",
    "areYouSureToUpdateThe": "Xác nhận cập nhật kho hàng liên kết chứ?",
    "OrdersThatHaveBeenAllocated": "1. Đơn hàng đã phân bổ kho hàng kênh sẽ tự động phân bổ kho khớp theo quan hệ liên kết, không cần thiết lập quy tắc phân bổ kho",
    "areYouSureToBindThe": "Xác nhận liên kết kho hàng hiện tại chứ?",
    "ownWarehouse": "Kho hàng chính chủ",
    "warehouseMarketplace": "Kho hàng kênh",
    "TheStatusOfTheCurrent": "3. Trạng thái đơn điều chỉnh hiện tại được cập nhật thành [Đã hoàn thành]",
    "lastSaleDateTips": "Ngày tạo của đơn hàng mới nhất của sản phẩm hiện tại",
    "notification74": "Tổng số tiền trong đơn hàng (bao gồm phí ship, v.v.) của các đơn hàng hợp lệ trong khoảng thời gian đáp ứng các điều kiện khó bán",
    "notification72": "Số lượng sản phẩm hiện tại bán ra của các đơn hàng hợp lệ trong một khoảng thời gian phù hợp với điều kiện hàng khó bán",
    "salesVolume": "Khối Lượng Hàng Bán",
    "notification70": "Tổng số người mua của tất cả đơn hàng trong một khoảng thời gian phù hợp với điều kiện hàng khó bán, lọc trùng lặp dựa vào số điện thoại di động",
    "totalNumberOfBuyers": "Tổng số người mua",
    "validOrder": "Đơn hàng hợp lệ",
    "notification66": "Tổng số đơn hàng trong một thời gian phù hợp với điều kiện hàng khó bán, bao gồm tất cả trạng thái",
    "totalNumberOfOrders": "Tổng số đơn hàng",
    "notification64": "Ngày tạo của đơn hàng mới nhất của sản phẩm hiện tại",
    "lastSaleDate": "Ngày bán hàng gần nhất",
    "notification58": "Chỉ hiển thị sản phẩm có Master SKU",
    "notification55": "Sản phẩm đã bán trong {day} ngày nhỏ hơn {number}",
    "notification54": "Trước khi sử dụng bảng thống kê hàng khó bán cần hoàn thành cài đặt điều kiện hàng khó bán",
    "slowSalesSettings": "Cài đặt hàng khó bán",
    "commodityCategory": "Danh mục sản phẩm",
    "slowSalesStatisticsTable": "Bảng thống kê hàng khó bán",
    "notification34": "Tạo tệp thất bại, vui lòng thử lại",
    "notification33": "Tệp sẽ được đồng bộ hiển thị trong danh sách lịch sử xuất, tệp chỉ lưu trữ 15 ngày, vui lòng kịp thời nhấp vào tải xuống",
    "notification32": "Tệp đã được tạo, vui lòng nhấp vào tải xuống",
    "generatingFilePleaseWait": "Đang tạo tệp, vui lòng chờ",
    "replenishStock": "Bổ sung",
    "unableEstimate": "Tạm thời không thể ước tính",
    "estimatedDaysTips": "(Tồn kho có sẵn - Tồn kho an toàn) / Số lượng bán hàng ngày, những ngày còn lại để đạt được lượng Tồn kho an toàn được ước tính theo số lượng hàng bán ra trung bình hàng ngày. Nếu số lượng bán hàng ngày nhỏ hơn 1, thì 1 sẽ được sử dụng để Ước tính",
    "estimatedDays": "Ngày ước tính",
    "avgQtySoldTips": "Số lượng đã bán, chu kì bán, bình quân hàng tồn kho bán ra mỗi ngày",
    "avgQtySold": "Số lượng bán ra mỗi ngày",
    "qtySoldTips": "Tổng số hàng tồn kho đã bán ra của Master SKU hiện tại trong chu kì bán hàng được chọn",
    "qtySold": "Số lượng đã bán",
    "safetyStockTips": "Số lượng Tồn kho an toàn của Master SKU hiện tại, ý nghĩa của Tồn kho an toàn phù hợp với danh sách quản lý hàng tồn kho",
    "availableStockTips": "Số lượng Tồn kho có sẵn của Master SKU hiện tại, ý nghĩa của hàng tồn kho có phù hợp với danh sách quản lý hàng tồn kho",
    "inventoryName": "Tên kho hàng",
    "inventorySKU": "MSKU",
    "notification151": "Hiểu Làm thế nào để Sử dụng Tồn kho an toàn",
    "days90": "90 ngày",
    "days60": "60 ngày",
    "days30": "30 ngày",
    "days15": "15 ngày",
    "days7": "7 ngày",
    "salesPeriod": "Chu kỳ bán hàng",
    "outOfStockEstimation": "Bảng dự báo hết hàng",
    "profitTips": "Chú ý: Lợi nhuận chỉ thống kê từ đơn hàng đã hoàn thành, và thời gian hỗ trợ lọc không vượt quá 30 ngày",
    "commissionSettingTips": "Lưu ý: Hiện đã hỗ trợ đặt hoa hồng cửa hàng từ Tokopedia và cửa hàng Shopify  đã liên kết, dữ liệu này chỉ được sử dụng để thống kê dữ liệu thu nhập, dữ liệu phí hoa hồng ở kênh khác dựa trên API kênh",
    "netProfitIncomeStatementTips2": "Lợi nhuận ròng / Báo cáo thu nhập = Thu nhập hoạt động + Thu nhập phi hoạt động - Chi phí hoạt động - Chi phí phi hoạt động - Chi phí khác",
    "netProfitIncomeStatementTips": "Lợi nhuận ròng / Báo cáo kinh doanh = Thu nhập hoạt động + Thu nhập ngoài kinh doanh - Chi phí hoạt động - Chi phí ngoài kinh doanh - Chi phí khác",
    "netProfitIncomeStatement": "Lợi nhuận ròng / Báo cáo kinh doanh",
    "outboundStockTips": "Ngày ghi nhớ nhập hàng tồn kho = Số lượng hàng nhập kho vào ngày đã chọn * Chi phí",
    "outboundStock": "Biến động hàng tồn kho (giảm xuống)",
    "refundFeeTips": "Ngày tạo đơn hàng = Tổng Phí hoàn lại trong ngày đã chọn",
    "refundFee": "Phí hoàn lại",
    "cashbackTips": "Ngày tạo đơn hàng = Tổng Tiền hoàn trong ngày đã chọn",
    "cashback": "Tiền hoàn (khuyến mãi)",
    "commissionFeeTips": "Ngày tạo đơn hàng = Tổng Phí hoa hồng trong ngày đã chọn",
    "commissionFee": "Phí hoa hồng",
    "serviceFeeTips": "Ngày tạo đơn hàng = Tổng phí dịch vụ trong ngày đã chọn",
    "serviceFee": "Phí dịch vụ",
    "otherExpensesTips": "Chi phí khác = Phí dịch vụ + Phí hoa hồng + Cashback + Phí hoàn lại + Biến động hàng tồn kho (giảm xuống)",
    "otherExpenses": "Chi phí khác",
    "taxTips": "Ngày tạo đơn hàng = Tổng số thuế trong ngày đã chọn",
    "insuranceTips": "Ngày tạo đơn hàng = Tổng phí bảo hiểm trong ngày đã chọn",
    "insurance": "Phí bảo hiểm",
    "nonOperatingExpensesTips": "Chi phí ngoài kinh doanh = Phí bảo hiểm + Thuế",
    "nonOperatingExpenses": "Chi phí ngoài kinh doanh",
    "costOfGoodSoldTips": "Ngày ghi nhớ hàng tồn kho = Số đơn hàng giao hàng vào ngày đã chọn * Chi phí",
    "costOfGoodSold": "Chi phí bán hàng",
    "operatingExpenseTips": "Chi phí hoạt động = Chi phí bán hàng",
    "operatingExpense": "Chi phí hoạt động",
    "inboundStockTips": "Ngày ghi nhớ hàng tồn kho = Số lượng hàng tồn kho tăng vào ngày đã chọn * Chi phí",
    "inboundStock": "Nhập thêm hàng tồn kho",
    "rebateTips": "Ngày tạo đơn hàng = Tổng chiết khấu trong ngày đã chọn",
    "rebate": "Chiết khấu",
    "nonOperatingIncomeTips": "Thu nhập ngoài kinh doanh = Phí ship",
    "nonOperatingIncome": "Thu nhập ngoài kinh doanh",
    "coinTips": "Ngày tạo đơn hàng = Tổng số coin trong ngày đã chọn",
    "coin": "Xu",
    "promotionTips": "Ngày tạo đơn hàng = Tổng số tiền Voucher trong ngày đã chọn",
    "promotion": "Khuyến mãi khác",
    "discountTips": "Ngày tạo đơn hàng = Tổng số tiền khuyến mãi trong ngày đã chọn",
    "discount": "Giảm giá",
    "salesAmountTips": "Ngày tạo đơn hàng = Tổng số tiền và Subtotal của ngày đã chọn",
    "salesAmount": "Doanh thu bán hàng",
    "operatingIncomeTips": "Thu nhập = Số tiền bán hàng - Giảm giá - Khuyến mãi + Coins + Chiết khấu",
    "operatingIncome": "Thu nhập hoạt động",
    "incomeStatement": "Bảng lợi nhuận",
    "refundOrdersTips": "Tổng số đơn hàng có trạng thái hoàn",
    "validBuyersTips": "Tổng số người mua trong đơn hàng hợp lệ",
    "buyersTips": "Tổng số người mua trong đơn hàng hợp lệ",
    "salesRateTips": "Tỷ lệ Doanh thu sản phẩm hiện tại trên Tổng doanh thu",
    "grossSalesTips": "Tổng số tiền",
    "qtySalesRateTips": "1. Thống kê Doanh số Sản phẩm: Doanh số của một sản phẩm / Tổng doanh số sản phẩm\n2. Thống kê bán hàng theo danh mục: Doanh số của 1 danh mục / Tổng doanh số\n3. Thống kê doanh số bán hàng tại cửa hàng: Doanh số của 1 cửa hàng / Tổng doanh số của tất cả cửa hàng\n4. Thống kê bán hàng theo kênh: Doanh số của 1 kênh / Tổng doanh số của tất cả kênh",
    "qtySalesTips": "Tổng sản phẩm bán ra trong Đơn hàng Hợp lệ",
    "validOrdersTips": "Đơn hàng Đã thanh toàn / Chờ Ship / Đang giao hàng / Đã nhận",
    "totalOrdersTips": "Tổng số lượng sản phẩm tương ứng với đơn hàng",
    "refundOrders": "Đơn hàng Hoàn",
    "validBuyers": "Người mua hợp lệ",
    "buyers": "Tổng số người mua",
    "salesRate": "Tỷ lệ doanh thu",
    "grossSales": "Tổng Thu nhập",
    "qtySalesRate": "Số lượng bán ra (%)",
    "qtySales": "Tổng doanh số",
    "validOrders": "Đơn hàng hợp lệ",
    "validOrderNumStatisticsTips": "Đơn hàng Đã thanh toàn / Chờ Ship / Đang giao hàng / Đã nhận",
    "totalProductNumStatisticsTips": "Tổng số sản phẩm có trong tất cả các đơn hàng, dữ liệu trùng lặp theo cửa hàng + tên sản phẩm Nếu mua cùng lúc 2 sản phẩm của cùng một loại sản phẩm trong một đơn hàng thì tổng số sản phẩm sẽ là 1",
    "totalValidBuyerTips": "Tổng người mua trong đơn hàng hợp lệ, lọc trùng lặp dựa vào số điện thoại di động",
    "shopNameTips": "Tên cửa hàng mà sản phẩm thuộc về",
    "mskuTips": "Thông tin Master SKU đã đồng bộ với thuộc tính tương ứng của sản phẩm trong đơn hàng, và sẽ không thay đổi theo cập nhật thông tin sản phẩm",
    "skuTips": "Thông tin SKU đã đồng bộ với thuộc tính tương ứng của sản phẩm trong đơn hàng, và sẽ không thay đổi theo cập nhật thông tin sản phẩm",
    "variationTips": "Tên Thuộc tính đã được đồng bộ tới sản phẩm trong đơn hàng và sẽ không thay đổi theo thông tin cập nhật sản phẩm",
    "productNameTips": "Tên cửa hàng mà sản phẩm thuộc về",
    "totalValidBuyerStatisticsTips": "Tổng người mua trong đơn hàng hợp lệ, lọc trùng lặp dựa vào số điện thoại di động",
    "totalQtySoldStatisticsTips": "Tổng số sản phẩm bán ra trong một đơn hàng hợp lệ Nếu mua đồng thời hai sản phẩm của cùng một loại sản phẩm trong một đơn hàng thì tổng doanh số bán ra sẽ là 2",
    "searchPlaceholder": "Vui lòng nhập nộp dung",
    "pleaseSelectCategory": "Vui lòng chọn danh mục",
    "productVariantsSalesStatistics": "Thống kê Thuộc tính sản phẩm bán hàng",
    "action73": "Thao tác",
    "datesPerPage": "Lượng dữ liệu của mỗi trang",
    "setPage": "Chọn số trang",
    "time69": "Thời gian",
    "notification68": "Nhắc nhở: Vui lòng Cài đặt phạm vi số trang của dữ liệu tải xuống",
    "variationName": "Tên Thuộc tính",
    "fieldDescription": "Đoạn mô tả",
    "detial": "Chi tiết",
    "action61": "Thao tác",
    "downloadFailed": "Tải xuống thất bại",
    "active": "Hợp lệ",
    "notification49": "File sẽ được hiển thị trong danh sách lịch sử xuất, và chỉ được lưu giữ trong 15 ngày, vui lòng bấm vào để tải xuống ngay",
    "returnRateTips": "Tổng đơn hàng hoàn / Tổng số đơn hàng",
    "returnRate": "Tỷ lệ hoàn",
    "refundOrderNumTips": "Tổng số đơn hàng với trạng thái hoàn",
    "refundOrderNum": "Đơn hàng hoàn",
    "repurchaseRateTips": "Phần trăm khách hàng có nhiều hơn 1 giao dịch",
    "repurchaseRate": "Tỷ lệ khách hàng quay lại",
    "grossSalesBuyer": "Tổng doanh thu / Tổng người mua",
    "customerAverageTransaction": "Đơn giá khách hàng",
    "buyerNumTips": "Tổng người mua trong đơn hàng hợp lệ",
    "buyerNum": "Người mua hợp lệ",
    "totalBuyerNumTips": "Tổng người mua của các đơn hàng",
    "totalBuyerNum": "Tổng số người mua",
    "saleRateTips": "1. Thống kê Doanh số Sản phẩm: Doanh số của một sản phẩm / Tổng doanh số sản phẩm\n2. Thống kê bán hàng theo danh mục: Doanh số của 1 danh mục / Tổng doanh số\n3. Thống kê doanh số bán hàng tại cửa hàng: Doanh số của 1 cửa hàng / Tổng doanh số của tất cả cửa hàng\n4. Thống kê bán hàng theo kênh: Doanh số của 1 kênh / Tổng doanh số của tất cả kênh",
    "saleRate": "Phần trăm doanh thu",
    "grossIncomeTips": "Tổng số tiền mỗi đơn hàng trong các đơn hàng hợp lệ, bao gồm chi phí vận chuyển và phí khác",
    "grossIncome": "Tổng doanh thu",
    "qtySaleRateTips": "1. Thống kê Doanh số Sản phẩm: Doanh số của một sản phẩm / Tổng doanh số sản phẩm\n2. Thống kê bán hàng theo danh mục: Doanh số của 1 danh mục / Tổng doanh số\n3. Thống kê doanh số bán hàng tại cửa hàng: Doanh số của 1 cửa hàng / Tổng doanh số của tất cả cửa hàng\n4. Thống kê bán hàng theo kênh: Doanh số của 1 kênh / Tổng doanh số của tất cả kênh",
    "qtySaleRate": "Tỷ lệ bán ra",
    "qtySaleNumTips": "Tổng số hàng đã bán trong các đơn hàng hợp lệ",
    "qtySaleNum": "Tổng số hàng bán ra",
    "effectiveRateTips": "Đơn hàng hợp lệ / Tổng số đơn hàng",
    "effectiveRate": "Tỷ lệ hợp lệ",
    "validOrderNumTips": "Đơn hàng Đã thanh toán / Sẵn sàng giao / Đang giao / Đã nhận",
    "validOrderNum": "Đơn hàng hợp lệ",
    "totalOrderNumTips": "Tổng số lượng sản phẩm tương ứng với đơn hàng",
    "totalOrderNum": "Tổng đơn hàng",
    "totalProductNumTips": "Lên kệ / Xuống kệ / Ban hết / Cấm bán",
    "totalProductNum": "Tổng sản phẩm",
    "totalValidBuyer": "Tổng người mua hợp lệ",
    "netIncomeTips": "Tổng giá trị của sản phẩm trong đơn hàng hợp lệ",
    "netIncome": "Tổng thu nhập bán hàng",
    "notification15": "Số lượng bán ra trong tổng số đơn hàng hợp lệ",
    "totalQtySold": "Tổng số lượng bán ra",
    "lowToHigh": "Từ thấp đến cao",
    "highToLow": "từ cao đến thấp",
    "storeSaleReport": "Bảng thống kê bán hàng của cửa hàng",
    "channelSaleReport": "Bảng thống kê kênh bán hàng",
    "revenuePerBuyerTips": "Tổng số tiền của đơn hàng / Số khách hàng",
    "revenuePerBuyer": "Doanh thu trên mỗi khách hàng",
    "monetaryUnit": "Đơn vị tiền tệ",
    "productOriginalPriceIncome": "Tổng thu nhập bán hàng: Tổng giá trị của sản phẩm trong đơn hàng hợp lệ; Tổng thu nhập: Tổng số tiền mỗi đơn hàng trong các đơn hàng hợp lệ, bao gồm chi phí vận chuyển và phí khác",
    "totalNetIncomeTips": "Tổng giá trị của sản phẩm trong đơn hàng hợp lệ",
    "totalNetIncome": "Tổng thu nhập bán hàng",
    "exportPdf": "Xuất đến PDF",
    "exportToPDF": "Xuất đến PDF",
    "countTips": "Số lượng bán ra không được nhỏ hơn 0",
    "selectTime": "Chọn thời gian",
    "exportExcel": "Xuất đến Excel",
    "exportTime": "Thời gian Xuất",
    "exportHistory": "Lịch sử Xuất",
    "title": "Chủ đề",
    "moveEnd": "Cuối",
    "moveTop": "Đầu",
    "moveDown": "Chuyển xuống",
    "moveUp": "Chuyển lên",
    "customField": "Tùy chọn",
    "taxTotalAmountTips": "Tổng tiền Thuế trong đơn hàng được tạo vào ngày hiện tại",
    "taxTotalAmount": "Tổng tiền Thuế",
    "systemShippingTotalAmountTips": "Tổng tiền ship trên hệ thống trong đơn hàng được tạo vào ngày hiện tại",
    "systemShippingTotalAmount": "Tổng tiền ship trên hệ thống",
    "serviceTotalAmountTips": "Tổng Phí Dịch vụ trong đơn hàng được tạo vào ngày hiện tại",
    "serviceTotalAmount": "Tổng Phí Dịch vụ",
    "commissionTotalAmountTips": "Tổng Phí Hoa hồng trong đơn hàng được tạo vào ngày hiện tại",
    "commissionTotalAmount": "Tổng Phí Hoa hồng",
    "insuranceTotalAmountTips": "Tổng tiền bảo hiểm trong đơn hàng được tạo vào ngày hiện tại",
    "insuranceTotalAmount": "Tổng tiền Bảo hiểm",
    "buyerTotalNumTips": "Tổng số khách hàng trong đơn hàng mà ngày tạo bằng ngày hiện tại (loại trừ trùng lặp bằng số điện thoại)",
    "buyerTotalNum": "Số khách hàng",
    "cancelOrderRateTips": "Đơn hàng Hủy/ tồng đơn hàng",
    "cancelOrderRate": "Tỷ lệ hủy",
    "cancelOrderTotalAmountTips": "Tổng số tiền (bao gồm phí ship/giảm giá v.v) của đơn hàng hủy trong ngày hiện tại, không tính số tiền đơn hàng hủy một phần",
    "cancelOrderTotalAmount": "Tổng tiền Hủy",
    "cancelOrderTotalNumTips": "Tổng số đơn hàng đã hủy trong ngày hiện tại, không tính đơn hàng hủy một phần",
    "cancelOrderTotalNum": "Đơn hủy",
    "returnOrderRateTips": "Đơn hàng Hoàn/tổng đơn hàng",
    "returnOrderRate": "Tỷ lệ Hoàn",
    "returnOrderTotalAmountTips": "Tổng số tiền (bao gồm phí ship/giảm giá v.v) của đơn hàng hoàn mà ngày tạo bằng ngày hiện tại, không tính số tiền đơn hàng hoàn một phần",
    "returnOrderTotalAmount": "Tổng số tiền hoàn",
    "returnOrderTotalNumTips": "Tổng số đơn hàng đã hoàn thành công trong ngày hiện tại, không tính đơn hàng hoàn một phần",
    "returnOrderTotalNum": "Tổng số Đơn Hoàn",
    "paymentTotalAmountTips": "Tổng số tiền của đơn hàng Đã thanh toán/Chờ ship/Đang ship/Đã nhận mà ngày tạo bằng ngày hiện tại (bao gồm phí ship, v.v)",
    "paymentTotalAmount": "Tổng số tiền của đơn hàng hợp lệ",
    "paymentTotalNumTips": "Tổng số đơn hàng Đã thanh toán/Chờ ship/Đang ship/Đã nhận mà ngày tạo bằng ngày hiện tại",
    "paymentTotalNum": "Đơn hàng Hợp lệ",
    "shippingTotalAmountTips": "Tổng tiền ship trong đơn hàng được tạo vào ngày hiện tại",
    "shippingTotalAmount": "Tổng tiền ship",
    "goodsTotalAmountTips": "Tổng số tiền của sản phẩm trong đơn hàng được tạo hiện tại (bao gồm Đang ship/giảm giá, v.v)",
    "goodsTotalAmount": "Tổng số tiền Sản phẩm",
    "orderTotalAmountTips": "Tổng số tiền của đơn hàng được tạo trong thời gian hiện tại (bao gồm Đang ship/giảm giá, v.v)",
    "orderTotalAmount": "Tổng số tiền Đơn hàng",
    "orderTotalNumTips": "Tổng số đơn hàng trong khoảng thời gian đáp ứng các điều kiện hàng khó bán, bao gồm tất cả các trạng thái",
    "orderTotalNum": "Tổng số Đơn hàng",
    "dayTips": "Ngày tạo Đơn hàng",
    "yesterday": "Hôm qua",
    "totalRefundAmountTips": "Ngày hoàn hàng bằng tổng số tiền hoàn  trong ngày hiện tại.Các đơn hàng bị trả lại một phần không được bao gồm trong thống kê số tiền hoàn lại (liên quan đến Lazada)",
    "totalRefundAmount": "Tổng số tiền hoàn",
    "returnOrderTips": "Tổng đơn hàng Hoàn đã được lọc",
    "shippingFeeTips": "Tổng phí ship của đơn hàng mà Ngày tạo bằng ngày hiện tại (tổng phí ship trong chi tiết đơn hàng)",
    "shippingFee": "Phí ship",
    "totalOrderAmountTips": "Ngày tạo bằng tổng tiền đơn hàng vào ngày hiện tại (bao gồm phí vận chuyển, v.v.)",
    "totalOrderAmount": "Tổng tiền Đơn hàng",
    "totalOrderNumberTips": "Tổng số đơn hàng mà Ngày tạo bằng  ngày hiện tại (tất cả trạng thái đơn hàng)",
    "totalOrderNumber": "Tổng số đơn hàng",
    "trendChart": "Biểu đồ xu hướng",
    "theSizeVariantDoesNotSupport": "Không hỗ trợ người đột Size thêm hình ảnh",
    "onlyShowAuthorizedStores": "Chỉ hiển thị các cửa hàng đã ủy quyền",
    "untickWithOneClick": "Bỏ chọn bằng một cú nhấp chuột",
    "pleaseUntickAndReoperate": "Vui lòng bỏ chọn và thao tác lại",
    "thereAreXExpireddeactivatedStoresIn": "Có {X} cửa hàng đã hết hạn/bị vô hiệu trong ủy quyền hiện tại",
    "failedToSave": "Lưu thất bại",
    "shortDescription": "Mô tả ngắn",
    "Item": "Mục",
    "shippingFeeTips2": "Ngày tạo đơn hàng = Tổng phí ship đã thanh toán và chưa thanh toán trong ngày đã chọn",
    "whenTheProductsInTheChannel": "Khi các sản phẩm trong hàng kho kênh được tự động tạo/liên kết với MSKU, chúng sẽ tự động được thêm vào kho hệ thống được ghép nối, nhưng bạn vẫn cần tự vận hành kho để đảm bảo tồn kho luôn chính xác nhất",
    "whenThereIsAStockChange": "Khi có sự thay đổi tồn kho trong kho hàng hệ thống được ghép nối, giá trị tồn kho sẽ được đẩy đến kho hàng kênh kịp thời",
    "channelWarehouseId": "ID kho hàng kênh",
    "forChannelProductsThatAreBound": "Đối với các sản phẩm kênh đã liên kết với kho hàng, sau khi MSKU được tạo, chúng sẽ tự động được thêm vào kho liên kết.",
    "forMultiwarehouseChannelsAndChannelProducts": "Đối với các kênh đa kho và các sản phẩm kênh liên kết với kho, sau khi liên kết MSKU, nó sẽ tự động được thêm vào kho được liên kết.",
    "forMultiwarehouseChannelsWithWarehouseboundChannel": "Đối với các kênh đa kho có sản phẩm kênh liên kết với kho, MSKU sẽ tự động được thêm vào kho liên kết sau khi tạo.",
    "existXwarehouse": "Có {X} kho hàng",
    "amountUnit": "Số tiền: (Đơn vị:",
    "thePriceOfTokoWillBe": "Giá Toko sẽ được cập nhật vào từng kho của kênh.",
    "priceAndStockWillBeUpdated": "Giá và tồn kho sẽ được cập nhật đến kho hàng mặc định",
    "dueToTheDelayInChannel": "Do dữ liệu cập nhật kênh bị chậm nên sẽ mất vài giây để dữ liệu đồng bộ được cập nhật, vui lòng kiên nhẫn đợi.",
    "theStoreWhereTheOrderIs1": "Cửa hàng nơi đặt hàng đã mở kênh đa kho, vui lòng liên kết kho Ginee và vận hành",
    "theStoreWhereTheOrderIs": "Cửa hàng đặt hàng đã mở kênh đa kho, chỉ hỗ trợ đổi sang kho Ginee ràng buộc {XXXX}",
    "theWarehouseXxxxAlreadyHasA": "Nhà kho đã có một nhiệm vụ kéo, có thể xem trong hồ sơ kéo. Hãy chờ đến khi nhiệm vụ hoàn thành.",
    "youNeedToIntegrateAstore": "Bạn cần liên kết cửa hàng {x} trước khi có thể sử dụng thanh toán {y}",
    "pleaseSelectTheStoreaccountTo": "Vui lòng chọn cửa hàng/tài khoản {x} để thanh toán",
    "theProductsXxxxInTheOrder": "Đơn hàng {XXXX} sản phẩm kênh {XXXX} được phân phối đến kho {XXXX} theo quan hệ liên kết giữa kho hàng kênh và kho hệ thống.",
    "theCurrentProductsWarehouseIsEmpty": "Kho hàng sản phẩm hoàn toàn trống rỗng. Đầu tiên, nhập vào KPPP.",
    "xIsOutOfStockIn": "{X} Thiếu hàng tồn kho trong {X}",
    "shopeebukalapaktokopediaOrdersUseGineeTemplateFor": "Đơn hàng Shopee/Bukalapal ?tokopediasử dụng mẫu của Ginee cho việc in vận đơn",
    "buyerPhone": "Số điện thoại khách hàng",
    "xNewWarehouses": "{X}nhà kho mới,ID nhà kho:{X}",
    "editStock": "Chỉnh tồn kho",
    "warehouseIsEmpty": "Nhà kho trống rỗng",
    "dueToTheLimitationsOfThe": "Do có giới hạn của hãng Keeppa API, những sản phẩm này không thể vận hành ở Ginee,Vui lòng thêm hàng tồn kho vào hệ thống Keeppack",
    "pleaseFindTheBindingEmailIn": "Hãy đến Trung tâm mua sắm Kopete (Thiết lập ứng dụng bên thứ ba) hoặc tìm thư điện tử trong hộp thư của bạn, đầu tiên chấp nhận yêu cầu kết nối",
    "created": "Tạo",
    "allstocktakingissuccessful": "Kiểm kê tất cả thành công",
    "partialstocktaking": "Kiểm kê một phần",
    "allstocktakingfailed": "Kiểm kê tất cả thất bại",
    "someexecute": "Một phần được thực hiện",
    "faileds": "Thất bại",
    "thePullTaskHasBeenCreated": "Nhiệm vụ kéo tạo thành công",
    "executed": "Đã hoàn thành",
    "keeppackWarehouse": "Kho Keeppack",
    "unbindMsku": "Hủy liên kết MSKU",
    "PullRecord": "Thành tích kéo kho",
    "thisStoreHasBeenConnectedTo": "Cửa hàng này đã được kết nối với Tài khoản Ginee khác vui lòng ngắt kết nối cửa hàng này khỏi các Tài khoản Ginee bên dưới trước khi thao tác:",
    "ManualInbound": "Nhập kho thủ công",
    "stocktakingInbound": "Nhập kho thặng dư",
    "NormalInbound": "Nhập kho thông thường",
    "notPushed": "Chưa đẩy",
    "partComplete": "Hoàn thành một phần",
    "partInvalid": "Vô hiệu một phần",
    "tripartiteSingleNumber": "Mã đơn {warehouse}",
    "storageInformation": "Thông tin Nhập kho",
    "types": "Loại",
    "plannedInbound": "Lập kế hoạch nhập kho",
    "receiptCost": "Chi phí nhập kho",
    "withAttachments": "Có phụ kiện",
    "automaticallyCreateMsku": "Tự động tạo MSKU",
    "createMskuManually": "Tạo MSKU bằng tay",
    "currentlyThirdPartyWarehouseSkuCode": "Trước mắt chị tạm thời hỗ trợ mã SKU kho bên thứ ba và mã MSKU phù hợp mới có thể liên kết ràng buộc",
    "warehouseKeeppackWarehouseInboundManagement": "Kho / Kho Keeppack /Quản lý Phiếu nhập kho",
    "warehouseKeeppackWarehouseOutboundManagement": "Kho / Kho Keeppack /Quản lý Phiếu xuất kho",
    "warehouseKeeppackWarehouse": "Kho / Kho Keeppack /Thành tích kéo kho",
    "theCurrentCommodityWarehouseIsEmpty": "Kho hàng hiện tại trống rỗng, hoàn thành thư viện nhập khẩu tại KPPP",
    "YouWillNotBeAble": "1. Bạn sẽ không thể xem thông tin đặt hàng cho cửa hàng này.\n2. Bạn không thể tìm thấy cửa hàng do mô-đun cài đặt cửa hàng.",
    "temperatureControlled": "Kiểm soát nhiệt độ",
    "standard": "Tiêu chuẩn",
    "storageType": "Kiểu lưu trữ",
    "enableWare": "Xác nhận bật chế độ đa kho chứ?",
    "afterEnablingTheMultiwarehouseModeAnd": "Sau khi kích hoạt chế độ đa kho và đăng tải sản phẩm, chế độ đa kho sẽ không thể tắt",
    "activateMultiwarehouse": "Bật đa kho",
    "turnOffMultiwarehouse": "Tắt đa kho",
    "wantTo": "Xác nhận tắt chế độ đa kho chứ?",
    "afterTheMultiwarehouseModeIsTurned": "Sau khi tắt chế độ đa kho, thông tin đa kho và tồn kho sẽ bị xóa",
    "kongfuWarehouse": "Kho hàng KONGFU",
    "useTheStockInformationOfThe": "Sử dụng thông tin tồn kho của kho KONGFU để kích hoạt tự động đồng bộ nhằm nâng cao hiệu quả quản lý",
    "afterTheAuthorizationIsSuccessfulYou": "Sau khi ủy quyền thành công, bạn có thể sử dụng dịch vụ fulfillment KONGFU để quản lý hàng hóa xuất nhập kho",
    "afterClickingTheAuthorizeButtonKongfus": "Sau khi nhấn nút [Đến ủy quyền], thông tin kho hàng của KONGFU sẽ được kéo về cho bạn, vui lòng chọn kho hàng cần thiết để hoàn tất quá trình ủy quyền",
    "PleaseContactKongfuCustomerService": "2. Vui lòng liên hệ bộ phận CSKH của KONGFU để lấy khóa bí mật",
    "ItIsRecommendedToFill": "1. Khuyến khích điền các thông tin đã thỏa thuận với KONGFU về tên tài khoản và tên người gửi hàng",
    "informationDescription": "Mô tả thông tin",
    "consignorName": "Tên người gửi hàng",
    "acuntName": "Tên tài khoản",
    "completeTheRepositoryAuthorizationConfirmationIn": "Hoàn thành xác nhận ủy quyền kho trong quản lý cấu hình của KONGFU",
    "afterSelectingAndSavingSubjectTo": "Sau khi chọn và lưu, vui lòng liên hệ với dịch vụ CSKH ngoại tuyến của KONGFU để hoàn tất việc xem xét ủy quyền kho hàng và quay lại hệ thống Ginee, trong [Ủy quyền - Danh sách cửa hàng] theo yêu cầu của quy trình ủy quyền KONGFU",
    "pleaseSelectTheWarehouseInformationThat": "Vui lòng chọn thông tin kho hàng cần ủy quyền và hoàn tất quy trình ủy quyền trực tuyến",
    "pleaseSelectTheWarehouseThatNeeds": "Vui lòng chọn kho hàng cần ủy quyền",
    "applyForAuthorization": "Xin ủy quyền",
    "afterTheAuthorizationIsCompletedPlease": "Sau khi hoàn tất kiểm tra, vui lòng đảm bảo quay lại hệ thống Ginee và hoàn thành xác nhận ủy quyền kho hàng trong phần quản lý cấu hình của [Ủy quyền - Danh sách cửa hàng] KONGFU",
    "pleaseContactKongfuCustomerServiceOffline": "Vui lòng liên hệ với bộ phận CSKH ngoại tuyến của KONGFU để hoàn tất việc xem xét ủy quyền kho hàng",
    "approvalStatus": "Tình trạng kiểm tra",
    "reasonForFailureTheThirdParty": "Lý do thất bại: Kho hàng bên thứ ba đã từ chối đơn đăng ký ủy quyền kho, vui lòng liên hệ với dịch vụ CSKH của kho bên thứ ba để xác nhận",
    "timeToOperateAuthorizedWarehouseInformation": "Thời gian vận hành thông tin kho ủy quyền tại Ginee",
    "theTimeToUpdateWarehouseInformation": "Thời gian cập nhật thông tin kho trong Ginee",
    "consignorCode": "Mã bên gửi hàng",
    "theSystemWillAutomaticallyUpdateThe": "Hệ thống sẽ tự động cập nhật trạng thái kiểm tra của kho bên thứ ba cho bạn mỗi giờ, nếu bạn cần xem kết quả kiểm tra trong thời gian thực, bạn có thể nhấp vào [Cập nhật trạng thái kiểm tra] theo cách thủ công để xem",
    "passed": "Đã thông qua",
    "theWarehouseAuthorizationApplicationInformationHas": "Thông tin ứng dụng ủy quyền kho hàng đã được thông báo thành công cho kho bên thứ ba",
    "afterConfirmationTheSystemWillCall": "Sau khi xác nhận, hệ thống sẽ lấy API KONGFU để bạn khởi tạo đơn xin cấp phép kho, sau khi xác nhận đơn, vui lòng liên hệ với nhân viên CSKH của KONGFU để hoàn tất duyệt xét cấp phép, hệ thống sẽ tự động đồng bộ trạng thái xét duyệt cho bạn mỗi giờ. Nếu bạn cần kiểm tra trạng thái, vui lòng thao tác [Cập nhật Trạng thái Kiểm tra] trên trang quản lý cấu hình để nhận kết quả kiểm tra",
    "areYouSureToInitiateA": "Xác nhận khởi tạo ứng dụng ủy quyền kho hàng cho kho của bên thứ ba chứ?",
    "initiateAuthorizationApplication": "Bắt đầu ứng dụng ủy quyền",
    "theListOnlyShowsUnauthorizedWarehouse": "Danh sách chỉ hiển thị thông tin kho chưa ủy quyền, vui lòng liên hệ với bộ phận CSKH ngoại tuyến của Kongfu để hoàn tất xác nhận kiểm toán sau khi xác nhận",
    "selectAuthorizedWarehouse": "Chọn kho ủy quyền",
    "gineeWillPullTheWarehouseInformation": "Ginee sẽ thông qua API KONGFU lấy thông tin kho hàng trong KONGFU, vui lòng chọn ủy quyền kho hàng cần thiết và liên hệ với bộ phận CSKH của KONGFU để hoàn tất việc xem xét ủy quyền kho hàng mới",
    "tipsa": "Lời nhắc ấm áp",
    "startToPullTheLatestWarehouse": "Bắt đầu kéo thông tin kho mới nhất của KONGFU, đợi giao diện KONGFU trả về thông tin, vui lòng kiên nhẫn chờ",
    "updateApprovalStatus": "Cập nhật trạng thái đánh giá",
    "confirmAuthorizedWarehouseIdXxxx": "Xác nhận ID kho được ủy quyền: XXXX , XXXX",
    "authorizationStatusXxxx": "Trạng thái ủy quyền: {XXXX}",
    "addWarehousesForApplicationAuthorization": "Thêm ủy quyền 3 kho, ID kho: XXXX , XXXX",
    "noMoreThanXxxCharacters": "Không vượt quá {X} ký tự",
    "customsWeight": "Trọng lượng khai báo",
    "customsDeclarationAmount": "Số tiền khai báo",
    "customsCode": "Mã hải quan",
    "customsEnglishName": "Khai báo Tên tiếng Anh",
    "customsDeclarationChineseName": "Khai báo Tên tiếng Trung",
    "ifTheWarehouseForThisDelivery": "Nếu kho giao hàng này là KONGFU và hàng hóa cần khai báo hải quan, vui lòng điền thông tin khai báo hải quan",
    "customsDeclarationInformation": "Thông tin hải quan",
    "theProductDeclarationInformationIsMissing": "Thông tin khai báo hàng hóa còn thiếu, vui lòng chuyển sang trang chỉnh sửa sản phẩm Chính để hoàn thiện và hoàn tất thao tác",
    "mskuDoesNotConformToThe": "MSKU không phù hợp với thông số kỹ thuật giao diện kho của bên thứ ba, vui lòng chỉnh sửa đổi và thao tác",
    "theProductNameDoesNotConform": "Tên sản phẩm không phù hợp với thông số kỹ thuật giao diện kho của bên thứ ba, vui lòng chỉnh sửa trước khi thao tác",
    "TheMskuDoesNotExceed": "Số MSKU không vượt quá 50 ký tự và chỉ hỗ trợ số, chữ cái và ký hiệu đặc biệt \"-\"",
    "ProductNameDoesNotExceed": "Tên sản phẩm không quá 200 ký tự",
    "channelRequirements": "Yêu cầu kênh",
    "ifTheProductsDeliveredToThe": "Nếu hàng về kho lần này là đợt hàng đi đầu tiên hoặc cần vận chuyển qua biên giới Trung Quốc, quý khách vui lòng chọn Khai báo và điền thông tin khai báo",
    "needToDeclare": "Cần khai báo",
    "noDeclarationRequired": "Không cần khai báo",
    "totalNumberOfSkus": "Tổng số SKU",
    "awb": "Số AWB",
    "outboundAmount": "Số tiền xuất kho",
    "outboundQuantity": "Số lượng xuất kho",
    "outboundInformation": "Thông tin xuất kho",
    "doNotEnableAutomaticBinding": "Không bật liên kết tự động",
    "thisFieldIsRequiredWhenThe": "Trường thông tin bắt buộc khi giao diện kho của bên thứ ba yêu cầu trả hàng vào kho",
    "returnTrackingNumber": "Trả mã vận đơn",
    "thirdpartyWarehouseTransactionReturn": "Phản hồi giao dịch kho bên thứ ba",
    "manualSync": "Đồng bộ thủ công",
    "timingSynchronization": "Đồng bộ định kì",
    "reasonReceivedAThirdpartyWarehouseInterface": "Nguyên do: Đã nhận lời nhắc từ API kho của bên thứ ba{x}",
    "actualInbound": "Nhập kho thực tế",
    "theOrderIsInconsistentWithThe": "Đơn hàng không phù hợp với quốc gia nơi đặt kho hàng, vui lòng thay đổi kho hàng kịp thời",
    "theChannelInterfaceCannotGenerateThe": "API kênh không thể tạo vận đơn và kho hiện tại không thể thực hiện hợp đồng. Vui lòng thay đổi kho kịp thời",
    "mskuIsNotAddToThe": "MSKU không thêm vào kho. Xin bổ sung trước.",
    "rejected": "Đã từ chối",
    "outboundListOutboundListInThe": "3. Phiếu xuất kho: Phiếu xuất kho trong kho, bao gồm mã số phiếu, trạng thái, chi tiết",
    "inboundListInboundListInThe": "2. Phiếu nhập kho: Phiếu nhập kho trong kho, bao gồm mã số phiếu, trạng thái, chi tiết",
    "productsProductsInTheWarehouseIncluding": "1. Sản phẩm: Sản phẩm trong kho, bao gồm chi tiết như giá trị tồn kho và giá trị trong kho",
    "agreeToAuthorizeGineeToObtain": "Đồng ý cho phép Ginee lấy các thông tin sau trong kho hàng",
    "editMultiwarehouseStock": "Chỉnh sửa tồn kho đa kho hàng",
    "totalStock": "Tổng tồn kho",
    "add2": "Thêm",
    "openMultiwarehouses": "Bật đa kho",
    "closeMultipleWarehouses": "Tắt đa kho",
    "noteHighSpeedPrintingDoesNot": "Lưu ý: In tốc độ cao không hỗ trợ in vận đơn tùy chỉnh của Ginee (Tokopedia tạm chỉ hỗ trợ in vận đơn tùy chỉnh của Ginee)",
    "massEditStockIsAvailableFor": "Chỉnh sửa tồn kho hàng loạt chỉ áp dụng đối với tồn kho của một kho duy nhất",
    "agreecontinueAuthorization": "Đồng ý, tiếp tục ủy quyền",
    "theStockAndProductInformationOf": "Thông tin tồn kho và sản phẩm của kho Fastock sẽ được tự động đồng bộ 2 chiều nhằm nâng cao hiệu quả quản lý",
    "afterClickingTheAuthorizeButtonYou": "Sau khi nhấn nút [Đến ủy quyền], bạn có thể sử dụng dịch vụ fulfillment Fastock để quản lý sản phẩm xuất nhập kho",
    "PleaseContactTheWarehouseService": "2. Vui lòng liên hệ nhà cung cấp dịch vụ kho  hàng để lấy mã khách hàng/mã kho/AppKey/SecretKey",
    "BeSureToSelectThe": "1. Đảm bảo chọn chính xác trang web dịch vụ, nếu không sẽ ủy quyền thất bại",
    "ifYouNeedToChangeWarehouse": "Nếu cần thay đổi thông tin kho, vui lòng đến Quản lý cấu hình",
    "theFastockFulfillmentWarehouseHasBeen": "Kho fulfillment Fastock đã được tạo thành công và bạn có thể bắt đầu sử dụng",
    "received": "Nhận lúc",
    "warehouseBreadcrumbProduct": "Kho / Kho {warehouseName} / Thông tin hàng hóa",
    "warehouseBreadcrumbInbound": "Kho / Kho {warehouseName} / Quản lý Phiếu nhập kho",
    "warehouseBreadcrumbOutbound": "Kho / Kho {warehouseName} / Quản lý Phiếu xuất kho",
    "warehouseBreadcrumbPullRecord": "Kho / Kho {warehouseName} / Thành tích kéo kho",
    "unboundStore": "Cửa hàng chưa liên kết",
    "storeAuthorizationHasExpired": "Ủy quyền cửa hàng đã hết hạn",
    "hongkong": "Hong Kong",
    "whetherItIsTurnedOnOr": "Có bật hay không",
    "theSkuRemovalQuantityCannotExceed": "Số lượng SKU loại bỏ không thể vượt quá số lượng đơn hàng",
    "productCodeRepeatedModification": "Mã sản phẩm chỉnh sửa nhiều lần",
    "cantDeleteAllDetails": "Không thể xóa tất cả các thông tin chi tiết",
    "theOutboundListHasBeenAllocated": "Phiếu xuất kho đã được phân bổ hoàn tất, không thể chỉnh sửa",
    "failedToRequestGxb": "Yêu cầu gxb thất bại",
    "theDetailsOfTheOutboundList": "Chi tiết của phiếu xuất kho đã được phân bổ hoàn tất, không thể chỉnh sửa",
    "outboundListDetailsDontExist": "Chi tiết phiếu xuất kho không tồn tại",
    "theMergingSkuOfInboundList": "SKU kết hợp của phiếu nhập kho bất thường, vui lòng liên hệ quản trị viên",
    "theSkuAcceptanceQuantityOfThe": "Số lượng chấp nhận SKU của phiếu nhập kho đã thay đổi, vui lòng thử lại",
    "orderDetailsCannotBeChecked": "Chi tiết đơn hàng không thể kiểm tra được",
    "productInformationDoesNotExist": "Thông tin sản phẩm không tồn tại",
    "returnAwbNumberCannotBeEmpty": "Số AWB hoàn trả không thể trống",
    "theInboundListHasBeenAccepted": "Phiếu nhập kho này đã được chấp nhận",
    "theInboundListDoesntExist": "Phiếu nhập kho không tồn tại",
    "multipleLastMileScacCodesCannot": "Nhiều mã SCAC đơn cuối cùng không thể xuất hiện trong cùng một đơn hàng",
    "theDomesticAwbNumberUnderThe": "Không thể lặp lại số AWB nội địa trong cùng một đơn hàng",
    "homeDeliveryDoesntNeedToFill": "Giao tận nhà không cần điền thông tin người nhận",
    "weightCanOnlyBeAPositive": "Trọng lượng chỉ có thể là một số nguyên dương",
    "theLastMilesTrackingNumberIs": "Số AWB của đơn cuối cùng không được trống và mã SCAC của đơn cuối cùng không thể trống",
    "theLabelPdfIsntEmptyAnd": "Vận đơn PDF không trống và mã đơn cuối cùng không thể trống",
    "theSelectedWarehouseDoesNotSupport": "Kho hàng đã chọn không hỗ trợ doanh nghiệp này",
    "lastMileInformationlogisticsProductCannotBe": "Thông tin đơn  cuối/sản phẩm vận chuyển không thể trống cùng lúc",
    "documentNumberCannotBeEmpty": "Số tài liệu không thể trống",
    "productQuantityCanOnlyBeA": "Số lượng sản phẩm chỉ có thể là số nguyên dương",
    "theDestinationCountryMustBeConsistent": "Quốc gia giao hàng đến phải thống nhất với quốc gia người nhận",
    "recipientCountryprovincecityParameterIsMissing": "Thông tin Quốc gia/Tỉnh/Thành phố của người nhận bị thiếu",
    "theOrderHasBeenProcessedAnd": "Đơn hàng đã được xử lý và không thể chỉnh sửa",
    "matchesMultipleOrders": "Khớp nhiều đơn hàng",
    "notWeighedWithoutWeight": "Không cân không trọng lượng",
    "theDocumentNumberAndLastMile": "Số tài liệu và mã đơn cuối cùng trống",
    "lastLegNumberAlreadyExists": "Mã đơn cuối cùng đã tồn tại",
    "orderShipped": "Đơn hàng đã được giao",
    "pickupPersonInformationCannotBeEmpty": "Thông tin người lấy hàng không thể trống",
    "theOrderHasBeenShippedAnd": "Đơn hàng đã được giao và không thể hủy",
    "senderCountryprovincecityParameterIsMissing": "Tham số Quốc gia/Tỉnh/Thành phố của Người gửi bị thiếu",
    "logisticsChannelDoesntSupport": "Kênh vận chuyển không hỗ trợ",
    "thisStatusDoesntSupportModification": "Trạng thái này không hỗ trợ chỉnh sửa",
    "pleaseCheckThePickupMethod": "Vui lòng kiểm tra phương thức lấy hàng",
    "outboundTypeNotMapped": "Kiểu xuất kho không khớp",
    "pleaseCheckTheScac": "Vui lòng kiểm tra SCAC",
    "productcombinedProductQuantityMustBe": "Số lượng sản phẩm/sản phẩm kết hợp phải >0",
    "documentNumberAlreadyExists": "Số tài liệu đã tồn tại",
    "pleaseCheckTheReceiver": "Vui lòng kiểm tra người nhận",
    "warehouseCodeDoesNotExist": "Mã kho hàng không tồn tại",
    "codAmountAndCodCurrencyCan": "Phải điền cả hai số tiền COD và tiền tệ COD",
    "pleaseCheckTheBusinessType": "Vui lòng kiểm tra loại hình kinh doanh",
    "failedToCheckDestinationCountryprovinceregion": "Kiểm tra quốc gia/tỉnh/khu vực giao hàng thất bại",
    "logisticsChannelTrackingFailed": "Kiểm tra vận chuyển kênh thất bại",
    "noPermissionToOperateThisOrder": "Không có quyền xử lý đơn hàng này",
    "thePlatformHasAuthorizedTheConsignor": "Nền tảng đã ủy quyền cho người gửi hàng",
    "tokenError": "Lỗi token",
    "noValidAuthorizedWarehouse": "Không có kho hàng có ủy quyền hợp lệ",
    "platformMismatch": "Nền tảng không khớp",
    "noConsignorPermission": "Không có quyền người gửi hàng",
    "theTokenDoesntMatch": "Token không khớp",
    "thePlatformConsignorDoesntMatch": "Người gửi hàng tại nền tảng không khớp",
    "pleaseCheckIfThereIsError": "Vui lòng kiểm tra xem có lỗi trong kho hàng không",
    "authorizationHasBeenApprovedPleaseDont": "Cấp quyền đã hoàn tất, vui lòng không lặp lại thao tác",
    "noConsignorOnThisPlatform": "Không có người gửi hàng tại nền tảng này",
    "userHasNo": "Người dùng không có quyền đối với kho hàng này",
    "userHasNoAuthorizationForThis": "Người dùng không có quyền đối với người gửi hàng này",
    "userDoesntHaveConsignorsAuthorization": "Người dùng không có quyền của người gửi hàng",
    "warehouseAuthorizationRecordDoesNotExist": "Bản ghi ủy quyền kho hàng không tồn tại",
    "duplicateAuthorizationApplication": "Đơn xin ủy quyền trùng lặp",
    "warehouseDoesntExist": "Kho hàng không tồn tại",
    "consignorDoesntExists": "Người gửi hàng không tồn tại",
    "platformInformationVerificationFailedPleaseContact": "Xác minh thông tin nền tảng thất bại, vui lòng liên hệ với quản trị viên",
    "consignorAlreadyExists": "Người gửi hàng đã tồn tại",
    "noTrackingNumber": "Không có số AWB",
    "hasTrackingNumber": "Có số AWB",
    "trackingNumberStatus": "Trạng thái AWB",
    "rulesBetween": "{name} nên trong khỏang {range}!",
    "waitingOutbound": "Chờ xuất kho",
    "productPriceShouldBeBetween": "Giá sản phẩm phải nằm trong khoảng 1-1.000.000.000",
    "pleaseMoveToTheMasterProduct": "Vui lòng chuyển sang trang chỉnh sửa sản phẩm Chính để hoàn thành và hoàn tất thao tác",
    "kongfuRequiresThatTheLabelMust": "*KONGFU yêu cầu đơn chuyển phát nhanh phải kết thúc bằng đuôi \".pdf\". Nếu ủy quyền thành công, điều đó có nghĩa là bạn đồng ý việc KONGFU có thể nhận đơn chuyển phát nhanh có hiệu lực dài hạn",
    "outboundListNumber": "Mã phiếu xuất kho",
    "inventoryChangesDueToManualOperation": "Thay đổi hàng tồn kho do vận hành thủ công của nhân viên kho",
    "totalDiscountedPrice": "Tổng giá chiết khấu",
    "inventoryChangesDueToWarehousePersonnel": "Tồn kho thay đổi do nhân viên kho thủ công hủy nhập kho và thay đổi số lượng xuất kho",
    "thirdpartyWarehouseInboundAdjustment": "Điều chỉnh kho lưu trữ bên thứ ba",
    "sinceTheKongfuApiLimitsThe": "Vì sự hạn chế chi phí của API sẽ được hạn chế vào gạch 99,99, lưu ý rằng nếu chi phí vượt quá 99,99,99, chúng tôi sẽ sử dụng 99,999",
    "pleaseCheckTheDocumentType": "Vui lòng kiểm tra kiểu tài liệu.",
    "tryNow": "Thử đi",
    "talkLater": "Nói chuyện sau",
    "congratulationsYouWillGetADay": "Chúc mừng, bạn sẽ được thử nghiệm miễn phí một hệ thống trọn vẹn trong 7 ngày",
    "theTrialVersionHasExpiredAnd": "Phiên bản thử nghiệm đã hết hạn, gói này đã được thả tự do. Nếu bạn cần nâng cấp, hãy mua một gói",
    "theTrialPackageWasUpdatedSuccessfully": "Bản cập nhật gói dùng thử đã thành công",
    "warehouseWarehousenameWarehouseActive": "Kho / Kho {warehouseName} / {active}",
    "theSalesTypeNameIsEmpty": "Tên kiểu bán hàng bị trống",
    "generalInformation": "Thông tin chung",
    "imageForPos": "Hình ảnh cho POS",
    "onlineAttributes": "Thuộc tính trực tuyến",
    "refurbished": "Đã tân trang lại",
    "insertProductWeightIncludingThePackaging": "Chèn trọng lượng sản phẩm gồm cả bao bì để tính tỷ lệ vận chuyển.",
    "maximumMax": "Tối đa: {max}",
    "kilogramKg": "Kilogram (kg)",
    "gramGr": "Gram (gr)",
    "insertProductDimensionAfterPackagingTo": "Chèn kích thước sản phẩm sau khi đóng gói để tính tỷ lệ vận chuyển.",
    "activatingThePreorderFeatureAllowsYou": "Kích hoạt tính năng Pre-Order cho phép bạn kéo dài thời gian xử lý đơn hàng lên đến 90 ngày (bao gồm cả cuối tuần và ngày lễ).",
    "productIsReadyStock": "Sản phẩm đã về hàng",
    "productNeedLongerShippingProcess": "Sản phẩm cần quá trình vận chuyển lâu hơn.",
    "processingTime": "Thời gian xử lý",
    "mokaOrderBetweenDays": "Đơn hàng Moka: giữa 1-90 ngày",
    "mokaProductImageTooltip": "Thêm tối đa 12 ảnh. Kích thước tối thiểu 500x500px và tối đa 8MB. Định dạng: PNG, JPEG, BMP.",
    "Inventory": "Kiểm kê",
    "cost": "Giá",
    "isMultiplePricePerSalesType": "Nhiều giá cho mỗi kiểu bán hàng",
    "trackStock": "Kiểm tra tồn kho",
    "alert": "Cảnh báo",
    "alertAt": "Cảnh báo lúc",
    "itemStockCanNotBeChanged": "Tồn kho sản phẩm không thể thay đổi sau khi lưu, vì vậy hãy đảm bảo thông tin chính xác nha!",
    "thisItemCanNotBeTracked": "Sản phẩm này không thể được theo dõi vì tồn kho kiểm kê không được theo dõi.",
    "avgCostCanNotBeChanged": "Không thể thay đổi chi phí trung bình sau khi lưu, vì vậy hãy đảm bảo thông tin chính xác nha!",
    "trackCogs": "Theo dõi giá vốn hàng bán (COGS)",
    "avgCost": "Giá trung bình",
    "theSynchronizationIsCompleteAndThe": "Đồng bộ hoàn tất và các kiểu bán hàng sau không còn hiệu lực",
    "Empty": "Trống",
    "domesticLogisticsTrackingNumberCannotBe": "Số AWB trong nước không thể trống",
    "consignorDoesntMatch": "Người gửi hàng không khớp",
    "documentNumberDoesNotExist": "Số tài liệu không tồn tại",
    "trackingNumberAlreadyExists": "Số AWB đã tồn tại",
    "packageAlreadyExists": "Gói đã tồn tại",
    "paketTambahanTidakBolehLebihBesar": "Gói bổ sung không thể lớn hơn tổng số lượng của sản phẩm",
    "theOrderHasBeenShippedNo": "Đơn hàng đã được giao, không thể thêm gói bổ sung",
    "productInformationCantBeEmpty": "Không được để trống thông tin sản phẩm",
    "theresEmptyDataInTheCombination": "Có dữ liệu trống trong số gói thông tin nhóm hàng hóa",
    "declaredAmount": "Số tiền khai báo",
    "declaredWeight": "Trọng lượng khai báo",
    "menuProductMoka": "Sản phẩm / Moka POS",
    "menuProductMokaAdd": "Sản phẩm / Moka POS /  Thêm sản phẩm",
    "menuProductMokaEdit": "Sản phẩm / Moka POS / Chỉnh sửa sản phẩm",
    "stockInOutAlert": "Bản ghi tồn kho vào/ra sẽ hiển thị tất cả các thay đổi về tồn kho trong 3 tháng qua",
    "theStockCanBePushedTo": "Tồn kho chỉ có thể được đẩy sang Moka sau khi bật nút theo dõi tồn kho",
    "youHaveADayFullfeaturedTrial": "You have a 7-day full-feature trial for free!",
    "youAreUsingTheFreeVersion": "Now you are using the free version of Ginee. \nIf you need functions such as warehouse management and inventory synchronization, you can apply once for a 7-day free trial of Ginee's full functions. \nAre you sure you want to apply for a trial? (Expected to take effect within 15 minutes after applying)",
    "Cname": "Tên kênh bán",
    "thisStaffAccountHasNoPermission": "Tài khoản nhân viên này không có quyền truy cập vào hệ thống, vui lòng liên hệ với người bán/quản trị viên để thêm tài khoản nhân viên vào hệ thống và phân quyền",
    "makeSureTheSwitchIsTurned": "Hãy chắc chắn rằng nút đã được bật khi điều chỉnh tồn kho, nếu không sẽ điều chỉnh thất bại",
    "printTaskCompletionTime": "Thời gian hoàn thành tác vụ in",
    "printTaskCreationTime": "Thời gian tạo tác vụ in",
    "noteLazadaAndBlibliOrdersDo": "Không hỗ trợ cài đặt trạng thái đóng gói cho đơn hàng Lazada & Blibli",
    "prefixMatch": "Khớp tiền tố",
    "prefixSearch": "tìm kiếm tiền tố",
    "GineeAccountCanOnlyAuthorize": "1 tài khoản Ginee chỉ ủy quyền được cho 1 tài khoản Accurate, nếu quý khách có nhu cầu thêm vui lòng liên hệ bộ phận CSKH",
    "matchingFailedThePreviousMatchingOption": "Khớp thất bại: không tìm thấy tùy chọn đã khớp trước đó, vui lòng xác nhận xem tùy chọn đã bị xóa trong Accurate chưa",
    "itemPriceCredit": "hạng mục Giá Tín dụng",
    "freeShippingMaxFee": "Phí khuyến mãi miễn phí vận chuyển",
    "paymentFee": "Phí thanh toán",
    "lazpicklaztopCommission": "Hoa hồng Lazpick/LazTop",
    "platformRequirementsTheDescriptionLengthMust": "Yêu cầu sàn: độ dài mô tả phải từ 100 đến 3000 ký tự",
    "currentlyBumped": "Đang ghim lên đầu",
    "numberOfBumpProducts": "Số sản phẩm ghim lên đầu",
    "bumpFixedSupportMaximumProducts": "Ghim cố định tối đa 5 sản phẩm",
    "bumpfixed": "Ghim cố định lên đầu",
    "bumpByTurns": "Ghim lần lượt lên đầu",
    "setAsBumpFixed": "Đặt thành ghim cố định",
    "setAsBumpByTurns": "Đặt thành ghim lần lượt",
    "tipsYouCanAddUpTo": "Mẹo: Bạn có thể thêm lên đến 30 sản phẩm, hãy ưu tiên đồng bộ thông tin sản phẩm trong cửa hàng",
    "editingInformationSuchAsStockAnd": "Không hỗ trợ chỉnh sửa thông tin như tồn kho và giá mua của sản phẩm kết hợp",
    "failedToUploadImagePleaseReupload": "Đăng tải hình ảnh thất bại, vui lòng đăng tải lại hình ảnh và thử lại",
    "solutionSteps": "Bước giải quyết",
    "addBinding": "Thêm quan hệ liên kết",
    "pleaseClickToRedistribute": "Vui lòng nhấn phân bổ lại",
    "pleaseAddBindingRelationship": "Vui lòng thêm quan hệ liên kết",
    "pleaseEnableDistributionSettings": "Vui lòng bật cài đặt phân bổ",
    "distributionSettingsArentEnabled": "Cài đặt phân bổ không được bật",
    "pleaseRestoreTheOrder": "Vui lòng khôi phục đơn hàng",
    "ordersThatHaveBeenPlacedOn": "Các đơn hàng đã được gác lại, không tham gia khóa hoặc khấu trừ tồn kho",
    "problemCauses": "Nguyên nhân vấn đề",
    "pleaseCreateAnInboundListAnd": "Vui lòng tạo phiếu nhập kho và hoàn tất việc nhập kho",
    "pleaseAdjustTheShippingWarehouse": "Vui lòng điều chỉnh quy tắc giao hàng",
    "pleaseCompleteTheInboundInThe": "Vui lòng hoàn tất việc nhập kho của kho hàng bên thứ ba",
    "pleaseCreateAnInboundList": "vui lòng tạo phiếu nhập kho",
    "mskuInXWarehouseYOut": "MSKU {y} trong kho {y} đã hết hàng",
    "theOrderStatusOfTheX": "Trạng thái đơn hàng của kênh {x} là: {y} không hỗ trợ tạo phiếu xuất kho",
    "currentlyDoesNotSupportTheCreation": "Hiện không hỗ trợ tạo phiếu xuất kho cho kênh {y}",
    "theXThirdpartyWarehouseDoesNot": "Kho của bên thứ ba {x} hiện không hỗ trợ tạo phiếu xuất kho cho quốc gia {y}",
    "theXThirdpartyWarehouseDoesntCurrently": "Kho của bên thứ ba {x} hiện không hỗ trợ tạo phiếu xuất kho cho kênh {y}",
    "bumpFixed": "Ghim cố định lên đầu",
    "serviceFeeMapping": "Khớp phí dịch vụ",
    "platformFulfillmentFeeMapping": "Khớp phí fulfillment nền tảng",
    "platformRebateMapping": "Khớp chiết khấu nền tảng",
    "voucherSellerMapping": "Khớp voucher người bán",
    "shippingDifferenceCashlessMapping": "Khớp chênh lệch phí vận chuyển (không tiền mặt)",
    "shippingFeeMapping": "Khớp phí vận chuyển",
    "shippingPriceSetting": "Cài đặt phí vận chuyển",
    "taxSetting": "Cài đặt thuế",
    "salesInvoiceSetting": "Cài đặt Hóa đơn Bán hàng",
    "storeMapping": "Khớp cửa hàng",
    "warehouseMapping": "Khớp kho hàng",
    "database": "Cơ sở dữ liệu",
    "newCompleteOrdersAfterTheSt": "\"Đơn hàng hoàn tất\" mới sau ngày 1 của tháng",
    "newCompleteOrderAfterMappingStore": "\"Đơn hàng hoàn tất\" mới sau khi khớp cửa hàng",
    "afterEnablingIfAccurateHasStock": "Sau khi bật, nếu Accurate có thay đổi về tồn kho:\n1. Đẩy đến Ginee theo thời gian thực và sửa đổi tồn kho Ginee;\n2. Hỗ trợ kéo thủ công giá trị tồn kho mới nhất của Accurate trên danh sách tồn kho.",
    "stockSyncRules": "Quy tắc Đồng bộ Tồn kho",
    "mappingTime": "Thời gian khớp",
    "branch": "Chi nhánh",
    "chartOfAccounts": "Sơ đồ Tài khoản",
    "pleaseCompleteAtLeast": "Vui lòng hoàn tất ít nhất 1 lệnh khớp cửa hàng. Sau khi khớp cửa hàng thành công, đơn hàng hoàn tất của cửa hàng sẽ tự động tạo Hóa đơn bán hàng và đẩy lên Accurate",
    "gineeWarehouseCanOnly": "1 kho hàng Ginee chỉ có thể khớp với 1 kho Accurate, vui lòng hoàn tất ít nhất 1 lệnh khớp kho hàng",
    "enabledByDefault": "Bật mặc định",
    "autoCreateRulesForSalesInvoice": "Tự động tạo quy tắc for Hóa đơn Bán hàng",
    "syncFromAccurateToGinee": "Đồng bộ từ Accurate đến Ginee",
    "syncFromGineeToAccurate": "Đồng bộ từ Ginee đến Accurate",
    "finance": "Tài chính",
    "mapFailed": "Khớp thất bại",
    "notMapped": "Chưa khớp",
    "mapped": "Đã khớp",
    "stockChange": "Thay đổi tồn kho",
    "pushMapMsku": "Đẩy & Khớp MSKU",
    "repushStock": "Đẩy lại tồn kho",
    "upToCharactersAndOnly": "Tối đa 50 ký tự và chỉ hỗ trợ: a-z, A-Z, 0-9, \"(\", \")\", \"-\", \".\", \"_\", \"/\" và Đơn giá phải >=1",
    "allUncreatedAndUnmappedMskusCurrently": "Tất cả các MSKU chưa được tạo và chưa từng được thêm vào ở hiện tại và sau này",
    "afterItIsEnabledAllMskus": "Sau khi được bật, tất cả các MSKU được khớp trong kho Ginee sẽ được đẩy sang kho Accurate, các SKU mới sẽ được tạo tự động trong Accurate và các SKU hiện có sẽ tự động khớp với Accurate",
    "mappingRules": "Khớp quy tắc",
    "autoPushMap": "Tự động Đẩy & Khớp",
    "thisSkuAlreadyExists": "SKU này đã tồn tại",
    "unitCostMustBe": "Đơn giá phải >=1",
    "salesInvoice": "Hóa đơn Bán hàng",
    "syncStock": "Đồng bộ Tồn kho",
    "theStoreIsMissingMappingInformation": "Cửa hàng thiếu thông tin để khớp (Kho hàng, Chi nhánh, Sơ đồ tải khoản, Khách hàng)",
    "source": "Nguồn",
    "associatedOrderNumber": "Mã đơn hàng liên quan",
    "itIsRecommendedToConvertTo": "Khuyến khích chuyển đổi thành số tiền mà đơn vị tiền tệ được thiết lập trong hệ thống",
    "theCurrencySymbolIsBasedOn": "The currency symbol is based on the currency set in the system.\nThe amount is the average cost price of inbound product",
    "bulkrepush": "Đẩy lại hàng loạt",
    "alasanGagalPushGudangPihakKetiga": "Lý do đẩy cho kho bên thứ 3 thất bại",
    "noShelf": "Kệ hàng mặc định",
    "plannedQuantityTip": "điền số lượng sản phẩm xuất kho dự kiến",
    "plannedQuantityTipOld": "Số lượng xuất kho dự kiến: điền số lượng sản phẩm xuất kho dự kiến",
    "selectLocationTip": "Chọn vị trí của SKU xuất kho",
    "exportSelectedOrderDetail": "Chi tiết phiếu xuất kho-Đã chọn",
    "exportAllOrderDetail": "Chi tiết phiếu xuất kho-Tất cả",
    "exportSelectedOrderList": "Danh sách phiếu xuất kho-Đã chọn",
    "exportAllOrderList": "Danh sách phiếu xuất kho-Tất cả",
    "mskuAndBarcode": "MSKU/Mã vạch",
    "confirmToSetAsInvalid": "Xác nhận cài đặt vô hiệu?",
    "bulkSubmitSuccess": "Đã xong, vui lòng làm mới trang để xem kết quả xử lý",
    "bindFailed": "Liên kết thất bại, vui lòng chọn lại vị trí",
    "bindSuccess": "Liên kết thành công",
    "autoCreateAndBind": "Tự động tạo vị trí và liên kết SKU",
    "warehouseName2": "Tên Kho hàng",
    "warehouseCode": "Mã Kho hàng",
    "quantityAdded": "Số lượng bổ sung",
    "sureToDelete": "Bạn có chắc xóa không?",
    "selectLocation": "Chọn vị trí SKU trong kho",
    "enterQuantity": "Nhập số lượng của lần nhập kho này",
    "createLocationTips": "Nếu bạn cần tạo hàng loạt vị trí mới, bạn có thể tạo chúng trong phần Quản lý vị trí.",
    "locationManagement": "Quản lý Vị trí",
    "warehouseStockColumnName": "Tổng tồn kho",
    "stockNotEnough": "Thiếu hàng tồn kho, không thể xuất kho",
    "externalOrderNo": "Đơn hàng",
    "numSelectedWithValue": "Đã chọn {val}",
    "column3TipOld": "Cột 3: vị trí (tùy chọn) nếu không điền, thì chọn vị trí của Tồn kho có sẵn lớn nhất",
    "column2TipOld": "Cột 2: số lượng (bắt buộc)",
    "column1TipOld": "Cột 1: SKU (bắt buộc)",
    "column3Tip": "Cột 3: Kệ hàng (tuỳ chọn) nếu không điền, sẽ sử dụng kệ mặc định.",
    "column2Tip": "Cột 2: số lượng (bắt buộc)",
    "column1Tip": "Cột 1: SKU (bắt buộc)",
    "copyTip": "Sao chép SKU, chi phí, số lượng, kệ hàng, vị trí từ excel đến ô vắn bản, có thể nhanh chonhs nhận diện, gửi",
    "printDetails": "In chi tiết sản phẩm",
    "copyTip2": "Cột 1 SKU, cột 2 Số lượng, cột 3 Vị trí",
    "logisticsNumberLengthError": "Mã vận chuyển tối đa 200 kí tự",
    "duplicateLocationError": "Trùng thông tin vị trí, cùng một vị trí để hàng chỉ được gửi một hàng.",
    "setPushRatio": "Cài đặt kho vận chuyển của cửa hàng - Cài đặt kho vận chuyển của cửa hàng - nếu bạn có nhiều cửa hàng, bạn có thể cài đặt liên kết kho hàng cho mỗi cửa hàng, cũng có thể cài đặt tỷ lệ đẩy hàng tồn kho cho từng cửa hàng",
    "ifOnlyOneWarehouse": "Nếu bạn chỉ có 1 kho hàng, cũng không cần liên kết vị trí để hàng, thì khi tạo sản phẩm chính, hệ thống sẽ tự động giúp bạn tạo một vị trí để hàng cho mỗi SKU, có thể trực tiếp sử dụng.",
    "addProductsToLocation": "Chi tiết kho hàng tồn kho - Sau khi thiết lập kệ hàng, bạn có thể cho hàng lên kệ, trên trang chi tiết kho hàng tồn kho, bạn bấm vào \\\"Thêm hàng vào vị trí\\\" để liên kết hàng với vị trí để hàng. Sau khi liên kết xong, bạn có thể thực hiện Quản lý hàng tồn kho cho hàng hóa ở các vị trí này.",
    "setUpLocation": "Quản lý vị trí hàng hóa - hàng hóa được đặt trên kệ trong kho, bạn cần thiết lập kệ hàng cho kho ở phần quản lý vị trí hàng hóa",
    "setUpWarehouse": "Quản lý kho hàng - bạn cần cài đặt kho hàng của mình trong chức năng Quản lý kho hàng",
    "helpShippingSettings": "Cài đặt kho hàng vận chuyển",
    "helpStockDetails": "Chi tiết kho hàng tồn kho",
    "helpLocationManage": "Quản lý vị trí",
    "helpWarehouseManage": "Quản lý kho hàng",
    "warehouseManagementIntro": "Giới thiệu chức năng liên quan đến Quản lý kho hàng",
    "moreThanPlannedError": "Đã vượt quá số lượng xuất kho dự kiến",
    "INVALID": "Đã vô hiệu",
    "PART_INVALID": "Vô hiệu một phần",
    "COMPLETE": "Hoàn thành",
    "PART_COMPLETE": "Hoàn thành một phần",
    "PENDING": "Chờ xuất kho",
    "CREATE": "Đang xử lý",
    "changeWarehouseAlert": "Cần thêm lại thông tin SKU khi thay đổi kho hàng, vui lòng xác nhận",
    "processResult": "Trạng thái xử lý",
    "complete": "Hoàn thành",
    "pushComplete": "Đẩy hàng thành công",
    "pushing": "Đang đẩy hàng",
    "workingStatusCANCEL_FAIL": "Hủy thất bại",
    "workingStatusCANCEL_SUCCESS": "Hủy thành công",
    "workingStatusCANCELED": "Đã hủy",
    "workingStatusCANCEL": "Đã hủy",
    "workingStatusOUTBOUND_DONE": "Đã xuất kho",
    "workingStatusCANCELING": "Đang hủy",
    "workingStatusWAITING_PICKUP": "Chờ lấy hàng",
    "workingStatusPACKED": "Đã đóng gói",
    "workingStatusSORTING_AND_REVIEWING": "Sắp xếp và xem xét",
    "workingStatusPICKING_PROCESS": "Đang lấy hàng",
    "workingStatusALLOCATED": "Đã phân phối",
    "workingStatusRECEIVED": "Đã nhận",
    "workingStatusSYNC_FAIL": "Đồng bộ thất bại",
    "workingStatusSYNC_SUCCESS": "Đồng bộ thành công",
    "workingStatusCREATE": "Đang xử lý",
    "workingStatus": "Trạng thái xử lý",
    "express": "Chuyển phát nhanh",
    "editOutboundList": "Chỉnh sửa Phiếu xuất kho",
    "defaultDelivery": "Mặc định",
    "selfPickup": "Tự lấy",
    "outboundListDetail": "Phiếu xuất kho chi tiết",
    "failedLineTip": "Thất bại (Hàng này không thể thêm vào Phiếu xuất kho)",
    "ifMoreThanStock1": "Nếu số lượng SKU lớn hơn Tồn kho có sẵn, thì gửi dựa vào Tồn kho có sẵn lớn nhất",
    "ifTwoColumns1": "Nếu chỉ có hai cột, thì dựa vào SKU + Phân tích số lượng",
    "copyTipOld": "Sao chép SKU, số lượng, vị trí, phân tích Ginee từ excel",
    "outboundListInvalid": "Vô hiệu Phiếu xuất kho",
    "outboundAll": "Xuất kho tất cả",
    "outboundListOutbound": "Xuất kho Phiếu xuất kho",
    "outboundManagement": "Quản lý Phiếu xuất kho",
    "scanInstruction": "Bạn có thể quét mã vạch bằng máy quét và nhấn Enter, nếu phân tích thành công thì số lượng hàng xuất kho thực tế +1",
    "receivedProduct": "Sản phẩm nhập kho",
    "goBack": "Trở về",
    "addToOutboundList": "Thêm vào Phiếu xuất kho",
    "row": "Hàng",
    "operationSuccessful": "Thành công",
    "editSuccess": "Chỉnh sửa thành công",
    "identify": "Nhận diện",
    "identifyResults": "Kết quả Nhận diện",
    "ifMoreThanStock": "Nếu số lượng SKU lớn hơn Tồn kho có sẵn, thì gửi dựa vào Tồn kho có sẵn lớn nhất",
    "ifTwoColumns": "Nếu chỉ có hai cột, thì dựa vào SKU + Phân tích số lượng",
    "submit": "Gửi",
    "directOutbound": "Xuất hàng trực tiếp",
    "locationStockNotEnough": "Thiếu vị trí Tồn kho có sẵn",
    "locationAvailableStock": "Vị trí Tồn kho có sẵn",
    "warehouseAvailableStock": "Kho Tồn kho có sẵn",
    "note": "Ghi chú",
    "plannedTotalAmount": "Tổng số tiền dự kiến",
    "unitPrice": "Đơn giá",
    "associatedDocumentNumber": "Mã tài liệu liên kết",
    "refreshStatus": "Làm mới",
    "outboundTime": "Thời gian xuất kho",
    "deliveryMethod": "Phương thức vận chuyển",
    "actualOutboundQty": "Số lượng xuất thực tế",
    "plannedOutboundQty": "Số lượng xuất dự kiến",
    "actualOutboundAmount": "Số tiền thực tế",
    "plannedOutboundAmount": "Số tiền dự kiến",
    "searchContent": "Nội dung tìm kiếm",
    "sortListSelected": "Phiếu sắp xếp - Đã chọn",
    "sortListAll": "Phiếu sắp xếp - Tất cả",
    "pickListSelected": "Phiếu lấy hàng - Đã chọn",
    "pickListAll": "Phiếu lấy hàng - Tất cả",
    "outboundListSelected": "Phiếu xuất kho - Đã chọn",
    "outboundListAll": "Phiếu xuất kho - Tất cả",
    "createOutboundList": "Tạo phiếu xuất kho",
    "greaterThan30Days": "＞30 ngày",
    "lessThan30Days": "≤ 30 ngày",
    "lessThan7Days": "≤ 7 ngày",
    "lessThan3Days": "≤ 3 ngày",
    "listNumber": "Mã đơn",
    "searchType": "Kiểu tìm kiếm",
    "allocateOutboundList": "Phân bổ phiếu xuất kho",
    "documentType": "Loại tài liệu",
    "shippedOld": "Đã xuất kho",
    "documentStatus": "Tình trạng",
    "pleaseSelectData": "Vui lòng chọn dữ liệu cần xuất",
    "basicFeaturesFreeForever": "Free vĩnh viễn",
    "nMinutesRemaining": "Còn {minus} phút",
    "gineeAndAccurateAreMappedBy": "Ginee và Accuracy đã được khớp SKU. Vì Accurate không phân biệt chữ hoa hay thường, nên hãy đảm bảo không có SKU bị trùng trong khi hàng trước khi khớp.",
    "accurateRequiresSkuToHaveA": "Accurate yêu cầu MSKU chứa tối đa 50 ký tự và chỉ hỗ trợ: a-z, A-Z, 0-9, \"(\",\")\", \"-\", \".\", \"_\", \"/\"",
    "otherSettingsAndMapping": "Các cài đặt và lệnh khớp khác",
    "mapping": "Đang khớp",
    "automapSettings": "Cài đặt tự động khớp",
    "pleaseSetAndMapTheCorrect": "Vui lòng cài đặt và khớp thông tin chính xác",
    "basedOnAccurateStock": "Lấy tồn kho Accurate làm chuẩn",
    "basedOnGineeStock": "Lấy tồn kho Ginee làm chuẩn",
    "whenTheSkuOfGineeAnd": "Khi SKU của Ginee và Accurate khớp, nếu tồn kho của 2 hệ thống khác nhau, vui lòng chọn một kho làm tiêu chuẩn, Ginee sẽ tự động đẩy hoặc kéo tồn kho sau khi khớp",
    "startingStock": "Chuẩn bị tồn kho",
    "theSubproductsOfTheCombinedProduct": "Các sản phẩm con trong sản phẩm kết hợp chưa được khớp, vui lòng hoàn thành việc khớp các sản phẩm con trước khi khớp sản phẩm kết hợp",
    "theWarehouseHasMatchedTheAccurate": "Kho đã khớp với Kho Accurate , tồn kho và giá vốn của sản phẩm phải > 0",
    "accurateAuthorizationHasExpiredPleaseReauthorize": "Ủy quyền Accurate đã hết hạn, vui lòng ủy quyền lại!",
    "accurateRequiresInventoryToBe": "Accurate yêu cầu tồn kho và giá vốn phải >0",
    "goToChange": "Đi chỉnh sửa",
    "inboundWhenAddedToWarehouse": "Inbound khi thêm vào kho",
    "youHaveNotCompletedTheInitial": "Bạn chưa hoàn thành cấu hình ban đầu của Chính xác, các tính năng liên quan chỉ có thể được sử dụng sau khi cấu hình",
    "failedToDeleteThisProductHas": "Không xóa được! Sản phẩm này đã được liên kết với một sản phẩm kết hợp, vui lòng xóa sản phẩm kết hợp hoặc hủy liên kết sản phẩm kết hợp trước",
    "noteIfTheWarehouseIsAdded": "Lưu ý: Nếu đầu vào khi được thêm vào kho là kho WMS hoặc kho của bên thứ ba, sau khi đầu vào, bạn cần xác nhận đầu vào trong kho WMS/kho của bên thứ ba để bắt đầu đồng bộ hóa kho thành công",
    "partnerAuthorizationFailed": "Ủy quyền đối tác không thành công",
    "customerMustBeFilled": "khách hàng phải được lấp đầy",
    "itemXSuccessfullySaved": "Đã lưu thành công mục \"{x}\"",
    "unitQtyMustBeFilled": "Số lượng đơn vị phải được điền",
    "itemXNotFoundOrHas": "Mục {x} không tìm thấy hoặc đã bị xóa",
    "accurateConfigurationInformationIsMissingPlease": "Thiếu thông tin Cấu hình chính xác, vui lòng kiểm tra Quản lý cấu hình và thử lại",
    "theWarehouseXWasNotFound": "Kho \"{x}\" không được tìm thấy hoặc đã bị xóa",
    "brazil": "Brazil",
    "taiwan": "Taiwan",
    "savedSuccessfullyThisPageWillClose": "Đã lưu thành công! Trang này sẽ tự động đóng lại",
    "salesInvoicePushRecord": "Bản ghi đẩy hóa đơn bán hàng",
    "orderPushTime": "Thời gian đẩy đơn",
    "orderCompleteTime": "Thời gian hoàn thành đơn",
    "completeTime": "thời gian hoàn thành",
    "updateResult": "Cập nhật kết quả",
    "productManage": "Quản lý sản phẩm",
    "becauseTheNumberOfStoresExceeds": "Số lượng đơn hàng của bạn đã đạt đến giới hạn của gói và không thể sử dụng tính năng này. Vui lòng liên hệ bộ phận CSKH để đăng ký gói mới",
    "theAuthorizationInformationIsFilledIncorrectly": "Thông tin ủy quyền được điền không chính xác, vui lòng tham khảo trung tâm trợ giúp tích hợp và điền lại thông tin",
    "channelItemXMoveOutPromotion": "Cửa hàng:Sản phẩm của{x}:{y} đã chuyển khỏi ID quảng cáo:{z}",
    "xManualReleaseOfReservedPromotion": "{x} Phát hành thủ công cổ phiếu khuyến mãi dành riêng",
    "promotionIdXHasEnded": "Cửa hàng:Sản phẩm của{x}:{y} đã tham gia ID quảng cáo:{z} đã kết thúc",
    "xManuallyEditedReservedPromotionStock": "{x} kho hàng khuyến mãi dành riêng được chỉnh sửa thủ công, được cập nhật từ {y} thành {z}",
    "channelProductXActivityIdY": "Cửa hàng:Sản phẩm của{x}:{y} ID quảng cáo:{z}",
    "unknownSystemException": "Ngoại lệ hệ thống không xác định,  Vui lòng liên hệ bộ phận CSKH",
    "theChannelProductSpecificationCorrespondingTo": "Không tìm thấy đặc tả sản phẩm kênh tương ứng với chương trình khuyến mãi",
    "theChannelProductCorrespondingToThe": "Không tìm thấy sản phẩm kênh tương ứng với chương trình khuyến mãi",
    "hasNoWarehouseInformationAndCannot": "MSKU không có thông tin kho hàng và không thể đặt trước Cổ Phiếu Khuyến Mãi",
    "channelSkuHasNoBoundMsku": "SKU kênh không có MSKU bị ràng buộc, vui lòng chuyển đến danh sách sản phẩm chính để liên kết SKU trước",
    "notCompliantWithPromotionReservation": "Không tuân thủ chính sách đặt trước Khuyến mãi: \"Các khuyến mãi chưa bắt đầu và đang diễn ra sẽ tự động được đặt trước\"",
    "promotionReservationPolicyNotMet": "Không tuân thủ Chính sách đặt trước Khuyến mãi: \"Chỉ dành riêng cho các khuyến mãi đang hoạt động\"",
    "theCategoryOrAttributeInformationIs": "Thông tin danh mục hoặc thuộc tính không chính xác, vui lòng thử lại sau khi đồng bộ hóa danh mục",
    "setUpOrderInvoices": "Lập hóa đơn đặt hàng",
    "orderPacking": "đặt hàng đóng gói",
    "orderDistribution": "phân phối đơn hàng",
    "syncOrder": "đồng bộ hóa thứ tự",
    "theOrderSSuccessfullyPrintedThe": "Đơn hàng [%s] đã in thành công danh sách lấy hàng",
    "theOrderssuccessfullyPrintedThePackingList": "Đơn hàng [%s] đã in danh sách đóng gói thành công",
    "orderSuccessInvoice": "Đơn đặt hàng [%s] đã in hóa đơn thành công",
    "orderSuccessLabel": "Đơn đặt hàng [%s] đã in nhãn thành công",
    "ordersreturnedInboundTheReturnInboundList": "Đơn đặt hàng [%s] được trả lại trong nước, danh sách hàng được trả lại đã được tạo thành công, số danh sách: <a href=\"/erp/main/outbound-manager-detail/%s/SELL\" target=\"_blank\">%s</a>",
    "ordersredistributedSuccessfully": "Lệnh được phân phối lại theo cách thủ công [{0}], vui lòng tham khảo bản ghi hoạt động tiếp theo để biết kết quả",
    "ordersfailedToPushWarehouseForDelivery": "Đơn hàng [%s] không thể chuyển kho để giao hàng, lý do: %s",
    "theOrdersisSuccessfullyPushedToThe": "Đơn hàng [%s] được đẩy thành công vào kho hàng",
    "orderspushWarehouseOperationSuccessfully": "Đặt hàng【%s】Đẩy kho vận hành thành công",
    "theOrderSSuccessfullySetThe": "Đơn đặt hàng [%s] đã đặt hóa đơn thành công",
    "ordersarrangedDeliverySuccessfully": "Đặt hàng [%s] giao hàng thành công",
    "orderspackedSuccessfully": "Đơn hàng [%s] được đóng gói thành công",
    "orderscancelledSuccessfully": "Đơn hàng [%s] đã được hủy thành công",
    "theWarehouseSelectedForProductS": "Kho được chọn cho sản phẩm [%s] trong đơn hàng [%s] được thay đổi từ [%s] thành [%s]",
    "theProductSInTheOrder": "Sản phẩm [%s] trong đơn hàng [%s] được thay thế bằng [%s] và kho hàng được chọn là [%s]",
    "theOrderSDeletesTheGift": "Đơn hàng [%s] xóa quà tặng [%s], số lượng là [%s], kho hàng được chọn là [%s]",
    "addGiftSToOrderS": "Thêm quà tặng [%s] vào đơn hàng [%s], số lượng là [%s], và kho đã chọn là [%s]",
    "theOrderSHasBeenCancelled": "Đơn đặt hàng [{0}] đã bị hủy (Khôi phục đơn hàng)",
    "ordersmarkedAsPutOnHold": "Đơn hàng [{0}] được đánh dấu là \"Đã lưu trữ\"",
    "theOrderSIsMarkedAs": "Đơn hàng [%s] được đánh dấu là hết hàng, lý do: sản phẩm chính [%s] tương ứng với sản phẩm kênh [%s] đã hết hàng",
    "orderSIsMarkedAsOut": "Đơn hàng [%s] được đánh dấu là [Có vấn đề về đơn hàng-Lỗi phân bổ kho hàng], lý do: [%s] không hỗ trợ sử dụng kho thực hiện [%s]",
    "theOrderSIsAPromotion": "Đơn hàng [%s] là đơn hàng khuyến mãi và sản phẩm kênh [%s] được phân bổ vào kho [%s]",
    "theChannelProductNotOutbound": "Sản phẩm kênh [%s] trong đơn đặt hàng [%s] không thể tạo danh sách gửi đi, lý do: sản phẩm chính được liên kết [%s] không có trong kho [%s]",
    "theChannelProductCantGen": "Sản phẩm kênh [%s] trong đơn hàng [%s] không thể tạo danh sách gửi đi, lý do: không có quy tắc kho vận chuyển tương ứng",
    "theChannelProductAllocate": "Sản phẩm Kênh [%s] theo thứ tự [%s], theo Quy tắc Kho Vận chuyển [%s] được phân bổ vào kho [%s]",
    "theChannelProductSInThe": "Đơn hàng [%s] được đánh dấu là [Đơn hàng có vấn đề-MSKU không được ánh xạ]，Sản phẩm của Kênh [%s] không thể tạo danh sách gửi đi, lý do: không được liên kết với Sản phẩm chính",
    "anOutboundListHasBeenCreated": "Một danh sách gửi đi đã được tạo cho đơn đặt hàng [%s], số đơn đặt hàng: <a href=\"/erp/main/outbound-manager-detail/%s/SELL\" target=\"_blank\">%s</a>",
    "theOrderSHasBeenSuccessfully": "Đơn hàng [%s] đã được đồng bộ hóa thành công và trạng thái đơn hàng đã được cập nhật từ [%s] thành [%s]",
    "syncToTheNewOrderS": "Đã đồng bộ với đơn hàng mới [%s], trạng thái đơn hàng là [%s]",
    "salesTaxAmountMustBeBetween": "'salesTax.amount' must be between 0 and 1000000000",
    "incorrectUnitFormat": "Định dạng đơn vị không chính xác, nội dung chỉ được hỗ trợ là \"giờ\", \"ngày trong tuần\", \"ngày\", \"tuần\", \"tháng\"",
    "purchaseDurationMustBeBetween": "Thời lượng mua hàng phải nằm trong khoảng từ 1-365",
    "selectedWarehouse": "Kho đã chọn",
    "addingToRepositoryXFailedReason": "Thêm vào kho lưu trữ \"{x}\" không thành công! Lý do thất bại:",
    "purchaseDurationUnit": "Đơn vị thời lượng mua (Chỉ nội dung được hỗ trợ là \"hour\", \"working day\", \"day\", \"week\", \"month\")",
    "thisCustomerHasOrdersInThe": "Khách hàng này có đơn đặt hàng trong 60 ngày qua, bạn có thể bắt đầu phiên",
    "theProductInboundStockCannotBe": "Sản phẩm trong kho không được âm",
    "theBundledProductHasNotBeen": "Sản phẩm đi kèm chưa được gửi đến kho!",
    "pleaseAddMskuToTheWarehouse": "Vui lòng thêm MSKU vào kho và hoàn thành Inbound",
    "createSalesInvoice": "Tạo hóa đơn bán hàng",
    "beforePushingMatchingCombinedProducts": "Trước khi đẩy và ánh xạ các sản phẩm kết hợp, cần đảm bảo rằng tất cả các sản phẩm kết hợp đã được đẩy và ánh xạ",
    "theSystemWillAutomaticallyPushSales": "Hệ thống sẽ tự động đẩy Hóa đơn bán hàng về Chính xác vào các thời điểm 4:00, 12:00 và 20:00. Nếu cả ba lần thử đều thất bại, nó sẽ không được đẩy lại",
    "ifGineeAndAccurateHaveThe": "Hiện tại chỉ hỗ trợ Cổ phiếu Ginee làm Cổ phiếu ban đầu",
    "thePushedMappedMskuSupports": "MSKU đã đẩy & ánh xạ hỗ trợ bật cổ phiếu đồng bộ hóa và tạm thời chỉ hỗ trợ cổ phiếu của Ginee được đẩy thành Chính xác khi cổ phiếu của Ginee thay đổi",
    "afterSettingTheTaxTypeWill": "Sau khi thiết lập, loại thuế sẽ được bao gồm khi tạo MSKU từ Ginee sang Chính xác",
    "thisMappingCorrespondsToTheService": "Ánh xạ này tương ứng với trường Phí dịch vụ theo thứ tự",
    "thisMappingCorrespondsToTheShipBySys": "Ánh xạ này tương ứng với trường Phí vận chuyển do hệ thống thanh toán theo thứ tự",
    "thisMappingCorrespondsToTheRebate": "Ánh xạ này tương ứng với trường Giảm giá theo thứ tự",
    "thisMappingCorrespondsToTheCommission": "Ánh xạ này tương ứng với trường Phí hoa hồng theo thứ tự",
    "thisMappingCorrespondsToTheShipReb": "Ánh xạ này tương ứng với trường Giảm phí vận chuyển trong đơn hàng",
    "thisMappingCorrespondsToTheShipping": "Ánh xạ này tương ứng với trường Phí vận chuyển trong đơn hàng",
    "thePromotionHasExpiredOrThe": "Khuyến mãi đã hết hạn hoặc loại khuyến mãi không chính xác",
    "invoiceBuyerPhoneNo": "SĐT Người mua trên Hóa đơn",
    "invoiceType": "Loại Hóa đơn",
    "invoiceAddress": "Địa chỉ trên Hóa đơn",
    "invoiceBuyerEmail": "Email Người mua trên Hóa đơn",
    "invoiceBuyerName": "Tên Người mua trên Hóa đơn",
    "upcoming": "Sắp tới",
    "finish": "Hoàn thành",
    "promoTime": "Thời gian khuyến mãi",
    "promotionid": "ID quảng cáo",
    "webstore": "Cửa hàng trên mạng",
    "orderShasBeenMovedToReturned": "Đơn đặt hàng[%s] đã được chuyển sang Trả lại",
    "orderShasBeenMovedToDelivered": "Đơn đặt hàng[%s] đã được chuyển sang Đã giao hàng",
    "orderShasBeenMovedToShipping": "Đơn đặt hàng[%s] đã được chuyển sang Đang vận chuyển",
    "orderShasBeenMovedToTo": "Đơn đặt hàng[%s] đã được chuyển sang Đang chờ đón",
    "orderSisMarkedAsNotPacked": "Đơn hàng [%s] được đánh dấu là \"chưa đóng gói\"",
    "orderSisMarkedAsPacked": "Đơn hàng [%s] được đánh dấu là \"Đã đóng gói\"",
    "ordersmarkedPickingStatus": "Đơn hàng[%s] đã đánh dấu trạng thái lấy hàng",
    "orderSisMarkedAsNotA": "Đơn hàng[%s] được đánh dấu là \"Không phải đơn hàng giả\"",
    "orderSisMarkedAsFakeOrder": "Đơn hàng[%s] bị đánh dấu là \"Đơn hàng giả mạo\"",
    "orderSHasBeenOperatedDelivery": "Đơn hàng[%s] đã được xử lý [Kho giao hàng]",
    "orderSmarkedAsPickingListNot": "Đơn hàng[%s] được đánh dấu là Danh sách chọn hàng chưa được in",
    "orderSmarkedAsPickingListPrinted": "Đơn hàng[%s] được đánh dấu là Đã in danh sách lấy hàng",
    "orderSmarkedAsPackingListNot": "Đơn hàng[%s] được đánh dấu là Danh sách đóng gói chưa in",
    "orderSmarkedAsPackingListPrinted": "Đơn hàng[%s] được đánh dấu là Đã in Danh sách đóng gói",
    "orderSmarkedAsInvoiceNotPrinted": "Đơn đặt hàng[%s] được đánh dấu là Hóa đơn chưa in",
    "orderSmarkedAsInvoicePrinted": "Đơn đặt hàng[%s] được đánh dấu là Đã in hóa đơn",
    "orderSmarkedAsLabelNotPrinted": "Đơn đặt hàng[%s] được đánh dấu là Chưa in nhãn",
    "orderSmarkedAsLabelPrinted": "Đơn đặt hàng[%s] được đánh dấu là nhãn đã in",
    "pleaseConfirmTheInstallationOfGinee": "Vui lòng xác nhận việc cài đặt Ginee trên trang Chính xác và làm mới trang sau khi cài đặt",
    "allActivities": "Tất cả các hoạt động",
    "eventStartTimeAscending": "Thời gian bắt đầu sự kiện tăng dần",
    "eventStartTimeDescending": "Thời gian bắt đầu sự kiện giảm dần",
    "eventEndTimeAscending": "Thời gian kết thúc sự kiện tăng dần",
    "eventEndTimeDescending": "Thời gian kết thúc sự kiện giảm dần",
    "sortEventStartTime": "Sắp xếp thời gian bắt đầu sự kiện",
    "thisOperationOnlyMarksWhetherThe": "Thao tác này chỉ đánh dấu có phải là đơn hàng giả hay không, sau khi đánh dấu sẽ không ảnh hưởng đến danh sách xuất và xuất kho",
    "IsTheHighestPriority": "1 là ưu tiên cao nhất, 20 là ưu tiên thấp nhất",
    "optional": "Không bắt buộc",
    "mandatory": "Bắt buộc",
    "shippingInsurance": "Bảo hiểm vận chuyển",
    "xModifiedWarehouseStockOutboundOrder": "{x} kho hàng đã sửa đổi, đơn đặt hàng xuất đi đã hoàn thành, nhận xét:",
    "xModifiedWarehouseStockCreatedOutbound": "{x} kho hàng đã sửa đổi, đơn đặt hàng xuất đi đã được tạo, nhận xét:",
    "xManuallyReplacesTheReservedWarehouse": "{x} thay thế thủ công kho dành riêng, từ kho {y} sang kho {z}",
    "pleaseModifyTheReservedPromotionStock": "Vui lòng sửa đổi số lượng cổ phiếu khuyến mãi dành riêng một cách cẩn thận! \n- Giảm hàng dự trữ sẽ làm tăng hàng tồn kho có sẵn và hàng khuyến mãi có thể được khấu trừ khỏi hàng của các kênh khác trong quá trình bán hàng đa kênh dẫn đến tình trạng bán quá mức\n- Sửa đổi hàng dự trữ trong Ginee sẽ chỉ thay đổi số lượng hàng khuyến mãi trong Ginee. Nó sẽ không ảnh hưởng đến hàng dự trữ trong Seller Center",
    "commissionFeeMapping": "Lập bản đồ phí hoa hồng",
    "priorityDelivery": "giao hàng ưu tiên",
    "flashSale": "Giảm giá thần tốc",
    "productPromotion": "khuyến mãi sản phẩm",
    "eventStartTime": "Thời gian bắt đầu sự kiện",
    "eventEndTime": "Thời gian kết thúc sự kiện",
    "notPrintable": "Không in được",
    "theFaceSheetCanBePrinted": "Nhãn vận chuyển có thể được in",
    "thTrackingNumberNotBeen": "Số theo dõi chưa được tạo",
    "theOrderStatusCannotPrintThe": "Trạng thái đơn hàng không hỗ trợ in Nhãn vận chuyển",
    "platformFulfillmentOrdersDoNotSupport": "Nền tảng thực hiện đơn hàng không hỗ trợ in Nhãn vận chuyển",
    "thisChannelDoesNotSupportPrinting": "Kênh này không hỗ trợ in Nhãn vận chuyển",
    "theChannelHasNotReturnedThe": "Kênh vẫn chưa trả lại AWB, các bạn kiên nhẫn chờ nhé. Nếu sau một thời gian dài AWB vẫn chưa được đồng bộ, vui lòng đến Trung tâm người bán để xác nhận đã tạo chưa, sau đó đồng bộ đơn hàng và thử lại.",
    "theChannelShippingLabelHasNot": "Nhãn vận chuyển kênh chưa được tạo, vui lòng thử lại sau bằng cách đồng bộ hóa đơn hàng",
    "syncAll": "Đồng bộ tất cả",
    "noShippingLabelToBePrint": "Tạm không có vận đơn để in",
    "promotionEnd": "Hết hạn",
    "handleProblem": "xử lý vấn đề",
    "stockMonitoringAndSynchronizationSettingsAre": "Cài đặt Stock Monitor và Sync chưa được bật",
    "thereAreUpToFixed": "Tối đa 5 sản phẩm ghim cố định lên đầu",
    "afterTurningOffTheAutomaticallyReserve": "Sau khi tắt tính năng Tự động bảo lưu kho khuyến mãi, kho khuyến mãi của Shopee sẽ không được đồng bộ và không được bảo lưu",
    "turnOffTheSwitchToAutomatically": "Tắt công tắc sang Tự động Dự trữ Cổ phiếu Khuyến mãi",
    "setTheReservationPolicyToUpcoming": "Đặt chính sách đặt trước thành \"Các chương trình khuyến mãi sắp tới và đang xử lý sẽ tự động được đặt trước\"",
    "setTheReservationPolicyToAutoreserve": "Đặt chính sách đặt trước thành \"Chỉ dành riêng cho các chương trình khuyến mãi đang xử lý\"",
    "turnOnTheSwitchToAutomatically": "Bật công tắc sang Tự động đặt trước cổ phiếu khuyến mãi",
    "processingPromotionOnlySupportModifyThe": "Chế biến khuyến mại chỉ hỗ trợ sửa kho, không hỗ trợ sửa kho dự trữ",
    "displayingWarehouseWhereTheMskuOf": "Kho hiển thị nơi MSKU của sản phẩm khuyến mại được phân bổ và kho có thể thay đổi.",
    "displayingTheBoundMskuOfThe": "Hiển thị MSKU bị ràng buộc của sản phẩm khuyến mại và số lượng hàng khuyến mãi được đặt trước trong Ginee",
    "displayTheChannelSkuOfThe": "Hiển thị SKU kênh của sản phẩm khuyến mãi và số lượng hàng khuyến mãi đã đăng ký thành công trong chương trình khuyến mãi nền tảng",
    "productParticipatedInPromotionButIs": "Sản phẩm đã tham gia khuyến mãi nhưng không được bảo lưu hàng khuyến mãi. Data khuyến mãi hết hạn chỉ tồn tại trong 30 ngày, sau 30 ngày sẽ không xem được data trong danh sách",
    "productParticipatedInPromotionAndOnly": "Sản phẩm đã tham gia khuyến mãi, chỉ một số biến thể là dành hàng khuyến mãi",
    "productsIncludingAllVariationsAreParticipating": "Sản phẩm (bao gồm tất cả các biến thể) đã tham gia khuyến mãi, đã hết hàng khuyến mãi",
    "thisPageCanOnlyViewAnd": "Trang này chỉ có thể xem và quản lý các khuyến mãi được tạo sau ngày 9 tháng 6 năm 2023",
    "channelReturnFailedReasonX": "Trả lại kênh không thành công, Lý do: {x}",
    "storeWillExp": "{m} Cửa hàng sắp hết hạn",
    "storeHasExp": "{m} Cửa hàng đã hết hạn",
    "big": "To lớn",
    "small": "Bé nhỏ",
    "standa": "Tiêu chuẩn",
    "onlySupportsAdjustingSkuAndQuantity": "Chỉ hỗ trợ điều chỉnh SKU và cỡ chữ số lượng",
    "fontSiz": "Cỡ chữ:",
    "paperSize": "Khổ giấy",
    "disableHighspeedPrintingForSshop": "Tắt in tốc độ cao cho cửa hàng [%s]",
    "enableHighspeedPrintingForSstore": "Bật tính năng in tốc độ cao cho cửa hàng [%s]",
    "disableTheHighSpeedPrintingButton": "Tắt nút [In tốc độ cao]",
    "enableTheHighSpeedPrintingSwitch": "Bật nút [In tốc độ cao]",
    "afterDisablingAllStoresWillNot": "Sau khi tắt, tất cả các cửa hàng sẽ không thể sử dụng tính năng in tốc độ cao và thời gian để có được nhãn vận chuyển kênh khi in hàng loạt sẽ tăng lên",
    "areYouSureToTurnOff": "Bạn có chắc chắn tắt tính năng in tốc độ cao không?",
    "shopeeWillMarkThePredownloadedOrder": "Shopee/Lazada/Tiktok sẽ đánh dấu đơn hàng tải sẵn là đã in, bạn vui lòng chọn bật công tắc tùy theo nhu cầu của mình",
    "afterEnablingGineeWillDownloadThe": "Sau khi bật, Ginee sẽ tải xuống nhãn vận chuyển kênh trước để cho phép in tốc độ cao và có thể giảm đáng kể thời gian chờ đợi khi in hàng loạt",
    "syncToObtainLatestLogisticsInformation": "Đồng bộ hóa để có được thông tin hậu cần mới nhất",
    "massSyncToObtainTheLatest": "Đồng bộ hóa hàng loạt để có được thông tin hậu cần mới nhất của đơn đặt hàng Shopee và Tokopedia",
    "syncLogistics": "Hậu cần đồng bộ",
    "package": "Bưu kiện",
    "storeAuthorizedtotalAmount": "Cửa hàng (Được ủy quyền/Tổng số tiền)",
    "subaccountUsedtotalAmount": "Tài khoản phụ (Dùng/Tổng số tiền)",
    "remainingOrderQuantity": "Số lượng đặt hàng còn lại",
    "remainingMDays": "{m} ngày còn lại",
    "packageRemainingMDays": "Gói còn lại {m} ngày",
    "toEnsureNormalUsePleaseRenew": "Sau khi gói hết hạn, công tắc đồng bộ hóa kho sẽ tự động tắt. Để đảm bảo sử dụng bình thường, vui lòng gia hạn kịp thời",
    "packageRemainingValidityMDays": "Hiệu lực còn lại của gói {m} ngày",
    "noReminderToday": "hôm nay không nhắc nhở",
    "theOperationIsTooFrequentPlease": "Thao tác quá thường xuyên, vui lòng thử lại sau 5 phút",
    "yourCurrentPackageDoesNotSupport": "Gói hiện tại của bạn không hỗ trợ ủy quyền kênh này, vui lòng nâng cấp gói OMS trước khi ủy quyền",
    "thisMethodIsOnlyOpenTo": "Phương pháp này chỉ dành cho các thương nhân được chỉ định. Hãy đặt nó một cách cẩn thận. Để biết thêm thông tin, tham khảo {m}",
    "shopeeOfficialInstructions": "Hướng dẫn chính thức của Shopee",
    "actualShippingFee": "Phí vận chuyển thực tế",
    "ifAutomaticallyReserveShopeePromotionStock": "Nếu bật [Tự động đặt trước kho khuyến mãi Shopee], đơn hàng khuyến mãi Shopee sẽ không được phân bổ theo quy tắc Kho vận chuyển mà sẽ bị khóa theo kho dành riêng cho khuyến mãi",
    "afterEnablingGineeWillAutomaticallyPull": "Sau khi bật, Ginee sẽ tự động pull BookingCode của đơn hàng Tokopedia",
    "turnOffTheAutomaticallyPullLogistics": "Tắt nút [Tự động kéo thông tin hậu cần]",
    "turnOnTheAutomaticallyPullLogistics": "Bật nút [Tự động kéo thông tin hậu cần]",
    "automaticallyPullLogisticsInformation": "Tự động kéo thông tin hậu cần",
    "disabled1": "Tàn tật",
    "enabled": "Đã bật",
    "newlyAuthorizedStoresAreAutomaticallySet": "Các cửa hàng được ủy quyền mới sẽ tự động được đặt thành \"Đã bật\"",
    "confirmToReauthorize": "Xác nhận để ủy quyền lại",
    "noteTiktokReauthorizationDoesNotExtend": "Lưu ý: Việc ủy quyền lại TikTok không kéo dài thời gian hiệu lực. Để gia hạn thời gian hiệu lực của ủy quyền, vui lòng truy cập Trung tâm người bán TikTok để hủy tích hợp Ginee trước, sau đó ủy quyền lại",
    "sellerFullAddress": "Người bán địa chỉ đầy đủ",
    "thereIsNoOrderThatCan": "Không thể in đơn hàng bằng in nhanh, vui lòng xác nhận rằng cửa hàng nơi đặt đơn hàng đã bật [In nhanh]",
    "startSynchronizingLatestOrderLogisticsInformation": "Bắt đầu đồng bộ hóa thông tin hậu cần đơn hàng mới nhất, dự kiến sẽ mất vài phút, vui lòng làm mới trang sau để xem thông tin hậu cần mới nhất",
    "video": "Băng hình",
    "tryHighlightingCoreSellingPoints": "Hãy thử làm nổi bật 1-2 điểm bán hàng cốt lõi cho sản phẩm của bạn. Điều này sẽ được hiển thị trong phần chi tiết sản phẩm.",
    "weRecommendTheVideoDurationIs": "Chúng tôi khuyên bạn nên sử dụng thời lượng video trong vòng 20 giây và thời lượng dài nhất không được vượt quá 60 giây.",
    "recommendedRatioIsAndRecommended": "Tỷ lệ được đề xuất là 1:1 và độ phân giải được đề xuất là trên HD 720p.",
    "ifYouUploadAVideoIt": "Nếu bạn tải video lên, video đó phải có tỷ lệ khung hình từ 9:16 đến 16:9. Tệp không được lớn hơn 30MB, định dạng phải là MP4.",
    "tiktokRequired": "Yêu cầu TikTok：",
    "changingCategoryWillNotAffectYour": "Việc thay đổi danh mục sẽ không ảnh hưởng đến thông tin sản phẩm của bạn như hiệu suất bán hàng và xếp hạng sản phẩm, nhưng sẽ xóa các thuộc tính sản phẩm. Bạn có chắc chắn muốn thay đổi nó?",
    "basedOnTheTitleYouEntered": "Dựa trên Tiêu đề bạn đã nhập, danh mục sản phẩm có thể là (vui lòng chọn nếu cần)",
    "economical": "Tiết kiệm",
    "cargo": "chở hàng",
    "standardShipping": "tiêu chuẩn vận chuyển",
    "pleaseInputTheValueOfWeight": "Vui lòng nhập giá trị trọng lượng lớn hơn 5.00kg",
    "deliveryOptions": "Tùy chọn giao hàng",
    "lineShoppingOnlySupportsSettingThe": "Line Shopping chỉ hỗ trợ cài đặt số tiền chiết khấu tức thì thống nhất cho tất cả các biến thể",
    "compulsoryOutbound": "Danh sách xuất hàng đã được đẩy vào kho thực hiện, vui lòng thực hiện xuất hàng trong kho thực hiện. Nếu bạn tiếp tục vận hành, chỉ đơn hàng xuất đi trong OMS sẽ bị cưỡng chế, điều này có thể gây ra sự không thống nhất với dữ liệu xuất đi thực tế của kho thực hiện. Bạn có chắc chắn muốn tiếp tục không?",
    "compulsoryCancel": "Danh sách xuất hàng đã được đẩy vào kho thực hiện, vui lòng thực hiện xuất hàng trong kho thực hiện. Nếu tiếp tục vận hành, bạn sẽ chỉ buộc hủy danh sách hàng xuất trong OMS, điều này có thể gây ra sự không thống nhất với dữ liệu hàng xuất thực tế của kho thực hiện. Bạn có chắc chắn muốn tiếp tục không?",
    "noPermission": "Bạn không có quyền truy cập trang này, vui lòng làm mới trang và thử lại sau khi nâng cấp gói của bạn. Đối với tài khoản nhân viên, vui lòng liên hệ với tài khoản chính để thêm quyền vai trò",
    "refuseToAddWms": "Bạn không có quyền thêm kho này, vui lòng liên hệ với Nhà cung cấp dịch vụ kho của bạn để thêm Tài khoản người gửi hàng cho bạn và ủy quyền cho kho",
    "theProductDescriptionShouldGiveThe": "Mô tả sản phẩm nên cung cấp cho khách hàng thông tin hữu ích về sản phẩm để đảm bảo mua hàng",
    "missingOrderInformation": "Thiếu thông tin đặt hàng",
    "compartmentDimensionInShippingWarehouseSetting": "Kích thước ngăn trong cài đặt Kho vận chuyển đã thay đổi từ {1} thành {2}",
    "priorityOfWarehouseAlocationInShipping": "Mức độ ưu tiên của phân bổ kho trong cài đặt Kho vận chuyển đã thay đổi từ {1} thành {2}",
    "warehouseInRuleChangedFrom": "Kho trong quy tắc {0} đã thay đổi từ {1} thành {2}",
    "startPullingTheOrderDataIt": "Ước tính sẽ mất vài phút để bắt đầu truy xuất dữ liệu đơn đặt hàng. Vì vậy, hãy chú ý đến việc đồng bộ hóa dữ liệu cửa hàng sau này.",
    "theRecipientsInformationCanBeDisplayed": "Thông tin của người nhận có thể được hiển thị bằng văn bản trên nhãn vận chuyển nhưng thông tin của người nhận có thể bị che đi.",
    "theCompleteRecipientInformationCanBe": "Thông tin đầy đủ về người nhận có thể được hiển thị dưới dạng ảnh trên nhãn vận chuyển, nhưng một số máy in có thể không nhận dạng được ảnh.",
    "customShipmentWarning": "Tùy chọn Cảnh báo giao hàng",
    "thisSystemWarehouseHasBeenBound": "Kho hệ thống này đã bị ràng buộc bởi các Kho kênh khác!",
    "completeRecipientInformationCanBe": "Thông tin đầy đủ về người nhận có thể được hiển thị dưới dạng ảnh trên nhãn vận chuyển",
    "recipientsInformationCanBeDisplayed": "Thông tin đầy đủ về người nhận có thể được hiển thị dưới dạng ảnh trên nhãn vận chuyển",
    "instantDiscountAmount": "Số tiền giảm giá ngay lập tức",
    "originalPriceShouldBeHigherThan": "Giá gốc phải cao hơn số tiền chiết khấu tức thì",
    "YourOmsWillBeUpgraded": "1. OMS của bạn sẽ được nâng cấp lên phiên bản đầy đủ tính năng, có thể ủy quyền cho các cửa hàng đa kênh, xử lý đơn hàng và quản lý hàng tồn kho;",
    "YouWillAutomaticallyBindThe": "2. Bạn sẽ tự động ràng buộc kho của nhà cung cấp dịch vụ và bạn có thể đẩy đơn hàng đến kho của nhà cung cấp dịch vụ và nhà cung cấp dịch vụ sẽ thực hiện các đơn hàng;",
    "theFulfillmentServiceProviderXInvites": "Nhà cung cấp dịch vụ thực hiện đơn hàng {x} mời bạn mở kho thực hiện đơn hàng {y}",
    "afterAgreeing": "Sau khi đồng ý:",
    "areYouSureYouAgreeTo": "Bạn có chắc chắn đồng ý kích hoạt không?",
    "confirmActivation": "Xác nhận kích hoạt",
    "youHaveNewApplication": "Bạn có {m} đơn đăng ký mới",
    "thisChannelCanBeAuthorizedAfter": "Kênh này có thể được cấp phép sau khi nâng cấp gói",
    "theCurrentPackageDoesNotSupport": "Gói hiện tại không hỗ trợ ủy quyền kênh này",
    "enableFailedYouCanOnlyAuthorize": "Kích hoạt không thành công! Cửa hàng vượt quá giới hạn tài nguyên gói",
    "afterUpgradingThePackageMoreMaster": "Sau khi nâng cấp gói, có thể thêm nhiều Sản phẩm chính",
    "addFailedMasterProducts": "Thêm không thành công! Số lượng Master Products đã đạt giới hạn của gói",
    "masterProductQuantityHasReachedThe": "Số lượng Sản phẩm Chính đã đạt đến giới hạn gói",
    "additionalXConsignorAccountsbusiness": "Bổ sung x 5 Tài khoản người gửi hàng",
    "additionalXGineeWmsOrders": "Thêm x 10.000 đơn đặt hàng Ginee WMS",
    "additionalXStores": "Thêm x 10 cửa hàng",
    "additionalXStaffAccounts": "Bổ sung x 5 Tài khoản nhân viên",
    "additionalXGineeOmsOrders": "Thêm x 10.000 Đơn hàng Ginee OMS",
    "addons": "Tiện ích bổ sung",
    "offlineOnboardingOn": "Tích hợp ngoại tuyến (1 trên 1)",
    "accountManagerOn": "Người quản lý tài khoản (1 trên 1)",
    "onlineTraining": "Đào tạo trực tuyến",
    "liveChatSupport": "Hỗ trợ trò chuyện trực tiếp",
    "implementationAndCustomerCare": "Triển khai và chăm sóc khách hàng",
    "XConsignorAccountsBusiness": "10 x Tài khoản người gửi hàng (Doanh nghiệp)",
    "servicesToExternalBrands": "Dịch vụ cho các thương hiệu bên ngoài",
    "serviceProviderManagement": "Quản lý nhà cung cấp dịch vụ",
    "gineeWmsOpenApi": "API mở Ginee WMS",
    "pdaapp": "PDA/ỨNG DỤNG",
    "fifofefo": "FIFO/FEFO",
    "smartReplenishment": "Bổ sung thông minh",
    "stocklocationTransfer": "Chuyển hàng/địa điểm",
    "waveShipping": "Sóng Vận Chuyển",
    "gineeWmsWarehouseManagementSystem": "Ginee WMS (Hệ thống quản lý kho)",
    "gineeOmsOpenApi": "API mở Ginee OMS",
    "theThirdPartyIntegrationposLogisticsFulfillment": "Tích hợp bên thứ ba（POS, Hậu cần, Thực hiện đơn hàng, OMS, Kế toán）",
    "multiwarehouseManagement": "Quản lý nhiều kho",
    "reporting": "Báo cáo",
    "stockManagement": "Quản lý chứng khoán",
    "gineeOmsOrderManagementSystem": "Ginee OMS (Hệ thống quản lý đơn hàng)",
    "noOfSalesChannels": "Số kênh bán hàng",
    "noOfMskus": "Số MSKU",
    "noOfStores": "Số cửa hàng",
    "noOfStaffAccounts": "Số tài khoản nhân viên",
    "noOfCountriesinternational": "Số quốc gia (Quốc tế)",
    "noOfGineeWmsOrders": "Số đơn đặt hàng Ginee WMS",
    "noOfGineeOmsOrders": "Số đơn đặt hàng Ginee OMS",
    "ecommerceOperations": "Hoạt động thương mại điện tử",
    "buyItNow": "Mua ngay",
    "clickMeToGetDiscount": "Nhận giảm giá",
    "customization": "Tùy chỉnh",
    "youHaveMNewMessages": "Bạn có {m} tin nhắn mới",
    "officialAccounts": "Tài khoản chính thức",
    "theThirdPartyIntegration": "Tích hợp bên thứ ba",
    "thereAreUnrecognizableCharactersSuchAs": "Có những ký tự không nhận dạng được, vui lòng thay đổi",
    "currentlyDoNotSupportIntegratingCnsc": "Hiện tại chưa hỗ trợ tích hợp cửa hàng CNSC hoặc 3PF",
    "manuallyRepushThePushFailedData": "Đẩy lại dữ liệu \"đẩy không thành công\" theo cách thủ công",
    "paymentTerms": "Chính sách thanh toán",
    "bankAccount": "Tài khoản ngân hàng",
    "issuingBank": "Ngân hàng phát hành",
    "taxIdNumber": "Mã số thuế",
    "contactPerson": "Người liên hệ",
    "website": "Trang mạng",
    "onlyLettersAndNumbersAreSupported": "Chỉ có chữ cái, số và '- _ . /' được hỗ trợ, vui lòng nhập lại",
    "theContentYouEnteredContainsIllegal": "Nội dung bạn nhập chứa ký tự không hợp lệ, vui lòng nhập lại",
    "autoGenerate": "Tự động tạo",
    "supplierName": "tên đệm",
    "supplierCode": "Mã nhà cung cấp",
    "createNewSupplier": "Tạo nhà cung cấp mới",
    "importSupplier": "Nhà cung cấp nhập khẩu",
    "supplierList": "Danh sách nhà cung cấp",
    "purchaseManagement": "Quản lý mua hàng",
    "supplierManagement": "Quản lý nhà cung ứng",
    "purchase": "Mua",
    "disableenable": "Tắt/Bật",
    "supplierCodeDoesNotExist": "Mã nhà cung cấp không tồn tại",
    "supplierNameAlreadyExists": "Tên nhà cung cấp đã tồn tại",
    "supplierCodeAlreadyExists": "Mã nhà cung cấp đã tồn tại",
    "failedToDeleteTheSupplierAlready": "Không thể xóa! Nhà cung cấp đã có Đơn đặt hàng hoặc không ở trạng thái Vô hiệu hóa",
    "onlySuppliersWithDisabledStatusAnd": "Chỉ có thể xóa Nhà cung cấp có trạng thái Vô hiệu hóa và không có Đơn đặt hàng nào",
    "wantToDeleteTheSupplier": "Bạn có chắc chắn muốn xóa Nhà cung cấp không?",
    "wantToEnableTheSupplier": "Bạn có chắc chắn muốn kích hoạt Nhà cung cấp không?",
    "failedToDisableThisSupplierHas": "Không thể tắt được! Nhà cung cấp này có Đơn đặt hàng chưa hoàn thành",
    "suppliersWithUnfinishedPurchaseOrdersCannot": "Không thể vô hiệu hóa các nhà cung cấp có Đơn đặt hàng chưa hoàn thành, vui lòng thận trọng khi tiến hành",
    "wantToDisableTheSupplier": "Bạn có chắc chắn muốn vô hiệu hóa Nhà cung cấp không?",
    "thisTypeHasBeenSelectedBy": "Loại này đã được nhà cung cấp lựa chọn và không thể xóa được",
    "wantToDeleteTheSupplierType": "Bạn có chắc chắn muốn xóa Loại nhà cung cấp không?",
    "typeName": "Tên loại",
    "addNewType": "Thêm loại mới",
    "fullPayment": "Thanh toán đầy đủ",
    "supplierCodeCannotBeEditedAfter": "Mã nhà cung cấp không thể được chỉnh sửa sau khi được tạo",
    "xAlreadyExists": "{x} đã tồn tại",
    "pleaseFillInThisField": "Vui lòng điền vào trường này",
    "theAmountMustBeBetweenXy": "Số tiền phải nằm trong khoảng từ {x}~{y}, cho phép 2 chữ số thập phân",
    "amountMustBeBetweenXyAnd": "Số tiền phải nằm trong khoảng từ {x}~{y} và không được phép có số thập phân.",
    "partialArrival": "Giao hàng một phần",
    "inTransit": "Trên đường vận chuyển",
    "paymentStatus": "Tình trạng thanh toán",
    "purchaseOrder": "Đơn đặt hàng",
    "paymentMethod": "Phương thức thanh toán",
    "paid": "Trả",
    "partialPayment": "Thanh toán một phần",
    "unpaid": "Chưa thanh toán",
    "feeInformation": "Thông tin phí",
    "deliveredQuantity": "Số lượng vận chuyển",
    "estimatedDateOfArrival": "Dự kiến ​​Ngày đến",
    "purchasingStaff": "Nhân viên thu mua",
    "receivingWarehouse": "Kho tiếp nhận",
    "puchaseDate": "Ngày mua hàng",
    "purchaseOrderSource": "Nguồn đơn đặt hàng",
    "noSupplier": "Không có nhà cung cấp",
    "purchaseOrderNumberAlreadyExists": "Số đơn đặt hàng đã tồn tại",
    "purchaseOrderNumberCannotBeEdited": "Số đơn đặt hàng không thể được chỉnh sửa sau khi nó được tạo",
    "pleaseEnterPurchaseOrderNumber": "Vui lòng nhập Mã đơn hàng",
    "purchaseOrderNumber": "Số lượng đơn đặt hàng",
    "basicInfoma": "Thông tin cơ bản",
    "otherNoncash": "Không dùng tiền mặt khác",
    "virtualAccount": "Tài khoản ảo",
    "paymentLink": "Liên kết thanh toán",
    "qris": "QRIS",
    "edc": "EDC",
    "bankTransfer": "Chuyển khoản ngân hàng",
    "chequeclearing": "Séc/Thanh toán bù trừ",
    "cash": "Tiền mặt",
    "discou": "Giảm giá",
    "productPurchasePrice": "Giá mua sản phẩm",
    "purchaseOrderTotalPrice": "Đơn đặt hàng Tổng giá",
    "skuQuantity": "Số lượng mã hàng",
    "totalPriceInformation": "Thông tin tổng giá",
    "inboundCostPrice": "Chi phí đầu vào",
    "individualCostSharing": "Chi phí phân bổ cá nhân",
    "taxRate": "Thuế suất",
    "onlyNumbersFromTo": "Chỉ cho phép các số từ {x}~{y}, số thập phân không được hỗ trợ",
    "discountRate": "Tỷ lệ chiết khấu",
    "purcPrice": "Giá mua",
    "quantityPcs": "Số lượng (chiếc)",
    "unit": "Đơn vị",
    "volumeM": "Khối lượng (cm³)",
    "productPriceIncludesTax": "Giá sản phẩm đã bao gồm thuế",
    "massAddd": "Thêm hàng loạt",
    "addProdu": "Thêm sản phẩm",
    "dividedByQuantity": "Phân bổ theo số lượng",
    "dividedByVolume": "Phân bổ dựa trên Khối lượng",
    "dividedByWeight": "Phân bổ dựa trên trọng lượng",
    "dividedByPrice": "Phân bổ dựa trên giá",
    "notDivided": "Không phân bổ",
    "fillInmodifyTrackingNumber": "Điền/sửa đổi Số theo dõi",
    "purchaseNote": "Lưu ý mua hàng",
    "thePurchaseOrderWarehouseIsWmsthirdparty": "Kho Đơn đặt hàng là WMS/Kho của bên thứ ba. Vui lòng thông báo cho kho thực hiện để hủy Đơn đặt hàng trước khi tiếp tục.",
    "failedToCancelThePurchaseOrder": "Không hủy được Đơn đặt hàng",
    "cancellationFailedInboundProductsInThis": "Hủy không thành công! Các sản phẩm gửi đến trong Đơn đặt hàng này đã được hoàn thành. Vui lòng làm mới trang để kiểm tra lại trạng thái của Đơn đặt hàng",
    "whenAPurchaseOrderIsCancelled": "Khi Đơn đặt hàng bị hủy, các giao dịch mua hàng trong nước sẽ không còn hiệu lực và Kho vận chuyển sẽ được giải phóng",
    "wantToCancelThePurchaseOrder": "Bạn có chắc chắn muốn hủy Đơn đặt hàng không?",
    "wantToDeleteThePurchaseOrder": "Bạn có chắc chắn muốn xóa Đơn đặt hàng không?",
    "IfTheReceivingWarehouseIs": "2. Nếu kho nhận hàng là Kho của bên thứ ba, Ginee sẽ tự động đẩy đơn hàng Inbound đến kho thực hiện",
    "AfterSubmissionThePurchaseOrder": "1. Sau khi gửi, Đơn đặt hàng sẽ chuyển sang trạng thái Đang vận chuyển và một giao dịch mua hàng trong nước sẽ được tạo ra và sản phẩm sẽ được thêm vào Kho vận chuyển",
    "youWantToSubmitThePurchase": "Bạn có chắc chắn muốn gửi Đơn đặt hàng không?",
    "identify1": "Nhận dạng",
    "copyMskuFromExcelAndPaste": "Sao chép MSKU từ Excel và dán nó vào đây. Vui lòng sử dụng enter để nhập nhiều MSKU. Mỗi dòng thể hiện một thông tin.",
    "EstimatedTimeOfArrivalFormat": "6. Định dạng Thời gian đến dự kiến: Năm/Tháng/Ngày (Ví dụ: 23/08/2023)",
    "WhenTheReceivingWarehouseIs": "3. Khi kho nhận là Kho bên thứ ba, nếu MSKU không được đẩy sang Kho bên thứ ba, vui lòng vào trang Danh sách hàng tồn kho để thêm SKU vào Kho bên thứ ba;",
    "ExplanationOfTheShippingFeeother": "4. Giải thích về Phí vận chuyển/phương pháp phân bổ chi phí khác:\n- Khi chọn “Giá”, việc phân bổ sẽ dựa trên giá của từng SKU\n- Khi chọn “Số lượng”, việc phân bổ sẽ căn cứ vào số lượng mua của từng SKU\n- Khi chọn “Số lượng”, việc phân bổ sẽ căn cứ vào số lượng của từng SKU\n- Khi chọn “Trọng lượng”, việc phân bổ sẽ dựa trên trọng lượng của từng SKU.\nNếu Phí vận chuyển/các chi phí khác không được điền, theo mặc định, khoản phí này sẽ không được phân bổ;",
    "purchaseOrderContainsMultipleProductsYou": "3. Khi Đơn đặt hàng chứa nhiều sản phẩm, bạn cần điền đầy đủ thông tin sản phẩm cho từng dữ liệu. Đối với thông tin công khai (thông tin cơ bản, thông tin chi phí, thông tin khác) chỉ cần điền dữ liệu đầu tiên. Khi thông tin công khai của nhiều dữ liệu không nhất quán thì dữ liệu đầu tiên sẽ chiếm ưu thế;",
    "WhenAPurchaseOrderContains": "2. Khi một Đơn đặt hàng có nhiều sản phẩm, vui lòng sử dụng cùng một Mã số Đơn đặt hàng. Khi nhập, nhiều hàng dữ liệu có cùng Số đơn đặt hàng sẽ được hợp nhất thành một Đơn đặt hàng;",
    "exportPurchaseOrder": "Xuất đơn đặt hàng",
    "printPurchaseOrder": "In đơn đặt hàng",
    "changePaymentStatus": "Thay đổi trạng thái thanh toán",
    "signature": "Chữ ký",
    "merchantWarehouseAddress": "Địa chỉ kho hàng thương mại",
    "contactInformation": "Liên hệ",
    "supplierAddress": "Địa chỉ nhà cung cấp",
    "supplier": "Nhà cung cấp",
    "supplierInformation": "Thông tin nhà cung cấp",
    "merchantInformation": "Thông tin người bán",
    "explanation": "Giải trình:",
    "UpToDataCan": "1. Có thể nhập tối đa 10.000 dữ liệu cùng một lúc (định dạng: xlsx, xls);",
    "estimatedTimeOfArrival": "Thời gian dự kiến đến",
    "warehouseType": "Loại kho",
    "theEntireInboundWillBeInvalidated": "Toàn bộ Hàng nhập vào sẽ bị vô hiệu và hàng tồn kho quá cảnh sẽ được giải phóng",
    "completeInbound": "Hoàn tất việc nhập vào",
    "partialInbound": "Nhập một phần",
    "afterInvalidationIsInitiatedOnlyThe": "Sau khi bắt đầu vô hiệu, chỉ có Hàng nhập vào OMS sẽ bị vô hiệu và trạng thái của Hàng nhập vào trong kho thực hiện sẽ không thay đổi",
    "onlyTheErpInboundIsInvalided": "Chỉ có OMS Inbound là không hợp lệ",
    "afterTheInvalidationIsInitiatedThe": "Sau khi bắt đầu vô hiệu, việc vô hiệu sẽ được thông báo cho kho thực hiện. Sau khi vô hiệu hóa thành công, trạng thái OMS của kho thực hiện sẽ chuyển thành không hợp lệ",
    "invalidErpAndFulfillmentInbound": "OMS vô hiệu và thực hiện đơn hàng trong nước",
    "invalidationMethod": "Phương pháp vô hiệu:",
    "theSkuWillBecameInvalidAnd": "SKU sẽ không còn hiệu lực và Hàng vận chuyển sẽ được giải phóng",
    "pleaseSelectTheInvalidationMethod": "Vui lòng chọn Phương thức vô hiệu hóa",
    "inboundFullAmount": "Toàn bộ số tiền nhập vào",
    "presentInboundQuantity": "Số lượng nhập hiện tại",
    "pendingInboundinboundCompletedplannedInbound": "Đang chờ nhập vào/Đã hoàn thành nhập vào/Đã lên kế hoạch nhập vào",
    "afterTheInboundIsCompletedThe": "Sau khi Inbound hoàn tất, trạng thái Inbound sẽ thay đổi thành \"Inbound Complete\" và không thể tiếp tục",
    "sureYouWantToEndThe": "Bạn có chắc chắn muốn kết thúc Inbound không?",
    "pushResult": "Kết quả cập nhật cửa hàng",
    "receiveItem": "Nhận hàng",
    "afterDisablingThePrintedPurchaseOrder": "Sau khi vô hiệu hóa, Đơn đặt hàng được in ra sẽ không hiển thị số tiền và phí của sản phẩm (Phí vận chuyển/phí khác/Tổng số tiền)",
    "amountDisplay": "Hiển thị số tiền",
    "purchaseOrderPrintingSettings": "Cài đặt in đơn đặt hàng",
    "purchaseSetting": "Cài đặt mua hàng",
    "receivingTime": "Thời gian nhận",
    "inboundTotalAmount": "Tổng số lượng nhập vào",
    "receiveItemNumber": "Nhận số mặt hàng",
    "supplierTypeAlreadyExists": "Loại nhà cung cấp đã tồn tại",
    "supplierType": "Loại nhà cung cấp",
    "supplierTypeDoesNotExist": "Loại nhà cung cấp không tồn tại",
    "theDataIsAbnormalAndThe": "Dữ liệu bất thường, tác vụ xuất không tồn tại",
    "xParameterIsIncorrect": "Tham số {x} không chính xác",
    "xParameterCannotBeEmpty": "Tham số {x} không được để trống",
    "thePurchaseOrderAlreadyExists": "Đơn đặt hàng đã tồn tại",
    "purchaseOrderDoesNotExist": "Đơn đặt hàng không tồn tại",
    "failedTheWarehouseTypeWasNot": "Không thành công, không lấy được loại kho",
    "purchaseOrderCancellationFailed": "Hủy đơn đặt hàng không thành công",
    "purchaseOrderDeletionFailed": "Xóa đơn đặt hàng không thành công",
    "purchaseOrderSubmissionFailed": "Gửi đơn đặt hàng không thành công",
    "purchaseOrderIsMissingProductInformation": "Đơn đặt hàng thiếu thông tin sản phẩm",
    "purchaseOrderIsNotInDraft": "Đơn đặt hàng không ở trạng thái \"Dự thảo\"",
    "purchaseOrderCancellationFailedPleaseRefresh": "Hủy đơn đặt hàng không thành công. Hãy làm mới trang và thử lại",
    "purchaseOrderDeletionFailedPleaseRefresh": "Xóa đơn đặt hàng không thành công. Hãy làm mới trang và thử lại",
    "purchaseOrderSubmissionFailedPleaseRefresh": "Gửi đơn đặt hàng không thành công. Hãy làm mới trang và thử lại",
    "theSupplierDoesNotExists": "Nhà cung cấp không tồn tại",
    "supplierAlreadyExists": "Nhà cung cấp đã tồn tại",
    "theImportedDataIsEmpty": "Dữ liệu đã nhập trống",
    "theImportedFileFormatIsNot": "Định dạng tệp đã nhập không được hỗ trợ",
    "theOperationTaskDoesNotExist": "Nhiệm vụ vận hành không tồn tại",
    "theImportTaskDoesNotExist": "Tác vụ nhập không tồn tại",
    "theExportTaskDoesNotExist": "Tác vụ xuất không tồn tại",
    "createAndPushSalesInvoicesWhen": "Tạo và đẩy Hóa đơn bán hàng khi hoàn thành việc xuất hóa đơn",
    "inboundAmount": "Số tiền gửi đến",
    "goodsReceivedNoteNumber": "Nhận số mặt hàng",
    "quotationGeneratedSuccessfully": "Báo giá được tạo thành công!",
    "theChannelWarehouseIsNotAssociated": "Kho kênh không được liên kết với Kho Ginee",
    "multiWarehouseChannelProductsAreNot": "Sản phẩm kênh đa kho không được liên kết với kho",
    "doesNotComplyWithTheRules": "Không tuân thủ quy định: Tất cả các cửa hàng được kết nối, ngoại trừ các cửa hàng mới được ủy quyền sau",
    "matchingDefaultPushRulesAreDisabled": "Khớp quy tắc đẩy mặc định bị vô hiệu hóa",
    "matchingStorePushRulesAreDisabled": "Quy tắc đẩy cửa hàng phù hợp bị vô hiệu hóa",
    "matchingCustomPushRulesAreDisabled": "Việc so khớp các quy tắc đẩy tùy chỉnh đã bị vô hiệu hóa",
    "shopifyProductInventoryIdDoesNot": "ID kho sản phẩm Shopify không tồn tại, không được phép cập nhật hàng trong kho",
    "blibliProductsAreDisabledAndStock": "Các sản phẩm Blibli bị vô hiệu hóa và không được phép cập nhật hàng trong kho",
    "returnFromTikiChannelForThis": "Trở về từ kênh Tiki cho sản phẩm này: Không được phép cập nhật",
    "theStoreHasBeenDisconnected": "Cửa hàng đã bị ngắt kết nối",
    "tikiProductInboundStatusDoesNot": "Sản phẩm Tiki trạng thái Inbound không cho phép đẩy hàng",
    "masterProductNotFound": "Không tìm thấy sản phẩm chính",
    "thePushTaskAlreadyExistsAnd": "Tác vụ đẩy đã tồn tại và không cần phải đẩy lại.",
    "failedAlreadyRepushed": "Không thành công, đã được đẩy lại",
    "pleaseSelectTheReceivingWarehouseFirst": "Vui lòng chọn kho nhận hàng trước",
    "ifNotFilledInItWill": "Nếu không điền, nó sẽ được tạo tự động",
    "region": "Khu vực",
    "cityNew": "Thành phố",
    "provinc": "Tỉnh",
    "districtsNew": "Quận",
    "purchaseQuantity": "Số lượng mua",
    "otherCostAllocationMethodsPleaseSelect": "Các phương pháp phân bổ chi phí khác (Vui lòng chọn)",
    "otherCostTotal": "Chi phí khác (Tổng cộng)",
    "shippingFeeAllocationMethodPleaseSelect": "Phương thức phân bổ phí vận chuyển (Vui lòng chọn)",
    "shippingFeeTotal": "Phí vận chuyển (Tổng cộng)",
    "wantToUnbindTheWarehouse": "Bạn có chắc chắn muốn hủy liên kết kho không?",
    "afterUnbindingChangesInStockIn": "Sau khi hủy liên kết, những thay đổi trong kho trong Kho hệ thống sẽ không được đẩy vào Kho kênh.",
    "unbindTheChannelWarehouseAaaFrom": "Hủy liên kết Kho kênh {AAA} khỏi Kho hệ thống",
    "thisFeatureIsOnlyAvailableFor": "Tính năng này chỉ có sẵn cho Tokopedia Official Store",
    "deleteSupplier": "Xóa nhà cung cấp",
    "enableSupplier": "Kích hoạt nhà cung cấp",
    "disableSupplier": "Vô hiệu hóa nhà cung cấp",
    "editSupplier": "Chỉnh sửa nhà cung cấp",
    "manualDeleteSupplierX": "Xóa thủ công nhà cung cấp [{x}]",
    "enableSupplierX": "Bật Nhà cung cấp [{x}]",
    "disableSupplierX": "Vô hiệu hóa nhà cung cấp [{x}]",
    "importToUpdateSupplierX": "Nhập để cập nhật nhà cung cấp [{x}]",
    "manualEditSupplierX": "Chỉnh sửa thủ công Nhà cung cấp [{x}]",
    "importToAddSupplierX": "Nhập để thêm nhà cung cấp [{x}]",
    "manualAddSupplierX": "Thêm nhà cung cấp thủ công [{x}]",
    "exportSupplierList": "Danh sách nhà cung cấp xuất khẩu",
    "exportPurchaseOrderX": "Xuất đơn đặt hàng [{x}]",
    "printPurchaseOrderX": "In đơn đặt hàng [{x}]",
    "cancelPurchaseOrderX": "Hủy đơn đặt hàng [{x}]",
    "deletePurchaseOrderX": "Xóa đơn đặt hàng [{x}]",
    "submitPurchaseOrderX": "Gửi đơn đặt hàng [{x}]",
    "editPurchaseOrderXManually": "Chỉnh sửa thủ công Đơn đặt hàng [{x}]",
    "importPurchaseOrderX": "Nhập để thêm đơn đặt hàng [{x}]",
    "addPurchaseOrderXManually": "Thêm đơn đặt hàng thủ công [{x}]",
    "exportPurchaseO": "Xuất đơn đặt hàng",
    "cancelPurchaseOrder": "Hủy đơn đặt hàng",
    "deletePurchaseOrder": "Xóa đơn đặt hàng",
    "submitPurchaseOrder": "Gửi đơn đặt hàng",
    "editPurchaseOrder": "Chỉnh sửa đơn đặt hàng",
    "addPurchaseOrder": "Thêm đơn đặt hàng",
    "shopeeRequirements": "Yêu cầu Shopee：",
    "formatMp": "Định dạng: MP4",
    "durationSs": "Thời lượng: 10s-60s",
    "sizeMaxMbResolutionShouldNot": "Kích thước: Tối đa 30Mb, độ phân giải không vượt quá 1280x1280px",
    "myShopeeSellerCenterCanInsert": "Chỉ một số cửa hàng Shopee mới có thể hỗ trợ hình ảnh và mô tả, vì vậy hãy đảm bảo cửa hàng của bạn hỗ trợ trước khi bật tùy chọn này.",
    "copyCopy": "Sao chép",
    "thankYouForYourCooperationPlease": "Cảm ơn sự hợp tác của bạn, vui lòng sao chép ID duy nhất và gửi cho nhóm Hỗ trợ Ginee.",
    "weNeedYourConsentToRecord": "Chúng tôi cần sự đồng ý của bạn để ghi lại màn hình và ghi lại quy trình thao tác, sau đó xác định và giải quyết vấn đề của bạn.",
    "weOnlyRecordThePagesIn": "Chúng tôi chỉ ghi lại các trang trong hệ thống Ginee.",
    "needAuthorization": "Cần sự cho phép",
    "approveStartToRecord": "Phê duyệt và bắt đầu ghi lại",
    "done": "Hoàn thành",
    "repeatTheOperationStepsToReproduce": "Lặp lại các bước thao tác để tái tạo sự cố, dừng bản ghi sau khi ghi lại toàn bộ luồng.",
    "capturingTheProcessingFlowOnThe": "Ghi lại quy trình vận hành trên màn hình",
    "stop": "Dừng lại",
    "uploading": "Đang tải lên",
    "importToUpdateSupplier": "Nhập để cập nhật nhà cung cấp",
    "importToAddSupplier": "Nhập để thêm nhà cung cấp",
    "feedback": "Nhận xét",
    "uploadVideo": "Tải video lên",
    "wereNotPerfectButWereListening": "Chúng tôi không hoàn hảo, nhưng chúng tôi đang lắng nghe",
    "feedbackForm": "Mẫu phản hồi",
    "pleaseSubmitATicektFormWith": "Vui lòng gửi mẫu vé có mô tả văn bản và hình ảnh đính kèm",
    "feedbackVideo": "Video phản hồi",
    "pleaseGrantGineeThePermissionRecord": "- Vui lòng cấp cho Ginee quyền ghi lại thao tác của bạn để chẩn đoán",
    "enterTheSpecificPageStartTo": "- Vào trang cụ thể, bắt đầu ghi và lặp lại thao tác của bạn để tái hiện vấn đề",
    "stopTheRecordCopyTheUnique": "- Dừng ghi, sao chép ID duy nhất và gửi cho nhóm Hỗ trợ Ginee",
    "faqfeedback": "Câu hỏi thường gặp/Phản hồi",
    "needHelp": "Cần giúp đỡ?",
    "detailedDescription": "miêu tả cụ thể",
    "trackingNumber": "Số theo dõi",
    "enableLongDescription": "Kích hoạt hình ảnh và mô tả.",
    "DetailProductDescription": "Mô tả sản phẩm",
    "dueToApiLimitationsBlibliMust": "Do hạn chế về API, Blibli phải sử dụng tính năng in nhanh để tải xuống trước nhãn vận chuyển của kênh. Vui lòng đi tới Cài đặt đơn hàng -> In nhanh để bật in tốc độ cao trong các cửa hàng Blibli và thử lại.",
    "abnormal": "Bất thường",
    "theInformationCannotBeFoundIn": "Nếu không tìm thấy thông tin trong Ginee, vui lòng xác nhận xem nội dung của từng trường đã khớp hay chưa hoặc đăng nhập vào Chính xác để sửa đổi thông tin rồi đồng bộ lại.",
    "purchasePurchaseOrder": "Mua / Đơn đặt hàng",
    "addToList": "Thêm vào danh sách",
    "modifyingAccuratePurchaseOrderInformationIn": "Việc sửa đổi thông tin Đơn đặt hàng Chính xác trong Ginee sẽ chỉ cập nhật dữ liệu của Ginee và sẽ không được đồng bộ hóa thành Chính xác; Sửa đổi Đơn đặt hàng ở trạng thái Chính xác ở trạng thái không phải bản nháp sẽ không được đồng bộ hóa với Ginee.",
    "pleaseSelectProduct": "Vui lòng chọn sản phẩm",
    "gineeOnlySupportsCreatingPurchaseOrder": "Ginee chỉ hỗ trợ tạo Đơn đặt hàng cho nhiều sản phẩm trong cùng một kho. Vui lòng đăng nhập vào Chính xác và sửa đổi tất cả các sản phẩm vào cùng một kho.",
    "supplierContactDetails": "Chi tiết liên hệ của nhà cung cấp",
    "operationFailed": "Thao tác thất bại",
    "whenSelectingMultipleShopeeStoresThe": "Khi chọn nhiều cửa hàng Shopee, giá trị hàng tồn kho chỉ có thể cập nhật về kho mặc định; Nếu bạn cần cập nhật hàng lên nhiều kho của Shopee vui lòng chỉ chọn 1 cửa hàng",
    "requestTimedOutPleaseCheckThe": "Yêu cầu đã hết thời gian chờ, vui lòng kiểm tra mạng và thử lại.",
    "manageMultiwarehouseStock": "Quản lý kho nhiều kho",
    "supplierCodeSupplierCodeCannotBe": "Mã nhà cung cấp (Mã nhà cung cấp không thể chỉnh sửa sau khi tạo)",
    "hasReturnrefund": "Đã trả lại/Hoàn tiền",
    "batchEditingOnlySupportsModifyingA": "Chỉnh sửa hàng loạt chỉ hỗ trợ chỉnh sửa kho trong một kho. Nếu bạn cần chỉnh sửa tồn kho ở một vài kho, vui lòng sử dụng chỉnh sửa một sản phẩm",
    "invalidationIsInitiatedOnlyTheOms": "Sau khi quá trình vô hiệu được bắt đầu, chỉ Chuyến đi OMS sẽ bị vô hiệu và trạng thái của Chuyến đi trong kho thực hiện sẽ không thay đổi",
    "onlyTheOmsOutboundIsInvalided": "Chỉ OMS Outbound là không hợp lệ",
    "invalidateOmsAndFulfillmentOutbound": "OMS không hợp lệ và thực hiện gửi đi",
    "installationTutorial": "Hướng dẫn cài đặt",
    "howToUse": "Cách sử dụng",
    "downloadAndInstallThePlugin": "Tải xuống và cài đặt plugin",
    "goToChromeWebStoreTo": "Đi tới Cửa hàng Chrome trực tuyến để cài đặt",
    "ForPluginsHowToUse": "4. Để biết Cách sử dụng plugin, vui lòng tham khảo:",
    "IfYouCannotAccessThe": "3. Nếu bạn không truy cập được Chrome Web Store, hãy tải plugin tại đây:",
    "PluginInstallationLink": "2. Link cài đặt plugin:",
    "RecommendedBrowserGoogleChrome": "1. Trình duyệt khuyên dùng: Google Chrome",
    "pleaseDownloadAndUseTheScrape": "Hãy tải và sử dụng plugin Scrape, chức năng ổn định và nhanh chóng!",
    "editAndPublishFromTheStore": "Chỉnh sửa và xuất bản từ bản nháp sản phẩm của cửa hàng",
    "EditAndPublish": "4. Chỉnh sửa và xuất bản",
    "selectAStoreAndCopyThe": "Chọn cửa hàng và sao chép Sản phẩm chính vào bản nháp sản phẩm của cửa hàng",
    "CopyAndSellInStores": "3. Sao chép và bán tại cửa hàng",
    "massClaimAndCreateMasterProduct": "Yêu cầu đại chúng và tạo ra Sản phẩm chính",
    "ClaimTheMasterProduct": "2. Yêu cầu Sản phẩm Chính",
    "visitTheProductPageYouWant": "Truy cập trang sản phẩm bạn muốn cạo và sử dụng plugin để cạo nó chỉ bằng một cú nhấp chuột",
    "ScrapeProduct": "1. Sản phẩm cạo",
    "returnrefund": "Trở lại/đền bù",
    "editWarehouseStockInStockList": "Chỉnh sửa kho hàng trong danh sách kho",
    "importToUpdateStockIncreasedecrease": "Nhập để cập nhật tồn kho - Tăng/Giảm giá trị",
    "importToUpdateStockFinal": "Nhập để cập nhật hàng tồn kho - Giá trị cuối cùng",
    "purchaseOrdersSourcedFromAccurateDo": "Đơn đặt hàng có nguồn gốc từ Chính xác không hỗ trợ hủy trong Ginee. Nếu bạn cần hủy, vui lòng đăng nhập vào Chính xác để hủy.",
    "failedToCreateOmsInboundThe": "Không tạo được OMS Inbound: SKU trong WMS Inbound không tồn tại trong OMS hoặc là sản phẩm kết hợp",
    "IfYouHaveDoneThe": "5. Nếu bạn đã thực hiện các bước trên, vui lòng liên hệ bộ phận chăm sóc khách hàng",
    "IfEverythingIsNormalPlease": "4. Nếu mọi thứ vẫn bình thường, vui lòng thử lại sau 1 phút",
    "PleaseSwitchTheNetworkAnd": "3. Vui lòng chuyển mạng và thử lại",
    "PleaseConfirmThatTheBrowser": "2. Vui lòng xác nhận rằng trình duyệt không có cài đặt đặc biệt nào hoặc thử lại sau khi thay đổi trình duyệt",
    "PleaseCheckWhetherYouAre": "1. Vui lòng kiểm tra xem bạn có đang sử dụng trình cắm của bên thứ ba như chặn quảng cáo/tên miền, v.v. Bạn nên đóng trình cắm và thử lại",
    "inComplianceWithShopeePolicyBuyer": "Tuân thủ chính sách của Shopee, thông tin người mua sẽ không còn được hiển thị đối với các đơn hàng lịch sử vượt quá 90 ngày. Xin hãy hiểu sự bất tiện do việc này gây ra.",
    "inComplianceWithShopeePolicyThe": "Tuân thủ chính sách của Shop, Danh sách khách hàng sẽ không cung cấp dữ liệu đơn hàng của Shop.",
    "massAddHistory": "Thêm nhiều Lịch sử",
    "cancellation": "Hủy bỏ",
    "failedDelivery": "Chuyển phát thất bại",
    "ordersHasBeenMovedToReturnrefund": "Đơn hàng[%s] đã được chuyển sang Hoàn trả/Hoàn tiền",
    "moveToReturnrefund": "Chuyển sang Trả lại/Hoàn tiền",
    "ordersHasBeenMovedToCompleted": "Đơn hàng[%s] đã được chuyển sang Đã hoàn thành",
    "moveToCompleted": "Chuyển đến Đã hoàn thành",
    "youWantToMoveTheOrder": "Bạn có chắc chắn muốn chuyển đơn hàng sang Đã hoàn thành không?",
    "confirmToSync": "Xác nhận để đồng bộ hóa?",
    "purchaseSupplierManagement": "Purchase & Supplier Management",
    "afterClosingAllStoresWillNot": "Sau khi vô hiệu hóa, tất cả các cửa hàng sẽ không được đồng bộ hóa và hàng khuyến mãi của Shop sẽ không được bảo lưu",
    "wantToDisableAutomaticallyReservePromotion": "Bạn có chắc chắn muốn tắt tính năng Tự động đặt trước hàng khuyến mãi không?",
    "pushingPleaseRefreshThePageLater": "Đang đẩy, vui lòng tải lại trang sau để xem Trạng thái đẩy mới nhất",
    "wantToMassDisableAutoPush": "Bạn có chắc chắn muốn tắt hàng loạt tính năng Tự động đẩy không?",
    "wantToMassEnableAutoPush": "Bạn có chắc chắn muốn bật tự động đẩy hàng loạt không?",
    "onlyGineeWmsWarehouseSupportsEditing": "Chỉ kho Ginee WMS hỗ trợ chỉnh sửa Chiến lược đẩy",
    "editPushStrategy": "Chỉnh sửa chiến lược đẩy",
    "disableAutoPush": "Vô hiệu hóa tự động đẩy",
    "enableAutoPush": "Bật tự động đẩy",
    "autoPush": "Tự động đẩy",
    "pushStrategy": "Chiến lược đẩy",
    "theOrderWillBePushedTo": "Đơn hàng sẽ được đẩy trước đến kho bên thứ ba theo điều kiện cấu hình. Các lệnh khác vẫn sẽ được đẩy theo Đẩy Thông Thường. Đơn đặt hàng có vấn đề sẽ không được đẩy",
    "regardlessOfWhetherTheOrderIs": "Bất kể đơn hàng có đủ điều kiện vận chuyển hay không (không sắp xếp vận chuyển, không AWB, không nhãn vận chuyển), tất cả các đơn hàng được đồng bộ hóa với trạng thái \"Đã thanh toán\" và \"Sẵn sàng giao hàng\", sau khi Danh sách gửi đi được tạo sẽ ngay lập tức được đẩy sang kho của bên thứ ba, Đơn hàng có vấn đề sẽ không được đẩy",
    "theDefaultPushRuleIsThat": "Quy tắc đẩy mặc định là chỉ những đơn hàng đã có nhãn vận chuyển mới được phép đẩy đến kho của bên thứ ba và Đơn hàng có vấn đề sẽ không được đẩy",
    "strategyDescription": "Mô tả chiến lược",
    "deleteFailedThereIsAWarehouse": "Xóa không thành công! Có một nhà kho đang được sử dụng!",
    "onlyStrategiesThatAreNotAssociated": "Chỉ có thể xóa các chiến lược không liên quan đến kho",
    "wantToDeleteTheStrategy": "Bạn có chắc chắn muốn xóa chiến lược này không?",
    "allowEarlyPushWhenTheOrder": "Cho phép đẩy sớm khi trạng thái đơn hàng là \"Đã thanh toán\"",
    "pleaseSetAtLeastOneAdvance": "Vui lòng đặt ít nhất một điều kiện Đẩy sớm. Chỉ những lệnh đáp ứng các điều kiện đã chọn mới được kích hoạt để đẩy trước. Nếu không, Đẩy bình thường sẽ được thực hiện",
    "upToCustomStrategiesCan": "Có thể thêm tối đa 100 chiến lược tùy chỉnh",
    "warehouseUsed": "Kho đã qua sử dụng",
    "pushPaidOrdersInAdvance": "Đẩy đơn hàng \"trả tiền\" trước",
    "strategyName": "Tên chiến lược",
    "manageCustomPolicies": "Quản lý chiến lược tùy chỉnh",
    "customAdvancePush": "Đẩy sớm tùy chỉnh",
    "pushAllInAdvance": "Đẩy tất cả sớm",
    "basicPush": "Đẩy bình thường",
    "normalPushPushAllEarlyCustom": "Có thể đặt Đẩy bình thường, Đẩy tất cả sớm, Đẩy sớm tùy chỉnh",
    "thirdpartyWarehousePushSettings": "Cài đặt đẩy kho của bên thứ ba",
    "quantityLimitInfo": "Số lượng mua không được vượt quá 1000",
    "valueShouldBeAtLeastThe": "Tối thiểu không ít hơn  {count}",
    "purchaseLimit": "Giới hạn mua",
    "maxPerOrder": "Tối đa mỗi đơn hàng",
    "storesAreAutomaticallySetToEnable": "[Cửa hàng được ủy quyền mới tự động được đặt thành \"Bật\"] Đã tắt",
    "authorizedStoresAreAutomaticallySetTo": "[Cửa hàng được ủy quyền mới tự động được đặt thành \"Bật\"] Đã bật",
    "storeXDisableTheAutomaticallyReserve": "Cửa hàng {x} vô hiệu hóa công tắc chính Tự động đặt trước hàng khuyến mãi",
    "storeXEnableTheAutomaticallyReserve": "Cửa hàng {x} bật công tắc chính Tự động đặt trước kho khuyến mãi",
    "disableAutomaticallyReservePromotionStockMain": "Vô hiệu hóa tính năng tự động dự trữ cổ phiếu khuyến mãi Công tắc chính",
    "enableAutomaticallyReservePromotionStockMain": "Kích hoạt tính năng Tự động dự trữ hàng khuyến mãi Chuyển đổi chính",
    "enabledisableAutomaticallyReservePromotionStock": "Bật/Tắt Tự động dự trữ hàng khuyến mãi",
    "change": "Thay đổi",
    "superLinkAsAnOpenDoor": "Siêu liên kết như một cửa mở để kết nối tất cả các cửa hàng, liên kết, nội dung kinh doanh của bạn, v.v., trong một trang!",
    "actualInboundQty": "Số lượng nhập thực tế",
    "plannedInboundQty": "Số lượng nhập vào theo kế hoạch",
    "tipShopeePendingWaitingForShopee": "Mẹo: Shopee Đang chờ xử lý: Đang chờ Shopee cung cấp thông tin hậu cần hoặc Shopee vẫn đang tiến hành kiểm tra gian lận. Vui lòng đồng bộ hóa đơn hàng theo cách thủ công sau để có trạng thái mới nhất",
    "shopeeOrdersAreCurrentlyInPending": "Đơn hàng của Shop hiện đang ở trạng thái Đang chờ xử lý. Hiện tại, việc lấy nhãn vận chuyển không được hỗ trợ. Vui lòng thử lại sau",
    "gineeHasAutomaticallyFilteredXShopee": "Ginee đã tự động lọc {x} đơn hàng trên Shopee có trạng thái là Đang chờ xử lý và không được phép sắp xếp lô hàng",
    "Template": "Tệp tin mẫu",
    "promotionname": "Tên khuyến mãi",
    "EndTimeLeastDay": "Thời điểm kết thúc phải lớn hơn thời gian bắt đầu ít nhất một ngày",
    "theEndTimeMustBeLater": "Thời điểm kết thúc phải sau thời điểm bắt đầu",
    "TokoStartMinutesTips": "Thời điểm bắt đầu phải sau thời điểm hiện tại ít nhất 5 phút",
    "startTimeMustBeAtLeast": "Thời điểm bắt đầu phải sau thời điểm hiện tại ít nhất 5 phút",
    "OnceTheDiscountPromotion": "Một khi chương trình khuyến mãi được lưu thành công, thời gian hoạt động chỉ có thể bị rút ngắn",
    "theEndTimeMustBeGreater": "Thời điểm kết thúc phải lớn hơn thời gian bắt đầu ít nhất một giờ",
    "EntitledProducts": "Sản phẩm áp dụng",
    "RemoveProducts": "Xóa sản phẩm",
    "removeSelectedProducts": "Xóa sản phẩm đã chọn`",
    "RemoveAllProducts": "Xóa tất cả",
    "promotionDetails": "Chi tiết khuyến mãi",
    "UpdateAllProducts": "Cập nhật đến tất cả sản phẩm",
    "UpdateSelectedProducts": "Cập nhật đến sản phẩm đã chọn",
    "SetDiscountAt": "Cài đặt giảm giá thành",
    "stockMonitorAndSyncIsNot": "Giám sát và đồng bộ hóa chứng khoán chưa được bật",
    "inboundHistory": "Lịch sử gửi đến",
    "inboundDetails": "Chi tiết gửi đến",
    "currentlyDoesNotSupportUploadingeditingLazada": "Hiện tại không hỗ trợ tải lên/chỉnh sửa video Lazada",
    "noteYouCanPublishThisListing": "Lưu ý: Bạn có thể xuất bản danh sách này trong khi video đang được xử lý. Video sẽ được hiển thị trong danh sách sau khi được xử lý thành công",
    "pageNumber": "/ trang",
    "rejectionReason": "Lý do từ chối",
    "theShippingLabelIsEmptyPush": "Nhãn vận chuyển trống, đẩy không thành công",
    "theTrackingNumberIsEmptyPush": "Số theo dõi trống, đẩy không thành công",
    "doesntMeetTheOrderStatusRequirement": "Không đáp ứng yêu cầu về trạng thái đơn hàng, đẩy không thành công",
    "problemOrderStoredOrderPush": "Sự cố Đơn hàng & Đơn hàng đã lưu, đẩy không thành công",
    "reminderOnceTheStoreStockIs": "Nhắc nhở: Sau khi đẩy kho hàng thành công, nó sẽ không thể được khôi phục. Hãy đảm bảo rằng mỗi MSKU được liên kết với kho hàng và thiết lập kho hàng chính xác.",
    "whenANewOrderIsPlaced": "Khi một đơn hàng mới được đặt hoặc một đơn hàng bị hủy đối với MSKU, lượng hàng tồn kho của tất cả các sản phẩm trong kênh liên kết với MSKU sẽ giảm hoặc tăng một cách đồng đều",
    "theStockOfAllChannelProducts": "Kho của tất cả các sản phẩm kênh liên kết với cùng một MSKU sẽ được đẩy ngay lập tức theo \"{x}\"",
    "afterTurningOnStockMonitorAnd": "Sau khi bật Stock Monitor và Sync:",
    "addingeditingProductsOrStockInVivo": "Không hỗ trợ thêm/chỉnh sửa sản phẩm hoặc hàng tồn trong kho Vivo. Nếu cần chỉnh sửa vui lòng đăng nhập vào hệ thống Vivo",
    "beforeOutboundItHasBeenVerified": "Trước khi xuất hàng đã xác minh kho Vivo thiếu hàng, đẩy không thành công",
    "areYouSureToEnableStock": "Bạn có chắc chắn bật Stock Monitor và Sync không?",
    "areYouSureToDisableStock": "Bạn có chắc chắn tắt Stock Monitor và Sync không?",
    "plannedInboundCost": "Chi phí đầu vào theo kế hoạch",
    "onlyTheGineeWmsWarehouseSupports": "Chỉ kho Ginee WMS / Keeppack / FASTOCK hỗ trợ chỉnh sửa Chiến lược đẩy. Việc chỉnh sửa chiến lược đẩy sẽ chỉ có hiệu lực đối với các đơn hàng mới. Các lệnh hiện tại có thể kích hoạt lại Chiến lược đẩy thông qua \"Đồng bộ hóa\"",
    "editingThePushStrategyWillOnly": "Việc chỉnh sửa Chiến lược đẩy sẽ chỉ có hiệu lực đối với các đơn hàng mới. Các lệnh hiện tại có thể kích hoạt lại Chiến lược đẩy thông qua \"Đồng bộ hóa\"",
    "userManuallyEditedTheWarehouseStock": "Người dùng chỉnh sửa thủ công Kho hàng khiến Kho hàng có sẵn trở thành số âm",
    "multipleChannelProductsAreBoundTo": "Nhiều sản phẩm tại cửa hàng/kênh được liên kết với cùng một MSKU, điều này sẽ khiến cùng một cổ phiếu được bán cùng một lúc. Nếu khách hàng mua cùng lúc sẽ gây ra hiện tượng bán quá mức",
    "orderSyncDelayDueToDelay": "Trì hoãn đồng bộ hóa đơn hàng: do việc đồng bộ hóa đơn hàng từ thị trường bị chậm trễ, thực tế tồn kho đã được sử dụng cho đơn hàng khác",
    "failedPushStockStockNotPushed": "Đẩy hàng không thành công, hàng không được đẩy về cửa hàng khiến hàng trong kho không khớp với hàng Ginee",
    "TheStockWasEditedIn": "2. Cổ phiếu đã được chỉnh sửa trong Trung tâm người bán. Việc chỉnh sửa hàng trong Trung tâm người bán sẽ không được đồng bộ hóa với hàng trong kho của Ginee MSKU, điều này có thể gây ra tình trạng hàng trong kho không nhất quán giữa Ginee và kênh.",
    "AbnormalitiesWhenTheChannelAdjusts": "1. Bất thường khi kênh điều chỉnh cổ phiếu dẫn đến cổ phiếu không được điều chỉnh hoàn toàn.",
    "gineeSuccessfullyPushedTheStockAnd": "Ginee đã đẩy hàng thành công và thị trường đã nhận được hàng thành công. Có 3 khả năng:",
    "pushDelayDueToTheLarge": "Độ trễ đẩy do số lượng nhiệm vụ đẩy lớn, cổ phiếu không được đẩy ra thị trường đúng thời hạn",
    "noPushRecordFoundStockNot": "Không tìm thấy bản ghi đẩy, hàng không được đẩy vào cửa hàng, khiến hàng trong kho không khớp với hàng của Ginee",
    "thisStoreHasReservedPromotionStock": "Cửa hàng này có Hàng khuyến mại đã đặt trước, nhưng Hàng khuyến mãi dự trữ tự động của Ginee bị vô hiệu hóa, khiến hàng trong kho của cửa hàng không khớp với hàng trong kho của Ginee",
    "theStockMonitorAndSyncIs": "Chức năng Giám sát và Đồng bộ hóa Chứng khoán bị vô hiệu hóa nên kho hàng cập nhật của Ginee không được đẩy đến các cửa hàng",
    "missingOrderInformationsCurrentlyUnableTo": "Thiếu thông tin đơn hàng. Hiện chưa xác định được nguyên nhân",
    "pleaseCheckTheChangeReasonOn": "Vui lòng kiểm tra Lý do thay đổi ở phía bên phải của cột này",
    "pleaseCheckThePushFailedReason": "Vui lòng kiểm tra lý do đẩy không thành công trong Bản ghi đẩy hàng",
    "forOtherChannelsPleaseDirectlyContact": "Đối với các kênh khác, vui lòng liên hệ trực tiếp với bộ phận Dịch vụ khách hàng Ginee hoặc Tài khoản chính của bạn để kiểm tra thêm",
    "PleaseContactGineeCustomerService": "3. Vui lòng liên hệ với Dịch vụ khách hàng Ginee hoặc Tài khoản chính của bạn để kiểm tra thêm",
    "PleaseSyncTheProductThrough": "1. Vui lòng đồng bộ sản phẩm qua Kênh sản phẩm",
    "forShopeeBukalapakAndLazadaProducts": "Đối với sản phẩm Shopee, Bukalapak và Lazada:",
    "ifYouDidntEditTheStock": "Nếu bạn không chỉnh sửa kho hàng từ Trung tâm người bán, vui lòng làm theo các bước bên dưới:",
    "preventTheSameIssueHappeningIn": "Để ngăn vấn đề tương tự xảy ra trong tương lai, vui lòng bật Khuyến mãi tự động đặt trước",
    "toPreventTheSameIssueHappening": "Để ngăn sự cố tương tự xảy ra trong tương lai, vui lòng bật Trình giám sát và đồng bộ hóa chứng khoán",
    "pleaseEnableStockMonitorAndSync": "nên kho của cửa hàng sẽ được cập nhật theo Quy định đẩy hàng",
    "notesIfTheStockIsSmaller": "Lưu ý: Nếu hàng tồn kho nhỏ hơn (-1), vui lòng kiểm tra Bản ghi thay đổi hàng tồn kho để biết (-1) hàng tồn kho đầu tiên",
    "pleaseContactGineeCustomerServiceOr": "Vui lòng liên hệ với Dịch vụ khách hàng Ginee hoặc Tài khoản chính của bạn để kiểm tra thêm",
    "gineeOmsOrderusedtotal": "Đơn hàng Ginee OMS (Đã sử dụng/Tổng cộng)",
    "mskuUsedtotal": "MSKU (Đã sử dụng/Tổng cộng)",
    "currentlyDoesNotSupportUploadingeditingShopee": "Hiện tại không hỗ trợ tải lên/chỉnh sửa video Shopee",
    "gineeWmsOrderusedtotal": "Đơn hàng Ginee WMS (Đã sử dụng/Tổng cộng)",
    "walletCredit": "Tín dụng Ví",
    "gineeWmsConsignorusedtotal": "người gửi hàng Ginee WMS (Đã sử dụng/Tổng cộng)",
    "onlySupportViewingCustomerDataFrom": "Chỉ hỗ trợ xem data khách hàng từ 1/1/2023",
    "packageSplit": "Chia gói",
    "theMskuWasNotFoundIn": "Không tìm thấy MSKU trong kho của bên thứ ba. Vui lòng kiểm tra trạng thái đẩy của SKU trong trang danh sách chứng khoán. Nếu việc đẩy không thành công, vui lòng vận hành thủ công \"Thêm vào kho thực hiện\".",
    "changePrice": "Thay đổi giá",
    "discAfterTax": "Thuế sau khi giảm giá",
    "discBeforeTax": "Thuế trước khi giảm giá",
    "productTaxIncluded": "Đã bao gồm thuế sản phẩm",
    "additionalTax": "Thêm thuế vào giá",
    "vatRules": "Quy tắc thuế",
    "vat": "VAT",
    "selectAndAdd": "Chọn và thêm",
    "customerNotFoundDoYouWant": "Không tìm thấy khách hàng, bạn có muốn thêm nó bây giờ không?",
    "selectFromExistingCustomerList": "Chọn từ Danh sách khách hàng hiện có",
    "enterCustomerPhoneNumberToAdd": "Nhập Số điện thoại của Khách hàng để thêm Khách hàng hoặc tìm kiếm Khách hàng hiện tại",
    "addBuyer": "Thêm người mua",
    "unknown": "không xác định",
    "afterDeduction": "Sau khi khấu trừ",
    "byPercentage": "Theo phần trăm",
    "byAmount": "Theo số tiền",
    "deductionMethod": "Phương pháp khấu trừ",
    "deductionAmount": "Số tiền khấu trừ",
    "actualPayment": "Thanh toán thực tế",
    "pleaseAddProductsFirst": "Vui lòng thêm sản phẩm trước",
    "youWantToClearYourCart": "Bạn có chắc chắn muốn xóa giỏ hàng của mình không?",
    "vatIncluded": "Đã bao gồm VAT",
    "payable": "Số tiền đến hạn",
    "productSubtotal": "Tổng phụ sản phẩm",
    "viewDetails": "Xem chi tiết",
    "productDiscount": "Giảm giá sản phẩm",
    "wholeOrderReduction": "Giảm toàn bộ đơn hàng",
    "youSureYouWantToDelete": "Bạn chắc chắn muốn xóa?",
    "switchingStores": "Chuyển đổi cửa hàng...",
    "wantToChangeTheStoreTo": "Bạn có chắc chắn muốn thay đổi cửa hàng thành {x} không?",
    "clearShoppingCart": "Dọn dẹp giỏ hàng",
    "disc": "Đĩa.",
    "totalXItems": "Tổng số {x} mặt hàng",
    "deduction": "Khấu trừ",
    "checkOut": "Thủ tục thanh toán",
    "submitOrder": "Nộp đơn đặt hàng",
    "productBarcode": "Mã vạch sản phẩm",
    "pleaseScanTheProductBarcode": "Vui lòng quét mã vạch sản phẩm",
    "cashier": "Thu ngân",
    "signalStrength": "Cường độ tín hiệu",
    "language": "Ngôn ngữ",
    "loginToTheStoreSuccessfully": "Đăng nhập vào cửa hàng thành công!",
    "pleaseSelectAStore": "Vui lòng chọn cửa hàng",
    "thereIsNoAccessibleStorePlease": "Không có cửa hàng có thể truy cập, vui lòng liên hệ với quản trị viên để kích hoạt nó",
    "partialRefund": "Hoàn lại một phần",
    "refunded": "Đã hoàn tiền",
    "partialReturn": "Hoàn trả một phần",
    "completedDone": "Hoàn thành",
    "printReceipt": "In biên lai",
    "noNeedToPrintReceipt": "Không cần in biên lai",
    "cashReceived": "Số tiền nhận được",
    "transactionAmount": "số tiền giao dịch",
    "numberOfItems": "Số hạng mục",
    "customer": "Khách hàng",
    "transactionTime": "Thời gian giao dịch",
    "transactionSuccessful": "Giao dịch thành công!",
    "reorder": "Đặt hàng lại",
    "theShoppingCartWillBeAutomatically": "Giỏ hàng sẽ tự động được xóa, vui lòng chọn lại sản phẩm để đặt hàng",
    "theStoreHasChangedItsWarehouse": "Cửa hàng kho đã thay đổi và không thể đặt hàng!",
    "continueToPlaceAnOrder": "Tiếp tục đặt hàng",
    "removeOutOfStockProducts": "Loại bỏ sản phẩm hết hàng",
    "outOfStockQuantity": "Hết hàng Số lượng",
    "theFollowingProductsAreOutOf": "Các sản phẩm sau đã hết hàng! Tiếp tục đặt hàng sẽ dẫn đến bán quá mức",
    "card": "Thẻ",
    "returnFailedTheReturnedItemHas": "Trở về không thành công! Mặt hàng trả lại đã bị xóa. Vui lòng chọn Chỉ hoàn tiền và trả sản phẩm về kho theo cách thủ công",
    "returnFailedTheReturnWarehouseIs": "Trở về không thành công! Kho trả lại bị vô hiệu hóa. Vui lòng chọn Chỉ hoàn tiền và trả sản phẩm về kho theo cách thủ công",
    "refundToTheOriginalCard": "Hoàn tiền vào thẻ gốc",
    "refundMethod": "Phương thức hoàn tiền",
    "vatRefund": "Hoàn thuế GTGT",
    "afterOperatingReturnTheStockThe": "Sau khi vận hành Return, lượng hàng tồn kho sẽ được bổ sung trở lại kho ngay lập tức",
    "wantToReturn": "Bạn có chắc chắn muốn quay lại không?",
    "return": "Trở lại",
    "completed": "Hoàn thành",
    "cancelEntireOrder": "Hủy toàn bộ đơn hàng",
    "onceTheEntireOrderIsCancelled": "Sau khi toàn bộ đơn hàng bị hủy, đơn hàng sẽ được hoàn lại toàn bộ số tiền và lượng sản phẩm trong đơn hàng sẽ được bổ sung ngay vào kho",
    "cancelTheEntireOrder": "Bạn có chắc chắn muốn hủy toàn bộ đơn hàng không?",
    "onlyRefundsWillOnlyAffectThe": "Chỉ hoàn tiền sẽ chỉ ảnh hưởng đến Thanh toán thực tế của đơn hàng và sẽ không ảnh hưởng đến kho sản phẩm",
    "youWantToRefund": "Bạn có chắc chắn muốn Hoàn tiền không?",
    "orderActualPayment": "Đặt hàng Thanh toán thực tế",
    "amountExcludeVat": "Số tiền chưa bao gồm VAT",
    "companyPhoneNumber": "Số điện thoại công ty",
    "companymerchantTin": "Công ty/Thương gia TIN",
    "companymerchantLegalAddress": "Địa chỉ pháp lý của công ty/người bán",
    "companymerchantName": "Tên công ty/người bán",
    "totalAmountPaid": "TỔNG SỐ TIỀN THANH TOÁN",
    "addVatX": "Cộng: VAT ({x}%)",
    "amountDue": "Số tiền đến hạn",
    "lessDiscounts": "Ít hơn: Đĩa.",
    "lessVatx": "Trừ: VAT({x}%)",
    "totalAmount": "Tổng cộng",
    "vatExemptSales": "Bán hàng miễn thuế VAT",
    "zeroratedSales": "Bán hàng không được xếp hạng",
    "vatableSales": "Bán hàng chịu thuế GTGT",
    "itemBig": "MỤC",
    "siNo": "SI Không",
    "businessStyle": "Phong cách kinh doanh",
    "tinNo": "số TÍN",
    "soldTo": "Bán cho",
    "changes": "Thay đổi",
    "totalPaid": "Tổng số chi trả",
    "totalOrder": "Tổng số đơn hàng",
    "vatx": "Thuế GTGT({x}%)",
    "totalDisc": "Tổng đĩa.",
    "totalItem": "Tổng phụ sản phẩm",
    "cashierPerson": "Thu ngân",
    "orderNo": "Số thứ tự",
    "insufficientStockUnableToPurchaseProducts": "Không đủ hàng, không thể mua sản phẩm",
    "isNotCorrectPleaseCheckPhone": "Số không hợp lệ, vui lòng kiểm tra",
    "mobileRangeLengthWithPrefix": "Số điện thoại phải bắt đầu bằng số {prefix} và bao gồm {min} đến {max} chữ số.",
    "mobileLengthWithPrefix": "Số điện thoại phải bắt đầu bằng {prefix} và bao gồm {length} số",
    "mobileRangeLength": "Số điện thoại di động phải có từ {min} đến {max} chữ số",
    "posSettings": "Cài đặt POS",
    "manageGineePosStore": "Quản lý cửa hàng Ginee POS",
    "changingWarehousesWillReplaceTheProducts": "Việc thay đổi kho sẽ thay thế sản phẩm trong POS Store bằng sản phẩm và hàng tồn trong kho mới, vui lòng vận hành thận trọng",
    "taxCollectionPreference": "Ưu đãi thu thuế",
    "orderTotalAfterRefund": "Tổng đơn hàng sau khi hoàn tiền",
    "productQuantity": "Chất lượng sản phẩm",
    "selectReturnProduct": "Chọn trả lại sản phẩm",
    "receipt": "Biên lai",
    "newDiscountedPrice": "Giá ưu đãi mới",
    "taxDiscountPreference": "Ưu đãi giảm thuế",
    "pleaseEnterCorrectUsername": "Vui lòng nhập đúng tên người dùng",
    "orderPhoneNumber": "Số điện thoại đặt hàng",
    "customerNam": "tên khách hàng",
    "theAmountDueCannotBeLess": "Số tiền đến hạn không được nhỏ hơn Số tiền phải thu",
    "excessiveReturnIsNotSupported": "Hoàn trả quá mức không được hỗ trợ",
    "theStoreIsNotBoundTo": "Cửa hàng không bị ràng buộc vào kho",
    "orderDoesNotExist": "Đơn hàng không tồn tại",
    "theStoreDoesNotExist": "Cửa hàng không tồn tại",
    "productBarcodeXDoesNotExist": "Mã vạch sản phẩm {x} không tồn tại trong kho",
    "notExistInTheWarehouse": "MSKU {x} không tồn tại trong kho",
    "addMskuToTheWarehouseFirst": "MSKU không tồn tại trong kho, vui lòng thêm MSKU vào kho trước",
    "pay": "chi trả",
    "aOperationToCancelTheEntire": "{a} Hủy toàn bộ đơn hàng, Ghi chú {b}",
    "aOperationReturnAndRefundMsku": "{a} Hoạt động hoàn trả và hoàn tiền, MSKU {b}, Số lượng {c}, Số tiền hoàn lại {d}, Tiền hoàn thuế {e}, Nhận xét {f}",
    "aOperationRefundRefundAmountB": "{a} Hoạt động hoàn tiền, số tiền hoàn lại {b}, tiền hoàn thuế {c}, nhận xét {d}",
    "discountPriceCannotBeHigherThan": "Giá chiết khấu không được cao hơn giá gốc",
    "supportsInputNumNoMore": "Chỉ hỗ trợ nhập số/chữ cái/dấu cách/\"-\"/\"_\"",
    "ooOrders": "Đơn đặt hàng O2O",
    "ifYouDoNotHaveOms": "Nếu bạn không có quyền Quản lý khách hàng OMS, vui lòng liên hệ với quản trị viên để kích hoạt nó",
    "totalVatInc": "TOTAL",
    "netDue": "DO NET",
    "lessOtherDiscounts": "Ít hơn: Giảm giá khác",
    "customerPhoneNo": "Số điện thoại của khách hàng",
    "doNotAllowLazadaEase": "Giới hạn kênh: Không cho phép cập nhật kho sản phẩm Cửa hàng Chế độ dễ dàng của Lazada",
    "doNotAllowTokoOO": "Hạn chế về kênh: Không cho phép cập nhật hàng tồn kho cho sản phẩm trong kho Tokopedia O2O",
    "youHaveAddedAChannelSku": "Bạn đã thêm SKU kênh bằng một tên khác. Việc liên kết các sản phẩm khác nhau với MSKU có thể dẫn đến tình trạng bán quá mức. Vui lòng xác nhận rằng SKU kênh và MSKU là cùng một sản phẩm",
    "bindingSkuWithDifferentNamesMay": "Việc liên kết SKU với các tên khác nhau có thể dẫn đến bán quá mức, vui lòng thận trọng khi thực hiện",
    "channelReturnedErrorReachTheChannel": "Lỗi trả về {Kênh}: Đạt đến giới hạn QPS API của kênh, việc đẩy cổ phiếu không thành công.",
    "expiredStoresWillNotPushInventory": "Cửa hàng hết hạn sẽ không đẩy hàng, vui lòng ủy quyền lại càng sớm càng tốt!",
    "massReleaseWillReleaseTheReserved": "Mass Release sẽ phát hành Kho khuyến mãi dành riêng theo cấp độ sản phẩm (bao gồm tất cả các biến thể của sản phẩm đã chọn), vui lòng vận hành một cách thận trọng!",
    "recommendedOperation": "Hoạt động được đề xuất",
    "oversoldReasons": "Lý do bán quá mức",
    "pleaseEnable": "Vui lòng kích hoạt",
    "ThereAreCancellationreturnrefundOrdersOn": "3. Có các đơn đặt hàng hủy/trả lại/hoàn tiền trên kênh khiến lượng hàng tồn kho của kênh tự động tăng lên. Người mua có thể đặt hàng nhưng lượng hàng tồn kho ở Ginee vẫn là 0, dẫn đến tình trạng bán quá mức.",
    "PleaseCheckWhetherThereAre": "2. Vui lòng kiểm tra xem có bất kỳ đơn hàng nào bị hủy/trả lại/hoàn tiền trong Trung tâm người bán sau khi số lượng hàng tồn kho được cập nhật về 0 hay không",
    "skuxAlreadyExistsInOmsAnd": "SKU{x} đã tồn tại trong OMS và là sản phẩm kết hợp. Sản phẩm kết hợp không được phép xuất/nhập!",
    "oneclickOversoldCheck": "Kiểm tra bán quá mức",
    "massRelease": "Phát hành đại chúng",
    "thisOperationWillReleaseTheReserved": "Hoạt động này sẽ phát hành Kho khuyến mãi dành riêng của tất cả các sản phẩm trong cửa hàng, vui lòng vận hành một cách thận trọng!",
    "imageSizeMustBeGreaterThan": "Kích thước hình ảnh phải lớn hơn chiều cao {x}, chiều rộng {y}",
    "clickToUpdateBasicInformationSuch": "Nhấp để cập nhật thông tin cơ bản như gói, quyền, v.v.",
    "clickRefreshToObtainTheLatest": "Nhấp vào Làm mới để nhận các gói, quyền, yêu cầu ràng buộc người gửi hàng mới nhất và các thông tin khác",
    "bySelectedProducts": "Theo sản phẩm được chọn",
    "byStoreName": "Theo cửa hàng",
    "settingProductDescription": "Cài đặt Sản phẩm chính và Sao chép",
    "paymentReconciliation": "Đối chiếu thanh toán",
    "partialRelease": "Phát hành một phần",
    "released": "Phát hành",
    "toRelease": "Phát hành",
    "marketplaceReleasedAmountForTheOrder": "Số tiền phát hành trên thị trường cho đơn hàng",
    "orderPackageNumber": "Số gói đặt hàng",
    "marketplaceReleasedAmountEstimatedRelease": "Số tiền chênh lệch = Số tiền phát hành trên thị trường - Số tiền phát hành ước tính",
    "afterTheOrderIsCompletedThe": "Sau khi đơn hàng được hoàn thành, thị trường sẽ chuyển thu nhập từ đơn hàng cho người bán",
    "settlementDetails": "Chi tiết thanh toán",
    "amountDifference": "Số tiền chênh lệch",
    "marketplaceReleasedAmount": "Số tiền phát hành trên thị trường",
    "reconciliationStatusProvidedByTheChannel": "Tình trạng đối chiếu do Thị trường cung cấp",
    "feesCharges": "Lệ phí",
    "voucherRebates": "Phiếu quà tặng & Giảm giá",
    "totalExpenses": "Tổng chi phí",
    "estimatedReleaseAmountTotalRevenue": "Số tiền phát hành ước tính = Tổng doanh thu - Tổng chi phí",
    "estimatedReleaseAmount": "Số tiền phát hành ước tính",
    "timeoutPaymentIsNotSettledWithin": "Thời gian chờ: Thanh toán không được giải quyết trong vòng 24 giờ sau khi đơn hàng được hoàn thành",
    "settlementDataIsSyncedOnceA": "Dữ liệu thanh toán được đồng bộ hóa mỗi ngày một lần và một số đơn hàng đã phát hành có thể vẫn chưa được phát hành",
    "xDigitsOfEnglishChineseNumbers": "{x} chữ số tiếng Anh, tiếng Trung, số, dấu cách và - _ & %",
    "pushSuccessnoShippingLabel": "Đẩy thành công(Không có nhãn vận chuyển)",
    "pushSuccesshasShippingLabel": "Đẩy thành công(Có nhãn vận chuyển)",
    "clickTheOkButtonToRecreate": "Nhấp vào nút [OK] để tạo lại Doanh số bán ra dựa trên dữ liệu đơn hàng mới nhất",
    "youSureYouWantToRecreate": "Bạn có chắc chắn muốn tạo lại Sales Outbound không?",
    "atchEditingOfBrandsInMultiple": "chỉnh sửa hàng loạt nhãn hiệu ở nhiều cửa hàng chỉ hỗ trợ chọn \"No Brand\". Nếu bạn cần chọn thương hiệu khác, vui lòng chọn sản phẩm từ cùng một cửa hàng.",
    "editingOfBrandsInMultiple": "chỉnh sửa hàng loạt nhãn hiệu ở nhiều cửa hàng chỉ hỗ trợ chọn \"No Brand\". Nếu bạn cần chọn thương hiệu khác, vui lòng chọn sản phẩm từ cùng một cửa hàng.",
    "masstRemark": "Chỉnh sửa Nhận xét hàng loạt",
    "pushFailedKeeppackOutboundOrderStatus": "Đẩy không thành công, Keeppack không cho phép thao tác này",
    "printingFailedTheOrderIsMissing": "In không thành công, đơn hàng bị thiếu Nhãn vận chuyển PDF",
    "dearCustomer": "Khách hàng thân mến,",
    "yourFollowingResourcesHaveExceededThe": "Các tài nguyên sau của bạn đã vượt quá giới hạn gói, vui lòng điều chỉnh hoặc nâng cấp gói trước khi tiếp tục sử dụng Ginee OMS",
    "selectXStoresToContinueBinding": "Chọn {x} cửa hàng để tiếp tục ràng buộc và phần còn lại sẽ bị Vô hiệu hóa (sau khi vô hiệu hóa, nó sẽ không ảnh hưởng đến doanh số bán hàng của cửa hàng, nhưng Genie OMS sẽ không hiển thị các sản phẩm / đơn đặt hàng và dữ liệu khác của cửa hàng)",
    "selectedXStore": "Đã chọn {x} cửa hàng",
    "staffAccount": "Tài khoản nhân viên",
    "selectXStaffAccountsToContinue": "Chọn {x} tài khoản nhân viên sẽ tiếp tục được sử dụng, phần còn lại sẽ bị xóa (chỉ xóa quyền hệ thống Ginee OMS của tài khoản nhân viên, các hệ thống Ginee khác sẽ không bị ảnh hưởng)",
    "onlySupportsUploadingPdfFilesMax": "Chỉ hỗ trợ tải lên tệp PDF, tối đa. 1 MB",
    "shippingLabelHasBeenUploaded": "Nhãn vận chuyển đã được tải lên",
    "uploadShippingLabel": "Tải lên nhãn vận chuyển",
    "shippingLabelPdf": "Nhãn vận chuyển PDF",
    "cancellationTime": "Thời gian hủy",
    "shippingTime": "Thời gian gửi hàng",
    "inventorySynchronizationSwitchTurnedOff": "Công tắc đồng bộ hóa và giám sát chứng khoán: đã tắt",
    "theFollowingFunctionsWillBeAutomatically": "Các chức năng sau sẽ tự động tắt do gói hết hạn và cần kích hoạt thủ công sau khi gia hạn gói.",
    "afterThePackageExpiresStockSynchronization": "Sau khi gói hết hạn, công tắc đồng bộ hóa kho sẽ tự động tắt.",
    "lazadaPhOrdersOnlySupportPrinting": "Các đơn hàng được chọn bao gồm các đơn hàng từ trang PH và chỉ hỗ trợ in hóa đơn theo mẫu của Ginee",
    "companymerchantVatTinNo": "Công ty/Thương gia VAT TIN No.",
    "afterTheSwitchIsTurnedOn": "Sau khi kích hoạt, trước khi đẩy Danh sách gửi đi vào kho, trước tiên hệ thống sẽ lấy kho mới nhất của kho bên thứ ba đã đồng bộ hóa. Nếu Kho trong kho không đủ, việc đẩy sẽ bị hủy, Kho trong kho OMS sẽ được cập nhật và đơn hàng sẽ được chuyển sang Đơn hàng có vấn đề - Hết hàng;\nSau khi vô hiệu hóa, Outbound List sẽ được đẩy thẳng vào kho khi đáp ứng đủ điều kiện đẩy và hệ thống sẽ không xác minh lượng hàng trong kho của bên thứ ba có đủ hay không",
    "checkStockBeforePushing": "Kiểm tra hàng trước khi đẩy",
    "theStrategyImplementedByTheWarehouse": "Chiến lược áp dụng cho kho, vui lòng xem Mô tả chiến lược để được giải thích",
    "whenTheOrderMeetsThePush": "Lệnh sẽ được đẩy tự động khi đủ điều kiện đẩy, không cần đẩy thủ công",
    "shopeeApiRequiresThatAllVariation": "API Shopify yêu cầu không được phép thay đổi tất cả các tên biến thể khi chỉnh sửa sản phẩm (phải giữ lại ít nhất một tên biến thể ban đầu)",
    "sellerOwnFleet": "Hạm đội riêng của người bán",
    "addedToday": "Khách hàng mới trong ngày",
    "globalProduct": "Sản phẩm toàn cầu",
    "publishedSite": "Trang web đã xuất bản",
    "sellerStock": "Người bán cổ phiếu",
    "globalProductPrice": "Giá sản phẩm toàn cầu",
    "verifyMskuBeforePushingYIs": "Xác minh MSKU trước khi đẩy: {y} đã hết hàng trong kho WMS/Bên thứ ba và quá trình đẩy đã dừng lại",
    "theStockVerificationFailedBeforePushing": "Việc xác minh hàng tồn kho không thành công trước khi đẩy và đơn hàng gửi đi đã bị chặn. Lý do: MSKU:{x} hết hàng trong kho WMS/bên thứ ba.",
    "enablePush": "Có thể đẩy",
    "disablePush": "Tắt tính năng đẩy",
    "changeToDisable": "Thay đổi thành Tắt",
    "changeToEnable": "Thay đổi thành Kích hoạt",
    "productNameAccountNumber": "Tên sản phẩm & số tài khoản",
    "dueToShopeeApiLimitationsProducts": "Do hạn chế của API Shopee, các sản phẩm có hơn 50 biến thể không thể được xuất bản hoặc chỉnh sửa từ Ginee",
    "accurateWh": "Kho chính xác",
    "gineeWarehouse": "Kho Ginee",
    "gineeWarehouseAddress": "Địa chỉ kho Ginee",
    "gineeWarehouseName": "Tên kho Ginee",
    "manageTheMappingRelationshipBetweenChannel": "Quản lý mối quan hệ ánh xạ giữa Channel Warehouse và Ginee Warehouse",
    "youCanSelectTheChannelStore": "Bạn có thể chọn kênh cửa hàng để đẩy hàng tới. Cửa hàng CNSC/3PF chỉ hỗ trợ thiết lập Quy tắc đẩy hàng theo Tài khoản người bán",
    "bindingStatus": "Trạng thái ràng buộc",
    "channelWarehouseStatus": "Trạng thái kho kênh",
    "thePartiallyReleasedListRefersTo": "Danh sách được phát hành một phần đề cập đến các đơn hàng của Lazada có chứa nhiều sản phẩm. Một số sản phẩm đã được phát hành và một số vẫn chưa được phát hành. Dữ liệu sẽ được đặt ở trạng thái này.",
    "theReleasedListDisplaysTheReleased": "Danh sách Đã phát hành hiển thị dữ liệu đã phát hành thu được từ API",
    "customTax": "Thuế tùy chỉnh",
    "promotionFee": "Phí chiến dịch",
    "transactionFee": "Phí giao dịch",
    "highQuality": "Chất lượng cao",
    "commissionF": "Phí hoa hồng",
    "amsCommissionFee": "Phí hoa hồng AMS",
    "reverseShippingFeeForReturnedOrders": "Phí vận chuyển ngược",
    "plShippingFeeDiscount": "Giảm phí vận chuyển từ 3PL",
    "shopeeShippingRebate": "Giảm giá vận chuyển từ Shopee",
    "sellerAbsorbedCoinCashback": "Hoàn tiền bằng xu được tài trợ bởi người bán",
    "productDiscountsAndCashbackByShopee": "Giảm giá do Shop cung cấp",
    "productRefundAmount": "Số tiền hoàn trả",
    "productDiscountedPrice": "Quảng cáo sản phẩm người bán của bạn",
    "productOriginalPrice": "Giá sản phẩm gốc",
    "totalReleaseAmount": "Tổng số tiền phát hành",
    "settlementNo": "Quyết toán số",
    "settlementTime": "Thời gian giải quyết",
    "whetherTimeout": "Cho dù hết thời gian",
    "releasing": "Phát hành",
    "orderItem": "Mục thứ tự",
    "settlementDetailsAllPages": "Chi tiết thanh toán - tất cả",
    "settlementDetailsBySelected": "Chi tiết thanh toán - đã chọn",
    "ordersAllPages": "Đơn hàng - tất cả",
    "ordersBySelected": "Đơn hàng - đã chọn",
    "compatibleModels": "Các mẫu tương thích",
    "noticeTitle": "Chủ đề",
    "promotionStockCannotBeGreaterThan": "Số lượng hàng khuyến mãi không được lớn hơn số lượng hàng có sẵn",
    "pleaseEnterTheTitle": "Vui lòng nhập tiêu đề",
    "theOutboundListHasBeenCompleted": "Danh sách gửi đi đã được hoàn thành tại kho thực hiện và không thể đẩy lại",
    "commissionChargedBasedOnItemUnit": "Hoa hồng được tính dựa trên đơn giá mặt hàng (% tính trên đơn giá - chiết khấu của người bán)",
    "theOrderHasBeenCompletedBut": "Đơn hàng đã được hoàn thành nhưng nền tảng vẫn chưa thanh toán.",
    "paymentFeeForProcessingCharges": "Phí thanh toán phí xử lý (% tính trên Đơn giá + Phí vận chuyển của Người mua - Phiếu quà tặng của Người bán)",
    "listedPriceOfTheItemSold": "Giá niêm yết của mặt hàng được bán trước khi có bất kỳ khoản giảm giá hoặc phí nào cho người mua",
    "productVat": "VAT sản phẩm",
    "buyerRealName": "Tên thật của người mua",
    "salesInvoiceWillUseTheSelected": "Hóa đơn bán hàng sẽ sử dụng thông tin khách hàng cố định đã chọn khi bị tắt. Khi được bật, Hóa đơn bán hàng sẽ sử dụng thông tin khách hàng thực do đơn hàng kênh cung cấp. Xin lưu ý rằng thông tin khách hàng ở một số kênh sẽ bị che đi. Nên tắt tính năng này đối với những cửa hàng che giấu thông tin khách hàng",
    "realCustomerInformationInTheOrder": "Thông tin khách hàng thật trong đơn hàng",
    "excludingVat": "Chưa bao gồm VAT",
    "theSkuIsNotOversoldReason": "SKU không được bán quá mức. Lý do: Vì TikTok Shop sẽ trì hoãn việc phát hành hàng sau khi đơn hàng bị hủy, Ginee cũng sẽ trì hoãn việc phát hành Hàng bị khóa trong 12 giờ sau khi đơn hàng bị hủy được đồng bộ hóa với Ginee (trạng thái sẽ được hiển thị là \"Đang phát hành\"). Số lượng hàng tồn kho bị khóa được phát hành ≥ số lượng hàng tồn kho âm không phải là quá bán.",
    "ifYouNeedToReleaseThe": "Nếu bạn cần giải phóng trước Kho bị khóa với trạng thái \"Đang phát hành\", bạn có thể vô hiệu hóa Danh sách gửi đi tương ứng trong Quản lý gửi đi để buộc giải phóng Kho bị khóa (có nguy cơ bán quá mức, vui lòng vận hành thận trọng)",
    "publishedGlobalProduct": "Sản phẩm toàn cầu đã xuất bản",
    "editingTheGlobalProductPriceWill": "Chỉnh sửa Giá Sản Phẩm Toàn Cầu sẽ cập nhật giá sản phẩm của cửa hàng theo công thức tính",
    "editingTheGlobalProductStockWill": "Chỉnh sửa kho Sản phẩm Toàn cầu sẽ cập nhật kho sản phẩm của cửa hàng",
    "pleaseNotePleaseUseMAuthorization": "Xin lưu ý: Vui lòng sử dụng ủy quyền {m} cho các cửa hàng địa phương nói chung và ủy quyền {u} cho các cửa hàng CNSC/3PF",
    "theSkuIsOutOfStock": "SKU đã hết hàng trong WMS và không thể đẩy được",
    "theSkuDoesNotExistIn": "SKU không tồn tại trong WMS và không thể đẩy được",
    "theOrdersOutboundListHasBeen": "Danh sách gửi đi của đơn hàng đã được hoàn thành trong WMS và không thể đẩy được",
    "wmsIsProcessingTheOrder": "WMS đang xử lý đơn hàng",
    "authorizeMainAccount": "Ủy quyền tài khoản chính",
    "authorizeShopAccount": "Ủy quyền tài khoản cửa hàng",
    "customOrNo": "Tùy chỉnh HOẶC Không.",
    "onlySupportsFilesPdfPngJpeg": "Chỉ hỗ trợ các tệp PDF, png, jpeg, JPG nhỏ hơn 5M",
    "gineeWillAutomaticallyCreateAnInbound": "Ginee sẽ tự động tạo Danh sách nhập vào theo Số lượng nhập vào đầu vào, trực tiếp hoàn thành Danh sách nhập vào và tăng Hàng tồn kho, vui lòng xác nhận!",
    "youWantToAddToThe": "Bạn có chắc chắn muốn thêm vào kho và nhập hàng trực tiếp không?",
    "feeRefundreturn": "Phí hoàn tiền/trả lại",
    "fulfillmentFee": "Phí thực hiện",
    "shippingFeeChargedToSeller": "Phí vận chuyển được tính cho người bán",
    "otherFee": "Phí khác",
    "serviceFee1": "Phí dịch vụ",
    "rebateFromMarketplace": "Giảm giá từ Marketplace",
    "sellerPromotion": "Khuyến mãi của người bán",
    "productDiscountPrice": "Giá giảm giá sản phẩm",
    "pleaseRenewOrPurchaseOrderQuantity": "Vui lòng gia hạn hoặc mua số lượng đơn hàng càng sớm càng tốt, nếu không bạn sẽ không thể sử dụng các chức năng liên quan đến đơn hàng!",
    "yourGineeWmsOrderQuotaHas": "Hạn ngạch đặt hàng Ginee WMS của bạn đã bị vượt quá!",
    "yourGineeOmsOrderQuotaHas": "Hạn ngạch đặt hàng Ginee OMS của bạn đã bị vượt quá!",
    "orderQuotaExceeded": "Đã vượt quá hạn ngạch đặt hàng",
    "insufficientOrderQuota": "Hạn ngạch đặt hàng < 10%",
    "theOrderQuotaOfYourCurrent": "Vui lòng gia hạn hoặc mua tiện ích bổ sung hạn ngạch đơn hàng càng sớm càng tốt, nếu không bạn sẽ không thể sử dụng các chức năng liên quan đến chuyến đi!",
    "bySelectingAllLazadaWillConsider": "Bằng cách chọn TẤT CẢ, Lazada sẽ coi đó là bao gồm tất cả các tùy chọn bên dưới, không cần đánh dấu từng mục con riêng lẻ.",
    "abnormalExpiration": "Hết hạn bất thường",
    "pushOutboundOrders": "Đẩy danh sách đi",
    "pushInboundOrders": "Đẩy danh sách gửi đến",
    "directInbound": "Trực tiếp nhập kho",
    "updatedToBePushed": "(Cập nhật) Sẽ bị đẩy",
    "addressNameAndPhoneNumberWill": "Địa chỉ, Tên và Số điện thoại sẽ được sử dụng cho thông tin người gửi trong Nhãn vận chuyển tùy chỉnh của Ginee",
    "syncChannelAddress": "Địa chỉ kênh đồng bộ",
    "withholdingTax": "Thuế khấu trừ",
    "theCnscpfsChannelWarehouseIsNot": "Kho kênh của CNSC/3PF không bị ràng buộc với Kho Ginee, do đó không thể tạo Danh sách gửi đi",
    "afterSettingTheShippingMethodWhen": "Sau khi cài đặt phương thức vận chuyển, khi sắp xếp vận chuyển tại Ginee, phương thức vận chuyển và địa chỉ nhận hàng sẽ được chọn tự động để nâng cao hiệu quả vận chuyển.",
    "theOutboundOrderIsInvalidIn": "Lệnh gửi đi không hợp lệ trong WMS và không thể đẩy lại được.",
    "progress": "Tiến triển",
    "setShippingInfo": "Đặt thông tin vận chuyển",
    "outboundOrderHasBeenSuccessfullyPushed": "Đơn hàng gửi đi đã được đẩy thành công về kho để xử lý, bạn không thể sửa đổi thông tin đơn hàng gửi đi. Vui lòng thực hiện thủ công việc xuất hàng theo thông tin thay đổi đơn hàng",
    "mStoreAbnormallyExpired": "cửa hàng {m} hết hạn bất thường",
    "oneClickToChangeTheStores": "Một cú nhấp chuột để thay đổi Địa chỉ nhận hàng của cửa hàng",
    "changeAddressByStore": "Thay đổi địa chỉ cửa hàng",
    "youCanQuicklyChangePickupAddress": "Bạn có thể nhanh chóng thay đổi Địa chỉ nhận hàng cho tất cả dịch vụ hậu cần trong một cửa hàng Shopee sang địa chỉ khác",
    "invalidTrackingNumberPleaseGoTo": "Số theo dõi không hợp lệ, vui lòng đến Trung tâm người bán để gửi lại Số theo dõi hợp lệ càng sớm càng tốt!",
    "invalidTrackingNumberawb": "Số theo dõi/AWB không hợp lệ",
    "channelAbnormalOrders": "Đơn đặt hàng bất thường của kênh",
    "tryNoww": "Thử ngay bây giờ",
    "congratulationsYouHaveReceivedADay": "Chúc mừng! Bạn đã nhận được bản dùng thử miễn phí 7 ngày với đầy đủ các tính năng",
    "mskuTotal": "Tổng số lượng MSKU",
    "updateUsageEveryHours": "Cập nhật sử dụng cứ sau 4 giờ",
    "dailyUpdateUsage": "Cập nhật sử dụng hàng ngày",
    "replacementSuccessful": "Thay thế thành công!",
    "theFollowingOrdersNeedToAdd": "Các đơn hàng sau cần bổ sung thêm thông tin vận chuyển trước khi sắp xếp vận chuyển.",
    "toEnsureNormalUse": "Để đảm bảo sử dụng bình thường, vui lòng gia hạn kịp thời",
    "invoiceNumberMustBeDigits": "Số hóa đơn phải có 8 chữ số",
    "companymerchantInformation": "Thông tin về công ty/hàng hóa",
    "orDate": "OR Date",
    "orNo": "OR No",
    "pleaseEnterTheWarehouseCodeNo": "Vui lòng nhập mã kho, không quá 50 ký tự",
    "failedToPrintInvoice": "In hóa đơn không thành công",
    "thatIsOfficialReceiptNumberYou": "Tức là Số Biên nhận Chính thức. Bạn có thể đặt tiền tố tối đa 3 chữ cái (tiền tố có thể để trống) + 8 chữ số (phải là 8 chữ số) để tạo thành OR No. Mỗi lần in đơn hàng sẽ bắt đầu tăng dần theo số đã đặt. Xin lưu ý: Sau mỗi lần sửa đổi, nó sẽ được in theo các quy định mới nhất, vui lòng sửa đổi cẩn thận;",
    "theCompanyInformationSetInGinee": "Thông tin Công ty được thiết lập trong Tài khoản Ginee sẽ được hiển thị",
    "setCompanyInformation": "Đặt thông tin công ty",
    "atTheRequestOfThePhilippine": "Theo yêu cầu của chính phủ Philippines, tất cả hóa đơn của các cửa hàng Philippine đều phải sử dụng mẫu hóa đơn Philippine. Mô-đun có thể chọn có bao gồm Thuế tiêu thụ (VAT) hay không",
    "orderDate": "Ngày đặt hàng",
    "thisReceiptShallBeValidFor": "BIÊN BẢN NÀY CÓ HIỆU LỰC TRONG NĂM (5) NĂM KỂ TỪ NGÀY CẤP GIẤY CHỨNG NHẬN",
    "acknowledgementCertificateNo": "Acknowledgement Certificate No",
    "dateIssued": "Ngày phát hành",
    "seriesRange": "Phạm vi loạt",
    "handleProb": "Xử lý sự cố",
    "checksFailed": "Kiểm tra không thành công!",
    "successfullyDeletedTheWarehouse": "Đã xóa Kho thành công!",
    "startDeletion": "Bắt đầu xóa",
    "checksPassed": "Kiểm tra đã được thông qua!",
    "inventoryClearanceAllProductStocksAre": "Giải phóng hàng tồn kho: Tất cả hàng tồn kho hiện tại là 0",
    "manualInboundListAllDocumentsHave": "Danh sách gửi đến thủ công: Tất cả các tài liệu đã được hoàn thành",
    "purchaseInboundListAllDocumentsHave": "Danh sách mua hàng trong nước: Tất cả các tài liệu đã được hoàn thành",
    "manualOutboundListAllDocumentsHave": "Danh sách gửi đi thủ công: Tất cả các tài liệu đã được hoàn thành",
    "salesOutboundListAllDocumentsHave": "Danh sách bán hàng đi: Tất cả các tài liệu đã được hoàn thành",
    "deletingAWarehouseRequiresCheckingThe": "Việc xóa Kho yêu cầu kiểm tra dữ liệu trong Kho, hoàn thành tất cả Danh sách Nhập/Xuất và xóa tất cả kho SKU trước khi xóa",
    "checkingWarehouseDeletion": "Kiểm tra việc xóa kho",
    "beforeDeletingAWarehouseYouNeed": "Trước khi xóa Kho, bạn cần hoàn thành tất cả Danh sách Nhập/Xuất và xóa tất cả kho SKU",
    "oneAreYouSureYouWantTo": "Bạn có chắc chắn muốn xóa Kho không?",
    "theNumberOfLocalWarehouseHas": "Số lượng Kho địa phương đã đạt đến giới hạn (tối đa 50). Vui lòng xóa kho dư thừa và thử lại!",
    "oneFailedToSaveTheNumberOf": "Lưu thất bại! Số lượng SKU kênh liên kết với Sản phẩm kết hợp đã đạt đến giới hạn (tối đa 500)",
    "failedToSaveTheNumberOf": "Lưu thất bại! Số lượng sản phẩm được thêm vào Sản phẩm kết hợp đã đạt giới hạn (tối đa 10)",
    "failedToSaveTheBoundCombined": "Lưu thất bại! Sản phẩm Kết hợp bị ràng buộc của một sản phẩm {x} đã đạt đến giới hạn (có thể bị ràng buộc ở mức tối đa 100 Sản phẩm Kết hợp)",
    "failedToSaveTheVariationsOf": "Lưu thất bại! Số lượng biến thể của Sản phẩm chính đã đạt đến giới hạn (tối đa 200)",
    "bindingFailedTheChannelSkuBound": "Ràng buộc không thành công! Số lượng SKU kênh được liên kết với MSKU đã đạt đến giới hạn (tối đa 500)",
    "deletingWarehouse": "Đang xóa kho...",
    "deletingWarehouseProducts": "Xóa sản phẩm trong kho...",
    "deletingMIO": "Đang xóa đơn hàng gửi đến thủ công...",
    "deletingPIO": "Đang xóa đơn hàng gửi đến mua hàng...",
    "deletingMOO": "Đang xóa đơn hàng gửi đi thủ công...",
    "deletingSOO": "Xóa đơn hàng xuất đi...",
    "none": "Không có",
    "InboundStockIsOnlyValid": "1. Phiếu nhập kho chỉ có giá trị thêm kho và nhập hàng kho, còn dữ liệu hàng nhập kho sẽ được đưa vào kho hàng. 2. Sản phẩm kết hợp chỉ có thể được thêm vào kho và không hỗ trợ lưu trữ. 3. Các sản phẩm không cần liên kết có thể xóa trực tiếp. 4. Sau khi gửi, nếu sản phẩm đã có trong kho, hệ thống sẽ tự động lọc nên sản phẩm sẽ không được nhập lại.",
    "differentWeightdimensionsForVariations": "Trọng lượng/kích thước khác nhau cho các biến thể",
    "becauseAllVariationsHaveExactlyThe": "Vì tất cả các biến thể đều có trọng lượng và kích thước giống hệt nhau nên hệ thống sẽ tự động giúp bạn chuyển sang trọng lượng và kích thước cấp sản phẩm",
    "mskuXWarehouseNameOrWarehouse": "MSKU {0}, Tên kho hoặc Mã kho không tồn tại",
    "mskuDoNotExist": "MSKU {0} không tồn tại trong kho {1}, vui lòng thêm SKU vào kho trước",
    "masterProductXDoesNotExist": "Sản phẩm chính {x} không tồn tại",
    "theOutboundListHasBeenPushed": "Danh sách gửi đi đã được đẩy lên và đơn hàng không còn được phép chỉnh sửa",
    "orderXStatusDoesNotAllow": "Trạng thái đơn hàng {0} không cho phép chỉnh sửa",
    "orderXDoesNotExist": "Đơn hàng {0} không tồn tại",
    "thePaymentAmountShouldBeLess": "Số tiền thanh toán phải nhỏ hơn Tổng đơn hàng, vui lòng kiểm tra",
    "theTotalAmountOfProductsExceeds": "Tổng số lượng sản phẩm vượt quá giới hạn",
    "theOrderNoIsDuplicatedPlease": "Số đơn hàng bị trùng lặp, vui lòng chỉnh sửa số đơn hàng.",
    "theStoreCannotBeEmpty": "Cửa hàng không thể trống",
    "channelRequirementsXMustBeBetween": "Yêu cầu về kênh {x} phải nằm trong khoảng {y}~{z}",
    "itemCount": "Số mặt hàng",
    "variationOptionLength": "Độ dài tùy chọn biến thể",
    "variationTypeLength": "Độ dài loại biến thể",
    "itemDescriptionLength": "Độ dài mô tả mặt hàng",
    "itemImageCount": "Số lượng hình ảnh mục",
    "itemNameLength": "Độ dài tên mặt hàng",
    "wholesalePriceThresholdPercentage": "Phần trăm ngưỡng giá bán buôn",
    "sinceSomeProductsHaveTheSame": "Vì một số sản phẩm có cùng Trọng lượng và Kích thước cho tất cả các biến thể nên hệ thống sẽ tự động giúp bạn chuyển sang Trọng lượng và Kích thước ở cấp độ sản phẩm",
    "toEnableDifferentWeightdimensionsForEach": "Để bật Trọng lượng/Kích thước khác nhau cho từng biến thể, vui lòng truy cập trang chỉnh sửa duy nhất của sản phẩm Shopee và bật nút \"Trọng lượng/Kích thước khác nhau cho biến thể\"",
    "mskuHasBeenInbounded": "MSKU đã được gửi đến",
    "exportFailed": "Xuất thất bại",
    "staffToRemovePermissions": "Nhân viên xóa quyền:",
    "staffsToRetainPermissions": "Nhân viên giữ quyền:",
    "storesToDeactivate": "Cửa hàng để hủy kích hoạt:",
    "storesToRetain": "Cửa hàng cần giữ lại:",
    "staffAccountsToContinueUsing": "Tài khoản nhân viên để tiếp tục sử dụng:",
    "storesToContinueBinding": "Cửa hàng để tiếp tục đóng sách:",
    "pleaseConfirmTheStoreOrStaff": "Vui lòng xác nhận Cửa hàng hoặc Nhân viên bạn muốn giữ lại",
    "theDefaultPickupTimeLatest": "Thời gian đón mặc định là muộn nhất.",
    "theDefaultPickupTimeEarliest": "Thời gian đón mặc định là sớm nhất.",
    "doNotAutomaticallySelectThePickup": "Không tự động chọn thời gian đón.",
    "setTo": "Đặt thành:",
    "onlyApplicableToOrdersWithPickup": "Chỉ áp dụng cho các đơn hàng có dịch vụ nhận hàng trên Shopee và TikTok.",
    "automaticallySelectThePickupTime": "Tự động chọn thời gian đón.",
    "whenYouSelectLatest": "Khi bạn chọn tùy chọn này, thời gian lấy hàng muộn nhất sẽ được tự động điền vào trang vận chuyển.",
    "whenYouSelectEarliest": "Khi bạn chọn tùy chọn này, thời gian lấy hàng sớm nhất có thể sẽ được tự động điền vào trang vận chuyển.",
    "thisOrderIsFromAMultiwarehouse": "Đơn hàng này đến từ một cửa hàng có nhiều kho. Việc thay đổi địa chỉ có thể phải chịu thêm chi phí vận chuyển. Bạn có chắc chắn muốn cập nhật nó không?",
    "noManualSyncTask": "Không có tác vụ đồng bộ hóa thủ công",
    "taskIds": "ID nhiệm vụ",
    "manualSyncProduct": "Sản phẩm đồng bộ thủ công",
    "manualSyncOrder": "Thứ tự đồng bộ hóa thủ công",
    "syncHistoricalOrders": "Đồng bộ hóa các đơn đặt hàng lịch sử",
    "sinceTiktokShopWillDelayReleasing": "Vì TikTok Shop sẽ trì hoãn việc phát hành hàng sau khi đơn hàng bị hủy, Ginee cũng sẽ trì hoãn việc phát hành Hàng bị khóa trong 12 giờ sau khi đơn hàng bị hủy được đồng bộ hóa với Ginee (trạng thái sẽ được hiển thị là \"Đang phát hành\").",
    "syncProgress": "Tiến triển",
    "notAbnormal": "Không bất thường",
    "reservationFailedTheBoundGineeWarehouse": "Đặt hàng không thành công, không tìm thấy kho Ginee liên kết (Shopee CNSC/3PF các cửa hàng giữ lại Hàng khuyến mãi theo mối quan hệ liên kết giữa kho cửa hàng và kho Ginee)",
    "discountType": "Loại giảm giá",
    "percentageOff": "Giảm phần trăm",
    "fixedPrice": "Giá cố định",
    "variationlevel": "Cấp biến thể",
    "productlevel": "Cấp sản phẩm",
    "youWantToSwitchTheDatabase": "Bạn có chắc chắn muốn chuyển cơ sở dữ liệu sang {x} không?",
    "allSettingsAndMappingsWillBe": "Tất cả các cài đặt và ánh xạ sẽ bị xóa và MSKU sẽ cần được khớp lại",
    "changeDatabase": "Chuyển cơ sở dữ liệu",
    "pleaseSelectTheDatabaseToChange": "Vui lòng chọn cơ sở dữ liệu để chuyển sang",
    "ifYouSwitchToANew": "Nếu bạn chuyển sang cơ sở dữ liệu mới, tất cả các cài đặt và ánh xạ sẽ cần được định cấu hình lại. Nhưng đừng lo lắng, dữ liệu đã được đẩy đến Accurate sẽ không bị xóa.",
    "mappingS": "Ánh xạ",
    "channelStoreName": "Tên cửa hàng kênh",
    "customersRealName": "Tên thật của khách hàng",
    "productTaxSettings": "Cài đặt thuế sản phẩm",
    "whetherTheProductWillIncludeVat": "Sản phẩm có bao gồm VAT trên hóa đơn bán hàng không",
    "noVat": "Không có VAT",
    "addVat": "Thêm VAT",
    "gineeWarehouseCanOnlyBeMapped": "Kho Ginee chỉ có thể được ánh xạ với 1 kho Accurate, vui lòng hoàn thành ít nhất 1 ánh xạ kho",
    "dataLinkedWithBranchesBanksAnd": "Dữ liệu được liên kết với các chi nhánh, ngân hàng và COA khi giao dịch đơn hàng được gửi đến Accurate",
    "shippingFeeChargedToSellersCalculated": "Phí vận chuyển tính cho người bán được tính dựa trên Chi tiết thanh toán của thị trường",
    "otherDeductedFeesProvidedByThe": "Các loại phí khấu trừ khác theo quy định tại Chi tiết thanh toán của thị trường",
    "rebateAndDiscountAmountAsProvided": "Số tiền hoàn lại và chiết khấu theo quy định tại Chi tiết thanh toán của thị trường",
    "sellerPromotionFeeAsProvided": "Phí khuyến mại của người bán theo quy định tại Chi tiết thanh toán của thị trường",
    "serviceFeeCommissionFeeAndOther": "Phí dịch vụ, Phí hoa hồng và các loại Phí khác do thị trường khấu trừ theo quy định tại Chi tiết thanh toán của thị trường",
    "importantNotesErrorNotificationTheCumulative": "Lưu ý quan trọng: Thông báo lỗi \"Thời hạn đặt hàng tích lũy không được vượt quá 18 tháng\" khi mua Lazada-Authorization-only có nghĩa là bạn đã mua 18 tháng Authorization-only. Vui lòng {x}, sau đó nhấp vào Sử dụng dịch vụ để hoàn tất tích hợp",
    "historicalOrdersSettlementDataWillNot": "Dữ liệu thanh toán của các đơn hàng lịch sử sẽ không được đồng bộ hóa",
    "variousServiceCharges": "Các loại phí dịch vụ khác nhau",
    "thePromotionPeriodMustBeLonger": "Thời gian khuyến mại phải dài hơn 10 phút và ngắn hơn 365 ngày",
    "purchaseLimitCannotBeGreaterThan": "Giới hạn mua hàng không được lớn hơn Hàng có sẵn",
    "fulfilledByChannel": "Hoàn tất bởi Kênh",
    "jitPo": "JIT PO",
    "advanceFulfillment": "Hoàn tất trước",
    "notSpecial": "Không đặc biệt",
    "createdByShopee": "Tạo bởi Shopee",
    "matchPending": "Đang chờ khớp",
    "discountPriceIsLowerThanM": "Giá chiết khấu thấp hơn {m}",
    "theDiscountRateMustNotbeHigher": "Tỷ lệ chiết khấu không được cao hơn {m}%",
    "limitationMethod": "Phương pháp giới hạn",
    "variationDimension": "Kích thước biến thể",
    "noLimit": "Không giới hạn",
    "invalidDiscount": "chiết khấu không hợp lệ",
    "invalidPrice": "giá không hợp lệ",
    "TheStartTimeMustBe": "1. Thời gian bắt đầu phải muộn hơn thời gian hiện tại",
    "ThePromotionPeriodMustBe": "2. Thời gian khuyến mại phải dài hơn 10 phút và ngắn hơn 365 ngày",
    "productId": "Mã sản phẩm",
    "variationId": "Mã biến thể",
    "thePromotionsPeriodMustBeLonger": "Thời gian khuyến mại phải dài hơn 1 giờ",
    "thePromotionuPeriodMustBeLonger": "Thời gian khuyến mại phải dài hơn 10 phút và ngắn hơn 365 ngày",
    "unavailableStockIntransit": "Hàng không có sẵn (Đang vận chuyển)",
    "stockTransferredToSortingCenterBy": "Hàng đã được Shopee chuyển đến trung tâm phân loại trước",
    "advanceStock": "Advance Stock",
    "outboundQuantityMustBe": "Số lượng hàng gửi đi phải>0",
    "needHelpWereHereForYou": "Need help? We're here for you. Contact us M-F, 9AM-6PM",
    "orderXIsMatchedWithShopee": "Đơn hàng [{0}] khớp với Đơn hàng hoàn tất trước của Shopee [{1}], các đơn hàng được hợp nhất",
    "bookingId": "Mã đặt chỗ",
    "availStock": "Cổ phiếu có sẵn",
    "notMatchedToOrder": "Không khớp với Đơn hàng",
    "importToCreateCombinedProduct": "Nhập để tạo Sản phẩm kết hợp",
    "youCanImportUpTo": "Bạn có thể nhập tối đa 10.000 dữ liệu cùng một lúc (định dạng: xlsx, xls) để tạo hàng loạt Sản phẩm kết hợp",
    "matched": "Đã khớp",
    "marketplaceSpecialOrder": "Đơn hàng đặc biệt của Marketplace"
};
});

var zh = createCommonjsModule(function (module, exports) {
exports.__esModule = true;
exports.zh = void 0;
exports.zh = {
    "globalNoData": "无数据",
    "globalPleaseSelect": "请选择",
    "globalPleaseEnter": "请输入",
    "globalsYearly": "近1年",
    "globalsMonthly": "近1个月",
    "globalsWeekly": "近7天",
    "globalsYesterday": "昨天",
    "globalsExportDisabled": "已失效",
    "globalsExportFailed": "下载失败",
    "globalsExportFinished": "有效",
    "globalsExportINProcess": "进行中",
    "globalsExportStatus": "状态",
    "globalsPackageExpired": "您的套餐已过期",
    "globalsLater": "稍后",
    "globalsContactNow": "马上联系",
    "globalsContactUsPackage": "请联系我们获取套餐信息和报价",
    "isRequired": "必填项",
    "globalsGoContinue": "继续前往",
    "globalsBrowserBlockedInfo": "检测到新窗口已被浏览器拦截，请点击“继续前往”打开新页面窗口（您还能在帮助中心搜索“解除浏览器拦截方法” 来解除拦截）",
    "globalsNotification": "提示",
    "globalsPleaseSelect": "请选择",
    "globalsIsRequired": "必填项",
    "globalsOperationGuide": "操作引导",
    "globalsSyncError": "同步失败",
    "globalsSynchronizing": "同步中",
    "globalsSync": "同步",
    "globalsSyncSuccess": "同步完成",
    "globalsNoProductYet": "未添加商品",
    "globalsSeeMore": "查看{ more }个以上的变化",
    "globalsSeeMoreRetract": "收起",
    "globalsSeeMoreExpand": "查看更多",
    "globalsNoName": "名称为空",
    "globalsTotalPage": "总共 { total } 条数据",
    "globalsReset": "重置",
    "globalsSearch": "搜索",
    "globalNoEmptyText": "暂无数据",
    "globalNoPermission": "您的账户没有此权限，如有问题请联系店铺高级管理员升级；",
    "globalAction": "操作",
    "globalTaskResult": "结果",
    "globalTaskFailedNumber": "失败",
    "globalTaskTotalNumber": "全部",
    "globalTaskSuccessNumber": "成功",
    "globalTaskFailed": "处理失败，请稍后重试",
    "globalTaskSuccess": "操作成功",
    "globalTaskWait": "处理中，请稍候",
    "globalStatus_unpublished": "未发布",
    "globalStatus_update_failed": "发布失败",
    "globalStatus_delete": "已删除",
    "globalStatus_draft": "草稿",
    "globalStatus_disabled": "已下架",
    "globalStatus_banned": "已禁卖",
    "globalStatus_sold_out": "已售完",
    "globalStatus_live": "已上架",
    "globalStatus_process": "发布中",
    "globalStatus_all": "全部",
    "globalName": "Global Name ZH",
    "goToLinkStoreTip": "无授权店铺，请先去授权店铺后再添加商品",
    "goToLinkStore": "前往授权店铺",
    "remove": "移除",
    "distributionSettings": "配货设置",
    "menuThirdPartyWarehouseProductList": "三方仓商品列表",
    "serviceLink": "Ginee Support 印尼用户交流群",
    "thirdPartyWarehouseStocktakingList": "三方仓库存拉取",
    "explore": "大数据选品",
    "authPlatform": "授权",
    "yilianFulfillment": "壹链履约服务",
    "syncdiconnected": "店铺断连",
    "syncsuccess": "同步完成",
    "syncing": "同步中",
    "commissionSetting": "佣金规则",
    "LAInvoiceSettingsTab": "发票规则",
    "ExportTemplateTab": "导出模板",
    "documentTemplatSeettings": "单据模板",
    "selectfulfillment": "请选择履约服务商",
    "haventBound": "您还没有绑定履约服务商，现在去绑定？",
    "newRule": "新增规则",
    "menuProductTiki": "商品列表 / Tiki",
    "logout": "退出登录",
    "productTools": "商品工具",
    "menuCustomer": "客户",
    "menuPromotion": "促销",
    "menuWarehouse": "仓库",
    "GineeChat": "聊天",
    "menuGineeCapital": "融资",
    "addIntegration": "添加授权",
    "thirdpartyWarehouseList": "三方仓库列表",
    "menuIntegration": "授权",
    "logisticsReport": "物流报表",
    "customerReport": "客户报表",
    "inventoryReport": "库存报表",
    "salesReport": "销售报表",
    "blacklist": "黑名单",
    "customerList": "客户列表",
    "discountList": "折扣列表",
    "discountManagement": "折扣管理",
    "transfer": "调拨",
    "stoktaking": "库存盘点",
    "menuInbound": "入库单管理",
    "menuOutbound": "出库单管理",
    "menuProductLocation": "货架位管理",
    "warehouseList": "仓库列表",
    "stockPullRecord": "三方仓库存拉取记录",
    "safetyStockManagement": "安全库存管理",
    "stock": "库存",
    "storeMoving": "店铺搬家",
    "productScrape": "商品采集",
    "channelProduct": "渠道商品",
    "masterProductList": "主商品列表",
    "orderProcess": "订单处理",
    "allOrders": "全部订单",
    "MenuOrders": "订单",
    "putAside": "搁置",
    "autoBump": "自动置顶",
    "oneStoresProductsToAnyStores": "克隆某店铺产品到其他店铺以快速开店",
    "blibliProductList": "Blibli 商品列表",
    "FulfillSetting": "配置管理",
    "MassAdd": "模板批量创建",
    "PromotionEditTpl": "编辑模板",
    "PromotionAddTpl": "新增模板",
    "PromotionTpl": "折扣模板",
    "IntegrationsUpdateStore": "更新店铺信息",
    "PrintPickingListTemplate": "拣货单模板",
    "PrintInvoiceTemplate": "发票模板",
    "PrintPackingListTemplate": "装箱单模板",
    "PrintLabelTemplate": "快递单模板",
    "addBundleISKU": "添加捆绑ISKU",
    "editBundleISKU": "编辑捆绑ISKU",
    "menuInboundManagementSubmit": "入库单管理 / 入库单入库",
    "menuInboundManagementDetail": "入库单管理 / 入库单详情",
    "menuInboundManagementEdit": "入库单管理 / 编辑入库单",
    "menuInboundManagementAdd": "入库单管理 / 创建入库单",
    "menuOutboundManagementSubmit": "出库单管理 / 出库单出库",
    "menuOutboundManagementDetail": "出库单管理 / 出库单详情",
    "menuOutboundManagementEdit": "出库单管理 / 编辑出库单",
    "menuOutboundManagementAdd": "出库单管理 / 创建出库单",
    "safetyStockSettings": "安全库存设置",
    "storeStockChangeHistory": "店铺推送记录",
    "stockPullRecordDetail": "库存拉取记录详情",
    "thirdpartyWarehouseInventoryPullRecord": "三方仓拉取记录",
    "stockInOutRecord": "出入库记录",
    "editWarehouseStock": "编辑仓库库存",
    "editLocalISKU": "编辑主SKU",
    "editISKU": "编辑库存SKU",
    "binPsku": "绑定的平台SKU",
    "addISKU": "添加库存SKU",
    "EditOrderTpl": "编辑模板",
    "AddOrderTpl": "新增模板",
    "CRMHistory": "历史",
    "CRMSales": "销售管理",
    "CRMTelemarketing": "电销管理",
    "SubscribeNow": "立即订阅",
    "RenewNow": "立即续费",
    "PaymentSubscriptionPrice": "套餐&价格",
    "PaymentSubscriptionResult": "支付结果",
    "AccountPackageSetting": "账户&当前套餐",
    "PackageSubscription": "订阅",
    "TransactionHistory": "交易记录",
    "menuScrapingSettings": "采集设置",
    "ExclusiveSubscription": "热门关注",
    "TopPopularStore": "热门店铺",
    "AccountManagement": "套餐管理",
    "OrderBatchLabel": "批量打印",
    "GineeChartRoom1": "多渠道聊天",
    "InvoicePrintTemplateSettings": "发票打印模板设置",
    "PackingListPrintTemplateSettings": "装箱单打印模板设置",
    "ShippingLabelTemplateSettings": "快递单打印模板设置",
    "SenderInformationSettings": "寄件信息",
    "ShippingRuleSettings": "发货方式",
    "GINEEFulfillment": "Ginee 履约服务",
    "YUEHAIFulfillment": "YUEHAI 履约服务",
    "FASTOCKFulfillment": "FASTOCK 履约服务",
    "JDFulfillment": "JD 履约服务",
    "OrderSettings": "订单设置",
    "CollectEdit": "认领到主商品",
    "CollectSetting": "采集设置",
    "ScrapeList": "采集列表",
    "ReportChart": "报表",
    "ProductsCollection": "一键采集",
    "editrole": "编辑角色",
    "addrole": "添加角色",
    "RoleSetting": "角色管理",
    "StaffSetting": "员工管理",
    "addstaff": "添加员工",
    "staffManagementAdd": "员工管理/添加员工",
    "staffManagementEdit": "员工管理/编辑员工",
    "editstaff": "编辑员工",
    "subscribe": "订阅",
    "days": "天",
    "freetrial": "免费试用",
    "contactus": "联系我们",
    "EditCustomer": "编辑客户",
    "PromotionManagement": "促销管理",
    "mProductSettings": "主商品设置",
    "productSettings": "商品设置",
    "CustomerDetail": "客户详情",
    "NewCustomer": "新增客户",
    "GroupManagement": "客户分组",
    "CustomerManagement": "客户管理",
    "MasterProductSettings": "主商品设置",
    "StockSetting": "库存设置",
    "menuAddProductToWarehouseEditStock": "填写库存",
    "menuAddProductToWarehouse": "添加商品到仓库",
    "stockManageList": "库存清单",
    "stockManage": "库存管理",
    "BarChart": "行业数据",
    "Masseditcategories": "批量修改类目或属性",
    "MassEdit": "批量编辑",
    "ShippingSetting": "物流设置",
    "BasicInformation": "基本信息",
    "BasicSetting": "基本设置",
    "ToolTipInfo": "范围：已上架、已下架、已售完、已禁卖",
    "Result": "结果",
    "PublishWait": "渠道处理上架中，请稍候",
    "UpdateFailed": "更新失败",
    "Publishing": "个产品可克隆",
    "TryAgain": "再试一次",
    "SomeStoresCopyFailed": "部分店铺克隆失败",
    "CopySuccess": "克隆成功",
    "ViewNow": "马上查看",
    "Close": "关闭",
    "NoCopyStore": "当前渠道没有可以克隆到的店铺，快去绑定新店铺吧",
    "CopyPublish": "直接上架",
    "CopyAsDraft": "克隆为草稿",
    "CopyCount": "个产品可克隆",
    "Live": "在售产品",
    "All": "全部产品",
    "Product": "产品",
    "Store": "店铺",
    "Channel": "渠道",
    "CopyTo": "克隆到",
    "CopyFrom": "克隆来源渠道",
    "CopyAlertInfo": "克隆某店铺产品到同一渠道的其他店铺以快速开店",
    "OperationGuide": "操作指导",
    "CopyStore": "克隆店铺",
    "AddStore": "新增店铺",
    "Copy": "克隆",
    "StoreList": "店铺列表",
    "MyStores": "店铺克隆",
    "customerCenter": "嗨，您需要什么帮助？每天9:00-18:00客服为您服务",
    "addStore": "添加店铺",
    "helpInfo": "帮助中心",
    "addSyncStore": "增加关联店铺",
    "lastSyncTime": "上次同步时间",
    "alreadyStore": "已关联的店铺",
    "menuProductLocalUpdateAutoBind": "主商品 / 更新自动绑定规则",
    "menuInboundManagement": "入库管理",
    "menuOutboundManagement": "出库管理",
    "menuLowStockReport": "滞销统计",
    "menuLogisticsCostStatistics": "运费明细表",
    "menuOutStockForecast": "缺货预测表",
    "menuInventoryStatistics": "库存统计表",
    "menuLogisticsStatistics": "物流统计表",
    "menuCustomerAreaAnalysis": "客户地区分析表",
    "menuCustomerAnalysis": "客户分析表",
    "menuCustomerStatistics": "客户统计表",
    "menuStoreSaleReport": "店铺销售统计",
    "menuPlatformSaleReport": "平台销售统计",
    "menuIncomeStatementReport": "利润统计",
    "menuCategorySaleReport": "商品规格销售统计",
    "menuProductSaleReport": "商品销售统计",
    "menuVariantsSalesStatistics": "商品规格销售统计",
    "menuOrderReport": "订单统计",
    "menuSetting": "设置",
    "ShippingOrderNew": "订单发货",
    "status_ready_to_ship": "待发货",
    "printOrderPending": "待打单",
    "beCollectedTo": "待揽收",
    "orderAbnormal": "异常订单",
    "PendingOrder": "待处理订单",
    "pending": "待处理",
    "operationRecord": "操作日志",
    "historyOperation": "发货仓操作日志",
    "shipped": "已发货",
    "stored": "已搁置",
    "warehouseSettingShipping": "分仓规则",
    "orderDetails": "订单详情",
    "manualOrder": "手工订单",
    "menuOrder": "订单列表",
    "menuIntegrations": "关联店铺",
    "menuProductLocationManagement": "货架管理",
    "menuProductLocationManagementOld": "货位管理",
    "menuWarehouseStockDetails": "仓库库存明细",
    "menuStockPushRules": "库存推送规则",
    "menuStoreShippingWarehouseSettings": "店铺发货仓设置",
    "menuWarehouseManagement": "仓库管理",
    "menuPriceManagement": "价格管理",
    "menuProductLocalImportToCreate": "主商品 / 导入创建商品",
    "menuProductLocalImportToBind": "主商品 / 导入绑定商品",
    "menuProductLocalEditBundle": "主商品 / 编辑捆绑商品",
    "menuProductLocalEdit": "主商品 / 编辑商品",
    "menuProductLocalAddBundle": "主商品 / 添加捆绑商品",
    "menuProductLocalEditCombined": "主商品 / 编辑组合商品",
    "menuProductLocalAddCombined": "主商品 / 添加组合商品",
    "menuProductLocalAdd": "主商品 / 添加商品",
    "menuProductWooCommerce": "商品列表 / WooCommerce",
    "menuBindHistory": "绑定记录",
    "menuProductLocal": "主商品",
    "menuProduct": "商品列表",
    "menuPoints": "积分规则",
    "menuMemberShipPage": "会员页面",
    "InventoryLog": "库存变更记录",
    "menuMemberList": "会员列表",
    "noticeList": "通知",
    "menuHomePage": "首页",
    "productmassEditMaster": "主商品/批量编辑",
    "productPublishToTheStoreMaster": "主商品/发布到店铺",
    "resultsPublish": "发布结果",
    "operationHistoryMass": "批量操作记录",
    "menuCapital": "资金",
    "menuProductTikTok": "商品列表 / TikTok",
    "menuProductTikTokEdit": "商品列表 / TikTok/ 编辑商品",
    "menuProductTikTokAdd": "商品列表 / TikTok / 添加商品",
    "menuProductZalora": "商品列表 / Zalora",
    "menuProductShopee": "商品列表 / Shopee",
    "ImportAdd": "导入批量创建",
    "menuProductBlibliEdit": "商品列表 / Blibli/ 编辑商品",
    "menuProductShopeeEdit": "商品列表 / Shopee/ 编辑商品",
    "menuProductBlibliAdd": "商品列表 / Blibli/ 添加商品",
    "menuProductShopeeAdd": "商品列表 / Shopee / 添加商品",
    "publishToStoreTip": "主商品/发布到店铺",
    "changePassword": "修改密码",
    "passworderror": "密码由至少8位英文、数字和特殊字符组成",
    "pwdErrorInfo": "密码必须至少为8-20个字符",
    "UpdateSuccess": "更新成功",
    "storeDiagnose": "店铺诊断",
    "shopeeAutoBump": "Shopee自动置顶",
    "menuscrapeList": "采集列表",
    "chromeExtension": "Chrome Extension",
    "getFreeDataTool": "获取免费数据工具",
    "bigdata": "大数据选品",
    "free": "免费",
    "DataExtension": "数据采集插件",
    "confirm": "确认",
    "noIndonesianLineGroup": "暂无印尼语Line群组",
    "noEnglishLineGroupTemporarily": "暂无英文Line群组",
    "noThaiGroupsForWhatsapp": "暂无Whatsapp泰语群组",
    "noVietnameseLineGroup": "暂无越南语Line群组",
    "noVietnameseWhatsappGroups": "暂无越南语Whatsapp群组",
    "noChineseWhatsappGroupTemporarily": "暂无中文Whatsapp群组",
    "noChineseLineGroupTemporarily": "暂无中文Line群组",
    "storeLimitTip": "您订阅的套餐已过期，若要使用免费套餐，请先选择2个继续绑定的店铺（免费套餐最多可绑定2个店铺，其余店铺会被解除绑定）。",
    "max2Store": "免费套餐最多可绑定2个店铺",
    "publishToTheStore": "发布到店铺",
    "createMasterProduct": "创建主商品",
    "confirmedNewPassword": "确认的新密码",
    "newPassword": "新密码",
    "oldPassword": "旧密码",
    "detail": "详情",
    "editProduct": "编辑商品",
    "orderXxxFailedToPushWarehouse": "订单xxx推送仓库失败，原因：xxx",
    "orderXxxPushedToWarehouseSuccessfully": "订单xxx推送仓库成功",
    "shippingToWarehouseOperationSucceeded": "下发仓库操作成功",
    "shippingToWarehouseOperationIsSuccessful": "下发仓库操作成功，请耐心等待仓库处理结果",
    "currentlyTiktokOrdersAreNotSupported": "暂不支持Tiktok订单通过OpenAPI操作发货与打单",
    "shopeeOrdersWithTheSameLogistics": "Shopee/TikTok订单支持对同一物流批量设置取件时间",
    "gineeSupportsSettingUpAutomaticShipping": "Ginee 支持为 Shopee/Bukalapak/Tokopedia/Akulaku/TikTok 店铺设置自动发货方式",
    "xxxStockDeductionProcessingEnableddisabled": "xxx店铺库存处理启用/禁用成功",
    "theEffectiveTimeOfXxx": "xxx店铺库存处理生效时间由xxx修改为xxx",
    "pleaseOpenAtLeastOneStore": "请至少开启一个店铺",
    "thereIsAnUnfinishedOrderExport": "存在未完成的订单导出任务，请至导出历史中查看导出记录，待任务完成再次操作导出",
    "theSystemServiceIsAbnormalPlease": "系统服务异常，请刷新页面重试",
    "afterTurnOffTheOrderWill": "关闭后，订单将按照分仓规则优先级分配仓库",
    "pleaseConfirmWhetherWantToPush": "请确认是否推送三方仓发货？",
    "pleaseConfirmWhetherWantToRedistribute": "请确认是否重新配货？",
    "outboundOrder": "出库单号",
    "splitOrder": "拆单",
    "abnormalDescription": "异常描述",
    "problemOrder": "问题订单",
    "redistribute": "重新配货",
    "deliveryWarehouse": "下发仓库",
    "nonindonesiaOrdersDoNotSupportXxx": "非印尼订单不支持xxx仓库发货，请调整发货仓库",
    "theOrderDoesNotSupportDelivery": "订单不支持xxx仓库发货，请调整发货仓库",
    "theAvailableStockOfMskuXxx": "MSKU xxx可用库存不足，请补足库存后重新进行配货",
    "mskuXxxIsNotAssociatedWith": "MSKU xxx未关联xxx仓库，请添加关联关系后重新进行配货",
    "channelSkuXxxIsNotBound": "渠道SKU xxx未绑定MSKU，请添加映射关系后重新进行配货",
    "channelSkuXxxDoesNotHave": "渠道SKU xxx未分配仓库，请添加分仓规则后重新进行配货",
    "failedCreateOutboundOrder": "出库单创建失败",
    "warehouseAllocationError": "仓库分配错误",
    "mskuNotAssociatedWithWarehouse": "MSKU未关联仓库",
    "mskuNotMatched": "未匹配MSKU",
    "unallocatedWarehouse": "未分配仓库",
    "pushTheThirdPartyWarehouse": "推送三方仓",
    "paidOrder": "付款",
    "waitingPaymentOrder": "订购",
    "alreadyInbound": "已入库",
    "pleaseConfirmWhetherItIsAutomatically": "请确认是否自动退货入库？",
    "inboundReturnProduct": "退货入库",
    "abnormalType": "异常类型",
    "printTime": "打印时间",
    "warehouseoutboundOrder": "仓库/出库单号",
    "variantsku": "规格/SKU",
    "afterOpeningTheOrderWillBe": "开启后，订单满足发货条件将自动推送三方仓",
    "automaticallyPushToThirdpartyWarehouse": "自动推送三方仓",
    "inventoryVerificationAfterItIsTurned": "库存校验：开启后将校验仓库可用库存是否足够，优先分配库存充足的仓库发货",
    "availableStockDeliveryDisabledSuccessfully": "关闭有货先发成功",
    "availableStockDeliveryActivatedSuccessfully": "开启有货先发成功",
    "shippingWarehouseSettingsDisabledSuccessfully": "关闭分仓设置成功",
    "shippingWarehouseSettingsActivatedSuccessfully": "开启分仓设置成功",
    "orderstockDeductionProcessDisabledSuccessfully": "订单关联库存处理关闭成功",
    "theStockDeductionProcessingOfXxx": "xxx店铺库存处理启用/禁用成功，生效时间由xxx修改为xxx",
    "orderstockDeductionProcessSuccessfullyActivated": "订单关联库存处理开启成功",
    "afterActivatedOrdersWillBeAssigned": "开启后，订单将按不同商品分别匹配仓库，同一订单可能分配至不同仓库发货；否则订单将分配至同一仓库发货",
    "shipmentWarehouseSettingsDeterminesWhetherWarehouse": "分仓设置：决定仓库匹配是订单维度还是商品维度",
    "effectiveTime": "生效时间",
    "activateStatus": "开启状态",
    "activateRange": "开启范围",
    "onceTurnOffAllStoreOrders": "关闭后，所有店铺的订单都将不进行库存处理",
    "afterActivatedTheOrderWillGenerate": "开启后，订单将按处理流程生成出库单扣减库存；否则订单发货不进行库存处理",
    "orderstockDeductionProcess": "订单关联库存处理",
    "orderCreatedSuccessfully": "手工单创建成功",
    "shipmentWarning": "发货警示语",
    "withoutUnboxingVideosComplaintsOfLessdamage": "如果没有拆箱视频，将不接受对少发/损坏物品的投诉",
    "shippingRebate": "运费补贴",
    "shippingFeePaidByBuyer": "买家支付运费",
    "operationIsTooFrequentPleaseExport": "操作过于频繁，请在1分钟后再次导出",
    "rdPartyLogisticsShippingFee": "三方物流费用",
    "shippingSubtotal": "运费总计",
    "importFailedInternalSystemErrorPlease": "导入失败：系统内部错误，请重新导入",
    "emailFormatIsIncorrect": "邮箱格式不正确",
    "supportsNumberslettersMaxCharacters": "仅支持输入数字，字母，\"-\". 不超过30位字符",
    "supportsNumberslettersspacesMaxCharacters": "仅支持输入数字/字母/空格/-，不超过20个字符",
    "basedOnProductSeparateRowFor": "按商品导出（多商品订单分行单独展示）",
    "basedOnOrderOrderWithMultiple": "按订单导出（多商品订单合并展示）",
    "exportMethod": "导出方式",
    "pleaseConfirmWhetherTheOrderStatus": "请确认是否设置订单状态为已退货？",
    "editOrder": "修改订单",
    "insufficientStockOfXxxPleaseMake": "xxx库存不足，请补足库存后发货",
    "paymentProblem": "支付问题",
    "invalidOrder": "无效订单",
    "moveToReturned": "移入已退货",
    "selectCustomer": "选择客户",
    "CustomerMobile": "手机号",
    "fyiPleaseDeleteWhenUploading": "仅供参考，请上传时删除",
    "pleaseDeleteThisTextBoxBefore": "导入前请删除此文本框",
    "fillInWithTwocharacterShortCode": "国家填写二字简码，支付方式填写预付款/支付方式，支付金额不得大于订单总金额",
    "multipleMskusunitPricesquantitiesInTheSame": "同一订单内多个MSKU/单价/数量换行输入，单价不得小于0，数量需大于0； 多行输入时订单其余字段信息可不填",
    "orderNumberOnlySupportsLettersNumbers": "订单号仅支持字母、数字、-/",
    "fieldWithMarkAreRequired": "带*号的字段为必填项",
    "pleaseEditInAccordanceWithThe": "请严格按照模板顺序编辑",
    "importantNotice": "重要说明",
    "theNumberOfDataRecordsCannot": "数据记录不可超过5000条",
    "pleaseImportWithDownloadedFormatTemplate": "请按照下载模板的格式导入，表头不可修改",
    "onlySupportFilesInXlsxFormat": "仅支持导入格式为xlsx的文件(手动修改文件后缀无效)",
    "downloadImportTemplate": "下载导入模板",
    "importOrder": "导入订单",
    "uploadPaymentProof": "上传支付凭证",
    "paymentProof": "支付凭证",
    "receivingAccount": "收款账号",
    "paymentAccount": "支付账号",
    "receiptNumber": "收款单编号",
    "theTotalPaymentAmountCannotExceed": "总支付金额不得超过订单总金额",
    "addPaymentRecord": "添加支付记录",
    "refer": "引用",
    "useCommaToSeparateMultipleItem": "多个以逗号分隔",
    "saveContinueAdd": "保存并继续添加",
    "save": "保存",
    "paymentSerialNumber": "支付流水号",
    "paymentAmount": "支付金额",
    "productTotalAmount": "商品总金额",
    "totalQuantity": "总数量",
    "totalPrice": "总价",
    "addProduct": "新增商品",
    "recipientArea": "收件人地区",
    "remarks": "备注信息",
    "paymentInfo": "支付信息",
    "amountInfo": "金额信息",
    "productInfo": "商品信息",
    "receiptInfo": "收货信息",
    "basicInfo": "基本信息",
    "referenceOrder": "引用订单",
    "canBeEnteredQuicklyByImporting": "可通过引入已有的信息快速录入",
    "addOrder": "新增订单",
    "bindStoreNow": "去绑定店铺",
    "bindTips": "因您暂未绑定渠道店铺，当前订单列表为空，请先绑定店铺后查看或操作订单",
    "PlatformDiscount": "平台折扣",
    "TotalDiscount": "折扣总额",
    "voidTipss": "移入已搁置的订单不再支持在系统内进行任何订单操作",
    "voidTips": "一旦订单移入已搁置，将不再参与库存锁定/扣减，必须操作还原订单后才会正常锁定/扣减库存，请谨慎操作",
    "pickingList": "拣货单",
    "printedBy": "打印人",
    "supportTikiAndZaloraOrdersToComplete": "支持Tiki与Zalora订单批量完成收货，Blibli订单请至订单详情操作",
    "onlyLazadaBlibiliAndZaloraSupportPackaging": "只有Lazada、Blibli和Zalora的订单支持打包、已打包的不支持二次打包，如需修改Blibli订单商品数量，请至订单详情中更新",
    "confirmWhetherToSetTheOrderStatusAsShipped": "请确认是否设置订单状态为已发货",
    "markAsShipped": "标记已发货",
    "whetherToUseGineeTemplateToPrintInvoices": "是否采用Ginee自有模板打印发票（Lazada PH站点的订单仅支持使用Ginee自有模板打印发票）",
    "buyerPayment": "买家支付金额",
    "doAllProductInTheSameOrder": "同一订单内所有商品是否使用同一发票号",
    "clickHereToSetting": "点此设置",
    "operationLog": "操作日志",
    "massSetPickupTime": "批量设置取件时间",
    "numberOfFails": "失败单数",
    "totalNumber": "总单数",
    "invoiceNumberSettingFailureDetails": "发票号设置失败详情",
    "invoiceNumberIsSetSuccessfully": "发票号设置成功",
    "clickHereToSetWhetherAll": "点此设置同一订单内所有商品是否使用同一发票号",
    "setInvoiceNumber": "设置发票号",
    "printShippingLabelAndPickingLists": "打印快递单和拣货单",
    "pleaseConfirmWhetherTheReceiptIs": "请确认是否完成收货？",
    "onlyTikiOrdersCanBeReceived": "仅支持Tiki订单批量完成收货，Blibli订单请至订单详情操作",
    "malaysiaTemplates": "马来模板",
    "generalTemplates": "通用模板",
    "unassignedWarehouse": "未分配仓库",
    "jikaPesananTelahDikaitkanDenganGudang": "如订单已关联三方仓，请确认出库单是否已被仓库成功取消，以防库存有误",
    "inOrderToEnsureTheNormal": "为保证系统功能正常使用，在11.11~11.12大促期间订单下载功能将无法使用，感谢您的支持",
    "warehouse": "仓库",
    "unpairedMasterSKU": "未配对主SKU",
    "postalCode": "收货邮编",
    "detailsAddress": "收货详细地址",
    "street": "收货街道",
    "districts": "收货乡镇",
    "city": "收货城市",
    "province": "收货省份",
    "senderPhone": "卖家电话",
    "senderName": "卖家姓名",
    "dataLoadingPleaseWaitOrClick": "数据加载中,请稍候或点击后方按钮直接跳转",
    "activatedAllOrdersFromTheAuthorized": "一旦开启，全部已授权店铺的订单需操作“订单打包”后，进行发货操作",
    "restrictionShippingOfOrdersMustBe": "发货限制：必须优先操作订单打包后，操作发货",
    "shippingDiscountSeller": "卖家运费折扣",
    "feeDiscountFromPlShipping": "3PL 运费折扣",
    "taxEscrow": "跨境税",
    "transactionFeeBuyer": "买家交易费",
    "transactionFeeSeller": "卖家交易费",
    "SetPackStatusTitle": "设置打包状态",
    "SetPackStatusInMass": "批量设置打包状态",
    "timeUpdate": "更新时间",
    "timeCreation": "创建时间",
    "contentOperation": "操作内容",
    "moduleOperation": "操作模块",
    "recordOperation": "操作日志",
    "orderRestore": "还原订单",
    "inToAlreadyShippedMove1": "移入已发货",
    "warehouseThirdparty": "三方仓",
    "warehouseOwn1": "自有仓",
    "inToAlreadyShippedMove": "移入已发货",
    "inToBeCollectedMove": "移入待揽收",
    "numberEmptyAwb": "运单号缺失",
    "printedNot": "未打印",
    "youWantToOperateSeparatelyPlease": "如希望分开操作，请移步至全部订单列表完成操作",
    "ordersWillBeDirectlyPackagedAnd": "Lazada/Blibli 订单将直接完成打包及发货操作",
    "processingTheTokopediabukalapakOrderWillBe": "处理完成后，Tokopedia/Bukalapak 订单将完成接受及发货操作",
    "youSureToProcessTheSelected": "确认将选中的 X 笔订单一起处理并移入待打单么？",
    "failedCancel": "取消失败",
    "pushToThirdpartyWarehouseFailed": "推送三方仓失败",
    "toPendingMove": "移入待处理",
    "asPriorityMark": "标记为优先处理",
    "orderOutOfStockAll": "全部缺货订单",
    "orderOutOfStockSelected": "所选缺货订单",
    "stockCheck": "检查库存",
    "skuXxxxIsInsufficientInThe": "主SKU:{x}在{y}仓库内库存不足，请在库存管理操作补货后重新处理",
    "skuXxxxDoesNotExistIn": "主SKU:XXXX在XXXX仓库内不存在，请通过更换主SKU重选仓库或在仓库中新增主SKU后重新处理",
    "warehouseToShipmentPush": "推送仓库发货",
    "orderProcessing": "处理订单",
    "processingFailed": "处理失败",
    "ofStockOut": "缺货",
    "warehouseOwn": "自有仓",
    "failedOrdersView": "查看失败订单",
    "failed": "失败",
    "success": "成功",
    "total": "总数",
    "descriptionXxOrdersHaveBeenSynchronized": "进度说明：已同步 XX 单，剩余 XX 单待同步",
    "pleaseWaitSyncing": "同步中，请稍候",
    "result": "结果",
    "reasonFailed": "失败原因",
    "exceptionSynchronization": "同步异常",
    "otherLogisticsProviderNumberEmptyPlaceholder": "尚未生成单号，请稍后同步",
    "theCheckIsRemovedTheIntegrated": "去除勾选后，已关联的主商品库存将不会增加",
    "selectedTheIntegratedMasterProductStock": "选中后，已关联的主商品库存会增加",
    "ifNoRuleIsAddedAfter": "提示：如未添加规则，订单变更为取消状态后，订单内全部已关联主商品的商品库存都将自动重新入库",
    "forTheProductStockOfThe": "仅会针对订单内已配对的主商品的商品库存，如未关联主商品，则不会处理",
    "theReasonForCancellationIs1": "将取消理由为##时，更新为商品不重新入库",
    "theReasonForCancellationIs": "将取消理由为##时，更新为商品重新入库",
    "operationContent": "操作内容",
    "delete": "删除",
    "updateTime": "更新时间",
    "createTime": "创建时间",
    "cancelReason": "取消理由",
    "operator": "操作人",
    "pleaseSelectStore": "请选择店铺",
    "pleasesSelectChannel": "请选择渠道",
    "no": "否",
    "yes": "是",
    "updateWarehouseStock": "商品重新入库",
    "unknownReason": "未知原因",
    "buyerCanceled": "买家取消",
    "systemCancel": "系统取消",
    "cancelSetting": "取消设置",
    "kodePos": "邮政区号",
    "nameFirst": "名",
    "nameLast": "姓",
    "staff": "员工",
    "secretary": "秘书",
    "receptionist": "接待",
    "security": "保安",
    "driver": "司机",
    "helperDomestic": "助手",
    "relationship": "亲属",
    "mrmrsms": "先生/女士",
    "spouse": "配偶",
    "related": "家人",
    "lessThanCharactersRequired": "必填，小于30个字符",
    "codeSettlement": "结算单号",
    "statusReceiver": "收货状态",
    "nameReceiver": "收货人姓名",
    "productDateReceived": "收货日期",
    "pleaseCompleteTheFollowingInformationAnd": "提示：请完善如下信息，更新订单至已完成状态",
    "completedOrder": "完成订单",
    "cancel": "取消",
    "noMoreThanCharactersRequired1": "必填，不超过300个字符",
    "ofThePerformanceReasonsPart": "部分履约原因",
    "quantityFinal": "最终数量",
    "ifTheCurrentOrderOnlySupports": "提示：如当前订单仅支持部分履约，请更新商品数量，如当前订单内全部商品数量更新为0，则会取消订单",
    "quantityChange": "修改数量",
    "endTimeMustBeGreaterThan": "结束时间需大于开始时间",
    "noMoreThanCharactersRequired": "必填，不超过300个字符",
    "fieldsRequired": "必填字段",
    "installationnote": "安装备注",
    "installationmobile": "安装人手机",
    "installationofficer": "安装人",
    "endDateInstallation": "安装结束日期",
    "startDateInstallation": "安装开始日期",
    "endDateShipping": "运输结束日期",
    "startDateShipping": "运输开始日期",
    "nameCourrier": "物流名称",
    "toPackSomeOrdersPleaseTry": "部分订单打包失败，请稍候重试",
    "successfullyPackaged": "打包成功",
    "pleaseWaitPacking": "打包中，请稍候",
    "quantity": "数量",
    "stockAvailablelocked": "可用/锁定库存",
    "skuwarehouseMaster": "主SKU/仓库",
    "nameProduct": "商品名称",
    "numberOrder": "订单号",
    "onlyLazadaAndBlibliOrdersSupport": "提示：只有Lazada和Blibli的订单支持打包，已打包的不支持二次打包，如需修改Blibli订单商品数量，请至订单详情中更新",
    "theProductsOfTheSameOrder": "将 Blibli 内同订单的商品一起打包（限Regular product / Big product）",
    "pack": "打包",
    "stockAvailable": "可用库存",
    "serviceProviderFulfillment": "履约服务商",
    "skuMaster": "主SKU",
    "nameWarehouse": "仓库名称",
    "serviceProvider": "服务商",
    "theMasterSkuReplacing": "更换主SKU",
    "withXxxxxxPaired": "已与XXXXXX配对",
    "reasonReturn": "退货原因",
    "itemsRestock": "库存退回",
    "amountRefund": "退款总额",
    "shippingReturn": "退回运费",
    "quantityReturn": "退货数量",
    "totalRefundable": "可退总额",
    "sendNotificationsToCustomers": "向客户发送通知",
    "totalRefund": "退款总额",
    "productRefund": "退款商品",
    "ifYouChooseToIncreaseStock": "提示：如选择增加库存，则有退货数量的商品的库存将会增加",
    "refund": "退款",
    "buyerInfoEdit": "编辑买家信息",
    "location": "位置",
    "other": "其他",
    "declinedPayment": "付款被拒",
    "orderFraudulent": "欺诈",
    "unavailableItems": "商品不可用",
    "changedcanceledOrderCustomer": "客户取消",
    "CreditCardTransactionFee": "信用卡服务费",
    "moreThan5": "大于5天",
    "fiveDays": "5天内",
    "threeDays": "3天内",
    "twoDays": "2天内",
    "oneDays": "1天内",
    "noNotes": "无备注",
    "hasNotes": "有备注",
    "noMessage": "无留言",
    "hasMessage": "有买家留言",
    "multipleProduct": "多商品",
    "oneProductWithMoreThanOneQty": "单商品多个",
    "singleProduct": "单商品单个",
    "jdTip": "提示：受渠道接口影响，2021年4月29日开始新绑定的JD店铺存在部分订单无法同步的情况，目前渠道正在积极修复中，给您带来的不变敬请谅解",
    "orderIdSearchTips": "多个订单号间请用,隔开",
    "lastSync": "上次同步",
    "sortBy": "排序",
    "asc3": "升序",
    "desc2": "降序",
    "desc4": "降序",
    "asc2": "升序",
    "desc": "降序",
    "asc": "升序",
    "zToA": "(Z-A)",
    "aToZ": "(A-Z)",
    "payTime": "支付时间",
    "deliverDeadline": "截止发货时间",
    "productSku": "商品SKU",
    "failnumber": "失败数量",
    "sucessnumber": "成功数量",
    "totalnumber": "总数量",
    "lazadatip": "Lazada活动期间(3月26日到3月31日)订单同步可能存在延迟情况，且Lazada会限制API的调用，导致暂时不能通过Ginee编辑产品信息（包括库存和价格）或发布新产品，给您带来的不便，敬请谅解",
    "keyword": "关键字",
    "logisticsProviderNumberEmptyPlaceholder": "Shopee尚未生成单号，请稍后同步",
    "logisticsProviderNameEmptyPlaceholder": "Shopee尚未分配物流，请稍后自行同步",
    "PrintPdfErrorMessage": "文件生成失败，请稍后重试",
    "orderDetailNoteLimit": "不超过500个字符",
    "GoContinue": "继续前往",
    "BrowserBlockedInfo": "检测到新窗口已被浏览器拦截，请点击“继续前往”打开新页面窗口（您还能在帮助中心搜索“解除浏览器拦截方法” 来解除拦截）",
    "Notification": "提示",
    "ShowLabelProductInfo": "(Bukalapak)物流单上显示商品信息",
    "VoucherPlatform": "平台优惠券",
    "PriceCreditCardServiceFee": "信用卡服务费",
    "PriceSellerReturnRefundAmount": "退款金额",
    "PriceCoinCashBack": "卖家现金返还",
    "PriceCoin": "金币",
    "AllStatus": "全部状态",
    "AllType": "全部类型",
    "theMarketplacePromotionPeriodPleaseUnderstand": "平台大促期间，订单同步可能存在延迟，敬请谅解",
    "pendingTableTips": "目前仅支持 Shopee/Bukalapak/Tokopedia/Lazada/JD/Shopify 渠道的订单在 Ginee 内完成发货操作",
    "Filter": "筛选",
    "InvoiceSettings": "发票设置",
    "MultipleProduct": "多商品",
    "OneProductOneQty": "单商品多个",
    "paidTime": "支付时间",
    "packageStatus": "打包状态",
    "shipBefore": "承诺运输",
    "sellerNote": "卖家备注",
    "buyerNote": "买家留言",
    "packageType": "包裹类型",
    "SingleProduct": "单商品单个",
    "orderProductType": "商品类型",
    "ViewFailedList": "查看失败列表",
    "AWBTrackingNumber2": "物流单号/发票号",
    "PickUpTime": "取件时间",
    "NoEmptyText": "暂无数据",
    "OrdersFailedTips": "部分订单发货失败，请稍后重试",
    "SuccessfullyShipped": "发货成功",
    "TotalOrdersShipped": "本次待发货的订单总数量",
    "ShippingWait": "发货中，请稍后...",
    "action": "操作",
    "SeeMoreRetract": "收起",
    "FailedReason": "失败原因",
    "RecipientMobile": "收件人手机",
    "SetNow": "去设置",
    "SkipNext": "跳过,下一步",
    "shippingTips3": "受JD接口限制，仅支持打印物流单后操作发货，如未打印，将不支持发货",
    "shippingTips2": "如无此设置，系统将按照上门拣货 > 自行寄出的先后顺序分配发货方式",
    "shippingTips1": "部分物流未设置发货方式，请优先完成发货方式设置后，操作发货",
    "FilterOrder": "订单筛选",
    "setAddress": "暂无地址，请前往卖家中心设置",
    "ArrangeShipment2": "安排发货",
    "MassUpload": "批量上传",
    "ShippingCarrier": "物流公司",
    "OrderQuantity": "订单数量",
    "DownloadTemplate": "下载模板",
    "FileSizeLimitTips": "仅支持小于5 M的 xlsx/xls 文件",
    "ImportFile": "导入文件",
    "FileFormatInvalid": "导入文件格式不正确",
    "ProcessFailed": "处理失败",
    "ToBeDelivered": "待安排发货",
    "ToBeAccepted": "待接受",
    "PickUpAddress": "取件地址",
    "axiosTips": "提示",
    "moreMaxLength": "最多{ maxLength }个字符",
    "PleaseSelect": "请选择",
    "SyncError": "同步失败",
    "syncSuccess": "同步完成",
    "Synchronizing": "同步中",
    "reset": "重置",
    "search": "搜索",
    "status_failed": "失败",
    "status_returned": "已退货",
    "status_cancelled": "已取消",
    "status_delivered": "已收货",
    "status_shipping": "发货中",
    "status_paid": "已支付",
    "status_pending_payment": "待付款",
    "CancellingProcess": "取消中",
    "Delete": "删除",
    "Unpicked": "待拣货",
    "Picked": "已拣货",
    "ShippingMethodLink": "快去设置吧",
    "ExportDisabled": "已失效",
    "ExportFailed": "下载失败",
    "ExportFinished": "有效",
    "ExportINProcess": "进行中",
    "ExportStatus": "状态",
    "AddTemplate": "新增模板",
    "ExportFiledReason": "失败原因：",
    "FileSaveHistoryDays": "文件将同步展示在导出历史列表，文件仅会保留15天，请及时点击下载",
    "ExportFileFailed": "文件生成失败，请稍后重试",
    "ExportFileGenerated": "文件已生成，请点击下载",
    "ExportFileInProcess": "文件正在生成、请稍后",
    "TokoOrderApiUnstable": "受 Tokopedia 渠道更新影响，Ginee 将于11月3日 印尼时间22:00起暂停该渠道订单同步服务1 小时，给您带来的不变敬请谅解",
    "ExportResultTitle": "结果",
    "OrderStatus": "订单状态",
    "ExportDownload": "下载",
    "ExportAllOrders": "按所有页导出",
    "ExportBySelected": "按勾选导出",
    "Export": "导出",
    "OtherInformation": "其他信息",
    "AmountInformation": "金额信息",
    "LogisticsInformation": "物流信息",
    "ProductInformation": "产品信息",
    "BuyerInformation": "买家信息",
    "ExportSelectedDate": "选择时间",
    "ExportSelectedTemplate": "选择模板",
    "ExportOrder": "导出订单",
    "ExportCreator": "创建人",
    "ExportContent": "内容",
    "ExportTime": "导出时间",
    "ExportHistory": "导出历史",
    "TaxationFee": "税费",
    "CancelNoteText": "取消原因",
    "FinalShippingFee": "系统代付运费",
    "PickingListStatus": "拣货单状态",
    "PackingListStatus": "装箱单状态",
    "InvoiceStatus": "发票状态",
    "LabelStatus": "快递单状态",
    "AfterClickPrint": "点击打印后，下方订单对应的单据会被标记为已打印",
    "SetToPrintedMass": "批量设置为已打印",
    "SetPrintStatusTips": "提示：一旦设置为已打印，不再支持更改打印状态，请知悉。",
    "SetPrintStatus": "设置打印状态",
    "SetStatus": "设置状态",
    "PickStatus": "拣货状态",
    "PrintStatus": "打印状态",
    "DiscountedTotalPrice": "折扣价",
    "InvoiceAlreadyPrinted": "发票已打印",
    "InvoiceNotPrinted": "发票未打印",
    "PackingListAlreadyPrinted": "装箱单已打印",
    "PackingListNotPrinted": "装箱单未打印",
    "PickingListAlreadyPrinted": "拣货单已打印",
    "PickingListNotPrinted": "拣货单未打印",
    "LabelAlreadyPrinted": "快递单已打印",
    "LabelNotPrinted": "快递单未打印",
    "NotPrinted": "未打印",
    "AlreadyPrinted": "已打印",
    "Rebate": "补贴",
    "NewBuyerNote": "买家留言",
    "OrderTotal": "订单总额",
    "BuyPhone": "电话",
    "ProductInfo": "商品信息",
    "SellerNote": "商家备注",
    "NoContentYet": "暂无内容",
    "OnlyVisibleToPicking": "仅拣货单可见",
    "OnlyVisibleToSellers": "仅卖家可见",
    "CurrentlyNotSupportedShipping": "暂不支持查询物流动态",
    "AWB": "快递单号",
    "Voucher": "优惠券",
    "ServiceFee": "服务费",
    "CommissionFee": "佣金",
    "UnstableLazadaAPI": "近期因Lazada接口不稳定，部分店铺订单无法完全同步，Lazada正在紧急修复，如遇到该问题，请优先至卖家中心处理订单",
    "HideProductInfo": "隐藏商品信息",
    "ShippingWeight": "重量",
    "ShippingSender": "寄件人",
    "ShippingRecipient": "收件人",
    "BatchLabelInfo": "点击打印后，下方订单对应的单据会被标记为已打印",
    "PrintBatchLabel": "批量打印快递单",
    "ViewAllProduct": "查看全部{ productCount }个商品",
    "ShipBefore": "承诺运输",
    "PackFailedInfo": "打包失败，请刷新或同步后重试",
    "PleaseSelectProduct": "请选择产品",
    "SkipPrint": "跳过并打印",
    "SomeOrderInvoiceEmpty": "部分订单发票号为空，无法打印，请先补充",
    "SearchPlaceholder": "请输入搜索内容",
    "Unpacked": "未打包",
    "PackingStatus": "打包状态",
    "ReceiverName": "收件人姓名",
    "BuyerEmail": "买家邮箱",
    "BuyerContact": "买家电话",
    "BuyerName": "买家姓名",
    "TrackIngNumber": "快递单号",
    "InvortySKU": "MSKU",
    "ParentSKU": "父SKU",
    "ItemName": "商品名称",
    "productName": "商品名称",
    "OrdersID": "订单号",
    "Keywords": "关键字",
    "OrderTypeNormal": "正常订单",
    "OrderTypePreOrder": "预售订单",
    "AllOrderType": "全部订单类型",
    "PaymentPrePaid": "预付款",
    "AllPayment": "全部方式",
    "LdProductStatusInfo": "订单内产品处理状态不一致，请查看订单详情",
    "PackingFailedInfo": "打包失败，请刷新页面后重试",
    "BillingAddress": "账单地址",
    "InvoiceNumber": "发票编号",
    "ProductsItemPack": "订单内已打包的商品不支持二次打包",
    "OnlyUnPackagedOrders": "仅适用于未打包的订单",
    "AllProductsPacked": "同一订单内所有商品使用同一发票号",
    "PackMethod": "打包方式",
    "PackOrderTips": "提示：Lazada的订单必须打包后方能发货，Ginee仅支持同订单下全部商品一起打包.",
    "AlreadyPacked": "已打包",
    "Pack": "打包",
    "PackOrder": "打包",
    "LWrongPricePricingError": "价格/定价有误",
    "LDuplicateOrder": "重复下单",
    "LCustomerUnreachable": "联系不上客户",
    "order_98c90235ea6e3d054f70d2ffdfa9e703": "缺货",
    "LOutOfDeliveryArea": "超出配送范围",
    "LIncorrectOr": "收货地址有误",
    "LSystemError": "系统故障",
    "LSourcingDelay": "采购延迟",
    "NoName": "名称为空",
    "ProductSyncFailedAgain": "商品同步失败，请重新同步店铺商品",
    "ShopCancelOrderStockInfo": "产品售罄 — 选中产品的库存将在订单所属店铺置为0",
    "TokoCancelOrderStockInfo": "产品售罄 — 选中产品的库存将在订单所属店铺置为0",
    "BLCancelOrderStockInfo": "取消订单后,订单内产品的库存将在订单所属店铺置为0",
    "AlreadyProcessed": "已处理",
    "Grab": "Grab [9:00-16:00]",
    "GOSENDInstant": "GO-SEND Instant [6:00-18:00]",
    "GOSENDSameDay": "GO-SEND Same Day [6:00-15:00]",
    "AvailableTimeToPickup": "接单时间",
    "ProductUnitPrice": "产品单价",
    "ShopCloseTime": "关店时间",
    "ToCancellationInfo": "Tokopedia 不支持卖家拒绝买家取消订单的申请",
    "ToTrackingNoInfo": "8-17个字符",
    "trackingNoErrorMessage": "{min}-{max} 字符",
    "BLTrackingNewNoInfo": "8-25个字符",
    "BLTrackingNoInfo": "必须由8-25个字母及数字组成",
    "TBuyerRequest": "买家要求",
    "TCourierProblem": "快递原因",
    "TOthers": "其他原因",
    "shopClosed": "商店关闭",
    "TWrongPriceOrWeight": "价格或重量有误",
    "TProductVariantUnavailable": "当前规格已售罄",
    "TProductOutOfStock": "产品已售罄",
    "TRejectShipping": "快递拒载",
    "BLBusy": "订单无法处理",
    "BLOutdatedPrice": "价格未更新",
    "BLInappropriatePrice": "价格有误",
    "BLVariantOutOfStock": "当前规格已售罄",
    "BlSuppliersOutOfStock": "供应商缺货",
    "BLNoStock": "产品已售罄",
    "ReceiveInfo": "您可以同时接受Tokopedia、\nAkulaku、 Bukalapak和Tiki的订单，但是Bukalapak与Tiki的订单一经接受无法取消",
    "SelectStockEmpty": "选中的产品库存将被清空",
    "DeleteKeepOneData": "请至少保留一条数据",
    "CourierTracking": "物流跟踪",
    "NoReturnOrder": "没有需要处理的退货申请",
    "InReturn": "退货中",
    "InCancel": "取消中",
    "ReturnTips": "仅支持单笔订单操作退货，选择多笔时，默认处理第一笔",
    "NoCancellationOrder": "没有需要处理的取消申请",
    "PrintDate": "打印日期",
    "BuyerNote": "买家备注",
    "message": "留言",
    "seeMore": "查看{ more }个以上的变化",
    "HistoryOrderCompleted": "订单已完成",
    "HistoryOrderCancellation": "成功拒绝退货",
    "HistoryOrderReturned": "订单已退货",
    "HistoryOrderShipping": "订单已发货",
    "HistoryCancelOrder": "订单已取消",
    "HistoryReject": "卖家拒绝取消订单",
    "HistoryCreateOrder": "创建订单",
    "Reject": "拒绝",
    "Confirm": "确认",
    "AcceptInfo": "如果同意，订单会被立即取消，已支付的金额将被退还给买家，请不要继续安排发货，如果已经发货，请拒绝这个取消申请",
    "Images": "图片",
    "AcceptReturn": "接受退货",
    "Respond": "回复",
    "Reason": "原因",
    "RequestID": "请求ID",
    "ReasonFromBuyer": "买家原因",
    "qtyToShip": "发货数量",
    "Qty": "总数量",
    "buyer": "客户名",
    "orders": "订单",
    "CODNotSupported": "不支持货到付款",
    "UndeliverableArea": "无法送达",
    "CustomerRequest": "买家要求",
    "OutOfStock": "无货",
    "History": "操作历史",
    "NoNext": "不是，继续",
    "YesNext": "是的，继续",
    "Print": "打印",
    "Deleted": "删除",
    "Retry": "重试",
    "AcceptRejectInfo": "是否接受订单的取消申请？",
    "receiveOrderTip": "您确定处理已选择的订单么？",
    "selectCancelTips": "您想用同一个理由取消同一渠道的订单么？",
    "AddPickingStatus": "新增状态",
    "SetPickStatusConfirm": "确认",
    "PickApplyToAll": "应用至全部",
    "SetPickStatusTitle": "设置拣货状态",
    "SetPickStatusInMass": "批量设置拣货状态：",
    "PickingSetTip": "提示：一旦设置为已拣货，不再支持更改状态，请知悉。",
    "PrintPicking": "打印拣货单",
    "PrintPackingList": "打印装箱单",
    "PrintInvoice": "打印发票",
    "PrintLabel": "打印快递单",
    "ProcessingCancellation": "处理取消单",
    "ProcessingReturns": "处理退货",
    "ReceiveOrder": "接受订单",
    "orderSelected": "已选中",
    "shippingfeetip": "BL的运费将在商品被发送后计入总额",
    "variationdeleted": "当前商品已删除",
    "Sync": "同步",
    "tipsWait": "“发货”功能将于近期上线，请您到该店铺的商家后台去处理发货",
    "variation": "规格",
    "MSKU": "MSKU",
    "SKU": "SKU",
    "Total": "总额",
    "SellerDiscountTotal": "卖家折扣",
    "Insurance": "保险费",
    "Tax": "税费",
    "Shipping": "运费",
    "subtotal": "总计",
    "readyToShip": "准备发货",
    "timeSlots": "时间段",
    "selectAddress": "选择地址",
    "address": "地址",
    "selectCourier": "选择物流公司",
    "logistics": "物流",
    "reasonForCancellation": "取消原因",
    "qtyToCancel": "取消数量",
    "qtyOrdered": "购买数量",
    "ArrangeShipment": "发货",
    "updateOrder": "更新",
    "CancelOrder": "取消订单",
    "notes": "备注",
    "item": "商品",
    "shippingAddress": "送货地址",
    "customerEmail": "电邮",
    "contactNo": "联系电话",
    "promiseToShipBefore": "承诺在..之前送到",
    "AWBTrackingNumber": "快递单号",
    "shippingType": "物流类型",
    "paymentType": "支付类型",
    "orderType": "订单类型",
    "store": "店铺名",
    "date": "日期",
    "customerDetails": "买家信息",
    "orderInfo": "订单信息",
    "processingFlow": "处理流程",
    "orderDetailId": "订单号 { orderId }",
    "updateAt": "更新时间",
    "createAt": "创建时间",
    "orderStatus": "状态",
    "customerName": "客户名",
    "orderId": "订单号",
    "amountMax": "最大总额",
    "amountMin": "最小总额",
    "amount": "金额",
    "createDate": "创建时间",
    "shippingCarrier": "物流公司",
    "channelName": "渠道",
    "keyWord": "搜索订单号，SKU 或客户名",
    "refundableShippingFeeIsCalculatedIn": "可退金额中计算了可退运费,请自行过滤",
    "youDontHaveOperationAuthorityFeature": "套餐生效期间，累计创建订单数已达{x}单，超出{y}单后将无法使用订单功能，请提前订阅套餐或联系客服升级套餐",
    "understood": "知道了",
    "disableDefaultRules": "关闭默认规则",
    "enableDefaultRules": "开启默认规则",
    "theDefaultRuleIsSuccessfullyDeactivated": "默认规则关闭成功",
    "theDefaultRuleIsSuccessfullyActivated": "默认规则开启成功",
    "ifTheOrderDoesNotMeet": "如订单不满足于任何一条已创建规则时，将无法创建出库单扣减库存",
    "afterDisableTheOrderWillOnly": "关闭后，订单将仅按照已创建的分仓规则分配仓库，并创建出库单扣减库存",
    "inOrderToEnsureThatThe": "为保证主商品库存可以进行正常扣减，未配置分仓规则时，默认规则不支持关闭",
    "defaultRuleWhenNoRuleIs": "默认规则：未手动创建规则时，按照MSKU最新创建的入库单仓库进行分仓",
    "noticeWhenTheRuleNotManually": "提示：默认规则在手动创建规则为空时，不支持关闭；手动创建规则后，订单将不会按照默认规则处理；如同一仓库有多个分配规则，则按照规则优先级进行分配",
    "country": "国家/地区",
    "IntegrateNow": "去绑定",
    "noconnected": "暂无绑定的店铺，请先前往授权绑定店铺",
    "enableTips": "启用后，该规则将影响订单仓库分配，请谨慎操作",
    "youSureToEnableAre": "确定启用？",
    "setAtLeastOneConditionPlease": "请至少设置一个条件",
    "deletedSuccessfully": "删除成功",
    "youSureToDeleteAre": "确定删除么？",
    "mskuAdd": "新增主SKU",
    "logisticAdd": "新增物流",
    "storeAdd": "新增店铺",
    "disabledWarehouseWillNoLongerBe": "禁用后将不再按照该规则分配仓库",
    "youSureYouWantToDisable": "确认禁用该规则？",
    "deleteMskuRule": "规则XXX删除MSKU: #XXX#",
    "addMskuRule": "规则XXX增加MSKU: #XXX#",
    "orderAmountIsAdjustedFrom": "规则XXX订单金额由xx~xx调整为XX~XX",
    "deleteLogisticsRule": "规则XXX删除#XXX#物流",
    "addLogisticRule": "规则XXX增加#XXX#物流",
    "ruleNameOfRules": "规则XXX的规则名称更新为#XXX#",
    "deleteStoreRule": "规则XXX删除#XXX#店铺",
    "addStoreRule": "规则XXX增加#XXX#店铺",
    "priorityOfRuleUpdated": "规则XXXX优先级由X更新为X",
    "historyOperation1": "操作日志",
    "logisticSelectedX": "已选中 {x} 个物流",
    "storeSelectedX": "已选中 {x} 个店铺",
    "warehouseAllocation": "分配仓库",
    "add": "新增",
    "amountOrder": "订单金额",
    "nameLogistic": "物流名称",
    "nameStore": "店铺名称",
    "conditionSelect": "已选条件",
    "masterSkuDesignated": "指定主SKU",
    "orderAmountRangeSpecify": "指定订单金额范围",
    "logisticDesignated": "指定物流",
    "storeDesignated": "指定店铺",
    "conditionOptional": "可选条件",
    "setAtLeastOneAllocationCondition": "请至少设置一个分配条件，以确保完成规则设置",
    "settingCondition": "条件设置",
    "informationBasic": "基本信息",
    "ifTheOrderShippingWarehouseRule": "提示：未添加订单发货仓规则或主SKU不存在于规则中配置的仓库时，将无法生成出库单扣减库存，请务必设置规则；如同一仓库有多个分配规则，则按照规则优先级进行分配",
    "ruleAdd": "新增规则",
    "disable": "停用",
    "enable": "启用",
    "edit": "编辑",
    "operate": "操作",
    "time": "时间",
    "conditionAllocation": "分配条件",
    "nameRule": "规则名称",
    "priority": "优先级",
    "upTheSearch": "单次最多搜索{X}个",
    "moreMax": "最多{ maxLength }个",
    "separateMultipleInfoWithCommasOr": "多个信息使用逗号或空格隔开",
    "addSearchType": "添加搜索类型",
    "advancedSearch": "高级搜索",
    "mskuIsNotEqualTo": "MSKU不等于",
    "skuIsNotEqualTo": "SKU不等于",
    "mskuIsEqualTo": "MSKU等于",
    "skuIsEquals": "SKU等于",
    "shopeebukalapakOrdersUseGineeTemplateFor": "Shopee / Bukalapak 订单使用 Ginee 模板进行快递单打印（ Tokopedia 目前仅支持使用 Ginee 模板）",
    "selectPrinter": "选择打印机",
    "printCompleted1": "打印完成",
    "printing2": "打印中",
    "checkTheDetails": "查看详情",
    "action1": "操作",
    "printQuantity": "打印数量",
    "printStatus": "打印状态",
    "printingAdmin": "打印人",
    "highspeedBatchPrintingHistory": "高速批量打印历史",
    "highspeedPrintingLabelHistory": "高速打单历史",
    "printAgain": "重新打印",
    "failureReason": "失败原因",
    "shipmentNumber": "快递单号",
    "order": "订单",
    "printFailed": "打印失败",
    "printSuccessfully": "打印成功",
    "printing1": "打印中",
    "cautionToEnsureNormalPrintingOperation": "特别提醒：为保证打印正常进行，请勿关闭/刷新当前页面，否则将造成当前打印任务终止或同一面单重复打印！",
    "IfPrintingFailsPleaseMove": "3.打印失败，请移至打印失败列表查看失败原因",
    "DuringPrintingIfSomeOrders": "2.打印中，部分订单打印失败，不影响整体打印进度",
    "AfterThePrintingIsCompleted": "1.打印完成后，订单将自动标记为已打印，如需更改，请至订单列表自行修改",
    "printingInstructions": "打印说明",
    "totalXxxAndXxPrinted": "共{ total } 条，已打印{ x } 条",
    "printCompleted": "打印完成",
    "printing": "打印中",
    "printingProgress": "打印进度：",
    "highSpeedPrintingTaskCenter": "高速打印任务中心",
    "recheck": "重新检测",
    "ifYouConfirmThatThePlugin": "如您确认已安装并启用插件，请点击重新检测按钮后操作打印",
    "notInstalledClickHereToDownload": "尚未安装，点击此处下载插件",
    "installed": "已安装，请自行启用插件",
    "detectedWeFoundThatYouHave": "经检测，发现您尚未安装或启用高速打印插件，请尝试以下方法：",
    "tips": "提示",
    "inOrderToImproveThePrinting": "为提升打印速度及体验，建议使用高速打印，",
    "startPrinting": "开始打印",
    "printer": "打印机",
    "noteToEnableHighspeedPrintingThe": "注：启用高速打印，必须下载并启动高速打印插件",
    "highspeedPrinting": "高速打印（单次打印不限数量，不支持打印预览）",
    "regularPrintingPrintUpTo": "普通打印（单次最多打印100笔订单，全部订单生成一份PDF面单，耗时较长）",
    "printingMethod": "打印方式",
    "haveBeenPrinted": "其中{ x }条已打印",
    "dataHaveBeenSelectedAnd": "已选中 { x }条数据",
    "printData": "打印数据",
    "customLabelOnlySupports": "自定义面单（仅支持Shopee/Tokopedia/Bukalapak订单）",
    "channelLabelAllChannelOrdersSupport": "渠道面单（除Shopify/Woocommerce订单外，均支持打印）",
    "setUpPrintTemplates": "设置打印模板",
    "printShippingLabelAll": "打印快递单（全部）",
    "printShippingLabelSelected": "打印快递单(已选中)",
    "selectAllOrders": "选中全部订单",
    "pleaseConfirmWhetherToTurnOff": "请确认是否关闭库存处理？",
    "putOnHold": "搁置",
    "pickingNote": "拣货备注",
    "dropshipper": "分销",
    "storeName": "店铺名",
    "setReadyToShip": "移入待发货",
    "becauseYouHaveNotIntegrateThe": "因您未授权渠道店铺，无法使用克隆功能，请优先完成授权",
    "integrateStore": "授权店铺",
    "integrationGuide": "授权引导",
    "MinutesToLearnAboutGinee": "1分钟了解Ginee",
    "shortcuts": "快捷操作",
    "theStoreHasNotBeenAuthorised": "尚未授权店铺，无法管理在线商品及订单",
    "inventoryManagement": "管理库存",
    "productManagement": "管理商品",
    "scrapeProduct": "采集商品",
    "masterProductSales": "主商品销量统计",
    "try": "马上试试",
    "productActivitySetting": "商品活跃度设置",
    "capitalOccupationRatio": "资金占用比例(%)",
    "stockTakesUpFunds": "库存占用资金",
    "numberOfMskus": "MSKU数量",
    "exportMsku": "导出MSKU",
    "purchaseDuration": "采购时长",
    "doesItNeedToBeReplenished": "是否需要补货",
    "availableDaysDays": "可售天数（天）",
    "averageDailySales": "日均销量",
    "lastMonth": "近1个月销量",
    "lastTwoWeeks": "近14天销量",
    "lastWeeks": "近7天销量",
    "lastThreeDays": "近3天销量",
    "numberOfRatings": "评价数",
    "ratings": "评分",
    "likes": "点赞量",
    "pageViews": "浏览量",
    "productActivityHasBeenSetFor": "该类目商品已设置了商品活跃度，请重新设置",
    "sales": "销量",
    "effectiveSales": "有效销量",
    "newItem": "新款",
    "lowSellingItem": "滞销款",
    "normalItem": "平款",
    "trendingItem": "旺款",
    "bestSellingItem": "爆款",
    "productActivity": "商品活跃度",
    "accounting": "财务管理",
    "categoryRange": "类目范围",
    "timeRange": "时间范围",
    "productDimension": "商品级别",
    "salesType": "销量类型",
    "salesRange": "销量区间",
    "allCategory": "全类目",
    "recent": "最近",
    "oneDay": "一天",
    "threeDay": "三天",
    "aWeek": "一周",
    "twoWeeks": "两周",
    "aMonth": "一个月",
    "threeMonths": "三个月",
    "halfYear": "半年",
    "onlySupportForIndonesia": "仅支持印尼",
    "addProductActivity": "新增商品活跃度",
    "cSupportToManuallyPullAccurate": "c.支持开启将Accurate的库存变更同步至Ginee，并更新绑定的Ginee仓库库存（默认关闭，请谨慎开启）",
    "bAfterTheOrderIsPlaced": "b.Ginee主商品出库会同步减少Accurate的库存",
    "aInboundStockForMasterProduct": "a.Ginee操作主商品入库会同步增加Accurate的库存",
    "StockSync": "库存同步",
    "noteOrderInformationSynchronizationIsA": "注：销售发票同步是每天自动从Ginee推送到Accurate的单向同步",
    "createdTheWarehousePairingRelationship": "店铺匹配Accurate成功后，Ginee会定时将店铺已完成的订单创建销售发票并推送至Accurate",
    "OrderDistribution": "4.销售发票推送",
    "noteTheSynchronizationOfMasterProduct": "注：主商品信息同步是从Ginee推送到Accurate的单向同步，创建新的主商品后会及时进行同步",
    "afterCreatedTheWarehousePairingRelationship": "仓库配对关系创建后，支持将Ginee仓库的主商品同步到Accurate对应仓库",
    "ProductPlacement": "3.商品下发",
    "psItIsNotRecommendedTo": "PS:不建议三方履约仓与Accurate仓库绑定，会有库存混乱的风险",
    "afterTheAuthorizationIsCompletedYou": "授权完成后，可在【设置-财务设置】管理仓库和店铺的匹配，以及设置同步规则。匹配后才可以正常进行商品/库存/销售发票的同步",
    "BasicConfiguration": "2.基础配置",
    "pleaseMakeSureToFillIn": "请务必正确填写Accurate账号/密码，并选择 Database 完成授权",
    "Authorization": "1. 授权绑定",
    "accurateOperationGuide": "Accurate 操作引导",
    "averageDailySalesSettings": "日均销量设置",
    "averageSalesForTheDayAs": "内的平均销量作为日均销量",
    "clickAuthorizeYouWillBeRedirected": "点击【去授权】，您将跳转到 Accurate 的页面，请务必正确填写Accurate账号/密码登录后完成授权",
    "onSaleDaysDays": "可售天数(天)",
    "purchaseTimedays": "采购时长(天)",
    "AfterTheAuthorizationIsCompleted": "2.授权完成后，请在 Accurate 配置管理中完成仓库配对；",
    "PleaseSelectAtLeast": "1.请至少选择1个 Database 完成授权，如果还没有Database请到Accurate进行添加",
    "remainder": "温馨提示",
    "authorizationSucceeded": "授权成功",
    "theAccurateWarehouseInformationIsBeing": "正在为您同步Accurate的仓库和会计科目，请继续进行财务初始化匹配设置！",
    "continueAuthorization": "继续授权",
    "reauthorize": "重新授权",
    "channelFailureReason": "渠道失败原因:",
    "authorizationFailed": "授权失败",
    "deleteAuthorization": "删除授权",
    "authorizer": "授权人",
    "authorizationTime": "授权时间",
    "connected": "已连接",
    "authorizationStatus": "授权状态",
    "ServiceProvider": "服务商",
    "accountName": "账户名称",
    "deleteFailed": "删除失败",
    "theBindingRelationshipBetweenTheAccurate": "Accurate 仓库与 Ginee仓库的绑定关系已解除，且不再与ACCURATE进行商品/订单/库存信息同步",
    "onceDeletedTheBindingRelationshipBetween": "一旦删除，将解除Ginee仓库与Accurate仓库的绑定关系，并不再与ACCURATE进行商品/订单/库存信息同步",
    "areYouSureWantToDelete": "确认删除么？",
    "productSuccessfullySynchronized": "商品同步成功",
    "whenDistributeGineeWarehouseProductGinees": "下发Ginee仓库商品，将直接使用Ginee的MSKU仓库库存覆盖Accurate仓库库存，请谨慎操作",
    "areYouSureToSyncGinee": "确认同步Ginee仓库商品至Accurate仓库？",
    "channelReturnFailureReason": "渠道返回失败原因：",
    "pullFailed": "拉取失败",
    "pullSuccessfully": "拉取成功",
    "pull": "拉取",
    "afterManuallyPullingTheWarehouseInformation": "手动拉取仓库信息后，新增的仓库请务必进行绑定操作，已停用的仓库将直接删除，绑定关系同步清空，不再进行数据同步，请务必知悉",
    "pullWarehouse": "拉取仓库",
    "warehouseStockSuccessfullyUpdated": "仓库库存更新成功",
    "onceTheStockOfTheThirdparty": "一旦三方仓库 库存与系统不一致，该操作将直接将现有商品的仓库库存更新为三方仓库库存值，并生成调整记录，具体信息可在拉取记录中查看",
    "areYouSureToPullStock": "确认拉取Accurate仓库的库存？",
    "theWarehouseBindingRelationshipHasBeen": "仓库绑定关系解除成功",
    "afterTheUnbindingIsSuccessfulThe": "解绑成功后，Accurate仓库内已接收的商品/订单信息不受影响，后续原绑定Ginee仓库内产生的数据将不会同步至 Accurate",
    "areYouSureToUnbindThe": "确认解除当前仓库的绑定关系？",
    "reasonTheCurrentWarehouseHasBeen": "原因：当前仓库已被其他Accurate仓库绑定，请解绑后重试",
    "bindingFailed": "绑定失败",
    "bindingIsSuccessfulPleaseDoNot": "绑定成功，请勿重复操作",
    "bind": "绑定",
    "tipOnlySupportsSelectingWarehouseThat": "提示：仅支持选择Ginee仓库列表中启用状态的仓库",
    "syncWarehouse": "同步仓库",
    "unbindWarehouse": "解绑仓库",
    "bindTime": "绑定时间",
    "accurateWarehouse": "Accurate 仓库",
    "accurateWarehouseInformationWillNotBe": "Accurate 仓库信息不会同步到 Ginee 仓库列表，将按照与Ginee已有仓库的绑定关系进行主商品/订单/库存信息同步",
    "accurateSettingManagement": "Accurate 配置管理",
    "synchronizeWarehouseInformationUnderAccurateDatebase": "同步 Accurate Datebase  {x}  下的仓库信息，新增  {x}  仓库，删除  {x}  仓库",
    "syncGineeWarehouseProductTo": "同步Ginee  {x}  仓库商品至Accurate",
    "syncStockFromAccurateWarehouse": "同步Accurate 仓库  {x}  的库存至Ginee 仓库  {x}",
    "accurateWarehouseUnbindFrom": "Accurate 仓库  {x}  与  {x}  仓库解除绑定",
    "accurateWarehouseBindTo": "Accurate 仓库 {x} 绑定 {x} 仓库",
    "operationLogIsOnlyRetainedFor": "操作日志仅保留近15天内容，超过15天后的操作信息将无法查看",
    "send": "发送",
    "translate": "翻译",
    "failedToSendMessage": "消息发送失败",
    "resend": "重新发送",
    "sendToBuyer": "发送给买家",
    "translationFailed": "翻译失败",
    "translatedx": "翻译成功：{x}",
    "translationInProgress": "正在努力翻译中",
    "automaticTranslationHasBeenTurnedOn": "已为您开启自动翻译",
    "autoTranslate": "自动翻译",
    "buyersLanguage": "买家语言",
    "afterCloseItThereWill": "关闭后，当买家有新消息进入，不再有声音提示",
    "notificationSound": "消息提示音",
    "uploadedNowlengthSheets": "已上传{nowLength}张",
    "isAUniqueIdentifierForThe": "为主商品的唯一识别码，确定后不能修改",
    "pleaseConfirmWhetherTheStoresChannel": "请确认店铺的渠道SKU是否有值，如果没有我们会取渠道商品标题作为主商品的MSKU",
    "errnameToko": "商品名称必须是1到70个字符",
    "sizeSettingTemplate": "尺寸模版设置",
    "mappingCategory": "类目映射",
    "templateSettingLogistic": "物流模版设置",
    "rangeEnable": "商品设置",
    "globalsMobileErrorInfoLength": "电话号码必须为{min}到{max}位数字",
    "globalsMobileErrorInfo": "请输入正确的手机号码",
    "masterSku": "主 SKU",
    "outboundPurchasePrice": "采购单价",
    "hasbinded": "这个规格已绑定库存SKU:{ sku } 请在「库存管理」更新库存",
    "deletecustomersuccess": "删除成功",
    "reasonforpushedfailed": "失败原因：商品在店铺已被删除或被禁卖 、正在参与活动（活动结束后,库存可被更新成功）请检查商品",
    "purchasePricerequired": "金额必须在0 〜 1,000,000,000之间！",
    "createsuccess": "创建成功",
    "atmost20tags": "最多20个tag",
    "AddTag": "添加标签",
    "tagexist": "Tag已存在",
    "fenotempty": "tag的前面和结尾不能为空格",
    "taginputlimit": "Tag应该是3-40个字符",
    "selecttag": "请选择标签",
    "withordernostock": "有订单无货",
    "withisku": "有库存SKU",
    "noisku": "无库存SKU",
    "withskuwithoutisku": "有SKU且无库存SKU",
    "nosku": "无SKU",
    "selectskusit": "选择SKU条件",
    "Pleaseenter": "请输入",
    "selectsku": "选择库存SKU",
    "emailreminder": "邮件提醒",
    "safetystockarea": "可用库存<=",
    "bindedisku": "已绑的库存SKU:",
    "most20bundles": "最多20个",
    "NAME": "名称",
    "PleaseEnter": "请输入",
    "disablebundletip": "为保证库存扣减的准确性，编辑捆绑ISKU时不能修改绑定的库存SKU及它们的数量",
    "updatesuccess": "更新成功",
    "ISKUtype": "ISKU类型",
    "bundleisku": "捆绑ISKU",
    "isku": "MSKU",
    "ProductNameISKU": "商品名称&库存SKU",
    "Image": "图片",
    "bundledproduct": "已捆绑的商品",
    "selectbundle": "选择库存SKU",
    "AddBundleISKU": "添加捆绑ISKU",
    "bukadisabled": "订单处理时间遵循商店的设置",
    "pleasecheckpreorderpart": "请检查预购信息",
    "shopeedisabled": "我将在2个工作日内发货。 （不包括公共假期和快递服务的非工作日）",
    "tokodisabled": "如果需要更长的处理时间，请激活此产品的预订。",
    "UNKNOWNunittip": "渠道的时间单位在Ginee中不存在，请联系Ginee解决",
    "day": "天",
    "week": "周",
    "bukreorderdayworkday": "个工作日才能发货",
    "bukreorderday": "天才能发货",
    "bukainstanhour": "工作小时内发货",
    "bukaregular": "我将在3个工作日内发货。 （不包括公共假期和快递服务的非工作日）",
    "bukreorder": "我需要",
    "bukainstan": "我将在",
    "tokopreorderday": "天才能发货",
    "tokopreorderweek": "周才能发货",
    "tokopreorder": "我需要",
    "shopeepreorderday": "个工作日才能发货",
    "shopeepreorder": "我需要",
    "max8pictures": "最多上传8张图片",
    "max5pictures": "最多上传5张图片",
    "uploadtip": "据各渠道的限制，商品发布渠道时将按以下规则发布商品图：Tokopedia、Bukalapak、Shopify、TikTok Shop、Blibli会取前5张、Lazada会取前8张，Shopee会取前9张商品图",
    "regular": "定期",
    "instan": "瞬间",
    "between18hours": "必须是1-8之间",
    "between330days": "在3-30之前",
    "max13weeks": "在1-13周内",
    "shopeepreorderdayAdd": "Star、Star+、Mall 支持长达 90 个工作日",
    "between790": "应该在7-90 个工作日之间",
    "max90days": "在1-90天内",
    "preorderdeadline": "订单处理截止日期",
    "preorder": "预购",
    "novariationforlazd": "此商品无variant所以不能添加图片",
    "SafetystockHead": "设置库存限制并在商品的可用库存<=安全库存时收到邮件通知以补充库存",
    "SpareStockkHead": "在实际仓库中预留的库存总量,该库存不会在渠道中出售。",
    "WarehouseStockHead": "您仓库中的实际库存总量,仓库库存=备用库存+锁定库存+可用库存+活动库存",
    "inventorySkuHead": "默认匹配平台SKU前50个字符",
    "NameHead": "库存SKU的名称，默认获取[商品名称-规格名称]前160个字符；",
    "BrandLogoTips": "上传建议：360*140，小于2M的jpg/png/jpeg图片",
    "MobileMustErrorInfo": "电话号码必须为以8开头的9到12位数字",
    "bindnow": "立即绑定",
    "Nospacesfront": "首部不能有空格，请检查",
    "spaceforbidden": "不能有空格，请检查",
    "range": "范围：已上架、已下架、已售完、已禁卖",
    "inventorySkunotempty": "库存SKU不能为空！",
    "limitsafety": "安全库存必须在0 〜 999,999之间！",
    "mustlessthanwarehouse": "备用库存不能大于仓库库存",
    "sparelimit": "备用库存必须在0 〜 999,999之间！",
    "mustbiggerthanspare": "备用库存不能大于仓库库存",
    "warehouselimit": "仓库库存必须在0 〜 999,999之间！",
    "masterSkuMost200": "主SKU最多200个字符",
    "masterSkuMost50": "主SKU最多50个字符",
    "most50": "库存SKU最多50个字符",
    "most160": "名称最多300个字符",
    "clicktoedit": "点击可编辑",
    "alreadyexist": "库存SKU已存在，规格的SKU会绑定该库存SKU",
    "generaterules": "库存SKU默认生成规则：1、库存SKU会默认取SKU前50个字符。2、仓库库存会默认等于规格的库存，安全库存默认为空。3、生成库存SKU时将会绑定规格的SKU。",
    "channel": "渠道",
    "Operationguide": "操作指导",
    "storeexplain": "将一个店铺的商品生成库存SKU，以便管理商品在多店的库存",
    "bystoremodal": "按店铺生成库存SKU",
    "ImportByStore": "按店铺导入",
    "Gotit": "知道了",
    "Safetystock": "安全库存",
    "SpareStock": "备用库存",
    "WarehouseStock": "仓库库存",
    "inventorySku": "MSKU",
    "Name": "名称",
    "image": "图片",
    "GenerationRules": "生成规则",
    "ClickContenttoEdit": "点击表格内容可编辑",
    "generatecount": "{productSum}个商品（{variationSum}个规格，其中{availableNum}个可创建Ginee商品）。已绑定Master SKU的规格不能再创建Ginee商品。",
    "generateisku": "生成库存SKU",
    "draftorfialed": "请只选择草稿或发布失败的商品",
    "uploadlimit": "最大文件 ：xlsx，xls仅5MB.单次最多可导入1000个商品",
    "canbedownloaded": "个商品",
    "selectedstatus": "当前所选商品状态：",
    "donwloadtemp": "下载导入模板",
    "DownloadFailedData": "下载失败数据",
    "importfail": "部分数据导入失败",
    "importfinish": "数据导入成功",
    "Cancel": "取消",
    "fileerror": "导入文件格式不正确",
    "close": "关闭",
    "importing": "导入中",
    "importlabel": "上传文件",
    "upload": "上传",
    "download": "下载",
    "templatetip": "下载模板",
    "import": "导入",
    "themaxtip2": "条数据，相同手机号的客户将被合并",
    "themaxtip1": "提示：单次最多导入",
    "ActionsSuccessInfo": "操作成功",
    "ShopeeTips": "点击开始同步，您将跳转到Shopee的页面进行登录授权",
    "AlreadyChecked": "已选中{selected}",
    "SubscriptionExtended": "订阅可延长使用，请点击“订阅”或 联系我们 来升级您的账户",
    "SubscribeSuccess": "订阅成功即可开通高级功能，请点击“订阅”或 联系我们 来升级您的账户",
    "SubscriptionExpired": "抱歉，您的套餐已过期，请点击“订阅”或 联系我们 来升级您的账户",
    "PayNow": "马上付费",
    "ThisFeatureIsOnlyPaidUsers": "该功能仅对付费用户开放，请付费后在使用此功能",
    "FoundANewGreatFeature": "发现了一项新功能",
    "urlplaceholder": "请输入店铺名称",
    "urltip": "名称限制2-30个字符 只允许使用小写字母和数字",
    "urldesciption": "请创建店铺的会员地址",
    "memberurlmodaltitle": "会员页地址",
    "CategoryLevel3": "三级类目",
    "CategoryLevel2": "二级类目",
    "RichTextDescription": "在此处输入您的描述并将其应用于您的产品",
    "PleaseInput": "请输入",
    "selectall": "选择全部",
    "noauth": "您的账户没有此权限，如有问题请联系店铺高级管理员升级；",
    "NoProductYet": "未添加商品",
    "SeeMore": "查看{ more }个以上的变化",
    "SeeMoreExpand": "查看更多",
    "SaveAndNext": "保存&下一步",
    "NoHaveAccount": "还没有账号？",
    "Requiredfields": "必填的字段不能为空",
    "Somerequiredfields": "部分必填字段为空，请填写",
    "Removetheseproducts": "移除这些商品",
    "GotoShippingSettings": "前往物流设置",
    "Somewithoutshipping": "某些商品没有运输选项所以将无法保存。在编辑产品之前，请打开更多的运送选项；",
    "Discardcontent": "放弃更新后，这个商品的信息会同步成渠道店铺一样",
    "Discardtip": "你确定要放弃这次更新吗？",
    "with": "替换成",
    "Changepriceto": "直接将价格改成",
    "Reducebypercent": "按百分减少",
    "Reducebyamount": "按金额减少",
    "Addbypercent": "按百分比增加",
    "Addbyamount": "按金额增加",
    "Basedoncurrentprice": "按现有价格",
    "replace": "将",
    "addaftername": "名称结尾添加",
    "addbeforename": "名称开头添加",
    "Changestockto": "直接将库存改成",
    "Example": "示例：",
    "errsize": "必填的字段不能为空",
    "BukalapakPrice": "价格的区间是RP 1 ～ Rp1.000.000.000",
    "phLazadaPrice": "价格的区间是 1 ～ 1.000.000.000",
    "LazadaPrice": "价格的区间是 1000 ～ 1.000.000.000",
    "TokopediaPrice": "价格的区间是RP 100 ～ Rp1.00.000.000",
    "phShopeePrice": "价格的区间是 2 ～ 1.000.000.000",
    "ShopeePrice": "价格的区间是 99 ～ 1.000.000.000",
    "pricetipBukalapak": "价格的区间是RP 1 ～Rp1.000.000.000",
    "pricetipShopee": "价格的区间是RP 99 ～Rp1.000.000.000",
    "errdescriptionBukalapak": "描述必须介于30到3000个字之间",
    "errdescriptionShopee": "描述必须介于30到3000个字之间",
    "errnameBukalapak": "商品名称必须是6到100个字符",
    "errnamelazada": "商品名称必须是2到255个字符",
    "allemptyorfilled": "长宽高应该都为空或都有值",
    "errnameShopee": "商品名称必须是5到100个字符",
    "spaceskuerr": "SKU不能有空格，请检查",
    "PleaseOneShipping": "请至少设置一个物流",
    "pricetip": "此字段必须在99 〜 1.000.000.000之间",
    "stocktip": "此字段必须在0 〜 999999之间",
    "Discardchanges": "放弃修改",
    "update": "保存更新",
    "draft": "保存为草稿",
    "publish": "发布",
    "Logistic": "物流",
    "ParcelSize": "包裹尺寸大小",
    "Width": "宽",
    "Length": "长",
    "Height": "高",
    "Weight": "重量",
    "price": "价格",
    "VariantName": "规格名称",
    "parentSku": "主SKU",
    "productImg": "图片",
    "Refresh": "刷新",
    "PlatformUpdateNotification": "Ginee 更新提示",
    "WaitAMoment": "以后再说",
    "GoAuth": "去绑定",
    "NewVersionsInfo": "Shopee渠道管理2.0全新上线！快来绑定，体验更稳定的数据同步和订单操作吧！",
    "NoDataInfo": "没有可以处理的有效数据",
    "ActionsErrorInfo": "操作失败，请稍后再试",
    "and": "及",
    "PrivacyPolicy": "隐私政策",
    "TermsConditions": "使用条款",
    "agree": "通过注册，我同意",
    "BLDescription": "描述必须在30-3000字符之间）",
    "markDownImageSize": "大小必须至少为300 x 300像素，小于5M，JPG格式，PNG格式",
    "markDownAddImage": "添加图片",
    "noProductStore": "您还没有已关联的店铺，请关联店铺后再添加商品",
    "noStoreInfo": "您可以关联您的线上店铺，马上开始商品、库存、订单的同步吧",
    "noMemberInfo": "当客户注册会员后，您可在此处维护他们的详细信息和积分变动，您也可以手动添加会员",
    "noProductInfo": "您还没有创建商品，马上去添加您的第一件商品吧",
    "TextDescription": "文本描述",
    "memberInfo": "会员详情页",
    "jdDisableTips": "暂时无法绑定",
    "Coming": "即将上线",
    "editPointRuleInfo": "您确定要编辑积分规则吗？",
    "previewImg": "预览",
    "addRule": "添加规则",
    "methodTypeAll": "全部",
    "sendSuccess": "验证码发送成功",
    "editPointsRule": "编辑积分规则",
    "pointeReduceErrorInfo": "并非所有选定的用户都有足够的积分扣除",
    "ruleFormatErrorInfo": "只允许使用小写字母和数字",
    "rewardDeductPoints": "积分变动",
    "alreadyExists": "{ name }已注册",
    "signNext": "下一步",
    "copySuccess": "拷贝成功: { copyUrl }",
    "deleted": "已删除",
    "rule": "规则",
    "noName": "无名字",
    "copyUrl": "拷贝",
    "linkInfo": "地址",
    "laseSevenDaysInfo": "最近7天新增的成员数",
    "editimage": "编辑规格图片",
    "packageContentplaceholder": "少于1000个字符",
    "packageContent": "包裹内容",
    "VariantImage": "规格图片",
    "brand": "品牌",
    "brandLogo": "品牌商标",
    "brandName": "品牌名",
    "pointsHistory": "积分历史记录",
    "editShopInfo": "编辑店铺信息",
    "alreadyShopOwn": "恭喜！您已经拥有自己的商店。",
    "shopSetInfo": "店铺信息",
    "contentWrapper": "内容",
    "newSections": "新模块",
    "accumulatePoints": "累积积分",
    "chooseCardBg": "主题",
    "shopLogo": "店铺商标",
    "shopName": "店铺名",
    "membershipPageSettings": "会员页面配置",
    "uploadImageError": "文件 { fileName } 图片尺寸过小，最小{limitMax}x{limitMin}",
    "uploadInfo": "要求：{maxWH} * {maxWH}, 小于{isLt}M，格式支持jpg, png, jpeg",
    "enterPointMax": "可输入的最大值是999999",
    "enterPointNum": "输入一个正整数",
    "updateSuccessFul": "更新成功",
    "pointsType": "积分类型",
    "redeemablePoints": "有效的积分数",
    "reason": "原因",
    "deductPoints": "抵扣积分",
    "rewardPoints": "奖励积分",
    "joinedMethod": "添加会员的方式",
    "dateOfBirth": "出生日期",
    "remarksErrorInfo": "备注最多30个字符",
    "editDetailInformation": "Edit Detail Information",
    "editMemberInformation": "Edit Member Information",
    "profileDetails": "详细信息",
    "accountInformation": "会员信息",
    "rewardCondition": "奖励条件",
    "customizeAlertInfo": "自定义积分规则会显示在会员页上，但给予积分的方式是手动的",
    "customizePointRules": "自定义积分规则",
    "defaultAlertInfo": "默认积分规则开启状态下，用户完成对应的操作会自动分配积分",
    "defaultPointRules": "默认积分规则",
    "joinMemberTime": "成为会员的时间",
    "inputNameOrMobile": "输入名字或手机号",
    "editPoints": "编辑积分",
    "addNewMember": "添加新会员",
    "totalPage": "总共{ total }条数据",
    "description": "描述",
    "points": "会员积分",
    "memberNo": "会员编号",
    "mobile": "手机号",
    "shopname": "店铺名称",
    "name": "名字",
    "genderFemale": "女",
    "genderMale": "男",
    "gender": "性别",
    "deleteRuleInfo": "确定删除规则？",
    "status": "状态",
    "checkBoxTableSelected": "已选中 { selected }",
    "create": "创建",
    "dashboardPointCumulativeRedeem": "累计使用积分",
    "dashboardPointCumulativeGiven": "累计发放积分",
    "dashboardPointToRedeem": "可用积分",
    "dashboardNewMember": "新会员数",
    "dashboardAllMember": "会员总数",
    "hello": "Hello, world!",
    "mobileErrorInfoLength": "电话号码必须为9到12位数字",
    "fullnameerror": "店铺名称应该是 1～30个字符",
    "fullname": "店铺名称",
    "mobileplaceholder": "电话号码必须为11位数字",
    "mobileErrorInfochina": "手机号错误",
    "mobileErrorInfo": "必须从8开始",
    "nameErrorInfo": "名称限制2-30个字符",
    "emailErrorInfo": "邮箱格式不正确",
    "resetPwdSuccess": "重置密码成功",
    "resetPwd": "重设密码",
    "alreadyInfo": "已经有Ginee 账户？",
    "applyConfirm": "确定",
    "forgotPwd": "忘记密码",
    "codeSendSuccess": "验证码已经通过电子邮件发送到{ email }",
    "noReceiveCountDown": "没有收到验证码吗?",
    "waitCountDown": "请等待{ someone }s倒计时",
    "resendCode": "重新发送",
    "sendCode": "发送",
    "signBy": "通过注册，我同意条款和条件以及隐私政策。",
    "verificationCode": "输入验证码",
    "verification": "验证",
    "userName": "名称",
    "password": "密码",
    "eMail": "邮箱",
    "signUp": "立即注册",
    "login": "立即登录",
    "welcomeRegister": "欢迎来到Ginee",
    "welcomeLogin": "欢迎来到Ginee",
    "welcome": "欢迎来到Ginee",
    "copyProduct": "克隆商品",
    "orderDetail": "订单详情",
    "productDetail": "商品详情",
    "status_unpublished": "未发布",
    "status_update_failed": "发布失败",
    "status_delete": "已删除",
    "status_draft": "草稿",
    "status_disabled": "已下架",
    "status_banned": "已禁卖",
    "status_sold_out": "已售完",
    "status_live": "已上架",
    "status_process": "发布中",
    "status_all": "全部",
    "staffDnotExists": "该员工账户无权限，请联系商户添加权限（前往员工列表-添加员工-添加该账户）",
    "fastPrinting": "高速打印",
    "printPluginHasBeenUpdatedPlease": "打印插件已更新，请重新下载",
    "afterUnbindTheStockWillNot": "解绑后，库存推送不会推送到该渠道商品。",
    "areYouSureYouWantTo": "确定进行解绑吗？",
    "MSKUAutoBindSettingTip": "点击这里可链接到设置自动绑定主商品的页面。",
    "masterSkuInventoryInventorySynchronizationOf": "主SKU库存：暂不支持Lazada多仓商品的库存同步，如有需要，请前往卖家中心修改。",
    "selectGenieCategory": "选择主类目",
    "dayDuration": "期限（天）",
    "unbindedChannelVariant": "未绑定渠道规格",
    "deleteSkuConfirm": "你确定要删除SKU吗？",
    "thirdpartyWarehouseProductDetails": "第三方商品明细：",
    "thereAreThirdpartyWarehouseProductAmong": "所选商品中，存在第三方仓库商品，无法删除。",
    "canNotDeleteThirdStock": "商品在第三方仓库存在库存，不允许删除",
    "skuAffectedOutbound": "共有{val}个出库单、入库单将进行商品作废操作，是否作废出库单、入库单并删除主SKU和库存数据？",
    "deleteSkuAlert": "如果主SKU有未完成的出库单、入库单，系统会将出库单中的商品进行作废处理，同时会将主商品、库存数据删除。",
    "proceedToDelete": "作废并删除",
    "productAffectedOutbound": "共有{val}个出库单、入库单将进行商品作废操作，是否作废出库单并删除主商品和库存数据？",
    "nextStep1": "下一步",
    "deleteProductAlert": "如果商品有未完成的出库单、入库单，系统会将出库单中的商品进行作废处理，同时会将主商品、库存数据删除。",
    "iNeed": "我需要",
    "youCanImportDataFormat": "你每次最多导入10,000条数据（格式：xlsx，xls）去批量编辑主商品名称、商品描述、主类目",
    "WorkingDaysBetween": "在7-90工作日内",
    "workingDays": "工作日",
    "withoutMasterSku": "仅未绑定主SKU",
    "processTime": "处理时间",
    "width": "宽",
    "whenCreatingInTheProVersion": "高级版创建时需上传必填商品字段，可用于创建主商品后再克隆到渠道售卖的场景",
    "whenAvailableStockInfo": "可用库存<=X邮件提醒",
    "weightLimit": "应在1-5,000,000之间！",
    "weight": "重量",
    "inboundLimitTips": "使用JD履约服务时，请务必填写.举例，如商品保质期期为100天，入库限制为0.5，则保质期小于50天不支持入库",
    "outboundLimitTips": "使用JD履约服务时，请务必填写.举例，如商品保质期期为100天，出库限制为0.5，则保质期小于50天不支持出库",
    "inboundLimit": "入库限制",
    "outboundLimit": "出库限制",
    "shelfLife": "保质期",
    "custom": "自定义",
    "warehouseStockTip": "您仓库中的实际库存总量,仓库库存=备用库存+锁定库存+可用库存+活动库存",
    "warehouseStockLimit": "仓库库存范围是0-999,999",
    "yearly": "近1年",
    "monthly": "近1个月",
    "weekly": "近1周",
    "today": "今天",
    "warehouseStock": "仓库库存",
    "warehouseName": "仓库名称",
    "viewNow": "马上查看",
    "viewMore": "展开",
    "viewLess": "收起",
    "view": "查看",
    "videoName2": "如何管理采购成本？",
    "variationTypeInfo": "规格类型",
    "variationStatus": "规格状态",
    "variationImageSizeLimit": "可以一次上传多张图像。 图片大小最小300 x 300像素，最大为5MB（最多8张）",
    "variationImageInfo": "编辑规格图片:",
    "variationCountDesc": "绑定规格数量由高到低",
    "variationCountAsc": "绑定规格数量由低到高",
    "variationAlreadyBound": "此规格已跟主SKU绑定，请先解绑再绑定！",
    "exportLimit2": "每次最多导出50000个主商品，请重新筛选",
    "exportLimit": "请输入页数来下载商品，单次最多可导出50000个主商品。",
    "selectChannel": "选择渠道",
    "variation1": "规格",
    "variantTypeRangeIs": "规格类型1范围是1-14个字符",
    "variantTypeIsMissingPlease14": "缺失规格类型2，请检查！",
    "variantTypeIsMissingPlease": "缺失规格类型1，请检查！",
    "variantsInfoB": "10个商品（100个规格，其中50个可创建主SKU）",
    "variantsInfoA": "{productSum}个商品. （{variationSum}个规格，其中{availableNum}个可创建主SKU）。已绑定Master SKU的规格不能再创建主SKU。",
    "numberOfProducts": "{productSum}个商品（{variationSum}个规格）",
    "variantPictureLinkCannotBe": "规格图片1链接不能为空!",
    "uploadFile": "上传文件",
    "downloadTemplate": "下载模版",
    "step2Limit": "请按下载模版导入规则，你每次最多可批量上传10,000条规则（格式xlsx，xls）",
    "step2Dec": "第二步：批量导入新的自动绑定规则",
    "step1Dec": "第一步：导出所有自动绑定的规则",
    "variantOptionsLimit": "规格选项最多20个",
    "variantOptionShouldBe": "规格选项2 应是1-20个字符",
    "import10000Tip": "单次最多可导入10000条数据",
    "variantNameChannel": "渠道规格名称",
    "variantName": "规格名称",
    "variantImage": "规格图片",
    "username": "用户名",
    "useImageFor": "图片应用到",
    "used": "二手",
    "operationReason": "操作原因",
    "goToSettings": "前往设置",
    "stores": "请前往【商品设置】并开启“自动绑定主SKU”设置，再使用此功能",
    "storeSKU": "功能暂不可用",
    "channelVID": "渠道规格ID",
    "enabledAutoBind": "自动绑定主SKU设置未开启，暂无规则！",
    "editAutoBindRule": "编辑自动绑定规则",
    "updateAutoBindRules": "更新自动绑定规则",
    "exportAllAutoBindRules": "导出自动绑定规则",
    "enterStoreSKU": "请输入平台SKU",
    "bindingError": "此平台SKU已和其他主SKU绑定，请检查",
    "storeSkuLimit": "SKU最多250个字符",
    "bindMasterSku": "匹配的未绑商品将自动绑定主SKU",
    "bindedStore": "已绑店铺",
    "bindedProduct": "已绑商品",
    "autoBind": "自动绑定",
    "stepExportRules": "平台SKU",
    "variant": "规格",
    "updateStock": "去更新库存",
    "updateSafetyStock2": "更新安全库存",
    "updateSafetyStock": "导入更新安全库存",
    "updateInventoryInfo2": "请在「库存管理」更新库存",
    "updateInventoryInfo": "请通过更新已绑主SKU的库存来修改捆绑商品的库存",
    "updatedSuccessfully": "更新成功",
    "updateAvailableStock": "更新到店铺的可用库存",
    "unitPurchasPrice": "采购单价",
    "unitPurchasePrice": "商品的采购单价",
    "unbindInfo": "商品已跟主SKU解绑，所以锁定库存释放",
    "unbind": "解绑",
    "turnOnStock": "开启库存监控与同步设置，渠道商品库存初始化",
    "totalNum": "总共{val}条数据",
    "toShip": "待发货",
    "timeInfoWorkingDay": "工作日",
    "timeInfoMonth": "月",
    "thisVariationOnly": "仅本规格",
    "theSameNameIsRegardedAs": "(名称相同视为同一商品。最长300字符)",
    "theNameAlreadyExistsInTheStore": "商品名称已存在",
    "theMasterSKUDoesNotExist": "主SKU不存在，请检查!",
    "theLinkOfVariantPicture": "规格图片1的链接无效，请重新录入!",
    "theInventorySkuAlreadyExists": "该主SKU已存在！",
    "theCurrencyUnitOfTheDefault": "(默认售价和采购单价的货币单位，可为USD/IDR/PHP/THB/VND/MYR/SGD/CNY）",
    "theCategoryIDDoesNotExist": "类目ID不存在，请下载类目表并核查",
    "theBasicVersionCanImportA": "基础版可导入少量必填字段，可快速创建主SKU用于启用库存管理功能；",
    "syncInfoB": "{6787686756关闭库存同步",
    "syncInfoA": "{6787686756}开启库存同步",
    "successNumber": "成功数量",
    "successfullyUnbindInfo": "成功解绑{val}个Variation(s)",
    "successfullyBindInfo": "成功绑定 {val}个Variation(s)",
    "storeWithoutSales": "未售店铺",
    "storeUpdateResult": "店铺更新结果",
    "storeSku": "平台SKU",
    "storeNameB": "在售店铺名称",
    "storeNameA": "店铺名称",
    "storeCountDesc": "在售店铺数由高到低",
    "storeCountAsc": "在售店铺数由低到高",
    "stockSettingsStockMonitorAndSyncOn": "库存设置-库存监控与同步，开启：26-10-2020 17:17",
    "stockSettingsStockMonitorAndSyncOff": "库存设置-库存监控与同步，关闭：26-10-2020 17:17",
    "stockChannelInfo": "渠道可售卖的库存总量,可用库存=仓库库存-备用库存-锁定库存-活动库存",
    "stockChanges": "库存变动",
    "stepImportAndCreateThe": "第二步：导入创建主商品",
    "stepDownloadTheMasterCategory": "第一步：下载主类目Id",
    "step3Text": "第三步:导入绑定商品",
    "step2Text": "第二步:导出主SKU",
    "step1Text": "第一步：选择并导出要绑定主SKU的渠道商品",
    "spareStockTip": "在实际仓库中预留的库存总量将不会在渠道中出售",
    "spareStock": "备用库存",
    "sourceUrl": "来源URL",
    "sortMasterProduct": "主商品筛选",
    "someWarehouseTheWarehouseStockOf": "在部分仓库中主SKU的仓库库存不为0，请更新为0再删除。",
    "somePartUpdateFailed": "部分更新成功",
    "somePartReviewFailed": "部分审核失败",
    "someDatasImportFailed": "部分数据导入失败",
    "someCreateFailed": "部分创建失败",
    "skuStoreSku": "SKU（平台SKU）",
    "skuAlreadyExistsPleaseDeleteThe": "SKU已存在，请删除已有商品或重新命名！",
    "singlePurchasePriceOfTheProduct": "商品的采购单价",
    "singlePurchasePrice": "采购单价",
    "shouldBeBetweenLimitB": "范围是 0-1000,000,000",
    "shouldBeBetweenLimitA": "范围是0-999,999",
    "shouldBeBetweenB": "应该在1-999,999之间！",
    "shouldBeBetweenA": "输入范围是1-999,999",
    "shippingFeeInformation": "运费信息",
    "setPageTip2": "请输入有效的页数",
    "setPageTip": "选择导出页数",
    "selling": "在售",
    "selectProductBundle": "选择捆绑商品",
    "selectGineeCategory": "选择主类目",
    "selectFile": "选择文件",
    "selectedMax20": "已选中(最多20个)",
    "selected": "已选中",
    "selectCategories": "请选择类目",
    "selectAtLeastOneInventorySku": "至少选择一个主SKU",
    "editRule": "编辑规则",
    "selectAtLeastOneImage": "至少选择一张图片",
    "createDates": "创建时间",
    "seeXProducts": "查看X个商品",
    "seeMoreTips": "查看{val}个商品",
    "saveUpdate": "保存更新",
    "saveForReview": "保存为待审核",
    "savedSuccessfully": "保存成功",
    "saveAsReviewed": "保存为已审核",
    "salesTaxAmount": "销售税金额",
    "safetyStockStatus": "安全库存状态",
    "safetyStock": "安全库存",
    "rupiah": "印尼盾",
    "reviewPassed": "审核通过",
    "reviewed": "已审核",
    "reReview": "重新审核",
    "reasonForFailure": "失败原因：缺失必填信息，请补全",
    "rangeLimitA": "范围应是0-1,000,000,000",
    "rangeIsLimit": "范围是5,000,000",
    "quantityLimit": "配比数量应是1-999,999",
    "qty": "配比数量",
    "purchaseTime": "采购时长",
    "purchasePriceShouldLimit": "采购单价范围是Rp0-Rp1000,000,000",
    "purchasePriceLimit": "金额必须在0 〜 1,000,000,000之间！",
    "purchasePriceInfo": "（不用录入，出库采购价将按平均成本计算）",
    "proVersionImportToCreate": "高级版导入创建",
    "promotionStockTip": "活动库存是为Campaign或闪购促销 活动预留的总库存量",
    "promotionStock": "活动库存",
    "productType": "商品类型",
    "productsWithoutVariantsCanBeEmpty": "(无规格的商品可为空)",
    "productSources": "商品来源",
    "productNameLimit": "商品名称必须是5到300个字符",
    "productNameInventorySku": "商品名称&主SKU",
    "productInformation": "商品信息",
    "productImageLinkIsInvalid": "商品图片1的链接无效，请重新录入!",
    "productImageLinkCannotBe": "商品图片1链接不能为空!",
    "productImage": "商品图片",
    "productFailedToCreatePleaseDownload": "主商品创建失败，请下载失败数据并查看失败原因。",
    "productEditSuccess": "商品编辑成功",
    "productEditFailed": "商品编辑失败",
    "productDescriptionShouldBeCharacters": "商品长描述应是 1 - 7000 个字符",
    "productDescription": "商品长描述",
    "productCreatedSuccessfully": "商品创建成功",
    "product": "商品",
    "preorder11": "预售订单",
    "popupResultButton": "查看失败数据",
    "pleaseEnterTheAmount": "请输入金额",
    "pleaseEnterKeywordsToSearchChannelProducts": "请输入关键词以搜索渠道商品",
    "pleaseEnter": "请输入",
    "pleaseDownloadTheTemplateAndComplete": "请下载模版并补全完整的数据，再导入创建商品（每次可导入1w条数据）。",
    "pleaseChoose": "请选择",
    "partiallyFailedInfo": "部分失败，仍是已审核",
    "packageSize": "包裹尺寸大小",
    "orderToEnsureTheIntegrity": "为保证流程和统计报表数据完整性，部分主SKU不能被删除，所以此商品不可编辑规格类型。建议“引用”该商品来生成多个规格类型的主商品，再将此商品置为“暂不关注”。",
    "orderToEnsureTheIntegrityOf": "为保证数据的完整性，建议使用“暂不关注”。",
    "optionLimit": "选项必须时1-30个字符之间",
    "option": "选项",
    "operationTime": "操作时间",
    "operationGuide": "操作指导",
    "noShelfLife": "无保质期",
    "onSaleShop": "在售店铺",
    "onReview": "审核中",
    "onlyShowBindedVariations": "仅展示无MSKU的规格",
    "numVariationsSelected": "已选中 0 规格",
    "numVariations": "{val}个规格",
    "numSelected": "已选中 {val}",
    "numEditBindInformation": "{val}-编辑绑定信息",
    "nowInbound": "去入库",
    "normalProducts": "普通商品",
    "noData": "无数据",
    "noChannelVariationId": "渠道规格ID不存在！",
    "newProduct": "新品",
    "newAveragePurchasePrice": "新平均采购价",
    "newAverageCostPrice": "新平均成本价",
    "new": "全新",
    "mutiVariations": "产品有多种规格，如果您的产品有不同的颜色，尺寸，请选择此项",
    "mustBeBetween": "必须是1-8之间",
    "mskuAndstockVideo": "主商品与库存管理有什么联系？",
    "mSku": "主SKU",
    "more": "更多",
    "minPurchaseQuantityShouldBe": "最小购买数量应该是1-1000",
    "minimumPurchaseQuantity": "最小购买数量",
    "mediaSettings": "媒体管理",
    "maxNumVal": "最多{val}张",
    "maxNum": "最多{val}个",
    "maxLengthNum": "最长{val}个字符",
    "maxLengthCharacters": "最长{max}个字符",
    "maxLength14": "最长14个字符",
    "maximumIs": "(最大是1,000,000,000)",
    "maximumCharacters": "(最长7000字符)",
    "max9": "最多9张",
    "masterSkuHasBeenMatchedWith": "主SKU已跟第三方仓库商品匹配。",
    "masterProductsImage": "主商品 & 图片",
    "masterProductName": "主商品名称",
    "masterProduct": "Master商品",
    "master": "主商品",
    "massSettings": "批量设置",
    "mainPhotos": "封面图片",
    "lockedStockTip": "库存正在等待履约,但尚未发货。同步到Ginee的“新付款”或“新待支付“的订单将添加锁定库存中。",
    "lockedStock": "锁定库存",
    "liveTips": "范围：包含缺货且已上架的",
    "live": "在售商品",
    "length": "长",
    "iWillShipOutWithinX": "我将在X工作小时内发货",
    "iWillShipOutWithin22": "我将在2个工作日内发货。 （不包括公共假期和快递服务的非工作日）",
    "iWillShipOutWithin": "我将在3个工作日内发货。 （不包括公共假期和快递服务的非工作日）",
    "iskuStandsForInventorySku": "MSKU是主SKU",
    "iskuCannotBeEmpty": "主SKU不能为空！",
    "isAnUnprocessedOutboundInThe": "主SKU存在未处理的出库单。",
    "inventorySkuSpaceLimit": "主SKU含有空格或换行符，请重新命名",
    "inventorySkuLimit": "主SKU最多300个字符",
    "inventorySkuGenRules": "Master SKU默认生成规则：\\n1、主SKU会默认取 Variation前50个字符\\n2、仓库库存会默认等于规格的库存，安全库存默认为空\\n3、生成主SKU时将会绑定规格的SKU",
    "inventorySkuExistsLimit": "主SKU存在，请重新命名",
    "instructions": "操作说明",
    "iNeedXWorkingDays": "我需要X个工作日才能发货",
    "iNeedXWeeksToShip": "我需要X周才能发货",
    "iNeedXDaysToShip": "我需要X天才能发货",
    "importToEditProduct": "导入编辑商品",
    "importToBindIntro": "此功能支持商户批量将店铺商品绑定到主SKU上，以便管理库存",
    "importToBind": "导入绑定商品",
    "importTip": "单次最多可导入1000条数据",
    "importMasterProducts": "导出主商品",
    "importFileFormatIsIncorrectInfo2": "导入文件的模版不正确，请检查",
    "importFileFormatIsIncorrect": "导入文件格式不正确,请下载导入模板",
    "importFile": "上传文件",
    "importFailed": "导入失败",
    "importAndCreateProducts": "导入创建商品",
    "importAndAddInfo": "你每次最多导入10,000条数据（格式：xlsx，xls）去批量添加主商品",
    "imageSizeLimit": "图片大小至少是300 x 300像素，最大为5MB",
    "ifItIsEmptyWillNot": "为空则未更新",
    "hour": "小时",
    "hotSale": "热销",
    "helpCenterLink": "操作指南",
    "height": "高",
    "hasBindedInfo": "这个规格已绑定主SKU ：xxxxxxxx. 请在「库存管理」更新库存",
    "generateMaster": "将一个店铺的商品生成主SKU，以便管理商品在多店的库存",
    "filter": "筛选",
    "fileRules": "最大文件 ：xlsx,xls仅5MB",
    "faqName2": "如何使用基础版导入创建主商品？",
    "faqName1": "如何使用高级版导入创建主商品？",
    "failedReason": "失败原因：商品疑似删除，请重新同步商品",
    "failedNumber": "失败数量",
    "exportUpTo": "你每次最多导出10,000条数据(格式xlsx，xls)去批量绑定商品",
    "exportTip": "请勾选要导出的商品。",
    "exportSelected": "按勾选项导出",
    "exportOrImport": "导出/导入",
    "exportByPageTip": "请输入有效的页数",
    "exportByPage": "按页导出",
    "export": "导出",
    "enterTheNameOfTheVariationForExampleColorEtc": "请输入规格名称、例如：颜色等等",
    "enterProcessTime": "输入处理时间",
    "enterPageNumber": "请输入页数来下载主商品",
    "enterOption": "请输入选项，例如：红色等等",
    "enableInventorySync": "开启库存同步",
    "editSafetyFtock": "编辑安全库存",
    "editOptionsBlue": "编辑选项-Blue",
    "editInventory": "更新库存",
    "editAvailableStock": "编辑可用库存",
    "downloadTip": "下载",
    "downloadTheMasterCategory": "下载主类目",
    "downloadTemplateInfo": "下载模版",
    "downloadProduct": "下载商品",
    "downloadFailedData": "下载失败数据",
    "discontinued": "停售",
    "disableInventorySync": "关闭库存同步",
    "descriptionRequired": "请输入您的描述并将其应用于您的产品",
    "descriptionLimit": "描述必须介于30到5000个字之间",
    "delivery": "运费信息",
    "deleteMPTips2": "主商品已删除",
    "deleteMPTips": "删除失败：{val}个主商品",
    "deleteLimit": "失败原因：请先和捆绑商品 {bundle MSKU} 解除关联,再操作删除",
    "delConfirmTitle": "你确定要删除商品吗？",
    "delConfirmContent": "删除主商品后，库存管理的主SKU也会被删除",
    "defaultPrice": "默认售价",
    "dataSources": "数据来源",
    "datasImportSuccess": "数据导入成功",
    "datasImport": "数据导入",
    "creatingProduct": "正在创建商品",
    "createProductFailed": "商品创建失败",
    "costInformation": "成本信息",
    "continueToBindInfo": "您可在Master商品列表中继续绑定渠道商品。",
    "condition": "商品保存状态",
    "columnsCannotBeEmpty": "这个选框不能为空",
    "collectByAdd": "认领创建",
    "cny": "人民币",
    "salesTaxAmountLimit": "金额必须在0 〜 1,000,000,000之间！",
    "clearance": "清仓",
    "clear": "清空",
    "checkNow": "马上查看",
    "channelVariationIdNotEmpty": "渠道规格ID不能为空！",
    "channelVariationId": "渠道规格ID",
    "channelSellingStatus": "渠道销售状态",
    "channelProductsImage": "渠道商品&图片",
    "channelProducts": "渠道商品",
    "channelProductName": "渠道商品名称",
    "changeTo": "改成",
    "changeReason": "变更原因",
    "changeIsku": "去改绑主SKU",
    "ceneratedFromChannelProduct": "据渠道生成",
    "categoryID": "类目ID",
    "category": "类目",
    "cancelUpdateConfirm": "你确定要放弃这次更新吗？",
    "bundledProduct": "已捆绑的商品",
    "bundle": "捆绑",
    "boundInfo": "已绑定",
    "bindProduct": "捆绑商品",
    "bindNow": "马上绑定",
    "bindInstruction": "将渠道商品和主SKU匹配完成，按下载模版将数据导入操作绑定",
    "bindingType": "绑定类型",
    "bindingInProgress": "正在批量绑定商品",
    "bindingInformation": "绑定信息",
    "bindInformation": "绑定信息",
    "bindInfoB": "l,gree{id:111234}跟{6787686756}解除绑定",
    "bindInfoA": "l,gree{id:111234}绑定{6787686756}",
    "bindHistory": "绑定记录",
    "bindedInventorySku": "已绑主SKU",
    "binded0Variation": "已绑 0 规格",
    "weightShouldBeBetween": "重量的范围应是1-5,000,000!",
    "heightShouldBeBetween": "高的范围应是1-999,999!",
    "widthShouldBeBetween": "宽的范围应是1-999,999!",
    "lengthShouldBeBetween": "长的范围应是1-999,999!",
    "betweenWeeks": "在1-13周内",
    "betweenDays": "在1-90天内",
    "between21": "在7～15之间",
    "between": "在3-30之前",
    "beBetweenWorkingHoursMust": "范围应是1～8工作小时",
    "basicVersionImportToCreate": "基础版导入创建",
    "theSameNameWillConsideredAs": "(同名名称则视为同一商品.名称最长300字符)",
    "categoryName": "类目名称",
    "basicInformation": "基本信息",
    "variantPictureLink": "规格图片1链接",
    "barcodeLimitC": "条形码仅支持字母,数字和-_和不应该超过100个字符",
    "productImageLink1": "商品图片1链接",
    "barcodeLimitB": "条形码应该不超过100个字符",
    "barcodeLimitA": "条形码仅支持字母,数字和-_",
    "temporaryNotFollowingXProducts": "已暂不关注{X}个商品",
    "thisBarcodeIsAlreadyOccupiedBy": "条形码已被{MSKU}占用，请重新输入",
    "currency": "币种",
    "barcode": "条形码",
    "averagePurchasePrice": "平均采购价",
    "averageCostPriceInfo": "平均成本价=入库总采购价/仓库库存",
    "averageCostPrice": "平均成本价",
    "variantOption1ShouldBe": "规格选项1 应是1-20个字符",
    "variantOption1": "规格选项1",
    "variantOption2": "规格选项2",
    "variantTypeIs2": "规格类型2",
    "variantTypeIs": "规格类型1",
    "unspecifiedProductsCanBeEmpty": "无规格商品可为空",
    "availableStockTip": "渠道可售卖的库存总量,可用库存=仓库库存-备用库存-锁定库存-活动库存",
    "availableStockLimit": "可用库存不能为空",
    "availableStockInputLimit": "仓库库存≥备用库存",
    "availableStockInfo": "可用库存=仓库库存-备用库存-锁定库存-活动库存，请通过更新仓库库存来编辑可用库存",
    "availableStock": "可用库存",
    "availableForDownloadCategory": "(下载类目可获取)",
    "autoMatchVariationInfo": "创建Master SKU后，Master SKU将自动绑定渠道SKU与Master SKU完全匹配的规格",
    "articleName2": "渠道商品如何绑定主SKU？",
    "applyToAllVariation": "应用到全部规格",
    "aOfb": "1-50页,共219",
    "allVariation": "全部规格",
    "missingVariantPleaseCheck": "缺失规格选项1，请检查！",
    "allUpdateFailed": "全部更新失败",
    "max1000": "最大1000",
    "missingMasterProductVariantRed": "主商品缺失规格：red/s、red/L\n需补全才可创建。",
    "massOperationHistory": "批量操作记录",
    "publishToStore": "发布到店铺",
    "chooseDestinationStore": "选择目标店铺",
    "selectStore": "选择店铺",
    "nextStep": "下一步",
    "orderProcessingTimeFollowsTheShops": "订单处理时间遵循商店的设置",
    "allUpdatedSuccessfully": "全部更新成功",
    "allSuccessfullyInfo": "全部成功转为待审核",
    "allSuccessfullyCreated": "全部创建成功",
    "allReviewFailed": "全部审核失败",
    "shipTime": "处理时间",
    "allReviewed": "全部审核通过",
    "allRecordsOfChannelProductsInfo": "商品（主SKU绑定的渠道商品）的店铺库存被Ginee系统更新的全部记录（近30天）。以方便商户查证。",
    "INeed": "我需要",
    "allFailedInfo": "全部失败，仍是已审核",
    "allCreateFailed": "全部创建失败",
    "hoursWorking": "工作小时",
    "instant": "瞬间",
    "orderProcessingDeadline": "订单处理截止日期",
    "all": "全部",
    "toViewTheMassOperationRecords": "已支持查看近7天的批量操作记录，马上查看",
    "toPublishToTheStoreHow": "“发布到店铺”的操作指南",
    "toBatchEditingOfMainProducts": "“批量编辑主商品”的操作指能",
    "thePriceOfTheProductIn": "将已绑渠道商品价格同步到Ginee",
    "productSyncronized": "同步商品",
    "isRecommendedToSynchronizeThePromotion": "建议先同步渠道店铺的活动，确保商品活动价格和卖家中心保持一致",
    "promotionSync": "同步活动",
    "variationIdHasBeenBoundChannel": "已绑渠道规格id",
    "productInformationMaster": "主商品信息",
    "theInformationOfAnExistingMaster": "引用已有主商品的信息",
    "productReferenceMaster": "引用主商品",
    "copy": "复制主商品",
    "skuAutobindSettingEnabledMaster": "自动绑定主SKU设置（已开启）",
    "skuAutobindSettingNotEnableMaster": "自动绑定主SKU设置（未开启）",
    "MSKUAutoBindSettings": "自动绑定主SKU设置",
    "matchingExact": "精确匹配",
    "matchingFuzzy": "模糊匹配",
    "Choose": "选择",
    "stockWarehouse": "仓库库存",
    "afterTheSafetyInfo": "开启安全库存后，若主SKU的可用库存低于安全库存，将会发送邮件预警。",
    "nowIntegrate": "去绑定",
    "productHasBeenBoundChannel": "已绑定渠道商品",
    "stockSynchronizationSettingsEnable": "开启库存同步设置",
    "inboundToWarehouseAlready": "已放入仓库",
    "partFailedDelete": "部分删除失败",
    "allFailedDelete": "全部删除失败",
    "allSuccessfulDelete": "全部删除成功",
    "close1": "关闭",
    "productHasAOutboundListTo": "该商品已有出入库单，为保证数据完整，建议使用“暂不关注”该商品",
    "warehouseStockOfThisProductIs": "该商品部分仓库库存不为0，请先清空再删除",
    "productHasBeenBoundToA": "该商品已绑定第三方仓库的商品，为保证数据完整，建议使用“暂不关注”该商品",
    "afterTheChannelInfo": "渠道商品绑定主SKU后，店铺库存初始化",
    "failedDelete": "删除失败",
    "productBindingStatusChannel": "渠道商品绑定状态",
    "boundChannelProductAll": "全部绑定渠道商品",
    "boundChannelProductPartially": "部分绑定渠道商品",
    "allChannelProductUnbind": "全部未绑定渠道商品",
    "operationSuccessfulMass": "批量操作成功！",
    "toTheMasterProductAnd": "恢复对该主商品的关注，不再标记为“暂不关注”",
    "temporaryUntrackedDisplay": "显示暂不关注",
    "unbindMasterProductAndChannelProduct": "批量解除主商品跟渠道商品的绑定",
    "recoverMass": "批量恢复",
    "temporaryUntrackedMass": "批量暂不关注",
    "unbindMass": "批量解绑",
    "deleteMass": "批量删除",
    "group": "组",
    "reference": "引",
    "untrackedTemporary": "暂不关注",
    "combination": "组合",
    "variantMultiple": "多规格",
    "addWarehouseStockLimit": "仓库库存范围是1-999,999",
    "addVariation": "添加规格",
    "addSpareStockLimit": "库存范围是0-999,999",
    "supportSelectingMultipleStoresInThe": "仅支持选择同国家的多个店铺",
    "variantNo": "无规格",
    "theProductThatFailedToBe": "将删除失败的商品置为暂不关注，由此主商品将不再”全部“中显示",
    "masterSkuHasBeenBoundBy": "主SKU已被组合商品捆绑，请解除关联再删除。",
    "masterProductsThatAreSetAs": "全部被置为”暂不关注“的主商品（包含组合或普通类型的商品）",
    "typeOfMasterProductCombined": "组合类型的主商品",
    "productSingle": "单品",
    "theGeneralOfMasterProductType1": "普通主商品类型中，有1或2个规格类型的主商品",
    "theGeneralOfMasterProductType": "普通主商品类型中，无规格类型的主商品",
    "notIncludeTheTemporaryUntrackedMaster": "不包含“暂不关注”主商品",
    "skuHasBeenBoundByThe": "主SKU已被组合商品{bundle MSKU} 捆绑，请解除捆绑再删除。",
    "skuHasMatchedTheProductsOf": "主SKU已跟第三方仓库{第三方仓库名称}的商品匹配",
    "skuHasPendingOutboundOrderOr": "主SKU存在待处理的出库单或入库单，请完成后再删除",
    "stockOfTheMasterSkuIn": "在{仓库名称1、仓库名称2、}仓库中主SKU的仓库库存不为0，请更新为0再删除。",
    "errorPleaseRefreshThePageAnd": "数据异常，请刷新页面后重试",
    "retry": "重试",
    "productSuccessfullyCreatedMaster": "主商品创建成功！",
    "beBetweenMust": "范围1-999,999",
    "addOption": "添加选项",
    "stockMonitoringAndSynchronizationEnable": "开启库存监控与同步",
    "toWarehouseInboundAdd": "添加到仓库+已入库",
    "channelProductsRefer": "引用渠道商品",
    "failedUnbind": "解绑失败",
    "addMskuArticle": "如何添加主SKU？",
    "unbindAllFailed": "全部解绑失败",
    "allUnbindSuccessfully": "全部解绑成功",
    "partialUnbinding": "部分解绑失败",
    "unbinded": "未绑定",
    "bound": "已绑定",
    "unbindedAll": "全未绑定",
    "boundAll": "全已绑定",
    "masterSkuHasEnableStockSynchronization": "主SKU已开启库存同步，请前往库存管理修改库存。",
    "youNeedToEnableStockSynchronization": "若需开启主商品的库存同步，请继续完成添加到仓库、入库的操作。",
    "toProductListReturn": "返回商品列表",
    "failedDataDownload": "下载失败数据",
    "createFailedAll": "全部创建失败！",
    "addManually": "手动创建",
    "masterProductSuccessfullyCreatedIfYou": "部分主商品已创建成功！若需开启主商品的库存同步，请继续完成将主商品添加到仓库、入库的操作。",
    "failedToCreateSome": "部分创建失败！",
    "masterProductView": "查看主商品",
    "successfullyCreatedAll": "全部创建成功！",
    "productSuccessfullyCreatedIfYouNeed": "主商品已创建成功！若需开启主商品的库存同步，请继续完成将主商品添加到仓库、入库的操作。",
    "productCanOnlyUseOneCurrency": "商品仅能用一种货币，请重新选择！",
    "creatingTheMasterProductCanCompleted": "创建主商品后再完成入库，才会开启主SKU的库存同步。\n开启后，主SKU和渠道店铺的库存可自动更新、同步。",
    "masterSkuHasBeenPlacedIn": "组合主SKU已放入的仓库",
    "warehouseDefault": "默认仓库",
    "toWarehouseAdd": "添加到仓库",
    "toStoreClone": "克隆到店铺",
    "enableStockSynchronizationOfMasterProduct": "若需开启主商品的库存同步，请继续完成添加到仓库、入库的操作。若需快速将主商品发布到渠道店铺售卖，请使用“克隆到店铺”。",
    "quicklyPublishTheMasterProductsTo": "若需快速将主商品发布到渠道店铺售卖，请使用“克隆到店铺”。",
    "informationWarehouse": "仓库信息",
    "inbounded": "已入库",
    "inboundNot": "未入库",
    "skuHasBeenPlacedInInbound": "主SKU已放入的仓库",
    "statusStock": "库存状态",
    "addByStore": "按店铺创建",
    "addBundleProduct": "添加捆绑商品",
    "oneCurrency": "商品仅能用一种货币，请检查！",
    "variantSingle": "单规格",
    "productsOfVariants": "10个商品（20个规格，其中3个可创建Ginee商品）。已绑定Master SKU的规格不能再创建Ginee商品。\n点击表格内容可编辑",
    "synchronizationOfTheMasterSkuWill": "创建主商品后再完成入库，才会开启主SKU的库存同步。开启后，主SKU和渠道店铺的库存可自动更新、同步",
    "toTheNChannelsProductList": "马上前往{n}渠道的商品列表",
    "channelsProductList": "前往渠道的商品列表：",
    "goNow": "马上前往",
    "goToTheChannelProductList": "请前往渠道商品列表-并勾选要引用的渠道商品。",
    "doNotHaveAnIntegratedStore": "您暂无授权店铺，请先去授权",
    "toModifyGo": "前往修改",
    "skuHasBeenInboundToThe": "该主SKU已放入仓库，请前往【库存管理】修改可用库存",
    "productCombined": "规格类型已存在，请重新输入",
    "combinedProductAdd": "组合商品",
    "theContentToEditClick": "添加组合商品",
    "combinationProductsEdit": "编辑组合商品",
    "preview": "预览",
    "atTheEndAdd": "结尾添加",
    "increaseStartingValueAutomatically": "自动增长开始值",
    "atTheBeginningAdd": "开头添加",
    "xxWithYyyReplace": "将xx替换成yyy",
    "massEditSKU": "批量编辑Master SKU",
    "changeStockTo": "将库存改成",
    "massEditStock": "批量编辑库存",
    "applyToAllProducts": "应用到全部商品",
    "masterDeleteFailedTip": "商品已被成功从{仓库名称1}、⁣{仓库名称2} 仓库移出.",
    "masterStockpdateFailed": "已入库的主SKU的库存更新失败，请前往库存管理修改",
    "stockLimit": "库存范围应该是0-999,999!",
    "reduceByPercent": "按百分比减少",
    "addByPercent": "按百分比增加",
    "reduceByAmount": "按金额减少",
    "addByAmount": "按金额增加",
    "massEdit": "批量编辑",
    "mskuNoSpaces": "主SKU不能含有空格，请更新",
    "followingXProductsRestore": "已恢复{X}个商品的关注",
    "productsAreSetAsTemporary": "已暂不关注{X}个商品",
    "masterSkuDoesNotMeetThe": "主SKU不符合被删除条件，因此不可删除规格类型和规格选项",
    "barcodeExist": "条形码已被{MSKU}占用，请重新输入",
    "massEditPrice": "批量修改价格",
    "changePriceTo": "将价格改成",
    "replaceWith": "替换为",
    "masterSkuLimit": "主SKU最多200个字符",
    "numberOfVariants": "规格数量",
    "yourCurrentPackageDoesNotHave": "您当前套餐无权限操作，请订阅新套餐或联系客户升级套餐",
    "yourNumberOfOrdersHasExceeded": "您的订单数已超出生效套餐的限制（最多{X}），请订阅新套餐或联系客服升级套餐。",
    "salesTaxAmountTip": "单个商品的税费",
    "purchaseTimeTip": "发起采购到采购入库的时间周期",
    "sourceUrlTip": "商品的来源，如供应商的连接或者采集商品的来源链接",
    "chatLimitTip2": "您的权限已超出套餐限制，请前往Ginee OMS调整后再继续使用Ginee chat",
    "chatNoResourcesLimitTip": "您的店铺数或员工账户数已超出套餐限制，请前往Ginee OMS调整后再继续使用Ginee chat",
    "chatNopermissionLimitTip": "您的账户套餐未包含Ginee chat 聊天功能，请联系客服升级套餐后再重新登录。",
    "packageLimit": "套餐限制",
    "masterProductpublishToTheStore": "主商品/发布到店铺",
    "masterProductmassEdit": "主商品/批量编辑",
    "stockStatusTip": "本列的库存状态展示：仓库、库存同步状态、主SKU的类型信息。\n仓库：此主SKU已放入的仓库。\n库存同步状态：此主SKU是否已开启库存同增减，新增的主商品的“库存同步状态”默认为关闭。\n组合主SKU标记：若此主SKU是组合主SKU则展示“组合”标记。",
    "synchronizationIsNotEnabledYouNeed": "关：库存同步未开启\n开启所需要的条件尚未达成：",
    "synchronizationIsEnabledStock": "开: 库存同步已开启\n开启所需要的条件都已达成：",
    "stockSynchronizationStatusOfTheMaster": "若主SKU的库存同步状态是未开启，则该主SKU不会开启库存同增减的功能。",
    "stockSynchronizationStatusOfTheCombined": "若组合MSKU的库存同步状态是未开启，则此组合MSKU不会自动根据捆绑的主SKU自动计算可用库存、并且不会开启库存同步功能。",
    "open": "开",
    "settingsManagementForAuthorizedThirdpartyAccounting": "已授权的三方财务软件配置管理",
    "accountingSettings": "财务配置",
    "addedBy": "添加人",
    "timeAdded": "添加时间",
    "updater": "更新人",
    "checkLog": "查看日志",
    "accurateOrder": "Accurate订单",
    "accurateProduct": "Accurate商品",
    "failedToGetChannelsLabelUrl": "渠道面单获取失败，请重新推送",
    "bindWarehouse": "绑定仓库",
    "Prohibited": "禁用",
    "interval": "区间",
    "productSales": "商品销量",
    "updatetime": "更新时间",
    "masterProductCreationTime": "主商品的创建时间",
    "channelProductCreationTime": "渠道商品的创建时间",
    "salesOfDifferentActivityLevelsOverlap": "不同活跃度销量出现重叠，请重新输入",
    "salesRangeOfXCannotBe": "{x}的销量范围不能高于{y}",
    "channelProductDimensionActivitySetting": "渠道商品维度设置活跃度，目前暂时只支持Shopee",
    "synchronizeallthexxinthewarehousewheremskusarelocatedtochannelskusaccordingtothexxxratiowhenthedefaultruleisenabledmskusthatalreadyexistinotherrulesarenotincluded": "将全部MSKU所在仓库的的和按照{X}比例同步至所有的渠道SKU，当默认规则开启时，不包含已在其他规则中存在的MSKU",
    "availablestockforotherstoresispushedto": "其他店铺的可用库存推送为0",
    "allExceptxx": "除了{X}的全部",
    "whentheavailablestockislessthanorequaltoxx": "可用库存小于等于{X}时",
    "whenmsku": "当MSKU",
    "stockThreshold": "库存阈值",
    "availablestocktransferstock": "可用库存+在途库存",
    "availablestock": "可用库存",
    "pleaseSelectawarehouse": "请选择仓库",
    "addPolicy": "添加策略",
    "pushratio": "推送比例",
    "warehousename": "仓库名称",
    "pushbyfixedvalue": "按固定值推送",
    "pushbybatio": "按比例推送",
    "setSyncPolicy": "设置同步策略",
    "setSynchronizationconditions": "设置同步条件",
    "selectMsku": "选择MSKU",
    "addMsku": "添加 MSKU",
    "ruleNamecannotbeempty": "规则名称不可为空",
    "pleaseenterarulename": "请输入规则名称",
    "executeRules": "执行规则",
    "pushLog": "推送日志",
    "default": "默认",
    "defaultRules": "默认规则",
    "storeName1": "店铺名称",
    "stockType": "库存类型",
    "pushType": "推送类型",
    "mainStoreStockLocked": "主店铺库存锁定",
    "log": "日志",
    "addrule": "添加规则",
    "stocklistpromptwhenadjustingstockstockadjustment": "【库存清单】调整库存时提示：库存调整[(+)/(-)X]",
    "promptwhenaddingstockwithmanualinboundmanualinbound": "手动入库单增加库存时提示：手动入库{121133}",
    "whenmanuallypushinginventorymanuallyexecutestockpushrules": "手动推送库存时：手动执行库存推送规则",
    "promptwhentheordercomesinneworder": "订单进来时提示：新增订单{576512290177977223}",
    "promptwhenorderiscancelledordercanceled": "订单取消时提示：订单取消{576512290177977223}",
    "promptwhenbindingforthefirsttimeenablestockmonitoringandsyncsettingsthenpushdatatostoresstock": "初次绑定时提示：开启库存监控与同步设置，店铺库存初始化",
    "xxofchannelproductsfailed": "渠道商品失败XX个",
    "creationtime": "创建时间",
    "pushquantity": "推送数量",
    "reasonforchange": "变更原因",
    "seemore": "查看更多",
    "imageMasterProduct": "图片&主商品",
    "partialSuccess": "部分成功",
    "storeupdateresults": "店铺更新结果",
    "pleaseentersearchcontent": "请输入搜索内容",
    "storepushrecord": "店铺推送记录",
    "stockValue": "库存价值",
    "isBestSellingItem": "为爆款",
    "isTrendingItem": "为旺款",
    "isNewItem": "为新款",
    "isLowSellingItem": "为滞销款",
    "isNormalItem": "为平款",
    "mskuXAlreadyExistsInRule": "MSKU：{X}已在{Y}规则中存在",
    "ruleNameAlreadyExists": "规则名称已存在",
    "thereAreDuplicateRuleNameOr": "存在重复的规则名称或主商品",
    "noteWhenTheInventoryThresholdIs": "注意：库存阈值开启时，当“同步策略”中的库存小于等于“阈值”时，除了主店铺，其他店铺的可用库存推送为0",
    "totalCapitalUsedFromAllWarehouses": "所有仓库总资金占用",
    "storeIntegrationIsInvalidPleaseReintegrate": "店铺授权失效请重新授权",
    "mskuAndStoreAreNotBound": "MSKU和店铺绑定失效",
    "warehouseAndStoreAreNotBound": "仓库和店铺未绑定",
    "warehouseAndMskuAreNotBound": "仓库和MSKU未绑定",
    "productChannelAndMskuAreNot": "渠道商品和MSKU未绑定",
    "deleteRule": "确定要删删除{X}规则？",
    "ruleNameXChangedToY": "规则名称：由{x}变更为{Y}",
    "pushTypeChangeFromFixedValue": "推送类型：由固定值推送变更为按比例推送，按{X}仓库+{y}仓库的可用库存的{X}%推送",
    "pushTypeChangeFromProportionalPush": "推送类型：由按比例推送变更为固定值推送，固定值为XXX",
    "warehouseNameWarehouseXHasBeen": "仓库名称：有{X}仓库变更为{Y}仓库",
    "addRuleNewRule": "添加策略：新增策略",
    "statusChangeStatusHasBeenChanged": "状态变更：状态由关闭变更为开启",
    "statusChangeStatus": "状态变更：状态由开启变更为关闭",
    "deleteRuleRuleHasBeenDeleted": "删除策略：删除了策略",
    "ruleChangeTheTotalOfStock": "策略变更：{X}仓库的可用库存的和从{X}%变更为{Y}%",
    "policyChangeTheAvailableStock": "策略变更：{X}仓库的可用库存+在途库存从推送比例{X}%变更为{Y}%",
    "editAddedXMskuThresholdIs": "编辑：添加{X } MSKU，阈值为{X}，选择主店铺为：{Y}",
    "editXMskuThresholdChangedFrom": "编辑：{X} msku阈值从{Y}变更为{Z}",
    "editXMskuMainStoreHas": "编辑：{X}msku主店铺从{X}变更为{Y}",
    "editXMskuHasBeenDeleted": "编辑：{X} msku已被删除",
    "afterDeletionThePolicyNeedsTo": "删除后，需重新配置策略，请慎重操作",
    "thirdpartyWarehouseReceptionTime": "三方仓接收时间",
    "whetherToReplaceProduct": "是否更换商品",
    "whetherItIsAGift": "是否赠品",
    "addGiftBForOrder": "订单{A} 添加赠品{B}，数量为{C}，所选仓库为{D}",
    "orderAWithProductBIs": "订单{A} 商品{B}更换为{C}，所选仓库为{D}",
    "changeSelectedWarehouseCToD": "订单{A}商品{B}所选仓库由{C}更换为{D}",
    "gift": "赠品",
    "specifiedWarehouse": "指定仓库",
    "specifiedQuantity": "指定数量",
    "massAddForOrdersSpecifiedMsku": "针对订单批量增加：指定MSKU及数量！",
    "replace1": "更换",
    "and1": "且",
    "replaceInBatchForTheSpecified": "针对订单下的指定商品，批量进行替换！",
    "changeWarehouseTo": "更换仓库为",
    "specifiedProduct": "指定商品",
    "doWarehouseModificationForSpecifiedProduct": "针对订单下的指定商品，进行仓库修改！（修改的商品必须要关联对应的仓库）",
    "doWarehouseModificationForAllProduct": "针对订单下的所有商品，进行仓库修改！（修改的商品需关联对应的仓库）",
    "byProduct": "按商品",
    "byOrder": "按订单",
    "processingMethod": "处理方式",
    "addGift": "添加赠品",
    "replaceProduct": "更换商品",
    "changeWarehouse": "更换仓库",
    "modifyProductInformation": "修改商品信息",
    "weightkg": "重量(kg)",
    "youMustAddAllTheVariation": "请务必添加全部规格图片或将全部规格图片置空",
    "masterVariationName": "主商品规格名称",
    "operationSuccessPrompt": "操作成功提示",
    "checkProduct": "去查看商品",
    "productHasBeenSubmittedAndIs": "商品已提交，等待{channel}平台处理，您可在发布中、已上架或\n者发布失败中查看商品。",
    "minimumStockLimit": "最小库存范围应是0-999,999",
    "noNeedToProvideAnyTransport": "不需要提供任何运送服务，例如物流凭证、现场服务、提货点服务等。",
    "thisTypeCanBeSelectedTo": "选择此类型可以运送重量超过50kg，或需要进行安装等特殊处理的产品。",
    "notShipped": "不运输",
    "sellerShipping": "卖家运输",
    "shipYourOrderToTheBLIBLI": "将您的订单发送给Blibli指派的物流合作伙伴。 对于Sameday / Instant，订单将在取货点取货。",
    "shippingUsingBlibliLogisticPartner": "通过Blibli合作物流运输",
    "minimumStock": "最小库存",
    "enterSellingPrice": "请输入售价",
    "sellingPriceLimit": "原价范围应该是 1-100.000.000",
    "originalPrice": "原价",
    "enterOriginalPrice": "请输入原价",
    "changeMinimumStockTo": "将最小库存改成",
    "restrictionsTheProductCannotBeEdited": "API限制，该商品不可编辑",
    "unboundMSKU": "未绑定主SKU",
    "bindMSKU": "绑定主SKU",
    "Country": "国家/地区",
    "ContinuetoPublish": "继续发布",
    "CreateTimeNewest": "创建时间降序",
    "CreateTimeOldest": "创建时间升序",
    "notification": "提示!",
    "NoBrand": "无品牌",
    "PromotionActivity": "活动",
    "PromotionCampaign": "活动",
    "PromotionDuration": "时间",
    "PromotionPrice": "活动价",
    "PromotionOriginalPrice": "原价",
    "cannot0": "潜在买家看不到您的产品，因为库存为0",
    "EditStock": "修改库存",
    "ResultsFail": "因为网络和服务异常，批量操作已被终止，请稍后再试",
    "SomeOperationsFailed": "部分商品操作失败",
    "Sort": "排序",
    "MostStock": "库存降序",
    "LeastStock": "库存升序",
    "Oldest": "更新时间降序",
    "Newest": "更新时间升序",
    "Within7Days": "7天以内",
    "Yesterday": "昨天",
    "Today": "今天",
    "CountDraftFailed": "个商品发布失败",
    "CountDraftSuccess": "个商品发布成功",
    "CountRemoveFailed": "个商品被移除失败",
    "CountRemoveSuccess": "个商品被移除成功",
    "CountUnAvailableFailed": "个商品下架失败",
    "CountUnAvailableSuccess": "个商品下架成功",
    "CountAvailableFailed": "个商品上架失败",
    "CountAvailableSuccess": "个商品上架成功",
    "PartDraftFailed": "部分商品发布失败",
    "DraftFailed": "发布失败",
    "DraftSuccess": "发布成功",
    "PartRemoveFailed": "部分商品移除失败",
    "RemoveFailed": "移除失败",
    "RemoveSuccess": "移除成功",
    "PartUnAvailableFailed": "部分商品下架失败",
    "UnAvailableFailed": "下架失败",
    "UnAvailableSuccess": "下架成功",
    "PartAvailableFailed": "部分商品上架失败",
    "AvailableFailed": "上架失败",
    "AvailableSuccess": "上架成功",
    "InProcessDraftPublishModalTitle": "正在批量将商品发布到店铺",
    "InProcessRemoveModalTitle": "正在删除店铺的商品",
    "InProcessDisableModalTitle": "渠道店铺的商品正在下架",
    "InProcessPublishModalTitle": "渠道店铺的商品正在上架",
    "StockRulesInfo": "库存仅能输入{minNum}～999999",
    "ResultsClose": "关闭",
    "ResultsTitle": "结果",
    "ApplyToAll": "全部应用",
    "MassEditStock": "批量修改",
    "SetUnAvailable": "下架",
    "SetAvailable": "上架",
    "DeletedDesc": "在Ginee删除商品，这个商品在渠道店铺也会被删除",
    "DeletedTitle": "你确定要删除商品吗？",
    "PublicDesc": "在Ginee上架商品，这个商品在渠道店铺也会被上架",
    "PublicTitle": "你确定要上架商品吗？",
    "DisabledDesc": "在Ginee下架商品，这个商品在渠道店铺也会被下架",
    "DisabledTitle": "你确定要下架商品吗？",
    "productTime": "时间",
    "ProductSelected": "已选中",
    "ProductParentSku": "父SKU",
    "ProductNameAndStore": "商品名称 & 店铺",
    "adjustSyncFailed": "以下商品的库存同步渠道失败，请重试",
    "UpdateStock": "更新",
    "NewStock": "新库存",
    "ModifyStock": "请在库存管理中修改库存",
    "CurrentStock": "现有库存",
    "adjustStock": "调整库存",
    "categories": "类目",
    "removeList": "删除",
    "productStatus": "状态",
    "savedAsdraft": "成功存为草稿",
    "pleaseSelect": "请选择",
    "ProductDescription": "商品长描述",
    "masterCategory": "主类目",
    "ProductImage": "商品图片",
    "defaultPriceError": "默认售价范围应该是0-1000,000,000",
    "editDefaultPrice": "编辑默认售价",
    "editDescription": "编辑商品描述",
    "addAfterName": "名称结尾添加",
    "addBeforeName": "名称开头添加",
    "weightShouldBeBetweenKg": "重量的范围应是0.001-1000",
    "workingHours": "工作小时",
    "shippingSettings": "物流设置",
    "massEditProductStatus": "批量编辑商品状态",
    "goToEdit": "前往修改",
    "theInventoryOfTheMasterProduct": "主商品的库存统一在【库存管理】中管理，请前往并批量修改已选商品的库存",
    "changeMinPurchaseQuantityTo": "最小购买数量改成",
    "massEditMinPurchaseQuantity": "批量编辑最小购买数量",
    "massEditDimensionsCm": "批量编辑尺寸（cm）",
    "massEditProductName": "批量编辑商品名称",
    "editProductImage": "编辑商品图片:",
    "customDisplayInformation": "自定义展示信息",
    "logisticsChoose": "请选择物流",
    "selectionOfStoreswarehousesMass": "批量选择店铺/仓库",
    "toMatchCategoryNeed": "需匹配类目",
    "categoriesMatched": "已匹配类目",
    "productsSelected": "已选产品",
    "categoryAttributesShopee": "Shopee 类目属性",
    "categoryAttributesEdit": "类目属性",
    "theValueJustEnteredWantIt": "刚录入的值，是否应用于同类目的全部商品",
    "matchingCategoryAndVariantAttributesProduct": "匹配类目和规格属性后，可自动匹配的商品信息，如规格价格、库存等。",
    "productInfoSource": "源商品信息",
    "noLogisticOptions": "暂无物流选项",
    "sellingPrice": "售价",
    "productUpdateFailed": "商品更新失败",
    "publishFailed": "商品发布失败",
    "publishSucceeded": "商品发布成功",
    "massEditMinimumStock": "批量编辑最小库存",
    "skuLimit": "SKU应少于100字符",
    "sku": "SKU",
    "packageSizeCM": "包裹尺寸(cm)",
    "main": "主图",
    "maxval": "最多{val}张",
    "priceBetweenMy": "输入范围是0.1-1000,000,000",
    "priceBetweenTh": "输入范围是1-500,000",
    "priceBetweenVn": "输入范围是1000-120,000,000",
    "priceBetweenPh": "输入范围是5-1,000,000",
    "priceBetweenId": "输入范围是99-150,000,000",
    "weightg": "重量(g)",
    "selectABrandPlease": "请选择品牌",
    "uploadAVariantPicturePlease": "请上传规格图片",
    "uploadProductImagePlease": "请上传商品图片",
    "enterADescriptionPlease": "请输入描述",
    "enterTheProductNamePlease": "请输入商品名称",
    "selectTheStatusOptionPlease": "请选择状态选项",
    "selectPreorderOptionPlease": "请选择预售选项",
    "enterLogisticsOptionsPlease": "请输入物流选项",
    "enterThePackageSizePlease": "请输入包裹尺寸",
    "enterTheWeightPlease": "请输入重量",
    "enterstock": "请输入库存",
    "enterPrice": "请输入价格",
    "enterSKU": "请输入SKU",
    "theProductHasVariationsIfYour": "产品有多种规格，如果您的产品有不同的颜色，尺寸，请选择此项",
    "enterAOptionForExampleRed": "请选择选项，例如：红色等等",
    "addVariationType": "添加规格类型",
    "variationType": "规格类型",
    "productDescriptionLimit": "商品描述必须是6到3500个字符",
    "productDescriptionLimitTh": "商品描述必须是25到5000个字符",
    "productDescriptionLimitVn": "商品描述必须是100到3000个字符",
    "productDescriptionLimitPh": "商品描述必须是 2到3000个字符",
    "productDescriptionLimitIdMy": "商品描述必须是20到3000个字符",
    "selectBrand": "请选择品牌",
    "ShopeeCategory": "Shopee 类目",
    "addAttributeValue": "添加属性值",
    "saveUpdateAndNextStep": "保存并下一步",
    "productNameLimitVn": "商品名称必须是10到120个字符",
    "productNameLimitPh": "商品名称必须是20到100个字符",
    "productNameLimitOthers": "商品名称必须是20到120个字符",
    "productNameLimitId": "商品名称必须是5到255个字符",
    "stocktLimitOhters": "库存范围应该是0-999,999,999!",
    "stocktLimit": "库存范围应该是0-999,999!",
    "setUnavailable": "下架",
    "setAvailable": "上架",
    "saveAsDraft": "存为草稿",
    "percentageLimit": "百分比范围应是0-100,000",
    "attributesNo": "无属性",
    "categoryNo": "无类目",
    "categoryAndAttributeApply": "将相同的类目和属性选项应用于相同源类目({N})的其他产品？",
    "detailsView": "查看详情",
    "pleaseSetUpLogisticsInThe": "请先在Shopee卖家中心设置物流",
    "optionMaximum": "最长20个字符",
    "typeMaximum": "最长14个字符",
    "vietnam": "越南",
    "thailand": "泰国",
    "philippines": "菲律宾",
    "indonesia": "印度尼西亚",
    "imageMustBeJOGPNGFormat": "图片必须是JPG、PNG格式，最大4MB，分辨率：300*300px～1024*1024px",
    "imageTip": "API限制，尺寸最大4MB，分辨率必须大于300*300px",
    "preOrderLimit": "据类目要求，时间应该是{min}到{max}个工作日",
    "preOrderTip": "Star、Star+、Mall 支持长达 90 个工作日",
    "malaysia": "马来西亚",
    "dangerousGoods": "危险物品",
    "dangerousGoodsTip": "请准确填写“危险物品”。不准确的“危险物品” 可能会导致额外的运费或交付失败。",
    "dangerousGoodsOption": "包含电池/磁体/液体/易燃材料",
    "attributeTip": "API限制，最多5个选项",
    "massEditTip1": "请选择商品类目和商品属性，避免商品被发布失败",
    "theMaximumRangeAccordingToApi": "按API要求限制最大范围",
    "requiresToThisInformationToBe": "API要求此字段必填",
    "chooseTheStoreFirstPlease": "请先选择店铺",
    "variantImageTip": "尺寸：4MB，分辨率：Min.300*300px(API要求此字段必填)",
    "variationTypeTip2": "规格类型已存在，请重新输入",
    "variationoptionTip1": "规格选项已存在，请重新输入",
    "applyAttribute": "将相同的属性选项应用于相同源类目({N})的其他产品？",
    "noBrandBatch": "你确定将该列商品的品牌选项都改成“No Brand”吗？",
    "noBrandBatchTip": "因API限制，仅支持批量将品牌选择为No Brand.",
    "massEditBrand": "批量编辑品牌",
    "previous": "上一步",
    "emptyMass": "批量清空",
    "singapore": "新加坡",
    "canUploadMultiplePicturesAtOnce": "一次可上传多张、拖拽可改变图片顺序",
    "contentToEditClick": "点击表格内容可编辑",
    "Policydetail": "相同商品发布到同一渠道的多店铺时\n我们建议您更改和区分产品名称和产品信息，以避免违反相关的市场政策",
    "PolicyReminder": "政策提醒",
    "Clonesuccess": "商品克隆成功!",
    "copyFailedResult": "个商品克隆失败",
    "copyCompete": "克隆完成",
    "amountMustBeGreaterThanDiscount": "金额必须大于折扣金额",
    "wholeStoreProduct": "全店商品",
    "valueMustBeGreaterThanThe": "值必须大于上一个梯度",
    "theSumOfTheAvailableStock": "将全部MSKU所在仓库的可用库存的和，按照",
    "ratioSyncToAllChannelSku": "比例同步至所有的渠道SKU",
    "whenthedefaultruleisonmskusthatalreadyexistinotherrulesarenotincluded": "当默认规则开启时，不包含已在其他规则中存在的MSKU",
    "statusDeleted": "deleted",
    "statusInactive": "inactive",
    "statusActive": "active",
    "optionsDoNotSupportEditAnd": "由于API限制，选项不支持编辑和删除",
    "dueToApiRestrictionsLabel": "由于API限制，该分类下的规格类型里面的{ label }是必选的。",
    "dueToTheChannelDelayIn": "由于渠道更新数据延迟，编辑后的数据需要等待几秒钟才会更新，请耐心等待。如果未更新，可手动点击同步按钮或者去卖家中心中心查看是否处于审核中状态，审核中状态的数据暂时不会进行同步。",
    "deletedProductCannotBeRecoveredAre": "删除商品后将不可恢复，确认删除吗？",
    "canNotInputChineseLanguage": "不可以输入中文",
    "areYouSureYouWantToZalora": "你确定删除该变体信息吗？删除后会改变SKU的结构和价格库存信息，你需要再次输入SKU的相关信息。",
    "failedToPublishGineeProductThat": "发布失败：Ginee发布失败的商品",
    "publishingGineeProductThatAreBeing": "发布中：Ginee发布中的商品或者在卖家中心审核中的商品",
    "draftAProductDraftCreatedBy": "草稿：Ginee创建的草稿商品",
    "suspendedThisIsAProductWith": "已禁卖：这是 卖家中心Suspended状态的商品",
    "soldOutThisIsAProduct": "已售完：这是卖家中心Active状态库存为0的商品",
    "deactivatedThisIsAProductWith": "已下架：这是卖家中心InActive状态的商品",
    "liveThisIsAProductWith": "已上架：这是卖家中心Active状态的商品",
    "currencyCannotBeEmpty": "币种不能为空",
    "failedToUploadQualification": "上传资质 {0} 失败",
    "failedToUploadImage": "上传图片 {0} 失败",
    "thereCannotBeTheSameOption": "同一组规格类型下，不能有重复选项",
    "numberOfVariationsInEachGroup": "每组规格类型的数量应该为 {0}-{1}",
    "numberOfGroupsVariationMustBe": "规格类型的组数应该为 {0}-{1}",
    "qualificationCertificateOfTheCurrentProduct": "当前商品类目的资质证书为必填项，不能为空",
    "forOneTypeOfQualificationCertificate": "单种类型的资质证书，文件和图片的总数不能超过 {0}",
    "apiLimitationMaxSizeMb": "API限制，尺寸最大5MB，分辨率必须大于600*600px",
    "totalSkuMustBeBetween": "SKU总数需在1～100之间",
    "pleaseSelectTheTypeOfVariation": "请选择规格类型",
    "pleaseInputWeight": "请输入重量",
    "pleaseInputLengthWidthAndHeight": "请填写完整的长宽高信息",
    "reviewing": "审核中",
    "processing": "处理中",
    "reviewFailed": "审核失败",
    "thisCategoryDoesNotSupportCod": "该分类不支持COD",
    "skuMustBeCharacters": "SKU必须是1-50个字符",
    "warrantyPolicyMustBeCharacters": "保修政策必须是0-99个字符",
    "priceRangeShouldBe": "价格的范围应该是100-100000000",
    "stocktLimitOhtersZalora": "库存范围应该是0-99999",
    "weightShouldBeBetweenKgZalora": "重量的范围应是0.01-20",
    "heightShouldBeBetweenZalora": "高的范围应是1-35",
    "widthShouldBeBetweenZalora": "宽的范围应是1-40",
    "lengthShouldBeBetweenZalora": "长的范围应是1-60",
    "pleaseUploadASizePicture": "请上传尺码图",
    "onlySupportsImages": "只支持1:1的图片",
    "pleaseEnterAtLeastOneVariation": "请输入至少一个规格选项",
    "variationOptionMustBeCharacters": "规格选项必须是1-20个字符",
    "productDescriptionLimitIdMyZalora": "商品描述必须是1到10000个字符",
    "fileSizeCannotExceedM": "文件大小不能超过10M",
    "onlySupportToUploadFilesIn": "只支持上传pdf格式文件",
    "onlySupportToUploadImagesWith": "只支持上传jpg，png，jpeg格式图片",
    "imageMustBePxpx": "图片需在600*600px - 20000*20000px",
    "pictureSizeCannotExceedM": "图片大小不能超过5M",
    "pleaseUploadAtLeastOneImage": "请至少上传一张图片或pdf文件",
    "supportCOD": "支持COD",
    "sizeChart": "尺码图",
    "pleaseSelectTheVariantTypeAnd": "请先选择规格类型和选项",
    "warrantyPolicy": "保修政策",
    "warrantyPeriod": "保修时间",
    "qualificationCertificate": "资质证明",
    "productNameLimitIdZalora": "商品名称必须是1到188个字符",
    "zaloraCategory": "Zalora 类目",
    "numberOfComments": "评论数",
    "keywordType": "筛选",
    "sortType": "排序",
    "number": "序号",
    "newRuleMasterProduct": "新增规则 {0};主商品 {1}",
    "deleteRuleMasterProduct": "删除规则 {0};主商品 {1}",
    "storePolicyAddPolicyNew": "店铺策略{0};添加策略：新增策略",
    "storePolicyDeletePolicyPolicy": "店铺策略{0};删除策略：删除了策略",
    "ruleNameChangedFromTo": "规则名称：由 {0} 变更为 {1}",
    "masterProductNameChangedFrom": "主商品名称：由 {0} 变更为 {1}",
    "pushTypeNameChangedFrom": "推送类型名称：由 {0} 变更为 {1}",
    "storePolicyWarehouseNameChanged": "店铺策略{0};仓库名称：由 {1} 仓库变更为 {2} 仓库",
    "storePolicyInventoryTypeName": "店铺策略{0};库存类型名称：由 {1} 变更为 {2}",
    "storePolicyStoreNameChanged": "店铺策略{0};店铺名称：由 {1} 变更为 {2}",
    "storeStrategyPushRatioChanged": "店铺策略{0};推送比例：由 {1} 变更为 {2}",
    "storePolicyPushFixedValue": "店铺策略{0};推送固定值：由 {1} 变更为 {2}",
    "addmskuthresholdIsselectmainstoreIs": "添加{0} MSKU，阈值为{1}，选择主店铺为：{2}",
    "mskuhasbeendeleted": "msku已被删除",
    "masterproductchangedfromto": "主商品从 {0} 变更为 {1}",
    "mainstorechangedfromto": "主店铺从 {0} 变更为 {1}",
    "thresholdchangedfromto": "阈值从 {0} 变更为 {1}",
    "masterProductActivity": "主商品活跃度",
    "operationType": "操作类型",
    "salesTypeId": "销售类型",
    "salesRangeId": "销售区间",
    "modifyChannelProductPrices": "修改渠道商品价格",
    "areyousureyouwanttodeletetheruleafterdeletedyouneedtoreconfigurethesynchronizationrules": "确定要删除规则？删除后需重新配置同步规则",
    "orderPushRecord": "订单推送记录",
    "productPushRecord": "商品推送记录",
    "youHaveNotActivatedStockMonitoring": "您暂未开通“库存监控与同步”",
    "turnOnNow": "去开启",
    "turnOn": "开启",
    "theSalesRangeOfCannot": "{}的销量范围不能高于{}",
    "theValueOnTheRightSide": "输入框右边的数值不能小于左边",
    "pushRecord": "推送记录",
    "allWarehouse": "全部仓库",
    "allStoresExceptCustomStores": "除自定义店铺外的所有店铺：{x}%",
    "pushFailed": "推送失败",
    "pushed": "已推送",
    "bindwarehouse": "关联仓库",
    "afterTurnOnTheProductsThat": "开启后，符合该规则的商品将推送至店铺后台",
    "afterDeactivationProductsThatMeetThis": "停用后，符合该规则的商品将不会再推送至店铺后台",
    "afterTheSettingIsCompleteAverage": "设置完成后，需要第二天才会按照新的设置来计算日均销量。",
    "productDescriptionMustBeBetweenMinmaxcharacters": "商品描述必须是{min}到{max}个字符",
    "repush": "重新推送",
    "pullAccurateStock": "拉取Accurate库存",
    "accounting1": "财务系统",
    "returnManagement": "退货管理",
    "returnOrderXxxRejectedToRefund": "退货订单xxx拒绝给买家退款",
    "returnOrderXxxAgreedToRefund": "退货订单xxx同意给买家退款",
    "returnOrderXxxRejectedRefundreturnRequest": "退货订单xxx拒绝退款/退货请求",
    "returnOrderXxxAcceptedRefundreturnRequest": "退货订单xxx接受退款/退货请求",
    "returnOrderXxxSuccessfullySyncedThe": "退货订单xxx同步成功，退货类型为“xxx”，处理状态为“xxx\"",
    "rejectRefund": "拒绝退款",
    "acceptRefund": "同意退款",
    "acceptRequest": "接受请求",
    "syncReturnOrder": "退货订单同步",
    "confirmInbound": "确认入库",
    "mass": "批量",
    "returnWarehouse1": "退货仓库",
    "returnProduct": "退货商品",
    "doYouAgreeToRefund": "请确认是否同意退款？",
    "pleaseSelectRejectionReason": "请选择拒绝原因",
    "doYouAcceptBuyersReturnRefund": "请确认是否接受买家的退货退款请求？",
    "onceYouAgreeItCannotBe": "一旦同意后将不可撤销！",
    "doYouAcceptBuyersRefundRequest": "请确认是否接受买家的退款请求？",
    "exportAll": "全部页导出",
    "viewReturnRecords": "查看退货日志",
    "accept": "接受",
    "isItADisputedOrder": "是否争议订单",
    "inboundNo": "入库单号 （仅退款页面不显示）",
    "returnWarehouse": "退货仓库 （仅退款页面不显示）",
    "returnAwb": "退货物流单号",
    "returnStatus": "退货状态",
    "processingDeadline": "处理截止时间",
    "processingStatus": "处理状态",
    "closed": "已关闭",
    "finished": "已完成",
    "inDispute": "争议中",
    "returnRemark": "退货备注",
    "variantchannelSkumsku": "规格/渠道SKU/MSKU",
    "returnNo": "退货单号",
    "disputedOrder": "争议订单",
    "cod": "COD",
    "separateMultipleInfoWithSpace": "多个信息使用空格隔开",
    "returnRefund": "退货退款",
    "returnOrder": "退货订单",
    "xRuleHasBeenDeletedPlease": "{x}规则已删除，请在操作日志中查看记录",
    "checknow": "去查看",
    "pushTime": "推送时间",
    "theActivityLevelOfTheChannel": "渠道商品已设置活跃度，不能重复设置。",
    "deleteMasterProduct": "删除主商品",
    "pleaseEnterRemarks": "请输入备注",
    "refundType": "退款类型",
    "refundOnly": "仅退款",
    "refundStatus": "退款状态",
    "returnInformation": "退货信息",
    "refundReason": "退款原因",
    "returned": "已退回",
    "returning": "退回中",
    "waitingBuyerToReturn": "待买家退回",
    "returnRecord": "退货日志",
    "refundList": "退款列表",
    "mskuBindingStatus": "主SKU绑定状态",
    "pleaseConfirmTheReturnedProductBefore": "请在退货入库前确认退货商品，如果有误请同步订单后再退货入库",
    "combinedProductSales": "被组合商品销量",
    "includingCombineProductSalesAndIndividual": "包含组合商品销量、被组合商品单独售卖的销量。",
    "countTheSalesOfCombinedProducts": "统计被组合商品单独售卖和捆绑售卖的销量",
    "pleaseSelectTheDataToBe": "请选择需要导出的数据",
    "salesOfIndividuallySoldItems": "单独出售的商品销量",
    "salesOfProductsSoldByThe": "被组合出售的商品销量",
    "combinedProductMsku": "被组合商品MSKU",
    "combinationProductMsku": "组合商品MSKU",
    "combinedProductName": "被组合商品名称",
    "mainCommoditySalesAnalysis": "主商品销量分析",
    "productDetailsOfThePortfolio": "被组合商品详情",
    "combinationProductDetails": "组合商品详情",
    "combinedProduct": "被组合商品",
    "exportChoice": "按勾选导出",
    "combinationProducts": "组合商品",
    "refundRate": "退货率",
    "refundAmount": "退货金额",
    "refundQuantity": "退货数量",
    "refundOrderQuantity": "退货订单量",
    "numberOfFailedOrders": "失败订单量",
    "numberOfReturnedOrder": "退货订单量",
    "numberOfCanceledOrder": "取消订单量",
    "numberOfShippedOrders": "已收货订单量",
    "numberOfOrdersInDelivery": "发货中订单量",
    "numberOfReadyToShipOrder": "待发货订单量",
    "numberOfPaidOrder": "已支付订单量",
    "numberOfPendingPaymentOrder": "待付款订单量",
    "effectiveSalesAmount": "有效销售额",
    "totalSales": "总销售额",
    "totalProductSales": "商品总销量",
    "relatedOrderQuantity": "关联订单量",
    "msku": "MSKU",
    "creationTime": "创建时间",
    "keywords": "关键字",
    "isACombinationProduct": "是否组合商品",
    "salesQTY": "销量",
    "failedGenerateInboundList": "生成入库单失败",
    "someMasterProductDoNotExist": "部分主商品在所选仓库不存在",
    "masterProductIsEmptyPleaseRebind": "主商品为空，请重新绑定主商品后重试",
    "refundInformationNotCompletePleaseRefresh": "退款信息不完整，请刷新页面",
    "informapping": "信息匹配",
    "mappingtip": "选择类别和变体类型，否则您将不会获得变体信息（例如：库存，价格等）",
    "Mappingcategory": "信息映射",
    "tiktokCategoryAttributes": "Tiktok 类目属性",
    "enterBetween": "输入1-60",
    "salesInformation": "销售信息",
    "tikTokCategory": "TikTok 类目",
    "tiktokfailedToPublishGineeProductThat": "发布失败：Ginee发布失败的商品",
    "tiktokpublishingGineeProductThatAreBeing": "发布中：Ginee发布中的商品或者在卖家中心审核中的商品",
    "tiktokdraftAProductDraftCreatedBy": "草稿：Ginee创建的草稿商品",
    "tiktoksuspendedThisIsAProductWith": "已禁卖：这是 卖家中心Suspended状态的商品",
    "tiktoksoldOutThisIsAProduct": "已售完：这是卖家中心Live/Deactivated状态库存为0的商品",
    "tiktokdeactivatedThisIsAProductWith": "已下架：这是卖家中心Deactivated状态的商品",
    "tiktokliveThisIsAProductWith": "已上架：这是卖家中心Live状态的商品",
    "tiktokskuMustBeCharacters": "SKU必须是1-50个字符",
    "tiktokwarrantyPolicyMustBeCharacters": "保修政策必须是0-99个字符",
    "tiktokpriceRangeShouldBe": "价格的范围应该是100-100000000",
    "tiktokstocktLimitOhters": "库存范围应该是0-99999",
    "tiktokweightShouldBeBetweenKg": "重量的范围应是0.01-100",
    "tiktokheightShouldBeBetween": "高的范围应是1-35",
    "tiktokwidthShouldBeBetween": "宽的范围应是1-40",
    "tiktoklengthShouldBeBetween": "长的范围应是1-60",
    "tiktokproductDescriptionLimitIdMy": "商品描述必须是1到10000个字符",
    "productNameMinMaxCharacters": "商品名称必须是{min}到{max}个字符",
    "mpType": "主SKU绑定状态",
    "updateDates": "更新时间",
    "returnOrderXHasSuccessfullyCreated": "退货订单{x}创建退货入库单成功，入库单号为{x}",
    "createInboundList": "创建入库单",
    "noteThereIsADelayIn": "注：渠道订单状态及快递单更新存在延迟，可能导致推送失败，一旦推送失败，请在失败列表尝试重新推送",
    "theOrderIsBeingPushedTo": "正在为你推送订单至三方仓库，请勿重复操作，请移步至订单列表查看推送进度",
    "pushTaskSuccessfullyCreated": "推送任务创建成功",
    "refundLog": "退款日志",
    "refundNo": "退款单号",
    "refundOrder": "退款订单",
    "theServiceIsBusyOrYou": "服务正忙，或者您可能已达到请求速率限制，请手动执行推送",
    "cancellationIsNotAllowedWhilePush": "推送中不允许作废",
    "synchronizeAllMskuInWarehouseTo": "将全部MSKU所在仓库按照{x}的和以{y}的比例同步至所有的渠道sku",
    "youDoNotHavePermissionAt": "您暂无权限，请联系管理员开在【角色管理】中配置权限",
    "tikiproductsCreatedInGineeExcludingProducts": "在Ginee创建的草稿商品，不包含卖家中心-Dradt状态的商品",
    "tikiproductsFailedPublishByGinee": "Ginee发布失败的商品",
    "tikiproductsPublishingByGinee": "Ginee发布中的商品",
    "tikiproductLockedInSellerCenterApi": "Tiki暂无Banned的商品",
    "tikiamongActiveAndInactiveProductsAll": "上下架商品中，所有变种库存数量=0的商品;\n由于API接口问题，FBT的商品将暂时放在已售完列表。",
    "tikinonactiveProductsInSellerCenter": "卖家中心状态为OFF和locked的商品",
    "tikiactiveProductInSellerCenter": "卖家中心状态为ON的商品",
    "skuProductCode": "SKU（商品编码）",
    "lowstockthresholdValueCannotHigherThan": "lowStockThreshold 值不能高于库存数量",
    "descriptionUpToCharacters": "描述最多{max} 个字符",
    "skuPromotionPriceCannotHigherThan": "SKU 促销价不能大于原价，请编辑",
    "skuPromotionStartTimeCannotBe": "SKU促销开始时间不能为空",
    "skuPromotionEndTimeCannotBe": "SKU促销结束时间不能为空",
    "skuLowstockthresholdValueCannotHigherThan": "SKU lowStockThreshold 值不能高于库存数量",
    "variantSkuCannotBeEmpty": "变体SKU不能为空",
    "productSkuCannotBeEmpty": "商品SKU不能为空",
    "thisSkuAlreadyExistsInThis": "这个sku:${0}已经存在该店铺,请重新编辑",
    "productMainImageCannotBeEmpty": "商品主图不能为空",
    "promotionPriceCannotBeHigherThan": "促销价不能高于原价",
    "theInputRangeFromMin": "输入范围是 { min } - { max}",
    "addVariants": "添加变体",
    "shippingClass": "Shipping class",
    "crosssells": "Cross-sells",
    "upsells": "Upsells",
    "enableThisToOnlyAllowOne": "Enable this to only allow one of this item to be bought in a single order",
    "soldIndividually": "Sold individually",
    "lowStockThreshold": "Low stock threshold",
    "allowBackorders": "Allow backorders",
    "enableStockManagementAtProductLevel": "Enable stock management at product level",
    "manageStock": "Manage stock",
    "taxClass": "Tax class",
    "taxStatus": "Tax status",
    "usedForVariations": "Used for variations",
    "visibleOnTheProductPage": "Visible on the product page",
    "thisIsAFeaturedProduct": "This is a featured product",
    "showSharingButtons": "Show sharing buttons",
    "showLikes": "Show likes",
    "likeAndShares": "Like and Shares",
    "hidden": "Hidden",
    "searchResultsOnly": "Search results only",
    "shopOnly": "Shop only",
    "shopAndSearchResults": "Shop and search results",
    "catalogVisibility": "Catalog visibility",
    "heightMinMax": "高的范围应是{min}-{max}",
    "widthMinMax": "宽的范围应是{min}-{max}",
    "lengthMinMax": "长的范围应是{min}-{max}",
    "weightMinMax": "重量的范围应是{min}-{max}",
    "pleaseEnterProductDescription": "请输入描述",
    "productNameMinMax": "商品名称必须是{min}-{max} 个字符",
    "variantPicture": "规格图片",
    "productImageMax": "商品图片最多{number}张",
    "promotionTime": "促销时间",
    "promotionPrice": "促销价",
    "options": "选项",
    "variantType": "规格类型",
    "productAttributes": "产品属性",
    "productShortDescription": "Product short description",
    "productTag": "产品标签",
    "updatedateasc": "更新时间升序",
    "updatedatedesc": "更新时间降序",
    "createdateasc": "创建时间升序",
    "createdatedesc": "创建时间降序",
    "withoutmp": "未绑主SKU",
    "withmp": "绑定主SKU",
    "generateInventorySKU": "创建库存SKU",
    "onBackorder": "延迟交货",
    "productNameAndStore": "商品名称 & 店铺",
    "sync": "同步",
    "inStock": "有货",
    "soldOut": "无货",
    "disabled": "已下架",
    "china": "中国",
    "wooPublishStatus": "在店铺中，等待审核的商品显示在发布中tab里",
    "wooActiveStatus": "在店铺中，已发布的商品显示在已上架tab里",
    "wooDeactiveStatus": "在店铺中，私密的商品会显示在Ginee已下架的tab里",
    "wooDraftStatus": "在店铺中，草稿商品会显示在Ginee草稿tab里",
    "inProcess": "发布中",
    "stockStatus": "库存状态",
    "externalProduct": "关联商品",
    "groupProduct": "组合商品",
    "variable": "多规格",
    "simpleVariation": "单规格",
    "editStatusError": "请先点击确认按钮后，再点击发布到店铺",
    "productInfoSetting": "商品信息设置",
    "choiceMskuTip": "选择主SKU后，系统会把主SKU的价格和库存信息带入，并将blibli的SKU信息和该主SKU进行绑定。",
    "categoryMatch": "类目匹配",
    "variationLimit": "已选类目不支持添加规格",
    "clickToEdit": "点击表格中的内容，可进行编辑操作！",
    "whatsInTheBox": "包裹内容",
    "MinPurchaseQuantity": "最小购买数量",
    "ProductDescriptionShouldBeCharacters": "商品长描述应是 6 - 25000 个字符",
    "chooseStoreLimit": "请至少选择一家目标店铺",
    "replaceWith38": "替换为",
    "addBetweenTheDescription": "描述中添加",
    "addAtTheEndOfThe": "描述结尾添加",
    "addAtTheStartOfThe": "描述开头添加",
    "addBetweenName": "名称中添加",
    "editProductName": "编辑商品名称",
    "theFileWillOnlyBeReserved": "文件仅会保留7天，请及时查看或下载",
    "theResultOfThePublishWill": "发布的结果会同步展示在【主商品-批量操作记录中】中",
    "successfulQtyAllQty": "成功数量/全部数量",
    "publishing": "发布中",
    "taskStatus": "任务状态",
    "publishedStoreStoreStore": "发布的店铺：店铺1、店铺2",
    "content": "内容",
    "creator": "创建人",
    "taskType": "任务类型",
    "allPublishFailed": "全部发布失败",
    "somePublishFailed": "部分发布失败",
    "allPublished": "全部发布成功",
    "productsWereSuccessfullyPublished": "29个商品发布成功",
    "publishingToChannelStores": "正在批量发布到渠道店铺",
    "massEditOfShopeesCategoryAttribute": "批量编辑Shopee店铺的类目属性",
    "matchTokopediaVariantType": "匹配tokopeida的规格类型",
    "matchLazadaVariantType": "匹配lazada的规格类型",
    "masterProductVariantType": "主商品 规格类型",
    "massEditProductDescription": "批量编辑商品描述",
    "shopeeCategoryAttribute": "Shopee店铺的类目属性",
    "productNameInThisStoreIs": "商品名称在该店铺已存在",
    "partOfTheMasterProductIs": "主商品的部分必填信息为空，请补全后再发布到店铺",
    "chooseACountry": "选择国家/地区",
    "max11": "最大1000",
    "massEditWeightG": "批量编辑重量(g)",
    "max7": "最大500,000",
    "empty": "清空",
    "editLogisticsMass": "批量编辑物流",
    "editBliblidraftmass": "Blibli/批量编辑",
    "categoryAttributesBlibli": "blibli 类目属性",
    "copyMass": "批量克隆",
    "categoryChoose": "选择类目",
    "matchCategoryBlibli": "Blibli类目匹配",
    "storeWarehouse": "店铺/仓库",
    "buyable": "可买",
    "MinimumStock": "最小库存范围应是0-999,999",
    "originalPriceLimit2": "原价应高于售价",
    "sellingPriceLimit2": "售价应低于原价",
    "originalPriceLimit": "原价范围应该是 1-100.000.000",
    "enterYoutubeLinkForExampleYouTubecomipadview": "输入YouTube链接，例如：youtube.com/ipadviwe",
    "youtubeVideoLink": "Youtube视频链接",
    "exampleTheBatteryLifeIsUp": "例如：续航时间长达10小时",
    "sellingPoint": "卖点",
    "continueToAddProducts": "继续添加商品",
    "jdChannelIsUnderReviewCheck": "blibli渠道正在审核，请稍后在“发布中/发布失败/下架”tab上检查结果",
    "failedReasons": "失败原因",
    "productsUpdateSucceeded": "商品更新成功",
    "changeSKUTo": "将SKU改成",
    "massEditSellingPrice": "批量修改售价",
    "massEditOriginalPrice": "批量修改原价",
    "massEditSku": "批量修改SKU",
    "editVariantImage": "编辑规格图片:",
    "multipleImagesCanBeUploadedAt": "可以一次上传多张图像。 图片最小600 x 600像素，最大为4MB（最多8张）",
    "shouldBeBetween": "输入范围是0-1000,000,000",
    "optionsnLimit": "选项应少于20个",
    "bigItem": "大件商品",
    "smallItem": "小件商品",
    "qualitydays": "质量保修时间",
    "afterSale": "售后服务",
    "Min1": "最小是1",
    "minpurchasePerOrder": "最小购买数量",
    "pleaseEnterProductName": "请输入商品名称",
    "blibliCategory": "BliBli 类目",
    "partOfTheProductIs": "按渠道店铺发布商品的要求，商品部分字段要必填。请先补全必填字段后再发布到店铺。",
    "blibliShouldBeBetweenA": "输入范围是 0.001-999.999",
    "blibliWeightLimit": "应在1-999,999之间！",
    "blibliStocktLimit": "库存范围应该是0-999,999!",
    "blibliImageSizeLimit": "图片大小至少是600 x 600像素，最大为4MB",
    "serviceErrorPleaseContactGineeTechnology": "服务错误，请联系Ginee技术",
    "minPurchaseLimit": "最小购买数量应该是1-1000,000,000",
    "enterThenChange": "先输入值再切换单位",
    "goToTheBlibliSellerCenter": "请前往blibli卖家中心补全仓库地址后，才能选择该物流；",
    "regularStoreSupport": "regular店铺支持",
    "toApiRestrictionsSomeVariantOption": "因API限制，部分规格选项无法克隆，若有需要请自行添加。",
    "theCategoryAndVariantTypeApply": "类目和规格类型 是否应用于相同源类目(相同站点-源类目：xxx/xxxx/xxxx)的所有产品?",
    "toApiRestrictionsSomeFieldsDo": "由于Blibli API限制，部分字段不可编辑",
    "shippingMethod": "运输方式",
    "backToProductList": "返回商品列表",
    "chooseTheShippingMethodFirstPlease": "请先选运输方式",
    "needToChooseNo": "无需选择",
    "fAq1": "Blibli:如何新增或编辑商品?",
    "fAq2": "Blibli:如何克隆商品？",
    "fAq3": "Blibli:如何批量编辑商品",
    "synchronizationForThisProductHasBeen": "商品已启用库存同步，请在库存管理中修改库存",
    "productDeleting": "正在删除商品",
    "operationWillNotDeleteTheProduct": "这个操作不会在卖家中心删除该商品",
    "deleteTip": "因API限制，仅支持删除草稿、发布中、发布失败的状态的商品。",
    "productOrVariationImage": "商品/规格图片",
    "imageTip2": "无规格的商品该图片指商品图片，有规格的商品指变体图片",
    "blibliSkuLimit": "SKU应少于50字符",
    "exampleTheAvailableInventoryOfWarehouse": "示例：仓库A的可用库存为100，仓库B的可用库存为200，设置推送仓库为A+B，推送比例为50%，那么推送到目标店铺C的数据是150个库存",
    "exampleThePushFixedValueIs": "示例：推送固定值是100，推送目标店铺为A，执行推送时，推送到店铺A的库存是100",
    "ProductStatus": "商品状态",
    "productsThatFailToBePushed": "推送失败的商品不允许再次推送，请联系ECCANG WMS工作人员先审核商品",
    "returnTime": "返回时间",
    "theProductHasNotPassedThe": "该商品审核不通过",
    "shippingFeePaidBySeller": "卖家支付运费",
    "skucanonlybenumberslettersandhyphenandthemaximumcharacters": "SKU只能是数字、字母、中横线,SKU最大长度不能超过30个字符.",
    "skuNameMaxCharactersOnly": "SKU名称，限制长度50位，仅支持中英文、数字、中横线、下横线",
    "productThatFailToPassThe": "商品在易仓WMS审核不通过的商品会下发失败",
    "skuCanOnlyBeInUppercase": "SKU只能为大写字母",
    "afterFailedToSendTheInbound": "入库单下发失败后，不支持重新推送",
    "productEffectiveSales": "商品有效销量",
    "pleaseNote": "请注意：",
    "dueToTheLimitationOfThe": "由于ECCANG WMS接口限制，SKU只能为数字、字母、中横线,SKU最大长度不能超过30个字符；",
    "someOrdersAreNotAssignedWith": "部分订单未分配子物流公司，请同步后重新发货",
    "ordersWithoutSublogisticsAreNotSupported": "未分配子物流的订单不支持发货，请同步订单获取子物流",
    "waitingForDistributionLogisticsPleaseSynchronize": "等待渠道分配物流，请同步",
    "productInformationSettingError": "商品信息设置错误",
    "userAddressInformationError": "用户地址信息错误",
    "notInShippingAddress": "地址不在配送范围内",
    "deliveryFailure": "配送失败（因用户不接电话、地址不符而配送失败）",
    "logisticsCannotSupportDistribution": "物流无法支持配送（如雨季有洪灾）",
    "theMerchantSentTheWrongProduct": "商家寄错商品",
    "productDamage": "商品损坏（未出库已损坏）",
    "productDescriptionDoesNotMatch": "商品描述不符",
    "businessAndUserPhoneToCancelOrder": "商家与用户电话协商取消订单",
    "goodsDamageDuringLogisticsDelivery": "物流寄丢/配送过程中货物损坏",
    "theUserAskedToCancelTheOrder": "用户要求取消订单",
    "logisticsAndDistributionCannotContactTheRecipient": "物流配送联系不上收件人",
    "incompleteRecipientAddress": "收件人地址不全",
    "thirdPartyMerchantsHaveNoInventory": "第三方商家无库存",
    "outOfDeliveryRange": "超过配送范围",
    "itIsOutOfStockAndCannotBeTraded": "已经缺货无法交易",
    "theBuyerSInformationIsFilledInWrong": "买家信息填写错误,重新拍",
    "latePayment": "未及时付款",
    "maliciousBuyers": "恶意买家/同行捣乱",
    "theBuyerHasNoSincerityToCompleteTheTransaction": "买家无诚意完成交易",
    "theBuyerMadeAMistakeOrRemake": "买家误拍或重拍了",
    "unableToContactBuyer": "无法联系上买家",
    "otherReasons": "其它原因",
    "unableToCompletePayment": "无法完成付款/无法收到付款代码",
    "thePaymentMethodNeedsToBeChanged": "需要更换付款方式",
    "commodityPriceReduction": "商品降价",
    "wrongAddressInformation": "地址信息填写错误",
    "wrongProductInformation": "商品信息填写错误",
    "iDonTWantItAnymore": "不想要了",
    "goodsOutOfStock": "商品无货",
    "synchronizeAllMskusUnderXTo": "将{x}下的全部MSKU的和以{y}按照{z}的比例同步至{w}",
    "pushInProcess": "推送中",
    "waitingToPush": "待推送",
    "rdWarehousePushStatus": "三方仓推送状态",
    "pushStatus": "推送状态",
    "printable": "可打印",
    "productInboundStockCannotBeNegative": "商品的入库库存不能为负",
    "andAccordingToZ": "按照{z}的比例同步至{w}",
    "synchronizeallmskuunder": "将{x}下的全部MSKU的和以{y}",
    "pushSucess": "推送成功",
    "stockPushRuleaddRule": "库存推送规则/添加规则",
    "peraturanTolakStokubahSuai": "库存推送规则/编辑",
    "peraturanTolakStoklihatPerincian": "库存推送规则/查看详情",
    "negativeInventoryCannotBeManipulatedInto": "库存为负数不能操作入库",
    "channelCategoryAttributes": "{channelName}类目属性",
    "switchingMskuRequiresReselectingWarehouseInformation": "切换MSKU需重新选择仓库信息",
    "pleaseAuthorizeTheStoreFirst": "请先进行店铺授权",
    "thereIsNoAlternativeStorePlease": "没有店铺，请先进行店铺授权",
    "stockTikiTips": "不支持修改商品信息的Tiki店铺以及FBT类商品不会进行库存同步。",
    "stocksettingtip2Local": "开启库存同步后,所有绑定同一主SKU的商品的库存将被调整为一致,且当主SKU产生新的订单或订单被取消时,所有绑定该主SKU的商品的库存会统一扣减或增加。请在开启前确保已完成商品和订单的同步,且主SKU的库存数量已调整完毕。 \n开启库存同步后，店铺SKU的库存将会按照所绑定的主SKU在库存管理中的库存和库存配比（店铺发货仓设置）进行推送。如果没有在库存管理维护过库存，店铺SKU的库存会被推送为0，并且无法恢复。请确认是否开启。",
    "afterTheStockSynchronizationIsTurned": "开启库存同步后，店铺SKU的库存将会按照所绑定的主SKU在库存管理中的库存和库存配比（店铺发货仓设置）进行推送。如果没有在库存管理维护过库存，店铺SKU的库存会被推送为0，并且无法恢复。请确认是否开启。",
    "doesntSupportCustomShippingLabelTemplate": "暂不支持菲律宾自定义快递单模板字段，仅支持设置 A4/A6 尺寸",
    "commissionTips": "请填写介于 0 到 100 的有效数字",
    "invoiceNumberTips": "同一订单内所有商品使用同一发票号",
    "SettingTips2": "请点击此处",
    "SettingTips": "本设置仅对在Ginee内处理渠道订单发货时有效（Lazada仅支持在卖家中心设置），如需更新店铺的默认/取件地址，",
    "SettingLogo": "设置品牌Logo",
    "SellerNotes": "卖家备注",
    "PickingNotes": "拣货备注",
    "TotalProduct": "商品总数",
    "PrintMan": "打印人",
    "DeliverySettingPickupAddress": "请前往卖家中心设置地址",
    "DeliverySettingStoreName": "店铺名称",
    "PickFormReset": "重置",
    "ChangePickStatusConfirm": "确认",
    "PickDeleteSuccess": "删除成功",
    "PickDeleteTipTwo": "一经删除不可恢复",
    "PickDeleteTip": "确定删除状态信息？",
    "PickOverCharacters": "不超过20个字符",
    "AddPickStatus": "新增规则",
    "PickUpdateTime": "更新时间",
    "PickCreateTime": "创建时间",
    "PickStatusOperatorName": "创建人",
    "PickStatusName": "状态名称",
    "PrintUnitPrice": "单价",
    "PrintVariationName": "规格",
    "PrintShipping": "物流",
    "PrintSellerInfo": "卖家",
    "PrintBuyerInfo": "买家",
    "BuyerNotes": "买家备注",
    "ShippingFee": "总运费",
    "SenderInfo": "寄件人",
    "GoToSellerCenter": "请前往卖家中心设置地址",
    "AutoShippingTips": "Ginee 支持为店铺设置自动发货方式，快去设置吧",
    "ShippingAddRule": "新增规则",
    "OperationFailed": "操作失败，请稍后重试",
    "OperationSuccess": "操作成功",
    "ApplyToAllStores": "应用到全部店铺",
    "PickupAddress": "取件地址",
    "ChooseAStore": "选择店铺",
    "ShippingSettingTips": "提示：Shopee仅针对部分指定商家开放了SPX Pickup发货方式",
    "ShopName": "店铺名称",
    "ShippingMethod": "发货方式",
    "Courier": "物流公司",
    "ShippingMethodDescription": "Ginee 将根据您的设置，在订单同步后，自动为您选择发货方式，提高发货效率",
    "laStoreName": "店铺名称",
    "proalertclosed": "库存预警已关闭",
    "proalertopend": "库存预警已开启，当库存SKU的可用库存低于安全库存时将触发邮件提醒",
    "PleaseSelectLeast": "必须选择一项",
    "DeleteTplTitle": "确定删除模板？",
    "DeleteTplDesc": "模板一经删除不可恢复",
    "LaRuleBindingStoreSearchPlaceholder": "请输入店铺名称",
    "LaRuleBindingStoreModalTitle": "请选择发票规则",
    "methodSettingDoubleCheckDescription": "规则删除后，对应店铺的发货规则将失效",
    "LaRuleDoubleCheckDescription": "规则删除后，对应店铺的发票生成规则将失效。",
    "LaRuleDoubleCheck": "确定删除规则？",
    "ExportField": "导出内容",
    "SelectChannel": "选择渠道",
    "OrderExportingDragging": "您可通过上下拖动字段改变字段导出时排列的先后顺序",
    "BasicInfo": "基本信息",
    "EditTemplate": "编辑模板",
    "UpdateTime": "更新时间",
    "CreateTime": "创建时间",
    "TemplateName": "模板名称",
    "Creator": "创建人",
    "LAInvoiceSettingSave": "确认",
    "ApplyToAllLazadaStores": "应用至全部Lazada店铺",
    "NextNumber": "下一编号",
    "EnterNumberManually": "手动输入编号类型",
    "UseCustomNumber": "自定义编号类型",
    "UseOrderNumber": "使用订单编号类型",
    "StoreManagement": "店铺管理",
    "RuleApplicationStoreNumber": "适用店铺数",
    "RuleCustomNumber": "自定义编号",
    "RulePrefix": "前缀",
    "RuleType": "规则类型",
    "RuleName": "规则名称",
    "AddRule": "新增规则",
    "commissionPercent": "佣金比例 (%)",
    "InvoiceRules": "发票规则",
    "ShippingSettingsTab": "发货设置",
    "TemplateShippingAddress": "送货地址",
    "TemplateLogistics": "物流商",
    "TemplateTotal": "订单总额",
    "TemplateSellerDiscountTotal": "卖家折扣",
    "TemplateTax": "税费",
    "TemplateShipping": "运费",
    "TemplateSubtotal": "总计",
    "TemplateQty": "数量",
    "TemplateCreatedAt": "创建时间",
    "TemplatePayment": "支付方式",
    "TemplateContactNo": "联系电话",
    "TemplateUnitPrice": "单价",
    "TemplateBuyer": "买家",
    "TemplateAWBTrackingNumber": "快递单号",
    "Quantity": "数量",
    "Specification": "规格",
    "ProductName": "商品名称",
    "TemplateProductItem": "商品",
    "TemplateInsurance": "保险费",
    "TemplateWeight": "重量",
    "TemplateShippingType": "物流",
    "OrderId": "订单ID",
    "TemplateSender": "寄件人",
    "TemplateRecipient": "收件人",
    "TemplateInformation": "信息",
    "LogisticsLOGO": "物流商 LOGO",
    "ChannelLogo": "渠道 LOGO",
    "TemplateIcon": "图标",
    "TemplatePrintDate": "打印日期",
    "TemplateDate": "日期",
    "ViewBoxItemTitle": "模板预览",
    "TemplateSettingsTitle": "模板设置",
    "SetPickUpAddress": "设置取件地址",
    "SetDefaultAddress": "设置默认地址",
    "SenderAddress": "地址",
    "SenderPhone": "电话",
    "SenderName": "姓名",
    "DefaultAddress": "默认地址",
    "BlSenderInfo": "i18n: BlSenderInfo",
    "TokoSenderInfo": "提示：设置的姓名/电话将用于 Ginee 自有的快递单中的信息展示.",
    "ShopeeSenderInfo": "提示: 默认地址的姓名/电话将用于快递单展示，且 Default / Pickup 地址设置后，地址信息将同步至Shopee.",
    "PickingListPrintTemplateSettings": "拣货单打印模版设置",
    "ShippingLabelTemplateAlertTips": "仅对Ginee自有模板生效，不适用于第三方电商平台物流单样式",
    "PrintTemplateSettings": "打印模板设置",
    "ShippingLabelAlertTips": "如需使用Ginee自有的快递单模板或更新店铺的默认/取件地址，请务必完成该信息设置",
    "ShippingLabelInformationSettings": "快递单信息设置",
    "htar": "如何添加角色?",
    "htas": "如何添加员工?",
    "htmr": "如何管理权限?",
    "htms": "如何管理员工?",
    "Mustbestartedwith8": "必须为以8开头",
    "atleastonerole": "请至少选择一个权限",
    "noroles": "无数据，请先添加角色",
    "deleteroletip": "删除后，员工则不能再选择该角色",
    "suretodeleterole": "你确定删除该角色吗？",
    "EditRole": "编辑角色",
    "Roleadded": "角色已添加",
    "Permissionlimit": "请至少选择一个权限",
    "Permission": "权限",
    "roledesclimit": "请输入低于600字符的描述",
    "roleNamelimit": "角色名称应该是1-150个字符",
    "EnterRoleName": "输入角色名称",
    "DeleteFailed": "删除失败",
    "staffDeleted": "员工已删除",
    "RoleDeleted": "角色已删除",
    "AddRole": "添加角色",
    "Numberofstaff": "员工数量",
    "RoleDescription": "角色描述",
    "RoleName": "角色名称",
    "Submit": "确认",
    "Staffhasbeenadded": "员工已添加",
    "Nextstep": "下一步",
    "DiscardChange": "放弃更新",
    "suretodiscard": "您确定要放弃此更改吗？",
    "AddNewRole": "新增角色",
    "Existingpermissions": "已有的权限",
    "EnterthePassword": "员工使用该密码登录Ginee后台",
    "passwordlimit": "密码至少包含8个字符，数字和特殊字符",
    "Staffsusethemailbox": "员工使用该邮箱作为账户登录Ginee后台",
    "Gineeaccountcannotbeempty": "Ginee账户不能为空",
    "Pleaseinputemail": "请输入邮箱地址",
    "Contactphonenumber": "联系手机号",
    "namelimit": "员工姓名应该是 1-70个字符；",
    "EnterStaffName": "输入员工姓名",
    "locktip": "该账户被锁定，请联系管理员",
    "deletetip": "删除后，该员工将无法管理店铺和你将不能再添加该员工",
    "suretodeletestaff": "你确定删除该员工吗？",
    "Disablenow": "确定停用",
    "disabletip": "停用后该员工将无法登录Ginee管理店铺",
    "suretodisabled": "你确定将该员工账户停用吗？",
    "Edit": "编辑",
    "Enabled": "启用",
    "Disabled": "停用",
    "Action": "操作",
    "LastLogin": "最近在线",
    "NameofStaff": "员工姓名",
    "AddStaff": "添加员工",
    "Search": "搜索",
    "Roles": "角色",
    "GineeAccount": "Ginee账户",
    "AccountStatus": "账户状态",
    "ActivationFailed": "开启失败，请先进行设置",
    "SavedSuccessfully": "操作成功",
    "donwloadlist": "点我下载全部库存列表",
    "InventoryWarnings": "库存预警",
    "moresetting": "更多设置",
    "suretoclosemainlock": "确定要关闭主店铺库存锁定吗？",
    "suretoopenstocklock": "确定要开启主店铺库存锁定吗？开启后当库存低于阈值时,仅保留主店铺库存,同时会清空其他店铺的所有库存",
    "whenempty2": "时，清空其他店铺的所有库存，除了店铺",
    "whenempty1": "当库存'<'=",
    "MainStoreStockLock": "主店铺库存锁定",
    "suretoclosestockwarning": "确定要关闭库存预警吗？",
    "emailreminderstocklow": "当商品库存不足时邮件提醒，确定要开启吗？",
    "Setup": "设置",
    "whenstock2": "时，通知我库存不足",
    "whenstock1": "当库存<=",
    "suretoclosealertsold": "确定要关闭缺货提醒吗？",
    "notifyme": "当商品已售光时，通知我",
    "stocksettingtip3Local": "关闭库存同步后,所有店铺中的商品库存将不再统一增减。",
    "stocksettingtip3": "关闭库存同步后，所有店铺中的商品库存将不再统一扣减，同时库存列表将自动清空！",
    "stocksettingtip2LocalOld": "开启库存同步后,所有绑定同一主SKU的商品的库存将被调整为一致,且当主SKU产生新的订单或订单被取消时,所有绑定该主SKU的商品的库存会统一扣减或增加。请在开启前确保已完成商品和订单的同步,且主SKU的库存数量已调整完毕",
    "stocksettingtip2": "开启库存同步后，所有店铺中同一SKU的库存将被调整为一致，且当SKU产生新的订单或订单被取消时，所有店铺中该SKU的库存会统一扣减或增加。请在开启前确保已完成商品和订单的同步，且库存SKU的数量已调整完毕",
    "stocksettingtip1Local": "为保证库存准确,请在开启前完成所有商品和订单的同步并配置好主SKU的库存；开启后,Ginee在同步订单后会根据订单状态自动增减主SKU的库存,并将主SKU的可用库存同步到已绑商品的店铺中。",
    "stocksettingtip1": "为保证库存准确，请在开启前完成所有商品和订单的同步并配置好库存SKU的库存；开启后，Ginee在同步订单后会根据订单状态自动增减库存SKU的库存，并将库存数量同步到所有已绑定店铺对应的平台SKU",
    "monitorsync": "库存监控与同步",
    "noteCreateBind": "注意：若自动绑定主SKU已开启则会被优先执行",
    "autoCreateSKUValue": "创建规则为：若商品的平台sku和某主SKU同名，则将该商品绑定此主SKU，反之自动创建新的主SKU。仅对同步到Ginee且未绑定主SKU的渠道商品有效。",
    "automaticallyCreateTheMasterSKU": "自动创建主SKU",
    "deleteAutoCreateRules": "您确定要关闭自动创建主SKU设置吗？",
    "turnAutoCreateRules": "开启自动创建主SKU后，针对未绑定主SKU的渠道商品,若商品的平台sku和某主SKU同名，则将该商品绑定此主SKU，反之自动创建新的主SKU。",
    "goToDownloadTheRules": "前往【导入/导出-导出全部自动绑定规则】下载规则",
    "downloadAllAutoBindRules": "下载全部自动绑定规则",
    "deleteAutoBindRules": "您确定要关闭自动绑定主SKU设置吗？",
    "turnOnAutoBindRules": "开启自动绑定主SKU后，针对未绑定主SKU的渠道商品，我们将会根据主SKU跟平台SKU同名的规则将商品自动绑到主SKU上。",
    "autoBindSKUValue": "开启设置后，若平台SKU跟主SKU同名将自动绑定，同时支持自定义自动绑定规则。仅对同步到Ginee且未绑定主SKU的渠道商品有效。",
    "automaticallyBindTheMasterSKU": "自动绑定主SKU",
    "SyncSuccess": "同步完成",
    "AreSureEnableCOD": "您确定要开启货到付款吗？",
    "StoreName": "店铺名称",
    "ExcludeShippingInfo3": "确定要开启 { JNEOKE }?",
    "ExcludeShippingInfo2": "商品如已开启 { JNEOKEList } 将会自动切换为 { JNEOKE }.",
    "ExcludeShippingInfo1": "如果您开启 { JNEOKE }, 则 { JNEOKEList } 将会自动关闭。",
    "NoShippingInfo": "您还没有Shopee店铺，快去绑定吧！",
    "CodNo": "否",
    "CodYes": "是",
    "DisabledCODClick": "请点击此处查看详细信息",
    "DisabledCODDesc4": "J&T的货到付款订单已投保，",
    "DisabledCODDesc3": "货到付款订单将更容易促成成交；",
    "DisabledCODDesc2": "滥用货到付款的买家将自动被列入黑名单；",
    "DisabledCODDesc1": "支持货到付款的卖家通常会获得更多的买家和订单；",
    "DisabledCODTitle": "您确定要禁用货到付款吗？",
    "PleaseGoToProductEnable": "请到每个商品下开启新选择的物流方式",
    "UnEnabledTitle": "一旦关闭开关，该物流将从所有现有商品中删除。 任何没有运输选项的商品都将下架且不会对买家可见",
    "CODDisabled": "货到付款已禁用",
    "CODEnabled": "货到付款已启用",
    "COD": "COD",
    "ShippingSupportedDesc": "使用Shopee支持的运输选项，Shopee将收到有关其包裹的最新跟踪信息的通知",
    "ShopeSupportedLogistics": "Shopee支持的物流",
    "ShippingTopTips": "说明：您可以在此管理已绑定店铺的物流设置，同渠道多个店铺的物流设置建议保持一致，请至少开启一个物流选项",
    "MembershipPage": "会员页",
    "BasicLogo": "店铺商标",
    "Account": "账号",
    "afterClosingTheAutomaticBindingRules": "关闭后，自动绑定的规则会清空 并且渠道商品不再自动绑定主SKU。",
    "ShippingRuleSettings1": "发货规则设置",
    "TheWarehouseAllocatedByThe": "2.订单分配的仓库已与Accurate仓库绑定",
    "MskuHasSuccessfullyPushedTo": "1.MSKU已成功推送Accurate",
    "orderRulesToPushToAccurate": "Accurate 订单推送规则如下：",
    "OnlySupports": "3.仅支持\"（\"、\"）\"、\"-\"、\".\"、\"_\"和\"/\"连接符",
    "OnlySupportsLetternumberCombination": "2.仅支持字母/数字组合",
    "NoMoreThanCharacters": "1.不超过30个字符",
    "mskuRulesToPushProductTo": "Accurate 商品MSKU推送规则如下：",
    "ifNoRulesAreAddedThe": "未添加规则时，按照该规则执行，一旦自行添加规则后，优先按照您设定的规则分配仓库",
    "afterTheWarehouseSettingIsModified": "分仓设置修改后，将按照新的规则进行仓库分配，请谨慎操作",
    "afterConfigurationWhenAnOrderContains1": "配置后，一笔订单包含多个商品时，可按照不同商品配对不同仓库",
    "particularCommodity": "单个商品",
    "afterConfigurationWhenAnOrderContains": "配置后，一笔订单包含多个商品时，只能分配给一个仓库，不能同时匹配多个仓库",
    "overallOrder": "整体订单",
    "compartmentDimension": "分仓维度",
    "afterConfigurationWhenMultipleWarehousedividingRules": "配置后，满足多条分仓规则时，优先分配给优先级序号小的仓库生成出库单，并处理库存",
    "warehouseWithHighRulePriority": "规则优先级高的仓库",
    "afterConfigurationWhenMultipleWarehouseAllocation": "配置后，满足多条分仓规则时，优先分配给可用库存足够的仓库生成出库单，并处理库存",
    "fullyStockedWarehouse": "库存充足的仓库",
    "priorityOfWarehouseDivision": "分仓优先级",
    "promptThisSettingAffectsWarehouseAllocation": "提示：该设置影响仓库分配规则，请务必谨慎操作",
    "temporarilyNotOpen": "暂不开启",
    "theSwitchIsNotTurnedOn": "开关未开启，分仓规则创建成功后不会执行",
    "theSwitchOrderSettingsdistributionSettingsorderAssociated": "尚未开启「订单设置-配货设置-订单关联库存」开关",
    "separateWarehouseSetting": "分仓设置",
    "afterAddingRulesByYourselfWhen": "自行添加规则后，符合添加的规则时，将优先按照您设定的规则分配仓库",
    "matchAccordingToTheWarehouseIn": "默认按照MSKU最新入库单内的仓库进行匹配",
    "downloadApp": "下载APP",
    "manageYourStoreAnytimeAnywhereGinee": "随时随地，管理你的店铺，Ginee App全新上线",
    "shippingMethodPriorityPickUp": "发货方式优先级：Pickup > Dropoff > Manual Ship",
    "logisticsCompaniesThatDoNotSet": "未设置发货规则的物流公司将根据支持的发货方式进行发货",
    "pleaseSelectALogisticsCompanyFor": "请为你的Akulaku订单选择物流公司",
    "pullThreepartyWarehouseForStorage": "拉取三方仓库存",
    "expired": "已过期",
    "deactivated": "受限制",
    "ecommercePlatform": "电商平台",
    "selfbuiltStation": "自建站",
    "financialSoftware": "财务软件",
    "originalName": "原名",
    "editStore": "编辑店铺",
    "pullData": "拉取数据",
    "reenable": "重新启用",
    "deleteStore": "删除店铺",
    "areyousureyouwanttodeletethisstore": "你确定要删除此店铺吗?",
    "TheBindingRelationshipBetweenThe": "1. 该店铺渠道商品和主商品的绑定关系会解除。\n2. 该店铺渠道商品列表信息会在Ginee被删除。\n3. 库存清单的库存将不会推送到该店铺。\n4. 您将无法看到该店铺的订单信息。\n5. 关于店铺设置模块您都无法找到该店铺。",
    "theDefinitionOfDisabledIsStore": "受限制：店铺已授权但超过套餐资源限制，该状态不会影响店铺在渠道销售。但该店铺的渠道商品会跟主SKU自动解绑、商品/订单等数据在Ginee不展示",
    "authorizationInfo": "授权信息",
    "configManagement": "配置管理",
    "germany": "德国",
    "belgium": "比利时",
    "italy": "意大利",
    "poland": "波兰",
    "authorized": "已授权",
    "authTips": "店铺授权成功后，您可在商品和订单列表页查看同步进度",
    "youDoNotHavePermissionTo": "你没有该权限店铺，所以不能修改该店铺价格。",
    "pleaseWaitPatientlyWhileTheStore": "店铺数据拉取中, 请耐心等待",
    "startPullingTheStoreDataIt": "开始拉取店铺数据, 预计需要花费几分钟, 请稍后再关注店铺数据同步情况",
    "editSucceeded": "编辑成功",
    "AreYouSureYouWantToDeleteTheAuthorization": "确定删除授权吗?",
    "areYouSureToModifyThe": "确认修改分仓设置么？",
    "CharactersLong": "长度为10-12个字符",
    "pleaseStartWithTwoLettersOr": "请以两个字母或数字“1“开头 另含10位数字",
    "pleaseEnterTheCorrectAkulakuTracking": "请输入正确的Akulaku快递单号格式",
    "transportStockChange": "在途库存：",
    "tipavailablestock": "渠道可售卖的库存总量，可用库存=仓库库存-备用库存-锁定库存-活动库存",
    "tiplockedStock": "正在等待履行，但尚未发货的订单。同步到Ginee的“新付款”或“新待支付“的订单将添加锁定的库存。",
    "tipsafetyStock": "设置库存限制并在商品的可用库存<=安全库存时收到邮件通知以补充库存",
    "tipspareStock": "在实际仓库中预留的库存总量，该库存不会在渠道中出售。",
    "tipwarehouseStock": "您仓库中的实际库存总量，仓库库存=备用库存+锁定库存+可用库存+活动库存",
    "totalAvailableStock": "总可用库存",
    "totalPromotionStock": "总活动库存",
    "totalLockedStock": "总锁定库存",
    "totalSpareStock": "总备用库存",
    "totalWarehouseStock": "总仓库库存",
    "ImageName": "图片&名称",
    "case": "案例",
    "purchasePricecalrules": "平均采购价=入库总采购价/仓库库存",
    "ISKUmeaning": "MSKU是主SKU",
    "nochange": "可用库存未变，所以店铺售卖的库存不用更新",
    "manulupdate": "无变动",
    "updatetimetip": "更新时间",
    "reasonforfailed": "导致失败的原因：商品在店铺中渠道已被删除或被禁卖 、正在参与活动（活动结束即可同步成功）",
    "TotalPromotionStock": "活动库存总数",
    "StoreSKU": "平台SKU",
    "tippromotionStock": "活动库存是为Campaign或闪购促销 活动预留的总库存量",
    "remark": "备注",
    "transportStock": "在途库存",
    "warehouseStockChange": "仓库库存：",
    "spareStockChange": "备用库存：",
    "promotionStockChange": "活动库存：",
    "lockedStockChange": "锁定库存：",
    "availableStockChange": "可用库存：",
    "oversell": "商品已超卖，请调整库存.现在推到渠道售卖的库存为0.",
    "Seethedetails": "查看详情",
    "warehouseStockerror": "库存范围是0-999,999",
    "warehouseStockrequired": "库存范围是0-999,999",
    "remarkplaceholder": "请输入更新的原因，应该少于300个字符.",
    "availablestockshort": "可用库存",
    "promotionstockshort": "活动库存",
    "lockedStockshort": "锁定库存",
    "spareStockshort": "备用库存",
    "warehouseStockshort": "仓库库存",
    "priceplaceholder": "请输入金额",
    "Barcodeplaceholder": "仅支持字母，数字和-_",
    "weightplaceholder": "范围是5,000,000",
    "upgradeResultClose": "关闭",
    "upgradeResultViewNow": "立即查看",
    "upgradeResultRetry": "重试",
    "upgradeResultFailedTitle": "库存管理专业版升级失败！",
    "upgradeResultSuccessTitle": "库存管理专业版升级成功！",
    "upgradeModalUpgradeNow": "立即升级",
    "upgradeModalCancel": "取消",
    "upgradeModalAbout": "关于库存管理专业版",
    "january5th": "2021年1月5日",
    "syncmanually": "手动同步订单",
    "upgradeModalFooterTitle": "升级请先{syncorder}，以便已有的订单都扣减库存.{updatedate}，启用库存低版本的Ginee账户将会被统一自动升级",
    "upgradeModalLabelStockWarning": "库存预警：",
    "upgradeModalLabelSoldOut": "库存不足：",
    "upgradeModalLabelAll": "全部：",
    "upgradeModalContentTitle": "您现有主SKU数量概览：",
    "upgradeModalTitleSub": "库存管理专业版是在库存管理基础上新增{val}大功能的高级版本。",
    "upgradeModalTitle": "升级为库存管理专业版",
    "upgradeBtn": "升级为库存管理专业版",
    "setstock": "设置库存",
    "setsafetyStock": "设置安全库存",
    "setspareStock": "设置备用库存",
    "setwarehouseStock": "设置仓库库存",
    "skusselected": "已选中",
    "skuselected": "已选中",
    "skus": "个SKU",
    "Viemore": "查看",
    "VariationStatus": "规格状态",
    "createnext": "创建&下一步",
    "sizelimittip": "这个字段必须在1-999,99之间！",
    "pricelimittip": "金额必须在0 〜 1,000,000,000之间！",
    "weightlimittip": "重量必须在1-5,000,000之间！",
    "validatorsize": "输入范围是1-999,99",
    "Barcodelengthtip": "条形码应该不超过30个字符",
    "Barcodeerror": "条形码仅支持字母，数字和-_",
    "mustbiggerthan": "不能小于（备用库存+锁定库存）",
    "mustlessthan": "应该小于（仓库库存-锁定库存）",
    "PackageSize": "包裹尺寸大小",
    "inputsafetyStock": "库存范围是0-999,999",
    "inputspareStock": "库存范围是0-999,999",
    "inputwarehouseStock": "库存范围是0-999,999",
    "safetyStockisRequired": "安全库存必须在0 〜 999,999之间！",
    "spareStockisRequired": "备用库存必须在0 〜 999,999之间！",
    "warehouseStockisRequired": "仓库库存必须在0 〜 999,999之间！",
    "namerequired": "i18n: namerequired",
    "Barcode": "条形码",
    "addprice": "入库采购价(Rp)",
    "averageprice": "平均采购价(Rp)",
    "ItemInformation": "商品信息",
    "stockimage": "格式: jpg, jpeg, png. 图片尺寸： less than 5MB.",
    "noresult": "无变动",
    "EMPTY": "无绑定的店铺商品",
    "FAILEDcontent": "失败：渠道返回该商品不允许更新库存",
    "inventorylogtip": "Ginee会对库存更新失败的店铺自动发起重试，您可以查看更新详情了解重试的情况或手动发起重试",
    "FAILED": "失败",
    "PART_FAILED": "部分失败",
    "UpdateCompleted": "更新成功",
    "StockWarning": "库存预警",
    "EXPIRED": "多次重试失败",
    "RETRY_SUCCEEDED": "重试成功",
    "SUCCEEDED": "成功",
    "NEED_RETRY": "失败，准备重试...",
    "PROCESSING": "推送中",
    "UpdateDetails": "店铺库存更新详情",
    "ManualUpdate": "手动更新",
    "StoreUpdateResult": "店铺更新结果",
    "Operator": "操作人",
    "Latest": "新库存",
    "Movement": "变动",
    "Original": "原库存",
    "updateDatetime": "更新时间",
    "StockUpdateFailed": "更新失败",
    "StockSuccessfullyUpdated": "更新成功",
    "Updating": "更新中",
    "UpdateToStore": "更新到店铺",
    "OrderCancel": "订单{ OrderId }被取消，原因：{ ReasonInfo }",
    "NewPaidOrder": "新支付订单",
    "UserEditStock": "用户修改库存",
    "System": "系统",
    "ChangeReason": "变更原因",
    "OriginalStock": "原库存",
    "createDatetime": "创建时间",
    "StockTime": "时间",
    "StockUserName": "用户名",
    "StockChangeHistory": "库存变更记录",
    "clicktoset": "点我设置",
    "synclosed": "库存同步已关闭，您修改主SKU的库存数量不会影响平台售卖的库存数量；想使用库存同步？",
    "syncopend": "库存同步已开启，主SKU的库存数量发生变化时，平台售卖的库存数量也将随之变化。请谨慎修改主SKU中已关联的SKU",
    "skurelation": "请输入平台SKU，用于建立SKU与主SKU的绑定关系，创建主SKU时会自动绑定同名的平台SKU",
    "stockrequired": "库存必须在0 〜 999,999之间！",
    "inventorySkurequired": "主SKU不能为空！",
    "inputpurchasePrice": "请输入金额",
    "inputname": "请输入名称",
    "inputstock": "库存范围 0-999,999",
    "inputinventorySku": "请输入主SKU",
    "addsuccess": "添加成功",
    "deletesuccess": "删除成功",
    "editsuccess": "编辑成功",
    "afterdelete": "删除主SKU后，与平台SKU的绑定关系也将删除",
    "suretodelete": "你确定要删除主SKU吗？",
    "products": "个主SKU",
    "failimport": "导入失败:",
    "sucessimport": "导入成功:",
    "totalimport": "导入总数:",
    "picture": "商品图片",
    "skuList": "SKU (平台SKU)",
    "purchasePrice": "入库采购价",
    "nameStock": "名称",
    "Stock": "库存",
    "SoldOut": "库存不足",
    "AddInventorySKU": "添加主SKU",
    "seacrh": "搜索",
    "ExportAll": "按所有页导出",
    "Exportbyselected": "按勾选导出",
    "DownloadImportTemplate": "下载导入模板",
    "ImportInventorySKU": "导入主SKU",
    "Import": "导入",
    "ok": "确定",
    "editsku": "编辑主SKU",
    "addsku": "添加主SKU",
    "skuseacrh": "搜索主SKU、SKU或名称",
    "skuplaceholder": "请填写平台销售SKU",
    "accordingToTheQueryConditionsYou": "根据你设置的查询条件，物流公司返回预估运费如下：",
    "calculationResults": "计算结果",
    "destination": "目的地",
    "shipFrom": "出发地",
    "calculationFormula": "计算公式",
    "checkShippingFee": "查询运费",
    "addAddress": "新增地址",
    "setAsDefault": "设为默认",
    "theBindingRelationshipBetweenJtExpress": "J&T Express(ID)与Ginee的绑定关系已解除，你将无法继续在Ginee使用该物流相关服务，请知悉。",
    "onceDeletedYouWillNoLonger": "一旦删除，将无法继续在Ginee使用J&T Express(ID)物流服务，已下发的订单不会清空，请谨慎操作",
    "confirmToDelete": "确认删除？",
    "shippingSettings1": "交运设置",
    "reasonTheAuthorizationInformationIsIncorrect": "原因：授权信息有误，请修改后重新授权",
    "youCanUseTheJtExpress": "你可以正常使用J&T Express（ID）服务进行预估运费查询>下单>获取快递单>打印面单>查询轨迹",
    "maxCharactersOnlyNumbers": "不超过{length}位，限数字及字母",
    "requiredToFillInPleaseGet": "必填项，请根据授权帮助获取并填写信息",
    "withQuickAuthorizationYouDontNeed": "使用快速授权，无需联系J&T Express(ID)获取授权所需信息，支持使用Ginee",
    "clickToViewAuthorizationInstruction": "点击查看授权帮助",
    "clickAuthorizeToBindAndIt": "点击【授权】绑定成功，支持下单/获取快递单号/查询运费/查询轨迹",
    "toUseAStandardAuthorizationPlease": "使用标准授权，请联系J&T Express(ID)获取授权所需的信息",
    "recomend": "推荐",
    "standardAuthorization": "标准授权",
    "quickAuthorization": "快速授权",
    "theSystemDetectsThatChannelProducts": "系统检测到渠道商品部分SKU为空，请确认是否使用规格名称和商品名称作为MSKU使用？",
    "yesApplyToAllProducts": "是，应用到全部商品",
    "productInbound": "商品入库",
    "manualOutbound": "手工出库",
    "salesOutbound": "销售出库",
    "theNumberOfConnectedStoresThat": "已超出生效套餐限制的已连接店铺数量，请继续订阅套餐或联系客服升级套餐后再启用店铺；",
    "subscriptionPackage": "订阅套餐",
    "theStoreHasBeenActivated": "店铺已启用！",
    "deleteSuccess": "删除成功",
    "fulfillConfirmDisconnectContent": "删除后，对应的{fulfillment}仓库及履约服务将不可使用，如需重新启用，请重新授权",
    "unfold": "展开",
    "collapse": "收起",
    "expiredStatusComingSoon": "即将过期",
    "ForLogisticCompanyPleaseAdd": "6、物流公司请填写新增手工订单中可选的物流公司",
    "WarehouseAsWarehouseNameNot": "4、仓库为仓库名称,非仓库编号",
    "jnePleaseFillTheLogisticThat": "JNE(请填写新增手工中可选的物流公司)",
    "whetherBlacklist": "是否黑名单",
    "pleaseSetupShipmentSettingsFirstBefore": "请先设置交运设置后选择取件地址",
    "authorizeNow": "去授权",
    "pleaseRecheckAfterAuthorizeLogistic": "请授权物流后重新查询",
    "temporaryNotAuthorize": "暂未授权",
    "oddNumbers": "单号",
    "wholesalePrice": "批发价",
    "addWholesalePrice": "添加批发价",
    "maxNumber": "Min的数量不能大于或等于Max的数量。",
    "maxNumberCannotBeLessThan": "Max的数量不能小于或等于Min的数量。",
    "theWholesalePriceCannotBeHigher": "批发价不能高于产品售价。",
    "theWholesalePriceOfCannot": "{a}的批发价不能高于{b}的批发价。",
    "wholesalePricesAreOnlyAvailableTo": "批发价只针对所有变体价格都是一样的才可以添加。",
    "productNameMinCharacter": "商品名称不能少于 {min} 个字符",
    "productNotCanNotLessThan": "商品图片不能少于{min}张。",
    "breadCrumbProductList": "商品列表 / {channel}",
    "storePermission": "店铺权限",
    "channelPermission": "渠道权限",
    "masterProductPermission": "主商品权限",
    "priceManagementPermission": "价格管理权限",
    "toEnsureTheAccuracyOfAvailable": "为保证可用库存准确，请点击“更新可用库存”获取最新库存值",
    "masterUpdateAvailableStock": "更新可用库存",
    "pleaseSelectCompleteReceiverArea": "请选择完整的收件人地区",
    "toCalculateShippingCostNeedTo": "计算运费需要填写收寄件信息和重量",
    "calculateShippingFee": "计算运费",
    "detailAddress": "详细地址",
    "needToSelectAreaDimension": "需要选择到区维度",
    "maximumCharactersNumbersOnly": "不超过30个字符, 仅限数字",
    "maximumCharactersLettersOnly": "不超过30个字符,仅限字母",
    "site": "站点",
    "gineeErrorUrllabelEmptyPleaseTry": "Ginee Error：面单URL为空，请尝试同步订单",
    "singleProductWeight": "单品重量",
    "calculate": "计算",
    "youDontHaveThisStorePermission": "你没有该店铺权限",
    "logisticsServices": "物流服务",
    "theMaximumLengthIsCharacters": "最大长度为{maxLength}个字符",
    "averagePurchasePriceCurrentInbound": "平均采购价 = ( 本次入库数量 * 本次入库采购价 + 原数量 * 原采购价 ) / （本次入库数量+原数量）",
    "tiktokMassDeleteTip": "由于Tiktok渠道接口限制，一次删除商品数量不能超过20条，请重新选择。",
    "validityofauthorization": "授权有效期",
    "channelProductMassEdit": "商品列表 / {channel}/批量编辑",
    "dataPermission": "数据权限",
    "ruleTypeSpecifyMsku": "规则类型：指定MSKU",
    "ruleTypeShopRules": "规则类型：店铺规则",
    "addNewRules": "新增规则",
    "delete1": "删除",
    "requiredItemLockSettingIf": "锁定设置—主店铺库存锁定设置开启时，当“推送策略”中的MSKU库存小于等于“阈值”时，除了选中的店铺，其他店铺的可用库存推送为0",
    "setupPushStore": "设置推送店铺",
    "requiredItemPleaseEnterAnInventory": "必填项，请输入大于等于0，小于等于99999的库存值",
    "setStockThresholds": "设置库存阈值",
    "pleaseEnterTheMskuToBe": "请输入需要添加的MSKU，多个换行，单次添加过多保存需要时间，请耐心等待",
    "manualSelection": "手动选择",
    "textRecognition": "文本识别",
    "addingMethod": "添加方式",
    "specifyMsku1": "指定MSKU",
    "allMskus": "全部MSKU",
    "addedMainStoreLock": "新增主店铺锁定",
    "afterTurnOnPleaseSetUp": "开启后，请务必设置主店铺锁定规则，单MSKU仅支持设置一条主店铺库存锁定规则\n一旦关闭该设置，所有主店铺规则默认失效",
    "turnOnMainStoreLocked": "开启主店铺库存锁定",
    "lockSettings": "锁定设置",
    "clickToView": "点击查看",
    "addMsku1": "添加 MSKU",
    "deleteMsku": "删除MSKU",
    "masterProductPicture": "主商品 & 图片",
    "pushMsku": "推送 MSKU",
    "ruleType": "规则类型",
    "ruleInformation": "规则信息",
    "massAdd": "批量新增",
    "add1": "单个新增",
    "addNewRule": "新增规则",
    "mainStoreLocked": "主店铺锁定",
    "operateBy": "操作人",
    "operationModule": "操作模块",
    "tipOnlySupportForQueryingOperation": "提示：仅支持查询近 30 天的操作日志",
    "theRuleWasDeletedSuccessfully": "规则删除成功",
    "theRuleWasSavedSuccessfully": "规则保存成功",
    "ruleDisabledSuccessfully": "规则禁用成功",
    "afterEnablingTheInventoryWillBe": "启用后，将按照当前规则计算并推送库存至渠道店铺",
    "areYouSureYouWantTo1": "确认启用规则么？",
    "afterDeletionTheRulesWillNo": "删除后，规则将不再支持管理及使用",
    "afterDisablingTheInventoryWillNo": "禁用后，将不再按照当前规则计算并推送库存至渠道店铺",
    "doYouConfirmToTurnOff": "确认禁用规则么？",
    "ifTheCustomRuleIsDisabled": "如自定义规则停用，将不再推送MSKU库存至渠道",
    "afterCheckingIfACustomRule": "勾选后，如MSKU添加自定义规则并启用，按照自定义规则计算库存并推送至渠道店铺；",
    "afterAddingACustomRuleTo": "MSKU 添加自定义规则后，不再按照默认规则推送库存",
    "applicationSettings": "应用设置",
    "newMskuAddedToWarehouseLater": "新MSKU：添加至仓库的时间晚于开启时间",
    "allMskusExcludingMskuThatAdded": "全部 MSKU（不包含后续新增的MSKU）",
    "allMskusIncludingMskuThatAdded": "全部 MSKU（包含后续新增的MSKU）",
    "newStoresStoresWhichAuthorizationTime": "新店铺：授权时间晚于开启时间的店铺；",
    "allAuthorizedStoresConnectedStoresWhose": "已授权的全部店铺：授权时间早于开启时间的已连接店铺；",
    "allConnectedStoresExcludingNewStores": "已连接的全部店铺，不包含后续授权的新店铺",
    "allConnectedStoresIncludingNewStores": "已连接的全部店铺，包含后续授权的新店铺",
    "pushRatio": "推送比例",
    "availableStockTransitStock": "可用库存+在途库存",
    "allWarehousesWithMskusInInventory": "MSKU 已入库且启用状态的全部仓库（不包含后续添加的新仓库）",
    "newWarehouseWarehouseWhoseCreationTime": "新仓库：创建时间晚于开启时间的仓库",
    "allWarehousesWithMskuThatHave": "MSKU 已入库且启用状态的全部仓库（包含后续添加的新仓库）",
    "ruleStatus": "规则状态",
    "noteThisTimeIsTheTime": "注: 该时间为“库存设置”—“库存监控与同步”开关开启时间",
    "activateTime": "开启时间",
    "viewFailureData": "查看失败数据",
    "backToRuleList": "返回规则列表",
    "fail": "失败",
    "sucess": "成功",
    "dataProcessingCompleted": "数据处理完成",
    "saveCurrentlyNotActive": "保存，暂不启用",
    "afterActivatePleaseAddMskuAs": "启用后，请尽快添加MSKU，否则规则将无法正常运行",
    "areYouSureWantToSave": "确认保存并启用规则么？",
    "stockType1": "库存类型",
    "warehouseName1": "仓库名称",
    "specifyMskuAfterCreatingTheRule": "指定MSKU (创建规则后，需自行添加MSKU，仅有添加的MSKU会按照设定规则推送)",
    "storeRulesAfterCreatingTheRules": "店铺规则 (创建规则后，无需添加MSKU，店铺内已绑定MSKU的渠道SKU均按照设定规则推送)",
    "tipsWhenAddingRulesIn": "提示：1.批量新增规则时，规则名称将由系统自动添加；2.批量新增仅保存规则信息，保存后请在规则列表设置应用的MSKU；3.单次最多添加100个；",
    "clickToViewMsku": "点击查看MSKU",
    "showAllStore": "显示全部店铺",
    "showAllWarehouses": "显示全部仓库",
    "executeTheRules": "执行规则",
    "fixedValue": "固定值",
    "storeRule": "店铺规则",
    "specifyMsku": "指定MSKU",
    "operation": "操作",
    "createBy": "创建人",
    "ratioFixValue": "比例/固定值",
    "ruleName": "规则名称",
    "exactMatch": "精确匹配",
    "multipleMskusSeparatedBySpacesMax": "多个MSKU用空格隔开最多50个",
    "pleaseFillInTheCompleteRecipient": "请填写完整的收件人地址信息",
    "pleaseSelectAPickupAddress": "请选择取件地址",
    "addMainStoreLock": "新增主店铺锁定",
    "editMainStoreLock": "编辑主店铺锁定",
    "ratio": "比例",
    "thisAddressDoesNotCurrentlySupport": "该地址暂不支持计算运费",
    "tExpressIdIsNotAuthorized": "J&T Express ID 未授权，取消将不会通知物流公司",
    "channelSku": "渠道SKU",
    "inventoryPushRulesMainStore": "库存推送规则/主店铺库存锁定",
    "inventoryPushRuleOperationLog": "库存推送规则/操作日志",
    "keywordsOfOperationContent": "操作内容关键词",
    "pleaseEnterMskuPleaseWrapMultiple": "请输入MSKU，多个MSKU请换行",
    "skuExactSearch": "SKU精确搜索",
    "viewMskuApplicationRules": "查看推送规则",
    "showAllStores": "显示所有店铺",
    "bindingCommodityInformation": "绑定商品信息",
    "analogComputation": "模拟计算",
    "unmatchedChannelGoods": "无配对的渠道商品",
    "normal": "正常",
    "viewRules": "查看规则",
    "promptThisFunctionSupportsViewingThe": "提示: 该功能支持查看当前MSKU在进行库存推送时实际使用的规则，单次最多查看50个MSKU",
    "newStoresWithoutSubsequentAuthorization": "（不包含后续授权的新店铺）",
    "newStoreWithSubsequentAuthorization": "（包含后续授权的新店铺）",
    "allConnectedStores": "已连接的全部店铺",
    "pleaseTurnOnTheInventorySynchronization": "请在库存设置菜单下打开库存同步开关后操作",
    "Ruledetails": "规则详情",
    "Default": "默认",
    "thereAreDuplicates": "存在重复项",
    "PleaseentertheMSKU": "请输入需要添加的MSKU，多个换行，单行字符不超过200个，超过系统将自动做截断处理",
    "noNeedToPush": "无需推送",
    "mskuDoesNotExist": "MSKU不存在",
    "mskuInventorypushInventory": "MSKU库存/推送库存",
    "proportional": "按比例",
    "IfTheMskuForThe": "4.如针对部门MSKU不推送至部分渠道，请创建禁用的指定MSKU规则；",
    "IfSomeMskusNeedTo": "3.如针对部分MSKU需按照指定仓库/指定店铺/指定比例或固定值进行推送，请创建指定MSKU规则；",
    "IfTheChannelStoreNeeds": "2.如渠道店铺需使用指定启用中的仓库可用库存进行推送，请创建店铺规则，设置店铺与仓库的关系及推送方式；",
    "IfTheInventoryOfAll": "1.如全部渠道店铺的商品库存都是按照全部启用中的仓库可用库存百分比进行推送，请直接使用默认规则，并设置推送比例即可；",
    "directionsForUse": "使用说明：",
    "youCanClickViewMskuApplication": "您可通过点击“查看MSKU应用规则”按钮，查询指定MSKU实际用于推送的规则",
    "thirdPriority": "第三优先级",
    "secondPriority": "第二优先级",
    "firstPrioritySpecifyMsku": "第一优先级：指定MSKU",
    "inventoryPushInstructions": "库存推送使用说明",
    "mskuCreationTimeIsLaterThan": "MSKU创建时间晚于库存推送开关开启时间",
    "theStoreBindingTimeIsLater": "店铺绑定时间晚于库存推送开关开启时间",
    "theWarehouseCreationTimeIsLater": "仓库创建时间晚于库存推送开关开启时间",
    "reasonTheDefaultRuleConditionsAre": "原因：不满足默认规则条件，请自行创建规则后推送",
    "reasonTheChannelStoreStatusIs": "原因：渠道店铺状态不是已授权，请重连店铺后推送",
    "reasonThePushRuleForChannel": "原因：渠道商品使用的推送规则已禁用，请开启规则后推送",
    "reasonInventorySettinginventoryPushSwitchIs": "原因：库存设置-库存推送开关未开启，请开启开关后推送",
    "reasonTheStatusOfChannelGoods": "原因：渠道商品状态为草稿状态，请上架商品后推送",
    "xpriceProtection": "{X}%价保",
    "order_628344ee0de79247bef8cb6694d1cd38": "错误的价格/促销",
    "fuzzySearch": "模糊搜索",
    "preciseSearch": "精确搜索",
    "historicalOrders": "历史订单",
    "nearlyDaysOrder": "近90天订单",
    "tipChannelInterfaceRestrictionOnlySupports": "提示：渠道接口限制仅支持更新已上架或已下架的商品库存",
    "theChannelStatusIsAbnormalPlease": "渠道商品状态异常，请确认后推送",
    "theWarehouseHasBeenDeactivatedPlease": "仓库已停用，请启用后推送",
    "whenSearchingAccuratelyTheMaximumNumber": "精确搜索时，单次最多查询{X}个，多个信息请使用回车换行，每行代表一个信息",
    "pleaseEnterTheOrderNumber": "请输入订单号",
    "settingSuccess": "设置成功",
    "insured": "保价",
    "insuranceRation": "保价比例",
    "pleaseSelectInsuranceService": "选择保价服务",
    "pleaseSettingInsuranceFeeForAkulaku": "请为你的Akulaku订单设置保价",
    "settingInsurance": "设置保价",
    "proportionalInsurance": "比例保价",
    "originalInsurancePrice": "原价保价",
    "notInsured": "不保价",
    "plannedInboundAmount": "计划入库金额",
    "actualInboundAmount": "实际入库金额",
    "shippingSubsidy": "运费补贴",
    "platformSubsidy": "平台补贴",
    "spuAlreadyExistsPleaseRename": "SPU已存在，请重新命名",
    "templateType": "模板类型",
    "labelSize": "标签尺寸",
    "barcodePrintingTemplateSettings": "条码打印模板设置",
    "fontSize": "字体大小",
    "howToGenerate": "生成方式",
    "barcodeHeightInsideLabel": "标签内条形码高度",
    "qrCode": "二维码",
    "qrCodeLocation": "二维码位置",
    "left": "居左",
    "top": "居上",
    "qrCodeSize": "二维码大小",
    "generatedByMsku": "按MSKU生成",
    "generatedByBarcode": "按条形码生成",
    "generatedBySpu": "按SPU生成",
    "mskuCode": "MSKU编码",
    "printContent": "打印内容",
    "customAdd": "自定义添加",
    "goToTheMasterProductPage": "去主商品页面选择商品打印",
    "customContent": "自定义内容",
    "pleaseInsertTheContentToBe": "请输入需要打印的内容，多个换行，单行字符不超过200个，超过系统将自动做截断处理。",
    "numberOfPrintsreferringToTheNumber": "打印数量(指一个条码的标签数量)",
    "paperPrintColumn": "纸张打印列",
    "columnGap": "列间隙",
    "barcodePrinting": "条码打印",
    "atLeastCharactersOrMore": "至少3字符以上",
    "theFuzzySearchContentMustExceed": "模糊搜索内容必须超过3个字符，请调整后重试",
    "ordersCreatedForMoreThan": "创建时间超过90天的订单将移入历史订单，不可处理",
    "areYouSureYouWantTo2": "确定将订单移入已收货？",
    "moveToDelivered": "移入已收货",
    "firstPriority": "第一优先级",
    "supportAddingMaxDataAt": "一次最多添加2000条数据",
    "supportAddingMaxDataAt1": "一次最多添加1000条数据",
    "stockWillBeIncreasedAfterInbound": "入库后将增加库存，请慎重操作，仅支持本地仓批量入库",
    "afterConfirmationTheDocumentWillBe": "确定后将重新推送单据到仓库",
    "stockWillBeDeductedAfterOutbound": "出库后将扣减库存，请慎重操作",
    "Days1": "30天",
    "Days": "7天",
    "clickToDownloadAttachment": "点击下载附件",
    "attachment": "附件",
    "addToTheFulfillmentWarehouse": "添加至履约仓",
    "lockedTime": "锁定时间",
    "LockedStock": "锁定库存数",
    "lockStockOrder": "锁定库存订单",
    "onlySupportsFilesDocXlsXlsx": "仅支持 *.doc、*.xls、.xlsx、PDF、Excel、png、jpeg、JPG文件需小于15M",
    "clickOrDragFilesHereTo": "点击或将文件拖拽到这来上传",
    "clickToDeleteAttachment": "点击删除附件",
    "uploadAttachment": "上传附件",
    "vatRate": "VAT税率（%）",
    "availableStockLocationCode": "货位可用库存",
    "stockWil": "出库后将扣减库存，请慎重操作，仅支持本地仓批量出库",
    "stockWillBeReleasedAfterBeing": "作废后将释放库存，请慎重操作",
    "xDataSelected": "已选{x}条数据",
    "massSetAsInvalid": "批量作废",
    "massPush": "批量推送",
    "massRefreshStatus": "批量刷新状态",
    "oneItemPerLineSupportExcel": "每行一项（支持EXCEL复制粘贴，最多可支持500）",
    "clickToMassSearch": "点击可批量搜索",
    "salesOutboundList": "销售出库单",
    "manualOutboundList": "手工出库单",
    "addTemplate": "添加模板",
    "massOutbound": "批量出库",
    "afterConfirmationTheSelectedDataWill": "确定后已选数据将重新添加至履约仓，请慎重操作",
    "massInbound": "批量入库",
    "itIsDetectedThatTheIssue": "检测到出库单已更新为待入库或已作废状态，不支持推送，请刷新后重试",
    "itIsDetectedThatTheReceipt": "检测到入库单已更新为已入库或已作废状态，不支持推送，请刷新后重试",
    "refunding": "退款中",
    "maxUploadDocuments": "最多可上传三个文件",
    "thirdPartyWarehousePushTime": "推送三方仓时间",
    "thirdPartyNumber": "三方仓单号",
    "bulkSearch": "批量搜索",
    "thisSizeAlreadyExists": "该尺寸已存在",
    "theRangeOfSupportedInputIs": "支持输入的范围为{min}-{max}",
    "skuName": "SKU名称",
    "skusellerSKUInJDSellerCenter": "SKU = JD卖家中心的卖家SKU",
    "barcodeHeight": "条形码高度",
    "QRCodeLocation": "二维码位置",
    "beLeft": "居左",
    "beTop": "beTop",
    "actualPrintingPerformanceDependsOnPrintingResults": "实际打印效果以打印结果为准",
    "printingFailedReasonIsEmpty": "打印失败，原因：{}为空不支持打印。",
    "goUpgrade": "去升级",
    "jtExpressIdOnlySupportsPaid": "J&T Express ID 仅支持付费套餐商户使用，请升级ERP套餐后重新授权",
    "ordersAreOutOfStockAnd": "订单处于缺货状态，不允许推送",
    "thereAreCurrentlyXPiecesOf": "当前共有{x}条数据，一次最多只可导出5万条数据，若需要导出更多数据请联系客服人员",
    "returnInboundListXSuccessfullyCreated": "退货入库单：{x}创建成功",
    "successXFailedX": "成功{x}条，失败{y}条",
    "homeCustomerTips": "在99大促期间(2022-09-08 20: 00-2022-09-10 23: 59)，客户统计服务下线，结果将显示为0",
    "orderOperationLogTips": "在99大促期间(2022-09-08 20: 00-2022-09-10 23: 59)仅支持查看近7天的操作日志",
    "stockPushLogTips": "在99大促期间(2022-09-08 20: 00-2022-09-10 23: 59)仅支持查看当天的操作日志",
    "adjustmentMethodAll": "调整方式：全部",
    "adjustmentMethodOutbound": "调整方式：出库",
    "adjustmentMethodInbound": "调整方式：入库",
    "numberRow1": "一列",
    "numberRow2": "二列",
    "numberRow3": "三列",
    "numberRow4": "四列",
    "theStoreAuthorizationIsAbnormalPlease": "店铺授权异常，请前往店铺后台检查授权",
    "pleaseSetUpYourJtExpress": "请先设置J&TExpress ID 交运设置",
    "theRangeOfProductImagesThat": "商品图片可以上传的范围为{min}-{max}张",
    "barcodeTemplate": "条码模板",
    "typeDimension": "{type}维度",
    "channelOrderInTheCurrentOutbound": "当前出库单内的渠道订单缺货，不满足推送条件，请在 待处理订单—问题订单 操作重新配货后推送",
    "supportsInputNumberFromMinToMax": "支持输入{min}～{max}的整数",
    "inboundListNumber": "入库单号",
    "inboundQuantity": "入库数量",
    "inboundWarehouse": "入库仓库",
    "theTotalQuantityOfASingle": "单次打印总数量不能超过{0}，请减少商品或打印个数",
    "aPrintTemplateWithTheSame": "已存在相同名称的打印模板，请重新命名",
    "printTemplateTip": "如果MSKU/条形码/SPU超过23个字符打印出来的条形码可能会无法识别，二维码没有这个限制，请结合实际情况选择模板类型。",
    "printMaxlengthTip": "字符如果超过23打印出来的条码可能会无法识别哦。",
    "pleaseSelectTheMainSku": "请选择主SKU",
    "adjustmentMethod": "调整方式",
    "purchaseInbound": "采购入库",
    "outbound": "出库",
    "inbound": "入库",
    "manualAdjustment": "手动调整",
    "sell": "售卖",
    "pleaseTickTheDataFirst": "请先勾选数据",
    "changeValue": "变动值",
    "latestLocationStock": "最终货位库存",
    "locationNumber": "货位编号",
    "postcode": "邮政编码",
    "sucGeneratedOrFailed": "成功生成{success}个，失败{fail}个",
    "printProductBarcode": "打印商品条码",
    "allPermissions": "无子权限",
    "pleaseSelectAtLeastOneExport": "请至少选择一项导出内容",
    "pleaseSelectAtLeastOneBasic": "请至少选择一项基本信息",
    "inboundType": "入库类型",
    "orderIdXSuccessfullyReprintedThe": "订单号：{X}补打快递单成功",
    "orderIdXSuccessfullyPrintedThe": "订单号：{X}成功打印快递单",
    "setAsInvalidPopulerPrintOrder": "作废爆款打单任务：{X}",
    "createPopularProductPrintOrderTask": "生成爆款打单任务：{X}",
    "theOrderIsAlreadyInThe": "订单已处于爆款打单任务中，请谨慎打印防止重复打单",
    "orderQuantity": "订单数",
    "numberOFProducts": "商品数",
    "taskId": "任务号",
    "printProgress": "打印进度",
    "setAsInvalid": "作废",
    "alreadySetAsInvalid": "已作废",
    "unfinished": "未完成",
    "creating": "生成中",
    "labelPrintingStatus": "快递单打印状态",
    "additionalPrintLabel": "补打快递单",
    "size": "尺寸",
    "ordinaryPrintingButPrintingUpTo": "普通打印（单次打印最多打印300笔订单，耗时较长）",
    "selectedPrintDataXTheSystem": "已选中打印数据：{X}条，系统检测其中{Y}个快递单已标记打印成功，{Z}个未打印",
    "taskDetails": "任务详情",
    "toPrintLabel": "去打单",
    "manyProductsWithManyQuantity": "多品多数",
    "singleProductWithManyQuantity": "单品多数",
    "singleProductWithSingleQuantity": "单品单数",
    "theTaskIsBeingCreatedPlease": "任务生成中，请稍后前往爆款打单中查看",
    "createTask": "生成任务",
    "noteOrdersThatHaveBeenPlaced": "注意：已在爆款打单任务中的订单不会进入其他任务中",
    "totalOrders": "订单总数：",
    "createdByFilter": "按筛选条件生成",
    "createByTick": "按勾选项生成",
    "printPopularProduct": "爆款打单",
    "adjustmentNo": "调整编号",
    "warehouseReplacementTask": "仓库更换任务",
    "warehouseChangeSucceeded": "换仓成功",
    "warehouseChangeFailed": "换仓失败",
    "currentWarehouse": "当前仓库",
    "newWarehouse": "新仓库",
    "processingProgress": "处理进度",
    "adjustmentType": "调整类型",
    "thisIsTheInboundPurchasePrice": "这是入库采购价或出库采购价",
    "theFinalLocationInventoryOnlyIndicates": "最终货位库存仅表示商品在该货位的库存，SKU的仓库库存=SKU在仓库的各个货位库存之和",
    "changeDimension": "更换维度",
    "Replace": "重新更换",
    "completeInboundStatus": "已完成",
    "setTemplate": "设置模板",
    "onlyNumberAndMaxLimit": "不超过{max}个字符, 仅限数字",
    "minimalOrderForWholesaleMustBe": "批发的最小订单必须大于1",
    "pleaseConfirmWhetherTheOrderMoved": "请确认是否将订单移入已发货",
    "moveToShipping": "移入发货中",
    "priceMustBeCheaperThanThe": "Price must be cheaper than the previous tier",
    "specificationInformation": "规格信息",
    "theThirdPartyLogisticsIsNot": "三方物流未授权，取消订单将不会通知物流公司",
    "deliverBy": "配送",
    "pickupBy": "取货",
    "ParcelInformation": "包裹信息",
    "theSameOptionValueExists": "存在相同的的选项值",
    "pleaseEnterOptionsSuchAsRed": "请输入选项, 如红色",
    "uploadFailed": "上传失败",
    "youCanUploadUpToMaxcount": "最多可以上传 {maxCount} 个文件, 已取消上传文件:",
    "fileSizeExceedsLimitMaxsize": "文件大小超过限制: {maxSize}",
    "pictureResolutionExceedsTheLimit": "图片分辨率超过限制",
    "maxOrderCannotBeLessThan": "Max的数量不能小于或等于Min的数量",
    "clickDownloadAndTheSystemWill": "点击下载，系统将按照您在订单列表选择的筛选条件及订单创建时间进行导出，请务必知悉后操作",
    "wholesalePriceCanNotBeLower": "批发价不能低于原价的{m}%，请设置合理的批发价",
    "mustBeAtLeastX": "大小必须至少为300 x 300像素，小于2MB，JPG格式，PNG格式",
    "thereAreDocumentsThatHaveNot": "存在未上传成功的文件",
    "ifYouContinueToSubmitThe": "如继续提交, 则提交数据将会忽略未上传成功的文件",
    "continueToSubmit": "继续提交",
    "thirdPartyLogisticsOnlySupportsThe": "三方物流仅支持付费套餐商户使用，请升级ERP套餐后重新授权",
    "numbering": "编号",
    "thereIsNoStoreThatSupports": "没有支持克隆的店铺, 请先绑定店铺",
    "productCloneInProgress": "商品克隆中...",
    "countProductClonedTo": "{count}个商品克隆到",
    "exportProgress": "导出进度",
    "exportType": "导出类型",
    "copyToDraft": "克隆成草稿",
    "invalid": "失效",
    "editImage": "编辑图片",
    "editName": "编辑名称",
    "editDesc": "编辑描述",
    "editInfo": "编辑信息",
    "printLabelStatus": "面单打印状态",
    "platformOrderNumber": "平台订单号",
    "markAsFakeOrder": "刷单标记",
    "noteOrdersThatHaveBeenPlaced1": "注意：已在爆款打单任务中的订单将不会进入其他任务中",
    "theNumberOfVariantCannotExceed": "规格数量不能超过{count}",
    "stepConfirmThePictureInformation": "第一步：确认图片信息",
    "stepSelectTheProductYou": "第二步：选择需要应用的商品",
    "editImagesInBatches": "批量编辑图片",
    "productImageTip": "商品图片，最多{maxLength}张，API限制,尺寸最大{size}MB，分辨率必须大于{width}*{height}px",
    "fakeOrder": "刷单",
    "whetherFakeOrder": "是否刷单",
    "massSettingFakeOrder": "批量设置刷单标记",
    "copyAsDraftTip": "个商品克隆成草稿",
    "orderXCancelsTheFakeOrder": "订单{X}取消刷单标记",
    "orderXIsMarkedAsA": "订单{X}标记为刷单订单",
    "fakeOrders": "订单刷单",
    "menuProductShopify": "商品列表 / Shopify",
    "menuProductShopifyAdd": "商品列表 / Shopify / 添加商品",
    "menuProductShopifyEdit": "商品列表 / Shopify / 编辑商品",
    "restrictedByJtExpressTheChinese": "受\"J&T Express“限制“商品中文名称”不可超过100；“商品英文名称”不可超过200“；\"商品编码\"不可超过50；请修改商品信息后重新下发商品",
    "tryingToGetChannelShippingLabel": "渠道面单信息获取中，请稍后重新推送",
    "rangeFieldMessage": "此字段必须在{min}〜{max}之间",
    "duringThePromotionPeriod": "在大促期间(2022-10-09 20: 00-2022-10-10 23: 59)，客户统计服务下线，结果将显示为0",
    "onlySupportedDuringTheBigPromotion": "在大促期间(2022-12-31 20: 00-2023-01-01 23: 59)仅支持查看近7天的操作日志",
    "onlyViewingTheOperationLogOf": "在大促期间(2022-12-31 20: 00-2023-01-01 23: 59)仅支持查看当天的操作日志",
    "gineeIsPushingInformationToThe": "Ginee 正在为您推送信息至仓库，并等待三方仓库接口返回推送结果，您可以继续处理其他订单或停留在订单列表查看推送结果",
    "theDistributedWarehouseTaskWasCreated": "下发仓库任务创建成功",
    "clickToConfirmTheSystemWill": "点击确认后，系统将调用三方仓库接口为您推送信息，推送结果需等待三方仓库接口返回，请耐心等待",
    "confirmThatTheSelectedOrderWill": "确认将选中的订单通知三方仓库处理么？",
    "tier": "Tier",
    "min": "Min",
    "max": "Max",
    "afterConfirmationTheOrderWillBe": "确认后订单将进入推送中，请稍后刷新页面查看推送结果",
    "onceThePushFailsPleaseTry": "旦推送失败，请在推送仓库>推送失败 中尝试重新推送",
    "noteThe": "注：渠道订单状态及快递单更新存在延迟，可能导致推送失败，一",
    "theOrderIs": "正在为你推送订单至三方仓库，请勿重复操作，请移步至推送仓库>推送中查看推送进度",
    "shipmentFailed": "发货失败",
    "afterAutomaticDeliveryIsEnabledThe": "开启自动发货后，满足发货条件的订单将自动填入寄件信息后发货，寄件信息不满足发货条件的需要手动发货",
    "defaultFirst": "默认第一个",
    "defaultLast": "默认最后一个",
    "pickUpTime": "取件时间：",
    "automaticShipping": "自动发货",
    "warehouseToBeAssigned": "待分配仓库",
    "pushWarehouse": "推送仓库",
    "tryingToLoad": "正在拼命加载中......",
    "orderTotalTotalProductAmount": "订单总额=商品总金额+运费+税费+保险费+服务费+佣金-折扣总额",
    "onceVoidedTheOrderWillBe": "作废后，订单将移入已作废",
    "addedChannelProducts": "添加了渠道商品",
    "syncedChannelProducts": "同步了渠道商品",
    "syncedChannelProductsChangedFrom": "同步了渠道商品，{1}由{2}改为{3}",
    "produkChannelTelahDisinkronkanDanInformasi": "同步了渠道商品，渠道返回渠道SKU{}已删除",
    "theChannelProductsAreSynchronizedAnd": "同步了渠道商品，渠道返回新增渠道SKU{}",
    "editedProductNameFromTo": "更新了商品名称，从{0}变为{1}",
    "editingProductCategoryFromTo": "编辑了商品类目，从{1}变为{2}",
    "editingLongDesecriptionForVariant": "更新了商品长描述，从{0}变为{1}",
    "editingPriceForVariantFrom": "更新了规格名称{0}的价格，从{1}变为{2}",
    "editedStockChannelVariantName": "更新了规格名称{0}的库存，从{1}变为{2}",
    "editedChannelSkuVariantName": "更新了规格名称{0}的渠道SKU，从{1}变为{2}",
    "deletedVariantType": "删除了规格类型{}",
    "addedVariantType": "新增了规格类型{}",
    "deletedOption": "在规格类型{1}下删除了选项{2}",
    "addedOption": "在规格类型{1}下新增了选项{2}",
    "updatedProductImage": "更新了商品图片",
    "updatedVariantImageForVariatName": "更新了规格名称{}的规格图片",
    "updatedOfProductSizeFrom": "更新了商品尺寸的{1}，由{2}改为{3}",
    "updatedProductWeightChangedFrom": "更新了商品的重量，由{0}改为{1}",
    "addTheCheckedLogisticAnd": "新增了勾选的物流为{1}，去除勾选的物流为{2}",
    "deleteChannelProduct": "删除了渠道商品{}",
    "copyProductToChannelStore": "克隆了商品{1}到{2}渠道{3}店铺",
    "thisItemIsListed": "上架了该商品{}",
    "thisItemHasBeenRemoved": "下架了该商品{}",
    "variationNa": "规格名称是{0}，渠道SKU是{1}符合自动绑定的规则，绑定了MSKU{2}",
    "variationNameTheChannelSku": "规格名称是{0}，渠道SKU是{1}符合自动创建的规则，创建了MSKU{2}",
    "variatio": "规格名称是{0}，渠道SKU是{1}，手动绑定了MSKU{2}",
    "variationNameIsChannelSku": "规格名称是{0}，渠道SKU是{1}，手动创建了MSKU{2}",
    "mskumanuallyUnboundTheCorrespondingChannelSku": "MSKU{1}在主商品页面手动解绑了对应的渠道SKU{2}",
    "sinceStoreHasBeenDeletedchannel": "由于店铺{1}已删除授权，渠道SKU{2}解绑MSKU{3}",
    "sinceMskuHasBeenRemovedchannelSkuwas": "由于MSKU{1}已被删除，渠道SKU{2}解绑MSKU{3}",
    "manuallyAddedMasterProduct": "手动添加了主商品",
    "manuallyCreatedMasterProductByStore": "手动按店铺创建了主商品",
    "manuallyCreatedMasterProductByChannel": "手动按渠道商品创建了主商品",
    "createdMasterProductByImportData": "导入创建了主商品",
    "autoCreateMasterProduct": "自动创建了主商品",
    "createdMasterProductByScrapping": "通过采集创建了主商品",
    "editedMasterProductNameFrom": "编辑了主商品名称，从{0}变为{1}",
    "editedMasterProductCategoryFrom": "编辑了主商品类目，从{0}变为{1}",
    "editedMasterProductSpuFrom": "编辑了主商品的SPU，从{0}变为{1}",
    "editedMasterProductShortDescriptionFrom": "编辑了主商品短描述，从{0}变为{1}",
    "editedTheMasterProductLongDescription": "更新了主商品长描述，从{1}变为{2}",
    "editedMskuPriceFromTo": "编辑了MSKU{0}的价格，从{1}变为{2}",
    "editedAvailableStockForMskuFrom": "编辑了MSKU{0}的可用库存，从{1}变为{2}",
    "editedBarcodeOfMskuFrom": "编辑了MSKU{0}的条形码，从{1}变为{2}",
    "updatedOfMasterProductSize": "更新了主商品尺寸的{1}，由{2}改为{3}",
    "updatedTheWeightOfMasterProduct": "更新了主商品的重量，由{1}改为{2}",
    "updatedAvailableStockTheUpdatedStock": "更新了可用库存，更新后的库存为{}",
    "deleteMaste": "删除了主商品{}",
    "deleteMskus": "删除了MSKU{}",
    "printingSpus": "打印了SPU{}的条码",
    "printingMskuBarcode": "打印了MSKU{}的条码",
    "printingBarcode": "打印了条形码{}的条码",
    "mskuHasBeenAddedTo": "MSKU{1}已添加至{2}仓库",
    "mskuHasBeenAddedAndInbound": "MSKU{1}已添加至{2}仓库并入库。",
    "theAutomaticBindingOfMasterSku": "开启了自动绑定主SKU规则，规则为：{}",
    "allStoresEnabledDefaultConfigurationFor": "全部店铺开启(新授权的店铺默认配置)",
    "activateByChanelsChanels": "按渠道开启，渠道{}",
    "openByStoreStore": "按店铺开启，店铺{}",
    "theAutomaticBindingOfTheMaster": "关闭了自动绑定主SKU规则。",
    "theAutomaticCreateMasterSkuRule": "开启了自动创建主SKU规则，规则为：{}",
    "turnOffTheAutomaticCreateMaster": "关闭了自动创建主SKU规则",
    "newPrice": "新价格",
    "priceCannotBeModifiedDuringThe": "促销期间不能修改产品价格",
    "thereAreDuplicateExportTasksRemaining": "存在重复的导出任务,剩余过期时间:{0}s",
    "theSingleUrlCannotBeEmpty": "面单URL不可为空，请稍后重新推送",
    "theLogisticsProviderCannotBeEmpty": "物流供应商不可为空，请联系仓库服务商添加物流供应商信息后重新推送",
    "awbNumberCannotBeEmptyPlease": "物流单号不可为空，请稍后重新推送",
    "syncSuccessfully": "同步成功",
    "syncFailed": "同步失败",
    "sourceOfError": "错误来源",
    "channelErrorPleaseContactTheChannel": "渠道错误：请联系渠道排查",
    "warehouseErrorPleaseContactTheWarehouse": "仓库错误：请联系仓库服务商排查",
    "unknownErrorPleaseContactGineeErp": "未知错误：请联系Ginee OMS客服排查",
    "recreate": "重建",
    "failedRecreate": "重建失败",
    "outboun": "出库单号：{x}关联订单号{y}创建成功；履约商：{z}出库仓库：{s}",
    "outOrderNumberXAssociatedOrder": "出库单号：{x}关联订单号{y}重建成功；履约商：{z}出库仓库：{s}",
    "orderNumberXAssociatedOrder": "出库单号：{x}关联订单号{y}重建失败",
    "rderNumberXAssociatedOrder": "出库单号：{x}关联订单号{y}”未推送“；履约商：{z}出库仓库：{s}",
    "oer": "出库单号：{x}关联订单号{y}”推送成功“；履约商：{z}出库仓库：{s}",
    "tboundder": "出库单号：{x}关联订单号{y}”推送失败“；履约商：{z}出库仓库：{s}；失败原因：{a}",
    "odOrderNumberXAssociatedOrder": "出库单号：{x}关联订单号{y}”出库成功“；履约商：{z}出库仓库：{s}",
    "outboundOrderNumberXAssociatedOrder": "出库单号：{x}关联订单号{y}”刷新成功“；履约商：{z}出库仓库：{s}",
    "inboundOrderNumberXAssociatedOrder": "出库单号：{x}关联订单号{y}”刷新失败“；履约商：{z}出库仓库：{s}；失败原因：{a}",
    "orderwe": "出库单号：{x}关联订单号{y}”已作废“；履约商：{z}出库仓库：{s}；",
    "outboundOrderNumberXRelatedOrder": "出库单号：{x}关联订单号{y}；SKU:{w}”已作废“；履约商：{z}出库仓库：{s}；",
    "outboundOrderNumberXHasBeen": "出库单号：{0} 已删除，关联订单{1}  删除原因：订单已搁置",
    "inboundNoXPerformerYInbound": "入库单号：{x}   履约商：{y}，入库仓库：{z} 新建入库单",
    "inboundNoXNotPushedPerformer": "入库单号：{x};\"未推送“；履约商：{y}，入库仓库：{z}",
    "inboundNumberXSuccessfulPushPerformer": "入库单号：{x};\"推送成功“；履约商：{y}，入库仓库：{z}",
    "inboundNoXPushFailedPerformer": "入库单号：{x};\"推送失败“；履约商：{y}，入库仓库：{z}；失败原因{a}",
    "inboundNoXRefreshSuccessfulPerformer": "入库单号：{x};\"刷新成功“；履约商：{y}，入库仓库：{z}",
    "nomorInboundXRefreshGagalPerformer": "入库单号：{x};\"刷新失败“；履约商：{y}，入库仓库：{z}；失败原因：{a}",
    "inboundNoXinvalidperformerYInboundWarehouse": "入库单号：{x};\"已作废“；履约商：{y}，入库仓库：{z}",
    "inboundNoXskuainvalidperformerYInboundWarehouse": "入库单号：{x};SKU:{a};\"已作废“；履约商：{y}，入库仓库：{z}",
    "inboundNoXwaitingInboundPerformerY": "入库单号：{x};\"待入库“；履约商：{y}，入库仓库：{z}",
    "receiptNoXinboundPerformerYInbound": "入库单号：{x};\"已入库“；履约商：{y}，入库仓库：{z}",
    "push": "推送",
    "productsChannelEditProduct": "商品列表 / {channel} / 编辑商品",
    "productsChannelAddProduct": "商品列表 / {channel} / 添加商品",
    "dueToPossibleDelaysInSyncing": "由于同步数据可能存在延迟，同步后请耐心等待几秒钟后再选择品牌。",
    "pleaseEnterBrandKeywordsToSync": "请输入品牌关键词进行同步",
    "cantFindTheBrandClickMe": "找不到品牌？点我同步试试",
    "outboundCompletionTime": "出库完成时间",
    "theImageAspectRatioIsInvalid": "图像宽高比例无效, 仅支持宽高比例为{ratio}的图像",
    "theFileWillBeDisplayedSynchronously": "文件将同步展示在导出历史列表，文件仅会保留15天，请及时下载",
    "exportRecords": "导出记录",
    "theFollowingOrdersNeedToBe": "以下订单需要添加发货信息后才能发货，Ginee已经自动过滤了状态仍为Pending不允许发货的{x}个Shopee订单",
    "arrangementFailed": "安排失败",
    "arranging": "安排中",
    "pleaseGoToPendingPrintOrder": "请前往待打单生成爆款打单任务",
    "limitasiApiSkuTidakDapatDi": "API限制，SKU暂不支持编辑",
    "ordersThatAreNotBoundTo": "未绑定MSKU的订单不会进入爆款打单任务",
    "customShop": "自定义店铺",
    "continueCreate": "继续创建",
    "theStoreWasCreatedSuccessfully": "店铺创建成功",
    "warehouseLevelAllPages": "仓库级别-所有页",
    "warehouseLevelClickToCheck": "仓库级别-按勾选",
    "locationLevelAllPages": "货位级别-所有页",
    "locationLevelClickToCheck": "货位级别-按勾选",
    "inboundManagementoperationLog": "入库管理/操作日志",
    "outboundManagementoperationLog": "出库管理/操作日志",
    "inboundNumberXxxxPushedToWarehouse": "入库单号：{xxxx}推送至至仓库:{XXXX}的任务失败",
    "reasonTheOrderNumberXxxxHas": "原因：订单号{xxxx} 已满足推送条件",
    "inboundNoXxxxHasBeenSuccessfully": "入库单号：{xxxx}已成功推送至仓库:{XXXX}，并获取{XXXX}仓单号:#xxxxx#",
    "inboundNumberXxxxHasSuccessfullyCreated": "入库单号：{xxxx}已成功创建推送至仓库:{XXXX}的任务，正在等待{XXX}仓库接口返回结果",
    "xxWarehouse": "{XX}仓库",
    "failureReasonXxxxErrorSourceXxxxx": "失败原因：{XXXX}，错误来源：{XXXXX}",
    "outboundNumberFailedToPushXxxx": "出库单号：{xxxx}推送至{XXXX}失败",
    "outboundNumberXxxxHasBeenSuccessfully": "出库单号：{xxxx}已成功推送至{XXXX}，并获取三方仓单号:{xxxxx}",
    "outboundNumberXxxxHasSuccessfullyCreated": "出库单号：{xxxx}已成功创建推送至仓库:{XXXX}的任务，正在等待{履约服务商}仓库接口返回结果",
    "inboundOrderPushed": "入库单推送",
    "outboundOrderPushed": "出库单推送",
    "sendersFullAddress": "寄件人详细地址",
    "sendersPostalCode": "寄件人邮编",
    "senderArea": "寄件人地区",
    "manualPush": "手动推送",
    "pendingOrderOldVer": "待处理订单（旧版）",
    "pleaseGoToTheDeliverySettings": "请前往交运设置填写",
    "noCustomStoreHasBeenDetected": "检测到暂无自定义店铺, 请先创建自定义店铺。",
    "goToCreate": "前往创建",
    "enableAtLeastOneVariation": "至少启用一项规格",
    "createdAChannelProductBy": "通过 {1} 途经, 店铺 {2} 创建了渠道商品 {3}",
    "storeSyncedChannelProduct": "店铺 {1} 同步了渠道商品 {2}",
    "ChangedFromTo": "{1} 由 {2} 改为 {3}",
    "deletedSkuWithVariantName": "删除了规格名称为 {} 的SKU",
    "addedSkuWithVarianName": "新增了规格名称为 {} 的SKU",
    "throughStoreDeleted": "通过 {1} 途经, 店铺 {2} 删除了渠道商品 {3}",
    "deletedVar": "删除了规格类型 {}",
    "addedVa": "新增了规格类型 {}",
    "updatedVarianImageWithVarianName": "更新了规格名称为 {} 的规格图片",
    "updatedProductSizeAndWeightInfo": "更新了商品尺寸重量相关, {1} 由 {2} 改为 {3}",
    "logisticsUpdatedChangedFrom": "更新了物流, {1} 由 {2} 改为 {3}",
    "atLeastOneSpecificationIsEnabled": "至少开启一项规格",
    "addMasterProduct": "添加主商品",
    "editMasterProduct": "编辑主商品",
    "autoCreateMasterSku": "自动创建主SKU",
    "autoBindMasterSku": "自动绑定主SKU",
    "addToWarehouse": "添加至仓库",
    "masterProductOperationLog": "主商品操作日志",
    "solutionPleaseFirstCheckWhetherThe": "解决方案：请先检查店铺是否属于Official Store或者Power Merchant店铺类型。如果不是请先在卖家中心升级店铺，如果是，请在卖家中心检查店铺授权是否解绑了。",
    "updatedProductLengthFromTo": "更新了商品的长，由{0}改为{1}",
    "updatedProductWidthFromTo": "更新了商品的宽，由{0}改为{1}",
    "updatedProductHeightFromTo": "更新了商品的高，由{0}改为{1}",
    "addedMsku": "新增了MSKU{}",
    "onlyIntegersAreSupported": "仅支持整数",
    "masstSku": "批量编辑SKU",
    "newOriginalPrice": "新原价",
    "newselingPrice": "新售价",
    "applicationCompletion": "应用完成",
    "editPrice": "编辑价格",
    "theStatusOfProductHas": "商品{1}状态发生变更，由{2}改为{3}",
    "theChannelPriceOfMsku": "MSKU{1}在店铺{2}的渠道价格发生变化，由{3}改为{4}",
    "defaultPriceOfMskuChanged": "MSKU{1}的默认价格由{2}改为{3}",
    "solutionPleaseSetThePickupWarehouse": "解决方案：请在卖家中心的My account>seller profile>warehouse设置Pickup warehouse的信息。",
    "afterSuccessfulAuthorizationYouCanUse": "授权成功后，可使用 Keeppack 履约服务，进行商品出入库管理；使用Keeppack仓库的库存及商品信息会自动进行双向同步，提升管理效率",
    "clickTheGoToAuthorizationButton": "点击【去授权】按钮，您将访问 Keeppack 登录页面，请输入用于登录 Keeppack 系统的账号密码，由 Keeppack 系统确认您的登录信息无误后，您就将 Keeppack 成功授权到 Ginee 系统啦",
    "beSureToCompleteTheMatching": "请务必在【仓库-Keeppack仓库-商品信息】完成主商品配对",
    "ifThereIsTooMuchCommodity": "如商品数据过多，会花费较长时间，请耐心等待",
    "imAutomaticallyPullingTheKeeppackWarehouse": "正在为您自动拉取 Keeppack 仓库列表及商品信息",
    "automaticallyPullCommodityInformation": "自动拉取商品信息",
    "automaticallyPullWarehouseInformationValidWarehouse": "自动拉取仓库信息(限有效仓库)",
    "senderMobilePhone": "寄件人手机",
    "theBindingRecordIsOnlyValid": "绑定记录仅有30天有效期，超出30天的绑定记录将无法查看",
    "pullRecord": "拉取记录",
    "batchPulling": "批量拉取",
    "mskuBinding": "MSKU绑定",
    "bindingMsku": "绑定MSKU",
    "pullTime": "拉取时间",
    "commodityValue": "商品价值",
    "theTotalValueOfGoodsReturned": "三方仓接口返回的商品在本仓库内的总价值",
    "totalWarehouseInventoryReturnedByThreeparty": "三方仓接口返回的仓库总库存",
    "inventoryWaitingForReceiptReturnedBy": "三方仓接口返回的等待入库的库存",
    "availableInventoryReturnedByThreepartyWarehouse": "三方仓接口返回的可用库存",
    "gineePushesItToTheWarehouse": "由Ginee推送至仓库创建",
    "sanfangcangSku": "三方仓SKU",
    "nameOfGoodsInTheWarehouse": "三方仓接口返回的仓库内商品名称",
    "commodityNameOfWarehouse": "仓库商品名称",
    "mskuIsBound": "已绑定MSKU",
    "unboundMsku": "未绑定MSKU",
    "startWithAndBeNo": "以0开头，不超过12个数字",
    "noMoreThanEnglishCharacters": "不超过35个英文字符",
    "usedToPushReceiptDocumentsTo": "用于入库单推送至Keeppack",
    "xxxWarehousesHaveBeenAddedWith": "新增 {XXX} 个仓库，仓库ID：{XXX},{XXX}",
    "OnceTheKeeppackInterfaceUpdates": "2.一旦 Keeppack 接口更新仓库状态为 Disabled，Ginee 内的仓库将同步停用，无法再同步商品/库存信息",
    "gineeOnlyReturnsTheWarehouseWith": "1.Ginee 仅为您同步 Keeppack 接口返回渠道状态为 Active 的仓库",
    "warmTip": "温馨提示",
    "startPullingTheLatestWarehouseInformation": "点击\"确认\"后, 将开始为您拉取 Keeppack 的最新仓库信息，等待Keeppack接口返回信息，请耐心等待",
    "pullWarehouseInformation": "拉取仓库信息",
    "pullLog": "拉取日志",
    "warehouseUpdateTimeReturnedByKeeppack": "Keeppack 接口返回的仓库更新时间",
    "warehouseCreationTimeReturnedByKeeppack": "Keeppack 接口返回的仓库创建时间",
    "channelStatus": "渠道状态",
    "warehouseId": "仓库ID",
    "senderInformation": "寄件人信息",
    "automaticCreationOfMainCommodity": "自动创建主商品",
    "automaticUnbindingMsku": "自动解绑MSKU",
    "automaticBindingMsku": "自动绑定MSKU",
    "manuallyCreateTheMainCommodity": "手动创建主商品",
    "manuallyUnbindMsku": "手动解绑MSKU",
    "manuallyBindMsku": "手动绑定MSKU",
    "startBinding": "开始绑定",
    "andAllUnpairedThreepartyWarehouseSkus": "当前及后续新增的全部未配对的全部三方仓SKU",
    "allCurrentlyUnpairedThreepartyWarehouseSkus": "当前未配对的全部三方仓SKU",
    "appliedRange": "应用范围",
    "whenTheThreepartyWarehouseSkuIs": "三方仓 SKU 与 已创建主商品 MSKU 完全一致时，自动绑定",
    "bindingRules": "绑定规则",
    "reasonItConformsToTheMsku1": "原因：符合商品设置-仓库商品自动创建MSKU规则",
    "reasonThreepartyWarehouseHasBeenUnbound": "原因：三方仓库已解绑",
    "reasonItConformsToTheMsku": "原因：符合商品设置-仓库商品自动绑定MSKU规则",
    "goodsOfWarehouseXxxxxXxxxxSku": "仓库:{XXXXX}的商品:{XXXXX},三方仓SKU:{XXXXX}成功在系统内创建主商品:{XXXXX},MSKU:{XXXXX}",
    "commodityXxxxxOfWarehouseXxxxxThreeparty": "仓库:{XXXXX}的商品:{XXXXX},三方仓SKU:{XXXXX}与MSKU:{XXXXX}解除绑定",
    "commodityXxxxxOfWarehouseXxxxxAnd": "仓库:{XXXXX}的商品:{XXXXX},三方仓SKU:{XXXXX}与MSKU:{XXXXX}绑定成功",
    "viewThePullRecord": "查看拉取记录",
    "gineeIsPullingCommodityInformationFrom": "Ginee 正在为您从仓库拉取商品信息，并等待三方仓库接口返回结果，您可以继续处理其他事宜或在拉取记录查看拉取进度及结果",
    "createAPullTask": "创建拉取任务",
    "commodityCreationTime": "商品创建时间",
    "inOrderToImproveThePulling": "为提高拉取效率，单次拉取时长不超过15天；",
    "bindingResultXSkusHaveBeen": "绑定结果：已为您成功完成 {X} 个SKU与MSKU的自动绑定",
    "automaticBindingSucceeded": "自动绑定成功",
    "mskuCreationIsNotSupportedFor": "已绑定MSKU的三方仓SKU不支持创建MSKU，系统会为您自动过滤，请务必知悉",
    "mainProductImage": "主商品图片",
    "AfterUnbindingTheStatusOf": "3. 解绑后，该三方仓商品绑定MSKU对应的出入库单单据状态更新为已作废。",
    "AfterUnbindingTheInventoryList": "2. 解绑后，该三方仓商品绑定MSKU产生的库存清单数据会清空。",
    "AfterUnbindingTheBindingRelationship": "1. 解绑后，该三方仓商品和主商品绑定关系会解除。",
    "systemRecommended": "系统推荐",
    "listingPullFailed": "商品信息拉取失败",
    "theListingWasPulledSuccessfully": "商品信息拉取成功",
    "pullResultTheSkuInformationOf": "拉取结果：三方仓SKU信息已拉取完成,SKU：XXXX/XXXX",
    "sourceOfErrorRepository": "错误来源：仓库",
    "reasonForFailureXxxxxx": "失败原因：XXXXXX",
    "pullResultAddedXxxPiecesOf": "拉取结果：拉取到{XXX}条数据",
    "resultcause": "结果/原因",
    "pullStatus": "拉取状态",
    "pullDuration": "拉取时长",
    "noteThatPullRecordsAreOnly": "请注意，拉取记录仅会在系统内保留 7 天，超出 7 天的拉取记录无法查询",
    "xxxData": "{XXX}数据",
    "viewFailureInformation": "查看失败信息",
    "upToCommoditiesCanBe": "单次最多支持选择50个商品进行活动信息拉取",
    "activityInformationPullFailed": "活动信息拉取失败",
    "pullResultXxxChannelCommodityActivity": "拉取结果：已为您成功拉取 {X} 个渠道商品活动信息",
    "activityInformationPullSucceeded": "活动信息拉取成功",
    "onlyTheActivityInformationOfChannel": "仅支持拉取已同步到Ginee，且商品状态为已上架的渠道商品的活动信息",
    "pullByCommodity": "按商品拉取",
    "pullByStore": "按店铺拉取",
    "pullActivity": "拉取活动",
    "activeReservationSettings": "活动预留设置",
    "synchronizationTime": "同步时间",
    "mskuReserved": "MSKU & 预留",
    "skuApplication": "SKU&申请",
    "notReserved": "未预留",
    "partialReservation": "部分预留",
    "reserved": "已预留",
    "activeReserveInventory": "活动预留库存",
    "pleaseTurnOnTheInventoryMonitoring": "请开启【库存监控与同步】开关后操作",
    "afterTheSetupIsCompletedThe": "设置完成后，未开始的活动将不会提前预留库存。Ginee将仅对活动开始时间小于24小时和已开始的活动自动预留活动库存",
    "automaticallyReservedOnlyForInprogressActivities": "仅对进行中（已开始）的活动自动预留",
    "onceSetUpTheSystemWill": "设置完成后，未开始和进行中（已开始）的活动均会自动预留活动库存",
    "unstartedAndInprogressActivitiesAreAutomatically": "未开始及进行中的活动均自动预留",
    "beSureToSetAReservation": "请务必设置预留策略，保证活动库存按照业务需求正常预留；",
    "reservationPolicySettings": "预留策略设置",
    "reservationPolicy": "预留策略",
    "afterItIsEnabledTheSystem1": "开启后，系统将根据平台活动自动预留活动库存(仅支持Shopee平台)，可在【库存-活动预留库存】/【库存-库存清单】列表查看",
    "automaticallyReserveActiveInventory": "自动预留活动库存",
    "afterItIsEnabledTheSystem": "开启后，系统将为您自动拉取Shopee平台活动信息，可在【库存-活动预留库存】列表查看",
    "automaticallyPullPlatformActivity": "自动拉取平台活动",
    "theClosedEventDataSystemIs": "已结束的活动数据系统仅会保留30天，超出30天，无法在本列表查看",
    "reasonNotReserved": "未预留原因",
    "releaseTheReservation": "释放预留",
    "TheActiveInventoryReservedBy": "3. 仓库内MSKU已预留的活动库存将更新为0",
    "AfterReleaseTheChannelActivity1": "2. 释放后，渠道活动申请库存不受影响，仍可在平台继续售卖，有超卖风险",
    "AfterReleaseTheChannelActivity": "1. 释放后，渠道活动状态不受影响",
    "confirmTheReleaseOfCurrentlyActive": "确认释放当前活动预留库存吗？",
    "theActiveInventoryThatWasReserved": "预留的活动库存已成功清零",
    "theActiveReservedInventoryWasEdited": "活动预留库存编辑成功",
    "obligate": "预留",
    "application": "申请",
    "reservedInventoryEditsAreOnlySupported": "未开始的活动可进行预留库存编辑，包含修改预留仓库及预留库存值",
    "mskuHasNoWarehouseInformationAnd": "MSKU无仓库信息，无法预留成功",
    "mskuWithUnboundChannelSkuPlease": "渠道SKU无绑定的MSKU，请先至主商品列表/渠道商品列表完成绑定并入库",
    "automaticReservationOfActiveInventorySetting": "未开启自动预留活动库存设置",
    "commodityXxxMoveoutActivityIdxxx": "渠道商品:{XXX}移出活动ID:{XXX}",
    "xxxManuallyReleaseTheReservedActive": "{XXX}手动释放预留活动库存",
    "meetTheActiveInventoryReleaseRules": "符合活动库存释放规则，自动释放",
    "complyWithTheRulesOfAutomatic": "符合自动预留活动库存规则，自动预留",
    "deleteProduct": "删除商品",
    "unbindTheMasterProduct": "解绑主商品",
    "bindTheMasterProduct": "绑定主商品",
    "editedTheAutoBindMasterSku": "编辑了自动绑定主SKU规则，规则由 {0} 改为 {1}",
    "editedTheAutoCreateMasterSku": "编辑了自动创建主SKU规则，规则由 {0} 改为 {1}",
    "createdMasterProductViaOpenapi": "通过Open-Api创建了主商品{0}",
    "manuallyCreatedMasterProductFrom": "通过仓库商品手动创建了主商品{}",
    "automaticallyCreatedMasterProductFromWarehouse": "通过仓库商品自动创建了主商品{}",
    "commonSearches": "常用搜索",
    "moreSearches": "更多搜索",
    "becauseChannelSkuHasBeen": "由于渠道SKU {0} 已被删除，渠道SKU {1} 解绑MSKU {2}",
    "updatedProductPriceFrom": "更新了商品 {0} 的价格，从 {1} 变为 {2}",
    "editedStockForProductFrom": "编辑了商品 {0} 的库存，从 {1} 变为 {2}",
    "editedChannelSkuForProduct": "更新了商品 {0} 的渠道SKU，从 {1} 变为 {2}",
    "channelProductMatchToAutobind": "渠道商品 {0} 符合自动绑定的规则，绑定了MSKU {1}",
    "channelProductMatchesTheAutocreatedRule": "渠道商品 {0} 符合自动创建的规则，创建了MSKU {1}",
    "channelProductIsManuallyBound": "渠道商品 {0} 手动绑定了MSKU {1}",
    "channelProductManuallyCreatedMsku": "渠道商品 {0} 手动创建了MSKU {1}",
    "distribution": "分销",
    "removeSelectedLogistics": "去除勾选的物流{}",
    "PickListIsPrinted": "已打印拣货单",
    "printedPacking": "已打印装箱单",
    "InvoiceIsPrinted": "已打印发票",
    "printeLabels": "已打印快递单",
    "bopis": "BOPIS",
    "bigProduct": "Big Product",
    "saveSearch": "保存搜索条件",
    "commonSearchNames": "常用搜索名称",
    "radio": "单选",
    "multiplechoice": "多选",
    "updateTheOrderStatus": "更新订单状态",
    "updatedStockForProductFrom": "更新了商品 {0} 的库存，从 {1} 变为 {2}",
    "mengeditDeskripsiPanjangMasterProdukDari": "编辑了主商品长描述，从{0}变为{1}",
    "updatedAvailableStockForMskuTo": "更新了MSKU{}的可用库存，更新后的库存为{}",
    "nameManagement": "名称管理",
    "nameList": "名称列表",
    "ForChannelProductsWithVariations": "2. 有规格的渠道商品使用规格名称和商品名称作为MSKU使用。",
    "ChannelProductsWithoutVariationsUse": "1. 无规格的渠道商品使用SPU作为MSKU使用。",
    "theSystemDetectsThatSomeSkus": "系统检测到渠道商品部分SKU为空，请确认是否启用系统默认规则：",
    "updatedProductShortDescriptionFrom": "更新了商品短描述，从{0}变为{1}",
    "fuzzyOnlySupportsSingle": "模糊仅支持单个",
    "scanShipment": "扫描发货",
    "editMasterProductName": "编辑主商品名称",
    "editChannelProductName": "编辑渠道商品名称",
    "newProductName": "新商品名称",
    "utilityTool": "实用工具",
    "TimesScanningIsRequiredFor": "2次：需要扫描2次，第1次扫描显示订单信息，第2次生成扫描记录，并按设置将订单移入待揽收",
    "TimeScanOnceGenerateA": "1次：扫描1次，生成扫描记录，并按设置将订单移入待揽收",
    "twice": "二次",
    "once": "一次",
    "scanResultEffectiveTimes": "扫描结果生效次数",
    "afterScanningItWillAutomaticallyMove": "扫描后自动移入待揽收",
    "scanSettings": "扫描设置",
    "scanned": "已扫描",
    "pleaseScanOrEnterTheCourier": "请扫描或输入快递单号",
    "scanByCourier": "按快递单号扫描",
    "afterTheScanIsSuccessfulThe": "扫描成功后，订单将根据设置确认是否自动移入【待揽收】列表，请务必确保已完成设置，默认设置为未开启状态",
    "scanRecord": "扫描记录",
    "scanSucceeded": "扫描成功",
    "xxxxnumberDoesNotExist": "{xxxx}单号不存在",
    "xxxxxHasBeenScannedPleaseDo": "{XXXXX}已扫描完毕，请勿重复扫描",
    "xxxxxIsNotASelfownedWarehouse": "{XXXXX}非自有仓履约订单，无法扫描发货",
    "xxxxxIsNotAnOrderIn": "{XXXXX}非待打单状态订单，无法扫描发货",
    "xxxxxDoesNotExistPleaseCheck": "{XXXXX}不存在，请检查后重新扫描",
    "scanFailed": "扫描失败",
    "pleaseScanOrEnterMskubarcode": "请扫描或输入MSKU/Barcode",
    "useState": "使用状态",
    "shelfName": "货架名称",
    "informationShelf": "货架信息",
    "modifyShelves": "修改货架",
    "commodityDetails": "商品明细",
    "storageRack": "货架",
    "pleaseSelectAWarehouseAndAdd": "请选择仓库后添加商品",
    "pleaseChangeToEnInputMethod": "扫描前请先切换成 [EN] 输入法",
    "scanResults": "扫描结果",
    "notScanned": "未扫描",
    "scanStatus": "扫描状态",
    "shelfNumber": "货架编号",
    "courierNumber": "快递单号",
    "sellerCashbackCoin": "卖家现金返还",
    "tax": "税",
    "sunTotal": "商品总价",
    "currentShelf": "当前货架",
    "vacant": "空置",
    "inboundAndOutboundRecordAdjustmentMethod": "出入库记录调整方式=手工出库的记录中（平均采购价*变动值）的总和",
    "adjustmentMethodOfInboundAndOutbound": "出入库记录调整方式=销售出库的记录中（平均采购价*变动值）的总和",
    "calculationDescriptionAccordingToTheExport": "计算说明：按照导出模板中，订单创建时间在筛选时间段内，且状态=已完成的订单{XXXX}金额之和",
    "allScanned": "全部已扫描",
    "partiallyScanned": "部分已扫描",
    "warehousesKeeppackWarehousesListings": "仓库 / Keeppack 仓库/ 商品信息 / 创建主商品",
    "warehouseKeeppackWarehouseProduct": "仓库 / Keeppack 仓库/ 商品信息",
    "inOrderToImproveThePull": "为提高拉取效率，单次拉取时长不超过15天;",
    "bindingRecordsAreOnlyValidFor": "绑定记录仅有30天有效期，超出30天的绑定记录将无法查看",
    "pullItems": "拉取商品",
    "onlyProductsIssuedByGineeAre": "仅展示由Ginee下发的商品",
    "synchronizeTimeInDescendingOrder": "同步时间降序",
    "synchronizeTimeInAscendingOrder": "同步时间升序",
    "areYouSureUnbindYouWantTo": "确认解绑吗？",
    "mskuCreationIsNotSupportedForTip": "已绑定MSKU的三方仓SKU不支持创建MSKU，系统会为您自动过滤，请务必知悉",
    "pullTheProduct": "拉取商品",
    "beforeBatchEditingYoudBetterSynchronize": "建议编辑前先同步商品，确保数据和卖家中心保持一致",
    "syncAndEdit": "同步&编辑",
    "skipAndEdit": "跳过&编辑",
    "connectWithAllOfYourCustomer": "只需一个软件便可联系你的所有客户",
    "makesItEasyToMonitorThe": "更轻松地实现多仓作业流程管控及库存管理",
    "goBackToTheList": "返回列表",
    "xxxxDoesNotExistPleaseScan": "{XXXX}不存在，请在主商品添加后再次扫描",
    "theCombinationXxxCannotBePut": "组合商品{XXX}无法手动入库，请删除后重试",
    "theTrackingNumberXxxxWasSuccessfully1": "成功扫描快递单号{XXXX}，订单{XXXX}扫描状态更新为已扫描，并移入待揽收",
    "theTrackingNumberXxxxWasSuccessfully": "成功扫描快递单号{XXXX}，订单{XXXX}扫描状态更新为已扫描",
    "waitingInbound": "待入库",
    "upgrade": "升级",
    "about": "关于",
    "pleaseSelectACategoryFirst": "请先选择类目",
    "subAccount": "子账号",
    "authorizedStores": "可授权店铺",
    "availableQuantity": "可用单量",
    "addImagesToEachVariantOption": "给每个规格选项添加图片",
    "productInformationDisplayedOnTheLogistics": "物流单上显示商品信息（JD/Bukalapak）",
    "tiktokPrintShippingLabelAndPick": "(Tiktok)打印快递单和拣货单",
    "downloadDocument": "下载文件：{0}",
    "thereAreOrdersThatStillBeing": "尚有订单在打印中，关闭弹窗后可在打印历史中下载快递单。",
    "printHistory": "打单历史",
    "printAll": "打印全部",
    "maximumOrdersCanBePrinted": "单次打印最多1000笔订单，每个渠道生成一份PDF快递单，耗时较长",
    "unlimited": "无限",
    "otherCharges": "其他费用",
    "bearTheCost": "付款手续费",
    "authorizationStatusTotalNumberOf": "授权状态=“停用中”的电商平台/自建站店铺数量之和",
    "expiredInfo": "授权状态=“已过期”的电商平台/自建站店铺数量之和",
    "storeStatusOfAllAuthorizedChannels": "已授权的全部渠道店铺中状态=“已售完”商品数量之和",
    "status_banned_info": "已授权的全部渠道店铺中状态=“已禁卖”商品数量之和",
    "theSumOfTheNumberOf": "已授权的全部渠道店铺中发布至渠道失败的商品数量之和",
    "stockListPageNotInboundTab": "【库存清单】页面【未入库】tab的MSKU数量",
    "theNumberOfMskusThatHave": "已添加至仓库且从未创建入库单增加库存的的MSKU数量",
    "stockListPageStockWarningTab": "【库存清单】页面【库存预警】tab的MSKU数量",
    "totalMskuWhichTheAvailableStock": "可用库存小于安全库存的MSKU数量",
    "totalMskuOfStockListPage": "【库存清单】页面【库存不足】tab的MSKU数量",
    "totalMskusWithAvailableStock": "可用库存为0的MSKU数量",
    "TotalOrderOf": "【退货管理-退货退款】页面【待处理】tab中的订单数量",
    "returnRequestInfo": "买家发起退货申请，需要卖家介入处理的Shopee/Lazada/Tiktok订单",
    "totalOrderOfReturnManagement": "【退货管理-退款列表】页面【待处理】tab中的订单数量",
    "shopeelazadatiktokOrdersThatRequireTheSeller": "买家发起退款申请，需要卖家介入处理的Shopee/Lazada/Tiktok订单",
    "ordersThatHaveBeenPushed": "【待处理-推送仓库】页面【推送失败】tab中的订单数量，请务必优先处理",
    "ordersThatHaveBeenPushedTo": "已手动或系统自动推送给三方仓，但三方仓接口返回失败结果的订单",
    "totalOrderOfPendingpushWarehousePage": "【待处理-推送仓库】页面【待推送】tab中的订单数量，请务必及时推送",
    "ordersThatNeedToBePushed": "需要推送至三方仓履约，但尚未推送的订单",
    "totalOrderOnAbnormalOrdersPage": "【异常订单】页面中的订单数量，如有相关数据，请务必手动同步订单",
    "ordersWithIncompleteInformationDueTo": "因渠道接口出现QPS限制，信息不完整的订单",
    "theNumberOfOrdersInThe": "【待打单】页面中【可打印】tab中的订单数量，可快速统一处理",
    "allPrintableShippingLabelsAreDisplayed": "全部可打印快递单的订单均在此列表展示",
    "toBeProcessedArrangeShipment": "【待处理-安排发货】页面各个tab中的订单数量之和，可优先统一处理多渠道多店铺发货",
    "allShippableOrdersAreDisplayedIn": "全部可发货订单均在此列表展示",
    "pendingproblemOrdersTheSumOfThe": "【待处理-问题订单】页面各个tab中的订单数量之和，请务必优先处理",
    "whenTheOutboundListCannotBe": "无法创建出库单或MSKU缺货时，均会定义为问题订单",
    "xxxxThereIsMoreThanOne": "{XXXX}在系统内存在不止一条，MSKU和Barcode存在重复，请检查后重试",
    "servicePH": "点击联系您的专属客服",
    "serviceCN": "微信搜索Ginee888联系您的专属顾问",
    "serviceTH": "Ginee_Thailand客服群",
    "serviceVN": "Zalo教学群组",
    "serviceMYandID": "Ginee Support客服群",
    "accountInfo": "账号信息",
    "refundApplication": "退款申请",
    "returnRequest": "退货申请",
    "orderManagement": "订单管理",
    "sumOfTheNumberOfShopeelazada": "授权状态=“已授权”且授权有效期剩余时间不足30天的Shopee/Lazada店铺数量之和",
    "shopeebukalapaktokopediaOrdersUseTheGineeTemplate": "Shopee/Bukalapak/Tokopedia 订单使用Ginee模板进行快递单打印，其他渠道暂时只支持渠道快递单打印",
    "highSpeedPrintTaskDetails": "高速打印任务详情",
    "printTaskDetails": "打印任务详情",
    "regularPrint": "普通打印",
    "printUnlimitedShippingLabelInSingle": "单次打印不限数量，不支持打印预览",
    "clickHereToViewDetailedInstructions": "点此查看详细说明",
    "inOrderToImprovePrintingSpeed": "为提升打印速度及体验，建议使用高速打印，",
    "itIsDetectedThatYouHave": "检测到您勾选订单超过300单，暂时不支持打印Ginee自定义快递单",
    "thePrintingTaskHasExpiredPlease": "打印任务过期，请前往近【90天订单>打单历史】查看打印记录",
    "imageSizeLengthLimit": "可以一次上传多张图像。 图片大小最小{width} x {height}像素，最大为{size}MB（最多{maxLength}张）",
    "imageManagement": "图片管理",
    "noMskuInTheDocumentHas": "单据内无任何MSKU存在库存差异，无需生成调整单",
    "inventoryHasNotBeenCompletedPlease": "盘点尚未完成，请优先完成盘点后操作",
    "stocktakingNumber": "盘点单号",
    "checkingStock": "盘点中",
    "waitingStocktaking": "待盘点",
    "importStockTakingResult": "导入盘点结果",
    "mskuNumber": "MSKU个数",
    "stocktakingType": "盘点类型",
    "numberOfMskuWithCountingDifferences": "盘点存在差异的MSKU个数",
    "numberOfDifferences": "差异数",
    "generateInventoryAdjustmentDocument": "生成库存调整单",
    "pleaseCheckTheSpecificInformationIn": "请在【仓库-库存调整单】查看具体信息，并进行手动调整，按照盘点结果更新MSKU库存",
    "inventoryAdjustmentDocGeneratedSuccessfully": "库存调整单生成成功",
    "voidSuccessfully": "作废成功",
    "afterVoidingItIsNotSupported": "作废后，不支持继续进行盘点，已盘点的MSKU也不支持生成库存调整单调整库存",
    "AfterTheCountingTheSystem": "3.盘点结束后，系统将自动为您将盘点期间未能正常出库，实际订单已发货/已取消的销售出库单做自动出库/作废，更正MSKU库存",
    "TheMskuInventoryThatHas": "2.未登记盘点库存的MSKU盘点库存将不做更新，且MSKU盘点结果更新为“未盘点”，请务必谨慎操作",
    "AfterTheInventoryIsFinished": "1.盘点结束后，盘点单状态将变更为已完成，不可继续操作",
    "confirmTheEndOfTheInventory": "确认结束盘点？",
    "theCurrentTaskIsStillBeing": "当前任务仍在创建中，请稍后操作",
    "clickStartCountingAndTheStatus": "点击【开始盘点】后，盘点单状态会更新为【盘点中】，并自动为您打开盘点操作页面",
    "printInventoryList": "打印盘点单",
    "endInventory": "结束盘点",
    "startCounting": "开始盘点",
    "inventoryByMsku": "按 MSKU 盘点",
    "wholeWarehouseInventory": "整仓盘点",
    "automaticallyFilterMskuXCombinationsAnd": "已自动过滤组合品MSKU [ {bundleSku} ] 个，尚在未完成盘点任务中等待盘点的MSKU [ {countingSKU} ] 个，本次可盘点MSKU共 [ {canCountSKU} ] 个",
    "aTotalOfXMskuCan": "本次可盘点MSKU共 {X} 个",
    "createInventoryTask": "创建盘点单",
    "aTotalOfXMskuAre": "本次共{count}个MSKU等待盘点，盘点单正在申请，请稍候，并移步至库存盘点列表继续操作",
    "inventoryCreationTaskSubmittedSuccessfully": "盘点单创建任务提交成功",
    "afterConfirmationTheFilledInformationWill": "确定后，已填写信息将不会保存",
    "areYouSureYouDontWant": "确认不保存当前信息吗？",
    "currentWarehouseInventory": "当前仓库库存",
    "inventoryIsNotSupportedForCombination": "组合品及已在未完成的盘点任务中的MSKU不支持盘点",
    "mskuDetails": "MSKU 明细",
    "inventory": "盘点仓库",
    "afterSavingTheInformationWillOnly": "保存后，信息仅会保存在盘点单中，支持再次修改",
    "inventoryLoss": "盘亏",
    "inventoryProfit": "盘盈",
    "panping": "盘平",
    "inventoryResults": "盘点结果",
    "takeStockInTheWarehouse": "盘点库存",
    "takeStockOf": "盘点",
    "counting": "盘点数",
    "inventorySheet": "盘点单号",
    "notCounted": "未盘点",
    "TheCurrentAdjustmentDocumentStatus": "3.当前调整单状态更新为【调整中】",
    "TheCurrentMskuAdjustmentStatus1": "3.当前MSKU调整状态更新为【未调整】",
    "TheSystemWillNotChange": "2.系统将不会变更当前MSKU仓库库存",
    "OnceItIsConfirmedThat": "1.一旦确认无需调整，不可再次操作",
    "confirmThatTheCurrentMskuInventory": "确认当前MSKU库存无需调整么？",
    "TheCurrentMskuAdjustmentStatus": "3.当前MSKU调整状态更新为【已完成】",
    "withoutAdjustment": "无需调整",
    "confirmAdjustment": "确认调整",
    "unadjusted": "未调整",
    "singleAdjustment": "整单调整",
    "continueToAdjust": "继续调整",
    "startAdjusting": "开始调整",
    "inventoryReductionMsku": "库存减少MSKU",
    "increaseInventoryMsku": "库存增加MSKU",
    "totalMsku": "MSKU总数",
    "adjusting": "调整中",
    "toBeAdjusted": "待调整",
    "adjustTheSingleNumber": "调整单号",
    "afterItIsTurnedOnMsku": "开启后，盘点期间的MSKU不支持进行库存增加及库存扣减动作，不影响库存锁定，请务必知悉",
    "mskuDuringTheCountingPeriodDoes": "盘点期间的MSKU不支持进行出入库操作",
    "afterAdjustment": "调整后",
    "beforeAdjustment": "调整前",
    "inventoryVariance": "库存差异",
    "stockAdjustment": "调整单",
    "continueCounting": "继续盘点",
    "confirmIntegrations": "确认授权",
    "mskuXDoesNotExistIn": "MSKU{X}在{X}仓库中不存在，请先将MSKU添加到仓库",
    "orderXxxxxStatusChangedToShipping": "订单{XXXXX}状态在{XXXX-XX-XX XX:XX}变更为Shipping",
    "xxxAfterTheInventoryCountIs": "{XXX}结束库存盘点，MSKU可操作出入库，依据订单状态变化，自动修正库存值",
    "xxxGeneratesTheInventoryAdjustmentDocument": "{XXX}生成库存调整单号：{XXXX}，盘点结果：盘亏",
    "xxxGenerateTheInventoryAdjustmentDocument": "{XXX}生成库存调整单号：{XXXX}，盘点结果：盘盈",
    "theCurrentRepositoryIsDeactivatedPlease": "当前仓库已停用，请在仓库列表启用后操作",
    "theCurrentWarehouseIsNotIts": "当前仓库不是自有仓，无法盘点",
    "theCurrentRepositoryDoesNotExist": "当前仓库不存在，请在仓库列表添加后操作",
    "theInventoryHasBeenTaken": "已盘点",
    "AreYouSureToDelete": "确定删除当前信息吗？",
    "defaultShelf": "默认货架",
    "AreYouSureToEnd": "确定结束盘点？",
    "mskuInInventoryExistsInThe": "当前单据内存在盘点中的MSKU，请结束盘点后，操作直接入库",
    "thereIsMskuInCountingIn": "当前单据内存在盘点中的MSKU，请结束盘点后，操作直接入库或出库",
    "AreYouSureToVoid": "确定作废当前信息吗？",
    "keywordid": "关键词",
    "onceTheAdjustmentIsConfirmedIt": "一旦确认调整，不可中止",
    "atTheBeginningOfAdjustmentThe": "调整开始，系统将自动将MSKU仓库库存更新为调整后的库存值",
    "addedInventoryList": "新增盘点单",
    "noMoreThanMaxCharacters": "不超过{max}个字符",
    "confirmAndUpdateMskuInventory": "确认调整并更新MSKU库存么？",
    "areYouSureNotToSave": "确定不保存当前信息吗?",
    "createAnInventoryTask": "创建盘点任务",
    "countingStatus": "盘点状态",
    "counted": "已盘点",
    "saving": "保存中...",
    "areYouCancel": "确认取消么？",
    "notCount": "当前单据不是待盘点状态，无法操作，请刷新页面后重试",
    "shouldBeBetweenXToX": "范围应是X到X",
    "offlineOrder": "线下订单",
    "duringTheUnboundPeriodTheOutbound": "未绑定期间无法生成出库单锁定/扣减库存且无法进行库存同步",
    "tokocabangStockChannelInterfaceDoesNot": "请勿将Tokopedia O2O仓库与Ginee WMS/三方仓进行绑定，否则会导致重复发货",
    "disabledChannelWarehousesCannotUpdateStock": "已停用的渠道仓库因渠道接口限制无法更新库存；",
    "pleaseBeSureToCompleteThe1": "请务必完成渠道仓库与Ginee仓库一对一绑定，以实现订单分仓及库存同步；",
    "systemWarehouseAddress": "系统仓库地址",
    "systemWarehouseName": "系统仓库名称",
    "channelWarehouseAddress": "渠道仓库地址",
    "channelWarehouseName": "渠道仓库名称",
    "channelWarehouseManagement": "渠道仓库管理",
    "upgradePackage": "升级套餐",
    "askCustomerService": "咨询客服",
    "theCurrentStoreHasOpenedThe": "当前店铺已开通渠道多仓功能，请先升级至已支持多仓管理功能的套餐后操作",
    "storeAuthorizationFailed": "店铺授权失败",
    "youCanDirectlyAddProductsIn": "您可以在系统内直接新增商品，处理订单，或开通库存同步体验一键管理多渠道库存功能",
    "pleaseBeSureToCompleteThe": "请务必立即完成渠道仓库与Ginee仓库绑定，否则，您将无法处理订单/同步库存",
    "theStoreHasOpenedTheChannel": "店铺已开通渠道多仓服务，且正在使用库存同步服务",
    "authorizing": "授权中",
    "multiWarehouse": "多仓",
    "theGineeSystemReportsAnError": "Ginee系统报错,原因如下：{XXXXX}",
    "theChannelInterfaceReportsAnError": "渠道接口报错,原因如下：{XXXXX}",
    "failedToPullChannelWarehouseInformation": "渠道仓库信息拉取失败",
    "deactivateWarehouse": "停用仓库",
    "addWarehouse": "新增仓库",
    "channelWarehouseInformationPulledSuccessfully": "渠道仓库信息拉取成功",
    "pullTheChannelWarehouseAddXxx": "拉取渠道仓库，新增{AAA}，停用{BBB}",
    "channelWarehouseXxxBindingWarehouseIs": "渠道仓库{AAA}绑定仓库由{BBB}更新为{CCC}",
    "theChannelWarehouseXxxIsBound": "渠道仓库{AAA}与系统仓库{BBB}绑定",
    "updateWarehouse": "更新仓库",
    "pleaseNoteThatTheOperationLog": "请注意，操作日志仅会在系统内保留 30 天，超出 30 天的拉取记录无法查询",
    "thereIsAnUnfinishedSalesDelivery": "当前仓库内存在未完成的销售出库单，无法更新绑定关系",
    "OrdersAllocated": "1.已分配至渠道仓库的订单将按照新的绑定关系，自动分配至配对仓库，无需设置分仓规则",
    "areYouSureToUpdateThe": "确认更新绑定的仓库么？",
    "OrdersThatHaveBeenAllocated": "1.已分配至渠道仓库的订单将按照绑定关系，自动分配至配对仓库，无需设置分仓规则",
    "areYouSureToBindThe": "确认绑定当前仓库么？",
    "ownWarehouse": "自有仓库",
    "warehouseMarketplace": "渠道仓库",
    "TheStatusOfTheCurrent": "3.当前调整单状态更新为【已完成】",
    "lastSaleDateTips": "当前商品最新的一笔订单的创建日期",
    "notification74": "符合滞销条件的时间段内有效订单的订单总额（含运费等）",
    "notification72": "符合滞销条件的时间段内有效订单内包含的当前商品的售出个数",
    "salesVolume": "销售数量",
    "notification70": "符合滞销条件的时间段内全部订单内的买家总数，按照手机号做买家数去重",
    "totalNumberOfBuyers": "买家总数",
    "validOrder": "有效订单",
    "notification66": "符合滞销条件的时间段内订单总数，包含全部状态",
    "totalNumberOfOrders": "订单总数",
    "notification64": "当前商品最新的一笔订单的创建日期",
    "lastSaleDate": "上次销售日期",
    "notification58": "仅显示有库存SKU的商品",
    "notification55": "在{day}天内已售数量小于{number}",
    "notification54": "在使用滞销统计表前必须优先完成滞销条件设置",
    "slowSalesSettings": "滞销设置",
    "commodityCategory": "商品类目",
    "slowSalesStatisticsTable": "滞销统计表",
    "notification34": "文件生成失败，请稍后重试",
    "notification33": "文件将同步展示在导出历史列表，文件仅会保留15天，请及时点击下载",
    "notification32": "文件已生成，请点击下载",
    "generatingFilePleaseWait": "文件生成中，请稍后",
    "replenishStock": "去补货",
    "unableEstimate": "暂无法预估",
    "estimatedDaysTips": "(可用库存-安全库存)/每日销售数量，按照每日平均售出数量预估的达到安全库存的剩余天数，每日销售数量不足1时，将按照1进行预估天数计算",
    "estimatedDays": "估算日期",
    "avgQtySoldTips": "已售数量/销售周期，每日销售库存数的平均值",
    "avgQtySold": "每日销售数量",
    "qtySoldTips": "选中的销售周期内当前库存SKU已销售出去的库存总数",
    "qtySold": "已售数量",
    "safetyStockTips": "当前库存SKU的安全库存数量，安全库存含义与库存管理列表一致",
    "availableStockTips": "当前库存SKU的可用库存数量，可用库存含义与库存管理列表一致",
    "inventoryName": "库存名称",
    "inventorySKU": "MSKU",
    "notification151": "了解如何开启安全库存",
    "days90": "90天",
    "days60": "60天",
    "days30": "30天",
    "days15": "15天",
    "days7": "7天",
    "salesPeriod": "销售周期",
    "outOfStockEstimation": "缺货预测表",
    "profitTips": "提示：利润表仅统计已完成订单，且支持筛选的时间跨度最长不超过30天",
    "commissionSettingTips": "注：现支持设置绑定Tokopedia和Shopify店铺的店铺佣金，该数据仅用于收入数据统计，其他渠道佣金数据以渠道API为准",
    "netProfitIncomeStatementTips2": "净利润/亏损=营业收入+营业外收入-营业成本-营业外支出-其他费用",
    "netProfitIncomeStatementTips": "营业收入+营业外收入-营业成本-营业外支出-其他费用",
    "netProfitIncomeStatement": "净利润/亏损",
    "outboundStockTips": "库存记录日期=筛选日期的库存调整增加库存值，取值为成本*增加数量",
    "outboundStock": "库存调整(扣减)",
    "refundFeeTips": "订单创建日期=筛选日期的退还费用金额之和",
    "refundFee": "退还费用",
    "cashbackTips": "订单创建日期=筛选日期的现金返还金额之和",
    "cashback": "现金返还",
    "commissionFeeTips": "订单创建日期=筛选日期的佣金金额之和",
    "commissionFee": "佣金",
    "serviceFeeTips": "订单创建日期=筛选日期的服务费金额之和",
    "serviceFee": "服务费",
    "otherExpensesTips": "其他费用=服务费+佣金+现金返还+退还费用+库存调整（扣减）",
    "otherExpenses": "其他费用",
    "taxTips": "订单创建日期=筛选日期的税费金额之和",
    "insuranceTips": "订单创建日期=筛选日期的保险费金额之和",
    "insurance": "保险费",
    "nonOperatingExpensesTips": "营业外支出=保险费+税费",
    "nonOperatingExpenses": "营业外支出",
    "costOfGoodSoldTips": "库存记录日期=筛选日期的订单发货商品数*成本",
    "costOfGoodSold": "销售成本(COGS)",
    "operatingExpenseTips": "营业成本=销售成本",
    "operatingExpense": "营业成本",
    "inboundStockTips": "库存记录日期=筛选日期的库存增加数量*成本",
    "inboundStock": "库存调整(增加)",
    "rebateTips": "订单创建日期=筛选日期的回扣金额之和",
    "rebate": "补贴",
    "nonOperatingIncomeTips": "营业外收入=运费",
    "nonOperatingIncome": "营业外收入",
    "coinTips": "订单创建日期=筛选日期的金币金额之和",
    "coin": "金币",
    "promotionTips": "订单创建日期=筛选日期的优惠券金额之和",
    "promotion": "其他优惠",
    "discountTips": "订单创建日期=筛选日期的折扣金额之和",
    "discount": "销售折扣",
    "salesAmountTips": "订单创建日期=筛选日期的商品总金额之和（Subtotal）",
    "salesAmount": "销售额",
    "operatingIncomeTips": "营业收入=销售额-销售折扣-其他优惠+金币+补贴",
    "operatingIncome": "营业收入",
    "incomeStatement": "利润表",
    "refundOrdersTips": "退货状态的订单总数",
    "validBuyersTips": "有效订单对应的买家总数",
    "buyersTips": "全部订单中的买家总数",
    "salesRateTips": "当前商品销售额占总销售额的比例",
    "grossSalesTips": "有效订单中订单金额总和，包含运费等信息",
    "qtySalesRateTips": "1. 商品销售统计表：单商品销量/全部商品销量总和\n2. 类目销售统计表：单类目销量/全部类目销量总和\n3. 店铺销售统计表：单店铺销量/全部店铺销量总和\n4. 渠道销售统计表：单渠道销量/全部渠道销量总和",
    "qtySalesTips": "有效订单中商品售出的总数量",
    "validOrdersTips": "Paid/Ready To Ship/Shipping/Delivered的订单",
    "totalOrdersTips": "商品对应订单的总数量",
    "refundOrders": "退货订单",
    "validBuyers": "有效买家",
    "buyers": "买家总数",
    "salesRate": "销售额占比",
    "grossSales": "总收入",
    "qtySalesRate": "销量占比",
    "qtySales": "总销量",
    "validOrders": "有效订单",
    "validOrderNumStatisticsTips": "Paid/Ready To Ship/Shipping/Delivered的订单",
    "totalProductNumStatisticsTips": "全部订单内包含的商品总个数，按照店铺+商品名去重后的数据     一个订单内如同时购买2个同一款商品，则商品总数为1",
    "totalValidBuyerTips": "有效订单内的买家数量总和，买家已按照同一手机号去重",
    "shopNameTips": "商品归属的店铺名称",
    "mskuTips": "已同步至订单中商品下的规格对应的主SKU信息，不会依据商品信息更新而变化",
    "skuTips": "已同步至订单中商品下的规格对应的SKU信息，不会依据商品信息更新而变化",
    "variationTips": "已同步至订单中商品下的规格名称，不会依据商品信息更新而变化",
    "productNameTips": "已同步至Ginee的订单内包含的商品名称",
    "totalValidBuyerStatisticsTips": "有效订单内的买家数量总和，买家已按照同一手机号去重",
    "totalQtySoldStatisticsTips": "有效订单内已销售商品的总数量    一个订单内如同时购买2个同一款商品，则总销量为2",
    "searchPlaceholder": "请输入搜索内容",
    "pleaseSelectCategory": "请选择类目",
    "productVariantsSalesStatistics": "商品规格销售统计",
    "action73": "操作",
    "datesPerPage": "每页数据量",
    "setPage": "选择页数",
    "time69": "时间",
    "notification68": "提示：请设置下载数据的页数范围",
    "variationName": "规格名称",
    "fieldDescription": "字段描述",
    "detial": "详情",
    "action61": "操作",
    "downloadFailed": "下载失败",
    "active": "有效",
    "notification49": "文件将同步展示在导出历史列表，文件仅会保留15天，请及时点击下载",
    "returnRateTips": "退货订单/总订单",
    "returnRate": "退货率",
    "refundOrderNumTips": "退货状态的订单总数",
    "refundOrderNum": "退货订单",
    "repurchaseRateTips": "成功交易次数大于1次的客户数占比",
    "repurchaseRate": "复购率",
    "grossSalesBuyer": "销售总额/买家数",
    "customerAverageTransaction": "客单价",
    "buyerNumTips": "有效订单对应的买家总数",
    "buyerNum": "有效买家",
    "totalBuyerNumTips": "全部订单中的买家总数",
    "totalBuyerNum": "买家总数",
    "saleRateTips": "1. 商品销售统计表：单商品销售额 / 全部商品销售总额\n2. 类目销售统计表：单类目销售额/全部类目销售总额\n3. 店铺销售统计表：单店铺销售额/全部店铺销售总额\n4. 渠道销售统计表：单渠道销售额/全部渠道销售总额",
    "saleRate": "销售额占比",
    "grossIncomeTips": "有效订单中订单金额总和，包含运费等信息",
    "grossIncome": "总收入",
    "qtySaleRateTips": "1. 商品销售统计表：单商品销量/全部商品销量总和\n2. 类目销售统计表：单类目销量/全部类目销量总和\n3. 店铺销售统计表：单店铺销量/全部店铺销量总和\n4. 渠道销售统计表：单渠道销量/全部渠道销量总和",
    "qtySaleRate": "销量占比",
    "qtySaleNumTips": "有效订单中商品售出的总数量",
    "qtySaleNum": "总销量",
    "effectiveRateTips": "有效订单/订单总数",
    "effectiveRate": "有效占比",
    "validOrderNumTips": "Paid/Ready To Ship/Shipping/Delivered的订单",
    "validOrderNum": "有效订单",
    "totalOrderNumTips": "商品对应订单的总数量",
    "totalOrderNum": "订单总数",
    "totalProductNumTips": "live/disabled/sold out/banned 的商品总数",
    "totalProductNum": "商品总数",
    "totalValidBuyer": "有效买家总数",
    "netIncomeTips": "有效订单中的商品金额之和",
    "netIncome": "商品销售总额",
    "notification15": "有效订单中商品售出的总数量",
    "totalQtySold": "总销量",
    "lowToHigh": "由低到高",
    "highToLow": "由高到低",
    "storeSaleReport": "店铺销售统计表",
    "channelSaleReport": "平台销售统计表",
    "revenuePerBuyerTips": "订单总额/客户数",
    "revenuePerBuyer": "客单价",
    "monetaryUnit": "货币单位",
    "productOriginalPriceIncome": "商品销售总额:有效订单中的商品金额之和；总收入:有效订单中订单金额总和，包含运费等信息",
    "totalNetIncomeTips": "有效订单中的商品金额之和",
    "totalNetIncome": "商品销售总额",
    "exportPdf": "导出为PDF",
    "exportToPDF": "导出为PDF",
    "countTips": "销售数量不能小于0",
    "selectTime": "选择时间",
    "exportExcel": "导出为Excel",
    "exportTime": "导出时间",
    "exportHistory": "导出历史",
    "title": "名称",
    "moveEnd": "置底",
    "moveTop": "置顶",
    "moveDown": "下移",
    "moveUp": "上移",
    "customField": "自定义",
    "taxTotalAmountTips": "当前日期创建的订单内的税费总和",
    "taxTotalAmount": "税费总额",
    "systemShippingTotalAmountTips": "当前日期创建的订单内的系统代付运费总和",
    "systemShippingTotalAmount": "系统代付运费总额",
    "serviceTotalAmountTips": "当前日期创建的订单内的服务费总和",
    "serviceTotalAmount": "服务费总额",
    "commissionTotalAmountTips": "当前日期创建的订单内的佣金总和",
    "commissionTotalAmount": "佣金总额",
    "insuranceTotalAmountTips": "当前日期创建的订单内的保险总和",
    "insuranceTotalAmount": "保险总额",
    "buyerTotalNumTips": "当前日期创建的订单内的客户总数(按照手机号去重)",
    "buyerTotalNum": "客户数",
    "cancelOrderRateTips": "取消订单/订单总数",
    "cancelOrderRate": "取消率",
    "cancelOrderTotalAmountTips": "创建日期等于当前日期的取消状态的订单总金额（含运费等），部分取消的订单不计入",
    "cancelOrderTotalAmount": "取消总额",
    "cancelOrderTotalNumTips": "创建日期等于当前日期的取消状态的订单总数量，部分取消的订单不计入",
    "cancelOrderTotalNum": "取消订单",
    "returnOrderRateTips": "退货订单/订单总数",
    "returnOrderRate": "退单率",
    "returnOrderTotalAmountTips": "创建日期等于当前日期的退货状态的订单总金额(含运费/折扣等)，部分退货的金额不计入",
    "returnOrderTotalAmount": "退款总额",
    "returnOrderTotalNumTips": "创建日期等于当前日期的退货状态的订单总数量，部分退货的订单不计入",
    "returnOrderTotalNum": "退货订单",
    "paymentTotalAmountTips": "创建日期等于当前日期的 Paid/Ready to Ship/Shipping/Delivered 订单总金额（含运费等）",
    "paymentTotalAmount": "有效订单总额",
    "paymentTotalNumTips": "创建日期等于当前日期的 Paid/Ready to Ship/Shipping/Delivered 订单总数量",
    "paymentTotalNum": "有效订单",
    "shippingTotalAmountTips": "当前日期创建的订单内运费总额",
    "shippingTotalAmount": "物流费用",
    "goodsTotalAmountTips": "当前日期创建的订单内的商品总金额(不含运费/折扣等)",
    "goodsTotalAmount": "商品总额",
    "orderTotalAmountTips": "当前日期创建的订单总金额(含运费/折扣等)",
    "orderTotalAmount": "订单总额",
    "orderTotalNumTips": "符合滞销条件的时间段内订单总数，包含全部状态",
    "orderTotalNum": "订单总数",
    "dayTips": "订单创建日期",
    "yesterday": "昨天",
    "totalRefundAmountTips": "退货日期等于当前日期的退货总金额 部分退货的订单不计入退款金额统计",
    "totalRefundAmount": "退款总额",
    "returnOrderTips": "创建日期等于当前日期的已退货订单总数量（不过滤订单状态）",
    "shippingFeeTips": "创建日期等于当前日期的订单内的物流总金额（订单详情内的Shipping Fee 总额）",
    "shippingFee": "物流费用",
    "totalOrderAmountTips": "创建日期等于当前日期的订单总金额（包含运费等）",
    "totalOrderAmount": "订单总额",
    "totalOrderNumberTips": "创建日期等于当前日期的订单总数量（不过滤订单状态）",
    "totalOrderNumber": "订单总数",
    "trendChart": "趋势图",
    "theSizeVariantDoesNotSupport": "\"Size\"变种不支持添加图片",
    "onlyShowAuthorizedStores": "仅展示已授权店铺",
    "untickWithOneClick": "一键取消勾选",
    "pleaseUntickAndReoperate": "请取消勾选后重新操作",
    "thereAreXExpireddeactivatedStoresIn": "当前权限中存在{X}个已过期/停用中的店铺",
    "failedToSave": "保存失败",
    "shortDescription": "短描述",
    "Item": "项目",
    "shippingFeeTips2": "订单创建日期=筛选日期的运费及系统代付运费金额之和",
    "whenTheProductsInTheChannel": "渠道仓库内的商品自动创建/绑定MSKU时，将自动添加至配对的系统仓库，但仍需自行操作入库，保证库存准确",
    "whenThereIsAStockChange": "配对的系统仓库发生库存变更时，将及时推送库存值至渠道仓库",
    "channelWarehouseId": "渠道仓库ID",
    "forChannelProductsThatAreBound": "对于已经有仓库绑定关系的渠道商品，创建MSKU后，将自动添加至绑定仓库。",
    "forMultiwarehouseChannelsAndChannelProducts": "针对多仓渠道且做了仓库绑定关系的渠道商品，绑定MSKU后将自动添加至绑定仓库。",
    "forMultiwarehouseChannelsWithWarehouseboundChannel": "针对多仓渠道且做了仓库绑定关系的渠道商品，创建MSKU后将自动添加至绑定仓库。",
    "existXwarehouse": "存在{X}个仓库",
    "amountUnit": "金额(单位:",
    "thePriceOfTokoWillBe": "Toko的价格会更新到渠道的每个仓库。",
    "priceAndStockWillBeUpdated": "价格和库存将更新到默认仓库",
    "dueToTheDelayInChannel": "由于渠道更新数据延迟，同步后的数据需要等待几秒钟才会更新完成，请耐心等待。",
    "theStoreWhereTheOrderIs1": "订单所在店铺已开启渠道多仓，请绑定Ginee仓库后操作",
    "theStoreWhereTheOrderIs": "订单所在店铺已开启渠道多仓，仅支持更换至已绑定的Ginee仓库{XXXX}",
    "theWarehouseXxxxAlreadyHasA": "仓库{XXXX}已有拉取中的任务，可在拉取记录中查看，请等待任务完成后操作",
    "youNeedToIntegrateAstore": "您需要先绑定一个{x}店铺才可以使用{y}付款",
    "pleaseSelectTheStoreaccountTo": "请选择要付款的{x}店铺/账号",
    "theProductsXxxxInTheOrder": "订单{XXXX}内商品{XXXX}，依据渠道仓库与系统仓库的绑定关系分配至仓库{XXXX}",
    "theCurrentProductsWarehouseIsEmpty": "当前商品仓库为空，请优先在keeppack完成入库",
    "xIsOutOfStockIn": "{X}在{X}中的库存不足",
    "shopeebukalapaktokopediaOrdersUseGineeTemplateFor": "Shopee / Bukalapak 订单使用 Ginee 模板进行快递单打印",
    "buyerPhone": "买家手机",
    "xNewWarehouses": "新增{X}个仓库,仓库ID:{X}",
    "editStock": "编辑库存",
    "warehouseIsEmpty": "缺失仓库信息",
    "dueToTheLimitationsOfThe": "受Keeppack接口限制，此类商品无法在Ginee操作，请务必在Keeppack完成入库后操作",
    "pleaseFindTheBindingEmailIn": "请在Tokopedia卖家中心【设置-第三方应用设置】或Toko卖家账号邮箱找到绑定邮件，接受绑定申请后操作",
    "created": "已创建",
    "allstocktakingissuccessful": "盘点成功",
    "partialstocktaking": "部分盘点",
    "allstocktakingfailed": "盘点失败",
    "someexecute": "部分执行",
    "faileds": "已失败",
    "thePullTaskHasBeenCreated": "拉取任务创建成功",
    "executed": "已执行",
    "keeppackWarehouse": "Keeppack 仓库",
    "unbindMsku": "解绑MSKU",
    "PullRecord": "库存拉取记录",
    "thisStoreHasBeenConnectedTo": "该店铺已与其他Ginee账号绑定，请先将该店铺与以下Ginee账号解绑后再操作：",
    "ManualInbound": "手工入库",
    "stocktakingInbound": "盘盈入库",
    "NormalInbound": "正常入库",
    "notPushed": "未推送",
    "partComplete": "部分完成",
    "partInvalid": "部分作废",
    "tripartiteSingleNumber": "三方单号",
    "storageInformation": "入库信息",
    "types": "类型",
    "plannedInbound": "计划入库",
    "receiptCost": "入库成本价",
    "withAttachments": "有附件",
    "automaticallyCreateMsku": "自动创建MSKU",
    "createMskuManually": "手动创建MSKU",
    "currentlyThirdPartyWarehouseSkuCode": "仅支持三方仓SKU编码和MSKU编码一致的才能绑定",
    "warehouseKeeppackWarehouseInboundManagement": "仓库/Keeppack仓库/入库管理",
    "warehouseKeeppackWarehouseOutboundManagement": "仓库/Keeppack仓库/出库管理",
    "warehouseKeeppackWarehouse": "仓库/Keeppack仓库/库存拉取记录",
    "theCurrentCommodityWarehouseIsEmpty": "当前商品仓库为空，请优先在keeppack完成入库",
    "YouWillNotBeAble": "1. 您将无法看到该店铺的订单信息。\n2. 关于店铺设置模块您都无法找到该店铺。",
    "temperatureControlled": "温度控制",
    "standard": "标准存储",
    "storageType": "存储类型",
    "enableWare": "确定要开启多仓库模式吗？",
    "afterEnablingTheMultiwarehouseModeAnd": "开启多仓库模式并发布商品后，不能关闭多仓库模式。",
    "activateMultiwarehouse": "开启多仓",
    "turnOffMultiwarehouse": "关闭多仓",
    "wantTo": "确定要关闭多仓库模式吗？",
    "afterTheMultiwarehouseModeIsTurned": "关闭多仓库模式后，多仓仓库和库存信息将会清空。",
    "kongfuWarehouse": "KONGFU仓库",
    "useTheStockInformationOfThe": "使用KONGFU仓库的库存信息可开启自动同步，提升管理效率",
    "afterTheAuthorizationIsSuccessfulYou": "授权成功后，可使用 KONGFU 履约服务，进行商品出入库管理；",
    "afterClickingTheAuthorizeButtonKongfus": "点击【去授权】按钮后，将为您拉取KONGFU的仓库信息，请选择需要的仓库完成授权流程",
    "PleaseContactKongfuCustomerService": "2、secretKey请与KONGFU客服获取",
    "ItIsRecommendedToFill": "1、账号名与货主名建议填写与KONGFU约定的信息",
    "informationDescription": "信息说明",
    "consignorName": "货主名",
    "acuntName": "账户名",
    "completeTheRepositoryAuthorizationConfirmationIn": "KONGFU的配置管理中完成仓库授权确认",
    "afterSelectingAndSavingSubjectTo": "选择并保存后，受KONGFU授权流程要求，请线下联系KONGFU客服，完成仓库授权审核，并返回Ginee系统，在【授权-店铺管理】",
    "pleaseSelectTheWarehouseInformationThat": "请选择需要授权的仓库信息，完成线上授权流程",
    "pleaseSelectTheWarehouseThatNeeds": "请选择需要授权的仓库",
    "applyForAuthorization": "申请授权",
    "afterTheAuthorizationIsCompletedPlease": "审核完成后，请务必返回Ginee系统，在【授权-店铺管理】KONGFU的配置管理中完成仓库授权确认",
    "pleaseContactKongfuCustomerServiceOffline": "请线下联系KONGFU客服，完成仓库授权审核",
    "approvalStatus": "审核状态",
    "reasonForFailureTheThirdParty": "失败原因：三方仓拒绝了该仓库授权申请，请联系三方仓客服确认",
    "timeToOperateAuthorizedWarehouseInformation": "在Ginee内操作授权仓库信息的时间",
    "theTimeToUpdateWarehouseInformation": "在Ginee内操作更新仓库信息的时间",
    "consignorCode": "货主编码",
    "theSystemWillAutomaticallyUpdateThe": "系统将每隔1小时为您自动更新三方仓审核状态，如需实时查看审核结果，可手动点击【更新审核状态】后查看",
    "passed": "已通过",
    "theWarehouseAuthorizationApplicationInformationHas": "仓库授权申请信息已成功告知三方仓",
    "afterConfirmationTheSystemWillCall": "确认后，系统将为您调用KONGFU接口，发起仓库授权申请，请在确认申请后，自行联系KONGFU客服人员完成授权审核，系统每隔1小时将为您自动同步一次审核状态，如您需要实时查看审核状态，请自行在配置管理页面操作【更新审核状态】获取审核结果",
    "areYouSureToInitiateA": "确认向三方仓发起仓库授权申请么？",
    "initiateAuthorizationApplication": "发起授权申请",
    "theListOnlyShowsUnauthorizedWarehouse": "列表仅展示未授权的仓库信息，请在确认后线下联系Kongfu客服完成审核确认",
    "selectAuthorizedWarehouse": "选择授权仓库",
    "gineeWillPullTheWarehouseInformation": "Ginee 将为您通过调用KONGFU接口，拉取KONGFU内的仓库信息，请选择需要的仓库授权，并自行联系KONGFU客服人员完成新仓库的授权审核",
    "tipsa": "温馨提示",
    "startToPullTheLatestWarehouse": "开始为您拉取 KONGFU 的最新仓库信息，等待KONGFU接口返回信息，请耐心等待",
    "updateApprovalStatus": "更新审核状态",
    "confirmAuthorizedWarehouseIdXxxx": "确认授权仓库ID：XXXX , XXXX",
    "authorizationStatusXxxx": "授权状态：{XXXX}",
    "addWarehousesForApplicationAuthorization": "新增申请授权 3 个仓库，仓库ID：XXXX , XXXX",
    "noMoreThanXxxCharacters": "不超过{X}个字符",
    "customsWeight": "报关重量",
    "customsDeclarationAmount": "报关金额",
    "customsCode": "海关编码",
    "customsEnglishName": "报关英文名",
    "customsDeclarationChineseName": "报关中文名",
    "ifTheWarehouseForThisDelivery": "如本次下发仓库为KONGFU，且商品有报关需要，请填写报关信息",
    "customsDeclarationInformation": "报关信息",
    "theProductDeclarationInformationIsMissing": "商品报关信息缺失，请移步至主商品编辑页面补充完整后操作",
    "mskuDoesNotConformToThe": "MSKU不符合三方仓仓库接口规范，请修改后操作",
    "theProductNameDoesNotConform": "商品名称不符合三方仓仓库接口规范，请修改后操作",
    "TheMskuDoesNotExceed": "MSKU编号不超过50个字符，仅支持数字、字母、及特殊符号“-”",
    "ProductNameDoesNotExceed": "商品名称不超过200个字符",
    "channelRequirements": "渠道要求",
    "ifTheProductsDeliveredToThe": "如本次下发仓库的商品为头程或有中国境内跨境发货需要，请务必选择需申报并填写申报信息",
    "needToDeclare": "需申报",
    "noDeclarationRequired": "无需申报",
    "totalNumberOfSkus": "MSKU个数",
    "awb": "物流单号",
    "outboundAmount": "出库金额",
    "outboundQuantity": "出库数量",
    "outboundInformation": "出库信息",
    "doNotEnableAutomaticBinding": "不开启自动绑定",
    "thisFieldIsRequiredWhenThe": "三方仓接口要求退货入库时，该字段必填",
    "returnTrackingNumber": "退货快递单号",
    "thirdpartyWarehouseTransactionReturn": "三方仓异动回传",
    "manualSync": "手动同步",
    "timingSynchronization": "定时同步",
    "reasonReceivedAThirdpartyWarehouseInterface": "原因：接收到三方仓接口提示{x}",
    "actualInbound": "实际入库",
    "theOrderIsInconsistentWithThe": "订单与仓库所在国家不一致，请及时更换仓库",
    "theChannelInterfaceCannotGenerateThe": "渠道接口无法生成面单，当前仓库无法履约，请及时更换仓库",
    "mskuIsNotAddToThe": "MSKU未添加至仓库，请添加后操作",
    "rejected": "已拒绝",
    "outboundListOutboundListInThe": "3.出库单：仓库内的出库单，包含编号、状态和详情",
    "inboundListInboundListInThe": "2.入库单：仓库内的入库单，包含编号、状态和详情",
    "productsProductsInTheWarehouseIncluding": "1.商品：仓库内的商品，包含详情、库存和库内价值",
    "agreeToAuthorizeGineeToObtain": "授权Ginee获取仓库如下信息",
    "editMultiwarehouseStock": "编辑多仓库存",
    "totalStock": "总库存",
    "add2": "添加",
    "openMultiwarehouses": "开启多仓库模式",
    "closeMultipleWarehouses": "关闭多仓库",
    "noteHighSpeedPrintingDoesNot": "注：高速打印暂不支持打印Ginee自定义快递单(Tokopedia暂只支持Ginee自定义快递单)",
    "massEditStockIsAvailableFor": "批量编辑库存只针对非多仓库存有效",
    "agreecontinueAuthorization": "同意并继续授权",
    "theStockAndProductInformationOf": "使用Fastock仓库的库存及商品信息会自动进行双向同步，提升管理效率",
    "afterClickingTheAuthorizeButtonYou": "点击【去授权】按钮后可使用Fastock履约服务，进行商品出入库管理；",
    "PleaseContactTheWarehouseService": "2. 客户编码/仓库编码/AppKey/SecretKey请联系仓库服务商获取",
    "BeSureToSelectThe": "1. 请务必正确选择服务站点，否则授权将无法成功",
    "ifYouNeedToChangeWarehouse": "如需更改仓库信息，请移步至配置管理操作",
    "theFastockFulfillmentWarehouseHasBeen": "已为您成功创建Fastock履约仓库，可以开始使用啦",
    "received": "接收",
    "warehouseBreadcrumbProduct": "仓库 / {warehouseName}仓库 / 商品信息",
    "warehouseBreadcrumbInbound": "仓库 / {warehouseName}仓库 / 入库管理",
    "warehouseBreadcrumbOutbound": "仓库 / {warehouseName}仓库 / 出库管理",
    "warehouseBreadcrumbPullRecord": "仓库 / {warehouseName}仓库 / 库存拉取记录",
    "unboundStore": "未绑定店铺",
    "storeAuthorizationHasExpired": "店铺授权已过期",
    "hongkong": "香港",
    "whetherItIsTurnedOnOr": "是否开启",
    "theSkuRemovalQuantityCannotExceed": "sku移除数量不能超过订单数量",
    "productCodeRepeatedModification": "商品编码重复修改",
    "cantDeleteAllDetails": "不能删除全部明细信息",
    "theOutboundListHasBeenAllocated": "出库单已分配完成，无法修改",
    "failedToRequestGxb": "请求gxb失败",
    "theDetailsOfTheOutboundList": "出库单明细存在已分配完成的明细，不支持修改",
    "outboundListDetailsDontExist": "出库单明细不存在",
    "theMergingSkuOfInboundList": "入库单合并sku异常，请联系管理员",
    "theSkuAcceptanceQuantityOfThe": "入库单sku验收数量发送变化，请重试",
    "orderDetailsCannotBeChecked": "查询不到订单明细信息",
    "productInformationDoesNotExist": "商品资料不存在",
    "returnAwbNumberCannotBeEmpty": "退件运单号不能为空",
    "theInboundListHasBeenAccepted": "该入库单已验收",
    "theInboundListDoesntExist": "该入库单不存在",
    "multipleLastMileScacCodesCannot": "同一订单下不能出现多个尾程承运商编码",
    "theDomesticAwbNumberUnderThe": "同一订单下国内物流单号不能重复",
    "homeDeliveryDoesntNeedToFill": "送货上门不需要填写提货人信息",
    "weightCanOnlyBeAPositive": "重量只能是正整数",
    "theLastMilesTrackingNumberIs": "尾程承运商单号不为空，尾程承运商编码不能为空",
    "theLabelPdfIsntEmptyAnd": "面单PDF不为空，尾程单号不能为空",
    "theSelectedWarehouseDoesNotSupport": "所选仓库不支持该业务",
    "lastMileInformationlogisticsProductCannotBe": "尾程信息/物流产品不能同时为空",
    "documentNumberCannotBeEmpty": "单据编号不能为空",
    "productQuantityCanOnlyBeA": "商品数量只能是正整数",
    "theDestinationCountryMustBeConsistent": "目的国与收件人国家需保持一致",
    "recipientCountryprovincecityParameterIsMissing": "收件人国/省/市参数缺失",
    "theOrderHasBeenProcessedAnd": "订单已处理，不能修改",
    "matchesMultipleOrders": "匹配到多个订单",
    "notWeighedWithoutWeight": "未称重无重量",
    "theDocumentNumberAndLastMile": "单据号、尾程单号为空",
    "lastLegNumberAlreadyExists": "尾程单号已存在",
    "orderShipped": "订单已发运",
    "pickupPersonInformationCannotBeEmpty": "提货人信息不能为空",
    "theOrderHasBeenShippedAnd": "订单已发运，不能取消",
    "senderCountryprovincecityParameterIsMissing": "发件人国/省/市参数缺失",
    "logisticsChannelDoesntSupport": "物流渠道不支持",
    "thisStatusDoesntSupportModification": "该状态不支持修改",
    "pleaseCheckThePickupMethod": "请检查取货方式",
    "outboundTypeNotMapped": "出库类型未映射",
    "pleaseCheckTheScac": "请检查承运商编码",
    "productcombinedProductQuantityMustBe": "商品组商品数量必须大于零",
    "documentNumberAlreadyExists": "单据编号已存在",
    "pleaseCheckTheReceiver": "请检查收件人",
    "warehouseCodeDoesNotExist": "仓库编码不存在",
    "codAmountAndCodCurrencyCan": "COD金额、COD币种不能只填写一个",
    "pleaseCheckTheBusinessType": "请检查业务类型",
    "failedToCheckDestinationCountryprovinceregion": "目的国/省市区查询失败",
    "logisticsChannelTrackingFailed": "物流渠道查询失败",
    "noPermissionToOperateThisOrder": "无此订单操作权限",
    "thePlatformHasAuthorizedTheConsignor": "平台已授权此货主",
    "tokenError": "token有误",
    "noValidAuthorizedWarehouse": "无有效的可授权仓库",
    "platformMismatch": "平台不匹配",
    "noConsignorPermission": "无此货主权限",
    "theTokenDoesntMatch": "token不匹配",
    "thePlatformConsignorDoesntMatch": "平台货主不匹配",
    "pleaseCheckIfThereIsError": "货品仓库错误请核对",
    "authorizationHasBeenApprovedPleaseDont": "授权已经审核，请勿重复操作",
    "noConsignorOnThisPlatform": "此平台无货主",
    "userHasNo": "用户无此仓库授权",
    "userHasNoAuthorizationForThis": "用户无此货主授权",
    "userDoesntHaveConsignorsAuthorization": "用户无货主授权",
    "warehouseAuthorizationRecordDoesNotExist": "仓库授权记录不存在",
    "duplicateAuthorizationApplication": "授权申请重复",
    "warehouseDoesntExist": "仓库不存在",
    "consignorDoesntExists": "货主不存在",
    "platformInformationVerificationFailedPleaseContact": "平台信息校验失败，请联系管理员",
    "consignorAlreadyExists": "货主已存在",
    "noTrackingNumber": "无物流单号",
    "hasTrackingNumber": "有物流单号",
    "trackingNumberStatus": "物流单号状态",
    "rulesBetween": "{name}必须在{range}之间！",
    "waitingOutbound": "待出库",
    "productPriceShouldBeBetween": "商品金额必须在1~ 1,000,000,000之间",
    "pleaseMoveToTheMasterProduct": "请移步至主商品编辑页面补充完整后操作",
    "kongfuRequiresThatTheLabelMust": "*KONGFU要求快递单必须以“.pdf”结尾，授权成功，则意味您同意KONGFU获取长期有效的快递单",
    "outboundListNumber": "出库单号",
    "inventoryChangesDueToManualOperation": "因仓库人员手动操作，导致库存变动",
    "totalDiscountedPrice": "商品折扣总价",
    "inventoryChangesDueToWarehousePersonnel": "因仓库人员手动取消入库，变更已入库库存数量，导致库存变动",
    "thirdpartyWarehouseInboundAdjustment": "三方仓入库调整",
    "sinceTheKongfuApiLimitsThe": "因KONGFU接口限制入库成本不超出999,999，因此，如成本大于999,999时，系统将按照999,999进行推送，请知悉",
    "pleaseCheckTheDocumentType": "请检查单据类型",
    "tryNow": "马上体验",
    "talkLater": "稍后再说",
    "congratulationsYouWillGetADay": "恭喜您，获得1次系统完整功能免费试用7天的机会",
    "theTrialVersionHasExpiredAnd": "试用版本已到期，免费套餐只能试用一次，如需升级，请购买套餐",
    "theTrialPackageWasUpdatedSuccessfully": "试用套餐更新成功",
    "warehouseWarehousenameWarehouseActive": "仓库 / {warehouseName}仓库 / {active}",
    "theSalesTypeNameIsEmpty": "销售类型名称为空",
    "generalInformation": "一般信息",
    "imageForPos": "POS 图像",
    "onlineAttributes": "在线属性",
    "refurbished": "翻新",
    "insertProductWeightIncludingThePackaging": "插入包括包装在内的产品重量以计算运费。",
    "maximumMax": "最大值:{max}",
    "kilogramKg": "千克 (kg)",
    "gramGr": "克 (gr)",
    "insertProductDimensionAfterPackagingTo": "在包装后插入产品尺寸以计算运费。",
    "activatingThePreorderFeatureAllowsYou": "激活预购功能可让您将订单处理时间最多延长 90 天（包括周末和公共假期）。",
    "productIsReadyStock": "产品是现货。",
    "productNeedLongerShippingProcess": "产品需要更长的运输过程。",
    "processingTime": "处理时间",
    "mokaOrderBetweenDays": "Moka 订单：1-90 天之间。",
    "mokaProductImageTooltip": "最多可添加 12 张照片。 最小 500x500 像素，最大 8MB。 格式：PNG、JPEG、BMP。",
    "Inventory": "库存",
    "cost": "成本",
    "isMultiplePricePerSalesType": "是否每个销售类型有多个价格",
    "trackStock": "追踪库存",
    "alert": "警告",
    "alertAt": "警告值",
    "itemStockCanNotBeChanged": "商品库存保存后无法更改商品库存，请确保商品库存无误！",
    "thisItemCanNotBeTracked": "无法跟踪此商品，因为未跟踪库存。",
    "avgCostCanNotBeChanged": "平均成本保存后无法更改，请确保正确无误！",
    "trackCogs": "追踪COGS",
    "avgCost": "平均成本",
    "theSynchronizationIsCompleteAndThe": "同步完成, 以下销售类型已无效",
    "Empty": "空",
    "domesticLogisticsTrackingNumberCannotBe": "国内物流单号不能为空",
    "consignorDoesntMatch": "货主不匹配",
    "documentNumberDoesNotExist": "单据编号不存在",
    "trackingNumberAlreadyExists": "运单号已存在",
    "packageAlreadyExists": "包裹已存在",
    "paketTambahanTidakBolehLebihBesar": "追加包裹不能大于商品总数量",
    "theOrderHasBeenShippedNo": "订单已发运，不能追加包裹",
    "productInformationCantBeEmpty": "商品信息不能为空",
    "theresEmptyDataInTheCombination": "商品组信息包裹编号存在空数据",
    "declaredAmount": "申报金额",
    "declaredWeight": "申报重量",
    "menuProductMoka": "商品列表 / Moka POS",
    "menuProductMokaAdd": "商品列表 / Moka POS / 添加商品",
    "menuProductMokaEdit": "商品列表 / Moka POS / 编辑商品",
    "stockInOutAlert": "出入库记录将显示过去3个月仓库库存的所有变动",
    "theStockCanBePushedTo": "追踪库存开关开启后，才能将库存推送至Moka",
    "youHaveADayFullfeaturedTrial": "您有一次机会可以免费试用全功能7天！",
    "youAreUsingTheFreeVersion": "您正在使用Ginee免费版。如您有仓库管理、库存同步等功能的需求，可申请一次7天免费试用Ginee全功能。\n确认要申请试用吗？(申请后预计15分钟内生效)",
    "Cname": "渠道名称",
    "thisStaffAccountHasNoPermission": "该员工账号无权限访问系统，请联系商家/管理员将员工账号添加到系统并分配权限",
    "makeSureTheSwitchIsTurned": "修改库存时确保开关已经开启，否则修改不成功",
    "printTaskCompletionTime": "打印任务完成时间",
    "printTaskCreationTime": "打印任务创建时间",
    "noteLazadaAndBlibliOrdersDo": "注意：Lazada和Blibli的订单不支持设置打包状态",
    "prefixMatch": "前缀匹配",
    "prefixSearch": "前缀搜索",
    "GineeAccountCanOnlyAuthorize": "1个Ginee账号仅支持授权1个Accurate账号，如有更多需求请联系客服",
    "matchingFailedThePreviousMatchingOption": "匹配失败：无法找到之前的匹配选项，请确认选项是否已在Accurate删除",
    "itemPriceCredit": "货款",
    "freeShippingMaxFee": "包邮促销费用",
    "paymentFee": "付款手续费",
    "lazpicklaztopCommission": "Lazpick/LazTop佣金",
    "platformRequirementsTheDescriptionLengthMust": "平台要求：描述长度范围必须在100~3000字符之间",
    "currentlyBumped": "当前置顶",
    "numberOfBumpProducts": "置顶产品数",
    "bumpFixedSupportMaximumProducts": "固定置顶最多置顶5个产品",
    "bumpfixed": "固定置顶",
    "bumpByTurns": "轮流置顶",
    "setAsBumpFixed": "设为固定置顶",
    "setAsBumpByTurns": "设为轮流置顶",
    "tipsYouCanAddUpTo": "提示：一次最多添加30个产品，请优先同步店铺内产品信息",
    "editingInformationSuchAsStockAnd": "不支持编辑组合商品的库存、采购价等信息",
    "failedToUploadImagePleaseReupload": "上传图片失败，请重新上传图片后再试",
    "solutionSteps": "解决步骤",
    "addBinding": "添加绑定",
    "pleaseClickToRedistribute": "请点击重新配货",
    "pleaseAddBindingRelationship": "请添加绑定关系",
    "pleaseEnableDistributionSettings": "请开启配货设置",
    "distributionSettingsArentEnabled": "配货设置未开",
    "pleaseRestoreTheOrder": "请还原订单",
    "ordersThatHaveBeenPlacedOn": "订单已被搁置不参与库存锁定或扣减",
    "problemCauses": "问题原因",
    "pleaseCreateAnInboundListAnd": "请创建入库单并完成入库",
    "pleaseAdjustTheShippingWarehouse": "请调整分仓规则",
    "pleaseCompleteTheInboundInThe": "请在三方仓内完成入库",
    "pleaseCreateAnInboundList": "请创建入库单",
    "mskuInXWarehouseYOut": "仓库{x}中的MSKU:{y}缺货",
    "theOrderStatusOfTheX": "{x}渠道的订单状态为:{y}时不支持创建出库单",
    "currentlyDoesNotSupportTheCreation": "暂不支持为{x}渠道创建出库单",
    "theXThirdpartyWarehouseDoesNot": "{x}三方仓暂不支持为{y}国家创建出库单",
    "theXThirdpartyWarehouseDoesntCurrently": "{x}三方仓暂不支持为{y}渠道创建出库单",
    "bumpFixed": "固定置顶",
    "serviceFeeMapping": "服务费映射",
    "platformFulfillmentFeeMapping": "系统代付运费映射",
    "platformRebateMapping": "平台补贴映射",
    "voucherSellerMapping": "优惠券卖家映射",
    "shippingDifferenceCashlessMapping": "运费补贴映射",
    "shippingFeeMapping": "物流费映射",
    "shippingPriceSetting": "物流价格",
    "taxSetting": "税设置",
    "salesInvoiceSetting": "销售发票设置",
    "storeMapping": "店铺匹配",
    "warehouseMapping": "仓库匹配",
    "database": "数据库",
    "newCompleteOrdersAfterTheSt": "本月1日之后的新“完成订单”",
    "newCompleteOrderAfterMappingStore": "匹配店铺之后的新“完成订单”",
    "afterEnablingIfAccurateHasStock": "开启后，如果Accurate有库存变化：\n1、将实时推送给Ginee，且修改Ginee的库存；\n2、支持在库存清单页面手动拉取Accurate的最新库存值。",
    "stockSyncRules": "库存同步规则",
    "mappingTime": "匹配时间",
    "branch": "分支机构",
    "chartOfAccounts": "会计科目",
    "pleaseCompleteAtLeast": "请至少完成1个店铺匹配，店铺匹配成功后该店铺的完成订单将自动创建Sales invoice并推送到Accurate",
    "gineeWarehouseCanOnly": "1个Ginee仓库只能与1个Accurate仓库建立匹配，请至少完成1个仓库匹配",
    "enabledByDefault": "默认开启",
    "autoCreateRulesForSalesInvoice": "自动创建销售发票的规则",
    "syncFromAccurateToGinee": "从 Accurate 同步到 Ginee",
    "syncFromGineeToAccurate": "从 Ginee 同步到 Accurate",
    "finance": "财务",
    "mapFailed": "匹配失败",
    "notMapped": "未匹配",
    "mapped": "已匹配",
    "stockChange": "库存变更",
    "pushMapMsku": "推送&匹配MSKU",
    "repushStock": "重新推送库存",
    "upToCharactersAndOnly": "最多50个字符且仅支持: a-z, A-Z, 0-9, \"(\", \")\", \"-\", \".\", \"_\", \"/\"且采购价必须>=1",
    "allUncreatedAndUnmappedMskusCurrently": "当前及后续新增的全部未创建、未匹配的MSKU",
    "afterItIsEnabledAllMskus": "开启后，已映射的Ginee仓库中的全部MSKU将推送到Accurate仓库，新SKU将自动在Accurate创建，已有SKU将自动和Accurate匹配",
    "mappingRules": "匹配规则",
    "autoPushMap": "自动推送&匹配",
    "thisSkuAlreadyExists": "该SKU已存在",
    "unitCostMustBe": "Unit Cost必须>=1",
    "salesInvoice": "销售发票",
    "syncStock": "同步库存",
    "theStoreIsMissingMappingInformation": "该店铺缺少匹配信息（Warehouse、Branch、Chart of Account、Customer）",
    "source": "来源",
    "associatedOrderNumber": "关联订单号",
    "itIsRecommendedToConvertTo": "建议换算为系统设置的金额币种",
    "theCurrencySymbolIsBasedOn": "币种符号为系统中设置的币种符号\\n金额为商品入库的平均成本价",
    "bulkrepush": "批量重新推送",
    "alasanGagalPushGudangPihakKetiga": "推送三方仓失败原因",
    "noShelf": "默认货架",
    "plannedQuantityTip": "填写商品计划出库的数量",
    "plannedQuantityTipOld": "计划出库数量：填写商品计划出库的数量",
    "selectLocationTip": "选择SKU出库的货位",
    "exportSelectedOrderDetail": "出库单明细-勾选",
    "exportAllOrderDetail": "出库单明细-全部",
    "exportSelectedOrderList": "出库单列表-勾选",
    "exportAllOrderList": "出库单列表-全部",
    "mskuAndBarcode": "主SKU/条码",
    "confirmToSetAsInvalid": "确定要作废吗？",
    "bulkSubmitSuccess": "提交成功，请刷新页面查看处理结果",
    "bindFailed": "绑定失败，请重新选择货位",
    "bindSuccess": "绑定成功",
    "autoCreateAndBind": "自动创建货位并与主SKU绑定",
    "warehouseName2": "仓库名称",
    "warehouseCode": "仓库代码",
    "quantityAdded": "已添加数量",
    "sureToDelete": "确定删除吗？",
    "selectLocation": "选择SKU在仓库存放的货位",
    "enterQuantity": "输入本次出库的数量",
    "createLocationTips": "如果你需要批量创建新的货位，可以在货位管理中进行创建。",
    "locationManagement": "货位管理",
    "warehouseStockColumnName": "仓库库存",
    "stockNotEnough": "仓库库存不足，无法出库",
    "externalOrderNo": "订单号",
    "numSelectedWithValue": "已选中 {val}",
    "column3TipOld": "第3列：货位（选填）如果不填，则选择可用库存最大的货位",
    "column2TipOld": "第2列：数量（必填）",
    "column1TipOld": "第1列：SKU（必填）",
    "column3Tip": "第3列：货架（选填）如果不填写，则使用默认货位",
    "column2Tip": "第2列：数量（必填）",
    "column1Tip": "第1列：SKU（必填）",
    "copyTip": "从excel复制SKU、出库数量、货架、货位到文本框，可快速解析、提交",
    "printDetails": "打印商品明细",
    "copyTip2": "第1列SKU，第2列数量，第3列货位",
    "logisticsNumberLengthError": "物流单号最长200字符",
    "duplicateLocationError": "货位信息重复，同一个货位只能提交一行。",
    "setPushRatio": "店铺发货仓设置——店铺发货仓设置—如果你有多个店铺，在这里可以为每个店铺设置关联的仓库，也可以为每个店铺设置库存推送的比例",
    "ifOnlyOneWarehouse": "如果你只有一个仓库，也不需要对货位进行关联，那么在创建主商品的时候，系统会帮你为每个SKU自动创建一个货位，可以直接使用。",
    "addProductsToLocation": "仓库库存明细——货架设置好之后，就可以将商品放入货架了，在仓库库存明细页面，点击“添加商品到货位”，就可以将商品与货位进行关联，关联完成之后，就可以对这些货位中的商品进行库存管理了。",
    "setUpLocation": "货位管理——商品在仓库是放在货架中的，你需要在货位管理中设置仓库的货架",
    "setUpWarehouse": "仓库管理——你需要在仓库管理功能中，设置你的仓库",
    "helpShippingSettings": "店铺发货仓设置",
    "helpStockDetails": "仓库库存明细",
    "helpLocationManage": "货位管理",
    "helpWarehouseManage": "仓库管理",
    "warehouseManagementIntro": "仓库管理相关功能介绍",
    "moreThanPlannedError": "已超出计划出库数量",
    "INVALID": "已作废",
    "PART_INVALID": "部分作废",
    "COMPLETE": "已完成",
    "PART_COMPLETE": "部分完成",
    "PENDING": "待出库",
    "CREATE": "处理中",
    "changeWarehouseAlert": "切换仓库需要重新添加SKU信息，请确认",
    "processResult": "处理状态",
    "complete": "已完成",
    "pushComplete": "推送成功",
    "pushing": "推送中",
    "workingStatusCANCEL_FAIL": "取消失败",
    "workingStatusCANCEL_SUCCESS": "取消成功",
    "workingStatusCANCELED": "已取消",
    "workingStatusCANCEL": "已取消",
    "workingStatusOUTBOUND_DONE": "已完成出库",
    "workingStatusCANCELING": "取消中",
    "workingStatusWAITING_PICKUP": "待揽收",
    "workingStatusPACKED": "已打包",
    "workingStatusSORTING_AND_REVIEWING": "分拣、复核中",
    "workingStatusPICKING_PROCESS": "拣货中",
    "workingStatusALLOCATED": "已分配",
    "workingStatusRECEIVED": "已接收",
    "workingStatusSYNC_FAIL": "同步失败",
    "workingStatusSYNC_SUCCESS": "同步成功",
    "workingStatusCREATE": "处理中",
    "workingStatus": "作业状态",
    "express": "快递",
    "editOutboundList": "编辑出库单",
    "defaultDelivery": "默认",
    "selfPickup": "自取",
    "outboundListDetail": "出库单详情",
    "failedLineTip": "失败（此行无法添加到出库单）",
    "ifMoreThanStock1": "如果SKU数量大于可用库存，则按照最大可用库存提交",
    "ifTwoColumns1": "如果只有两列，则按照SKU+数量解析",
    "copyTipOld": "从excel复制SKU、数量、货位，Ginee解析",
    "outboundListInvalid": "出库单作废",
    "outboundAll": "全量出库",
    "outboundListOutbound": "出库单出库",
    "outboundManagement": "出库单管理",
    "scanInstruction": "可通过扫枪扫描条码并按回车，如果解析成功，商品实际出库数量+1",
    "receivedProduct": "入库商品",
    "goBack": "返回",
    "addToOutboundList": "添加到出库单",
    "row": "条",
    "operationSuccessful": "操作成功",
    "editSuccess": "编辑成功",
    "identify": "解析",
    "identifyResults": "解析结果",
    "ifMoreThanStock": "如果SKU数量大于可用库存，则按照最大可用库存提交",
    "ifTwoColumns": "如果只有两列，则按照SKU+数量解析",
    "submit": "提交",
    "directOutbound": "直接出库",
    "locationStockNotEnough": "货位可用库存不足",
    "locationAvailableStock": "货位可用库存",
    "warehouseAvailableStock": "仓库可用库存",
    "note": "备注",
    "plannedTotalAmount": "计划总金额",
    "unitPrice": "单价",
    "associatedDocumentNumber": "关联单据编号",
    "refreshStatus": "刷新状态",
    "outboundTime": "出库时间",
    "deliveryMethod": "配送方式",
    "actualOutboundQty": "实际出库数量",
    "plannedOutboundQty": "计划出库数量",
    "actualOutboundAmount": "实际出库金额",
    "plannedOutboundAmount": "计划出库金额",
    "searchContent": "搜索内容",
    "sortListSelected": "分拣单—勾选部分",
    "sortListAll": "分拣单—全部",
    "pickListSelected": "拣货单—勾选部分",
    "pickListAll": "拣货单—全部",
    "outboundListSelected": "出库单—勾选部分",
    "outboundListAll": "出库单—全部",
    "createOutboundList": "创建出库单",
    "greaterThan30Days": "＞30天",
    "lessThan30Days": "≤30天",
    "lessThan7Days": "≤7天",
    "lessThan3Days": "≤3天",
    "listNumber": "单号",
    "searchType": "搜索类型",
    "allocateOutboundList": "调拨出库单",
    "documentType": "单据类型",
    "shippedOld": "已出库",
    "documentStatus": "单据状态",
    "pleaseSelectData": "请勾选要导出的数据",
    "basicFeaturesFreeForever": "永久免费",
    "nMinutesRemaining": "剩余{minus}分钟",
    "gineeAndAccurateAreMappedBy": "Ginee与Accurate通过SKU进行匹配，由于Accurate不区分大小写，请匹配前确保该仓库下没有同名的SKU",
    "accurateRequiresSkuToHaveA": "Accurate要求MSKU最多50个字符且只支持：a-z, A-Z, 0-9, \"(\", \")\", \"-\", \".\", \"_\", \"/\"",
    "otherSettingsAndMapping": "其他设置和匹配",
    "mapping": "匹配中",
    "automapSettings": "自动匹配设置",
    "pleaseSetAndMapTheCorrect": "请设置和匹配正确的信息",
    "basedOnAccurateStock": "以Accurate库存为准",
    "basedOnGineeStock": "以Ginee库存为准",
    "whenTheSkuOfGineeAnd": "当Ginee和Accurate的SKU进行匹配时，如果2个系统库存不一致，请选择以哪个系统的库存为准，Ginee会在匹配后自动推送库存或拉取库存",
    "startingStock": "初始库存",
    "theSubproductsOfTheCombinedProduct": "组合商品中的子商品尚未匹配完成，请先完成子商品的匹配后再匹配组合商品",
    "theWarehouseHasMatchedTheAccurate": "该仓库已匹配Accurate仓库，商品的库存和成本价必须>0",
    "accurateAuthorizationHasExpiredPleaseReauthorize": "Accurate授权已失效，请重新授权！",
    "accurateRequiresInventoryToBe": "Accurate要求库存和成本价必须>0",
    "goToChange": "去修改",
    "inboundWhenAddedToWarehouse": "添加到仓库时入库",
    "youHaveNotCompletedTheInitial": "您尚未完成Accurate初始化配置，配置后才可使用相关功能",
    "failedToDeleteThisProductHas": "删除失败！该商品已被组合商品关联，请先删除组合商品或解绑组合商品",
    "noteIfTheWarehouseIsAdded": "注意：如果添加入库的是WMS仓库或三方仓，添加入库后需要在WMS/三方仓确认入库才可成功开启库存同步",
    "partnerAuthorizationFailed": "合作伙伴授权失败",
    "customerMustBeFilled": "客户必须填写",
    "itemXSuccessfullySaved": "商品\"{x}\"已保存",
    "unitQtyMustBeFilled": "单位数量必须填写",
    "itemXNotFoundOrHas": "{x}商品未找到或已删除",
    "accurateConfigurationInformationIsMissingPlease": "Accurate配置信息缺失，请检查配置管理后重试",
    "theWarehouseXWasNotFound": "仓库“{x}”不存在或已删除",
    "brazil": "巴西",
    "taiwan": "台湾",
    "savedSuccessfullyThisPageWillClose": "保存成功！该页面将自动关闭",
    "salesInvoicePushRecord": "销售发票推送记录",
    "orderPushTime": "订单推送时间",
    "orderCompleteTime": "订单完成时间",
    "completeTime": "完成时间",
    "updateResult": "更新结果",
    "productManage": "商品管理",
    "becauseTheNumberOfStoresExceeds": "您的订单量已到达套餐上限，该功能无法使用。请联系客服订阅新的套餐",
    "theAuthorizationInformationIsFilledIncorrectly": "授权信息填写错误，请参考授权帮助文档并重新填写提交",
    "channelItemXMoveOutPromotion": "店铺:{x}的商品:{y}移出活动ID:{z}",
    "xManualReleaseOfReservedPromotion": "{x}手动释放预留活动库存",
    "promotionIdXHasEnded": "店铺:{x}的商品:{y}参加的活动ID:{z}已结束",
    "xManuallyEditedReservedPromotionStock": "{x}手动编辑预留活动库存，由{y}更新为{z}",
    "channelProductXActivityIdY": "店铺:{x}的商品:{y}参加活动ID:{z}",
    "unknownSystemException": "未知系统异常，请联系客服",
    "theChannelProductSpecificationCorrespondingTo": "未找到活动中对应的渠道商品规格",
    "theChannelProductCorrespondingToThe": "未找到活动中对应的渠道商品",
    "hasNoWarehouseInformationAndCannot": "MSKU无仓库信息，无法预留活动库存",
    "channelSkuHasNoBoundMsku": "渠道SKU无绑定的MSKU，请先去主商品列表绑定该SKU",
    "notCompliantWithPromotionReservation": "不符合活动预留策略：“未开始及进行中的活动均自动预留”",
    "promotionReservationPolicyNotMet": "不符合活动预留策略：“仅对进行中的活动自动预留”",
    "theCategoryOrAttributeInformationIs": "类目或属性信息不正确，请同步类目后重试",
    "setUpOrderInvoices": "设置订单发票",
    "orderPacking": "订单打包",
    "orderDistribution": "订单配货",
    "syncOrder": "订单同步",
    "theOrderSSuccessfullyPrintedThe": "订单【%s】成功打印拣货单",
    "theOrderssuccessfullyPrintedThePackingList": "订单【%s】成功打印装箱单",
    "orderSuccessInvoice": "订单【%s】成功打印发票",
    "orderSuccessLabel": "订单【%s】成功打印快递单",
    "ordersreturnedInboundTheReturnInboundList": "订单【%s】退货入库，退货入库单创建成功，单号：<a href=\"/erp/main/outbound-management-detail/%s/SELL\" target=\"_blank\">%s</a>",
    "ordersredistributedSuccessfully": "手动重新配货订单[{0}]，配货结果请查看后续操作日志",
    "ordersfailedToPushWarehouseForDelivery": "订单【%s】推送仓库发货失败，原因：%s",
    "theOrdersisSuccessfullyPushedToThe": "订单【%s】推送仓库成功",
    "orderspushWarehouseOperationSuccessfully": "订单【%s】推送仓库操作成功",
    "theOrderSSuccessfullySetThe": "订单【%s】设置发票成功",
    "ordersarrangedDeliverySuccessfully": "订单【%s】安排发货成功",
    "orderspackedSuccessfully": "订单【%s】打包成功",
    "orderscancelledSuccessfully": "订单【%s】取消成功",
    "theWarehouseSelectedForProductS": "订单【%s】中的商品【%s】所选仓库由【%s】更换为【%s】",
    "theProductSInTheOrder": "订单【%s】中商品【%s】更换为【%s】，所选仓库为【%s】",
    "theOrderSDeletesTheGift": "订单【%s】移除赠品【%s】，数量为【%s】，所选仓库为【%s】",
    "addGiftSToOrderS": "订单【%s】添加赠品【%s】，数量为【%s】，所选仓库为【%s】",
    "theOrderSHasBeenCancelled": "订单【{0}】已取消搁置（还原订单）",
    "ordersmarkedAsPutOnHold": "订单【{0}】标记为“已搁置”",
    "theOrderSIsMarkedAs": "订单【%s】被标记为【问题订单-缺货】，原因：渠道商品【%s】对应的主商品【%s】缺货",
    "orderSIsMarkedAsOut": "订单【%s】被标记为【问题订单-仓库分配错误】，原因：【%s】不支持使用【%s】仓库履约",
    "theOrderSIsAPromotion": "订单【%s】为活动订单，渠道商品【%s】分配至仓库【%s】",
    "theChannelProductNotOutbound": "订单【%s】被标记为【问题订单-MSKU未关联仓库】，渠道商品【%s】无法生成出库单，原因：关联的主商品【%s】不在仓库【%s】中",
    "theChannelProductCantGen": "订单【%s】被标记为【问题订单-未分配仓库】，渠道商品【%s】无法生成出库单，原因：没有对应分仓规则",
    "theChannelProductAllocate": "订单【%s】内渠道商品【%s】，依据分仓规则【%s】分配至仓库【%s】",
    "theChannelProductSInThe": "订单【%s】被标记为【问题订单-未匹配MSKU】，渠道商品【%s】无法生成出库单，原因：未关联主商品",
    "anOutboundListHasBeenCreated": "订单【%s】已创建出库单，单号：<a href=\"/erp/main/outbound-management-detail/%s/SELL\" target=\"_blank\">%s</a>",
    "theOrderSHasBeenSuccessfully": "订单【%s】同步成功，订单状态从【%s】更新为【%s】",
    "syncToTheNewOrderS": "同步到新订单【%s】，订单状态为【%s】",
    "salesTaxAmountMustBeBetween": "销售税金额须在0-1000000000之间",
    "incorrectUnitFormat": "单位格式不正确，仅支持内容为“小时”、“工作日”、“天”、“周”、“月”",
    "purchaseDurationMustBeBetween": "采购时长须在1-365之间",
    "selectedWarehouse": "已选仓库",
    "addingToRepositoryXFailedReason": "添加到仓库“{x}”失败！失败原因：",
    "purchaseDurationUnit": "采购时长单位（仅支持内容为\"hour\", \"working day\", \"day\", \"week\", \"month\"）",
    "thisCustomerHasOrdersInThe": "该客户近60天内有订单，您可以主动发起会话",
    "theProductInboundStockCannotBe": "商品的入库库存不能为负数",
    "theBundledProductHasNotBeen": "子商品没有下发到仓库!",
    "pleaseAddMskuToTheWarehouse": "请添加MSKU到仓库并完成入库",
    "createSalesInvoice": "创建销售发票",
    "beforePushingMatchingCombinedProducts": "组合商品推送&匹配前，需要保证所有被组合商品已完成推送&匹配",
    "theSystemWillAutomaticallyPushSales": "系统会在雅加达时间03:00、11:00、19:00自动将销售发票推送到Accurate。 如果3次推送都失败，则不会再进行推送",
    "ifGineeAndAccurateHaveThe": "目前仅支持将Ginee库存作为初始库存",
    "thePushedMappedMskuSupports": "已推送&匹配的MSKU支持开启同步库存，暂时仅支持Ginee的库存发生变化时自动同步到Accurate",
    "afterSettingTheTaxTypeWill": "设置后，从Ginee创建MSKU到Accurate时会包含税类型",
    "thisMappingCorrespondsToTheService": "该映射对应订单中的Service Fee字段",
    "thisMappingCorrespondsToTheShipBySys": "该映射对应订单中的Shipping Fee Paid by System字段",
    "thisMappingCorrespondsToTheRebate": "该映射对应订单中的Rebate字段",
    "thisMappingCorrespondsToTheCommission": "该映射对应订单中的Commission Fee字段",
    "thisMappingCorrespondsToTheShipReb": "该映射对应订单中的Shipping Fee Rebate字段",
    "thisMappingCorrespondsToTheShipping": "该映射对应订单中的Shipping Fee字段",
    "thePromotionHasExpiredOrThe": "活动已过期或活动类型不正确",
    "invoiceBuyerPhoneNo": "发票买家手机",
    "invoiceType": "发票类型",
    "invoiceAddress": "发票地址",
    "invoiceBuyerEmail": "发票买家邮箱",
    "invoiceBuyerName": "发票买家姓名",
    "upcoming": "未开始",
    "finish": "结束",
    "promoTime": "活动时间",
    "promotionid": "活动ID",
    "webstore": "独立站",
    "orderShasBeenMovedToReturned": "订单【%s】已移入已退货",
    "orderShasBeenMovedToDelivered": "订单【%s】已移入已收货",
    "orderShasBeenMovedToShipping": "订单【%s】已移入发货中",
    "orderShasBeenMovedToTo": "订单【%s】已移入待揽收",
    "orderSisMarkedAsNotPacked": "订单【%s】标记为“未打包”",
    "orderSisMarkedAsPacked": "订单【%s】标记为“已打包”",
    "ordersmarkedPickingStatus": "订单【%s】标记拣货状态",
    "orderSisMarkedAsNotA": "订单【%s】标记为“非刷单”",
    "orderSisMarkedAsFakeOrder": "订单【%s】标记为“刷单”",
    "orderSHasBeenOperatedDelivery": "订单【%s】被操作【下发仓库】",
    "orderSmarkedAsPickingListNot": "订单【%s】标记未打印拣货单",
    "orderSmarkedAsPickingListPrinted": "订单【%s】标记已打印拣货单",
    "orderSmarkedAsPackingListNot": "订单【%s】标记未打印装箱单",
    "orderSmarkedAsPackingListPrinted": "订单【%s】标记已打印装箱单",
    "orderSmarkedAsInvoiceNotPrinted": "订单【%s】标记未打印发票",
    "orderSmarkedAsInvoicePrinted": "订单【%s】标记已打印发票",
    "orderSmarkedAsLabelNotPrinted": "订单【%s】标记未打印快递单",
    "orderSmarkedAsLabelPrinted": "订单【%s】标记已打印快递单",
    "pleaseConfirmTheInstallationOfGinee": "请在Accurate的页面确认安装Ginee，安装后请刷新该页面",
    "allActivities": "全部活动",
    "eventStartTimeAscending": "活动开始时间 升序",
    "eventStartTimeDescending": "活动开始时间 降序",
    "eventEndTimeAscending": "活动结束时间 升序",
    "eventEndTimeDescending": "活动结束时间 降序",
    "sortEventStartTime": "活动开始时间排序",
    "thisOperationOnlyMarksWhetherThe": "该操作仅标记订单是否刷单，标记后不会影响出库单和下发仓库",
    "IsTheHighestPriority": "1为最高优先级，20为最低优先级",
    "optional": "可选",
    "mandatory": "必须",
    "shippingInsurance": "运输保险",
    "xModifiedWarehouseStockOutboundOrder": "{x}修改仓库库存，出库单完成，备注：",
    "xModifiedWarehouseStockCreatedOutbound": "{x}修改仓库库存，创建出库单，备注：",
    "xManuallyReplacesTheReservedWarehouse": "{x}手动更换预留仓库，由仓库{y}更新为仓库{z}",
    "pleaseModifyTheReservedPromotionStock": "请谨慎修改预留活动库存的数量！\n- 减少预留库存将增加可用库存，多渠道售卖时活动库存可能被其他渠道扣减从而导致超卖\n- 修改预留库存只会影响在Ginee预留的活动库存值，不会影响在卖家中心的预留库存",
    "commissionFeeMapping": "平台佣金映射",
    "priorityDelivery": "优先发货",
    "flashSale": "闪购",
    "productPromotion": "商品促销",
    "eventStartTime": "活动开始时间",
    "eventEndTime": "活动结束时间",
    "notPrintable": "不可打印",
    "theFaceSheetCanBePrinted": "快递单可打印",
    "thTrackingNumberNotBeen": "物流单号尚未生成",
    "theOrderStatusCannotPrintThe": "订单状态不可打印快递单",
    "platformFulfillmentOrdersDoNotSupport": "平台履约订单不支持打印快递单",
    "thisChannelDoesNotSupportPrinting": "该渠道不支持打印快递单",
    "theChannelHasNotReturnedThe": "渠道尚未返回物流单号，请耐心等待。如长时间未同步到物流单号请先到卖家中心确认是否已生成，再同步订单重试",
    "theChannelShippingLabelHasNot": "渠道快递单尚未生成，请稍后同步订单重试",
    "syncAll": "同步所有",
    "noShippingLabelToBePrint": "不可打印快递单",
    "promotionEnd": "已结束",
    "handleProblem": "去操作",
    "stockMonitoringAndSynchronizationSettingsAre": "未开启库存监控与同步设置",
    "thereAreUpToFixed": "最多有5个固定置顶产品",
    "afterTurningOffTheAutomaticallyReserve": "关闭自动预留活动库存后，将不会同步且不预留Shopee的活动库存",
    "turnOffTheSwitchToAutomatically": "关闭自动预留活动库存开关",
    "setTheReservationPolicyToUpcoming": "设置预留策略为“未开始及进行中的活动均自动预留”",
    "setTheReservationPolicyToAutoreserve": "设置预留策略为“仅对进行中的活动自动预留”",
    "turnOnTheSwitchToAutomatically": "开启自动预留活动库存开关",
    "processingPromotionOnlySupportModifyThe": "进行中的活动仅支持修改仓库，不支持修改预留库存值",
    "displayingWarehouseWhereTheMskuOf": "展示活动商品MSKU已绑定的仓库，可更换仓库",
    "displayingTheBoundMskuOfThe": "展示活动商品已绑定的MSKU，以及在Ginee预留的活动库存数量",
    "displayTheChannelSkuOfThe": "展示活动商品的平台SKU，以及在平台报名活动成功的活动库存数量",
    "productParticipatedInPromotionButIs": "商品参加活动，但未预留活动库存。已结束的活动数据系统仅会保留30天，超出30天，无法在本列表查看",
    "productParticipatedInPromotionAndOnly": "商品参加活动，仅有部分规格预留了活动库存",
    "productsIncludingAllVariationsAreParticipating": "商品（含所有规格）参加活动，均已预留了活动库存",
    "thisPageCanOnlyViewAnd": "该页面仅可查看和管理2023年6月9日之后创建的活动",
    "channelReturnFailedReasonX": "渠道返回失败，原因：{x}",
    "storeWillExp": "{m}店铺即将过期",
    "storeHasExp": "{m}店铺已过期",
    "big": "大",
    "small": "小",
    "standa": "标准",
    "onlySupportsAdjustingSkuAndQuantity": "仅支持调整SKU和数量的字号大小",
    "fontSiz": "字号：",
    "paperSize": "纸张尺寸",
    "disableHighspeedPrintingForSshop": "关闭【%s】店铺的高速打印",
    "enableHighspeedPrintingForSstore": "开启【%s】店铺的高速打印",
    "disableTheHighSpeedPrintingButton": "关闭【高速打印】开关",
    "enableTheHighSpeedPrintingSwitch": "开启【高速打印】开关",
    "afterDisablingAllStoresWillNot": "关闭后，所有店铺均无法使用高速打印，且批量打印时获取渠道快递单的时间将延长",
    "areYouSureToTurnOff": "确定要关闭高速打印吗？",
    "shopeeWillMarkThePredownloadedOrder": "Shopee/Lazada/Tiktok平台会将预下载的订单标记为已打印，请按需选择是否要开启开关",
    "afterEnablingGineeWillDownloadThe": "开启后，Ginee会提前下载渠道快递单，允许使用高速打印功能，且批量打印时可以大幅减少等待时间",
    "syncToObtainLatestLogisticsInformation": "同步获取最新的物流信息",
    "massSyncToObtainTheLatest": "批量同步获取最新的Tokopedia订单物流信息",
    "syncLogistics": "同步物流",
    "package": "套餐",
    "storeAuthorizedtotalAmount": "店铺(已授权/总量)",
    "subaccountUsedtotalAmount": "子账号(已用/总量)",
    "remainingOrderQuantity": "剩余订单量",
    "remainingMDays": "剩余{m}天",
    "packageRemainingMDays": "套餐剩余{m}天",
    "toEnsureNormalUsePleaseRenew": "套餐到期后，库存同步开关将自动关闭。为保证正常使用，请及时续费",
    "packageRemainingValidityMDays": "套餐有效期剩余{m}天",
    "noReminderToday": "今日不再提醒",
    "theOperationIsTooFrequentPlease": "操作过于频繁，请5分钟后再试",
    "yourCurrentPackageDoesNotSupport": "您当前的套餐不支持授权该渠道，请升级OMS套餐后再进行授权",
    "thisMethodIsOnlyOpenTo": "该方式仅针对指定商家开放，请谨慎设置，更多信息参考{m}",
    "shopeeOfficialInstructions": "Shopee官方说明",
    "actualShippingFee": "实际运费",
    "ifAutomaticallyReserveShopeePromotionStock": "如已启用[自动预留Shopee活动库存]，Shopee活动订单将不按照分仓规则执行，而是按照活动预留的仓库进行库存锁定",
    "afterEnablingGineeWillAutomaticallyPull": "开启开关后，Ginee将自动拉取Tokopedia订单的BookingCode",
    "turnOffTheAutomaticallyPullLogistics": "关闭【自动拉取物流信息】开关",
    "turnOnTheAutomaticallyPullLogistics": "开启【自动拉取物流信息】开关",
    "automaticallyPullLogisticsInformation": "自动拉取物流信息",
    "disabled1": "未开启",
    "enabled": "已开启",
    "newlyAuthorizedStoresAreAutomaticallySet": "新授权的店铺自动设置为“开启”",
    "confirmToReauthorize": "确定重新授权",
    "noteTiktokReauthorizationDoesNotExtend": "注意：TikTok的重新授权不会延长有效期。如需延长授权有效期，请先去TikTok卖家中心解绑Ginee，然后进行重新授权",
    "sellerFullAddress": "卖家完整地址",
    "thereIsNoOrderThatCan": "没有可以高速打印的订单，请确认订单所在店铺已开启【高速打印】",
    "startSynchronizingLatestOrderLogisticsInformation": "开始同步最新的订单物流信息，预计需要几分钟，请稍后刷新页面查看最新的物流信息",
    "video": "视频",
    "tryHighlightingCoreSellingPoints": "尝试突出显示您产品的 1-2 个核心卖点。 这将显示在产品详细信息部分。",
    "weRecommendTheVideoDurationIs": "我们建议视频时长在20秒以内，最长不要超过60秒。",
    "recommendedRatioIsAndRecommended": "建议比例为 1:1，建议分辨率为 HD 720p 以上。",
    "ifYouUploadAVideoIt": "您上传视频的宽高比应在 9:16 到 16:9 之间。 文件大小不能超过30MB，格式为MP4。",
    "tiktokRequired": "Tiktok的要求：",
    "changingCategoryWillNotAffectYour": "更改类别不会影响您的销售业绩、产品评分等产品信息，但会清除产品属性。 您确定要更改它吗？",
    "basedOnTheTitleYouEntered": "根据您输入的标题，产品类别可能是（请根据需要选择）",
    "economical": "经济型",
    "cargo": "物流",
    "standardShipping": "标准运输",
    "pleaseInputTheValueOfWeight": "请输入大于5.00kg的重量值",
    "deliveryOptions": "运输方式",
    "lineShoppingOnlySupportsSettingThe": "Line Shopping仅支持对所有规格统一设置立减金额",
    "compulsoryOutbound": "该出库单已推送到履约仓，请在履约仓进行出库。如果继续操作将仅强制出库在OMS的出库单，可能会导致和履约仓实际出库数据不一致。确定要继续吗？",
    "compulsoryCancel": "该出库单已推送到履约仓，请在履约仓进行作废。如果继续操作将仅强制作废在OMS的出库单，可能会导致和履约仓实际出库数据不一致。确定要继续吗？",
    "noPermission": "您没有权限访问该页面，请升级套餐后刷新页面重试。员工账号请联系管理页添加角色权限",
    "refuseToAddWms": "您没有权限添加该仓库，请联系您的仓库服务商为您添加货主账号并授权该仓库",
    "theProductDescriptionShouldGiveThe": "产品描述应向客户提供有关产品的有用信息，以确保购买",
    "missingOrderInformation": "订单信息缺失",
    "compartmentDimensionInShippingWarehouseSetting": "分仓设置的分仓维度由{1}调整为{2}",
    "priorityOfWarehouseAlocationInShipping": "分仓设置的分仓优先级由{1}调整为{2}",
    "warehouseInRuleChangedFrom": "规则{0}仓库由{1}调整为{2}",
    "startPullingTheOrderDataIt": "开始拉取订单数据, 预计需要花费几分钟, 请稍后再关注店铺数据同步情况",
    "theRecipientsInformationCanBeDisplayed": "可在快递单以文本方式展示收件人信息，但收件人信息可能脱敏展示",
    "theCompleteRecipientInformationCanBe": "可在快递单以图片方式展示完整收件人信息，但部分打印机可能无法识别图片",
    "customShipmentWarning": "自定义发货警示语",
    "thisSystemWarehouseHasBeenBound": "该系统仓库已被其他渠道仓库绑定！",
    "completeRecipientInformationCanBe": "图片方式展示收件人信息",
    "recipientsInformationCanBeDisplayed": "以文本方式展示收件人信息",
    "instantDiscountAmount": "立减金额",
    "originalPriceShouldBeHigherThan": "原价应高于立减金额",
    "YourOmsWillBeUpgraded": "1. 您的OMS将升级为全功能版本，可以授权多渠道店铺、处理订单、管理库存；",
    "YouWillAutomaticallyBindThe": "2. 您将自动绑定服务商的仓库，可将订单推送至服务商仓库、由服务商来进行订单履约；",
    "theFulfillmentServiceProviderXInvites": "履约服务商{x}邀请您开通履约仓库{y}",
    "afterAgreeing": "同意后：",
    "areYouSureYouAgreeTo": "确认要同意开通吗？",
    "confirmActivation": "确认开通",
    "youHaveNewApplication": "您有{m}个新申请",
    "thisChannelCanBeAuthorizedAfter": "升级套餐后可授权该渠道",
    "theCurrentPackageDoesNotSupport": "当前的套餐不支持授权该渠道",
    "enableFailedYouCanOnlyAuthorize": "启用失败！该店铺超过套餐资源限制",
    "afterUpgradingThePackageMoreMaster": "升级套餐后可添加更多的主商品",
    "addFailedMasterProducts": "添加失败！主商品数量已到达套餐上限",
    "masterProductQuantityHasReachedThe": "主商品数量已到达套餐上限",
    "additionalXConsignorAccountsbusiness": "额外5个货主账号",
    "additionalXGineeWmsOrders": "额外1万个WMS订单量",
    "additionalXStores": "额外10个店铺",
    "additionalXStaffAccounts": "额外5个员工账号",
    "additionalXGineeOmsOrders": "额外1万个OMS订单量",
    "addons": "增值",
    "offlineOnboardingOn": "上门指导（1对1）",
    "accountManagerOn": "客户经理（1对1）",
    "onlineTraining": "线上培训",
    "liveChatSupport": "在线客服",
    "implementationAndCustomerCare": "客户服务与实施",
    "XConsignorAccountsBusiness": "10个货主账号（Business套餐）",
    "servicesToExternalBrands": "服务外部品牌商（货主）",
    "serviceProviderManagement": "服务商管理系统",
    "gineeWmsOpenApi": "Ginee WMS API集成",
    "pdaapp": "PDA/APP",
    "fifofefo": "先进先出/先过期先出",
    "smartReplenishment": "智能补货",
    "stocklocationTransfer": "移库/调拨",
    "waveShipping": "波次发货",
    "gineeWmsWarehouseManagementSystem": "Ginee WMS（仓储管理系统）",
    "gineeOmsOpenApi": "Ginee OMS API集成",
    "theThirdPartyIntegrationposLogisticsFulfillment": "第三方系统集成（收单、物流、履约、OMS、财务）",
    "multiwarehouseManagement": "多仓管理",
    "reporting": "报表管理",
    "stockManagement": "库存管理",
    "gineeOmsOrderManagementSystem": "Ginee OMS（订单管理系统）",
    "noOfSalesChannels": "渠道数量",
    "noOfMskus": "MSKU数量",
    "noOfStores": "店铺数量",
    "noOfStaffAccounts": "员工数量",
    "noOfCountriesinternational": "支持的国家（国际）",
    "noOfGineeWmsOrders": "Ginee WMS 订单量",
    "noOfGineeOmsOrders": "Ginee OMS 订单量",
    "ecommerceOperations": "电商运营",
    "buyItNow": "立即购买",
    "clickMeToGetDiscount": "获取优惠",
    "customization": "定制化",
    "youHaveMNewMessages": "您有{m}条新消息",
    "officialAccounts": "公众号",
    "theThirdPartyIntegration": "第三方系统集成",
    "thereAreUnrecognizableCharactersSuchAs": "有无法识别的字符，请修改",
    "currentlyDoNotSupportIntegratingCnsc": "暂不支持授权CNSC/3PF店铺",
    "manuallyRepushThePushFailedData": "手动重新推送“推送失败”的数据",
    "paymentTerms": "付款期限",
    "bankAccount": "银行账号",
    "issuingBank": "开户行",
    "taxIdNumber": "税号",
    "contactPerson": "联系人",
    "website": "网址",
    "onlyLettersAndNumbersAreSupported": "仅支持字母、数字和“- _ . /”，请重新输入",
    "theContentYouEnteredContainsIllegal": "您输入的内容含有不合法的字符，请重新输入",
    "autoGenerate": "自动生成",
    "supplierName": "供应商名称",
    "supplierCode": "供应商编码",
    "createNewSupplier": "新增供应商",
    "importSupplier": "导入供应商",
    "supplierList": "供应商列表",
    "purchaseManagement": "采购管理",
    "supplierManagement": "供应商管理",
    "purchase": "采购",
    "disableenable": "停用/启用",
    "supplierCodeDoesNotExist": "供应商编码不存在",
    "supplierNameAlreadyExists": "供应商名称已存在",
    "supplierCodeAlreadyExists": "供应商编码已存在",
    "failedToDeleteTheSupplierAlready": "删除失败！供应商有采购订单或非停用状态",
    "onlySuppliersWithDisabledStatusAnd": "只有停用状态且没有采购订单的供应商才可以删除",
    "wantToDeleteTheSupplier": "确定要删除供应商吗？",
    "wantToEnableTheSupplier": "确定要启用供应商吗？",
    "failedToDisableThisSupplierHas": "停用失败！该供应商有未结清的采购订单",
    "suppliersWithUnfinishedPurchaseOrdersCannot": "有未结清采购订单的供应商将无法停用，请谨慎操作",
    "wantToDisableTheSupplier": "确定要停用供应商吗？",
    "thisTypeHasBeenSelectedBy": "有供应商已选择该类型，无法删除",
    "wantToDeleteTheSupplierType": "确认删除供应商类型？",
    "typeName": "类型名称",
    "addNewType": "新增类型",
    "fullPayment": "全额付款",
    "supplierCodeCannotBeEditedAfter": "供应商编码创建后将不允许修改",
    "xAlreadyExists": "{x}已存在",
    "pleaseFillInThisField": "请填写该字段",
    "theAmountMustBeBetweenXy": "金额必须在{x}~{y}之间，允许2位小数",
    "amountMustBeBetweenXyAnd": "金额必须在{x}~{y}之间，且不允许输入小数",
    "partialArrival": "部分到货",
    "inTransit": "在途",
    "paymentStatus": "付款状态",
    "purchaseOrder": "采购订单",
    "paymentMethod": "付款方式",
    "paid": "已付款",
    "partialPayment": "部分付款",
    "unpaid": "未付款",
    "feeInformation": "费用信息",
    "deliveredQuantity": "到货数量",
    "estimatedDateOfArrival": "预计到货日期",
    "purchasingStaff": "采购员",
    "receivingWarehouse": "接收仓库",
    "puchaseDate": "采购日期",
    "purchaseOrderSource": "采购订单来源",
    "noSupplier": "无供应商",
    "purchaseOrderNumberAlreadyExists": "采购订单编号已存在",
    "purchaseOrderNumberCannotBeEdited": "采购订单编号创建后将不允许修改",
    "pleaseEnterPurchaseOrderNumber": "请输入采购订单编号",
    "purchaseOrderNumber": "采购订单编号",
    "basicInfoma": "基础信息",
    "otherNoncash": "其他非现金",
    "virtualAccount": "虚拟账号",
    "paymentLink": "支付链接",
    "qris": "扫码支付",
    "edc": "刷卡支付",
    "bankTransfer": "银行转账",
    "chequeclearing": "支票支付",
    "cash": "现金",
    "discou": "折扣",
    "productPurchasePrice": "商品采购价",
    "purchaseOrderTotalPrice": "采购订单总价",
    "skuQuantity": "SKU数量",
    "totalPriceInformation": "总价信息",
    "inboundCostPrice": "入库成本",
    "individualCostSharing": "单个分摊费用",
    "taxRate": "税率",
    "onlyNumbersFromTo": "仅允许输入{x}~{y}的数字，不支持小数",
    "discountRate": "折扣率",
    "purcPrice": "采购价",
    "quantityPcs": "数量(pcs)",
    "unit": "单位",
    "volumeM": "体积(cm³)",
    "productPriceIncludesTax": "商品价格含税",
    "massAddd": "批量添加",
    "addProdu": "添加商品",
    "dividedByQuantity": "按数量分摊",
    "dividedByVolume": "按体积分摊",
    "dividedByWeight": "按重量分摊",
    "dividedByPrice": "按价格分摊",
    "notDivided": "不分摊",
    "fillInmodifyTrackingNumber": "填写/修改快递单号",
    "purchaseNote": "采购备注",
    "thePurchaseOrderWarehouseIsWmsthirdparty": "该采购订单入库仓库为WMS/三方仓，请先通知履约仓库取消采购入库单后再操作",
    "failedToCancelThePurchaseOrder": "取消采购订单失败",
    "cancellationFailedInboundProductsInThis": "取消失败！该采购订单中的商品已入库，请刷新页面重新查看采购订单状态",
    "whenAPurchaseOrderIsCancelled": "采购订单取消时会同时作废采购入库单并释放在途库存",
    "wantToCancelThePurchaseOrder": "确定要取消采购订单吗？",
    "wantToDeleteThePurchaseOrder": "确定要删除采购订单吗？",
    "IfTheReceivingWarehouseIs": "2. 如果接收仓库为三方仓，Ginee将自动推送采购入库单到履约仓库",
    "AfterSubmissionThePurchaseOrder": "1. 提交后采购订单将变为在途状态，同时生成采购入库单、商品将增加在途库存",
    "youWantToSubmitThePurchase": "确定要提交采购订单吗？",
    "identify1": "识别",
    "copyMskuFromExcelAndPaste": "从Excel复制MSKU并粘贴在这里，多个MSKU请使用回车换行，每行代表一个信息",
    "EstimatedTimeOfArrivalFormat": "6. 预计到货时间格式：年/月/日 （例如：2023/08/23）",
    "WhenTheReceivingWarehouseIs": "3. 接收仓库为三方仓时，若MSKU未推送三方仓，请先到库存清单页面添加SKU到三方仓库；",
    "ExplanationOfTheShippingFeeother": "4. 运费/其他费用分摊方式说明：选择“价格”时按各SKU的价格分摊，选择“数量”则按各SKU的采购数量分摊，选择“体积”时按各SKU的体积分摊，选择“重量”时按各SKU的重量分摊，不填写运费/其他费用时默认不分摊；",
    "purchaseOrderContainsMultipleProductsYou": "3. 一个采购订单包含多个商品时，每条数据均需要完整填写商品信息；公共信息（基础信息、费用信息、其他信息）只需要填写第一条数据；多条数据的公共信息不一致时，将以第一条数据为准；",
    "WhenAPurchaseOrderContains": "2. 一个采购订单包含多个商品时，请使用相同的采购订单编号，导入时相同采购订单编号的多行数据会合并为同一个采购订单；",
    "exportPurchaseOrder": "导入采购订单",
    "printPurchaseOrder": "打印采购订单",
    "changePaymentStatus": "修改付款状态",
    "signature": "签名",
    "merchantWarehouseAddress": "商家仓库地址",
    "contactInformation": "联系方式",
    "supplierAddress": "供应商地址",
    "supplier": "供应商",
    "supplierInformation": "供应商信息",
    "merchantInformation": "商家信息",
    "explanation": "说明：",
    "UpToDataCan": "1. 每次最多导入10,000条数据（格式：xlsx，xls）；",
    "estimatedTimeOfArrival": "预计到货时间",
    "warehouseType": "仓库类型",
    "theEntireInboundWillBeInvalidated": "该笔入库单将整体作废并释放在途库存",
    "completeInbound": "结束入库",
    "partialInbound": "部分入库",
    "afterInvalidationIsInitiatedOnlyThe": "发起作废后仅会将OMS入库单进行作废，履约仓的入库单状态不会变化",
    "onlyTheErpInboundIsInvalided": "仅作废OMS的入库单",
    "afterTheInvalidationIsInitiatedThe": "发起作废后会通知履约仓作废，履约仓作废成功后OMS状态将变为作废",
    "invalidErpAndFulfillmentInbound": "作废OMS和履约仓的入库单",
    "invalidationMethod": "作废方式：",
    "theSkuWillBecameInvalidAnd": "该SKU将作废并释放在途库存",
    "pleaseSelectTheInvalidationMethod": "请选择作废方式",
    "inboundFullAmount": "全量入库",
    "presentInboundQuantity": "本次入库数量",
    "pendingInboundinboundCompletedplannedInbound": "待入库/已入库/计划入库",
    "afterTheInboundIsCompletedThe": "结束入库后，该入库单将变为“已入库”状态且无法再继续入库",
    "sureYouWantToEndThe": "确定要结束入库吗？",
    "pushResult": "店铺更新结果",
    "receiveItem": "收货单",
    "afterDisablingThePrintedPurchaseOrder": "关闭后，打印采购订单不展示商品金额以及费用（运费/其他费用/总金额）",
    "amountDisplay": "金额展示",
    "purchaseOrderPrintingSettings": "采购订单打印设置",
    "purchaseSetting": "采购设置",
    "receivingTime": "接收时间",
    "inboundTotalAmount": "入库总数量",
    "receiveItemNumber": "收货编号",
    "supplierTypeAlreadyExists": "供应商类型已存在",
    "supplierType": "供应商类型",
    "supplierTypeDoesNotExist": "供应商类型不存在",
    "theDataIsAbnormalAndThe": "数据异常，导出任务不存在",
    "xParameterIsIncorrect": "{x}参数不正确",
    "xParameterCannotBeEmpty": "{x}参数不能为空",
    "thePurchaseOrderAlreadyExists": "采购订单已存在",
    "purchaseOrderDoesNotExist": "采购订单不存在",
    "failedTheWarehouseTypeWasNot": "失败，未获取到仓库类型",
    "purchaseOrderCancellationFailed": "采购订单取消失败",
    "purchaseOrderDeletionFailed": "采购订单删除失败",
    "purchaseOrderSubmissionFailed": "采购订单提交失败",
    "purchaseOrderIsMissingProductInformation": "采购订单缺少商品信息",
    "purchaseOrderIsNotInDraft": "采购订单不是“草稿”状态",
    "purchaseOrderCancellationFailedPleaseRefresh": "采购订单取消失败，请刷新页面重试",
    "purchaseOrderDeletionFailedPleaseRefresh": "采购订单删除失败，请刷新页面重试",
    "purchaseOrderSubmissionFailedPleaseRefresh": "采购订单提交失败，请刷新页面重试",
    "theSupplierDoesNotExists": "供应商不存在",
    "supplierAlreadyExists": "供应商已存在",
    "theImportedDataIsEmpty": "导入数据为空",
    "theImportedFileFormatIsNot": "导入的文件格式不支持",
    "theOperationTaskDoesNotExist": "操作任务不存在",
    "theImportTaskDoesNotExist": "导入任务不存在",
    "theExportTaskDoesNotExist": "导出任务不存在",
    "createAndPushSalesInvoicesWhen": "订单完成出库时创建并推送销售发票",
    "inboundAmount": "入库金额",
    "goodsReceivedNoteNumber": "收货单编号",
    "quotationGeneratedSuccessfully": "报价单生成成功！",
    "theChannelWarehouseIsNotAssociated": "渠道仓库未关联Ginee仓库",
    "multiWarehouseChannelProductsAreNot": "多仓渠道商品未关联仓库",
    "doesNotComplyWithTheRules": "不符合规则：已连接的全部店铺，不包含后续授权的店铺",
    "matchingDefaultPushRulesAreDisabled": "匹配的默认推送规则被禁用",
    "matchingStorePushRulesAreDisabled": "匹配的店铺推送规则被禁用",
    "matchingCustomPushRulesAreDisabled": "匹配的自定义推送规则被禁用",
    "shopifyProductInventoryIdDoesNot": "Shopify商品库存ID不存在，不允许更新库存",
    "blibliProductsAreDisabledAndStock": "Blibli商品为禁用状态，不允许更新库存",
    "returnFromTikiChannelForThis": "该商品Tiki渠道返回：不允许更新",
    "theStoreHasBeenDisconnected": "店铺已断开",
    "tikiProductInboundStatusDoesNot": "Tiki商品入库状态不允许推送库存",
    "masterProductNotFound": "未找到主商品",
    "thePushTaskAlreadyExistsAnd": "推送任务已存在，不需要重新推送",
    "failedAlreadyRepushed": "失败，已重新推送",
    "pleaseSelectTheReceivingWarehouseFirst": "请先选择接收仓库",
    "ifNotFilledInItWill": "不填写将自动生成",
    "region": "县/区",
    "cityNew": "市",
    "provinc": "省",
    "districtsNew": "城镇",
    "purchaseQuantity": "采购数量",
    "otherCostAllocationMethodsPleaseSelect": "其他费用分摊方式(请选择)",
    "otherCostTotal": "其他费用(总)",
    "shippingFeeAllocationMethodPleaseSelect": "运费分摊方式(请选择)",
    "shippingFeeTotal": "运费(总)",
    "wantToUnbindTheWarehouse": "确定解绑仓库吗？",
    "afterUnbindingChangesInStockIn": "解绑后，系统仓库的库存有变化时将不会推送到该渠道仓库",
    "unbindTheChannelWarehouseAaaFrom": "渠道仓库{AAA}与系统仓库解除绑定",
    "thisFeatureIsOnlyAvailableFor": "此功能仅对Tokopedia的Official Store有效",
    "deleteSupplier": "删除供应商",
    "enableSupplier": "启用供应商",
    "disableSupplier": "停用供应商",
    "editSupplier": "编辑供应商",
    "manualDeleteSupplierX": "手动删除供应商 [{x}]",
    "enableSupplierX": "启用供应商 [{x}]",
    "disableSupplierX": "停用供应商 [{x}]",
    "importToUpdateSupplierX": "导入更新供应商 [{x}]",
    "manualEditSupplierX": "手动编辑供应商 [{x}]",
    "importToAddSupplierX": "导入新增供应商 [{x}]",
    "manualAddSupplierX": "手动新增供应商 [{x}]",
    "exportSupplierList": "供应商列表导出",
    "exportPurchaseOrderX": "导出采购订单 [{x}]",
    "printPurchaseOrderX": "打印采购订单 [{x}]",
    "cancelPurchaseOrderX": "取消采购订单 [{x}]",
    "deletePurchaseOrderX": "删除采购订单 [{x}]",
    "submitPurchaseOrderX": "提交采购订单 [{x}]",
    "editPurchaseOrderXManually": "手动编辑采购订单 [{x}]",
    "importPurchaseOrderX": "导入新增采购订单 [{x}]",
    "addPurchaseOrderXManually": "手动新增采购订单 [{x}]",
    "exportPurchaseO": "导出采购订单",
    "cancelPurchaseOrder": "取消采购订单",
    "deletePurchaseOrder": "删除采购订单",
    "submitPurchaseOrder": "提交采购订单",
    "editPurchaseOrder": "编辑采购订单",
    "addPurchaseOrder": "新增采购订单",
    "shopeeRequirements": "Shopee的要求：",
    "formatMp": "格式：MP4",
    "durationSs": "持续时间：10秒-60秒",
    "sizeMaxMbResolutionShouldNot": "大小：最大30Mb，分辨率不应超过1280x1280px",
    "myShopeeSellerCenterCanInsert": "只有部分Shopee店铺支持图文描述，请确认您的店铺已支持图文描述后再开启该选项",
    "copyCopy": "复制",
    "thankYouForYourCooperationPlease": "谢谢您的合作,请复制下面的唯一ID,并将其发送给Ginee支持团队进行问题分析。",
    "weNeedYourConsentToRecord": "我们需要您的许可来记录您的屏幕并捕获操作过程,以便识别和解决遇到的问题。",
    "weOnlyRecordThePagesIn": "我们仅录制Ginee系统的操作界面。",
    "needAuthorization": "需要授权",
    "approveStartToRecord": "允许并开始录制屏幕",
    "done": "操作流程已捕获",
    "repeatTheOperationStepsToReproduce": "重复问题发生前所执行的步骤,捕获到发生的问题后停止录制。",
    "capturingTheProcessingFlowOnThe": "在屏幕上捕获操作过程",
    "stop": "停止录制",
    "uploading": "上传中",
    "importToUpdateSupplier": "导入更新供应商",
    "importToAddSupplier": "导入新增供应商",
    "feedback": "问题反馈",
    "uploadVideo": "上传视频",
    "wereNotPerfectButWereListening": "Ginee 不是完美的，我们渴望您的建议",
    "feedbackForm": "工单反馈",
    "pleaseSubmitATicektFormWith": "请通过文字和图片附件来描述您的问题来提交工单反馈",
    "feedbackVideo": "视频反馈",
    "pleaseGrantGineeThePermissionRecord": "- 请授予 Ginee 权限，用于记录您的操作过程并进行诊断",
    "enterTheSpecificPageStartTo": "- 进入相应页面，开启录制记录并复现导致问题的步骤",
    "stopTheRecordCopyTheUnique": "- 停止录制后复制生成的唯一 ID 发送给我们",
    "faqfeedback": "帮助/反馈",
    "needHelp": "需要帮助？",
    "detailedDescription": "详细说明",
    "trackingNumber": "运单号",
    "enableLongDescription": "开启图文描述",
    "DetailProductDescription": "商品描述",
    "dueToApiLimitationsBlibliMust": "由于API限制，Blibli必须使用高速打印提前下载快递单，请到订单设置-高速打印开启Blibli店铺的高速打印后重试",
    "abnormal": "异常",
    "theInformationCannotBeFoundIn": "无法在Ginee找到该信息，请确认该字段内容是否已经与Ginee进行了匹配，或登录Accurate后台修改信息后再重新同步",
    "purchasePurchaseOrder": "采购 / 采购订单",
    "addToList": "添加到列表",
    "modifyingAccuratePurchaseOrderInformationIn": "在Ginee修改Accurate采购订单信息仅会更新Ginee的数据，不会同步到Accurate；非草稿状态在Accurate修改采购订单不会同步到Ginee",
    "pleaseSelectProduct": "请选择商品",
    "gineeOnlySupportsCreatingPurchaseOrder": "Ginee仅支持采购多个商品到同一个仓库，请登录Accurate并将所有商品修改为相同的入库仓库",
    "supplierContactDetails": "供应商联系方式",
    "operationFailed": "操作失败",
    "whenSelectingMultipleShopeeStoresThe": "选择多个Shopee店铺时，仅支持将库存值更新到默认仓库；如需将库存更新到Shopee多个仓库，请只选择1个店铺",
    "requestTimedOutPleaseCheckThe": "请求超时，请检查网络后重试",
    "manageMultiwarehouseStock": "管理多仓库存",
    "supplierCodeSupplierCodeCannotBe": "供应商编码（供应商编码创建后不可编辑）",
    "hasReturnrefund": "有退货/退款",
    "batchEditingOnlySupportsModifyingA": "批量编辑仅支持修改单个仓库库存。如需修改多仓库库存，请使用单个商品编辑",
    "invalidationIsInitiatedOnlyTheOms": "发起作废后仅会将OMS出库单进行作废，履约仓的出库单状态不会变化",
    "onlyTheOmsOutboundIsInvalided": "仅作废OMS的出库单",
    "invalidateOmsAndFulfillmentOutbound": "作废OMS和履约仓的出库单",
    "installationTutorial": "安装教程",
    "howToUse": "使用教程",
    "downloadAndInstallThePlugin": "下载并安装插件",
    "goToChromeWebStoreTo": "前往谷歌商店安装",
    "ForPluginsHowToUse": "4、关于如何使用插件，请参考：",
    "IfYouCannotAccessThe": "3、无法访问谷歌商店时，在此下载插件：",
    "PluginInstallationLink": "2、插件安装地址：",
    "RecommendedBrowserGoogleChrome": "1、推荐浏览器：Google Chrome",
    "pleaseDownloadAndUseTheScrape": "请下载使用采集插件，功能稳定又快速！",
    "editAndPublishFromTheStore": "在店铺商品草稿箱进行编辑和发布",
    "EditAndPublish": "4.编辑并发布",
    "selectAStoreAndCopyThe": "选择店铺并将主商品克隆到店铺草稿箱",
    "CopyAndSellInStores": "3.克隆到店铺售卖",
    "massClaimAndCreateMasterProduct": "批量认领并创建主商品",
    "ClaimTheMasterProduct": "2.认领到主商品",
    "visitTheProductPageYouWant": "访问要采集的商品页面，使用插件一键采集",
    "ScrapeProduct": "1.采集商品",
    "returnrefund": "退货/退款",
    "editWarehouseStockInStockList": "在库存清单编辑仓库库存",
    "importToUpdateStockIncreasedecrease": "导入更新库存-增/减值",
    "importToUpdateStockFinal": "导入更新库存-最终值",
    "purchaseOrdersSourcedFromAccurateDo": "来源为Accurate的采购订单不支持在Ginee取消，如需取消请登录Accurate进行处理",
    "failedToCreateOmsInboundThe": "创建OMS入库单失败：WMS入库单中的SKU在OMS不存在或者是组合品",
    "IfYouHaveDoneThe": "5.如果以上操作都已尝试,请联系官方客服",
    "IfEverythingIsNormalPlease": "4.如果一切正常, 请1分钟后重试",
    "PleaseSwitchTheNetworkAnd": "3.请切换网络后重试",
    "PleaseConfirmThatTheBrowser": "2.请确认浏览器没有特殊设置或更换浏览器后重试",
    "PleaseCheckWhetherYouAre": "1.请检查是否使用了第三方插件如广告/域名拦截等，建议关闭插件后重试",
    "inComplianceWithShopeePolicyBuyer": "店铺授权后，Ginee不主动同步历史订单，如需查看历史订单可点击[订单同步]按钮，按需同步历史订单。应Shopee平台政策要求，超过90天的历史订单将不再展示客户信息，敬请谅解",
    "inComplianceWithShopeePolicyThe": "应Shopee平台政策要求，客户列表将不提供Shopee平台订单数据",
    "massAddHistory": "批量添加历史",
    "cancellation": "取消",
    "failedDelivery": "配送失败",
    "ordersHasBeenMovedToReturnrefund": "订单[%s]已移入退货/退款",
    "moveToReturnrefund": "移入退货/退款",
    "ordersHasBeenMovedToCompleted": "订单[%s]已移入“已完成”",
    "moveToCompleted": "移入已完成",
    "youWantToMoveTheOrder": "您确定要将订单移入“已完成”吗？",
    "confirmToSync": "确认进行同步？",
    "purchaseSupplierManagement": "采购和供应商管理",
    "afterClosingAllStoresWillNot": "关闭后，所有店铺都将不会同步且不预留Shopee的活动库存",
    "wantToDisableAutomaticallyReservePromotion": "确定要关闭自动预留活动库存吗？",
    "pushingPleaseRefreshThePageLater": "正在推送，请稍后刷新页面查看最新推送状态",
    "wantToMassDisableAutoPush": "确定要批量关闭自动推送吗？",
    "wantToMassEnableAutoPush": "确定要批量开启自动推送吗？",
    "onlyGineeWmsWarehouseSupportsEditing": "只有Ginee WMS仓库才支持修改推送策略",
    "editPushStrategy": "修改推送策略",
    "disableAutoPush": "关闭自动推送",
    "enableAutoPush": "开启自动推送",
    "autoPush": "自动推送",
    "pushStrategy": "推送策略",
    "theOrderWillBePushedTo": "按照配置条件将订单提前推送到三方仓，其他订单仍执行标准推送，问题订单将不会进行推送",
    "regardlessOfWhetherTheOrderIs": "不管订单是否具备发货条件（未安排发货、无物流单号、无物流面单），所有同步下来状态为“已支付”、“待发货”的订单生成出库单后将立即推送到三方仓，问题订单将不会进行推送",
    "theDefaultPushRuleIsThat": "默认推送规则，只有已获取物流面单的订单才允许推送到三方仓，问题订单不会进行推送",
    "strategyDescription": "策略说明",
    "deleteFailedThereIsAWarehouse": "删除失败！有正在使用的仓库！",
    "onlyStrategiesThatAreNotAssociated": "只有未关联仓库的策略才可以删除",
    "wantToDeleteTheStrategy": "确定要删除策略吗？",
    "allowEarlyPushWhenTheOrder": "当订单状态为“已支付”时就允许提前推送",
    "pleaseSetAtLeastOneAdvance": "请至少设置一个提前推送条件，只有订单符合选中条件时才会触发提前推送，否则将执行标准推送",
    "upToCustomStrategiesCan": "最多可添加100个自定义策略",
    "warehouseUsed": "已使用仓库",
    "pushPaidOrdersInAdvance": "提前推送“已支付”订单",
    "strategyName": "策略名称",
    "manageCustomPolicies": "管理自定义策略",
    "customAdvancePush": "自定义提前推送",
    "pushAllInAdvance": "全部提前推送",
    "basicPush": "标准推送",
    "normalPushPushAllEarlyCustom": "可设置标准推送、全部提前推送、按指定条件自定义提前推送",
    "thirdpartyWarehousePushSettings": "推送三方仓设置",
    "quantityLimitInfo": "购买量不能超过1000",
    "valueShouldBeAtLeastThe": "最小不低于 {count}",
    "purchaseLimit": "购买限制",
    "maxPerOrder": "每笔订单最多",
    "storesAreAutomaticallySetToEnable": "【新授权的店铺自动设置为“开启”】已禁用",
    "authorizedStoresAreAutomaticallySetTo": "【新授权的店铺自动设置为“开启”】已启用",
    "storeXDisableTheAutomaticallyReserve": "店铺{x}关闭自动预留活动库存开关",
    "storeXEnableTheAutomaticallyReserve": "店铺{x}开启自动预留活动库存开关",
    "disableAutomaticallyReservePromotionStockMain": "关闭自动预留活动库存总开关",
    "enableAutomaticallyReservePromotionStockMain": "开启自动预留活动库存总开关",
    "enabledisableAutomaticallyReservePromotionStock": "开启/关闭自动预留活动库存",
    "change": "修改",
    "superLinkAsAnOpenDoor": "超级链接：连接您所有业务店铺和内容的开放之门！",
    "actualInboundQty": "实际入库数量",
    "plannedInboundQty": "计划入库数量",
    "tipShopeePendingWaitingForShopee": "提示：Shopee Pending：等待Shopee提供物流信息或Shopee仍在进行欺诈检查，请稍后手动同步订单获取最新状态",
    "shopeeOrdersAreCurrentlyInPending": "Shopee订单目前在Pending状态，暂不支持获取面单，请稍后再试",
    "gineeHasAutomaticallyFilteredXShopee": "Ginee已经自动过滤了状态仍为Pending不允许发货的{x}个Shopee订单",
    "Template": "模版",
    "promotionname": "促销名称",
    "EndTimeLeastDay": "结束时间必须晚于开始时间至少1天",
    "theEndTimeMustBeLater": "结束时间必须不小于开始时间",
    "TokoStartMinutesTips": "开始时间大于当前时间至少5分钟",
    "startTimeMustBeAtLeast": "活动持续时间不超于180天",
    "OnceTheDiscountPromotion": "折扣促销保存成功后，活动时间只能缩短",
    "theEndTimeMustBeGreater": "结束时间必须晚于开始时间至少1小时",
    "EntitledProducts": "促销商品",
    "RemoveProducts": "移除商品",
    "removeSelectedProducts": "移除选中商品",
    "RemoveAllProducts": "移除全部商品",
    "promotionDetails": "促销详情",
    "UpdateAllProducts": "更新至全部商品",
    "UpdateSelectedProducts": "更新至选中商品",
    "SetDiscountAt": "设置折扣为",
    "stockMonitorAndSyncIsNot": "库存监控与同步功能未启用",
    "inboundHistory": "入库记录",
    "inboundDetails": "入库详情",
    "currentlyDoesNotSupportUploadingeditingLazada": "Lazada视频暂时不支持上传/编辑",
    "noteYouCanPublishThisListing": "注意：您可以在视频审核时发布此商品。Shopee审核成功后，视频将显示在商品中",
    "pageNumber": "条/页",
    "rejectionReason": "拒绝原因",
    "theShippingLabelIsEmptyPush": "面单为空，推送失败",
    "theTrackingNumberIsEmptyPush": "物流单号为空，推送失败",
    "doesntMeetTheOrderStatusRequirement": "订单状态不满足，推送失败",
    "problemOrderStoredOrderPush": "问题订单&搁置订单，推送失败",
    "reminderOnceTheStoreStockIs": "提醒：店铺库存一旦推送成功将无法恢复，请确保每个MSKU已关联仓库并设置了正确的库存",
    "whenANewOrderIsPlaced": "当MSKU产生新的订单或订单被取消时，所有绑定该MSKU的渠道商品的库存会统一扣减或增加",
    "theStockOfAllChannelProducts": "所有绑定同一MSKU的渠道商品的库存将根据“{x}”立刻进行一次库存推送",
    "afterTurningOnStockMonitorAnd": "开启库存同步后：",
    "addingeditingProductsOrStockInVivo": "不支持新增/修改Vivo仓库的商品或库存，如需修改请登录Vivo系统",
    "beforeOutboundItHasBeenVerified": "出库前校验Vivo仓库库存不足",
    "areYouSureToEnableStock": "确认开启库存同步吗？",
    "areYouSureToDisableStock": "确认关闭库存同步吗？",
    "plannedInboundCost": "入库计划成本",
    "onlyTheGineeWmsWarehouseSupports": "只有Ginee WMS / Keeppack / FASTOCK 仓库才支持修改推送策略。修改推送策略后仅对新订单生效，已有订单可通过操作“同步”重新触发推送策略",
    "editingThePushStrategyWillOnly": "修改推送策略后仅对新订单生效，已有订单可通过操作“同步”重新触发推送策略",
    "userManuallyEditedTheWarehouseStock": "用户手动修改仓库库存，导致可用库存变为负数",
    "multipleChannelProductsAreBoundTo": "多个店铺 / 多个渠道商品 绑定到同一个MSKU，导致同一库存在同一时间被下单购买。如果顾客同时下单，就会造成超卖",
    "orderSyncDelayDueToDelay": "订单同步延迟：由于渠道订单同步延迟，该库存实际上已被其他订单使用",
    "failedPushStockStockNotPushed": "推送库存失败，库存没有推送到店铺，导致店铺库存与Ginee库存不一致。",
    "TheStockWasEditedIn": "2. 该库存是在卖家中心编辑的。 在卖家中心编辑库存不会同步到Ginee MSKU库存，这可能会导致Ginee和渠道之间的库存不一致。",
    "AbnormalitiesWhenTheChannelAdjusts": "1. 渠道更新库存时出现异常，导致库存更新不彻底。",
    "gineeSuccessfullyPushedTheStockAnd": "Ginee推送库存成功，渠道也成功收到新库存值。 有3种可能性：",
    "pushDelayDueToTheLarge": "由于推送任务较多，导致推送延迟，库存没有按时推送到渠道店铺",
    "noPushRecordFoundStockNot": "没有找到推送记录，库存没有推送到店铺，导致店铺库存与Ginee库存不一致",
    "thisStoreHasReservedPromotionStock": "该店铺有活动预留库存，但Ginee的自动预留活动库存未开启，导致店铺库存与Ginee库存不一致",
    "theStockMonitorAndSyncIs": "未开启库存监控与同步，所以Ginee库存更新后不会推送到店铺",
    "missingOrderInformationsCurrentlyUnableTo": "缺少订单信息。 目前无法确定原因",
    "pleaseCheckTheChangeReasonOn": "请检查列表右侧的变更原因字段",
    "pleaseCheckThePushFailedReason": "请在库存推送记录查看推送失败原因",
    "forOtherChannelsPleaseDirectlyContact": "其他渠道，请直接联系Ginee客服/客户经理进行进一步核实",
    "PleaseContactGineeCustomerService": "3. 请联系Ginee客服/客户经理进行进一步检查",
    "PleaseSyncTheProductThrough": "1. 请通过渠道商品列表同步产品",
    "forShopeeBukalapakAndLazadaProducts": "对于Shopee、Bukalapak和Lazada商品：",
    "ifYouDidntEditTheStock": "如果你没有在卖家中心编辑库存，请参考以下步骤：",
    "preventTheSameIssueHappeningIn": "为了防止将来发生同样的问题，请启用",
    "toPreventTheSameIssueHappening": "为了防止将来发生同样的问题，请保持库存监控和同步启用",
    "pleaseEnableStockMonitorAndSync": "然后重新推送库存，以便商店库存将根据库存推送规则进行更新",
    "notesIfTheStockIsSmaller": "注：如果库存小于(-1)，请查看库存变动记录中第一个(-1)的可用库存",
    "pleaseContactGineeCustomerServiceOr": "请联系Ginee客服/客户经理进行进一步检查",
    "gineeOmsOrderusedtotal": "Ginee OMS 订单量 (使用/总数)",
    "mskuUsedtotal": "MSKU(使用/总数)",
    "currentlyDoesNotSupportUploadingeditingShopee": "Shopee视频暂时不支持上传/编辑",
    "gineeWmsOrderusedtotal": "Ginee WMS 订单量 (使用/总数)",
    "walletCredit": "钱包信用",
    "gineeWmsConsignorusedtotal": "Ginee WMS货主(使用/总数)",
    "onlySupportViewingCustomerDataFrom": "仅支持查看2023年1月1日起的数据",
    "packageSplit": "拆包",
    "theMskuWasNotFoundIn": "三方仓未找到该MSKU，请在库存清单页面检查该SKU的三方仓推送状态，如推送失败请手动操作“添加到履约仓”",
    "changePrice": "改价",
    "discAfterTax": "折扣后税",
    "discBeforeTax": "折扣前税",
    "productTaxIncluded": "商品含税",
    "additionalTax": "额外收税",
    "vatRules": "税规则",
    "vat": "增值税",
    "selectAndAdd": "选择并添加",
    "customerNotFoundDoYouWant": "未找到客户，是否立刻添加？",
    "selectFromExistingCustomerList": "从已有客户列表选择",
    "enterCustomerPhoneNumberToAdd": "输入客户手机号添加客户或查询已有客户",
    "addBuyer": "添加下单客户",
    "unknown": "未知",
    "afterDeduction": "减免后",
    "byPercentage": "按百分比",
    "byAmount": "按金额",
    "deductionMethod": "减免方式",
    "deductionAmount": "减免金额",
    "actualPayment": "实付",
    "pleaseAddProductsFirst": "请先添加商品",
    "youWantToClearYourCart": "确定要清空购物车吗？",
    "vatIncluded": "含增值税",
    "payable": "应付",
    "productSubtotal": "商品小计",
    "viewDetails": "查看明细",
    "productDiscount": "商品折扣",
    "wholeOrderReduction": "整单减免",
    "youSureYouWantToDelete": "确定要删除吗？",
    "switchingStores": "切换门店中...",
    "wantToChangeTheStoreTo": "确定要更换门店为{x}吗？",
    "clearShoppingCart": "清空购物车",
    "disc": "优惠",
    "totalXItems": "共{x}件",
    "deduction": "减免",
    "checkOut": "结账",
    "submitOrder": "提交订单",
    "productBarcode": "商品条码",
    "pleaseScanTheProductBarcode": "请扫描商品条码",
    "cashier": "收银",
    "signalStrength": "信号强度",
    "language": "语言",
    "loginToTheStoreSuccessfully": "登录门店成功！",
    "pleaseSelectAStore": "请选择门店",
    "thereIsNoAccessibleStorePlease": "没有可以访问的门店，请联系管理员开通",
    "partialRefund": "部分退款",
    "refunded": "已退款",
    "partialReturn": "部分退货",
    "completedDone": "已成交",
    "printReceipt": "打印小票",
    "noNeedToPrintReceipt": "不打印小票",
    "cashReceived": "实收金额",
    "transactionAmount": "交易金额",
    "numberOfItems": "商品件数",
    "customer": "下单客户",
    "transactionTime": "交易时间",
    "transactionSuccessful": "交易成功！",
    "reorder": "重新下单",
    "theShoppingCartWillBeAutomatically": "将自动清空购物车，请重新选择商品下单",
    "theStoreHasChangedItsWarehouse": "店铺已更换仓库，无法下单！",
    "continueToPlaceAnOrder": "继续下单",
    "removeOutOfStockProducts": "移除缺货商品",
    "outOfStockQuantity": "缺货数量",
    "theFollowingProductsAreOutOf": "以下商品缺货！继续下单将导致超卖",
    "card": "刷卡",
    "returnFailedTheReturnedItemHas": "退货失败！退货商品已被删除，请选择仅退款并手动入库退货商品",
    "returnFailedTheReturnWarehouseIs": "退货失败！退货仓库已禁用，请选择仅退款并手动入库退货商品",
    "refundToTheOriginalCard": "原卡退回",
    "refundMethod": "退款方式",
    "vatRefund": "退税",
    "afterOperatingReturnTheStockThe": "操作退货后，仓库将会立刻增加退货商品的库存",
    "wantToReturn": "确认要退货吗？",
    "return": "退货",
    "completed": "完成",
    "cancelEntireOrder": "整单取消",
    "onceTheEntireOrderIsCancelled": "整单取消后，订单会全额退款，且仓库将立刻增加订单中商品的库存",
    "cancelTheEntireOrder": "确认要整单取消吗？",
    "onlyRefundsWillOnlyAffectThe": "仅退款只会影响订单实收金额，不会影响商品库存",
    "youWantToRefund": "确认要退款吗？",
    "orderActualPayment": "订单实付",
    "amountExcludeVat": "不含税金额",
    "companyPhoneNumber": "公司电话",
    "companymerchantTin": "公司/商家税号",
    "companymerchantLegalAddress": "公司/商家法定地址",
    "companymerchantName": "公司/商家名称",
    "totalAmountPaid": "实付金额",
    "addVatX": "加：增值税({x}%)",
    "amountDue": "应付金额",
    "lessDiscounts": "减：折扣",
    "lessVatx": "减：增值税({x}%)",
    "totalAmount": "总金额",
    "vatExemptSales": "免税销售额",
    "zeroratedSales": "零税率销售额",
    "vatableSales": "应税销售额",
    "itemBig": "商品",
    "siNo": "SI No",
    "businessStyle": "公司名",
    "tinNo": "TIN No.",
    "soldTo": "买家",
    "changes": "找零",
    "totalPaid": "实付",
    "totalOrder": "应付",
    "vatx": "增值税({x}%)",
    "totalDisc": "折扣总额",
    "totalItem": "商品小计",
    "cashierPerson": "收银员",
    "orderNo": "订单号",
    "insufficientStockUnableToPurchaseProducts": "商品库存不足，无法加购",
    "isNotCorrectPleaseCheckPhone": "号码不合法，请检查",
    "mobileRangeLengthWithPrefix": "手机号应该以{prefix}开头, 且由{min}至{max}位数字组成",
    "mobileLengthWithPrefix": "手机号应该以{prefix}开头, 且由{length}位数字组成",
    "mobileRangeLength": "手机号必须是{min}至{max}位数字",
    "posSettings": "POS设置",
    "manageGineePosStore": "管理Ginee POS店铺",
    "changingWarehousesWillReplaceTheProducts": "更换仓库将替换POS店铺的商品为新仓库的商品和库存，请谨慎操作",
    "taxCollectionPreference": "收税方案",
    "orderTotalAfterRefund": "退款后订单总额",
    "productQuantity": "商品数量",
    "selectReturnProduct": "选择退货商品",
    "receipt": "小票",
    "newDiscountedPrice": "新折扣价",
    "taxDiscountPreference": "税折扣方案",
    "pleaseEnterCorrectUsername": "请输入正确的用户名",
    "orderPhoneNumber": "下单手机号",
    "customerNam": "客户姓名",
    "theAmountDueCannotBeLess": "实收金额不能小于应收",
    "excessiveReturnIsNotSupported": "不支持超额退货",
    "theStoreIsNotBoundTo": "店铺未绑定仓库",
    "orderDoesNotExist": "订单不存在",
    "theStoreDoesNotExist": "店铺不存在",
    "productBarcodeXDoesNotExist": "商品条码{x}在仓库不存在",
    "notExistInTheWarehouse": "MSKU{x}在仓库不存在",
    "addMskuToTheWarehouseFirst": "MSKU在仓库不存在，请先添加MSKU到仓库",
    "pay": "支付",
    "aOperationToCancelTheEntire": "{a}操作整单取消，备注{b}",
    "aOperationReturnAndRefundMsku": "{a}操作退货退款，MSKU {b} ，数量{c}，退款金额{d}，退税金额{e}，备注{f}",
    "aOperationRefundRefundAmountB": "{a}操作退款，退款金额{b}，退税金额{c}，备注{d}",
    "discountPriceCannotBeHigherThan": "折扣价不能高于原价",
    "supportsInputNumNoMore": "仅支持输入数字/字母/空格/\"-\"/\"_\"",
    "ooOrders": "O2O订单",
    "ifYouDoNotHaveOms": "没有OMS客户管理权限，请联系管理员开通",
    "totalVatInc": "总额",
    "netDue": "净额",
    "lessOtherDiscounts": "减：其他折扣",
    "customerPhoneNo": "客户手机号",
    "doNotAllowLazadaEase": "渠道限制：Lazada Ease Mode店铺的商品不允许更新库存",
    "doNotAllowTokoOO": "渠道限制：Tokopedia O2O仓库的商品不允许更新库存",
    "youHaveAddedAChannelSku": "您添加了非同名的平台SKU，如果将不同的商品绑定到MSKU上，可能会导致超卖，请您确认该平台SKU与MSKU是相同的商品",
    "bindingSkuWithDifferentNamesMay": "如果绑定不同的SKU可能会导致超卖，请谨慎操作",
    "channelReturnedErrorReachTheChannel": "{Channel}返回错误：达到了渠道API的QPS调用上限，库存推送失败",
    "expiredStoresWillNotPushInventory": "已过期的店铺不会推送库存，请尽快重新绑定！",
    "massReleaseWillReleaseTheReserved": "批量释放将按照商品维度（包含已选商品下的所有规格）释放活动预留库存，请谨慎操作！",
    "recommendedOperation": "建议操作",
    "oversoldReasons": "超卖原因",
    "pleaseEnable": "请启用",
    "ThereAreCancellationreturnrefundOrdersOn": "3. 平台有取消/退货/退款订单，导致平台库存自动增加可以下单，但Ginee库存仍为0，导致超卖。",
    "PleaseCheckWhetherThereAre": "2. 请检查库存更新为0后，卖家中心是否有取消/退货/退款的订单",
    "skuxAlreadyExistsInOmsAnd": "SKU{x}在OMS已存在且是组合品，组合品不允许出/入库！",
    "oneclickOversoldCheck": "超卖检查",
    "massRelease": "批量释放",
    "thisOperationWillReleaseTheReserved": "该操作会释放店铺下所有商品的预留活动库存，请谨慎操作！",
    "imageSizeMustBeGreaterThan": "图片尺寸必须大于高{x}，宽{y}",
    "clickToUpdateBasicInformationSuch": "点击更新套餐、权限等基本信息",
    "clickRefreshToObtainTheLatest": "点击刷新可获取最新套餐、权限和货主绑定申请等信息",
    "bySelectedProducts": "按已选商品",
    "byStoreName": "按店铺",
    "settingProductDescription": "主商品和克隆设置",
    "paymentReconciliation": "平台对账",
    "partialRelease": "部分结算",
    "released": "已结算",
    "toRelease": "待结算",
    "marketplaceReleasedAmountForTheOrder": "该笔订单的平台结算金额",
    "orderPackageNumber": "订单包裹号",
    "marketplaceReleasedAmountEstimatedRelease": "差异金额 = 平台结算金额 - 预计结算金额",
    "afterTheOrderIsCompletedThe": "订单完成后平台结算给卖家的订单收入",
    "settlementDetails": "结算详情",
    "amountDifference": "差异金额",
    "marketplaceReleasedAmount": "平台结算金额",
    "reconciliationStatusProvidedByTheChannel": "平台提供的对账状态",
    "feesCharges": "费用&收费",
    "voucherRebates": "优惠券和返现",
    "totalExpenses": "总支出",
    "estimatedReleaseAmountTotalRevenue": "预计结算金额 = 总收入 - 总支出",
    "estimatedReleaseAmount": "预计结算金额",
    "timeoutPaymentIsNotSettledWithin": "超时：订单完成后24小时内未结算打款",
    "settlementDataIsSyncedOnceA": "每天同步一次结算数据，可能存在部分已结算订单仍停留在未结算状态",
    "xDigitsOfEnglishChineseNumbers": "{x}位英文、中文、数字、空格和 - _ & %",
    "pushSuccessnoShippingLabel": "推送成功(无面单)",
    "pushSuccesshasShippingLabel": "推送成功(有面单)",
    "clickTheOkButtonToRecreate": "点击【确定】按钮，将按照订单最新的数据重建一个销售出库单",
    "youSureYouWantToRecreate": "确定要重建销售出库单？",
    "atchEditingOfBrandsInMultiple": "多店铺批量编辑品牌仅支持选择\"No Brand\"，如需选择其他品牌请选择同一店铺的商品",
    "editingOfBrandsInMultiple": "多店铺批量编辑品牌仅支持选择\"No Brand\"，如需选择其他品牌请选择同一店铺的商品",
    "masstRemark": "批量编辑备注",
    "pushFailedKeeppackOutboundOrderStatus": "推送失败，Keeppack不允许执行此操作",
    "printingFailedTheOrderIsMissing": "打印失败，该订单缺少快递单PDF",
    "dearCustomer": "尊敬的",
    "yourFollowingResourcesHaveExceededThe": "您以下资源已超出套餐的限制，请调整或升级套餐后再继续使用Ginee OMS",
    "selectXStoresToContinueBinding": "选择{x}个继续绑定的店铺，其余将被停用（店铺被停用后，不会影响店铺在渠道销售；该店铺的渠道商品会跟主SKU自动解绑；店铺的商品/订单等数据将不会在Ginee ERP展示）",
    "selectedXStore": "已选{x}店铺",
    "staffAccount": "员工账户",
    "selectXStaffAccountsToContinue": "选择{x}个继续使用的员工账户，其余将被移除（仅移除员工账户的Ginee OMS系统权限，其他Ginee系统不受影响）",
    "onlySupportsUploadingPdfFilesMax": "仅支持上传PDF文件，最大不能超过1MB",
    "shippingLabelHasBeenUploaded": "快递单已上传",
    "uploadShippingLabel": "上传快递单",
    "shippingLabelPdf": "快递单PDF",
    "cancellationTime": "取消时间",
    "shippingTime": "发货时间",
    "inventorySynchronizationSwitchTurnedOff": "库存监控与同步开关：已关闭",
    "theFollowingFunctionsWillBeAutomatically": "以下功能因为套餐过期自动关闭，需续费套餐后手动开启：",
    "afterThePackageExpiresStockSynchronization": "套餐到期后，库存同步开关将自动关闭。",
    "lazadaPhOrdersOnlySupportPrinting": "已选订单包含PH站点的订单，仅支持使用Ginee自有模板打印发票",
    "companymerchantVatTinNo": "公司/商家增值税税号",
    "afterTheSwitchIsTurnedOn": "开启后，出库单在推送仓库前会先拉取同步三方仓的最新库存，如果仓库库存不足则取消推送，且更新OMS的仓库库存并将该笔订单移入问题订单-缺货；\n关闭后，出库单满足推送条件将直接推送仓库，不会再校验三方仓库存是否充足",
    "checkStockBeforePushing": "推送前检查库存",
    "theStrategyImplementedByTheWarehouse": "仓库执行的策略，具体请查看策略说明",
    "whenTheOrderMeetsThePush": "订单满足推送条件时将自动推送，无需手动推送",
    "shopeeApiRequiresThatAllVariation": "Shopee API要求编辑商品时不允许更换全部规格名称（至少要保留一个原有的规格名称）",
    "sellerOwnFleet": "卖家自配送",
    "addedToday": "今日新增",
    "globalProduct": "全球商品",
    "publishedSite": "已发布的站点",
    "sellerStock": "卖家库存",
    "globalProductPrice": "全球商品价格",
    "verifyMskuBeforePushingYIs": "推送前校验MSKU:{y}在WMS/三方仓缺货，拦截推送",
    "theStockVerificationFailedBeforePushing": "推送前检查库存校验未通过，拦截推送出库单，原因：MSKU:{x}在WMS/三方仓缺货",
    "enablePush": "启用推送",
    "disablePush": "禁止推送",
    "changeToDisable": "改为禁用",
    "changeToEnable": "改为启用",
    "productNameAccountNumber": "商品名称 & 账号",
    "dueToShopeeApiLimitationsProducts": "由于Shopee API限制，商品规格超过50个将无法在Ginee进行发布或修改",
    "accurateWh": "Accurate仓库",
    "gineeWarehouse": "Ginee仓库",
    "gineeWarehouseAddress": "Ginee仓库地址",
    "gineeWarehouseName": "Ginee仓库名称",
    "manageTheMappingRelationshipBetweenChannel": "管理渠道仓库与Ginee仓库的映射关系",
    "youCanSelectTheChannelStore": "可选择要推送库存的渠道店铺，CNSC/3PF店铺仅支持按照商家账号设置库存推送规则",
    "bindingStatus": "绑定状态",
    "channelWarehouseStatus": "渠道仓库状态",
    "thePartiallyReleasedListRefersTo": "部分结算列表指的是Lazada订单包含多个商品，有的商品结算了、有的商品还没结算的数据，会放入该状态下",
    "theReleasedListDisplaysTheReleased": "已结算列表展示的是从API获取到的已结算数据",
    "customTax": "定制税",
    "promotionFee": "活动费用",
    "transactionFee": "交易手续费",
    "highQuality": "优质的",
    "commissionF": "佣金费",
    "amsCommissionFee": "AMS佣金费",
    "reverseShippingFeeForReturnedOrders": "退回订单的反向运费",
    "plShippingFeeDiscount": "3PL运费折扣",
    "shopeeShippingRebate": "Shopee运费回扣",
    "sellerAbsorbedCoinCashback": "卖家吸收硬币现金返还",
    "productDiscountsAndCashbackByShopee": "Shopee提供的商品折扣返现",
    "productRefundAmount": "商品退款金额",
    "productDiscountedPrice": "商品折扣价",
    "productOriginalPrice": "商品原价",
    "totalReleaseAmount": "结算总额",
    "settlementNo": "结算单号",
    "settlementTime": "结算时间",
    "whetherTimeout": "是否超时",
    "releasing": "释放中",
    "orderItem": "订单商品",
    "settlementDetailsAllPages": "结算详情-所有页",
    "settlementDetailsBySelected": "结算详情-按勾选",
    "ordersAllPages": "订单列表-所有页",
    "ordersBySelected": "订单列表-按勾选",
    "compatibleModels": "适配车型",
    "noticeTitle": "标题",
    "promotionStockCannotBeGreaterThan": "活动库存不能大于可用库存",
    "pleaseEnterTheTitle": "请输入标题",
    "theOutboundListHasBeenCompleted": "该出库单已在履约仓出库完成，无法重新推送",
    "commissionChargedBasedOnItemUnit": "根据商品单价收取佣金(按单价百分比收取 - 卖家优惠)",
    "theOrderHasBeenCompletedBut": "订单已完成，但平台尚未结算打款",
    "paymentFeeForProcessingCharges": "手续费支付费（按单价收取% + 买家运费 - 卖家优惠券）",
    "listedPriceOfTheItemSold": "在向买家提供任何折扣或费用之前所售商品的标价",
    "productVat": "商品增值税",
    "buyerRealName": "买家真实姓名",
    "salesInvoiceWillUseTheSelected": "关闭时Sales Invoice将使用已选择的固定客户信息。开启时Sales Invoice将使用平台订单提供的真实客户信息。请注意部分渠道的客户信息会脱敏显示，建议脱敏客户信息的店铺不要开启该功能",
    "realCustomerInformationInTheOrder": "订单中的真实客户信息",
    "excludingVat": "不含增值税",
    "theSkuIsNotOversoldReason": "SKU未超卖，原因：由于TTS取消订单后会延迟释放库存，所以Ginee同步到TTS的取消订单后也会延迟12小时释放锁定库存（状态会显示为释放中）。释放中的锁定库存数量≥负库存数量不属于超卖",
    "ifYouNeedToReleaseThe": "如需提前释放“释放中”的锁定库存，可在出库管理找到对应的出库单操作作废，强制释放锁定库存（有超卖风险，请谨慎操作)",
    "publishedGlobalProduct": "已发布的全球商品",
    "editingTheGlobalProductPriceWill": "修改全球商品价格会按照计算公式修改店铺商品的价格",
    "editingTheGlobalProductStockWill": "修改全球商品库存，会更新店铺商品的库存",
    "pleaseNotePleaseUseMAuthorization": "请注意:本土普通店铺请使用{m}授权，CNSC/3PF店铺请使用{u}授权",
    "theSkuIsOutOfStock": "SKU在WMS库存不足，无法推送",
    "theSkuDoesNotExistIn": "SKU在WMS不存在，无法推送",
    "theOrdersOutboundListHasBeen": "订单在WMS已完成出库，无法推送",
    "wmsIsProcessingTheOrder": "WMS正在处理该订单",
    "authorizeMainAccount": "Main Account授权",
    "authorizeShopAccount": "Shop Account授权",
    "customOrNo": "自定义OR编号",
    "onlySupportsFilesPdfPngJpeg": "仅支持PDF、png、jpeg、JPG文件需小于5M",
    "gineeWillAutomaticallyCreateAnInbound": "Ginee将按照填写的入库数量自动创建入库单、直接完成入库并增加仓库库存，请确认！",
    "youWantToAddToThe": "确认要添加至仓库并直接入库吗？",
    "feeRefundreturn": "退款/退货费用",
    "fulfillmentFee": "履约费用",
    "shippingFeeChargedToSeller": "向卖家收取的运费",
    "otherFee": "其他费用",
    "serviceFee1": "服务费",
    "rebateFromMarketplace": "平台回扣",
    "sellerPromotion": "卖家促销",
    "productDiscountPrice": "商品折扣价",
    "pleaseRenewOrPurchaseOrderQuantity": "请尽快续费或购买订单量，否则将无法使用订单相关功能！",
    "yourGineeWmsOrderQuotaHas": "您的Ginee WMS订单量已用尽！",
    "yourGineeOmsOrderQuotaHas": "您的Ginee OMS订单量已用尽！",
    "orderQuotaExceeded": "订单量已用尽",
    "insufficientOrderQuota": "剩余订单量不足10%",
    "theOrderQuotaOfYourCurrent": "请尽快续费或购买单量，否则将无法使用出库相关功能！",
    "bySelectingAllLazadaWillConsider": "选择ALL后Lazada将认为选择包含下方所有选项，无需勾选每个子项",
    "abnormalExpiration": "异常过期",
    "pushOutboundOrders": "推送出库单",
    "pushInboundOrders": "推送入库单",
    "directInbound": "直接入库",
    "updatedToBePushed": "（已更新）待推送",
    "addressNameAndPhoneNumberWill": "地址、姓名、电话将用于Ginee自定义快递单中的寄件信息展示",
    "syncChannelAddress": "同步平台地址",
    "withholdingTax": "预扣税",
    "theCnscpfsChannelWarehouseIsNot": "CNSC/3PF的渠道仓库未绑定Ginee仓库，无法生成出库单",
    "afterSettingTheShippingMethodWhen": "设置发货方式后，在Ginee安排发货时将自动选择发货方式和取件地址，提高发货效率",
    "theOutboundOrderIsInvalidIn": "出库单在WMS已作废，无法重新推送",
    "progress": "进度",
    "setShippingInfo": "设置寄件信息",
    "outboundOrderHasBeenSuccessfullyPushed": "出库单已成功推送到仓库进行履约，无法修改出库单信息。请根据订单变更信息进行手工出库",
    "mStoreAbnormallyExpired": "{m}店铺异常过期",
    "oneClickToChangeTheStores": "一键更换店铺取件地址",
    "changeAddressByStore": "更换店铺地址",
    "youCanQuicklyChangePickupAddress": "可快速将同一Shopee店铺在所有物流的拣货地址更换为其它地址",
    "invalidTrackingNumberPleaseGoTo": "无效的货运参考号（AWB），请尽快到卖家中心重新提交有效的追踪号码！",
    "invalidTrackingNumberawb": "物流单无效",
    "channelAbnormalOrders": "平台异常订单",
    "tryNoww": "开心收下",
    "congratulationsYouHaveReceivedADay": "恭喜！您已获得7天免费试用全功能的机会",
    "mskuTotal": "MSKU总量",
    "updateUsageEveryHours": "每4小时更新使用量",
    "dailyUpdateUsage": "每日更新使用量",
    "replacementSuccessful": "更换成功！",
    "theFollowingOrdersNeedToAdd": "以下订单需要添加发货信息后才能发货",
    "toEnsureNormalUse": "为保证正常使用，请及时续费",
    "invoiceNumberMustBeDigits": "发票号编号必须为8位数字",
    "companymerchantInformation": "公司/商家信息",
    "orDate": "开票日期",
    "orNo": "正式收据编号",
    "pleaseEnterTheWarehouseCodeNo": "请输入仓库代码，不超过50位",
    "failedToPrintInvoice": "打印发票失败",
    "thatIsOfficialReceiptNumberYou": "即Official Receipt Number，你可以设置一个最多3位字母的前缀（前缀可不填写）+ 8位数字（必须为8位数字）来组成OR No，每打印1笔订单将按设置的编号开始自增。请注意：每次修改后将按照最新的规则进行打印，请谨慎修改；",
    "theCompanyInformationSetInGinee": "将显示在Ginee Accounts中设置的公司信息",
    "setCompanyInformation": "设置公司信息",
    "atTheRequestOfThePhilippine": "应菲律宾政府要求，所有菲律宾店铺的发票必须使用菲律宾发票模板，模块可选择是否包含消费税(VAT)",
    "orderDate": "订单日期",
    "thisReceiptShallBeValidFor": "本收据自确认证书之日起有效期为五（5）年",
    "acknowledgementCertificateNo": "确认证书编号",
    "dateIssued": "签发日期",
    "seriesRange": "系列范围",
    "handleProb": "去处理",
    "checksFailed": "检查失败！",
    "successfullyDeletedTheWarehouse": "仓库删除成功！",
    "startDeletion": "开始删除",
    "checksPassed": "检查通过！",
    "inventoryClearanceAllProductStocksAre": "库存清零：所有的商品库存已为0",
    "manualInboundListAllDocumentsHave": "手工入库单：所有单据已完结",
    "purchaseInboundListAllDocumentsHave": "采购入库单：所有单据已完结",
    "manualOutboundListAllDocumentsHave": "手工出库单：所有单据已完结",
    "salesOutboundListAllDocumentsHave": "销售出库单：所有单据已完结",
    "deletingAWarehouseRequiresCheckingThe": "删除仓库需要对仓库内的数据进行检查，结束所有的入库单/出库单、并将所有的SKU库存清零后才可删除",
    "checkingWarehouseDeletion": "删除仓库检查中",
    "beforeDeletingAWarehouseYouNeed": "删除仓库前需要结束所有的入库单/出库单，并将所有的SKU库存清零",
    "oneAreYouSureYouWantTo": "确定要删除仓库吗？",
    "theNumberOfLocalWarehouseHas": "本地仓数量已达上限（最多50个），请删除多余的仓库后重试！",
    "oneFailedToSaveTheNumberOf": "保存失败！捆绑商品已绑定的渠道SKU已达上限（最多500个）",
    "failedToSaveTheNumberOf": "保存失败！组合商品添加的捆绑商品已达上限（最多10个）",
    "failedToSaveTheBoundCombined": "保存失败！被组合商品 {x} 的捆绑数量已超限（最多被100个组合品绑定）",
    "failedToSaveTheVariationsOf": "保存失败！主商品添加的规格已达上限（最多200个）",
    "bindingFailedTheChannelSkuBound": "绑定失败！MSKU绑定的渠道SKU已达上限（最多500个）",
    "deletingWarehouse": "正在删除仓库...",
    "deletingWarehouseProducts": "正在删除仓库商品...",
    "deletingMIO": "正在删除手动入库单...",
    "deletingPIO": "正在删除采购入库单...",
    "deletingMOO": "正在删除手工出库单...",
    "deletingSOO": "正在删除销售出库单...",
    "none": "无",
    "InboundStockIsOnlyValid": "1.入库库存只针对添加仓库并入库有效，入库时会带入入库库存数据。 2.组合商品只能添加至仓库，不支持入库。 3.不需要关联的商品可直接操作删除。 4.提交后，如果仓库中已存在该商品，系统会自动过滤且该商品不会再次入库。",
    "differentWeightdimensionsForVariations": "规格有不同的重量/尺寸",
    "becauseAllVariationsHaveExactlyThe": "由于所有规格的重量和尺寸完全相同，系统将自动帮助您切换为商品级的重量和尺寸",
    "mskuXWarehouseNameOrWarehouse": "MSKU {0} , 仓库名称或仓库编码不存在",
    "mskuDoNotExist": "MSKU {0} 在{1}仓库中不存在, 请先将SKU添加到仓库",
    "masterProductXDoesNotExist": "主商品{x}不存在",
    "theOutboundListHasBeenPushed": "出库单已推送，订单不再允许编辑",
    "orderXStatusDoesNotAllow": "{0}订单状态不允许编辑",
    "orderXDoesNotExist": "{0}订单不存在",
    "thePaymentAmountShouldBeLess": "支付金额应该小于订单总额，请检查",
    "theTotalAmountOfProductsExceeds": "商品总额超出限制",
    "theOrderNoIsDuplicatedPlease": "订单号重复，请修改订单号",
    "theStoreCannotBeEmpty": "店铺不能为空",
    "channelRequirementsXMustBeBetween": "渠道要求{x}必须在{y}~{z}之间",
    "itemCount": "商品数量",
    "variationOptionLength": "规格选项名称长度",
    "variationTypeLength": "规格类型名称长度",
    "itemDescriptionLength": "商品描述长度",
    "itemImageCount": "商品图片数量",
    "itemNameLength": "商品名称长度",
    "wholesalePriceThresholdPercentage": "批发价格门槛百分比",
    "sinceSomeProductsHaveTheSame": "由于有的商品所有规格的重量和尺寸完全相同，系统将自动帮助您切换为商品级的重量和尺寸",
    "toEnableDifferentWeightdimensionsForEach": "要为每个变体启用不同的重量/尺寸，请到Shopee单个商品编辑页并启用“规格有不同的重量/尺寸”按钮",
    "mskuHasBeenInbounded": "MSKU已入库",
    "exportFailed": "导出失败",
    "staffToRemovePermissions": "要移除权限的员工：",
    "staffsToRetainPermissions": "要保留权限的员工：",
    "storesToDeactivate": "要停用的店铺：",
    "storesToRetain": "要保留的店铺：",
    "staffAccountsToContinueUsing": "继续使用的员工账户：",
    "storesToContinueBinding": "继续绑定的店铺：",
    "pleaseConfirmTheStoreOrStaff": "请确认您要保留的店铺或员工",
    "theDefaultPickupTimeLatest": "取件时间默认选最晚",
    "theDefaultPickupTimeEarliest": "取件时间默认选最早",
    "doNotAutomaticallySelectThePickup": "不自动选中取件时间",
    "setTo": "设置为",
    "onlyApplicableToOrdersWithPickup": "仅适用于Shopee、TikTok上门取件的订单",
    "automaticallySelectThePickupTime": "自动选中取件时间",
    "whenYouSelectLatest": "选择该选项后，进入发货页面时，默认填充取件时间为当前可选择的最晚时间",
    "whenYouSelectEarliest": "选择该选项后，进入发货页面时，默认填充取件时间为当前可选择的最早时间",
    "thisOrderIsFromAMultiwarehouse": "该订单为多仓店铺的订单，更换地址后可能会产生额外的物流费用，确定要更新吗？",
    "noManualSyncTask": "无手动同步任务",
    "taskIds": "任务ID",
    "manualSyncProduct": "手动商品同步",
    "manualSyncOrder": "手动订单同步",
    "syncHistoricalOrders": "同步历史订单",
    "sinceTiktokShopWillDelayReleasing": "由于TTS取消订单后会延迟释放库存，所以Ginee同步到TTS的取消订单后也会延迟12小时释放锁定库存（状态会显示为释放中）",
    "syncProgress": "同步进度",
    "notAbnormal": "无异常",
    "reservationFailedTheBoundGineeWarehouse": "预留失败，未找到绑定的Ginee仓库（Shopee CNSC/3PF店铺按照店铺仓库与Ginee仓库的绑定关系来预留活动库存）",
    "discountType": "折扣类型",
    "percentageOff": "折扣百分比",
    "fixedPrice": "固定折扣价",
    "variationlevel": "规格级别",
    "productlevel": "商品级别",
    "youWantToSwitchTheDatabase": "您确定要更换数据库为{x}吗?",
    "allSettingsAndMappingsWillBe": "所有设置和映射将被删除，MSKU需要重新匹配",
    "changeDatabase": "切换数据库",
    "pleaseSelectTheDatabaseToChange": "请选择要切换的数据库",
    "ifYouSwitchToANew": "如果切换为新数据库，则所有的设置与映射均需要重新配置。但不用担心，已推送到Accurate的数据不会被删除。",
    "mappingS": "匹配",
    "channelStoreName": "渠道店铺名称",
    "customersRealName": "客户真实姓名",
    "productTaxSettings": "商品税设置",
    "whetherTheProductWillIncludeVat": "该产品的销售发票上是否包含增值税",
    "noVat": "不含增值税",
    "addVat": "含增值税",
    "gineeWarehouseCanOnlyBeMapped": "Ginee仓库只能与1个Accurate仓库映射，请至少完成1个仓库映射",
    "dataLinkedWithBranchesBanksAnd": "当订单交易发送至Accurate时，数据与分支机构、银行和COA相关联",
    "shippingFeeChargedToSellersCalculated": "根据平台结算详情计算的向卖家收取的运费",
    "otherDeductedFeesProvidedByThe": "平台结算详情提供的其他扣除的费用",
    "rebateAndDiscountAmountAsProvided": "平台结算详情提供的补贴和折扣金额",
    "sellerPromotionFeeAsProvided": "平台结算详情提供的卖家促销的费用",
    "serviceFeeCommissionFeeAndOther": "平台结算详情提供的服务费、佣金等平台扣除的费用",
    "importantNotesErrorNotificationTheCumulative": "重要提示：购买Lazada-Authorization-only时错误提示“累计订单期限不能超过18个月”表示您购买了18个月的仅限授权。请{x}, 然后单击使用服务 完成集成",
    "historicalOrdersSettlementDataWillNot": "历史订单的结算数据不会同步",
    "variousServiceCharges": "各类服务费",
    "thePromotionPeriodMustBeLonger": "促销时长必须大于10分钟且小于365天",
    "purchaseLimitCannotBeGreaterThan": "购买限制不能大于可用库存",
    "fulfilledByChannel": "平台履约",
    "jitPo": "JIT PO",
    "advanceFulfillment": "预先履行",
    "notSpecial": "无特殊",
    "createdByShopee": "由Shopee创建",
    "matchPending": "待匹配",
    "discountPriceIsLowerThanM": "折扣价低于{m}",
    "theDiscountRateMustNotbeHigher": "折扣率不得高于{m}％",
    "limitationMethod": "限制方式",
    "variationDimension": "规格维度",
    "noLimit": "不限制",
    "invalidDiscount": "无效的折扣",
    "invalidPrice": "无效的价格",
    "TheStartTimeMustBe": "1、开始时间必须晚于当前时间",
    "ThePromotionPeriodMustBe": "2、活动时间段必须大于10分钟小于365天",
    "productId": "商品ID",
    "variationId": "规格ID",
    "thePromotionsPeriodMustBeLonger": "活动时间必须大于1小时",
    "thePromotionuPeriodMustBeLonger": "活动时长必须大于10分钟，小于365天",
    "unavailableStockIntransit": "不可用库存（在途）",
    "stockTransferredToSortingCenterBy": "Shopee提前将库存转至分拣中心",
    "advanceStock": "预先履行库存",
    "outboundQuantityMustBe": "出库数量必须>0",
    "needHelpWereHereForYou": "需要帮助？我们随时为您服务。联系我们：周一至周五，9AM-6PM",
    "orderXIsMatchedWithShopee": "订单【{0}】与Shopee预先履行订单【{1}】完成匹配，订单合并",
    "bookingId": "预订编号",
    "availStock": "可用库存",
    "notMatchedToOrder": "与订单不匹配",
    "importToCreateCombinedProduct": "导入创建组合商品",
    "youCanImportUpTo": "你每次最多导入10,000条数据（格式：xlsx，xls）去批量创建组合商品。",
    "matched": "已匹配",
    "marketplaceSpecialOrder": "平台特殊订单"
};
});

var genieI18nErp = createCommonjsModule(function (module, exports) {
exports.__esModule = true;
exports.zh = exports.vn = exports.vi = exports.th = exports.my = exports.id = exports.en = void 0;

exports.en = en.en;

exports.id = id.id;

exports.my = my.my;

exports.th = th.th;

exports.vi = vi.vi;

exports.vn = vn.vn;

exports.zh = zh.zh;
});

var en$1 = genieI18nErp.en;
var id$1 = genieI18nErp.id;
var th$1 = genieI18nErp.th;
var vi$1 = genieI18nErp.vi;
var zh$1 = genieI18nErp.zh;
export { en$1 as en, id$1 as id, th$1 as th, vi$1 as vi, zh$1 as zh };
