import {Alert} from "../../../__snowpack__/pkg/antd.js";
import classNames from "../../../__snowpack__/pkg/classnames.js";
import React from "../../../__snowpack__/pkg/react.js";
import "./index.css";
export default function GnAlert(props) {
  const {type = "info", className} = props;
  return /* @__PURE__ */ React.createElement(Alert, {
    ...props,
    className: classNames("gn-alert", `gn-alert-${type}`, className)
  });
}
