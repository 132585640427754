import {useState} from "../../__snowpack__/pkg/react.js";
import {createModel} from "../../__snowpack__/pkg/hox.js";
import {request} from "../utils/request/index.js";
import Cookies from "../../__snowpack__/pkg/js-cookie.js";
import {OriginMap} from "../../__snowpack__/pkg/@genie/lib-iam-utils.js";
export const useProvideInfo = () => {
  const [provideInfo, setProvideInfo] = useState({});
  const [loading, setLoading] = useState(false);
  const fetchData = async (clear) => {
    try {
      if (Cookies.get("setProvideInfo") && !clear) {
        let jsonResourceInfo = JSON.parse(Cookies.get("setProvideInfo"));
        setProvideInfo(jsonResourceInfo);
        return jsonResourceInfo;
      }
      setLoading(true);
      let seconds = 10;
      let expires = new Date(new Date() * 1 + seconds * 1e3 * 60);
      let data = await request.get(`/saas/user/provide/info`);
      Cookies.set("setProvideInfo", JSON.stringify(data), {expires});
      setProvideInfo(data);
      setLoading(false);
      return data;
    } catch (e) {
      setLoading(false);
    }
  };
  return {
    loading,
    provideInfo,
    fetchData
  };
};
export const useProvideInfoModal = createModel(useProvideInfo);
export const useIamDisplay = () => {
  const [resourceInfo, setResourceInfo] = useState({});
  const [displayInfoTime, setDisplayInfoTime] = useState("");
  const [loading, setLoading] = useState(false);
  const fetchData = async (clear) => {
    try {
      if (Cookies.get("setDisplayInfo") && !clear) {
        let jsonResourceInfo = JSON.parse(Cookies.get("setDisplayInfo"));
        let infoTime = Cookies.get("setDisplayInfoTime");
        setDisplayInfoTime(infoTime);
        setResourceInfo(jsonResourceInfo);
        return jsonResourceInfo;
      }
      setLoading(true);
      let seconds = 60 * 15;
      let expires = new Date(new Date() * 1 + seconds * 1e3);
      const origin = OriginMap[window.location.origin]?.INFRA_ORIGIN ?? window.location.origin;
      let data = await request.get(`${origin}/infra/iam/account/package/display`);
      Cookies.set("setDisplayInfo", JSON.stringify(data), {expires});
      let newDate = new Date();
      setResourceInfo(newDate);
      setDisplayInfoTime(newDate);
      Cookies.set("setDisplayInfoTime", newDate, {expires});
      setResourceInfo(data);
      setLoading(false);
      return data;
    } catch (e) {
      setLoading(false);
    }
  };
  return {
    loading,
    resourceInfo,
    fetchData,
    displayInfoTime
  };
};
export const useIamDisplayModal = createModel(useIamDisplay);
