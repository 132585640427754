import React from "../../../../__snowpack__/pkg/react.js";
export const IconMenuReports = React.memo((props) => {
  return /* @__PURE__ */ React.createElement("svg", {
    xmlns: "http://www.w3.org/2000/svg",
    width: "18",
    height: "18",
    viewBox: "0 0 18 18",
    ...props
  }, /* @__PURE__ */ React.createElement("g", {
    fill: "none",
    fillRule: "evenodd",
    stroke: "none",
    strokeWidth: "1"
  }, /* @__PURE__ */ React.createElement("g", {
    transform: "translate(-12 -400)"
  }, /* @__PURE__ */ React.createElement("g", {
    transform: "translate(0 388)"
  }, /* @__PURE__ */ React.createElement("g", {
    transform: "translate(12 12)"
  }, /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", {
    d: "M0 0H18V18H0z"
  }), /* @__PURE__ */ React.createElement("path", {
    fill: "currentColor",
    fillRule: "nonzero",
    d: "M16.4882812 16.3636364L16.4882812 11.1253551 13.5 11.1253551 13.5 14.8519176 11.9882812 14.8519176 11.9882812 5.11363636 9 5.11363636 9 14.8519176 7.48828125 14.8519176 7.48828125 8.10191761 4.5 8.10191761 4.5 14.8519176 2.98828125 14.8519176 2.98828125 2.86363636 1.51171875 2.86363636 1.51171875 16.3636364z"
  })))))));
});
