import {createModel} from "../../../__snowpack__/pkg/hox.js";
import _ from "../../../__snowpack__/pkg/lodash.js";
import {useState} from "../../../__snowpack__/pkg/react.js";
export const useTaskCenter = () => {
  const [data, setData] = useState(localStorage.getItem("taskCenterStore") ? JSON.parse(localStorage.getItem("taskCenterStore")) : []);
  const [isInit, setIsInit] = useState(true);
  const addTaskId = (taskId) => {
    setIsInit(false);
    const temp = [...data];
    temp.unshift(taskId);
    console.log("temp: ", temp);
    if (temp.length > 5) {
      const result = _.slice(temp, 0, 5);
      localStorage.setItem("taskCenterStore", JSON.stringify(result));
      console.log("result: ", result);
      return setData(result);
    } else {
      localStorage.setItem("taskCenterStore", JSON.stringify(temp));
      console.log("result: ", temp);
      return setData(temp);
    }
  };
  return {
    addTaskId,
    taskIdList: data,
    isInit
  };
};
export const useTaskCenterModal = createModel(useTaskCenter);
