import React from "../../../../__snowpack__/pkg/react.js";
export const IconMenuChannelProducts = React.memo((props) => {
  return /* @__PURE__ */ React.createElement("svg", {
    xmlns: "http://www.w3.org/2000/svg",
    width: "18",
    height: "18",
    viewBox: "0 0 18 18",
    ...props
  }, /* @__PURE__ */ React.createElement("g", {
    fill: "none",
    fillRule: "evenodd",
    stroke: "none",
    strokeWidth: "1"
  }, /* @__PURE__ */ React.createElement("g", {
    transform: "translate(-12 -190)"
  }, /* @__PURE__ */ React.createElement("g", {
    transform: "translate(0 178)"
  }, /* @__PURE__ */ React.createElement("g", {
    transform: "translate(12 12)"
  }, /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", {
    d: "M0 0H18V18H0z"
  }), /* @__PURE__ */ React.createElement("path", {
    fill: "currentColor",
    fillRule: "nonzero",
    d: "M6.787 16.364c.415 0 .761-.139 1.038-.415.276-.277.426-.622.449-1.037v-2.213c-.023-.438-.173-.795-.45-1.072a1.407 1.407 0 00-1.037-.414h-.726V9.726h5.878v1.487h-.726c-.415 0-.761.138-1.038.414-.276.277-.426.634-.449 1.072v2.213c.023.415.173.76.45 1.037.276.276.622.415 1.037.415h2.938c.438 0 .795-.139 1.072-.415.276-.277.415-.622.415-1.037v-2.213c0-.438-.139-.795-.415-1.072-.277-.276-.634-.414-1.072-.414h-.726V9.726h2.939V8.274H9.726V6.787h.76c.415 0 .761-.138 1.038-.414.276-.277.415-.634.415-1.072V3.088c0-.415-.139-.76-.415-1.037a1.407 1.407 0 00-1.037-.415H7.513c-.414 0-.76.139-1.037.415a1.407 1.407 0 00-.415 1.037v2.213c0 .438.139.795.415 1.072.277.276.623.414 1.037.414h.761v1.487H1.636v1.452h2.939v1.487h-.726c-.438 0-.795.138-1.072.414-.276.277-.415.634-.415 1.072v2.213c0 .415.139.76.415 1.037.277.276.634.415 1.072.415h2.938zm3.7-11.063H7.513V3.088h2.974v2.213zm-3.7 9.61H3.85V12.7h2.938v2.213zm7.364 0h-2.938V12.7h2.938v2.213z"
  })))))));
});
