import {lazy} from "../../lib/lazy.js";
export const warehouseRoutes = [
  {
    path: "/warehouse-settings",
    auth: "shipping-warehouse-setting",
    component: lazy(() => import(/* webpackChunkName: "warehouseRoutes" */ "../../views/order-warehouse-settings/index.js"))
  },
  {
    path: "/warehouse-settings/add-warehouse-setting",
    component: lazy(() => import(/* webpackChunkName: "warehouseRoutes" */ "../../views/order-warehouse-settings/AddWareHouseSetting.js"))
  },
  {
    path: "/warehouse-settings/warehouse-history-operation",
    component: lazy(() => import(/* webpackChunkName: "warehouseRoutes" */ "../../views/order-warehouse-settings/WareHouseHistoryOperation.js"))
  }
];
