import React, {memo} from "../../../__snowpack__/pkg/react.js";
import {Tooltip} from "../../../__snowpack__/pkg/antd.js";
import {ExclamationCircleFilled} from "../../../__snowpack__/pkg/@ant-design/icons.js";
export const Tip = memo((props) => {
  const {tip, bgcolor, icon, style, ...otherProps} = props;
  return /* @__PURE__ */ React.createElement(Tooltip, {
    placement: "top",
    title: /* @__PURE__ */ React.createElement("div", {
      style: {whiteSpace: "pre-wrap"}
    }, tip),
    ...otherProps
  }, /* @__PURE__ */ React.createElement("span", {
    style: style ? Object.assign({
      display: tip ? "inline-block" : "none",
      marginLeft: 8,
      color: bgcolor || "#ADBACC"
    }, style) : {
      display: tip ? "inline-block" : "none",
      marginLeft: 8,
      color: bgcolor || "#ADBACC"
    }
  }, /* @__PURE__ */ React.createElement(ExclamationCircleFilled, {
    width: 14,
    height: 14
  })));
});
