import { g as getDefaultExportFromCjs, c as createCommonjsModule } from '../../../common/_commonjsHelpers-64249c85.js';
import { i as interopRequireDefault } from '../../../common/interopRequireDefault-6e0c2256.js';
import '../../../common/extends-0409b284.js';
import { _ as _default_1 } from '../../../common/default-5efab910.js';

var en_US = createCommonjsModule(function (module, exports) {



Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _default2 = interopRequireDefault(_default_1);

var _default = _default2["default"];
exports["default"] = _default;
});

var __pika_web_default_export_for_treeshaking__ = /*@__PURE__*/getDefaultExportFromCjs(en_US);

export default __pika_web_default_export_for_treeshaking__;
