import {lazy} from "../../lib/lazy.js";
export const productMokaRoutes = [
  {
    path: "/product-moka",
    component: lazy(() => import(/* webpackChunkName: "productMokaRoutes" */ "../../views/product-moka/list/index.js")),
    auth: "list-product"
  },
  {
    path: "/product-moka-:action(add)",
    component: lazy(() => import(/* webpackChunkName: "productMokaRoutes" */ "../../views/product-moka/edit/index.js")),
    auth: "list-product"
  },
  {
    path: "/product-moka-:action(edit)/:id",
    component: lazy(() => import(/* webpackChunkName: "productMokaRoutes" */ "../../views/product-moka/edit/index.js")),
    auth: "list-product"
  }
];
