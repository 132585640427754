import {productLocalRoutes} from "./modules/product-local.js";
import {productShopifyRoutes} from "./modules/product-shopify.js";
import {productMokaRoutes} from "./modules/product-moka.js";
import {productGineePOSRoutes} from "./modules/product-ginee-pos.js";
import {productLazadaRoutes} from "./modules/product-lazada.js";
import {productLazadaChoiceRoutes} from "./modules/product-lazada-choice.js";
import {productBlibliRoutes} from "./modules/product-blibli.js";
import {productWooCommerceRoutes} from "./modules/product-woo-commerce.js";
import {productTiktokRoutes} from "./modules/product-tiktok.js";
import {productZaloraRoutes} from "./modules/product-zalora.js";
import {productTikiRoutes} from "./modules/product-tiki.js";
import {productMagentoRoutes} from "./modules/product-magento.js";
import {productLocalPriceRoutes} from "./modules/product-local-price.js";
import {warehouseManagementRoutes} from "./modules/warehouse-management.js";
import {stockPushNewRoutes} from "./modules/stock-push-new.js";
import {warehouseStockDetailsRoutes} from "./modules/warehouse-stock-details.js";
import {warehouseStockInventoryRoutes} from "./modules/warehouse-stock-inventory.js";
import {warehouseStockOrderRoutes} from "./modules/warehouse-stock-order.js";
import {fulfillRoutes} from "./modules/fulfill.js";
import {productSettingRoutes} from "./modules/product.js";
import {warehouseShelfManagementRoutes} from "./modules/warehouse-shelf-management.js";
import {outboundManagementRoutes} from "./modules/outbound-management.js";
import {inboundManagementRoutes} from "./modules/inbound-management.js";
import {productLocalPublishRoutes} from "./modules/product-local-publish.js";
import {productShopeeRoutes} from "./modules/product-shopee.js";
import {productShopeeCnscRoutes} from "./modules/product-shopee-cnsc.js";
import {productBukalapakRoutes} from "./modules/product-bukalapak.js";
import {productLineShoppingRoutes} from "./modules/product-line-shopping.js";
import {staffManagementRoutes} from "./modules/staff-management.js";
import {addProductToWarehouseRoutes} from "./modules/add-product-to-warehouse.js";
import {promotionRoutes} from "./modules/promotion.js";
import {reportRoutes} from "./modules/report.js";
import {integrationRoutes} from "./modules/integration.js";
import {collectionRoutes} from "./modules/collection.js";
import {customerRoutes} from "./modules/customer.js";
import {stockRoutes} from "./modules/stock.js";
import {stockLocalRoutes} from "./modules/stock-local.js";
import {warehouseRoutes} from "./modules/warehouse.js";
import {constraintRoutes} from "./modules/constraints.js";
import {welcomeRoutes} from "./modules/welcome.js";
import {settingRoutes} from "./modules/setting.js";
import {warehouseInventoryRoutes} from "./modules/warehouse-inventory.js";
import {warehousePrductListRouters} from "./modules/warehouse-product-list.js";
import {accounting} from "./modules/accounting.js";
import {accurateWarehouse} from "./modules/accurateWarehouse.js";
import {productAkulakuRoutes} from "./modules/product-akulaku.js";
import {logisticsManagementRoutes} from "./modules/logistics-management.js";
import {productTokoPedia} from "./modules/product-tokopedia.js";
import {keeppackWarehouse} from "./modules/keeppack-warehouse.js";
import {kongfuWarehouse} from "./modules/kongfu-warehouse.js";
import {financialRoutes} from "./modules/financial-management.js";
import {orderRoutes} from "./modules/order.js";
import {purchaseRoutes} from "./modules/purchase.js";
export const routeList = [
  ...productShopifyRoutes,
  ...productMokaRoutes,
  ...productGineePOSRoutes,
  ...productLazadaRoutes,
  ...productLazadaChoiceRoutes,
  ...productBukalapakRoutes,
  ...productAkulakuRoutes,
  ...accurateWarehouse,
  ...keeppackWarehouse,
  ...collectionRoutes,
  ...productLocalRoutes,
  ...productLocalPriceRoutes,
  ...customerRoutes,
  ...stockRoutes,
  ...stockLocalRoutes,
  ...warehouseRoutes,
  ...orderRoutes,
  ...productLocalPublishRoutes,
  ...productBlibliRoutes,
  ...warehouseManagementRoutes,
  ...warehouseStockDetailsRoutes,
  ...warehouseStockInventoryRoutes,
  ...warehouseStockOrderRoutes,
  ...stockPushNewRoutes,
  ...fulfillRoutes,
  ...productSettingRoutes,
  ...warehouseShelfManagementRoutes,
  ...outboundManagementRoutes,
  ...inboundManagementRoutes,
  ...productWooCommerceRoutes,
  ...productShopeeRoutes,
  ...productShopeeCnscRoutes,
  ...staffManagementRoutes,
  ...productTiktokRoutes,
  ...productZaloraRoutes,
  ...addProductToWarehouseRoutes,
  ...promotionRoutes,
  ...reportRoutes,
  ...integrationRoutes,
  ...constraintRoutes,
  ...welcomeRoutes,
  ...settingRoutes,
  ...productTikiRoutes,
  ...warehouseInventoryRoutes,
  ...warehousePrductListRouters,
  ...accounting,
  ...logisticsManagementRoutes,
  ...productTokoPedia,
  ...kongfuWarehouse,
  ...financialRoutes,
  ...productLineShoppingRoutes,
  ...productMagentoRoutes,
  ...purchaseRoutes
];
export const getRouteMeta = (path) => {
  return routeList.find((_) => _.path === path)?.meta;
};
export const getRouteAuth = (path) => {
  return routeList.find((_) => _.path?.replaceAll(":action(add)", "add")?.replaceAll(":action(edit)", "edit") === path)?.auth;
};
export const getRouteAuthMap = (path) => {
  return routeList.find((_) => _.path === path)?.authMap;
};
