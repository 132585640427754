import {v1 as uuidv1} from "../../__snowpack__/pkg/uuid.js";
import {useState} from "../../__snowpack__/pkg/react.js";
import {createModel} from "../../__snowpack__/pkg/hox.js";
export const useUuid = () => {
  const [isInitialize, setIsInitialize] = useState(false);
  const getUuid = () => {
    const currentUuid = window.sessionStorage.getItem("uuid");
    if (!isInitialize || !currentUuid) {
      const uuid = uuidv1();
      setIsInitialize(true);
      window.sessionStorage.setItem("uuid", uuid);
      return uuid;
    } else {
      return currentUuid;
    }
  };
  return {
    isInitialize,
    getUuid
  };
};
let SAME_UUID = uuidv1();
export const freshUUID = () => SAME_UUID = uuidv1();
export const getSameUuid = () => SAME_UUID;
export const useUuidModel = createModel(useUuid);
