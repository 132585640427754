import {lazy} from "../../lib/lazy.js";
export const productShopeeCnscRoutes = [
  {
    path: "/product-shopee-cnsc",
    component: lazy(() => import(/* webpackChunkName: "productShopeeCNSCRoutes" */ "../../views/product-shopee-cnsc/list/index.js")),
    auth: "list-product"
  },
  {
    path: "/product-shopee-cnsc-add",
    component: lazy(() => import(/* webpackChunkName: "productShopeeCNSCRoutes" */ "../../views/product-shopee-cnsc/edit/add.js")),
    auth: "list-product"
  },
  {
    path: "/product-shopee-cnsc-edit/:id",
    component: lazy(() => import(/* webpackChunkName: "productShopeeCNSCRoutes" */ "../../views/product-shopee-cnsc/edit/edit.js")),
    auth: "list-product"
  },
  {
    path: "/product-shopee-cnsc-mass-edit-category-attribute",
    component: lazy(() => import(/* webpackChunkName: "productShopeeCNSCRoutes" */ "../../views/product-shopee-cnsc/mass-edit-category-attribute/index.js"))
  },
  {
    path: "/product-shopee-cnsc-mass-edit",
    component: lazy(() => import(/* webpackChunkName: "productShopeeCNSCRoutes" */ "../../views/product-shopee-cnsc/mass-edit/batch-update.js"))
  }
];
