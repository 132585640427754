import React from "../../../../__snowpack__/pkg/react.js";
export const IconMenuChannelCnsc = React.memo((props) => {
  return /* @__PURE__ */ React.createElement("svg", {
    width: "18px",
    height: "18px",
    viewBox: "0 0 18 18",
    version: "1.1",
    xmlns: "http://www.w3.org/2000/svg",
    ...props
  }, /* @__PURE__ */ React.createElement("title", null, "\u7F16\u7EC4"), /* @__PURE__ */ React.createElement("g", {
    id: "\u5546\u54C1",
    stroke: "none",
    "stroke-width": "1",
    fill: "none",
    "fill-rule": "evenodd"
  }, /* @__PURE__ */ React.createElement("g", {
    id: "\u5168\u7403\u5546\u54C1",
    transform: "translate(-16.000000, -237.000000)",
    fill: "#606C80",
    "fill-rule": "nonzero"
  }, /* @__PURE__ */ React.createElement("g", {
    id: "\u7F16\u7EC4-4",
    transform: "translate(16.000000, 237.000000)"
  }, /* @__PURE__ */ React.createElement("g", {
    id: "\u7F16\u7EC4",
    transform: "translate(1.000000, 1.000000)"
  }, /* @__PURE__ */ React.createElement("path", {
    d: "M8,0 C3.581722,0 0,3.581722 0,8 C0,12.418278 3.581722,16 8,16 C12.418278,16 16,12.418278 16,8 C16,5.87826808 15.1571453,3.84343678 13.6568542,2.34314575 C12.1565632,0.842854723 10.1217319,0 8,0 Z M14.344,7.2 L12,7.2 C11.9007381,5.35534347 11.3156432,3.56970008 10.304,2.024 C12.5005396,2.87444652 14.0524135,4.86268895 14.344,7.2 Z M5.6,8.8 L10.4,8.8 C10.3245425,10.8487297 9.46543804,12.7903059 8,14.224 C6.53456196,12.7903059 5.67545745,10.8487297 5.6,8.8 Z M5.6,7.2 C5.67545745,5.15127027 6.53456196,3.20969407 8,1.776 C9.46543804,3.20969407 10.3245425,5.15127027 10.4,7.2 L5.6,7.2 Z M5.704,2.024 C4.68849192,3.56839418 4.10052368,5.35438221 4,7.2 L1.6,7.2 C1.90883474,4.83976627 3.50205447,2.84593036 5.736,2.024 L5.704,2.024 Z M4,8.8 C4.10014499,10.6456891 4.68814491,12.4317734 5.704,13.976 C3.50434418,13.1279066 1.94903766,11.1392044 1.656,8.8 L4,8.8 Z M12,8.8 L14.4,8.8 C14.0911653,11.1602337 12.4979455,13.1540696 10.264,13.976 C11.2908258,12.434845 11.8899138,10.6486241 12,8.8 Z",
    id: "\u5F62\u72B6",
    fill: "currentColor"
  }))))));
});
