import {lazy} from "../../lib/lazy.js";
export const welcomeRoutes = [
  {
    path: "/welcome",
    component: lazy(() => import(/* webpackChunkName: "welcomeRoutes" */ "../../views/welcome/index.js"))
  },
  {
    path: "/sorry",
    component: lazy(() => import(/* webpackChunkName: "welcomeRoutes" */ "../../views/welcome/sorry.js"))
  }
];
