import "./i18n/moment-id.js";
import "./i18n/moment-zh-cn.js";
import "./i18n/moment-vi.js";
import "./i18n/moment-th.js";
import React, {useEffect, useReducer} from "../__snowpack__/pkg/react.js";
import {ConfigProvider, Modal} from "../__snowpack__/pkg/antd.js";
import antdLocaleEn from "../__snowpack__/pkg/antd/lib/locale/en_US.js";
import antdLocaleId from "../__snowpack__/pkg/antd/lib/locale/id_ID.js";
import antdLocaleTh from "../__snowpack__/pkg/antd/lib/locale/th_TH.js";
import antdLocaleVn from "../__snowpack__/pkg/antd/lib/locale/vi_VN.js";
import antdLocaleZh from "../__snowpack__/pkg/antd/lib/locale/zh_CN.js";
import moment from "../__snowpack__/pkg/moment-timezone.js";
import {IntlProvider} from "../__snowpack__/pkg/react-intl.js";
import {Route, Switch, useHistory} from "../__snowpack__/pkg/react-router-dom.js";
import * as intlMessages from "./locales/index.js";
import {ReactRouterScrollToTop} from "./components/ReactRouterScrollToTop.js";
import {findLocalTimeZoneName} from "./lib/country.js";
import {lazy} from "./lib/lazy.js";
import {useIsInIframe} from "./lib/useIframe.js";
import {routeList} from "./router/main.js";
import request from "./utils/request/index.js";
import {useLanguageModel} from "./store/language.js";
import Menu from "./views/menu/index.js";
const Login = lazy(() => import("./views/login/index.js"));
const Template = lazy(() => import("./views/template/OrderHtml.js"));
const InvoiceTemplate = lazy(() => import("./views/template/InvoiceOrderHtml.js"));
import queryString from "../__snowpack__/pkg/query-string.js";
import {ConfigProvider as ConfigProvider5} from "../__snowpack__/pkg/antd-v5.js";
import {
  DispatchLayoutContext,
  layoutInitialState,
  layoutReducer,
  LayoutStateContext,
  useLayoutState
} from "./store/layout.js";
import "./styles/index.css";
import "./index.css";
import {SessionHelper} from "../__snowpack__/pkg/@genie/lib-iam-utils.js";
import {useMount} from "../__snowpack__/pkg/ahooks.js";
import {toAccountsOpen, toAccounts} from "./views/login/index.js";
const momentLocaleMap = {
  en: "en",
  zh: "zh-cn",
  id: "id",
  th: "th",
  vn: "vi"
};
const antdLocaleMap = {
  en: antdLocaleEn,
  zh: antdLocaleZh,
  id: antdLocaleId,
  th: antdLocaleTh,
  vn: antdLocaleVn
};
export const App = () => {
  const [layoutStateRaw, dispatchLayoutStateRaw] = useReducer(layoutReducer, layoutInitialState);
  const {layoutState, dispatchLayoutState, setLanguage} = useLayoutState(layoutStateRaw, dispatchLayoutStateRaw);
  const {isInIframe} = useIsInIframe();
  const router = useHistory();
  const {language, setLanguage: setLanguageOld} = useLanguageModel();
  router.listen(() => {
    Modal.destroyAll();
  });
  useMount(() => {
    SessionHelper.installBridge({
      toAccountLoginPageOpen() {
        toAccountsOpen();
      },
      toAccountLoginPage() {
        toAccounts();
      }
    });
    const queries = queryString.parse(location.search.slice(1));
    const token = SessionHelper.getSessionToken();
    if (!token) {
      toAccounts(queries.target);
      return;
    }
    SessionHelper.recordSystemAccessEventLog("SYS_ERP");
  });
  const getCountryList = async () => {
    const data = await request.get("/infra/common/selector?type=CURRENCY");
    if (data.length > 0) {
      const countryList = data;
      if (countryList.length > 0) {
        localStorage.setItem("countryList", JSON.stringify(countryList));
      }
    }
  };
  const init = (language2) => {
    setLanguage(language2);
    setLanguageOld(language2);
    SessionHelper.setLanguage({vn: "vi"}[language2] ?? language2);
    localStorage.setItem("language", language2);
    moment.tz.setDefault(findLocalTimeZoneName());
    moment.locale(momentLocaleMap[language2]);
    const countryList = localStorage.getItem("countryList");
    if (!countryList) {
      getCountryList();
    }
  };
  useEffect(() => {
    if (isInIframe !== void 0 && !isInIframe) {
      localStorage.removeItem("notificationError");
      init(language);
    }
  }, [isInIframe, language]);
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(IntlProvider, {
    locale: language,
    messages: intlMessages[language],
    key: language
  }, /* @__PURE__ */ React.createElement(ConfigProvider5, {
    locale: antdLocaleMap[language],
    prefixCls: "ant5",
    theme: {
      token: {
        colorPrimary: "#6020ff",
        borderRadius: 3
      }
    }
  }, /* @__PURE__ */ React.createElement(ConfigProvider, {
    locale: antdLocaleMap[language]
  }, /* @__PURE__ */ React.createElement(DispatchLayoutContext.Provider, {
    value: dispatchLayoutState
  }, /* @__PURE__ */ React.createElement(LayoutStateContext.Provider, {
    value: layoutState
  }, /* @__PURE__ */ React.createElement(ReactRouterScrollToTop, null, /* @__PURE__ */ React.createElement(Switch, null, /* @__PURE__ */ React.createElement(Route, {
    key: "Login",
    exact: false,
    path: "/login"
  }, /* @__PURE__ */ React.createElement(Route, {
    component: Login
  })), /* @__PURE__ */ React.createElement(Route, {
    key: "template",
    exact: false,
    path: "/template"
  }, /* @__PURE__ */ React.createElement(Route, {
    component: Template
  })), /* @__PURE__ */ React.createElement(Route, {
    key: "invoiceTemplate",
    exact: false,
    path: "/invoice-template"
  }, /* @__PURE__ */ React.createElement(Route, {
    component: InvoiceTemplate
  })), /* @__PURE__ */ React.createElement(Menu, {
    init
  }, routeList.map((route) => {
    return /* @__PURE__ */ React.createElement(Route, {
      key: route.path,
      path: route.path,
      exact: true
    }, /* @__PURE__ */ React.createElement(Route, {
      component: route.component
    }));
  }))))))))));
};
