import {useState} from "../../__snowpack__/pkg/react.js";
import {handleError} from "./errorHandler.js";
export const useWrapSync = () => {
  const [loading, setLoading] = useState(false);
  const wrapSync = (val) => {
    return async (...args) => {
      if (loading) {
        return;
      }
      try {
        setLoading(true);
        const result = await val(...args);
        return result;
      } catch (error) {
        handleError(error);
      } finally {
        setLoading(false);
      }
    };
  };
  return {
    wrapSync,
    loading,
    setLoading
  };
};
