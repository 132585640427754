import {Modal} from "../../__snowpack__/pkg/antd.js";
import queryString from "../../__snowpack__/pkg/query-string.js";
import {createContext, useCallback, useContext, useMemo} from "../../__snowpack__/pkg/react.js";
import {useShops} from "../entities/useShops.js";
import {axios} from "../lib/axios.js";
import {localeMessage} from "../lib/i18n.js";
import {getSortChannelIdList} from "../lib/util.js";
import {purchasePackage} from "../lib/util.js";
import {SessionHelper} from "../../__snowpack__/pkg/@genie/lib-iam-utils.js";
import Cookies from "../../__snowpack__/pkg/js-cookie.js";
export const formatMessage = (info) => {
  let local = localStorage.getItem("language") || "id";
  return localeMessage[local][info.id];
};
export const DispatchLayoutContext = createContext(null);
export const LayoutStateContext = createContext(null);
export const defaultSideWidth = 220;
export const defaultSideWidthCollapsed = 42;
export const layoutInitialState = {
  sideWidth: defaultSideWidth,
  language: getDefaultLanguage()
};
export const layoutReducer = (state, action) => {
  switch (action.type) {
    case "setLanguage":
      return {...state, language: action.value};
    case "setSideWidth":
      return {...state, sideWidth: action.value};
    case "setBreadcrumb":
      return {...state, breadcrumb: action.value};
    case "setUserInfo":
      return {...state, userInfo: action.value};
    case "setPermissionMap":
      return {...state, permissionMap: action.value};
    case "setMerchantChannelIdMap":
      return {...state, merchantChannelIdMap: action.value};
    case "setMerchantChannelList":
      return {...state, setMerchantChannelList: action.value};
    case "setUserResourceVO":
      return {...state, userResource: action.value};
    case "setUserType":
      return {...state, userType: action.value};
    case "setMenuNumber":
      return {...state, menuNumber: action.value};
    case "setPerssionList":
      return {...state, perssionList: action.value};
    default:
      throw new Error();
  }
};
export const useLayoutState = (curState, curDispatch) => {
  const {fetchShops: fetchShopsRaw} = useShops();
  const state = curState ? curState : useContext(LayoutStateContext);
  const dispatch = curDispatch ? curDispatch : useContext(DispatchLayoutContext);
  const setLanguage = useMemo(() => {
    return (value) => {
      dispatch && dispatch({
        type: "setLanguage",
        value
      });
    };
  }, [dispatch]);
  const setSideWidth = useMemo(() => {
    return (value) => {
      dispatch && dispatch({
        type: "setSideWidth",
        value
      });
    };
  }, [dispatch]);
  const setBreadcrumb = useMemo(() => {
    return (value) => {
      dispatch && dispatch({
        type: "setBreadcrumb",
        value
      });
    };
  }, [dispatch]);
  const setUserInfo = useMemo(() => {
    return (value) => {
      dispatch && dispatch({
        type: "setUserInfo",
        value
      });
    };
  }, [dispatch]);
  const setPermissionMap = useMemo(() => {
    return (value) => {
      dispatch && dispatch({
        type: "setPermissionMap",
        value
      });
    };
  }, [dispatch]);
  const setMerchantChannelIdMap = useMemo(() => {
    return (value) => {
      dispatch && dispatch({
        type: "setMerchantChannelIdMap",
        value
      });
    };
  }, [dispatch]);
  const setMerchantChannelList = useMemo(() => {
    return (value) => {
      dispatch && dispatch({
        type: "setMerchantChannelList",
        value
      });
    };
  }, [dispatch]);
  const setPerssionList = useMemo(() => {
    return (value) => {
      dispatch && dispatch({
        type: "setPerssionList",
        value
      });
    };
  }, [dispatch]);
  const setMenuNumber = useMemo(() => {
    return (value) => {
      dispatch && dispatch({
        type: "setMenuNumber",
        value
      });
    };
  }, [dispatch]);
  const setUserResourceVO = useMemo(() => {
    return (value) => {
      dispatch && dispatch({
        type: "setUserResourceVO",
        value
      });
    };
  }, [dispatch]);
  const setUserType = useMemo(() => {
    return (value) => {
      dispatch && dispatch({
        type: "setUserType",
        value
      });
    };
  }, [dispatch]);
  const fetchMenuNumber = async () => {
    const {data} = await axios.post("/saas/dashboard/statistics/red-point", {
      type: ["ORDER"],
      needRefresh: true
    });
    if (data && data?.order && data?.order.length > 0) {
      const {order} = data;
      const numberObject = {};
      order.map((numberItem) => {
        numberObject[numberItem.tag] = numberItem.total;
      });
      setMenuNumber(numberObject);
    }
  };
  const fetchMerchantChannelIdMap = async () => {
    const {shops} = await fetchShopsRaw({
      page: 0,
      pageSize: 1e3
    }, {status: "CONNECTED"});
    const channelIdList = [];
    const result = shops.filter((_) => !["EXPIRED", "DISCONNECTED"].includes(_.connectStatus)).reduce((acc, cur) => {
      if (!channelIdList.includes(cur.channelId)) {
        channelIdList.push(cur.channelId);
      }
      acc[cur.channelId] = true;
      return acc;
    }, {});
    localStorage.setItem("formatChannelList", JSON.stringify(result));
    const sortChannelIdList = getSortChannelIdList(channelIdList);
    setMerchantChannelList(sortChannelIdList);
    setMerchantChannelIdMap(result);
  };
  const fetchPermissionMaps = async () => {
    return new Promise(async (resolve, reject) => {
      const initMap = {};
      let {
        data: {permissions, userResourceVO}
      } = await axios.get("/saas/user-permission/get");
      let newUserInfo = SessionHelper.getSessionUserInfo();
      setUserType(newUserInfo?.managementRole || "MAIN");
      setUserResourceVO(userResourceVO);
      setPerssionList(permissions);
      permissions?.forEach((item) => {
        if (item.authorityVisible && item.authorityOperable) {
          initMap[item.name] = 2;
        } else if (item.authorityVisible) {
          initMap[item.name] = 1;
        }
      });
      setPermissionMap({...initMap});
      if (permissions && permissions.length) {
        setPermissionMap({...initMap, ...{GineeResetPassword: 2}});
      }
      let seconds = 60 * 15;
      let expires = new Date(new Date() * 1 + seconds * 1e3);
      Cookies.set("permissionMapExpires", "true", {expires});
      window.localStorage.setItem("permissionMap", JSON.stringify({...initMap}));
      resolve(true);
    });
  };
  const fetchActiveTrial = async (nextTip, type) => {
    return new Promise(async (resolve) => {
      let {data} = await axios.post("/saas/user/active/trial-package");
      if (data) {
        Modal.success({
          content: formatMessage({id: "congratulationsYouHaveReceivedADay"}),
          okText: formatMessage({id: "tryNoww"}),
          onOk() {
            nextTip(type);
          }
        });
        resolve(true);
      } else {
        resolve(false);
      }
    });
  };
  const fetchPermissionNewMaps = async () => {
    let permissionMaps = await SessionHelper.getPermissionNameMap("SYS_ERP");
    let permissions = Object.keys(permissionMaps).map((i) => ({
      name: i
    }));
    setPerssionList(permissions);
    setPermissionMap({...permissionMaps});
    if (permissions && permissions.length) {
      setPermissionMap({...permissionMaps, ...{GineeResetPassword: 2}});
    }
    let seconds = 60 * 15;
    let expires = new Date(new Date() * 1 + seconds * 1e3);
    Cookies.set("permissionMapExpires", "true", {expires});
    window.localStorage.setItem("permissionMap", JSON.stringify({...permissionMaps}));
  };
  const withPermission = useCallback((permission) => {
    let cppermissionMap = state?.permissionMap || {};
    if (!permission) {
      return true;
    }
    if (typeof permission === "string") {
      return cppermissionMap[permission] && cppermissionMap[permission] >= 1;
    } else {
      let hasPer = false;
      permission?.forEach((item) => {
        hasPer = cppermissionMap[item] && cppermissionMap[item] >= 1;
      });
      return hasPer;
    }
  }, [state?.permissionMap]);
  const withPermissionAll = (permission) => {
    let cppermissionMap = state?.permissionMap || {};
    if (typeof permission === "string") {
      return cppermissionMap[permission] && cppermissionMap[permission] >= 1;
    } else {
      let hasPer = true;
      permission?.forEach((item) => {
        if (!cppermissionMap[item]) {
          hasPer = false;
        }
      });
      return hasPer;
    }
  };
  const PermissionClickAlert = (permission, userType) => {
    if (!permission) {
      return true;
    }
    let newUserInfo = SessionHelper.getSessionUserInfo() ?? {managementRole: "MAIN"};
    let cppermissionMap = state?.permissionMap || {};
    console.log("cppermissionMap", cppermissionMap, permission);
    if (typeof permission === "string") {
      if (cppermissionMap[permission] && cppermissionMap[permission] === 2) {
        return true;
      } else {
        if (newUserInfo.managementRole !== "MAIN") {
          Modal.error({
            title: formatMessage({id: "hint"}),
            content: formatMessage({id: "staffNotAuthority"}),
            okText: formatMessage({id: "confirm"})
          });
        } else {
          Modal.confirm({
            title: formatMessage({id: "hint"}),
            content: formatMessage({id: "becauseTheNumberOfStoresExceeds"}),
            okText: formatMessage({id: "confirm"}),
            cancelText: formatMessage({id: "subscriptionPackage"}),
            onCancel() {
              purchasePackage();
            }
          });
        }
        return false;
      }
    } else {
      let hasPer = false;
      permission?.forEach((item) => {
        hasPer = cppermissionMap[item] && cppermissionMap[item] === 2;
      });
      if (hasPer) {
        return true;
      } else {
        if (newUserInfo.managementRole && newUserInfo.managementRole === "SUB") {
          Modal.error({
            title: formatMessage({id: "hint"}),
            content: formatMessage({id: "staffNotAuthority"}),
            okText: formatMessage({id: "confirm"})
          });
        } else {
          Modal.confirm({
            title: formatMessage({id: "hint"}),
            content: formatMessage({id: "becauseTheNumberOfStoresExceeds"}),
            okText: formatMessage({id: "confirm"}),
            cancelText: formatMessage({id: "subscriptionPackage"}),
            onCancel() {
              purchasePackage();
            }
          });
        }
      }
    }
  };
  const PermissionCheckRouter = (permission) => {
    if (!permission) {
      return true;
    }
    const permissionMap = localStorage.getItem("permissionMap");
    if (!permissionMap) {
      return true;
    }
    let cppermissionMap = JSON.parse(permissionMap || "{}");
    if (typeof permission === "string") {
      if (cppermissionMap[permission] && cppermissionMap[permission] === 2) {
        return true;
      } else {
        return false;
      }
    } else {
      let hasPer = false;
      permission?.forEach((item) => {
        hasPer = cppermissionMap[item] && cppermissionMap[item] === 2;
      });
      if (hasPer) {
        return true;
      } else {
        return false;
      }
    }
  };
  const getPermissionMap = () => {
    const permissionMapStr = localStorage.getItem("permissionMap");
    if (permissionMapStr) {
      return JSON.parse(permissionMapStr);
    } else {
      return {};
    }
  };
  const withPermissionClick = (permission) => {
    let localStoragePermissionMap = JSON.parse(localStorage.getItem("permissionMap") || "{}");
    let cppermissionMap = state?.permissionMap || localStoragePermissionMap || {};
    return cppermissionMap[permission] && cppermissionMap[permission] === 2;
  };
  const withPermissionMap = (permission) => {
    let perssionList = state?.perssionList || {};
    return perssionList?.find((_) => _.name === permission);
  };
  const withPermissionShow = (permission) => {
    let cppermissionMap = state?.permissionMap || {};
    return cppermissionMap[permission] && cppermissionMap[permission] >= 1;
  };
  return {
    language: state?.language,
    layoutState: state,
    userInfo: state?.userInfo,
    PermissionMap: state?.permissionMap || {},
    userType: state?.userType,
    userResource: state?.userResource || {},
    menuNumber: state?.menuNumber || {},
    setLanguage,
    getPermissionMap,
    dispatchLayoutState: dispatch,
    setSideWidth,
    setBreadcrumb,
    setUserInfo,
    fetchPermissionNewMaps,
    fetchPermissionMaps,
    setPermissionMap,
    withPermission,
    withPermissionAll,
    withPermissionClick,
    withPermissionMap,
    withPermissionShow,
    PermissionClickAlert,
    PermissionCheckRouter,
    setMerchantChannelIdMap,
    fetchMerchantChannelIdMap,
    fetchMenuNumber,
    fetchActiveTrial
  };
};
function getDefaultLanguage() {
  const {query} = queryString.parseUrl(location.href);
  const languageQuery = query.language;
  const languageLocalStorage = localStorage.getItem("language");
  return languageQuery || languageLocalStorage || "en";
}
