import {lazy} from "../../lib/lazy.js";
export const addProductToWarehouseRoutes = [
  {
    path: "/add-product-to-warehouse",
    component: lazy(() => import(/* webpackChunkName: "addProductToWarehouseRoutes" */ "../../views/add-product-to-warehouse/index.js")),
    auth: "list-msku-stock"
  },
  {
    path: "/add-master-product-to-warehouse",
    component: lazy(() => import(/* webpackChunkName: "addProductToWarehouseRoutes" */ "../../views/add-product-to-warehouse/MasterProductToWareHouse.js")),
    auth: "list-msku-stock"
  },
  {
    path: "/add-product-to-warehouse-edit-stock",
    component: lazy(() => import(/* webpackChunkName: "addProductToWarehouseRoutes" */ "../../views/add-product-to-warehouse/edit-stock.js")),
    auth: "list-msku-stock"
  }
];
