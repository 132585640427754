import {lazy} from "../../lib/lazy.js";
export const fulfillRoutes = [
  {
    path: "/fulfill-setting",
    component: lazy(() => import(/* webpackChunkName: "fulfillRoutes" */ "../../views/fulfill/fulfillSetting.js")),
    auth: "integrations-store"
  },
  {
    path: "/keeppack-fulfillment",
    component: lazy(() => import(/* webpackChunkName: "fulfillRoutes" */ "../../views/fulfill/includes/KeeppackFulfillSetting/index.js"))
  },
  {
    path: "/kongfu-fulfillment",
    component: lazy(() => import(/* webpackChunkName: "fulfillRoutes" */ "../../views/fulfill/includes/KongfuFulfillSetting/index.js"))
  }
];
