import {lazy} from "../../lib/lazy.js";
export const warehouseShelfManagementRoutes = [
  {
    path: "/product-location-management",
    auth: "shelf-management",
    component: lazy(() => import(/* webpackChunkName: "warehouseShelfManagementRoutes" */ "../../views/warehouse-shelf-management/index.js")),
    meta: {
      openKeys: ["menu-sub-warehouse"]
    }
  }
];
