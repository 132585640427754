import React from "../../../../__snowpack__/pkg/react.js";
export const IconSync = React.memo((props) => {
  return /* @__PURE__ */ React.createElement("svg", {
    xmlns: "http://www.w3.org/2000/svg",
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    ...props
  }, /* @__PURE__ */ React.createElement("g", {
    fill: "none",
    fillRule: "evenodd",
    stroke: "none",
    strokeWidth: "1"
  }, /* @__PURE__ */ React.createElement("g", {
    transform: "translate(-1021 -16)"
  }, /* @__PURE__ */ React.createElement("g", {
    transform: "translate(844 8)"
  }, /* @__PURE__ */ React.createElement("g", {
    transform: "translate(169)"
  }, /* @__PURE__ */ React.createElement("g", {
    transform: "translate(8 8)"
  }, /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", {
    d: "M0 0H16V16H0z"
  }), /* @__PURE__ */ React.createElement("path", {
    fill: "currentColor",
    fillRule: "nonzero",
    d: "M12.656 13c.959-.02 1.75-.327 2.375-.921.625-.595.948-1.33.969-2.208-.02-.838-.323-1.544-.906-2.12-.584-.575-1.313-.901-2.188-.98-.25-1.09-.823-1.987-1.719-2.69C10.293 3.382 9.23 3.02 8 3c-.98.02-1.854.258-2.625.716a4.998 4.998 0 00-1.813 1.828c-1.02.117-1.864.516-2.53 1.199C.364 7.425.02 8.263 0 9.257c.02 1.053.411 1.93 1.172 2.632C1.932 12.59 2.875 12.96 4 13h8.656zm-2.312-2.924l-.969-.906c.23-.312.323-.649.281-1.01a1.577 1.577 0 00-.469-.95A1.868 1.868 0 008 6.744v1.14l-.906-.877-.969-.907L8 4.345v1.17c.396 0 .776.073 1.14.219.365.146.693.346.985.6.542.526.833 1.135.875 1.827.042.692-.177 1.33-.656 1.915zm-3 2.193v-1.14c-.417 0-.808-.073-1.172-.22a3.03 3.03 0 01-.953-.6c-.542-.545-.834-1.159-.875-1.841-.042-.682.177-1.316.656-1.9l.938.876a1.617 1.617 0 00-.266 1.024c.031.35.193.663.484.936.334.311.73.467 1.188.467v-1.11l1.875 1.754-1.875 1.754z"
  }))))))));
});
