import jsCookie from "../../__snowpack__/pkg/js-cookie.js";
import {v4 as uuid} from "../../__snowpack__/pkg/uuid.js";
import {eventLogger as eventLoggerNew} from "../../__snowpack__/pkg/@genie/event-logger/dist/adaptors/saas.js";
export function getDomain() {
  const result = document.domain.match(/[^.]*\.[^.]*$/);
  return result ? result[0] : void 0;
}
export function getTrackId() {
  if (!jsCookie.get("trackId")) {
    jsCookie.set("trackId", uuid().replace(/-/g, ""), {
      domain: getDomain(),
      path: "/",
      expires: new Date(Date.now() + 3600 * 1e3 * 24 * 365 * 20)
    });
  }
  return jsCookie.get("trackId");
}
const key = "updatable";
class EventLogger {
  constructor() {
    this.push = async (event) => {
      await eventLoggerNew.pushOld(event);
    };
  }
}
export const eventLogger = new EventLogger();
