import React from "../../__snowpack__/pkg/react.js";
export const ROUND_ICON_MAP = {
  AKULAKU_ID: "/images/icon-channel-round/akulaku.svg",
  BLIBLI_ID: "/images/icon-channel-round/blibli.svg",
  BUKALAPAK_ID: "/images/icon-channel-round/bukalapak.svg",
  JD_V2: "/images/icon-channel-round/jd.svg",
  JD_ID: "/images/icon-channel-round/jd_id.svg",
  JD_TH: "/images/icon-channel-round/jd_th.png",
  LAZADA_ID: "/images/icon-channel-round/lazada.svg",
  LAZADA_CHOICE: "/images/icon-channel-round/LazadaChoice.svg",
  SHOPEE_ID: "/images/icon-channel-round/shopee.svg",
  SHOPIFY_ID: "/images/icon-channel-round/shopify.svg",
  TIKI_ID: "/images/icon-channel-round/tiki.svg",
  TIKTOK_ID: "/images/icon-channel-round/tiktok.svg",
  TOKOPEDIA_ID: "/images/icon-channel-round/tokopedia.svg",
  WOO_COMMERCE_ID: "/images/icon-channel-round/woo-commerce.svg",
  MAGENTO_ID: "/images/icon-channel-round/Magento.svg",
  WIX_ID: "/images/icon-channel-round/Wix.svg",
  ZALORA_ID: "/images/icon-channel-round/zalora.svg",
  ALIEXPRESS_ID: "/images/icon-channel-round/aliexpress.svg",
  JAKMALL_ID: "/images/icon-channel-round/jakmall.svg",
  MOKA_POS_ID: "/images/icon-channel-round/moka.svg",
  LINE_SHOPPING_TH: "/images/icon-channel-round/line_shopping.svg",
  GINEE_POS: "/images/icon-channel-round/ginee-pos.svg",
  OTHER: "/images/icon-channel-round/other.svg"
};
const ICONS_MAP = {
  round: ROUND_ICON_MAP
};
export default function ChannelIcon(props) {
  const {channelId, size = 14, type = "round", ...imgProps} = props;
  const src = ICONS_MAP[type][channelId] ?? ICONS_MAP[type]?.OTHER;
  return /* @__PURE__ */ React.createElement("img", {
    src,
    ...imgProps,
    style: {
      display: "inline-block",
      width: size,
      height: size,
      ...imgProps.style
    }
  });
}
