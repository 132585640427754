import "./assets/menu.css";
import React, {useEffect, useRef, useState, useMemo} from "../../../__snowpack__/pkg/react.js";
import {useLocation} from "../../../__snowpack__/pkg/react-router-dom.js";
import {usePostError} from "../../entities/usePostError.js";
import {Button, Dropdown, Layout, Menu, message, Modal, Popover} from "../../../__snowpack__/pkg/antd.js";
import {useIntl} from "../../../__snowpack__/pkg/react-intl.js";
import jsCookie from "../../../__snowpack__/pkg/js-cookie.js";
import jsonpack from "../../../__snowpack__/pkg/jsonpack.js";
import {CommitErrorModal} from "../../components/CommitErrorModal.js";
import {ErrorModalTip} from "../../components/ErrorModalTip.js";
import styled from "../../../__snowpack__/pkg/styled-components.js";
import PackageInfo from "./includes/PackageInformation.js";
import {DownOutlined} from "../../../__snowpack__/pkg/@ant-design/icons.js";
import {useIframeHook} from "../../../__snowpack__/pkg/@genie/amz-iframe/dist/react/useIframe.js";
import {toAccounts} from "../login/index.js";
import collapsedSvg from "./assets/collapsed.svg";
import {SiderLogo} from "./includes/SiderLogo.js";
import {SiderMenu} from "./includes/SiderMenu.js";
import {SiderService} from "./includes/SiderService.js";
import {SyncButton} from "./includes/SyncButton.js";
import {SetMessage} from "./includes/SetMessage.js";
import VideoPng from "../../assets/video.png";
import HelpPng from "../../assets/help.png";
import FormPng from "../../assets/form.png";
import copy from "../../../__snowpack__/pkg/copy-to-clipboard.js";
import {useCheckUserType} from "../../entities/useCheckUserType.js";
import {defaultSideWidth, defaultSideWidthCollapsed, useLayoutState} from "../../store/layout.js";
import {IconHelp} from "./assets/IconHelp.js";
import {ChangePasswordModal, useChangePasswordModal} from "./includes/ChangePasswordModal.js";
import {HeaderBreadcrumb} from "./includes/HeaderBreadcrumb.js";
import {eventLogger} from "../../lib/eventLogger.js";
import {request} from "../../utils/request/index.js";
import {useRequest, useNetwork} from "../../../__snowpack__/pkg/ahooks.js";
import {SessionHelper} from "../../../__snowpack__/pkg/@genie/lib-iam-utils.js";
import NetWorkError from "./includes/NetworkError.js";
import NetworkErrorImg from "./assets/networkError.png";
import WordCasting from "../../components/WordCasting/index.js";
import Cookies from "../../../__snowpack__/pkg/js-cookie.js";
import {TaskCenter} from "./includes/TaskCenter.js";
const {Header, Sider, Content} = Layout;
const LANGS = {
  id: "Bahasa",
  en: "English",
  zh: "\u4E2D\u6587\u7B80\u4F53",
  th: "\u0E20\u0E32\u0E29\u0E32\u0E44\u0E17\u0E22",
  vn: "Vi\u1EC7t Nam"
};
const WrapperHeader = styled.div`
  position: fixed;
  right: 8px;
  bottom: 360px;
  z-index: 9999;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 40px;
  border-radius: 4px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.12);

  .status-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 286px;
    height: 100%;
    background: #6020ff;
  }
  .control-bar {
    display: flex;
    position: fixed;
    top: 0;
    left: 50%;
    height: 32px;
    width: 438px;
    margin-left: -219px;
    border-radius: 4px;
    overflow: hidden;
    font-size: 14px;
    font-weight: 500;
    color: #fff;
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.1);
  }

  .action-wrapper {
    position: relative;
    height: 100%;
    width: 152px;
    background: #ee4d2d;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    .recording-icon {
      width: 14px;
      position: relative;
      margin-right: 4px;
    }
  }
  .action-wrapper .recording-icon:before {
    position: absolute;
    z-index: 9999;
    top: 50%;
    left: 50%;
    width: 4px;
    height: 4px;
    margin-left: -2px;
    margin-top: -2px;
    border-radius: 50%;
    background: #fff;
    content: '';
    -webkit-animation: 1.5s ease-in-out infinite alternate record-icon-flashing-before-data-v-2c22e97e;
    animation: 1.5s ease-in-out infinite alternate record-icon-flashing-before-data-v-2c22e97e;
  }
  .action-wrapper .recording-icon:after {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 14px;
    height: 14px;
    margin-left: -6.6px;
    margin-top: -6.6px;
    border: 1.33px solid #fff;
    border-radius: 50%;
    background: hsla(0, 0%, 100%, 0);
    content: '';
    -webkit-animation: 1.5s ease-in-out infinite alternate record-icon-flashing-after;
    animation: 1.5s ease-in-out infinite alternate record-icon-flashing-after;
  }

  @keyframes record-icon-flashing-before {
    40%,
    50% {
      background: #ee4d2d;
    }
  }

  @keyframes record-icon-flashing-after {
    40%,
    50% {
      background: #fff;
    }
  }
`;
export const GineeMenu = (props) => {
  const {submitError} = usePostError();
  const netWorkStatus = useNetwork();
  const [isVisible, setIsVisible] = useState(false);
  const {pathname} = useLocation();
  const [isStart, setIsStart] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const {sendMessageToChildren} = useIframeHook("amzIframeOldElIframe");
  const {formatMessage} = useIntl();
  const [state, setState] = useState({
    collapsed: false
  });
  const {changePasswordModalVisible, setChangePasswordModalVisible} = useChangePasswordModal();
  const {language, layoutState, dispatchLayoutState, setUserInfo, fetchMerchantChannelIdMap, withPermission} = useLayoutState();
  const scrollIntersectionObserverEl = useRef(null);
  const isMobileRoute = useMemo(() => {
    return pathname.match(/-mobile$/);
  }, [pathname]);
  const toggle = () => {
    dispatchLayoutState({
      type: "setSideWidth",
      value: state.collapsed ? defaultSideWidth : defaultSideWidthCollapsed
    });
    setState({
      collapsed: !state.collapsed
    });
  };
  const contentStyle = {
    margin: 0,
    height: "46px",
    color: "#333",
    lineHeight: "50px",
    textAlign: "left",
    background: "#fff",
    flex: "1",
    width: "auto"
  };
  const {initPermission} = useCheckUserType();
  const {run: logout} = useRequest(() => request.post("/infra/iam/account/sign-out", {}, {
    handleError: () => {
    }
  }), {
    manual: true,
    onFinally: () => {
      sendMessageToChildren({
        event: "logout"
      });
      const language2 = localStorage.language;
      jsCookie.remove("token");
      window.localStorage.removeItem("updateModalVisible");
      localStorage.setItem("language", language2 || "id");
      localStorage.setItem("countryList", JSON.stringify([]));
      SessionHelper.sendEvent("logout");
      SessionHelper.removeSessionToken();
      setTimeout(() => {
        toAccounts();
      }, 10);
    }
  });
  const fetchUserInfo = async (noInitUser = false) => {
    if (!SessionHelper.getSessionToken()) {
      return;
    }
    let oldUserInfo = localStorage.getItem("userInfo");
    let newUserInfo = SessionHelper.getSessionUserInfo();
    if (oldUserInfo) {
      let oldUserInfoJson = JSON.parse(oldUserInfo);
      if (oldUserInfoJson.userId !== newUserInfo?.userId) {
        localStorage.removeItem("permissionMap");
        localStorage.removeItem("planCurrentDate");
        localStorage.removeItem("todoShopList");
        Cookies.remove("setDisplayInfoTime");
        Cookies.remove("setDisplayInfo");
        localStorage.setItem("userInfo", JSON.stringify(newUserInfo));
      }
    }
    setUserInfo(newUserInfo);
  };
  const tipContent = /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement("p", null, formatMessage({id: "PleaseCheckWhetherYouAre"})), /* @__PURE__ */ React.createElement("p", null, formatMessage({id: "PleaseConfirmThatTheBrowser"})), /* @__PURE__ */ React.createElement("p", null, formatMessage({id: "PleaseSwitchTheNetworkAnd"})), /* @__PURE__ */ React.createElement("p", null, formatMessage({id: "IfEverythingIsNormalPlease"})), /* @__PURE__ */ React.createElement("p", null, formatMessage({id: "IfYouHaveDoneThe"})));
  const start = () => {
    Modal.confirm({
      title: formatMessage({id: "needAuthorization"}),
      okText: formatMessage({id: "approveStartToRecord"}),
      cancelText: formatMessage({id: "cancel"}),
      content: /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement("div", null, formatMessage({id: "weNeedYourConsentToRecord"})), /* @__PURE__ */ React.createElement("div", null, formatMessage({id: "weOnlyRecordThePagesIn"}))),
      onOk() {
        window.events = [];
        setIsStart(true);
        window.stopFn = window.rrweb.record({
          emit(event) {
            window.events.push(event);
          },
          packFn: window.rrweb.pack
        });
      }
    });
  };
  const stop = () => {
    setIsLoading(true);
    window.stopFn && window.stopFn();
    setTimeout(async () => {
      var packed = jsonpack.pack(window.events);
      let params = {
        title: "FE_VIDEO_DIAGNOSIS",
        description: "page encountered exception and submit video",
        videoString: packed,
        ticketSource: "SAAS_SUBMIT_VIDEO_MANUALLY"
      };
      const data = await submitError(params);
      setIsStart(false);
      setIsLoading(false);
      Modal.info({
        title: formatMessage({id: "done"}),
        okText: formatMessage({id: "copyCopy"}),
        closable: true,
        content: /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement("div", null, formatMessage({id: "thankYouForYourCooperationPlease"})), /* @__PURE__ */ React.createElement("div", {
          style: {color: "#6020ff", background: "#ddd", borderRadius: 4, padding: "2px 4px", marginTop: 10}
        }, data)),
        onOk() {
          copy(data);
          message.success("Success");
        }
      });
    }, 1);
  };
  useEffect(() => {
    fetchUserInfo();
    initPermission(false, []);
    import("../../../__snowpack__/pkg/@genie/lib-iam-utils/dist/components/UserInfoPanel.js").then(() => {
    });
  }, []);
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(CommitErrorModal, {
    formatMessage,
    isVisible,
    onCancel: () => {
      setIsVisible(false);
    },
    onOk: () => {
      setIsVisible(false);
    },
    setExportResultsVisible: setIsVisible
  }), /* @__PURE__ */ React.createElement(ErrorModalTip, {
    formatMessage,
    isVisible,
    setExportResultsVisible: setIsVisible
  }), isStart ? /* @__PURE__ */ React.createElement(WrapperHeader, null, /* @__PURE__ */ React.createElement("div", {
    className: "control-bar "
  }, /* @__PURE__ */ React.createElement("div", {
    className: "status-wrapper"
  }, formatMessage({id: "capturingTheProcessingFlowOnThe"})), /* @__PURE__ */ React.createElement("div", {
    className: "shopee-popover shopee-popover--light"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "action-wrapper",
    onClick: () => {
      if (isLoading) {
        return;
      }
      stop();
    }
  }, /* @__PURE__ */ React.createElement("div", {
    className: "recording-icon"
  }), /* @__PURE__ */ React.createElement("div", null, isLoading ? formatMessage({id: "uploading"}) : formatMessage({id: "stop"})))))) : /* @__PURE__ */ React.createElement(React.Fragment, null), !isMobileRoute && /* @__PURE__ */ React.createElement(Wrapper, null, /* @__PURE__ */ React.createElement(Sider, {
    theme: "light",
    width: defaultSideWidth,
    trigger: null,
    collapsible: true,
    collapsed: state.collapsed
  }, /* @__PURE__ */ React.createElement(SiderLogo, {
    collapsed: state.collapsed
  }), /* @__PURE__ */ React.createElement(SiderMenu, null), /* @__PURE__ */ React.createElement(SiderService, {
    collapsed: state.collapsed
  })), /* @__PURE__ */ React.createElement(Header, {
    style: {
      paddingLeft: state.collapsed ? `${defaultSideWidthCollapsed}px` : `${defaultSideWidth}px`
    }
  }, /* @__PURE__ */ React.createElement("div", {
    className: "header-left"
  }, /* @__PURE__ */ React.createElement("img", {
    className: "header-toggle-icon",
    src: collapsedSvg,
    onClick: () => {
      toggle();
    }
  }), layoutState.breadcrumb ? /* @__PURE__ */ React.createElement("span", {
    style: {marginLeft: "24px"}
  }, layoutState.breadcrumb) : /* @__PURE__ */ React.createElement(HeaderBreadcrumb, null)), /* @__PURE__ */ React.createElement(WordCasting, null), /* @__PURE__ */ React.createElement("div", {
    className: "header-right"
  }, /* @__PURE__ */ React.createElement(SetMessage, {
    getUserInfo: () => {
      fetchUserInfo(true);
      fetchMerchantChannelIdMap();
    }
  }), /* @__PURE__ */ React.createElement(TaskCenter, null), /* @__PURE__ */ React.createElement("span", {
    className: "header-right-divider",
    style: {margin: "0 12px"}
  }, "|"), withPermission("sync-store") && /* @__PURE__ */ React.createElement(SyncButton, null), /* @__PURE__ */ React.createElement(PackageInfo, null), /* @__PURE__ */ React.createElement(Dropdown, {
    overlay: /* @__PURE__ */ React.createElement(Menu, {
      onClick: (val) => {
        switch (val.key) {
          case "help":
            eventLogger.push({
              action: "ClickHelpCenter",
              location: "TopNavigation"
            });
            window.open("https://ginee.com/help");
            break;
          case "video":
            start();
            break;
          case "form":
            setIsVisible(true);
            break;
        }
      }
    }, /* @__PURE__ */ React.createElement(Menu.Item, {
      style: {display: "flex", alignItems: "center"},
      key: "help"
    }, /* @__PURE__ */ React.createElement("img", {
      src: HelpPng,
      style: {width: 12, marginRight: 4}
    }), formatMessage({id: "helpInfo"})), /* @__PURE__ */ React.createElement(Menu.Item, {
      style: {display: "flex", alignItems: "center"},
      key: "form"
    }, /* @__PURE__ */ React.createElement(Popover, {
      placement: "left",
      content: formatMessage({id: "pleaseSubmitATicektFormWith"}),
      trigger: "hover"
    }, /* @__PURE__ */ React.createElement("img", {
      style: {width: 12, marginRight: 4},
      src: FormPng
    }), formatMessage({id: "feedback"}))), /* @__PURE__ */ React.createElement(Menu.Item, {
      key: "video",
      style: {display: "flex", alignItems: "center"}
    }, /* @__PURE__ */ React.createElement(Popover, {
      placement: "left",
      content: formatMessage({id: "enterTheSpecificPageStartTo"}),
      trigger: "hover"
    }, /* @__PURE__ */ React.createElement("img", {
      src: VideoPng,
      style: {width: 12, marginRight: 4}
    }), formatMessage({id: "uploadVideo"}))))
  }, /* @__PURE__ */ React.createElement(Button, {
    className: "header-right-button",
    target: "_blank",
    type: "link"
  }, /* @__PURE__ */ React.createElement(IconHelp, {
    style: {marginRight: 4, color: "#8C99AC"}
  }), formatMessage({id: "faqfeedback"}))), /* @__PURE__ */ React.createElement("span", {
    className: "header-right-divider"
  }, "|"), /* @__PURE__ */ React.createElement(Dropdown, {
    overlay: /* @__PURE__ */ React.createElement(Menu, {
      onClick: (val) => {
        SessionHelper.setLanguage(val.key);
        props.init(val.key);
      }
    }, Object.keys(LANGS).map((_) => {
      return /* @__PURE__ */ React.createElement(Menu.Item, {
        key: _
      }, LANGS[_]);
    }))
  }, /* @__PURE__ */ React.createElement(Button, {
    className: "header-right-button",
    type: "link"
  }, LANGS[language], " ", /* @__PURE__ */ React.createElement(DownOutlined, null))), /* @__PURE__ */ React.createElement("span", {
    className: "header-right-divider",
    style: {marginRight: 12}
  }, "|"), /* @__PURE__ */ React.createElement("iam-user-panel", {
    key: "user-panel"
  }))), /* @__PURE__ */ React.createElement(Content, {
    className: "menu-content",
    style: {
      paddingLeft: state.collapsed ? `${defaultSideWidthCollapsed}px` : `${defaultSideWidth}px`
    }
  }, /* @__PURE__ */ React.createElement("div", {
    ref: scrollIntersectionObserverEl,
    className: "scroll-intersection-observer"
  }), netWorkStatus?.online ? props.children : /* @__PURE__ */ React.createElement(NetWorkError, {
    formatMessage,
    img: NetworkErrorImg,
    tipContent
  }))), isMobileRoute && /* @__PURE__ */ React.createElement(React.Fragment, null, props.children), /* @__PURE__ */ React.createElement(ChangePasswordModal, {
    visible: changePasswordModalVisible,
    onChange: () => {
      setChangePasswordModalVisible(false);
    },
    onCancel: () => {
      setChangePasswordModalVisible(false);
    }
  }));
};
const Wrapper = styled.div`
  .ant-carousel {
    flex: 1;
  }
  .scroll-intersection-observer {
    width: 100%;
    height: 0px;
  }

  .logo-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 48px;
    background: #ffffff;
    box-shadow: 0px -1px 0px 0px #dfe3e8;
    border-bottom: 1px solid #dfe3e8;
    padding-left: 16px;
    .logo {
      width: 130px;
      height: 37px;
    }
    .logo-small {
      width: 24px;
      height: 36px;
    }
  }
  .logo-wrapper-collapsed {
    justify-content: center;
    padding-left: 0;
  }
  .menu-icon {
    width: 18px;
    height: 18px;
    margin-right: 8px;
  }
  .header-left,
  .header-right {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .header-left {
    min-width: 30px;
  }
  .header-button-plan-info {
    display: flex;
    justify-content: center;
    align-items: center;
    .anticon {
      display: flex;
      margin-left: 4px;
    }
  }
  .header-right-button {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #606c80;
    margin-left: 8px;
    margin-right: 8px;
    padding-left: 8px;
    padding-right: 8px;
    &:hover {
      background-color: #f5f7fa;
    }
  }
  .header-right-button-pro {
    border-radius: 20px;
    margin-left: 8px;
    margin-right: 16px;
  }
  .header-toggle-icon {
    cursor: pointer;
    width: 24px;
    height: 24px;
  }
  .header-breadcrumb {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 24px;
  }
  .header-right-divider {
    color: #dfe3e8;
    line-height: 24px;
  }
  .buttom {
    width: 100%;
    position: absolute;
    bottom: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 12px;
    padding-right: 12px;
    .button-service {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 34px;
      background: #f0f2f5;
      border-radius: 21px;
      font-size: 14px;
      font-family: SFProText-Regular, SFProText;
      font-weight: 400;
      color: #455166;
      line-height: 18px;
    }
  }
  .buttom-collapsed {
    padding-left: 4px;
    padding-right: 4px;
  }
  /* overload */
  .ant-layout-sider-children {
    position: relative;
  }
  .ant-layout-sider-children > .ant-menu {
    height: calc(100vh - 48px);
    overflow-x: hidden;
    overflow-y: overlay;
    padding-bottom: 40px;

    &::-webkit-scrollbar {
      width: 8px;
      background: unset;
    }

    &::-webkit-scrollbar-thumb {
      background-color: unset;
      border-radius: 4px;
    }

    &:hover::-webkit-scrollbar-thumb:hover,
    &:hover::-webkit-scrollbar-thumb:active {
      background-color: rgba(0, 0, 0, 0.35);
    }

    &:hover::-webkit-scrollbar-thumb {
      background-color: rgba(0, 0, 0, 0.15);
    }
  }
  .ant-menu {
    padding-top: 4px;
    background: #f7f8fa;
    box-shadow: -1px 0px 0px 0px #d9d9d9;
  }
  .ant-layout-sider {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 101;
  }
  .ant-layout-header {
    position: fixed;
    width: 100%;
    top: 0;
    right: 0;
    z-index: 100;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 48px;
    background: #ffffff;
    padding-left: 16px;
    padding-right: 16px;
    box-shadow: 0px -1px 0px 0px #dfe3e8;
    border-bottom: 1px solid #dfe3e8;
  }
  .menu-content {
    padding-top: 48px;
  }
  .menu-badge-title {
    display: flex;
    flex: 1;
    padding-right: 32px;
    justify-content: space-between;
    align-items: center;
  }
  .menu-badge {
    display: inline-flex;
    width: 28px;
    height: 14px;
    background: #f5222d;
    border-radius: 7px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;

    font-family: SFProText-Regular, SFProText;
    font-weight: 400;
    color: #ffffff;
    line-height: 14px;
  }
  .menu-item-l1,
  .menu-sub-l1 > .ant-menu-submenu-title {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 0 !important;
    padding: 0 !important;
    padding-left: 14px !important;
    height: 42px !important;
    line-height: 42px !important;
    font-size: 14px;
    font-family: SFProText-Regular, SFProText;
    font-weight: 400;
    color: #455166;
    line-height: 18px;
  }
  .menu-item-l1.ant-menu-item-selected,
  .menu-sub-l1.ant-menu-submenu-selected > .ant-menu-submenu-title {
    font-size: 14px;
    font-family: SFProText-Medium, SFProText;
    font-weight: 500;
    color: #6020ff;
    line-height: 18px;
    .fill-name-box path {
      fill: #6020ff;
    }
  }
  .menu-item-l1.ant-menu-item-selected {
    background-color: #e3e8f0;
  }
  .ant-menu-inline-collapsed .menu-sub-l1.ant-menu-submenu-selected {
    background-color: #e3e8f0 !important;
  }
  .menu-item-l2,
  .menu-sub-l2 > .ant-menu-submenu-title {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 0 !important;
    padding: 0 !important;
    padding-left: 38px !important;
    height: 32px !important;
    line-height: 32px !important;
    font-size: 12px;
    font-family: SFProText-Regular, SFProText;
    font-weight: 400;
    color: #455166;
    line-height: 18px;
  }
  .menu-item-l2.ant-menu-item-selected,
  .menu-sub-l2.ant-menu-submenu-selected > .ant-menu-submenu-title {
    font-size: 12px;
    font-family: SFProText-Medium, SFProText;
    font-weight: 500;
    color: #6020ff;
    line-height: 18px;
  }
  .menu-item-l2.ant-menu-item-selected {
    background-color: #e3e8f0;
  }
  .menu-item-l3 {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 0 !important;
    padding: 0 !important;
    padding-left: 54px !important;
    height: 32px !important;
    line-height: 32px !important;
    font-size: 12px;
    font-family: SFProText-Regular, SFProText;
    font-weight: 400;
    color: #455166;
    line-height: 18px;
  }
  .menu-item-l3.ant-menu-item-selected {
    font-size: 12px;
    font-family: SFProText-Medium, SFProText;
    font-weight: 500;
    color: #6020ff;
    line-height: 18px;
    background-color: #e3e8f0;
  }
  .ant-layout-sider-collapsed {
    width: 42px !important;
    flex: 0 0 42px !important;
    max-width: 42px !important;
    min-width: 42px !important;
  }
  .ant-menu-inline-collapsed {
    width: 42px;
    background: #f7f8fa;
    box-shadow: -1px 0px 0px 0px #d9d9d9;
    .menu-item-l1,
    .menu-sub-l1 .ant-menu-submenu-title {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-left: 0 !important;
    }
    /* .menu-icon {
      margin-right: 0;
    } */
    span {
      display: none;
    }
    .menu-badge-title {
      display: flex;
      position: absolute;
      width: 6px;
      height: 6px;
      background: #e7192c;
      padding: 0;
      top: 6px;
      right: 4px;
      border-radius: 100%;
    }
  }
  /* hide menu after bar  */
  .ant-menu-inline .ant-menu-item:after,
  .ant-menu-vertical-left .ant-menu-item:after,
  .ant-menu-vertical-right .ant-menu-item:after,
  .ant-menu-vertical .ant-menu-item:after {
    display: none;
  }
  /* add menu before bar  */
  .ant-menu-inline .ant-menu-item:before,
  .ant-menu-vertical-left .ant-menu-item:before,
  .ant-menu-vertical-right .ant-menu-item:before,
  .ant-menu-vertical .ant-menu-item:before {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    border-left: 3px solid #6020ff;
    transform: scaleY(0.0001);
    opacity: 0;
    transition: transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), opacity 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
    content: '';
  }
  /* add menu before bar active  */
  .ant-menu-inline .ant-menu-item-selected:before,
  .ant-menu-inline .ant-menu-selected:before {
    transform: scaleY(1);
    opacity: 1;
    transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .ant-menu-item a {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .package-plan-icon-box {
    display: flex;
    height: 48px;
    align-items: center;
    justify-content: center;
    margin-right: 12px;
    margin-left: 12px;
    cursor: pointer;
  }
`;
export default GineeMenu;
