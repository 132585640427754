import React, {useEffect, useMemo, useState, useCallback} from "../../../../__snowpack__/pkg/react.js";
import {Button, Spin, Popover, Modal} from "../../../../__snowpack__/pkg/antd.js";
import dayjs from "../../../../__snowpack__/pkg/dayjs.js";
import styled from "../../../../__snowpack__/pkg/styled-components.js";
import ErpIcon from "../assets/erp_icon.svg";
import ChatIcon from "../assets/chat_icon.svg";
import {OriginMap, SessionHelper} from "../../../../__snowpack__/pkg/@genie/lib-iam-utils.js";
import {useIntl} from "../../../../__snowpack__/pkg/react-intl.js";
import {IconPlan} from "../assets/IconPlan.js";
import {purchasePackage} from "../../../lib/util.js";
import {ExclamationCircleOutlined} from "../../../../__snowpack__/pkg/@ant-design/icons.js";
import moment from "../../../../__snowpack__/pkg/moment-timezone.js";
import {useIamDisplayModal} from "../../../entities/useResourceInfo.js";
import {Tip} from "../../../components/Tip/index.js";
export const formatDateTime = (value) => {
  return value ? dayjs(value).format("DD-MM-YYYY HH:mm:ss") : "-";
};
const PackageInformation = styled.div`
  .package-information-item {
    flex: 1;
    /* height: 224px; */
    background: #ffffff;
    border-radius: 6px;
    border: 1px solid #dee3ea;
    padding: 12px 16px;
    width: 550px;
    max-width: 100%;
  }
  .package-information-item-margin {
    margin-top: 8px;
  }
  .package-information-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .package-information-header-left {
      display: flex;
      align-items: center;
    }
  }
  .package-information-title {
    font-size: 16px;
    font-weight: 500;
    color: #333;
    img {
      width: 54px;
      height: 54px;
    }
  }
  .package-information-action {
    width: 160px;
  }
  .package-information-content {
    font-size: 14px;
    color: #666;
    padding-left: 12px;
    h4 {
      font-size: 18px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      color: #202d40;
      line-height: 20px;
      margin: 0;
      display: flex;
      div {
        font-size: 12px;
        color: red;
        font-weight: 400;
        margin-left: 4px;
      }
    }
    p {
      font-size: 12px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      color: #606c80;
      line-height: 20px;
      padding-top: 4px;
      margin: 0;
    }
  }
  .package-information-detail {
    display: flex;
    padding-left: 66px;
    font-size: 12px;
    font-family: SFProText, SFProText;
    font-weight: 400;
    display: flex;
    margin-top: 8px;
    line-height: 20px;
    h4 {
      color: #202d40;
      margin: 0;
      flex: 1;
    }
    p {
      width: 160px;

      margin: 0;
      .package-information-detail_num_left {
        color: #899bb5;
      }
      .package-information-detail_num_right {
        color: #202d40;
      }
    }
  }
  .package-information-children {
    display: flex;
    padding-left: 66px;
    font-size: 12px;
    font-family: SFProText, SFProText;
    font-weight: 400;
    display: flex;
    margin-top: 2px;
    line-height: 20px;
    h4 {
      flex: 1;
      color: #899bb5;
      margin: 0;
    }
    p {
      width: 160px;
      margin: 0;
      .package-information-detail_num_left {
        color: #899bb5;
      }
    }
  }
  .more-button-box {
    display: flex;
    flex-direction: column;
    margin-top: 8px;
    .more-button {
      display: flex;
      justify-content: center;
      span {
        color: #6020ff;
        cursor: pointer;
      }
    }
  }
`;
const PopoverStyled = styled.div`
  display: flex;
  align-items: center;
  .header-days-button {
    background: #faa858;
    border-radius: 14px;
    padding: 0px 8px;
    height: 20px;
    line-height: 20px;
    margin-right: 10px;
    margin-left: 10px;
    color: #fff;
    cursor: pointer;
    path {
      fill: #faa858;
    }
  }
  .header-days-order {
    background: linear-gradient(45deg, #fc927b 0%, #f5222d 100%);
    border-radius: 14px;
    padding: 0px 8px;
    height: 20px;
    line-height: 20px;
    margin-right: 10px;
    margin-left: 10px;
    color: #fff;
    cursor: pointer;
    path {
      fill: #faa858;
    }
  }
`;
export default function Index(props) {
  const showUserId = {
    U1559142840477413376: 1,
    U1686655143510011904: 1,
    U1446224643257004032: 1,
    U5DDCC5369BD1160001ADD698: 1
  };
  const newUserInfo = SessionHelper.getSessionUserInfo();
  const {formatMessage} = useIntl();
  const [currentPlanInfo, setCurrentPlanInfo] = useState({});
  const [hasMore, setHasMore] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [isOrderOpen, setIsOrderOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const {loading: currentPlanLoading, fetchData} = useIamDisplayModal();
  const fetchCurrentPlan = async (status) => {
    let data = await fetchData(status);
    if (data) {
      setCurrentPlanInfo(data);
    } else {
      setCurrentPlanInfo({});
    }
  };
  const handleVisible = () => {
    setIsVisible(!isVisible);
    if (!isVisible) {
      fetchCurrentPlan(true);
    }
  };
  useEffect(() => {
    fetchCurrentPlan(false);
  }, []);
  const renewNowSubscribe = (systemId) => {
    const iamConfig = OriginMap[window.location.origin] ?? OriginMap["https://accounts.ginee.com"];
    window.location.href = `${iamConfig?.IAM_ORIGIN}/expenses/package-subscription-price?systemId=${systemId}`;
  };
  const getSystemChatInfo = useMemo(() => {
    if (currentPlanInfo && currentPlanInfo.chatPackage) {
      return currentPlanInfo.chatPackage || "";
    } else {
      return "";
    }
  }, [currentPlanInfo]);
  const getSystemErpInfo = useMemo(() => {
    if (currentPlanInfo && currentPlanInfo.erpPackage) {
      return currentPlanInfo.erpPackage || {};
    } else {
      return {};
    }
  }, [currentPlanInfo]);
  const getShowNumber = (num) => {
    if (!num) {
      return 0;
    } else if (num === -1) {
      return formatMessage({id: "unlimited"});
    } else {
      return num;
    }
  };
  const chatDataAuthority = useMemo(() => {
    return {
      ...getSystemChatInfo?.dataAuthority || {}
    };
  }, [getSystemChatInfo]);
  const erpDataAuthority = useMemo(() => {
    return {
      ...getSystemErpInfo?.dataAuthority || {}
    };
  }, [getSystemErpInfo]);
  const getChatLen = useMemo(() => {
    return Object.keys(chatDataAuthority)?.length > 0;
  }, [chatDataAuthority]);
  const getErpLen = useMemo(() => {
    return Object.keys(erpDataAuthority)?.length > 0;
  }, [erpDataAuthority]);
  const limitMapTitle = {
    SYS_ERP_MONTH_ORDER_LIMIT: "gineeOmsOrderusedtotal",
    SYS_WMS_CONSIGNOR_COUNT: "gineeWmsConsignorusedtotal",
    SYS_ERP_MAX_SHOP_COUNT: "storeAuthorizedtotalAmount",
    SYS_ERP_MAX_SUB_ACCOUNTS: "subaccountUsedtotalAmount",
    SYS_ERP_MAX_MSKU_COUNT: "mskuUsedtotal",
    SYS_CHAT_MAX_SUB_ACCOUNTS: "subaccountUsedtotalAmount",
    SYS_CHAT_MAX_SHOPS_COUNT: "storeAuthorizedtotalAmount",
    SYS_WMS_MONTH_ORDER_LIMIT: "gineeWmsOrderusedtotal"
  };
  const orderQuotaNum = (info) => {
    return 1 - (info.quotaCount == -1 ? 0 : info.usedCount / info.quotaCount);
  };
  const remainingTimeInfoDom = useMemo(() => {
    let erpPackageInfo = currentPlanInfo.erpPackage;
    let erpOrderInfo = erpPackageInfo?.dataAuthority?.SYS_ERP_MONTH_ORDER_LIMIT || {};
    let orderQuota = orderQuotaNum(erpOrderInfo);
    let Mday = /* @__PURE__ */ React.createElement("div", {
      className: "header-days-button"
    }, formatMessage({id: "packageRemainingMDays"}, {m: erpPackageInfo?.remainingDays}));
    if (erpPackageInfo && erpPackageInfo?.remainingDays <= 7) {
      return Mday;
    } else {
      if (orderQuota <= 0) {
        return /* @__PURE__ */ React.createElement("div", {
          className: "header-days-order"
        }, formatMessage({id: "orderQuotaExceeded"}));
      } else if (orderQuota > 0 && orderQuota < 0.1) {
        return /* @__PURE__ */ React.createElement("div", {
          className: "header-days-button"
        }, formatMessage({id: "insufficientOrderQuota"}));
      } else if (erpPackageInfo && erpPackageInfo?.remainingDays <= 30) {
        return Mday;
      }
      return /* @__PURE__ */ React.createElement("div", {
        className: "package-plan-icon-box"
      }, /* @__PURE__ */ React.createElement(IconPlan, {
        className: "package-plan-icon"
      }));
    }
  }, [currentPlanInfo]);
  useEffect(() => {
    if (getSystemErpInfo && getSystemErpInfo?.remainingDays <= 6 && getSystemErpInfo?.remainingDays >= 0) {
      let currentDate = moment(new Date()).format("YYYY-MM-DD");
      let lastDate = localStorage.getItem("planCurrentDate");
      if (!lastDate || currentDate !== lastDate) {
        setIsModalOpen(true);
        localStorage.setItem("planCurrentDate", currentDate);
      }
    } else {
      checkOrder();
    }
  }, [getSystemErpInfo]);
  const checkCount = useCallback((info) => {
    let orderQuota = orderQuotaNum(erpDataAuthority[info]);
    return orderQuota < 0.1 ? "red" : "";
  }, [erpDataAuthority]);
  const checkOrder = (isOpen = false) => {
    let orderQuota = orderQuotaNum(getSystemErpInfo?.dataAuthority?.SYS_ERP_MONTH_ORDER_LIMIT || {});
    if (orderQuota <= 0) {
      let currentDate = moment(new Date()).format("YYYY-MM-DD");
      let lastDate = localStorage.getItem("planCurrentDate");
      if (!lastDate || currentDate !== lastDate || isOpen) {
        setIsOrderOpen(true);
        localStorage.setItem("planCurrentDate", currentDate);
      }
    }
  };
  const ErpPackage = (erpProp) => {
    const {getSystemErpInfo: getSystemErpInfo2, style = {}} = erpProp;
    return /* @__PURE__ */ React.createElement("div", {
      className: "package-information-item package-information-item-margin",
      style: {...style}
    }, /* @__PURE__ */ React.createElement(Spin, {
      spinning: currentPlanLoading
    }, /* @__PURE__ */ React.createElement("div", {
      className: "package-information-header"
    }, /* @__PURE__ */ React.createElement("div", {
      className: "package-information-header-left"
    }, /* @__PURE__ */ React.createElement("div", {
      className: "package-information-title"
    }, /* @__PURE__ */ React.createElement("img", {
      src: ErpIcon
    })), /* @__PURE__ */ React.createElement("div", {
      className: "package-information-content"
    }, /* @__PURE__ */ React.createElement("h4", {
      className: "package-information-name"
    }, showUserId[newUserInfo?.id || ""] ? "Business" : getSystemErpInfo2.packageName, getSystemErpInfo2?.remainingDays <= 30 ? /* @__PURE__ */ React.createElement("div", {
      className: "header-days-button"
    }, "(", formatMessage({id: "remainingMDays"}, {m: getSystemErpInfo2?.remainingDays}), ")") : /* @__PURE__ */ React.createElement(React.Fragment, null)), /* @__PURE__ */ React.createElement("p", null, formatDateTime(getSystemErpInfo2.startTime), "\xA0~\xA0", formatDateTime(getSystemErpInfo2.endTime)))), /* @__PURE__ */ React.createElement(Button, {
      className: "package-information-action",
      type: "primary",
      onClick: () => {
        renewNowSubscribe("SYS_ERP");
      }
    }, formatMessage({id: "RenewNow"}), "/", formatMessage({id: "subscribe"}))), Object.keys(erpDataAuthority)?.sort((i, n) => {
      return erpDataAuthority[i]?.sequence - erpDataAuthority[n]?.sequence;
    })?.map((info) => {
      return limitMapTitle[info] ? /* @__PURE__ */ React.createElement("div", {
        className: "package-information-detail",
        key: info
      }, /* @__PURE__ */ React.createElement("h4", {
        className: "package-information-detail_left"
      }, limitMapTitle[info] ? formatMessage({id: limitMapTitle[info]}) : info, info === "SYS_ERP_MONTH_ORDER_LIMIT" ? /* @__PURE__ */ React.createElement(Tip, {
        tip: formatMessage({id: "dailyUpdateUsage"})
      }) : /* @__PURE__ */ React.createElement(React.Fragment, null), info === "SYS_ERP_MAX_MSKU_COUNT" ? /* @__PURE__ */ React.createElement(Tip, {
        tip: formatMessage({id: "updateUsageEveryHours"})
      }) : /* @__PURE__ */ React.createElement(React.Fragment, null)), /* @__PURE__ */ React.createElement("p", {
        className: "package-information-detail_num"
      }, /* @__PURE__ */ React.createElement("span", {
        className: "package-information-detail_num_left",
        style: {
          color: checkCount(info)
        }
      }, erpDataAuthority[info]?.usedCount || 0), /* @__PURE__ */ React.createElement("span", {
        className: "package-information-detail_num_right",
        style: {
          color: checkCount(info)
        }
      }, "/", getShowNumber(erpDataAuthority[info]?.quotaCount)))) : /* @__PURE__ */ React.createElement(React.Fragment, null);
    })));
  };
  const ChatPackage = (chatProp) => {
    const {getSystemChatInfo: getSystemChatInfo2, style = {}} = chatProp;
    return /* @__PURE__ */ React.createElement("div", {
      className: "package-information-item package-information-item-margin",
      style: {...style}
    }, /* @__PURE__ */ React.createElement(Spin, {
      spinning: currentPlanLoading
    }, /* @__PURE__ */ React.createElement("div", {
      className: "package-information-header"
    }, /* @__PURE__ */ React.createElement("div", {
      className: "package-information-header-left"
    }, /* @__PURE__ */ React.createElement("div", {
      className: "package-information-title"
    }, /* @__PURE__ */ React.createElement("img", {
      src: ChatIcon
    })), /* @__PURE__ */ React.createElement("div", {
      className: "package-information-content"
    }, /* @__PURE__ */ React.createElement("h4", null, getSystemChatInfo2.packageName), getSystemChatInfo2.endTime.includes("2038") ? /* @__PURE__ */ React.createElement(React.Fragment, null) : /* @__PURE__ */ React.createElement("p", null, formatDateTime(getSystemChatInfo2.startTime), "\xA0~\xA0", formatDateTime(getSystemChatInfo2.endTime)))), /* @__PURE__ */ React.createElement(Button, {
      className: "package-information-action",
      type: "primary",
      onClick: () => {
        renewNowSubscribe("SYS_CHAT");
      }
    }, formatMessage({id: "RenewNow"}), "/", formatMessage({id: "subscribe"}))), Object.keys(chatDataAuthority)?.sort((i, n) => {
      return chatDataAuthority[i]?.sequence - chatDataAuthority[n]?.sequence;
    })?.map((info) => {
      return limitMapTitle[info] ? /* @__PURE__ */ React.createElement("div", {
        className: "package-information-detail",
        key: info
      }, /* @__PURE__ */ React.createElement("h4", {
        className: "package-information-detail_left"
      }, limitMapTitle[info] ? formatMessage({id: limitMapTitle[info]}) : info), /* @__PURE__ */ React.createElement("p", {
        className: "package-information-detail_num"
      }, /* @__PURE__ */ React.createElement("span", {
        className: "package-information-detail_num_left"
      }, chatDataAuthority[info]?.usedCount || 0), /* @__PURE__ */ React.createElement("span", {
        className: "package-information-detail_num_right"
      }, "/", getShowNumber(chatDataAuthority[info]?.quotaCount)))) : /* @__PURE__ */ React.createElement(React.Fragment, null);
    })));
  };
  return getChatLen || getErpLen ? /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(Modal, {
    title: /* @__PURE__ */ React.createElement("p", {
      style: {display: "flex", alignItems: "center", margin: 0}
    }, /* @__PURE__ */ React.createElement(ExclamationCircleOutlined, {
      style: {marginRight: 4, color: "#FFB83B", fontSize: 24}
    }), formatMessage({id: "packageRemainingValidityMDays"}, {m: getSystemErpInfo?.remainingDays})),
    visible: isModalOpen,
    okText: formatMessage({id: "RenewNow"}),
    cancelText: formatMessage({id: "cancel"}),
    onOk: () => {
      purchasePackage();
      setIsModalOpen(false);
    },
    onCancel: () => {
      checkOrder(true);
      setIsModalOpen(false);
    }
  }, /* @__PURE__ */ React.createElement("p", {
    style: {paddingLeft: 34}
  }, formatMessage({id: "toEnsureNormalUsePleaseRenew"}))), /* @__PURE__ */ React.createElement(Modal, {
    title: /* @__PURE__ */ React.createElement("p", {
      style: {display: "flex", alignItems: "center", margin: 0}
    }, /* @__PURE__ */ React.createElement(ExclamationCircleOutlined, {
      style: {marginRight: 4, color: "#FFB83B", fontSize: 24}
    }), formatMessage({id: "yourGineeOmsOrderQuotaHas"})),
    visible: isOrderOpen,
    okText: formatMessage({id: "RenewNow"}),
    cancelText: formatMessage({id: "cancel"}),
    onOk: () => {
      purchasePackage();
      setIsOrderOpen(false);
    },
    onCancel: () => {
      setIsOrderOpen(false);
    }
  }, /* @__PURE__ */ React.createElement("p", {
    style: {paddingLeft: 34}
  }, formatMessage({id: "pleaseRenewOrPurchaseOrderQuantity"}))), /* @__PURE__ */ React.createElement(Popover, {
    placement: "bottom",
    title: "",
    onVisibleChange: () => handleVisible(),
    visible: isVisible,
    content: /* @__PURE__ */ React.createElement(PackageInformation, null, getErpLen ? /* @__PURE__ */ React.createElement(ErpPackage, {
      getSystemErpInfo
    }) : /* @__PURE__ */ React.createElement(React.Fragment, null), hasMore && getChatLen ? /* @__PURE__ */ React.createElement(ChatPackage, {
      getSystemChatInfo,
      style: {marginTop: 8}
    }) : "", getChatLen && getErpLen ? /* @__PURE__ */ React.createElement("div", {
      className: "more-button-box"
    }, /* @__PURE__ */ React.createElement("div", {
      className: "more-button",
      onClick: () => {
        setHasMore(!hasMore);
      }
    }, /* @__PURE__ */ React.createElement("span", null, hasMore ? formatMessage({id: "viewLess"}) : formatMessage({id: "viewMore"})))) : /* @__PURE__ */ React.createElement(React.Fragment, null))
  }, /* @__PURE__ */ React.createElement(PopoverStyled, null, remainingTimeInfoDom)), /* @__PURE__ */ React.createElement("span", {
    className: "header-right-divider"
  }, "|")) : /* @__PURE__ */ React.createElement(React.Fragment, null);
}
