import React from "../../../../__snowpack__/pkg/react.js";
export const IconMenuAccounting = React.memo((props) => {
  return /* @__PURE__ */ React.createElement("svg", {
    width: "18px",
    height: "18px",
    viewBox: "0 0 18 18",
    version: "1.1",
    xmlns: "http://www.w3.org/2000/svg",
    ...props,
    className: props.className ? `${props.className} fill-name-box` : "fill-name-box"
  }, /* @__PURE__ */ React.createElement("title", null, "\u7F16\u7EC4 5"), /* @__PURE__ */ React.createElement("g", {
    id: "\u8D22\u52A1\u53CA\u6388\u6743",
    stroke: "none",
    "stroke-width": "1",
    fill: "none",
    "fill-rule": "evenodd"
  }, /* @__PURE__ */ React.createElement("g", {
    id: "Accurate-\u5546\u54C1\u7BA1\u7406",
    transform: "translate(-16.000000, -513.000000)",
    fill: "currentColor"
  }, /* @__PURE__ */ React.createElement("g", {
    id: "\u7F16\u7EC4-3",
    transform: "translate(16.000000, 513.000000)"
  }, /* @__PURE__ */ React.createElement("g", {
    id: "\u7F16\u7EC4",
    transform: "translate(1.000000, 1.000000)"
  }, /* @__PURE__ */ React.createElement("path", {
    d: "M7.73044916,9.63109721 C7.73044916,11.3013542 9.08928715,12.6601028 10.7595441,12.6601028 L14.3575777,12.6601028 L14.3575777,13.4956782 C14.3562369,13.9560134 13.9809966,14.3315218 13.5211978,14.3327732 L2.47753296,14.3327732 C2.01728715,14.3315218 1.64177877,13.9562816 1.64052737,13.4963933 L1.64052737,5.7665162 C1.64177877,5.30618101 2.01701899,4.93067263 2.47681788,4.92933184 L13.519857,4.92933184 C13.9804603,4.93031508 14.3562369,5.30555531 14.3575777,5.76571173 L14.3575777,6.60200223 L10.7595441,6.60200223 C9.08928715,6.60200223 7.73044916,7.96084022 7.73044916,9.63109721 M10.7595441,8.2422257 L14.3575777,8.2422257 L14.3575777,11.0189855 L10.7595441,11.0189855 C9.99395754,11.0189855 9.37103017,10.3962369 9.37103017,9.63065028 C9.37103017,8.86506369 9.99395754,8.2422257 10.7595441,8.2422257 M10.3748291,2.05469497 L11.0874994,3.28884022 L6.56387933,3.28884022 L9.23203575,1.74881788 C9.63105251,1.52008045 10.1437676,1.65719777 10.3748291,2.05469497 M13.5211978,3.28884022 L12.9813989,3.28884022 L11.7947173,1.23342123 C11.1107397,0.0569296089 9.59333184,-0.34941676 8.41201341,0.327588827 L3.28235978,3.28973408 L2.47619218,3.28973408 C1.11458324,3.29295196 0.00378994413,4.40365587 3.57541899e-05,5.76571173 L3.57541899e-05,13.4971084 C0.00396871508,14.8584492 1.11503017,15.9692425 2.47681788,15.9732648 L13.5211978,15.9732648 C14.8825385,15.9692425 15.9932425,14.8582704 15.9971754,13.4963933 L15.9971754,5.76490726 C15.9932425,4.40356648 14.8825385,3.29286257 13.5211978,3.28884022",
    id: "Fill-1"
  }), /* @__PURE__ */ React.createElement("path", {
    d: "M10.7596872,10.4505743 L10.7606704,10.4505743 C11.2129609,10.450038 11.5806927,10.0815911 11.580157,9.62930056 C11.5798883,9.41003799 11.4943464,9.20418324 11.3392626,9.04954637 C11.1844469,8.89499888 10.9784134,8.80981453 10.7589721,8.80981453 C10.5399777,8.8099933 10.3343017,8.89544581 10.1796648,9.05017207 C10.0267263,9.20302123 9.93912849,9.4148648 9.93939603,9.63108827 C9.9396648,9.85017207 10.0252067,10.0561162 10.1803799,10.2108425 C10.3351955,10.3654793 10.5409609,10.4505743 10.7596872,10.4505743",
    id: "Fill-3"
  }))))));
});
