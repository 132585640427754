import React from "../../../../__snowpack__/pkg/react.js";
export const IconMenuDashboard = React.memo((props) => {
  return /* @__PURE__ */ React.createElement("svg", {
    xmlns: "http://www.w3.org/2000/svg",
    width: "18",
    height: "18",
    viewBox: "0 0 18 18",
    ...props
  }, /* @__PURE__ */ React.createElement("g", {
    fill: "none",
    fillRule: "evenodd",
    stroke: "none",
    strokeWidth: "1"
  }, /* @__PURE__ */ React.createElement("g", {
    transform: "translate(-12 -64)"
  }, /* @__PURE__ */ React.createElement("g", {
    transform: "translate(0 52)"
  }, /* @__PURE__ */ React.createElement("g", {
    transform: "translate(12 12)"
  }, /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", {
    d: "M0 0H18V18H0z"
  }), /* @__PURE__ */ React.createElement("path", {
    fill: "currentColor",
    fillRule: "nonzero",
    d: "M9.125 16.613c2.132-.047 3.902-.774 5.308-2.18 1.406-1.406 2.133-3.176 2.18-5.308-.047-2.133-.774-3.903-2.18-5.309-1.406-1.406-3.176-2.133-5.308-2.18-2.133.047-3.903.774-5.309 2.18-1.406 1.406-2.133 3.176-2.18 5.309.047 2.132.774 3.902 2.18 5.308 1.406 1.406 3.176 2.133 5.309 2.18zm3.972-2.988A5.67 5.67 0 0011.27 12.5a5.996 5.996 0 00-2.144-.387c-1.524.023-2.848.527-3.973 1.512a5.99 5.99 0 01-1.477-1.987 6.32 6.32 0 01-.562-2.513c.047-1.711.633-3.13 1.758-4.254C5.996 3.746 7.414 3.16 9.125 3.113c1.71.047 3.129.633 4.254 1.758 1.125 1.125 1.71 2.543 1.757 4.254a6.32 6.32 0 01-.562 2.513 5.99 5.99 0 01-1.477 1.987zM7.613 6.1a.715.715 0 00.738-.738.715.715 0 00-.738-.738.715.715 0 00-.738.738.715.715 0 00.738.738zm1.512 4.5c.422 0 .773-.14 1.054-.422a1.56 1.56 0 00.457-1.054c-.023-.375-.129-.68-.316-.914l1.055-2.532a.853.853 0 00-.053-.562.834.834 0 00-.404-.422.776.776 0 00-.563 0c-.187.07-.328.223-.422.457l-1.02 2.46c-.398.048-.708.218-.93.51a1.665 1.665 0 00-.335 1.038c0 .398.14.738.422 1.02.281.28.633.421 1.055.421zm-3.762-2.25a.715.715 0 00.738-.738.715.715 0 00-.738-.738.715.715 0 00-.738.738.715.715 0 00.738.738zm7.523 0a.715.715 0 00.738-.738.715.715 0 00-.738-.738.715.715 0 00-.738.738c0 .21.07.387.211.527.14.14.316.211.527.211z"
  })))))));
});
