import {lazy} from "../../lib/lazy.js";
export const warehouseStockInventoryRoutes = [
  {
    path: "/warehouse-stock-inventory",
    component: lazy(() => import(/* webpackChunkName: "warehouseStockInventoryRoutes" */ "../../views/warehouse-stock-inventory/index.js")),
    auth: "inv-count-stocktaking"
  },
  {
    path: "/warehouse-stock-inventory/add-inventory",
    auth: "inv-count-stocktaking",
    component: lazy(() => import(/* webpackChunkName: "warehouseStockInventoryRoutes" */ "../../views/warehouse-stock-inventory/Add.js"))
  },
  {
    path: "/warehouse-stock-inventory/edit-inventory/:id",
    component: lazy(() => import(/* webpackChunkName: "warehouseStockInventoryRoutes" */ "../../views/warehouse-stock-inventory/Edit.js"))
  },
  {
    path: "/warehouse-stock-inventory/detail-inventory/:id",
    component: lazy(() => import(/* webpackChunkName: "warehouseStockInventoryRoutes" */ "../../views/warehouse-stock-inventory/Detail.js"))
  }
];
