import React, {useEffect, Fragment} from "../../__snowpack__/pkg/react.js";
import {withRouter} from "../../__snowpack__/pkg/react-router-dom.js";
export const ReactRouterScrollToTop = withRouter(({history, children}) => {
  useEffect(() => {
    const unListen = history.listen(() => {
      window.scrollTo(0, 0);
    });
    return () => {
      unListen();
    };
  }, []);
  return /* @__PURE__ */ React.createElement(Fragment, null, children);
});
