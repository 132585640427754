import {useState} from "../../../__snowpack__/pkg/react.js";
import {createModel} from "../../../__snowpack__/pkg/hox.js";
import Cookies from "../../../__snowpack__/pkg/js-cookie.js";
import {request} from "../../utils/request/index.js";
import {handleError} from "../../lib/errorHandler.js";
var ParamsType;
(function(ParamsType2) {
  ParamsType2["ALL"] = "ALL";
  ParamsType2["SHOP"] = "SHOP";
  ParamsType2["ORDER"] = "ORDER";
  ParamsType2["PRODUCT"] = "PRODUCT";
  ParamsType2["STOCK"] = "STOCK";
})(ParamsType || (ParamsType = {}));
export const useTodoList = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isOrderLoading, setIsOrderLoading] = useState(false);
  const [orderList, setOrderList] = useState(null);
  const [isStockLoading, setIsStockLoading] = useState(false);
  const [stockList, setStockList] = useState(null);
  const [isProductLoading, setIsProductLoading] = useState(false);
  const [productList, setProductList] = useState(null);
  const [isShopLoading, setIsShopLoading] = useState(false);
  const [shopList, setShopList] = useState(null);
  const [todoList, setTodoList] = useState(null);
  const fetchData = async (type, needCache) => {
    try {
      const data = await request.post(`saas/dashboard/statistics/to-do`, {type, needCache});
      return data;
    } catch (error) {
      handleError(error);
      return null;
    }
  };
  const fetchOrderData = async (needCache) => {
    try {
      setIsOrderLoading(true);
      const data = await fetchData(ParamsType.ORDER, needCache);
      setOrderList(data);
    } catch (error) {
      handleError(error);
      return {todoList: null};
    } finally {
      setIsOrderLoading(false);
    }
  };
  const fetchStockData = async (needCache) => {
    try {
      setIsStockLoading(true);
      const data = await fetchData(ParamsType.STOCK, needCache);
      setStockList(data);
    } catch (error) {
      handleError(error);
      return {todoList: null};
    } finally {
      setIsStockLoading(false);
    }
  };
  const fetchProductData = async (needCache) => {
    try {
      setIsProductLoading(true);
      const data = await fetchData(ParamsType.PRODUCT, needCache);
      setProductList(data);
    } catch (error) {
      handleError(error);
      return {todoList: null};
    } finally {
      setIsProductLoading(false);
    }
  };
  const fetchShopData = async (needCache) => {
    try {
      setIsShopLoading(true);
      const data = await fetchData(ParamsType.SHOP, needCache);
      setShopList(data);
      let seconds = 1 * 60 * 60;
      let expires = new Date(new Date().valueOf() * 1 + seconds * 1e3);
      localStorage.setItem("todoShopList", JSON.stringify(data));
      Cookies.set("fetchShopData", "1", {expires});
      return {todoList: data};
    } catch (error) {
      handleError(error);
      return {todoList: null};
    } finally {
      setIsShopLoading(false);
    }
  };
  const fetchToDoList = (needCache = true) => {
    fetchOrderData(needCache);
    fetchStockData(needCache);
    fetchProductData(needCache);
    fetchShopData(needCache);
  };
  return {
    isOrderLoading,
    orderList,
    fetchOrderData,
    isStockLoading,
    stockList,
    fetchStockData,
    isProductLoading,
    productList,
    fetchProductData,
    isShopLoading,
    shopList,
    fetchShopData,
    isLoading,
    fetchData,
    todoList,
    todoLoading: isOrderLoading && isStockLoading && isProductLoading && isShopLoading,
    fetchToDoList
  };
};
export const useTodoListModal = createModel(useTodoList);
