import React, {memo} from "../../../__snowpack__/pkg/react.js";
import {Empty} from "../../../__snowpack__/pkg/antd.js";
import {useIntl} from "../../../__snowpack__/pkg/react-intl.js";
import {IconEmptyorder} from "../../../__snowpack__/pkg/@genieui-react/icon.js";
export const TableEmpty = memo((props) => {
  const {
    children,
    descriptionText,
    descriptionStyles,
    imageStyle,
    image
  } = props;
  const {formatMessage} = useIntl();
  return /* @__PURE__ */ React.createElement(Empty, {
    image: image || /* @__PURE__ */ React.createElement(IconEmptyorder, null),
    imageStyle: imageStyle || {width: 126, height: 80, margin: "32px auto 7px"},
    description: /* @__PURE__ */ React.createElement("div", {
      style: descriptionStyles || {
        color: "#161344",
        fontSize: 14,
        fontWeight: 400,
        fontFamily: "SFProText-Regular, SFProText",
        marginBottom: 32
      }
    }, descriptionText || formatMessage({id: "globalNoEmptyText"}))
  }, children);
});
