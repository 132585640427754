import {lazy} from "../../lib/lazy.js";
export const productBukalapakRoutes = [
  {
    path: "/product-bukalapak",
    component: lazy(() => import(/* webpackChunkName: "productBukalapakRoutes" */ "../../views/product-bukalapak/list/index.js")),
    auth: "list-product"
  },
  {
    path: "/product-bukalapak-add",
    component: lazy(() => import(/* webpackChunkName: "productBukalapakRoutes" */ "../../views/product-bukalapak/edit/add.js")),
    auth: "list-product"
  },
  {
    path: "/product-bukalapak-edit/:id",
    component: lazy(() => import(/* webpackChunkName: "productBukalapakRoutes" */ "../../views/product-bukalapak/edit/edit.js")),
    auth: "list-product"
  },
  {
    path: "/product-bukalapak-mass-edit-category-attribute",
    component: lazy(() => import(/* webpackChunkName: "productBukalapakRoutes" */ "../../views/product-bukalapak/mass-edit-category/index.js"))
  },
  {
    path: "/product-bukalapak-mass-edit",
    component: lazy(() => import(/* webpackChunkName: "productBukalapakRoutes" */ "../../views/product-bukalapak/mass-edit/index.js"))
  }
];
