import {lazy} from "../../lib/lazy.js";
export const productTiktokRoutes = [
  {
    path: "/product-tiktok",
    component: lazy(() => import(/* webpackChunkName: "productTiktokRoutes" */ "../../views/product-tiktok/list/index.js")),
    auth: "list-product"
  },
  {
    path: "/product-tiktok-add",
    component: lazy(() => import(/* webpackChunkName: "productTiktokAddEditRoutes" */ "../../views/product-tiktok/detail/add.js")),
    auth: "list-product"
  },
  {
    path: "/product-tiktok-edit/:id",
    component: lazy(() => import(/* webpackChunkName: "productTiktokAddEditRoutes" */ "../../views/product-tiktok/detail/edit.js")),
    auth: "list-product"
  },
  {
    path: "/product-tiktok-mass-edit-category-attribute",
    component: lazy(() => import(/* webpackChunkName: "productTiktokRoutes" */ "../../views/product-tiktok/mass-edit-category-attribute/index.js"))
  },
  {
    path: "/product-tiktok-mass-edit",
    component: lazy(() => import(/* webpackChunkName: "productTiktokRoutes" */ "../../views/product-tiktok/mass-edit/batch-update.js"))
  }
];
