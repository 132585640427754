import React, {memo} from "../../__snowpack__/pkg/react.js";
import {isObject, isArray} from "../../__snowpack__/pkg/lodash.js";
import {useLayoutState} from "../store/layout.js";
import {Modal} from "../../__snowpack__/pkg/antd.js";
import {purchasePackage} from "./util.js";
import {localeMessage} from "./i18n.js";
import {useIntlTyped} from "./useIntlTyped.js";
const renderSubmitButton = (Children) => {
  const {formatMessage, locale} = useIntlTyped(localeMessage);
  const alertMethod = (e) => {
    e?.stopPropagation && e?.stopPropagation();
    e?.preventDefault && e?.preventDefault();
    Modal.confirm({
      title: formatMessage({id: "hint"}),
      content: formatMessage({id: "becauseTheNumberOfStoresExceeds"}),
      okText: formatMessage({id: "confirm"}),
      cancelText: formatMessage({id: "subscriptionPackage"}),
      onCancel() {
        purchasePackage();
      }
    });
  };
  return React.Children.map(Children, (child) => {
    if (["boolean", "undefined", "string", "number"].includes(typeof child) || child === null) {
      return child;
    }
    if (child?.props?.onClick || child?.type?.render?.displayName === "Button") {
      return React.cloneElement(child, {
        onClick: alertMethod
      });
    } else if (isObject(child?.props?.children)) {
      return React.Children.map(child.props.children, (child1) => {
        if (["boolean", "undefined", "string", "number"].includes(typeof child1) || child === null) {
          return child1;
        } else {
          return React.cloneElement(child1, {
            onClick: alertMethod
          });
        }
      });
    } else {
      return React.cloneElement(child, {
        onClick: alertMethod
      });
    }
  });
};
export const PermissionInfo = memo(({authPermission = "", children}) => {
  const {withPermissionShow, withPermissionClick} = useLayoutState();
  let hasClick = false;
  let hasShow = false;
  if (isArray(authPermission)) {
    authPermission.forEach((item) => {
      if (hasClick) {
        return;
      }
      if (withPermissionClick(item)) {
        hasClick = true;
      }
      if (withPermissionShow(item)) {
        hasShow = true;
      }
    });
  } else {
    if (withPermissionClick(authPermission)) {
      hasClick = true;
    }
    if (withPermissionShow(authPermission)) {
      hasShow = true;
    }
  }
  if (hasClick || !authPermission) {
    return children;
  } else if (hasShow) {
    return renderSubmitButton(children);
  } else {
    return /* @__PURE__ */ React.createElement(React.Fragment, null);
  }
});
