import sockClient from "../../__snowpack__/pkg/sockjs-client.js";
import stomp from "../../__snowpack__/pkg/@genie/stompjs.js";
import {message as messageTip} from "../../__snowpack__/pkg/antd.js";
import {getSameUuid} from "./uuid.js";
import {SessionHelper} from "../../__snowpack__/pkg/@genie/lib-iam-utils.js";
export var ConnectStatus;
(function(ConnectStatus2) {
  ConnectStatus2["CLOSE"] = "CLOSE";
  ConnectStatus2["CONNECTING"] = "CONNECTING";
  ConnectStatus2["CONNECTED"] = "CONNECTED";
})(ConnectStatus || (ConnectStatus = {}));
let connecting = ConnectStatus.CLOSE;
const RETRY_CONNECT_MAX_TIMES = 2;
let reconnectTimes = 0;
let stompClient = null;
function connectWebsocket(options = {}) {
  connecting = ConnectStatus.CONNECTING;
  var socket = new sockClient("/saas/ws/app-temp");
  stompClient = stomp.over(socket);
  stompClient.connect({
    authorization: SessionHelper.getSessionToken() ?? "",
    "user-client-id": getSameUuid()
  }, function(frame) {
    stompClient.subscribe("/user/topic/notification.message", function(greeting) {
      const result = JSON.parse(greeting.body);
      options.onMessage(result);
    });
    options.onConnect();
  }, function(error) {
    messageTip.error(error);
    options.onError();
  });
}
const throttleOnMessage = (messages) => {
  if (taskQueue.size > 0) {
    taskQueue.forEach(function(taskRegister) {
      taskRegister.callback(messages);
    });
  }
};
let taskQueue = new Set();
export function subscribe(taskRegister) {
  taskQueue.add(taskRegister);
  return () => {
    taskQueue.delete(taskRegister);
  };
}
let connectQueue = new Set();
const connect = (options) => {
  const {retryConnectMaxTimes = RETRY_CONNECT_MAX_TIMES} = options || {};
  let config = {
    onMessage: throttleOnMessage,
    onConnect: () => {
      connecting = ConnectStatus.CONNECTED;
      reconnectTimes = 0;
      connectQueue.forEach(function([resolved, rejected]) {
        resolved();
      });
      connectQueue.clear();
    },
    onError: () => {
      if (++reconnectTimes <= retryConnectMaxTimes) {
      } else {
        if (connecting === ConnectStatus.CONNECTING) {
          connectQueue.forEach(function([resolved, rejected]) {
            rejected();
          });
          connectQueue.clear();
        } else {
          taskQueue.forEach(function(taskRegister) {
            if (taskRegister?.onDisconnect) {
              taskRegister.onDisconnect();
            }
          });
        }
        reconnectTimes = 0;
        connecting = ConnectStatus.CLOSE;
      }
    }
  };
  return new Promise((resolved, rejected) => {
    connectQueue.add([resolved, rejected]);
    switch (connecting) {
      case ConnectStatus.CONNECTING:
        break;
      case ConnectStatus.CONNECTED:
        connectQueue.forEach(function([resolved2, rejected2]) {
          resolved2();
        });
        connectQueue.clear();
        break;
      default:
        connectWebsocket(config);
        break;
    }
  });
};
const handleChangeConnecting = (status) => {
  if (status) {
    connecting = status;
  }
};
export {stompClient, connect, handleChangeConnecting};
