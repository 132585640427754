import React from "../../../../__snowpack__/pkg/react.js";
export const IconSyncExpiredBe = React.memo((props) => {
  return /* @__PURE__ */ React.createElement("svg", {
    width: "16px",
    height: "16px",
    viewBox: "0 0 16 16",
    version: "1.1",
    xmlns: "http://www.w3.org/2000/svg"
  }, /* @__PURE__ */ React.createElement("title", null, "\u7F16\u7EC4 2"), /* @__PURE__ */ React.createElement("g", {
    id: "\u5957\u9910/\u5E97\u94FA\u63D0\u9192",
    stroke: "none",
    "stroke-width": "1",
    fill: "none",
    "fill-rule": "evenodd"
  }, /* @__PURE__ */ React.createElement("g", {
    id: "\u5373\u5C06\u5230\u671F",
    transform: "translate(-973.000000, -18.000000)"
  }, /* @__PURE__ */ React.createElement("g", {
    id: "\u7F16\u7EC4-3",
    transform: "translate(973.000000, 18.000000)"
  }, /* @__PURE__ */ React.createElement("g", {
    id: "\u7F16\u7EC4-2",
    transform: "translate(0.000000, 1.000000)"
  }, /* @__PURE__ */ React.createElement("path", {
    d: "M8,0 C10.3831572,0 12.3606723,1.73676055 12.7359009,4.0136373 L12.8237272,4.00509269 L12.8237272,4.00509269 L13,4 C14.6568542,4 16,5.34314575 16,7 C16,8.58947186 14.7638812,9.8902327 13.2005213,9.99340473 L13.2,10 L3.2,10 L3.19909624,9.97792847 C1.39955121,9.77853994 0,8.25271132 0,6.4 C0,4.4117749 1.6117749,2.8 3.6,2.8 C3.61175272,2.8 3.62349229,2.80005632 3.63521846,2.8001687 C4.39363149,1.14758165 6.06279972,0 8,0 Z",
    id: "\u5F62\u72B6\u7ED3\u5408",
    fill: "#8C99AC"
  }), /* @__PURE__ */ React.createElement("g", {
    id: "\u7F16\u7EC4-4",
    transform: "translate(3.000000, 4.000000)"
  }, /* @__PURE__ */ React.createElement("path", {
    d: "M5,10 C2.23828125,10 0,7.76171875 0,5 C0,2.23828125 2.23828125,0 5,0 C7.76171875,0 10,2.23828125 10,5 C10,7.76171875 7.76171875,10 5,10 L5,10 Z",
    id: "Path",
    fill: "#FAAD14",
    transform: "translate(5.000000, 5.000000) scale(1, -1) translate(-5.000000, -5.000000) "
  }), /* @__PURE__ */ React.createElement("rect", {
    id: "\u77E9\u5F62",
    fill: "#FFFFFF",
    x: "4.5",
    y: "1.8",
    width: "1.2",
    height: "5",
    rx: "0.6"
  }), /* @__PURE__ */ React.createElement("circle", {
    id: "\u692D\u5706\u5F62",
    fill: "#FFFFFF",
    cx: "5.1",
    cy: "8.2",
    r: "1"
  })))))));
});
