import {lazy} from "../../lib/lazy.js";
export const kongfuWarehouse = [
  {
    path: "/warehouse-kongfu-product",
    component: lazy(() => import(/* webpackChunkName: "warehouseProductListRoutes" */ "../../views/kongfu-warehouse/product/index.js")),
    meta: {
      openKeys: ["menu-sub-warehouse", "menu-sub-kongfu-warehouse"]
    },
    auth: "kongfu-warehouse-product"
  },
  {
    path: "/kongfu-create-master-product",
    component: lazy(() => import(/* webpackChunkName: "warehouseProductListRoutes" */ "../../views/kongfu-warehouse/component/CreateMainProduct.js"))
  },
  {
    path: "/kongfu-inbound-management",
    component: lazy(() => import(/* webpackChunkName: "warehouseProductListRoutes" */ "../../views/kongfu-warehouse/inbound/index.js")),
    meta: {
      openKeys: ["menu-sub-warehouse", "menu-sub-kongfu-warehouse"],
      selectedKeys: ["kongfu-inbound-management"]
    },
    auth: "kongfu-warehouse-purchase"
  },
  {
    path: "/kongfu-inbound-management-add",
    component: lazy(() => import(/* webpackChunkName: "inboundManagementRoutes" */ "../../views/inbound-management/Add.js")),
    meta: {
      openKeys: ["menu-sub-warehouse", "menu-sub-kongfu-warehouse"],
      selectedKeys: ["kongfu-inbound-management"]
    }
  },
  {
    path: "/kongfu-inbound-management-edit/:id",
    component: lazy(() => import(/* webpackChunkName: "inboundManagementRoutes" */ "../../views/inbound-management/Edit.js")),
    meta: {
      openKeys: ["menu-sub-warehouse", "menu-sub-kongfu-warehouse"],
      selectedKeys: ["kongfu-inbound-management"]
    }
  },
  {
    path: "/kongfu-inbound-management-detail/:id/:orderType",
    component: lazy(() => import(/* webpackChunkName: "inboundManagementRoutes" */ "../../views/inbound-management/Detail.js")),
    meta: {
      openKeys: ["menu-sub-warehouse", "menu-sub-kongfu-warehouse"],
      selectedKeys: ["kongfu-inbound-management"]
    }
  },
  {
    path: "/kongfu-inbound-management-submit/:id/:orderType",
    component: lazy(() => import(/* webpackChunkName: "inboundManagementRoutes" */ "../../views/inbound-management/Submit.js")),
    meta: {
      openKeys: ["menu-sub-warehouse", "menu-sub-kongfu-warehouse"],
      selectedKeys: ["kongfu-inbound-management"]
    }
  },
  {
    path: "/kongfu-outbound-management",
    component: lazy(() => import(/* webpackChunkName: "warehouseProductListRoutes" */ "../../views/kongfu-warehouse/outbound/index.js")),
    meta: {
      openKeys: ["menu-sub-warehouse", "menu-sub-kongfu-warehouse"],
      selectedKeys: ["kongfu-outbound-management"]
    },
    auth: "kongfu-warehouse-outbound"
  },
  {
    path: "/kongfu-outbound-inventory",
    component: lazy(() => import(/* webpackChunkName: "warehouseProductListRoutes" */ "../../views/kongfu-warehouse/inventory/index.js")),
    meta: {
      openKeys: ["menu-sub-warehouse", "menu-sub-kongfu-warehouse"]
    },
    auth: "kongfu-warehouse-stock-pull"
  }
];
