import * as __SNOWPACK_ENV__ from '../../__snowpack__/env.js';

import qs from "../../__snowpack__/pkg/query-string.js";
import {OriginMap} from "../../__snowpack__/pkg/@genie/lib-iam-utils.js";
export const limitDecimals = (value) => {
  const reg = /^(\-)*(\d+)\.(\d\d).*$/;
  if (typeof value === "string") {
    return !isNaN(Number(value)) ? value.replace(reg, "$1$2.$3") : "";
  } else if (typeof value === "number") {
    return !isNaN(value) ? String(value).replace(reg, "$1$2.$3") : "";
  } else {
    return "";
  }
};
export const calculateHandler = (type, value, amount) => {
  switch (type) {
    case "SET_DIRECTLY":
      amount = value;
      break;
    case "ADD_BY_CARDINAL":
      amount = amount + value;
      break;
    case "DECREASE_BY_CARDINAL":
      amount = amount - value;
      break;
    case "ADD_BY_PERCENTAGE":
      amount = amount + amount * (value / 100);
      break;
    case "DECREASE_BY_PERCENTAGE":
      amount = amount - amount * (value / 100);
      break;
    default:
      amount = value;
      break;
  }
  return amount;
};
export const gineeThrottle = function(callBack, time) {
  let isSearch = false;
  return function() {
    if (isSearch) {
      return;
    }
    isSearch = true;
    callBack();
    setTimeout(() => {
      isSearch = false;
    }, time || 100);
  };
};
export const getCountryCode = () => {
  let searchInfo = qs.parse(window.location.search.replace("?", ""));
  return searchInfo && searchInfo.countryCode ? searchInfo.countryCode : "ID";
};
export const limitNumber = (value) => {
  if (typeof value === "string") {
    return !isNaN(Number(value)) ? value.replace(/^|[^\d]/g, "") : "";
  } else if (typeof value === "number") {
    return !isNaN(value) ? String(value).replace(/^|[^\d]/g, "") : "";
  } else {
    return "";
  }
};
export const limitNumberTwo = (value) => {
  if (typeof value === "string") {
    const newValue = !isNaN(Number(value)) ? value.replace(/^(\-)*(\d+)\.(\d\d).*$/, "$1$2.$3") : "";
    return `${newValue}%`;
  } else if (typeof value === "number") {
    const newValue = !isNaN(value) ? String(value).replace(/^(\-)*(\d+)\.(\d\d).*$/, "$1$2.$3") : "";
    return `${newValue}%`;
  } else {
    return "";
  }
};
export const limitNumberThree = (value) => {
  if (typeof value === "string") {
    return !isNaN(Number(value)) ? value.replace(/^(\-)*(\d+)\.(\d\d\d).*$/, "$1$2.$3") : "";
  } else if (typeof value === "number") {
    return !isNaN(value) ? String(value).replace(/^(\-)*(\d+)\.(\d\d\d).*$/, "$1$2.$3") : "";
  } else {
    return "";
  }
};
export const limitDecimalsP = (value) => {
  return `${value}`.replace(/^(\-)*(\d+)\.(\d\d).*$/, "$1$2.$3");
};
export const limitStock = {
  ID: {
    min: 0,
    max: 999999,
    tip: "stocktLimit"
  },
  PH: {
    min: 0,
    max: 999999999,
    tip: "stocktLimit"
  },
  VN: {
    min: 0,
    max: 999999999,
    tip: "stocktLimit"
  },
  TH: {
    min: 0,
    max: 999999999,
    tip: "stocktLimit"
  },
  MY: {
    min: 0,
    max: 999999999,
    tip: "stocktLimit"
  }
};
export const limitPrice = {
  ID: {
    type: "integer",
    min: 99,
    precision: 0,
    max: 15e7
  },
  PH: {
    type: "integer",
    min: 5,
    precision: 0,
    max: 1e6
  },
  VN: {
    type: "integer",
    min: 1e3,
    precision: 0,
    max: 12e7
  },
  TH: {
    type: "integer",
    min: 1,
    precision: 0,
    max: 5e5
  },
  MY: {
    type: "number",
    min: 0.1,
    precision: 1,
    max: 1e9
  }
};
export const limitDesc = {
  ID: {
    min: 20,
    max: 3e3
  },
  PH: {
    min: 2,
    max: 3e3
  },
  VN: {
    min: 100,
    max: 3e3
  },
  TH: {
    min: 25,
    max: 5e3
  },
  MY: {
    min: 20,
    max: 3e3
  }
};
export const limitProduceName = {
  ID: {
    min: 5,
    max: 255
  },
  PH: {
    min: 20,
    max: 100
  },
  VN: {
    min: 10,
    max: 120
  },
  TH: {
    min: 20,
    max: 120
  },
  MY: {
    min: 20,
    max: 120
  }
};
export const downloadFails = async (failExcelUrl) => {
  if (failExcelUrl) {
    const eLink = document.createElement("a");
    eLink.style.display = "none";
    eLink.href = failExcelUrl;
    document.body.appendChild(eLink);
    eLink.click();
    setTimeout(() => {
      URL.revokeObjectURL(eLink.href);
      document.body.removeChild(eLink);
    }, 10);
  } else {
    alert("no failExcelUrl from api");
  }
};
export const getIamOrigin = () => {
  const origin = window.location.origin;
  const loginUrl = OriginMap[origin]?.IAM_ORIGIN || origin;
  return loginUrl;
};
export const openIamStaff = () => {
  const iamOrigin = getIamOrigin();
  window.open(`${iamOrigin}/organization/staff?systemId=SYS_ERP`);
};
export const hrefIamStaff = () => {
  const iamOrigin = getIamOrigin();
  window.location.href = `${iamOrigin}/organization/staff?systemId=SYS_ERP`;
};
export const hrefIamRole = () => {
  const iamOrigin = getIamOrigin();
  window.location.href = `${iamOrigin}/organization/role?systemId=SYS_ERP`;
};
export const openIamRole = () => {
  const iamOrigin = getIamOrigin();
  window.open(`${iamOrigin}/organization/role?systemId=SYS_ERP`);
};
export const openIamAccountInfo = () => {
  const iamOrigin = getIamOrigin();
  window.open(`${iamOrigin}/profile/account-info`);
};
export const getIamPlanOrigin = () => {
  const origin = window.location.origin;
  const loginUrl = OriginMap[origin]?.IAM_ORIGIN || "http://localhost:8000";
  return loginUrl;
};
export const getPartnerOrigin = () => {
  if (__SNOWPACK_ENV__.NODE_ENV === "development") {
    return `${window.location.protocol}//${window.location.hostname}:3000`;
  }
  const origin = window.location.origin;
  const partnerOrigin = OriginMap[origin]?.PARTNER_CONFIG?.ORIGIN ?? "";
  return partnerOrigin;
};
export const getAddStoreUrl = (_targetUrl) => {
  let targetUrl = _targetUrl ? `${window.origin}${_targetUrl}` : window.location.href;
  const origin = window.location.origin;
  const partnerConfig = OriginMap[origin]?.PARTNER_CONFIG;
  let partnerURL = `${partnerConfig?.ORIGIN}${partnerConfig?.ACCESS_PATH}`;
  const userInfo = JSON.parse(window.localStorage.getItem("userInfo") || "{}");
  if (__SNOWPACK_ENV__.NODE_ENV === "development") {
    partnerURL = `${window.location.protocol}//${window.location.hostname}:3000/auth-list/`;
  }
  const oURL = new URL(partnerURL);
  const language = localStorage.getItem("language") || "id";
  oURL.searchParams.set("client_id", "SYS_ERP");
  oURL.searchParams.set("language", language);
  oURL.searchParams.set("meal", userInfo?.merchant?.currentPlanName);
  oURL.searchParams.set("target", encodeURIComponent(targetUrl));
  return oURL.toString();
};
export const getAddStoreChannelUrl = (channel, callbackPath) => {
  const hostname = window.location.hostname;
  const language = localStorage.getItem("language") || "id";
  const thisHref = `${window.origin}${callbackPath ?? window.location.pathname}`;
  const defaultRoute = channel || "/auth-list";
  const partnerOrigin = getPartnerOrigin();
  const authUrl = `${partnerOrigin}${defaultRoute}/?${qs.stringify({
    client_id: "SYS_ERP",
    target: encodeURIComponent(thisHref),
    language
  })}`;
  return authUrl;
};
export const getResetStoreUrl = (resetInfo) => {
  const thisHref = window.location.href;
  const language = localStorage.getItem("language") || "id";
  const partnerOrigin = getPartnerOrigin();
  const authUrl = `${partnerOrigin}/auth-list/?${qs.stringify({
    client_id: "SYS_ERP",
    ...qs.parse(resetInfo || ""),
    target: encodeURIComponent(thisHref),
    language
  })}`;
  return authUrl;
};
export const filterCategory = (array) => {
  return array.filter((item) => {
    const {status} = item;
    if (status == "DELETED") {
      return false;
    }
    return true;
  }).map((item) => {
    const {children = [], ...otherItem} = item;
    if (children?.length) {
      return {
        ...otherItem,
        children: filterCategory(children)
      };
    }
    return item;
  });
};
export const formatTabCount = (count) => {
  if (!count) {
    return "0";
  }
  if (count < 9999) {
    return `${count}`;
  }
  return `9999+`;
};
export const getChannelShopList = (shopList, channelId, labelKey = "shopName", valueKey = "shopId") => {
  if (shopList?.length) {
    return shopList.filter((shop) => {
      return shop?.channelId == channelId;
    }).map((shop) => {
      return {
        ...shop,
        label: shop[labelKey],
        value: shop[valueKey]
      };
    });
  }
  return [];
};
export const getChannelCountryList = (shopList, channelId) => {
  return shopList.reduce((acc, activeShop) => {
    const findItem = acc.find((_) => _.countryCode === activeShop.countryCode);
    if (activeShop?.channelId == channelId && !findItem) {
      acc.push({
        countryCode: activeShop.countryCode,
        country: activeShop.country
      });
    }
    return acc;
  }, []);
};
export const channelSortList = [
  "SHOPEE_ID",
  "LAZADA_ID",
  "TOKOPEDIA_ID",
  "TIKTOK_ID",
  "BUKALAPAK_ID",
  "BLIBLI_ID",
  "JD_V2",
  "JD_ID",
  "JD_TH",
  "TIKI_ID",
  "ZALORA_ID",
  "LINE_SHOPPING_TH",
  "LAZADA_CHOICE",
  "SHOPIFY_ID",
  "WOO_COMMERCE_ID",
  "MAGENTO_ID",
  "WIX_ID",
  "AKULAKU_ID",
  "MOKA_POS_ID",
  "MANUAL_ID",
  "GINEE_POS"
];
export const getSortChannelIdList = (array) => {
  const copyArray = [...array];
  copyArray.sort((a, b) => {
    return channelSortList.indexOf(a) - channelSortList.indexOf(b);
  });
  return copyArray;
};
export const getSortChannelList = (array) => {
  const copyArray = [...array];
  copyArray.sort((a, b) => {
    return channelSortList.indexOf(a?.channelId) - channelSortList.indexOf(b?.channelId);
  });
  return copyArray;
};
export const validateInt = (val, min, max) => {
  if (min === 0) {
    return max ? val >= min && val <= max && /^(0|([1-9]\d*))$/.test(val) : val >= min && /^(0|([1-9]\d*))$/.test(val);
  } else {
    return max ? val >= min && val <= max && /^[1-9]\d*$/.test(val) : val >= min && /^[1-9]\d*$/.test(val);
  }
};
export const purchasePackage = () => {
  let openUrl = getIamPlanOrigin() + "/expenses/package-subscription-price?systemId=SYS_ERP";
  window.open(openUrl);
};
export const goPaidList = () => {
  let openUrl = getIamPlanOrigin() + "/expenses/paid-list";
  window.open(openUrl);
};
export const productErrorMapCode = {
  BUNDLE_MASTER_VARIATION_LIMIT_EXCEEDED: "failedToSaveTheNumberOf",
  BUNDLE_VARIATION_CHANNEL_VARIATION_RELATION_LIMIT_EXCEEDED: "oneFailedToSaveTheNumberOf",
  BE_BUNDLE_MASTER_VARIATION_LIMIT_EXCEEDED: "failedToSaveTheBoundCombined",
  VARIATION_CHANNEL_VARIATION_RELATION_LIMIT_EXCEEDED: "bindingFailedTheChannelSkuBound",
  MASTER_VARIATION_MAX_LIMIT_EXCEEDED: "failedToSaveTheVariationsOf"
};
