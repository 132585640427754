import {lazy} from "../../lib/lazy.js";
export const promotionRoutes = [
  {
    path: "/promotion-management",
    auth: "list-promotion",
    component: lazy(() => import(/* webpackChunkName: "promotionRoutes" */ "../../views/promotion-management/index.js")),
    meta: {
      openKeys: ["menu-sub-promotion", "menu-sub-promotion-management"],
      selectedKeys: ["promotion-management"]
    }
  },
  {
    path: "/promotion-tpl",
    auth: "list-promotion-tpl",
    component: lazy(() => import(/* webpackChunkName: "promotionRoutes" */ "../../views/promotion-tpl/index.js")),
    meta: {
      openKeys: ["menu-sub-promotion", "menu-sub-promotion-management"],
      selectedKeys: ["promotion-tpl"]
    }
  },
  {
    path: "/promotion-add-tpl",
    component: lazy(() => import(/* webpackChunkName: "promotionRoutes" */ "../../views/promotion-tpl/NewPromotionTpl.js"))
  },
  {
    path: "/promotion-edit-tpl/:tplId",
    component: lazy(() => import(/* webpackChunkName: "promotionRoutes" */ "../../views/promotion-tpl/NewPromotionTpl.js"))
  },
  {
    path: "/promotion-management/add",
    component: lazy(() => import(/* webpackChunkName: "promotionRoutes" */ "../../views/promotion-management/add.js"))
  },
  {
    path: "/promotion-management/add-tiktok",
    component: lazy(() => import(/* webpackChunkName: "promotionRoutes" */ "../../views/promotion-management/add-tiktok.js"))
  },
  {
    path: "/promotion-management/edit",
    component: lazy(() => import(/* webpackChunkName: "promotionRoutes" */ "../../views/promotion-management/edit.js"))
  },
  {
    path: "/promotion-management/edit-tiktok",
    component: lazy(() => import(/* webpackChunkName: "promotionRoutes" */ "../../views/promotion-management/edit-tiktok.js"))
  },
  {
    path: "/promotion-management/detail",
    component: lazy(() => import(/* webpackChunkName: "promotionRoutes" */ "../../views/promotion-management/PromotionDetail.js"))
  },
  {
    path: "/promotion-management/mass-add",
    component: lazy(() => import(/* webpackChunkName: "promotionRoutes" */ "../../views/promotion-management/MassPromotion.js"))
  },
  {
    path: "/promotion-management/import-add",
    component: lazy(() => import(/* webpackChunkName: "promotionRoutes" */ "../../views/promotion-import/index.js"))
  },
  {
    path: "/promotion-tpl/promotion-add-tpl",
    component: lazy(() => import(/* webpackChunkName: "promotionRoutes" */ "../../views/promotion-tpl/NewPromotionTpl.js"))
  },
  {
    path: "/promotion-tpl/promotion-edit-tpl/:tplId",
    component: lazy(() => import(/* webpackChunkName: "promotionRoutes" */ "../../views/promotion-tpl/NewPromotionTpl.js"))
  },
  {
    path: "/diagnose-shop",
    component: lazy(() => import(/* webpackChunkName: "promotionRoutes" */ "../../views/shop-diagnose/index.js"))
  },
  {
    path: "/auto-bump",
    component: lazy(() => import(/* webpackChunkName: "promotionRoutes" */ "../../views/auto-bump/index.js")),
    auth: "auto-bump",
    meta: {
      openKeys: ["menu-sub-promotion"]
    }
  },
  {
    path: "/auto-bump/edit/:id/:shopName/:externalShopId/:shopId",
    component: lazy(() => import(/* webpackChunkName: "promotionRoutes" */ "../../views/auto-bump/edit.js"))
  },
  {
    path: "/diagnose-shop/detail/:shopId",
    component: lazy(() => import(/* webpackChunkName: "promotionRoutes" */ "../../views/shop-diagnose/detail.js"))
  },
  {
    path: "/promotion-management/promotion-la",
    exact: true,
    component: lazy(() => import(/* webpackChunkName: "PromotionLazada" */ "../../views/promotion-la/index.js")),
    children: []
  },
  {
    path: "/promotion-management/manage-product",
    exact: true,
    component: lazy(() => import(/* webpackChunkName: "PromotionLazada" */ "../../views/promotion-la/ManageDiscountProduct.js")),
    children: []
  }
];
