import {lazy} from "../../lib/lazy.js";
export const warehouseStockOrderRoutes = [
  {
    path: "/warehouse-stock-order",
    component: lazy(() => import(/* webpackChunkName: "warehouseStockOrderRoutes" */ "../../views/warehouse-stock-order/index.js")),
    auth: "inv-calibration-order"
  },
  {
    path: "/warehouse-stock-order/edit-stock-order/:id",
    component: lazy(() => import(/* webpackChunkName: "warehouseStockOrderRoutes" */ "../../views/warehouse-stock-order/Edit.js"))
  },
  {
    path: "/warehouse-stock-order/detail-stock-order/:id",
    component: lazy(() => import(/* webpackChunkName: "warehouseStockOrderRoutes" */ "../../views/warehouse-stock-order/Detail.js"))
  }
];
