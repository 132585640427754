import React from "../../../../__snowpack__/pkg/react.js";
export const IconLogisticsIcon = React.memo((props) => {
  return /* @__PURE__ */ React.createElement("svg", {
    width: "18px",
    height: "18px",
    viewBox: "0 0 18 18",
    version: "1.1",
    xmlns: "http://www.w3.org/2000/svg",
    ...props,
    className: props.className ? `${props.className} fill-name-box` : "fill-name-box"
  }, /* @__PURE__ */ React.createElement("title", null, "\u7F16\u7EC4 5"), /* @__PURE__ */ React.createElement("g", {
    id: "\u8D22\u52A1\u53CA\u6388\u6743",
    stroke: "none",
    "stroke-width": "1",
    fill: "none",
    "fill-rule": "evenodd"
  }, /* @__PURE__ */ React.createElement("g", {
    id: "\u67E5\u8BE2\u8FD0\u8D39",
    transform: "translate(-16.000000, -467.000000)"
  }, /* @__PURE__ */ React.createElement("g", {
    id: "\u7F16\u7EC4-5",
    transform: "translate(16.000000, 467.000000)"
  }, /* @__PURE__ */ React.createElement("rect", {
    id: "\u77E9\u5F62",
    x: "0",
    y: "0",
    width: "18",
    height: "18"
  }), /* @__PURE__ */ React.createElement("path", {
    d: "M11.3666667,3.7 L11.3666667,11.4984667 L11.3326667,11.5188667 C11.0697333,11.7024667 10.8408,11.9348 10.6628667,12.2 L6.40266667,12.2 L6.39473333,12.1864 C5.91986667,11.4848667 5.12993333,11.0666667 4.28333333,11.0666667 C3.50813333,11.0666667 2.78053333,11.4202667 2.3,12.013 L2.3,3.7 L11.3666667,3.7 Z M4.28333333,14.4666667 C3.81413333,14.4666667 3.43333333,14.0847333 3.43333333,13.6166667 C3.43333333,13.1486 3.81413333,12.7666667 4.28333333,12.7666667 C4.75253333,12.7666667 5.13333333,13.1486 5.13333333,13.6166667 C5.13333333,14.0847333 4.75253333,14.4666667 4.28333333,14.4666667 L4.28333333,14.4666667 Z M12.7833333,14.4666667 C12.3152667,14.4666667 11.9333333,14.0847333 11.9333333,13.6166667 C11.9333333,13.1486 12.3152667,12.7666667 12.7833333,12.7666667 C13.2514,12.7666667 13.6333333,13.1486 13.6333333,13.6166667 C13.6333333,14.0847333 13.2514,14.4666667 12.7833333,14.4666667 L12.7833333,14.4666667 Z M15.6948667,12.2 L14.9026667,12.1988667 L14.8879333,12.1762 C14.4663333,11.5608 13.7988,11.1641333 13.0666667,11.0825333 L13.0666667,9.5854 L13.0666667,7.8854 L13.0666667,6.947 L14.8686667,6.947 L15.6948667,12.2 Z M16.5199333,6.47666667 C16.4258667,5.77513333 15.8229333,5.247 15.1157333,5.247 L13.0666667,5.247 L13.0666667,3.41666667 C13.0666667,2.6358 12.4308667,2 11.65,2 L2.01666667,2 C1.2358,2 0.6,2.6358 0.6,3.41666667 L0.6,12.4833333 C0.6,13.1712667 1.09413333,13.747 1.7458,13.8750667 C1.87613333,15.1648 2.97773333,16.1666667 4.28333333,16.1666667 C5.59346667,16.1666667 6.6758,15.1727333 6.81746667,13.9 L10.2492,13.9 C10.3908667,15.1784 11.4856667,16.1666667 12.7833333,16.1666667 C14.0934667,16.1666667 15.1758,15.1727333 15.3174667,13.9 L16.0212667,13.9 C16.0847333,13.9 16.1482,13.8954667 16.2094,13.8875333 C16.5845333,13.8365333 16.9166,13.6438667 17.1478,13.3435333 C17.3778667,13.0432 17.4764667,12.6714667 17.4266,12.2940667 L16.5199333,6.47666667 Z",
    id: "Fill-1",
    fill: "#606C80"
  })))));
});
