import {lazy} from "../../lib/lazy.js";
export const productSettingRoutes = [
  {
    path: "/setting/new-master-product-settings",
    auth: "product-settings",
    component: lazy(() => import(/* webpackChunkName: "productSettingRoutes" */ "../../views/setting/MasterProductSettings.js"))
  },
  {
    path: "/product-setting/product-logistics-template",
    component: lazy(() => import(/* webpackChunkName: "productSettingRoutes" */ "../../views/product-logistics-template/index.js")),
    auth: "product-settings"
  },
  {
    path: "/product-setting/product-size-template",
    component: lazy(() => import(/* webpackChunkName: "productSettingRoutes" */ "../../views/product-size-template/index.js"))
  },
  {
    path: "/product-setting/product-category-mapping",
    component: lazy(() => import(/* webpackChunkName: "productSettingRoutes" */ "../../views/product-category-mapping/index.js")),
    auth: "product-settings"
  },
  {
    path: "/product-setting/setting-category-mapping",
    component: lazy(() => import(/* webpackChunkName: "productSettingRoutes" */ "../../views/setting-category-mapping/index.js")),
    auth: "product-settings"
  },
  {
    path: "/setting/copyStore",
    auth: "clone-shop",
    component: lazy(() => import(/* webpackChunkName: "productSettingRoutes" */ "../../views/setting/CopyStore/index.js"))
  },
  {
    path: "/setting/index",
    auth: "settings",
    component: lazy(() => import(/* webpackChunkName: "productSettingRoutes" */ "../../views/setting/Settings/index.js"))
  }
];
