import {lazy} from "../../lib/lazy.js";
export const productTikiRoutes = [
  {
    name: "ProductTiki",
    path: "/product-tiki",
    exact: false,
    component: lazy(() => import("../../views/product-tiki/list/index.js")),
    children: [],
    auth: "list-product"
  }
];
