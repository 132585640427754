import {lazy} from "../../lib/lazy.js";
export const productLocalPriceRoutes = [
  {
    path: "/product-2local-price/mass-edit/:ids",
    component: lazy(() => import(/* webpackChunkName: "productLocalPriceRoutes" */ "../../views/product-local-price/mass-edit.js"))
  },
  {
    path: "/product-2local-price",
    auth: "price-management",
    component: lazy(() => import(/* webpackChunkName: "productLocalPriceRoutes" */ "../../views/product-local-price/index.js")),
    meta: {
      openKeys: ["menu-sub-master-products"]
    }
  }
];
