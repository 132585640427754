import React from "../../../../__snowpack__/pkg/react.js";
export const IconMenuCapital = React.memo((props) => {
  return /* @__PURE__ */ React.createElement("svg", {
    xmlns: "http://www.w3.org/2000/svg",
    width: "18",
    height: "18",
    viewBox: "0 0 18 18",
    ...props
  }, /* @__PURE__ */ React.createElement("g", {
    fill: "none",
    fillRule: "evenodd",
    stroke: "none",
    strokeWidth: "1"
  }, /* @__PURE__ */ React.createElement("g", {
    fill: "currentColor",
    fillRule: "nonzero",
    transform: "translate(-12 -484)"
  }, /* @__PURE__ */ React.createElement("g", {
    transform: "translate(12 484)"
  }, /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", {
    d: "M8.927 0a8.928 8.928 0 018.927 8.927A8.927 8.927 0 118.927 0zm.012 1.518c-4.087 0-7.412 3.325-7.412 7.411 0 4.087 3.325 7.412 7.412 7.412 4.086 0 7.411-3.325 7.411-7.412 0-4.086-3.325-7.411-7.411-7.411z"
  }), /* @__PURE__ */ React.createElement("path", {
    d: "M11.467 9.094c-.403-.411-1.277-.89-2.43-1.258-.686-.218-1.08-.388-1.08-.908 0-.726.457-.889 1.036-.889.446 0 1.102.54 1.102.977l1.771-.416c0-.337-.258-.898-.767-1.335-.409-.348-.941-.55-1.588-.62v-.937H8.333v.939c-.684.084-1.165.307-1.576.656-.423.355-.684.946-.684 1.532 0 .678.105 1.264.484 1.676.376.413 1.739.876 2.413 1.027.65.147 1.253.556 1.257 1.125.007.695-.632.958-1.167.958-.897 0-1.499-.667-1.499-1.25l-1.774.434c.114.618.29 1.111.87 1.572.437.35.955.564 1.676.65v1.119H9.51v-1.102a3.292 3.292 0 001.711-.66c.554-.414.844-.942.844-1.72 0-.633-.2-1.154-.6-1.57z"
  }))))));
});
