import React, {memo, useMemo} from "../../../../__snowpack__/pkg/react.js";
import {Breadcrumb} from "../../../../__snowpack__/pkg/antd.js";
import {useLocation} from "../../../../__snowpack__/pkg/react-router.js";
import {useIntl} from "../../../../__snowpack__/pkg/react-intl.js";
import QueryString from "../../../../__snowpack__/pkg/query-string.js";
export const HeaderBreadcrumb = memo(() => {
  const {formatMessage} = useIntl();
  const location = useLocation();
  const {search} = location;
  const urlParams = useMemo(() => {
    return search ?? "";
  }, [search]);
  const searchParams = QueryString.parse(search);
  const {channel} = searchParams || {};
  const BreadcrumbMap = {
    "pos-setting-edit": {name: formatMessage({id: "posSettings"})},
    "order-list": {name: "purchasePurchaseOrder", isLink: true},
    "order-detail-add": {
      isLink: true,
      text: `${formatMessage({id: "purchasePurchaseOrder"})}/${formatMessage({id: "add"})}`
    },
    "order-detail-edit": {
      isLink: true,
      text: `${formatMessage({id: "purchasePurchaseOrder"})}/${formatMessage({id: "edit"})}`
    },
    "push-third-party": {
      text: `${formatMessage({id: "thirdpartyWarehousePushSettings"})}`
    },
    "strategy-list": {
      text: `${formatMessage({id: "thirdpartyWarehousePushSettings"})} / ${formatMessage({
        id: "manageCustomPolicies"
      })}`
    },
    "order-detail-detail": {
      name: "\u67E5\u770B\u91C7\u8D2D\u8BA2\u5355",
      isLink: true,
      text: `${formatMessage({id: "purchasePurchaseOrder"})}/${formatMessage({id: "detail"})}`
    },
    "supplier-list": {
      isLink: true,
      text: `${formatMessage({id: "purchase"})}/${formatMessage({id: "supplierManagement"})}`
    },
    "diagnose-shop": {name: "storeDiagnose", isLink: true, permission: ""},
    dashboard: {name: "menuHomePage", isLink: true, permission: "dashboard"},
    "notice-list": {name: "noticeList", isLink: true, permission: ""},
    "member-list": {
      name: "menuMemberList",
      isLink: true,
      permission: "list-member"
    },
    "member-info": {
      name: "memberInfo",
      isLink: false,
      permission: "edit-member-basic-info"
    },
    "member-ship": {
      name: "menuMemberShipPage",
      isLink: true,
      permission: "list-member"
    },
    points: {name: "menuPoints", isLink: true, permission: "point-rule"},
    product: {name: "menuProduct", isLink: false, permission: "list-product"},
    add: {name: "add", isLink: false, permission: "list-product"},
    edit: {name: "edit", isLink: false, permission: "list-product"},
    detail: {name: "detail", isLink: false, permission: null},
    "product-woo-commerce": {
      name: "menuProductWooCommerce",
      isLink: false,
      permission: "list-product"
    },
    "mass-edit-category": {
      name: "edit",
      isLink: false
    },
    "product-tiktok": {
      name: "menuProductTikTok",
      isLink: false,
      permission: "list-product"
    },
    "product-tiktok-add": {
      name: "menuProductTikTokAdd",
      isLink: false,
      permission: "list-product"
    },
    "product-tiktok-edit": {
      name: "menuProductTikTokEdit",
      isLink: false,
      permission: "list-product"
    },
    "product-tiki": {
      name: "menuProductTiki",
      isLink: false,
      permission: "list-product"
    },
    "product-zalora": {
      name: "menuProductZalora",
      isLink: false,
      permission: "list-product"
    },
    "product-shopify": {
      name: "menuProductShopify",
      isLink: false,
      permission: "list-product"
    },
    "product-shopify-add": {
      name: "menuProductShopifyAdd",
      isLink: false,
      permission: "list-product"
    },
    "modify-warehouse": {
      name: `${formatMessage({id: "menuSetting"})} / ${formatMessage({id: "channelWarehouseManagement"})}`,
      isLink: false
    },
    "product-shopify-edit": {
      name: "menuProductShopifyEdit",
      isLink: false,
      permission: "list-product"
    },
    "product-moka": {
      name: "menuProductMoka",
      isLink: false,
      permission: "list-product"
    },
    "product-moka-add": {
      name: "menuProductMokaAdd",
      isLink: false,
      permission: "list-product"
    },
    "product-moka-edit": {
      name: "menuProductMokaEdit",
      isLink: false,
      permission: "list-product"
    },
    "product-tokopedia-add": {
      name: "productsChannelAddProduct",
      isLink: false,
      permission: "list-product",
      extend: {channel: "Tokopedia"}
    },
    "product-tokopedia-edit": {
      name: "productsChannelEditProduct",
      isLink: false,
      permission: "list-product",
      extend: {channel: "Tokopedia"}
    },
    "product-tokopedia-mass-edit": {
      isLink: true,
      text: `Tokopedia / ${formatMessage({id: "massEdit"})}`
    },
    "product-tokopedia-mass-edit-category-attribute": {
      text: `${formatMessage({id: "channelProductMassEdit"}, {channel: "Todopedia"})}`,
      isLink: false,
      permission: "list-product"
    },
    "product-lazada-add": {
      name: "productsChannelAddProduct",
      isLink: false,
      permission: "list-product",
      extend: {channel: "Lazada"}
    },
    "product-lazada-edit": {
      name: "productsChannelEditProduct",
      isLink: false,
      permission: "list-product",
      extend: {channel: "Lazada"}
    },
    "product-bukalapak": {
      text: `${formatMessage({id: "breadCrumbProductList"}, {channel: "Bukalapak"})}`,
      permission: "list-product"
    },
    "product-bukalapak-add": {
      text: `${formatMessage({id: "productsChannelAddProduct"}, {channel: "Bukalapak"})}`,
      permission: "list-product"
    },
    "product-bukalapak-edit": {
      text: `${formatMessage({id: "productsChannelEditProduct"}, {channel: "Bukalapak"})}`,
      permission: "list-product"
    },
    "product-shopee": {
      name: "menuProductShopee",
      isLink: false,
      permission: "list-product"
    },
    "product-shopee-cnsc": {
      text: `${formatMessage({id: "globalProduct"})} / Shopee`,
      isLink: false,
      permission: "list-product"
    },
    "product-line-shopping": {
      name: `${formatMessage({id: "menuProduct"})} / Line Shopping`,
      isLink: false
    },
    "product-magento": {
      name: `${formatMessage({id: "menuProduct"})} / Magento`,
      isLink: false
    },
    "product-ginee-pos": {
      name: `${formatMessage({id: "menuProduct"})} / Ginee POS`,
      isLink: false
    },
    "product-shopee-add": {
      name: "menuProductShopeeAdd",
      isLink: false,
      permission: "list-product"
    },
    "product-shopee-edit": {
      name: "menuProductShopeeEdit",
      isLink: false,
      permission: "list-product"
    },
    "product-shopee-mass-edit-category-attribute": {
      name: "menuProductShopeeEdit",
      isLink: false,
      permission: "list-product"
    },
    "product-shopee-mass-edit": {
      name: "menuProductShopeeEdit",
      isLink: false,
      permission: "list-product"
    },
    "product-local": {
      name: "menuProductLocal",
      isLink: false,
      permission: "master-product-listing"
    },
    "product-local-bind-history": {
      name: "menuBindHistory",
      isLink: false,
      permission: "master-product-listing"
    },
    "product-local-add": {
      name: "menuProductLocalAdd",
      isLink: false,
      permission: "master-product-listing"
    },
    "product-local-edit": {
      name: "menuProductLocalEdit",
      isLink: false,
      permission: "master-product-listing"
    },
    "product-local-add-bundle": {
      name: "menuProductLocalAddCombined",
      isLink: false,
      permission: "master-product-listing"
    },
    "product-local-edit-bundle": {
      name: "menuProductLocalEditCombined",
      isLink: false,
      permission: "master-product-listing"
    },
    "product-local-import-to-bind": {
      name: "menuProductLocalImportToBind",
      isLink: false,
      permission: "master-product-listing"
    },
    "product-local-import-to-create": {
      name: "menuProductLocalImportToCreate",
      isLink: false,
      permission: "master-product-listing"
    },
    "product-local-update-auto-bind": {
      name: "menuProductLocalUpdateAutoBind",
      isLink: false,
      permission: "master-product-listing"
    },
    "product-local-publish": {
      name: "productPublishToTheStoreMaster",
      isLink: false,
      permission: "master-product-listing"
    },
    "task-center": {
      name: "operationHistoryMass",
      isLink: false,
      permission: "master-product-listing"
    },
    "product-local-publish-result": {
      name: "resultsPublish",
      isLink: false,
      permission: "master-product-listing"
    },
    "product-2local-price": {
      name: "menuPriceManagement",
      isLink: true,
      permission: "master-product-listing"
    },
    "warehouse-management": {
      name: "warehouseList",
      isLink: true,
      permission: "inventory-management-2.0"
    },
    "warehouse-shipping-settings": {
      name: "menuStockPushRules",
      isLink: true,
      permission: "inventory-management-2.0"
    },
    "stock-push-new": {
      name: "menuStockPushRules",
      isLink: true,
      permission: "store-stock-push-rules"
    },
    "stockpush-add-new-rule": {
      name: "stockPushRuleaddRule",
      isLink: false,
      permission: "store-push-rule-create"
    },
    "stockpush-add-rule": {
      name: "menuStockPushRules",
      isLink: false,
      permission: "store-push-rule-update"
    },
    "stockpush-rule-detail": {
      name: "peraturanTolakStoklihatPerincian",
      isLink: false,
      permission: "store-stock-push-rules"
    },
    "stock-push-main-store-stock-lock": {
      name: "inventoryPushRulesMainStore",
      isLink: false,
      permission: "store-push-rule-main-lock"
    },
    "stock-push-operation-log": {
      name: "inventoryPushRuleOperationLog",
      isLink: false,
      permission: "store-stock-push-rules"
    },
    "warehouse-stock-details": {
      name: "menuWarehouseStockDetails",
      isLink: true,
      permission: "inventory-management-2.0"
    },
    "product-location-management": {
      name: "menuProductLocationManagement",
      isLink: true,
      permission: "warehouse-management"
    },
    "product-info": {name: "productDetail", isLink: false, permission: null},
    "copy-product": {
      name: "copyProduct",
      isLink: false,
      permission: "list-product"
    },
    integrations: {
      name: "menuIntegrations",
      isLink: true,
      permission: "integrations-store"
    },
    "logistics-address-settings": {
      name: "shippingSettings1",
      isLink: true
    },
    "manual-order-add": {name: "manualOrder", isLink: false, permission: null},
    "arrange-delivery": {name: "ArrangeShipment2", isLink: false, permission: null},
    "push-warehouse": {name: "pushWarehouse", isLink: false, permission: null},
    "problem-order": {name: "problemOrder", isLink: false, permission: null},
    order: {name: "nearlyDaysOrder", isLink: true, permission: "list-order"},
    pending: {name: "pending", isLink: true, permission: "list-order"},
    shelved: {name: "stored", isLink: true, permission: "list-order"},
    shipped: {name: "shipped", isLink: true, permission: "list-order"},
    "abnormal-order": {name: "orderAbnormal", isLink: true, permission: "list-order"},
    "history-order": {name: "historicalOrders", isLink: true, permission: "history-order"},
    "order-refund-list": {name: "refundList", isLink: false, permission: null},
    "order-return-refund-list": {name: "returnRefund", isLink: false, permission: null},
    "print-tpl-setting": {
      name: urlParams.includes("LABEL_TPL") && "PrintLabelTemplate" || urlParams.includes("PACKAGE_TPL") && "PrintPackingListTemplate" || urlParams.includes("INVOICE_TPL") && "PrintInvoiceTemplate" || urlParams.includes("PICKING_TPL") && "PrintPickingListTemplate",
      isLink: false,
      permission: null
    },
    "print-invoice-tpl": {name: "PrintInvoiceTemplate", isLink: false, permission: null},
    "shipping-setting": {name: "ShippingSettingsTab", isLink: false, permission: null},
    "commission-setting": {name: "commissionSetting", isLink: false, permission: null},
    "tool-setting": {name: "utilityTool", isLink: false, permission: null},
    "scan-shipping": {name: "scanShipment", isLink: true, permission: null},
    "explosive-print-order": {name: "printPopularProduct", isLink: true, permission: null},
    "export-template-setting": {name: "ExportTemplateTab", isLink: false, permission: null},
    "distribution-setting": {name: "distributionSettings", isLink: false, permission: null},
    "invoice-setting": {name: "LAInvoiceSettingsTab", isLink: false, permission: null},
    "waiting-packed": {
      name: "printOrderPending",
      isLink: true,
      permission: "list-order"
    },
    "waiting-collected": {
      name: "beCollectedTo",
      isLink: true,
      permission: "list-order"
    },
    "warehouse-settings": {
      name: "warehouseSettingShipping",
      isLink: true,
      permission: "list-order"
    },
    "operation-log": {
      name: "operationRecord",
      isLink: true,
      permission: "list-order"
    },
    "add-warehouse-setting": {
      name: "newRule",
      isLink: true,
      permission: "list-order"
    },
    "warehouse-history-operation": {
      name: "historyOperation",
      isLink: true,
      permission: "list-order"
    },
    abnormal: {
      name: "orderAbnormal",
      isLink: true,
      permission: "unusual-order-management"
    },
    "shipping-order": {
      name: "ShippingOrderNew",
      isLink: true,
      permission: "list-order"
    },
    auth: {name: "authPlatform", isLink: false, permission: null},
    "order-detail": {
      name: "orderDetails",
      isLink: false,
      permission: "list-order"
    },
    "add-store": {name: "addStore", isLink: false, permission: "add-store"},
    "basic-setting": {name: "BasicSetting", isLink: true, permission: null},
    "mass-edit": {
      name: "MassEdit",
      isLink: false,
      permission: "list-product"
    },
    "mass-category": {
      name: "Masseditcategories",
      isLink: false,
      permission: "list-product"
    },
    "mass-attribute": {
      name: "Masseditcategories",
      isLink: false,
      permission: "list-product"
    },
    "bar-chart": {
      name: "BarChart",
      isLink: true,
      permission: "report-management"
    },
    "bar-chart-store": {
      name: "TopPopularStore",
      isLink: true,
      permission: "top-popular-store"
    },
    "bar-chart-subscription": {
      name: "ExclusiveSubscription",
      isLink: true,
      permission: "exclusive-subscription"
    },
    "stock-manage": {
      name: "stockManage",
      isLink: true,
      permission: "inventory-management"
    },
    "add-isku": {name: "addISKU", isLink: false, permission: "inventory-management"},
    "bind-psku": {name: "binPsku", isLink: false, permission: "inventory-management"},
    "add-bundle-isku": {
      name: "addBundleISKU",
      isLink: false,
      permission: "inventory-management"
    },
    "edit-isku": {name: "editISKU", isLink: false, permission: "inventory-management"},
    "edit-local-isku": {
      name: "editLocalISKU",
      isLink: false,
      permission: "inventory-management"
    },
    "edit-bundle-isku": {
      name: "editBundleISKU",
      isLink: false,
      permission: "inventory-management"
    },
    "edit-warehouse-stock": {
      name: "editWarehouseStock",
      isLink: false,
      permission: "inventory-management"
    },
    "inventory-log": {
      name: "InventoryLog",
      isLink: true,
      permission: "inventory-management"
    },
    "stock-in-out-record": {
      name: "stockInOutRecord",
      isLink: true,
      permission: "inventory-management"
    },
    "store-stock-change-history": {
      name: "storeStockChangeHistory",
      isLink: true,
      permission: "inventory-management-2.0"
    },
    "stock-safety-settings": {
      name: "safetyStockSettings",
      isLink: true,
      permission: "inventory-management-2.0"
    },
    "outbound-management": {
      name: "menuOutboundManagement",
      isLink: true,
      permission: "warehouse-management"
    },
    "outbound-management-add": {
      name: "menuOutboundManagementAdd",
      isLink: false,
      permission: "warehouse-management"
    },
    "outbound-management-edit": {
      name: "menuOutboundManagementEdit",
      isLink: false,
      permission: "warehouse-management"
    },
    "outbound-management-detail": {
      name: "menuOutboundManagementDetail",
      isLink: false,
      permission: "warehouse-management"
    },
    "outbound-management-submit": {
      name: "menuOutboundManagementSubmit",
      isLink: false,
      permission: "warehouse-management"
    },
    "warehouse-stock-inventory": {
      name: "stoktaking",
      isLink: true,
      permission: null
    },
    "warehouse-stock-order": {
      name: "stockAdjustment",
      isLink: true,
      permission: null
    },
    "inbound-management": {
      name: "menuInboundManagement",
      isLink: true,
      permission: "warehouse-management"
    },
    "inbound-management-add": {
      name: "menuInboundManagementAdd",
      isLink: false,
      permission: "warehouse-management"
    },
    "inbound-management-edit": {
      name: "menuInboundManagementEdit",
      isLink: false,
      permission: "warehouse-management"
    },
    "inbound-management-detail": {
      name: "menuInboundManagementDetail",
      isLink: false,
      permission: "warehouse-management"
    },
    "inbound-management-submit": {
      name: "menuInboundManagementSubmit",
      isLink: false,
      permission: "warehouse-management"
    },
    "stock-manage-local": {
      name: "stockManageList",
      isLink: true,
      permission: "inventory-management-2.0"
    },
    "stock-reserve": {
      name: "activeReserveInventory",
      isLink: true,
      permission: "promotion-stock-search"
    },
    "inventory-log-local": {
      name: "InventoryLog",
      isLink: true,
      permission: "inventory-management-2.0"
    },
    "stock-in-out-record-local": {
      name: "stockInOutRecord",
      isLink: true,
      permission: "inventory-management-2.0"
    },
    "warehouse-inventory": {
      name: "thirdPartyWarehouseStocktakingList",
      isLink: true,
      permission: "warehouse-management"
    },
    "warehouse-inventory-detail": {
      name: "thirdPartyWarehouseStocktakingList",
      isLink: true,
      permission: "warehouse-management"
    },
    "warehouse-product-list": {
      name: "menuThirdPartyWarehouseProductList",
      isLink: true,
      permission: "warehouse-management"
    },
    "warehouse-keeppack-product": {
      name: "warehouseKeeppackWarehouseProduct",
      isLink: true,
      permission: "warehouse-management"
    },
    "keeppack-inbound-management": {
      name: "warehouseKeeppackWarehouseInboundManagement",
      isLink: true,
      permission: "warehouse-management"
    },
    "keeppack-outbound-management": {
      name: "warehouseKeeppackWarehouseOutboundManagement",
      isLink: true,
      permission: "warehouse-management"
    },
    "keeppack-outbound-inventory": {
      name: "warehouseKeeppackWarehouse",
      isLink: true,
      permission: "warehouse-management"
    },
    "keeppack-create-master-product": {
      name: "warehousesKeeppackWarehousesListings",
      isLink: true,
      permission: "warehouse-management"
    },
    "keeppack-fulfillment": {
      name: "configManagement",
      isLink: true,
      permission: "warehouse-management"
    },
    "warehouse-kongfu-product": {
      name: "warehouseBreadcrumbProduct",
      extend: {warehouseName: "KongFu"},
      isLink: true,
      permission: "warehouse-management"
    },
    "kongfu-inbound-management": {
      name: "warehouseBreadcrumbInbound",
      extend: {warehouseName: "KongFu"},
      isLink: true,
      permission: "warehouse-management"
    },
    "kongfu-inbound-management-add": {
      name: "warehouseBreadcrumbInbound",
      extend: {warehouseName: "KongFu"},
      isLink: true,
      permission: "warehouse-management"
    },
    "kongfu-inbound-management-edit": {
      name: "warehouseBreadcrumbInbound",
      extend: {warehouseName: "KongFu"},
      isLink: true,
      permission: "warehouse-management"
    },
    "kongfu-outbound-management": {
      name: "warehouseBreadcrumbOutbound",
      extend: {warehouseName: "KongFu"},
      isLink: true,
      permission: "warehouse-management"
    },
    "kongfu-outbound-inventory": {
      name: "warehouseBreadcrumbPullRecord",
      extend: {warehouseName: "KongFu"},
      isLink: true,
      permission: "warehouse-management"
    },
    "kongfu-fulfillment": {
      name: "configManagement",
      isLink: true,
      permission: "warehouse-management"
    },
    "stock-pull-record": {
      name: "stockPullRecord",
      isLink: true,
      permission: "warehouse-management"
    },
    "stock-pull-record-detail": {
      name: "stockPullRecordDetail",
      isLink: false,
      permission: "warehouse-management"
    },
    "add-product-to-warehouse": {
      name: "menuAddProductToWarehouse",
      isLink: false,
      permission: "inventory-management-2.0"
    },
    "add-master-product-to-warehouse": {
      name: "addToWarehouse",
      isLink: false,
      permission: "inventory-management-2.0"
    },
    "add-product-to-warehouse-edit-stock": {
      name: "menuAddProductToWarehouseEditStock",
      isLink: false,
      permission: "inventory-management-2.0"
    },
    "stock-setting": {
      name: "StockSetting",
      isLink: true,
      permission: "stocks-settings"
    },
    "new-master-product-settings": {
      name: "MasterProductSettings",
      isLink: true,
      permission: "master-product-listing"
    },
    "staff-management": {
      name: "StaffSetting",
      isLink: true,
      permission: "list-staff"
    },
    "staff-management-add": {
      name: "staffManagementAdd",
      isLink: true,
      permission: "add-staff"
    },
    "staff-management-edit": {
      name: "staffManagementEdit",
      isLink: true,
      permission: "edit-staff"
    },
    "staff-info": {
      name: "StaffSetting",
      isLink: true,
      permission: "list-staff"
    },
    "role-management": {
      name: "RoleSetting",
      isLink: true,
      permission: "list-role"
    },
    addrole: {name: "addrole", isLink: true, permission: "add-role"},
    editrole: {name: "editrole", isLink: false, permission: "edit-role"},
    addstaff: {name: "addstaff", isLink: true, permission: "add-staff"},
    editstaff: {name: "editstaff", isLink: true, permission: "edit-staff"},
    "customer-management": {
      name: "CustomerManagement",
      isLink: true,
      permission: "list-customer"
    },
    "group-management": {
      name: "GroupManagement",
      isLink: false,
      permission: null
    },
    "add-customer": {
      name: "NewCustomer",
      isLink: false,
      permission: "add-customer"
    },
    "edit-customer": {
      name: "EditCustomer",
      isLink: false,
      permission: "edit-customer"
    },
    "customer-detail": {
      name: "CustomerDetail",
      isLink: false,
      permission: "list-customer"
    },
    "indent-report": {name: "menuOrderReport", isLink: true},
    "master-product-sales-analysis": {name: "masterProductSales", isLink: true},
    "product-activity": {name: "masterProductActivity", isLink: true},
    "stockout-prediction": {name: "menuOutStockForecast", isLink: true},
    unsalable: {name: "menuLowStockReport", isLink: true},
    "stock-value": {name: "stockValue", isLink: true},
    "product-report": {name: "menuProductSaleReport", isLink: true},
    "product-category-sale-report": {
      name: "menuVariantsSalesStatistics",
      isLink: true
    },
    "category-report": {name: "menuCategorySaleReport", isLink: true},
    "profit-report": {name: "menuIncomeStatementReport", isLink: true},
    "platform-report": {name: "menuPlatformSaleReport", isLink: true},
    "store-report": {name: "menuStoreSaleReport", isLink: true},
    "level-two-detail": {name: "CategoryLevel2", isLink: false},
    "level-three-detail": {name: "CategoryLevel3", isLink: false},
    "promotion-management": {
      name: "PromotionManagement",
      isLink: true,
      permission: "list-promotion"
    },
    "promotion-la": {name: "edit", isLink: false},
    "manage-product": {name: "productManagement", isLink: false},
    report: {name: "ReportChart", isLink: true},
    "order-setting": {name: "OrderSettings", isLink: true},
    "method-setting": {name: "ShippingRuleSettings", isLine: true},
    "sender-setting": {name: "SenderInformationSettings", isLine: true},
    "order-batch-label": {name: "OrderBatchLabel", isLink: false},
    "normal-print-task-detail": {name: "printTaskDetails", isLink: false},
    "print-task-detail": {name: "highSpeedPrintTaskDetails", isLink: false},
    "scraping-settings": {name: "menuScrapingSettings", isLink: true},
    "transaction-record": {name: "TransactionHistory", isLink: true},
    "package-subscription": {name: "PackageSubscription", isLink: false},
    "pay-subscription-results": {
      name: "PaymentSubscriptionResult",
      isLink: false
    },
    "pay-subscription-results-auth": {
      name: "PaymentSubscriptionResult",
      isLink: false
    },
    "pay-subscription-success": {
      name: "PaymentSubscriptionResult",
      isLink: false
    },
    "pay-subscription-error": {
      name: "PaymentSubscriptionResult",
      isLink: false
    },
    "pay-subscription-undone": {
      name: "PaymentSubscriptionResult",
      isLink: false
    },
    "package-subscription-price": {
      name: "PaymentSubscriptionPrice",
      isLink: false
    },
    "crm-tele": {
      name: "CRMTelemarketing",
      isLink: true,
      permission: "crm-tele-sales"
    },
    "crm-sales": {name: "CRMSales", isLink: true, permission: "crm-sales"},
    "crm-sales-history": {name: "CRMHistory", isLink: false},
    "crm-tele-history": {name: "CRMHistory", isLink: false},
    "add-temp": {name: "AddOrderTpl", isLink: false},
    "edit-temp": {name: "EditOrderTpl", isLink: false},
    "label-tpl-setting": {name: "PrintLabelTemplate", isLink: false},
    "packing-tpl-setting": {name: "PrintPackingListTemplate", isLink: false},
    "invoice-tpl-setting": {name: "PrintInvoiceTemplate", isLink: false},
    "picking-tpl-setting": {name: "PrintPickingListTemplate", isLink: false},
    "integrations-update": {name: "IntegrationsUpdateStore", isLink: false},
    "promotion-tpl": {name: "PromotionTpl", isLink: true},
    "promotion-edit-tpl": {name: "PromotionEditTpl", isLink: false},
    "promotion-add-tpl": {name: "PromotionAddTpl", isLink: false},
    "mass-add": {name: "MassAdd", isLink: false},
    "import-add": {name: "ImportAdd", isLink: false},
    "collection-list": {name: "ScrapeList", isLink: true},
    "collect-list": {name: "ScrapeList", isLink: true},
    "collect-setting": {name: "CollectSetting", isLink: true},
    "collect-edit": {name: "CollectEdit", isLink: true},
    "fulfill-setting": {name: "FulfillSetting", isLink: true},
    "product-setting": {name: "rangeEnable", isLink: true},
    "product-logistics-template": {
      name: "templateSettingLogistic",
      isLink: true
    },
    "setting-category-mapping": {name: "mappingCategory", isLink: true},
    "product-category-mapping": {name: "mappingCategory", isLink: true},
    "product-size-template": {name: "sizeSettingTemplate", isLink: true},
    "product-local-publish-channel": {
      name: "createMasterProduct",
      isLink: true
    },
    "product-blibli": {
      name: `${formatMessage({id: "menuProduct"})} / Blibli`,
      isLink: false
    },
    "kreditpintar-loan": {
      name: "menuCapital",
      isLink: false
    },
    "distribution-settings": {name: "distributionSettings", isLink: true},
    "distributionSettings-history-operation": {
      name: "operationRecord",
      isLink: true,
      permission: "list-order"
    },
    "warehouse-accurate-product": {
      name: "productPushRecord",
      isLink: true
    },
    "warehouse-accurate-order": {
      name: "orderPushRecord",
      isLink: true
    },
    "logistics-management": {
      name: "logistics"
    },
    "accounting-accurate-product-setting": {
      name: `Accurate / ${formatMessage({id: "productManage"})}`
    },
    "accounting-accurate-product-push-log": {
      name: `Accurate / ${formatMessage({id: "productPushRecord"})}`
    },
    "accounting-accurate-bill": {
      name: `Accurate / ${formatMessage({id: "salesInvoice"})}`
    },
    "accounting-accurate-bill-push-log": {
      name: `Accurate / ${formatMessage({id: "salesInvoicePushRecord"})}`
    },
    "inquire-freight": {
      name: "checkShippingFee"
    }
  };
  const newBreadMap = {
    "/product-akulaku": [
      {
        text: formatMessage({id: "breadCrumbProductList"}, {channel: "Akulaku"})
      }
    ],
    "/product-jd-th": [
      {
        text: formatMessage({id: "breadCrumbProductList"}, {channel: "JD.TH"})
      }
    ],
    "/product-tiktok-mass-edit": [
      {
        text: formatMessage({id: "channelProductMassEdit"}, {channel: "TikTok"})
      }
    ],
    "/product-tiktok-mass-edit-category-attribute": [
      {
        text: formatMessage({id: "channelProductMassEdit"}, {channel: "TikTok"})
      }
    ],
    "/shipping-setting": [
      {
        text: formatMessage({id: "ShippingSetting"})
      }
    ],
    "/pos-setting": [{text: `${formatMessage({id: "menuSetting"})} / ${formatMessage({id: "posSettings"})}`}],
    "/pos-setting-edit": [{text: `${formatMessage({id: "posSettings"})} / ${formatMessage({id: "edit"})}`}],
    "/product-mass-edit/field": [
      {
        text: formatMessage({id: "channelProductMassEdit"}, {channel})
      }
    ],
    "/product-tokopedia": [
      {
        text: formatMessage({id: "breadCrumbProductList"}, {channel: "Tokopedia"})
      }
    ],
    "/product-lazada": [
      {
        text: formatMessage({id: "breadCrumbProductList"}, {channel: "Lazada"})
      }
    ],
    "/product-lazada-choice": [
      {
        text: formatMessage({id: "breadCrumbProductList"}, {channel: "Lazada Choice"})
      }
    ],
    "/setting/copyStore": [
      {
        text: formatMessage({id: "storeMoving"})
      }
    ],
    "/master-product-operation-list": [
      {
        text: formatMessage({id: "menuProductLocal"})
      },
      {
        text: formatMessage({id: "operationLog"})
      }
    ],
    "/product-operation-list": [
      {
        text: formatMessage({id: "operationLog"})
      },
      {
        text: channel
      }
    ],
    "/product-lazada-mass-category": [
      {
        text: formatMessage({id: "channelProductMassEdit"}, {channel: "Lazada"})
      },
      {
        text: formatMessage({id: "categoryAttributesEdit"})
      }
    ],
    "/product-lazada-mass-edit": [
      {
        text: formatMessage({id: "channelProductMassEdit"}, {channel: "Lazada"})
      }
    ],
    "/accounting/reconciliation": [
      {
        text: formatMessage({id: "paymentReconciliation"})
      }
    ]
  };
  const newBreadCrumbItemList = useMemo(() => {
    const list2 = newBreadMap[location.pathname] || [];
    return list2;
  }, [location.pathname]);
  const list = useMemo(() => {
    const result = location.pathname.replace("/", "").split("/").map((_) => {
      if (["Lazada", "Tokopedia", "Blibli", "Bukalapak"].includes(_)) {
        return {
          name: _,
          isLink: false,
          permission: "list-product"
        };
      }
      return BreadcrumbMap[_];
    }).filter((_) => _);
    return result;
  }, [location.pathname]);
  return /* @__PURE__ */ React.createElement(Breadcrumb, {
    className: "header-breadcrumb"
  }, list.map((_, index) => {
    return /* @__PURE__ */ React.createElement(Breadcrumb.Item, {
      key: `${_.name}${index}`
    }, _?.text ? _.text : formatMessage({id: _.name}, _?.extend), _.extraName ? _.extraName : "");
  }), newBreadCrumbItemList.map((item) => {
    return /* @__PURE__ */ React.createElement(Breadcrumb.Item, {
      key: item?.text
    }, item?.text);
  }));
});
