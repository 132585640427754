import React, {memo, useMemo} from "../../../../__snowpack__/pkg/react.js";
import {Popover, Button} from "../../../../__snowpack__/pkg/antd.js";
import {useIntl} from "../../../../__snowpack__/pkg/react-intl.js";
import {getBaseInfo} from "../../../config/service/index.js";
import {IconService} from "../assets/IconService.js";
import gineeHelp from "../../../assets/ginee-help.png";
import iconEmail from "../../../assets/customer-email.png";
import iconPhone from "../../../assets/customer-phone.png";
import iconTel from "../../../assets/customer-tel.png";
import {eventLogger} from "../../../lib/eventLogger.js";
import styled from "../../../../__snowpack__/pkg/styled-components.js";
import {useCountryInfo} from "../../../entities/useCountryInfo.js";
export const SiderService = memo((props) => {
  const {country} = useCountryInfo();
  const {formatMessage} = useIntl();
  const countryInfo = useMemo(() => {
    return getBaseInfo(country?.phoneCountry);
  }, [country]);
  const helpContent = /* @__PURE__ */ React.createElement(HelpContentWrapper, null, /* @__PURE__ */ React.createElement("div", {
    className: "help-top"
  }, /* @__PURE__ */ React.createElement("img", {
    className: "icon",
    src: gineeHelp
  }), /* @__PURE__ */ React.createElement("div", {
    className: "help-message"
  }, country?.phoneCountry === "PH" ? formatMessage({id: "needHelpWereHereForYou"}) : formatMessage({id: "customerCenter"}))), /* @__PURE__ */ React.createElement("div", {
    className: "help-bottom"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "email item"
  }, /* @__PURE__ */ React.createElement("img", {
    src: iconEmail,
    className: "icon"
  }), /* @__PURE__ */ React.createElement("span", null, countryInfo?.email)), country?.phoneCountry === "CN" ? null : /* @__PURE__ */ React.createElement("div", {
    className: "phone item"
  }, /* @__PURE__ */ React.createElement("img", {
    src: iconPhone,
    className: "icon"
  }), /* @__PURE__ */ React.createElement("span", null, countryInfo?.phone)), /* @__PURE__ */ React.createElement("div", {
    className: "tel item"
  }, /* @__PURE__ */ React.createElement("img", {
    src: iconTel,
    className: "icon"
  }), /* @__PURE__ */ React.createElement(Button, {
    type: "link",
    onClick: () => {
      if (country?.phoneCountry === "CN")
        return;
      if (countryInfo?.supportLink) {
        eventLogger.push({
          action: "ClickGroupSupport",
          location: "Dashboard"
        });
        window.open(countryInfo?.supportLink);
      }
    }
  }, formatMessage({id: countryInfo?.lang})))));
  return /* @__PURE__ */ React.createElement(PopWrapper, null, /* @__PURE__ */ React.createElement(Popover, {
    style: {borderRadius: 16},
    content: helpContent,
    overlayClassName: "ginee-service-pop",
    trigger: "click",
    placement: "top"
  }, /* @__PURE__ */ React.createElement("div", {
    className: `buttom ${props.collapsed ? "buttom-collapsed" : ""}`
  }, /* @__PURE__ */ React.createElement("div", {
    className: "button-service"
  }, /* @__PURE__ */ React.createElement(IconService, {
    style: {marginRight: props.collapsed ? 0 : 8, cursor: "pointer"}
  }), !props.collapsed && /* @__PURE__ */ React.createElement(React.Fragment, null, "Service")))));
});
const PopWrapper = styled.div``;
const HelpContentWrapper = styled.div`
  font-size: 13px;
  font-family: SFProText-Medium, SFProText;
  font-weight: 500;
  color: #202d40;
  line-height: 18px;
  margin: -12px -16px;
  .help-top {
    display: flex;
    width: 340px;
    position: relative;
    padding: 11px;
    padding-left: 95px;
    background: linear-gradient(90deg, #f2f1ff 0%, #e1dfff 100%);
    border-radius: 16px 16px 0px 0px;
    .icon {
      width: 95px;
      position: absolute;
      left: 0;
      bottom: 0;
    }
  }
  .help-bottom {
    padding: 0 20px;

    .icon {
      width: 16px;
      margin-right: 10px;
    }
    .item {
      /* line-height: 30px; */
      margin-bottom: 10px;
      margin-top: 10px;
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }
  .ant-btn-link {
    padding: 0px !important;
  }
`;
