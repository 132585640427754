import {lazy} from "../../lib/lazy.js";
export const orderRoutes = [
  {
    path: "/order",
    auth: "list-order",
    component: lazy(() => import(/* webpackChunkName: "orderRoutes" */ "../../views/order/OrderList.js")),
    meta: {
      openKeys: ["menu-sub-orders"]
    }
  },
  {
    path: "/history-order",
    auth: "history-order",
    component: lazy(() => import(/* webpackChunkName: "orderRoutes" */ "../../views/order/HistoryOrder.js"))
  },
  {
    path: "/order-shipping",
    component: lazy(() => import(/* webpackChunkName: "orderRoutes" */ "../../views/order/OrderShipping.js"))
  },
  {
    path: "/order-batch-label",
    component: lazy(() => import(/* webpackChunkName: "orderRoutes" */ "../../views/order/OrderBatchLabel.js"))
  },
  {
    path: "/abnormal-order",
    component: lazy(() => import(/* webpackChunkName: "orderRoutes" */ "../../views/order/AbnormalOrder.js")),
    auth: "unusual-order-management"
  },
  {
    path: "/waiting-packed",
    auth: "pending-print-order",
    component: lazy(() => import(/* webpackChunkName: "orderRoutes" */ "../../views/order/WaitingPacked.js"))
  },
  {
    path: "/waiting-collected",
    auth: "to-be-collected",
    component: lazy(() => import(/* webpackChunkName: "orderRoutes" */ "../../views/order/WaitingCollected.js"))
  },
  {
    path: "/shipped",
    auth: "shipped",
    component: lazy(() => import(/* webpackChunkName: "orderRoutes" */ "../../views/order/Shipped.js"))
  },
  {
    path: "/shelved",
    auth: "shelved",
    component: lazy(() => import(/* webpackChunkName: "orderRoutes" */ "../../views/order/Shelved.js"))
  },
  {
    path: "/tool-setting/explosive-print-order",
    component: lazy(() => import(/* webpackChunkName: "orderRoutes" */ "../../views/order/ExplosivePrintOrder.js")),
    auth: "print-hot-item-label"
  },
  {
    path: "/arrange-delivery",
    auth: "new-pending-order-management",
    component: lazy(() => import(/* webpackChunkName: "orderRoutes" */ "../../views/order/pending-order-management/ArrangeDelivery.js"))
  },
  {
    path: "/push-warehouse",
    auth: "new-pending-order-management",
    component: lazy(() => import(/* webpackChunkName: "orderRoutes" */ "../../views/order/pending-order-management/PushWarehouse.js"))
  },
  {
    path: "/problem-order",
    auth: "new-pending-order-management",
    component: lazy(() => import(/* webpackChunkName: "orderRoutes" */ "../../views/order/pending-order-management/ProblemOrder.js"))
  },
  {
    path: "/print-task-detail",
    component: lazy(() => import(/* webpackChunkName: "orderRoutes" */ "../../views/order/PrintTaskDetail.js"))
  },
  {
    path: "/normal-print-task-detail",
    component: lazy(() => import(/* webpackChunkName: "orderRoutes" */ "../../views/order/NormalPrintTaskDetail.js"))
  },
  {
    path: "/operation-log",
    component: lazy(() => import(/* webpackChunkName: "orderRoutes" */ "../../views/order/OrderHistoryOperation.js")),
    auth: "unusual-order-management-order-log"
  },
  {
    path: "/order/manual-order-add",
    component: lazy(() => import(/* webpackChunkName: "orderRoutes" */ "../../views/order/ManualOrderAdd.js")),
    auth: "list-order"
  },
  {
    path: "/order/order-detail",
    component: lazy(() => import(/* webpackChunkName: "orderRoutes" */ "../../views/order/OrderDetails.js"))
  },
  {
    path: "/shipping-order",
    component: lazy(() => import(/* webpackChunkName: "orderRoutes" */ "../../views/order/OrderShipping.js"))
  },
  {
    path: "/shipping-order-list",
    component: lazy(() => import(/* webpackChunkName: "orderRoutes" */ "../../views/order/OrderShippingTaskList.js"))
  },
  {
    path: "/print-invoice-ld",
    component: lazy(() => import(/* webpackChunkName: "orderRoutes" */ "../../views/print-invoice-ld/index.js"))
  },
  {
    path: "/order-refund-list",
    component: lazy(() => import(/* webpackChunkName: "orderRoutes" */ "../../views/order/return-management/RefundList.js")),
    auth: "return-management"
  },
  {
    path: "/order-return-refund-list",
    component: lazy(() => import(/* webpackChunkName: "orderRoutes" */ "../../views/order/return-management/ReturnAndRefundList.js")),
    auth: "return-management"
  },
  {
    path: "/order-setting/method-setting",
    component: lazy(() => import(/* webpackChunkName: "orderRoutes" */ "../../views/order/setting/MethodSettings.js")),
    meta: {
      title: "\u53D1\u8D27\u65B9\u5F0F\u8BBE\u7F6E"
    },
    auth: "entrance-logistics-rule"
  },
  {
    path: "/order-setting/sender-setting",
    component: lazy(() => import(/* webpackChunkName: "orderRoutes" */ "../../views/order/setting/SenderSettingsNew.js")),
    meta: {
      title: "\u5BC4\u4EF6\u4EBA\u4FE1\u606F\u8BBE\u7F6E"
    },
    auth: "sender-info-setting"
  },
  {
    path: "/order-setting/speed-order-setting",
    component: lazy(() => import(/* webpackChunkName: "orderRoutes" */ "../../views/order/setting/SpeedPrintSetting.js")),
    meta: {
      title: "\u9AD8\u901F\u6253\u5355\u8BBE\u7F6E"
    }
  },
  {
    path: "/order-setting/shipping-setting",
    component: lazy(() => import(/* webpackChunkName: "orderRoutes" */ "../../views/order/setting/ShippingSetting.js")),
    meta: {
      title: "\u53D1\u8D27\u8BBE\u7F6E\u9875"
    },
    auth: "template-settings"
  },
  {
    path: "/order-setting/export-template-setting",
    component: lazy(() => import(/* webpackChunkName: "orderRoutes" */ "../../views/order/setting/ExportOrderTemplate.js")),
    meta: {
      title: "\u5BFC\u51FA\u6A21\u7248\u9875"
    },
    auth: "list-order-tpl"
  },
  {
    path: "/order-setting/distribution-setting",
    component: lazy(() => import(/* webpackChunkName: "orderRoutes" */ "../../views/order/setting/DistributionSetting/index.js")),
    meta: {
      title: "\u914D\u8D27\u8BBE\u7F6E"
    },
    auth: "distribution-settings"
  },
  {
    path: "/order-setting/invoice-setting",
    component: lazy(() => import(/* webpackChunkName: "orderRoutes" */ "../../views/order/setting/LAInvoicesSetting.js")),
    meta: {
      title: "\u53D1\u7968\u89C4\u5219"
    },
    auth: "LA-invoice-rule-setting"
  },
  {
    path: "/order-setting/commission-setting",
    component: lazy(() => import(/* webpackChunkName: "orderRoutes" */ "../../views/order/setting/CommissionSetting.js")),
    meta: {
      title: "\u4F63\u91D1\u89C4\u5219"
    },
    auth: "list-order-commission"
  },
  {
    path: "/order-setting/add-temp",
    component: lazy(() => import(/* webpackChunkName: 'orderRoutes' */ "../../views/order/setting/OrderSettingsTemp.js")),
    meta: {
      title: "\u8BA2\u5355\u65B0\u589E\u5BFC\u51FA\u6A21\u7248"
    }
  },
  {
    path: "/order-setting/edit-temp",
    component: lazy(() => import(/* webpackChunkName: 'orderRoutes' */ "../../views/order/setting/OrderSettingsTemp.js")),
    meta: {
      title: "\u8BA2\u5355\u7F16\u8F91\u5BFC\u51FA\u6A21\u7248"
    }
  },
  {
    path: "/order-setting/print-tpl-setting",
    component: lazy(() => import(/* webpackChunkName: "orderRoutes" */ "../../views/order/setting/order-template/PrintTpl.js")),
    auth: "order-setting",
    meta: {
      title: "\u591A\u4E2A\u9762\u5355\u6A21\u7248\u8BBE\u7F6E\u9875"
    }
  },
  {
    path: "/order-setting/print-invoice-tpl",
    component: lazy(() => import(/* webpackChunkName: "orderRoutes" */ "../../views/order/setting/order-template/InvoiceTemplate.js")),
    auth: "order-setting",
    meta: {
      title: "\u53D1\u7968\u6A21\u7248\u8BBE\u7F6E\u9875"
    }
  },
  {
    path: "/order-setting/add-pick-status",
    component: lazy(() => import(/* webpackChunkName: "orderRoutes" */ "../../views/order/setting/OrderPickStatusManagement.js")),
    meta: {
      title: "\u8BBE\u7F6E\u62E3\u8D27\u72B6\u6001\u9875"
    }
  },
  {
    path: "/order-setting/push-third-party",
    component: lazy(() => import(/* webpackChunkName: "orderRoutes" */ "../../views/order/setting/PushThirdPartySetting/index.js")),
    meta: {
      title: "\u63A8\u9001\u4E09\u65B9\u4ED3\u8BBE\u7F6E"
    }
  },
  {
    path: "/order-setting/strategy-list",
    component: lazy(() => import(/* webpackChunkName: "orderRoutes" */ "../../views/order/setting/DiyStrategy/list.js")),
    meta: {
      title: "\u81EA\u5B9A\u4E49\u7B56\u7565\u5217\u8868"
    }
  },
  {
    path: "/order-setting/strategy-detail",
    component: lazy(() => import(/* webpackChunkName: "orderRoutes" */ "../../views/order/setting/DiyStrategy/detail.js")),
    meta: {
      title: "\u81EA\u5B9A\u4E49\u7B56\u7565\u5217\u8868"
    }
  },
  {
    path: "/tool-setting/scan-shipping",
    component: lazy(() => import(/* webpackChunkName: "orderRoutes" */ "../../views/order/tool/ScanShipping.js")),
    meta: {
      title: "\u626B\u63CF\u53D1\u8D27"
    },
    auth: "scan-shipment"
  }
];
