export const localeMessage = {
  en: {
    congratulationsYouHaveReceivedADay: "Congratulations! You have received a 7-day free trial of the full features",
    tryNoww: "Try Now",
    orderMaxTip: `During effective period of the package, the cumulative orders created has exceeded the limit of the package. Please subscribe to the package or contact customer service to upgrade package.`,
    selected: `Selected`,
    selectedXStore: `Selected {x} store`,
    pleaseSelectAStore: `Please select a store`,
    pleaseSelectAStaffAccount: `Please select a staff account`,
    contactus: `Contact Us`,
    PleaseSelect: `Please Select`,
    staffNotAuthority: `You do not have the operation authority of this function, please contact the merchant or Admin for handling.`,
    permissions: `Permissions:`,
    selectedPermissions: `Selected permissions`,
    theRoleCannotBeEmptyPlease: `The role cannot be empty, please select a role`,
    dearCustomer: `Dear customer,`,
    yourFollowingResourcesHaveExceededThe: `Your following resources have exceeded the package limit, please adjust or upgrade the package before continuing to use Ginee OMS`,
    numberOfStores: `Number of Stores`,
    RESTRICTED: `Disable`,
    theDefinitionOfDisabledIsStore: `Disable: After the store is Disabled, it will not affect sales on the store's channel; the store's channel products will be automatically unbound with Master SKU; the store's products/orders and other data will not be displayed in Ginee OMS`,
    selectXStoresToContinueBinding: `Select {x} stores to continue binding, and the rest will be Disabled (after deactivation, it will not affect store sales, but Ginee OMS will not display the store\u2019s products/orders and other data)`,
    chooseStore: `Choose Store`,
    staffAccount: `Staff Account`,
    selectXStaffAccountsToContinue: `Select {x} staff accounts to continue to use, and the rest will be deleted (only staff accounts of Ginee OMS will be deleted, other Ginee systems will not be affected)`,
    subscriptionPackage: `Subscription Package`,
    displayTheStoresDataInformationIn: `Display the store's data information in Ginee OMS`,
    reactivate: `Reactivate`,
    theStoreAuthorizationIsSuccessfulBut: `The store authorization is successful, but it cannot be displayed in Ginee OMS.
Because the number of stores exceeds the limit of the effective package, so the store is disabled ( After the store is deactivated, it will not affect sales on the store's channel; the store's channel products will be automatically unbound with Master SKU; the store's products/orders and other data will not be displayed in Ginee OMS)`,
    becauseTheNumberOfStoresExceeds: `You don't have operation authority, feature authority has exceeded the package limit, please subscribe to new package or contact customer service to upgrade the package`,
    youDontHaveOperationAuthorityFeature: `During effective period of the package, the cumulative number of orders created has reached {x} orders. After {y} orders are exceeded, the order feature will not be available. Please subscribe to the package in advance or contact customer service to upgrade the package`,
    duringEffectivePeriodOfThePackage: `Number of authorized stores or employee accounts has exceeded the package limit. Please contact the merchant or admin to log in to the main account for processing.`,
    emailOtpCode: `Email OTP code`,
    otpCodeHasBeenSentTo: `OTP code has been sent to the mailbox`,
    failedReason: `Failed Reason`,
    inOrderToEnsureTheIntegrity: `In order to ensure the integrity of data, it is recommended to use "Temporary Untracked".`,
    inSomeWarehouseTheWarehouseStock: `In some warehouse, the warehouse stock of the master SKU is not 0, please update to 0 before deleting.`,
    thereIsAnUnprocessedOutboundIn: `There is an unprocessed outbound in the master SKU.`,
    theMasterSkuHasBeenMatched: `The master SKU has been matched with the third-party warehouse product`,
    weWillSendAnOtpCode: `We will send an OTP code to your phone number`,
    cantReceiveTheVerificationCodeTry: `Can't receive the verification code? Try voice verification code`,
    weWillDialYourPhoneNumber: `We will dial your phone number and tell you the verification code by voice`,
    basedOnAccountSecurityPleaseLog: `Based on account security, please log in with your phone number this time. After login is successful and completed the authentication in[settings - account information - > email authentication], you can log in with email address`,
    hint: `Hint`,
    loginByPhoneNumber: `Login by Phone Number`,
    loginByEmail: `Login by Email`,
    basedOnAccountSecurityPleaseUse: `Based on account security, please use your email to log in this time. After successfully log in and completed the authentication in [Settings-Account Information->Mobile Number Authentication], you can log in with your mobile phone number.`,
    resetPasswordWithPhoneNumber: `Reset password with phone number`,
    resetPasswordWithEmail: `Reset password with email`,
    basedOnAccountSecurityPleaseUse1: `Based on account security, please use you email to login this time. After successfully login and completed the authentication in [Setting - Account Information-> Mobile Number Authentication], you can login with your mobile phone`,
    resetPasswordWithEmail1: `Reset password with email`,
    basedOnAccountSecurityPleaseUse2: `Based on account security, please use your phone number to reset your password. After login is successful and completed the authentication in[settings - account information - > email authentication], you can use your email to reset your password`,
    basedOnAccountSecurityPleaseUse3: `Based on account security, please use your email to reset  your password. After login is successful and completed the authentication in [settings - account information - > phone number authentication], you can use your phone number to reset your password`,
    notAuthenticate: `Not authenticate`,
    afterAuthenticationYouCanLogIn: `After authentication, you can log in with your mobile phone number and reset your password`,
    goToAuthentication: `Go to authentication`,
    afterAuthenticationYouCanLogIn1: `After authentication, you can log in by email and reset your password`,
    pleaseEnterYourAccountPassword: `Please enter your account password`,
    toProtectYourAccountSecurityPlease: `To protect your account security, please enter your login password for verification`,
    passwordMustBeAtLeast: `Password must be at least 8 characters long`,
    afterVerificationYouCanUseThe: `After verification, you can use the phone to login and change your password`,
    afterVerificationYouCanUseEmail: `After verification, you can use email to login and change your password`,
    authenticationSuccessful: `Authentication successful!`,
    merchantId: `Merchant ID`,
    verificationMethod: `Verification method`,
    scene: `Scene`,
    verificationCode: `Verification Code`,
    sendTime: `Send Time`,
    pleaseEnterPhoneNumber: `Please enter phone number`,
    sendTooManyTimesPleaseTry: `Send too many times, please try again in 24 hours!`,
    accountDoesNotExistPleaseCheck: `Account does not exist, please check!`,
    thisIsAStaffAccountPlease: `This is a staff account, please contact the merchant to change the password (change in Staff Account-Edit Staff) or change the password after logging in (Settings-Change Password)`,
    send: `send`,
    company: `Copyright 2021 \xA9 Ginee owned by Advintel Inc.`,
    title: `Ginee Seller Center - Log In or Sign Up`,
    content: `Join Ginee by logging in or signing up now to make your business smarter and easier to manage!`,
    keyWords: `Ginee  Log In,Ginee Sign Up,Ginee Reset Password`,
    confirm: `Confirm`,
    ofUseTerms: `Terms of Use`,
    and: `and`,
    policyPrivacy: `and Privacy Policy`,
    businessSmarterMaking: `Making Business Smarter`,
    successfulReset: `Reset successful!`,
    passwordReset: `Reset Password`,
    login: `Login`,
    email: `Email`,
    inputYourEmailPlease: `Please input your email`,
    passwordForget: `Forget password?`,
    signUp: `Sign up`,
    accountsignUpNew: `New account\uFF1F`,
    emailFormatIsNotCorrectPlease: `The email format is not correct, please re-enter`,
    enterPasswordPlease: `Please enter password`,
    accountOrPasswordIsNotCorrect: `The account or password is not correct, please check`,
    phoneNumberMustBeDigits: `The phone number must be 11 digits`,
    startWithAndDigits: `Must start with 8 and 9-12 digits`,
    startWithMust: `Must start with 9`,
    isNotCorrectPleaseCheckPhone: `Phone is not correct, please check`,
    successfulRegistration: `Registration successful!`,
    successfulLogin: `Login successful!`,
    addressAlreadyExistsEmail: `Email address already exists`,
    haveSendTooManyTimesPlease: `You have send too many times, please try again in 24 hours!`,
    codeErrorVerification: `Verification code error`,
    codeHasExpiredVerification: `Verification code has expired`,
    passwordConsistsOfAtLeast: `The password consists of at least 8 alphabets, numbers and special characters`,
    codeVerification: `Verification code`,
    password: `Password`,
    anAccountloginHave: `Have an account\uFF1F`,
    newPasswordCannotBeTheSame: `The new password cannot be the same as the original password!`,
    enterPhoneNumberPlease: `Please enter phone number`,
    phoneNumberHasBeenUsedPlease: `The phone number has been used, please re-enter`,
    toGineeWelcome: `Welcome to Ginee`,
    nHello: `Hello, {n}`,
    orderForYouToHaveA: `In order for you to have a better experience, please complete this following information, thank you`,
    enterTheChannelStoreNamePlease: `Please enter the channel store name`,
    CharactersMaximum: `Maximum 100 characters`,
    byRegistering: `By registering, I agree to the`,
    goUpgrade: "Go Upgrade",
    cancel: "Cancel",
    masterProductQuantityHasReachedThe: "Master Product quantity has reached the package limitation",
    afterUpgradingThePackageMoreMaster: "After upgrading the package, more Master Products can be added"
  },
  zh: {
    congratulationsYouHaveReceivedADay: "\u606D\u559C\uFF01\u60A8\u5DF2\u83B7\u5F977\u5929\u514D\u8D39\u8BD5\u7528\u5168\u529F\u80FD\u7684\u673A\u4F1A",
    tryNoww: "\u5F00\u5FC3\u6536\u4E0B",
    orderMaxTip: `\u5728\u5957\u9910\u751F\u6548\u671F\u95F4\uFF0C\u7D2F\u8BA1\u521B\u5EFA\u8BA2\u5355\u6570\u5DF2\u8D85\u5957\u9910\u9650\u5236\uFF0C\u8BF7\u8BA2\u9605\u5957\u9910\u6216\u8054\u7CFB\u5BA2\u670D\u5347\u7EA7\u5957\u9910\u3002`,
    selected: `\u5DF2\u9009\u4E2D`,
    selectedXStore: `\u5DF2\u9009{x}\u5E97\u94FA`,
    pleaseSelectAStore: `\u8BF7\u9009\u62E9\u5E97\u94FA`,
    pleaseSelectAStaffAccount: `\u8BF7\u9009\u62E9\u5458\u5DE5\u8D26\u6237`,
    contactus: `\u8054\u7CFB\u6211\u4EEC`,
    PleaseSelect: `\u8BF7\u9009\u62E9`,
    staffNotAuthority: `\u60A8\u65E0\u8BE5\u529F\u80FD\u7684\u64CD\u4F5C\u6743\u9650\uFF0C\u8BF7\u8054\u7CFB\u5546\u6237\u6216Admin\u5904\u7406\u3002`,
    permissions: `\u6743\u9650\uFF1A`,
    selectedPermissions: `\u5DF2\u9009\u7684\u6743\u9650`,
    theRoleCannotBeEmptyPlease: `\u89D2\u8272\u4E0D\u80FD\u4E3A\u7A7A\uFF0C\u8BF7\u9009\u62E9\u89D2\u8272`,
    dearCustomer: `\u5C0A\u656C\u7684`,
    yourFollowingResourcesHaveExceededThe: `\u60A8\u4EE5\u4E0B\u8D44\u6E90\u5DF2\u8D85\u51FA\u5957\u9910\u7684\u9650\u5236\uFF0C\u8BF7\u8C03\u6574\u6216\u5347\u7EA7\u5957\u9910\u540E\u518D\u7EE7\u7EED\u4F7F\u7528Ginee OMS`,
    numberOfStores: `\u5E97\u94FA\u6570\u91CF`,
    RESTRICTED: `\u505C\u7528`,
    theDefinitionOfDisabledIsStore: `\u505C\u7528\uFF1A\u5E97\u94FA\u88AB\u505C\u7528\u540E\uFF0C\u4E0D\u4F1A\u5F71\u54CD\u5E97\u94FA\u5728\u6E20\u9053\u9500\u552E\uFF1B\u8BE5\u5E97\u94FA\u7684\u6E20\u9053\u5546\u54C1\u4F1A\u8DDF\u4E3BSKU\u81EA\u52A8\u89E3\u7ED1\uFF1B\u5E97\u94FA\u7684\u5546\u54C1/\u8BA2\u5355\u7B49\u6570\u636E\u5C06\u4E0D\u4F1A\u5728Ginee ERP\u5C55\u793A`,
    selectXStoresToContinueBinding: `\u9009\u62E9{x}\u4E2A\u7EE7\u7EED\u7ED1\u5B9A\u7684\u5E97\u94FA\uFF0C\u5176\u4F59\u5C06\u88AB\u505C\u7528\uFF08\u5E97\u94FA\u88AB\u505C\u7528\u540E\uFF0C\u4E0D\u4F1A\u5F71\u54CD\u5E97\u94FA\u5728\u6E20\u9053\u9500\u552E\uFF1B\u8BE5\u5E97\u94FA\u7684\u6E20\u9053\u5546\u54C1\u4F1A\u8DDF\u4E3BSKU\u81EA\u52A8\u89E3\u7ED1\uFF1B\u5E97\u94FA\u7684\u5546\u54C1/\u8BA2\u5355\u7B49\u6570\u636E\u5C06\u4E0D\u4F1A\u5728Ginee ERP\u5C55\u793A\uFF09`,
    chooseStore: `\u9009\u62E9\u5E97\u94FA`,
    staffAccount: `\u5458\u5DE5\u8D26\u6237`,
    selectXStaffAccountsToContinue: `\u9009\u62E9{x}\u4E2A\u7EE7\u7EED\u4F7F\u7528\u7684\u5458\u5DE5\u8D26\u6237\uFF0C\u5176\u4F59\u5C06\u88AB\u5220\u9664\uFF08\u4EC5\u4F1A\u5220\u9664Ginee ERP\u7684\u5458\u5DE5\u8D26\u6237\uFF0C\u5176\u4ED6Ginee\u7CFB\u7EDF\u4E0D\u53D7\u5F71\u54CD\uFF09`,
    subscriptionPackage: `\u8BA2\u9605\u5957\u9910`,
    displayTheStoresDataInformationIn: `\u5728Ginee ERP\u663E\u793A\u8BE5\u5E97\u94FA\u7684\u6570\u636E\u4FE1\u606F`,
    reactivate: `\u91CD\u65B0\u542F\u7528`,
    theStoreAuthorizationIsSuccessfulBut: `\u5E97\u94FA\u6388\u6743\u6210\u529F\uFF0C\u4F46\u5728Ginee ERP\u65E0\u6CD5\u5C55\u793A\u3002
\u56E0\u5E97\u94FA\u6570\u8D85\u51FA\u751F\u6548\u5957\u9910\u7684\u9650\u5236\uFF0C\u5E97\u94FA\u88AB\u505C\u7528\uFF08\u5E97\u94FA\u88AB\u505C\u7528\u540E\uFF0C\u4E0D\u4F1A\u5F71\u54CD\u5E97\u94FA\u5728\u6E20\u9053\u9500\u552E\uFF1B\u8BE5\u5E97\u94FA\u7684\u6E20\u9053\u5546\u54C1\u4F1A\u8DDF\u4E3BSKU\u81EA\u52A8\u89E3\u7ED1\uFF1B\u5E97\u94FA\u7684\u5546\u54C1/\u8BA2\u5355\u7B49\u6570\u636E\u5C06\u4E0D\u4F1A\u5728Ginee ERP\u5C55\u793A\uFF09`,
    becauseTheNumberOfStoresExceeds: `\u60A8\u65E0\u64CD\u4F5C\u6743\u9650\uFF0C\u8BE5\u529F\u80FD\u6743\u9650\u5DF2\u8D85\u51FA\u5957\u9910\u9650\u5236\uFF0C\u8BF7\u8BA2\u9605\u65B0\u5957\u9910\u6216\u8054\u7CFB\u5BA2\u670D\u5347\u7EA7\u5957\u9910`,
    youDontHaveOperationAuthorityFeature: `\u5957\u9910\u751F\u6548\u671F\u95F4\uFF0C\u7D2F\u8BA1\u521B\u5EFA\u8BA2\u5355\u6570\u5DF2\u8FBE{x}\u5355\uFF0C\u8D85\u51FA{y}\u5355\u540E\u5C06\u65E0\u6CD5\u4F7F\u7528\u8BA2\u5355\u529F\u80FD\uFF0C\u8BF7\u63D0\u524D\u8BA2\u9605\u5957\u9910\u6216\u8054\u7CFB\u5BA2\u670D\u5347\u7EA7\u5957\u9910`,
    duringEffectivePeriodOfThePackage: `\u6388\u6743\u5E97\u94FA\u6570\u91CF\u6216\u5458\u5DE5\u8D26\u6237\u6570\u5DF2\u8D85\u5957\u9910\u9650\u5236\uFF0C\u8BF7\u8054\u7CFB\u5546\u6237\u6216admin\u767B\u5F55\u4E3B\u8D26\u6237\u5904\u7406\u3002`,
    emailOtpCode: `\u90AE\u7BB1\u9A8C\u8BC1\u7801`,
    otpCodeHasBeenSentTo: `\u5DF2\u5411\u90AE\u7BB1\u53D1\u9001\u9A8C\u8BC1\u7801`,
    failedReason: `\u5931\u8D25\u539F\u56E0`,
    inOrderToEnsureTheIntegrity: `\u4E3A\u4FDD\u8BC1\u6570\u636E\u7684\u5B8C\u6574\u6027\uFF0C\u5EFA\u8BAE\u4F7F\u7528\u201C\u6682\u4E0D\u5173\u6CE8\u201D\u3002`,
    inSomeWarehouseTheWarehouseStock: `\u5728\u90E8\u5206\u4ED3\u5E93\u4E2D\u4E3BSKU\u7684\u4ED3\u5E93\u5E93\u5B58\u4E0D\u4E3A0\uFF0C\u8BF7\u66F4\u65B0\u4E3A0\u518D\u5220\u9664\u3002`,
    thereIsAnUnprocessedOutboundIn: `\u4E3BSKU\u5B58\u5728\u672A\u5904\u7406\u7684\u51FA\u5E93\u5355\u3002`,
    theMasterSkuHasBeenMatched: `\u4E3BSKU\u5DF2\u8DDF\u7B2C\u4E09\u65B9\u4ED3\u5E93\u5546\u54C1\u5339\u914D\u3002`,
    weWillSendAnOtpCode: `\u6211\u4EEC\u5C06\u5411\u60A8\u7684\u624B\u673A\u53F7\u7801\u53D1\u9001\u4E00\u4E2A OTP \u4EE3\u7801`,
    cantReceiveTheVerificationCodeTry: `\u6536\u4E0D\u5230\u9A8C\u8BC1\u7801\uFF1F\u8BED\u97F3\u9A8C\u8BC1\u7801`,
    weWillDialYourPhoneNumber: `\u6211\u4EEC\u5C06\u62E8\u6253\u60A8\u7684\u624B\u673A\u53F7\u5E76\u901A\u8FC7\u8BED\u97F3\u544A\u77E5\u9A8C\u8BC1\u7801`,
    basedOnAccountSecurityPleaseLog: `\u57FA\u4E8E\u8D26\u6237\u5B89\u5168\uFF0C\u8BF7\u60A8\u672C\u6B21\u4F7F\u7528\u624B\u673A\u53F7\u767B\u5F55\u3002
\u767B\u5F55\u6210\u529F\u5E76\u5728\u3010\u8BBE\u7F6E-\u8D26\u6237\u4FE1\u606F->\u90AE\u7BB1\u8BA4\u8BC1\u3011\u5B8C\u6210\u8BA4\u540E\uFF0C\u53EF\u7528\u90AE\u7BB1\u767B\u5F55\u3002`,
    hint: `\u63D0\u793A`,
    loginByPhoneNumber: `\u624B\u673A\u53F7\u767B\u5F55`,
    loginByEmail: `\u90AE\u7BB1\u767B\u5F55`,
    basedOnAccountSecurityPleaseUse: `\u57FA\u4E8E\u8D26\u6237\u5B89\u5168\uFF0C\u8BF7\u60A8\u672C\u6B21\u4F7F\u7528\u90AE\u7BB1\u767B\u5F55\u3002
\u767B\u5F55\u6210\u529F\u5E76\u5728\u3010\u8BBE\u7F6E-\u8D26\u6237\u4FE1\u606F->\u624B\u673A\u53F7\u8BA4\u8BC1\u3011\u5B8C\u6210\u8BA4\u8BC1\u540E\uFF0C\u53EF\u7528\u624B\u673A\u53F7\u767B\u5F55\u3002`,
    resetPasswordWithPhoneNumber: `\u7528\u624B\u673A\u53F7\u91CD\u7F6E\u5BC6\u7801`,
    resetPasswordWithEmail: `\u7528\u90AE\u7BB1\u91CD\u7F6E\u5BC6\u7801`,
    basedOnAccountSecurityPleaseUse1: `\u57FA\u4E8E\u8D26\u6237\u5B89\u5168\uFF0C\u8BF7\u60A8\u672C\u6B21\u4F7F\u7528\u90AE\u7BB1\u767B\u5F55\u3002
\u767B\u5F55\u6210\u529F\u5E76\u5728\u3010\u8BBE\u7F6E-\u8D26\u6237\u4FE1\u606F->\u624B\u673A\u53F7\u8BA4\u8BC1\u3011\u5B8C\u6210\u8BA4\u8BC1\u540E\uFF0C\u53EF\u7528\u624B\u673A\u53F7\u767B\u5F55\u3002`,
    resetPasswordWithEmail1: `\u901A\u8FC7\u90AE\u7BB1\u91CD\u7F6E\u5BC6\u7801`,
    basedOnAccountSecurityPleaseUse2: `\u57FA\u4E8E\u8D26\u6237\u5B89\u5168\uFF0C\u8BF7\u60A8\u672C\u6B21\u4F7F\u7528\u624B\u673A\u53F7\u91CD\u7F6E\u5BC6\u7801\u3002
\u767B\u5F55\u6210\u529F\u5E76\u5728\u3010\u8BBE\u7F6E-\u8D26\u6237\u4FE1\u606F->\u90AE\u7BB1\u8BA4\u8BC1\u3011\u5B8C\u6210\u8BA4\u8BC1\u540E\uFF0C\u53EF\u7528\u90AE\u7BB1\u91CD\u7F6E\u5BC6\u7801\u3002`,
    basedOnAccountSecurityPleaseUse3: `\u57FA\u4E8E\u8D26\u6237\u5B89\u5168\uFF0C\u8BF7\u60A8\u672C\u6B21\u4F7F\u7528\u90AE\u7BB1\u91CD\u7F6E\u5BC6\u7801\u3002
\u767B\u5F55\u6210\u529F\u5E76\u5728\u3010\u8BBE\u7F6E-\u8D26\u6237\u4FE1\u606F->\u624B\u673A\u53F7\u8BA4\u8BC1\u3011\u5B8C\u6210\u8BA4\u8BC1\u540E\uFF0C\u53EF\u7528\u624B\u673A\u53F7\u91CD\u7F6E\u5BC6\u7801\u3002`,
    notAuthenticate: `\u672A\u8BA4\u8BC1`,
    afterAuthenticationYouCanLogIn: `\u8BA4\u8BC1\u540E\uFF0C\u53EF\u7528\u624B\u673A\u53F7\u767B\u5F55\u548C\u91CD\u8BBE\u5BC6\u7801`,
    goToAuthentication: `\u53BB\u8BA4\u8BC1`,
    afterAuthenticationYouCanLogIn1: `\u8BA4\u8BC1\u540E\uFF0C\u53EF\u7528\u90AE\u7BB1\u767B\u5F55\u548C\u91CD\u8BBE\u5BC6\u7801`,
    pleaseEnterYourAccountPassword: `\u8BF7\u8F93\u5165\u8D26\u6237\u5BC6\u7801`,
    toProtectYourAccountSecurityPlease: `\u4E3A\u4E86\u4FDD\u62A4\u60A8\u7684\u8D26\u6237\u5B89\u5168\uFF0C\u8BF7\u8F93\u5165\u60A8\u7684\u767B\u5F55\u5BC6\u7801\u8FDB\u884C\u9A8C\u8BC1`,
    passwordMustBeAtLeast: `\u5BC6\u7801\u5FC5\u987B\u81F3\u5C118\u4E2A\u5B57\u7B26`,
    afterVerificationYouCanUseThe: `\u9A8C\u8BC1\u901A\u8FC7\u540E\uFF0C\u53EF\u7528\u8BE5\u624B\u673A\u767B\u5F55\u548C\u66F4\u6539\u5BC6\u7801`,
    afterVerificationYouCanUseEmail: `\u9A8C\u8BC1\u901A\u8FC7\u540E\uFF0C\u53EF\u7528\u8BE5\u90AE\u7BB1\u767B\u5F55\u548C\u66F4\u6539\u5BC6\u7801`,
    authenticationSuccessful: `\u8BA4\u8BC1\u6210\u529F\uFF01`,
    merchantId: `\u5546\u6237ID`,
    verificationMethod: `\u9A8C\u8BC1\u65B9\u5F0F`,
    scene: `\u573A\u666F`,
    verificationCode: `\u9A8C\u8BC1\u7801`,
    sendTime: `\u53D1\u9001\u65F6\u95F4`,
    pleaseEnterPhoneNumber: `\u8BF7\u8F93\u5165\u624B\u673A\u53F7`,
    sendTooManyTimesPleaseTry: `\u53D1\u9001\u6B21\u6570\u8FC7\u591A\uFF0C\u8BF724\u5C0F\u65F6\u540E\u518D\u8BD5\uFF01`,
    accountDoesNotExistPleaseCheck: `\u8D26\u6237\u4E0D\u5B58\u5728\uFF0C\u8BF7\u68C0\u67E5\uFF01`,
    thisIsAStaffAccountPlease: `\u8FD9\u662F\u5458\u5DE5\u8D26\u6237\uFF0C\u8BF7\u8054\u7CFB\u5546\u6237\u4FEE\u6539\u5BC6\u7801\uFF08\u5728\u5458\u5DE5\u8D26\u6237-\u7F16\u8F91\u5458\u5DE5\u4E2D\u4FEE\u6539)\u6216\u767B\u5F55\u540E\u518D\u4FEE\u6539\u5BC6\u7801\uFF08\u8BBE\u7F6E-\u4FEE\u6539\u5BC6\u7801\uFF09`,
    send: `\u53D1\u9001`,
    company: `\u6DF1\u5733\u5E02\u9886\u521B\u805A\u7075\u79D1\u6280\u6709\u9650\u516C\u53F8`,
    title: `Ginee Seller Center -\u767B\u5F55 \u548C\u6CE8\u518C`,
    content: `Ginee \u805A\u7075ERP\uFF0C\u9886\u5148\u7684\u4E00\u7AD9\u5F0F\u4E1C\u5357\u4E9A\u7535\u5546\u51FA\u6D77SaaS\u670D\u52A1\u5546\uFF01`,
    keyWords: `Ginee \u767B\u5F55,Ginee \u6CE8\u518C,Ginee \u91CD\u7F6E\u5BC6\u7801`,
    confirm: `\u786E\u5B9A`,
    ofUseTerms: `\u4F7F\u7528\u6761\u6B3E`,
    and: `\u53CA`,
    policyPrivacy: `\u9690\u79C1\u653F\u7B56`,
    businessSmarterMaking: `\u8BA9\u751F\u610F\u66F4\u667A\u6167`,
    successfulReset: `\u91CD\u7F6E\u6210\u529F\uFF01`,
    passwordReset: `\u91CD\u7F6E\u5BC6\u7801\uFF01`,
    login: `\u767B\u5F55`,
    email: `\u90AE\u7BB1`,
    inputYourEmailPlease: `\u8BF7\u8F93\u5165\u90AE\u7BB1`,
    passwordForget: `\u5FD8\u8BB0\u5BC6\u7801\uFF1F`,
    signUp: `\u6CE8\u518C`,
    accountsignUpNew: `\u65B0\u8D26\u6237\uFF1F`,
    emailFormatIsNotCorrectPlease: `\u90AE\u7BB1\u683C\u5F0F\u9519\u8BEF\uFF0C\u8BF7\u91CD\u65B0\u8F93\u5165`,
    enterPasswordPlease: `\u8BF7\u8F93\u5165\u5BC6\u7801`,
    accountOrPasswordIsNotCorrect: `\u8D26\u6237\u6216\u5BC6\u7801\u9519\u8BEF\uFF0C\u8BF7\u68C0\u67E5`,
    phoneNumberMustBeDigits: `\u624B\u673A\u53F7\u5FC5\u987B11\u4F4D`,
    startWithAndDigits: `\u5FC5\u987B8\u5F00\u5934\u4E149-12\u4F4D`,
    startWithMust: `\u5FC5\u987B9\u5F00\u5934`,
    isNotCorrectPleaseCheckPhone: `\u624B\u673A\u4E0D\u5408\u6CD5\uFF0C\u8BF7\u68C0\u67E5`,
    successfulRegistration: `\u6CE8\u518C\u6210\u529F\uFF01`,
    successfulLogin: `\u767B\u5F55\u6210\u529F\uFF01`,
    addressAlreadyExistsEmail: `\u90AE\u7BB1\u5DF2\u5B58\u5728`,
    haveSendTooManyTimesPlease: `\u53D1\u9001\u6B21\u6570\u8FC7\u591A\uFF0C\u8BF724\u5C0F\u65F6\u540E\u518D\u8BD5\uFF01`,
    codeErrorVerification: `\u9A8C\u8BC1\u7801\u9519\u8BEF`,
    codeHasExpiredVerification: `\u9A8C\u8BC1\u7801\u5DF2\u8FC7\u671F`,
    passwordConsistsOfAtLeast: `\u5BC6\u7801\u7531\u81F3\u5C118\u4F4D\u82F1\u6587\u3001\u6570\u5B57\u548C\u7279\u6B8A\u5B57\u7B26\u7EC4\u6210`,
    codeVerification: `\u9A8C\u8BC1\u7801`,
    password: `\u5BC6\u7801`,
    anAccountloginHave: `\u5DF2\u6709\u8D26\u6237\uFF1F`,
    newPasswordCannotBeTheSame: `\u65B0\u5BC6\u7801\u4E0D\u80FD\u8DDF\u539F\u5BC6\u7801\u76F8\u540C\uFF01`,
    enterPhoneNumberPlease: `\u8BF7\u8F93\u5165\u624B\u673A\u53F7`,
    phoneNumberHasBeenUsedPlease: `\u624B\u673A\u53F7\u5DF2\u88AB\u4F7F\u7528\uFF0C\u8BF7\u91CD\u65B0\u8F93\u5165`,
    toGineeWelcome: `\u6B22\u8FCE\u6765\u5230Ginee`,
    nHello: `\u60A8\u597D\uFF0C{n}`,
    orderForYouToHaveA: `\u4E3A\u4E86\u8BA9\u60A8\u6709\u66F4\u597D\u7684\u4F53\u9A8C\uFF0C\u8BF7\u5B8C\u6210\u4EE5\u4E0B\u4FE1\u606F\uFF0C\u8C22\u8C22`,
    enterTheChannelStoreNamePlease: `\u8BF7\u8F93\u5165\u6E20\u9053\u5E97\u94FA\u540D\u79F0`,
    CharactersMaximum: `\u6700\u957F100\u4E2A\u5B57\u7B26`,
    byRegistering: `\u901A\u8FC7\u6CE8\u518C\uFF0C\u6211\u540C\u610F`,
    goUpgrade: "\u53BB\u5347\u7EA7",
    cancel: "\u53D6\u6D88",
    masterProductQuantityHasReachedThe: "\u4E3B\u5546\u54C1\u6570\u91CF\u5DF2\u5230\u8FBE\u5957\u9910\u4E0A\u9650",
    afterUpgradingThePackageMoreMaster: "\u5347\u7EA7\u5957\u9910\u540E\u53EF\u6DFB\u52A0\u66F4\u591A\u7684\u4E3B\u5546\u54C1"
  },
  id: {
    congratulationsYouHaveReceivedADay: "Selamat! Anda telah mendapatkan uji coba gratis semua fitur selama 7 hari",
    tryNoww: "Coba Sekarang",
    orderMaxTip: `Selama masa berlaku paket, jumlah akumulasi pesanan yang dibuat telah melebihi batas paket. Silakan berlangganan atau hubungi customer service untuk upgrade paket.`,
    selected: `Terpilih`,
    selectedXStore: `Toko {x} terpilih`,
    pleaseSelectAStore: `Silakan pilih toko`,
    pleaseSelectAStaffAccount: `Silakan pilih akun staf`,
    contactus: `Hubungi Kami`,
    PleaseSelect: `Silahkan Pilih`,
    staffNotAuthority: `Anda tidak memiliki otoritas pengoperasian fungsi ini, harap hubungi pedagang atau Admin untuk penanganan.`,
    permissions: `Izin:`,
    selectedPermissions: `Izin yang dipilih`,
    theRoleCannotBeEmptyPlease: `Peran tidak boleh kosong, silakan pilih peran`,
    dearCustomer: `Pelanggan yang terhormat,`,
    yourFollowingResourcesHaveExceededThe: `Penggunaan fitur Anda telah melampaui batas paket, silahkan sesuaikan atau tingkatkan paket sebelum melanjutkan penggunaan 
Ginee OMS`,
    numberOfStores: `Jumlah Toko`,
    RESTRICTED: `Non-aktif`,
    theDefinitionOfDisabledIsStore: `Non-aktif: Setelah toko dinonaktifkan, tidak akan berpengaruh ke penjualan toko di marketplace. Produk toko tersebut akan otomatis terlepas dari Master SKU.Produk/pesanan toko dan data lainnya tidak akan ditampilkan di Ginee OMS`,
    selectXStoresToContinueBinding: `Pilih toko {x} untuk melanjutkan pengikatan, dan sisanya akan Dinonaktifkan ( Setelah toko dinonaktifkan, tidak akan berpengaruh ke penjualan toko di marketplace. Produk toko tersebut akan otomatis terlepas dari Master SKU.Produk/pesanan toko dan data lainnya tidak akan ditampilkan di Ginee OMS)`,
    chooseStore: `Pilih Toko`,
    staffAccount: `Akun Staf`,
    selectXStaffAccountsToContinue: `Pilih {x} akun staf untuk terus digunakan, dan sisanya akan dihapus (hanya akun staf Ginee OMS yang akan dihapus, tidak akan berpengaruh ke sistem Ginee lainnya)`,
    subscriptionPackage: `Paket Langganan`,
    displayTheStoresDataInformationIn: `Menampilkan informasi data toko di Ginee OMS`,
    reactivate: `Mengaktifkan kembali`,
    theStoreAuthorizationIsSuccessfulBut: `Otorisasi toko berhasil, tetapi tidak dapat ditampilkan di Ginee OMS.
Karena jumlah toko melebihi batas paket efektif, maka toko dinonaktifkan (Setelah toko dinonaktifkan, tidak akan berpengaruh ke penjualan toko di marketplace. Produk toko tersebut akan otomatis terlepas dari Master SKU.Produk/pesanan toko dan data lainnya tidak akan ditampilkan di Ginee OMS\uFF09`,
    becauseTheNumberOfStoresExceeds: `Anda tidak memiliki akses operasi, dikarnakan akses fitur telah melampaui batas paket, silakan berlangganan paket baru atau hubungi customer service untuk meningkatkan paket`,
    youDontHaveOperationAuthorityFeature: `Selama masa berlangganan paket, jumlah kumulatif pesanan yang dibuat telah mencapai {x} pesanan. Setelah {y} pesanan terlampaui, fitur pesanan tidak akan bisa digunakan. Silakan berlangganan paket terlebih dahulu atau hubungi customer service untuk meningkatkan paket`,
    duringEffectivePeriodOfThePackage: `Jumlah otorisasi toko atau akun staf telah melebihi batas paket. Silahkan hubungi merchant atau admin untuk login dan diatur di akun utama.`,
    emailOtpCode: `Email Kode OTP`,
    otpCodeHasBeenSentTo: `Kode OTP telah dikirimkan ke email Anda`,
    failedReason: `Alasan Gagal`,
    inOrderToEnsureTheIntegrity: `Untuk memastikan integritas data, disarankan untuk menggunakan "Tidak Terlacak".`,
    inSomeWarehouseTheWarehouseStock: `Di beberapa gudang, stok gudang master SKU tidak 0, harap perbarui ke 0 sebelum menghapus.`,
    thereIsAnUnprocessedOutboundIn: `Ada outbound yang belum diproses pada master SKU`,
    theMasterSkuHasBeenMatched: `Master SKU telah dicocokan dengan produk gudang pihak ketiga`,
    weWillSendAnOtpCode: `Kami akan mengirimkan kode OTP ke nomor ponsel Anda`,
    cantReceiveTheVerificationCodeTry: `Tidak dapat menerima kode verifikasi? Coba kode verifikasi melalui suara`,
    weWillDialYourPhoneNumber: `Kami akan menghubungi nomor ponsel Anda dan memberi tahu Anda kode verifikasi melalui suara`,
    basedOnAccountSecurityPleaseLog: `Untuk keamanan akun, silakan gunakan nomor ponsel Anda untuk login saat ini. Setelah login berhasil dan menyelesaikan autentikasi pada [Pengaturan-Informasi Akun -> Otentikasi Email], Anda dapat login dengan alamat email Anda.`,
    hint: `Petunjuk`,
    loginByPhoneNumber: `Login menggunakan Nomor Ponsel`,
    loginByEmail: `Login menggunakan Email`,
    basedOnAccountSecurityPleaseUse: `Untuk keamanan akun, silakan gunakan email untuk login saat ini. Setelah login berhasil dan menyelesaikan autentikasi pada [Pengaturan-Informasi Akun -> Otentikasi Nomor Ponsel], Anda dapat login dengan nomor ponsel Anda`,
    resetPasswordWithPhoneNumber: `Atur ulang kata sandi menggunakan nomor ponsel`,
    resetPasswordWithEmail: `Atur ulang kata sandi menggunakan email`,
    basedOnAccountSecurityPleaseUse1: `Untuk keamanan akun, silakan gunakan email untuk login saat ini. Setelah login berhasil dan menyelesaikan autentikasi pada [Pengaturan-Informasi Akun -> Otentikasi Nomor Ponsel], Anda dapat login dengan nomor ponsel Anda`,
    resetPasswordWithEmail1: `Atur ulang kata sandi menggunakan email`,
    basedOnAccountSecurityPleaseUse2: `Untuk keamanan akun, silakan gunakan nomor ponsel Anda untuk mengatur ulang kata sandi Anda. Setelah login berhasil dan menyelesaikan autentikasi pada [Pengaturan-Informasi Akun -> Otentikasi Email], Anda menggunakan email Anda untuk mengatur ulang kata sandi Anda`,
    basedOnAccountSecurityPleaseUse3: `Untuk keamanan akun, silakan gunakan email Anda untuk mengatur ulang kata sandi Anda. Setelah login berhasil dan menyelesaikan autentikasi pada [Pengaturan-Informasi Akun -> Otentikasi Nomor Ponsel, Anda menggunakan nomor ponsel Anda untuk mengatur ulang kata sandi Anda`,
    notAuthenticate: `Belum diautentikasi`,
    afterAuthenticationYouCanLogIn: `Setelah otentikasi, Anda dapat masuk dengan nomor ponsel Anda dan mengatur ulang kata sandi Anda`,
    goToAuthentication: `Otentikasikan`,
    afterAuthenticationYouCanLogIn1: `Setelah otentikasi, Anda dapat login menggunakan email dan mengatur ulang kata sandi Anda`,
    pleaseEnterYourAccountPassword: `Silakan masukkan kata sandi akun Anda`,
    toProtectYourAccountSecurityPlease: `Untuk melindungi keamanan akun Anda, silakan masukkan kata sandi login Anda untuk verifikasi`,
    passwordMustBeAtLeast: `Kata Sandi harus minimal 8 karakter`,
    afterVerificationYouCanUseThe: `Setelah verifikasi, Anda dapat menggunakan nomor ponsel untuk login dan mengganti kata sandi Anda`,
    afterVerificationYouCanUseEmail: `Setelah verifikasi, Anda dapat menggunakan email untuk login dan mengganti kata sandi Anda`,
    authenticationSuccessful: `Otentikasi berhasil!`,
    merchantId: `ID Merchant`,
    verificationMethod: `Metode verifikasi`,
    scene: `Situasi`,
    verificationCode: `Kode Verifikasi`,
    sendTime: `Waktu Kirim`,
    pleaseEnterPhoneNumber: `Silakan masukkan nomor ponsel`,
    sendTooManyTimesPleaseTry: `Anda telah mengirim terlalu banyakan, silakan coba lagi dalam waktu 24 jam!`,
    accountDoesNotExistPleaseCheck: `Akun tidak ada, silakan periksa!`,
    thisIsAStaffAccountPlease: `Ini adalah akun staf, silakan hubungi merchant untuk mengubah kata sandi (ubah di Akun Staf-Edit Staf) atau ubah kata sandi setelah masuk (Pengaturan-Ubah Kata Sandi)`,
    send: `Kirim`,
    company: `Copyright 2021 \xA9 Ginee owned by PT Advance Intelligence Indonesia.`,
    title: `Ginee Seller Center - Masuk atau Daftar`,
    content: `Gabung dengan Ginee sekarang dengan masuk atau daftar di Ginee Seller Center untuk membuat bisnis Anda makin sukses dan mudah dikelola!`,
    keyWords: `Ginee Masuk,Ginee Daftar`,
    confirm: `Konfirmasi`,
    ofUseTerms: `Ketentuan Penggunaan`,
    and: `dan`,
    policyPrivacy: `Kebijakan Privasi`,
    businessSmarterMaking: `Jalankan Bisnismu Dengan Cerdas`,
    successfulReset: `Berhasil direset`,
    passwordReset: `Reset Ginee`,
    login: `Login`,
    email: `Email`,
    inputYourEmailPlease: `Silakan masukkan Email Anda`,
    passwordForget: `Lupa password?`,
    signUp: `Sign up`,
    accountsignUpNew: `Akun baru?`,
    emailFormatIsNotCorrectPlease: `Format email salah, silakan masukkan kembali`,
    enterPasswordPlease: `Silakan masukkan kata sandi`,
    accountOrPasswordIsNotCorrect: `Akun atau password salah, silakan periksa kembali`,
    phoneNumberMustBeDigits: `Nomor telepon harus 11 digit`,
    startWithAndDigits: `Harus diawali dengan angka 8 dan 9-12 digit`,
    startWithMust: `Harus diawali dengan angka 9`,
    isNotCorrectPleaseCheckPhone: `Nomor telpon salah, silakan periksa kembali`,
    successfulRegistration: `Registrasi berhasil!`,
    successfulLogin: `Login berhasil!`,
    addressAlreadyExistsEmail: `Alamat email sudah terdaftar`,
    haveSendTooManyTimesPlease: `Anda sudah mengirim terlalu banyak, silakan coba lagi dalam waktu 24 jam!`,
    codeErrorVerification: `Kode verifikasi salah`,
    codeHasExpiredVerification: `Kode verifikasi telah kadaluarsa`,
    passwordConsistsOfAtLeast: `Kata sandi terdiri dari setidaknya 8 huruf, angka dan karakter khusus`,
    codeVerification: `Kode verifikasi`,
    password: `Kata sandi`,
    anAccountloginHave: `Sudah punya akun?`,
    newPasswordCannotBeTheSame: `Kata sandi baru tidak boleh sama dengan kata sandi awal!`,
    enterPhoneNumberPlease: `Silakan masukan nomor telepon`,
    phoneNumberHasBeenUsedPlease: `Nomor telepon sudah digunakan, silakan masukkan kembali`,
    toGineeWelcome: `Selamat datang di Ginee`,
    nHello: `Halo, {n}`,
    orderForYouToHaveA: `Untuk pengalaman Anda yang lebih baik, harap lengkapi informasi berikut ini. Terima kasih`,
    enterTheChannelStoreNamePlease: `Silakan masukan nama toko channel`,
    CharactersMaximum: `Maksimal 100 karakter`,
    byRegistering: `Dengan mendaftar, Saya setuju dengan`,
    goUpgrade: "Upgrade",
    cancel: "Batalkan",
    masterProductQuantityHasReachedThe: "Jumlah Master Produk telah mencapai batas paket",
    afterUpgradingThePackageMoreMaster: "Setelah pembaruan paket, lebih banyak Master Produk dapat ditambahkan"
  },
  th: {
    congratulationsYouHaveReceivedADay: "\u0E22\u0E34\u0E19\u0E14\u0E35\u0E14\u0E49\u0E27\u0E22! \u0E04\u0E38\u0E13\u0E44\u0E14\u0E49\u0E23\u0E31\u0E1A\u0E01\u0E32\u0E23\u0E17\u0E14\u0E25\u0E2D\u0E07\u0E43\u0E0A\u0E49\u0E1F\u0E35\u0E40\u0E08\u0E2D\u0E23\u0E4C\u0E40\u0E15\u0E47\u0E21\u0E23\u0E39\u0E1B\u0E41\u0E1A\u0E1A\u0E1F\u0E23\u0E35 7 \u0E27\u0E31\u0E19",
    tryNoww: "\u0E17\u0E14\u0E25\u0E2D\u0E07\u0E40\u0E25\u0E22",
    orderMaxTip: `\u0E43\u0E19\u0E0A\u0E48\u0E27\u0E07\u0E23\u0E30\u0E22\u0E30\u0E40\u0E27\u0E25\u0E32\u0E17\u0E35\u0E48\u0E21\u0E35\u0E1C\u0E25\u0E43\u0E0A\u0E49\u0E07\u0E32\u0E19\u0E02\u0E2D\u0E07\u0E41\u0E1E\u0E47\u0E04\u0E40\u0E01\u0E08 \u0E08\u0E33\u0E19\u0E27\u0E19\u0E04\u0E33\u0E2A\u0E31\u0E48\u0E07\u0E0B\u0E37\u0E49\u0E2D\u0E17\u0E35\u0E48\u0E2A\u0E30\u0E2A\u0E21\u0E44\u0E14\u0E49\u0E40\u0E01\u0E34\u0E19\u0E02\u0E35\u0E14\u0E08\u0E33\u0E01\u0E31\u0E14\u0E02\u0E2D\u0E07\u0E41\u0E1E\u0E47\u0E04\u0E40\u0E01\u0E08 \u0E42\u0E1B\u0E23\u0E14\u0E2A\u0E21\u0E31\u0E04\u0E23\u0E43\u0E0A\u0E49\u0E07\u0E32\u0E19\u0E41\u0E1E\u0E47\u0E04\u0E40\u0E01\u0E08\u0E2B\u0E23\u0E37\u0E2D\u0E15\u0E34\u0E14\u0E15\u0E48\u0E2D\u0E1D\u0E48\u0E32\u0E22\u0E1A\u0E23\u0E34\u0E01\u0E32\u0E23\u0E25\u0E39\u0E01\u0E04\u0E49\u0E32\u0E40\u0E1E\u0E37\u0E48\u0E2D\u0E2D\u0E31\u0E1B\u0E40\u0E01\u0E23\u0E14\u0E41\u0E1E\u0E47\u0E04\u0E40\u0E01\u0E08`,
    selected: `\u0E17\u0E35\u0E48\u0E40\u0E25\u0E37\u0E2D\u0E01`,
    selectedXStore: `\u0E40\u0E25\u0E37\u0E2D\u0E01 {x} \u0E23\u0E49\u0E32\u0E19\u0E04\u0E49\u0E32`,
    pleaseSelectAStore: `\u0E40\u0E25\u0E37\u0E2D\u0E01\u0E23\u0E49\u0E32\u0E19\u0E04\u0E49\u0E32`,
    pleaseSelectAStaffAccount: `\u0E41\u0E1E\u0E47\u0E04\u0E40\u0E01\u0E08\u0E02\u0E2D\u0E07\u0E04\u0E38\u0E13\u0E08\u0E33\u0E01\u0E31\u0E14\u0E01\u0E32\u0E23\u0E2A\u0E34\u0E17\u0E18\u0E34\u0E4C\u0E1A\u0E32\u0E07\u0E23\u0E32\u0E22\u0E01\u0E32\u0E23 \u0E01\u0E23\u0E38\u0E13\u0E32\u0E1B\u0E23\u0E31\u0E1A\u0E01\u0E32\u0E23\u0E40\u0E0A\u0E37\u0E48\u0E2D\u0E21\u0E15\u0E48\u0E2D\u0E2B\u0E23\u0E37\u0E2D\u0E2D\u0E31\u0E1B\u0E40\u0E01\u0E23\u0E14\u0E41\u0E1E\u0E47\u0E04\u0E40\u0E01\u0E08\u0E40\u0E1E\u0E37\u0E48\u0E2D\u0E43\u0E0A\u0E49\u0E07\u0E32\u0E19 Ginee OMS \u0E15\u0E48\u0E2D\u0E44\u0E1B`,
    contactus: `\u0E15\u0E34\u0E14\u0E15\u0E48\u0E2D\u0E40\u0E23\u0E32`,
    PleaseSelect: `\u0E42\u0E1B\u0E23\u0E14\u0E40\u0E25\u0E37\u0E2D\u0E01`,
    staffNotAuthority: `\u0E04\u0E38\u0E13\u0E44\u0E21\u0E48\u0E21\u0E35\u0E2A\u0E34\u0E17\u0E18\u0E34\u0E4C\u0E43\u0E19\u0E01\u0E32\u0E23\u0E43\u0E0A\u0E49\u0E07\u0E32\u0E19\u0E1F\u0E31\u0E07\u0E01\u0E4C\u0E0A\u0E31\u0E19\u0E19\u0E35\u0E49 \u0E42\u0E1B\u0E23\u0E14\u0E15\u0E34\u0E14\u0E15\u0E48\u0E2D\u0E17\u0E35\u0E21\u0E07\u0E32\u0E19 Ginee \u0E40\u0E1E\u0E37\u0E48\u0E2D\u0E40\u0E1B\u0E34\u0E14\u0E01\u0E32\u0E23\u0E43\u0E0A\u0E49\u0E07\u0E32\u0E19`,
    permissions: `\u0E2A\u0E34\u0E17\u0E18\u0E34\u0E4C:`,
    selectedPermissions: `\u0E2A\u0E34\u0E17\u0E18\u0E34\u0E4C\u0E17\u0E35\u0E48\u0E40\u0E25\u0E37\u0E2D\u0E01`,
    theRoleCannotBeEmptyPlease: `\u0E1A\u0E17\u0E1A\u0E32\u0E17\u0E44\u0E21\u0E48\u0E2A\u0E32\u0E21\u0E32\u0E23\u0E16\u0E40\u0E27\u0E49\u0E19\u0E27\u0E48\u0E32\u0E07\u0E44\u0E14\u0E49 \u0E42\u0E1B\u0E23\u0E14\u0E40\u0E25\u0E37\u0E2D\u0E01\u0E1A\u0E17\u0E1A\u0E32\u0E17`,
    dearCustomer: `\u0E40\u0E23\u0E35\u0E22\u0E19\u0E25\u0E39\u0E01\u0E04\u0E49\u0E32`,
    yourFollowingResourcesHaveExceededThe: `\u0E41\u0E1E\u0E47\u0E04\u0E40\u0E01\u0E08\u0E02\u0E2D\u0E07\u0E04\u0E38\u0E13\u0E08\u0E33\u0E01\u0E31\u0E14\u0E01\u0E32\u0E23\u0E40\u0E0A\u0E37\u0E48\u0E2D\u0E21\u0E15\u0E48\u0E2D\u0E1A\u0E32\u0E07\u0E23\u0E32\u0E22\u0E01\u0E32\u0E23 \u0E01\u0E23\u0E38\u0E13\u0E32\u0E1B\u0E23\u0E31\u0E1A\u0E01\u0E32\u0E23\u0E40\u0E0A\u0E37\u0E48\u0E2D\u0E21\u0E15\u0E48\u0E2D\u0E2B\u0E23\u0E37\u0E2D\u0E2D\u0E31\u0E1B\u0E40\u0E01\u0E23\u0E14\u0E41\u0E1E\u0E47\u0E04\u0E40\u0E01\u0E08\u0E40\u0E1E\u0E37\u0E48\u0E2D\u0E43\u0E0A\u0E49\u0E07\u0E32\u0E19 Ginee OMS \u0E15\u0E48\u0E2D\u0E44\u0E1B`,
    numberOfStores: `\u0E08\u0E33\u0E19\u0E27\u0E19\u0E23\u0E49\u0E32\u0E19\u0E04\u0E49\u0E32`,
    RESTRICTED: `\u0E1B\u0E34\u0E14\u0E01\u0E32\u0E23\u0E43\u0E0A\u0E49\u0E07\u0E32\u0E19`,
    theDefinitionOfDisabledIsStore: `\u0E1B\u0E34\u0E14\u0E01\u0E32\u0E23\u0E43\u0E0A\u0E49\u0E07\u0E32\u0E19: \u0E2B\u0E25\u0E31\u0E07\u0E08\u0E32\u0E01\u0E17\u0E35\u0E48\u0E23\u0E49\u0E32\u0E19\u0E04\u0E49\u0E32\u0E16\u0E39\u0E01\u0E1B\u0E34\u0E14\u0E43\u0E0A\u0E49\u0E07\u0E32\u0E19 \u0E08\u0E30\u0E44\u0E21\u0E48\u0E2A\u0E48\u0E07\u0E1C\u0E25\u0E01\u0E23\u0E30\u0E17\u0E1A\u0E15\u0E48\u0E2D\u0E01\u0E32\u0E23\u0E02\u0E32\u0E22\u0E43\u0E19\u0E23\u0E49\u0E32\u0E19\u0E04\u0E49\u0E32\u0E0A\u0E48\u0E2D\u0E07\u0E17\u0E32\u0E07 \u0E2A\u0E34\u0E19\u0E04\u0E49\u0E32\u0E0A\u0E48\u0E2D\u0E07\u0E17\u0E32\u0E07\u0E02\u0E2D\u0E07\u0E23\u0E49\u0E32\u0E19\u0E04\u0E49\u0E32\u0E08\u0E30\u0E16\u0E39\u0E01\u0E22\u0E01\u0E40\u0E25\u0E34\u0E01\u0E01\u0E32\u0E23\u0E40\u0E0A\u0E37\u0E48\u0E2D\u0E21\u0E42\u0E22\u0E07\u0E42\u0E14\u0E22\u0E2D\u0E31\u0E15\u0E42\u0E19\u0E21\u0E31\u0E15\u0E34\u0E08\u0E32\u0E01 SKU \u0E2B\u0E25\u0E31\u0E01 \u0E02\u0E49\u0E2D\u0E21\u0E39\u0E25\u0E2A\u0E34\u0E19\u0E04\u0E49\u0E32/\u0E04\u0E33\u0E2A\u0E31\u0E48\u0E07\u0E0B\u0E37\u0E49\u0E2D\u0E02\u0E2D\u0E07\u0E23\u0E49\u0E32\u0E19\u0E04\u0E49\u0E32\u0E08\u0E30\u0E44\u0E21\u0E48\u0E41\u0E2A\u0E14\u0E07\u0E43\u0E19 Ginee OMS`,
    selectXStoresToContinueBinding: `\u0E40\u0E25\u0E37\u0E2D\u0E01\u0E23\u0E49\u0E32\u0E19\u0E04\u0E49\u0E32 {x} \u0E23\u0E49\u0E32\u0E19\u0E17\u0E35\u0E48\u0E15\u0E49\u0E2D\u0E07\u0E01\u0E32\u0E23\u0E43\u0E0A\u0E49\u0E07\u0E32\u0E19\u0E15\u0E48\u0E2D \u0E41\u0E25\u0E30\u0E23\u0E49\u0E32\u0E19\u0E04\u0E49\u0E32\u0E17\u0E35\u0E48\u0E40\u0E2B\u0E25\u0E37\u0E2D\u0E08\u0E30\u0E16\u0E39\u0E01\u0E1B\u0E34\u0E14\u0E43\u0E0A\u0E49\u0E07\u0E32\u0E19 (\u0E2B\u0E25\u0E31\u0E07\u0E08\u0E32\u0E01\u0E1B\u0E34\u0E14\u0E43\u0E0A\u0E49\u0E07\u0E32\u0E19 \u0E08\u0E30\u0E44\u0E21\u0E48\u0E2A\u0E48\u0E07\u0E1C\u0E25\u0E15\u0E48\u0E2D\u0E22\u0E2D\u0E14\u0E02\u0E32\u0E22\u0E43\u0E19\u0E23\u0E49\u0E32\u0E19 \u0E41\u0E15\u0E48 Ginee OMS \u0E08\u0E30\u0E44\u0E21\u0E48\u0E41\u0E2A\u0E14\u0E07\u0E2A\u0E34\u0E19\u0E04\u0E49\u0E32/\u0E04\u0E33\u0E2A\u0E31\u0E48\u0E07\u0E0B\u0E37\u0E49\u0E2D\u0E02\u0E2D\u0E07\u0E23\u0E49\u0E32\u0E19\u0E04\u0E49\u0E32\u0E41\u0E25\u0E30\u0E02\u0E49\u0E2D\u0E21\u0E39\u0E25\u0E2D\u0E37\u0E48\u0E19\u0E46)`,
    chooseStore: `\u0E40\u0E25\u0E37\u0E2D\u0E01\u0E23\u0E49\u0E32\u0E19\u0E04\u0E49\u0E32`,
    staffAccount: `\u0E1A\u0E31\u0E0D\u0E0A\u0E35\u0E1E\u0E19\u0E31\u0E01\u0E07\u0E32\u0E19`,
    selectXStaffAccountsToContinue: `\u0E40\u0E25\u0E37\u0E2D\u0E01\u0E1A\u0E31\u0E0D\u0E0A\u0E35\u0E1E\u0E19\u0E31\u0E01\u0E07\u0E32\u0E19 0 \u0E1A\u0E31\u0E0D\u0E0A\u0E35\u0E17\u0E35\u0E48\u0E15\u0E49\u0E2D\u0E07\u0E01\u0E32\u0E23\u0E43\u0E0A\u0E49\u0E07\u0E32\u0E19\u0E15\u0E48\u0E2D \u0E42\u0E14\u0E22\u0E1A\u0E31\u0E0D\u0E0A\u0E35\u0E17\u0E35\u0E48\u0E40\u0E2B\u0E25\u0E37\u0E2D\u0E08\u0E30\u0E16\u0E39\u0E01\u0E25\u0E1A\u0E2D\u0E2D\u0E01 (\u0E08\u0E30\u0E16\u0E39\u0E01\u0E25\u0E1A\u0E40\u0E09\u0E1E\u0E32\u0E30\u0E1A\u0E31\u0E0D\u0E0A\u0E35\u0E1E\u0E19\u0E31\u0E01\u0E07\u0E32\u0E19\u0E02\u0E2D\u0E07 Ginee OMS \u0E40\u0E17\u0E48\u0E32\u0E19\u0E31\u0E49\u0E19 \u0E23\u0E30\u0E1A\u0E1A Ginee \u0E2D\u0E37\u0E48\u0E19\u0E46 \u0E08\u0E30\u0E44\u0E21\u0E48\u0E16\u0E39\u0E01\u0E25\u0E1A\u0E2D\u0E2D\u0E01)`,
    subscriptionPackage: `\u0E2A\u0E21\u0E31\u0E04\u0E23\u0E2A\u0E21\u0E32\u0E0A\u0E34\u0E01\u0E41\u0E1E\u0E47\u0E04\u0E40\u0E01\u0E08`,
    displayTheStoresDataInformationIn: `\u0E41\u0E2A\u0E14\u0E07\u0E02\u0E49\u0E2D\u0E21\u0E39\u0E25\u0E02\u0E2D\u0E07\u0E23\u0E49\u0E32\u0E19\u0E04\u0E49\u0E32\u0E43\u0E19 Ginee OMS`,
    reactivate: `\u0E40\u0E1B\u0E34\u0E14\u0E43\u0E0A\u0E49\u0E07\u0E32\u0E19\u0E2D\u0E35\u0E01\u0E04\u0E23\u0E31\u0E49\u0E07`,
    theStoreAuthorizationIsSuccessfulBut: `\u0E40\u0E0A\u0E37\u0E48\u0E2D\u0E21\u0E15\u0E48\u0E2D\u0E23\u0E49\u0E32\u0E19\u0E04\u0E49\u0E32\u0E2A\u0E33\u0E40\u0E23\u0E47\u0E08 \u0E41\u0E15\u0E48\u0E44\u0E21\u0E48\u0E2A\u0E32\u0E21\u0E32\u0E23\u0E16\u0E41\u0E2A\u0E14\u0E07\u0E43\u0E19 Ginee OMS \u0E44\u0E14\u0E49
\u0E40\u0E19\u0E37\u0E48\u0E2D\u0E07\u0E08\u0E32\u0E01\u0E08\u0E33\u0E19\u0E27\u0E19\u0E23\u0E49\u0E32\u0E19\u0E04\u0E49\u0E32\u0E40\u0E01\u0E34\u0E19\u0E02\u0E35\u0E14\u0E08\u0E33\u0E01\u0E31\u0E14\u0E02\u0E2D\u0E07\u0E41\u0E1E\u0E47\u0E04\u0E40\u0E01\u0E08 \u0E23\u0E49\u0E32\u0E19\u0E04\u0E49\u0E32\u0E08\u0E36\u0E07\u0E16\u0E39\u0E01\u0E1B\u0E34\u0E14\u0E43\u0E0A\u0E49\u0E07\u0E32\u0E19 (\u0E2B\u0E25\u0E31\u0E07\u0E08\u0E32\u0E01\u0E17\u0E35\u0E48\u0E23\u0E49\u0E32\u0E19\u0E04\u0E49\u0E32\u0E16\u0E39\u0E01\u0E1B\u0E34\u0E14\u0E43\u0E0A\u0E49\u0E07\u0E32\u0E19 \u0E08\u0E30\u0E44\u0E21\u0E48\u0E2A\u0E48\u0E07\u0E1C\u0E25\u0E15\u0E48\u0E2D\u0E01\u0E32\u0E23\u0E02\u0E32\u0E22\u0E02\u0E2D\u0E07\u0E23\u0E49\u0E32\u0E19\u0E04\u0E49\u0E32\u0E43\u0E19\u0E0A\u0E48\u0E2D\u0E07\u0E17\u0E32\u0E07 \u0E2A\u0E34\u0E19\u0E04\u0E49\u0E32\u0E0A\u0E48\u0E2D\u0E07\u0E17\u0E32\u0E07\u0E02\u0E2D\u0E07\u0E23\u0E49\u0E32\u0E19\u0E04\u0E49\u0E32\u0E08\u0E30\u0E16\u0E39\u0E01\u0E22\u0E01\u0E40\u0E25\u0E34\u0E01\u0E01\u0E32\u0E23\u0E40\u0E0A\u0E37\u0E48\u0E2D\u0E21\u0E15\u0E48\u0E2D\u0E42\u0E14\u0E22\u0E2D\u0E31\u0E15\u0E42\u0E19\u0E21\u0E31\u0E15\u0E34\u0E08\u0E32\u0E01 SKU \u0E2B\u0E25\u0E31\u0E01 \u0E02\u0E49\u0E2D\u0E21\u0E39\u0E25\u0E2A\u0E34\u0E19\u0E04\u0E49\u0E32/\u0E04\u0E33\u0E2A\u0E31\u0E48\u0E07\u0E0B\u0E37\u0E49\u0E2D\u0E02\u0E2D\u0E07\u0E23\u0E49\u0E32\u0E19\u0E04\u0E49\u0E32\u0E08\u0E30\u0E44\u0E21\u0E48\u0E41\u0E2A\u0E14\u0E07\u0E43\u0E19 Ginee OMS)`,
    becauseTheNumberOfStoresExceeds: `\u0E04\u0E38\u0E13\u0E44\u0E21\u0E48\u0E21\u0E35\u0E2A\u0E34\u0E17\u0E18\u0E34\u0E4C\u0E43\u0E19\u0E01\u0E32\u0E23\u0E14\u0E33\u0E40\u0E19\u0E34\u0E19\u0E01\u0E32\u0E23 \u0E41\u0E1E\u0E47\u0E04\u0E40\u0E01\u0E08\u0E02\u0E2D\u0E07\u0E04\u0E38\u0E13\u0E44\u0E21\u0E48\u0E2A\u0E32\u0E21\u0E32\u0E23\u0E16\u0E43\u0E0A\u0E49\u0E07\u0E32\u0E19\u0E1F\u0E31\u0E07\u0E01\u0E4C\u0E0A\u0E31\u0E19\u0E19\u0E35\u0E49\u0E44\u0E14\u0E49 \u0E01\u0E23\u0E38\u0E13\u0E32\u0E2A\u0E21\u0E31\u0E04\u0E23\u0E2A\u0E21\u0E32\u0E0A\u0E34\u0E01\u0E41\u0E1E\u0E47\u0E04\u0E40\u0E01\u0E08\u0E43\u0E2B\u0E21\u0E48\u0E2B\u0E23\u0E37\u0E2D\u0E15\u0E34\u0E14\u0E15\u0E48\u0E2D\u0E1D\u0E48\u0E32\u0E22\u0E1A\u0E23\u0E34\u0E01\u0E32\u0E23\u0E25\u0E39\u0E01\u0E04\u0E49\u0E32\u0E40\u0E1E\u0E37\u0E48\u0E2D\u0E2D\u0E31\u0E1B\u0E40\u0E01\u0E23\u0E14\u0E41\u0E1E\u0E47\u0E04\u0E40\u0E01\u0E08`,
    youDontHaveOperationAuthorityFeature: `\u0E43\u0E19\u0E0A\u0E48\u0E27\u0E07\u0E23\u0E30\u0E22\u0E30\u0E40\u0E27\u0E25\u0E32\u0E43\u0E0A\u0E49\u0E07\u0E32\u0E19\u0E41\u0E1E\u0E47\u0E04\u0E40\u0E01\u0E08 \u0E08\u0E33\u0E19\u0E27\u0E19\u0E04\u0E33\u0E2A\u0E31\u0E48\u0E07\u0E0B\u0E37\u0E49\u0E2D\u0E2A\u0E30\u0E2A\u0E21\u0E17\u0E35\u0E48\u0E2A\u0E23\u0E49\u0E32\u0E07\u0E02\u0E36\u0E49\u0E19\u0E21\u0E35\u0E16\u0E36\u0E07 {x} \u0E04\u0E33\u0E2A\u0E31\u0E48\u0E07\u0E0B\u0E37\u0E49\u0E2D\u0E41\u0E25\u0E49\u0E27 \u0E2B\u0E25\u0E31\u0E07\u0E08\u0E32\u0E01\u0E40\u0E01\u0E34\u0E19 {y} \u0E04\u0E33\u0E2A\u0E31\u0E48\u0E07\u0E0B\u0E37\u0E49\u0E2D\u0E41\u0E25\u0E49\u0E27 \u0E1F\u0E31\u0E07\u0E01\u0E4C\u0E0A\u0E31\u0E48\u0E19\u0E04\u0E33\u0E2A\u0E31\u0E48\u0E07\u0E0B\u0E37\u0E49\u0E2D\u0E08\u0E30\u0E44\u0E21\u0E48\u0E2A\u0E32\u0E21\u0E32\u0E23\u0E16\u0E43\u0E0A\u0E49\u0E44\u0E14\u0E49 \u0E01\u0E23\u0E38\u0E13\u0E32\u0E2A\u0E21\u0E31\u0E04\u0E23\u0E2A\u0E21\u0E32\u0E0A\u0E34\u0E01\u0E41\u0E1E\u0E47\u0E04\u0E40\u0E01\u0E08\u0E25\u0E48\u0E27\u0E07\u0E2B\u0E19\u0E49\u0E32\u0E2B\u0E23\u0E37\u0E2D\u0E15\u0E34\u0E14\u0E15\u0E48\u0E2D\u0E1D\u0E48\u0E32\u0E22\u0E1A\u0E23\u0E34\u0E01\u0E32\u0E23\u0E25\u0E39\u0E01\u0E04\u0E49\u0E32\u0E40\u0E1E\u0E37\u0E48\u0E2D\u0E2D\u0E31\u0E1B\u0E40\u0E01\u0E23\u0E14\u0E41\u0E1E\u0E47\u0E04\u0E40\u0E01\u0E08`,
    duringEffectivePeriodOfThePackage: `\u0E08\u0E33\u0E19\u0E27\u0E19\u0E23\u0E49\u0E32\u0E19\u0E04\u0E49\u0E32\u0E17\u0E35\u0E48\u0E40\u0E0A\u0E37\u0E48\u0E2D\u0E21\u0E15\u0E48\u0E2D\u0E2B\u0E23\u0E37\u0E2D\u0E1A\u0E31\u0E0D\u0E0A\u0E35\u0E1E\u0E19\u0E31\u0E01\u0E07\u0E32\u0E19\u0E40\u0E01\u0E34\u0E19\u0E01\u0E32\u0E23\u0E43\u0E0A\u0E49\u0E07\u0E32\u0E19\u0E41\u0E1E\u0E47\u0E04\u0E40\u0E01\u0E08 \u0E42\u0E1B\u0E23\u0E14\u0E15\u0E34\u0E14\u0E15\u0E48\u0E2D\u0E17\u0E35\u0E21\u0E07\u0E32\u0E19\u0E02\u0E2D\u0E07\u0E04\u0E38\u0E13\u0E40\u0E1E\u0E37\u0E48\u0E2D\u0E25\u0E07\u0E0A\u0E37\u0E48\u0E2D\u0E40\u0E02\u0E49\u0E32\u0E43\u0E0A\u0E49\u0E1A\u0E31\u0E0D\u0E0A\u0E35\u0E2B\u0E25\u0E31\u0E01\u0E41\u0E25\u0E30\u0E14\u0E33\u0E40\u0E19\u0E34\u0E19\u0E01\u0E32\u0E23`,
    emailOtpCode: `\u0E23\u0E2B\u0E31\u0E2A\u0E22\u0E37\u0E19\u0E22\u0E31\u0E19\u0E2D\u0E35\u0E40\u0E21\u0E25`,
    otpCodeHasBeenSentTo: `\u0E2A\u0E48\u0E07\u0E23\u0E2B\u0E31\u0E2A\u0E22\u0E37\u0E19\u0E22\u0E31\u0E19\u0E44\u0E1B\u0E22\u0E31\u0E07\u0E2D\u0E35\u0E40\u0E21\u0E25\u0E41\u0E25\u0E49\u0E27`,
    failedReason: `\u0E40\u0E2B\u0E15\u0E38\u0E1C\u0E25\u0E17\u0E35\u0E48\u0E25\u0E49\u0E21\u0E40\u0E2B\u0E25\u0E27`,
    inOrderToEnsureTheIntegrity: `\u0E40\u0E1E\u0E37\u0E48\u0E2D\u0E43\u0E2B\u0E49\u0E21\u0E31\u0E48\u0E19\u0E43\u0E08\u0E16\u0E36\u0E07\u0E04\u0E27\u0E32\u0E21\u0E2A\u0E21\u0E1A\u0E39\u0E23\u0E13\u0E4C\u0E02\u0E2D\u0E07\u0E02\u0E49\u0E2D\u0E21\u0E39\u0E25 \u0E02\u0E2D\u0E41\u0E19\u0E30\u0E19\u0E33\u0E43\u0E2B\u0E49\u0E43\u0E0A\u0E49 "\u0E22\u0E31\u0E07\u0E44\u0E21\u0E48\u0E15\u0E34\u0E14\u0E15\u0E32\u0E21\u0E0A\u0E31\u0E48\u0E27\u0E04\u0E23\u0E32\u0E27"`,
    inSomeWarehouseTheWarehouseStock: `\u0E43\u0E19\u0E04\u0E25\u0E31\u0E07\u0E2A\u0E34\u0E19\u0E04\u0E49\u0E32\u0E1A\u0E32\u0E07\u0E41\u0E2B\u0E48\u0E07 \u0E2A\u0E15\u0E47\u0E2D\u0E01\u0E04\u0E25\u0E31\u0E07\u0E2A\u0E34\u0E19\u0E04\u0E49\u0E32\u0E02\u0E2D\u0E07 SKU \u0E2B\u0E25\u0E31\u0E01\u0E44\u0E21\u0E48\u0E43\u0E0A\u0E48 0 \u0E42\u0E1B\u0E23\u0E14\u0E1B\u0E23\u0E31\u0E1A\u0E2A\u0E15\u0E47\u0E2D\u0E01\u0E40\u0E1B\u0E47\u0E19 0 \u0E01\u0E48\u0E2D\u0E19\u0E25\u0E1A`,
    thereIsAnUnprocessedOutboundIn: `\u0E21\u0E35\u0E43\u0E1A\u0E04\u0E25\u0E31\u0E07\u0E2A\u0E34\u0E19\u0E04\u0E49\u0E32\u0E02\u0E32\u0E2D\u0E2D\u0E01\u0E17\u0E35\u0E48\u0E22\u0E31\u0E07\u0E44\u0E21\u0E48\u0E44\u0E14\u0E49\u0E14\u0E33\u0E40\u0E19\u0E34\u0E19\u0E01\u0E32\u0E23\u0E43\u0E19 SKU \u0E2B\u0E25\u0E31\u0E01`,
    theMasterSkuHasBeenMatched: `SKU \u0E2B\u0E25\u0E31\u0E01 \u0E08\u0E31\u0E1A\u0E04\u0E39\u0E48\u0E01\u0E31\u0E1A\u0E2A\u0E34\u0E19\u0E04\u0E49\u0E32\u0E08\u0E32\u0E01\u0E04\u0E25\u0E31\u0E07\u0E1D\u0E32\u0E01\u0E2A\u0E15\u0E47\u0E2D\u0E01\u0E2A\u0E34\u0E19\u0E04\u0E49\u0E32\u0E41\u0E25\u0E49\u0E27`,
    weWillSendAnOtpCode: `\u0E40\u0E23\u0E32\u0E08\u0E30\u0E2A\u0E48\u0E07\u0E23\u0E2B\u0E31\u0E2A OTP \u0E44\u0E1B\u0E22\u0E31\u0E07\u0E2B\u0E21\u0E32\u0E22\u0E40\u0E25\u0E02\u0E42\u0E17\u0E23\u0E28\u0E31\u0E1E\u0E17\u0E4C\u0E21\u0E37\u0E2D\u0E16\u0E37\u0E2D\u0E02\u0E2D\u0E07\u0E04\u0E38\u0E13`,
    cantReceiveTheVerificationCodeTry: `\u0E44\u0E21\u0E48\u0E2A\u0E32\u0E21\u0E32\u0E23\u0E16\u0E23\u0E31\u0E1A\u0E23\u0E2B\u0E31\u0E2A\u0E22\u0E37\u0E19\u0E22\u0E31\u0E19\u0E44\u0E14\u0E49? \u0E25\u0E2D\u0E07\u0E43\u0E0A\u0E49\u0E23\u0E2B\u0E31\u0E2A\u0E22\u0E37\u0E19\u0E22\u0E31\u0E19\u0E14\u0E49\u0E27\u0E22\u0E40\u0E2A\u0E35\u0E22\u0E07`,
    weWillDialYourPhoneNumber: `\u0E40\u0E23\u0E32\u0E08\u0E30\u0E01\u0E14\u0E2B\u0E21\u0E32\u0E22\u0E40\u0E25\u0E02\u0E42\u0E17\u0E23\u0E28\u0E31\u0E1E\u0E17\u0E4C\u0E02\u0E2D\u0E07\u0E04\u0E38\u0E13\u0E41\u0E25\u0E30\u0E41\u0E08\u0E49\u0E07\u0E23\u0E2B\u0E31\u0E2A\u0E22\u0E37\u0E19\u0E22\u0E31\u0E19\u0E14\u0E49\u0E27\u0E22\u0E40\u0E2A\u0E35\u0E22\u0E07`,
    basedOnAccountSecurityPleaseLog: `\u0E40\u0E1E\u0E37\u0E48\u0E2D\u0E04\u0E27\u0E32\u0E21\u0E1B\u0E25\u0E2D\u0E14\u0E20\u0E31\u0E22\u0E02\u0E2D\u0E07\u0E1A\u0E31\u0E0D\u0E0A\u0E35 \u0E42\u0E1B\u0E23\u0E14\u0E43\u0E0A\u0E49\u0E2B\u0E21\u0E32\u0E22\u0E40\u0E25\u0E02\u0E42\u0E17\u0E23\u0E28\u0E31\u0E1E\u0E17\u0E4C\u0E21\u0E37\u0E2D\u0E16\u0E37\u0E2D\u0E02\u0E2D\u0E07\u0E04\u0E38\u0E13\u0E40\u0E1E\u0E37\u0E48\u0E2D\u0E40\u0E02\u0E49\u0E32\u0E2A\u0E39\u0E48\u0E23\u0E30\u0E1A\u0E1A\u0E43\u0E19\u0E04\u0E23\u0E31\u0E49\u0E07\u0E19\u0E35\u0E49 \u0E2B\u0E25\u0E31\u0E07\u0E08\u0E32\u0E01\u0E40\u0E02\u0E49\u0E32\u0E2A\u0E39\u0E48\u0E23\u0E30\u0E1A\u0E1A\u0E2A\u0E33\u0E40\u0E23\u0E47\u0E08\u0E41\u0E25\u0E30\u0E22\u0E37\u0E19\u0E22\u0E31\u0E19\u0E2A\u0E33\u0E40\u0E23\u0E47\u0E08\u0E43\u0E19 [\u0E01\u0E32\u0E23\u0E15\u0E31\u0E49\u0E07\u0E04\u0E48\u0E32-\u0E02\u0E49\u0E2D\u0E21\u0E39\u0E25\u0E1A\u0E31\u0E0D\u0E0A\u0E35 -> \u0E01\u0E32\u0E23\u0E15\u0E23\u0E27\u0E08\u0E2A\u0E2D\u0E1A\u0E04\u0E27\u0E32\u0E21\u0E16\u0E39\u0E01\u0E15\u0E49\u0E2D\u0E07\u0E02\u0E2D\u0E07\u0E2D\u0E35\u0E40\u0E21\u0E25] \u0E04\u0E38\u0E13\u0E2A\u0E32\u0E21\u0E32\u0E23\u0E16\u0E40\u0E02\u0E49\u0E32\u0E2A\u0E39\u0E48\u0E23\u0E30\u0E1A\u0E1A\u0E14\u0E49\u0E27\u0E22\u0E2D\u0E35\u0E40\u0E21\u0E25\u0E02\u0E2D\u0E07\u0E04\u0E38\u0E13`,
    hint: `\u0E2B\u0E21\u0E32\u0E22\u0E40\u0E2B\u0E15\u0E38`,
    loginByPhoneNumber: `\u0E40\u0E02\u0E49\u0E32\u0E2A\u0E39\u0E48\u0E23\u0E30\u0E1A\u0E1A\u0E14\u0E49\u0E27\u0E22\u0E2B\u0E21\u0E32\u0E22\u0E40\u0E25\u0E02\u0E42\u0E17\u0E23\u0E28\u0E31\u0E1E\u0E17\u0E4C\u0E21\u0E37\u0E2D\u0E16\u0E37\u0E2D`,
    loginByEmail: `\u0E40\u0E02\u0E49\u0E32\u0E2A\u0E39\u0E48\u0E23\u0E30\u0E1A\u0E1A\u0E14\u0E49\u0E27\u0E22\u0E2D\u0E35\u0E40\u0E21\u0E25`,
    basedOnAccountSecurityPleaseUse: `\u0E40\u0E1E\u0E37\u0E48\u0E2D\u0E04\u0E27\u0E32\u0E21\u0E1B\u0E25\u0E2D\u0E14\u0E20\u0E31\u0E22\u0E02\u0E2D\u0E07\u0E1A\u0E31\u0E0D\u0E0A\u0E35 \u0E42\u0E1B\u0E23\u0E14\u0E43\u0E0A\u0E49\u0E2D\u0E35\u0E40\u0E21\u0E25\u0E02\u0E2D\u0E07\u0E04\u0E38\u0E13\u0E40\u0E1E\u0E37\u0E48\u0E2D\u0E40\u0E02\u0E49\u0E32\u0E2A\u0E39\u0E48\u0E23\u0E30\u0E1A\u0E1A\u0E43\u0E19\u0E04\u0E23\u0E31\u0E49\u0E07\u0E19\u0E35\u0E49
\u0E2B\u0E25\u0E31\u0E07\u0E08\u0E32\u0E01\u0E40\u0E02\u0E49\u0E32\u0E2A\u0E39\u0E48\u0E23\u0E30\u0E1A\u0E1A\u0E2A\u0E33\u0E40\u0E23\u0E47\u0E08\u0E41\u0E25\u0E30\u0E22\u0E37\u0E19\u0E22\u0E31\u0E19\u0E2A\u0E33\u0E40\u0E23\u0E47\u0E08\u0E43\u0E19 [\u0E01\u0E32\u0E23\u0E15\u0E31\u0E49\u0E07\u0E04\u0E48\u0E32-\u0E02\u0E49\u0E2D\u0E21\u0E39\u0E25\u0E1A\u0E31\u0E0D\u0E0A\u0E35->\u0E01\u0E32\u0E23\u0E15\u0E23\u0E27\u0E08\u0E2A\u0E2D\u0E1A\u0E04\u0E27\u0E32\u0E21\u0E16\u0E39\u0E01\u0E15\u0E49\u0E2D\u0E07\u0E02\u0E2D\u0E07\u0E2B\u0E21\u0E32\u0E22\u0E40\u0E25\u0E02\u0E42\u0E17\u0E23\u0E28\u0E31\u0E1E\u0E17\u0E4C\u0E21\u0E37\u0E2D\u0E16\u0E37\u0E2D] \u0E04\u0E38\u0E13\u0E2A\u0E32\u0E21\u0E32\u0E23\u0E16\u0E40\u0E02\u0E49\u0E32\u0E2A\u0E39\u0E48\u0E23\u0E30\u0E1A\u0E1A\u0E14\u0E49\u0E27\u0E22\u0E2B\u0E21\u0E32\u0E22\u0E40\u0E25\u0E02\u0E42\u0E17\u0E23\u0E28\u0E31\u0E1E\u0E17\u0E4C\u0E21\u0E37\u0E2D\u0E16\u0E37\u0E2D\u0E02\u0E2D\u0E07\u0E04\u0E38\u0E13`,
    resetPasswordWithPhoneNumber: `\u0E23\u0E35\u0E40\u0E0B\u0E47\u0E15\u0E23\u0E2B\u0E31\u0E2A\u0E1C\u0E48\u0E32\u0E19\u0E14\u0E49\u0E27\u0E22\u0E42\u0E17\u0E23\u0E28\u0E31\u0E1E\u0E17\u0E4C\u0E21\u0E37\u0E2D\u0E16\u0E37\u0E2D`,
    resetPasswordWithEmail: `\u0E23\u0E35\u0E40\u0E0B\u0E47\u0E15\u0E23\u0E2B\u0E31\u0E2A\u0E1C\u0E48\u0E32\u0E19\u0E14\u0E49\u0E27\u0E22\u0E2D\u0E35\u0E40\u0E21\u0E25`,
    basedOnAccountSecurityPleaseUse1: `\u0E40\u0E1E\u0E37\u0E48\u0E2D\u0E04\u0E27\u0E32\u0E21\u0E1B\u0E25\u0E2D\u0E14\u0E20\u0E31\u0E22\u0E02\u0E2D\u0E07\u0E1A\u0E31\u0E0D\u0E0A\u0E35 \u0E42\u0E1B\u0E23\u0E14\u0E43\u0E0A\u0E49\u0E2D\u0E35\u0E40\u0E21\u0E25\u0E02\u0E2D\u0E07\u0E04\u0E38\u0E13\u0E40\u0E1E\u0E37\u0E48\u0E2D\u0E40\u0E02\u0E49\u0E32\u0E2A\u0E39\u0E48\u0E23\u0E30\u0E1A\u0E1A\u0E43\u0E19\u0E04\u0E23\u0E31\u0E49\u0E07\u0E19\u0E35\u0E49
\u0E2B\u0E25\u0E31\u0E07\u0E08\u0E32\u0E01\u0E40\u0E02\u0E49\u0E32\u0E2A\u0E39\u0E48\u0E23\u0E30\u0E1A\u0E1A\u0E2A\u0E33\u0E40\u0E23\u0E47\u0E08\u0E41\u0E25\u0E30\u0E22\u0E37\u0E19\u0E22\u0E31\u0E19\u0E2A\u0E33\u0E40\u0E23\u0E47\u0E08\u0E43\u0E19 [\u0E01\u0E32\u0E23\u0E15\u0E31\u0E49\u0E07\u0E04\u0E48\u0E32-\u0E02\u0E49\u0E2D\u0E21\u0E39\u0E25\u0E1A\u0E31\u0E0D\u0E0A\u0E35->\u0E01\u0E32\u0E23\u0E15\u0E23\u0E27\u0E08\u0E2A\u0E2D\u0E1A\u0E04\u0E27\u0E32\u0E21\u0E16\u0E39\u0E01\u0E15\u0E49\u0E2D\u0E07\u0E02\u0E2D\u0E07\u0E2B\u0E21\u0E32\u0E22\u0E40\u0E25\u0E02\u0E42\u0E17\u0E23\u0E28\u0E31\u0E1E\u0E17\u0E4C\u0E21\u0E37\u0E2D\u0E16\u0E37\u0E2D] \u0E04\u0E38\u0E13\u0E2A\u0E32\u0E21\u0E32\u0E23\u0E16\u0E40\u0E02\u0E49\u0E32\u0E2A\u0E39\u0E48\u0E23\u0E30\u0E1A\u0E1A\u0E14\u0E49\u0E27\u0E22\u0E2B\u0E21\u0E32\u0E22\u0E40\u0E25\u0E02\u0E42\u0E17\u0E23\u0E28\u0E31\u0E1E\u0E17\u0E4C\u0E21\u0E37\u0E2D\u0E16\u0E37\u0E2D\u0E02\u0E2D\u0E07\u0E04\u0E38\u0E13`,
    resetPasswordWithEmail1: `\u0E23\u0E35\u0E40\u0E0B\u0E47\u0E15\u0E23\u0E2B\u0E31\u0E2A\u0E1C\u0E48\u0E32\u0E19\u0E14\u0E49\u0E27\u0E22\u0E2D\u0E35\u0E40\u0E21\u0E25`,
    basedOnAccountSecurityPleaseUse2: `\u0E40\u0E1E\u0E37\u0E48\u0E2D\u0E04\u0E27\u0E32\u0E21\u0E1B\u0E25\u0E2D\u0E14\u0E20\u0E31\u0E22\u0E02\u0E2D\u0E07\u0E1A\u0E31\u0E0D\u0E0A\u0E35 \u0E42\u0E1B\u0E23\u0E14\u0E23\u0E35\u0E40\u0E0B\u0E47\u0E15\u0E23\u0E2B\u0E31\u0E2A\u0E1C\u0E48\u0E32\u0E19\u0E14\u0E49\u0E27\u0E22\u0E42\u0E17\u0E23\u0E28\u0E31\u0E1E\u0E17\u0E4C\u0E21\u0E37\u0E2D\u0E16\u0E37\u0E2D\u0E43\u0E19\u0E04\u0E23\u0E31\u0E49\u0E07\u0E19\u0E35\u0E49 \u0E2B\u0E25\u0E31\u0E07\u0E08\u0E32\u0E01\u0E40\u0E02\u0E49\u0E32\u0E2A\u0E39\u0E48\u0E23\u0E30\u0E1A\u0E1A\u0E2A\u0E33\u0E40\u0E23\u0E47\u0E08\u0E41\u0E25\u0E30\u0E22\u0E37\u0E19\u0E22\u0E31\u0E19\u0E2A\u0E33\u0E40\u0E23\u0E47\u0E08\u0E43\u0E19 [\u0E01\u0E32\u0E23\u0E15\u0E31\u0E49\u0E07\u0E04\u0E48\u0E32-\u0E02\u0E49\u0E2D\u0E21\u0E39\u0E25\u0E1A\u0E31\u0E0D\u0E0A\u0E35 -> \u0E01\u0E32\u0E23\u0E15\u0E23\u0E27\u0E08\u0E2A\u0E2D\u0E1A\u0E04\u0E27\u0E32\u0E21\u0E16\u0E39\u0E01\u0E15\u0E49\u0E2D\u0E07\u0E02\u0E2D\u0E07\u0E2D\u0E35\u0E40\u0E21\u0E25] \u0E04\u0E38\u0E13\u0E2A\u0E32\u0E21\u0E32\u0E23\u0E16\u0E23\u0E35\u0E40\u0E0B\u0E47\u0E15\u0E23\u0E2B\u0E31\u0E2A\u0E1C\u0E48\u0E32\u0E19\u0E17\u0E32\u0E07\u0E2D\u0E35\u0E40\u0E21\u0E25`,
    basedOnAccountSecurityPleaseUse3: `\u0E15\u0E32\u0E21\u0E04\u0E27\u0E32\u0E21\u0E1B\u0E25\u0E2D\u0E14\u0E20\u0E31\u0E22\u0E02\u0E2D\u0E07\u0E1A\u0E31\u0E0D\u0E0A\u0E35 \u0E42\u0E1B\u0E23\u0E14\u0E43\u0E0A\u0E49\u0E2D\u0E35\u0E40\u0E21\u0E25\u0E02\u0E2D\u0E07\u0E04\u0E38\u0E13\u0E40\u0E1E\u0E37\u0E48\u0E2D\u0E23\u0E35\u0E40\u0E0B\u0E47\u0E15\u0E23\u0E2B\u0E31\u0E2A\u0E1C\u0E48\u0E32\u0E19 \u0E2B\u0E25\u0E31\u0E07\u0E08\u0E32\u0E01\u0E40\u0E02\u0E49\u0E32\u0E2A\u0E39\u0E48\u0E23\u0E30\u0E1A\u0E1A\u0E2A\u0E33\u0E40\u0E23\u0E47\u0E08\u0E41\u0E25\u0E30\u0E23\u0E31\u0E1A\u0E23\u0E2D\u0E07\u0E04\u0E27\u0E32\u0E21\u0E16\u0E39\u0E01\u0E15\u0E49\u0E2D\u0E07\u0E43\u0E19 [\u0E01\u0E32\u0E23\u0E15\u0E31\u0E49\u0E07\u0E04\u0E48\u0E32 - \u0E02\u0E49\u0E2D\u0E21\u0E39\u0E25\u0E1A\u0E31\u0E0D\u0E0A\u0E35 -> \u0E01\u0E32\u0E23\u0E15\u0E23\u0E27\u0E08\u0E2A\u0E2D\u0E1A\u0E2B\u0E21\u0E32\u0E22\u0E40\u0E25\u0E02\u0E42\u0E17\u0E23\u0E28\u0E31\u0E1E\u0E17\u0E4C] \u0E04\u0E38\u0E13\u0E2A\u0E32\u0E21\u0E32\u0E23\u0E16\u0E43\u0E0A\u0E49\u0E2B\u0E21\u0E32\u0E22\u0E40\u0E25\u0E02\u0E42\u0E17\u0E23\u0E28\u0E31\u0E1E\u0E17\u0E4C\u0E02\u0E2D\u0E07\u0E04\u0E38\u0E13\u0E40\u0E1E\u0E37\u0E48\u0E2D\u0E23\u0E35\u0E40\u0E0B\u0E47\u0E15\u0E23\u0E2B\u0E31\u0E2A\u0E1C\u0E48\u0E32\u0E19\u0E44\u0E14\u0E49`,
    notAuthenticate: `\u0E44\u0E21\u0E48\u0E44\u0E14\u0E49\u0E23\u0E31\u0E1A\u0E23\u0E2D\u0E07\u0E04\u0E27\u0E32\u0E21\u0E16\u0E39\u0E01\u0E15\u0E49\u0E2D\u0E07`,
    afterAuthenticationYouCanLogIn: `\u0E2B\u0E25\u0E31\u0E07\u0E08\u0E32\u0E01\u0E44\u0E14\u0E49\u0E23\u0E31\u0E1A\u0E01\u0E32\u0E23\u0E23\u0E31\u0E1A\u0E23\u0E2D\u0E07\u0E41\u0E25\u0E49\u0E27 \u0E04\u0E38\u0E13\u0E2A\u0E32\u0E21\u0E32\u0E23\u0E16\u0E40\u0E02\u0E49\u0E32\u0E2A\u0E39\u0E48\u0E23\u0E30\u0E1A\u0E1A\u0E42\u0E14\u0E22\u0E43\u0E0A\u0E49\u0E42\u0E17\u0E23\u0E28\u0E31\u0E1E\u0E17\u0E4C\u0E21\u0E37\u0E2D\u0E16\u0E37\u0E2D\u0E41\u0E25\u0E30\u0E23\u0E35\u0E40\u0E0A\u0E47\u0E15\u0E23\u0E2B\u0E31\u0E2A\u0E1C\u0E48\u0E32\u0E19`,
    goToAuthentication: `\u0E44\u0E1B\u0E23\u0E31\u0E1A\u0E23\u0E2D\u0E07\u0E04\u0E27\u0E32\u0E21\u0E16\u0E39\u0E01\u0E15\u0E49\u0E2D\u0E07`,
    afterAuthenticationYouCanLogIn1: `\u0E2B\u0E25\u0E31\u0E07\u0E08\u0E32\u0E01\u0E44\u0E14\u0E49\u0E23\u0E31\u0E1A\u0E23\u0E2D\u0E07\u0E04\u0E27\u0E32\u0E21\u0E16\u0E39\u0E01\u0E15\u0E49\u0E2D\u0E07\u0E2A\u0E33\u0E40\u0E23\u0E47\u0E08\u0E41\u0E25\u0E49\u0E27 \u0E04\u0E38\u0E13\u0E2A\u0E32\u0E21\u0E32\u0E23\u0E16\u0E40\u0E02\u0E49\u0E32\u0E2A\u0E39\u0E48\u0E23\u0E30\u0E1A\u0E1A\u0E42\u0E14\u0E22\u0E43\u0E0A\u0E49\u0E2D\u0E35\u0E40\u0E21\u0E25\u0E41\u0E25\u0E30\u0E23\u0E35\u0E40\u0E0A\u0E47\u0E15\u0E23\u0E2B\u0E31\u0E2A\u0E1C\u0E48\u0E32\u0E19`,
    pleaseEnterYourAccountPassword: `\u0E01\u0E23\u0E38\u0E13\u0E32\u0E43\u0E2A\u0E48\u0E23\u0E2B\u0E31\u0E2A\u0E1C\u0E48\u0E32\u0E19\u0E1A\u0E31\u0E0D\u0E0A\u0E35\u0E02\u0E2D\u0E07\u0E04\u0E38\u0E13`,
    toProtectYourAccountSecurityPlease: `\u0E40\u0E1E\u0E37\u0E48\u0E2D\u0E04\u0E27\u0E32\u0E21\u0E1B\u0E25\u0E2D\u0E14\u0E20\u0E31\u0E22\u0E02\u0E2D\u0E07\u0E1A\u0E31\u0E0D\u0E0A\u0E35\u0E02\u0E2D\u0E07\u0E04\u0E38\u0E13 \u0E01\u0E23\u0E38\u0E13\u0E32\u0E01\u0E23\u0E2D\u0E01\u0E23\u0E2B\u0E31\u0E2A\u0E1C\u0E48\u0E32\u0E19\u0E2A\u0E33\u0E2B\u0E23\u0E31\u0E1A\u0E40\u0E02\u0E49\u0E32\u0E2A\u0E39\u0E48\u0E23\u0E30\u0E1A\u0E1A\u0E40\u0E1E\u0E37\u0E48\u0E2D\u0E22\u0E37\u0E19\u0E22\u0E31\u0E19`,
    passwordMustBeAtLeast: `\u0E23\u0E2B\u0E31\u0E2A\u0E1C\u0E48\u0E32\u0E19\u0E15\u0E49\u0E2D\u0E07\u0E21\u0E35\u0E2D\u0E22\u0E48\u0E32\u0E07\u0E19\u0E49\u0E2D\u0E22 8 \u0E2B\u0E25\u0E31\u0E01`,
    afterVerificationYouCanUseThe: `\u0E2B\u0E25\u0E31\u0E07\u0E08\u0E32\u0E01\u0E1C\u0E48\u0E32\u0E19\u0E01\u0E32\u0E23\u0E15\u0E23\u0E27\u0E08\u0E2A\u0E2D\u0E1A\u0E41\u0E25\u0E49\u0E27 \u0E2A\u0E32\u0E21\u0E32\u0E23\u0E16\u0E43\u0E0A\u0E49\u0E42\u0E17\u0E23\u0E28\u0E31\u0E1E\u0E17\u0E4C\u0E40\u0E1E\u0E37\u0E48\u0E2D\u0E40\u0E02\u0E49\u0E32\u0E2A\u0E39\u0E48\u0E23\u0E30\u0E1A\u0E1A\u0E41\u0E25\u0E30\u0E40\u0E1B\u0E25\u0E35\u0E48\u0E22\u0E19\u0E23\u0E2B\u0E31\u0E2A\u0E1C\u0E48\u0E32\u0E19\u0E44\u0E14\u0E49`,
    afterVerificationYouCanUseEmail: `\u0E2B\u0E25\u0E31\u0E07\u0E08\u0E32\u0E01\u0E1C\u0E48\u0E32\u0E19\u0E01\u0E32\u0E23\u0E15\u0E23\u0E27\u0E08\u0E2A\u0E2D\u0E1A\u0E41\u0E25\u0E49\u0E27 \u0E2A\u0E32\u0E21\u0E32\u0E23\u0E16\u0E43\u0E0A\u0E49\u0E2D\u0E35\u0E40\u0E21\u0E25\u0E40\u0E1E\u0E37\u0E48\u0E2D\u0E40\u0E02\u0E49\u0E32\u0E2A\u0E39\u0E48\u0E23\u0E30\u0E1A\u0E1A\u0E41\u0E25\u0E30\u0E40\u0E1B\u0E25\u0E35\u0E48\u0E22\u0E19\u0E23\u0E2B\u0E31\u0E2A\u0E1C\u0E48\u0E32\u0E19\u0E44\u0E14\u0E49`,
    authenticationSuccessful: `\u0E23\u0E31\u0E1A\u0E23\u0E2D\u0E07\u0E04\u0E27\u0E32\u0E21\u0E16\u0E39\u0E01\u0E15\u0E49\u0E2D\u0E07\u0E2A\u0E33\u0E40\u0E23\u0E47\u0E08!`,
    merchantId: `ID \u0E1C\u0E39\u0E49\u0E02\u0E32\u0E22`,
    verificationMethod: `\u0E27\u0E34\u0E18\u0E35\u0E01\u0E32\u0E23\u0E15\u0E23\u0E27\u0E08\u0E2A\u0E2D\u0E1A`,
    scene: `\u0E2A\u0E16\u0E32\u0E19\u0E01\u0E32\u0E23\u0E13\u0E4C`,
    verificationCode: `\u0E23\u0E2B\u0E31\u0E2A\u0E22\u0E37\u0E19\u0E22\u0E31\u0E19`,
    sendTime: `\u0E40\u0E27\u0E25\u0E32\u0E17\u0E35\u0E48\u0E2A\u0E48\u0E07`,
    pleaseEnterPhoneNumber: `\u0E01\u0E23\u0E38\u0E13\u0E32\u0E01\u0E23\u0E2D\u0E01\u0E2B\u0E21\u0E32\u0E22\u0E40\u0E25\u0E02\u0E42\u0E17\u0E23\u0E28\u0E31\u0E1E\u0E17\u0E4C\u0E21\u0E37\u0E2D\u0E16\u0E37\u0E2D`,
    sendTooManyTimesPleaseTry: `\u0E2A\u0E48\u0E07\u0E2B\u0E25\u0E32\u0E22\u0E04\u0E23\u0E31\u0E49\u0E07\u0E40\u0E01\u0E34\u0E19\u0E44\u0E1B \u0E01\u0E23\u0E38\u0E13\u0E32\u0E25\u0E2D\u0E07\u0E2D\u0E35\u0E01\u0E04\u0E23\u0E31\u0E49\u0E07\u0E43\u0E19 24 \u0E0A\u0E31\u0E48\u0E27\u0E42\u0E21\u0E07!`,
    accountDoesNotExistPleaseCheck: `\u0E44\u0E21\u0E48\u0E21\u0E35\u0E1A\u0E31\u0E0D\u0E0A\u0E35 \u0E01\u0E23\u0E38\u0E13\u0E32\u0E15\u0E23\u0E27\u0E08\u0E2A\u0E2D\u0E1A!`,
    thisIsAStaffAccountPlease: `\u0E19\u0E35\u0E48\u0E04\u0E37\u0E2D\u0E1A\u0E31\u0E0D\u0E0A\u0E35\u0E1E\u0E19\u0E31\u0E01\u0E07\u0E32\u0E19 \u0E01\u0E23\u0E38\u0E13\u0E32\u0E15\u0E34\u0E14\u0E15\u0E48\u0E2D\u0E17\u0E35\u0E21\u0E07\u0E32\u0E19\u0E02\u0E2D\u0E07\u0E04\u0E38\u0E13\u0E40\u0E1E\u0E37\u0E48\u0E2D\u0E41\u0E01\u0E49\u0E44\u0E02\u0E23\u0E2B\u0E31\u0E2A\u0E1C\u0E48\u0E32\u0E19 (\u0E40\u0E1B\u0E25\u0E35\u0E48\u0E22\u0E19\u0E43\u0E19\u0E1A\u0E31\u0E0D\u0E0A\u0E35\u0E1E\u0E19\u0E31\u0E01\u0E07\u0E32\u0E19-\u0E41\u0E01\u0E49\u0E44\u0E02\u0E1E\u0E19\u0E31\u0E01\u0E07\u0E32\u0E19) \u0E2B\u0E23\u0E37\u0E2D\u0E40\u0E02\u0E49\u0E32\u0E2A\u0E39\u0E48\u0E23\u0E30\u0E1A\u0E1A\u0E41\u0E25\u0E49\u0E27\u0E41\u0E01\u0E49\u0E44\u0E02\u0E23\u0E2B\u0E31\u0E2A\u0E1C\u0E48\u0E32\u0E19 (\u0E01\u0E32\u0E23\u0E15\u0E31\u0E49\u0E07\u0E04\u0E48\u0E32-\u0E41\u0E01\u0E49\u0E44\u0E02\u0E23\u0E2B\u0E31\u0E2A\u0E1C\u0E48\u0E32\u0E19)`,
    send: `\u0E2A\u0E48\u0E07`,
    company: `Copyright 2021 \xA9 \u0E1A\u0E23\u0E34\u0E29\u0E31\u0E17 \u0E2B\u0E25\u0E34\u0E48\u0E07\u0E0A\u0E48\u0E27\u0E07\u0E08\u0E39\u0E25\u0E34\u0E07 \u0E42\u0E2E\u0E25\u0E14\u0E34\u0E49\u0E07\u0E2A\u0E4C (\u0E1B\u0E23\u0E30\u0E40\u0E17\u0E28\u0E08\u0E35\u0E19) \u0E08\u0E33\u0E01\u0E31\u0E14`,
    title: `Ginee Seller Center- \u0E40\u0E02\u0E49\u0E32\u0E2A\u0E39\u0E48\u0E23\u0E30\u0E1A\u0E1A\u0E41\u0E25\u0E30\u0E25\u0E07\u0E17\u0E30\u0E40\u0E1A\u0E35\u0E22\u0E19`,
    content: `\u0E40\u0E02\u0E49\u0E32\u0E2A\u0E39\u0E48\u0E23\u0E30\u0E1A\u0E1A\u0E2B\u0E23\u0E37\u0E2D\u0E25\u0E07\u0E17\u0E30\u0E40\u0E1A\u0E35\u0E22\u0E19\u0E43\u0E0A\u0E49\u0E07\u0E32\u0E19 Ginee \u0E15\u0E2D\u0E19\u0E19\u0E35\u0E49 \u0E40\u0E1E\u0E37\u0E48\u0E2D\u0E08\u0E31\u0E14\u0E01\u0E32\u0E23\u0E23\u0E49\u0E32\u0E19\u0E04\u0E49\u0E32\u0E2D\u0E2D\u0E19\u0E44\u0E25\u0E19\u0E4C\u0E02\u0E2D\u0E07\u0E04\u0E38\u0E13\u0E43\u0E2B\u0E49\u0E07\u0E48\u0E32\u0E22\u0E02\u0E36\u0E49\u0E19!`,
    keyWords: `\u0E40\u0E02\u0E49\u0E32\u0E2A\u0E39\u0E48\u0E23\u0E30\u0E1A\u0E1A Ginee,\u0E25\u0E07\u0E17\u0E30\u0E40\u0E1A\u0E35\u0E22\u0E19 Ginee ,\u0E23\u0E35\u0E40\u0E0B\u0E47\u0E15\u0E23\u0E2B\u0E31\u0E2A\u0E1C\u0E48\u0E32\u0E19 Ginee`,
    confirm: `\u0E22\u0E37\u0E19\u0E22\u0E31\u0E19`,
    ofUseTerms: `\u0E40\u0E07\u0E37\u0E48\u0E2D\u0E19\u0E44\u0E02\u0E01\u0E32\u0E23\u0E43\u0E0A\u0E49\u0E07\u0E32\u0E19`,
    and: `\u0E41\u0E25\u0E30`,
    policyPrivacy: `\u0E19\u0E42\u0E22\u0E1A\u0E32\u0E22\u0E04\u0E27\u0E32\u0E21\u0E40\u0E1B\u0E47\u0E19\u0E2A\u0E48\u0E27\u0E19\u0E15\u0E31\u0E27`,
    businessSmarterMaking: `\u0E17\u0E33\u0E18\u0E38\u0E23\u0E01\u0E34\u0E08\u0E43\u0E2B\u0E49\u0E07\u0E48\u0E32\u0E22\u0E02\u0E36\u0E49\u0E19`,
    successfulReset: `\u0E23\u0E35\u0E40\u0E0B\u0E47\u0E15\u0E2A\u0E33\u0E40\u0E23\u0E47\u0E08`,
    passwordReset: `\u0E23\u0E35\u0E40\u0E0B\u0E47\u0E15\u0E23\u0E2B\u0E31\u0E2A\u0E1C\u0E48\u0E32\u0E19`,
    login: `\u0E40\u0E02\u0E49\u0E32\u0E2A\u0E39\u0E48\u0E23\u0E30\u0E1A\u0E1A`,
    email: `\u0E2D\u0E35\u0E40\u0E21\u0E25`,
    inputYourEmailPlease: `\u0E01\u0E23\u0E38\u0E13\u0E32\u0E01\u0E23\u0E2D\u0E01\u0E2D\u0E35\u0E40\u0E21\u0E25\u0E02\u0E2D\u0E07\u0E04\u0E38\u0E13`,
    passwordForget: `\u0E25\u0E37\u0E21\u0E23\u0E2B\u0E31\u0E2A\u0E1C\u0E48\u0E32\u0E19?`,
    signUp: `\u0E25\u0E07\u0E17\u0E30\u0E40\u0E1A\u0E35\u0E22\u0E19`,
    accountsignUpNew: `\u0E1A\u0E31\u0E0D\u0E0A\u0E35\u0E43\u0E2B\u0E21\u0E48\uFF1F`,
    emailFormatIsNotCorrectPlease: `\u0E23\u0E39\u0E1B\u0E41\u0E1A\u0E1A\u0E2D\u0E35\u0E40\u0E21\u0E25\u0E44\u0E21\u0E48\u0E16\u0E39\u0E01\u0E15\u0E49\u0E2D\u0E07 \u0E42\u0E1B\u0E23\u0E14\u0E01\u0E23\u0E2D\u0E01\u0E2D\u0E35\u0E01\u0E04\u0E23\u0E31\u0E49\u0E07`,
    enterPasswordPlease: `\u0E01\u0E23\u0E38\u0E13\u0E32\u0E01\u0E23\u0E2D\u0E01\u0E23\u0E2B\u0E31\u0E2A\u0E1C\u0E48\u0E32\u0E19`,
    accountOrPasswordIsNotCorrect: `\u0E1A\u0E31\u0E0D\u0E0A\u0E35\u0E2B\u0E23\u0E37\u0E2D\u0E23\u0E2B\u0E31\u0E2A\u0E1C\u0E48\u0E32\u0E19\u0E44\u0E21\u0E48\u0E16\u0E39\u0E01\u0E15\u0E49\u0E2D\u0E07 \u0E42\u0E1B\u0E23\u0E14\u0E15\u0E23\u0E27\u0E08\u0E2A\u0E2D\u0E1A`,
    phoneNumberMustBeDigits: `\u0E2B\u0E21\u0E32\u0E22\u0E40\u0E25\u0E02\u0E42\u0E17\u0E23\u0E28\u0E31\u0E1E\u0E17\u0E4C\u0E15\u0E49\u0E2D\u0E07\u0E40\u0E1B\u0E47\u0E19 11 \u0E2B\u0E25\u0E31\u0E01`,
    startWithAndDigits: `\u0E15\u0E49\u0E2D\u0E07\u0E40\u0E23\u0E34\u0E48\u0E21\u0E15\u0E49\u0E19\u0E14\u0E49\u0E27\u0E22\u0E15\u0E31\u0E27\u0E40\u0E25\u0E02 8 \u0E41\u0E25\u0E30\u0E21\u0E35 9-12 \u0E2B\u0E25\u0E31\u0E01`,
    startWithMust: `\u0E15\u0E49\u0E2D\u0E07\u0E40\u0E23\u0E34\u0E48\u0E21\u0E15\u0E49\u0E19\u0E14\u0E49\u0E27\u0E22\u0E15\u0E31\u0E27\u0E40\u0E25\u0E02 9`,
    isNotCorrectPleaseCheckPhone: `\u0E2B\u0E21\u0E32\u0E22\u0E40\u0E25\u0E02\u0E42\u0E17\u0E23\u0E28\u0E31\u0E1E\u0E17\u0E4C\u0E44\u0E21\u0E48\u0E16\u0E39\u0E01\u0E15\u0E49\u0E2D\u0E07  \u0E42\u0E1B\u0E23\u0E14\u0E15\u0E23\u0E27\u0E08\u0E2A\u0E2D\u0E1A`,
    successfulRegistration: `\u0E25\u0E07\u0E17\u0E30\u0E40\u0E1A\u0E35\u0E22\u0E19\u0E2A\u0E33\u0E40\u0E23\u0E47\u0E08!`,
    successfulLogin: `\u0E40\u0E02\u0E49\u0E32\u0E2A\u0E39\u0E48\u0E23\u0E30\u0E1A\u0E1A\u0E2A\u0E33\u0E40\u0E23\u0E47\u0E08!`,
    addressAlreadyExistsEmail: `\u0E2D\u0E35\u0E40\u0E21\u0E25\u0E0B\u0E33\u0E49`,
    haveSendTooManyTimesPlease: `\u0E2A\u0E48\u0E07\u0E2B\u0E25\u0E32\u0E22\u0E04\u0E23\u0E31\u0E49\u0E07\u0E40\u0E01\u0E34\u0E19\u0E44\u0E1B \u0E42\u0E1B\u0E23\u0E14\u0E25\u0E2D\u0E07\u0E2D\u0E35\u0E01\u0E04\u0E23\u0E31\u0E49\u0E07\u0E43\u0E19 24 \u0E0A\u0E31\u0E48\u0E27\u0E42\u0E21\u0E07!`,
    codeErrorVerification: `\u0E23\u0E2B\u0E31\u0E2A\u0E22\u0E37\u0E19\u0E22\u0E31\u0E19\u0E1C\u0E34\u0E14`,
    codeHasExpiredVerification: `\u0E23\u0E2B\u0E31\u0E2A\u0E22\u0E37\u0E19\u0E22\u0E31\u0E19\u0E2B\u0E21\u0E14\u0E2D\u0E32\u0E22\u0E38`,
    passwordConsistsOfAtLeast: `\u0E23\u0E2B\u0E31\u0E2A\u0E1C\u0E48\u0E32\u0E19\u0E1B\u0E23\u0E30\u0E01\u0E2D\u0E1A\u0E14\u0E49\u0E27\u0E22\u0E15\u0E31\u0E27\u0E2D\u0E31\u0E01\u0E29\u0E23\u0E20\u0E32\u0E29\u0E32\u0E2D\u0E31\u0E07\u0E01\u0E24\u0E29 \u0E15\u0E31\u0E27\u0E40\u0E25\u0E02 \u0E41\u0E25\u0E30\u0E2D\u0E31\u0E01\u0E02\u0E23\u0E30\u0E1E\u0E34\u0E40\u0E28\u0E29\u0E2D\u0E22\u0E48\u0E32\u0E07\u0E19\u0E49\u0E2D\u0E22 8 \u0E2B\u0E25\u0E31\u0E01`,
    codeVerification: `\u0E23\u0E2B\u0E31\u0E2A\u0E22\u0E37\u0E19\u0E22\u0E31\u0E19`,
    password: `\u0E23\u0E2B\u0E31\u0E2A\u0E1C\u0E48\u0E32\u0E19`,
    anAccountloginHave: `\u0E21\u0E35\u0E1A\u0E31\u0E0D\u0E0A\u0E35\u0E2D\u0E22\u0E39\u0E48\u0E41\u0E25\u0E49\u0E27?`,
    newPasswordCannotBeTheSame: `\u0E23\u0E2B\u0E31\u0E2A\u0E1C\u0E48\u0E32\u0E19\u0E43\u0E2B\u0E21\u0E48\u0E15\u0E49\u0E2D\u0E07\u0E44\u0E21\u0E48\u0E40\u0E2B\u0E21\u0E37\u0E2D\u0E19\u0E01\u0E31\u0E1A\u0E23\u0E2B\u0E31\u0E2A\u0E1C\u0E48\u0E32\u0E19\u0E40\u0E14\u0E34\u0E21!`,
    enterPhoneNumberPlease: `\u0E01\u0E23\u0E38\u0E13\u0E32\u0E01\u0E23\u0E2D\u0E01\u0E2B\u0E21\u0E32\u0E22\u0E40\u0E25\u0E02\u0E42\u0E17\u0E23\u0E28\u0E31\u0E1E\u0E17\u0E4C`,
    phoneNumberHasBeenUsedPlease: `\u0E2B\u0E21\u0E32\u0E22\u0E40\u0E25\u0E02\u0E42\u0E17\u0E23\u0E28\u0E31\u0E1E\u0E17\u0E4C\u0E16\u0E39\u0E01\u0E43\u0E0A\u0E49\u0E41\u0E25\u0E49\u0E27 \u0E42\u0E1B\u0E23\u0E14\u0E01\u0E23\u0E2D\u0E01\u0E43\u0E2B\u0E21\u0E48`,
    toGineeWelcome: `\u0E22\u0E34\u0E19\u0E14\u0E35\u0E15\u0E49\u0E2D\u0E19\u0E23\u0E31\u0E1A\u0E2A\u0E39\u0E48 Ginee`,
    nHello: `\u0E2A\u0E27\u0E31\u0E2A\u0E14\u0E35 {n}`,
    orderForYouToHaveA: `\u0E40\u0E1E\u0E37\u0E48\u0E2D\u0E43\u0E2B\u0E49\u0E04\u0E38\u0E13\u0E43\u0E0A\u0E49\u0E07\u0E32\u0E19\u0E23\u0E30\u0E1A\u0E1A\u0E44\u0E14\u0E49\u0E14\u0E35\u0E02\u0E36\u0E49\u0E19 \u0E42\u0E1B\u0E23\u0E14\u0E01\u0E23\u0E2D\u0E01\u0E02\u0E49\u0E2D\u0E21\u0E39\u0E25\u0E15\u0E48\u0E2D\u0E44\u0E1B\u0E19\u0E35\u0E49 \u0E02\u0E2D\u0E1A\u0E04\u0E38\u0E13`,
    enterTheChannelStoreNamePlease: `\u0E01\u0E23\u0E38\u0E13\u0E32\u0E01\u0E23\u0E2D\u0E01\u0E0A\u0E37\u0E48\u0E2D\u0E23\u0E49\u0E32\u0E19\u0E04\u0E49\u0E32\u0E02\u0E2D\u0E07\u0E0A\u0E48\u0E2D\u0E07\u0E17\u0E32\u0E07\u0E01\u0E32\u0E23\u0E02\u0E32\u0E22`,
    CharactersMaximum: `\u0E2A\u0E39\u0E07\u0E2A\u0E38\u0E14 100 \u0E2B\u0E25\u0E31\u0E01`,
    byRegistering: `\u0E42\u0E14\u0E22\u0E01\u0E32\u0E23\u0E25\u0E07\u0E17\u0E30\u0E40\u0E1A\u0E35\u0E22\u0E19 \u0E09\u0E31\u0E19\u0E22\u0E2D\u0E21\u0E23\u0E31\u0E1A`,
    goUpgrade: "\u0E2D\u0E31\u0E1E\u0E40\u0E01\u0E23\u0E14",
    cancel: "\u0E22\u0E01\u0E40\u0E25\u0E34\u0E01",
    masterProductQuantityHasReachedThe: "Jumlah Master Produk telah mencapai batas paket",
    afterUpgradingThePackageMoreMaster: "Setelah pembaruan paket, lebih banyak Master Produk dapat ditambahkan"
  },
  vn: {
    congratulationsYouHaveReceivedADay: "Ch\xFAc m\u1EEBng! B\u1EA1n \u0111\xE3 nh\u1EADn \u0111\u01B0\u1EE3c b\u1EA3n d\xF9ng th\u1EED mi\u1EC5n ph\xED 7 ng\xE0y v\u1EDBi \u0111\u1EA7y \u0111\u1EE7 c\xE1c t\xEDnh n\u0103ng",
    tryNoww: "Th\u1EED ngay b\xE2y gi\u1EDD",
    orderMaxTip: `Trong th\u1EDDi gian g\xF3i c\xF3 hi\u1EC7u l\u1EF1c, s\u1ED1 l\u01B0\u1EE3ng t\u1EA1o \u0111\u01A1n h\xE0ng c\u1ED9ng d\u1ED3n v\u01B0\u1EE3t qu\xE1 gi\u1EDBi h\u1EA1n g\xF3i, vui l\xF2ng \u0111\u0103ng k\xFD g\xF3i ho\u1EB7c li\xEAn h\u1EC7 b\u1ED9 ph\u1EADn ch\u0103m s\xF3c kh\xE1ch h\xE0ng \u0111\u1EC3 n\xE2ng c\u1EA5p g\xF3i.`,
    selected: `\u0110\xE3 ch\u1ECDn`,
    selectedXStore: `\u0110\xE3 ch\u1ECDn {x} c\u1EEDa h\xE0ng`,
    pleaseSelectAStore: `Vui l\xF2ng ch\u1ECDn m\u1ED9t c\u1EEDa h\xE0ng`,
    pleaseSelectAStaffAccount: `Vui l\xF2ng ch\u1ECDn m\u1ED9t t\xE0i kho\u1EA3n nh\xE2n vi\xEAn`,
    contactus: `Li\xEAn h\u1EC7 ch\xFAng t\xF4i`,
    PleaseSelect: `Vui l\xF2ng ch\u1ECDn`,
    staffNotAuthority: `B\u1EA1n kh\xF4ng c\xF3 quy\u1EC1n v\u1EADn h\xE0nh ch\u1EE9c n\u0103ng n\xE0y, vui l\xF2ng li\xEAn h\u1EC7 v\u1EDBi ng\u01B0\u1EDDi b\xE1n ho\u1EB7c Qu\u1EA3n tr\u1ECB vi\xEAn \u0111\u1EC3 \u0111\u01B0\u1EE3c x\u1EED l\xFD.`,
    permissions: `Quy\u1EC1n:`,
    selectedPermissions: `Quy\u1EC1n \u0111\xE3 ch\u1ECDn`,
    theRoleCannotBeEmptyPlease: `Kh\xF4ng \u0111\u01B0\u1EE3c \u0111\u1EC3 tr\u1ED1ng vai tr\xF2, vui l\xF2ng ch\u1ECDn m\u1ED9t vai tr\xF2`,
    dearCustomer: `Kh\xE1ch h\xE0ng th\xE2n m\u1EBFn,`,
    yourFollowingResourcesHaveExceededThe: `C\xE1c t\xE0i nguy\xEAn sau c\u1EE7a b\u1EA1n \u0111\xE3 v\u01B0\u1EE3t qu\xE1 gi\u1EDBi h\u1EA1n g\xF3i, vui l\xF2ng \u0111i\u1EC1u ch\u1EC9nh ho\u1EB7c n\xE2ng c\u1EA5p g\xF3i tr\u01B0\u1EDBc khi ti\u1EBFp t\u1EE5c s\u1EED d\u1EE5ng Ginee OMS`,
    numberOfStores: `S\u1ED1 l\u01B0\u1EE3ng c\u1EEDa h\xE0ng`,
    RESTRICTED: `V\xF4 hi\u1EC7u h\xF3a`,
    theDefinitionOfDisabledIsStore: `V\xF4 hi\u1EC7u h\xF3a: Sau khi c\u1EEDa h\xE0ng b\u1ECB h\u1EE7y k\xEDch ho\u1EA1t, s\u1EBD kh\xF4ng \u1EA3nh h\u01B0\u1EDFng \u0111\u1EBFn vi\u1EC7c b\xE1n h\xE0ng tr\xEAn s\xE0n TM\u0110T; c\xE1c s\u1EA3n ph\u1EA9m tr\xEAn s\xE0n c\u1EE7a c\u1EEDa h\xE0ng s\u1EBD t\u1EF1 \u0111\u1ED9ng \u0111\u01B0\u1EE3c g\u1EE1 b\u1ECF kh\u1ECFi SKU ch\xEDnh; d\u1EEF li\u1EC7u s\u1EA3n ph\u1EA9m / \u0111\u01A1n h\xE0ng c\u1EE7a c\u1EEDa h\xE0ng s\u1EBD kh\xF4ng \u0111\u01B0\u1EE3c hi\u1EC3n th\u1ECB trong Ginee OMS`,
    selectXStoresToContinueBinding: `Ch\u1ECDn {x} c\u1EEDa h\xE0ng \u0111\u1EC3 ti\u1EBFp t\u1EE5c r\xE0ng bu\u1ED9c v\xE0 ph\u1EA7n c\xF2n l\u1EA1i s\u1EBD b\u1ECB V\xF4 hi\u1EC7u h\xF3a (sau khi v\xF4 hi\u1EC7u h\xF3a, n\xF3 s\u1EBD kh\xF4ng \u1EA3nh h\u01B0\u1EDFng \u0111\u1EBFn doanh s\u1ED1 b\xE1n h\xE0ng c\u1EE7a c\u1EEDa h\xE0ng, nh\u01B0ng Genie OMS s\u1EBD kh\xF4ng hi\u1EC3n th\u1ECB c\xE1c s\u1EA3n ph\u1EA9m / \u0111\u01A1n \u0111\u1EB7t h\xE0ng v\xE0 d\u1EEF li\u1EC7u kh\xE1c c\u1EE7a c\u1EEDa h\xE0ng)`,
    chooseStore: `Ch\u1ECDn c\u1EEDa h\xE0ng`,
    staffAccount: `T\xE0i kho\u1EA3n nh\xE2n vi\xEAn`,
    selectXStaffAccountsToContinue: `Ch\u1ECDn {x} t\xE0i kho\u1EA3n nh\xE2n vi\xEAn \u0111\u1EC3 ti\u1EBFp t\u1EE5c s\u1EED d\u1EE5ng, ph\u1EA7n c\xF2n l\u1EA1i s\u1EBD b\u1ECB x\xF3a (ch\u1EC9 x\xF3a t\xE0i kho\u1EA3n nh\xE2n vi\xEAn c\u1EE7a Ginee OMS, c\xE1c h\u1EC7 th\u1ED1ng Ginee kh\xE1c s\u1EBD kh\xF4ng b\u1ECB \u1EA3nh h\u01B0\u1EDFng)`,
    subscriptionPackage: `G\xF3i \u0111\u0103ng k\xFD`,
    displayTheStoresDataInformationIn: `Hi\u1EC3n th\u1ECB th\xF4ng tin d\u1EEF li\u1EC7u c\u1EE7a c\u1EEDa h\xE0ng trong Ginee OMS`,
    reactivate: `K\xEDch ho\u1EA1t l\u1EA1i`,
    theStoreAuthorizationIsSuccessfulBut: `\u1EE6y quy\u1EC1n c\u1EEDa h\xE0ng th\xE0nh c\xF4ng, nh\u01B0ng kh\xF4ng hi\u1EC3n th\u1ECB trong Ginee OMS.
V\xEC s\u1ED1 l\u01B0\u1EE3ng c\u1EEDa h\xE0ng v\u01B0\u1EE3t qu\xE1 gi\u1EDBi h\u1EA1n g\xF3i, c\u1EEDa h\xE0ng s\u1EBD b\u1ECB v\xF4 hi\u1EC7u (Sau khi c\u1EEDa h\xE0ng b\u1ECB h\u1EE7y k\xEDch ho\u1EA1t, s\u1EBD kh\xF4ng \u1EA3nh h\u01B0\u1EDFng \u0111\u1EBFn vi\u1EC7c b\xE1n h\xE0ng tr\xEAn s\xE0n TM\u0110T; c\xE1c s\u1EA3n ph\u1EA9m tr\xEAn s\xE0n c\u1EE7a c\u1EEDa h\xE0ng s\u1EBD t\u1EF1 \u0111\u1ED9ng \u0111\u01B0\u1EE3c g\u1EE1 b\u1ECF kh\u1ECFi SKU ch\xEDnh; d\u1EEF li\u1EC7u s\u1EA3n ph\u1EA9m / \u0111\u01A1n h\xE0ng c\u1EE7a c\u1EEDa h\xE0ng s\u1EBD kh\xF4ng \u0111\u01B0\u1EE3c hi\u1EC3n th\u1ECB trong Ginee OMS)`,
    becauseTheNumberOfStoresExceeds: `B\u1EA1n kh\xF4ng c\xF3 quy\u1EC1n thao t\xE1c, quy\u1EC1n truy c\u1EADp ch\u1EE9c n\u0103ng n\xE0y v\u01B0\u1EE3t qu\xE1 gi\u1EDBi h\u1EA1n g\xF3i, vui l\xF2ng \u0111\u0103ng k\xFD g\xF3i m\u1EDBi ho\u1EB7c li\xEAn h\u1EC7 v\u1EDBi b\u1ED9 ph\u1EADn CSKH \u0111\u1EC3 n\xE2ng c\u1EA5p g\xF3i`,
    youDontHaveOperationAuthorityFeature: `Trong th\u1EDDi gian g\xF3i c\xF2n hi\u1EC7u l\u1EF1c, l\u01B0\u1EE3ng \u0111\u01A1n h\xE0ng t\xEDch l\u0169y \u0111\u01B0\u1EE3c t\u1EA1o ra \u0111\xE3 \u0111\u1EA1t {x} \u0111\u01A1n h\xE0ng. Sau khi v\u01B0\u1EE3t qu\xE1 {y} \u0111\u01A1n h\xE0ng, ch\u1EE9c n\u0103ng \u0110\u01A1n h\xE0ng s\u1EBD kh\xF4ng kh\u1EA3 d\u1EE5ng. Vui l\xF2ng \u0111\u0103ng k\xFD g\xF3i ho\u1EB7c li\xEAn h\u1EC7 v\u1EDBi b\u1ED9 ph\u1EADn CSKH \u0111\u1EC3 n\xE2ng c\u1EA5p g\xF3i.`,
    duringEffectivePeriodOfThePackage: `S\u1ED1 l\u01B0\u1EE3ng c\u1EEDa h\xE0ng \u1EE7y quy\u1EC1n ho\u1EB7c t\xE0i kho\u1EA3n nh\xE2n vi\xEAn \u0111\xE3 v\u01B0\u1EE3t qu\xE1 gi\u1EDBi h\u1EA1n g\xF3i, vui l\xF2ng li\xEAn h\u1EC7 nh\xE0 b\xE1n h\xE0ng ho\u1EB7c admin \u0111\u0103ng nh\u1EADp t\xE0i kho\u1EA3n ch\xEDnh \u0111\u1EC3 x\u1EED l\xFD.`,
    emailOtpCode: `M\xE3 x\xE1c minh email`,
    otpCodeHasBeenSentTo: `\u0110\xE3 g\u1EEDi m\xE3 x\xE1c minh \u0111\u1EBFn email`,
    failedReason: `Nguy\xEAn nh\xE2n th\u1EA5t b\u1EA1i`,
    inOrderToEnsureTheIntegrity: `\u0110\u1EC3 \u0111\u1EA3m b\u1EA3i t\xEDnh ho\xE0n thi\u1EC7n c\u1EE7a s\u1ED1 li\u1EC7u, \u0111\u1EC1 ngh\u1ECB s\u1EED d\u1EE5ng "T\u1EA1m th\u1EDDi kh\xF4ng theo d\xF5i"`,
    inSomeWarehouseTheWarehouseStock: `Trong m\u1ED9t s\u1ED1 kho h\xE0ng, T\u1ED5ng t\u1ED3n kho c\u1EE7a Master SKU kh\xF4ng ph\u1EA3i l\xE0 0, vui l\xF2ng c\u1EADp nh\u1EADt th\xE0nh 0 r\u1ED3i x\xF3a.`,
    thereIsAnUnprocessedOutboundIn: `Master SKU t\u1ED3n t\u1EA1i Phi\u1EBFu xu\u1EA5t h\xE0ng ch\u01B0a x\u1EED l\xFD`,
    theMasterSkuHasBeenMatched: `Master SKU \u0111\xE3 \u0111\u01B0\u1EE3c kh\u1EDBp v\u1EDBi c\xE1c s\u1EA3n ph\u1EA9m c\u1EE7a kho c\u1EE7a b\xEAn th\u1EE9 ba.`,
    weWillSendAnOtpCode: `Ch\xFAng t\xF4i s\u1EBD g\u1EEDi m\u1ED9t m\xE3 OTP \u0111\u1EBFn s\u1ED1 \u0111i\u1EC7n tho\u1EA1i c\u1EE7a b\u1EA1n`,
    cantReceiveTheVerificationCodeTry: `Kh\xF4ng nh\u1EADn \u0111\u01B0\u1EE3c m\xE3 x\xE1c minh? M\xE3 x\xE1c minh b\u1EB1ng gi\u1ECDng n\xF3i`,
    weWillDialYourPhoneNumber: `Ch\xFAng t\xF4i g\u1ECDi \u0111\u1EBFn s\u1ED1 \u0111i\u1EC7n tho\u1EA1i c\u1EE7a b\u1EA1n v\xE0 th\xF4ng b\xE1o m\xE3 x\xE1c minh th\xF4ng qua gi\u1ECDng n\xF3i`,
    basedOnAccountSecurityPleaseLog: `\u0110\u1EC3 b\u1EA3o m\u1EADt t\xE0i kho\u1EA3n, vui l\xF2ng s\u1EED d\u1EE5ng s\u1ED1 \u0111i\u1EC7n tho\u1EA1i di \u0111\u1ED9ng c\u1EE7a b\u1EA1n \u0111\u1EC3 \u0111\u0103ng nh\u1EADp l\u1EA7n n\xE0y.
Sau khi \u0111\u0103ng nh\u1EADp th\xE0nh c\xF4ng v\xE0 ho\xE0n t\u1EA5t x\xE1c nh\u1EADn trong [C\xE0i \u0111\u1EB7t-Th\xF4ng tin t\xE0i kho\u1EA3n -> X\xE1c th\u1EF1c email], b\u1EA1n c\xF3 th\u1EC3 \u0111\u0103ng nh\u1EADp b\u1EB1ng \u0111\u1ECBa ch\u1EC9 email.`,
    hint: `Nh\u1EAFc nh\u1EDF`,
    loginByPhoneNumber: `\u0110\u0103ng nh\u1EADp b\u1EB1ng s\u1ED1 \u0111i\u1EC7n tho\u1EA1i`,
    loginByEmail: `\u0110\u0103ng nh\u1EADp b\u1EB1ng email`,
    basedOnAccountSecurityPleaseUse: `\u0110\u1EC3 b\u1EA3o m\u1EADt t\xE0i kho\u1EA3n, vui l\xF2ng s\u1EED d\u1EE5ng email c\u1EE7a b\u1EA1n \u0111\u1EC3 \u0111\u0103ng nh\u1EADp l\u1EA7n n\xE0y.
Sau khi \u0111\u0103ng nh\u1EADp th\xE0nh c\xF4ng v\xE0 ho\xE0n t\u1EA5t x\xE1c nh\u1EADn trong [C\xE0i \u0111\u1EB7t-Th\xF4ng tin t\xE0i kho\u1EA3n -> X\xE1c th\u1EF1c s\u1ED1 \u0111i\u1EC7n tho\u1EA1i], b\u1EA1n c\xF3 th\u1EC3 \u0111\u0103ng nh\u1EADp b\u1EB1ng s\u1ED1 \u0111i\u1EC7n tho\u1EA1il.`,
    resetPasswordWithPhoneNumber: `S\u1EED d\u1EE5ng s\u1ED1 \u0111i\u1EC7n tho\u1EA1i \u0111\u1EC3 c\xE0i \u0111\u1EB7t l\u1EA1i m\u1EADt kh\u1EA9u`,
    resetPasswordWithEmail: `S\u1EED d\u1EE5ng email \u0111\u1EC3 c\xE0i \u0111\u1EB7t l\u1EA1i m\u1EADt kh\u1EA9u`,
    basedOnAccountSecurityPleaseUse1: `\u0110\u1EC3 b\u1EA3o m\u1EADt t\xE0i kho\u1EA3n, vui l\xF2ng s\u1EED d\u1EE5ng email c\u1EE7a b\u1EA1n \u0111\u1EC3 \u0111\u0103ng nh\u1EADp l\u1EA7n n\xE0y.
Sau khi \u0111\u0103ng nh\u1EADp th\xE0nh c\xF4ng v\xE0 ho\xE0n t\u1EA5t x\xE1c nh\u1EADn trong [C\xE0i \u0111\u1EB7t-Th\xF4ng tin t\xE0i kho\u1EA3n -> X\xE1c th\u1EF1c s\u1ED1 \u0111i\u1EC7n tho\u1EA1i], b\u1EA1n c\xF3 th\u1EC3 \u0111\u0103ng nh\u1EADp b\u1EB1ng s\u1ED1 \u0111i\u1EC7n tho\u1EA1i.`,
    resetPasswordWithEmail1: `C\xE0i \u0111\u1EB7t l\u1EA1i m\u1EADt kh\u1EA9u th\xF4ng qua email`,
    basedOnAccountSecurityPleaseUse2: `\u0110\u1EC3 b\u1EA3o m\u1EADt t\xE0i kho\u1EA3n, vui l\xF2ng s\u1EED d\u1EE5ng s\u1ED1 \u0111i\u1EC7n tho\u1EA1i c\u1EE7a b\u1EA1n \u0111\u1EC3 \u0111\u1EB7t l\u1EA1i m\u1EADt kh\u1EA9u.
Sau khi \u0111\u0103ng nh\u1EADp th\xE0nh c\xF4ng v\xE0 ho\xE0n t\u1EA5t x\xE1c nh\u1EADn trong [C\xE0i \u0111\u1EB7t-Th\xF4ng tin t\xE0i kho\u1EA3n -> X\xE1c th\u1EF1c email], b\u1EA1n c\xF3 th\u1EC3 s\u1EED d\u1EE5ng email \u0111\u1EC3 \u0111\u1EB7t l\u1EA1i m\u1EADt kh\u1EA9u.`,
    basedOnAccountSecurityPleaseUse3: `D\u1EF1a tr\xEAn b\u1EA3o m\u1EADt t\xE0i kho\u1EA3n, vui l\xF2ng s\u1EED d\u1EE5ng email c\u1EE7a b\u1EA1n \u0111\u1EC3 \u0111\u1EB7t l\u1EA1i m\u1EADt kh\u1EA9u. \u0110\u0103ng nh\u1EADp th\xE0nh c\xF4ng v\xE0 ho\xE0n t\u1EA5t x\xE1c th\u1EF1c trong [C\xE0i \u0111\u1EB7t - Th\xF4ng tin t\xE0i kho\u1EA3n -> X\xE1c th\u1EF1c s\u1ED1 \u0111i\u1EC7n tho\u1EA1i], b\u1EA1n c\xF3 th\u1EC3 s\u1EED d\u1EE5ng s\u1ED1 \u0111i\u1EC7n tho\u1EA1i c\u1EE7a m\xECnh \u0111\u1EC3 \u0111\u1EB7t l\u1EA1i m\u1EADt kh\u1EA9u`,
    notAuthenticate: `Ch\u01B0a x\xE1c th\u1EF1c`,
    afterAuthenticationYouCanLogIn: `Sau khi x\xE1c th\u1EF1c, c\xF3 th\u1EC3 d\xF9ng s\u1ED1 \u0111i\u1EC7n tho\u1EA1i \u0111\u1EC3 \u0111\u0103ng nh\u1EADp v\xE0 \u0111\u1EB7t l\u1EA1i m\u1EADt kh\u1EA9u`,
    goToAuthentication: `X\xE1c th\u1EF1c`,
    afterAuthenticationYouCanLogIn1: `Sau khi x\xE1c th\u1EF1c, c\xF3 th\u1EC3 s\u1EED d\u1EE5ng email \u0111\u0103ng nh\u1EADp v\xE0 \u0111\u1EB7t l\u1EA1i m\u1EADt kh\u1EA9u`,
    pleaseEnterYourAccountPassword: `Vui l\xF2ng nh\u1EADp m\u1EADt kh\u1EA9u t\xE0i kho\u1EA3n`,
    toProtectYourAccountSecurityPlease: `\u0110\u1EC3 b\u1EA3o m\u1EADt t\xE0i kho\u1EA3n, vui l\xF2ng nh\u1EADp m\u1EADt kh\u1EA9u \u0111\u0103ng nh\u1EADp c\u1EE7a b\u1EA1n \u0111\u1EC3 ti\u1EBFn h\xE0nh x\xE1c th\u1EF1c`,
    passwordMustBeAtLeast: `M\u1EADt kh\u1EA9u kh\xF4ng \u0111\u01B0\u1EE3c \xEDt h\u01A1n 8 k\xED t\u1EF1`,
    afterVerificationYouCanUseThe: `Sau khi x\xE1c th\u1EF1c \u0111\u01B0\u1EE3c th\xF4ng qua, c\xF3 th\u1EC3 s\u1EED d\u1EE5ng s\u1ED1 \u0111i\u1EC7n tho\u1EA1i \u0111\u1EC3 \u0111\u0103ng nh\u1EADp v\xE0 s\u1EEDa m\u1EADt kh\u1EA9u`,
    afterVerificationYouCanUseEmail: `Sau khi x\xE1c th\u1EF1c \u0111\u01B0\u1EE3c th\xF4ng qua, c\xF3 th\u1EC3 s\u1EED d\u1EE5ng email \u0111\u1EC3 \u0111\u0103ng nh\u1EADp v\xE0 s\u1EEDa m\u1EADt kh\u1EA9u`,
    authenticationSuccessful: `X\xE1c th\u1EF1c th\xE0nh c\xF4ng!`,
    merchantId: `ID Kh\xE1ch h\xE0ng`,
    verificationMethod: `Ph\u01B0\u01A1ng th\u1EE9c x\xE1c minh`,
    scene: `Ho\xE0n c\u1EA3nh`,
    verificationCode: `M\xE3 x\xE1c minh`,
    sendTime: `Th\u1EDDi gian g\u1EEDi`,
    pleaseEnterPhoneNumber: `Vui l\xF2ng nh\u1EADp s\u1ED1 \u0111i\u1EC7n tho\u1EA1i`,
    sendTooManyTimesPleaseTry: `S\u1ED1 l\u1EA7n g\u1EEDi \u0111i qu\xE1 nhi\u1EC1u, vui l\xF2ng th\u1EED l\u1EA1i sau 24 ti\u1EBFng!`,
    accountDoesNotExistPleaseCheck: `T\xE0i kho\u1EA3n kh\xF4ng t\u1ED3n t\u1EA1i, vui l\xF2ng ki\u1EC3m tra!`,
    thisIsAStaffAccountPlease: `\u0110\xE2y l\xE0 t\xE0i kho\u1EA3n nh\xE2n vi\xEAn, vui l\xF2ng li\xEAn h\u1EC7 Nh\xE0 b\xE1n \u0111\u1EC3 \u0111\u1ED5i m\u1EADt kh\u1EA9u (t\u1EA1i T\xE0i kho\u1EA3n nh\xE2n vi\xEAn - Ch\u1EC9nh s\u1EEDa nh\xE2n vi\xEAn) ho\u1EB7c \u0111\u0103ng nh\u1EADp r\u1ED3i ch\u1EC9nh s\u1EEDa m\u1EADt kh\u1EA9u (C\xE0i \u0111\u1EB7t - Thay \u0111\u1ED5i m\u1EADt kh\u1EA9u)`,
    send: `G\u1EEDi`,
    company: `Copyright 2021 \xA9 CONG TY TNHH ADVANCE INTELLIGENCE VIETNAM`,
    title: `Ginee Seller Center - \u0110\u0103ng nh\u1EADp v\xE0 \u0111\u0103ng k\xFD`,
    content: `Kinh doanh th\xF4ng minh h\u01A1n!`,
    keyWords: `\u0110\u0103ng nh\u1EADp Ginee ,\u0110\u0103ng k\xFD Ginee,\u0110\u1EB7t l\u1EA1i m\u1EADt kh\u1EA9u Ginee`,
    confirm: `X\xE1c nh\u1EADn`,
    ofUseTerms: `\u0110i\u1EC1u kho\u1EA3n ng\u01B0\u1EDDi d\xF9ng`,
    and: `v\xE0`,
    policyPrivacy: `Ch\xEDnh s\xE1ch b\u1EA3o m\u1EADt`,
    businessSmarterMaking: `Kinh doanh th\xF4ng minh h\u01A1n`,
    successfulReset: `\u0110\u1EB7t l\u1EA1i th\xE0nh c\xF4ng!`,
    passwordReset: `\u0110\u1EB7t l\u1EA1i m\u1EADt kh\u1EA9u`,
    login: `\u0110\u0103ng nh\u1EADp`,
    email: `Email`,
    inputYourEmailPlease: `Vui l\xF2ng nh\u1EADp email`,
    passwordForget: `Qu\xEAn m\u1EADt kh\u1EA9u?`,
    signUp: `\u0110\u0103ng k\xFD`,
    accountsignUpNew: `T\xE0i kho\u1EA3n m\u1EDBi?`,
    emailFormatIsNotCorrectPlease: `Email nh\u1EADp kh\xF4ng \u0111\xFAng, vui l\xF2ng nh\u1EADp l\u1EA1i`,
    enterPasswordPlease: `Vui l\xF2ng nh\u1EADp l\u1EA1i m\u1EADt kh\u1EA9u`,
    accountOrPasswordIsNotCorrect: `T\xE0i kho\u1EA3n ho\u1EB7c m\u1EADt kh\u1EA9u kh\xF4ng \u0111\xFAng, vui l\xF2ng ki\u1EC3m tra.`,
    phoneNumberMustBeDigits: `\u0110i\u1EC7n tho\u1EA1i b\u1EAFt bu\u1ED9c c\xF3 11 s\u1ED1.`,
    startWithAndDigits: `B\u1EAFt bu\u1ED9c b\u1EAFt \u0111\u1EA7u b\u1EB1ng s\u1ED1 8 v\xE0 ch\u1EE9a 9-12 s\u1ED1.`,
    startWithMust: `B\u1EAFt bu\u1ED9c b\u1EAFt \u0111\u1EA7u b\u1EB1ng s\u1ED1 9`,
    isNotCorrectPleaseCheckPhone: `S\u1ED1 \u0111i\u1EC7n tho\u1EA1i kh\xF4ng \u0111\xFAng, vui l\xF2ng ki\u1EC3m tra.`,
    successfulRegistration: `\u0110\u0103ng k\xFD th\xE0nh c\xF4ng!`,
    successfulLogin: `\u0110\u0103ng nh\u1EADp th\xE0nh c\xF4ng!`,
    addressAlreadyExistsEmail: `Email \u0111\xE3 t\u1ED3n t\u1EA1i.`,
    haveSendTooManyTimesPlease: `B\u1EA1n \u0111\xE3 g\u1EEDi qu\xE1 s\u1ED1 l\u1EA7n quy \u0111\u1ECBnh, vui l\xF2ng th\u1EED l\u1EA1i sau 24 gi\u1EDD!`,
    codeErrorVerification: `M\xE3 x\xE1c nh\u1EADn sai`,
    codeHasExpiredVerification: `M\xE3 x\xE1c nh\u1EADn \u0111\xE3 qu\xE1 h\u1EA1n`,
    passwordConsistsOfAtLeast: `M\u1EADt kh\u1EA9u ph\u1EA3i c\xF3 \xEDt nh\u1EA5t 8 k\xFD t\u1EF1, bao g\u1ED3m ch\u1EEF c\xE1i, s\u1ED1 v\xE0 c\xE1c k\xFD hi\u1EC7u \u0111\u1EB7c bi\u1EC7t.`,
    codeVerification: `M\xE3 x\xE1c minh`,
    password: `M\u1EADt kh\u1EA9u`,
    anAccountloginHave: `\u0110\xE3 c\xF3 t\xE0i kho\u1EA3n?`,
    newPasswordCannotBeTheSame: `M\u1EADt kh\u1EA9u m\u1EDBi kh\xF4ng \u0111\u01B0\u1EE3c tr\xF9ng m\u1EADt kh\u1EA9u c\u0169!`,
    enterPhoneNumberPlease: `Vui l\xF2ng nh\u1EADp s\u1ED1 \u0111i\u1EC7n tho\u1EA1i`,
    phoneNumberHasBeenUsedPlease: `S\u1ED1 \u0111i\u1EC7n tho\u1EA1i \u0111\xE3 s\u1EED d\u1EE5ng, vui l\xF2ng nh\u1EADp s\u1ED1 kh\xE1c.`,
    toGineeWelcome: `Ch\xE0o m\u1EEBng Nh\xE0 b\xE1n \u0111\u1EBFn v\u1EDBi Ginee`,
    nHello: `Ch\xE0o b\u1EA1n, {n}`,
    orderForYouToHaveA: `\u0110\u1EC3 c\xF3 tr\u1EA3i nghi\u1EC7m t\u1ED1t h\u01A1n, b\u1EA1n vui l\xF2ng ho\xE0n t\u1EA5t c\xE1c th\xF4ng tin d\u01B0\u1EDBi \u0111\xE2y, xin c\u1EA3m \u01A1n!`,
    enterTheChannelStoreNamePlease: `Vui l\xF2ng nh\u1EADp t\xEAn c\u1EEDa h\xE0ng k\xEAnh b\xE1n`,
    CharactersMaximum: `\u0110\u1ED9 d\xE0i t\u1ED1i \u0111a 100 k\xFD t\u1EF1`,
    byRegistering: `Khi \u0111\u0103ng k\xFD, t\xF4i \u0111\u1ED3ng \xFD`,
    goUpgrade: "N\xE2ng c\u1EA5p",
    cancel: "H\u1EE7y",
    masterProductQuantityHasReachedThe: "S\u1ED1 l\u01B0\u1EE3ng S\u1EA3n ph\u1EA9m Ch\xEDnh \u0111\xE3 \u0111\u1EA1t \u0111\u1EBFn gi\u1EDBi h\u1EA1n g\xF3i",
    afterUpgradingThePackageMoreMaster: "Sau khi n\xE2ng c\u1EA5p g\xF3i, c\xF3 th\u1EC3 th\xEAm nhi\u1EC1u S\u1EA3n ph\u1EA9m ch\xEDnh"
  }
};
