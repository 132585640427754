import {Menu} from "../../../../__snowpack__/pkg/antd.js";
import QueryString from "../../../../__snowpack__/pkg/qs.js";
import React, {memo, useEffect, useMemo, useState, useRef} from "../../../../__snowpack__/pkg/react.js";
import {useLocation} from "../../../../__snowpack__/pkg/react-router.js";
import {useIntl} from "../../../../__snowpack__/pkg/react-intl.js";
import queryString from "../../../../__snowpack__/pkg/query-string.js";
import {eventLogger} from "../../../lib/eventLogger.js";
import {useUpdateRoute} from "../../../lib/useIframe.js";
import {getRouteAuth, getRouteAuthMap, getRouteMeta} from "../../../router/main.js";
import {useLayoutState} from "../../../store/layout.js";
import {useShopsModel} from "../../../entities/useShops.js";
import {
  IconLogisticsIcon,
  IconMenuChannelProducts,
  IconMenuChannelCnsc,
  IconMenuCustomer,
  IconMenuDashboard,
  IconMenuIntegration,
  IconMenuInventory,
  IconMenuMasterProducts,
  IconMenuOrders,
  IconMenuPromotion,
  IconMenuReports,
  IconMenuSetting,
  IconMenuWarehouse,
  IconMenuAccounting,
  IconMenuPurchase
} from "../assets/IconMap.js";
import {getSortChannelList} from "../../../lib/util.js";
import {request} from "../../../utils/request/index.js";
import {subscribe, connect as socketConnect} from "../../../lib/menuNumberSocket.js";
import {v4 as uuidv4} from "../../../../__snowpack__/pkg/uuid.js";
const {SubMenu} = Menu;
const rootSubMenuKeys = [
  "menu-sub-orders",
  "menu-sub-master-products",
  "menu-sub-channel-products",
  "menu-sub-purchase",
  "menu-sub-inventory",
  "menu-sub-warehouse",
  "menu-sub-promotion",
  "menu-sub-customer",
  "menu-sub-reports",
  "menu-sub-accounting",
  "menu-sub-integration",
  "menu-sub-logistics-management"
];
const numberStyle = {
  color: "#FFFFFF",
  background: "#FF4D4F",
  borderRadius: "7px",
  fontSize: "12px",
  marginLeft: "4px",
  display: "inline-block",
  height: "14px",
  padding: "0 3px",
  textAlign: "center",
  lineHeight: "14px"
};
const numberSonStyle = {
  color: "#FFFFFF",
  background: "#6020FF",
  borderRadius: "7px",
  fontSize: "12px",
  marginLeft: "4px",
  display: "inline-block",
  height: "14px",
  padding: "0 3px",
  textAlign: "center",
  lineHeight: "14px"
};
const grayNumberStyle = {
  color: "gray",
  background: "#E3E8F0",
  borderRadius: "7px",
  fontSize: "12px",
  marginLeft: "4px",
  display: "inline-block",
  height: "14px",
  padding: "0 3px",
  textAlign: "center",
  lineHeight: "14px"
};
let windowId = "";
export const SiderMenu = memo(() => {
  const {formatMessage} = useIntl();
  const location = useLocation();
  const {fetchShops: shopsFetchData, globalMap} = useShopsModel();
  const mainChannel = useRef(null);
  const defaultOpenKeys = useMemo(() => {
    return getRouteMeta(location.pathname)?.openKeys;
  }, [location.pathname]);
  const queries = queryString.parse(location.search.slice(1));
  const init = async () => {
    if (queries.isRegister) {
      if (!["/add-integration"].includes(location.pathname)) {
        updateRoute("/");
      }
      return;
    }
  };
  const defaultSelectKeys = useMemo(() => {
    const selectedKeysFromMeta = getRouteMeta(location.pathname)?.selectedKeys;
    if (selectedKeysFromMeta && selectedKeysFromMeta.length) {
      return selectedKeysFromMeta;
    } else {
      return [location.pathname.replace("/", "")];
    }
  }, [location.pathname]);
  const {
    withPermission,
    withPermissionAll,
    PermissionClickAlert,
    fetchMenuNumber,
    menuNumber,
    PermissionCheckRouter
  } = useLayoutState();
  const [openKeys, setOpenKeys] = useState(defaultOpenKeys || []);
  const [selectedKeys, setSelectedKeys] = useState(defaultSelectKeys);
  const [channelList, setChannelList] = useState([]);
  const {updateRoute} = useUpdateRoute();
  const menuChannelList = getSortChannelList([
    {
      channelId: "WOO_COMMERCE_ID",
      text: "WooCommerce",
      url: "product-woo-commerce"
    },
    {
      channelId: "SHOPEE_ID",
      text: "Shopee",
      url: "product-shopee"
    },
    {
      channelId: "LAZADA_ID",
      text: "Lazada",
      url: "product-lazada"
    },
    {
      channelId: "LAZADA_CHOICE",
      text: "Lazada Choice",
      url: "product-lazada-choice"
    },
    {
      channelId: "BUKALAPAK_ID",
      text: "Bukalapak",
      url: "product-bukalapak"
    },
    {
      channelId: "TOKOPEDIA_ID",
      text: "Tokopedia",
      url: "product-tokopedia"
    },
    {
      channelId: "SHOPIFY_ID",
      text: "Shopify",
      url: "product-shopify"
    },
    {
      channelId: "BLIBLI_ID",
      text: "Blibli",
      url: "product-blibli"
    },
    {
      channelId: "TIKTOK_ID",
      text: "TikTok",
      url: "product-tiktok"
    },
    {
      channelId: "MAGENTO_ID",
      text: "Magento",
      url: "product-magento"
    },
    {
      channelId: "TIKI_ID",
      text: "Tiki",
      url: "product-tiki"
    },
    {
      channelId: "ZALORA_ID",
      text: "Zalora",
      url: "product-zalora"
    },
    {
      channelId: "LINE_SHOPPING_TH",
      text: "Line Shopping",
      url: "product-line-shopping"
    },
    {
      channelId: "AKULAKU_ID",
      text: "Akulaku",
      url: "product-akulaku"
    },
    {
      channelId: "MOKA_POS_ID",
      text: "Moka POS",
      url: "product-moka"
    },
    {
      channelId: "GINEE_POS",
      text: "Ginee POS",
      url: "product-ginee-pos"
    }
  ]);
  const onOpenChange = (keys) => {
    let tempKeys = [];
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (rootSubMenuKeys.indexOf(latestOpenKey || "") === -1) {
      tempKeys = keys;
    } else {
      tempKeys = latestOpenKey ? [latestOpenKey] : [];
    }
    setOpenKeys(tempKeys);
  };
  useEffect(() => {
    if (defaultOpenKeys) {
      onOpenChange(defaultOpenKeys);
    }
  }, [defaultOpenKeys]);
  useEffect(() => {
    setSelectedKeys(defaultSelectKeys);
  }, [defaultSelectKeys]);
  const getMenuAuth = async () => {
    const data = await request.post("/infra/integration/channel/list", {
      application: "SYS_ERP",
      authorizationStatus: "CONNECTED"
    });
    setChannelList(data);
  };
  const renderMenuNumber = (key, number = 0) => {
    let element = /* @__PURE__ */ React.createElement(React.Fragment, null);
    if (!withPermission("order-status-count"))
      return element;
    if (number || number === 0) {
      element = selectedKeys.includes(key) ? /* @__PURE__ */ React.createElement("span", {
        style: numberSonStyle
      }, number) : /* @__PURE__ */ React.createElement("span", {
        style: grayNumberStyle
      }, number);
    }
    return element;
  };
  const connectSocket = () => {
    if (!withPermission("order-status-count"))
      return;
    socketConnect().then((res) => {
      subscribe({
        callback: (message) => {
          if (message?.payload?.needRefresh) {
            fetchMenuNumber();
          }
        }
      });
    });
  };
  const onMessage = (event) => {
    const websocketIds = JSON.parse(localStorage.getItem("websocketIds") || "[]");
    if (websocketIds.length > 0) {
      const contentId = websocketIds[0];
      if (contentId === windowId) {
        connectSocket();
      }
    }
  };
  useEffect(() => {
    try {
      if (typeof BroadcastChannel !== "undefined") {
        const channel = new BroadcastChannel("Ginee ERP");
        channel.onmessage = onMessage;
        mainChannel.current = channel;
        const id = uuidv4();
        windowId = id;
        const socketIds = localStorage.getItem("websocketIds") && localStorage.getItem("websocketIds") !== "undefined" ? localStorage.getItem("websocketIds") : "[]";
        const ids = JSON.parse(socketIds || "[]");
        ids.push(id);
        localStorage.setItem("websocketIds", JSON.stringify(ids));
        if (ids && ids?.length === 1) {
          connectSocket();
        }
        window.addEventListener("unload", function() {
          const ids2 = JSON.parse(localStorage.getItem("websocketIds") || "[]");
          const newIds = ids2?.filter((id2) => id2 !== windowId);
          localStorage.setItem("websocketIds", JSON.stringify(newIds));
          mainChannel.current.postMessage("close");
        });
      } else {
        console.log("\u5F53\u524D\u6D4F\u89C8\u5668\u4E0D\u652F\u6301 BroadcastChannel");
      }
    } catch (error) {
      console.error(error);
    }
  }, []);
  useEffect(() => {
    getMenuAuth();
    init();
    fetchMenuNumber();
    shopsFetchData({url: "/infra/integration/my/search?timePost=1"});
  }, []);
  useEffect(() => {
    if (location.pathname) {
      let auth = getRouteAuth(location.pathname);
      if (auth && !PermissionCheckRouter(auth)) {
        updateRoute("sorry");
        return;
      }
    }
  }, [location.pathname]);
  const isNeedUpdateNumberKeys = [
    "menu-sub-orders",
    "order",
    "menu-sub-orders-handling",
    "arrange-delivery",
    "push-warehouse",
    "problem-order",
    "waiting-packed",
    "waiting-collected"
  ];
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(Menu, {
    theme: "light",
    mode: "inline",
    openKeys,
    selectedKeys,
    onOpenChange,
    style: {backgroundColor: "white"},
    onClick: (event) => {
      if (event.key) {
        let keyInfo = event.key?.split("?");
        let key = keyInfo[0];
        let queryMap = {};
        if (keyInfo && keyInfo[1]) {
          queryMap = QueryString.parse(keyInfo[1]);
        }
        let auth = getRouteAuth(`/${key}`);
        let authMap = getRouteAuthMap(`/${key}`);
        if (auth && !PermissionClickAlert(auth))
          return;
        if (authMap && authMap.authName) {
          if (queryMap && queryMap[authMap.authName]) {
            if (!PermissionClickAlert(authMap[queryMap[authMap.authName]]))
              return;
          }
        }
        if (isNeedUpdateNumberKeys.includes(event.key)) {
          fetchMenuNumber();
        }
        setSelectedKeys([event.key]);
        if (event.key === "dashboard") {
          updateRoute("");
        } else {
          updateRoute(event.key);
        }
        eventLogger.push({
          action: "ClickMenu",
          location: "Menu",
          actionExtra: {
            id: event.key
          }
        });
      }
    }
  }, withPermission("dashboard") && /* @__PURE__ */ React.createElement(Menu.Item, {
    className: "menu-item-l1",
    key: "dashboard",
    icon: /* @__PURE__ */ React.createElement(IconMenuDashboard, {
      className: "menu-icon"
    })
  }, /* @__PURE__ */ React.createElement("a", {
    onClick: (event) => event.preventDefault(),
    href: "/"
  }, formatMessage({id: "menuHomePage"}))), withPermission("order-management") && /* @__PURE__ */ React.createElement(SubMenu, {
    className: "menu-sub-l1",
    key: "menu-sub-orders",
    icon: /* @__PURE__ */ React.createElement(IconMenuOrders, {
      className: "menu-icon"
    }),
    title: formatMessage({id: "MenuOrders"})
  }, withPermission("list-order") && /* @__PURE__ */ React.createElement(Menu.Item, {
    className: "menu-item-l2",
    key: "order"
  }, /* @__PURE__ */ React.createElement("a", {
    onClick: (event) => event.preventDefault(),
    href: "/order"
  }, formatMessage({id: "nearlyDaysOrder"}))), withPermission("new-pending-order-management") && /* @__PURE__ */ React.createElement(SubMenu, {
    className: "menu-sub-l2",
    key: "menu-sub-orders-handling",
    title: /* @__PURE__ */ React.createElement(React.Fragment, null, formatMessage({id: "pending"}), menuNumber?.PENDING_LIST && /* @__PURE__ */ React.createElement("span", {
      style: numberStyle
    }, menuNumber?.PENDING_LIST))
  }, withPermission("ship-order") && /* @__PURE__ */ React.createElement(Menu.Item, {
    className: "menu-item-l3",
    key: "arrange-delivery"
  }, /* @__PURE__ */ React.createElement("a", {
    onClick: (event) => event.preventDefault()
  }, formatMessage({id: "ArrangeShipment2"}), renderMenuNumber("arrange-delivery", menuNumber?.ORDER_SHIP))), withPermission("push-warehouse") && /* @__PURE__ */ React.createElement(Menu.Item, {
    className: "menu-item-l3",
    key: "push-warehouse"
  }, /* @__PURE__ */ React.createElement("a", {
    onClick: (event) => event.preventDefault()
  }, formatMessage({id: "pushWarehouse"}), renderMenuNumber("push-warehouse", menuNumber?.ORDER_PUSH_FULFILLMENT))), withPermission("problem-order") && /* @__PURE__ */ React.createElement(Menu.Item, {
    className: "menu-item-l3",
    key: "problem-order"
  }, /* @__PURE__ */ React.createElement("a", {
    onClick: (event) => event.preventDefault()
  }, formatMessage({id: "problemOrder"}), renderMenuNumber("problem-order", menuNumber?.ERROR)))), withPermission("pending-print-order") && /* @__PURE__ */ React.createElement(Menu.Item, {
    className: "menu-item-l2",
    key: "waiting-packed"
  }, /* @__PURE__ */ React.createElement("a", {
    onClick: (event) => event.preventDefault(),
    href: "/waiting-packed"
  }, formatMessage({id: "printOrderPending"}), renderMenuNumber("waiting-packed", menuNumber?.STAY_PACK))), withPermission("to-be-collected") && /* @__PURE__ */ React.createElement(Menu.Item, {
    className: "menu-item-l2",
    key: "waiting-collected"
  }, /* @__PURE__ */ React.createElement("a", {
    onClick: (event) => event.preventDefault(),
    href: "/waiting-collected"
  }, formatMessage({id: "beCollectedTo"}), renderMenuNumber("waiting-collected", menuNumber?.STAY_RECEIVE))), withPermission("shipped") && /* @__PURE__ */ React.createElement(Menu.Item, {
    className: "menu-item-l2",
    key: "shipped"
  }, /* @__PURE__ */ React.createElement("a", {
    onClick: (event) => event.preventDefault(),
    href: "/shipped"
  }, formatMessage({id: "shipped"}))), withPermission("shelved") && /* @__PURE__ */ React.createElement(Menu.Item, {
    className: "menu-item-l2",
    key: "shelved"
  }, /* @__PURE__ */ React.createElement("a", {
    onClick: (event) => event.preventDefault(),
    href: "/shelved"
  }, formatMessage({id: "stored"}))), withPermission("history-order") && /* @__PURE__ */ React.createElement(Menu.Item, {
    className: "menu-item-l2",
    key: "history-order"
  }, /* @__PURE__ */ React.createElement("a", {
    onClick: (event) => event.preventDefault(),
    href: "/history-order"
  }, formatMessage({id: "historicalOrders"}))), withPermission("unusual-order-management-order-log") && /* @__PURE__ */ React.createElement(Menu.Item, {
    className: "menu-item-l2",
    key: "operation-log"
  }, /* @__PURE__ */ React.createElement("a", {
    onClick: (event) => event.preventDefault(),
    href: "/operation-log"
  }, formatMessage({id: "operationRecord"}))), withPermission("return-management") && /* @__PURE__ */ React.createElement(SubMenu, {
    className: "menu-sub-l2",
    key: "menu-sub-orders-return-management",
    title: formatMessage({id: "returnManagement"})
  }, withPermission("return-management") && /* @__PURE__ */ React.createElement(Menu.Item, {
    className: "menu-item-l3",
    key: "order-refund-list"
  }, /* @__PURE__ */ React.createElement("a", {
    onClick: (event) => event.preventDefault(),
    href: "/order-refund-list"
  }, formatMessage({id: "refundList"}))), withPermission("return-management") && /* @__PURE__ */ React.createElement(Menu.Item, {
    className: "menu-item-l3",
    key: "order-return-refund-list"
  }, /* @__PURE__ */ React.createElement("a", {
    onClick: (event) => event.preventDefault(),
    href: "/order-return-refund-list"
  }, formatMessage({id: "returnRefund"})))), withPermission("order-setting") && /* @__PURE__ */ React.createElement(SubMenu, {
    className: "menu-sub-l2",
    key: "menu-sub-orders-setting",
    title: formatMessage({id: "OrderSettings"})
  }, withPermission("entrance-logistics-rule") && /* @__PURE__ */ React.createElement(Menu.Item, {
    className: "menu-item-l3",
    key: "order-setting/method-setting"
  }, /* @__PURE__ */ React.createElement("a", {
    onClick: (event) => event.preventDefault(),
    href: "/order-setting/method-setting"
  }, formatMessage({id: "ShippingRuleSettings"}))), withPermission("shipping-warehouse-setting") && /* @__PURE__ */ React.createElement(Menu.Item, {
    className: "menu-item-l3",
    key: "warehouse-settings"
  }, /* @__PURE__ */ React.createElement("a", {
    onClick: (event) => event.preventDefault(),
    href: "/warehouse-settings"
  }, formatMessage({id: "warehouseSettingShipping"}))), withPermission("sender-info-setting") && /* @__PURE__ */ React.createElement(Menu.Item, {
    className: "menu-item-l3",
    key: "order-setting/sender-setting"
  }, /* @__PURE__ */ React.createElement("a", {
    onClick: (event) => event.preventDefault(),
    href: "/order-setting/sender-setting"
  }, formatMessage({id: "SenderInformationSettings"}))), withPermission("template-settings") && /* @__PURE__ */ React.createElement(Menu.Item, {
    className: "menu-item-l3",
    key: "order-setting/shipping-setting"
  }, /* @__PURE__ */ React.createElement("a", {
    onClick: (event) => event.preventDefault(),
    href: "/order-setting/shipping-setting"
  }, formatMessage({id: "ShippingSettingsTab"}))), withPermission("distribution-settings") && /* @__PURE__ */ React.createElement(Menu.Item, {
    className: "menu-item-l3",
    key: "order-setting/distribution-setting"
  }, /* @__PURE__ */ React.createElement("a", {
    onClick: (event) => event.preventDefault(),
    href: "/order-setting/distribution-setting"
  }, formatMessage({id: "distributionSettings"}))), withPermission("list-order-tpl") && /* @__PURE__ */ React.createElement(Menu.Item, {
    className: "menu-item-l3",
    key: "order-setting/export-template-setting"
  }, /* @__PURE__ */ React.createElement("a", {
    onClick: (event) => event.preventDefault(),
    href: "/order-setting/export-template-setting"
  }, formatMessage({id: "ExportTemplateTab"}))), withPermission("LA-invoice-rule-setting") && /* @__PURE__ */ React.createElement(Menu.Item, {
    className: "menu-item-l3",
    key: "order-setting/invoice-setting"
  }, /* @__PURE__ */ React.createElement("a", {
    onClick: (event) => event.preventDefault(),
    href: "/order-setting/invoice-setting"
  }, formatMessage({id: "LAInvoiceSettingsTab"}))), withPermission("list-order-commission") && /* @__PURE__ */ React.createElement(Menu.Item, {
    className: "menu-item-l3",
    key: "order-setting/commission-setting"
  }, /* @__PURE__ */ React.createElement("a", {
    onClick: (event) => event.preventDefault(),
    href: "/order-setting/commission-setting"
  }, formatMessage({id: "commissionSetting"})))), withPermission("utility-tool") && /* @__PURE__ */ React.createElement(SubMenu, {
    className: "menu-sub-l2",
    key: "menu-sub-tool-setting",
    title: formatMessage({id: "utilityTool"})
  }, withPermission("scan-shipment") && /* @__PURE__ */ React.createElement(Menu.Item, {
    className: "menu-item-l3",
    key: "tool-setting/scan-shipping"
  }, /* @__PURE__ */ React.createElement("a", {
    onClick: (event) => event.preventDefault(),
    href: "/tool-setting/scan-shipping"
  }, formatMessage({id: "scanShipment"}))), withPermission("print-hot-item-label") && /* @__PURE__ */ React.createElement(Menu.Item, {
    className: "menu-item-l3",
    key: "tool-setting/explosive-print-order"
  }, /* @__PURE__ */ React.createElement("a", {
    onClick: (event) => event.preventDefault(),
    href: "/tool-setting/explosive-print-order"
  }, formatMessage({id: "printPopularProduct"}))))), withPermission("master-product") && /* @__PURE__ */ React.createElement(SubMenu, {
    className: "menu-sub-l1",
    key: "menu-sub-master-products",
    icon: /* @__PURE__ */ React.createElement(IconMenuMasterProducts, {
      className: "menu-icon"
    }),
    title: formatMessage({id: "menuProductLocal"})
  }, withPermission("master-product-listing") && /* @__PURE__ */ React.createElement(Menu.Item, {
    className: "menu-item-l2",
    key: "product-local"
  }, /* @__PURE__ */ React.createElement("a", {
    onClick: (event) => event.preventDefault(),
    href: "/product-local"
  }, formatMessage({id: "masterProductList"}))), withPermission("price-management") && /* @__PURE__ */ React.createElement(Menu.Item, {
    className: "menu-item-l2",
    key: "product-2local-price"
  }, /* @__PURE__ */ React.createElement("a", {
    onClick: (event) => event.preventDefault(),
    href: "/product-2local-price"
  }, formatMessage({id: "menuPriceManagement"}))), withPermission("product-settings") && /* @__PURE__ */ React.createElement(Menu.Item, {
    className: "menu-item-l2",
    key: "setting/new-master-product-settings"
  }, /* @__PURE__ */ React.createElement("a", {
    onClick: (event) => event.preventDefault(),
    href: "/setting/new-master-product-settings"
  }, formatMessage({id: "productSettings"})))), withPermission("product-management") && /* @__PURE__ */ React.createElement(SubMenu, {
    className: "menu-sub-l1",
    key: "menu-sub-channel-products",
    icon: /* @__PURE__ */ React.createElement(IconMenuChannelProducts, {
      className: "menu-icon"
    }),
    title: /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement("span", null, formatMessage({id: "channelProduct"})))
  }, withPermission("list-product") && menuChannelList.filter(({channelId}) => {
    return channelList?.includes(channelId);
  }).map((channelItem) => {
    return channelItem.channelId !== "GINEE_POS" ? /* @__PURE__ */ React.createElement(Menu.Item, {
      className: "menu-item-l2",
      key: channelItem?.url
    }, /* @__PURE__ */ React.createElement("a", {
      onClick: (event) => event.preventDefault(),
      href: `/${channelItem?.url}`
    }, channelItem?.text)) : /* @__PURE__ */ React.createElement(React.Fragment, null);
  }), channelList?.includes("GINEE_POS") && /* @__PURE__ */ React.createElement(Menu.Item, {
    className: "menu-item-l2",
    key: "product-ginee-pos"
  }, /* @__PURE__ */ React.createElement("a", {
    onClick: (event) => event.preventDefault(),
    href: "/product-ginee-pos"
  }, "Ginee POS")), withPermission("product-tools") && /* @__PURE__ */ React.createElement(SubMenu, {
    className: "menu-sub-l2",
    key: "menu-sub-tools",
    title: /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement("span", null, formatMessage({id: "productTools"})))
  }, withPermission("product-collect") && /* @__PURE__ */ React.createElement(Menu.Item, {
    className: "menu-item-l3",
    key: "collection-list"
  }, /* @__PURE__ */ React.createElement("a", {
    onClick: (event) => event.preventDefault(),
    href: "/collection-list"
  }, formatMessage({id: "productScrape"}))), withPermission("clone-shop") && /* @__PURE__ */ React.createElement(Menu.Item, {
    className: "menu-item-l3",
    key: "setting/copyStore"
  }, formatMessage({id: "storeMoving"})))), withPermission("product-management") && Object.keys(globalMap)?.length ? /* @__PURE__ */ React.createElement(SubMenu, {
    className: "menu-sub-l1",
    key: "menu-sub-channel-cnsc-products",
    icon: /* @__PURE__ */ React.createElement(IconMenuChannelCnsc, {
      className: "menu-icon"
    }),
    title: /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement("span", null, formatMessage({id: "globalProduct"})))
  }, /* @__PURE__ */ React.createElement(Menu.Item, {
    className: "menu-item-l2",
    key: "product-shopee-cnsc"
  }, /* @__PURE__ */ React.createElement("a", {
    onClick: (event) => event.preventDefault(),
    href: "/product-shopee-cnsc"
  }, "Shopee"))) : /* @__PURE__ */ React.createElement(React.Fragment, null), withPermission("purchase-management") && /* @__PURE__ */ React.createElement(SubMenu, {
    className: "menu-sub-l1",
    key: "menu-sub-purchase",
    icon: /* @__PURE__ */ React.createElement(IconMenuPurchase, {
      className: "menu-icon"
    }),
    title: formatMessage({id: "purchase"})
  }, withPermission("purchase-management-purchase-order") && /* @__PURE__ */ React.createElement(Menu.Item, {
    className: "menu-item-l2",
    key: "purchase/order-list"
  }, /* @__PURE__ */ React.createElement("a", {
    onClick: (event) => event.preventDefault()
  }, formatMessage({id: "purchaseOrder"}))), withPermission("purchase-management-supplier") && /* @__PURE__ */ React.createElement(Menu.Item, {
    className: "menu-item-l2",
    key: "purchase/supplier-list"
  }, /* @__PURE__ */ React.createElement("a", {
    onClick: (event) => event.preventDefault()
  }, formatMessage({id: "supplierManagement"})))), withPermission("list-msku-stock") && /* @__PURE__ */ React.createElement(SubMenu, {
    className: "menu-sub-l1",
    key: "menu-sub-inventory",
    icon: /* @__PURE__ */ React.createElement(IconMenuInventory, {
      className: "menu-icon"
    }),
    title: formatMessage({id: "stock"})
  }, withPermission("list-msku-stock") && /* @__PURE__ */ React.createElement(Menu.Item, {
    className: "menu-item-l2",
    key: "stock-manage-local"
  }, /* @__PURE__ */ React.createElement("a", {
    onClick: (event) => event.preventDefault(),
    href: "/stock-manage-local"
  }, formatMessage({id: "stockManageList"}))), withPermission("promotion-stock-search") && /* @__PURE__ */ React.createElement(Menu.Item, {
    className: "menu-item-l2",
    key: "stock-reserve"
  }, /* @__PURE__ */ React.createElement("a", {
    onClick: (event) => event.preventDefault(),
    href: "/stock-reserve"
  }, formatMessage({id: "activeReserveInventory"}))), withPermission("stock-changes-record-2.0") && /* @__PURE__ */ React.createElement(Menu.Item, {
    className: "menu-item-l2",
    key: "inventory-log-local"
  }, /* @__PURE__ */ React.createElement("a", {
    onClick: (event) => event.preventDefault(),
    href: "/inventory-log-local"
  }, formatMessage({id: "InventoryLog"}))), withPermission("store-stock-change-history") && /* @__PURE__ */ React.createElement(Menu.Item, {
    className: "menu-item-l2",
    key: "store-stock-change-history"
  }, /* @__PURE__ */ React.createElement("a", {
    onClick: (event) => event.preventDefault(),
    href: "/store-stock-change-history"
  }, formatMessage({id: "storeStockChangeHistory"}))), withPermission("store-stock-push-rules") && /* @__PURE__ */ React.createElement(Menu.Item, {
    className: "menu-item-l2",
    key: "stock-push-new"
  }, /* @__PURE__ */ React.createElement("a", {
    onClick: (event) => event.preventDefault(),
    href: "/stock-push-new"
  }, formatMessage({id: "menuStockPushRules"}))), withPermission("stocks-settings") && /* @__PURE__ */ React.createElement(Menu.Item, {
    className: "menu-item-l2",
    key: "stock-setting"
  }, /* @__PURE__ */ React.createElement("a", {
    onClick: (event) => event.preventDefault(),
    href: "/stock-setting"
  }, formatMessage({id: "StockSetting"})))), withPermission("warehouse") && /* @__PURE__ */ React.createElement(SubMenu, {
    className: "menu-sub-l1",
    key: "menu-sub-warehouse",
    icon: /* @__PURE__ */ React.createElement(IconMenuWarehouse, {
      className: "menu-icon"
    }),
    title: formatMessage({id: "menuWarehouse"})
  }, withPermission("warehouse-management") && /* @__PURE__ */ React.createElement(Menu.Item, {
    className: "menu-item-l2",
    key: "warehouse-management"
  }, /* @__PURE__ */ React.createElement("a", {
    onClick: (event) => event.preventDefault(),
    href: "/warehouse-management"
  }, formatMessage({id: "warehouseList"}))), withPermission("shelf-management") && /* @__PURE__ */ React.createElement(Menu.Item, {
    className: "menu-item-l2",
    key: "product-location-management"
  }, /* @__PURE__ */ React.createElement("a", {
    onClick: (event) => event.preventDefault(),
    href: "/product-location-management"
  }, formatMessage({id: "menuProductLocation"}))), withPermission("outbound-management") && /* @__PURE__ */ React.createElement(Menu.Item, {
    className: "menu-item-l2",
    key: "outbound-management"
  }, /* @__PURE__ */ React.createElement("a", {
    onClick: (event) => event.preventDefault(),
    href: "/outbound-management"
  }, formatMessage({id: "menuOutboundManagement"}))), withPermission("inv-count-stocktaking") && /* @__PURE__ */ React.createElement(Menu.Item, {
    className: "menu-item-l2",
    key: "warehouse-stock-inventory"
  }, /* @__PURE__ */ React.createElement("a", {
    onClick: (event) => event.preventDefault(),
    href: "/warehouse-stock-inventory"
  }, formatMessage({id: "stoktaking"}))), withPermission("inv-calibration-order") && /* @__PURE__ */ React.createElement(Menu.Item, {
    className: "menu-item-l2",
    key: "warehouse-stock-order"
  }, /* @__PURE__ */ React.createElement("a", {
    onClick: (event) => event.preventDefault(),
    href: "/warehouse-stock-order"
  }, formatMessage({id: "stockAdjustment"}))), withPermission("inbound-management") && /* @__PURE__ */ React.createElement(Menu.Item, {
    className: "menu-item-l2",
    key: "inbound-management"
  }, /* @__PURE__ */ React.createElement("a", {
    onClick: (event) => event.preventDefault(),
    href: "/inbound-management"
  }, formatMessage({id: "menuInboundManagement"}))), withPermission("stock-in-or-out-record") && /* @__PURE__ */ React.createElement(Menu.Item, {
    className: "menu-item-l2",
    key: "stock-in-out-record-local"
  }, /* @__PURE__ */ React.createElement("a", {
    onClick: (event) => event.preventDefault(),
    href: "/stock-in-out-record-local"
  }, formatMessage({id: "stockInOutRecord"}))), withPermission("warehouse") && /* @__PURE__ */ React.createElement(Menu.Item, {
    className: "menu-item-l2",
    key: "warehouse-inventory"
  }, /* @__PURE__ */ React.createElement("a", {
    onClick: (event) => event.preventDefault(),
    href: "/warehouse-inventory"
  }, formatMessage({id: "thirdPartyWarehouseStocktakingList"}))), withPermission("warehouse") && channelList?.includes("GINEE") && /* @__PURE__ */ React.createElement(SubMenu, {
    className: "menu-sub-l2",
    key: "menu-sub-wms-warehouse",
    title: `WMS ${formatMessage({id: "warehouse"})}`
  }, withPermission("warehouse") && /* @__PURE__ */ React.createElement(Menu.Item, {
    className: "menu-item-l3",
    key: "warehouse-product-list"
  }, /* @__PURE__ */ React.createElement("a", {
    onClick: (event) => event.preventDefault(),
    href: "/warehouse-product-list"
  }, formatMessage({id: "menuProduct"})))), withPermission("keeppack-warehouse") && channelList?.includes("KEEPPACK") && /* @__PURE__ */ React.createElement(SubMenu, {
    className: "menu-sub-l2",
    key: "menu-sub-keeppack-warehouse",
    title: `Keeppack ${formatMessage({id: "warehouse"})}`
  }, withPermission("keeppack-warehouse-product") && /* @__PURE__ */ React.createElement(Menu.Item, {
    className: "menu-item-l3",
    key: "warehouse-keeppack-product"
  }, /* @__PURE__ */ React.createElement("a", {
    onClick: (event) => event.preventDefault(),
    href: "/warehouse-keeppack-product"
  }, formatMessage({id: "ItemInformation"}))), withPermission("keeppack-warehouse-purchase") && /* @__PURE__ */ React.createElement(Menu.Item, {
    className: "menu-item-l3",
    key: "keeppack-inbound-management"
  }, /* @__PURE__ */ React.createElement("a", {
    onClick: (event) => event.preventDefault(),
    href: "/keeppack-inbound-management"
  }, formatMessage({id: "menuInboundManagement"}))), withPermission("keeppack-warehouse-outbound") && /* @__PURE__ */ React.createElement(Menu.Item, {
    className: "menu-item-l3",
    key: "keeppack-outbound-management"
  }, /* @__PURE__ */ React.createElement("a", {
    onClick: (event) => event.preventDefault(),
    href: "/keeppack-outbound-management"
  }, formatMessage({id: "menuOutboundManagement"}))), withPermission("keeppack-warehouse-stock-pull") && /* @__PURE__ */ React.createElement(Menu.Item, {
    className: "menu-item-l3",
    key: "keeppack-outbound-inventory"
  }, /* @__PURE__ */ React.createElement("a", {
    onClick: (event) => event.preventDefault(),
    href: "/keeppack-outbound-inventory"
  }, formatMessage({id: "PullRecord"})))), withPermission("kongfu-warehouse") && channelList?.includes("KONGFU") && /* @__PURE__ */ React.createElement(SubMenu, {
    className: "menu-sub-l2",
    key: "menu-sub-kongfu-warehouse",
    title: `KongFu ${formatMessage({id: "warehouse"})}`
  }, withPermission("kongfu-warehouse-product") && /* @__PURE__ */ React.createElement(Menu.Item, {
    className: "menu-item-l3",
    key: "warehouse-kongfu-product"
  }, /* @__PURE__ */ React.createElement("a", {
    onClick: (event) => event.preventDefault(),
    href: "/warehouse-kongfu-product"
  }, formatMessage({id: "ItemInformation"}))), withPermission("kongfu-warehouse-purchase") && /* @__PURE__ */ React.createElement(Menu.Item, {
    className: "menu-item-l3",
    key: "kongfu-inbound-management"
  }, /* @__PURE__ */ React.createElement("a", {
    onClick: (event) => event.preventDefault(),
    href: "/kongfu-inbound-management"
  }, formatMessage({id: "menuInboundManagement"}))), withPermission("kongfu-warehouse-outbound") && /* @__PURE__ */ React.createElement(Menu.Item, {
    className: "menu-item-l3",
    key: "kongfu-outbound-management"
  }, /* @__PURE__ */ React.createElement("a", {
    onClick: (event) => event.preventDefault(),
    href: "/kongfu-outbound-management"
  }, formatMessage({id: "menuOutboundManagement"}))), withPermission("kongfu-warehouse-stock-pull") && /* @__PURE__ */ React.createElement(Menu.Item, {
    className: "menu-item-l3",
    key: "kongfu-outbound-inventory"
  }, /* @__PURE__ */ React.createElement("a", {
    onClick: (event) => event.preventDefault(),
    href: "/kongfu-outbound-inventory"
  }, formatMessage({id: "PullRecord"}))))), withPermission("promotion") && /* @__PURE__ */ React.createElement(SubMenu, {
    className: "menu-sub-l1",
    key: "menu-sub-promotion",
    icon: /* @__PURE__ */ React.createElement(IconMenuPromotion, {
      className: "menu-icon"
    }),
    title: /* @__PURE__ */ React.createElement(React.Fragment, null, formatMessage({id: "menuPromotion"}))
  }, withPermission("promotion-management") && /* @__PURE__ */ React.createElement(SubMenu, {
    className: "menu-sub-l2",
    key: "menu-sub-promotion-management",
    title: formatMessage({id: "discountManagement"})
  }, withPermission("list-promotion") && /* @__PURE__ */ React.createElement(Menu.Item, {
    className: "menu-item-l3",
    key: "promotion-management"
  }, /* @__PURE__ */ React.createElement("a", {
    onClick: (event) => event.preventDefault(),
    href: "/promotion-management"
  }, formatMessage({id: "discountList"}))), withPermission("list-promotion-tpl") && /* @__PURE__ */ React.createElement(Menu.Item, {
    className: "menu-item-l3",
    key: "promotion-tpl"
  }, /* @__PURE__ */ React.createElement("a", {
    onClick: (event) => event.preventDefault(),
    href: "/promotion-tpl"
  }, formatMessage({id: "PromotionTpl"})))), withPermission("auto-bump") && withPermission("promotion-management") && /* @__PURE__ */ React.createElement(Menu.Item, {
    className: "menu-item-l2",
    key: "auto-bump"
  }, /* @__PURE__ */ React.createElement("a", {
    onClick: (event) => event.preventDefault(),
    href: "/auto-bump"
  }, formatMessage({id: "autoBump"}))), withPermission("diagnose-shop") && withPermission("promotion-management") && /* @__PURE__ */ React.createElement(Menu.Item, {
    className: "menu-item-l2",
    key: "diagnose-shop"
  }, /* @__PURE__ */ React.createElement("a", {
    onClick: (event) => event.preventDefault(),
    href: "/diagnose-shop"
  }, formatMessage({id: "storeDiagnose"})))), withPermission("customer-management") && /* @__PURE__ */ React.createElement(SubMenu, {
    className: "menu-sub-l1",
    key: "menu-sub-customer",
    icon: /* @__PURE__ */ React.createElement(IconMenuCustomer, {
      className: "menu-icon"
    }),
    title: formatMessage({id: "menuCustomer"})
  }, withPermission("list-customer") && /* @__PURE__ */ React.createElement(Menu.Item, {
    className: "menu-item-l2",
    key: "customer-management"
  }, /* @__PURE__ */ React.createElement("a", {
    onClick: (event) => event.preventDefault(),
    href: "/customer-management"
  }, formatMessage({id: "customerList"})))), withPermission("report-management") && /* @__PURE__ */ React.createElement(SubMenu, {
    className: "menu-sub-l1",
    key: "menu-sub-reports",
    icon: /* @__PURE__ */ React.createElement(IconMenuReports, {
      className: "menu-icon"
    }),
    title: formatMessage({id: "ReportChart"})
  }, withPermission("sales-report") && /* @__PURE__ */ React.createElement(SubMenu, {
    className: "menu-sub-l2",
    key: "menu-sub-reports-sales",
    title: formatMessage({id: "salesReport"})
  }, withPermission("order-statistics-report") && /* @__PURE__ */ React.createElement(Menu.Item, {
    className: "menu-item-l3",
    key: "report/indent-report"
  }, /* @__PURE__ */ React.createElement("a", {
    onClick: (event) => event.preventDefault(),
    href: "/report/indent-report"
  }, formatMessage({id: "menuOrderReport"}))), withPermission("master-product-sales") && /* @__PURE__ */ React.createElement(Menu.Item, {
    className: "menu-item-l3",
    key: "report/master-product-sales-analysis"
  }, /* @__PURE__ */ React.createElement("a", {
    onClick: (event) => event.preventDefault(),
    href: "/report/master-product-sales-analysis"
  }, formatMessage({id: "masterProductSales"}))), withPermission("master-product-activity") && /* @__PURE__ */ React.createElement(Menu.Item, {
    className: "menu-item-l3",
    key: "report/product-activity"
  }, /* @__PURE__ */ React.createElement("a", {
    onClick: (event) => event.preventDefault(),
    href: "/report/product-activity"
  }, formatMessage({id: "masterProductActivity"}))), withPermission("product-sale-report") && /* @__PURE__ */ React.createElement(Menu.Item, {
    className: "menu-item-l3",
    key: "report/product-report"
  }, /* @__PURE__ */ React.createElement("a", {
    onClick: (event) => event.preventDefault(),
    href: "/report/product-report"
  }, formatMessage({id: "menuProductSaleReport"}))), withPermission("product-variant-sale-report") && /* @__PURE__ */ React.createElement(Menu.Item, {
    className: "menu-item-l3",
    key: "report/product-category-sale-report"
  }, /* @__PURE__ */ React.createElement("a", {
    onClick: (event) => event.preventDefault(),
    href: "/report/product-category-sale-report"
  }, formatMessage({id: "menuCategorySaleReport"}))), withPermission("channel-sale-report") && /* @__PURE__ */ React.createElement(Menu.Item, {
    className: "menu-item-l3",
    key: "report/platform-report"
  }, /* @__PURE__ */ React.createElement("a", {
    onClick: (event) => event.preventDefault(),
    href: "/report/platform-report"
  }, formatMessage({id: "menuPlatformSaleReport"}))), withPermission("store-sale-report") && /* @__PURE__ */ React.createElement(Menu.Item, {
    className: "menu-item-l3",
    key: "report/store-report"
  }, /* @__PURE__ */ React.createElement("a", {
    onClick: (event) => event.preventDefault(),
    href: "/report/store-report"
  }, formatMessage({id: "menuStoreSaleReport"}))), withPermissionAll(["list-msku-stock", "net-income-report"]) && /* @__PURE__ */ React.createElement(Menu.Item, {
    className: "menu-item-l3",
    key: "report/profit-report"
  }, /* @__PURE__ */ React.createElement("a", {
    onClick: (event) => event.preventDefault(),
    href: "/report/profit-report"
  }, formatMessage({id: "menuIncomeStatementReport"})))), (withPermission("out-of-stock-estimation") || withPermissionAll(["list-msku-stock", "low-stock-report"])) && /* @__PURE__ */ React.createElement(SubMenu, {
    className: "menu-sub-l2",
    key: "menu-sub-reports-stock",
    title: formatMessage({id: "inventoryReport"})
  }, withPermissionAll(["list-msku-stock", "low-stock-report"]) && /* @__PURE__ */ React.createElement(Menu.Item, {
    className: "menu-item-l3",
    key: "report/unsalable"
  }, /* @__PURE__ */ React.createElement("a", {
    onClick: (event) => event.preventDefault(),
    href: "/report/unsalable"
  }, formatMessage({id: "menuLowStockReport"}))), withPermission("stock-value") && /* @__PURE__ */ React.createElement(Menu.Item, {
    className: "menu-item-l3",
    key: "report/stock-value"
  }, /* @__PURE__ */ React.createElement("a", {
    onClick: (event) => event.preventDefault(),
    href: "/report/stock-value"
  }, formatMessage({id: "stockValue"}))))), withPermission("logistics") && /* @__PURE__ */ React.createElement(SubMenu, {
    className: "menu-sub-l1",
    key: "menu-sub-logistics-management",
    icon: /* @__PURE__ */ React.createElement(IconLogisticsIcon, {
      className: "menu-icon"
    }),
    title: formatMessage({id: "logistics"})
  }, withPermission("logistics-check-shipping-fee") && /* @__PURE__ */ React.createElement(Menu.Item, {
    className: "menu-item-l2",
    key: "logistics-management/inquire-freight"
  }, /* @__PURE__ */ React.createElement("a", {
    onClick: (event) => event.preventDefault(),
    href: "#"
  }, formatMessage({id: "checkShippingFee"})))), withPermission("accounting-management") && /* @__PURE__ */ React.createElement(SubMenu, {
    className: "menu-sub-l1",
    key: "menu-sub-accounting",
    icon: /* @__PURE__ */ React.createElement(IconMenuAccounting, {
      className: "menu-icon"
    }),
    title: formatMessage({id: "finance"})
  }, withPermission("accurate-view") && channelList?.includes("ACCURATE") && /* @__PURE__ */ React.createElement(SubMenu, {
    className: "menu-sub-l2",
    key: "menu-sub-accounting-accurate",
    title: `Accurate`
  }, /* @__PURE__ */ React.createElement(Menu.Item, {
    className: "menu-item-l3",
    key: "accounting-accurate-product-setting"
  }, /* @__PURE__ */ React.createElement("a", {
    onClick: (event) => event.preventDefault(),
    href: "/accounting-accurate-product-setting"
  }, formatMessage({id: "productManage"}))), /* @__PURE__ */ React.createElement(Menu.Item, {
    className: "menu-item-l3",
    key: "accounting-accurate-product-push-log"
  }, /* @__PURE__ */ React.createElement("a", {
    onClick: (event) => event.preventDefault(),
    href: "/accounting-accurate-product-push-log"
  }, formatMessage({id: "productPushRecord"}))), /* @__PURE__ */ React.createElement(Menu.Item, {
    className: "menu-item-l3",
    key: "accounting-accurate-bill"
  }, /* @__PURE__ */ React.createElement("a", {
    onClick: (event) => event.preventDefault(),
    href: "/accounting-accurate-bill"
  }, formatMessage({id: "salesInvoice"}))), withPermission("accurate-management") && /* @__PURE__ */ React.createElement(Menu.Item, {
    className: "menu-item-l3",
    key: "accounting-accurate-bill-push-log"
  }, /* @__PURE__ */ React.createElement("a", {
    onClick: (event) => event.preventDefault(),
    href: "/accounting-accurate-bill-push-log"
  }, formatMessage({id: "salesInvoicePushRecord"}))))), withPermission("integration") && /* @__PURE__ */ React.createElement(SubMenu, {
    className: "menu-sub-l1",
    key: "menu-sub-integration",
    icon: /* @__PURE__ */ React.createElement(IconMenuIntegration, {
      className: "menu-icon"
    }),
    title: formatMessage({id: "menuIntegration"})
  }, withPermission("integrations-store") && /* @__PURE__ */ React.createElement(Menu.Item, {
    className: "menu-item-l2",
    key: "integrations"
  }, /* @__PURE__ */ React.createElement("a", {
    onClick: (event) => event.preventDefault(),
    href: "/integrations"
  }, formatMessage({id: "StoreList"}))), withPermission("add-store") && /* @__PURE__ */ React.createElement(Menu.Item, {
    className: "menu-item-l2",
    key: "add-integration"
  }, /* @__PURE__ */ React.createElement("a", {
    onClick: (event) => event.preventDefault(),
    href: "/add-integration"
  }, formatMessage({id: "addIntegration"})))), withPermission("settings") && /* @__PURE__ */ React.createElement(Menu.Item, {
    className: "menu-item-l1",
    key: "setting/index",
    icon: /* @__PURE__ */ React.createElement(IconMenuSetting, {
      className: "menu-icon"
    })
  }, /* @__PURE__ */ React.createElement("a", {
    onClick: (event) => event.preventDefault(),
    href: "/setting/index"
  }, formatMessage({id: "menuSetting"})))));
});
