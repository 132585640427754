import {useState} from "../../__snowpack__/pkg/react.js";
import {createModel} from "../../__snowpack__/pkg/hox.js";
import {axios} from "../lib/axios.js";
import {handleError} from "../lib/errorHandler.js";
let timeOutId = null;
let timeOutStatus = false;
export const useGetTipsList = () => {
  const [tipInfo, setTipInfo] = useState({});
  const [tipList, setTipList] = useState([]);
  const [loading, setLoading] = useState(true);
  const fetchData = async (param) => {
    setLoading(true);
    timeOutStatus = false;
    setTipInfo({});
    if (timeOutId) {
      clearTimeout(timeOutId);
    }
    timeOutId = setTimeout(() => {
      setLoading(false);
      timeOutStatus = true;
    }, 4e3);
    try {
      const {
        data: {content}
      } = await axios.post("/saas/tips/list", param);
      if (!timeOutStatus) {
        clearTimeout(timeOutId);
        setTipInfo(content && content[0] ? content[0] : {});
        setTipList(content || []);
      }
    } catch (error) {
      handleError(error);
    } finally {
      setLoading(false);
    }
  };
  return {
    tipsListLoading: loading,
    tipInfo,
    tipList,
    fetchTipsList: fetchData
  };
};
export const useGetTipsListModal = createModel(useGetTipsList);
