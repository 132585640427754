import {lazy} from "../../lib/lazy.js";
export const accounting = [
  {
    path: "/accounting",
    component: lazy(() => import(/* webpackChunkName: "sorryRoutes" */ "../../views/accounting/index.js"))
  },
  {
    path: "/accounting-accurate-product-setting",
    component: lazy(() => import(/* webpackChunkName: "sorryRoutes" */ "../../views/accounting/accurate/ProductSetting.js"))
  },
  {
    path: "/accounting-accurate-product-push-log",
    component: lazy(() => import(/* webpackChunkName: "warehouseProductListRoutes" */ "../../views/accounting/accurate/ProductPushLog.js"))
  },
  {
    path: "/accounting-accurate-bill",
    component: lazy(() => import(/* webpackChunkName: "warehouseProductListRoutes" */ "../../views/accounting/accurate/BillSetting.js"))
  },
  {
    path: "/accounting-accurate-bill-push-log",
    component: lazy(() => import(/* webpackChunkName: "warehouseProductListRoutes" */ "../../views/accounting/accurate/BillPushLog.js"))
  },
  {
    path: "/accounting/reconciliation",
    component: lazy(() => import(/* webpackChunkName: "accountingReconciliation" */ "../../views/reconciliation/OrderList.js"))
  }
];
