import {lazy} from "../../lib/lazy.js";
export const inboundManagementRoutes = [
  {
    path: "/inbound-management",
    auth: "inbound-management",
    component: lazy(() => import(/* webpackChunkName: "inboundManagementRoutes" */ "../../views/inbound-management/index.js")),
    meta: {
      openKeys: ["menu-sub-warehouse"]
    }
  },
  {
    path: "/inbound-management-add",
    component: lazy(() => import(/* webpackChunkName: "inboundManagementRoutes" */ "../../views/inbound-management/Add.js")),
    meta: {
      openKeys: ["menu-sub-warehouse"],
      selectedKeys: ["inbound-management"]
    }
  },
  {
    path: "/inbound-management-edit/:id/:orderType",
    component: lazy(() => import(/* webpackChunkName: "inboundManagementRoutes" */ "../../views/inbound-management/Edit.js")),
    meta: {
      openKeys: ["menu-sub-warehouse"],
      selectedKeys: ["inbound-management"]
    }
  },
  {
    path: "/inbound-management-detail/:id/:orderType",
    component: lazy(() => import(/* webpackChunkName: "inboundManagementRoutes" */ "../../views/inbound-management/Detail.js")),
    meta: {
      openKeys: ["menu-sub-warehouse"],
      selectedKeys: ["inbound-management"]
    }
  },
  {
    path: "/inbound-management-submit/:id/:orderType",
    component: lazy(() => import(/* webpackChunkName: "inboundManagementRoutes" */ "../../views/inbound-management/Submit.js")),
    meta: {
      openKeys: ["menu-sub-warehouse"],
      selectedKeys: ["inbound-management"]
    }
  }
];
