export var ORDER_PERSIST_KEY;
(function(ORDER_PERSIST_KEY2) {
  ORDER_PERSIST_KEY2["DEFAULT_PAGE_SIZE"] = "ORDER_DEFAULT_PAGE_SIZE";
  ORDER_PERSIST_KEY2["DEFAULT_PAGE_SIZE_ALL_ORDER"] = "DEFAULT_PAGE_SIZE_ALL_ORDER";
  ORDER_PERSIST_KEY2["DEFAULT_PAGE_SIZE_PENDING_ORDER"] = "DEFAULT_PAGE_SIZE_PENDING_ORDER";
  ORDER_PERSIST_KEY2["DEFAULT_PAGE_SIZE_HISTORY_ORDER"] = "DEFAULT_PAGE_SIZE_HISTORY_ORDER";
  ORDER_PERSIST_KEY2["DEFAULT_PAGE_SIZE_STAY_PACK"] = "DEFAULT_PAGE_SIZE_STAY_PACK";
  ORDER_PERSIST_KEY2["DEFAULT_PAGE_SIZE_STAY_RECEIVE"] = "DEFAULT_PAGE_SIZE_STAY_RECEIVE";
  ORDER_PERSIST_KEY2["DEFAULT_PAGE_SIZE_SHIPPED"] = "DEFAULT_PAGE_SIZE_SHIPPED";
  ORDER_PERSIST_KEY2["DEFAULT_PAGE_SIZE_SHELVE"] = "DEFAULT_PAGE_SIZE_SHELVE";
  ORDER_PERSIST_KEY2["SHIP_ORDER_SET_LOGISTICS_COMPANY"] = "SHIP_ORDER_SET_LOGISTICS_COMPANY";
  ORDER_PERSIST_KEY2["SHIP_ORDER_SET_PROTECTION_PRICE"] = "SHIP_ORDER_SET_PROTECTION_PRICE";
  ORDER_PERSIST_KEY2["LOGISTICS_AUTHORIZE_JT_EXPRESS_ID"] = "LOGISTICS_AUTHORIZE_JT_EXPRESS_ID";
  ORDER_PERSIST_KEY2["LOGISTICS_AUTHORIZE_NINJA"] = "LOGISTICS_AUTHORIZE_NINJA";
  ORDER_PERSIST_KEY2["FREE_USER_LIST"] = "FREE_USER_LIST";
  ORDER_PERSIST_KEY2["DEFAULT_PAGE_SIZE_ORDER_LIST"] = "DEFAULT_PAGE_SIZE_ORDER_LIST";
})(ORDER_PERSIST_KEY || (ORDER_PERSIST_KEY = {}));
const persist = {
  set: (key, value) => {
    return localStorage.setItem(key, JSON.stringify(value));
  },
  get: (key) => {
    let value = localStorage.getItem(key);
    let tempValue = value === "undefined" ? void 0 : value === "null" ? null : value;
    return !!tempValue ? JSON.parse(tempValue) : tempValue;
  }
};
export {persist};
export var PERSIST_LIFETIME_KEY;
(function(PERSIST_LIFETIME_KEY2) {
  PERSIST_LIFETIME_KEY2["GLOBAL_COUNTRY_LIST"] = "GLOBAL_COUNTRY_LIST";
})(PERSIST_LIFETIME_KEY || (PERSIST_LIFETIME_KEY = {}));
const PERSIST_LIFETIME_ROOT_KEY = "__PERSIST_LIFETIME_ROOT__";
const PERSIST_LIFETIME_EXPIRY_TIME_KEY = "__PERSIST_LIFETIME_EXPIRY_TIME_KEY__";
const parsePersisitLifeRoot = () => {
  return JSON.parse(localStorage.getItem(PERSIST_LIFETIME_ROOT_KEY) || "{}");
};
const stringifyPersisitLifeRoot = (cache) => {
  return localStorage.setItem(PERSIST_LIFETIME_ROOT_KEY, JSON.stringify(cache));
};
const persistLocalStorageWithLifetime = {
  set: (key, value, lifetime) => {
    let cache = parsePersisitLifeRoot();
    let cachedNode = {
      [PERSIST_LIFETIME_EXPIRY_TIME_KEY]: Date.now() + lifetime,
      value
    };
    cache[key] = cachedNode;
    stringifyPersisitLifeRoot(cache);
  },
  get: (key) => {
    const now = Date.now();
    let cache = parsePersisitLifeRoot();
    let cachedNode = cache[key] || {};
    if (parseInt(cachedNode?.[PERSIST_LIFETIME_EXPIRY_TIME_KEY] || now) >= now) {
      return cachedNode.value;
    } else {
      return null;
    }
  },
  delete: (key) => {
    let cache = parsePersisitLifeRoot();
    delete cache[key];
    stringifyPersisitLifeRoot(cache);
  },
  deleteAll: () => {
    localStorage.removeItem(PERSIST_LIFETIME_ROOT_KEY);
  }
};
const persistDefaultAkulaku = () => {
  persist.set(ORDER_PERSIST_KEY.SHIP_ORDER_SET_LOGISTICS_COMPANY, void 0);
  persist.set(ORDER_PERSIST_KEY.SHIP_ORDER_SET_PROTECTION_PRICE, void 0);
};
export {persistLocalStorageWithLifetime, persistDefaultAkulaku};
