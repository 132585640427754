import React from "../../../../__snowpack__/pkg/react.js";
export const IconMenuWarehouse = React.memo((props) => {
  return /* @__PURE__ */ React.createElement("svg", {
    xmlns: "http://www.w3.org/2000/svg",
    xmlnsXlink: "http://www.w3.org/1999/xlink",
    width: "18",
    height: "18",
    viewBox: "0 0 18 18",
    ...props
  }, /* @__PURE__ */ React.createElement("defs", null, /* @__PURE__ */ React.createElement("path", {
    id: "path-1",
    d: "M0.5 0H18.5V18H0.5z"
  })), /* @__PURE__ */ React.createElement("g", {
    fill: "none",
    fillRule: "evenodd",
    stroke: "none",
    strokeWidth: "1"
  }, /* @__PURE__ */ React.createElement("g", {
    transform: "translate(-12 -274)"
  }, /* @__PURE__ */ React.createElement("g", {
    transform: "translate(11 274)"
  }, /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("mask", {
    id: "mask-2",
    fill: "#fff"
  }, /* @__PURE__ */ React.createElement("use", {
    xlinkHref: "#path-1"
  })), /* @__PURE__ */ React.createElement("g", {
    fill: "currentColor",
    fillRule: "nonzero",
    mask: "url(#mask-2)"
  }, /* @__PURE__ */ React.createElement("g", {
    transform: "translate(0 2)"
  }, /* @__PURE__ */ React.createElement("path", {
    d: "M10.638.268a2.384 2.384 0 00-2.195 0L1.236 6.036a.556.556 0 00.347.99h.926v6.859c0 .54.439.978.979.978h1.215V8.484A1.435 1.435 0 016.13 7.062h6.82a1.435 1.435 0 011.427 1.428v6.378h1.215c.54 0 .979-.438.98-.978V7.026h.926a.556.556 0 00.347-.99L10.638.268z"
  }), /* @__PURE__ */ React.createElement("path", {
    d: "M7.603 11.747c.642 0 .963.321.963.963v1.19c0 .642-.321.963-.963.963h-1.19c-.642 0-.963-.32-.963-.963v-1.19c0-.642.32-.963.962-.963h1.19zm3.88 0c.641 0 .962.321.962.963v1.19c0 .642-.32.963-.963.963h-1.19c-.642 0-.963-.32-.963-.963v-1.19c0-.642.321-.963.963-.963h1.19zm-3.88-3.674c.642 0 .963.321.963.963v1.19c0 .642-.321.963-.963.963h-1.19c-.642 0-.963-.32-.963-.962v-1.19c0-.643.32-.964.962-.964h1.19z"
  })))))))));
});
