import React from "../../../../__snowpack__/pkg/react.js";
export const IconMenuChat = React.memo((props) => {
  return /* @__PURE__ */ React.createElement("svg", {
    xmlns: "http://www.w3.org/2000/svg",
    width: "18",
    height: "18",
    viewBox: "0 0 18 18",
    ...props
  }, /* @__PURE__ */ React.createElement("g", {
    fill: "none",
    fillRule: "evenodd",
    stroke: "none",
    strokeWidth: "1"
  }, /* @__PURE__ */ React.createElement("g", {
    transform: "translate(-12 -526)"
  }, /* @__PURE__ */ React.createElement("g", {
    transform: "translate(0 514)"
  }, /* @__PURE__ */ React.createElement("g", {
    transform: "translate(12 12)"
  }, /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", {
    d: "M0 0H18V18H0z"
  }), /* @__PURE__ */ React.createElement("path", {
    fill: "#606C80",
    fillRule: "nonzero",
    d: "M1.512 17.92L4.5 14.932h10.512c.422 0 .773-.147 1.054-.44a1.49 1.49 0 00.422-1.072v-9c0-.422-.14-.773-.422-1.055a1.431 1.431 0 00-1.054-.421H2.988c-.422 0-.773.14-1.054.421a1.431 1.431 0 00-.422 1.055v13.5zM2.988 14.3V4.42h12.024v9H3.867l-.879.879zM13.5 8.182V6.67h-9v1.512h9zm-2.25 2.988V9.694H4.5v1.476h6.75z"
  })))))));
});
