import {Button, Form, Upload, Input, Modal, message} from "../../__snowpack__/pkg/antd.js";
import React, {useState, useEffect} from "../../__snowpack__/pkg/react.js";
import styled from "../../__snowpack__/pkg/styled-components.js";
import {PlusOutlined} from "../../__snowpack__/pkg/@ant-design/icons.js";
import {SessionHelper, OriginMap} from "../../__snowpack__/pkg/@genie/lib-iam-utils.js";
import copy from "../../__snowpack__/pkg/copy-to-clipboard.js";
import {usePostError} from "../entities/usePostError.js";
export const CommitErrorModal = (props) => {
  const actionUrl = OriginMap[window.location.origin]?.INFRA_ORIGIN || window.location.origin;
  const {formatMessage, isVisible, onCancel} = props;
  const [fileList, setFileList] = useState([]);
  const [form] = Form.useForm();
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const {submitError, errorLoading} = usePostError();
  const handleChange = ({fileList: fileList2}) => {
    let newFileList = fileList2.map((_) => {
      _.response ? _.url = _.response : "";
      return _;
    });
    setFileList(newFileList);
  };
  const saveError = async () => {
    let formData = form.getFieldsValue();
    let pushImages = fileList.filter((_) => _?.response?.code === "SUCCESS").map((_) => _?.response?.data?.url);
    let params = {
      title: formData.title,
      description: formData.description,
      attachmentList: pushImages || [],
      ticketSource: "SAAS_SUBMIT_FORM_MANUALLY"
    };
    const data = await submitError(params);
    Modal.info({
      title: formatMessage({id: "done"}),
      okText: formatMessage({id: "copyCopy"}),
      closable: true,
      content: /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement("div", null, formatMessage({id: "thankYouForYourCooperationPlease"})), /* @__PURE__ */ React.createElement("div", {
        style: {color: "#6020ff", background: "#ddd", borderRadius: 4, padding: "2px 4px", marginTop: 10}
      }, data)),
      onOk() {
        copy(data);
        message.success("Success");
      }
    });
    onCancel();
  };
  const uploadButton = /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement(PlusOutlined, null), /* @__PURE__ */ React.createElement("div", {
    style: {marginTop: 8}
  }, formatMessage({id: "upload"})));
  const handlePreview = async (file) => {
    setPreviewImage(file.thumbUrl || file.url || "");
    setPreviewOpen(true);
    setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf("/") + 1));
  };
  const handleCancel = () => setPreviewOpen(false);
  useEffect(() => {
    if (isVisible) {
      form.resetFields();
      setFileList([]);
    }
  }, [isVisible]);
  return /* @__PURE__ */ React.createElement(ModalContent, {
    width: 490,
    title: formatMessage({id: "feedbackForm"}),
    visible: isVisible,
    onCancel: () => {
      onCancel();
    },
    footer: [
      /* @__PURE__ */ React.createElement(Button, {
        onClick: () => onCancel()
      }, formatMessage({id: "cancel"})),
      /* @__PURE__ */ React.createElement(Button, {
        type: "primary",
        loading: errorLoading,
        onClick: saveError
      }, formatMessage({id: "confirm"}))
    ]
  }, /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement("p", null, formatMessage({id: "pleaseSubmitATicektFormWith"})), /* @__PURE__ */ React.createElement(Form, {
    layout: "vertical",
    name: "basic",
    form
  }, /* @__PURE__ */ React.createElement(Form.Item, {
    label: formatMessage({id: "noticeTitle"}),
    name: `title`,
    rules: [{required: true, message: formatMessage({id: "pleaseEnterTheTitle"})}]
  }, /* @__PURE__ */ React.createElement(Input, {
    type: "text",
    maxLength: 35,
    placeholder: formatMessage({id: "pleaseEnterTheTitle"})
  })), /* @__PURE__ */ React.createElement(Form.Item, {
    label: formatMessage({id: "detailedDescription"}),
    name: "description",
    rules: [{required: true, message: formatMessage({id: "PleaseInput"})}]
  }, /* @__PURE__ */ React.createElement(Input.TextArea, {
    placeholder: formatMessage({id: "PleaseInput"}),
    maxLength: 300,
    showCount: true,
    autoSize: {minRows: 4, maxRows: 4}
  })), /* @__PURE__ */ React.createElement(Form.Item, {
    label: formatMessage({id: "uploadAttachment"}),
    name: `attachmentList`
  }, /* @__PURE__ */ React.createElement(Upload, {
    action: `${actionUrl}/infra/common/file/upload`,
    listType: "picture-card",
    name: "file",
    accept: "image/jpeg,image/png,image/jpg",
    fileList,
    data: {accessModifier: "PUBLIC"},
    onChange: handleChange,
    onPreview: handlePreview,
    headers: {Authorization: SessionHelper.getSessionToken() ?? ""}
  }, fileList.length >= 8 ? null : uploadButton))), /* @__PURE__ */ React.createElement(Modal, {
    visible: previewOpen,
    title: previewTitle,
    footer: null,
    onCancel: handleCancel
  }, /* @__PURE__ */ React.createElement("img", {
    alt: "example",
    style: {width: "100%"},
    src: previewImage
  }))));
};
const ModalContent = styled(Modal)`
  .reference-content {
    font-size: 13px;
    font-family: SFProText-Regular, SFProText;
    font-weight: 400;
    color: #202d40;
    line-height: 20px;
    overflow: hidden;
    margin: 0;
  }
`;
