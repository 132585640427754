import qs from "../../__snowpack__/pkg/qs.js";
import _axios from "../../__snowpack__/pkg/axios.js";
import {AcceptLanguageMap} from "./const.js";
import {SessionHelper, OriginMap} from "../../__snowpack__/pkg/@genie/lib-iam-utils.js";
export class CustomError extends Error {
  constructor(code, data, message, transactionId) {
    super();
    this.code = code;
    this.data = data;
    this.message = message;
    this.transactionId = transactionId;
  }
}
const instance = _axios.create({
  baseURL: "/",
  timeout: 6e4,
  withCredentials: true,
  headers: {
    "Access-Control-Allow-Credentials": false
  },
  paramsSerializer: (params) => qs.stringify(params, {arrayFormat: "comma", skipNulls: true})
});
const requestInterceptor = (config) => {
  Object.assign(config.headers, {
    Authorization: SessionHelper.getSessionToken() ?? "",
    "Accept-Language": AcceptLanguageMap[localStorage.getItem("language") || "id"]
  });
  let postPathAndUrl = localStorage.postPathAndUrl ? JSON.parse(localStorage.postPathAndUrl) : {};
  let pathname = window.location.pathname;
  let postUrl = config.url || "";
  if (postUrl) {
    if (!postPathAndUrl[pathname]) {
      postPathAndUrl[pathname] = {};
      postPathAndUrl[pathname][postUrl] = {
        method: config.method
      };
    } else {
      postPathAndUrl[pathname][postUrl] = {
        method: config.method
      };
    }
  }
  if (config.url && /^\/infra\//.test(config.url)) {
    config.baseURL = OriginMap[window.location.origin]?.INFRA_ORIGIN ?? "/";
  }
  localStorage.postPathAndUrl = JSON.stringify(postPathAndUrl);
  return config;
};
const responseInterceptor = (response) => {
  const {data, config} = response;
  let noCheckUrlList = ["/saas/tips/list"];
  if (data.code && data.code !== "SUCCESS") {
    if (data.code && data.code === "PACKAGE_NOT_FOUND") {
      localStorage.removeItem("permissionMap");
      if (window.location.pathname !== "/package-expired") {
        window.location.href = "/package-expired";
      }
    } else if (data.code && data.code === "RESOURCE_REACH_LIMIT") {
      let dataCV = {...data};
      dataCV.code = "SUCCESS";
      dataCV.errorCode = "RESOURCE_REACH_LIMIT";
      if (window.location.pathname !== "/resource-constraints" && !noCheckUrlList.includes(config.url)) {
        window.location.href = "/resource-constraints";
      }
      return dataCV;
    } else {
      throw new CustomError(data.code, data.data, data.message, data.transactionId);
    }
  }
  if (data.code && data.code === "SUCCESS") {
    return data;
  }
  console.warn("[axios:responseInterceptor]: un normalized api response", response.config.method, response.config.url);
  return response;
};
instance.interceptors.request.use(requestInterceptor);
instance.interceptors.response.use(responseInterceptor);
export const axios = instance;
