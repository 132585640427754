import React from "../../../../__snowpack__/pkg/react.js";
export const IconPlan = React.memo((props) => {
  return /* @__PURE__ */ React.createElement("svg", {
    width: "18px",
    height: "18px",
    viewBox: "0 0 18 18",
    version: "1.1",
    xmlns: "http://www.w3.org/2000/svg"
  }, /* @__PURE__ */ React.createElement("g", {
    id: "\u9875\u9762-1",
    stroke: "none",
    "stroke-width": "1",
    fill: "none",
    "fill-rule": "evenodd"
  }, /* @__PURE__ */ React.createElement("g", {
    id: "\u4ED8\u6B3E\u8BB0\u5F55",
    transform: "translate(-1124.000000, -16.000000)",
    "fill-rule": "nonzero"
  }, /* @__PURE__ */ React.createElement("g", {
    id: "\u7F16\u7EC4-25",
    transform: "translate(1124.000000, 16.000000)"
  }, /* @__PURE__ */ React.createElement("g", {
    id: "\u7F16\u7EC4",
    transform: "translate(0.000000, 1.000000)"
  }, /* @__PURE__ */ React.createElement("path", {
    d: "M12.9904319,5.88009666 L9.69506671,0.443260507 C9.53128719,0.172740692 9.2672475,0.0122606871 8.9859375,0.0122606871 C8.7046275,0.0122606871 8.44058781,0.172740692 8.27680829,0.443260507 L4.9808111,5.88009666 L1.15644312,4.39698337 C0.856347388,4.28016551 0.526302932,4.36442468 0.293663826,4.61724784 C0.0610247197,4.87007099 -0.0378336076,5.25192673 0.0352352528,5.61546858 L2.05138694,15.7184303 C2.08911636,15.8934983 2.2235169,16.0150415 2.37624649,16.0122607 L15.6044768,16.0122607 C15.7555302,16.0122607 15.8888869,15.8918589 15.9287044,15.7184303 L17.9379038,5.61322597 C18.0101204,5.25001655 17.9108885,4.86899174 17.6783994,4.61679665 C17.4459104,4.36460156 17.1164034,4.28054711 16.8166959,4.39698337 L12.9904319,5.88009666 L12.9904319,5.88009666 Z",
    id: "\u8DEF\u5F84",
    fill: "#FEC675"
  }), /* @__PURE__ */ React.createElement("path", {
    d: "M12.9904319,5.90044412 L9.56360604,0.226034173 C9.48338081,0.0935335336 9.34020643,0.0377926955 9.20946229,0.0881584619 C9.07871815,0.138524228 8.99039496,0.283443465 8.99099065,0.446618137 L9.05735604,16.0698151 L15.6038448,16.0698151 C15.7548982,16.0698151 15.8882549,15.9490191 15.9280723,15.7749528 L17.9372718,5.63259216 C18.0094884,5.26804724 17.9102565,4.88562142 17.6777674,4.63249902 C17.4452783,4.37937662 17.1157714,4.29501311 16.8160639,4.4118775 L12.9897999,5.90044412 L12.9904319,5.90044412 Z",
    id: "\u8DEF\u5F84",
    fill: "#FFDBA5"
  }), /* @__PURE__ */ React.createElement("path", {
    d: "M13.4547242,7.8955627 L9.63928436,14.0287361 C9.51525303,14.2698896 9.26803413,14.4145817 9.00394022,14.3988329 L7.1476891,14.3988329 L5.55806314,8.0806111 C5.48578752,7.86476156 5.32761735,7.70376803 5.13450038,7.64948769 L4.5,7.40439699 L7.46662679,7.40439699 L8.68500253,12.0621047 L11.3343791,8.142622 C11.3778783,8.0415208 11.3800475,7.92294571 11.3402854,7.8197716 C11.2969793,7.712546 11.219002,7.62972052 11.1234415,7.58944539 L10.6467225,7.40439699 L13.1366302,7.40439699 C13.2539692,7.38365178 13.3719124,7.43726306 13.4462867,7.54515189 C13.5146543,7.64716321 13.5180752,7.78923509 13.4547242,7.8955627 L13.4547242,7.8955627 Z",
    id: "\u8DEF\u5F84",
    fill: "#A97539"
  }))))));
});
