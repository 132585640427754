import {lazy} from "../../lib/lazy.js";
export const productLocalRoutes = [
  {
    path: "/product-local",
    auth: "master-product-listing",
    component: lazy(() => import(/* webpackChunkName: "productLocalRoutes" */ "../../views/product-local/index.js")),
    meta: {
      openKeys: ["menu-sub-master-products"]
    }
  },
  {
    path: "/product-local-bind-history",
    component: lazy(() => import(/* webpackChunkName: "productLocalRoutes" */ "../../views/product-local-bind-history/index.js"))
  },
  {
    path: "/product-local-add",
    component: lazy(() => import(/* webpackChunkName: "productLocalRoutes" */ "../../views/product-local/add.js")),
    auth: "master-product-listing"
  },
  {
    path: "/product-local-add-bundle",
    component: lazy(() => import(/* webpackChunkName: "productLocalRoutes" */ "../../views/product-local/add-bundle.js")),
    auth: "master-product-listing"
  },
  {
    path: "/product-local-edit-bundle/:id",
    component: lazy(() => import(/* webpackChunkName: "productLocalRoutes" */ "../../views/product-local/edit-bundle.js"))
  },
  {
    path: "/product-local-edit/:id",
    component: lazy(() => import(/* webpackChunkName: "productLocalRoutes" */ "../../views/product-local/edit.js"))
  },
  {
    path: "/product-local-import-to-bind",
    component: lazy(() => import(/* webpackChunkName: "productLocalRoutes" */ "../../views/product-local/import-to-bind/index.js"))
  },
  {
    path: "/product-local-import-to-create",
    component: lazy(() => import(/* webpackChunkName: "productLocalRoutes" */ "../../views/product-local/import-to-create/index.js"))
  },
  {
    path: "/product-local-update-auto-bind",
    component: lazy(() => import(/* webpackChunkName: "productLocalRoutes" */ "../../views/product-local/update-auto-bind/index.js"))
  },
  {
    path: "/product-mass-edit/field",
    component: lazy(() => import("../../views/product-mass-edit-field/index.js"))
  },
  {
    path: "/product-operation-list",
    component: lazy(() => import("../../views/product-operation-list/index.js"))
  },
  {
    path: "/master-product-operation-list",
    component: lazy(() => import("../../views/master-product-operation-list/index.js"))
  }
];
