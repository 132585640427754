import {lazy} from "../../lib/lazy.js";
export const integrationRoutes = [
  {
    path: "/integrations",
    auth: "integrations-store",
    component: lazy(() => import(/* webpackChunkName: "integrationRoutes" */ "../../views/integrations/store-list.js"))
  },
  {
    path: "/add-integration",
    auth: "add-store",
    component: lazy(() => import(/* webpackChunkName: "integrationRoutes" */ "../../views/integrations/add-integration.js"))
  },
  {
    path: "/logistics-address-settings",
    auth: "integrations-store",
    component: lazy(() => import(/* webpackChunkName: "integrationRoutes" */ "../../views/integrations/logistics-address-settings.js")),
    meta: {
      openKeys: ["menu-sub-integration", "integrations"]
    }
  },
  {
    path: "/modify-warehouse",
    auth: "integrations-store",
    component: lazy(() => import(/* webpackChunkName: "integrationRoutes" */ "../../views/integrations/modify-warehouse.js"))
  }
];
