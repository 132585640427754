import React from "../../../../__snowpack__/pkg/react.js";
export const IconService = React.memo((props) => {
  return /* @__PURE__ */ React.createElement("svg", {
    xmlns: "http://www.w3.org/2000/svg",
    width: "18",
    height: "18",
    viewBox: "0 0 18 18",
    ...props
  }, /* @__PURE__ */ React.createElement("g", {
    fill: "none",
    fillRule: "evenodd",
    stroke: "none",
    strokeWidth: "1"
  }, /* @__PURE__ */ React.createElement("g", {
    fill: "#606C80",
    fillRule: "nonzero",
    transform: "translate(-72 -862)"
  }, /* @__PURE__ */ React.createElement("g", {
    transform: "translate(12 854)"
  }, /* @__PURE__ */ React.createElement("g", {
    transform: "translate(60 8)"
  }, /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", {
    d: "M16.488 6.937C15.764 2.904 12.627 0 8.766 0c-3.86 0-6.997 3.065-7.72 6.937C.402 7.5 0 8.469 0 9.598c0 1.372.804 3.066 2.01 3.066 1.046 0 1.046-1.291 1.046-3.066 0-1.693 0-2.903-.804-3.065C2.976 3.388 5.63 1.05 8.767 1.05c3.138 0 5.79 2.339 6.514 5.404-.804.161-.804 1.371-.804 2.984 0 1.21 0 1.936.16 2.581-.964 1.694-2.331 2.823-4.101 3.308-.322-.485-.965-.807-1.77-.807-1.126 0-2.01.564-2.01 1.29 0 .726.885 1.29 2.011 1.29 1.046 0 2.011-.564 2.011-1.29 1.77-.483 3.217-1.774 4.263-3.387.08.08.321.08.563.08 1.206 0 2.01-1.694 2.01-3.065-.08-.968-.482-1.936-1.126-2.5z"
  }), /* @__PURE__ */ React.createElement("path", {
    d: "M5.6 9.721a.817.817 0 101.63 0 .817.817 0 10-1.63 0zM10.18 9.721a.817.817 0 101.63 0 .817.817 0 10-1.63 0z"
  }))))))));
});
