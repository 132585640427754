import '../../../../common/_commonjsHelpers-64249c85.js';
import '../../../../common/tslib.es6-a8e14131.js';
import '../../../../common/isSymbol-c0accfbe.js';
import '../../../../common/js.cookie-4da54cf2.js';
import '../../../../common/_getTag-7fad8ff9.js';
import '../../../../common/pick-b476c0a8.js';
import '../../../../common/index-48cdf8cc.js';
import '../../../../common/process-2545f00a.js';
import '../../../../common/toString-c7daafa9.js';
import { e as eventLogger$1, c as customMerge } from '../../../../common/index-4db7daf4.js';
import '../../../../common/_baseSlice-ca622ba3.js';
import '../../../../common/v4-08a3b663.js';
import '../../../../common/index-152ec97e.js';

var getUserId = function () {
    var userInfo = {};
    try {
        userInfo = JSON.parse(window.localStorage.getItem("userInfo") || "{}");
    }
    catch (error) { }
    return userInfo.id;
};
var autoSourceInfoEvents = [{ eventAction: "REGISTER", eventLocation: "REGISTER" }];
var isAutoSourceInfo = function (params) {
    return !!autoSourceInfoEvents.find(function (_) {
        return _.eventLocation === params.eventAction &&
            _.eventLocation === params.eventLocation;
    });
};
var isAutoSourceInfoOld = function (params) {
    return !!autoSourceInfoEvents.find(function (_) {
        return _.eventLocation === params.action && _.eventLocation === params.location;
    });
};
var eventLogger = {
    push: function (params, useWorker) {
        var autoSourceInfo = isAutoSourceInfo(params);
        return eventLogger$1.push(customMerge({
            applicationName: "SYS_ERP",
            eventUserId: getUserId(),
        }, params), autoSourceInfo, undefined, useWorker);
    },
    pushOld: function (params, useWorker) {
        var autoSourceInfo = isAutoSourceInfoOld(params);
        return eventLogger$1.pushOld(customMerge({
            applicationName: "SYS_ERP",
            userId: getUserId(),
        }, params), autoSourceInfo, undefined, useWorker);
    },
};

export { eventLogger };
