import React, {useState, memo, useEffect, useMemo} from "../../../__snowpack__/pkg/react.js";
import styled from "../../../__snowpack__/pkg/styled-components.js";
import Trumpet from "../../assets/trumpet.svg";
import {useGetTipsList} from "../../entities/useGetTips.js";
import {useTodoListModal} from "../../entities/dashboard/useTodoList.js";
let clearBoth = null;
let clearBoth1 = null;
let clearIntervalNum = null;
const WordCasting = (props) => {
  const {tipList, tipsListLoading, fetchTipsList} = useGetTipsList();
  const [list, setList] = useState([]);
  const {shopList} = useTodoListModal();
  const [shopLimitInfo, setShopLimitInfo] = useState();
  const {sleepTime = 1e4} = props;
  const [isTop, setIsTop] = useState(false);
  const [loopNum, setLoopNum] = useState(0);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const startAnimation = (start) => {
    let add = false;
    let scrollPosition = 0;
    const container = document.querySelector(".scroll-container");
    const content = document.querySelector(".scroll-content span");
    content.style.transform = `translateX(${12}px)`;
    clearIntervalNum = true;
    window.requestAnimationFrame(function fu() {
      const containerWidth = container?.offsetWidth;
      const contentWidth = content?.offsetWidth;
      if (contentWidth > containerWidth || contentWidth < 0) {
        if (add) {
          scrollPosition += 1.5;
        } else {
          scrollPosition -= 1.5;
        }
        if (-scrollPosition >= contentWidth - 24) {
          scrollPosition = containerWidth - 12;
          content.style.transform = `translateX(${containerWidth - 12}px)`;
        } else {
          content.style.transform = `translateX(${scrollPosition}px)`;
        }
        if (clearIntervalNum) {
          window.requestAnimationFrame(fu);
        }
      } else {
        content.style.transform = `translateX(${0}px)`;
        clearIntervalNum = false;
      }
    });
  };
  const setTimeInfo = () => {
    clearBoth = setTimeout(() => {
      clearIntervalNum = false;
      let firstNum = loopNum + 1;
      let lastNum = loopNum + 2;
      setIsTop(true);
      const content = document.querySelector("#scrollContent span");
      const content1 = document.querySelector(".header-text-loop-first span");
      console.log("content", content.style.transform);
      clearBoth1 = setTimeout(() => {
        content.style.transform = `translateX(${1}px)`;
        content1.style.transform = `translateX(${0}px)`;
        setIsTop(false);
        setLoopNum(firstNum);
        setFirstName(list[firstNum % list.length]);
        setLastName(list[lastNum % list.length]);
        setTimeout(() => {
          startAnimation(true);
        }, 500);
      }, 100);
    }, sleepTime);
  };
  const handleData = () => {
    if (localStorage.getItem("todoShopList")) {
      let todoShopList = JSON.parse(localStorage.getItem("todoShopList") || "{}");
      setShopLimitInfo(todoShopList);
    }
  };
  useEffect(() => {
    if (shopLimitInfo && shopLimitInfo.abnormalShopExpiredCount && shopLimitInfo.abnormalShopExpiredCount > 0) {
      let param = {
        pageNames: ["Header Tip Abnormal Shop", "Header Tip ALL"]
      };
      fetchTipsList(param);
    } else if (shopLimitInfo && shopLimitInfo.expiredShopCount >= 0) {
      let param = {
        pageNames: ["Header Tip ALL"]
      };
      fetchTipsList(param);
    }
  }, [shopLimitInfo]);
  useEffect(() => {
    if (shopList && shopList.expiredShopCount >= 0) {
      setShopLimitInfo(shopList);
    }
  }, [shopList]);
  useEffect(() => {
    let language = localStorage.getItem("language");
    let newList = [];
    if (language && tipsListLoading === false) {
      tipList.forEach((item) => {
        if (language === "en" && item.descEn) {
          newList.push(item.descEn);
        } else if (language === "zh" && item.descZh) {
          newList.push(item.descZh);
        } else if (language === "id" && item.descIn) {
          newList.push(item.descIn && item.descIn);
        } else if (language === "vn" && item.descVn) {
          newList.push(item.descVn);
        } else if (language === "th" && item.descTh) {
          newList.push(item.descTh);
        }
      });
      setList(newList);
    }
  }, [tipList, tipsListLoading]);
  useEffect(() => {
    if (!list || !list.length)
      return;
    setFirstName(list[0]);
    if (list && list.length > 1) {
      setLastName(list[1]);
      setTimeInfo();
    }
    setTimeout(() => {
      startAnimation(true);
    }, 300);
  }, [list]);
  useEffect(() => {
    if (loopNum > 0) {
      setTimeInfo();
    }
  }, [loopNum]);
  const isShow = useMemo(() => {
    return list && list.length > 0;
  }, [list]);
  const hover = () => {
    clearBoth && clearTimeout(clearBoth);
    clearBoth1 && clearTimeout(clearBoth1);
  };
  const leave = () => {
    console.log("leave");
    if (loopNum > 0) {
      setTimeInfo();
    }
  };
  const checkPage = () => {
    if (document.visibilityState === "hidden") {
      hover();
    }
    if (document.visibilityState === "visible") {
      leave();
    }
  };
  useEffect(() => {
    handleData();
    document.addEventListener("visibilitychange", checkPage);
    return () => {
      document.removeEventListener("visibilitychange", checkPage);
    };
  }, []);
  return isShow ? /* @__PURE__ */ React.createElement(Wrapper, null, /* @__PURE__ */ React.createElement("div", {
    className: "header-text-loop"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "header-text-loop-list"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "img-box"
  }, /* @__PURE__ */ React.createElement("img", {
    src: Trumpet
  })), /* @__PURE__ */ React.createElement("div", {
    className: "header-text-loop-first",
    style: {top: isTop ? "-50px" : "0", transition: isTop ? "all 0.3s linear" : ""}
  }, /* @__PURE__ */ React.createElement("div", {
    className: "scroll-container"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "scroll-content",
    id: "scrollContent"
  }, /* @__PURE__ */ React.createElement("span", {
    onMouseMove: hover,
    onMouseLeave: leave
  }, firstName)))), /* @__PURE__ */ React.createElement("div", {
    className: "header-text-loop-last",
    style: {top: isTop ? "0px" : "50px", transition: isTop ? "all 0.3s linear" : ""}
  }, /* @__PURE__ */ React.createElement("span", null, lastName))))) : /* @__PURE__ */ React.createElement(React.Fragment, null);
};
const Wrapper = styled.div`
  flex: 1;
  height: 50px;
  margin-left: 16px;
  margin-right: 16px;
  .header-text-loop-list {
    list-style: none;
    margin: 0;
    padding: 0;
    height: 30px;
    margin-top: 10px;
    overflow: hidden;
    position: relative;
    padding-right: 16px;
    background: linear-gradient(90deg, #fff5ed 0%, #ffecf0 100%);
    border-radius: 15px;
    display: flex;

    .img-box {
      width: 36px;
      height: 30px;
      position: absolute;
      z-index: 9999;
      padding-left: 16px;
      left: 0;
      display: flex;
      align-items: center;
    }
    .header-text-loop-first {
      display: flex;
      align-items: center;
      height: 30px;
      line-height: 30px;
      position: absolute;
      top: 0;
      left: 34px;
      right: 16px;
      overflow: hidden;
      span {
        cursor: pointer;
        color: #f5222d;
        display: flex;
        height: 30px;
        line-height: 30px;
        align-items: center;
      }
    }
    .header-text-loop-last {
      display: flex;
      align-items: center;
      height: 30px;
      line-height: 30px;
      position: absolute;
      top: 0;
      left: 34px;
      right: 16px;
      overflow: hidden;
      span {
        cursor: pointer;
        color: #f5222d;
        display: flex;
        height: 30px;
        line-height: 30px;
        align-items: center;
        display: block;
      }
    }
  }
  .scroll-container {
    width: 100%;
    overflow: hidden;
  }
  .scroll-content {
    white-space: nowrap;
    display: flex;
    align-items: center;
    overflow: hidden;
  }
  @keyframes scroll {
    0% {
      width: 10px;
    }
    40% {
      width: 12px;
    }
    80% {
      width: 14px;
    }
    100% {
      width: 12px;
    }
  }
`;
export default memo(WordCasting);
