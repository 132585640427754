import {lazy} from "../../lib/lazy.js";
export const productMagentoRoutes = [
  {
    path: "/product-magento",
    component: lazy(() => import(/* webpackChunkName: "productWooCommerceRoutes" */ "../../views/product-magento/index.js")),
    auth: "list-product"
  },
  {
    path: "/product-magento/add",
    component: lazy(() => import(/* webpackChunkName: "productWooCommerceRoutes" */ "../../views/product-magento/detail/index.js")),
    auth: "list-product"
  },
  {
    path: "/product-magento/edit/:id",
    component: lazy(() => import(/* webpackChunkName: "productWooCommerceRoutes" */ "../../views/product-magento/detail/index.js")),
    auth: "list-product"
  },
  {
    path: "/product-magento/mass-edit-category",
    component: lazy(() => import(/* webpackChunkName: "productWooCommerceRoutes" */ "../../views/product-magento/MassEditCategory/index.js"))
  },
  {
    path: "/product-magento/mass-edit",
    component: lazy(() => import(/* webpackChunkName: "productWooCommerceRoutes" */ "../../views/product-magento/MassEdit/index.js")),
    auth: "list-product"
  }
];
