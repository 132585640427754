import React, {useState, useEffect} from "../../../../__snowpack__/pkg/react.js";
import {Form, Input, Modal, message} from "../../../../__snowpack__/pkg/antd.js";
import styled from "../../../../__snowpack__/pkg/styled-components.js";
import {axios} from "../../../lib/axios.js";
import {useIntl} from "../../../../__snowpack__/pkg/react-intl.js";
import {useWrapSync} from "../../../lib/useWrapSync.js";
import {IconCustomer2} from "../../../../__snowpack__/pkg/@genieui-react/icon.js";
export const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%#?&~_\-+=`:;"\'<>,\/|\\*^(){}\[\].])[A-Za-z\d@$!%#?&~_\-+=`:;"\'<>,\/|\\*^(){}\[\].]{8,20}$/;
export const useChangePasswordModal = () => {
  const [visible, setVisible] = useState(false);
  return {
    changePasswordModalVisible: visible,
    setChangePasswordModalVisible: setVisible
  };
};
export const ChangePasswordModal = (props) => {
  const {formatMessage} = useIntl();
  const [form] = Form.useForm();
  const {
    wrapSync: wrapSubmitHandler,
    loading: submitHandlerLoading
  } = useWrapSync();
  const submitHandler = wrapSubmitHandler(async () => {
    try {
      const values2 = form.getFieldsValue();
      form.setFieldsValue({
        password: values2.password?.trim()
      });
      await form.validateFields();
    } catch (error) {
      return;
    }
    const values = form.getFieldsValue();
    await axios.post("/saas/merchant/staff-change-password", {
      ...values
    });
    message.success(formatMessage({id: "UpdateSuccess"}));
    props.onChange && props.onChange();
  });
  useEffect(() => {
    if (props.visible) {
      form.resetFields();
    }
  }, [props.visible]);
  return /* @__PURE__ */ React.createElement(ModalWrapper, {
    title: formatMessage({id: "changePassword"}),
    onOk: () => {
      submitHandler();
    },
    confirmLoading: submitHandlerLoading,
    ...props
  }, /* @__PURE__ */ React.createElement(Form, {
    layout: "vertical",
    form
  }, /* @__PURE__ */ React.createElement(Form.Item, {
    name: "oldPassword",
    label: formatMessage({id: "oldPassword"}),
    rules: [
      {
        pattern: passwordRegex,
        message: formatMessage({id: "passworderror"})
      },
      {
        required: true,
        message: formatMessage({id: "pwdErrorInfo"})
      }
    ]
  }, /* @__PURE__ */ React.createElement(Input.Password, {
    prefix: /* @__PURE__ */ React.createElement(IconCustomer2, {
      primaryColor: "#606c80",
      style: {width: 16}
    })
  })), /* @__PURE__ */ React.createElement(Form.Item, {
    name: "newPassword",
    label: formatMessage({id: "newPassword"}),
    rules: [
      {
        pattern: passwordRegex,
        message: formatMessage({id: "passworderror"})
      },
      {
        required: true,
        message: formatMessage({id: "pwdErrorInfo"})
      }
    ]
  }, /* @__PURE__ */ React.createElement(Input.Password, {
    prefix: /* @__PURE__ */ React.createElement(IconCustomer2, {
      primaryColor: "#606c80",
      style: {width: 16}
    })
  })), /* @__PURE__ */ React.createElement(Form.Item, {
    name: "confirmPassword",
    label: formatMessage({id: "confirmedNewPassword"}),
    rules: [
      {
        pattern: passwordRegex,
        message: formatMessage({id: "passworderror"})
      },
      {
        required: true,
        message: formatMessage({id: "pwdErrorInfo"})
      }
    ]
  }, /* @__PURE__ */ React.createElement(Input.Password, {
    prefix: /* @__PURE__ */ React.createElement(IconCustomer2, {
      primaryColor: "#606c80",
      style: {width: 16}
    })
  }))));
};
const ModalWrapper = styled(Modal)`
  .ant-form-item {
    margin-bottom: 8px;
  }
`;
