import React from "../../../../__snowpack__/pkg/react.js";
export const IconMenuInventory = React.memo((props) => {
  return /* @__PURE__ */ React.createElement("svg", {
    xmlns: "http://www.w3.org/2000/svg",
    width: "18",
    height: "18",
    viewBox: "0 0 18 18",
    ...props
  }, /* @__PURE__ */ React.createElement("g", {
    fill: "none",
    fillRule: "evenodd",
    stroke: "none",
    strokeWidth: "1"
  }, /* @__PURE__ */ React.createElement("g", {
    transform: "translate(-12 -232)"
  }, /* @__PURE__ */ React.createElement("g", {
    transform: "translate(0 220)"
  }, /* @__PURE__ */ React.createElement("g", {
    transform: "translate(12 12)"
  }, /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", {
    d: "M0 0H18V18H0z"
  }), /* @__PURE__ */ React.createElement("path", {
    fill: "currentColor",
    fillRule: "nonzero",
    d: "M9 17.182a.713.713 0 00.46-.154l6.482-3.61c.255-.154.396-.398.422-.73V5.312c-.026-.332-.167-.576-.422-.73L9.46.973A.713.713 0 009 .818a.713.713 0 00-.46.154l-6.482 3.61c-.255.154-.396.398-.422.73v7.376c.026.332.167.576.422.73l6.482 3.61a.713.713 0 00.46.154zm3.26-10.948L7.466 3.43 9 2.585l4.87 2.727-1.61.922zM9 8.04L4.13 5.312l1.648-.96 4.833 2.804L9 8.04zm-.805 6.952l-4.91-2.804V6.695l4.91 2.766v5.531zm1.61 0V9.461l4.91-2.766v5.493l-4.91 2.804z"
  })))))));
});
