import {lazy} from "../../lib/lazy.js";
export const warehouseManagementRoutes = [
  {
    path: "/warehouse-management",
    auth: "warehouse-management",
    component: lazy(() => import(/* webpackChunkName: "warehouseManagementRoutes" */ "../../views/warehouse-management/index.js")),
    meta: {
      openKeys: ["menu-sub-warehouse"]
    }
  }
];
