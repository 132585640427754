import {useMemo, useState} from "../../__snowpack__/pkg/react.js";
import {createModel} from "../../__snowpack__/pkg/hox.js";
import axios from "../utils/request/index.js";
import {handleError} from "../lib/errorHandler.js";
import {useRequest} from "../../__snowpack__/pkg/ahooks.js";
import {request} from "../utils/request/index.js";
import {ROUND_ICON_MAP} from "../components/ChannelIcon.js";
import {uniqBy} from "../../__snowpack__/pkg/lodash.js";
export const useShops = () => {
  const [data, setData] = useState([]);
  const [cnscData, setCnscData] = useState([]);
  const [specialShops, setSpecialShops] = useState([]);
  const [normalShops, setNormalShops] = useState([]);
  const [globalMap, setGlobalMap] = useState({});
  const [globalCountryMap, setGlobalCountryMap] = useState({});
  const [externalShopMap, setExternalShopMap] = useState({});
  const [cnscCountryList, setCnscCountryList] = useState([]);
  const [dataMap, setDataMap] = useState({});
  const [simpleShops, setSimpleShops] = useState({});
  const [loading, setLoading] = useState(false);
  const fetchData = async (pageInfo, query) => {
    setLoading(true);
    try {
      const countryListStr = localStorage.getItem("countryList");
      let countryList = [];
      if (countryListStr) {
        countryList = JSON.parse(countryListStr);
      }
      const data2 = await axios.post(pageInfo?.url || "/infra/integration/my/search", {
        application: "SYS_ERP",
        integrationType: query?.integrationType || "MARKETPLACE",
        authorizationStatus: query?.connectStatus,
        integrationMarketplaceList: query?.selectedChannel ? [query?.selectedChannel] : query?.channelList || null,
        countryCodeList: query?.countryCode ? [query?.countryCode] : null
      });
      let shopDataMap = {};
      let gDataMap = {};
      let gCountryMap = {};
      let externalMap = {};
      let content = data2.map((i) => {
        let countryInfo = countryList.find((j) => j.country == i.countryCode);
        let newInfo = {
          shopId: i.id,
          id: i.id,
          externalShopId: i.externalShopId,
          shopName: i.name,
          allName: ["CNSC", "TPF"].includes(i.externalShopType) ? `(${i.externalShopType == "TPF" ? "3PF" : i.externalShopType})${i.name}` : i.name,
          channel: i.integrationMarketplace,
          channelId: i.integrationMarketplace,
          countryCode: i.countryCode,
          country: i.countryCodeName,
          channelName: i.integrationMarketplaceName,
          connectStatus: i.authorizationStatus,
          currencyCode: i.currencyCode || countryInfo?.currencyCode || "IDR",
          currency: i.currencySymbol || countryInfo?.currencySymbol || "IDR",
          logoUrl: ROUND_ICON_MAP[i.integrationMarketplace] || ROUND_ICON_MAP.OTHER,
          externalShopType: i.externalShopType,
          externalMerchantName: i.externalMerchantName,
          externalMerchantId: i.externalMerchantId,
          integrationMarketplace: i.integrationMarketplace,
          multiWarehouse: i.multiWarehouse,
          shopTypeName: i.externalShopType == "TPF" ? "3PF" : i.externalShopType
        };
        externalMap[i.externalShopId] = newInfo;
        shopDataMap[newInfo.id] = newInfo;
        if (newInfo.externalMerchantId) {
          gDataMap[newInfo.externalMerchantId] = newInfo.externalMerchantName;
          if (!gCountryMap[newInfo.externalMerchantId]) {
            gCountryMap[newInfo.externalMerchantId] = [newInfo.countryCode];
          } else {
            gCountryMap[newInfo.externalMerchantId].push(newInfo.countryCode);
          }
        }
        return newInfo;
      });
      setData(content);
      setDataMap(shopDataMap);
      setGlobalMap(gDataMap);
      setGlobalCountryMap(gCountryMap);
      setExternalShopMap(externalMap);
      let newList = content.filter((i) => ["CNSC", "TPF"].includes(i.externalShopType))?.map((i) => {
        return {
          ...i,
          id: i.externalMerchantId,
          shopId: i.externalMerchantId,
          shopName: i.externalMerchantName
        };
      });
      let noCnscShops = content.filter((i) => !["CNSC", "TPF"].includes(i.externalShopType));
      let merchantList = uniqBy(newList, "id");
      let sShops = content?.filter((i) => i.multiWarehouse || ["CNSC", "TPF"].includes(i.externalShopType));
      setSpecialShops(sShops);
      let nShops = content?.filter((i) => !["CNSC", "TPF"].includes(i.externalShopType));
      let mShops = content?.filter((i) => !i.multiWarehouse && !["CNSC", "TPF"].includes(i.externalShopType));
      setNormalShops(nShops);
      setCnscData(merchantList);
      setSimpleShops(mShops);
      return {
        shops: content,
        specialShops: sShops,
        normalShops: nShops,
        cnscData,
        globalMap: gDataMap,
        externalShopMap: externalMap,
        simpleShops: mShops,
        globalCountryMap: gCountryMap,
        noCnscShops: [
          ...noCnscShops,
          ...merchantList?.map((i) => ({...i, shopId: `${i.integrationMarketplace}-${i.id}`}))
        ],
        shopsPageInfo: {
          page: 1,
          pageSize: pageInfo?.pageSize,
          pageTotal: data2.length
        }
      };
    } catch (error) {
      handleError(error);
      return {
        noCnscShops: data,
        shops: data,
        normalShops,
        specialShops,
        externalShopMap,
        simpleShops,
        globalCountryMap,
        globalMap,
        cnscData,
        shopsPageInfo: pageInfo
      };
    } finally {
      setLoading(false);
    }
  };
  return {
    shopsLoading: loading,
    shops: data,
    specialShops,
    simpleShops,
    cnscCountryList,
    cnscData,
    externalShopMap,
    normalShops,
    globalMap,
    globalCountryMap,
    setShops: setData,
    shopDataMap: dataMap,
    fetchShops: fetchData
  };
};
export const useShopSync = () => {
  const [loading, setLoading] = useState(false);
  const syncStore = async (shopId) => {
    try {
      let {data} = await axios.post(`/saas/shop/${shopId}/sync-all`);
      return data?.result || false;
    } catch (error) {
      handleError(error);
      return false;
    } finally {
      setLoading(false);
    }
  };
  return {
    loading,
    syncStore
  };
};
export const useMySearchShops = () => {
  const {
    data = [],
    loading,
    run,
    runAsync
  } = useRequest(() => request.post("/infra/integration/my/search", {
    application: "SYS_ERP",
    authorizationStatus: "CONNECTED",
    integrationType: "MARKETPLACE"
  }), {manual: true});
  const limitData = useMemo(() => {
    return data?.filter((item) => {
      return ["GINEE_POS", "MANUAL_ID"].includes(item.integrationMarketplace);
    });
  }, [data]);
  const filterData = useMemo(() => {
    return data?.filter((item) => {
      return !["GINEE_POS", "MANUAL_ID"].includes(item.integrationMarketplace);
    });
  }, [data]);
  return {
    data,
    limitData,
    filterData,
    loading,
    run,
    runAsync
  };
};
export const useShopsModel = createModel(useShops);
export const useShopSyncModel = createModel(useShopSync);
export const useMySearchShopsModal = createModel(useMySearchShops);
