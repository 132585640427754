import { c as createCommonjsModule, g as getDefaultExportFromCjs } from '../../../common/_commonjsHelpers-64249c85.js';
import { i as interopRequireDefault } from '../../../common/interopRequireDefault-6e0c2256.js';
import { _ as _extends_1 } from '../../../common/extends-0409b284.js';

var id_ID = createCommonjsModule(function (module, exports) {

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  // Options.jsx
  items_per_page: '/ halaman',
  jump_to: 'Menuju',
  jump_to_confirm: 'konfirmasi',
  page: 'Halaman',
  // Pagination.jsx
  prev_page: 'Halaman Sebelumnya',
  next_page: 'Halaman Berikutnya',
  prev_5: '5 Halaman Sebelumnya',
  next_5: '5 Halaman Berikutnya',
  prev_3: '3 Halaman Sebelumnya',
  next_3: '3 Halaman Berikutnya',
  page_size: 'ukuran halaman'
};
exports.default = _default;
});

var id_ID$1 = createCommonjsModule(function (module, exports) {

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var locale = {
  locale: 'id_ID',
  today: 'Hari ini',
  now: 'Sekarang',
  backToToday: 'Kembali ke hari ini',
  ok: 'Baik',
  clear: 'Bersih',
  month: 'Bulan',
  year: 'Tahun',
  timeSelect: 'pilih waktu',
  dateSelect: 'pilih tanggal',
  weekSelect: 'Pilih satu minggu',
  monthSelect: 'Pilih satu bulan',
  yearSelect: 'Pilih satu tahun',
  decadeSelect: 'Pilih satu dekade',
  yearFormat: 'YYYY',
  dateFormat: 'D/M/YYYY',
  dayFormat: 'D',
  dateTimeFormat: 'D/M/YYYY HH:mm:ss',
  monthBeforeYear: true,
  previousMonth: 'Bulan sebelumnya (PageUp)',
  nextMonth: 'Bulan selanjutnya (PageDown)',
  previousYear: 'Tahun lalu (Control + kiri)',
  nextYear: 'Tahun selanjutnya (Kontrol + kanan)',
  previousDecade: 'Dekade terakhir',
  nextDecade: 'Dekade berikutnya',
  previousCentury: 'Abad terakhir',
  nextCentury: 'Abad berikutnya'
};
var _default = locale;
exports.default = _default;
});

var id_ID$2 = createCommonjsModule(function (module, exports) {

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var locale = {
  placeholder: 'Pilih waktu'
};
var _default = locale;
exports["default"] = _default;
});

var id_ID$3 = createCommonjsModule(function (module, exports) {



Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _extends2 = interopRequireDefault(_extends_1);

var _id_ID = interopRequireDefault(id_ID$1);

var _id_ID2 = interopRequireDefault(id_ID$2);

// Merge into a locale object
var locale = {
  lang: (0, _extends2["default"])({
    placeholder: 'Pilih tanggal',
    rangePlaceholder: ['Mulai tanggal', 'Tanggal akhir']
  }, _id_ID["default"]),
  timePickerLocale: (0, _extends2["default"])({}, _id_ID2["default"])
}; // All settings at:
// https://github.com/ant-design/ant-design/blob/master/components/date-picker/locale/example.json

var _default = locale;
exports["default"] = _default;
});

var id_ID$4 = createCommonjsModule(function (module, exports) {



Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _id_ID = interopRequireDefault(id_ID$3);

var _default = _id_ID["default"];
exports["default"] = _default;
});

var id_ID$5 = createCommonjsModule(function (module, exports) {



Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _id_ID = interopRequireDefault(id_ID);

var _id_ID2 = interopRequireDefault(id_ID$3);

var _id_ID3 = interopRequireDefault(id_ID$2);

var _id_ID4 = interopRequireDefault(id_ID$4);

var localeValues = {
  locale: 'id',
  Pagination: _id_ID["default"],
  DatePicker: _id_ID2["default"],
  TimePicker: _id_ID3["default"],
  Calendar: _id_ID4["default"],
  Table: {
    filterTitle: 'Saring',
    filterConfirm: 'OK',
    filterReset: 'Hapus',
    selectAll: 'Pilih semua di halaman ini',
    selectInvert: 'Balikkan pilihan di halaman ini',
    sortTitle: 'Urutkan'
  },
  Modal: {
    okText: 'OK',
    cancelText: 'Batal',
    justOkText: 'OK'
  },
  Popconfirm: {
    okText: 'OK',
    cancelText: 'Batal'
  },
  Transfer: {
    titles: ['', ''],
    searchPlaceholder: 'Cari',
    itemUnit: 'item',
    itemsUnit: 'item'
  },
  Upload: {
    uploading: 'Mengunggah...',
    removeFile: 'Hapus file',
    uploadError: 'Kesalahan pengunggahan',
    previewFile: 'File pratinjau',
    downloadFile: 'Unduh berkas'
  },
  Empty: {
    description: 'Tidak ada data'
  }
};
var _default = localeValues;
exports["default"] = _default;
});

var __pika_web_default_export_for_treeshaking__ = /*@__PURE__*/getDefaultExportFromCjs(id_ID$5);

export default __pika_web_default_export_for_treeshaking__;
