import React from "../../../../__snowpack__/pkg/react.js";
export const IconMenuCustomer = React.memo((props) => {
  return /* @__PURE__ */ React.createElement("svg", {
    xmlns: "http://www.w3.org/2000/svg",
    width: "18",
    height: "18",
    viewBox: "0 0 18 18",
    ...props
  }, /* @__PURE__ */ React.createElement("g", {
    fill: "none",
    fillRule: "evenodd",
    stroke: "none",
    strokeWidth: "1"
  }, /* @__PURE__ */ React.createElement("g", {
    transform: "translate(-12 -358)"
  }, /* @__PURE__ */ React.createElement("g", {
    transform: "translate(0 346)"
  }, /* @__PURE__ */ React.createElement("g", {
    transform: "translate(12 12)"
  }, /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", {
    d: "M0 0H18V18H0z"
  }), /* @__PURE__ */ React.createElement("path", {
    fill: "currentColor",
    fillRule: "nonzero",
    d: "M12.677 9.41c.817-.028 1.493-.33 2.03-.907.536-.577.804-1.297.804-2.162s-.268-1.593-.805-2.183c-.536-.59-1.212-.885-2.03-.885-.816 0-1.493.295-2.03.885-.535.59-.803 1.318-.803 2.183 0 .865.268 1.585.804 2.162s1.213.879 2.03.906zm-7.354 0c.817-.028 1.494-.33 2.03-.907.536-.577.804-1.297.804-2.162s-.268-1.593-.804-2.183c-.536-.59-1.213-.885-2.03-.885-.817 0-1.493.295-2.03.885-.536.59-.804 1.318-.804 2.183 0 .865.268 1.585.805 2.162.536.577 1.212.879 2.03.906zm7.354-1.319c-.46 0-.843-.171-1.15-.515-.306-.343-.459-.761-.459-1.256 0-.494.153-.906.46-1.235.306-.33.689-.494 1.149-.494.46 0 .842.164 1.149.494.306.33.46.741.46 1.235 0 .495-.154.913-.46 1.256-.307.344-.69.515-1.15.515zm-7.354 0c-.46 0-.842-.171-1.149-.515-.306-.343-.46-.761-.46-1.256 0-.494.154-.906.46-1.235.307-.33.69-.494 1.15-.494.459 0 .842.164 1.148.494.307.33.46.741.46 1.235 0 .495-.153.913-.46 1.256-.306.344-.689.515-1.149.515zM18 15.545v-2.388c-.128-.961-.798-1.682-2.01-2.162-1.213-.48-2.318-.721-3.313-.721-.511 0-1.098.075-1.762.226A8.664 8.664 0 009 11.18a8.664 8.664 0 00-1.915-.68c-.664-.15-1.251-.226-1.762-.226-.995 0-2.1.24-3.312.72C.798 11.476.128 12.197 0 13.158v2.388h18zm-1.226-1.317h-6.127v-1.071a1.785 1.785 0 00-.421-1.071 8.582 8.582 0 011.168-.35 5.75 5.75 0 011.283-.144c1.046.055 1.978.26 2.795.618.817.357 1.251.672 1.302.947v1.07zm-7.353 0H1.226v-1.071c.05-.275.485-.59 1.302-.947.817-.357 1.749-.563 2.795-.618 1.047.055 1.98.26 2.796.618.817.357 1.251.672 1.302.947v1.07z"
  })))))));
});
