import {CheckCircleFilled, CheckOutlined, CloseCircleFilled, CloseOutlined, LoadingOutlined} from "../../../../__snowpack__/pkg/@ant-design/icons.js";
import React, {useEffect, useMemo, useState} from "../../../../__snowpack__/pkg/react.js";
import styled from "../../../../__snowpack__/pkg/styled-components.js";
import sync from "../assets/sync.svg";
import {Popover, Space, Table} from "../../../../__snowpack__/pkg/antd-v5.js";
import {FormattedMessage, useIntl} from "../../../../__snowpack__/pkg/react-intl.js";
import {useRequest} from "../../../../__snowpack__/pkg/ahooks.js";
import {mockRequest} from "../../../utils/request/index.js";
import {getSameUuid} from "../../../lib/uuid.js";
import moment from "../../../../__snowpack__/pkg/moment-timezone.js";
import {handleError} from "../../../lib/errorHandler.js";
import {useTaskCenterModal} from "../../../entities/task-center/useTaskCenter.js";
import {getIamOrigin} from "../../../lib/util.js";
var SyncDataType;
(function(SyncDataType2) {
  SyncDataType2["ORDER_HOT_NORMAL"] = "ORDER_HOT_NORMAL";
  SyncDataType2["ORDER_HISTORY_NORMAL"] = "ORDER_HISTORY_NORMAL";
  SyncDataType2["PRODUCT_INFORMATION"] = "PRODUCT_INFORMATION";
})(SyncDataType || (SyncDataType = {}));
var TaskResult;
(function(TaskResult2) {
  TaskResult2["PROCESSING"] = "PROCESSING";
  TaskResult2["SUCCEEDED"] = "SUCCEEDED";
  TaskResult2["PARTIAL_SUCCEEDED"] = "PARTIAL_SUCCEEDED";
  TaskResult2["FAILED"] = "FAILED";
})(TaskResult || (TaskResult = {}));
const TaskStatusMap = {
  0: {
    color: "#F2F4F7",
    text: /* @__PURE__ */ React.createElement(FormattedMessage, {
      id: "sync"
    }),
    icon: () => /* @__PURE__ */ React.createElement("div", {
      className: "icon-wrapper",
      style: {background: "#F2F4F7"}
    }, /* @__PURE__ */ React.createElement("div", {
      className: "icon-wrapper-2"
    }, /* @__PURE__ */ React.createElement("img", {
      src: sync,
      alt: "sync"
    })))
  },
  FAILED: {
    color: "#FFCDD1",
    text: /* @__PURE__ */ React.createElement(FormattedMessage, {
      id: "syncFailed"
    }),
    icon: () => /* @__PURE__ */ React.createElement("div", {
      className: "icon-wrapper",
      style: {background: "#E7192C"}
    }, /* @__PURE__ */ React.createElement("div", {
      className: "icon-wrapper-2"
    }, /* @__PURE__ */ React.createElement(CloseOutlined, {
      style: {color: "#E7192C"}
    })))
  },
  SUCCEEDED: {
    color: "#D5F6E1",
    text: /* @__PURE__ */ React.createElement(FormattedMessage, {
      id: "syncSuccessfully"
    }),
    icon: () => /* @__PURE__ */ React.createElement("div", {
      className: "icon-wrapper",
      style: {background: "#66BE75"}
    }, /* @__PURE__ */ React.createElement("div", {
      className: "icon-wrapper-2"
    }, /* @__PURE__ */ React.createElement(CheckOutlined, {
      style: {color: "#66BE75"}
    })))
  },
  PROCESSING: {
    color: "#DDDDFF",
    text: /* @__PURE__ */ React.createElement(FormattedMessage, {
      id: "globalsSynchronizing"
    }),
    icon: (rateOfProcess) => /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement("div", {
      className: "icon-wrapper",
      style: {background: "#DDDDFF"}
    }, /* @__PURE__ */ React.createElement("div", {
      className: "icon-wrapper-2"
    }, /* @__PURE__ */ React.createElement(LoadingOutlined, {
      style: {color: "#6020FF", fontSize: 24}
    }), /* @__PURE__ */ React.createElement("div", {
      style: {position: "absolute", color: "#606C80"}
    }, rateOfProcess))))
  },
  PARTIAL_SUCCEEDED: {
    color: "#D5F6E1",
    text: /* @__PURE__ */ React.createElement(FormattedMessage, {
      id: "partialSuccess"
    }),
    icon: () => /* @__PURE__ */ React.createElement("div", {
      className: "icon-wrapper",
      style: {background: "#66BE75"}
    }, /* @__PURE__ */ React.createElement("div", {
      className: "icon-wrapper-2"
    }, /* @__PURE__ */ React.createElement(CheckOutlined, {
      style: {color: "#66BE75"}
    })))
  }
};
export const TaskCenter = () => {
  const {taskIdList, isInit} = useTaskCenterModal();
  const [repeatTimes, setRepeatTimes] = useState(0);
  const {data, run, cancel} = useRequest(async (taskIdList2) => {
    try {
      if (taskIdList2?.length === 0 || !taskIdList2) {
        return;
      }
      const times = repeatTimes + 1;
      if (times > 10) {
        return data;
      }
      setRepeatTimes(times);
      const {data: result} = await mockRequest.post(`/saas/shop/get-batch-sync-result`, {
        clientId: getSameUuid(),
        parentIds: taskIdList2
      });
      return result?.data?.results.reverse().sort((a, b) => {
        return new Date(b.operationTime).getTime() - new Date(a.operationTime).getTime();
      });
    } catch (error) {
      cancel();
      handleError(error);
    }
  }, {
    pollingInterval: 4e3,
    pollingWhenHidden: false,
    pollingErrorRetryCount: 3
  });
  const config = useMemo(() => {
    if (data?.length > 0) {
      if (TaskStatusMap[data[0].taskResult]) {
        return {...TaskStatusMap[data[0].taskResult], rateOfProcess: data[0].rateOfProcess};
      } else {
        return {...TaskStatusMap["PARTIAL_SUCCEEDED"], rateOfProcess: data[0].rateOfProcess};
      }
    }
    return TaskStatusMap[0];
  }, [data]);
  useEffect(() => {
    if (data && data.length > 0 && data[0].taskResult !== "PROCESSING") {
      cancel();
    }
  }, [data]);
  useEffect(() => {
    if (taskIdList && taskIdList?.length > 0) {
      setRepeatTimes(0);
      run(taskIdList);
    }
    return;
  }, [taskIdList]);
  return /* @__PURE__ */ React.createElement(Popover, {
    content: /* @__PURE__ */ React.createElement(TaskList, {
      data: data || []
    }),
    onOpenChange: (visible) => {
      if (visible) {
        run(taskIdList);
      }
    }
  }, /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement(TaskCenterInfo, {
    color: config?.color,
    text: config?.text,
    icon: config?.icon && config?.icon(config?.rateOfProcess)
  })));
};
const TaskList = ({data}) => {
  const {formatMessage} = useIntl();
  const goHistorySync = () => {
    const iamOrigin = getIamOrigin();
    window.open(`${iamOrigin}/integration/sync`);
  };
  const columns = [
    {
      title: formatMessage({id: "taskType"}),
      dataIndex: "syncDataType",
      key: "syncDataType",
      render: (text) => {
        const typeMap = {
          ORDER_HOT_NORMAL: "manualSyncOrder",
          ORDER_HISTORY_NORMAL: "syncHistoricalOrders",
          PRODUCT_INFORMATION: "manualSyncProduct",
          ORDER_BOOKING: "manualSyncOrder"
        };
        return formatMessage({id: typeMap[text]});
      }
    },
    {
      title: formatMessage({id: "taskIds"}),
      dataIndex: "parentId",
      key: "parentId",
      width: 200
    },
    {
      title: formatMessage({id: "syncProgress"}),
      dataIndex: "rateOfProcess",
      key: "rateOfProcess",
      render: (text, record) => {
        if (text === 100) {
          return record.taskResult === "FAILED" ? /* @__PURE__ */ React.createElement("div", {
            style: {color: "#E7192C"}
          }, /* @__PURE__ */ React.createElement(CloseCircleFilled, {
            style: {color: "#E7192C"}
          }), " ", formatMessage({id: "failed"})) : record?.taskResult === "PARTIAL_SUCCEEDED" ? /* @__PURE__ */ React.createElement("div", {
            style: {color: "#66BE75"}
          }, /* @__PURE__ */ React.createElement(CheckCircleFilled, {
            style: {color: "#66BE75"}
          }), " ", formatMessage({id: "partialSuccess"})) : /* @__PURE__ */ React.createElement("div", {
            style: {color: "#66BE75"}
          }, /* @__PURE__ */ React.createElement(CheckCircleFilled, {
            style: {color: "#66BE75"}
          }), " ", formatMessage({id: "success"}));
        }
        return `${text}%`;
      }
    },
    {
      title: formatMessage({id: "synchronizationTime"}),
      dataIndex: "operationTime",
      key: "operationTime",
      render: (text, record) => {
        return /* @__PURE__ */ React.createElement(React.Fragment, null, moment(text).format("DD-MM-YYYY HH:mm"));
      }
    },
    {
      title: formatMessage({id: "action73"}),
      key: "action",
      render: (_2, record) => /* @__PURE__ */ React.createElement(Space, {
        size: "middle"
      }, /* @__PURE__ */ React.createElement("a", {
        onClick: goHistorySync
      }, formatMessage({id: "Viemore"})))
    }
  ];
  return /* @__PURE__ */ React.createElement(Table, {
    locale: {
      emptyText: formatMessage({id: "noManualSyncTask"})
    },
    columns,
    size: "middle",
    dataSource: data,
    pagination: false
  });
};
const TaskCenterInfo = ({color, text, icon}) => {
  return /* @__PURE__ */ React.createElement(StyledTaskCenter, null, icon, /* @__PURE__ */ React.createElement("div", {
    className: "text",
    style: {background: color}
  }, /* @__PURE__ */ React.createElement("span", null, text)));
};
const StyledTaskCenter = styled.div`
  height: 48px;
  display: flex;
  align-items: center;
  position: relative;
  justify-content: center;
  cursor: pointer;
  .icon-wrapper {
    background-color: #f2f4f7;
    width: 26px;
    height: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    position: absolute;
    left: 0;
    .icon-wrapper-2 {
      width: 20px;
      height: 20px;
      display: flex;
      background-color: #fff;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      img {
        width: 12px;
        height: 12px;
      }
    }
  }
  .process {
    position: absolute;
    /* left: 4px; */
    height: 48px;
    display: flex;
    align-items: center;
  }
  .text {
    padding-right: 12px;
    padding-left: 32px;
    font-weight: 400;
    font-size: 12px;
    color: #606c80;
    line-height: 18px;
    background-color: #f2f4f7;
    min-width: 65px;
    display: flex;
    justify-content: flex-end;
    border-radius: 14px;
  }
`;
