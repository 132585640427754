import React from "../../../__snowpack__/pkg/react.js";
import {notification, Modal} from "../../../__snowpack__/pkg/antd.js";
import {isAxiosError} from "../../lib/errorHandler.js";
import Cookies from "../../../__snowpack__/pkg/js-cookie.js";
import {purchasePackage} from "../../lib/util.js";
import {toAccounts} from "../../views/login/index.js";
import {localeMessage} from "../../lib/i18n.js";
import {SessionHelper} from "../../../__snowpack__/pkg/@genie/lib-iam-utils.js";
var ErrorType;
(function(ErrorType2) {
  ErrorType2[ErrorType2["NETWORK"] = 0] = "NETWORK";
  ErrorType2[ErrorType2["CUSTOM"] = 1] = "CUSTOM";
  ErrorType2[ErrorType2["OTHER"] = 2] = "OTHER";
})(ErrorType || (ErrorType = {}));
let language = localStorage.getItem("language") || "id";
const TimedOutLanguage = {
  zh: "\u8BF7\u6C42\u8D85\u65F6\uFF0C\u8BF7\u68C0\u67E5\u7F51\u7EDC\u540E\u91CD\u8BD5",
  en: "Request timed out, please check the network and try again.",
  id: "Waktu permintaan habis, harap periksa jaringan dan coba lagi.",
  vn: "Y\xEAu c\u1EA7u \u0111\xE3 h\u1EBFt th\u1EDDi gian ch\u1EDD, vui l\xF2ng ki\u1EC3m tra m\u1EA1ng v\xE0 th\u1EED l\u1EA1i.",
  th: "\u0E04\u0E33\u0E02\u0E2D\u0E2B\u0E21\u0E14\u0E40\u0E27\u0E25\u0E32 \u0E42\u0E1B\u0E23\u0E14\u0E15\u0E23\u0E27\u0E08\u0E2A\u0E2D\u0E1A\u0E40\u0E04\u0E23\u0E37\u0E2D\u0E02\u0E48\u0E32\u0E22\u0E41\u0E25\u0E49\u0E27\u0E25\u0E2D\u0E07\u0E2D\u0E35\u0E01\u0E04\u0E23\u0E31\u0E49\u0E07"
};
export const formatMessage = (info) => {
  let local = localStorage.getItem("language") || "id";
  return localeMessage[local][info.id];
};
const messageShowTime = 3e3;
let messageIndex = null;
export const errorTipFunction = (errorMessage, errorTransactionId, force) => {
  if (!messageIndex && !localStorage.notificationError || force) {
    localStorage.setItem("notificationError", "true");
    if (errorMessage?.toLocaleUpperCase() === "RESOURCE_REACH_LIMIT_STAFF_LOGIN") {
      Modal.error({
        content: formatMessage({id: "duringEffectivePeriodOfThePackage"}),
        onOk: async () => {
          await Cookies.remove("token");
          SessionHelper.removeSessionToken();
          toAccounts();
        }
      });
      return;
    } else if (errorMessage === "Access denied due to identity or access management certification failed" || errorMessage === "ACCESS_DENIED") {
      Modal.confirm({
        title: formatMessage({id: "hint"}),
        content: /* @__PURE__ */ React.createElement("p", null, formatMessage({id: "becauseTheNumberOfStoresExceeds"}), /* @__PURE__ */ React.createElement("p", {
          style: {
            color: "#333",
            textAlign: "right",
            paddingTop: 10,
            margin: 0,
            opacity: 0.5
          }
        }, errorTransactionId)),
        okText: formatMessage({id: "confirm"}),
        cancelText: formatMessage({id: "subscriptionPackage"}),
        onCancel() {
          purchasePackage();
        }
      });
    } else if (errorMessage && errorMessage !== "RBAC_MDLW_001:FS ID is not associated with Shop ID or Warehouse ID") {
      notification.error({
        message: "Tips",
        description: /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement("div", {
          style: {wordBreak: "break-all"}
        }, errorMessage || TimedOutLanguage[language]), /* @__PURE__ */ React.createElement("div", {
          style: {
            textAlign: "right",
            marginTop: 8,
            marginBottom: 8,
            opacity: 0.5,
            color: "#333",
            fontSize: "12px"
          }
        }, errorTransactionId)),
        duration: 4
      });
    }
    messageIndex = setTimeout(() => {
      messageIndex = null;
      localStorage.removeItem("notificationError");
    }, messageShowTime);
  }
};
export function isCustomError(object) {
  return object.code !== void 0;
}
const getErrorType = (error) => {
  let errorType = 2;
  if (isAxiosError(error)) {
    errorType = 0;
  }
  if (isCustomError(error)) {
    errorType = 1;
  }
  return errorType;
};
export function handleError(error) {
  const errorType = getErrorType(error);
  console.log("error:", error, errorType);
  let handleFunc = null;
  switch (errorType) {
    case 0:
      handleFunc = handleNetWokeError;
      break;
    case 1:
      handleFunc = handleCustomError;
      break;
    default:
      handleFunc = handleInterFaceError;
      break;
  }
  handleFunc(error);
}
const handleNetWokeError = (error) => {
  if (error.response) {
    const response = error.response;
    errorTipFunction(response.data.message, response.data.transactionId);
  } else {
    notification.open({
      key: "ERROR",
      message: "error",
      description: TimedOutLanguage[language]
    });
  }
};
const handleCustomError = (error) => {
  const errorCode = error.code.toLocaleUpperCase();
  if (["TOKEN_INVALID", "TOKEN_EXPIRED", "IAM_LOGOUT"].includes(errorCode || "")) {
    SessionHelper.sendDefaultEvent(errorCode);
  } else if (errorCode === "RESOURCE_REACH_LIMIT_STAFF_LOGIN" || errorCode === "ACCESS_DENIED") {
    errorTipFunction(errorCode, error.transactionId);
  } else {
    errorTipFunction(error.message, error.transactionId);
  }
};
const handleInterFaceError = (error) => {
  errorTipFunction(error.message, "FE-ERROR");
};
