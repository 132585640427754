
      function getAbsoluteUrl(relativeUrl) {
        const publicPath = __webpack_public_path__;
        let url = '';
        if (!publicPath || publicPath.indexOf('://') < 0) {
          url += window.location.protocol + '//' + window.location.host;
        }
        if (publicPath) {
          url += publicPath;
        } else {
          url += '/';
        }
        return url + relativeUrl;
      }
import * as __SNOWPACK_ENV__ from '../__snowpack__/env.js';
({ url: getAbsoluteUrl('snowpack/_dist_/index.js'), env: __SNOWPACK_ENV__ }).env = __SNOWPACK_ENV__;

import React from "../__snowpack__/pkg/react.js";
import ReactDOM from "../__snowpack__/pkg/react-dom.js";
import {BrowserRouter as Router} from "../__snowpack__/pkg/react-router-dom.js";
import {App} from "./App.js";
const ignoreErrors = ["ResizeObserver loop limit exceeded"];
window.snowpackHmrErrorOverlayIgnoreErrors = ignoreErrors;
ReactDOM.render(/* @__PURE__ */ React.createElement(Router, null, /* @__PURE__ */ React.createElement(App, null)), document.getElementById("root"));
if (undefined /* [snowpack] ({ url: getAbsoluteUrl('snowpack/_dist_/index.js'), env: __SNOWPACK_ENV__ }).hot */ ) {
  undefined /* [snowpack] ({ url: getAbsoluteUrl('snowpack/_dist_/index.js'), env: __SNOWPACK_ENV__ }).hot */ .accept();
}
