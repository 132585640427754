import NoProductIcon from "../assets/no-product-icon.png";
import {channelInfoConfig, channelNamePlaceholder} from "../assets/config/channelInfoConfig/index.js";
export const OrderStatusList = [
  {
    type: "ALL",
    eventType: "All",
    title: "globalStatus_all"
  },
  {
    type: "PENDING_PAYMENT",
    eventType: "PendingPayment",
    title: "status_pending_payment",
    color: "#FFB83BFF"
  },
  {
    type: "PAID",
    eventType: "Paid",
    title: "status_paid",
    color: "#FFB83BFF"
  },
  {
    type: "READY_TO_SHIP",
    eventType: "ReadyToShip",
    title: "status_ready_to_ship",
    color: "#FFB83BFF"
  },
  {
    type: "SHIPPING",
    eventType: "Shipping",
    title: "status_shipping",
    color: "#7ED321FF"
  },
  {
    type: "DELIVERED",
    eventType: "Delivered",
    title: "status_delivered",
    color: "#7ED321FF"
  },
  {
    type: "CANCELLED",
    eventType: "Cancelled",
    title: "status_cancelled",
    color: "#000000A6"
  },
  {
    type: "RETURNED",
    eventType: "Returned",
    title: "status_returned",
    color: "#000000A6"
  },
  {
    type: "FAILED",
    eventType: "Failed",
    title: "status_failed",
    color: "#000000A6"
  },
  {
    type: "PARTIALLY_RETURNED",
    eventType: "Returned",
    title: "partialReturn",
    color: "#000000A6"
  }
];
export const getRandom = (m, n) => {
  return Math.floor(Math.random() * (m - n) + n);
};
export const multiplePlatformTool = channelInfoConfig;
export const noChannelName = channelNamePlaceholder;
export const formatOrderStatus = (status) => OrderStatusList.find((item) => item.type === status);
export const platformLogo = (channelId, type) => {
  if (multiplePlatformTool[channelId]) {
    return channelId ? multiplePlatformTool[channelId][type ? "live" : "disabled"] : noChannelName;
  } else {
    return noChannelName;
  }
};
export const platformName = (channelId) => {
  if (multiplePlatformTool[channelId]) {
    return channelId ? multiplePlatformTool[channelId].title : "-";
  } else {
    return "-";
  }
};
export const generateQuickGuid = () => {
  return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
};
export const getRandomColor = () => {
  const r = Math.floor(Math.random() * 256);
  const g = Math.floor(Math.random() * 256);
  const b = Math.floor(Math.random() * 256);
  return `rgb(${r},${g},${b})`;
};
export const NoProductDefaultIcon = () => NoProductIcon;
