import _ from "../../../__snowpack__/pkg/lodash.js";
const BASIC_CONTACT = {
  PH: {
    phone: "+639157682896",
    email: "helloph@ginee.com",
    supportLink: "https://msng.link/o/?639157682896=vi",
    lang: "servicePH"
  },
  CN: {
    phone: "18600620917",
    email: "hellocn@ginee.com",
    supportLink: "",
    lang: "serviceCN"
  },
  TH: {
    phone: "080-892-0719",
    email: "helloth@ginee.com",
    supportLink: "https://line.me/ti/g2/N4IcnKNVJGGKjTgMoeCcSZ_R_YDTp348lL1q-Q?utm_source=invitation&utm_medium=link_copy&utm_campaign=default",
    lang: "serviceTH"
  },
  VN: {
    phone: "0383033615",
    email: "hellovn@ginee.com",
    supportLink: "https://zalo.me/g/kbboxa998",
    lang: "serviceVN"
  },
  MY: {
    phone: "601121116522",
    email: "hellomy@ginee.com",
    supportLink: "https://bit.ly/FreePlanGineeGroup",
    lang: "serviceMYandID"
  },
  ID: {
    phone: "081290518614",
    email: "helloid@ginee.com",
    supportLink: "https://t.me/gineesupport",
    lang: "serviceMYandID"
  }
};
const getBaseInfo = (countryCode) => {
  if (_.isNil(countryCode))
    return BASIC_CONTACT["ID"];
  if (BASIC_CONTACT[countryCode]) {
    return BASIC_CONTACT[countryCode];
  } else {
    return BASIC_CONTACT["ID"];
  }
};
export {BASIC_CONTACT, getBaseInfo};
