import {lazy} from "../../lib/lazy.js";
export const productLazadaChoiceRoutes = [
  {
    path: "/product-lazada-choice",
    component: lazy(() => import(/* webpackChunkName: "productLazadaChoiceRoutes" */ "../../views/product-lazada-choice/list/index.js")),
    auth: "list-product"
  },
  {
    path: "/product-lazada-choice-:action(add)",
    component: lazy(() => import(/* webpackChunkName: "productLazadaChoiceRoutes" */ "../../views/product-lazada-choice/edit/index.js")),
    auth: "list-product"
  },
  {
    path: "/product-lazada-choice:action(edit)/:id",
    component: lazy(() => import(/* webpackChunkName: "productLazadaChoiceRoutes" */ "../../views/product-lazada-choice/edit/index.js")),
    auth: "list-product"
  },
  {
    path: "/product-lazada-choice-mass-edit",
    component: lazy(() => import(/* webpackChunkName: "productLazadaChoiceRoutes" */ "../../views/product-lazada-choice/massEdit/index.js"))
  },
  {
    path: "/product-lazada-choice-mass-category",
    component: lazy(() => import(/* webpackChunkName: "productLazadaChoiceRoutes" */ "../../views/product-lazada-choice/massEditCategory/index.js"))
  }
];
