import queryString from "../../__snowpack__/pkg/query-string.js";
import {useLayoutState} from "../store/layout.js";
import Cookies from "../../__snowpack__/pkg/js-cookie.js";
import {purchasePackage} from "../lib/util.js";
import {useUpdateRoute} from "../lib/useIframe.js";
import {message} from "../../__snowpack__/pkg/antd.js";
import {useIntl} from "../../__snowpack__/pkg/react-intl.js";
window.isStart = true;
import {SessionHelper} from "../../__snowpack__/pkg/@genie/lib-iam-utils.js";
import {useIamDisplayModal} from "./useResourceInfo.js";
export const useCheckUserType = () => {
  const {fetchPermissionNewMaps, setPermissionMap, fetchActiveTrial} = useLayoutState();
  const {updateRoute} = useUpdateRoute();
  const {formatMessage} = useIntl();
  const {fetchData} = useIamDisplayModal();
  const queries = queryString.parse(location.search.slice(1));
  const nextTip = (loginStatus, shops) => {
    let permissionMapStr = window.localStorage.getItem("permissionMap");
    let permissionMap = JSON.parse(permissionMapStr || "{}");
    setPermissionMap(permissionMap);
    if ((!permissionMap || !Object.keys(permissionMap).length) && window.location.pathname !== "/resource-constraints") {
      if (SessionHelper.getSessionUserInfo()?.parentId) {
        updateRoute("sorry");
      } else {
        updateRoute("package-expired");
        message.error(formatMessage({id: "globalsPackageExpired"}));
        if (loginStatus) {
          purchasePackage();
        }
      }
    }
    if ((queries.isRegister || shops?.length <= 0) && loginStatus) {
      updateRoute("add-integration");
      return;
    }
    if (["/package-expired", "/login"].includes(window.location.pathname) && Object.keys(permissionMap).length) {
      if (permissionMap["dashboard"]) {
        window.location.href = "/";
      } else {
        window.location.href = "/welcome";
      }
    }
  };
  const goInfo = async (loginStatus, shops) => {
    return new Promise(async (resolve) => {
      let permissionMapStr = window.localStorage.getItem("permissionMap");
      let permissionMapExpires = Cookies.get("permissionMapExpires");
      if (!permissionMapStr || permissionMapStr === "{}") {
        let info = await fetchActiveTrial(nextTip, loginStatus);
        if (info) {
          fetchData(true);
          await fetchPermissionNewMaps();
        } else {
          nextTip(loginStatus, shops);
        }
        resolve(true);
      } else if (!permissionMapExpires) {
        await fetchPermissionNewMaps();
      } else {
        nextTip(loginStatus, shops);
        resolve(true);
      }
    });
  };
  const initPermission = async (loginStatus, shops) => {
    return new Promise(async (resolve) => {
      let permissionMapStr = window.localStorage.getItem("permissionMap");
      let permissionMapExpires = Cookies.get("permissionMapExpires");
      if (!permissionMapStr || permissionMapStr === "{}" || loginStatus || !permissionMapExpires) {
        await fetchPermissionNewMaps();
        await goInfo(loginStatus, shops);
        resolve(true);
      } else {
        let permissionMapStr2 = window.localStorage.getItem("permissionMap");
        let permissionMap = JSON.parse(permissionMapStr2 || "{}");
        setPermissionMap(permissionMap);
        resolve(false);
      }
    });
  };
  return {
    initPermission
  };
};
