import {SessionHelper} from "../../__snowpack__/pkg/@genie/lib-iam-utils.js";
import {find, isEmpty, uniqBy} from "../../__snowpack__/pkg/lodash.js";
import moment from "../../__snowpack__/pkg/moment-timezone.js";
export const AriaLabelMap = [
  {
    countryCode: "+86",
    countryName: "China",
    region: "CN",
    regionName: "\u4E2D\u56FD",
    currency: "CNY",
    timeZone: "GMT+8",
    timeZoneName: "Asia/Shanghai",
    timeZoneGMT: {
      "GMT+08:00": "Asia/Shanghai"
    }
  },
  {
    countryCode: "+62",
    countryName: "Indonesia",
    region: "ID",
    regionName: "\u5370\u5EA6\u5C3C\u897F\u4E9A",
    currency: "IDR",
    timeZone: "GMT+7",
    timeZoneName: "Asia/Jakarta",
    timeZoneGMT: {
      "GMT+07:00": "Asia/Pontianak",
      "GMT+08:00": "Asia/Makassar",
      "GMT+09:00": "Asia/Jayapura"
    }
  },
  {
    countryCode: "+63",
    countryName: "Philippines",
    region: "PH",
    regionName: "\u83F2\u5F8B\u5BBE",
    currency: "PHP",
    timeZone: "GMT+8",
    timeZoneName: "Asia/Manila",
    timeZoneGMT: {
      "GMT+08:00": "Asia/Manila"
    }
  },
  {
    countryCode: "+66",
    countryName: "Thailand",
    region: "TH",
    regionName: "\u6CF0\u56FD",
    currency: "THB",
    timeZone: "GMT+7",
    timeZoneName: "Asia/Bangkok",
    timeZoneGMT: {
      "GMT+07:00": "Asia/Bangkok"
    }
  },
  {
    countryCode: "+84",
    countryName: "Vietnam",
    region: "VN",
    regionName: "\u8D8A\u5357",
    currency: "VND",
    timeZone: "GMT+7",
    timeZoneName: "Asia/Ho_Chi_Minh",
    timeZoneGMT: {
      "GMT+07:00": "Asia/Ho_Chi_Minh"
    }
  },
  {
    countryCode: "+60",
    countryName: "Malaysia",
    region: "MY",
    regionName: "\u9A6C\u6765\u897F\u4E9A",
    currency: "MYR",
    timeZone: "GMT+8",
    timeZoneName: "Asia/Kuala_Lumpur",
    timeZoneGMT: {
      "GMT+08:00": "Asia/Kuala_Lumpur"
    }
  },
  {
    countryCode: "+65",
    countryName: "Singapore",
    region: "SG",
    regionName: "\u65B0\u52A0\u5761",
    currency: "SGD",
    timeZone: "GMT+8",
    timeZoneName: "Asia/Singapore",
    timeZoneGMT: {
      "GMT+08:00": "Asia/Singapore"
    }
  }
];
const tzOptions = moment.tz.names().map((name) => {
  const now = Date.now();
  const zone = moment.tz.zone(name);
  return {name, offset: zone !== null ? zone.utcOffset(now) : 0};
}).sort((a, b) => a.offset === b.offset ? a.name.localeCompare(b.name) : b.offset - a.offset).map((zone) => {
  const gmtOffset = `GMT${moment.tz(zone.name).format("Z")}`;
  const abbr = moment.tz(zone.name).format("z");
  return {
    timeZoneName: zone.name,
    timeZoneDesc: `(${gmtOffset}) ${zone.name} - ${abbr}`,
    timeZoneOffset: gmtOffset
  };
});
const uniqByTz = uniqBy(tzOptions, "timeZoneOffset");
export const getTimeZoneList = () => uniqByTz;
export const TimeZoneList = ["GMT+07:00", "GMT+08:00", "GMT+09:00"];
export const findLocalTimeZoneName = (_timeZone) => {
  const {timeZone: localTimeZone} = getCountryCode();
  const TZ = isEmpty(localTimeZone) ? _timeZone || "GMT+8:00" : localTimeZone;
  return find(uniqByTz, (item) => {
    return item.timeZoneOffset.includes(`${TZ}`);
  })?.timeZoneName;
};
export const getCountryCode = () => {
  const newUserInfo = SessionHelper.getSessionUserInfo();
  const {country} = newUserInfo ?? {};
  return {
    countryCode: isEmpty(country) ? "ID" : country?.countryCode,
    timeZone: isEmpty(country) ? "GMT+08:00" : `GMT${country?.timeZone}`,
    registerCurrencyCode: isEmpty(country) ? "IDR" : country?.currency,
    registerCurrencySymbol: isEmpty(country) ? "Rp" : country?.currency
  };
};
const getFindItemInfo = () => {
  const {countryCode, timeZone} = getCountryCode();
  const findItem = find(AriaLabelMap, (item) => item.region === countryCode);
  return {findItem, timeZone};
};
export const getTimeZoneGMTList = () => {
  const {
    findItem: {timeZoneGMT}
  } = getFindItemInfo();
  return Object.keys(timeZoneGMT);
};
export const getLocalTimeZoneName = () => {
  const {
    findItem: {timeZoneGMT},
    timeZone
  } = getFindItemInfo();
  return timeZoneGMT[timeZone];
};
export const defaultLocale = (region) => {
  if (!region) {
    return "ID";
  }
  switch (region.toUpperCase()) {
    case "CN":
    case "ZH":
      return "CN";
    case "ID":
      return "ID";
    case "PH":
      return "PH";
    case "SG":
      return "SG";
    case "VN":
      return "VN";
    case "MY":
      return "MY";
    case "TH":
      return "TH";
    default:
      return "ID";
  }
};
