import React from "../../../../__snowpack__/pkg/react.js";
export const IconMenuIntegration = React.memo((props) => {
  return /* @__PURE__ */ React.createElement("svg", {
    xmlns: "http://www.w3.org/2000/svg",
    width: "18",
    height: "18",
    viewBox: "0 0 18 18",
    ...props
  }, /* @__PURE__ */ React.createElement("g", {
    fill: "none",
    fillRule: "evenodd",
    stroke: "none",
    strokeWidth: "1"
  }, /* @__PURE__ */ React.createElement("g", {
    fill: "currentColor",
    fillRule: "nonzero",
    transform: "translate(-12 -442)"
  }, /* @__PURE__ */ React.createElement("g", {
    transform: "translate(12 442)"
  }, /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", {
    d: "M5.02 6.077l.595.594a.466.466 0 010 .659l-2.702 2.705a3.576 3.576 0 002.528 6.101c.876 0 1.7-.31 2.351-.885l.174-.163 2.702-2.702a.466.466 0 01.659 0l.596.595a.466.466 0 010 .66l-2.702 2.703A5.317 5.317 0 015.44 17.91a5.313 5.313 0 01-3.782-1.566 5.358 5.358 0 01-.19-7.366l.19-.198L4.36 6.077a.466.466 0 01.66 0zm11.33-4.422a5.358 5.358 0 01.188 7.366l-.189.198-2.705 2.704a.466.466 0 01-.66 0l-.596-.596a.466.466 0 01-.06-.585l.06-.073 2.703-2.703a3.58 3.58 0 000-5.054 3.577 3.577 0 00-4.888-.155l-.164.155-2.703 2.702a.466.466 0 01-.66 0l-.596-.596a.466.466 0 010-.66l2.705-2.703a5.354 5.354 0 017.564 0z"
  }), /* @__PURE__ */ React.createElement("path", {
    d: "M11.45 5.296l-6.156 6.157a.466.466 0 000 .658l.597.597a.466.466 0 00.66 0l6.155-6.156a.466.466 0 000-.658l-.597-.598a.466.466 0 00-.658 0z"
  }))))));
});
