import React, {memo, useState, useEffect, useMemo, useCallback} from "../../../../__snowpack__/pkg/react.js";
import {Spin, Button, Popover, List, Tooltip, Avatar, message} from "../../../../__snowpack__/pkg/antd.js";
import {useIntl} from "../../../../__snowpack__/pkg/react-intl.js";
import moment from "../../../../__snowpack__/pkg/moment-timezone.js";
import VirtualList from "../../../../__snowpack__/pkg/rc-virtual-list.js";
import {useShopSyncModel} from "../../../entities/useShops.js";
import {useLocation, useHistory} from "../../../../__snowpack__/pkg/react-router-dom.js";
import styled from "../../../../__snowpack__/pkg/styled-components.js";
import {IconSync} from "../assets/IconSync.js";
import {IconClose} from "../assets/IconClose.js";
import GnAlert from "../../../components/GnAlert/index.js";
import {IconSyncExpiredBe} from "../assets/IconSyncExpiredBe.js";
import disconnectedIcon from "../../../assets/disconnected.png";
import normalStatusIcon from "../../../assets/normal-status.png";
import {TableEmpty} from "../../../components/TableEmpty/index.js";
import {useUpdateRoute} from "../../../lib/useIframe.js";
import {eventLogger} from "../../../lib/eventLogger.js";
import {PermissionInfo} from "../../../lib/isHasPermission.js";
import {useTodoListModal} from "../../../entities/dashboard/useTodoList.js";
import {useMySearchShopsModal} from "../../../entities/useShops.js";
import {platformLogo} from "../../../lib/basic.js";
let interval = null;
const ContainerHeight = 324;
export const SyncButton = memo(() => {
  const router = useHistory();
  const {syncStore} = useShopSyncModel();
  const {runAsync: fetchStoreList, filterData: storeList} = useMySearchShopsModal();
  const {updateRoute} = useUpdateRoute();
  const {pathname} = useLocation();
  const {shopList, fetchShopData, isShopLoading} = useTodoListModal();
  const [syncShopInfo, setSyncShopInfo] = useState({});
  const {formatMessage} = useIntl();
  const [isVisible, setIsVisible] = useState(false);
  const [storeCountMap, setStoreCountMap] = useState({});
  const [IsLoading, setIsLoading] = useState(true);
  useEffect(() => {
    if (isVisible)
      setIsVisible(false);
  }, [pathname]);
  const getStoreList = async () => {
    try {
      setIsLoading(true);
      await fetchStoreList();
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };
  const countDown = () => {
    const countMap = localStorage.getItem("storeCountMap") ? JSON.parse(localStorage.getItem("storeCountMap") || "{}") : {};
    Object.keys(countMap).forEach((i) => {
      if (countMap[i] > 0) {
        countMap[i] -= 1e3;
      }
    });
    setStoreCountMap(countMap);
    localStorage.setItem("storeCountMap", JSON.stringify(countMap));
    const everyzero = Object.values(countMap).every((e) => e === 0);
    if (everyzero) {
      clearInterval(interval);
      localStorage.removeItem("storeCountMap");
    }
  };
  const startInterval = () => {
    if (!interval) {
      interval = setInterval(countDown, 1e3);
    } else {
      clearInterval(interval);
      interval = setInterval(countDown, 1e3);
    }
  };
  const handleSync = async (item, msg) => {
    if (item.authorizationStatus !== "CONNECTED") {
      updateRoute("integrations?shopId=" + item.id);
    } else {
      const shopId = item.id;
      message.info(msg);
      let storeCountMapCv = {...storeCountMap, [shopId]: 18e4};
      setStoreCountMap(storeCountMapCv);
      localStorage.setItem("storeCountMap", JSON.stringify(storeCountMapCv));
      startInterval();
      let result = await syncStore(shopId);
      eventLogger.push({
        action: "SyncStore",
        location: "TopNavigation",
        actionExtra: {
          Result: result ? "Succeed" : "Failed"
        }
      });
      getStoreList();
    }
  };
  const handelStoreAuth = () => {
    eventLogger.push({
      action: "ClickSyncIntegrations",
      location: "TopNavigation"
    });
    setIsVisible(false);
    updateRoute("integrations");
  };
  const getSyncShopInfo = async () => {
    if (isShopLoading) {
      return;
    }
    let shopInfo = await fetchShopData(true);
    setSyncShopInfo(shopInfo?.todoList || {});
  };
  const handleVisible = async () => {
    setIsVisible(!isVisible);
    if (!isVisible) {
      const localstoreCountMap = localStorage.getItem("storeCountMap") ? JSON.parse(localStorage.getItem("storeCountMap") || "{}") : {};
      const existlast = Object.values(localstoreCountMap)?.find((count) => {
        return count > 0;
      });
      if (existlast) {
        startInterval();
      }
      setStoreCountMap(localstoreCountMap);
      getStoreList();
      getSyncShopInfo();
    }
  };
  const handleData = useCallback(async () => {
    if (!localStorage.getItem("todoShopList")) {
      getSyncShopInfo();
    } else if (localStorage.getItem("todoShopList")) {
      let todoShopList = JSON.parse(localStorage.getItem("todoShopList") || "{}");
      setSyncShopInfo(todoShopList);
    }
  }, [shopList]);
  useEffect(() => {
    handleData();
  }, []);
  const goStoreList = (url) => {
    router.push(url || "/integrations?status=EXPIRED_ABOUT");
  };
  const formatDate = (data) => data && moment(data).format("DD-MM-YYYY HH:mm");
  const ShopLimitList = useMemo(() => {
    let list = [];
    if (syncShopInfo?.expiredShopCount > 0) {
      list.push({
        count: syncShopInfo?.expiredShopCount,
        title: formatMessage({id: "storeHasExp"}, {m: syncShopInfo?.expiredShopCount}),
        path: "integrations?status=EXPIRED"
      });
    }
    if (syncShopInfo?.willExpireShopCount > 0) {
      list.push({
        count: syncShopInfo?.willExpireShopCount,
        title: formatMessage({id: "storeWillExp"}, {m: syncShopInfo?.willExpireShopCount}),
        path: "integrations?status=EXPIRED_ABOUT"
      });
    }
    if (syncShopInfo?.abnormalShopExpiredCount > 0) {
      list.push({
        count: syncShopInfo?.abnormalShopExpiredCount,
        title: formatMessage({id: "mStoreAbnormallyExpired"}, {m: syncShopInfo?.abnormalShopExpiredCount}),
        path: "integrations?status=ABNORMAL"
      });
    }
    return list;
  }, [syncShopInfo]);
  return /* @__PURE__ */ React.createElement(PopoverStyled, {
    placement: "bottomRight",
    trigger: "hover",
    overlayClassName: "store-sync-session",
    overlayStyle: {
      width: 428
    },
    onVisibleChange: () => handleVisible(),
    visible: isVisible,
    content: /* @__PURE__ */ React.createElement(Spin, {
      spinning: IsLoading
    }, /* @__PURE__ */ React.createElement(StoreSyncStyled, {
      className: "sync-store"
    }, syncShopInfo?.expiredShopCount > 0 ? /* @__PURE__ */ React.createElement(GnAlert, {
      message: formatMessage({id: "expiredStoresWillNotPushInventory"}),
      showIcon: true,
      style: {marginBottom: 0}
    }) : /* @__PURE__ */ React.createElement(React.Fragment, null), /* @__PURE__ */ React.createElement("div", {
      className: "sync-store-header"
    }, /* @__PURE__ */ React.createElement("div", {
      className: "sync-store-header-title"
    }, formatMessage({id: "menuIntegrations"})), ShopLimitList[0] ? /* @__PURE__ */ React.createElement(Spin, {
      spinning: isShopLoading
    }, /* @__PURE__ */ React.createElement("div", {
      className: "sync-store-header-expired"
    }, /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(IconClose, {
      style: {marginRight: 4}
    }), /* @__PURE__ */ React.createElement("span", {
      className: "sync-store-header-expired-text",
      onClick: () => {
        updateRoute(ShopLimitList[0]?.path);
      }
    }, ShopLimitList[0]?.title), ShopLimitList[1] && /* @__PURE__ */ React.createElement("span", {
      className: "sync-store-header-expired-round"
    })), ShopLimitList[1] && /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement("span", {
      className: "sync-store-header-expired-text",
      style: {color: "#FAAD14", borderColor: "#FAAD14"},
      onClick: () => {
        updateRoute(ShopLimitList[1]?.path);
      }
    }, ShopLimitList[1].title))), ShopLimitList[2] && /* @__PURE__ */ React.createElement("div", {
      className: "sync-store-header-expired",
      style: {marginTop: 6}
    }, /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(IconClose, {
      style: {marginRight: 4}
    }), /* @__PURE__ */ React.createElement("span", {
      className: "sync-store-header-expired-text",
      onClick: () => {
        updateRoute(ShopLimitList[2]?.path);
      }
    }, ShopLimitList[2]?.title)))) : /* @__PURE__ */ React.createElement("div", {
      className: "sync-store-header-desc"
    }, formatMessage({id: "alreadyStore"}))), /* @__PURE__ */ React.createElement("div", {
      className: "sync-store-body"
    }, /* @__PURE__ */ React.createElement(List, {
      className: "sync-store--loadMore-list",
      locale: {emptyText: /* @__PURE__ */ React.createElement(TableEmpty, null)}
    }, /* @__PURE__ */ React.createElement(VirtualList, {
      data: storeList ? storeList.filter((el) => {
        return el.authorizationStatus === "CONNECTED";
      }) : [],
      height: ContainerHeight,
      itemHeight: 94,
      itemKey: "id"
    }, (item) => {
      return /* @__PURE__ */ React.createElement(List.Item, {
        key: item.id,
        actions: [
          /* @__PURE__ */ React.createElement(React.Fragment, null, item.count, !!item.count && /* @__PURE__ */ React.createElement(Tooltip, {
            placement: "top",
            title: formatMessage({
              id: item.authorizationStatus === "CONNECTED" ? "syncing" : "syncdiconnected"
            })
          }, /* @__PURE__ */ React.createElement("span", {
            style: {
              position: "relative",
              fontSize: "12px",
              right: "-35px",
              display: "inline-block",
              width: "30px",
              height: "30px"
            }
          })), !storeCountMap[item.id] && /* @__PURE__ */ React.createElement(Tooltip, {
            placement: "top",
            title: formatMessage({
              id: item.authorizationStatus === "CONNECTED" ? "sync" : "syncdiconnected"
            })
          }, /* @__PURE__ */ React.createElement(Button, {
            type: "link",
            loading: item.count ? true : false,
            onClick: () => handleSync(item, formatMessage({id: "syncing"}))
          }, item.authorizationStatus !== "CONNECTED" ? /* @__PURE__ */ React.createElement("img", {
            src: disconnectedIcon,
            width: "20",
            height: "20",
            style: {
              position: "relative",
              left: "10px"
            }
          }) : !storeCountMap[item.id] && /* @__PURE__ */ React.createElement("img", {
            src: normalStatusIcon,
            width: "20",
            height: "20",
            style: {
              position: "relative",
              left: "10px"
            }
          }))), !!storeCountMap[item.id] && /* @__PURE__ */ React.createElement(Tooltip, {
            placement: "top",
            title: formatMessage({
              id: item.authorizationStatus === "CONNECTED" ? "sync" : "syncdiconnected"
            })
          }, /* @__PURE__ */ React.createElement(Button, {
            type: "link",
            loading: storeCountMap[item.id] ? true : false,
            onClick: () => handleSync(item, formatMessage({id: "syncing"}))
          }, item.authorizationStatus !== "CONNECTED" ? /* @__PURE__ */ React.createElement("img", {
            src: disconnectedIcon,
            width: "20",
            height: "20",
            style: {
              position: "relative",
              left: "10px"
            }
          }) : !storeCountMap[item.id] && /* @__PURE__ */ React.createElement("img", {
            src: normalStatusIcon,
            width: "20",
            height: "20",
            style: {
              position: "relative",
              left: "10px"
            }
          }))))
        ]
      }, /* @__PURE__ */ React.createElement(List.Item.Meta, {
        avatar: /* @__PURE__ */ React.createElement(Avatar, {
          size: 48,
          src: platformLogo(item.integrationMarketplace, item.authorizationStatus === "CONNECTED")
        }),
        title: /* @__PURE__ */ React.createElement("span", {
          style: {
            overflow: "hidden",
            textOverflow: "ellipsis",
            wordBreak: "break-all",
            width: 150
          }
        }, item.name),
        description: /* @__PURE__ */ React.createElement("span", null, formatMessage({id: "lastSyncTime"}), ": ", formatDate(item.latestOrderSyncDatetime))
      }));
    }))), /* @__PURE__ */ React.createElement(PermissionInfo, {
      authPermission: "integrations-store"
    }, /* @__PURE__ */ React.createElement("div", {
      className: "sync-store-footer",
      onClick: handelStoreAuth
    }, formatMessage({id: "addSyncStore"})))))
  }, syncShopInfo?.expiredShopCount || syncShopInfo?.willExpireShopCount ? syncShopInfo?.expiredShopCount ? /* @__PURE__ */ React.createElement("div", {
    className: "sync-btn-expired"
  }, /* @__PURE__ */ React.createElement("span", {
    className: "sync-store-header-expired-text",
    onClick: () => goStoreList("/integrations?status=EXPIRED")
  }, formatMessage({id: "storeHasExp"}, {m: syncShopInfo?.expiredShopCount})), syncShopInfo?.willExpireShopCount > 0 && /* @__PURE__ */ React.createElement("span", {
    className: "sync-store-header-expired-round"
  })) : /* @__PURE__ */ React.createElement(Button, {
    className: "header-right-button",
    type: "link"
  }, /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(IconSyncExpiredBe, {
    style: {marginRight: 4}
  }), formatMessage({id: "Store"}))) : /* @__PURE__ */ React.createElement(Button, {
    className: "header-right-button",
    type: "link"
  }, /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(IconSync, {
    style: {marginRight: 4, color: "#8C99AC"}
  }), formatMessage({id: "Store"}))), /* @__PURE__ */ React.createElement("span", {
    className: "header-right-divider"
  }, "|"));
});
const PopoverStyled = styled(Popover)`
  display: flex;
  align-items: center;
  .sync-btn-expired {
    height: 20px;
    padding: 0 12px;
    color: #fff;
    line-height: 20px;
    background: linear-gradient(45deg, #fc927b 0%, #f5222d 100%);
    border-radius: 14px;
    margin-right: 16px;
    cursor: pointer;
  }
`;
const StoreSyncStyled = styled.div`
  .sync-store {
    display: flex;
    width: 378px;
    flex-direction: column;
    &-header {
      padding: 16px 16px 16px 0;
      border-bottom: 1px solid #e8e8e8;
      &-title {
        font-size: 16px;
        color: #000000;
      }
      &-desc {
        font-size: 14px;
        color: rgba(0, 0, 0, 0.35);
      }
      &-expired {
        font-size: 12px;
        color: #ff4d4f;
        display: flex;
        align-items: center;
        &-text {
          border-bottom: 1px solid #ff4d4f;
          cursor: pointer;
        }
        &-round {
          width: 4px;
          height: 4px;
          border-radius: 4px;
          background: #ff4d4f;
          margin: 0 5px;
        }
      }
    }
    &-body {
      flex: 1;
      height: 324px;
      overflow: auto;
      .ant-list-items {
        .ant-list-item {
          padding: 16px;
          border-bottom: 1px solid #e8e8e8;
          &:last-child {
            border-bottom: 0;
          }
          .ant-list-item-action > li {
            font-size: 20px;
            cursor: pointer;
            padding: 0;
          }
          .ant-list-item-meta-avatar {
            margin-right: 12px;
          }
          .ant-list-item-meta-content {
            .ant-list-item-meta-title {
              color: rgba(0, 0, 0, 0.85);
              font-family: ArialMT;
            }
            .ant-list-item-meta-description {
              font-family: ArialMT;
              font-size: 14px;
              color: rgba(0, 0, 0, 0.35);
            }
          }
          &-action {
            margin-left: 0;
            padding-left: 10px;
          }
        }
      }
    }
    &-footer {
      border-top: 1px solid #e8e8e8;
      height: 50px;
      line-height: 50px;
      text-align: center;
      font-family: ArialMT;
      color: #6020ff;
      cursor: pointer;
    }
  }
`;
