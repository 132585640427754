const cacheUrlConfig = {
  "/infra/integration/channel/list": {
    outTime: 2 * 60 * 1e3,
    caches: ""
  },
  "/infra/integration/my/search?timePost=1": {
    outTime: 2 * 60 * 1e3,
    caches: ""
  }
};
const cacheUrlList = Object.keys(cacheUrlConfig);
export {cacheUrlConfig, cacheUrlList};
