import React, {useState, useEffect} from "../../__snowpack__/pkg/react.js";
import styled from "../../__snowpack__/pkg/styled-components.js";
import VideoPng from "../assets/video.png";
import HelpPng from "../assets/help.png";
import FormPng from "../assets/form.png";
import One from "../assets/one.svg";
import Two from "../assets/two.svg";
import {IconHelp} from "../views/menu/assets/IconHelp.js";
import Triangle2 from "../assets/Triangle2.svg";
export const ErrorModalTip = (props) => {
  const {formatMessage} = props;
  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    if (!localStorage.getItem("ErrorModalTip")) {
      setIsVisible(true);
      localStorage.setItem("ErrorModalTip", "true");
    }
  }, []);
  return isVisible ? /* @__PURE__ */ React.createElement(ModalContent, null, /* @__PURE__ */ React.createElement("div", {
    className: "box1"
  }, /* @__PURE__ */ React.createElement("img", {
    src: Triangle2,
    className: "box1-img"
  }), /* @__PURE__ */ React.createElement("h4", null, formatMessage({id: "needHelp"})), /* @__PURE__ */ React.createElement("p", null, formatMessage({id: "wereNotPerfectButWereListening"})), /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement("h4", null, /* @__PURE__ */ React.createElement("img", {
    src: One,
    style: {width: 20, height: 30, marginRight: 4}
  }), formatMessage({id: "feedbackForm"})), /* @__PURE__ */ React.createElement("p", null, formatMessage({id: "pleaseSubmitATicektFormWith"}))), /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement("h4", null, /* @__PURE__ */ React.createElement("img", {
    src: Two,
    style: {width: 20, height: 30, marginRight: 4}
  }), formatMessage({id: "feedbackVideo"})), /* @__PURE__ */ React.createElement("p", null, formatMessage({id: "pleaseGrantGineeThePermissionRecord"})), /* @__PURE__ */ React.createElement("p", null, formatMessage({id: "enterTheSpecificPageStartTo"})), /* @__PURE__ */ React.createElement("p", null, formatMessage({id: "stopTheRecordCopyTheUnique"}))), /* @__PURE__ */ React.createElement("button", {
    className: "error-btn",
    onClick: () => {
      setIsVisible(false);
    }
  }, formatMessage({id: "Gotit"}))), /* @__PURE__ */ React.createElement("div", {
    className: "box2"
  }, /* @__PURE__ */ React.createElement("h4", null, /* @__PURE__ */ React.createElement(IconHelp, {
    style: {marginRight: 4, color: "#8C99AC"}
  }), formatMessage({id: "faqfeedback"})), /* @__PURE__ */ React.createElement("div", {
    className: "box2-content"
  }, /* @__PURE__ */ React.createElement("p", null, /* @__PURE__ */ React.createElement("img", {
    src: HelpPng,
    style: {width: 12, marginRight: 4}
  }), formatMessage({id: "helpInfo"})), /* @__PURE__ */ React.createElement("p", null, /* @__PURE__ */ React.createElement("img", {
    style: {width: 12, marginRight: 4},
    src: FormPng
  }), formatMessage({id: "feedback"})), /* @__PURE__ */ React.createElement("p", null, /* @__PURE__ */ React.createElement("img", {
    src: VideoPng,
    style: {width: 12, marginRight: 4}
  }), formatMessage({id: "uploadVideo"}))))) : /* @__PURE__ */ React.createElement(React.Fragment, null);
};
const ModalContent = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 999;
  .box1 {
    position: absolute;
    right: 460px;
    padding: 32px;
    top: 40px;
    width: 500px;
    background: linear-gradient(180deg, #ffffff 0%, #edebff 100%);
    border-radius: 8px;
    p {
      color: #899bb5;
      font-size: 12px;
    }
    h4 {
      font-size: 12px;
      color: #333;
      display: flex;
      align-items: center;
    }
    .box1-img {
      position: absolute;
      right: -10px;
      width: 20px;
      top: 40px;
    }
  }
  .box2 {
    position: absolute;
    right: 314px;
    top: 5px;
    min-width: 130px;
    background: #ffffff;
    border-radius: 6px;
    overflow: hidden;
    h4 {
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #606c80;
      line-height: 17px;
      padding: 12px 16px;
      margin: 0;
      display: flex;
      align-items: center;
    }
    .box2-content {
      background: #ffffff;
      box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.2);
      padding: 10px 0;
      p {
        padding: 6px 16px;
        margin: 0;
        display: flex;
        align-items: center;
      }
    }
  }
  .error-btn {
    padding: 6px 16px;
    border: none;
    background: #6020ff;
    color: #ffffff;
    box-shadow: 0px 0px 11px 0px rgba(96, 32, 255, 0.41);
    border-radius: 3px;
    float: right;
    cursor: pointer;
  }
  .error-btn:hover {
    opacity: 0.6;
  }
`;
