import posSvg from "./ginee-pos.svg";
export const channelInfoOriginConfig = {
  shopee: {
    live: "https://cdn-erp.ginee.com/crm/stag/genie_20200108115600831_0236379310.png",
    disabled: "https://cdn-erp.ginee.com/crm/stag/genie_20200108115800549_2752132888.png",
    chosen: "https://cdn-erp.ginee.com/crm/stag/genie_20200108124836201_5432629267.png",
    type: "Shopee",
    title: "Shopee",
    channelId: "SHOPEE_ID"
  },
  tokopedia: {
    live: "https://cdn-erp.ginee.com/crm/stag/genie_20200108115651555_7433745245.png",
    disabled: "https://cdn-erp.ginee.com/crm/stag/genie_20200108115840797_7296248333.png",
    chosen: "https://cdn-erp.ginee.com/erp/stag/genie_20200108120202890_9906352055.png",
    type: "Tokopedia",
    title: "Tokopedia",
    channelId: "TOKOPEDIA_ID"
  },
  bukalapak: {
    live: "https://cdn-erp.ginee.com/crm/stag/genie_20200403164740938_4208818330.png",
    disabled: "https://cdn-erp.ginee.com/crm/stag/genie_20200403165726520_4689618058.png",
    chosen: "https://cdn-erp.ginee.com/crm/stag/genie_20200205180244274_7914610238.png",
    type: "Bukalapak",
    title: "Bukalapak",
    channelId: "BUKALAPAK_ID"
  },
  jd: {
    live: "https://cdn-erp.ginee.com/crm/stag/genie_20200108115635082_4536571964.png",
    disabled: "https://cdn-erp.ginee.com/crm/stag/genie_20200108115744750_1137710427.png",
    chosen: "https://cdn-erp.ginee.com/crm/stag/genie_20200108124809375_0103665336.png",
    type: "JD",
    title: "JD.ID",
    channelId: "JD_ID"
  },
  jd_v2: {
    live: "https://cdn-erp.ginee.com/crm/stag/genie_20200108115635082_4536571964.png",
    disabled: "https://cdn-erp.ginee.com/crm/stag/genie_20200108115744750_1137710427.png",
    chosen: "https://cdn-erp.ginee.com/crm/stag/genie_20200108124809375_0103665336.png",
    type: "JD",
    title: "JD",
    channelId: "JD_V2"
  },
  "jd.th": {
    live: "https://cdn-oss.ginee.com/erp/prod/ginee_20220822171051840_7003506763.png",
    disabled: "https://cdn-oss.ginee.com/erp/prod/ginee_20220822171051840_7003506763.png",
    chosen: "https://cdn-oss.ginee.com/erp/prod/ginee_20220822171051840_7003506763.png",
    type: "JD_TH",
    title: "JD.TH",
    channelId: "JD_TH"
  },
  shopify: {
    live: "https://cdn-erp.ginee.com/crm/stag/genie_20200108115535375_3834740896.png",
    disabled: "https://cdn-erp.ginee.com/crm/stag/genie_20200108115722314_5487414831.png",
    chosen: "https://cdn-erp.ginee.com/crm/stag/genie_20200108124734473_7831694930.png",
    type: "Shopify",
    title: "Shopify",
    channelId: "SHOPIFY_ID"
  },
  lazada: {
    live: "https://cdn-erp.ginee.com/crm/stag/genie_20200520110455984_9943552779.png",
    disabled: "https://cdn-erp.ginee.com/crm/stag/genie_20200520110526918_4039548485.png",
    chosen: "https://cdn-erp.ginee.com/crm/stag/genie_20200108124907671_1518567287.png",
    type: "Lazada",
    title: "Lazada",
    channelId: "LAZADA_ID"
  },
  "lazada choice": {
    live: "https://genie-public.oss-ap-southeast-1.aliyuncs.com/cms/prod/cms_20240415191814752_5067416637_.svg",
    disabled: "https://genie-public.oss-ap-southeast-1.aliyuncs.com/cms/prod/cms_20240415191814752_5067416637_.svg",
    chosen: "https://genie-public.oss-ap-southeast-1.aliyuncs.com/cms/prod/cms_20240415191814752_5067416637_.svg",
    type: "LazadaChoice",
    title: "Lazada Choice",
    channelId: "LAZADA_CHOICE"
  },
  blibli: {
    live: "https://cdn-erp.ginee.com/crm/stag/genie_20201019165139349_3750817268.png",
    disabled: "https://cdn-erp.ginee.com/crm/stag/genie_20201019165515925_6877238334.png",
    chosen: "https://cdn-erp.ginee.com/crm/stag/genie_20200108124907671_1518567287.png",
    type: "Blibli",
    title: "Blibli",
    channelId: "BLIBLI_ID"
  },
  woocommerce: {
    live: "https://cdn-erp.ginee.com/crm/stag/genie_woocommerce_icon.png",
    disabled: "https://cdn-erp.ginee.com/crm/stag/genie_woocommerce_icon_grey.png",
    chosen: "https://cdn-erp.ginee.com/crm/stag/genie_woocommerce_icon_grey.png",
    type: "WOO_COMMERCE_ID",
    title: "WooCommerce",
    channelId: "WOO_COMMERCE_ID"
  },
  wix: {
    live: "https://cdn-erp.ginee.com/crm/public/logo-wix.svg",
    disabled: "https://cdn-erp.ginee.com/crm/public/logo-wix.svg",
    chosen: "https://cdn-erp.ginee.com/crm/public/logo-wix.svg",
    type: "WIX_ID",
    title: "wix",
    channelId: "WIX_ID"
  },
  magento: {
    live: "https://cdn-erp.ginee.com/crm/public/logo-magento.svg",
    disabled: "https://cdn-erp.ginee.com/crm/public/logo-magento.svg",
    chosen: "https://cdn-erp.ginee.com/crm/public/logo-magento.svg",
    type: "MAGENTO_ID",
    title: "magento",
    channelId: "MAGENTO_ID"
  },
  tiktok: {
    live: "https://genie-public.oss-ap-southeast-1.aliyuncs.com/crm/public/logo-tiktok.jpeg",
    disabled: "https://genie-public.oss-ap-southeast-1.aliyuncs.com/crm/public/logo-tiktok.jpeg",
    chosen: "https://genie-public.oss-ap-southeast-1.aliyuncs.com/crm/public/logo-tiktok.jpeg",
    type: "TIKTOK_ID",
    title: "Tik Tok",
    channelId: "TIKTOK_ID"
  },
  tiki: {
    live: "https://cdn-erp.ginee.com/erp/stag/ginee_20211118141344413_4384007724.png",
    disabled: "https://cdn-erp.ginee.com/erp/stag/ginee_20211118153333126_3155028797.png",
    chosen: "https://cdn-erp.ginee.com/erp/stag/ginee_20211118141344413_4384007724.png",
    type: "TIKI_ID",
    title: "Tiki",
    channelId: "TIKI_ID"
  },
  zalora: {
    live: "https://cdn-erp.ginee.com/erp/prod/ginee_20220118183619893_4128353604.png",
    disabled: "https://cdn-erp.ginee.com/erp/prod/ginee_20220118183619893_4128353604.png",
    chosen: "https://cdn-erp.ginee.com/erp/prod/ginee_20220118183619893_4128353604.png",
    type: "ZALORA",
    title: "ZALORA",
    channelId: "ZALORA_ID"
  },
  manual_shop: {
    live: "https://cdn-erp.ginee.com/erp/stag/ginee_20220303151140627_0221562754.png",
    disabled: "https://cdn-erp.ginee.com/erp/prod/ginee_20220303150831426_4517733841.png",
    chosen: "https://cdn-erp.ginee.com/erp/prod/ginee_20220303150831426_4517733841.png",
    type: "MANUAL_ID",
    title: "Manual",
    channelId: "MANUAL_ID"
  },
  manual: {
    live: "https://cdn-erp.ginee.com/erp/stag/ginee_20220303151140627_0221562754.png",
    disabled: "https://cdn-erp.ginee.com/erp/prod/ginee_20220303150831426_4517733841.png",
    chosen: "https://cdn-erp.ginee.com/erp/prod/ginee_20220303150831426_4517733841.png",
    type: "MANUAL_ID",
    title: "Manual",
    channelId: "MANUAL_ID"
  },
  akulaku: {
    live: "https://cdn-erp.ginee.com/erp/prod/ginee_20220706111540441_1852600109.png",
    disabled: "https://cdn-erp.ginee.com/erp/prod/ginee_20220706111540441_1852600109.png",
    chosen: "https://cdn-erp.ginee.com/erp/prod/ginee_20220706111540441_1852600109.png",
    type: "AKULAKU",
    title: "AKULAKU",
    channelId: "AKULAKU_ID"
  },
  "moka pos": {
    live: "https://cdn-oss.ginee.com/erp/prod/ginee_20221122141533865_2980948817.png",
    disabled: "https://cdn-oss.ginee.com/erp/prod/ginee_20221122141533865_2980948817.png",
    chosen: "https://cdn-oss.ginee.com/erp/prod/ginee_20221122141533865_2980948817.png",
    type: "MOKA_POS_ID",
    title: "MOKA_POS_ID",
    channelId: "MOKA_POS_ID"
  },
  "line shopping": {
    live: "https://cdn-erp.ginee.com/crm/public/logo-line-shopping.svg",
    disabled: "https://cdn-erp.ginee.com/crm/public/logo-line-shopping.svg",
    chosen: "https://cdn-erp.ginee.com/crm/public/logo-line-shopping.svg",
    type: "Line Shopping",
    title: "Line Shopping",
    channelId: "LINE_SHOPPING_TH"
  },
  lineshopping: {
    live: "https://cdn-erp.ginee.com/crm/public/logo-line-shopping.svg",
    disabled: "https://cdn-erp.ginee.com/crm/public/logo-line-shopping.svg",
    chosen: "https://cdn-erp.ginee.com/crm/public/logo-line-shopping.svg",
    type: "Line Shopping",
    title: "Line Shopping",
    channelId: "LINE_SHOPPING_TH"
  },
  "ginee pos": {
    live: posSvg,
    disabled: posSvg,
    chosen: posSvg,
    type: "GINEE_POS",
    title: "Ginee POS",
    channelId: "GINEE_POS"
  }
};
Object.keys(channelInfoOriginConfig)?.map((key) => {
  let obj = channelInfoOriginConfig;
  let lowerKey = obj[key]?.channelId?.toLowerCase();
  obj[lowerKey] = obj[key];
});
export const channelInfoConfig = new Proxy(channelInfoOriginConfig, {
  get(target, prop) {
    return target[prop.toLowerCase()];
  }
});
export const channelNamePlaceholder = "https://cdn-erp.ginee.com/crm/stag/genie_20200108130805393_3301922361.png";
