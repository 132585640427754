import { c as createCommonjsModule, g as getDefaultExportFromCjs } from '../../../common/_commonjsHelpers-64249c85.js';
import { i as interopRequireDefault } from '../../../common/interopRequireDefault-6e0c2256.js';
import { _ as _extends_1 } from '../../../common/extends-0409b284.js';

var th_TH = createCommonjsModule(function (module, exports) {

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  // Options.jsx
  items_per_page: '/ หน้า',
  jump_to: 'ไปยัง',
  jump_to_confirm: 'ยืนยัน',
  page: 'หน้า',
  // Pagination.jsx
  prev_page: 'หน้าก่อนหน้า',
  next_page: 'หน้าถัดไป',
  prev_5: 'ย้อนกลับ 5 หน้า',
  next_5: 'ถัดไป 5 หน้า',
  prev_3: 'ย้อนกลับ 3 หน้า',
  next_3: 'ถัดไป 3 หน้า',
  page_size: 'ขนาดหน้า'
};
exports.default = _default;
});

var th_TH$1 = createCommonjsModule(function (module, exports) {

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var locale = {
  locale: 'th_TH',
  today: 'วันนี้',
  now: 'ตอนนี้',
  backToToday: 'กลับไปยังวันนี้',
  ok: 'ตกลง',
  clear: 'ลบล้าง',
  month: 'เดือน',
  year: 'ปี',
  timeSelect: 'เลือกเวลา',
  dateSelect: 'เลือกวัน',
  monthSelect: 'เลือกเดือน',
  yearSelect: 'เลือกปี',
  decadeSelect: 'เลือกทศวรรษ',
  yearFormat: 'YYYY',
  dateFormat: 'D/M/YYYY',
  dayFormat: 'D',
  dateTimeFormat: 'D/M/YYYY HH:mm:ss',
  monthBeforeYear: true,
  previousMonth: 'เดือนก่อนหน้า (PageUp)',
  nextMonth: 'เดือนถัดไป (PageDown)',
  previousYear: 'ปีก่อนหน้า (Control + left)',
  nextYear: 'ปีถัดไป (Control + right)',
  previousDecade: 'ทศวรรษก่อนหน้า',
  nextDecade: 'ทศวรรษถัดไป',
  previousCentury: 'ศตวรรษก่อนหน้า',
  nextCentury: 'ศตวรรษถัดไป'
};
var _default = locale;
exports.default = _default;
});

var th_TH$2 = createCommonjsModule(function (module, exports) {

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var locale = {
  placeholder: 'เลือกเวลา'
};
var _default = locale;
exports["default"] = _default;
});

var th_TH$3 = createCommonjsModule(function (module, exports) {



Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _extends2 = interopRequireDefault(_extends_1);

var _th_TH = interopRequireDefault(th_TH$1);

var _th_TH2 = interopRequireDefault(th_TH$2);

// Merge into a locale object
var locale = {
  lang: (0, _extends2["default"])({
    placeholder: 'เลือกวันที่',
    yearPlaceholder: 'เลือกปี',
    quarterPlaceholder: 'เลือกไตรมาส',
    monthPlaceholder: 'เลือกเดือน',
    weekPlaceholder: 'เลือกสัปดาห์',
    rangePlaceholder: ['วันเริ่มต้น', 'วันสิ้นสุด'],
    rangeYearPlaceholder: ['ปีเริ่มต้น', 'ปีสิ้นสุด'],
    rangeMonthPlaceholder: ['เดือนเริ่มต้น', 'เดือนสิ้นสุด'],
    rangeWeekPlaceholder: ['สัปดาห์เริ่มต้น', 'สัปดาห์สิ้นสุด']
  }, _th_TH["default"]),
  timePickerLocale: (0, _extends2["default"])({}, _th_TH2["default"])
}; // All settings at:
// https://github.com/ant-design/ant-design/blob/master/components/date-picker/locale/example.json

var _default = locale;
exports["default"] = _default;
});

var th_TH$4 = createCommonjsModule(function (module, exports) {



Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _th_TH = interopRequireDefault(th_TH$3);

var _default = _th_TH["default"];
exports["default"] = _default;
});

var th_TH$5 = createCommonjsModule(function (module, exports) {



Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _th_TH = interopRequireDefault(th_TH);

var _th_TH2 = interopRequireDefault(th_TH$3);

var _th_TH3 = interopRequireDefault(th_TH$2);

var _th_TH4 = interopRequireDefault(th_TH$4);

/* eslint-disable no-template-curly-in-string */
var typeTemplate = '${label} ไม่ใช่ ${type} ที่ถูกต้อง';
var localeValues = {
  locale: 'th',
  Pagination: _th_TH["default"],
  DatePicker: _th_TH2["default"],
  TimePicker: _th_TH3["default"],
  Calendar: _th_TH4["default"],
  global: {
    placeholder: 'กรุณาเลือก'
  },
  Table: {
    filterTitle: 'ตัวกรอง',
    filterConfirm: 'ยืนยัน',
    filterReset: 'รีเซ็ต',
    filterEmptyText: 'ไม่มีตัวกรอง',
    emptyText: 'ไม่มีข้อมูล',
    selectAll: 'เลือกทั้งหมดในหน้านี้',
    selectInvert: 'กลับสถานะการเลือกในหน้านี้',
    selectionAll: 'เลือกข้อมูลทั้งหมด',
    sortTitle: 'เรียง',
    expand: 'แสดงแถวข้อมูล',
    collapse: 'ย่อแถวข้อมูล',
    triggerDesc: 'คลิกเรียงจากมากไปน้อย',
    triggerAsc: 'คลิกเรียงจากน้อยไปมาก',
    cancelSort: 'คลิกเพื่อยกเลิกการเรียง'
  },
  Modal: {
    okText: 'ตกลง',
    cancelText: 'ยกเลิก',
    justOkText: 'ตกลง'
  },
  Popconfirm: {
    okText: 'ตกลง',
    cancelText: 'ยกเลิก'
  },
  Transfer: {
    titles: ['', ''],
    searchPlaceholder: 'ค้นหา',
    itemUnit: 'ชิ้น',
    itemsUnit: 'ชิ้น',
    remove: 'นำออก',
    selectCurrent: 'เลือกทั้งหมดในหน้านี้',
    removeCurrent: 'นำออกทั้งหมดในหน้านี้',
    selectAll: 'เลือกข้อมูลทั้งหมด',
    removeAll: 'นำข้อมูลออกทั้งหมด',
    selectInvert: 'กลับสถานะการเลือกในหน้านี้'
  },
  Upload: {
    uploading: 'กำลังอัปโหลด...',
    removeFile: 'ลบไฟล์',
    uploadError: 'เกิดข้อผิดพลาดในการอัปโหลด',
    previewFile: 'ดูตัวอย่างไฟล์',
    downloadFile: 'ดาวน์โหลดไฟล์'
  },
  Empty: {
    description: 'ไม่มีข้อมูล'
  },
  Icon: {
    icon: 'ไอคอน'
  },
  Text: {
    edit: 'แก้ไข',
    copy: 'คัดลอก',
    copied: 'คัดลอกแล้ว',
    expand: 'ขยาย'
  },
  PageHeader: {
    back: 'ย้อนกลับ'
  },
  Form: {
    optional: '(ไม่จำเป็น)',
    defaultValidateMessages: {
      "default": 'ฟิลด์ ${label} ไม่ผ่านเงื่อนไขการตรวจสอบ',
      required: 'กรุณากรอก ${label}',
      "enum": '${label} ต้องเป็นค่าใดค่าหนึ่งใน [${enum}]',
      whitespace: '${label} ไม่สามารถเป็นช่องว่างได้',
      date: {
        format: 'รูปแบบวันที่ ${label} ไม่ถูกต้อง',
        parse: '${label} ไม่สามารถแปลงเป็นวันที่ได้',
        invalid: '${label} เป็นวันที่ที่ไม่ถูกต้อง'
      },
      types: {
        string: typeTemplate,
        method: typeTemplate,
        array: typeTemplate,
        object: typeTemplate,
        number: typeTemplate,
        date: typeTemplate,
        "boolean": typeTemplate,
        integer: typeTemplate,
        "float": typeTemplate,
        regexp: typeTemplate,
        email: typeTemplate,
        url: typeTemplate,
        hex: typeTemplate
      },
      string: {
        len: '${label} ต้องมี ${len} ตัวอักษร',
        min: '${label} ต้องมีอย่างน้อย ${min} ตัวอักษร',
        max: '${label} มีได้สูงสุด ${max} ตัวอักษร',
        range: '${label} ต้องมี ${min}-${max} ตัวอักษร'
      },
      number: {
        len: '${label} ต้องมี ${len} ตัว',
        min: 'ค่าต่ำสุด ${label} คือ ${min}',
        max: 'ค่าสูงสุด ${label} คือ ${max}',
        range: '${label} ต้องมีค่า ${min}-${max}'
      },
      array: {
        len: 'ต้องมี ${len} ${label}',
        min: 'ต้องมีอย่างน้อย ${min} ${label}',
        max: 'มีได้สูงสุด ${max} ${label}',
        range: 'จำนวน ${label} ต้องอยู่ในช่วง ${min}-${max}'
      },
      pattern: {
        mismatch: '${label} ไม่ตรงกับรูปแบบ ${pattern}'
      }
    }
  }
};
var _default = localeValues;
exports["default"] = _default;
});

var __pika_web_default_export_for_treeshaking__ = /*@__PURE__*/getDefaultExportFromCjs(th_TH$5);

export default __pika_web_default_export_for_treeshaking__;
