import React from "../../../../__snowpack__/pkg/react.js";
export const IconMenuMasterProducts = React.memo((props) => {
  return /* @__PURE__ */ React.createElement("svg", {
    xmlns: "http://www.w3.org/2000/svg",
    width: "18",
    height: "18",
    viewBox: "0 0 18 18",
    ...props
  }, /* @__PURE__ */ React.createElement("g", {
    fill: "none",
    fillRule: "evenodd",
    stroke: "none",
    strokeWidth: "1"
  }, /* @__PURE__ */ React.createElement("g", {
    transform: "translate(-12 -148)"
  }, /* @__PURE__ */ React.createElement("g", {
    transform: "translate(0 136)"
  }, /* @__PURE__ */ React.createElement("g", {
    transform: "translate(12 12)"
  }, /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", {
    d: "M0 0H18V18H0z"
  }), /* @__PURE__ */ React.createElement("g", {
    stroke: "currentColor",
    strokeWidth: "1.364",
    transform: "translate(2 2)"
  }, /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", {
    d: "M1.707 4.09H11.93a1.09 1.09 0 011.088 1.024l.546 8.727A1.09 1.09 0 0112.475 15H1.161a1.09 1.09 0 01-1.089-1.159l.546-8.727A1.09 1.09 0 011.707 4.09z"
  }), /* @__PURE__ */ React.createElement("path", {
    d: "M10.227 3.41a3.41 3.41 0 10-6.818 0"
  }), /* @__PURE__ */ React.createElement("path", {
    d: "M9.545 9.545a2.727 2.727 0 00-5.454 0",
    transform: "matrix(1 0 0 -1 0 16.364)"
  })))))))));
});
