import { c as createCommonjsModule, g as getDefaultExportFromCjs } from '../../../common/_commonjsHelpers-64249c85.js';
import { i as interopRequireDefault } from '../../../common/interopRequireDefault-6e0c2256.js';
import { _ as _extends_1 } from '../../../common/extends-0409b284.js';

var vi_VN = createCommonjsModule(function (module, exports) {

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  // Options.jsx
  items_per_page: '/ trang',
  jump_to: 'Đến',
  jump_to_confirm: 'xác nhận',
  page: 'Trang',
  // Pagination.jsx
  prev_page: 'Trang Trước',
  next_page: 'Trang Kế',
  prev_5: 'Về 5 Trang Trước',
  next_5: 'Đến 5 Trang Kế',
  prev_3: 'Về 3 Trang Trước',
  next_3: 'Đến 3 Trang Kế',
  page_size: 'kích thước trang'
};
exports.default = _default;
});

var vi_VN$1 = createCommonjsModule(function (module, exports) {

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var locale = {
  locale: 'vi_VN',
  today: 'Hôm nay',
  now: 'Bây giờ',
  backToToday: 'Trở về hôm nay',
  ok: 'Ok',
  clear: 'Xóa',
  month: 'Tháng',
  year: 'Năm',
  timeSelect: 'Chọn thời gian',
  dateSelect: 'Chọn ngày',
  weekSelect: 'Chọn tuần',
  monthSelect: 'Chọn tháng',
  yearSelect: 'Chọn năm',
  decadeSelect: 'Chọn thập kỷ',
  yearFormat: 'YYYY',
  dateFormat: 'D/M/YYYY',
  dayFormat: 'D',
  dateTimeFormat: 'D/M/YYYY HH:mm:ss',
  monthBeforeYear: true,
  previousMonth: 'Tháng trước (PageUp)',
  nextMonth: 'Tháng sau (PageDown)',
  previousYear: 'Năm trước (Control + left)',
  nextYear: 'Năm sau (Control + right)',
  previousDecade: 'Thập kỷ trước',
  nextDecade: 'Thập kỷ sau',
  previousCentury: 'Thế kỷ trước',
  nextCentury: 'Thế kỷ sau'
};
var _default = locale;
exports.default = _default;
});

var vi_VN$2 = createCommonjsModule(function (module, exports) {

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var locale = {
  placeholder: 'Chọn thời gian'
};
var _default = locale;
exports["default"] = _default;
});

var vi_VN$3 = createCommonjsModule(function (module, exports) {



Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _extends2 = interopRequireDefault(_extends_1);

var _vi_VN = interopRequireDefault(vi_VN$1);

var _vi_VN2 = interopRequireDefault(vi_VN$2);

// Merge into a locale object
var locale = {
  lang: (0, _extends2["default"])({
    placeholder: 'Chọn thời điểm',
    rangePlaceholder: ['Ngày bắt đầu', 'Ngày kết thúc']
  }, _vi_VN["default"]),
  timePickerLocale: (0, _extends2["default"])({}, _vi_VN2["default"])
}; // All settings at:
// https://github.com/ant-design/ant-design/blob/master/components/date-picker/locale/example.json

var _default = locale;
exports["default"] = _default;
});

var vi_VN$4 = createCommonjsModule(function (module, exports) {



Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _vi_VN = interopRequireDefault(vi_VN$3);

var _default = _vi_VN["default"];
exports["default"] = _default;
});

var vi_VN$5 = createCommonjsModule(function (module, exports) {



Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _vi_VN = interopRequireDefault(vi_VN);

var _vi_VN2 = interopRequireDefault(vi_VN$3);

var _vi_VN3 = interopRequireDefault(vi_VN$2);

var _vi_VN4 = interopRequireDefault(vi_VN$4);

var localeValues = {
  locale: 'vi',
  Pagination: _vi_VN["default"],
  DatePicker: _vi_VN2["default"],
  TimePicker: _vi_VN3["default"],
  Calendar: _vi_VN4["default"],
  Table: {
    filterTitle: 'Bộ ',
    filterConfirm: 'OK',
    filterReset: 'Tạo Lại',
    selectAll: 'Chọn Tất Cả',
    selectInvert: 'Chọn Ngược Lại'
  },
  Modal: {
    okText: 'OK',
    cancelText: 'Huỷ',
    justOkText: 'OK'
  },
  Popconfirm: {
    okText: 'OK',
    cancelText: 'Huỷ'
  },
  Transfer: {
    searchPlaceholder: 'Tìm ở đây',
    itemUnit: 'mục',
    itemsUnit: 'mục'
  },
  Upload: {
    uploading: 'Đang tải lên...',
    removeFile: 'Gỡ bỏ tập tin',
    uploadError: 'Lỗi tải lên',
    previewFile: 'Xem thử tập tin',
    downloadFile: 'Tải tập tin'
  },
  Empty: {
    description: 'Trống'
  }
};
var _default = localeValues;
exports["default"] = _default;
});

var __pika_web_default_export_for_treeshaking__ = /*@__PURE__*/getDefaultExportFromCjs(vi_VN$5);

export default __pika_web_default_export_for_treeshaking__;
