import React, {Suspense} from "../../__snowpack__/pkg/react.js";
import {Loading} from "../components/Loading/index.js";
import {ErrorBoundary} from "../components/ErrorBoundary/index.js";
export const lazy = (factory) => {
  const Component = React.lazy(factory);
  const LazyLoad = () => {
    return /* @__PURE__ */ React.createElement(Suspense, {
      fallback: /* @__PURE__ */ React.createElement(Loading, null)
    }, /* @__PURE__ */ React.createElement(ErrorBoundary, null, /* @__PURE__ */ React.createElement(Component, null)));
  };
  return LazyLoad;
};
