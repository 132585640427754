import React, {memo, useState, useEffect, useMemo} from "../../../../__snowpack__/pkg/react.js";
import {Spin, Button, Popover, Modal} from "../../../../__snowpack__/pkg/antd.js";
import {IconInfo} from "../../../../__snowpack__/pkg/@genieui-react/icon.js";
import {useIntl} from "../../../../__snowpack__/pkg/react-intl.js";
import {useLocation} from "../../../../__snowpack__/pkg/react-router-dom.js";
import styled from "../../../../__snowpack__/pkg/styled-components.js";
import {handleError} from "../../../lib/errorHandler.js";
import {request} from "../../../utils/request/index.js";
import Bell from "../assets/bell.svg";
import Triangle from "../assets/triangle.svg";
import {useProvideInfoModal} from "../../../entities/useResourceInfo.js";
export const SetMessage = memo((props) => {
  const {pathname} = useLocation();
  const {formatMessage} = useIntl();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [currentMessage, setCurrentMessage] = useState({serviceAuthorizationId: "", servedUserName: "", authorizationFlag: ""});
  const {loading: resourceLoading, provideInfo, fetchData} = useProvideInfoModal();
  useEffect(() => {
    if (isVisible)
      setIsVisible(false);
  }, [pathname]);
  const handleVisible = () => {
    setIsVisible(!isVisible);
    if (!isVisible) {
      fetchData();
    }
  };
  const showMessage = useMemo(() => {
    if (provideInfo && provideInfo.length) {
      return true;
    } else {
      return false;
    }
  }, [provideInfo]);
  const agreeFunction = async (isAgree) => {
    try {
      setIsLoading(true);
      await request.post(`saas/user/active/provider`, {
        serviceAuthorizationId: currentMessage.serviceAuthorizationId,
        isAgree
      });
      await request.post(`/infra/integration/connect`, {
        channel: "GINEE",
        country: "ID",
        application: "SYS_ERP",
        payload: {
          authorizeType: "USE_GENIE_AUTHORIZE",
          warehouseNo: currentMessage.authorizationFlag,
          syncProduct: true
        }
      });
      if (isAgree) {
        props.getUserInfo();
      }
      fetchData(true);
    } catch (error) {
      handleError(error);
    } finally {
      setIsLoading(false);
      setIsModalOpen(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(MessageModal, {
    title: /* @__PURE__ */ React.createElement("p", {
      style: {display: "flex", alignItems: "center", margin: 0}
    }, /* @__PURE__ */ React.createElement(IconInfo, {
      style: {marginRight: 4, fontSize: 20, width: 20, marginLeft: -10}
    }), formatMessage({id: "theFulfillmentServiceProviderXInvites"}, {x: currentMessage.servedUserName, y: currentMessage.authorizationFlag})),
    visible: isModalOpen,
    onCancel: () => {
      setIsModalOpen(false);
    },
    footer: [
      /* @__PURE__ */ React.createElement(Button, {
        onClick: () => agreeFunction(false)
      }, formatMessage({
        id: "Reject"
      })),
      /* @__PURE__ */ React.createElement(Button, {
        type: "primary",
        style: {marginLeft: 16},
        onClick: () => agreeFunction(true)
      }, formatMessage({id: "confirmActivation"}))
    ]
  }, /* @__PURE__ */ React.createElement("p", null, formatMessage({id: "afterAgreeing"})), /* @__PURE__ */ React.createElement("p", null, formatMessage({id: "YourOmsWillBeUpgraded"})), /* @__PURE__ */ React.createElement("p", null, formatMessage({id: "YouWillAutomaticallyBindThe"})), /* @__PURE__ */ React.createElement("p", null, formatMessage({id: "areYouSureYouAgreeTo"}))), showMessage ? /* @__PURE__ */ React.createElement(PopoverStyled, {
    placement: "bottom",
    trigger: "hover",
    onVisibleChange: () => handleVisible(),
    visible: isVisible,
    content: /* @__PURE__ */ React.createElement(NewApplication, null, /* @__PURE__ */ React.createElement(Spin, {
      spinning: isLoading || resourceLoading,
      style: {display: "flex"}
    }, provideInfo?.map((data) => {
      return /* @__PURE__ */ React.createElement("div", {
        className: "message-list"
      }, formatMessage({id: "theFulfillmentServiceProviderXInvites"}, {x: data.servedUserName, y: data.authorizationFlag}), /* @__PURE__ */ React.createElement("span", {
        className: "message-button",
        onClick: () => {
          setCurrentMessage(data);
          setIsVisible(false);
          setIsModalOpen(true);
        }
      }, formatMessage({id: "view"})));
    })))
  }, /* @__PURE__ */ React.createElement(BellBox, null, /* @__PURE__ */ React.createElement("img", {
    style: {marginRight: 16, marginLeft: 32, cursor: "pointer"},
    src: Bell
  }), /* @__PURE__ */ React.createElement("div", {
    className: "bell-box-message"
  }, /* @__PURE__ */ React.createElement("img", {
    src: Triangle,
    className: "bell-box-message-img"
  }), formatMessage({id: "youHaveNewApplication"}, {m: provideInfo?.length})), /* @__PURE__ */ React.createElement("span", {
    className: "header-right-divider"
  }, "|"))) : /* @__PURE__ */ React.createElement(React.Fragment, null));
});
const BellBox = styled.div`
  position: relative;
  cursor: pointer;
  .bell-box-message {
    position: absolute;
    top: 36px;
    right: 0;
    height: 38px;
    line-height: 38px;
    font-size: 12px;
    padding: 0px 16px;
    border-radius: 4px;
    background: #fff;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
    transition: all linear 0.3s;
    white-space: nowrap;
    .bell-box-message-img {
      position: absolute;
      top: -4px;
      right: 38px;
    }
  }
`;
const PopoverStyled = styled(Popover)`
  display: flex;
  align-items: center;
  .header-right-divider {
    margin-right: 16px;
  }
`;
const MessageModal = styled(Modal)`
  .ant-modal-content {
    background: linear-gradient(180deg, #edebff 0%, #ffffff 100%);
    border-radius: 16px;
    overflow: hidden;
  }

  .ant-modal-header {
    background: transparent;
  }
  .ant-modal-footer {
    border-top: 0;
    padding: 16px 40px 24px;
  }
  .ant-modal-body {
    padding: 0 40px 16px;
  }
  .ant-btn-primary {
    box-shadow: 0px 0px 11px 0px rgba(96, 32, 255, 0.41);
    margin-left: 16px;
  }
`;
const NewApplication = styled.div`
  .message-list {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    color: #202d40;
    padding: 12px 0;
    width: 300px;
    border-bottom: 1px solid #f0f0f0;
  }
  .message-list:last-child {
    border: none;
  }
  .message-button {
    cursor: pointer;
    color: #6020ff;
  }
  .message-button:hover {
    color: #6020ff;
  }
`;
