import React, {useEffect} from "../../../__snowpack__/pkg/react.js";
import styled from "../../../__snowpack__/pkg/styled-components.js";
import {Spin, Modal} from "../../../__snowpack__/pkg/antd.js";
import queryString from "../../../__snowpack__/pkg/query-string.js";
import {omit} from "../../../__snowpack__/pkg/lodash.js";
import {useUpdateRoute} from "../../lib/useIframe.js";
import {useIframeHook} from "../../../__snowpack__/pkg/@genie/amz-iframe/dist/react/useIframe.js";
import {useLayoutState} from "../../store/layout.js";
import {localeMessage} from "../../lib/i18n.js";
import {useIntlTyped} from "../../lib/useIntlTyped.js";
import {handleError} from "../../lib/errorHandler.js";
import {useMySearchShopsModal} from "../../entities/useShops.js";
import moment from "../../../__snowpack__/pkg/moment-timezone.js";
import {findLocalTimeZoneName} from "../../lib/country.js";
import {OriginMap, SessionHelper} from "../../../__snowpack__/pkg/@genie/lib-iam-utils.js";
import Cookies from "../../../__snowpack__/pkg/js-cookie.js";
import {useCheckUserType} from "../../entities/useCheckUserType.js";
import {cacheUrlList} from "../../utils/request/cacheConfig.js";
export const toAccounts = async (target) => {
  const origin = window.location.origin;
  await SessionHelper.removeSessionToken();
  Cookies.remove("token");
  const iamOrigin = OriginMap[origin]?.IAM_ORIGIN ?? origin;
  const iamURL = `${iamOrigin}/accounts`;
  let language = localStorage.getItem("language") || "id";
  location.href = `${iamURL}/?${queryString.stringify({
    system_id: "SYS_ERP",
    redirect_uri: `${origin}/login?${queryString.stringify({
      target: encodeURIComponent(target || "")
    })}`,
    language: language === "vn" ? "vi" : language,
    ...omit(queryString.parse(location.search.slice(1)), "code", "target")
  })}`;
};
export const toAccountsOpen = async (target) => {
  const origin = window.location.origin;
  await SessionHelper.removeSessionToken();
  Cookies.remove("token");
  const iamOrigin = OriginMap[origin]?.IAM_ORIGIN ?? origin;
  const iamURL = `${iamOrigin}/accounts`;
  let language = localStorage.getItem("language") || "id";
  window.open(`${iamURL}/?${queryString.stringify({
    system_id: "SYS_ERP",
    redirect_uri: `${origin}/login?${queryString.stringify({
      target: encodeURIComponent(target || "")
    })}`,
    language: language === "vn" ? "vi" : language,
    ...omit(queryString.parse(location.search.slice(1)), "code", "target")
  })}`);
};
export const Login = () => {
  const {formatMessage} = useIntlTyped(localeMessage);
  const {setUserInfo} = useLayoutState();
  const {runAsync: fetchShops} = useMySearchShopsModal();
  const {initPermission} = useCheckUserType();
  const {sendMessageToChildren} = useIframeHook("amzIframeOldElIframe");
  const {updateRoute} = useUpdateRoute();
  const handleLogin = async () => {
    try {
      localStorage.removeItem("IAM_USER_TYPE");
      Cookies.remove("setDisplayInfo");
      Cookies.remove("setDisplayInfoTime");
      Cookies.remove("setProvideInfo");
      cacheUrlList?.forEach((url) => {
        url && localStorage.removeItem(url);
      });
      sendMessageToChildren({event: "login"});
      let newUserInfo = SessionHelper.getSessionUserInfo();
      setUserInfo(newUserInfo);
      localStorage.setItem("userInfo", JSON.stringify(newUserInfo || {}));
      moment.tz.setDefault(findLocalTimeZoneName());
      const {shops} = await fetchShops();
      await initPermission(true, shops);
    } catch (error) {
      if (error.code === "STAFF_LOGIN_FAILED") {
        await new Promise(() => {
          Modal.error({
            content: error.message,
            onOk: async () => {
              toAccounts();
            }
          });
        });
        return;
      } else if (error.code === "RESOURCE_REACH_LIMIT_STAFF_LOGIN") {
        await new Promise(() => {
          Modal.error({
            content: formatMessage({
              id: "duringEffectivePeriodOfThePackage"
            }),
            onOk: () => {
              toAccounts();
            }
          });
        });
        return;
      } else if (error.code === "SUB_ACCOUNT_NOT_EXIST") {
        updateRoute("sorry");
        return;
      }
      handleError(error);
    }
  };
  useEffect(() => {
    const queries = queryString.parse(location.search.slice(1));
    const token = SessionHelper.getSessionToken();
    if (!token) {
      toAccounts(queries.target);
      return;
    }
    handleLogin();
  }, []);
  return /* @__PURE__ */ React.createElement(Wrapper, null, /* @__PURE__ */ React.createElement(Spin, null));
};
export default Login;
const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;
