import {lazy} from "../../lib/lazy.js";
export const warehouseInventoryRoutes = [
  {
    path: "/warehouse-inventory",
    auth: "warehouse",
    component: lazy(() => import(/* webpackChunkName: "warehouseInventoryRoutes" */ "../../views/warehouse-inventory/index.js"))
  },
  {
    path: "/warehouse-inventory-detail/:id",
    component: lazy(() => import(/* webpackChunkName: "outboundManagementRoutes" */ "../../views/warehouse-inventory/includes/Detail.js")),
    meta: {
      openKeys: ["menu-sub-warehouse"],
      selectedKeys: ["outbound-management"]
    }
  }
];
