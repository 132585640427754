import {lazy} from "../../lib/lazy.js";
export const keeppackWarehouse = [
  {
    path: "/warehouse-keeppack-product",
    component: lazy(() => import(/* webpackChunkName: "warehouseProductListRoutes" */ "../../views/keeppack-warehouse/product/index.js")),
    meta: {
      openKeys: ["menu-sub-warehouse", "menu-sub-keeppack-warehouse"]
    },
    auth: "keeppack-warehouse-product"
  },
  {
    path: "/keeppack-create-master-product",
    component: lazy(() => import(/* webpackChunkName: "warehouseProductListRoutes" */ "../../views/keeppack-warehouse/component/CreateMainProduct.js"))
  },
  {
    path: "/keeppack-inbound-management",
    component: lazy(() => import(/* webpackChunkName: "warehouseProductListRoutes" */ "../../views/keeppack-warehouse/inbound/index.js")),
    meta: {
      openKeys: ["menu-sub-warehouse", "menu-sub-keeppack-warehouse"],
      selectedKeys: ["keeppack-inbound-management"]
    },
    auth: "keeppack-warehouse-purchase"
  },
  {
    path: "/keeppack-inbound-management-add",
    component: lazy(() => import(/* webpackChunkName: "inboundManagementRoutes" */ "../../views/inbound-management/Add.js")),
    meta: {
      openKeys: ["menu-sub-warehouse", "menu-sub-keeppack-warehouse"],
      selectedKeys: ["keeppack-inbound-management"]
    }
  },
  {
    path: "/keeppack-inbound-management-edit/:id",
    component: lazy(() => import(/* webpackChunkName: "inboundManagementRoutes" */ "../../views/inbound-management/Edit.js")),
    meta: {
      openKeys: ["menu-sub-warehouse", "menu-sub-keeppack-warehouse"],
      selectedKeys: ["keeppack-inbound-management"]
    }
  },
  {
    path: "/keeppack-inbound-management-detail/:id/:orderType",
    component: lazy(() => import(/* webpackChunkName: "inboundManagementRoutes" */ "../../views/inbound-management/Detail.js")),
    meta: {
      openKeys: ["menu-sub-warehouse", "menu-sub-keeppack-warehouse"],
      selectedKeys: ["keeppack-inbound-management"]
    }
  },
  {
    path: "/keeppack-inbound-management-submit/:id/:orderType",
    component: lazy(() => import(/* webpackChunkName: "inboundManagementRoutes" */ "../../views/inbound-management/Submit.js")),
    meta: {
      openKeys: ["menu-sub-warehouse", "menu-sub-keeppack-warehouse"],
      selectedKeys: ["keeppack-inbound-management"]
    }
  },
  {
    path: "/keeppack-outbound-management",
    component: lazy(() => import(/* webpackChunkName: "warehouseProductListRoutes" */ "../../views/keeppack-warehouse/outbound/index.js")),
    meta: {
      openKeys: ["menu-sub-warehouse", "menu-sub-keeppack-warehouse"],
      selectedKeys: ["keeppack-outbound-management"]
    },
    auth: "keeppack-warehouse-outbound"
  },
  {
    path: "/keeppack-outbound-management-add",
    component: lazy(() => import(/* webpackChunkName: "outboundManagementRoutes" */ "../../views/keeppack-warehouse/outbound/Add.js")),
    meta: {
      openKeys: ["menu-sub-warehouse", "menu-sub-keeppack-warehouse"],
      selectedKeys: ["keeppack-outbound-management"]
    }
  },
  {
    path: "/keeppack-outbound-management-edit/:id/:orderType",
    component: lazy(() => import(/* webpackChunkName: "outboundManagementRoutes" */ "../../views/keeppack-warehouse/outbound/Edit.js")),
    meta: {
      openKeys: ["menu-sub-warehouse", "menu-sub-keeppack-warehouse"],
      selectedKeys: ["keeppack-outbound-management"]
    }
  },
  {
    path: "/keeppack-outbound-management-detail/:id/:orderType",
    component: lazy(() => import(/* webpackChunkName: "outboundManagementRoutes" */ "../../views/keeppack-warehouse/outbound/Detail.js")),
    meta: {
      openKeys: ["menu-sub-warehouse", "menu-sub-keeppack-warehouse"],
      selectedKeys: ["keeppack-outbound-management"]
    }
  },
  {
    path: "/keeppack-outbound-management-submit/:id/:orderType",
    component: lazy(() => import(/* webpackChunkName: "outboundManagementRoutes" */ "../../views/keeppack-warehouse/outbound/Submit.js")),
    meta: {
      openKeys: ["menu-sub-warehouse", "menu-sub-keeppack-warehouse"],
      selectedKeys: ["keeppack-outbound-management"]
    }
  },
  {
    path: "/keeppack-outbound-inventory",
    component: lazy(() => import(/* webpackChunkName: "warehouseProductListRoutes" */ "../../views/keeppack-warehouse/inventory/index.js")),
    meta: {
      openKeys: ["menu-sub-warehouse", "menu-sub-keeppack-warehouse"]
    },
    auth: "keeppack-warehouse-stock-pull"
  }
];
