import {lazy} from "../../lib/lazy.js";
export const purchaseRoutes = [
  {
    path: "/purchase/supplier-list",
    component: lazy(() => import(/* webpackChunkName: "purchaseRoutes" */ "../../views/purchase/supplierList/index.js")),
    auth: "purchase-management-supplier-view"
  },
  {
    path: "/purchase/supplier-detail",
    component: lazy(() => import(/* webpackChunkName: "purchaseRoutes" */ "../../views/purchase/supplierDetail/detail.js")),
    auth: "purchase-management-supplier-view"
  },
  {
    path: "/purchase/order-list",
    component: lazy(() => import(/* webpackChunkName: "purchaseRoutes" */ "../../views/purchase/orderList/index.js")),
    auth: "purchase-management-purchase-order-view"
  },
  {
    path: "/purchase/order-detail-add",
    component: lazy(() => import(/* webpackChunkName: "purchaseRoutes" */ "../../views/purchase/orderDetail/add.js")),
    auth: "purchase-management-purchase-order-operation"
  },
  {
    path: "/purchase/order-detail-edit/:status/:id",
    component: lazy(() => import(/* webpackChunkName: "purchaseRoutes" */ "../../views/purchase/orderDetail/edit.js")),
    auth: "purchase-management-purchase-order-operation"
  },
  {
    path: "/purchase/order-detail-detail/:id",
    component: lazy(() => import(/* webpackChunkName: "purchaseRoutes" */ "../../views/purchase/orderDetail/detail.js")),
    auth: "purchase-management-purchase-order-view"
  },
  {
    path: "/purchase/type/setting",
    component: lazy(() => import(/* webpackChunkName: "purchaseRoutes" */ "../../views/purchase/typeSetting/index.js")),
    auth: "purchase-management"
  },
  {
    path: "/purchase/setting",
    component: lazy(() => import(/* webpackChunkName: "purchaseRoutes" */ "../../views/purchase/setting/index.js")),
    auth: "purchase-management-purchase-order-operation"
  }
];
