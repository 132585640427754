import {lazy} from "../../lib/lazy.js";
export const productLocalPublishRoutes = [
  {
    path: "/product-local-publish-result",
    component: lazy(() => import(/* webpackChunkName: "productLocalPublishRoutes" */ "../../views/product-local-publish-result/index.js"))
  },
  {
    path: "/product-local-edit",
    component: lazy(() => import(/* webpackChunkName: "productLocalPublishRoutes" */ "../../views/product-local-publish/index.js"))
  },
  {
    path: "/product-local-publish/channel",
    component: lazy(() => import(/* webpackChunkName: "productLocalPublishRoutes" */ "../../views/product-local-publish/channel.js"))
  },
  {
    path: "/product-local-publish",
    component: lazy(() => import(/* webpackChunkName: "productLocalPublishRoutes" */ "../../views/product-local-publish/index.js"))
  },
  {
    path: "/product-local-publish-channel",
    component: lazy(() => import(/* webpackChunkName: "productLocalPublishRoutes" */ "../../views/product-local-publish-channel/index.js"))
  }
];
