import {lazy} from "../../lib/lazy.js";
export const collectionRoutes = [
  {
    path: "/404",
    component: lazy(() => import(/* webpackChunkName: "NotFound" */ "../../views/404/index.js"))
  },
  {
    path: "/empty",
    component: lazy(() => import(/* webpackChunkName: "Empty" */ "../../views/empty/index.js"))
  },
  {
    path: "/",
    auth: "dashboard",
    component: lazy(() => import(/* webpackChunkName: "dashboard" */ "../../views/dashboard/index.js")),
    meta: {
      selectedKeys: ["dashboard"]
    }
  },
  {
    path: "/dashboard/notice-list",
    component: lazy(() => import(/* webpackChunkName: "NotFound" */ "../../views/dashboard/notice-list/index.js"))
  },
  {
    path: "/collect-list",
    component: lazy(() => import("../../views/collect-list/index.js"))
  },
  {
    path: "/collection-list",
    auth: "product-collect",
    component: lazy(() => import("../../views/collect-list/index.js"))
  },
  {
    path: "/collection-list/collect-edit/:ids",
    component: lazy(() => import("../../views/collect-edit/CollectEdit.js"))
  },
  {
    path: "/collection-edit/:ids",
    component: lazy(() => import("../../views/collect-edit/CollectEdit.js"))
  },
  {
    path: "/task-center",
    component: lazy(() => import("../../views/task-center/index.js"))
  },
  {
    path: "/package-expired",
    component: lazy(() => import("../../views/package-expired/index.js"))
  }
];
