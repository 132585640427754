import {lazy} from "../../lib/lazy.js";
export const productGineePOSRoutes = [
  {
    path: "/product-ginee-pos",
    component: lazy(() => import(/* webpackChunkName: "productGineePOSRoutes" */ "../../views/product-ginee-pos/list/index.js")),
    auth: "pos-settings-list"
  },
  {
    path: "/pos-setting",
    component: lazy(() => import(/* webpackChunkName: "productGineePOSRoutes" */ "../../views/pos-setting/index.js")),
    auth: "pos-settings-list"
  },
  {
    path: "/pos-setting-:action(edit)/:id",
    component: lazy(() => import(/* webpackChunkName: "productGineePOSRoutes" */ "../../views/pos-setting/edit.js")),
    auth: "pos-settings-list"
  }
];
