import {lazy} from "../../lib/lazy.js";
export const stockRoutes = [
  {
    path: "/stock-in-out-record",
    component: lazy(() => import(/* webpackChunkName: "stockRoutes" */ "../../views/stock-in-out-record/index.js")),
    meta: {
      openKeys: ["menu-sub-inventory"]
    }
  },
  {
    path: "/stock-in-out-record-local",
    component: lazy(() => import(/* webpackChunkName: "stockRoutes" */ "../../views/stock-in-out-record-local/index.js")),
    meta: {
      openKeys: ["menu-sub-warehouse"]
    }
  },
  {
    path: "/stock-pull-record",
    auth: "stock-pull-record",
    component: lazy(() => import(/* webpackChunkName: "stockRoutes" */ "../../views/stock-pull-record/index.js")),
    meta: {
      openKeys: ["menu-sub-inventory"]
    }
  },
  {
    path: "/stock-pull-record-detail/:id",
    component: lazy(() => import(/* webpackChunkName: "stockRoutes" */ "../../views/stock-pull-record/Detail.js")),
    meta: {
      openKeys: ["menu-sub-inventory"],
      selectedKeys: ["stock-pull-record"]
    }
  },
  {
    path: "/store-stock-change-history",
    auth: "store-stock-change-history",
    component: lazy(() => import(/* webpackChunkName: "stockRoutes" */ "../../views/stock-in-out-record-local/change-history.js")),
    meta: {
      openKeys: ["menu-sub-inventory"]
    }
  },
  {
    path: "/stock-safety-settings",
    component: lazy(() => import(/* webpackChunkName: "stockRoutes" */ "../../views/stock-safety-settings/index.js")),
    meta: {
      openKeys: ["menu-sub-inventory"]
    }
  },
  {
    path: "/stock-manage/edit-warehouse-stock",
    component: lazy(() => import(/* webpackChunkName: "stockRoutes" */ "../../views/edit-warehouse-stock/index.js")),
    meta: {
      openKeys: ["menu-sub-inventory"],
      selectedKeys: ["stock-manage"]
    }
  }
];
