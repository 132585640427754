import {lazy} from "../../lib/lazy.js";
export const financialRoutes = [
  {
    path: "/sales-slip",
    component: lazy(() => import(/* webpackChunkName: "customerRoutes" */ "../../views/financial-management/SalesSlip.js"))
  },
  {
    path: "/sales-invoice-push-record",
    component: lazy(() => import(/* webpackChunkName: "customerRoutes" */ "../../views/financial-management/SalesInvoicePushRecord.js"))
  }
];
