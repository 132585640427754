import React from "../../../../__snowpack__/pkg/react.js";
export const IconHelp = React.memo((props) => {
  return /* @__PURE__ */ React.createElement("svg", {
    xmlns: "http://www.w3.org/2000/svg",
    width: "16",
    height: "16",
    viewBox: "0 0 16 16",
    ...props
  }, /* @__PURE__ */ React.createElement("g", {
    fill: "none",
    fillRule: "evenodd",
    stroke: "none",
    strokeWidth: "1"
  }, /* @__PURE__ */ React.createElement("g", {
    transform: "translate(-1107 -16)"
  }, /* @__PURE__ */ React.createElement("g", {
    transform: "translate(844 8)"
  }, /* @__PURE__ */ React.createElement("g", {
    transform: "translate(255)"
  }, /* @__PURE__ */ React.createElement("g", {
    transform: "translate(8 8)"
  }, /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", {
    d: "M0 0H16V16H0z"
  }), /* @__PURE__ */ React.createElement("path", {
    fill: "currentColor",
    fillRule: "nonzero",
    d: "M8 14c1.709-.038 3.127-.62 4.254-1.746C13.38 11.127 13.962 9.709 14 8c-.038-1.709-.624-3.127-1.76-4.254C11.102 2.62 9.69 2.038 8 2c-1.709.038-3.127.62-4.254 1.746C2.62 4.873 2.038 6.291 2 8c.038 1.709.62 3.127 1.746 4.254C4.873 13.38 6.291 13.962 8 14zm.592-4.197H7.408v-.31c.02-.676.254-1.24.705-1.69l.732-.76c.225-.226.338-.508.338-.846 0-.338-.113-.62-.338-.845A1.147 1.147 0 008 5.014c-.338 0-.62.113-.845.338a1.25 1.25 0 00-.366.845H5.606c.018-.676.253-1.24.704-1.69.45-.45 1.014-.676 1.69-.676.676 0 1.24.225 1.69.676.45.45.686 1.014.704 1.69 0 .545-.187.995-.563 1.352l-.535.564a2.346 2.346 0 00-.507.662c-.132.253-.197.596-.197 1.028zm0 2.394H7.408v-1.183h1.184v1.183z"
  }))))))));
});
