import {lazy} from "../../lib/lazy.js";
export const productTokoPedia = [
  {
    path: "/product-tokopedia",
    component: lazy(() => import(/* webpackChunkName: "productTokopediaRoutes" */ "../../views/product-tokopedia/list/index.js")),
    auth: "list-product"
  },
  {
    path: "/product-tokopedia-:action(add)",
    component: lazy(() => import(/* webpackChunkName: "productTokopediaRoutes" */ "../../views/product-tokopedia/edit/index.js")),
    auth: "list-product"
  },
  {
    path: "/product-tokopedia-:action(edit)/:id",
    component: lazy(() => import(/* webpackChunkName: "productTokopediaRoutes" */ "../../views/product-tokopedia/edit/index.js")),
    auth: "list-product"
  },
  {
    path: "/product-tokopedia-mass-edit-category-attribute",
    component: lazy(() => import(/* webpackChunkName: "productTokopediaRoutes" */ "../../views/product-tokopedia/mass-edit-category/index.js"))
  },
  {
    path: "/product-tokopedia-mass-edit",
    component: lazy(() => import(/* webpackChunkName: "productTokopediaRoutes" */ "../../views/product-tokopedia/mass-edit/index.js"))
  }
];
