import qs from "../../../__snowpack__/pkg/qs.js";
import Axios from "../../../__snowpack__/pkg/axios.js";
import {RequestError} from "./type.js";
import {AcceptLanguageMap} from "../../lib/const.js";
import {handleError as globalHandleError} from "./ErrorHandler.js";
import {OriginMap, SessionHelper} from "../../../__snowpack__/pkg/@genie/lib-iam-utils.js";
const instance = Axios.create({
  baseURL: "/",
  timeout: 6e4,
  withCredentials: true,
  headers: {
    "Access-Control-Allow-Credentials": true
  },
  paramsSerializer: (params) => qs.stringify(params, {arrayFormat: "comma", skipNulls: true})
});
const adapter = (config) => {
  const url = config.url;
  const cache = localStorage.getItem(url) || "";
  const data = JSON.parse(cache) || {};
  return new Promise((resolve) => {
    const response = {
      data: {
        data: data?.cacheData,
        code: "SUCCESS"
      },
      status: 200,
      statusText: "OK",
      config,
      headers: {},
      gineeIsCache: true
    };
    resolve(response);
  });
};
const requestInterceptor = (config) => {
  if (config.url && /^\/infra\//.test(config.url)) {
    config.baseURL = OriginMap[window.location.origin]?.INFRA_ORIGIN ?? "/";
  }
  Object.assign(config.headers, {
    Authorization: SessionHelper.getSessionToken() ?? "",
    "Accept-Language": AcceptLanguageMap[localStorage.getItem("language") || "id"]
  });
  return config;
};
const responseInterceptor = (response) => {
  const {data, config} = response;
  const {parseResponse = true} = config ?? {};
  if (data?.code === "SUCCESS")
    return parseResponse ? data?.data : data;
  const noCheckUrlList = ["/saas/tips/list"];
  if (data.code && data.code === "RESOURCE_REACH_LIMIT") {
    if (window.location.pathname !== "/resource-constraints" && !noCheckUrlList.includes(config.url)) {
      window.location.href = "/resource-constraints";
    }
    return Object.assign({}, data, {code: "SUCCESS", errorCode: "RESOURCE_REACH_LIMIT"});
  }
  if (data?.code && data.code !== "SUCCESS") {
    const error = new RequestError(data?.code, data?.data, data?.message, data?.transactionId);
    config?.handleError ? config.handleError(error, globalHandleError) : globalHandleError(error);
    throw error;
  }
};
const responseInterceptorError = (error) => {
  const {config, response = {}} = error;
  const {data} = response;
  const netError = new RequestError(data?.code, data?.data, data?.message, data?.transactionId);
  config?.handleError ? config.handleError(netError, globalHandleError) : globalHandleError(netError);
  throw error;
};
instance.interceptors.request.use(requestInterceptor);
instance.interceptors.response.use(responseInterceptor, responseInterceptorError);
export const request = instance;
export const mockRequest = Axios.create({
  baseURL: "/",
  timeout: 6e4,
  withCredentials: true,
  headers: {
    "Access-Control-Allow-Credentials": true
  },
  paramsSerializer: (params) => qs.stringify(params, {arrayFormat: "comma", skipNulls: true})
});
mockRequest.interceptors.request.use(requestInterceptor);
export default instance;
