import { r as react } from './index-ae8bfcfb.js';
import { _ as __read } from './tslib.es6-a8e14131.js';

function useMemoizedFn(fn) {
  var fnRef = react.useRef(fn);
  // why not write `fnRef.current = fn`?
  // https://github.com/alibaba/hooks/issues/728
  fnRef.current = react.useMemo(function () {
    return fn;
  }, [fn]);
  var memoizedFn = react.useRef();
  if (!memoizedFn.current) {
    memoizedFn.current = function () {
      var args = [];
      for (var _i = 0; _i < arguments.length; _i++) {
        args[_i] = arguments[_i];
      }
      return fnRef.current.apply(this, args);
    };
  }
  return memoizedFn.current;
}

var useUpdate = function () {
  var _a = __read(react.useState({}), 2),
    setState = _a[1];
  return react.useCallback(function () {
    return setState({});
  }, []);
};

export { useMemoizedFn as a, useUpdate as u };
