import React from "../../../__snowpack__/pkg/react.js";
import {Button} from "../../../__snowpack__/pkg/antd.js";
import errorPagePng from "./errorPage.png";
import LocaleContext from "../../../__snowpack__/pkg/antd/es/locale-provider/context.js";
import Cookies from "../../../__snowpack__/pkg/js-cookie.js";
import request from "../../utils/request/index.js";
import {throttle} from "../../../__snowpack__/pkg/lodash.js";
const i18n = {
  "zh-cn": {
    title: `\u5929\u554A\uFF01\u9875\u9762\u5D29\u6E83\u4E86\uFF01\u8BF7\u5237\u65B0\u9875\u9762\u91CD\u8BD5\u6216\u8054\u7CFB\u5BA2\u670D`,
    button: `\u5237\u65B0\u9875\u9762`
  },
  en: {
    title: `Oh my! Page Crashed! Please refresh the page and try again or contact Customer Service`,
    button: `Refresh Page`
  },
  id: {
    title: `Ups! Halaman tidak merespon! Silahkan refresh halaman dan coba lagi, atau kontak Customer Service`,
    button: `Refresh Halaman`
  },
  vi: {
    title: `i18n: title`,
    button: `i18n: button`
  },
  th: {
    title: `i18n: title`,
    button: `i18n: button`
  }
};
export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.submitError = throttle(async (params) => {
      let trackId = Cookies.get("trackId");
      const defaultParams = {
        applicationName: "SYS_ERP",
        requestHost: window.location.origin,
        requestUri: window.location.pathname,
        httpAgent: window.navigator.userAgent,
        clientId: trackId
      };
      const data = request.post("/infra/supports/ticket/submit", {...defaultParams, ...params}).finally(() => {
      });
    }, 30 * 60 * 1e3);
    this.state = {hasError: false};
  }
  static getDerivedStateFromError(error) {
    return {hasError: true};
  }
  componentDidCatch(error, errorInfo) {
    const errorMessage = (errorInfo?.componentStack || "").replace(/\s/g, "").slice(0, 100);
    const noSubmitStringArray = ["InvalidStateError", "ResizeObserver"];
    if (!noSubmitStringArray.includes(error?.toString())) {
      this.submitError({
        title: "OMS \u9875\u9762\u5D29\u6E83",
        ticketSource: "SAAS_SUBMIT_ERROR_COLLECTION_AUTO",
        description: error.toString(),
        content: {
          message: errorMessage || "",
          error: error?.toString()
        }
      });
    }
  }
  render() {
    if (this.state.hasError) {
      return /* @__PURE__ */ React.createElement(LocaleContext.Consumer, null, (context) => {
        return /* @__PURE__ */ React.createElement("div", {
          style: {
            width: "100vw",
            height: "100vh",
            backgroundColor: "#fff",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            overflow: "hidden"
          }
        }, /* @__PURE__ */ React.createElement("img", {
          src: errorPagePng,
          style: {width: 601}
        }), /* @__PURE__ */ React.createElement("div", {
          style: {
            width: 100,
            height: 4,
            backgroundColor: "#FA541C",
            marginTop: 40,
            marginBottom: 16
          }
        }), /* @__PURE__ */ React.createElement("span", {
          style: {
            fontSize: 18,
            fontWeight: 500,
            color: "#202D40",
            marginBottom: 16,
            width: 600,
            textAlign: "center"
          }
        }, i18n[context?.locale || ""]?.title), /* @__PURE__ */ React.createElement(Button, {
          type: "primary",
          onClick: () => {
            if (window.parent !== window) {
              window.parent.location.reload();
              return;
            }
            window.location.reload();
          }
        }, i18n[context?.locale || ""]?.button));
      });
    }
    return this.props.children;
  }
}
