import React from "../../../../__snowpack__/pkg/react.js";
export const IconMenuOrders = React.memo((props) => {
  return /* @__PURE__ */ React.createElement("svg", {
    xmlns: "http://www.w3.org/2000/svg",
    width: "18",
    height: "18",
    viewBox: "0 0 18 18",
    ...props
  }, /* @__PURE__ */ React.createElement("g", {
    fill: "none",
    fillRule: "evenodd",
    stroke: "none",
    strokeWidth: "1"
  }, /* @__PURE__ */ React.createElement("g", {
    transform: "translate(-12 -106)"
  }, /* @__PURE__ */ React.createElement("g", {
    transform: "translate(0 94)"
  }, /* @__PURE__ */ React.createElement("g", {
    transform: "translate(12 12)"
  }, /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", {
    d: "M0 0H18V18H0z"
  }), /* @__PURE__ */ React.createElement("g", {
    fill: "currentColor",
    fillRule: "nonzero",
    transform: "translate(1 2)"
  }, /* @__PURE__ */ React.createElement("path", {
    d: "M5.368 11.88a1.481 1.481 0 011.387.934c.081.19.122.39.122.601 0 .211-.041.412-.122.6a1.558 1.558 0 01-.803.826 1.397 1.397 0 01-.584.126c-.216 0-.416-.042-.6-.125a1.558 1.558 0 01-.803-.826 1.508 1.508 0 01-.121-.601c0-.211.04-.412.121-.6.081-.19.19-.354.325-.493s.294-.247.478-.325c.184-.078.384-.117.6-.117zm6.747.033c.206 0 .403.04.592.117.19.078.351.187.487.326.135.139.243.303.324.492.081.189.122.39.122.6 0 .212-.04.412-.122.601a1.532 1.532 0 01-1.403.951c-.205 0-.4-.042-.584-.125a1.558 1.558 0 01-.803-.826 1.508 1.508 0 01-.121-.6c0-.212.04-.412.121-.601.082-.19.19-.353.325-.492a1.481 1.481 0 011.062-.442zm2.725-9.476c.303 0 .538.042.705.125a.81.81 0 01.357.309c.07.122.103.253.097.392a1.34 1.34 0 01-.056.359c-.033.1-.103.305-.211.617-.108.311-.225.653-.349 1.026-.124.373-.249.742-.373 1.11-.124.366-.224.656-.3.867-.14.445-.316.754-.527.926-.21.172-.473.259-.787.259H4.882l.227 1.501h8.19c.52 0 .779.228.779.684 0 .223-.052.417-.155.584-.102.167-.305.245-.608.234H4.817a.938.938 0 01-.543-.15c-.146-.1-.268-.231-.365-.393a2.416 2.416 0 01-.236-.525 4.914 4.914 0 01-.137-.534 25.13 25.13 0 00-.09-.484 147.79 147.79 0 01-.178-.96 8220.89 8220.89 0 01-.495-2.71 754.455 754.455 0 00-.697-3.754H.86a.644.644 0 01-.406-.125 1.078 1.078 0 01-.267-.3 1.175 1.175 0 01-.146-.376A1.86 1.86 0 010 .752C0 .53.073.346.219.202.365.056.562-.01.81.001h1.638c.216 0 .39.033.519.1.13.066.232.15.308.25.076.1.13.203.162.308.033.106.06.198.081.275.022.09.044.215.065.376.022.161.044.325.065.492.033.2.065.412.097.634H14.84zm-.6 1.485L4.087 3.889l.568 3.02 8.482.016 1.103-3.003z"
  }))))))));
});
