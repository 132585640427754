import moment from "../../__snowpack__/pkg/moment-timezone.js";
export const topNavHeight = 49;
export const smeLoanOrigin = {
  alistging1: "https://sme.kreditpintar.net",
  prod: "https://business.kreditpintar.com"
};
export const AcceptLanguageMap = {
  en: "en",
  zh: "zh",
  id: "id",
  th: "th",
  vn: "vi"
};
const nowTime = moment();
const startTime = moment("2023-12-11 20:00:00");
const endTime = moment("2023-12-14 00:00:00");
export const bigPromotionTimeRange = nowTime.isBetween(startTime, endTime);
