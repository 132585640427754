import {lazy} from "../../lib/lazy.js";
export const stockLocalRoutes = [
  {
    path: "/stock-manage-local",
    auth: "list-msku-stock",
    component: lazy(() => import(/* webpackChunkName: "stockLocalRoutes" */ "../../views/stock-local/index.js")),
    meta: {
      openKeys: ["menu-sub-inventory"]
    }
  },
  {
    path: "/stock-reserve",
    auth: "list-msku-stock",
    component: lazy(() => import(/* webpackChunkName: "stockLocalRoutes" */ "../../views/stock-reserve/index.js")),
    meta: {
      openKeys: ["menu-sub-inventory"]
    }
  },
  {
    path: "/inventory-log-local",
    auth: "stock-changes-record-2.0",
    component: lazy(() => import(/* webpackChunkName: "stockLocalRoutes" */ "../../views/stock-inventory-change-record/index.js")),
    meta: {
      openKeys: ["menu-sub-inventory"]
    }
  },
  {
    path: "/stock-manage-local/edit-warehouse-stock",
    component: lazy(() => import(/* webpackChunkName: "stockLocalRoutes" */ "../../views/edit-warehouse-stock/index-local.js")),
    meta: {
      openKeys: ["menu-sub-inventory"],
      selectedKeys: ["stock-manage-local"]
    }
  },
  {
    path: "/stock-manage-local/edit-local-isku",
    component: lazy(() => import(/* webpackChunkName: "stockLocalRoutes" */ "../../views/stock-local/edit-isku.js")),
    meta: {
      openKeys: ["menu-sub-inventory"],
      selectedKeys: ["stock-manage-local"]
    }
  },
  {
    path: "/stock-manage-local/edit-bundle-isku",
    component: lazy(() => import(/* webpackChunkName: "stockLocalRoutes" */ "../../views/stock-local/edit-isku.js")),
    meta: {
      openKeys: ["menu-sub-inventory"],
      selectedKeys: ["stock-manage-local"]
    }
  }
];
