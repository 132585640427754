import React from "../../../../__snowpack__/pkg/react.js";
export const IconMenuPromotion = React.memo((props) => {
  return /* @__PURE__ */ React.createElement("svg", {
    xmlns: "http://www.w3.org/2000/svg",
    width: "18",
    height: "18",
    viewBox: "0 0 18 18",
    ...props
  }, /* @__PURE__ */ React.createElement("g", {
    fill: "none",
    fillRule: "evenodd",
    stroke: "none",
    strokeWidth: "1"
  }, /* @__PURE__ */ React.createElement("g", {
    fill: "currentColor",
    fillRule: "nonzero",
    transform: "translate(-12 -316)"
  }, /* @__PURE__ */ React.createElement("g", {
    transform: "translate(2 306)"
  }, /* @__PURE__ */ React.createElement("g", {
    transform: "rotate(-45 23.036 9.257)"
  }, /* @__PURE__ */ React.createElement("path", {
    d: "M12.505 4.293h-.513l-.363.364-6.381 6.38a3.183 3.183 0 00-.923 1.868c-.095.827 0 1.33.57 1.907l6.208 6.206a2.925 2.925 0 002.07.894 3.004 3.004 0 002.118-.827l6.497-6.502.363-.343V6.793a2.603 2.603 0 00-2.673-2.483l-6.973-.017zm8.148 9.562l-6.342 6.322a1.782 1.782 0 01-2.424 0L6.043 14.24c-.63-.636-.444-1.611.187-2.247l6.225-6.34h6.525c.894.02 1.623.735 1.673 1.641v6.561z",
    transform: "rotate(-45 13.222 13.102)"
  }), /* @__PURE__ */ React.createElement("path", {
    d: "M11.538 7.495a2.483 2.483 0 013.433 3.588l-.134.12a2.483 2.483 0 01-3.374-3.633l.037-.038zm1.678.591c-.685 0-1.24.557-1.24 1.242l.006.127a1.242 1.242 0 002.476-.128l-.006-.127a1.242 1.242 0 00-1.236-1.114z"
  }), /* @__PURE__ */ React.createElement("path", {
    d: "M11.616 13.693L9.86 15.428a.621.621 0 000 .88c.24.24.468.373.712.133l2.07-2.047c.24-.246.111-.482-.13-.72a.624.624 0 00-.897.019z",
    transform: "rotate(-45 11.224 15.03)"
  }), /* @__PURE__ */ React.createElement("path", {
    d: "M14.532 16.446c.24.245.343.245.583 0l2.07-2.048c.243-.24.243-.343 0-.584-.246-.24-.593-.545-.828-.301l-2.096 2.069c-.265.232.029.624.27.865v-.001z",
    transform: "rotate(-45 15.762 15.026)"
  }))))));
});
