import {lazy} from "../../lib/lazy.js";
export const accurateWarehouse = [
  {
    path: "/warehouse-accurate-product",
    component: lazy(() => import(/* webpackChunkName: "warehouseProductListRoutes" */ "../../views/accurate-warehouse/product/index.js")),
    meta: {}
  },
  {
    path: "/warehouse-accurate-order",
    component: lazy(() => import(/* webpackChunkName: "warehouseProductListRoutes" */ "../../views/accurate-warehouse/order/index.js")),
    meta: {}
  }
];
